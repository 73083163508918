/* eslint-disable */

import { AllTypesProps, ReturnTypes } from './const';
type ZEUS_INTERFACES = never
type ZEUS_UNIONS = never

export type ValueTypes = {
    ["AddResult"]: AliasType<{
	sum?:true,
		__typename?: true
}>;
	/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
["Boolean_comparison_exp"]: {
	_eq?:boolean,
	_gt?:boolean,
	_gte?:boolean,
	_in?:boolean[],
	_is_null?:boolean,
	_lt?:boolean,
	_lte?:boolean,
	_neq?:boolean,
	_nin?:boolean[]
};
	/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
["Int_comparison_exp"]: {
	_eq?:number,
	_gt?:number,
	_gte?:number,
	_in?:number[],
	_is_null?:boolean,
	_lt?:number,
	_lte?:number,
	_neq?:number,
	_nin?:number[]
};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
["String_comparison_exp"]: {
	_eq?:string,
	_gt?:string,
	_gte?:string,
	/** does the column match the given case-insensitive pattern */
	_ilike?:string,
	_in?:string[],
	/** does the column match the given POSIX regular expression, case insensitive */
	_iregex?:string,
	_is_null?:boolean,
	/** does the column match the given pattern */
	_like?:string,
	_lt?:string,
	_lte?:string,
	_neq?:string,
	/** does the column NOT match the given case-insensitive pattern */
	_nilike?:string,
	_nin?:string[],
	/** does the column NOT match the given POSIX regular expression, case insensitive */
	_niregex?:string,
	/** does the column NOT match the given pattern */
	_nlike?:string,
	/** does the column NOT match the given POSIX regular expression, case sensitive */
	_nregex?:string,
	/** does the column NOT match the given SQL regular expression */
	_nsimilar?:string,
	/** does the column match the given POSIX regular expression, case sensitive */
	_regex?:string,
	/** does the column match the given SQL regular expression */
	_similar?:string
};
	/** columns and relationships of "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1"]: AliasType<{
	DETAILS?:true,
	FAILURE_TIME?:true,
	FAILURE_TYPE?:true,
	KEY?:true,
	KEY_TYPE?:true,
	TABLE_NAME?:true,
	TABLE_OWNER?:true,
	TASK_NAME?:true,
		__typename?: true
}>;
	/** aggregated selection of "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_aggregate"]: AliasType<{
	aggregate?:ValueTypes["awsdms_validation_failures_v1_aggregate_fields"],
	nodes?:ValueTypes["awsdms_validation_failures_v1"],
		__typename?: true
}>;
	/** aggregate fields of "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["awsdms_validation_failures_v1_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["awsdms_validation_failures_v1_max_fields"],
	min?:ValueTypes["awsdms_validation_failures_v1_min_fields"],
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "awsdms_validation_failures_v1". All fields are combined with a logical 'AND'. */
["awsdms_validation_failures_v1_bool_exp"]: {
	DETAILS?:ValueTypes["String_comparison_exp"],
	FAILURE_TIME?:ValueTypes["timestamp_comparison_exp"],
	FAILURE_TYPE?:ValueTypes["String_comparison_exp"],
	KEY?:ValueTypes["String_comparison_exp"],
	KEY_TYPE?:ValueTypes["String_comparison_exp"],
	TABLE_NAME?:ValueTypes["String_comparison_exp"],
	TABLE_OWNER?:ValueTypes["String_comparison_exp"],
	TASK_NAME?:ValueTypes["String_comparison_exp"],
	_and?:ValueTypes["awsdms_validation_failures_v1_bool_exp"][],
	_not?:ValueTypes["awsdms_validation_failures_v1_bool_exp"],
	_or?:ValueTypes["awsdms_validation_failures_v1_bool_exp"][]
};
	/** input type for inserting data into table "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_insert_input"]: {
	DETAILS?:string,
	FAILURE_TIME?:ValueTypes["timestamp"],
	FAILURE_TYPE?:string,
	KEY?:string,
	KEY_TYPE?:string,
	TABLE_NAME?:string,
	TABLE_OWNER?:string,
	TASK_NAME?:string
};
	/** aggregate max on columns */
["awsdms_validation_failures_v1_max_fields"]: AliasType<{
	DETAILS?:true,
	FAILURE_TIME?:true,
	FAILURE_TYPE?:true,
	KEY?:true,
	KEY_TYPE?:true,
	TABLE_NAME?:true,
	TABLE_OWNER?:true,
	TASK_NAME?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["awsdms_validation_failures_v1_min_fields"]: AliasType<{
	DETAILS?:true,
	FAILURE_TIME?:true,
	FAILURE_TYPE?:true,
	KEY?:true,
	KEY_TYPE?:true,
	TABLE_NAME?:true,
	TABLE_OWNER?:true,
	TASK_NAME?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["awsdms_validation_failures_v1"],
		__typename?: true
}>;
	/** Ordering options when selecting data from "awsdms_validation_failures_v1". */
["awsdms_validation_failures_v1_order_by"]: {
	DETAILS?:ValueTypes["order_by"],
	FAILURE_TIME?:ValueTypes["order_by"],
	FAILURE_TYPE?:ValueTypes["order_by"],
	KEY?:ValueTypes["order_by"],
	KEY_TYPE?:ValueTypes["order_by"],
	TABLE_NAME?:ValueTypes["order_by"],
	TABLE_OWNER?:ValueTypes["order_by"],
	TASK_NAME?:ValueTypes["order_by"]
};
	/** select columns of table "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_select_column"]:awsdms_validation_failures_v1_select_column;
	/** input type for updating data in table "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_set_input"]: {
	DETAILS?:string,
	FAILURE_TIME?:ValueTypes["timestamp"],
	FAILURE_TYPE?:string,
	KEY?:string,
	KEY_TYPE?:string,
	TABLE_NAME?:string,
	TABLE_OWNER?:string,
	TASK_NAME?:string
};
	/** Streaming cursor of the table "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["awsdms_validation_failures_v1_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["awsdms_validation_failures_v1_stream_cursor_value_input"]: {
	DETAILS?:string,
	FAILURE_TIME?:ValueTypes["timestamp"],
	FAILURE_TYPE?:string,
	KEY?:string,
	KEY_TYPE?:string,
	TABLE_NAME?:string,
	TABLE_OWNER?:string,
	TASK_NAME?:string
};
	["awsdms_validation_failures_v1_updates"]: {
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["awsdms_validation_failures_v1_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["awsdms_validation_failures_v1_bool_exp"]
};
	["bigint"]:unknown;
	/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
["bigint_comparison_exp"]: {
	_eq?:ValueTypes["bigint"],
	_gt?:ValueTypes["bigint"],
	_gte?:ValueTypes["bigint"],
	_in?:ValueTypes["bigint"][],
	_is_null?:boolean,
	_lt?:ValueTypes["bigint"],
	_lte?:ValueTypes["bigint"],
	_neq?:ValueTypes["bigint"],
	_nin?:ValueTypes["bigint"][]
};
	["bit"]:unknown;
	/** Boolean expression to compare columns of type "bit". All fields are combined with logical 'AND'. */
["bit_comparison_exp"]: {
	_eq?:ValueTypes["bit"],
	_gt?:ValueTypes["bit"],
	_gte?:ValueTypes["bit"],
	_in?:ValueTypes["bit"][],
	_is_null?:boolean,
	_lt?:ValueTypes["bit"],
	_lte?:ValueTypes["bit"],
	_neq?:ValueTypes["bit"],
	_nin?:ValueTypes["bit"][]
};
	["bpchar"]:unknown;
	/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
["bpchar_comparison_exp"]: {
	_eq?:ValueTypes["bpchar"],
	_gt?:ValueTypes["bpchar"],
	_gte?:ValueTypes["bpchar"],
	/** does the column match the given case-insensitive pattern */
	_ilike?:ValueTypes["bpchar"],
	_in?:ValueTypes["bpchar"][],
	/** does the column match the given POSIX regular expression, case insensitive */
	_iregex?:ValueTypes["bpchar"],
	_is_null?:boolean,
	/** does the column match the given pattern */
	_like?:ValueTypes["bpchar"],
	_lt?:ValueTypes["bpchar"],
	_lte?:ValueTypes["bpchar"],
	_neq?:ValueTypes["bpchar"],
	/** does the column NOT match the given case-insensitive pattern */
	_nilike?:ValueTypes["bpchar"],
	_nin?:ValueTypes["bpchar"][],
	/** does the column NOT match the given POSIX regular expression, case insensitive */
	_niregex?:ValueTypes["bpchar"],
	/** does the column NOT match the given pattern */
	_nlike?:ValueTypes["bpchar"],
	/** does the column NOT match the given POSIX regular expression, case sensitive */
	_nregex?:ValueTypes["bpchar"],
	/** does the column NOT match the given SQL regular expression */
	_nsimilar?:ValueTypes["bpchar"],
	/** does the column match the given POSIX regular expression, case sensitive */
	_regex?:ValueTypes["bpchar"],
	/** does the column match the given SQL regular expression */
	_similar?:ValueTypes["bpchar"]
};
	["bytea"]:unknown;
	/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
["bytea_comparison_exp"]: {
	_eq?:ValueTypes["bytea"],
	_gt?:ValueTypes["bytea"],
	_gte?:ValueTypes["bytea"],
	_in?:ValueTypes["bytea"][],
	_is_null?:boolean,
	_lt?:ValueTypes["bytea"],
	_lte?:ValueTypes["bytea"],
	_neq?:ValueTypes["bytea"],
	_nin?:ValueTypes["bytea"][]
};
	/** columns and relationships of "coinsystem.balances" */
["coinsystem_balances"]: AliasType<{
	balance?:true,
	/** An object relationship */
	coin?:ValueTypes["coinsystem_coins"],
	coin_id?:true,
	created_at?:true,
	id?:true,
	updated_at?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
	/** An object relationship */
	wallet?:ValueTypes["coinsystem_wallets"],
	wallet_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "coinsystem.balances" */
["coinsystem_balances_aggregate"]: AliasType<{
	aggregate?:ValueTypes["coinsystem_balances_aggregate_fields"],
	nodes?:ValueTypes["coinsystem_balances"],
		__typename?: true
}>;
	/** aggregate fields of "coinsystem.balances" */
["coinsystem_balances_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["coinsystem_balances_avg_fields"],
count?: [{	columns?:ValueTypes["coinsystem_balances_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["coinsystem_balances_max_fields"],
	min?:ValueTypes["coinsystem_balances_min_fields"],
	stddev?:ValueTypes["coinsystem_balances_stddev_fields"],
	stddev_pop?:ValueTypes["coinsystem_balances_stddev_pop_fields"],
	stddev_samp?:ValueTypes["coinsystem_balances_stddev_samp_fields"],
	sum?:ValueTypes["coinsystem_balances_sum_fields"],
	var_pop?:ValueTypes["coinsystem_balances_var_pop_fields"],
	var_samp?:ValueTypes["coinsystem_balances_var_samp_fields"],
	variance?:ValueTypes["coinsystem_balances_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["coinsystem_balances_avg_fields"]: AliasType<{
	balance?:true,
	coin_id?:true,
	id?:true,
	user_id?:true,
	wallet_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "coinsystem.balances". All fields are combined with a logical 'AND'. */
["coinsystem_balances_bool_exp"]: {
	_and?:ValueTypes["coinsystem_balances_bool_exp"][],
	_not?:ValueTypes["coinsystem_balances_bool_exp"],
	_or?:ValueTypes["coinsystem_balances_bool_exp"][],
	balance?:ValueTypes["numeric_comparison_exp"],
	coin?:ValueTypes["coinsystem_coins_bool_exp"],
	coin_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	wallet?:ValueTypes["coinsystem_wallets_bool_exp"],
	wallet_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "coinsystem.balances" */
["coinsystem_balances_constraint"]:coinsystem_balances_constraint;
	/** input type for incrementing numeric columns in table "coinsystem.balances" */
["coinsystem_balances_inc_input"]: {
	balance?:ValueTypes["numeric"],
	coin_id?:number,
	id?:number,
	user_id?:number,
	wallet_id?:number
};
	/** input type for inserting data into table "coinsystem.balances" */
["coinsystem_balances_insert_input"]: {
	balance?:ValueTypes["numeric"],
	coin?:ValueTypes["coinsystem_coins_obj_rel_insert_input"],
	coin_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	updated_at?:ValueTypes["timestamptz"],
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number,
	wallet?:ValueTypes["coinsystem_wallets_obj_rel_insert_input"],
	wallet_id?:number
};
	/** aggregate max on columns */
["coinsystem_balances_max_fields"]: AliasType<{
	balance?:true,
	coin_id?:true,
	created_at?:true,
	id?:true,
	updated_at?:true,
	user_id?:true,
	wallet_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["coinsystem_balances_min_fields"]: AliasType<{
	balance?:true,
	coin_id?:true,
	created_at?:true,
	id?:true,
	updated_at?:true,
	user_id?:true,
	wallet_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "coinsystem.balances" */
["coinsystem_balances_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["coinsystem_balances"],
		__typename?: true
}>;
	/** on_conflict condition type for table "coinsystem.balances" */
["coinsystem_balances_on_conflict"]: {
	constraint:ValueTypes["coinsystem_balances_constraint"],
	update_columns:ValueTypes["coinsystem_balances_update_column"][],
	where?:ValueTypes["coinsystem_balances_bool_exp"]
};
	/** Ordering options when selecting data from "coinsystem.balances". */
["coinsystem_balances_order_by"]: {
	balance?:ValueTypes["order_by"],
	coin?:ValueTypes["coinsystem_coins_order_by"],
	coin_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"],
	wallet?:ValueTypes["coinsystem_wallets_order_by"],
	wallet_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: coinsystem.balances */
["coinsystem_balances_pk_columns_input"]: {
	id:number
};
	/** select columns of table "coinsystem.balances" */
["coinsystem_balances_select_column"]:coinsystem_balances_select_column;
	/** input type for updating data in table "coinsystem.balances" */
["coinsystem_balances_set_input"]: {
	balance?:ValueTypes["numeric"],
	coin_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number,
	wallet_id?:number
};
	/** aggregate stddev on columns */
["coinsystem_balances_stddev_fields"]: AliasType<{
	balance?:true,
	coin_id?:true,
	id?:true,
	user_id?:true,
	wallet_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["coinsystem_balances_stddev_pop_fields"]: AliasType<{
	balance?:true,
	coin_id?:true,
	id?:true,
	user_id?:true,
	wallet_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["coinsystem_balances_stddev_samp_fields"]: AliasType<{
	balance?:true,
	coin_id?:true,
	id?:true,
	user_id?:true,
	wallet_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "coinsystem_balances" */
["coinsystem_balances_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["coinsystem_balances_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["coinsystem_balances_stream_cursor_value_input"]: {
	balance?:ValueTypes["numeric"],
	coin_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number,
	wallet_id?:number
};
	/** aggregate sum on columns */
["coinsystem_balances_sum_fields"]: AliasType<{
	balance?:true,
	coin_id?:true,
	id?:true,
	user_id?:true,
	wallet_id?:true,
		__typename?: true
}>;
	/** update columns of table "coinsystem.balances" */
["coinsystem_balances_update_column"]:coinsystem_balances_update_column;
	["coinsystem_balances_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_balances_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_balances_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["coinsystem_balances_bool_exp"]
};
	/** aggregate var_pop on columns */
["coinsystem_balances_var_pop_fields"]: AliasType<{
	balance?:true,
	coin_id?:true,
	id?:true,
	user_id?:true,
	wallet_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["coinsystem_balances_var_samp_fields"]: AliasType<{
	balance?:true,
	coin_id?:true,
	id?:true,
	user_id?:true,
	wallet_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["coinsystem_balances_variance_fields"]: AliasType<{
	balance?:true,
	coin_id?:true,
	id?:true,
	user_id?:true,
	wallet_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "coinsystem.bonus_coins" */
["coinsystem_bonus_coins"]: AliasType<{
	active?:true,
	bonus_amount?:true,
	coin_id?:true,
	created_at?:true,
	id?:true,
	max_amount?:true,
	min_amount?:true,
		__typename?: true
}>;
	/** aggregated selection of "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_aggregate"]: AliasType<{
	aggregate?:ValueTypes["coinsystem_bonus_coins_aggregate_fields"],
	nodes?:ValueTypes["coinsystem_bonus_coins"],
		__typename?: true
}>;
	/** aggregate fields of "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["coinsystem_bonus_coins_avg_fields"],
count?: [{	columns?:ValueTypes["coinsystem_bonus_coins_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["coinsystem_bonus_coins_max_fields"],
	min?:ValueTypes["coinsystem_bonus_coins_min_fields"],
	stddev?:ValueTypes["coinsystem_bonus_coins_stddev_fields"],
	stddev_pop?:ValueTypes["coinsystem_bonus_coins_stddev_pop_fields"],
	stddev_samp?:ValueTypes["coinsystem_bonus_coins_stddev_samp_fields"],
	sum?:ValueTypes["coinsystem_bonus_coins_sum_fields"],
	var_pop?:ValueTypes["coinsystem_bonus_coins_var_pop_fields"],
	var_samp?:ValueTypes["coinsystem_bonus_coins_var_samp_fields"],
	variance?:ValueTypes["coinsystem_bonus_coins_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["coinsystem_bonus_coins_avg_fields"]: AliasType<{
	bonus_amount?:true,
	coin_id?:true,
	id?:true,
	max_amount?:true,
	min_amount?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "coinsystem.bonus_coins". All fields are combined with a logical 'AND'. */
["coinsystem_bonus_coins_bool_exp"]: {
	_and?:ValueTypes["coinsystem_bonus_coins_bool_exp"][],
	_not?:ValueTypes["coinsystem_bonus_coins_bool_exp"],
	_or?:ValueTypes["coinsystem_bonus_coins_bool_exp"][],
	active?:ValueTypes["Boolean_comparison_exp"],
	bonus_amount?:ValueTypes["numeric_comparison_exp"],
	coin_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	max_amount?:ValueTypes["numeric_comparison_exp"],
	min_amount?:ValueTypes["numeric_comparison_exp"]
};
	/** unique or primary key constraints on table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_constraint"]:coinsystem_bonus_coins_constraint;
	/** input type for incrementing numeric columns in table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_inc_input"]: {
	bonus_amount?:ValueTypes["numeric"],
	coin_id?:number,
	id?:number,
	max_amount?:ValueTypes["numeric"],
	min_amount?:ValueTypes["numeric"]
};
	/** input type for inserting data into table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_insert_input"]: {
	active?:boolean,
	bonus_amount?:ValueTypes["numeric"],
	coin_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	max_amount?:ValueTypes["numeric"],
	min_amount?:ValueTypes["numeric"]
};
	/** aggregate max on columns */
["coinsystem_bonus_coins_max_fields"]: AliasType<{
	bonus_amount?:true,
	coin_id?:true,
	created_at?:true,
	id?:true,
	max_amount?:true,
	min_amount?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["coinsystem_bonus_coins_min_fields"]: AliasType<{
	bonus_amount?:true,
	coin_id?:true,
	created_at?:true,
	id?:true,
	max_amount?:true,
	min_amount?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["coinsystem_bonus_coins"],
		__typename?: true
}>;
	/** on_conflict condition type for table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_on_conflict"]: {
	constraint:ValueTypes["coinsystem_bonus_coins_constraint"],
	update_columns:ValueTypes["coinsystem_bonus_coins_update_column"][],
	where?:ValueTypes["coinsystem_bonus_coins_bool_exp"]
};
	/** Ordering options when selecting data from "coinsystem.bonus_coins". */
["coinsystem_bonus_coins_order_by"]: {
	active?:ValueTypes["order_by"],
	bonus_amount?:ValueTypes["order_by"],
	coin_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	max_amount?:ValueTypes["order_by"],
	min_amount?:ValueTypes["order_by"]
};
	/** primary key columns input for table: coinsystem.bonus_coins */
["coinsystem_bonus_coins_pk_columns_input"]: {
	id:number
};
	/** select columns of table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_select_column"]:coinsystem_bonus_coins_select_column;
	/** input type for updating data in table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_set_input"]: {
	active?:boolean,
	bonus_amount?:ValueTypes["numeric"],
	coin_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	max_amount?:ValueTypes["numeric"],
	min_amount?:ValueTypes["numeric"]
};
	/** aggregate stddev on columns */
["coinsystem_bonus_coins_stddev_fields"]: AliasType<{
	bonus_amount?:true,
	coin_id?:true,
	id?:true,
	max_amount?:true,
	min_amount?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["coinsystem_bonus_coins_stddev_pop_fields"]: AliasType<{
	bonus_amount?:true,
	coin_id?:true,
	id?:true,
	max_amount?:true,
	min_amount?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["coinsystem_bonus_coins_stddev_samp_fields"]: AliasType<{
	bonus_amount?:true,
	coin_id?:true,
	id?:true,
	max_amount?:true,
	min_amount?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "coinsystem_bonus_coins" */
["coinsystem_bonus_coins_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["coinsystem_bonus_coins_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["coinsystem_bonus_coins_stream_cursor_value_input"]: {
	active?:boolean,
	bonus_amount?:ValueTypes["numeric"],
	coin_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	max_amount?:ValueTypes["numeric"],
	min_amount?:ValueTypes["numeric"]
};
	/** aggregate sum on columns */
["coinsystem_bonus_coins_sum_fields"]: AliasType<{
	bonus_amount?:true,
	coin_id?:true,
	id?:true,
	max_amount?:true,
	min_amount?:true,
		__typename?: true
}>;
	/** update columns of table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_update_column"]:coinsystem_bonus_coins_update_column;
	["coinsystem_bonus_coins_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_bonus_coins_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_bonus_coins_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["coinsystem_bonus_coins_bool_exp"]
};
	/** aggregate var_pop on columns */
["coinsystem_bonus_coins_var_pop_fields"]: AliasType<{
	bonus_amount?:true,
	coin_id?:true,
	id?:true,
	max_amount?:true,
	min_amount?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["coinsystem_bonus_coins_var_samp_fields"]: AliasType<{
	bonus_amount?:true,
	coin_id?:true,
	id?:true,
	max_amount?:true,
	min_amount?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["coinsystem_bonus_coins_variance_fields"]: AliasType<{
	bonus_amount?:true,
	coin_id?:true,
	id?:true,
	max_amount?:true,
	min_amount?:true,
		__typename?: true
}>;
	/** columns and relationships of "coinsystem.coins" */
["coinsystem_coins"]: AliasType<{
	can_mint?:true,
	coin_decimal?:true,
	coin_logo?:true,
	coin_name?:true,
	coin_symbol?:true,
	created_at?:true,
	default?:true,
	id?:true,
	in_app?:true,
	is_withdrawable?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "coinsystem.coins" */
["coinsystem_coins_aggregate"]: AliasType<{
	aggregate?:ValueTypes["coinsystem_coins_aggregate_fields"],
	nodes?:ValueTypes["coinsystem_coins"],
		__typename?: true
}>;
	/** aggregate fields of "coinsystem.coins" */
["coinsystem_coins_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["coinsystem_coins_avg_fields"],
count?: [{	columns?:ValueTypes["coinsystem_coins_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["coinsystem_coins_max_fields"],
	min?:ValueTypes["coinsystem_coins_min_fields"],
	stddev?:ValueTypes["coinsystem_coins_stddev_fields"],
	stddev_pop?:ValueTypes["coinsystem_coins_stddev_pop_fields"],
	stddev_samp?:ValueTypes["coinsystem_coins_stddev_samp_fields"],
	sum?:ValueTypes["coinsystem_coins_sum_fields"],
	var_pop?:ValueTypes["coinsystem_coins_var_pop_fields"],
	var_samp?:ValueTypes["coinsystem_coins_var_samp_fields"],
	variance?:ValueTypes["coinsystem_coins_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["coinsystem_coins_avg_fields"]: AliasType<{
	coin_decimal?:true,
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "coinsystem.coins". All fields are combined with a logical 'AND'. */
["coinsystem_coins_bool_exp"]: {
	_and?:ValueTypes["coinsystem_coins_bool_exp"][],
	_not?:ValueTypes["coinsystem_coins_bool_exp"],
	_or?:ValueTypes["coinsystem_coins_bool_exp"][],
	can_mint?:ValueTypes["Boolean_comparison_exp"],
	coin_decimal?:ValueTypes["smallint_comparison_exp"],
	coin_logo?:ValueTypes["String_comparison_exp"],
	coin_name?:ValueTypes["String_comparison_exp"],
	coin_symbol?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	default?:ValueTypes["Boolean_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	in_app?:ValueTypes["Boolean_comparison_exp"],
	is_withdrawable?:ValueTypes["Boolean_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "coinsystem.coins" */
["coinsystem_coins_constraint"]:coinsystem_coins_constraint;
	/** input type for incrementing numeric columns in table "coinsystem.coins" */
["coinsystem_coins_inc_input"]: {
	coin_decimal?:ValueTypes["smallint"],
	id?:number
};
	/** input type for inserting data into table "coinsystem.coins" */
["coinsystem_coins_insert_input"]: {
	can_mint?:boolean,
	coin_decimal?:ValueTypes["smallint"],
	coin_logo?:string,
	coin_name?:string,
	coin_symbol?:string,
	created_at?:ValueTypes["timestamptz"],
	default?:boolean,
	id?:number,
	in_app?:boolean,
	is_withdrawable?:boolean,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["coinsystem_coins_max_fields"]: AliasType<{
	coin_decimal?:true,
	coin_logo?:true,
	coin_name?:true,
	coin_symbol?:true,
	created_at?:true,
	id?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["coinsystem_coins_min_fields"]: AliasType<{
	coin_decimal?:true,
	coin_logo?:true,
	coin_name?:true,
	coin_symbol?:true,
	created_at?:true,
	id?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "coinsystem.coins" */
["coinsystem_coins_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["coinsystem_coins"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "coinsystem.coins" */
["coinsystem_coins_obj_rel_insert_input"]: {
	data:ValueTypes["coinsystem_coins_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["coinsystem_coins_on_conflict"]
};
	/** on_conflict condition type for table "coinsystem.coins" */
["coinsystem_coins_on_conflict"]: {
	constraint:ValueTypes["coinsystem_coins_constraint"],
	update_columns:ValueTypes["coinsystem_coins_update_column"][],
	where?:ValueTypes["coinsystem_coins_bool_exp"]
};
	/** Ordering options when selecting data from "coinsystem.coins". */
["coinsystem_coins_order_by"]: {
	can_mint?:ValueTypes["order_by"],
	coin_decimal?:ValueTypes["order_by"],
	coin_logo?:ValueTypes["order_by"],
	coin_name?:ValueTypes["order_by"],
	coin_symbol?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	default?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	in_app?:ValueTypes["order_by"],
	is_withdrawable?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: coinsystem.coins */
["coinsystem_coins_pk_columns_input"]: {
	id:number
};
	/** select columns of table "coinsystem.coins" */
["coinsystem_coins_select_column"]:coinsystem_coins_select_column;
	/** input type for updating data in table "coinsystem.coins" */
["coinsystem_coins_set_input"]: {
	can_mint?:boolean,
	coin_decimal?:ValueTypes["smallint"],
	coin_logo?:string,
	coin_name?:string,
	coin_symbol?:string,
	created_at?:ValueTypes["timestamptz"],
	default?:boolean,
	id?:number,
	in_app?:boolean,
	is_withdrawable?:boolean,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["coinsystem_coins_stddev_fields"]: AliasType<{
	coin_decimal?:true,
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["coinsystem_coins_stddev_pop_fields"]: AliasType<{
	coin_decimal?:true,
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["coinsystem_coins_stddev_samp_fields"]: AliasType<{
	coin_decimal?:true,
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "coinsystem_coins" */
["coinsystem_coins_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["coinsystem_coins_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["coinsystem_coins_stream_cursor_value_input"]: {
	can_mint?:boolean,
	coin_decimal?:ValueTypes["smallint"],
	coin_logo?:string,
	coin_name?:string,
	coin_symbol?:string,
	created_at?:ValueTypes["timestamptz"],
	default?:boolean,
	id?:number,
	in_app?:boolean,
	is_withdrawable?:boolean,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["coinsystem_coins_sum_fields"]: AliasType<{
	coin_decimal?:true,
	id?:true,
		__typename?: true
}>;
	/** update columns of table "coinsystem.coins" */
["coinsystem_coins_update_column"]:coinsystem_coins_update_column;
	["coinsystem_coins_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_coins_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_coins_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["coinsystem_coins_bool_exp"]
};
	/** aggregate var_pop on columns */
["coinsystem_coins_var_pop_fields"]: AliasType<{
	coin_decimal?:true,
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["coinsystem_coins_var_samp_fields"]: AliasType<{
	coin_decimal?:true,
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["coinsystem_coins_variance_fields"]: AliasType<{
	coin_decimal?:true,
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "coinsystem.exchange_rates" */
["coinsystem_exchange_rates"]: AliasType<{
	buy_rate?:true,
	created_at?:true,
	destination_coin_id?:true,
	id?:true,
	sell_rate?:true,
	source_coin_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_aggregate"]: AliasType<{
	aggregate?:ValueTypes["coinsystem_exchange_rates_aggregate_fields"],
	nodes?:ValueTypes["coinsystem_exchange_rates"],
		__typename?: true
}>;
	/** aggregate fields of "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["coinsystem_exchange_rates_avg_fields"],
count?: [{	columns?:ValueTypes["coinsystem_exchange_rates_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["coinsystem_exchange_rates_max_fields"],
	min?:ValueTypes["coinsystem_exchange_rates_min_fields"],
	stddev?:ValueTypes["coinsystem_exchange_rates_stddev_fields"],
	stddev_pop?:ValueTypes["coinsystem_exchange_rates_stddev_pop_fields"],
	stddev_samp?:ValueTypes["coinsystem_exchange_rates_stddev_samp_fields"],
	sum?:ValueTypes["coinsystem_exchange_rates_sum_fields"],
	var_pop?:ValueTypes["coinsystem_exchange_rates_var_pop_fields"],
	var_samp?:ValueTypes["coinsystem_exchange_rates_var_samp_fields"],
	variance?:ValueTypes["coinsystem_exchange_rates_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["coinsystem_exchange_rates_avg_fields"]: AliasType<{
	buy_rate?:true,
	destination_coin_id?:true,
	id?:true,
	sell_rate?:true,
	source_coin_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "coinsystem.exchange_rates". All fields are combined with a logical 'AND'. */
["coinsystem_exchange_rates_bool_exp"]: {
	_and?:ValueTypes["coinsystem_exchange_rates_bool_exp"][],
	_not?:ValueTypes["coinsystem_exchange_rates_bool_exp"],
	_or?:ValueTypes["coinsystem_exchange_rates_bool_exp"][],
	buy_rate?:ValueTypes["numeric_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	destination_coin_id?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	sell_rate?:ValueTypes["numeric_comparison_exp"],
	source_coin_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_constraint"]:coinsystem_exchange_rates_constraint;
	/** input type for incrementing numeric columns in table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_inc_input"]: {
	buy_rate?:ValueTypes["numeric"],
	destination_coin_id?:number,
	id?:number,
	sell_rate?:ValueTypes["numeric"],
	source_coin_id?:number
};
	/** input type for inserting data into table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_insert_input"]: {
	buy_rate?:ValueTypes["numeric"],
	created_at?:ValueTypes["timestamptz"],
	destination_coin_id?:number,
	id?:number,
	sell_rate?:ValueTypes["numeric"],
	source_coin_id?:number
};
	/** aggregate max on columns */
["coinsystem_exchange_rates_max_fields"]: AliasType<{
	buy_rate?:true,
	created_at?:true,
	destination_coin_id?:true,
	id?:true,
	sell_rate?:true,
	source_coin_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["coinsystem_exchange_rates_min_fields"]: AliasType<{
	buy_rate?:true,
	created_at?:true,
	destination_coin_id?:true,
	id?:true,
	sell_rate?:true,
	source_coin_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["coinsystem_exchange_rates"],
		__typename?: true
}>;
	/** on_conflict condition type for table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_on_conflict"]: {
	constraint:ValueTypes["coinsystem_exchange_rates_constraint"],
	update_columns:ValueTypes["coinsystem_exchange_rates_update_column"][],
	where?:ValueTypes["coinsystem_exchange_rates_bool_exp"]
};
	/** Ordering options when selecting data from "coinsystem.exchange_rates". */
["coinsystem_exchange_rates_order_by"]: {
	buy_rate?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	destination_coin_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	sell_rate?:ValueTypes["order_by"],
	source_coin_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: coinsystem.exchange_rates */
["coinsystem_exchange_rates_pk_columns_input"]: {
	id:number
};
	/** select columns of table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_select_column"]:coinsystem_exchange_rates_select_column;
	/** input type for updating data in table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_set_input"]: {
	buy_rate?:ValueTypes["numeric"],
	created_at?:ValueTypes["timestamptz"],
	destination_coin_id?:number,
	id?:number,
	sell_rate?:ValueTypes["numeric"],
	source_coin_id?:number
};
	/** aggregate stddev on columns */
["coinsystem_exchange_rates_stddev_fields"]: AliasType<{
	buy_rate?:true,
	destination_coin_id?:true,
	id?:true,
	sell_rate?:true,
	source_coin_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["coinsystem_exchange_rates_stddev_pop_fields"]: AliasType<{
	buy_rate?:true,
	destination_coin_id?:true,
	id?:true,
	sell_rate?:true,
	source_coin_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["coinsystem_exchange_rates_stddev_samp_fields"]: AliasType<{
	buy_rate?:true,
	destination_coin_id?:true,
	id?:true,
	sell_rate?:true,
	source_coin_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "coinsystem_exchange_rates" */
["coinsystem_exchange_rates_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["coinsystem_exchange_rates_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["coinsystem_exchange_rates_stream_cursor_value_input"]: {
	buy_rate?:ValueTypes["numeric"],
	created_at?:ValueTypes["timestamptz"],
	destination_coin_id?:number,
	id?:number,
	sell_rate?:ValueTypes["numeric"],
	source_coin_id?:number
};
	/** aggregate sum on columns */
["coinsystem_exchange_rates_sum_fields"]: AliasType<{
	buy_rate?:true,
	destination_coin_id?:true,
	id?:true,
	sell_rate?:true,
	source_coin_id?:true,
		__typename?: true
}>;
	/** update columns of table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_update_column"]:coinsystem_exchange_rates_update_column;
	["coinsystem_exchange_rates_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_exchange_rates_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_exchange_rates_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["coinsystem_exchange_rates_bool_exp"]
};
	/** aggregate var_pop on columns */
["coinsystem_exchange_rates_var_pop_fields"]: AliasType<{
	buy_rate?:true,
	destination_coin_id?:true,
	id?:true,
	sell_rate?:true,
	source_coin_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["coinsystem_exchange_rates_var_samp_fields"]: AliasType<{
	buy_rate?:true,
	destination_coin_id?:true,
	id?:true,
	sell_rate?:true,
	source_coin_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["coinsystem_exchange_rates_variance_fields"]: AliasType<{
	buy_rate?:true,
	destination_coin_id?:true,
	id?:true,
	sell_rate?:true,
	source_coin_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "coinsystem.logs" */
["coinsystem_logs"]: AliasType<{
	created_at?:true,
	description?:true,
	id?:true,
	transaction_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "coinsystem.logs" */
["coinsystem_logs_aggregate"]: AliasType<{
	aggregate?:ValueTypes["coinsystem_logs_aggregate_fields"],
	nodes?:ValueTypes["coinsystem_logs"],
		__typename?: true
}>;
	/** aggregate fields of "coinsystem.logs" */
["coinsystem_logs_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["coinsystem_logs_avg_fields"],
count?: [{	columns?:ValueTypes["coinsystem_logs_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["coinsystem_logs_max_fields"],
	min?:ValueTypes["coinsystem_logs_min_fields"],
	stddev?:ValueTypes["coinsystem_logs_stddev_fields"],
	stddev_pop?:ValueTypes["coinsystem_logs_stddev_pop_fields"],
	stddev_samp?:ValueTypes["coinsystem_logs_stddev_samp_fields"],
	sum?:ValueTypes["coinsystem_logs_sum_fields"],
	var_pop?:ValueTypes["coinsystem_logs_var_pop_fields"],
	var_samp?:ValueTypes["coinsystem_logs_var_samp_fields"],
	variance?:ValueTypes["coinsystem_logs_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["coinsystem_logs_avg_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "coinsystem.logs". All fields are combined with a logical 'AND'. */
["coinsystem_logs_bool_exp"]: {
	_and?:ValueTypes["coinsystem_logs_bool_exp"][],
	_not?:ValueTypes["coinsystem_logs_bool_exp"],
	_or?:ValueTypes["coinsystem_logs_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	description?:ValueTypes["bpchar_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	transaction_id?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "coinsystem.logs" */
["coinsystem_logs_constraint"]:coinsystem_logs_constraint;
	/** input type for incrementing numeric columns in table "coinsystem.logs" */
["coinsystem_logs_inc_input"]: {
	id?:number
};
	/** input type for inserting data into table "coinsystem.logs" */
["coinsystem_logs_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	description?:ValueTypes["bpchar"],
	id?:number,
	transaction_id?:string
};
	/** aggregate max on columns */
["coinsystem_logs_max_fields"]: AliasType<{
	created_at?:true,
	description?:true,
	id?:true,
	transaction_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["coinsystem_logs_min_fields"]: AliasType<{
	created_at?:true,
	description?:true,
	id?:true,
	transaction_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "coinsystem.logs" */
["coinsystem_logs_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["coinsystem_logs"],
		__typename?: true
}>;
	/** on_conflict condition type for table "coinsystem.logs" */
["coinsystem_logs_on_conflict"]: {
	constraint:ValueTypes["coinsystem_logs_constraint"],
	update_columns:ValueTypes["coinsystem_logs_update_column"][],
	where?:ValueTypes["coinsystem_logs_bool_exp"]
};
	/** Ordering options when selecting data from "coinsystem.logs". */
["coinsystem_logs_order_by"]: {
	created_at?:ValueTypes["order_by"],
	description?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	transaction_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: coinsystem.logs */
["coinsystem_logs_pk_columns_input"]: {
	id:number
};
	/** select columns of table "coinsystem.logs" */
["coinsystem_logs_select_column"]:coinsystem_logs_select_column;
	/** input type for updating data in table "coinsystem.logs" */
["coinsystem_logs_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	description?:ValueTypes["bpchar"],
	id?:number,
	transaction_id?:string
};
	/** aggregate stddev on columns */
["coinsystem_logs_stddev_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["coinsystem_logs_stddev_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["coinsystem_logs_stddev_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "coinsystem_logs" */
["coinsystem_logs_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["coinsystem_logs_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["coinsystem_logs_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	description?:ValueTypes["bpchar"],
	id?:number,
	transaction_id?:string
};
	/** aggregate sum on columns */
["coinsystem_logs_sum_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** update columns of table "coinsystem.logs" */
["coinsystem_logs_update_column"]:coinsystem_logs_update_column;
	["coinsystem_logs_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_logs_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_logs_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["coinsystem_logs_bool_exp"]
};
	/** aggregate var_pop on columns */
["coinsystem_logs_var_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["coinsystem_logs_var_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["coinsystem_logs_variance_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "coinsystem.transactions" */
["coinsystem_transactions"]: AliasType<{
	coin_amount?:true,
	coin_id?:true,
	created_at?:true,
	description?:true,
	id?:true,
	recipient_id?:true,
	recipient_wallet_address?:true,
	sender_id?:true,
	sender_wallet_address?:true,
	transaction_id?:true,
	transaction_type?:true,
		__typename?: true
}>;
	/** aggregated selection of "coinsystem.transactions" */
["coinsystem_transactions_aggregate"]: AliasType<{
	aggregate?:ValueTypes["coinsystem_transactions_aggregate_fields"],
	nodes?:ValueTypes["coinsystem_transactions"],
		__typename?: true
}>;
	/** aggregate fields of "coinsystem.transactions" */
["coinsystem_transactions_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["coinsystem_transactions_avg_fields"],
count?: [{	columns?:ValueTypes["coinsystem_transactions_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["coinsystem_transactions_max_fields"],
	min?:ValueTypes["coinsystem_transactions_min_fields"],
	stddev?:ValueTypes["coinsystem_transactions_stddev_fields"],
	stddev_pop?:ValueTypes["coinsystem_transactions_stddev_pop_fields"],
	stddev_samp?:ValueTypes["coinsystem_transactions_stddev_samp_fields"],
	sum?:ValueTypes["coinsystem_transactions_sum_fields"],
	var_pop?:ValueTypes["coinsystem_transactions_var_pop_fields"],
	var_samp?:ValueTypes["coinsystem_transactions_var_samp_fields"],
	variance?:ValueTypes["coinsystem_transactions_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["coinsystem_transactions_avg_fields"]: AliasType<{
	coin_amount?:true,
	coin_id?:true,
	id?:true,
	recipient_id?:true,
	sender_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "coinsystem.transactions". All fields are combined with a logical 'AND'. */
["coinsystem_transactions_bool_exp"]: {
	_and?:ValueTypes["coinsystem_transactions_bool_exp"][],
	_not?:ValueTypes["coinsystem_transactions_bool_exp"],
	_or?:ValueTypes["coinsystem_transactions_bool_exp"][],
	coin_amount?:ValueTypes["numeric_comparison_exp"],
	coin_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	description?:ValueTypes["bpchar_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	recipient_id?:ValueTypes["Int_comparison_exp"],
	recipient_wallet_address?:ValueTypes["String_comparison_exp"],
	sender_id?:ValueTypes["Int_comparison_exp"],
	sender_wallet_address?:ValueTypes["String_comparison_exp"],
	transaction_id?:ValueTypes["String_comparison_exp"],
	transaction_type?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "coinsystem.transactions" */
["coinsystem_transactions_constraint"]:coinsystem_transactions_constraint;
	/** input type for incrementing numeric columns in table "coinsystem.transactions" */
["coinsystem_transactions_inc_input"]: {
	coin_amount?:ValueTypes["numeric"],
	coin_id?:number,
	id?:number,
	recipient_id?:number,
	sender_id?:number
};
	/** input type for inserting data into table "coinsystem.transactions" */
["coinsystem_transactions_insert_input"]: {
	coin_amount?:ValueTypes["numeric"],
	coin_id?:number,
	created_at?:ValueTypes["timestamptz"],
	description?:ValueTypes["bpchar"],
	id?:number,
	recipient_id?:number,
	recipient_wallet_address?:string,
	sender_id?:number,
	sender_wallet_address?:string,
	transaction_id?:string,
	transaction_type?:string
};
	/** aggregate max on columns */
["coinsystem_transactions_max_fields"]: AliasType<{
	coin_amount?:true,
	coin_id?:true,
	created_at?:true,
	description?:true,
	id?:true,
	recipient_id?:true,
	recipient_wallet_address?:true,
	sender_id?:true,
	sender_wallet_address?:true,
	transaction_id?:true,
	transaction_type?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["coinsystem_transactions_min_fields"]: AliasType<{
	coin_amount?:true,
	coin_id?:true,
	created_at?:true,
	description?:true,
	id?:true,
	recipient_id?:true,
	recipient_wallet_address?:true,
	sender_id?:true,
	sender_wallet_address?:true,
	transaction_id?:true,
	transaction_type?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "coinsystem.transactions" */
["coinsystem_transactions_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["coinsystem_transactions"],
		__typename?: true
}>;
	/** on_conflict condition type for table "coinsystem.transactions" */
["coinsystem_transactions_on_conflict"]: {
	constraint:ValueTypes["coinsystem_transactions_constraint"],
	update_columns:ValueTypes["coinsystem_transactions_update_column"][],
	where?:ValueTypes["coinsystem_transactions_bool_exp"]
};
	/** Ordering options when selecting data from "coinsystem.transactions". */
["coinsystem_transactions_order_by"]: {
	coin_amount?:ValueTypes["order_by"],
	coin_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	description?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	recipient_id?:ValueTypes["order_by"],
	recipient_wallet_address?:ValueTypes["order_by"],
	sender_id?:ValueTypes["order_by"],
	sender_wallet_address?:ValueTypes["order_by"],
	transaction_id?:ValueTypes["order_by"],
	transaction_type?:ValueTypes["order_by"]
};
	/** primary key columns input for table: coinsystem.transactions */
["coinsystem_transactions_pk_columns_input"]: {
	id:number
};
	/** select columns of table "coinsystem.transactions" */
["coinsystem_transactions_select_column"]:coinsystem_transactions_select_column;
	/** input type for updating data in table "coinsystem.transactions" */
["coinsystem_transactions_set_input"]: {
	coin_amount?:ValueTypes["numeric"],
	coin_id?:number,
	created_at?:ValueTypes["timestamptz"],
	description?:ValueTypes["bpchar"],
	id?:number,
	recipient_id?:number,
	recipient_wallet_address?:string,
	sender_id?:number,
	sender_wallet_address?:string,
	transaction_id?:string,
	transaction_type?:string
};
	/** aggregate stddev on columns */
["coinsystem_transactions_stddev_fields"]: AliasType<{
	coin_amount?:true,
	coin_id?:true,
	id?:true,
	recipient_id?:true,
	sender_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["coinsystem_transactions_stddev_pop_fields"]: AliasType<{
	coin_amount?:true,
	coin_id?:true,
	id?:true,
	recipient_id?:true,
	sender_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["coinsystem_transactions_stddev_samp_fields"]: AliasType<{
	coin_amount?:true,
	coin_id?:true,
	id?:true,
	recipient_id?:true,
	sender_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "coinsystem_transactions" */
["coinsystem_transactions_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["coinsystem_transactions_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["coinsystem_transactions_stream_cursor_value_input"]: {
	coin_amount?:ValueTypes["numeric"],
	coin_id?:number,
	created_at?:ValueTypes["timestamptz"],
	description?:ValueTypes["bpchar"],
	id?:number,
	recipient_id?:number,
	recipient_wallet_address?:string,
	sender_id?:number,
	sender_wallet_address?:string,
	transaction_id?:string,
	transaction_type?:string
};
	/** aggregate sum on columns */
["coinsystem_transactions_sum_fields"]: AliasType<{
	coin_amount?:true,
	coin_id?:true,
	id?:true,
	recipient_id?:true,
	sender_id?:true,
		__typename?: true
}>;
	/** update columns of table "coinsystem.transactions" */
["coinsystem_transactions_update_column"]:coinsystem_transactions_update_column;
	["coinsystem_transactions_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_transactions_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_transactions_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["coinsystem_transactions_bool_exp"]
};
	/** aggregate var_pop on columns */
["coinsystem_transactions_var_pop_fields"]: AliasType<{
	coin_amount?:true,
	coin_id?:true,
	id?:true,
	recipient_id?:true,
	sender_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["coinsystem_transactions_var_samp_fields"]: AliasType<{
	coin_amount?:true,
	coin_id?:true,
	id?:true,
	recipient_id?:true,
	sender_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["coinsystem_transactions_variance_fields"]: AliasType<{
	coin_amount?:true,
	coin_id?:true,
	id?:true,
	recipient_id?:true,
	sender_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "coinsystem.wallets" */
["coinsystem_wallets"]: AliasType<{
	can_mint?:true,
	coin_id?:true,
	created_at?:true,
	default?:true,
	id?:true,
	is_vm?:true,
	tag?:true,
	updated_at?:true,
	user_id?:true,
	wallet_address?:true,
		__typename?: true
}>;
	/** aggregated selection of "coinsystem.wallets" */
["coinsystem_wallets_aggregate"]: AliasType<{
	aggregate?:ValueTypes["coinsystem_wallets_aggregate_fields"],
	nodes?:ValueTypes["coinsystem_wallets"],
		__typename?: true
}>;
	/** aggregate fields of "coinsystem.wallets" */
["coinsystem_wallets_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["coinsystem_wallets_avg_fields"],
count?: [{	columns?:ValueTypes["coinsystem_wallets_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["coinsystem_wallets_max_fields"],
	min?:ValueTypes["coinsystem_wallets_min_fields"],
	stddev?:ValueTypes["coinsystem_wallets_stddev_fields"],
	stddev_pop?:ValueTypes["coinsystem_wallets_stddev_pop_fields"],
	stddev_samp?:ValueTypes["coinsystem_wallets_stddev_samp_fields"],
	sum?:ValueTypes["coinsystem_wallets_sum_fields"],
	var_pop?:ValueTypes["coinsystem_wallets_var_pop_fields"],
	var_samp?:ValueTypes["coinsystem_wallets_var_samp_fields"],
	variance?:ValueTypes["coinsystem_wallets_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["coinsystem_wallets_avg_fields"]: AliasType<{
	coin_id?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "coinsystem.wallets". All fields are combined with a logical 'AND'. */
["coinsystem_wallets_bool_exp"]: {
	_and?:ValueTypes["coinsystem_wallets_bool_exp"][],
	_not?:ValueTypes["coinsystem_wallets_bool_exp"],
	_or?:ValueTypes["coinsystem_wallets_bool_exp"][],
	can_mint?:ValueTypes["Boolean_comparison_exp"],
	coin_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	default?:ValueTypes["Boolean_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_vm?:ValueTypes["Boolean_comparison_exp"],
	tag?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	wallet_address?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "coinsystem.wallets" */
["coinsystem_wallets_constraint"]:coinsystem_wallets_constraint;
	/** input type for incrementing numeric columns in table "coinsystem.wallets" */
["coinsystem_wallets_inc_input"]: {
	coin_id?:number,
	id?:number,
	user_id?:number
};
	/** input type for inserting data into table "coinsystem.wallets" */
["coinsystem_wallets_insert_input"]: {
	can_mint?:boolean,
	coin_id?:number,
	created_at?:ValueTypes["timestamptz"],
	default?:boolean,
	id?:number,
	is_vm?:boolean,
	tag?:string,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number,
	wallet_address?:string
};
	/** aggregate max on columns */
["coinsystem_wallets_max_fields"]: AliasType<{
	coin_id?:true,
	created_at?:true,
	id?:true,
	tag?:true,
	updated_at?:true,
	user_id?:true,
	wallet_address?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["coinsystem_wallets_min_fields"]: AliasType<{
	coin_id?:true,
	created_at?:true,
	id?:true,
	tag?:true,
	updated_at?:true,
	user_id?:true,
	wallet_address?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "coinsystem.wallets" */
["coinsystem_wallets_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["coinsystem_wallets"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "coinsystem.wallets" */
["coinsystem_wallets_obj_rel_insert_input"]: {
	data:ValueTypes["coinsystem_wallets_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["coinsystem_wallets_on_conflict"]
};
	/** on_conflict condition type for table "coinsystem.wallets" */
["coinsystem_wallets_on_conflict"]: {
	constraint:ValueTypes["coinsystem_wallets_constraint"],
	update_columns:ValueTypes["coinsystem_wallets_update_column"][],
	where?:ValueTypes["coinsystem_wallets_bool_exp"]
};
	/** Ordering options when selecting data from "coinsystem.wallets". */
["coinsystem_wallets_order_by"]: {
	can_mint?:ValueTypes["order_by"],
	coin_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	default?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_vm?:ValueTypes["order_by"],
	tag?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	wallet_address?:ValueTypes["order_by"]
};
	/** primary key columns input for table: coinsystem.wallets */
["coinsystem_wallets_pk_columns_input"]: {
	id:number
};
	/** select columns of table "coinsystem.wallets" */
["coinsystem_wallets_select_column"]:coinsystem_wallets_select_column;
	/** input type for updating data in table "coinsystem.wallets" */
["coinsystem_wallets_set_input"]: {
	can_mint?:boolean,
	coin_id?:number,
	created_at?:ValueTypes["timestamptz"],
	default?:boolean,
	id?:number,
	is_vm?:boolean,
	tag?:string,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number,
	wallet_address?:string
};
	/** aggregate stddev on columns */
["coinsystem_wallets_stddev_fields"]: AliasType<{
	coin_id?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["coinsystem_wallets_stddev_pop_fields"]: AliasType<{
	coin_id?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["coinsystem_wallets_stddev_samp_fields"]: AliasType<{
	coin_id?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "coinsystem_wallets" */
["coinsystem_wallets_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["coinsystem_wallets_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["coinsystem_wallets_stream_cursor_value_input"]: {
	can_mint?:boolean,
	coin_id?:number,
	created_at?:ValueTypes["timestamptz"],
	default?:boolean,
	id?:number,
	is_vm?:boolean,
	tag?:string,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number,
	wallet_address?:string
};
	/** aggregate sum on columns */
["coinsystem_wallets_sum_fields"]: AliasType<{
	coin_id?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "coinsystem.wallets" */
["coinsystem_wallets_update_column"]:coinsystem_wallets_update_column;
	["coinsystem_wallets_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_wallets_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_wallets_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["coinsystem_wallets_bool_exp"]
};
	/** aggregate var_pop on columns */
["coinsystem_wallets_var_pop_fields"]: AliasType<{
	coin_id?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["coinsystem_wallets_var_samp_fields"]: AliasType<{
	coin_id?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["coinsystem_wallets_variance_fields"]: AliasType<{
	coin_id?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** ordering argument of a cursor */
["cursor_ordering"]:cursor_ordering;
	["date"]:unknown;
	/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
["date_comparison_exp"]: {
	_eq?:ValueTypes["date"],
	_gt?:ValueTypes["date"],
	_gte?:ValueTypes["date"],
	_in?:ValueTypes["date"][],
	_is_null?:boolean,
	_lt?:ValueTypes["date"],
	_lte?:ValueTypes["date"],
	_neq?:ValueTypes["date"],
	_nin?:ValueTypes["date"][]
};
	["float8"]:unknown;
	/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
["float8_comparison_exp"]: {
	_eq?:ValueTypes["float8"],
	_gt?:ValueTypes["float8"],
	_gte?:ValueTypes["float8"],
	_in?:ValueTypes["float8"][],
	_is_null?:boolean,
	_lt?:ValueTypes["float8"],
	_lte?:ValueTypes["float8"],
	_neq?:ValueTypes["float8"],
	_nin?:ValueTypes["float8"][]
};
	["json"]:unknown;
	/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
["json_comparison_exp"]: {
	_eq?:ValueTypes["json"],
	_gt?:ValueTypes["json"],
	_gte?:ValueTypes["json"],
	_in?:ValueTypes["json"][],
	_is_null?:boolean,
	_lt?:ValueTypes["json"],
	_lte?:ValueTypes["json"],
	_neq?:ValueTypes["json"],
	_nin?:ValueTypes["json"][]
};
	["jsonb"]:unknown;
	["jsonb_cast_exp"]: {
	String?:ValueTypes["String_comparison_exp"]
};
	/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
["jsonb_comparison_exp"]: {
	_cast?:ValueTypes["jsonb_cast_exp"],
	/** is the column contained in the given json value */
	_contained_in?:ValueTypes["jsonb"],
	/** does the column contain the given json value at the top level */
	_contains?:ValueTypes["jsonb"],
	_eq?:ValueTypes["jsonb"],
	_gt?:ValueTypes["jsonb"],
	_gte?:ValueTypes["jsonb"],
	/** does the string exist as a top-level key in the column */
	_has_key?:string,
	/** do all of these strings exist as top-level keys in the column */
	_has_keys_all?:string[],
	/** do any of these strings exist as top-level keys in the column */
	_has_keys_any?:string[],
	_in?:ValueTypes["jsonb"][],
	_is_null?:boolean,
	_lt?:ValueTypes["jsonb"],
	_lte?:ValueTypes["jsonb"],
	_neq?:ValueTypes["jsonb"],
	_nin?:ValueTypes["jsonb"][]
};
	/** mutation root */
["mutation_root"]: AliasType<{
delete_awsdms_validation_failures_v1?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["awsdms_validation_failures_v1_bool_exp"]},ValueTypes["awsdms_validation_failures_v1_mutation_response"]],
delete_coinsystem_balances?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["coinsystem_balances_bool_exp"]},ValueTypes["coinsystem_balances_mutation_response"]],
delete_coinsystem_balances_by_pk?: [{	id:number},ValueTypes["coinsystem_balances"]],
delete_coinsystem_bonus_coins?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["coinsystem_bonus_coins_bool_exp"]},ValueTypes["coinsystem_bonus_coins_mutation_response"]],
delete_coinsystem_bonus_coins_by_pk?: [{	id:number},ValueTypes["coinsystem_bonus_coins"]],
delete_coinsystem_coins?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["coinsystem_coins_bool_exp"]},ValueTypes["coinsystem_coins_mutation_response"]],
delete_coinsystem_coins_by_pk?: [{	id:number},ValueTypes["coinsystem_coins"]],
delete_coinsystem_exchange_rates?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["coinsystem_exchange_rates_bool_exp"]},ValueTypes["coinsystem_exchange_rates_mutation_response"]],
delete_coinsystem_exchange_rates_by_pk?: [{	id:number},ValueTypes["coinsystem_exchange_rates"]],
delete_coinsystem_logs?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["coinsystem_logs_bool_exp"]},ValueTypes["coinsystem_logs_mutation_response"]],
delete_coinsystem_logs_by_pk?: [{	id:number},ValueTypes["coinsystem_logs"]],
delete_coinsystem_transactions?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["coinsystem_transactions_bool_exp"]},ValueTypes["coinsystem_transactions_mutation_response"]],
delete_coinsystem_transactions_by_pk?: [{	id:number},ValueTypes["coinsystem_transactions"]],
delete_coinsystem_wallets?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["coinsystem_wallets_bool_exp"]},ValueTypes["coinsystem_wallets_mutation_response"]],
delete_coinsystem_wallets_by_pk?: [{	id:number},ValueTypes["coinsystem_wallets"]],
delete_mysql_user?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["mysql_user_bool_exp"]},ValueTypes["mysql_user_mutation_response"]],
delete_mysql_user_by_pk?: [{	host:string,	user:string},ValueTypes["mysql_user"]],
delete_store_payments?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["store_payments_bool_exp"]},ValueTypes["store_payments_mutation_response"]],
delete_store_payments_by_pk?: [{	id:ValueTypes["uuid"],	purchase_id:string},ValueTypes["store_payments"]],
delete_store_products?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["store_products_bool_exp"]},ValueTypes["store_products_mutation_response"]],
delete_store_products_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["store_products"]],
delete_store_purchases?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["store_purchases_bool_exp"]},ValueTypes["store_purchases_mutation_response"]],
delete_store_purchases_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["store_purchases"]],
delete_store_users?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["store_users_bool_exp"]},ValueTypes["store_users_mutation_response"]],
delete_voyce_admin_roles?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_admin_roles_bool_exp"]},ValueTypes["voyce_admin_roles_mutation_response"]],
delete_voyce_admin_roles_by_pk?: [{	id:number},ValueTypes["voyce_admin_roles"]],
delete_voyce_app_banners?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_app_banners_bool_exp"]},ValueTypes["voyce_app_banners_mutation_response"]],
delete_voyce_app_banners_by_pk?: [{	id:number},ValueTypes["voyce_app_banners"]],
delete_voyce_app_components?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_app_components_bool_exp"]},ValueTypes["voyce_app_components_mutation_response"]],
delete_voyce_app_components_by_pk?: [{	id:number},ValueTypes["voyce_app_components"]],
delete_voyce_audit_trail?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_audit_trail_bool_exp"]},ValueTypes["voyce_audit_trail_mutation_response"]],
delete_voyce_audit_trail_by_pk?: [{	id:number},ValueTypes["voyce_audit_trail"]],
delete_voyce_author_profiles?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_author_profiles_bool_exp"]},ValueTypes["voyce_author_profiles_mutation_response"]],
delete_voyce_author_profiles_by_pk?: [{	id:number},ValueTypes["voyce_author_profiles"]],
delete_voyce_balances?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_balances_bool_exp"]},ValueTypes["voyce_balances_mutation_response"]],
delete_voyce_balances_by_pk?: [{	id:number},ValueTypes["voyce_balances"]],
delete_voyce_banners?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_banners_bool_exp"]},ValueTypes["voyce_banners_mutation_response"]],
delete_voyce_banners_by_pk?: [{	id:number},ValueTypes["voyce_banners"]],
delete_voyce_chapter_assets?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_assets_bool_exp"]},ValueTypes["voyce_chapter_assets_mutation_response"]],
delete_voyce_chapter_assets_by_pk?: [{	id:number},ValueTypes["voyce_chapter_assets"]],
delete_voyce_chapter_bookmarks?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_bookmarks_bool_exp"]},ValueTypes["voyce_chapter_bookmarks_mutation_response"]],
delete_voyce_chapter_bookmarks_by_pk?: [{	chapter_id:number,	user_id:number},ValueTypes["voyce_chapter_bookmarks"]],
delete_voyce_chapter_comments?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_comments_bool_exp"]},ValueTypes["voyce_chapter_comments_mutation_response"]],
delete_voyce_chapter_comments_07252024?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_comments_07252024_bool_exp"]},ValueTypes["voyce_chapter_comments_07252024_mutation_response"]],
delete_voyce_chapter_comments_by_pk?: [{	id:number},ValueTypes["voyce_chapter_comments"]],
delete_voyce_chapter_comments_reaction?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_comments_reaction_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction_mutation_response"]],
delete_voyce_chapter_comments_reaction_by_pk?: [{	id:number},ValueTypes["voyce_chapter_comments_reaction"]],
delete_voyce_chapter_comments_reaction_count?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_comments_reaction_count_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction_count_mutation_response"]],
delete_voyce_chapter_comments_reaction_count_by_pk?: [{	id:number},ValueTypes["voyce_chapter_comments_reaction_count"]],
delete_voyce_chapter_comments_reports?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_comments_reports_bool_exp"]},ValueTypes["voyce_chapter_comments_reports_mutation_response"]],
delete_voyce_chapter_comments_reports_by_pk?: [{	id:number},ValueTypes["voyce_chapter_comments_reports"]],
delete_voyce_chapter_contents?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_contents_bool_exp"]},ValueTypes["voyce_chapter_contents_mutation_response"]],
delete_voyce_chapter_contents_by_pk?: [{	id:number},ValueTypes["voyce_chapter_contents"]],
delete_voyce_chapter_contents_old?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_contents_old_bool_exp"]},ValueTypes["voyce_chapter_contents_old_mutation_response"]],
delete_voyce_chapter_contents_old_by_pk?: [{	id:number},ValueTypes["voyce_chapter_contents_old"]],
delete_voyce_chapter_effects?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_effects_bool_exp"]},ValueTypes["voyce_chapter_effects_mutation_response"]],
delete_voyce_chapter_effects_by_pk?: [{	id:number},ValueTypes["voyce_chapter_effects"]],
delete_voyce_chapter_images?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_images_bool_exp"]},ValueTypes["voyce_chapter_images_mutation_response"]],
delete_voyce_chapter_images_07252024?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_images_07252024_bool_exp"]},ValueTypes["voyce_chapter_images_07252024_mutation_response"]],
delete_voyce_chapter_images_by_pk?: [{	id:number},ValueTypes["voyce_chapter_images"]],
delete_voyce_chapter_likes?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_likes_bool_exp"]},ValueTypes["voyce_chapter_likes_mutation_response"]],
delete_voyce_chapter_likes_by_pk?: [{	chapter_id:number,	created_at:ValueTypes["timestamptz"]},ValueTypes["voyce_chapter_likes"]],
delete_voyce_chapter_locks?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_locks_bool_exp"]},ValueTypes["voyce_chapter_locks_mutation_response"]],
delete_voyce_chapter_locks_by_pk?: [{	id:number},ValueTypes["voyce_chapter_locks"]],
delete_voyce_chapter_panel_views?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_panel_views_bool_exp"]},ValueTypes["voyce_chapter_panel_views_mutation_response"]],
delete_voyce_chapter_panel_views_by_pk?: [{	id:number},ValueTypes["voyce_chapter_panel_views"]],
delete_voyce_chapter_panels?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_panels_bool_exp"]},ValueTypes["voyce_chapter_panels_mutation_response"]],
delete_voyce_chapter_panels_by_pk?: [{	id:number},ValueTypes["voyce_chapter_panels"]],
delete_voyce_chapter_publish_schedules?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_publish_schedules_bool_exp"]},ValueTypes["voyce_chapter_publish_schedules_mutation_response"]],
delete_voyce_chapter_publish_schedules_by_pk?: [{	id:number},ValueTypes["voyce_chapter_publish_schedules"]],
delete_voyce_chapter_reactions?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_reactions_bool_exp"]},ValueTypes["voyce_chapter_reactions_mutation_response"]],
delete_voyce_chapter_reactions_by_pk?: [{	id:number},ValueTypes["voyce_chapter_reactions"]],
delete_voyce_chapter_reactions_count?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_reactions_count_bool_exp"]},ValueTypes["voyce_chapter_reactions_count_mutation_response"]],
delete_voyce_chapter_reactions_count_by_pk?: [{	id:number},ValueTypes["voyce_chapter_reactions_count"]],
delete_voyce_chapter_view_logs?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_view_logs_bool_exp"]},ValueTypes["voyce_chapter_view_logs_mutation_response"]],
delete_voyce_chapter_view_logs_by_pk?: [{	id:number},ValueTypes["voyce_chapter_view_logs"]],
delete_voyce_chapter_views?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapter_views_bool_exp"]},ValueTypes["voyce_chapter_views_mutation_response"]],
delete_voyce_chapter_views_by_pk?: [{	id:number},ValueTypes["voyce_chapter_views"]],
delete_voyce_chapters?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapters_bool_exp"]},ValueTypes["voyce_chapters_mutation_response"]],
delete_voyce_chapters_07252024?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_chapters_07252024_bool_exp"]},ValueTypes["voyce_chapters_07252024_mutation_response"]],
delete_voyce_chapters_by_pk?: [{	id:number},ValueTypes["voyce_chapters"]],
delete_voyce_collaborator_roles?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_collaborator_roles_bool_exp"]},ValueTypes["voyce_collaborator_roles_mutation_response"]],
delete_voyce_collaborator_roles_by_pk?: [{	id:number},ValueTypes["voyce_collaborator_roles"]],
delete_voyce_comic_types?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_comic_types_bool_exp"]},ValueTypes["voyce_comic_types_mutation_response"]],
delete_voyce_comic_types_by_pk?: [{	id:number},ValueTypes["voyce_comic_types"]],
delete_voyce_community_blurbs?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_community_blurbs_bool_exp"]},ValueTypes["voyce_community_blurbs_mutation_response"]],
delete_voyce_community_blurbs_by_pk?: [{	id:number},ValueTypes["voyce_community_blurbs"]],
delete_voyce_contact_us?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_contact_us_bool_exp"]},ValueTypes["voyce_contact_us_mutation_response"]],
delete_voyce_contact_us_by_pk?: [{	id:number},ValueTypes["voyce_contact_us"]],
delete_voyce_content_report?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_content_report_bool_exp"]},ValueTypes["voyce_content_report_mutation_response"]],
delete_voyce_content_report_by_pk?: [{	id:number},ValueTypes["voyce_content_report"]],
delete_voyce_contest_data?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_contest_data_bool_exp"]},ValueTypes["voyce_contest_data_mutation_response"]],
delete_voyce_contest_data_by_pk?: [{	id:number},ValueTypes["voyce_contest_data"]],
delete_voyce_countries?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_countries_bool_exp"]},ValueTypes["voyce_countries_mutation_response"]],
delete_voyce_countries_by_pk?: [{	id:number},ValueTypes["voyce_countries"]],
delete_voyce_csat_ratings?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_csat_ratings_bool_exp"]},ValueTypes["voyce_csat_ratings_mutation_response"]],
delete_voyce_csat_ratings_by_pk?: [{	id:number},ValueTypes["voyce_csat_ratings"]],
delete_voyce_csat_ratings_data?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_csat_ratings_data_bool_exp"]},ValueTypes["voyce_csat_ratings_data_mutation_response"]],
delete_voyce_csat_ratings_data_by_pk?: [{	id:number},ValueTypes["voyce_csat_ratings_data"]],
delete_voyce_education_types?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_education_types_bool_exp"]},ValueTypes["voyce_education_types_mutation_response"]],
delete_voyce_education_types_by_pk?: [{	id:number},ValueTypes["voyce_education_types"]],
delete_voyce_erp_login_roles?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_erp_login_roles_bool_exp"]},ValueTypes["voyce_erp_login_roles_mutation_response"]],
delete_voyce_erp_login_roles_by_pk?: [{	id:number},ValueTypes["voyce_erp_login_roles"]],
delete_voyce_etl_series_views_logs?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_etl_series_views_logs_bool_exp"]},ValueTypes["voyce_etl_series_views_logs_mutation_response"]],
delete_voyce_etl_series_views_logs_by_pk?: [{	id:number},ValueTypes["voyce_etl_series_views_logs"]],
delete_voyce_event_schedules?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_event_schedules_bool_exp"]},ValueTypes["voyce_event_schedules_mutation_response"]],
delete_voyce_event_schedules_by_pk?: [{	id:number},ValueTypes["voyce_event_schedules"]],
delete_voyce_genres?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_genres_bool_exp"]},ValueTypes["voyce_genres_mutation_response"]],
delete_voyce_genres_by_pk?: [{	id:number},ValueTypes["voyce_genres"]],
delete_voyce_guest_gtags?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_guest_gtags_bool_exp"]},ValueTypes["voyce_guest_gtags_mutation_response"]],
delete_voyce_guest_gtags_by_pk?: [{	id:number},ValueTypes["voyce_guest_gtags"]],
delete_voyce_metabases?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_metabases_bool_exp"]},ValueTypes["voyce_metabases_mutation_response"]],
delete_voyce_metabases_access?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_metabases_access_bool_exp"]},ValueTypes["voyce_metabases_access_mutation_response"]],
delete_voyce_metabases_access_by_pk?: [{	id:number},ValueTypes["voyce_metabases_access"]],
delete_voyce_metabases_by_pk?: [{	id:number},ValueTypes["voyce_metabases"]],
delete_voyce_newsletter?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_newsletter_bool_exp"]},ValueTypes["voyce_newsletter_mutation_response"]],
delete_voyce_newsletter_by_pk?: [{	email:string},ValueTypes["voyce_newsletter"]],
delete_voyce_notification_series_settings?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_notification_series_settings_bool_exp"]},ValueTypes["voyce_notification_series_settings_mutation_response"]],
delete_voyce_notification_series_settings_by_pk?: [{	id:number},ValueTypes["voyce_notification_series_settings"]],
delete_voyce_notification_settings?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_notification_settings_bool_exp"]},ValueTypes["voyce_notification_settings_mutation_response"]],
delete_voyce_notification_settings_by_pk?: [{	id:number},ValueTypes["voyce_notification_settings"]],
delete_voyce_notifications?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_notifications_bool_exp"]},ValueTypes["voyce_notifications_mutation_response"]],
delete_voyce_notifications_by_pk?: [{	id:number},ValueTypes["voyce_notifications"]],
delete_voyce_page_banners?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_page_banners_bool_exp"]},ValueTypes["voyce_page_banners_mutation_response"]],
delete_voyce_page_banners_by_pk?: [{	id:number},ValueTypes["voyce_page_banners"]],
delete_voyce_page_components?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_page_components_bool_exp"]},ValueTypes["voyce_page_components_mutation_response"]],
delete_voyce_page_components_by_pk?: [{	id:number},ValueTypes["voyce_page_components"]],
delete_voyce_pages?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_pages_bool_exp"]},ValueTypes["voyce_pages_mutation_response"]],
delete_voyce_pages_by_pk?: [{	id:number},ValueTypes["voyce_pages"]],
delete_voyce_panels_comments?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_panels_comments_bool_exp"]},ValueTypes["voyce_panels_comments_mutation_response"]],
delete_voyce_panels_comments_by_pk?: [{	id:number},ValueTypes["voyce_panels_comments"]],
delete_voyce_panels_reactions?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_panels_reactions_bool_exp"]},ValueTypes["voyce_panels_reactions_mutation_response"]],
delete_voyce_panels_reactions_by_pk?: [{	id:number},ValueTypes["voyce_panels_reactions"]],
delete_voyce_panels_reactions_count?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_panels_reactions_count_bool_exp"]},ValueTypes["voyce_panels_reactions_count_mutation_response"]],
delete_voyce_panels_reactions_count_by_pk?: [{	id:number},ValueTypes["voyce_panels_reactions_count"]],
delete_voyce_payments_to_creators?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_payments_to_creators_bool_exp"]},ValueTypes["voyce_payments_to_creators_mutation_response"]],
delete_voyce_payments_to_creators_by_pk?: [{	id:number},ValueTypes["voyce_payments_to_creators"]],
delete_voyce_pg_rs_series_views_count?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_pg_rs_series_views_count_bool_exp"]},ValueTypes["voyce_pg_rs_series_views_count_mutation_response"]],
delete_voyce_pg_rs_series_views_count_by_pk?: [{	series_id:number},ValueTypes["voyce_pg_rs_series_views_count"]],
delete_voyce_polls?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_polls_bool_exp"]},ValueTypes["voyce_polls_mutation_response"]],
delete_voyce_polls_answers?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_polls_answers_bool_exp"]},ValueTypes["voyce_polls_answers_mutation_response"]],
delete_voyce_polls_answers_by_pk?: [{	id:number},ValueTypes["voyce_polls_answers"]],
delete_voyce_polls_by_pk?: [{	id:number},ValueTypes["voyce_polls"]],
delete_voyce_polls_comments?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_polls_comments_bool_exp"]},ValueTypes["voyce_polls_comments_mutation_response"]],
delete_voyce_polls_comments_by_pk?: [{	id:number},ValueTypes["voyce_polls_comments"]],
delete_voyce_polls_reactions?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_polls_reactions_bool_exp"]},ValueTypes["voyce_polls_reactions_mutation_response"]],
delete_voyce_polls_reactions_by_pk?: [{	id:number},ValueTypes["voyce_polls_reactions"]],
delete_voyce_polls_votes?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_polls_votes_bool_exp"]},ValueTypes["voyce_polls_votes_mutation_response"]],
delete_voyce_polls_votes_by_pk?: [{	id:number},ValueTypes["voyce_polls_votes"]],
delete_voyce_popup_components?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_popup_components_bool_exp"]},ValueTypes["voyce_popup_components_mutation_response"]],
delete_voyce_popup_components_by_pk?: [{	id:number},ValueTypes["voyce_popup_components"]],
delete_voyce_popup_emails?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_popup_emails_bool_exp"]},ValueTypes["voyce_popup_emails_mutation_response"]],
delete_voyce_popup_emails_by_pk?: [{	id:number},ValueTypes["voyce_popup_emails"]],
delete_voyce_posts?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_posts_bool_exp"]},ValueTypes["voyce_posts_mutation_response"]],
delete_voyce_posts_by_pk?: [{	id:number},ValueTypes["voyce_posts"]],
delete_voyce_posts_comments?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_posts_comments_bool_exp"]},ValueTypes["voyce_posts_comments_mutation_response"]],
delete_voyce_posts_comments_by_pk?: [{	id:number},ValueTypes["voyce_posts_comments"]],
delete_voyce_posts_reactions?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_posts_reactions_bool_exp"]},ValueTypes["voyce_posts_reactions_mutation_response"]],
delete_voyce_posts_reactions_by_pk?: [{	id:number},ValueTypes["voyce_posts_reactions"]],
delete_voyce_quest_difficulties?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_quest_difficulties_bool_exp"]},ValueTypes["voyce_quest_difficulties_mutation_response"]],
delete_voyce_quest_difficulties_by_pk?: [{	id:number},ValueTypes["voyce_quest_difficulties"]],
delete_voyce_quests?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_quests_bool_exp"]},ValueTypes["voyce_quests_mutation_response"]],
delete_voyce_quests_by_pk?: [{	id:number},ValueTypes["voyce_quests"]],
delete_voyce_quests_old?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_quests_old_bool_exp"]},ValueTypes["voyce_quests_old_mutation_response"]],
delete_voyce_quests_old_by_pk?: [{	id:number},ValueTypes["voyce_quests_old"]],
delete_voyce_recommended_series?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_recommended_series_bool_exp"]},ValueTypes["voyce_recommended_series_mutation_response"]],
delete_voyce_recommended_series_by_pk?: [{	id:number},ValueTypes["voyce_recommended_series"]],
delete_voyce_release_schedules?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_release_schedules_bool_exp"]},ValueTypes["voyce_release_schedules_mutation_response"]],
delete_voyce_release_schedules_by_pk?: [{	id:number},ValueTypes["voyce_release_schedules"]],
delete_voyce_rewards?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_rewards_bool_exp"]},ValueTypes["voyce_rewards_mutation_response"]],
delete_voyce_rewards_by_pk?: [{	id:number},ValueTypes["voyce_rewards"]],
delete_voyce_rewards_leaderboard?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_rewards_leaderboard_bool_exp"]},ValueTypes["voyce_rewards_leaderboard_mutation_response"]],
delete_voyce_rewards_leaderboard_by_pk?: [{	id:number},ValueTypes["voyce_rewards_leaderboard"]],
delete_voyce_rewards_quest?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_rewards_quest_bool_exp"]},ValueTypes["voyce_rewards_quest_mutation_response"]],
delete_voyce_rewards_quest_by_pk?: [{	id:number},ValueTypes["voyce_rewards_quest"]],
delete_voyce_sections?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_sections_bool_exp"]},ValueTypes["voyce_sections_mutation_response"]],
delete_voyce_sections_by_pk?: [{	id:number},ValueTypes["voyce_sections"]],
delete_voyce_series?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_bool_exp"]},ValueTypes["voyce_series_mutation_response"]],
delete_voyce_series_by_pk?: [{	id:number},ValueTypes["voyce_series"]],
delete_voyce_series_collaborators?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_collaborators_bool_exp"]},ValueTypes["voyce_series_collaborators_mutation_response"]],
delete_voyce_series_collaborators_by_pk?: [{	id:number},ValueTypes["voyce_series_collaborators"]],
delete_voyce_series_donations?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_donations_bool_exp"]},ValueTypes["voyce_series_donations_mutation_response"]],
delete_voyce_series_donations_by_pk?: [{	id:number},ValueTypes["voyce_series_donations"]],
delete_voyce_series_favorites?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_favorites_bool_exp"]},ValueTypes["voyce_series_favorites_mutation_response"]],
delete_voyce_series_favorites_by_pk?: [{	id:number,	series_id:number},ValueTypes["voyce_series_favorites"]],
delete_voyce_series_featured_panels?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_featured_panels_bool_exp"]},ValueTypes["voyce_series_featured_panels_mutation_response"]],
delete_voyce_series_featured_panels_by_pk?: [{	id:number},ValueTypes["voyce_series_featured_panels"]],
delete_voyce_series_genre?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_genre_bool_exp"]},ValueTypes["voyce_series_genre_mutation_response"]],
delete_voyce_series_genre_by_pk?: [{	id:number},ValueTypes["voyce_series_genre"]],
delete_voyce_series_giveaway_participants?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_giveaway_participants_bool_exp"]},ValueTypes["voyce_series_giveaway_participants_mutation_response"]],
delete_voyce_series_giveaway_participants_by_pk?: [{	id:number},ValueTypes["voyce_series_giveaway_participants"]],
delete_voyce_series_hot_panels?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_hot_panels_bool_exp"]},ValueTypes["voyce_series_hot_panels_mutation_response"]],
delete_voyce_series_hot_panels_by_pk?: [{	id:number},ValueTypes["voyce_series_hot_panels"]],
delete_voyce_series_layouts?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_layouts_bool_exp"]},ValueTypes["voyce_series_layouts_mutation_response"]],
delete_voyce_series_layouts_by_pk?: [{	id:number},ValueTypes["voyce_series_layouts"]],
delete_voyce_series_leaderboard?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_leaderboard_bool_exp"]},ValueTypes["voyce_series_leaderboard_mutation_response"]],
delete_voyce_series_leaderboard_by_pk?: [{	id:number},ValueTypes["voyce_series_leaderboard"]],
delete_voyce_series_lock_config?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_lock_config_bool_exp"]},ValueTypes["voyce_series_lock_config_mutation_response"]],
delete_voyce_series_lock_config_by_pk?: [{	id:number},ValueTypes["voyce_series_lock_config"]],
delete_voyce_series_sponsorships_configuration?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_sponsorships_configuration_bool_exp"]},ValueTypes["voyce_series_sponsorships_configuration_mutation_response"]],
delete_voyce_series_sponsorships_configuration_by_pk?: [{	id:number},ValueTypes["voyce_series_sponsorships_configuration"]],
delete_voyce_series_subscription?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_subscription_bool_exp"]},ValueTypes["voyce_series_subscription_mutation_response"]],
delete_voyce_series_subscription_by_pk?: [{	id:number},ValueTypes["voyce_series_subscription"]],
delete_voyce_series_tags?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_tags_bool_exp"]},ValueTypes["voyce_series_tags_mutation_response"]],
delete_voyce_series_tags_by_pk?: [{	id:number},ValueTypes["voyce_series_tags"]],
delete_voyce_series_tags_junction?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_tags_junction_bool_exp"]},ValueTypes["voyce_series_tags_junction_mutation_response"]],
delete_voyce_series_tags_junction_by_pk?: [{	id:number},ValueTypes["voyce_series_tags_junction"]],
delete_voyce_series_top_comments?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_top_comments_bool_exp"]},ValueTypes["voyce_series_top_comments_mutation_response"]],
delete_voyce_series_top_comments_by_pk?: [{	id:number},ValueTypes["voyce_series_top_comments"]],
delete_voyce_series_top_likes?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_top_likes_bool_exp"]},ValueTypes["voyce_series_top_likes_mutation_response"]],
delete_voyce_series_top_likes_by_pk?: [{	id:number},ValueTypes["voyce_series_top_likes"]],
delete_voyce_series_top_views?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_top_views_bool_exp"]},ValueTypes["voyce_series_top_views_mutation_response"]],
delete_voyce_series_top_views_by_pk?: [{	id:number},ValueTypes["voyce_series_top_views"]],
delete_voyce_series_views_adjustment?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_views_adjustment_bool_exp"]},ValueTypes["voyce_series_views_adjustment_mutation_response"]],
delete_voyce_series_views_adjustment_by_pk?: [{	id:number},ValueTypes["voyce_series_views_adjustment"]],
delete_voyce_series_votes?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_votes_bool_exp"]},ValueTypes["voyce_series_votes_mutation_response"]],
delete_voyce_series_votes_by_pk?: [{	id:number},ValueTypes["voyce_series_votes"]],
delete_voyce_series_votes_count?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_votes_count_bool_exp"]},ValueTypes["voyce_series_votes_count_mutation_response"]],
delete_voyce_series_votes_count_by_pk?: [{	id:number},ValueTypes["voyce_series_votes_count"]],
delete_voyce_series_voting_schedules?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_series_voting_schedules_bool_exp"]},ValueTypes["voyce_series_voting_schedules_mutation_response"]],
delete_voyce_series_voting_schedules_by_pk?: [{	id:number},ValueTypes["voyce_series_voting_schedules"]],
delete_voyce_sponsorships?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_sponsorships_bool_exp"]},ValueTypes["voyce_sponsorships_mutation_response"]],
delete_voyce_sponsorships_by_pk?: [{	id:number},ValueTypes["voyce_sponsorships"]],
delete_voyce_states?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_states_bool_exp"]},ValueTypes["voyce_states_mutation_response"]],
delete_voyce_states_by_pk?: [{	id:number},ValueTypes["voyce_states"]],
delete_voyce_stripe_payouts_setup?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_stripe_payouts_setup_bool_exp"]},ValueTypes["voyce_stripe_payouts_setup_mutation_response"]],
delete_voyce_stripe_payouts_setup_by_pk?: [{	id:number},ValueTypes["voyce_stripe_payouts_setup"]],
delete_voyce_studio?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_studio_bool_exp"]},ValueTypes["voyce_studio_mutation_response"]],
delete_voyce_studio_by_pk?: [{	id:number},ValueTypes["voyce_studio"]],
delete_voyce_studio_followers?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_studio_followers_bool_exp"]},ValueTypes["voyce_studio_followers_mutation_response"]],
delete_voyce_studio_followers_by_pk?: [{	id:number},ValueTypes["voyce_studio_followers"]],
delete_voyce_studio_series?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_studio_series_bool_exp"]},ValueTypes["voyce_studio_series_mutation_response"]],
delete_voyce_studio_series_by_pk?: [{	id:number},ValueTypes["voyce_studio_series"]],
delete_voyce_studio_views?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_studio_views_bool_exp"]},ValueTypes["voyce_studio_views_mutation_response"]],
delete_voyce_studio_views_by_pk?: [{	id:number},ValueTypes["voyce_studio_views"]],
delete_voyce_system_settings?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_system_settings_bool_exp"]},ValueTypes["voyce_system_settings_mutation_response"]],
delete_voyce_system_settings_by_pk?: [{	id:number},ValueTypes["voyce_system_settings"]],
delete_voyce_transaction_logs?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_transaction_logs_bool_exp"]},ValueTypes["voyce_transaction_logs_mutation_response"]],
delete_voyce_transaction_logs_by_pk?: [{	id:number},ValueTypes["voyce_transaction_logs"]],
delete_voyce_url_redirects?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_url_redirects_bool_exp"]},ValueTypes["voyce_url_redirects_mutation_response"]],
delete_voyce_url_redirects_by_pk?: [{	id:number},ValueTypes["voyce_url_redirects"]],
delete_voyce_user_addresses?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_addresses_bool_exp"]},ValueTypes["voyce_user_addresses_mutation_response"]],
delete_voyce_user_addresses_by_pk?: [{	id:number},ValueTypes["voyce_user_addresses"]],
delete_voyce_user_blocks?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_blocks_bool_exp"]},ValueTypes["voyce_user_blocks_mutation_response"]],
delete_voyce_user_blocks_by_pk?: [{	linked_id:number,	user_id:number},ValueTypes["voyce_user_blocks"]],
delete_voyce_user_chapters_unlocked?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_chapters_unlocked_bool_exp"]},ValueTypes["voyce_user_chapters_unlocked_mutation_response"]],
delete_voyce_user_chapters_unlocked_by_pk?: [{	id:number},ValueTypes["voyce_user_chapters_unlocked"]],
delete_voyce_user_contacts?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_contacts_bool_exp"]},ValueTypes["voyce_user_contacts_mutation_response"]],
delete_voyce_user_contacts_by_pk?: [{	id:number},ValueTypes["voyce_user_contacts"]],
delete_voyce_user_csat_ratings?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_csat_ratings_bool_exp"]},ValueTypes["voyce_user_csat_ratings_mutation_response"]],
delete_voyce_user_csat_ratings_by_pk?: [{	id:number},ValueTypes["voyce_user_csat_ratings"]],
delete_voyce_user_education?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_education_bool_exp"]},ValueTypes["voyce_user_education_mutation_response"]],
delete_voyce_user_education_by_pk?: [{	id:number},ValueTypes["voyce_user_education"]],
delete_voyce_user_level?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_level_bool_exp"]},ValueTypes["voyce_user_level_mutation_response"]],
delete_voyce_user_level_by_pk?: [{	id:number},ValueTypes["voyce_user_level"]],
delete_voyce_user_linked_accounts?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_linked_accounts_bool_exp"]},ValueTypes["voyce_user_linked_accounts_mutation_response"]],
delete_voyce_user_linked_accounts_by_pk?: [{	id:number},ValueTypes["voyce_user_linked_accounts"]],
delete_voyce_user_login_history?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_login_history_bool_exp"]},ValueTypes["voyce_user_login_history_mutation_response"]],
delete_voyce_user_login_history_by_pk?: [{	id:number},ValueTypes["voyce_user_login_history"]],
delete_voyce_user_quest_ids?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_quest_ids_bool_exp"]},ValueTypes["voyce_user_quest_ids_mutation_response"]],
delete_voyce_user_quest_ids_by_pk?: [{	id:number},ValueTypes["voyce_user_quest_ids"]],
delete_voyce_user_quest_rewards?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_quest_rewards_bool_exp"]},ValueTypes["voyce_user_quest_rewards_mutation_response"]],
delete_voyce_user_quest_rewards_by_pk?: [{	id:number},ValueTypes["voyce_user_quest_rewards"]],
delete_voyce_user_quests?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_quests_bool_exp"]},ValueTypes["voyce_user_quests_mutation_response"]],
delete_voyce_user_quests_by_pk?: [{	id:number},ValueTypes["voyce_user_quests"]],
delete_voyce_user_quests_old?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_quests_old_bool_exp"]},ValueTypes["voyce_user_quests_old_mutation_response"]],
delete_voyce_user_quests_old_by_pk?: [{	id:number},ValueTypes["voyce_user_quests_old"]],
delete_voyce_user_ranking?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_ranking_bool_exp"]},ValueTypes["voyce_user_ranking_mutation_response"]],
delete_voyce_user_ranking_by_pk?: [{	id:number},ValueTypes["voyce_user_ranking"]],
delete_voyce_user_reviews?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_reviews_bool_exp"]},ValueTypes["voyce_user_reviews_mutation_response"]],
delete_voyce_user_reviews_by_pk?: [{	id:number},ValueTypes["voyce_user_reviews"]],
delete_voyce_user_rewards?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_rewards_bool_exp"]},ValueTypes["voyce_user_rewards_mutation_response"]],
delete_voyce_user_rewards_by_pk?: [{	id:number},ValueTypes["voyce_user_rewards"]],
delete_voyce_user_roles?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_roles_bool_exp"]},ValueTypes["voyce_user_roles_mutation_response"]],
delete_voyce_user_roles_by_pk?: [{	id:number},ValueTypes["voyce_user_roles"]],
delete_voyce_user_settings?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_settings_bool_exp"]},ValueTypes["voyce_user_settings_mutation_response"]],
delete_voyce_user_settings_by_pk?: [{	user_id:number},ValueTypes["voyce_user_settings"]],
delete_voyce_user_skills?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_skills_bool_exp"]},ValueTypes["voyce_user_skills_mutation_response"]],
delete_voyce_user_skills_by_pk?: [{	id:number},ValueTypes["voyce_user_skills"]],
delete_voyce_user_socials?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_socials_bool_exp"]},ValueTypes["voyce_user_socials_mutation_response"]],
delete_voyce_user_socials_by_pk?: [{	id:number},ValueTypes["voyce_user_socials"]],
delete_voyce_user_soft_deleted?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_soft_deleted_bool_exp"]},ValueTypes["voyce_user_soft_deleted_mutation_response"]],
delete_voyce_user_soft_deleted_by_pk?: [{	id:number},ValueTypes["voyce_user_soft_deleted"]],
delete_voyce_user_vtags?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_vtags_bool_exp"]},ValueTypes["voyce_user_vtags_mutation_response"]],
delete_voyce_user_vtags_by_pk?: [{	id:number},ValueTypes["voyce_user_vtags"]],
delete_voyce_user_wins?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_user_wins_bool_exp"]},ValueTypes["voyce_user_wins_mutation_response"]],
delete_voyce_user_wins_by_pk?: [{	id:number},ValueTypes["voyce_user_wins"]],
delete_voyce_users?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_users_bool_exp"]},ValueTypes["voyce_users_mutation_response"]],
delete_voyce_users_by_pk?: [{	id:number},ValueTypes["voyce_users"]],
delete_voyce_users_followers?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_users_followers_bool_exp"]},ValueTypes["voyce_users_followers_mutation_response"]],
delete_voyce_users_followers_by_pk?: [{	id:number},ValueTypes["voyce_users_followers"]],
delete_voyce_users_private?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_users_private_bool_exp"]},ValueTypes["voyce_users_private_mutation_response"]],
delete_voyce_users_series_junction?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_users_series_junction_bool_exp"]},ValueTypes["voyce_users_series_junction_mutation_response"]],
delete_voyce_users_series_junction_by_pk?: [{	id:number},ValueTypes["voyce_users_series_junction"]],
delete_voyce_versions?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_versions_bool_exp"]},ValueTypes["voyce_versions_mutation_response"]],
delete_voyce_versions_by_pk?: [{	id:number},ValueTypes["voyce_versions"]],
delete_voyce_voting_analytics?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_voting_analytics_bool_exp"]},ValueTypes["voyce_voting_analytics_mutation_response"]],
delete_voyce_voting_analytics_by_pk?: [{	id:number},ValueTypes["voyce_voting_analytics"]],
delete_voyce_voting_panels?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_voting_panels_bool_exp"]},ValueTypes["voyce_voting_panels_mutation_response"]],
delete_voyce_voting_panels_by_pk?: [{	id:number},ValueTypes["voyce_voting_panels"]],
delete_voyce_voting_series?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_voting_series_bool_exp"]},ValueTypes["voyce_voting_series_mutation_response"]],
delete_voyce_voting_series_by_pk?: [{	id:number},ValueTypes["voyce_voting_series"]],
delete_voyce_voting_user_votes?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["voyce_voting_user_votes_bool_exp"]},ValueTypes["voyce_voting_user_votes_mutation_response"]],
delete_voyce_voting_user_votes_by_pk?: [{	id:number},ValueTypes["voyce_voting_user_votes"]],
insert_awsdms_validation_failures_v1?: [{	/** the rows to be inserted */
	objects:ValueTypes["awsdms_validation_failures_v1_insert_input"][]},ValueTypes["awsdms_validation_failures_v1_mutation_response"]],
insert_awsdms_validation_failures_v1_one?: [{	/** the row to be inserted */
	object:ValueTypes["awsdms_validation_failures_v1_insert_input"]},ValueTypes["awsdms_validation_failures_v1"]],
insert_coinsystem_balances?: [{	/** the rows to be inserted */
	objects:ValueTypes["coinsystem_balances_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["coinsystem_balances_on_conflict"]},ValueTypes["coinsystem_balances_mutation_response"]],
insert_coinsystem_balances_one?: [{	/** the row to be inserted */
	object:ValueTypes["coinsystem_balances_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["coinsystem_balances_on_conflict"]},ValueTypes["coinsystem_balances"]],
insert_coinsystem_bonus_coins?: [{	/** the rows to be inserted */
	objects:ValueTypes["coinsystem_bonus_coins_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["coinsystem_bonus_coins_on_conflict"]},ValueTypes["coinsystem_bonus_coins_mutation_response"]],
insert_coinsystem_bonus_coins_one?: [{	/** the row to be inserted */
	object:ValueTypes["coinsystem_bonus_coins_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["coinsystem_bonus_coins_on_conflict"]},ValueTypes["coinsystem_bonus_coins"]],
insert_coinsystem_coins?: [{	/** the rows to be inserted */
	objects:ValueTypes["coinsystem_coins_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["coinsystem_coins_on_conflict"]},ValueTypes["coinsystem_coins_mutation_response"]],
insert_coinsystem_coins_one?: [{	/** the row to be inserted */
	object:ValueTypes["coinsystem_coins_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["coinsystem_coins_on_conflict"]},ValueTypes["coinsystem_coins"]],
insert_coinsystem_exchange_rates?: [{	/** the rows to be inserted */
	objects:ValueTypes["coinsystem_exchange_rates_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["coinsystem_exchange_rates_on_conflict"]},ValueTypes["coinsystem_exchange_rates_mutation_response"]],
insert_coinsystem_exchange_rates_one?: [{	/** the row to be inserted */
	object:ValueTypes["coinsystem_exchange_rates_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["coinsystem_exchange_rates_on_conflict"]},ValueTypes["coinsystem_exchange_rates"]],
insert_coinsystem_logs?: [{	/** the rows to be inserted */
	objects:ValueTypes["coinsystem_logs_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["coinsystem_logs_on_conflict"]},ValueTypes["coinsystem_logs_mutation_response"]],
insert_coinsystem_logs_one?: [{	/** the row to be inserted */
	object:ValueTypes["coinsystem_logs_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["coinsystem_logs_on_conflict"]},ValueTypes["coinsystem_logs"]],
insert_coinsystem_transactions?: [{	/** the rows to be inserted */
	objects:ValueTypes["coinsystem_transactions_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["coinsystem_transactions_on_conflict"]},ValueTypes["coinsystem_transactions_mutation_response"]],
insert_coinsystem_transactions_one?: [{	/** the row to be inserted */
	object:ValueTypes["coinsystem_transactions_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["coinsystem_transactions_on_conflict"]},ValueTypes["coinsystem_transactions"]],
insert_coinsystem_wallets?: [{	/** the rows to be inserted */
	objects:ValueTypes["coinsystem_wallets_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["coinsystem_wallets_on_conflict"]},ValueTypes["coinsystem_wallets_mutation_response"]],
insert_coinsystem_wallets_one?: [{	/** the row to be inserted */
	object:ValueTypes["coinsystem_wallets_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["coinsystem_wallets_on_conflict"]},ValueTypes["coinsystem_wallets"]],
insert_mysql_user?: [{	/** the rows to be inserted */
	objects:ValueTypes["mysql_user_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["mysql_user_on_conflict"]},ValueTypes["mysql_user_mutation_response"]],
insert_mysql_user_one?: [{	/** the row to be inserted */
	object:ValueTypes["mysql_user_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["mysql_user_on_conflict"]},ValueTypes["mysql_user"]],
insert_store_payments?: [{	/** the rows to be inserted */
	objects:ValueTypes["store_payments_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["store_payments_on_conflict"]},ValueTypes["store_payments_mutation_response"]],
insert_store_payments_one?: [{	/** the row to be inserted */
	object:ValueTypes["store_payments_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["store_payments_on_conflict"]},ValueTypes["store_payments"]],
insert_store_products?: [{	/** the rows to be inserted */
	objects:ValueTypes["store_products_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["store_products_on_conflict"]},ValueTypes["store_products_mutation_response"]],
insert_store_products_one?: [{	/** the row to be inserted */
	object:ValueTypes["store_products_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["store_products_on_conflict"]},ValueTypes["store_products"]],
insert_store_purchases?: [{	/** the rows to be inserted */
	objects:ValueTypes["store_purchases_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["store_purchases_on_conflict"]},ValueTypes["store_purchases_mutation_response"]],
insert_store_purchases_one?: [{	/** the row to be inserted */
	object:ValueTypes["store_purchases_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["store_purchases_on_conflict"]},ValueTypes["store_purchases"]],
insert_store_users?: [{	/** the rows to be inserted */
	objects:ValueTypes["store_users_insert_input"][]},ValueTypes["store_users_mutation_response"]],
insert_store_users_one?: [{	/** the row to be inserted */
	object:ValueTypes["store_users_insert_input"]},ValueTypes["store_users"]],
insert_voyce_admin_roles?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_admin_roles_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_admin_roles_on_conflict"]},ValueTypes["voyce_admin_roles_mutation_response"]],
insert_voyce_admin_roles_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_admin_roles_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_admin_roles_on_conflict"]},ValueTypes["voyce_admin_roles"]],
insert_voyce_app_banners?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_app_banners_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_app_banners_on_conflict"]},ValueTypes["voyce_app_banners_mutation_response"]],
insert_voyce_app_banners_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_app_banners_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_app_banners_on_conflict"]},ValueTypes["voyce_app_banners"]],
insert_voyce_app_components?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_app_components_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_app_components_on_conflict"]},ValueTypes["voyce_app_components_mutation_response"]],
insert_voyce_app_components_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_app_components_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_app_components_on_conflict"]},ValueTypes["voyce_app_components"]],
insert_voyce_audit_trail?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_audit_trail_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_audit_trail_on_conflict"]},ValueTypes["voyce_audit_trail_mutation_response"]],
insert_voyce_audit_trail_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_audit_trail_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_audit_trail_on_conflict"]},ValueTypes["voyce_audit_trail"]],
insert_voyce_author_profiles?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_author_profiles_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_author_profiles_on_conflict"]},ValueTypes["voyce_author_profiles_mutation_response"]],
insert_voyce_author_profiles_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_author_profiles_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_author_profiles_on_conflict"]},ValueTypes["voyce_author_profiles"]],
insert_voyce_balances?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_balances_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_balances_on_conflict"]},ValueTypes["voyce_balances_mutation_response"]],
insert_voyce_balances_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_balances_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_balances_on_conflict"]},ValueTypes["voyce_balances"]],
insert_voyce_banners?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_banners_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_banners_on_conflict"]},ValueTypes["voyce_banners_mutation_response"]],
insert_voyce_banners_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_banners_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_banners_on_conflict"]},ValueTypes["voyce_banners"]],
insert_voyce_chapter_assets?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_assets_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_assets_on_conflict"]},ValueTypes["voyce_chapter_assets_mutation_response"]],
insert_voyce_chapter_assets_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_assets_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_assets_on_conflict"]},ValueTypes["voyce_chapter_assets"]],
insert_voyce_chapter_bookmarks?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_bookmarks_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_bookmarks_on_conflict"]},ValueTypes["voyce_chapter_bookmarks_mutation_response"]],
insert_voyce_chapter_bookmarks_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_bookmarks_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_bookmarks_on_conflict"]},ValueTypes["voyce_chapter_bookmarks"]],
insert_voyce_chapter_comments?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_comments_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_comments_on_conflict"]},ValueTypes["voyce_chapter_comments_mutation_response"]],
insert_voyce_chapter_comments_07252024?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_comments_07252024_insert_input"][]},ValueTypes["voyce_chapter_comments_07252024_mutation_response"]],
insert_voyce_chapter_comments_07252024_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_comments_07252024_insert_input"]},ValueTypes["voyce_chapter_comments_07252024"]],
insert_voyce_chapter_comments_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_comments_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_comments_on_conflict"]},ValueTypes["voyce_chapter_comments"]],
insert_voyce_chapter_comments_reaction?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_comments_reaction_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_comments_reaction_on_conflict"]},ValueTypes["voyce_chapter_comments_reaction_mutation_response"]],
insert_voyce_chapter_comments_reaction_count?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_comments_reaction_count_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_comments_reaction_count_on_conflict"]},ValueTypes["voyce_chapter_comments_reaction_count_mutation_response"]],
insert_voyce_chapter_comments_reaction_count_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_comments_reaction_count_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_comments_reaction_count_on_conflict"]},ValueTypes["voyce_chapter_comments_reaction_count"]],
insert_voyce_chapter_comments_reaction_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_comments_reaction_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_comments_reaction_on_conflict"]},ValueTypes["voyce_chapter_comments_reaction"]],
insert_voyce_chapter_comments_reports?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_comments_reports_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_comments_reports_on_conflict"]},ValueTypes["voyce_chapter_comments_reports_mutation_response"]],
insert_voyce_chapter_comments_reports_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_comments_reports_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_comments_reports_on_conflict"]},ValueTypes["voyce_chapter_comments_reports"]],
insert_voyce_chapter_contents?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_contents_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_contents_on_conflict"]},ValueTypes["voyce_chapter_contents_mutation_response"]],
insert_voyce_chapter_contents_old?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_contents_old_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_contents_old_on_conflict"]},ValueTypes["voyce_chapter_contents_old_mutation_response"]],
insert_voyce_chapter_contents_old_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_contents_old_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_contents_old_on_conflict"]},ValueTypes["voyce_chapter_contents_old"]],
insert_voyce_chapter_contents_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_contents_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_contents_on_conflict"]},ValueTypes["voyce_chapter_contents"]],
insert_voyce_chapter_effects?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_effects_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_effects_on_conflict"]},ValueTypes["voyce_chapter_effects_mutation_response"]],
insert_voyce_chapter_effects_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_effects_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_effects_on_conflict"]},ValueTypes["voyce_chapter_effects"]],
insert_voyce_chapter_images?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_images_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_images_on_conflict"]},ValueTypes["voyce_chapter_images_mutation_response"]],
insert_voyce_chapter_images_07252024?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_images_07252024_insert_input"][]},ValueTypes["voyce_chapter_images_07252024_mutation_response"]],
insert_voyce_chapter_images_07252024_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_images_07252024_insert_input"]},ValueTypes["voyce_chapter_images_07252024"]],
insert_voyce_chapter_images_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_images_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_images_on_conflict"]},ValueTypes["voyce_chapter_images"]],
insert_voyce_chapter_likes?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_likes_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_likes_on_conflict"]},ValueTypes["voyce_chapter_likes_mutation_response"]],
insert_voyce_chapter_likes_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_likes_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_likes_on_conflict"]},ValueTypes["voyce_chapter_likes"]],
insert_voyce_chapter_locks?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_locks_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_locks_on_conflict"]},ValueTypes["voyce_chapter_locks_mutation_response"]],
insert_voyce_chapter_locks_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_locks_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_locks_on_conflict"]},ValueTypes["voyce_chapter_locks"]],
insert_voyce_chapter_panel_views?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_panel_views_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_panel_views_on_conflict"]},ValueTypes["voyce_chapter_panel_views_mutation_response"]],
insert_voyce_chapter_panel_views_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_panel_views_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_panel_views_on_conflict"]},ValueTypes["voyce_chapter_panel_views"]],
insert_voyce_chapter_panels?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_panels_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_panels_on_conflict"]},ValueTypes["voyce_chapter_panels_mutation_response"]],
insert_voyce_chapter_panels_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_panels_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_panels_on_conflict"]},ValueTypes["voyce_chapter_panels"]],
insert_voyce_chapter_publish_schedules?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_publish_schedules_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_publish_schedules_on_conflict"]},ValueTypes["voyce_chapter_publish_schedules_mutation_response"]],
insert_voyce_chapter_publish_schedules_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_publish_schedules_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_publish_schedules_on_conflict"]},ValueTypes["voyce_chapter_publish_schedules"]],
insert_voyce_chapter_reactions?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_reactions_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_reactions_on_conflict"]},ValueTypes["voyce_chapter_reactions_mutation_response"]],
insert_voyce_chapter_reactions_count?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_reactions_count_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_reactions_count_on_conflict"]},ValueTypes["voyce_chapter_reactions_count_mutation_response"]],
insert_voyce_chapter_reactions_count_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_reactions_count_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_reactions_count_on_conflict"]},ValueTypes["voyce_chapter_reactions_count"]],
insert_voyce_chapter_reactions_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_reactions_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_reactions_on_conflict"]},ValueTypes["voyce_chapter_reactions"]],
insert_voyce_chapter_view_logs?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_view_logs_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_view_logs_on_conflict"]},ValueTypes["voyce_chapter_view_logs_mutation_response"]],
insert_voyce_chapter_view_logs_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_view_logs_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_view_logs_on_conflict"]},ValueTypes["voyce_chapter_view_logs"]],
insert_voyce_chapter_views?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapter_views_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_views_on_conflict"]},ValueTypes["voyce_chapter_views_mutation_response"]],
insert_voyce_chapter_views_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapter_views_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_views_on_conflict"]},ValueTypes["voyce_chapter_views"]],
insert_voyce_chapters?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapters_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapters_on_conflict"]},ValueTypes["voyce_chapters_mutation_response"]],
insert_voyce_chapters_07252024?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_chapters_07252024_insert_input"][]},ValueTypes["voyce_chapters_07252024_mutation_response"]],
insert_voyce_chapters_07252024_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapters_07252024_insert_input"]},ValueTypes["voyce_chapters_07252024"]],
insert_voyce_chapters_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_chapters_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapters_on_conflict"]},ValueTypes["voyce_chapters"]],
insert_voyce_collaborator_roles?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_collaborator_roles_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_collaborator_roles_on_conflict"]},ValueTypes["voyce_collaborator_roles_mutation_response"]],
insert_voyce_collaborator_roles_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_collaborator_roles_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_collaborator_roles_on_conflict"]},ValueTypes["voyce_collaborator_roles"]],
insert_voyce_comic_types?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_comic_types_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_comic_types_on_conflict"]},ValueTypes["voyce_comic_types_mutation_response"]],
insert_voyce_comic_types_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_comic_types_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_comic_types_on_conflict"]},ValueTypes["voyce_comic_types"]],
insert_voyce_community_blurbs?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_community_blurbs_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_community_blurbs_on_conflict"]},ValueTypes["voyce_community_blurbs_mutation_response"]],
insert_voyce_community_blurbs_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_community_blurbs_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_community_blurbs_on_conflict"]},ValueTypes["voyce_community_blurbs"]],
insert_voyce_contact_us?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_contact_us_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_contact_us_on_conflict"]},ValueTypes["voyce_contact_us_mutation_response"]],
insert_voyce_contact_us_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_contact_us_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_contact_us_on_conflict"]},ValueTypes["voyce_contact_us"]],
insert_voyce_content_report?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_content_report_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_content_report_on_conflict"]},ValueTypes["voyce_content_report_mutation_response"]],
insert_voyce_content_report_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_content_report_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_content_report_on_conflict"]},ValueTypes["voyce_content_report"]],
insert_voyce_contest_data?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_contest_data_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_contest_data_on_conflict"]},ValueTypes["voyce_contest_data_mutation_response"]],
insert_voyce_contest_data_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_contest_data_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_contest_data_on_conflict"]},ValueTypes["voyce_contest_data"]],
insert_voyce_countries?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_countries_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_countries_on_conflict"]},ValueTypes["voyce_countries_mutation_response"]],
insert_voyce_countries_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_countries_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_countries_on_conflict"]},ValueTypes["voyce_countries"]],
insert_voyce_csat_ratings?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_csat_ratings_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_csat_ratings_on_conflict"]},ValueTypes["voyce_csat_ratings_mutation_response"]],
insert_voyce_csat_ratings_data?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_csat_ratings_data_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_csat_ratings_data_on_conflict"]},ValueTypes["voyce_csat_ratings_data_mutation_response"]],
insert_voyce_csat_ratings_data_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_csat_ratings_data_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_csat_ratings_data_on_conflict"]},ValueTypes["voyce_csat_ratings_data"]],
insert_voyce_csat_ratings_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_csat_ratings_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_csat_ratings_on_conflict"]},ValueTypes["voyce_csat_ratings"]],
insert_voyce_education_types?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_education_types_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_education_types_on_conflict"]},ValueTypes["voyce_education_types_mutation_response"]],
insert_voyce_education_types_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_education_types_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_education_types_on_conflict"]},ValueTypes["voyce_education_types"]],
insert_voyce_erp_login_roles?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_erp_login_roles_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_erp_login_roles_on_conflict"]},ValueTypes["voyce_erp_login_roles_mutation_response"]],
insert_voyce_erp_login_roles_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_erp_login_roles_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_erp_login_roles_on_conflict"]},ValueTypes["voyce_erp_login_roles"]],
insert_voyce_etl_series_views_logs?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_etl_series_views_logs_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_etl_series_views_logs_on_conflict"]},ValueTypes["voyce_etl_series_views_logs_mutation_response"]],
insert_voyce_etl_series_views_logs_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_etl_series_views_logs_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_etl_series_views_logs_on_conflict"]},ValueTypes["voyce_etl_series_views_logs"]],
insert_voyce_event_schedules?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_event_schedules_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_event_schedules_on_conflict"]},ValueTypes["voyce_event_schedules_mutation_response"]],
insert_voyce_event_schedules_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_event_schedules_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_event_schedules_on_conflict"]},ValueTypes["voyce_event_schedules"]],
insert_voyce_genres?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_genres_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_genres_on_conflict"]},ValueTypes["voyce_genres_mutation_response"]],
insert_voyce_genres_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_genres_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_genres_on_conflict"]},ValueTypes["voyce_genres"]],
insert_voyce_guest_gtags?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_guest_gtags_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_guest_gtags_on_conflict"]},ValueTypes["voyce_guest_gtags_mutation_response"]],
insert_voyce_guest_gtags_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_guest_gtags_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_guest_gtags_on_conflict"]},ValueTypes["voyce_guest_gtags"]],
insert_voyce_metabases?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_metabases_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_metabases_on_conflict"]},ValueTypes["voyce_metabases_mutation_response"]],
insert_voyce_metabases_access?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_metabases_access_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_metabases_access_on_conflict"]},ValueTypes["voyce_metabases_access_mutation_response"]],
insert_voyce_metabases_access_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_metabases_access_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_metabases_access_on_conflict"]},ValueTypes["voyce_metabases_access"]],
insert_voyce_metabases_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_metabases_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_metabases_on_conflict"]},ValueTypes["voyce_metabases"]],
insert_voyce_newsletter?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_newsletter_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_newsletter_on_conflict"]},ValueTypes["voyce_newsletter_mutation_response"]],
insert_voyce_newsletter_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_newsletter_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_newsletter_on_conflict"]},ValueTypes["voyce_newsletter"]],
insert_voyce_notification_series_settings?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_notification_series_settings_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_notification_series_settings_on_conflict"]},ValueTypes["voyce_notification_series_settings_mutation_response"]],
insert_voyce_notification_series_settings_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_notification_series_settings_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_notification_series_settings_on_conflict"]},ValueTypes["voyce_notification_series_settings"]],
insert_voyce_notification_settings?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_notification_settings_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_notification_settings_on_conflict"]},ValueTypes["voyce_notification_settings_mutation_response"]],
insert_voyce_notification_settings_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_notification_settings_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_notification_settings_on_conflict"]},ValueTypes["voyce_notification_settings"]],
insert_voyce_notifications?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_notifications_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_notifications_on_conflict"]},ValueTypes["voyce_notifications_mutation_response"]],
insert_voyce_notifications_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_notifications_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_notifications_on_conflict"]},ValueTypes["voyce_notifications"]],
insert_voyce_page_banners?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_page_banners_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_page_banners_on_conflict"]},ValueTypes["voyce_page_banners_mutation_response"]],
insert_voyce_page_banners_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_page_banners_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_page_banners_on_conflict"]},ValueTypes["voyce_page_banners"]],
insert_voyce_page_components?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_page_components_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_page_components_on_conflict"]},ValueTypes["voyce_page_components_mutation_response"]],
insert_voyce_page_components_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_page_components_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_page_components_on_conflict"]},ValueTypes["voyce_page_components"]],
insert_voyce_pages?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_pages_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_pages_on_conflict"]},ValueTypes["voyce_pages_mutation_response"]],
insert_voyce_pages_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_pages_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_pages_on_conflict"]},ValueTypes["voyce_pages"]],
insert_voyce_panels_comments?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_panels_comments_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_panels_comments_on_conflict"]},ValueTypes["voyce_panels_comments_mutation_response"]],
insert_voyce_panels_comments_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_panels_comments_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_panels_comments_on_conflict"]},ValueTypes["voyce_panels_comments"]],
insert_voyce_panels_reactions?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_panels_reactions_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_panels_reactions_on_conflict"]},ValueTypes["voyce_panels_reactions_mutation_response"]],
insert_voyce_panels_reactions_count?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_panels_reactions_count_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_panels_reactions_count_on_conflict"]},ValueTypes["voyce_panels_reactions_count_mutation_response"]],
insert_voyce_panels_reactions_count_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_panels_reactions_count_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_panels_reactions_count_on_conflict"]},ValueTypes["voyce_panels_reactions_count"]],
insert_voyce_panels_reactions_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_panels_reactions_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_panels_reactions_on_conflict"]},ValueTypes["voyce_panels_reactions"]],
insert_voyce_payments_to_creators?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_payments_to_creators_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_payments_to_creators_on_conflict"]},ValueTypes["voyce_payments_to_creators_mutation_response"]],
insert_voyce_payments_to_creators_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_payments_to_creators_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_payments_to_creators_on_conflict"]},ValueTypes["voyce_payments_to_creators"]],
insert_voyce_pg_rs_series_views_count?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_pg_rs_series_views_count_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_pg_rs_series_views_count_on_conflict"]},ValueTypes["voyce_pg_rs_series_views_count_mutation_response"]],
insert_voyce_pg_rs_series_views_count_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_pg_rs_series_views_count_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_pg_rs_series_views_count_on_conflict"]},ValueTypes["voyce_pg_rs_series_views_count"]],
insert_voyce_polls?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_polls_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_on_conflict"]},ValueTypes["voyce_polls_mutation_response"]],
insert_voyce_polls_answers?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_polls_answers_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_answers_on_conflict"]},ValueTypes["voyce_polls_answers_mutation_response"]],
insert_voyce_polls_answers_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_polls_answers_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_answers_on_conflict"]},ValueTypes["voyce_polls_answers"]],
insert_voyce_polls_comments?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_polls_comments_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_comments_on_conflict"]},ValueTypes["voyce_polls_comments_mutation_response"]],
insert_voyce_polls_comments_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_polls_comments_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_comments_on_conflict"]},ValueTypes["voyce_polls_comments"]],
insert_voyce_polls_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_polls_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_on_conflict"]},ValueTypes["voyce_polls"]],
insert_voyce_polls_reactions?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_polls_reactions_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_reactions_on_conflict"]},ValueTypes["voyce_polls_reactions_mutation_response"]],
insert_voyce_polls_reactions_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_polls_reactions_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_reactions_on_conflict"]},ValueTypes["voyce_polls_reactions"]],
insert_voyce_polls_votes?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_polls_votes_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_votes_on_conflict"]},ValueTypes["voyce_polls_votes_mutation_response"]],
insert_voyce_polls_votes_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_polls_votes_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_votes_on_conflict"]},ValueTypes["voyce_polls_votes"]],
insert_voyce_popup_components?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_popup_components_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_popup_components_on_conflict"]},ValueTypes["voyce_popup_components_mutation_response"]],
insert_voyce_popup_components_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_popup_components_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_popup_components_on_conflict"]},ValueTypes["voyce_popup_components"]],
insert_voyce_popup_emails?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_popup_emails_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_popup_emails_on_conflict"]},ValueTypes["voyce_popup_emails_mutation_response"]],
insert_voyce_popup_emails_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_popup_emails_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_popup_emails_on_conflict"]},ValueTypes["voyce_popup_emails"]],
insert_voyce_posts?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_posts_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_posts_on_conflict"]},ValueTypes["voyce_posts_mutation_response"]],
insert_voyce_posts_comments?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_posts_comments_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_posts_comments_on_conflict"]},ValueTypes["voyce_posts_comments_mutation_response"]],
insert_voyce_posts_comments_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_posts_comments_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_posts_comments_on_conflict"]},ValueTypes["voyce_posts_comments"]],
insert_voyce_posts_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_posts_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_posts_on_conflict"]},ValueTypes["voyce_posts"]],
insert_voyce_posts_reactions?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_posts_reactions_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_posts_reactions_on_conflict"]},ValueTypes["voyce_posts_reactions_mutation_response"]],
insert_voyce_posts_reactions_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_posts_reactions_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_posts_reactions_on_conflict"]},ValueTypes["voyce_posts_reactions"]],
insert_voyce_quest_difficulties?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_quest_difficulties_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_quest_difficulties_on_conflict"]},ValueTypes["voyce_quest_difficulties_mutation_response"]],
insert_voyce_quest_difficulties_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_quest_difficulties_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_quest_difficulties_on_conflict"]},ValueTypes["voyce_quest_difficulties"]],
insert_voyce_quests?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_quests_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_quests_on_conflict"]},ValueTypes["voyce_quests_mutation_response"]],
insert_voyce_quests_old?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_quests_old_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_quests_old_on_conflict"]},ValueTypes["voyce_quests_old_mutation_response"]],
insert_voyce_quests_old_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_quests_old_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_quests_old_on_conflict"]},ValueTypes["voyce_quests_old"]],
insert_voyce_quests_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_quests_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_quests_on_conflict"]},ValueTypes["voyce_quests"]],
insert_voyce_recommended_series?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_recommended_series_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_recommended_series_on_conflict"]},ValueTypes["voyce_recommended_series_mutation_response"]],
insert_voyce_recommended_series_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_recommended_series_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_recommended_series_on_conflict"]},ValueTypes["voyce_recommended_series"]],
insert_voyce_release_schedules?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_release_schedules_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_release_schedules_on_conflict"]},ValueTypes["voyce_release_schedules_mutation_response"]],
insert_voyce_release_schedules_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_release_schedules_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_release_schedules_on_conflict"]},ValueTypes["voyce_release_schedules"]],
insert_voyce_rewards?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_rewards_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_rewards_on_conflict"]},ValueTypes["voyce_rewards_mutation_response"]],
insert_voyce_rewards_leaderboard?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_rewards_leaderboard_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_rewards_leaderboard_on_conflict"]},ValueTypes["voyce_rewards_leaderboard_mutation_response"]],
insert_voyce_rewards_leaderboard_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_rewards_leaderboard_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_rewards_leaderboard_on_conflict"]},ValueTypes["voyce_rewards_leaderboard"]],
insert_voyce_rewards_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_rewards_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_rewards_on_conflict"]},ValueTypes["voyce_rewards"]],
insert_voyce_rewards_quest?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_rewards_quest_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_rewards_quest_on_conflict"]},ValueTypes["voyce_rewards_quest_mutation_response"]],
insert_voyce_rewards_quest_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_rewards_quest_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_rewards_quest_on_conflict"]},ValueTypes["voyce_rewards_quest"]],
insert_voyce_sections?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_sections_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_sections_on_conflict"]},ValueTypes["voyce_sections_mutation_response"]],
insert_voyce_sections_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_sections_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_sections_on_conflict"]},ValueTypes["voyce_sections"]],
insert_voyce_series?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_on_conflict"]},ValueTypes["voyce_series_mutation_response"]],
insert_voyce_series_collaborators?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_collaborators_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_collaborators_on_conflict"]},ValueTypes["voyce_series_collaborators_mutation_response"]],
insert_voyce_series_collaborators_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_collaborators_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_collaborators_on_conflict"]},ValueTypes["voyce_series_collaborators"]],
insert_voyce_series_donations?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_donations_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_donations_on_conflict"]},ValueTypes["voyce_series_donations_mutation_response"]],
insert_voyce_series_donations_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_donations_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_donations_on_conflict"]},ValueTypes["voyce_series_donations"]],
insert_voyce_series_favorites?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_favorites_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_favorites_on_conflict"]},ValueTypes["voyce_series_favorites_mutation_response"]],
insert_voyce_series_favorites_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_favorites_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_favorites_on_conflict"]},ValueTypes["voyce_series_favorites"]],
insert_voyce_series_featured_panels?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_featured_panels_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_featured_panels_on_conflict"]},ValueTypes["voyce_series_featured_panels_mutation_response"]],
insert_voyce_series_featured_panels_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_featured_panels_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_featured_panels_on_conflict"]},ValueTypes["voyce_series_featured_panels"]],
insert_voyce_series_genre?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_genre_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_genre_on_conflict"]},ValueTypes["voyce_series_genre_mutation_response"]],
insert_voyce_series_genre_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_genre_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_genre_on_conflict"]},ValueTypes["voyce_series_genre"]],
insert_voyce_series_giveaway_participants?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_giveaway_participants_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_giveaway_participants_on_conflict"]},ValueTypes["voyce_series_giveaway_participants_mutation_response"]],
insert_voyce_series_giveaway_participants_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_giveaway_participants_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_giveaway_participants_on_conflict"]},ValueTypes["voyce_series_giveaway_participants"]],
insert_voyce_series_hot_panels?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_hot_panels_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_hot_panels_on_conflict"]},ValueTypes["voyce_series_hot_panels_mutation_response"]],
insert_voyce_series_hot_panels_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_hot_panels_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_hot_panels_on_conflict"]},ValueTypes["voyce_series_hot_panels"]],
insert_voyce_series_layouts?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_layouts_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_layouts_on_conflict"]},ValueTypes["voyce_series_layouts_mutation_response"]],
insert_voyce_series_layouts_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_layouts_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_layouts_on_conflict"]},ValueTypes["voyce_series_layouts"]],
insert_voyce_series_leaderboard?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_leaderboard_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_leaderboard_on_conflict"]},ValueTypes["voyce_series_leaderboard_mutation_response"]],
insert_voyce_series_leaderboard_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_leaderboard_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_leaderboard_on_conflict"]},ValueTypes["voyce_series_leaderboard"]],
insert_voyce_series_lock_config?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_lock_config_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_lock_config_on_conflict"]},ValueTypes["voyce_series_lock_config_mutation_response"]],
insert_voyce_series_lock_config_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_lock_config_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_lock_config_on_conflict"]},ValueTypes["voyce_series_lock_config"]],
insert_voyce_series_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_on_conflict"]},ValueTypes["voyce_series"]],
insert_voyce_series_sponsorships_configuration?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_sponsorships_configuration_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_sponsorships_configuration_on_conflict"]},ValueTypes["voyce_series_sponsorships_configuration_mutation_response"]],
insert_voyce_series_sponsorships_configuration_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_sponsorships_configuration_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_sponsorships_configuration_on_conflict"]},ValueTypes["voyce_series_sponsorships_configuration"]],
insert_voyce_series_subscription?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_subscription_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_subscription_on_conflict"]},ValueTypes["voyce_series_subscription_mutation_response"]],
insert_voyce_series_subscription_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_subscription_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_subscription_on_conflict"]},ValueTypes["voyce_series_subscription"]],
insert_voyce_series_tags?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_tags_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_tags_on_conflict"]},ValueTypes["voyce_series_tags_mutation_response"]],
insert_voyce_series_tags_junction?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_tags_junction_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_tags_junction_on_conflict"]},ValueTypes["voyce_series_tags_junction_mutation_response"]],
insert_voyce_series_tags_junction_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_tags_junction_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_tags_junction_on_conflict"]},ValueTypes["voyce_series_tags_junction"]],
insert_voyce_series_tags_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_tags_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_tags_on_conflict"]},ValueTypes["voyce_series_tags"]],
insert_voyce_series_top_comments?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_top_comments_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_top_comments_on_conflict"]},ValueTypes["voyce_series_top_comments_mutation_response"]],
insert_voyce_series_top_comments_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_top_comments_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_top_comments_on_conflict"]},ValueTypes["voyce_series_top_comments"]],
insert_voyce_series_top_likes?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_top_likes_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_top_likes_on_conflict"]},ValueTypes["voyce_series_top_likes_mutation_response"]],
insert_voyce_series_top_likes_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_top_likes_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_top_likes_on_conflict"]},ValueTypes["voyce_series_top_likes"]],
insert_voyce_series_top_views?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_top_views_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_top_views_on_conflict"]},ValueTypes["voyce_series_top_views_mutation_response"]],
insert_voyce_series_top_views_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_top_views_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_top_views_on_conflict"]},ValueTypes["voyce_series_top_views"]],
insert_voyce_series_views_adjustment?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_views_adjustment_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_views_adjustment_on_conflict"]},ValueTypes["voyce_series_views_adjustment_mutation_response"]],
insert_voyce_series_views_adjustment_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_views_adjustment_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_views_adjustment_on_conflict"]},ValueTypes["voyce_series_views_adjustment"]],
insert_voyce_series_votes?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_votes_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_votes_on_conflict"]},ValueTypes["voyce_series_votes_mutation_response"]],
insert_voyce_series_votes_count?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_votes_count_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_votes_count_on_conflict"]},ValueTypes["voyce_series_votes_count_mutation_response"]],
insert_voyce_series_votes_count_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_votes_count_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_votes_count_on_conflict"]},ValueTypes["voyce_series_votes_count"]],
insert_voyce_series_votes_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_votes_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_votes_on_conflict"]},ValueTypes["voyce_series_votes"]],
insert_voyce_series_voting_schedules?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_series_voting_schedules_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_voting_schedules_on_conflict"]},ValueTypes["voyce_series_voting_schedules_mutation_response"]],
insert_voyce_series_voting_schedules_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_series_voting_schedules_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_voting_schedules_on_conflict"]},ValueTypes["voyce_series_voting_schedules"]],
insert_voyce_sponsorships?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_sponsorships_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_sponsorships_on_conflict"]},ValueTypes["voyce_sponsorships_mutation_response"]],
insert_voyce_sponsorships_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_sponsorships_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_sponsorships_on_conflict"]},ValueTypes["voyce_sponsorships"]],
insert_voyce_states?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_states_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_states_on_conflict"]},ValueTypes["voyce_states_mutation_response"]],
insert_voyce_states_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_states_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_states_on_conflict"]},ValueTypes["voyce_states"]],
insert_voyce_stripe_payouts_setup?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_stripe_payouts_setup_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_stripe_payouts_setup_on_conflict"]},ValueTypes["voyce_stripe_payouts_setup_mutation_response"]],
insert_voyce_stripe_payouts_setup_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_stripe_payouts_setup_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_stripe_payouts_setup_on_conflict"]},ValueTypes["voyce_stripe_payouts_setup"]],
insert_voyce_studio?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_studio_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_studio_on_conflict"]},ValueTypes["voyce_studio_mutation_response"]],
insert_voyce_studio_followers?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_studio_followers_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_studio_followers_on_conflict"]},ValueTypes["voyce_studio_followers_mutation_response"]],
insert_voyce_studio_followers_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_studio_followers_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_studio_followers_on_conflict"]},ValueTypes["voyce_studio_followers"]],
insert_voyce_studio_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_studio_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_studio_on_conflict"]},ValueTypes["voyce_studio"]],
insert_voyce_studio_series?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_studio_series_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_studio_series_on_conflict"]},ValueTypes["voyce_studio_series_mutation_response"]],
insert_voyce_studio_series_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_studio_series_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_studio_series_on_conflict"]},ValueTypes["voyce_studio_series"]],
insert_voyce_studio_views?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_studio_views_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_studio_views_on_conflict"]},ValueTypes["voyce_studio_views_mutation_response"]],
insert_voyce_studio_views_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_studio_views_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_studio_views_on_conflict"]},ValueTypes["voyce_studio_views"]],
insert_voyce_system_settings?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_system_settings_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_system_settings_on_conflict"]},ValueTypes["voyce_system_settings_mutation_response"]],
insert_voyce_system_settings_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_system_settings_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_system_settings_on_conflict"]},ValueTypes["voyce_system_settings"]],
insert_voyce_transaction_logs?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_transaction_logs_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_transaction_logs_on_conflict"]},ValueTypes["voyce_transaction_logs_mutation_response"]],
insert_voyce_transaction_logs_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_transaction_logs_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_transaction_logs_on_conflict"]},ValueTypes["voyce_transaction_logs"]],
insert_voyce_url_redirects?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_url_redirects_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_url_redirects_on_conflict"]},ValueTypes["voyce_url_redirects_mutation_response"]],
insert_voyce_url_redirects_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_url_redirects_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_url_redirects_on_conflict"]},ValueTypes["voyce_url_redirects"]],
insert_voyce_user_addresses?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_addresses_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_addresses_on_conflict"]},ValueTypes["voyce_user_addresses_mutation_response"]],
insert_voyce_user_addresses_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_addresses_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_addresses_on_conflict"]},ValueTypes["voyce_user_addresses"]],
insert_voyce_user_blocks?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_blocks_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_blocks_on_conflict"]},ValueTypes["voyce_user_blocks_mutation_response"]],
insert_voyce_user_blocks_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_blocks_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_blocks_on_conflict"]},ValueTypes["voyce_user_blocks"]],
insert_voyce_user_chapters_unlocked?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_chapters_unlocked_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_chapters_unlocked_on_conflict"]},ValueTypes["voyce_user_chapters_unlocked_mutation_response"]],
insert_voyce_user_chapters_unlocked_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_chapters_unlocked_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_chapters_unlocked_on_conflict"]},ValueTypes["voyce_user_chapters_unlocked"]],
insert_voyce_user_contacts?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_contacts_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_contacts_on_conflict"]},ValueTypes["voyce_user_contacts_mutation_response"]],
insert_voyce_user_contacts_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_contacts_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_contacts_on_conflict"]},ValueTypes["voyce_user_contacts"]],
insert_voyce_user_csat_ratings?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_csat_ratings_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_csat_ratings_on_conflict"]},ValueTypes["voyce_user_csat_ratings_mutation_response"]],
insert_voyce_user_csat_ratings_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_csat_ratings_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_csat_ratings_on_conflict"]},ValueTypes["voyce_user_csat_ratings"]],
insert_voyce_user_education?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_education_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_education_on_conflict"]},ValueTypes["voyce_user_education_mutation_response"]],
insert_voyce_user_education_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_education_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_education_on_conflict"]},ValueTypes["voyce_user_education"]],
insert_voyce_user_level?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_level_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_level_on_conflict"]},ValueTypes["voyce_user_level_mutation_response"]],
insert_voyce_user_level_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_level_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_level_on_conflict"]},ValueTypes["voyce_user_level"]],
insert_voyce_user_linked_accounts?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_linked_accounts_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_linked_accounts_on_conflict"]},ValueTypes["voyce_user_linked_accounts_mutation_response"]],
insert_voyce_user_linked_accounts_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_linked_accounts_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_linked_accounts_on_conflict"]},ValueTypes["voyce_user_linked_accounts"]],
insert_voyce_user_login_history?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_login_history_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_login_history_on_conflict"]},ValueTypes["voyce_user_login_history_mutation_response"]],
insert_voyce_user_login_history_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_login_history_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_login_history_on_conflict"]},ValueTypes["voyce_user_login_history"]],
insert_voyce_user_quest_ids?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_quest_ids_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_quest_ids_on_conflict"]},ValueTypes["voyce_user_quest_ids_mutation_response"]],
insert_voyce_user_quest_ids_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_quest_ids_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_quest_ids_on_conflict"]},ValueTypes["voyce_user_quest_ids"]],
insert_voyce_user_quest_rewards?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_quest_rewards_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_quest_rewards_on_conflict"]},ValueTypes["voyce_user_quest_rewards_mutation_response"]],
insert_voyce_user_quest_rewards_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_quest_rewards_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_quest_rewards_on_conflict"]},ValueTypes["voyce_user_quest_rewards"]],
insert_voyce_user_quests?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_quests_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_quests_on_conflict"]},ValueTypes["voyce_user_quests_mutation_response"]],
insert_voyce_user_quests_old?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_quests_old_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_quests_old_on_conflict"]},ValueTypes["voyce_user_quests_old_mutation_response"]],
insert_voyce_user_quests_old_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_quests_old_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_quests_old_on_conflict"]},ValueTypes["voyce_user_quests_old"]],
insert_voyce_user_quests_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_quests_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_quests_on_conflict"]},ValueTypes["voyce_user_quests"]],
insert_voyce_user_ranking?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_ranking_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_ranking_on_conflict"]},ValueTypes["voyce_user_ranking_mutation_response"]],
insert_voyce_user_ranking_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_ranking_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_ranking_on_conflict"]},ValueTypes["voyce_user_ranking"]],
insert_voyce_user_reviews?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_reviews_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_reviews_on_conflict"]},ValueTypes["voyce_user_reviews_mutation_response"]],
insert_voyce_user_reviews_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_reviews_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_reviews_on_conflict"]},ValueTypes["voyce_user_reviews"]],
insert_voyce_user_rewards?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_rewards_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_rewards_on_conflict"]},ValueTypes["voyce_user_rewards_mutation_response"]],
insert_voyce_user_rewards_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_rewards_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_rewards_on_conflict"]},ValueTypes["voyce_user_rewards"]],
insert_voyce_user_roles?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_roles_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_roles_on_conflict"]},ValueTypes["voyce_user_roles_mutation_response"]],
insert_voyce_user_roles_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_roles_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_roles_on_conflict"]},ValueTypes["voyce_user_roles"]],
insert_voyce_user_settings?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_settings_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_settings_on_conflict"]},ValueTypes["voyce_user_settings_mutation_response"]],
insert_voyce_user_settings_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_settings_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_settings_on_conflict"]},ValueTypes["voyce_user_settings"]],
insert_voyce_user_skills?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_skills_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_skills_on_conflict"]},ValueTypes["voyce_user_skills_mutation_response"]],
insert_voyce_user_skills_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_skills_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_skills_on_conflict"]},ValueTypes["voyce_user_skills"]],
insert_voyce_user_socials?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_socials_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_socials_on_conflict"]},ValueTypes["voyce_user_socials_mutation_response"]],
insert_voyce_user_socials_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_socials_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_socials_on_conflict"]},ValueTypes["voyce_user_socials"]],
insert_voyce_user_soft_deleted?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_soft_deleted_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_soft_deleted_on_conflict"]},ValueTypes["voyce_user_soft_deleted_mutation_response"]],
insert_voyce_user_soft_deleted_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_soft_deleted_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_soft_deleted_on_conflict"]},ValueTypes["voyce_user_soft_deleted"]],
insert_voyce_user_vtags?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_vtags_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_vtags_on_conflict"]},ValueTypes["voyce_user_vtags_mutation_response"]],
insert_voyce_user_vtags_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_vtags_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_vtags_on_conflict"]},ValueTypes["voyce_user_vtags"]],
insert_voyce_user_wins?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_user_wins_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_wins_on_conflict"]},ValueTypes["voyce_user_wins_mutation_response"]],
insert_voyce_user_wins_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_user_wins_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_wins_on_conflict"]},ValueTypes["voyce_user_wins"]],
insert_voyce_users?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_users_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_users_on_conflict"]},ValueTypes["voyce_users_mutation_response"]],
insert_voyce_users_followers?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_users_followers_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_users_followers_on_conflict"]},ValueTypes["voyce_users_followers_mutation_response"]],
insert_voyce_users_followers_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_users_followers_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_users_followers_on_conflict"]},ValueTypes["voyce_users_followers"]],
insert_voyce_users_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_users_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_users_on_conflict"]},ValueTypes["voyce_users"]],
insert_voyce_users_private?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_users_private_insert_input"][]},ValueTypes["voyce_users_private_mutation_response"]],
insert_voyce_users_private_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_users_private_insert_input"]},ValueTypes["voyce_users_private"]],
insert_voyce_users_series_junction?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_users_series_junction_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_users_series_junction_on_conflict"]},ValueTypes["voyce_users_series_junction_mutation_response"]],
insert_voyce_users_series_junction_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_users_series_junction_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_users_series_junction_on_conflict"]},ValueTypes["voyce_users_series_junction"]],
insert_voyce_versions?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_versions_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_versions_on_conflict"]},ValueTypes["voyce_versions_mutation_response"]],
insert_voyce_versions_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_versions_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_versions_on_conflict"]},ValueTypes["voyce_versions"]],
insert_voyce_voting_analytics?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_voting_analytics_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_voting_analytics_on_conflict"]},ValueTypes["voyce_voting_analytics_mutation_response"]],
insert_voyce_voting_analytics_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_voting_analytics_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_voting_analytics_on_conflict"]},ValueTypes["voyce_voting_analytics"]],
insert_voyce_voting_panels?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_voting_panels_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_voting_panels_on_conflict"]},ValueTypes["voyce_voting_panels_mutation_response"]],
insert_voyce_voting_panels_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_voting_panels_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_voting_panels_on_conflict"]},ValueTypes["voyce_voting_panels"]],
insert_voyce_voting_series?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_voting_series_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_voting_series_on_conflict"]},ValueTypes["voyce_voting_series_mutation_response"]],
insert_voyce_voting_series_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_voting_series_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_voting_series_on_conflict"]},ValueTypes["voyce_voting_series"]],
insert_voyce_voting_user_votes?: [{	/** the rows to be inserted */
	objects:ValueTypes["voyce_voting_user_votes_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_voting_user_votes_on_conflict"]},ValueTypes["voyce_voting_user_votes_mutation_response"]],
insert_voyce_voting_user_votes_one?: [{	/** the row to be inserted */
	object:ValueTypes["voyce_voting_user_votes_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["voyce_voting_user_votes_on_conflict"]},ValueTypes["voyce_voting_user_votes"]],
update_awsdms_validation_failures_v1?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["awsdms_validation_failures_v1_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["awsdms_validation_failures_v1_bool_exp"]},ValueTypes["awsdms_validation_failures_v1_mutation_response"]],
update_awsdms_validation_failures_v1_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["awsdms_validation_failures_v1_updates"][]},ValueTypes["awsdms_validation_failures_v1_mutation_response"]],
update_coinsystem_balances?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_balances_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_balances_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["coinsystem_balances_bool_exp"]},ValueTypes["coinsystem_balances_mutation_response"]],
update_coinsystem_balances_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_balances_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_balances_set_input"],	pk_columns:ValueTypes["coinsystem_balances_pk_columns_input"]},ValueTypes["coinsystem_balances"]],
update_coinsystem_balances_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["coinsystem_balances_updates"][]},ValueTypes["coinsystem_balances_mutation_response"]],
update_coinsystem_bonus_coins?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_bonus_coins_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_bonus_coins_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["coinsystem_bonus_coins_bool_exp"]},ValueTypes["coinsystem_bonus_coins_mutation_response"]],
update_coinsystem_bonus_coins_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_bonus_coins_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_bonus_coins_set_input"],	pk_columns:ValueTypes["coinsystem_bonus_coins_pk_columns_input"]},ValueTypes["coinsystem_bonus_coins"]],
update_coinsystem_bonus_coins_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["coinsystem_bonus_coins_updates"][]},ValueTypes["coinsystem_bonus_coins_mutation_response"]],
update_coinsystem_coins?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_coins_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_coins_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["coinsystem_coins_bool_exp"]},ValueTypes["coinsystem_coins_mutation_response"]],
update_coinsystem_coins_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_coins_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_coins_set_input"],	pk_columns:ValueTypes["coinsystem_coins_pk_columns_input"]},ValueTypes["coinsystem_coins"]],
update_coinsystem_coins_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["coinsystem_coins_updates"][]},ValueTypes["coinsystem_coins_mutation_response"]],
update_coinsystem_exchange_rates?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_exchange_rates_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_exchange_rates_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["coinsystem_exchange_rates_bool_exp"]},ValueTypes["coinsystem_exchange_rates_mutation_response"]],
update_coinsystem_exchange_rates_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_exchange_rates_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_exchange_rates_set_input"],	pk_columns:ValueTypes["coinsystem_exchange_rates_pk_columns_input"]},ValueTypes["coinsystem_exchange_rates"]],
update_coinsystem_exchange_rates_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["coinsystem_exchange_rates_updates"][]},ValueTypes["coinsystem_exchange_rates_mutation_response"]],
update_coinsystem_logs?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_logs_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_logs_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["coinsystem_logs_bool_exp"]},ValueTypes["coinsystem_logs_mutation_response"]],
update_coinsystem_logs_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_logs_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_logs_set_input"],	pk_columns:ValueTypes["coinsystem_logs_pk_columns_input"]},ValueTypes["coinsystem_logs"]],
update_coinsystem_logs_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["coinsystem_logs_updates"][]},ValueTypes["coinsystem_logs_mutation_response"]],
update_coinsystem_transactions?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_transactions_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_transactions_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["coinsystem_transactions_bool_exp"]},ValueTypes["coinsystem_transactions_mutation_response"]],
update_coinsystem_transactions_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_transactions_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_transactions_set_input"],	pk_columns:ValueTypes["coinsystem_transactions_pk_columns_input"]},ValueTypes["coinsystem_transactions"]],
update_coinsystem_transactions_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["coinsystem_transactions_updates"][]},ValueTypes["coinsystem_transactions_mutation_response"]],
update_coinsystem_wallets?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_wallets_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_wallets_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["coinsystem_wallets_bool_exp"]},ValueTypes["coinsystem_wallets_mutation_response"]],
update_coinsystem_wallets_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["coinsystem_wallets_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["coinsystem_wallets_set_input"],	pk_columns:ValueTypes["coinsystem_wallets_pk_columns_input"]},ValueTypes["coinsystem_wallets"]],
update_coinsystem_wallets_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["coinsystem_wallets_updates"][]},ValueTypes["coinsystem_wallets_mutation_response"]],
update_mysql_user?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["mysql_user_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["mysql_user_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["mysql_user_bool_exp"]},ValueTypes["mysql_user_mutation_response"]],
update_mysql_user_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["mysql_user_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["mysql_user_set_input"],	pk_columns:ValueTypes["mysql_user_pk_columns_input"]},ValueTypes["mysql_user"]],
update_mysql_user_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["mysql_user_updates"][]},ValueTypes["mysql_user_mutation_response"]],
update_store_payments?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["store_payments_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["store_payments_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["store_payments_bool_exp"]},ValueTypes["store_payments_mutation_response"]],
update_store_payments_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["store_payments_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["store_payments_set_input"],	pk_columns:ValueTypes["store_payments_pk_columns_input"]},ValueTypes["store_payments"]],
update_store_payments_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["store_payments_updates"][]},ValueTypes["store_payments_mutation_response"]],
update_store_products?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["store_products_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["store_products_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["store_products_bool_exp"]},ValueTypes["store_products_mutation_response"]],
update_store_products_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["store_products_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["store_products_set_input"],	pk_columns:ValueTypes["store_products_pk_columns_input"]},ValueTypes["store_products"]],
update_store_products_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["store_products_updates"][]},ValueTypes["store_products_mutation_response"]],
update_store_purchases?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["store_purchases_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["store_purchases_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["store_purchases_bool_exp"]},ValueTypes["store_purchases_mutation_response"]],
update_store_purchases_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["store_purchases_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["store_purchases_set_input"],	pk_columns:ValueTypes["store_purchases_pk_columns_input"]},ValueTypes["store_purchases"]],
update_store_purchases_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["store_purchases_updates"][]},ValueTypes["store_purchases_mutation_response"]],
update_store_users?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["store_users_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["store_users_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["store_users_bool_exp"]},ValueTypes["store_users_mutation_response"]],
update_store_users_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["store_users_updates"][]},ValueTypes["store_users_mutation_response"]],
update_voyce_admin_roles?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_admin_roles_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_admin_roles_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_admin_roles_bool_exp"]},ValueTypes["voyce_admin_roles_mutation_response"]],
update_voyce_admin_roles_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_admin_roles_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_admin_roles_set_input"],	pk_columns:ValueTypes["voyce_admin_roles_pk_columns_input"]},ValueTypes["voyce_admin_roles"]],
update_voyce_admin_roles_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_admin_roles_updates"][]},ValueTypes["voyce_admin_roles_mutation_response"]],
update_voyce_app_banners?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_app_banners_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_app_banners_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_app_banners_bool_exp"]},ValueTypes["voyce_app_banners_mutation_response"]],
update_voyce_app_banners_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_app_banners_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_app_banners_set_input"],	pk_columns:ValueTypes["voyce_app_banners_pk_columns_input"]},ValueTypes["voyce_app_banners"]],
update_voyce_app_banners_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_app_banners_updates"][]},ValueTypes["voyce_app_banners_mutation_response"]],
update_voyce_app_components?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["voyce_app_components_append_input"],	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["voyce_app_components_delete_at_path_input"],	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["voyce_app_components_delete_elem_input"],	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["voyce_app_components_delete_key_input"],	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_app_components_inc_input"],	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["voyce_app_components_prepend_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_app_components_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_app_components_bool_exp"]},ValueTypes["voyce_app_components_mutation_response"]],
update_voyce_app_components_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["voyce_app_components_append_input"],	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["voyce_app_components_delete_at_path_input"],	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["voyce_app_components_delete_elem_input"],	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["voyce_app_components_delete_key_input"],	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_app_components_inc_input"],	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["voyce_app_components_prepend_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_app_components_set_input"],	pk_columns:ValueTypes["voyce_app_components_pk_columns_input"]},ValueTypes["voyce_app_components"]],
update_voyce_app_components_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_app_components_updates"][]},ValueTypes["voyce_app_components_mutation_response"]],
update_voyce_audit_trail?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_audit_trail_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_audit_trail_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_audit_trail_bool_exp"]},ValueTypes["voyce_audit_trail_mutation_response"]],
update_voyce_audit_trail_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_audit_trail_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_audit_trail_set_input"],	pk_columns:ValueTypes["voyce_audit_trail_pk_columns_input"]},ValueTypes["voyce_audit_trail"]],
update_voyce_audit_trail_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_audit_trail_updates"][]},ValueTypes["voyce_audit_trail_mutation_response"]],
update_voyce_author_profiles?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_author_profiles_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_author_profiles_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_author_profiles_bool_exp"]},ValueTypes["voyce_author_profiles_mutation_response"]],
update_voyce_author_profiles_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_author_profiles_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_author_profiles_set_input"],	pk_columns:ValueTypes["voyce_author_profiles_pk_columns_input"]},ValueTypes["voyce_author_profiles"]],
update_voyce_author_profiles_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_author_profiles_updates"][]},ValueTypes["voyce_author_profiles_mutation_response"]],
update_voyce_balances?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_balances_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_balances_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_balances_bool_exp"]},ValueTypes["voyce_balances_mutation_response"]],
update_voyce_balances_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_balances_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_balances_set_input"],	pk_columns:ValueTypes["voyce_balances_pk_columns_input"]},ValueTypes["voyce_balances"]],
update_voyce_balances_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_balances_updates"][]},ValueTypes["voyce_balances_mutation_response"]],
update_voyce_banners?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_banners_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_banners_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_banners_bool_exp"]},ValueTypes["voyce_banners_mutation_response"]],
update_voyce_banners_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_banners_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_banners_set_input"],	pk_columns:ValueTypes["voyce_banners_pk_columns_input"]},ValueTypes["voyce_banners"]],
update_voyce_banners_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_banners_updates"][]},ValueTypes["voyce_banners_mutation_response"]],
update_voyce_chapter_assets?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_assets_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_assets_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_assets_bool_exp"]},ValueTypes["voyce_chapter_assets_mutation_response"]],
update_voyce_chapter_assets_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_assets_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_assets_set_input"],	pk_columns:ValueTypes["voyce_chapter_assets_pk_columns_input"]},ValueTypes["voyce_chapter_assets"]],
update_voyce_chapter_assets_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_assets_updates"][]},ValueTypes["voyce_chapter_assets_mutation_response"]],
update_voyce_chapter_bookmarks?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_bookmarks_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_bookmarks_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_bookmarks_bool_exp"]},ValueTypes["voyce_chapter_bookmarks_mutation_response"]],
update_voyce_chapter_bookmarks_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_bookmarks_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_bookmarks_set_input"],	pk_columns:ValueTypes["voyce_chapter_bookmarks_pk_columns_input"]},ValueTypes["voyce_chapter_bookmarks"]],
update_voyce_chapter_bookmarks_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_bookmarks_updates"][]},ValueTypes["voyce_chapter_bookmarks_mutation_response"]],
update_voyce_chapter_comments?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_comments_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_comments_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_comments_bool_exp"]},ValueTypes["voyce_chapter_comments_mutation_response"]],
update_voyce_chapter_comments_07252024?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_comments_07252024_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_comments_07252024_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_comments_07252024_bool_exp"]},ValueTypes["voyce_chapter_comments_07252024_mutation_response"]],
update_voyce_chapter_comments_07252024_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_comments_07252024_updates"][]},ValueTypes["voyce_chapter_comments_07252024_mutation_response"]],
update_voyce_chapter_comments_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_comments_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_comments_set_input"],	pk_columns:ValueTypes["voyce_chapter_comments_pk_columns_input"]},ValueTypes["voyce_chapter_comments"]],
update_voyce_chapter_comments_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_comments_updates"][]},ValueTypes["voyce_chapter_comments_mutation_response"]],
update_voyce_chapter_comments_reaction?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_comments_reaction_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_comments_reaction_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_comments_reaction_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction_mutation_response"]],
update_voyce_chapter_comments_reaction_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_comments_reaction_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_comments_reaction_set_input"],	pk_columns:ValueTypes["voyce_chapter_comments_reaction_pk_columns_input"]},ValueTypes["voyce_chapter_comments_reaction"]],
update_voyce_chapter_comments_reaction_count?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_comments_reaction_count_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_comments_reaction_count_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_comments_reaction_count_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction_count_mutation_response"]],
update_voyce_chapter_comments_reaction_count_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_comments_reaction_count_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_comments_reaction_count_set_input"],	pk_columns:ValueTypes["voyce_chapter_comments_reaction_count_pk_columns_input"]},ValueTypes["voyce_chapter_comments_reaction_count"]],
update_voyce_chapter_comments_reaction_count_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_comments_reaction_count_updates"][]},ValueTypes["voyce_chapter_comments_reaction_count_mutation_response"]],
update_voyce_chapter_comments_reaction_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_comments_reaction_updates"][]},ValueTypes["voyce_chapter_comments_reaction_mutation_response"]],
update_voyce_chapter_comments_reports?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_comments_reports_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_comments_reports_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_comments_reports_bool_exp"]},ValueTypes["voyce_chapter_comments_reports_mutation_response"]],
update_voyce_chapter_comments_reports_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_comments_reports_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_comments_reports_set_input"],	pk_columns:ValueTypes["voyce_chapter_comments_reports_pk_columns_input"]},ValueTypes["voyce_chapter_comments_reports"]],
update_voyce_chapter_comments_reports_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_comments_reports_updates"][]},ValueTypes["voyce_chapter_comments_reports_mutation_response"]],
update_voyce_chapter_contents?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_contents_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_contents_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_contents_bool_exp"]},ValueTypes["voyce_chapter_contents_mutation_response"]],
update_voyce_chapter_contents_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_contents_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_contents_set_input"],	pk_columns:ValueTypes["voyce_chapter_contents_pk_columns_input"]},ValueTypes["voyce_chapter_contents"]],
update_voyce_chapter_contents_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_contents_updates"][]},ValueTypes["voyce_chapter_contents_mutation_response"]],
update_voyce_chapter_contents_old?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_contents_old_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_contents_old_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_contents_old_bool_exp"]},ValueTypes["voyce_chapter_contents_old_mutation_response"]],
update_voyce_chapter_contents_old_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_contents_old_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_contents_old_set_input"],	pk_columns:ValueTypes["voyce_chapter_contents_old_pk_columns_input"]},ValueTypes["voyce_chapter_contents_old"]],
update_voyce_chapter_contents_old_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_contents_old_updates"][]},ValueTypes["voyce_chapter_contents_old_mutation_response"]],
update_voyce_chapter_effects?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_effects_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_effects_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_effects_bool_exp"]},ValueTypes["voyce_chapter_effects_mutation_response"]],
update_voyce_chapter_effects_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_effects_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_effects_set_input"],	pk_columns:ValueTypes["voyce_chapter_effects_pk_columns_input"]},ValueTypes["voyce_chapter_effects"]],
update_voyce_chapter_effects_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_effects_updates"][]},ValueTypes["voyce_chapter_effects_mutation_response"]],
update_voyce_chapter_images?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_images_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_images_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_images_bool_exp"]},ValueTypes["voyce_chapter_images_mutation_response"]],
update_voyce_chapter_images_07252024?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_images_07252024_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_images_07252024_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_images_07252024_bool_exp"]},ValueTypes["voyce_chapter_images_07252024_mutation_response"]],
update_voyce_chapter_images_07252024_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_images_07252024_updates"][]},ValueTypes["voyce_chapter_images_07252024_mutation_response"]],
update_voyce_chapter_images_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_images_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_images_set_input"],	pk_columns:ValueTypes["voyce_chapter_images_pk_columns_input"]},ValueTypes["voyce_chapter_images"]],
update_voyce_chapter_images_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_images_updates"][]},ValueTypes["voyce_chapter_images_mutation_response"]],
update_voyce_chapter_likes?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_likes_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_likes_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_likes_bool_exp"]},ValueTypes["voyce_chapter_likes_mutation_response"]],
update_voyce_chapter_likes_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_likes_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_likes_set_input"],	pk_columns:ValueTypes["voyce_chapter_likes_pk_columns_input"]},ValueTypes["voyce_chapter_likes"]],
update_voyce_chapter_likes_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_likes_updates"][]},ValueTypes["voyce_chapter_likes_mutation_response"]],
update_voyce_chapter_locks?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_locks_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_locks_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_locks_bool_exp"]},ValueTypes["voyce_chapter_locks_mutation_response"]],
update_voyce_chapter_locks_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_locks_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_locks_set_input"],	pk_columns:ValueTypes["voyce_chapter_locks_pk_columns_input"]},ValueTypes["voyce_chapter_locks"]],
update_voyce_chapter_locks_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_locks_updates"][]},ValueTypes["voyce_chapter_locks_mutation_response"]],
update_voyce_chapter_panel_views?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_panel_views_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_panel_views_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_panel_views_bool_exp"]},ValueTypes["voyce_chapter_panel_views_mutation_response"]],
update_voyce_chapter_panel_views_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_panel_views_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_panel_views_set_input"],	pk_columns:ValueTypes["voyce_chapter_panel_views_pk_columns_input"]},ValueTypes["voyce_chapter_panel_views"]],
update_voyce_chapter_panel_views_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_panel_views_updates"][]},ValueTypes["voyce_chapter_panel_views_mutation_response"]],
update_voyce_chapter_panels?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_panels_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_panels_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_panels_bool_exp"]},ValueTypes["voyce_chapter_panels_mutation_response"]],
update_voyce_chapter_panels_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_panels_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_panels_set_input"],	pk_columns:ValueTypes["voyce_chapter_panels_pk_columns_input"]},ValueTypes["voyce_chapter_panels"]],
update_voyce_chapter_panels_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_panels_updates"][]},ValueTypes["voyce_chapter_panels_mutation_response"]],
update_voyce_chapter_publish_schedules?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_publish_schedules_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_publish_schedules_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_publish_schedules_bool_exp"]},ValueTypes["voyce_chapter_publish_schedules_mutation_response"]],
update_voyce_chapter_publish_schedules_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_publish_schedules_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_publish_schedules_set_input"],	pk_columns:ValueTypes["voyce_chapter_publish_schedules_pk_columns_input"]},ValueTypes["voyce_chapter_publish_schedules"]],
update_voyce_chapter_publish_schedules_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_publish_schedules_updates"][]},ValueTypes["voyce_chapter_publish_schedules_mutation_response"]],
update_voyce_chapter_reactions?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_reactions_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_reactions_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_reactions_bool_exp"]},ValueTypes["voyce_chapter_reactions_mutation_response"]],
update_voyce_chapter_reactions_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_reactions_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_reactions_set_input"],	pk_columns:ValueTypes["voyce_chapter_reactions_pk_columns_input"]},ValueTypes["voyce_chapter_reactions"]],
update_voyce_chapter_reactions_count?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_reactions_count_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_reactions_count_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_reactions_count_bool_exp"]},ValueTypes["voyce_chapter_reactions_count_mutation_response"]],
update_voyce_chapter_reactions_count_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_reactions_count_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_reactions_count_set_input"],	pk_columns:ValueTypes["voyce_chapter_reactions_count_pk_columns_input"]},ValueTypes["voyce_chapter_reactions_count"]],
update_voyce_chapter_reactions_count_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_reactions_count_updates"][]},ValueTypes["voyce_chapter_reactions_count_mutation_response"]],
update_voyce_chapter_reactions_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_reactions_updates"][]},ValueTypes["voyce_chapter_reactions_mutation_response"]],
update_voyce_chapter_view_logs?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_view_logs_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_view_logs_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_view_logs_bool_exp"]},ValueTypes["voyce_chapter_view_logs_mutation_response"]],
update_voyce_chapter_view_logs_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_view_logs_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_view_logs_set_input"],	pk_columns:ValueTypes["voyce_chapter_view_logs_pk_columns_input"]},ValueTypes["voyce_chapter_view_logs"]],
update_voyce_chapter_view_logs_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_view_logs_updates"][]},ValueTypes["voyce_chapter_view_logs_mutation_response"]],
update_voyce_chapter_views?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_views_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_views_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_views_bool_exp"]},ValueTypes["voyce_chapter_views_mutation_response"]],
update_voyce_chapter_views_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_views_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_views_set_input"],	pk_columns:ValueTypes["voyce_chapter_views_pk_columns_input"]},ValueTypes["voyce_chapter_views"]],
update_voyce_chapter_views_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapter_views_updates"][]},ValueTypes["voyce_chapter_views_mutation_response"]],
update_voyce_chapters?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapters_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapters_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapters_bool_exp"]},ValueTypes["voyce_chapters_mutation_response"]],
update_voyce_chapters_07252024?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapters_07252024_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapters_07252024_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapters_07252024_bool_exp"]},ValueTypes["voyce_chapters_07252024_mutation_response"]],
update_voyce_chapters_07252024_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapters_07252024_updates"][]},ValueTypes["voyce_chapters_07252024_mutation_response"]],
update_voyce_chapters_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapters_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapters_set_input"],	pk_columns:ValueTypes["voyce_chapters_pk_columns_input"]},ValueTypes["voyce_chapters"]],
update_voyce_chapters_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_chapters_updates"][]},ValueTypes["voyce_chapters_mutation_response"]],
update_voyce_collaborator_roles?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_collaborator_roles_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_collaborator_roles_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_collaborator_roles_bool_exp"]},ValueTypes["voyce_collaborator_roles_mutation_response"]],
update_voyce_collaborator_roles_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_collaborator_roles_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_collaborator_roles_set_input"],	pk_columns:ValueTypes["voyce_collaborator_roles_pk_columns_input"]},ValueTypes["voyce_collaborator_roles"]],
update_voyce_collaborator_roles_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_collaborator_roles_updates"][]},ValueTypes["voyce_collaborator_roles_mutation_response"]],
update_voyce_comic_types?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_comic_types_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_comic_types_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_comic_types_bool_exp"]},ValueTypes["voyce_comic_types_mutation_response"]],
update_voyce_comic_types_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_comic_types_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_comic_types_set_input"],	pk_columns:ValueTypes["voyce_comic_types_pk_columns_input"]},ValueTypes["voyce_comic_types"]],
update_voyce_comic_types_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_comic_types_updates"][]},ValueTypes["voyce_comic_types_mutation_response"]],
update_voyce_community_blurbs?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_community_blurbs_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_community_blurbs_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_community_blurbs_bool_exp"]},ValueTypes["voyce_community_blurbs_mutation_response"]],
update_voyce_community_blurbs_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_community_blurbs_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_community_blurbs_set_input"],	pk_columns:ValueTypes["voyce_community_blurbs_pk_columns_input"]},ValueTypes["voyce_community_blurbs"]],
update_voyce_community_blurbs_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_community_blurbs_updates"][]},ValueTypes["voyce_community_blurbs_mutation_response"]],
update_voyce_contact_us?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_contact_us_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_contact_us_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_contact_us_bool_exp"]},ValueTypes["voyce_contact_us_mutation_response"]],
update_voyce_contact_us_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_contact_us_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_contact_us_set_input"],	pk_columns:ValueTypes["voyce_contact_us_pk_columns_input"]},ValueTypes["voyce_contact_us"]],
update_voyce_contact_us_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_contact_us_updates"][]},ValueTypes["voyce_contact_us_mutation_response"]],
update_voyce_content_report?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_content_report_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_content_report_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_content_report_bool_exp"]},ValueTypes["voyce_content_report_mutation_response"]],
update_voyce_content_report_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_content_report_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_content_report_set_input"],	pk_columns:ValueTypes["voyce_content_report_pk_columns_input"]},ValueTypes["voyce_content_report"]],
update_voyce_content_report_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_content_report_updates"][]},ValueTypes["voyce_content_report_mutation_response"]],
update_voyce_contest_data?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_contest_data_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_contest_data_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_contest_data_bool_exp"]},ValueTypes["voyce_contest_data_mutation_response"]],
update_voyce_contest_data_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_contest_data_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_contest_data_set_input"],	pk_columns:ValueTypes["voyce_contest_data_pk_columns_input"]},ValueTypes["voyce_contest_data"]],
update_voyce_contest_data_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_contest_data_updates"][]},ValueTypes["voyce_contest_data_mutation_response"]],
update_voyce_countries?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_countries_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_countries_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_countries_bool_exp"]},ValueTypes["voyce_countries_mutation_response"]],
update_voyce_countries_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_countries_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_countries_set_input"],	pk_columns:ValueTypes["voyce_countries_pk_columns_input"]},ValueTypes["voyce_countries"]],
update_voyce_countries_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_countries_updates"][]},ValueTypes["voyce_countries_mutation_response"]],
update_voyce_csat_ratings?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_csat_ratings_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_csat_ratings_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_csat_ratings_bool_exp"]},ValueTypes["voyce_csat_ratings_mutation_response"]],
update_voyce_csat_ratings_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_csat_ratings_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_csat_ratings_set_input"],	pk_columns:ValueTypes["voyce_csat_ratings_pk_columns_input"]},ValueTypes["voyce_csat_ratings"]],
update_voyce_csat_ratings_data?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_csat_ratings_data_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_csat_ratings_data_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_csat_ratings_data_bool_exp"]},ValueTypes["voyce_csat_ratings_data_mutation_response"]],
update_voyce_csat_ratings_data_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_csat_ratings_data_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_csat_ratings_data_set_input"],	pk_columns:ValueTypes["voyce_csat_ratings_data_pk_columns_input"]},ValueTypes["voyce_csat_ratings_data"]],
update_voyce_csat_ratings_data_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_csat_ratings_data_updates"][]},ValueTypes["voyce_csat_ratings_data_mutation_response"]],
update_voyce_csat_ratings_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_csat_ratings_updates"][]},ValueTypes["voyce_csat_ratings_mutation_response"]],
update_voyce_education_types?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_education_types_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_education_types_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_education_types_bool_exp"]},ValueTypes["voyce_education_types_mutation_response"]],
update_voyce_education_types_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_education_types_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_education_types_set_input"],	pk_columns:ValueTypes["voyce_education_types_pk_columns_input"]},ValueTypes["voyce_education_types"]],
update_voyce_education_types_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_education_types_updates"][]},ValueTypes["voyce_education_types_mutation_response"]],
update_voyce_erp_login_roles?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_erp_login_roles_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_erp_login_roles_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_erp_login_roles_bool_exp"]},ValueTypes["voyce_erp_login_roles_mutation_response"]],
update_voyce_erp_login_roles_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_erp_login_roles_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_erp_login_roles_set_input"],	pk_columns:ValueTypes["voyce_erp_login_roles_pk_columns_input"]},ValueTypes["voyce_erp_login_roles"]],
update_voyce_erp_login_roles_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_erp_login_roles_updates"][]},ValueTypes["voyce_erp_login_roles_mutation_response"]],
update_voyce_etl_series_views_logs?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_etl_series_views_logs_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_etl_series_views_logs_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_etl_series_views_logs_bool_exp"]},ValueTypes["voyce_etl_series_views_logs_mutation_response"]],
update_voyce_etl_series_views_logs_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_etl_series_views_logs_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_etl_series_views_logs_set_input"],	pk_columns:ValueTypes["voyce_etl_series_views_logs_pk_columns_input"]},ValueTypes["voyce_etl_series_views_logs"]],
update_voyce_etl_series_views_logs_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_etl_series_views_logs_updates"][]},ValueTypes["voyce_etl_series_views_logs_mutation_response"]],
update_voyce_event_schedules?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_event_schedules_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_event_schedules_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_event_schedules_bool_exp"]},ValueTypes["voyce_event_schedules_mutation_response"]],
update_voyce_event_schedules_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_event_schedules_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_event_schedules_set_input"],	pk_columns:ValueTypes["voyce_event_schedules_pk_columns_input"]},ValueTypes["voyce_event_schedules"]],
update_voyce_event_schedules_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_event_schedules_updates"][]},ValueTypes["voyce_event_schedules_mutation_response"]],
update_voyce_genres?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_genres_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_genres_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_genres_bool_exp"]},ValueTypes["voyce_genres_mutation_response"]],
update_voyce_genres_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_genres_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_genres_set_input"],	pk_columns:ValueTypes["voyce_genres_pk_columns_input"]},ValueTypes["voyce_genres"]],
update_voyce_genres_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_genres_updates"][]},ValueTypes["voyce_genres_mutation_response"]],
update_voyce_guest_gtags?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_guest_gtags_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_guest_gtags_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_guest_gtags_bool_exp"]},ValueTypes["voyce_guest_gtags_mutation_response"]],
update_voyce_guest_gtags_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_guest_gtags_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_guest_gtags_set_input"],	pk_columns:ValueTypes["voyce_guest_gtags_pk_columns_input"]},ValueTypes["voyce_guest_gtags"]],
update_voyce_guest_gtags_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_guest_gtags_updates"][]},ValueTypes["voyce_guest_gtags_mutation_response"]],
update_voyce_metabases?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_metabases_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_metabases_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_metabases_bool_exp"]},ValueTypes["voyce_metabases_mutation_response"]],
update_voyce_metabases_access?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_metabases_access_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_metabases_access_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_metabases_access_bool_exp"]},ValueTypes["voyce_metabases_access_mutation_response"]],
update_voyce_metabases_access_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_metabases_access_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_metabases_access_set_input"],	pk_columns:ValueTypes["voyce_metabases_access_pk_columns_input"]},ValueTypes["voyce_metabases_access"]],
update_voyce_metabases_access_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_metabases_access_updates"][]},ValueTypes["voyce_metabases_access_mutation_response"]],
update_voyce_metabases_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_metabases_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_metabases_set_input"],	pk_columns:ValueTypes["voyce_metabases_pk_columns_input"]},ValueTypes["voyce_metabases"]],
update_voyce_metabases_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_metabases_updates"][]},ValueTypes["voyce_metabases_mutation_response"]],
update_voyce_newsletter?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_newsletter_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_newsletter_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_newsletter_bool_exp"]},ValueTypes["voyce_newsletter_mutation_response"]],
update_voyce_newsletter_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_newsletter_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_newsletter_set_input"],	pk_columns:ValueTypes["voyce_newsletter_pk_columns_input"]},ValueTypes["voyce_newsletter"]],
update_voyce_newsletter_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_newsletter_updates"][]},ValueTypes["voyce_newsletter_mutation_response"]],
update_voyce_notification_series_settings?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_notification_series_settings_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_notification_series_settings_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_notification_series_settings_bool_exp"]},ValueTypes["voyce_notification_series_settings_mutation_response"]],
update_voyce_notification_series_settings_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_notification_series_settings_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_notification_series_settings_set_input"],	pk_columns:ValueTypes["voyce_notification_series_settings_pk_columns_input"]},ValueTypes["voyce_notification_series_settings"]],
update_voyce_notification_series_settings_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_notification_series_settings_updates"][]},ValueTypes["voyce_notification_series_settings_mutation_response"]],
update_voyce_notification_settings?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_notification_settings_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_notification_settings_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_notification_settings_bool_exp"]},ValueTypes["voyce_notification_settings_mutation_response"]],
update_voyce_notification_settings_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_notification_settings_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_notification_settings_set_input"],	pk_columns:ValueTypes["voyce_notification_settings_pk_columns_input"]},ValueTypes["voyce_notification_settings"]],
update_voyce_notification_settings_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_notification_settings_updates"][]},ValueTypes["voyce_notification_settings_mutation_response"]],
update_voyce_notifications?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["voyce_notifications_append_input"],	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["voyce_notifications_delete_at_path_input"],	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["voyce_notifications_delete_elem_input"],	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["voyce_notifications_delete_key_input"],	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_notifications_inc_input"],	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["voyce_notifications_prepend_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_notifications_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_notifications_bool_exp"]},ValueTypes["voyce_notifications_mutation_response"]],
update_voyce_notifications_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["voyce_notifications_append_input"],	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["voyce_notifications_delete_at_path_input"],	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["voyce_notifications_delete_elem_input"],	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["voyce_notifications_delete_key_input"],	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_notifications_inc_input"],	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["voyce_notifications_prepend_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_notifications_set_input"],	pk_columns:ValueTypes["voyce_notifications_pk_columns_input"]},ValueTypes["voyce_notifications"]],
update_voyce_notifications_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_notifications_updates"][]},ValueTypes["voyce_notifications_mutation_response"]],
update_voyce_page_banners?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_page_banners_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_page_banners_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_page_banners_bool_exp"]},ValueTypes["voyce_page_banners_mutation_response"]],
update_voyce_page_banners_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_page_banners_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_page_banners_set_input"],	pk_columns:ValueTypes["voyce_page_banners_pk_columns_input"]},ValueTypes["voyce_page_banners"]],
update_voyce_page_banners_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_page_banners_updates"][]},ValueTypes["voyce_page_banners_mutation_response"]],
update_voyce_page_components?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_page_components_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_page_components_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_page_components_bool_exp"]},ValueTypes["voyce_page_components_mutation_response"]],
update_voyce_page_components_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_page_components_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_page_components_set_input"],	pk_columns:ValueTypes["voyce_page_components_pk_columns_input"]},ValueTypes["voyce_page_components"]],
update_voyce_page_components_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_page_components_updates"][]},ValueTypes["voyce_page_components_mutation_response"]],
update_voyce_pages?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_pages_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_pages_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_pages_bool_exp"]},ValueTypes["voyce_pages_mutation_response"]],
update_voyce_pages_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_pages_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_pages_set_input"],	pk_columns:ValueTypes["voyce_pages_pk_columns_input"]},ValueTypes["voyce_pages"]],
update_voyce_pages_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_pages_updates"][]},ValueTypes["voyce_pages_mutation_response"]],
update_voyce_panels_comments?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_panels_comments_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_panels_comments_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_panels_comments_bool_exp"]},ValueTypes["voyce_panels_comments_mutation_response"]],
update_voyce_panels_comments_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_panels_comments_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_panels_comments_set_input"],	pk_columns:ValueTypes["voyce_panels_comments_pk_columns_input"]},ValueTypes["voyce_panels_comments"]],
update_voyce_panels_comments_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_panels_comments_updates"][]},ValueTypes["voyce_panels_comments_mutation_response"]],
update_voyce_panels_reactions?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_panels_reactions_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_panels_reactions_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_panels_reactions_bool_exp"]},ValueTypes["voyce_panels_reactions_mutation_response"]],
update_voyce_panels_reactions_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_panels_reactions_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_panels_reactions_set_input"],	pk_columns:ValueTypes["voyce_panels_reactions_pk_columns_input"]},ValueTypes["voyce_panels_reactions"]],
update_voyce_panels_reactions_count?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_panels_reactions_count_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_panels_reactions_count_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_panels_reactions_count_bool_exp"]},ValueTypes["voyce_panels_reactions_count_mutation_response"]],
update_voyce_panels_reactions_count_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_panels_reactions_count_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_panels_reactions_count_set_input"],	pk_columns:ValueTypes["voyce_panels_reactions_count_pk_columns_input"]},ValueTypes["voyce_panels_reactions_count"]],
update_voyce_panels_reactions_count_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_panels_reactions_count_updates"][]},ValueTypes["voyce_panels_reactions_count_mutation_response"]],
update_voyce_panels_reactions_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_panels_reactions_updates"][]},ValueTypes["voyce_panels_reactions_mutation_response"]],
update_voyce_payments_to_creators?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_payments_to_creators_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_payments_to_creators_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_payments_to_creators_bool_exp"]},ValueTypes["voyce_payments_to_creators_mutation_response"]],
update_voyce_payments_to_creators_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_payments_to_creators_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_payments_to_creators_set_input"],	pk_columns:ValueTypes["voyce_payments_to_creators_pk_columns_input"]},ValueTypes["voyce_payments_to_creators"]],
update_voyce_payments_to_creators_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_payments_to_creators_updates"][]},ValueTypes["voyce_payments_to_creators_mutation_response"]],
update_voyce_pg_rs_series_views_count?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_pg_rs_series_views_count_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_pg_rs_series_views_count_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_pg_rs_series_views_count_bool_exp"]},ValueTypes["voyce_pg_rs_series_views_count_mutation_response"]],
update_voyce_pg_rs_series_views_count_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_pg_rs_series_views_count_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_pg_rs_series_views_count_set_input"],	pk_columns:ValueTypes["voyce_pg_rs_series_views_count_pk_columns_input"]},ValueTypes["voyce_pg_rs_series_views_count"]],
update_voyce_pg_rs_series_views_count_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_pg_rs_series_views_count_updates"][]},ValueTypes["voyce_pg_rs_series_views_count_mutation_response"]],
update_voyce_polls?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_polls_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_polls_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_polls_bool_exp"]},ValueTypes["voyce_polls_mutation_response"]],
update_voyce_polls_answers?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_polls_answers_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_polls_answers_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_polls_answers_bool_exp"]},ValueTypes["voyce_polls_answers_mutation_response"]],
update_voyce_polls_answers_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_polls_answers_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_polls_answers_set_input"],	pk_columns:ValueTypes["voyce_polls_answers_pk_columns_input"]},ValueTypes["voyce_polls_answers"]],
update_voyce_polls_answers_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_polls_answers_updates"][]},ValueTypes["voyce_polls_answers_mutation_response"]],
update_voyce_polls_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_polls_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_polls_set_input"],	pk_columns:ValueTypes["voyce_polls_pk_columns_input"]},ValueTypes["voyce_polls"]],
update_voyce_polls_comments?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_polls_comments_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_polls_comments_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_polls_comments_bool_exp"]},ValueTypes["voyce_polls_comments_mutation_response"]],
update_voyce_polls_comments_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_polls_comments_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_polls_comments_set_input"],	pk_columns:ValueTypes["voyce_polls_comments_pk_columns_input"]},ValueTypes["voyce_polls_comments"]],
update_voyce_polls_comments_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_polls_comments_updates"][]},ValueTypes["voyce_polls_comments_mutation_response"]],
update_voyce_polls_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_polls_updates"][]},ValueTypes["voyce_polls_mutation_response"]],
update_voyce_polls_reactions?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_polls_reactions_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_polls_reactions_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_polls_reactions_bool_exp"]},ValueTypes["voyce_polls_reactions_mutation_response"]],
update_voyce_polls_reactions_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_polls_reactions_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_polls_reactions_set_input"],	pk_columns:ValueTypes["voyce_polls_reactions_pk_columns_input"]},ValueTypes["voyce_polls_reactions"]],
update_voyce_polls_reactions_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_polls_reactions_updates"][]},ValueTypes["voyce_polls_reactions_mutation_response"]],
update_voyce_polls_votes?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_polls_votes_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_polls_votes_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_polls_votes_bool_exp"]},ValueTypes["voyce_polls_votes_mutation_response"]],
update_voyce_polls_votes_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_polls_votes_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_polls_votes_set_input"],	pk_columns:ValueTypes["voyce_polls_votes_pk_columns_input"]},ValueTypes["voyce_polls_votes"]],
update_voyce_polls_votes_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_polls_votes_updates"][]},ValueTypes["voyce_polls_votes_mutation_response"]],
update_voyce_popup_components?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_popup_components_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_popup_components_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_popup_components_bool_exp"]},ValueTypes["voyce_popup_components_mutation_response"]],
update_voyce_popup_components_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_popup_components_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_popup_components_set_input"],	pk_columns:ValueTypes["voyce_popup_components_pk_columns_input"]},ValueTypes["voyce_popup_components"]],
update_voyce_popup_components_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_popup_components_updates"][]},ValueTypes["voyce_popup_components_mutation_response"]],
update_voyce_popup_emails?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_popup_emails_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_popup_emails_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_popup_emails_bool_exp"]},ValueTypes["voyce_popup_emails_mutation_response"]],
update_voyce_popup_emails_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_popup_emails_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_popup_emails_set_input"],	pk_columns:ValueTypes["voyce_popup_emails_pk_columns_input"]},ValueTypes["voyce_popup_emails"]],
update_voyce_popup_emails_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_popup_emails_updates"][]},ValueTypes["voyce_popup_emails_mutation_response"]],
update_voyce_posts?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_posts_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_posts_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_posts_bool_exp"]},ValueTypes["voyce_posts_mutation_response"]],
update_voyce_posts_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_posts_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_posts_set_input"],	pk_columns:ValueTypes["voyce_posts_pk_columns_input"]},ValueTypes["voyce_posts"]],
update_voyce_posts_comments?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_posts_comments_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_posts_comments_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_posts_comments_bool_exp"]},ValueTypes["voyce_posts_comments_mutation_response"]],
update_voyce_posts_comments_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_posts_comments_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_posts_comments_set_input"],	pk_columns:ValueTypes["voyce_posts_comments_pk_columns_input"]},ValueTypes["voyce_posts_comments"]],
update_voyce_posts_comments_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_posts_comments_updates"][]},ValueTypes["voyce_posts_comments_mutation_response"]],
update_voyce_posts_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_posts_updates"][]},ValueTypes["voyce_posts_mutation_response"]],
update_voyce_posts_reactions?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_posts_reactions_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_posts_reactions_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_posts_reactions_bool_exp"]},ValueTypes["voyce_posts_reactions_mutation_response"]],
update_voyce_posts_reactions_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_posts_reactions_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_posts_reactions_set_input"],	pk_columns:ValueTypes["voyce_posts_reactions_pk_columns_input"]},ValueTypes["voyce_posts_reactions"]],
update_voyce_posts_reactions_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_posts_reactions_updates"][]},ValueTypes["voyce_posts_reactions_mutation_response"]],
update_voyce_quest_difficulties?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_quest_difficulties_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_quest_difficulties_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_quest_difficulties_bool_exp"]},ValueTypes["voyce_quest_difficulties_mutation_response"]],
update_voyce_quest_difficulties_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_quest_difficulties_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_quest_difficulties_set_input"],	pk_columns:ValueTypes["voyce_quest_difficulties_pk_columns_input"]},ValueTypes["voyce_quest_difficulties"]],
update_voyce_quest_difficulties_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_quest_difficulties_updates"][]},ValueTypes["voyce_quest_difficulties_mutation_response"]],
update_voyce_quests?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_quests_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_quests_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_quests_bool_exp"]},ValueTypes["voyce_quests_mutation_response"]],
update_voyce_quests_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_quests_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_quests_set_input"],	pk_columns:ValueTypes["voyce_quests_pk_columns_input"]},ValueTypes["voyce_quests"]],
update_voyce_quests_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_quests_updates"][]},ValueTypes["voyce_quests_mutation_response"]],
update_voyce_quests_old?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_quests_old_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_quests_old_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_quests_old_bool_exp"]},ValueTypes["voyce_quests_old_mutation_response"]],
update_voyce_quests_old_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_quests_old_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_quests_old_set_input"],	pk_columns:ValueTypes["voyce_quests_old_pk_columns_input"]},ValueTypes["voyce_quests_old"]],
update_voyce_quests_old_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_quests_old_updates"][]},ValueTypes["voyce_quests_old_mutation_response"]],
update_voyce_recommended_series?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_recommended_series_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_recommended_series_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_recommended_series_bool_exp"]},ValueTypes["voyce_recommended_series_mutation_response"]],
update_voyce_recommended_series_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_recommended_series_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_recommended_series_set_input"],	pk_columns:ValueTypes["voyce_recommended_series_pk_columns_input"]},ValueTypes["voyce_recommended_series"]],
update_voyce_recommended_series_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_recommended_series_updates"][]},ValueTypes["voyce_recommended_series_mutation_response"]],
update_voyce_release_schedules?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_release_schedules_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_release_schedules_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_release_schedules_bool_exp"]},ValueTypes["voyce_release_schedules_mutation_response"]],
update_voyce_release_schedules_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_release_schedules_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_release_schedules_set_input"],	pk_columns:ValueTypes["voyce_release_schedules_pk_columns_input"]},ValueTypes["voyce_release_schedules"]],
update_voyce_release_schedules_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_release_schedules_updates"][]},ValueTypes["voyce_release_schedules_mutation_response"]],
update_voyce_rewards?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_rewards_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_rewards_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_rewards_bool_exp"]},ValueTypes["voyce_rewards_mutation_response"]],
update_voyce_rewards_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_rewards_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_rewards_set_input"],	pk_columns:ValueTypes["voyce_rewards_pk_columns_input"]},ValueTypes["voyce_rewards"]],
update_voyce_rewards_leaderboard?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_rewards_leaderboard_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_rewards_leaderboard_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_rewards_leaderboard_bool_exp"]},ValueTypes["voyce_rewards_leaderboard_mutation_response"]],
update_voyce_rewards_leaderboard_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_rewards_leaderboard_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_rewards_leaderboard_set_input"],	pk_columns:ValueTypes["voyce_rewards_leaderboard_pk_columns_input"]},ValueTypes["voyce_rewards_leaderboard"]],
update_voyce_rewards_leaderboard_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_rewards_leaderboard_updates"][]},ValueTypes["voyce_rewards_leaderboard_mutation_response"]],
update_voyce_rewards_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_rewards_updates"][]},ValueTypes["voyce_rewards_mutation_response"]],
update_voyce_rewards_quest?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_rewards_quest_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_rewards_quest_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_rewards_quest_bool_exp"]},ValueTypes["voyce_rewards_quest_mutation_response"]],
update_voyce_rewards_quest_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_rewards_quest_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_rewards_quest_set_input"],	pk_columns:ValueTypes["voyce_rewards_quest_pk_columns_input"]},ValueTypes["voyce_rewards_quest"]],
update_voyce_rewards_quest_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_rewards_quest_updates"][]},ValueTypes["voyce_rewards_quest_mutation_response"]],
update_voyce_sections?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_sections_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_sections_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_sections_bool_exp"]},ValueTypes["voyce_sections_mutation_response"]],
update_voyce_sections_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_sections_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_sections_set_input"],	pk_columns:ValueTypes["voyce_sections_pk_columns_input"]},ValueTypes["voyce_sections"]],
update_voyce_sections_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_sections_updates"][]},ValueTypes["voyce_sections_mutation_response"]],
update_voyce_series?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_bool_exp"]},ValueTypes["voyce_series_mutation_response"]],
update_voyce_series_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_set_input"],	pk_columns:ValueTypes["voyce_series_pk_columns_input"]},ValueTypes["voyce_series"]],
update_voyce_series_collaborators?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_collaborators_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_collaborators_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_collaborators_bool_exp"]},ValueTypes["voyce_series_collaborators_mutation_response"]],
update_voyce_series_collaborators_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_collaborators_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_collaborators_set_input"],	pk_columns:ValueTypes["voyce_series_collaborators_pk_columns_input"]},ValueTypes["voyce_series_collaborators"]],
update_voyce_series_collaborators_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_collaborators_updates"][]},ValueTypes["voyce_series_collaborators_mutation_response"]],
update_voyce_series_donations?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_donations_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_donations_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_donations_bool_exp"]},ValueTypes["voyce_series_donations_mutation_response"]],
update_voyce_series_donations_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_donations_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_donations_set_input"],	pk_columns:ValueTypes["voyce_series_donations_pk_columns_input"]},ValueTypes["voyce_series_donations"]],
update_voyce_series_donations_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_donations_updates"][]},ValueTypes["voyce_series_donations_mutation_response"]],
update_voyce_series_favorites?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_favorites_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_favorites_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_favorites_bool_exp"]},ValueTypes["voyce_series_favorites_mutation_response"]],
update_voyce_series_favorites_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_favorites_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_favorites_set_input"],	pk_columns:ValueTypes["voyce_series_favorites_pk_columns_input"]},ValueTypes["voyce_series_favorites"]],
update_voyce_series_favorites_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_favorites_updates"][]},ValueTypes["voyce_series_favorites_mutation_response"]],
update_voyce_series_featured_panels?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_featured_panels_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_featured_panels_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_featured_panels_bool_exp"]},ValueTypes["voyce_series_featured_panels_mutation_response"]],
update_voyce_series_featured_panels_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_featured_panels_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_featured_panels_set_input"],	pk_columns:ValueTypes["voyce_series_featured_panels_pk_columns_input"]},ValueTypes["voyce_series_featured_panels"]],
update_voyce_series_featured_panels_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_featured_panels_updates"][]},ValueTypes["voyce_series_featured_panels_mutation_response"]],
update_voyce_series_genre?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_genre_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_genre_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_genre_bool_exp"]},ValueTypes["voyce_series_genre_mutation_response"]],
update_voyce_series_genre_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_genre_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_genre_set_input"],	pk_columns:ValueTypes["voyce_series_genre_pk_columns_input"]},ValueTypes["voyce_series_genre"]],
update_voyce_series_genre_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_genre_updates"][]},ValueTypes["voyce_series_genre_mutation_response"]],
update_voyce_series_giveaway_participants?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_giveaway_participants_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_giveaway_participants_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_giveaway_participants_bool_exp"]},ValueTypes["voyce_series_giveaway_participants_mutation_response"]],
update_voyce_series_giveaway_participants_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_giveaway_participants_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_giveaway_participants_set_input"],	pk_columns:ValueTypes["voyce_series_giveaway_participants_pk_columns_input"]},ValueTypes["voyce_series_giveaway_participants"]],
update_voyce_series_giveaway_participants_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_giveaway_participants_updates"][]},ValueTypes["voyce_series_giveaway_participants_mutation_response"]],
update_voyce_series_hot_panels?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_hot_panels_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_hot_panels_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_hot_panels_bool_exp"]},ValueTypes["voyce_series_hot_panels_mutation_response"]],
update_voyce_series_hot_panels_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_hot_panels_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_hot_panels_set_input"],	pk_columns:ValueTypes["voyce_series_hot_panels_pk_columns_input"]},ValueTypes["voyce_series_hot_panels"]],
update_voyce_series_hot_panels_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_hot_panels_updates"][]},ValueTypes["voyce_series_hot_panels_mutation_response"]],
update_voyce_series_layouts?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_layouts_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_layouts_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_layouts_bool_exp"]},ValueTypes["voyce_series_layouts_mutation_response"]],
update_voyce_series_layouts_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_layouts_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_layouts_set_input"],	pk_columns:ValueTypes["voyce_series_layouts_pk_columns_input"]},ValueTypes["voyce_series_layouts"]],
update_voyce_series_layouts_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_layouts_updates"][]},ValueTypes["voyce_series_layouts_mutation_response"]],
update_voyce_series_leaderboard?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_leaderboard_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_leaderboard_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_leaderboard_bool_exp"]},ValueTypes["voyce_series_leaderboard_mutation_response"]],
update_voyce_series_leaderboard_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_leaderboard_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_leaderboard_set_input"],	pk_columns:ValueTypes["voyce_series_leaderboard_pk_columns_input"]},ValueTypes["voyce_series_leaderboard"]],
update_voyce_series_leaderboard_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_leaderboard_updates"][]},ValueTypes["voyce_series_leaderboard_mutation_response"]],
update_voyce_series_lock_config?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_lock_config_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_lock_config_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_lock_config_bool_exp"]},ValueTypes["voyce_series_lock_config_mutation_response"]],
update_voyce_series_lock_config_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_lock_config_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_lock_config_set_input"],	pk_columns:ValueTypes["voyce_series_lock_config_pk_columns_input"]},ValueTypes["voyce_series_lock_config"]],
update_voyce_series_lock_config_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_lock_config_updates"][]},ValueTypes["voyce_series_lock_config_mutation_response"]],
update_voyce_series_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_updates"][]},ValueTypes["voyce_series_mutation_response"]],
update_voyce_series_sponsorships_configuration?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_sponsorships_configuration_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_sponsorships_configuration_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_sponsorships_configuration_bool_exp"]},ValueTypes["voyce_series_sponsorships_configuration_mutation_response"]],
update_voyce_series_sponsorships_configuration_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_sponsorships_configuration_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_sponsorships_configuration_set_input"],	pk_columns:ValueTypes["voyce_series_sponsorships_configuration_pk_columns_input"]},ValueTypes["voyce_series_sponsorships_configuration"]],
update_voyce_series_sponsorships_configuration_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_sponsorships_configuration_updates"][]},ValueTypes["voyce_series_sponsorships_configuration_mutation_response"]],
update_voyce_series_subscription?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_subscription_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_subscription_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_subscription_bool_exp"]},ValueTypes["voyce_series_subscription_mutation_response"]],
update_voyce_series_subscription_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_subscription_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_subscription_set_input"],	pk_columns:ValueTypes["voyce_series_subscription_pk_columns_input"]},ValueTypes["voyce_series_subscription"]],
update_voyce_series_subscription_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_subscription_updates"][]},ValueTypes["voyce_series_subscription_mutation_response"]],
update_voyce_series_tags?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_tags_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_tags_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_tags_bool_exp"]},ValueTypes["voyce_series_tags_mutation_response"]],
update_voyce_series_tags_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_tags_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_tags_set_input"],	pk_columns:ValueTypes["voyce_series_tags_pk_columns_input"]},ValueTypes["voyce_series_tags"]],
update_voyce_series_tags_junction?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["voyce_series_tags_junction_append_input"],	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["voyce_series_tags_junction_delete_at_path_input"],	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["voyce_series_tags_junction_delete_elem_input"],	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["voyce_series_tags_junction_delete_key_input"],	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_tags_junction_inc_input"],	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["voyce_series_tags_junction_prepend_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_tags_junction_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_tags_junction_bool_exp"]},ValueTypes["voyce_series_tags_junction_mutation_response"]],
update_voyce_series_tags_junction_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["voyce_series_tags_junction_append_input"],	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["voyce_series_tags_junction_delete_at_path_input"],	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["voyce_series_tags_junction_delete_elem_input"],	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["voyce_series_tags_junction_delete_key_input"],	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_tags_junction_inc_input"],	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["voyce_series_tags_junction_prepend_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_tags_junction_set_input"],	pk_columns:ValueTypes["voyce_series_tags_junction_pk_columns_input"]},ValueTypes["voyce_series_tags_junction"]],
update_voyce_series_tags_junction_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_tags_junction_updates"][]},ValueTypes["voyce_series_tags_junction_mutation_response"]],
update_voyce_series_tags_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_tags_updates"][]},ValueTypes["voyce_series_tags_mutation_response"]],
update_voyce_series_top_comments?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_top_comments_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_top_comments_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_top_comments_bool_exp"]},ValueTypes["voyce_series_top_comments_mutation_response"]],
update_voyce_series_top_comments_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_top_comments_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_top_comments_set_input"],	pk_columns:ValueTypes["voyce_series_top_comments_pk_columns_input"]},ValueTypes["voyce_series_top_comments"]],
update_voyce_series_top_comments_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_top_comments_updates"][]},ValueTypes["voyce_series_top_comments_mutation_response"]],
update_voyce_series_top_likes?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_top_likes_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_top_likes_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_top_likes_bool_exp"]},ValueTypes["voyce_series_top_likes_mutation_response"]],
update_voyce_series_top_likes_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_top_likes_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_top_likes_set_input"],	pk_columns:ValueTypes["voyce_series_top_likes_pk_columns_input"]},ValueTypes["voyce_series_top_likes"]],
update_voyce_series_top_likes_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_top_likes_updates"][]},ValueTypes["voyce_series_top_likes_mutation_response"]],
update_voyce_series_top_views?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_top_views_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_top_views_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_top_views_bool_exp"]},ValueTypes["voyce_series_top_views_mutation_response"]],
update_voyce_series_top_views_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_top_views_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_top_views_set_input"],	pk_columns:ValueTypes["voyce_series_top_views_pk_columns_input"]},ValueTypes["voyce_series_top_views"]],
update_voyce_series_top_views_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_top_views_updates"][]},ValueTypes["voyce_series_top_views_mutation_response"]],
update_voyce_series_views_adjustment?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_views_adjustment_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_views_adjustment_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_views_adjustment_bool_exp"]},ValueTypes["voyce_series_views_adjustment_mutation_response"]],
update_voyce_series_views_adjustment_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_views_adjustment_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_views_adjustment_set_input"],	pk_columns:ValueTypes["voyce_series_views_adjustment_pk_columns_input"]},ValueTypes["voyce_series_views_adjustment"]],
update_voyce_series_views_adjustment_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_views_adjustment_updates"][]},ValueTypes["voyce_series_views_adjustment_mutation_response"]],
update_voyce_series_votes?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_votes_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_votes_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_votes_bool_exp"]},ValueTypes["voyce_series_votes_mutation_response"]],
update_voyce_series_votes_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_votes_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_votes_set_input"],	pk_columns:ValueTypes["voyce_series_votes_pk_columns_input"]},ValueTypes["voyce_series_votes"]],
update_voyce_series_votes_count?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_votes_count_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_votes_count_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_votes_count_bool_exp"]},ValueTypes["voyce_series_votes_count_mutation_response"]],
update_voyce_series_votes_count_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_votes_count_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_votes_count_set_input"],	pk_columns:ValueTypes["voyce_series_votes_count_pk_columns_input"]},ValueTypes["voyce_series_votes_count"]],
update_voyce_series_votes_count_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_votes_count_updates"][]},ValueTypes["voyce_series_votes_count_mutation_response"]],
update_voyce_series_votes_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_votes_updates"][]},ValueTypes["voyce_series_votes_mutation_response"]],
update_voyce_series_voting_schedules?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_voting_schedules_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_voting_schedules_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_voting_schedules_bool_exp"]},ValueTypes["voyce_series_voting_schedules_mutation_response"]],
update_voyce_series_voting_schedules_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_voting_schedules_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_voting_schedules_set_input"],	pk_columns:ValueTypes["voyce_series_voting_schedules_pk_columns_input"]},ValueTypes["voyce_series_voting_schedules"]],
update_voyce_series_voting_schedules_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_series_voting_schedules_updates"][]},ValueTypes["voyce_series_voting_schedules_mutation_response"]],
update_voyce_sponsorships?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_sponsorships_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_sponsorships_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_sponsorships_bool_exp"]},ValueTypes["voyce_sponsorships_mutation_response"]],
update_voyce_sponsorships_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_sponsorships_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_sponsorships_set_input"],	pk_columns:ValueTypes["voyce_sponsorships_pk_columns_input"]},ValueTypes["voyce_sponsorships"]],
update_voyce_sponsorships_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_sponsorships_updates"][]},ValueTypes["voyce_sponsorships_mutation_response"]],
update_voyce_states?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_states_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_states_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_states_bool_exp"]},ValueTypes["voyce_states_mutation_response"]],
update_voyce_states_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_states_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_states_set_input"],	pk_columns:ValueTypes["voyce_states_pk_columns_input"]},ValueTypes["voyce_states"]],
update_voyce_states_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_states_updates"][]},ValueTypes["voyce_states_mutation_response"]],
update_voyce_stripe_payouts_setup?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_stripe_payouts_setup_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_stripe_payouts_setup_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_stripe_payouts_setup_bool_exp"]},ValueTypes["voyce_stripe_payouts_setup_mutation_response"]],
update_voyce_stripe_payouts_setup_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_stripe_payouts_setup_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_stripe_payouts_setup_set_input"],	pk_columns:ValueTypes["voyce_stripe_payouts_setup_pk_columns_input"]},ValueTypes["voyce_stripe_payouts_setup"]],
update_voyce_stripe_payouts_setup_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_stripe_payouts_setup_updates"][]},ValueTypes["voyce_stripe_payouts_setup_mutation_response"]],
update_voyce_studio?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_studio_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_studio_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_studio_bool_exp"]},ValueTypes["voyce_studio_mutation_response"]],
update_voyce_studio_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_studio_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_studio_set_input"],	pk_columns:ValueTypes["voyce_studio_pk_columns_input"]},ValueTypes["voyce_studio"]],
update_voyce_studio_followers?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_studio_followers_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_studio_followers_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_studio_followers_bool_exp"]},ValueTypes["voyce_studio_followers_mutation_response"]],
update_voyce_studio_followers_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_studio_followers_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_studio_followers_set_input"],	pk_columns:ValueTypes["voyce_studio_followers_pk_columns_input"]},ValueTypes["voyce_studio_followers"]],
update_voyce_studio_followers_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_studio_followers_updates"][]},ValueTypes["voyce_studio_followers_mutation_response"]],
update_voyce_studio_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_studio_updates"][]},ValueTypes["voyce_studio_mutation_response"]],
update_voyce_studio_series?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_studio_series_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_studio_series_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_studio_series_bool_exp"]},ValueTypes["voyce_studio_series_mutation_response"]],
update_voyce_studio_series_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_studio_series_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_studio_series_set_input"],	pk_columns:ValueTypes["voyce_studio_series_pk_columns_input"]},ValueTypes["voyce_studio_series"]],
update_voyce_studio_series_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_studio_series_updates"][]},ValueTypes["voyce_studio_series_mutation_response"]],
update_voyce_studio_views?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_studio_views_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_studio_views_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_studio_views_bool_exp"]},ValueTypes["voyce_studio_views_mutation_response"]],
update_voyce_studio_views_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_studio_views_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_studio_views_set_input"],	pk_columns:ValueTypes["voyce_studio_views_pk_columns_input"]},ValueTypes["voyce_studio_views"]],
update_voyce_studio_views_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_studio_views_updates"][]},ValueTypes["voyce_studio_views_mutation_response"]],
update_voyce_system_settings?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_system_settings_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_system_settings_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_system_settings_bool_exp"]},ValueTypes["voyce_system_settings_mutation_response"]],
update_voyce_system_settings_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_system_settings_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_system_settings_set_input"],	pk_columns:ValueTypes["voyce_system_settings_pk_columns_input"]},ValueTypes["voyce_system_settings"]],
update_voyce_system_settings_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_system_settings_updates"][]},ValueTypes["voyce_system_settings_mutation_response"]],
update_voyce_transaction_logs?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_transaction_logs_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_transaction_logs_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_transaction_logs_bool_exp"]},ValueTypes["voyce_transaction_logs_mutation_response"]],
update_voyce_transaction_logs_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_transaction_logs_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_transaction_logs_set_input"],	pk_columns:ValueTypes["voyce_transaction_logs_pk_columns_input"]},ValueTypes["voyce_transaction_logs"]],
update_voyce_transaction_logs_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_transaction_logs_updates"][]},ValueTypes["voyce_transaction_logs_mutation_response"]],
update_voyce_url_redirects?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_url_redirects_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_url_redirects_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_url_redirects_bool_exp"]},ValueTypes["voyce_url_redirects_mutation_response"]],
update_voyce_url_redirects_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_url_redirects_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_url_redirects_set_input"],	pk_columns:ValueTypes["voyce_url_redirects_pk_columns_input"]},ValueTypes["voyce_url_redirects"]],
update_voyce_url_redirects_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_url_redirects_updates"][]},ValueTypes["voyce_url_redirects_mutation_response"]],
update_voyce_user_addresses?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_addresses_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_addresses_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_addresses_bool_exp"]},ValueTypes["voyce_user_addresses_mutation_response"]],
update_voyce_user_addresses_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_addresses_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_addresses_set_input"],	pk_columns:ValueTypes["voyce_user_addresses_pk_columns_input"]},ValueTypes["voyce_user_addresses"]],
update_voyce_user_addresses_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_addresses_updates"][]},ValueTypes["voyce_user_addresses_mutation_response"]],
update_voyce_user_blocks?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_blocks_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_blocks_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_blocks_bool_exp"]},ValueTypes["voyce_user_blocks_mutation_response"]],
update_voyce_user_blocks_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_blocks_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_blocks_set_input"],	pk_columns:ValueTypes["voyce_user_blocks_pk_columns_input"]},ValueTypes["voyce_user_blocks"]],
update_voyce_user_blocks_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_blocks_updates"][]},ValueTypes["voyce_user_blocks_mutation_response"]],
update_voyce_user_chapters_unlocked?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_chapters_unlocked_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_chapters_unlocked_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_chapters_unlocked_bool_exp"]},ValueTypes["voyce_user_chapters_unlocked_mutation_response"]],
update_voyce_user_chapters_unlocked_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_chapters_unlocked_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_chapters_unlocked_set_input"],	pk_columns:ValueTypes["voyce_user_chapters_unlocked_pk_columns_input"]},ValueTypes["voyce_user_chapters_unlocked"]],
update_voyce_user_chapters_unlocked_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_chapters_unlocked_updates"][]},ValueTypes["voyce_user_chapters_unlocked_mutation_response"]],
update_voyce_user_contacts?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_contacts_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_contacts_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_contacts_bool_exp"]},ValueTypes["voyce_user_contacts_mutation_response"]],
update_voyce_user_contacts_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_contacts_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_contacts_set_input"],	pk_columns:ValueTypes["voyce_user_contacts_pk_columns_input"]},ValueTypes["voyce_user_contacts"]],
update_voyce_user_contacts_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_contacts_updates"][]},ValueTypes["voyce_user_contacts_mutation_response"]],
update_voyce_user_csat_ratings?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_csat_ratings_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_csat_ratings_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_csat_ratings_bool_exp"]},ValueTypes["voyce_user_csat_ratings_mutation_response"]],
update_voyce_user_csat_ratings_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_csat_ratings_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_csat_ratings_set_input"],	pk_columns:ValueTypes["voyce_user_csat_ratings_pk_columns_input"]},ValueTypes["voyce_user_csat_ratings"]],
update_voyce_user_csat_ratings_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_csat_ratings_updates"][]},ValueTypes["voyce_user_csat_ratings_mutation_response"]],
update_voyce_user_education?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_education_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_education_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_education_bool_exp"]},ValueTypes["voyce_user_education_mutation_response"]],
update_voyce_user_education_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_education_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_education_set_input"],	pk_columns:ValueTypes["voyce_user_education_pk_columns_input"]},ValueTypes["voyce_user_education"]],
update_voyce_user_education_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_education_updates"][]},ValueTypes["voyce_user_education_mutation_response"]],
update_voyce_user_level?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_level_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_level_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_level_bool_exp"]},ValueTypes["voyce_user_level_mutation_response"]],
update_voyce_user_level_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_level_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_level_set_input"],	pk_columns:ValueTypes["voyce_user_level_pk_columns_input"]},ValueTypes["voyce_user_level"]],
update_voyce_user_level_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_level_updates"][]},ValueTypes["voyce_user_level_mutation_response"]],
update_voyce_user_linked_accounts?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_linked_accounts_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_linked_accounts_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_linked_accounts_bool_exp"]},ValueTypes["voyce_user_linked_accounts_mutation_response"]],
update_voyce_user_linked_accounts_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_linked_accounts_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_linked_accounts_set_input"],	pk_columns:ValueTypes["voyce_user_linked_accounts_pk_columns_input"]},ValueTypes["voyce_user_linked_accounts"]],
update_voyce_user_linked_accounts_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_linked_accounts_updates"][]},ValueTypes["voyce_user_linked_accounts_mutation_response"]],
update_voyce_user_login_history?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_login_history_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_login_history_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_login_history_bool_exp"]},ValueTypes["voyce_user_login_history_mutation_response"]],
update_voyce_user_login_history_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_login_history_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_login_history_set_input"],	pk_columns:ValueTypes["voyce_user_login_history_pk_columns_input"]},ValueTypes["voyce_user_login_history"]],
update_voyce_user_login_history_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_login_history_updates"][]},ValueTypes["voyce_user_login_history_mutation_response"]],
update_voyce_user_quest_ids?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_quest_ids_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_quest_ids_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_quest_ids_bool_exp"]},ValueTypes["voyce_user_quest_ids_mutation_response"]],
update_voyce_user_quest_ids_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_quest_ids_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_quest_ids_set_input"],	pk_columns:ValueTypes["voyce_user_quest_ids_pk_columns_input"]},ValueTypes["voyce_user_quest_ids"]],
update_voyce_user_quest_ids_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_quest_ids_updates"][]},ValueTypes["voyce_user_quest_ids_mutation_response"]],
update_voyce_user_quest_rewards?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_quest_rewards_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_quest_rewards_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_quest_rewards_bool_exp"]},ValueTypes["voyce_user_quest_rewards_mutation_response"]],
update_voyce_user_quest_rewards_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_quest_rewards_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_quest_rewards_set_input"],	pk_columns:ValueTypes["voyce_user_quest_rewards_pk_columns_input"]},ValueTypes["voyce_user_quest_rewards"]],
update_voyce_user_quest_rewards_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_quest_rewards_updates"][]},ValueTypes["voyce_user_quest_rewards_mutation_response"]],
update_voyce_user_quests?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_quests_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_quests_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_quests_bool_exp"]},ValueTypes["voyce_user_quests_mutation_response"]],
update_voyce_user_quests_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_quests_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_quests_set_input"],	pk_columns:ValueTypes["voyce_user_quests_pk_columns_input"]},ValueTypes["voyce_user_quests"]],
update_voyce_user_quests_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_quests_updates"][]},ValueTypes["voyce_user_quests_mutation_response"]],
update_voyce_user_quests_old?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_quests_old_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_quests_old_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_quests_old_bool_exp"]},ValueTypes["voyce_user_quests_old_mutation_response"]],
update_voyce_user_quests_old_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_quests_old_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_quests_old_set_input"],	pk_columns:ValueTypes["voyce_user_quests_old_pk_columns_input"]},ValueTypes["voyce_user_quests_old"]],
update_voyce_user_quests_old_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_quests_old_updates"][]},ValueTypes["voyce_user_quests_old_mutation_response"]],
update_voyce_user_ranking?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_ranking_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_ranking_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_ranking_bool_exp"]},ValueTypes["voyce_user_ranking_mutation_response"]],
update_voyce_user_ranking_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_ranking_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_ranking_set_input"],	pk_columns:ValueTypes["voyce_user_ranking_pk_columns_input"]},ValueTypes["voyce_user_ranking"]],
update_voyce_user_ranking_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_ranking_updates"][]},ValueTypes["voyce_user_ranking_mutation_response"]],
update_voyce_user_reviews?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_reviews_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_reviews_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_reviews_bool_exp"]},ValueTypes["voyce_user_reviews_mutation_response"]],
update_voyce_user_reviews_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_reviews_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_reviews_set_input"],	pk_columns:ValueTypes["voyce_user_reviews_pk_columns_input"]},ValueTypes["voyce_user_reviews"]],
update_voyce_user_reviews_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_reviews_updates"][]},ValueTypes["voyce_user_reviews_mutation_response"]],
update_voyce_user_rewards?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_rewards_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_rewards_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_rewards_bool_exp"]},ValueTypes["voyce_user_rewards_mutation_response"]],
update_voyce_user_rewards_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_rewards_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_rewards_set_input"],	pk_columns:ValueTypes["voyce_user_rewards_pk_columns_input"]},ValueTypes["voyce_user_rewards"]],
update_voyce_user_rewards_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_rewards_updates"][]},ValueTypes["voyce_user_rewards_mutation_response"]],
update_voyce_user_roles?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_roles_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_roles_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_roles_bool_exp"]},ValueTypes["voyce_user_roles_mutation_response"]],
update_voyce_user_roles_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_roles_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_roles_set_input"],	pk_columns:ValueTypes["voyce_user_roles_pk_columns_input"]},ValueTypes["voyce_user_roles"]],
update_voyce_user_roles_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_roles_updates"][]},ValueTypes["voyce_user_roles_mutation_response"]],
update_voyce_user_settings?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_settings_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_settings_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_settings_bool_exp"]},ValueTypes["voyce_user_settings_mutation_response"]],
update_voyce_user_settings_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_settings_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_settings_set_input"],	pk_columns:ValueTypes["voyce_user_settings_pk_columns_input"]},ValueTypes["voyce_user_settings"]],
update_voyce_user_settings_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_settings_updates"][]},ValueTypes["voyce_user_settings_mutation_response"]],
update_voyce_user_skills?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_skills_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_skills_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_skills_bool_exp"]},ValueTypes["voyce_user_skills_mutation_response"]],
update_voyce_user_skills_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_skills_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_skills_set_input"],	pk_columns:ValueTypes["voyce_user_skills_pk_columns_input"]},ValueTypes["voyce_user_skills"]],
update_voyce_user_skills_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_skills_updates"][]},ValueTypes["voyce_user_skills_mutation_response"]],
update_voyce_user_socials?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_socials_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_socials_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_socials_bool_exp"]},ValueTypes["voyce_user_socials_mutation_response"]],
update_voyce_user_socials_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_socials_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_socials_set_input"],	pk_columns:ValueTypes["voyce_user_socials_pk_columns_input"]},ValueTypes["voyce_user_socials"]],
update_voyce_user_socials_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_socials_updates"][]},ValueTypes["voyce_user_socials_mutation_response"]],
update_voyce_user_soft_deleted?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_soft_deleted_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_soft_deleted_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_soft_deleted_bool_exp"]},ValueTypes["voyce_user_soft_deleted_mutation_response"]],
update_voyce_user_soft_deleted_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_soft_deleted_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_soft_deleted_set_input"],	pk_columns:ValueTypes["voyce_user_soft_deleted_pk_columns_input"]},ValueTypes["voyce_user_soft_deleted"]],
update_voyce_user_soft_deleted_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_soft_deleted_updates"][]},ValueTypes["voyce_user_soft_deleted_mutation_response"]],
update_voyce_user_vtags?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_vtags_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_vtags_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_vtags_bool_exp"]},ValueTypes["voyce_user_vtags_mutation_response"]],
update_voyce_user_vtags_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_vtags_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_vtags_set_input"],	pk_columns:ValueTypes["voyce_user_vtags_pk_columns_input"]},ValueTypes["voyce_user_vtags"]],
update_voyce_user_vtags_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_vtags_updates"][]},ValueTypes["voyce_user_vtags_mutation_response"]],
update_voyce_user_wins?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_wins_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_wins_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_wins_bool_exp"]},ValueTypes["voyce_user_wins_mutation_response"]],
update_voyce_user_wins_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_wins_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_wins_set_input"],	pk_columns:ValueTypes["voyce_user_wins_pk_columns_input"]},ValueTypes["voyce_user_wins"]],
update_voyce_user_wins_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_user_wins_updates"][]},ValueTypes["voyce_user_wins_mutation_response"]],
update_voyce_users?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["voyce_users_append_input"],	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["voyce_users_delete_at_path_input"],	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["voyce_users_delete_elem_input"],	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["voyce_users_delete_key_input"],	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_users_inc_input"],	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["voyce_users_prepend_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_users_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_users_bool_exp"]},ValueTypes["voyce_users_mutation_response"]],
update_voyce_users_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["voyce_users_append_input"],	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["voyce_users_delete_at_path_input"],	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["voyce_users_delete_elem_input"],	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["voyce_users_delete_key_input"],	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_users_inc_input"],	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["voyce_users_prepend_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_users_set_input"],	pk_columns:ValueTypes["voyce_users_pk_columns_input"]},ValueTypes["voyce_users"]],
update_voyce_users_followers?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_users_followers_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_users_followers_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_users_followers_bool_exp"]},ValueTypes["voyce_users_followers_mutation_response"]],
update_voyce_users_followers_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_users_followers_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_users_followers_set_input"],	pk_columns:ValueTypes["voyce_users_followers_pk_columns_input"]},ValueTypes["voyce_users_followers"]],
update_voyce_users_followers_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_users_followers_updates"][]},ValueTypes["voyce_users_followers_mutation_response"]],
update_voyce_users_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_users_updates"][]},ValueTypes["voyce_users_mutation_response"]],
update_voyce_users_private?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_users_private_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_users_private_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_users_private_bool_exp"]},ValueTypes["voyce_users_private_mutation_response"]],
update_voyce_users_private_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_users_private_updates"][]},ValueTypes["voyce_users_private_mutation_response"]],
update_voyce_users_series_junction?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_users_series_junction_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_users_series_junction_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_users_series_junction_bool_exp"]},ValueTypes["voyce_users_series_junction_mutation_response"]],
update_voyce_users_series_junction_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_users_series_junction_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_users_series_junction_set_input"],	pk_columns:ValueTypes["voyce_users_series_junction_pk_columns_input"]},ValueTypes["voyce_users_series_junction"]],
update_voyce_users_series_junction_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_users_series_junction_updates"][]},ValueTypes["voyce_users_series_junction_mutation_response"]],
update_voyce_versions?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_versions_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_versions_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_versions_bool_exp"]},ValueTypes["voyce_versions_mutation_response"]],
update_voyce_versions_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_versions_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_versions_set_input"],	pk_columns:ValueTypes["voyce_versions_pk_columns_input"]},ValueTypes["voyce_versions"]],
update_voyce_versions_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_versions_updates"][]},ValueTypes["voyce_versions_mutation_response"]],
update_voyce_voting_analytics?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_voting_analytics_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_voting_analytics_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_voting_analytics_bool_exp"]},ValueTypes["voyce_voting_analytics_mutation_response"]],
update_voyce_voting_analytics_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_voting_analytics_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_voting_analytics_set_input"],	pk_columns:ValueTypes["voyce_voting_analytics_pk_columns_input"]},ValueTypes["voyce_voting_analytics"]],
update_voyce_voting_analytics_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_voting_analytics_updates"][]},ValueTypes["voyce_voting_analytics_mutation_response"]],
update_voyce_voting_panels?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_voting_panels_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_voting_panels_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_voting_panels_bool_exp"]},ValueTypes["voyce_voting_panels_mutation_response"]],
update_voyce_voting_panels_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_voting_panels_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_voting_panels_set_input"],	pk_columns:ValueTypes["voyce_voting_panels_pk_columns_input"]},ValueTypes["voyce_voting_panels"]],
update_voyce_voting_panels_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_voting_panels_updates"][]},ValueTypes["voyce_voting_panels_mutation_response"]],
update_voyce_voting_series?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_voting_series_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_voting_series_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_voting_series_bool_exp"]},ValueTypes["voyce_voting_series_mutation_response"]],
update_voyce_voting_series_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_voting_series_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_voting_series_set_input"],	pk_columns:ValueTypes["voyce_voting_series_pk_columns_input"]},ValueTypes["voyce_voting_series"]],
update_voyce_voting_series_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_voting_series_updates"][]},ValueTypes["voyce_voting_series_mutation_response"]],
update_voyce_voting_user_votes?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_voting_user_votes_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_voting_user_votes_set_input"],	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_voting_user_votes_bool_exp"]},ValueTypes["voyce_voting_user_votes_mutation_response"]],
update_voyce_voting_user_votes_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_voting_user_votes_inc_input"],	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_voting_user_votes_set_input"],	pk_columns:ValueTypes["voyce_voting_user_votes_pk_columns_input"]},ValueTypes["voyce_voting_user_votes"]],
update_voyce_voting_user_votes_many?: [{	/** updates to execute, in order */
	updates:ValueTypes["voyce_voting_user_votes_updates"][]},ValueTypes["voyce_voting_user_votes_mutation_response"]],
		__typename?: true
}>;
	/** columns and relationships of "mysql.user" */
["mysql_user"]: AliasType<{
	account_locked?:true,
	alter_priv?:true,
	alter_routine_priv?:true,
	authentication_string?:true,
	create_priv?:true,
	create_role_priv?:true,
	create_routine_priv?:true,
	create_tablespace_priv?:true,
	create_tmp_table_priv?:true,
	create_user_priv?:true,
	create_view_priv?:true,
	delete_priv?:true,
	drop_priv?:true,
	drop_role_priv?:true,
	event_priv?:true,
	execute_priv?:true,
	file_priv?:true,
	grant_priv?:true,
	host?:true,
	index_priv?:true,
	insert_priv?:true,
	lock_tables_priv?:true,
	max_connections?:true,
	max_questions?:true,
	max_updates?:true,
	max_user_connections?:true,
	password_expired?:true,
	password_last_changed?:true,
	password_lifetime?:true,
	password_require_current?:true,
	password_reuse_history?:true,
	password_reuse_time?:true,
	plugin?:true,
	process_priv?:true,
	references_priv?:true,
	reload_priv?:true,
	repl_client_priv?:true,
	repl_slave_priv?:true,
	select_priv?:true,
	show_db_priv?:true,
	show_view_priv?:true,
	shutdown_priv?:true,
	ssl_cipher?:true,
	ssl_type?:true,
	super_priv?:true,
	trigger_priv?:true,
	update_priv?:true,
	user?:true,
	user_attributes?:true,
	x509_issuer?:true,
	x509_subject?:true,
		__typename?: true
}>;
	/** aggregated selection of "mysql.user" */
["mysql_user_aggregate"]: AliasType<{
	aggregate?:ValueTypes["mysql_user_aggregate_fields"],
	nodes?:ValueTypes["mysql_user"],
		__typename?: true
}>;
	/** aggregate fields of "mysql.user" */
["mysql_user_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["mysql_user_avg_fields"],
count?: [{	columns?:ValueTypes["mysql_user_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["mysql_user_max_fields"],
	min?:ValueTypes["mysql_user_min_fields"],
	stddev?:ValueTypes["mysql_user_stddev_fields"],
	stddev_pop?:ValueTypes["mysql_user_stddev_pop_fields"],
	stddev_samp?:ValueTypes["mysql_user_stddev_samp_fields"],
	sum?:ValueTypes["mysql_user_sum_fields"],
	var_pop?:ValueTypes["mysql_user_var_pop_fields"],
	var_samp?:ValueTypes["mysql_user_var_samp_fields"],
	variance?:ValueTypes["mysql_user_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["mysql_user_avg_fields"]: AliasType<{
	max_connections?:true,
	max_questions?:true,
	max_updates?:true,
	max_user_connections?:true,
	password_lifetime?:true,
	password_reuse_history?:true,
	password_reuse_time?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "mysql.user". All fields are combined with a logical 'AND'. */
["mysql_user_bool_exp"]: {
	_and?:ValueTypes["mysql_user_bool_exp"][],
	_not?:ValueTypes["mysql_user_bool_exp"],
	_or?:ValueTypes["mysql_user_bool_exp"][],
	account_locked?:ValueTypes["String_comparison_exp"],
	alter_priv?:ValueTypes["String_comparison_exp"],
	alter_routine_priv?:ValueTypes["String_comparison_exp"],
	authentication_string?:ValueTypes["String_comparison_exp"],
	create_priv?:ValueTypes["String_comparison_exp"],
	create_role_priv?:ValueTypes["String_comparison_exp"],
	create_routine_priv?:ValueTypes["String_comparison_exp"],
	create_tablespace_priv?:ValueTypes["String_comparison_exp"],
	create_tmp_table_priv?:ValueTypes["String_comparison_exp"],
	create_user_priv?:ValueTypes["String_comparison_exp"],
	create_view_priv?:ValueTypes["String_comparison_exp"],
	delete_priv?:ValueTypes["String_comparison_exp"],
	drop_priv?:ValueTypes["String_comparison_exp"],
	drop_role_priv?:ValueTypes["String_comparison_exp"],
	event_priv?:ValueTypes["String_comparison_exp"],
	execute_priv?:ValueTypes["String_comparison_exp"],
	file_priv?:ValueTypes["String_comparison_exp"],
	grant_priv?:ValueTypes["String_comparison_exp"],
	host?:ValueTypes["String_comparison_exp"],
	index_priv?:ValueTypes["String_comparison_exp"],
	insert_priv?:ValueTypes["String_comparison_exp"],
	lock_tables_priv?:ValueTypes["String_comparison_exp"],
	max_connections?:ValueTypes["bigint_comparison_exp"],
	max_questions?:ValueTypes["bigint_comparison_exp"],
	max_updates?:ValueTypes["bigint_comparison_exp"],
	max_user_connections?:ValueTypes["bigint_comparison_exp"],
	password_expired?:ValueTypes["String_comparison_exp"],
	password_last_changed?:ValueTypes["timestamp_comparison_exp"],
	password_lifetime?:ValueTypes["Int_comparison_exp"],
	password_require_current?:ValueTypes["String_comparison_exp"],
	password_reuse_history?:ValueTypes["Int_comparison_exp"],
	password_reuse_time?:ValueTypes["Int_comparison_exp"],
	plugin?:ValueTypes["String_comparison_exp"],
	process_priv?:ValueTypes["String_comparison_exp"],
	references_priv?:ValueTypes["String_comparison_exp"],
	reload_priv?:ValueTypes["String_comparison_exp"],
	repl_client_priv?:ValueTypes["String_comparison_exp"],
	repl_slave_priv?:ValueTypes["String_comparison_exp"],
	select_priv?:ValueTypes["String_comparison_exp"],
	show_db_priv?:ValueTypes["String_comparison_exp"],
	show_view_priv?:ValueTypes["String_comparison_exp"],
	shutdown_priv?:ValueTypes["String_comparison_exp"],
	ssl_cipher?:ValueTypes["bytea_comparison_exp"],
	ssl_type?:ValueTypes["String_comparison_exp"],
	super_priv?:ValueTypes["String_comparison_exp"],
	trigger_priv?:ValueTypes["String_comparison_exp"],
	update_priv?:ValueTypes["String_comparison_exp"],
	user?:ValueTypes["String_comparison_exp"],
	user_attributes?:ValueTypes["String_comparison_exp"],
	x509_issuer?:ValueTypes["bytea_comparison_exp"],
	x509_subject?:ValueTypes["bytea_comparison_exp"]
};
	/** unique or primary key constraints on table "mysql.user" */
["mysql_user_constraint"]:mysql_user_constraint;
	/** input type for incrementing numeric columns in table "mysql.user" */
["mysql_user_inc_input"]: {
	max_connections?:ValueTypes["bigint"],
	max_questions?:ValueTypes["bigint"],
	max_updates?:ValueTypes["bigint"],
	max_user_connections?:ValueTypes["bigint"],
	password_lifetime?:number,
	password_reuse_history?:number,
	password_reuse_time?:number
};
	/** input type for inserting data into table "mysql.user" */
["mysql_user_insert_input"]: {
	account_locked?:string,
	alter_priv?:string,
	alter_routine_priv?:string,
	authentication_string?:string,
	create_priv?:string,
	create_role_priv?:string,
	create_routine_priv?:string,
	create_tablespace_priv?:string,
	create_tmp_table_priv?:string,
	create_user_priv?:string,
	create_view_priv?:string,
	delete_priv?:string,
	drop_priv?:string,
	drop_role_priv?:string,
	event_priv?:string,
	execute_priv?:string,
	file_priv?:string,
	grant_priv?:string,
	host?:string,
	index_priv?:string,
	insert_priv?:string,
	lock_tables_priv?:string,
	max_connections?:ValueTypes["bigint"],
	max_questions?:ValueTypes["bigint"],
	max_updates?:ValueTypes["bigint"],
	max_user_connections?:ValueTypes["bigint"],
	password_expired?:string,
	password_last_changed?:ValueTypes["timestamp"],
	password_lifetime?:number,
	password_require_current?:string,
	password_reuse_history?:number,
	password_reuse_time?:number,
	plugin?:string,
	process_priv?:string,
	references_priv?:string,
	reload_priv?:string,
	repl_client_priv?:string,
	repl_slave_priv?:string,
	select_priv?:string,
	show_db_priv?:string,
	show_view_priv?:string,
	shutdown_priv?:string,
	ssl_cipher?:ValueTypes["bytea"],
	ssl_type?:string,
	super_priv?:string,
	trigger_priv?:string,
	update_priv?:string,
	user?:string,
	user_attributes?:string,
	x509_issuer?:ValueTypes["bytea"],
	x509_subject?:ValueTypes["bytea"]
};
	/** aggregate max on columns */
["mysql_user_max_fields"]: AliasType<{
	account_locked?:true,
	alter_priv?:true,
	alter_routine_priv?:true,
	authentication_string?:true,
	create_priv?:true,
	create_role_priv?:true,
	create_routine_priv?:true,
	create_tablespace_priv?:true,
	create_tmp_table_priv?:true,
	create_user_priv?:true,
	create_view_priv?:true,
	delete_priv?:true,
	drop_priv?:true,
	drop_role_priv?:true,
	event_priv?:true,
	execute_priv?:true,
	file_priv?:true,
	grant_priv?:true,
	host?:true,
	index_priv?:true,
	insert_priv?:true,
	lock_tables_priv?:true,
	max_connections?:true,
	max_questions?:true,
	max_updates?:true,
	max_user_connections?:true,
	password_expired?:true,
	password_last_changed?:true,
	password_lifetime?:true,
	password_require_current?:true,
	password_reuse_history?:true,
	password_reuse_time?:true,
	plugin?:true,
	process_priv?:true,
	references_priv?:true,
	reload_priv?:true,
	repl_client_priv?:true,
	repl_slave_priv?:true,
	select_priv?:true,
	show_db_priv?:true,
	show_view_priv?:true,
	shutdown_priv?:true,
	ssl_type?:true,
	super_priv?:true,
	trigger_priv?:true,
	update_priv?:true,
	user?:true,
	user_attributes?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["mysql_user_min_fields"]: AliasType<{
	account_locked?:true,
	alter_priv?:true,
	alter_routine_priv?:true,
	authentication_string?:true,
	create_priv?:true,
	create_role_priv?:true,
	create_routine_priv?:true,
	create_tablespace_priv?:true,
	create_tmp_table_priv?:true,
	create_user_priv?:true,
	create_view_priv?:true,
	delete_priv?:true,
	drop_priv?:true,
	drop_role_priv?:true,
	event_priv?:true,
	execute_priv?:true,
	file_priv?:true,
	grant_priv?:true,
	host?:true,
	index_priv?:true,
	insert_priv?:true,
	lock_tables_priv?:true,
	max_connections?:true,
	max_questions?:true,
	max_updates?:true,
	max_user_connections?:true,
	password_expired?:true,
	password_last_changed?:true,
	password_lifetime?:true,
	password_require_current?:true,
	password_reuse_history?:true,
	password_reuse_time?:true,
	plugin?:true,
	process_priv?:true,
	references_priv?:true,
	reload_priv?:true,
	repl_client_priv?:true,
	repl_slave_priv?:true,
	select_priv?:true,
	show_db_priv?:true,
	show_view_priv?:true,
	shutdown_priv?:true,
	ssl_type?:true,
	super_priv?:true,
	trigger_priv?:true,
	update_priv?:true,
	user?:true,
	user_attributes?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "mysql.user" */
["mysql_user_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["mysql_user"],
		__typename?: true
}>;
	/** on_conflict condition type for table "mysql.user" */
["mysql_user_on_conflict"]: {
	constraint:ValueTypes["mysql_user_constraint"],
	update_columns:ValueTypes["mysql_user_update_column"][],
	where?:ValueTypes["mysql_user_bool_exp"]
};
	/** Ordering options when selecting data from "mysql.user". */
["mysql_user_order_by"]: {
	account_locked?:ValueTypes["order_by"],
	alter_priv?:ValueTypes["order_by"],
	alter_routine_priv?:ValueTypes["order_by"],
	authentication_string?:ValueTypes["order_by"],
	create_priv?:ValueTypes["order_by"],
	create_role_priv?:ValueTypes["order_by"],
	create_routine_priv?:ValueTypes["order_by"],
	create_tablespace_priv?:ValueTypes["order_by"],
	create_tmp_table_priv?:ValueTypes["order_by"],
	create_user_priv?:ValueTypes["order_by"],
	create_view_priv?:ValueTypes["order_by"],
	delete_priv?:ValueTypes["order_by"],
	drop_priv?:ValueTypes["order_by"],
	drop_role_priv?:ValueTypes["order_by"],
	event_priv?:ValueTypes["order_by"],
	execute_priv?:ValueTypes["order_by"],
	file_priv?:ValueTypes["order_by"],
	grant_priv?:ValueTypes["order_by"],
	host?:ValueTypes["order_by"],
	index_priv?:ValueTypes["order_by"],
	insert_priv?:ValueTypes["order_by"],
	lock_tables_priv?:ValueTypes["order_by"],
	max_connections?:ValueTypes["order_by"],
	max_questions?:ValueTypes["order_by"],
	max_updates?:ValueTypes["order_by"],
	max_user_connections?:ValueTypes["order_by"],
	password_expired?:ValueTypes["order_by"],
	password_last_changed?:ValueTypes["order_by"],
	password_lifetime?:ValueTypes["order_by"],
	password_require_current?:ValueTypes["order_by"],
	password_reuse_history?:ValueTypes["order_by"],
	password_reuse_time?:ValueTypes["order_by"],
	plugin?:ValueTypes["order_by"],
	process_priv?:ValueTypes["order_by"],
	references_priv?:ValueTypes["order_by"],
	reload_priv?:ValueTypes["order_by"],
	repl_client_priv?:ValueTypes["order_by"],
	repl_slave_priv?:ValueTypes["order_by"],
	select_priv?:ValueTypes["order_by"],
	show_db_priv?:ValueTypes["order_by"],
	show_view_priv?:ValueTypes["order_by"],
	shutdown_priv?:ValueTypes["order_by"],
	ssl_cipher?:ValueTypes["order_by"],
	ssl_type?:ValueTypes["order_by"],
	super_priv?:ValueTypes["order_by"],
	trigger_priv?:ValueTypes["order_by"],
	update_priv?:ValueTypes["order_by"],
	user?:ValueTypes["order_by"],
	user_attributes?:ValueTypes["order_by"],
	x509_issuer?:ValueTypes["order_by"],
	x509_subject?:ValueTypes["order_by"]
};
	/** primary key columns input for table: mysql.user */
["mysql_user_pk_columns_input"]: {
	host:string,
	user:string
};
	/** select columns of table "mysql.user" */
["mysql_user_select_column"]:mysql_user_select_column;
	/** input type for updating data in table "mysql.user" */
["mysql_user_set_input"]: {
	account_locked?:string,
	alter_priv?:string,
	alter_routine_priv?:string,
	authentication_string?:string,
	create_priv?:string,
	create_role_priv?:string,
	create_routine_priv?:string,
	create_tablespace_priv?:string,
	create_tmp_table_priv?:string,
	create_user_priv?:string,
	create_view_priv?:string,
	delete_priv?:string,
	drop_priv?:string,
	drop_role_priv?:string,
	event_priv?:string,
	execute_priv?:string,
	file_priv?:string,
	grant_priv?:string,
	host?:string,
	index_priv?:string,
	insert_priv?:string,
	lock_tables_priv?:string,
	max_connections?:ValueTypes["bigint"],
	max_questions?:ValueTypes["bigint"],
	max_updates?:ValueTypes["bigint"],
	max_user_connections?:ValueTypes["bigint"],
	password_expired?:string,
	password_last_changed?:ValueTypes["timestamp"],
	password_lifetime?:number,
	password_require_current?:string,
	password_reuse_history?:number,
	password_reuse_time?:number,
	plugin?:string,
	process_priv?:string,
	references_priv?:string,
	reload_priv?:string,
	repl_client_priv?:string,
	repl_slave_priv?:string,
	select_priv?:string,
	show_db_priv?:string,
	show_view_priv?:string,
	shutdown_priv?:string,
	ssl_cipher?:ValueTypes["bytea"],
	ssl_type?:string,
	super_priv?:string,
	trigger_priv?:string,
	update_priv?:string,
	user?:string,
	user_attributes?:string,
	x509_issuer?:ValueTypes["bytea"],
	x509_subject?:ValueTypes["bytea"]
};
	/** aggregate stddev on columns */
["mysql_user_stddev_fields"]: AliasType<{
	max_connections?:true,
	max_questions?:true,
	max_updates?:true,
	max_user_connections?:true,
	password_lifetime?:true,
	password_reuse_history?:true,
	password_reuse_time?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["mysql_user_stddev_pop_fields"]: AliasType<{
	max_connections?:true,
	max_questions?:true,
	max_updates?:true,
	max_user_connections?:true,
	password_lifetime?:true,
	password_reuse_history?:true,
	password_reuse_time?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["mysql_user_stddev_samp_fields"]: AliasType<{
	max_connections?:true,
	max_questions?:true,
	max_updates?:true,
	max_user_connections?:true,
	password_lifetime?:true,
	password_reuse_history?:true,
	password_reuse_time?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "mysql_user" */
["mysql_user_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["mysql_user_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["mysql_user_stream_cursor_value_input"]: {
	account_locked?:string,
	alter_priv?:string,
	alter_routine_priv?:string,
	authentication_string?:string,
	create_priv?:string,
	create_role_priv?:string,
	create_routine_priv?:string,
	create_tablespace_priv?:string,
	create_tmp_table_priv?:string,
	create_user_priv?:string,
	create_view_priv?:string,
	delete_priv?:string,
	drop_priv?:string,
	drop_role_priv?:string,
	event_priv?:string,
	execute_priv?:string,
	file_priv?:string,
	grant_priv?:string,
	host?:string,
	index_priv?:string,
	insert_priv?:string,
	lock_tables_priv?:string,
	max_connections?:ValueTypes["bigint"],
	max_questions?:ValueTypes["bigint"],
	max_updates?:ValueTypes["bigint"],
	max_user_connections?:ValueTypes["bigint"],
	password_expired?:string,
	password_last_changed?:ValueTypes["timestamp"],
	password_lifetime?:number,
	password_require_current?:string,
	password_reuse_history?:number,
	password_reuse_time?:number,
	plugin?:string,
	process_priv?:string,
	references_priv?:string,
	reload_priv?:string,
	repl_client_priv?:string,
	repl_slave_priv?:string,
	select_priv?:string,
	show_db_priv?:string,
	show_view_priv?:string,
	shutdown_priv?:string,
	ssl_cipher?:ValueTypes["bytea"],
	ssl_type?:string,
	super_priv?:string,
	trigger_priv?:string,
	update_priv?:string,
	user?:string,
	user_attributes?:string,
	x509_issuer?:ValueTypes["bytea"],
	x509_subject?:ValueTypes["bytea"]
};
	/** aggregate sum on columns */
["mysql_user_sum_fields"]: AliasType<{
	max_connections?:true,
	max_questions?:true,
	max_updates?:true,
	max_user_connections?:true,
	password_lifetime?:true,
	password_reuse_history?:true,
	password_reuse_time?:true,
		__typename?: true
}>;
	/** update columns of table "mysql.user" */
["mysql_user_update_column"]:mysql_user_update_column;
	["mysql_user_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["mysql_user_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["mysql_user_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["mysql_user_bool_exp"]
};
	/** aggregate var_pop on columns */
["mysql_user_var_pop_fields"]: AliasType<{
	max_connections?:true,
	max_questions?:true,
	max_updates?:true,
	max_user_connections?:true,
	password_lifetime?:true,
	password_reuse_history?:true,
	password_reuse_time?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["mysql_user_var_samp_fields"]: AliasType<{
	max_connections?:true,
	max_questions?:true,
	max_updates?:true,
	max_user_connections?:true,
	password_lifetime?:true,
	password_reuse_history?:true,
	password_reuse_time?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["mysql_user_variance_fields"]: AliasType<{
	max_connections?:true,
	max_questions?:true,
	max_updates?:true,
	max_user_connections?:true,
	password_lifetime?:true,
	password_reuse_history?:true,
	password_reuse_time?:true,
		__typename?: true
}>;
	["numeric"]:unknown;
	/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
["numeric_comparison_exp"]: {
	_eq?:ValueTypes["numeric"],
	_gt?:ValueTypes["numeric"],
	_gte?:ValueTypes["numeric"],
	_in?:ValueTypes["numeric"][],
	_is_null?:boolean,
	_lt?:ValueTypes["numeric"],
	_lte?:ValueTypes["numeric"],
	_neq?:ValueTypes["numeric"],
	_nin?:ValueTypes["numeric"][]
};
	/** column ordering options */
["order_by"]:order_by;
	["query_root"]: AliasType<{
awsdms_validation_failures_v1?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["awsdms_validation_failures_v1_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["awsdms_validation_failures_v1_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["awsdms_validation_failures_v1_bool_exp"]},ValueTypes["awsdms_validation_failures_v1"]],
awsdms_validation_failures_v1_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["awsdms_validation_failures_v1_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["awsdms_validation_failures_v1_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["awsdms_validation_failures_v1_bool_exp"]},ValueTypes["awsdms_validation_failures_v1_aggregate"]],
coinsystem_balances?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_balances_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_balances_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_balances_bool_exp"]},ValueTypes["coinsystem_balances"]],
coinsystem_balances_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_balances_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_balances_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_balances_bool_exp"]},ValueTypes["coinsystem_balances_aggregate"]],
coinsystem_balances_by_pk?: [{	id:number},ValueTypes["coinsystem_balances"]],
coinsystem_bonus_coins?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_bonus_coins_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_bonus_coins_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_bonus_coins_bool_exp"]},ValueTypes["coinsystem_bonus_coins"]],
coinsystem_bonus_coins_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_bonus_coins_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_bonus_coins_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_bonus_coins_bool_exp"]},ValueTypes["coinsystem_bonus_coins_aggregate"]],
coinsystem_bonus_coins_by_pk?: [{	id:number},ValueTypes["coinsystem_bonus_coins"]],
coinsystem_coins?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_coins_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_coins_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_coins_bool_exp"]},ValueTypes["coinsystem_coins"]],
coinsystem_coins_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_coins_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_coins_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_coins_bool_exp"]},ValueTypes["coinsystem_coins_aggregate"]],
coinsystem_coins_by_pk?: [{	id:number},ValueTypes["coinsystem_coins"]],
coinsystem_exchange_rates?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_exchange_rates_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_exchange_rates_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_exchange_rates_bool_exp"]},ValueTypes["coinsystem_exchange_rates"]],
coinsystem_exchange_rates_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_exchange_rates_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_exchange_rates_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_exchange_rates_bool_exp"]},ValueTypes["coinsystem_exchange_rates_aggregate"]],
coinsystem_exchange_rates_by_pk?: [{	id:number},ValueTypes["coinsystem_exchange_rates"]],
coinsystem_logs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_logs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_logs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_logs_bool_exp"]},ValueTypes["coinsystem_logs"]],
coinsystem_logs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_logs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_logs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_logs_bool_exp"]},ValueTypes["coinsystem_logs_aggregate"]],
coinsystem_logs_by_pk?: [{	id:number},ValueTypes["coinsystem_logs"]],
coinsystem_transactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_transactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_transactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_transactions_bool_exp"]},ValueTypes["coinsystem_transactions"]],
coinsystem_transactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_transactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_transactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_transactions_bool_exp"]},ValueTypes["coinsystem_transactions_aggregate"]],
coinsystem_transactions_by_pk?: [{	id:number},ValueTypes["coinsystem_transactions"]],
coinsystem_wallets?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_wallets_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_wallets_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_wallets_bool_exp"]},ValueTypes["coinsystem_wallets"]],
coinsystem_wallets_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_wallets_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_wallets_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_wallets_bool_exp"]},ValueTypes["coinsystem_wallets_aggregate"]],
coinsystem_wallets_by_pk?: [{	id:number},ValueTypes["coinsystem_wallets"]],
mysql_user?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["mysql_user_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["mysql_user_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["mysql_user_bool_exp"]},ValueTypes["mysql_user"]],
mysql_user_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["mysql_user_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["mysql_user_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["mysql_user_bool_exp"]},ValueTypes["mysql_user_aggregate"]],
mysql_user_by_pk?: [{	host:string,	user:string},ValueTypes["mysql_user"]],
store_payments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["store_payments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["store_payments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["store_payments_bool_exp"]},ValueTypes["store_payments"]],
store_payments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["store_payments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["store_payments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["store_payments_bool_exp"]},ValueTypes["store_payments_aggregate"]],
store_payments_by_pk?: [{	id:ValueTypes["uuid"],	purchase_id:string},ValueTypes["store_payments"]],
store_products?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["store_products_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["store_products_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["store_products_bool_exp"]},ValueTypes["store_products"]],
store_products_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["store_products_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["store_products_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["store_products_bool_exp"]},ValueTypes["store_products_aggregate"]],
store_products_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["store_products"]],
store_purchases?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["store_purchases_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["store_purchases_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["store_purchases_bool_exp"]},ValueTypes["store_purchases"]],
store_purchases_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["store_purchases_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["store_purchases_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["store_purchases_bool_exp"]},ValueTypes["store_purchases_aggregate"]],
store_purchases_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["store_purchases"]],
store_users?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["store_users_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["store_users_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["store_users_bool_exp"]},ValueTypes["store_users"]],
store_users_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["store_users_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["store_users_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["store_users_bool_exp"]},ValueTypes["store_users_aggregate"]],
test?: [{	numbers?:(number | undefined)[]},ValueTypes["AddResult"]],
voyce_admin_roles?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_admin_roles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_admin_roles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_admin_roles_bool_exp"]},ValueTypes["voyce_admin_roles"]],
voyce_admin_roles_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_admin_roles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_admin_roles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_admin_roles_bool_exp"]},ValueTypes["voyce_admin_roles_aggregate"]],
voyce_admin_roles_by_pk?: [{	id:number},ValueTypes["voyce_admin_roles"]],
voyce_app_banners?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_app_banners_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_app_banners_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_app_banners_bool_exp"]},ValueTypes["voyce_app_banners"]],
voyce_app_banners_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_app_banners_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_app_banners_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_app_banners_bool_exp"]},ValueTypes["voyce_app_banners_aggregate"]],
voyce_app_banners_by_pk?: [{	id:number},ValueTypes["voyce_app_banners"]],
voyce_app_components?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_app_components_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_app_components_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_app_components_bool_exp"]},ValueTypes["voyce_app_components"]],
voyce_app_components_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_app_components_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_app_components_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_app_components_bool_exp"]},ValueTypes["voyce_app_components_aggregate"]],
voyce_app_components_by_pk?: [{	id:number},ValueTypes["voyce_app_components"]],
voyce_audit_trail?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_audit_trail_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_audit_trail_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_audit_trail_bool_exp"]},ValueTypes["voyce_audit_trail"]],
voyce_audit_trail_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_audit_trail_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_audit_trail_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_audit_trail_bool_exp"]},ValueTypes["voyce_audit_trail_aggregate"]],
voyce_audit_trail_by_pk?: [{	id:number},ValueTypes["voyce_audit_trail"]],
voyce_author_profiles?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_author_profiles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_author_profiles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_author_profiles_bool_exp"]},ValueTypes["voyce_author_profiles"]],
voyce_author_profiles_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_author_profiles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_author_profiles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_author_profiles_bool_exp"]},ValueTypes["voyce_author_profiles_aggregate"]],
voyce_author_profiles_by_pk?: [{	id:number},ValueTypes["voyce_author_profiles"]],
voyce_balances?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_balances_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_balances_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_balances_bool_exp"]},ValueTypes["voyce_balances"]],
voyce_balances_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_balances_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_balances_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_balances_bool_exp"]},ValueTypes["voyce_balances_aggregate"]],
voyce_balances_by_pk?: [{	id:number},ValueTypes["voyce_balances"]],
voyce_banners?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_banners_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_banners_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_banners_bool_exp"]},ValueTypes["voyce_banners"]],
voyce_banners_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_banners_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_banners_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_banners_bool_exp"]},ValueTypes["voyce_banners_aggregate"]],
voyce_banners_by_pk?: [{	id:number},ValueTypes["voyce_banners"]],
voyce_chapter_assets?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_assets_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_assets_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_assets_bool_exp"]},ValueTypes["voyce_chapter_assets"]],
voyce_chapter_assets_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_assets_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_assets_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_assets_bool_exp"]},ValueTypes["voyce_chapter_assets_aggregate"]],
voyce_chapter_assets_by_pk?: [{	id:number},ValueTypes["voyce_chapter_assets"]],
voyce_chapter_bookmarks?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_bookmarks_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_bookmarks_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_bookmarks_bool_exp"]},ValueTypes["voyce_chapter_bookmarks"]],
voyce_chapter_bookmarks_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_bookmarks_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_bookmarks_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_bookmarks_bool_exp"]},ValueTypes["voyce_chapter_bookmarks_aggregate"]],
voyce_chapter_bookmarks_by_pk?: [{	chapter_id:number,	user_id:number},ValueTypes["voyce_chapter_bookmarks"]],
voyce_chapter_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_bool_exp"]},ValueTypes["voyce_chapter_comments"]],
voyce_chapter_comments_07252024?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_07252024_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_07252024_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_07252024_bool_exp"]},ValueTypes["voyce_chapter_comments_07252024"]],
voyce_chapter_comments_07252024_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_07252024_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_07252024_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_07252024_bool_exp"]},ValueTypes["voyce_chapter_comments_07252024_aggregate"]],
voyce_chapter_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_bool_exp"]},ValueTypes["voyce_chapter_comments_aggregate"]],
voyce_chapter_comments_by_pk?: [{	id:number},ValueTypes["voyce_chapter_comments"]],
voyce_chapter_comments_reaction?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reaction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reaction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reaction_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction"]],
voyce_chapter_comments_reaction_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reaction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reaction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reaction_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction_aggregate"]],
voyce_chapter_comments_reaction_by_pk?: [{	id:number},ValueTypes["voyce_chapter_comments_reaction"]],
voyce_chapter_comments_reaction_count?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reaction_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reaction_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reaction_count_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction_count"]],
voyce_chapter_comments_reaction_count_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reaction_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reaction_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reaction_count_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction_count_aggregate"]],
voyce_chapter_comments_reaction_count_by_pk?: [{	id:number},ValueTypes["voyce_chapter_comments_reaction_count"]],
voyce_chapter_comments_reports?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reports_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reports_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reports_bool_exp"]},ValueTypes["voyce_chapter_comments_reports"]],
voyce_chapter_comments_reports_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reports_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reports_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reports_bool_exp"]},ValueTypes["voyce_chapter_comments_reports_aggregate"]],
voyce_chapter_comments_reports_by_pk?: [{	id:number},ValueTypes["voyce_chapter_comments_reports"]],
voyce_chapter_contents?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_contents_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_contents_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_contents_bool_exp"]},ValueTypes["voyce_chapter_contents"]],
voyce_chapter_contents_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_contents_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_contents_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_contents_bool_exp"]},ValueTypes["voyce_chapter_contents_aggregate"]],
voyce_chapter_contents_by_pk?: [{	id:number},ValueTypes["voyce_chapter_contents"]],
voyce_chapter_contents_old?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_contents_old_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_contents_old_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_contents_old_bool_exp"]},ValueTypes["voyce_chapter_contents_old"]],
voyce_chapter_contents_old_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_contents_old_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_contents_old_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_contents_old_bool_exp"]},ValueTypes["voyce_chapter_contents_old_aggregate"]],
voyce_chapter_contents_old_by_pk?: [{	id:number},ValueTypes["voyce_chapter_contents_old"]],
voyce_chapter_effects?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_effects_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_effects_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_effects_bool_exp"]},ValueTypes["voyce_chapter_effects"]],
voyce_chapter_effects_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_effects_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_effects_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_effects_bool_exp"]},ValueTypes["voyce_chapter_effects_aggregate"]],
voyce_chapter_effects_by_pk?: [{	id:number},ValueTypes["voyce_chapter_effects"]],
voyce_chapter_images?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_images_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_images_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_images_bool_exp"]},ValueTypes["voyce_chapter_images"]],
voyce_chapter_images_07252024?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_images_07252024_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_images_07252024_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_images_07252024_bool_exp"]},ValueTypes["voyce_chapter_images_07252024"]],
voyce_chapter_images_07252024_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_images_07252024_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_images_07252024_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_images_07252024_bool_exp"]},ValueTypes["voyce_chapter_images_07252024_aggregate"]],
voyce_chapter_images_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_images_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_images_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_images_bool_exp"]},ValueTypes["voyce_chapter_images_aggregate"]],
voyce_chapter_images_by_pk?: [{	id:number},ValueTypes["voyce_chapter_images"]],
voyce_chapter_likes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_likes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_likes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_likes_bool_exp"]},ValueTypes["voyce_chapter_likes"]],
voyce_chapter_likes_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_likes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_likes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_likes_bool_exp"]},ValueTypes["voyce_chapter_likes_aggregate"]],
voyce_chapter_likes_by_pk?: [{	chapter_id:number,	created_at:ValueTypes["timestamptz"]},ValueTypes["voyce_chapter_likes"]],
voyce_chapter_locks?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_locks_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_locks_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_locks_bool_exp"]},ValueTypes["voyce_chapter_locks"]],
voyce_chapter_locks_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_locks_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_locks_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_locks_bool_exp"]},ValueTypes["voyce_chapter_locks_aggregate"]],
voyce_chapter_locks_by_pk?: [{	id:number},ValueTypes["voyce_chapter_locks"]],
voyce_chapter_panel_views?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_panel_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_panel_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_panel_views_bool_exp"]},ValueTypes["voyce_chapter_panel_views"]],
voyce_chapter_panel_views_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_panel_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_panel_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_panel_views_bool_exp"]},ValueTypes["voyce_chapter_panel_views_aggregate"]],
voyce_chapter_panel_views_by_pk?: [{	id:number},ValueTypes["voyce_chapter_panel_views"]],
voyce_chapter_panels?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_panels_bool_exp"]},ValueTypes["voyce_chapter_panels"]],
voyce_chapter_panels_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_panels_bool_exp"]},ValueTypes["voyce_chapter_panels_aggregate"]],
voyce_chapter_panels_by_pk?: [{	id:number},ValueTypes["voyce_chapter_panels"]],
voyce_chapter_publish_schedules?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_publish_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_publish_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_publish_schedules_bool_exp"]},ValueTypes["voyce_chapter_publish_schedules"]],
voyce_chapter_publish_schedules_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_publish_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_publish_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_publish_schedules_bool_exp"]},ValueTypes["voyce_chapter_publish_schedules_aggregate"]],
voyce_chapter_publish_schedules_by_pk?: [{	id:number},ValueTypes["voyce_chapter_publish_schedules"]],
voyce_chapter_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_reactions_bool_exp"]},ValueTypes["voyce_chapter_reactions"]],
voyce_chapter_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_reactions_bool_exp"]},ValueTypes["voyce_chapter_reactions_aggregate"]],
voyce_chapter_reactions_by_pk?: [{	id:number},ValueTypes["voyce_chapter_reactions"]],
voyce_chapter_reactions_count?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_reactions_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_reactions_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_reactions_count_bool_exp"]},ValueTypes["voyce_chapter_reactions_count"]],
voyce_chapter_reactions_count_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_reactions_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_reactions_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_reactions_count_bool_exp"]},ValueTypes["voyce_chapter_reactions_count_aggregate"]],
voyce_chapter_reactions_count_by_pk?: [{	id:number},ValueTypes["voyce_chapter_reactions_count"]],
voyce_chapter_view_logs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_view_logs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_view_logs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_view_logs_bool_exp"]},ValueTypes["voyce_chapter_view_logs"]],
voyce_chapter_view_logs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_view_logs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_view_logs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_view_logs_bool_exp"]},ValueTypes["voyce_chapter_view_logs_aggregate"]],
voyce_chapter_view_logs_by_pk?: [{	id:number},ValueTypes["voyce_chapter_view_logs"]],
voyce_chapter_views?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_views_bool_exp"]},ValueTypes["voyce_chapter_views"]],
voyce_chapter_views_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_views_bool_exp"]},ValueTypes["voyce_chapter_views_aggregate"]],
voyce_chapter_views_by_pk?: [{	id:number},ValueTypes["voyce_chapter_views"]],
voyce_chapters?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapters_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapters_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapters_bool_exp"]},ValueTypes["voyce_chapters"]],
voyce_chapters_07252024?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapters_07252024_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapters_07252024_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapters_07252024_bool_exp"]},ValueTypes["voyce_chapters_07252024"]],
voyce_chapters_07252024_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapters_07252024_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapters_07252024_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapters_07252024_bool_exp"]},ValueTypes["voyce_chapters_07252024_aggregate"]],
voyce_chapters_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapters_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapters_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapters_bool_exp"]},ValueTypes["voyce_chapters_aggregate"]],
voyce_chapters_by_pk?: [{	id:number},ValueTypes["voyce_chapters"]],
voyce_collaborator_roles?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_collaborator_roles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_collaborator_roles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_collaborator_roles_bool_exp"]},ValueTypes["voyce_collaborator_roles"]],
voyce_collaborator_roles_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_collaborator_roles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_collaborator_roles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_collaborator_roles_bool_exp"]},ValueTypes["voyce_collaborator_roles_aggregate"]],
voyce_collaborator_roles_by_pk?: [{	id:number},ValueTypes["voyce_collaborator_roles"]],
voyce_comic_types?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_comic_types_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_comic_types_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_comic_types_bool_exp"]},ValueTypes["voyce_comic_types"]],
voyce_comic_types_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_comic_types_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_comic_types_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_comic_types_bool_exp"]},ValueTypes["voyce_comic_types_aggregate"]],
voyce_comic_types_by_pk?: [{	id:number},ValueTypes["voyce_comic_types"]],
voyce_comments_top_mat?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_comments_top_mat_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_comments_top_mat_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_comments_top_mat_bool_exp"]},ValueTypes["voyce_comments_top_mat"]],
voyce_comments_top_mat_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_comments_top_mat_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_comments_top_mat_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_comments_top_mat_bool_exp"]},ValueTypes["voyce_comments_top_mat_aggregate"]],
voyce_community_blurbs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_community_blurbs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_community_blurbs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_community_blurbs_bool_exp"]},ValueTypes["voyce_community_blurbs"]],
voyce_community_blurbs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_community_blurbs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_community_blurbs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_community_blurbs_bool_exp"]},ValueTypes["voyce_community_blurbs_aggregate"]],
voyce_community_blurbs_by_pk?: [{	id:number},ValueTypes["voyce_community_blurbs"]],
voyce_contact_us?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_contact_us_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_contact_us_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_contact_us_bool_exp"]},ValueTypes["voyce_contact_us"]],
voyce_contact_us_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_contact_us_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_contact_us_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_contact_us_bool_exp"]},ValueTypes["voyce_contact_us_aggregate"]],
voyce_contact_us_by_pk?: [{	id:number},ValueTypes["voyce_contact_us"]],
voyce_content_report?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_content_report_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_content_report_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_content_report_bool_exp"]},ValueTypes["voyce_content_report"]],
voyce_content_report_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_content_report_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_content_report_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_content_report_bool_exp"]},ValueTypes["voyce_content_report_aggregate"]],
voyce_content_report_by_pk?: [{	id:number},ValueTypes["voyce_content_report"]],
voyce_contest_data?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_contest_data_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_contest_data_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_contest_data_bool_exp"]},ValueTypes["voyce_contest_data"]],
voyce_contest_data_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_contest_data_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_contest_data_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_contest_data_bool_exp"]},ValueTypes["voyce_contest_data_aggregate"]],
voyce_contest_data_by_pk?: [{	id:number},ValueTypes["voyce_contest_data"]],
voyce_countries?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_countries_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_countries_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_countries_bool_exp"]},ValueTypes["voyce_countries"]],
voyce_countries_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_countries_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_countries_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_countries_bool_exp"]},ValueTypes["voyce_countries_aggregate"]],
voyce_countries_by_pk?: [{	id:number},ValueTypes["voyce_countries"]],
voyce_csat_ratings?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_csat_ratings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_csat_ratings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_csat_ratings_bool_exp"]},ValueTypes["voyce_csat_ratings"]],
voyce_csat_ratings_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_csat_ratings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_csat_ratings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_csat_ratings_bool_exp"]},ValueTypes["voyce_csat_ratings_aggregate"]],
voyce_csat_ratings_by_pk?: [{	id:number},ValueTypes["voyce_csat_ratings"]],
voyce_csat_ratings_data?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_csat_ratings_data_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_csat_ratings_data_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_csat_ratings_data_bool_exp"]},ValueTypes["voyce_csat_ratings_data"]],
voyce_csat_ratings_data_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_csat_ratings_data_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_csat_ratings_data_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_csat_ratings_data_bool_exp"]},ValueTypes["voyce_csat_ratings_data_aggregate"]],
voyce_csat_ratings_data_by_pk?: [{	id:number},ValueTypes["voyce_csat_ratings_data"]],
voyce_education_types?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_education_types_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_education_types_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_education_types_bool_exp"]},ValueTypes["voyce_education_types"]],
voyce_education_types_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_education_types_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_education_types_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_education_types_bool_exp"]},ValueTypes["voyce_education_types_aggregate"]],
voyce_education_types_by_pk?: [{	id:number},ValueTypes["voyce_education_types"]],
voyce_erp_login_roles?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_erp_login_roles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_erp_login_roles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_erp_login_roles_bool_exp"]},ValueTypes["voyce_erp_login_roles"]],
voyce_erp_login_roles_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_erp_login_roles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_erp_login_roles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_erp_login_roles_bool_exp"]},ValueTypes["voyce_erp_login_roles_aggregate"]],
voyce_erp_login_roles_by_pk?: [{	id:number},ValueTypes["voyce_erp_login_roles"]],
voyce_etl_series_views_logs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_etl_series_views_logs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_etl_series_views_logs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_etl_series_views_logs_bool_exp"]},ValueTypes["voyce_etl_series_views_logs"]],
voyce_etl_series_views_logs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_etl_series_views_logs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_etl_series_views_logs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_etl_series_views_logs_bool_exp"]},ValueTypes["voyce_etl_series_views_logs_aggregate"]],
voyce_etl_series_views_logs_by_pk?: [{	id:number},ValueTypes["voyce_etl_series_views_logs"]],
voyce_event_schedules?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_event_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_event_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_event_schedules_bool_exp"]},ValueTypes["voyce_event_schedules"]],
voyce_event_schedules_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_event_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_event_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_event_schedules_bool_exp"]},ValueTypes["voyce_event_schedules_aggregate"]],
voyce_event_schedules_by_pk?: [{	id:number},ValueTypes["voyce_event_schedules"]],
voyce_genres?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_genres_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_genres_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_genres_bool_exp"]},ValueTypes["voyce_genres"]],
voyce_genres_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_genres_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_genres_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_genres_bool_exp"]},ValueTypes["voyce_genres_aggregate"]],
voyce_genres_by_pk?: [{	id:number},ValueTypes["voyce_genres"]],
voyce_guest_gtags?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_guest_gtags_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_guest_gtags_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_guest_gtags_bool_exp"]},ValueTypes["voyce_guest_gtags"]],
voyce_guest_gtags_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_guest_gtags_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_guest_gtags_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_guest_gtags_bool_exp"]},ValueTypes["voyce_guest_gtags_aggregate"]],
voyce_guest_gtags_by_pk?: [{	id:number},ValueTypes["voyce_guest_gtags"]],
voyce_metabases?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_metabases_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_metabases_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_metabases_bool_exp"]},ValueTypes["voyce_metabases"]],
voyce_metabases_access?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_metabases_access_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_metabases_access_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_metabases_access_bool_exp"]},ValueTypes["voyce_metabases_access"]],
voyce_metabases_access_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_metabases_access_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_metabases_access_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_metabases_access_bool_exp"]},ValueTypes["voyce_metabases_access_aggregate"]],
voyce_metabases_access_by_pk?: [{	id:number},ValueTypes["voyce_metabases_access"]],
voyce_metabases_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_metabases_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_metabases_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_metabases_bool_exp"]},ValueTypes["voyce_metabases_aggregate"]],
voyce_metabases_by_pk?: [{	id:number},ValueTypes["voyce_metabases"]],
voyce_newsletter?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_newsletter_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_newsletter_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_newsletter_bool_exp"]},ValueTypes["voyce_newsletter"]],
voyce_newsletter_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_newsletter_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_newsletter_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_newsletter_bool_exp"]},ValueTypes["voyce_newsletter_aggregate"]],
voyce_newsletter_by_pk?: [{	email:string},ValueTypes["voyce_newsletter"]],
voyce_notification_series_settings?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_notification_series_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_notification_series_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notification_series_settings_bool_exp"]},ValueTypes["voyce_notification_series_settings"]],
voyce_notification_series_settings_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_notification_series_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_notification_series_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notification_series_settings_bool_exp"]},ValueTypes["voyce_notification_series_settings_aggregate"]],
voyce_notification_series_settings_by_pk?: [{	id:number},ValueTypes["voyce_notification_series_settings"]],
voyce_notification_settings?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_notification_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_notification_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notification_settings_bool_exp"]},ValueTypes["voyce_notification_settings"]],
voyce_notification_settings_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_notification_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_notification_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notification_settings_bool_exp"]},ValueTypes["voyce_notification_settings_aggregate"]],
voyce_notification_settings_by_pk?: [{	id:number},ValueTypes["voyce_notification_settings"]],
voyce_notifications?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_notifications_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_notifications_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notifications_bool_exp"]},ValueTypes["voyce_notifications"]],
voyce_notifications_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_notifications_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_notifications_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notifications_bool_exp"]},ValueTypes["voyce_notifications_aggregate"]],
voyce_notifications_by_pk?: [{	id:number},ValueTypes["voyce_notifications"]],
voyce_originals_feed?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_originals_feed_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_originals_feed_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_originals_feed_bool_exp"]},ValueTypes["voyce_originals_feed"]],
voyce_originals_feed_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_originals_feed_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_originals_feed_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_originals_feed_bool_exp"]},ValueTypes["voyce_originals_feed_aggregate"]],
voyce_page_banners?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_page_banners_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_page_banners_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_page_banners_bool_exp"]},ValueTypes["voyce_page_banners"]],
voyce_page_banners_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_page_banners_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_page_banners_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_page_banners_bool_exp"]},ValueTypes["voyce_page_banners_aggregate"]],
voyce_page_banners_by_pk?: [{	id:number},ValueTypes["voyce_page_banners"]],
voyce_page_components?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_page_components_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_page_components_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_page_components_bool_exp"]},ValueTypes["voyce_page_components"]],
voyce_page_components_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_page_components_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_page_components_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_page_components_bool_exp"]},ValueTypes["voyce_page_components_aggregate"]],
voyce_page_components_by_pk?: [{	id:number},ValueTypes["voyce_page_components"]],
voyce_pages?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_pages_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_pages_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_pages_bool_exp"]},ValueTypes["voyce_pages"]],
voyce_pages_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_pages_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_pages_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_pages_bool_exp"]},ValueTypes["voyce_pages_aggregate"]],
voyce_pages_by_pk?: [{	id:number},ValueTypes["voyce_pages"]],
voyce_panels_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_comments_bool_exp"]},ValueTypes["voyce_panels_comments"]],
voyce_panels_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_comments_bool_exp"]},ValueTypes["voyce_panels_comments_aggregate"]],
voyce_panels_comments_by_pk?: [{	id:number},ValueTypes["voyce_panels_comments"]],
voyce_panels_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_bool_exp"]},ValueTypes["voyce_panels_reactions"]],
voyce_panels_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_bool_exp"]},ValueTypes["voyce_panels_reactions_aggregate"]],
voyce_panels_reactions_by_pk?: [{	id:number},ValueTypes["voyce_panels_reactions"]],
voyce_panels_reactions_count?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_reactions_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_reactions_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_count_bool_exp"]},ValueTypes["voyce_panels_reactions_count"]],
voyce_panels_reactions_count_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_reactions_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_reactions_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_count_bool_exp"]},ValueTypes["voyce_panels_reactions_count_aggregate"]],
voyce_panels_reactions_count_by_pk?: [{	id:number},ValueTypes["voyce_panels_reactions_count"]],
voyce_payments_to_creators?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_payments_to_creators_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_payments_to_creators_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_payments_to_creators_bool_exp"]},ValueTypes["voyce_payments_to_creators"]],
voyce_payments_to_creators_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_payments_to_creators_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_payments_to_creators_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_payments_to_creators_bool_exp"]},ValueTypes["voyce_payments_to_creators_aggregate"]],
voyce_payments_to_creators_by_pk?: [{	id:number},ValueTypes["voyce_payments_to_creators"]],
voyce_pg_rs_series_views_count?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_pg_rs_series_views_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_pg_rs_series_views_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_pg_rs_series_views_count_bool_exp"]},ValueTypes["voyce_pg_rs_series_views_count"]],
voyce_pg_rs_series_views_count_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_pg_rs_series_views_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_pg_rs_series_views_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_pg_rs_series_views_count_bool_exp"]},ValueTypes["voyce_pg_rs_series_views_count_aggregate"]],
voyce_pg_rs_series_views_count_by_pk?: [{	series_id:number},ValueTypes["voyce_pg_rs_series_views_count"]],
voyce_polls?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_bool_exp"]},ValueTypes["voyce_polls"]],
voyce_polls_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_bool_exp"]},ValueTypes["voyce_polls_aggregate"]],
voyce_polls_answers?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_answers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_answers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_answers_bool_exp"]},ValueTypes["voyce_polls_answers"]],
voyce_polls_answers_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_answers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_answers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_answers_bool_exp"]},ValueTypes["voyce_polls_answers_aggregate"]],
voyce_polls_answers_by_pk?: [{	id:number},ValueTypes["voyce_polls_answers"]],
voyce_polls_by_pk?: [{	id:number},ValueTypes["voyce_polls"]],
voyce_polls_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_comments_bool_exp"]},ValueTypes["voyce_polls_comments"]],
voyce_polls_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_comments_bool_exp"]},ValueTypes["voyce_polls_comments_aggregate"]],
voyce_polls_comments_by_pk?: [{	id:number},ValueTypes["voyce_polls_comments"]],
voyce_polls_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_reactions_bool_exp"]},ValueTypes["voyce_polls_reactions"]],
voyce_polls_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_reactions_bool_exp"]},ValueTypes["voyce_polls_reactions_aggregate"]],
voyce_polls_reactions_by_pk?: [{	id:number},ValueTypes["voyce_polls_reactions"]],
voyce_polls_votes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_votes_bool_exp"]},ValueTypes["voyce_polls_votes"]],
voyce_polls_votes_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_votes_bool_exp"]},ValueTypes["voyce_polls_votes_aggregate"]],
voyce_polls_votes_by_pk?: [{	id:number},ValueTypes["voyce_polls_votes"]],
voyce_popup_components?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_popup_components_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_popup_components_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_popup_components_bool_exp"]},ValueTypes["voyce_popup_components"]],
voyce_popup_components_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_popup_components_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_popup_components_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_popup_components_bool_exp"]},ValueTypes["voyce_popup_components_aggregate"]],
voyce_popup_components_by_pk?: [{	id:number},ValueTypes["voyce_popup_components"]],
voyce_popup_emails?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_popup_emails_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_popup_emails_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_popup_emails_bool_exp"]},ValueTypes["voyce_popup_emails"]],
voyce_popup_emails_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_popup_emails_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_popup_emails_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_popup_emails_bool_exp"]},ValueTypes["voyce_popup_emails_aggregate"]],
voyce_popup_emails_by_pk?: [{	id:number},ValueTypes["voyce_popup_emails"]],
voyce_posts?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_bool_exp"]},ValueTypes["voyce_posts"]],
voyce_posts_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_bool_exp"]},ValueTypes["voyce_posts_aggregate"]],
voyce_posts_by_pk?: [{	id:number},ValueTypes["voyce_posts"]],
voyce_posts_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_comments_bool_exp"]},ValueTypes["voyce_posts_comments"]],
voyce_posts_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_comments_bool_exp"]},ValueTypes["voyce_posts_comments_aggregate"]],
voyce_posts_comments_by_pk?: [{	id:number},ValueTypes["voyce_posts_comments"]],
voyce_posts_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_reactions_bool_exp"]},ValueTypes["voyce_posts_reactions"]],
voyce_posts_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_reactions_bool_exp"]},ValueTypes["voyce_posts_reactions_aggregate"]],
voyce_posts_reactions_by_pk?: [{	id:number},ValueTypes["voyce_posts_reactions"]],
voyce_quest_difficulties?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_quest_difficulties_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_quest_difficulties_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_quest_difficulties_bool_exp"]},ValueTypes["voyce_quest_difficulties"]],
voyce_quest_difficulties_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_quest_difficulties_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_quest_difficulties_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_quest_difficulties_bool_exp"]},ValueTypes["voyce_quest_difficulties_aggregate"]],
voyce_quest_difficulties_by_pk?: [{	id:number},ValueTypes["voyce_quest_difficulties"]],
voyce_quests?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_quests_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_quests_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_quests_bool_exp"]},ValueTypes["voyce_quests"]],
voyce_quests_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_quests_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_quests_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_quests_bool_exp"]},ValueTypes["voyce_quests_aggregate"]],
voyce_quests_by_pk?: [{	id:number},ValueTypes["voyce_quests"]],
voyce_quests_old?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_quests_old_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_quests_old_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_quests_old_bool_exp"]},ValueTypes["voyce_quests_old"]],
voyce_quests_old_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_quests_old_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_quests_old_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_quests_old_bool_exp"]},ValueTypes["voyce_quests_old_aggregate"]],
voyce_quests_old_by_pk?: [{	id:number},ValueTypes["voyce_quests_old"]],
voyce_recommended_series?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_recommended_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_recommended_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_recommended_series_bool_exp"]},ValueTypes["voyce_recommended_series"]],
voyce_recommended_series_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_recommended_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_recommended_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_recommended_series_bool_exp"]},ValueTypes["voyce_recommended_series_aggregate"]],
voyce_recommended_series_by_pk?: [{	id:number},ValueTypes["voyce_recommended_series"]],
voyce_release_schedules?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_release_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_release_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_release_schedules_bool_exp"]},ValueTypes["voyce_release_schedules"]],
voyce_release_schedules_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_release_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_release_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_release_schedules_bool_exp"]},ValueTypes["voyce_release_schedules_aggregate"]],
voyce_release_schedules_by_pk?: [{	id:number},ValueTypes["voyce_release_schedules"]],
voyce_rewards?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_rewards_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_rewards_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_bool_exp"]},ValueTypes["voyce_rewards"]],
voyce_rewards_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_rewards_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_rewards_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_bool_exp"]},ValueTypes["voyce_rewards_aggregate"]],
voyce_rewards_by_pk?: [{	id:number},ValueTypes["voyce_rewards"]],
voyce_rewards_leaderboard?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_rewards_leaderboard_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_rewards_leaderboard_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_leaderboard_bool_exp"]},ValueTypes["voyce_rewards_leaderboard"]],
voyce_rewards_leaderboard_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_rewards_leaderboard_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_rewards_leaderboard_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_leaderboard_bool_exp"]},ValueTypes["voyce_rewards_leaderboard_aggregate"]],
voyce_rewards_leaderboard_by_pk?: [{	id:number},ValueTypes["voyce_rewards_leaderboard"]],
voyce_rewards_quest?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_rewards_quest_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_rewards_quest_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_quest_bool_exp"]},ValueTypes["voyce_rewards_quest"]],
voyce_rewards_quest_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_rewards_quest_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_rewards_quest_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_quest_bool_exp"]},ValueTypes["voyce_rewards_quest_aggregate"]],
voyce_rewards_quest_by_pk?: [{	id:number},ValueTypes["voyce_rewards_quest"]],
voyce_sections?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_sections_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_sections_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_sections_bool_exp"]},ValueTypes["voyce_sections"]],
voyce_sections_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_sections_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_sections_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_sections_bool_exp"]},ValueTypes["voyce_sections_aggregate"]],
voyce_sections_by_pk?: [{	id:number},ValueTypes["voyce_sections"]],
voyce_series?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_bool_exp"]},ValueTypes["voyce_series"]],
voyce_series_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_bool_exp"]},ValueTypes["voyce_series_aggregate"]],
voyce_series_by_pk?: [{	id:number},ValueTypes["voyce_series"]],
voyce_series_collaborators?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_collaborators_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_collaborators_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_collaborators_bool_exp"]},ValueTypes["voyce_series_collaborators"]],
voyce_series_collaborators_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_collaborators_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_collaborators_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_collaborators_bool_exp"]},ValueTypes["voyce_series_collaborators_aggregate"]],
voyce_series_collaborators_by_pk?: [{	id:number},ValueTypes["voyce_series_collaborators"]],
voyce_series_donations?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_donations_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_donations_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_donations_bool_exp"]},ValueTypes["voyce_series_donations"]],
voyce_series_donations_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_donations_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_donations_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_donations_bool_exp"]},ValueTypes["voyce_series_donations_aggregate"]],
voyce_series_donations_by_pk?: [{	id:number},ValueTypes["voyce_series_donations"]],
voyce_series_favorites?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_favorites_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_favorites_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_favorites_bool_exp"]},ValueTypes["voyce_series_favorites"]],
voyce_series_favorites_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_favorites_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_favorites_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_favorites_bool_exp"]},ValueTypes["voyce_series_favorites_aggregate"]],
voyce_series_favorites_by_pk?: [{	id:number,	series_id:number},ValueTypes["voyce_series_favorites"]],
voyce_series_featured_panels?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_featured_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_featured_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_featured_panels_bool_exp"]},ValueTypes["voyce_series_featured_panels"]],
voyce_series_featured_panels_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_featured_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_featured_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_featured_panels_bool_exp"]},ValueTypes["voyce_series_featured_panels_aggregate"]],
voyce_series_featured_panels_by_pk?: [{	id:number},ValueTypes["voyce_series_featured_panels"]],
voyce_series_genre?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_genre_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_genre_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_genre_bool_exp"]},ValueTypes["voyce_series_genre"]],
voyce_series_genre_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_genre_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_genre_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_genre_bool_exp"]},ValueTypes["voyce_series_genre_aggregate"]],
voyce_series_genre_by_pk?: [{	id:number},ValueTypes["voyce_series_genre"]],
voyce_series_giveaway_participants?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_giveaway_participants_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_giveaway_participants_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_giveaway_participants_bool_exp"]},ValueTypes["voyce_series_giveaway_participants"]],
voyce_series_giveaway_participants_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_giveaway_participants_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_giveaway_participants_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_giveaway_participants_bool_exp"]},ValueTypes["voyce_series_giveaway_participants_aggregate"]],
voyce_series_giveaway_participants_by_pk?: [{	id:number},ValueTypes["voyce_series_giveaway_participants"]],
voyce_series_hot_panels?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_hot_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_hot_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_hot_panels_bool_exp"]},ValueTypes["voyce_series_hot_panels"]],
voyce_series_hot_panels_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_hot_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_hot_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_hot_panels_bool_exp"]},ValueTypes["voyce_series_hot_panels_aggregate"]],
voyce_series_hot_panels_by_pk?: [{	id:number},ValueTypes["voyce_series_hot_panels"]],
voyce_series_layouts?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_layouts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_layouts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_layouts_bool_exp"]},ValueTypes["voyce_series_layouts"]],
voyce_series_layouts_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_layouts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_layouts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_layouts_bool_exp"]},ValueTypes["voyce_series_layouts_aggregate"]],
voyce_series_layouts_by_pk?: [{	id:number},ValueTypes["voyce_series_layouts"]],
voyce_series_leaderboard?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_leaderboard_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_leaderboard_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_leaderboard_bool_exp"]},ValueTypes["voyce_series_leaderboard"]],
voyce_series_leaderboard_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_leaderboard_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_leaderboard_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_leaderboard_bool_exp"]},ValueTypes["voyce_series_leaderboard_aggregate"]],
voyce_series_leaderboard_by_pk?: [{	id:number},ValueTypes["voyce_series_leaderboard"]],
voyce_series_likes_count?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_likes_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_likes_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_likes_count_bool_exp"]},ValueTypes["voyce_series_likes_count"]],
voyce_series_likes_count_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_likes_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_likes_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_likes_count_bool_exp"]},ValueTypes["voyce_series_likes_count_aggregate"]],
voyce_series_likes_count_mat?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_likes_count_mat_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_likes_count_mat_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_likes_count_mat_bool_exp"]},ValueTypes["voyce_series_likes_count_mat"]],
voyce_series_likes_count_mat_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_likes_count_mat_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_likes_count_mat_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_likes_count_mat_bool_exp"]},ValueTypes["voyce_series_likes_count_mat_aggregate"]],
voyce_series_lock_config?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_lock_config_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_lock_config_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_lock_config_bool_exp"]},ValueTypes["voyce_series_lock_config"]],
voyce_series_lock_config_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_lock_config_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_lock_config_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_lock_config_bool_exp"]},ValueTypes["voyce_series_lock_config_aggregate"]],
voyce_series_lock_config_by_pk?: [{	id:number},ValueTypes["voyce_series_lock_config"]],
voyce_series_sponsorships_configuration?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_sponsorships_configuration_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_sponsorships_configuration_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_sponsorships_configuration_bool_exp"]},ValueTypes["voyce_series_sponsorships_configuration"]],
voyce_series_sponsorships_configuration_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_sponsorships_configuration_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_sponsorships_configuration_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_sponsorships_configuration_bool_exp"]},ValueTypes["voyce_series_sponsorships_configuration_aggregate"]],
voyce_series_sponsorships_configuration_by_pk?: [{	id:number},ValueTypes["voyce_series_sponsorships_configuration"]],
voyce_series_subscription?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_subscription_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_subscription_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_subscription_bool_exp"]},ValueTypes["voyce_series_subscription"]],
voyce_series_subscription_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_subscription_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_subscription_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_subscription_bool_exp"]},ValueTypes["voyce_series_subscription_aggregate"]],
voyce_series_subscription_by_pk?: [{	id:number},ValueTypes["voyce_series_subscription"]],
voyce_series_tags?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_tags_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_tags_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_tags_bool_exp"]},ValueTypes["voyce_series_tags"]],
voyce_series_tags_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_tags_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_tags_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_tags_bool_exp"]},ValueTypes["voyce_series_tags_aggregate"]],
voyce_series_tags_by_pk?: [{	id:number},ValueTypes["voyce_series_tags"]],
voyce_series_tags_junction?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_tags_junction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_tags_junction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_tags_junction_bool_exp"]},ValueTypes["voyce_series_tags_junction"]],
voyce_series_tags_junction_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_tags_junction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_tags_junction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_tags_junction_bool_exp"]},ValueTypes["voyce_series_tags_junction_aggregate"]],
voyce_series_tags_junction_by_pk?: [{	id:number},ValueTypes["voyce_series_tags_junction"]],
voyce_series_top_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_top_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_top_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_top_comments_bool_exp"]},ValueTypes["voyce_series_top_comments"]],
voyce_series_top_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_top_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_top_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_top_comments_bool_exp"]},ValueTypes["voyce_series_top_comments_aggregate"]],
voyce_series_top_comments_by_pk?: [{	id:number},ValueTypes["voyce_series_top_comments"]],
voyce_series_top_likes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_top_likes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_top_likes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_top_likes_bool_exp"]},ValueTypes["voyce_series_top_likes"]],
voyce_series_top_likes_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_top_likes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_top_likes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_top_likes_bool_exp"]},ValueTypes["voyce_series_top_likes_aggregate"]],
voyce_series_top_likes_by_pk?: [{	id:number},ValueTypes["voyce_series_top_likes"]],
voyce_series_top_views?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_top_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_top_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_top_views_bool_exp"]},ValueTypes["voyce_series_top_views"]],
voyce_series_top_views_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_top_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_top_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_top_views_bool_exp"]},ValueTypes["voyce_series_top_views_aggregate"]],
voyce_series_top_views_by_pk?: [{	id:number},ValueTypes["voyce_series_top_views"]],
voyce_series_views_adjustment?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_adjustment_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_adjustment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_adjustment_bool_exp"]},ValueTypes["voyce_series_views_adjustment"]],
voyce_series_views_adjustment_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_adjustment_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_adjustment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_adjustment_bool_exp"]},ValueTypes["voyce_series_views_adjustment_aggregate"]],
voyce_series_views_adjustment_by_pk?: [{	id:number},ValueTypes["voyce_series_views_adjustment"]],
voyce_series_views_count?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_bool_exp"]},ValueTypes["voyce_series_views_count"]],
voyce_series_views_count_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_bool_exp"]},ValueTypes["voyce_series_views_count_aggregate"]],
voyce_series_views_count_mat?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_count_mat_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_count_mat_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_mat_bool_exp"]},ValueTypes["voyce_series_views_count_mat"]],
voyce_series_views_count_mat_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_count_mat_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_count_mat_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_mat_bool_exp"]},ValueTypes["voyce_series_views_count_mat_aggregate"]],
voyce_series_views_count_mat_sep30?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_count_mat_sep30_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_count_mat_sep30_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_mat_sep30_bool_exp"]},ValueTypes["voyce_series_views_count_mat_sep30"]],
voyce_series_views_count_mat_sep30_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_count_mat_sep30_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_count_mat_sep30_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_mat_sep30_bool_exp"]},ValueTypes["voyce_series_views_count_mat_sep30_aggregate"]],
voyce_series_views_count_mat_v2?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_count_mat_v2_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_count_mat_v2_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_mat_v2_bool_exp"]},ValueTypes["voyce_series_views_count_mat_v2"]],
voyce_series_views_count_mat_v2_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_count_mat_v2_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_count_mat_v2_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_mat_v2_bool_exp"]},ValueTypes["voyce_series_views_count_mat_v2_aggregate"]],
voyce_series_votes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_votes_bool_exp"]},ValueTypes["voyce_series_votes"]],
voyce_series_votes_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_votes_bool_exp"]},ValueTypes["voyce_series_votes_aggregate"]],
voyce_series_votes_by_pk?: [{	id:number},ValueTypes["voyce_series_votes"]],
voyce_series_votes_count?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_votes_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_votes_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_votes_count_bool_exp"]},ValueTypes["voyce_series_votes_count"]],
voyce_series_votes_count_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_votes_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_votes_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_votes_count_bool_exp"]},ValueTypes["voyce_series_votes_count_aggregate"]],
voyce_series_votes_count_by_pk?: [{	id:number},ValueTypes["voyce_series_votes_count"]],
voyce_series_voting_schedules?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_voting_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_voting_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_voting_schedules_bool_exp"]},ValueTypes["voyce_series_voting_schedules"]],
voyce_series_voting_schedules_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_voting_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_voting_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_voting_schedules_bool_exp"]},ValueTypes["voyce_series_voting_schedules_aggregate"]],
voyce_series_voting_schedules_by_pk?: [{	id:number},ValueTypes["voyce_series_voting_schedules"]],
voyce_sponsorships?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_sponsorships_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_sponsorships_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_sponsorships_bool_exp"]},ValueTypes["voyce_sponsorships"]],
voyce_sponsorships_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_sponsorships_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_sponsorships_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_sponsorships_bool_exp"]},ValueTypes["voyce_sponsorships_aggregate"]],
voyce_sponsorships_by_pk?: [{	id:number},ValueTypes["voyce_sponsorships"]],
voyce_states?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_states_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_states_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_states_bool_exp"]},ValueTypes["voyce_states"]],
voyce_states_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_states_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_states_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_states_bool_exp"]},ValueTypes["voyce_states_aggregate"]],
voyce_states_by_pk?: [{	id:number},ValueTypes["voyce_states"]],
voyce_stripe_payouts_setup?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_stripe_payouts_setup_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_stripe_payouts_setup_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_stripe_payouts_setup_bool_exp"]},ValueTypes["voyce_stripe_payouts_setup"]],
voyce_stripe_payouts_setup_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_stripe_payouts_setup_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_stripe_payouts_setup_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_stripe_payouts_setup_bool_exp"]},ValueTypes["voyce_stripe_payouts_setup_aggregate"]],
voyce_stripe_payouts_setup_by_pk?: [{	id:number},ValueTypes["voyce_stripe_payouts_setup"]],
voyce_studio?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_bool_exp"]},ValueTypes["voyce_studio"]],
voyce_studio_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_bool_exp"]},ValueTypes["voyce_studio_aggregate"]],
voyce_studio_by_pk?: [{	id:number},ValueTypes["voyce_studio"]],
voyce_studio_followers?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_followers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_followers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_followers_bool_exp"]},ValueTypes["voyce_studio_followers"]],
voyce_studio_followers_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_followers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_followers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_followers_bool_exp"]},ValueTypes["voyce_studio_followers_aggregate"]],
voyce_studio_followers_by_pk?: [{	id:number},ValueTypes["voyce_studio_followers"]],
voyce_studio_series?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_series_bool_exp"]},ValueTypes["voyce_studio_series"]],
voyce_studio_series_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_series_bool_exp"]},ValueTypes["voyce_studio_series_aggregate"]],
voyce_studio_series_by_pk?: [{	id:number},ValueTypes["voyce_studio_series"]],
voyce_studio_views?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_views_bool_exp"]},ValueTypes["voyce_studio_views"]],
voyce_studio_views_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_views_bool_exp"]},ValueTypes["voyce_studio_views_aggregate"]],
voyce_studio_views_by_pk?: [{	id:number},ValueTypes["voyce_studio_views"]],
voyce_system_settings?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_system_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_system_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_system_settings_bool_exp"]},ValueTypes["voyce_system_settings"]],
voyce_system_settings_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_system_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_system_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_system_settings_bool_exp"]},ValueTypes["voyce_system_settings_aggregate"]],
voyce_system_settings_by_pk?: [{	id:number},ValueTypes["voyce_system_settings"]],
voyce_top_genres?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_top_genres_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_top_genres_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_top_genres_bool_exp"]},ValueTypes["voyce_top_genres"]],
voyce_top_genres_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_top_genres_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_top_genres_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_top_genres_bool_exp"]},ValueTypes["voyce_top_genres_aggregate"]],
voyce_transaction_logs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_transaction_logs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_transaction_logs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_transaction_logs_bool_exp"]},ValueTypes["voyce_transaction_logs"]],
voyce_transaction_logs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_transaction_logs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_transaction_logs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_transaction_logs_bool_exp"]},ValueTypes["voyce_transaction_logs_aggregate"]],
voyce_transaction_logs_by_pk?: [{	id:number},ValueTypes["voyce_transaction_logs"]],
voyce_url_redirects?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_url_redirects_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_url_redirects_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_url_redirects_bool_exp"]},ValueTypes["voyce_url_redirects"]],
voyce_url_redirects_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_url_redirects_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_url_redirects_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_url_redirects_bool_exp"]},ValueTypes["voyce_url_redirects_aggregate"]],
voyce_url_redirects_by_pk?: [{	id:number},ValueTypes["voyce_url_redirects"]],
voyce_user_addresses?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_addresses_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_addresses_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_addresses_bool_exp"]},ValueTypes["voyce_user_addresses"]],
voyce_user_addresses_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_addresses_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_addresses_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_addresses_bool_exp"]},ValueTypes["voyce_user_addresses_aggregate"]],
voyce_user_addresses_by_pk?: [{	id:number},ValueTypes["voyce_user_addresses"]],
voyce_user_blocks?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_blocks_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_blocks_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_blocks_bool_exp"]},ValueTypes["voyce_user_blocks"]],
voyce_user_blocks_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_blocks_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_blocks_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_blocks_bool_exp"]},ValueTypes["voyce_user_blocks_aggregate"]],
voyce_user_blocks_by_pk?: [{	linked_id:number,	user_id:number},ValueTypes["voyce_user_blocks"]],
voyce_user_chapters_unlocked?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_chapters_unlocked_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_chapters_unlocked_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_chapters_unlocked_bool_exp"]},ValueTypes["voyce_user_chapters_unlocked"]],
voyce_user_chapters_unlocked_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_chapters_unlocked_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_chapters_unlocked_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_chapters_unlocked_bool_exp"]},ValueTypes["voyce_user_chapters_unlocked_aggregate"]],
voyce_user_chapters_unlocked_by_pk?: [{	id:number},ValueTypes["voyce_user_chapters_unlocked"]],
voyce_user_contacts?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_contacts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_contacts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_contacts_bool_exp"]},ValueTypes["voyce_user_contacts"]],
voyce_user_contacts_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_contacts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_contacts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_contacts_bool_exp"]},ValueTypes["voyce_user_contacts_aggregate"]],
voyce_user_contacts_by_pk?: [{	id:number},ValueTypes["voyce_user_contacts"]],
voyce_user_csat_ratings?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_csat_ratings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_csat_ratings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_csat_ratings_bool_exp"]},ValueTypes["voyce_user_csat_ratings"]],
voyce_user_csat_ratings_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_csat_ratings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_csat_ratings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_csat_ratings_bool_exp"]},ValueTypes["voyce_user_csat_ratings_aggregate"]],
voyce_user_csat_ratings_by_pk?: [{	id:number},ValueTypes["voyce_user_csat_ratings"]],
voyce_user_education?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_education_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_education_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_education_bool_exp"]},ValueTypes["voyce_user_education"]],
voyce_user_education_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_education_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_education_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_education_bool_exp"]},ValueTypes["voyce_user_education_aggregate"]],
voyce_user_education_by_pk?: [{	id:number},ValueTypes["voyce_user_education"]],
voyce_user_level?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_level_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_level_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_level_bool_exp"]},ValueTypes["voyce_user_level"]],
voyce_user_level_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_level_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_level_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_level_bool_exp"]},ValueTypes["voyce_user_level_aggregate"]],
voyce_user_level_by_pk?: [{	id:number},ValueTypes["voyce_user_level"]],
voyce_user_linked_accounts?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_linked_accounts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_linked_accounts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_linked_accounts_bool_exp"]},ValueTypes["voyce_user_linked_accounts"]],
voyce_user_linked_accounts_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_linked_accounts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_linked_accounts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_linked_accounts_bool_exp"]},ValueTypes["voyce_user_linked_accounts_aggregate"]],
voyce_user_linked_accounts_by_pk?: [{	id:number},ValueTypes["voyce_user_linked_accounts"]],
voyce_user_login_history?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_login_history_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_login_history_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_login_history_bool_exp"]},ValueTypes["voyce_user_login_history"]],
voyce_user_login_history_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_login_history_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_login_history_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_login_history_bool_exp"]},ValueTypes["voyce_user_login_history_aggregate"]],
voyce_user_login_history_by_pk?: [{	id:number},ValueTypes["voyce_user_login_history"]],
voyce_user_quest_ids?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quest_ids_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quest_ids_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quest_ids_bool_exp"]},ValueTypes["voyce_user_quest_ids"]],
voyce_user_quest_ids_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quest_ids_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quest_ids_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quest_ids_bool_exp"]},ValueTypes["voyce_user_quest_ids_aggregate"]],
voyce_user_quest_ids_by_pk?: [{	id:number},ValueTypes["voyce_user_quest_ids"]],
voyce_user_quest_rewards?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quest_rewards_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quest_rewards_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quest_rewards_bool_exp"]},ValueTypes["voyce_user_quest_rewards"]],
voyce_user_quest_rewards_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quest_rewards_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quest_rewards_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quest_rewards_bool_exp"]},ValueTypes["voyce_user_quest_rewards_aggregate"]],
voyce_user_quest_rewards_by_pk?: [{	id:number},ValueTypes["voyce_user_quest_rewards"]],
voyce_user_quests?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quests_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quests_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quests_bool_exp"]},ValueTypes["voyce_user_quests"]],
voyce_user_quests_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quests_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quests_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quests_bool_exp"]},ValueTypes["voyce_user_quests_aggregate"]],
voyce_user_quests_by_pk?: [{	id:number},ValueTypes["voyce_user_quests"]],
voyce_user_quests_old?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quests_old_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quests_old_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quests_old_bool_exp"]},ValueTypes["voyce_user_quests_old"]],
voyce_user_quests_old_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quests_old_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quests_old_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quests_old_bool_exp"]},ValueTypes["voyce_user_quests_old_aggregate"]],
voyce_user_quests_old_by_pk?: [{	id:number},ValueTypes["voyce_user_quests_old"]],
voyce_user_ranking?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_ranking_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_ranking_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_ranking_bool_exp"]},ValueTypes["voyce_user_ranking"]],
voyce_user_ranking_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_ranking_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_ranking_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_ranking_bool_exp"]},ValueTypes["voyce_user_ranking_aggregate"]],
voyce_user_ranking_by_pk?: [{	id:number},ValueTypes["voyce_user_ranking"]],
voyce_user_reviews?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_reviews_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_reviews_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_reviews_bool_exp"]},ValueTypes["voyce_user_reviews"]],
voyce_user_reviews_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_reviews_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_reviews_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_reviews_bool_exp"]},ValueTypes["voyce_user_reviews_aggregate"]],
voyce_user_reviews_by_pk?: [{	id:number},ValueTypes["voyce_user_reviews"]],
voyce_user_rewards?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_rewards_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_rewards_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_rewards_bool_exp"]},ValueTypes["voyce_user_rewards"]],
voyce_user_rewards_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_rewards_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_rewards_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_rewards_bool_exp"]},ValueTypes["voyce_user_rewards_aggregate"]],
voyce_user_rewards_by_pk?: [{	id:number},ValueTypes["voyce_user_rewards"]],
voyce_user_roles?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_roles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_roles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_roles_bool_exp"]},ValueTypes["voyce_user_roles"]],
voyce_user_roles_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_roles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_roles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_roles_bool_exp"]},ValueTypes["voyce_user_roles_aggregate"]],
voyce_user_roles_by_pk?: [{	id:number},ValueTypes["voyce_user_roles"]],
voyce_user_series_subscription_count?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_series_subscription_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_series_subscription_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_series_subscription_count_bool_exp"]},ValueTypes["voyce_user_series_subscription_count"]],
voyce_user_series_subscription_count_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_series_subscription_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_series_subscription_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_series_subscription_count_bool_exp"]},ValueTypes["voyce_user_series_subscription_count_aggregate"]],
voyce_user_settings?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_settings_bool_exp"]},ValueTypes["voyce_user_settings"]],
voyce_user_settings_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_settings_bool_exp"]},ValueTypes["voyce_user_settings_aggregate"]],
voyce_user_settings_by_pk?: [{	user_id:number},ValueTypes["voyce_user_settings"]],
voyce_user_skills?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_skills_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_skills_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_skills_bool_exp"]},ValueTypes["voyce_user_skills"]],
voyce_user_skills_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_skills_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_skills_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_skills_bool_exp"]},ValueTypes["voyce_user_skills_aggregate"]],
voyce_user_skills_by_pk?: [{	id:number},ValueTypes["voyce_user_skills"]],
voyce_user_socials?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_socials_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_socials_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_socials_bool_exp"]},ValueTypes["voyce_user_socials"]],
voyce_user_socials_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_socials_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_socials_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_socials_bool_exp"]},ValueTypes["voyce_user_socials_aggregate"]],
voyce_user_socials_by_pk?: [{	id:number},ValueTypes["voyce_user_socials"]],
voyce_user_soft_deleted?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_soft_deleted_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_soft_deleted_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_soft_deleted_bool_exp"]},ValueTypes["voyce_user_soft_deleted"]],
voyce_user_soft_deleted_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_soft_deleted_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_soft_deleted_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_soft_deleted_bool_exp"]},ValueTypes["voyce_user_soft_deleted_aggregate"]],
voyce_user_soft_deleted_by_pk?: [{	id:number},ValueTypes["voyce_user_soft_deleted"]],
voyce_user_vtags?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_vtags_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_vtags_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_vtags_bool_exp"]},ValueTypes["voyce_user_vtags"]],
voyce_user_vtags_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_vtags_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_vtags_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_vtags_bool_exp"]},ValueTypes["voyce_user_vtags_aggregate"]],
voyce_user_vtags_by_pk?: [{	id:number},ValueTypes["voyce_user_vtags"]],
voyce_user_wins?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_wins_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_wins_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_wins_bool_exp"]},ValueTypes["voyce_user_wins"]],
voyce_user_wins_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_wins_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_wins_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_wins_bool_exp"]},ValueTypes["voyce_user_wins_aggregate"]],
voyce_user_wins_by_pk?: [{	id:number},ValueTypes["voyce_user_wins"]],
voyce_users?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_bool_exp"]},ValueTypes["voyce_users"]],
voyce_users_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_bool_exp"]},ValueTypes["voyce_users_aggregate"]],
voyce_users_by_pk?: [{	id:number},ValueTypes["voyce_users"]],
voyce_users_followers?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_followers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_followers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_followers_bool_exp"]},ValueTypes["voyce_users_followers"]],
voyce_users_followers_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_followers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_followers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_followers_bool_exp"]},ValueTypes["voyce_users_followers_aggregate"]],
voyce_users_followers_by_pk?: [{	id:number},ValueTypes["voyce_users_followers"]],
voyce_users_private?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_private_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_private_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_private_bool_exp"]},ValueTypes["voyce_users_private"]],
voyce_users_private_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_private_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_private_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_private_bool_exp"]},ValueTypes["voyce_users_private_aggregate"]],
voyce_users_series_junction?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_series_junction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_series_junction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_series_junction_bool_exp"]},ValueTypes["voyce_users_series_junction"]],
voyce_users_series_junction_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_series_junction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_series_junction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_series_junction_bool_exp"]},ValueTypes["voyce_users_series_junction_aggregate"]],
voyce_users_series_junction_by_pk?: [{	id:number},ValueTypes["voyce_users_series_junction"]],
voyce_versions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_versions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_versions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_versions_bool_exp"]},ValueTypes["voyce_versions"]],
voyce_versions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_versions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_versions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_versions_bool_exp"]},ValueTypes["voyce_versions_aggregate"]],
voyce_versions_by_pk?: [{	id:number},ValueTypes["voyce_versions"]],
voyce_voting_analytics?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_analytics_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_analytics_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_analytics_bool_exp"]},ValueTypes["voyce_voting_analytics"]],
voyce_voting_analytics_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_analytics_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_analytics_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_analytics_bool_exp"]},ValueTypes["voyce_voting_analytics_aggregate"]],
voyce_voting_analytics_by_pk?: [{	id:number},ValueTypes["voyce_voting_analytics"]],
voyce_voting_panels?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_panels_bool_exp"]},ValueTypes["voyce_voting_panels"]],
voyce_voting_panels_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_panels_bool_exp"]},ValueTypes["voyce_voting_panels_aggregate"]],
voyce_voting_panels_by_pk?: [{	id:number},ValueTypes["voyce_voting_panels"]],
voyce_voting_series?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_series_bool_exp"]},ValueTypes["voyce_voting_series"]],
voyce_voting_series_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_series_bool_exp"]},ValueTypes["voyce_voting_series_aggregate"]],
voyce_voting_series_by_pk?: [{	id:number},ValueTypes["voyce_voting_series"]],
voyce_voting_user_votes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_user_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_user_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_user_votes_bool_exp"]},ValueTypes["voyce_voting_user_votes"]],
voyce_voting_user_votes_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_user_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_user_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_user_votes_bool_exp"]},ValueTypes["voyce_voting_user_votes_aggregate"]],
voyce_voting_user_votes_by_pk?: [{	id:number},ValueTypes["voyce_voting_user_votes"]],
		__typename?: true
}>;
	["smallint"]:unknown;
	/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
["smallint_comparison_exp"]: {
	_eq?:ValueTypes["smallint"],
	_gt?:ValueTypes["smallint"],
	_gte?:ValueTypes["smallint"],
	_in?:ValueTypes["smallint"][],
	_is_null?:boolean,
	_lt?:ValueTypes["smallint"],
	_lte?:ValueTypes["smallint"],
	_neq?:ValueTypes["smallint"],
	_nin?:ValueTypes["smallint"][]
};
	/** columns and relationships of "store.payments" */
["store_payments"]: AliasType<{
	amount?:true,
	created_at?:true,
	currency?:true,
	gateway?:true,
	id?:true,
	purchase_id?:true,
	remarks?:true,
	status?:true,
		__typename?: true
}>;
	/** aggregated selection of "store.payments" */
["store_payments_aggregate"]: AliasType<{
	aggregate?:ValueTypes["store_payments_aggregate_fields"],
	nodes?:ValueTypes["store_payments"],
		__typename?: true
}>;
	/** aggregate fields of "store.payments" */
["store_payments_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["store_payments_avg_fields"],
count?: [{	columns?:ValueTypes["store_payments_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["store_payments_max_fields"],
	min?:ValueTypes["store_payments_min_fields"],
	stddev?:ValueTypes["store_payments_stddev_fields"],
	stddev_pop?:ValueTypes["store_payments_stddev_pop_fields"],
	stddev_samp?:ValueTypes["store_payments_stddev_samp_fields"],
	sum?:ValueTypes["store_payments_sum_fields"],
	var_pop?:ValueTypes["store_payments_var_pop_fields"],
	var_samp?:ValueTypes["store_payments_var_samp_fields"],
	variance?:ValueTypes["store_payments_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["store_payments_avg_fields"]: AliasType<{
	amount?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "store.payments". All fields are combined with a logical 'AND'. */
["store_payments_bool_exp"]: {
	_and?:ValueTypes["store_payments_bool_exp"][],
	_not?:ValueTypes["store_payments_bool_exp"],
	_or?:ValueTypes["store_payments_bool_exp"][],
	amount?:ValueTypes["numeric_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	currency?:ValueTypes["String_comparison_exp"],
	gateway?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["uuid_comparison_exp"],
	purchase_id?:ValueTypes["String_comparison_exp"],
	remarks?:ValueTypes["String_comparison_exp"],
	status?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "store.payments" */
["store_payments_constraint"]:store_payments_constraint;
	/** input type for incrementing numeric columns in table "store.payments" */
["store_payments_inc_input"]: {
	amount?:ValueTypes["numeric"]
};
	/** input type for inserting data into table "store.payments" */
["store_payments_insert_input"]: {
	amount?:ValueTypes["numeric"],
	created_at?:ValueTypes["timestamptz"],
	currency?:string,
	gateway?:string,
	id?:ValueTypes["uuid"],
	purchase_id?:string,
	remarks?:string,
	status?:string
};
	/** aggregate max on columns */
["store_payments_max_fields"]: AliasType<{
	amount?:true,
	created_at?:true,
	currency?:true,
	gateway?:true,
	id?:true,
	purchase_id?:true,
	remarks?:true,
	status?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["store_payments_min_fields"]: AliasType<{
	amount?:true,
	created_at?:true,
	currency?:true,
	gateway?:true,
	id?:true,
	purchase_id?:true,
	remarks?:true,
	status?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "store.payments" */
["store_payments_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["store_payments"],
		__typename?: true
}>;
	/** on_conflict condition type for table "store.payments" */
["store_payments_on_conflict"]: {
	constraint:ValueTypes["store_payments_constraint"],
	update_columns:ValueTypes["store_payments_update_column"][],
	where?:ValueTypes["store_payments_bool_exp"]
};
	/** Ordering options when selecting data from "store.payments". */
["store_payments_order_by"]: {
	amount?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	currency?:ValueTypes["order_by"],
	gateway?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	purchase_id?:ValueTypes["order_by"],
	remarks?:ValueTypes["order_by"],
	status?:ValueTypes["order_by"]
};
	/** primary key columns input for table: store.payments */
["store_payments_pk_columns_input"]: {
	id:ValueTypes["uuid"],
	purchase_id:string
};
	/** select columns of table "store.payments" */
["store_payments_select_column"]:store_payments_select_column;
	/** input type for updating data in table "store.payments" */
["store_payments_set_input"]: {
	amount?:ValueTypes["numeric"],
	created_at?:ValueTypes["timestamptz"],
	currency?:string,
	gateway?:string,
	id?:ValueTypes["uuid"],
	purchase_id?:string,
	remarks?:string,
	status?:string
};
	/** aggregate stddev on columns */
["store_payments_stddev_fields"]: AliasType<{
	amount?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["store_payments_stddev_pop_fields"]: AliasType<{
	amount?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["store_payments_stddev_samp_fields"]: AliasType<{
	amount?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "store_payments" */
["store_payments_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["store_payments_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["store_payments_stream_cursor_value_input"]: {
	amount?:ValueTypes["numeric"],
	created_at?:ValueTypes["timestamptz"],
	currency?:string,
	gateway?:string,
	id?:ValueTypes["uuid"],
	purchase_id?:string,
	remarks?:string,
	status?:string
};
	/** aggregate sum on columns */
["store_payments_sum_fields"]: AliasType<{
	amount?:true,
		__typename?: true
}>;
	/** update columns of table "store.payments" */
["store_payments_update_column"]:store_payments_update_column;
	["store_payments_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["store_payments_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["store_payments_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["store_payments_bool_exp"]
};
	/** aggregate var_pop on columns */
["store_payments_var_pop_fields"]: AliasType<{
	amount?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["store_payments_var_samp_fields"]: AliasType<{
	amount?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["store_payments_variance_fields"]: AliasType<{
	amount?:true,
		__typename?: true
}>;
	/** columns and relationships of "store.products" */
["store_products"]: AliasType<{
	category?:true,
	chapter_type?:true,
	currency?:true,
	description?:true,
	enabled?:true,
	id?:true,
	image?:true,
	is_store_visible?:true,
	label?:true,
	name?:true,
	price?:true,
	purchase_limit?:true,
	quantity?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	sort_num?:true,
	tag?:true,
	type?:true,
		__typename?: true
}>;
	/** aggregated selection of "store.products" */
["store_products_aggregate"]: AliasType<{
	aggregate?:ValueTypes["store_products_aggregate_fields"],
	nodes?:ValueTypes["store_products"],
		__typename?: true
}>;
	/** aggregate fields of "store.products" */
["store_products_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["store_products_avg_fields"],
count?: [{	columns?:ValueTypes["store_products_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["store_products_max_fields"],
	min?:ValueTypes["store_products_min_fields"],
	stddev?:ValueTypes["store_products_stddev_fields"],
	stddev_pop?:ValueTypes["store_products_stddev_pop_fields"],
	stddev_samp?:ValueTypes["store_products_stddev_samp_fields"],
	sum?:ValueTypes["store_products_sum_fields"],
	var_pop?:ValueTypes["store_products_var_pop_fields"],
	var_samp?:ValueTypes["store_products_var_samp_fields"],
	variance?:ValueTypes["store_products_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["store_products_avg_fields"]: AliasType<{
	price?:true,
	purchase_limit?:true,
	quantity?:true,
	series_id?:true,
	sort_num?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "store.products". All fields are combined with a logical 'AND'. */
["store_products_bool_exp"]: {
	_and?:ValueTypes["store_products_bool_exp"][],
	_not?:ValueTypes["store_products_bool_exp"],
	_or?:ValueTypes["store_products_bool_exp"][],
	category?:ValueTypes["String_comparison_exp"],
	chapter_type?:ValueTypes["String_comparison_exp"],
	currency?:ValueTypes["String_comparison_exp"],
	description?:ValueTypes["String_comparison_exp"],
	enabled?:ValueTypes["Boolean_comparison_exp"],
	id?:ValueTypes["uuid_comparison_exp"],
	image?:ValueTypes["String_comparison_exp"],
	is_store_visible?:ValueTypes["Boolean_comparison_exp"],
	label?:ValueTypes["String_comparison_exp"],
	name?:ValueTypes["String_comparison_exp"],
	price?:ValueTypes["numeric_comparison_exp"],
	purchase_limit?:ValueTypes["numeric_comparison_exp"],
	quantity?:ValueTypes["Int_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["numeric_comparison_exp"],
	sort_num?:ValueTypes["numeric_comparison_exp"],
	tag?:ValueTypes["String_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "store.products" */
["store_products_constraint"]:store_products_constraint;
	/** input type for incrementing numeric columns in table "store.products" */
["store_products_inc_input"]: {
	price?:ValueTypes["numeric"],
	purchase_limit?:ValueTypes["numeric"],
	quantity?:number,
	series_id?:ValueTypes["numeric"],
	sort_num?:ValueTypes["numeric"]
};
	/** input type for inserting data into table "store.products" */
["store_products_insert_input"]: {
	category?:string,
	chapter_type?:string,
	currency?:string,
	description?:string,
	enabled?:boolean,
	id?:ValueTypes["uuid"],
	image?:string,
	is_store_visible?:boolean,
	label?:string,
	name?:string,
	price?:ValueTypes["numeric"],
	purchase_limit?:ValueTypes["numeric"],
	quantity?:number,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:ValueTypes["numeric"],
	sort_num?:ValueTypes["numeric"],
	tag?:string,
	type?:string
};
	/** aggregate max on columns */
["store_products_max_fields"]: AliasType<{
	category?:true,
	chapter_type?:true,
	currency?:true,
	description?:true,
	id?:true,
	image?:true,
	label?:true,
	name?:true,
	price?:true,
	purchase_limit?:true,
	quantity?:true,
	series_id?:true,
	sort_num?:true,
	tag?:true,
	type?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["store_products_min_fields"]: AliasType<{
	category?:true,
	chapter_type?:true,
	currency?:true,
	description?:true,
	id?:true,
	image?:true,
	label?:true,
	name?:true,
	price?:true,
	purchase_limit?:true,
	quantity?:true,
	series_id?:true,
	sort_num?:true,
	tag?:true,
	type?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "store.products" */
["store_products_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["store_products"],
		__typename?: true
}>;
	/** on_conflict condition type for table "store.products" */
["store_products_on_conflict"]: {
	constraint:ValueTypes["store_products_constraint"],
	update_columns:ValueTypes["store_products_update_column"][],
	where?:ValueTypes["store_products_bool_exp"]
};
	/** Ordering options when selecting data from "store.products". */
["store_products_order_by"]: {
	category?:ValueTypes["order_by"],
	chapter_type?:ValueTypes["order_by"],
	currency?:ValueTypes["order_by"],
	description?:ValueTypes["order_by"],
	enabled?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	is_store_visible?:ValueTypes["order_by"],
	label?:ValueTypes["order_by"],
	name?:ValueTypes["order_by"],
	price?:ValueTypes["order_by"],
	purchase_limit?:ValueTypes["order_by"],
	quantity?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	sort_num?:ValueTypes["order_by"],
	tag?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"]
};
	/** primary key columns input for table: store.products */
["store_products_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "store.products" */
["store_products_select_column"]:store_products_select_column;
	/** input type for updating data in table "store.products" */
["store_products_set_input"]: {
	category?:string,
	chapter_type?:string,
	currency?:string,
	description?:string,
	enabled?:boolean,
	id?:ValueTypes["uuid"],
	image?:string,
	is_store_visible?:boolean,
	label?:string,
	name?:string,
	price?:ValueTypes["numeric"],
	purchase_limit?:ValueTypes["numeric"],
	quantity?:number,
	series_id?:ValueTypes["numeric"],
	sort_num?:ValueTypes["numeric"],
	tag?:string,
	type?:string
};
	/** aggregate stddev on columns */
["store_products_stddev_fields"]: AliasType<{
	price?:true,
	purchase_limit?:true,
	quantity?:true,
	series_id?:true,
	sort_num?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["store_products_stddev_pop_fields"]: AliasType<{
	price?:true,
	purchase_limit?:true,
	quantity?:true,
	series_id?:true,
	sort_num?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["store_products_stddev_samp_fields"]: AliasType<{
	price?:true,
	purchase_limit?:true,
	quantity?:true,
	series_id?:true,
	sort_num?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "store_products" */
["store_products_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["store_products_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["store_products_stream_cursor_value_input"]: {
	category?:string,
	chapter_type?:string,
	currency?:string,
	description?:string,
	enabled?:boolean,
	id?:ValueTypes["uuid"],
	image?:string,
	is_store_visible?:boolean,
	label?:string,
	name?:string,
	price?:ValueTypes["numeric"],
	purchase_limit?:ValueTypes["numeric"],
	quantity?:number,
	series_id?:ValueTypes["numeric"],
	sort_num?:ValueTypes["numeric"],
	tag?:string,
	type?:string
};
	/** aggregate sum on columns */
["store_products_sum_fields"]: AliasType<{
	price?:true,
	purchase_limit?:true,
	quantity?:true,
	series_id?:true,
	sort_num?:true,
		__typename?: true
}>;
	/** update columns of table "store.products" */
["store_products_update_column"]:store_products_update_column;
	["store_products_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["store_products_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["store_products_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["store_products_bool_exp"]
};
	/** aggregate var_pop on columns */
["store_products_var_pop_fields"]: AliasType<{
	price?:true,
	purchase_limit?:true,
	quantity?:true,
	series_id?:true,
	sort_num?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["store_products_var_samp_fields"]: AliasType<{
	price?:true,
	purchase_limit?:true,
	quantity?:true,
	series_id?:true,
	sort_num?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["store_products_variance_fields"]: AliasType<{
	price?:true,
	purchase_limit?:true,
	quantity?:true,
	series_id?:true,
	sort_num?:true,
		__typename?: true
}>;
	/** columns and relationships of "store.purchases" */
["store_purchases"]: AliasType<{
	created_at?:true,
	id?:true,
	product_category?:true,
	product_currency?:true,
	product_description?:true,
	product_image_url?:true,
	product_label?:true,
product_meta?: [{	/** JSON select path */
	path?:string},true],
	product_name?:true,
	product_price?:true,
	product_type?:true,
	quantity?:true,
	reference_id?:true,
	remarks?:true,
	series_id?:true,
	status?:true,
	total_price?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "store.purchases" */
["store_purchases_aggregate"]: AliasType<{
	aggregate?:ValueTypes["store_purchases_aggregate_fields"],
	nodes?:ValueTypes["store_purchases"],
		__typename?: true
}>;
	/** aggregate fields of "store.purchases" */
["store_purchases_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["store_purchases_avg_fields"],
count?: [{	columns?:ValueTypes["store_purchases_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["store_purchases_max_fields"],
	min?:ValueTypes["store_purchases_min_fields"],
	stddev?:ValueTypes["store_purchases_stddev_fields"],
	stddev_pop?:ValueTypes["store_purchases_stddev_pop_fields"],
	stddev_samp?:ValueTypes["store_purchases_stddev_samp_fields"],
	sum?:ValueTypes["store_purchases_sum_fields"],
	var_pop?:ValueTypes["store_purchases_var_pop_fields"],
	var_samp?:ValueTypes["store_purchases_var_samp_fields"],
	variance?:ValueTypes["store_purchases_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["store_purchases_avg_fields"]: AliasType<{
	product_price?:true,
	quantity?:true,
	series_id?:true,
	total_price?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "store.purchases". All fields are combined with a logical 'AND'. */
["store_purchases_bool_exp"]: {
	_and?:ValueTypes["store_purchases_bool_exp"][],
	_not?:ValueTypes["store_purchases_bool_exp"],
	_or?:ValueTypes["store_purchases_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["uuid_comparison_exp"],
	product_category?:ValueTypes["String_comparison_exp"],
	product_currency?:ValueTypes["String_comparison_exp"],
	product_description?:ValueTypes["String_comparison_exp"],
	product_image_url?:ValueTypes["String_comparison_exp"],
	product_label?:ValueTypes["String_comparison_exp"],
	product_meta?:ValueTypes["json_comparison_exp"],
	product_name?:ValueTypes["String_comparison_exp"],
	product_price?:ValueTypes["numeric_comparison_exp"],
	product_type?:ValueTypes["String_comparison_exp"],
	quantity?:ValueTypes["numeric_comparison_exp"],
	reference_id?:ValueTypes["String_comparison_exp"],
	remarks?:ValueTypes["String_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	status?:ValueTypes["String_comparison_exp"],
	total_price?:ValueTypes["numeric_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	user_id?:ValueTypes["numeric_comparison_exp"]
};
	/** unique or primary key constraints on table "store.purchases" */
["store_purchases_constraint"]:store_purchases_constraint;
	/** input type for incrementing numeric columns in table "store.purchases" */
["store_purchases_inc_input"]: {
	product_price?:ValueTypes["numeric"],
	quantity?:ValueTypes["numeric"],
	series_id?:number,
	total_price?:ValueTypes["numeric"],
	user_id?:ValueTypes["numeric"]
};
	/** input type for inserting data into table "store.purchases" */
["store_purchases_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:ValueTypes["uuid"],
	product_category?:string,
	product_currency?:string,
	product_description?:string,
	product_image_url?:string,
	product_label?:string,
	product_meta?:ValueTypes["json"],
	product_name?:string,
	product_price?:ValueTypes["numeric"],
	product_type?:string,
	quantity?:ValueTypes["numeric"],
	reference_id?:string,
	remarks?:string,
	series_id?:number,
	status?:string,
	total_price?:ValueTypes["numeric"],
	updated_at?:ValueTypes["timestamptz"],
	user_id?:ValueTypes["numeric"]
};
	/** aggregate max on columns */
["store_purchases_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	product_category?:true,
	product_currency?:true,
	product_description?:true,
	product_image_url?:true,
	product_label?:true,
	product_name?:true,
	product_price?:true,
	product_type?:true,
	quantity?:true,
	reference_id?:true,
	remarks?:true,
	series_id?:true,
	status?:true,
	total_price?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["store_purchases_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	product_category?:true,
	product_currency?:true,
	product_description?:true,
	product_image_url?:true,
	product_label?:true,
	product_name?:true,
	product_price?:true,
	product_type?:true,
	quantity?:true,
	reference_id?:true,
	remarks?:true,
	series_id?:true,
	status?:true,
	total_price?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "store.purchases" */
["store_purchases_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["store_purchases"],
		__typename?: true
}>;
	/** on_conflict condition type for table "store.purchases" */
["store_purchases_on_conflict"]: {
	constraint:ValueTypes["store_purchases_constraint"],
	update_columns:ValueTypes["store_purchases_update_column"][],
	where?:ValueTypes["store_purchases_bool_exp"]
};
	/** Ordering options when selecting data from "store.purchases". */
["store_purchases_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	product_category?:ValueTypes["order_by"],
	product_currency?:ValueTypes["order_by"],
	product_description?:ValueTypes["order_by"],
	product_image_url?:ValueTypes["order_by"],
	product_label?:ValueTypes["order_by"],
	product_meta?:ValueTypes["order_by"],
	product_name?:ValueTypes["order_by"],
	product_price?:ValueTypes["order_by"],
	product_type?:ValueTypes["order_by"],
	quantity?:ValueTypes["order_by"],
	reference_id?:ValueTypes["order_by"],
	remarks?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	status?:ValueTypes["order_by"],
	total_price?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: store.purchases */
["store_purchases_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "store.purchases" */
["store_purchases_select_column"]:store_purchases_select_column;
	/** input type for updating data in table "store.purchases" */
["store_purchases_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:ValueTypes["uuid"],
	product_category?:string,
	product_currency?:string,
	product_description?:string,
	product_image_url?:string,
	product_label?:string,
	product_meta?:ValueTypes["json"],
	product_name?:string,
	product_price?:ValueTypes["numeric"],
	product_type?:string,
	quantity?:ValueTypes["numeric"],
	reference_id?:string,
	remarks?:string,
	series_id?:number,
	status?:string,
	total_price?:ValueTypes["numeric"],
	updated_at?:ValueTypes["timestamptz"],
	user_id?:ValueTypes["numeric"]
};
	/** aggregate stddev on columns */
["store_purchases_stddev_fields"]: AliasType<{
	product_price?:true,
	quantity?:true,
	series_id?:true,
	total_price?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["store_purchases_stddev_pop_fields"]: AliasType<{
	product_price?:true,
	quantity?:true,
	series_id?:true,
	total_price?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["store_purchases_stddev_samp_fields"]: AliasType<{
	product_price?:true,
	quantity?:true,
	series_id?:true,
	total_price?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "store_purchases" */
["store_purchases_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["store_purchases_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["store_purchases_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:ValueTypes["uuid"],
	product_category?:string,
	product_currency?:string,
	product_description?:string,
	product_image_url?:string,
	product_label?:string,
	product_meta?:ValueTypes["json"],
	product_name?:string,
	product_price?:ValueTypes["numeric"],
	product_type?:string,
	quantity?:ValueTypes["numeric"],
	reference_id?:string,
	remarks?:string,
	series_id?:number,
	status?:string,
	total_price?:ValueTypes["numeric"],
	updated_at?:ValueTypes["timestamptz"],
	user_id?:ValueTypes["numeric"]
};
	/** aggregate sum on columns */
["store_purchases_sum_fields"]: AliasType<{
	product_price?:true,
	quantity?:true,
	series_id?:true,
	total_price?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "store.purchases" */
["store_purchases_update_column"]:store_purchases_update_column;
	["store_purchases_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["store_purchases_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["store_purchases_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["store_purchases_bool_exp"]
};
	/** aggregate var_pop on columns */
["store_purchases_var_pop_fields"]: AliasType<{
	product_price?:true,
	quantity?:true,
	series_id?:true,
	total_price?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["store_purchases_var_samp_fields"]: AliasType<{
	product_price?:true,
	quantity?:true,
	series_id?:true,
	total_price?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["store_purchases_variance_fields"]: AliasType<{
	product_price?:true,
	quantity?:true,
	series_id?:true,
	total_price?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "store.users" */
["store_users"]: AliasType<{
	about?:true,
	ad_share?:true,
	avatar?:true,
	banner?:true,
	birth_date?:true,
	dark_mode?:true,
	date_create?:true,
	email?:true,
	first_name?:true,
	gender?:true,
	id?:true,
	is_account_active?:true,
	last_login?:true,
	last_name?:true,
	nickname?:true,
	password?:true,
	remember_code?:true,
	role_id?:true,
	username?:true,
	vmuser?:true,
		__typename?: true
}>;
	/** aggregated selection of "store.users" */
["store_users_aggregate"]: AliasType<{
	aggregate?:ValueTypes["store_users_aggregate_fields"],
	nodes?:ValueTypes["store_users"],
		__typename?: true
}>;
	/** aggregate fields of "store.users" */
["store_users_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["store_users_avg_fields"],
count?: [{	columns?:ValueTypes["store_users_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["store_users_max_fields"],
	min?:ValueTypes["store_users_min_fields"],
	stddev?:ValueTypes["store_users_stddev_fields"],
	stddev_pop?:ValueTypes["store_users_stddev_pop_fields"],
	stddev_samp?:ValueTypes["store_users_stddev_samp_fields"],
	sum?:ValueTypes["store_users_sum_fields"],
	var_pop?:ValueTypes["store_users_var_pop_fields"],
	var_samp?:ValueTypes["store_users_var_samp_fields"],
	variance?:ValueTypes["store_users_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["store_users_avg_fields"]: AliasType<{
	ad_share?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	role_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "store.users". All fields are combined with a logical 'AND'. */
["store_users_bool_exp"]: {
	_and?:ValueTypes["store_users_bool_exp"][],
	_not?:ValueTypes["store_users_bool_exp"],
	_or?:ValueTypes["store_users_bool_exp"][],
	about?:ValueTypes["String_comparison_exp"],
	ad_share?:ValueTypes["Int_comparison_exp"],
	avatar?:ValueTypes["String_comparison_exp"],
	banner?:ValueTypes["String_comparison_exp"],
	birth_date?:ValueTypes["date_comparison_exp"],
	dark_mode?:ValueTypes["smallint_comparison_exp"],
	date_create?:ValueTypes["timestamp_comparison_exp"],
	email?:ValueTypes["String_comparison_exp"],
	first_name?:ValueTypes["String_comparison_exp"],
	gender?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_account_active?:ValueTypes["smallint_comparison_exp"],
	last_login?:ValueTypes["timestamp_comparison_exp"],
	last_name?:ValueTypes["String_comparison_exp"],
	nickname?:ValueTypes["String_comparison_exp"],
	password?:ValueTypes["String_comparison_exp"],
	remember_code?:ValueTypes["String_comparison_exp"],
	role_id?:ValueTypes["Int_comparison_exp"],
	username?:ValueTypes["String_comparison_exp"],
	vmuser?:ValueTypes["Boolean_comparison_exp"]
};
	/** input type for incrementing numeric columns in table "store.users" */
["store_users_inc_input"]: {
	ad_share?:number,
	dark_mode?:ValueTypes["smallint"],
	id?:number,
	is_account_active?:ValueTypes["smallint"],
	role_id?:number
};
	/** input type for inserting data into table "store.users" */
["store_users_insert_input"]: {
	about?:string,
	ad_share?:number,
	avatar?:string,
	banner?:string,
	birth_date?:ValueTypes["date"],
	dark_mode?:ValueTypes["smallint"],
	date_create?:ValueTypes["timestamp"],
	email?:string,
	first_name?:string,
	gender?:string,
	id?:number,
	is_account_active?:ValueTypes["smallint"],
	last_login?:ValueTypes["timestamp"],
	last_name?:string,
	nickname?:string,
	password?:string,
	remember_code?:string,
	role_id?:number,
	username?:string,
	vmuser?:boolean
};
	/** aggregate max on columns */
["store_users_max_fields"]: AliasType<{
	about?:true,
	ad_share?:true,
	avatar?:true,
	banner?:true,
	birth_date?:true,
	dark_mode?:true,
	date_create?:true,
	email?:true,
	first_name?:true,
	gender?:true,
	id?:true,
	is_account_active?:true,
	last_login?:true,
	last_name?:true,
	nickname?:true,
	password?:true,
	remember_code?:true,
	role_id?:true,
	username?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["store_users_min_fields"]: AliasType<{
	about?:true,
	ad_share?:true,
	avatar?:true,
	banner?:true,
	birth_date?:true,
	dark_mode?:true,
	date_create?:true,
	email?:true,
	first_name?:true,
	gender?:true,
	id?:true,
	is_account_active?:true,
	last_login?:true,
	last_name?:true,
	nickname?:true,
	password?:true,
	remember_code?:true,
	role_id?:true,
	username?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "store.users" */
["store_users_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["store_users"],
		__typename?: true
}>;
	/** Ordering options when selecting data from "store.users". */
["store_users_order_by"]: {
	about?:ValueTypes["order_by"],
	ad_share?:ValueTypes["order_by"],
	avatar?:ValueTypes["order_by"],
	banner?:ValueTypes["order_by"],
	birth_date?:ValueTypes["order_by"],
	dark_mode?:ValueTypes["order_by"],
	date_create?:ValueTypes["order_by"],
	email?:ValueTypes["order_by"],
	first_name?:ValueTypes["order_by"],
	gender?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_account_active?:ValueTypes["order_by"],
	last_login?:ValueTypes["order_by"],
	last_name?:ValueTypes["order_by"],
	nickname?:ValueTypes["order_by"],
	password?:ValueTypes["order_by"],
	remember_code?:ValueTypes["order_by"],
	role_id?:ValueTypes["order_by"],
	username?:ValueTypes["order_by"],
	vmuser?:ValueTypes["order_by"]
};
	/** select columns of table "store.users" */
["store_users_select_column"]:store_users_select_column;
	/** input type for updating data in table "store.users" */
["store_users_set_input"]: {
	about?:string,
	ad_share?:number,
	avatar?:string,
	banner?:string,
	birth_date?:ValueTypes["date"],
	dark_mode?:ValueTypes["smallint"],
	date_create?:ValueTypes["timestamp"],
	email?:string,
	first_name?:string,
	gender?:string,
	id?:number,
	is_account_active?:ValueTypes["smallint"],
	last_login?:ValueTypes["timestamp"],
	last_name?:string,
	nickname?:string,
	password?:string,
	remember_code?:string,
	role_id?:number,
	username?:string,
	vmuser?:boolean
};
	/** aggregate stddev on columns */
["store_users_stddev_fields"]: AliasType<{
	ad_share?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["store_users_stddev_pop_fields"]: AliasType<{
	ad_share?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["store_users_stddev_samp_fields"]: AliasType<{
	ad_share?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	role_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "store_users" */
["store_users_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["store_users_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["store_users_stream_cursor_value_input"]: {
	about?:string,
	ad_share?:number,
	avatar?:string,
	banner?:string,
	birth_date?:ValueTypes["date"],
	dark_mode?:ValueTypes["smallint"],
	date_create?:ValueTypes["timestamp"],
	email?:string,
	first_name?:string,
	gender?:string,
	id?:number,
	is_account_active?:ValueTypes["smallint"],
	last_login?:ValueTypes["timestamp"],
	last_name?:string,
	nickname?:string,
	password?:string,
	remember_code?:string,
	role_id?:number,
	username?:string,
	vmuser?:boolean
};
	/** aggregate sum on columns */
["store_users_sum_fields"]: AliasType<{
	ad_share?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	role_id?:true,
		__typename?: true
}>;
	["store_users_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["store_users_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["store_users_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["store_users_bool_exp"]
};
	/** aggregate var_pop on columns */
["store_users_var_pop_fields"]: AliasType<{
	ad_share?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["store_users_var_samp_fields"]: AliasType<{
	ad_share?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["store_users_variance_fields"]: AliasType<{
	ad_share?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	role_id?:true,
		__typename?: true
}>;
	["subscription_root"]: AliasType<{
awsdms_validation_failures_v1?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["awsdms_validation_failures_v1_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["awsdms_validation_failures_v1_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["awsdms_validation_failures_v1_bool_exp"]},ValueTypes["awsdms_validation_failures_v1"]],
awsdms_validation_failures_v1_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["awsdms_validation_failures_v1_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["awsdms_validation_failures_v1_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["awsdms_validation_failures_v1_bool_exp"]},ValueTypes["awsdms_validation_failures_v1_aggregate"]],
awsdms_validation_failures_v1_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["awsdms_validation_failures_v1_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["awsdms_validation_failures_v1_bool_exp"]},ValueTypes["awsdms_validation_failures_v1"]],
coinsystem_balances?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_balances_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_balances_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_balances_bool_exp"]},ValueTypes["coinsystem_balances"]],
coinsystem_balances_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_balances_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_balances_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_balances_bool_exp"]},ValueTypes["coinsystem_balances_aggregate"]],
coinsystem_balances_by_pk?: [{	id:number},ValueTypes["coinsystem_balances"]],
coinsystem_balances_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["coinsystem_balances_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_balances_bool_exp"]},ValueTypes["coinsystem_balances"]],
coinsystem_bonus_coins?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_bonus_coins_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_bonus_coins_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_bonus_coins_bool_exp"]},ValueTypes["coinsystem_bonus_coins"]],
coinsystem_bonus_coins_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_bonus_coins_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_bonus_coins_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_bonus_coins_bool_exp"]},ValueTypes["coinsystem_bonus_coins_aggregate"]],
coinsystem_bonus_coins_by_pk?: [{	id:number},ValueTypes["coinsystem_bonus_coins"]],
coinsystem_bonus_coins_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["coinsystem_bonus_coins_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_bonus_coins_bool_exp"]},ValueTypes["coinsystem_bonus_coins"]],
coinsystem_coins?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_coins_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_coins_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_coins_bool_exp"]},ValueTypes["coinsystem_coins"]],
coinsystem_coins_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_coins_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_coins_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_coins_bool_exp"]},ValueTypes["coinsystem_coins_aggregate"]],
coinsystem_coins_by_pk?: [{	id:number},ValueTypes["coinsystem_coins"]],
coinsystem_coins_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["coinsystem_coins_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_coins_bool_exp"]},ValueTypes["coinsystem_coins"]],
coinsystem_exchange_rates?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_exchange_rates_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_exchange_rates_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_exchange_rates_bool_exp"]},ValueTypes["coinsystem_exchange_rates"]],
coinsystem_exchange_rates_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_exchange_rates_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_exchange_rates_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_exchange_rates_bool_exp"]},ValueTypes["coinsystem_exchange_rates_aggregate"]],
coinsystem_exchange_rates_by_pk?: [{	id:number},ValueTypes["coinsystem_exchange_rates"]],
coinsystem_exchange_rates_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["coinsystem_exchange_rates_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_exchange_rates_bool_exp"]},ValueTypes["coinsystem_exchange_rates"]],
coinsystem_logs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_logs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_logs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_logs_bool_exp"]},ValueTypes["coinsystem_logs"]],
coinsystem_logs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_logs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_logs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_logs_bool_exp"]},ValueTypes["coinsystem_logs_aggregate"]],
coinsystem_logs_by_pk?: [{	id:number},ValueTypes["coinsystem_logs"]],
coinsystem_logs_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["coinsystem_logs_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_logs_bool_exp"]},ValueTypes["coinsystem_logs"]],
coinsystem_transactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_transactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_transactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_transactions_bool_exp"]},ValueTypes["coinsystem_transactions"]],
coinsystem_transactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_transactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_transactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_transactions_bool_exp"]},ValueTypes["coinsystem_transactions_aggregate"]],
coinsystem_transactions_by_pk?: [{	id:number},ValueTypes["coinsystem_transactions"]],
coinsystem_transactions_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["coinsystem_transactions_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_transactions_bool_exp"]},ValueTypes["coinsystem_transactions"]],
coinsystem_wallets?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_wallets_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_wallets_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_wallets_bool_exp"]},ValueTypes["coinsystem_wallets"]],
coinsystem_wallets_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["coinsystem_wallets_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["coinsystem_wallets_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_wallets_bool_exp"]},ValueTypes["coinsystem_wallets_aggregate"]],
coinsystem_wallets_by_pk?: [{	id:number},ValueTypes["coinsystem_wallets"]],
coinsystem_wallets_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["coinsystem_wallets_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["coinsystem_wallets_bool_exp"]},ValueTypes["coinsystem_wallets"]],
mysql_user?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["mysql_user_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["mysql_user_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["mysql_user_bool_exp"]},ValueTypes["mysql_user"]],
mysql_user_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["mysql_user_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["mysql_user_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["mysql_user_bool_exp"]},ValueTypes["mysql_user_aggregate"]],
mysql_user_by_pk?: [{	host:string,	user:string},ValueTypes["mysql_user"]],
mysql_user_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["mysql_user_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["mysql_user_bool_exp"]},ValueTypes["mysql_user"]],
store_payments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["store_payments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["store_payments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["store_payments_bool_exp"]},ValueTypes["store_payments"]],
store_payments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["store_payments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["store_payments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["store_payments_bool_exp"]},ValueTypes["store_payments_aggregate"]],
store_payments_by_pk?: [{	id:ValueTypes["uuid"],	purchase_id:string},ValueTypes["store_payments"]],
store_payments_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["store_payments_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["store_payments_bool_exp"]},ValueTypes["store_payments"]],
store_products?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["store_products_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["store_products_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["store_products_bool_exp"]},ValueTypes["store_products"]],
store_products_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["store_products_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["store_products_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["store_products_bool_exp"]},ValueTypes["store_products_aggregate"]],
store_products_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["store_products"]],
store_products_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["store_products_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["store_products_bool_exp"]},ValueTypes["store_products"]],
store_purchases?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["store_purchases_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["store_purchases_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["store_purchases_bool_exp"]},ValueTypes["store_purchases"]],
store_purchases_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["store_purchases_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["store_purchases_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["store_purchases_bool_exp"]},ValueTypes["store_purchases_aggregate"]],
store_purchases_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["store_purchases"]],
store_purchases_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["store_purchases_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["store_purchases_bool_exp"]},ValueTypes["store_purchases"]],
store_users?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["store_users_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["store_users_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["store_users_bool_exp"]},ValueTypes["store_users"]],
store_users_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["store_users_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["store_users_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["store_users_bool_exp"]},ValueTypes["store_users_aggregate"]],
store_users_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["store_users_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["store_users_bool_exp"]},ValueTypes["store_users"]],
voyce_admin_roles?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_admin_roles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_admin_roles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_admin_roles_bool_exp"]},ValueTypes["voyce_admin_roles"]],
voyce_admin_roles_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_admin_roles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_admin_roles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_admin_roles_bool_exp"]},ValueTypes["voyce_admin_roles_aggregate"]],
voyce_admin_roles_by_pk?: [{	id:number},ValueTypes["voyce_admin_roles"]],
voyce_admin_roles_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_admin_roles_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_admin_roles_bool_exp"]},ValueTypes["voyce_admin_roles"]],
voyce_app_banners?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_app_banners_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_app_banners_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_app_banners_bool_exp"]},ValueTypes["voyce_app_banners"]],
voyce_app_banners_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_app_banners_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_app_banners_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_app_banners_bool_exp"]},ValueTypes["voyce_app_banners_aggregate"]],
voyce_app_banners_by_pk?: [{	id:number},ValueTypes["voyce_app_banners"]],
voyce_app_banners_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_app_banners_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_app_banners_bool_exp"]},ValueTypes["voyce_app_banners"]],
voyce_app_components?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_app_components_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_app_components_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_app_components_bool_exp"]},ValueTypes["voyce_app_components"]],
voyce_app_components_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_app_components_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_app_components_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_app_components_bool_exp"]},ValueTypes["voyce_app_components_aggregate"]],
voyce_app_components_by_pk?: [{	id:number},ValueTypes["voyce_app_components"]],
voyce_app_components_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_app_components_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_app_components_bool_exp"]},ValueTypes["voyce_app_components"]],
voyce_audit_trail?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_audit_trail_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_audit_trail_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_audit_trail_bool_exp"]},ValueTypes["voyce_audit_trail"]],
voyce_audit_trail_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_audit_trail_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_audit_trail_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_audit_trail_bool_exp"]},ValueTypes["voyce_audit_trail_aggregate"]],
voyce_audit_trail_by_pk?: [{	id:number},ValueTypes["voyce_audit_trail"]],
voyce_audit_trail_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_audit_trail_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_audit_trail_bool_exp"]},ValueTypes["voyce_audit_trail"]],
voyce_author_profiles?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_author_profiles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_author_profiles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_author_profiles_bool_exp"]},ValueTypes["voyce_author_profiles"]],
voyce_author_profiles_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_author_profiles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_author_profiles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_author_profiles_bool_exp"]},ValueTypes["voyce_author_profiles_aggregate"]],
voyce_author_profiles_by_pk?: [{	id:number},ValueTypes["voyce_author_profiles"]],
voyce_author_profiles_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_author_profiles_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_author_profiles_bool_exp"]},ValueTypes["voyce_author_profiles"]],
voyce_balances?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_balances_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_balances_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_balances_bool_exp"]},ValueTypes["voyce_balances"]],
voyce_balances_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_balances_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_balances_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_balances_bool_exp"]},ValueTypes["voyce_balances_aggregate"]],
voyce_balances_by_pk?: [{	id:number},ValueTypes["voyce_balances"]],
voyce_balances_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_balances_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_balances_bool_exp"]},ValueTypes["voyce_balances"]],
voyce_banners?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_banners_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_banners_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_banners_bool_exp"]},ValueTypes["voyce_banners"]],
voyce_banners_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_banners_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_banners_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_banners_bool_exp"]},ValueTypes["voyce_banners_aggregate"]],
voyce_banners_by_pk?: [{	id:number},ValueTypes["voyce_banners"]],
voyce_banners_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_banners_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_banners_bool_exp"]},ValueTypes["voyce_banners"]],
voyce_chapter_assets?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_assets_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_assets_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_assets_bool_exp"]},ValueTypes["voyce_chapter_assets"]],
voyce_chapter_assets_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_assets_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_assets_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_assets_bool_exp"]},ValueTypes["voyce_chapter_assets_aggregate"]],
voyce_chapter_assets_by_pk?: [{	id:number},ValueTypes["voyce_chapter_assets"]],
voyce_chapter_assets_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_assets_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_assets_bool_exp"]},ValueTypes["voyce_chapter_assets"]],
voyce_chapter_bookmarks?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_bookmarks_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_bookmarks_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_bookmarks_bool_exp"]},ValueTypes["voyce_chapter_bookmarks"]],
voyce_chapter_bookmarks_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_bookmarks_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_bookmarks_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_bookmarks_bool_exp"]},ValueTypes["voyce_chapter_bookmarks_aggregate"]],
voyce_chapter_bookmarks_by_pk?: [{	chapter_id:number,	user_id:number},ValueTypes["voyce_chapter_bookmarks"]],
voyce_chapter_bookmarks_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_bookmarks_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_bookmarks_bool_exp"]},ValueTypes["voyce_chapter_bookmarks"]],
voyce_chapter_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_bool_exp"]},ValueTypes["voyce_chapter_comments"]],
voyce_chapter_comments_07252024?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_07252024_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_07252024_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_07252024_bool_exp"]},ValueTypes["voyce_chapter_comments_07252024"]],
voyce_chapter_comments_07252024_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_07252024_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_07252024_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_07252024_bool_exp"]},ValueTypes["voyce_chapter_comments_07252024_aggregate"]],
voyce_chapter_comments_07252024_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_comments_07252024_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_07252024_bool_exp"]},ValueTypes["voyce_chapter_comments_07252024"]],
voyce_chapter_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_bool_exp"]},ValueTypes["voyce_chapter_comments_aggregate"]],
voyce_chapter_comments_by_pk?: [{	id:number},ValueTypes["voyce_chapter_comments"]],
voyce_chapter_comments_reaction?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reaction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reaction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reaction_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction"]],
voyce_chapter_comments_reaction_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reaction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reaction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reaction_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction_aggregate"]],
voyce_chapter_comments_reaction_by_pk?: [{	id:number},ValueTypes["voyce_chapter_comments_reaction"]],
voyce_chapter_comments_reaction_count?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reaction_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reaction_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reaction_count_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction_count"]],
voyce_chapter_comments_reaction_count_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reaction_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reaction_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reaction_count_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction_count_aggregate"]],
voyce_chapter_comments_reaction_count_by_pk?: [{	id:number},ValueTypes["voyce_chapter_comments_reaction_count"]],
voyce_chapter_comments_reaction_count_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_comments_reaction_count_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reaction_count_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction_count"]],
voyce_chapter_comments_reaction_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_comments_reaction_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reaction_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction"]],
voyce_chapter_comments_reports?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reports_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reports_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reports_bool_exp"]},ValueTypes["voyce_chapter_comments_reports"]],
voyce_chapter_comments_reports_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reports_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reports_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reports_bool_exp"]},ValueTypes["voyce_chapter_comments_reports_aggregate"]],
voyce_chapter_comments_reports_by_pk?: [{	id:number},ValueTypes["voyce_chapter_comments_reports"]],
voyce_chapter_comments_reports_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_comments_reports_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reports_bool_exp"]},ValueTypes["voyce_chapter_comments_reports"]],
voyce_chapter_comments_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_comments_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_bool_exp"]},ValueTypes["voyce_chapter_comments"]],
voyce_chapter_contents?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_contents_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_contents_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_contents_bool_exp"]},ValueTypes["voyce_chapter_contents"]],
voyce_chapter_contents_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_contents_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_contents_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_contents_bool_exp"]},ValueTypes["voyce_chapter_contents_aggregate"]],
voyce_chapter_contents_by_pk?: [{	id:number},ValueTypes["voyce_chapter_contents"]],
voyce_chapter_contents_old?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_contents_old_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_contents_old_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_contents_old_bool_exp"]},ValueTypes["voyce_chapter_contents_old"]],
voyce_chapter_contents_old_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_contents_old_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_contents_old_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_contents_old_bool_exp"]},ValueTypes["voyce_chapter_contents_old_aggregate"]],
voyce_chapter_contents_old_by_pk?: [{	id:number},ValueTypes["voyce_chapter_contents_old"]],
voyce_chapter_contents_old_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_contents_old_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_contents_old_bool_exp"]},ValueTypes["voyce_chapter_contents_old"]],
voyce_chapter_contents_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_contents_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_contents_bool_exp"]},ValueTypes["voyce_chapter_contents"]],
voyce_chapter_effects?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_effects_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_effects_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_effects_bool_exp"]},ValueTypes["voyce_chapter_effects"]],
voyce_chapter_effects_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_effects_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_effects_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_effects_bool_exp"]},ValueTypes["voyce_chapter_effects_aggregate"]],
voyce_chapter_effects_by_pk?: [{	id:number},ValueTypes["voyce_chapter_effects"]],
voyce_chapter_effects_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_effects_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_effects_bool_exp"]},ValueTypes["voyce_chapter_effects"]],
voyce_chapter_images?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_images_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_images_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_images_bool_exp"]},ValueTypes["voyce_chapter_images"]],
voyce_chapter_images_07252024?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_images_07252024_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_images_07252024_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_images_07252024_bool_exp"]},ValueTypes["voyce_chapter_images_07252024"]],
voyce_chapter_images_07252024_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_images_07252024_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_images_07252024_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_images_07252024_bool_exp"]},ValueTypes["voyce_chapter_images_07252024_aggregate"]],
voyce_chapter_images_07252024_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_images_07252024_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_images_07252024_bool_exp"]},ValueTypes["voyce_chapter_images_07252024"]],
voyce_chapter_images_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_images_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_images_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_images_bool_exp"]},ValueTypes["voyce_chapter_images_aggregate"]],
voyce_chapter_images_by_pk?: [{	id:number},ValueTypes["voyce_chapter_images"]],
voyce_chapter_images_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_images_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_images_bool_exp"]},ValueTypes["voyce_chapter_images"]],
voyce_chapter_likes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_likes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_likes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_likes_bool_exp"]},ValueTypes["voyce_chapter_likes"]],
voyce_chapter_likes_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_likes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_likes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_likes_bool_exp"]},ValueTypes["voyce_chapter_likes_aggregate"]],
voyce_chapter_likes_by_pk?: [{	chapter_id:number,	created_at:ValueTypes["timestamptz"]},ValueTypes["voyce_chapter_likes"]],
voyce_chapter_likes_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_likes_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_likes_bool_exp"]},ValueTypes["voyce_chapter_likes"]],
voyce_chapter_locks?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_locks_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_locks_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_locks_bool_exp"]},ValueTypes["voyce_chapter_locks"]],
voyce_chapter_locks_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_locks_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_locks_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_locks_bool_exp"]},ValueTypes["voyce_chapter_locks_aggregate"]],
voyce_chapter_locks_by_pk?: [{	id:number},ValueTypes["voyce_chapter_locks"]],
voyce_chapter_locks_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_locks_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_locks_bool_exp"]},ValueTypes["voyce_chapter_locks"]],
voyce_chapter_panel_views?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_panel_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_panel_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_panel_views_bool_exp"]},ValueTypes["voyce_chapter_panel_views"]],
voyce_chapter_panel_views_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_panel_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_panel_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_panel_views_bool_exp"]},ValueTypes["voyce_chapter_panel_views_aggregate"]],
voyce_chapter_panel_views_by_pk?: [{	id:number},ValueTypes["voyce_chapter_panel_views"]],
voyce_chapter_panel_views_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_panel_views_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_panel_views_bool_exp"]},ValueTypes["voyce_chapter_panel_views"]],
voyce_chapter_panels?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_panels_bool_exp"]},ValueTypes["voyce_chapter_panels"]],
voyce_chapter_panels_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_panels_bool_exp"]},ValueTypes["voyce_chapter_panels_aggregate"]],
voyce_chapter_panels_by_pk?: [{	id:number},ValueTypes["voyce_chapter_panels"]],
voyce_chapter_panels_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_panels_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_panels_bool_exp"]},ValueTypes["voyce_chapter_panels"]],
voyce_chapter_publish_schedules?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_publish_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_publish_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_publish_schedules_bool_exp"]},ValueTypes["voyce_chapter_publish_schedules"]],
voyce_chapter_publish_schedules_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_publish_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_publish_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_publish_schedules_bool_exp"]},ValueTypes["voyce_chapter_publish_schedules_aggregate"]],
voyce_chapter_publish_schedules_by_pk?: [{	id:number},ValueTypes["voyce_chapter_publish_schedules"]],
voyce_chapter_publish_schedules_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_publish_schedules_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_publish_schedules_bool_exp"]},ValueTypes["voyce_chapter_publish_schedules"]],
voyce_chapter_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_reactions_bool_exp"]},ValueTypes["voyce_chapter_reactions"]],
voyce_chapter_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_reactions_bool_exp"]},ValueTypes["voyce_chapter_reactions_aggregate"]],
voyce_chapter_reactions_by_pk?: [{	id:number},ValueTypes["voyce_chapter_reactions"]],
voyce_chapter_reactions_count?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_reactions_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_reactions_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_reactions_count_bool_exp"]},ValueTypes["voyce_chapter_reactions_count"]],
voyce_chapter_reactions_count_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_reactions_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_reactions_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_reactions_count_bool_exp"]},ValueTypes["voyce_chapter_reactions_count_aggregate"]],
voyce_chapter_reactions_count_by_pk?: [{	id:number},ValueTypes["voyce_chapter_reactions_count"]],
voyce_chapter_reactions_count_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_reactions_count_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_reactions_count_bool_exp"]},ValueTypes["voyce_chapter_reactions_count"]],
voyce_chapter_reactions_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_reactions_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_reactions_bool_exp"]},ValueTypes["voyce_chapter_reactions"]],
voyce_chapter_view_logs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_view_logs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_view_logs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_view_logs_bool_exp"]},ValueTypes["voyce_chapter_view_logs"]],
voyce_chapter_view_logs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_view_logs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_view_logs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_view_logs_bool_exp"]},ValueTypes["voyce_chapter_view_logs_aggregate"]],
voyce_chapter_view_logs_by_pk?: [{	id:number},ValueTypes["voyce_chapter_view_logs"]],
voyce_chapter_view_logs_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_view_logs_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_view_logs_bool_exp"]},ValueTypes["voyce_chapter_view_logs"]],
voyce_chapter_views?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_views_bool_exp"]},ValueTypes["voyce_chapter_views"]],
voyce_chapter_views_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_views_bool_exp"]},ValueTypes["voyce_chapter_views_aggregate"]],
voyce_chapter_views_by_pk?: [{	id:number},ValueTypes["voyce_chapter_views"]],
voyce_chapter_views_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapter_views_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_views_bool_exp"]},ValueTypes["voyce_chapter_views"]],
voyce_chapters?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapters_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapters_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapters_bool_exp"]},ValueTypes["voyce_chapters"]],
voyce_chapters_07252024?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapters_07252024_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapters_07252024_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapters_07252024_bool_exp"]},ValueTypes["voyce_chapters_07252024"]],
voyce_chapters_07252024_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapters_07252024_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapters_07252024_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapters_07252024_bool_exp"]},ValueTypes["voyce_chapters_07252024_aggregate"]],
voyce_chapters_07252024_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapters_07252024_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapters_07252024_bool_exp"]},ValueTypes["voyce_chapters_07252024"]],
voyce_chapters_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapters_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapters_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapters_bool_exp"]},ValueTypes["voyce_chapters_aggregate"]],
voyce_chapters_by_pk?: [{	id:number},ValueTypes["voyce_chapters"]],
voyce_chapters_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_chapters_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapters_bool_exp"]},ValueTypes["voyce_chapters"]],
voyce_collaborator_roles?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_collaborator_roles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_collaborator_roles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_collaborator_roles_bool_exp"]},ValueTypes["voyce_collaborator_roles"]],
voyce_collaborator_roles_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_collaborator_roles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_collaborator_roles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_collaborator_roles_bool_exp"]},ValueTypes["voyce_collaborator_roles_aggregate"]],
voyce_collaborator_roles_by_pk?: [{	id:number},ValueTypes["voyce_collaborator_roles"]],
voyce_collaborator_roles_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_collaborator_roles_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_collaborator_roles_bool_exp"]},ValueTypes["voyce_collaborator_roles"]],
voyce_comic_types?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_comic_types_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_comic_types_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_comic_types_bool_exp"]},ValueTypes["voyce_comic_types"]],
voyce_comic_types_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_comic_types_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_comic_types_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_comic_types_bool_exp"]},ValueTypes["voyce_comic_types_aggregate"]],
voyce_comic_types_by_pk?: [{	id:number},ValueTypes["voyce_comic_types"]],
voyce_comic_types_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_comic_types_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_comic_types_bool_exp"]},ValueTypes["voyce_comic_types"]],
voyce_comments_top_mat?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_comments_top_mat_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_comments_top_mat_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_comments_top_mat_bool_exp"]},ValueTypes["voyce_comments_top_mat"]],
voyce_comments_top_mat_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_comments_top_mat_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_comments_top_mat_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_comments_top_mat_bool_exp"]},ValueTypes["voyce_comments_top_mat_aggregate"]],
voyce_comments_top_mat_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_comments_top_mat_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_comments_top_mat_bool_exp"]},ValueTypes["voyce_comments_top_mat"]],
voyce_community_blurbs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_community_blurbs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_community_blurbs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_community_blurbs_bool_exp"]},ValueTypes["voyce_community_blurbs"]],
voyce_community_blurbs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_community_blurbs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_community_blurbs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_community_blurbs_bool_exp"]},ValueTypes["voyce_community_blurbs_aggregate"]],
voyce_community_blurbs_by_pk?: [{	id:number},ValueTypes["voyce_community_blurbs"]],
voyce_community_blurbs_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_community_blurbs_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_community_blurbs_bool_exp"]},ValueTypes["voyce_community_blurbs"]],
voyce_contact_us?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_contact_us_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_contact_us_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_contact_us_bool_exp"]},ValueTypes["voyce_contact_us"]],
voyce_contact_us_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_contact_us_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_contact_us_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_contact_us_bool_exp"]},ValueTypes["voyce_contact_us_aggregate"]],
voyce_contact_us_by_pk?: [{	id:number},ValueTypes["voyce_contact_us"]],
voyce_contact_us_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_contact_us_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_contact_us_bool_exp"]},ValueTypes["voyce_contact_us"]],
voyce_content_report?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_content_report_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_content_report_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_content_report_bool_exp"]},ValueTypes["voyce_content_report"]],
voyce_content_report_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_content_report_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_content_report_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_content_report_bool_exp"]},ValueTypes["voyce_content_report_aggregate"]],
voyce_content_report_by_pk?: [{	id:number},ValueTypes["voyce_content_report"]],
voyce_content_report_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_content_report_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_content_report_bool_exp"]},ValueTypes["voyce_content_report"]],
voyce_contest_data?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_contest_data_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_contest_data_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_contest_data_bool_exp"]},ValueTypes["voyce_contest_data"]],
voyce_contest_data_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_contest_data_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_contest_data_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_contest_data_bool_exp"]},ValueTypes["voyce_contest_data_aggregate"]],
voyce_contest_data_by_pk?: [{	id:number},ValueTypes["voyce_contest_data"]],
voyce_contest_data_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_contest_data_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_contest_data_bool_exp"]},ValueTypes["voyce_contest_data"]],
voyce_countries?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_countries_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_countries_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_countries_bool_exp"]},ValueTypes["voyce_countries"]],
voyce_countries_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_countries_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_countries_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_countries_bool_exp"]},ValueTypes["voyce_countries_aggregate"]],
voyce_countries_by_pk?: [{	id:number},ValueTypes["voyce_countries"]],
voyce_countries_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_countries_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_countries_bool_exp"]},ValueTypes["voyce_countries"]],
voyce_csat_ratings?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_csat_ratings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_csat_ratings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_csat_ratings_bool_exp"]},ValueTypes["voyce_csat_ratings"]],
voyce_csat_ratings_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_csat_ratings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_csat_ratings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_csat_ratings_bool_exp"]},ValueTypes["voyce_csat_ratings_aggregate"]],
voyce_csat_ratings_by_pk?: [{	id:number},ValueTypes["voyce_csat_ratings"]],
voyce_csat_ratings_data?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_csat_ratings_data_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_csat_ratings_data_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_csat_ratings_data_bool_exp"]},ValueTypes["voyce_csat_ratings_data"]],
voyce_csat_ratings_data_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_csat_ratings_data_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_csat_ratings_data_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_csat_ratings_data_bool_exp"]},ValueTypes["voyce_csat_ratings_data_aggregate"]],
voyce_csat_ratings_data_by_pk?: [{	id:number},ValueTypes["voyce_csat_ratings_data"]],
voyce_csat_ratings_data_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_csat_ratings_data_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_csat_ratings_data_bool_exp"]},ValueTypes["voyce_csat_ratings_data"]],
voyce_csat_ratings_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_csat_ratings_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_csat_ratings_bool_exp"]},ValueTypes["voyce_csat_ratings"]],
voyce_education_types?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_education_types_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_education_types_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_education_types_bool_exp"]},ValueTypes["voyce_education_types"]],
voyce_education_types_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_education_types_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_education_types_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_education_types_bool_exp"]},ValueTypes["voyce_education_types_aggregate"]],
voyce_education_types_by_pk?: [{	id:number},ValueTypes["voyce_education_types"]],
voyce_education_types_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_education_types_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_education_types_bool_exp"]},ValueTypes["voyce_education_types"]],
voyce_erp_login_roles?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_erp_login_roles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_erp_login_roles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_erp_login_roles_bool_exp"]},ValueTypes["voyce_erp_login_roles"]],
voyce_erp_login_roles_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_erp_login_roles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_erp_login_roles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_erp_login_roles_bool_exp"]},ValueTypes["voyce_erp_login_roles_aggregate"]],
voyce_erp_login_roles_by_pk?: [{	id:number},ValueTypes["voyce_erp_login_roles"]],
voyce_erp_login_roles_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_erp_login_roles_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_erp_login_roles_bool_exp"]},ValueTypes["voyce_erp_login_roles"]],
voyce_etl_series_views_logs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_etl_series_views_logs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_etl_series_views_logs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_etl_series_views_logs_bool_exp"]},ValueTypes["voyce_etl_series_views_logs"]],
voyce_etl_series_views_logs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_etl_series_views_logs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_etl_series_views_logs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_etl_series_views_logs_bool_exp"]},ValueTypes["voyce_etl_series_views_logs_aggregate"]],
voyce_etl_series_views_logs_by_pk?: [{	id:number},ValueTypes["voyce_etl_series_views_logs"]],
voyce_etl_series_views_logs_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_etl_series_views_logs_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_etl_series_views_logs_bool_exp"]},ValueTypes["voyce_etl_series_views_logs"]],
voyce_event_schedules?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_event_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_event_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_event_schedules_bool_exp"]},ValueTypes["voyce_event_schedules"]],
voyce_event_schedules_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_event_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_event_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_event_schedules_bool_exp"]},ValueTypes["voyce_event_schedules_aggregate"]],
voyce_event_schedules_by_pk?: [{	id:number},ValueTypes["voyce_event_schedules"]],
voyce_event_schedules_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_event_schedules_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_event_schedules_bool_exp"]},ValueTypes["voyce_event_schedules"]],
voyce_genres?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_genres_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_genres_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_genres_bool_exp"]},ValueTypes["voyce_genres"]],
voyce_genres_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_genres_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_genres_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_genres_bool_exp"]},ValueTypes["voyce_genres_aggregate"]],
voyce_genres_by_pk?: [{	id:number},ValueTypes["voyce_genres"]],
voyce_genres_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_genres_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_genres_bool_exp"]},ValueTypes["voyce_genres"]],
voyce_guest_gtags?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_guest_gtags_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_guest_gtags_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_guest_gtags_bool_exp"]},ValueTypes["voyce_guest_gtags"]],
voyce_guest_gtags_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_guest_gtags_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_guest_gtags_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_guest_gtags_bool_exp"]},ValueTypes["voyce_guest_gtags_aggregate"]],
voyce_guest_gtags_by_pk?: [{	id:number},ValueTypes["voyce_guest_gtags"]],
voyce_guest_gtags_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_guest_gtags_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_guest_gtags_bool_exp"]},ValueTypes["voyce_guest_gtags"]],
voyce_metabases?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_metabases_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_metabases_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_metabases_bool_exp"]},ValueTypes["voyce_metabases"]],
voyce_metabases_access?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_metabases_access_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_metabases_access_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_metabases_access_bool_exp"]},ValueTypes["voyce_metabases_access"]],
voyce_metabases_access_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_metabases_access_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_metabases_access_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_metabases_access_bool_exp"]},ValueTypes["voyce_metabases_access_aggregate"]],
voyce_metabases_access_by_pk?: [{	id:number},ValueTypes["voyce_metabases_access"]],
voyce_metabases_access_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_metabases_access_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_metabases_access_bool_exp"]},ValueTypes["voyce_metabases_access"]],
voyce_metabases_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_metabases_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_metabases_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_metabases_bool_exp"]},ValueTypes["voyce_metabases_aggregate"]],
voyce_metabases_by_pk?: [{	id:number},ValueTypes["voyce_metabases"]],
voyce_metabases_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_metabases_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_metabases_bool_exp"]},ValueTypes["voyce_metabases"]],
voyce_newsletter?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_newsletter_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_newsletter_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_newsletter_bool_exp"]},ValueTypes["voyce_newsletter"]],
voyce_newsletter_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_newsletter_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_newsletter_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_newsletter_bool_exp"]},ValueTypes["voyce_newsletter_aggregate"]],
voyce_newsletter_by_pk?: [{	email:string},ValueTypes["voyce_newsletter"]],
voyce_newsletter_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_newsletter_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_newsletter_bool_exp"]},ValueTypes["voyce_newsletter"]],
voyce_notification_series_settings?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_notification_series_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_notification_series_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notification_series_settings_bool_exp"]},ValueTypes["voyce_notification_series_settings"]],
voyce_notification_series_settings_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_notification_series_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_notification_series_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notification_series_settings_bool_exp"]},ValueTypes["voyce_notification_series_settings_aggregate"]],
voyce_notification_series_settings_by_pk?: [{	id:number},ValueTypes["voyce_notification_series_settings"]],
voyce_notification_series_settings_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_notification_series_settings_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notification_series_settings_bool_exp"]},ValueTypes["voyce_notification_series_settings"]],
voyce_notification_settings?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_notification_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_notification_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notification_settings_bool_exp"]},ValueTypes["voyce_notification_settings"]],
voyce_notification_settings_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_notification_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_notification_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notification_settings_bool_exp"]},ValueTypes["voyce_notification_settings_aggregate"]],
voyce_notification_settings_by_pk?: [{	id:number},ValueTypes["voyce_notification_settings"]],
voyce_notification_settings_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_notification_settings_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notification_settings_bool_exp"]},ValueTypes["voyce_notification_settings"]],
voyce_notifications?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_notifications_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_notifications_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notifications_bool_exp"]},ValueTypes["voyce_notifications"]],
voyce_notifications_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_notifications_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_notifications_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notifications_bool_exp"]},ValueTypes["voyce_notifications_aggregate"]],
voyce_notifications_by_pk?: [{	id:number},ValueTypes["voyce_notifications"]],
voyce_notifications_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_notifications_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notifications_bool_exp"]},ValueTypes["voyce_notifications"]],
voyce_originals_feed?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_originals_feed_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_originals_feed_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_originals_feed_bool_exp"]},ValueTypes["voyce_originals_feed"]],
voyce_originals_feed_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_originals_feed_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_originals_feed_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_originals_feed_bool_exp"]},ValueTypes["voyce_originals_feed_aggregate"]],
voyce_originals_feed_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_originals_feed_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_originals_feed_bool_exp"]},ValueTypes["voyce_originals_feed"]],
voyce_page_banners?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_page_banners_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_page_banners_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_page_banners_bool_exp"]},ValueTypes["voyce_page_banners"]],
voyce_page_banners_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_page_banners_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_page_banners_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_page_banners_bool_exp"]},ValueTypes["voyce_page_banners_aggregate"]],
voyce_page_banners_by_pk?: [{	id:number},ValueTypes["voyce_page_banners"]],
voyce_page_banners_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_page_banners_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_page_banners_bool_exp"]},ValueTypes["voyce_page_banners"]],
voyce_page_components?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_page_components_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_page_components_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_page_components_bool_exp"]},ValueTypes["voyce_page_components"]],
voyce_page_components_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_page_components_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_page_components_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_page_components_bool_exp"]},ValueTypes["voyce_page_components_aggregate"]],
voyce_page_components_by_pk?: [{	id:number},ValueTypes["voyce_page_components"]],
voyce_page_components_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_page_components_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_page_components_bool_exp"]},ValueTypes["voyce_page_components"]],
voyce_pages?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_pages_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_pages_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_pages_bool_exp"]},ValueTypes["voyce_pages"]],
voyce_pages_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_pages_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_pages_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_pages_bool_exp"]},ValueTypes["voyce_pages_aggregate"]],
voyce_pages_by_pk?: [{	id:number},ValueTypes["voyce_pages"]],
voyce_pages_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_pages_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_pages_bool_exp"]},ValueTypes["voyce_pages"]],
voyce_panels_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_comments_bool_exp"]},ValueTypes["voyce_panels_comments"]],
voyce_panels_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_comments_bool_exp"]},ValueTypes["voyce_panels_comments_aggregate"]],
voyce_panels_comments_by_pk?: [{	id:number},ValueTypes["voyce_panels_comments"]],
voyce_panels_comments_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_panels_comments_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_comments_bool_exp"]},ValueTypes["voyce_panels_comments"]],
voyce_panels_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_bool_exp"]},ValueTypes["voyce_panels_reactions"]],
voyce_panels_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_bool_exp"]},ValueTypes["voyce_panels_reactions_aggregate"]],
voyce_panels_reactions_by_pk?: [{	id:number},ValueTypes["voyce_panels_reactions"]],
voyce_panels_reactions_count?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_reactions_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_reactions_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_count_bool_exp"]},ValueTypes["voyce_panels_reactions_count"]],
voyce_panels_reactions_count_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_reactions_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_reactions_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_count_bool_exp"]},ValueTypes["voyce_panels_reactions_count_aggregate"]],
voyce_panels_reactions_count_by_pk?: [{	id:number},ValueTypes["voyce_panels_reactions_count"]],
voyce_panels_reactions_count_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_panels_reactions_count_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_count_bool_exp"]},ValueTypes["voyce_panels_reactions_count"]],
voyce_panels_reactions_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_panels_reactions_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_bool_exp"]},ValueTypes["voyce_panels_reactions"]],
voyce_payments_to_creators?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_payments_to_creators_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_payments_to_creators_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_payments_to_creators_bool_exp"]},ValueTypes["voyce_payments_to_creators"]],
voyce_payments_to_creators_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_payments_to_creators_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_payments_to_creators_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_payments_to_creators_bool_exp"]},ValueTypes["voyce_payments_to_creators_aggregate"]],
voyce_payments_to_creators_by_pk?: [{	id:number},ValueTypes["voyce_payments_to_creators"]],
voyce_payments_to_creators_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_payments_to_creators_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_payments_to_creators_bool_exp"]},ValueTypes["voyce_payments_to_creators"]],
voyce_pg_rs_series_views_count?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_pg_rs_series_views_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_pg_rs_series_views_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_pg_rs_series_views_count_bool_exp"]},ValueTypes["voyce_pg_rs_series_views_count"]],
voyce_pg_rs_series_views_count_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_pg_rs_series_views_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_pg_rs_series_views_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_pg_rs_series_views_count_bool_exp"]},ValueTypes["voyce_pg_rs_series_views_count_aggregate"]],
voyce_pg_rs_series_views_count_by_pk?: [{	series_id:number},ValueTypes["voyce_pg_rs_series_views_count"]],
voyce_pg_rs_series_views_count_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_pg_rs_series_views_count_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_pg_rs_series_views_count_bool_exp"]},ValueTypes["voyce_pg_rs_series_views_count"]],
voyce_polls?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_bool_exp"]},ValueTypes["voyce_polls"]],
voyce_polls_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_bool_exp"]},ValueTypes["voyce_polls_aggregate"]],
voyce_polls_answers?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_answers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_answers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_answers_bool_exp"]},ValueTypes["voyce_polls_answers"]],
voyce_polls_answers_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_answers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_answers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_answers_bool_exp"]},ValueTypes["voyce_polls_answers_aggregate"]],
voyce_polls_answers_by_pk?: [{	id:number},ValueTypes["voyce_polls_answers"]],
voyce_polls_answers_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_polls_answers_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_answers_bool_exp"]},ValueTypes["voyce_polls_answers"]],
voyce_polls_by_pk?: [{	id:number},ValueTypes["voyce_polls"]],
voyce_polls_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_comments_bool_exp"]},ValueTypes["voyce_polls_comments"]],
voyce_polls_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_comments_bool_exp"]},ValueTypes["voyce_polls_comments_aggregate"]],
voyce_polls_comments_by_pk?: [{	id:number},ValueTypes["voyce_polls_comments"]],
voyce_polls_comments_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_polls_comments_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_comments_bool_exp"]},ValueTypes["voyce_polls_comments"]],
voyce_polls_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_reactions_bool_exp"]},ValueTypes["voyce_polls_reactions"]],
voyce_polls_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_reactions_bool_exp"]},ValueTypes["voyce_polls_reactions_aggregate"]],
voyce_polls_reactions_by_pk?: [{	id:number},ValueTypes["voyce_polls_reactions"]],
voyce_polls_reactions_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_polls_reactions_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_reactions_bool_exp"]},ValueTypes["voyce_polls_reactions"]],
voyce_polls_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_polls_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_bool_exp"]},ValueTypes["voyce_polls"]],
voyce_polls_votes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_votes_bool_exp"]},ValueTypes["voyce_polls_votes"]],
voyce_polls_votes_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_votes_bool_exp"]},ValueTypes["voyce_polls_votes_aggregate"]],
voyce_polls_votes_by_pk?: [{	id:number},ValueTypes["voyce_polls_votes"]],
voyce_polls_votes_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_polls_votes_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_votes_bool_exp"]},ValueTypes["voyce_polls_votes"]],
voyce_popup_components?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_popup_components_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_popup_components_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_popup_components_bool_exp"]},ValueTypes["voyce_popup_components"]],
voyce_popup_components_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_popup_components_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_popup_components_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_popup_components_bool_exp"]},ValueTypes["voyce_popup_components_aggregate"]],
voyce_popup_components_by_pk?: [{	id:number},ValueTypes["voyce_popup_components"]],
voyce_popup_components_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_popup_components_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_popup_components_bool_exp"]},ValueTypes["voyce_popup_components"]],
voyce_popup_emails?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_popup_emails_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_popup_emails_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_popup_emails_bool_exp"]},ValueTypes["voyce_popup_emails"]],
voyce_popup_emails_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_popup_emails_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_popup_emails_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_popup_emails_bool_exp"]},ValueTypes["voyce_popup_emails_aggregate"]],
voyce_popup_emails_by_pk?: [{	id:number},ValueTypes["voyce_popup_emails"]],
voyce_popup_emails_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_popup_emails_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_popup_emails_bool_exp"]},ValueTypes["voyce_popup_emails"]],
voyce_posts?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_bool_exp"]},ValueTypes["voyce_posts"]],
voyce_posts_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_bool_exp"]},ValueTypes["voyce_posts_aggregate"]],
voyce_posts_by_pk?: [{	id:number},ValueTypes["voyce_posts"]],
voyce_posts_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_comments_bool_exp"]},ValueTypes["voyce_posts_comments"]],
voyce_posts_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_comments_bool_exp"]},ValueTypes["voyce_posts_comments_aggregate"]],
voyce_posts_comments_by_pk?: [{	id:number},ValueTypes["voyce_posts_comments"]],
voyce_posts_comments_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_posts_comments_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_comments_bool_exp"]},ValueTypes["voyce_posts_comments"]],
voyce_posts_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_reactions_bool_exp"]},ValueTypes["voyce_posts_reactions"]],
voyce_posts_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_reactions_bool_exp"]},ValueTypes["voyce_posts_reactions_aggregate"]],
voyce_posts_reactions_by_pk?: [{	id:number},ValueTypes["voyce_posts_reactions"]],
voyce_posts_reactions_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_posts_reactions_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_reactions_bool_exp"]},ValueTypes["voyce_posts_reactions"]],
voyce_posts_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_posts_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_bool_exp"]},ValueTypes["voyce_posts"]],
voyce_quest_difficulties?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_quest_difficulties_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_quest_difficulties_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_quest_difficulties_bool_exp"]},ValueTypes["voyce_quest_difficulties"]],
voyce_quest_difficulties_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_quest_difficulties_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_quest_difficulties_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_quest_difficulties_bool_exp"]},ValueTypes["voyce_quest_difficulties_aggregate"]],
voyce_quest_difficulties_by_pk?: [{	id:number},ValueTypes["voyce_quest_difficulties"]],
voyce_quest_difficulties_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_quest_difficulties_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_quest_difficulties_bool_exp"]},ValueTypes["voyce_quest_difficulties"]],
voyce_quests?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_quests_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_quests_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_quests_bool_exp"]},ValueTypes["voyce_quests"]],
voyce_quests_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_quests_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_quests_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_quests_bool_exp"]},ValueTypes["voyce_quests_aggregate"]],
voyce_quests_by_pk?: [{	id:number},ValueTypes["voyce_quests"]],
voyce_quests_old?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_quests_old_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_quests_old_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_quests_old_bool_exp"]},ValueTypes["voyce_quests_old"]],
voyce_quests_old_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_quests_old_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_quests_old_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_quests_old_bool_exp"]},ValueTypes["voyce_quests_old_aggregate"]],
voyce_quests_old_by_pk?: [{	id:number},ValueTypes["voyce_quests_old"]],
voyce_quests_old_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_quests_old_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_quests_old_bool_exp"]},ValueTypes["voyce_quests_old"]],
voyce_quests_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_quests_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_quests_bool_exp"]},ValueTypes["voyce_quests"]],
voyce_recommended_series?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_recommended_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_recommended_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_recommended_series_bool_exp"]},ValueTypes["voyce_recommended_series"]],
voyce_recommended_series_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_recommended_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_recommended_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_recommended_series_bool_exp"]},ValueTypes["voyce_recommended_series_aggregate"]],
voyce_recommended_series_by_pk?: [{	id:number},ValueTypes["voyce_recommended_series"]],
voyce_recommended_series_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_recommended_series_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_recommended_series_bool_exp"]},ValueTypes["voyce_recommended_series"]],
voyce_release_schedules?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_release_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_release_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_release_schedules_bool_exp"]},ValueTypes["voyce_release_schedules"]],
voyce_release_schedules_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_release_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_release_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_release_schedules_bool_exp"]},ValueTypes["voyce_release_schedules_aggregate"]],
voyce_release_schedules_by_pk?: [{	id:number},ValueTypes["voyce_release_schedules"]],
voyce_release_schedules_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_release_schedules_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_release_schedules_bool_exp"]},ValueTypes["voyce_release_schedules"]],
voyce_rewards?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_rewards_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_rewards_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_bool_exp"]},ValueTypes["voyce_rewards"]],
voyce_rewards_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_rewards_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_rewards_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_bool_exp"]},ValueTypes["voyce_rewards_aggregate"]],
voyce_rewards_by_pk?: [{	id:number},ValueTypes["voyce_rewards"]],
voyce_rewards_leaderboard?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_rewards_leaderboard_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_rewards_leaderboard_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_leaderboard_bool_exp"]},ValueTypes["voyce_rewards_leaderboard"]],
voyce_rewards_leaderboard_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_rewards_leaderboard_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_rewards_leaderboard_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_leaderboard_bool_exp"]},ValueTypes["voyce_rewards_leaderboard_aggregate"]],
voyce_rewards_leaderboard_by_pk?: [{	id:number},ValueTypes["voyce_rewards_leaderboard"]],
voyce_rewards_leaderboard_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_rewards_leaderboard_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_leaderboard_bool_exp"]},ValueTypes["voyce_rewards_leaderboard"]],
voyce_rewards_quest?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_rewards_quest_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_rewards_quest_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_quest_bool_exp"]},ValueTypes["voyce_rewards_quest"]],
voyce_rewards_quest_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_rewards_quest_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_rewards_quest_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_quest_bool_exp"]},ValueTypes["voyce_rewards_quest_aggregate"]],
voyce_rewards_quest_by_pk?: [{	id:number},ValueTypes["voyce_rewards_quest"]],
voyce_rewards_quest_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_rewards_quest_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_quest_bool_exp"]},ValueTypes["voyce_rewards_quest"]],
voyce_rewards_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_rewards_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_bool_exp"]},ValueTypes["voyce_rewards"]],
voyce_sections?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_sections_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_sections_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_sections_bool_exp"]},ValueTypes["voyce_sections"]],
voyce_sections_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_sections_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_sections_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_sections_bool_exp"]},ValueTypes["voyce_sections_aggregate"]],
voyce_sections_by_pk?: [{	id:number},ValueTypes["voyce_sections"]],
voyce_sections_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_sections_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_sections_bool_exp"]},ValueTypes["voyce_sections"]],
voyce_series?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_bool_exp"]},ValueTypes["voyce_series"]],
voyce_series_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_bool_exp"]},ValueTypes["voyce_series_aggregate"]],
voyce_series_by_pk?: [{	id:number},ValueTypes["voyce_series"]],
voyce_series_collaborators?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_collaborators_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_collaborators_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_collaborators_bool_exp"]},ValueTypes["voyce_series_collaborators"]],
voyce_series_collaborators_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_collaborators_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_collaborators_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_collaborators_bool_exp"]},ValueTypes["voyce_series_collaborators_aggregate"]],
voyce_series_collaborators_by_pk?: [{	id:number},ValueTypes["voyce_series_collaborators"]],
voyce_series_collaborators_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_collaborators_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_collaborators_bool_exp"]},ValueTypes["voyce_series_collaborators"]],
voyce_series_donations?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_donations_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_donations_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_donations_bool_exp"]},ValueTypes["voyce_series_donations"]],
voyce_series_donations_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_donations_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_donations_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_donations_bool_exp"]},ValueTypes["voyce_series_donations_aggregate"]],
voyce_series_donations_by_pk?: [{	id:number},ValueTypes["voyce_series_donations"]],
voyce_series_donations_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_donations_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_donations_bool_exp"]},ValueTypes["voyce_series_donations"]],
voyce_series_favorites?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_favorites_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_favorites_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_favorites_bool_exp"]},ValueTypes["voyce_series_favorites"]],
voyce_series_favorites_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_favorites_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_favorites_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_favorites_bool_exp"]},ValueTypes["voyce_series_favorites_aggregate"]],
voyce_series_favorites_by_pk?: [{	id:number,	series_id:number},ValueTypes["voyce_series_favorites"]],
voyce_series_favorites_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_favorites_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_favorites_bool_exp"]},ValueTypes["voyce_series_favorites"]],
voyce_series_featured_panels?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_featured_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_featured_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_featured_panels_bool_exp"]},ValueTypes["voyce_series_featured_panels"]],
voyce_series_featured_panels_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_featured_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_featured_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_featured_panels_bool_exp"]},ValueTypes["voyce_series_featured_panels_aggregate"]],
voyce_series_featured_panels_by_pk?: [{	id:number},ValueTypes["voyce_series_featured_panels"]],
voyce_series_featured_panels_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_featured_panels_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_featured_panels_bool_exp"]},ValueTypes["voyce_series_featured_panels"]],
voyce_series_genre?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_genre_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_genre_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_genre_bool_exp"]},ValueTypes["voyce_series_genre"]],
voyce_series_genre_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_genre_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_genre_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_genre_bool_exp"]},ValueTypes["voyce_series_genre_aggregate"]],
voyce_series_genre_by_pk?: [{	id:number},ValueTypes["voyce_series_genre"]],
voyce_series_genre_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_genre_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_genre_bool_exp"]},ValueTypes["voyce_series_genre"]],
voyce_series_giveaway_participants?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_giveaway_participants_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_giveaway_participants_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_giveaway_participants_bool_exp"]},ValueTypes["voyce_series_giveaway_participants"]],
voyce_series_giveaway_participants_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_giveaway_participants_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_giveaway_participants_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_giveaway_participants_bool_exp"]},ValueTypes["voyce_series_giveaway_participants_aggregate"]],
voyce_series_giveaway_participants_by_pk?: [{	id:number},ValueTypes["voyce_series_giveaway_participants"]],
voyce_series_giveaway_participants_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_giveaway_participants_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_giveaway_participants_bool_exp"]},ValueTypes["voyce_series_giveaway_participants"]],
voyce_series_hot_panels?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_hot_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_hot_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_hot_panels_bool_exp"]},ValueTypes["voyce_series_hot_panels"]],
voyce_series_hot_panels_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_hot_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_hot_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_hot_panels_bool_exp"]},ValueTypes["voyce_series_hot_panels_aggregate"]],
voyce_series_hot_panels_by_pk?: [{	id:number},ValueTypes["voyce_series_hot_panels"]],
voyce_series_hot_panels_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_hot_panels_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_hot_panels_bool_exp"]},ValueTypes["voyce_series_hot_panels"]],
voyce_series_layouts?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_layouts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_layouts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_layouts_bool_exp"]},ValueTypes["voyce_series_layouts"]],
voyce_series_layouts_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_layouts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_layouts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_layouts_bool_exp"]},ValueTypes["voyce_series_layouts_aggregate"]],
voyce_series_layouts_by_pk?: [{	id:number},ValueTypes["voyce_series_layouts"]],
voyce_series_layouts_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_layouts_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_layouts_bool_exp"]},ValueTypes["voyce_series_layouts"]],
voyce_series_leaderboard?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_leaderboard_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_leaderboard_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_leaderboard_bool_exp"]},ValueTypes["voyce_series_leaderboard"]],
voyce_series_leaderboard_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_leaderboard_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_leaderboard_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_leaderboard_bool_exp"]},ValueTypes["voyce_series_leaderboard_aggregate"]],
voyce_series_leaderboard_by_pk?: [{	id:number},ValueTypes["voyce_series_leaderboard"]],
voyce_series_leaderboard_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_leaderboard_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_leaderboard_bool_exp"]},ValueTypes["voyce_series_leaderboard"]],
voyce_series_likes_count?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_likes_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_likes_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_likes_count_bool_exp"]},ValueTypes["voyce_series_likes_count"]],
voyce_series_likes_count_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_likes_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_likes_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_likes_count_bool_exp"]},ValueTypes["voyce_series_likes_count_aggregate"]],
voyce_series_likes_count_mat?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_likes_count_mat_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_likes_count_mat_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_likes_count_mat_bool_exp"]},ValueTypes["voyce_series_likes_count_mat"]],
voyce_series_likes_count_mat_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_likes_count_mat_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_likes_count_mat_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_likes_count_mat_bool_exp"]},ValueTypes["voyce_series_likes_count_mat_aggregate"]],
voyce_series_likes_count_mat_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_likes_count_mat_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_likes_count_mat_bool_exp"]},ValueTypes["voyce_series_likes_count_mat"]],
voyce_series_likes_count_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_likes_count_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_likes_count_bool_exp"]},ValueTypes["voyce_series_likes_count"]],
voyce_series_lock_config?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_lock_config_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_lock_config_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_lock_config_bool_exp"]},ValueTypes["voyce_series_lock_config"]],
voyce_series_lock_config_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_lock_config_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_lock_config_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_lock_config_bool_exp"]},ValueTypes["voyce_series_lock_config_aggregate"]],
voyce_series_lock_config_by_pk?: [{	id:number},ValueTypes["voyce_series_lock_config"]],
voyce_series_lock_config_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_lock_config_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_lock_config_bool_exp"]},ValueTypes["voyce_series_lock_config"]],
voyce_series_sponsorships_configuration?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_sponsorships_configuration_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_sponsorships_configuration_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_sponsorships_configuration_bool_exp"]},ValueTypes["voyce_series_sponsorships_configuration"]],
voyce_series_sponsorships_configuration_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_sponsorships_configuration_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_sponsorships_configuration_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_sponsorships_configuration_bool_exp"]},ValueTypes["voyce_series_sponsorships_configuration_aggregate"]],
voyce_series_sponsorships_configuration_by_pk?: [{	id:number},ValueTypes["voyce_series_sponsorships_configuration"]],
voyce_series_sponsorships_configuration_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_sponsorships_configuration_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_sponsorships_configuration_bool_exp"]},ValueTypes["voyce_series_sponsorships_configuration"]],
voyce_series_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_bool_exp"]},ValueTypes["voyce_series"]],
voyce_series_subscription?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_subscription_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_subscription_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_subscription_bool_exp"]},ValueTypes["voyce_series_subscription"]],
voyce_series_subscription_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_subscription_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_subscription_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_subscription_bool_exp"]},ValueTypes["voyce_series_subscription_aggregate"]],
voyce_series_subscription_by_pk?: [{	id:number},ValueTypes["voyce_series_subscription"]],
voyce_series_subscription_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_subscription_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_subscription_bool_exp"]},ValueTypes["voyce_series_subscription"]],
voyce_series_tags?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_tags_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_tags_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_tags_bool_exp"]},ValueTypes["voyce_series_tags"]],
voyce_series_tags_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_tags_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_tags_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_tags_bool_exp"]},ValueTypes["voyce_series_tags_aggregate"]],
voyce_series_tags_by_pk?: [{	id:number},ValueTypes["voyce_series_tags"]],
voyce_series_tags_junction?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_tags_junction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_tags_junction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_tags_junction_bool_exp"]},ValueTypes["voyce_series_tags_junction"]],
voyce_series_tags_junction_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_tags_junction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_tags_junction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_tags_junction_bool_exp"]},ValueTypes["voyce_series_tags_junction_aggregate"]],
voyce_series_tags_junction_by_pk?: [{	id:number},ValueTypes["voyce_series_tags_junction"]],
voyce_series_tags_junction_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_tags_junction_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_tags_junction_bool_exp"]},ValueTypes["voyce_series_tags_junction"]],
voyce_series_tags_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_tags_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_tags_bool_exp"]},ValueTypes["voyce_series_tags"]],
voyce_series_top_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_top_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_top_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_top_comments_bool_exp"]},ValueTypes["voyce_series_top_comments"]],
voyce_series_top_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_top_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_top_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_top_comments_bool_exp"]},ValueTypes["voyce_series_top_comments_aggregate"]],
voyce_series_top_comments_by_pk?: [{	id:number},ValueTypes["voyce_series_top_comments"]],
voyce_series_top_comments_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_top_comments_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_top_comments_bool_exp"]},ValueTypes["voyce_series_top_comments"]],
voyce_series_top_likes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_top_likes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_top_likes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_top_likes_bool_exp"]},ValueTypes["voyce_series_top_likes"]],
voyce_series_top_likes_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_top_likes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_top_likes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_top_likes_bool_exp"]},ValueTypes["voyce_series_top_likes_aggregate"]],
voyce_series_top_likes_by_pk?: [{	id:number},ValueTypes["voyce_series_top_likes"]],
voyce_series_top_likes_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_top_likes_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_top_likes_bool_exp"]},ValueTypes["voyce_series_top_likes"]],
voyce_series_top_views?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_top_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_top_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_top_views_bool_exp"]},ValueTypes["voyce_series_top_views"]],
voyce_series_top_views_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_top_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_top_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_top_views_bool_exp"]},ValueTypes["voyce_series_top_views_aggregate"]],
voyce_series_top_views_by_pk?: [{	id:number},ValueTypes["voyce_series_top_views"]],
voyce_series_top_views_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_top_views_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_top_views_bool_exp"]},ValueTypes["voyce_series_top_views"]],
voyce_series_views_adjustment?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_adjustment_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_adjustment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_adjustment_bool_exp"]},ValueTypes["voyce_series_views_adjustment"]],
voyce_series_views_adjustment_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_adjustment_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_adjustment_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_adjustment_bool_exp"]},ValueTypes["voyce_series_views_adjustment_aggregate"]],
voyce_series_views_adjustment_by_pk?: [{	id:number},ValueTypes["voyce_series_views_adjustment"]],
voyce_series_views_adjustment_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_views_adjustment_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_adjustment_bool_exp"]},ValueTypes["voyce_series_views_adjustment"]],
voyce_series_views_count?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_bool_exp"]},ValueTypes["voyce_series_views_count"]],
voyce_series_views_count_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_bool_exp"]},ValueTypes["voyce_series_views_count_aggregate"]],
voyce_series_views_count_mat?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_count_mat_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_count_mat_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_mat_bool_exp"]},ValueTypes["voyce_series_views_count_mat"]],
voyce_series_views_count_mat_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_count_mat_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_count_mat_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_mat_bool_exp"]},ValueTypes["voyce_series_views_count_mat_aggregate"]],
voyce_series_views_count_mat_sep30?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_count_mat_sep30_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_count_mat_sep30_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_mat_sep30_bool_exp"]},ValueTypes["voyce_series_views_count_mat_sep30"]],
voyce_series_views_count_mat_sep30_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_count_mat_sep30_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_count_mat_sep30_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_mat_sep30_bool_exp"]},ValueTypes["voyce_series_views_count_mat_sep30_aggregate"]],
voyce_series_views_count_mat_sep30_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_views_count_mat_sep30_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_mat_sep30_bool_exp"]},ValueTypes["voyce_series_views_count_mat_sep30"]],
voyce_series_views_count_mat_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_views_count_mat_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_mat_bool_exp"]},ValueTypes["voyce_series_views_count_mat"]],
voyce_series_views_count_mat_v2?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_count_mat_v2_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_count_mat_v2_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_mat_v2_bool_exp"]},ValueTypes["voyce_series_views_count_mat_v2"]],
voyce_series_views_count_mat_v2_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_views_count_mat_v2_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_views_count_mat_v2_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_mat_v2_bool_exp"]},ValueTypes["voyce_series_views_count_mat_v2_aggregate"]],
voyce_series_views_count_mat_v2_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_views_count_mat_v2_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_mat_v2_bool_exp"]},ValueTypes["voyce_series_views_count_mat_v2"]],
voyce_series_views_count_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_views_count_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_views_count_bool_exp"]},ValueTypes["voyce_series_views_count"]],
voyce_series_votes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_votes_bool_exp"]},ValueTypes["voyce_series_votes"]],
voyce_series_votes_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_votes_bool_exp"]},ValueTypes["voyce_series_votes_aggregate"]],
voyce_series_votes_by_pk?: [{	id:number},ValueTypes["voyce_series_votes"]],
voyce_series_votes_count?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_votes_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_votes_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_votes_count_bool_exp"]},ValueTypes["voyce_series_votes_count"]],
voyce_series_votes_count_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_votes_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_votes_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_votes_count_bool_exp"]},ValueTypes["voyce_series_votes_count_aggregate"]],
voyce_series_votes_count_by_pk?: [{	id:number},ValueTypes["voyce_series_votes_count"]],
voyce_series_votes_count_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_votes_count_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_votes_count_bool_exp"]},ValueTypes["voyce_series_votes_count"]],
voyce_series_votes_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_votes_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_votes_bool_exp"]},ValueTypes["voyce_series_votes"]],
voyce_series_voting_schedules?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_voting_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_voting_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_voting_schedules_bool_exp"]},ValueTypes["voyce_series_voting_schedules"]],
voyce_series_voting_schedules_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_voting_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_voting_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_voting_schedules_bool_exp"]},ValueTypes["voyce_series_voting_schedules_aggregate"]],
voyce_series_voting_schedules_by_pk?: [{	id:number},ValueTypes["voyce_series_voting_schedules"]],
voyce_series_voting_schedules_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_series_voting_schedules_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_voting_schedules_bool_exp"]},ValueTypes["voyce_series_voting_schedules"]],
voyce_sponsorships?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_sponsorships_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_sponsorships_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_sponsorships_bool_exp"]},ValueTypes["voyce_sponsorships"]],
voyce_sponsorships_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_sponsorships_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_sponsorships_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_sponsorships_bool_exp"]},ValueTypes["voyce_sponsorships_aggregate"]],
voyce_sponsorships_by_pk?: [{	id:number},ValueTypes["voyce_sponsorships"]],
voyce_sponsorships_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_sponsorships_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_sponsorships_bool_exp"]},ValueTypes["voyce_sponsorships"]],
voyce_states?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_states_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_states_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_states_bool_exp"]},ValueTypes["voyce_states"]],
voyce_states_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_states_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_states_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_states_bool_exp"]},ValueTypes["voyce_states_aggregate"]],
voyce_states_by_pk?: [{	id:number},ValueTypes["voyce_states"]],
voyce_states_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_states_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_states_bool_exp"]},ValueTypes["voyce_states"]],
voyce_stripe_payouts_setup?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_stripe_payouts_setup_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_stripe_payouts_setup_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_stripe_payouts_setup_bool_exp"]},ValueTypes["voyce_stripe_payouts_setup"]],
voyce_stripe_payouts_setup_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_stripe_payouts_setup_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_stripe_payouts_setup_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_stripe_payouts_setup_bool_exp"]},ValueTypes["voyce_stripe_payouts_setup_aggregate"]],
voyce_stripe_payouts_setup_by_pk?: [{	id:number},ValueTypes["voyce_stripe_payouts_setup"]],
voyce_stripe_payouts_setup_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_stripe_payouts_setup_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_stripe_payouts_setup_bool_exp"]},ValueTypes["voyce_stripe_payouts_setup"]],
voyce_studio?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_bool_exp"]},ValueTypes["voyce_studio"]],
voyce_studio_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_bool_exp"]},ValueTypes["voyce_studio_aggregate"]],
voyce_studio_by_pk?: [{	id:number},ValueTypes["voyce_studio"]],
voyce_studio_followers?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_followers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_followers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_followers_bool_exp"]},ValueTypes["voyce_studio_followers"]],
voyce_studio_followers_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_followers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_followers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_followers_bool_exp"]},ValueTypes["voyce_studio_followers_aggregate"]],
voyce_studio_followers_by_pk?: [{	id:number},ValueTypes["voyce_studio_followers"]],
voyce_studio_followers_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_studio_followers_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_followers_bool_exp"]},ValueTypes["voyce_studio_followers"]],
voyce_studio_series?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_series_bool_exp"]},ValueTypes["voyce_studio_series"]],
voyce_studio_series_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_series_bool_exp"]},ValueTypes["voyce_studio_series_aggregate"]],
voyce_studio_series_by_pk?: [{	id:number},ValueTypes["voyce_studio_series"]],
voyce_studio_series_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_studio_series_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_series_bool_exp"]},ValueTypes["voyce_studio_series"]],
voyce_studio_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_studio_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_bool_exp"]},ValueTypes["voyce_studio"]],
voyce_studio_views?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_views_bool_exp"]},ValueTypes["voyce_studio_views"]],
voyce_studio_views_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_views_bool_exp"]},ValueTypes["voyce_studio_views_aggregate"]],
voyce_studio_views_by_pk?: [{	id:number},ValueTypes["voyce_studio_views"]],
voyce_studio_views_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_studio_views_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_views_bool_exp"]},ValueTypes["voyce_studio_views"]],
voyce_system_settings?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_system_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_system_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_system_settings_bool_exp"]},ValueTypes["voyce_system_settings"]],
voyce_system_settings_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_system_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_system_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_system_settings_bool_exp"]},ValueTypes["voyce_system_settings_aggregate"]],
voyce_system_settings_by_pk?: [{	id:number},ValueTypes["voyce_system_settings"]],
voyce_system_settings_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_system_settings_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_system_settings_bool_exp"]},ValueTypes["voyce_system_settings"]],
voyce_top_genres?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_top_genres_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_top_genres_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_top_genres_bool_exp"]},ValueTypes["voyce_top_genres"]],
voyce_top_genres_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_top_genres_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_top_genres_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_top_genres_bool_exp"]},ValueTypes["voyce_top_genres_aggregate"]],
voyce_top_genres_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_top_genres_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_top_genres_bool_exp"]},ValueTypes["voyce_top_genres"]],
voyce_transaction_logs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_transaction_logs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_transaction_logs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_transaction_logs_bool_exp"]},ValueTypes["voyce_transaction_logs"]],
voyce_transaction_logs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_transaction_logs_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_transaction_logs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_transaction_logs_bool_exp"]},ValueTypes["voyce_transaction_logs_aggregate"]],
voyce_transaction_logs_by_pk?: [{	id:number},ValueTypes["voyce_transaction_logs"]],
voyce_transaction_logs_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_transaction_logs_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_transaction_logs_bool_exp"]},ValueTypes["voyce_transaction_logs"]],
voyce_url_redirects?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_url_redirects_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_url_redirects_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_url_redirects_bool_exp"]},ValueTypes["voyce_url_redirects"]],
voyce_url_redirects_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_url_redirects_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_url_redirects_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_url_redirects_bool_exp"]},ValueTypes["voyce_url_redirects_aggregate"]],
voyce_url_redirects_by_pk?: [{	id:number},ValueTypes["voyce_url_redirects"]],
voyce_url_redirects_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_url_redirects_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_url_redirects_bool_exp"]},ValueTypes["voyce_url_redirects"]],
voyce_user_addresses?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_addresses_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_addresses_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_addresses_bool_exp"]},ValueTypes["voyce_user_addresses"]],
voyce_user_addresses_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_addresses_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_addresses_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_addresses_bool_exp"]},ValueTypes["voyce_user_addresses_aggregate"]],
voyce_user_addresses_by_pk?: [{	id:number},ValueTypes["voyce_user_addresses"]],
voyce_user_addresses_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_addresses_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_addresses_bool_exp"]},ValueTypes["voyce_user_addresses"]],
voyce_user_blocks?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_blocks_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_blocks_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_blocks_bool_exp"]},ValueTypes["voyce_user_blocks"]],
voyce_user_blocks_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_blocks_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_blocks_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_blocks_bool_exp"]},ValueTypes["voyce_user_blocks_aggregate"]],
voyce_user_blocks_by_pk?: [{	linked_id:number,	user_id:number},ValueTypes["voyce_user_blocks"]],
voyce_user_blocks_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_blocks_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_blocks_bool_exp"]},ValueTypes["voyce_user_blocks"]],
voyce_user_chapters_unlocked?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_chapters_unlocked_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_chapters_unlocked_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_chapters_unlocked_bool_exp"]},ValueTypes["voyce_user_chapters_unlocked"]],
voyce_user_chapters_unlocked_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_chapters_unlocked_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_chapters_unlocked_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_chapters_unlocked_bool_exp"]},ValueTypes["voyce_user_chapters_unlocked_aggregate"]],
voyce_user_chapters_unlocked_by_pk?: [{	id:number},ValueTypes["voyce_user_chapters_unlocked"]],
voyce_user_chapters_unlocked_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_chapters_unlocked_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_chapters_unlocked_bool_exp"]},ValueTypes["voyce_user_chapters_unlocked"]],
voyce_user_contacts?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_contacts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_contacts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_contacts_bool_exp"]},ValueTypes["voyce_user_contacts"]],
voyce_user_contacts_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_contacts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_contacts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_contacts_bool_exp"]},ValueTypes["voyce_user_contacts_aggregate"]],
voyce_user_contacts_by_pk?: [{	id:number},ValueTypes["voyce_user_contacts"]],
voyce_user_contacts_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_contacts_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_contacts_bool_exp"]},ValueTypes["voyce_user_contacts"]],
voyce_user_csat_ratings?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_csat_ratings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_csat_ratings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_csat_ratings_bool_exp"]},ValueTypes["voyce_user_csat_ratings"]],
voyce_user_csat_ratings_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_csat_ratings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_csat_ratings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_csat_ratings_bool_exp"]},ValueTypes["voyce_user_csat_ratings_aggregate"]],
voyce_user_csat_ratings_by_pk?: [{	id:number},ValueTypes["voyce_user_csat_ratings"]],
voyce_user_csat_ratings_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_csat_ratings_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_csat_ratings_bool_exp"]},ValueTypes["voyce_user_csat_ratings"]],
voyce_user_education?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_education_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_education_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_education_bool_exp"]},ValueTypes["voyce_user_education"]],
voyce_user_education_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_education_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_education_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_education_bool_exp"]},ValueTypes["voyce_user_education_aggregate"]],
voyce_user_education_by_pk?: [{	id:number},ValueTypes["voyce_user_education"]],
voyce_user_education_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_education_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_education_bool_exp"]},ValueTypes["voyce_user_education"]],
voyce_user_level?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_level_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_level_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_level_bool_exp"]},ValueTypes["voyce_user_level"]],
voyce_user_level_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_level_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_level_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_level_bool_exp"]},ValueTypes["voyce_user_level_aggregate"]],
voyce_user_level_by_pk?: [{	id:number},ValueTypes["voyce_user_level"]],
voyce_user_level_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_level_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_level_bool_exp"]},ValueTypes["voyce_user_level"]],
voyce_user_linked_accounts?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_linked_accounts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_linked_accounts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_linked_accounts_bool_exp"]},ValueTypes["voyce_user_linked_accounts"]],
voyce_user_linked_accounts_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_linked_accounts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_linked_accounts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_linked_accounts_bool_exp"]},ValueTypes["voyce_user_linked_accounts_aggregate"]],
voyce_user_linked_accounts_by_pk?: [{	id:number},ValueTypes["voyce_user_linked_accounts"]],
voyce_user_linked_accounts_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_linked_accounts_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_linked_accounts_bool_exp"]},ValueTypes["voyce_user_linked_accounts"]],
voyce_user_login_history?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_login_history_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_login_history_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_login_history_bool_exp"]},ValueTypes["voyce_user_login_history"]],
voyce_user_login_history_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_login_history_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_login_history_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_login_history_bool_exp"]},ValueTypes["voyce_user_login_history_aggregate"]],
voyce_user_login_history_by_pk?: [{	id:number},ValueTypes["voyce_user_login_history"]],
voyce_user_login_history_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_login_history_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_login_history_bool_exp"]},ValueTypes["voyce_user_login_history"]],
voyce_user_quest_ids?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quest_ids_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quest_ids_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quest_ids_bool_exp"]},ValueTypes["voyce_user_quest_ids"]],
voyce_user_quest_ids_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quest_ids_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quest_ids_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quest_ids_bool_exp"]},ValueTypes["voyce_user_quest_ids_aggregate"]],
voyce_user_quest_ids_by_pk?: [{	id:number},ValueTypes["voyce_user_quest_ids"]],
voyce_user_quest_ids_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_quest_ids_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quest_ids_bool_exp"]},ValueTypes["voyce_user_quest_ids"]],
voyce_user_quest_rewards?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quest_rewards_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quest_rewards_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quest_rewards_bool_exp"]},ValueTypes["voyce_user_quest_rewards"]],
voyce_user_quest_rewards_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quest_rewards_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quest_rewards_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quest_rewards_bool_exp"]},ValueTypes["voyce_user_quest_rewards_aggregate"]],
voyce_user_quest_rewards_by_pk?: [{	id:number},ValueTypes["voyce_user_quest_rewards"]],
voyce_user_quest_rewards_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_quest_rewards_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quest_rewards_bool_exp"]},ValueTypes["voyce_user_quest_rewards"]],
voyce_user_quests?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quests_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quests_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quests_bool_exp"]},ValueTypes["voyce_user_quests"]],
voyce_user_quests_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quests_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quests_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quests_bool_exp"]},ValueTypes["voyce_user_quests_aggregate"]],
voyce_user_quests_by_pk?: [{	id:number},ValueTypes["voyce_user_quests"]],
voyce_user_quests_old?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quests_old_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quests_old_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quests_old_bool_exp"]},ValueTypes["voyce_user_quests_old"]],
voyce_user_quests_old_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quests_old_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quests_old_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quests_old_bool_exp"]},ValueTypes["voyce_user_quests_old_aggregate"]],
voyce_user_quests_old_by_pk?: [{	id:number},ValueTypes["voyce_user_quests_old"]],
voyce_user_quests_old_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_quests_old_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quests_old_bool_exp"]},ValueTypes["voyce_user_quests_old"]],
voyce_user_quests_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_quests_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quests_bool_exp"]},ValueTypes["voyce_user_quests"]],
voyce_user_ranking?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_ranking_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_ranking_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_ranking_bool_exp"]},ValueTypes["voyce_user_ranking"]],
voyce_user_ranking_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_ranking_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_ranking_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_ranking_bool_exp"]},ValueTypes["voyce_user_ranking_aggregate"]],
voyce_user_ranking_by_pk?: [{	id:number},ValueTypes["voyce_user_ranking"]],
voyce_user_ranking_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_ranking_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_ranking_bool_exp"]},ValueTypes["voyce_user_ranking"]],
voyce_user_reviews?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_reviews_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_reviews_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_reviews_bool_exp"]},ValueTypes["voyce_user_reviews"]],
voyce_user_reviews_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_reviews_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_reviews_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_reviews_bool_exp"]},ValueTypes["voyce_user_reviews_aggregate"]],
voyce_user_reviews_by_pk?: [{	id:number},ValueTypes["voyce_user_reviews"]],
voyce_user_reviews_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_reviews_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_reviews_bool_exp"]},ValueTypes["voyce_user_reviews"]],
voyce_user_rewards?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_rewards_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_rewards_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_rewards_bool_exp"]},ValueTypes["voyce_user_rewards"]],
voyce_user_rewards_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_rewards_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_rewards_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_rewards_bool_exp"]},ValueTypes["voyce_user_rewards_aggregate"]],
voyce_user_rewards_by_pk?: [{	id:number},ValueTypes["voyce_user_rewards"]],
voyce_user_rewards_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_rewards_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_rewards_bool_exp"]},ValueTypes["voyce_user_rewards"]],
voyce_user_roles?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_roles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_roles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_roles_bool_exp"]},ValueTypes["voyce_user_roles"]],
voyce_user_roles_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_roles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_roles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_roles_bool_exp"]},ValueTypes["voyce_user_roles_aggregate"]],
voyce_user_roles_by_pk?: [{	id:number},ValueTypes["voyce_user_roles"]],
voyce_user_roles_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_roles_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_roles_bool_exp"]},ValueTypes["voyce_user_roles"]],
voyce_user_series_subscription_count?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_series_subscription_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_series_subscription_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_series_subscription_count_bool_exp"]},ValueTypes["voyce_user_series_subscription_count"]],
voyce_user_series_subscription_count_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_series_subscription_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_series_subscription_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_series_subscription_count_bool_exp"]},ValueTypes["voyce_user_series_subscription_count_aggregate"]],
voyce_user_series_subscription_count_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_series_subscription_count_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_series_subscription_count_bool_exp"]},ValueTypes["voyce_user_series_subscription_count"]],
voyce_user_settings?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_settings_bool_exp"]},ValueTypes["voyce_user_settings"]],
voyce_user_settings_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_settings_bool_exp"]},ValueTypes["voyce_user_settings_aggregate"]],
voyce_user_settings_by_pk?: [{	user_id:number},ValueTypes["voyce_user_settings"]],
voyce_user_settings_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_settings_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_settings_bool_exp"]},ValueTypes["voyce_user_settings"]],
voyce_user_skills?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_skills_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_skills_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_skills_bool_exp"]},ValueTypes["voyce_user_skills"]],
voyce_user_skills_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_skills_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_skills_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_skills_bool_exp"]},ValueTypes["voyce_user_skills_aggregate"]],
voyce_user_skills_by_pk?: [{	id:number},ValueTypes["voyce_user_skills"]],
voyce_user_skills_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_skills_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_skills_bool_exp"]},ValueTypes["voyce_user_skills"]],
voyce_user_socials?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_socials_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_socials_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_socials_bool_exp"]},ValueTypes["voyce_user_socials"]],
voyce_user_socials_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_socials_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_socials_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_socials_bool_exp"]},ValueTypes["voyce_user_socials_aggregate"]],
voyce_user_socials_by_pk?: [{	id:number},ValueTypes["voyce_user_socials"]],
voyce_user_socials_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_socials_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_socials_bool_exp"]},ValueTypes["voyce_user_socials"]],
voyce_user_soft_deleted?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_soft_deleted_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_soft_deleted_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_soft_deleted_bool_exp"]},ValueTypes["voyce_user_soft_deleted"]],
voyce_user_soft_deleted_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_soft_deleted_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_soft_deleted_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_soft_deleted_bool_exp"]},ValueTypes["voyce_user_soft_deleted_aggregate"]],
voyce_user_soft_deleted_by_pk?: [{	id:number},ValueTypes["voyce_user_soft_deleted"]],
voyce_user_soft_deleted_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_soft_deleted_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_soft_deleted_bool_exp"]},ValueTypes["voyce_user_soft_deleted"]],
voyce_user_vtags?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_vtags_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_vtags_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_vtags_bool_exp"]},ValueTypes["voyce_user_vtags"]],
voyce_user_vtags_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_vtags_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_vtags_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_vtags_bool_exp"]},ValueTypes["voyce_user_vtags_aggregate"]],
voyce_user_vtags_by_pk?: [{	id:number},ValueTypes["voyce_user_vtags"]],
voyce_user_vtags_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_vtags_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_vtags_bool_exp"]},ValueTypes["voyce_user_vtags"]],
voyce_user_wins?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_wins_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_wins_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_wins_bool_exp"]},ValueTypes["voyce_user_wins"]],
voyce_user_wins_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_wins_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_wins_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_wins_bool_exp"]},ValueTypes["voyce_user_wins_aggregate"]],
voyce_user_wins_by_pk?: [{	id:number},ValueTypes["voyce_user_wins"]],
voyce_user_wins_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_user_wins_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_wins_bool_exp"]},ValueTypes["voyce_user_wins"]],
voyce_users?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_bool_exp"]},ValueTypes["voyce_users"]],
voyce_users_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_bool_exp"]},ValueTypes["voyce_users_aggregate"]],
voyce_users_by_pk?: [{	id:number},ValueTypes["voyce_users"]],
voyce_users_followers?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_followers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_followers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_followers_bool_exp"]},ValueTypes["voyce_users_followers"]],
voyce_users_followers_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_followers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_followers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_followers_bool_exp"]},ValueTypes["voyce_users_followers_aggregate"]],
voyce_users_followers_by_pk?: [{	id:number},ValueTypes["voyce_users_followers"]],
voyce_users_followers_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_users_followers_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_followers_bool_exp"]},ValueTypes["voyce_users_followers"]],
voyce_users_private?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_private_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_private_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_private_bool_exp"]},ValueTypes["voyce_users_private"]],
voyce_users_private_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_private_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_private_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_private_bool_exp"]},ValueTypes["voyce_users_private_aggregate"]],
voyce_users_private_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_users_private_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_private_bool_exp"]},ValueTypes["voyce_users_private"]],
voyce_users_series_junction?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_series_junction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_series_junction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_series_junction_bool_exp"]},ValueTypes["voyce_users_series_junction"]],
voyce_users_series_junction_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_series_junction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_series_junction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_series_junction_bool_exp"]},ValueTypes["voyce_users_series_junction_aggregate"]],
voyce_users_series_junction_by_pk?: [{	id:number},ValueTypes["voyce_users_series_junction"]],
voyce_users_series_junction_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_users_series_junction_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_series_junction_bool_exp"]},ValueTypes["voyce_users_series_junction"]],
voyce_users_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_users_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_bool_exp"]},ValueTypes["voyce_users"]],
voyce_versions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_versions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_versions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_versions_bool_exp"]},ValueTypes["voyce_versions"]],
voyce_versions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_versions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_versions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_versions_bool_exp"]},ValueTypes["voyce_versions_aggregate"]],
voyce_versions_by_pk?: [{	id:number},ValueTypes["voyce_versions"]],
voyce_versions_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_versions_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_versions_bool_exp"]},ValueTypes["voyce_versions"]],
voyce_voting_analytics?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_analytics_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_analytics_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_analytics_bool_exp"]},ValueTypes["voyce_voting_analytics"]],
voyce_voting_analytics_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_analytics_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_analytics_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_analytics_bool_exp"]},ValueTypes["voyce_voting_analytics_aggregate"]],
voyce_voting_analytics_by_pk?: [{	id:number},ValueTypes["voyce_voting_analytics"]],
voyce_voting_analytics_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_voting_analytics_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_analytics_bool_exp"]},ValueTypes["voyce_voting_analytics"]],
voyce_voting_panels?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_panels_bool_exp"]},ValueTypes["voyce_voting_panels"]],
voyce_voting_panels_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_panels_bool_exp"]},ValueTypes["voyce_voting_panels_aggregate"]],
voyce_voting_panels_by_pk?: [{	id:number},ValueTypes["voyce_voting_panels"]],
voyce_voting_panels_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_voting_panels_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_panels_bool_exp"]},ValueTypes["voyce_voting_panels"]],
voyce_voting_series?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_series_bool_exp"]},ValueTypes["voyce_voting_series"]],
voyce_voting_series_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_series_bool_exp"]},ValueTypes["voyce_voting_series_aggregate"]],
voyce_voting_series_by_pk?: [{	id:number},ValueTypes["voyce_voting_series"]],
voyce_voting_series_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_voting_series_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_series_bool_exp"]},ValueTypes["voyce_voting_series"]],
voyce_voting_user_votes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_user_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_user_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_user_votes_bool_exp"]},ValueTypes["voyce_voting_user_votes"]],
voyce_voting_user_votes_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_user_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_user_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_user_votes_bool_exp"]},ValueTypes["voyce_voting_user_votes_aggregate"]],
voyce_voting_user_votes_by_pk?: [{	id:number},ValueTypes["voyce_voting_user_votes"]],
voyce_voting_user_votes_stream?: [{	/** maximum number of rows returned in a single batch */
	batch_size:number,	/** cursor to stream the results returned by the query */
	cursor?:ValueTypes["voyce_voting_user_votes_stream_cursor_input"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_user_votes_bool_exp"]},ValueTypes["voyce_voting_user_votes"]],
		__typename?: true
}>;
	["time"]:unknown;
	/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
["time_comparison_exp"]: {
	_eq?:ValueTypes["time"],
	_gt?:ValueTypes["time"],
	_gte?:ValueTypes["time"],
	_in?:ValueTypes["time"][],
	_is_null?:boolean,
	_lt?:ValueTypes["time"],
	_lte?:ValueTypes["time"],
	_neq?:ValueTypes["time"],
	_nin?:ValueTypes["time"][]
};
	["timestamp"]:unknown;
	/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
["timestamp_comparison_exp"]: {
	_eq?:ValueTypes["timestamp"],
	_gt?:ValueTypes["timestamp"],
	_gte?:ValueTypes["timestamp"],
	_in?:ValueTypes["timestamp"][],
	_is_null?:boolean,
	_lt?:ValueTypes["timestamp"],
	_lte?:ValueTypes["timestamp"],
	_neq?:ValueTypes["timestamp"],
	_nin?:ValueTypes["timestamp"][]
};
	["timestamptz"]:unknown;
	/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
["timestamptz_comparison_exp"]: {
	_eq?:ValueTypes["timestamptz"],
	_gt?:ValueTypes["timestamptz"],
	_gte?:ValueTypes["timestamptz"],
	_in?:ValueTypes["timestamptz"][],
	_is_null?:boolean,
	_lt?:ValueTypes["timestamptz"],
	_lte?:ValueTypes["timestamptz"],
	_neq?:ValueTypes["timestamptz"],
	_nin?:ValueTypes["timestamptz"][]
};
	["uuid"]:unknown;
	/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
["uuid_comparison_exp"]: {
	_eq?:ValueTypes["uuid"],
	_gt?:ValueTypes["uuid"],
	_gte?:ValueTypes["uuid"],
	_in?:ValueTypes["uuid"][],
	_is_null?:boolean,
	_lt?:ValueTypes["uuid"],
	_lte?:ValueTypes["uuid"],
	_neq?:ValueTypes["uuid"],
	_nin?:ValueTypes["uuid"][]
};
	/** columns and relationships of "voyce.admin_roles" */
["voyce_admin_roles"]: AliasType<{
	created_at?:true,
	id?:true,
	role?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.admin_roles" */
["voyce_admin_roles_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_admin_roles_aggregate_fields"],
	nodes?:ValueTypes["voyce_admin_roles"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.admin_roles" */
["voyce_admin_roles_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_admin_roles_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_admin_roles_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_admin_roles_max_fields"],
	min?:ValueTypes["voyce_admin_roles_min_fields"],
	stddev?:ValueTypes["voyce_admin_roles_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_admin_roles_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_admin_roles_stddev_samp_fields"],
	sum?:ValueTypes["voyce_admin_roles_sum_fields"],
	var_pop?:ValueTypes["voyce_admin_roles_var_pop_fields"],
	var_samp?:ValueTypes["voyce_admin_roles_var_samp_fields"],
	variance?:ValueTypes["voyce_admin_roles_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_admin_roles_avg_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.admin_roles". All fields are combined with a logical 'AND'. */
["voyce_admin_roles_bool_exp"]: {
	_and?:ValueTypes["voyce_admin_roles_bool_exp"][],
	_not?:ValueTypes["voyce_admin_roles_bool_exp"],
	_or?:ValueTypes["voyce_admin_roles_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	role?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.admin_roles" */
["voyce_admin_roles_constraint"]:voyce_admin_roles_constraint;
	/** input type for incrementing numeric columns in table "voyce.admin_roles" */
["voyce_admin_roles_inc_input"]: {
	id?:number
};
	/** input type for inserting data into table "voyce.admin_roles" */
["voyce_admin_roles_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	role?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_admin_roles_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	role?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_admin_roles_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	role?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.admin_roles" */
["voyce_admin_roles_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_admin_roles"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.admin_roles" */
["voyce_admin_roles_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_admin_roles_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_admin_roles_on_conflict"]
};
	/** on_conflict condition type for table "voyce.admin_roles" */
["voyce_admin_roles_on_conflict"]: {
	constraint:ValueTypes["voyce_admin_roles_constraint"],
	update_columns:ValueTypes["voyce_admin_roles_update_column"][],
	where?:ValueTypes["voyce_admin_roles_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.admin_roles". */
["voyce_admin_roles_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	role?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.admin_roles */
["voyce_admin_roles_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.admin_roles" */
["voyce_admin_roles_select_column"]:voyce_admin_roles_select_column;
	/** input type for updating data in table "voyce.admin_roles" */
["voyce_admin_roles_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	role?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_admin_roles_stddev_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_admin_roles_stddev_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_admin_roles_stddev_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_admin_roles" */
["voyce_admin_roles_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_admin_roles_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_admin_roles_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	role?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_admin_roles_sum_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.admin_roles" */
["voyce_admin_roles_update_column"]:voyce_admin_roles_update_column;
	["voyce_admin_roles_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_admin_roles_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_admin_roles_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_admin_roles_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_admin_roles_var_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_admin_roles_var_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_admin_roles_variance_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.app_banners" */
["voyce_app_banners"]: AliasType<{
	active?:true,
	background?:true,
	created_at?:true,
	id?:true,
	link?:true,
	mobile?:true,
	overlap?:true,
	screen?:true,
	sort?:true,
	tablet?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.app_banners" */
["voyce_app_banners_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_app_banners_aggregate_fields"],
	nodes?:ValueTypes["voyce_app_banners"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.app_banners" */
["voyce_app_banners_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_app_banners_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_app_banners_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_app_banners_max_fields"],
	min?:ValueTypes["voyce_app_banners_min_fields"],
	stddev?:ValueTypes["voyce_app_banners_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_app_banners_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_app_banners_stddev_samp_fields"],
	sum?:ValueTypes["voyce_app_banners_sum_fields"],
	var_pop?:ValueTypes["voyce_app_banners_var_pop_fields"],
	var_samp?:ValueTypes["voyce_app_banners_var_samp_fields"],
	variance?:ValueTypes["voyce_app_banners_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_app_banners_avg_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.app_banners". All fields are combined with a logical 'AND'. */
["voyce_app_banners_bool_exp"]: {
	_and?:ValueTypes["voyce_app_banners_bool_exp"][],
	_not?:ValueTypes["voyce_app_banners_bool_exp"],
	_or?:ValueTypes["voyce_app_banners_bool_exp"][],
	active?:ValueTypes["Boolean_comparison_exp"],
	background?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	link?:ValueTypes["String_comparison_exp"],
	mobile?:ValueTypes["String_comparison_exp"],
	overlap?:ValueTypes["Boolean_comparison_exp"],
	screen?:ValueTypes["String_comparison_exp"],
	sort?:ValueTypes["smallint_comparison_exp"],
	tablet?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.app_banners" */
["voyce_app_banners_constraint"]:voyce_app_banners_constraint;
	/** input type for incrementing numeric columns in table "voyce.app_banners" */
["voyce_app_banners_inc_input"]: {
	id?:number,
	sort?:ValueTypes["smallint"]
};
	/** input type for inserting data into table "voyce.app_banners" */
["voyce_app_banners_insert_input"]: {
	active?:boolean,
	background?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	link?:string,
	mobile?:string,
	overlap?:boolean,
	screen?:string,
	sort?:ValueTypes["smallint"],
	tablet?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_app_banners_max_fields"]: AliasType<{
	background?:true,
	created_at?:true,
	id?:true,
	link?:true,
	mobile?:true,
	screen?:true,
	sort?:true,
	tablet?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_app_banners_min_fields"]: AliasType<{
	background?:true,
	created_at?:true,
	id?:true,
	link?:true,
	mobile?:true,
	screen?:true,
	sort?:true,
	tablet?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.app_banners" */
["voyce_app_banners_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_app_banners"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.app_banners" */
["voyce_app_banners_on_conflict"]: {
	constraint:ValueTypes["voyce_app_banners_constraint"],
	update_columns:ValueTypes["voyce_app_banners_update_column"][],
	where?:ValueTypes["voyce_app_banners_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.app_banners". */
["voyce_app_banners_order_by"]: {
	active?:ValueTypes["order_by"],
	background?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	link?:ValueTypes["order_by"],
	mobile?:ValueTypes["order_by"],
	overlap?:ValueTypes["order_by"],
	screen?:ValueTypes["order_by"],
	sort?:ValueTypes["order_by"],
	tablet?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.app_banners */
["voyce_app_banners_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.app_banners" */
["voyce_app_banners_select_column"]:voyce_app_banners_select_column;
	/** input type for updating data in table "voyce.app_banners" */
["voyce_app_banners_set_input"]: {
	active?:boolean,
	background?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	link?:string,
	mobile?:string,
	overlap?:boolean,
	screen?:string,
	sort?:ValueTypes["smallint"],
	tablet?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_app_banners_stddev_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_app_banners_stddev_pop_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_app_banners_stddev_samp_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_app_banners" */
["voyce_app_banners_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_app_banners_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_app_banners_stream_cursor_value_input"]: {
	active?:boolean,
	background?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	link?:string,
	mobile?:string,
	overlap?:boolean,
	screen?:string,
	sort?:ValueTypes["smallint"],
	tablet?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_app_banners_sum_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.app_banners" */
["voyce_app_banners_update_column"]:voyce_app_banners_update_column;
	["voyce_app_banners_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_app_banners_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_app_banners_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_app_banners_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_app_banners_var_pop_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_app_banners_var_samp_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_app_banners_variance_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.app_components" */
["voyce_app_components"]: AliasType<{
	active?:true,
	auth?:true,
	component?:true,
	created_at?:true,
	device?:true,
	id?:true,
props?: [{	/** JSON select path */
	path?:string},true],
	screen?:true,
	sort?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.app_components" */
["voyce_app_components_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_app_components_aggregate_fields"],
	nodes?:ValueTypes["voyce_app_components"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.app_components" */
["voyce_app_components_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_app_components_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_app_components_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_app_components_max_fields"],
	min?:ValueTypes["voyce_app_components_min_fields"],
	stddev?:ValueTypes["voyce_app_components_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_app_components_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_app_components_stddev_samp_fields"],
	sum?:ValueTypes["voyce_app_components_sum_fields"],
	var_pop?:ValueTypes["voyce_app_components_var_pop_fields"],
	var_samp?:ValueTypes["voyce_app_components_var_samp_fields"],
	variance?:ValueTypes["voyce_app_components_variance_fields"],
		__typename?: true
}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
["voyce_app_components_append_input"]: {
	props?:ValueTypes["jsonb"]
};
	/** aggregate avg on columns */
["voyce_app_components_avg_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.app_components". All fields are combined with a logical 'AND'. */
["voyce_app_components_bool_exp"]: {
	_and?:ValueTypes["voyce_app_components_bool_exp"][],
	_not?:ValueTypes["voyce_app_components_bool_exp"],
	_or?:ValueTypes["voyce_app_components_bool_exp"][],
	active?:ValueTypes["Boolean_comparison_exp"],
	auth?:ValueTypes["Boolean_comparison_exp"],
	component?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	device?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	props?:ValueTypes["jsonb_comparison_exp"],
	screen?:ValueTypes["String_comparison_exp"],
	sort?:ValueTypes["smallint_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.app_components" */
["voyce_app_components_constraint"]:voyce_app_components_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["voyce_app_components_delete_at_path_input"]: {
	props?:string[]
};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["voyce_app_components_delete_elem_input"]: {
	props?:number
};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["voyce_app_components_delete_key_input"]: {
	props?:string
};
	/** input type for incrementing numeric columns in table "voyce.app_components" */
["voyce_app_components_inc_input"]: {
	id?:number,
	sort?:ValueTypes["smallint"]
};
	/** input type for inserting data into table "voyce.app_components" */
["voyce_app_components_insert_input"]: {
	active?:boolean,
	auth?:boolean,
	component?:string,
	created_at?:ValueTypes["timestamptz"],
	device?:string,
	id?:number,
	props?:ValueTypes["jsonb"],
	screen?:string,
	sort?:ValueTypes["smallint"],
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_app_components_max_fields"]: AliasType<{
	component?:true,
	created_at?:true,
	device?:true,
	id?:true,
	screen?:true,
	sort?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_app_components_min_fields"]: AliasType<{
	component?:true,
	created_at?:true,
	device?:true,
	id?:true,
	screen?:true,
	sort?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.app_components" */
["voyce_app_components_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_app_components"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.app_components" */
["voyce_app_components_on_conflict"]: {
	constraint:ValueTypes["voyce_app_components_constraint"],
	update_columns:ValueTypes["voyce_app_components_update_column"][],
	where?:ValueTypes["voyce_app_components_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.app_components". */
["voyce_app_components_order_by"]: {
	active?:ValueTypes["order_by"],
	auth?:ValueTypes["order_by"],
	component?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	device?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	props?:ValueTypes["order_by"],
	screen?:ValueTypes["order_by"],
	sort?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.app_components */
["voyce_app_components_pk_columns_input"]: {
	id:number
};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["voyce_app_components_prepend_input"]: {
	props?:ValueTypes["jsonb"]
};
	/** select columns of table "voyce.app_components" */
["voyce_app_components_select_column"]:voyce_app_components_select_column;
	/** input type for updating data in table "voyce.app_components" */
["voyce_app_components_set_input"]: {
	active?:boolean,
	auth?:boolean,
	component?:string,
	created_at?:ValueTypes["timestamptz"],
	device?:string,
	id?:number,
	props?:ValueTypes["jsonb"],
	screen?:string,
	sort?:ValueTypes["smallint"],
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_app_components_stddev_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_app_components_stddev_pop_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_app_components_stddev_samp_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_app_components" */
["voyce_app_components_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_app_components_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_app_components_stream_cursor_value_input"]: {
	active?:boolean,
	auth?:boolean,
	component?:string,
	created_at?:ValueTypes["timestamptz"],
	device?:string,
	id?:number,
	props?:ValueTypes["jsonb"],
	screen?:string,
	sort?:ValueTypes["smallint"],
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_app_components_sum_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.app_components" */
["voyce_app_components_update_column"]:voyce_app_components_update_column;
	["voyce_app_components_updates"]: {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["voyce_app_components_append_input"],
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["voyce_app_components_delete_at_path_input"],
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["voyce_app_components_delete_elem_input"],
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["voyce_app_components_delete_key_input"],
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_app_components_inc_input"],
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["voyce_app_components_prepend_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_app_components_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_app_components_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_app_components_var_pop_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_app_components_var_samp_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_app_components_variance_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.audit_trail" */
["voyce_audit_trail"]: AliasType<{
	action?:true,
	created_at?:true,
	id?:true,
	module?:true,
payload?: [{	/** JSON select path */
	path?:string},true],
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.audit_trail" */
["voyce_audit_trail_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_audit_trail_aggregate_fields"],
	nodes?:ValueTypes["voyce_audit_trail"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.audit_trail" */
["voyce_audit_trail_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_audit_trail_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_audit_trail_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_audit_trail_max_fields"],
	min?:ValueTypes["voyce_audit_trail_min_fields"],
	stddev?:ValueTypes["voyce_audit_trail_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_audit_trail_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_audit_trail_stddev_samp_fields"],
	sum?:ValueTypes["voyce_audit_trail_sum_fields"],
	var_pop?:ValueTypes["voyce_audit_trail_var_pop_fields"],
	var_samp?:ValueTypes["voyce_audit_trail_var_samp_fields"],
	variance?:ValueTypes["voyce_audit_trail_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_audit_trail_avg_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.audit_trail". All fields are combined with a logical 'AND'. */
["voyce_audit_trail_bool_exp"]: {
	_and?:ValueTypes["voyce_audit_trail_bool_exp"][],
	_not?:ValueTypes["voyce_audit_trail_bool_exp"],
	_or?:ValueTypes["voyce_audit_trail_bool_exp"][],
	action?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	module?:ValueTypes["String_comparison_exp"],
	payload?:ValueTypes["json_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.audit_trail" */
["voyce_audit_trail_constraint"]:voyce_audit_trail_constraint;
	/** input type for incrementing numeric columns in table "voyce.audit_trail" */
["voyce_audit_trail_inc_input"]: {
	id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.audit_trail" */
["voyce_audit_trail_insert_input"]: {
	action?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	module?:string,
	payload?:ValueTypes["json"],
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_audit_trail_max_fields"]: AliasType<{
	action?:true,
	created_at?:true,
	id?:true,
	module?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_audit_trail_min_fields"]: AliasType<{
	action?:true,
	created_at?:true,
	id?:true,
	module?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.audit_trail" */
["voyce_audit_trail_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_audit_trail"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.audit_trail" */
["voyce_audit_trail_on_conflict"]: {
	constraint:ValueTypes["voyce_audit_trail_constraint"],
	update_columns:ValueTypes["voyce_audit_trail_update_column"][],
	where?:ValueTypes["voyce_audit_trail_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.audit_trail". */
["voyce_audit_trail_order_by"]: {
	action?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	module?:ValueTypes["order_by"],
	payload?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.audit_trail */
["voyce_audit_trail_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.audit_trail" */
["voyce_audit_trail_select_column"]:voyce_audit_trail_select_column;
	/** input type for updating data in table "voyce.audit_trail" */
["voyce_audit_trail_set_input"]: {
	action?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	module?:string,
	payload?:ValueTypes["json"],
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_audit_trail_stddev_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_audit_trail_stddev_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_audit_trail_stddev_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_audit_trail" */
["voyce_audit_trail_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_audit_trail_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_audit_trail_stream_cursor_value_input"]: {
	action?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	module?:string,
	payload?:ValueTypes["json"],
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_audit_trail_sum_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.audit_trail" */
["voyce_audit_trail_update_column"]:voyce_audit_trail_update_column;
	["voyce_audit_trail_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_audit_trail_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_audit_trail_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_audit_trail_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_audit_trail_var_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_audit_trail_var_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_audit_trail_variance_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.author_profiles" */
["voyce_author_profiles"]: AliasType<{
	created_at?:true,
	followers_count?:true,
	id?:true,
	last_activity_date?:true,
	likes_count?:true,
	outdated?:true,
	popularity?:true,
	total_popularity?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
	views_count?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.author_profiles" */
["voyce_author_profiles_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_author_profiles_aggregate_fields"],
	nodes?:ValueTypes["voyce_author_profiles"],
		__typename?: true
}>;
	["voyce_author_profiles_aggregate_bool_exp"]: {
	bool_and?:ValueTypes["voyce_author_profiles_aggregate_bool_exp_bool_and"],
	bool_or?:ValueTypes["voyce_author_profiles_aggregate_bool_exp_bool_or"],
	count?:ValueTypes["voyce_author_profiles_aggregate_bool_exp_count"]
};
	["voyce_author_profiles_aggregate_bool_exp_bool_and"]: {
	arguments:ValueTypes["voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_author_profiles_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_author_profiles_aggregate_bool_exp_bool_or"]: {
	arguments:ValueTypes["voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_author_profiles_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_author_profiles_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_author_profiles_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_author_profiles_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.author_profiles" */
["voyce_author_profiles_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_author_profiles_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_author_profiles_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_author_profiles_max_fields"],
	min?:ValueTypes["voyce_author_profiles_min_fields"],
	stddev?:ValueTypes["voyce_author_profiles_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_author_profiles_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_author_profiles_stddev_samp_fields"],
	sum?:ValueTypes["voyce_author_profiles_sum_fields"],
	var_pop?:ValueTypes["voyce_author_profiles_var_pop_fields"],
	var_samp?:ValueTypes["voyce_author_profiles_var_samp_fields"],
	variance?:ValueTypes["voyce_author_profiles_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.author_profiles" */
["voyce_author_profiles_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_author_profiles_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_author_profiles_max_order_by"],
	min?:ValueTypes["voyce_author_profiles_min_order_by"],
	stddev?:ValueTypes["voyce_author_profiles_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_author_profiles_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_author_profiles_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_author_profiles_sum_order_by"],
	var_pop?:ValueTypes["voyce_author_profiles_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_author_profiles_var_samp_order_by"],
	variance?:ValueTypes["voyce_author_profiles_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.author_profiles" */
["voyce_author_profiles_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_author_profiles_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_author_profiles_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_author_profiles_avg_fields"]: AliasType<{
	followers_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	total_popularity?:true,
	user_id?:true,
	views_count?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_avg_order_by"]: {
	followers_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	views_count?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.author_profiles". All fields are combined with a logical 'AND'. */
["voyce_author_profiles_bool_exp"]: {
	_and?:ValueTypes["voyce_author_profiles_bool_exp"][],
	_not?:ValueTypes["voyce_author_profiles_bool_exp"],
	_or?:ValueTypes["voyce_author_profiles_bool_exp"][],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	followers_count?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	last_activity_date?:ValueTypes["timestamptz_comparison_exp"],
	likes_count?:ValueTypes["Int_comparison_exp"],
	outdated?:ValueTypes["Boolean_comparison_exp"],
	popularity?:ValueTypes["Int_comparison_exp"],
	total_popularity?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	views_count?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.author_profiles" */
["voyce_author_profiles_constraint"]:voyce_author_profiles_constraint;
	/** input type for incrementing numeric columns in table "voyce.author_profiles" */
["voyce_author_profiles_inc_input"]: {
	followers_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	total_popularity?:number,
	user_id?:number,
	views_count?:number
};
	/** input type for inserting data into table "voyce.author_profiles" */
["voyce_author_profiles_insert_input"]: {
	created_at?:ValueTypes["timestamp"],
	followers_count?:number,
	id?:number,
	last_activity_date?:ValueTypes["timestamptz"],
	likes_count?:number,
	outdated?:boolean,
	popularity?:number,
	total_popularity?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number,
	views_count?:number
};
	/** aggregate max on columns */
["voyce_author_profiles_max_fields"]: AliasType<{
	created_at?:true,
	followers_count?:true,
	id?:true,
	last_activity_date?:true,
	likes_count?:true,
	popularity?:true,
	total_popularity?:true,
	user_id?:true,
	views_count?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_max_order_by"]: {
	created_at?:ValueTypes["order_by"],
	followers_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_activity_date?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	views_count?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_author_profiles_min_fields"]: AliasType<{
	created_at?:true,
	followers_count?:true,
	id?:true,
	last_activity_date?:true,
	likes_count?:true,
	popularity?:true,
	total_popularity?:true,
	user_id?:true,
	views_count?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_min_order_by"]: {
	created_at?:ValueTypes["order_by"],
	followers_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_activity_date?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	views_count?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.author_profiles" */
["voyce_author_profiles_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_author_profiles"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.author_profiles" */
["voyce_author_profiles_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_author_profiles_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_author_profiles_on_conflict"]
};
	/** on_conflict condition type for table "voyce.author_profiles" */
["voyce_author_profiles_on_conflict"]: {
	constraint:ValueTypes["voyce_author_profiles_constraint"],
	update_columns:ValueTypes["voyce_author_profiles_update_column"][],
	where?:ValueTypes["voyce_author_profiles_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.author_profiles". */
["voyce_author_profiles_order_by"]: {
	created_at?:ValueTypes["order_by"],
	followers_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_activity_date?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	outdated?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"],
	views_count?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.author_profiles */
["voyce_author_profiles_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.author_profiles" */
["voyce_author_profiles_select_column"]:voyce_author_profiles_select_column;
	/** select "voyce_author_profiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.author_profiles" */
["voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_and_arguments_columns"]:voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_author_profiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.author_profiles" */
["voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_or_arguments_columns"]:voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.author_profiles" */
["voyce_author_profiles_set_input"]: {
	created_at?:ValueTypes["timestamp"],
	followers_count?:number,
	id?:number,
	last_activity_date?:ValueTypes["timestamptz"],
	likes_count?:number,
	outdated?:boolean,
	popularity?:number,
	total_popularity?:number,
	user_id?:number,
	views_count?:number
};
	/** aggregate stddev on columns */
["voyce_author_profiles_stddev_fields"]: AliasType<{
	followers_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	total_popularity?:true,
	user_id?:true,
	views_count?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_stddev_order_by"]: {
	followers_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	views_count?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_author_profiles_stddev_pop_fields"]: AliasType<{
	followers_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	total_popularity?:true,
	user_id?:true,
	views_count?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_stddev_pop_order_by"]: {
	followers_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	views_count?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_author_profiles_stddev_samp_fields"]: AliasType<{
	followers_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	total_popularity?:true,
	user_id?:true,
	views_count?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_stddev_samp_order_by"]: {
	followers_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	views_count?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_author_profiles" */
["voyce_author_profiles_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_author_profiles_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_author_profiles_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamp"],
	followers_count?:number,
	id?:number,
	last_activity_date?:ValueTypes["timestamptz"],
	likes_count?:number,
	outdated?:boolean,
	popularity?:number,
	total_popularity?:number,
	user_id?:number,
	views_count?:number
};
	/** aggregate sum on columns */
["voyce_author_profiles_sum_fields"]: AliasType<{
	followers_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	total_popularity?:true,
	user_id?:true,
	views_count?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_sum_order_by"]: {
	followers_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	views_count?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.author_profiles" */
["voyce_author_profiles_update_column"]:voyce_author_profiles_update_column;
	["voyce_author_profiles_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_author_profiles_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_author_profiles_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_author_profiles_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_author_profiles_var_pop_fields"]: AliasType<{
	followers_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	total_popularity?:true,
	user_id?:true,
	views_count?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_var_pop_order_by"]: {
	followers_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	views_count?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_author_profiles_var_samp_fields"]: AliasType<{
	followers_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	total_popularity?:true,
	user_id?:true,
	views_count?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_var_samp_order_by"]: {
	followers_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	views_count?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_author_profiles_variance_fields"]: AliasType<{
	followers_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	total_popularity?:true,
	user_id?:true,
	views_count?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_variance_order_by"]: {
	followers_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	views_count?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.balances" */
["voyce_balances"]: AliasType<{
	amount?:true,
	created_at?:true,
	id?:true,
	transferable_amount?:true,
	type?:true,
	updated_at?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.balances" */
["voyce_balances_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_balances_aggregate_fields"],
	nodes?:ValueTypes["voyce_balances"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.balances" */
["voyce_balances_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_balances_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_balances_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_balances_max_fields"],
	min?:ValueTypes["voyce_balances_min_fields"],
	stddev?:ValueTypes["voyce_balances_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_balances_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_balances_stddev_samp_fields"],
	sum?:ValueTypes["voyce_balances_sum_fields"],
	var_pop?:ValueTypes["voyce_balances_var_pop_fields"],
	var_samp?:ValueTypes["voyce_balances_var_samp_fields"],
	variance?:ValueTypes["voyce_balances_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_balances_avg_fields"]: AliasType<{
	amount?:true,
	id?:true,
	transferable_amount?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.balances". All fields are combined with a logical 'AND'. */
["voyce_balances_bool_exp"]: {
	_and?:ValueTypes["voyce_balances_bool_exp"][],
	_not?:ValueTypes["voyce_balances_bool_exp"],
	_or?:ValueTypes["voyce_balances_bool_exp"][],
	amount?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	transferable_amount?:ValueTypes["Int_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamp_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.balances" */
["voyce_balances_constraint"]:voyce_balances_constraint;
	/** input type for incrementing numeric columns in table "voyce.balances" */
["voyce_balances_inc_input"]: {
	amount?:number,
	id?:number,
	transferable_amount?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.balances" */
["voyce_balances_insert_input"]: {
	amount?:number,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	transferable_amount?:number,
	type?:string,
	updated_at?:ValueTypes["timestamp"],
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_balances_max_fields"]: AliasType<{
	amount?:true,
	created_at?:true,
	id?:true,
	transferable_amount?:true,
	type?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_balances_min_fields"]: AliasType<{
	amount?:true,
	created_at?:true,
	id?:true,
	transferable_amount?:true,
	type?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.balances" */
["voyce_balances_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_balances"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.balances" */
["voyce_balances_on_conflict"]: {
	constraint:ValueTypes["voyce_balances_constraint"],
	update_columns:ValueTypes["voyce_balances_update_column"][],
	where?:ValueTypes["voyce_balances_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.balances". */
["voyce_balances_order_by"]: {
	amount?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	transferable_amount?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.balances */
["voyce_balances_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.balances" */
["voyce_balances_select_column"]:voyce_balances_select_column;
	/** input type for updating data in table "voyce.balances" */
["voyce_balances_set_input"]: {
	amount?:number,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	transferable_amount?:number,
	type?:string,
	updated_at?:ValueTypes["timestamp"],
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_balances_stddev_fields"]: AliasType<{
	amount?:true,
	id?:true,
	transferable_amount?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_balances_stddev_pop_fields"]: AliasType<{
	amount?:true,
	id?:true,
	transferable_amount?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_balances_stddev_samp_fields"]: AliasType<{
	amount?:true,
	id?:true,
	transferable_amount?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_balances" */
["voyce_balances_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_balances_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_balances_stream_cursor_value_input"]: {
	amount?:number,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	transferable_amount?:number,
	type?:string,
	updated_at?:ValueTypes["timestamp"],
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_balances_sum_fields"]: AliasType<{
	amount?:true,
	id?:true,
	transferable_amount?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.balances" */
["voyce_balances_update_column"]:voyce_balances_update_column;
	["voyce_balances_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_balances_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_balances_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_balances_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_balances_var_pop_fields"]: AliasType<{
	amount?:true,
	id?:true,
	transferable_amount?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_balances_var_samp_fields"]: AliasType<{
	amount?:true,
	id?:true,
	transferable_amount?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_balances_variance_fields"]: AliasType<{
	amount?:true,
	id?:true,
	transferable_amount?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Series Banners */
["voyce_banners"]: AliasType<{
	back_image?:true,
	front_image?:true,
	id?:true,
	order?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	subtitle?:true,
	tag?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.banners" */
["voyce_banners_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_banners_aggregate_fields"],
	nodes?:ValueTypes["voyce_banners"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.banners" */
["voyce_banners_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_banners_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_banners_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_banners_max_fields"],
	min?:ValueTypes["voyce_banners_min_fields"],
	stddev?:ValueTypes["voyce_banners_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_banners_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_banners_stddev_samp_fields"],
	sum?:ValueTypes["voyce_banners_sum_fields"],
	var_pop?:ValueTypes["voyce_banners_var_pop_fields"],
	var_samp?:ValueTypes["voyce_banners_var_samp_fields"],
	variance?:ValueTypes["voyce_banners_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_banners_avg_fields"]: AliasType<{
	id?:true,
	order?:true,
	series_id?:true,
	tag?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.banners". All fields are combined with a logical 'AND'. */
["voyce_banners_bool_exp"]: {
	_and?:ValueTypes["voyce_banners_bool_exp"][],
	_not?:ValueTypes["voyce_banners_bool_exp"],
	_or?:ValueTypes["voyce_banners_bool_exp"][],
	back_image?:ValueTypes["String_comparison_exp"],
	front_image?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	order?:ValueTypes["Int_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	subtitle?:ValueTypes["String_comparison_exp"],
	tag?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.banners" */
["voyce_banners_constraint"]:voyce_banners_constraint;
	/** input type for incrementing numeric columns in table "voyce.banners" */
["voyce_banners_inc_input"]: {
	id?:number,
	order?:number,
	series_id?:number,
	tag?:number
};
	/** input type for inserting data into table "voyce.banners" */
["voyce_banners_insert_input"]: {
	back_image?:string,
	front_image?:string,
	id?:number,
	order?:number,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	subtitle?:string,
	tag?:number
};
	/** aggregate max on columns */
["voyce_banners_max_fields"]: AliasType<{
	back_image?:true,
	front_image?:true,
	id?:true,
	order?:true,
	series_id?:true,
	subtitle?:true,
	tag?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_banners_min_fields"]: AliasType<{
	back_image?:true,
	front_image?:true,
	id?:true,
	order?:true,
	series_id?:true,
	subtitle?:true,
	tag?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.banners" */
["voyce_banners_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_banners"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.banners" */
["voyce_banners_on_conflict"]: {
	constraint:ValueTypes["voyce_banners_constraint"],
	update_columns:ValueTypes["voyce_banners_update_column"][],
	where?:ValueTypes["voyce_banners_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.banners". */
["voyce_banners_order_by"]: {
	back_image?:ValueTypes["order_by"],
	front_image?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	subtitle?:ValueTypes["order_by"],
	tag?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.banners */
["voyce_banners_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.banners" */
["voyce_banners_select_column"]:voyce_banners_select_column;
	/** input type for updating data in table "voyce.banners" */
["voyce_banners_set_input"]: {
	back_image?:string,
	front_image?:string,
	id?:number,
	order?:number,
	series_id?:number,
	subtitle?:string,
	tag?:number
};
	/** aggregate stddev on columns */
["voyce_banners_stddev_fields"]: AliasType<{
	id?:true,
	order?:true,
	series_id?:true,
	tag?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_banners_stddev_pop_fields"]: AliasType<{
	id?:true,
	order?:true,
	series_id?:true,
	tag?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_banners_stddev_samp_fields"]: AliasType<{
	id?:true,
	order?:true,
	series_id?:true,
	tag?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_banners" */
["voyce_banners_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_banners_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_banners_stream_cursor_value_input"]: {
	back_image?:string,
	front_image?:string,
	id?:number,
	order?:number,
	series_id?:number,
	subtitle?:string,
	tag?:number
};
	/** aggregate sum on columns */
["voyce_banners_sum_fields"]: AliasType<{
	id?:true,
	order?:true,
	series_id?:true,
	tag?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.banners" */
["voyce_banners_update_column"]:voyce_banners_update_column;
	["voyce_banners_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_banners_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_banners_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_banners_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_banners_var_pop_fields"]: AliasType<{
	id?:true,
	order?:true,
	series_id?:true,
	tag?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_banners_var_samp_fields"]: AliasType<{
	id?:true,
	order?:true,
	series_id?:true,
	tag?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_banners_variance_fields"]: AliasType<{
	id?:true,
	order?:true,
	series_id?:true,
	tag?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.chapter_assets" */
["voyce_chapter_assets"]: AliasType<{
	/** An object relationship */
	chapter_effect?:ValueTypes["voyce_chapter_effects"],
	chapter_effect_id?:true,
	id?:true,
	order?:true,
	type?:true,
	url?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_assets" */
["voyce_chapter_assets_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_assets_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_assets"],
		__typename?: true
}>;
	["voyce_chapter_assets_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_chapter_assets_aggregate_bool_exp_count"]
};
	["voyce_chapter_assets_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_chapter_assets_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_assets_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_assets" */
["voyce_chapter_assets_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_assets_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_assets_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_assets_max_fields"],
	min?:ValueTypes["voyce_chapter_assets_min_fields"],
	stddev?:ValueTypes["voyce_chapter_assets_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_assets_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_assets_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_assets_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_assets_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_assets_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_assets_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.chapter_assets" */
["voyce_chapter_assets_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_chapter_assets_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_chapter_assets_max_order_by"],
	min?:ValueTypes["voyce_chapter_assets_min_order_by"],
	stddev?:ValueTypes["voyce_chapter_assets_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_chapter_assets_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_chapter_assets_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_chapter_assets_sum_order_by"],
	var_pop?:ValueTypes["voyce_chapter_assets_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_chapter_assets_var_samp_order_by"],
	variance?:ValueTypes["voyce_chapter_assets_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_assets" */
["voyce_chapter_assets_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_assets_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_assets_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_assets_avg_fields"]: AliasType<{
	chapter_effect_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_avg_order_by"]: {
	chapter_effect_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_assets". All fields are combined with a logical 'AND'. */
["voyce_chapter_assets_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_assets_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_assets_bool_exp"],
	_or?:ValueTypes["voyce_chapter_assets_bool_exp"][],
	chapter_effect?:ValueTypes["voyce_chapter_effects_bool_exp"],
	chapter_effect_id?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	order?:ValueTypes["Int_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"],
	url?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_assets" */
["voyce_chapter_assets_constraint"]:voyce_chapter_assets_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_assets" */
["voyce_chapter_assets_inc_input"]: {
	chapter_effect_id?:number,
	id?:number,
	order?:number
};
	/** input type for inserting data into table "voyce.chapter_assets" */
["voyce_chapter_assets_insert_input"]: {
	chapter_effect?:ValueTypes["voyce_chapter_effects_obj_rel_insert_input"],
	chapter_effect_id?:number,
	id?:number,
	order?:number,
	type?:string,
	url?:string
};
	/** aggregate max on columns */
["voyce_chapter_assets_max_fields"]: AliasType<{
	chapter_effect_id?:true,
	id?:true,
	order?:true,
	type?:true,
	url?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_max_order_by"]: {
	chapter_effect_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	url?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_assets_min_fields"]: AliasType<{
	chapter_effect_id?:true,
	id?:true,
	order?:true,
	type?:true,
	url?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_min_order_by"]: {
	chapter_effect_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	url?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_assets" */
["voyce_chapter_assets_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_assets"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.chapter_assets" */
["voyce_chapter_assets_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_assets_constraint"],
	update_columns:ValueTypes["voyce_chapter_assets_update_column"][],
	where?:ValueTypes["voyce_chapter_assets_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_assets". */
["voyce_chapter_assets_order_by"]: {
	chapter_effect?:ValueTypes["voyce_chapter_effects_order_by"],
	chapter_effect_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	url?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_assets */
["voyce_chapter_assets_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_select_column"]:voyce_chapter_assets_select_column;
	/** input type for updating data in table "voyce.chapter_assets" */
["voyce_chapter_assets_set_input"]: {
	chapter_effect_id?:number,
	id?:number,
	order?:number,
	type?:string,
	url?:string
};
	/** aggregate stddev on columns */
["voyce_chapter_assets_stddev_fields"]: AliasType<{
	chapter_effect_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_stddev_order_by"]: {
	chapter_effect_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_assets_stddev_pop_fields"]: AliasType<{
	chapter_effect_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_stddev_pop_order_by"]: {
	chapter_effect_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_assets_stddev_samp_fields"]: AliasType<{
	chapter_effect_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_stddev_samp_order_by"]: {
	chapter_effect_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_assets" */
["voyce_chapter_assets_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_assets_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_assets_stream_cursor_value_input"]: {
	chapter_effect_id?:number,
	id?:number,
	order?:number,
	type?:string,
	url?:string
};
	/** aggregate sum on columns */
["voyce_chapter_assets_sum_fields"]: AliasType<{
	chapter_effect_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_sum_order_by"]: {
	chapter_effect_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_update_column"]:voyce_chapter_assets_update_column;
	["voyce_chapter_assets_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_assets_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_assets_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_assets_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_assets_var_pop_fields"]: AliasType<{
	chapter_effect_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_var_pop_order_by"]: {
	chapter_effect_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_assets_var_samp_fields"]: AliasType<{
	chapter_effect_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_var_samp_order_by"]: {
	chapter_effect_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_assets_variance_fields"]: AliasType<{
	chapter_effect_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_variance_order_by"]: {
	chapter_effect_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	id?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_bookmarks_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_bookmarks"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_bookmarks_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_bookmarks_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_bookmarks_max_fields"],
	min?:ValueTypes["voyce_chapter_bookmarks_min_fields"],
	stddev?:ValueTypes["voyce_chapter_bookmarks_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_bookmarks_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_bookmarks_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_bookmarks_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_bookmarks_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_bookmarks_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_bookmarks_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_chapter_bookmarks_avg_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.chapter_bookmarks". All fields are combined with a logical 'AND'. */
["voyce_chapter_bookmarks_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_bookmarks_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_bookmarks_bool_exp"],
	_or?:ValueTypes["voyce_chapter_bookmarks_bool_exp"][],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_constraint"]:voyce_chapter_bookmarks_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_inc_input"]: {
	chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_insert_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	user_id?:number
};
	/** aggregate max on columns */
["voyce_chapter_bookmarks_max_fields"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_chapter_bookmarks_min_fields"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_bookmarks"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_bookmarks_constraint"],
	update_columns:ValueTypes["voyce_chapter_bookmarks_update_column"][],
	where?:ValueTypes["voyce_chapter_bookmarks_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_bookmarks". */
["voyce_chapter_bookmarks_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_bookmarks */
["voyce_chapter_bookmarks_pk_columns_input"]: {
	chapter_id:number,
	user_id:number
};
	/** select columns of table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_select_column"]:voyce_chapter_bookmarks_select_column;
	/** input type for updating data in table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_set_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_chapter_bookmarks_stddev_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_chapter_bookmarks_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_chapter_bookmarks_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_chapter_bookmarks" */
["voyce_chapter_bookmarks_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_bookmarks_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_bookmarks_stream_cursor_value_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_chapter_bookmarks_sum_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_update_column"]:voyce_chapter_bookmarks_update_column;
	["voyce_chapter_bookmarks_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_bookmarks_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_bookmarks_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_bookmarks_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_bookmarks_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_chapter_bookmarks_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_chapter_bookmarks_variance_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.chapter_comments" */
["voyce_chapter_comments"]: AliasType<{
	animated_image?:true,
	attachment_url?:true,
	/** An object relationship */
	chapter?:ValueTypes["voyce_chapters"],
	/** An object relationship */
	chapter_comment?:ValueTypes["voyce_chapter_comments"],
chapter_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_bool_exp"]},ValueTypes["voyce_chapter_comments"]],
chapter_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_bool_exp"]},ValueTypes["voyce_chapter_comments_aggregate"]],
chapter_comments_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reaction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reaction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reaction_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction"]],
chapter_comments_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reaction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reaction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reaction_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction_aggregate"]],
chapter_comments_reports?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reports_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reports_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reports_bool_exp"]},ValueTypes["voyce_chapter_comments_reports"]],
chapter_comments_reports_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reports_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reports_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reports_bool_exp"]},ValueTypes["voyce_chapter_comments_reports_aggregate"]],
	chapter_id?:true,
	comment?:true,
	created_at?:true,
	id?:true,
	last_reacted_at?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reaction_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reaction_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reaction_count_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction_count"]],
reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reaction_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reaction_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reaction_count_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction_count_aggregate"]],
	replies_count?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024"]: AliasType<{
	animated_image?:true,
	attachment_url?:true,
	chapter_id?:true,
	comment?:true,
	created_at?:true,
	id?:true,
	last_reacted_at?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_comments_07252024_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_comments_07252024"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_comments_07252024_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_comments_07252024_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_comments_07252024_max_fields"],
	min?:ValueTypes["voyce_chapter_comments_07252024_min_fields"],
	stddev?:ValueTypes["voyce_chapter_comments_07252024_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_comments_07252024_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_comments_07252024_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_comments_07252024_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_comments_07252024_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_comments_07252024_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_comments_07252024_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_chapter_comments_07252024_avg_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.chapter_comments_07252024". All fields are combined with a logical 'AND'. */
["voyce_chapter_comments_07252024_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_comments_07252024_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_comments_07252024_bool_exp"],
	_or?:ValueTypes["voyce_chapter_comments_07252024_bool_exp"][],
	animated_image?:ValueTypes["String_comparison_exp"],
	attachment_url?:ValueTypes["String_comparison_exp"],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	comment?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	last_reacted_at?:ValueTypes["timestamptz_comparison_exp"],
	last_reaction_count?:ValueTypes["Int_comparison_exp"],
	original_comment_id?:ValueTypes["Int_comparison_exp"],
	reaction_count?:ValueTypes["Int_comparison_exp"],
	replies_count?:ValueTypes["Int_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** input type for incrementing numeric columns in table "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_inc_input"]: {
	chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_insert_input"]: {
	animated_image?:string,
	attachment_url?:string,
	chapter_id?:number,
	comment?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	last_reacted_at?:ValueTypes["timestamptz"],
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate max on columns */
["voyce_chapter_comments_07252024_max_fields"]: AliasType<{
	animated_image?:true,
	attachment_url?:true,
	chapter_id?:true,
	comment?:true,
	created_at?:true,
	id?:true,
	last_reacted_at?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_chapter_comments_07252024_min_fields"]: AliasType<{
	animated_image?:true,
	attachment_url?:true,
	chapter_id?:true,
	comment?:true,
	created_at?:true,
	id?:true,
	last_reacted_at?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_comments_07252024"],
		__typename?: true
}>;
	/** Ordering options when selecting data from "voyce.chapter_comments_07252024". */
["voyce_chapter_comments_07252024_order_by"]: {
	animated_image?:ValueTypes["order_by"],
	attachment_url?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	comment?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_reacted_at?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	original_comment_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_select_column"]:voyce_chapter_comments_07252024_select_column;
	/** input type for updating data in table "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_set_input"]: {
	animated_image?:string,
	attachment_url?:string,
	chapter_id?:number,
	comment?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	last_reacted_at?:ValueTypes["timestamptz"],
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_chapter_comments_07252024_stddev_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_chapter_comments_07252024_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_chapter_comments_07252024_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_chapter_comments_07252024" */
["voyce_chapter_comments_07252024_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_comments_07252024_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_comments_07252024_stream_cursor_value_input"]: {
	animated_image?:string,
	attachment_url?:string,
	chapter_id?:number,
	comment?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	last_reacted_at?:ValueTypes["timestamptz"],
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_chapter_comments_07252024_sum_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	["voyce_chapter_comments_07252024_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_comments_07252024_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_comments_07252024_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_comments_07252024_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_comments_07252024_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_chapter_comments_07252024_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_chapter_comments_07252024_variance_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_comments" */
["voyce_chapter_comments_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_comments_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_comments"],
		__typename?: true
}>;
	["voyce_chapter_comments_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_chapter_comments_aggregate_bool_exp_count"]
};
	["voyce_chapter_comments_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_chapter_comments_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_comments_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_comments" */
["voyce_chapter_comments_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_comments_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_comments_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_comments_max_fields"],
	min?:ValueTypes["voyce_chapter_comments_min_fields"],
	stddev?:ValueTypes["voyce_chapter_comments_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_comments_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_comments_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_comments_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_comments_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_comments_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_comments_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.chapter_comments" */
["voyce_chapter_comments_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_chapter_comments_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_chapter_comments_max_order_by"],
	min?:ValueTypes["voyce_chapter_comments_min_order_by"],
	stddev?:ValueTypes["voyce_chapter_comments_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_chapter_comments_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_chapter_comments_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_chapter_comments_sum_order_by"],
	var_pop?:ValueTypes["voyce_chapter_comments_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_chapter_comments_var_samp_order_by"],
	variance?:ValueTypes["voyce_chapter_comments_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_comments" */
["voyce_chapter_comments_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_comments_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_comments_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_comments_avg_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_avg_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	original_comment_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_comments". All fields are combined with a logical 'AND'. */
["voyce_chapter_comments_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_comments_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_comments_bool_exp"],
	_or?:ValueTypes["voyce_chapter_comments_bool_exp"][],
	animated_image?:ValueTypes["String_comparison_exp"],
	attachment_url?:ValueTypes["String_comparison_exp"],
	chapter?:ValueTypes["voyce_chapters_bool_exp"],
	chapter_comment?:ValueTypes["voyce_chapter_comments_bool_exp"],
	chapter_comments?:ValueTypes["voyce_chapter_comments_bool_exp"],
	chapter_comments_aggregate?:ValueTypes["voyce_chapter_comments_aggregate_bool_exp"],
	chapter_comments_reactions?:ValueTypes["voyce_chapter_comments_reaction_bool_exp"],
	chapter_comments_reactions_aggregate?:ValueTypes["voyce_chapter_comments_reaction_aggregate_bool_exp"],
	chapter_comments_reports?:ValueTypes["voyce_chapter_comments_reports_bool_exp"],
	chapter_comments_reports_aggregate?:ValueTypes["voyce_chapter_comments_reports_aggregate_bool_exp"],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	comment?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	last_reacted_at?:ValueTypes["timestamptz_comparison_exp"],
	last_reaction_count?:ValueTypes["Int_comparison_exp"],
	original_comment_id?:ValueTypes["Int_comparison_exp"],
	reaction_count?:ValueTypes["Int_comparison_exp"],
	reactions?:ValueTypes["voyce_chapter_comments_reaction_count_bool_exp"],
	reactions_aggregate?:ValueTypes["voyce_chapter_comments_reaction_count_aggregate_bool_exp"],
	replies_count?:ValueTypes["Int_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_comments" */
["voyce_chapter_comments_constraint"]:voyce_chapter_comments_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_comments" */
["voyce_chapter_comments_inc_input"]: {
	chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.chapter_comments" */
["voyce_chapter_comments_insert_input"]: {
	animated_image?:string,
	attachment_url?:string,
	chapter?:ValueTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_comment?:ValueTypes["voyce_chapter_comments_obj_rel_insert_input"],
	chapter_comments?:ValueTypes["voyce_chapter_comments_arr_rel_insert_input"],
	chapter_comments_reactions?:ValueTypes["voyce_chapter_comments_reaction_arr_rel_insert_input"],
	chapter_comments_reports?:ValueTypes["voyce_chapter_comments_reports_arr_rel_insert_input"],
	chapter_id?:number,
	comment?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	last_reacted_at?:ValueTypes["timestamptz"],
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	reactions?:ValueTypes["voyce_chapter_comments_reaction_count_arr_rel_insert_input"],
	replies_count?:number,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_chapter_comments_max_fields"]: AliasType<{
	animated_image?:true,
	attachment_url?:true,
	chapter_id?:true,
	comment?:true,
	created_at?:true,
	id?:true,
	last_reacted_at?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_max_order_by"]: {
	animated_image?:ValueTypes["order_by"],
	attachment_url?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	comment?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_reacted_at?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	original_comment_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_comments_min_fields"]: AliasType<{
	animated_image?:true,
	attachment_url?:true,
	chapter_id?:true,
	comment?:true,
	created_at?:true,
	id?:true,
	last_reacted_at?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_min_order_by"]: {
	animated_image?:ValueTypes["order_by"],
	attachment_url?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	comment?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_reacted_at?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	original_comment_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_comments" */
["voyce_chapter_comments_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_comments"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.chapter_comments" */
["voyce_chapter_comments_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_comments_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_comments_on_conflict"]
};
	/** on_conflict condition type for table "voyce.chapter_comments" */
["voyce_chapter_comments_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_comments_constraint"],
	update_columns:ValueTypes["voyce_chapter_comments_update_column"][],
	where?:ValueTypes["voyce_chapter_comments_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_comments". */
["voyce_chapter_comments_order_by"]: {
	animated_image?:ValueTypes["order_by"],
	attachment_url?:ValueTypes["order_by"],
	chapter?:ValueTypes["voyce_chapters_order_by"],
	chapter_comment?:ValueTypes["voyce_chapter_comments_order_by"],
	chapter_comments_aggregate?:ValueTypes["voyce_chapter_comments_aggregate_order_by"],
	chapter_comments_reactions_aggregate?:ValueTypes["voyce_chapter_comments_reaction_aggregate_order_by"],
	chapter_comments_reports_aggregate?:ValueTypes["voyce_chapter_comments_reports_aggregate_order_by"],
	chapter_id?:ValueTypes["order_by"],
	comment?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_reacted_at?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	original_comment_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	reactions_aggregate?:ValueTypes["voyce_chapter_comments_reaction_count_aggregate_order_by"],
	replies_count?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_comments */
["voyce_chapter_comments_pk_columns_input"]: {
	id:number
};
	/** columns and relationships of "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction"]: AliasType<{
	/** An object relationship */
	chapter_comment?:ValueTypes["voyce_chapter_comments"],
	comment_id?:true,
	created_at?:true,
	id?:true,
	reaction?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_comments_reaction_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_comments_reaction"],
		__typename?: true
}>;
	["voyce_chapter_comments_reaction_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_chapter_comments_reaction_aggregate_bool_exp_count"]
};
	["voyce_chapter_comments_reaction_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_chapter_comments_reaction_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_comments_reaction_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_comments_reaction_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_comments_reaction_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_comments_reaction_max_fields"],
	min?:ValueTypes["voyce_chapter_comments_reaction_min_fields"],
	stddev?:ValueTypes["voyce_chapter_comments_reaction_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_comments_reaction_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_comments_reaction_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_comments_reaction_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_comments_reaction_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_comments_reaction_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_comments_reaction_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_chapter_comments_reaction_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_chapter_comments_reaction_max_order_by"],
	min?:ValueTypes["voyce_chapter_comments_reaction_min_order_by"],
	stddev?:ValueTypes["voyce_chapter_comments_reaction_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_chapter_comments_reaction_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_chapter_comments_reaction_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_chapter_comments_reaction_sum_order_by"],
	var_pop?:ValueTypes["voyce_chapter_comments_reaction_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_chapter_comments_reaction_var_samp_order_by"],
	variance?:ValueTypes["voyce_chapter_comments_reaction_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_comments_reaction_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_comments_reaction_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_comments_reaction_avg_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_avg_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_comments_reaction". All fields are combined with a logical 'AND'. */
["voyce_chapter_comments_reaction_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_comments_reaction_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_comments_reaction_bool_exp"],
	_or?:ValueTypes["voyce_chapter_comments_reaction_bool_exp"][],
	chapter_comment?:ValueTypes["voyce_chapter_comments_bool_exp"],
	comment_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	reaction?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_constraint"]:voyce_chapter_comments_reaction_constraint;
	/** columns and relationships of "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_comments_reaction_count_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_comments_reaction_count"],
		__typename?: true
}>;
	["voyce_chapter_comments_reaction_count_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_chapter_comments_reaction_count_aggregate_bool_exp_count"]
};
	["voyce_chapter_comments_reaction_count_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_chapter_comments_reaction_count_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_comments_reaction_count_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_comments_reaction_count_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_comments_reaction_count_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_comments_reaction_count_max_fields"],
	min?:ValueTypes["voyce_chapter_comments_reaction_count_min_fields"],
	stddev?:ValueTypes["voyce_chapter_comments_reaction_count_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_comments_reaction_count_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_comments_reaction_count_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_comments_reaction_count_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_comments_reaction_count_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_comments_reaction_count_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_comments_reaction_count_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_chapter_comments_reaction_count_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_chapter_comments_reaction_count_max_order_by"],
	min?:ValueTypes["voyce_chapter_comments_reaction_count_min_order_by"],
	stddev?:ValueTypes["voyce_chapter_comments_reaction_count_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_chapter_comments_reaction_count_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_chapter_comments_reaction_count_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_chapter_comments_reaction_count_sum_order_by"],
	var_pop?:ValueTypes["voyce_chapter_comments_reaction_count_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_chapter_comments_reaction_count_var_samp_order_by"],
	variance?:ValueTypes["voyce_chapter_comments_reaction_count_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_comments_reaction_count_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_comments_reaction_count_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_comments_reaction_count_avg_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_avg_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_comments_reaction_count". All fields are combined with a logical 'AND'. */
["voyce_chapter_comments_reaction_count_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_comments_reaction_count_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_comments_reaction_count_bool_exp"],
	_or?:ValueTypes["voyce_chapter_comments_reaction_count_bool_exp"][],
	comment_id?:ValueTypes["Int_comparison_exp"],
	count?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	reaction?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_constraint"]:voyce_chapter_comments_reaction_count_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_inc_input"]: {
	comment_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** input type for inserting data into table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_insert_input"]: {
	comment_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** aggregate max on columns */
["voyce_chapter_comments_reaction_count_max_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_max_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_comments_reaction_count_min_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_min_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_comments_reaction_count"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_comments_reaction_count_constraint"],
	update_columns:ValueTypes["voyce_chapter_comments_reaction_count_update_column"][],
	where?:ValueTypes["voyce_chapter_comments_reaction_count_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_comments_reaction_count". */
["voyce_chapter_comments_reaction_count_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_comments_reaction_count */
["voyce_chapter_comments_reaction_count_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_select_column"]:voyce_chapter_comments_reaction_count_select_column;
	/** input type for updating data in table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_set_input"]: {
	comment_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** aggregate stddev on columns */
["voyce_chapter_comments_reaction_count_stddev_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_stddev_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_comments_reaction_count_stddev_pop_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_stddev_pop_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_comments_reaction_count_stddev_samp_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_stddev_samp_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_comments_reaction_count_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_comments_reaction_count_stream_cursor_value_input"]: {
	comment_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** aggregate sum on columns */
["voyce_chapter_comments_reaction_count_sum_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_sum_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_update_column"]:voyce_chapter_comments_reaction_count_update_column;
	["voyce_chapter_comments_reaction_count_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_comments_reaction_count_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_comments_reaction_count_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_comments_reaction_count_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_comments_reaction_count_var_pop_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_var_pop_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_comments_reaction_count_var_samp_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_var_samp_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_comments_reaction_count_variance_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_variance_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** input type for incrementing numeric columns in table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_inc_input"]: {
	comment_id?:number,
	id?:number,
	reaction?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_insert_input"]: {
	chapter_comment?:ValueTypes["voyce_chapter_comments_obj_rel_insert_input"],
	comment_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	reaction?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_chapter_comments_reaction_max_fields"]: AliasType<{
	comment_id?:true,
	created_at?:true,
	id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_max_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_comments_reaction_min_fields"]: AliasType<{
	comment_id?:true,
	created_at?:true,
	id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_min_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_comments_reaction"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_comments_reaction_constraint"],
	update_columns:ValueTypes["voyce_chapter_comments_reaction_update_column"][],
	where?:ValueTypes["voyce_chapter_comments_reaction_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_comments_reaction". */
["voyce_chapter_comments_reaction_order_by"]: {
	chapter_comment?:ValueTypes["voyce_chapter_comments_order_by"],
	comment_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_comments_reaction */
["voyce_chapter_comments_reaction_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_select_column"]:voyce_chapter_comments_reaction_select_column;
	/** input type for updating data in table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_set_input"]: {
	comment_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	reaction?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_chapter_comments_reaction_stddev_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_stddev_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_comments_reaction_stddev_pop_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_stddev_pop_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_comments_reaction_stddev_samp_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_stddev_samp_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_comments_reaction" */
["voyce_chapter_comments_reaction_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_comments_reaction_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_comments_reaction_stream_cursor_value_input"]: {
	comment_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	reaction?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_chapter_comments_reaction_sum_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_sum_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_update_column"]:voyce_chapter_comments_reaction_update_column;
	["voyce_chapter_comments_reaction_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_comments_reaction_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_comments_reaction_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_comments_reaction_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_comments_reaction_var_pop_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_var_pop_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_comments_reaction_var_samp_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_var_samp_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_comments_reaction_variance_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_variance_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports"]: AliasType<{
	/** An object relationship */
	chapter_comment?:ValueTypes["voyce_chapter_comments"],
	comment_id?:true,
	id?:true,
	message?:true,
	type_id?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_comments_reports_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_comments_reports"],
		__typename?: true
}>;
	["voyce_chapter_comments_reports_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_chapter_comments_reports_aggregate_bool_exp_count"]
};
	["voyce_chapter_comments_reports_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_chapter_comments_reports_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_comments_reports_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_comments_reports_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_comments_reports_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_comments_reports_max_fields"],
	min?:ValueTypes["voyce_chapter_comments_reports_min_fields"],
	stddev?:ValueTypes["voyce_chapter_comments_reports_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_comments_reports_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_comments_reports_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_comments_reports_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_comments_reports_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_comments_reports_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_comments_reports_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_chapter_comments_reports_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_chapter_comments_reports_max_order_by"],
	min?:ValueTypes["voyce_chapter_comments_reports_min_order_by"],
	stddev?:ValueTypes["voyce_chapter_comments_reports_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_chapter_comments_reports_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_chapter_comments_reports_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_chapter_comments_reports_sum_order_by"],
	var_pop?:ValueTypes["voyce_chapter_comments_reports_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_chapter_comments_reports_var_samp_order_by"],
	variance?:ValueTypes["voyce_chapter_comments_reports_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_comments_reports_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_comments_reports_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_comments_reports_avg_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	type_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_avg_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	type_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_comments_reports". All fields are combined with a logical 'AND'. */
["voyce_chapter_comments_reports_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_comments_reports_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_comments_reports_bool_exp"],
	_or?:ValueTypes["voyce_chapter_comments_reports_bool_exp"][],
	chapter_comment?:ValueTypes["voyce_chapter_comments_bool_exp"],
	comment_id?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	message?:ValueTypes["String_comparison_exp"],
	type_id?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_constraint"]:voyce_chapter_comments_reports_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_inc_input"]: {
	comment_id?:number,
	id?:number,
	type_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_insert_input"]: {
	chapter_comment?:ValueTypes["voyce_chapter_comments_obj_rel_insert_input"],
	comment_id?:number,
	id?:number,
	message?:string,
	type_id?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_chapter_comments_reports_max_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	message?:true,
	type_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_max_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	message?:ValueTypes["order_by"],
	type_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_comments_reports_min_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	message?:true,
	type_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_min_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	message?:ValueTypes["order_by"],
	type_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_comments_reports"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_comments_reports_constraint"],
	update_columns:ValueTypes["voyce_chapter_comments_reports_update_column"][],
	where?:ValueTypes["voyce_chapter_comments_reports_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_comments_reports". */
["voyce_chapter_comments_reports_order_by"]: {
	chapter_comment?:ValueTypes["voyce_chapter_comments_order_by"],
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	message?:ValueTypes["order_by"],
	type_id?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_comments_reports */
["voyce_chapter_comments_reports_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_select_column"]:voyce_chapter_comments_reports_select_column;
	/** input type for updating data in table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_set_input"]: {
	comment_id?:number,
	id?:number,
	message?:string,
	type_id?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_chapter_comments_reports_stddev_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	type_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_stddev_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	type_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_comments_reports_stddev_pop_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	type_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_stddev_pop_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	type_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_comments_reports_stddev_samp_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	type_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_stddev_samp_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	type_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_comments_reports" */
["voyce_chapter_comments_reports_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_comments_reports_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_comments_reports_stream_cursor_value_input"]: {
	comment_id?:number,
	id?:number,
	message?:string,
	type_id?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_chapter_comments_reports_sum_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	type_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_sum_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	type_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_update_column"]:voyce_chapter_comments_reports_update_column;
	["voyce_chapter_comments_reports_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_comments_reports_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_comments_reports_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_comments_reports_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_comments_reports_var_pop_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	type_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_var_pop_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	type_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_comments_reports_var_samp_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	type_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_var_samp_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	type_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_comments_reports_variance_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	type_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_variance_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	type_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_select_column"]:voyce_chapter_comments_select_column;
	/** input type for updating data in table "voyce.chapter_comments" */
["voyce_chapter_comments_set_input"]: {
	animated_image?:string,
	attachment_url?:string,
	chapter_id?:number,
	comment?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	last_reacted_at?:ValueTypes["timestamptz"],
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_chapter_comments_stddev_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_stddev_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	original_comment_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_comments_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_stddev_pop_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	original_comment_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_comments_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_stddev_samp_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	original_comment_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_comments" */
["voyce_chapter_comments_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_comments_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_comments_stream_cursor_value_input"]: {
	animated_image?:string,
	attachment_url?:string,
	chapter_id?:number,
	comment?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	last_reacted_at?:ValueTypes["timestamptz"],
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_chapter_comments_sum_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_sum_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	original_comment_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_update_column"]:voyce_chapter_comments_update_column;
	["voyce_chapter_comments_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_comments_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_comments_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_comments_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_comments_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_var_pop_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	original_comment_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_comments_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_var_samp_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	original_comment_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_comments_variance_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	last_reaction_count?:true,
	original_comment_id?:true,
	reaction_count?:true,
	replies_count?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_variance_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	original_comment_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** series novel content for each chapter */
["voyce_chapter_contents"]: AliasType<{
	/** An object relationship */
	chapter?:ValueTypes["voyce_chapters"],
	chapter_id?:true,
	content?:true,
	content_short?:true,
	created_at?:true,
	id?:true,
	mobile_content?:true,
	mobile_content_old?:true,
	order?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_contents" */
["voyce_chapter_contents_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_contents_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_contents"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.chapter_contents" */
["voyce_chapter_contents_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_contents_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_contents_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_contents_max_fields"],
	min?:ValueTypes["voyce_chapter_contents_min_fields"],
	stddev?:ValueTypes["voyce_chapter_contents_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_contents_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_contents_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_contents_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_contents_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_contents_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_contents_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_chapter_contents_avg_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.chapter_contents". All fields are combined with a logical 'AND'. */
["voyce_chapter_contents_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_contents_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_contents_bool_exp"],
	_or?:ValueTypes["voyce_chapter_contents_bool_exp"][],
	chapter?:ValueTypes["voyce_chapters_bool_exp"],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	content?:ValueTypes["String_comparison_exp"],
	content_short?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	mobile_content?:ValueTypes["String_comparison_exp"],
	mobile_content_old?:ValueTypes["String_comparison_exp"],
	order?:ValueTypes["Int_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_contents" */
["voyce_chapter_contents_constraint"]:voyce_chapter_contents_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_contents" */
["voyce_chapter_contents_inc_input"]: {
	chapter_id?:number,
	id?:number,
	order?:number
};
	/** input type for inserting data into table "voyce.chapter_contents" */
["voyce_chapter_contents_insert_input"]: {
	chapter?:ValueTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?:number,
	content?:string,
	content_short?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	mobile_content?:string,
	mobile_content_old?:string,
	order?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_chapter_contents_max_fields"]: AliasType<{
	chapter_id?:true,
	content?:true,
	content_short?:true,
	created_at?:true,
	id?:true,
	mobile_content?:true,
	mobile_content_old?:true,
	order?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_chapter_contents_min_fields"]: AliasType<{
	chapter_id?:true,
	content?:true,
	content_short?:true,
	created_at?:true,
	id?:true,
	mobile_content?:true,
	mobile_content_old?:true,
	order?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.chapter_contents" */
["voyce_chapter_contents_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_contents"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.chapter_contents" */
["voyce_chapter_contents_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_contents_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_contents_on_conflict"]
};
	/** columns and relationships of "voyce.chapter_contents_old" */
["voyce_chapter_contents_old"]: AliasType<{
	/** An object relationship */
	chapter?:ValueTypes["voyce_chapters"],
	chapter_id?:true,
	content?:true,
	created_at?:true,
	id?:true,
	mobile_content?:true,
	mobile_content_old?:true,
	order?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_contents_old_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_contents_old"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_contents_old_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_contents_old_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_contents_old_max_fields"],
	min?:ValueTypes["voyce_chapter_contents_old_min_fields"],
	stddev?:ValueTypes["voyce_chapter_contents_old_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_contents_old_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_contents_old_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_contents_old_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_contents_old_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_contents_old_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_contents_old_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_chapter_contents_old_avg_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.chapter_contents_old". All fields are combined with a logical 'AND'. */
["voyce_chapter_contents_old_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_contents_old_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_contents_old_bool_exp"],
	_or?:ValueTypes["voyce_chapter_contents_old_bool_exp"][],
	chapter?:ValueTypes["voyce_chapters_bool_exp"],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	content?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	mobile_content?:ValueTypes["String_comparison_exp"],
	mobile_content_old?:ValueTypes["String_comparison_exp"],
	order?:ValueTypes["Int_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_constraint"]:voyce_chapter_contents_old_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_inc_input"]: {
	chapter_id?:number,
	id?:number,
	order?:number
};
	/** input type for inserting data into table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_insert_input"]: {
	chapter?:ValueTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?:number,
	content?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	mobile_content?:string,
	mobile_content_old?:string,
	order?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_chapter_contents_old_max_fields"]: AliasType<{
	chapter_id?:true,
	content?:true,
	created_at?:true,
	id?:true,
	mobile_content?:true,
	mobile_content_old?:true,
	order?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_chapter_contents_old_min_fields"]: AliasType<{
	chapter_id?:true,
	content?:true,
	created_at?:true,
	id?:true,
	mobile_content?:true,
	mobile_content_old?:true,
	order?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_contents_old"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_contents_old_constraint"],
	update_columns:ValueTypes["voyce_chapter_contents_old_update_column"][],
	where?:ValueTypes["voyce_chapter_contents_old_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_contents_old". */
["voyce_chapter_contents_old_order_by"]: {
	chapter?:ValueTypes["voyce_chapters_order_by"],
	chapter_id?:ValueTypes["order_by"],
	content?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	mobile_content?:ValueTypes["order_by"],
	mobile_content_old?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_contents_old */
["voyce_chapter_contents_old_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_select_column"]:voyce_chapter_contents_old_select_column;
	/** input type for updating data in table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_set_input"]: {
	chapter_id?:number,
	content?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	mobile_content?:string,
	mobile_content_old?:string,
	order?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_chapter_contents_old_stddev_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_chapter_contents_old_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_chapter_contents_old_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_chapter_contents_old" */
["voyce_chapter_contents_old_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_contents_old_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_contents_old_stream_cursor_value_input"]: {
	chapter_id?:number,
	content?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	mobile_content?:string,
	mobile_content_old?:string,
	order?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_chapter_contents_old_sum_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_update_column"]:voyce_chapter_contents_old_update_column;
	["voyce_chapter_contents_old_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_contents_old_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_contents_old_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_contents_old_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_contents_old_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_chapter_contents_old_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_chapter_contents_old_variance_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.chapter_contents" */
["voyce_chapter_contents_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_contents_constraint"],
	update_columns:ValueTypes["voyce_chapter_contents_update_column"][],
	where?:ValueTypes["voyce_chapter_contents_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_contents". */
["voyce_chapter_contents_order_by"]: {
	chapter?:ValueTypes["voyce_chapters_order_by"],
	chapter_id?:ValueTypes["order_by"],
	content?:ValueTypes["order_by"],
	content_short?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	mobile_content?:ValueTypes["order_by"],
	mobile_content_old?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_contents */
["voyce_chapter_contents_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapter_contents" */
["voyce_chapter_contents_select_column"]:voyce_chapter_contents_select_column;
	/** input type for updating data in table "voyce.chapter_contents" */
["voyce_chapter_contents_set_input"]: {
	chapter_id?:number,
	content?:string,
	content_short?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	mobile_content?:string,
	mobile_content_old?:string,
	order?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_chapter_contents_stddev_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_chapter_contents_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_chapter_contents_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_chapter_contents" */
["voyce_chapter_contents_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_contents_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_contents_stream_cursor_value_input"]: {
	chapter_id?:number,
	content?:string,
	content_short?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	mobile_content?:string,
	mobile_content_old?:string,
	order?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_chapter_contents_sum_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.chapter_contents" */
["voyce_chapter_contents_update_column"]:voyce_chapter_contents_update_column;
	["voyce_chapter_contents_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_contents_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_contents_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_contents_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_contents_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_chapter_contents_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_chapter_contents_variance_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** To match scroll speed: duration = end_x || end_y */
["voyce_chapter_effects"]: AliasType<{
	animation_offset?:true,
	/** An object relationship */
	chapter?:ValueTypes["voyce_chapters"],
chapter_assets?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_assets_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_assets_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_assets_bool_exp"]},ValueTypes["voyce_chapter_assets"]],
chapter_assets_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_assets_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_assets_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_assets_bool_exp"]},ValueTypes["voyce_chapter_assets_aggregate"]],
	chapter_id?:true,
	/** An object relationship */
	chapter_image?:ValueTypes["voyce_chapter_images"],
	chapter_img_id?:true,
	duration?:true,
	effect_type?:true,
	end_x?:true,
	end_y?:true,
	id?:true,
	strength?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_effects" */
["voyce_chapter_effects_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_effects_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_effects"],
		__typename?: true
}>;
	["voyce_chapter_effects_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_chapter_effects_aggregate_bool_exp_count"]
};
	["voyce_chapter_effects_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_chapter_effects_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_effects_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_effects" */
["voyce_chapter_effects_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_effects_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_effects_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_effects_max_fields"],
	min?:ValueTypes["voyce_chapter_effects_min_fields"],
	stddev?:ValueTypes["voyce_chapter_effects_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_effects_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_effects_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_effects_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_effects_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_effects_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_effects_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.chapter_effects" */
["voyce_chapter_effects_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_chapter_effects_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_chapter_effects_max_order_by"],
	min?:ValueTypes["voyce_chapter_effects_min_order_by"],
	stddev?:ValueTypes["voyce_chapter_effects_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_chapter_effects_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_chapter_effects_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_chapter_effects_sum_order_by"],
	var_pop?:ValueTypes["voyce_chapter_effects_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_chapter_effects_var_samp_order_by"],
	variance?:ValueTypes["voyce_chapter_effects_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_effects" */
["voyce_chapter_effects_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_effects_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_effects_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_effects_avg_fields"]: AliasType<{
	animation_offset?:true,
	chapter_id?:true,
	chapter_img_id?:true,
	duration?:true,
	effect_type?:true,
	end_x?:true,
	end_y?:true,
	id?:true,
	strength?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_avg_order_by"]: {
	animation_offset?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	chapter_img_id?:ValueTypes["order_by"],
	duration?:ValueTypes["order_by"],
	effect_type?:ValueTypes["order_by"],
	end_x?:ValueTypes["order_by"],
	end_y?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	strength?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_effects". All fields are combined with a logical 'AND'. */
["voyce_chapter_effects_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_effects_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_effects_bool_exp"],
	_or?:ValueTypes["voyce_chapter_effects_bool_exp"][],
	animation_offset?:ValueTypes["Int_comparison_exp"],
	chapter?:ValueTypes["voyce_chapters_bool_exp"],
	chapter_assets?:ValueTypes["voyce_chapter_assets_bool_exp"],
	chapter_assets_aggregate?:ValueTypes["voyce_chapter_assets_aggregate_bool_exp"],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	chapter_image?:ValueTypes["voyce_chapter_images_bool_exp"],
	chapter_img_id?:ValueTypes["Int_comparison_exp"],
	duration?:ValueTypes["Int_comparison_exp"],
	effect_type?:ValueTypes["Int_comparison_exp"],
	end_x?:ValueTypes["Int_comparison_exp"],
	end_y?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	strength?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_effects" */
["voyce_chapter_effects_constraint"]:voyce_chapter_effects_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_effects" */
["voyce_chapter_effects_inc_input"]: {
	animation_offset?:number,
	chapter_id?:number,
	chapter_img_id?:number,
	duration?:number,
	effect_type?:number,
	end_x?:number,
	end_y?:number,
	id?:number,
	strength?:number
};
	/** input type for inserting data into table "voyce.chapter_effects" */
["voyce_chapter_effects_insert_input"]: {
	animation_offset?:number,
	chapter?:ValueTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_assets?:ValueTypes["voyce_chapter_assets_arr_rel_insert_input"],
	chapter_id?:number,
	chapter_image?:ValueTypes["voyce_chapter_images_obj_rel_insert_input"],
	chapter_img_id?:number,
	duration?:number,
	effect_type?:number,
	end_x?:number,
	end_y?:number,
	id?:number,
	strength?:number
};
	/** aggregate max on columns */
["voyce_chapter_effects_max_fields"]: AliasType<{
	animation_offset?:true,
	chapter_id?:true,
	chapter_img_id?:true,
	duration?:true,
	effect_type?:true,
	end_x?:true,
	end_y?:true,
	id?:true,
	strength?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_max_order_by"]: {
	animation_offset?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	chapter_img_id?:ValueTypes["order_by"],
	duration?:ValueTypes["order_by"],
	effect_type?:ValueTypes["order_by"],
	end_x?:ValueTypes["order_by"],
	end_y?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	strength?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_effects_min_fields"]: AliasType<{
	animation_offset?:true,
	chapter_id?:true,
	chapter_img_id?:true,
	duration?:true,
	effect_type?:true,
	end_x?:true,
	end_y?:true,
	id?:true,
	strength?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_min_order_by"]: {
	animation_offset?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	chapter_img_id?:ValueTypes["order_by"],
	duration?:ValueTypes["order_by"],
	effect_type?:ValueTypes["order_by"],
	end_x?:ValueTypes["order_by"],
	end_y?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	strength?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_effects" */
["voyce_chapter_effects_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_effects"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.chapter_effects" */
["voyce_chapter_effects_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_effects_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_effects_on_conflict"]
};
	/** on_conflict condition type for table "voyce.chapter_effects" */
["voyce_chapter_effects_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_effects_constraint"],
	update_columns:ValueTypes["voyce_chapter_effects_update_column"][],
	where?:ValueTypes["voyce_chapter_effects_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_effects". */
["voyce_chapter_effects_order_by"]: {
	animation_offset?:ValueTypes["order_by"],
	chapter?:ValueTypes["voyce_chapters_order_by"],
	chapter_assets_aggregate?:ValueTypes["voyce_chapter_assets_aggregate_order_by"],
	chapter_id?:ValueTypes["order_by"],
	chapter_image?:ValueTypes["voyce_chapter_images_order_by"],
	chapter_img_id?:ValueTypes["order_by"],
	duration?:ValueTypes["order_by"],
	effect_type?:ValueTypes["order_by"],
	end_x?:ValueTypes["order_by"],
	end_y?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	strength?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_effects */
["voyce_chapter_effects_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_select_column"]:voyce_chapter_effects_select_column;
	/** input type for updating data in table "voyce.chapter_effects" */
["voyce_chapter_effects_set_input"]: {
	animation_offset?:number,
	chapter_id?:number,
	chapter_img_id?:number,
	duration?:number,
	effect_type?:number,
	end_x?:number,
	end_y?:number,
	id?:number,
	strength?:number
};
	/** aggregate stddev on columns */
["voyce_chapter_effects_stddev_fields"]: AliasType<{
	animation_offset?:true,
	chapter_id?:true,
	chapter_img_id?:true,
	duration?:true,
	effect_type?:true,
	end_x?:true,
	end_y?:true,
	id?:true,
	strength?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_stddev_order_by"]: {
	animation_offset?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	chapter_img_id?:ValueTypes["order_by"],
	duration?:ValueTypes["order_by"],
	effect_type?:ValueTypes["order_by"],
	end_x?:ValueTypes["order_by"],
	end_y?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	strength?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_effects_stddev_pop_fields"]: AliasType<{
	animation_offset?:true,
	chapter_id?:true,
	chapter_img_id?:true,
	duration?:true,
	effect_type?:true,
	end_x?:true,
	end_y?:true,
	id?:true,
	strength?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_stddev_pop_order_by"]: {
	animation_offset?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	chapter_img_id?:ValueTypes["order_by"],
	duration?:ValueTypes["order_by"],
	effect_type?:ValueTypes["order_by"],
	end_x?:ValueTypes["order_by"],
	end_y?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	strength?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_effects_stddev_samp_fields"]: AliasType<{
	animation_offset?:true,
	chapter_id?:true,
	chapter_img_id?:true,
	duration?:true,
	effect_type?:true,
	end_x?:true,
	end_y?:true,
	id?:true,
	strength?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_stddev_samp_order_by"]: {
	animation_offset?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	chapter_img_id?:ValueTypes["order_by"],
	duration?:ValueTypes["order_by"],
	effect_type?:ValueTypes["order_by"],
	end_x?:ValueTypes["order_by"],
	end_y?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	strength?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_effects" */
["voyce_chapter_effects_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_effects_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_effects_stream_cursor_value_input"]: {
	animation_offset?:number,
	chapter_id?:number,
	chapter_img_id?:number,
	duration?:number,
	effect_type?:number,
	end_x?:number,
	end_y?:number,
	id?:number,
	strength?:number
};
	/** aggregate sum on columns */
["voyce_chapter_effects_sum_fields"]: AliasType<{
	animation_offset?:true,
	chapter_id?:true,
	chapter_img_id?:true,
	duration?:true,
	effect_type?:true,
	end_x?:true,
	end_y?:true,
	id?:true,
	strength?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_sum_order_by"]: {
	animation_offset?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	chapter_img_id?:ValueTypes["order_by"],
	duration?:ValueTypes["order_by"],
	effect_type?:ValueTypes["order_by"],
	end_x?:ValueTypes["order_by"],
	end_y?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	strength?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_update_column"]:voyce_chapter_effects_update_column;
	["voyce_chapter_effects_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_effects_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_effects_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_effects_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_effects_var_pop_fields"]: AliasType<{
	animation_offset?:true,
	chapter_id?:true,
	chapter_img_id?:true,
	duration?:true,
	effect_type?:true,
	end_x?:true,
	end_y?:true,
	id?:true,
	strength?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_var_pop_order_by"]: {
	animation_offset?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	chapter_img_id?:ValueTypes["order_by"],
	duration?:ValueTypes["order_by"],
	effect_type?:ValueTypes["order_by"],
	end_x?:ValueTypes["order_by"],
	end_y?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	strength?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_effects_var_samp_fields"]: AliasType<{
	animation_offset?:true,
	chapter_id?:true,
	chapter_img_id?:true,
	duration?:true,
	effect_type?:true,
	end_x?:true,
	end_y?:true,
	id?:true,
	strength?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_var_samp_order_by"]: {
	animation_offset?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	chapter_img_id?:ValueTypes["order_by"],
	duration?:ValueTypes["order_by"],
	effect_type?:ValueTypes["order_by"],
	end_x?:ValueTypes["order_by"],
	end_y?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	strength?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_effects_variance_fields"]: AliasType<{
	animation_offset?:true,
	chapter_id?:true,
	chapter_img_id?:true,
	duration?:true,
	effect_type?:true,
	end_x?:true,
	end_y?:true,
	id?:true,
	strength?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_variance_order_by"]: {
	animation_offset?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	chapter_img_id?:ValueTypes["order_by"],
	duration?:ValueTypes["order_by"],
	effect_type?:ValueTypes["order_by"],
	end_x?:ValueTypes["order_by"],
	end_y?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	strength?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_images" */
["voyce_chapter_images"]: AliasType<{
	/** An object relationship */
	chapter?:ValueTypes["voyce_chapters"],
chapter_effects?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_effects_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_effects_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_effects_bool_exp"]},ValueTypes["voyce_chapter_effects"]],
chapter_effects_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_effects_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_effects_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_effects_bool_exp"]},ValueTypes["voyce_chapter_effects_aggregate"]],
	chapter_id?:true,
chapter_panels?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_panels_bool_exp"]},ValueTypes["voyce_chapter_panels"]],
chapter_panels_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_panels_bool_exp"]},ValueTypes["voyce_chapter_panels_aggregate"]],
	created_at?:true,
	group?:true,
	id?:true,
	image?:true,
	sort_order?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	group?:true,
	id?:true,
	image?:true,
	sort_order?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_images_07252024_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_images_07252024"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_images_07252024_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_images_07252024_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_images_07252024_max_fields"],
	min?:ValueTypes["voyce_chapter_images_07252024_min_fields"],
	stddev?:ValueTypes["voyce_chapter_images_07252024_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_images_07252024_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_images_07252024_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_images_07252024_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_images_07252024_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_images_07252024_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_images_07252024_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_chapter_images_07252024_avg_fields"]: AliasType<{
	chapter_id?:true,
	group?:true,
	id?:true,
	sort_order?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.chapter_images_07252024". All fields are combined with a logical 'AND'. */
["voyce_chapter_images_07252024_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_images_07252024_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_images_07252024_bool_exp"],
	_or?:ValueTypes["voyce_chapter_images_07252024_bool_exp"][],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	group?:ValueTypes["smallint_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	image?:ValueTypes["String_comparison_exp"],
	sort_order?:ValueTypes["Int_comparison_exp"],
	updated_at?:ValueTypes["timestamp_comparison_exp"]
};
	/** input type for incrementing numeric columns in table "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_inc_input"]: {
	chapter_id?:number,
	group?:ValueTypes["smallint"],
	id?:number,
	sort_order?:number
};
	/** input type for inserting data into table "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_insert_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamp"],
	group?:ValueTypes["smallint"],
	id?:number,
	image?:string,
	sort_order?:number,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate max on columns */
["voyce_chapter_images_07252024_max_fields"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	group?:true,
	id?:true,
	image?:true,
	sort_order?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_chapter_images_07252024_min_fields"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	group?:true,
	id?:true,
	image?:true,
	sort_order?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_images_07252024"],
		__typename?: true
}>;
	/** Ordering options when selecting data from "voyce.chapter_images_07252024". */
["voyce_chapter_images_07252024_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	group?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	sort_order?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_select_column"]:voyce_chapter_images_07252024_select_column;
	/** input type for updating data in table "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_set_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamp"],
	group?:ValueTypes["smallint"],
	id?:number,
	image?:string,
	sort_order?:number,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate stddev on columns */
["voyce_chapter_images_07252024_stddev_fields"]: AliasType<{
	chapter_id?:true,
	group?:true,
	id?:true,
	sort_order?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_chapter_images_07252024_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	group?:true,
	id?:true,
	sort_order?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_chapter_images_07252024_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	group?:true,
	id?:true,
	sort_order?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_chapter_images_07252024" */
["voyce_chapter_images_07252024_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_images_07252024_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_images_07252024_stream_cursor_value_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamp"],
	group?:ValueTypes["smallint"],
	id?:number,
	image?:string,
	sort_order?:number,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate sum on columns */
["voyce_chapter_images_07252024_sum_fields"]: AliasType<{
	chapter_id?:true,
	group?:true,
	id?:true,
	sort_order?:true,
		__typename?: true
}>;
	["voyce_chapter_images_07252024_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_images_07252024_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_images_07252024_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_images_07252024_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_images_07252024_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	group?:true,
	id?:true,
	sort_order?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_chapter_images_07252024_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	group?:true,
	id?:true,
	sort_order?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_chapter_images_07252024_variance_fields"]: AliasType<{
	chapter_id?:true,
	group?:true,
	id?:true,
	sort_order?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_images" */
["voyce_chapter_images_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_images_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_images"],
		__typename?: true
}>;
	["voyce_chapter_images_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_chapter_images_aggregate_bool_exp_count"]
};
	["voyce_chapter_images_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_chapter_images_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_images_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_images" */
["voyce_chapter_images_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_images_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_images_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_images_max_fields"],
	min?:ValueTypes["voyce_chapter_images_min_fields"],
	stddev?:ValueTypes["voyce_chapter_images_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_images_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_images_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_images_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_images_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_images_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_images_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.chapter_images" */
["voyce_chapter_images_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_chapter_images_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_chapter_images_max_order_by"],
	min?:ValueTypes["voyce_chapter_images_min_order_by"],
	stddev?:ValueTypes["voyce_chapter_images_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_chapter_images_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_chapter_images_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_chapter_images_sum_order_by"],
	var_pop?:ValueTypes["voyce_chapter_images_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_chapter_images_var_samp_order_by"],
	variance?:ValueTypes["voyce_chapter_images_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_images" */
["voyce_chapter_images_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_images_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_images_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_images_avg_fields"]: AliasType<{
	chapter_id?:true,
	group?:true,
	id?:true,
	sort_order?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_avg_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	group?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	sort_order?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_images". All fields are combined with a logical 'AND'. */
["voyce_chapter_images_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_images_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_images_bool_exp"],
	_or?:ValueTypes["voyce_chapter_images_bool_exp"][],
	chapter?:ValueTypes["voyce_chapters_bool_exp"],
	chapter_effects?:ValueTypes["voyce_chapter_effects_bool_exp"],
	chapter_effects_aggregate?:ValueTypes["voyce_chapter_effects_aggregate_bool_exp"],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	chapter_panels?:ValueTypes["voyce_chapter_panels_bool_exp"],
	chapter_panels_aggregate?:ValueTypes["voyce_chapter_panels_aggregate_bool_exp"],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	group?:ValueTypes["smallint_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	image?:ValueTypes["String_comparison_exp"],
	sort_order?:ValueTypes["Int_comparison_exp"],
	updated_at?:ValueTypes["timestamp_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_images" */
["voyce_chapter_images_constraint"]:voyce_chapter_images_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_images" */
["voyce_chapter_images_inc_input"]: {
	chapter_id?:number,
	group?:ValueTypes["smallint"],
	id?:number,
	sort_order?:number
};
	/** input type for inserting data into table "voyce.chapter_images" */
["voyce_chapter_images_insert_input"]: {
	chapter?:ValueTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_effects?:ValueTypes["voyce_chapter_effects_arr_rel_insert_input"],
	chapter_id?:number,
	chapter_panels?:ValueTypes["voyce_chapter_panels_arr_rel_insert_input"],
	created_at?:ValueTypes["timestamp"],
	group?:ValueTypes["smallint"],
	id?:number,
	image?:string,
	sort_order?:number,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate max on columns */
["voyce_chapter_images_max_fields"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	group?:true,
	id?:true,
	image?:true,
	sort_order?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_max_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	group?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	sort_order?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_images_min_fields"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	group?:true,
	id?:true,
	image?:true,
	sort_order?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_min_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	group?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	sort_order?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_images" */
["voyce_chapter_images_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_images"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.chapter_images" */
["voyce_chapter_images_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_images_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_images_on_conflict"]
};
	/** on_conflict condition type for table "voyce.chapter_images" */
["voyce_chapter_images_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_images_constraint"],
	update_columns:ValueTypes["voyce_chapter_images_update_column"][],
	where?:ValueTypes["voyce_chapter_images_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_images". */
["voyce_chapter_images_order_by"]: {
	chapter?:ValueTypes["voyce_chapters_order_by"],
	chapter_effects_aggregate?:ValueTypes["voyce_chapter_effects_aggregate_order_by"],
	chapter_id?:ValueTypes["order_by"],
	chapter_panels_aggregate?:ValueTypes["voyce_chapter_panels_aggregate_order_by"],
	created_at?:ValueTypes["order_by"],
	group?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	sort_order?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_images */
["voyce_chapter_images_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapter_images" */
["voyce_chapter_images_select_column"]:voyce_chapter_images_select_column;
	/** input type for updating data in table "voyce.chapter_images" */
["voyce_chapter_images_set_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamp"],
	group?:ValueTypes["smallint"],
	id?:number,
	image?:string,
	sort_order?:number,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate stddev on columns */
["voyce_chapter_images_stddev_fields"]: AliasType<{
	chapter_id?:true,
	group?:true,
	id?:true,
	sort_order?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_stddev_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	group?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	sort_order?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_images_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	group?:true,
	id?:true,
	sort_order?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_stddev_pop_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	group?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	sort_order?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_images_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	group?:true,
	id?:true,
	sort_order?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_stddev_samp_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	group?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	sort_order?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_images" */
["voyce_chapter_images_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_images_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_images_stream_cursor_value_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamp"],
	group?:ValueTypes["smallint"],
	id?:number,
	image?:string,
	sort_order?:number,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate sum on columns */
["voyce_chapter_images_sum_fields"]: AliasType<{
	chapter_id?:true,
	group?:true,
	id?:true,
	sort_order?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_sum_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	group?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	sort_order?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.chapter_images" */
["voyce_chapter_images_update_column"]:voyce_chapter_images_update_column;
	["voyce_chapter_images_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_images_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_images_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_images_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_images_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	group?:true,
	id?:true,
	sort_order?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_var_pop_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	group?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	sort_order?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_images_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	group?:true,
	id?:true,
	sort_order?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_var_samp_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	group?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	sort_order?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_images_variance_fields"]: AliasType<{
	chapter_id?:true,
	group?:true,
	id?:true,
	sort_order?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_variance_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	group?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	sort_order?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_likes" */
["voyce_chapter_likes"]: AliasType<{
	/** An object relationship */
	chapter?:ValueTypes["voyce_chapters"],
	chapter_id?:true,
	created_at?:true,
	id?:true,
	is_poll_like?:true,
	series_id?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_likes" */
["voyce_chapter_likes_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_likes_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_likes"],
		__typename?: true
}>;
	["voyce_chapter_likes_aggregate_bool_exp"]: {
	bool_and?:ValueTypes["voyce_chapter_likes_aggregate_bool_exp_bool_and"],
	bool_or?:ValueTypes["voyce_chapter_likes_aggregate_bool_exp_bool_or"],
	count?:ValueTypes["voyce_chapter_likes_aggregate_bool_exp_count"]
};
	["voyce_chapter_likes_aggregate_bool_exp_bool_and"]: {
	arguments:ValueTypes["voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_likes_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_chapter_likes_aggregate_bool_exp_bool_or"]: {
	arguments:ValueTypes["voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_likes_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_chapter_likes_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_chapter_likes_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_likes_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_likes" */
["voyce_chapter_likes_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_likes_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_likes_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_likes_max_fields"],
	min?:ValueTypes["voyce_chapter_likes_min_fields"],
	stddev?:ValueTypes["voyce_chapter_likes_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_likes_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_likes_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_likes_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_likes_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_likes_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_likes_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.chapter_likes" */
["voyce_chapter_likes_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_chapter_likes_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_chapter_likes_max_order_by"],
	min?:ValueTypes["voyce_chapter_likes_min_order_by"],
	stddev?:ValueTypes["voyce_chapter_likes_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_chapter_likes_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_chapter_likes_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_chapter_likes_sum_order_by"],
	var_pop?:ValueTypes["voyce_chapter_likes_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_chapter_likes_var_samp_order_by"],
	variance?:ValueTypes["voyce_chapter_likes_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_likes" */
["voyce_chapter_likes_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_likes_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_likes_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_likes_avg_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_avg_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_likes". All fields are combined with a logical 'AND'. */
["voyce_chapter_likes_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_likes_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_likes_bool_exp"],
	_or?:ValueTypes["voyce_chapter_likes_bool_exp"][],
	chapter?:ValueTypes["voyce_chapters_bool_exp"],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_poll_like?:ValueTypes["Boolean_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_likes" */
["voyce_chapter_likes_constraint"]:voyce_chapter_likes_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_likes" */
["voyce_chapter_likes_inc_input"]: {
	chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.chapter_likes" */
["voyce_chapter_likes_insert_input"]: {
	chapter?:ValueTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	is_poll_like?:boolean,
	series_id?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_chapter_likes_max_fields"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_max_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_likes_min_fields"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_min_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_likes" */
["voyce_chapter_likes_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_likes"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.chapter_likes" */
["voyce_chapter_likes_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_likes_constraint"],
	update_columns:ValueTypes["voyce_chapter_likes_update_column"][],
	where?:ValueTypes["voyce_chapter_likes_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_likes". */
["voyce_chapter_likes_order_by"]: {
	chapter?:ValueTypes["voyce_chapters_order_by"],
	chapter_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_poll_like?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_likes */
["voyce_chapter_likes_pk_columns_input"]: {
	chapter_id:number,
	created_at:ValueTypes["timestamptz"]
};
	/** select columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_select_column"]:voyce_chapter_likes_select_column;
	/** select "voyce_chapter_likes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_and_arguments_columns"]:voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_chapter_likes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_or_arguments_columns"]:voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.chapter_likes" */
["voyce_chapter_likes_set_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	is_poll_like?:boolean,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_chapter_likes_stddev_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_stddev_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_likes_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_stddev_pop_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_likes_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_stddev_samp_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_likes" */
["voyce_chapter_likes_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_likes_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_likes_stream_cursor_value_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	is_poll_like?:boolean,
	series_id?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_chapter_likes_sum_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_sum_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_update_column"]:voyce_chapter_likes_update_column;
	["voyce_chapter_likes_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_likes_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_likes_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_likes_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_likes_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_var_pop_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_likes_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_var_samp_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_likes_variance_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_variance_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_locks" */
["voyce_chapter_locks"]: AliasType<{
	buy_currency?:true,
	buy_price?:true,
	/** An object relationship */
	chapter?:ValueTypes["voyce_chapters"],
	chapter_id?:true,
	created_at?:true,
	creator_id?:true,
	end_date?:true,
	id?:true,
	is_active?:true,
	rent_currency?:true,
	rent_duration?:true,
	rent_price?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	start_date?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_locks" */
["voyce_chapter_locks_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_locks_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_locks"],
		__typename?: true
}>;
	["voyce_chapter_locks_aggregate_bool_exp"]: {
	bool_and?:ValueTypes["voyce_chapter_locks_aggregate_bool_exp_bool_and"],
	bool_or?:ValueTypes["voyce_chapter_locks_aggregate_bool_exp_bool_or"],
	count?:ValueTypes["voyce_chapter_locks_aggregate_bool_exp_count"]
};
	["voyce_chapter_locks_aggregate_bool_exp_bool_and"]: {
	arguments:ValueTypes["voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_locks_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_chapter_locks_aggregate_bool_exp_bool_or"]: {
	arguments:ValueTypes["voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_locks_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_chapter_locks_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_chapter_locks_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_locks_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_locks" */
["voyce_chapter_locks_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_locks_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_locks_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_locks_max_fields"],
	min?:ValueTypes["voyce_chapter_locks_min_fields"],
	stddev?:ValueTypes["voyce_chapter_locks_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_locks_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_locks_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_locks_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_locks_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_locks_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_locks_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.chapter_locks" */
["voyce_chapter_locks_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_chapter_locks_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_chapter_locks_max_order_by"],
	min?:ValueTypes["voyce_chapter_locks_min_order_by"],
	stddev?:ValueTypes["voyce_chapter_locks_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_chapter_locks_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_chapter_locks_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_chapter_locks_sum_order_by"],
	var_pop?:ValueTypes["voyce_chapter_locks_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_chapter_locks_var_samp_order_by"],
	variance?:ValueTypes["voyce_chapter_locks_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_locks" */
["voyce_chapter_locks_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_locks_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_locks_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_locks_avg_fields"]: AliasType<{
	buy_price?:true,
	chapter_id?:true,
	creator_id?:true,
	id?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_avg_order_by"]: {
	buy_price?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	creator_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	rent_duration?:ValueTypes["order_by"],
	rent_price?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_locks". All fields are combined with a logical 'AND'. */
["voyce_chapter_locks_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_locks_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_locks_bool_exp"],
	_or?:ValueTypes["voyce_chapter_locks_bool_exp"][],
	buy_currency?:ValueTypes["String_comparison_exp"],
	buy_price?:ValueTypes["numeric_comparison_exp"],
	chapter?:ValueTypes["voyce_chapters_bool_exp"],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	creator_id?:ValueTypes["Int_comparison_exp"],
	end_date?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_active?:ValueTypes["Boolean_comparison_exp"],
	rent_currency?:ValueTypes["String_comparison_exp"],
	rent_duration?:ValueTypes["numeric_comparison_exp"],
	rent_price?:ValueTypes["numeric_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	start_date?:ValueTypes["timestamptz_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_locks" */
["voyce_chapter_locks_constraint"]:voyce_chapter_locks_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_locks" */
["voyce_chapter_locks_inc_input"]: {
	buy_price?:ValueTypes["numeric"],
	chapter_id?:number,
	creator_id?:number,
	id?:number,
	rent_duration?:ValueTypes["numeric"],
	rent_price?:ValueTypes["numeric"],
	series_id?:number
};
	/** input type for inserting data into table "voyce.chapter_locks" */
["voyce_chapter_locks_insert_input"]: {
	buy_currency?:string,
	buy_price?:ValueTypes["numeric"],
	chapter?:ValueTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	creator_id?:number,
	end_date?:ValueTypes["timestamptz"],
	id?:number,
	is_active?:boolean,
	rent_currency?:string,
	rent_duration?:ValueTypes["numeric"],
	rent_price?:ValueTypes["numeric"],
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	start_date?:ValueTypes["timestamptz"],
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_chapter_locks_max_fields"]: AliasType<{
	buy_currency?:true,
	buy_price?:true,
	chapter_id?:true,
	created_at?:true,
	creator_id?:true,
	end_date?:true,
	id?:true,
	rent_currency?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
	start_date?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_max_order_by"]: {
	buy_currency?:ValueTypes["order_by"],
	buy_price?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	creator_id?:ValueTypes["order_by"],
	end_date?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	rent_currency?:ValueTypes["order_by"],
	rent_duration?:ValueTypes["order_by"],
	rent_price?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	start_date?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_locks_min_fields"]: AliasType<{
	buy_currency?:true,
	buy_price?:true,
	chapter_id?:true,
	created_at?:true,
	creator_id?:true,
	end_date?:true,
	id?:true,
	rent_currency?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
	start_date?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_min_order_by"]: {
	buy_currency?:ValueTypes["order_by"],
	buy_price?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	creator_id?:ValueTypes["order_by"],
	end_date?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	rent_currency?:ValueTypes["order_by"],
	rent_duration?:ValueTypes["order_by"],
	rent_price?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	start_date?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_locks" */
["voyce_chapter_locks_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_locks"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.chapter_locks" */
["voyce_chapter_locks_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_locks_constraint"],
	update_columns:ValueTypes["voyce_chapter_locks_update_column"][],
	where?:ValueTypes["voyce_chapter_locks_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_locks". */
["voyce_chapter_locks_order_by"]: {
	buy_currency?:ValueTypes["order_by"],
	buy_price?:ValueTypes["order_by"],
	chapter?:ValueTypes["voyce_chapters_order_by"],
	chapter_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	creator_id?:ValueTypes["order_by"],
	end_date?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_active?:ValueTypes["order_by"],
	rent_currency?:ValueTypes["order_by"],
	rent_duration?:ValueTypes["order_by"],
	rent_price?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	start_date?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_locks */
["voyce_chapter_locks_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_select_column"]:voyce_chapter_locks_select_column;
	/** select "voyce_chapter_locks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_and_arguments_columns"]:voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_chapter_locks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_or_arguments_columns"]:voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.chapter_locks" */
["voyce_chapter_locks_set_input"]: {
	buy_currency?:string,
	buy_price?:ValueTypes["numeric"],
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	creator_id?:number,
	end_date?:ValueTypes["timestamptz"],
	id?:number,
	is_active?:boolean,
	rent_currency?:string,
	rent_duration?:ValueTypes["numeric"],
	rent_price?:ValueTypes["numeric"],
	series_id?:number,
	start_date?:ValueTypes["timestamptz"],
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_chapter_locks_stddev_fields"]: AliasType<{
	buy_price?:true,
	chapter_id?:true,
	creator_id?:true,
	id?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_stddev_order_by"]: {
	buy_price?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	creator_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	rent_duration?:ValueTypes["order_by"],
	rent_price?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_locks_stddev_pop_fields"]: AliasType<{
	buy_price?:true,
	chapter_id?:true,
	creator_id?:true,
	id?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_stddev_pop_order_by"]: {
	buy_price?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	creator_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	rent_duration?:ValueTypes["order_by"],
	rent_price?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_locks_stddev_samp_fields"]: AliasType<{
	buy_price?:true,
	chapter_id?:true,
	creator_id?:true,
	id?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_stddev_samp_order_by"]: {
	buy_price?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	creator_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	rent_duration?:ValueTypes["order_by"],
	rent_price?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_locks" */
["voyce_chapter_locks_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_locks_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_locks_stream_cursor_value_input"]: {
	buy_currency?:string,
	buy_price?:ValueTypes["numeric"],
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	creator_id?:number,
	end_date?:ValueTypes["timestamptz"],
	id?:number,
	is_active?:boolean,
	rent_currency?:string,
	rent_duration?:ValueTypes["numeric"],
	rent_price?:ValueTypes["numeric"],
	series_id?:number,
	start_date?:ValueTypes["timestamptz"],
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_chapter_locks_sum_fields"]: AliasType<{
	buy_price?:true,
	chapter_id?:true,
	creator_id?:true,
	id?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_sum_order_by"]: {
	buy_price?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	creator_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	rent_duration?:ValueTypes["order_by"],
	rent_price?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_update_column"]:voyce_chapter_locks_update_column;
	["voyce_chapter_locks_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_locks_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_locks_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_locks_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_locks_var_pop_fields"]: AliasType<{
	buy_price?:true,
	chapter_id?:true,
	creator_id?:true,
	id?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_var_pop_order_by"]: {
	buy_price?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	creator_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	rent_duration?:ValueTypes["order_by"],
	rent_price?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_locks_var_samp_fields"]: AliasType<{
	buy_price?:true,
	chapter_id?:true,
	creator_id?:true,
	id?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_var_samp_order_by"]: {
	buy_price?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	creator_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	rent_duration?:ValueTypes["order_by"],
	rent_price?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_locks_variance_fields"]: AliasType<{
	buy_price?:true,
	chapter_id?:true,
	creator_id?:true,
	id?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_variance_order_by"]: {
	buy_price?:ValueTypes["order_by"],
	chapter_id?:ValueTypes["order_by"],
	creator_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	rent_duration?:ValueTypes["order_by"],
	rent_price?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_panel_views" */
["voyce_chapter_panel_views"]: AliasType<{
	/** An object relationship */
	chapter?:ValueTypes["voyce_chapters"],
	chapter_id?:true,
	current_panel?:true,
	id?:true,
	panel_id?:true,
	panels?:true,
	percentage?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	total_panels?:true,
	type?:true,
	updated_at?:true,
	user_id?:true,
	word_idx?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_panel_views_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_panel_views"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_panel_views_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_panel_views_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_panel_views_max_fields"],
	min?:ValueTypes["voyce_chapter_panel_views_min_fields"],
	stddev?:ValueTypes["voyce_chapter_panel_views_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_panel_views_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_panel_views_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_panel_views_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_panel_views_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_panel_views_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_panel_views_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_chapter_panel_views_avg_fields"]: AliasType<{
	chapter_id?:true,
	current_panel?:true,
	id?:true,
	panel_id?:true,
	percentage?:true,
	series_id?:true,
	total_panels?:true,
	user_id?:true,
	word_idx?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.chapter_panel_views". All fields are combined with a logical 'AND'. */
["voyce_chapter_panel_views_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_panel_views_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_panel_views_bool_exp"],
	_or?:ValueTypes["voyce_chapter_panel_views_bool_exp"][],
	chapter?:ValueTypes["voyce_chapters_bool_exp"],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	current_panel?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	panel_id?:ValueTypes["Int_comparison_exp"],
	panels?:ValueTypes["bpchar_comparison_exp"],
	percentage?:ValueTypes["Int_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	total_panels?:ValueTypes["Int_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	word_idx?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_constraint"]:voyce_chapter_panel_views_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_inc_input"]: {
	chapter_id?:number,
	current_panel?:number,
	id?:number,
	panel_id?:number,
	percentage?:number,
	series_id?:number,
	total_panels?:number,
	user_id?:number,
	word_idx?:number
};
	/** input type for inserting data into table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_insert_input"]: {
	chapter?:ValueTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?:number,
	current_panel?:number,
	id?:number,
	panel_id?:number,
	panels?:ValueTypes["bpchar"],
	percentage?:number,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	total_panels?:number,
	type?:string,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number,
	word_idx?:number
};
	/** aggregate max on columns */
["voyce_chapter_panel_views_max_fields"]: AliasType<{
	chapter_id?:true,
	current_panel?:true,
	id?:true,
	panel_id?:true,
	panels?:true,
	percentage?:true,
	series_id?:true,
	total_panels?:true,
	type?:true,
	updated_at?:true,
	user_id?:true,
	word_idx?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_chapter_panel_views_min_fields"]: AliasType<{
	chapter_id?:true,
	current_panel?:true,
	id?:true,
	panel_id?:true,
	panels?:true,
	percentage?:true,
	series_id?:true,
	total_panels?:true,
	type?:true,
	updated_at?:true,
	user_id?:true,
	word_idx?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_panel_views"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_panel_views_constraint"],
	update_columns:ValueTypes["voyce_chapter_panel_views_update_column"][],
	where?:ValueTypes["voyce_chapter_panel_views_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_panel_views". */
["voyce_chapter_panel_views_order_by"]: {
	chapter?:ValueTypes["voyce_chapters_order_by"],
	chapter_id?:ValueTypes["order_by"],
	current_panel?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	panel_id?:ValueTypes["order_by"],
	panels?:ValueTypes["order_by"],
	percentage?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	total_panels?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	word_idx?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_panel_views */
["voyce_chapter_panel_views_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_select_column"]:voyce_chapter_panel_views_select_column;
	/** input type for updating data in table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_set_input"]: {
	chapter_id?:number,
	current_panel?:number,
	id?:number,
	panel_id?:number,
	panels?:ValueTypes["bpchar"],
	percentage?:number,
	series_id?:number,
	total_panels?:number,
	type?:string,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number,
	word_idx?:number
};
	/** aggregate stddev on columns */
["voyce_chapter_panel_views_stddev_fields"]: AliasType<{
	chapter_id?:true,
	current_panel?:true,
	id?:true,
	panel_id?:true,
	percentage?:true,
	series_id?:true,
	total_panels?:true,
	user_id?:true,
	word_idx?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_chapter_panel_views_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	current_panel?:true,
	id?:true,
	panel_id?:true,
	percentage?:true,
	series_id?:true,
	total_panels?:true,
	user_id?:true,
	word_idx?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_chapter_panel_views_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	current_panel?:true,
	id?:true,
	panel_id?:true,
	percentage?:true,
	series_id?:true,
	total_panels?:true,
	user_id?:true,
	word_idx?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_chapter_panel_views" */
["voyce_chapter_panel_views_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_panel_views_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_panel_views_stream_cursor_value_input"]: {
	chapter_id?:number,
	current_panel?:number,
	id?:number,
	panel_id?:number,
	panels?:ValueTypes["bpchar"],
	percentage?:number,
	series_id?:number,
	total_panels?:number,
	type?:string,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number,
	word_idx?:number
};
	/** aggregate sum on columns */
["voyce_chapter_panel_views_sum_fields"]: AliasType<{
	chapter_id?:true,
	current_panel?:true,
	id?:true,
	panel_id?:true,
	percentage?:true,
	series_id?:true,
	total_panels?:true,
	user_id?:true,
	word_idx?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_update_column"]:voyce_chapter_panel_views_update_column;
	["voyce_chapter_panel_views_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_panel_views_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_panel_views_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_panel_views_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_panel_views_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	current_panel?:true,
	id?:true,
	panel_id?:true,
	percentage?:true,
	series_id?:true,
	total_panels?:true,
	user_id?:true,
	word_idx?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_chapter_panel_views_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	current_panel?:true,
	id?:true,
	panel_id?:true,
	percentage?:true,
	series_id?:true,
	total_panels?:true,
	user_id?:true,
	word_idx?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_chapter_panel_views_variance_fields"]: AliasType<{
	chapter_id?:true,
	current_panel?:true,
	id?:true,
	panel_id?:true,
	percentage?:true,
	series_id?:true,
	total_panels?:true,
	user_id?:true,
	word_idx?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.chapter_panels" */
["voyce_chapter_panels"]: AliasType<{
	/** An object relationship */
	chapter?:ValueTypes["voyce_chapters"],
	chapter_id?:true,
	comments_count?:true,
	created_at?:true,
	id?:true,
	image?:true,
	image_id?:true,
	last_activity_date?:true,
	likes_count?:true,
	outdated?:true,
panels_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_comments_bool_exp"]},ValueTypes["voyce_panels_comments"]],
panels_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_comments_bool_exp"]},ValueTypes["voyce_panels_comments_aggregate"]],
panels_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_bool_exp"]},ValueTypes["voyce_panels_reactions"]],
panels_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_bool_exp"]},ValueTypes["voyce_panels_reactions_aggregate"]],
panels_reactions_counts?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_reactions_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_reactions_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_count_bool_exp"]},ValueTypes["voyce_panels_reactions_count"]],
panels_reactions_counts_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_reactions_count_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_reactions_count_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_count_bool_exp"]},ValueTypes["voyce_panels_reactions_count_aggregate"]],
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	spoiler?:true,
	total_popularity?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_panels" */
["voyce_chapter_panels_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_panels_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_panels"],
		__typename?: true
}>;
	["voyce_chapter_panels_aggregate_bool_exp"]: {
	bool_and?:ValueTypes["voyce_chapter_panels_aggregate_bool_exp_bool_and"],
	bool_or?:ValueTypes["voyce_chapter_panels_aggregate_bool_exp_bool_or"],
	count?:ValueTypes["voyce_chapter_panels_aggregate_bool_exp_count"]
};
	["voyce_chapter_panels_aggregate_bool_exp_bool_and"]: {
	arguments:ValueTypes["voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_panels_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_chapter_panels_aggregate_bool_exp_bool_or"]: {
	arguments:ValueTypes["voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_panels_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_chapter_panels_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_chapter_panels_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_panels_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_panels" */
["voyce_chapter_panels_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_panels_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_panels_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_panels_max_fields"],
	min?:ValueTypes["voyce_chapter_panels_min_fields"],
	stddev?:ValueTypes["voyce_chapter_panels_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_panels_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_panels_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_panels_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_panels_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_panels_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_panels_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.chapter_panels" */
["voyce_chapter_panels_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_chapter_panels_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_chapter_panels_max_order_by"],
	min?:ValueTypes["voyce_chapter_panels_min_order_by"],
	stddev?:ValueTypes["voyce_chapter_panels_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_chapter_panels_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_chapter_panels_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_chapter_panels_sum_order_by"],
	var_pop?:ValueTypes["voyce_chapter_panels_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_chapter_panels_var_samp_order_by"],
	variance?:ValueTypes["voyce_chapter_panels_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_panels" */
["voyce_chapter_panels_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_panels_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_panels_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_panels_avg_fields"]: AliasType<{
	chapter_id?:true,
	comments_count?:true,
	id?:true,
	image_id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	series_id?:true,
	total_popularity?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_avg_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image_id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_panels". All fields are combined with a logical 'AND'. */
["voyce_chapter_panels_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_panels_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_panels_bool_exp"],
	_or?:ValueTypes["voyce_chapter_panels_bool_exp"][],
	chapter?:ValueTypes["voyce_chapters_bool_exp"],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	comments_count?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	image?:ValueTypes["String_comparison_exp"],
	image_id?:ValueTypes["Int_comparison_exp"],
	last_activity_date?:ValueTypes["timestamptz_comparison_exp"],
	likes_count?:ValueTypes["Int_comparison_exp"],
	outdated?:ValueTypes["Boolean_comparison_exp"],
	panels_comments?:ValueTypes["voyce_panels_comments_bool_exp"],
	panels_comments_aggregate?:ValueTypes["voyce_panels_comments_aggregate_bool_exp"],
	panels_reactions?:ValueTypes["voyce_panels_reactions_bool_exp"],
	panels_reactions_aggregate?:ValueTypes["voyce_panels_reactions_aggregate_bool_exp"],
	panels_reactions_counts?:ValueTypes["voyce_panels_reactions_count_bool_exp"],
	panels_reactions_counts_aggregate?:ValueTypes["voyce_panels_reactions_count_aggregate_bool_exp"],
	popularity?:ValueTypes["Int_comparison_exp"],
	popularity_weekly?:ValueTypes["Int_comparison_exp"],
	reactions_count?:ValueTypes["Int_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	spoiler?:ValueTypes["Boolean_comparison_exp"],
	total_popularity?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_panels" */
["voyce_chapter_panels_constraint"]:voyce_chapter_panels_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_panels" */
["voyce_chapter_panels_inc_input"]: {
	chapter_id?:number,
	comments_count?:number,
	id?:number,
	image_id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	series_id?:number,
	total_popularity?:number
};
	/** input type for inserting data into table "voyce.chapter_panels" */
["voyce_chapter_panels_insert_input"]: {
	chapter?:ValueTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?:number,
	comments_count?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	image?:string,
	image_id?:number,
	last_activity_date?:ValueTypes["timestamptz"],
	likes_count?:number,
	outdated?:boolean,
	panels_comments?:ValueTypes["voyce_panels_comments_arr_rel_insert_input"],
	panels_reactions?:ValueTypes["voyce_panels_reactions_arr_rel_insert_input"],
	panels_reactions_counts?:ValueTypes["voyce_panels_reactions_count_arr_rel_insert_input"],
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	spoiler?:boolean,
	total_popularity?:number
};
	/** aggregate max on columns */
["voyce_chapter_panels_max_fields"]: AliasType<{
	chapter_id?:true,
	comments_count?:true,
	created_at?:true,
	id?:true,
	image?:true,
	image_id?:true,
	last_activity_date?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	series_id?:true,
	total_popularity?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_max_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	comments_count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	image_id?:ValueTypes["order_by"],
	last_activity_date?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_panels_min_fields"]: AliasType<{
	chapter_id?:true,
	comments_count?:true,
	created_at?:true,
	id?:true,
	image?:true,
	image_id?:true,
	last_activity_date?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	series_id?:true,
	total_popularity?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_min_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	comments_count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	image_id?:ValueTypes["order_by"],
	last_activity_date?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_panels" */
["voyce_chapter_panels_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_panels"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.chapter_panels" */
["voyce_chapter_panels_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_panels_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_panels_on_conflict"]
};
	/** on_conflict condition type for table "voyce.chapter_panels" */
["voyce_chapter_panels_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_panels_constraint"],
	update_columns:ValueTypes["voyce_chapter_panels_update_column"][],
	where?:ValueTypes["voyce_chapter_panels_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_panels". */
["voyce_chapter_panels_order_by"]: {
	chapter?:ValueTypes["voyce_chapters_order_by"],
	chapter_id?:ValueTypes["order_by"],
	comments_count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	image_id?:ValueTypes["order_by"],
	last_activity_date?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	outdated?:ValueTypes["order_by"],
	panels_comments_aggregate?:ValueTypes["voyce_panels_comments_aggregate_order_by"],
	panels_reactions_aggregate?:ValueTypes["voyce_panels_reactions_aggregate_order_by"],
	panels_reactions_counts_aggregate?:ValueTypes["voyce_panels_reactions_count_aggregate_order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	spoiler?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_panels */
["voyce_chapter_panels_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_select_column"]:voyce_chapter_panels_select_column;
	/** select "voyce_chapter_panels_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_and_arguments_columns"]:voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_chapter_panels_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_or_arguments_columns"]:voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.chapter_panels" */
["voyce_chapter_panels_set_input"]: {
	chapter_id?:number,
	comments_count?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	image?:string,
	image_id?:number,
	last_activity_date?:ValueTypes["timestamptz"],
	likes_count?:number,
	outdated?:boolean,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	series_id?:number,
	spoiler?:boolean,
	total_popularity?:number
};
	/** aggregate stddev on columns */
["voyce_chapter_panels_stddev_fields"]: AliasType<{
	chapter_id?:true,
	comments_count?:true,
	id?:true,
	image_id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	series_id?:true,
	total_popularity?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_stddev_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image_id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_panels_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	comments_count?:true,
	id?:true,
	image_id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	series_id?:true,
	total_popularity?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_stddev_pop_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image_id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_panels_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	comments_count?:true,
	id?:true,
	image_id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	series_id?:true,
	total_popularity?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_stddev_samp_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image_id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_panels" */
["voyce_chapter_panels_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_panels_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_panels_stream_cursor_value_input"]: {
	chapter_id?:number,
	comments_count?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	image?:string,
	image_id?:number,
	last_activity_date?:ValueTypes["timestamptz"],
	likes_count?:number,
	outdated?:boolean,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	series_id?:number,
	spoiler?:boolean,
	total_popularity?:number
};
	/** aggregate sum on columns */
["voyce_chapter_panels_sum_fields"]: AliasType<{
	chapter_id?:true,
	comments_count?:true,
	id?:true,
	image_id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	series_id?:true,
	total_popularity?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_sum_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image_id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_update_column"]:voyce_chapter_panels_update_column;
	["voyce_chapter_panels_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_panels_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_panels_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_panels_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_panels_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	comments_count?:true,
	id?:true,
	image_id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	series_id?:true,
	total_popularity?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_var_pop_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image_id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_panels_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	comments_count?:true,
	id?:true,
	image_id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	series_id?:true,
	total_popularity?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_var_samp_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image_id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_panels_variance_fields"]: AliasType<{
	chapter_id?:true,
	comments_count?:true,
	id?:true,
	image_id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	series_id?:true,
	total_popularity?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_variance_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image_id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	date?:true,
	/** in seconds */
	epoch?:true,
	gmt_offset?:true,
	id?:true,
	is_done?:true,
	publish_date?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	time?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_publish_schedules_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_publish_schedules"],
		__typename?: true
}>;
	["voyce_chapter_publish_schedules_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_chapter_publish_schedules_aggregate_bool_exp_count"]
};
	["voyce_chapter_publish_schedules_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_chapter_publish_schedules_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_publish_schedules_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_publish_schedules_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_publish_schedules_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_publish_schedules_max_fields"],
	min?:ValueTypes["voyce_chapter_publish_schedules_min_fields"],
	stddev?:ValueTypes["voyce_chapter_publish_schedules_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_publish_schedules_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_publish_schedules_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_publish_schedules_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_publish_schedules_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_publish_schedules_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_publish_schedules_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_chapter_publish_schedules_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_chapter_publish_schedules_max_order_by"],
	min?:ValueTypes["voyce_chapter_publish_schedules_min_order_by"],
	stddev?:ValueTypes["voyce_chapter_publish_schedules_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_chapter_publish_schedules_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_chapter_publish_schedules_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_chapter_publish_schedules_sum_order_by"],
	var_pop?:ValueTypes["voyce_chapter_publish_schedules_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_chapter_publish_schedules_var_samp_order_by"],
	variance?:ValueTypes["voyce_chapter_publish_schedules_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_publish_schedules_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_publish_schedules_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_publish_schedules_avg_fields"]: AliasType<{
	chapter_id?:true,
	/** in seconds */
	epoch?:true,
	gmt_offset?:true,
	id?:true,
	is_done?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_avg_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	/** in seconds */
	epoch?:ValueTypes["order_by"],
	gmt_offset?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_done?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_publish_schedules". All fields are combined with a logical 'AND'. */
["voyce_chapter_publish_schedules_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_publish_schedules_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_publish_schedules_bool_exp"],
	_or?:ValueTypes["voyce_chapter_publish_schedules_bool_exp"][],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	date?:ValueTypes["date_comparison_exp"],
	epoch?:ValueTypes["numeric_comparison_exp"],
	gmt_offset?:ValueTypes["numeric_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_done?:ValueTypes["numeric_comparison_exp"],
	publish_date?:ValueTypes["timestamp_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	time?:ValueTypes["time_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_constraint"]:voyce_chapter_publish_schedules_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_inc_input"]: {
	chapter_id?:number,
	/** in seconds */
	epoch?:ValueTypes["numeric"],
	gmt_offset?:ValueTypes["numeric"],
	id?:number,
	is_done?:ValueTypes["numeric"],
	series_id?:number
};
	/** input type for inserting data into table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_insert_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	date?:ValueTypes["date"],
	/** in seconds */
	epoch?:ValueTypes["numeric"],
	gmt_offset?:ValueTypes["numeric"],
	id?:number,
	is_done?:ValueTypes["numeric"],
	publish_date?:ValueTypes["timestamp"],
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	time?:ValueTypes["time"]
};
	/** aggregate max on columns */
["voyce_chapter_publish_schedules_max_fields"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	date?:true,
	/** in seconds */
	epoch?:true,
	gmt_offset?:true,
	id?:true,
	is_done?:true,
	publish_date?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_max_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	date?:ValueTypes["order_by"],
	/** in seconds */
	epoch?:ValueTypes["order_by"],
	gmt_offset?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_done?:ValueTypes["order_by"],
	publish_date?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_publish_schedules_min_fields"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	date?:true,
	/** in seconds */
	epoch?:true,
	gmt_offset?:true,
	id?:true,
	is_done?:true,
	publish_date?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_min_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	date?:ValueTypes["order_by"],
	/** in seconds */
	epoch?:ValueTypes["order_by"],
	gmt_offset?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_done?:ValueTypes["order_by"],
	publish_date?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_publish_schedules"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_publish_schedules_constraint"],
	update_columns:ValueTypes["voyce_chapter_publish_schedules_update_column"][],
	where?:ValueTypes["voyce_chapter_publish_schedules_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_publish_schedules". */
["voyce_chapter_publish_schedules_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	date?:ValueTypes["order_by"],
	epoch?:ValueTypes["order_by"],
	gmt_offset?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_done?:ValueTypes["order_by"],
	publish_date?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	time?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_publish_schedules */
["voyce_chapter_publish_schedules_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_select_column"]:voyce_chapter_publish_schedules_select_column;
	/** input type for updating data in table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_set_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	date?:ValueTypes["date"],
	/** in seconds */
	epoch?:ValueTypes["numeric"],
	gmt_offset?:ValueTypes["numeric"],
	id?:number,
	is_done?:ValueTypes["numeric"],
	publish_date?:ValueTypes["timestamp"],
	series_id?:number,
	time?:ValueTypes["time"]
};
	/** aggregate stddev on columns */
["voyce_chapter_publish_schedules_stddev_fields"]: AliasType<{
	chapter_id?:true,
	/** in seconds */
	epoch?:true,
	gmt_offset?:true,
	id?:true,
	is_done?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_stddev_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	/** in seconds */
	epoch?:ValueTypes["order_by"],
	gmt_offset?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_done?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_publish_schedules_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	/** in seconds */
	epoch?:true,
	gmt_offset?:true,
	id?:true,
	is_done?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_stddev_pop_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	/** in seconds */
	epoch?:ValueTypes["order_by"],
	gmt_offset?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_done?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_publish_schedules_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	/** in seconds */
	epoch?:true,
	gmt_offset?:true,
	id?:true,
	is_done?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_stddev_samp_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	/** in seconds */
	epoch?:ValueTypes["order_by"],
	gmt_offset?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_done?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_publish_schedules" */
["voyce_chapter_publish_schedules_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_publish_schedules_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_publish_schedules_stream_cursor_value_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	date?:ValueTypes["date"],
	/** in seconds */
	epoch?:ValueTypes["numeric"],
	gmt_offset?:ValueTypes["numeric"],
	id?:number,
	is_done?:ValueTypes["numeric"],
	publish_date?:ValueTypes["timestamp"],
	series_id?:number,
	time?:ValueTypes["time"]
};
	/** aggregate sum on columns */
["voyce_chapter_publish_schedules_sum_fields"]: AliasType<{
	chapter_id?:true,
	/** in seconds */
	epoch?:true,
	gmt_offset?:true,
	id?:true,
	is_done?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_sum_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	/** in seconds */
	epoch?:ValueTypes["order_by"],
	gmt_offset?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_done?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_update_column"]:voyce_chapter_publish_schedules_update_column;
	["voyce_chapter_publish_schedules_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_publish_schedules_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_publish_schedules_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_publish_schedules_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_publish_schedules_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	/** in seconds */
	epoch?:true,
	gmt_offset?:true,
	id?:true,
	is_done?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_var_pop_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	/** in seconds */
	epoch?:ValueTypes["order_by"],
	gmt_offset?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_done?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_publish_schedules_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	/** in seconds */
	epoch?:true,
	gmt_offset?:true,
	id?:true,
	is_done?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_var_samp_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	/** in seconds */
	epoch?:ValueTypes["order_by"],
	gmt_offset?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_done?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_publish_schedules_variance_fields"]: AliasType<{
	chapter_id?:true,
	/** in seconds */
	epoch?:true,
	gmt_offset?:true,
	id?:true,
	is_done?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_variance_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	/** in seconds */
	epoch?:ValueTypes["order_by"],
	gmt_offset?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_done?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_reactions" */
["voyce_chapter_reactions"]: AliasType<{
	/** An object relationship */
	chapter?:ValueTypes["voyce_chapters"],
	created_at?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_reactions" */
["voyce_chapter_reactions_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_reactions_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_reactions"],
		__typename?: true
}>;
	["voyce_chapter_reactions_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_chapter_reactions_aggregate_bool_exp_count"]
};
	["voyce_chapter_reactions_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_chapter_reactions_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_reactions_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_reactions" */
["voyce_chapter_reactions_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_reactions_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_reactions_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_reactions_max_fields"],
	min?:ValueTypes["voyce_chapter_reactions_min_fields"],
	stddev?:ValueTypes["voyce_chapter_reactions_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_reactions_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_reactions_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_reactions_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_reactions_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_reactions_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_reactions_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_chapter_reactions_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_chapter_reactions_max_order_by"],
	min?:ValueTypes["voyce_chapter_reactions_min_order_by"],
	stddev?:ValueTypes["voyce_chapter_reactions_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_chapter_reactions_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_chapter_reactions_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_chapter_reactions_sum_order_by"],
	var_pop?:ValueTypes["voyce_chapter_reactions_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_chapter_reactions_var_samp_order_by"],
	variance?:ValueTypes["voyce_chapter_reactions_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_reactions" */
["voyce_chapter_reactions_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_reactions_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_reactions_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_reactions_avg_fields"]: AliasType<{
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_reactions". All fields are combined with a logical 'AND'. */
["voyce_chapter_reactions_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_reactions_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_reactions_bool_exp"],
	_or?:ValueTypes["voyce_chapter_reactions_bool_exp"][],
	chapter?:ValueTypes["voyce_chapters_bool_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	linked_entity_id?:ValueTypes["Int_comparison_exp"],
	reaction?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_reactions" */
["voyce_chapter_reactions_constraint"]:voyce_chapter_reactions_constraint;
	/** Table to aggregate chapter reactions count by reaction type */
["voyce_chapter_reactions_count"]: AliasType<{
	/** An object relationship */
	chapter?:ValueTypes["voyce_chapters"],
	chapter_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_reactions_count_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_reactions_count"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_reactions_count_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_reactions_count_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_reactions_count_max_fields"],
	min?:ValueTypes["voyce_chapter_reactions_count_min_fields"],
	stddev?:ValueTypes["voyce_chapter_reactions_count_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_reactions_count_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_reactions_count_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_reactions_count_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_reactions_count_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_reactions_count_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_reactions_count_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_chapter_reactions_count_avg_fields"]: AliasType<{
	chapter_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.chapter_reactions_count". All fields are combined with a logical 'AND'. */
["voyce_chapter_reactions_count_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_reactions_count_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_reactions_count_bool_exp"],
	_or?:ValueTypes["voyce_chapter_reactions_count_bool_exp"][],
	chapter?:ValueTypes["voyce_chapters_bool_exp"],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	count?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	reaction?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_constraint"]:voyce_chapter_reactions_count_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_inc_input"]: {
	chapter_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** input type for inserting data into table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_insert_input"]: {
	chapter?:ValueTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** aggregate max on columns */
["voyce_chapter_reactions_count_max_fields"]: AliasType<{
	chapter_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_chapter_reactions_count_min_fields"]: AliasType<{
	chapter_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_reactions_count"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_reactions_count_constraint"],
	update_columns:ValueTypes["voyce_chapter_reactions_count_update_column"][],
	where?:ValueTypes["voyce_chapter_reactions_count_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_reactions_count". */
["voyce_chapter_reactions_count_order_by"]: {
	chapter?:ValueTypes["voyce_chapters_order_by"],
	chapter_id?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_reactions_count */
["voyce_chapter_reactions_count_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_select_column"]:voyce_chapter_reactions_count_select_column;
	/** input type for updating data in table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_set_input"]: {
	chapter_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** aggregate stddev on columns */
["voyce_chapter_reactions_count_stddev_fields"]: AliasType<{
	chapter_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_chapter_reactions_count_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_chapter_reactions_count_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_chapter_reactions_count" */
["voyce_chapter_reactions_count_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_reactions_count_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_reactions_count_stream_cursor_value_input"]: {
	chapter_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** aggregate sum on columns */
["voyce_chapter_reactions_count_sum_fields"]: AliasType<{
	chapter_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_update_column"]:voyce_chapter_reactions_count_update_column;
	["voyce_chapter_reactions_count_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_reactions_count_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_reactions_count_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_reactions_count_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_reactions_count_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_chapter_reactions_count_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_chapter_reactions_count_variance_fields"]: AliasType<{
	chapter_id?:true,
	count?:true,
	id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** input type for incrementing numeric columns in table "voyce.chapter_reactions" */
["voyce_chapter_reactions_inc_input"]: {
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.chapter_reactions" */
["voyce_chapter_reactions_insert_input"]: {
	chapter?:ValueTypes["voyce_chapters_obj_rel_insert_input"],
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_chapter_reactions_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_max_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_reactions_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_min_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_reactions" */
["voyce_chapter_reactions_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_reactions"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.chapter_reactions" */
["voyce_chapter_reactions_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_reactions_constraint"],
	update_columns:ValueTypes["voyce_chapter_reactions_update_column"][],
	where?:ValueTypes["voyce_chapter_reactions_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_reactions". */
["voyce_chapter_reactions_order_by"]: {
	chapter?:ValueTypes["voyce_chapters_order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_reactions */
["voyce_chapter_reactions_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_select_column"]:voyce_chapter_reactions_select_column;
	/** input type for updating data in table "voyce.chapter_reactions" */
["voyce_chapter_reactions_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_chapter_reactions_stddev_fields"]: AliasType<{
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_reactions_stddev_pop_fields"]: AliasType<{
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_reactions_stddev_samp_fields"]: AliasType<{
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_reactions" */
["voyce_chapter_reactions_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_reactions_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_reactions_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_chapter_reactions_sum_fields"]: AliasType<{
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_update_column"]:voyce_chapter_reactions_update_column;
	["voyce_chapter_reactions_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_reactions_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_reactions_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_reactions_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_reactions_var_pop_fields"]: AliasType<{
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_reactions_var_samp_fields"]: AliasType<{
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_reactions_variance_fields"]: AliasType<{
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_view_logs" */
["voyce_chapter_view_logs"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	device_id?:true,
	gtag?:true,
	id?:true,
	origin?:true,
	page?:true,
	panel_id?:true,
	series_id?:true,
	session_api?:true,
	session_id?:true,
	source?:true,
	user_id?:true,
	user_ip?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_view_logs_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_view_logs"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_view_logs_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_view_logs_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_view_logs_max_fields"],
	min?:ValueTypes["voyce_chapter_view_logs_min_fields"],
	stddev?:ValueTypes["voyce_chapter_view_logs_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_view_logs_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_view_logs_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_view_logs_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_view_logs_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_view_logs_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_view_logs_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_chapter_view_logs_avg_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	panel_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.chapter_view_logs". All fields are combined with a logical 'AND'. */
["voyce_chapter_view_logs_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_view_logs_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_view_logs_bool_exp"],
	_or?:ValueTypes["voyce_chapter_view_logs_bool_exp"][],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	device_id?:ValueTypes["String_comparison_exp"],
	gtag?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	origin?:ValueTypes["String_comparison_exp"],
	page?:ValueTypes["String_comparison_exp"],
	panel_id?:ValueTypes["Int_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	session_api?:ValueTypes["String_comparison_exp"],
	session_id?:ValueTypes["String_comparison_exp"],
	source?:ValueTypes["String_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	user_ip?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_constraint"]:voyce_chapter_view_logs_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_inc_input"]: {
	chapter_id?:number,
	id?:number,
	panel_id?:number,
	series_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_insert_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	device_id?:string,
	gtag?:string,
	id?:number,
	origin?:string,
	page?:string,
	panel_id?:number,
	series_id?:number,
	session_api?:string,
	session_id?:string,
	source?:string,
	user_id?:number,
	user_ip?:string
};
	/** aggregate max on columns */
["voyce_chapter_view_logs_max_fields"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	device_id?:true,
	gtag?:true,
	id?:true,
	origin?:true,
	page?:true,
	panel_id?:true,
	series_id?:true,
	session_api?:true,
	session_id?:true,
	source?:true,
	user_id?:true,
	user_ip?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_chapter_view_logs_min_fields"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	device_id?:true,
	gtag?:true,
	id?:true,
	origin?:true,
	page?:true,
	panel_id?:true,
	series_id?:true,
	session_api?:true,
	session_id?:true,
	source?:true,
	user_id?:true,
	user_ip?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_view_logs"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_view_logs_constraint"],
	update_columns:ValueTypes["voyce_chapter_view_logs_update_column"][],
	where?:ValueTypes["voyce_chapter_view_logs_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_view_logs". */
["voyce_chapter_view_logs_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	device_id?:ValueTypes["order_by"],
	gtag?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	origin?:ValueTypes["order_by"],
	page?:ValueTypes["order_by"],
	panel_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	session_api?:ValueTypes["order_by"],
	session_id?:ValueTypes["order_by"],
	source?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	user_ip?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_view_logs */
["voyce_chapter_view_logs_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_select_column"]:voyce_chapter_view_logs_select_column;
	/** input type for updating data in table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_set_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	device_id?:string,
	gtag?:string,
	id?:number,
	origin?:string,
	page?:string,
	panel_id?:number,
	series_id?:number,
	session_api?:string,
	session_id?:string,
	source?:string,
	user_id?:number,
	user_ip?:string
};
	/** aggregate stddev on columns */
["voyce_chapter_view_logs_stddev_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	panel_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_chapter_view_logs_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	panel_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_chapter_view_logs_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	panel_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_chapter_view_logs" */
["voyce_chapter_view_logs_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_view_logs_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_view_logs_stream_cursor_value_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	device_id?:string,
	gtag?:string,
	id?:number,
	origin?:string,
	page?:string,
	panel_id?:number,
	series_id?:number,
	session_api?:string,
	session_id?:string,
	source?:string,
	user_id?:number,
	user_ip?:string
};
	/** aggregate sum on columns */
["voyce_chapter_view_logs_sum_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	panel_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_update_column"]:voyce_chapter_view_logs_update_column;
	["voyce_chapter_view_logs_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_view_logs_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_view_logs_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_view_logs_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_view_logs_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	panel_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_chapter_view_logs_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	panel_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_chapter_view_logs_variance_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	panel_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.chapter_views" */
["voyce_chapter_views"]: AliasType<{
	chapter_id?:true,
	id?:true,
	is_read?:true,
	page?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	updated_at?:true,
	user_id?:true,
	user_ip?:true,
	view_hash?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapter_views" */
["voyce_chapter_views_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapter_views_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapter_views"],
		__typename?: true
}>;
	["voyce_chapter_views_aggregate_bool_exp"]: {
	bool_and?:ValueTypes["voyce_chapter_views_aggregate_bool_exp_bool_and"],
	bool_or?:ValueTypes["voyce_chapter_views_aggregate_bool_exp_bool_or"],
	count?:ValueTypes["voyce_chapter_views_aggregate_bool_exp_count"]
};
	["voyce_chapter_views_aggregate_bool_exp_bool_and"]: {
	arguments:ValueTypes["voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_views_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_chapter_views_aggregate_bool_exp_bool_or"]: {
	arguments:ValueTypes["voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_views_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_chapter_views_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_chapter_views_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapter_views_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_views" */
["voyce_chapter_views_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapter_views_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapter_views_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapter_views_max_fields"],
	min?:ValueTypes["voyce_chapter_views_min_fields"],
	stddev?:ValueTypes["voyce_chapter_views_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapter_views_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapter_views_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapter_views_sum_fields"],
	var_pop?:ValueTypes["voyce_chapter_views_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapter_views_var_samp_fields"],
	variance?:ValueTypes["voyce_chapter_views_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.chapter_views" */
["voyce_chapter_views_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_chapter_views_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_chapter_views_max_order_by"],
	min?:ValueTypes["voyce_chapter_views_min_order_by"],
	stddev?:ValueTypes["voyce_chapter_views_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_chapter_views_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_chapter_views_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_chapter_views_sum_order_by"],
	var_pop?:ValueTypes["voyce_chapter_views_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_chapter_views_var_samp_order_by"],
	variance?:ValueTypes["voyce_chapter_views_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_views" */
["voyce_chapter_views_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_chapter_views_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapter_views_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_views_avg_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	user_ip?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_avg_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	user_ip?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_views". All fields are combined with a logical 'AND'. */
["voyce_chapter_views_bool_exp"]: {
	_and?:ValueTypes["voyce_chapter_views_bool_exp"][],
	_not?:ValueTypes["voyce_chapter_views_bool_exp"],
	_or?:ValueTypes["voyce_chapter_views_bool_exp"][],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_read?:ValueTypes["Boolean_comparison_exp"],
	page?:ValueTypes["String_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	updated_at?:ValueTypes["timestamp_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	user_ip?:ValueTypes["bigint_comparison_exp"],
	view_hash?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_views" */
["voyce_chapter_views_constraint"]:voyce_chapter_views_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_views" */
["voyce_chapter_views_inc_input"]: {
	chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	user_ip?:ValueTypes["bigint"]
};
	/** input type for inserting data into table "voyce.chapter_views" */
["voyce_chapter_views_insert_input"]: {
	chapter_id?:number,
	id?:number,
	is_read?:boolean,
	page?:string,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	updated_at?:ValueTypes["timestamp"],
	user_id?:number,
	user_ip?:ValueTypes["bigint"],
	view_hash?:string
};
	/** aggregate max on columns */
["voyce_chapter_views_max_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	page?:true,
	series_id?:true,
	updated_at?:true,
	user_id?:true,
	user_ip?:true,
	view_hash?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_max_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	page?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	user_ip?:ValueTypes["order_by"],
	view_hash?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_views_min_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	page?:true,
	series_id?:true,
	updated_at?:true,
	user_id?:true,
	user_ip?:true,
	view_hash?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_min_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	page?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	user_ip?:ValueTypes["order_by"],
	view_hash?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_views" */
["voyce_chapter_views_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapter_views"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.chapter_views" */
["voyce_chapter_views_on_conflict"]: {
	constraint:ValueTypes["voyce_chapter_views_constraint"],
	update_columns:ValueTypes["voyce_chapter_views_update_column"][],
	where?:ValueTypes["voyce_chapter_views_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_views". */
["voyce_chapter_views_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_read?:ValueTypes["order_by"],
	page?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	user_ip?:ValueTypes["order_by"],
	view_hash?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_views */
["voyce_chapter_views_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapter_views" */
["voyce_chapter_views_select_column"]:voyce_chapter_views_select_column;
	/** select "voyce_chapter_views_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapter_views" */
["voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_and_arguments_columns"]:voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_chapter_views_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapter_views" */
["voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_or_arguments_columns"]:voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.chapter_views" */
["voyce_chapter_views_set_input"]: {
	chapter_id?:number,
	id?:number,
	is_read?:boolean,
	page?:string,
	series_id?:number,
	updated_at?:ValueTypes["timestamp"],
	user_id?:number,
	user_ip?:ValueTypes["bigint"],
	view_hash?:string
};
	/** aggregate stddev on columns */
["voyce_chapter_views_stddev_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	user_ip?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_stddev_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	user_ip?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_views_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	user_ip?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_stddev_pop_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	user_ip?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_views_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	user_ip?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_stddev_samp_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	user_ip?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_views" */
["voyce_chapter_views_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapter_views_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_views_stream_cursor_value_input"]: {
	chapter_id?:number,
	id?:number,
	is_read?:boolean,
	page?:string,
	series_id?:number,
	updated_at?:ValueTypes["timestamp"],
	user_id?:number,
	user_ip?:ValueTypes["bigint"],
	view_hash?:string
};
	/** aggregate sum on columns */
["voyce_chapter_views_sum_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	user_ip?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_sum_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	user_ip?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.chapter_views" */
["voyce_chapter_views_update_column"]:voyce_chapter_views_update_column;
	["voyce_chapter_views_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapter_views_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapter_views_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapter_views_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_views_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	user_ip?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_var_pop_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	user_ip?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_views_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	user_ip?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_var_samp_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	user_ip?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_views_variance_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	user_ip?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_variance_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	user_ip?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.chapters" */
["voyce_chapters"]: AliasType<{
chapter_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_bool_exp"]},ValueTypes["voyce_chapter_comments"]],
chapter_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_bool_exp"]},ValueTypes["voyce_chapter_comments_aggregate"]],
chapter_effects?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_effects_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_effects_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_effects_bool_exp"]},ValueTypes["voyce_chapter_effects"]],
chapter_effects_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_effects_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_effects_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_effects_bool_exp"]},ValueTypes["voyce_chapter_effects_aggregate"]],
chapter_panels?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_panels_bool_exp"]},ValueTypes["voyce_chapter_panels"]],
chapter_panels_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_panels_bool_exp"]},ValueTypes["voyce_chapter_panels_aggregate"]],
chapter_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_reactions_bool_exp"]},ValueTypes["voyce_chapter_reactions"]],
chapter_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_reactions_bool_exp"]},ValueTypes["voyce_chapter_reactions_aggregate"]],
	/** An object relationship */
	content?:ValueTypes["voyce_chapter_contents"],
	created_at?:true,
	description?:true,
	description_old?:true,
	displayed_exclusive?:true,
	formatted_content?:true,
	formatted_content_v2?:true,
	formatted_content_v3?:true,
	has_text?:true,
	id?:true,
images?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_images_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_images_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_images_bool_exp"]},ValueTypes["voyce_chapter_images"]],
images_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_images_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_images_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_images_bool_exp"]},ValueTypes["voyce_chapter_images_aggregate"]],
	is_deleted?:true,
likes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_likes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_likes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_likes_bool_exp"]},ValueTypes["voyce_chapter_likes"]],
likes_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_likes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_likes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_likes_bool_exp"]},ValueTypes["voyce_chapter_likes_aggregate"]],
locks?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_locks_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_locks_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_locks_bool_exp"]},ValueTypes["voyce_chapter_locks"]],
locks_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_locks_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_locks_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_locks_bool_exp"]},ValueTypes["voyce_chapter_locks_aggregate"]],
	order?:true,
	publish?:true,
	publish_date?:true,
	reactions_count?:true,
	reading_view?:true,
schedule?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_publish_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_publish_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_publish_schedules_bool_exp"]},ValueTypes["voyce_chapter_publish_schedules"]],
schedule_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_publish_schedules_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_publish_schedules_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_publish_schedules_bool_exp"]},ValueTypes["voyce_chapter_publish_schedules_aggregate"]],
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	status?:true,
	thumbnail?:true,
	title?:true,
	type?:true,
	updated_at?:true,
	view_count?:true,
views?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_views_bool_exp"]},ValueTypes["voyce_chapter_views"]],
views_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_views_bool_exp"]},ValueTypes["voyce_chapter_views_aggregate"]],
		__typename?: true
}>;
	/** columns and relationships of "voyce.chapters_07252024" */
["voyce_chapters_07252024"]: AliasType<{
	created_at?:true,
	description?:true,
	description_old?:true,
	displayed_exclusive?:true,
	formatted_content?:true,
	formatted_content_v2?:true,
	formatted_content_v3?:true,
	has_text?:true,
	id?:true,
	is_deleted?:true,
	order?:true,
	publish?:true,
	publish_date?:true,
	reactions_count?:true,
	reading_view?:true,
	series_id?:true,
	status?:true,
	thumbnail?:true,
	title?:true,
	type?:true,
	updated_at?:true,
	view_count?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapters_07252024" */
["voyce_chapters_07252024_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapters_07252024_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapters_07252024"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.chapters_07252024" */
["voyce_chapters_07252024_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapters_07252024_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapters_07252024_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapters_07252024_max_fields"],
	min?:ValueTypes["voyce_chapters_07252024_min_fields"],
	stddev?:ValueTypes["voyce_chapters_07252024_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapters_07252024_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapters_07252024_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapters_07252024_sum_fields"],
	var_pop?:ValueTypes["voyce_chapters_07252024_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapters_07252024_var_samp_fields"],
	variance?:ValueTypes["voyce_chapters_07252024_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_chapters_07252024_avg_fields"]: AliasType<{
	id?:true,
	order?:true,
	publish?:true,
	reactions_count?:true,
	series_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.chapters_07252024". All fields are combined with a logical 'AND'. */
["voyce_chapters_07252024_bool_exp"]: {
	_and?:ValueTypes["voyce_chapters_07252024_bool_exp"][],
	_not?:ValueTypes["voyce_chapters_07252024_bool_exp"],
	_or?:ValueTypes["voyce_chapters_07252024_bool_exp"][],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	description?:ValueTypes["String_comparison_exp"],
	description_old?:ValueTypes["String_comparison_exp"],
	displayed_exclusive?:ValueTypes["Boolean_comparison_exp"],
	formatted_content?:ValueTypes["String_comparison_exp"],
	formatted_content_v2?:ValueTypes["String_comparison_exp"],
	formatted_content_v3?:ValueTypes["String_comparison_exp"],
	has_text?:ValueTypes["Boolean_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_deleted?:ValueTypes["Boolean_comparison_exp"],
	order?:ValueTypes["smallint_comparison_exp"],
	publish?:ValueTypes["smallint_comparison_exp"],
	publish_date?:ValueTypes["timestamptz_comparison_exp"],
	reactions_count?:ValueTypes["Int_comparison_exp"],
	reading_view?:ValueTypes["String_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	status?:ValueTypes["String_comparison_exp"],
	thumbnail?:ValueTypes["String_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	view_count?:ValueTypes["Int_comparison_exp"]
};
	/** input type for incrementing numeric columns in table "voyce.chapters_07252024" */
["voyce_chapters_07252024_inc_input"]: {
	id?:number,
	order?:ValueTypes["smallint"],
	publish?:ValueTypes["smallint"],
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** input type for inserting data into table "voyce.chapters_07252024" */
["voyce_chapters_07252024_insert_input"]: {
	created_at?:ValueTypes["timestamp"],
	description?:string,
	description_old?:string,
	displayed_exclusive?:boolean,
	formatted_content?:string,
	formatted_content_v2?:string,
	formatted_content_v3?:string,
	has_text?:boolean,
	id?:number,
	is_deleted?:boolean,
	order?:ValueTypes["smallint"],
	publish?:ValueTypes["smallint"],
	publish_date?:ValueTypes["timestamptz"],
	reactions_count?:number,
	reading_view?:string,
	series_id?:number,
	status?:string,
	thumbnail?:string,
	title?:string,
	type?:string,
	updated_at?:ValueTypes["timestamptz"],
	view_count?:number
};
	/** aggregate max on columns */
["voyce_chapters_07252024_max_fields"]: AliasType<{
	created_at?:true,
	description?:true,
	description_old?:true,
	formatted_content?:true,
	formatted_content_v2?:true,
	formatted_content_v3?:true,
	id?:true,
	order?:true,
	publish?:true,
	publish_date?:true,
	reactions_count?:true,
	reading_view?:true,
	series_id?:true,
	status?:true,
	thumbnail?:true,
	title?:true,
	type?:true,
	updated_at?:true,
	view_count?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_chapters_07252024_min_fields"]: AliasType<{
	created_at?:true,
	description?:true,
	description_old?:true,
	formatted_content?:true,
	formatted_content_v2?:true,
	formatted_content_v3?:true,
	id?:true,
	order?:true,
	publish?:true,
	publish_date?:true,
	reactions_count?:true,
	reading_view?:true,
	series_id?:true,
	status?:true,
	thumbnail?:true,
	title?:true,
	type?:true,
	updated_at?:true,
	view_count?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.chapters_07252024" */
["voyce_chapters_07252024_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapters_07252024"],
		__typename?: true
}>;
	/** Ordering options when selecting data from "voyce.chapters_07252024". */
["voyce_chapters_07252024_order_by"]: {
	created_at?:ValueTypes["order_by"],
	description?:ValueTypes["order_by"],
	description_old?:ValueTypes["order_by"],
	displayed_exclusive?:ValueTypes["order_by"],
	formatted_content?:ValueTypes["order_by"],
	formatted_content_v2?:ValueTypes["order_by"],
	formatted_content_v3?:ValueTypes["order_by"],
	has_text?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_deleted?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	publish?:ValueTypes["order_by"],
	publish_date?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	reading_view?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	status?:ValueTypes["order_by"],
	thumbnail?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	view_count?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.chapters_07252024" */
["voyce_chapters_07252024_select_column"]:voyce_chapters_07252024_select_column;
	/** input type for updating data in table "voyce.chapters_07252024" */
["voyce_chapters_07252024_set_input"]: {
	created_at?:ValueTypes["timestamp"],
	description?:string,
	description_old?:string,
	displayed_exclusive?:boolean,
	formatted_content?:string,
	formatted_content_v2?:string,
	formatted_content_v3?:string,
	has_text?:boolean,
	id?:number,
	is_deleted?:boolean,
	order?:ValueTypes["smallint"],
	publish?:ValueTypes["smallint"],
	publish_date?:ValueTypes["timestamptz"],
	reactions_count?:number,
	reading_view?:string,
	series_id?:number,
	status?:string,
	thumbnail?:string,
	title?:string,
	type?:string,
	updated_at?:ValueTypes["timestamptz"],
	view_count?:number
};
	/** aggregate stddev on columns */
["voyce_chapters_07252024_stddev_fields"]: AliasType<{
	id?:true,
	order?:true,
	publish?:true,
	reactions_count?:true,
	series_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_chapters_07252024_stddev_pop_fields"]: AliasType<{
	id?:true,
	order?:true,
	publish?:true,
	reactions_count?:true,
	series_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_chapters_07252024_stddev_samp_fields"]: AliasType<{
	id?:true,
	order?:true,
	publish?:true,
	reactions_count?:true,
	series_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_chapters_07252024" */
["voyce_chapters_07252024_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapters_07252024_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapters_07252024_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamp"],
	description?:string,
	description_old?:string,
	displayed_exclusive?:boolean,
	formatted_content?:string,
	formatted_content_v2?:string,
	formatted_content_v3?:string,
	has_text?:boolean,
	id?:number,
	is_deleted?:boolean,
	order?:ValueTypes["smallint"],
	publish?:ValueTypes["smallint"],
	publish_date?:ValueTypes["timestamptz"],
	reactions_count?:number,
	reading_view?:string,
	series_id?:number,
	status?:string,
	thumbnail?:string,
	title?:string,
	type?:string,
	updated_at?:ValueTypes["timestamptz"],
	view_count?:number
};
	/** aggregate sum on columns */
["voyce_chapters_07252024_sum_fields"]: AliasType<{
	id?:true,
	order?:true,
	publish?:true,
	reactions_count?:true,
	series_id?:true,
	view_count?:true,
		__typename?: true
}>;
	["voyce_chapters_07252024_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapters_07252024_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapters_07252024_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapters_07252024_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapters_07252024_var_pop_fields"]: AliasType<{
	id?:true,
	order?:true,
	publish?:true,
	reactions_count?:true,
	series_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_chapters_07252024_var_samp_fields"]: AliasType<{
	id?:true,
	order?:true,
	publish?:true,
	reactions_count?:true,
	series_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_chapters_07252024_variance_fields"]: AliasType<{
	id?:true,
	order?:true,
	publish?:true,
	reactions_count?:true,
	series_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.chapters" */
["voyce_chapters_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_chapters_aggregate_fields"],
	nodes?:ValueTypes["voyce_chapters"],
		__typename?: true
}>;
	["voyce_chapters_aggregate_bool_exp"]: {
	bool_and?:ValueTypes["voyce_chapters_aggregate_bool_exp_bool_and"],
	bool_or?:ValueTypes["voyce_chapters_aggregate_bool_exp_bool_or"],
	count?:ValueTypes["voyce_chapters_aggregate_bool_exp_count"]
};
	["voyce_chapters_aggregate_bool_exp_bool_and"]: {
	arguments:ValueTypes["voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapters_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_chapters_aggregate_bool_exp_bool_or"]: {
	arguments:ValueTypes["voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapters_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_chapters_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_chapters_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_chapters_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapters" */
["voyce_chapters_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_chapters_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_chapters_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_chapters_max_fields"],
	min?:ValueTypes["voyce_chapters_min_fields"],
	stddev?:ValueTypes["voyce_chapters_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_chapters_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_chapters_stddev_samp_fields"],
	sum?:ValueTypes["voyce_chapters_sum_fields"],
	var_pop?:ValueTypes["voyce_chapters_var_pop_fields"],
	var_samp?:ValueTypes["voyce_chapters_var_samp_fields"],
	variance?:ValueTypes["voyce_chapters_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.chapters" */
["voyce_chapters_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_chapters_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_chapters_max_order_by"],
	min?:ValueTypes["voyce_chapters_min_order_by"],
	stddev?:ValueTypes["voyce_chapters_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_chapters_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_chapters_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_chapters_sum_order_by"],
	var_pop?:ValueTypes["voyce_chapters_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_chapters_var_samp_order_by"],
	variance?:ValueTypes["voyce_chapters_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapters" */
["voyce_chapters_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_chapters_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapters_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapters_avg_fields"]: AliasType<{
	id?:true,
	order?:true,
	publish?:true,
	reactions_count?:true,
	series_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.chapters" */
["voyce_chapters_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	publish?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	view_count?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapters". All fields are combined with a logical 'AND'. */
["voyce_chapters_bool_exp"]: {
	_and?:ValueTypes["voyce_chapters_bool_exp"][],
	_not?:ValueTypes["voyce_chapters_bool_exp"],
	_or?:ValueTypes["voyce_chapters_bool_exp"][],
	chapter_comments?:ValueTypes["voyce_chapter_comments_bool_exp"],
	chapter_comments_aggregate?:ValueTypes["voyce_chapter_comments_aggregate_bool_exp"],
	chapter_effects?:ValueTypes["voyce_chapter_effects_bool_exp"],
	chapter_effects_aggregate?:ValueTypes["voyce_chapter_effects_aggregate_bool_exp"],
	chapter_panels?:ValueTypes["voyce_chapter_panels_bool_exp"],
	chapter_panels_aggregate?:ValueTypes["voyce_chapter_panels_aggregate_bool_exp"],
	chapter_reactions?:ValueTypes["voyce_chapter_reactions_bool_exp"],
	chapter_reactions_aggregate?:ValueTypes["voyce_chapter_reactions_aggregate_bool_exp"],
	content?:ValueTypes["voyce_chapter_contents_bool_exp"],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	description?:ValueTypes["String_comparison_exp"],
	description_old?:ValueTypes["String_comparison_exp"],
	displayed_exclusive?:ValueTypes["Boolean_comparison_exp"],
	formatted_content?:ValueTypes["String_comparison_exp"],
	formatted_content_v2?:ValueTypes["String_comparison_exp"],
	formatted_content_v3?:ValueTypes["String_comparison_exp"],
	has_text?:ValueTypes["Boolean_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	images?:ValueTypes["voyce_chapter_images_bool_exp"],
	images_aggregate?:ValueTypes["voyce_chapter_images_aggregate_bool_exp"],
	is_deleted?:ValueTypes["Boolean_comparison_exp"],
	likes?:ValueTypes["voyce_chapter_likes_bool_exp"],
	likes_aggregate?:ValueTypes["voyce_chapter_likes_aggregate_bool_exp"],
	locks?:ValueTypes["voyce_chapter_locks_bool_exp"],
	locks_aggregate?:ValueTypes["voyce_chapter_locks_aggregate_bool_exp"],
	order?:ValueTypes["smallint_comparison_exp"],
	publish?:ValueTypes["smallint_comparison_exp"],
	publish_date?:ValueTypes["timestamptz_comparison_exp"],
	reactions_count?:ValueTypes["Int_comparison_exp"],
	reading_view?:ValueTypes["String_comparison_exp"],
	schedule?:ValueTypes["voyce_chapter_publish_schedules_bool_exp"],
	schedule_aggregate?:ValueTypes["voyce_chapter_publish_schedules_aggregate_bool_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	status?:ValueTypes["String_comparison_exp"],
	thumbnail?:ValueTypes["String_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	view_count?:ValueTypes["Int_comparison_exp"],
	views?:ValueTypes["voyce_chapter_views_bool_exp"],
	views_aggregate?:ValueTypes["voyce_chapter_views_aggregate_bool_exp"]
};
	/** unique or primary key constraints on table "voyce.chapters" */
["voyce_chapters_constraint"]:voyce_chapters_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapters" */
["voyce_chapters_inc_input"]: {
	id?:number,
	order?:ValueTypes["smallint"],
	publish?:ValueTypes["smallint"],
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** input type for inserting data into table "voyce.chapters" */
["voyce_chapters_insert_input"]: {
	chapter_comments?:ValueTypes["voyce_chapter_comments_arr_rel_insert_input"],
	chapter_effects?:ValueTypes["voyce_chapter_effects_arr_rel_insert_input"],
	chapter_panels?:ValueTypes["voyce_chapter_panels_arr_rel_insert_input"],
	chapter_reactions?:ValueTypes["voyce_chapter_reactions_arr_rel_insert_input"],
	content?:ValueTypes["voyce_chapter_contents_obj_rel_insert_input"],
	created_at?:ValueTypes["timestamp"],
	description?:string,
	description_old?:string,
	displayed_exclusive?:boolean,
	formatted_content?:string,
	formatted_content_v2?:string,
	formatted_content_v3?:string,
	has_text?:boolean,
	id?:number,
	images?:ValueTypes["voyce_chapter_images_arr_rel_insert_input"],
	is_deleted?:boolean,
	likes?:ValueTypes["voyce_chapter_likes_arr_rel_insert_input"],
	locks?:ValueTypes["voyce_chapter_locks_arr_rel_insert_input"],
	order?:ValueTypes["smallint"],
	publish?:ValueTypes["smallint"],
	publish_date?:ValueTypes["timestamptz"],
	reactions_count?:number,
	reading_view?:string,
	schedule?:ValueTypes["voyce_chapter_publish_schedules_arr_rel_insert_input"],
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	status?:string,
	thumbnail?:string,
	title?:string,
	type?:string,
	updated_at?:ValueTypes["timestamptz"],
	view_count?:number,
	views?:ValueTypes["voyce_chapter_views_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["voyce_chapters_max_fields"]: AliasType<{
	created_at?:true,
	description?:true,
	description_old?:true,
	formatted_content?:true,
	formatted_content_v2?:true,
	formatted_content_v3?:true,
	id?:true,
	order?:true,
	publish?:true,
	publish_date?:true,
	reactions_count?:true,
	reading_view?:true,
	series_id?:true,
	status?:true,
	thumbnail?:true,
	title?:true,
	type?:true,
	updated_at?:true,
	view_count?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.chapters" */
["voyce_chapters_max_order_by"]: {
	created_at?:ValueTypes["order_by"],
	description?:ValueTypes["order_by"],
	description_old?:ValueTypes["order_by"],
	formatted_content?:ValueTypes["order_by"],
	formatted_content_v2?:ValueTypes["order_by"],
	formatted_content_v3?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	publish?:ValueTypes["order_by"],
	publish_date?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	reading_view?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	status?:ValueTypes["order_by"],
	thumbnail?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	view_count?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapters_min_fields"]: AliasType<{
	created_at?:true,
	description?:true,
	description_old?:true,
	formatted_content?:true,
	formatted_content_v2?:true,
	formatted_content_v3?:true,
	id?:true,
	order?:true,
	publish?:true,
	publish_date?:true,
	reactions_count?:true,
	reading_view?:true,
	series_id?:true,
	status?:true,
	thumbnail?:true,
	title?:true,
	type?:true,
	updated_at?:true,
	view_count?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.chapters" */
["voyce_chapters_min_order_by"]: {
	created_at?:ValueTypes["order_by"],
	description?:ValueTypes["order_by"],
	description_old?:ValueTypes["order_by"],
	formatted_content?:ValueTypes["order_by"],
	formatted_content_v2?:ValueTypes["order_by"],
	formatted_content_v3?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	publish?:ValueTypes["order_by"],
	publish_date?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	reading_view?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	status?:ValueTypes["order_by"],
	thumbnail?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	view_count?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapters" */
["voyce_chapters_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_chapters"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.chapters" */
["voyce_chapters_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_chapters_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_chapters_on_conflict"]
};
	/** on_conflict condition type for table "voyce.chapters" */
["voyce_chapters_on_conflict"]: {
	constraint:ValueTypes["voyce_chapters_constraint"],
	update_columns:ValueTypes["voyce_chapters_update_column"][],
	where?:ValueTypes["voyce_chapters_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapters". */
["voyce_chapters_order_by"]: {
	chapter_comments_aggregate?:ValueTypes["voyce_chapter_comments_aggregate_order_by"],
	chapter_effects_aggregate?:ValueTypes["voyce_chapter_effects_aggregate_order_by"],
	chapter_panels_aggregate?:ValueTypes["voyce_chapter_panels_aggregate_order_by"],
	chapter_reactions_aggregate?:ValueTypes["voyce_chapter_reactions_aggregate_order_by"],
	content?:ValueTypes["voyce_chapter_contents_order_by"],
	created_at?:ValueTypes["order_by"],
	description?:ValueTypes["order_by"],
	description_old?:ValueTypes["order_by"],
	displayed_exclusive?:ValueTypes["order_by"],
	formatted_content?:ValueTypes["order_by"],
	formatted_content_v2?:ValueTypes["order_by"],
	formatted_content_v3?:ValueTypes["order_by"],
	has_text?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	images_aggregate?:ValueTypes["voyce_chapter_images_aggregate_order_by"],
	is_deleted?:ValueTypes["order_by"],
	likes_aggregate?:ValueTypes["voyce_chapter_likes_aggregate_order_by"],
	locks_aggregate?:ValueTypes["voyce_chapter_locks_aggregate_order_by"],
	order?:ValueTypes["order_by"],
	publish?:ValueTypes["order_by"],
	publish_date?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	reading_view?:ValueTypes["order_by"],
	schedule_aggregate?:ValueTypes["voyce_chapter_publish_schedules_aggregate_order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	status?:ValueTypes["order_by"],
	thumbnail?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	view_count?:ValueTypes["order_by"],
	views_aggregate?:ValueTypes["voyce_chapter_views_aggregate_order_by"]
};
	/** primary key columns input for table: voyce.chapters */
["voyce_chapters_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.chapters" */
["voyce_chapters_select_column"]:voyce_chapters_select_column;
	/** select "voyce_chapters_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapters" */
["voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_and_arguments_columns"]:voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_chapters_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapters" */
["voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_or_arguments_columns"]:voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.chapters" */
["voyce_chapters_set_input"]: {
	created_at?:ValueTypes["timestamp"],
	description?:string,
	description_old?:string,
	displayed_exclusive?:boolean,
	formatted_content?:string,
	formatted_content_v2?:string,
	formatted_content_v3?:string,
	has_text?:boolean,
	id?:number,
	is_deleted?:boolean,
	order?:ValueTypes["smallint"],
	publish?:ValueTypes["smallint"],
	publish_date?:ValueTypes["timestamptz"],
	reactions_count?:number,
	reading_view?:string,
	series_id?:number,
	status?:string,
	thumbnail?:string,
	title?:string,
	type?:string,
	updated_at?:ValueTypes["timestamptz"],
	view_count?:number
};
	/** aggregate stddev on columns */
["voyce_chapters_stddev_fields"]: AliasType<{
	id?:true,
	order?:true,
	publish?:true,
	reactions_count?:true,
	series_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.chapters" */
["voyce_chapters_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	publish?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	view_count?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapters_stddev_pop_fields"]: AliasType<{
	id?:true,
	order?:true,
	publish?:true,
	reactions_count?:true,
	series_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.chapters" */
["voyce_chapters_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	publish?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	view_count?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapters_stddev_samp_fields"]: AliasType<{
	id?:true,
	order?:true,
	publish?:true,
	reactions_count?:true,
	series_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.chapters" */
["voyce_chapters_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	publish?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	view_count?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapters" */
["voyce_chapters_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_chapters_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapters_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamp"],
	description?:string,
	description_old?:string,
	displayed_exclusive?:boolean,
	formatted_content?:string,
	formatted_content_v2?:string,
	formatted_content_v3?:string,
	has_text?:boolean,
	id?:number,
	is_deleted?:boolean,
	order?:ValueTypes["smallint"],
	publish?:ValueTypes["smallint"],
	publish_date?:ValueTypes["timestamptz"],
	reactions_count?:number,
	reading_view?:string,
	series_id?:number,
	status?:string,
	thumbnail?:string,
	title?:string,
	type?:string,
	updated_at?:ValueTypes["timestamptz"],
	view_count?:number
};
	/** aggregate sum on columns */
["voyce_chapters_sum_fields"]: AliasType<{
	id?:true,
	order?:true,
	publish?:true,
	reactions_count?:true,
	series_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.chapters" */
["voyce_chapters_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	publish?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	view_count?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.chapters" */
["voyce_chapters_update_column"]:voyce_chapters_update_column;
	["voyce_chapters_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_chapters_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_chapters_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_chapters_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapters_var_pop_fields"]: AliasType<{
	id?:true,
	order?:true,
	publish?:true,
	reactions_count?:true,
	series_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.chapters" */
["voyce_chapters_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	publish?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	view_count?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapters_var_samp_fields"]: AliasType<{
	id?:true,
	order?:true,
	publish?:true,
	reactions_count?:true,
	series_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.chapters" */
["voyce_chapters_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	publish?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	view_count?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapters_variance_fields"]: AliasType<{
	id?:true,
	order?:true,
	publish?:true,
	reactions_count?:true,
	series_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.chapters" */
["voyce_chapters_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	publish?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	view_count?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.collaborator_roles" */
["voyce_collaborator_roles"]: AliasType<{
	created_at?:true,
	id?:true,
	role?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.collaborator_roles" */
["voyce_collaborator_roles_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_collaborator_roles_aggregate_fields"],
	nodes?:ValueTypes["voyce_collaborator_roles"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.collaborator_roles" */
["voyce_collaborator_roles_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_collaborator_roles_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_collaborator_roles_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_collaborator_roles_max_fields"],
	min?:ValueTypes["voyce_collaborator_roles_min_fields"],
	stddev?:ValueTypes["voyce_collaborator_roles_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_collaborator_roles_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_collaborator_roles_stddev_samp_fields"],
	sum?:ValueTypes["voyce_collaborator_roles_sum_fields"],
	var_pop?:ValueTypes["voyce_collaborator_roles_var_pop_fields"],
	var_samp?:ValueTypes["voyce_collaborator_roles_var_samp_fields"],
	variance?:ValueTypes["voyce_collaborator_roles_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_collaborator_roles_avg_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.collaborator_roles". All fields are combined with a logical 'AND'. */
["voyce_collaborator_roles_bool_exp"]: {
	_and?:ValueTypes["voyce_collaborator_roles_bool_exp"][],
	_not?:ValueTypes["voyce_collaborator_roles_bool_exp"],
	_or?:ValueTypes["voyce_collaborator_roles_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	role?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.collaborator_roles" */
["voyce_collaborator_roles_constraint"]:voyce_collaborator_roles_constraint;
	/** input type for incrementing numeric columns in table "voyce.collaborator_roles" */
["voyce_collaborator_roles_inc_input"]: {
	id?:number
};
	/** input type for inserting data into table "voyce.collaborator_roles" */
["voyce_collaborator_roles_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	role?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_collaborator_roles_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	role?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_collaborator_roles_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	role?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.collaborator_roles" */
["voyce_collaborator_roles_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_collaborator_roles"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.collaborator_roles" */
["voyce_collaborator_roles_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_collaborator_roles_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_collaborator_roles_on_conflict"]
};
	/** on_conflict condition type for table "voyce.collaborator_roles" */
["voyce_collaborator_roles_on_conflict"]: {
	constraint:ValueTypes["voyce_collaborator_roles_constraint"],
	update_columns:ValueTypes["voyce_collaborator_roles_update_column"][],
	where?:ValueTypes["voyce_collaborator_roles_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.collaborator_roles". */
["voyce_collaborator_roles_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	role?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.collaborator_roles */
["voyce_collaborator_roles_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.collaborator_roles" */
["voyce_collaborator_roles_select_column"]:voyce_collaborator_roles_select_column;
	/** input type for updating data in table "voyce.collaborator_roles" */
["voyce_collaborator_roles_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	role?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_collaborator_roles_stddev_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_collaborator_roles_stddev_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_collaborator_roles_stddev_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_collaborator_roles" */
["voyce_collaborator_roles_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_collaborator_roles_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_collaborator_roles_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	role?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_collaborator_roles_sum_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.collaborator_roles" */
["voyce_collaborator_roles_update_column"]:voyce_collaborator_roles_update_column;
	["voyce_collaborator_roles_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_collaborator_roles_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_collaborator_roles_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_collaborator_roles_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_collaborator_roles_var_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_collaborator_roles_var_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_collaborator_roles_variance_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.comic_types" */
["voyce_comic_types"]: AliasType<{
	created_at?:true,
	id?:true,
	klaviyo_id?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.comic_types" */
["voyce_comic_types_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_comic_types_aggregate_fields"],
	nodes?:ValueTypes["voyce_comic_types"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.comic_types" */
["voyce_comic_types_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_comic_types_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_comic_types_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_comic_types_max_fields"],
	min?:ValueTypes["voyce_comic_types_min_fields"],
	stddev?:ValueTypes["voyce_comic_types_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_comic_types_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_comic_types_stddev_samp_fields"],
	sum?:ValueTypes["voyce_comic_types_sum_fields"],
	var_pop?:ValueTypes["voyce_comic_types_var_pop_fields"],
	var_samp?:ValueTypes["voyce_comic_types_var_samp_fields"],
	variance?:ValueTypes["voyce_comic_types_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_comic_types_avg_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.comic_types". All fields are combined with a logical 'AND'. */
["voyce_comic_types_bool_exp"]: {
	_and?:ValueTypes["voyce_comic_types_bool_exp"][],
	_not?:ValueTypes["voyce_comic_types_bool_exp"],
	_or?:ValueTypes["voyce_comic_types_bool_exp"][],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	klaviyo_id?:ValueTypes["String_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamp_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.comic_types" */
["voyce_comic_types_constraint"]:voyce_comic_types_constraint;
	/** input type for incrementing numeric columns in table "voyce.comic_types" */
["voyce_comic_types_inc_input"]: {
	id?:number
};
	/** input type for inserting data into table "voyce.comic_types" */
["voyce_comic_types_insert_input"]: {
	created_at?:ValueTypes["timestamp"],
	id?:number,
	klaviyo_id?:string,
	title?:string,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate max on columns */
["voyce_comic_types_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	klaviyo_id?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_comic_types_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	klaviyo_id?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.comic_types" */
["voyce_comic_types_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_comic_types"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.comic_types" */
["voyce_comic_types_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_comic_types_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_comic_types_on_conflict"]
};
	/** on_conflict condition type for table "voyce.comic_types" */
["voyce_comic_types_on_conflict"]: {
	constraint:ValueTypes["voyce_comic_types_constraint"],
	update_columns:ValueTypes["voyce_comic_types_update_column"][],
	where?:ValueTypes["voyce_comic_types_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.comic_types". */
["voyce_comic_types_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	klaviyo_id?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.comic_types */
["voyce_comic_types_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.comic_types" */
["voyce_comic_types_select_column"]:voyce_comic_types_select_column;
	/** input type for updating data in table "voyce.comic_types" */
["voyce_comic_types_set_input"]: {
	created_at?:ValueTypes["timestamp"],
	id?:number,
	klaviyo_id?:string,
	title?:string,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate stddev on columns */
["voyce_comic_types_stddev_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_comic_types_stddev_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_comic_types_stddev_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_comic_types" */
["voyce_comic_types_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_comic_types_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_comic_types_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamp"],
	id?:number,
	klaviyo_id?:string,
	title?:string,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate sum on columns */
["voyce_comic_types_sum_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.comic_types" */
["voyce_comic_types_update_column"]:voyce_comic_types_update_column;
	["voyce_comic_types_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_comic_types_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_comic_types_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_comic_types_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_comic_types_var_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_comic_types_var_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_comic_types_variance_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.comments_top_mat" */
["voyce_comments_top_mat"]: AliasType<{
	comment_type?:true,
	id?:true,
	reaction_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.comments_top_mat" */
["voyce_comments_top_mat_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_comments_top_mat_aggregate_fields"],
	nodes?:ValueTypes["voyce_comments_top_mat"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.comments_top_mat" */
["voyce_comments_top_mat_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_comments_top_mat_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_comments_top_mat_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_comments_top_mat_max_fields"],
	min?:ValueTypes["voyce_comments_top_mat_min_fields"],
	stddev?:ValueTypes["voyce_comments_top_mat_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_comments_top_mat_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_comments_top_mat_stddev_samp_fields"],
	sum?:ValueTypes["voyce_comments_top_mat_sum_fields"],
	var_pop?:ValueTypes["voyce_comments_top_mat_var_pop_fields"],
	var_samp?:ValueTypes["voyce_comments_top_mat_var_samp_fields"],
	variance?:ValueTypes["voyce_comments_top_mat_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_comments_top_mat_avg_fields"]: AliasType<{
	id?:true,
	reaction_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.comments_top_mat". All fields are combined with a logical 'AND'. */
["voyce_comments_top_mat_bool_exp"]: {
	_and?:ValueTypes["voyce_comments_top_mat_bool_exp"][],
	_not?:ValueTypes["voyce_comments_top_mat_bool_exp"],
	_or?:ValueTypes["voyce_comments_top_mat_bool_exp"][],
	comment_type?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	reaction_count?:ValueTypes["Int_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** aggregate max on columns */
["voyce_comments_top_mat_max_fields"]: AliasType<{
	comment_type?:true,
	id?:true,
	reaction_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_comments_top_mat_min_fields"]: AliasType<{
	comment_type?:true,
	id?:true,
	reaction_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Ordering options when selecting data from "voyce.comments_top_mat". */
["voyce_comments_top_mat_order_by"]: {
	comment_type?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.comments_top_mat" */
["voyce_comments_top_mat_select_column"]:voyce_comments_top_mat_select_column;
	/** aggregate stddev on columns */
["voyce_comments_top_mat_stddev_fields"]: AliasType<{
	id?:true,
	reaction_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_comments_top_mat_stddev_pop_fields"]: AliasType<{
	id?:true,
	reaction_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_comments_top_mat_stddev_samp_fields"]: AliasType<{
	id?:true,
	reaction_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_comments_top_mat" */
["voyce_comments_top_mat_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_comments_top_mat_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_comments_top_mat_stream_cursor_value_input"]: {
	comment_type?:string,
	id?:number,
	reaction_count?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_comments_top_mat_sum_fields"]: AliasType<{
	id?:true,
	reaction_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_pop on columns */
["voyce_comments_top_mat_var_pop_fields"]: AliasType<{
	id?:true,
	reaction_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_comments_top_mat_var_samp_fields"]: AliasType<{
	id?:true,
	reaction_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_comments_top_mat_variance_fields"]: AliasType<{
	id?:true,
	reaction_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.community_blurbs" */
["voyce_community_blurbs"]: AliasType<{
	avatar?:true,
	content?:true,
	id?:true,
	name?:true,
	order?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.community_blurbs" */
["voyce_community_blurbs_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_community_blurbs_aggregate_fields"],
	nodes?:ValueTypes["voyce_community_blurbs"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.community_blurbs" */
["voyce_community_blurbs_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_community_blurbs_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_community_blurbs_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_community_blurbs_max_fields"],
	min?:ValueTypes["voyce_community_blurbs_min_fields"],
	stddev?:ValueTypes["voyce_community_blurbs_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_community_blurbs_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_community_blurbs_stddev_samp_fields"],
	sum?:ValueTypes["voyce_community_blurbs_sum_fields"],
	var_pop?:ValueTypes["voyce_community_blurbs_var_pop_fields"],
	var_samp?:ValueTypes["voyce_community_blurbs_var_samp_fields"],
	variance?:ValueTypes["voyce_community_blurbs_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_community_blurbs_avg_fields"]: AliasType<{
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.community_blurbs". All fields are combined with a logical 'AND'. */
["voyce_community_blurbs_bool_exp"]: {
	_and?:ValueTypes["voyce_community_blurbs_bool_exp"][],
	_not?:ValueTypes["voyce_community_blurbs_bool_exp"],
	_or?:ValueTypes["voyce_community_blurbs_bool_exp"][],
	avatar?:ValueTypes["String_comparison_exp"],
	content?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	name?:ValueTypes["String_comparison_exp"],
	order?:ValueTypes["Int_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.community_blurbs" */
["voyce_community_blurbs_constraint"]:voyce_community_blurbs_constraint;
	/** input type for incrementing numeric columns in table "voyce.community_blurbs" */
["voyce_community_blurbs_inc_input"]: {
	id?:number,
	order?:number
};
	/** input type for inserting data into table "voyce.community_blurbs" */
["voyce_community_blurbs_insert_input"]: {
	avatar?:string,
	content?:string,
	id?:number,
	name?:string,
	order?:number,
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_community_blurbs_max_fields"]: AliasType<{
	avatar?:true,
	content?:true,
	id?:true,
	name?:true,
	order?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_community_blurbs_min_fields"]: AliasType<{
	avatar?:true,
	content?:true,
	id?:true,
	name?:true,
	order?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.community_blurbs" */
["voyce_community_blurbs_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_community_blurbs"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.community_blurbs" */
["voyce_community_blurbs_on_conflict"]: {
	constraint:ValueTypes["voyce_community_blurbs_constraint"],
	update_columns:ValueTypes["voyce_community_blurbs_update_column"][],
	where?:ValueTypes["voyce_community_blurbs_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.community_blurbs". */
["voyce_community_blurbs_order_by"]: {
	avatar?:ValueTypes["order_by"],
	content?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	name?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.community_blurbs */
["voyce_community_blurbs_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.community_blurbs" */
["voyce_community_blurbs_select_column"]:voyce_community_blurbs_select_column;
	/** input type for updating data in table "voyce.community_blurbs" */
["voyce_community_blurbs_set_input"]: {
	avatar?:string,
	content?:string,
	id?:number,
	name?:string,
	order?:number,
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_community_blurbs_stddev_fields"]: AliasType<{
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_community_blurbs_stddev_pop_fields"]: AliasType<{
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_community_blurbs_stddev_samp_fields"]: AliasType<{
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_community_blurbs" */
["voyce_community_blurbs_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_community_blurbs_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_community_blurbs_stream_cursor_value_input"]: {
	avatar?:string,
	content?:string,
	id?:number,
	name?:string,
	order?:number,
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_community_blurbs_sum_fields"]: AliasType<{
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.community_blurbs" */
["voyce_community_blurbs_update_column"]:voyce_community_blurbs_update_column;
	["voyce_community_blurbs_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_community_blurbs_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_community_blurbs_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_community_blurbs_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_community_blurbs_var_pop_fields"]: AliasType<{
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_community_blurbs_var_samp_fields"]: AliasType<{
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_community_blurbs_variance_fields"]: AliasType<{
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.contact_us" */
["voyce_contact_us"]: AliasType<{
	created_at?:true,
	email?:true,
	first_name?:true,
	id?:true,
	last_name?:true,
	message?:true,
	number?:true,
	reason?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.contact_us" */
["voyce_contact_us_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_contact_us_aggregate_fields"],
	nodes?:ValueTypes["voyce_contact_us"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.contact_us" */
["voyce_contact_us_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_contact_us_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_contact_us_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_contact_us_max_fields"],
	min?:ValueTypes["voyce_contact_us_min_fields"],
	stddev?:ValueTypes["voyce_contact_us_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_contact_us_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_contact_us_stddev_samp_fields"],
	sum?:ValueTypes["voyce_contact_us_sum_fields"],
	var_pop?:ValueTypes["voyce_contact_us_var_pop_fields"],
	var_samp?:ValueTypes["voyce_contact_us_var_samp_fields"],
	variance?:ValueTypes["voyce_contact_us_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_contact_us_avg_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.contact_us". All fields are combined with a logical 'AND'. */
["voyce_contact_us_bool_exp"]: {
	_and?:ValueTypes["voyce_contact_us_bool_exp"][],
	_not?:ValueTypes["voyce_contact_us_bool_exp"],
	_or?:ValueTypes["voyce_contact_us_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	email?:ValueTypes["String_comparison_exp"],
	first_name?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	last_name?:ValueTypes["String_comparison_exp"],
	message?:ValueTypes["String_comparison_exp"],
	number?:ValueTypes["String_comparison_exp"],
	reason?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.contact_us" */
["voyce_contact_us_constraint"]:voyce_contact_us_constraint;
	/** input type for incrementing numeric columns in table "voyce.contact_us" */
["voyce_contact_us_inc_input"]: {
	id?:number
};
	/** input type for inserting data into table "voyce.contact_us" */
["voyce_contact_us_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	email?:string,
	first_name?:string,
	id?:number,
	last_name?:string,
	message?:string,
	number?:string,
	reason?:string
};
	/** aggregate max on columns */
["voyce_contact_us_max_fields"]: AliasType<{
	created_at?:true,
	email?:true,
	first_name?:true,
	id?:true,
	last_name?:true,
	message?:true,
	number?:true,
	reason?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_contact_us_min_fields"]: AliasType<{
	created_at?:true,
	email?:true,
	first_name?:true,
	id?:true,
	last_name?:true,
	message?:true,
	number?:true,
	reason?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.contact_us" */
["voyce_contact_us_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_contact_us"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.contact_us" */
["voyce_contact_us_on_conflict"]: {
	constraint:ValueTypes["voyce_contact_us_constraint"],
	update_columns:ValueTypes["voyce_contact_us_update_column"][],
	where?:ValueTypes["voyce_contact_us_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.contact_us". */
["voyce_contact_us_order_by"]: {
	created_at?:ValueTypes["order_by"],
	email?:ValueTypes["order_by"],
	first_name?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_name?:ValueTypes["order_by"],
	message?:ValueTypes["order_by"],
	number?:ValueTypes["order_by"],
	reason?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.contact_us */
["voyce_contact_us_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.contact_us" */
["voyce_contact_us_select_column"]:voyce_contact_us_select_column;
	/** input type for updating data in table "voyce.contact_us" */
["voyce_contact_us_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	email?:string,
	first_name?:string,
	id?:number,
	last_name?:string,
	message?:string,
	number?:string,
	reason?:string
};
	/** aggregate stddev on columns */
["voyce_contact_us_stddev_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_contact_us_stddev_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_contact_us_stddev_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_contact_us" */
["voyce_contact_us_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_contact_us_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_contact_us_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	email?:string,
	first_name?:string,
	id?:number,
	last_name?:string,
	message?:string,
	number?:string,
	reason?:string
};
	/** aggregate sum on columns */
["voyce_contact_us_sum_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.contact_us" */
["voyce_contact_us_update_column"]:voyce_contact_us_update_column;
	["voyce_contact_us_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_contact_us_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_contact_us_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_contact_us_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_contact_us_var_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_contact_us_var_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_contact_us_variance_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.content_report" */
["voyce_content_report"]: AliasType<{
	created_at?:true,
	extras?:true,
	id?:true,
	linked_id?:true,
	reason?:true,
	type?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.content_report" */
["voyce_content_report_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_content_report_aggregate_fields"],
	nodes?:ValueTypes["voyce_content_report"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.content_report" */
["voyce_content_report_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_content_report_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_content_report_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_content_report_max_fields"],
	min?:ValueTypes["voyce_content_report_min_fields"],
	stddev?:ValueTypes["voyce_content_report_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_content_report_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_content_report_stddev_samp_fields"],
	sum?:ValueTypes["voyce_content_report_sum_fields"],
	var_pop?:ValueTypes["voyce_content_report_var_pop_fields"],
	var_samp?:ValueTypes["voyce_content_report_var_samp_fields"],
	variance?:ValueTypes["voyce_content_report_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_content_report_avg_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.content_report". All fields are combined with a logical 'AND'. */
["voyce_content_report_bool_exp"]: {
	_and?:ValueTypes["voyce_content_report_bool_exp"][],
	_not?:ValueTypes["voyce_content_report_bool_exp"],
	_or?:ValueTypes["voyce_content_report_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	extras?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	linked_id?:ValueTypes["Int_comparison_exp"],
	reason?:ValueTypes["String_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.content_report" */
["voyce_content_report_constraint"]:voyce_content_report_constraint;
	/** input type for incrementing numeric columns in table "voyce.content_report" */
["voyce_content_report_inc_input"]: {
	id?:number,
	linked_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.content_report" */
["voyce_content_report_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	extras?:string,
	id?:number,
	linked_id?:number,
	reason?:string,
	type?:string,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_content_report_max_fields"]: AliasType<{
	created_at?:true,
	extras?:true,
	id?:true,
	linked_id?:true,
	reason?:true,
	type?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_content_report_min_fields"]: AliasType<{
	created_at?:true,
	extras?:true,
	id?:true,
	linked_id?:true,
	reason?:true,
	type?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.content_report" */
["voyce_content_report_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_content_report"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.content_report" */
["voyce_content_report_on_conflict"]: {
	constraint:ValueTypes["voyce_content_report_constraint"],
	update_columns:ValueTypes["voyce_content_report_update_column"][],
	where?:ValueTypes["voyce_content_report_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.content_report". */
["voyce_content_report_order_by"]: {
	created_at?:ValueTypes["order_by"],
	extras?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	reason?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.content_report */
["voyce_content_report_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.content_report" */
["voyce_content_report_select_column"]:voyce_content_report_select_column;
	/** input type for updating data in table "voyce.content_report" */
["voyce_content_report_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	extras?:string,
	id?:number,
	linked_id?:number,
	reason?:string,
	type?:string,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_content_report_stddev_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_content_report_stddev_pop_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_content_report_stddev_samp_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_content_report" */
["voyce_content_report_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_content_report_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_content_report_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	extras?:string,
	id?:number,
	linked_id?:number,
	reason?:string,
	type?:string,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_content_report_sum_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.content_report" */
["voyce_content_report_update_column"]:voyce_content_report_update_column;
	["voyce_content_report_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_content_report_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_content_report_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_content_report_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_content_report_var_pop_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_content_report_var_samp_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_content_report_variance_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Table for storing data about contest submits */
["voyce_contest_data"]: AliasType<{
	age?:true,
	created_at?:true,
	discord_username?:true,
	first_arc?:true,
	id?:true,
	name?:true,
	pdf?:true,
	social_media?:true,
	story_pitch?:true,
	story_summary?:true,
	target_audience?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
	where_did_hear?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.contest_data" */
["voyce_contest_data_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_contest_data_aggregate_fields"],
	nodes?:ValueTypes["voyce_contest_data"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.contest_data" */
["voyce_contest_data_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_contest_data_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_contest_data_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_contest_data_max_fields"],
	min?:ValueTypes["voyce_contest_data_min_fields"],
	stddev?:ValueTypes["voyce_contest_data_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_contest_data_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_contest_data_stddev_samp_fields"],
	sum?:ValueTypes["voyce_contest_data_sum_fields"],
	var_pop?:ValueTypes["voyce_contest_data_var_pop_fields"],
	var_samp?:ValueTypes["voyce_contest_data_var_samp_fields"],
	variance?:ValueTypes["voyce_contest_data_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_contest_data_avg_fields"]: AliasType<{
	age?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.contest_data". All fields are combined with a logical 'AND'. */
["voyce_contest_data_bool_exp"]: {
	_and?:ValueTypes["voyce_contest_data_bool_exp"][],
	_not?:ValueTypes["voyce_contest_data_bool_exp"],
	_or?:ValueTypes["voyce_contest_data_bool_exp"][],
	age?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	discord_username?:ValueTypes["String_comparison_exp"],
	first_arc?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	name?:ValueTypes["String_comparison_exp"],
	pdf?:ValueTypes["String_comparison_exp"],
	social_media?:ValueTypes["String_comparison_exp"],
	story_pitch?:ValueTypes["String_comparison_exp"],
	story_summary?:ValueTypes["String_comparison_exp"],
	target_audience?:ValueTypes["String_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	where_did_hear?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.contest_data" */
["voyce_contest_data_constraint"]:voyce_contest_data_constraint;
	/** input type for incrementing numeric columns in table "voyce.contest_data" */
["voyce_contest_data_inc_input"]: {
	age?:number,
	id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.contest_data" */
["voyce_contest_data_insert_input"]: {
	age?:number,
	created_at?:ValueTypes["timestamp"],
	discord_username?:string,
	first_arc?:string,
	id?:number,
	name?:string,
	pdf?:string,
	social_media?:string,
	story_pitch?:string,
	story_summary?:string,
	target_audience?:string,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number,
	where_did_hear?:string
};
	/** aggregate max on columns */
["voyce_contest_data_max_fields"]: AliasType<{
	age?:true,
	created_at?:true,
	discord_username?:true,
	first_arc?:true,
	id?:true,
	name?:true,
	pdf?:true,
	social_media?:true,
	story_pitch?:true,
	story_summary?:true,
	target_audience?:true,
	user_id?:true,
	where_did_hear?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_contest_data_min_fields"]: AliasType<{
	age?:true,
	created_at?:true,
	discord_username?:true,
	first_arc?:true,
	id?:true,
	name?:true,
	pdf?:true,
	social_media?:true,
	story_pitch?:true,
	story_summary?:true,
	target_audience?:true,
	user_id?:true,
	where_did_hear?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.contest_data" */
["voyce_contest_data_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_contest_data"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.contest_data" */
["voyce_contest_data_on_conflict"]: {
	constraint:ValueTypes["voyce_contest_data_constraint"],
	update_columns:ValueTypes["voyce_contest_data_update_column"][],
	where?:ValueTypes["voyce_contest_data_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.contest_data". */
["voyce_contest_data_order_by"]: {
	age?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	discord_username?:ValueTypes["order_by"],
	first_arc?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	name?:ValueTypes["order_by"],
	pdf?:ValueTypes["order_by"],
	social_media?:ValueTypes["order_by"],
	story_pitch?:ValueTypes["order_by"],
	story_summary?:ValueTypes["order_by"],
	target_audience?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"],
	where_did_hear?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.contest_data */
["voyce_contest_data_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.contest_data" */
["voyce_contest_data_select_column"]:voyce_contest_data_select_column;
	/** input type for updating data in table "voyce.contest_data" */
["voyce_contest_data_set_input"]: {
	age?:number,
	created_at?:ValueTypes["timestamp"],
	discord_username?:string,
	first_arc?:string,
	id?:number,
	name?:string,
	pdf?:string,
	social_media?:string,
	story_pitch?:string,
	story_summary?:string,
	target_audience?:string,
	user_id?:number,
	where_did_hear?:string
};
	/** aggregate stddev on columns */
["voyce_contest_data_stddev_fields"]: AliasType<{
	age?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_contest_data_stddev_pop_fields"]: AliasType<{
	age?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_contest_data_stddev_samp_fields"]: AliasType<{
	age?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_contest_data" */
["voyce_contest_data_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_contest_data_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_contest_data_stream_cursor_value_input"]: {
	age?:number,
	created_at?:ValueTypes["timestamp"],
	discord_username?:string,
	first_arc?:string,
	id?:number,
	name?:string,
	pdf?:string,
	social_media?:string,
	story_pitch?:string,
	story_summary?:string,
	target_audience?:string,
	user_id?:number,
	where_did_hear?:string
};
	/** aggregate sum on columns */
["voyce_contest_data_sum_fields"]: AliasType<{
	age?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.contest_data" */
["voyce_contest_data_update_column"]:voyce_contest_data_update_column;
	["voyce_contest_data_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_contest_data_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_contest_data_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_contest_data_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_contest_data_var_pop_fields"]: AliasType<{
	age?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_contest_data_var_samp_fields"]: AliasType<{
	age?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_contest_data_variance_fields"]: AliasType<{
	age?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.countries" */
["voyce_countries"]: AliasType<{
	date_create?:true,
	description?:true,
	feature?:true,
	flag?:true,
	id?:true,
	name?:true,
	phone_code?:true,
	publish?:true,
	sort_name?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.countries" */
["voyce_countries_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_countries_aggregate_fields"],
	nodes?:ValueTypes["voyce_countries"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.countries" */
["voyce_countries_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_countries_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_countries_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_countries_max_fields"],
	min?:ValueTypes["voyce_countries_min_fields"],
	stddev?:ValueTypes["voyce_countries_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_countries_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_countries_stddev_samp_fields"],
	sum?:ValueTypes["voyce_countries_sum_fields"],
	var_pop?:ValueTypes["voyce_countries_var_pop_fields"],
	var_samp?:ValueTypes["voyce_countries_var_samp_fields"],
	variance?:ValueTypes["voyce_countries_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_countries_avg_fields"]: AliasType<{
	feature?:true,
	id?:true,
	phone_code?:true,
	publish?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.countries". All fields are combined with a logical 'AND'. */
["voyce_countries_bool_exp"]: {
	_and?:ValueTypes["voyce_countries_bool_exp"][],
	_not?:ValueTypes["voyce_countries_bool_exp"],
	_or?:ValueTypes["voyce_countries_bool_exp"][],
	date_create?:ValueTypes["timestamp_comparison_exp"],
	description?:ValueTypes["String_comparison_exp"],
	feature?:ValueTypes["smallint_comparison_exp"],
	flag?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	name?:ValueTypes["String_comparison_exp"],
	phone_code?:ValueTypes["Int_comparison_exp"],
	publish?:ValueTypes["smallint_comparison_exp"],
	sort_name?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.countries" */
["voyce_countries_constraint"]:voyce_countries_constraint;
	/** input type for incrementing numeric columns in table "voyce.countries" */
["voyce_countries_inc_input"]: {
	feature?:ValueTypes["smallint"],
	id?:number,
	phone_code?:number,
	publish?:ValueTypes["smallint"]
};
	/** input type for inserting data into table "voyce.countries" */
["voyce_countries_insert_input"]: {
	date_create?:ValueTypes["timestamp"],
	description?:string,
	feature?:ValueTypes["smallint"],
	flag?:string,
	id?:number,
	name?:string,
	phone_code?:number,
	publish?:ValueTypes["smallint"],
	sort_name?:string
};
	/** aggregate max on columns */
["voyce_countries_max_fields"]: AliasType<{
	date_create?:true,
	description?:true,
	feature?:true,
	flag?:true,
	id?:true,
	name?:true,
	phone_code?:true,
	publish?:true,
	sort_name?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_countries_min_fields"]: AliasType<{
	date_create?:true,
	description?:true,
	feature?:true,
	flag?:true,
	id?:true,
	name?:true,
	phone_code?:true,
	publish?:true,
	sort_name?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.countries" */
["voyce_countries_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_countries"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.countries" */
["voyce_countries_on_conflict"]: {
	constraint:ValueTypes["voyce_countries_constraint"],
	update_columns:ValueTypes["voyce_countries_update_column"][],
	where?:ValueTypes["voyce_countries_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.countries". */
["voyce_countries_order_by"]: {
	date_create?:ValueTypes["order_by"],
	description?:ValueTypes["order_by"],
	feature?:ValueTypes["order_by"],
	flag?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	name?:ValueTypes["order_by"],
	phone_code?:ValueTypes["order_by"],
	publish?:ValueTypes["order_by"],
	sort_name?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.countries */
["voyce_countries_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.countries" */
["voyce_countries_select_column"]:voyce_countries_select_column;
	/** input type for updating data in table "voyce.countries" */
["voyce_countries_set_input"]: {
	date_create?:ValueTypes["timestamp"],
	description?:string,
	feature?:ValueTypes["smallint"],
	flag?:string,
	id?:number,
	name?:string,
	phone_code?:number,
	publish?:ValueTypes["smallint"],
	sort_name?:string
};
	/** aggregate stddev on columns */
["voyce_countries_stddev_fields"]: AliasType<{
	feature?:true,
	id?:true,
	phone_code?:true,
	publish?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_countries_stddev_pop_fields"]: AliasType<{
	feature?:true,
	id?:true,
	phone_code?:true,
	publish?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_countries_stddev_samp_fields"]: AliasType<{
	feature?:true,
	id?:true,
	phone_code?:true,
	publish?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_countries" */
["voyce_countries_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_countries_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_countries_stream_cursor_value_input"]: {
	date_create?:ValueTypes["timestamp"],
	description?:string,
	feature?:ValueTypes["smallint"],
	flag?:string,
	id?:number,
	name?:string,
	phone_code?:number,
	publish?:ValueTypes["smallint"],
	sort_name?:string
};
	/** aggregate sum on columns */
["voyce_countries_sum_fields"]: AliasType<{
	feature?:true,
	id?:true,
	phone_code?:true,
	publish?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.countries" */
["voyce_countries_update_column"]:voyce_countries_update_column;
	["voyce_countries_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_countries_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_countries_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_countries_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_countries_var_pop_fields"]: AliasType<{
	feature?:true,
	id?:true,
	phone_code?:true,
	publish?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_countries_var_samp_fields"]: AliasType<{
	feature?:true,
	id?:true,
	phone_code?:true,
	publish?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_countries_variance_fields"]: AliasType<{
	feature?:true,
	id?:true,
	phone_code?:true,
	publish?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.csat_ratings" */
["voyce_csat_ratings"]: AliasType<{
	cancel_text?:true,
	comment?:true,
	created_at?:true,
data?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_csat_ratings_data_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_csat_ratings_data_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_csat_ratings_data_bool_exp"]},ValueTypes["voyce_csat_ratings_data"]],
data_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_csat_ratings_data_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_csat_ratings_data_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_csat_ratings_data_bool_exp"]},ValueTypes["voyce_csat_ratings_data_aggregate"]],
	desc?:true,
	id?:true,
	screen?:true,
	title?:true,
	version?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.csat_ratings" */
["voyce_csat_ratings_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_csat_ratings_aggregate_fields"],
	nodes?:ValueTypes["voyce_csat_ratings"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.csat_ratings" */
["voyce_csat_ratings_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_csat_ratings_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_csat_ratings_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_csat_ratings_max_fields"],
	min?:ValueTypes["voyce_csat_ratings_min_fields"],
	stddev?:ValueTypes["voyce_csat_ratings_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_csat_ratings_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_csat_ratings_stddev_samp_fields"],
	sum?:ValueTypes["voyce_csat_ratings_sum_fields"],
	var_pop?:ValueTypes["voyce_csat_ratings_var_pop_fields"],
	var_samp?:ValueTypes["voyce_csat_ratings_var_samp_fields"],
	variance?:ValueTypes["voyce_csat_ratings_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_csat_ratings_avg_fields"]: AliasType<{
	id?:true,
	screen?:true,
	version?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.csat_ratings". All fields are combined with a logical 'AND'. */
["voyce_csat_ratings_bool_exp"]: {
	_and?:ValueTypes["voyce_csat_ratings_bool_exp"][],
	_not?:ValueTypes["voyce_csat_ratings_bool_exp"],
	_or?:ValueTypes["voyce_csat_ratings_bool_exp"][],
	cancel_text?:ValueTypes["String_comparison_exp"],
	comment?:ValueTypes["Boolean_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	data?:ValueTypes["voyce_csat_ratings_data_bool_exp"],
	data_aggregate?:ValueTypes["voyce_csat_ratings_data_aggregate_bool_exp"],
	desc?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	screen?:ValueTypes["Int_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	version?:ValueTypes["smallint_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.csat_ratings" */
["voyce_csat_ratings_constraint"]:voyce_csat_ratings_constraint;
	/** columns and relationships of "voyce.csat_ratings_data" */
["voyce_csat_ratings_data"]: AliasType<{
	created_at?:true,
	csat_rating_id?:true,
	id?:true,
	image?:true,
	label?:true,
	order?:true,
	rating?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_csat_ratings_data_aggregate_fields"],
	nodes?:ValueTypes["voyce_csat_ratings_data"],
		__typename?: true
}>;
	["voyce_csat_ratings_data_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_csat_ratings_data_aggregate_bool_exp_count"]
};
	["voyce_csat_ratings_data_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_csat_ratings_data_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_csat_ratings_data_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_csat_ratings_data_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_csat_ratings_data_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_csat_ratings_data_max_fields"],
	min?:ValueTypes["voyce_csat_ratings_data_min_fields"],
	stddev?:ValueTypes["voyce_csat_ratings_data_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_csat_ratings_data_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_csat_ratings_data_stddev_samp_fields"],
	sum?:ValueTypes["voyce_csat_ratings_data_sum_fields"],
	var_pop?:ValueTypes["voyce_csat_ratings_data_var_pop_fields"],
	var_samp?:ValueTypes["voyce_csat_ratings_data_var_samp_fields"],
	variance?:ValueTypes["voyce_csat_ratings_data_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_csat_ratings_data_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_csat_ratings_data_max_order_by"],
	min?:ValueTypes["voyce_csat_ratings_data_min_order_by"],
	stddev?:ValueTypes["voyce_csat_ratings_data_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_csat_ratings_data_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_csat_ratings_data_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_csat_ratings_data_sum_order_by"],
	var_pop?:ValueTypes["voyce_csat_ratings_data_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_csat_ratings_data_var_samp_order_by"],
	variance?:ValueTypes["voyce_csat_ratings_data_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_csat_ratings_data_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_csat_ratings_data_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_csat_ratings_data_avg_fields"]: AliasType<{
	csat_rating_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_avg_order_by"]: {
	csat_rating_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.csat_ratings_data". All fields are combined with a logical 'AND'. */
["voyce_csat_ratings_data_bool_exp"]: {
	_and?:ValueTypes["voyce_csat_ratings_data_bool_exp"][],
	_not?:ValueTypes["voyce_csat_ratings_data_bool_exp"],
	_or?:ValueTypes["voyce_csat_ratings_data_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	csat_rating_id?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	image?:ValueTypes["String_comparison_exp"],
	label?:ValueTypes["String_comparison_exp"],
	order?:ValueTypes["smallint_comparison_exp"],
	rating?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_constraint"]:voyce_csat_ratings_data_constraint;
	/** input type for incrementing numeric columns in table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_inc_input"]: {
	csat_rating_id?:number,
	id?:number,
	order?:ValueTypes["smallint"]
};
	/** input type for inserting data into table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	csat_rating_id?:number,
	id?:number,
	image?:string,
	label?:string,
	order?:ValueTypes["smallint"],
	rating?:string
};
	/** aggregate max on columns */
["voyce_csat_ratings_data_max_fields"]: AliasType<{
	created_at?:true,
	csat_rating_id?:true,
	id?:true,
	image?:true,
	label?:true,
	order?:true,
	rating?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_max_order_by"]: {
	created_at?:ValueTypes["order_by"],
	csat_rating_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	label?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	rating?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_csat_ratings_data_min_fields"]: AliasType<{
	created_at?:true,
	csat_rating_id?:true,
	id?:true,
	image?:true,
	label?:true,
	order?:true,
	rating?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_min_order_by"]: {
	created_at?:ValueTypes["order_by"],
	csat_rating_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	label?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	rating?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_csat_ratings_data"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_on_conflict"]: {
	constraint:ValueTypes["voyce_csat_ratings_data_constraint"],
	update_columns:ValueTypes["voyce_csat_ratings_data_update_column"][],
	where?:ValueTypes["voyce_csat_ratings_data_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.csat_ratings_data". */
["voyce_csat_ratings_data_order_by"]: {
	created_at?:ValueTypes["order_by"],
	csat_rating_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	label?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	rating?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.csat_ratings_data */
["voyce_csat_ratings_data_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_select_column"]:voyce_csat_ratings_data_select_column;
	/** input type for updating data in table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	csat_rating_id?:number,
	id?:number,
	image?:string,
	label?:string,
	order?:ValueTypes["smallint"],
	rating?:string
};
	/** aggregate stddev on columns */
["voyce_csat_ratings_data_stddev_fields"]: AliasType<{
	csat_rating_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_stddev_order_by"]: {
	csat_rating_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_csat_ratings_data_stddev_pop_fields"]: AliasType<{
	csat_rating_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_stddev_pop_order_by"]: {
	csat_rating_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_csat_ratings_data_stddev_samp_fields"]: AliasType<{
	csat_rating_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_stddev_samp_order_by"]: {
	csat_rating_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_csat_ratings_data" */
["voyce_csat_ratings_data_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_csat_ratings_data_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_csat_ratings_data_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	csat_rating_id?:number,
	id?:number,
	image?:string,
	label?:string,
	order?:ValueTypes["smallint"],
	rating?:string
};
	/** aggregate sum on columns */
["voyce_csat_ratings_data_sum_fields"]: AliasType<{
	csat_rating_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_sum_order_by"]: {
	csat_rating_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_update_column"]:voyce_csat_ratings_data_update_column;
	["voyce_csat_ratings_data_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_csat_ratings_data_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_csat_ratings_data_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_csat_ratings_data_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_csat_ratings_data_var_pop_fields"]: AliasType<{
	csat_rating_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_var_pop_order_by"]: {
	csat_rating_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_csat_ratings_data_var_samp_fields"]: AliasType<{
	csat_rating_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_var_samp_order_by"]: {
	csat_rating_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_csat_ratings_data_variance_fields"]: AliasType<{
	csat_rating_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_variance_order_by"]: {
	csat_rating_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"]
};
	/** input type for incrementing numeric columns in table "voyce.csat_ratings" */
["voyce_csat_ratings_inc_input"]: {
	id?:number,
	screen?:number,
	version?:ValueTypes["smallint"]
};
	/** input type for inserting data into table "voyce.csat_ratings" */
["voyce_csat_ratings_insert_input"]: {
	cancel_text?:string,
	comment?:boolean,
	created_at?:ValueTypes["timestamptz"],
	data?:ValueTypes["voyce_csat_ratings_data_arr_rel_insert_input"],
	desc?:string,
	id?:number,
	screen?:number,
	title?:string,
	version?:ValueTypes["smallint"]
};
	/** aggregate max on columns */
["voyce_csat_ratings_max_fields"]: AliasType<{
	cancel_text?:true,
	created_at?:true,
	desc?:true,
	id?:true,
	screen?:true,
	title?:true,
	version?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_csat_ratings_min_fields"]: AliasType<{
	cancel_text?:true,
	created_at?:true,
	desc?:true,
	id?:true,
	screen?:true,
	title?:true,
	version?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.csat_ratings" */
["voyce_csat_ratings_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_csat_ratings"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.csat_ratings" */
["voyce_csat_ratings_on_conflict"]: {
	constraint:ValueTypes["voyce_csat_ratings_constraint"],
	update_columns:ValueTypes["voyce_csat_ratings_update_column"][],
	where?:ValueTypes["voyce_csat_ratings_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.csat_ratings". */
["voyce_csat_ratings_order_by"]: {
	cancel_text?:ValueTypes["order_by"],
	comment?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	data_aggregate?:ValueTypes["voyce_csat_ratings_data_aggregate_order_by"],
	desc?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	screen?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	version?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.csat_ratings */
["voyce_csat_ratings_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.csat_ratings" */
["voyce_csat_ratings_select_column"]:voyce_csat_ratings_select_column;
	/** input type for updating data in table "voyce.csat_ratings" */
["voyce_csat_ratings_set_input"]: {
	cancel_text?:string,
	comment?:boolean,
	created_at?:ValueTypes["timestamptz"],
	desc?:string,
	id?:number,
	screen?:number,
	title?:string,
	version?:ValueTypes["smallint"]
};
	/** aggregate stddev on columns */
["voyce_csat_ratings_stddev_fields"]: AliasType<{
	id?:true,
	screen?:true,
	version?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_csat_ratings_stddev_pop_fields"]: AliasType<{
	id?:true,
	screen?:true,
	version?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_csat_ratings_stddev_samp_fields"]: AliasType<{
	id?:true,
	screen?:true,
	version?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_csat_ratings" */
["voyce_csat_ratings_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_csat_ratings_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_csat_ratings_stream_cursor_value_input"]: {
	cancel_text?:string,
	comment?:boolean,
	created_at?:ValueTypes["timestamptz"],
	desc?:string,
	id?:number,
	screen?:number,
	title?:string,
	version?:ValueTypes["smallint"]
};
	/** aggregate sum on columns */
["voyce_csat_ratings_sum_fields"]: AliasType<{
	id?:true,
	screen?:true,
	version?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.csat_ratings" */
["voyce_csat_ratings_update_column"]:voyce_csat_ratings_update_column;
	["voyce_csat_ratings_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_csat_ratings_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_csat_ratings_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_csat_ratings_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_csat_ratings_var_pop_fields"]: AliasType<{
	id?:true,
	screen?:true,
	version?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_csat_ratings_var_samp_fields"]: AliasType<{
	id?:true,
	screen?:true,
	version?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_csat_ratings_variance_fields"]: AliasType<{
	id?:true,
	screen?:true,
	version?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.education_types" */
["voyce_education_types"]: AliasType<{
	date_create?:true,
	id?:true,
	title?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.education_types" */
["voyce_education_types_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_education_types_aggregate_fields"],
	nodes?:ValueTypes["voyce_education_types"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.education_types" */
["voyce_education_types_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_education_types_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_education_types_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_education_types_max_fields"],
	min?:ValueTypes["voyce_education_types_min_fields"],
	stddev?:ValueTypes["voyce_education_types_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_education_types_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_education_types_stddev_samp_fields"],
	sum?:ValueTypes["voyce_education_types_sum_fields"],
	var_pop?:ValueTypes["voyce_education_types_var_pop_fields"],
	var_samp?:ValueTypes["voyce_education_types_var_samp_fields"],
	variance?:ValueTypes["voyce_education_types_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_education_types_avg_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.education_types". All fields are combined with a logical 'AND'. */
["voyce_education_types_bool_exp"]: {
	_and?:ValueTypes["voyce_education_types_bool_exp"][],
	_not?:ValueTypes["voyce_education_types_bool_exp"],
	_or?:ValueTypes["voyce_education_types_bool_exp"][],
	date_create?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.education_types" */
["voyce_education_types_constraint"]:voyce_education_types_constraint;
	/** input type for incrementing numeric columns in table "voyce.education_types" */
["voyce_education_types_inc_input"]: {
	id?:number
};
	/** input type for inserting data into table "voyce.education_types" */
["voyce_education_types_insert_input"]: {
	date_create?:ValueTypes["timestamp"],
	id?:number,
	title?:string
};
	/** aggregate max on columns */
["voyce_education_types_max_fields"]: AliasType<{
	date_create?:true,
	id?:true,
	title?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_education_types_min_fields"]: AliasType<{
	date_create?:true,
	id?:true,
	title?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.education_types" */
["voyce_education_types_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_education_types"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.education_types" */
["voyce_education_types_on_conflict"]: {
	constraint:ValueTypes["voyce_education_types_constraint"],
	update_columns:ValueTypes["voyce_education_types_update_column"][],
	where?:ValueTypes["voyce_education_types_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.education_types". */
["voyce_education_types_order_by"]: {
	date_create?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.education_types */
["voyce_education_types_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.education_types" */
["voyce_education_types_select_column"]:voyce_education_types_select_column;
	/** input type for updating data in table "voyce.education_types" */
["voyce_education_types_set_input"]: {
	date_create?:ValueTypes["timestamp"],
	id?:number,
	title?:string
};
	/** aggregate stddev on columns */
["voyce_education_types_stddev_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_education_types_stddev_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_education_types_stddev_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_education_types" */
["voyce_education_types_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_education_types_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_education_types_stream_cursor_value_input"]: {
	date_create?:ValueTypes["timestamp"],
	id?:number,
	title?:string
};
	/** aggregate sum on columns */
["voyce_education_types_sum_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.education_types" */
["voyce_education_types_update_column"]:voyce_education_types_update_column;
	["voyce_education_types_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_education_types_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_education_types_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_education_types_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_education_types_var_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_education_types_var_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_education_types_variance_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.erp_login_roles" */
["voyce_erp_login_roles"]: AliasType<{
	id?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.erp_login_roles" */
["voyce_erp_login_roles_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_erp_login_roles_aggregate_fields"],
	nodes?:ValueTypes["voyce_erp_login_roles"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.erp_login_roles" */
["voyce_erp_login_roles_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_erp_login_roles_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_erp_login_roles_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_erp_login_roles_max_fields"],
	min?:ValueTypes["voyce_erp_login_roles_min_fields"],
	stddev?:ValueTypes["voyce_erp_login_roles_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_erp_login_roles_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_erp_login_roles_stddev_samp_fields"],
	sum?:ValueTypes["voyce_erp_login_roles_sum_fields"],
	var_pop?:ValueTypes["voyce_erp_login_roles_var_pop_fields"],
	var_samp?:ValueTypes["voyce_erp_login_roles_var_samp_fields"],
	variance?:ValueTypes["voyce_erp_login_roles_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_erp_login_roles_avg_fields"]: AliasType<{
	id?:true,
	role_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.erp_login_roles". All fields are combined with a logical 'AND'. */
["voyce_erp_login_roles_bool_exp"]: {
	_and?:ValueTypes["voyce_erp_login_roles_bool_exp"][],
	_not?:ValueTypes["voyce_erp_login_roles_bool_exp"],
	_or?:ValueTypes["voyce_erp_login_roles_bool_exp"][],
	id?:ValueTypes["Int_comparison_exp"],
	role_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.erp_login_roles" */
["voyce_erp_login_roles_constraint"]:voyce_erp_login_roles_constraint;
	/** input type for incrementing numeric columns in table "voyce.erp_login_roles" */
["voyce_erp_login_roles_inc_input"]: {
	id?:number,
	role_id?:number
};
	/** input type for inserting data into table "voyce.erp_login_roles" */
["voyce_erp_login_roles_insert_input"]: {
	id?:number,
	role_id?:number
};
	/** aggregate max on columns */
["voyce_erp_login_roles_max_fields"]: AliasType<{
	id?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_erp_login_roles_min_fields"]: AliasType<{
	id?:true,
	role_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.erp_login_roles" */
["voyce_erp_login_roles_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_erp_login_roles"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.erp_login_roles" */
["voyce_erp_login_roles_on_conflict"]: {
	constraint:ValueTypes["voyce_erp_login_roles_constraint"],
	update_columns:ValueTypes["voyce_erp_login_roles_update_column"][],
	where?:ValueTypes["voyce_erp_login_roles_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.erp_login_roles". */
["voyce_erp_login_roles_order_by"]: {
	id?:ValueTypes["order_by"],
	role_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.erp_login_roles */
["voyce_erp_login_roles_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.erp_login_roles" */
["voyce_erp_login_roles_select_column"]:voyce_erp_login_roles_select_column;
	/** input type for updating data in table "voyce.erp_login_roles" */
["voyce_erp_login_roles_set_input"]: {
	id?:number,
	role_id?:number
};
	/** aggregate stddev on columns */
["voyce_erp_login_roles_stddev_fields"]: AliasType<{
	id?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_erp_login_roles_stddev_pop_fields"]: AliasType<{
	id?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_erp_login_roles_stddev_samp_fields"]: AliasType<{
	id?:true,
	role_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_erp_login_roles" */
["voyce_erp_login_roles_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_erp_login_roles_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_erp_login_roles_stream_cursor_value_input"]: {
	id?:number,
	role_id?:number
};
	/** aggregate sum on columns */
["voyce_erp_login_roles_sum_fields"]: AliasType<{
	id?:true,
	role_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.erp_login_roles" */
["voyce_erp_login_roles_update_column"]:voyce_erp_login_roles_update_column;
	["voyce_erp_login_roles_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_erp_login_roles_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_erp_login_roles_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_erp_login_roles_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_erp_login_roles_var_pop_fields"]: AliasType<{
	id?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_erp_login_roles_var_samp_fields"]: AliasType<{
	id?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_erp_login_roles_variance_fields"]: AliasType<{
	id?:true,
	role_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs"]: AliasType<{
	created_at?:true,
	glue_epoc?:true,
	id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_etl_series_views_logs_aggregate_fields"],
	nodes?:ValueTypes["voyce_etl_series_views_logs"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_etl_series_views_logs_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_etl_series_views_logs_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_etl_series_views_logs_max_fields"],
	min?:ValueTypes["voyce_etl_series_views_logs_min_fields"],
	stddev?:ValueTypes["voyce_etl_series_views_logs_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_etl_series_views_logs_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_etl_series_views_logs_stddev_samp_fields"],
	sum?:ValueTypes["voyce_etl_series_views_logs_sum_fields"],
	var_pop?:ValueTypes["voyce_etl_series_views_logs_var_pop_fields"],
	var_samp?:ValueTypes["voyce_etl_series_views_logs_var_samp_fields"],
	variance?:ValueTypes["voyce_etl_series_views_logs_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_etl_series_views_logs_avg_fields"]: AliasType<{
	glue_epoc?:true,
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.etl_series_views_logs". All fields are combined with a logical 'AND'. */
["voyce_etl_series_views_logs_bool_exp"]: {
	_and?:ValueTypes["voyce_etl_series_views_logs_bool_exp"][],
	_not?:ValueTypes["voyce_etl_series_views_logs_bool_exp"],
	_or?:ValueTypes["voyce_etl_series_views_logs_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	glue_epoc?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_constraint"]:voyce_etl_series_views_logs_constraint;
	/** input type for incrementing numeric columns in table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_inc_input"]: {
	glue_epoc?:number,
	id?:number
};
	/** input type for inserting data into table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	glue_epoc?:number,
	id?:number
};
	/** aggregate max on columns */
["voyce_etl_series_views_logs_max_fields"]: AliasType<{
	created_at?:true,
	glue_epoc?:true,
	id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_etl_series_views_logs_min_fields"]: AliasType<{
	created_at?:true,
	glue_epoc?:true,
	id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_etl_series_views_logs"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_on_conflict"]: {
	constraint:ValueTypes["voyce_etl_series_views_logs_constraint"],
	update_columns:ValueTypes["voyce_etl_series_views_logs_update_column"][],
	where?:ValueTypes["voyce_etl_series_views_logs_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.etl_series_views_logs". */
["voyce_etl_series_views_logs_order_by"]: {
	created_at?:ValueTypes["order_by"],
	glue_epoc?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.etl_series_views_logs */
["voyce_etl_series_views_logs_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_select_column"]:voyce_etl_series_views_logs_select_column;
	/** input type for updating data in table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	glue_epoc?:number,
	id?:number
};
	/** aggregate stddev on columns */
["voyce_etl_series_views_logs_stddev_fields"]: AliasType<{
	glue_epoc?:true,
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_etl_series_views_logs_stddev_pop_fields"]: AliasType<{
	glue_epoc?:true,
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_etl_series_views_logs_stddev_samp_fields"]: AliasType<{
	glue_epoc?:true,
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_etl_series_views_logs" */
["voyce_etl_series_views_logs_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_etl_series_views_logs_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_etl_series_views_logs_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	glue_epoc?:number,
	id?:number
};
	/** aggregate sum on columns */
["voyce_etl_series_views_logs_sum_fields"]: AliasType<{
	glue_epoc?:true,
	id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_update_column"]:voyce_etl_series_views_logs_update_column;
	["voyce_etl_series_views_logs_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_etl_series_views_logs_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_etl_series_views_logs_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_etl_series_views_logs_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_etl_series_views_logs_var_pop_fields"]: AliasType<{
	glue_epoc?:true,
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_etl_series_views_logs_var_samp_fields"]: AliasType<{
	glue_epoc?:true,
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_etl_series_views_logs_variance_fields"]: AliasType<{
	glue_epoc?:true,
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.event_schedules" */
["voyce_event_schedules"]: AliasType<{
	active?:true,
	bg_image?:true,
	created_at?:true,
	date_time?:true,
	description?:true,
	id?:true,
	link?:true,
	main_event?:true,
	main_image?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.event_schedules" */
["voyce_event_schedules_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_event_schedules_aggregate_fields"],
	nodes?:ValueTypes["voyce_event_schedules"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.event_schedules" */
["voyce_event_schedules_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_event_schedules_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_event_schedules_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_event_schedules_max_fields"],
	min?:ValueTypes["voyce_event_schedules_min_fields"],
	stddev?:ValueTypes["voyce_event_schedules_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_event_schedules_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_event_schedules_stddev_samp_fields"],
	sum?:ValueTypes["voyce_event_schedules_sum_fields"],
	var_pop?:ValueTypes["voyce_event_schedules_var_pop_fields"],
	var_samp?:ValueTypes["voyce_event_schedules_var_samp_fields"],
	variance?:ValueTypes["voyce_event_schedules_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_event_schedules_avg_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.event_schedules". All fields are combined with a logical 'AND'. */
["voyce_event_schedules_bool_exp"]: {
	_and?:ValueTypes["voyce_event_schedules_bool_exp"][],
	_not?:ValueTypes["voyce_event_schedules_bool_exp"],
	_or?:ValueTypes["voyce_event_schedules_bool_exp"][],
	active?:ValueTypes["Boolean_comparison_exp"],
	bg_image?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	date_time?:ValueTypes["timestamptz_comparison_exp"],
	description?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	link?:ValueTypes["String_comparison_exp"],
	main_event?:ValueTypes["Boolean_comparison_exp"],
	main_image?:ValueTypes["String_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.event_schedules" */
["voyce_event_schedules_constraint"]:voyce_event_schedules_constraint;
	/** input type for incrementing numeric columns in table "voyce.event_schedules" */
["voyce_event_schedules_inc_input"]: {
	id?:number
};
	/** input type for inserting data into table "voyce.event_schedules" */
["voyce_event_schedules_insert_input"]: {
	active?:boolean,
	bg_image?:string,
	created_at?:ValueTypes["timestamptz"],
	date_time?:ValueTypes["timestamptz"],
	description?:string,
	id?:number,
	link?:string,
	main_event?:boolean,
	main_image?:string,
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_event_schedules_max_fields"]: AliasType<{
	bg_image?:true,
	created_at?:true,
	date_time?:true,
	description?:true,
	id?:true,
	link?:true,
	main_image?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_event_schedules_min_fields"]: AliasType<{
	bg_image?:true,
	created_at?:true,
	date_time?:true,
	description?:true,
	id?:true,
	link?:true,
	main_image?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.event_schedules" */
["voyce_event_schedules_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_event_schedules"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.event_schedules" */
["voyce_event_schedules_on_conflict"]: {
	constraint:ValueTypes["voyce_event_schedules_constraint"],
	update_columns:ValueTypes["voyce_event_schedules_update_column"][],
	where?:ValueTypes["voyce_event_schedules_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.event_schedules". */
["voyce_event_schedules_order_by"]: {
	active?:ValueTypes["order_by"],
	bg_image?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	date_time?:ValueTypes["order_by"],
	description?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	link?:ValueTypes["order_by"],
	main_event?:ValueTypes["order_by"],
	main_image?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.event_schedules */
["voyce_event_schedules_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.event_schedules" */
["voyce_event_schedules_select_column"]:voyce_event_schedules_select_column;
	/** input type for updating data in table "voyce.event_schedules" */
["voyce_event_schedules_set_input"]: {
	active?:boolean,
	bg_image?:string,
	created_at?:ValueTypes["timestamptz"],
	date_time?:ValueTypes["timestamptz"],
	description?:string,
	id?:number,
	link?:string,
	main_event?:boolean,
	main_image?:string,
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_event_schedules_stddev_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_event_schedules_stddev_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_event_schedules_stddev_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_event_schedules" */
["voyce_event_schedules_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_event_schedules_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_event_schedules_stream_cursor_value_input"]: {
	active?:boolean,
	bg_image?:string,
	created_at?:ValueTypes["timestamptz"],
	date_time?:ValueTypes["timestamptz"],
	description?:string,
	id?:number,
	link?:string,
	main_event?:boolean,
	main_image?:string,
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_event_schedules_sum_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.event_schedules" */
["voyce_event_schedules_update_column"]:voyce_event_schedules_update_column;
	["voyce_event_schedules_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_event_schedules_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_event_schedules_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_event_schedules_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_event_schedules_var_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_event_schedules_var_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_event_schedules_variance_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.genres" */
["voyce_genres"]: AliasType<{
	color?:true,
	created_at?:true,
	id?:true,
	is_trending?:true,
	klaviyo_id?:true,
	thumbnail?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.genres" */
["voyce_genres_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_genres_aggregate_fields"],
	nodes?:ValueTypes["voyce_genres"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.genres" */
["voyce_genres_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_genres_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_genres_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_genres_max_fields"],
	min?:ValueTypes["voyce_genres_min_fields"],
	stddev?:ValueTypes["voyce_genres_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_genres_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_genres_stddev_samp_fields"],
	sum?:ValueTypes["voyce_genres_sum_fields"],
	var_pop?:ValueTypes["voyce_genres_var_pop_fields"],
	var_samp?:ValueTypes["voyce_genres_var_samp_fields"],
	variance?:ValueTypes["voyce_genres_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_genres_avg_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.genres". All fields are combined with a logical 'AND'. */
["voyce_genres_bool_exp"]: {
	_and?:ValueTypes["voyce_genres_bool_exp"][],
	_not?:ValueTypes["voyce_genres_bool_exp"],
	_or?:ValueTypes["voyce_genres_bool_exp"][],
	color?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_trending?:ValueTypes["Boolean_comparison_exp"],
	klaviyo_id?:ValueTypes["String_comparison_exp"],
	thumbnail?:ValueTypes["String_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamp_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.genres" */
["voyce_genres_constraint"]:voyce_genres_constraint;
	/** input type for incrementing numeric columns in table "voyce.genres" */
["voyce_genres_inc_input"]: {
	id?:number
};
	/** input type for inserting data into table "voyce.genres" */
["voyce_genres_insert_input"]: {
	color?:string,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	is_trending?:boolean,
	klaviyo_id?:string,
	thumbnail?:string,
	title?:string,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate max on columns */
["voyce_genres_max_fields"]: AliasType<{
	color?:true,
	created_at?:true,
	id?:true,
	klaviyo_id?:true,
	thumbnail?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_genres_min_fields"]: AliasType<{
	color?:true,
	created_at?:true,
	id?:true,
	klaviyo_id?:true,
	thumbnail?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.genres" */
["voyce_genres_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_genres"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.genres" */
["voyce_genres_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_genres_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_genres_on_conflict"]
};
	/** on_conflict condition type for table "voyce.genres" */
["voyce_genres_on_conflict"]: {
	constraint:ValueTypes["voyce_genres_constraint"],
	update_columns:ValueTypes["voyce_genres_update_column"][],
	where?:ValueTypes["voyce_genres_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.genres". */
["voyce_genres_order_by"]: {
	color?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_trending?:ValueTypes["order_by"],
	klaviyo_id?:ValueTypes["order_by"],
	thumbnail?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.genres */
["voyce_genres_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.genres" */
["voyce_genres_select_column"]:voyce_genres_select_column;
	/** input type for updating data in table "voyce.genres" */
["voyce_genres_set_input"]: {
	color?:string,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	is_trending?:boolean,
	klaviyo_id?:string,
	thumbnail?:string,
	title?:string,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate stddev on columns */
["voyce_genres_stddev_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_genres_stddev_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_genres_stddev_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_genres" */
["voyce_genres_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_genres_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_genres_stream_cursor_value_input"]: {
	color?:string,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	is_trending?:boolean,
	klaviyo_id?:string,
	thumbnail?:string,
	title?:string,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate sum on columns */
["voyce_genres_sum_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.genres" */
["voyce_genres_update_column"]:voyce_genres_update_column;
	["voyce_genres_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_genres_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_genres_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_genres_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_genres_var_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_genres_var_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_genres_variance_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.guest_gtags" */
["voyce_guest_gtags"]: AliasType<{
	created_at?:true,
	device_id?:true,
emails?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_popup_emails_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_popup_emails_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_popup_emails_bool_exp"]},ValueTypes["voyce_popup_emails"]],
emails_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_popup_emails_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_popup_emails_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_popup_emails_bool_exp"]},ValueTypes["voyce_popup_emails_aggregate"]],
	gtag?:true,
	gtag_v2?:true,
	id?:true,
	klaviyo_id?:true,
	source?:true,
	user_ip?:true,
	username?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.guest_gtags" */
["voyce_guest_gtags_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_guest_gtags_aggregate_fields"],
	nodes?:ValueTypes["voyce_guest_gtags"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.guest_gtags" */
["voyce_guest_gtags_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_guest_gtags_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_guest_gtags_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_guest_gtags_max_fields"],
	min?:ValueTypes["voyce_guest_gtags_min_fields"],
	stddev?:ValueTypes["voyce_guest_gtags_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_guest_gtags_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_guest_gtags_stddev_samp_fields"],
	sum?:ValueTypes["voyce_guest_gtags_sum_fields"],
	var_pop?:ValueTypes["voyce_guest_gtags_var_pop_fields"],
	var_samp?:ValueTypes["voyce_guest_gtags_var_samp_fields"],
	variance?:ValueTypes["voyce_guest_gtags_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_guest_gtags_avg_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.guest_gtags". All fields are combined with a logical 'AND'. */
["voyce_guest_gtags_bool_exp"]: {
	_and?:ValueTypes["voyce_guest_gtags_bool_exp"][],
	_not?:ValueTypes["voyce_guest_gtags_bool_exp"],
	_or?:ValueTypes["voyce_guest_gtags_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	device_id?:ValueTypes["String_comparison_exp"],
	emails?:ValueTypes["voyce_popup_emails_bool_exp"],
	emails_aggregate?:ValueTypes["voyce_popup_emails_aggregate_bool_exp"],
	gtag?:ValueTypes["String_comparison_exp"],
	gtag_v2?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	klaviyo_id?:ValueTypes["String_comparison_exp"],
	source?:ValueTypes["String_comparison_exp"],
	user_ip?:ValueTypes["String_comparison_exp"],
	username?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.guest_gtags" */
["voyce_guest_gtags_constraint"]:voyce_guest_gtags_constraint;
	/** input type for incrementing numeric columns in table "voyce.guest_gtags" */
["voyce_guest_gtags_inc_input"]: {
	id?:number
};
	/** input type for inserting data into table "voyce.guest_gtags" */
["voyce_guest_gtags_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	device_id?:string,
	emails?:ValueTypes["voyce_popup_emails_arr_rel_insert_input"],
	gtag?:string,
	gtag_v2?:string,
	id?:number,
	klaviyo_id?:string,
	source?:string,
	user_ip?:string,
	username?:string
};
	/** aggregate max on columns */
["voyce_guest_gtags_max_fields"]: AliasType<{
	created_at?:true,
	device_id?:true,
	gtag?:true,
	gtag_v2?:true,
	id?:true,
	klaviyo_id?:true,
	source?:true,
	user_ip?:true,
	username?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_guest_gtags_min_fields"]: AliasType<{
	created_at?:true,
	device_id?:true,
	gtag?:true,
	gtag_v2?:true,
	id?:true,
	klaviyo_id?:true,
	source?:true,
	user_ip?:true,
	username?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.guest_gtags" */
["voyce_guest_gtags_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_guest_gtags"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.guest_gtags" */
["voyce_guest_gtags_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_guest_gtags_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_guest_gtags_on_conflict"]
};
	/** on_conflict condition type for table "voyce.guest_gtags" */
["voyce_guest_gtags_on_conflict"]: {
	constraint:ValueTypes["voyce_guest_gtags_constraint"],
	update_columns:ValueTypes["voyce_guest_gtags_update_column"][],
	where?:ValueTypes["voyce_guest_gtags_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.guest_gtags". */
["voyce_guest_gtags_order_by"]: {
	created_at?:ValueTypes["order_by"],
	device_id?:ValueTypes["order_by"],
	emails_aggregate?:ValueTypes["voyce_popup_emails_aggregate_order_by"],
	gtag?:ValueTypes["order_by"],
	gtag_v2?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	klaviyo_id?:ValueTypes["order_by"],
	source?:ValueTypes["order_by"],
	user_ip?:ValueTypes["order_by"],
	username?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.guest_gtags */
["voyce_guest_gtags_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.guest_gtags" */
["voyce_guest_gtags_select_column"]:voyce_guest_gtags_select_column;
	/** input type for updating data in table "voyce.guest_gtags" */
["voyce_guest_gtags_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	device_id?:string,
	gtag?:string,
	gtag_v2?:string,
	id?:number,
	klaviyo_id?:string,
	source?:string,
	user_ip?:string,
	username?:string
};
	/** aggregate stddev on columns */
["voyce_guest_gtags_stddev_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_guest_gtags_stddev_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_guest_gtags_stddev_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_guest_gtags" */
["voyce_guest_gtags_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_guest_gtags_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_guest_gtags_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	device_id?:string,
	gtag?:string,
	gtag_v2?:string,
	id?:number,
	klaviyo_id?:string,
	source?:string,
	user_ip?:string,
	username?:string
};
	/** aggregate sum on columns */
["voyce_guest_gtags_sum_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.guest_gtags" */
["voyce_guest_gtags_update_column"]:voyce_guest_gtags_update_column;
	["voyce_guest_gtags_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_guest_gtags_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_guest_gtags_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_guest_gtags_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_guest_gtags_var_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_guest_gtags_var_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_guest_gtags_variance_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.metabases" */
["voyce_metabases"]: AliasType<{
	/** An object relationship */
	access?:ValueTypes["voyce_metabases_access"],
	created_at?:true,
	dashboard_id?:true,
	id?:true,
	is_active?:true,
	order?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.metabases_access" */
["voyce_metabases_access"]: AliasType<{
	created_at?:true,
	id?:true,
	metabase_id?:true,
	role_id?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.metabases_access" */
["voyce_metabases_access_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_metabases_access_aggregate_fields"],
	nodes?:ValueTypes["voyce_metabases_access"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.metabases_access" */
["voyce_metabases_access_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_metabases_access_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_metabases_access_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_metabases_access_max_fields"],
	min?:ValueTypes["voyce_metabases_access_min_fields"],
	stddev?:ValueTypes["voyce_metabases_access_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_metabases_access_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_metabases_access_stddev_samp_fields"],
	sum?:ValueTypes["voyce_metabases_access_sum_fields"],
	var_pop?:ValueTypes["voyce_metabases_access_var_pop_fields"],
	var_samp?:ValueTypes["voyce_metabases_access_var_samp_fields"],
	variance?:ValueTypes["voyce_metabases_access_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_metabases_access_avg_fields"]: AliasType<{
	id?:true,
	metabase_id?:true,
	role_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.metabases_access". All fields are combined with a logical 'AND'. */
["voyce_metabases_access_bool_exp"]: {
	_and?:ValueTypes["voyce_metabases_access_bool_exp"][],
	_not?:ValueTypes["voyce_metabases_access_bool_exp"],
	_or?:ValueTypes["voyce_metabases_access_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	metabase_id?:ValueTypes["Int_comparison_exp"],
	role_id?:ValueTypes["Int_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.metabases_access" */
["voyce_metabases_access_constraint"]:voyce_metabases_access_constraint;
	/** input type for incrementing numeric columns in table "voyce.metabases_access" */
["voyce_metabases_access_inc_input"]: {
	id?:number,
	metabase_id?:number,
	role_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.metabases_access" */
["voyce_metabases_access_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	metabase_id?:number,
	role_id?:number,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_metabases_access_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	metabase_id?:true,
	role_id?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_metabases_access_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	metabase_id?:true,
	role_id?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.metabases_access" */
["voyce_metabases_access_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_metabases_access"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.metabases_access" */
["voyce_metabases_access_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_metabases_access_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_metabases_access_on_conflict"]
};
	/** on_conflict condition type for table "voyce.metabases_access" */
["voyce_metabases_access_on_conflict"]: {
	constraint:ValueTypes["voyce_metabases_access_constraint"],
	update_columns:ValueTypes["voyce_metabases_access_update_column"][],
	where?:ValueTypes["voyce_metabases_access_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.metabases_access". */
["voyce_metabases_access_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	metabase_id?:ValueTypes["order_by"],
	role_id?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.metabases_access */
["voyce_metabases_access_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.metabases_access" */
["voyce_metabases_access_select_column"]:voyce_metabases_access_select_column;
	/** input type for updating data in table "voyce.metabases_access" */
["voyce_metabases_access_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	metabase_id?:number,
	role_id?:number,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_metabases_access_stddev_fields"]: AliasType<{
	id?:true,
	metabase_id?:true,
	role_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_metabases_access_stddev_pop_fields"]: AliasType<{
	id?:true,
	metabase_id?:true,
	role_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_metabases_access_stddev_samp_fields"]: AliasType<{
	id?:true,
	metabase_id?:true,
	role_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_metabases_access" */
["voyce_metabases_access_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_metabases_access_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_metabases_access_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	metabase_id?:number,
	role_id?:number,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_metabases_access_sum_fields"]: AliasType<{
	id?:true,
	metabase_id?:true,
	role_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.metabases_access" */
["voyce_metabases_access_update_column"]:voyce_metabases_access_update_column;
	["voyce_metabases_access_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_metabases_access_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_metabases_access_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_metabases_access_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_metabases_access_var_pop_fields"]: AliasType<{
	id?:true,
	metabase_id?:true,
	role_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_metabases_access_var_samp_fields"]: AliasType<{
	id?:true,
	metabase_id?:true,
	role_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_metabases_access_variance_fields"]: AliasType<{
	id?:true,
	metabase_id?:true,
	role_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.metabases" */
["voyce_metabases_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_metabases_aggregate_fields"],
	nodes?:ValueTypes["voyce_metabases"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.metabases" */
["voyce_metabases_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_metabases_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_metabases_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_metabases_max_fields"],
	min?:ValueTypes["voyce_metabases_min_fields"],
	stddev?:ValueTypes["voyce_metabases_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_metabases_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_metabases_stddev_samp_fields"],
	sum?:ValueTypes["voyce_metabases_sum_fields"],
	var_pop?:ValueTypes["voyce_metabases_var_pop_fields"],
	var_samp?:ValueTypes["voyce_metabases_var_samp_fields"],
	variance?:ValueTypes["voyce_metabases_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_metabases_avg_fields"]: AliasType<{
	dashboard_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.metabases". All fields are combined with a logical 'AND'. */
["voyce_metabases_bool_exp"]: {
	_and?:ValueTypes["voyce_metabases_bool_exp"][],
	_not?:ValueTypes["voyce_metabases_bool_exp"],
	_or?:ValueTypes["voyce_metabases_bool_exp"][],
	access?:ValueTypes["voyce_metabases_access_bool_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	dashboard_id?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_active?:ValueTypes["Boolean_comparison_exp"],
	order?:ValueTypes["Int_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.metabases" */
["voyce_metabases_constraint"]:voyce_metabases_constraint;
	/** input type for incrementing numeric columns in table "voyce.metabases" */
["voyce_metabases_inc_input"]: {
	dashboard_id?:number,
	id?:number,
	order?:number
};
	/** input type for inserting data into table "voyce.metabases" */
["voyce_metabases_insert_input"]: {
	access?:ValueTypes["voyce_metabases_access_obj_rel_insert_input"],
	created_at?:ValueTypes["timestamptz"],
	dashboard_id?:number,
	id?:number,
	is_active?:boolean,
	order?:number,
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_metabases_max_fields"]: AliasType<{
	created_at?:true,
	dashboard_id?:true,
	id?:true,
	order?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_metabases_min_fields"]: AliasType<{
	created_at?:true,
	dashboard_id?:true,
	id?:true,
	order?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.metabases" */
["voyce_metabases_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_metabases"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.metabases" */
["voyce_metabases_on_conflict"]: {
	constraint:ValueTypes["voyce_metabases_constraint"],
	update_columns:ValueTypes["voyce_metabases_update_column"][],
	where?:ValueTypes["voyce_metabases_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.metabases". */
["voyce_metabases_order_by"]: {
	access?:ValueTypes["voyce_metabases_access_order_by"],
	created_at?:ValueTypes["order_by"],
	dashboard_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_active?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.metabases */
["voyce_metabases_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.metabases" */
["voyce_metabases_select_column"]:voyce_metabases_select_column;
	/** input type for updating data in table "voyce.metabases" */
["voyce_metabases_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	dashboard_id?:number,
	id?:number,
	is_active?:boolean,
	order?:number,
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_metabases_stddev_fields"]: AliasType<{
	dashboard_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_metabases_stddev_pop_fields"]: AliasType<{
	dashboard_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_metabases_stddev_samp_fields"]: AliasType<{
	dashboard_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_metabases" */
["voyce_metabases_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_metabases_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_metabases_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	dashboard_id?:number,
	id?:number,
	is_active?:boolean,
	order?:number,
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_metabases_sum_fields"]: AliasType<{
	dashboard_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.metabases" */
["voyce_metabases_update_column"]:voyce_metabases_update_column;
	["voyce_metabases_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_metabases_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_metabases_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_metabases_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_metabases_var_pop_fields"]: AliasType<{
	dashboard_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_metabases_var_samp_fields"]: AliasType<{
	dashboard_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_metabases_variance_fields"]: AliasType<{
	dashboard_id?:true,
	id?:true,
	order?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.newsletter" */
["voyce_newsletter"]: AliasType<{
	created_at?:true,
	email?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.newsletter" */
["voyce_newsletter_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_newsletter_aggregate_fields"],
	nodes?:ValueTypes["voyce_newsletter"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.newsletter" */
["voyce_newsletter_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_newsletter_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_newsletter_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_newsletter_max_fields"],
	min?:ValueTypes["voyce_newsletter_min_fields"],
	stddev?:ValueTypes["voyce_newsletter_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_newsletter_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_newsletter_stddev_samp_fields"],
	sum?:ValueTypes["voyce_newsletter_sum_fields"],
	var_pop?:ValueTypes["voyce_newsletter_var_pop_fields"],
	var_samp?:ValueTypes["voyce_newsletter_var_samp_fields"],
	variance?:ValueTypes["voyce_newsletter_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_newsletter_avg_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.newsletter". All fields are combined with a logical 'AND'. */
["voyce_newsletter_bool_exp"]: {
	_and?:ValueTypes["voyce_newsletter_bool_exp"][],
	_not?:ValueTypes["voyce_newsletter_bool_exp"],
	_or?:ValueTypes["voyce_newsletter_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	email?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.newsletter" */
["voyce_newsletter_constraint"]:voyce_newsletter_constraint;
	/** input type for incrementing numeric columns in table "voyce.newsletter" */
["voyce_newsletter_inc_input"]: {
	id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.newsletter" */
["voyce_newsletter_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	email?:string,
	id?:number,
	user_id?:number
};
	/** aggregate max on columns */
["voyce_newsletter_max_fields"]: AliasType<{
	created_at?:true,
	email?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_newsletter_min_fields"]: AliasType<{
	created_at?:true,
	email?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.newsletter" */
["voyce_newsletter_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_newsletter"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.newsletter" */
["voyce_newsletter_on_conflict"]: {
	constraint:ValueTypes["voyce_newsletter_constraint"],
	update_columns:ValueTypes["voyce_newsletter_update_column"][],
	where?:ValueTypes["voyce_newsletter_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.newsletter". */
["voyce_newsletter_order_by"]: {
	created_at?:ValueTypes["order_by"],
	email?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.newsletter */
["voyce_newsletter_pk_columns_input"]: {
	email:string
};
	/** select columns of table "voyce.newsletter" */
["voyce_newsletter_select_column"]:voyce_newsletter_select_column;
	/** input type for updating data in table "voyce.newsletter" */
["voyce_newsletter_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	email?:string,
	id?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_newsletter_stddev_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_newsletter_stddev_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_newsletter_stddev_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_newsletter" */
["voyce_newsletter_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_newsletter_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_newsletter_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	email?:string,
	id?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_newsletter_sum_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.newsletter" */
["voyce_newsletter_update_column"]:voyce_newsletter_update_column;
	["voyce_newsletter_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_newsletter_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_newsletter_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_newsletter_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_newsletter_var_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_newsletter_var_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_newsletter_variance_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.notification_series_settings" */
["voyce_notification_series_settings"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	id?:true,
	series_id?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.notification_series_settings" */
["voyce_notification_series_settings_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_notification_series_settings_aggregate_fields"],
	nodes?:ValueTypes["voyce_notification_series_settings"],
		__typename?: true
}>;
	["voyce_notification_series_settings_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_notification_series_settings_aggregate_bool_exp_count"]
};
	["voyce_notification_series_settings_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_notification_series_settings_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_notification_series_settings_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.notification_series_settings" */
["voyce_notification_series_settings_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_notification_series_settings_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_notification_series_settings_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_notification_series_settings_max_fields"],
	min?:ValueTypes["voyce_notification_series_settings_min_fields"],
	stddev?:ValueTypes["voyce_notification_series_settings_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_notification_series_settings_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_notification_series_settings_stddev_samp_fields"],
	sum?:ValueTypes["voyce_notification_series_settings_sum_fields"],
	var_pop?:ValueTypes["voyce_notification_series_settings_var_pop_fields"],
	var_samp?:ValueTypes["voyce_notification_series_settings_var_samp_fields"],
	variance?:ValueTypes["voyce_notification_series_settings_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_notification_series_settings_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_notification_series_settings_max_order_by"],
	min?:ValueTypes["voyce_notification_series_settings_min_order_by"],
	stddev?:ValueTypes["voyce_notification_series_settings_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_notification_series_settings_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_notification_series_settings_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_notification_series_settings_sum_order_by"],
	var_pop?:ValueTypes["voyce_notification_series_settings_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_notification_series_settings_var_samp_order_by"],
	variance?:ValueTypes["voyce_notification_series_settings_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.notification_series_settings" */
["voyce_notification_series_settings_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_notification_series_settings_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_notification_series_settings_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_notification_series_settings_avg_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	id?:true,
	series_id?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_avg_order_by"]: {
	chapter_comment_email?:ValueTypes["order_by"],
	chapter_comment_push?:ValueTypes["order_by"],
	chapter_like_email?:ValueTypes["order_by"],
	chapter_like_push?:ValueTypes["order_by"],
	chapter_react_email?:ValueTypes["order_by"],
	chapter_react_push?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	subscription_email?:ValueTypes["order_by"],
	subscription_push?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.notification_series_settings". All fields are combined with a logical 'AND'. */
["voyce_notification_series_settings_bool_exp"]: {
	_and?:ValueTypes["voyce_notification_series_settings_bool_exp"][],
	_not?:ValueTypes["voyce_notification_series_settings_bool_exp"],
	_or?:ValueTypes["voyce_notification_series_settings_bool_exp"][],
	chapter_comment_email?:ValueTypes["smallint_comparison_exp"],
	chapter_comment_push?:ValueTypes["smallint_comparison_exp"],
	chapter_like_email?:ValueTypes["smallint_comparison_exp"],
	chapter_like_push?:ValueTypes["smallint_comparison_exp"],
	chapter_react_email?:ValueTypes["smallint_comparison_exp"],
	chapter_react_push?:ValueTypes["smallint_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	subscription_email?:ValueTypes["smallint_comparison_exp"],
	subscription_push?:ValueTypes["smallint_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.notification_series_settings" */
["voyce_notification_series_settings_constraint"]:voyce_notification_series_settings_constraint;
	/** input type for incrementing numeric columns in table "voyce.notification_series_settings" */
["voyce_notification_series_settings_inc_input"]: {
	chapter_comment_email?:ValueTypes["smallint"],
	chapter_comment_push?:ValueTypes["smallint"],
	chapter_like_email?:ValueTypes["smallint"],
	chapter_like_push?:ValueTypes["smallint"],
	chapter_react_email?:ValueTypes["smallint"],
	chapter_react_push?:ValueTypes["smallint"],
	id?:number,
	series_id?:number,
	subscription_email?:ValueTypes["smallint"],
	subscription_push?:ValueTypes["smallint"],
	user_id?:number
};
	/** input type for inserting data into table "voyce.notification_series_settings" */
["voyce_notification_series_settings_insert_input"]: {
	chapter_comment_email?:ValueTypes["smallint"],
	chapter_comment_push?:ValueTypes["smallint"],
	chapter_like_email?:ValueTypes["smallint"],
	chapter_like_push?:ValueTypes["smallint"],
	chapter_react_email?:ValueTypes["smallint"],
	chapter_react_push?:ValueTypes["smallint"],
	id?:number,
	series_id?:number,
	subscription_email?:ValueTypes["smallint"],
	subscription_push?:ValueTypes["smallint"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_notification_series_settings_max_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	id?:true,
	series_id?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_max_order_by"]: {
	chapter_comment_email?:ValueTypes["order_by"],
	chapter_comment_push?:ValueTypes["order_by"],
	chapter_like_email?:ValueTypes["order_by"],
	chapter_like_push?:ValueTypes["order_by"],
	chapter_react_email?:ValueTypes["order_by"],
	chapter_react_push?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	subscription_email?:ValueTypes["order_by"],
	subscription_push?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_notification_series_settings_min_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	id?:true,
	series_id?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_min_order_by"]: {
	chapter_comment_email?:ValueTypes["order_by"],
	chapter_comment_push?:ValueTypes["order_by"],
	chapter_like_email?:ValueTypes["order_by"],
	chapter_like_push?:ValueTypes["order_by"],
	chapter_react_email?:ValueTypes["order_by"],
	chapter_react_push?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	subscription_email?:ValueTypes["order_by"],
	subscription_push?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.notification_series_settings" */
["voyce_notification_series_settings_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_notification_series_settings"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.notification_series_settings" */
["voyce_notification_series_settings_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_notification_series_settings_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_notification_series_settings_on_conflict"]
};
	/** on_conflict condition type for table "voyce.notification_series_settings" */
["voyce_notification_series_settings_on_conflict"]: {
	constraint:ValueTypes["voyce_notification_series_settings_constraint"],
	update_columns:ValueTypes["voyce_notification_series_settings_update_column"][],
	where?:ValueTypes["voyce_notification_series_settings_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.notification_series_settings". */
["voyce_notification_series_settings_order_by"]: {
	chapter_comment_email?:ValueTypes["order_by"],
	chapter_comment_push?:ValueTypes["order_by"],
	chapter_like_email?:ValueTypes["order_by"],
	chapter_like_push?:ValueTypes["order_by"],
	chapter_react_email?:ValueTypes["order_by"],
	chapter_react_push?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	subscription_email?:ValueTypes["order_by"],
	subscription_push?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.notification_series_settings */
["voyce_notification_series_settings_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_select_column"]:voyce_notification_series_settings_select_column;
	/** input type for updating data in table "voyce.notification_series_settings" */
["voyce_notification_series_settings_set_input"]: {
	chapter_comment_email?:ValueTypes["smallint"],
	chapter_comment_push?:ValueTypes["smallint"],
	chapter_like_email?:ValueTypes["smallint"],
	chapter_like_push?:ValueTypes["smallint"],
	chapter_react_email?:ValueTypes["smallint"],
	chapter_react_push?:ValueTypes["smallint"],
	id?:number,
	series_id?:number,
	subscription_email?:ValueTypes["smallint"],
	subscription_push?:ValueTypes["smallint"],
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_notification_series_settings_stddev_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	id?:true,
	series_id?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_stddev_order_by"]: {
	chapter_comment_email?:ValueTypes["order_by"],
	chapter_comment_push?:ValueTypes["order_by"],
	chapter_like_email?:ValueTypes["order_by"],
	chapter_like_push?:ValueTypes["order_by"],
	chapter_react_email?:ValueTypes["order_by"],
	chapter_react_push?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	subscription_email?:ValueTypes["order_by"],
	subscription_push?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_notification_series_settings_stddev_pop_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	id?:true,
	series_id?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_stddev_pop_order_by"]: {
	chapter_comment_email?:ValueTypes["order_by"],
	chapter_comment_push?:ValueTypes["order_by"],
	chapter_like_email?:ValueTypes["order_by"],
	chapter_like_push?:ValueTypes["order_by"],
	chapter_react_email?:ValueTypes["order_by"],
	chapter_react_push?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	subscription_email?:ValueTypes["order_by"],
	subscription_push?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_notification_series_settings_stddev_samp_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	id?:true,
	series_id?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_stddev_samp_order_by"]: {
	chapter_comment_email?:ValueTypes["order_by"],
	chapter_comment_push?:ValueTypes["order_by"],
	chapter_like_email?:ValueTypes["order_by"],
	chapter_like_push?:ValueTypes["order_by"],
	chapter_react_email?:ValueTypes["order_by"],
	chapter_react_push?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	subscription_email?:ValueTypes["order_by"],
	subscription_push?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_notification_series_settings" */
["voyce_notification_series_settings_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_notification_series_settings_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_notification_series_settings_stream_cursor_value_input"]: {
	chapter_comment_email?:ValueTypes["smallint"],
	chapter_comment_push?:ValueTypes["smallint"],
	chapter_like_email?:ValueTypes["smallint"],
	chapter_like_push?:ValueTypes["smallint"],
	chapter_react_email?:ValueTypes["smallint"],
	chapter_react_push?:ValueTypes["smallint"],
	id?:number,
	series_id?:number,
	subscription_email?:ValueTypes["smallint"],
	subscription_push?:ValueTypes["smallint"],
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_notification_series_settings_sum_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	id?:true,
	series_id?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_sum_order_by"]: {
	chapter_comment_email?:ValueTypes["order_by"],
	chapter_comment_push?:ValueTypes["order_by"],
	chapter_like_email?:ValueTypes["order_by"],
	chapter_like_push?:ValueTypes["order_by"],
	chapter_react_email?:ValueTypes["order_by"],
	chapter_react_push?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	subscription_email?:ValueTypes["order_by"],
	subscription_push?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_update_column"]:voyce_notification_series_settings_update_column;
	["voyce_notification_series_settings_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_notification_series_settings_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_notification_series_settings_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_notification_series_settings_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_notification_series_settings_var_pop_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	id?:true,
	series_id?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_var_pop_order_by"]: {
	chapter_comment_email?:ValueTypes["order_by"],
	chapter_comment_push?:ValueTypes["order_by"],
	chapter_like_email?:ValueTypes["order_by"],
	chapter_like_push?:ValueTypes["order_by"],
	chapter_react_email?:ValueTypes["order_by"],
	chapter_react_push?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	subscription_email?:ValueTypes["order_by"],
	subscription_push?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_notification_series_settings_var_samp_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	id?:true,
	series_id?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_var_samp_order_by"]: {
	chapter_comment_email?:ValueTypes["order_by"],
	chapter_comment_push?:ValueTypes["order_by"],
	chapter_like_email?:ValueTypes["order_by"],
	chapter_like_push?:ValueTypes["order_by"],
	chapter_react_email?:ValueTypes["order_by"],
	chapter_react_push?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	subscription_email?:ValueTypes["order_by"],
	subscription_push?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_notification_series_settings_variance_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	id?:true,
	series_id?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_variance_order_by"]: {
	chapter_comment_email?:ValueTypes["order_by"],
	chapter_comment_push?:ValueTypes["order_by"],
	chapter_like_email?:ValueTypes["order_by"],
	chapter_like_push?:ValueTypes["order_by"],
	chapter_react_email?:ValueTypes["order_by"],
	chapter_react_push?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	subscription_email?:ValueTypes["order_by"],
	subscription_push?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.notification_settings" */
["voyce_notification_settings"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	chapter_release_email?:true,
	chapter_release_push?:true,
	comment_react_email?:true,
	comment_react_push?:true,
	comment_reply_email?:true,
	comment_reply_push?:true,
	event_email?:true,
	event_push?:true,
	follow_email?:true,
	follow_push?:true,
	id?:true,
	promotion_email?:true,
	promotion_push?:true,
	recommended_series_email?:true,
	recommended_series_push?:true,
	reward_email?:true,
	reward_push?:true,
	subscription_email?:true,
	subscription_push?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.notification_settings" */
["voyce_notification_settings_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_notification_settings_aggregate_fields"],
	nodes?:ValueTypes["voyce_notification_settings"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.notification_settings" */
["voyce_notification_settings_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_notification_settings_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_notification_settings_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_notification_settings_max_fields"],
	min?:ValueTypes["voyce_notification_settings_min_fields"],
	stddev?:ValueTypes["voyce_notification_settings_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_notification_settings_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_notification_settings_stddev_samp_fields"],
	sum?:ValueTypes["voyce_notification_settings_sum_fields"],
	var_pop?:ValueTypes["voyce_notification_settings_var_pop_fields"],
	var_samp?:ValueTypes["voyce_notification_settings_var_samp_fields"],
	variance?:ValueTypes["voyce_notification_settings_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_notification_settings_avg_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	chapter_release_email?:true,
	chapter_release_push?:true,
	comment_react_email?:true,
	comment_react_push?:true,
	comment_reply_email?:true,
	comment_reply_push?:true,
	event_email?:true,
	event_push?:true,
	follow_email?:true,
	follow_push?:true,
	id?:true,
	promotion_email?:true,
	promotion_push?:true,
	recommended_series_email?:true,
	recommended_series_push?:true,
	reward_email?:true,
	reward_push?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.notification_settings". All fields are combined with a logical 'AND'. */
["voyce_notification_settings_bool_exp"]: {
	_and?:ValueTypes["voyce_notification_settings_bool_exp"][],
	_not?:ValueTypes["voyce_notification_settings_bool_exp"],
	_or?:ValueTypes["voyce_notification_settings_bool_exp"][],
	chapter_comment_email?:ValueTypes["smallint_comparison_exp"],
	chapter_comment_push?:ValueTypes["smallint_comparison_exp"],
	chapter_like_email?:ValueTypes["smallint_comparison_exp"],
	chapter_like_push?:ValueTypes["smallint_comparison_exp"],
	chapter_react_email?:ValueTypes["smallint_comparison_exp"],
	chapter_react_push?:ValueTypes["smallint_comparison_exp"],
	chapter_release_email?:ValueTypes["smallint_comparison_exp"],
	chapter_release_push?:ValueTypes["smallint_comparison_exp"],
	comment_react_email?:ValueTypes["smallint_comparison_exp"],
	comment_react_push?:ValueTypes["smallint_comparison_exp"],
	comment_reply_email?:ValueTypes["smallint_comparison_exp"],
	comment_reply_push?:ValueTypes["smallint_comparison_exp"],
	event_email?:ValueTypes["smallint_comparison_exp"],
	event_push?:ValueTypes["smallint_comparison_exp"],
	follow_email?:ValueTypes["smallint_comparison_exp"],
	follow_push?:ValueTypes["smallint_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	promotion_email?:ValueTypes["smallint_comparison_exp"],
	promotion_push?:ValueTypes["smallint_comparison_exp"],
	recommended_series_email?:ValueTypes["smallint_comparison_exp"],
	recommended_series_push?:ValueTypes["smallint_comparison_exp"],
	reward_email?:ValueTypes["smallint_comparison_exp"],
	reward_push?:ValueTypes["smallint_comparison_exp"],
	subscription_email?:ValueTypes["smallint_comparison_exp"],
	subscription_push?:ValueTypes["smallint_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.notification_settings" */
["voyce_notification_settings_constraint"]:voyce_notification_settings_constraint;
	/** input type for incrementing numeric columns in table "voyce.notification_settings" */
["voyce_notification_settings_inc_input"]: {
	chapter_comment_email?:ValueTypes["smallint"],
	chapter_comment_push?:ValueTypes["smallint"],
	chapter_like_email?:ValueTypes["smallint"],
	chapter_like_push?:ValueTypes["smallint"],
	chapter_react_email?:ValueTypes["smallint"],
	chapter_react_push?:ValueTypes["smallint"],
	chapter_release_email?:ValueTypes["smallint"],
	chapter_release_push?:ValueTypes["smallint"],
	comment_react_email?:ValueTypes["smallint"],
	comment_react_push?:ValueTypes["smallint"],
	comment_reply_email?:ValueTypes["smallint"],
	comment_reply_push?:ValueTypes["smallint"],
	event_email?:ValueTypes["smallint"],
	event_push?:ValueTypes["smallint"],
	follow_email?:ValueTypes["smallint"],
	follow_push?:ValueTypes["smallint"],
	id?:number,
	promotion_email?:ValueTypes["smallint"],
	promotion_push?:ValueTypes["smallint"],
	recommended_series_email?:ValueTypes["smallint"],
	recommended_series_push?:ValueTypes["smallint"],
	reward_email?:ValueTypes["smallint"],
	reward_push?:ValueTypes["smallint"],
	subscription_email?:ValueTypes["smallint"],
	subscription_push?:ValueTypes["smallint"],
	user_id?:number
};
	/** input type for inserting data into table "voyce.notification_settings" */
["voyce_notification_settings_insert_input"]: {
	chapter_comment_email?:ValueTypes["smallint"],
	chapter_comment_push?:ValueTypes["smallint"],
	chapter_like_email?:ValueTypes["smallint"],
	chapter_like_push?:ValueTypes["smallint"],
	chapter_react_email?:ValueTypes["smallint"],
	chapter_react_push?:ValueTypes["smallint"],
	chapter_release_email?:ValueTypes["smallint"],
	chapter_release_push?:ValueTypes["smallint"],
	comment_react_email?:ValueTypes["smallint"],
	comment_react_push?:ValueTypes["smallint"],
	comment_reply_email?:ValueTypes["smallint"],
	comment_reply_push?:ValueTypes["smallint"],
	event_email?:ValueTypes["smallint"],
	event_push?:ValueTypes["smallint"],
	follow_email?:ValueTypes["smallint"],
	follow_push?:ValueTypes["smallint"],
	id?:number,
	promotion_email?:ValueTypes["smallint"],
	promotion_push?:ValueTypes["smallint"],
	recommended_series_email?:ValueTypes["smallint"],
	recommended_series_push?:ValueTypes["smallint"],
	reward_email?:ValueTypes["smallint"],
	reward_push?:ValueTypes["smallint"],
	subscription_email?:ValueTypes["smallint"],
	subscription_push?:ValueTypes["smallint"],
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_notification_settings_max_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	chapter_release_email?:true,
	chapter_release_push?:true,
	comment_react_email?:true,
	comment_react_push?:true,
	comment_reply_email?:true,
	comment_reply_push?:true,
	event_email?:true,
	event_push?:true,
	follow_email?:true,
	follow_push?:true,
	id?:true,
	promotion_email?:true,
	promotion_push?:true,
	recommended_series_email?:true,
	recommended_series_push?:true,
	reward_email?:true,
	reward_push?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_notification_settings_min_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	chapter_release_email?:true,
	chapter_release_push?:true,
	comment_react_email?:true,
	comment_react_push?:true,
	comment_reply_email?:true,
	comment_reply_push?:true,
	event_email?:true,
	event_push?:true,
	follow_email?:true,
	follow_push?:true,
	id?:true,
	promotion_email?:true,
	promotion_push?:true,
	recommended_series_email?:true,
	recommended_series_push?:true,
	reward_email?:true,
	reward_push?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.notification_settings" */
["voyce_notification_settings_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_notification_settings"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.notification_settings" */
["voyce_notification_settings_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_notification_settings_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_notification_settings_on_conflict"]
};
	/** on_conflict condition type for table "voyce.notification_settings" */
["voyce_notification_settings_on_conflict"]: {
	constraint:ValueTypes["voyce_notification_settings_constraint"],
	update_columns:ValueTypes["voyce_notification_settings_update_column"][],
	where?:ValueTypes["voyce_notification_settings_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.notification_settings". */
["voyce_notification_settings_order_by"]: {
	chapter_comment_email?:ValueTypes["order_by"],
	chapter_comment_push?:ValueTypes["order_by"],
	chapter_like_email?:ValueTypes["order_by"],
	chapter_like_push?:ValueTypes["order_by"],
	chapter_react_email?:ValueTypes["order_by"],
	chapter_react_push?:ValueTypes["order_by"],
	chapter_release_email?:ValueTypes["order_by"],
	chapter_release_push?:ValueTypes["order_by"],
	comment_react_email?:ValueTypes["order_by"],
	comment_react_push?:ValueTypes["order_by"],
	comment_reply_email?:ValueTypes["order_by"],
	comment_reply_push?:ValueTypes["order_by"],
	event_email?:ValueTypes["order_by"],
	event_push?:ValueTypes["order_by"],
	follow_email?:ValueTypes["order_by"],
	follow_push?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	promotion_email?:ValueTypes["order_by"],
	promotion_push?:ValueTypes["order_by"],
	recommended_series_email?:ValueTypes["order_by"],
	recommended_series_push?:ValueTypes["order_by"],
	reward_email?:ValueTypes["order_by"],
	reward_push?:ValueTypes["order_by"],
	subscription_email?:ValueTypes["order_by"],
	subscription_push?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.notification_settings */
["voyce_notification_settings_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.notification_settings" */
["voyce_notification_settings_select_column"]:voyce_notification_settings_select_column;
	/** input type for updating data in table "voyce.notification_settings" */
["voyce_notification_settings_set_input"]: {
	chapter_comment_email?:ValueTypes["smallint"],
	chapter_comment_push?:ValueTypes["smallint"],
	chapter_like_email?:ValueTypes["smallint"],
	chapter_like_push?:ValueTypes["smallint"],
	chapter_react_email?:ValueTypes["smallint"],
	chapter_react_push?:ValueTypes["smallint"],
	chapter_release_email?:ValueTypes["smallint"],
	chapter_release_push?:ValueTypes["smallint"],
	comment_react_email?:ValueTypes["smallint"],
	comment_react_push?:ValueTypes["smallint"],
	comment_reply_email?:ValueTypes["smallint"],
	comment_reply_push?:ValueTypes["smallint"],
	event_email?:ValueTypes["smallint"],
	event_push?:ValueTypes["smallint"],
	follow_email?:ValueTypes["smallint"],
	follow_push?:ValueTypes["smallint"],
	id?:number,
	promotion_email?:ValueTypes["smallint"],
	promotion_push?:ValueTypes["smallint"],
	recommended_series_email?:ValueTypes["smallint"],
	recommended_series_push?:ValueTypes["smallint"],
	reward_email?:ValueTypes["smallint"],
	reward_push?:ValueTypes["smallint"],
	subscription_email?:ValueTypes["smallint"],
	subscription_push?:ValueTypes["smallint"],
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_notification_settings_stddev_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	chapter_release_email?:true,
	chapter_release_push?:true,
	comment_react_email?:true,
	comment_react_push?:true,
	comment_reply_email?:true,
	comment_reply_push?:true,
	event_email?:true,
	event_push?:true,
	follow_email?:true,
	follow_push?:true,
	id?:true,
	promotion_email?:true,
	promotion_push?:true,
	recommended_series_email?:true,
	recommended_series_push?:true,
	reward_email?:true,
	reward_push?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_notification_settings_stddev_pop_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	chapter_release_email?:true,
	chapter_release_push?:true,
	comment_react_email?:true,
	comment_react_push?:true,
	comment_reply_email?:true,
	comment_reply_push?:true,
	event_email?:true,
	event_push?:true,
	follow_email?:true,
	follow_push?:true,
	id?:true,
	promotion_email?:true,
	promotion_push?:true,
	recommended_series_email?:true,
	recommended_series_push?:true,
	reward_email?:true,
	reward_push?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_notification_settings_stddev_samp_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	chapter_release_email?:true,
	chapter_release_push?:true,
	comment_react_email?:true,
	comment_react_push?:true,
	comment_reply_email?:true,
	comment_reply_push?:true,
	event_email?:true,
	event_push?:true,
	follow_email?:true,
	follow_push?:true,
	id?:true,
	promotion_email?:true,
	promotion_push?:true,
	recommended_series_email?:true,
	recommended_series_push?:true,
	reward_email?:true,
	reward_push?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_notification_settings" */
["voyce_notification_settings_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_notification_settings_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_notification_settings_stream_cursor_value_input"]: {
	chapter_comment_email?:ValueTypes["smallint"],
	chapter_comment_push?:ValueTypes["smallint"],
	chapter_like_email?:ValueTypes["smallint"],
	chapter_like_push?:ValueTypes["smallint"],
	chapter_react_email?:ValueTypes["smallint"],
	chapter_react_push?:ValueTypes["smallint"],
	chapter_release_email?:ValueTypes["smallint"],
	chapter_release_push?:ValueTypes["smallint"],
	comment_react_email?:ValueTypes["smallint"],
	comment_react_push?:ValueTypes["smallint"],
	comment_reply_email?:ValueTypes["smallint"],
	comment_reply_push?:ValueTypes["smallint"],
	event_email?:ValueTypes["smallint"],
	event_push?:ValueTypes["smallint"],
	follow_email?:ValueTypes["smallint"],
	follow_push?:ValueTypes["smallint"],
	id?:number,
	promotion_email?:ValueTypes["smallint"],
	promotion_push?:ValueTypes["smallint"],
	recommended_series_email?:ValueTypes["smallint"],
	recommended_series_push?:ValueTypes["smallint"],
	reward_email?:ValueTypes["smallint"],
	reward_push?:ValueTypes["smallint"],
	subscription_email?:ValueTypes["smallint"],
	subscription_push?:ValueTypes["smallint"],
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_notification_settings_sum_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	chapter_release_email?:true,
	chapter_release_push?:true,
	comment_react_email?:true,
	comment_react_push?:true,
	comment_reply_email?:true,
	comment_reply_push?:true,
	event_email?:true,
	event_push?:true,
	follow_email?:true,
	follow_push?:true,
	id?:true,
	promotion_email?:true,
	promotion_push?:true,
	recommended_series_email?:true,
	recommended_series_push?:true,
	reward_email?:true,
	reward_push?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.notification_settings" */
["voyce_notification_settings_update_column"]:voyce_notification_settings_update_column;
	["voyce_notification_settings_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_notification_settings_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_notification_settings_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_notification_settings_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_notification_settings_var_pop_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	chapter_release_email?:true,
	chapter_release_push?:true,
	comment_react_email?:true,
	comment_react_push?:true,
	comment_reply_email?:true,
	comment_reply_push?:true,
	event_email?:true,
	event_push?:true,
	follow_email?:true,
	follow_push?:true,
	id?:true,
	promotion_email?:true,
	promotion_push?:true,
	recommended_series_email?:true,
	recommended_series_push?:true,
	reward_email?:true,
	reward_push?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_notification_settings_var_samp_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	chapter_release_email?:true,
	chapter_release_push?:true,
	comment_react_email?:true,
	comment_react_push?:true,
	comment_reply_email?:true,
	comment_reply_push?:true,
	event_email?:true,
	event_push?:true,
	follow_email?:true,
	follow_push?:true,
	id?:true,
	promotion_email?:true,
	promotion_push?:true,
	recommended_series_email?:true,
	recommended_series_push?:true,
	reward_email?:true,
	reward_push?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_notification_settings_variance_fields"]: AliasType<{
	chapter_comment_email?:true,
	chapter_comment_push?:true,
	chapter_like_email?:true,
	chapter_like_push?:true,
	chapter_react_email?:true,
	chapter_react_push?:true,
	chapter_release_email?:true,
	chapter_release_push?:true,
	comment_react_email?:true,
	comment_react_push?:true,
	comment_reply_email?:true,
	comment_reply_push?:true,
	event_email?:true,
	event_push?:true,
	follow_email?:true,
	follow_push?:true,
	id?:true,
	promotion_email?:true,
	promotion_push?:true,
	recommended_series_email?:true,
	recommended_series_push?:true,
	reward_email?:true,
	reward_push?:true,
	subscription_email?:true,
	subscription_push?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.notifications" */
["voyce_notifications"]: AliasType<{
	child_count?:true,
	created_at?:true,
data?: [{	/** JSON select path */
	path?:string},true],
	email_status?:true,
	emailed_at?:true,
	id?:true,
	linked_id?:true,
	parent_id?:true,
	pushed_at?:true,
	read_at?:true,
	type?:true,
	unsubscribe_id?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.notifications" */
["voyce_notifications_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_notifications_aggregate_fields"],
	nodes?:ValueTypes["voyce_notifications"],
		__typename?: true
}>;
	["voyce_notifications_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_notifications_aggregate_bool_exp_count"]
};
	["voyce_notifications_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_notifications_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_notifications_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.notifications" */
["voyce_notifications_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_notifications_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_notifications_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_notifications_max_fields"],
	min?:ValueTypes["voyce_notifications_min_fields"],
	stddev?:ValueTypes["voyce_notifications_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_notifications_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_notifications_stddev_samp_fields"],
	sum?:ValueTypes["voyce_notifications_sum_fields"],
	var_pop?:ValueTypes["voyce_notifications_var_pop_fields"],
	var_samp?:ValueTypes["voyce_notifications_var_samp_fields"],
	variance?:ValueTypes["voyce_notifications_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.notifications" */
["voyce_notifications_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_notifications_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_notifications_max_order_by"],
	min?:ValueTypes["voyce_notifications_min_order_by"],
	stddev?:ValueTypes["voyce_notifications_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_notifications_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_notifications_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_notifications_sum_order_by"],
	var_pop?:ValueTypes["voyce_notifications_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_notifications_var_samp_order_by"],
	variance?:ValueTypes["voyce_notifications_variance_order_by"]
};
	/** append existing jsonb value of filtered columns with new jsonb value */
["voyce_notifications_append_input"]: {
	data?:ValueTypes["jsonb"]
};
	/** input type for inserting array relation for remote table "voyce.notifications" */
["voyce_notifications_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_notifications_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_notifications_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_notifications_avg_fields"]: AliasType<{
	child_count?:true,
	id?:true,
	linked_id?:true,
	parent_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.notifications" */
["voyce_notifications_avg_order_by"]: {
	child_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.notifications". All fields are combined with a logical 'AND'. */
["voyce_notifications_bool_exp"]: {
	_and?:ValueTypes["voyce_notifications_bool_exp"][],
	_not?:ValueTypes["voyce_notifications_bool_exp"],
	_or?:ValueTypes["voyce_notifications_bool_exp"][],
	child_count?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	data?:ValueTypes["jsonb_comparison_exp"],
	email_status?:ValueTypes["String_comparison_exp"],
	emailed_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	linked_id?:ValueTypes["Int_comparison_exp"],
	parent_id?:ValueTypes["Int_comparison_exp"],
	pushed_at?:ValueTypes["timestamptz_comparison_exp"],
	read_at?:ValueTypes["timestamptz_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"],
	unsubscribe_id?:ValueTypes["uuid_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.notifications" */
["voyce_notifications_constraint"]:voyce_notifications_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["voyce_notifications_delete_at_path_input"]: {
	data?:string[]
};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["voyce_notifications_delete_elem_input"]: {
	data?:number
};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["voyce_notifications_delete_key_input"]: {
	data?:string
};
	/** input type for incrementing numeric columns in table "voyce.notifications" */
["voyce_notifications_inc_input"]: {
	child_count?:number,
	id?:number,
	linked_id?:number,
	parent_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.notifications" */
["voyce_notifications_insert_input"]: {
	child_count?:number,
	created_at?:ValueTypes["timestamptz"],
	data?:ValueTypes["jsonb"],
	email_status?:string,
	emailed_at?:ValueTypes["timestamptz"],
	id?:number,
	linked_id?:number,
	parent_id?:number,
	pushed_at?:ValueTypes["timestamptz"],
	read_at?:ValueTypes["timestamptz"],
	type?:string,
	unsubscribe_id?:ValueTypes["uuid"],
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_notifications_max_fields"]: AliasType<{
	child_count?:true,
	created_at?:true,
	email_status?:true,
	emailed_at?:true,
	id?:true,
	linked_id?:true,
	parent_id?:true,
	pushed_at?:true,
	read_at?:true,
	type?:true,
	unsubscribe_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.notifications" */
["voyce_notifications_max_order_by"]: {
	child_count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	email_status?:ValueTypes["order_by"],
	emailed_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	pushed_at?:ValueTypes["order_by"],
	read_at?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	unsubscribe_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_notifications_min_fields"]: AliasType<{
	child_count?:true,
	created_at?:true,
	email_status?:true,
	emailed_at?:true,
	id?:true,
	linked_id?:true,
	parent_id?:true,
	pushed_at?:true,
	read_at?:true,
	type?:true,
	unsubscribe_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.notifications" */
["voyce_notifications_min_order_by"]: {
	child_count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	email_status?:ValueTypes["order_by"],
	emailed_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	pushed_at?:ValueTypes["order_by"],
	read_at?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	unsubscribe_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.notifications" */
["voyce_notifications_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_notifications"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.notifications" */
["voyce_notifications_on_conflict"]: {
	constraint:ValueTypes["voyce_notifications_constraint"],
	update_columns:ValueTypes["voyce_notifications_update_column"][],
	where?:ValueTypes["voyce_notifications_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.notifications". */
["voyce_notifications_order_by"]: {
	child_count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	data?:ValueTypes["order_by"],
	email_status?:ValueTypes["order_by"],
	emailed_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	pushed_at?:ValueTypes["order_by"],
	read_at?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	unsubscribe_id?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.notifications */
["voyce_notifications_pk_columns_input"]: {
	id:number
};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["voyce_notifications_prepend_input"]: {
	data?:ValueTypes["jsonb"]
};
	/** select columns of table "voyce.notifications" */
["voyce_notifications_select_column"]:voyce_notifications_select_column;
	/** input type for updating data in table "voyce.notifications" */
["voyce_notifications_set_input"]: {
	child_count?:number,
	created_at?:ValueTypes["timestamptz"],
	data?:ValueTypes["jsonb"],
	email_status?:string,
	emailed_at?:ValueTypes["timestamptz"],
	id?:number,
	linked_id?:number,
	parent_id?:number,
	pushed_at?:ValueTypes["timestamptz"],
	read_at?:ValueTypes["timestamptz"],
	type?:string,
	unsubscribe_id?:ValueTypes["uuid"],
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_notifications_stddev_fields"]: AliasType<{
	child_count?:true,
	id?:true,
	linked_id?:true,
	parent_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.notifications" */
["voyce_notifications_stddev_order_by"]: {
	child_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_notifications_stddev_pop_fields"]: AliasType<{
	child_count?:true,
	id?:true,
	linked_id?:true,
	parent_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.notifications" */
["voyce_notifications_stddev_pop_order_by"]: {
	child_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_notifications_stddev_samp_fields"]: AliasType<{
	child_count?:true,
	id?:true,
	linked_id?:true,
	parent_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.notifications" */
["voyce_notifications_stddev_samp_order_by"]: {
	child_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_notifications" */
["voyce_notifications_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_notifications_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_notifications_stream_cursor_value_input"]: {
	child_count?:number,
	created_at?:ValueTypes["timestamptz"],
	data?:ValueTypes["jsonb"],
	email_status?:string,
	emailed_at?:ValueTypes["timestamptz"],
	id?:number,
	linked_id?:number,
	parent_id?:number,
	pushed_at?:ValueTypes["timestamptz"],
	read_at?:ValueTypes["timestamptz"],
	type?:string,
	unsubscribe_id?:ValueTypes["uuid"],
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_notifications_sum_fields"]: AliasType<{
	child_count?:true,
	id?:true,
	linked_id?:true,
	parent_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.notifications" */
["voyce_notifications_sum_order_by"]: {
	child_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.notifications" */
["voyce_notifications_update_column"]:voyce_notifications_update_column;
	["voyce_notifications_updates"]: {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["voyce_notifications_append_input"],
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["voyce_notifications_delete_at_path_input"],
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["voyce_notifications_delete_elem_input"],
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["voyce_notifications_delete_key_input"],
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_notifications_inc_input"],
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["voyce_notifications_prepend_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_notifications_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_notifications_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_notifications_var_pop_fields"]: AliasType<{
	child_count?:true,
	id?:true,
	linked_id?:true,
	parent_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.notifications" */
["voyce_notifications_var_pop_order_by"]: {
	child_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_notifications_var_samp_fields"]: AliasType<{
	child_count?:true,
	id?:true,
	linked_id?:true,
	parent_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.notifications" */
["voyce_notifications_var_samp_order_by"]: {
	child_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_notifications_variance_fields"]: AliasType<{
	child_count?:true,
	id?:true,
	linked_id?:true,
	parent_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.notifications" */
["voyce_notifications_variance_order_by"]: {
	child_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.originals_feed" */
["voyce_originals_feed"]: AliasType<{
	category?:true,
	created_at?:true,
	desc?:true,
	id?:true,
	image?:true,
	title?:true,
	url?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.originals_feed" */
["voyce_originals_feed_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_originals_feed_aggregate_fields"],
	nodes?:ValueTypes["voyce_originals_feed"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.originals_feed" */
["voyce_originals_feed_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["voyce_originals_feed_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_originals_feed_max_fields"],
	min?:ValueTypes["voyce_originals_feed_min_fields"],
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.originals_feed". All fields are combined with a logical 'AND'. */
["voyce_originals_feed_bool_exp"]: {
	_and?:ValueTypes["voyce_originals_feed_bool_exp"][],
	_not?:ValueTypes["voyce_originals_feed_bool_exp"],
	_or?:ValueTypes["voyce_originals_feed_bool_exp"][],
	category?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	desc?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["String_comparison_exp"],
	image?:ValueTypes["String_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	url?:ValueTypes["String_comparison_exp"]
};
	/** aggregate max on columns */
["voyce_originals_feed_max_fields"]: AliasType<{
	category?:true,
	created_at?:true,
	desc?:true,
	id?:true,
	image?:true,
	title?:true,
	url?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_originals_feed_min_fields"]: AliasType<{
	category?:true,
	created_at?:true,
	desc?:true,
	id?:true,
	image?:true,
	title?:true,
	url?:true,
		__typename?: true
}>;
	/** Ordering options when selecting data from "voyce.originals_feed". */
["voyce_originals_feed_order_by"]: {
	category?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	desc?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	url?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.originals_feed" */
["voyce_originals_feed_select_column"]:voyce_originals_feed_select_column;
	/** Streaming cursor of the table "voyce_originals_feed" */
["voyce_originals_feed_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_originals_feed_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_originals_feed_stream_cursor_value_input"]: {
	category?:string,
	created_at?:ValueTypes["timestamptz"],
	desc?:string,
	id?:string,
	image?:string,
	title?:string,
	url?:string
};
	/** columns and relationships of "voyce.page_banners" */
["voyce_page_banners"]: AliasType<{
	active?:true,
	background?:true,
	created_at?:true,
	desktop?:true,
	id?:true,
	link?:true,
	mobile?:true,
	overlap?:true,
	page?:true,
	sort?:true,
	tablet?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.page_banners" */
["voyce_page_banners_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_page_banners_aggregate_fields"],
	nodes?:ValueTypes["voyce_page_banners"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.page_banners" */
["voyce_page_banners_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_page_banners_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_page_banners_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_page_banners_max_fields"],
	min?:ValueTypes["voyce_page_banners_min_fields"],
	stddev?:ValueTypes["voyce_page_banners_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_page_banners_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_page_banners_stddev_samp_fields"],
	sum?:ValueTypes["voyce_page_banners_sum_fields"],
	var_pop?:ValueTypes["voyce_page_banners_var_pop_fields"],
	var_samp?:ValueTypes["voyce_page_banners_var_samp_fields"],
	variance?:ValueTypes["voyce_page_banners_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_page_banners_avg_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.page_banners". All fields are combined with a logical 'AND'. */
["voyce_page_banners_bool_exp"]: {
	_and?:ValueTypes["voyce_page_banners_bool_exp"][],
	_not?:ValueTypes["voyce_page_banners_bool_exp"],
	_or?:ValueTypes["voyce_page_banners_bool_exp"][],
	active?:ValueTypes["Boolean_comparison_exp"],
	background?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	desktop?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	link?:ValueTypes["String_comparison_exp"],
	mobile?:ValueTypes["String_comparison_exp"],
	overlap?:ValueTypes["Boolean_comparison_exp"],
	page?:ValueTypes["String_comparison_exp"],
	sort?:ValueTypes["smallint_comparison_exp"],
	tablet?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.page_banners" */
["voyce_page_banners_constraint"]:voyce_page_banners_constraint;
	/** input type for incrementing numeric columns in table "voyce.page_banners" */
["voyce_page_banners_inc_input"]: {
	id?:number,
	sort?:ValueTypes["smallint"]
};
	/** input type for inserting data into table "voyce.page_banners" */
["voyce_page_banners_insert_input"]: {
	active?:boolean,
	background?:string,
	created_at?:ValueTypes["timestamptz"],
	desktop?:string,
	id?:number,
	link?:string,
	mobile?:string,
	overlap?:boolean,
	page?:string,
	sort?:ValueTypes["smallint"],
	tablet?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_page_banners_max_fields"]: AliasType<{
	background?:true,
	created_at?:true,
	desktop?:true,
	id?:true,
	link?:true,
	mobile?:true,
	page?:true,
	sort?:true,
	tablet?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_page_banners_min_fields"]: AliasType<{
	background?:true,
	created_at?:true,
	desktop?:true,
	id?:true,
	link?:true,
	mobile?:true,
	page?:true,
	sort?:true,
	tablet?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.page_banners" */
["voyce_page_banners_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_page_banners"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.page_banners" */
["voyce_page_banners_on_conflict"]: {
	constraint:ValueTypes["voyce_page_banners_constraint"],
	update_columns:ValueTypes["voyce_page_banners_update_column"][],
	where?:ValueTypes["voyce_page_banners_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.page_banners". */
["voyce_page_banners_order_by"]: {
	active?:ValueTypes["order_by"],
	background?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	desktop?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	link?:ValueTypes["order_by"],
	mobile?:ValueTypes["order_by"],
	overlap?:ValueTypes["order_by"],
	page?:ValueTypes["order_by"],
	sort?:ValueTypes["order_by"],
	tablet?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.page_banners */
["voyce_page_banners_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.page_banners" */
["voyce_page_banners_select_column"]:voyce_page_banners_select_column;
	/** input type for updating data in table "voyce.page_banners" */
["voyce_page_banners_set_input"]: {
	active?:boolean,
	background?:string,
	created_at?:ValueTypes["timestamptz"],
	desktop?:string,
	id?:number,
	link?:string,
	mobile?:string,
	overlap?:boolean,
	page?:string,
	sort?:ValueTypes["smallint"],
	tablet?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_page_banners_stddev_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_page_banners_stddev_pop_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_page_banners_stddev_samp_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_page_banners" */
["voyce_page_banners_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_page_banners_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_page_banners_stream_cursor_value_input"]: {
	active?:boolean,
	background?:string,
	created_at?:ValueTypes["timestamptz"],
	desktop?:string,
	id?:number,
	link?:string,
	mobile?:string,
	overlap?:boolean,
	page?:string,
	sort?:ValueTypes["smallint"],
	tablet?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_page_banners_sum_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.page_banners" */
["voyce_page_banners_update_column"]:voyce_page_banners_update_column;
	["voyce_page_banners_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_page_banners_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_page_banners_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_page_banners_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_page_banners_var_pop_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_page_banners_var_samp_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_page_banners_variance_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** Setup Page Sections / Components */
["voyce_page_components"]: AliasType<{
	active?:true,
	auth?:true,
	component?:true,
	created_at?:true,
	device?:true,
	id?:true,
	page?:true,
props?: [{	/** JSON select path */
	path?:string},true],
	sort?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.page_components" */
["voyce_page_components_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_page_components_aggregate_fields"],
	nodes?:ValueTypes["voyce_page_components"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.page_components" */
["voyce_page_components_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_page_components_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_page_components_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_page_components_max_fields"],
	min?:ValueTypes["voyce_page_components_min_fields"],
	stddev?:ValueTypes["voyce_page_components_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_page_components_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_page_components_stddev_samp_fields"],
	sum?:ValueTypes["voyce_page_components_sum_fields"],
	var_pop?:ValueTypes["voyce_page_components_var_pop_fields"],
	var_samp?:ValueTypes["voyce_page_components_var_samp_fields"],
	variance?:ValueTypes["voyce_page_components_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_page_components_avg_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.page_components". All fields are combined with a logical 'AND'. */
["voyce_page_components_bool_exp"]: {
	_and?:ValueTypes["voyce_page_components_bool_exp"][],
	_not?:ValueTypes["voyce_page_components_bool_exp"],
	_or?:ValueTypes["voyce_page_components_bool_exp"][],
	active?:ValueTypes["Boolean_comparison_exp"],
	auth?:ValueTypes["Boolean_comparison_exp"],
	component?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	device?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	page?:ValueTypes["String_comparison_exp"],
	props?:ValueTypes["json_comparison_exp"],
	sort?:ValueTypes["smallint_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.page_components" */
["voyce_page_components_constraint"]:voyce_page_components_constraint;
	/** input type for incrementing numeric columns in table "voyce.page_components" */
["voyce_page_components_inc_input"]: {
	id?:number,
	sort?:ValueTypes["smallint"]
};
	/** input type for inserting data into table "voyce.page_components" */
["voyce_page_components_insert_input"]: {
	active?:boolean,
	auth?:boolean,
	component?:string,
	created_at?:ValueTypes["timestamptz"],
	device?:string,
	id?:number,
	page?:string,
	props?:ValueTypes["json"],
	sort?:ValueTypes["smallint"],
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_page_components_max_fields"]: AliasType<{
	component?:true,
	created_at?:true,
	device?:true,
	id?:true,
	page?:true,
	sort?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_page_components_min_fields"]: AliasType<{
	component?:true,
	created_at?:true,
	device?:true,
	id?:true,
	page?:true,
	sort?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.page_components" */
["voyce_page_components_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_page_components"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.page_components" */
["voyce_page_components_on_conflict"]: {
	constraint:ValueTypes["voyce_page_components_constraint"],
	update_columns:ValueTypes["voyce_page_components_update_column"][],
	where?:ValueTypes["voyce_page_components_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.page_components". */
["voyce_page_components_order_by"]: {
	active?:ValueTypes["order_by"],
	auth?:ValueTypes["order_by"],
	component?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	device?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	page?:ValueTypes["order_by"],
	props?:ValueTypes["order_by"],
	sort?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.page_components */
["voyce_page_components_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.page_components" */
["voyce_page_components_select_column"]:voyce_page_components_select_column;
	/** input type for updating data in table "voyce.page_components" */
["voyce_page_components_set_input"]: {
	active?:boolean,
	auth?:boolean,
	component?:string,
	created_at?:ValueTypes["timestamptz"],
	device?:string,
	id?:number,
	page?:string,
	props?:ValueTypes["json"],
	sort?:ValueTypes["smallint"],
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_page_components_stddev_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_page_components_stddev_pop_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_page_components_stddev_samp_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_page_components" */
["voyce_page_components_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_page_components_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_page_components_stream_cursor_value_input"]: {
	active?:boolean,
	auth?:boolean,
	component?:string,
	created_at?:ValueTypes["timestamptz"],
	device?:string,
	id?:number,
	page?:string,
	props?:ValueTypes["json"],
	sort?:ValueTypes["smallint"],
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_page_components_sum_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.page_components" */
["voyce_page_components_update_column"]:voyce_page_components_update_column;
	["voyce_page_components_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_page_components_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_page_components_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_page_components_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_page_components_var_pop_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_page_components_var_samp_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_page_components_variance_fields"]: AliasType<{
	id?:true,
	sort?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.pages" */
["voyce_pages"]: AliasType<{
	content?:true,
	created_at?:true,
	id?:true,
	slug?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.pages" */
["voyce_pages_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_pages_aggregate_fields"],
	nodes?:ValueTypes["voyce_pages"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.pages" */
["voyce_pages_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_pages_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_pages_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_pages_max_fields"],
	min?:ValueTypes["voyce_pages_min_fields"],
	stddev?:ValueTypes["voyce_pages_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_pages_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_pages_stddev_samp_fields"],
	sum?:ValueTypes["voyce_pages_sum_fields"],
	var_pop?:ValueTypes["voyce_pages_var_pop_fields"],
	var_samp?:ValueTypes["voyce_pages_var_samp_fields"],
	variance?:ValueTypes["voyce_pages_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_pages_avg_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.pages". All fields are combined with a logical 'AND'. */
["voyce_pages_bool_exp"]: {
	_and?:ValueTypes["voyce_pages_bool_exp"][],
	_not?:ValueTypes["voyce_pages_bool_exp"],
	_or?:ValueTypes["voyce_pages_bool_exp"][],
	content?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	slug?:ValueTypes["String_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamp_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.pages" */
["voyce_pages_constraint"]:voyce_pages_constraint;
	/** input type for incrementing numeric columns in table "voyce.pages" */
["voyce_pages_inc_input"]: {
	id?:number
};
	/** input type for inserting data into table "voyce.pages" */
["voyce_pages_insert_input"]: {
	content?:string,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	slug?:string,
	title?:string,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate max on columns */
["voyce_pages_max_fields"]: AliasType<{
	content?:true,
	created_at?:true,
	id?:true,
	slug?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_pages_min_fields"]: AliasType<{
	content?:true,
	created_at?:true,
	id?:true,
	slug?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.pages" */
["voyce_pages_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_pages"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.pages" */
["voyce_pages_on_conflict"]: {
	constraint:ValueTypes["voyce_pages_constraint"],
	update_columns:ValueTypes["voyce_pages_update_column"][],
	where?:ValueTypes["voyce_pages_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.pages". */
["voyce_pages_order_by"]: {
	content?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	slug?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.pages */
["voyce_pages_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.pages" */
["voyce_pages_select_column"]:voyce_pages_select_column;
	/** input type for updating data in table "voyce.pages" */
["voyce_pages_set_input"]: {
	content?:string,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	slug?:string,
	title?:string,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate stddev on columns */
["voyce_pages_stddev_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_pages_stddev_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_pages_stddev_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_pages" */
["voyce_pages_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_pages_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_pages_stream_cursor_value_input"]: {
	content?:string,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	slug?:string,
	title?:string,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate sum on columns */
["voyce_pages_sum_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.pages" */
["voyce_pages_update_column"]:voyce_pages_update_column;
	["voyce_pages_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_pages_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_pages_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_pages_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_pages_var_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_pages_var_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_pages_variance_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.panels_comments" */
["voyce_panels_comments"]: AliasType<{
	animated_image?:true,
	/** An object relationship */
	chapter_panel?:ValueTypes["voyce_chapter_panels"],
	created_at?:true,
	id?:true,
	image?:true,
	linked_id?:true,
	/** An object relationship */
	panels_comment?:ValueTypes["voyce_panels_comments"],
panels_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_comments_bool_exp"]},ValueTypes["voyce_panels_comments"]],
panels_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_comments_bool_exp"]},ValueTypes["voyce_panels_comments_aggregate"]],
panels_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_bool_exp"]},ValueTypes["voyce_panels_reactions"]],
panels_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_bool_exp"]},ValueTypes["voyce_panels_reactions_aggregate"]],
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	text?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.panels_comments" */
["voyce_panels_comments_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_panels_comments_aggregate_fields"],
	nodes?:ValueTypes["voyce_panels_comments"],
		__typename?: true
}>;
	["voyce_panels_comments_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_panels_comments_aggregate_bool_exp_count"]
};
	["voyce_panels_comments_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_panels_comments_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_panels_comments_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.panels_comments" */
["voyce_panels_comments_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_panels_comments_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_panels_comments_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_panels_comments_max_fields"],
	min?:ValueTypes["voyce_panels_comments_min_fields"],
	stddev?:ValueTypes["voyce_panels_comments_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_panels_comments_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_panels_comments_stddev_samp_fields"],
	sum?:ValueTypes["voyce_panels_comments_sum_fields"],
	var_pop?:ValueTypes["voyce_panels_comments_var_pop_fields"],
	var_samp?:ValueTypes["voyce_panels_comments_var_samp_fields"],
	variance?:ValueTypes["voyce_panels_comments_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.panels_comments" */
["voyce_panels_comments_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_panels_comments_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_panels_comments_max_order_by"],
	min?:ValueTypes["voyce_panels_comments_min_order_by"],
	stddev?:ValueTypes["voyce_panels_comments_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_panels_comments_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_panels_comments_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_panels_comments_sum_order_by"],
	var_pop?:ValueTypes["voyce_panels_comments_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_panels_comments_var_samp_order_by"],
	variance?:ValueTypes["voyce_panels_comments_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.panels_comments" */
["voyce_panels_comments_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_panels_comments_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_panels_comments_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_panels_comments_avg_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.panels_comments". All fields are combined with a logical 'AND'. */
["voyce_panels_comments_bool_exp"]: {
	_and?:ValueTypes["voyce_panels_comments_bool_exp"][],
	_not?:ValueTypes["voyce_panels_comments_bool_exp"],
	_or?:ValueTypes["voyce_panels_comments_bool_exp"][],
	animated_image?:ValueTypes["String_comparison_exp"],
	chapter_panel?:ValueTypes["voyce_chapter_panels_bool_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	image?:ValueTypes["String_comparison_exp"],
	linked_id?:ValueTypes["Int_comparison_exp"],
	panels_comment?:ValueTypes["voyce_panels_comments_bool_exp"],
	panels_comments?:ValueTypes["voyce_panels_comments_bool_exp"],
	panels_comments_aggregate?:ValueTypes["voyce_panels_comments_aggregate_bool_exp"],
	panels_reactions?:ValueTypes["voyce_panels_reactions_bool_exp"],
	panels_reactions_aggregate?:ValueTypes["voyce_panels_reactions_aggregate_bool_exp"],
	parent_id?:ValueTypes["Int_comparison_exp"],
	reaction_count?:ValueTypes["Int_comparison_exp"],
	replies_count?:ValueTypes["Int_comparison_exp"],
	text?:ValueTypes["String_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.panels_comments" */
["voyce_panels_comments_constraint"]:voyce_panels_comments_constraint;
	/** input type for incrementing numeric columns in table "voyce.panels_comments" */
["voyce_panels_comments_inc_input"]: {
	id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.panels_comments" */
["voyce_panels_comments_insert_input"]: {
	animated_image?:string,
	chapter_panel?:ValueTypes["voyce_chapter_panels_obj_rel_insert_input"],
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	image?:string,
	linked_id?:number,
	panels_comment?:ValueTypes["voyce_panels_comments_obj_rel_insert_input"],
	panels_comments?:ValueTypes["voyce_panels_comments_arr_rel_insert_input"],
	panels_reactions?:ValueTypes["voyce_panels_reactions_arr_rel_insert_input"],
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	text?:string,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_panels_comments_max_fields"]: AliasType<{
	animated_image?:true,
	created_at?:true,
	id?:true,
	image?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	text?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_max_order_by"]: {
	animated_image?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	text?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_panels_comments_min_fields"]: AliasType<{
	animated_image?:true,
	created_at?:true,
	id?:true,
	image?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	text?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_min_order_by"]: {
	animated_image?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	text?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.panels_comments" */
["voyce_panels_comments_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_panels_comments"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.panels_comments" */
["voyce_panels_comments_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_panels_comments_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_panels_comments_on_conflict"]
};
	/** on_conflict condition type for table "voyce.panels_comments" */
["voyce_panels_comments_on_conflict"]: {
	constraint:ValueTypes["voyce_panels_comments_constraint"],
	update_columns:ValueTypes["voyce_panels_comments_update_column"][],
	where?:ValueTypes["voyce_panels_comments_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.panels_comments". */
["voyce_panels_comments_order_by"]: {
	animated_image?:ValueTypes["order_by"],
	chapter_panel?:ValueTypes["voyce_chapter_panels_order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	panels_comment?:ValueTypes["voyce_panels_comments_order_by"],
	panels_comments_aggregate?:ValueTypes["voyce_panels_comments_aggregate_order_by"],
	panels_reactions_aggregate?:ValueTypes["voyce_panels_reactions_aggregate_order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	text?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.panels_comments */
["voyce_panels_comments_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.panels_comments" */
["voyce_panels_comments_select_column"]:voyce_panels_comments_select_column;
	/** input type for updating data in table "voyce.panels_comments" */
["voyce_panels_comments_set_input"]: {
	animated_image?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	image?:string,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	text?:string,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_panels_comments_stddev_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_panels_comments_stddev_pop_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_panels_comments_stddev_samp_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_panels_comments" */
["voyce_panels_comments_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_panels_comments_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_panels_comments_stream_cursor_value_input"]: {
	animated_image?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	image?:string,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	text?:string,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_panels_comments_sum_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.panels_comments" */
["voyce_panels_comments_update_column"]:voyce_panels_comments_update_column;
	["voyce_panels_comments_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_panels_comments_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_panels_comments_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_panels_comments_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_panels_comments_var_pop_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_panels_comments_var_samp_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_panels_comments_variance_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.panels_reactions" */
["voyce_panels_reactions"]: AliasType<{
	/** An object relationship */
	chapter_panel?:ValueTypes["voyce_chapter_panels"],
	comment_id?:true,
	created_at?:true,
	id?:true,
	linked_entity_id?:true,
	/** An object relationship */
	panels_comment?:ValueTypes["voyce_panels_comments"],
	reaction?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.panels_reactions" */
["voyce_panels_reactions_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_panels_reactions_aggregate_fields"],
	nodes?:ValueTypes["voyce_panels_reactions"],
		__typename?: true
}>;
	["voyce_panels_reactions_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_panels_reactions_aggregate_bool_exp_count"]
};
	["voyce_panels_reactions_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_panels_reactions_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_panels_reactions_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.panels_reactions" */
["voyce_panels_reactions_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_panels_reactions_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_panels_reactions_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_panels_reactions_max_fields"],
	min?:ValueTypes["voyce_panels_reactions_min_fields"],
	stddev?:ValueTypes["voyce_panels_reactions_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_panels_reactions_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_panels_reactions_stddev_samp_fields"],
	sum?:ValueTypes["voyce_panels_reactions_sum_fields"],
	var_pop?:ValueTypes["voyce_panels_reactions_var_pop_fields"],
	var_samp?:ValueTypes["voyce_panels_reactions_var_samp_fields"],
	variance?:ValueTypes["voyce_panels_reactions_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.panels_reactions" */
["voyce_panels_reactions_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_panels_reactions_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_panels_reactions_max_order_by"],
	min?:ValueTypes["voyce_panels_reactions_min_order_by"],
	stddev?:ValueTypes["voyce_panels_reactions_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_panels_reactions_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_panels_reactions_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_panels_reactions_sum_order_by"],
	var_pop?:ValueTypes["voyce_panels_reactions_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_panels_reactions_var_samp_order_by"],
	variance?:ValueTypes["voyce_panels_reactions_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.panels_reactions" */
["voyce_panels_reactions_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_panels_reactions_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_panels_reactions_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_panels_reactions_avg_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_avg_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.panels_reactions". All fields are combined with a logical 'AND'. */
["voyce_panels_reactions_bool_exp"]: {
	_and?:ValueTypes["voyce_panels_reactions_bool_exp"][],
	_not?:ValueTypes["voyce_panels_reactions_bool_exp"],
	_or?:ValueTypes["voyce_panels_reactions_bool_exp"][],
	chapter_panel?:ValueTypes["voyce_chapter_panels_bool_exp"],
	comment_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	linked_entity_id?:ValueTypes["Int_comparison_exp"],
	panels_comment?:ValueTypes["voyce_panels_comments_bool_exp"],
	reaction?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.panels_reactions" */
["voyce_panels_reactions_constraint"]:voyce_panels_reactions_constraint;
	/** Tabel for reactions count */
["voyce_panels_reactions_count"]: AliasType<{
	/** An object relationship */
	chapter_panel?:ValueTypes["voyce_chapter_panels"],
	count?:true,
	id?:true,
	panel_id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_panels_reactions_count_aggregate_fields"],
	nodes?:ValueTypes["voyce_panels_reactions_count"],
		__typename?: true
}>;
	["voyce_panels_reactions_count_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_panels_reactions_count_aggregate_bool_exp_count"]
};
	["voyce_panels_reactions_count_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_panels_reactions_count_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_panels_reactions_count_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_panels_reactions_count_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_panels_reactions_count_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_panels_reactions_count_max_fields"],
	min?:ValueTypes["voyce_panels_reactions_count_min_fields"],
	stddev?:ValueTypes["voyce_panels_reactions_count_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_panels_reactions_count_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_panels_reactions_count_stddev_samp_fields"],
	sum?:ValueTypes["voyce_panels_reactions_count_sum_fields"],
	var_pop?:ValueTypes["voyce_panels_reactions_count_var_pop_fields"],
	var_samp?:ValueTypes["voyce_panels_reactions_count_var_samp_fields"],
	variance?:ValueTypes["voyce_panels_reactions_count_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_panels_reactions_count_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_panels_reactions_count_max_order_by"],
	min?:ValueTypes["voyce_panels_reactions_count_min_order_by"],
	stddev?:ValueTypes["voyce_panels_reactions_count_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_panels_reactions_count_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_panels_reactions_count_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_panels_reactions_count_sum_order_by"],
	var_pop?:ValueTypes["voyce_panels_reactions_count_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_panels_reactions_count_var_samp_order_by"],
	variance?:ValueTypes["voyce_panels_reactions_count_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_panels_reactions_count_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_panels_reactions_count_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_panels_reactions_count_avg_fields"]: AliasType<{
	count?:true,
	id?:true,
	panel_id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_avg_order_by"]: {
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	panel_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.panels_reactions_count". All fields are combined with a logical 'AND'. */
["voyce_panels_reactions_count_bool_exp"]: {
	_and?:ValueTypes["voyce_panels_reactions_count_bool_exp"][],
	_not?:ValueTypes["voyce_panels_reactions_count_bool_exp"],
	_or?:ValueTypes["voyce_panels_reactions_count_bool_exp"][],
	chapter_panel?:ValueTypes["voyce_chapter_panels_bool_exp"],
	count?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	panel_id?:ValueTypes["Int_comparison_exp"],
	reaction?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_constraint"]:voyce_panels_reactions_count_constraint;
	/** input type for incrementing numeric columns in table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_inc_input"]: {
	count?:number,
	id?:number,
	panel_id?:number,
	reaction?:number
};
	/** input type for inserting data into table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_insert_input"]: {
	chapter_panel?:ValueTypes["voyce_chapter_panels_obj_rel_insert_input"],
	count?:number,
	id?:number,
	panel_id?:number,
	reaction?:number
};
	/** aggregate max on columns */
["voyce_panels_reactions_count_max_fields"]: AliasType<{
	count?:true,
	id?:true,
	panel_id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_max_order_by"]: {
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	panel_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_panels_reactions_count_min_fields"]: AliasType<{
	count?:true,
	id?:true,
	panel_id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_min_order_by"]: {
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	panel_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_panels_reactions_count"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_on_conflict"]: {
	constraint:ValueTypes["voyce_panels_reactions_count_constraint"],
	update_columns:ValueTypes["voyce_panels_reactions_count_update_column"][],
	where?:ValueTypes["voyce_panels_reactions_count_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.panels_reactions_count". */
["voyce_panels_reactions_count_order_by"]: {
	chapter_panel?:ValueTypes["voyce_chapter_panels_order_by"],
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	panel_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.panels_reactions_count */
["voyce_panels_reactions_count_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_select_column"]:voyce_panels_reactions_count_select_column;
	/** input type for updating data in table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_set_input"]: {
	count?:number,
	id?:number,
	panel_id?:number,
	reaction?:number
};
	/** aggregate stddev on columns */
["voyce_panels_reactions_count_stddev_fields"]: AliasType<{
	count?:true,
	id?:true,
	panel_id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_stddev_order_by"]: {
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	panel_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_panels_reactions_count_stddev_pop_fields"]: AliasType<{
	count?:true,
	id?:true,
	panel_id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_stddev_pop_order_by"]: {
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	panel_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_panels_reactions_count_stddev_samp_fields"]: AliasType<{
	count?:true,
	id?:true,
	panel_id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_stddev_samp_order_by"]: {
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	panel_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_panels_reactions_count" */
["voyce_panels_reactions_count_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_panels_reactions_count_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_panels_reactions_count_stream_cursor_value_input"]: {
	count?:number,
	id?:number,
	panel_id?:number,
	reaction?:number
};
	/** aggregate sum on columns */
["voyce_panels_reactions_count_sum_fields"]: AliasType<{
	count?:true,
	id?:true,
	panel_id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_sum_order_by"]: {
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	panel_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_update_column"]:voyce_panels_reactions_count_update_column;
	["voyce_panels_reactions_count_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_panels_reactions_count_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_panels_reactions_count_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_panels_reactions_count_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_panels_reactions_count_var_pop_fields"]: AliasType<{
	count?:true,
	id?:true,
	panel_id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_var_pop_order_by"]: {
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	panel_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_panels_reactions_count_var_samp_fields"]: AliasType<{
	count?:true,
	id?:true,
	panel_id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_var_samp_order_by"]: {
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	panel_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_panels_reactions_count_variance_fields"]: AliasType<{
	count?:true,
	id?:true,
	panel_id?:true,
	reaction?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_variance_order_by"]: {
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	panel_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"]
};
	/** input type for incrementing numeric columns in table "voyce.panels_reactions" */
["voyce_panels_reactions_inc_input"]: {
	comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.panels_reactions" */
["voyce_panels_reactions_insert_input"]: {
	chapter_panel?:ValueTypes["voyce_chapter_panels_obj_rel_insert_input"],
	comment_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	panels_comment?:ValueTypes["voyce_panels_comments_obj_rel_insert_input"],
	reaction?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_panels_reactions_max_fields"]: AliasType<{
	comment_id?:true,
	created_at?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_max_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_panels_reactions_min_fields"]: AliasType<{
	comment_id?:true,
	created_at?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_min_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.panels_reactions" */
["voyce_panels_reactions_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_panels_reactions"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.panels_reactions" */
["voyce_panels_reactions_on_conflict"]: {
	constraint:ValueTypes["voyce_panels_reactions_constraint"],
	update_columns:ValueTypes["voyce_panels_reactions_update_column"][],
	where?:ValueTypes["voyce_panels_reactions_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.panels_reactions". */
["voyce_panels_reactions_order_by"]: {
	chapter_panel?:ValueTypes["voyce_chapter_panels_order_by"],
	comment_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	panels_comment?:ValueTypes["voyce_panels_comments_order_by"],
	reaction?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.panels_reactions */
["voyce_panels_reactions_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_select_column"]:voyce_panels_reactions_select_column;
	/** input type for updating data in table "voyce.panels_reactions" */
["voyce_panels_reactions_set_input"]: {
	comment_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_panels_reactions_stddev_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_stddev_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_panels_reactions_stddev_pop_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_stddev_pop_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_panels_reactions_stddev_samp_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_stddev_samp_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_panels_reactions" */
["voyce_panels_reactions_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_panels_reactions_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_panels_reactions_stream_cursor_value_input"]: {
	comment_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_panels_reactions_sum_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_sum_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_update_column"]:voyce_panels_reactions_update_column;
	["voyce_panels_reactions_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_panels_reactions_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_panels_reactions_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_panels_reactions_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_panels_reactions_var_pop_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_var_pop_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_panels_reactions_var_samp_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_var_samp_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_panels_reactions_variance_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_variance_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.payments_to_creators" */
["voyce_payments_to_creators"]: AliasType<{
	amount?:true,
	created_at?:true,
	id?:true,
	stripe_id?:true,
	/** An object relationship */
	user_from?:ValueTypes["voyce_users"],
	user_from_id?:true,
	/** An object relationship */
	user_to?:ValueTypes["voyce_users"],
	user_to_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.payments_to_creators" */
["voyce_payments_to_creators_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_payments_to_creators_aggregate_fields"],
	nodes?:ValueTypes["voyce_payments_to_creators"],
		__typename?: true
}>;
	["voyce_payments_to_creators_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_payments_to_creators_aggregate_bool_exp_count"]
};
	["voyce_payments_to_creators_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_payments_to_creators_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_payments_to_creators_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.payments_to_creators" */
["voyce_payments_to_creators_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_payments_to_creators_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_payments_to_creators_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_payments_to_creators_max_fields"],
	min?:ValueTypes["voyce_payments_to_creators_min_fields"],
	stddev?:ValueTypes["voyce_payments_to_creators_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_payments_to_creators_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_payments_to_creators_stddev_samp_fields"],
	sum?:ValueTypes["voyce_payments_to_creators_sum_fields"],
	var_pop?:ValueTypes["voyce_payments_to_creators_var_pop_fields"],
	var_samp?:ValueTypes["voyce_payments_to_creators_var_samp_fields"],
	variance?:ValueTypes["voyce_payments_to_creators_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_payments_to_creators_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_payments_to_creators_max_order_by"],
	min?:ValueTypes["voyce_payments_to_creators_min_order_by"],
	stddev?:ValueTypes["voyce_payments_to_creators_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_payments_to_creators_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_payments_to_creators_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_payments_to_creators_sum_order_by"],
	var_pop?:ValueTypes["voyce_payments_to_creators_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_payments_to_creators_var_samp_order_by"],
	variance?:ValueTypes["voyce_payments_to_creators_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.payments_to_creators" */
["voyce_payments_to_creators_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_payments_to_creators_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_payments_to_creators_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_payments_to_creators_avg_fields"]: AliasType<{
	amount?:true,
	id?:true,
	user_from_id?:true,
	user_to_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_avg_order_by"]: {
	amount?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	user_from_id?:ValueTypes["order_by"],
	user_to_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.payments_to_creators". All fields are combined with a logical 'AND'. */
["voyce_payments_to_creators_bool_exp"]: {
	_and?:ValueTypes["voyce_payments_to_creators_bool_exp"][],
	_not?:ValueTypes["voyce_payments_to_creators_bool_exp"],
	_or?:ValueTypes["voyce_payments_to_creators_bool_exp"][],
	amount?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	stripe_id?:ValueTypes["String_comparison_exp"],
	user_from?:ValueTypes["voyce_users_bool_exp"],
	user_from_id?:ValueTypes["Int_comparison_exp"],
	user_to?:ValueTypes["voyce_users_bool_exp"],
	user_to_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.payments_to_creators" */
["voyce_payments_to_creators_constraint"]:voyce_payments_to_creators_constraint;
	/** input type for incrementing numeric columns in table "voyce.payments_to_creators" */
["voyce_payments_to_creators_inc_input"]: {
	amount?:number,
	id?:number,
	user_from_id?:number,
	user_to_id?:number
};
	/** input type for inserting data into table "voyce.payments_to_creators" */
["voyce_payments_to_creators_insert_input"]: {
	amount?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	stripe_id?:string,
	user_from?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_from_id?:number,
	user_to?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_to_id?:number
};
	/** aggregate max on columns */
["voyce_payments_to_creators_max_fields"]: AliasType<{
	amount?:true,
	created_at?:true,
	id?:true,
	stripe_id?:true,
	user_from_id?:true,
	user_to_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_max_order_by"]: {
	amount?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	stripe_id?:ValueTypes["order_by"],
	user_from_id?:ValueTypes["order_by"],
	user_to_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_payments_to_creators_min_fields"]: AliasType<{
	amount?:true,
	created_at?:true,
	id?:true,
	stripe_id?:true,
	user_from_id?:true,
	user_to_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_min_order_by"]: {
	amount?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	stripe_id?:ValueTypes["order_by"],
	user_from_id?:ValueTypes["order_by"],
	user_to_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.payments_to_creators" */
["voyce_payments_to_creators_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_payments_to_creators"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.payments_to_creators" */
["voyce_payments_to_creators_on_conflict"]: {
	constraint:ValueTypes["voyce_payments_to_creators_constraint"],
	update_columns:ValueTypes["voyce_payments_to_creators_update_column"][],
	where?:ValueTypes["voyce_payments_to_creators_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.payments_to_creators". */
["voyce_payments_to_creators_order_by"]: {
	amount?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	stripe_id?:ValueTypes["order_by"],
	user_from?:ValueTypes["voyce_users_order_by"],
	user_from_id?:ValueTypes["order_by"],
	user_to?:ValueTypes["voyce_users_order_by"],
	user_to_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.payments_to_creators */
["voyce_payments_to_creators_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_select_column"]:voyce_payments_to_creators_select_column;
	/** input type for updating data in table "voyce.payments_to_creators" */
["voyce_payments_to_creators_set_input"]: {
	amount?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	stripe_id?:string,
	user_from_id?:number,
	user_to_id?:number
};
	/** aggregate stddev on columns */
["voyce_payments_to_creators_stddev_fields"]: AliasType<{
	amount?:true,
	id?:true,
	user_from_id?:true,
	user_to_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_stddev_order_by"]: {
	amount?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	user_from_id?:ValueTypes["order_by"],
	user_to_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_payments_to_creators_stddev_pop_fields"]: AliasType<{
	amount?:true,
	id?:true,
	user_from_id?:true,
	user_to_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_stddev_pop_order_by"]: {
	amount?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	user_from_id?:ValueTypes["order_by"],
	user_to_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_payments_to_creators_stddev_samp_fields"]: AliasType<{
	amount?:true,
	id?:true,
	user_from_id?:true,
	user_to_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_stddev_samp_order_by"]: {
	amount?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	user_from_id?:ValueTypes["order_by"],
	user_to_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_payments_to_creators" */
["voyce_payments_to_creators_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_payments_to_creators_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_payments_to_creators_stream_cursor_value_input"]: {
	amount?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	stripe_id?:string,
	user_from_id?:number,
	user_to_id?:number
};
	/** aggregate sum on columns */
["voyce_payments_to_creators_sum_fields"]: AliasType<{
	amount?:true,
	id?:true,
	user_from_id?:true,
	user_to_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_sum_order_by"]: {
	amount?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	user_from_id?:ValueTypes["order_by"],
	user_to_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_update_column"]:voyce_payments_to_creators_update_column;
	["voyce_payments_to_creators_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_payments_to_creators_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_payments_to_creators_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_payments_to_creators_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_payments_to_creators_var_pop_fields"]: AliasType<{
	amount?:true,
	id?:true,
	user_from_id?:true,
	user_to_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_var_pop_order_by"]: {
	amount?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	user_from_id?:ValueTypes["order_by"],
	user_to_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_payments_to_creators_var_samp_fields"]: AliasType<{
	amount?:true,
	id?:true,
	user_from_id?:true,
	user_to_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_var_samp_order_by"]: {
	amount?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	user_from_id?:ValueTypes["order_by"],
	user_to_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_payments_to_creators_variance_fields"]: AliasType<{
	amount?:true,
	id?:true,
	user_from_id?:true,
	user_to_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_variance_order_by"]: {
	amount?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	user_from_id?:ValueTypes["order_by"],
	user_to_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_pg_rs_series_views_count_aggregate_fields"],
	nodes?:ValueTypes["voyce_pg_rs_series_views_count"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_pg_rs_series_views_count_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_pg_rs_series_views_count_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_pg_rs_series_views_count_max_fields"],
	min?:ValueTypes["voyce_pg_rs_series_views_count_min_fields"],
	stddev?:ValueTypes["voyce_pg_rs_series_views_count_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_pg_rs_series_views_count_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_pg_rs_series_views_count_stddev_samp_fields"],
	sum?:ValueTypes["voyce_pg_rs_series_views_count_sum_fields"],
	var_pop?:ValueTypes["voyce_pg_rs_series_views_count_var_pop_fields"],
	var_samp?:ValueTypes["voyce_pg_rs_series_views_count_var_samp_fields"],
	variance?:ValueTypes["voyce_pg_rs_series_views_count_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_pg_rs_series_views_count_avg_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.pg_rs_series_views_count". All fields are combined with a logical 'AND'. */
["voyce_pg_rs_series_views_count_bool_exp"]: {
	_and?:ValueTypes["voyce_pg_rs_series_views_count_bool_exp"][],
	_not?:ValueTypes["voyce_pg_rs_series_views_count_bool_exp"],
	_or?:ValueTypes["voyce_pg_rs_series_views_count_bool_exp"][],
	count?:ValueTypes["bigint_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_constraint"]:voyce_pg_rs_series_views_count_constraint;
	/** input type for incrementing numeric columns in table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_inc_input"]: {
	count?:ValueTypes["bigint"],
	series_id?:number
};
	/** input type for inserting data into table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_insert_input"]: {
	count?:ValueTypes["bigint"],
	series_id?:number
};
	/** aggregate max on columns */
["voyce_pg_rs_series_views_count_max_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_pg_rs_series_views_count_min_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_pg_rs_series_views_count"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_pg_rs_series_views_count_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_pg_rs_series_views_count_on_conflict"]
};
	/** on_conflict condition type for table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_on_conflict"]: {
	constraint:ValueTypes["voyce_pg_rs_series_views_count_constraint"],
	update_columns:ValueTypes["voyce_pg_rs_series_views_count_update_column"][],
	where?:ValueTypes["voyce_pg_rs_series_views_count_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.pg_rs_series_views_count". */
["voyce_pg_rs_series_views_count_order_by"]: {
	count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.pg_rs_series_views_count */
["voyce_pg_rs_series_views_count_pk_columns_input"]: {
	series_id:number
};
	/** select columns of table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_select_column"]:voyce_pg_rs_series_views_count_select_column;
	/** input type for updating data in table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_set_input"]: {
	count?:ValueTypes["bigint"],
	series_id?:number
};
	/** aggregate stddev on columns */
["voyce_pg_rs_series_views_count_stddev_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_pg_rs_series_views_count_stddev_pop_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_pg_rs_series_views_count_stddev_samp_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_pg_rs_series_views_count_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_pg_rs_series_views_count_stream_cursor_value_input"]: {
	count?:ValueTypes["bigint"],
	series_id?:number
};
	/** aggregate sum on columns */
["voyce_pg_rs_series_views_count_sum_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_update_column"]:voyce_pg_rs_series_views_count_update_column;
	["voyce_pg_rs_series_views_count_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_pg_rs_series_views_count_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_pg_rs_series_views_count_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_pg_rs_series_views_count_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_pg_rs_series_views_count_var_pop_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_pg_rs_series_views_count_var_samp_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_pg_rs_series_views_count_variance_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.polls" */
["voyce_polls"]: AliasType<{
	comments_count?:true,
	created_at?:true,
	end_date?:true,
	id?:true,
	last_activity_date?:true,
	likes_count?:true,
	outdated?:true,
polls_answers?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_answers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_answers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_answers_bool_exp"]},ValueTypes["voyce_polls_answers"]],
polls_answers_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_answers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_answers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_answers_bool_exp"]},ValueTypes["voyce_polls_answers_aggregate"]],
polls_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_comments_bool_exp"]},ValueTypes["voyce_polls_comments"]],
polls_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_comments_bool_exp"]},ValueTypes["voyce_polls_comments_aggregate"]],
polls_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_reactions_bool_exp"]},ValueTypes["voyce_polls_reactions"]],
polls_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_reactions_bool_exp"]},ValueTypes["voyce_polls_reactions_aggregate"]],
polls_votes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_votes_bool_exp"]},ValueTypes["voyce_polls_votes"]],
polls_votes_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_votes_bool_exp"]},ValueTypes["voyce_polls_votes_aggregate"]],
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	spoiler?:true,
	title?:true,
	total_popularity?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.polls" */
["voyce_polls_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_polls_aggregate_fields"],
	nodes?:ValueTypes["voyce_polls"],
		__typename?: true
}>;
	["voyce_polls_aggregate_bool_exp"]: {
	bool_and?:ValueTypes["voyce_polls_aggregate_bool_exp_bool_and"],
	bool_or?:ValueTypes["voyce_polls_aggregate_bool_exp_bool_or"],
	count?:ValueTypes["voyce_polls_aggregate_bool_exp_count"]
};
	["voyce_polls_aggregate_bool_exp_bool_and"]: {
	arguments:ValueTypes["voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_polls_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_polls_aggregate_bool_exp_bool_or"]: {
	arguments:ValueTypes["voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_polls_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_polls_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_polls_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_polls_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.polls" */
["voyce_polls_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_polls_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_polls_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_polls_max_fields"],
	min?:ValueTypes["voyce_polls_min_fields"],
	stddev?:ValueTypes["voyce_polls_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_polls_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_polls_stddev_samp_fields"],
	sum?:ValueTypes["voyce_polls_sum_fields"],
	var_pop?:ValueTypes["voyce_polls_var_pop_fields"],
	var_samp?:ValueTypes["voyce_polls_var_samp_fields"],
	variance?:ValueTypes["voyce_polls_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.polls" */
["voyce_polls_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_polls_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_polls_max_order_by"],
	min?:ValueTypes["voyce_polls_min_order_by"],
	stddev?:ValueTypes["voyce_polls_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_polls_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_polls_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_polls_sum_order_by"],
	var_pop?:ValueTypes["voyce_polls_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_polls_var_samp_order_by"],
	variance?:ValueTypes["voyce_polls_variance_order_by"]
};
	/** columns and relationships of "voyce.polls_answers" */
["voyce_polls_answers"]: AliasType<{
	id?:true,
	image?:true,
	order?:true,
	/** An object relationship */
	poll?:ValueTypes["voyce_polls"],
	poll_id?:true,
polls_votes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_votes_bool_exp"]},ValueTypes["voyce_polls_votes"]],
polls_votes_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_votes_bool_exp"]},ValueTypes["voyce_polls_votes_aggregate"]],
	text?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.polls_answers" */
["voyce_polls_answers_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_polls_answers_aggregate_fields"],
	nodes?:ValueTypes["voyce_polls_answers"],
		__typename?: true
}>;
	["voyce_polls_answers_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_polls_answers_aggregate_bool_exp_count"]
};
	["voyce_polls_answers_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_polls_answers_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_polls_answers_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.polls_answers" */
["voyce_polls_answers_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_polls_answers_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_polls_answers_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_polls_answers_max_fields"],
	min?:ValueTypes["voyce_polls_answers_min_fields"],
	stddev?:ValueTypes["voyce_polls_answers_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_polls_answers_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_polls_answers_stddev_samp_fields"],
	sum?:ValueTypes["voyce_polls_answers_sum_fields"],
	var_pop?:ValueTypes["voyce_polls_answers_var_pop_fields"],
	var_samp?:ValueTypes["voyce_polls_answers_var_samp_fields"],
	variance?:ValueTypes["voyce_polls_answers_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.polls_answers" */
["voyce_polls_answers_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_polls_answers_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_polls_answers_max_order_by"],
	min?:ValueTypes["voyce_polls_answers_min_order_by"],
	stddev?:ValueTypes["voyce_polls_answers_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_polls_answers_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_polls_answers_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_polls_answers_sum_order_by"],
	var_pop?:ValueTypes["voyce_polls_answers_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_polls_answers_var_samp_order_by"],
	variance?:ValueTypes["voyce_polls_answers_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.polls_answers" */
["voyce_polls_answers_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_polls_answers_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_answers_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_polls_answers_avg_fields"]: AliasType<{
	id?:true,
	order?:true,
	poll_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.polls_answers". All fields are combined with a logical 'AND'. */
["voyce_polls_answers_bool_exp"]: {
	_and?:ValueTypes["voyce_polls_answers_bool_exp"][],
	_not?:ValueTypes["voyce_polls_answers_bool_exp"],
	_or?:ValueTypes["voyce_polls_answers_bool_exp"][],
	id?:ValueTypes["Int_comparison_exp"],
	image?:ValueTypes["String_comparison_exp"],
	order?:ValueTypes["Int_comparison_exp"],
	poll?:ValueTypes["voyce_polls_bool_exp"],
	poll_id?:ValueTypes["Int_comparison_exp"],
	polls_votes?:ValueTypes["voyce_polls_votes_bool_exp"],
	polls_votes_aggregate?:ValueTypes["voyce_polls_votes_aggregate_bool_exp"],
	text?:ValueTypes["String_comparison_exp"],
	votes_count?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.polls_answers" */
["voyce_polls_answers_constraint"]:voyce_polls_answers_constraint;
	/** input type for incrementing numeric columns in table "voyce.polls_answers" */
["voyce_polls_answers_inc_input"]: {
	id?:number,
	order?:number,
	poll_id?:number,
	votes_count?:number
};
	/** input type for inserting data into table "voyce.polls_answers" */
["voyce_polls_answers_insert_input"]: {
	id?:number,
	image?:string,
	order?:number,
	poll?:ValueTypes["voyce_polls_obj_rel_insert_input"],
	poll_id?:number,
	polls_votes?:ValueTypes["voyce_polls_votes_arr_rel_insert_input"],
	text?:string,
	votes_count?:number
};
	/** aggregate max on columns */
["voyce_polls_answers_max_fields"]: AliasType<{
	id?:true,
	image?:true,
	order?:true,
	poll_id?:true,
	text?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_max_order_by"]: {
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	text?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_polls_answers_min_fields"]: AliasType<{
	id?:true,
	image?:true,
	order?:true,
	poll_id?:true,
	text?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_min_order_by"]: {
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	text?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.polls_answers" */
["voyce_polls_answers_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_polls_answers"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.polls_answers" */
["voyce_polls_answers_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_polls_answers_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_answers_on_conflict"]
};
	/** on_conflict condition type for table "voyce.polls_answers" */
["voyce_polls_answers_on_conflict"]: {
	constraint:ValueTypes["voyce_polls_answers_constraint"],
	update_columns:ValueTypes["voyce_polls_answers_update_column"][],
	where?:ValueTypes["voyce_polls_answers_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.polls_answers". */
["voyce_polls_answers_order_by"]: {
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	poll?:ValueTypes["voyce_polls_order_by"],
	poll_id?:ValueTypes["order_by"],
	polls_votes_aggregate?:ValueTypes["voyce_polls_votes_aggregate_order_by"],
	text?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.polls_answers */
["voyce_polls_answers_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.polls_answers" */
["voyce_polls_answers_select_column"]:voyce_polls_answers_select_column;
	/** input type for updating data in table "voyce.polls_answers" */
["voyce_polls_answers_set_input"]: {
	id?:number,
	image?:string,
	order?:number,
	poll_id?:number,
	text?:string,
	votes_count?:number
};
	/** aggregate stddev on columns */
["voyce_polls_answers_stddev_fields"]: AliasType<{
	id?:true,
	order?:true,
	poll_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_polls_answers_stddev_pop_fields"]: AliasType<{
	id?:true,
	order?:true,
	poll_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_polls_answers_stddev_samp_fields"]: AliasType<{
	id?:true,
	order?:true,
	poll_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_polls_answers" */
["voyce_polls_answers_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_polls_answers_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_polls_answers_stream_cursor_value_input"]: {
	id?:number,
	image?:string,
	order?:number,
	poll_id?:number,
	text?:string,
	votes_count?:number
};
	/** aggregate sum on columns */
["voyce_polls_answers_sum_fields"]: AliasType<{
	id?:true,
	order?:true,
	poll_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.polls_answers" */
["voyce_polls_answers_update_column"]:voyce_polls_answers_update_column;
	["voyce_polls_answers_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_polls_answers_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_polls_answers_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_polls_answers_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_polls_answers_var_pop_fields"]: AliasType<{
	id?:true,
	order?:true,
	poll_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_polls_answers_var_samp_fields"]: AliasType<{
	id?:true,
	order?:true,
	poll_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_polls_answers_variance_fields"]: AliasType<{
	id?:true,
	order?:true,
	poll_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** input type for inserting array relation for remote table "voyce.polls" */
["voyce_polls_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_polls_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_polls_avg_fields"]: AliasType<{
	comments_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	total_popularity?:true,
	user_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.polls" */
["voyce_polls_avg_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.polls". All fields are combined with a logical 'AND'. */
["voyce_polls_bool_exp"]: {
	_and?:ValueTypes["voyce_polls_bool_exp"][],
	_not?:ValueTypes["voyce_polls_bool_exp"],
	_or?:ValueTypes["voyce_polls_bool_exp"][],
	comments_count?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	end_date?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	last_activity_date?:ValueTypes["timestamp_comparison_exp"],
	likes_count?:ValueTypes["Int_comparison_exp"],
	outdated?:ValueTypes["Boolean_comparison_exp"],
	polls_answers?:ValueTypes["voyce_polls_answers_bool_exp"],
	polls_answers_aggregate?:ValueTypes["voyce_polls_answers_aggregate_bool_exp"],
	polls_comments?:ValueTypes["voyce_polls_comments_bool_exp"],
	polls_comments_aggregate?:ValueTypes["voyce_polls_comments_aggregate_bool_exp"],
	polls_reactions?:ValueTypes["voyce_polls_reactions_bool_exp"],
	polls_reactions_aggregate?:ValueTypes["voyce_polls_reactions_aggregate_bool_exp"],
	polls_votes?:ValueTypes["voyce_polls_votes_bool_exp"],
	polls_votes_aggregate?:ValueTypes["voyce_polls_votes_aggregate_bool_exp"],
	popularity?:ValueTypes["Int_comparison_exp"],
	popularity_weekly?:ValueTypes["Int_comparison_exp"],
	reactions_count?:ValueTypes["Int_comparison_exp"],
	spoiler?:ValueTypes["Boolean_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	total_popularity?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	votes_count?:ValueTypes["Int_comparison_exp"]
};
	/** columns and relationships of "voyce.polls_comments" */
["voyce_polls_comments"]: AliasType<{
	animated_image?:true,
	created_at?:true,
	id?:true,
	image?:true,
	linked_id?:true,
	parent_id?:true,
	/** An object relationship */
	poll?:ValueTypes["voyce_polls"],
	/** An object relationship */
	polls_comment?:ValueTypes["voyce_polls_comments"],
polls_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_comments_bool_exp"]},ValueTypes["voyce_polls_comments"]],
polls_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_comments_bool_exp"]},ValueTypes["voyce_polls_comments_aggregate"]],
polls_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_reactions_bool_exp"]},ValueTypes["voyce_polls_reactions"]],
polls_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_reactions_bool_exp"]},ValueTypes["voyce_polls_reactions_aggregate"]],
	reaction_count?:true,
	replies_count?:true,
	text?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.polls_comments" */
["voyce_polls_comments_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_polls_comments_aggregate_fields"],
	nodes?:ValueTypes["voyce_polls_comments"],
		__typename?: true
}>;
	["voyce_polls_comments_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_polls_comments_aggregate_bool_exp_count"]
};
	["voyce_polls_comments_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_polls_comments_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_polls_comments_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.polls_comments" */
["voyce_polls_comments_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_polls_comments_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_polls_comments_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_polls_comments_max_fields"],
	min?:ValueTypes["voyce_polls_comments_min_fields"],
	stddev?:ValueTypes["voyce_polls_comments_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_polls_comments_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_polls_comments_stddev_samp_fields"],
	sum?:ValueTypes["voyce_polls_comments_sum_fields"],
	var_pop?:ValueTypes["voyce_polls_comments_var_pop_fields"],
	var_samp?:ValueTypes["voyce_polls_comments_var_samp_fields"],
	variance?:ValueTypes["voyce_polls_comments_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.polls_comments" */
["voyce_polls_comments_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_polls_comments_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_polls_comments_max_order_by"],
	min?:ValueTypes["voyce_polls_comments_min_order_by"],
	stddev?:ValueTypes["voyce_polls_comments_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_polls_comments_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_polls_comments_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_polls_comments_sum_order_by"],
	var_pop?:ValueTypes["voyce_polls_comments_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_polls_comments_var_samp_order_by"],
	variance?:ValueTypes["voyce_polls_comments_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.polls_comments" */
["voyce_polls_comments_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_polls_comments_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_comments_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_polls_comments_avg_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.polls_comments". All fields are combined with a logical 'AND'. */
["voyce_polls_comments_bool_exp"]: {
	_and?:ValueTypes["voyce_polls_comments_bool_exp"][],
	_not?:ValueTypes["voyce_polls_comments_bool_exp"],
	_or?:ValueTypes["voyce_polls_comments_bool_exp"][],
	animated_image?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	image?:ValueTypes["String_comparison_exp"],
	linked_id?:ValueTypes["Int_comparison_exp"],
	parent_id?:ValueTypes["Int_comparison_exp"],
	poll?:ValueTypes["voyce_polls_bool_exp"],
	polls_comment?:ValueTypes["voyce_polls_comments_bool_exp"],
	polls_comments?:ValueTypes["voyce_polls_comments_bool_exp"],
	polls_comments_aggregate?:ValueTypes["voyce_polls_comments_aggregate_bool_exp"],
	polls_reactions?:ValueTypes["voyce_polls_reactions_bool_exp"],
	polls_reactions_aggregate?:ValueTypes["voyce_polls_reactions_aggregate_bool_exp"],
	reaction_count?:ValueTypes["Int_comparison_exp"],
	replies_count?:ValueTypes["Int_comparison_exp"],
	text?:ValueTypes["String_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.polls_comments" */
["voyce_polls_comments_constraint"]:voyce_polls_comments_constraint;
	/** input type for incrementing numeric columns in table "voyce.polls_comments" */
["voyce_polls_comments_inc_input"]: {
	id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.polls_comments" */
["voyce_polls_comments_insert_input"]: {
	animated_image?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	image?:string,
	linked_id?:number,
	parent_id?:number,
	poll?:ValueTypes["voyce_polls_obj_rel_insert_input"],
	polls_comment?:ValueTypes["voyce_polls_comments_obj_rel_insert_input"],
	polls_comments?:ValueTypes["voyce_polls_comments_arr_rel_insert_input"],
	polls_reactions?:ValueTypes["voyce_polls_reactions_arr_rel_insert_input"],
	reaction_count?:number,
	replies_count?:number,
	text?:string,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_polls_comments_max_fields"]: AliasType<{
	animated_image?:true,
	created_at?:true,
	id?:true,
	image?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	text?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_max_order_by"]: {
	animated_image?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	text?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_polls_comments_min_fields"]: AliasType<{
	animated_image?:true,
	created_at?:true,
	id?:true,
	image?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	text?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_min_order_by"]: {
	animated_image?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	text?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.polls_comments" */
["voyce_polls_comments_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_polls_comments"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.polls_comments" */
["voyce_polls_comments_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_polls_comments_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_comments_on_conflict"]
};
	/** on_conflict condition type for table "voyce.polls_comments" */
["voyce_polls_comments_on_conflict"]: {
	constraint:ValueTypes["voyce_polls_comments_constraint"],
	update_columns:ValueTypes["voyce_polls_comments_update_column"][],
	where?:ValueTypes["voyce_polls_comments_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.polls_comments". */
["voyce_polls_comments_order_by"]: {
	animated_image?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	poll?:ValueTypes["voyce_polls_order_by"],
	polls_comment?:ValueTypes["voyce_polls_comments_order_by"],
	polls_comments_aggregate?:ValueTypes["voyce_polls_comments_aggregate_order_by"],
	polls_reactions_aggregate?:ValueTypes["voyce_polls_reactions_aggregate_order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	text?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.polls_comments */
["voyce_polls_comments_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.polls_comments" */
["voyce_polls_comments_select_column"]:voyce_polls_comments_select_column;
	/** input type for updating data in table "voyce.polls_comments" */
["voyce_polls_comments_set_input"]: {
	animated_image?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	image?:string,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	text?:string,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_polls_comments_stddev_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_polls_comments_stddev_pop_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_polls_comments_stddev_samp_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_polls_comments" */
["voyce_polls_comments_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_polls_comments_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_polls_comments_stream_cursor_value_input"]: {
	animated_image?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	image?:string,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	text?:string,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_polls_comments_sum_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.polls_comments" */
["voyce_polls_comments_update_column"]:voyce_polls_comments_update_column;
	["voyce_polls_comments_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_polls_comments_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_polls_comments_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_polls_comments_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_polls_comments_var_pop_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_polls_comments_var_samp_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_polls_comments_variance_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** unique or primary key constraints on table "voyce.polls" */
["voyce_polls_constraint"]:voyce_polls_constraint;
	/** input type for incrementing numeric columns in table "voyce.polls" */
["voyce_polls_inc_input"]: {
	comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number,
	votes_count?:number
};
	/** input type for inserting data into table "voyce.polls" */
["voyce_polls_insert_input"]: {
	comments_count?:number,
	created_at?:ValueTypes["timestamp"],
	end_date?:ValueTypes["timestamp"],
	id?:number,
	last_activity_date?:ValueTypes["timestamp"],
	likes_count?:number,
	outdated?:boolean,
	polls_answers?:ValueTypes["voyce_polls_answers_arr_rel_insert_input"],
	polls_comments?:ValueTypes["voyce_polls_comments_arr_rel_insert_input"],
	polls_reactions?:ValueTypes["voyce_polls_reactions_arr_rel_insert_input"],
	polls_votes?:ValueTypes["voyce_polls_votes_arr_rel_insert_input"],
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	spoiler?:boolean,
	title?:string,
	total_popularity?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number,
	votes_count?:number
};
	/** aggregate max on columns */
["voyce_polls_max_fields"]: AliasType<{
	comments_count?:true,
	created_at?:true,
	end_date?:true,
	id?:true,
	last_activity_date?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	title?:true,
	total_popularity?:true,
	user_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.polls" */
["voyce_polls_max_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	end_date?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_activity_date?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_polls_min_fields"]: AliasType<{
	comments_count?:true,
	created_at?:true,
	end_date?:true,
	id?:true,
	last_activity_date?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	title?:true,
	total_popularity?:true,
	user_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.polls" */
["voyce_polls_min_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	end_date?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_activity_date?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.polls" */
["voyce_polls_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_polls"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.polls" */
["voyce_polls_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_polls_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_on_conflict"]
};
	/** on_conflict condition type for table "voyce.polls" */
["voyce_polls_on_conflict"]: {
	constraint:ValueTypes["voyce_polls_constraint"],
	update_columns:ValueTypes["voyce_polls_update_column"][],
	where?:ValueTypes["voyce_polls_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.polls". */
["voyce_polls_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	end_date?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_activity_date?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	outdated?:ValueTypes["order_by"],
	polls_answers_aggregate?:ValueTypes["voyce_polls_answers_aggregate_order_by"],
	polls_comments_aggregate?:ValueTypes["voyce_polls_comments_aggregate_order_by"],
	polls_reactions_aggregate?:ValueTypes["voyce_polls_reactions_aggregate_order_by"],
	polls_votes_aggregate?:ValueTypes["voyce_polls_votes_aggregate_order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	spoiler?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.polls */
["voyce_polls_pk_columns_input"]: {
	id:number
};
	/** columns and relationships of "voyce.polls_reactions" */
["voyce_polls_reactions"]: AliasType<{
	comment_id?:true,
	created_at?:true,
	id?:true,
	linked_entity_id?:true,
	/** An object relationship */
	poll?:ValueTypes["voyce_polls"],
	/** An object relationship */
	polls_comment?:ValueTypes["voyce_polls_comments"],
	reaction?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.polls_reactions" */
["voyce_polls_reactions_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_polls_reactions_aggregate_fields"],
	nodes?:ValueTypes["voyce_polls_reactions"],
		__typename?: true
}>;
	["voyce_polls_reactions_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_polls_reactions_aggregate_bool_exp_count"]
};
	["voyce_polls_reactions_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_polls_reactions_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_polls_reactions_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.polls_reactions" */
["voyce_polls_reactions_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_polls_reactions_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_polls_reactions_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_polls_reactions_max_fields"],
	min?:ValueTypes["voyce_polls_reactions_min_fields"],
	stddev?:ValueTypes["voyce_polls_reactions_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_polls_reactions_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_polls_reactions_stddev_samp_fields"],
	sum?:ValueTypes["voyce_polls_reactions_sum_fields"],
	var_pop?:ValueTypes["voyce_polls_reactions_var_pop_fields"],
	var_samp?:ValueTypes["voyce_polls_reactions_var_samp_fields"],
	variance?:ValueTypes["voyce_polls_reactions_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.polls_reactions" */
["voyce_polls_reactions_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_polls_reactions_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_polls_reactions_max_order_by"],
	min?:ValueTypes["voyce_polls_reactions_min_order_by"],
	stddev?:ValueTypes["voyce_polls_reactions_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_polls_reactions_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_polls_reactions_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_polls_reactions_sum_order_by"],
	var_pop?:ValueTypes["voyce_polls_reactions_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_polls_reactions_var_samp_order_by"],
	variance?:ValueTypes["voyce_polls_reactions_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.polls_reactions" */
["voyce_polls_reactions_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_polls_reactions_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_reactions_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_polls_reactions_avg_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_avg_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.polls_reactions". All fields are combined with a logical 'AND'. */
["voyce_polls_reactions_bool_exp"]: {
	_and?:ValueTypes["voyce_polls_reactions_bool_exp"][],
	_not?:ValueTypes["voyce_polls_reactions_bool_exp"],
	_or?:ValueTypes["voyce_polls_reactions_bool_exp"][],
	comment_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	linked_entity_id?:ValueTypes["Int_comparison_exp"],
	poll?:ValueTypes["voyce_polls_bool_exp"],
	polls_comment?:ValueTypes["voyce_polls_comments_bool_exp"],
	reaction?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.polls_reactions" */
["voyce_polls_reactions_constraint"]:voyce_polls_reactions_constraint;
	/** input type for incrementing numeric columns in table "voyce.polls_reactions" */
["voyce_polls_reactions_inc_input"]: {
	comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.polls_reactions" */
["voyce_polls_reactions_insert_input"]: {
	comment_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	poll?:ValueTypes["voyce_polls_obj_rel_insert_input"],
	polls_comment?:ValueTypes["voyce_polls_comments_obj_rel_insert_input"],
	reaction?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_polls_reactions_max_fields"]: AliasType<{
	comment_id?:true,
	created_at?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_max_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_polls_reactions_min_fields"]: AliasType<{
	comment_id?:true,
	created_at?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_min_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.polls_reactions" */
["voyce_polls_reactions_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_polls_reactions"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.polls_reactions" */
["voyce_polls_reactions_on_conflict"]: {
	constraint:ValueTypes["voyce_polls_reactions_constraint"],
	update_columns:ValueTypes["voyce_polls_reactions_update_column"][],
	where?:ValueTypes["voyce_polls_reactions_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.polls_reactions". */
["voyce_polls_reactions_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	poll?:ValueTypes["voyce_polls_order_by"],
	polls_comment?:ValueTypes["voyce_polls_comments_order_by"],
	reaction?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.polls_reactions */
["voyce_polls_reactions_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_select_column"]:voyce_polls_reactions_select_column;
	/** input type for updating data in table "voyce.polls_reactions" */
["voyce_polls_reactions_set_input"]: {
	comment_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_polls_reactions_stddev_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_stddev_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_polls_reactions_stddev_pop_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_stddev_pop_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_polls_reactions_stddev_samp_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_stddev_samp_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_polls_reactions" */
["voyce_polls_reactions_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_polls_reactions_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_polls_reactions_stream_cursor_value_input"]: {
	comment_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_polls_reactions_sum_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_sum_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_update_column"]:voyce_polls_reactions_update_column;
	["voyce_polls_reactions_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_polls_reactions_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_polls_reactions_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_polls_reactions_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_polls_reactions_var_pop_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_var_pop_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_polls_reactions_var_samp_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_var_samp_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_polls_reactions_variance_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_variance_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.polls" */
["voyce_polls_select_column"]:voyce_polls_select_column;
	/** select "voyce_polls_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.polls" */
["voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_and_arguments_columns"]:voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_polls_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.polls" */
["voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_or_arguments_columns"]:voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.polls" */
["voyce_polls_set_input"]: {
	comments_count?:number,
	created_at?:ValueTypes["timestamp"],
	end_date?:ValueTypes["timestamp"],
	id?:number,
	last_activity_date?:ValueTypes["timestamp"],
	likes_count?:number,
	outdated?:boolean,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	spoiler?:boolean,
	title?:string,
	total_popularity?:number,
	user_id?:number,
	votes_count?:number
};
	/** aggregate stddev on columns */
["voyce_polls_stddev_fields"]: AliasType<{
	comments_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	total_popularity?:true,
	user_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.polls" */
["voyce_polls_stddev_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_polls_stddev_pop_fields"]: AliasType<{
	comments_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	total_popularity?:true,
	user_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.polls" */
["voyce_polls_stddev_pop_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_polls_stddev_samp_fields"]: AliasType<{
	comments_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	total_popularity?:true,
	user_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.polls" */
["voyce_polls_stddev_samp_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_polls" */
["voyce_polls_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_polls_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_polls_stream_cursor_value_input"]: {
	comments_count?:number,
	created_at?:ValueTypes["timestamp"],
	end_date?:ValueTypes["timestamp"],
	id?:number,
	last_activity_date?:ValueTypes["timestamp"],
	likes_count?:number,
	outdated?:boolean,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	spoiler?:boolean,
	title?:string,
	total_popularity?:number,
	user_id?:number,
	votes_count?:number
};
	/** aggregate sum on columns */
["voyce_polls_sum_fields"]: AliasType<{
	comments_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	total_popularity?:true,
	user_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.polls" */
["voyce_polls_sum_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.polls" */
["voyce_polls_update_column"]:voyce_polls_update_column;
	["voyce_polls_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_polls_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_polls_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_polls_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_polls_var_pop_fields"]: AliasType<{
	comments_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	total_popularity?:true,
	user_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.polls" */
["voyce_polls_var_pop_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_polls_var_samp_fields"]: AliasType<{
	comments_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	total_popularity?:true,
	user_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.polls" */
["voyce_polls_var_samp_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_polls_variance_fields"]: AliasType<{
	comments_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	total_popularity?:true,
	user_id?:true,
	votes_count?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.polls" */
["voyce_polls_variance_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	votes_count?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.polls_votes" */
["voyce_polls_votes"]: AliasType<{
	answer_id?:true,
	created_at?:true,
	id?:true,
	/** An object relationship */
	poll?:ValueTypes["voyce_polls"],
	poll_id?:true,
	/** An object relationship */
	polls_answer?:ValueTypes["voyce_polls_answers"],
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.polls_votes" */
["voyce_polls_votes_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_polls_votes_aggregate_fields"],
	nodes?:ValueTypes["voyce_polls_votes"],
		__typename?: true
}>;
	["voyce_polls_votes_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_polls_votes_aggregate_bool_exp_count"]
};
	["voyce_polls_votes_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_polls_votes_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_polls_votes_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.polls_votes" */
["voyce_polls_votes_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_polls_votes_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_polls_votes_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_polls_votes_max_fields"],
	min?:ValueTypes["voyce_polls_votes_min_fields"],
	stddev?:ValueTypes["voyce_polls_votes_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_polls_votes_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_polls_votes_stddev_samp_fields"],
	sum?:ValueTypes["voyce_polls_votes_sum_fields"],
	var_pop?:ValueTypes["voyce_polls_votes_var_pop_fields"],
	var_samp?:ValueTypes["voyce_polls_votes_var_samp_fields"],
	variance?:ValueTypes["voyce_polls_votes_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.polls_votes" */
["voyce_polls_votes_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_polls_votes_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_polls_votes_max_order_by"],
	min?:ValueTypes["voyce_polls_votes_min_order_by"],
	stddev?:ValueTypes["voyce_polls_votes_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_polls_votes_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_polls_votes_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_polls_votes_sum_order_by"],
	var_pop?:ValueTypes["voyce_polls_votes_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_polls_votes_var_samp_order_by"],
	variance?:ValueTypes["voyce_polls_votes_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.polls_votes" */
["voyce_polls_votes_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_polls_votes_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_polls_votes_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_polls_votes_avg_fields"]: AliasType<{
	answer_id?:true,
	id?:true,
	poll_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_avg_order_by"]: {
	answer_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.polls_votes". All fields are combined with a logical 'AND'. */
["voyce_polls_votes_bool_exp"]: {
	_and?:ValueTypes["voyce_polls_votes_bool_exp"][],
	_not?:ValueTypes["voyce_polls_votes_bool_exp"],
	_or?:ValueTypes["voyce_polls_votes_bool_exp"][],
	answer_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	poll?:ValueTypes["voyce_polls_bool_exp"],
	poll_id?:ValueTypes["Int_comparison_exp"],
	polls_answer?:ValueTypes["voyce_polls_answers_bool_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.polls_votes" */
["voyce_polls_votes_constraint"]:voyce_polls_votes_constraint;
	/** input type for incrementing numeric columns in table "voyce.polls_votes" */
["voyce_polls_votes_inc_input"]: {
	answer_id?:number,
	id?:number,
	poll_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.polls_votes" */
["voyce_polls_votes_insert_input"]: {
	answer_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	poll?:ValueTypes["voyce_polls_obj_rel_insert_input"],
	poll_id?:number,
	polls_answer?:ValueTypes["voyce_polls_answers_obj_rel_insert_input"],
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_polls_votes_max_fields"]: AliasType<{
	answer_id?:true,
	created_at?:true,
	id?:true,
	poll_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_max_order_by"]: {
	answer_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_polls_votes_min_fields"]: AliasType<{
	answer_id?:true,
	created_at?:true,
	id?:true,
	poll_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_min_order_by"]: {
	answer_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.polls_votes" */
["voyce_polls_votes_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_polls_votes"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.polls_votes" */
["voyce_polls_votes_on_conflict"]: {
	constraint:ValueTypes["voyce_polls_votes_constraint"],
	update_columns:ValueTypes["voyce_polls_votes_update_column"][],
	where?:ValueTypes["voyce_polls_votes_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.polls_votes". */
["voyce_polls_votes_order_by"]: {
	answer_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	poll?:ValueTypes["voyce_polls_order_by"],
	poll_id?:ValueTypes["order_by"],
	polls_answer?:ValueTypes["voyce_polls_answers_order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.polls_votes */
["voyce_polls_votes_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.polls_votes" */
["voyce_polls_votes_select_column"]:voyce_polls_votes_select_column;
	/** input type for updating data in table "voyce.polls_votes" */
["voyce_polls_votes_set_input"]: {
	answer_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	poll_id?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_polls_votes_stddev_fields"]: AliasType<{
	answer_id?:true,
	id?:true,
	poll_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_stddev_order_by"]: {
	answer_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_polls_votes_stddev_pop_fields"]: AliasType<{
	answer_id?:true,
	id?:true,
	poll_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_stddev_pop_order_by"]: {
	answer_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_polls_votes_stddev_samp_fields"]: AliasType<{
	answer_id?:true,
	id?:true,
	poll_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_stddev_samp_order_by"]: {
	answer_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_polls_votes" */
["voyce_polls_votes_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_polls_votes_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_polls_votes_stream_cursor_value_input"]: {
	answer_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	poll_id?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_polls_votes_sum_fields"]: AliasType<{
	answer_id?:true,
	id?:true,
	poll_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_sum_order_by"]: {
	answer_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.polls_votes" */
["voyce_polls_votes_update_column"]:voyce_polls_votes_update_column;
	["voyce_polls_votes_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_polls_votes_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_polls_votes_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_polls_votes_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_polls_votes_var_pop_fields"]: AliasType<{
	answer_id?:true,
	id?:true,
	poll_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_var_pop_order_by"]: {
	answer_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_polls_votes_var_samp_fields"]: AliasType<{
	answer_id?:true,
	id?:true,
	poll_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_var_samp_order_by"]: {
	answer_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_polls_votes_variance_fields"]: AliasType<{
	answer_id?:true,
	id?:true,
	poll_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_variance_order_by"]: {
	answer_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	poll_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.popup_components" */
["voyce_popup_components"]: AliasType<{
	auth?:true,
	component?:true,
	created_at?:true,
	email_form?:true,
	exclusive_series?:true,
	followers?:true,
	id?:true,
	is_active?:true,
	klaviyo_list_id?:true,
	layout?:true,
	page?:true,
props?: [{	/** JSON select path */
	path?:string},true],
	series_visibility?:true,
	sort?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.popup_components" */
["voyce_popup_components_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_popup_components_aggregate_fields"],
	nodes?:ValueTypes["voyce_popup_components"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.popup_components" */
["voyce_popup_components_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_popup_components_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_popup_components_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_popup_components_max_fields"],
	min?:ValueTypes["voyce_popup_components_min_fields"],
	stddev?:ValueTypes["voyce_popup_components_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_popup_components_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_popup_components_stddev_samp_fields"],
	sum?:ValueTypes["voyce_popup_components_sum_fields"],
	var_pop?:ValueTypes["voyce_popup_components_var_pop_fields"],
	var_samp?:ValueTypes["voyce_popup_components_var_samp_fields"],
	variance?:ValueTypes["voyce_popup_components_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_popup_components_avg_fields"]: AliasType<{
	id?:true,
	layout?:true,
	sort?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.popup_components". All fields are combined with a logical 'AND'. */
["voyce_popup_components_bool_exp"]: {
	_and?:ValueTypes["voyce_popup_components_bool_exp"][],
	_not?:ValueTypes["voyce_popup_components_bool_exp"],
	_or?:ValueTypes["voyce_popup_components_bool_exp"][],
	auth?:ValueTypes["Boolean_comparison_exp"],
	component?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	email_form?:ValueTypes["Boolean_comparison_exp"],
	exclusive_series?:ValueTypes["Boolean_comparison_exp"],
	followers?:ValueTypes["Boolean_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_active?:ValueTypes["Boolean_comparison_exp"],
	klaviyo_list_id?:ValueTypes["String_comparison_exp"],
	layout?:ValueTypes["Int_comparison_exp"],
	page?:ValueTypes["String_comparison_exp"],
	props?:ValueTypes["json_comparison_exp"],
	series_visibility?:ValueTypes["Boolean_comparison_exp"],
	sort?:ValueTypes["smallint_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.popup_components" */
["voyce_popup_components_constraint"]:voyce_popup_components_constraint;
	/** input type for incrementing numeric columns in table "voyce.popup_components" */
["voyce_popup_components_inc_input"]: {
	id?:number,
	layout?:number,
	sort?:ValueTypes["smallint"]
};
	/** input type for inserting data into table "voyce.popup_components" */
["voyce_popup_components_insert_input"]: {
	auth?:boolean,
	component?:string,
	created_at?:ValueTypes["timestamptz"],
	email_form?:boolean,
	exclusive_series?:boolean,
	followers?:boolean,
	id?:number,
	is_active?:boolean,
	klaviyo_list_id?:string,
	layout?:number,
	page?:string,
	props?:ValueTypes["json"],
	series_visibility?:boolean,
	sort?:ValueTypes["smallint"],
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_popup_components_max_fields"]: AliasType<{
	component?:true,
	created_at?:true,
	id?:true,
	klaviyo_list_id?:true,
	layout?:true,
	page?:true,
	sort?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_popup_components_min_fields"]: AliasType<{
	component?:true,
	created_at?:true,
	id?:true,
	klaviyo_list_id?:true,
	layout?:true,
	page?:true,
	sort?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.popup_components" */
["voyce_popup_components_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_popup_components"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.popup_components" */
["voyce_popup_components_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_popup_components_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_popup_components_on_conflict"]
};
	/** on_conflict condition type for table "voyce.popup_components" */
["voyce_popup_components_on_conflict"]: {
	constraint:ValueTypes["voyce_popup_components_constraint"],
	update_columns:ValueTypes["voyce_popup_components_update_column"][],
	where?:ValueTypes["voyce_popup_components_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.popup_components". */
["voyce_popup_components_order_by"]: {
	auth?:ValueTypes["order_by"],
	component?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	email_form?:ValueTypes["order_by"],
	exclusive_series?:ValueTypes["order_by"],
	followers?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_active?:ValueTypes["order_by"],
	klaviyo_list_id?:ValueTypes["order_by"],
	layout?:ValueTypes["order_by"],
	page?:ValueTypes["order_by"],
	props?:ValueTypes["order_by"],
	series_visibility?:ValueTypes["order_by"],
	sort?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.popup_components */
["voyce_popup_components_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.popup_components" */
["voyce_popup_components_select_column"]:voyce_popup_components_select_column;
	/** input type for updating data in table "voyce.popup_components" */
["voyce_popup_components_set_input"]: {
	auth?:boolean,
	component?:string,
	created_at?:ValueTypes["timestamptz"],
	email_form?:boolean,
	exclusive_series?:boolean,
	followers?:boolean,
	id?:number,
	is_active?:boolean,
	klaviyo_list_id?:string,
	layout?:number,
	page?:string,
	props?:ValueTypes["json"],
	series_visibility?:boolean,
	sort?:ValueTypes["smallint"],
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_popup_components_stddev_fields"]: AliasType<{
	id?:true,
	layout?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_popup_components_stddev_pop_fields"]: AliasType<{
	id?:true,
	layout?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_popup_components_stddev_samp_fields"]: AliasType<{
	id?:true,
	layout?:true,
	sort?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_popup_components" */
["voyce_popup_components_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_popup_components_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_popup_components_stream_cursor_value_input"]: {
	auth?:boolean,
	component?:string,
	created_at?:ValueTypes["timestamptz"],
	email_form?:boolean,
	exclusive_series?:boolean,
	followers?:boolean,
	id?:number,
	is_active?:boolean,
	klaviyo_list_id?:string,
	layout?:number,
	page?:string,
	props?:ValueTypes["json"],
	series_visibility?:boolean,
	sort?:ValueTypes["smallint"],
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_popup_components_sum_fields"]: AliasType<{
	id?:true,
	layout?:true,
	sort?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.popup_components" */
["voyce_popup_components_update_column"]:voyce_popup_components_update_column;
	["voyce_popup_components_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_popup_components_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_popup_components_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_popup_components_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_popup_components_var_pop_fields"]: AliasType<{
	id?:true,
	layout?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_popup_components_var_samp_fields"]: AliasType<{
	id?:true,
	layout?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_popup_components_variance_fields"]: AliasType<{
	id?:true,
	layout?:true,
	sort?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.popup_emails" */
["voyce_popup_emails"]: AliasType<{
	/** An object relationship */
	component?:ValueTypes["voyce_popup_components"],
	created_at?:true,
	email?:true,
	gtag?:true,
	/** An object relationship */
	guest_gtag?:ValueTypes["voyce_guest_gtags"],
	id?:true,
	klaviyo_id?:true,
	page?:true,
	popup_id?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.popup_emails" */
["voyce_popup_emails_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_popup_emails_aggregate_fields"],
	nodes?:ValueTypes["voyce_popup_emails"],
		__typename?: true
}>;
	["voyce_popup_emails_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_popup_emails_aggregate_bool_exp_count"]
};
	["voyce_popup_emails_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_popup_emails_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_popup_emails_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.popup_emails" */
["voyce_popup_emails_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_popup_emails_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_popup_emails_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_popup_emails_max_fields"],
	min?:ValueTypes["voyce_popup_emails_min_fields"],
	stddev?:ValueTypes["voyce_popup_emails_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_popup_emails_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_popup_emails_stddev_samp_fields"],
	sum?:ValueTypes["voyce_popup_emails_sum_fields"],
	var_pop?:ValueTypes["voyce_popup_emails_var_pop_fields"],
	var_samp?:ValueTypes["voyce_popup_emails_var_samp_fields"],
	variance?:ValueTypes["voyce_popup_emails_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.popup_emails" */
["voyce_popup_emails_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_popup_emails_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_popup_emails_max_order_by"],
	min?:ValueTypes["voyce_popup_emails_min_order_by"],
	stddev?:ValueTypes["voyce_popup_emails_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_popup_emails_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_popup_emails_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_popup_emails_sum_order_by"],
	var_pop?:ValueTypes["voyce_popup_emails_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_popup_emails_var_samp_order_by"],
	variance?:ValueTypes["voyce_popup_emails_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.popup_emails" */
["voyce_popup_emails_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_popup_emails_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_popup_emails_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_popup_emails_avg_fields"]: AliasType<{
	id?:true,
	popup_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	popup_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.popup_emails". All fields are combined with a logical 'AND'. */
["voyce_popup_emails_bool_exp"]: {
	_and?:ValueTypes["voyce_popup_emails_bool_exp"][],
	_not?:ValueTypes["voyce_popup_emails_bool_exp"],
	_or?:ValueTypes["voyce_popup_emails_bool_exp"][],
	component?:ValueTypes["voyce_popup_components_bool_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	email?:ValueTypes["String_comparison_exp"],
	gtag?:ValueTypes["String_comparison_exp"],
	guest_gtag?:ValueTypes["voyce_guest_gtags_bool_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	klaviyo_id?:ValueTypes["String_comparison_exp"],
	page?:ValueTypes["String_comparison_exp"],
	popup_id?:ValueTypes["Int_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.popup_emails" */
["voyce_popup_emails_constraint"]:voyce_popup_emails_constraint;
	/** input type for incrementing numeric columns in table "voyce.popup_emails" */
["voyce_popup_emails_inc_input"]: {
	id?:number,
	popup_id?:number,
	series_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.popup_emails" */
["voyce_popup_emails_insert_input"]: {
	component?:ValueTypes["voyce_popup_components_obj_rel_insert_input"],
	created_at?:ValueTypes["timestamptz"],
	email?:string,
	gtag?:string,
	guest_gtag?:ValueTypes["voyce_guest_gtags_obj_rel_insert_input"],
	id?:number,
	klaviyo_id?:string,
	page?:string,
	popup_id?:number,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_popup_emails_max_fields"]: AliasType<{
	created_at?:true,
	email?:true,
	gtag?:true,
	id?:true,
	klaviyo_id?:true,
	page?:true,
	popup_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_max_order_by"]: {
	created_at?:ValueTypes["order_by"],
	email?:ValueTypes["order_by"],
	gtag?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	klaviyo_id?:ValueTypes["order_by"],
	page?:ValueTypes["order_by"],
	popup_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_popup_emails_min_fields"]: AliasType<{
	created_at?:true,
	email?:true,
	gtag?:true,
	id?:true,
	klaviyo_id?:true,
	page?:true,
	popup_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_min_order_by"]: {
	created_at?:ValueTypes["order_by"],
	email?:ValueTypes["order_by"],
	gtag?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	klaviyo_id?:ValueTypes["order_by"],
	page?:ValueTypes["order_by"],
	popup_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.popup_emails" */
["voyce_popup_emails_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_popup_emails"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.popup_emails" */
["voyce_popup_emails_on_conflict"]: {
	constraint:ValueTypes["voyce_popup_emails_constraint"],
	update_columns:ValueTypes["voyce_popup_emails_update_column"][],
	where?:ValueTypes["voyce_popup_emails_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.popup_emails". */
["voyce_popup_emails_order_by"]: {
	component?:ValueTypes["voyce_popup_components_order_by"],
	created_at?:ValueTypes["order_by"],
	email?:ValueTypes["order_by"],
	gtag?:ValueTypes["order_by"],
	guest_gtag?:ValueTypes["voyce_guest_gtags_order_by"],
	id?:ValueTypes["order_by"],
	klaviyo_id?:ValueTypes["order_by"],
	page?:ValueTypes["order_by"],
	popup_id?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.popup_emails */
["voyce_popup_emails_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.popup_emails" */
["voyce_popup_emails_select_column"]:voyce_popup_emails_select_column;
	/** input type for updating data in table "voyce.popup_emails" */
["voyce_popup_emails_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	email?:string,
	gtag?:string,
	id?:number,
	klaviyo_id?:string,
	page?:string,
	popup_id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_popup_emails_stddev_fields"]: AliasType<{
	id?:true,
	popup_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	popup_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_popup_emails_stddev_pop_fields"]: AliasType<{
	id?:true,
	popup_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	popup_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_popup_emails_stddev_samp_fields"]: AliasType<{
	id?:true,
	popup_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	popup_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_popup_emails" */
["voyce_popup_emails_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_popup_emails_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_popup_emails_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	email?:string,
	gtag?:string,
	id?:number,
	klaviyo_id?:string,
	page?:string,
	popup_id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_popup_emails_sum_fields"]: AliasType<{
	id?:true,
	popup_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	popup_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.popup_emails" */
["voyce_popup_emails_update_column"]:voyce_popup_emails_update_column;
	["voyce_popup_emails_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_popup_emails_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_popup_emails_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_popup_emails_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_popup_emails_var_pop_fields"]: AliasType<{
	id?:true,
	popup_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	popup_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_popup_emails_var_samp_fields"]: AliasType<{
	id?:true,
	popup_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	popup_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_popup_emails_variance_fields"]: AliasType<{
	id?:true,
	popup_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	popup_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.posts" */
["voyce_posts"]: AliasType<{
	comments_count?:true,
	created_at?:true,
	giphy_url?:true,
	id?:true,
	image_url?:true,
	last_activity_date?:true,
	likes_count?:true,
	outdated?:true,
	popularity?:true,
	popularity_weekly?:true,
posts_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_comments_bool_exp"]},ValueTypes["voyce_posts_comments"]],
posts_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_comments_bool_exp"]},ValueTypes["voyce_posts_comments_aggregate"]],
posts_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_reactions_bool_exp"]},ValueTypes["voyce_posts_reactions"]],
posts_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_reactions_bool_exp"]},ValueTypes["voyce_posts_reactions_aggregate"]],
	reactions_count?:true,
	spoiler?:true,
	text?:true,
	total_popularity?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
	video_url?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.posts" */
["voyce_posts_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_posts_aggregate_fields"],
	nodes?:ValueTypes["voyce_posts"],
		__typename?: true
}>;
	["voyce_posts_aggregate_bool_exp"]: {
	bool_and?:ValueTypes["voyce_posts_aggregate_bool_exp_bool_and"],
	bool_or?:ValueTypes["voyce_posts_aggregate_bool_exp_bool_or"],
	count?:ValueTypes["voyce_posts_aggregate_bool_exp_count"]
};
	["voyce_posts_aggregate_bool_exp_bool_and"]: {
	arguments:ValueTypes["voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_posts_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_posts_aggregate_bool_exp_bool_or"]: {
	arguments:ValueTypes["voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_posts_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_posts_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_posts_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_posts_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.posts" */
["voyce_posts_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_posts_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_posts_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_posts_max_fields"],
	min?:ValueTypes["voyce_posts_min_fields"],
	stddev?:ValueTypes["voyce_posts_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_posts_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_posts_stddev_samp_fields"],
	sum?:ValueTypes["voyce_posts_sum_fields"],
	var_pop?:ValueTypes["voyce_posts_var_pop_fields"],
	var_samp?:ValueTypes["voyce_posts_var_samp_fields"],
	variance?:ValueTypes["voyce_posts_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.posts" */
["voyce_posts_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_posts_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_posts_max_order_by"],
	min?:ValueTypes["voyce_posts_min_order_by"],
	stddev?:ValueTypes["voyce_posts_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_posts_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_posts_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_posts_sum_order_by"],
	var_pop?:ValueTypes["voyce_posts_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_posts_var_samp_order_by"],
	variance?:ValueTypes["voyce_posts_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.posts" */
["voyce_posts_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_posts_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_posts_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_posts_avg_fields"]: AliasType<{
	comments_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	total_popularity?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.posts" */
["voyce_posts_avg_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.posts". All fields are combined with a logical 'AND'. */
["voyce_posts_bool_exp"]: {
	_and?:ValueTypes["voyce_posts_bool_exp"][],
	_not?:ValueTypes["voyce_posts_bool_exp"],
	_or?:ValueTypes["voyce_posts_bool_exp"][],
	comments_count?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	giphy_url?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	image_url?:ValueTypes["String_comparison_exp"],
	last_activity_date?:ValueTypes["timestamp_comparison_exp"],
	likes_count?:ValueTypes["Int_comparison_exp"],
	outdated?:ValueTypes["Boolean_comparison_exp"],
	popularity?:ValueTypes["Int_comparison_exp"],
	popularity_weekly?:ValueTypes["Int_comparison_exp"],
	posts_comments?:ValueTypes["voyce_posts_comments_bool_exp"],
	posts_comments_aggregate?:ValueTypes["voyce_posts_comments_aggregate_bool_exp"],
	posts_reactions?:ValueTypes["voyce_posts_reactions_bool_exp"],
	posts_reactions_aggregate?:ValueTypes["voyce_posts_reactions_aggregate_bool_exp"],
	reactions_count?:ValueTypes["Int_comparison_exp"],
	spoiler?:ValueTypes["Boolean_comparison_exp"],
	text?:ValueTypes["String_comparison_exp"],
	total_popularity?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	video_url?:ValueTypes["String_comparison_exp"]
};
	/** columns and relationships of "voyce.posts_comments" */
["voyce_posts_comments"]: AliasType<{
	animated_image?:true,
	created_at?:true,
	id?:true,
	image?:true,
	last_reacted_at?:true,
	last_reaction_count?:true,
	linked_id?:true,
	parent_id?:true,
	/** An object relationship */
	post?:ValueTypes["voyce_posts"],
	/** An object relationship */
	posts_comment?:ValueTypes["voyce_posts_comments"],
posts_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_comments_bool_exp"]},ValueTypes["voyce_posts_comments"]],
posts_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_comments_bool_exp"]},ValueTypes["voyce_posts_comments_aggregate"]],
posts_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_reactions_bool_exp"]},ValueTypes["voyce_posts_reactions"]],
posts_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_reactions_bool_exp"]},ValueTypes["voyce_posts_reactions_aggregate"]],
	reaction_count?:true,
	replies_count?:true,
	text?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.posts_comments" */
["voyce_posts_comments_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_posts_comments_aggregate_fields"],
	nodes?:ValueTypes["voyce_posts_comments"],
		__typename?: true
}>;
	["voyce_posts_comments_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_posts_comments_aggregate_bool_exp_count"]
};
	["voyce_posts_comments_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_posts_comments_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_posts_comments_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.posts_comments" */
["voyce_posts_comments_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_posts_comments_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_posts_comments_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_posts_comments_max_fields"],
	min?:ValueTypes["voyce_posts_comments_min_fields"],
	stddev?:ValueTypes["voyce_posts_comments_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_posts_comments_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_posts_comments_stddev_samp_fields"],
	sum?:ValueTypes["voyce_posts_comments_sum_fields"],
	var_pop?:ValueTypes["voyce_posts_comments_var_pop_fields"],
	var_samp?:ValueTypes["voyce_posts_comments_var_samp_fields"],
	variance?:ValueTypes["voyce_posts_comments_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.posts_comments" */
["voyce_posts_comments_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_posts_comments_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_posts_comments_max_order_by"],
	min?:ValueTypes["voyce_posts_comments_min_order_by"],
	stddev?:ValueTypes["voyce_posts_comments_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_posts_comments_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_posts_comments_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_posts_comments_sum_order_by"],
	var_pop?:ValueTypes["voyce_posts_comments_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_posts_comments_var_samp_order_by"],
	variance?:ValueTypes["voyce_posts_comments_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.posts_comments" */
["voyce_posts_comments_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_posts_comments_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_posts_comments_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_posts_comments_avg_fields"]: AliasType<{
	id?:true,
	last_reaction_count?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.posts_comments". All fields are combined with a logical 'AND'. */
["voyce_posts_comments_bool_exp"]: {
	_and?:ValueTypes["voyce_posts_comments_bool_exp"][],
	_not?:ValueTypes["voyce_posts_comments_bool_exp"],
	_or?:ValueTypes["voyce_posts_comments_bool_exp"][],
	animated_image?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	image?:ValueTypes["String_comparison_exp"],
	last_reacted_at?:ValueTypes["timestamptz_comparison_exp"],
	last_reaction_count?:ValueTypes["Int_comparison_exp"],
	linked_id?:ValueTypes["Int_comparison_exp"],
	parent_id?:ValueTypes["Int_comparison_exp"],
	post?:ValueTypes["voyce_posts_bool_exp"],
	posts_comment?:ValueTypes["voyce_posts_comments_bool_exp"],
	posts_comments?:ValueTypes["voyce_posts_comments_bool_exp"],
	posts_comments_aggregate?:ValueTypes["voyce_posts_comments_aggregate_bool_exp"],
	posts_reactions?:ValueTypes["voyce_posts_reactions_bool_exp"],
	posts_reactions_aggregate?:ValueTypes["voyce_posts_reactions_aggregate_bool_exp"],
	reaction_count?:ValueTypes["Int_comparison_exp"],
	replies_count?:ValueTypes["Int_comparison_exp"],
	text?:ValueTypes["String_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.posts_comments" */
["voyce_posts_comments_constraint"]:voyce_posts_comments_constraint;
	/** input type for incrementing numeric columns in table "voyce.posts_comments" */
["voyce_posts_comments_inc_input"]: {
	id?:number,
	last_reaction_count?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.posts_comments" */
["voyce_posts_comments_insert_input"]: {
	animated_image?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	image?:string,
	last_reacted_at?:ValueTypes["timestamptz"],
	last_reaction_count?:number,
	linked_id?:number,
	parent_id?:number,
	post?:ValueTypes["voyce_posts_obj_rel_insert_input"],
	posts_comment?:ValueTypes["voyce_posts_comments_obj_rel_insert_input"],
	posts_comments?:ValueTypes["voyce_posts_comments_arr_rel_insert_input"],
	posts_reactions?:ValueTypes["voyce_posts_reactions_arr_rel_insert_input"],
	reaction_count?:number,
	replies_count?:number,
	text?:string,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_posts_comments_max_fields"]: AliasType<{
	animated_image?:true,
	created_at?:true,
	id?:true,
	image?:true,
	last_reacted_at?:true,
	last_reaction_count?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	text?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_max_order_by"]: {
	animated_image?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	last_reacted_at?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	text?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_posts_comments_min_fields"]: AliasType<{
	animated_image?:true,
	created_at?:true,
	id?:true,
	image?:true,
	last_reacted_at?:true,
	last_reaction_count?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	text?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_min_order_by"]: {
	animated_image?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	last_reacted_at?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	text?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.posts_comments" */
["voyce_posts_comments_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_posts_comments"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.posts_comments" */
["voyce_posts_comments_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_posts_comments_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_posts_comments_on_conflict"]
};
	/** on_conflict condition type for table "voyce.posts_comments" */
["voyce_posts_comments_on_conflict"]: {
	constraint:ValueTypes["voyce_posts_comments_constraint"],
	update_columns:ValueTypes["voyce_posts_comments_update_column"][],
	where?:ValueTypes["voyce_posts_comments_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.posts_comments". */
["voyce_posts_comments_order_by"]: {
	animated_image?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	last_reacted_at?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	post?:ValueTypes["voyce_posts_order_by"],
	posts_comment?:ValueTypes["voyce_posts_comments_order_by"],
	posts_comments_aggregate?:ValueTypes["voyce_posts_comments_aggregate_order_by"],
	posts_reactions_aggregate?:ValueTypes["voyce_posts_reactions_aggregate_order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	text?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.posts_comments */
["voyce_posts_comments_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.posts_comments" */
["voyce_posts_comments_select_column"]:voyce_posts_comments_select_column;
	/** input type for updating data in table "voyce.posts_comments" */
["voyce_posts_comments_set_input"]: {
	animated_image?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	image?:string,
	last_reacted_at?:ValueTypes["timestamptz"],
	last_reaction_count?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	text?:string,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_posts_comments_stddev_fields"]: AliasType<{
	id?:true,
	last_reaction_count?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_posts_comments_stddev_pop_fields"]: AliasType<{
	id?:true,
	last_reaction_count?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_posts_comments_stddev_samp_fields"]: AliasType<{
	id?:true,
	last_reaction_count?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_posts_comments" */
["voyce_posts_comments_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_posts_comments_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_posts_comments_stream_cursor_value_input"]: {
	animated_image?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	image?:string,
	last_reacted_at?:ValueTypes["timestamptz"],
	last_reaction_count?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	text?:string,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_posts_comments_sum_fields"]: AliasType<{
	id?:true,
	last_reaction_count?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.posts_comments" */
["voyce_posts_comments_update_column"]:voyce_posts_comments_update_column;
	["voyce_posts_comments_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_posts_comments_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_posts_comments_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_posts_comments_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_posts_comments_var_pop_fields"]: AliasType<{
	id?:true,
	last_reaction_count?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_posts_comments_var_samp_fields"]: AliasType<{
	id?:true,
	last_reaction_count?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_posts_comments_variance_fields"]: AliasType<{
	id?:true,
	last_reaction_count?:true,
	linked_id?:true,
	parent_id?:true,
	reaction_count?:true,
	replies_count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	last_reaction_count?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	parent_id?:ValueTypes["order_by"],
	reaction_count?:ValueTypes["order_by"],
	replies_count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** unique or primary key constraints on table "voyce.posts" */
["voyce_posts_constraint"]:voyce_posts_constraint;
	/** input type for incrementing numeric columns in table "voyce.posts" */
["voyce_posts_inc_input"]: {
	comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.posts" */
["voyce_posts_insert_input"]: {
	comments_count?:number,
	created_at?:ValueTypes["timestamp"],
	giphy_url?:string,
	id?:number,
	image_url?:string,
	last_activity_date?:ValueTypes["timestamp"],
	likes_count?:number,
	outdated?:boolean,
	popularity?:number,
	popularity_weekly?:number,
	posts_comments?:ValueTypes["voyce_posts_comments_arr_rel_insert_input"],
	posts_reactions?:ValueTypes["voyce_posts_reactions_arr_rel_insert_input"],
	reactions_count?:number,
	spoiler?:boolean,
	text?:string,
	total_popularity?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number,
	video_url?:string
};
	/** aggregate max on columns */
["voyce_posts_max_fields"]: AliasType<{
	comments_count?:true,
	created_at?:true,
	giphy_url?:true,
	id?:true,
	image_url?:true,
	last_activity_date?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	text?:true,
	total_popularity?:true,
	user_id?:true,
	video_url?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.posts" */
["voyce_posts_max_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	giphy_url?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image_url?:ValueTypes["order_by"],
	last_activity_date?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	text?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	video_url?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_posts_min_fields"]: AliasType<{
	comments_count?:true,
	created_at?:true,
	giphy_url?:true,
	id?:true,
	image_url?:true,
	last_activity_date?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	text?:true,
	total_popularity?:true,
	user_id?:true,
	video_url?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.posts" */
["voyce_posts_min_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	giphy_url?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image_url?:ValueTypes["order_by"],
	last_activity_date?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	text?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	video_url?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.posts" */
["voyce_posts_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_posts"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.posts" */
["voyce_posts_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_posts_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_posts_on_conflict"]
};
	/** on_conflict condition type for table "voyce.posts" */
["voyce_posts_on_conflict"]: {
	constraint:ValueTypes["voyce_posts_constraint"],
	update_columns:ValueTypes["voyce_posts_update_column"][],
	where?:ValueTypes["voyce_posts_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.posts". */
["voyce_posts_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	giphy_url?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image_url?:ValueTypes["order_by"],
	last_activity_date?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	outdated?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	posts_comments_aggregate?:ValueTypes["voyce_posts_comments_aggregate_order_by"],
	posts_reactions_aggregate?:ValueTypes["voyce_posts_reactions_aggregate_order_by"],
	reactions_count?:ValueTypes["order_by"],
	spoiler?:ValueTypes["order_by"],
	text?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"],
	video_url?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.posts */
["voyce_posts_pk_columns_input"]: {
	id:number
};
	/** columns and relationships of "voyce.posts_reactions" */
["voyce_posts_reactions"]: AliasType<{
	comment_id?:true,
	created_at?:true,
	id?:true,
	linked_entity_id?:true,
	/** An object relationship */
	post?:ValueTypes["voyce_posts"],
	/** An object relationship */
	posts_comment?:ValueTypes["voyce_posts_comments"],
	reaction?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.posts_reactions" */
["voyce_posts_reactions_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_posts_reactions_aggregate_fields"],
	nodes?:ValueTypes["voyce_posts_reactions"],
		__typename?: true
}>;
	["voyce_posts_reactions_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_posts_reactions_aggregate_bool_exp_count"]
};
	["voyce_posts_reactions_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_posts_reactions_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_posts_reactions_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.posts_reactions" */
["voyce_posts_reactions_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_posts_reactions_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_posts_reactions_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_posts_reactions_max_fields"],
	min?:ValueTypes["voyce_posts_reactions_min_fields"],
	stddev?:ValueTypes["voyce_posts_reactions_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_posts_reactions_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_posts_reactions_stddev_samp_fields"],
	sum?:ValueTypes["voyce_posts_reactions_sum_fields"],
	var_pop?:ValueTypes["voyce_posts_reactions_var_pop_fields"],
	var_samp?:ValueTypes["voyce_posts_reactions_var_samp_fields"],
	variance?:ValueTypes["voyce_posts_reactions_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.posts_reactions" */
["voyce_posts_reactions_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_posts_reactions_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_posts_reactions_max_order_by"],
	min?:ValueTypes["voyce_posts_reactions_min_order_by"],
	stddev?:ValueTypes["voyce_posts_reactions_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_posts_reactions_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_posts_reactions_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_posts_reactions_sum_order_by"],
	var_pop?:ValueTypes["voyce_posts_reactions_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_posts_reactions_var_samp_order_by"],
	variance?:ValueTypes["voyce_posts_reactions_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.posts_reactions" */
["voyce_posts_reactions_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_posts_reactions_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_posts_reactions_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_posts_reactions_avg_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_avg_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.posts_reactions". All fields are combined with a logical 'AND'. */
["voyce_posts_reactions_bool_exp"]: {
	_and?:ValueTypes["voyce_posts_reactions_bool_exp"][],
	_not?:ValueTypes["voyce_posts_reactions_bool_exp"],
	_or?:ValueTypes["voyce_posts_reactions_bool_exp"][],
	comment_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	linked_entity_id?:ValueTypes["Int_comparison_exp"],
	post?:ValueTypes["voyce_posts_bool_exp"],
	posts_comment?:ValueTypes["voyce_posts_comments_bool_exp"],
	reaction?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.posts_reactions" */
["voyce_posts_reactions_constraint"]:voyce_posts_reactions_constraint;
	/** input type for incrementing numeric columns in table "voyce.posts_reactions" */
["voyce_posts_reactions_inc_input"]: {
	comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.posts_reactions" */
["voyce_posts_reactions_insert_input"]: {
	comment_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	post?:ValueTypes["voyce_posts_obj_rel_insert_input"],
	posts_comment?:ValueTypes["voyce_posts_comments_obj_rel_insert_input"],
	reaction?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_posts_reactions_max_fields"]: AliasType<{
	comment_id?:true,
	created_at?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_max_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_posts_reactions_min_fields"]: AliasType<{
	comment_id?:true,
	created_at?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_min_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.posts_reactions" */
["voyce_posts_reactions_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_posts_reactions"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.posts_reactions" */
["voyce_posts_reactions_on_conflict"]: {
	constraint:ValueTypes["voyce_posts_reactions_constraint"],
	update_columns:ValueTypes["voyce_posts_reactions_update_column"][],
	where?:ValueTypes["voyce_posts_reactions_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.posts_reactions". */
["voyce_posts_reactions_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	post?:ValueTypes["voyce_posts_order_by"],
	posts_comment?:ValueTypes["voyce_posts_comments_order_by"],
	reaction?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.posts_reactions */
["voyce_posts_reactions_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_select_column"]:voyce_posts_reactions_select_column;
	/** input type for updating data in table "voyce.posts_reactions" */
["voyce_posts_reactions_set_input"]: {
	comment_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_posts_reactions_stddev_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_stddev_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_posts_reactions_stddev_pop_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_stddev_pop_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_posts_reactions_stddev_samp_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_stddev_samp_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_posts_reactions" */
["voyce_posts_reactions_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_posts_reactions_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_posts_reactions_stream_cursor_value_input"]: {
	comment_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_posts_reactions_sum_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_sum_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_update_column"]:voyce_posts_reactions_update_column;
	["voyce_posts_reactions_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_posts_reactions_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_posts_reactions_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_posts_reactions_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_posts_reactions_var_pop_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_var_pop_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_posts_reactions_var_samp_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_var_samp_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_posts_reactions_variance_fields"]: AliasType<{
	comment_id?:true,
	id?:true,
	linked_entity_id?:true,
	reaction?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_variance_order_by"]: {
	comment_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_entity_id?:ValueTypes["order_by"],
	reaction?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.posts" */
["voyce_posts_select_column"]:voyce_posts_select_column;
	/** select "voyce_posts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.posts" */
["voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_and_arguments_columns"]:voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_posts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.posts" */
["voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_or_arguments_columns"]:voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.posts" */
["voyce_posts_set_input"]: {
	comments_count?:number,
	created_at?:ValueTypes["timestamp"],
	giphy_url?:string,
	id?:number,
	image_url?:string,
	last_activity_date?:ValueTypes["timestamp"],
	likes_count?:number,
	outdated?:boolean,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	spoiler?:boolean,
	text?:string,
	total_popularity?:number,
	user_id?:number,
	video_url?:string
};
	/** aggregate stddev on columns */
["voyce_posts_stddev_fields"]: AliasType<{
	comments_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	total_popularity?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.posts" */
["voyce_posts_stddev_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_posts_stddev_pop_fields"]: AliasType<{
	comments_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	total_popularity?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.posts" */
["voyce_posts_stddev_pop_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_posts_stddev_samp_fields"]: AliasType<{
	comments_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	total_popularity?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.posts" */
["voyce_posts_stddev_samp_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_posts" */
["voyce_posts_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_posts_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_posts_stream_cursor_value_input"]: {
	comments_count?:number,
	created_at?:ValueTypes["timestamp"],
	giphy_url?:string,
	id?:number,
	image_url?:string,
	last_activity_date?:ValueTypes["timestamp"],
	likes_count?:number,
	outdated?:boolean,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	spoiler?:boolean,
	text?:string,
	total_popularity?:number,
	user_id?:number,
	video_url?:string
};
	/** aggregate sum on columns */
["voyce_posts_sum_fields"]: AliasType<{
	comments_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	total_popularity?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.posts" */
["voyce_posts_sum_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.posts" */
["voyce_posts_update_column"]:voyce_posts_update_column;
	["voyce_posts_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_posts_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_posts_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_posts_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_posts_var_pop_fields"]: AliasType<{
	comments_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	total_popularity?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.posts" */
["voyce_posts_var_pop_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_posts_var_samp_fields"]: AliasType<{
	comments_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	total_popularity?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.posts" */
["voyce_posts_var_samp_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_posts_variance_fields"]: AliasType<{
	comments_count?:true,
	id?:true,
	likes_count?:true,
	popularity?:true,
	popularity_weekly?:true,
	reactions_count?:true,
	total_popularity?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.posts" */
["voyce_posts_variance_order_by"]: {
	comments_count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	likes_count?:ValueTypes["order_by"],
	popularity?:ValueTypes["order_by"],
	popularity_weekly?:ValueTypes["order_by"],
	reactions_count?:ValueTypes["order_by"],
	total_popularity?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.quest_difficulties" */
["voyce_quest_difficulties"]: AliasType<{
	coins?:true,
	created_at?:true,
	experience?:true,
	id?:true,
	title?:true,
	updated_at?:true,
	vox?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.quest_difficulties" */
["voyce_quest_difficulties_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_quest_difficulties_aggregate_fields"],
	nodes?:ValueTypes["voyce_quest_difficulties"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.quest_difficulties" */
["voyce_quest_difficulties_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_quest_difficulties_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_quest_difficulties_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_quest_difficulties_max_fields"],
	min?:ValueTypes["voyce_quest_difficulties_min_fields"],
	stddev?:ValueTypes["voyce_quest_difficulties_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_quest_difficulties_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_quest_difficulties_stddev_samp_fields"],
	sum?:ValueTypes["voyce_quest_difficulties_sum_fields"],
	var_pop?:ValueTypes["voyce_quest_difficulties_var_pop_fields"],
	var_samp?:ValueTypes["voyce_quest_difficulties_var_samp_fields"],
	variance?:ValueTypes["voyce_quest_difficulties_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_quest_difficulties_avg_fields"]: AliasType<{
	coins?:true,
	experience?:true,
	id?:true,
	vox?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.quest_difficulties". All fields are combined with a logical 'AND'. */
["voyce_quest_difficulties_bool_exp"]: {
	_and?:ValueTypes["voyce_quest_difficulties_bool_exp"][],
	_not?:ValueTypes["voyce_quest_difficulties_bool_exp"],
	_or?:ValueTypes["voyce_quest_difficulties_bool_exp"][],
	coins?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	experience?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	vox?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.quest_difficulties" */
["voyce_quest_difficulties_constraint"]:voyce_quest_difficulties_constraint;
	/** input type for incrementing numeric columns in table "voyce.quest_difficulties" */
["voyce_quest_difficulties_inc_input"]: {
	coins?:number,
	experience?:number,
	id?:number,
	vox?:number
};
	/** input type for inserting data into table "voyce.quest_difficulties" */
["voyce_quest_difficulties_insert_input"]: {
	coins?:number,
	created_at?:ValueTypes["timestamptz"],
	experience?:number,
	id?:number,
	title?:string,
	updated_at?:ValueTypes["timestamptz"],
	vox?:number
};
	/** aggregate max on columns */
["voyce_quest_difficulties_max_fields"]: AliasType<{
	coins?:true,
	created_at?:true,
	experience?:true,
	id?:true,
	title?:true,
	updated_at?:true,
	vox?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_quest_difficulties_min_fields"]: AliasType<{
	coins?:true,
	created_at?:true,
	experience?:true,
	id?:true,
	title?:true,
	updated_at?:true,
	vox?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.quest_difficulties" */
["voyce_quest_difficulties_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_quest_difficulties"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.quest_difficulties" */
["voyce_quest_difficulties_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_quest_difficulties_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_quest_difficulties_on_conflict"]
};
	/** on_conflict condition type for table "voyce.quest_difficulties" */
["voyce_quest_difficulties_on_conflict"]: {
	constraint:ValueTypes["voyce_quest_difficulties_constraint"],
	update_columns:ValueTypes["voyce_quest_difficulties_update_column"][],
	where?:ValueTypes["voyce_quest_difficulties_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.quest_difficulties". */
["voyce_quest_difficulties_order_by"]: {
	coins?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	experience?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	vox?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.quest_difficulties */
["voyce_quest_difficulties_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.quest_difficulties" */
["voyce_quest_difficulties_select_column"]:voyce_quest_difficulties_select_column;
	/** input type for updating data in table "voyce.quest_difficulties" */
["voyce_quest_difficulties_set_input"]: {
	coins?:number,
	created_at?:ValueTypes["timestamptz"],
	experience?:number,
	id?:number,
	title?:string,
	updated_at?:ValueTypes["timestamptz"],
	vox?:number
};
	/** aggregate stddev on columns */
["voyce_quest_difficulties_stddev_fields"]: AliasType<{
	coins?:true,
	experience?:true,
	id?:true,
	vox?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_quest_difficulties_stddev_pop_fields"]: AliasType<{
	coins?:true,
	experience?:true,
	id?:true,
	vox?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_quest_difficulties_stddev_samp_fields"]: AliasType<{
	coins?:true,
	experience?:true,
	id?:true,
	vox?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_quest_difficulties" */
["voyce_quest_difficulties_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_quest_difficulties_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_quest_difficulties_stream_cursor_value_input"]: {
	coins?:number,
	created_at?:ValueTypes["timestamptz"],
	experience?:number,
	id?:number,
	title?:string,
	updated_at?:ValueTypes["timestamptz"],
	vox?:number
};
	/** aggregate sum on columns */
["voyce_quest_difficulties_sum_fields"]: AliasType<{
	coins?:true,
	experience?:true,
	id?:true,
	vox?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.quest_difficulties" */
["voyce_quest_difficulties_update_column"]:voyce_quest_difficulties_update_column;
	["voyce_quest_difficulties_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_quest_difficulties_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_quest_difficulties_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_quest_difficulties_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_quest_difficulties_var_pop_fields"]: AliasType<{
	coins?:true,
	experience?:true,
	id?:true,
	vox?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_quest_difficulties_var_samp_fields"]: AliasType<{
	coins?:true,
	experience?:true,
	id?:true,
	vox?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_quest_difficulties_variance_fields"]: AliasType<{
	coins?:true,
	experience?:true,
	id?:true,
	vox?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.quests" */
["voyce_quests"]: AliasType<{
	created_at?:true,
	description?:true,
	/** An object relationship */
	difficulty?:ValueTypes["voyce_quest_difficulties"],
	difficulty_id?:true,
event_data?: [{	/** JSON select path */
	path?:string},true],
	/** SubscribeSeries, DailyCheckIn, etc.. */
	event_trigger?:true,
	experience_points?:true,
	/** once, daily, weekly */
	frequency?:true,
	id?:true,
	/** Qualified, Started, Completed */
	initial_status?:true,
	notif_message?:true,
reward?: [{	/** JSON select path */
	path?:string},true],
start_data?: [{	/** JSON select path */
	path?:string},true],
	/** auto, manual */
	start_type?:true,
	tag?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.quests" */
["voyce_quests_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_quests_aggregate_fields"],
	nodes?:ValueTypes["voyce_quests"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.quests" */
["voyce_quests_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_quests_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_quests_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_quests_max_fields"],
	min?:ValueTypes["voyce_quests_min_fields"],
	stddev?:ValueTypes["voyce_quests_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_quests_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_quests_stddev_samp_fields"],
	sum?:ValueTypes["voyce_quests_sum_fields"],
	var_pop?:ValueTypes["voyce_quests_var_pop_fields"],
	var_samp?:ValueTypes["voyce_quests_var_samp_fields"],
	variance?:ValueTypes["voyce_quests_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_quests_avg_fields"]: AliasType<{
	difficulty_id?:true,
	experience_points?:true,
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.quests". All fields are combined with a logical 'AND'. */
["voyce_quests_bool_exp"]: {
	_and?:ValueTypes["voyce_quests_bool_exp"][],
	_not?:ValueTypes["voyce_quests_bool_exp"],
	_or?:ValueTypes["voyce_quests_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	description?:ValueTypes["String_comparison_exp"],
	difficulty?:ValueTypes["voyce_quest_difficulties_bool_exp"],
	difficulty_id?:ValueTypes["Int_comparison_exp"],
	event_data?:ValueTypes["json_comparison_exp"],
	event_trigger?:ValueTypes["String_comparison_exp"],
	experience_points?:ValueTypes["Int_comparison_exp"],
	frequency?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	initial_status?:ValueTypes["String_comparison_exp"],
	notif_message?:ValueTypes["String_comparison_exp"],
	reward?:ValueTypes["json_comparison_exp"],
	start_data?:ValueTypes["json_comparison_exp"],
	start_type?:ValueTypes["String_comparison_exp"],
	tag?:ValueTypes["String_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.quests" */
["voyce_quests_constraint"]:voyce_quests_constraint;
	/** input type for incrementing numeric columns in table "voyce.quests" */
["voyce_quests_inc_input"]: {
	difficulty_id?:number,
	experience_points?:number,
	id?:number
};
	/** input type for inserting data into table "voyce.quests" */
["voyce_quests_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	description?:string,
	difficulty?:ValueTypes["voyce_quest_difficulties_obj_rel_insert_input"],
	difficulty_id?:number,
	/** e.g: { chapters_viewed: 3, chapter_completion: 0.5 } */
	event_data?:ValueTypes["json"],
	/** SubscribeSeries, DailyCheckIn, etc.. */
	event_trigger?:string,
	experience_points?:number,
	/** once, daily, weekly */
	frequency?:string,
	id?:number,
	/** Qualified, Started, Completed */
	initial_status?:string,
	notif_message?:string,
	/** e.g: { type: coin, value: 100 } */
	reward?:ValueTypes["json"],
	/** e.g. { page: shop, is_rewarded: false } */
	start_data?:ValueTypes["json"],
	/** auto, manual */
	start_type?:string,
	tag?:string,
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_quests_max_fields"]: AliasType<{
	created_at?:true,
	description?:true,
	difficulty_id?:true,
	/** SubscribeSeries, DailyCheckIn, etc.. */
	event_trigger?:true,
	experience_points?:true,
	/** once, daily, weekly */
	frequency?:true,
	id?:true,
	/** Qualified, Started, Completed */
	initial_status?:true,
	notif_message?:true,
	/** auto, manual */
	start_type?:true,
	tag?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_quests_min_fields"]: AliasType<{
	created_at?:true,
	description?:true,
	difficulty_id?:true,
	/** SubscribeSeries, DailyCheckIn, etc.. */
	event_trigger?:true,
	experience_points?:true,
	/** once, daily, weekly */
	frequency?:true,
	id?:true,
	/** Qualified, Started, Completed */
	initial_status?:true,
	notif_message?:true,
	/** auto, manual */
	start_type?:true,
	tag?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.quests" */
["voyce_quests_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_quests"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.quests" */
["voyce_quests_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_quests_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_quests_on_conflict"]
};
	/** columns and relationships of "voyce.quests_old" */
["voyce_quests_old"]: AliasType<{
	end_date?:true,
	id?:true,
	image?:true,
	recurrency?:true,
	recurrent?:true,
rewards_quests?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_rewards_quest_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_rewards_quest_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_quest_bool_exp"]},ValueTypes["voyce_rewards_quest"]],
rewards_quests_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_rewards_quest_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_rewards_quest_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_rewards_quest_bool_exp"]},ValueTypes["voyce_rewards_quest_aggregate"]],
	start_date?:true,
	steps?:true,
	title?:true,
user_quests?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quests_old_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quests_old_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quests_old_bool_exp"]},ValueTypes["voyce_user_quests_old"]],
user_quests_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_user_quests_old_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_user_quests_old_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_user_quests_old_bool_exp"]},ValueTypes["voyce_user_quests_old_aggregate"]],
		__typename?: true
}>;
	/** aggregated selection of "voyce.quests_old" */
["voyce_quests_old_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_quests_old_aggregate_fields"],
	nodes?:ValueTypes["voyce_quests_old"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.quests_old" */
["voyce_quests_old_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_quests_old_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_quests_old_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_quests_old_max_fields"],
	min?:ValueTypes["voyce_quests_old_min_fields"],
	stddev?:ValueTypes["voyce_quests_old_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_quests_old_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_quests_old_stddev_samp_fields"],
	sum?:ValueTypes["voyce_quests_old_sum_fields"],
	var_pop?:ValueTypes["voyce_quests_old_var_pop_fields"],
	var_samp?:ValueTypes["voyce_quests_old_var_samp_fields"],
	variance?:ValueTypes["voyce_quests_old_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_quests_old_avg_fields"]: AliasType<{
	id?:true,
	recurrency?:true,
	steps?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.quests_old". All fields are combined with a logical 'AND'. */
["voyce_quests_old_bool_exp"]: {
	_and?:ValueTypes["voyce_quests_old_bool_exp"][],
	_not?:ValueTypes["voyce_quests_old_bool_exp"],
	_or?:ValueTypes["voyce_quests_old_bool_exp"][],
	end_date?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	image?:ValueTypes["String_comparison_exp"],
	recurrency?:ValueTypes["Int_comparison_exp"],
	recurrent?:ValueTypes["Boolean_comparison_exp"],
	rewards_quests?:ValueTypes["voyce_rewards_quest_bool_exp"],
	rewards_quests_aggregate?:ValueTypes["voyce_rewards_quest_aggregate_bool_exp"],
	start_date?:ValueTypes["timestamp_comparison_exp"],
	steps?:ValueTypes["Int_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	user_quests?:ValueTypes["voyce_user_quests_old_bool_exp"],
	user_quests_aggregate?:ValueTypes["voyce_user_quests_old_aggregate_bool_exp"]
};
	/** unique or primary key constraints on table "voyce.quests_old" */
["voyce_quests_old_constraint"]:voyce_quests_old_constraint;
	/** input type for incrementing numeric columns in table "voyce.quests_old" */
["voyce_quests_old_inc_input"]: {
	id?:number,
	recurrency?:number,
	steps?:number
};
	/** input type for inserting data into table "voyce.quests_old" */
["voyce_quests_old_insert_input"]: {
	end_date?:ValueTypes["timestamp"],
	id?:number,
	image?:string,
	recurrency?:number,
	recurrent?:boolean,
	rewards_quests?:ValueTypes["voyce_rewards_quest_arr_rel_insert_input"],
	start_date?:ValueTypes["timestamp"],
	steps?:number,
	title?:string,
	user_quests?:ValueTypes["voyce_user_quests_old_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["voyce_quests_old_max_fields"]: AliasType<{
	end_date?:true,
	id?:true,
	image?:true,
	recurrency?:true,
	start_date?:true,
	steps?:true,
	title?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_quests_old_min_fields"]: AliasType<{
	end_date?:true,
	id?:true,
	image?:true,
	recurrency?:true,
	start_date?:true,
	steps?:true,
	title?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.quests_old" */
["voyce_quests_old_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_quests_old"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.quests_old" */
["voyce_quests_old_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_quests_old_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_quests_old_on_conflict"]
};
	/** on_conflict condition type for table "voyce.quests_old" */
["voyce_quests_old_on_conflict"]: {
	constraint:ValueTypes["voyce_quests_old_constraint"],
	update_columns:ValueTypes["voyce_quests_old_update_column"][],
	where?:ValueTypes["voyce_quests_old_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.quests_old". */
["voyce_quests_old_order_by"]: {
	end_date?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	recurrency?:ValueTypes["order_by"],
	recurrent?:ValueTypes["order_by"],
	rewards_quests_aggregate?:ValueTypes["voyce_rewards_quest_aggregate_order_by"],
	start_date?:ValueTypes["order_by"],
	steps?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	user_quests_aggregate?:ValueTypes["voyce_user_quests_old_aggregate_order_by"]
};
	/** primary key columns input for table: voyce.quests_old */
["voyce_quests_old_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.quests_old" */
["voyce_quests_old_select_column"]:voyce_quests_old_select_column;
	/** input type for updating data in table "voyce.quests_old" */
["voyce_quests_old_set_input"]: {
	end_date?:ValueTypes["timestamp"],
	id?:number,
	image?:string,
	recurrency?:number,
	recurrent?:boolean,
	start_date?:ValueTypes["timestamp"],
	steps?:number,
	title?:string
};
	/** aggregate stddev on columns */
["voyce_quests_old_stddev_fields"]: AliasType<{
	id?:true,
	recurrency?:true,
	steps?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_quests_old_stddev_pop_fields"]: AliasType<{
	id?:true,
	recurrency?:true,
	steps?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_quests_old_stddev_samp_fields"]: AliasType<{
	id?:true,
	recurrency?:true,
	steps?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_quests_old" */
["voyce_quests_old_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_quests_old_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_quests_old_stream_cursor_value_input"]: {
	end_date?:ValueTypes["timestamp"],
	id?:number,
	image?:string,
	recurrency?:number,
	recurrent?:boolean,
	start_date?:ValueTypes["timestamp"],
	steps?:number,
	title?:string
};
	/** aggregate sum on columns */
["voyce_quests_old_sum_fields"]: AliasType<{
	id?:true,
	recurrency?:true,
	steps?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.quests_old" */
["voyce_quests_old_update_column"]:voyce_quests_old_update_column;
	["voyce_quests_old_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_quests_old_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_quests_old_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_quests_old_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_quests_old_var_pop_fields"]: AliasType<{
	id?:true,
	recurrency?:true,
	steps?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_quests_old_var_samp_fields"]: AliasType<{
	id?:true,
	recurrency?:true,
	steps?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_quests_old_variance_fields"]: AliasType<{
	id?:true,
	recurrency?:true,
	steps?:true,
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.quests" */
["voyce_quests_on_conflict"]: {
	constraint:ValueTypes["voyce_quests_constraint"],
	update_columns:ValueTypes["voyce_quests_update_column"][],
	where?:ValueTypes["voyce_quests_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.quests". */
["voyce_quests_order_by"]: {
	created_at?:ValueTypes["order_by"],
	description?:ValueTypes["order_by"],
	difficulty?:ValueTypes["voyce_quest_difficulties_order_by"],
	difficulty_id?:ValueTypes["order_by"],
	event_data?:ValueTypes["order_by"],
	event_trigger?:ValueTypes["order_by"],
	experience_points?:ValueTypes["order_by"],
	frequency?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	initial_status?:ValueTypes["order_by"],
	notif_message?:ValueTypes["order_by"],
	reward?:ValueTypes["order_by"],
	start_data?:ValueTypes["order_by"],
	start_type?:ValueTypes["order_by"],
	tag?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.quests */
["voyce_quests_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.quests" */
["voyce_quests_select_column"]:voyce_quests_select_column;
	/** input type for updating data in table "voyce.quests" */
["voyce_quests_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	description?:string,
	difficulty_id?:number,
	/** e.g: { chapters_viewed: 3, chapter_completion: 0.5 } */
	event_data?:ValueTypes["json"],
	/** SubscribeSeries, DailyCheckIn, etc.. */
	event_trigger?:string,
	experience_points?:number,
	/** once, daily, weekly */
	frequency?:string,
	id?:number,
	/** Qualified, Started, Completed */
	initial_status?:string,
	notif_message?:string,
	/** e.g: { type: coin, value: 100 } */
	reward?:ValueTypes["json"],
	/** e.g. { page: shop, is_rewarded: false } */
	start_data?:ValueTypes["json"],
	/** auto, manual */
	start_type?:string,
	tag?:string,
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_quests_stddev_fields"]: AliasType<{
	difficulty_id?:true,
	experience_points?:true,
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_quests_stddev_pop_fields"]: AliasType<{
	difficulty_id?:true,
	experience_points?:true,
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_quests_stddev_samp_fields"]: AliasType<{
	difficulty_id?:true,
	experience_points?:true,
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_quests" */
["voyce_quests_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_quests_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_quests_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	description?:string,
	difficulty_id?:number,
	/** e.g: { chapters_viewed: 3, chapter_completion: 0.5 } */
	event_data?:ValueTypes["json"],
	/** SubscribeSeries, DailyCheckIn, etc.. */
	event_trigger?:string,
	experience_points?:number,
	/** once, daily, weekly */
	frequency?:string,
	id?:number,
	/** Qualified, Started, Completed */
	initial_status?:string,
	notif_message?:string,
	/** e.g: { type: coin, value: 100 } */
	reward?:ValueTypes["json"],
	/** e.g. { page: shop, is_rewarded: false } */
	start_data?:ValueTypes["json"],
	/** auto, manual */
	start_type?:string,
	tag?:string,
	title?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_quests_sum_fields"]: AliasType<{
	difficulty_id?:true,
	experience_points?:true,
	id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.quests" */
["voyce_quests_update_column"]:voyce_quests_update_column;
	["voyce_quests_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_quests_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_quests_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_quests_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_quests_var_pop_fields"]: AliasType<{
	difficulty_id?:true,
	experience_points?:true,
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_quests_var_samp_fields"]: AliasType<{
	difficulty_id?:true,
	experience_points?:true,
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_quests_variance_fields"]: AliasType<{
	difficulty_id?:true,
	experience_points?:true,
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.recommended_series" */
["voyce_recommended_series"]: AliasType<{
	created_at?:true,
	id?:true,
	notification_image?:true,
	notification_status?:true,
	notification_text?:true,
	notification_title?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.recommended_series" */
["voyce_recommended_series_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_recommended_series_aggregate_fields"],
	nodes?:ValueTypes["voyce_recommended_series"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.recommended_series" */
["voyce_recommended_series_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_recommended_series_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_recommended_series_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_recommended_series_max_fields"],
	min?:ValueTypes["voyce_recommended_series_min_fields"],
	stddev?:ValueTypes["voyce_recommended_series_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_recommended_series_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_recommended_series_stddev_samp_fields"],
	sum?:ValueTypes["voyce_recommended_series_sum_fields"],
	var_pop?:ValueTypes["voyce_recommended_series_var_pop_fields"],
	var_samp?:ValueTypes["voyce_recommended_series_var_samp_fields"],
	variance?:ValueTypes["voyce_recommended_series_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_recommended_series_avg_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.recommended_series". All fields are combined with a logical 'AND'. */
["voyce_recommended_series_bool_exp"]: {
	_and?:ValueTypes["voyce_recommended_series_bool_exp"][],
	_not?:ValueTypes["voyce_recommended_series_bool_exp"],
	_or?:ValueTypes["voyce_recommended_series_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	notification_image?:ValueTypes["String_comparison_exp"],
	notification_status?:ValueTypes["String_comparison_exp"],
	notification_text?:ValueTypes["String_comparison_exp"],
	notification_title?:ValueTypes["String_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.recommended_series" */
["voyce_recommended_series_constraint"]:voyce_recommended_series_constraint;
	/** input type for incrementing numeric columns in table "voyce.recommended_series" */
["voyce_recommended_series_inc_input"]: {
	id?:number,
	series_id?:number
};
	/** input type for inserting data into table "voyce.recommended_series" */
["voyce_recommended_series_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	notification_image?:string,
	notification_status?:string,
	notification_text?:string,
	notification_title?:string,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number
};
	/** aggregate max on columns */
["voyce_recommended_series_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	notification_image?:true,
	notification_status?:true,
	notification_text?:true,
	notification_title?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_recommended_series_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	notification_image?:true,
	notification_status?:true,
	notification_text?:true,
	notification_title?:true,
	series_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.recommended_series" */
["voyce_recommended_series_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_recommended_series"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.recommended_series" */
["voyce_recommended_series_on_conflict"]: {
	constraint:ValueTypes["voyce_recommended_series_constraint"],
	update_columns:ValueTypes["voyce_recommended_series_update_column"][],
	where?:ValueTypes["voyce_recommended_series_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.recommended_series". */
["voyce_recommended_series_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	notification_image?:ValueTypes["order_by"],
	notification_status?:ValueTypes["order_by"],
	notification_text?:ValueTypes["order_by"],
	notification_title?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.recommended_series */
["voyce_recommended_series_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.recommended_series" */
["voyce_recommended_series_select_column"]:voyce_recommended_series_select_column;
	/** input type for updating data in table "voyce.recommended_series" */
["voyce_recommended_series_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	notification_image?:string,
	notification_status?:string,
	notification_text?:string,
	notification_title?:string,
	series_id?:number
};
	/** aggregate stddev on columns */
["voyce_recommended_series_stddev_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_recommended_series_stddev_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_recommended_series_stddev_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_recommended_series" */
["voyce_recommended_series_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_recommended_series_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_recommended_series_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	notification_image?:string,
	notification_status?:string,
	notification_text?:string,
	notification_title?:string,
	series_id?:number
};
	/** aggregate sum on columns */
["voyce_recommended_series_sum_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.recommended_series" */
["voyce_recommended_series_update_column"]:voyce_recommended_series_update_column;
	["voyce_recommended_series_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_recommended_series_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_recommended_series_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_recommended_series_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_recommended_series_var_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_recommended_series_var_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_recommended_series_variance_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.release_schedules" */
["voyce_release_schedules"]: AliasType<{
	/** An object relationship */
	chapter?:ValueTypes["voyce_chapters"],
	chapter_id?:true,
	created_at?:true,
	id?:true,
	schedule?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.release_schedules" */
["voyce_release_schedules_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_release_schedules_aggregate_fields"],
	nodes?:ValueTypes["voyce_release_schedules"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.release_schedules" */
["voyce_release_schedules_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_release_schedules_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_release_schedules_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_release_schedules_max_fields"],
	min?:ValueTypes["voyce_release_schedules_min_fields"],
	stddev?:ValueTypes["voyce_release_schedules_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_release_schedules_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_release_schedules_stddev_samp_fields"],
	sum?:ValueTypes["voyce_release_schedules_sum_fields"],
	var_pop?:ValueTypes["voyce_release_schedules_var_pop_fields"],
	var_samp?:ValueTypes["voyce_release_schedules_var_samp_fields"],
	variance?:ValueTypes["voyce_release_schedules_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_release_schedules_avg_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.release_schedules". All fields are combined with a logical 'AND'. */
["voyce_release_schedules_bool_exp"]: {
	_and?:ValueTypes["voyce_release_schedules_bool_exp"][],
	_not?:ValueTypes["voyce_release_schedules_bool_exp"],
	_or?:ValueTypes["voyce_release_schedules_bool_exp"][],
	chapter?:ValueTypes["voyce_chapters_bool_exp"],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	schedule?:ValueTypes["timestamptz_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.release_schedules" */
["voyce_release_schedules_constraint"]:voyce_release_schedules_constraint;
	/** input type for incrementing numeric columns in table "voyce.release_schedules" */
["voyce_release_schedules_inc_input"]: {
	chapter_id?:number,
	id?:number,
	series_id?:number
};
	/** input type for inserting data into table "voyce.release_schedules" */
["voyce_release_schedules_insert_input"]: {
	chapter?:ValueTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	schedule?:ValueTypes["timestamptz"],
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_release_schedules_max_fields"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	id?:true,
	schedule?:true,
	series_id?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_release_schedules_min_fields"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	id?:true,
	schedule?:true,
	series_id?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.release_schedules" */
["voyce_release_schedules_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_release_schedules"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.release_schedules" */
["voyce_release_schedules_on_conflict"]: {
	constraint:ValueTypes["voyce_release_schedules_constraint"],
	update_columns:ValueTypes["voyce_release_schedules_update_column"][],
	where?:ValueTypes["voyce_release_schedules_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.release_schedules". */
["voyce_release_schedules_order_by"]: {
	chapter?:ValueTypes["voyce_chapters_order_by"],
	chapter_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	schedule?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.release_schedules */
["voyce_release_schedules_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.release_schedules" */
["voyce_release_schedules_select_column"]:voyce_release_schedules_select_column;
	/** input type for updating data in table "voyce.release_schedules" */
["voyce_release_schedules_set_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	schedule?:ValueTypes["timestamptz"],
	series_id?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_release_schedules_stddev_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_release_schedules_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_release_schedules_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_release_schedules" */
["voyce_release_schedules_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_release_schedules_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_release_schedules_stream_cursor_value_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	schedule?:ValueTypes["timestamptz"],
	series_id?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_release_schedules_sum_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.release_schedules" */
["voyce_release_schedules_update_column"]:voyce_release_schedules_update_column;
	["voyce_release_schedules_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_release_schedules_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_release_schedules_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_release_schedules_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_release_schedules_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_release_schedules_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_release_schedules_variance_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** table to describe all possible rewards users can get */
["voyce_rewards"]: AliasType<{
	end_date?:true,
	expiry_date?:true,
	free?:true,
	id?:true,
	image?:true,
	purchaseId?:true,
	start_date?:true,
	title?:true,
	transferable?:true,
	type?:true,
	value?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.rewards" */
["voyce_rewards_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_rewards_aggregate_fields"],
	nodes?:ValueTypes["voyce_rewards"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.rewards" */
["voyce_rewards_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_rewards_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_rewards_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_rewards_max_fields"],
	min?:ValueTypes["voyce_rewards_min_fields"],
	stddev?:ValueTypes["voyce_rewards_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_rewards_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_rewards_stddev_samp_fields"],
	sum?:ValueTypes["voyce_rewards_sum_fields"],
	var_pop?:ValueTypes["voyce_rewards_var_pop_fields"],
	var_samp?:ValueTypes["voyce_rewards_var_samp_fields"],
	variance?:ValueTypes["voyce_rewards_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_rewards_avg_fields"]: AliasType<{
	id?:true,
	value?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.rewards". All fields are combined with a logical 'AND'. */
["voyce_rewards_bool_exp"]: {
	_and?:ValueTypes["voyce_rewards_bool_exp"][],
	_not?:ValueTypes["voyce_rewards_bool_exp"],
	_or?:ValueTypes["voyce_rewards_bool_exp"][],
	end_date?:ValueTypes["timestamp_comparison_exp"],
	expiry_date?:ValueTypes["timestamp_comparison_exp"],
	free?:ValueTypes["Boolean_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	image?:ValueTypes["String_comparison_exp"],
	purchaseId?:ValueTypes["String_comparison_exp"],
	start_date?:ValueTypes["timestamp_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	transferable?:ValueTypes["Boolean_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"],
	value?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.rewards" */
["voyce_rewards_constraint"]:voyce_rewards_constraint;
	/** input type for incrementing numeric columns in table "voyce.rewards" */
["voyce_rewards_inc_input"]: {
	id?:number,
	value?:number
};
	/** input type for inserting data into table "voyce.rewards" */
["voyce_rewards_insert_input"]: {
	end_date?:ValueTypes["timestamp"],
	expiry_date?:ValueTypes["timestamp"],
	free?:boolean,
	id?:number,
	image?:string,
	purchaseId?:string,
	start_date?:ValueTypes["timestamp"],
	title?:string,
	transferable?:boolean,
	type?:string,
	value?:number
};
	/** table to keep rewards configuration for each leaderboards */
["voyce_rewards_leaderboard"]: AliasType<{
	id?:true,
	order?:true,
	/** An object relationship */
	reward?:ValueTypes["voyce_rewards"],
	reward_id?:true,
	tag?:true,
	title?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_rewards_leaderboard_aggregate_fields"],
	nodes?:ValueTypes["voyce_rewards_leaderboard"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_rewards_leaderboard_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_rewards_leaderboard_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_rewards_leaderboard_max_fields"],
	min?:ValueTypes["voyce_rewards_leaderboard_min_fields"],
	stddev?:ValueTypes["voyce_rewards_leaderboard_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_rewards_leaderboard_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_rewards_leaderboard_stddev_samp_fields"],
	sum?:ValueTypes["voyce_rewards_leaderboard_sum_fields"],
	var_pop?:ValueTypes["voyce_rewards_leaderboard_var_pop_fields"],
	var_samp?:ValueTypes["voyce_rewards_leaderboard_var_samp_fields"],
	variance?:ValueTypes["voyce_rewards_leaderboard_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_rewards_leaderboard_avg_fields"]: AliasType<{
	id?:true,
	order?:true,
	reward_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.rewards_leaderboard". All fields are combined with a logical 'AND'. */
["voyce_rewards_leaderboard_bool_exp"]: {
	_and?:ValueTypes["voyce_rewards_leaderboard_bool_exp"][],
	_not?:ValueTypes["voyce_rewards_leaderboard_bool_exp"],
	_or?:ValueTypes["voyce_rewards_leaderboard_bool_exp"][],
	id?:ValueTypes["Int_comparison_exp"],
	order?:ValueTypes["Int_comparison_exp"],
	reward?:ValueTypes["voyce_rewards_bool_exp"],
	reward_id?:ValueTypes["Int_comparison_exp"],
	tag?:ValueTypes["String_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_constraint"]:voyce_rewards_leaderboard_constraint;
	/** input type for incrementing numeric columns in table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_inc_input"]: {
	id?:number,
	order?:number,
	reward_id?:number
};
	/** input type for inserting data into table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_insert_input"]: {
	id?:number,
	order?:number,
	reward?:ValueTypes["voyce_rewards_obj_rel_insert_input"],
	reward_id?:number,
	tag?:string,
	title?:string
};
	/** aggregate max on columns */
["voyce_rewards_leaderboard_max_fields"]: AliasType<{
	id?:true,
	order?:true,
	reward_id?:true,
	tag?:true,
	title?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_rewards_leaderboard_min_fields"]: AliasType<{
	id?:true,
	order?:true,
	reward_id?:true,
	tag?:true,
	title?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_rewards_leaderboard"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_on_conflict"]: {
	constraint:ValueTypes["voyce_rewards_leaderboard_constraint"],
	update_columns:ValueTypes["voyce_rewards_leaderboard_update_column"][],
	where?:ValueTypes["voyce_rewards_leaderboard_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.rewards_leaderboard". */
["voyce_rewards_leaderboard_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	reward?:ValueTypes["voyce_rewards_order_by"],
	reward_id?:ValueTypes["order_by"],
	tag?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.rewards_leaderboard */
["voyce_rewards_leaderboard_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_select_column"]:voyce_rewards_leaderboard_select_column;
	/** input type for updating data in table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_set_input"]: {
	id?:number,
	order?:number,
	reward_id?:number,
	tag?:string,
	title?:string
};
	/** aggregate stddev on columns */
["voyce_rewards_leaderboard_stddev_fields"]: AliasType<{
	id?:true,
	order?:true,
	reward_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_rewards_leaderboard_stddev_pop_fields"]: AliasType<{
	id?:true,
	order?:true,
	reward_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_rewards_leaderboard_stddev_samp_fields"]: AliasType<{
	id?:true,
	order?:true,
	reward_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_rewards_leaderboard" */
["voyce_rewards_leaderboard_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_rewards_leaderboard_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_rewards_leaderboard_stream_cursor_value_input"]: {
	id?:number,
	order?:number,
	reward_id?:number,
	tag?:string,
	title?:string
};
	/** aggregate sum on columns */
["voyce_rewards_leaderboard_sum_fields"]: AliasType<{
	id?:true,
	order?:true,
	reward_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_update_column"]:voyce_rewards_leaderboard_update_column;
	["voyce_rewards_leaderboard_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_rewards_leaderboard_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_rewards_leaderboard_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_rewards_leaderboard_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_rewards_leaderboard_var_pop_fields"]: AliasType<{
	id?:true,
	order?:true,
	reward_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_rewards_leaderboard_var_samp_fields"]: AliasType<{
	id?:true,
	order?:true,
	reward_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_rewards_leaderboard_variance_fields"]: AliasType<{
	id?:true,
	order?:true,
	reward_id?:true,
		__typename?: true
}>;
	/** aggregate max on columns */
["voyce_rewards_max_fields"]: AliasType<{
	end_date?:true,
	expiry_date?:true,
	id?:true,
	image?:true,
	purchaseId?:true,
	start_date?:true,
	title?:true,
	type?:true,
	value?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_rewards_min_fields"]: AliasType<{
	end_date?:true,
	expiry_date?:true,
	id?:true,
	image?:true,
	purchaseId?:true,
	start_date?:true,
	title?:true,
	type?:true,
	value?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.rewards" */
["voyce_rewards_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_rewards"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.rewards" */
["voyce_rewards_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_rewards_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_rewards_on_conflict"]
};
	/** on_conflict condition type for table "voyce.rewards" */
["voyce_rewards_on_conflict"]: {
	constraint:ValueTypes["voyce_rewards_constraint"],
	update_columns:ValueTypes["voyce_rewards_update_column"][],
	where?:ValueTypes["voyce_rewards_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.rewards". */
["voyce_rewards_order_by"]: {
	end_date?:ValueTypes["order_by"],
	expiry_date?:ValueTypes["order_by"],
	free?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	purchaseId?:ValueTypes["order_by"],
	start_date?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	transferable?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	value?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.rewards */
["voyce_rewards_pk_columns_input"]: {
	id:number
};
	/** table to describe rewards of a given quest */
["voyce_rewards_quest"]: AliasType<{
	id?:true,
	/** An object relationship */
	quest?:ValueTypes["voyce_quests_old"],
	quest_id?:true,
	/** An object relationship */
	reward?:ValueTypes["voyce_rewards"],
	rewards_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.rewards_quest" */
["voyce_rewards_quest_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_rewards_quest_aggregate_fields"],
	nodes?:ValueTypes["voyce_rewards_quest"],
		__typename?: true
}>;
	["voyce_rewards_quest_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_rewards_quest_aggregate_bool_exp_count"]
};
	["voyce_rewards_quest_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_rewards_quest_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_rewards_quest_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.rewards_quest" */
["voyce_rewards_quest_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_rewards_quest_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_rewards_quest_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_rewards_quest_max_fields"],
	min?:ValueTypes["voyce_rewards_quest_min_fields"],
	stddev?:ValueTypes["voyce_rewards_quest_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_rewards_quest_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_rewards_quest_stddev_samp_fields"],
	sum?:ValueTypes["voyce_rewards_quest_sum_fields"],
	var_pop?:ValueTypes["voyce_rewards_quest_var_pop_fields"],
	var_samp?:ValueTypes["voyce_rewards_quest_var_samp_fields"],
	variance?:ValueTypes["voyce_rewards_quest_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.rewards_quest" */
["voyce_rewards_quest_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_rewards_quest_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_rewards_quest_max_order_by"],
	min?:ValueTypes["voyce_rewards_quest_min_order_by"],
	stddev?:ValueTypes["voyce_rewards_quest_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_rewards_quest_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_rewards_quest_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_rewards_quest_sum_order_by"],
	var_pop?:ValueTypes["voyce_rewards_quest_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_rewards_quest_var_samp_order_by"],
	variance?:ValueTypes["voyce_rewards_quest_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.rewards_quest" */
["voyce_rewards_quest_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_rewards_quest_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_rewards_quest_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_rewards_quest_avg_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	rewards_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	rewards_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.rewards_quest". All fields are combined with a logical 'AND'. */
["voyce_rewards_quest_bool_exp"]: {
	_and?:ValueTypes["voyce_rewards_quest_bool_exp"][],
	_not?:ValueTypes["voyce_rewards_quest_bool_exp"],
	_or?:ValueTypes["voyce_rewards_quest_bool_exp"][],
	id?:ValueTypes["Int_comparison_exp"],
	quest?:ValueTypes["voyce_quests_old_bool_exp"],
	quest_id?:ValueTypes["Int_comparison_exp"],
	reward?:ValueTypes["voyce_rewards_bool_exp"],
	rewards_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.rewards_quest" */
["voyce_rewards_quest_constraint"]:voyce_rewards_quest_constraint;
	/** input type for incrementing numeric columns in table "voyce.rewards_quest" */
["voyce_rewards_quest_inc_input"]: {
	id?:number,
	quest_id?:number,
	rewards_id?:number
};
	/** input type for inserting data into table "voyce.rewards_quest" */
["voyce_rewards_quest_insert_input"]: {
	id?:number,
	quest?:ValueTypes["voyce_quests_old_obj_rel_insert_input"],
	quest_id?:number,
	reward?:ValueTypes["voyce_rewards_obj_rel_insert_input"],
	rewards_id?:number
};
	/** aggregate max on columns */
["voyce_rewards_quest_max_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	rewards_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_max_order_by"]: {
	id?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	rewards_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_rewards_quest_min_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	rewards_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_min_order_by"]: {
	id?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	rewards_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.rewards_quest" */
["voyce_rewards_quest_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_rewards_quest"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.rewards_quest" */
["voyce_rewards_quest_on_conflict"]: {
	constraint:ValueTypes["voyce_rewards_quest_constraint"],
	update_columns:ValueTypes["voyce_rewards_quest_update_column"][],
	where?:ValueTypes["voyce_rewards_quest_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.rewards_quest". */
["voyce_rewards_quest_order_by"]: {
	id?:ValueTypes["order_by"],
	quest?:ValueTypes["voyce_quests_old_order_by"],
	quest_id?:ValueTypes["order_by"],
	reward?:ValueTypes["voyce_rewards_order_by"],
	rewards_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.rewards_quest */
["voyce_rewards_quest_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_select_column"]:voyce_rewards_quest_select_column;
	/** input type for updating data in table "voyce.rewards_quest" */
["voyce_rewards_quest_set_input"]: {
	id?:number,
	quest_id?:number,
	rewards_id?:number
};
	/** aggregate stddev on columns */
["voyce_rewards_quest_stddev_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	rewards_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	rewards_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_rewards_quest_stddev_pop_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	rewards_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	rewards_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_rewards_quest_stddev_samp_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	rewards_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	rewards_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_rewards_quest" */
["voyce_rewards_quest_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_rewards_quest_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_rewards_quest_stream_cursor_value_input"]: {
	id?:number,
	quest_id?:number,
	rewards_id?:number
};
	/** aggregate sum on columns */
["voyce_rewards_quest_sum_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	rewards_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	rewards_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_update_column"]:voyce_rewards_quest_update_column;
	["voyce_rewards_quest_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_rewards_quest_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_rewards_quest_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_rewards_quest_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_rewards_quest_var_pop_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	rewards_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	rewards_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_rewards_quest_var_samp_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	rewards_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	rewards_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_rewards_quest_variance_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	rewards_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	rewards_id?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.rewards" */
["voyce_rewards_select_column"]:voyce_rewards_select_column;
	/** input type for updating data in table "voyce.rewards" */
["voyce_rewards_set_input"]: {
	end_date?:ValueTypes["timestamp"],
	expiry_date?:ValueTypes["timestamp"],
	free?:boolean,
	id?:number,
	image?:string,
	purchaseId?:string,
	start_date?:ValueTypes["timestamp"],
	title?:string,
	transferable?:boolean,
	type?:string,
	value?:number
};
	/** aggregate stddev on columns */
["voyce_rewards_stddev_fields"]: AliasType<{
	id?:true,
	value?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_rewards_stddev_pop_fields"]: AliasType<{
	id?:true,
	value?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_rewards_stddev_samp_fields"]: AliasType<{
	id?:true,
	value?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_rewards" */
["voyce_rewards_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_rewards_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_rewards_stream_cursor_value_input"]: {
	end_date?:ValueTypes["timestamp"],
	expiry_date?:ValueTypes["timestamp"],
	free?:boolean,
	id?:number,
	image?:string,
	purchaseId?:string,
	start_date?:ValueTypes["timestamp"],
	title?:string,
	transferable?:boolean,
	type?:string,
	value?:number
};
	/** aggregate sum on columns */
["voyce_rewards_sum_fields"]: AliasType<{
	id?:true,
	value?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.rewards" */
["voyce_rewards_update_column"]:voyce_rewards_update_column;
	["voyce_rewards_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_rewards_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_rewards_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_rewards_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_rewards_var_pop_fields"]: AliasType<{
	id?:true,
	value?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_rewards_var_samp_fields"]: AliasType<{
	id?:true,
	value?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_rewards_variance_fields"]: AliasType<{
	id?:true,
	value?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.sections" */
["voyce_sections"]: AliasType<{
	content?:true,
	created_at?:true,
	id?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.sections" */
["voyce_sections_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_sections_aggregate_fields"],
	nodes?:ValueTypes["voyce_sections"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.sections" */
["voyce_sections_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_sections_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_sections_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_sections_max_fields"],
	min?:ValueTypes["voyce_sections_min_fields"],
	stddev?:ValueTypes["voyce_sections_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_sections_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_sections_stddev_samp_fields"],
	sum?:ValueTypes["voyce_sections_sum_fields"],
	var_pop?:ValueTypes["voyce_sections_var_pop_fields"],
	var_samp?:ValueTypes["voyce_sections_var_samp_fields"],
	variance?:ValueTypes["voyce_sections_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_sections_avg_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.sections". All fields are combined with a logical 'AND'. */
["voyce_sections_bool_exp"]: {
	_and?:ValueTypes["voyce_sections_bool_exp"][],
	_not?:ValueTypes["voyce_sections_bool_exp"],
	_or?:ValueTypes["voyce_sections_bool_exp"][],
	content?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamp_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.sections" */
["voyce_sections_constraint"]:voyce_sections_constraint;
	/** input type for incrementing numeric columns in table "voyce.sections" */
["voyce_sections_inc_input"]: {
	id?:number
};
	/** input type for inserting data into table "voyce.sections" */
["voyce_sections_insert_input"]: {
	content?:string,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	title?:string,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate max on columns */
["voyce_sections_max_fields"]: AliasType<{
	content?:true,
	created_at?:true,
	id?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_sections_min_fields"]: AliasType<{
	content?:true,
	created_at?:true,
	id?:true,
	title?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.sections" */
["voyce_sections_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_sections"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.sections" */
["voyce_sections_on_conflict"]: {
	constraint:ValueTypes["voyce_sections_constraint"],
	update_columns:ValueTypes["voyce_sections_update_column"][],
	where?:ValueTypes["voyce_sections_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.sections". */
["voyce_sections_order_by"]: {
	content?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.sections */
["voyce_sections_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.sections" */
["voyce_sections_select_column"]:voyce_sections_select_column;
	/** input type for updating data in table "voyce.sections" */
["voyce_sections_set_input"]: {
	content?:string,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	title?:string,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate stddev on columns */
["voyce_sections_stddev_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_sections_stddev_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_sections_stddev_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_sections" */
["voyce_sections_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_sections_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_sections_stream_cursor_value_input"]: {
	content?:string,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	title?:string,
	updated_at?:ValueTypes["timestamp"]
};
	/** aggregate sum on columns */
["voyce_sections_sum_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.sections" */
["voyce_sections_update_column"]:voyce_sections_update_column;
	["voyce_sections_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_sections_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_sections_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_sections_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_sections_var_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_sections_var_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_sections_variance_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.series" */
["voyce_series"]: AliasType<{
	animated_cover_image?:true,
	audio_allowed?:true,
	/** An object relationship */
	author?:ValueTypes["voyce_users"],
	average_rating?:true,
	back_image?:true,
	banner_bg?:true,
	book_reverse_view?:true,
	book_view?:true,
chapter_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_bool_exp"]},ValueTypes["voyce_chapter_comments"]],
chapter_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_bool_exp"]},ValueTypes["voyce_chapter_comments_aggregate"]],
	chapter_count?:true,
chapter_panels?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_panels_bool_exp"]},ValueTypes["voyce_chapter_panels"]],
chapter_panels_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_panels_bool_exp"]},ValueTypes["voyce_chapter_panels_aggregate"]],
chapters?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapters_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapters_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapters_bool_exp"]},ValueTypes["voyce_chapters"]],
chapters_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapters_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapters_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapters_bool_exp"]},ValueTypes["voyce_chapters_aggregate"]],
	chapters_hide?:true,
	character_cover?:true,
	coin_price?:true,
collaborators?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_collaborators_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_collaborators_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_collaborators_bool_exp"]},ValueTypes["voyce_series_collaborators"]],
collaborators_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_collaborators_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_collaborators_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_collaborators_bool_exp"]},ValueTypes["voyce_series_collaborators_aggregate"]],
	comic_type_id?:true,
	cover_image?:true,
	cover_image_mobile?:true,
	created_at?:true,
	csat?:true,
	default_view?:true,
	description?:true,
	feature?:true,
featured_panels?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_featured_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_featured_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_featured_panels_bool_exp"]},ValueTypes["voyce_series_featured_panels"]],
featured_panels_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_featured_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_featured_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_featured_panels_bool_exp"]},ValueTypes["voyce_series_featured_panels_aggregate"]],
followers?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_subscription_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_subscription_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_subscription_bool_exp"]},ValueTypes["voyce_series_subscription"]],
followers_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_subscription_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_subscription_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_subscription_bool_exp"]},ValueTypes["voyce_series_subscription_aggregate"]],
	frequency?:true,
	frequency_mask?:true,
	front_image?:true,
genres?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_genre_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_genre_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_genre_bool_exp"]},ValueTypes["voyce_series_genre"]],
genres_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_genre_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_genre_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_genre_bool_exp"]},ValueTypes["voyce_series_genre_aggregate"]],
hot_panels?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_hot_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_hot_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_hot_panels_bool_exp"]},ValueTypes["voyce_series_hot_panels"]],
hot_panels_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_hot_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_hot_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_hot_panels_bool_exp"]},ValueTypes["voyce_series_hot_panels_aggregate"]],
	id?:true,
	is_animated_cover_allowed?:true,
	is_approved?:true,
	is_deleted?:true,
	is_intro?:true,
	is_locked?:true,
	is_original?:true,
	is_pilots?:true,
	is_recommended?:true,
	klaviyo_id?:true,
	klaviyo_list_id?:true,
	latest_chapter_update?:true,
likes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_likes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_likes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_likes_bool_exp"]},ValueTypes["voyce_chapter_likes"]],
likes_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_likes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_likes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_likes_bool_exp"]},ValueTypes["voyce_chapter_likes_aggregate"]],
	/** An object relationship */
	likes_count?:ValueTypes["voyce_series_likes_count_mat"],
	/** An object relationship */
	lock_config?:ValueTypes["voyce_series_lock_config"],
	locked?:true,
	locked_chapters_amount?:true,
	/** An object relationship */
	notification_settings?:ValueTypes["voyce_notification_series_settings"],
	paginated_view?:true,
	pilots_votes_count?:true,
	portfolio_desc?:true,
	publish?:true,
	rating_count?:true,
	reading_view?:true,
	scroll_view?:true,
series_tags_junctions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_tags_junction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_tags_junction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_tags_junction_bool_exp"]},ValueTypes["voyce_series_tags_junction"]],
series_tags_junctions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_tags_junction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_tags_junction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_tags_junction_bool_exp"]},ValueTypes["voyce_series_tags_junction_aggregate"]],
	short_desc?:true,
	slug?:true,
	/** An object relationship */
	sponsorship_config?:ValueTypes["voyce_series_sponsorships_configuration"],
sponsorships?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_sponsorships_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_sponsorships_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_sponsorships_bool_exp"]},ValueTypes["voyce_sponsorships"]],
sponsorships_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_sponsorships_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_sponsorships_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_sponsorships_bool_exp"]},ValueTypes["voyce_sponsorships_aggregate"]],
	status?:true,
	subscriber_count?:true,
	support_msg?:true,
	team_spotlight?:true,
	thumbnail?:true,
	thumbnail_box?:true,
	title?:true,
	trending?:true,
	trending_position?:true,
	/** An object relationship */
	type?:ValueTypes["voyce_comic_types"],
	updated_at?:true,
	user_display_field?:true,
	user_id?:true,
users_series_junctions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_series_junction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_series_junction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_series_junction_bool_exp"]},ValueTypes["voyce_users_series_junction"]],
users_series_junctions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_series_junction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_series_junction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_series_junction_bool_exp"]},ValueTypes["voyce_users_series_junction_aggregate"]],
	view_count?:true,
views?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_views_bool_exp"]},ValueTypes["voyce_chapter_views"]],
views_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_views_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_views_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_views_bool_exp"]},ValueTypes["voyce_chapter_views_aggregate"]],
	/** An object relationship */
	views_counts?:ValueTypes["voyce_pg_rs_series_views_count"],
	welcome_msg?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series" */
["voyce_series_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_aggregate_fields"],
	nodes?:ValueTypes["voyce_series"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series" */
["voyce_series_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_max_fields"],
	min?:ValueTypes["voyce_series_min_fields"],
	stddev?:ValueTypes["voyce_series_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_sum_fields"],
	var_pop?:ValueTypes["voyce_series_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_var_samp_fields"],
	variance?:ValueTypes["voyce_series_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_avg_fields"]: AliasType<{
	average_rating?:true,
	chapter_count?:true,
	coin_price?:true,
	comic_type_id?:true,
	frequency_mask?:true,
	id?:true,
	is_approved?:true,
	is_intro?:true,
	is_original?:true,
	is_pilots?:true,
	is_recommended?:true,
	locked_chapters_amount?:true,
	pilots_votes_count?:true,
	publish?:true,
	rating_count?:true,
	subscriber_count?:true,
	trending_position?:true,
	user_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series". All fields are combined with a logical 'AND'. */
["voyce_series_bool_exp"]: {
	_and?:ValueTypes["voyce_series_bool_exp"][],
	_not?:ValueTypes["voyce_series_bool_exp"],
	_or?:ValueTypes["voyce_series_bool_exp"][],
	animated_cover_image?:ValueTypes["String_comparison_exp"],
	audio_allowed?:ValueTypes["Boolean_comparison_exp"],
	author?:ValueTypes["voyce_users_bool_exp"],
	average_rating?:ValueTypes["float8_comparison_exp"],
	back_image?:ValueTypes["String_comparison_exp"],
	banner_bg?:ValueTypes["String_comparison_exp"],
	book_reverse_view?:ValueTypes["Boolean_comparison_exp"],
	book_view?:ValueTypes["Boolean_comparison_exp"],
	chapter_comments?:ValueTypes["voyce_chapter_comments_bool_exp"],
	chapter_comments_aggregate?:ValueTypes["voyce_chapter_comments_aggregate_bool_exp"],
	chapter_count?:ValueTypes["Int_comparison_exp"],
	chapter_panels?:ValueTypes["voyce_chapter_panels_bool_exp"],
	chapter_panels_aggregate?:ValueTypes["voyce_chapter_panels_aggregate_bool_exp"],
	chapters?:ValueTypes["voyce_chapters_bool_exp"],
	chapters_aggregate?:ValueTypes["voyce_chapters_aggregate_bool_exp"],
	chapters_hide?:ValueTypes["String_comparison_exp"],
	character_cover?:ValueTypes["String_comparison_exp"],
	coin_price?:ValueTypes["smallint_comparison_exp"],
	collaborators?:ValueTypes["voyce_series_collaborators_bool_exp"],
	collaborators_aggregate?:ValueTypes["voyce_series_collaborators_aggregate_bool_exp"],
	comic_type_id?:ValueTypes["Int_comparison_exp"],
	cover_image?:ValueTypes["String_comparison_exp"],
	cover_image_mobile?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	csat?:ValueTypes["Boolean_comparison_exp"],
	default_view?:ValueTypes["String_comparison_exp"],
	description?:ValueTypes["String_comparison_exp"],
	feature?:ValueTypes["bit_comparison_exp"],
	featured_panels?:ValueTypes["voyce_series_featured_panels_bool_exp"],
	featured_panels_aggregate?:ValueTypes["voyce_series_featured_panels_aggregate_bool_exp"],
	followers?:ValueTypes["voyce_series_subscription_bool_exp"],
	followers_aggregate?:ValueTypes["voyce_series_subscription_aggregate_bool_exp"],
	frequency?:ValueTypes["String_comparison_exp"],
	frequency_mask?:ValueTypes["Int_comparison_exp"],
	front_image?:ValueTypes["String_comparison_exp"],
	genres?:ValueTypes["voyce_series_genre_bool_exp"],
	genres_aggregate?:ValueTypes["voyce_series_genre_aggregate_bool_exp"],
	hot_panels?:ValueTypes["voyce_series_hot_panels_bool_exp"],
	hot_panels_aggregate?:ValueTypes["voyce_series_hot_panels_aggregate_bool_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_animated_cover_allowed?:ValueTypes["Boolean_comparison_exp"],
	is_approved?:ValueTypes["smallint_comparison_exp"],
	is_deleted?:ValueTypes["Boolean_comparison_exp"],
	is_intro?:ValueTypes["smallint_comparison_exp"],
	is_locked?:ValueTypes["Boolean_comparison_exp"],
	is_original?:ValueTypes["smallint_comparison_exp"],
	is_pilots?:ValueTypes["smallint_comparison_exp"],
	is_recommended?:ValueTypes["smallint_comparison_exp"],
	klaviyo_id?:ValueTypes["String_comparison_exp"],
	klaviyo_list_id?:ValueTypes["String_comparison_exp"],
	latest_chapter_update?:ValueTypes["timestamp_comparison_exp"],
	likes?:ValueTypes["voyce_chapter_likes_bool_exp"],
	likes_aggregate?:ValueTypes["voyce_chapter_likes_aggregate_bool_exp"],
	likes_count?:ValueTypes["voyce_series_likes_count_mat_bool_exp"],
	lock_config?:ValueTypes["voyce_series_lock_config_bool_exp"],
	locked?:ValueTypes["Boolean_comparison_exp"],
	locked_chapters_amount?:ValueTypes["Int_comparison_exp"],
	notification_settings?:ValueTypes["voyce_notification_series_settings_bool_exp"],
	paginated_view?:ValueTypes["Boolean_comparison_exp"],
	pilots_votes_count?:ValueTypes["Int_comparison_exp"],
	portfolio_desc?:ValueTypes["String_comparison_exp"],
	publish?:ValueTypes["smallint_comparison_exp"],
	rating_count?:ValueTypes["Int_comparison_exp"],
	reading_view?:ValueTypes["String_comparison_exp"],
	scroll_view?:ValueTypes["Boolean_comparison_exp"],
	series_tags_junctions?:ValueTypes["voyce_series_tags_junction_bool_exp"],
	series_tags_junctions_aggregate?:ValueTypes["voyce_series_tags_junction_aggregate_bool_exp"],
	short_desc?:ValueTypes["String_comparison_exp"],
	slug?:ValueTypes["String_comparison_exp"],
	sponsorship_config?:ValueTypes["voyce_series_sponsorships_configuration_bool_exp"],
	sponsorships?:ValueTypes["voyce_sponsorships_bool_exp"],
	sponsorships_aggregate?:ValueTypes["voyce_sponsorships_aggregate_bool_exp"],
	status?:ValueTypes["String_comparison_exp"],
	subscriber_count?:ValueTypes["Int_comparison_exp"],
	support_msg?:ValueTypes["String_comparison_exp"],
	team_spotlight?:ValueTypes["Boolean_comparison_exp"],
	thumbnail?:ValueTypes["String_comparison_exp"],
	thumbnail_box?:ValueTypes["String_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	trending?:ValueTypes["bit_comparison_exp"],
	trending_position?:ValueTypes["Int_comparison_exp"],
	type?:ValueTypes["voyce_comic_types_bool_exp"],
	updated_at?:ValueTypes["timestamp_comparison_exp"],
	user_display_field?:ValueTypes["String_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	users_series_junctions?:ValueTypes["voyce_users_series_junction_bool_exp"],
	users_series_junctions_aggregate?:ValueTypes["voyce_users_series_junction_aggregate_bool_exp"],
	view_count?:ValueTypes["Int_comparison_exp"],
	views?:ValueTypes["voyce_chapter_views_bool_exp"],
	views_aggregate?:ValueTypes["voyce_chapter_views_aggregate_bool_exp"],
	views_counts?:ValueTypes["voyce_pg_rs_series_views_count_bool_exp"],
	welcome_msg?:ValueTypes["String_comparison_exp"]
};
	/** columns and relationships of "voyce.series_collaborators" */
["voyce_series_collaborators"]: AliasType<{
	/** An object relationship */
	collaborator_role?:ValueTypes["voyce_collaborator_roles"],
	created_at?:true,
	id?:true,
	role_id?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_collaborators" */
["voyce_series_collaborators_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_collaborators_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_collaborators"],
		__typename?: true
}>;
	["voyce_series_collaborators_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_series_collaborators_aggregate_bool_exp_count"]
};
	["voyce_series_collaborators_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_series_collaborators_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_series_collaborators_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.series_collaborators" */
["voyce_series_collaborators_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_collaborators_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_collaborators_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_collaborators_max_fields"],
	min?:ValueTypes["voyce_series_collaborators_min_fields"],
	stddev?:ValueTypes["voyce_series_collaborators_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_collaborators_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_collaborators_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_collaborators_sum_fields"],
	var_pop?:ValueTypes["voyce_series_collaborators_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_collaborators_var_samp_fields"],
	variance?:ValueTypes["voyce_series_collaborators_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.series_collaborators" */
["voyce_series_collaborators_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_series_collaborators_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_series_collaborators_max_order_by"],
	min?:ValueTypes["voyce_series_collaborators_min_order_by"],
	stddev?:ValueTypes["voyce_series_collaborators_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_series_collaborators_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_series_collaborators_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_series_collaborators_sum_order_by"],
	var_pop?:ValueTypes["voyce_series_collaborators_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_series_collaborators_var_samp_order_by"],
	variance?:ValueTypes["voyce_series_collaborators_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.series_collaborators" */
["voyce_series_collaborators_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_series_collaborators_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_collaborators_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_series_collaborators_avg_fields"]: AliasType<{
	id?:true,
	role_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	role_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.series_collaborators". All fields are combined with a logical 'AND'. */
["voyce_series_collaborators_bool_exp"]: {
	_and?:ValueTypes["voyce_series_collaborators_bool_exp"][],
	_not?:ValueTypes["voyce_series_collaborators_bool_exp"],
	_or?:ValueTypes["voyce_series_collaborators_bool_exp"][],
	collaborator_role?:ValueTypes["voyce_collaborator_roles_bool_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	role_id?:ValueTypes["Int_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_collaborators" */
["voyce_series_collaborators_constraint"]:voyce_series_collaborators_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_collaborators" */
["voyce_series_collaborators_inc_input"]: {
	id?:number,
	role_id?:number,
	series_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.series_collaborators" */
["voyce_series_collaborators_insert_input"]: {
	collaborator_role?:ValueTypes["voyce_collaborator_roles_obj_rel_insert_input"],
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	role_id?:number,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_series_collaborators_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	role_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_max_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	role_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_series_collaborators_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	role_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_min_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	role_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.series_collaborators" */
["voyce_series_collaborators_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_collaborators"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_collaborators" */
["voyce_series_collaborators_on_conflict"]: {
	constraint:ValueTypes["voyce_series_collaborators_constraint"],
	update_columns:ValueTypes["voyce_series_collaborators_update_column"][],
	where?:ValueTypes["voyce_series_collaborators_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_collaborators". */
["voyce_series_collaborators_order_by"]: {
	collaborator_role?:ValueTypes["voyce_collaborator_roles_order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	role_id?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_collaborators */
["voyce_series_collaborators_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_select_column"]:voyce_series_collaborators_select_column;
	/** input type for updating data in table "voyce.series_collaborators" */
["voyce_series_collaborators_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	role_id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_series_collaborators_stddev_fields"]: AliasType<{
	id?:true,
	role_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	role_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_series_collaborators_stddev_pop_fields"]: AliasType<{
	id?:true,
	role_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	role_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_series_collaborators_stddev_samp_fields"]: AliasType<{
	id?:true,
	role_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	role_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_series_collaborators" */
["voyce_series_collaborators_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_collaborators_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_collaborators_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	role_id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_series_collaborators_sum_fields"]: AliasType<{
	id?:true,
	role_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	role_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_update_column"]:voyce_series_collaborators_update_column;
	["voyce_series_collaborators_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_collaborators_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_collaborators_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_collaborators_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_collaborators_var_pop_fields"]: AliasType<{
	id?:true,
	role_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	role_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_series_collaborators_var_samp_fields"]: AliasType<{
	id?:true,
	role_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	role_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_series_collaborators_variance_fields"]: AliasType<{
	id?:true,
	role_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	role_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** unique or primary key constraints on table "voyce.series" */
["voyce_series_constraint"]:voyce_series_constraint;
	/** columns and relationships of "voyce.series_donations" */
["voyce_series_donations"]: AliasType<{
	amount?:true,
	created_at?:true,
	currency?:true,
	id?:true,
	message?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_donations" */
["voyce_series_donations_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_donations_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_donations"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_donations" */
["voyce_series_donations_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_donations_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_donations_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_donations_max_fields"],
	min?:ValueTypes["voyce_series_donations_min_fields"],
	stddev?:ValueTypes["voyce_series_donations_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_donations_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_donations_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_donations_sum_fields"],
	var_pop?:ValueTypes["voyce_series_donations_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_donations_var_samp_fields"],
	variance?:ValueTypes["voyce_series_donations_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_donations_avg_fields"]: AliasType<{
	amount?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_donations". All fields are combined with a logical 'AND'. */
["voyce_series_donations_bool_exp"]: {
	_and?:ValueTypes["voyce_series_donations_bool_exp"][],
	_not?:ValueTypes["voyce_series_donations_bool_exp"],
	_or?:ValueTypes["voyce_series_donations_bool_exp"][],
	amount?:ValueTypes["numeric_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	currency?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	message?:ValueTypes["String_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_donations" */
["voyce_series_donations_constraint"]:voyce_series_donations_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_donations" */
["voyce_series_donations_inc_input"]: {
	amount?:ValueTypes["numeric"],
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.series_donations" */
["voyce_series_donations_insert_input"]: {
	amount?:ValueTypes["numeric"],
	created_at?:ValueTypes["timestamptz"],
	currency?:string,
	id?:number,
	message?:string,
	series_id?:number,
	user_id?:number
};
	/** aggregate max on columns */
["voyce_series_donations_max_fields"]: AliasType<{
	amount?:true,
	created_at?:true,
	currency?:true,
	id?:true,
	message?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_donations_min_fields"]: AliasType<{
	amount?:true,
	created_at?:true,
	currency?:true,
	id?:true,
	message?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.series_donations" */
["voyce_series_donations_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_donations"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_donations" */
["voyce_series_donations_on_conflict"]: {
	constraint:ValueTypes["voyce_series_donations_constraint"],
	update_columns:ValueTypes["voyce_series_donations_update_column"][],
	where?:ValueTypes["voyce_series_donations_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_donations". */
["voyce_series_donations_order_by"]: {
	amount?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	currency?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	message?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_donations */
["voyce_series_donations_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_donations" */
["voyce_series_donations_select_column"]:voyce_series_donations_select_column;
	/** input type for updating data in table "voyce.series_donations" */
["voyce_series_donations_set_input"]: {
	amount?:ValueTypes["numeric"],
	created_at?:ValueTypes["timestamptz"],
	currency?:string,
	id?:number,
	message?:string,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_series_donations_stddev_fields"]: AliasType<{
	amount?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_donations_stddev_pop_fields"]: AliasType<{
	amount?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_donations_stddev_samp_fields"]: AliasType<{
	amount?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_donations" */
["voyce_series_donations_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_donations_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_donations_stream_cursor_value_input"]: {
	amount?:ValueTypes["numeric"],
	created_at?:ValueTypes["timestamptz"],
	currency?:string,
	id?:number,
	message?:string,
	series_id?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_series_donations_sum_fields"]: AliasType<{
	amount?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.series_donations" */
["voyce_series_donations_update_column"]:voyce_series_donations_update_column;
	["voyce_series_donations_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_donations_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_donations_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_donations_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_donations_var_pop_fields"]: AliasType<{
	amount?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_donations_var_samp_fields"]: AliasType<{
	amount?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_donations_variance_fields"]: AliasType<{
	amount?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.series_favorites" */
["voyce_series_favorites"]: AliasType<{
	created_at?:true,
	id?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_favorites" */
["voyce_series_favorites_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_favorites_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_favorites"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_favorites" */
["voyce_series_favorites_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_favorites_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_favorites_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_favorites_max_fields"],
	min?:ValueTypes["voyce_series_favorites_min_fields"],
	stddev?:ValueTypes["voyce_series_favorites_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_favorites_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_favorites_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_favorites_sum_fields"],
	var_pop?:ValueTypes["voyce_series_favorites_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_favorites_var_samp_fields"],
	variance?:ValueTypes["voyce_series_favorites_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_favorites_avg_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_favorites". All fields are combined with a logical 'AND'. */
["voyce_series_favorites_bool_exp"]: {
	_and?:ValueTypes["voyce_series_favorites_bool_exp"][],
	_not?:ValueTypes["voyce_series_favorites_bool_exp"],
	_or?:ValueTypes["voyce_series_favorites_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_favorites" */
["voyce_series_favorites_constraint"]:voyce_series_favorites_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_favorites" */
["voyce_series_favorites_inc_input"]: {
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.series_favorites" */
["voyce_series_favorites_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	user_id?:number
};
	/** aggregate max on columns */
["voyce_series_favorites_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_favorites_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.series_favorites" */
["voyce_series_favorites_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_favorites"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_favorites" */
["voyce_series_favorites_on_conflict"]: {
	constraint:ValueTypes["voyce_series_favorites_constraint"],
	update_columns:ValueTypes["voyce_series_favorites_update_column"][],
	where?:ValueTypes["voyce_series_favorites_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_favorites". */
["voyce_series_favorites_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_favorites */
["voyce_series_favorites_pk_columns_input"]: {
	id:number,
	series_id:number
};
	/** select columns of table "voyce.series_favorites" */
["voyce_series_favorites_select_column"]:voyce_series_favorites_select_column;
	/** input type for updating data in table "voyce.series_favorites" */
["voyce_series_favorites_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_series_favorites_stddev_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_favorites_stddev_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_favorites_stddev_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_favorites" */
["voyce_series_favorites_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_favorites_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_favorites_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_series_favorites_sum_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.series_favorites" */
["voyce_series_favorites_update_column"]:voyce_series_favorites_update_column;
	["voyce_series_favorites_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_favorites_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_favorites_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_favorites_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_favorites_var_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_favorites_var_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_favorites_variance_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.series_featured_panels" */
["voyce_series_featured_panels"]: AliasType<{
	id?:true,
	image?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_featured_panels" */
["voyce_series_featured_panels_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_featured_panels_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_featured_panels"],
		__typename?: true
}>;
	["voyce_series_featured_panels_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_series_featured_panels_aggregate_bool_exp_count"]
};
	["voyce_series_featured_panels_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_series_featured_panels_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_series_featured_panels_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.series_featured_panels" */
["voyce_series_featured_panels_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_featured_panels_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_featured_panels_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_featured_panels_max_fields"],
	min?:ValueTypes["voyce_series_featured_panels_min_fields"],
	stddev?:ValueTypes["voyce_series_featured_panels_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_featured_panels_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_featured_panels_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_featured_panels_sum_fields"],
	var_pop?:ValueTypes["voyce_series_featured_panels_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_featured_panels_var_samp_fields"],
	variance?:ValueTypes["voyce_series_featured_panels_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_series_featured_panels_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_series_featured_panels_max_order_by"],
	min?:ValueTypes["voyce_series_featured_panels_min_order_by"],
	stddev?:ValueTypes["voyce_series_featured_panels_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_series_featured_panels_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_series_featured_panels_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_series_featured_panels_sum_order_by"],
	var_pop?:ValueTypes["voyce_series_featured_panels_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_series_featured_panels_var_samp_order_by"],
	variance?:ValueTypes["voyce_series_featured_panels_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.series_featured_panels" */
["voyce_series_featured_panels_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_series_featured_panels_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_featured_panels_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_series_featured_panels_avg_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.series_featured_panels". All fields are combined with a logical 'AND'. */
["voyce_series_featured_panels_bool_exp"]: {
	_and?:ValueTypes["voyce_series_featured_panels_bool_exp"][],
	_not?:ValueTypes["voyce_series_featured_panels_bool_exp"],
	_or?:ValueTypes["voyce_series_featured_panels_bool_exp"][],
	id?:ValueTypes["Int_comparison_exp"],
	image?:ValueTypes["String_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_featured_panels" */
["voyce_series_featured_panels_constraint"]:voyce_series_featured_panels_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_featured_panels" */
["voyce_series_featured_panels_inc_input"]: {
	id?:number,
	series_id?:number
};
	/** input type for inserting data into table "voyce.series_featured_panels" */
["voyce_series_featured_panels_insert_input"]: {
	id?:number,
	image?:string,
	series_id?:number
};
	/** aggregate max on columns */
["voyce_series_featured_panels_max_fields"]: AliasType<{
	id?:true,
	image?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_max_order_by"]: {
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_series_featured_panels_min_fields"]: AliasType<{
	id?:true,
	image?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_min_order_by"]: {
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.series_featured_panels" */
["voyce_series_featured_panels_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_featured_panels"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_featured_panels" */
["voyce_series_featured_panels_on_conflict"]: {
	constraint:ValueTypes["voyce_series_featured_panels_constraint"],
	update_columns:ValueTypes["voyce_series_featured_panels_update_column"][],
	where?:ValueTypes["voyce_series_featured_panels_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_featured_panels". */
["voyce_series_featured_panels_order_by"]: {
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_featured_panels */
["voyce_series_featured_panels_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_select_column"]:voyce_series_featured_panels_select_column;
	/** input type for updating data in table "voyce.series_featured_panels" */
["voyce_series_featured_panels_set_input"]: {
	id?:number,
	image?:string,
	series_id?:number
};
	/** aggregate stddev on columns */
["voyce_series_featured_panels_stddev_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_series_featured_panels_stddev_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_series_featured_panels_stddev_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_series_featured_panels" */
["voyce_series_featured_panels_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_featured_panels_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_featured_panels_stream_cursor_value_input"]: {
	id?:number,
	image?:string,
	series_id?:number
};
	/** aggregate sum on columns */
["voyce_series_featured_panels_sum_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_update_column"]:voyce_series_featured_panels_update_column;
	["voyce_series_featured_panels_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_featured_panels_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_featured_panels_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_featured_panels_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_featured_panels_var_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_series_featured_panels_var_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_series_featured_panels_variance_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.series_genre" */
["voyce_series_genre"]: AliasType<{
	created_at?:true,
	/** An object relationship */
	genre?:ValueTypes["voyce_genres"],
	genre_id?:true,
	id?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	sub_genre?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_genre" */
["voyce_series_genre_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_genre_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_genre"],
		__typename?: true
}>;
	["voyce_series_genre_aggregate_bool_exp"]: {
	bool_and?:ValueTypes["voyce_series_genre_aggregate_bool_exp_bool_and"],
	bool_or?:ValueTypes["voyce_series_genre_aggregate_bool_exp_bool_or"],
	count?:ValueTypes["voyce_series_genre_aggregate_bool_exp_count"]
};
	["voyce_series_genre_aggregate_bool_exp_bool_and"]: {
	arguments:ValueTypes["voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_series_genre_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_series_genre_aggregate_bool_exp_bool_or"]: {
	arguments:ValueTypes["voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_series_genre_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_series_genre_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_series_genre_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_series_genre_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.series_genre" */
["voyce_series_genre_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_genre_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_genre_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_genre_max_fields"],
	min?:ValueTypes["voyce_series_genre_min_fields"],
	stddev?:ValueTypes["voyce_series_genre_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_genre_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_genre_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_genre_sum_fields"],
	var_pop?:ValueTypes["voyce_series_genre_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_genre_var_samp_fields"],
	variance?:ValueTypes["voyce_series_genre_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.series_genre" */
["voyce_series_genre_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_series_genre_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_series_genre_max_order_by"],
	min?:ValueTypes["voyce_series_genre_min_order_by"],
	stddev?:ValueTypes["voyce_series_genre_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_series_genre_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_series_genre_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_series_genre_sum_order_by"],
	var_pop?:ValueTypes["voyce_series_genre_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_series_genre_var_samp_order_by"],
	variance?:ValueTypes["voyce_series_genre_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.series_genre" */
["voyce_series_genre_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_series_genre_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_genre_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_series_genre_avg_fields"]: AliasType<{
	genre_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.series_genre" */
["voyce_series_genre_avg_order_by"]: {
	genre_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.series_genre". All fields are combined with a logical 'AND'. */
["voyce_series_genre_bool_exp"]: {
	_and?:ValueTypes["voyce_series_genre_bool_exp"][],
	_not?:ValueTypes["voyce_series_genre_bool_exp"],
	_or?:ValueTypes["voyce_series_genre_bool_exp"][],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	genre?:ValueTypes["voyce_genres_bool_exp"],
	genre_id?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	sub_genre?:ValueTypes["Boolean_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_genre" */
["voyce_series_genre_constraint"]:voyce_series_genre_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_genre" */
["voyce_series_genre_inc_input"]: {
	genre_id?:number,
	id?:number,
	series_id?:number
};
	/** input type for inserting data into table "voyce.series_genre" */
["voyce_series_genre_insert_input"]: {
	created_at?:ValueTypes["timestamp"],
	genre?:ValueTypes["voyce_genres_obj_rel_insert_input"],
	genre_id?:number,
	id?:number,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	sub_genre?:boolean
};
	/** aggregate max on columns */
["voyce_series_genre_max_fields"]: AliasType<{
	created_at?:true,
	genre_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.series_genre" */
["voyce_series_genre_max_order_by"]: {
	created_at?:ValueTypes["order_by"],
	genre_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_series_genre_min_fields"]: AliasType<{
	created_at?:true,
	genre_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.series_genre" */
["voyce_series_genre_min_order_by"]: {
	created_at?:ValueTypes["order_by"],
	genre_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.series_genre" */
["voyce_series_genre_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_genre"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_genre" */
["voyce_series_genre_on_conflict"]: {
	constraint:ValueTypes["voyce_series_genre_constraint"],
	update_columns:ValueTypes["voyce_series_genre_update_column"][],
	where?:ValueTypes["voyce_series_genre_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_genre". */
["voyce_series_genre_order_by"]: {
	created_at?:ValueTypes["order_by"],
	genre?:ValueTypes["voyce_genres_order_by"],
	genre_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	sub_genre?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_genre */
["voyce_series_genre_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_genre" */
["voyce_series_genre_select_column"]:voyce_series_genre_select_column;
	/** select "voyce_series_genre_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.series_genre" */
["voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_and_arguments_columns"]:voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_series_genre_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.series_genre" */
["voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_or_arguments_columns"]:voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.series_genre" */
["voyce_series_genre_set_input"]: {
	created_at?:ValueTypes["timestamp"],
	genre_id?:number,
	id?:number,
	series_id?:number,
	sub_genre?:boolean
};
	/** aggregate stddev on columns */
["voyce_series_genre_stddev_fields"]: AliasType<{
	genre_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.series_genre" */
["voyce_series_genre_stddev_order_by"]: {
	genre_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_series_genre_stddev_pop_fields"]: AliasType<{
	genre_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.series_genre" */
["voyce_series_genre_stddev_pop_order_by"]: {
	genre_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_series_genre_stddev_samp_fields"]: AliasType<{
	genre_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.series_genre" */
["voyce_series_genre_stddev_samp_order_by"]: {
	genre_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_series_genre" */
["voyce_series_genre_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_genre_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_genre_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamp"],
	genre_id?:number,
	id?:number,
	series_id?:number,
	sub_genre?:boolean
};
	/** aggregate sum on columns */
["voyce_series_genre_sum_fields"]: AliasType<{
	genre_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.series_genre" */
["voyce_series_genre_sum_order_by"]: {
	genre_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.series_genre" */
["voyce_series_genre_update_column"]:voyce_series_genre_update_column;
	["voyce_series_genre_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_genre_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_genre_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_genre_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_genre_var_pop_fields"]: AliasType<{
	genre_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.series_genre" */
["voyce_series_genre_var_pop_order_by"]: {
	genre_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_series_genre_var_samp_fields"]: AliasType<{
	genre_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.series_genre" */
["voyce_series_genre_var_samp_order_by"]: {
	genre_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_series_genre_variance_fields"]: AliasType<{
	genre_id?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.series_genre" */
["voyce_series_genre_variance_order_by"]: {
	genre_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants"]: AliasType<{
	created_at?:true,
	email?:true,
	first_name?:true,
	from_series?:true,
	id?:true,
	last_name?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_giveaway_participants_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_giveaway_participants"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_giveaway_participants_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_giveaway_participants_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_giveaway_participants_max_fields"],
	min?:ValueTypes["voyce_series_giveaway_participants_min_fields"],
	stddev?:ValueTypes["voyce_series_giveaway_participants_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_giveaway_participants_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_giveaway_participants_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_giveaway_participants_sum_fields"],
	var_pop?:ValueTypes["voyce_series_giveaway_participants_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_giveaway_participants_var_samp_fields"],
	variance?:ValueTypes["voyce_series_giveaway_participants_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_giveaway_participants_avg_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_giveaway_participants". All fields are combined with a logical 'AND'. */
["voyce_series_giveaway_participants_bool_exp"]: {
	_and?:ValueTypes["voyce_series_giveaway_participants_bool_exp"][],
	_not?:ValueTypes["voyce_series_giveaway_participants_bool_exp"],
	_or?:ValueTypes["voyce_series_giveaway_participants_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	email?:ValueTypes["String_comparison_exp"],
	first_name?:ValueTypes["String_comparison_exp"],
	from_series?:ValueTypes["Boolean_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	last_name?:ValueTypes["String_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_constraint"]:voyce_series_giveaway_participants_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_inc_input"]: {
	id?:number,
	series_id?:number
};
	/** input type for inserting data into table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	email?:string,
	first_name?:string,
	from_series?:boolean,
	id?:number,
	last_name?:string,
	series_id?:number
};
	/** aggregate max on columns */
["voyce_series_giveaway_participants_max_fields"]: AliasType<{
	created_at?:true,
	email?:true,
	first_name?:true,
	id?:true,
	last_name?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_giveaway_participants_min_fields"]: AliasType<{
	created_at?:true,
	email?:true,
	first_name?:true,
	id?:true,
	last_name?:true,
	series_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_giveaway_participants"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_on_conflict"]: {
	constraint:ValueTypes["voyce_series_giveaway_participants_constraint"],
	update_columns:ValueTypes["voyce_series_giveaway_participants_update_column"][],
	where?:ValueTypes["voyce_series_giveaway_participants_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_giveaway_participants". */
["voyce_series_giveaway_participants_order_by"]: {
	created_at?:ValueTypes["order_by"],
	email?:ValueTypes["order_by"],
	first_name?:ValueTypes["order_by"],
	from_series?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	last_name?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_giveaway_participants */
["voyce_series_giveaway_participants_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_select_column"]:voyce_series_giveaway_participants_select_column;
	/** input type for updating data in table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	email?:string,
	first_name?:string,
	from_series?:boolean,
	id?:number,
	last_name?:string,
	series_id?:number
};
	/** aggregate stddev on columns */
["voyce_series_giveaway_participants_stddev_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_giveaway_participants_stddev_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_giveaway_participants_stddev_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_giveaway_participants" */
["voyce_series_giveaway_participants_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_giveaway_participants_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_giveaway_participants_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	email?:string,
	first_name?:string,
	from_series?:boolean,
	id?:number,
	last_name?:string,
	series_id?:number
};
	/** aggregate sum on columns */
["voyce_series_giveaway_participants_sum_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_update_column"]:voyce_series_giveaway_participants_update_column;
	["voyce_series_giveaway_participants_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_giveaway_participants_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_giveaway_participants_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_giveaway_participants_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_giveaway_participants_var_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_giveaway_participants_var_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_giveaway_participants_variance_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.series_hot_panels" */
["voyce_series_hot_panels"]: AliasType<{
	active?:true,
	created_at?:true,
	id?:true,
	image?:true,
	order?:true,
	series_id?:true,
	spoiler?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_hot_panels" */
["voyce_series_hot_panels_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_hot_panels_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_hot_panels"],
		__typename?: true
}>;
	["voyce_series_hot_panels_aggregate_bool_exp"]: {
	bool_and?:ValueTypes["voyce_series_hot_panels_aggregate_bool_exp_bool_and"],
	bool_or?:ValueTypes["voyce_series_hot_panels_aggregate_bool_exp_bool_or"],
	count?:ValueTypes["voyce_series_hot_panels_aggregate_bool_exp_count"]
};
	["voyce_series_hot_panels_aggregate_bool_exp_bool_and"]: {
	arguments:ValueTypes["voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_series_hot_panels_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_series_hot_panels_aggregate_bool_exp_bool_or"]: {
	arguments:ValueTypes["voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_series_hot_panels_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_series_hot_panels_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_series_hot_panels_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_series_hot_panels_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.series_hot_panels" */
["voyce_series_hot_panels_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_hot_panels_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_hot_panels_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_hot_panels_max_fields"],
	min?:ValueTypes["voyce_series_hot_panels_min_fields"],
	stddev?:ValueTypes["voyce_series_hot_panels_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_hot_panels_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_hot_panels_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_hot_panels_sum_fields"],
	var_pop?:ValueTypes["voyce_series_hot_panels_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_hot_panels_var_samp_fields"],
	variance?:ValueTypes["voyce_series_hot_panels_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_series_hot_panels_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_series_hot_panels_max_order_by"],
	min?:ValueTypes["voyce_series_hot_panels_min_order_by"],
	stddev?:ValueTypes["voyce_series_hot_panels_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_series_hot_panels_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_series_hot_panels_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_series_hot_panels_sum_order_by"],
	var_pop?:ValueTypes["voyce_series_hot_panels_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_series_hot_panels_var_samp_order_by"],
	variance?:ValueTypes["voyce_series_hot_panels_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.series_hot_panels" */
["voyce_series_hot_panels_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_series_hot_panels_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_hot_panels_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_series_hot_panels_avg_fields"]: AliasType<{
	id?:true,
	order?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.series_hot_panels". All fields are combined with a logical 'AND'. */
["voyce_series_hot_panels_bool_exp"]: {
	_and?:ValueTypes["voyce_series_hot_panels_bool_exp"][],
	_not?:ValueTypes["voyce_series_hot_panels_bool_exp"],
	_or?:ValueTypes["voyce_series_hot_panels_bool_exp"][],
	active?:ValueTypes["Boolean_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	image?:ValueTypes["String_comparison_exp"],
	order?:ValueTypes["smallint_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	spoiler?:ValueTypes["Boolean_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_hot_panels" */
["voyce_series_hot_panels_constraint"]:voyce_series_hot_panels_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_hot_panels" */
["voyce_series_hot_panels_inc_input"]: {
	id?:number,
	order?:ValueTypes["smallint"],
	series_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.series_hot_panels" */
["voyce_series_hot_panels_insert_input"]: {
	active?:boolean,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	image?:string,
	order?:ValueTypes["smallint"],
	series_id?:number,
	spoiler?:boolean,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_series_hot_panels_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	image?:true,
	order?:true,
	series_id?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_max_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_series_hot_panels_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	image?:true,
	order?:true,
	series_id?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_min_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.series_hot_panels" */
["voyce_series_hot_panels_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_hot_panels"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_hot_panels" */
["voyce_series_hot_panels_on_conflict"]: {
	constraint:ValueTypes["voyce_series_hot_panels_constraint"],
	update_columns:ValueTypes["voyce_series_hot_panels_update_column"][],
	where?:ValueTypes["voyce_series_hot_panels_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_hot_panels". */
["voyce_series_hot_panels_order_by"]: {
	active?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	image?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	spoiler?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_hot_panels */
["voyce_series_hot_panels_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_select_column"]:voyce_series_hot_panels_select_column;
	/** select "voyce_series_hot_panels_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_and_arguments_columns"]:voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_series_hot_panels_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_or_arguments_columns"]:voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.series_hot_panels" */
["voyce_series_hot_panels_set_input"]: {
	active?:boolean,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	image?:string,
	order?:ValueTypes["smallint"],
	series_id?:number,
	spoiler?:boolean,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_series_hot_panels_stddev_fields"]: AliasType<{
	id?:true,
	order?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_series_hot_panels_stddev_pop_fields"]: AliasType<{
	id?:true,
	order?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_series_hot_panels_stddev_samp_fields"]: AliasType<{
	id?:true,
	order?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_series_hot_panels" */
["voyce_series_hot_panels_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_hot_panels_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_hot_panels_stream_cursor_value_input"]: {
	active?:boolean,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	image?:string,
	order?:ValueTypes["smallint"],
	series_id?:number,
	spoiler?:boolean,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_series_hot_panels_sum_fields"]: AliasType<{
	id?:true,
	order?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_update_column"]:voyce_series_hot_panels_update_column;
	["voyce_series_hot_panels_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_hot_panels_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_hot_panels_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_hot_panels_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_hot_panels_var_pop_fields"]: AliasType<{
	id?:true,
	order?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_series_hot_panels_var_samp_fields"]: AliasType<{
	id?:true,
	order?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_series_hot_panels_variance_fields"]: AliasType<{
	id?:true,
	order?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** input type for incrementing numeric columns in table "voyce.series" */
["voyce_series_inc_input"]: {
	average_rating?:ValueTypes["float8"],
	chapter_count?:number,
	coin_price?:ValueTypes["smallint"],
	comic_type_id?:number,
	frequency_mask?:number,
	id?:number,
	is_approved?:ValueTypes["smallint"],
	is_intro?:ValueTypes["smallint"],
	is_original?:ValueTypes["smallint"],
	is_pilots?:ValueTypes["smallint"],
	is_recommended?:ValueTypes["smallint"],
	locked_chapters_amount?:number,
	pilots_votes_count?:number,
	publish?:ValueTypes["smallint"],
	rating_count?:number,
	subscriber_count?:number,
	trending_position?:number,
	user_id?:number,
	view_count?:number
};
	/** input type for inserting data into table "voyce.series" */
["voyce_series_insert_input"]: {
	animated_cover_image?:string,
	audio_allowed?:boolean,
	author?:ValueTypes["voyce_users_obj_rel_insert_input"],
	average_rating?:ValueTypes["float8"],
	back_image?:string,
	banner_bg?:string,
	book_reverse_view?:boolean,
	book_view?:boolean,
	chapter_comments?:ValueTypes["voyce_chapter_comments_arr_rel_insert_input"],
	chapter_count?:number,
	chapter_panels?:ValueTypes["voyce_chapter_panels_arr_rel_insert_input"],
	chapters?:ValueTypes["voyce_chapters_arr_rel_insert_input"],
	chapters_hide?:string,
	character_cover?:string,
	coin_price?:ValueTypes["smallint"],
	collaborators?:ValueTypes["voyce_series_collaborators_arr_rel_insert_input"],
	comic_type_id?:number,
	cover_image?:string,
	cover_image_mobile?:string,
	created_at?:ValueTypes["timestamp"],
	csat?:boolean,
	default_view?:string,
	description?:string,
	feature?:ValueTypes["bit"],
	featured_panels?:ValueTypes["voyce_series_featured_panels_arr_rel_insert_input"],
	followers?:ValueTypes["voyce_series_subscription_arr_rel_insert_input"],
	frequency?:string,
	frequency_mask?:number,
	front_image?:string,
	genres?:ValueTypes["voyce_series_genre_arr_rel_insert_input"],
	hot_panels?:ValueTypes["voyce_series_hot_panels_arr_rel_insert_input"],
	id?:number,
	is_animated_cover_allowed?:boolean,
	is_approved?:ValueTypes["smallint"],
	is_deleted?:boolean,
	is_intro?:ValueTypes["smallint"],
	is_locked?:boolean,
	is_original?:ValueTypes["smallint"],
	is_pilots?:ValueTypes["smallint"],
	is_recommended?:ValueTypes["smallint"],
	klaviyo_id?:string,
	klaviyo_list_id?:string,
	latest_chapter_update?:ValueTypes["timestamp"],
	likes?:ValueTypes["voyce_chapter_likes_arr_rel_insert_input"],
	likes_count?:ValueTypes["voyce_series_likes_count_mat_obj_rel_insert_input"],
	lock_config?:ValueTypes["voyce_series_lock_config_obj_rel_insert_input"],
	locked?:boolean,
	locked_chapters_amount?:number,
	notification_settings?:ValueTypes["voyce_notification_series_settings_obj_rel_insert_input"],
	paginated_view?:boolean,
	pilots_votes_count?:number,
	portfolio_desc?:string,
	publish?:ValueTypes["smallint"],
	rating_count?:number,
	reading_view?:string,
	scroll_view?:boolean,
	series_tags_junctions?:ValueTypes["voyce_series_tags_junction_arr_rel_insert_input"],
	short_desc?:string,
	slug?:string,
	sponsorship_config?:ValueTypes["voyce_series_sponsorships_configuration_obj_rel_insert_input"],
	sponsorships?:ValueTypes["voyce_sponsorships_arr_rel_insert_input"],
	status?:string,
	subscriber_count?:number,
	support_msg?:string,
	team_spotlight?:boolean,
	thumbnail?:string,
	thumbnail_box?:string,
	title?:string,
	trending?:ValueTypes["bit"],
	trending_position?:number,
	type?:ValueTypes["voyce_comic_types_obj_rel_insert_input"],
	updated_at?:ValueTypes["timestamp"],
	user_display_field?:string,
	user_id?:number,
	users_series_junctions?:ValueTypes["voyce_users_series_junction_arr_rel_insert_input"],
	view_count?:number,
	views?:ValueTypes["voyce_chapter_views_arr_rel_insert_input"],
	views_counts?:ValueTypes["voyce_pg_rs_series_views_count_obj_rel_insert_input"],
	welcome_msg?:string
};
	/** columns and relationships of "voyce.series_layouts" */
["voyce_series_layouts"]: AliasType<{
	active?:true,
	bg_image?:true,
characters?: [{	/** JSON select path */
	path?:string},true],
comments?: [{	/** JSON select path */
	path?:string},true],
dl_banner?: [{	/** JSON select path */
	path?:string},true],
	id?:true,
	layout?:true,
	logo?:true,
menu?: [{	/** JSON select path */
	path?:string},true],
	series_id?:true,
socials?: [{	/** JSON select path */
	path?:string},true],
story?: [{	/** JSON select path */
	path?:string},true],
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_layouts" */
["voyce_series_layouts_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_layouts_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_layouts"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_layouts" */
["voyce_series_layouts_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_layouts_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_layouts_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_layouts_max_fields"],
	min?:ValueTypes["voyce_series_layouts_min_fields"],
	stddev?:ValueTypes["voyce_series_layouts_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_layouts_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_layouts_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_layouts_sum_fields"],
	var_pop?:ValueTypes["voyce_series_layouts_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_layouts_var_samp_fields"],
	variance?:ValueTypes["voyce_series_layouts_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_layouts_avg_fields"]: AliasType<{
	id?:true,
	layout?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_layouts". All fields are combined with a logical 'AND'. */
["voyce_series_layouts_bool_exp"]: {
	_and?:ValueTypes["voyce_series_layouts_bool_exp"][],
	_not?:ValueTypes["voyce_series_layouts_bool_exp"],
	_or?:ValueTypes["voyce_series_layouts_bool_exp"][],
	active?:ValueTypes["Boolean_comparison_exp"],
	bg_image?:ValueTypes["String_comparison_exp"],
	characters?:ValueTypes["json_comparison_exp"],
	comments?:ValueTypes["json_comparison_exp"],
	dl_banner?:ValueTypes["json_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	layout?:ValueTypes["smallint_comparison_exp"],
	logo?:ValueTypes["String_comparison_exp"],
	menu?:ValueTypes["json_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	socials?:ValueTypes["json_comparison_exp"],
	story?:ValueTypes["json_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_layouts" */
["voyce_series_layouts_constraint"]:voyce_series_layouts_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_layouts" */
["voyce_series_layouts_inc_input"]: {
	id?:number,
	layout?:ValueTypes["smallint"],
	series_id?:number
};
	/** input type for inserting data into table "voyce.series_layouts" */
["voyce_series_layouts_insert_input"]: {
	active?:boolean,
	bg_image?:string,
	characters?:ValueTypes["json"],
	comments?:ValueTypes["json"],
	dl_banner?:ValueTypes["json"],
	id?:number,
	layout?:ValueTypes["smallint"],
	logo?:string,
	menu?:ValueTypes["json"],
	series_id?:number,
	socials?:ValueTypes["json"],
	story?:ValueTypes["json"]
};
	/** aggregate max on columns */
["voyce_series_layouts_max_fields"]: AliasType<{
	bg_image?:true,
	id?:true,
	layout?:true,
	logo?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_layouts_min_fields"]: AliasType<{
	bg_image?:true,
	id?:true,
	layout?:true,
	logo?:true,
	series_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.series_layouts" */
["voyce_series_layouts_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_layouts"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_layouts" */
["voyce_series_layouts_on_conflict"]: {
	constraint:ValueTypes["voyce_series_layouts_constraint"],
	update_columns:ValueTypes["voyce_series_layouts_update_column"][],
	where?:ValueTypes["voyce_series_layouts_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_layouts". */
["voyce_series_layouts_order_by"]: {
	active?:ValueTypes["order_by"],
	bg_image?:ValueTypes["order_by"],
	characters?:ValueTypes["order_by"],
	comments?:ValueTypes["order_by"],
	dl_banner?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	layout?:ValueTypes["order_by"],
	logo?:ValueTypes["order_by"],
	menu?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	socials?:ValueTypes["order_by"],
	story?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_layouts */
["voyce_series_layouts_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_layouts" */
["voyce_series_layouts_select_column"]:voyce_series_layouts_select_column;
	/** input type for updating data in table "voyce.series_layouts" */
["voyce_series_layouts_set_input"]: {
	active?:boolean,
	bg_image?:string,
	characters?:ValueTypes["json"],
	comments?:ValueTypes["json"],
	dl_banner?:ValueTypes["json"],
	id?:number,
	layout?:ValueTypes["smallint"],
	logo?:string,
	menu?:ValueTypes["json"],
	series_id?:number,
	socials?:ValueTypes["json"],
	story?:ValueTypes["json"]
};
	/** aggregate stddev on columns */
["voyce_series_layouts_stddev_fields"]: AliasType<{
	id?:true,
	layout?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_layouts_stddev_pop_fields"]: AliasType<{
	id?:true,
	layout?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_layouts_stddev_samp_fields"]: AliasType<{
	id?:true,
	layout?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_layouts" */
["voyce_series_layouts_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_layouts_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_layouts_stream_cursor_value_input"]: {
	active?:boolean,
	bg_image?:string,
	characters?:ValueTypes["json"],
	comments?:ValueTypes["json"],
	dl_banner?:ValueTypes["json"],
	id?:number,
	layout?:ValueTypes["smallint"],
	logo?:string,
	menu?:ValueTypes["json"],
	series_id?:number,
	socials?:ValueTypes["json"],
	story?:ValueTypes["json"]
};
	/** aggregate sum on columns */
["voyce_series_layouts_sum_fields"]: AliasType<{
	id?:true,
	layout?:true,
	series_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.series_layouts" */
["voyce_series_layouts_update_column"]:voyce_series_layouts_update_column;
	["voyce_series_layouts_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_layouts_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_layouts_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_layouts_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_layouts_var_pop_fields"]: AliasType<{
	id?:true,
	layout?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_layouts_var_samp_fields"]: AliasType<{
	id?:true,
	layout?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_layouts_variance_fields"]: AliasType<{
	id?:true,
	layout?:true,
	series_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.series_leaderboard" */
["voyce_series_leaderboard"]: AliasType<{
	amount?:true,
	arrow?:true,
	created_at?:true,
	currency?:true,
	date?:true,
	id?:true,
	rank?:true,
	series_id?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_leaderboard" */
["voyce_series_leaderboard_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_leaderboard_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_leaderboard"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_leaderboard" */
["voyce_series_leaderboard_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_leaderboard_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_leaderboard_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_leaderboard_max_fields"],
	min?:ValueTypes["voyce_series_leaderboard_min_fields"],
	stddev?:ValueTypes["voyce_series_leaderboard_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_leaderboard_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_leaderboard_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_leaderboard_sum_fields"],
	var_pop?:ValueTypes["voyce_series_leaderboard_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_leaderboard_var_samp_fields"],
	variance?:ValueTypes["voyce_series_leaderboard_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_leaderboard_avg_fields"]: AliasType<{
	amount?:true,
	id?:true,
	rank?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_leaderboard". All fields are combined with a logical 'AND'. */
["voyce_series_leaderboard_bool_exp"]: {
	_and?:ValueTypes["voyce_series_leaderboard_bool_exp"][],
	_not?:ValueTypes["voyce_series_leaderboard_bool_exp"],
	_or?:ValueTypes["voyce_series_leaderboard_bool_exp"][],
	amount?:ValueTypes["numeric_comparison_exp"],
	arrow?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	currency?:ValueTypes["String_comparison_exp"],
	date?:ValueTypes["date_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	rank?:ValueTypes["Int_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_leaderboard" */
["voyce_series_leaderboard_constraint"]:voyce_series_leaderboard_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_leaderboard" */
["voyce_series_leaderboard_inc_input"]: {
	amount?:ValueTypes["numeric"],
	id?:number,
	rank?:number,
	series_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.series_leaderboard" */
["voyce_series_leaderboard_insert_input"]: {
	amount?:ValueTypes["numeric"],
	arrow?:string,
	created_at?:ValueTypes["timestamptz"],
	currency?:string,
	date?:ValueTypes["date"],
	id?:number,
	rank?:number,
	series_id?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_series_leaderboard_max_fields"]: AliasType<{
	amount?:true,
	arrow?:true,
	created_at?:true,
	currency?:true,
	date?:true,
	id?:true,
	rank?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_leaderboard_min_fields"]: AliasType<{
	amount?:true,
	arrow?:true,
	created_at?:true,
	currency?:true,
	date?:true,
	id?:true,
	rank?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.series_leaderboard" */
["voyce_series_leaderboard_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_leaderboard"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_leaderboard" */
["voyce_series_leaderboard_on_conflict"]: {
	constraint:ValueTypes["voyce_series_leaderboard_constraint"],
	update_columns:ValueTypes["voyce_series_leaderboard_update_column"][],
	where?:ValueTypes["voyce_series_leaderboard_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_leaderboard". */
["voyce_series_leaderboard_order_by"]: {
	amount?:ValueTypes["order_by"],
	arrow?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	currency?:ValueTypes["order_by"],
	date?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	rank?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_leaderboard */
["voyce_series_leaderboard_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_leaderboard" */
["voyce_series_leaderboard_select_column"]:voyce_series_leaderboard_select_column;
	/** input type for updating data in table "voyce.series_leaderboard" */
["voyce_series_leaderboard_set_input"]: {
	amount?:ValueTypes["numeric"],
	arrow?:string,
	created_at?:ValueTypes["timestamptz"],
	currency?:string,
	date?:ValueTypes["date"],
	id?:number,
	rank?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_series_leaderboard_stddev_fields"]: AliasType<{
	amount?:true,
	id?:true,
	rank?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_leaderboard_stddev_pop_fields"]: AliasType<{
	amount?:true,
	id?:true,
	rank?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_leaderboard_stddev_samp_fields"]: AliasType<{
	amount?:true,
	id?:true,
	rank?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_leaderboard" */
["voyce_series_leaderboard_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_leaderboard_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_leaderboard_stream_cursor_value_input"]: {
	amount?:ValueTypes["numeric"],
	arrow?:string,
	created_at?:ValueTypes["timestamptz"],
	currency?:string,
	date?:ValueTypes["date"],
	id?:number,
	rank?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_series_leaderboard_sum_fields"]: AliasType<{
	amount?:true,
	id?:true,
	rank?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.series_leaderboard" */
["voyce_series_leaderboard_update_column"]:voyce_series_leaderboard_update_column;
	["voyce_series_leaderboard_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_leaderboard_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_leaderboard_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_leaderboard_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_leaderboard_var_pop_fields"]: AliasType<{
	amount?:true,
	id?:true,
	rank?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_leaderboard_var_samp_fields"]: AliasType<{
	amount?:true,
	id?:true,
	rank?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_leaderboard_variance_fields"]: AliasType<{
	amount?:true,
	id?:true,
	rank?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.series_likes_count" */
["voyce_series_likes_count"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_likes_count" */
["voyce_series_likes_count_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_likes_count_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_likes_count"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_likes_count" */
["voyce_series_likes_count_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_likes_count_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_likes_count_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_likes_count_max_fields"],
	min?:ValueTypes["voyce_series_likes_count_min_fields"],
	stddev?:ValueTypes["voyce_series_likes_count_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_likes_count_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_likes_count_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_likes_count_sum_fields"],
	var_pop?:ValueTypes["voyce_series_likes_count_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_likes_count_var_samp_fields"],
	variance?:ValueTypes["voyce_series_likes_count_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_likes_count_avg_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_likes_count". All fields are combined with a logical 'AND'. */
["voyce_series_likes_count_bool_exp"]: {
	_and?:ValueTypes["voyce_series_likes_count_bool_exp"][],
	_not?:ValueTypes["voyce_series_likes_count_bool_exp"],
	_or?:ValueTypes["voyce_series_likes_count_bool_exp"][],
	count?:ValueTypes["bigint_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"]
};
	/** columns and relationships of "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_likes_count_mat_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_likes_count_mat"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_likes_count_mat_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_likes_count_mat_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_likes_count_mat_max_fields"],
	min?:ValueTypes["voyce_series_likes_count_mat_min_fields"],
	stddev?:ValueTypes["voyce_series_likes_count_mat_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_likes_count_mat_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_likes_count_mat_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_likes_count_mat_sum_fields"],
	var_pop?:ValueTypes["voyce_series_likes_count_mat_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_likes_count_mat_var_samp_fields"],
	variance?:ValueTypes["voyce_series_likes_count_mat_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_likes_count_mat_avg_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_likes_count_mat". All fields are combined with a logical 'AND'. */
["voyce_series_likes_count_mat_bool_exp"]: {
	_and?:ValueTypes["voyce_series_likes_count_mat_bool_exp"][],
	_not?:ValueTypes["voyce_series_likes_count_mat_bool_exp"],
	_or?:ValueTypes["voyce_series_likes_count_mat_bool_exp"][],
	actual_count?:ValueTypes["bigint_comparison_exp"],
	count?:ValueTypes["float8_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"]
};
	/** input type for inserting data into table "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat_insert_input"]: {
	actual_count?:ValueTypes["bigint"],
	count?:ValueTypes["float8"],
	series_id?:number
};
	/** aggregate max on columns */
["voyce_series_likes_count_mat_max_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_likes_count_mat_min_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_series_likes_count_mat_insert_input"]
};
	/** Ordering options when selecting data from "voyce.series_likes_count_mat". */
["voyce_series_likes_count_mat_order_by"]: {
	actual_count?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat_select_column"]:voyce_series_likes_count_mat_select_column;
	/** aggregate stddev on columns */
["voyce_series_likes_count_mat_stddev_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_likes_count_mat_stddev_pop_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_likes_count_mat_stddev_samp_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_likes_count_mat" */
["voyce_series_likes_count_mat_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_likes_count_mat_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_likes_count_mat_stream_cursor_value_input"]: {
	actual_count?:ValueTypes["bigint"],
	count?:ValueTypes["float8"],
	series_id?:number
};
	/** aggregate sum on columns */
["voyce_series_likes_count_mat_sum_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_pop on columns */
["voyce_series_likes_count_mat_var_pop_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_likes_count_mat_var_samp_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_likes_count_mat_variance_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate max on columns */
["voyce_series_likes_count_max_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_likes_count_min_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Ordering options when selecting data from "voyce.series_likes_count". */
["voyce_series_likes_count_order_by"]: {
	count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.series_likes_count" */
["voyce_series_likes_count_select_column"]:voyce_series_likes_count_select_column;
	/** aggregate stddev on columns */
["voyce_series_likes_count_stddev_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_likes_count_stddev_pop_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_likes_count_stddev_samp_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_likes_count" */
["voyce_series_likes_count_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_likes_count_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_likes_count_stream_cursor_value_input"]: {
	count?:ValueTypes["bigint"],
	series_id?:number
};
	/** aggregate sum on columns */
["voyce_series_likes_count_sum_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_pop on columns */
["voyce_series_likes_count_var_pop_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_likes_count_var_samp_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_likes_count_variance_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.series_lock_config" */
["voyce_series_lock_config"]: AliasType<{
	buy_currency?:true,
	buy_price?:true,
	created_at?:true,
	id?:true,
	lock_duration?:true,
	lock_latest_chapters?:true,
	rent_currency?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_lock_config" */
["voyce_series_lock_config_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_lock_config_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_lock_config"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_lock_config" */
["voyce_series_lock_config_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_lock_config_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_lock_config_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_lock_config_max_fields"],
	min?:ValueTypes["voyce_series_lock_config_min_fields"],
	stddev?:ValueTypes["voyce_series_lock_config_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_lock_config_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_lock_config_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_lock_config_sum_fields"],
	var_pop?:ValueTypes["voyce_series_lock_config_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_lock_config_var_samp_fields"],
	variance?:ValueTypes["voyce_series_lock_config_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_lock_config_avg_fields"]: AliasType<{
	buy_price?:true,
	id?:true,
	lock_duration?:true,
	lock_latest_chapters?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_lock_config". All fields are combined with a logical 'AND'. */
["voyce_series_lock_config_bool_exp"]: {
	_and?:ValueTypes["voyce_series_lock_config_bool_exp"][],
	_not?:ValueTypes["voyce_series_lock_config_bool_exp"],
	_or?:ValueTypes["voyce_series_lock_config_bool_exp"][],
	buy_currency?:ValueTypes["String_comparison_exp"],
	buy_price?:ValueTypes["numeric_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	lock_duration?:ValueTypes["numeric_comparison_exp"],
	lock_latest_chapters?:ValueTypes["Int_comparison_exp"],
	rent_currency?:ValueTypes["String_comparison_exp"],
	rent_duration?:ValueTypes["numeric_comparison_exp"],
	rent_price?:ValueTypes["numeric_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_lock_config" */
["voyce_series_lock_config_constraint"]:voyce_series_lock_config_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_lock_config" */
["voyce_series_lock_config_inc_input"]: {
	buy_price?:ValueTypes["numeric"],
	id?:number,
	lock_duration?:ValueTypes["numeric"],
	lock_latest_chapters?:number,
	rent_duration?:ValueTypes["numeric"],
	rent_price?:ValueTypes["numeric"],
	series_id?:number
};
	/** input type for inserting data into table "voyce.series_lock_config" */
["voyce_series_lock_config_insert_input"]: {
	buy_currency?:string,
	buy_price?:ValueTypes["numeric"],
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	lock_duration?:ValueTypes["numeric"],
	lock_latest_chapters?:number,
	rent_currency?:string,
	rent_duration?:ValueTypes["numeric"],
	rent_price?:ValueTypes["numeric"],
	series_id?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_series_lock_config_max_fields"]: AliasType<{
	buy_currency?:true,
	buy_price?:true,
	created_at?:true,
	id?:true,
	lock_duration?:true,
	lock_latest_chapters?:true,
	rent_currency?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_lock_config_min_fields"]: AliasType<{
	buy_currency?:true,
	buy_price?:true,
	created_at?:true,
	id?:true,
	lock_duration?:true,
	lock_latest_chapters?:true,
	rent_currency?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.series_lock_config" */
["voyce_series_lock_config_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_lock_config"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.series_lock_config" */
["voyce_series_lock_config_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_series_lock_config_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_lock_config_on_conflict"]
};
	/** on_conflict condition type for table "voyce.series_lock_config" */
["voyce_series_lock_config_on_conflict"]: {
	constraint:ValueTypes["voyce_series_lock_config_constraint"],
	update_columns:ValueTypes["voyce_series_lock_config_update_column"][],
	where?:ValueTypes["voyce_series_lock_config_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_lock_config". */
["voyce_series_lock_config_order_by"]: {
	buy_currency?:ValueTypes["order_by"],
	buy_price?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	lock_duration?:ValueTypes["order_by"],
	lock_latest_chapters?:ValueTypes["order_by"],
	rent_currency?:ValueTypes["order_by"],
	rent_duration?:ValueTypes["order_by"],
	rent_price?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_lock_config */
["voyce_series_lock_config_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_lock_config" */
["voyce_series_lock_config_select_column"]:voyce_series_lock_config_select_column;
	/** input type for updating data in table "voyce.series_lock_config" */
["voyce_series_lock_config_set_input"]: {
	buy_currency?:string,
	buy_price?:ValueTypes["numeric"],
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	lock_duration?:ValueTypes["numeric"],
	lock_latest_chapters?:number,
	rent_currency?:string,
	rent_duration?:ValueTypes["numeric"],
	rent_price?:ValueTypes["numeric"],
	series_id?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_series_lock_config_stddev_fields"]: AliasType<{
	buy_price?:true,
	id?:true,
	lock_duration?:true,
	lock_latest_chapters?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_lock_config_stddev_pop_fields"]: AliasType<{
	buy_price?:true,
	id?:true,
	lock_duration?:true,
	lock_latest_chapters?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_lock_config_stddev_samp_fields"]: AliasType<{
	buy_price?:true,
	id?:true,
	lock_duration?:true,
	lock_latest_chapters?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_lock_config" */
["voyce_series_lock_config_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_lock_config_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_lock_config_stream_cursor_value_input"]: {
	buy_currency?:string,
	buy_price?:ValueTypes["numeric"],
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	lock_duration?:ValueTypes["numeric"],
	lock_latest_chapters?:number,
	rent_currency?:string,
	rent_duration?:ValueTypes["numeric"],
	rent_price?:ValueTypes["numeric"],
	series_id?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_series_lock_config_sum_fields"]: AliasType<{
	buy_price?:true,
	id?:true,
	lock_duration?:true,
	lock_latest_chapters?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.series_lock_config" */
["voyce_series_lock_config_update_column"]:voyce_series_lock_config_update_column;
	["voyce_series_lock_config_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_lock_config_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_lock_config_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_lock_config_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_lock_config_var_pop_fields"]: AliasType<{
	buy_price?:true,
	id?:true,
	lock_duration?:true,
	lock_latest_chapters?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_lock_config_var_samp_fields"]: AliasType<{
	buy_price?:true,
	id?:true,
	lock_duration?:true,
	lock_latest_chapters?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_lock_config_variance_fields"]: AliasType<{
	buy_price?:true,
	id?:true,
	lock_duration?:true,
	lock_latest_chapters?:true,
	rent_duration?:true,
	rent_price?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate max on columns */
["voyce_series_max_fields"]: AliasType<{
	animated_cover_image?:true,
	average_rating?:true,
	back_image?:true,
	banner_bg?:true,
	chapter_count?:true,
	chapters_hide?:true,
	character_cover?:true,
	coin_price?:true,
	comic_type_id?:true,
	cover_image?:true,
	cover_image_mobile?:true,
	created_at?:true,
	default_view?:true,
	description?:true,
	frequency?:true,
	frequency_mask?:true,
	front_image?:true,
	id?:true,
	is_approved?:true,
	is_intro?:true,
	is_original?:true,
	is_pilots?:true,
	is_recommended?:true,
	klaviyo_id?:true,
	klaviyo_list_id?:true,
	latest_chapter_update?:true,
	locked_chapters_amount?:true,
	pilots_votes_count?:true,
	portfolio_desc?:true,
	publish?:true,
	rating_count?:true,
	reading_view?:true,
	short_desc?:true,
	slug?:true,
	status?:true,
	subscriber_count?:true,
	support_msg?:true,
	thumbnail?:true,
	thumbnail_box?:true,
	title?:true,
	trending_position?:true,
	updated_at?:true,
	user_display_field?:true,
	user_id?:true,
	view_count?:true,
	welcome_msg?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_min_fields"]: AliasType<{
	animated_cover_image?:true,
	average_rating?:true,
	back_image?:true,
	banner_bg?:true,
	chapter_count?:true,
	chapters_hide?:true,
	character_cover?:true,
	coin_price?:true,
	comic_type_id?:true,
	cover_image?:true,
	cover_image_mobile?:true,
	created_at?:true,
	default_view?:true,
	description?:true,
	frequency?:true,
	frequency_mask?:true,
	front_image?:true,
	id?:true,
	is_approved?:true,
	is_intro?:true,
	is_original?:true,
	is_pilots?:true,
	is_recommended?:true,
	klaviyo_id?:true,
	klaviyo_list_id?:true,
	latest_chapter_update?:true,
	locked_chapters_amount?:true,
	pilots_votes_count?:true,
	portfolio_desc?:true,
	publish?:true,
	rating_count?:true,
	reading_view?:true,
	short_desc?:true,
	slug?:true,
	status?:true,
	subscriber_count?:true,
	support_msg?:true,
	thumbnail?:true,
	thumbnail_box?:true,
	title?:true,
	trending_position?:true,
	updated_at?:true,
	user_display_field?:true,
	user_id?:true,
	view_count?:true,
	welcome_msg?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.series" */
["voyce_series_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.series" */
["voyce_series_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_series_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_on_conflict"]
};
	/** on_conflict condition type for table "voyce.series" */
["voyce_series_on_conflict"]: {
	constraint:ValueTypes["voyce_series_constraint"],
	update_columns:ValueTypes["voyce_series_update_column"][],
	where?:ValueTypes["voyce_series_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series". */
["voyce_series_order_by"]: {
	animated_cover_image?:ValueTypes["order_by"],
	audio_allowed?:ValueTypes["order_by"],
	author?:ValueTypes["voyce_users_order_by"],
	average_rating?:ValueTypes["order_by"],
	back_image?:ValueTypes["order_by"],
	banner_bg?:ValueTypes["order_by"],
	book_reverse_view?:ValueTypes["order_by"],
	book_view?:ValueTypes["order_by"],
	chapter_comments_aggregate?:ValueTypes["voyce_chapter_comments_aggregate_order_by"],
	chapter_count?:ValueTypes["order_by"],
	chapter_panels_aggregate?:ValueTypes["voyce_chapter_panels_aggregate_order_by"],
	chapters_aggregate?:ValueTypes["voyce_chapters_aggregate_order_by"],
	chapters_hide?:ValueTypes["order_by"],
	character_cover?:ValueTypes["order_by"],
	coin_price?:ValueTypes["order_by"],
	collaborators_aggregate?:ValueTypes["voyce_series_collaborators_aggregate_order_by"],
	comic_type_id?:ValueTypes["order_by"],
	cover_image?:ValueTypes["order_by"],
	cover_image_mobile?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	csat?:ValueTypes["order_by"],
	default_view?:ValueTypes["order_by"],
	description?:ValueTypes["order_by"],
	feature?:ValueTypes["order_by"],
	featured_panels_aggregate?:ValueTypes["voyce_series_featured_panels_aggregate_order_by"],
	followers_aggregate?:ValueTypes["voyce_series_subscription_aggregate_order_by"],
	frequency?:ValueTypes["order_by"],
	frequency_mask?:ValueTypes["order_by"],
	front_image?:ValueTypes["order_by"],
	genres_aggregate?:ValueTypes["voyce_series_genre_aggregate_order_by"],
	hot_panels_aggregate?:ValueTypes["voyce_series_hot_panels_aggregate_order_by"],
	id?:ValueTypes["order_by"],
	is_animated_cover_allowed?:ValueTypes["order_by"],
	is_approved?:ValueTypes["order_by"],
	is_deleted?:ValueTypes["order_by"],
	is_intro?:ValueTypes["order_by"],
	is_locked?:ValueTypes["order_by"],
	is_original?:ValueTypes["order_by"],
	is_pilots?:ValueTypes["order_by"],
	is_recommended?:ValueTypes["order_by"],
	klaviyo_id?:ValueTypes["order_by"],
	klaviyo_list_id?:ValueTypes["order_by"],
	latest_chapter_update?:ValueTypes["order_by"],
	likes_aggregate?:ValueTypes["voyce_chapter_likes_aggregate_order_by"],
	likes_count?:ValueTypes["voyce_series_likes_count_mat_order_by"],
	lock_config?:ValueTypes["voyce_series_lock_config_order_by"],
	locked?:ValueTypes["order_by"],
	locked_chapters_amount?:ValueTypes["order_by"],
	notification_settings?:ValueTypes["voyce_notification_series_settings_order_by"],
	paginated_view?:ValueTypes["order_by"],
	pilots_votes_count?:ValueTypes["order_by"],
	portfolio_desc?:ValueTypes["order_by"],
	publish?:ValueTypes["order_by"],
	rating_count?:ValueTypes["order_by"],
	reading_view?:ValueTypes["order_by"],
	scroll_view?:ValueTypes["order_by"],
	series_tags_junctions_aggregate?:ValueTypes["voyce_series_tags_junction_aggregate_order_by"],
	short_desc?:ValueTypes["order_by"],
	slug?:ValueTypes["order_by"],
	sponsorship_config?:ValueTypes["voyce_series_sponsorships_configuration_order_by"],
	sponsorships_aggregate?:ValueTypes["voyce_sponsorships_aggregate_order_by"],
	status?:ValueTypes["order_by"],
	subscriber_count?:ValueTypes["order_by"],
	support_msg?:ValueTypes["order_by"],
	team_spotlight?:ValueTypes["order_by"],
	thumbnail?:ValueTypes["order_by"],
	thumbnail_box?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	trending?:ValueTypes["order_by"],
	trending_position?:ValueTypes["order_by"],
	type?:ValueTypes["voyce_comic_types_order_by"],
	updated_at?:ValueTypes["order_by"],
	user_display_field?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	users_series_junctions_aggregate?:ValueTypes["voyce_users_series_junction_aggregate_order_by"],
	view_count?:ValueTypes["order_by"],
	views_aggregate?:ValueTypes["voyce_chapter_views_aggregate_order_by"],
	views_counts?:ValueTypes["voyce_pg_rs_series_views_count_order_by"],
	welcome_msg?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series */
["voyce_series_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series" */
["voyce_series_select_column"]:voyce_series_select_column;
	/** input type for updating data in table "voyce.series" */
["voyce_series_set_input"]: {
	animated_cover_image?:string,
	audio_allowed?:boolean,
	average_rating?:ValueTypes["float8"],
	back_image?:string,
	banner_bg?:string,
	book_reverse_view?:boolean,
	book_view?:boolean,
	chapter_count?:number,
	chapters_hide?:string,
	character_cover?:string,
	coin_price?:ValueTypes["smallint"],
	comic_type_id?:number,
	cover_image?:string,
	cover_image_mobile?:string,
	created_at?:ValueTypes["timestamp"],
	csat?:boolean,
	default_view?:string,
	description?:string,
	feature?:ValueTypes["bit"],
	frequency?:string,
	frequency_mask?:number,
	front_image?:string,
	id?:number,
	is_animated_cover_allowed?:boolean,
	is_approved?:ValueTypes["smallint"],
	is_deleted?:boolean,
	is_intro?:ValueTypes["smallint"],
	is_locked?:boolean,
	is_original?:ValueTypes["smallint"],
	is_pilots?:ValueTypes["smallint"],
	is_recommended?:ValueTypes["smallint"],
	klaviyo_id?:string,
	klaviyo_list_id?:string,
	latest_chapter_update?:ValueTypes["timestamp"],
	locked?:boolean,
	locked_chapters_amount?:number,
	paginated_view?:boolean,
	pilots_votes_count?:number,
	portfolio_desc?:string,
	publish?:ValueTypes["smallint"],
	rating_count?:number,
	reading_view?:string,
	scroll_view?:boolean,
	short_desc?:string,
	slug?:string,
	status?:string,
	subscriber_count?:number,
	support_msg?:string,
	team_spotlight?:boolean,
	thumbnail?:string,
	thumbnail_box?:string,
	title?:string,
	trending?:ValueTypes["bit"],
	trending_position?:number,
	updated_at?:ValueTypes["timestamp"],
	user_display_field?:string,
	user_id?:number,
	view_count?:number,
	welcome_msg?:string
};
	/** columns and relationships of "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration"]: AliasType<{
	enabled?:true,
	id?:true,
	message?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	voyceme_payee?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_sponsorships_configuration_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_sponsorships_configuration"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_sponsorships_configuration_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_sponsorships_configuration_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_sponsorships_configuration_max_fields"],
	min?:ValueTypes["voyce_series_sponsorships_configuration_min_fields"],
	stddev?:ValueTypes["voyce_series_sponsorships_configuration_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_sponsorships_configuration_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_sponsorships_configuration_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_sponsorships_configuration_sum_fields"],
	var_pop?:ValueTypes["voyce_series_sponsorships_configuration_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_sponsorships_configuration_var_samp_fields"],
	variance?:ValueTypes["voyce_series_sponsorships_configuration_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_sponsorships_configuration_avg_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_sponsorships_configuration". All fields are combined with a logical 'AND'. */
["voyce_series_sponsorships_configuration_bool_exp"]: {
	_and?:ValueTypes["voyce_series_sponsorships_configuration_bool_exp"][],
	_not?:ValueTypes["voyce_series_sponsorships_configuration_bool_exp"],
	_or?:ValueTypes["voyce_series_sponsorships_configuration_bool_exp"][],
	enabled?:ValueTypes["Boolean_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	message?:ValueTypes["String_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	voyceme_payee?:ValueTypes["Boolean_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_constraint"]:voyce_series_sponsorships_configuration_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_inc_input"]: {
	id?:number,
	series_id?:number
};
	/** input type for inserting data into table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_insert_input"]: {
	enabled?:boolean,
	id?:number,
	message?:string,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	voyceme_payee?:boolean
};
	/** aggregate max on columns */
["voyce_series_sponsorships_configuration_max_fields"]: AliasType<{
	id?:true,
	message?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_sponsorships_configuration_min_fields"]: AliasType<{
	id?:true,
	message?:true,
	series_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_sponsorships_configuration"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_series_sponsorships_configuration_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_sponsorships_configuration_on_conflict"]
};
	/** on_conflict condition type for table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_on_conflict"]: {
	constraint:ValueTypes["voyce_series_sponsorships_configuration_constraint"],
	update_columns:ValueTypes["voyce_series_sponsorships_configuration_update_column"][],
	where?:ValueTypes["voyce_series_sponsorships_configuration_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_sponsorships_configuration". */
["voyce_series_sponsorships_configuration_order_by"]: {
	enabled?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	message?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	voyceme_payee?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_sponsorships_configuration */
["voyce_series_sponsorships_configuration_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_select_column"]:voyce_series_sponsorships_configuration_select_column;
	/** input type for updating data in table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_set_input"]: {
	enabled?:boolean,
	id?:number,
	message?:string,
	series_id?:number,
	voyceme_payee?:boolean
};
	/** aggregate stddev on columns */
["voyce_series_sponsorships_configuration_stddev_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_sponsorships_configuration_stddev_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_sponsorships_configuration_stddev_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_sponsorships_configuration_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_sponsorships_configuration_stream_cursor_value_input"]: {
	enabled?:boolean,
	id?:number,
	message?:string,
	series_id?:number,
	voyceme_payee?:boolean
};
	/** aggregate sum on columns */
["voyce_series_sponsorships_configuration_sum_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_update_column"]:voyce_series_sponsorships_configuration_update_column;
	["voyce_series_sponsorships_configuration_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_sponsorships_configuration_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_sponsorships_configuration_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_sponsorships_configuration_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_sponsorships_configuration_var_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_sponsorships_configuration_var_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_sponsorships_configuration_variance_fields"]: AliasType<{
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev on columns */
["voyce_series_stddev_fields"]: AliasType<{
	average_rating?:true,
	chapter_count?:true,
	coin_price?:true,
	comic_type_id?:true,
	frequency_mask?:true,
	id?:true,
	is_approved?:true,
	is_intro?:true,
	is_original?:true,
	is_pilots?:true,
	is_recommended?:true,
	locked_chapters_amount?:true,
	pilots_votes_count?:true,
	publish?:true,
	rating_count?:true,
	subscriber_count?:true,
	trending_position?:true,
	user_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_stddev_pop_fields"]: AliasType<{
	average_rating?:true,
	chapter_count?:true,
	coin_price?:true,
	comic_type_id?:true,
	frequency_mask?:true,
	id?:true,
	is_approved?:true,
	is_intro?:true,
	is_original?:true,
	is_pilots?:true,
	is_recommended?:true,
	locked_chapters_amount?:true,
	pilots_votes_count?:true,
	publish?:true,
	rating_count?:true,
	subscriber_count?:true,
	trending_position?:true,
	user_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_stddev_samp_fields"]: AliasType<{
	average_rating?:true,
	chapter_count?:true,
	coin_price?:true,
	comic_type_id?:true,
	frequency_mask?:true,
	id?:true,
	is_approved?:true,
	is_intro?:true,
	is_original?:true,
	is_pilots?:true,
	is_recommended?:true,
	locked_chapters_amount?:true,
	pilots_votes_count?:true,
	publish?:true,
	rating_count?:true,
	subscriber_count?:true,
	trending_position?:true,
	user_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series" */
["voyce_series_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_stream_cursor_value_input"]: {
	animated_cover_image?:string,
	audio_allowed?:boolean,
	average_rating?:ValueTypes["float8"],
	back_image?:string,
	banner_bg?:string,
	book_reverse_view?:boolean,
	book_view?:boolean,
	chapter_count?:number,
	chapters_hide?:string,
	character_cover?:string,
	coin_price?:ValueTypes["smallint"],
	comic_type_id?:number,
	cover_image?:string,
	cover_image_mobile?:string,
	created_at?:ValueTypes["timestamp"],
	csat?:boolean,
	default_view?:string,
	description?:string,
	feature?:ValueTypes["bit"],
	frequency?:string,
	frequency_mask?:number,
	front_image?:string,
	id?:number,
	is_animated_cover_allowed?:boolean,
	is_approved?:ValueTypes["smallint"],
	is_deleted?:boolean,
	is_intro?:ValueTypes["smallint"],
	is_locked?:boolean,
	is_original?:ValueTypes["smallint"],
	is_pilots?:ValueTypes["smallint"],
	is_recommended?:ValueTypes["smallint"],
	klaviyo_id?:string,
	klaviyo_list_id?:string,
	latest_chapter_update?:ValueTypes["timestamp"],
	locked?:boolean,
	locked_chapters_amount?:number,
	paginated_view?:boolean,
	pilots_votes_count?:number,
	portfolio_desc?:string,
	publish?:ValueTypes["smallint"],
	rating_count?:number,
	reading_view?:string,
	scroll_view?:boolean,
	short_desc?:string,
	slug?:string,
	status?:string,
	subscriber_count?:number,
	support_msg?:string,
	team_spotlight?:boolean,
	thumbnail?:string,
	thumbnail_box?:string,
	title?:string,
	trending?:ValueTypes["bit"],
	trending_position?:number,
	updated_at?:ValueTypes["timestamp"],
	user_display_field?:string,
	user_id?:number,
	view_count?:number,
	welcome_msg?:string
};
	/** columns and relationships of "voyce.series_subscription" */
["voyce_series_subscription"]: AliasType<{
	created_at?:true,
	id?:true,
	klaviyo_id?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_subscription" */
["voyce_series_subscription_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_subscription_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_subscription"],
		__typename?: true
}>;
	["voyce_series_subscription_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_series_subscription_aggregate_bool_exp_count"]
};
	["voyce_series_subscription_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_series_subscription_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_series_subscription_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.series_subscription" */
["voyce_series_subscription_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_subscription_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_subscription_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_subscription_max_fields"],
	min?:ValueTypes["voyce_series_subscription_min_fields"],
	stddev?:ValueTypes["voyce_series_subscription_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_subscription_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_subscription_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_subscription_sum_fields"],
	var_pop?:ValueTypes["voyce_series_subscription_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_subscription_var_samp_fields"],
	variance?:ValueTypes["voyce_series_subscription_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.series_subscription" */
["voyce_series_subscription_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_series_subscription_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_series_subscription_max_order_by"],
	min?:ValueTypes["voyce_series_subscription_min_order_by"],
	stddev?:ValueTypes["voyce_series_subscription_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_series_subscription_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_series_subscription_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_series_subscription_sum_order_by"],
	var_pop?:ValueTypes["voyce_series_subscription_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_series_subscription_var_samp_order_by"],
	variance?:ValueTypes["voyce_series_subscription_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.series_subscription" */
["voyce_series_subscription_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_series_subscription_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_subscription_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_series_subscription_avg_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.series_subscription". All fields are combined with a logical 'AND'. */
["voyce_series_subscription_bool_exp"]: {
	_and?:ValueTypes["voyce_series_subscription_bool_exp"][],
	_not?:ValueTypes["voyce_series_subscription_bool_exp"],
	_or?:ValueTypes["voyce_series_subscription_bool_exp"][],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	klaviyo_id?:ValueTypes["String_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_subscription" */
["voyce_series_subscription_constraint"]:voyce_series_subscription_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_subscription" */
["voyce_series_subscription_inc_input"]: {
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.series_subscription" */
["voyce_series_subscription_insert_input"]: {
	created_at?:ValueTypes["timestamp"],
	id?:number,
	klaviyo_id?:string,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_series_subscription_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	klaviyo_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_max_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	klaviyo_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_series_subscription_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	klaviyo_id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_min_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	klaviyo_id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.series_subscription" */
["voyce_series_subscription_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_subscription"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_subscription" */
["voyce_series_subscription_on_conflict"]: {
	constraint:ValueTypes["voyce_series_subscription_constraint"],
	update_columns:ValueTypes["voyce_series_subscription_update_column"][],
	where?:ValueTypes["voyce_series_subscription_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_subscription". */
["voyce_series_subscription_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	klaviyo_id?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_subscription */
["voyce_series_subscription_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_subscription" */
["voyce_series_subscription_select_column"]:voyce_series_subscription_select_column;
	/** input type for updating data in table "voyce.series_subscription" */
["voyce_series_subscription_set_input"]: {
	created_at?:ValueTypes["timestamp"],
	id?:number,
	klaviyo_id?:string,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_series_subscription_stddev_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_series_subscription_stddev_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_series_subscription_stddev_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_series_subscription" */
["voyce_series_subscription_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_subscription_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_subscription_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamp"],
	id?:number,
	klaviyo_id?:string,
	series_id?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_series_subscription_sum_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.series_subscription" */
["voyce_series_subscription_update_column"]:voyce_series_subscription_update_column;
	["voyce_series_subscription_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_subscription_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_subscription_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_subscription_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_subscription_var_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_series_subscription_var_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_series_subscription_variance_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate sum on columns */
["voyce_series_sum_fields"]: AliasType<{
	average_rating?:true,
	chapter_count?:true,
	coin_price?:true,
	comic_type_id?:true,
	frequency_mask?:true,
	id?:true,
	is_approved?:true,
	is_intro?:true,
	is_original?:true,
	is_pilots?:true,
	is_recommended?:true,
	locked_chapters_amount?:true,
	pilots_votes_count?:true,
	publish?:true,
	rating_count?:true,
	subscriber_count?:true,
	trending_position?:true,
	user_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** This table keeps tags assigned series. */
["voyce_series_tags"]: AliasType<{
	id?:true,
	name?:true,
series_tags_junctions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_tags_junction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_tags_junction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_tags_junction_bool_exp"]},ValueTypes["voyce_series_tags_junction"]],
series_tags_junctions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_tags_junction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_tags_junction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_tags_junction_bool_exp"]},ValueTypes["voyce_series_tags_junction_aggregate"]],
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_tags" */
["voyce_series_tags_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_tags_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_tags"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_tags" */
["voyce_series_tags_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_tags_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_tags_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_tags_max_fields"],
	min?:ValueTypes["voyce_series_tags_min_fields"],
	stddev?:ValueTypes["voyce_series_tags_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_tags_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_tags_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_tags_sum_fields"],
	var_pop?:ValueTypes["voyce_series_tags_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_tags_var_samp_fields"],
	variance?:ValueTypes["voyce_series_tags_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_tags_avg_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_tags". All fields are combined with a logical 'AND'. */
["voyce_series_tags_bool_exp"]: {
	_and?:ValueTypes["voyce_series_tags_bool_exp"][],
	_not?:ValueTypes["voyce_series_tags_bool_exp"],
	_or?:ValueTypes["voyce_series_tags_bool_exp"][],
	id?:ValueTypes["Int_comparison_exp"],
	name?:ValueTypes["String_comparison_exp"],
	series_tags_junctions?:ValueTypes["voyce_series_tags_junction_bool_exp"],
	series_tags_junctions_aggregate?:ValueTypes["voyce_series_tags_junction_aggregate_bool_exp"]
};
	/** unique or primary key constraints on table "voyce.series_tags" */
["voyce_series_tags_constraint"]:voyce_series_tags_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_tags" */
["voyce_series_tags_inc_input"]: {
	id?:number
};
	/** input type for inserting data into table "voyce.series_tags" */
["voyce_series_tags_insert_input"]: {
	id?:number,
	name?:string,
	series_tags_junctions?:ValueTypes["voyce_series_tags_junction_arr_rel_insert_input"]
};
	/** Many-to-many junction table for series_tags and series */
["voyce_series_tags_junction"]: AliasType<{
	admin_created?:true,
	/** An object relationship */
	chapter?:ValueTypes["voyce_chapters"],
	chapter_id?:true,
	id?:true,
images?: [{	/** JSON select path */
	path?:string},true],
	order?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	/** An object relationship */
	series_tag?:ValueTypes["voyce_series_tags"],
	tag_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_tags_junction" */
["voyce_series_tags_junction_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_tags_junction_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_tags_junction"],
		__typename?: true
}>;
	["voyce_series_tags_junction_aggregate_bool_exp"]: {
	bool_and?:ValueTypes["voyce_series_tags_junction_aggregate_bool_exp_bool_and"],
	bool_or?:ValueTypes["voyce_series_tags_junction_aggregate_bool_exp_bool_or"],
	count?:ValueTypes["voyce_series_tags_junction_aggregate_bool_exp_count"]
};
	["voyce_series_tags_junction_aggregate_bool_exp_bool_and"]: {
	arguments:ValueTypes["voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_series_tags_junction_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_series_tags_junction_aggregate_bool_exp_bool_or"]: {
	arguments:ValueTypes["voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?:boolean,
	filter?:ValueTypes["voyce_series_tags_junction_bool_exp"],
	predicate:ValueTypes["Boolean_comparison_exp"]
};
	["voyce_series_tags_junction_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_series_tags_junction_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_series_tags_junction_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.series_tags_junction" */
["voyce_series_tags_junction_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_tags_junction_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_tags_junction_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_tags_junction_max_fields"],
	min?:ValueTypes["voyce_series_tags_junction_min_fields"],
	stddev?:ValueTypes["voyce_series_tags_junction_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_tags_junction_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_tags_junction_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_tags_junction_sum_fields"],
	var_pop?:ValueTypes["voyce_series_tags_junction_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_tags_junction_var_samp_fields"],
	variance?:ValueTypes["voyce_series_tags_junction_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_series_tags_junction_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_series_tags_junction_max_order_by"],
	min?:ValueTypes["voyce_series_tags_junction_min_order_by"],
	stddev?:ValueTypes["voyce_series_tags_junction_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_series_tags_junction_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_series_tags_junction_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_series_tags_junction_sum_order_by"],
	var_pop?:ValueTypes["voyce_series_tags_junction_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_series_tags_junction_var_samp_order_by"],
	variance?:ValueTypes["voyce_series_tags_junction_variance_order_by"]
};
	/** append existing jsonb value of filtered columns with new jsonb value */
["voyce_series_tags_junction_append_input"]: {
	images?:ValueTypes["jsonb"]
};
	/** input type for inserting array relation for remote table "voyce.series_tags_junction" */
["voyce_series_tags_junction_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_series_tags_junction_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_tags_junction_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_series_tags_junction_avg_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
	series_id?:true,
	tag_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_avg_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	tag_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.series_tags_junction". All fields are combined with a logical 'AND'. */
["voyce_series_tags_junction_bool_exp"]: {
	_and?:ValueTypes["voyce_series_tags_junction_bool_exp"][],
	_not?:ValueTypes["voyce_series_tags_junction_bool_exp"],
	_or?:ValueTypes["voyce_series_tags_junction_bool_exp"][],
	admin_created?:ValueTypes["Boolean_comparison_exp"],
	chapter?:ValueTypes["voyce_chapters_bool_exp"],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	images?:ValueTypes["jsonb_comparison_exp"],
	order?:ValueTypes["Int_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	series_tag?:ValueTypes["voyce_series_tags_bool_exp"],
	tag_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_tags_junction" */
["voyce_series_tags_junction_constraint"]:voyce_series_tags_junction_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["voyce_series_tags_junction_delete_at_path_input"]: {
	images?:string[]
};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["voyce_series_tags_junction_delete_elem_input"]: {
	images?:number
};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["voyce_series_tags_junction_delete_key_input"]: {
	images?:string
};
	/** input type for incrementing numeric columns in table "voyce.series_tags_junction" */
["voyce_series_tags_junction_inc_input"]: {
	chapter_id?:number,
	id?:number,
	order?:number,
	series_id?:number,
	tag_id?:number
};
	/** input type for inserting data into table "voyce.series_tags_junction" */
["voyce_series_tags_junction_insert_input"]: {
	admin_created?:boolean,
	chapter?:ValueTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?:number,
	id?:number,
	images?:ValueTypes["jsonb"],
	order?:number,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	series_tag?:ValueTypes["voyce_series_tags_obj_rel_insert_input"],
	tag_id?:number
};
	/** aggregate max on columns */
["voyce_series_tags_junction_max_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
	series_id?:true,
	tag_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_max_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	tag_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_series_tags_junction_min_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
	series_id?:true,
	tag_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_min_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	tag_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.series_tags_junction" */
["voyce_series_tags_junction_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_tags_junction"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_tags_junction" */
["voyce_series_tags_junction_on_conflict"]: {
	constraint:ValueTypes["voyce_series_tags_junction_constraint"],
	update_columns:ValueTypes["voyce_series_tags_junction_update_column"][],
	where?:ValueTypes["voyce_series_tags_junction_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_tags_junction". */
["voyce_series_tags_junction_order_by"]: {
	admin_created?:ValueTypes["order_by"],
	chapter?:ValueTypes["voyce_chapters_order_by"],
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	images?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	series_tag?:ValueTypes["voyce_series_tags_order_by"],
	tag_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_tags_junction */
["voyce_series_tags_junction_pk_columns_input"]: {
	id:number
};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["voyce_series_tags_junction_prepend_input"]: {
	images?:ValueTypes["jsonb"]
};
	/** select columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_select_column"]:voyce_series_tags_junction_select_column;
	/** select "voyce_series_tags_junction_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_and_arguments_columns"]:voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_series_tags_junction_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_or_arguments_columns"]:voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.series_tags_junction" */
["voyce_series_tags_junction_set_input"]: {
	admin_created?:boolean,
	chapter_id?:number,
	id?:number,
	images?:ValueTypes["jsonb"],
	order?:number,
	series_id?:number,
	tag_id?:number
};
	/** aggregate stddev on columns */
["voyce_series_tags_junction_stddev_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
	series_id?:true,
	tag_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_stddev_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	tag_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_series_tags_junction_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
	series_id?:true,
	tag_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_stddev_pop_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	tag_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_series_tags_junction_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
	series_id?:true,
	tag_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_stddev_samp_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	tag_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_series_tags_junction" */
["voyce_series_tags_junction_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_tags_junction_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_tags_junction_stream_cursor_value_input"]: {
	admin_created?:boolean,
	chapter_id?:number,
	id?:number,
	images?:ValueTypes["jsonb"],
	order?:number,
	series_id?:number,
	tag_id?:number
};
	/** aggregate sum on columns */
["voyce_series_tags_junction_sum_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
	series_id?:true,
	tag_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_sum_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	tag_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_update_column"]:voyce_series_tags_junction_update_column;
	["voyce_series_tags_junction_updates"]: {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["voyce_series_tags_junction_append_input"],
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["voyce_series_tags_junction_delete_at_path_input"],
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["voyce_series_tags_junction_delete_elem_input"],
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["voyce_series_tags_junction_delete_key_input"],
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_tags_junction_inc_input"],
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["voyce_series_tags_junction_prepend_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_tags_junction_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_tags_junction_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_tags_junction_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
	series_id?:true,
	tag_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_var_pop_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	tag_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_series_tags_junction_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
	series_id?:true,
	tag_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_var_samp_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	tag_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_series_tags_junction_variance_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	order?:true,
	series_id?:true,
	tag_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_variance_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	tag_id?:ValueTypes["order_by"]
};
	/** aggregate max on columns */
["voyce_series_tags_max_fields"]: AliasType<{
	id?:true,
	name?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_tags_min_fields"]: AliasType<{
	id?:true,
	name?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.series_tags" */
["voyce_series_tags_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_tags"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.series_tags" */
["voyce_series_tags_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_series_tags_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_series_tags_on_conflict"]
};
	/** on_conflict condition type for table "voyce.series_tags" */
["voyce_series_tags_on_conflict"]: {
	constraint:ValueTypes["voyce_series_tags_constraint"],
	update_columns:ValueTypes["voyce_series_tags_update_column"][],
	where?:ValueTypes["voyce_series_tags_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_tags". */
["voyce_series_tags_order_by"]: {
	id?:ValueTypes["order_by"],
	name?:ValueTypes["order_by"],
	series_tags_junctions_aggregate?:ValueTypes["voyce_series_tags_junction_aggregate_order_by"]
};
	/** primary key columns input for table: voyce.series_tags */
["voyce_series_tags_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_tags" */
["voyce_series_tags_select_column"]:voyce_series_tags_select_column;
	/** input type for updating data in table "voyce.series_tags" */
["voyce_series_tags_set_input"]: {
	id?:number,
	name?:string
};
	/** aggregate stddev on columns */
["voyce_series_tags_stddev_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_tags_stddev_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_tags_stddev_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_tags" */
["voyce_series_tags_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_tags_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_tags_stream_cursor_value_input"]: {
	id?:number,
	name?:string
};
	/** aggregate sum on columns */
["voyce_series_tags_sum_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.series_tags" */
["voyce_series_tags_update_column"]:voyce_series_tags_update_column;
	["voyce_series_tags_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_tags_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_tags_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_tags_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_tags_var_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_tags_var_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_tags_variance_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.series_top_comments" */
["voyce_series_top_comments"]: AliasType<{
	/** An object relationship */
	comment?:ValueTypes["voyce_chapter_comments"],
	comment_id?:true,
	count?:true,
	created_at?:true,
	id?:true,
	series_id?:true,
	tag?:true,
	type?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_top_comments" */
["voyce_series_top_comments_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_top_comments_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_top_comments"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_top_comments" */
["voyce_series_top_comments_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_top_comments_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_top_comments_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_top_comments_max_fields"],
	min?:ValueTypes["voyce_series_top_comments_min_fields"],
	stddev?:ValueTypes["voyce_series_top_comments_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_top_comments_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_top_comments_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_top_comments_sum_fields"],
	var_pop?:ValueTypes["voyce_series_top_comments_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_top_comments_var_samp_fields"],
	variance?:ValueTypes["voyce_series_top_comments_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_top_comments_avg_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_top_comments". All fields are combined with a logical 'AND'. */
["voyce_series_top_comments_bool_exp"]: {
	_and?:ValueTypes["voyce_series_top_comments_bool_exp"][],
	_not?:ValueTypes["voyce_series_top_comments_bool_exp"],
	_or?:ValueTypes["voyce_series_top_comments_bool_exp"][],
	comment?:ValueTypes["voyce_chapter_comments_bool_exp"],
	comment_id?:ValueTypes["Int_comparison_exp"],
	count?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	tag?:ValueTypes["String_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_top_comments" */
["voyce_series_top_comments_constraint"]:voyce_series_top_comments_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_top_comments" */
["voyce_series_top_comments_inc_input"]: {
	comment_id?:number,
	count?:number,
	id?:number,
	series_id?:number
};
	/** input type for inserting data into table "voyce.series_top_comments" */
["voyce_series_top_comments_insert_input"]: {
	comment?:ValueTypes["voyce_chapter_comments_obj_rel_insert_input"],
	comment_id?:number,
	count?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	series_id?:number,
	tag?:string,
	type?:string
};
	/** aggregate max on columns */
["voyce_series_top_comments_max_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	created_at?:true,
	id?:true,
	series_id?:true,
	tag?:true,
	type?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_top_comments_min_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	created_at?:true,
	id?:true,
	series_id?:true,
	tag?:true,
	type?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.series_top_comments" */
["voyce_series_top_comments_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_top_comments"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_top_comments" */
["voyce_series_top_comments_on_conflict"]: {
	constraint:ValueTypes["voyce_series_top_comments_constraint"],
	update_columns:ValueTypes["voyce_series_top_comments_update_column"][],
	where?:ValueTypes["voyce_series_top_comments_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_top_comments". */
["voyce_series_top_comments_order_by"]: {
	comment?:ValueTypes["voyce_chapter_comments_order_by"],
	comment_id?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	tag?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_top_comments */
["voyce_series_top_comments_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_top_comments" */
["voyce_series_top_comments_select_column"]:voyce_series_top_comments_select_column;
	/** input type for updating data in table "voyce.series_top_comments" */
["voyce_series_top_comments_set_input"]: {
	comment_id?:number,
	count?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	series_id?:number,
	tag?:string,
	type?:string
};
	/** aggregate stddev on columns */
["voyce_series_top_comments_stddev_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_top_comments_stddev_pop_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_top_comments_stddev_samp_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_top_comments" */
["voyce_series_top_comments_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_top_comments_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_top_comments_stream_cursor_value_input"]: {
	comment_id?:number,
	count?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	series_id?:number,
	tag?:string,
	type?:string
};
	/** aggregate sum on columns */
["voyce_series_top_comments_sum_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.series_top_comments" */
["voyce_series_top_comments_update_column"]:voyce_series_top_comments_update_column;
	["voyce_series_top_comments_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_top_comments_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_top_comments_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_top_comments_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_top_comments_var_pop_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_top_comments_var_samp_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_top_comments_variance_fields"]: AliasType<{
	comment_id?:true,
	count?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.series_top_likes" */
["voyce_series_top_likes"]: AliasType<{
	count?:true,
	created_at?:true,
	id?:true,
	is_original?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	type?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_top_likes" */
["voyce_series_top_likes_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_top_likes_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_top_likes"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_top_likes" */
["voyce_series_top_likes_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_top_likes_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_top_likes_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_top_likes_max_fields"],
	min?:ValueTypes["voyce_series_top_likes_min_fields"],
	stddev?:ValueTypes["voyce_series_top_likes_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_top_likes_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_top_likes_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_top_likes_sum_fields"],
	var_pop?:ValueTypes["voyce_series_top_likes_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_top_likes_var_samp_fields"],
	variance?:ValueTypes["voyce_series_top_likes_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_top_likes_avg_fields"]: AliasType<{
	count?:true,
	id?:true,
	is_original?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_top_likes". All fields are combined with a logical 'AND'. */
["voyce_series_top_likes_bool_exp"]: {
	_and?:ValueTypes["voyce_series_top_likes_bool_exp"][],
	_not?:ValueTypes["voyce_series_top_likes_bool_exp"],
	_or?:ValueTypes["voyce_series_top_likes_bool_exp"][],
	count?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_original?:ValueTypes["smallint_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_top_likes" */
["voyce_series_top_likes_constraint"]:voyce_series_top_likes_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_top_likes" */
["voyce_series_top_likes_inc_input"]: {
	count?:number,
	id?:number,
	is_original?:ValueTypes["smallint"],
	series_id?:number
};
	/** input type for inserting data into table "voyce.series_top_likes" */
["voyce_series_top_likes_insert_input"]: {
	count?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	is_original?:ValueTypes["smallint"],
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	type?:string
};
	/** aggregate max on columns */
["voyce_series_top_likes_max_fields"]: AliasType<{
	count?:true,
	created_at?:true,
	id?:true,
	is_original?:true,
	series_id?:true,
	type?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_top_likes_min_fields"]: AliasType<{
	count?:true,
	created_at?:true,
	id?:true,
	is_original?:true,
	series_id?:true,
	type?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.series_top_likes" */
["voyce_series_top_likes_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_top_likes"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_top_likes" */
["voyce_series_top_likes_on_conflict"]: {
	constraint:ValueTypes["voyce_series_top_likes_constraint"],
	update_columns:ValueTypes["voyce_series_top_likes_update_column"][],
	where?:ValueTypes["voyce_series_top_likes_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_top_likes". */
["voyce_series_top_likes_order_by"]: {
	count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_original?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_top_likes */
["voyce_series_top_likes_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_top_likes" */
["voyce_series_top_likes_select_column"]:voyce_series_top_likes_select_column;
	/** input type for updating data in table "voyce.series_top_likes" */
["voyce_series_top_likes_set_input"]: {
	count?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	is_original?:ValueTypes["smallint"],
	series_id?:number,
	type?:string
};
	/** aggregate stddev on columns */
["voyce_series_top_likes_stddev_fields"]: AliasType<{
	count?:true,
	id?:true,
	is_original?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_top_likes_stddev_pop_fields"]: AliasType<{
	count?:true,
	id?:true,
	is_original?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_top_likes_stddev_samp_fields"]: AliasType<{
	count?:true,
	id?:true,
	is_original?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_top_likes" */
["voyce_series_top_likes_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_top_likes_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_top_likes_stream_cursor_value_input"]: {
	count?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	is_original?:ValueTypes["smallint"],
	series_id?:number,
	type?:string
};
	/** aggregate sum on columns */
["voyce_series_top_likes_sum_fields"]: AliasType<{
	count?:true,
	id?:true,
	is_original?:true,
	series_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.series_top_likes" */
["voyce_series_top_likes_update_column"]:voyce_series_top_likes_update_column;
	["voyce_series_top_likes_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_top_likes_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_top_likes_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_top_likes_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_top_likes_var_pop_fields"]: AliasType<{
	count?:true,
	id?:true,
	is_original?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_top_likes_var_samp_fields"]: AliasType<{
	count?:true,
	id?:true,
	is_original?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_top_likes_variance_fields"]: AliasType<{
	count?:true,
	id?:true,
	is_original?:true,
	series_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.series_top_views" */
["voyce_series_top_views"]: AliasType<{
	comic_type?:true,
	count?:true,
	created_at?:true,
	id?:true,
	prev_rank?:true,
	rank?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	tag?:true,
	type?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_top_views" */
["voyce_series_top_views_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_top_views_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_top_views"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_top_views" */
["voyce_series_top_views_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_top_views_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_top_views_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_top_views_max_fields"],
	min?:ValueTypes["voyce_series_top_views_min_fields"],
	stddev?:ValueTypes["voyce_series_top_views_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_top_views_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_top_views_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_top_views_sum_fields"],
	var_pop?:ValueTypes["voyce_series_top_views_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_top_views_var_samp_fields"],
	variance?:ValueTypes["voyce_series_top_views_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_top_views_avg_fields"]: AliasType<{
	count?:true,
	id?:true,
	prev_rank?:true,
	rank?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_top_views". All fields are combined with a logical 'AND'. */
["voyce_series_top_views_bool_exp"]: {
	_and?:ValueTypes["voyce_series_top_views_bool_exp"][],
	_not?:ValueTypes["voyce_series_top_views_bool_exp"],
	_or?:ValueTypes["voyce_series_top_views_bool_exp"][],
	comic_type?:ValueTypes["String_comparison_exp"],
	count?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	prev_rank?:ValueTypes["Int_comparison_exp"],
	rank?:ValueTypes["Int_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	tag?:ValueTypes["String_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_top_views" */
["voyce_series_top_views_constraint"]:voyce_series_top_views_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_top_views" */
["voyce_series_top_views_inc_input"]: {
	count?:number,
	id?:number,
	prev_rank?:number,
	rank?:number,
	series_id?:number
};
	/** input type for inserting data into table "voyce.series_top_views" */
["voyce_series_top_views_insert_input"]: {
	comic_type?:string,
	count?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	prev_rank?:number,
	rank?:number,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	tag?:string,
	type?:string
};
	/** aggregate max on columns */
["voyce_series_top_views_max_fields"]: AliasType<{
	comic_type?:true,
	count?:true,
	created_at?:true,
	id?:true,
	prev_rank?:true,
	rank?:true,
	series_id?:true,
	tag?:true,
	type?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_top_views_min_fields"]: AliasType<{
	comic_type?:true,
	count?:true,
	created_at?:true,
	id?:true,
	prev_rank?:true,
	rank?:true,
	series_id?:true,
	tag?:true,
	type?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.series_top_views" */
["voyce_series_top_views_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_top_views"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_top_views" */
["voyce_series_top_views_on_conflict"]: {
	constraint:ValueTypes["voyce_series_top_views_constraint"],
	update_columns:ValueTypes["voyce_series_top_views_update_column"][],
	where?:ValueTypes["voyce_series_top_views_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_top_views". */
["voyce_series_top_views_order_by"]: {
	comic_type?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	prev_rank?:ValueTypes["order_by"],
	rank?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	tag?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_top_views */
["voyce_series_top_views_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_top_views" */
["voyce_series_top_views_select_column"]:voyce_series_top_views_select_column;
	/** input type for updating data in table "voyce.series_top_views" */
["voyce_series_top_views_set_input"]: {
	comic_type?:string,
	count?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	prev_rank?:number,
	rank?:number,
	series_id?:number,
	tag?:string,
	type?:string
};
	/** aggregate stddev on columns */
["voyce_series_top_views_stddev_fields"]: AliasType<{
	count?:true,
	id?:true,
	prev_rank?:true,
	rank?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_top_views_stddev_pop_fields"]: AliasType<{
	count?:true,
	id?:true,
	prev_rank?:true,
	rank?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_top_views_stddev_samp_fields"]: AliasType<{
	count?:true,
	id?:true,
	prev_rank?:true,
	rank?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_top_views" */
["voyce_series_top_views_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_top_views_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_top_views_stream_cursor_value_input"]: {
	comic_type?:string,
	count?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	prev_rank?:number,
	rank?:number,
	series_id?:number,
	tag?:string,
	type?:string
};
	/** aggregate sum on columns */
["voyce_series_top_views_sum_fields"]: AliasType<{
	count?:true,
	id?:true,
	prev_rank?:true,
	rank?:true,
	series_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.series_top_views" */
["voyce_series_top_views_update_column"]:voyce_series_top_views_update_column;
	["voyce_series_top_views_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_top_views_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_top_views_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_top_views_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_top_views_var_pop_fields"]: AliasType<{
	count?:true,
	id?:true,
	prev_rank?:true,
	rank?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_top_views_var_samp_fields"]: AliasType<{
	count?:true,
	id?:true,
	prev_rank?:true,
	rank?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_top_views_variance_fields"]: AliasType<{
	count?:true,
	id?:true,
	prev_rank?:true,
	rank?:true,
	series_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.series" */
["voyce_series_update_column"]:voyce_series_update_column;
	["voyce_series_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_var_pop_fields"]: AliasType<{
	average_rating?:true,
	chapter_count?:true,
	coin_price?:true,
	comic_type_id?:true,
	frequency_mask?:true,
	id?:true,
	is_approved?:true,
	is_intro?:true,
	is_original?:true,
	is_pilots?:true,
	is_recommended?:true,
	locked_chapters_amount?:true,
	pilots_votes_count?:true,
	publish?:true,
	rating_count?:true,
	subscriber_count?:true,
	trending_position?:true,
	user_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_var_samp_fields"]: AliasType<{
	average_rating?:true,
	chapter_count?:true,
	coin_price?:true,
	comic_type_id?:true,
	frequency_mask?:true,
	id?:true,
	is_approved?:true,
	is_intro?:true,
	is_original?:true,
	is_pilots?:true,
	is_recommended?:true,
	locked_chapters_amount?:true,
	pilots_votes_count?:true,
	publish?:true,
	rating_count?:true,
	subscriber_count?:true,
	trending_position?:true,
	user_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_variance_fields"]: AliasType<{
	average_rating?:true,
	chapter_count?:true,
	coin_price?:true,
	comic_type_id?:true,
	frequency_mask?:true,
	id?:true,
	is_approved?:true,
	is_intro?:true,
	is_original?:true,
	is_pilots?:true,
	is_recommended?:true,
	locked_chapters_amount?:true,
	pilots_votes_count?:true,
	publish?:true,
	rating_count?:true,
	subscriber_count?:true,
	trending_position?:true,
	user_id?:true,
	view_count?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.series_views_adjustment" */
["voyce_series_views_adjustment"]: AliasType<{
	count?:true,
	created_at?:true,
	id?:true,
	series_id?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_views_adjustment_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_views_adjustment"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_views_adjustment_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_views_adjustment_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_views_adjustment_max_fields"],
	min?:ValueTypes["voyce_series_views_adjustment_min_fields"],
	stddev?:ValueTypes["voyce_series_views_adjustment_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_views_adjustment_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_views_adjustment_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_views_adjustment_sum_fields"],
	var_pop?:ValueTypes["voyce_series_views_adjustment_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_views_adjustment_var_samp_fields"],
	variance?:ValueTypes["voyce_series_views_adjustment_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_views_adjustment_avg_fields"]: AliasType<{
	count?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_views_adjustment". All fields are combined with a logical 'AND'. */
["voyce_series_views_adjustment_bool_exp"]: {
	_and?:ValueTypes["voyce_series_views_adjustment_bool_exp"][],
	_not?:ValueTypes["voyce_series_views_adjustment_bool_exp"],
	_or?:ValueTypes["voyce_series_views_adjustment_bool_exp"][],
	count?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_constraint"]:voyce_series_views_adjustment_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_inc_input"]: {
	count?:number,
	id?:number,
	series_id?:number
};
	/** input type for inserting data into table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_insert_input"]: {
	count?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	series_id?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_series_views_adjustment_max_fields"]: AliasType<{
	count?:true,
	created_at?:true,
	id?:true,
	series_id?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_views_adjustment_min_fields"]: AliasType<{
	count?:true,
	created_at?:true,
	id?:true,
	series_id?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_views_adjustment"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_on_conflict"]: {
	constraint:ValueTypes["voyce_series_views_adjustment_constraint"],
	update_columns:ValueTypes["voyce_series_views_adjustment_update_column"][],
	where?:ValueTypes["voyce_series_views_adjustment_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_views_adjustment". */
["voyce_series_views_adjustment_order_by"]: {
	count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_views_adjustment */
["voyce_series_views_adjustment_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_select_column"]:voyce_series_views_adjustment_select_column;
	/** input type for updating data in table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_set_input"]: {
	count?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	series_id?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_series_views_adjustment_stddev_fields"]: AliasType<{
	count?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_views_adjustment_stddev_pop_fields"]: AliasType<{
	count?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_views_adjustment_stddev_samp_fields"]: AliasType<{
	count?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_views_adjustment" */
["voyce_series_views_adjustment_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_views_adjustment_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_views_adjustment_stream_cursor_value_input"]: {
	count?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	series_id?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_series_views_adjustment_sum_fields"]: AliasType<{
	count?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_update_column"]:voyce_series_views_adjustment_update_column;
	["voyce_series_views_adjustment_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_views_adjustment_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_views_adjustment_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_views_adjustment_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_views_adjustment_var_pop_fields"]: AliasType<{
	count?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_views_adjustment_var_samp_fields"]: AliasType<{
	count?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_views_adjustment_variance_fields"]: AliasType<{
	count?:true,
	id?:true,
	series_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.series_views_count" */
["voyce_series_views_count"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_views_count" */
["voyce_series_views_count_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_views_count_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_views_count"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_views_count" */
["voyce_series_views_count_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_views_count_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_views_count_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_views_count_max_fields"],
	min?:ValueTypes["voyce_series_views_count_min_fields"],
	stddev?:ValueTypes["voyce_series_views_count_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_views_count_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_views_count_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_views_count_sum_fields"],
	var_pop?:ValueTypes["voyce_series_views_count_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_views_count_var_samp_fields"],
	variance?:ValueTypes["voyce_series_views_count_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_views_count_avg_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_views_count". All fields are combined with a logical 'AND'. */
["voyce_series_views_count_bool_exp"]: {
	_and?:ValueTypes["voyce_series_views_count_bool_exp"][],
	_not?:ValueTypes["voyce_series_views_count_bool_exp"],
	_or?:ValueTypes["voyce_series_views_count_bool_exp"][],
	count?:ValueTypes["bigint_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"]
};
	/** columns and relationships of "voyce.series_views_count_mat" */
["voyce_series_views_count_mat"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_views_count_mat" */
["voyce_series_views_count_mat_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_views_count_mat_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_views_count_mat"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_views_count_mat" */
["voyce_series_views_count_mat_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_views_count_mat_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_views_count_mat_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_views_count_mat_max_fields"],
	min?:ValueTypes["voyce_series_views_count_mat_min_fields"],
	stddev?:ValueTypes["voyce_series_views_count_mat_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_views_count_mat_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_views_count_mat_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_views_count_mat_sum_fields"],
	var_pop?:ValueTypes["voyce_series_views_count_mat_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_views_count_mat_var_samp_fields"],
	variance?:ValueTypes["voyce_series_views_count_mat_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_views_count_mat_avg_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_views_count_mat". All fields are combined with a logical 'AND'. */
["voyce_series_views_count_mat_bool_exp"]: {
	_and?:ValueTypes["voyce_series_views_count_mat_bool_exp"][],
	_not?:ValueTypes["voyce_series_views_count_mat_bool_exp"],
	_or?:ValueTypes["voyce_series_views_count_mat_bool_exp"][],
	actual_count?:ValueTypes["bigint_comparison_exp"],
	count?:ValueTypes["float8_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"]
};
	/** aggregate max on columns */
["voyce_series_views_count_mat_max_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_views_count_mat_min_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Ordering options when selecting data from "voyce.series_views_count_mat". */
["voyce_series_views_count_mat_order_by"]: {
	actual_count?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.series_views_count_mat" */
["voyce_series_views_count_mat_select_column"]:voyce_series_views_count_mat_select_column;
	/** columns and relationships of "voyce.series_views_count_mat_sep30" */
["voyce_series_views_count_mat_sep30"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_views_count_mat_sep30" */
["voyce_series_views_count_mat_sep30_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_views_count_mat_sep30_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_views_count_mat_sep30"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_views_count_mat_sep30" */
["voyce_series_views_count_mat_sep30_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_views_count_mat_sep30_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_views_count_mat_sep30_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_views_count_mat_sep30_max_fields"],
	min?:ValueTypes["voyce_series_views_count_mat_sep30_min_fields"],
	stddev?:ValueTypes["voyce_series_views_count_mat_sep30_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_views_count_mat_sep30_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_views_count_mat_sep30_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_views_count_mat_sep30_sum_fields"],
	var_pop?:ValueTypes["voyce_series_views_count_mat_sep30_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_views_count_mat_sep30_var_samp_fields"],
	variance?:ValueTypes["voyce_series_views_count_mat_sep30_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_views_count_mat_sep30_avg_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_views_count_mat_sep30". All fields are combined with a logical 'AND'. */
["voyce_series_views_count_mat_sep30_bool_exp"]: {
	_and?:ValueTypes["voyce_series_views_count_mat_sep30_bool_exp"][],
	_not?:ValueTypes["voyce_series_views_count_mat_sep30_bool_exp"],
	_or?:ValueTypes["voyce_series_views_count_mat_sep30_bool_exp"][],
	actual_count?:ValueTypes["bigint_comparison_exp"],
	count?:ValueTypes["float8_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"]
};
	/** aggregate max on columns */
["voyce_series_views_count_mat_sep30_max_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_views_count_mat_sep30_min_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Ordering options when selecting data from "voyce.series_views_count_mat_sep30". */
["voyce_series_views_count_mat_sep30_order_by"]: {
	actual_count?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.series_views_count_mat_sep30" */
["voyce_series_views_count_mat_sep30_select_column"]:voyce_series_views_count_mat_sep30_select_column;
	/** aggregate stddev on columns */
["voyce_series_views_count_mat_sep30_stddev_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_views_count_mat_sep30_stddev_pop_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_views_count_mat_sep30_stddev_samp_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_views_count_mat_sep30" */
["voyce_series_views_count_mat_sep30_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_views_count_mat_sep30_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_views_count_mat_sep30_stream_cursor_value_input"]: {
	actual_count?:ValueTypes["bigint"],
	count?:ValueTypes["float8"],
	series_id?:number
};
	/** aggregate sum on columns */
["voyce_series_views_count_mat_sep30_sum_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_pop on columns */
["voyce_series_views_count_mat_sep30_var_pop_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_views_count_mat_sep30_var_samp_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_views_count_mat_sep30_variance_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev on columns */
["voyce_series_views_count_mat_stddev_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_views_count_mat_stddev_pop_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_views_count_mat_stddev_samp_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_views_count_mat" */
["voyce_series_views_count_mat_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_views_count_mat_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_views_count_mat_stream_cursor_value_input"]: {
	actual_count?:ValueTypes["bigint"],
	count?:ValueTypes["float8"],
	series_id?:number
};
	/** aggregate sum on columns */
["voyce_series_views_count_mat_sum_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.series_views_count_mat_v2" */
["voyce_series_views_count_mat_v2"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_views_count_mat_v2" */
["voyce_series_views_count_mat_v2_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_views_count_mat_v2_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_views_count_mat_v2"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_views_count_mat_v2" */
["voyce_series_views_count_mat_v2_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_views_count_mat_v2_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_views_count_mat_v2_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_views_count_mat_v2_max_fields"],
	min?:ValueTypes["voyce_series_views_count_mat_v2_min_fields"],
	stddev?:ValueTypes["voyce_series_views_count_mat_v2_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_views_count_mat_v2_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_views_count_mat_v2_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_views_count_mat_v2_sum_fields"],
	var_pop?:ValueTypes["voyce_series_views_count_mat_v2_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_views_count_mat_v2_var_samp_fields"],
	variance?:ValueTypes["voyce_series_views_count_mat_v2_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_views_count_mat_v2_avg_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_views_count_mat_v2". All fields are combined with a logical 'AND'. */
["voyce_series_views_count_mat_v2_bool_exp"]: {
	_and?:ValueTypes["voyce_series_views_count_mat_v2_bool_exp"][],
	_not?:ValueTypes["voyce_series_views_count_mat_v2_bool_exp"],
	_or?:ValueTypes["voyce_series_views_count_mat_v2_bool_exp"][],
	actual_count?:ValueTypes["bigint_comparison_exp"],
	count?:ValueTypes["float8_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"]
};
	/** aggregate max on columns */
["voyce_series_views_count_mat_v2_max_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_views_count_mat_v2_min_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Ordering options when selecting data from "voyce.series_views_count_mat_v2". */
["voyce_series_views_count_mat_v2_order_by"]: {
	actual_count?:ValueTypes["order_by"],
	count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.series_views_count_mat_v2" */
["voyce_series_views_count_mat_v2_select_column"]:voyce_series_views_count_mat_v2_select_column;
	/** aggregate stddev on columns */
["voyce_series_views_count_mat_v2_stddev_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_views_count_mat_v2_stddev_pop_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_views_count_mat_v2_stddev_samp_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_views_count_mat_v2" */
["voyce_series_views_count_mat_v2_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_views_count_mat_v2_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_views_count_mat_v2_stream_cursor_value_input"]: {
	actual_count?:ValueTypes["bigint"],
	count?:ValueTypes["float8"],
	series_id?:number
};
	/** aggregate sum on columns */
["voyce_series_views_count_mat_v2_sum_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_pop on columns */
["voyce_series_views_count_mat_v2_var_pop_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_views_count_mat_v2_var_samp_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_views_count_mat_v2_variance_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_pop on columns */
["voyce_series_views_count_mat_var_pop_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_views_count_mat_var_samp_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_views_count_mat_variance_fields"]: AliasType<{
	actual_count?:true,
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate max on columns */
["voyce_series_views_count_max_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_views_count_min_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Ordering options when selecting data from "voyce.series_views_count". */
["voyce_series_views_count_order_by"]: {
	count?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.series_views_count" */
["voyce_series_views_count_select_column"]:voyce_series_views_count_select_column;
	/** aggregate stddev on columns */
["voyce_series_views_count_stddev_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_views_count_stddev_pop_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_views_count_stddev_samp_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_views_count" */
["voyce_series_views_count_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_views_count_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_views_count_stream_cursor_value_input"]: {
	count?:ValueTypes["bigint"],
	series_id?:number
};
	/** aggregate sum on columns */
["voyce_series_views_count_sum_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_pop on columns */
["voyce_series_views_count_var_pop_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_views_count_var_samp_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_views_count_variance_fields"]: AliasType<{
	count?:true,
	series_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.series_votes" */
["voyce_series_votes"]: AliasType<{
	created_at?:true,
	date?:true,
	gtag_id?:true,
	id?:true,
	series_id?:true,
	tag?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_votes" */
["voyce_series_votes_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_votes_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_votes"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_votes" */
["voyce_series_votes_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_votes_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_votes_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_votes_max_fields"],
	min?:ValueTypes["voyce_series_votes_min_fields"],
	stddev?:ValueTypes["voyce_series_votes_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_votes_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_votes_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_votes_sum_fields"],
	var_pop?:ValueTypes["voyce_series_votes_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_votes_var_samp_fields"],
	variance?:ValueTypes["voyce_series_votes_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_votes_avg_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_votes". All fields are combined with a logical 'AND'. */
["voyce_series_votes_bool_exp"]: {
	_and?:ValueTypes["voyce_series_votes_bool_exp"][],
	_not?:ValueTypes["voyce_series_votes_bool_exp"],
	_or?:ValueTypes["voyce_series_votes_bool_exp"][],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	date?:ValueTypes["date_comparison_exp"],
	gtag_id?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	tag?:ValueTypes["String_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_votes" */
["voyce_series_votes_constraint"]:voyce_series_votes_constraint;
	/** columns and relationships of "voyce.series_votes_count" */
["voyce_series_votes_count"]: AliasType<{
	count?:true,
	created_at?:true,
	date?:true,
	id?:true,
	series_id?:true,
	tag?:true,
	total?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_votes_count" */
["voyce_series_votes_count_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_votes_count_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_votes_count"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_votes_count" */
["voyce_series_votes_count_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_votes_count_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_votes_count_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_votes_count_max_fields"],
	min?:ValueTypes["voyce_series_votes_count_min_fields"],
	stddev?:ValueTypes["voyce_series_votes_count_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_votes_count_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_votes_count_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_votes_count_sum_fields"],
	var_pop?:ValueTypes["voyce_series_votes_count_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_votes_count_var_samp_fields"],
	variance?:ValueTypes["voyce_series_votes_count_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_votes_count_avg_fields"]: AliasType<{
	count?:true,
	id?:true,
	series_id?:true,
	total?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_votes_count". All fields are combined with a logical 'AND'. */
["voyce_series_votes_count_bool_exp"]: {
	_and?:ValueTypes["voyce_series_votes_count_bool_exp"][],
	_not?:ValueTypes["voyce_series_votes_count_bool_exp"],
	_or?:ValueTypes["voyce_series_votes_count_bool_exp"][],
	count?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	date?:ValueTypes["date_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	tag?:ValueTypes["String_comparison_exp"],
	total?:ValueTypes["Int_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_votes_count" */
["voyce_series_votes_count_constraint"]:voyce_series_votes_count_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_votes_count" */
["voyce_series_votes_count_inc_input"]: {
	count?:number,
	id?:number,
	series_id?:number,
	total?:number
};
	/** input type for inserting data into table "voyce.series_votes_count" */
["voyce_series_votes_count_insert_input"]: {
	count?:number,
	created_at?:ValueTypes["timestamptz"],
	date?:ValueTypes["date"],
	id?:number,
	series_id?:number,
	tag?:string,
	total?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_series_votes_count_max_fields"]: AliasType<{
	count?:true,
	created_at?:true,
	date?:true,
	id?:true,
	series_id?:true,
	tag?:true,
	total?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_votes_count_min_fields"]: AliasType<{
	count?:true,
	created_at?:true,
	date?:true,
	id?:true,
	series_id?:true,
	tag?:true,
	total?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.series_votes_count" */
["voyce_series_votes_count_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_votes_count"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_votes_count" */
["voyce_series_votes_count_on_conflict"]: {
	constraint:ValueTypes["voyce_series_votes_count_constraint"],
	update_columns:ValueTypes["voyce_series_votes_count_update_column"][],
	where?:ValueTypes["voyce_series_votes_count_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_votes_count". */
["voyce_series_votes_count_order_by"]: {
	count?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	date?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	tag?:ValueTypes["order_by"],
	total?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_votes_count */
["voyce_series_votes_count_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_votes_count" */
["voyce_series_votes_count_select_column"]:voyce_series_votes_count_select_column;
	/** input type for updating data in table "voyce.series_votes_count" */
["voyce_series_votes_count_set_input"]: {
	count?:number,
	created_at?:ValueTypes["timestamptz"],
	date?:ValueTypes["date"],
	id?:number,
	series_id?:number,
	tag?:string,
	total?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_series_votes_count_stddev_fields"]: AliasType<{
	count?:true,
	id?:true,
	series_id?:true,
	total?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_votes_count_stddev_pop_fields"]: AliasType<{
	count?:true,
	id?:true,
	series_id?:true,
	total?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_votes_count_stddev_samp_fields"]: AliasType<{
	count?:true,
	id?:true,
	series_id?:true,
	total?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_votes_count" */
["voyce_series_votes_count_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_votes_count_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_votes_count_stream_cursor_value_input"]: {
	count?:number,
	created_at?:ValueTypes["timestamptz"],
	date?:ValueTypes["date"],
	id?:number,
	series_id?:number,
	tag?:string,
	total?:number,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_series_votes_count_sum_fields"]: AliasType<{
	count?:true,
	id?:true,
	series_id?:true,
	total?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.series_votes_count" */
["voyce_series_votes_count_update_column"]:voyce_series_votes_count_update_column;
	["voyce_series_votes_count_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_votes_count_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_votes_count_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_votes_count_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_votes_count_var_pop_fields"]: AliasType<{
	count?:true,
	id?:true,
	series_id?:true,
	total?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_votes_count_var_samp_fields"]: AliasType<{
	count?:true,
	id?:true,
	series_id?:true,
	total?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_votes_count_variance_fields"]: AliasType<{
	count?:true,
	id?:true,
	series_id?:true,
	total?:true,
		__typename?: true
}>;
	/** input type for incrementing numeric columns in table "voyce.series_votes" */
["voyce_series_votes_inc_input"]: {
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.series_votes" */
["voyce_series_votes_insert_input"]: {
	created_at?:ValueTypes["timestamp"],
	date?:ValueTypes["date"],
	gtag_id?:string,
	id?:number,
	series_id?:number,
	tag?:string,
	user_id?:number
};
	/** aggregate max on columns */
["voyce_series_votes_max_fields"]: AliasType<{
	created_at?:true,
	date?:true,
	gtag_id?:true,
	id?:true,
	series_id?:true,
	tag?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_votes_min_fields"]: AliasType<{
	created_at?:true,
	date?:true,
	gtag_id?:true,
	id?:true,
	series_id?:true,
	tag?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.series_votes" */
["voyce_series_votes_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_votes"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_votes" */
["voyce_series_votes_on_conflict"]: {
	constraint:ValueTypes["voyce_series_votes_constraint"],
	update_columns:ValueTypes["voyce_series_votes_update_column"][],
	where?:ValueTypes["voyce_series_votes_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_votes". */
["voyce_series_votes_order_by"]: {
	created_at?:ValueTypes["order_by"],
	date?:ValueTypes["order_by"],
	gtag_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	tag?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_votes */
["voyce_series_votes_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_votes" */
["voyce_series_votes_select_column"]:voyce_series_votes_select_column;
	/** input type for updating data in table "voyce.series_votes" */
["voyce_series_votes_set_input"]: {
	created_at?:ValueTypes["timestamp"],
	date?:ValueTypes["date"],
	gtag_id?:string,
	id?:number,
	series_id?:number,
	tag?:string,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_series_votes_stddev_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_votes_stddev_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_votes_stddev_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_votes" */
["voyce_series_votes_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_votes_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_votes_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamp"],
	date?:ValueTypes["date"],
	gtag_id?:string,
	id?:number,
	series_id?:number,
	tag?:string,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_series_votes_sum_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.series_votes" */
["voyce_series_votes_update_column"]:voyce_series_votes_update_column;
	["voyce_series_votes_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_votes_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_votes_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_votes_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_votes_var_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_votes_var_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_votes_variance_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.series_voting_schedules" */
["voyce_series_voting_schedules"]: AliasType<{
	created_at?:true,
	desc?:true,
	end_date?:true,
	id?:true,
	start_date?:true,
	tag?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_series_voting_schedules_aggregate_fields"],
	nodes?:ValueTypes["voyce_series_voting_schedules"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_series_voting_schedules_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_series_voting_schedules_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_series_voting_schedules_max_fields"],
	min?:ValueTypes["voyce_series_voting_schedules_min_fields"],
	stddev?:ValueTypes["voyce_series_voting_schedules_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_series_voting_schedules_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_series_voting_schedules_stddev_samp_fields"],
	sum?:ValueTypes["voyce_series_voting_schedules_sum_fields"],
	var_pop?:ValueTypes["voyce_series_voting_schedules_var_pop_fields"],
	var_samp?:ValueTypes["voyce_series_voting_schedules_var_samp_fields"],
	variance?:ValueTypes["voyce_series_voting_schedules_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_series_voting_schedules_avg_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.series_voting_schedules". All fields are combined with a logical 'AND'. */
["voyce_series_voting_schedules_bool_exp"]: {
	_and?:ValueTypes["voyce_series_voting_schedules_bool_exp"][],
	_not?:ValueTypes["voyce_series_voting_schedules_bool_exp"],
	_or?:ValueTypes["voyce_series_voting_schedules_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	desc?:ValueTypes["String_comparison_exp"],
	end_date?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	start_date?:ValueTypes["timestamptz_comparison_exp"],
	tag?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_constraint"]:voyce_series_voting_schedules_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_inc_input"]: {
	id?:number
};
	/** input type for inserting data into table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	desc?:string,
	end_date?:ValueTypes["timestamptz"],
	id?:number,
	start_date?:ValueTypes["timestamptz"],
	tag?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_series_voting_schedules_max_fields"]: AliasType<{
	created_at?:true,
	desc?:true,
	end_date?:true,
	id?:true,
	start_date?:true,
	tag?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_series_voting_schedules_min_fields"]: AliasType<{
	created_at?:true,
	desc?:true,
	end_date?:true,
	id?:true,
	start_date?:true,
	tag?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_series_voting_schedules"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_on_conflict"]: {
	constraint:ValueTypes["voyce_series_voting_schedules_constraint"],
	update_columns:ValueTypes["voyce_series_voting_schedules_update_column"][],
	where?:ValueTypes["voyce_series_voting_schedules_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_voting_schedules". */
["voyce_series_voting_schedules_order_by"]: {
	created_at?:ValueTypes["order_by"],
	desc?:ValueTypes["order_by"],
	end_date?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	start_date?:ValueTypes["order_by"],
	tag?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_voting_schedules */
["voyce_series_voting_schedules_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_select_column"]:voyce_series_voting_schedules_select_column;
	/** input type for updating data in table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	desc?:string,
	end_date?:ValueTypes["timestamptz"],
	id?:number,
	start_date?:ValueTypes["timestamptz"],
	tag?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_series_voting_schedules_stddev_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_series_voting_schedules_stddev_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_series_voting_schedules_stddev_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_series_voting_schedules" */
["voyce_series_voting_schedules_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_series_voting_schedules_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_voting_schedules_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	desc?:string,
	end_date?:ValueTypes["timestamptz"],
	id?:number,
	start_date?:ValueTypes["timestamptz"],
	tag?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_series_voting_schedules_sum_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_update_column"]:voyce_series_voting_schedules_update_column;
	["voyce_series_voting_schedules_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_series_voting_schedules_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_series_voting_schedules_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_series_voting_schedules_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_voting_schedules_var_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_series_voting_schedules_var_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_series_voting_schedules_variance_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.sponsorships" */
["voyce_sponsorships"]: AliasType<{
	amount?:true,
	created_at?:true,
	id?:true,
	message?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	/** An object relationship */
	sponsored_user?:ValueTypes["voyce_users"],
	sponsored_user_id?:true,
	/** An object relationship */
	sponsoring_user?:ValueTypes["voyce_users"],
	sponsoring_user_id?:true,
	stripe_subscription?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.sponsorships" */
["voyce_sponsorships_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_sponsorships_aggregate_fields"],
	nodes?:ValueTypes["voyce_sponsorships"],
		__typename?: true
}>;
	["voyce_sponsorships_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_sponsorships_aggregate_bool_exp_count"]
};
	["voyce_sponsorships_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_sponsorships_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_sponsorships_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.sponsorships" */
["voyce_sponsorships_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_sponsorships_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_sponsorships_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_sponsorships_max_fields"],
	min?:ValueTypes["voyce_sponsorships_min_fields"],
	stddev?:ValueTypes["voyce_sponsorships_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_sponsorships_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_sponsorships_stddev_samp_fields"],
	sum?:ValueTypes["voyce_sponsorships_sum_fields"],
	var_pop?:ValueTypes["voyce_sponsorships_var_pop_fields"],
	var_samp?:ValueTypes["voyce_sponsorships_var_samp_fields"],
	variance?:ValueTypes["voyce_sponsorships_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.sponsorships" */
["voyce_sponsorships_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_sponsorships_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_sponsorships_max_order_by"],
	min?:ValueTypes["voyce_sponsorships_min_order_by"],
	stddev?:ValueTypes["voyce_sponsorships_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_sponsorships_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_sponsorships_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_sponsorships_sum_order_by"],
	var_pop?:ValueTypes["voyce_sponsorships_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_sponsorships_var_samp_order_by"],
	variance?:ValueTypes["voyce_sponsorships_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.sponsorships" */
["voyce_sponsorships_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_sponsorships_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_sponsorships_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_sponsorships_avg_fields"]: AliasType<{
	amount?:true,
	id?:true,
	series_id?:true,
	sponsored_user_id?:true,
	sponsoring_user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_avg_order_by"]: {
	amount?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	sponsored_user_id?:ValueTypes["order_by"],
	sponsoring_user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.sponsorships". All fields are combined with a logical 'AND'. */
["voyce_sponsorships_bool_exp"]: {
	_and?:ValueTypes["voyce_sponsorships_bool_exp"][],
	_not?:ValueTypes["voyce_sponsorships_bool_exp"],
	_or?:ValueTypes["voyce_sponsorships_bool_exp"][],
	amount?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	message?:ValueTypes["String_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	sponsored_user?:ValueTypes["voyce_users_bool_exp"],
	sponsored_user_id?:ValueTypes["Int_comparison_exp"],
	sponsoring_user?:ValueTypes["voyce_users_bool_exp"],
	sponsoring_user_id?:ValueTypes["Int_comparison_exp"],
	stripe_subscription?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.sponsorships" */
["voyce_sponsorships_constraint"]:voyce_sponsorships_constraint;
	/** input type for incrementing numeric columns in table "voyce.sponsorships" */
["voyce_sponsorships_inc_input"]: {
	amount?:number,
	id?:number,
	series_id?:number,
	sponsored_user_id?:number,
	sponsoring_user_id?:number
};
	/** input type for inserting data into table "voyce.sponsorships" */
["voyce_sponsorships_insert_input"]: {
	amount?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	message?:string,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	sponsored_user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	sponsored_user_id?:number,
	sponsoring_user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	sponsoring_user_id?:number,
	stripe_subscription?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_sponsorships_max_fields"]: AliasType<{
	amount?:true,
	created_at?:true,
	id?:true,
	message?:true,
	series_id?:true,
	sponsored_user_id?:true,
	sponsoring_user_id?:true,
	stripe_subscription?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_max_order_by"]: {
	amount?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	message?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	sponsored_user_id?:ValueTypes["order_by"],
	sponsoring_user_id?:ValueTypes["order_by"],
	stripe_subscription?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_sponsorships_min_fields"]: AliasType<{
	amount?:true,
	created_at?:true,
	id?:true,
	message?:true,
	series_id?:true,
	sponsored_user_id?:true,
	sponsoring_user_id?:true,
	stripe_subscription?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_min_order_by"]: {
	amount?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	message?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	sponsored_user_id?:ValueTypes["order_by"],
	sponsoring_user_id?:ValueTypes["order_by"],
	stripe_subscription?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.sponsorships" */
["voyce_sponsorships_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_sponsorships"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.sponsorships" */
["voyce_sponsorships_on_conflict"]: {
	constraint:ValueTypes["voyce_sponsorships_constraint"],
	update_columns:ValueTypes["voyce_sponsorships_update_column"][],
	where?:ValueTypes["voyce_sponsorships_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.sponsorships". */
["voyce_sponsorships_order_by"]: {
	amount?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	message?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	sponsored_user?:ValueTypes["voyce_users_order_by"],
	sponsored_user_id?:ValueTypes["order_by"],
	sponsoring_user?:ValueTypes["voyce_users_order_by"],
	sponsoring_user_id?:ValueTypes["order_by"],
	stripe_subscription?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.sponsorships */
["voyce_sponsorships_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.sponsorships" */
["voyce_sponsorships_select_column"]:voyce_sponsorships_select_column;
	/** input type for updating data in table "voyce.sponsorships" */
["voyce_sponsorships_set_input"]: {
	amount?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	message?:string,
	series_id?:number,
	sponsored_user_id?:number,
	sponsoring_user_id?:number,
	stripe_subscription?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_sponsorships_stddev_fields"]: AliasType<{
	amount?:true,
	id?:true,
	series_id?:true,
	sponsored_user_id?:true,
	sponsoring_user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_stddev_order_by"]: {
	amount?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	sponsored_user_id?:ValueTypes["order_by"],
	sponsoring_user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_sponsorships_stddev_pop_fields"]: AliasType<{
	amount?:true,
	id?:true,
	series_id?:true,
	sponsored_user_id?:true,
	sponsoring_user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_stddev_pop_order_by"]: {
	amount?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	sponsored_user_id?:ValueTypes["order_by"],
	sponsoring_user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_sponsorships_stddev_samp_fields"]: AliasType<{
	amount?:true,
	id?:true,
	series_id?:true,
	sponsored_user_id?:true,
	sponsoring_user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_stddev_samp_order_by"]: {
	amount?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	sponsored_user_id?:ValueTypes["order_by"],
	sponsoring_user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_sponsorships" */
["voyce_sponsorships_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_sponsorships_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_sponsorships_stream_cursor_value_input"]: {
	amount?:number,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	message?:string,
	series_id?:number,
	sponsored_user_id?:number,
	sponsoring_user_id?:number,
	stripe_subscription?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_sponsorships_sum_fields"]: AliasType<{
	amount?:true,
	id?:true,
	series_id?:true,
	sponsored_user_id?:true,
	sponsoring_user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_sum_order_by"]: {
	amount?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	sponsored_user_id?:ValueTypes["order_by"],
	sponsoring_user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.sponsorships" */
["voyce_sponsorships_update_column"]:voyce_sponsorships_update_column;
	["voyce_sponsorships_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_sponsorships_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_sponsorships_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_sponsorships_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_sponsorships_var_pop_fields"]: AliasType<{
	amount?:true,
	id?:true,
	series_id?:true,
	sponsored_user_id?:true,
	sponsoring_user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_var_pop_order_by"]: {
	amount?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	sponsored_user_id?:ValueTypes["order_by"],
	sponsoring_user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_sponsorships_var_samp_fields"]: AliasType<{
	amount?:true,
	id?:true,
	series_id?:true,
	sponsored_user_id?:true,
	sponsoring_user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_var_samp_order_by"]: {
	amount?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	sponsored_user_id?:ValueTypes["order_by"],
	sponsoring_user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_sponsorships_variance_fields"]: AliasType<{
	amount?:true,
	id?:true,
	series_id?:true,
	sponsored_user_id?:true,
	sponsoring_user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_variance_order_by"]: {
	amount?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	sponsored_user_id?:ValueTypes["order_by"],
	sponsoring_user_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.states" */
["voyce_states"]: AliasType<{
	country_id?:true,
	date_create?:true,
	description?:true,
	feature?:true,
	id?:true,
	name?:true,
	publish?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.states" */
["voyce_states_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_states_aggregate_fields"],
	nodes?:ValueTypes["voyce_states"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.states" */
["voyce_states_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_states_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_states_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_states_max_fields"],
	min?:ValueTypes["voyce_states_min_fields"],
	stddev?:ValueTypes["voyce_states_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_states_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_states_stddev_samp_fields"],
	sum?:ValueTypes["voyce_states_sum_fields"],
	var_pop?:ValueTypes["voyce_states_var_pop_fields"],
	var_samp?:ValueTypes["voyce_states_var_samp_fields"],
	variance?:ValueTypes["voyce_states_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_states_avg_fields"]: AliasType<{
	country_id?:true,
	feature?:true,
	id?:true,
	publish?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.states". All fields are combined with a logical 'AND'. */
["voyce_states_bool_exp"]: {
	_and?:ValueTypes["voyce_states_bool_exp"][],
	_not?:ValueTypes["voyce_states_bool_exp"],
	_or?:ValueTypes["voyce_states_bool_exp"][],
	country_id?:ValueTypes["Int_comparison_exp"],
	date_create?:ValueTypes["timestamp_comparison_exp"],
	description?:ValueTypes["String_comparison_exp"],
	feature?:ValueTypes["smallint_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	name?:ValueTypes["String_comparison_exp"],
	publish?:ValueTypes["smallint_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.states" */
["voyce_states_constraint"]:voyce_states_constraint;
	/** input type for incrementing numeric columns in table "voyce.states" */
["voyce_states_inc_input"]: {
	country_id?:number,
	feature?:ValueTypes["smallint"],
	id?:number,
	publish?:ValueTypes["smallint"]
};
	/** input type for inserting data into table "voyce.states" */
["voyce_states_insert_input"]: {
	country_id?:number,
	date_create?:ValueTypes["timestamp"],
	description?:string,
	feature?:ValueTypes["smallint"],
	id?:number,
	name?:string,
	publish?:ValueTypes["smallint"]
};
	/** aggregate max on columns */
["voyce_states_max_fields"]: AliasType<{
	country_id?:true,
	date_create?:true,
	description?:true,
	feature?:true,
	id?:true,
	name?:true,
	publish?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_states_min_fields"]: AliasType<{
	country_id?:true,
	date_create?:true,
	description?:true,
	feature?:true,
	id?:true,
	name?:true,
	publish?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.states" */
["voyce_states_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_states"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.states" */
["voyce_states_on_conflict"]: {
	constraint:ValueTypes["voyce_states_constraint"],
	update_columns:ValueTypes["voyce_states_update_column"][],
	where?:ValueTypes["voyce_states_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.states". */
["voyce_states_order_by"]: {
	country_id?:ValueTypes["order_by"],
	date_create?:ValueTypes["order_by"],
	description?:ValueTypes["order_by"],
	feature?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	name?:ValueTypes["order_by"],
	publish?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.states */
["voyce_states_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.states" */
["voyce_states_select_column"]:voyce_states_select_column;
	/** input type for updating data in table "voyce.states" */
["voyce_states_set_input"]: {
	country_id?:number,
	date_create?:ValueTypes["timestamp"],
	description?:string,
	feature?:ValueTypes["smallint"],
	id?:number,
	name?:string,
	publish?:ValueTypes["smallint"]
};
	/** aggregate stddev on columns */
["voyce_states_stddev_fields"]: AliasType<{
	country_id?:true,
	feature?:true,
	id?:true,
	publish?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_states_stddev_pop_fields"]: AliasType<{
	country_id?:true,
	feature?:true,
	id?:true,
	publish?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_states_stddev_samp_fields"]: AliasType<{
	country_id?:true,
	feature?:true,
	id?:true,
	publish?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_states" */
["voyce_states_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_states_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_states_stream_cursor_value_input"]: {
	country_id?:number,
	date_create?:ValueTypes["timestamp"],
	description?:string,
	feature?:ValueTypes["smallint"],
	id?:number,
	name?:string,
	publish?:ValueTypes["smallint"]
};
	/** aggregate sum on columns */
["voyce_states_sum_fields"]: AliasType<{
	country_id?:true,
	feature?:true,
	id?:true,
	publish?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.states" */
["voyce_states_update_column"]:voyce_states_update_column;
	["voyce_states_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_states_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_states_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_states_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_states_var_pop_fields"]: AliasType<{
	country_id?:true,
	feature?:true,
	id?:true,
	publish?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_states_var_samp_fields"]: AliasType<{
	country_id?:true,
	feature?:true,
	id?:true,
	publish?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_states_variance_fields"]: AliasType<{
	country_id?:true,
	feature?:true,
	id?:true,
	publish?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup"]: AliasType<{
	completed_stripe_onboarding?:true,
	cut?:true,
	id?:true,
	stripe_account_id?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_stripe_payouts_setup_aggregate_fields"],
	nodes?:ValueTypes["voyce_stripe_payouts_setup"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_stripe_payouts_setup_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_stripe_payouts_setup_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_stripe_payouts_setup_max_fields"],
	min?:ValueTypes["voyce_stripe_payouts_setup_min_fields"],
	stddev?:ValueTypes["voyce_stripe_payouts_setup_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_stripe_payouts_setup_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_stripe_payouts_setup_stddev_samp_fields"],
	sum?:ValueTypes["voyce_stripe_payouts_setup_sum_fields"],
	var_pop?:ValueTypes["voyce_stripe_payouts_setup_var_pop_fields"],
	var_samp?:ValueTypes["voyce_stripe_payouts_setup_var_samp_fields"],
	variance?:ValueTypes["voyce_stripe_payouts_setup_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_stripe_payouts_setup_avg_fields"]: AliasType<{
	cut?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.stripe_payouts_setup". All fields are combined with a logical 'AND'. */
["voyce_stripe_payouts_setup_bool_exp"]: {
	_and?:ValueTypes["voyce_stripe_payouts_setup_bool_exp"][],
	_not?:ValueTypes["voyce_stripe_payouts_setup_bool_exp"],
	_or?:ValueTypes["voyce_stripe_payouts_setup_bool_exp"][],
	completed_stripe_onboarding?:ValueTypes["Boolean_comparison_exp"],
	cut?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	stripe_account_id?:ValueTypes["String_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_constraint"]:voyce_stripe_payouts_setup_constraint;
	/** input type for incrementing numeric columns in table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_inc_input"]: {
	cut?:number,
	id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_insert_input"]: {
	completed_stripe_onboarding?:boolean,
	cut?:number,
	id?:number,
	stripe_account_id?:string,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_stripe_payouts_setup_max_fields"]: AliasType<{
	cut?:true,
	id?:true,
	stripe_account_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_stripe_payouts_setup_min_fields"]: AliasType<{
	cut?:true,
	id?:true,
	stripe_account_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_stripe_payouts_setup"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_stripe_payouts_setup_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_stripe_payouts_setup_on_conflict"]
};
	/** on_conflict condition type for table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_on_conflict"]: {
	constraint:ValueTypes["voyce_stripe_payouts_setup_constraint"],
	update_columns:ValueTypes["voyce_stripe_payouts_setup_update_column"][],
	where?:ValueTypes["voyce_stripe_payouts_setup_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.stripe_payouts_setup". */
["voyce_stripe_payouts_setup_order_by"]: {
	completed_stripe_onboarding?:ValueTypes["order_by"],
	cut?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	stripe_account_id?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.stripe_payouts_setup */
["voyce_stripe_payouts_setup_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_select_column"]:voyce_stripe_payouts_setup_select_column;
	/** input type for updating data in table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_set_input"]: {
	completed_stripe_onboarding?:boolean,
	cut?:number,
	id?:number,
	stripe_account_id?:string,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_stripe_payouts_setup_stddev_fields"]: AliasType<{
	cut?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_stripe_payouts_setup_stddev_pop_fields"]: AliasType<{
	cut?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_stripe_payouts_setup_stddev_samp_fields"]: AliasType<{
	cut?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_stripe_payouts_setup" */
["voyce_stripe_payouts_setup_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_stripe_payouts_setup_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_stripe_payouts_setup_stream_cursor_value_input"]: {
	completed_stripe_onboarding?:boolean,
	cut?:number,
	id?:number,
	stripe_account_id?:string,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_stripe_payouts_setup_sum_fields"]: AliasType<{
	cut?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_update_column"]:voyce_stripe_payouts_setup_update_column;
	["voyce_stripe_payouts_setup_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_stripe_payouts_setup_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_stripe_payouts_setup_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_stripe_payouts_setup_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_stripe_payouts_setup_var_pop_fields"]: AliasType<{
	cut?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_stripe_payouts_setup_var_samp_fields"]: AliasType<{
	cut?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_stripe_payouts_setup_variance_fields"]: AliasType<{
	cut?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.studio" */
["voyce_studio"]: AliasType<{
	active?:true,
	avatar?:true,
	created_at?:true,
	description?:true,
	discord?:true,
	facebook?:true,
followers?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_followers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_followers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_followers_bool_exp"]},ValueTypes["voyce_studio_followers"]],
followers_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_followers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_followers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_followers_bool_exp"]},ValueTypes["voyce_studio_followers_aggregate"]],
	id?:true,
	instagram?:true,
	layout?:true,
	logo?:true,
	order?:true,
props?: [{	/** JSON select path */
	path?:string},true],
series?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_series_bool_exp"]},ValueTypes["voyce_studio_series"]],
series_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_studio_series_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_studio_series_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_studio_series_bool_exp"]},ValueTypes["voyce_studio_series_aggregate"]],
	slug?:true,
	tiktok?:true,
	title?:true,
	twitter?:true,
	updated_at?:true,
	/** An object relationship */
	views?:ValueTypes["voyce_studio_views"],
	website?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.studio" */
["voyce_studio_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_studio_aggregate_fields"],
	nodes?:ValueTypes["voyce_studio"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.studio" */
["voyce_studio_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_studio_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_studio_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_studio_max_fields"],
	min?:ValueTypes["voyce_studio_min_fields"],
	stddev?:ValueTypes["voyce_studio_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_studio_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_studio_stddev_samp_fields"],
	sum?:ValueTypes["voyce_studio_sum_fields"],
	var_pop?:ValueTypes["voyce_studio_var_pop_fields"],
	var_samp?:ValueTypes["voyce_studio_var_samp_fields"],
	variance?:ValueTypes["voyce_studio_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_studio_avg_fields"]: AliasType<{
	id?:true,
	layout?:true,
	order?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.studio". All fields are combined with a logical 'AND'. */
["voyce_studio_bool_exp"]: {
	_and?:ValueTypes["voyce_studio_bool_exp"][],
	_not?:ValueTypes["voyce_studio_bool_exp"],
	_or?:ValueTypes["voyce_studio_bool_exp"][],
	active?:ValueTypes["Boolean_comparison_exp"],
	avatar?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	description?:ValueTypes["String_comparison_exp"],
	discord?:ValueTypes["String_comparison_exp"],
	facebook?:ValueTypes["String_comparison_exp"],
	followers?:ValueTypes["voyce_studio_followers_bool_exp"],
	followers_aggregate?:ValueTypes["voyce_studio_followers_aggregate_bool_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	instagram?:ValueTypes["String_comparison_exp"],
	layout?:ValueTypes["smallint_comparison_exp"],
	logo?:ValueTypes["String_comparison_exp"],
	order?:ValueTypes["Int_comparison_exp"],
	props?:ValueTypes["json_comparison_exp"],
	series?:ValueTypes["voyce_studio_series_bool_exp"],
	series_aggregate?:ValueTypes["voyce_studio_series_aggregate_bool_exp"],
	slug?:ValueTypes["String_comparison_exp"],
	tiktok?:ValueTypes["String_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	twitter?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	views?:ValueTypes["voyce_studio_views_bool_exp"],
	website?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.studio" */
["voyce_studio_constraint"]:voyce_studio_constraint;
	/** columns and relationships of "voyce.studio_followers" */
["voyce_studio_followers"]: AliasType<{
	created_at?:true,
	id?:true,
	studio_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.studio_followers" */
["voyce_studio_followers_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_studio_followers_aggregate_fields"],
	nodes?:ValueTypes["voyce_studio_followers"],
		__typename?: true
}>;
	["voyce_studio_followers_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_studio_followers_aggregate_bool_exp_count"]
};
	["voyce_studio_followers_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_studio_followers_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_studio_followers_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.studio_followers" */
["voyce_studio_followers_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_studio_followers_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_studio_followers_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_studio_followers_max_fields"],
	min?:ValueTypes["voyce_studio_followers_min_fields"],
	stddev?:ValueTypes["voyce_studio_followers_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_studio_followers_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_studio_followers_stddev_samp_fields"],
	sum?:ValueTypes["voyce_studio_followers_sum_fields"],
	var_pop?:ValueTypes["voyce_studio_followers_var_pop_fields"],
	var_samp?:ValueTypes["voyce_studio_followers_var_samp_fields"],
	variance?:ValueTypes["voyce_studio_followers_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.studio_followers" */
["voyce_studio_followers_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_studio_followers_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_studio_followers_max_order_by"],
	min?:ValueTypes["voyce_studio_followers_min_order_by"],
	stddev?:ValueTypes["voyce_studio_followers_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_studio_followers_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_studio_followers_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_studio_followers_sum_order_by"],
	var_pop?:ValueTypes["voyce_studio_followers_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_studio_followers_var_samp_order_by"],
	variance?:ValueTypes["voyce_studio_followers_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.studio_followers" */
["voyce_studio_followers_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_studio_followers_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_studio_followers_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_studio_followers_avg_fields"]: AliasType<{
	id?:true,
	studio_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.studio_followers". All fields are combined with a logical 'AND'. */
["voyce_studio_followers_bool_exp"]: {
	_and?:ValueTypes["voyce_studio_followers_bool_exp"][],
	_not?:ValueTypes["voyce_studio_followers_bool_exp"],
	_or?:ValueTypes["voyce_studio_followers_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	studio_id?:ValueTypes["Int_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.studio_followers" */
["voyce_studio_followers_constraint"]:voyce_studio_followers_constraint;
	/** input type for incrementing numeric columns in table "voyce.studio_followers" */
["voyce_studio_followers_inc_input"]: {
	id?:number,
	studio_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.studio_followers" */
["voyce_studio_followers_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	studio_id?:number,
	user_id?:number
};
	/** aggregate max on columns */
["voyce_studio_followers_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	studio_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_max_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_studio_followers_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	studio_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_min_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.studio_followers" */
["voyce_studio_followers_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_studio_followers"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.studio_followers" */
["voyce_studio_followers_on_conflict"]: {
	constraint:ValueTypes["voyce_studio_followers_constraint"],
	update_columns:ValueTypes["voyce_studio_followers_update_column"][],
	where?:ValueTypes["voyce_studio_followers_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.studio_followers". */
["voyce_studio_followers_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.studio_followers */
["voyce_studio_followers_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.studio_followers" */
["voyce_studio_followers_select_column"]:voyce_studio_followers_select_column;
	/** input type for updating data in table "voyce.studio_followers" */
["voyce_studio_followers_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	studio_id?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_studio_followers_stddev_fields"]: AliasType<{
	id?:true,
	studio_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_studio_followers_stddev_pop_fields"]: AliasType<{
	id?:true,
	studio_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_studio_followers_stddev_samp_fields"]: AliasType<{
	id?:true,
	studio_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_studio_followers" */
["voyce_studio_followers_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_studio_followers_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_studio_followers_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	studio_id?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_studio_followers_sum_fields"]: AliasType<{
	id?:true,
	studio_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.studio_followers" */
["voyce_studio_followers_update_column"]:voyce_studio_followers_update_column;
	["voyce_studio_followers_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_studio_followers_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_studio_followers_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_studio_followers_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_studio_followers_var_pop_fields"]: AliasType<{
	id?:true,
	studio_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_studio_followers_var_samp_fields"]: AliasType<{
	id?:true,
	studio_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_studio_followers_variance_fields"]: AliasType<{
	id?:true,
	studio_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** input type for incrementing numeric columns in table "voyce.studio" */
["voyce_studio_inc_input"]: {
	id?:number,
	layout?:ValueTypes["smallint"],
	order?:number
};
	/** input type for inserting data into table "voyce.studio" */
["voyce_studio_insert_input"]: {
	active?:boolean,
	avatar?:string,
	created_at?:ValueTypes["timestamptz"],
	description?:string,
	discord?:string,
	facebook?:string,
	followers?:ValueTypes["voyce_studio_followers_arr_rel_insert_input"],
	id?:number,
	instagram?:string,
	layout?:ValueTypes["smallint"],
	logo?:string,
	order?:number,
	props?:ValueTypes["json"],
	series?:ValueTypes["voyce_studio_series_arr_rel_insert_input"],
	slug?:string,
	tiktok?:string,
	title?:string,
	twitter?:string,
	updated_at?:ValueTypes["timestamptz"],
	views?:ValueTypes["voyce_studio_views_obj_rel_insert_input"],
	website?:string
};
	/** aggregate max on columns */
["voyce_studio_max_fields"]: AliasType<{
	avatar?:true,
	created_at?:true,
	description?:true,
	discord?:true,
	facebook?:true,
	id?:true,
	instagram?:true,
	layout?:true,
	logo?:true,
	order?:true,
	slug?:true,
	tiktok?:true,
	title?:true,
	twitter?:true,
	updated_at?:true,
	website?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_studio_min_fields"]: AliasType<{
	avatar?:true,
	created_at?:true,
	description?:true,
	discord?:true,
	facebook?:true,
	id?:true,
	instagram?:true,
	layout?:true,
	logo?:true,
	order?:true,
	slug?:true,
	tiktok?:true,
	title?:true,
	twitter?:true,
	updated_at?:true,
	website?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.studio" */
["voyce_studio_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_studio"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.studio" */
["voyce_studio_on_conflict"]: {
	constraint:ValueTypes["voyce_studio_constraint"],
	update_columns:ValueTypes["voyce_studio_update_column"][],
	where?:ValueTypes["voyce_studio_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.studio". */
["voyce_studio_order_by"]: {
	active?:ValueTypes["order_by"],
	avatar?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	description?:ValueTypes["order_by"],
	discord?:ValueTypes["order_by"],
	facebook?:ValueTypes["order_by"],
	followers_aggregate?:ValueTypes["voyce_studio_followers_aggregate_order_by"],
	id?:ValueTypes["order_by"],
	instagram?:ValueTypes["order_by"],
	layout?:ValueTypes["order_by"],
	logo?:ValueTypes["order_by"],
	order?:ValueTypes["order_by"],
	props?:ValueTypes["order_by"],
	series_aggregate?:ValueTypes["voyce_studio_series_aggregate_order_by"],
	slug?:ValueTypes["order_by"],
	tiktok?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	twitter?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	views?:ValueTypes["voyce_studio_views_order_by"],
	website?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.studio */
["voyce_studio_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.studio" */
["voyce_studio_select_column"]:voyce_studio_select_column;
	/** columns and relationships of "voyce.studio_series" */
["voyce_studio_series"]: AliasType<{
	id?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	/** An object relationship */
	series_views?:ValueTypes["voyce_pg_rs_series_views_count"],
	studio_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.studio_series" */
["voyce_studio_series_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_studio_series_aggregate_fields"],
	nodes?:ValueTypes["voyce_studio_series"],
		__typename?: true
}>;
	["voyce_studio_series_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_studio_series_aggregate_bool_exp_count"]
};
	["voyce_studio_series_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_studio_series_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_studio_series_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.studio_series" */
["voyce_studio_series_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_studio_series_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_studio_series_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_studio_series_max_fields"],
	min?:ValueTypes["voyce_studio_series_min_fields"],
	stddev?:ValueTypes["voyce_studio_series_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_studio_series_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_studio_series_stddev_samp_fields"],
	sum?:ValueTypes["voyce_studio_series_sum_fields"],
	var_pop?:ValueTypes["voyce_studio_series_var_pop_fields"],
	var_samp?:ValueTypes["voyce_studio_series_var_samp_fields"],
	variance?:ValueTypes["voyce_studio_series_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.studio_series" */
["voyce_studio_series_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_studio_series_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_studio_series_max_order_by"],
	min?:ValueTypes["voyce_studio_series_min_order_by"],
	stddev?:ValueTypes["voyce_studio_series_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_studio_series_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_studio_series_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_studio_series_sum_order_by"],
	var_pop?:ValueTypes["voyce_studio_series_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_studio_series_var_samp_order_by"],
	variance?:ValueTypes["voyce_studio_series_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.studio_series" */
["voyce_studio_series_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_studio_series_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_studio_series_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_studio_series_avg_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.studio_series" */
["voyce_studio_series_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.studio_series". All fields are combined with a logical 'AND'. */
["voyce_studio_series_bool_exp"]: {
	_and?:ValueTypes["voyce_studio_series_bool_exp"][],
	_not?:ValueTypes["voyce_studio_series_bool_exp"],
	_or?:ValueTypes["voyce_studio_series_bool_exp"][],
	id?:ValueTypes["Int_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	series_views?:ValueTypes["voyce_pg_rs_series_views_count_bool_exp"],
	studio_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.studio_series" */
["voyce_studio_series_constraint"]:voyce_studio_series_constraint;
	/** input type for incrementing numeric columns in table "voyce.studio_series" */
["voyce_studio_series_inc_input"]: {
	id?:number,
	series_id?:number,
	studio_id?:number
};
	/** input type for inserting data into table "voyce.studio_series" */
["voyce_studio_series_insert_input"]: {
	id?:number,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	series_views?:ValueTypes["voyce_pg_rs_series_views_count_obj_rel_insert_input"],
	studio_id?:number
};
	/** aggregate max on columns */
["voyce_studio_series_max_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.studio_series" */
["voyce_studio_series_max_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_studio_series_min_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.studio_series" */
["voyce_studio_series_min_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.studio_series" */
["voyce_studio_series_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_studio_series"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.studio_series" */
["voyce_studio_series_on_conflict"]: {
	constraint:ValueTypes["voyce_studio_series_constraint"],
	update_columns:ValueTypes["voyce_studio_series_update_column"][],
	where?:ValueTypes["voyce_studio_series_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.studio_series". */
["voyce_studio_series_order_by"]: {
	id?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	series_views?:ValueTypes["voyce_pg_rs_series_views_count_order_by"],
	studio_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.studio_series */
["voyce_studio_series_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.studio_series" */
["voyce_studio_series_select_column"]:voyce_studio_series_select_column;
	/** input type for updating data in table "voyce.studio_series" */
["voyce_studio_series_set_input"]: {
	id?:number,
	series_id?:number,
	studio_id?:number
};
	/** aggregate stddev on columns */
["voyce_studio_series_stddev_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.studio_series" */
["voyce_studio_series_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_studio_series_stddev_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.studio_series" */
["voyce_studio_series_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_studio_series_stddev_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.studio_series" */
["voyce_studio_series_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_studio_series" */
["voyce_studio_series_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_studio_series_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_studio_series_stream_cursor_value_input"]: {
	id?:number,
	series_id?:number,
	studio_id?:number
};
	/** aggregate sum on columns */
["voyce_studio_series_sum_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.studio_series" */
["voyce_studio_series_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.studio_series" */
["voyce_studio_series_update_column"]:voyce_studio_series_update_column;
	["voyce_studio_series_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_studio_series_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_studio_series_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_studio_series_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_studio_series_var_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.studio_series" */
["voyce_studio_series_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_studio_series_var_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.studio_series" */
["voyce_studio_series_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_studio_series_variance_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.studio_series" */
["voyce_studio_series_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"]
};
	/** input type for updating data in table "voyce.studio" */
["voyce_studio_set_input"]: {
	active?:boolean,
	avatar?:string,
	created_at?:ValueTypes["timestamptz"],
	description?:string,
	discord?:string,
	facebook?:string,
	id?:number,
	instagram?:string,
	layout?:ValueTypes["smallint"],
	logo?:string,
	order?:number,
	props?:ValueTypes["json"],
	slug?:string,
	tiktok?:string,
	title?:string,
	twitter?:string,
	updated_at?:ValueTypes["timestamptz"],
	website?:string
};
	/** aggregate stddev on columns */
["voyce_studio_stddev_fields"]: AliasType<{
	id?:true,
	layout?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_studio_stddev_pop_fields"]: AliasType<{
	id?:true,
	layout?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_studio_stddev_samp_fields"]: AliasType<{
	id?:true,
	layout?:true,
	order?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_studio" */
["voyce_studio_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_studio_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_studio_stream_cursor_value_input"]: {
	active?:boolean,
	avatar?:string,
	created_at?:ValueTypes["timestamptz"],
	description?:string,
	discord?:string,
	facebook?:string,
	id?:number,
	instagram?:string,
	layout?:ValueTypes["smallint"],
	logo?:string,
	order?:number,
	props?:ValueTypes["json"],
	slug?:string,
	tiktok?:string,
	title?:string,
	twitter?:string,
	updated_at?:ValueTypes["timestamptz"],
	website?:string
};
	/** aggregate sum on columns */
["voyce_studio_sum_fields"]: AliasType<{
	id?:true,
	layout?:true,
	order?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.studio" */
["voyce_studio_update_column"]:voyce_studio_update_column;
	["voyce_studio_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_studio_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_studio_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_studio_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_studio_var_pop_fields"]: AliasType<{
	id?:true,
	layout?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_studio_var_samp_fields"]: AliasType<{
	id?:true,
	layout?:true,
	order?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_studio_variance_fields"]: AliasType<{
	id?:true,
	layout?:true,
	order?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.studio_views" */
["voyce_studio_views"]: AliasType<{
	count?:true,
	id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.studio_views" */
["voyce_studio_views_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_studio_views_aggregate_fields"],
	nodes?:ValueTypes["voyce_studio_views"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.studio_views" */
["voyce_studio_views_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_studio_views_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_studio_views_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_studio_views_max_fields"],
	min?:ValueTypes["voyce_studio_views_min_fields"],
	stddev?:ValueTypes["voyce_studio_views_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_studio_views_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_studio_views_stddev_samp_fields"],
	sum?:ValueTypes["voyce_studio_views_sum_fields"],
	var_pop?:ValueTypes["voyce_studio_views_var_pop_fields"],
	var_samp?:ValueTypes["voyce_studio_views_var_samp_fields"],
	variance?:ValueTypes["voyce_studio_views_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_studio_views_avg_fields"]: AliasType<{
	count?:true,
	id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.studio_views". All fields are combined with a logical 'AND'. */
["voyce_studio_views_bool_exp"]: {
	_and?:ValueTypes["voyce_studio_views_bool_exp"][],
	_not?:ValueTypes["voyce_studio_views_bool_exp"],
	_or?:ValueTypes["voyce_studio_views_bool_exp"][],
	count?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	studio_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.studio_views" */
["voyce_studio_views_constraint"]:voyce_studio_views_constraint;
	/** input type for incrementing numeric columns in table "voyce.studio_views" */
["voyce_studio_views_inc_input"]: {
	count?:number,
	id?:number,
	studio_id?:number
};
	/** input type for inserting data into table "voyce.studio_views" */
["voyce_studio_views_insert_input"]: {
	count?:number,
	id?:number,
	studio_id?:number
};
	/** aggregate max on columns */
["voyce_studio_views_max_fields"]: AliasType<{
	count?:true,
	id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_studio_views_min_fields"]: AliasType<{
	count?:true,
	id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.studio_views" */
["voyce_studio_views_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_studio_views"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.studio_views" */
["voyce_studio_views_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_studio_views_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_studio_views_on_conflict"]
};
	/** on_conflict condition type for table "voyce.studio_views" */
["voyce_studio_views_on_conflict"]: {
	constraint:ValueTypes["voyce_studio_views_constraint"],
	update_columns:ValueTypes["voyce_studio_views_update_column"][],
	where?:ValueTypes["voyce_studio_views_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.studio_views". */
["voyce_studio_views_order_by"]: {
	count?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	studio_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.studio_views */
["voyce_studio_views_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.studio_views" */
["voyce_studio_views_select_column"]:voyce_studio_views_select_column;
	/** input type for updating data in table "voyce.studio_views" */
["voyce_studio_views_set_input"]: {
	count?:number,
	id?:number,
	studio_id?:number
};
	/** aggregate stddev on columns */
["voyce_studio_views_stddev_fields"]: AliasType<{
	count?:true,
	id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_studio_views_stddev_pop_fields"]: AliasType<{
	count?:true,
	id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_studio_views_stddev_samp_fields"]: AliasType<{
	count?:true,
	id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_studio_views" */
["voyce_studio_views_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_studio_views_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_studio_views_stream_cursor_value_input"]: {
	count?:number,
	id?:number,
	studio_id?:number
};
	/** aggregate sum on columns */
["voyce_studio_views_sum_fields"]: AliasType<{
	count?:true,
	id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.studio_views" */
["voyce_studio_views_update_column"]:voyce_studio_views_update_column;
	["voyce_studio_views_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_studio_views_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_studio_views_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_studio_views_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_studio_views_var_pop_fields"]: AliasType<{
	count?:true,
	id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_studio_views_var_samp_fields"]: AliasType<{
	count?:true,
	id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_studio_views_variance_fields"]: AliasType<{
	count?:true,
	id?:true,
	studio_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.system_settings" */
["voyce_system_settings"]: AliasType<{
	created_at?:true,
	id?:true,
	key?:true,
	updated_at?:true,
	value?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.system_settings" */
["voyce_system_settings_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_system_settings_aggregate_fields"],
	nodes?:ValueTypes["voyce_system_settings"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.system_settings" */
["voyce_system_settings_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_system_settings_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_system_settings_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_system_settings_max_fields"],
	min?:ValueTypes["voyce_system_settings_min_fields"],
	stddev?:ValueTypes["voyce_system_settings_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_system_settings_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_system_settings_stddev_samp_fields"],
	sum?:ValueTypes["voyce_system_settings_sum_fields"],
	var_pop?:ValueTypes["voyce_system_settings_var_pop_fields"],
	var_samp?:ValueTypes["voyce_system_settings_var_samp_fields"],
	variance?:ValueTypes["voyce_system_settings_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_system_settings_avg_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.system_settings". All fields are combined with a logical 'AND'. */
["voyce_system_settings_bool_exp"]: {
	_and?:ValueTypes["voyce_system_settings_bool_exp"][],
	_not?:ValueTypes["voyce_system_settings_bool_exp"],
	_or?:ValueTypes["voyce_system_settings_bool_exp"][],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	key?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamp_comparison_exp"],
	value?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.system_settings" */
["voyce_system_settings_constraint"]:voyce_system_settings_constraint;
	/** input type for incrementing numeric columns in table "voyce.system_settings" */
["voyce_system_settings_inc_input"]: {
	id?:number
};
	/** input type for inserting data into table "voyce.system_settings" */
["voyce_system_settings_insert_input"]: {
	created_at?:ValueTypes["timestamp"],
	id?:number,
	key?:string,
	updated_at?:ValueTypes["timestamp"],
	value?:string
};
	/** aggregate max on columns */
["voyce_system_settings_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	key?:true,
	updated_at?:true,
	value?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_system_settings_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	key?:true,
	updated_at?:true,
	value?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.system_settings" */
["voyce_system_settings_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_system_settings"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.system_settings" */
["voyce_system_settings_on_conflict"]: {
	constraint:ValueTypes["voyce_system_settings_constraint"],
	update_columns:ValueTypes["voyce_system_settings_update_column"][],
	where?:ValueTypes["voyce_system_settings_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.system_settings". */
["voyce_system_settings_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	key?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	value?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.system_settings */
["voyce_system_settings_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.system_settings" */
["voyce_system_settings_select_column"]:voyce_system_settings_select_column;
	/** input type for updating data in table "voyce.system_settings" */
["voyce_system_settings_set_input"]: {
	created_at?:ValueTypes["timestamp"],
	id?:number,
	key?:string,
	updated_at?:ValueTypes["timestamp"],
	value?:string
};
	/** aggregate stddev on columns */
["voyce_system_settings_stddev_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_system_settings_stddev_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_system_settings_stddev_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_system_settings" */
["voyce_system_settings_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_system_settings_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_system_settings_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamp"],
	id?:number,
	key?:string,
	updated_at?:ValueTypes["timestamp"],
	value?:string
};
	/** aggregate sum on columns */
["voyce_system_settings_sum_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.system_settings" */
["voyce_system_settings_update_column"]:voyce_system_settings_update_column;
	["voyce_system_settings_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_system_settings_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_system_settings_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_system_settings_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_system_settings_var_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_system_settings_var_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_system_settings_variance_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.top_genres" */
["voyce_top_genres"]: AliasType<{
	count?:true,
	/** An object relationship */
	genre?:ValueTypes["voyce_genres"],
	genre_id?:true,
series_genres?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_genre_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_genre_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_genre_bool_exp"]},ValueTypes["voyce_series_genre"]],
series_genres_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_genre_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_genre_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_genre_bool_exp"]},ValueTypes["voyce_series_genre_aggregate"]],
		__typename?: true
}>;
	/** aggregated selection of "voyce.top_genres" */
["voyce_top_genres_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_top_genres_aggregate_fields"],
	nodes?:ValueTypes["voyce_top_genres"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.top_genres" */
["voyce_top_genres_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_top_genres_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_top_genres_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_top_genres_max_fields"],
	min?:ValueTypes["voyce_top_genres_min_fields"],
	stddev?:ValueTypes["voyce_top_genres_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_top_genres_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_top_genres_stddev_samp_fields"],
	sum?:ValueTypes["voyce_top_genres_sum_fields"],
	var_pop?:ValueTypes["voyce_top_genres_var_pop_fields"],
	var_samp?:ValueTypes["voyce_top_genres_var_samp_fields"],
	variance?:ValueTypes["voyce_top_genres_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_top_genres_avg_fields"]: AliasType<{
	count?:true,
	genre_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.top_genres". All fields are combined with a logical 'AND'. */
["voyce_top_genres_bool_exp"]: {
	_and?:ValueTypes["voyce_top_genres_bool_exp"][],
	_not?:ValueTypes["voyce_top_genres_bool_exp"],
	_or?:ValueTypes["voyce_top_genres_bool_exp"][],
	count?:ValueTypes["bigint_comparison_exp"],
	genre?:ValueTypes["voyce_genres_bool_exp"],
	genre_id?:ValueTypes["Int_comparison_exp"],
	series_genres?:ValueTypes["voyce_series_genre_bool_exp"],
	series_genres_aggregate?:ValueTypes["voyce_series_genre_aggregate_bool_exp"]
};
	/** aggregate max on columns */
["voyce_top_genres_max_fields"]: AliasType<{
	count?:true,
	genre_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_top_genres_min_fields"]: AliasType<{
	count?:true,
	genre_id?:true,
		__typename?: true
}>;
	/** Ordering options when selecting data from "voyce.top_genres". */
["voyce_top_genres_order_by"]: {
	count?:ValueTypes["order_by"],
	genre?:ValueTypes["voyce_genres_order_by"],
	genre_id?:ValueTypes["order_by"],
	series_genres_aggregate?:ValueTypes["voyce_series_genre_aggregate_order_by"]
};
	/** select columns of table "voyce.top_genres" */
["voyce_top_genres_select_column"]:voyce_top_genres_select_column;
	/** aggregate stddev on columns */
["voyce_top_genres_stddev_fields"]: AliasType<{
	count?:true,
	genre_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_top_genres_stddev_pop_fields"]: AliasType<{
	count?:true,
	genre_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_top_genres_stddev_samp_fields"]: AliasType<{
	count?:true,
	genre_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_top_genres" */
["voyce_top_genres_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_top_genres_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_top_genres_stream_cursor_value_input"]: {
	count?:ValueTypes["bigint"],
	genre_id?:number
};
	/** aggregate sum on columns */
["voyce_top_genres_sum_fields"]: AliasType<{
	count?:true,
	genre_id?:true,
		__typename?: true
}>;
	/** aggregate var_pop on columns */
["voyce_top_genres_var_pop_fields"]: AliasType<{
	count?:true,
	genre_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_top_genres_var_samp_fields"]: AliasType<{
	count?:true,
	genre_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_top_genres_variance_fields"]: AliasType<{
	count?:true,
	genre_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.transaction_logs" */
["voyce_transaction_logs"]: AliasType<{
	amount?:true,
	coins_type?:true,
	created_at?:true,
	id?:true,
	item_id?:true,
	product_id?:true,
	receipt_id?:true,
	receiver_id?:true,
	sender_id?:true,
	title?:true,
	type?:true,
	/** An object relationship */
	userByReceiverId?:ValueTypes["voyce_users"],
	/** An object relationship */
	userBySenderId?:ValueTypes["voyce_users"],
		__typename?: true
}>;
	/** aggregated selection of "voyce.transaction_logs" */
["voyce_transaction_logs_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_transaction_logs_aggregate_fields"],
	nodes?:ValueTypes["voyce_transaction_logs"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.transaction_logs" */
["voyce_transaction_logs_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_transaction_logs_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_transaction_logs_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_transaction_logs_max_fields"],
	min?:ValueTypes["voyce_transaction_logs_min_fields"],
	stddev?:ValueTypes["voyce_transaction_logs_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_transaction_logs_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_transaction_logs_stddev_samp_fields"],
	sum?:ValueTypes["voyce_transaction_logs_sum_fields"],
	var_pop?:ValueTypes["voyce_transaction_logs_var_pop_fields"],
	var_samp?:ValueTypes["voyce_transaction_logs_var_samp_fields"],
	variance?:ValueTypes["voyce_transaction_logs_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_transaction_logs_avg_fields"]: AliasType<{
	amount?:true,
	id?:true,
	item_id?:true,
	product_id?:true,
	receipt_id?:true,
	receiver_id?:true,
	sender_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.transaction_logs". All fields are combined with a logical 'AND'. */
["voyce_transaction_logs_bool_exp"]: {
	_and?:ValueTypes["voyce_transaction_logs_bool_exp"][],
	_not?:ValueTypes["voyce_transaction_logs_bool_exp"],
	_or?:ValueTypes["voyce_transaction_logs_bool_exp"][],
	amount?:ValueTypes["Int_comparison_exp"],
	coins_type?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	item_id?:ValueTypes["Int_comparison_exp"],
	product_id?:ValueTypes["Int_comparison_exp"],
	receipt_id?:ValueTypes["Int_comparison_exp"],
	receiver_id?:ValueTypes["Int_comparison_exp"],
	sender_id?:ValueTypes["Int_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"],
	userByReceiverId?:ValueTypes["voyce_users_bool_exp"],
	userBySenderId?:ValueTypes["voyce_users_bool_exp"]
};
	/** unique or primary key constraints on table "voyce.transaction_logs" */
["voyce_transaction_logs_constraint"]:voyce_transaction_logs_constraint;
	/** input type for incrementing numeric columns in table "voyce.transaction_logs" */
["voyce_transaction_logs_inc_input"]: {
	amount?:number,
	id?:number,
	item_id?:number,
	product_id?:number,
	receipt_id?:number,
	receiver_id?:number,
	sender_id?:number
};
	/** input type for inserting data into table "voyce.transaction_logs" */
["voyce_transaction_logs_insert_input"]: {
	amount?:number,
	coins_type?:string,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	item_id?:number,
	product_id?:number,
	receipt_id?:number,
	receiver_id?:number,
	sender_id?:number,
	title?:string,
	type?:string,
	userByReceiverId?:ValueTypes["voyce_users_obj_rel_insert_input"],
	userBySenderId?:ValueTypes["voyce_users_obj_rel_insert_input"]
};
	/** aggregate max on columns */
["voyce_transaction_logs_max_fields"]: AliasType<{
	amount?:true,
	coins_type?:true,
	created_at?:true,
	id?:true,
	item_id?:true,
	product_id?:true,
	receipt_id?:true,
	receiver_id?:true,
	sender_id?:true,
	title?:true,
	type?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_transaction_logs_min_fields"]: AliasType<{
	amount?:true,
	coins_type?:true,
	created_at?:true,
	id?:true,
	item_id?:true,
	product_id?:true,
	receipt_id?:true,
	receiver_id?:true,
	sender_id?:true,
	title?:true,
	type?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.transaction_logs" */
["voyce_transaction_logs_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_transaction_logs"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.transaction_logs" */
["voyce_transaction_logs_on_conflict"]: {
	constraint:ValueTypes["voyce_transaction_logs_constraint"],
	update_columns:ValueTypes["voyce_transaction_logs_update_column"][],
	where?:ValueTypes["voyce_transaction_logs_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.transaction_logs". */
["voyce_transaction_logs_order_by"]: {
	amount?:ValueTypes["order_by"],
	coins_type?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	item_id?:ValueTypes["order_by"],
	product_id?:ValueTypes["order_by"],
	receipt_id?:ValueTypes["order_by"],
	receiver_id?:ValueTypes["order_by"],
	sender_id?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	userByReceiverId?:ValueTypes["voyce_users_order_by"],
	userBySenderId?:ValueTypes["voyce_users_order_by"]
};
	/** primary key columns input for table: voyce.transaction_logs */
["voyce_transaction_logs_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.transaction_logs" */
["voyce_transaction_logs_select_column"]:voyce_transaction_logs_select_column;
	/** input type for updating data in table "voyce.transaction_logs" */
["voyce_transaction_logs_set_input"]: {
	amount?:number,
	coins_type?:string,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	item_id?:number,
	product_id?:number,
	receipt_id?:number,
	receiver_id?:number,
	sender_id?:number,
	title?:string,
	type?:string
};
	/** aggregate stddev on columns */
["voyce_transaction_logs_stddev_fields"]: AliasType<{
	amount?:true,
	id?:true,
	item_id?:true,
	product_id?:true,
	receipt_id?:true,
	receiver_id?:true,
	sender_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_transaction_logs_stddev_pop_fields"]: AliasType<{
	amount?:true,
	id?:true,
	item_id?:true,
	product_id?:true,
	receipt_id?:true,
	receiver_id?:true,
	sender_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_transaction_logs_stddev_samp_fields"]: AliasType<{
	amount?:true,
	id?:true,
	item_id?:true,
	product_id?:true,
	receipt_id?:true,
	receiver_id?:true,
	sender_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_transaction_logs" */
["voyce_transaction_logs_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_transaction_logs_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_transaction_logs_stream_cursor_value_input"]: {
	amount?:number,
	coins_type?:string,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	item_id?:number,
	product_id?:number,
	receipt_id?:number,
	receiver_id?:number,
	sender_id?:number,
	title?:string,
	type?:string
};
	/** aggregate sum on columns */
["voyce_transaction_logs_sum_fields"]: AliasType<{
	amount?:true,
	id?:true,
	item_id?:true,
	product_id?:true,
	receipt_id?:true,
	receiver_id?:true,
	sender_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.transaction_logs" */
["voyce_transaction_logs_update_column"]:voyce_transaction_logs_update_column;
	["voyce_transaction_logs_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_transaction_logs_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_transaction_logs_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_transaction_logs_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_transaction_logs_var_pop_fields"]: AliasType<{
	amount?:true,
	id?:true,
	item_id?:true,
	product_id?:true,
	receipt_id?:true,
	receiver_id?:true,
	sender_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_transaction_logs_var_samp_fields"]: AliasType<{
	amount?:true,
	id?:true,
	item_id?:true,
	product_id?:true,
	receipt_id?:true,
	receiver_id?:true,
	sender_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_transaction_logs_variance_fields"]: AliasType<{
	amount?:true,
	id?:true,
	item_id?:true,
	product_id?:true,
	receipt_id?:true,
	receiver_id?:true,
	sender_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.url_redirects" */
["voyce_url_redirects"]: AliasType<{
	id?:true,
	path?:true,
	redirect_url?:true,
	views?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.url_redirects" */
["voyce_url_redirects_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_url_redirects_aggregate_fields"],
	nodes?:ValueTypes["voyce_url_redirects"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.url_redirects" */
["voyce_url_redirects_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_url_redirects_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_url_redirects_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_url_redirects_max_fields"],
	min?:ValueTypes["voyce_url_redirects_min_fields"],
	stddev?:ValueTypes["voyce_url_redirects_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_url_redirects_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_url_redirects_stddev_samp_fields"],
	sum?:ValueTypes["voyce_url_redirects_sum_fields"],
	var_pop?:ValueTypes["voyce_url_redirects_var_pop_fields"],
	var_samp?:ValueTypes["voyce_url_redirects_var_samp_fields"],
	variance?:ValueTypes["voyce_url_redirects_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_url_redirects_avg_fields"]: AliasType<{
	id?:true,
	views?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.url_redirects". All fields are combined with a logical 'AND'. */
["voyce_url_redirects_bool_exp"]: {
	_and?:ValueTypes["voyce_url_redirects_bool_exp"][],
	_not?:ValueTypes["voyce_url_redirects_bool_exp"],
	_or?:ValueTypes["voyce_url_redirects_bool_exp"][],
	id?:ValueTypes["Int_comparison_exp"],
	path?:ValueTypes["String_comparison_exp"],
	redirect_url?:ValueTypes["String_comparison_exp"],
	views?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.url_redirects" */
["voyce_url_redirects_constraint"]:voyce_url_redirects_constraint;
	/** input type for incrementing numeric columns in table "voyce.url_redirects" */
["voyce_url_redirects_inc_input"]: {
	id?:number,
	views?:number
};
	/** input type for inserting data into table "voyce.url_redirects" */
["voyce_url_redirects_insert_input"]: {
	id?:number,
	path?:string,
	redirect_url?:string,
	views?:number
};
	/** aggregate max on columns */
["voyce_url_redirects_max_fields"]: AliasType<{
	id?:true,
	path?:true,
	redirect_url?:true,
	views?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_url_redirects_min_fields"]: AliasType<{
	id?:true,
	path?:true,
	redirect_url?:true,
	views?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.url_redirects" */
["voyce_url_redirects_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_url_redirects"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.url_redirects" */
["voyce_url_redirects_on_conflict"]: {
	constraint:ValueTypes["voyce_url_redirects_constraint"],
	update_columns:ValueTypes["voyce_url_redirects_update_column"][],
	where?:ValueTypes["voyce_url_redirects_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.url_redirects". */
["voyce_url_redirects_order_by"]: {
	id?:ValueTypes["order_by"],
	path?:ValueTypes["order_by"],
	redirect_url?:ValueTypes["order_by"],
	views?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.url_redirects */
["voyce_url_redirects_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.url_redirects" */
["voyce_url_redirects_select_column"]:voyce_url_redirects_select_column;
	/** input type for updating data in table "voyce.url_redirects" */
["voyce_url_redirects_set_input"]: {
	id?:number,
	path?:string,
	redirect_url?:string,
	views?:number
};
	/** aggregate stddev on columns */
["voyce_url_redirects_stddev_fields"]: AliasType<{
	id?:true,
	views?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_url_redirects_stddev_pop_fields"]: AliasType<{
	id?:true,
	views?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_url_redirects_stddev_samp_fields"]: AliasType<{
	id?:true,
	views?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_url_redirects" */
["voyce_url_redirects_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_url_redirects_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_url_redirects_stream_cursor_value_input"]: {
	id?:number,
	path?:string,
	redirect_url?:string,
	views?:number
};
	/** aggregate sum on columns */
["voyce_url_redirects_sum_fields"]: AliasType<{
	id?:true,
	views?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.url_redirects" */
["voyce_url_redirects_update_column"]:voyce_url_redirects_update_column;
	["voyce_url_redirects_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_url_redirects_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_url_redirects_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_url_redirects_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_url_redirects_var_pop_fields"]: AliasType<{
	id?:true,
	views?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_url_redirects_var_samp_fields"]: AliasType<{
	id?:true,
	views?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_url_redirects_variance_fields"]: AliasType<{
	id?:true,
	views?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_addresses" */
["voyce_user_addresses"]: AliasType<{
	address?:true,
	city_id?:true,
	country_id?:true,
	date_create?:true,
	id?:true,
	is_billing_address?:true,
	is_shipping_address?:true,
	state_id?:true,
	user_id?:true,
	zip_code?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_addresses" */
["voyce_user_addresses_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_addresses_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_addresses"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_addresses" */
["voyce_user_addresses_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_addresses_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_addresses_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_addresses_max_fields"],
	min?:ValueTypes["voyce_user_addresses_min_fields"],
	stddev?:ValueTypes["voyce_user_addresses_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_addresses_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_addresses_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_addresses_sum_fields"],
	var_pop?:ValueTypes["voyce_user_addresses_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_addresses_var_samp_fields"],
	variance?:ValueTypes["voyce_user_addresses_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_addresses_avg_fields"]: AliasType<{
	city_id?:true,
	country_id?:true,
	id?:true,
	is_billing_address?:true,
	is_shipping_address?:true,
	state_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_addresses". All fields are combined with a logical 'AND'. */
["voyce_user_addresses_bool_exp"]: {
	_and?:ValueTypes["voyce_user_addresses_bool_exp"][],
	_not?:ValueTypes["voyce_user_addresses_bool_exp"],
	_or?:ValueTypes["voyce_user_addresses_bool_exp"][],
	address?:ValueTypes["String_comparison_exp"],
	city_id?:ValueTypes["Int_comparison_exp"],
	country_id?:ValueTypes["Int_comparison_exp"],
	date_create?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_billing_address?:ValueTypes["smallint_comparison_exp"],
	is_shipping_address?:ValueTypes["smallint_comparison_exp"],
	state_id?:ValueTypes["Int_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	zip_code?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_addresses" */
["voyce_user_addresses_constraint"]:voyce_user_addresses_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_addresses" */
["voyce_user_addresses_inc_input"]: {
	city_id?:number,
	country_id?:number,
	id?:number,
	is_billing_address?:ValueTypes["smallint"],
	is_shipping_address?:ValueTypes["smallint"],
	state_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_addresses" */
["voyce_user_addresses_insert_input"]: {
	address?:string,
	city_id?:number,
	country_id?:number,
	date_create?:ValueTypes["timestamp"],
	id?:number,
	is_billing_address?:ValueTypes["smallint"],
	is_shipping_address?:ValueTypes["smallint"],
	state_id?:number,
	user_id?:number,
	zip_code?:string
};
	/** aggregate max on columns */
["voyce_user_addresses_max_fields"]: AliasType<{
	address?:true,
	city_id?:true,
	country_id?:true,
	date_create?:true,
	id?:true,
	is_billing_address?:true,
	is_shipping_address?:true,
	state_id?:true,
	user_id?:true,
	zip_code?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_addresses_min_fields"]: AliasType<{
	address?:true,
	city_id?:true,
	country_id?:true,
	date_create?:true,
	id?:true,
	is_billing_address?:true,
	is_shipping_address?:true,
	state_id?:true,
	user_id?:true,
	zip_code?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_addresses" */
["voyce_user_addresses_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_addresses"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_addresses" */
["voyce_user_addresses_on_conflict"]: {
	constraint:ValueTypes["voyce_user_addresses_constraint"],
	update_columns:ValueTypes["voyce_user_addresses_update_column"][],
	where?:ValueTypes["voyce_user_addresses_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_addresses". */
["voyce_user_addresses_order_by"]: {
	address?:ValueTypes["order_by"],
	city_id?:ValueTypes["order_by"],
	country_id?:ValueTypes["order_by"],
	date_create?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_billing_address?:ValueTypes["order_by"],
	is_shipping_address?:ValueTypes["order_by"],
	state_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	zip_code?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_addresses */
["voyce_user_addresses_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_addresses" */
["voyce_user_addresses_select_column"]:voyce_user_addresses_select_column;
	/** input type for updating data in table "voyce.user_addresses" */
["voyce_user_addresses_set_input"]: {
	address?:string,
	city_id?:number,
	country_id?:number,
	date_create?:ValueTypes["timestamp"],
	id?:number,
	is_billing_address?:ValueTypes["smallint"],
	is_shipping_address?:ValueTypes["smallint"],
	state_id?:number,
	user_id?:number,
	zip_code?:string
};
	/** aggregate stddev on columns */
["voyce_user_addresses_stddev_fields"]: AliasType<{
	city_id?:true,
	country_id?:true,
	id?:true,
	is_billing_address?:true,
	is_shipping_address?:true,
	state_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_addresses_stddev_pop_fields"]: AliasType<{
	city_id?:true,
	country_id?:true,
	id?:true,
	is_billing_address?:true,
	is_shipping_address?:true,
	state_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_addresses_stddev_samp_fields"]: AliasType<{
	city_id?:true,
	country_id?:true,
	id?:true,
	is_billing_address?:true,
	is_shipping_address?:true,
	state_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_addresses" */
["voyce_user_addresses_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_addresses_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_addresses_stream_cursor_value_input"]: {
	address?:string,
	city_id?:number,
	country_id?:number,
	date_create?:ValueTypes["timestamp"],
	id?:number,
	is_billing_address?:ValueTypes["smallint"],
	is_shipping_address?:ValueTypes["smallint"],
	state_id?:number,
	user_id?:number,
	zip_code?:string
};
	/** aggregate sum on columns */
["voyce_user_addresses_sum_fields"]: AliasType<{
	city_id?:true,
	country_id?:true,
	id?:true,
	is_billing_address?:true,
	is_shipping_address?:true,
	state_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_addresses" */
["voyce_user_addresses_update_column"]:voyce_user_addresses_update_column;
	["voyce_user_addresses_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_addresses_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_addresses_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_addresses_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_addresses_var_pop_fields"]: AliasType<{
	city_id?:true,
	country_id?:true,
	id?:true,
	is_billing_address?:true,
	is_shipping_address?:true,
	state_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_addresses_var_samp_fields"]: AliasType<{
	city_id?:true,
	country_id?:true,
	id?:true,
	is_billing_address?:true,
	is_shipping_address?:true,
	state_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_addresses_variance_fields"]: AliasType<{
	city_id?:true,
	country_id?:true,
	id?:true,
	is_billing_address?:true,
	is_shipping_address?:true,
	state_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_blocks" */
["voyce_user_blocks"]: AliasType<{
	created_at?:true,
	extras?:true,
	id?:true,
	linked_id?:true,
	reason?:true,
	type?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_blocks" */
["voyce_user_blocks_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_blocks_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_blocks"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_blocks" */
["voyce_user_blocks_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_blocks_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_blocks_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_blocks_max_fields"],
	min?:ValueTypes["voyce_user_blocks_min_fields"],
	stddev?:ValueTypes["voyce_user_blocks_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_blocks_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_blocks_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_blocks_sum_fields"],
	var_pop?:ValueTypes["voyce_user_blocks_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_blocks_var_samp_fields"],
	variance?:ValueTypes["voyce_user_blocks_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_blocks_avg_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_blocks". All fields are combined with a logical 'AND'. */
["voyce_user_blocks_bool_exp"]: {
	_and?:ValueTypes["voyce_user_blocks_bool_exp"][],
	_not?:ValueTypes["voyce_user_blocks_bool_exp"],
	_or?:ValueTypes["voyce_user_blocks_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	extras?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	linked_id?:ValueTypes["Int_comparison_exp"],
	reason?:ValueTypes["String_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_blocks" */
["voyce_user_blocks_constraint"]:voyce_user_blocks_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_blocks" */
["voyce_user_blocks_inc_input"]: {
	id?:number,
	linked_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_blocks" */
["voyce_user_blocks_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	extras?:string,
	id?:number,
	linked_id?:number,
	reason?:string,
	type?:string,
	user_id?:number
};
	/** aggregate max on columns */
["voyce_user_blocks_max_fields"]: AliasType<{
	created_at?:true,
	extras?:true,
	id?:true,
	linked_id?:true,
	reason?:true,
	type?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_blocks_min_fields"]: AliasType<{
	created_at?:true,
	extras?:true,
	id?:true,
	linked_id?:true,
	reason?:true,
	type?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_blocks" */
["voyce_user_blocks_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_blocks"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_blocks" */
["voyce_user_blocks_on_conflict"]: {
	constraint:ValueTypes["voyce_user_blocks_constraint"],
	update_columns:ValueTypes["voyce_user_blocks_update_column"][],
	where?:ValueTypes["voyce_user_blocks_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_blocks". */
["voyce_user_blocks_order_by"]: {
	created_at?:ValueTypes["order_by"],
	extras?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	reason?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_blocks */
["voyce_user_blocks_pk_columns_input"]: {
	linked_id:number,
	user_id:number
};
	/** select columns of table "voyce.user_blocks" */
["voyce_user_blocks_select_column"]:voyce_user_blocks_select_column;
	/** input type for updating data in table "voyce.user_blocks" */
["voyce_user_blocks_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	extras?:string,
	id?:number,
	linked_id?:number,
	reason?:string,
	type?:string,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_blocks_stddev_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_blocks_stddev_pop_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_blocks_stddev_samp_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_blocks" */
["voyce_user_blocks_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_blocks_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_blocks_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	extras?:string,
	id?:number,
	linked_id?:number,
	reason?:string,
	type?:string,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_blocks_sum_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_blocks" */
["voyce_user_blocks_update_column"]:voyce_user_blocks_update_column;
	["voyce_user_blocks_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_blocks_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_blocks_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_blocks_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_blocks_var_pop_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_blocks_var_samp_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_blocks_variance_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	end_date?:true,
	id?:true,
	is_expired?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	start_date?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_chapters_unlocked_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_chapters_unlocked"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_chapters_unlocked_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_chapters_unlocked_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_chapters_unlocked_max_fields"],
	min?:ValueTypes["voyce_user_chapters_unlocked_min_fields"],
	stddev?:ValueTypes["voyce_user_chapters_unlocked_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_chapters_unlocked_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_chapters_unlocked_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_chapters_unlocked_sum_fields"],
	var_pop?:ValueTypes["voyce_user_chapters_unlocked_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_chapters_unlocked_var_samp_fields"],
	variance?:ValueTypes["voyce_user_chapters_unlocked_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_chapters_unlocked_avg_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_chapters_unlocked". All fields are combined with a logical 'AND'. */
["voyce_user_chapters_unlocked_bool_exp"]: {
	_and?:ValueTypes["voyce_user_chapters_unlocked_bool_exp"][],
	_not?:ValueTypes["voyce_user_chapters_unlocked_bool_exp"],
	_or?:ValueTypes["voyce_user_chapters_unlocked_bool_exp"][],
	chapter_id?:ValueTypes["Int_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	end_date?:ValueTypes["date_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_expired?:ValueTypes["Boolean_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	start_date?:ValueTypes["date_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_constraint"]:voyce_user_chapters_unlocked_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_inc_input"]: {
	chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_insert_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	end_date?:ValueTypes["date"],
	id?:number,
	is_expired?:boolean,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	start_date?:ValueTypes["date"],
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_user_chapters_unlocked_max_fields"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	end_date?:true,
	id?:true,
	series_id?:true,
	start_date?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_chapters_unlocked_min_fields"]: AliasType<{
	chapter_id?:true,
	created_at?:true,
	end_date?:true,
	id?:true,
	series_id?:true,
	start_date?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_chapters_unlocked"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_on_conflict"]: {
	constraint:ValueTypes["voyce_user_chapters_unlocked_constraint"],
	update_columns:ValueTypes["voyce_user_chapters_unlocked_update_column"][],
	where?:ValueTypes["voyce_user_chapters_unlocked_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_chapters_unlocked". */
["voyce_user_chapters_unlocked_order_by"]: {
	chapter_id?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	end_date?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_expired?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	start_date?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_chapters_unlocked */
["voyce_user_chapters_unlocked_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_select_column"]:voyce_user_chapters_unlocked_select_column;
	/** input type for updating data in table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_set_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	end_date?:ValueTypes["date"],
	id?:number,
	is_expired?:boolean,
	series_id?:number,
	start_date?:ValueTypes["date"],
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_chapters_unlocked_stddev_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_chapters_unlocked_stddev_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_chapters_unlocked_stddev_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_chapters_unlocked" */
["voyce_user_chapters_unlocked_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_chapters_unlocked_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_chapters_unlocked_stream_cursor_value_input"]: {
	chapter_id?:number,
	created_at?:ValueTypes["timestamptz"],
	end_date?:ValueTypes["date"],
	id?:number,
	is_expired?:boolean,
	series_id?:number,
	start_date?:ValueTypes["date"],
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_chapters_unlocked_sum_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_update_column"]:voyce_user_chapters_unlocked_update_column;
	["voyce_user_chapters_unlocked_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_chapters_unlocked_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_chapters_unlocked_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_chapters_unlocked_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_chapters_unlocked_var_pop_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_chapters_unlocked_var_samp_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_chapters_unlocked_variance_fields"]: AliasType<{
	chapter_id?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_contacts" */
["voyce_user_contacts"]: AliasType<{
	contact?:true,
	country_code?:true,
	date_create?:true,
	id?:true,
	is_primary?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_contacts" */
["voyce_user_contacts_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_contacts_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_contacts"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_contacts" */
["voyce_user_contacts_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_contacts_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_contacts_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_contacts_max_fields"],
	min?:ValueTypes["voyce_user_contacts_min_fields"],
	stddev?:ValueTypes["voyce_user_contacts_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_contacts_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_contacts_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_contacts_sum_fields"],
	var_pop?:ValueTypes["voyce_user_contacts_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_contacts_var_samp_fields"],
	variance?:ValueTypes["voyce_user_contacts_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_contacts_avg_fields"]: AliasType<{
	id?:true,
	is_primary?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_contacts". All fields are combined with a logical 'AND'. */
["voyce_user_contacts_bool_exp"]: {
	_and?:ValueTypes["voyce_user_contacts_bool_exp"][],
	_not?:ValueTypes["voyce_user_contacts_bool_exp"],
	_or?:ValueTypes["voyce_user_contacts_bool_exp"][],
	contact?:ValueTypes["String_comparison_exp"],
	country_code?:ValueTypes["String_comparison_exp"],
	date_create?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_primary?:ValueTypes["smallint_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_contacts" */
["voyce_user_contacts_constraint"]:voyce_user_contacts_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_contacts" */
["voyce_user_contacts_inc_input"]: {
	id?:number,
	is_primary?:ValueTypes["smallint"],
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_contacts" */
["voyce_user_contacts_insert_input"]: {
	contact?:string,
	country_code?:string,
	date_create?:ValueTypes["timestamp"],
	id?:number,
	is_primary?:ValueTypes["smallint"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_user_contacts_max_fields"]: AliasType<{
	contact?:true,
	country_code?:true,
	date_create?:true,
	id?:true,
	is_primary?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_contacts_min_fields"]: AliasType<{
	contact?:true,
	country_code?:true,
	date_create?:true,
	id?:true,
	is_primary?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_contacts" */
["voyce_user_contacts_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_contacts"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_contacts" */
["voyce_user_contacts_on_conflict"]: {
	constraint:ValueTypes["voyce_user_contacts_constraint"],
	update_columns:ValueTypes["voyce_user_contacts_update_column"][],
	where?:ValueTypes["voyce_user_contacts_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_contacts". */
["voyce_user_contacts_order_by"]: {
	contact?:ValueTypes["order_by"],
	country_code?:ValueTypes["order_by"],
	date_create?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_primary?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_contacts */
["voyce_user_contacts_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_contacts" */
["voyce_user_contacts_select_column"]:voyce_user_contacts_select_column;
	/** input type for updating data in table "voyce.user_contacts" */
["voyce_user_contacts_set_input"]: {
	contact?:string,
	country_code?:string,
	date_create?:ValueTypes["timestamp"],
	id?:number,
	is_primary?:ValueTypes["smallint"],
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_contacts_stddev_fields"]: AliasType<{
	id?:true,
	is_primary?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_contacts_stddev_pop_fields"]: AliasType<{
	id?:true,
	is_primary?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_contacts_stddev_samp_fields"]: AliasType<{
	id?:true,
	is_primary?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_contacts" */
["voyce_user_contacts_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_contacts_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_contacts_stream_cursor_value_input"]: {
	contact?:string,
	country_code?:string,
	date_create?:ValueTypes["timestamp"],
	id?:number,
	is_primary?:ValueTypes["smallint"],
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_contacts_sum_fields"]: AliasType<{
	id?:true,
	is_primary?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_contacts" */
["voyce_user_contacts_update_column"]:voyce_user_contacts_update_column;
	["voyce_user_contacts_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_contacts_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_contacts_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_contacts_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_contacts_var_pop_fields"]: AliasType<{
	id?:true,
	is_primary?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_contacts_var_samp_fields"]: AliasType<{
	id?:true,
	is_primary?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_contacts_variance_fields"]: AliasType<{
	id?:true,
	is_primary?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_csat_ratings" */
["voyce_user_csat_ratings"]: AliasType<{
	comment?:true,
	created_at?:true,
	gtag_id?:true,
	id?:true,
	is_answered?:true,
	more_info?:true,
	page_uri?:true,
	rating?:true,
	tag?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_csat_ratings_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_csat_ratings"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_csat_ratings_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_csat_ratings_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_csat_ratings_max_fields"],
	min?:ValueTypes["voyce_user_csat_ratings_min_fields"],
	stddev?:ValueTypes["voyce_user_csat_ratings_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_csat_ratings_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_csat_ratings_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_csat_ratings_sum_fields"],
	var_pop?:ValueTypes["voyce_user_csat_ratings_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_csat_ratings_var_samp_fields"],
	variance?:ValueTypes["voyce_user_csat_ratings_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_csat_ratings_avg_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_csat_ratings". All fields are combined with a logical 'AND'. */
["voyce_user_csat_ratings_bool_exp"]: {
	_and?:ValueTypes["voyce_user_csat_ratings_bool_exp"][],
	_not?:ValueTypes["voyce_user_csat_ratings_bool_exp"],
	_or?:ValueTypes["voyce_user_csat_ratings_bool_exp"][],
	comment?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	gtag_id?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_answered?:ValueTypes["Boolean_comparison_exp"],
	more_info?:ValueTypes["String_comparison_exp"],
	page_uri?:ValueTypes["String_comparison_exp"],
	rating?:ValueTypes["String_comparison_exp"],
	tag?:ValueTypes["String_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_constraint"]:voyce_user_csat_ratings_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_inc_input"]: {
	id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_insert_input"]: {
	comment?:string,
	created_at?:ValueTypes["timestamptz"],
	gtag_id?:string,
	id?:number,
	is_answered?:boolean,
	more_info?:string,
	page_uri?:string,
	rating?:string,
	tag?:string,
	user_id?:number
};
	/** aggregate max on columns */
["voyce_user_csat_ratings_max_fields"]: AliasType<{
	comment?:true,
	created_at?:true,
	gtag_id?:true,
	id?:true,
	more_info?:true,
	page_uri?:true,
	rating?:true,
	tag?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_csat_ratings_min_fields"]: AliasType<{
	comment?:true,
	created_at?:true,
	gtag_id?:true,
	id?:true,
	more_info?:true,
	page_uri?:true,
	rating?:true,
	tag?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_csat_ratings"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_on_conflict"]: {
	constraint:ValueTypes["voyce_user_csat_ratings_constraint"],
	update_columns:ValueTypes["voyce_user_csat_ratings_update_column"][],
	where?:ValueTypes["voyce_user_csat_ratings_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_csat_ratings". */
["voyce_user_csat_ratings_order_by"]: {
	comment?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	gtag_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_answered?:ValueTypes["order_by"],
	more_info?:ValueTypes["order_by"],
	page_uri?:ValueTypes["order_by"],
	rating?:ValueTypes["order_by"],
	tag?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_csat_ratings */
["voyce_user_csat_ratings_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_select_column"]:voyce_user_csat_ratings_select_column;
	/** input type for updating data in table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_set_input"]: {
	comment?:string,
	created_at?:ValueTypes["timestamptz"],
	gtag_id?:string,
	id?:number,
	is_answered?:boolean,
	more_info?:string,
	page_uri?:string,
	rating?:string,
	tag?:string,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_csat_ratings_stddev_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_csat_ratings_stddev_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_csat_ratings_stddev_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_csat_ratings" */
["voyce_user_csat_ratings_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_csat_ratings_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_csat_ratings_stream_cursor_value_input"]: {
	comment?:string,
	created_at?:ValueTypes["timestamptz"],
	gtag_id?:string,
	id?:number,
	is_answered?:boolean,
	more_info?:string,
	page_uri?:string,
	rating?:string,
	tag?:string,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_csat_ratings_sum_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_update_column"]:voyce_user_csat_ratings_update_column;
	["voyce_user_csat_ratings_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_csat_ratings_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_csat_ratings_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_csat_ratings_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_csat_ratings_var_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_csat_ratings_var_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_csat_ratings_variance_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_education" */
["voyce_user_education"]: AliasType<{
	date_create?:true,
	education_type_id?:true,
	id?:true,
	in_progress?:true,
	institute?:true,
	passing_date?:true,
	title?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_education" */
["voyce_user_education_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_education_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_education"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_education" */
["voyce_user_education_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_education_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_education_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_education_max_fields"],
	min?:ValueTypes["voyce_user_education_min_fields"],
	stddev?:ValueTypes["voyce_user_education_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_education_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_education_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_education_sum_fields"],
	var_pop?:ValueTypes["voyce_user_education_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_education_var_samp_fields"],
	variance?:ValueTypes["voyce_user_education_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_education_avg_fields"]: AliasType<{
	education_type_id?:true,
	id?:true,
	in_progress?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_education". All fields are combined with a logical 'AND'. */
["voyce_user_education_bool_exp"]: {
	_and?:ValueTypes["voyce_user_education_bool_exp"][],
	_not?:ValueTypes["voyce_user_education_bool_exp"],
	_or?:ValueTypes["voyce_user_education_bool_exp"][],
	date_create?:ValueTypes["timestamp_comparison_exp"],
	education_type_id?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	in_progress?:ValueTypes["smallint_comparison_exp"],
	institute?:ValueTypes["String_comparison_exp"],
	passing_date?:ValueTypes["date_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_education" */
["voyce_user_education_constraint"]:voyce_user_education_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_education" */
["voyce_user_education_inc_input"]: {
	education_type_id?:number,
	id?:number,
	in_progress?:ValueTypes["smallint"],
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_education" */
["voyce_user_education_insert_input"]: {
	date_create?:ValueTypes["timestamp"],
	education_type_id?:number,
	id?:number,
	in_progress?:ValueTypes["smallint"],
	institute?:string,
	passing_date?:ValueTypes["date"],
	title?:string,
	user_id?:number
};
	/** aggregate max on columns */
["voyce_user_education_max_fields"]: AliasType<{
	date_create?:true,
	education_type_id?:true,
	id?:true,
	in_progress?:true,
	institute?:true,
	passing_date?:true,
	title?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_education_min_fields"]: AliasType<{
	date_create?:true,
	education_type_id?:true,
	id?:true,
	in_progress?:true,
	institute?:true,
	passing_date?:true,
	title?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_education" */
["voyce_user_education_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_education"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_education" */
["voyce_user_education_on_conflict"]: {
	constraint:ValueTypes["voyce_user_education_constraint"],
	update_columns:ValueTypes["voyce_user_education_update_column"][],
	where?:ValueTypes["voyce_user_education_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_education". */
["voyce_user_education_order_by"]: {
	date_create?:ValueTypes["order_by"],
	education_type_id?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	in_progress?:ValueTypes["order_by"],
	institute?:ValueTypes["order_by"],
	passing_date?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_education */
["voyce_user_education_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_education" */
["voyce_user_education_select_column"]:voyce_user_education_select_column;
	/** input type for updating data in table "voyce.user_education" */
["voyce_user_education_set_input"]: {
	date_create?:ValueTypes["timestamp"],
	education_type_id?:number,
	id?:number,
	in_progress?:ValueTypes["smallint"],
	institute?:string,
	passing_date?:ValueTypes["date"],
	title?:string,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_education_stddev_fields"]: AliasType<{
	education_type_id?:true,
	id?:true,
	in_progress?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_education_stddev_pop_fields"]: AliasType<{
	education_type_id?:true,
	id?:true,
	in_progress?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_education_stddev_samp_fields"]: AliasType<{
	education_type_id?:true,
	id?:true,
	in_progress?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_education" */
["voyce_user_education_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_education_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_education_stream_cursor_value_input"]: {
	date_create?:ValueTypes["timestamp"],
	education_type_id?:number,
	id?:number,
	in_progress?:ValueTypes["smallint"],
	institute?:string,
	passing_date?:ValueTypes["date"],
	title?:string,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_education_sum_fields"]: AliasType<{
	education_type_id?:true,
	id?:true,
	in_progress?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_education" */
["voyce_user_education_update_column"]:voyce_user_education_update_column;
	["voyce_user_education_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_education_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_education_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_education_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_education_var_pop_fields"]: AliasType<{
	education_type_id?:true,
	id?:true,
	in_progress?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_education_var_samp_fields"]: AliasType<{
	education_type_id?:true,
	id?:true,
	in_progress?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_education_variance_fields"]: AliasType<{
	education_type_id?:true,
	id?:true,
	in_progress?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_level" */
["voyce_user_level"]: AliasType<{
	created_at?:true,
	experience?:true,
	id?:true,
	level?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_level" */
["voyce_user_level_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_level_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_level"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_level" */
["voyce_user_level_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_level_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_level_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_level_max_fields"],
	min?:ValueTypes["voyce_user_level_min_fields"],
	stddev?:ValueTypes["voyce_user_level_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_level_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_level_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_level_sum_fields"],
	var_pop?:ValueTypes["voyce_user_level_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_level_var_samp_fields"],
	variance?:ValueTypes["voyce_user_level_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_level_avg_fields"]: AliasType<{
	experience?:true,
	id?:true,
	level?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_level". All fields are combined with a logical 'AND'. */
["voyce_user_level_bool_exp"]: {
	_and?:ValueTypes["voyce_user_level_bool_exp"][],
	_not?:ValueTypes["voyce_user_level_bool_exp"],
	_or?:ValueTypes["voyce_user_level_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	experience?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	level?:ValueTypes["Int_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_level" */
["voyce_user_level_constraint"]:voyce_user_level_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_level" */
["voyce_user_level_inc_input"]: {
	experience?:number,
	id?:number,
	level?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_level" */
["voyce_user_level_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	experience?:number,
	id?:number,
	level?:number,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_user_level_max_fields"]: AliasType<{
	created_at?:true,
	experience?:true,
	id?:true,
	level?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_level_min_fields"]: AliasType<{
	created_at?:true,
	experience?:true,
	id?:true,
	level?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_level" */
["voyce_user_level_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_level"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_level" */
["voyce_user_level_on_conflict"]: {
	constraint:ValueTypes["voyce_user_level_constraint"],
	update_columns:ValueTypes["voyce_user_level_update_column"][],
	where?:ValueTypes["voyce_user_level_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_level". */
["voyce_user_level_order_by"]: {
	created_at?:ValueTypes["order_by"],
	experience?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	level?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_level */
["voyce_user_level_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_level" */
["voyce_user_level_select_column"]:voyce_user_level_select_column;
	/** input type for updating data in table "voyce.user_level" */
["voyce_user_level_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	experience?:number,
	id?:number,
	level?:number,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_level_stddev_fields"]: AliasType<{
	experience?:true,
	id?:true,
	level?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_level_stddev_pop_fields"]: AliasType<{
	experience?:true,
	id?:true,
	level?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_level_stddev_samp_fields"]: AliasType<{
	experience?:true,
	id?:true,
	level?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_level" */
["voyce_user_level_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_level_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_level_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	experience?:number,
	id?:number,
	level?:number,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_level_sum_fields"]: AliasType<{
	experience?:true,
	id?:true,
	level?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_level" */
["voyce_user_level_update_column"]:voyce_user_level_update_column;
	["voyce_user_level_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_level_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_level_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_level_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_level_var_pop_fields"]: AliasType<{
	experience?:true,
	id?:true,
	level?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_level_var_samp_fields"]: AliasType<{
	experience?:true,
	id?:true,
	level?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_level_variance_fields"]: AliasType<{
	experience?:true,
	id?:true,
	level?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_linked_accounts" */
["voyce_user_linked_accounts"]: AliasType<{
	created_at?:true,
	hash?:true,
	id?:true,
	identity?:true,
	main_account?:true,
	provider?:true,
	sub?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_linked_accounts_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_linked_accounts"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_linked_accounts_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_linked_accounts_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_linked_accounts_max_fields"],
	min?:ValueTypes["voyce_user_linked_accounts_min_fields"],
	stddev?:ValueTypes["voyce_user_linked_accounts_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_linked_accounts_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_linked_accounts_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_linked_accounts_sum_fields"],
	var_pop?:ValueTypes["voyce_user_linked_accounts_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_linked_accounts_var_samp_fields"],
	variance?:ValueTypes["voyce_user_linked_accounts_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_linked_accounts_avg_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_linked_accounts". All fields are combined with a logical 'AND'. */
["voyce_user_linked_accounts_bool_exp"]: {
	_and?:ValueTypes["voyce_user_linked_accounts_bool_exp"][],
	_not?:ValueTypes["voyce_user_linked_accounts_bool_exp"],
	_or?:ValueTypes["voyce_user_linked_accounts_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	hash?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	identity?:ValueTypes["String_comparison_exp"],
	main_account?:ValueTypes["Boolean_comparison_exp"],
	provider?:ValueTypes["String_comparison_exp"],
	sub?:ValueTypes["String_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_constraint"]:voyce_user_linked_accounts_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_inc_input"]: {
	id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	hash?:string,
	id?:number,
	identity?:string,
	main_account?:boolean,
	provider?:string,
	sub?:string,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_user_linked_accounts_max_fields"]: AliasType<{
	created_at?:true,
	hash?:true,
	id?:true,
	identity?:true,
	provider?:true,
	sub?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_linked_accounts_min_fields"]: AliasType<{
	created_at?:true,
	hash?:true,
	id?:true,
	identity?:true,
	provider?:true,
	sub?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_linked_accounts"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_on_conflict"]: {
	constraint:ValueTypes["voyce_user_linked_accounts_constraint"],
	update_columns:ValueTypes["voyce_user_linked_accounts_update_column"][],
	where?:ValueTypes["voyce_user_linked_accounts_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_linked_accounts". */
["voyce_user_linked_accounts_order_by"]: {
	created_at?:ValueTypes["order_by"],
	hash?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	identity?:ValueTypes["order_by"],
	main_account?:ValueTypes["order_by"],
	provider?:ValueTypes["order_by"],
	sub?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_linked_accounts */
["voyce_user_linked_accounts_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_select_column"]:voyce_user_linked_accounts_select_column;
	/** input type for updating data in table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	hash?:string,
	id?:number,
	identity?:string,
	main_account?:boolean,
	provider?:string,
	sub?:string,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_linked_accounts_stddev_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_linked_accounts_stddev_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_linked_accounts_stddev_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_linked_accounts" */
["voyce_user_linked_accounts_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_linked_accounts_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_linked_accounts_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	hash?:string,
	id?:number,
	identity?:string,
	main_account?:boolean,
	provider?:string,
	sub?:string,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_linked_accounts_sum_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_update_column"]:voyce_user_linked_accounts_update_column;
	["voyce_user_linked_accounts_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_linked_accounts_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_linked_accounts_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_linked_accounts_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_linked_accounts_var_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_linked_accounts_var_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_linked_accounts_variance_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_login_history" */
["voyce_user_login_history"]: AliasType<{
	browser?:true,
	city?:true,
	country?:true,
	id?:true,
	ip?:true,
	is_login?:true,
	latitude?:true,
	login_time?:true,
	logout_time?:true,
	longitude?:true,
	os?:true,
	region?:true,
	token?:true,
	user_agent?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_login_history" */
["voyce_user_login_history_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_login_history_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_login_history"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_login_history" */
["voyce_user_login_history_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_login_history_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_login_history_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_login_history_max_fields"],
	min?:ValueTypes["voyce_user_login_history_min_fields"],
	stddev?:ValueTypes["voyce_user_login_history_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_login_history_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_login_history_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_login_history_sum_fields"],
	var_pop?:ValueTypes["voyce_user_login_history_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_login_history_var_samp_fields"],
	variance?:ValueTypes["voyce_user_login_history_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_login_history_avg_fields"]: AliasType<{
	id?:true,
	is_login?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_login_history". All fields are combined with a logical 'AND'. */
["voyce_user_login_history_bool_exp"]: {
	_and?:ValueTypes["voyce_user_login_history_bool_exp"][],
	_not?:ValueTypes["voyce_user_login_history_bool_exp"],
	_or?:ValueTypes["voyce_user_login_history_bool_exp"][],
	browser?:ValueTypes["String_comparison_exp"],
	city?:ValueTypes["String_comparison_exp"],
	country?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	ip?:ValueTypes["String_comparison_exp"],
	is_login?:ValueTypes["smallint_comparison_exp"],
	latitude?:ValueTypes["String_comparison_exp"],
	login_time?:ValueTypes["timestamp_comparison_exp"],
	logout_time?:ValueTypes["timestamp_comparison_exp"],
	longitude?:ValueTypes["String_comparison_exp"],
	os?:ValueTypes["String_comparison_exp"],
	region?:ValueTypes["String_comparison_exp"],
	token?:ValueTypes["String_comparison_exp"],
	user_agent?:ValueTypes["String_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_login_history" */
["voyce_user_login_history_constraint"]:voyce_user_login_history_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_login_history" */
["voyce_user_login_history_inc_input"]: {
	id?:number,
	is_login?:ValueTypes["smallint"],
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_login_history" */
["voyce_user_login_history_insert_input"]: {
	browser?:string,
	city?:string,
	country?:string,
	id?:number,
	ip?:string,
	is_login?:ValueTypes["smallint"],
	latitude?:string,
	login_time?:ValueTypes["timestamp"],
	logout_time?:ValueTypes["timestamp"],
	longitude?:string,
	os?:string,
	region?:string,
	token?:string,
	user_agent?:string,
	user_id?:number
};
	/** aggregate max on columns */
["voyce_user_login_history_max_fields"]: AliasType<{
	browser?:true,
	city?:true,
	country?:true,
	id?:true,
	ip?:true,
	is_login?:true,
	latitude?:true,
	login_time?:true,
	logout_time?:true,
	longitude?:true,
	os?:true,
	region?:true,
	token?:true,
	user_agent?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_login_history_min_fields"]: AliasType<{
	browser?:true,
	city?:true,
	country?:true,
	id?:true,
	ip?:true,
	is_login?:true,
	latitude?:true,
	login_time?:true,
	logout_time?:true,
	longitude?:true,
	os?:true,
	region?:true,
	token?:true,
	user_agent?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_login_history" */
["voyce_user_login_history_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_login_history"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_login_history" */
["voyce_user_login_history_on_conflict"]: {
	constraint:ValueTypes["voyce_user_login_history_constraint"],
	update_columns:ValueTypes["voyce_user_login_history_update_column"][],
	where?:ValueTypes["voyce_user_login_history_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_login_history". */
["voyce_user_login_history_order_by"]: {
	browser?:ValueTypes["order_by"],
	city?:ValueTypes["order_by"],
	country?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	ip?:ValueTypes["order_by"],
	is_login?:ValueTypes["order_by"],
	latitude?:ValueTypes["order_by"],
	login_time?:ValueTypes["order_by"],
	logout_time?:ValueTypes["order_by"],
	longitude?:ValueTypes["order_by"],
	os?:ValueTypes["order_by"],
	region?:ValueTypes["order_by"],
	token?:ValueTypes["order_by"],
	user_agent?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_login_history */
["voyce_user_login_history_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_login_history" */
["voyce_user_login_history_select_column"]:voyce_user_login_history_select_column;
	/** input type for updating data in table "voyce.user_login_history" */
["voyce_user_login_history_set_input"]: {
	browser?:string,
	city?:string,
	country?:string,
	id?:number,
	ip?:string,
	is_login?:ValueTypes["smallint"],
	latitude?:string,
	login_time?:ValueTypes["timestamp"],
	logout_time?:ValueTypes["timestamp"],
	longitude?:string,
	os?:string,
	region?:string,
	token?:string,
	user_agent?:string,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_login_history_stddev_fields"]: AliasType<{
	id?:true,
	is_login?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_login_history_stddev_pop_fields"]: AliasType<{
	id?:true,
	is_login?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_login_history_stddev_samp_fields"]: AliasType<{
	id?:true,
	is_login?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_login_history" */
["voyce_user_login_history_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_login_history_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_login_history_stream_cursor_value_input"]: {
	browser?:string,
	city?:string,
	country?:string,
	id?:number,
	ip?:string,
	is_login?:ValueTypes["smallint"],
	latitude?:string,
	login_time?:ValueTypes["timestamp"],
	logout_time?:ValueTypes["timestamp"],
	longitude?:string,
	os?:string,
	region?:string,
	token?:string,
	user_agent?:string,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_login_history_sum_fields"]: AliasType<{
	id?:true,
	is_login?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_login_history" */
["voyce_user_login_history_update_column"]:voyce_user_login_history_update_column;
	["voyce_user_login_history_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_login_history_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_login_history_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_login_history_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_login_history_var_pop_fields"]: AliasType<{
	id?:true,
	is_login?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_login_history_var_samp_fields"]: AliasType<{
	id?:true,
	is_login?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_login_history_variance_fields"]: AliasType<{
	id?:true,
	is_login?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_quest_ids" */
["voyce_user_quest_ids"]: AliasType<{
	created_at?:true,
	id?:true,
	/** An object relationship */
	quest?:ValueTypes["voyce_user_quests"],
	tag?:true,
	type?:true,
	type_id?:true,
	user_id?:true,
	user_quest_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_quest_ids" */
["voyce_user_quest_ids_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_quest_ids_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_quest_ids"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_quest_ids" */
["voyce_user_quest_ids_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_quest_ids_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_quest_ids_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_quest_ids_max_fields"],
	min?:ValueTypes["voyce_user_quest_ids_min_fields"],
	stddev?:ValueTypes["voyce_user_quest_ids_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_quest_ids_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_quest_ids_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_quest_ids_sum_fields"],
	var_pop?:ValueTypes["voyce_user_quest_ids_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_quest_ids_var_samp_fields"],
	variance?:ValueTypes["voyce_user_quest_ids_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_quest_ids_avg_fields"]: AliasType<{
	id?:true,
	type_id?:true,
	user_id?:true,
	user_quest_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_quest_ids". All fields are combined with a logical 'AND'. */
["voyce_user_quest_ids_bool_exp"]: {
	_and?:ValueTypes["voyce_user_quest_ids_bool_exp"][],
	_not?:ValueTypes["voyce_user_quest_ids_bool_exp"],
	_or?:ValueTypes["voyce_user_quest_ids_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	quest?:ValueTypes["voyce_user_quests_bool_exp"],
	tag?:ValueTypes["String_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"],
	type_id?:ValueTypes["Int_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	user_quest_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_quest_ids" */
["voyce_user_quest_ids_constraint"]:voyce_user_quest_ids_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_quest_ids" */
["voyce_user_quest_ids_inc_input"]: {
	id?:number,
	type_id?:number,
	user_id?:number,
	user_quest_id?:number
};
	/** input type for inserting data into table "voyce.user_quest_ids" */
["voyce_user_quest_ids_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	quest?:ValueTypes["voyce_user_quests_obj_rel_insert_input"],
	tag?:string,
	type?:string,
	type_id?:number,
	user_id?:number,
	user_quest_id?:number
};
	/** aggregate max on columns */
["voyce_user_quest_ids_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	tag?:true,
	type?:true,
	type_id?:true,
	user_id?:true,
	user_quest_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_quest_ids_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	tag?:true,
	type?:true,
	type_id?:true,
	user_id?:true,
	user_quest_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_quest_ids" */
["voyce_user_quest_ids_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_quest_ids"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_quest_ids" */
["voyce_user_quest_ids_on_conflict"]: {
	constraint:ValueTypes["voyce_user_quest_ids_constraint"],
	update_columns:ValueTypes["voyce_user_quest_ids_update_column"][],
	where?:ValueTypes["voyce_user_quest_ids_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_quest_ids". */
["voyce_user_quest_ids_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	quest?:ValueTypes["voyce_user_quests_order_by"],
	tag?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	type_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	user_quest_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_quest_ids */
["voyce_user_quest_ids_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_quest_ids" */
["voyce_user_quest_ids_select_column"]:voyce_user_quest_ids_select_column;
	/** input type for updating data in table "voyce.user_quest_ids" */
["voyce_user_quest_ids_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	tag?:string,
	type?:string,
	type_id?:number,
	user_id?:number,
	user_quest_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_quest_ids_stddev_fields"]: AliasType<{
	id?:true,
	type_id?:true,
	user_id?:true,
	user_quest_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_quest_ids_stddev_pop_fields"]: AliasType<{
	id?:true,
	type_id?:true,
	user_id?:true,
	user_quest_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_quest_ids_stddev_samp_fields"]: AliasType<{
	id?:true,
	type_id?:true,
	user_id?:true,
	user_quest_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_quest_ids" */
["voyce_user_quest_ids_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_quest_ids_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_quest_ids_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	tag?:string,
	type?:string,
	type_id?:number,
	user_id?:number,
	user_quest_id?:number
};
	/** aggregate sum on columns */
["voyce_user_quest_ids_sum_fields"]: AliasType<{
	id?:true,
	type_id?:true,
	user_id?:true,
	user_quest_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_quest_ids" */
["voyce_user_quest_ids_update_column"]:voyce_user_quest_ids_update_column;
	["voyce_user_quest_ids_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_quest_ids_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_quest_ids_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_quest_ids_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_quest_ids_var_pop_fields"]: AliasType<{
	id?:true,
	type_id?:true,
	user_id?:true,
	user_quest_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_quest_ids_var_samp_fields"]: AliasType<{
	id?:true,
	type_id?:true,
	user_id?:true,
	user_quest_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_quest_ids_variance_fields"]: AliasType<{
	id?:true,
	type_id?:true,
	user_id?:true,
	user_quest_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_quest_rewards" */
["voyce_user_quest_rewards"]: AliasType<{
	created_at?:true,
	description?:true,
	id?:true,
	metadata?:true,
	type?:true,
	user_id?:true,
	user_quest_id?:true,
	value?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_quest_rewards_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_quest_rewards"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_quest_rewards_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_quest_rewards_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_quest_rewards_max_fields"],
	min?:ValueTypes["voyce_user_quest_rewards_min_fields"],
	stddev?:ValueTypes["voyce_user_quest_rewards_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_quest_rewards_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_quest_rewards_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_quest_rewards_sum_fields"],
	var_pop?:ValueTypes["voyce_user_quest_rewards_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_quest_rewards_var_samp_fields"],
	variance?:ValueTypes["voyce_user_quest_rewards_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_quest_rewards_avg_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	user_quest_id?:true,
	value?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_quest_rewards". All fields are combined with a logical 'AND'. */
["voyce_user_quest_rewards_bool_exp"]: {
	_and?:ValueTypes["voyce_user_quest_rewards_bool_exp"][],
	_not?:ValueTypes["voyce_user_quest_rewards_bool_exp"],
	_or?:ValueTypes["voyce_user_quest_rewards_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	description?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	metadata?:ValueTypes["String_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	user_quest_id?:ValueTypes["Int_comparison_exp"],
	value?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_constraint"]:voyce_user_quest_rewards_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_inc_input"]: {
	id?:number,
	user_id?:number,
	user_quest_id?:number,
	value?:number
};
	/** input type for inserting data into table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	description?:string,
	id?:number,
	metadata?:string,
	type?:string,
	user_id?:number,
	user_quest_id?:number,
	value?:number
};
	/** aggregate max on columns */
["voyce_user_quest_rewards_max_fields"]: AliasType<{
	created_at?:true,
	description?:true,
	id?:true,
	metadata?:true,
	type?:true,
	user_id?:true,
	user_quest_id?:true,
	value?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_quest_rewards_min_fields"]: AliasType<{
	created_at?:true,
	description?:true,
	id?:true,
	metadata?:true,
	type?:true,
	user_id?:true,
	user_quest_id?:true,
	value?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_quest_rewards"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_on_conflict"]: {
	constraint:ValueTypes["voyce_user_quest_rewards_constraint"],
	update_columns:ValueTypes["voyce_user_quest_rewards_update_column"][],
	where?:ValueTypes["voyce_user_quest_rewards_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_quest_rewards". */
["voyce_user_quest_rewards_order_by"]: {
	created_at?:ValueTypes["order_by"],
	description?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	metadata?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	user_quest_id?:ValueTypes["order_by"],
	value?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_quest_rewards */
["voyce_user_quest_rewards_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_select_column"]:voyce_user_quest_rewards_select_column;
	/** input type for updating data in table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	description?:string,
	id?:number,
	metadata?:string,
	type?:string,
	user_id?:number,
	user_quest_id?:number,
	value?:number
};
	/** aggregate stddev on columns */
["voyce_user_quest_rewards_stddev_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	user_quest_id?:true,
	value?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_quest_rewards_stddev_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	user_quest_id?:true,
	value?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_quest_rewards_stddev_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	user_quest_id?:true,
	value?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_quest_rewards" */
["voyce_user_quest_rewards_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_quest_rewards_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_quest_rewards_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	description?:string,
	id?:number,
	metadata?:string,
	type?:string,
	user_id?:number,
	user_quest_id?:number,
	value?:number
};
	/** aggregate sum on columns */
["voyce_user_quest_rewards_sum_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	user_quest_id?:true,
	value?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_update_column"]:voyce_user_quest_rewards_update_column;
	["voyce_user_quest_rewards_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_quest_rewards_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_quest_rewards_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_quest_rewards_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_quest_rewards_var_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	user_quest_id?:true,
	value?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_quest_rewards_var_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	user_quest_id?:true,
	value?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_quest_rewards_variance_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	user_quest_id?:true,
	value?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_quests" */
["voyce_user_quests"]: AliasType<{
	completed_at?:true,
	created_at?:true,
data?: [{	/** JSON select path */
	path?:string},true],
	/** e.g. once = once, daily = date (2024-06-08), weekly = week # or week first day (2024-06-08) */
	frequency_tag?:true,
	id?:true,
	notified?:true,
	/** An object relationship */
	quest?:ValueTypes["voyce_quests"],
	quest_id?:true,
	rewarded_at?:true,
rewards_data?: [{	/** JSON select path */
	path?:string},true],
	status?:true,
	updated_at?:true,
	user_id?:true,
	/** An object relationship */
	vtag?:ValueTypes["voyce_user_vtags"],
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_quests" */
["voyce_user_quests_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_quests_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_quests"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_quests" */
["voyce_user_quests_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_quests_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_quests_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_quests_max_fields"],
	min?:ValueTypes["voyce_user_quests_min_fields"],
	stddev?:ValueTypes["voyce_user_quests_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_quests_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_quests_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_quests_sum_fields"],
	var_pop?:ValueTypes["voyce_user_quests_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_quests_var_samp_fields"],
	variance?:ValueTypes["voyce_user_quests_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_quests_avg_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_quests". All fields are combined with a logical 'AND'. */
["voyce_user_quests_bool_exp"]: {
	_and?:ValueTypes["voyce_user_quests_bool_exp"][],
	_not?:ValueTypes["voyce_user_quests_bool_exp"],
	_or?:ValueTypes["voyce_user_quests_bool_exp"][],
	completed_at?:ValueTypes["timestamptz_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	data?:ValueTypes["json_comparison_exp"],
	frequency_tag?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	notified?:ValueTypes["Boolean_comparison_exp"],
	quest?:ValueTypes["voyce_quests_bool_exp"],
	quest_id?:ValueTypes["Int_comparison_exp"],
	rewarded_at?:ValueTypes["timestamptz_comparison_exp"],
	rewards_data?:ValueTypes["json_comparison_exp"],
	status?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	vtag?:ValueTypes["voyce_user_vtags_bool_exp"]
};
	/** unique or primary key constraints on table "voyce.user_quests" */
["voyce_user_quests_constraint"]:voyce_user_quests_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_quests" */
["voyce_user_quests_inc_input"]: {
	id?:number,
	quest_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_quests" */
["voyce_user_quests_insert_input"]: {
	completed_at?:ValueTypes["timestamptz"],
	created_at?:ValueTypes["timestamptz"],
	data?:ValueTypes["json"],
	/** e.g. once = once, daily = date (2024-06-08), weekly = week # or week first day (2024-06-08) */
	frequency_tag?:string,
	id?:number,
	notified?:boolean,
	quest?:ValueTypes["voyce_quests_obj_rel_insert_input"],
	quest_id?:number,
	rewarded_at?:ValueTypes["timestamptz"],
	rewards_data?:ValueTypes["json"],
	status?:string,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number,
	vtag?:ValueTypes["voyce_user_vtags_obj_rel_insert_input"]
};
	/** aggregate max on columns */
["voyce_user_quests_max_fields"]: AliasType<{
	completed_at?:true,
	created_at?:true,
	/** e.g. once = once, daily = date (2024-06-08), weekly = week # or week first day (2024-06-08) */
	frequency_tag?:true,
	id?:true,
	quest_id?:true,
	rewarded_at?:true,
	status?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_quests_min_fields"]: AliasType<{
	completed_at?:true,
	created_at?:true,
	/** e.g. once = once, daily = date (2024-06-08), weekly = week # or week first day (2024-06-08) */
	frequency_tag?:true,
	id?:true,
	quest_id?:true,
	rewarded_at?:true,
	status?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_quests" */
["voyce_user_quests_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_quests"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.user_quests" */
["voyce_user_quests_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_user_quests_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_quests_on_conflict"]
};
	/** columns and relationships of "voyce.user_quests_old" */
["voyce_user_quests_old"]: AliasType<{
	created_at?:true,
	id?:true,
	progress?:true,
	/** An object relationship */
	quest?:ValueTypes["voyce_quests_old"],
	quest_id?:true,
	updated_at?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_quests_old" */
["voyce_user_quests_old_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_quests_old_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_quests_old"],
		__typename?: true
}>;
	["voyce_user_quests_old_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_user_quests_old_aggregate_bool_exp_count"]
};
	["voyce_user_quests_old_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_user_quests_old_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_user_quests_old_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.user_quests_old" */
["voyce_user_quests_old_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_quests_old_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_quests_old_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_quests_old_max_fields"],
	min?:ValueTypes["voyce_user_quests_old_min_fields"],
	stddev?:ValueTypes["voyce_user_quests_old_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_quests_old_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_quests_old_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_quests_old_sum_fields"],
	var_pop?:ValueTypes["voyce_user_quests_old_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_quests_old_var_samp_fields"],
	variance?:ValueTypes["voyce_user_quests_old_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.user_quests_old" */
["voyce_user_quests_old_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_user_quests_old_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_user_quests_old_max_order_by"],
	min?:ValueTypes["voyce_user_quests_old_min_order_by"],
	stddev?:ValueTypes["voyce_user_quests_old_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_user_quests_old_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_user_quests_old_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_user_quests_old_sum_order_by"],
	var_pop?:ValueTypes["voyce_user_quests_old_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_user_quests_old_var_samp_order_by"],
	variance?:ValueTypes["voyce_user_quests_old_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.user_quests_old" */
["voyce_user_quests_old_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_user_quests_old_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_quests_old_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_user_quests_old_avg_fields"]: AliasType<{
	id?:true,
	progress?:true,
	quest_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	progress?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.user_quests_old". All fields are combined with a logical 'AND'. */
["voyce_user_quests_old_bool_exp"]: {
	_and?:ValueTypes["voyce_user_quests_old_bool_exp"][],
	_not?:ValueTypes["voyce_user_quests_old_bool_exp"],
	_or?:ValueTypes["voyce_user_quests_old_bool_exp"][],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	progress?:ValueTypes["numeric_comparison_exp"],
	quest?:ValueTypes["voyce_quests_old_bool_exp"],
	quest_id?:ValueTypes["Int_comparison_exp"],
	updated_at?:ValueTypes["timestamp_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_quests_old" */
["voyce_user_quests_old_constraint"]:voyce_user_quests_old_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_quests_old" */
["voyce_user_quests_old_inc_input"]: {
	id?:number,
	progress?:ValueTypes["numeric"],
	quest_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_quests_old" */
["voyce_user_quests_old_insert_input"]: {
	created_at?:ValueTypes["timestamp"],
	id?:number,
	progress?:ValueTypes["numeric"],
	quest?:ValueTypes["voyce_quests_old_obj_rel_insert_input"],
	quest_id?:number,
	updated_at?:ValueTypes["timestamp"],
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_user_quests_old_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	progress?:true,
	quest_id?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_max_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	progress?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_user_quests_old_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	progress?:true,
	quest_id?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_min_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	progress?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.user_quests_old" */
["voyce_user_quests_old_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_quests_old"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_quests_old" */
["voyce_user_quests_old_on_conflict"]: {
	constraint:ValueTypes["voyce_user_quests_old_constraint"],
	update_columns:ValueTypes["voyce_user_quests_old_update_column"][],
	where?:ValueTypes["voyce_user_quests_old_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_quests_old". */
["voyce_user_quests_old_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	progress?:ValueTypes["order_by"],
	quest?:ValueTypes["voyce_quests_old_order_by"],
	quest_id?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_quests_old */
["voyce_user_quests_old_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_select_column"]:voyce_user_quests_old_select_column;
	/** input type for updating data in table "voyce.user_quests_old" */
["voyce_user_quests_old_set_input"]: {
	created_at?:ValueTypes["timestamp"],
	id?:number,
	progress?:ValueTypes["numeric"],
	quest_id?:number,
	updated_at?:ValueTypes["timestamp"],
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_quests_old_stddev_fields"]: AliasType<{
	id?:true,
	progress?:true,
	quest_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	progress?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_user_quests_old_stddev_pop_fields"]: AliasType<{
	id?:true,
	progress?:true,
	quest_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	progress?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_user_quests_old_stddev_samp_fields"]: AliasType<{
	id?:true,
	progress?:true,
	quest_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	progress?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_user_quests_old" */
["voyce_user_quests_old_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_quests_old_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_quests_old_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamp"],
	id?:number,
	progress?:ValueTypes["numeric"],
	quest_id?:number,
	updated_at?:ValueTypes["timestamp"],
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_quests_old_sum_fields"]: AliasType<{
	id?:true,
	progress?:true,
	quest_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	progress?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_update_column"]:voyce_user_quests_old_update_column;
	["voyce_user_quests_old_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_quests_old_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_quests_old_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_quests_old_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_quests_old_var_pop_fields"]: AliasType<{
	id?:true,
	progress?:true,
	quest_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	progress?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_user_quests_old_var_samp_fields"]: AliasType<{
	id?:true,
	progress?:true,
	quest_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	progress?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_user_quests_old_variance_fields"]: AliasType<{
	id?:true,
	progress?:true,
	quest_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	progress?:ValueTypes["order_by"],
	quest_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** on_conflict condition type for table "voyce.user_quests" */
["voyce_user_quests_on_conflict"]: {
	constraint:ValueTypes["voyce_user_quests_constraint"],
	update_columns:ValueTypes["voyce_user_quests_update_column"][],
	where?:ValueTypes["voyce_user_quests_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_quests". */
["voyce_user_quests_order_by"]: {
	completed_at?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	data?:ValueTypes["order_by"],
	frequency_tag?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	notified?:ValueTypes["order_by"],
	quest?:ValueTypes["voyce_quests_order_by"],
	quest_id?:ValueTypes["order_by"],
	rewarded_at?:ValueTypes["order_by"],
	rewards_data?:ValueTypes["order_by"],
	status?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	vtag?:ValueTypes["voyce_user_vtags_order_by"]
};
	/** primary key columns input for table: voyce.user_quests */
["voyce_user_quests_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_quests" */
["voyce_user_quests_select_column"]:voyce_user_quests_select_column;
	/** input type for updating data in table "voyce.user_quests" */
["voyce_user_quests_set_input"]: {
	completed_at?:ValueTypes["timestamptz"],
	created_at?:ValueTypes["timestamptz"],
	data?:ValueTypes["json"],
	/** e.g. once = once, daily = date (2024-06-08), weekly = week # or week first day (2024-06-08) */
	frequency_tag?:string,
	id?:number,
	notified?:boolean,
	quest_id?:number,
	rewarded_at?:ValueTypes["timestamptz"],
	rewards_data?:ValueTypes["json"],
	status?:string,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_quests_stddev_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_quests_stddev_pop_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_quests_stddev_samp_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_quests" */
["voyce_user_quests_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_quests_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_quests_stream_cursor_value_input"]: {
	completed_at?:ValueTypes["timestamptz"],
	created_at?:ValueTypes["timestamptz"],
	data?:ValueTypes["json"],
	/** e.g. once = once, daily = date (2024-06-08), weekly = week # or week first day (2024-06-08) */
	frequency_tag?:string,
	id?:number,
	notified?:boolean,
	quest_id?:number,
	rewarded_at?:ValueTypes["timestamptz"],
	rewards_data?:ValueTypes["json"],
	status?:string,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_quests_sum_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_quests" */
["voyce_user_quests_update_column"]:voyce_user_quests_update_column;
	["voyce_user_quests_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_quests_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_quests_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_quests_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_quests_var_pop_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_quests_var_samp_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_quests_variance_fields"]: AliasType<{
	id?:true,
	quest_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** table to keep relation between user and leaderboards */
["voyce_user_ranking"]: AliasType<{
	counter?:true,
	day?:true,
	id?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
	xp_count?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_ranking" */
["voyce_user_ranking_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_ranking_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_ranking"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_ranking" */
["voyce_user_ranking_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_ranking_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_ranking_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_ranking_max_fields"],
	min?:ValueTypes["voyce_user_ranking_min_fields"],
	stddev?:ValueTypes["voyce_user_ranking_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_ranking_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_ranking_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_ranking_sum_fields"],
	var_pop?:ValueTypes["voyce_user_ranking_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_ranking_var_samp_fields"],
	variance?:ValueTypes["voyce_user_ranking_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_ranking_avg_fields"]: AliasType<{
	counter?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	xp_count?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_ranking". All fields are combined with a logical 'AND'. */
["voyce_user_ranking_bool_exp"]: {
	_and?:ValueTypes["voyce_user_ranking_bool_exp"][],
	_not?:ValueTypes["voyce_user_ranking_bool_exp"],
	_or?:ValueTypes["voyce_user_ranking_bool_exp"][],
	counter?:ValueTypes["Int_comparison_exp"],
	day?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	xp_count?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_ranking" */
["voyce_user_ranking_constraint"]:voyce_user_ranking_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_ranking" */
["voyce_user_ranking_inc_input"]: {
	counter?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	xp_count?:number
};
	/** input type for inserting data into table "voyce.user_ranking" */
["voyce_user_ranking_insert_input"]: {
	counter?:number,
	day?:string,
	id?:number,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number,
	xp_count?:number
};
	/** aggregate max on columns */
["voyce_user_ranking_max_fields"]: AliasType<{
	counter?:true,
	day?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	xp_count?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_ranking_min_fields"]: AliasType<{
	counter?:true,
	day?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	xp_count?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_ranking" */
["voyce_user_ranking_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_ranking"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_ranking" */
["voyce_user_ranking_on_conflict"]: {
	constraint:ValueTypes["voyce_user_ranking_constraint"],
	update_columns:ValueTypes["voyce_user_ranking_update_column"][],
	where?:ValueTypes["voyce_user_ranking_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_ranking". */
["voyce_user_ranking_order_by"]: {
	counter?:ValueTypes["order_by"],
	day?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"],
	xp_count?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_ranking */
["voyce_user_ranking_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_ranking" */
["voyce_user_ranking_select_column"]:voyce_user_ranking_select_column;
	/** input type for updating data in table "voyce.user_ranking" */
["voyce_user_ranking_set_input"]: {
	counter?:number,
	day?:string,
	id?:number,
	series_id?:number,
	user_id?:number,
	xp_count?:number
};
	/** aggregate stddev on columns */
["voyce_user_ranking_stddev_fields"]: AliasType<{
	counter?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	xp_count?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_ranking_stddev_pop_fields"]: AliasType<{
	counter?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	xp_count?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_ranking_stddev_samp_fields"]: AliasType<{
	counter?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	xp_count?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_ranking" */
["voyce_user_ranking_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_ranking_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_ranking_stream_cursor_value_input"]: {
	counter?:number,
	day?:string,
	id?:number,
	series_id?:number,
	user_id?:number,
	xp_count?:number
};
	/** aggregate sum on columns */
["voyce_user_ranking_sum_fields"]: AliasType<{
	counter?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	xp_count?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_ranking" */
["voyce_user_ranking_update_column"]:voyce_user_ranking_update_column;
	["voyce_user_ranking_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_ranking_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_ranking_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_ranking_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_ranking_var_pop_fields"]: AliasType<{
	counter?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	xp_count?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_ranking_var_samp_fields"]: AliasType<{
	counter?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	xp_count?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_ranking_variance_fields"]: AliasType<{
	counter?:true,
	id?:true,
	series_id?:true,
	user_id?:true,
	xp_count?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_reviews" */
["voyce_user_reviews"]: AliasType<{
	date_create?:true,
	id?:true,
	publish?:true,
	rating?:true,
	review?:true,
	reviewer_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_reviews" */
["voyce_user_reviews_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_reviews_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_reviews"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_reviews" */
["voyce_user_reviews_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_reviews_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_reviews_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_reviews_max_fields"],
	min?:ValueTypes["voyce_user_reviews_min_fields"],
	stddev?:ValueTypes["voyce_user_reviews_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_reviews_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_reviews_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_reviews_sum_fields"],
	var_pop?:ValueTypes["voyce_user_reviews_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_reviews_var_samp_fields"],
	variance?:ValueTypes["voyce_user_reviews_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_reviews_avg_fields"]: AliasType<{
	id?:true,
	publish?:true,
	rating?:true,
	reviewer_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_reviews". All fields are combined with a logical 'AND'. */
["voyce_user_reviews_bool_exp"]: {
	_and?:ValueTypes["voyce_user_reviews_bool_exp"][],
	_not?:ValueTypes["voyce_user_reviews_bool_exp"],
	_or?:ValueTypes["voyce_user_reviews_bool_exp"][],
	date_create?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	publish?:ValueTypes["smallint_comparison_exp"],
	rating?:ValueTypes["Int_comparison_exp"],
	review?:ValueTypes["String_comparison_exp"],
	reviewer_id?:ValueTypes["Int_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_reviews" */
["voyce_user_reviews_constraint"]:voyce_user_reviews_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_reviews" */
["voyce_user_reviews_inc_input"]: {
	id?:number,
	publish?:ValueTypes["smallint"],
	rating?:number,
	reviewer_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_reviews" */
["voyce_user_reviews_insert_input"]: {
	date_create?:ValueTypes["timestamp"],
	id?:number,
	publish?:ValueTypes["smallint"],
	rating?:number,
	review?:string,
	reviewer_id?:number,
	user_id?:number
};
	/** aggregate max on columns */
["voyce_user_reviews_max_fields"]: AliasType<{
	date_create?:true,
	id?:true,
	publish?:true,
	rating?:true,
	review?:true,
	reviewer_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_reviews_min_fields"]: AliasType<{
	date_create?:true,
	id?:true,
	publish?:true,
	rating?:true,
	review?:true,
	reviewer_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_reviews" */
["voyce_user_reviews_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_reviews"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_reviews" */
["voyce_user_reviews_on_conflict"]: {
	constraint:ValueTypes["voyce_user_reviews_constraint"],
	update_columns:ValueTypes["voyce_user_reviews_update_column"][],
	where?:ValueTypes["voyce_user_reviews_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_reviews". */
["voyce_user_reviews_order_by"]: {
	date_create?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	publish?:ValueTypes["order_by"],
	rating?:ValueTypes["order_by"],
	review?:ValueTypes["order_by"],
	reviewer_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_reviews */
["voyce_user_reviews_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_reviews" */
["voyce_user_reviews_select_column"]:voyce_user_reviews_select_column;
	/** input type for updating data in table "voyce.user_reviews" */
["voyce_user_reviews_set_input"]: {
	date_create?:ValueTypes["timestamp"],
	id?:number,
	publish?:ValueTypes["smallint"],
	rating?:number,
	review?:string,
	reviewer_id?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_reviews_stddev_fields"]: AliasType<{
	id?:true,
	publish?:true,
	rating?:true,
	reviewer_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_reviews_stddev_pop_fields"]: AliasType<{
	id?:true,
	publish?:true,
	rating?:true,
	reviewer_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_reviews_stddev_samp_fields"]: AliasType<{
	id?:true,
	publish?:true,
	rating?:true,
	reviewer_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_reviews" */
["voyce_user_reviews_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_reviews_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_reviews_stream_cursor_value_input"]: {
	date_create?:ValueTypes["timestamp"],
	id?:number,
	publish?:ValueTypes["smallint"],
	rating?:number,
	review?:string,
	reviewer_id?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_reviews_sum_fields"]: AliasType<{
	id?:true,
	publish?:true,
	rating?:true,
	reviewer_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_reviews" */
["voyce_user_reviews_update_column"]:voyce_user_reviews_update_column;
	["voyce_user_reviews_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_reviews_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_reviews_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_reviews_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_reviews_var_pop_fields"]: AliasType<{
	id?:true,
	publish?:true,
	rating?:true,
	reviewer_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_reviews_var_samp_fields"]: AliasType<{
	id?:true,
	publish?:true,
	rating?:true,
	reviewer_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_reviews_variance_fields"]: AliasType<{
	id?:true,
	publish?:true,
	rating?:true,
	reviewer_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** table to describe rewards won by users */
["voyce_user_rewards"]: AliasType<{
	coin_amount?:true,
	coin_type?:true,
	created_at?:true,
	id?:true,
	type?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_rewards" */
["voyce_user_rewards_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_rewards_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_rewards"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_rewards" */
["voyce_user_rewards_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_rewards_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_rewards_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_rewards_max_fields"],
	min?:ValueTypes["voyce_user_rewards_min_fields"],
	stddev?:ValueTypes["voyce_user_rewards_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_rewards_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_rewards_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_rewards_sum_fields"],
	var_pop?:ValueTypes["voyce_user_rewards_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_rewards_var_samp_fields"],
	variance?:ValueTypes["voyce_user_rewards_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_rewards_avg_fields"]: AliasType<{
	coin_amount?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_rewards". All fields are combined with a logical 'AND'. */
["voyce_user_rewards_bool_exp"]: {
	_and?:ValueTypes["voyce_user_rewards_bool_exp"][],
	_not?:ValueTypes["voyce_user_rewards_bool_exp"],
	_or?:ValueTypes["voyce_user_rewards_bool_exp"][],
	coin_amount?:ValueTypes["numeric_comparison_exp"],
	coin_type?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	type?:ValueTypes["String_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_rewards" */
["voyce_user_rewards_constraint"]:voyce_user_rewards_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_rewards" */
["voyce_user_rewards_inc_input"]: {
	coin_amount?:ValueTypes["numeric"],
	id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_rewards" */
["voyce_user_rewards_insert_input"]: {
	coin_amount?:ValueTypes["numeric"],
	coin_type?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	type?:string,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_user_rewards_max_fields"]: AliasType<{
	coin_amount?:true,
	coin_type?:true,
	created_at?:true,
	id?:true,
	type?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_rewards_min_fields"]: AliasType<{
	coin_amount?:true,
	coin_type?:true,
	created_at?:true,
	id?:true,
	type?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_rewards" */
["voyce_user_rewards_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_rewards"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_rewards" */
["voyce_user_rewards_on_conflict"]: {
	constraint:ValueTypes["voyce_user_rewards_constraint"],
	update_columns:ValueTypes["voyce_user_rewards_update_column"][],
	where?:ValueTypes["voyce_user_rewards_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_rewards". */
["voyce_user_rewards_order_by"]: {
	coin_amount?:ValueTypes["order_by"],
	coin_type?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	type?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_rewards */
["voyce_user_rewards_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_rewards" */
["voyce_user_rewards_select_column"]:voyce_user_rewards_select_column;
	/** input type for updating data in table "voyce.user_rewards" */
["voyce_user_rewards_set_input"]: {
	coin_amount?:ValueTypes["numeric"],
	coin_type?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	type?:string,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_rewards_stddev_fields"]: AliasType<{
	coin_amount?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_rewards_stddev_pop_fields"]: AliasType<{
	coin_amount?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_rewards_stddev_samp_fields"]: AliasType<{
	coin_amount?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_rewards" */
["voyce_user_rewards_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_rewards_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_rewards_stream_cursor_value_input"]: {
	coin_amount?:ValueTypes["numeric"],
	coin_type?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	type?:string,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_rewards_sum_fields"]: AliasType<{
	coin_amount?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_rewards" */
["voyce_user_rewards_update_column"]:voyce_user_rewards_update_column;
	["voyce_user_rewards_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_rewards_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_rewards_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_rewards_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_rewards_var_pop_fields"]: AliasType<{
	coin_amount?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_rewards_var_samp_fields"]: AliasType<{
	coin_amount?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_rewards_variance_fields"]: AliasType<{
	coin_amount?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_roles" */
["voyce_user_roles"]: AliasType<{
	date_create?:true,
	id?:true,
	title?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_roles" */
["voyce_user_roles_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_roles_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_roles"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_roles" */
["voyce_user_roles_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_roles_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_roles_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_roles_max_fields"],
	min?:ValueTypes["voyce_user_roles_min_fields"],
	stddev?:ValueTypes["voyce_user_roles_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_roles_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_roles_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_roles_sum_fields"],
	var_pop?:ValueTypes["voyce_user_roles_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_roles_var_samp_fields"],
	variance?:ValueTypes["voyce_user_roles_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_roles_avg_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_roles". All fields are combined with a logical 'AND'. */
["voyce_user_roles_bool_exp"]: {
	_and?:ValueTypes["voyce_user_roles_bool_exp"][],
	_not?:ValueTypes["voyce_user_roles_bool_exp"],
	_or?:ValueTypes["voyce_user_roles_bool_exp"][],
	date_create?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_roles" */
["voyce_user_roles_constraint"]:voyce_user_roles_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_roles" */
["voyce_user_roles_inc_input"]: {
	id?:number
};
	/** input type for inserting data into table "voyce.user_roles" */
["voyce_user_roles_insert_input"]: {
	date_create?:ValueTypes["timestamp"],
	id?:number,
	title?:string
};
	/** aggregate max on columns */
["voyce_user_roles_max_fields"]: AliasType<{
	date_create?:true,
	id?:true,
	title?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_roles_min_fields"]: AliasType<{
	date_create?:true,
	id?:true,
	title?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_roles" */
["voyce_user_roles_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_roles"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.user_roles" */
["voyce_user_roles_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_user_roles_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_roles_on_conflict"]
};
	/** on_conflict condition type for table "voyce.user_roles" */
["voyce_user_roles_on_conflict"]: {
	constraint:ValueTypes["voyce_user_roles_constraint"],
	update_columns:ValueTypes["voyce_user_roles_update_column"][],
	where?:ValueTypes["voyce_user_roles_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_roles". */
["voyce_user_roles_order_by"]: {
	date_create?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_roles */
["voyce_user_roles_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_roles" */
["voyce_user_roles_select_column"]:voyce_user_roles_select_column;
	/** input type for updating data in table "voyce.user_roles" */
["voyce_user_roles_set_input"]: {
	date_create?:ValueTypes["timestamp"],
	id?:number,
	title?:string
};
	/** aggregate stddev on columns */
["voyce_user_roles_stddev_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_roles_stddev_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_roles_stddev_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_roles" */
["voyce_user_roles_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_roles_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_roles_stream_cursor_value_input"]: {
	date_create?:ValueTypes["timestamp"],
	id?:number,
	title?:string
};
	/** aggregate sum on columns */
["voyce_user_roles_sum_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_roles" */
["voyce_user_roles_update_column"]:voyce_user_roles_update_column;
	["voyce_user_roles_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_roles_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_roles_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_roles_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_roles_var_pop_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_roles_var_samp_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_roles_variance_fields"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count"]: AliasType<{
	count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_series_subscription_count_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_series_subscription_count"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_series_subscription_count_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_series_subscription_count_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_series_subscription_count_max_fields"],
	min?:ValueTypes["voyce_user_series_subscription_count_min_fields"],
	stddev?:ValueTypes["voyce_user_series_subscription_count_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_series_subscription_count_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_series_subscription_count_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_series_subscription_count_sum_fields"],
	var_pop?:ValueTypes["voyce_user_series_subscription_count_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_series_subscription_count_var_samp_fields"],
	variance?:ValueTypes["voyce_user_series_subscription_count_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_series_subscription_count_avg_fields"]: AliasType<{
	count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_series_subscription_count". All fields are combined with a logical 'AND'. */
["voyce_user_series_subscription_count_bool_exp"]: {
	_and?:ValueTypes["voyce_user_series_subscription_count_bool_exp"][],
	_not?:ValueTypes["voyce_user_series_subscription_count_bool_exp"],
	_or?:ValueTypes["voyce_user_series_subscription_count_bool_exp"][],
	count?:ValueTypes["bigint_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_constraint"]:voyce_user_series_subscription_count_constraint;
	/** input type for inserting data into table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_insert_input"]: {
	count?:ValueTypes["bigint"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_user_series_subscription_count_max_fields"]: AliasType<{
	count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_series_subscription_count_min_fields"]: AliasType<{
	count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_user_series_subscription_count_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_series_subscription_count_on_conflict"]
};
	/** on_conflict condition type for table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_on_conflict"]: {
	constraint:ValueTypes["voyce_user_series_subscription_count_constraint"],
	update_columns:ValueTypes["voyce_user_series_subscription_count_update_column"][],
	where?:ValueTypes["voyce_user_series_subscription_count_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_series_subscription_count". */
["voyce_user_series_subscription_count_order_by"]: {
	count?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_select_column"]:voyce_user_series_subscription_count_select_column;
	/** aggregate stddev on columns */
["voyce_user_series_subscription_count_stddev_fields"]: AliasType<{
	count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_series_subscription_count_stddev_pop_fields"]: AliasType<{
	count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_series_subscription_count_stddev_samp_fields"]: AliasType<{
	count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_series_subscription_count" */
["voyce_user_series_subscription_count_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_series_subscription_count_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_series_subscription_count_stream_cursor_value_input"]: {
	count?:ValueTypes["bigint"],
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_series_subscription_count_sum_fields"]: AliasType<{
	count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_update_column"]:voyce_user_series_subscription_count_update_column;
	/** aggregate var_pop on columns */
["voyce_user_series_subscription_count_var_pop_fields"]: AliasType<{
	count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_series_subscription_count_var_samp_fields"]: AliasType<{
	count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_series_subscription_count_variance_fields"]: AliasType<{
	count?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_settings" */
["voyce_user_settings"]: AliasType<{
	content_mature?:true,
	content_spoilers?:true,
	id?:true,
	page_animation?:true,
	reading_comic?:true,
	reading_manga?:true,
	reading_novel?:true,
	theme?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_settings" */
["voyce_user_settings_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_settings_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_settings"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_settings" */
["voyce_user_settings_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_settings_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_settings_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_settings_max_fields"],
	min?:ValueTypes["voyce_user_settings_min_fields"],
	stddev?:ValueTypes["voyce_user_settings_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_settings_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_settings_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_settings_sum_fields"],
	var_pop?:ValueTypes["voyce_user_settings_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_settings_var_samp_fields"],
	variance?:ValueTypes["voyce_user_settings_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_settings_avg_fields"]: AliasType<{
	content_mature?:true,
	content_spoilers?:true,
	id?:true,
	page_animation?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_settings". All fields are combined with a logical 'AND'. */
["voyce_user_settings_bool_exp"]: {
	_and?:ValueTypes["voyce_user_settings_bool_exp"][],
	_not?:ValueTypes["voyce_user_settings_bool_exp"],
	_or?:ValueTypes["voyce_user_settings_bool_exp"][],
	content_mature?:ValueTypes["smallint_comparison_exp"],
	content_spoilers?:ValueTypes["smallint_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	page_animation?:ValueTypes["smallint_comparison_exp"],
	reading_comic?:ValueTypes["String_comparison_exp"],
	reading_manga?:ValueTypes["String_comparison_exp"],
	reading_novel?:ValueTypes["String_comparison_exp"],
	theme?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_settings" */
["voyce_user_settings_constraint"]:voyce_user_settings_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_settings" */
["voyce_user_settings_inc_input"]: {
	content_mature?:ValueTypes["smallint"],
	content_spoilers?:ValueTypes["smallint"],
	id?:number,
	page_animation?:ValueTypes["smallint"],
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_settings" */
["voyce_user_settings_insert_input"]: {
	content_mature?:ValueTypes["smallint"],
	content_spoilers?:ValueTypes["smallint"],
	id?:number,
	page_animation?:ValueTypes["smallint"],
	reading_comic?:string,
	reading_manga?:string,
	reading_novel?:string,
	theme?:string,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_user_settings_max_fields"]: AliasType<{
	content_mature?:true,
	content_spoilers?:true,
	id?:true,
	page_animation?:true,
	reading_comic?:true,
	reading_manga?:true,
	reading_novel?:true,
	theme?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_settings_min_fields"]: AliasType<{
	content_mature?:true,
	content_spoilers?:true,
	id?:true,
	page_animation?:true,
	reading_comic?:true,
	reading_manga?:true,
	reading_novel?:true,
	theme?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_settings" */
["voyce_user_settings_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_settings"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_settings" */
["voyce_user_settings_on_conflict"]: {
	constraint:ValueTypes["voyce_user_settings_constraint"],
	update_columns:ValueTypes["voyce_user_settings_update_column"][],
	where?:ValueTypes["voyce_user_settings_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_settings". */
["voyce_user_settings_order_by"]: {
	content_mature?:ValueTypes["order_by"],
	content_spoilers?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	page_animation?:ValueTypes["order_by"],
	reading_comic?:ValueTypes["order_by"],
	reading_manga?:ValueTypes["order_by"],
	reading_novel?:ValueTypes["order_by"],
	theme?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_settings */
["voyce_user_settings_pk_columns_input"]: {
	user_id:number
};
	/** select columns of table "voyce.user_settings" */
["voyce_user_settings_select_column"]:voyce_user_settings_select_column;
	/** input type for updating data in table "voyce.user_settings" */
["voyce_user_settings_set_input"]: {
	content_mature?:ValueTypes["smallint"],
	content_spoilers?:ValueTypes["smallint"],
	id?:number,
	page_animation?:ValueTypes["smallint"],
	reading_comic?:string,
	reading_manga?:string,
	reading_novel?:string,
	theme?:string,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_settings_stddev_fields"]: AliasType<{
	content_mature?:true,
	content_spoilers?:true,
	id?:true,
	page_animation?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_settings_stddev_pop_fields"]: AliasType<{
	content_mature?:true,
	content_spoilers?:true,
	id?:true,
	page_animation?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_settings_stddev_samp_fields"]: AliasType<{
	content_mature?:true,
	content_spoilers?:true,
	id?:true,
	page_animation?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_settings" */
["voyce_user_settings_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_settings_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_settings_stream_cursor_value_input"]: {
	content_mature?:ValueTypes["smallint"],
	content_spoilers?:ValueTypes["smallint"],
	id?:number,
	page_animation?:ValueTypes["smallint"],
	reading_comic?:string,
	reading_manga?:string,
	reading_novel?:string,
	theme?:string,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_settings_sum_fields"]: AliasType<{
	content_mature?:true,
	content_spoilers?:true,
	id?:true,
	page_animation?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_settings" */
["voyce_user_settings_update_column"]:voyce_user_settings_update_column;
	["voyce_user_settings_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_settings_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_settings_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_settings_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_settings_var_pop_fields"]: AliasType<{
	content_mature?:true,
	content_spoilers?:true,
	id?:true,
	page_animation?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_settings_var_samp_fields"]: AliasType<{
	content_mature?:true,
	content_spoilers?:true,
	id?:true,
	page_animation?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_settings_variance_fields"]: AliasType<{
	content_mature?:true,
	content_spoilers?:true,
	id?:true,
	page_animation?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_skills" */
["voyce_user_skills"]: AliasType<{
	date_create?:true,
	id?:true,
	skill?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_skills" */
["voyce_user_skills_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_skills_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_skills"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_skills" */
["voyce_user_skills_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_skills_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_skills_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_skills_max_fields"],
	min?:ValueTypes["voyce_user_skills_min_fields"],
	stddev?:ValueTypes["voyce_user_skills_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_skills_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_skills_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_skills_sum_fields"],
	var_pop?:ValueTypes["voyce_user_skills_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_skills_var_samp_fields"],
	variance?:ValueTypes["voyce_user_skills_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_skills_avg_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_skills". All fields are combined with a logical 'AND'. */
["voyce_user_skills_bool_exp"]: {
	_and?:ValueTypes["voyce_user_skills_bool_exp"][],
	_not?:ValueTypes["voyce_user_skills_bool_exp"],
	_or?:ValueTypes["voyce_user_skills_bool_exp"][],
	date_create?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	skill?:ValueTypes["String_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_skills" */
["voyce_user_skills_constraint"]:voyce_user_skills_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_skills" */
["voyce_user_skills_inc_input"]: {
	id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_skills" */
["voyce_user_skills_insert_input"]: {
	date_create?:ValueTypes["timestamp"],
	id?:number,
	skill?:string,
	user_id?:number
};
	/** aggregate max on columns */
["voyce_user_skills_max_fields"]: AliasType<{
	date_create?:true,
	id?:true,
	skill?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_skills_min_fields"]: AliasType<{
	date_create?:true,
	id?:true,
	skill?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_skills" */
["voyce_user_skills_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_skills"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_skills" */
["voyce_user_skills_on_conflict"]: {
	constraint:ValueTypes["voyce_user_skills_constraint"],
	update_columns:ValueTypes["voyce_user_skills_update_column"][],
	where?:ValueTypes["voyce_user_skills_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_skills". */
["voyce_user_skills_order_by"]: {
	date_create?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	skill?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_skills */
["voyce_user_skills_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_skills" */
["voyce_user_skills_select_column"]:voyce_user_skills_select_column;
	/** input type for updating data in table "voyce.user_skills" */
["voyce_user_skills_set_input"]: {
	date_create?:ValueTypes["timestamp"],
	id?:number,
	skill?:string,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_skills_stddev_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_skills_stddev_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_skills_stddev_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_skills" */
["voyce_user_skills_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_skills_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_skills_stream_cursor_value_input"]: {
	date_create?:ValueTypes["timestamp"],
	id?:number,
	skill?:string,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_skills_sum_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_skills" */
["voyce_user_skills_update_column"]:voyce_user_skills_update_column;
	["voyce_user_skills_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_skills_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_skills_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_skills_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_skills_var_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_skills_var_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_skills_variance_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_socials" */
["voyce_user_socials"]: AliasType<{
	access_token?:true,
	created_at?:true,
	expired_at?:true,
	expires_in?:true,
	id?:true,
	provider?:true,
	refresh_token?:true,
	scope?:true,
	token_type?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_socials" */
["voyce_user_socials_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_socials_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_socials"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_socials" */
["voyce_user_socials_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_socials_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_socials_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_socials_max_fields"],
	min?:ValueTypes["voyce_user_socials_min_fields"],
	stddev?:ValueTypes["voyce_user_socials_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_socials_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_socials_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_socials_sum_fields"],
	var_pop?:ValueTypes["voyce_user_socials_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_socials_var_samp_fields"],
	variance?:ValueTypes["voyce_user_socials_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_socials_avg_fields"]: AliasType<{
	expires_in?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_socials". All fields are combined with a logical 'AND'. */
["voyce_user_socials_bool_exp"]: {
	_and?:ValueTypes["voyce_user_socials_bool_exp"][],
	_not?:ValueTypes["voyce_user_socials_bool_exp"],
	_or?:ValueTypes["voyce_user_socials_bool_exp"][],
	access_token?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	expired_at?:ValueTypes["timestamptz_comparison_exp"],
	expires_in?:ValueTypes["numeric_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	provider?:ValueTypes["String_comparison_exp"],
	refresh_token?:ValueTypes["String_comparison_exp"],
	scope?:ValueTypes["String_comparison_exp"],
	token_type?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_socials" */
["voyce_user_socials_constraint"]:voyce_user_socials_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_socials" */
["voyce_user_socials_inc_input"]: {
	expires_in?:ValueTypes["numeric"],
	id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_socials" */
["voyce_user_socials_insert_input"]: {
	access_token?:string,
	created_at?:ValueTypes["timestamptz"],
	expired_at?:ValueTypes["timestamptz"],
	expires_in?:ValueTypes["numeric"],
	id?:number,
	provider?:string,
	refresh_token?:string,
	scope?:string,
	token_type?:string,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_user_socials_max_fields"]: AliasType<{
	access_token?:true,
	created_at?:true,
	expired_at?:true,
	expires_in?:true,
	id?:true,
	provider?:true,
	refresh_token?:true,
	scope?:true,
	token_type?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_socials_min_fields"]: AliasType<{
	access_token?:true,
	created_at?:true,
	expired_at?:true,
	expires_in?:true,
	id?:true,
	provider?:true,
	refresh_token?:true,
	scope?:true,
	token_type?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_socials" */
["voyce_user_socials_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_socials"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_socials" */
["voyce_user_socials_on_conflict"]: {
	constraint:ValueTypes["voyce_user_socials_constraint"],
	update_columns:ValueTypes["voyce_user_socials_update_column"][],
	where?:ValueTypes["voyce_user_socials_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_socials". */
["voyce_user_socials_order_by"]: {
	access_token?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	expired_at?:ValueTypes["order_by"],
	expires_in?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	provider?:ValueTypes["order_by"],
	refresh_token?:ValueTypes["order_by"],
	scope?:ValueTypes["order_by"],
	token_type?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_socials */
["voyce_user_socials_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_socials" */
["voyce_user_socials_select_column"]:voyce_user_socials_select_column;
	/** input type for updating data in table "voyce.user_socials" */
["voyce_user_socials_set_input"]: {
	access_token?:string,
	created_at?:ValueTypes["timestamptz"],
	expired_at?:ValueTypes["timestamptz"],
	expires_in?:ValueTypes["numeric"],
	id?:number,
	provider?:string,
	refresh_token?:string,
	scope?:string,
	token_type?:string,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_socials_stddev_fields"]: AliasType<{
	expires_in?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_socials_stddev_pop_fields"]: AliasType<{
	expires_in?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_socials_stddev_samp_fields"]: AliasType<{
	expires_in?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_socials" */
["voyce_user_socials_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_socials_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_socials_stream_cursor_value_input"]: {
	access_token?:string,
	created_at?:ValueTypes["timestamptz"],
	expired_at?:ValueTypes["timestamptz"],
	expires_in?:ValueTypes["numeric"],
	id?:number,
	provider?:string,
	refresh_token?:string,
	scope?:string,
	token_type?:string,
	updated_at?:ValueTypes["timestamptz"],
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_socials_sum_fields"]: AliasType<{
	expires_in?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_socials" */
["voyce_user_socials_update_column"]:voyce_user_socials_update_column;
	["voyce_user_socials_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_socials_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_socials_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_socials_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_socials_var_pop_fields"]: AliasType<{
	expires_in?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_socials_var_samp_fields"]: AliasType<{
	expires_in?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_socials_variance_fields"]: AliasType<{
	expires_in?:true,
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_soft_deleted" */
["voyce_user_soft_deleted"]: AliasType<{
	about?:true,
	avatar?:true,
	banner?:true,
	created_at?:true,
	deleted_at?:true,
	email?:true,
	first_name?:true,
	id?:true,
	is_deleted?:true,
	last_name?:true,
	nickname?:true,
	social_discord?:true,
	social_fb?:true,
	social_instagram?:true,
	social_tiktok?:true,
	social_twitter?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
	username?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_soft_deleted_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_soft_deleted"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_soft_deleted_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_soft_deleted_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_soft_deleted_max_fields"],
	min?:ValueTypes["voyce_user_soft_deleted_min_fields"],
	stddev?:ValueTypes["voyce_user_soft_deleted_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_soft_deleted_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_soft_deleted_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_soft_deleted_sum_fields"],
	var_pop?:ValueTypes["voyce_user_soft_deleted_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_soft_deleted_var_samp_fields"],
	variance?:ValueTypes["voyce_user_soft_deleted_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_soft_deleted_avg_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_soft_deleted". All fields are combined with a logical 'AND'. */
["voyce_user_soft_deleted_bool_exp"]: {
	_and?:ValueTypes["voyce_user_soft_deleted_bool_exp"][],
	_not?:ValueTypes["voyce_user_soft_deleted_bool_exp"],
	_or?:ValueTypes["voyce_user_soft_deleted_bool_exp"][],
	about?:ValueTypes["String_comparison_exp"],
	avatar?:ValueTypes["String_comparison_exp"],
	banner?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	deleted_at?:ValueTypes["timestamptz_comparison_exp"],
	email?:ValueTypes["String_comparison_exp"],
	first_name?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_deleted?:ValueTypes["Boolean_comparison_exp"],
	last_name?:ValueTypes["String_comparison_exp"],
	nickname?:ValueTypes["String_comparison_exp"],
	social_discord?:ValueTypes["String_comparison_exp"],
	social_fb?:ValueTypes["String_comparison_exp"],
	social_instagram?:ValueTypes["String_comparison_exp"],
	social_tiktok?:ValueTypes["String_comparison_exp"],
	social_twitter?:ValueTypes["String_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	username?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_constraint"]:voyce_user_soft_deleted_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_inc_input"]: {
	id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_insert_input"]: {
	about?:string,
	avatar?:string,
	banner?:string,
	created_at?:ValueTypes["timestamptz"],
	deleted_at?:ValueTypes["timestamptz"],
	email?:string,
	first_name?:string,
	id?:number,
	is_deleted?:boolean,
	last_name?:string,
	nickname?:string,
	social_discord?:string,
	social_fb?:string,
	social_instagram?:string,
	social_tiktok?:string,
	social_twitter?:string,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number,
	username?:string
};
	/** aggregate max on columns */
["voyce_user_soft_deleted_max_fields"]: AliasType<{
	about?:true,
	avatar?:true,
	banner?:true,
	created_at?:true,
	deleted_at?:true,
	email?:true,
	first_name?:true,
	id?:true,
	last_name?:true,
	nickname?:true,
	social_discord?:true,
	social_fb?:true,
	social_instagram?:true,
	social_tiktok?:true,
	social_twitter?:true,
	user_id?:true,
	username?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_soft_deleted_min_fields"]: AliasType<{
	about?:true,
	avatar?:true,
	banner?:true,
	created_at?:true,
	deleted_at?:true,
	email?:true,
	first_name?:true,
	id?:true,
	last_name?:true,
	nickname?:true,
	social_discord?:true,
	social_fb?:true,
	social_instagram?:true,
	social_tiktok?:true,
	social_twitter?:true,
	user_id?:true,
	username?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_soft_deleted"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_on_conflict"]: {
	constraint:ValueTypes["voyce_user_soft_deleted_constraint"],
	update_columns:ValueTypes["voyce_user_soft_deleted_update_column"][],
	where?:ValueTypes["voyce_user_soft_deleted_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_soft_deleted". */
["voyce_user_soft_deleted_order_by"]: {
	about?:ValueTypes["order_by"],
	avatar?:ValueTypes["order_by"],
	banner?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	deleted_at?:ValueTypes["order_by"],
	email?:ValueTypes["order_by"],
	first_name?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_deleted?:ValueTypes["order_by"],
	last_name?:ValueTypes["order_by"],
	nickname?:ValueTypes["order_by"],
	social_discord?:ValueTypes["order_by"],
	social_fb?:ValueTypes["order_by"],
	social_instagram?:ValueTypes["order_by"],
	social_tiktok?:ValueTypes["order_by"],
	social_twitter?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"],
	username?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_soft_deleted */
["voyce_user_soft_deleted_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_select_column"]:voyce_user_soft_deleted_select_column;
	/** input type for updating data in table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_set_input"]: {
	about?:string,
	avatar?:string,
	banner?:string,
	created_at?:ValueTypes["timestamptz"],
	deleted_at?:ValueTypes["timestamptz"],
	email?:string,
	first_name?:string,
	id?:number,
	is_deleted?:boolean,
	last_name?:string,
	nickname?:string,
	social_discord?:string,
	social_fb?:string,
	social_instagram?:string,
	social_tiktok?:string,
	social_twitter?:string,
	user_id?:number,
	username?:string
};
	/** aggregate stddev on columns */
["voyce_user_soft_deleted_stddev_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_soft_deleted_stddev_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_soft_deleted_stddev_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_soft_deleted" */
["voyce_user_soft_deleted_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_soft_deleted_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_soft_deleted_stream_cursor_value_input"]: {
	about?:string,
	avatar?:string,
	banner?:string,
	created_at?:ValueTypes["timestamptz"],
	deleted_at?:ValueTypes["timestamptz"],
	email?:string,
	first_name?:string,
	id?:number,
	is_deleted?:boolean,
	last_name?:string,
	nickname?:string,
	social_discord?:string,
	social_fb?:string,
	social_instagram?:string,
	social_tiktok?:string,
	social_twitter?:string,
	user_id?:number,
	username?:string
};
	/** aggregate sum on columns */
["voyce_user_soft_deleted_sum_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_update_column"]:voyce_user_soft_deleted_update_column;
	["voyce_user_soft_deleted_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_soft_deleted_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_soft_deleted_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_soft_deleted_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_soft_deleted_var_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_soft_deleted_var_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_soft_deleted_variance_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.user_vtags" */
["voyce_user_vtags"]: AliasType<{
	id?:true,
	user_id?:true,
	vtag?:true,
	vtag_v2?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_vtags" */
["voyce_user_vtags_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_vtags_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_vtags"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_vtags" */
["voyce_user_vtags_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_vtags_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_vtags_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_vtags_max_fields"],
	min?:ValueTypes["voyce_user_vtags_min_fields"],
	stddev?:ValueTypes["voyce_user_vtags_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_vtags_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_vtags_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_vtags_sum_fields"],
	var_pop?:ValueTypes["voyce_user_vtags_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_vtags_var_samp_fields"],
	variance?:ValueTypes["voyce_user_vtags_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_vtags_avg_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_vtags". All fields are combined with a logical 'AND'. */
["voyce_user_vtags_bool_exp"]: {
	_and?:ValueTypes["voyce_user_vtags_bool_exp"][],
	_not?:ValueTypes["voyce_user_vtags_bool_exp"],
	_or?:ValueTypes["voyce_user_vtags_bool_exp"][],
	id?:ValueTypes["Int_comparison_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	vtag?:ValueTypes["String_comparison_exp"],
	vtag_v2?:ValueTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_vtags" */
["voyce_user_vtags_constraint"]:voyce_user_vtags_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_vtags" */
["voyce_user_vtags_inc_input"]: {
	id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_vtags" */
["voyce_user_vtags_insert_input"]: {
	id?:number,
	user_id?:number,
	vtag?:string,
	vtag_v2?:string
};
	/** aggregate max on columns */
["voyce_user_vtags_max_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	vtag?:true,
	vtag_v2?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_vtags_min_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	vtag?:true,
	vtag_v2?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_vtags" */
["voyce_user_vtags_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_vtags"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.user_vtags" */
["voyce_user_vtags_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_user_vtags_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_user_vtags_on_conflict"]
};
	/** on_conflict condition type for table "voyce.user_vtags" */
["voyce_user_vtags_on_conflict"]: {
	constraint:ValueTypes["voyce_user_vtags_constraint"],
	update_columns:ValueTypes["voyce_user_vtags_update_column"][],
	where?:ValueTypes["voyce_user_vtags_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_vtags". */
["voyce_user_vtags_order_by"]: {
	id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	vtag?:ValueTypes["order_by"],
	vtag_v2?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_vtags */
["voyce_user_vtags_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_vtags" */
["voyce_user_vtags_select_column"]:voyce_user_vtags_select_column;
	/** input type for updating data in table "voyce.user_vtags" */
["voyce_user_vtags_set_input"]: {
	id?:number,
	user_id?:number,
	vtag?:string,
	vtag_v2?:string
};
	/** aggregate stddev on columns */
["voyce_user_vtags_stddev_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_vtags_stddev_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_vtags_stddev_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_vtags" */
["voyce_user_vtags_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_vtags_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_vtags_stream_cursor_value_input"]: {
	id?:number,
	user_id?:number,
	vtag?:string,
	vtag_v2?:string
};
	/** aggregate sum on columns */
["voyce_user_vtags_sum_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_vtags" */
["voyce_user_vtags_update_column"]:voyce_user_vtags_update_column;
	["voyce_user_vtags_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_vtags_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_vtags_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_vtags_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_vtags_var_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_vtags_var_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_vtags_variance_fields"]: AliasType<{
	id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** table to describe quests user is taking part in */
["voyce_user_wins"]: AliasType<{
	claimed?:true,
	created_at?:true,
	id?:true,
	linked_id?:true,
	source?:true,
	title?:true,
	updated_at?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.user_wins" */
["voyce_user_wins_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_user_wins_aggregate_fields"],
	nodes?:ValueTypes["voyce_user_wins"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.user_wins" */
["voyce_user_wins_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_user_wins_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_user_wins_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_user_wins_max_fields"],
	min?:ValueTypes["voyce_user_wins_min_fields"],
	stddev?:ValueTypes["voyce_user_wins_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_user_wins_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_user_wins_stddev_samp_fields"],
	sum?:ValueTypes["voyce_user_wins_sum_fields"],
	var_pop?:ValueTypes["voyce_user_wins_var_pop_fields"],
	var_samp?:ValueTypes["voyce_user_wins_var_samp_fields"],
	variance?:ValueTypes["voyce_user_wins_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_user_wins_avg_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.user_wins". All fields are combined with a logical 'AND'. */
["voyce_user_wins_bool_exp"]: {
	_and?:ValueTypes["voyce_user_wins_bool_exp"][],
	_not?:ValueTypes["voyce_user_wins_bool_exp"],
	_or?:ValueTypes["voyce_user_wins_bool_exp"][],
	claimed?:ValueTypes["Boolean_comparison_exp"],
	created_at?:ValueTypes["timestamp_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	linked_id?:ValueTypes["Int_comparison_exp"],
	source?:ValueTypes["String_comparison_exp"],
	title?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamp_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_wins" */
["voyce_user_wins_constraint"]:voyce_user_wins_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_wins" */
["voyce_user_wins_inc_input"]: {
	id?:number,
	linked_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.user_wins" */
["voyce_user_wins_insert_input"]: {
	claimed?:boolean,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	linked_id?:number,
	source?:string,
	title?:string,
	updated_at?:ValueTypes["timestamp"],
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_user_wins_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	linked_id?:true,
	source?:true,
	title?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_user_wins_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	linked_id?:true,
	source?:true,
	title?:true,
	updated_at?:true,
	user_id?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.user_wins" */
["voyce_user_wins_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_user_wins"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.user_wins" */
["voyce_user_wins_on_conflict"]: {
	constraint:ValueTypes["voyce_user_wins_constraint"],
	update_columns:ValueTypes["voyce_user_wins_update_column"][],
	where?:ValueTypes["voyce_user_wins_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_wins". */
["voyce_user_wins_order_by"]: {
	claimed?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	linked_id?:ValueTypes["order_by"],
	source?:ValueTypes["order_by"],
	title?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_wins */
["voyce_user_wins_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.user_wins" */
["voyce_user_wins_select_column"]:voyce_user_wins_select_column;
	/** input type for updating data in table "voyce.user_wins" */
["voyce_user_wins_set_input"]: {
	claimed?:boolean,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	linked_id?:number,
	source?:string,
	title?:string,
	updated_at?:ValueTypes["timestamp"],
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_user_wins_stddev_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_user_wins_stddev_pop_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_user_wins_stddev_samp_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_user_wins" */
["voyce_user_wins_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_user_wins_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_wins_stream_cursor_value_input"]: {
	claimed?:boolean,
	created_at?:ValueTypes["timestamp"],
	id?:number,
	linked_id?:number,
	source?:string,
	title?:string,
	updated_at?:ValueTypes["timestamp"],
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_user_wins_sum_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.user_wins" */
["voyce_user_wins_update_column"]:voyce_user_wins_update_column;
	["voyce_user_wins_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_user_wins_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_user_wins_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_user_wins_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_wins_var_pop_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_user_wins_var_samp_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_user_wins_variance_fields"]: AliasType<{
	id?:true,
	linked_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.users" */
["voyce_users"]: AliasType<{
	about?:true,
	account_disabled?:true,
	ad_share?:true,
	/** An object relationship */
	admin_role?:ValueTypes["voyce_admin_roles"],
	admin_role_id?:true,
	/** An object relationship */
	author_profile?:ValueTypes["voyce_author_profiles"],
author_profiles?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_author_profiles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_author_profiles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_author_profiles_bool_exp"]},ValueTypes["voyce_author_profiles"]],
author_profiles_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_author_profiles_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_author_profiles_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_author_profiles_bool_exp"]},ValueTypes["voyce_author_profiles_aggregate"]],
	avatar?:true,
	avatar_bgcolor?:true,
	banner?:true,
	birth_date?:true,
chapter_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_bool_exp"]},ValueTypes["voyce_chapter_comments"]],
chapter_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_bool_exp"]},ValueTypes["voyce_chapter_comments_aggregate"]],
chapter_comments_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reaction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reaction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reaction_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction"]],
chapter_comments_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_chapter_comments_reaction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_chapter_comments_reaction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_chapter_comments_reaction_bool_exp"]},ValueTypes["voyce_chapter_comments_reaction_aggregate"]],
	cognito_id?:true,
	cognito_provider?:true,
	cognito_username?:true,
	country_id?:true,
creator_type_preferences?: [{	/** JSON select path */
	path?:string},true],
	dark_mode?:true,
	date_create?:true,
	delete_code?:true,
	email?:true,
	email_verified?:true,
	/** mobile or web or null */
	email_verified_source?:true,
	fcm_token?:true,
fcm_tokens?: [{	/** JSON select path */
	path?:string},true],
	first_name?:true,
following?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_subscription_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_subscription_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_subscription_bool_exp"]},ValueTypes["voyce_series_subscription"]],
following_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_subscription_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_subscription_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_subscription_bool_exp"]},ValueTypes["voyce_series_subscription_aggregate"]],
	frame?:true,
	gender?:true,
	guest_gtag?:true,
hot_panels?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_hot_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_hot_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_hot_panels_bool_exp"]},ValueTypes["voyce_series_hot_panels"]],
hot_panels_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_series_hot_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_series_hot_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_series_hot_panels_bool_exp"]},ValueTypes["voyce_series_hot_panels_aggregate"]],
	id?:true,
	is_account_active?:true,
	is_approved?:true,
	is_onboarded?:true,
	is_publisher_verified?:true,
	klaviyo_id?:true,
	last_login?:true,
	last_name?:true,
	linked_account_status?:true,
	new_email_code?:true,
	newsletter_subscribe?:true,
	nickname?:true,
notification_series_settings?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_notification_series_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_notification_series_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notification_series_settings_bool_exp"]},ValueTypes["voyce_notification_series_settings"]],
notification_series_settings_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_notification_series_settings_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_notification_series_settings_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notification_series_settings_bool_exp"]},ValueTypes["voyce_notification_series_settings_aggregate"]],
	/** An object relationship */
	notification_setting?:ValueTypes["voyce_notification_settings"],
notifications?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_notifications_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_notifications_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notifications_bool_exp"]},ValueTypes["voyce_notifications"]],
notifications_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_notifications_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_notifications_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_notifications_bool_exp"]},ValueTypes["voyce_notifications_aggregate"]],
panels_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_comments_bool_exp"]},ValueTypes["voyce_panels_comments"]],
panels_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_comments_bool_exp"]},ValueTypes["voyce_panels_comments_aggregate"]],
panels_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_bool_exp"]},ValueTypes["voyce_panels_reactions"]],
panels_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_panels_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_panels_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_panels_reactions_bool_exp"]},ValueTypes["voyce_panels_reactions_aggregate"]],
	password?:true,
	password_secure?:true,
payments_to?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_payments_to_creators_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_payments_to_creators_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_payments_to_creators_bool_exp"]},ValueTypes["voyce_payments_to_creators"]],
payments_to_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_payments_to_creators_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_payments_to_creators_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_payments_to_creators_bool_exp"]},ValueTypes["voyce_payments_to_creators_aggregate"]],
polls?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_bool_exp"]},ValueTypes["voyce_polls"]],
polls_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_bool_exp"]},ValueTypes["voyce_polls_aggregate"]],
polls_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_comments_bool_exp"]},ValueTypes["voyce_polls_comments"]],
polls_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_comments_bool_exp"]},ValueTypes["voyce_polls_comments_aggregate"]],
polls_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_reactions_bool_exp"]},ValueTypes["voyce_polls_reactions"]],
polls_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_reactions_bool_exp"]},ValueTypes["voyce_polls_reactions_aggregate"]],
polls_votes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_votes_bool_exp"]},ValueTypes["voyce_polls_votes"]],
polls_votes_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_polls_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_polls_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_polls_votes_bool_exp"]},ValueTypes["voyce_polls_votes_aggregate"]],
posts?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_bool_exp"]},ValueTypes["voyce_posts"]],
posts_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_bool_exp"]},ValueTypes["voyce_posts_aggregate"]],
posts_comments?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_comments_bool_exp"]},ValueTypes["voyce_posts_comments"]],
posts_comments_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_comments_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_comments_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_comments_bool_exp"]},ValueTypes["voyce_posts_comments_aggregate"]],
posts_reactions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_reactions_bool_exp"]},ValueTypes["voyce_posts_reactions"]],
posts_reactions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_posts_reactions_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_posts_reactions_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_posts_reactions_bool_exp"]},ValueTypes["voyce_posts_reactions_aggregate"]],
	/** An object relationship */
	private_fields?:ValueTypes["voyce_users_private"],
	publisher_verification_code?:true,
	quest_onboarded?:true,
	remember_code?:true,
	reset_code?:true,
	reset_code_created_at?:true,
	reset_otp?:true,
	/** An object relationship */
	role?:ValueTypes["voyce_user_roles"],
	role_id?:true,
	/** An object relationship */
	series_subscription_count?:ValueTypes["voyce_user_series_subscription_count"],
	/** mobile or web or null */
	signup_source?:true,
	social_discord?:true,
	social_fb?:true,
	social_instagram?:true,
	social_tiktok?:true,
	social_twitter?:true,
sponsorships?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_sponsorships_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_sponsorships_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_sponsorships_bool_exp"]},ValueTypes["voyce_sponsorships"]],
sponsorshipsBySponsoredUserId?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_sponsorships_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_sponsorships_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_sponsorships_bool_exp"]},ValueTypes["voyce_sponsorships"]],
sponsorshipsBySponsoredUserId_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_sponsorships_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_sponsorships_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_sponsorships_bool_exp"]},ValueTypes["voyce_sponsorships_aggregate"]],
sponsorships_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_sponsorships_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_sponsorships_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_sponsorships_bool_exp"]},ValueTypes["voyce_sponsorships_aggregate"]],
	/** An object relationship */
	stripe_setup?:ValueTypes["voyce_stripe_payouts_setup"],
	unsubscribe?:true,
	updated_at?:true,
	user_type_preference?:true,
	username?:true,
usersFollowersByFollowingUid?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_followers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_followers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_followers_bool_exp"]},ValueTypes["voyce_users_followers"]],
usersFollowersByFollowingUid_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_followers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_followers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_followers_bool_exp"]},ValueTypes["voyce_users_followers_aggregate"]],
users_followers?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_followers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_followers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_followers_bool_exp"]},ValueTypes["voyce_users_followers"]],
users_followers_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_followers_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_followers_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_followers_bool_exp"]},ValueTypes["voyce_users_followers_aggregate"]],
users_series_junctions?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_series_junction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_series_junction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_series_junction_bool_exp"]},ValueTypes["voyce_users_series_junction"]],
users_series_junctions_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_users_series_junction_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_users_series_junction_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_users_series_junction_bool_exp"]},ValueTypes["voyce_users_series_junction_aggregate"]],
	vmuser?:true,
	/** An object relationship */
	vtag?:ValueTypes["voyce_user_vtags"],
		__typename?: true
}>;
	/** aggregated selection of "voyce.users" */
["voyce_users_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_users_aggregate_fields"],
	nodes?:ValueTypes["voyce_users"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.users" */
["voyce_users_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_users_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_users_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_users_max_fields"],
	min?:ValueTypes["voyce_users_min_fields"],
	stddev?:ValueTypes["voyce_users_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_users_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_users_stddev_samp_fields"],
	sum?:ValueTypes["voyce_users_sum_fields"],
	var_pop?:ValueTypes["voyce_users_var_pop_fields"],
	var_samp?:ValueTypes["voyce_users_var_samp_fields"],
	variance?:ValueTypes["voyce_users_variance_fields"],
		__typename?: true
}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
["voyce_users_append_input"]: {
	creator_type_preferences?:ValueTypes["jsonb"],
	fcm_tokens?:ValueTypes["jsonb"]
};
	/** aggregate avg on columns */
["voyce_users_avg_fields"]: AliasType<{
	ad_share?:true,
	admin_role_id?:true,
	country_id?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	is_onboarded?:true,
	role_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.users". All fields are combined with a logical 'AND'. */
["voyce_users_bool_exp"]: {
	_and?:ValueTypes["voyce_users_bool_exp"][],
	_not?:ValueTypes["voyce_users_bool_exp"],
	_or?:ValueTypes["voyce_users_bool_exp"][],
	about?:ValueTypes["String_comparison_exp"],
	account_disabled?:ValueTypes["Boolean_comparison_exp"],
	ad_share?:ValueTypes["Int_comparison_exp"],
	admin_role?:ValueTypes["voyce_admin_roles_bool_exp"],
	admin_role_id?:ValueTypes["Int_comparison_exp"],
	author_profile?:ValueTypes["voyce_author_profiles_bool_exp"],
	author_profiles?:ValueTypes["voyce_author_profiles_bool_exp"],
	author_profiles_aggregate?:ValueTypes["voyce_author_profiles_aggregate_bool_exp"],
	avatar?:ValueTypes["String_comparison_exp"],
	avatar_bgcolor?:ValueTypes["String_comparison_exp"],
	banner?:ValueTypes["String_comparison_exp"],
	birth_date?:ValueTypes["date_comparison_exp"],
	chapter_comments?:ValueTypes["voyce_chapter_comments_bool_exp"],
	chapter_comments_aggregate?:ValueTypes["voyce_chapter_comments_aggregate_bool_exp"],
	chapter_comments_reactions?:ValueTypes["voyce_chapter_comments_reaction_bool_exp"],
	chapter_comments_reactions_aggregate?:ValueTypes["voyce_chapter_comments_reaction_aggregate_bool_exp"],
	cognito_id?:ValueTypes["String_comparison_exp"],
	cognito_provider?:ValueTypes["String_comparison_exp"],
	cognito_username?:ValueTypes["String_comparison_exp"],
	country_id?:ValueTypes["Int_comparison_exp"],
	creator_type_preferences?:ValueTypes["jsonb_comparison_exp"],
	dark_mode?:ValueTypes["smallint_comparison_exp"],
	date_create?:ValueTypes["timestamp_comparison_exp"],
	delete_code?:ValueTypes["String_comparison_exp"],
	email?:ValueTypes["String_comparison_exp"],
	email_verified?:ValueTypes["Boolean_comparison_exp"],
	email_verified_source?:ValueTypes["String_comparison_exp"],
	fcm_token?:ValueTypes["String_comparison_exp"],
	fcm_tokens?:ValueTypes["jsonb_comparison_exp"],
	first_name?:ValueTypes["String_comparison_exp"],
	following?:ValueTypes["voyce_series_subscription_bool_exp"],
	following_aggregate?:ValueTypes["voyce_series_subscription_aggregate_bool_exp"],
	frame?:ValueTypes["String_comparison_exp"],
	gender?:ValueTypes["String_comparison_exp"],
	guest_gtag?:ValueTypes["String_comparison_exp"],
	hot_panels?:ValueTypes["voyce_series_hot_panels_bool_exp"],
	hot_panels_aggregate?:ValueTypes["voyce_series_hot_panels_aggregate_bool_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_account_active?:ValueTypes["smallint_comparison_exp"],
	is_approved?:ValueTypes["Boolean_comparison_exp"],
	is_onboarded?:ValueTypes["Int_comparison_exp"],
	is_publisher_verified?:ValueTypes["Boolean_comparison_exp"],
	klaviyo_id?:ValueTypes["String_comparison_exp"],
	last_login?:ValueTypes["timestamp_comparison_exp"],
	last_name?:ValueTypes["String_comparison_exp"],
	linked_account_status?:ValueTypes["String_comparison_exp"],
	new_email_code?:ValueTypes["String_comparison_exp"],
	newsletter_subscribe?:ValueTypes["Boolean_comparison_exp"],
	nickname?:ValueTypes["String_comparison_exp"],
	notification_series_settings?:ValueTypes["voyce_notification_series_settings_bool_exp"],
	notification_series_settings_aggregate?:ValueTypes["voyce_notification_series_settings_aggregate_bool_exp"],
	notification_setting?:ValueTypes["voyce_notification_settings_bool_exp"],
	notifications?:ValueTypes["voyce_notifications_bool_exp"],
	notifications_aggregate?:ValueTypes["voyce_notifications_aggregate_bool_exp"],
	panels_comments?:ValueTypes["voyce_panels_comments_bool_exp"],
	panels_comments_aggregate?:ValueTypes["voyce_panels_comments_aggregate_bool_exp"],
	panels_reactions?:ValueTypes["voyce_panels_reactions_bool_exp"],
	panels_reactions_aggregate?:ValueTypes["voyce_panels_reactions_aggregate_bool_exp"],
	password?:ValueTypes["String_comparison_exp"],
	password_secure?:ValueTypes["String_comparison_exp"],
	payments_to?:ValueTypes["voyce_payments_to_creators_bool_exp"],
	payments_to_aggregate?:ValueTypes["voyce_payments_to_creators_aggregate_bool_exp"],
	polls?:ValueTypes["voyce_polls_bool_exp"],
	polls_aggregate?:ValueTypes["voyce_polls_aggregate_bool_exp"],
	polls_comments?:ValueTypes["voyce_polls_comments_bool_exp"],
	polls_comments_aggregate?:ValueTypes["voyce_polls_comments_aggregate_bool_exp"],
	polls_reactions?:ValueTypes["voyce_polls_reactions_bool_exp"],
	polls_reactions_aggregate?:ValueTypes["voyce_polls_reactions_aggregate_bool_exp"],
	polls_votes?:ValueTypes["voyce_polls_votes_bool_exp"],
	polls_votes_aggregate?:ValueTypes["voyce_polls_votes_aggregate_bool_exp"],
	posts?:ValueTypes["voyce_posts_bool_exp"],
	posts_aggregate?:ValueTypes["voyce_posts_aggregate_bool_exp"],
	posts_comments?:ValueTypes["voyce_posts_comments_bool_exp"],
	posts_comments_aggregate?:ValueTypes["voyce_posts_comments_aggregate_bool_exp"],
	posts_reactions?:ValueTypes["voyce_posts_reactions_bool_exp"],
	posts_reactions_aggregate?:ValueTypes["voyce_posts_reactions_aggregate_bool_exp"],
	private_fields?:ValueTypes["voyce_users_private_bool_exp"],
	publisher_verification_code?:ValueTypes["String_comparison_exp"],
	quest_onboarded?:ValueTypes["Boolean_comparison_exp"],
	remember_code?:ValueTypes["String_comparison_exp"],
	reset_code?:ValueTypes["String_comparison_exp"],
	reset_code_created_at?:ValueTypes["timestamptz_comparison_exp"],
	reset_otp?:ValueTypes["String_comparison_exp"],
	role?:ValueTypes["voyce_user_roles_bool_exp"],
	role_id?:ValueTypes["Int_comparison_exp"],
	series_subscription_count?:ValueTypes["voyce_user_series_subscription_count_bool_exp"],
	signup_source?:ValueTypes["String_comparison_exp"],
	social_discord?:ValueTypes["String_comparison_exp"],
	social_fb?:ValueTypes["String_comparison_exp"],
	social_instagram?:ValueTypes["String_comparison_exp"],
	social_tiktok?:ValueTypes["String_comparison_exp"],
	social_twitter?:ValueTypes["String_comparison_exp"],
	sponsorships?:ValueTypes["voyce_sponsorships_bool_exp"],
	sponsorshipsBySponsoredUserId?:ValueTypes["voyce_sponsorships_bool_exp"],
	sponsorshipsBySponsoredUserId_aggregate?:ValueTypes["voyce_sponsorships_aggregate_bool_exp"],
	sponsorships_aggregate?:ValueTypes["voyce_sponsorships_aggregate_bool_exp"],
	stripe_setup?:ValueTypes["voyce_stripe_payouts_setup_bool_exp"],
	unsubscribe?:ValueTypes["Boolean_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	user_type_preference?:ValueTypes["String_comparison_exp"],
	username?:ValueTypes["String_comparison_exp"],
	usersFollowersByFollowingUid?:ValueTypes["voyce_users_followers_bool_exp"],
	usersFollowersByFollowingUid_aggregate?:ValueTypes["voyce_users_followers_aggregate_bool_exp"],
	users_followers?:ValueTypes["voyce_users_followers_bool_exp"],
	users_followers_aggregate?:ValueTypes["voyce_users_followers_aggregate_bool_exp"],
	users_series_junctions?:ValueTypes["voyce_users_series_junction_bool_exp"],
	users_series_junctions_aggregate?:ValueTypes["voyce_users_series_junction_aggregate_bool_exp"],
	vmuser?:ValueTypes["Boolean_comparison_exp"],
	vtag?:ValueTypes["voyce_user_vtags_bool_exp"]
};
	/** unique or primary key constraints on table "voyce.users" */
["voyce_users_constraint"]:voyce_users_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["voyce_users_delete_at_path_input"]: {
	creator_type_preferences?:string[],
	fcm_tokens?:string[]
};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["voyce_users_delete_elem_input"]: {
	creator_type_preferences?:number,
	fcm_tokens?:number
};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["voyce_users_delete_key_input"]: {
	creator_type_preferences?:string,
	fcm_tokens?:string
};
	/** columns and relationships of "voyce.users_followers" */
["voyce_users_followers"]: AliasType<{
	created_at?:true,
	follower_uid?:true,
	following_uid?:true,
	id?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	/** An object relationship */
	userByFollowingUid?:ValueTypes["voyce_users"],
		__typename?: true
}>;
	/** aggregated selection of "voyce.users_followers" */
["voyce_users_followers_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_users_followers_aggregate_fields"],
	nodes?:ValueTypes["voyce_users_followers"],
		__typename?: true
}>;
	["voyce_users_followers_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_users_followers_aggregate_bool_exp_count"]
};
	["voyce_users_followers_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_users_followers_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_users_followers_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.users_followers" */
["voyce_users_followers_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_users_followers_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_users_followers_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_users_followers_max_fields"],
	min?:ValueTypes["voyce_users_followers_min_fields"],
	stddev?:ValueTypes["voyce_users_followers_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_users_followers_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_users_followers_stddev_samp_fields"],
	sum?:ValueTypes["voyce_users_followers_sum_fields"],
	var_pop?:ValueTypes["voyce_users_followers_var_pop_fields"],
	var_samp?:ValueTypes["voyce_users_followers_var_samp_fields"],
	variance?:ValueTypes["voyce_users_followers_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.users_followers" */
["voyce_users_followers_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_users_followers_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_users_followers_max_order_by"],
	min?:ValueTypes["voyce_users_followers_min_order_by"],
	stddev?:ValueTypes["voyce_users_followers_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_users_followers_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_users_followers_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_users_followers_sum_order_by"],
	var_pop?:ValueTypes["voyce_users_followers_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_users_followers_var_samp_order_by"],
	variance?:ValueTypes["voyce_users_followers_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.users_followers" */
["voyce_users_followers_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_users_followers_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_users_followers_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_users_followers_avg_fields"]: AliasType<{
	follower_uid?:true,
	following_uid?:true,
	id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.users_followers" */
["voyce_users_followers_avg_order_by"]: {
	follower_uid?:ValueTypes["order_by"],
	following_uid?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.users_followers". All fields are combined with a logical 'AND'. */
["voyce_users_followers_bool_exp"]: {
	_and?:ValueTypes["voyce_users_followers_bool_exp"][],
	_not?:ValueTypes["voyce_users_followers_bool_exp"],
	_or?:ValueTypes["voyce_users_followers_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	follower_uid?:ValueTypes["Int_comparison_exp"],
	following_uid?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	userByFollowingUid?:ValueTypes["voyce_users_bool_exp"]
};
	/** unique or primary key constraints on table "voyce.users_followers" */
["voyce_users_followers_constraint"]:voyce_users_followers_constraint;
	/** input type for incrementing numeric columns in table "voyce.users_followers" */
["voyce_users_followers_inc_input"]: {
	follower_uid?:number,
	following_uid?:number,
	id?:number
};
	/** input type for inserting data into table "voyce.users_followers" */
["voyce_users_followers_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	follower_uid?:number,
	following_uid?:number,
	id?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	userByFollowingUid?:ValueTypes["voyce_users_obj_rel_insert_input"]
};
	/** aggregate max on columns */
["voyce_users_followers_max_fields"]: AliasType<{
	created_at?:true,
	follower_uid?:true,
	following_uid?:true,
	id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.users_followers" */
["voyce_users_followers_max_order_by"]: {
	created_at?:ValueTypes["order_by"],
	follower_uid?:ValueTypes["order_by"],
	following_uid?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_users_followers_min_fields"]: AliasType<{
	created_at?:true,
	follower_uid?:true,
	following_uid?:true,
	id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.users_followers" */
["voyce_users_followers_min_order_by"]: {
	created_at?:ValueTypes["order_by"],
	follower_uid?:ValueTypes["order_by"],
	following_uid?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.users_followers" */
["voyce_users_followers_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_users_followers"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.users_followers" */
["voyce_users_followers_on_conflict"]: {
	constraint:ValueTypes["voyce_users_followers_constraint"],
	update_columns:ValueTypes["voyce_users_followers_update_column"][],
	where?:ValueTypes["voyce_users_followers_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.users_followers". */
["voyce_users_followers_order_by"]: {
	created_at?:ValueTypes["order_by"],
	follower_uid?:ValueTypes["order_by"],
	following_uid?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	userByFollowingUid?:ValueTypes["voyce_users_order_by"]
};
	/** primary key columns input for table: voyce.users_followers */
["voyce_users_followers_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.users_followers" */
["voyce_users_followers_select_column"]:voyce_users_followers_select_column;
	/** input type for updating data in table "voyce.users_followers" */
["voyce_users_followers_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	follower_uid?:number,
	following_uid?:number,
	id?:number
};
	/** aggregate stddev on columns */
["voyce_users_followers_stddev_fields"]: AliasType<{
	follower_uid?:true,
	following_uid?:true,
	id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.users_followers" */
["voyce_users_followers_stddev_order_by"]: {
	follower_uid?:ValueTypes["order_by"],
	following_uid?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_users_followers_stddev_pop_fields"]: AliasType<{
	follower_uid?:true,
	following_uid?:true,
	id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.users_followers" */
["voyce_users_followers_stddev_pop_order_by"]: {
	follower_uid?:ValueTypes["order_by"],
	following_uid?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_users_followers_stddev_samp_fields"]: AliasType<{
	follower_uid?:true,
	following_uid?:true,
	id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.users_followers" */
["voyce_users_followers_stddev_samp_order_by"]: {
	follower_uid?:ValueTypes["order_by"],
	following_uid?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_users_followers" */
["voyce_users_followers_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_users_followers_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_users_followers_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	follower_uid?:number,
	following_uid?:number,
	id?:number
};
	/** aggregate sum on columns */
["voyce_users_followers_sum_fields"]: AliasType<{
	follower_uid?:true,
	following_uid?:true,
	id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.users_followers" */
["voyce_users_followers_sum_order_by"]: {
	follower_uid?:ValueTypes["order_by"],
	following_uid?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.users_followers" */
["voyce_users_followers_update_column"]:voyce_users_followers_update_column;
	["voyce_users_followers_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_users_followers_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_users_followers_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_users_followers_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_users_followers_var_pop_fields"]: AliasType<{
	follower_uid?:true,
	following_uid?:true,
	id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.users_followers" */
["voyce_users_followers_var_pop_order_by"]: {
	follower_uid?:ValueTypes["order_by"],
	following_uid?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_users_followers_var_samp_fields"]: AliasType<{
	follower_uid?:true,
	following_uid?:true,
	id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.users_followers" */
["voyce_users_followers_var_samp_order_by"]: {
	follower_uid?:ValueTypes["order_by"],
	following_uid?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_users_followers_variance_fields"]: AliasType<{
	follower_uid?:true,
	following_uid?:true,
	id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.users_followers" */
["voyce_users_followers_variance_order_by"]: {
	follower_uid?:ValueTypes["order_by"],
	following_uid?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"]
};
	/** input type for incrementing numeric columns in table "voyce.users" */
["voyce_users_inc_input"]: {
	ad_share?:number,
	admin_role_id?:number,
	country_id?:number,
	dark_mode?:ValueTypes["smallint"],
	id?:number,
	is_account_active?:ValueTypes["smallint"],
	is_onboarded?:number,
	role_id?:number
};
	/** input type for inserting data into table "voyce.users" */
["voyce_users_insert_input"]: {
	about?:string,
	account_disabled?:boolean,
	ad_share?:number,
	admin_role?:ValueTypes["voyce_admin_roles_obj_rel_insert_input"],
	admin_role_id?:number,
	author_profile?:ValueTypes["voyce_author_profiles_obj_rel_insert_input"],
	author_profiles?:ValueTypes["voyce_author_profiles_arr_rel_insert_input"],
	avatar?:string,
	avatar_bgcolor?:string,
	banner?:string,
	birth_date?:ValueTypes["date"],
	chapter_comments?:ValueTypes["voyce_chapter_comments_arr_rel_insert_input"],
	chapter_comments_reactions?:ValueTypes["voyce_chapter_comments_reaction_arr_rel_insert_input"],
	cognito_id?:string,
	cognito_provider?:string,
	cognito_username?:string,
	country_id?:number,
	creator_type_preferences?:ValueTypes["jsonb"],
	dark_mode?:ValueTypes["smallint"],
	date_create?:ValueTypes["timestamp"],
	delete_code?:string,
	email?:string,
	email_verified?:boolean,
	/** mobile or web or null */
	email_verified_source?:string,
	fcm_token?:string,
	fcm_tokens?:ValueTypes["jsonb"],
	first_name?:string,
	following?:ValueTypes["voyce_series_subscription_arr_rel_insert_input"],
	frame?:string,
	gender?:string,
	guest_gtag?:string,
	hot_panels?:ValueTypes["voyce_series_hot_panels_arr_rel_insert_input"],
	id?:number,
	is_account_active?:ValueTypes["smallint"],
	is_approved?:boolean,
	is_onboarded?:number,
	is_publisher_verified?:boolean,
	klaviyo_id?:string,
	last_login?:ValueTypes["timestamp"],
	last_name?:string,
	linked_account_status?:string,
	new_email_code?:string,
	newsletter_subscribe?:boolean,
	nickname?:string,
	notification_series_settings?:ValueTypes["voyce_notification_series_settings_arr_rel_insert_input"],
	notification_setting?:ValueTypes["voyce_notification_settings_obj_rel_insert_input"],
	notifications?:ValueTypes["voyce_notifications_arr_rel_insert_input"],
	panels_comments?:ValueTypes["voyce_panels_comments_arr_rel_insert_input"],
	panels_reactions?:ValueTypes["voyce_panels_reactions_arr_rel_insert_input"],
	password?:string,
	password_secure?:string,
	payments_to?:ValueTypes["voyce_payments_to_creators_arr_rel_insert_input"],
	polls?:ValueTypes["voyce_polls_arr_rel_insert_input"],
	polls_comments?:ValueTypes["voyce_polls_comments_arr_rel_insert_input"],
	polls_reactions?:ValueTypes["voyce_polls_reactions_arr_rel_insert_input"],
	polls_votes?:ValueTypes["voyce_polls_votes_arr_rel_insert_input"],
	posts?:ValueTypes["voyce_posts_arr_rel_insert_input"],
	posts_comments?:ValueTypes["voyce_posts_comments_arr_rel_insert_input"],
	posts_reactions?:ValueTypes["voyce_posts_reactions_arr_rel_insert_input"],
	private_fields?:ValueTypes["voyce_users_private_obj_rel_insert_input"],
	publisher_verification_code?:string,
	quest_onboarded?:boolean,
	remember_code?:string,
	reset_code?:string,
	reset_code_created_at?:ValueTypes["timestamptz"],
	reset_otp?:string,
	role?:ValueTypes["voyce_user_roles_obj_rel_insert_input"],
	role_id?:number,
	series_subscription_count?:ValueTypes["voyce_user_series_subscription_count_obj_rel_insert_input"],
	/** mobile or web or null */
	signup_source?:string,
	social_discord?:string,
	social_fb?:string,
	social_instagram?:string,
	social_tiktok?:string,
	social_twitter?:string,
	sponsorships?:ValueTypes["voyce_sponsorships_arr_rel_insert_input"],
	sponsorshipsBySponsoredUserId?:ValueTypes["voyce_sponsorships_arr_rel_insert_input"],
	stripe_setup?:ValueTypes["voyce_stripe_payouts_setup_obj_rel_insert_input"],
	unsubscribe?:boolean,
	updated_at?:ValueTypes["timestamptz"],
	user_type_preference?:string,
	username?:string,
	usersFollowersByFollowingUid?:ValueTypes["voyce_users_followers_arr_rel_insert_input"],
	users_followers?:ValueTypes["voyce_users_followers_arr_rel_insert_input"],
	users_series_junctions?:ValueTypes["voyce_users_series_junction_arr_rel_insert_input"],
	vmuser?:boolean,
	vtag?:ValueTypes["voyce_user_vtags_obj_rel_insert_input"]
};
	/** aggregate max on columns */
["voyce_users_max_fields"]: AliasType<{
	about?:true,
	ad_share?:true,
	admin_role_id?:true,
	avatar?:true,
	avatar_bgcolor?:true,
	banner?:true,
	birth_date?:true,
	cognito_id?:true,
	cognito_provider?:true,
	cognito_username?:true,
	country_id?:true,
	dark_mode?:true,
	date_create?:true,
	delete_code?:true,
	email?:true,
	/** mobile or web or null */
	email_verified_source?:true,
	fcm_token?:true,
	first_name?:true,
	frame?:true,
	gender?:true,
	guest_gtag?:true,
	id?:true,
	is_account_active?:true,
	is_onboarded?:true,
	klaviyo_id?:true,
	last_login?:true,
	last_name?:true,
	linked_account_status?:true,
	new_email_code?:true,
	nickname?:true,
	password?:true,
	password_secure?:true,
	publisher_verification_code?:true,
	remember_code?:true,
	reset_code?:true,
	reset_code_created_at?:true,
	reset_otp?:true,
	role_id?:true,
	/** mobile or web or null */
	signup_source?:true,
	social_discord?:true,
	social_fb?:true,
	social_instagram?:true,
	social_tiktok?:true,
	social_twitter?:true,
	updated_at?:true,
	user_type_preference?:true,
	username?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_users_min_fields"]: AliasType<{
	about?:true,
	ad_share?:true,
	admin_role_id?:true,
	avatar?:true,
	avatar_bgcolor?:true,
	banner?:true,
	birth_date?:true,
	cognito_id?:true,
	cognito_provider?:true,
	cognito_username?:true,
	country_id?:true,
	dark_mode?:true,
	date_create?:true,
	delete_code?:true,
	email?:true,
	/** mobile or web or null */
	email_verified_source?:true,
	fcm_token?:true,
	first_name?:true,
	frame?:true,
	gender?:true,
	guest_gtag?:true,
	id?:true,
	is_account_active?:true,
	is_onboarded?:true,
	klaviyo_id?:true,
	last_login?:true,
	last_name?:true,
	linked_account_status?:true,
	new_email_code?:true,
	nickname?:true,
	password?:true,
	password_secure?:true,
	publisher_verification_code?:true,
	remember_code?:true,
	reset_code?:true,
	reset_code_created_at?:true,
	reset_otp?:true,
	role_id?:true,
	/** mobile or web or null */
	signup_source?:true,
	social_discord?:true,
	social_fb?:true,
	social_instagram?:true,
	social_tiktok?:true,
	social_twitter?:true,
	updated_at?:true,
	user_type_preference?:true,
	username?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.users" */
["voyce_users_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_users"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.users" */
["voyce_users_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_users_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_users_on_conflict"]
};
	/** on_conflict condition type for table "voyce.users" */
["voyce_users_on_conflict"]: {
	constraint:ValueTypes["voyce_users_constraint"],
	update_columns:ValueTypes["voyce_users_update_column"][],
	where?:ValueTypes["voyce_users_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.users". */
["voyce_users_order_by"]: {
	about?:ValueTypes["order_by"],
	account_disabled?:ValueTypes["order_by"],
	ad_share?:ValueTypes["order_by"],
	admin_role?:ValueTypes["voyce_admin_roles_order_by"],
	admin_role_id?:ValueTypes["order_by"],
	author_profile?:ValueTypes["voyce_author_profiles_order_by"],
	author_profiles_aggregate?:ValueTypes["voyce_author_profiles_aggregate_order_by"],
	avatar?:ValueTypes["order_by"],
	avatar_bgcolor?:ValueTypes["order_by"],
	banner?:ValueTypes["order_by"],
	birth_date?:ValueTypes["order_by"],
	chapter_comments_aggregate?:ValueTypes["voyce_chapter_comments_aggregate_order_by"],
	chapter_comments_reactions_aggregate?:ValueTypes["voyce_chapter_comments_reaction_aggregate_order_by"],
	cognito_id?:ValueTypes["order_by"],
	cognito_provider?:ValueTypes["order_by"],
	cognito_username?:ValueTypes["order_by"],
	country_id?:ValueTypes["order_by"],
	creator_type_preferences?:ValueTypes["order_by"],
	dark_mode?:ValueTypes["order_by"],
	date_create?:ValueTypes["order_by"],
	delete_code?:ValueTypes["order_by"],
	email?:ValueTypes["order_by"],
	email_verified?:ValueTypes["order_by"],
	email_verified_source?:ValueTypes["order_by"],
	fcm_token?:ValueTypes["order_by"],
	fcm_tokens?:ValueTypes["order_by"],
	first_name?:ValueTypes["order_by"],
	following_aggregate?:ValueTypes["voyce_series_subscription_aggregate_order_by"],
	frame?:ValueTypes["order_by"],
	gender?:ValueTypes["order_by"],
	guest_gtag?:ValueTypes["order_by"],
	hot_panels_aggregate?:ValueTypes["voyce_series_hot_panels_aggregate_order_by"],
	id?:ValueTypes["order_by"],
	is_account_active?:ValueTypes["order_by"],
	is_approved?:ValueTypes["order_by"],
	is_onboarded?:ValueTypes["order_by"],
	is_publisher_verified?:ValueTypes["order_by"],
	klaviyo_id?:ValueTypes["order_by"],
	last_login?:ValueTypes["order_by"],
	last_name?:ValueTypes["order_by"],
	linked_account_status?:ValueTypes["order_by"],
	new_email_code?:ValueTypes["order_by"],
	newsletter_subscribe?:ValueTypes["order_by"],
	nickname?:ValueTypes["order_by"],
	notification_series_settings_aggregate?:ValueTypes["voyce_notification_series_settings_aggregate_order_by"],
	notification_setting?:ValueTypes["voyce_notification_settings_order_by"],
	notifications_aggregate?:ValueTypes["voyce_notifications_aggregate_order_by"],
	panels_comments_aggregate?:ValueTypes["voyce_panels_comments_aggregate_order_by"],
	panels_reactions_aggregate?:ValueTypes["voyce_panels_reactions_aggregate_order_by"],
	password?:ValueTypes["order_by"],
	password_secure?:ValueTypes["order_by"],
	payments_to_aggregate?:ValueTypes["voyce_payments_to_creators_aggregate_order_by"],
	polls_aggregate?:ValueTypes["voyce_polls_aggregate_order_by"],
	polls_comments_aggregate?:ValueTypes["voyce_polls_comments_aggregate_order_by"],
	polls_reactions_aggregate?:ValueTypes["voyce_polls_reactions_aggregate_order_by"],
	polls_votes_aggregate?:ValueTypes["voyce_polls_votes_aggregate_order_by"],
	posts_aggregate?:ValueTypes["voyce_posts_aggregate_order_by"],
	posts_comments_aggregate?:ValueTypes["voyce_posts_comments_aggregate_order_by"],
	posts_reactions_aggregate?:ValueTypes["voyce_posts_reactions_aggregate_order_by"],
	private_fields?:ValueTypes["voyce_users_private_order_by"],
	publisher_verification_code?:ValueTypes["order_by"],
	quest_onboarded?:ValueTypes["order_by"],
	remember_code?:ValueTypes["order_by"],
	reset_code?:ValueTypes["order_by"],
	reset_code_created_at?:ValueTypes["order_by"],
	reset_otp?:ValueTypes["order_by"],
	role?:ValueTypes["voyce_user_roles_order_by"],
	role_id?:ValueTypes["order_by"],
	series_subscription_count?:ValueTypes["voyce_user_series_subscription_count_order_by"],
	signup_source?:ValueTypes["order_by"],
	social_discord?:ValueTypes["order_by"],
	social_fb?:ValueTypes["order_by"],
	social_instagram?:ValueTypes["order_by"],
	social_tiktok?:ValueTypes["order_by"],
	social_twitter?:ValueTypes["order_by"],
	sponsorshipsBySponsoredUserId_aggregate?:ValueTypes["voyce_sponsorships_aggregate_order_by"],
	sponsorships_aggregate?:ValueTypes["voyce_sponsorships_aggregate_order_by"],
	stripe_setup?:ValueTypes["voyce_stripe_payouts_setup_order_by"],
	unsubscribe?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	user_type_preference?:ValueTypes["order_by"],
	username?:ValueTypes["order_by"],
	usersFollowersByFollowingUid_aggregate?:ValueTypes["voyce_users_followers_aggregate_order_by"],
	users_followers_aggregate?:ValueTypes["voyce_users_followers_aggregate_order_by"],
	users_series_junctions_aggregate?:ValueTypes["voyce_users_series_junction_aggregate_order_by"],
	vmuser?:ValueTypes["order_by"],
	vtag?:ValueTypes["voyce_user_vtags_order_by"]
};
	/** primary key columns input for table: voyce.users */
["voyce_users_pk_columns_input"]: {
	id:number
};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["voyce_users_prepend_input"]: {
	creator_type_preferences?:ValueTypes["jsonb"],
	fcm_tokens?:ValueTypes["jsonb"]
};
	/** columns and relationships of "voyce.users_private" */
["voyce_users_private"]: AliasType<{
	about?:true,
	ad_share?:true,
	avatar?:true,
	banner?:true,
	birth_date?:true,
	dark_mode?:true,
	date_create?:true,
	email?:true,
	first_name?:true,
	gender?:true,
	id?:true,
	is_account_active?:true,
	last_login?:true,
	last_name?:true,
	nickname?:true,
	password?:true,
	remember_code?:true,
	role_id?:true,
	username?:true,
	vmuser?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.users_private" */
["voyce_users_private_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_users_private_aggregate_fields"],
	nodes?:ValueTypes["voyce_users_private"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.users_private" */
["voyce_users_private_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_users_private_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_users_private_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_users_private_max_fields"],
	min?:ValueTypes["voyce_users_private_min_fields"],
	stddev?:ValueTypes["voyce_users_private_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_users_private_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_users_private_stddev_samp_fields"],
	sum?:ValueTypes["voyce_users_private_sum_fields"],
	var_pop?:ValueTypes["voyce_users_private_var_pop_fields"],
	var_samp?:ValueTypes["voyce_users_private_var_samp_fields"],
	variance?:ValueTypes["voyce_users_private_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_users_private_avg_fields"]: AliasType<{
	ad_share?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	role_id?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.users_private". All fields are combined with a logical 'AND'. */
["voyce_users_private_bool_exp"]: {
	_and?:ValueTypes["voyce_users_private_bool_exp"][],
	_not?:ValueTypes["voyce_users_private_bool_exp"],
	_or?:ValueTypes["voyce_users_private_bool_exp"][],
	about?:ValueTypes["String_comparison_exp"],
	ad_share?:ValueTypes["Int_comparison_exp"],
	avatar?:ValueTypes["String_comparison_exp"],
	banner?:ValueTypes["String_comparison_exp"],
	birth_date?:ValueTypes["date_comparison_exp"],
	dark_mode?:ValueTypes["smallint_comparison_exp"],
	date_create?:ValueTypes["timestamp_comparison_exp"],
	email?:ValueTypes["String_comparison_exp"],
	first_name?:ValueTypes["String_comparison_exp"],
	gender?:ValueTypes["String_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	is_account_active?:ValueTypes["smallint_comparison_exp"],
	last_login?:ValueTypes["timestamp_comparison_exp"],
	last_name?:ValueTypes["String_comparison_exp"],
	nickname?:ValueTypes["String_comparison_exp"],
	password?:ValueTypes["String_comparison_exp"],
	remember_code?:ValueTypes["String_comparison_exp"],
	role_id?:ValueTypes["Int_comparison_exp"],
	username?:ValueTypes["String_comparison_exp"],
	vmuser?:ValueTypes["Boolean_comparison_exp"]
};
	/** input type for incrementing numeric columns in table "voyce.users_private" */
["voyce_users_private_inc_input"]: {
	ad_share?:number,
	dark_mode?:ValueTypes["smallint"],
	id?:number,
	is_account_active?:ValueTypes["smallint"],
	role_id?:number
};
	/** input type for inserting data into table "voyce.users_private" */
["voyce_users_private_insert_input"]: {
	about?:string,
	ad_share?:number,
	avatar?:string,
	banner?:string,
	birth_date?:ValueTypes["date"],
	dark_mode?:ValueTypes["smallint"],
	date_create?:ValueTypes["timestamp"],
	email?:string,
	first_name?:string,
	gender?:string,
	id?:number,
	is_account_active?:ValueTypes["smallint"],
	last_login?:ValueTypes["timestamp"],
	last_name?:string,
	nickname?:string,
	password?:string,
	remember_code?:string,
	role_id?:number,
	username?:string,
	vmuser?:boolean
};
	/** aggregate max on columns */
["voyce_users_private_max_fields"]: AliasType<{
	about?:true,
	ad_share?:true,
	avatar?:true,
	banner?:true,
	birth_date?:true,
	dark_mode?:true,
	date_create?:true,
	email?:true,
	first_name?:true,
	gender?:true,
	id?:true,
	is_account_active?:true,
	last_login?:true,
	last_name?:true,
	nickname?:true,
	password?:true,
	remember_code?:true,
	role_id?:true,
	username?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_users_private_min_fields"]: AliasType<{
	about?:true,
	ad_share?:true,
	avatar?:true,
	banner?:true,
	birth_date?:true,
	dark_mode?:true,
	date_create?:true,
	email?:true,
	first_name?:true,
	gender?:true,
	id?:true,
	is_account_active?:true,
	last_login?:true,
	last_name?:true,
	nickname?:true,
	password?:true,
	remember_code?:true,
	role_id?:true,
	username?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.users_private" */
["voyce_users_private_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_users_private"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.users_private" */
["voyce_users_private_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_users_private_insert_input"]
};
	/** Ordering options when selecting data from "voyce.users_private". */
["voyce_users_private_order_by"]: {
	about?:ValueTypes["order_by"],
	ad_share?:ValueTypes["order_by"],
	avatar?:ValueTypes["order_by"],
	banner?:ValueTypes["order_by"],
	birth_date?:ValueTypes["order_by"],
	dark_mode?:ValueTypes["order_by"],
	date_create?:ValueTypes["order_by"],
	email?:ValueTypes["order_by"],
	first_name?:ValueTypes["order_by"],
	gender?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	is_account_active?:ValueTypes["order_by"],
	last_login?:ValueTypes["order_by"],
	last_name?:ValueTypes["order_by"],
	nickname?:ValueTypes["order_by"],
	password?:ValueTypes["order_by"],
	remember_code?:ValueTypes["order_by"],
	role_id?:ValueTypes["order_by"],
	username?:ValueTypes["order_by"],
	vmuser?:ValueTypes["order_by"]
};
	/** select columns of table "voyce.users_private" */
["voyce_users_private_select_column"]:voyce_users_private_select_column;
	/** input type for updating data in table "voyce.users_private" */
["voyce_users_private_set_input"]: {
	about?:string,
	ad_share?:number,
	avatar?:string,
	banner?:string,
	birth_date?:ValueTypes["date"],
	dark_mode?:ValueTypes["smallint"],
	date_create?:ValueTypes["timestamp"],
	email?:string,
	first_name?:string,
	gender?:string,
	id?:number,
	is_account_active?:ValueTypes["smallint"],
	last_login?:ValueTypes["timestamp"],
	last_name?:string,
	nickname?:string,
	password?:string,
	remember_code?:string,
	role_id?:number,
	username?:string,
	vmuser?:boolean
};
	/** aggregate stddev on columns */
["voyce_users_private_stddev_fields"]: AliasType<{
	ad_share?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_users_private_stddev_pop_fields"]: AliasType<{
	ad_share?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_users_private_stddev_samp_fields"]: AliasType<{
	ad_share?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	role_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_users_private" */
["voyce_users_private_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_users_private_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_users_private_stream_cursor_value_input"]: {
	about?:string,
	ad_share?:number,
	avatar?:string,
	banner?:string,
	birth_date?:ValueTypes["date"],
	dark_mode?:ValueTypes["smallint"],
	date_create?:ValueTypes["timestamp"],
	email?:string,
	first_name?:string,
	gender?:string,
	id?:number,
	is_account_active?:ValueTypes["smallint"],
	last_login?:ValueTypes["timestamp"],
	last_name?:string,
	nickname?:string,
	password?:string,
	remember_code?:string,
	role_id?:number,
	username?:string,
	vmuser?:boolean
};
	/** aggregate sum on columns */
["voyce_users_private_sum_fields"]: AliasType<{
	ad_share?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	role_id?:true,
		__typename?: true
}>;
	["voyce_users_private_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_users_private_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_users_private_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_users_private_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_users_private_var_pop_fields"]: AliasType<{
	ad_share?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_users_private_var_samp_fields"]: AliasType<{
	ad_share?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_users_private_variance_fields"]: AliasType<{
	ad_share?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	role_id?:true,
		__typename?: true
}>;
	/** select columns of table "voyce.users" */
["voyce_users_select_column"]:voyce_users_select_column;
	/** columns and relationships of "voyce.users_series_junction" */
["voyce_users_series_junction"]: AliasType<{
	id?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.users_series_junction" */
["voyce_users_series_junction_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_users_series_junction_aggregate_fields"],
	nodes?:ValueTypes["voyce_users_series_junction"],
		__typename?: true
}>;
	["voyce_users_series_junction_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_users_series_junction_aggregate_bool_exp_count"]
};
	["voyce_users_series_junction_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_users_series_junction_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_users_series_junction_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.users_series_junction" */
["voyce_users_series_junction_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_users_series_junction_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_users_series_junction_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_users_series_junction_max_fields"],
	min?:ValueTypes["voyce_users_series_junction_min_fields"],
	stddev?:ValueTypes["voyce_users_series_junction_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_users_series_junction_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_users_series_junction_stddev_samp_fields"],
	sum?:ValueTypes["voyce_users_series_junction_sum_fields"],
	var_pop?:ValueTypes["voyce_users_series_junction_var_pop_fields"],
	var_samp?:ValueTypes["voyce_users_series_junction_var_samp_fields"],
	variance?:ValueTypes["voyce_users_series_junction_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.users_series_junction" */
["voyce_users_series_junction_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_users_series_junction_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_users_series_junction_max_order_by"],
	min?:ValueTypes["voyce_users_series_junction_min_order_by"],
	stddev?:ValueTypes["voyce_users_series_junction_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_users_series_junction_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_users_series_junction_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_users_series_junction_sum_order_by"],
	var_pop?:ValueTypes["voyce_users_series_junction_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_users_series_junction_var_samp_order_by"],
	variance?:ValueTypes["voyce_users_series_junction_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.users_series_junction" */
["voyce_users_series_junction_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_users_series_junction_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_users_series_junction_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_users_series_junction_avg_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.users_series_junction". All fields are combined with a logical 'AND'. */
["voyce_users_series_junction_bool_exp"]: {
	_and?:ValueTypes["voyce_users_series_junction_bool_exp"][],
	_not?:ValueTypes["voyce_users_series_junction_bool_exp"],
	_or?:ValueTypes["voyce_users_series_junction_bool_exp"][],
	id?:ValueTypes["Int_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.users_series_junction" */
["voyce_users_series_junction_constraint"]:voyce_users_series_junction_constraint;
	/** input type for incrementing numeric columns in table "voyce.users_series_junction" */
["voyce_users_series_junction_inc_input"]: {
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** input type for inserting data into table "voyce.users_series_junction" */
["voyce_users_series_junction_insert_input"]: {
	id?:number,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number
};
	/** aggregate max on columns */
["voyce_users_series_junction_max_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_max_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_users_series_junction_min_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_min_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.users_series_junction" */
["voyce_users_series_junction_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_users_series_junction"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.users_series_junction" */
["voyce_users_series_junction_on_conflict"]: {
	constraint:ValueTypes["voyce_users_series_junction_constraint"],
	update_columns:ValueTypes["voyce_users_series_junction_update_column"][],
	where?:ValueTypes["voyce_users_series_junction_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.users_series_junction". */
["voyce_users_series_junction_order_by"]: {
	id?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.users_series_junction */
["voyce_users_series_junction_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_select_column"]:voyce_users_series_junction_select_column;
	/** input type for updating data in table "voyce.users_series_junction" */
["voyce_users_series_junction_set_input"]: {
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev on columns */
["voyce_users_series_junction_stddev_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_users_series_junction_stddev_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_users_series_junction_stddev_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_users_series_junction" */
["voyce_users_series_junction_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_users_series_junction_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_users_series_junction_stream_cursor_value_input"]: {
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate sum on columns */
["voyce_users_series_junction_sum_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_update_column"]:voyce_users_series_junction_update_column;
	["voyce_users_series_junction_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_users_series_junction_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_users_series_junction_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_users_series_junction_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_users_series_junction_var_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_users_series_junction_var_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_users_series_junction_variance_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	user_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	series_id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"]
};
	/** input type for updating data in table "voyce.users" */
["voyce_users_set_input"]: {
	about?:string,
	account_disabled?:boolean,
	ad_share?:number,
	admin_role_id?:number,
	avatar?:string,
	avatar_bgcolor?:string,
	banner?:string,
	birth_date?:ValueTypes["date"],
	cognito_id?:string,
	cognito_provider?:string,
	cognito_username?:string,
	country_id?:number,
	creator_type_preferences?:ValueTypes["jsonb"],
	dark_mode?:ValueTypes["smallint"],
	date_create?:ValueTypes["timestamp"],
	delete_code?:string,
	email?:string,
	email_verified?:boolean,
	/** mobile or web or null */
	email_verified_source?:string,
	fcm_token?:string,
	fcm_tokens?:ValueTypes["jsonb"],
	first_name?:string,
	frame?:string,
	gender?:string,
	guest_gtag?:string,
	id?:number,
	is_account_active?:ValueTypes["smallint"],
	is_approved?:boolean,
	is_onboarded?:number,
	is_publisher_verified?:boolean,
	klaviyo_id?:string,
	last_login?:ValueTypes["timestamp"],
	last_name?:string,
	linked_account_status?:string,
	new_email_code?:string,
	newsletter_subscribe?:boolean,
	nickname?:string,
	password?:string,
	password_secure?:string,
	publisher_verification_code?:string,
	quest_onboarded?:boolean,
	remember_code?:string,
	reset_code?:string,
	reset_code_created_at?:ValueTypes["timestamptz"],
	reset_otp?:string,
	role_id?:number,
	/** mobile or web or null */
	signup_source?:string,
	social_discord?:string,
	social_fb?:string,
	social_instagram?:string,
	social_tiktok?:string,
	social_twitter?:string,
	unsubscribe?:boolean,
	updated_at?:ValueTypes["timestamptz"],
	user_type_preference?:string,
	username?:string,
	vmuser?:boolean
};
	/** aggregate stddev on columns */
["voyce_users_stddev_fields"]: AliasType<{
	ad_share?:true,
	admin_role_id?:true,
	country_id?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	is_onboarded?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_users_stddev_pop_fields"]: AliasType<{
	ad_share?:true,
	admin_role_id?:true,
	country_id?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	is_onboarded?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_users_stddev_samp_fields"]: AliasType<{
	ad_share?:true,
	admin_role_id?:true,
	country_id?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	is_onboarded?:true,
	role_id?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_users" */
["voyce_users_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_users_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_users_stream_cursor_value_input"]: {
	about?:string,
	account_disabled?:boolean,
	ad_share?:number,
	admin_role_id?:number,
	avatar?:string,
	avatar_bgcolor?:string,
	banner?:string,
	birth_date?:ValueTypes["date"],
	cognito_id?:string,
	cognito_provider?:string,
	cognito_username?:string,
	country_id?:number,
	creator_type_preferences?:ValueTypes["jsonb"],
	dark_mode?:ValueTypes["smallint"],
	date_create?:ValueTypes["timestamp"],
	delete_code?:string,
	email?:string,
	email_verified?:boolean,
	/** mobile or web or null */
	email_verified_source?:string,
	fcm_token?:string,
	fcm_tokens?:ValueTypes["jsonb"],
	first_name?:string,
	frame?:string,
	gender?:string,
	guest_gtag?:string,
	id?:number,
	is_account_active?:ValueTypes["smallint"],
	is_approved?:boolean,
	is_onboarded?:number,
	is_publisher_verified?:boolean,
	klaviyo_id?:string,
	last_login?:ValueTypes["timestamp"],
	last_name?:string,
	linked_account_status?:string,
	new_email_code?:string,
	newsletter_subscribe?:boolean,
	nickname?:string,
	password?:string,
	password_secure?:string,
	publisher_verification_code?:string,
	quest_onboarded?:boolean,
	remember_code?:string,
	reset_code?:string,
	reset_code_created_at?:ValueTypes["timestamptz"],
	reset_otp?:string,
	role_id?:number,
	/** mobile or web or null */
	signup_source?:string,
	social_discord?:string,
	social_fb?:string,
	social_instagram?:string,
	social_tiktok?:string,
	social_twitter?:string,
	unsubscribe?:boolean,
	updated_at?:ValueTypes["timestamptz"],
	user_type_preference?:string,
	username?:string,
	vmuser?:boolean
};
	/** aggregate sum on columns */
["voyce_users_sum_fields"]: AliasType<{
	ad_share?:true,
	admin_role_id?:true,
	country_id?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	is_onboarded?:true,
	role_id?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.users" */
["voyce_users_update_column"]:voyce_users_update_column;
	["voyce_users_updates"]: {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["voyce_users_append_input"],
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["voyce_users_delete_at_path_input"],
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["voyce_users_delete_elem_input"],
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["voyce_users_delete_key_input"],
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_users_inc_input"],
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["voyce_users_prepend_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_users_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_users_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_users_var_pop_fields"]: AliasType<{
	ad_share?:true,
	admin_role_id?:true,
	country_id?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	is_onboarded?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_users_var_samp_fields"]: AliasType<{
	ad_share?:true,
	admin_role_id?:true,
	country_id?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	is_onboarded?:true,
	role_id?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_users_variance_fields"]: AliasType<{
	ad_share?:true,
	admin_role_id?:true,
	country_id?:true,
	dark_mode?:true,
	id?:true,
	is_account_active?:true,
	is_onboarded?:true,
	role_id?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.versions" */
["voyce_versions"]: AliasType<{
	id?:true,
	table?:true,
	updated_at?:true,
	version?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.versions" */
["voyce_versions_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_versions_aggregate_fields"],
	nodes?:ValueTypes["voyce_versions"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.versions" */
["voyce_versions_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_versions_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_versions_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_versions_max_fields"],
	min?:ValueTypes["voyce_versions_min_fields"],
	stddev?:ValueTypes["voyce_versions_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_versions_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_versions_stddev_samp_fields"],
	sum?:ValueTypes["voyce_versions_sum_fields"],
	var_pop?:ValueTypes["voyce_versions_var_pop_fields"],
	var_samp?:ValueTypes["voyce_versions_var_samp_fields"],
	variance?:ValueTypes["voyce_versions_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_versions_avg_fields"]: AliasType<{
	id?:true,
	version?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.versions". All fields are combined with a logical 'AND'. */
["voyce_versions_bool_exp"]: {
	_and?:ValueTypes["voyce_versions_bool_exp"][],
	_not?:ValueTypes["voyce_versions_bool_exp"],
	_or?:ValueTypes["voyce_versions_bool_exp"][],
	id?:ValueTypes["Int_comparison_exp"],
	table?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	version?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.versions" */
["voyce_versions_constraint"]:voyce_versions_constraint;
	/** input type for incrementing numeric columns in table "voyce.versions" */
["voyce_versions_inc_input"]: {
	id?:number,
	version?:number
};
	/** input type for inserting data into table "voyce.versions" */
["voyce_versions_insert_input"]: {
	id?:number,
	table?:string,
	updated_at?:ValueTypes["timestamptz"],
	version?:number
};
	/** aggregate max on columns */
["voyce_versions_max_fields"]: AliasType<{
	id?:true,
	table?:true,
	updated_at?:true,
	version?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_versions_min_fields"]: AliasType<{
	id?:true,
	table?:true,
	updated_at?:true,
	version?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.versions" */
["voyce_versions_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_versions"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.versions" */
["voyce_versions_on_conflict"]: {
	constraint:ValueTypes["voyce_versions_constraint"],
	update_columns:ValueTypes["voyce_versions_update_column"][],
	where?:ValueTypes["voyce_versions_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.versions". */
["voyce_versions_order_by"]: {
	id?:ValueTypes["order_by"],
	table?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	version?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.versions */
["voyce_versions_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.versions" */
["voyce_versions_select_column"]:voyce_versions_select_column;
	/** input type for updating data in table "voyce.versions" */
["voyce_versions_set_input"]: {
	id?:number,
	table?:string,
	updated_at?:ValueTypes["timestamptz"],
	version?:number
};
	/** aggregate stddev on columns */
["voyce_versions_stddev_fields"]: AliasType<{
	id?:true,
	version?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_versions_stddev_pop_fields"]: AliasType<{
	id?:true,
	version?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_versions_stddev_samp_fields"]: AliasType<{
	id?:true,
	version?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_versions" */
["voyce_versions_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_versions_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_versions_stream_cursor_value_input"]: {
	id?:number,
	table?:string,
	updated_at?:ValueTypes["timestamptz"],
	version?:number
};
	/** aggregate sum on columns */
["voyce_versions_sum_fields"]: AliasType<{
	id?:true,
	version?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.versions" */
["voyce_versions_update_column"]:voyce_versions_update_column;
	["voyce_versions_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_versions_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_versions_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_versions_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_versions_var_pop_fields"]: AliasType<{
	id?:true,
	version?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_versions_var_samp_fields"]: AliasType<{
	id?:true,
	version?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_versions_variance_fields"]: AliasType<{
	id?:true,
	version?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.voting_analytics" */
["voyce_voting_analytics"]: AliasType<{
	created_at?:true,
	cumulative_votes?:true,
	daily_votes?:true,
	id?:true,
	meta?:true,
	updated_at?:true,
	vote_date?:true,
	/** An object relationship */
	voting_series?:ValueTypes["voyce_voting_series"],
	voting_series_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.voting_analytics" */
["voyce_voting_analytics_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_voting_analytics_aggregate_fields"],
	nodes?:ValueTypes["voyce_voting_analytics"],
		__typename?: true
}>;
	["voyce_voting_analytics_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_voting_analytics_aggregate_bool_exp_count"]
};
	["voyce_voting_analytics_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_voting_analytics_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_voting_analytics_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.voting_analytics" */
["voyce_voting_analytics_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_voting_analytics_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_voting_analytics_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_voting_analytics_max_fields"],
	min?:ValueTypes["voyce_voting_analytics_min_fields"],
	stddev?:ValueTypes["voyce_voting_analytics_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_voting_analytics_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_voting_analytics_stddev_samp_fields"],
	sum?:ValueTypes["voyce_voting_analytics_sum_fields"],
	var_pop?:ValueTypes["voyce_voting_analytics_var_pop_fields"],
	var_samp?:ValueTypes["voyce_voting_analytics_var_samp_fields"],
	variance?:ValueTypes["voyce_voting_analytics_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.voting_analytics" */
["voyce_voting_analytics_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_voting_analytics_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_voting_analytics_max_order_by"],
	min?:ValueTypes["voyce_voting_analytics_min_order_by"],
	stddev?:ValueTypes["voyce_voting_analytics_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_voting_analytics_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_voting_analytics_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_voting_analytics_sum_order_by"],
	var_pop?:ValueTypes["voyce_voting_analytics_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_voting_analytics_var_samp_order_by"],
	variance?:ValueTypes["voyce_voting_analytics_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.voting_analytics" */
["voyce_voting_analytics_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_voting_analytics_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_voting_analytics_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_voting_analytics_avg_fields"]: AliasType<{
	cumulative_votes?:true,
	daily_votes?:true,
	id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_avg_order_by"]: {
	cumulative_votes?:ValueTypes["order_by"],
	daily_votes?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.voting_analytics". All fields are combined with a logical 'AND'. */
["voyce_voting_analytics_bool_exp"]: {
	_and?:ValueTypes["voyce_voting_analytics_bool_exp"][],
	_not?:ValueTypes["voyce_voting_analytics_bool_exp"],
	_or?:ValueTypes["voyce_voting_analytics_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	cumulative_votes?:ValueTypes["Int_comparison_exp"],
	daily_votes?:ValueTypes["Int_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	meta?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	vote_date?:ValueTypes["date_comparison_exp"],
	voting_series?:ValueTypes["voyce_voting_series_bool_exp"],
	voting_series_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.voting_analytics" */
["voyce_voting_analytics_constraint"]:voyce_voting_analytics_constraint;
	/** input type for incrementing numeric columns in table "voyce.voting_analytics" */
["voyce_voting_analytics_inc_input"]: {
	cumulative_votes?:number,
	daily_votes?:number,
	id?:number,
	voting_series_id?:number
};
	/** input type for inserting data into table "voyce.voting_analytics" */
["voyce_voting_analytics_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	cumulative_votes?:number,
	daily_votes?:number,
	id?:number,
	meta?:string,
	updated_at?:ValueTypes["timestamptz"],
	vote_date?:ValueTypes["date"],
	voting_series?:ValueTypes["voyce_voting_series_obj_rel_insert_input"],
	voting_series_id?:number
};
	/** aggregate max on columns */
["voyce_voting_analytics_max_fields"]: AliasType<{
	created_at?:true,
	cumulative_votes?:true,
	daily_votes?:true,
	id?:true,
	meta?:true,
	updated_at?:true,
	vote_date?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_max_order_by"]: {
	created_at?:ValueTypes["order_by"],
	cumulative_votes?:ValueTypes["order_by"],
	daily_votes?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	meta?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	vote_date?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_voting_analytics_min_fields"]: AliasType<{
	created_at?:true,
	cumulative_votes?:true,
	daily_votes?:true,
	id?:true,
	meta?:true,
	updated_at?:true,
	vote_date?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_min_order_by"]: {
	created_at?:ValueTypes["order_by"],
	cumulative_votes?:ValueTypes["order_by"],
	daily_votes?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	meta?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	vote_date?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.voting_analytics" */
["voyce_voting_analytics_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_voting_analytics"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.voting_analytics" */
["voyce_voting_analytics_on_conflict"]: {
	constraint:ValueTypes["voyce_voting_analytics_constraint"],
	update_columns:ValueTypes["voyce_voting_analytics_update_column"][],
	where?:ValueTypes["voyce_voting_analytics_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.voting_analytics". */
["voyce_voting_analytics_order_by"]: {
	created_at?:ValueTypes["order_by"],
	cumulative_votes?:ValueTypes["order_by"],
	daily_votes?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	meta?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	vote_date?:ValueTypes["order_by"],
	voting_series?:ValueTypes["voyce_voting_series_order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.voting_analytics */
["voyce_voting_analytics_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_select_column"]:voyce_voting_analytics_select_column;
	/** input type for updating data in table "voyce.voting_analytics" */
["voyce_voting_analytics_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	cumulative_votes?:number,
	daily_votes?:number,
	id?:number,
	meta?:string,
	updated_at?:ValueTypes["timestamptz"],
	vote_date?:ValueTypes["date"],
	voting_series_id?:number
};
	/** aggregate stddev on columns */
["voyce_voting_analytics_stddev_fields"]: AliasType<{
	cumulative_votes?:true,
	daily_votes?:true,
	id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_stddev_order_by"]: {
	cumulative_votes?:ValueTypes["order_by"],
	daily_votes?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_voting_analytics_stddev_pop_fields"]: AliasType<{
	cumulative_votes?:true,
	daily_votes?:true,
	id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_stddev_pop_order_by"]: {
	cumulative_votes?:ValueTypes["order_by"],
	daily_votes?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_voting_analytics_stddev_samp_fields"]: AliasType<{
	cumulative_votes?:true,
	daily_votes?:true,
	id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_stddev_samp_order_by"]: {
	cumulative_votes?:ValueTypes["order_by"],
	daily_votes?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_voting_analytics" */
["voyce_voting_analytics_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_voting_analytics_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_voting_analytics_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	cumulative_votes?:number,
	daily_votes?:number,
	id?:number,
	meta?:string,
	updated_at?:ValueTypes["timestamptz"],
	vote_date?:ValueTypes["date"],
	voting_series_id?:number
};
	/** aggregate sum on columns */
["voyce_voting_analytics_sum_fields"]: AliasType<{
	cumulative_votes?:true,
	daily_votes?:true,
	id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_sum_order_by"]: {
	cumulative_votes?:ValueTypes["order_by"],
	daily_votes?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_update_column"]:voyce_voting_analytics_update_column;
	["voyce_voting_analytics_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_voting_analytics_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_voting_analytics_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_voting_analytics_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_voting_analytics_var_pop_fields"]: AliasType<{
	cumulative_votes?:true,
	daily_votes?:true,
	id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_var_pop_order_by"]: {
	cumulative_votes?:ValueTypes["order_by"],
	daily_votes?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_voting_analytics_var_samp_fields"]: AliasType<{
	cumulative_votes?:true,
	daily_votes?:true,
	id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_var_samp_order_by"]: {
	cumulative_votes?:ValueTypes["order_by"],
	daily_votes?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_voting_analytics_variance_fields"]: AliasType<{
	cumulative_votes?:true,
	daily_votes?:true,
	id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_variance_order_by"]: {
	cumulative_votes?:ValueTypes["order_by"],
	daily_votes?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.voting_panels" */
["voyce_voting_panels"]: AliasType<{
	created_at?:true,
	id?:true,
	panel_image?:true,
	updated_at?:true,
	/** An object relationship */
	voting_series?:ValueTypes["voyce_voting_series"],
	voting_series_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.voting_panels" */
["voyce_voting_panels_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_voting_panels_aggregate_fields"],
	nodes?:ValueTypes["voyce_voting_panels"],
		__typename?: true
}>;
	["voyce_voting_panels_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_voting_panels_aggregate_bool_exp_count"]
};
	["voyce_voting_panels_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_voting_panels_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_voting_panels_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.voting_panels" */
["voyce_voting_panels_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_voting_panels_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_voting_panels_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_voting_panels_max_fields"],
	min?:ValueTypes["voyce_voting_panels_min_fields"],
	stddev?:ValueTypes["voyce_voting_panels_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_voting_panels_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_voting_panels_stddev_samp_fields"],
	sum?:ValueTypes["voyce_voting_panels_sum_fields"],
	var_pop?:ValueTypes["voyce_voting_panels_var_pop_fields"],
	var_samp?:ValueTypes["voyce_voting_panels_var_samp_fields"],
	variance?:ValueTypes["voyce_voting_panels_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.voting_panels" */
["voyce_voting_panels_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_voting_panels_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_voting_panels_max_order_by"],
	min?:ValueTypes["voyce_voting_panels_min_order_by"],
	stddev?:ValueTypes["voyce_voting_panels_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_voting_panels_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_voting_panels_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_voting_panels_sum_order_by"],
	var_pop?:ValueTypes["voyce_voting_panels_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_voting_panels_var_samp_order_by"],
	variance?:ValueTypes["voyce_voting_panels_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.voting_panels" */
["voyce_voting_panels_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_voting_panels_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_voting_panels_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_voting_panels_avg_fields"]: AliasType<{
	id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.voting_panels". All fields are combined with a logical 'AND'. */
["voyce_voting_panels_bool_exp"]: {
	_and?:ValueTypes["voyce_voting_panels_bool_exp"][],
	_not?:ValueTypes["voyce_voting_panels_bool_exp"],
	_or?:ValueTypes["voyce_voting_panels_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	panel_image?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	voting_series?:ValueTypes["voyce_voting_series_bool_exp"],
	voting_series_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.voting_panels" */
["voyce_voting_panels_constraint"]:voyce_voting_panels_constraint;
	/** input type for incrementing numeric columns in table "voyce.voting_panels" */
["voyce_voting_panels_inc_input"]: {
	id?:number,
	voting_series_id?:number
};
	/** input type for inserting data into table "voyce.voting_panels" */
["voyce_voting_panels_insert_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	panel_image?:string,
	updated_at?:ValueTypes["timestamptz"],
	voting_series?:ValueTypes["voyce_voting_series_obj_rel_insert_input"],
	voting_series_id?:number
};
	/** aggregate max on columns */
["voyce_voting_panels_max_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	panel_image?:true,
	updated_at?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_max_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	panel_image?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_voting_panels_min_fields"]: AliasType<{
	created_at?:true,
	id?:true,
	panel_image?:true,
	updated_at?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_min_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	panel_image?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.voting_panels" */
["voyce_voting_panels_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_voting_panels"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.voting_panels" */
["voyce_voting_panels_on_conflict"]: {
	constraint:ValueTypes["voyce_voting_panels_constraint"],
	update_columns:ValueTypes["voyce_voting_panels_update_column"][],
	where?:ValueTypes["voyce_voting_panels_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.voting_panels". */
["voyce_voting_panels_order_by"]: {
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	panel_image?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	voting_series?:ValueTypes["voyce_voting_series_order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.voting_panels */
["voyce_voting_panels_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.voting_panels" */
["voyce_voting_panels_select_column"]:voyce_voting_panels_select_column;
	/** input type for updating data in table "voyce.voting_panels" */
["voyce_voting_panels_set_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	panel_image?:string,
	updated_at?:ValueTypes["timestamptz"],
	voting_series_id?:number
};
	/** aggregate stddev on columns */
["voyce_voting_panels_stddev_fields"]: AliasType<{
	id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_voting_panels_stddev_pop_fields"]: AliasType<{
	id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_voting_panels_stddev_samp_fields"]: AliasType<{
	id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_voting_panels" */
["voyce_voting_panels_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_voting_panels_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_voting_panels_stream_cursor_value_input"]: {
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	panel_image?:string,
	updated_at?:ValueTypes["timestamptz"],
	voting_series_id?:number
};
	/** aggregate sum on columns */
["voyce_voting_panels_sum_fields"]: AliasType<{
	id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.voting_panels" */
["voyce_voting_panels_update_column"]:voyce_voting_panels_update_column;
	["voyce_voting_panels_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_voting_panels_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_voting_panels_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_voting_panels_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_voting_panels_var_pop_fields"]: AliasType<{
	id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_voting_panels_var_samp_fields"]: AliasType<{
	id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_voting_panels_variance_fields"]: AliasType<{
	id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** columns and relationships of "voyce.voting_series" */
["voyce_voting_series"]: AliasType<{
	author_image?:true,
	author_name?:true,
	collaborators?:true,
	created_at?:true,
	id?:true,
	/** An object relationship */
	series?:ValueTypes["voyce_series"],
	series_id?:true,
	sort?:true,
	spotlight_caption?:true,
	spotlight_img1?:true,
	spotlight_img2?:true,
	spotlight_img3?:true,
	updated_at?:true,
voting_analytics?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_analytics_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_analytics_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_analytics_bool_exp"]},ValueTypes["voyce_voting_analytics"]],
voting_analytics_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_analytics_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_analytics_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_analytics_bool_exp"]},ValueTypes["voyce_voting_analytics_aggregate"]],
voting_panels?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_panels_bool_exp"]},ValueTypes["voyce_voting_panels"]],
voting_panels_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_panels_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_panels_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_panels_bool_exp"]},ValueTypes["voyce_voting_panels_aggregate"]],
voting_user_votes?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_user_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_user_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_user_votes_bool_exp"]},ValueTypes["voyce_voting_user_votes"]],
voting_user_votes_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["voyce_voting_user_votes_select_column"][],	/** limit the number of rows returned */
	limit?:number,	/** skip the first n rows. Use only with order_by */
	offset?:number,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["voyce_voting_user_votes_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["voyce_voting_user_votes_bool_exp"]},ValueTypes["voyce_voting_user_votes_aggregate"]],
		__typename?: true
}>;
	/** aggregated selection of "voyce.voting_series" */
["voyce_voting_series_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_voting_series_aggregate_fields"],
	nodes?:ValueTypes["voyce_voting_series"],
		__typename?: true
}>;
	/** aggregate fields of "voyce.voting_series" */
["voyce_voting_series_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_voting_series_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_voting_series_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_voting_series_max_fields"],
	min?:ValueTypes["voyce_voting_series_min_fields"],
	stddev?:ValueTypes["voyce_voting_series_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_voting_series_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_voting_series_stddev_samp_fields"],
	sum?:ValueTypes["voyce_voting_series_sum_fields"],
	var_pop?:ValueTypes["voyce_voting_series_var_pop_fields"],
	var_samp?:ValueTypes["voyce_voting_series_var_samp_fields"],
	variance?:ValueTypes["voyce_voting_series_variance_fields"],
		__typename?: true
}>;
	/** aggregate avg on columns */
["voyce_voting_series_avg_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	sort?:true,
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "voyce.voting_series". All fields are combined with a logical 'AND'. */
["voyce_voting_series_bool_exp"]: {
	_and?:ValueTypes["voyce_voting_series_bool_exp"][],
	_not?:ValueTypes["voyce_voting_series_bool_exp"],
	_or?:ValueTypes["voyce_voting_series_bool_exp"][],
	author_image?:ValueTypes["String_comparison_exp"],
	author_name?:ValueTypes["String_comparison_exp"],
	collaborators?:ValueTypes["String_comparison_exp"],
	created_at?:ValueTypes["timestamptz_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	series?:ValueTypes["voyce_series_bool_exp"],
	series_id?:ValueTypes["Int_comparison_exp"],
	sort?:ValueTypes["Int_comparison_exp"],
	spotlight_caption?:ValueTypes["String_comparison_exp"],
	spotlight_img1?:ValueTypes["String_comparison_exp"],
	spotlight_img2?:ValueTypes["String_comparison_exp"],
	spotlight_img3?:ValueTypes["String_comparison_exp"],
	updated_at?:ValueTypes["timestamptz_comparison_exp"],
	voting_analytics?:ValueTypes["voyce_voting_analytics_bool_exp"],
	voting_analytics_aggregate?:ValueTypes["voyce_voting_analytics_aggregate_bool_exp"],
	voting_panels?:ValueTypes["voyce_voting_panels_bool_exp"],
	voting_panels_aggregate?:ValueTypes["voyce_voting_panels_aggregate_bool_exp"],
	voting_user_votes?:ValueTypes["voyce_voting_user_votes_bool_exp"],
	voting_user_votes_aggregate?:ValueTypes["voyce_voting_user_votes_aggregate_bool_exp"]
};
	/** unique or primary key constraints on table "voyce.voting_series" */
["voyce_voting_series_constraint"]:voyce_voting_series_constraint;
	/** input type for incrementing numeric columns in table "voyce.voting_series" */
["voyce_voting_series_inc_input"]: {
	id?:number,
	series_id?:number,
	sort?:number
};
	/** input type for inserting data into table "voyce.voting_series" */
["voyce_voting_series_insert_input"]: {
	author_image?:string,
	author_name?:string,
	collaborators?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	series?:ValueTypes["voyce_series_obj_rel_insert_input"],
	series_id?:number,
	sort?:number,
	spotlight_caption?:string,
	spotlight_img1?:string,
	spotlight_img2?:string,
	spotlight_img3?:string,
	updated_at?:ValueTypes["timestamptz"],
	voting_analytics?:ValueTypes["voyce_voting_analytics_arr_rel_insert_input"],
	voting_panels?:ValueTypes["voyce_voting_panels_arr_rel_insert_input"],
	voting_user_votes?:ValueTypes["voyce_voting_user_votes_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["voyce_voting_series_max_fields"]: AliasType<{
	author_image?:true,
	author_name?:true,
	collaborators?:true,
	created_at?:true,
	id?:true,
	series_id?:true,
	sort?:true,
	spotlight_caption?:true,
	spotlight_img1?:true,
	spotlight_img2?:true,
	spotlight_img3?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["voyce_voting_series_min_fields"]: AliasType<{
	author_image?:true,
	author_name?:true,
	collaborators?:true,
	created_at?:true,
	id?:true,
	series_id?:true,
	sort?:true,
	spotlight_caption?:true,
	spotlight_img1?:true,
	spotlight_img2?:true,
	spotlight_img3?:true,
	updated_at?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "voyce.voting_series" */
["voyce_voting_series_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_voting_series"],
		__typename?: true
}>;
	/** input type for inserting object relation for remote table "voyce.voting_series" */
["voyce_voting_series_obj_rel_insert_input"]: {
	data:ValueTypes["voyce_voting_series_insert_input"],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_voting_series_on_conflict"]
};
	/** on_conflict condition type for table "voyce.voting_series" */
["voyce_voting_series_on_conflict"]: {
	constraint:ValueTypes["voyce_voting_series_constraint"],
	update_columns:ValueTypes["voyce_voting_series_update_column"][],
	where?:ValueTypes["voyce_voting_series_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.voting_series". */
["voyce_voting_series_order_by"]: {
	author_image?:ValueTypes["order_by"],
	author_name?:ValueTypes["order_by"],
	collaborators?:ValueTypes["order_by"],
	created_at?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	series?:ValueTypes["voyce_series_order_by"],
	series_id?:ValueTypes["order_by"],
	sort?:ValueTypes["order_by"],
	spotlight_caption?:ValueTypes["order_by"],
	spotlight_img1?:ValueTypes["order_by"],
	spotlight_img2?:ValueTypes["order_by"],
	spotlight_img3?:ValueTypes["order_by"],
	updated_at?:ValueTypes["order_by"],
	voting_analytics_aggregate?:ValueTypes["voyce_voting_analytics_aggregate_order_by"],
	voting_panels_aggregate?:ValueTypes["voyce_voting_panels_aggregate_order_by"],
	voting_user_votes_aggregate?:ValueTypes["voyce_voting_user_votes_aggregate_order_by"]
};
	/** primary key columns input for table: voyce.voting_series */
["voyce_voting_series_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.voting_series" */
["voyce_voting_series_select_column"]:voyce_voting_series_select_column;
	/** input type for updating data in table "voyce.voting_series" */
["voyce_voting_series_set_input"]: {
	author_image?:string,
	author_name?:string,
	collaborators?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	series_id?:number,
	sort?:number,
	spotlight_caption?:string,
	spotlight_img1?:string,
	spotlight_img2?:string,
	spotlight_img3?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_voting_series_stddev_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate stddev_pop on columns */
["voyce_voting_series_stddev_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate stddev_samp on columns */
["voyce_voting_series_stddev_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	sort?:true,
		__typename?: true
}>;
	/** Streaming cursor of the table "voyce_voting_series" */
["voyce_voting_series_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_voting_series_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_voting_series_stream_cursor_value_input"]: {
	author_image?:string,
	author_name?:string,
	collaborators?:string,
	created_at?:ValueTypes["timestamptz"],
	id?:number,
	series_id?:number,
	sort?:number,
	spotlight_caption?:string,
	spotlight_img1?:string,
	spotlight_img2?:string,
	spotlight_img3?:string,
	updated_at?:ValueTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_voting_series_sum_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	sort?:true,
		__typename?: true
}>;
	/** update columns of table "voyce.voting_series" */
["voyce_voting_series_update_column"]:voyce_voting_series_update_column;
	["voyce_voting_series_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_voting_series_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_voting_series_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_voting_series_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_voting_series_var_pop_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate var_samp on columns */
["voyce_voting_series_var_samp_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	sort?:true,
		__typename?: true
}>;
	/** aggregate variance on columns */
["voyce_voting_series_variance_fields"]: AliasType<{
	id?:true,
	series_id?:true,
	sort?:true,
		__typename?: true
}>;
	/** columns and relationships of "voyce.voting_user_votes" */
["voyce_voting_user_votes"]: AliasType<{
	date_voted?:true,
	id?:true,
	time_voted?:true,
	/** An object relationship */
	user?:ValueTypes["voyce_users"],
	user_id?:true,
	/** An object relationship */
	voting_series?:ValueTypes["voyce_voting_series"],
	voting_series_id?:true,
		__typename?: true
}>;
	/** aggregated selection of "voyce.voting_user_votes" */
["voyce_voting_user_votes_aggregate"]: AliasType<{
	aggregate?:ValueTypes["voyce_voting_user_votes_aggregate_fields"],
	nodes?:ValueTypes["voyce_voting_user_votes"],
		__typename?: true
}>;
	["voyce_voting_user_votes_aggregate_bool_exp"]: {
	count?:ValueTypes["voyce_voting_user_votes_aggregate_bool_exp_count"]
};
	["voyce_voting_user_votes_aggregate_bool_exp_count"]: {
	arguments?:ValueTypes["voyce_voting_user_votes_select_column"][],
	distinct?:boolean,
	filter?:ValueTypes["voyce_voting_user_votes_bool_exp"],
	predicate:ValueTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.voting_user_votes" */
["voyce_voting_user_votes_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["voyce_voting_user_votes_avg_fields"],
count?: [{	columns?:ValueTypes["voyce_voting_user_votes_select_column"][],	distinct?:boolean},true],
	max?:ValueTypes["voyce_voting_user_votes_max_fields"],
	min?:ValueTypes["voyce_voting_user_votes_min_fields"],
	stddev?:ValueTypes["voyce_voting_user_votes_stddev_fields"],
	stddev_pop?:ValueTypes["voyce_voting_user_votes_stddev_pop_fields"],
	stddev_samp?:ValueTypes["voyce_voting_user_votes_stddev_samp_fields"],
	sum?:ValueTypes["voyce_voting_user_votes_sum_fields"],
	var_pop?:ValueTypes["voyce_voting_user_votes_var_pop_fields"],
	var_samp?:ValueTypes["voyce_voting_user_votes_var_samp_fields"],
	variance?:ValueTypes["voyce_voting_user_votes_variance_fields"],
		__typename?: true
}>;
	/** order by aggregate values of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_aggregate_order_by"]: {
	avg?:ValueTypes["voyce_voting_user_votes_avg_order_by"],
	count?:ValueTypes["order_by"],
	max?:ValueTypes["voyce_voting_user_votes_max_order_by"],
	min?:ValueTypes["voyce_voting_user_votes_min_order_by"],
	stddev?:ValueTypes["voyce_voting_user_votes_stddev_order_by"],
	stddev_pop?:ValueTypes["voyce_voting_user_votes_stddev_pop_order_by"],
	stddev_samp?:ValueTypes["voyce_voting_user_votes_stddev_samp_order_by"],
	sum?:ValueTypes["voyce_voting_user_votes_sum_order_by"],
	var_pop?:ValueTypes["voyce_voting_user_votes_var_pop_order_by"],
	var_samp?:ValueTypes["voyce_voting_user_votes_var_samp_order_by"],
	variance?:ValueTypes["voyce_voting_user_votes_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.voting_user_votes" */
["voyce_voting_user_votes_arr_rel_insert_input"]: {
	data:ValueTypes["voyce_voting_user_votes_insert_input"][],
	/** upsert condition */
	on_conflict?:ValueTypes["voyce_voting_user_votes_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_voting_user_votes_avg_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by avg() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_avg_order_by"]: {
	id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.voting_user_votes". All fields are combined with a logical 'AND'. */
["voyce_voting_user_votes_bool_exp"]: {
	_and?:ValueTypes["voyce_voting_user_votes_bool_exp"][],
	_not?:ValueTypes["voyce_voting_user_votes_bool_exp"],
	_or?:ValueTypes["voyce_voting_user_votes_bool_exp"][],
	date_voted?:ValueTypes["date_comparison_exp"],
	id?:ValueTypes["Int_comparison_exp"],
	time_voted?:ValueTypes["timestamptz_comparison_exp"],
	user?:ValueTypes["voyce_users_bool_exp"],
	user_id?:ValueTypes["Int_comparison_exp"],
	voting_series?:ValueTypes["voyce_voting_series_bool_exp"],
	voting_series_id?:ValueTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.voting_user_votes" */
["voyce_voting_user_votes_constraint"]:voyce_voting_user_votes_constraint;
	/** input type for incrementing numeric columns in table "voyce.voting_user_votes" */
["voyce_voting_user_votes_inc_input"]: {
	id?:number,
	user_id?:number,
	voting_series_id?:number
};
	/** input type for inserting data into table "voyce.voting_user_votes" */
["voyce_voting_user_votes_insert_input"]: {
	date_voted?:ValueTypes["date"],
	id?:number,
	time_voted?:ValueTypes["timestamptz"],
	user?:ValueTypes["voyce_users_obj_rel_insert_input"],
	user_id?:number,
	voting_series?:ValueTypes["voyce_voting_series_obj_rel_insert_input"],
	voting_series_id?:number
};
	/** aggregate max on columns */
["voyce_voting_user_votes_max_fields"]: AliasType<{
	date_voted?:true,
	id?:true,
	time_voted?:true,
	user_id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by max() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_max_order_by"]: {
	date_voted?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	time_voted?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_voting_user_votes_min_fields"]: AliasType<{
	date_voted?:true,
	id?:true,
	time_voted?:true,
	user_id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by min() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_min_order_by"]: {
	date_voted?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	time_voted?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** response of any mutation on the table "voyce.voting_user_votes" */
["voyce_voting_user_votes_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["voyce_voting_user_votes"],
		__typename?: true
}>;
	/** on_conflict condition type for table "voyce.voting_user_votes" */
["voyce_voting_user_votes_on_conflict"]: {
	constraint:ValueTypes["voyce_voting_user_votes_constraint"],
	update_columns:ValueTypes["voyce_voting_user_votes_update_column"][],
	where?:ValueTypes["voyce_voting_user_votes_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.voting_user_votes". */
["voyce_voting_user_votes_order_by"]: {
	date_voted?:ValueTypes["order_by"],
	id?:ValueTypes["order_by"],
	time_voted?:ValueTypes["order_by"],
	user?:ValueTypes["voyce_users_order_by"],
	user_id?:ValueTypes["order_by"],
	voting_series?:ValueTypes["voyce_voting_series_order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** primary key columns input for table: voyce.voting_user_votes */
["voyce_voting_user_votes_pk_columns_input"]: {
	id:number
};
	/** select columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_select_column"]:voyce_voting_user_votes_select_column;
	/** input type for updating data in table "voyce.voting_user_votes" */
["voyce_voting_user_votes_set_input"]: {
	date_voted?:ValueTypes["date"],
	id?:number,
	time_voted?:ValueTypes["timestamptz"],
	user_id?:number,
	voting_series_id?:number
};
	/** aggregate stddev on columns */
["voyce_voting_user_votes_stddev_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by stddev() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_stddev_order_by"]: {
	id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_voting_user_votes_stddev_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by stddev_pop() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_stddev_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_voting_user_votes_stddev_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by stddev_samp() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_stddev_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_voting_user_votes" */
["voyce_voting_user_votes_stream_cursor_input"]: {
	/** Stream column input with initial value */
	initial_value:ValueTypes["voyce_voting_user_votes_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?:ValueTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_voting_user_votes_stream_cursor_value_input"]: {
	date_voted?:ValueTypes["date"],
	id?:number,
	time_voted?:ValueTypes["timestamptz"],
	user_id?:number,
	voting_series_id?:number
};
	/** aggregate sum on columns */
["voyce_voting_user_votes_sum_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by sum() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_sum_order_by"]: {
	id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** update columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_update_column"]:voyce_voting_user_votes_update_column;
	["voyce_voting_user_votes_updates"]: {
	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["voyce_voting_user_votes_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["voyce_voting_user_votes_set_input"],
	/** filter the rows which have to be updated */
	where:ValueTypes["voyce_voting_user_votes_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_voting_user_votes_var_pop_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by var_pop() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_var_pop_order_by"]: {
	id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_voting_user_votes_var_samp_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by var_samp() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_var_samp_order_by"]: {
	id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_voting_user_votes_variance_fields"]: AliasType<{
	id?:true,
	user_id?:true,
	voting_series_id?:true,
		__typename?: true
}>;
	/** order by variance() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_variance_order_by"]: {
	id?:ValueTypes["order_by"],
	user_id?:ValueTypes["order_by"],
	voting_series_id?:ValueTypes["order_by"]
}
  }

export type ModelTypes = {
    ["AddResult"]: {
		sum?:number
};
	/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
["Boolean_comparison_exp"]: GraphQLTypes["Boolean_comparison_exp"];
	/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
["Int_comparison_exp"]: GraphQLTypes["Int_comparison_exp"];
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
["String_comparison_exp"]: GraphQLTypes["String_comparison_exp"];
	/** columns and relationships of "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1"]: {
		DETAILS:string,
	FAILURE_TIME:ModelTypes["timestamp"],
	FAILURE_TYPE:string,
	KEY:string,
	KEY_TYPE:string,
	TABLE_NAME:string,
	TABLE_OWNER:string,
	TASK_NAME:string
};
	/** aggregated selection of "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_aggregate"]: {
		aggregate?:ModelTypes["awsdms_validation_failures_v1_aggregate_fields"],
	nodes:ModelTypes["awsdms_validation_failures_v1"][]
};
	/** aggregate fields of "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["awsdms_validation_failures_v1_max_fields"],
	min?:ModelTypes["awsdms_validation_failures_v1_min_fields"]
};
	/** Boolean expression to filter rows from the table "awsdms_validation_failures_v1". All fields are combined with a logical 'AND'. */
["awsdms_validation_failures_v1_bool_exp"]: GraphQLTypes["awsdms_validation_failures_v1_bool_exp"];
	/** input type for inserting data into table "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_insert_input"]: GraphQLTypes["awsdms_validation_failures_v1_insert_input"];
	/** aggregate max on columns */
["awsdms_validation_failures_v1_max_fields"]: {
		DETAILS?:string,
	FAILURE_TIME?:ModelTypes["timestamp"],
	FAILURE_TYPE?:string,
	KEY?:string,
	KEY_TYPE?:string,
	TABLE_NAME?:string,
	TABLE_OWNER?:string,
	TASK_NAME?:string
};
	/** aggregate min on columns */
["awsdms_validation_failures_v1_min_fields"]: {
		DETAILS?:string,
	FAILURE_TIME?:ModelTypes["timestamp"],
	FAILURE_TYPE?:string,
	KEY?:string,
	KEY_TYPE?:string,
	TABLE_NAME?:string,
	TABLE_OWNER?:string,
	TASK_NAME?:string
};
	/** response of any mutation on the table "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["awsdms_validation_failures_v1"][]
};
	/** Ordering options when selecting data from "awsdms_validation_failures_v1". */
["awsdms_validation_failures_v1_order_by"]: GraphQLTypes["awsdms_validation_failures_v1_order_by"];
	/** select columns of table "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_select_column"]: GraphQLTypes["awsdms_validation_failures_v1_select_column"];
	/** input type for updating data in table "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_set_input"]: GraphQLTypes["awsdms_validation_failures_v1_set_input"];
	/** Streaming cursor of the table "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_stream_cursor_input"]: GraphQLTypes["awsdms_validation_failures_v1_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["awsdms_validation_failures_v1_stream_cursor_value_input"]: GraphQLTypes["awsdms_validation_failures_v1_stream_cursor_value_input"];
	["awsdms_validation_failures_v1_updates"]: GraphQLTypes["awsdms_validation_failures_v1_updates"];
	["bigint"]:any;
	/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
["bigint_comparison_exp"]: GraphQLTypes["bigint_comparison_exp"];
	["bit"]:any;
	/** Boolean expression to compare columns of type "bit". All fields are combined with logical 'AND'. */
["bit_comparison_exp"]: GraphQLTypes["bit_comparison_exp"];
	["bpchar"]:any;
	/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
["bpchar_comparison_exp"]: GraphQLTypes["bpchar_comparison_exp"];
	["bytea"]:any;
	/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
["bytea_comparison_exp"]: GraphQLTypes["bytea_comparison_exp"];
	/** columns and relationships of "coinsystem.balances" */
["coinsystem_balances"]: {
		balance:ModelTypes["numeric"],
	/** An object relationship */
	coin:ModelTypes["coinsystem_coins"],
	coin_id:number,
	created_at:ModelTypes["timestamptz"],
	id:number,
	updated_at:ModelTypes["timestamptz"],
	/** An object relationship */
	user?:ModelTypes["voyce_users"],
	user_id?:number,
	/** An object relationship */
	wallet:ModelTypes["coinsystem_wallets"],
	wallet_id:number
};
	/** aggregated selection of "coinsystem.balances" */
["coinsystem_balances_aggregate"]: {
		aggregate?:ModelTypes["coinsystem_balances_aggregate_fields"],
	nodes:ModelTypes["coinsystem_balances"][]
};
	/** aggregate fields of "coinsystem.balances" */
["coinsystem_balances_aggregate_fields"]: {
		avg?:ModelTypes["coinsystem_balances_avg_fields"],
	count:number,
	max?:ModelTypes["coinsystem_balances_max_fields"],
	min?:ModelTypes["coinsystem_balances_min_fields"],
	stddev?:ModelTypes["coinsystem_balances_stddev_fields"],
	stddev_pop?:ModelTypes["coinsystem_balances_stddev_pop_fields"],
	stddev_samp?:ModelTypes["coinsystem_balances_stddev_samp_fields"],
	sum?:ModelTypes["coinsystem_balances_sum_fields"],
	var_pop?:ModelTypes["coinsystem_balances_var_pop_fields"],
	var_samp?:ModelTypes["coinsystem_balances_var_samp_fields"],
	variance?:ModelTypes["coinsystem_balances_variance_fields"]
};
	/** aggregate avg on columns */
["coinsystem_balances_avg_fields"]: {
		balance?:number,
	coin_id?:number,
	id?:number,
	user_id?:number,
	wallet_id?:number
};
	/** Boolean expression to filter rows from the table "coinsystem.balances". All fields are combined with a logical 'AND'. */
["coinsystem_balances_bool_exp"]: GraphQLTypes["coinsystem_balances_bool_exp"];
	/** unique or primary key constraints on table "coinsystem.balances" */
["coinsystem_balances_constraint"]: GraphQLTypes["coinsystem_balances_constraint"];
	/** input type for incrementing numeric columns in table "coinsystem.balances" */
["coinsystem_balances_inc_input"]: GraphQLTypes["coinsystem_balances_inc_input"];
	/** input type for inserting data into table "coinsystem.balances" */
["coinsystem_balances_insert_input"]: GraphQLTypes["coinsystem_balances_insert_input"];
	/** aggregate max on columns */
["coinsystem_balances_max_fields"]: {
		balance?:ModelTypes["numeric"],
	coin_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number,
	wallet_id?:number
};
	/** aggregate min on columns */
["coinsystem_balances_min_fields"]: {
		balance?:ModelTypes["numeric"],
	coin_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number,
	wallet_id?:number
};
	/** response of any mutation on the table "coinsystem.balances" */
["coinsystem_balances_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["coinsystem_balances"][]
};
	/** on_conflict condition type for table "coinsystem.balances" */
["coinsystem_balances_on_conflict"]: GraphQLTypes["coinsystem_balances_on_conflict"];
	/** Ordering options when selecting data from "coinsystem.balances". */
["coinsystem_balances_order_by"]: GraphQLTypes["coinsystem_balances_order_by"];
	/** primary key columns input for table: coinsystem.balances */
["coinsystem_balances_pk_columns_input"]: GraphQLTypes["coinsystem_balances_pk_columns_input"];
	/** select columns of table "coinsystem.balances" */
["coinsystem_balances_select_column"]: GraphQLTypes["coinsystem_balances_select_column"];
	/** input type for updating data in table "coinsystem.balances" */
["coinsystem_balances_set_input"]: GraphQLTypes["coinsystem_balances_set_input"];
	/** aggregate stddev on columns */
["coinsystem_balances_stddev_fields"]: {
		balance?:number,
	coin_id?:number,
	id?:number,
	user_id?:number,
	wallet_id?:number
};
	/** aggregate stddev_pop on columns */
["coinsystem_balances_stddev_pop_fields"]: {
		balance?:number,
	coin_id?:number,
	id?:number,
	user_id?:number,
	wallet_id?:number
};
	/** aggregate stddev_samp on columns */
["coinsystem_balances_stddev_samp_fields"]: {
		balance?:number,
	coin_id?:number,
	id?:number,
	user_id?:number,
	wallet_id?:number
};
	/** Streaming cursor of the table "coinsystem_balances" */
["coinsystem_balances_stream_cursor_input"]: GraphQLTypes["coinsystem_balances_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["coinsystem_balances_stream_cursor_value_input"]: GraphQLTypes["coinsystem_balances_stream_cursor_value_input"];
	/** aggregate sum on columns */
["coinsystem_balances_sum_fields"]: {
		balance?:ModelTypes["numeric"],
	coin_id?:number,
	id?:number,
	user_id?:number,
	wallet_id?:number
};
	/** update columns of table "coinsystem.balances" */
["coinsystem_balances_update_column"]: GraphQLTypes["coinsystem_balances_update_column"];
	["coinsystem_balances_updates"]: GraphQLTypes["coinsystem_balances_updates"];
	/** aggregate var_pop on columns */
["coinsystem_balances_var_pop_fields"]: {
		balance?:number,
	coin_id?:number,
	id?:number,
	user_id?:number,
	wallet_id?:number
};
	/** aggregate var_samp on columns */
["coinsystem_balances_var_samp_fields"]: {
		balance?:number,
	coin_id?:number,
	id?:number,
	user_id?:number,
	wallet_id?:number
};
	/** aggregate variance on columns */
["coinsystem_balances_variance_fields"]: {
		balance?:number,
	coin_id?:number,
	id?:number,
	user_id?:number,
	wallet_id?:number
};
	/** columns and relationships of "coinsystem.bonus_coins" */
["coinsystem_bonus_coins"]: {
		active:boolean,
	bonus_amount:ModelTypes["numeric"],
	coin_id:number,
	created_at:ModelTypes["timestamptz"],
	id:number,
	max_amount:ModelTypes["numeric"],
	min_amount:ModelTypes["numeric"]
};
	/** aggregated selection of "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_aggregate"]: {
		aggregate?:ModelTypes["coinsystem_bonus_coins_aggregate_fields"],
	nodes:ModelTypes["coinsystem_bonus_coins"][]
};
	/** aggregate fields of "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_aggregate_fields"]: {
		avg?:ModelTypes["coinsystem_bonus_coins_avg_fields"],
	count:number,
	max?:ModelTypes["coinsystem_bonus_coins_max_fields"],
	min?:ModelTypes["coinsystem_bonus_coins_min_fields"],
	stddev?:ModelTypes["coinsystem_bonus_coins_stddev_fields"],
	stddev_pop?:ModelTypes["coinsystem_bonus_coins_stddev_pop_fields"],
	stddev_samp?:ModelTypes["coinsystem_bonus_coins_stddev_samp_fields"],
	sum?:ModelTypes["coinsystem_bonus_coins_sum_fields"],
	var_pop?:ModelTypes["coinsystem_bonus_coins_var_pop_fields"],
	var_samp?:ModelTypes["coinsystem_bonus_coins_var_samp_fields"],
	variance?:ModelTypes["coinsystem_bonus_coins_variance_fields"]
};
	/** aggregate avg on columns */
["coinsystem_bonus_coins_avg_fields"]: {
		bonus_amount?:number,
	coin_id?:number,
	id?:number,
	max_amount?:number,
	min_amount?:number
};
	/** Boolean expression to filter rows from the table "coinsystem.bonus_coins". All fields are combined with a logical 'AND'. */
["coinsystem_bonus_coins_bool_exp"]: GraphQLTypes["coinsystem_bonus_coins_bool_exp"];
	/** unique or primary key constraints on table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_constraint"]: GraphQLTypes["coinsystem_bonus_coins_constraint"];
	/** input type for incrementing numeric columns in table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_inc_input"]: GraphQLTypes["coinsystem_bonus_coins_inc_input"];
	/** input type for inserting data into table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_insert_input"]: GraphQLTypes["coinsystem_bonus_coins_insert_input"];
	/** aggregate max on columns */
["coinsystem_bonus_coins_max_fields"]: {
		bonus_amount?:ModelTypes["numeric"],
	coin_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	max_amount?:ModelTypes["numeric"],
	min_amount?:ModelTypes["numeric"]
};
	/** aggregate min on columns */
["coinsystem_bonus_coins_min_fields"]: {
		bonus_amount?:ModelTypes["numeric"],
	coin_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	max_amount?:ModelTypes["numeric"],
	min_amount?:ModelTypes["numeric"]
};
	/** response of any mutation on the table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["coinsystem_bonus_coins"][]
};
	/** on_conflict condition type for table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_on_conflict"]: GraphQLTypes["coinsystem_bonus_coins_on_conflict"];
	/** Ordering options when selecting data from "coinsystem.bonus_coins". */
["coinsystem_bonus_coins_order_by"]: GraphQLTypes["coinsystem_bonus_coins_order_by"];
	/** primary key columns input for table: coinsystem.bonus_coins */
["coinsystem_bonus_coins_pk_columns_input"]: GraphQLTypes["coinsystem_bonus_coins_pk_columns_input"];
	/** select columns of table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_select_column"]: GraphQLTypes["coinsystem_bonus_coins_select_column"];
	/** input type for updating data in table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_set_input"]: GraphQLTypes["coinsystem_bonus_coins_set_input"];
	/** aggregate stddev on columns */
["coinsystem_bonus_coins_stddev_fields"]: {
		bonus_amount?:number,
	coin_id?:number,
	id?:number,
	max_amount?:number,
	min_amount?:number
};
	/** aggregate stddev_pop on columns */
["coinsystem_bonus_coins_stddev_pop_fields"]: {
		bonus_amount?:number,
	coin_id?:number,
	id?:number,
	max_amount?:number,
	min_amount?:number
};
	/** aggregate stddev_samp on columns */
["coinsystem_bonus_coins_stddev_samp_fields"]: {
		bonus_amount?:number,
	coin_id?:number,
	id?:number,
	max_amount?:number,
	min_amount?:number
};
	/** Streaming cursor of the table "coinsystem_bonus_coins" */
["coinsystem_bonus_coins_stream_cursor_input"]: GraphQLTypes["coinsystem_bonus_coins_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["coinsystem_bonus_coins_stream_cursor_value_input"]: GraphQLTypes["coinsystem_bonus_coins_stream_cursor_value_input"];
	/** aggregate sum on columns */
["coinsystem_bonus_coins_sum_fields"]: {
		bonus_amount?:ModelTypes["numeric"],
	coin_id?:number,
	id?:number,
	max_amount?:ModelTypes["numeric"],
	min_amount?:ModelTypes["numeric"]
};
	/** update columns of table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_update_column"]: GraphQLTypes["coinsystem_bonus_coins_update_column"];
	["coinsystem_bonus_coins_updates"]: GraphQLTypes["coinsystem_bonus_coins_updates"];
	/** aggregate var_pop on columns */
["coinsystem_bonus_coins_var_pop_fields"]: {
		bonus_amount?:number,
	coin_id?:number,
	id?:number,
	max_amount?:number,
	min_amount?:number
};
	/** aggregate var_samp on columns */
["coinsystem_bonus_coins_var_samp_fields"]: {
		bonus_amount?:number,
	coin_id?:number,
	id?:number,
	max_amount?:number,
	min_amount?:number
};
	/** aggregate variance on columns */
["coinsystem_bonus_coins_variance_fields"]: {
		bonus_amount?:number,
	coin_id?:number,
	id?:number,
	max_amount?:number,
	min_amount?:number
};
	/** columns and relationships of "coinsystem.coins" */
["coinsystem_coins"]: {
		can_mint:boolean,
	coin_decimal:ModelTypes["smallint"],
	coin_logo:string,
	coin_name:string,
	coin_symbol:string,
	created_at:ModelTypes["timestamptz"],
	default:boolean,
	id:number,
	in_app:boolean,
	is_withdrawable:boolean,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "coinsystem.coins" */
["coinsystem_coins_aggregate"]: {
		aggregate?:ModelTypes["coinsystem_coins_aggregate_fields"],
	nodes:ModelTypes["coinsystem_coins"][]
};
	/** aggregate fields of "coinsystem.coins" */
["coinsystem_coins_aggregate_fields"]: {
		avg?:ModelTypes["coinsystem_coins_avg_fields"],
	count:number,
	max?:ModelTypes["coinsystem_coins_max_fields"],
	min?:ModelTypes["coinsystem_coins_min_fields"],
	stddev?:ModelTypes["coinsystem_coins_stddev_fields"],
	stddev_pop?:ModelTypes["coinsystem_coins_stddev_pop_fields"],
	stddev_samp?:ModelTypes["coinsystem_coins_stddev_samp_fields"],
	sum?:ModelTypes["coinsystem_coins_sum_fields"],
	var_pop?:ModelTypes["coinsystem_coins_var_pop_fields"],
	var_samp?:ModelTypes["coinsystem_coins_var_samp_fields"],
	variance?:ModelTypes["coinsystem_coins_variance_fields"]
};
	/** aggregate avg on columns */
["coinsystem_coins_avg_fields"]: {
		coin_decimal?:number,
	id?:number
};
	/** Boolean expression to filter rows from the table "coinsystem.coins". All fields are combined with a logical 'AND'. */
["coinsystem_coins_bool_exp"]: GraphQLTypes["coinsystem_coins_bool_exp"];
	/** unique or primary key constraints on table "coinsystem.coins" */
["coinsystem_coins_constraint"]: GraphQLTypes["coinsystem_coins_constraint"];
	/** input type for incrementing numeric columns in table "coinsystem.coins" */
["coinsystem_coins_inc_input"]: GraphQLTypes["coinsystem_coins_inc_input"];
	/** input type for inserting data into table "coinsystem.coins" */
["coinsystem_coins_insert_input"]: GraphQLTypes["coinsystem_coins_insert_input"];
	/** aggregate max on columns */
["coinsystem_coins_max_fields"]: {
		coin_decimal?:ModelTypes["smallint"],
	coin_logo?:string,
	coin_name?:string,
	coin_symbol?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["coinsystem_coins_min_fields"]: {
		coin_decimal?:ModelTypes["smallint"],
	coin_logo?:string,
	coin_name?:string,
	coin_symbol?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "coinsystem.coins" */
["coinsystem_coins_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["coinsystem_coins"][]
};
	/** input type for inserting object relation for remote table "coinsystem.coins" */
["coinsystem_coins_obj_rel_insert_input"]: GraphQLTypes["coinsystem_coins_obj_rel_insert_input"];
	/** on_conflict condition type for table "coinsystem.coins" */
["coinsystem_coins_on_conflict"]: GraphQLTypes["coinsystem_coins_on_conflict"];
	/** Ordering options when selecting data from "coinsystem.coins". */
["coinsystem_coins_order_by"]: GraphQLTypes["coinsystem_coins_order_by"];
	/** primary key columns input for table: coinsystem.coins */
["coinsystem_coins_pk_columns_input"]: GraphQLTypes["coinsystem_coins_pk_columns_input"];
	/** select columns of table "coinsystem.coins" */
["coinsystem_coins_select_column"]: GraphQLTypes["coinsystem_coins_select_column"];
	/** input type for updating data in table "coinsystem.coins" */
["coinsystem_coins_set_input"]: GraphQLTypes["coinsystem_coins_set_input"];
	/** aggregate stddev on columns */
["coinsystem_coins_stddev_fields"]: {
		coin_decimal?:number,
	id?:number
};
	/** aggregate stddev_pop on columns */
["coinsystem_coins_stddev_pop_fields"]: {
		coin_decimal?:number,
	id?:number
};
	/** aggregate stddev_samp on columns */
["coinsystem_coins_stddev_samp_fields"]: {
		coin_decimal?:number,
	id?:number
};
	/** Streaming cursor of the table "coinsystem_coins" */
["coinsystem_coins_stream_cursor_input"]: GraphQLTypes["coinsystem_coins_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["coinsystem_coins_stream_cursor_value_input"]: GraphQLTypes["coinsystem_coins_stream_cursor_value_input"];
	/** aggregate sum on columns */
["coinsystem_coins_sum_fields"]: {
		coin_decimal?:ModelTypes["smallint"],
	id?:number
};
	/** update columns of table "coinsystem.coins" */
["coinsystem_coins_update_column"]: GraphQLTypes["coinsystem_coins_update_column"];
	["coinsystem_coins_updates"]: GraphQLTypes["coinsystem_coins_updates"];
	/** aggregate var_pop on columns */
["coinsystem_coins_var_pop_fields"]: {
		coin_decimal?:number,
	id?:number
};
	/** aggregate var_samp on columns */
["coinsystem_coins_var_samp_fields"]: {
		coin_decimal?:number,
	id?:number
};
	/** aggregate variance on columns */
["coinsystem_coins_variance_fields"]: {
		coin_decimal?:number,
	id?:number
};
	/** columns and relationships of "coinsystem.exchange_rates" */
["coinsystem_exchange_rates"]: {
		buy_rate:ModelTypes["numeric"],
	created_at:ModelTypes["timestamptz"],
	destination_coin_id:number,
	id:number,
	sell_rate:ModelTypes["numeric"],
	source_coin_id:number
};
	/** aggregated selection of "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_aggregate"]: {
		aggregate?:ModelTypes["coinsystem_exchange_rates_aggregate_fields"],
	nodes:ModelTypes["coinsystem_exchange_rates"][]
};
	/** aggregate fields of "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_aggregate_fields"]: {
		avg?:ModelTypes["coinsystem_exchange_rates_avg_fields"],
	count:number,
	max?:ModelTypes["coinsystem_exchange_rates_max_fields"],
	min?:ModelTypes["coinsystem_exchange_rates_min_fields"],
	stddev?:ModelTypes["coinsystem_exchange_rates_stddev_fields"],
	stddev_pop?:ModelTypes["coinsystem_exchange_rates_stddev_pop_fields"],
	stddev_samp?:ModelTypes["coinsystem_exchange_rates_stddev_samp_fields"],
	sum?:ModelTypes["coinsystem_exchange_rates_sum_fields"],
	var_pop?:ModelTypes["coinsystem_exchange_rates_var_pop_fields"],
	var_samp?:ModelTypes["coinsystem_exchange_rates_var_samp_fields"],
	variance?:ModelTypes["coinsystem_exchange_rates_variance_fields"]
};
	/** aggregate avg on columns */
["coinsystem_exchange_rates_avg_fields"]: {
		buy_rate?:number,
	destination_coin_id?:number,
	id?:number,
	sell_rate?:number,
	source_coin_id?:number
};
	/** Boolean expression to filter rows from the table "coinsystem.exchange_rates". All fields are combined with a logical 'AND'. */
["coinsystem_exchange_rates_bool_exp"]: GraphQLTypes["coinsystem_exchange_rates_bool_exp"];
	/** unique or primary key constraints on table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_constraint"]: GraphQLTypes["coinsystem_exchange_rates_constraint"];
	/** input type for incrementing numeric columns in table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_inc_input"]: GraphQLTypes["coinsystem_exchange_rates_inc_input"];
	/** input type for inserting data into table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_insert_input"]: GraphQLTypes["coinsystem_exchange_rates_insert_input"];
	/** aggregate max on columns */
["coinsystem_exchange_rates_max_fields"]: {
		buy_rate?:ModelTypes["numeric"],
	created_at?:ModelTypes["timestamptz"],
	destination_coin_id?:number,
	id?:number,
	sell_rate?:ModelTypes["numeric"],
	source_coin_id?:number
};
	/** aggregate min on columns */
["coinsystem_exchange_rates_min_fields"]: {
		buy_rate?:ModelTypes["numeric"],
	created_at?:ModelTypes["timestamptz"],
	destination_coin_id?:number,
	id?:number,
	sell_rate?:ModelTypes["numeric"],
	source_coin_id?:number
};
	/** response of any mutation on the table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["coinsystem_exchange_rates"][]
};
	/** on_conflict condition type for table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_on_conflict"]: GraphQLTypes["coinsystem_exchange_rates_on_conflict"];
	/** Ordering options when selecting data from "coinsystem.exchange_rates". */
["coinsystem_exchange_rates_order_by"]: GraphQLTypes["coinsystem_exchange_rates_order_by"];
	/** primary key columns input for table: coinsystem.exchange_rates */
["coinsystem_exchange_rates_pk_columns_input"]: GraphQLTypes["coinsystem_exchange_rates_pk_columns_input"];
	/** select columns of table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_select_column"]: GraphQLTypes["coinsystem_exchange_rates_select_column"];
	/** input type for updating data in table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_set_input"]: GraphQLTypes["coinsystem_exchange_rates_set_input"];
	/** aggregate stddev on columns */
["coinsystem_exchange_rates_stddev_fields"]: {
		buy_rate?:number,
	destination_coin_id?:number,
	id?:number,
	sell_rate?:number,
	source_coin_id?:number
};
	/** aggregate stddev_pop on columns */
["coinsystem_exchange_rates_stddev_pop_fields"]: {
		buy_rate?:number,
	destination_coin_id?:number,
	id?:number,
	sell_rate?:number,
	source_coin_id?:number
};
	/** aggregate stddev_samp on columns */
["coinsystem_exchange_rates_stddev_samp_fields"]: {
		buy_rate?:number,
	destination_coin_id?:number,
	id?:number,
	sell_rate?:number,
	source_coin_id?:number
};
	/** Streaming cursor of the table "coinsystem_exchange_rates" */
["coinsystem_exchange_rates_stream_cursor_input"]: GraphQLTypes["coinsystem_exchange_rates_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["coinsystem_exchange_rates_stream_cursor_value_input"]: GraphQLTypes["coinsystem_exchange_rates_stream_cursor_value_input"];
	/** aggregate sum on columns */
["coinsystem_exchange_rates_sum_fields"]: {
		buy_rate?:ModelTypes["numeric"],
	destination_coin_id?:number,
	id?:number,
	sell_rate?:ModelTypes["numeric"],
	source_coin_id?:number
};
	/** update columns of table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_update_column"]: GraphQLTypes["coinsystem_exchange_rates_update_column"];
	["coinsystem_exchange_rates_updates"]: GraphQLTypes["coinsystem_exchange_rates_updates"];
	/** aggregate var_pop on columns */
["coinsystem_exchange_rates_var_pop_fields"]: {
		buy_rate?:number,
	destination_coin_id?:number,
	id?:number,
	sell_rate?:number,
	source_coin_id?:number
};
	/** aggregate var_samp on columns */
["coinsystem_exchange_rates_var_samp_fields"]: {
		buy_rate?:number,
	destination_coin_id?:number,
	id?:number,
	sell_rate?:number,
	source_coin_id?:number
};
	/** aggregate variance on columns */
["coinsystem_exchange_rates_variance_fields"]: {
		buy_rate?:number,
	destination_coin_id?:number,
	id?:number,
	sell_rate?:number,
	source_coin_id?:number
};
	/** columns and relationships of "coinsystem.logs" */
["coinsystem_logs"]: {
		created_at:ModelTypes["timestamptz"],
	description:ModelTypes["bpchar"],
	id:number,
	transaction_id:string
};
	/** aggregated selection of "coinsystem.logs" */
["coinsystem_logs_aggregate"]: {
		aggregate?:ModelTypes["coinsystem_logs_aggregate_fields"],
	nodes:ModelTypes["coinsystem_logs"][]
};
	/** aggregate fields of "coinsystem.logs" */
["coinsystem_logs_aggregate_fields"]: {
		avg?:ModelTypes["coinsystem_logs_avg_fields"],
	count:number,
	max?:ModelTypes["coinsystem_logs_max_fields"],
	min?:ModelTypes["coinsystem_logs_min_fields"],
	stddev?:ModelTypes["coinsystem_logs_stddev_fields"],
	stddev_pop?:ModelTypes["coinsystem_logs_stddev_pop_fields"],
	stddev_samp?:ModelTypes["coinsystem_logs_stddev_samp_fields"],
	sum?:ModelTypes["coinsystem_logs_sum_fields"],
	var_pop?:ModelTypes["coinsystem_logs_var_pop_fields"],
	var_samp?:ModelTypes["coinsystem_logs_var_samp_fields"],
	variance?:ModelTypes["coinsystem_logs_variance_fields"]
};
	/** aggregate avg on columns */
["coinsystem_logs_avg_fields"]: {
		id?:number
};
	/** Boolean expression to filter rows from the table "coinsystem.logs". All fields are combined with a logical 'AND'. */
["coinsystem_logs_bool_exp"]: GraphQLTypes["coinsystem_logs_bool_exp"];
	/** unique or primary key constraints on table "coinsystem.logs" */
["coinsystem_logs_constraint"]: GraphQLTypes["coinsystem_logs_constraint"];
	/** input type for incrementing numeric columns in table "coinsystem.logs" */
["coinsystem_logs_inc_input"]: GraphQLTypes["coinsystem_logs_inc_input"];
	/** input type for inserting data into table "coinsystem.logs" */
["coinsystem_logs_insert_input"]: GraphQLTypes["coinsystem_logs_insert_input"];
	/** aggregate max on columns */
["coinsystem_logs_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	description?:ModelTypes["bpchar"],
	id?:number,
	transaction_id?:string
};
	/** aggregate min on columns */
["coinsystem_logs_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	description?:ModelTypes["bpchar"],
	id?:number,
	transaction_id?:string
};
	/** response of any mutation on the table "coinsystem.logs" */
["coinsystem_logs_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["coinsystem_logs"][]
};
	/** on_conflict condition type for table "coinsystem.logs" */
["coinsystem_logs_on_conflict"]: GraphQLTypes["coinsystem_logs_on_conflict"];
	/** Ordering options when selecting data from "coinsystem.logs". */
["coinsystem_logs_order_by"]: GraphQLTypes["coinsystem_logs_order_by"];
	/** primary key columns input for table: coinsystem.logs */
["coinsystem_logs_pk_columns_input"]: GraphQLTypes["coinsystem_logs_pk_columns_input"];
	/** select columns of table "coinsystem.logs" */
["coinsystem_logs_select_column"]: GraphQLTypes["coinsystem_logs_select_column"];
	/** input type for updating data in table "coinsystem.logs" */
["coinsystem_logs_set_input"]: GraphQLTypes["coinsystem_logs_set_input"];
	/** aggregate stddev on columns */
["coinsystem_logs_stddev_fields"]: {
		id?:number
};
	/** aggregate stddev_pop on columns */
["coinsystem_logs_stddev_pop_fields"]: {
		id?:number
};
	/** aggregate stddev_samp on columns */
["coinsystem_logs_stddev_samp_fields"]: {
		id?:number
};
	/** Streaming cursor of the table "coinsystem_logs" */
["coinsystem_logs_stream_cursor_input"]: GraphQLTypes["coinsystem_logs_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["coinsystem_logs_stream_cursor_value_input"]: GraphQLTypes["coinsystem_logs_stream_cursor_value_input"];
	/** aggregate sum on columns */
["coinsystem_logs_sum_fields"]: {
		id?:number
};
	/** update columns of table "coinsystem.logs" */
["coinsystem_logs_update_column"]: GraphQLTypes["coinsystem_logs_update_column"];
	["coinsystem_logs_updates"]: GraphQLTypes["coinsystem_logs_updates"];
	/** aggregate var_pop on columns */
["coinsystem_logs_var_pop_fields"]: {
		id?:number
};
	/** aggregate var_samp on columns */
["coinsystem_logs_var_samp_fields"]: {
		id?:number
};
	/** aggregate variance on columns */
["coinsystem_logs_variance_fields"]: {
		id?:number
};
	/** columns and relationships of "coinsystem.transactions" */
["coinsystem_transactions"]: {
		coin_amount:ModelTypes["numeric"],
	coin_id:number,
	created_at:ModelTypes["timestamptz"],
	description:ModelTypes["bpchar"],
	id:number,
	recipient_id?:number,
	recipient_wallet_address?:string,
	sender_id?:number,
	sender_wallet_address?:string,
	transaction_id:string,
	transaction_type:string
};
	/** aggregated selection of "coinsystem.transactions" */
["coinsystem_transactions_aggregate"]: {
		aggregate?:ModelTypes["coinsystem_transactions_aggregate_fields"],
	nodes:ModelTypes["coinsystem_transactions"][]
};
	/** aggregate fields of "coinsystem.transactions" */
["coinsystem_transactions_aggregate_fields"]: {
		avg?:ModelTypes["coinsystem_transactions_avg_fields"],
	count:number,
	max?:ModelTypes["coinsystem_transactions_max_fields"],
	min?:ModelTypes["coinsystem_transactions_min_fields"],
	stddev?:ModelTypes["coinsystem_transactions_stddev_fields"],
	stddev_pop?:ModelTypes["coinsystem_transactions_stddev_pop_fields"],
	stddev_samp?:ModelTypes["coinsystem_transactions_stddev_samp_fields"],
	sum?:ModelTypes["coinsystem_transactions_sum_fields"],
	var_pop?:ModelTypes["coinsystem_transactions_var_pop_fields"],
	var_samp?:ModelTypes["coinsystem_transactions_var_samp_fields"],
	variance?:ModelTypes["coinsystem_transactions_variance_fields"]
};
	/** aggregate avg on columns */
["coinsystem_transactions_avg_fields"]: {
		coin_amount?:number,
	coin_id?:number,
	id?:number,
	recipient_id?:number,
	sender_id?:number
};
	/** Boolean expression to filter rows from the table "coinsystem.transactions". All fields are combined with a logical 'AND'. */
["coinsystem_transactions_bool_exp"]: GraphQLTypes["coinsystem_transactions_bool_exp"];
	/** unique or primary key constraints on table "coinsystem.transactions" */
["coinsystem_transactions_constraint"]: GraphQLTypes["coinsystem_transactions_constraint"];
	/** input type for incrementing numeric columns in table "coinsystem.transactions" */
["coinsystem_transactions_inc_input"]: GraphQLTypes["coinsystem_transactions_inc_input"];
	/** input type for inserting data into table "coinsystem.transactions" */
["coinsystem_transactions_insert_input"]: GraphQLTypes["coinsystem_transactions_insert_input"];
	/** aggregate max on columns */
["coinsystem_transactions_max_fields"]: {
		coin_amount?:ModelTypes["numeric"],
	coin_id?:number,
	created_at?:ModelTypes["timestamptz"],
	description?:ModelTypes["bpchar"],
	id?:number,
	recipient_id?:number,
	recipient_wallet_address?:string,
	sender_id?:number,
	sender_wallet_address?:string,
	transaction_id?:string,
	transaction_type?:string
};
	/** aggregate min on columns */
["coinsystem_transactions_min_fields"]: {
		coin_amount?:ModelTypes["numeric"],
	coin_id?:number,
	created_at?:ModelTypes["timestamptz"],
	description?:ModelTypes["bpchar"],
	id?:number,
	recipient_id?:number,
	recipient_wallet_address?:string,
	sender_id?:number,
	sender_wallet_address?:string,
	transaction_id?:string,
	transaction_type?:string
};
	/** response of any mutation on the table "coinsystem.transactions" */
["coinsystem_transactions_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["coinsystem_transactions"][]
};
	/** on_conflict condition type for table "coinsystem.transactions" */
["coinsystem_transactions_on_conflict"]: GraphQLTypes["coinsystem_transactions_on_conflict"];
	/** Ordering options when selecting data from "coinsystem.transactions". */
["coinsystem_transactions_order_by"]: GraphQLTypes["coinsystem_transactions_order_by"];
	/** primary key columns input for table: coinsystem.transactions */
["coinsystem_transactions_pk_columns_input"]: GraphQLTypes["coinsystem_transactions_pk_columns_input"];
	/** select columns of table "coinsystem.transactions" */
["coinsystem_transactions_select_column"]: GraphQLTypes["coinsystem_transactions_select_column"];
	/** input type for updating data in table "coinsystem.transactions" */
["coinsystem_transactions_set_input"]: GraphQLTypes["coinsystem_transactions_set_input"];
	/** aggregate stddev on columns */
["coinsystem_transactions_stddev_fields"]: {
		coin_amount?:number,
	coin_id?:number,
	id?:number,
	recipient_id?:number,
	sender_id?:number
};
	/** aggregate stddev_pop on columns */
["coinsystem_transactions_stddev_pop_fields"]: {
		coin_amount?:number,
	coin_id?:number,
	id?:number,
	recipient_id?:number,
	sender_id?:number
};
	/** aggregate stddev_samp on columns */
["coinsystem_transactions_stddev_samp_fields"]: {
		coin_amount?:number,
	coin_id?:number,
	id?:number,
	recipient_id?:number,
	sender_id?:number
};
	/** Streaming cursor of the table "coinsystem_transactions" */
["coinsystem_transactions_stream_cursor_input"]: GraphQLTypes["coinsystem_transactions_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["coinsystem_transactions_stream_cursor_value_input"]: GraphQLTypes["coinsystem_transactions_stream_cursor_value_input"];
	/** aggregate sum on columns */
["coinsystem_transactions_sum_fields"]: {
		coin_amount?:ModelTypes["numeric"],
	coin_id?:number,
	id?:number,
	recipient_id?:number,
	sender_id?:number
};
	/** update columns of table "coinsystem.transactions" */
["coinsystem_transactions_update_column"]: GraphQLTypes["coinsystem_transactions_update_column"];
	["coinsystem_transactions_updates"]: GraphQLTypes["coinsystem_transactions_updates"];
	/** aggregate var_pop on columns */
["coinsystem_transactions_var_pop_fields"]: {
		coin_amount?:number,
	coin_id?:number,
	id?:number,
	recipient_id?:number,
	sender_id?:number
};
	/** aggregate var_samp on columns */
["coinsystem_transactions_var_samp_fields"]: {
		coin_amount?:number,
	coin_id?:number,
	id?:number,
	recipient_id?:number,
	sender_id?:number
};
	/** aggregate variance on columns */
["coinsystem_transactions_variance_fields"]: {
		coin_amount?:number,
	coin_id?:number,
	id?:number,
	recipient_id?:number,
	sender_id?:number
};
	/** columns and relationships of "coinsystem.wallets" */
["coinsystem_wallets"]: {
		can_mint:boolean,
	coin_id:number,
	created_at:ModelTypes["timestamptz"],
	default:boolean,
	id:number,
	is_vm:boolean,
	tag?:string,
	updated_at:ModelTypes["timestamptz"],
	user_id?:number,
	wallet_address:string
};
	/** aggregated selection of "coinsystem.wallets" */
["coinsystem_wallets_aggregate"]: {
		aggregate?:ModelTypes["coinsystem_wallets_aggregate_fields"],
	nodes:ModelTypes["coinsystem_wallets"][]
};
	/** aggregate fields of "coinsystem.wallets" */
["coinsystem_wallets_aggregate_fields"]: {
		avg?:ModelTypes["coinsystem_wallets_avg_fields"],
	count:number,
	max?:ModelTypes["coinsystem_wallets_max_fields"],
	min?:ModelTypes["coinsystem_wallets_min_fields"],
	stddev?:ModelTypes["coinsystem_wallets_stddev_fields"],
	stddev_pop?:ModelTypes["coinsystem_wallets_stddev_pop_fields"],
	stddev_samp?:ModelTypes["coinsystem_wallets_stddev_samp_fields"],
	sum?:ModelTypes["coinsystem_wallets_sum_fields"],
	var_pop?:ModelTypes["coinsystem_wallets_var_pop_fields"],
	var_samp?:ModelTypes["coinsystem_wallets_var_samp_fields"],
	variance?:ModelTypes["coinsystem_wallets_variance_fields"]
};
	/** aggregate avg on columns */
["coinsystem_wallets_avg_fields"]: {
		coin_id?:number,
	id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "coinsystem.wallets". All fields are combined with a logical 'AND'. */
["coinsystem_wallets_bool_exp"]: GraphQLTypes["coinsystem_wallets_bool_exp"];
	/** unique or primary key constraints on table "coinsystem.wallets" */
["coinsystem_wallets_constraint"]: GraphQLTypes["coinsystem_wallets_constraint"];
	/** input type for incrementing numeric columns in table "coinsystem.wallets" */
["coinsystem_wallets_inc_input"]: GraphQLTypes["coinsystem_wallets_inc_input"];
	/** input type for inserting data into table "coinsystem.wallets" */
["coinsystem_wallets_insert_input"]: GraphQLTypes["coinsystem_wallets_insert_input"];
	/** aggregate max on columns */
["coinsystem_wallets_max_fields"]: {
		coin_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	tag?:string,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number,
	wallet_address?:string
};
	/** aggregate min on columns */
["coinsystem_wallets_min_fields"]: {
		coin_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	tag?:string,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number,
	wallet_address?:string
};
	/** response of any mutation on the table "coinsystem.wallets" */
["coinsystem_wallets_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["coinsystem_wallets"][]
};
	/** input type for inserting object relation for remote table "coinsystem.wallets" */
["coinsystem_wallets_obj_rel_insert_input"]: GraphQLTypes["coinsystem_wallets_obj_rel_insert_input"];
	/** on_conflict condition type for table "coinsystem.wallets" */
["coinsystem_wallets_on_conflict"]: GraphQLTypes["coinsystem_wallets_on_conflict"];
	/** Ordering options when selecting data from "coinsystem.wallets". */
["coinsystem_wallets_order_by"]: GraphQLTypes["coinsystem_wallets_order_by"];
	/** primary key columns input for table: coinsystem.wallets */
["coinsystem_wallets_pk_columns_input"]: GraphQLTypes["coinsystem_wallets_pk_columns_input"];
	/** select columns of table "coinsystem.wallets" */
["coinsystem_wallets_select_column"]: GraphQLTypes["coinsystem_wallets_select_column"];
	/** input type for updating data in table "coinsystem.wallets" */
["coinsystem_wallets_set_input"]: GraphQLTypes["coinsystem_wallets_set_input"];
	/** aggregate stddev on columns */
["coinsystem_wallets_stddev_fields"]: {
		coin_id?:number,
	id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["coinsystem_wallets_stddev_pop_fields"]: {
		coin_id?:number,
	id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["coinsystem_wallets_stddev_samp_fields"]: {
		coin_id?:number,
	id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "coinsystem_wallets" */
["coinsystem_wallets_stream_cursor_input"]: GraphQLTypes["coinsystem_wallets_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["coinsystem_wallets_stream_cursor_value_input"]: GraphQLTypes["coinsystem_wallets_stream_cursor_value_input"];
	/** aggregate sum on columns */
["coinsystem_wallets_sum_fields"]: {
		coin_id?:number,
	id?:number,
	user_id?:number
};
	/** update columns of table "coinsystem.wallets" */
["coinsystem_wallets_update_column"]: GraphQLTypes["coinsystem_wallets_update_column"];
	["coinsystem_wallets_updates"]: GraphQLTypes["coinsystem_wallets_updates"];
	/** aggregate var_pop on columns */
["coinsystem_wallets_var_pop_fields"]: {
		coin_id?:number,
	id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["coinsystem_wallets_var_samp_fields"]: {
		coin_id?:number,
	id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["coinsystem_wallets_variance_fields"]: {
		coin_id?:number,
	id?:number,
	user_id?:number
};
	/** ordering argument of a cursor */
["cursor_ordering"]: GraphQLTypes["cursor_ordering"];
	["date"]:any;
	/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
["date_comparison_exp"]: GraphQLTypes["date_comparison_exp"];
	["float8"]:any;
	/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
["float8_comparison_exp"]: GraphQLTypes["float8_comparison_exp"];
	["json"]:any;
	/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
["json_comparison_exp"]: GraphQLTypes["json_comparison_exp"];
	["jsonb"]:any;
	["jsonb_cast_exp"]: GraphQLTypes["jsonb_cast_exp"];
	/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
["jsonb_comparison_exp"]: GraphQLTypes["jsonb_comparison_exp"];
	/** mutation root */
["mutation_root"]: {
		/** delete data from the table: "awsdms_validation_failures_v1" */
	delete_awsdms_validation_failures_v1?:ModelTypes["awsdms_validation_failures_v1_mutation_response"],
	/** delete data from the table: "coinsystem.balances" */
	delete_coinsystem_balances?:ModelTypes["coinsystem_balances_mutation_response"],
	/** delete single row from the table: "coinsystem.balances" */
	delete_coinsystem_balances_by_pk?:ModelTypes["coinsystem_balances"],
	/** delete data from the table: "coinsystem.bonus_coins" */
	delete_coinsystem_bonus_coins?:ModelTypes["coinsystem_bonus_coins_mutation_response"],
	/** delete single row from the table: "coinsystem.bonus_coins" */
	delete_coinsystem_bonus_coins_by_pk?:ModelTypes["coinsystem_bonus_coins"],
	/** delete data from the table: "coinsystem.coins" */
	delete_coinsystem_coins?:ModelTypes["coinsystem_coins_mutation_response"],
	/** delete single row from the table: "coinsystem.coins" */
	delete_coinsystem_coins_by_pk?:ModelTypes["coinsystem_coins"],
	/** delete data from the table: "coinsystem.exchange_rates" */
	delete_coinsystem_exchange_rates?:ModelTypes["coinsystem_exchange_rates_mutation_response"],
	/** delete single row from the table: "coinsystem.exchange_rates" */
	delete_coinsystem_exchange_rates_by_pk?:ModelTypes["coinsystem_exchange_rates"],
	/** delete data from the table: "coinsystem.logs" */
	delete_coinsystem_logs?:ModelTypes["coinsystem_logs_mutation_response"],
	/** delete single row from the table: "coinsystem.logs" */
	delete_coinsystem_logs_by_pk?:ModelTypes["coinsystem_logs"],
	/** delete data from the table: "coinsystem.transactions" */
	delete_coinsystem_transactions?:ModelTypes["coinsystem_transactions_mutation_response"],
	/** delete single row from the table: "coinsystem.transactions" */
	delete_coinsystem_transactions_by_pk?:ModelTypes["coinsystem_transactions"],
	/** delete data from the table: "coinsystem.wallets" */
	delete_coinsystem_wallets?:ModelTypes["coinsystem_wallets_mutation_response"],
	/** delete single row from the table: "coinsystem.wallets" */
	delete_coinsystem_wallets_by_pk?:ModelTypes["coinsystem_wallets"],
	/** delete data from the table: "mysql.user" */
	delete_mysql_user?:ModelTypes["mysql_user_mutation_response"],
	/** delete single row from the table: "mysql.user" */
	delete_mysql_user_by_pk?:ModelTypes["mysql_user"],
	/** delete data from the table: "store.payments" */
	delete_store_payments?:ModelTypes["store_payments_mutation_response"],
	/** delete single row from the table: "store.payments" */
	delete_store_payments_by_pk?:ModelTypes["store_payments"],
	/** delete data from the table: "store.products" */
	delete_store_products?:ModelTypes["store_products_mutation_response"],
	/** delete single row from the table: "store.products" */
	delete_store_products_by_pk?:ModelTypes["store_products"],
	/** delete data from the table: "store.purchases" */
	delete_store_purchases?:ModelTypes["store_purchases_mutation_response"],
	/** delete single row from the table: "store.purchases" */
	delete_store_purchases_by_pk?:ModelTypes["store_purchases"],
	/** delete data from the table: "store.users" */
	delete_store_users?:ModelTypes["store_users_mutation_response"],
	/** delete data from the table: "voyce.admin_roles" */
	delete_voyce_admin_roles?:ModelTypes["voyce_admin_roles_mutation_response"],
	/** delete single row from the table: "voyce.admin_roles" */
	delete_voyce_admin_roles_by_pk?:ModelTypes["voyce_admin_roles"],
	/** delete data from the table: "voyce.app_banners" */
	delete_voyce_app_banners?:ModelTypes["voyce_app_banners_mutation_response"],
	/** delete single row from the table: "voyce.app_banners" */
	delete_voyce_app_banners_by_pk?:ModelTypes["voyce_app_banners"],
	/** delete data from the table: "voyce.app_components" */
	delete_voyce_app_components?:ModelTypes["voyce_app_components_mutation_response"],
	/** delete single row from the table: "voyce.app_components" */
	delete_voyce_app_components_by_pk?:ModelTypes["voyce_app_components"],
	/** delete data from the table: "voyce.audit_trail" */
	delete_voyce_audit_trail?:ModelTypes["voyce_audit_trail_mutation_response"],
	/** delete single row from the table: "voyce.audit_trail" */
	delete_voyce_audit_trail_by_pk?:ModelTypes["voyce_audit_trail"],
	/** delete data from the table: "voyce.author_profiles" */
	delete_voyce_author_profiles?:ModelTypes["voyce_author_profiles_mutation_response"],
	/** delete single row from the table: "voyce.author_profiles" */
	delete_voyce_author_profiles_by_pk?:ModelTypes["voyce_author_profiles"],
	/** delete data from the table: "voyce.balances" */
	delete_voyce_balances?:ModelTypes["voyce_balances_mutation_response"],
	/** delete single row from the table: "voyce.balances" */
	delete_voyce_balances_by_pk?:ModelTypes["voyce_balances"],
	/** delete data from the table: "voyce.banners" */
	delete_voyce_banners?:ModelTypes["voyce_banners_mutation_response"],
	/** delete single row from the table: "voyce.banners" */
	delete_voyce_banners_by_pk?:ModelTypes["voyce_banners"],
	/** delete data from the table: "voyce.chapter_assets" */
	delete_voyce_chapter_assets?:ModelTypes["voyce_chapter_assets_mutation_response"],
	/** delete single row from the table: "voyce.chapter_assets" */
	delete_voyce_chapter_assets_by_pk?:ModelTypes["voyce_chapter_assets"],
	/** delete data from the table: "voyce.chapter_bookmarks" */
	delete_voyce_chapter_bookmarks?:ModelTypes["voyce_chapter_bookmarks_mutation_response"],
	/** delete single row from the table: "voyce.chapter_bookmarks" */
	delete_voyce_chapter_bookmarks_by_pk?:ModelTypes["voyce_chapter_bookmarks"],
	/** delete data from the table: "voyce.chapter_comments" */
	delete_voyce_chapter_comments?:ModelTypes["voyce_chapter_comments_mutation_response"],
	/** delete data from the table: "voyce.chapter_comments_07252024" */
	delete_voyce_chapter_comments_07252024?:ModelTypes["voyce_chapter_comments_07252024_mutation_response"],
	/** delete single row from the table: "voyce.chapter_comments" */
	delete_voyce_chapter_comments_by_pk?:ModelTypes["voyce_chapter_comments"],
	/** delete data from the table: "voyce.chapter_comments_reaction" */
	delete_voyce_chapter_comments_reaction?:ModelTypes["voyce_chapter_comments_reaction_mutation_response"],
	/** delete single row from the table: "voyce.chapter_comments_reaction" */
	delete_voyce_chapter_comments_reaction_by_pk?:ModelTypes["voyce_chapter_comments_reaction"],
	/** delete data from the table: "voyce.chapter_comments_reaction_count" */
	delete_voyce_chapter_comments_reaction_count?:ModelTypes["voyce_chapter_comments_reaction_count_mutation_response"],
	/** delete single row from the table: "voyce.chapter_comments_reaction_count" */
	delete_voyce_chapter_comments_reaction_count_by_pk?:ModelTypes["voyce_chapter_comments_reaction_count"],
	/** delete data from the table: "voyce.chapter_comments_reports" */
	delete_voyce_chapter_comments_reports?:ModelTypes["voyce_chapter_comments_reports_mutation_response"],
	/** delete single row from the table: "voyce.chapter_comments_reports" */
	delete_voyce_chapter_comments_reports_by_pk?:ModelTypes["voyce_chapter_comments_reports"],
	/** delete data from the table: "voyce.chapter_contents" */
	delete_voyce_chapter_contents?:ModelTypes["voyce_chapter_contents_mutation_response"],
	/** delete single row from the table: "voyce.chapter_contents" */
	delete_voyce_chapter_contents_by_pk?:ModelTypes["voyce_chapter_contents"],
	/** delete data from the table: "voyce.chapter_contents_old" */
	delete_voyce_chapter_contents_old?:ModelTypes["voyce_chapter_contents_old_mutation_response"],
	/** delete single row from the table: "voyce.chapter_contents_old" */
	delete_voyce_chapter_contents_old_by_pk?:ModelTypes["voyce_chapter_contents_old"],
	/** delete data from the table: "voyce.chapter_effects" */
	delete_voyce_chapter_effects?:ModelTypes["voyce_chapter_effects_mutation_response"],
	/** delete single row from the table: "voyce.chapter_effects" */
	delete_voyce_chapter_effects_by_pk?:ModelTypes["voyce_chapter_effects"],
	/** delete data from the table: "voyce.chapter_images" */
	delete_voyce_chapter_images?:ModelTypes["voyce_chapter_images_mutation_response"],
	/** delete data from the table: "voyce.chapter_images_07252024" */
	delete_voyce_chapter_images_07252024?:ModelTypes["voyce_chapter_images_07252024_mutation_response"],
	/** delete single row from the table: "voyce.chapter_images" */
	delete_voyce_chapter_images_by_pk?:ModelTypes["voyce_chapter_images"],
	/** delete data from the table: "voyce.chapter_likes" */
	delete_voyce_chapter_likes?:ModelTypes["voyce_chapter_likes_mutation_response"],
	/** delete single row from the table: "voyce.chapter_likes" */
	delete_voyce_chapter_likes_by_pk?:ModelTypes["voyce_chapter_likes"],
	/** delete data from the table: "voyce.chapter_locks" */
	delete_voyce_chapter_locks?:ModelTypes["voyce_chapter_locks_mutation_response"],
	/** delete single row from the table: "voyce.chapter_locks" */
	delete_voyce_chapter_locks_by_pk?:ModelTypes["voyce_chapter_locks"],
	/** delete data from the table: "voyce.chapter_panel_views" */
	delete_voyce_chapter_panel_views?:ModelTypes["voyce_chapter_panel_views_mutation_response"],
	/** delete single row from the table: "voyce.chapter_panel_views" */
	delete_voyce_chapter_panel_views_by_pk?:ModelTypes["voyce_chapter_panel_views"],
	/** delete data from the table: "voyce.chapter_panels" */
	delete_voyce_chapter_panels?:ModelTypes["voyce_chapter_panels_mutation_response"],
	/** delete single row from the table: "voyce.chapter_panels" */
	delete_voyce_chapter_panels_by_pk?:ModelTypes["voyce_chapter_panels"],
	/** delete data from the table: "voyce.chapter_publish_schedules" */
	delete_voyce_chapter_publish_schedules?:ModelTypes["voyce_chapter_publish_schedules_mutation_response"],
	/** delete single row from the table: "voyce.chapter_publish_schedules" */
	delete_voyce_chapter_publish_schedules_by_pk?:ModelTypes["voyce_chapter_publish_schedules"],
	/** delete data from the table: "voyce.chapter_reactions" */
	delete_voyce_chapter_reactions?:ModelTypes["voyce_chapter_reactions_mutation_response"],
	/** delete single row from the table: "voyce.chapter_reactions" */
	delete_voyce_chapter_reactions_by_pk?:ModelTypes["voyce_chapter_reactions"],
	/** delete data from the table: "voyce.chapter_reactions_count" */
	delete_voyce_chapter_reactions_count?:ModelTypes["voyce_chapter_reactions_count_mutation_response"],
	/** delete single row from the table: "voyce.chapter_reactions_count" */
	delete_voyce_chapter_reactions_count_by_pk?:ModelTypes["voyce_chapter_reactions_count"],
	/** delete data from the table: "voyce.chapter_view_logs" */
	delete_voyce_chapter_view_logs?:ModelTypes["voyce_chapter_view_logs_mutation_response"],
	/** delete single row from the table: "voyce.chapter_view_logs" */
	delete_voyce_chapter_view_logs_by_pk?:ModelTypes["voyce_chapter_view_logs"],
	/** delete data from the table: "voyce.chapter_views" */
	delete_voyce_chapter_views?:ModelTypes["voyce_chapter_views_mutation_response"],
	/** delete single row from the table: "voyce.chapter_views" */
	delete_voyce_chapter_views_by_pk?:ModelTypes["voyce_chapter_views"],
	/** delete data from the table: "voyce.chapters" */
	delete_voyce_chapters?:ModelTypes["voyce_chapters_mutation_response"],
	/** delete data from the table: "voyce.chapters_07252024" */
	delete_voyce_chapters_07252024?:ModelTypes["voyce_chapters_07252024_mutation_response"],
	/** delete single row from the table: "voyce.chapters" */
	delete_voyce_chapters_by_pk?:ModelTypes["voyce_chapters"],
	/** delete data from the table: "voyce.collaborator_roles" */
	delete_voyce_collaborator_roles?:ModelTypes["voyce_collaborator_roles_mutation_response"],
	/** delete single row from the table: "voyce.collaborator_roles" */
	delete_voyce_collaborator_roles_by_pk?:ModelTypes["voyce_collaborator_roles"],
	/** delete data from the table: "voyce.comic_types" */
	delete_voyce_comic_types?:ModelTypes["voyce_comic_types_mutation_response"],
	/** delete single row from the table: "voyce.comic_types" */
	delete_voyce_comic_types_by_pk?:ModelTypes["voyce_comic_types"],
	/** delete data from the table: "voyce.community_blurbs" */
	delete_voyce_community_blurbs?:ModelTypes["voyce_community_blurbs_mutation_response"],
	/** delete single row from the table: "voyce.community_blurbs" */
	delete_voyce_community_blurbs_by_pk?:ModelTypes["voyce_community_blurbs"],
	/** delete data from the table: "voyce.contact_us" */
	delete_voyce_contact_us?:ModelTypes["voyce_contact_us_mutation_response"],
	/** delete single row from the table: "voyce.contact_us" */
	delete_voyce_contact_us_by_pk?:ModelTypes["voyce_contact_us"],
	/** delete data from the table: "voyce.content_report" */
	delete_voyce_content_report?:ModelTypes["voyce_content_report_mutation_response"],
	/** delete single row from the table: "voyce.content_report" */
	delete_voyce_content_report_by_pk?:ModelTypes["voyce_content_report"],
	/** delete data from the table: "voyce.contest_data" */
	delete_voyce_contest_data?:ModelTypes["voyce_contest_data_mutation_response"],
	/** delete single row from the table: "voyce.contest_data" */
	delete_voyce_contest_data_by_pk?:ModelTypes["voyce_contest_data"],
	/** delete data from the table: "voyce.countries" */
	delete_voyce_countries?:ModelTypes["voyce_countries_mutation_response"],
	/** delete single row from the table: "voyce.countries" */
	delete_voyce_countries_by_pk?:ModelTypes["voyce_countries"],
	/** delete data from the table: "voyce.csat_ratings" */
	delete_voyce_csat_ratings?:ModelTypes["voyce_csat_ratings_mutation_response"],
	/** delete single row from the table: "voyce.csat_ratings" */
	delete_voyce_csat_ratings_by_pk?:ModelTypes["voyce_csat_ratings"],
	/** delete data from the table: "voyce.csat_ratings_data" */
	delete_voyce_csat_ratings_data?:ModelTypes["voyce_csat_ratings_data_mutation_response"],
	/** delete single row from the table: "voyce.csat_ratings_data" */
	delete_voyce_csat_ratings_data_by_pk?:ModelTypes["voyce_csat_ratings_data"],
	/** delete data from the table: "voyce.education_types" */
	delete_voyce_education_types?:ModelTypes["voyce_education_types_mutation_response"],
	/** delete single row from the table: "voyce.education_types" */
	delete_voyce_education_types_by_pk?:ModelTypes["voyce_education_types"],
	/** delete data from the table: "voyce.erp_login_roles" */
	delete_voyce_erp_login_roles?:ModelTypes["voyce_erp_login_roles_mutation_response"],
	/** delete single row from the table: "voyce.erp_login_roles" */
	delete_voyce_erp_login_roles_by_pk?:ModelTypes["voyce_erp_login_roles"],
	/** delete data from the table: "voyce.etl_series_views_logs" */
	delete_voyce_etl_series_views_logs?:ModelTypes["voyce_etl_series_views_logs_mutation_response"],
	/** delete single row from the table: "voyce.etl_series_views_logs" */
	delete_voyce_etl_series_views_logs_by_pk?:ModelTypes["voyce_etl_series_views_logs"],
	/** delete data from the table: "voyce.event_schedules" */
	delete_voyce_event_schedules?:ModelTypes["voyce_event_schedules_mutation_response"],
	/** delete single row from the table: "voyce.event_schedules" */
	delete_voyce_event_schedules_by_pk?:ModelTypes["voyce_event_schedules"],
	/** delete data from the table: "voyce.genres" */
	delete_voyce_genres?:ModelTypes["voyce_genres_mutation_response"],
	/** delete single row from the table: "voyce.genres" */
	delete_voyce_genres_by_pk?:ModelTypes["voyce_genres"],
	/** delete data from the table: "voyce.guest_gtags" */
	delete_voyce_guest_gtags?:ModelTypes["voyce_guest_gtags_mutation_response"],
	/** delete single row from the table: "voyce.guest_gtags" */
	delete_voyce_guest_gtags_by_pk?:ModelTypes["voyce_guest_gtags"],
	/** delete data from the table: "voyce.metabases" */
	delete_voyce_metabases?:ModelTypes["voyce_metabases_mutation_response"],
	/** delete data from the table: "voyce.metabases_access" */
	delete_voyce_metabases_access?:ModelTypes["voyce_metabases_access_mutation_response"],
	/** delete single row from the table: "voyce.metabases_access" */
	delete_voyce_metabases_access_by_pk?:ModelTypes["voyce_metabases_access"],
	/** delete single row from the table: "voyce.metabases" */
	delete_voyce_metabases_by_pk?:ModelTypes["voyce_metabases"],
	/** delete data from the table: "voyce.newsletter" */
	delete_voyce_newsletter?:ModelTypes["voyce_newsletter_mutation_response"],
	/** delete single row from the table: "voyce.newsletter" */
	delete_voyce_newsletter_by_pk?:ModelTypes["voyce_newsletter"],
	/** delete data from the table: "voyce.notification_series_settings" */
	delete_voyce_notification_series_settings?:ModelTypes["voyce_notification_series_settings_mutation_response"],
	/** delete single row from the table: "voyce.notification_series_settings" */
	delete_voyce_notification_series_settings_by_pk?:ModelTypes["voyce_notification_series_settings"],
	/** delete data from the table: "voyce.notification_settings" */
	delete_voyce_notification_settings?:ModelTypes["voyce_notification_settings_mutation_response"],
	/** delete single row from the table: "voyce.notification_settings" */
	delete_voyce_notification_settings_by_pk?:ModelTypes["voyce_notification_settings"],
	/** delete data from the table: "voyce.notifications" */
	delete_voyce_notifications?:ModelTypes["voyce_notifications_mutation_response"],
	/** delete single row from the table: "voyce.notifications" */
	delete_voyce_notifications_by_pk?:ModelTypes["voyce_notifications"],
	/** delete data from the table: "voyce.page_banners" */
	delete_voyce_page_banners?:ModelTypes["voyce_page_banners_mutation_response"],
	/** delete single row from the table: "voyce.page_banners" */
	delete_voyce_page_banners_by_pk?:ModelTypes["voyce_page_banners"],
	/** delete data from the table: "voyce.page_components" */
	delete_voyce_page_components?:ModelTypes["voyce_page_components_mutation_response"],
	/** delete single row from the table: "voyce.page_components" */
	delete_voyce_page_components_by_pk?:ModelTypes["voyce_page_components"],
	/** delete data from the table: "voyce.pages" */
	delete_voyce_pages?:ModelTypes["voyce_pages_mutation_response"],
	/** delete single row from the table: "voyce.pages" */
	delete_voyce_pages_by_pk?:ModelTypes["voyce_pages"],
	/** delete data from the table: "voyce.panels_comments" */
	delete_voyce_panels_comments?:ModelTypes["voyce_panels_comments_mutation_response"],
	/** delete single row from the table: "voyce.panels_comments" */
	delete_voyce_panels_comments_by_pk?:ModelTypes["voyce_panels_comments"],
	/** delete data from the table: "voyce.panels_reactions" */
	delete_voyce_panels_reactions?:ModelTypes["voyce_panels_reactions_mutation_response"],
	/** delete single row from the table: "voyce.panels_reactions" */
	delete_voyce_panels_reactions_by_pk?:ModelTypes["voyce_panels_reactions"],
	/** delete data from the table: "voyce.panels_reactions_count" */
	delete_voyce_panels_reactions_count?:ModelTypes["voyce_panels_reactions_count_mutation_response"],
	/** delete single row from the table: "voyce.panels_reactions_count" */
	delete_voyce_panels_reactions_count_by_pk?:ModelTypes["voyce_panels_reactions_count"],
	/** delete data from the table: "voyce.payments_to_creators" */
	delete_voyce_payments_to_creators?:ModelTypes["voyce_payments_to_creators_mutation_response"],
	/** delete single row from the table: "voyce.payments_to_creators" */
	delete_voyce_payments_to_creators_by_pk?:ModelTypes["voyce_payments_to_creators"],
	/** delete data from the table: "voyce.pg_rs_series_views_count" */
	delete_voyce_pg_rs_series_views_count?:ModelTypes["voyce_pg_rs_series_views_count_mutation_response"],
	/** delete single row from the table: "voyce.pg_rs_series_views_count" */
	delete_voyce_pg_rs_series_views_count_by_pk?:ModelTypes["voyce_pg_rs_series_views_count"],
	/** delete data from the table: "voyce.polls" */
	delete_voyce_polls?:ModelTypes["voyce_polls_mutation_response"],
	/** delete data from the table: "voyce.polls_answers" */
	delete_voyce_polls_answers?:ModelTypes["voyce_polls_answers_mutation_response"],
	/** delete single row from the table: "voyce.polls_answers" */
	delete_voyce_polls_answers_by_pk?:ModelTypes["voyce_polls_answers"],
	/** delete single row from the table: "voyce.polls" */
	delete_voyce_polls_by_pk?:ModelTypes["voyce_polls"],
	/** delete data from the table: "voyce.polls_comments" */
	delete_voyce_polls_comments?:ModelTypes["voyce_polls_comments_mutation_response"],
	/** delete single row from the table: "voyce.polls_comments" */
	delete_voyce_polls_comments_by_pk?:ModelTypes["voyce_polls_comments"],
	/** delete data from the table: "voyce.polls_reactions" */
	delete_voyce_polls_reactions?:ModelTypes["voyce_polls_reactions_mutation_response"],
	/** delete single row from the table: "voyce.polls_reactions" */
	delete_voyce_polls_reactions_by_pk?:ModelTypes["voyce_polls_reactions"],
	/** delete data from the table: "voyce.polls_votes" */
	delete_voyce_polls_votes?:ModelTypes["voyce_polls_votes_mutation_response"],
	/** delete single row from the table: "voyce.polls_votes" */
	delete_voyce_polls_votes_by_pk?:ModelTypes["voyce_polls_votes"],
	/** delete data from the table: "voyce.popup_components" */
	delete_voyce_popup_components?:ModelTypes["voyce_popup_components_mutation_response"],
	/** delete single row from the table: "voyce.popup_components" */
	delete_voyce_popup_components_by_pk?:ModelTypes["voyce_popup_components"],
	/** delete data from the table: "voyce.popup_emails" */
	delete_voyce_popup_emails?:ModelTypes["voyce_popup_emails_mutation_response"],
	/** delete single row from the table: "voyce.popup_emails" */
	delete_voyce_popup_emails_by_pk?:ModelTypes["voyce_popup_emails"],
	/** delete data from the table: "voyce.posts" */
	delete_voyce_posts?:ModelTypes["voyce_posts_mutation_response"],
	/** delete single row from the table: "voyce.posts" */
	delete_voyce_posts_by_pk?:ModelTypes["voyce_posts"],
	/** delete data from the table: "voyce.posts_comments" */
	delete_voyce_posts_comments?:ModelTypes["voyce_posts_comments_mutation_response"],
	/** delete single row from the table: "voyce.posts_comments" */
	delete_voyce_posts_comments_by_pk?:ModelTypes["voyce_posts_comments"],
	/** delete data from the table: "voyce.posts_reactions" */
	delete_voyce_posts_reactions?:ModelTypes["voyce_posts_reactions_mutation_response"],
	/** delete single row from the table: "voyce.posts_reactions" */
	delete_voyce_posts_reactions_by_pk?:ModelTypes["voyce_posts_reactions"],
	/** delete data from the table: "voyce.quest_difficulties" */
	delete_voyce_quest_difficulties?:ModelTypes["voyce_quest_difficulties_mutation_response"],
	/** delete single row from the table: "voyce.quest_difficulties" */
	delete_voyce_quest_difficulties_by_pk?:ModelTypes["voyce_quest_difficulties"],
	/** delete data from the table: "voyce.quests" */
	delete_voyce_quests?:ModelTypes["voyce_quests_mutation_response"],
	/** delete single row from the table: "voyce.quests" */
	delete_voyce_quests_by_pk?:ModelTypes["voyce_quests"],
	/** delete data from the table: "voyce.quests_old" */
	delete_voyce_quests_old?:ModelTypes["voyce_quests_old_mutation_response"],
	/** delete single row from the table: "voyce.quests_old" */
	delete_voyce_quests_old_by_pk?:ModelTypes["voyce_quests_old"],
	/** delete data from the table: "voyce.recommended_series" */
	delete_voyce_recommended_series?:ModelTypes["voyce_recommended_series_mutation_response"],
	/** delete single row from the table: "voyce.recommended_series" */
	delete_voyce_recommended_series_by_pk?:ModelTypes["voyce_recommended_series"],
	/** delete data from the table: "voyce.release_schedules" */
	delete_voyce_release_schedules?:ModelTypes["voyce_release_schedules_mutation_response"],
	/** delete single row from the table: "voyce.release_schedules" */
	delete_voyce_release_schedules_by_pk?:ModelTypes["voyce_release_schedules"],
	/** delete data from the table: "voyce.rewards" */
	delete_voyce_rewards?:ModelTypes["voyce_rewards_mutation_response"],
	/** delete single row from the table: "voyce.rewards" */
	delete_voyce_rewards_by_pk?:ModelTypes["voyce_rewards"],
	/** delete data from the table: "voyce.rewards_leaderboard" */
	delete_voyce_rewards_leaderboard?:ModelTypes["voyce_rewards_leaderboard_mutation_response"],
	/** delete single row from the table: "voyce.rewards_leaderboard" */
	delete_voyce_rewards_leaderboard_by_pk?:ModelTypes["voyce_rewards_leaderboard"],
	/** delete data from the table: "voyce.rewards_quest" */
	delete_voyce_rewards_quest?:ModelTypes["voyce_rewards_quest_mutation_response"],
	/** delete single row from the table: "voyce.rewards_quest" */
	delete_voyce_rewards_quest_by_pk?:ModelTypes["voyce_rewards_quest"],
	/** delete data from the table: "voyce.sections" */
	delete_voyce_sections?:ModelTypes["voyce_sections_mutation_response"],
	/** delete single row from the table: "voyce.sections" */
	delete_voyce_sections_by_pk?:ModelTypes["voyce_sections"],
	/** delete data from the table: "voyce.series" */
	delete_voyce_series?:ModelTypes["voyce_series_mutation_response"],
	/** delete single row from the table: "voyce.series" */
	delete_voyce_series_by_pk?:ModelTypes["voyce_series"],
	/** delete data from the table: "voyce.series_collaborators" */
	delete_voyce_series_collaborators?:ModelTypes["voyce_series_collaborators_mutation_response"],
	/** delete single row from the table: "voyce.series_collaborators" */
	delete_voyce_series_collaborators_by_pk?:ModelTypes["voyce_series_collaborators"],
	/** delete data from the table: "voyce.series_donations" */
	delete_voyce_series_donations?:ModelTypes["voyce_series_donations_mutation_response"],
	/** delete single row from the table: "voyce.series_donations" */
	delete_voyce_series_donations_by_pk?:ModelTypes["voyce_series_donations"],
	/** delete data from the table: "voyce.series_favorites" */
	delete_voyce_series_favorites?:ModelTypes["voyce_series_favorites_mutation_response"],
	/** delete single row from the table: "voyce.series_favorites" */
	delete_voyce_series_favorites_by_pk?:ModelTypes["voyce_series_favorites"],
	/** delete data from the table: "voyce.series_featured_panels" */
	delete_voyce_series_featured_panels?:ModelTypes["voyce_series_featured_panels_mutation_response"],
	/** delete single row from the table: "voyce.series_featured_panels" */
	delete_voyce_series_featured_panels_by_pk?:ModelTypes["voyce_series_featured_panels"],
	/** delete data from the table: "voyce.series_genre" */
	delete_voyce_series_genre?:ModelTypes["voyce_series_genre_mutation_response"],
	/** delete single row from the table: "voyce.series_genre" */
	delete_voyce_series_genre_by_pk?:ModelTypes["voyce_series_genre"],
	/** delete data from the table: "voyce.series_giveaway_participants" */
	delete_voyce_series_giveaway_participants?:ModelTypes["voyce_series_giveaway_participants_mutation_response"],
	/** delete single row from the table: "voyce.series_giveaway_participants" */
	delete_voyce_series_giveaway_participants_by_pk?:ModelTypes["voyce_series_giveaway_participants"],
	/** delete data from the table: "voyce.series_hot_panels" */
	delete_voyce_series_hot_panels?:ModelTypes["voyce_series_hot_panels_mutation_response"],
	/** delete single row from the table: "voyce.series_hot_panels" */
	delete_voyce_series_hot_panels_by_pk?:ModelTypes["voyce_series_hot_panels"],
	/** delete data from the table: "voyce.series_layouts" */
	delete_voyce_series_layouts?:ModelTypes["voyce_series_layouts_mutation_response"],
	/** delete single row from the table: "voyce.series_layouts" */
	delete_voyce_series_layouts_by_pk?:ModelTypes["voyce_series_layouts"],
	/** delete data from the table: "voyce.series_leaderboard" */
	delete_voyce_series_leaderboard?:ModelTypes["voyce_series_leaderboard_mutation_response"],
	/** delete single row from the table: "voyce.series_leaderboard" */
	delete_voyce_series_leaderboard_by_pk?:ModelTypes["voyce_series_leaderboard"],
	/** delete data from the table: "voyce.series_lock_config" */
	delete_voyce_series_lock_config?:ModelTypes["voyce_series_lock_config_mutation_response"],
	/** delete single row from the table: "voyce.series_lock_config" */
	delete_voyce_series_lock_config_by_pk?:ModelTypes["voyce_series_lock_config"],
	/** delete data from the table: "voyce.series_sponsorships_configuration" */
	delete_voyce_series_sponsorships_configuration?:ModelTypes["voyce_series_sponsorships_configuration_mutation_response"],
	/** delete single row from the table: "voyce.series_sponsorships_configuration" */
	delete_voyce_series_sponsorships_configuration_by_pk?:ModelTypes["voyce_series_sponsorships_configuration"],
	/** delete data from the table: "voyce.series_subscription" */
	delete_voyce_series_subscription?:ModelTypes["voyce_series_subscription_mutation_response"],
	/** delete single row from the table: "voyce.series_subscription" */
	delete_voyce_series_subscription_by_pk?:ModelTypes["voyce_series_subscription"],
	/** delete data from the table: "voyce.series_tags" */
	delete_voyce_series_tags?:ModelTypes["voyce_series_tags_mutation_response"],
	/** delete single row from the table: "voyce.series_tags" */
	delete_voyce_series_tags_by_pk?:ModelTypes["voyce_series_tags"],
	/** delete data from the table: "voyce.series_tags_junction" */
	delete_voyce_series_tags_junction?:ModelTypes["voyce_series_tags_junction_mutation_response"],
	/** delete single row from the table: "voyce.series_tags_junction" */
	delete_voyce_series_tags_junction_by_pk?:ModelTypes["voyce_series_tags_junction"],
	/** delete data from the table: "voyce.series_top_comments" */
	delete_voyce_series_top_comments?:ModelTypes["voyce_series_top_comments_mutation_response"],
	/** delete single row from the table: "voyce.series_top_comments" */
	delete_voyce_series_top_comments_by_pk?:ModelTypes["voyce_series_top_comments"],
	/** delete data from the table: "voyce.series_top_likes" */
	delete_voyce_series_top_likes?:ModelTypes["voyce_series_top_likes_mutation_response"],
	/** delete single row from the table: "voyce.series_top_likes" */
	delete_voyce_series_top_likes_by_pk?:ModelTypes["voyce_series_top_likes"],
	/** delete data from the table: "voyce.series_top_views" */
	delete_voyce_series_top_views?:ModelTypes["voyce_series_top_views_mutation_response"],
	/** delete single row from the table: "voyce.series_top_views" */
	delete_voyce_series_top_views_by_pk?:ModelTypes["voyce_series_top_views"],
	/** delete data from the table: "voyce.series_views_adjustment" */
	delete_voyce_series_views_adjustment?:ModelTypes["voyce_series_views_adjustment_mutation_response"],
	/** delete single row from the table: "voyce.series_views_adjustment" */
	delete_voyce_series_views_adjustment_by_pk?:ModelTypes["voyce_series_views_adjustment"],
	/** delete data from the table: "voyce.series_votes" */
	delete_voyce_series_votes?:ModelTypes["voyce_series_votes_mutation_response"],
	/** delete single row from the table: "voyce.series_votes" */
	delete_voyce_series_votes_by_pk?:ModelTypes["voyce_series_votes"],
	/** delete data from the table: "voyce.series_votes_count" */
	delete_voyce_series_votes_count?:ModelTypes["voyce_series_votes_count_mutation_response"],
	/** delete single row from the table: "voyce.series_votes_count" */
	delete_voyce_series_votes_count_by_pk?:ModelTypes["voyce_series_votes_count"],
	/** delete data from the table: "voyce.series_voting_schedules" */
	delete_voyce_series_voting_schedules?:ModelTypes["voyce_series_voting_schedules_mutation_response"],
	/** delete single row from the table: "voyce.series_voting_schedules" */
	delete_voyce_series_voting_schedules_by_pk?:ModelTypes["voyce_series_voting_schedules"],
	/** delete data from the table: "voyce.sponsorships" */
	delete_voyce_sponsorships?:ModelTypes["voyce_sponsorships_mutation_response"],
	/** delete single row from the table: "voyce.sponsorships" */
	delete_voyce_sponsorships_by_pk?:ModelTypes["voyce_sponsorships"],
	/** delete data from the table: "voyce.states" */
	delete_voyce_states?:ModelTypes["voyce_states_mutation_response"],
	/** delete single row from the table: "voyce.states" */
	delete_voyce_states_by_pk?:ModelTypes["voyce_states"],
	/** delete data from the table: "voyce.stripe_payouts_setup" */
	delete_voyce_stripe_payouts_setup?:ModelTypes["voyce_stripe_payouts_setup_mutation_response"],
	/** delete single row from the table: "voyce.stripe_payouts_setup" */
	delete_voyce_stripe_payouts_setup_by_pk?:ModelTypes["voyce_stripe_payouts_setup"],
	/** delete data from the table: "voyce.studio" */
	delete_voyce_studio?:ModelTypes["voyce_studio_mutation_response"],
	/** delete single row from the table: "voyce.studio" */
	delete_voyce_studio_by_pk?:ModelTypes["voyce_studio"],
	/** delete data from the table: "voyce.studio_followers" */
	delete_voyce_studio_followers?:ModelTypes["voyce_studio_followers_mutation_response"],
	/** delete single row from the table: "voyce.studio_followers" */
	delete_voyce_studio_followers_by_pk?:ModelTypes["voyce_studio_followers"],
	/** delete data from the table: "voyce.studio_series" */
	delete_voyce_studio_series?:ModelTypes["voyce_studio_series_mutation_response"],
	/** delete single row from the table: "voyce.studio_series" */
	delete_voyce_studio_series_by_pk?:ModelTypes["voyce_studio_series"],
	/** delete data from the table: "voyce.studio_views" */
	delete_voyce_studio_views?:ModelTypes["voyce_studio_views_mutation_response"],
	/** delete single row from the table: "voyce.studio_views" */
	delete_voyce_studio_views_by_pk?:ModelTypes["voyce_studio_views"],
	/** delete data from the table: "voyce.system_settings" */
	delete_voyce_system_settings?:ModelTypes["voyce_system_settings_mutation_response"],
	/** delete single row from the table: "voyce.system_settings" */
	delete_voyce_system_settings_by_pk?:ModelTypes["voyce_system_settings"],
	/** delete data from the table: "voyce.transaction_logs" */
	delete_voyce_transaction_logs?:ModelTypes["voyce_transaction_logs_mutation_response"],
	/** delete single row from the table: "voyce.transaction_logs" */
	delete_voyce_transaction_logs_by_pk?:ModelTypes["voyce_transaction_logs"],
	/** delete data from the table: "voyce.url_redirects" */
	delete_voyce_url_redirects?:ModelTypes["voyce_url_redirects_mutation_response"],
	/** delete single row from the table: "voyce.url_redirects" */
	delete_voyce_url_redirects_by_pk?:ModelTypes["voyce_url_redirects"],
	/** delete data from the table: "voyce.user_addresses" */
	delete_voyce_user_addresses?:ModelTypes["voyce_user_addresses_mutation_response"],
	/** delete single row from the table: "voyce.user_addresses" */
	delete_voyce_user_addresses_by_pk?:ModelTypes["voyce_user_addresses"],
	/** delete data from the table: "voyce.user_blocks" */
	delete_voyce_user_blocks?:ModelTypes["voyce_user_blocks_mutation_response"],
	/** delete single row from the table: "voyce.user_blocks" */
	delete_voyce_user_blocks_by_pk?:ModelTypes["voyce_user_blocks"],
	/** delete data from the table: "voyce.user_chapters_unlocked" */
	delete_voyce_user_chapters_unlocked?:ModelTypes["voyce_user_chapters_unlocked_mutation_response"],
	/** delete single row from the table: "voyce.user_chapters_unlocked" */
	delete_voyce_user_chapters_unlocked_by_pk?:ModelTypes["voyce_user_chapters_unlocked"],
	/** delete data from the table: "voyce.user_contacts" */
	delete_voyce_user_contacts?:ModelTypes["voyce_user_contacts_mutation_response"],
	/** delete single row from the table: "voyce.user_contacts" */
	delete_voyce_user_contacts_by_pk?:ModelTypes["voyce_user_contacts"],
	/** delete data from the table: "voyce.user_csat_ratings" */
	delete_voyce_user_csat_ratings?:ModelTypes["voyce_user_csat_ratings_mutation_response"],
	/** delete single row from the table: "voyce.user_csat_ratings" */
	delete_voyce_user_csat_ratings_by_pk?:ModelTypes["voyce_user_csat_ratings"],
	/** delete data from the table: "voyce.user_education" */
	delete_voyce_user_education?:ModelTypes["voyce_user_education_mutation_response"],
	/** delete single row from the table: "voyce.user_education" */
	delete_voyce_user_education_by_pk?:ModelTypes["voyce_user_education"],
	/** delete data from the table: "voyce.user_level" */
	delete_voyce_user_level?:ModelTypes["voyce_user_level_mutation_response"],
	/** delete single row from the table: "voyce.user_level" */
	delete_voyce_user_level_by_pk?:ModelTypes["voyce_user_level"],
	/** delete data from the table: "voyce.user_linked_accounts" */
	delete_voyce_user_linked_accounts?:ModelTypes["voyce_user_linked_accounts_mutation_response"],
	/** delete single row from the table: "voyce.user_linked_accounts" */
	delete_voyce_user_linked_accounts_by_pk?:ModelTypes["voyce_user_linked_accounts"],
	/** delete data from the table: "voyce.user_login_history" */
	delete_voyce_user_login_history?:ModelTypes["voyce_user_login_history_mutation_response"],
	/** delete single row from the table: "voyce.user_login_history" */
	delete_voyce_user_login_history_by_pk?:ModelTypes["voyce_user_login_history"],
	/** delete data from the table: "voyce.user_quest_ids" */
	delete_voyce_user_quest_ids?:ModelTypes["voyce_user_quest_ids_mutation_response"],
	/** delete single row from the table: "voyce.user_quest_ids" */
	delete_voyce_user_quest_ids_by_pk?:ModelTypes["voyce_user_quest_ids"],
	/** delete data from the table: "voyce.user_quest_rewards" */
	delete_voyce_user_quest_rewards?:ModelTypes["voyce_user_quest_rewards_mutation_response"],
	/** delete single row from the table: "voyce.user_quest_rewards" */
	delete_voyce_user_quest_rewards_by_pk?:ModelTypes["voyce_user_quest_rewards"],
	/** delete data from the table: "voyce.user_quests" */
	delete_voyce_user_quests?:ModelTypes["voyce_user_quests_mutation_response"],
	/** delete single row from the table: "voyce.user_quests" */
	delete_voyce_user_quests_by_pk?:ModelTypes["voyce_user_quests"],
	/** delete data from the table: "voyce.user_quests_old" */
	delete_voyce_user_quests_old?:ModelTypes["voyce_user_quests_old_mutation_response"],
	/** delete single row from the table: "voyce.user_quests_old" */
	delete_voyce_user_quests_old_by_pk?:ModelTypes["voyce_user_quests_old"],
	/** delete data from the table: "voyce.user_ranking" */
	delete_voyce_user_ranking?:ModelTypes["voyce_user_ranking_mutation_response"],
	/** delete single row from the table: "voyce.user_ranking" */
	delete_voyce_user_ranking_by_pk?:ModelTypes["voyce_user_ranking"],
	/** delete data from the table: "voyce.user_reviews" */
	delete_voyce_user_reviews?:ModelTypes["voyce_user_reviews_mutation_response"],
	/** delete single row from the table: "voyce.user_reviews" */
	delete_voyce_user_reviews_by_pk?:ModelTypes["voyce_user_reviews"],
	/** delete data from the table: "voyce.user_rewards" */
	delete_voyce_user_rewards?:ModelTypes["voyce_user_rewards_mutation_response"],
	/** delete single row from the table: "voyce.user_rewards" */
	delete_voyce_user_rewards_by_pk?:ModelTypes["voyce_user_rewards"],
	/** delete data from the table: "voyce.user_roles" */
	delete_voyce_user_roles?:ModelTypes["voyce_user_roles_mutation_response"],
	/** delete single row from the table: "voyce.user_roles" */
	delete_voyce_user_roles_by_pk?:ModelTypes["voyce_user_roles"],
	/** delete data from the table: "voyce.user_settings" */
	delete_voyce_user_settings?:ModelTypes["voyce_user_settings_mutation_response"],
	/** delete single row from the table: "voyce.user_settings" */
	delete_voyce_user_settings_by_pk?:ModelTypes["voyce_user_settings"],
	/** delete data from the table: "voyce.user_skills" */
	delete_voyce_user_skills?:ModelTypes["voyce_user_skills_mutation_response"],
	/** delete single row from the table: "voyce.user_skills" */
	delete_voyce_user_skills_by_pk?:ModelTypes["voyce_user_skills"],
	/** delete data from the table: "voyce.user_socials" */
	delete_voyce_user_socials?:ModelTypes["voyce_user_socials_mutation_response"],
	/** delete single row from the table: "voyce.user_socials" */
	delete_voyce_user_socials_by_pk?:ModelTypes["voyce_user_socials"],
	/** delete data from the table: "voyce.user_soft_deleted" */
	delete_voyce_user_soft_deleted?:ModelTypes["voyce_user_soft_deleted_mutation_response"],
	/** delete single row from the table: "voyce.user_soft_deleted" */
	delete_voyce_user_soft_deleted_by_pk?:ModelTypes["voyce_user_soft_deleted"],
	/** delete data from the table: "voyce.user_vtags" */
	delete_voyce_user_vtags?:ModelTypes["voyce_user_vtags_mutation_response"],
	/** delete single row from the table: "voyce.user_vtags" */
	delete_voyce_user_vtags_by_pk?:ModelTypes["voyce_user_vtags"],
	/** delete data from the table: "voyce.user_wins" */
	delete_voyce_user_wins?:ModelTypes["voyce_user_wins_mutation_response"],
	/** delete single row from the table: "voyce.user_wins" */
	delete_voyce_user_wins_by_pk?:ModelTypes["voyce_user_wins"],
	/** delete data from the table: "voyce.users" */
	delete_voyce_users?:ModelTypes["voyce_users_mutation_response"],
	/** delete single row from the table: "voyce.users" */
	delete_voyce_users_by_pk?:ModelTypes["voyce_users"],
	/** delete data from the table: "voyce.users_followers" */
	delete_voyce_users_followers?:ModelTypes["voyce_users_followers_mutation_response"],
	/** delete single row from the table: "voyce.users_followers" */
	delete_voyce_users_followers_by_pk?:ModelTypes["voyce_users_followers"],
	/** delete data from the table: "voyce.users_private" */
	delete_voyce_users_private?:ModelTypes["voyce_users_private_mutation_response"],
	/** delete data from the table: "voyce.users_series_junction" */
	delete_voyce_users_series_junction?:ModelTypes["voyce_users_series_junction_mutation_response"],
	/** delete single row from the table: "voyce.users_series_junction" */
	delete_voyce_users_series_junction_by_pk?:ModelTypes["voyce_users_series_junction"],
	/** delete data from the table: "voyce.versions" */
	delete_voyce_versions?:ModelTypes["voyce_versions_mutation_response"],
	/** delete single row from the table: "voyce.versions" */
	delete_voyce_versions_by_pk?:ModelTypes["voyce_versions"],
	/** delete data from the table: "voyce.voting_analytics" */
	delete_voyce_voting_analytics?:ModelTypes["voyce_voting_analytics_mutation_response"],
	/** delete single row from the table: "voyce.voting_analytics" */
	delete_voyce_voting_analytics_by_pk?:ModelTypes["voyce_voting_analytics"],
	/** delete data from the table: "voyce.voting_panels" */
	delete_voyce_voting_panels?:ModelTypes["voyce_voting_panels_mutation_response"],
	/** delete single row from the table: "voyce.voting_panels" */
	delete_voyce_voting_panels_by_pk?:ModelTypes["voyce_voting_panels"],
	/** delete data from the table: "voyce.voting_series" */
	delete_voyce_voting_series?:ModelTypes["voyce_voting_series_mutation_response"],
	/** delete single row from the table: "voyce.voting_series" */
	delete_voyce_voting_series_by_pk?:ModelTypes["voyce_voting_series"],
	/** delete data from the table: "voyce.voting_user_votes" */
	delete_voyce_voting_user_votes?:ModelTypes["voyce_voting_user_votes_mutation_response"],
	/** delete single row from the table: "voyce.voting_user_votes" */
	delete_voyce_voting_user_votes_by_pk?:ModelTypes["voyce_voting_user_votes"],
	/** insert data into the table: "awsdms_validation_failures_v1" */
	insert_awsdms_validation_failures_v1?:ModelTypes["awsdms_validation_failures_v1_mutation_response"],
	/** insert a single row into the table: "awsdms_validation_failures_v1" */
	insert_awsdms_validation_failures_v1_one?:ModelTypes["awsdms_validation_failures_v1"],
	/** insert data into the table: "coinsystem.balances" */
	insert_coinsystem_balances?:ModelTypes["coinsystem_balances_mutation_response"],
	/** insert a single row into the table: "coinsystem.balances" */
	insert_coinsystem_balances_one?:ModelTypes["coinsystem_balances"],
	/** insert data into the table: "coinsystem.bonus_coins" */
	insert_coinsystem_bonus_coins?:ModelTypes["coinsystem_bonus_coins_mutation_response"],
	/** insert a single row into the table: "coinsystem.bonus_coins" */
	insert_coinsystem_bonus_coins_one?:ModelTypes["coinsystem_bonus_coins"],
	/** insert data into the table: "coinsystem.coins" */
	insert_coinsystem_coins?:ModelTypes["coinsystem_coins_mutation_response"],
	/** insert a single row into the table: "coinsystem.coins" */
	insert_coinsystem_coins_one?:ModelTypes["coinsystem_coins"],
	/** insert data into the table: "coinsystem.exchange_rates" */
	insert_coinsystem_exchange_rates?:ModelTypes["coinsystem_exchange_rates_mutation_response"],
	/** insert a single row into the table: "coinsystem.exchange_rates" */
	insert_coinsystem_exchange_rates_one?:ModelTypes["coinsystem_exchange_rates"],
	/** insert data into the table: "coinsystem.logs" */
	insert_coinsystem_logs?:ModelTypes["coinsystem_logs_mutation_response"],
	/** insert a single row into the table: "coinsystem.logs" */
	insert_coinsystem_logs_one?:ModelTypes["coinsystem_logs"],
	/** insert data into the table: "coinsystem.transactions" */
	insert_coinsystem_transactions?:ModelTypes["coinsystem_transactions_mutation_response"],
	/** insert a single row into the table: "coinsystem.transactions" */
	insert_coinsystem_transactions_one?:ModelTypes["coinsystem_transactions"],
	/** insert data into the table: "coinsystem.wallets" */
	insert_coinsystem_wallets?:ModelTypes["coinsystem_wallets_mutation_response"],
	/** insert a single row into the table: "coinsystem.wallets" */
	insert_coinsystem_wallets_one?:ModelTypes["coinsystem_wallets"],
	/** insert data into the table: "mysql.user" */
	insert_mysql_user?:ModelTypes["mysql_user_mutation_response"],
	/** insert a single row into the table: "mysql.user" */
	insert_mysql_user_one?:ModelTypes["mysql_user"],
	/** insert data into the table: "store.payments" */
	insert_store_payments?:ModelTypes["store_payments_mutation_response"],
	/** insert a single row into the table: "store.payments" */
	insert_store_payments_one?:ModelTypes["store_payments"],
	/** insert data into the table: "store.products" */
	insert_store_products?:ModelTypes["store_products_mutation_response"],
	/** insert a single row into the table: "store.products" */
	insert_store_products_one?:ModelTypes["store_products"],
	/** insert data into the table: "store.purchases" */
	insert_store_purchases?:ModelTypes["store_purchases_mutation_response"],
	/** insert a single row into the table: "store.purchases" */
	insert_store_purchases_one?:ModelTypes["store_purchases"],
	/** insert data into the table: "store.users" */
	insert_store_users?:ModelTypes["store_users_mutation_response"],
	/** insert a single row into the table: "store.users" */
	insert_store_users_one?:ModelTypes["store_users"],
	/** insert data into the table: "voyce.admin_roles" */
	insert_voyce_admin_roles?:ModelTypes["voyce_admin_roles_mutation_response"],
	/** insert a single row into the table: "voyce.admin_roles" */
	insert_voyce_admin_roles_one?:ModelTypes["voyce_admin_roles"],
	/** insert data into the table: "voyce.app_banners" */
	insert_voyce_app_banners?:ModelTypes["voyce_app_banners_mutation_response"],
	/** insert a single row into the table: "voyce.app_banners" */
	insert_voyce_app_banners_one?:ModelTypes["voyce_app_banners"],
	/** insert data into the table: "voyce.app_components" */
	insert_voyce_app_components?:ModelTypes["voyce_app_components_mutation_response"],
	/** insert a single row into the table: "voyce.app_components" */
	insert_voyce_app_components_one?:ModelTypes["voyce_app_components"],
	/** insert data into the table: "voyce.audit_trail" */
	insert_voyce_audit_trail?:ModelTypes["voyce_audit_trail_mutation_response"],
	/** insert a single row into the table: "voyce.audit_trail" */
	insert_voyce_audit_trail_one?:ModelTypes["voyce_audit_trail"],
	/** insert data into the table: "voyce.author_profiles" */
	insert_voyce_author_profiles?:ModelTypes["voyce_author_profiles_mutation_response"],
	/** insert a single row into the table: "voyce.author_profiles" */
	insert_voyce_author_profiles_one?:ModelTypes["voyce_author_profiles"],
	/** insert data into the table: "voyce.balances" */
	insert_voyce_balances?:ModelTypes["voyce_balances_mutation_response"],
	/** insert a single row into the table: "voyce.balances" */
	insert_voyce_balances_one?:ModelTypes["voyce_balances"],
	/** insert data into the table: "voyce.banners" */
	insert_voyce_banners?:ModelTypes["voyce_banners_mutation_response"],
	/** insert a single row into the table: "voyce.banners" */
	insert_voyce_banners_one?:ModelTypes["voyce_banners"],
	/** insert data into the table: "voyce.chapter_assets" */
	insert_voyce_chapter_assets?:ModelTypes["voyce_chapter_assets_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_assets" */
	insert_voyce_chapter_assets_one?:ModelTypes["voyce_chapter_assets"],
	/** insert data into the table: "voyce.chapter_bookmarks" */
	insert_voyce_chapter_bookmarks?:ModelTypes["voyce_chapter_bookmarks_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_bookmarks" */
	insert_voyce_chapter_bookmarks_one?:ModelTypes["voyce_chapter_bookmarks"],
	/** insert data into the table: "voyce.chapter_comments" */
	insert_voyce_chapter_comments?:ModelTypes["voyce_chapter_comments_mutation_response"],
	/** insert data into the table: "voyce.chapter_comments_07252024" */
	insert_voyce_chapter_comments_07252024?:ModelTypes["voyce_chapter_comments_07252024_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_comments_07252024" */
	insert_voyce_chapter_comments_07252024_one?:ModelTypes["voyce_chapter_comments_07252024"],
	/** insert a single row into the table: "voyce.chapter_comments" */
	insert_voyce_chapter_comments_one?:ModelTypes["voyce_chapter_comments"],
	/** insert data into the table: "voyce.chapter_comments_reaction" */
	insert_voyce_chapter_comments_reaction?:ModelTypes["voyce_chapter_comments_reaction_mutation_response"],
	/** insert data into the table: "voyce.chapter_comments_reaction_count" */
	insert_voyce_chapter_comments_reaction_count?:ModelTypes["voyce_chapter_comments_reaction_count_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_comments_reaction_count" */
	insert_voyce_chapter_comments_reaction_count_one?:ModelTypes["voyce_chapter_comments_reaction_count"],
	/** insert a single row into the table: "voyce.chapter_comments_reaction" */
	insert_voyce_chapter_comments_reaction_one?:ModelTypes["voyce_chapter_comments_reaction"],
	/** insert data into the table: "voyce.chapter_comments_reports" */
	insert_voyce_chapter_comments_reports?:ModelTypes["voyce_chapter_comments_reports_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_comments_reports" */
	insert_voyce_chapter_comments_reports_one?:ModelTypes["voyce_chapter_comments_reports"],
	/** insert data into the table: "voyce.chapter_contents" */
	insert_voyce_chapter_contents?:ModelTypes["voyce_chapter_contents_mutation_response"],
	/** insert data into the table: "voyce.chapter_contents_old" */
	insert_voyce_chapter_contents_old?:ModelTypes["voyce_chapter_contents_old_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_contents_old" */
	insert_voyce_chapter_contents_old_one?:ModelTypes["voyce_chapter_contents_old"],
	/** insert a single row into the table: "voyce.chapter_contents" */
	insert_voyce_chapter_contents_one?:ModelTypes["voyce_chapter_contents"],
	/** insert data into the table: "voyce.chapter_effects" */
	insert_voyce_chapter_effects?:ModelTypes["voyce_chapter_effects_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_effects" */
	insert_voyce_chapter_effects_one?:ModelTypes["voyce_chapter_effects"],
	/** insert data into the table: "voyce.chapter_images" */
	insert_voyce_chapter_images?:ModelTypes["voyce_chapter_images_mutation_response"],
	/** insert data into the table: "voyce.chapter_images_07252024" */
	insert_voyce_chapter_images_07252024?:ModelTypes["voyce_chapter_images_07252024_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_images_07252024" */
	insert_voyce_chapter_images_07252024_one?:ModelTypes["voyce_chapter_images_07252024"],
	/** insert a single row into the table: "voyce.chapter_images" */
	insert_voyce_chapter_images_one?:ModelTypes["voyce_chapter_images"],
	/** insert data into the table: "voyce.chapter_likes" */
	insert_voyce_chapter_likes?:ModelTypes["voyce_chapter_likes_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_likes" */
	insert_voyce_chapter_likes_one?:ModelTypes["voyce_chapter_likes"],
	/** insert data into the table: "voyce.chapter_locks" */
	insert_voyce_chapter_locks?:ModelTypes["voyce_chapter_locks_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_locks" */
	insert_voyce_chapter_locks_one?:ModelTypes["voyce_chapter_locks"],
	/** insert data into the table: "voyce.chapter_panel_views" */
	insert_voyce_chapter_panel_views?:ModelTypes["voyce_chapter_panel_views_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_panel_views" */
	insert_voyce_chapter_panel_views_one?:ModelTypes["voyce_chapter_panel_views"],
	/** insert data into the table: "voyce.chapter_panels" */
	insert_voyce_chapter_panels?:ModelTypes["voyce_chapter_panels_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_panels" */
	insert_voyce_chapter_panels_one?:ModelTypes["voyce_chapter_panels"],
	/** insert data into the table: "voyce.chapter_publish_schedules" */
	insert_voyce_chapter_publish_schedules?:ModelTypes["voyce_chapter_publish_schedules_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_publish_schedules" */
	insert_voyce_chapter_publish_schedules_one?:ModelTypes["voyce_chapter_publish_schedules"],
	/** insert data into the table: "voyce.chapter_reactions" */
	insert_voyce_chapter_reactions?:ModelTypes["voyce_chapter_reactions_mutation_response"],
	/** insert data into the table: "voyce.chapter_reactions_count" */
	insert_voyce_chapter_reactions_count?:ModelTypes["voyce_chapter_reactions_count_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_reactions_count" */
	insert_voyce_chapter_reactions_count_one?:ModelTypes["voyce_chapter_reactions_count"],
	/** insert a single row into the table: "voyce.chapter_reactions" */
	insert_voyce_chapter_reactions_one?:ModelTypes["voyce_chapter_reactions"],
	/** insert data into the table: "voyce.chapter_view_logs" */
	insert_voyce_chapter_view_logs?:ModelTypes["voyce_chapter_view_logs_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_view_logs" */
	insert_voyce_chapter_view_logs_one?:ModelTypes["voyce_chapter_view_logs"],
	/** insert data into the table: "voyce.chapter_views" */
	insert_voyce_chapter_views?:ModelTypes["voyce_chapter_views_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_views" */
	insert_voyce_chapter_views_one?:ModelTypes["voyce_chapter_views"],
	/** insert data into the table: "voyce.chapters" */
	insert_voyce_chapters?:ModelTypes["voyce_chapters_mutation_response"],
	/** insert data into the table: "voyce.chapters_07252024" */
	insert_voyce_chapters_07252024?:ModelTypes["voyce_chapters_07252024_mutation_response"],
	/** insert a single row into the table: "voyce.chapters_07252024" */
	insert_voyce_chapters_07252024_one?:ModelTypes["voyce_chapters_07252024"],
	/** insert a single row into the table: "voyce.chapters" */
	insert_voyce_chapters_one?:ModelTypes["voyce_chapters"],
	/** insert data into the table: "voyce.collaborator_roles" */
	insert_voyce_collaborator_roles?:ModelTypes["voyce_collaborator_roles_mutation_response"],
	/** insert a single row into the table: "voyce.collaborator_roles" */
	insert_voyce_collaborator_roles_one?:ModelTypes["voyce_collaborator_roles"],
	/** insert data into the table: "voyce.comic_types" */
	insert_voyce_comic_types?:ModelTypes["voyce_comic_types_mutation_response"],
	/** insert a single row into the table: "voyce.comic_types" */
	insert_voyce_comic_types_one?:ModelTypes["voyce_comic_types"],
	/** insert data into the table: "voyce.community_blurbs" */
	insert_voyce_community_blurbs?:ModelTypes["voyce_community_blurbs_mutation_response"],
	/** insert a single row into the table: "voyce.community_blurbs" */
	insert_voyce_community_blurbs_one?:ModelTypes["voyce_community_blurbs"],
	/** insert data into the table: "voyce.contact_us" */
	insert_voyce_contact_us?:ModelTypes["voyce_contact_us_mutation_response"],
	/** insert a single row into the table: "voyce.contact_us" */
	insert_voyce_contact_us_one?:ModelTypes["voyce_contact_us"],
	/** insert data into the table: "voyce.content_report" */
	insert_voyce_content_report?:ModelTypes["voyce_content_report_mutation_response"],
	/** insert a single row into the table: "voyce.content_report" */
	insert_voyce_content_report_one?:ModelTypes["voyce_content_report"],
	/** insert data into the table: "voyce.contest_data" */
	insert_voyce_contest_data?:ModelTypes["voyce_contest_data_mutation_response"],
	/** insert a single row into the table: "voyce.contest_data" */
	insert_voyce_contest_data_one?:ModelTypes["voyce_contest_data"],
	/** insert data into the table: "voyce.countries" */
	insert_voyce_countries?:ModelTypes["voyce_countries_mutation_response"],
	/** insert a single row into the table: "voyce.countries" */
	insert_voyce_countries_one?:ModelTypes["voyce_countries"],
	/** insert data into the table: "voyce.csat_ratings" */
	insert_voyce_csat_ratings?:ModelTypes["voyce_csat_ratings_mutation_response"],
	/** insert data into the table: "voyce.csat_ratings_data" */
	insert_voyce_csat_ratings_data?:ModelTypes["voyce_csat_ratings_data_mutation_response"],
	/** insert a single row into the table: "voyce.csat_ratings_data" */
	insert_voyce_csat_ratings_data_one?:ModelTypes["voyce_csat_ratings_data"],
	/** insert a single row into the table: "voyce.csat_ratings" */
	insert_voyce_csat_ratings_one?:ModelTypes["voyce_csat_ratings"],
	/** insert data into the table: "voyce.education_types" */
	insert_voyce_education_types?:ModelTypes["voyce_education_types_mutation_response"],
	/** insert a single row into the table: "voyce.education_types" */
	insert_voyce_education_types_one?:ModelTypes["voyce_education_types"],
	/** insert data into the table: "voyce.erp_login_roles" */
	insert_voyce_erp_login_roles?:ModelTypes["voyce_erp_login_roles_mutation_response"],
	/** insert a single row into the table: "voyce.erp_login_roles" */
	insert_voyce_erp_login_roles_one?:ModelTypes["voyce_erp_login_roles"],
	/** insert data into the table: "voyce.etl_series_views_logs" */
	insert_voyce_etl_series_views_logs?:ModelTypes["voyce_etl_series_views_logs_mutation_response"],
	/** insert a single row into the table: "voyce.etl_series_views_logs" */
	insert_voyce_etl_series_views_logs_one?:ModelTypes["voyce_etl_series_views_logs"],
	/** insert data into the table: "voyce.event_schedules" */
	insert_voyce_event_schedules?:ModelTypes["voyce_event_schedules_mutation_response"],
	/** insert a single row into the table: "voyce.event_schedules" */
	insert_voyce_event_schedules_one?:ModelTypes["voyce_event_schedules"],
	/** insert data into the table: "voyce.genres" */
	insert_voyce_genres?:ModelTypes["voyce_genres_mutation_response"],
	/** insert a single row into the table: "voyce.genres" */
	insert_voyce_genres_one?:ModelTypes["voyce_genres"],
	/** insert data into the table: "voyce.guest_gtags" */
	insert_voyce_guest_gtags?:ModelTypes["voyce_guest_gtags_mutation_response"],
	/** insert a single row into the table: "voyce.guest_gtags" */
	insert_voyce_guest_gtags_one?:ModelTypes["voyce_guest_gtags"],
	/** insert data into the table: "voyce.metabases" */
	insert_voyce_metabases?:ModelTypes["voyce_metabases_mutation_response"],
	/** insert data into the table: "voyce.metabases_access" */
	insert_voyce_metabases_access?:ModelTypes["voyce_metabases_access_mutation_response"],
	/** insert a single row into the table: "voyce.metabases_access" */
	insert_voyce_metabases_access_one?:ModelTypes["voyce_metabases_access"],
	/** insert a single row into the table: "voyce.metabases" */
	insert_voyce_metabases_one?:ModelTypes["voyce_metabases"],
	/** insert data into the table: "voyce.newsletter" */
	insert_voyce_newsletter?:ModelTypes["voyce_newsletter_mutation_response"],
	/** insert a single row into the table: "voyce.newsletter" */
	insert_voyce_newsletter_one?:ModelTypes["voyce_newsletter"],
	/** insert data into the table: "voyce.notification_series_settings" */
	insert_voyce_notification_series_settings?:ModelTypes["voyce_notification_series_settings_mutation_response"],
	/** insert a single row into the table: "voyce.notification_series_settings" */
	insert_voyce_notification_series_settings_one?:ModelTypes["voyce_notification_series_settings"],
	/** insert data into the table: "voyce.notification_settings" */
	insert_voyce_notification_settings?:ModelTypes["voyce_notification_settings_mutation_response"],
	/** insert a single row into the table: "voyce.notification_settings" */
	insert_voyce_notification_settings_one?:ModelTypes["voyce_notification_settings"],
	/** insert data into the table: "voyce.notifications" */
	insert_voyce_notifications?:ModelTypes["voyce_notifications_mutation_response"],
	/** insert a single row into the table: "voyce.notifications" */
	insert_voyce_notifications_one?:ModelTypes["voyce_notifications"],
	/** insert data into the table: "voyce.page_banners" */
	insert_voyce_page_banners?:ModelTypes["voyce_page_banners_mutation_response"],
	/** insert a single row into the table: "voyce.page_banners" */
	insert_voyce_page_banners_one?:ModelTypes["voyce_page_banners"],
	/** insert data into the table: "voyce.page_components" */
	insert_voyce_page_components?:ModelTypes["voyce_page_components_mutation_response"],
	/** insert a single row into the table: "voyce.page_components" */
	insert_voyce_page_components_one?:ModelTypes["voyce_page_components"],
	/** insert data into the table: "voyce.pages" */
	insert_voyce_pages?:ModelTypes["voyce_pages_mutation_response"],
	/** insert a single row into the table: "voyce.pages" */
	insert_voyce_pages_one?:ModelTypes["voyce_pages"],
	/** insert data into the table: "voyce.panels_comments" */
	insert_voyce_panels_comments?:ModelTypes["voyce_panels_comments_mutation_response"],
	/** insert a single row into the table: "voyce.panels_comments" */
	insert_voyce_panels_comments_one?:ModelTypes["voyce_panels_comments"],
	/** insert data into the table: "voyce.panels_reactions" */
	insert_voyce_panels_reactions?:ModelTypes["voyce_panels_reactions_mutation_response"],
	/** insert data into the table: "voyce.panels_reactions_count" */
	insert_voyce_panels_reactions_count?:ModelTypes["voyce_panels_reactions_count_mutation_response"],
	/** insert a single row into the table: "voyce.panels_reactions_count" */
	insert_voyce_panels_reactions_count_one?:ModelTypes["voyce_panels_reactions_count"],
	/** insert a single row into the table: "voyce.panels_reactions" */
	insert_voyce_panels_reactions_one?:ModelTypes["voyce_panels_reactions"],
	/** insert data into the table: "voyce.payments_to_creators" */
	insert_voyce_payments_to_creators?:ModelTypes["voyce_payments_to_creators_mutation_response"],
	/** insert a single row into the table: "voyce.payments_to_creators" */
	insert_voyce_payments_to_creators_one?:ModelTypes["voyce_payments_to_creators"],
	/** insert data into the table: "voyce.pg_rs_series_views_count" */
	insert_voyce_pg_rs_series_views_count?:ModelTypes["voyce_pg_rs_series_views_count_mutation_response"],
	/** insert a single row into the table: "voyce.pg_rs_series_views_count" */
	insert_voyce_pg_rs_series_views_count_one?:ModelTypes["voyce_pg_rs_series_views_count"],
	/** insert data into the table: "voyce.polls" */
	insert_voyce_polls?:ModelTypes["voyce_polls_mutation_response"],
	/** insert data into the table: "voyce.polls_answers" */
	insert_voyce_polls_answers?:ModelTypes["voyce_polls_answers_mutation_response"],
	/** insert a single row into the table: "voyce.polls_answers" */
	insert_voyce_polls_answers_one?:ModelTypes["voyce_polls_answers"],
	/** insert data into the table: "voyce.polls_comments" */
	insert_voyce_polls_comments?:ModelTypes["voyce_polls_comments_mutation_response"],
	/** insert a single row into the table: "voyce.polls_comments" */
	insert_voyce_polls_comments_one?:ModelTypes["voyce_polls_comments"],
	/** insert a single row into the table: "voyce.polls" */
	insert_voyce_polls_one?:ModelTypes["voyce_polls"],
	/** insert data into the table: "voyce.polls_reactions" */
	insert_voyce_polls_reactions?:ModelTypes["voyce_polls_reactions_mutation_response"],
	/** insert a single row into the table: "voyce.polls_reactions" */
	insert_voyce_polls_reactions_one?:ModelTypes["voyce_polls_reactions"],
	/** insert data into the table: "voyce.polls_votes" */
	insert_voyce_polls_votes?:ModelTypes["voyce_polls_votes_mutation_response"],
	/** insert a single row into the table: "voyce.polls_votes" */
	insert_voyce_polls_votes_one?:ModelTypes["voyce_polls_votes"],
	/** insert data into the table: "voyce.popup_components" */
	insert_voyce_popup_components?:ModelTypes["voyce_popup_components_mutation_response"],
	/** insert a single row into the table: "voyce.popup_components" */
	insert_voyce_popup_components_one?:ModelTypes["voyce_popup_components"],
	/** insert data into the table: "voyce.popup_emails" */
	insert_voyce_popup_emails?:ModelTypes["voyce_popup_emails_mutation_response"],
	/** insert a single row into the table: "voyce.popup_emails" */
	insert_voyce_popup_emails_one?:ModelTypes["voyce_popup_emails"],
	/** insert data into the table: "voyce.posts" */
	insert_voyce_posts?:ModelTypes["voyce_posts_mutation_response"],
	/** insert data into the table: "voyce.posts_comments" */
	insert_voyce_posts_comments?:ModelTypes["voyce_posts_comments_mutation_response"],
	/** insert a single row into the table: "voyce.posts_comments" */
	insert_voyce_posts_comments_one?:ModelTypes["voyce_posts_comments"],
	/** insert a single row into the table: "voyce.posts" */
	insert_voyce_posts_one?:ModelTypes["voyce_posts"],
	/** insert data into the table: "voyce.posts_reactions" */
	insert_voyce_posts_reactions?:ModelTypes["voyce_posts_reactions_mutation_response"],
	/** insert a single row into the table: "voyce.posts_reactions" */
	insert_voyce_posts_reactions_one?:ModelTypes["voyce_posts_reactions"],
	/** insert data into the table: "voyce.quest_difficulties" */
	insert_voyce_quest_difficulties?:ModelTypes["voyce_quest_difficulties_mutation_response"],
	/** insert a single row into the table: "voyce.quest_difficulties" */
	insert_voyce_quest_difficulties_one?:ModelTypes["voyce_quest_difficulties"],
	/** insert data into the table: "voyce.quests" */
	insert_voyce_quests?:ModelTypes["voyce_quests_mutation_response"],
	/** insert data into the table: "voyce.quests_old" */
	insert_voyce_quests_old?:ModelTypes["voyce_quests_old_mutation_response"],
	/** insert a single row into the table: "voyce.quests_old" */
	insert_voyce_quests_old_one?:ModelTypes["voyce_quests_old"],
	/** insert a single row into the table: "voyce.quests" */
	insert_voyce_quests_one?:ModelTypes["voyce_quests"],
	/** insert data into the table: "voyce.recommended_series" */
	insert_voyce_recommended_series?:ModelTypes["voyce_recommended_series_mutation_response"],
	/** insert a single row into the table: "voyce.recommended_series" */
	insert_voyce_recommended_series_one?:ModelTypes["voyce_recommended_series"],
	/** insert data into the table: "voyce.release_schedules" */
	insert_voyce_release_schedules?:ModelTypes["voyce_release_schedules_mutation_response"],
	/** insert a single row into the table: "voyce.release_schedules" */
	insert_voyce_release_schedules_one?:ModelTypes["voyce_release_schedules"],
	/** insert data into the table: "voyce.rewards" */
	insert_voyce_rewards?:ModelTypes["voyce_rewards_mutation_response"],
	/** insert data into the table: "voyce.rewards_leaderboard" */
	insert_voyce_rewards_leaderboard?:ModelTypes["voyce_rewards_leaderboard_mutation_response"],
	/** insert a single row into the table: "voyce.rewards_leaderboard" */
	insert_voyce_rewards_leaderboard_one?:ModelTypes["voyce_rewards_leaderboard"],
	/** insert a single row into the table: "voyce.rewards" */
	insert_voyce_rewards_one?:ModelTypes["voyce_rewards"],
	/** insert data into the table: "voyce.rewards_quest" */
	insert_voyce_rewards_quest?:ModelTypes["voyce_rewards_quest_mutation_response"],
	/** insert a single row into the table: "voyce.rewards_quest" */
	insert_voyce_rewards_quest_one?:ModelTypes["voyce_rewards_quest"],
	/** insert data into the table: "voyce.sections" */
	insert_voyce_sections?:ModelTypes["voyce_sections_mutation_response"],
	/** insert a single row into the table: "voyce.sections" */
	insert_voyce_sections_one?:ModelTypes["voyce_sections"],
	/** insert data into the table: "voyce.series" */
	insert_voyce_series?:ModelTypes["voyce_series_mutation_response"],
	/** insert data into the table: "voyce.series_collaborators" */
	insert_voyce_series_collaborators?:ModelTypes["voyce_series_collaborators_mutation_response"],
	/** insert a single row into the table: "voyce.series_collaborators" */
	insert_voyce_series_collaborators_one?:ModelTypes["voyce_series_collaborators"],
	/** insert data into the table: "voyce.series_donations" */
	insert_voyce_series_donations?:ModelTypes["voyce_series_donations_mutation_response"],
	/** insert a single row into the table: "voyce.series_donations" */
	insert_voyce_series_donations_one?:ModelTypes["voyce_series_donations"],
	/** insert data into the table: "voyce.series_favorites" */
	insert_voyce_series_favorites?:ModelTypes["voyce_series_favorites_mutation_response"],
	/** insert a single row into the table: "voyce.series_favorites" */
	insert_voyce_series_favorites_one?:ModelTypes["voyce_series_favorites"],
	/** insert data into the table: "voyce.series_featured_panels" */
	insert_voyce_series_featured_panels?:ModelTypes["voyce_series_featured_panels_mutation_response"],
	/** insert a single row into the table: "voyce.series_featured_panels" */
	insert_voyce_series_featured_panels_one?:ModelTypes["voyce_series_featured_panels"],
	/** insert data into the table: "voyce.series_genre" */
	insert_voyce_series_genre?:ModelTypes["voyce_series_genre_mutation_response"],
	/** insert a single row into the table: "voyce.series_genre" */
	insert_voyce_series_genre_one?:ModelTypes["voyce_series_genre"],
	/** insert data into the table: "voyce.series_giveaway_participants" */
	insert_voyce_series_giveaway_participants?:ModelTypes["voyce_series_giveaway_participants_mutation_response"],
	/** insert a single row into the table: "voyce.series_giveaway_participants" */
	insert_voyce_series_giveaway_participants_one?:ModelTypes["voyce_series_giveaway_participants"],
	/** insert data into the table: "voyce.series_hot_panels" */
	insert_voyce_series_hot_panels?:ModelTypes["voyce_series_hot_panels_mutation_response"],
	/** insert a single row into the table: "voyce.series_hot_panels" */
	insert_voyce_series_hot_panels_one?:ModelTypes["voyce_series_hot_panels"],
	/** insert data into the table: "voyce.series_layouts" */
	insert_voyce_series_layouts?:ModelTypes["voyce_series_layouts_mutation_response"],
	/** insert a single row into the table: "voyce.series_layouts" */
	insert_voyce_series_layouts_one?:ModelTypes["voyce_series_layouts"],
	/** insert data into the table: "voyce.series_leaderboard" */
	insert_voyce_series_leaderboard?:ModelTypes["voyce_series_leaderboard_mutation_response"],
	/** insert a single row into the table: "voyce.series_leaderboard" */
	insert_voyce_series_leaderboard_one?:ModelTypes["voyce_series_leaderboard"],
	/** insert data into the table: "voyce.series_lock_config" */
	insert_voyce_series_lock_config?:ModelTypes["voyce_series_lock_config_mutation_response"],
	/** insert a single row into the table: "voyce.series_lock_config" */
	insert_voyce_series_lock_config_one?:ModelTypes["voyce_series_lock_config"],
	/** insert a single row into the table: "voyce.series" */
	insert_voyce_series_one?:ModelTypes["voyce_series"],
	/** insert data into the table: "voyce.series_sponsorships_configuration" */
	insert_voyce_series_sponsorships_configuration?:ModelTypes["voyce_series_sponsorships_configuration_mutation_response"],
	/** insert a single row into the table: "voyce.series_sponsorships_configuration" */
	insert_voyce_series_sponsorships_configuration_one?:ModelTypes["voyce_series_sponsorships_configuration"],
	/** insert data into the table: "voyce.series_subscription" */
	insert_voyce_series_subscription?:ModelTypes["voyce_series_subscription_mutation_response"],
	/** insert a single row into the table: "voyce.series_subscription" */
	insert_voyce_series_subscription_one?:ModelTypes["voyce_series_subscription"],
	/** insert data into the table: "voyce.series_tags" */
	insert_voyce_series_tags?:ModelTypes["voyce_series_tags_mutation_response"],
	/** insert data into the table: "voyce.series_tags_junction" */
	insert_voyce_series_tags_junction?:ModelTypes["voyce_series_tags_junction_mutation_response"],
	/** insert a single row into the table: "voyce.series_tags_junction" */
	insert_voyce_series_tags_junction_one?:ModelTypes["voyce_series_tags_junction"],
	/** insert a single row into the table: "voyce.series_tags" */
	insert_voyce_series_tags_one?:ModelTypes["voyce_series_tags"],
	/** insert data into the table: "voyce.series_top_comments" */
	insert_voyce_series_top_comments?:ModelTypes["voyce_series_top_comments_mutation_response"],
	/** insert a single row into the table: "voyce.series_top_comments" */
	insert_voyce_series_top_comments_one?:ModelTypes["voyce_series_top_comments"],
	/** insert data into the table: "voyce.series_top_likes" */
	insert_voyce_series_top_likes?:ModelTypes["voyce_series_top_likes_mutation_response"],
	/** insert a single row into the table: "voyce.series_top_likes" */
	insert_voyce_series_top_likes_one?:ModelTypes["voyce_series_top_likes"],
	/** insert data into the table: "voyce.series_top_views" */
	insert_voyce_series_top_views?:ModelTypes["voyce_series_top_views_mutation_response"],
	/** insert a single row into the table: "voyce.series_top_views" */
	insert_voyce_series_top_views_one?:ModelTypes["voyce_series_top_views"],
	/** insert data into the table: "voyce.series_views_adjustment" */
	insert_voyce_series_views_adjustment?:ModelTypes["voyce_series_views_adjustment_mutation_response"],
	/** insert a single row into the table: "voyce.series_views_adjustment" */
	insert_voyce_series_views_adjustment_one?:ModelTypes["voyce_series_views_adjustment"],
	/** insert data into the table: "voyce.series_votes" */
	insert_voyce_series_votes?:ModelTypes["voyce_series_votes_mutation_response"],
	/** insert data into the table: "voyce.series_votes_count" */
	insert_voyce_series_votes_count?:ModelTypes["voyce_series_votes_count_mutation_response"],
	/** insert a single row into the table: "voyce.series_votes_count" */
	insert_voyce_series_votes_count_one?:ModelTypes["voyce_series_votes_count"],
	/** insert a single row into the table: "voyce.series_votes" */
	insert_voyce_series_votes_one?:ModelTypes["voyce_series_votes"],
	/** insert data into the table: "voyce.series_voting_schedules" */
	insert_voyce_series_voting_schedules?:ModelTypes["voyce_series_voting_schedules_mutation_response"],
	/** insert a single row into the table: "voyce.series_voting_schedules" */
	insert_voyce_series_voting_schedules_one?:ModelTypes["voyce_series_voting_schedules"],
	/** insert data into the table: "voyce.sponsorships" */
	insert_voyce_sponsorships?:ModelTypes["voyce_sponsorships_mutation_response"],
	/** insert a single row into the table: "voyce.sponsorships" */
	insert_voyce_sponsorships_one?:ModelTypes["voyce_sponsorships"],
	/** insert data into the table: "voyce.states" */
	insert_voyce_states?:ModelTypes["voyce_states_mutation_response"],
	/** insert a single row into the table: "voyce.states" */
	insert_voyce_states_one?:ModelTypes["voyce_states"],
	/** insert data into the table: "voyce.stripe_payouts_setup" */
	insert_voyce_stripe_payouts_setup?:ModelTypes["voyce_stripe_payouts_setup_mutation_response"],
	/** insert a single row into the table: "voyce.stripe_payouts_setup" */
	insert_voyce_stripe_payouts_setup_one?:ModelTypes["voyce_stripe_payouts_setup"],
	/** insert data into the table: "voyce.studio" */
	insert_voyce_studio?:ModelTypes["voyce_studio_mutation_response"],
	/** insert data into the table: "voyce.studio_followers" */
	insert_voyce_studio_followers?:ModelTypes["voyce_studio_followers_mutation_response"],
	/** insert a single row into the table: "voyce.studio_followers" */
	insert_voyce_studio_followers_one?:ModelTypes["voyce_studio_followers"],
	/** insert a single row into the table: "voyce.studio" */
	insert_voyce_studio_one?:ModelTypes["voyce_studio"],
	/** insert data into the table: "voyce.studio_series" */
	insert_voyce_studio_series?:ModelTypes["voyce_studio_series_mutation_response"],
	/** insert a single row into the table: "voyce.studio_series" */
	insert_voyce_studio_series_one?:ModelTypes["voyce_studio_series"],
	/** insert data into the table: "voyce.studio_views" */
	insert_voyce_studio_views?:ModelTypes["voyce_studio_views_mutation_response"],
	/** insert a single row into the table: "voyce.studio_views" */
	insert_voyce_studio_views_one?:ModelTypes["voyce_studio_views"],
	/** insert data into the table: "voyce.system_settings" */
	insert_voyce_system_settings?:ModelTypes["voyce_system_settings_mutation_response"],
	/** insert a single row into the table: "voyce.system_settings" */
	insert_voyce_system_settings_one?:ModelTypes["voyce_system_settings"],
	/** insert data into the table: "voyce.transaction_logs" */
	insert_voyce_transaction_logs?:ModelTypes["voyce_transaction_logs_mutation_response"],
	/** insert a single row into the table: "voyce.transaction_logs" */
	insert_voyce_transaction_logs_one?:ModelTypes["voyce_transaction_logs"],
	/** insert data into the table: "voyce.url_redirects" */
	insert_voyce_url_redirects?:ModelTypes["voyce_url_redirects_mutation_response"],
	/** insert a single row into the table: "voyce.url_redirects" */
	insert_voyce_url_redirects_one?:ModelTypes["voyce_url_redirects"],
	/** insert data into the table: "voyce.user_addresses" */
	insert_voyce_user_addresses?:ModelTypes["voyce_user_addresses_mutation_response"],
	/** insert a single row into the table: "voyce.user_addresses" */
	insert_voyce_user_addresses_one?:ModelTypes["voyce_user_addresses"],
	/** insert data into the table: "voyce.user_blocks" */
	insert_voyce_user_blocks?:ModelTypes["voyce_user_blocks_mutation_response"],
	/** insert a single row into the table: "voyce.user_blocks" */
	insert_voyce_user_blocks_one?:ModelTypes["voyce_user_blocks"],
	/** insert data into the table: "voyce.user_chapters_unlocked" */
	insert_voyce_user_chapters_unlocked?:ModelTypes["voyce_user_chapters_unlocked_mutation_response"],
	/** insert a single row into the table: "voyce.user_chapters_unlocked" */
	insert_voyce_user_chapters_unlocked_one?:ModelTypes["voyce_user_chapters_unlocked"],
	/** insert data into the table: "voyce.user_contacts" */
	insert_voyce_user_contacts?:ModelTypes["voyce_user_contacts_mutation_response"],
	/** insert a single row into the table: "voyce.user_contacts" */
	insert_voyce_user_contacts_one?:ModelTypes["voyce_user_contacts"],
	/** insert data into the table: "voyce.user_csat_ratings" */
	insert_voyce_user_csat_ratings?:ModelTypes["voyce_user_csat_ratings_mutation_response"],
	/** insert a single row into the table: "voyce.user_csat_ratings" */
	insert_voyce_user_csat_ratings_one?:ModelTypes["voyce_user_csat_ratings"],
	/** insert data into the table: "voyce.user_education" */
	insert_voyce_user_education?:ModelTypes["voyce_user_education_mutation_response"],
	/** insert a single row into the table: "voyce.user_education" */
	insert_voyce_user_education_one?:ModelTypes["voyce_user_education"],
	/** insert data into the table: "voyce.user_level" */
	insert_voyce_user_level?:ModelTypes["voyce_user_level_mutation_response"],
	/** insert a single row into the table: "voyce.user_level" */
	insert_voyce_user_level_one?:ModelTypes["voyce_user_level"],
	/** insert data into the table: "voyce.user_linked_accounts" */
	insert_voyce_user_linked_accounts?:ModelTypes["voyce_user_linked_accounts_mutation_response"],
	/** insert a single row into the table: "voyce.user_linked_accounts" */
	insert_voyce_user_linked_accounts_one?:ModelTypes["voyce_user_linked_accounts"],
	/** insert data into the table: "voyce.user_login_history" */
	insert_voyce_user_login_history?:ModelTypes["voyce_user_login_history_mutation_response"],
	/** insert a single row into the table: "voyce.user_login_history" */
	insert_voyce_user_login_history_one?:ModelTypes["voyce_user_login_history"],
	/** insert data into the table: "voyce.user_quest_ids" */
	insert_voyce_user_quest_ids?:ModelTypes["voyce_user_quest_ids_mutation_response"],
	/** insert a single row into the table: "voyce.user_quest_ids" */
	insert_voyce_user_quest_ids_one?:ModelTypes["voyce_user_quest_ids"],
	/** insert data into the table: "voyce.user_quest_rewards" */
	insert_voyce_user_quest_rewards?:ModelTypes["voyce_user_quest_rewards_mutation_response"],
	/** insert a single row into the table: "voyce.user_quest_rewards" */
	insert_voyce_user_quest_rewards_one?:ModelTypes["voyce_user_quest_rewards"],
	/** insert data into the table: "voyce.user_quests" */
	insert_voyce_user_quests?:ModelTypes["voyce_user_quests_mutation_response"],
	/** insert data into the table: "voyce.user_quests_old" */
	insert_voyce_user_quests_old?:ModelTypes["voyce_user_quests_old_mutation_response"],
	/** insert a single row into the table: "voyce.user_quests_old" */
	insert_voyce_user_quests_old_one?:ModelTypes["voyce_user_quests_old"],
	/** insert a single row into the table: "voyce.user_quests" */
	insert_voyce_user_quests_one?:ModelTypes["voyce_user_quests"],
	/** insert data into the table: "voyce.user_ranking" */
	insert_voyce_user_ranking?:ModelTypes["voyce_user_ranking_mutation_response"],
	/** insert a single row into the table: "voyce.user_ranking" */
	insert_voyce_user_ranking_one?:ModelTypes["voyce_user_ranking"],
	/** insert data into the table: "voyce.user_reviews" */
	insert_voyce_user_reviews?:ModelTypes["voyce_user_reviews_mutation_response"],
	/** insert a single row into the table: "voyce.user_reviews" */
	insert_voyce_user_reviews_one?:ModelTypes["voyce_user_reviews"],
	/** insert data into the table: "voyce.user_rewards" */
	insert_voyce_user_rewards?:ModelTypes["voyce_user_rewards_mutation_response"],
	/** insert a single row into the table: "voyce.user_rewards" */
	insert_voyce_user_rewards_one?:ModelTypes["voyce_user_rewards"],
	/** insert data into the table: "voyce.user_roles" */
	insert_voyce_user_roles?:ModelTypes["voyce_user_roles_mutation_response"],
	/** insert a single row into the table: "voyce.user_roles" */
	insert_voyce_user_roles_one?:ModelTypes["voyce_user_roles"],
	/** insert data into the table: "voyce.user_settings" */
	insert_voyce_user_settings?:ModelTypes["voyce_user_settings_mutation_response"],
	/** insert a single row into the table: "voyce.user_settings" */
	insert_voyce_user_settings_one?:ModelTypes["voyce_user_settings"],
	/** insert data into the table: "voyce.user_skills" */
	insert_voyce_user_skills?:ModelTypes["voyce_user_skills_mutation_response"],
	/** insert a single row into the table: "voyce.user_skills" */
	insert_voyce_user_skills_one?:ModelTypes["voyce_user_skills"],
	/** insert data into the table: "voyce.user_socials" */
	insert_voyce_user_socials?:ModelTypes["voyce_user_socials_mutation_response"],
	/** insert a single row into the table: "voyce.user_socials" */
	insert_voyce_user_socials_one?:ModelTypes["voyce_user_socials"],
	/** insert data into the table: "voyce.user_soft_deleted" */
	insert_voyce_user_soft_deleted?:ModelTypes["voyce_user_soft_deleted_mutation_response"],
	/** insert a single row into the table: "voyce.user_soft_deleted" */
	insert_voyce_user_soft_deleted_one?:ModelTypes["voyce_user_soft_deleted"],
	/** insert data into the table: "voyce.user_vtags" */
	insert_voyce_user_vtags?:ModelTypes["voyce_user_vtags_mutation_response"],
	/** insert a single row into the table: "voyce.user_vtags" */
	insert_voyce_user_vtags_one?:ModelTypes["voyce_user_vtags"],
	/** insert data into the table: "voyce.user_wins" */
	insert_voyce_user_wins?:ModelTypes["voyce_user_wins_mutation_response"],
	/** insert a single row into the table: "voyce.user_wins" */
	insert_voyce_user_wins_one?:ModelTypes["voyce_user_wins"],
	/** insert data into the table: "voyce.users" */
	insert_voyce_users?:ModelTypes["voyce_users_mutation_response"],
	/** insert data into the table: "voyce.users_followers" */
	insert_voyce_users_followers?:ModelTypes["voyce_users_followers_mutation_response"],
	/** insert a single row into the table: "voyce.users_followers" */
	insert_voyce_users_followers_one?:ModelTypes["voyce_users_followers"],
	/** insert a single row into the table: "voyce.users" */
	insert_voyce_users_one?:ModelTypes["voyce_users"],
	/** insert data into the table: "voyce.users_private" */
	insert_voyce_users_private?:ModelTypes["voyce_users_private_mutation_response"],
	/** insert a single row into the table: "voyce.users_private" */
	insert_voyce_users_private_one?:ModelTypes["voyce_users_private"],
	/** insert data into the table: "voyce.users_series_junction" */
	insert_voyce_users_series_junction?:ModelTypes["voyce_users_series_junction_mutation_response"],
	/** insert a single row into the table: "voyce.users_series_junction" */
	insert_voyce_users_series_junction_one?:ModelTypes["voyce_users_series_junction"],
	/** insert data into the table: "voyce.versions" */
	insert_voyce_versions?:ModelTypes["voyce_versions_mutation_response"],
	/** insert a single row into the table: "voyce.versions" */
	insert_voyce_versions_one?:ModelTypes["voyce_versions"],
	/** insert data into the table: "voyce.voting_analytics" */
	insert_voyce_voting_analytics?:ModelTypes["voyce_voting_analytics_mutation_response"],
	/** insert a single row into the table: "voyce.voting_analytics" */
	insert_voyce_voting_analytics_one?:ModelTypes["voyce_voting_analytics"],
	/** insert data into the table: "voyce.voting_panels" */
	insert_voyce_voting_panels?:ModelTypes["voyce_voting_panels_mutation_response"],
	/** insert a single row into the table: "voyce.voting_panels" */
	insert_voyce_voting_panels_one?:ModelTypes["voyce_voting_panels"],
	/** insert data into the table: "voyce.voting_series" */
	insert_voyce_voting_series?:ModelTypes["voyce_voting_series_mutation_response"],
	/** insert a single row into the table: "voyce.voting_series" */
	insert_voyce_voting_series_one?:ModelTypes["voyce_voting_series"],
	/** insert data into the table: "voyce.voting_user_votes" */
	insert_voyce_voting_user_votes?:ModelTypes["voyce_voting_user_votes_mutation_response"],
	/** insert a single row into the table: "voyce.voting_user_votes" */
	insert_voyce_voting_user_votes_one?:ModelTypes["voyce_voting_user_votes"],
	/** update data of the table: "awsdms_validation_failures_v1" */
	update_awsdms_validation_failures_v1?:ModelTypes["awsdms_validation_failures_v1_mutation_response"],
	/** update multiples rows of table: "awsdms_validation_failures_v1" */
	update_awsdms_validation_failures_v1_many?:(ModelTypes["awsdms_validation_failures_v1_mutation_response"] | undefined)[],
	/** update data of the table: "coinsystem.balances" */
	update_coinsystem_balances?:ModelTypes["coinsystem_balances_mutation_response"],
	/** update single row of the table: "coinsystem.balances" */
	update_coinsystem_balances_by_pk?:ModelTypes["coinsystem_balances"],
	/** update multiples rows of table: "coinsystem.balances" */
	update_coinsystem_balances_many?:(ModelTypes["coinsystem_balances_mutation_response"] | undefined)[],
	/** update data of the table: "coinsystem.bonus_coins" */
	update_coinsystem_bonus_coins?:ModelTypes["coinsystem_bonus_coins_mutation_response"],
	/** update single row of the table: "coinsystem.bonus_coins" */
	update_coinsystem_bonus_coins_by_pk?:ModelTypes["coinsystem_bonus_coins"],
	/** update multiples rows of table: "coinsystem.bonus_coins" */
	update_coinsystem_bonus_coins_many?:(ModelTypes["coinsystem_bonus_coins_mutation_response"] | undefined)[],
	/** update data of the table: "coinsystem.coins" */
	update_coinsystem_coins?:ModelTypes["coinsystem_coins_mutation_response"],
	/** update single row of the table: "coinsystem.coins" */
	update_coinsystem_coins_by_pk?:ModelTypes["coinsystem_coins"],
	/** update multiples rows of table: "coinsystem.coins" */
	update_coinsystem_coins_many?:(ModelTypes["coinsystem_coins_mutation_response"] | undefined)[],
	/** update data of the table: "coinsystem.exchange_rates" */
	update_coinsystem_exchange_rates?:ModelTypes["coinsystem_exchange_rates_mutation_response"],
	/** update single row of the table: "coinsystem.exchange_rates" */
	update_coinsystem_exchange_rates_by_pk?:ModelTypes["coinsystem_exchange_rates"],
	/** update multiples rows of table: "coinsystem.exchange_rates" */
	update_coinsystem_exchange_rates_many?:(ModelTypes["coinsystem_exchange_rates_mutation_response"] | undefined)[],
	/** update data of the table: "coinsystem.logs" */
	update_coinsystem_logs?:ModelTypes["coinsystem_logs_mutation_response"],
	/** update single row of the table: "coinsystem.logs" */
	update_coinsystem_logs_by_pk?:ModelTypes["coinsystem_logs"],
	/** update multiples rows of table: "coinsystem.logs" */
	update_coinsystem_logs_many?:(ModelTypes["coinsystem_logs_mutation_response"] | undefined)[],
	/** update data of the table: "coinsystem.transactions" */
	update_coinsystem_transactions?:ModelTypes["coinsystem_transactions_mutation_response"],
	/** update single row of the table: "coinsystem.transactions" */
	update_coinsystem_transactions_by_pk?:ModelTypes["coinsystem_transactions"],
	/** update multiples rows of table: "coinsystem.transactions" */
	update_coinsystem_transactions_many?:(ModelTypes["coinsystem_transactions_mutation_response"] | undefined)[],
	/** update data of the table: "coinsystem.wallets" */
	update_coinsystem_wallets?:ModelTypes["coinsystem_wallets_mutation_response"],
	/** update single row of the table: "coinsystem.wallets" */
	update_coinsystem_wallets_by_pk?:ModelTypes["coinsystem_wallets"],
	/** update multiples rows of table: "coinsystem.wallets" */
	update_coinsystem_wallets_many?:(ModelTypes["coinsystem_wallets_mutation_response"] | undefined)[],
	/** update data of the table: "mysql.user" */
	update_mysql_user?:ModelTypes["mysql_user_mutation_response"],
	/** update single row of the table: "mysql.user" */
	update_mysql_user_by_pk?:ModelTypes["mysql_user"],
	/** update multiples rows of table: "mysql.user" */
	update_mysql_user_many?:(ModelTypes["mysql_user_mutation_response"] | undefined)[],
	/** update data of the table: "store.payments" */
	update_store_payments?:ModelTypes["store_payments_mutation_response"],
	/** update single row of the table: "store.payments" */
	update_store_payments_by_pk?:ModelTypes["store_payments"],
	/** update multiples rows of table: "store.payments" */
	update_store_payments_many?:(ModelTypes["store_payments_mutation_response"] | undefined)[],
	/** update data of the table: "store.products" */
	update_store_products?:ModelTypes["store_products_mutation_response"],
	/** update single row of the table: "store.products" */
	update_store_products_by_pk?:ModelTypes["store_products"],
	/** update multiples rows of table: "store.products" */
	update_store_products_many?:(ModelTypes["store_products_mutation_response"] | undefined)[],
	/** update data of the table: "store.purchases" */
	update_store_purchases?:ModelTypes["store_purchases_mutation_response"],
	/** update single row of the table: "store.purchases" */
	update_store_purchases_by_pk?:ModelTypes["store_purchases"],
	/** update multiples rows of table: "store.purchases" */
	update_store_purchases_many?:(ModelTypes["store_purchases_mutation_response"] | undefined)[],
	/** update data of the table: "store.users" */
	update_store_users?:ModelTypes["store_users_mutation_response"],
	/** update multiples rows of table: "store.users" */
	update_store_users_many?:(ModelTypes["store_users_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.admin_roles" */
	update_voyce_admin_roles?:ModelTypes["voyce_admin_roles_mutation_response"],
	/** update single row of the table: "voyce.admin_roles" */
	update_voyce_admin_roles_by_pk?:ModelTypes["voyce_admin_roles"],
	/** update multiples rows of table: "voyce.admin_roles" */
	update_voyce_admin_roles_many?:(ModelTypes["voyce_admin_roles_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.app_banners" */
	update_voyce_app_banners?:ModelTypes["voyce_app_banners_mutation_response"],
	/** update single row of the table: "voyce.app_banners" */
	update_voyce_app_banners_by_pk?:ModelTypes["voyce_app_banners"],
	/** update multiples rows of table: "voyce.app_banners" */
	update_voyce_app_banners_many?:(ModelTypes["voyce_app_banners_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.app_components" */
	update_voyce_app_components?:ModelTypes["voyce_app_components_mutation_response"],
	/** update single row of the table: "voyce.app_components" */
	update_voyce_app_components_by_pk?:ModelTypes["voyce_app_components"],
	/** update multiples rows of table: "voyce.app_components" */
	update_voyce_app_components_many?:(ModelTypes["voyce_app_components_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.audit_trail" */
	update_voyce_audit_trail?:ModelTypes["voyce_audit_trail_mutation_response"],
	/** update single row of the table: "voyce.audit_trail" */
	update_voyce_audit_trail_by_pk?:ModelTypes["voyce_audit_trail"],
	/** update multiples rows of table: "voyce.audit_trail" */
	update_voyce_audit_trail_many?:(ModelTypes["voyce_audit_trail_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.author_profiles" */
	update_voyce_author_profiles?:ModelTypes["voyce_author_profiles_mutation_response"],
	/** update single row of the table: "voyce.author_profiles" */
	update_voyce_author_profiles_by_pk?:ModelTypes["voyce_author_profiles"],
	/** update multiples rows of table: "voyce.author_profiles" */
	update_voyce_author_profiles_many?:(ModelTypes["voyce_author_profiles_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.balances" */
	update_voyce_balances?:ModelTypes["voyce_balances_mutation_response"],
	/** update single row of the table: "voyce.balances" */
	update_voyce_balances_by_pk?:ModelTypes["voyce_balances"],
	/** update multiples rows of table: "voyce.balances" */
	update_voyce_balances_many?:(ModelTypes["voyce_balances_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.banners" */
	update_voyce_banners?:ModelTypes["voyce_banners_mutation_response"],
	/** update single row of the table: "voyce.banners" */
	update_voyce_banners_by_pk?:ModelTypes["voyce_banners"],
	/** update multiples rows of table: "voyce.banners" */
	update_voyce_banners_many?:(ModelTypes["voyce_banners_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_assets" */
	update_voyce_chapter_assets?:ModelTypes["voyce_chapter_assets_mutation_response"],
	/** update single row of the table: "voyce.chapter_assets" */
	update_voyce_chapter_assets_by_pk?:ModelTypes["voyce_chapter_assets"],
	/** update multiples rows of table: "voyce.chapter_assets" */
	update_voyce_chapter_assets_many?:(ModelTypes["voyce_chapter_assets_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_bookmarks" */
	update_voyce_chapter_bookmarks?:ModelTypes["voyce_chapter_bookmarks_mutation_response"],
	/** update single row of the table: "voyce.chapter_bookmarks" */
	update_voyce_chapter_bookmarks_by_pk?:ModelTypes["voyce_chapter_bookmarks"],
	/** update multiples rows of table: "voyce.chapter_bookmarks" */
	update_voyce_chapter_bookmarks_many?:(ModelTypes["voyce_chapter_bookmarks_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_comments" */
	update_voyce_chapter_comments?:ModelTypes["voyce_chapter_comments_mutation_response"],
	/** update data of the table: "voyce.chapter_comments_07252024" */
	update_voyce_chapter_comments_07252024?:ModelTypes["voyce_chapter_comments_07252024_mutation_response"],
	/** update multiples rows of table: "voyce.chapter_comments_07252024" */
	update_voyce_chapter_comments_07252024_many?:(ModelTypes["voyce_chapter_comments_07252024_mutation_response"] | undefined)[],
	/** update single row of the table: "voyce.chapter_comments" */
	update_voyce_chapter_comments_by_pk?:ModelTypes["voyce_chapter_comments"],
	/** update multiples rows of table: "voyce.chapter_comments" */
	update_voyce_chapter_comments_many?:(ModelTypes["voyce_chapter_comments_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_comments_reaction" */
	update_voyce_chapter_comments_reaction?:ModelTypes["voyce_chapter_comments_reaction_mutation_response"],
	/** update single row of the table: "voyce.chapter_comments_reaction" */
	update_voyce_chapter_comments_reaction_by_pk?:ModelTypes["voyce_chapter_comments_reaction"],
	/** update data of the table: "voyce.chapter_comments_reaction_count" */
	update_voyce_chapter_comments_reaction_count?:ModelTypes["voyce_chapter_comments_reaction_count_mutation_response"],
	/** update single row of the table: "voyce.chapter_comments_reaction_count" */
	update_voyce_chapter_comments_reaction_count_by_pk?:ModelTypes["voyce_chapter_comments_reaction_count"],
	/** update multiples rows of table: "voyce.chapter_comments_reaction_count" */
	update_voyce_chapter_comments_reaction_count_many?:(ModelTypes["voyce_chapter_comments_reaction_count_mutation_response"] | undefined)[],
	/** update multiples rows of table: "voyce.chapter_comments_reaction" */
	update_voyce_chapter_comments_reaction_many?:(ModelTypes["voyce_chapter_comments_reaction_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_comments_reports" */
	update_voyce_chapter_comments_reports?:ModelTypes["voyce_chapter_comments_reports_mutation_response"],
	/** update single row of the table: "voyce.chapter_comments_reports" */
	update_voyce_chapter_comments_reports_by_pk?:ModelTypes["voyce_chapter_comments_reports"],
	/** update multiples rows of table: "voyce.chapter_comments_reports" */
	update_voyce_chapter_comments_reports_many?:(ModelTypes["voyce_chapter_comments_reports_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_contents" */
	update_voyce_chapter_contents?:ModelTypes["voyce_chapter_contents_mutation_response"],
	/** update single row of the table: "voyce.chapter_contents" */
	update_voyce_chapter_contents_by_pk?:ModelTypes["voyce_chapter_contents"],
	/** update multiples rows of table: "voyce.chapter_contents" */
	update_voyce_chapter_contents_many?:(ModelTypes["voyce_chapter_contents_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_contents_old" */
	update_voyce_chapter_contents_old?:ModelTypes["voyce_chapter_contents_old_mutation_response"],
	/** update single row of the table: "voyce.chapter_contents_old" */
	update_voyce_chapter_contents_old_by_pk?:ModelTypes["voyce_chapter_contents_old"],
	/** update multiples rows of table: "voyce.chapter_contents_old" */
	update_voyce_chapter_contents_old_many?:(ModelTypes["voyce_chapter_contents_old_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_effects" */
	update_voyce_chapter_effects?:ModelTypes["voyce_chapter_effects_mutation_response"],
	/** update single row of the table: "voyce.chapter_effects" */
	update_voyce_chapter_effects_by_pk?:ModelTypes["voyce_chapter_effects"],
	/** update multiples rows of table: "voyce.chapter_effects" */
	update_voyce_chapter_effects_many?:(ModelTypes["voyce_chapter_effects_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_images" */
	update_voyce_chapter_images?:ModelTypes["voyce_chapter_images_mutation_response"],
	/** update data of the table: "voyce.chapter_images_07252024" */
	update_voyce_chapter_images_07252024?:ModelTypes["voyce_chapter_images_07252024_mutation_response"],
	/** update multiples rows of table: "voyce.chapter_images_07252024" */
	update_voyce_chapter_images_07252024_many?:(ModelTypes["voyce_chapter_images_07252024_mutation_response"] | undefined)[],
	/** update single row of the table: "voyce.chapter_images" */
	update_voyce_chapter_images_by_pk?:ModelTypes["voyce_chapter_images"],
	/** update multiples rows of table: "voyce.chapter_images" */
	update_voyce_chapter_images_many?:(ModelTypes["voyce_chapter_images_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_likes" */
	update_voyce_chapter_likes?:ModelTypes["voyce_chapter_likes_mutation_response"],
	/** update single row of the table: "voyce.chapter_likes" */
	update_voyce_chapter_likes_by_pk?:ModelTypes["voyce_chapter_likes"],
	/** update multiples rows of table: "voyce.chapter_likes" */
	update_voyce_chapter_likes_many?:(ModelTypes["voyce_chapter_likes_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_locks" */
	update_voyce_chapter_locks?:ModelTypes["voyce_chapter_locks_mutation_response"],
	/** update single row of the table: "voyce.chapter_locks" */
	update_voyce_chapter_locks_by_pk?:ModelTypes["voyce_chapter_locks"],
	/** update multiples rows of table: "voyce.chapter_locks" */
	update_voyce_chapter_locks_many?:(ModelTypes["voyce_chapter_locks_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_panel_views" */
	update_voyce_chapter_panel_views?:ModelTypes["voyce_chapter_panel_views_mutation_response"],
	/** update single row of the table: "voyce.chapter_panel_views" */
	update_voyce_chapter_panel_views_by_pk?:ModelTypes["voyce_chapter_panel_views"],
	/** update multiples rows of table: "voyce.chapter_panel_views" */
	update_voyce_chapter_panel_views_many?:(ModelTypes["voyce_chapter_panel_views_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_panels" */
	update_voyce_chapter_panels?:ModelTypes["voyce_chapter_panels_mutation_response"],
	/** update single row of the table: "voyce.chapter_panels" */
	update_voyce_chapter_panels_by_pk?:ModelTypes["voyce_chapter_panels"],
	/** update multiples rows of table: "voyce.chapter_panels" */
	update_voyce_chapter_panels_many?:(ModelTypes["voyce_chapter_panels_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_publish_schedules" */
	update_voyce_chapter_publish_schedules?:ModelTypes["voyce_chapter_publish_schedules_mutation_response"],
	/** update single row of the table: "voyce.chapter_publish_schedules" */
	update_voyce_chapter_publish_schedules_by_pk?:ModelTypes["voyce_chapter_publish_schedules"],
	/** update multiples rows of table: "voyce.chapter_publish_schedules" */
	update_voyce_chapter_publish_schedules_many?:(ModelTypes["voyce_chapter_publish_schedules_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_reactions" */
	update_voyce_chapter_reactions?:ModelTypes["voyce_chapter_reactions_mutation_response"],
	/** update single row of the table: "voyce.chapter_reactions" */
	update_voyce_chapter_reactions_by_pk?:ModelTypes["voyce_chapter_reactions"],
	/** update data of the table: "voyce.chapter_reactions_count" */
	update_voyce_chapter_reactions_count?:ModelTypes["voyce_chapter_reactions_count_mutation_response"],
	/** update single row of the table: "voyce.chapter_reactions_count" */
	update_voyce_chapter_reactions_count_by_pk?:ModelTypes["voyce_chapter_reactions_count"],
	/** update multiples rows of table: "voyce.chapter_reactions_count" */
	update_voyce_chapter_reactions_count_many?:(ModelTypes["voyce_chapter_reactions_count_mutation_response"] | undefined)[],
	/** update multiples rows of table: "voyce.chapter_reactions" */
	update_voyce_chapter_reactions_many?:(ModelTypes["voyce_chapter_reactions_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_view_logs" */
	update_voyce_chapter_view_logs?:ModelTypes["voyce_chapter_view_logs_mutation_response"],
	/** update single row of the table: "voyce.chapter_view_logs" */
	update_voyce_chapter_view_logs_by_pk?:ModelTypes["voyce_chapter_view_logs"],
	/** update multiples rows of table: "voyce.chapter_view_logs" */
	update_voyce_chapter_view_logs_many?:(ModelTypes["voyce_chapter_view_logs_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapter_views" */
	update_voyce_chapter_views?:ModelTypes["voyce_chapter_views_mutation_response"],
	/** update single row of the table: "voyce.chapter_views" */
	update_voyce_chapter_views_by_pk?:ModelTypes["voyce_chapter_views"],
	/** update multiples rows of table: "voyce.chapter_views" */
	update_voyce_chapter_views_many?:(ModelTypes["voyce_chapter_views_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.chapters" */
	update_voyce_chapters?:ModelTypes["voyce_chapters_mutation_response"],
	/** update data of the table: "voyce.chapters_07252024" */
	update_voyce_chapters_07252024?:ModelTypes["voyce_chapters_07252024_mutation_response"],
	/** update multiples rows of table: "voyce.chapters_07252024" */
	update_voyce_chapters_07252024_many?:(ModelTypes["voyce_chapters_07252024_mutation_response"] | undefined)[],
	/** update single row of the table: "voyce.chapters" */
	update_voyce_chapters_by_pk?:ModelTypes["voyce_chapters"],
	/** update multiples rows of table: "voyce.chapters" */
	update_voyce_chapters_many?:(ModelTypes["voyce_chapters_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.collaborator_roles" */
	update_voyce_collaborator_roles?:ModelTypes["voyce_collaborator_roles_mutation_response"],
	/** update single row of the table: "voyce.collaborator_roles" */
	update_voyce_collaborator_roles_by_pk?:ModelTypes["voyce_collaborator_roles"],
	/** update multiples rows of table: "voyce.collaborator_roles" */
	update_voyce_collaborator_roles_many?:(ModelTypes["voyce_collaborator_roles_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.comic_types" */
	update_voyce_comic_types?:ModelTypes["voyce_comic_types_mutation_response"],
	/** update single row of the table: "voyce.comic_types" */
	update_voyce_comic_types_by_pk?:ModelTypes["voyce_comic_types"],
	/** update multiples rows of table: "voyce.comic_types" */
	update_voyce_comic_types_many?:(ModelTypes["voyce_comic_types_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.community_blurbs" */
	update_voyce_community_blurbs?:ModelTypes["voyce_community_blurbs_mutation_response"],
	/** update single row of the table: "voyce.community_blurbs" */
	update_voyce_community_blurbs_by_pk?:ModelTypes["voyce_community_blurbs"],
	/** update multiples rows of table: "voyce.community_blurbs" */
	update_voyce_community_blurbs_many?:(ModelTypes["voyce_community_blurbs_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.contact_us" */
	update_voyce_contact_us?:ModelTypes["voyce_contact_us_mutation_response"],
	/** update single row of the table: "voyce.contact_us" */
	update_voyce_contact_us_by_pk?:ModelTypes["voyce_contact_us"],
	/** update multiples rows of table: "voyce.contact_us" */
	update_voyce_contact_us_many?:(ModelTypes["voyce_contact_us_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.content_report" */
	update_voyce_content_report?:ModelTypes["voyce_content_report_mutation_response"],
	/** update single row of the table: "voyce.content_report" */
	update_voyce_content_report_by_pk?:ModelTypes["voyce_content_report"],
	/** update multiples rows of table: "voyce.content_report" */
	update_voyce_content_report_many?:(ModelTypes["voyce_content_report_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.contest_data" */
	update_voyce_contest_data?:ModelTypes["voyce_contest_data_mutation_response"],
	/** update single row of the table: "voyce.contest_data" */
	update_voyce_contest_data_by_pk?:ModelTypes["voyce_contest_data"],
	/** update multiples rows of table: "voyce.contest_data" */
	update_voyce_contest_data_many?:(ModelTypes["voyce_contest_data_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.countries" */
	update_voyce_countries?:ModelTypes["voyce_countries_mutation_response"],
	/** update single row of the table: "voyce.countries" */
	update_voyce_countries_by_pk?:ModelTypes["voyce_countries"],
	/** update multiples rows of table: "voyce.countries" */
	update_voyce_countries_many?:(ModelTypes["voyce_countries_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.csat_ratings" */
	update_voyce_csat_ratings?:ModelTypes["voyce_csat_ratings_mutation_response"],
	/** update single row of the table: "voyce.csat_ratings" */
	update_voyce_csat_ratings_by_pk?:ModelTypes["voyce_csat_ratings"],
	/** update data of the table: "voyce.csat_ratings_data" */
	update_voyce_csat_ratings_data?:ModelTypes["voyce_csat_ratings_data_mutation_response"],
	/** update single row of the table: "voyce.csat_ratings_data" */
	update_voyce_csat_ratings_data_by_pk?:ModelTypes["voyce_csat_ratings_data"],
	/** update multiples rows of table: "voyce.csat_ratings_data" */
	update_voyce_csat_ratings_data_many?:(ModelTypes["voyce_csat_ratings_data_mutation_response"] | undefined)[],
	/** update multiples rows of table: "voyce.csat_ratings" */
	update_voyce_csat_ratings_many?:(ModelTypes["voyce_csat_ratings_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.education_types" */
	update_voyce_education_types?:ModelTypes["voyce_education_types_mutation_response"],
	/** update single row of the table: "voyce.education_types" */
	update_voyce_education_types_by_pk?:ModelTypes["voyce_education_types"],
	/** update multiples rows of table: "voyce.education_types" */
	update_voyce_education_types_many?:(ModelTypes["voyce_education_types_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.erp_login_roles" */
	update_voyce_erp_login_roles?:ModelTypes["voyce_erp_login_roles_mutation_response"],
	/** update single row of the table: "voyce.erp_login_roles" */
	update_voyce_erp_login_roles_by_pk?:ModelTypes["voyce_erp_login_roles"],
	/** update multiples rows of table: "voyce.erp_login_roles" */
	update_voyce_erp_login_roles_many?:(ModelTypes["voyce_erp_login_roles_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.etl_series_views_logs" */
	update_voyce_etl_series_views_logs?:ModelTypes["voyce_etl_series_views_logs_mutation_response"],
	/** update single row of the table: "voyce.etl_series_views_logs" */
	update_voyce_etl_series_views_logs_by_pk?:ModelTypes["voyce_etl_series_views_logs"],
	/** update multiples rows of table: "voyce.etl_series_views_logs" */
	update_voyce_etl_series_views_logs_many?:(ModelTypes["voyce_etl_series_views_logs_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.event_schedules" */
	update_voyce_event_schedules?:ModelTypes["voyce_event_schedules_mutation_response"],
	/** update single row of the table: "voyce.event_schedules" */
	update_voyce_event_schedules_by_pk?:ModelTypes["voyce_event_schedules"],
	/** update multiples rows of table: "voyce.event_schedules" */
	update_voyce_event_schedules_many?:(ModelTypes["voyce_event_schedules_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.genres" */
	update_voyce_genres?:ModelTypes["voyce_genres_mutation_response"],
	/** update single row of the table: "voyce.genres" */
	update_voyce_genres_by_pk?:ModelTypes["voyce_genres"],
	/** update multiples rows of table: "voyce.genres" */
	update_voyce_genres_many?:(ModelTypes["voyce_genres_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.guest_gtags" */
	update_voyce_guest_gtags?:ModelTypes["voyce_guest_gtags_mutation_response"],
	/** update single row of the table: "voyce.guest_gtags" */
	update_voyce_guest_gtags_by_pk?:ModelTypes["voyce_guest_gtags"],
	/** update multiples rows of table: "voyce.guest_gtags" */
	update_voyce_guest_gtags_many?:(ModelTypes["voyce_guest_gtags_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.metabases" */
	update_voyce_metabases?:ModelTypes["voyce_metabases_mutation_response"],
	/** update data of the table: "voyce.metabases_access" */
	update_voyce_metabases_access?:ModelTypes["voyce_metabases_access_mutation_response"],
	/** update single row of the table: "voyce.metabases_access" */
	update_voyce_metabases_access_by_pk?:ModelTypes["voyce_metabases_access"],
	/** update multiples rows of table: "voyce.metabases_access" */
	update_voyce_metabases_access_many?:(ModelTypes["voyce_metabases_access_mutation_response"] | undefined)[],
	/** update single row of the table: "voyce.metabases" */
	update_voyce_metabases_by_pk?:ModelTypes["voyce_metabases"],
	/** update multiples rows of table: "voyce.metabases" */
	update_voyce_metabases_many?:(ModelTypes["voyce_metabases_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.newsletter" */
	update_voyce_newsletter?:ModelTypes["voyce_newsletter_mutation_response"],
	/** update single row of the table: "voyce.newsletter" */
	update_voyce_newsletter_by_pk?:ModelTypes["voyce_newsletter"],
	/** update multiples rows of table: "voyce.newsletter" */
	update_voyce_newsletter_many?:(ModelTypes["voyce_newsletter_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.notification_series_settings" */
	update_voyce_notification_series_settings?:ModelTypes["voyce_notification_series_settings_mutation_response"],
	/** update single row of the table: "voyce.notification_series_settings" */
	update_voyce_notification_series_settings_by_pk?:ModelTypes["voyce_notification_series_settings"],
	/** update multiples rows of table: "voyce.notification_series_settings" */
	update_voyce_notification_series_settings_many?:(ModelTypes["voyce_notification_series_settings_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.notification_settings" */
	update_voyce_notification_settings?:ModelTypes["voyce_notification_settings_mutation_response"],
	/** update single row of the table: "voyce.notification_settings" */
	update_voyce_notification_settings_by_pk?:ModelTypes["voyce_notification_settings"],
	/** update multiples rows of table: "voyce.notification_settings" */
	update_voyce_notification_settings_many?:(ModelTypes["voyce_notification_settings_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.notifications" */
	update_voyce_notifications?:ModelTypes["voyce_notifications_mutation_response"],
	/** update single row of the table: "voyce.notifications" */
	update_voyce_notifications_by_pk?:ModelTypes["voyce_notifications"],
	/** update multiples rows of table: "voyce.notifications" */
	update_voyce_notifications_many?:(ModelTypes["voyce_notifications_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.page_banners" */
	update_voyce_page_banners?:ModelTypes["voyce_page_banners_mutation_response"],
	/** update single row of the table: "voyce.page_banners" */
	update_voyce_page_banners_by_pk?:ModelTypes["voyce_page_banners"],
	/** update multiples rows of table: "voyce.page_banners" */
	update_voyce_page_banners_many?:(ModelTypes["voyce_page_banners_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.page_components" */
	update_voyce_page_components?:ModelTypes["voyce_page_components_mutation_response"],
	/** update single row of the table: "voyce.page_components" */
	update_voyce_page_components_by_pk?:ModelTypes["voyce_page_components"],
	/** update multiples rows of table: "voyce.page_components" */
	update_voyce_page_components_many?:(ModelTypes["voyce_page_components_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.pages" */
	update_voyce_pages?:ModelTypes["voyce_pages_mutation_response"],
	/** update single row of the table: "voyce.pages" */
	update_voyce_pages_by_pk?:ModelTypes["voyce_pages"],
	/** update multiples rows of table: "voyce.pages" */
	update_voyce_pages_many?:(ModelTypes["voyce_pages_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.panels_comments" */
	update_voyce_panels_comments?:ModelTypes["voyce_panels_comments_mutation_response"],
	/** update single row of the table: "voyce.panels_comments" */
	update_voyce_panels_comments_by_pk?:ModelTypes["voyce_panels_comments"],
	/** update multiples rows of table: "voyce.panels_comments" */
	update_voyce_panels_comments_many?:(ModelTypes["voyce_panels_comments_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.panels_reactions" */
	update_voyce_panels_reactions?:ModelTypes["voyce_panels_reactions_mutation_response"],
	/** update single row of the table: "voyce.panels_reactions" */
	update_voyce_panels_reactions_by_pk?:ModelTypes["voyce_panels_reactions"],
	/** update data of the table: "voyce.panels_reactions_count" */
	update_voyce_panels_reactions_count?:ModelTypes["voyce_panels_reactions_count_mutation_response"],
	/** update single row of the table: "voyce.panels_reactions_count" */
	update_voyce_panels_reactions_count_by_pk?:ModelTypes["voyce_panels_reactions_count"],
	/** update multiples rows of table: "voyce.panels_reactions_count" */
	update_voyce_panels_reactions_count_many?:(ModelTypes["voyce_panels_reactions_count_mutation_response"] | undefined)[],
	/** update multiples rows of table: "voyce.panels_reactions" */
	update_voyce_panels_reactions_many?:(ModelTypes["voyce_panels_reactions_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.payments_to_creators" */
	update_voyce_payments_to_creators?:ModelTypes["voyce_payments_to_creators_mutation_response"],
	/** update single row of the table: "voyce.payments_to_creators" */
	update_voyce_payments_to_creators_by_pk?:ModelTypes["voyce_payments_to_creators"],
	/** update multiples rows of table: "voyce.payments_to_creators" */
	update_voyce_payments_to_creators_many?:(ModelTypes["voyce_payments_to_creators_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.pg_rs_series_views_count" */
	update_voyce_pg_rs_series_views_count?:ModelTypes["voyce_pg_rs_series_views_count_mutation_response"],
	/** update single row of the table: "voyce.pg_rs_series_views_count" */
	update_voyce_pg_rs_series_views_count_by_pk?:ModelTypes["voyce_pg_rs_series_views_count"],
	/** update multiples rows of table: "voyce.pg_rs_series_views_count" */
	update_voyce_pg_rs_series_views_count_many?:(ModelTypes["voyce_pg_rs_series_views_count_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.polls" */
	update_voyce_polls?:ModelTypes["voyce_polls_mutation_response"],
	/** update data of the table: "voyce.polls_answers" */
	update_voyce_polls_answers?:ModelTypes["voyce_polls_answers_mutation_response"],
	/** update single row of the table: "voyce.polls_answers" */
	update_voyce_polls_answers_by_pk?:ModelTypes["voyce_polls_answers"],
	/** update multiples rows of table: "voyce.polls_answers" */
	update_voyce_polls_answers_many?:(ModelTypes["voyce_polls_answers_mutation_response"] | undefined)[],
	/** update single row of the table: "voyce.polls" */
	update_voyce_polls_by_pk?:ModelTypes["voyce_polls"],
	/** update data of the table: "voyce.polls_comments" */
	update_voyce_polls_comments?:ModelTypes["voyce_polls_comments_mutation_response"],
	/** update single row of the table: "voyce.polls_comments" */
	update_voyce_polls_comments_by_pk?:ModelTypes["voyce_polls_comments"],
	/** update multiples rows of table: "voyce.polls_comments" */
	update_voyce_polls_comments_many?:(ModelTypes["voyce_polls_comments_mutation_response"] | undefined)[],
	/** update multiples rows of table: "voyce.polls" */
	update_voyce_polls_many?:(ModelTypes["voyce_polls_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.polls_reactions" */
	update_voyce_polls_reactions?:ModelTypes["voyce_polls_reactions_mutation_response"],
	/** update single row of the table: "voyce.polls_reactions" */
	update_voyce_polls_reactions_by_pk?:ModelTypes["voyce_polls_reactions"],
	/** update multiples rows of table: "voyce.polls_reactions" */
	update_voyce_polls_reactions_many?:(ModelTypes["voyce_polls_reactions_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.polls_votes" */
	update_voyce_polls_votes?:ModelTypes["voyce_polls_votes_mutation_response"],
	/** update single row of the table: "voyce.polls_votes" */
	update_voyce_polls_votes_by_pk?:ModelTypes["voyce_polls_votes"],
	/** update multiples rows of table: "voyce.polls_votes" */
	update_voyce_polls_votes_many?:(ModelTypes["voyce_polls_votes_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.popup_components" */
	update_voyce_popup_components?:ModelTypes["voyce_popup_components_mutation_response"],
	/** update single row of the table: "voyce.popup_components" */
	update_voyce_popup_components_by_pk?:ModelTypes["voyce_popup_components"],
	/** update multiples rows of table: "voyce.popup_components" */
	update_voyce_popup_components_many?:(ModelTypes["voyce_popup_components_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.popup_emails" */
	update_voyce_popup_emails?:ModelTypes["voyce_popup_emails_mutation_response"],
	/** update single row of the table: "voyce.popup_emails" */
	update_voyce_popup_emails_by_pk?:ModelTypes["voyce_popup_emails"],
	/** update multiples rows of table: "voyce.popup_emails" */
	update_voyce_popup_emails_many?:(ModelTypes["voyce_popup_emails_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.posts" */
	update_voyce_posts?:ModelTypes["voyce_posts_mutation_response"],
	/** update single row of the table: "voyce.posts" */
	update_voyce_posts_by_pk?:ModelTypes["voyce_posts"],
	/** update data of the table: "voyce.posts_comments" */
	update_voyce_posts_comments?:ModelTypes["voyce_posts_comments_mutation_response"],
	/** update single row of the table: "voyce.posts_comments" */
	update_voyce_posts_comments_by_pk?:ModelTypes["voyce_posts_comments"],
	/** update multiples rows of table: "voyce.posts_comments" */
	update_voyce_posts_comments_many?:(ModelTypes["voyce_posts_comments_mutation_response"] | undefined)[],
	/** update multiples rows of table: "voyce.posts" */
	update_voyce_posts_many?:(ModelTypes["voyce_posts_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.posts_reactions" */
	update_voyce_posts_reactions?:ModelTypes["voyce_posts_reactions_mutation_response"],
	/** update single row of the table: "voyce.posts_reactions" */
	update_voyce_posts_reactions_by_pk?:ModelTypes["voyce_posts_reactions"],
	/** update multiples rows of table: "voyce.posts_reactions" */
	update_voyce_posts_reactions_many?:(ModelTypes["voyce_posts_reactions_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.quest_difficulties" */
	update_voyce_quest_difficulties?:ModelTypes["voyce_quest_difficulties_mutation_response"],
	/** update single row of the table: "voyce.quest_difficulties" */
	update_voyce_quest_difficulties_by_pk?:ModelTypes["voyce_quest_difficulties"],
	/** update multiples rows of table: "voyce.quest_difficulties" */
	update_voyce_quest_difficulties_many?:(ModelTypes["voyce_quest_difficulties_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.quests" */
	update_voyce_quests?:ModelTypes["voyce_quests_mutation_response"],
	/** update single row of the table: "voyce.quests" */
	update_voyce_quests_by_pk?:ModelTypes["voyce_quests"],
	/** update multiples rows of table: "voyce.quests" */
	update_voyce_quests_many?:(ModelTypes["voyce_quests_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.quests_old" */
	update_voyce_quests_old?:ModelTypes["voyce_quests_old_mutation_response"],
	/** update single row of the table: "voyce.quests_old" */
	update_voyce_quests_old_by_pk?:ModelTypes["voyce_quests_old"],
	/** update multiples rows of table: "voyce.quests_old" */
	update_voyce_quests_old_many?:(ModelTypes["voyce_quests_old_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.recommended_series" */
	update_voyce_recommended_series?:ModelTypes["voyce_recommended_series_mutation_response"],
	/** update single row of the table: "voyce.recommended_series" */
	update_voyce_recommended_series_by_pk?:ModelTypes["voyce_recommended_series"],
	/** update multiples rows of table: "voyce.recommended_series" */
	update_voyce_recommended_series_many?:(ModelTypes["voyce_recommended_series_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.release_schedules" */
	update_voyce_release_schedules?:ModelTypes["voyce_release_schedules_mutation_response"],
	/** update single row of the table: "voyce.release_schedules" */
	update_voyce_release_schedules_by_pk?:ModelTypes["voyce_release_schedules"],
	/** update multiples rows of table: "voyce.release_schedules" */
	update_voyce_release_schedules_many?:(ModelTypes["voyce_release_schedules_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.rewards" */
	update_voyce_rewards?:ModelTypes["voyce_rewards_mutation_response"],
	/** update single row of the table: "voyce.rewards" */
	update_voyce_rewards_by_pk?:ModelTypes["voyce_rewards"],
	/** update data of the table: "voyce.rewards_leaderboard" */
	update_voyce_rewards_leaderboard?:ModelTypes["voyce_rewards_leaderboard_mutation_response"],
	/** update single row of the table: "voyce.rewards_leaderboard" */
	update_voyce_rewards_leaderboard_by_pk?:ModelTypes["voyce_rewards_leaderboard"],
	/** update multiples rows of table: "voyce.rewards_leaderboard" */
	update_voyce_rewards_leaderboard_many?:(ModelTypes["voyce_rewards_leaderboard_mutation_response"] | undefined)[],
	/** update multiples rows of table: "voyce.rewards" */
	update_voyce_rewards_many?:(ModelTypes["voyce_rewards_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.rewards_quest" */
	update_voyce_rewards_quest?:ModelTypes["voyce_rewards_quest_mutation_response"],
	/** update single row of the table: "voyce.rewards_quest" */
	update_voyce_rewards_quest_by_pk?:ModelTypes["voyce_rewards_quest"],
	/** update multiples rows of table: "voyce.rewards_quest" */
	update_voyce_rewards_quest_many?:(ModelTypes["voyce_rewards_quest_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.sections" */
	update_voyce_sections?:ModelTypes["voyce_sections_mutation_response"],
	/** update single row of the table: "voyce.sections" */
	update_voyce_sections_by_pk?:ModelTypes["voyce_sections"],
	/** update multiples rows of table: "voyce.sections" */
	update_voyce_sections_many?:(ModelTypes["voyce_sections_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series" */
	update_voyce_series?:ModelTypes["voyce_series_mutation_response"],
	/** update single row of the table: "voyce.series" */
	update_voyce_series_by_pk?:ModelTypes["voyce_series"],
	/** update data of the table: "voyce.series_collaborators" */
	update_voyce_series_collaborators?:ModelTypes["voyce_series_collaborators_mutation_response"],
	/** update single row of the table: "voyce.series_collaborators" */
	update_voyce_series_collaborators_by_pk?:ModelTypes["voyce_series_collaborators"],
	/** update multiples rows of table: "voyce.series_collaborators" */
	update_voyce_series_collaborators_many?:(ModelTypes["voyce_series_collaborators_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_donations" */
	update_voyce_series_donations?:ModelTypes["voyce_series_donations_mutation_response"],
	/** update single row of the table: "voyce.series_donations" */
	update_voyce_series_donations_by_pk?:ModelTypes["voyce_series_donations"],
	/** update multiples rows of table: "voyce.series_donations" */
	update_voyce_series_donations_many?:(ModelTypes["voyce_series_donations_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_favorites" */
	update_voyce_series_favorites?:ModelTypes["voyce_series_favorites_mutation_response"],
	/** update single row of the table: "voyce.series_favorites" */
	update_voyce_series_favorites_by_pk?:ModelTypes["voyce_series_favorites"],
	/** update multiples rows of table: "voyce.series_favorites" */
	update_voyce_series_favorites_many?:(ModelTypes["voyce_series_favorites_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_featured_panels" */
	update_voyce_series_featured_panels?:ModelTypes["voyce_series_featured_panels_mutation_response"],
	/** update single row of the table: "voyce.series_featured_panels" */
	update_voyce_series_featured_panels_by_pk?:ModelTypes["voyce_series_featured_panels"],
	/** update multiples rows of table: "voyce.series_featured_panels" */
	update_voyce_series_featured_panels_many?:(ModelTypes["voyce_series_featured_panels_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_genre" */
	update_voyce_series_genre?:ModelTypes["voyce_series_genre_mutation_response"],
	/** update single row of the table: "voyce.series_genre" */
	update_voyce_series_genre_by_pk?:ModelTypes["voyce_series_genre"],
	/** update multiples rows of table: "voyce.series_genre" */
	update_voyce_series_genre_many?:(ModelTypes["voyce_series_genre_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_giveaway_participants" */
	update_voyce_series_giveaway_participants?:ModelTypes["voyce_series_giveaway_participants_mutation_response"],
	/** update single row of the table: "voyce.series_giveaway_participants" */
	update_voyce_series_giveaway_participants_by_pk?:ModelTypes["voyce_series_giveaway_participants"],
	/** update multiples rows of table: "voyce.series_giveaway_participants" */
	update_voyce_series_giveaway_participants_many?:(ModelTypes["voyce_series_giveaway_participants_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_hot_panels" */
	update_voyce_series_hot_panels?:ModelTypes["voyce_series_hot_panels_mutation_response"],
	/** update single row of the table: "voyce.series_hot_panels" */
	update_voyce_series_hot_panels_by_pk?:ModelTypes["voyce_series_hot_panels"],
	/** update multiples rows of table: "voyce.series_hot_panels" */
	update_voyce_series_hot_panels_many?:(ModelTypes["voyce_series_hot_panels_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_layouts" */
	update_voyce_series_layouts?:ModelTypes["voyce_series_layouts_mutation_response"],
	/** update single row of the table: "voyce.series_layouts" */
	update_voyce_series_layouts_by_pk?:ModelTypes["voyce_series_layouts"],
	/** update multiples rows of table: "voyce.series_layouts" */
	update_voyce_series_layouts_many?:(ModelTypes["voyce_series_layouts_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_leaderboard" */
	update_voyce_series_leaderboard?:ModelTypes["voyce_series_leaderboard_mutation_response"],
	/** update single row of the table: "voyce.series_leaderboard" */
	update_voyce_series_leaderboard_by_pk?:ModelTypes["voyce_series_leaderboard"],
	/** update multiples rows of table: "voyce.series_leaderboard" */
	update_voyce_series_leaderboard_many?:(ModelTypes["voyce_series_leaderboard_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_lock_config" */
	update_voyce_series_lock_config?:ModelTypes["voyce_series_lock_config_mutation_response"],
	/** update single row of the table: "voyce.series_lock_config" */
	update_voyce_series_lock_config_by_pk?:ModelTypes["voyce_series_lock_config"],
	/** update multiples rows of table: "voyce.series_lock_config" */
	update_voyce_series_lock_config_many?:(ModelTypes["voyce_series_lock_config_mutation_response"] | undefined)[],
	/** update multiples rows of table: "voyce.series" */
	update_voyce_series_many?:(ModelTypes["voyce_series_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_sponsorships_configuration" */
	update_voyce_series_sponsorships_configuration?:ModelTypes["voyce_series_sponsorships_configuration_mutation_response"],
	/** update single row of the table: "voyce.series_sponsorships_configuration" */
	update_voyce_series_sponsorships_configuration_by_pk?:ModelTypes["voyce_series_sponsorships_configuration"],
	/** update multiples rows of table: "voyce.series_sponsorships_configuration" */
	update_voyce_series_sponsorships_configuration_many?:(ModelTypes["voyce_series_sponsorships_configuration_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_subscription" */
	update_voyce_series_subscription?:ModelTypes["voyce_series_subscription_mutation_response"],
	/** update single row of the table: "voyce.series_subscription" */
	update_voyce_series_subscription_by_pk?:ModelTypes["voyce_series_subscription"],
	/** update multiples rows of table: "voyce.series_subscription" */
	update_voyce_series_subscription_many?:(ModelTypes["voyce_series_subscription_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_tags" */
	update_voyce_series_tags?:ModelTypes["voyce_series_tags_mutation_response"],
	/** update single row of the table: "voyce.series_tags" */
	update_voyce_series_tags_by_pk?:ModelTypes["voyce_series_tags"],
	/** update data of the table: "voyce.series_tags_junction" */
	update_voyce_series_tags_junction?:ModelTypes["voyce_series_tags_junction_mutation_response"],
	/** update single row of the table: "voyce.series_tags_junction" */
	update_voyce_series_tags_junction_by_pk?:ModelTypes["voyce_series_tags_junction"],
	/** update multiples rows of table: "voyce.series_tags_junction" */
	update_voyce_series_tags_junction_many?:(ModelTypes["voyce_series_tags_junction_mutation_response"] | undefined)[],
	/** update multiples rows of table: "voyce.series_tags" */
	update_voyce_series_tags_many?:(ModelTypes["voyce_series_tags_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_top_comments" */
	update_voyce_series_top_comments?:ModelTypes["voyce_series_top_comments_mutation_response"],
	/** update single row of the table: "voyce.series_top_comments" */
	update_voyce_series_top_comments_by_pk?:ModelTypes["voyce_series_top_comments"],
	/** update multiples rows of table: "voyce.series_top_comments" */
	update_voyce_series_top_comments_many?:(ModelTypes["voyce_series_top_comments_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_top_likes" */
	update_voyce_series_top_likes?:ModelTypes["voyce_series_top_likes_mutation_response"],
	/** update single row of the table: "voyce.series_top_likes" */
	update_voyce_series_top_likes_by_pk?:ModelTypes["voyce_series_top_likes"],
	/** update multiples rows of table: "voyce.series_top_likes" */
	update_voyce_series_top_likes_many?:(ModelTypes["voyce_series_top_likes_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_top_views" */
	update_voyce_series_top_views?:ModelTypes["voyce_series_top_views_mutation_response"],
	/** update single row of the table: "voyce.series_top_views" */
	update_voyce_series_top_views_by_pk?:ModelTypes["voyce_series_top_views"],
	/** update multiples rows of table: "voyce.series_top_views" */
	update_voyce_series_top_views_many?:(ModelTypes["voyce_series_top_views_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_views_adjustment" */
	update_voyce_series_views_adjustment?:ModelTypes["voyce_series_views_adjustment_mutation_response"],
	/** update single row of the table: "voyce.series_views_adjustment" */
	update_voyce_series_views_adjustment_by_pk?:ModelTypes["voyce_series_views_adjustment"],
	/** update multiples rows of table: "voyce.series_views_adjustment" */
	update_voyce_series_views_adjustment_many?:(ModelTypes["voyce_series_views_adjustment_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_votes" */
	update_voyce_series_votes?:ModelTypes["voyce_series_votes_mutation_response"],
	/** update single row of the table: "voyce.series_votes" */
	update_voyce_series_votes_by_pk?:ModelTypes["voyce_series_votes"],
	/** update data of the table: "voyce.series_votes_count" */
	update_voyce_series_votes_count?:ModelTypes["voyce_series_votes_count_mutation_response"],
	/** update single row of the table: "voyce.series_votes_count" */
	update_voyce_series_votes_count_by_pk?:ModelTypes["voyce_series_votes_count"],
	/** update multiples rows of table: "voyce.series_votes_count" */
	update_voyce_series_votes_count_many?:(ModelTypes["voyce_series_votes_count_mutation_response"] | undefined)[],
	/** update multiples rows of table: "voyce.series_votes" */
	update_voyce_series_votes_many?:(ModelTypes["voyce_series_votes_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.series_voting_schedules" */
	update_voyce_series_voting_schedules?:ModelTypes["voyce_series_voting_schedules_mutation_response"],
	/** update single row of the table: "voyce.series_voting_schedules" */
	update_voyce_series_voting_schedules_by_pk?:ModelTypes["voyce_series_voting_schedules"],
	/** update multiples rows of table: "voyce.series_voting_schedules" */
	update_voyce_series_voting_schedules_many?:(ModelTypes["voyce_series_voting_schedules_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.sponsorships" */
	update_voyce_sponsorships?:ModelTypes["voyce_sponsorships_mutation_response"],
	/** update single row of the table: "voyce.sponsorships" */
	update_voyce_sponsorships_by_pk?:ModelTypes["voyce_sponsorships"],
	/** update multiples rows of table: "voyce.sponsorships" */
	update_voyce_sponsorships_many?:(ModelTypes["voyce_sponsorships_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.states" */
	update_voyce_states?:ModelTypes["voyce_states_mutation_response"],
	/** update single row of the table: "voyce.states" */
	update_voyce_states_by_pk?:ModelTypes["voyce_states"],
	/** update multiples rows of table: "voyce.states" */
	update_voyce_states_many?:(ModelTypes["voyce_states_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.stripe_payouts_setup" */
	update_voyce_stripe_payouts_setup?:ModelTypes["voyce_stripe_payouts_setup_mutation_response"],
	/** update single row of the table: "voyce.stripe_payouts_setup" */
	update_voyce_stripe_payouts_setup_by_pk?:ModelTypes["voyce_stripe_payouts_setup"],
	/** update multiples rows of table: "voyce.stripe_payouts_setup" */
	update_voyce_stripe_payouts_setup_many?:(ModelTypes["voyce_stripe_payouts_setup_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.studio" */
	update_voyce_studio?:ModelTypes["voyce_studio_mutation_response"],
	/** update single row of the table: "voyce.studio" */
	update_voyce_studio_by_pk?:ModelTypes["voyce_studio"],
	/** update data of the table: "voyce.studio_followers" */
	update_voyce_studio_followers?:ModelTypes["voyce_studio_followers_mutation_response"],
	/** update single row of the table: "voyce.studio_followers" */
	update_voyce_studio_followers_by_pk?:ModelTypes["voyce_studio_followers"],
	/** update multiples rows of table: "voyce.studio_followers" */
	update_voyce_studio_followers_many?:(ModelTypes["voyce_studio_followers_mutation_response"] | undefined)[],
	/** update multiples rows of table: "voyce.studio" */
	update_voyce_studio_many?:(ModelTypes["voyce_studio_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.studio_series" */
	update_voyce_studio_series?:ModelTypes["voyce_studio_series_mutation_response"],
	/** update single row of the table: "voyce.studio_series" */
	update_voyce_studio_series_by_pk?:ModelTypes["voyce_studio_series"],
	/** update multiples rows of table: "voyce.studio_series" */
	update_voyce_studio_series_many?:(ModelTypes["voyce_studio_series_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.studio_views" */
	update_voyce_studio_views?:ModelTypes["voyce_studio_views_mutation_response"],
	/** update single row of the table: "voyce.studio_views" */
	update_voyce_studio_views_by_pk?:ModelTypes["voyce_studio_views"],
	/** update multiples rows of table: "voyce.studio_views" */
	update_voyce_studio_views_many?:(ModelTypes["voyce_studio_views_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.system_settings" */
	update_voyce_system_settings?:ModelTypes["voyce_system_settings_mutation_response"],
	/** update single row of the table: "voyce.system_settings" */
	update_voyce_system_settings_by_pk?:ModelTypes["voyce_system_settings"],
	/** update multiples rows of table: "voyce.system_settings" */
	update_voyce_system_settings_many?:(ModelTypes["voyce_system_settings_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.transaction_logs" */
	update_voyce_transaction_logs?:ModelTypes["voyce_transaction_logs_mutation_response"],
	/** update single row of the table: "voyce.transaction_logs" */
	update_voyce_transaction_logs_by_pk?:ModelTypes["voyce_transaction_logs"],
	/** update multiples rows of table: "voyce.transaction_logs" */
	update_voyce_transaction_logs_many?:(ModelTypes["voyce_transaction_logs_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.url_redirects" */
	update_voyce_url_redirects?:ModelTypes["voyce_url_redirects_mutation_response"],
	/** update single row of the table: "voyce.url_redirects" */
	update_voyce_url_redirects_by_pk?:ModelTypes["voyce_url_redirects"],
	/** update multiples rows of table: "voyce.url_redirects" */
	update_voyce_url_redirects_many?:(ModelTypes["voyce_url_redirects_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_addresses" */
	update_voyce_user_addresses?:ModelTypes["voyce_user_addresses_mutation_response"],
	/** update single row of the table: "voyce.user_addresses" */
	update_voyce_user_addresses_by_pk?:ModelTypes["voyce_user_addresses"],
	/** update multiples rows of table: "voyce.user_addresses" */
	update_voyce_user_addresses_many?:(ModelTypes["voyce_user_addresses_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_blocks" */
	update_voyce_user_blocks?:ModelTypes["voyce_user_blocks_mutation_response"],
	/** update single row of the table: "voyce.user_blocks" */
	update_voyce_user_blocks_by_pk?:ModelTypes["voyce_user_blocks"],
	/** update multiples rows of table: "voyce.user_blocks" */
	update_voyce_user_blocks_many?:(ModelTypes["voyce_user_blocks_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_chapters_unlocked" */
	update_voyce_user_chapters_unlocked?:ModelTypes["voyce_user_chapters_unlocked_mutation_response"],
	/** update single row of the table: "voyce.user_chapters_unlocked" */
	update_voyce_user_chapters_unlocked_by_pk?:ModelTypes["voyce_user_chapters_unlocked"],
	/** update multiples rows of table: "voyce.user_chapters_unlocked" */
	update_voyce_user_chapters_unlocked_many?:(ModelTypes["voyce_user_chapters_unlocked_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_contacts" */
	update_voyce_user_contacts?:ModelTypes["voyce_user_contacts_mutation_response"],
	/** update single row of the table: "voyce.user_contacts" */
	update_voyce_user_contacts_by_pk?:ModelTypes["voyce_user_contacts"],
	/** update multiples rows of table: "voyce.user_contacts" */
	update_voyce_user_contacts_many?:(ModelTypes["voyce_user_contacts_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_csat_ratings" */
	update_voyce_user_csat_ratings?:ModelTypes["voyce_user_csat_ratings_mutation_response"],
	/** update single row of the table: "voyce.user_csat_ratings" */
	update_voyce_user_csat_ratings_by_pk?:ModelTypes["voyce_user_csat_ratings"],
	/** update multiples rows of table: "voyce.user_csat_ratings" */
	update_voyce_user_csat_ratings_many?:(ModelTypes["voyce_user_csat_ratings_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_education" */
	update_voyce_user_education?:ModelTypes["voyce_user_education_mutation_response"],
	/** update single row of the table: "voyce.user_education" */
	update_voyce_user_education_by_pk?:ModelTypes["voyce_user_education"],
	/** update multiples rows of table: "voyce.user_education" */
	update_voyce_user_education_many?:(ModelTypes["voyce_user_education_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_level" */
	update_voyce_user_level?:ModelTypes["voyce_user_level_mutation_response"],
	/** update single row of the table: "voyce.user_level" */
	update_voyce_user_level_by_pk?:ModelTypes["voyce_user_level"],
	/** update multiples rows of table: "voyce.user_level" */
	update_voyce_user_level_many?:(ModelTypes["voyce_user_level_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_linked_accounts" */
	update_voyce_user_linked_accounts?:ModelTypes["voyce_user_linked_accounts_mutation_response"],
	/** update single row of the table: "voyce.user_linked_accounts" */
	update_voyce_user_linked_accounts_by_pk?:ModelTypes["voyce_user_linked_accounts"],
	/** update multiples rows of table: "voyce.user_linked_accounts" */
	update_voyce_user_linked_accounts_many?:(ModelTypes["voyce_user_linked_accounts_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_login_history" */
	update_voyce_user_login_history?:ModelTypes["voyce_user_login_history_mutation_response"],
	/** update single row of the table: "voyce.user_login_history" */
	update_voyce_user_login_history_by_pk?:ModelTypes["voyce_user_login_history"],
	/** update multiples rows of table: "voyce.user_login_history" */
	update_voyce_user_login_history_many?:(ModelTypes["voyce_user_login_history_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_quest_ids" */
	update_voyce_user_quest_ids?:ModelTypes["voyce_user_quest_ids_mutation_response"],
	/** update single row of the table: "voyce.user_quest_ids" */
	update_voyce_user_quest_ids_by_pk?:ModelTypes["voyce_user_quest_ids"],
	/** update multiples rows of table: "voyce.user_quest_ids" */
	update_voyce_user_quest_ids_many?:(ModelTypes["voyce_user_quest_ids_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_quest_rewards" */
	update_voyce_user_quest_rewards?:ModelTypes["voyce_user_quest_rewards_mutation_response"],
	/** update single row of the table: "voyce.user_quest_rewards" */
	update_voyce_user_quest_rewards_by_pk?:ModelTypes["voyce_user_quest_rewards"],
	/** update multiples rows of table: "voyce.user_quest_rewards" */
	update_voyce_user_quest_rewards_many?:(ModelTypes["voyce_user_quest_rewards_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_quests" */
	update_voyce_user_quests?:ModelTypes["voyce_user_quests_mutation_response"],
	/** update single row of the table: "voyce.user_quests" */
	update_voyce_user_quests_by_pk?:ModelTypes["voyce_user_quests"],
	/** update multiples rows of table: "voyce.user_quests" */
	update_voyce_user_quests_many?:(ModelTypes["voyce_user_quests_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_quests_old" */
	update_voyce_user_quests_old?:ModelTypes["voyce_user_quests_old_mutation_response"],
	/** update single row of the table: "voyce.user_quests_old" */
	update_voyce_user_quests_old_by_pk?:ModelTypes["voyce_user_quests_old"],
	/** update multiples rows of table: "voyce.user_quests_old" */
	update_voyce_user_quests_old_many?:(ModelTypes["voyce_user_quests_old_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_ranking" */
	update_voyce_user_ranking?:ModelTypes["voyce_user_ranking_mutation_response"],
	/** update single row of the table: "voyce.user_ranking" */
	update_voyce_user_ranking_by_pk?:ModelTypes["voyce_user_ranking"],
	/** update multiples rows of table: "voyce.user_ranking" */
	update_voyce_user_ranking_many?:(ModelTypes["voyce_user_ranking_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_reviews" */
	update_voyce_user_reviews?:ModelTypes["voyce_user_reviews_mutation_response"],
	/** update single row of the table: "voyce.user_reviews" */
	update_voyce_user_reviews_by_pk?:ModelTypes["voyce_user_reviews"],
	/** update multiples rows of table: "voyce.user_reviews" */
	update_voyce_user_reviews_many?:(ModelTypes["voyce_user_reviews_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_rewards" */
	update_voyce_user_rewards?:ModelTypes["voyce_user_rewards_mutation_response"],
	/** update single row of the table: "voyce.user_rewards" */
	update_voyce_user_rewards_by_pk?:ModelTypes["voyce_user_rewards"],
	/** update multiples rows of table: "voyce.user_rewards" */
	update_voyce_user_rewards_many?:(ModelTypes["voyce_user_rewards_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_roles" */
	update_voyce_user_roles?:ModelTypes["voyce_user_roles_mutation_response"],
	/** update single row of the table: "voyce.user_roles" */
	update_voyce_user_roles_by_pk?:ModelTypes["voyce_user_roles"],
	/** update multiples rows of table: "voyce.user_roles" */
	update_voyce_user_roles_many?:(ModelTypes["voyce_user_roles_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_settings" */
	update_voyce_user_settings?:ModelTypes["voyce_user_settings_mutation_response"],
	/** update single row of the table: "voyce.user_settings" */
	update_voyce_user_settings_by_pk?:ModelTypes["voyce_user_settings"],
	/** update multiples rows of table: "voyce.user_settings" */
	update_voyce_user_settings_many?:(ModelTypes["voyce_user_settings_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_skills" */
	update_voyce_user_skills?:ModelTypes["voyce_user_skills_mutation_response"],
	/** update single row of the table: "voyce.user_skills" */
	update_voyce_user_skills_by_pk?:ModelTypes["voyce_user_skills"],
	/** update multiples rows of table: "voyce.user_skills" */
	update_voyce_user_skills_many?:(ModelTypes["voyce_user_skills_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_socials" */
	update_voyce_user_socials?:ModelTypes["voyce_user_socials_mutation_response"],
	/** update single row of the table: "voyce.user_socials" */
	update_voyce_user_socials_by_pk?:ModelTypes["voyce_user_socials"],
	/** update multiples rows of table: "voyce.user_socials" */
	update_voyce_user_socials_many?:(ModelTypes["voyce_user_socials_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_soft_deleted" */
	update_voyce_user_soft_deleted?:ModelTypes["voyce_user_soft_deleted_mutation_response"],
	/** update single row of the table: "voyce.user_soft_deleted" */
	update_voyce_user_soft_deleted_by_pk?:ModelTypes["voyce_user_soft_deleted"],
	/** update multiples rows of table: "voyce.user_soft_deleted" */
	update_voyce_user_soft_deleted_many?:(ModelTypes["voyce_user_soft_deleted_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_vtags" */
	update_voyce_user_vtags?:ModelTypes["voyce_user_vtags_mutation_response"],
	/** update single row of the table: "voyce.user_vtags" */
	update_voyce_user_vtags_by_pk?:ModelTypes["voyce_user_vtags"],
	/** update multiples rows of table: "voyce.user_vtags" */
	update_voyce_user_vtags_many?:(ModelTypes["voyce_user_vtags_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.user_wins" */
	update_voyce_user_wins?:ModelTypes["voyce_user_wins_mutation_response"],
	/** update single row of the table: "voyce.user_wins" */
	update_voyce_user_wins_by_pk?:ModelTypes["voyce_user_wins"],
	/** update multiples rows of table: "voyce.user_wins" */
	update_voyce_user_wins_many?:(ModelTypes["voyce_user_wins_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.users" */
	update_voyce_users?:ModelTypes["voyce_users_mutation_response"],
	/** update single row of the table: "voyce.users" */
	update_voyce_users_by_pk?:ModelTypes["voyce_users"],
	/** update data of the table: "voyce.users_followers" */
	update_voyce_users_followers?:ModelTypes["voyce_users_followers_mutation_response"],
	/** update single row of the table: "voyce.users_followers" */
	update_voyce_users_followers_by_pk?:ModelTypes["voyce_users_followers"],
	/** update multiples rows of table: "voyce.users_followers" */
	update_voyce_users_followers_many?:(ModelTypes["voyce_users_followers_mutation_response"] | undefined)[],
	/** update multiples rows of table: "voyce.users" */
	update_voyce_users_many?:(ModelTypes["voyce_users_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.users_private" */
	update_voyce_users_private?:ModelTypes["voyce_users_private_mutation_response"],
	/** update multiples rows of table: "voyce.users_private" */
	update_voyce_users_private_many?:(ModelTypes["voyce_users_private_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.users_series_junction" */
	update_voyce_users_series_junction?:ModelTypes["voyce_users_series_junction_mutation_response"],
	/** update single row of the table: "voyce.users_series_junction" */
	update_voyce_users_series_junction_by_pk?:ModelTypes["voyce_users_series_junction"],
	/** update multiples rows of table: "voyce.users_series_junction" */
	update_voyce_users_series_junction_many?:(ModelTypes["voyce_users_series_junction_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.versions" */
	update_voyce_versions?:ModelTypes["voyce_versions_mutation_response"],
	/** update single row of the table: "voyce.versions" */
	update_voyce_versions_by_pk?:ModelTypes["voyce_versions"],
	/** update multiples rows of table: "voyce.versions" */
	update_voyce_versions_many?:(ModelTypes["voyce_versions_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.voting_analytics" */
	update_voyce_voting_analytics?:ModelTypes["voyce_voting_analytics_mutation_response"],
	/** update single row of the table: "voyce.voting_analytics" */
	update_voyce_voting_analytics_by_pk?:ModelTypes["voyce_voting_analytics"],
	/** update multiples rows of table: "voyce.voting_analytics" */
	update_voyce_voting_analytics_many?:(ModelTypes["voyce_voting_analytics_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.voting_panels" */
	update_voyce_voting_panels?:ModelTypes["voyce_voting_panels_mutation_response"],
	/** update single row of the table: "voyce.voting_panels" */
	update_voyce_voting_panels_by_pk?:ModelTypes["voyce_voting_panels"],
	/** update multiples rows of table: "voyce.voting_panels" */
	update_voyce_voting_panels_many?:(ModelTypes["voyce_voting_panels_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.voting_series" */
	update_voyce_voting_series?:ModelTypes["voyce_voting_series_mutation_response"],
	/** update single row of the table: "voyce.voting_series" */
	update_voyce_voting_series_by_pk?:ModelTypes["voyce_voting_series"],
	/** update multiples rows of table: "voyce.voting_series" */
	update_voyce_voting_series_many?:(ModelTypes["voyce_voting_series_mutation_response"] | undefined)[],
	/** update data of the table: "voyce.voting_user_votes" */
	update_voyce_voting_user_votes?:ModelTypes["voyce_voting_user_votes_mutation_response"],
	/** update single row of the table: "voyce.voting_user_votes" */
	update_voyce_voting_user_votes_by_pk?:ModelTypes["voyce_voting_user_votes"],
	/** update multiples rows of table: "voyce.voting_user_votes" */
	update_voyce_voting_user_votes_many?:(ModelTypes["voyce_voting_user_votes_mutation_response"] | undefined)[]
};
	/** columns and relationships of "mysql.user" */
["mysql_user"]: {
		account_locked:string,
	alter_priv:string,
	alter_routine_priv:string,
	authentication_string?:string,
	create_priv:string,
	create_role_priv:string,
	create_routine_priv:string,
	create_tablespace_priv:string,
	create_tmp_table_priv:string,
	create_user_priv:string,
	create_view_priv:string,
	delete_priv:string,
	drop_priv:string,
	drop_role_priv:string,
	event_priv:string,
	execute_priv:string,
	file_priv:string,
	grant_priv:string,
	host:string,
	index_priv:string,
	insert_priv:string,
	lock_tables_priv:string,
	max_connections:ModelTypes["bigint"],
	max_questions:ModelTypes["bigint"],
	max_updates:ModelTypes["bigint"],
	max_user_connections:ModelTypes["bigint"],
	password_expired:string,
	password_last_changed?:ModelTypes["timestamp"],
	password_lifetime?:number,
	password_require_current?:string,
	password_reuse_history?:number,
	password_reuse_time?:number,
	plugin:string,
	process_priv:string,
	references_priv:string,
	reload_priv:string,
	repl_client_priv:string,
	repl_slave_priv:string,
	select_priv:string,
	show_db_priv:string,
	show_view_priv:string,
	shutdown_priv:string,
	ssl_cipher:ModelTypes["bytea"],
	ssl_type:string,
	super_priv:string,
	trigger_priv:string,
	update_priv:string,
	user:string,
	user_attributes?:string,
	x509_issuer:ModelTypes["bytea"],
	x509_subject:ModelTypes["bytea"]
};
	/** aggregated selection of "mysql.user" */
["mysql_user_aggregate"]: {
		aggregate?:ModelTypes["mysql_user_aggregate_fields"],
	nodes:ModelTypes["mysql_user"][]
};
	/** aggregate fields of "mysql.user" */
["mysql_user_aggregate_fields"]: {
		avg?:ModelTypes["mysql_user_avg_fields"],
	count:number,
	max?:ModelTypes["mysql_user_max_fields"],
	min?:ModelTypes["mysql_user_min_fields"],
	stddev?:ModelTypes["mysql_user_stddev_fields"],
	stddev_pop?:ModelTypes["mysql_user_stddev_pop_fields"],
	stddev_samp?:ModelTypes["mysql_user_stddev_samp_fields"],
	sum?:ModelTypes["mysql_user_sum_fields"],
	var_pop?:ModelTypes["mysql_user_var_pop_fields"],
	var_samp?:ModelTypes["mysql_user_var_samp_fields"],
	variance?:ModelTypes["mysql_user_variance_fields"]
};
	/** aggregate avg on columns */
["mysql_user_avg_fields"]: {
		max_connections?:number,
	max_questions?:number,
	max_updates?:number,
	max_user_connections?:number,
	password_lifetime?:number,
	password_reuse_history?:number,
	password_reuse_time?:number
};
	/** Boolean expression to filter rows from the table "mysql.user". All fields are combined with a logical 'AND'. */
["mysql_user_bool_exp"]: GraphQLTypes["mysql_user_bool_exp"];
	/** unique or primary key constraints on table "mysql.user" */
["mysql_user_constraint"]: GraphQLTypes["mysql_user_constraint"];
	/** input type for incrementing numeric columns in table "mysql.user" */
["mysql_user_inc_input"]: GraphQLTypes["mysql_user_inc_input"];
	/** input type for inserting data into table "mysql.user" */
["mysql_user_insert_input"]: GraphQLTypes["mysql_user_insert_input"];
	/** aggregate max on columns */
["mysql_user_max_fields"]: {
		account_locked?:string,
	alter_priv?:string,
	alter_routine_priv?:string,
	authentication_string?:string,
	create_priv?:string,
	create_role_priv?:string,
	create_routine_priv?:string,
	create_tablespace_priv?:string,
	create_tmp_table_priv?:string,
	create_user_priv?:string,
	create_view_priv?:string,
	delete_priv?:string,
	drop_priv?:string,
	drop_role_priv?:string,
	event_priv?:string,
	execute_priv?:string,
	file_priv?:string,
	grant_priv?:string,
	host?:string,
	index_priv?:string,
	insert_priv?:string,
	lock_tables_priv?:string,
	max_connections?:ModelTypes["bigint"],
	max_questions?:ModelTypes["bigint"],
	max_updates?:ModelTypes["bigint"],
	max_user_connections?:ModelTypes["bigint"],
	password_expired?:string,
	password_last_changed?:ModelTypes["timestamp"],
	password_lifetime?:number,
	password_require_current?:string,
	password_reuse_history?:number,
	password_reuse_time?:number,
	plugin?:string,
	process_priv?:string,
	references_priv?:string,
	reload_priv?:string,
	repl_client_priv?:string,
	repl_slave_priv?:string,
	select_priv?:string,
	show_db_priv?:string,
	show_view_priv?:string,
	shutdown_priv?:string,
	ssl_type?:string,
	super_priv?:string,
	trigger_priv?:string,
	update_priv?:string,
	user?:string,
	user_attributes?:string
};
	/** aggregate min on columns */
["mysql_user_min_fields"]: {
		account_locked?:string,
	alter_priv?:string,
	alter_routine_priv?:string,
	authentication_string?:string,
	create_priv?:string,
	create_role_priv?:string,
	create_routine_priv?:string,
	create_tablespace_priv?:string,
	create_tmp_table_priv?:string,
	create_user_priv?:string,
	create_view_priv?:string,
	delete_priv?:string,
	drop_priv?:string,
	drop_role_priv?:string,
	event_priv?:string,
	execute_priv?:string,
	file_priv?:string,
	grant_priv?:string,
	host?:string,
	index_priv?:string,
	insert_priv?:string,
	lock_tables_priv?:string,
	max_connections?:ModelTypes["bigint"],
	max_questions?:ModelTypes["bigint"],
	max_updates?:ModelTypes["bigint"],
	max_user_connections?:ModelTypes["bigint"],
	password_expired?:string,
	password_last_changed?:ModelTypes["timestamp"],
	password_lifetime?:number,
	password_require_current?:string,
	password_reuse_history?:number,
	password_reuse_time?:number,
	plugin?:string,
	process_priv?:string,
	references_priv?:string,
	reload_priv?:string,
	repl_client_priv?:string,
	repl_slave_priv?:string,
	select_priv?:string,
	show_db_priv?:string,
	show_view_priv?:string,
	shutdown_priv?:string,
	ssl_type?:string,
	super_priv?:string,
	trigger_priv?:string,
	update_priv?:string,
	user?:string,
	user_attributes?:string
};
	/** response of any mutation on the table "mysql.user" */
["mysql_user_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["mysql_user"][]
};
	/** on_conflict condition type for table "mysql.user" */
["mysql_user_on_conflict"]: GraphQLTypes["mysql_user_on_conflict"];
	/** Ordering options when selecting data from "mysql.user". */
["mysql_user_order_by"]: GraphQLTypes["mysql_user_order_by"];
	/** primary key columns input for table: mysql.user */
["mysql_user_pk_columns_input"]: GraphQLTypes["mysql_user_pk_columns_input"];
	/** select columns of table "mysql.user" */
["mysql_user_select_column"]: GraphQLTypes["mysql_user_select_column"];
	/** input type for updating data in table "mysql.user" */
["mysql_user_set_input"]: GraphQLTypes["mysql_user_set_input"];
	/** aggregate stddev on columns */
["mysql_user_stddev_fields"]: {
		max_connections?:number,
	max_questions?:number,
	max_updates?:number,
	max_user_connections?:number,
	password_lifetime?:number,
	password_reuse_history?:number,
	password_reuse_time?:number
};
	/** aggregate stddev_pop on columns */
["mysql_user_stddev_pop_fields"]: {
		max_connections?:number,
	max_questions?:number,
	max_updates?:number,
	max_user_connections?:number,
	password_lifetime?:number,
	password_reuse_history?:number,
	password_reuse_time?:number
};
	/** aggregate stddev_samp on columns */
["mysql_user_stddev_samp_fields"]: {
		max_connections?:number,
	max_questions?:number,
	max_updates?:number,
	max_user_connections?:number,
	password_lifetime?:number,
	password_reuse_history?:number,
	password_reuse_time?:number
};
	/** Streaming cursor of the table "mysql_user" */
["mysql_user_stream_cursor_input"]: GraphQLTypes["mysql_user_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["mysql_user_stream_cursor_value_input"]: GraphQLTypes["mysql_user_stream_cursor_value_input"];
	/** aggregate sum on columns */
["mysql_user_sum_fields"]: {
		max_connections?:ModelTypes["bigint"],
	max_questions?:ModelTypes["bigint"],
	max_updates?:ModelTypes["bigint"],
	max_user_connections?:ModelTypes["bigint"],
	password_lifetime?:number,
	password_reuse_history?:number,
	password_reuse_time?:number
};
	/** update columns of table "mysql.user" */
["mysql_user_update_column"]: GraphQLTypes["mysql_user_update_column"];
	["mysql_user_updates"]: GraphQLTypes["mysql_user_updates"];
	/** aggregate var_pop on columns */
["mysql_user_var_pop_fields"]: {
		max_connections?:number,
	max_questions?:number,
	max_updates?:number,
	max_user_connections?:number,
	password_lifetime?:number,
	password_reuse_history?:number,
	password_reuse_time?:number
};
	/** aggregate var_samp on columns */
["mysql_user_var_samp_fields"]: {
		max_connections?:number,
	max_questions?:number,
	max_updates?:number,
	max_user_connections?:number,
	password_lifetime?:number,
	password_reuse_history?:number,
	password_reuse_time?:number
};
	/** aggregate variance on columns */
["mysql_user_variance_fields"]: {
		max_connections?:number,
	max_questions?:number,
	max_updates?:number,
	max_user_connections?:number,
	password_lifetime?:number,
	password_reuse_history?:number,
	password_reuse_time?:number
};
	["numeric"]:any;
	/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
["numeric_comparison_exp"]: GraphQLTypes["numeric_comparison_exp"];
	/** column ordering options */
["order_by"]: GraphQLTypes["order_by"];
	["query_root"]: {
		/** fetch data from the table: "awsdms_validation_failures_v1" */
	awsdms_validation_failures_v1:ModelTypes["awsdms_validation_failures_v1"][],
	/** fetch aggregated fields from the table: "awsdms_validation_failures_v1" */
	awsdms_validation_failures_v1_aggregate:ModelTypes["awsdms_validation_failures_v1_aggregate"],
	/** fetch data from the table: "coinsystem.balances" */
	coinsystem_balances:ModelTypes["coinsystem_balances"][],
	/** fetch aggregated fields from the table: "coinsystem.balances" */
	coinsystem_balances_aggregate:ModelTypes["coinsystem_balances_aggregate"],
	/** fetch data from the table: "coinsystem.balances" using primary key columns */
	coinsystem_balances_by_pk?:ModelTypes["coinsystem_balances"],
	/** fetch data from the table: "coinsystem.bonus_coins" */
	coinsystem_bonus_coins:ModelTypes["coinsystem_bonus_coins"][],
	/** fetch aggregated fields from the table: "coinsystem.bonus_coins" */
	coinsystem_bonus_coins_aggregate:ModelTypes["coinsystem_bonus_coins_aggregate"],
	/** fetch data from the table: "coinsystem.bonus_coins" using primary key columns */
	coinsystem_bonus_coins_by_pk?:ModelTypes["coinsystem_bonus_coins"],
	/** fetch data from the table: "coinsystem.coins" */
	coinsystem_coins:ModelTypes["coinsystem_coins"][],
	/** fetch aggregated fields from the table: "coinsystem.coins" */
	coinsystem_coins_aggregate:ModelTypes["coinsystem_coins_aggregate"],
	/** fetch data from the table: "coinsystem.coins" using primary key columns */
	coinsystem_coins_by_pk?:ModelTypes["coinsystem_coins"],
	/** fetch data from the table: "coinsystem.exchange_rates" */
	coinsystem_exchange_rates:ModelTypes["coinsystem_exchange_rates"][],
	/** fetch aggregated fields from the table: "coinsystem.exchange_rates" */
	coinsystem_exchange_rates_aggregate:ModelTypes["coinsystem_exchange_rates_aggregate"],
	/** fetch data from the table: "coinsystem.exchange_rates" using primary key columns */
	coinsystem_exchange_rates_by_pk?:ModelTypes["coinsystem_exchange_rates"],
	/** fetch data from the table: "coinsystem.logs" */
	coinsystem_logs:ModelTypes["coinsystem_logs"][],
	/** fetch aggregated fields from the table: "coinsystem.logs" */
	coinsystem_logs_aggregate:ModelTypes["coinsystem_logs_aggregate"],
	/** fetch data from the table: "coinsystem.logs" using primary key columns */
	coinsystem_logs_by_pk?:ModelTypes["coinsystem_logs"],
	/** fetch data from the table: "coinsystem.transactions" */
	coinsystem_transactions:ModelTypes["coinsystem_transactions"][],
	/** fetch aggregated fields from the table: "coinsystem.transactions" */
	coinsystem_transactions_aggregate:ModelTypes["coinsystem_transactions_aggregate"],
	/** fetch data from the table: "coinsystem.transactions" using primary key columns */
	coinsystem_transactions_by_pk?:ModelTypes["coinsystem_transactions"],
	/** fetch data from the table: "coinsystem.wallets" */
	coinsystem_wallets:ModelTypes["coinsystem_wallets"][],
	/** fetch aggregated fields from the table: "coinsystem.wallets" */
	coinsystem_wallets_aggregate:ModelTypes["coinsystem_wallets_aggregate"],
	/** fetch data from the table: "coinsystem.wallets" using primary key columns */
	coinsystem_wallets_by_pk?:ModelTypes["coinsystem_wallets"],
	/** fetch data from the table: "mysql.user" */
	mysql_user:ModelTypes["mysql_user"][],
	/** fetch aggregated fields from the table: "mysql.user" */
	mysql_user_aggregate:ModelTypes["mysql_user_aggregate"],
	/** fetch data from the table: "mysql.user" using primary key columns */
	mysql_user_by_pk?:ModelTypes["mysql_user"],
	/** fetch data from the table: "store.payments" */
	store_payments:ModelTypes["store_payments"][],
	/** fetch aggregated fields from the table: "store.payments" */
	store_payments_aggregate:ModelTypes["store_payments_aggregate"],
	/** fetch data from the table: "store.payments" using primary key columns */
	store_payments_by_pk?:ModelTypes["store_payments"],
	/** fetch data from the table: "store.products" */
	store_products:ModelTypes["store_products"][],
	/** fetch aggregated fields from the table: "store.products" */
	store_products_aggregate:ModelTypes["store_products_aggregate"],
	/** fetch data from the table: "store.products" using primary key columns */
	store_products_by_pk?:ModelTypes["store_products"],
	/** fetch data from the table: "store.purchases" */
	store_purchases:ModelTypes["store_purchases"][],
	/** fetch aggregated fields from the table: "store.purchases" */
	store_purchases_aggregate:ModelTypes["store_purchases_aggregate"],
	/** fetch data from the table: "store.purchases" using primary key columns */
	store_purchases_by_pk?:ModelTypes["store_purchases"],
	/** fetch data from the table: "store.users" */
	store_users:ModelTypes["store_users"][],
	/** fetch aggregated fields from the table: "store.users" */
	store_users_aggregate:ModelTypes["store_users_aggregate"],
	test?:ModelTypes["AddResult"],
	/** fetch data from the table: "voyce.admin_roles" */
	voyce_admin_roles:ModelTypes["voyce_admin_roles"][],
	/** fetch aggregated fields from the table: "voyce.admin_roles" */
	voyce_admin_roles_aggregate:ModelTypes["voyce_admin_roles_aggregate"],
	/** fetch data from the table: "voyce.admin_roles" using primary key columns */
	voyce_admin_roles_by_pk?:ModelTypes["voyce_admin_roles"],
	/** fetch data from the table: "voyce.app_banners" */
	voyce_app_banners:ModelTypes["voyce_app_banners"][],
	/** fetch aggregated fields from the table: "voyce.app_banners" */
	voyce_app_banners_aggregate:ModelTypes["voyce_app_banners_aggregate"],
	/** fetch data from the table: "voyce.app_banners" using primary key columns */
	voyce_app_banners_by_pk?:ModelTypes["voyce_app_banners"],
	/** fetch data from the table: "voyce.app_components" */
	voyce_app_components:ModelTypes["voyce_app_components"][],
	/** fetch aggregated fields from the table: "voyce.app_components" */
	voyce_app_components_aggregate:ModelTypes["voyce_app_components_aggregate"],
	/** fetch data from the table: "voyce.app_components" using primary key columns */
	voyce_app_components_by_pk?:ModelTypes["voyce_app_components"],
	/** fetch data from the table: "voyce.audit_trail" */
	voyce_audit_trail:ModelTypes["voyce_audit_trail"][],
	/** fetch aggregated fields from the table: "voyce.audit_trail" */
	voyce_audit_trail_aggregate:ModelTypes["voyce_audit_trail_aggregate"],
	/** fetch data from the table: "voyce.audit_trail" using primary key columns */
	voyce_audit_trail_by_pk?:ModelTypes["voyce_audit_trail"],
	/** fetch data from the table: "voyce.author_profiles" */
	voyce_author_profiles:ModelTypes["voyce_author_profiles"][],
	/** fetch aggregated fields from the table: "voyce.author_profiles" */
	voyce_author_profiles_aggregate:ModelTypes["voyce_author_profiles_aggregate"],
	/** fetch data from the table: "voyce.author_profiles" using primary key columns */
	voyce_author_profiles_by_pk?:ModelTypes["voyce_author_profiles"],
	/** fetch data from the table: "voyce.balances" */
	voyce_balances:ModelTypes["voyce_balances"][],
	/** fetch aggregated fields from the table: "voyce.balances" */
	voyce_balances_aggregate:ModelTypes["voyce_balances_aggregate"],
	/** fetch data from the table: "voyce.balances" using primary key columns */
	voyce_balances_by_pk?:ModelTypes["voyce_balances"],
	/** fetch data from the table: "voyce.banners" */
	voyce_banners:ModelTypes["voyce_banners"][],
	/** fetch aggregated fields from the table: "voyce.banners" */
	voyce_banners_aggregate:ModelTypes["voyce_banners_aggregate"],
	/** fetch data from the table: "voyce.banners" using primary key columns */
	voyce_banners_by_pk?:ModelTypes["voyce_banners"],
	/** fetch data from the table: "voyce.chapter_assets" */
	voyce_chapter_assets:ModelTypes["voyce_chapter_assets"][],
	/** fetch aggregated fields from the table: "voyce.chapter_assets" */
	voyce_chapter_assets_aggregate:ModelTypes["voyce_chapter_assets_aggregate"],
	/** fetch data from the table: "voyce.chapter_assets" using primary key columns */
	voyce_chapter_assets_by_pk?:ModelTypes["voyce_chapter_assets"],
	/** fetch data from the table: "voyce.chapter_bookmarks" */
	voyce_chapter_bookmarks:ModelTypes["voyce_chapter_bookmarks"][],
	/** fetch aggregated fields from the table: "voyce.chapter_bookmarks" */
	voyce_chapter_bookmarks_aggregate:ModelTypes["voyce_chapter_bookmarks_aggregate"],
	/** fetch data from the table: "voyce.chapter_bookmarks" using primary key columns */
	voyce_chapter_bookmarks_by_pk?:ModelTypes["voyce_chapter_bookmarks"],
	/** fetch data from the table: "voyce.chapter_comments" */
	voyce_chapter_comments:ModelTypes["voyce_chapter_comments"][],
	/** fetch data from the table: "voyce.chapter_comments_07252024" */
	voyce_chapter_comments_07252024:ModelTypes["voyce_chapter_comments_07252024"][],
	/** fetch aggregated fields from the table: "voyce.chapter_comments_07252024" */
	voyce_chapter_comments_07252024_aggregate:ModelTypes["voyce_chapter_comments_07252024_aggregate"],
	/** fetch aggregated fields from the table: "voyce.chapter_comments" */
	voyce_chapter_comments_aggregate:ModelTypes["voyce_chapter_comments_aggregate"],
	/** fetch data from the table: "voyce.chapter_comments" using primary key columns */
	voyce_chapter_comments_by_pk?:ModelTypes["voyce_chapter_comments"],
	/** fetch data from the table: "voyce.chapter_comments_reaction" */
	voyce_chapter_comments_reaction:ModelTypes["voyce_chapter_comments_reaction"][],
	/** fetch aggregated fields from the table: "voyce.chapter_comments_reaction" */
	voyce_chapter_comments_reaction_aggregate:ModelTypes["voyce_chapter_comments_reaction_aggregate"],
	/** fetch data from the table: "voyce.chapter_comments_reaction" using primary key columns */
	voyce_chapter_comments_reaction_by_pk?:ModelTypes["voyce_chapter_comments_reaction"],
	/** fetch data from the table: "voyce.chapter_comments_reaction_count" */
	voyce_chapter_comments_reaction_count:ModelTypes["voyce_chapter_comments_reaction_count"][],
	/** fetch aggregated fields from the table: "voyce.chapter_comments_reaction_count" */
	voyce_chapter_comments_reaction_count_aggregate:ModelTypes["voyce_chapter_comments_reaction_count_aggregate"],
	/** fetch data from the table: "voyce.chapter_comments_reaction_count" using primary key columns */
	voyce_chapter_comments_reaction_count_by_pk?:ModelTypes["voyce_chapter_comments_reaction_count"],
	/** fetch data from the table: "voyce.chapter_comments_reports" */
	voyce_chapter_comments_reports:ModelTypes["voyce_chapter_comments_reports"][],
	/** fetch aggregated fields from the table: "voyce.chapter_comments_reports" */
	voyce_chapter_comments_reports_aggregate:ModelTypes["voyce_chapter_comments_reports_aggregate"],
	/** fetch data from the table: "voyce.chapter_comments_reports" using primary key columns */
	voyce_chapter_comments_reports_by_pk?:ModelTypes["voyce_chapter_comments_reports"],
	/** fetch data from the table: "voyce.chapter_contents" */
	voyce_chapter_contents:ModelTypes["voyce_chapter_contents"][],
	/** fetch aggregated fields from the table: "voyce.chapter_contents" */
	voyce_chapter_contents_aggregate:ModelTypes["voyce_chapter_contents_aggregate"],
	/** fetch data from the table: "voyce.chapter_contents" using primary key columns */
	voyce_chapter_contents_by_pk?:ModelTypes["voyce_chapter_contents"],
	/** fetch data from the table: "voyce.chapter_contents_old" */
	voyce_chapter_contents_old:ModelTypes["voyce_chapter_contents_old"][],
	/** fetch aggregated fields from the table: "voyce.chapter_contents_old" */
	voyce_chapter_contents_old_aggregate:ModelTypes["voyce_chapter_contents_old_aggregate"],
	/** fetch data from the table: "voyce.chapter_contents_old" using primary key columns */
	voyce_chapter_contents_old_by_pk?:ModelTypes["voyce_chapter_contents_old"],
	/** fetch data from the table: "voyce.chapter_effects" */
	voyce_chapter_effects:ModelTypes["voyce_chapter_effects"][],
	/** fetch aggregated fields from the table: "voyce.chapter_effects" */
	voyce_chapter_effects_aggregate:ModelTypes["voyce_chapter_effects_aggregate"],
	/** fetch data from the table: "voyce.chapter_effects" using primary key columns */
	voyce_chapter_effects_by_pk?:ModelTypes["voyce_chapter_effects"],
	/** fetch data from the table: "voyce.chapter_images" */
	voyce_chapter_images:ModelTypes["voyce_chapter_images"][],
	/** fetch data from the table: "voyce.chapter_images_07252024" */
	voyce_chapter_images_07252024:ModelTypes["voyce_chapter_images_07252024"][],
	/** fetch aggregated fields from the table: "voyce.chapter_images_07252024" */
	voyce_chapter_images_07252024_aggregate:ModelTypes["voyce_chapter_images_07252024_aggregate"],
	/** fetch aggregated fields from the table: "voyce.chapter_images" */
	voyce_chapter_images_aggregate:ModelTypes["voyce_chapter_images_aggregate"],
	/** fetch data from the table: "voyce.chapter_images" using primary key columns */
	voyce_chapter_images_by_pk?:ModelTypes["voyce_chapter_images"],
	/** fetch data from the table: "voyce.chapter_likes" */
	voyce_chapter_likes:ModelTypes["voyce_chapter_likes"][],
	/** fetch aggregated fields from the table: "voyce.chapter_likes" */
	voyce_chapter_likes_aggregate:ModelTypes["voyce_chapter_likes_aggregate"],
	/** fetch data from the table: "voyce.chapter_likes" using primary key columns */
	voyce_chapter_likes_by_pk?:ModelTypes["voyce_chapter_likes"],
	/** fetch data from the table: "voyce.chapter_locks" */
	voyce_chapter_locks:ModelTypes["voyce_chapter_locks"][],
	/** fetch aggregated fields from the table: "voyce.chapter_locks" */
	voyce_chapter_locks_aggregate:ModelTypes["voyce_chapter_locks_aggregate"],
	/** fetch data from the table: "voyce.chapter_locks" using primary key columns */
	voyce_chapter_locks_by_pk?:ModelTypes["voyce_chapter_locks"],
	/** fetch data from the table: "voyce.chapter_panel_views" */
	voyce_chapter_panel_views:ModelTypes["voyce_chapter_panel_views"][],
	/** fetch aggregated fields from the table: "voyce.chapter_panel_views" */
	voyce_chapter_panel_views_aggregate:ModelTypes["voyce_chapter_panel_views_aggregate"],
	/** fetch data from the table: "voyce.chapter_panel_views" using primary key columns */
	voyce_chapter_panel_views_by_pk?:ModelTypes["voyce_chapter_panel_views"],
	/** fetch data from the table: "voyce.chapter_panels" */
	voyce_chapter_panels:ModelTypes["voyce_chapter_panels"][],
	/** fetch aggregated fields from the table: "voyce.chapter_panels" */
	voyce_chapter_panels_aggregate:ModelTypes["voyce_chapter_panels_aggregate"],
	/** fetch data from the table: "voyce.chapter_panels" using primary key columns */
	voyce_chapter_panels_by_pk?:ModelTypes["voyce_chapter_panels"],
	/** fetch data from the table: "voyce.chapter_publish_schedules" */
	voyce_chapter_publish_schedules:ModelTypes["voyce_chapter_publish_schedules"][],
	/** fetch aggregated fields from the table: "voyce.chapter_publish_schedules" */
	voyce_chapter_publish_schedules_aggregate:ModelTypes["voyce_chapter_publish_schedules_aggregate"],
	/** fetch data from the table: "voyce.chapter_publish_schedules" using primary key columns */
	voyce_chapter_publish_schedules_by_pk?:ModelTypes["voyce_chapter_publish_schedules"],
	/** fetch data from the table: "voyce.chapter_reactions" */
	voyce_chapter_reactions:ModelTypes["voyce_chapter_reactions"][],
	/** fetch aggregated fields from the table: "voyce.chapter_reactions" */
	voyce_chapter_reactions_aggregate:ModelTypes["voyce_chapter_reactions_aggregate"],
	/** fetch data from the table: "voyce.chapter_reactions" using primary key columns */
	voyce_chapter_reactions_by_pk?:ModelTypes["voyce_chapter_reactions"],
	/** fetch data from the table: "voyce.chapter_reactions_count" */
	voyce_chapter_reactions_count:ModelTypes["voyce_chapter_reactions_count"][],
	/** fetch aggregated fields from the table: "voyce.chapter_reactions_count" */
	voyce_chapter_reactions_count_aggregate:ModelTypes["voyce_chapter_reactions_count_aggregate"],
	/** fetch data from the table: "voyce.chapter_reactions_count" using primary key columns */
	voyce_chapter_reactions_count_by_pk?:ModelTypes["voyce_chapter_reactions_count"],
	/** fetch data from the table: "voyce.chapter_view_logs" */
	voyce_chapter_view_logs:ModelTypes["voyce_chapter_view_logs"][],
	/** fetch aggregated fields from the table: "voyce.chapter_view_logs" */
	voyce_chapter_view_logs_aggregate:ModelTypes["voyce_chapter_view_logs_aggregate"],
	/** fetch data from the table: "voyce.chapter_view_logs" using primary key columns */
	voyce_chapter_view_logs_by_pk?:ModelTypes["voyce_chapter_view_logs"],
	/** fetch data from the table: "voyce.chapter_views" */
	voyce_chapter_views:ModelTypes["voyce_chapter_views"][],
	/** fetch aggregated fields from the table: "voyce.chapter_views" */
	voyce_chapter_views_aggregate:ModelTypes["voyce_chapter_views_aggregate"],
	/** fetch data from the table: "voyce.chapter_views" using primary key columns */
	voyce_chapter_views_by_pk?:ModelTypes["voyce_chapter_views"],
	/** fetch data from the table: "voyce.chapters" */
	voyce_chapters:ModelTypes["voyce_chapters"][],
	/** fetch data from the table: "voyce.chapters_07252024" */
	voyce_chapters_07252024:ModelTypes["voyce_chapters_07252024"][],
	/** fetch aggregated fields from the table: "voyce.chapters_07252024" */
	voyce_chapters_07252024_aggregate:ModelTypes["voyce_chapters_07252024_aggregate"],
	/** fetch aggregated fields from the table: "voyce.chapters" */
	voyce_chapters_aggregate:ModelTypes["voyce_chapters_aggregate"],
	/** fetch data from the table: "voyce.chapters" using primary key columns */
	voyce_chapters_by_pk?:ModelTypes["voyce_chapters"],
	/** fetch data from the table: "voyce.collaborator_roles" */
	voyce_collaborator_roles:ModelTypes["voyce_collaborator_roles"][],
	/** fetch aggregated fields from the table: "voyce.collaborator_roles" */
	voyce_collaborator_roles_aggregate:ModelTypes["voyce_collaborator_roles_aggregate"],
	/** fetch data from the table: "voyce.collaborator_roles" using primary key columns */
	voyce_collaborator_roles_by_pk?:ModelTypes["voyce_collaborator_roles"],
	/** fetch data from the table: "voyce.comic_types" */
	voyce_comic_types:ModelTypes["voyce_comic_types"][],
	/** fetch aggregated fields from the table: "voyce.comic_types" */
	voyce_comic_types_aggregate:ModelTypes["voyce_comic_types_aggregate"],
	/** fetch data from the table: "voyce.comic_types" using primary key columns */
	voyce_comic_types_by_pk?:ModelTypes["voyce_comic_types"],
	/** fetch data from the table: "voyce.comments_top_mat" */
	voyce_comments_top_mat:ModelTypes["voyce_comments_top_mat"][],
	/** fetch aggregated fields from the table: "voyce.comments_top_mat" */
	voyce_comments_top_mat_aggregate:ModelTypes["voyce_comments_top_mat_aggregate"],
	/** fetch data from the table: "voyce.community_blurbs" */
	voyce_community_blurbs:ModelTypes["voyce_community_blurbs"][],
	/** fetch aggregated fields from the table: "voyce.community_blurbs" */
	voyce_community_blurbs_aggregate:ModelTypes["voyce_community_blurbs_aggregate"],
	/** fetch data from the table: "voyce.community_blurbs" using primary key columns */
	voyce_community_blurbs_by_pk?:ModelTypes["voyce_community_blurbs"],
	/** fetch data from the table: "voyce.contact_us" */
	voyce_contact_us:ModelTypes["voyce_contact_us"][],
	/** fetch aggregated fields from the table: "voyce.contact_us" */
	voyce_contact_us_aggregate:ModelTypes["voyce_contact_us_aggregate"],
	/** fetch data from the table: "voyce.contact_us" using primary key columns */
	voyce_contact_us_by_pk?:ModelTypes["voyce_contact_us"],
	/** fetch data from the table: "voyce.content_report" */
	voyce_content_report:ModelTypes["voyce_content_report"][],
	/** fetch aggregated fields from the table: "voyce.content_report" */
	voyce_content_report_aggregate:ModelTypes["voyce_content_report_aggregate"],
	/** fetch data from the table: "voyce.content_report" using primary key columns */
	voyce_content_report_by_pk?:ModelTypes["voyce_content_report"],
	/** fetch data from the table: "voyce.contest_data" */
	voyce_contest_data:ModelTypes["voyce_contest_data"][],
	/** fetch aggregated fields from the table: "voyce.contest_data" */
	voyce_contest_data_aggregate:ModelTypes["voyce_contest_data_aggregate"],
	/** fetch data from the table: "voyce.contest_data" using primary key columns */
	voyce_contest_data_by_pk?:ModelTypes["voyce_contest_data"],
	/** fetch data from the table: "voyce.countries" */
	voyce_countries:ModelTypes["voyce_countries"][],
	/** fetch aggregated fields from the table: "voyce.countries" */
	voyce_countries_aggregate:ModelTypes["voyce_countries_aggregate"],
	/** fetch data from the table: "voyce.countries" using primary key columns */
	voyce_countries_by_pk?:ModelTypes["voyce_countries"],
	/** fetch data from the table: "voyce.csat_ratings" */
	voyce_csat_ratings:ModelTypes["voyce_csat_ratings"][],
	/** fetch aggregated fields from the table: "voyce.csat_ratings" */
	voyce_csat_ratings_aggregate:ModelTypes["voyce_csat_ratings_aggregate"],
	/** fetch data from the table: "voyce.csat_ratings" using primary key columns */
	voyce_csat_ratings_by_pk?:ModelTypes["voyce_csat_ratings"],
	/** fetch data from the table: "voyce.csat_ratings_data" */
	voyce_csat_ratings_data:ModelTypes["voyce_csat_ratings_data"][],
	/** fetch aggregated fields from the table: "voyce.csat_ratings_data" */
	voyce_csat_ratings_data_aggregate:ModelTypes["voyce_csat_ratings_data_aggregate"],
	/** fetch data from the table: "voyce.csat_ratings_data" using primary key columns */
	voyce_csat_ratings_data_by_pk?:ModelTypes["voyce_csat_ratings_data"],
	/** fetch data from the table: "voyce.education_types" */
	voyce_education_types:ModelTypes["voyce_education_types"][],
	/** fetch aggregated fields from the table: "voyce.education_types" */
	voyce_education_types_aggregate:ModelTypes["voyce_education_types_aggregate"],
	/** fetch data from the table: "voyce.education_types" using primary key columns */
	voyce_education_types_by_pk?:ModelTypes["voyce_education_types"],
	/** fetch data from the table: "voyce.erp_login_roles" */
	voyce_erp_login_roles:ModelTypes["voyce_erp_login_roles"][],
	/** fetch aggregated fields from the table: "voyce.erp_login_roles" */
	voyce_erp_login_roles_aggregate:ModelTypes["voyce_erp_login_roles_aggregate"],
	/** fetch data from the table: "voyce.erp_login_roles" using primary key columns */
	voyce_erp_login_roles_by_pk?:ModelTypes["voyce_erp_login_roles"],
	/** fetch data from the table: "voyce.etl_series_views_logs" */
	voyce_etl_series_views_logs:ModelTypes["voyce_etl_series_views_logs"][],
	/** fetch aggregated fields from the table: "voyce.etl_series_views_logs" */
	voyce_etl_series_views_logs_aggregate:ModelTypes["voyce_etl_series_views_logs_aggregate"],
	/** fetch data from the table: "voyce.etl_series_views_logs" using primary key columns */
	voyce_etl_series_views_logs_by_pk?:ModelTypes["voyce_etl_series_views_logs"],
	/** fetch data from the table: "voyce.event_schedules" */
	voyce_event_schedules:ModelTypes["voyce_event_schedules"][],
	/** fetch aggregated fields from the table: "voyce.event_schedules" */
	voyce_event_schedules_aggregate:ModelTypes["voyce_event_schedules_aggregate"],
	/** fetch data from the table: "voyce.event_schedules" using primary key columns */
	voyce_event_schedules_by_pk?:ModelTypes["voyce_event_schedules"],
	/** fetch data from the table: "voyce.genres" */
	voyce_genres:ModelTypes["voyce_genres"][],
	/** fetch aggregated fields from the table: "voyce.genres" */
	voyce_genres_aggregate:ModelTypes["voyce_genres_aggregate"],
	/** fetch data from the table: "voyce.genres" using primary key columns */
	voyce_genres_by_pk?:ModelTypes["voyce_genres"],
	/** fetch data from the table: "voyce.guest_gtags" */
	voyce_guest_gtags:ModelTypes["voyce_guest_gtags"][],
	/** fetch aggregated fields from the table: "voyce.guest_gtags" */
	voyce_guest_gtags_aggregate:ModelTypes["voyce_guest_gtags_aggregate"],
	/** fetch data from the table: "voyce.guest_gtags" using primary key columns */
	voyce_guest_gtags_by_pk?:ModelTypes["voyce_guest_gtags"],
	/** fetch data from the table: "voyce.metabases" */
	voyce_metabases:ModelTypes["voyce_metabases"][],
	/** fetch data from the table: "voyce.metabases_access" */
	voyce_metabases_access:ModelTypes["voyce_metabases_access"][],
	/** fetch aggregated fields from the table: "voyce.metabases_access" */
	voyce_metabases_access_aggregate:ModelTypes["voyce_metabases_access_aggregate"],
	/** fetch data from the table: "voyce.metabases_access" using primary key columns */
	voyce_metabases_access_by_pk?:ModelTypes["voyce_metabases_access"],
	/** fetch aggregated fields from the table: "voyce.metabases" */
	voyce_metabases_aggregate:ModelTypes["voyce_metabases_aggregate"],
	/** fetch data from the table: "voyce.metabases" using primary key columns */
	voyce_metabases_by_pk?:ModelTypes["voyce_metabases"],
	/** fetch data from the table: "voyce.newsletter" */
	voyce_newsletter:ModelTypes["voyce_newsletter"][],
	/** fetch aggregated fields from the table: "voyce.newsletter" */
	voyce_newsletter_aggregate:ModelTypes["voyce_newsletter_aggregate"],
	/** fetch data from the table: "voyce.newsletter" using primary key columns */
	voyce_newsletter_by_pk?:ModelTypes["voyce_newsletter"],
	/** fetch data from the table: "voyce.notification_series_settings" */
	voyce_notification_series_settings:ModelTypes["voyce_notification_series_settings"][],
	/** fetch aggregated fields from the table: "voyce.notification_series_settings" */
	voyce_notification_series_settings_aggregate:ModelTypes["voyce_notification_series_settings_aggregate"],
	/** fetch data from the table: "voyce.notification_series_settings" using primary key columns */
	voyce_notification_series_settings_by_pk?:ModelTypes["voyce_notification_series_settings"],
	/** fetch data from the table: "voyce.notification_settings" */
	voyce_notification_settings:ModelTypes["voyce_notification_settings"][],
	/** fetch aggregated fields from the table: "voyce.notification_settings" */
	voyce_notification_settings_aggregate:ModelTypes["voyce_notification_settings_aggregate"],
	/** fetch data from the table: "voyce.notification_settings" using primary key columns */
	voyce_notification_settings_by_pk?:ModelTypes["voyce_notification_settings"],
	/** fetch data from the table: "voyce.notifications" */
	voyce_notifications:ModelTypes["voyce_notifications"][],
	/** fetch aggregated fields from the table: "voyce.notifications" */
	voyce_notifications_aggregate:ModelTypes["voyce_notifications_aggregate"],
	/** fetch data from the table: "voyce.notifications" using primary key columns */
	voyce_notifications_by_pk?:ModelTypes["voyce_notifications"],
	/** fetch data from the table: "voyce.originals_feed" */
	voyce_originals_feed:ModelTypes["voyce_originals_feed"][],
	/** fetch aggregated fields from the table: "voyce.originals_feed" */
	voyce_originals_feed_aggregate:ModelTypes["voyce_originals_feed_aggregate"],
	/** fetch data from the table: "voyce.page_banners" */
	voyce_page_banners:ModelTypes["voyce_page_banners"][],
	/** fetch aggregated fields from the table: "voyce.page_banners" */
	voyce_page_banners_aggregate:ModelTypes["voyce_page_banners_aggregate"],
	/** fetch data from the table: "voyce.page_banners" using primary key columns */
	voyce_page_banners_by_pk?:ModelTypes["voyce_page_banners"],
	/** fetch data from the table: "voyce.page_components" */
	voyce_page_components:ModelTypes["voyce_page_components"][],
	/** fetch aggregated fields from the table: "voyce.page_components" */
	voyce_page_components_aggregate:ModelTypes["voyce_page_components_aggregate"],
	/** fetch data from the table: "voyce.page_components" using primary key columns */
	voyce_page_components_by_pk?:ModelTypes["voyce_page_components"],
	/** fetch data from the table: "voyce.pages" */
	voyce_pages:ModelTypes["voyce_pages"][],
	/** fetch aggregated fields from the table: "voyce.pages" */
	voyce_pages_aggregate:ModelTypes["voyce_pages_aggregate"],
	/** fetch data from the table: "voyce.pages" using primary key columns */
	voyce_pages_by_pk?:ModelTypes["voyce_pages"],
	/** fetch data from the table: "voyce.panels_comments" */
	voyce_panels_comments:ModelTypes["voyce_panels_comments"][],
	/** fetch aggregated fields from the table: "voyce.panels_comments" */
	voyce_panels_comments_aggregate:ModelTypes["voyce_panels_comments_aggregate"],
	/** fetch data from the table: "voyce.panels_comments" using primary key columns */
	voyce_panels_comments_by_pk?:ModelTypes["voyce_panels_comments"],
	/** fetch data from the table: "voyce.panels_reactions" */
	voyce_panels_reactions:ModelTypes["voyce_panels_reactions"][],
	/** fetch aggregated fields from the table: "voyce.panels_reactions" */
	voyce_panels_reactions_aggregate:ModelTypes["voyce_panels_reactions_aggregate"],
	/** fetch data from the table: "voyce.panels_reactions" using primary key columns */
	voyce_panels_reactions_by_pk?:ModelTypes["voyce_panels_reactions"],
	/** fetch data from the table: "voyce.panels_reactions_count" */
	voyce_panels_reactions_count:ModelTypes["voyce_panels_reactions_count"][],
	/** fetch aggregated fields from the table: "voyce.panels_reactions_count" */
	voyce_panels_reactions_count_aggregate:ModelTypes["voyce_panels_reactions_count_aggregate"],
	/** fetch data from the table: "voyce.panels_reactions_count" using primary key columns */
	voyce_panels_reactions_count_by_pk?:ModelTypes["voyce_panels_reactions_count"],
	/** fetch data from the table: "voyce.payments_to_creators" */
	voyce_payments_to_creators:ModelTypes["voyce_payments_to_creators"][],
	/** fetch aggregated fields from the table: "voyce.payments_to_creators" */
	voyce_payments_to_creators_aggregate:ModelTypes["voyce_payments_to_creators_aggregate"],
	/** fetch data from the table: "voyce.payments_to_creators" using primary key columns */
	voyce_payments_to_creators_by_pk?:ModelTypes["voyce_payments_to_creators"],
	/** fetch data from the table: "voyce.pg_rs_series_views_count" */
	voyce_pg_rs_series_views_count:ModelTypes["voyce_pg_rs_series_views_count"][],
	/** fetch aggregated fields from the table: "voyce.pg_rs_series_views_count" */
	voyce_pg_rs_series_views_count_aggregate:ModelTypes["voyce_pg_rs_series_views_count_aggregate"],
	/** fetch data from the table: "voyce.pg_rs_series_views_count" using primary key columns */
	voyce_pg_rs_series_views_count_by_pk?:ModelTypes["voyce_pg_rs_series_views_count"],
	/** fetch data from the table: "voyce.polls" */
	voyce_polls:ModelTypes["voyce_polls"][],
	/** fetch aggregated fields from the table: "voyce.polls" */
	voyce_polls_aggregate:ModelTypes["voyce_polls_aggregate"],
	/** fetch data from the table: "voyce.polls_answers" */
	voyce_polls_answers:ModelTypes["voyce_polls_answers"][],
	/** fetch aggregated fields from the table: "voyce.polls_answers" */
	voyce_polls_answers_aggregate:ModelTypes["voyce_polls_answers_aggregate"],
	/** fetch data from the table: "voyce.polls_answers" using primary key columns */
	voyce_polls_answers_by_pk?:ModelTypes["voyce_polls_answers"],
	/** fetch data from the table: "voyce.polls" using primary key columns */
	voyce_polls_by_pk?:ModelTypes["voyce_polls"],
	/** fetch data from the table: "voyce.polls_comments" */
	voyce_polls_comments:ModelTypes["voyce_polls_comments"][],
	/** fetch aggregated fields from the table: "voyce.polls_comments" */
	voyce_polls_comments_aggregate:ModelTypes["voyce_polls_comments_aggregate"],
	/** fetch data from the table: "voyce.polls_comments" using primary key columns */
	voyce_polls_comments_by_pk?:ModelTypes["voyce_polls_comments"],
	/** fetch data from the table: "voyce.polls_reactions" */
	voyce_polls_reactions:ModelTypes["voyce_polls_reactions"][],
	/** fetch aggregated fields from the table: "voyce.polls_reactions" */
	voyce_polls_reactions_aggregate:ModelTypes["voyce_polls_reactions_aggregate"],
	/** fetch data from the table: "voyce.polls_reactions" using primary key columns */
	voyce_polls_reactions_by_pk?:ModelTypes["voyce_polls_reactions"],
	/** fetch data from the table: "voyce.polls_votes" */
	voyce_polls_votes:ModelTypes["voyce_polls_votes"][],
	/** fetch aggregated fields from the table: "voyce.polls_votes" */
	voyce_polls_votes_aggregate:ModelTypes["voyce_polls_votes_aggregate"],
	/** fetch data from the table: "voyce.polls_votes" using primary key columns */
	voyce_polls_votes_by_pk?:ModelTypes["voyce_polls_votes"],
	/** fetch data from the table: "voyce.popup_components" */
	voyce_popup_components:ModelTypes["voyce_popup_components"][],
	/** fetch aggregated fields from the table: "voyce.popup_components" */
	voyce_popup_components_aggregate:ModelTypes["voyce_popup_components_aggregate"],
	/** fetch data from the table: "voyce.popup_components" using primary key columns */
	voyce_popup_components_by_pk?:ModelTypes["voyce_popup_components"],
	/** fetch data from the table: "voyce.popup_emails" */
	voyce_popup_emails:ModelTypes["voyce_popup_emails"][],
	/** fetch aggregated fields from the table: "voyce.popup_emails" */
	voyce_popup_emails_aggregate:ModelTypes["voyce_popup_emails_aggregate"],
	/** fetch data from the table: "voyce.popup_emails" using primary key columns */
	voyce_popup_emails_by_pk?:ModelTypes["voyce_popup_emails"],
	/** fetch data from the table: "voyce.posts" */
	voyce_posts:ModelTypes["voyce_posts"][],
	/** fetch aggregated fields from the table: "voyce.posts" */
	voyce_posts_aggregate:ModelTypes["voyce_posts_aggregate"],
	/** fetch data from the table: "voyce.posts" using primary key columns */
	voyce_posts_by_pk?:ModelTypes["voyce_posts"],
	/** fetch data from the table: "voyce.posts_comments" */
	voyce_posts_comments:ModelTypes["voyce_posts_comments"][],
	/** fetch aggregated fields from the table: "voyce.posts_comments" */
	voyce_posts_comments_aggregate:ModelTypes["voyce_posts_comments_aggregate"],
	/** fetch data from the table: "voyce.posts_comments" using primary key columns */
	voyce_posts_comments_by_pk?:ModelTypes["voyce_posts_comments"],
	/** fetch data from the table: "voyce.posts_reactions" */
	voyce_posts_reactions:ModelTypes["voyce_posts_reactions"][],
	/** fetch aggregated fields from the table: "voyce.posts_reactions" */
	voyce_posts_reactions_aggregate:ModelTypes["voyce_posts_reactions_aggregate"],
	/** fetch data from the table: "voyce.posts_reactions" using primary key columns */
	voyce_posts_reactions_by_pk?:ModelTypes["voyce_posts_reactions"],
	/** fetch data from the table: "voyce.quest_difficulties" */
	voyce_quest_difficulties:ModelTypes["voyce_quest_difficulties"][],
	/** fetch aggregated fields from the table: "voyce.quest_difficulties" */
	voyce_quest_difficulties_aggregate:ModelTypes["voyce_quest_difficulties_aggregate"],
	/** fetch data from the table: "voyce.quest_difficulties" using primary key columns */
	voyce_quest_difficulties_by_pk?:ModelTypes["voyce_quest_difficulties"],
	/** fetch data from the table: "voyce.quests" */
	voyce_quests:ModelTypes["voyce_quests"][],
	/** fetch aggregated fields from the table: "voyce.quests" */
	voyce_quests_aggregate:ModelTypes["voyce_quests_aggregate"],
	/** fetch data from the table: "voyce.quests" using primary key columns */
	voyce_quests_by_pk?:ModelTypes["voyce_quests"],
	/** fetch data from the table: "voyce.quests_old" */
	voyce_quests_old:ModelTypes["voyce_quests_old"][],
	/** fetch aggregated fields from the table: "voyce.quests_old" */
	voyce_quests_old_aggregate:ModelTypes["voyce_quests_old_aggregate"],
	/** fetch data from the table: "voyce.quests_old" using primary key columns */
	voyce_quests_old_by_pk?:ModelTypes["voyce_quests_old"],
	/** fetch data from the table: "voyce.recommended_series" */
	voyce_recommended_series:ModelTypes["voyce_recommended_series"][],
	/** fetch aggregated fields from the table: "voyce.recommended_series" */
	voyce_recommended_series_aggregate:ModelTypes["voyce_recommended_series_aggregate"],
	/** fetch data from the table: "voyce.recommended_series" using primary key columns */
	voyce_recommended_series_by_pk?:ModelTypes["voyce_recommended_series"],
	/** fetch data from the table: "voyce.release_schedules" */
	voyce_release_schedules:ModelTypes["voyce_release_schedules"][],
	/** fetch aggregated fields from the table: "voyce.release_schedules" */
	voyce_release_schedules_aggregate:ModelTypes["voyce_release_schedules_aggregate"],
	/** fetch data from the table: "voyce.release_schedules" using primary key columns */
	voyce_release_schedules_by_pk?:ModelTypes["voyce_release_schedules"],
	/** fetch data from the table: "voyce.rewards" */
	voyce_rewards:ModelTypes["voyce_rewards"][],
	/** fetch aggregated fields from the table: "voyce.rewards" */
	voyce_rewards_aggregate:ModelTypes["voyce_rewards_aggregate"],
	/** fetch data from the table: "voyce.rewards" using primary key columns */
	voyce_rewards_by_pk?:ModelTypes["voyce_rewards"],
	/** fetch data from the table: "voyce.rewards_leaderboard" */
	voyce_rewards_leaderboard:ModelTypes["voyce_rewards_leaderboard"][],
	/** fetch aggregated fields from the table: "voyce.rewards_leaderboard" */
	voyce_rewards_leaderboard_aggregate:ModelTypes["voyce_rewards_leaderboard_aggregate"],
	/** fetch data from the table: "voyce.rewards_leaderboard" using primary key columns */
	voyce_rewards_leaderboard_by_pk?:ModelTypes["voyce_rewards_leaderboard"],
	/** fetch data from the table: "voyce.rewards_quest" */
	voyce_rewards_quest:ModelTypes["voyce_rewards_quest"][],
	/** fetch aggregated fields from the table: "voyce.rewards_quest" */
	voyce_rewards_quest_aggregate:ModelTypes["voyce_rewards_quest_aggregate"],
	/** fetch data from the table: "voyce.rewards_quest" using primary key columns */
	voyce_rewards_quest_by_pk?:ModelTypes["voyce_rewards_quest"],
	/** fetch data from the table: "voyce.sections" */
	voyce_sections:ModelTypes["voyce_sections"][],
	/** fetch aggregated fields from the table: "voyce.sections" */
	voyce_sections_aggregate:ModelTypes["voyce_sections_aggregate"],
	/** fetch data from the table: "voyce.sections" using primary key columns */
	voyce_sections_by_pk?:ModelTypes["voyce_sections"],
	/** fetch data from the table: "voyce.series" */
	voyce_series:ModelTypes["voyce_series"][],
	/** fetch aggregated fields from the table: "voyce.series" */
	voyce_series_aggregate:ModelTypes["voyce_series_aggregate"],
	/** fetch data from the table: "voyce.series" using primary key columns */
	voyce_series_by_pk?:ModelTypes["voyce_series"],
	/** fetch data from the table: "voyce.series_collaborators" */
	voyce_series_collaborators:ModelTypes["voyce_series_collaborators"][],
	/** fetch aggregated fields from the table: "voyce.series_collaborators" */
	voyce_series_collaborators_aggregate:ModelTypes["voyce_series_collaborators_aggregate"],
	/** fetch data from the table: "voyce.series_collaborators" using primary key columns */
	voyce_series_collaborators_by_pk?:ModelTypes["voyce_series_collaborators"],
	/** fetch data from the table: "voyce.series_donations" */
	voyce_series_donations:ModelTypes["voyce_series_donations"][],
	/** fetch aggregated fields from the table: "voyce.series_donations" */
	voyce_series_donations_aggregate:ModelTypes["voyce_series_donations_aggregate"],
	/** fetch data from the table: "voyce.series_donations" using primary key columns */
	voyce_series_donations_by_pk?:ModelTypes["voyce_series_donations"],
	/** fetch data from the table: "voyce.series_favorites" */
	voyce_series_favorites:ModelTypes["voyce_series_favorites"][],
	/** fetch aggregated fields from the table: "voyce.series_favorites" */
	voyce_series_favorites_aggregate:ModelTypes["voyce_series_favorites_aggregate"],
	/** fetch data from the table: "voyce.series_favorites" using primary key columns */
	voyce_series_favorites_by_pk?:ModelTypes["voyce_series_favorites"],
	/** fetch data from the table: "voyce.series_featured_panels" */
	voyce_series_featured_panels:ModelTypes["voyce_series_featured_panels"][],
	/** fetch aggregated fields from the table: "voyce.series_featured_panels" */
	voyce_series_featured_panels_aggregate:ModelTypes["voyce_series_featured_panels_aggregate"],
	/** fetch data from the table: "voyce.series_featured_panels" using primary key columns */
	voyce_series_featured_panels_by_pk?:ModelTypes["voyce_series_featured_panels"],
	/** fetch data from the table: "voyce.series_genre" */
	voyce_series_genre:ModelTypes["voyce_series_genre"][],
	/** fetch aggregated fields from the table: "voyce.series_genre" */
	voyce_series_genre_aggregate:ModelTypes["voyce_series_genre_aggregate"],
	/** fetch data from the table: "voyce.series_genre" using primary key columns */
	voyce_series_genre_by_pk?:ModelTypes["voyce_series_genre"],
	/** fetch data from the table: "voyce.series_giveaway_participants" */
	voyce_series_giveaway_participants:ModelTypes["voyce_series_giveaway_participants"][],
	/** fetch aggregated fields from the table: "voyce.series_giveaway_participants" */
	voyce_series_giveaway_participants_aggregate:ModelTypes["voyce_series_giveaway_participants_aggregate"],
	/** fetch data from the table: "voyce.series_giveaway_participants" using primary key columns */
	voyce_series_giveaway_participants_by_pk?:ModelTypes["voyce_series_giveaway_participants"],
	/** fetch data from the table: "voyce.series_hot_panels" */
	voyce_series_hot_panels:ModelTypes["voyce_series_hot_panels"][],
	/** fetch aggregated fields from the table: "voyce.series_hot_panels" */
	voyce_series_hot_panels_aggregate:ModelTypes["voyce_series_hot_panels_aggregate"],
	/** fetch data from the table: "voyce.series_hot_panels" using primary key columns */
	voyce_series_hot_panels_by_pk?:ModelTypes["voyce_series_hot_panels"],
	/** fetch data from the table: "voyce.series_layouts" */
	voyce_series_layouts:ModelTypes["voyce_series_layouts"][],
	/** fetch aggregated fields from the table: "voyce.series_layouts" */
	voyce_series_layouts_aggregate:ModelTypes["voyce_series_layouts_aggregate"],
	/** fetch data from the table: "voyce.series_layouts" using primary key columns */
	voyce_series_layouts_by_pk?:ModelTypes["voyce_series_layouts"],
	/** fetch data from the table: "voyce.series_leaderboard" */
	voyce_series_leaderboard:ModelTypes["voyce_series_leaderboard"][],
	/** fetch aggregated fields from the table: "voyce.series_leaderboard" */
	voyce_series_leaderboard_aggregate:ModelTypes["voyce_series_leaderboard_aggregate"],
	/** fetch data from the table: "voyce.series_leaderboard" using primary key columns */
	voyce_series_leaderboard_by_pk?:ModelTypes["voyce_series_leaderboard"],
	/** fetch data from the table: "voyce.series_likes_count" */
	voyce_series_likes_count:ModelTypes["voyce_series_likes_count"][],
	/** fetch aggregated fields from the table: "voyce.series_likes_count" */
	voyce_series_likes_count_aggregate:ModelTypes["voyce_series_likes_count_aggregate"],
	/** fetch data from the table: "voyce.series_likes_count_mat" */
	voyce_series_likes_count_mat:ModelTypes["voyce_series_likes_count_mat"][],
	/** fetch aggregated fields from the table: "voyce.series_likes_count_mat" */
	voyce_series_likes_count_mat_aggregate:ModelTypes["voyce_series_likes_count_mat_aggregate"],
	/** fetch data from the table: "voyce.series_lock_config" */
	voyce_series_lock_config:ModelTypes["voyce_series_lock_config"][],
	/** fetch aggregated fields from the table: "voyce.series_lock_config" */
	voyce_series_lock_config_aggregate:ModelTypes["voyce_series_lock_config_aggregate"],
	/** fetch data from the table: "voyce.series_lock_config" using primary key columns */
	voyce_series_lock_config_by_pk?:ModelTypes["voyce_series_lock_config"],
	/** fetch data from the table: "voyce.series_sponsorships_configuration" */
	voyce_series_sponsorships_configuration:ModelTypes["voyce_series_sponsorships_configuration"][],
	/** fetch aggregated fields from the table: "voyce.series_sponsorships_configuration" */
	voyce_series_sponsorships_configuration_aggregate:ModelTypes["voyce_series_sponsorships_configuration_aggregate"],
	/** fetch data from the table: "voyce.series_sponsorships_configuration" using primary key columns */
	voyce_series_sponsorships_configuration_by_pk?:ModelTypes["voyce_series_sponsorships_configuration"],
	/** fetch data from the table: "voyce.series_subscription" */
	voyce_series_subscription:ModelTypes["voyce_series_subscription"][],
	/** fetch aggregated fields from the table: "voyce.series_subscription" */
	voyce_series_subscription_aggregate:ModelTypes["voyce_series_subscription_aggregate"],
	/** fetch data from the table: "voyce.series_subscription" using primary key columns */
	voyce_series_subscription_by_pk?:ModelTypes["voyce_series_subscription"],
	/** fetch data from the table: "voyce.series_tags" */
	voyce_series_tags:ModelTypes["voyce_series_tags"][],
	/** fetch aggregated fields from the table: "voyce.series_tags" */
	voyce_series_tags_aggregate:ModelTypes["voyce_series_tags_aggregate"],
	/** fetch data from the table: "voyce.series_tags" using primary key columns */
	voyce_series_tags_by_pk?:ModelTypes["voyce_series_tags"],
	/** fetch data from the table: "voyce.series_tags_junction" */
	voyce_series_tags_junction:ModelTypes["voyce_series_tags_junction"][],
	/** fetch aggregated fields from the table: "voyce.series_tags_junction" */
	voyce_series_tags_junction_aggregate:ModelTypes["voyce_series_tags_junction_aggregate"],
	/** fetch data from the table: "voyce.series_tags_junction" using primary key columns */
	voyce_series_tags_junction_by_pk?:ModelTypes["voyce_series_tags_junction"],
	/** fetch data from the table: "voyce.series_top_comments" */
	voyce_series_top_comments:ModelTypes["voyce_series_top_comments"][],
	/** fetch aggregated fields from the table: "voyce.series_top_comments" */
	voyce_series_top_comments_aggregate:ModelTypes["voyce_series_top_comments_aggregate"],
	/** fetch data from the table: "voyce.series_top_comments" using primary key columns */
	voyce_series_top_comments_by_pk?:ModelTypes["voyce_series_top_comments"],
	/** fetch data from the table: "voyce.series_top_likes" */
	voyce_series_top_likes:ModelTypes["voyce_series_top_likes"][],
	/** fetch aggregated fields from the table: "voyce.series_top_likes" */
	voyce_series_top_likes_aggregate:ModelTypes["voyce_series_top_likes_aggregate"],
	/** fetch data from the table: "voyce.series_top_likes" using primary key columns */
	voyce_series_top_likes_by_pk?:ModelTypes["voyce_series_top_likes"],
	/** fetch data from the table: "voyce.series_top_views" */
	voyce_series_top_views:ModelTypes["voyce_series_top_views"][],
	/** fetch aggregated fields from the table: "voyce.series_top_views" */
	voyce_series_top_views_aggregate:ModelTypes["voyce_series_top_views_aggregate"],
	/** fetch data from the table: "voyce.series_top_views" using primary key columns */
	voyce_series_top_views_by_pk?:ModelTypes["voyce_series_top_views"],
	/** fetch data from the table: "voyce.series_views_adjustment" */
	voyce_series_views_adjustment:ModelTypes["voyce_series_views_adjustment"][],
	/** fetch aggregated fields from the table: "voyce.series_views_adjustment" */
	voyce_series_views_adjustment_aggregate:ModelTypes["voyce_series_views_adjustment_aggregate"],
	/** fetch data from the table: "voyce.series_views_adjustment" using primary key columns */
	voyce_series_views_adjustment_by_pk?:ModelTypes["voyce_series_views_adjustment"],
	/** fetch data from the table: "voyce.series_views_count" */
	voyce_series_views_count:ModelTypes["voyce_series_views_count"][],
	/** fetch aggregated fields from the table: "voyce.series_views_count" */
	voyce_series_views_count_aggregate:ModelTypes["voyce_series_views_count_aggregate"],
	/** fetch data from the table: "voyce.series_views_count_mat" */
	voyce_series_views_count_mat:ModelTypes["voyce_series_views_count_mat"][],
	/** fetch aggregated fields from the table: "voyce.series_views_count_mat" */
	voyce_series_views_count_mat_aggregate:ModelTypes["voyce_series_views_count_mat_aggregate"],
	/** fetch data from the table: "voyce.series_views_count_mat_sep30" */
	voyce_series_views_count_mat_sep30:ModelTypes["voyce_series_views_count_mat_sep30"][],
	/** fetch aggregated fields from the table: "voyce.series_views_count_mat_sep30" */
	voyce_series_views_count_mat_sep30_aggregate:ModelTypes["voyce_series_views_count_mat_sep30_aggregate"],
	/** fetch data from the table: "voyce.series_views_count_mat_v2" */
	voyce_series_views_count_mat_v2:ModelTypes["voyce_series_views_count_mat_v2"][],
	/** fetch aggregated fields from the table: "voyce.series_views_count_mat_v2" */
	voyce_series_views_count_mat_v2_aggregate:ModelTypes["voyce_series_views_count_mat_v2_aggregate"],
	/** fetch data from the table: "voyce.series_votes" */
	voyce_series_votes:ModelTypes["voyce_series_votes"][],
	/** fetch aggregated fields from the table: "voyce.series_votes" */
	voyce_series_votes_aggregate:ModelTypes["voyce_series_votes_aggregate"],
	/** fetch data from the table: "voyce.series_votes" using primary key columns */
	voyce_series_votes_by_pk?:ModelTypes["voyce_series_votes"],
	/** fetch data from the table: "voyce.series_votes_count" */
	voyce_series_votes_count:ModelTypes["voyce_series_votes_count"][],
	/** fetch aggregated fields from the table: "voyce.series_votes_count" */
	voyce_series_votes_count_aggregate:ModelTypes["voyce_series_votes_count_aggregate"],
	/** fetch data from the table: "voyce.series_votes_count" using primary key columns */
	voyce_series_votes_count_by_pk?:ModelTypes["voyce_series_votes_count"],
	/** fetch data from the table: "voyce.series_voting_schedules" */
	voyce_series_voting_schedules:ModelTypes["voyce_series_voting_schedules"][],
	/** fetch aggregated fields from the table: "voyce.series_voting_schedules" */
	voyce_series_voting_schedules_aggregate:ModelTypes["voyce_series_voting_schedules_aggregate"],
	/** fetch data from the table: "voyce.series_voting_schedules" using primary key columns */
	voyce_series_voting_schedules_by_pk?:ModelTypes["voyce_series_voting_schedules"],
	/** fetch data from the table: "voyce.sponsorships" */
	voyce_sponsorships:ModelTypes["voyce_sponsorships"][],
	/** fetch aggregated fields from the table: "voyce.sponsorships" */
	voyce_sponsorships_aggregate:ModelTypes["voyce_sponsorships_aggregate"],
	/** fetch data from the table: "voyce.sponsorships" using primary key columns */
	voyce_sponsorships_by_pk?:ModelTypes["voyce_sponsorships"],
	/** fetch data from the table: "voyce.states" */
	voyce_states:ModelTypes["voyce_states"][],
	/** fetch aggregated fields from the table: "voyce.states" */
	voyce_states_aggregate:ModelTypes["voyce_states_aggregate"],
	/** fetch data from the table: "voyce.states" using primary key columns */
	voyce_states_by_pk?:ModelTypes["voyce_states"],
	/** fetch data from the table: "voyce.stripe_payouts_setup" */
	voyce_stripe_payouts_setup:ModelTypes["voyce_stripe_payouts_setup"][],
	/** fetch aggregated fields from the table: "voyce.stripe_payouts_setup" */
	voyce_stripe_payouts_setup_aggregate:ModelTypes["voyce_stripe_payouts_setup_aggregate"],
	/** fetch data from the table: "voyce.stripe_payouts_setup" using primary key columns */
	voyce_stripe_payouts_setup_by_pk?:ModelTypes["voyce_stripe_payouts_setup"],
	/** fetch data from the table: "voyce.studio" */
	voyce_studio:ModelTypes["voyce_studio"][],
	/** fetch aggregated fields from the table: "voyce.studio" */
	voyce_studio_aggregate:ModelTypes["voyce_studio_aggregate"],
	/** fetch data from the table: "voyce.studio" using primary key columns */
	voyce_studio_by_pk?:ModelTypes["voyce_studio"],
	/** fetch data from the table: "voyce.studio_followers" */
	voyce_studio_followers:ModelTypes["voyce_studio_followers"][],
	/** fetch aggregated fields from the table: "voyce.studio_followers" */
	voyce_studio_followers_aggregate:ModelTypes["voyce_studio_followers_aggregate"],
	/** fetch data from the table: "voyce.studio_followers" using primary key columns */
	voyce_studio_followers_by_pk?:ModelTypes["voyce_studio_followers"],
	/** fetch data from the table: "voyce.studio_series" */
	voyce_studio_series:ModelTypes["voyce_studio_series"][],
	/** fetch aggregated fields from the table: "voyce.studio_series" */
	voyce_studio_series_aggregate:ModelTypes["voyce_studio_series_aggregate"],
	/** fetch data from the table: "voyce.studio_series" using primary key columns */
	voyce_studio_series_by_pk?:ModelTypes["voyce_studio_series"],
	/** fetch data from the table: "voyce.studio_views" */
	voyce_studio_views:ModelTypes["voyce_studio_views"][],
	/** fetch aggregated fields from the table: "voyce.studio_views" */
	voyce_studio_views_aggregate:ModelTypes["voyce_studio_views_aggregate"],
	/** fetch data from the table: "voyce.studio_views" using primary key columns */
	voyce_studio_views_by_pk?:ModelTypes["voyce_studio_views"],
	/** fetch data from the table: "voyce.system_settings" */
	voyce_system_settings:ModelTypes["voyce_system_settings"][],
	/** fetch aggregated fields from the table: "voyce.system_settings" */
	voyce_system_settings_aggregate:ModelTypes["voyce_system_settings_aggregate"],
	/** fetch data from the table: "voyce.system_settings" using primary key columns */
	voyce_system_settings_by_pk?:ModelTypes["voyce_system_settings"],
	/** fetch data from the table: "voyce.top_genres" */
	voyce_top_genres:ModelTypes["voyce_top_genres"][],
	/** fetch aggregated fields from the table: "voyce.top_genres" */
	voyce_top_genres_aggregate:ModelTypes["voyce_top_genres_aggregate"],
	/** fetch data from the table: "voyce.transaction_logs" */
	voyce_transaction_logs:ModelTypes["voyce_transaction_logs"][],
	/** fetch aggregated fields from the table: "voyce.transaction_logs" */
	voyce_transaction_logs_aggregate:ModelTypes["voyce_transaction_logs_aggregate"],
	/** fetch data from the table: "voyce.transaction_logs" using primary key columns */
	voyce_transaction_logs_by_pk?:ModelTypes["voyce_transaction_logs"],
	/** fetch data from the table: "voyce.url_redirects" */
	voyce_url_redirects:ModelTypes["voyce_url_redirects"][],
	/** fetch aggregated fields from the table: "voyce.url_redirects" */
	voyce_url_redirects_aggregate:ModelTypes["voyce_url_redirects_aggregate"],
	/** fetch data from the table: "voyce.url_redirects" using primary key columns */
	voyce_url_redirects_by_pk?:ModelTypes["voyce_url_redirects"],
	/** fetch data from the table: "voyce.user_addresses" */
	voyce_user_addresses:ModelTypes["voyce_user_addresses"][],
	/** fetch aggregated fields from the table: "voyce.user_addresses" */
	voyce_user_addresses_aggregate:ModelTypes["voyce_user_addresses_aggregate"],
	/** fetch data from the table: "voyce.user_addresses" using primary key columns */
	voyce_user_addresses_by_pk?:ModelTypes["voyce_user_addresses"],
	/** fetch data from the table: "voyce.user_blocks" */
	voyce_user_blocks:ModelTypes["voyce_user_blocks"][],
	/** fetch aggregated fields from the table: "voyce.user_blocks" */
	voyce_user_blocks_aggregate:ModelTypes["voyce_user_blocks_aggregate"],
	/** fetch data from the table: "voyce.user_blocks" using primary key columns */
	voyce_user_blocks_by_pk?:ModelTypes["voyce_user_blocks"],
	/** fetch data from the table: "voyce.user_chapters_unlocked" */
	voyce_user_chapters_unlocked:ModelTypes["voyce_user_chapters_unlocked"][],
	/** fetch aggregated fields from the table: "voyce.user_chapters_unlocked" */
	voyce_user_chapters_unlocked_aggregate:ModelTypes["voyce_user_chapters_unlocked_aggregate"],
	/** fetch data from the table: "voyce.user_chapters_unlocked" using primary key columns */
	voyce_user_chapters_unlocked_by_pk?:ModelTypes["voyce_user_chapters_unlocked"],
	/** fetch data from the table: "voyce.user_contacts" */
	voyce_user_contacts:ModelTypes["voyce_user_contacts"][],
	/** fetch aggregated fields from the table: "voyce.user_contacts" */
	voyce_user_contacts_aggregate:ModelTypes["voyce_user_contacts_aggregate"],
	/** fetch data from the table: "voyce.user_contacts" using primary key columns */
	voyce_user_contacts_by_pk?:ModelTypes["voyce_user_contacts"],
	/** fetch data from the table: "voyce.user_csat_ratings" */
	voyce_user_csat_ratings:ModelTypes["voyce_user_csat_ratings"][],
	/** fetch aggregated fields from the table: "voyce.user_csat_ratings" */
	voyce_user_csat_ratings_aggregate:ModelTypes["voyce_user_csat_ratings_aggregate"],
	/** fetch data from the table: "voyce.user_csat_ratings" using primary key columns */
	voyce_user_csat_ratings_by_pk?:ModelTypes["voyce_user_csat_ratings"],
	/** fetch data from the table: "voyce.user_education" */
	voyce_user_education:ModelTypes["voyce_user_education"][],
	/** fetch aggregated fields from the table: "voyce.user_education" */
	voyce_user_education_aggregate:ModelTypes["voyce_user_education_aggregate"],
	/** fetch data from the table: "voyce.user_education" using primary key columns */
	voyce_user_education_by_pk?:ModelTypes["voyce_user_education"],
	/** fetch data from the table: "voyce.user_level" */
	voyce_user_level:ModelTypes["voyce_user_level"][],
	/** fetch aggregated fields from the table: "voyce.user_level" */
	voyce_user_level_aggregate:ModelTypes["voyce_user_level_aggregate"],
	/** fetch data from the table: "voyce.user_level" using primary key columns */
	voyce_user_level_by_pk?:ModelTypes["voyce_user_level"],
	/** fetch data from the table: "voyce.user_linked_accounts" */
	voyce_user_linked_accounts:ModelTypes["voyce_user_linked_accounts"][],
	/** fetch aggregated fields from the table: "voyce.user_linked_accounts" */
	voyce_user_linked_accounts_aggregate:ModelTypes["voyce_user_linked_accounts_aggregate"],
	/** fetch data from the table: "voyce.user_linked_accounts" using primary key columns */
	voyce_user_linked_accounts_by_pk?:ModelTypes["voyce_user_linked_accounts"],
	/** fetch data from the table: "voyce.user_login_history" */
	voyce_user_login_history:ModelTypes["voyce_user_login_history"][],
	/** fetch aggregated fields from the table: "voyce.user_login_history" */
	voyce_user_login_history_aggregate:ModelTypes["voyce_user_login_history_aggregate"],
	/** fetch data from the table: "voyce.user_login_history" using primary key columns */
	voyce_user_login_history_by_pk?:ModelTypes["voyce_user_login_history"],
	/** fetch data from the table: "voyce.user_quest_ids" */
	voyce_user_quest_ids:ModelTypes["voyce_user_quest_ids"][],
	/** fetch aggregated fields from the table: "voyce.user_quest_ids" */
	voyce_user_quest_ids_aggregate:ModelTypes["voyce_user_quest_ids_aggregate"],
	/** fetch data from the table: "voyce.user_quest_ids" using primary key columns */
	voyce_user_quest_ids_by_pk?:ModelTypes["voyce_user_quest_ids"],
	/** fetch data from the table: "voyce.user_quest_rewards" */
	voyce_user_quest_rewards:ModelTypes["voyce_user_quest_rewards"][],
	/** fetch aggregated fields from the table: "voyce.user_quest_rewards" */
	voyce_user_quest_rewards_aggregate:ModelTypes["voyce_user_quest_rewards_aggregate"],
	/** fetch data from the table: "voyce.user_quest_rewards" using primary key columns */
	voyce_user_quest_rewards_by_pk?:ModelTypes["voyce_user_quest_rewards"],
	/** fetch data from the table: "voyce.user_quests" */
	voyce_user_quests:ModelTypes["voyce_user_quests"][],
	/** fetch aggregated fields from the table: "voyce.user_quests" */
	voyce_user_quests_aggregate:ModelTypes["voyce_user_quests_aggregate"],
	/** fetch data from the table: "voyce.user_quests" using primary key columns */
	voyce_user_quests_by_pk?:ModelTypes["voyce_user_quests"],
	/** fetch data from the table: "voyce.user_quests_old" */
	voyce_user_quests_old:ModelTypes["voyce_user_quests_old"][],
	/** fetch aggregated fields from the table: "voyce.user_quests_old" */
	voyce_user_quests_old_aggregate:ModelTypes["voyce_user_quests_old_aggregate"],
	/** fetch data from the table: "voyce.user_quests_old" using primary key columns */
	voyce_user_quests_old_by_pk?:ModelTypes["voyce_user_quests_old"],
	/** fetch data from the table: "voyce.user_ranking" */
	voyce_user_ranking:ModelTypes["voyce_user_ranking"][],
	/** fetch aggregated fields from the table: "voyce.user_ranking" */
	voyce_user_ranking_aggregate:ModelTypes["voyce_user_ranking_aggregate"],
	/** fetch data from the table: "voyce.user_ranking" using primary key columns */
	voyce_user_ranking_by_pk?:ModelTypes["voyce_user_ranking"],
	/** fetch data from the table: "voyce.user_reviews" */
	voyce_user_reviews:ModelTypes["voyce_user_reviews"][],
	/** fetch aggregated fields from the table: "voyce.user_reviews" */
	voyce_user_reviews_aggregate:ModelTypes["voyce_user_reviews_aggregate"],
	/** fetch data from the table: "voyce.user_reviews" using primary key columns */
	voyce_user_reviews_by_pk?:ModelTypes["voyce_user_reviews"],
	/** fetch data from the table: "voyce.user_rewards" */
	voyce_user_rewards:ModelTypes["voyce_user_rewards"][],
	/** fetch aggregated fields from the table: "voyce.user_rewards" */
	voyce_user_rewards_aggregate:ModelTypes["voyce_user_rewards_aggregate"],
	/** fetch data from the table: "voyce.user_rewards" using primary key columns */
	voyce_user_rewards_by_pk?:ModelTypes["voyce_user_rewards"],
	/** fetch data from the table: "voyce.user_roles" */
	voyce_user_roles:ModelTypes["voyce_user_roles"][],
	/** fetch aggregated fields from the table: "voyce.user_roles" */
	voyce_user_roles_aggregate:ModelTypes["voyce_user_roles_aggregate"],
	/** fetch data from the table: "voyce.user_roles" using primary key columns */
	voyce_user_roles_by_pk?:ModelTypes["voyce_user_roles"],
	/** fetch data from the table: "voyce.user_series_subscription_count" */
	voyce_user_series_subscription_count:ModelTypes["voyce_user_series_subscription_count"][],
	/** fetch aggregated fields from the table: "voyce.user_series_subscription_count" */
	voyce_user_series_subscription_count_aggregate:ModelTypes["voyce_user_series_subscription_count_aggregate"],
	/** fetch data from the table: "voyce.user_settings" */
	voyce_user_settings:ModelTypes["voyce_user_settings"][],
	/** fetch aggregated fields from the table: "voyce.user_settings" */
	voyce_user_settings_aggregate:ModelTypes["voyce_user_settings_aggregate"],
	/** fetch data from the table: "voyce.user_settings" using primary key columns */
	voyce_user_settings_by_pk?:ModelTypes["voyce_user_settings"],
	/** fetch data from the table: "voyce.user_skills" */
	voyce_user_skills:ModelTypes["voyce_user_skills"][],
	/** fetch aggregated fields from the table: "voyce.user_skills" */
	voyce_user_skills_aggregate:ModelTypes["voyce_user_skills_aggregate"],
	/** fetch data from the table: "voyce.user_skills" using primary key columns */
	voyce_user_skills_by_pk?:ModelTypes["voyce_user_skills"],
	/** fetch data from the table: "voyce.user_socials" */
	voyce_user_socials:ModelTypes["voyce_user_socials"][],
	/** fetch aggregated fields from the table: "voyce.user_socials" */
	voyce_user_socials_aggregate:ModelTypes["voyce_user_socials_aggregate"],
	/** fetch data from the table: "voyce.user_socials" using primary key columns */
	voyce_user_socials_by_pk?:ModelTypes["voyce_user_socials"],
	/** fetch data from the table: "voyce.user_soft_deleted" */
	voyce_user_soft_deleted:ModelTypes["voyce_user_soft_deleted"][],
	/** fetch aggregated fields from the table: "voyce.user_soft_deleted" */
	voyce_user_soft_deleted_aggregate:ModelTypes["voyce_user_soft_deleted_aggregate"],
	/** fetch data from the table: "voyce.user_soft_deleted" using primary key columns */
	voyce_user_soft_deleted_by_pk?:ModelTypes["voyce_user_soft_deleted"],
	/** fetch data from the table: "voyce.user_vtags" */
	voyce_user_vtags:ModelTypes["voyce_user_vtags"][],
	/** fetch aggregated fields from the table: "voyce.user_vtags" */
	voyce_user_vtags_aggregate:ModelTypes["voyce_user_vtags_aggregate"],
	/** fetch data from the table: "voyce.user_vtags" using primary key columns */
	voyce_user_vtags_by_pk?:ModelTypes["voyce_user_vtags"],
	/** fetch data from the table: "voyce.user_wins" */
	voyce_user_wins:ModelTypes["voyce_user_wins"][],
	/** fetch aggregated fields from the table: "voyce.user_wins" */
	voyce_user_wins_aggregate:ModelTypes["voyce_user_wins_aggregate"],
	/** fetch data from the table: "voyce.user_wins" using primary key columns */
	voyce_user_wins_by_pk?:ModelTypes["voyce_user_wins"],
	/** fetch data from the table: "voyce.users" */
	voyce_users:ModelTypes["voyce_users"][],
	/** fetch aggregated fields from the table: "voyce.users" */
	voyce_users_aggregate:ModelTypes["voyce_users_aggregate"],
	/** fetch data from the table: "voyce.users" using primary key columns */
	voyce_users_by_pk?:ModelTypes["voyce_users"],
	/** fetch data from the table: "voyce.users_followers" */
	voyce_users_followers:ModelTypes["voyce_users_followers"][],
	/** fetch aggregated fields from the table: "voyce.users_followers" */
	voyce_users_followers_aggregate:ModelTypes["voyce_users_followers_aggregate"],
	/** fetch data from the table: "voyce.users_followers" using primary key columns */
	voyce_users_followers_by_pk?:ModelTypes["voyce_users_followers"],
	/** fetch data from the table: "voyce.users_private" */
	voyce_users_private:ModelTypes["voyce_users_private"][],
	/** fetch aggregated fields from the table: "voyce.users_private" */
	voyce_users_private_aggregate:ModelTypes["voyce_users_private_aggregate"],
	/** fetch data from the table: "voyce.users_series_junction" */
	voyce_users_series_junction:ModelTypes["voyce_users_series_junction"][],
	/** fetch aggregated fields from the table: "voyce.users_series_junction" */
	voyce_users_series_junction_aggregate:ModelTypes["voyce_users_series_junction_aggregate"],
	/** fetch data from the table: "voyce.users_series_junction" using primary key columns */
	voyce_users_series_junction_by_pk?:ModelTypes["voyce_users_series_junction"],
	/** fetch data from the table: "voyce.versions" */
	voyce_versions:ModelTypes["voyce_versions"][],
	/** fetch aggregated fields from the table: "voyce.versions" */
	voyce_versions_aggregate:ModelTypes["voyce_versions_aggregate"],
	/** fetch data from the table: "voyce.versions" using primary key columns */
	voyce_versions_by_pk?:ModelTypes["voyce_versions"],
	/** fetch data from the table: "voyce.voting_analytics" */
	voyce_voting_analytics:ModelTypes["voyce_voting_analytics"][],
	/** fetch aggregated fields from the table: "voyce.voting_analytics" */
	voyce_voting_analytics_aggregate:ModelTypes["voyce_voting_analytics_aggregate"],
	/** fetch data from the table: "voyce.voting_analytics" using primary key columns */
	voyce_voting_analytics_by_pk?:ModelTypes["voyce_voting_analytics"],
	/** fetch data from the table: "voyce.voting_panels" */
	voyce_voting_panels:ModelTypes["voyce_voting_panels"][],
	/** fetch aggregated fields from the table: "voyce.voting_panels" */
	voyce_voting_panels_aggregate:ModelTypes["voyce_voting_panels_aggregate"],
	/** fetch data from the table: "voyce.voting_panels" using primary key columns */
	voyce_voting_panels_by_pk?:ModelTypes["voyce_voting_panels"],
	/** fetch data from the table: "voyce.voting_series" */
	voyce_voting_series:ModelTypes["voyce_voting_series"][],
	/** fetch aggregated fields from the table: "voyce.voting_series" */
	voyce_voting_series_aggregate:ModelTypes["voyce_voting_series_aggregate"],
	/** fetch data from the table: "voyce.voting_series" using primary key columns */
	voyce_voting_series_by_pk?:ModelTypes["voyce_voting_series"],
	/** fetch data from the table: "voyce.voting_user_votes" */
	voyce_voting_user_votes:ModelTypes["voyce_voting_user_votes"][],
	/** fetch aggregated fields from the table: "voyce.voting_user_votes" */
	voyce_voting_user_votes_aggregate:ModelTypes["voyce_voting_user_votes_aggregate"],
	/** fetch data from the table: "voyce.voting_user_votes" using primary key columns */
	voyce_voting_user_votes_by_pk?:ModelTypes["voyce_voting_user_votes"]
};
	["smallint"]:any;
	/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
["smallint_comparison_exp"]: GraphQLTypes["smallint_comparison_exp"];
	/** columns and relationships of "store.payments" */
["store_payments"]: {
		amount:ModelTypes["numeric"],
	created_at:ModelTypes["timestamptz"],
	currency:string,
	gateway:string,
	id:ModelTypes["uuid"],
	purchase_id:string,
	remarks?:string,
	status:string
};
	/** aggregated selection of "store.payments" */
["store_payments_aggregate"]: {
		aggregate?:ModelTypes["store_payments_aggregate_fields"],
	nodes:ModelTypes["store_payments"][]
};
	/** aggregate fields of "store.payments" */
["store_payments_aggregate_fields"]: {
		avg?:ModelTypes["store_payments_avg_fields"],
	count:number,
	max?:ModelTypes["store_payments_max_fields"],
	min?:ModelTypes["store_payments_min_fields"],
	stddev?:ModelTypes["store_payments_stddev_fields"],
	stddev_pop?:ModelTypes["store_payments_stddev_pop_fields"],
	stddev_samp?:ModelTypes["store_payments_stddev_samp_fields"],
	sum?:ModelTypes["store_payments_sum_fields"],
	var_pop?:ModelTypes["store_payments_var_pop_fields"],
	var_samp?:ModelTypes["store_payments_var_samp_fields"],
	variance?:ModelTypes["store_payments_variance_fields"]
};
	/** aggregate avg on columns */
["store_payments_avg_fields"]: {
		amount?:number
};
	/** Boolean expression to filter rows from the table "store.payments". All fields are combined with a logical 'AND'. */
["store_payments_bool_exp"]: GraphQLTypes["store_payments_bool_exp"];
	/** unique or primary key constraints on table "store.payments" */
["store_payments_constraint"]: GraphQLTypes["store_payments_constraint"];
	/** input type for incrementing numeric columns in table "store.payments" */
["store_payments_inc_input"]: GraphQLTypes["store_payments_inc_input"];
	/** input type for inserting data into table "store.payments" */
["store_payments_insert_input"]: GraphQLTypes["store_payments_insert_input"];
	/** aggregate max on columns */
["store_payments_max_fields"]: {
		amount?:ModelTypes["numeric"],
	created_at?:ModelTypes["timestamptz"],
	currency?:string,
	gateway?:string,
	id?:ModelTypes["uuid"],
	purchase_id?:string,
	remarks?:string,
	status?:string
};
	/** aggregate min on columns */
["store_payments_min_fields"]: {
		amount?:ModelTypes["numeric"],
	created_at?:ModelTypes["timestamptz"],
	currency?:string,
	gateway?:string,
	id?:ModelTypes["uuid"],
	purchase_id?:string,
	remarks?:string,
	status?:string
};
	/** response of any mutation on the table "store.payments" */
["store_payments_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["store_payments"][]
};
	/** on_conflict condition type for table "store.payments" */
["store_payments_on_conflict"]: GraphQLTypes["store_payments_on_conflict"];
	/** Ordering options when selecting data from "store.payments". */
["store_payments_order_by"]: GraphQLTypes["store_payments_order_by"];
	/** primary key columns input for table: store.payments */
["store_payments_pk_columns_input"]: GraphQLTypes["store_payments_pk_columns_input"];
	/** select columns of table "store.payments" */
["store_payments_select_column"]: GraphQLTypes["store_payments_select_column"];
	/** input type for updating data in table "store.payments" */
["store_payments_set_input"]: GraphQLTypes["store_payments_set_input"];
	/** aggregate stddev on columns */
["store_payments_stddev_fields"]: {
		amount?:number
};
	/** aggregate stddev_pop on columns */
["store_payments_stddev_pop_fields"]: {
		amount?:number
};
	/** aggregate stddev_samp on columns */
["store_payments_stddev_samp_fields"]: {
		amount?:number
};
	/** Streaming cursor of the table "store_payments" */
["store_payments_stream_cursor_input"]: GraphQLTypes["store_payments_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["store_payments_stream_cursor_value_input"]: GraphQLTypes["store_payments_stream_cursor_value_input"];
	/** aggregate sum on columns */
["store_payments_sum_fields"]: {
		amount?:ModelTypes["numeric"]
};
	/** update columns of table "store.payments" */
["store_payments_update_column"]: GraphQLTypes["store_payments_update_column"];
	["store_payments_updates"]: GraphQLTypes["store_payments_updates"];
	/** aggregate var_pop on columns */
["store_payments_var_pop_fields"]: {
		amount?:number
};
	/** aggregate var_samp on columns */
["store_payments_var_samp_fields"]: {
		amount?:number
};
	/** aggregate variance on columns */
["store_payments_variance_fields"]: {
		amount?:number
};
	/** columns and relationships of "store.products" */
["store_products"]: {
		category?:string,
	chapter_type?:string,
	currency:string,
	description:string,
	enabled:boolean,
	id:ModelTypes["uuid"],
	image:string,
	is_store_visible:boolean,
	label?:string,
	name:string,
	price:ModelTypes["numeric"],
	purchase_limit:ModelTypes["numeric"],
	quantity:number,
	/** An object relationship */
	series?:ModelTypes["voyce_series"],
	series_id?:ModelTypes["numeric"],
	sort_num:ModelTypes["numeric"],
	tag?:string,
	type:string
};
	/** aggregated selection of "store.products" */
["store_products_aggregate"]: {
		aggregate?:ModelTypes["store_products_aggregate_fields"],
	nodes:ModelTypes["store_products"][]
};
	/** aggregate fields of "store.products" */
["store_products_aggregate_fields"]: {
		avg?:ModelTypes["store_products_avg_fields"],
	count:number,
	max?:ModelTypes["store_products_max_fields"],
	min?:ModelTypes["store_products_min_fields"],
	stddev?:ModelTypes["store_products_stddev_fields"],
	stddev_pop?:ModelTypes["store_products_stddev_pop_fields"],
	stddev_samp?:ModelTypes["store_products_stddev_samp_fields"],
	sum?:ModelTypes["store_products_sum_fields"],
	var_pop?:ModelTypes["store_products_var_pop_fields"],
	var_samp?:ModelTypes["store_products_var_samp_fields"],
	variance?:ModelTypes["store_products_variance_fields"]
};
	/** aggregate avg on columns */
["store_products_avg_fields"]: {
		price?:number,
	purchase_limit?:number,
	quantity?:number,
	series_id?:number,
	sort_num?:number
};
	/** Boolean expression to filter rows from the table "store.products". All fields are combined with a logical 'AND'. */
["store_products_bool_exp"]: GraphQLTypes["store_products_bool_exp"];
	/** unique or primary key constraints on table "store.products" */
["store_products_constraint"]: GraphQLTypes["store_products_constraint"];
	/** input type for incrementing numeric columns in table "store.products" */
["store_products_inc_input"]: GraphQLTypes["store_products_inc_input"];
	/** input type for inserting data into table "store.products" */
["store_products_insert_input"]: GraphQLTypes["store_products_insert_input"];
	/** aggregate max on columns */
["store_products_max_fields"]: {
		category?:string,
	chapter_type?:string,
	currency?:string,
	description?:string,
	id?:ModelTypes["uuid"],
	image?:string,
	label?:string,
	name?:string,
	price?:ModelTypes["numeric"],
	purchase_limit?:ModelTypes["numeric"],
	quantity?:number,
	series_id?:ModelTypes["numeric"],
	sort_num?:ModelTypes["numeric"],
	tag?:string,
	type?:string
};
	/** aggregate min on columns */
["store_products_min_fields"]: {
		category?:string,
	chapter_type?:string,
	currency?:string,
	description?:string,
	id?:ModelTypes["uuid"],
	image?:string,
	label?:string,
	name?:string,
	price?:ModelTypes["numeric"],
	purchase_limit?:ModelTypes["numeric"],
	quantity?:number,
	series_id?:ModelTypes["numeric"],
	sort_num?:ModelTypes["numeric"],
	tag?:string,
	type?:string
};
	/** response of any mutation on the table "store.products" */
["store_products_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["store_products"][]
};
	/** on_conflict condition type for table "store.products" */
["store_products_on_conflict"]: GraphQLTypes["store_products_on_conflict"];
	/** Ordering options when selecting data from "store.products". */
["store_products_order_by"]: GraphQLTypes["store_products_order_by"];
	/** primary key columns input for table: store.products */
["store_products_pk_columns_input"]: GraphQLTypes["store_products_pk_columns_input"];
	/** select columns of table "store.products" */
["store_products_select_column"]: GraphQLTypes["store_products_select_column"];
	/** input type for updating data in table "store.products" */
["store_products_set_input"]: GraphQLTypes["store_products_set_input"];
	/** aggregate stddev on columns */
["store_products_stddev_fields"]: {
		price?:number,
	purchase_limit?:number,
	quantity?:number,
	series_id?:number,
	sort_num?:number
};
	/** aggregate stddev_pop on columns */
["store_products_stddev_pop_fields"]: {
		price?:number,
	purchase_limit?:number,
	quantity?:number,
	series_id?:number,
	sort_num?:number
};
	/** aggregate stddev_samp on columns */
["store_products_stddev_samp_fields"]: {
		price?:number,
	purchase_limit?:number,
	quantity?:number,
	series_id?:number,
	sort_num?:number
};
	/** Streaming cursor of the table "store_products" */
["store_products_stream_cursor_input"]: GraphQLTypes["store_products_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["store_products_stream_cursor_value_input"]: GraphQLTypes["store_products_stream_cursor_value_input"];
	/** aggregate sum on columns */
["store_products_sum_fields"]: {
		price?:ModelTypes["numeric"],
	purchase_limit?:ModelTypes["numeric"],
	quantity?:number,
	series_id?:ModelTypes["numeric"],
	sort_num?:ModelTypes["numeric"]
};
	/** update columns of table "store.products" */
["store_products_update_column"]: GraphQLTypes["store_products_update_column"];
	["store_products_updates"]: GraphQLTypes["store_products_updates"];
	/** aggregate var_pop on columns */
["store_products_var_pop_fields"]: {
		price?:number,
	purchase_limit?:number,
	quantity?:number,
	series_id?:number,
	sort_num?:number
};
	/** aggregate var_samp on columns */
["store_products_var_samp_fields"]: {
		price?:number,
	purchase_limit?:number,
	quantity?:number,
	series_id?:number,
	sort_num?:number
};
	/** aggregate variance on columns */
["store_products_variance_fields"]: {
		price?:number,
	purchase_limit?:number,
	quantity?:number,
	series_id?:number,
	sort_num?:number
};
	/** columns and relationships of "store.purchases" */
["store_purchases"]: {
		created_at:ModelTypes["timestamptz"],
	id:ModelTypes["uuid"],
	product_category?:string,
	product_currency:string,
	product_description?:string,
	product_image_url?:string,
	product_label?:string,
	product_meta?:ModelTypes["json"],
	product_name:string,
	product_price:ModelTypes["numeric"],
	product_type?:string,
	quantity:ModelTypes["numeric"],
	reference_id?:string,
	remarks?:string,
	series_id?:number,
	status:string,
	total_price:ModelTypes["numeric"],
	updated_at:ModelTypes["timestamptz"],
	user_id:ModelTypes["numeric"]
};
	/** aggregated selection of "store.purchases" */
["store_purchases_aggregate"]: {
		aggregate?:ModelTypes["store_purchases_aggregate_fields"],
	nodes:ModelTypes["store_purchases"][]
};
	/** aggregate fields of "store.purchases" */
["store_purchases_aggregate_fields"]: {
		avg?:ModelTypes["store_purchases_avg_fields"],
	count:number,
	max?:ModelTypes["store_purchases_max_fields"],
	min?:ModelTypes["store_purchases_min_fields"],
	stddev?:ModelTypes["store_purchases_stddev_fields"],
	stddev_pop?:ModelTypes["store_purchases_stddev_pop_fields"],
	stddev_samp?:ModelTypes["store_purchases_stddev_samp_fields"],
	sum?:ModelTypes["store_purchases_sum_fields"],
	var_pop?:ModelTypes["store_purchases_var_pop_fields"],
	var_samp?:ModelTypes["store_purchases_var_samp_fields"],
	variance?:ModelTypes["store_purchases_variance_fields"]
};
	/** aggregate avg on columns */
["store_purchases_avg_fields"]: {
		product_price?:number,
	quantity?:number,
	series_id?:number,
	total_price?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "store.purchases". All fields are combined with a logical 'AND'. */
["store_purchases_bool_exp"]: GraphQLTypes["store_purchases_bool_exp"];
	/** unique or primary key constraints on table "store.purchases" */
["store_purchases_constraint"]: GraphQLTypes["store_purchases_constraint"];
	/** input type for incrementing numeric columns in table "store.purchases" */
["store_purchases_inc_input"]: GraphQLTypes["store_purchases_inc_input"];
	/** input type for inserting data into table "store.purchases" */
["store_purchases_insert_input"]: GraphQLTypes["store_purchases_insert_input"];
	/** aggregate max on columns */
["store_purchases_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:ModelTypes["uuid"],
	product_category?:string,
	product_currency?:string,
	product_description?:string,
	product_image_url?:string,
	product_label?:string,
	product_name?:string,
	product_price?:ModelTypes["numeric"],
	product_type?:string,
	quantity?:ModelTypes["numeric"],
	reference_id?:string,
	remarks?:string,
	series_id?:number,
	status?:string,
	total_price?:ModelTypes["numeric"],
	updated_at?:ModelTypes["timestamptz"],
	user_id?:ModelTypes["numeric"]
};
	/** aggregate min on columns */
["store_purchases_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:ModelTypes["uuid"],
	product_category?:string,
	product_currency?:string,
	product_description?:string,
	product_image_url?:string,
	product_label?:string,
	product_name?:string,
	product_price?:ModelTypes["numeric"],
	product_type?:string,
	quantity?:ModelTypes["numeric"],
	reference_id?:string,
	remarks?:string,
	series_id?:number,
	status?:string,
	total_price?:ModelTypes["numeric"],
	updated_at?:ModelTypes["timestamptz"],
	user_id?:ModelTypes["numeric"]
};
	/** response of any mutation on the table "store.purchases" */
["store_purchases_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["store_purchases"][]
};
	/** on_conflict condition type for table "store.purchases" */
["store_purchases_on_conflict"]: GraphQLTypes["store_purchases_on_conflict"];
	/** Ordering options when selecting data from "store.purchases". */
["store_purchases_order_by"]: GraphQLTypes["store_purchases_order_by"];
	/** primary key columns input for table: store.purchases */
["store_purchases_pk_columns_input"]: GraphQLTypes["store_purchases_pk_columns_input"];
	/** select columns of table "store.purchases" */
["store_purchases_select_column"]: GraphQLTypes["store_purchases_select_column"];
	/** input type for updating data in table "store.purchases" */
["store_purchases_set_input"]: GraphQLTypes["store_purchases_set_input"];
	/** aggregate stddev on columns */
["store_purchases_stddev_fields"]: {
		product_price?:number,
	quantity?:number,
	series_id?:number,
	total_price?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["store_purchases_stddev_pop_fields"]: {
		product_price?:number,
	quantity?:number,
	series_id?:number,
	total_price?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["store_purchases_stddev_samp_fields"]: {
		product_price?:number,
	quantity?:number,
	series_id?:number,
	total_price?:number,
	user_id?:number
};
	/** Streaming cursor of the table "store_purchases" */
["store_purchases_stream_cursor_input"]: GraphQLTypes["store_purchases_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["store_purchases_stream_cursor_value_input"]: GraphQLTypes["store_purchases_stream_cursor_value_input"];
	/** aggregate sum on columns */
["store_purchases_sum_fields"]: {
		product_price?:ModelTypes["numeric"],
	quantity?:ModelTypes["numeric"],
	series_id?:number,
	total_price?:ModelTypes["numeric"],
	user_id?:ModelTypes["numeric"]
};
	/** update columns of table "store.purchases" */
["store_purchases_update_column"]: GraphQLTypes["store_purchases_update_column"];
	["store_purchases_updates"]: GraphQLTypes["store_purchases_updates"];
	/** aggregate var_pop on columns */
["store_purchases_var_pop_fields"]: {
		product_price?:number,
	quantity?:number,
	series_id?:number,
	total_price?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["store_purchases_var_samp_fields"]: {
		product_price?:number,
	quantity?:number,
	series_id?:number,
	total_price?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["store_purchases_variance_fields"]: {
		product_price?:number,
	quantity?:number,
	series_id?:number,
	total_price?:number,
	user_id?:number
};
	/** columns and relationships of "store.users" */
["store_users"]: {
		about?:string,
	ad_share?:number,
	avatar?:string,
	banner?:string,
	birth_date?:ModelTypes["date"],
	dark_mode?:ModelTypes["smallint"],
	date_create?:ModelTypes["timestamp"],
	email?:string,
	first_name?:string,
	gender?:string,
	id?:number,
	is_account_active?:ModelTypes["smallint"],
	last_login?:ModelTypes["timestamp"],
	last_name?:string,
	nickname?:string,
	password?:string,
	remember_code?:string,
	role_id?:number,
	username?:string,
	vmuser?:boolean
};
	/** aggregated selection of "store.users" */
["store_users_aggregate"]: {
		aggregate?:ModelTypes["store_users_aggregate_fields"],
	nodes:ModelTypes["store_users"][]
};
	/** aggregate fields of "store.users" */
["store_users_aggregate_fields"]: {
		avg?:ModelTypes["store_users_avg_fields"],
	count:number,
	max?:ModelTypes["store_users_max_fields"],
	min?:ModelTypes["store_users_min_fields"],
	stddev?:ModelTypes["store_users_stddev_fields"],
	stddev_pop?:ModelTypes["store_users_stddev_pop_fields"],
	stddev_samp?:ModelTypes["store_users_stddev_samp_fields"],
	sum?:ModelTypes["store_users_sum_fields"],
	var_pop?:ModelTypes["store_users_var_pop_fields"],
	var_samp?:ModelTypes["store_users_var_samp_fields"],
	variance?:ModelTypes["store_users_variance_fields"]
};
	/** aggregate avg on columns */
["store_users_avg_fields"]: {
		ad_share?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	role_id?:number
};
	/** Boolean expression to filter rows from the table "store.users". All fields are combined with a logical 'AND'. */
["store_users_bool_exp"]: GraphQLTypes["store_users_bool_exp"];
	/** input type for incrementing numeric columns in table "store.users" */
["store_users_inc_input"]: GraphQLTypes["store_users_inc_input"];
	/** input type for inserting data into table "store.users" */
["store_users_insert_input"]: GraphQLTypes["store_users_insert_input"];
	/** aggregate max on columns */
["store_users_max_fields"]: {
		about?:string,
	ad_share?:number,
	avatar?:string,
	banner?:string,
	birth_date?:ModelTypes["date"],
	dark_mode?:ModelTypes["smallint"],
	date_create?:ModelTypes["timestamp"],
	email?:string,
	first_name?:string,
	gender?:string,
	id?:number,
	is_account_active?:ModelTypes["smallint"],
	last_login?:ModelTypes["timestamp"],
	last_name?:string,
	nickname?:string,
	password?:string,
	remember_code?:string,
	role_id?:number,
	username?:string
};
	/** aggregate min on columns */
["store_users_min_fields"]: {
		about?:string,
	ad_share?:number,
	avatar?:string,
	banner?:string,
	birth_date?:ModelTypes["date"],
	dark_mode?:ModelTypes["smallint"],
	date_create?:ModelTypes["timestamp"],
	email?:string,
	first_name?:string,
	gender?:string,
	id?:number,
	is_account_active?:ModelTypes["smallint"],
	last_login?:ModelTypes["timestamp"],
	last_name?:string,
	nickname?:string,
	password?:string,
	remember_code?:string,
	role_id?:number,
	username?:string
};
	/** response of any mutation on the table "store.users" */
["store_users_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["store_users"][]
};
	/** Ordering options when selecting data from "store.users". */
["store_users_order_by"]: GraphQLTypes["store_users_order_by"];
	/** select columns of table "store.users" */
["store_users_select_column"]: GraphQLTypes["store_users_select_column"];
	/** input type for updating data in table "store.users" */
["store_users_set_input"]: GraphQLTypes["store_users_set_input"];
	/** aggregate stddev on columns */
["store_users_stddev_fields"]: {
		ad_share?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	role_id?:number
};
	/** aggregate stddev_pop on columns */
["store_users_stddev_pop_fields"]: {
		ad_share?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	role_id?:number
};
	/** aggregate stddev_samp on columns */
["store_users_stddev_samp_fields"]: {
		ad_share?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	role_id?:number
};
	/** Streaming cursor of the table "store_users" */
["store_users_stream_cursor_input"]: GraphQLTypes["store_users_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["store_users_stream_cursor_value_input"]: GraphQLTypes["store_users_stream_cursor_value_input"];
	/** aggregate sum on columns */
["store_users_sum_fields"]: {
		ad_share?:number,
	dark_mode?:ModelTypes["smallint"],
	id?:number,
	is_account_active?:ModelTypes["smallint"],
	role_id?:number
};
	["store_users_updates"]: GraphQLTypes["store_users_updates"];
	/** aggregate var_pop on columns */
["store_users_var_pop_fields"]: {
		ad_share?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	role_id?:number
};
	/** aggregate var_samp on columns */
["store_users_var_samp_fields"]: {
		ad_share?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	role_id?:number
};
	/** aggregate variance on columns */
["store_users_variance_fields"]: {
		ad_share?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	role_id?:number
};
	["subscription_root"]: {
		/** fetch data from the table: "awsdms_validation_failures_v1" */
	awsdms_validation_failures_v1:ModelTypes["awsdms_validation_failures_v1"][],
	/** fetch aggregated fields from the table: "awsdms_validation_failures_v1" */
	awsdms_validation_failures_v1_aggregate:ModelTypes["awsdms_validation_failures_v1_aggregate"],
	/** fetch data from the table in a streaming manner: "awsdms_validation_failures_v1" */
	awsdms_validation_failures_v1_stream:ModelTypes["awsdms_validation_failures_v1"][],
	/** fetch data from the table: "coinsystem.balances" */
	coinsystem_balances:ModelTypes["coinsystem_balances"][],
	/** fetch aggregated fields from the table: "coinsystem.balances" */
	coinsystem_balances_aggregate:ModelTypes["coinsystem_balances_aggregate"],
	/** fetch data from the table: "coinsystem.balances" using primary key columns */
	coinsystem_balances_by_pk?:ModelTypes["coinsystem_balances"],
	/** fetch data from the table in a streaming manner: "coinsystem.balances" */
	coinsystem_balances_stream:ModelTypes["coinsystem_balances"][],
	/** fetch data from the table: "coinsystem.bonus_coins" */
	coinsystem_bonus_coins:ModelTypes["coinsystem_bonus_coins"][],
	/** fetch aggregated fields from the table: "coinsystem.bonus_coins" */
	coinsystem_bonus_coins_aggregate:ModelTypes["coinsystem_bonus_coins_aggregate"],
	/** fetch data from the table: "coinsystem.bonus_coins" using primary key columns */
	coinsystem_bonus_coins_by_pk?:ModelTypes["coinsystem_bonus_coins"],
	/** fetch data from the table in a streaming manner: "coinsystem.bonus_coins" */
	coinsystem_bonus_coins_stream:ModelTypes["coinsystem_bonus_coins"][],
	/** fetch data from the table: "coinsystem.coins" */
	coinsystem_coins:ModelTypes["coinsystem_coins"][],
	/** fetch aggregated fields from the table: "coinsystem.coins" */
	coinsystem_coins_aggregate:ModelTypes["coinsystem_coins_aggregate"],
	/** fetch data from the table: "coinsystem.coins" using primary key columns */
	coinsystem_coins_by_pk?:ModelTypes["coinsystem_coins"],
	/** fetch data from the table in a streaming manner: "coinsystem.coins" */
	coinsystem_coins_stream:ModelTypes["coinsystem_coins"][],
	/** fetch data from the table: "coinsystem.exchange_rates" */
	coinsystem_exchange_rates:ModelTypes["coinsystem_exchange_rates"][],
	/** fetch aggregated fields from the table: "coinsystem.exchange_rates" */
	coinsystem_exchange_rates_aggregate:ModelTypes["coinsystem_exchange_rates_aggregate"],
	/** fetch data from the table: "coinsystem.exchange_rates" using primary key columns */
	coinsystem_exchange_rates_by_pk?:ModelTypes["coinsystem_exchange_rates"],
	/** fetch data from the table in a streaming manner: "coinsystem.exchange_rates" */
	coinsystem_exchange_rates_stream:ModelTypes["coinsystem_exchange_rates"][],
	/** fetch data from the table: "coinsystem.logs" */
	coinsystem_logs:ModelTypes["coinsystem_logs"][],
	/** fetch aggregated fields from the table: "coinsystem.logs" */
	coinsystem_logs_aggregate:ModelTypes["coinsystem_logs_aggregate"],
	/** fetch data from the table: "coinsystem.logs" using primary key columns */
	coinsystem_logs_by_pk?:ModelTypes["coinsystem_logs"],
	/** fetch data from the table in a streaming manner: "coinsystem.logs" */
	coinsystem_logs_stream:ModelTypes["coinsystem_logs"][],
	/** fetch data from the table: "coinsystem.transactions" */
	coinsystem_transactions:ModelTypes["coinsystem_transactions"][],
	/** fetch aggregated fields from the table: "coinsystem.transactions" */
	coinsystem_transactions_aggregate:ModelTypes["coinsystem_transactions_aggregate"],
	/** fetch data from the table: "coinsystem.transactions" using primary key columns */
	coinsystem_transactions_by_pk?:ModelTypes["coinsystem_transactions"],
	/** fetch data from the table in a streaming manner: "coinsystem.transactions" */
	coinsystem_transactions_stream:ModelTypes["coinsystem_transactions"][],
	/** fetch data from the table: "coinsystem.wallets" */
	coinsystem_wallets:ModelTypes["coinsystem_wallets"][],
	/** fetch aggregated fields from the table: "coinsystem.wallets" */
	coinsystem_wallets_aggregate:ModelTypes["coinsystem_wallets_aggregate"],
	/** fetch data from the table: "coinsystem.wallets" using primary key columns */
	coinsystem_wallets_by_pk?:ModelTypes["coinsystem_wallets"],
	/** fetch data from the table in a streaming manner: "coinsystem.wallets" */
	coinsystem_wallets_stream:ModelTypes["coinsystem_wallets"][],
	/** fetch data from the table: "mysql.user" */
	mysql_user:ModelTypes["mysql_user"][],
	/** fetch aggregated fields from the table: "mysql.user" */
	mysql_user_aggregate:ModelTypes["mysql_user_aggregate"],
	/** fetch data from the table: "mysql.user" using primary key columns */
	mysql_user_by_pk?:ModelTypes["mysql_user"],
	/** fetch data from the table in a streaming manner: "mysql.user" */
	mysql_user_stream:ModelTypes["mysql_user"][],
	/** fetch data from the table: "store.payments" */
	store_payments:ModelTypes["store_payments"][],
	/** fetch aggregated fields from the table: "store.payments" */
	store_payments_aggregate:ModelTypes["store_payments_aggregate"],
	/** fetch data from the table: "store.payments" using primary key columns */
	store_payments_by_pk?:ModelTypes["store_payments"],
	/** fetch data from the table in a streaming manner: "store.payments" */
	store_payments_stream:ModelTypes["store_payments"][],
	/** fetch data from the table: "store.products" */
	store_products:ModelTypes["store_products"][],
	/** fetch aggregated fields from the table: "store.products" */
	store_products_aggregate:ModelTypes["store_products_aggregate"],
	/** fetch data from the table: "store.products" using primary key columns */
	store_products_by_pk?:ModelTypes["store_products"],
	/** fetch data from the table in a streaming manner: "store.products" */
	store_products_stream:ModelTypes["store_products"][],
	/** fetch data from the table: "store.purchases" */
	store_purchases:ModelTypes["store_purchases"][],
	/** fetch aggregated fields from the table: "store.purchases" */
	store_purchases_aggregate:ModelTypes["store_purchases_aggregate"],
	/** fetch data from the table: "store.purchases" using primary key columns */
	store_purchases_by_pk?:ModelTypes["store_purchases"],
	/** fetch data from the table in a streaming manner: "store.purchases" */
	store_purchases_stream:ModelTypes["store_purchases"][],
	/** fetch data from the table: "store.users" */
	store_users:ModelTypes["store_users"][],
	/** fetch aggregated fields from the table: "store.users" */
	store_users_aggregate:ModelTypes["store_users_aggregate"],
	/** fetch data from the table in a streaming manner: "store.users" */
	store_users_stream:ModelTypes["store_users"][],
	/** fetch data from the table: "voyce.admin_roles" */
	voyce_admin_roles:ModelTypes["voyce_admin_roles"][],
	/** fetch aggregated fields from the table: "voyce.admin_roles" */
	voyce_admin_roles_aggregate:ModelTypes["voyce_admin_roles_aggregate"],
	/** fetch data from the table: "voyce.admin_roles" using primary key columns */
	voyce_admin_roles_by_pk?:ModelTypes["voyce_admin_roles"],
	/** fetch data from the table in a streaming manner: "voyce.admin_roles" */
	voyce_admin_roles_stream:ModelTypes["voyce_admin_roles"][],
	/** fetch data from the table: "voyce.app_banners" */
	voyce_app_banners:ModelTypes["voyce_app_banners"][],
	/** fetch aggregated fields from the table: "voyce.app_banners" */
	voyce_app_banners_aggregate:ModelTypes["voyce_app_banners_aggregate"],
	/** fetch data from the table: "voyce.app_banners" using primary key columns */
	voyce_app_banners_by_pk?:ModelTypes["voyce_app_banners"],
	/** fetch data from the table in a streaming manner: "voyce.app_banners" */
	voyce_app_banners_stream:ModelTypes["voyce_app_banners"][],
	/** fetch data from the table: "voyce.app_components" */
	voyce_app_components:ModelTypes["voyce_app_components"][],
	/** fetch aggregated fields from the table: "voyce.app_components" */
	voyce_app_components_aggregate:ModelTypes["voyce_app_components_aggregate"],
	/** fetch data from the table: "voyce.app_components" using primary key columns */
	voyce_app_components_by_pk?:ModelTypes["voyce_app_components"],
	/** fetch data from the table in a streaming manner: "voyce.app_components" */
	voyce_app_components_stream:ModelTypes["voyce_app_components"][],
	/** fetch data from the table: "voyce.audit_trail" */
	voyce_audit_trail:ModelTypes["voyce_audit_trail"][],
	/** fetch aggregated fields from the table: "voyce.audit_trail" */
	voyce_audit_trail_aggregate:ModelTypes["voyce_audit_trail_aggregate"],
	/** fetch data from the table: "voyce.audit_trail" using primary key columns */
	voyce_audit_trail_by_pk?:ModelTypes["voyce_audit_trail"],
	/** fetch data from the table in a streaming manner: "voyce.audit_trail" */
	voyce_audit_trail_stream:ModelTypes["voyce_audit_trail"][],
	/** fetch data from the table: "voyce.author_profiles" */
	voyce_author_profiles:ModelTypes["voyce_author_profiles"][],
	/** fetch aggregated fields from the table: "voyce.author_profiles" */
	voyce_author_profiles_aggregate:ModelTypes["voyce_author_profiles_aggregate"],
	/** fetch data from the table: "voyce.author_profiles" using primary key columns */
	voyce_author_profiles_by_pk?:ModelTypes["voyce_author_profiles"],
	/** fetch data from the table in a streaming manner: "voyce.author_profiles" */
	voyce_author_profiles_stream:ModelTypes["voyce_author_profiles"][],
	/** fetch data from the table: "voyce.balances" */
	voyce_balances:ModelTypes["voyce_balances"][],
	/** fetch aggregated fields from the table: "voyce.balances" */
	voyce_balances_aggregate:ModelTypes["voyce_balances_aggregate"],
	/** fetch data from the table: "voyce.balances" using primary key columns */
	voyce_balances_by_pk?:ModelTypes["voyce_balances"],
	/** fetch data from the table in a streaming manner: "voyce.balances" */
	voyce_balances_stream:ModelTypes["voyce_balances"][],
	/** fetch data from the table: "voyce.banners" */
	voyce_banners:ModelTypes["voyce_banners"][],
	/** fetch aggregated fields from the table: "voyce.banners" */
	voyce_banners_aggregate:ModelTypes["voyce_banners_aggregate"],
	/** fetch data from the table: "voyce.banners" using primary key columns */
	voyce_banners_by_pk?:ModelTypes["voyce_banners"],
	/** fetch data from the table in a streaming manner: "voyce.banners" */
	voyce_banners_stream:ModelTypes["voyce_banners"][],
	/** fetch data from the table: "voyce.chapter_assets" */
	voyce_chapter_assets:ModelTypes["voyce_chapter_assets"][],
	/** fetch aggregated fields from the table: "voyce.chapter_assets" */
	voyce_chapter_assets_aggregate:ModelTypes["voyce_chapter_assets_aggregate"],
	/** fetch data from the table: "voyce.chapter_assets" using primary key columns */
	voyce_chapter_assets_by_pk?:ModelTypes["voyce_chapter_assets"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_assets" */
	voyce_chapter_assets_stream:ModelTypes["voyce_chapter_assets"][],
	/** fetch data from the table: "voyce.chapter_bookmarks" */
	voyce_chapter_bookmarks:ModelTypes["voyce_chapter_bookmarks"][],
	/** fetch aggregated fields from the table: "voyce.chapter_bookmarks" */
	voyce_chapter_bookmarks_aggregate:ModelTypes["voyce_chapter_bookmarks_aggregate"],
	/** fetch data from the table: "voyce.chapter_bookmarks" using primary key columns */
	voyce_chapter_bookmarks_by_pk?:ModelTypes["voyce_chapter_bookmarks"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_bookmarks" */
	voyce_chapter_bookmarks_stream:ModelTypes["voyce_chapter_bookmarks"][],
	/** fetch data from the table: "voyce.chapter_comments" */
	voyce_chapter_comments:ModelTypes["voyce_chapter_comments"][],
	/** fetch data from the table: "voyce.chapter_comments_07252024" */
	voyce_chapter_comments_07252024:ModelTypes["voyce_chapter_comments_07252024"][],
	/** fetch aggregated fields from the table: "voyce.chapter_comments_07252024" */
	voyce_chapter_comments_07252024_aggregate:ModelTypes["voyce_chapter_comments_07252024_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_comments_07252024" */
	voyce_chapter_comments_07252024_stream:ModelTypes["voyce_chapter_comments_07252024"][],
	/** fetch aggregated fields from the table: "voyce.chapter_comments" */
	voyce_chapter_comments_aggregate:ModelTypes["voyce_chapter_comments_aggregate"],
	/** fetch data from the table: "voyce.chapter_comments" using primary key columns */
	voyce_chapter_comments_by_pk?:ModelTypes["voyce_chapter_comments"],
	/** fetch data from the table: "voyce.chapter_comments_reaction" */
	voyce_chapter_comments_reaction:ModelTypes["voyce_chapter_comments_reaction"][],
	/** fetch aggregated fields from the table: "voyce.chapter_comments_reaction" */
	voyce_chapter_comments_reaction_aggregate:ModelTypes["voyce_chapter_comments_reaction_aggregate"],
	/** fetch data from the table: "voyce.chapter_comments_reaction" using primary key columns */
	voyce_chapter_comments_reaction_by_pk?:ModelTypes["voyce_chapter_comments_reaction"],
	/** fetch data from the table: "voyce.chapter_comments_reaction_count" */
	voyce_chapter_comments_reaction_count:ModelTypes["voyce_chapter_comments_reaction_count"][],
	/** fetch aggregated fields from the table: "voyce.chapter_comments_reaction_count" */
	voyce_chapter_comments_reaction_count_aggregate:ModelTypes["voyce_chapter_comments_reaction_count_aggregate"],
	/** fetch data from the table: "voyce.chapter_comments_reaction_count" using primary key columns */
	voyce_chapter_comments_reaction_count_by_pk?:ModelTypes["voyce_chapter_comments_reaction_count"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_comments_reaction_count" */
	voyce_chapter_comments_reaction_count_stream:ModelTypes["voyce_chapter_comments_reaction_count"][],
	/** fetch data from the table in a streaming manner: "voyce.chapter_comments_reaction" */
	voyce_chapter_comments_reaction_stream:ModelTypes["voyce_chapter_comments_reaction"][],
	/** fetch data from the table: "voyce.chapter_comments_reports" */
	voyce_chapter_comments_reports:ModelTypes["voyce_chapter_comments_reports"][],
	/** fetch aggregated fields from the table: "voyce.chapter_comments_reports" */
	voyce_chapter_comments_reports_aggregate:ModelTypes["voyce_chapter_comments_reports_aggregate"],
	/** fetch data from the table: "voyce.chapter_comments_reports" using primary key columns */
	voyce_chapter_comments_reports_by_pk?:ModelTypes["voyce_chapter_comments_reports"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_comments_reports" */
	voyce_chapter_comments_reports_stream:ModelTypes["voyce_chapter_comments_reports"][],
	/** fetch data from the table in a streaming manner: "voyce.chapter_comments" */
	voyce_chapter_comments_stream:ModelTypes["voyce_chapter_comments"][],
	/** fetch data from the table: "voyce.chapter_contents" */
	voyce_chapter_contents:ModelTypes["voyce_chapter_contents"][],
	/** fetch aggregated fields from the table: "voyce.chapter_contents" */
	voyce_chapter_contents_aggregate:ModelTypes["voyce_chapter_contents_aggregate"],
	/** fetch data from the table: "voyce.chapter_contents" using primary key columns */
	voyce_chapter_contents_by_pk?:ModelTypes["voyce_chapter_contents"],
	/** fetch data from the table: "voyce.chapter_contents_old" */
	voyce_chapter_contents_old:ModelTypes["voyce_chapter_contents_old"][],
	/** fetch aggregated fields from the table: "voyce.chapter_contents_old" */
	voyce_chapter_contents_old_aggregate:ModelTypes["voyce_chapter_contents_old_aggregate"],
	/** fetch data from the table: "voyce.chapter_contents_old" using primary key columns */
	voyce_chapter_contents_old_by_pk?:ModelTypes["voyce_chapter_contents_old"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_contents_old" */
	voyce_chapter_contents_old_stream:ModelTypes["voyce_chapter_contents_old"][],
	/** fetch data from the table in a streaming manner: "voyce.chapter_contents" */
	voyce_chapter_contents_stream:ModelTypes["voyce_chapter_contents"][],
	/** fetch data from the table: "voyce.chapter_effects" */
	voyce_chapter_effects:ModelTypes["voyce_chapter_effects"][],
	/** fetch aggregated fields from the table: "voyce.chapter_effects" */
	voyce_chapter_effects_aggregate:ModelTypes["voyce_chapter_effects_aggregate"],
	/** fetch data from the table: "voyce.chapter_effects" using primary key columns */
	voyce_chapter_effects_by_pk?:ModelTypes["voyce_chapter_effects"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_effects" */
	voyce_chapter_effects_stream:ModelTypes["voyce_chapter_effects"][],
	/** fetch data from the table: "voyce.chapter_images" */
	voyce_chapter_images:ModelTypes["voyce_chapter_images"][],
	/** fetch data from the table: "voyce.chapter_images_07252024" */
	voyce_chapter_images_07252024:ModelTypes["voyce_chapter_images_07252024"][],
	/** fetch aggregated fields from the table: "voyce.chapter_images_07252024" */
	voyce_chapter_images_07252024_aggregate:ModelTypes["voyce_chapter_images_07252024_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_images_07252024" */
	voyce_chapter_images_07252024_stream:ModelTypes["voyce_chapter_images_07252024"][],
	/** fetch aggregated fields from the table: "voyce.chapter_images" */
	voyce_chapter_images_aggregate:ModelTypes["voyce_chapter_images_aggregate"],
	/** fetch data from the table: "voyce.chapter_images" using primary key columns */
	voyce_chapter_images_by_pk?:ModelTypes["voyce_chapter_images"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_images" */
	voyce_chapter_images_stream:ModelTypes["voyce_chapter_images"][],
	/** fetch data from the table: "voyce.chapter_likes" */
	voyce_chapter_likes:ModelTypes["voyce_chapter_likes"][],
	/** fetch aggregated fields from the table: "voyce.chapter_likes" */
	voyce_chapter_likes_aggregate:ModelTypes["voyce_chapter_likes_aggregate"],
	/** fetch data from the table: "voyce.chapter_likes" using primary key columns */
	voyce_chapter_likes_by_pk?:ModelTypes["voyce_chapter_likes"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_likes" */
	voyce_chapter_likes_stream:ModelTypes["voyce_chapter_likes"][],
	/** fetch data from the table: "voyce.chapter_locks" */
	voyce_chapter_locks:ModelTypes["voyce_chapter_locks"][],
	/** fetch aggregated fields from the table: "voyce.chapter_locks" */
	voyce_chapter_locks_aggregate:ModelTypes["voyce_chapter_locks_aggregate"],
	/** fetch data from the table: "voyce.chapter_locks" using primary key columns */
	voyce_chapter_locks_by_pk?:ModelTypes["voyce_chapter_locks"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_locks" */
	voyce_chapter_locks_stream:ModelTypes["voyce_chapter_locks"][],
	/** fetch data from the table: "voyce.chapter_panel_views" */
	voyce_chapter_panel_views:ModelTypes["voyce_chapter_panel_views"][],
	/** fetch aggregated fields from the table: "voyce.chapter_panel_views" */
	voyce_chapter_panel_views_aggregate:ModelTypes["voyce_chapter_panel_views_aggregate"],
	/** fetch data from the table: "voyce.chapter_panel_views" using primary key columns */
	voyce_chapter_panel_views_by_pk?:ModelTypes["voyce_chapter_panel_views"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_panel_views" */
	voyce_chapter_panel_views_stream:ModelTypes["voyce_chapter_panel_views"][],
	/** fetch data from the table: "voyce.chapter_panels" */
	voyce_chapter_panels:ModelTypes["voyce_chapter_panels"][],
	/** fetch aggregated fields from the table: "voyce.chapter_panels" */
	voyce_chapter_panels_aggregate:ModelTypes["voyce_chapter_panels_aggregate"],
	/** fetch data from the table: "voyce.chapter_panels" using primary key columns */
	voyce_chapter_panels_by_pk?:ModelTypes["voyce_chapter_panels"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_panels" */
	voyce_chapter_panels_stream:ModelTypes["voyce_chapter_panels"][],
	/** fetch data from the table: "voyce.chapter_publish_schedules" */
	voyce_chapter_publish_schedules:ModelTypes["voyce_chapter_publish_schedules"][],
	/** fetch aggregated fields from the table: "voyce.chapter_publish_schedules" */
	voyce_chapter_publish_schedules_aggregate:ModelTypes["voyce_chapter_publish_schedules_aggregate"],
	/** fetch data from the table: "voyce.chapter_publish_schedules" using primary key columns */
	voyce_chapter_publish_schedules_by_pk?:ModelTypes["voyce_chapter_publish_schedules"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_publish_schedules" */
	voyce_chapter_publish_schedules_stream:ModelTypes["voyce_chapter_publish_schedules"][],
	/** fetch data from the table: "voyce.chapter_reactions" */
	voyce_chapter_reactions:ModelTypes["voyce_chapter_reactions"][],
	/** fetch aggregated fields from the table: "voyce.chapter_reactions" */
	voyce_chapter_reactions_aggregate:ModelTypes["voyce_chapter_reactions_aggregate"],
	/** fetch data from the table: "voyce.chapter_reactions" using primary key columns */
	voyce_chapter_reactions_by_pk?:ModelTypes["voyce_chapter_reactions"],
	/** fetch data from the table: "voyce.chapter_reactions_count" */
	voyce_chapter_reactions_count:ModelTypes["voyce_chapter_reactions_count"][],
	/** fetch aggregated fields from the table: "voyce.chapter_reactions_count" */
	voyce_chapter_reactions_count_aggregate:ModelTypes["voyce_chapter_reactions_count_aggregate"],
	/** fetch data from the table: "voyce.chapter_reactions_count" using primary key columns */
	voyce_chapter_reactions_count_by_pk?:ModelTypes["voyce_chapter_reactions_count"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_reactions_count" */
	voyce_chapter_reactions_count_stream:ModelTypes["voyce_chapter_reactions_count"][],
	/** fetch data from the table in a streaming manner: "voyce.chapter_reactions" */
	voyce_chapter_reactions_stream:ModelTypes["voyce_chapter_reactions"][],
	/** fetch data from the table: "voyce.chapter_view_logs" */
	voyce_chapter_view_logs:ModelTypes["voyce_chapter_view_logs"][],
	/** fetch aggregated fields from the table: "voyce.chapter_view_logs" */
	voyce_chapter_view_logs_aggregate:ModelTypes["voyce_chapter_view_logs_aggregate"],
	/** fetch data from the table: "voyce.chapter_view_logs" using primary key columns */
	voyce_chapter_view_logs_by_pk?:ModelTypes["voyce_chapter_view_logs"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_view_logs" */
	voyce_chapter_view_logs_stream:ModelTypes["voyce_chapter_view_logs"][],
	/** fetch data from the table: "voyce.chapter_views" */
	voyce_chapter_views:ModelTypes["voyce_chapter_views"][],
	/** fetch aggregated fields from the table: "voyce.chapter_views" */
	voyce_chapter_views_aggregate:ModelTypes["voyce_chapter_views_aggregate"],
	/** fetch data from the table: "voyce.chapter_views" using primary key columns */
	voyce_chapter_views_by_pk?:ModelTypes["voyce_chapter_views"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_views" */
	voyce_chapter_views_stream:ModelTypes["voyce_chapter_views"][],
	/** fetch data from the table: "voyce.chapters" */
	voyce_chapters:ModelTypes["voyce_chapters"][],
	/** fetch data from the table: "voyce.chapters_07252024" */
	voyce_chapters_07252024:ModelTypes["voyce_chapters_07252024"][],
	/** fetch aggregated fields from the table: "voyce.chapters_07252024" */
	voyce_chapters_07252024_aggregate:ModelTypes["voyce_chapters_07252024_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.chapters_07252024" */
	voyce_chapters_07252024_stream:ModelTypes["voyce_chapters_07252024"][],
	/** fetch aggregated fields from the table: "voyce.chapters" */
	voyce_chapters_aggregate:ModelTypes["voyce_chapters_aggregate"],
	/** fetch data from the table: "voyce.chapters" using primary key columns */
	voyce_chapters_by_pk?:ModelTypes["voyce_chapters"],
	/** fetch data from the table in a streaming manner: "voyce.chapters" */
	voyce_chapters_stream:ModelTypes["voyce_chapters"][],
	/** fetch data from the table: "voyce.collaborator_roles" */
	voyce_collaborator_roles:ModelTypes["voyce_collaborator_roles"][],
	/** fetch aggregated fields from the table: "voyce.collaborator_roles" */
	voyce_collaborator_roles_aggregate:ModelTypes["voyce_collaborator_roles_aggregate"],
	/** fetch data from the table: "voyce.collaborator_roles" using primary key columns */
	voyce_collaborator_roles_by_pk?:ModelTypes["voyce_collaborator_roles"],
	/** fetch data from the table in a streaming manner: "voyce.collaborator_roles" */
	voyce_collaborator_roles_stream:ModelTypes["voyce_collaborator_roles"][],
	/** fetch data from the table: "voyce.comic_types" */
	voyce_comic_types:ModelTypes["voyce_comic_types"][],
	/** fetch aggregated fields from the table: "voyce.comic_types" */
	voyce_comic_types_aggregate:ModelTypes["voyce_comic_types_aggregate"],
	/** fetch data from the table: "voyce.comic_types" using primary key columns */
	voyce_comic_types_by_pk?:ModelTypes["voyce_comic_types"],
	/** fetch data from the table in a streaming manner: "voyce.comic_types" */
	voyce_comic_types_stream:ModelTypes["voyce_comic_types"][],
	/** fetch data from the table: "voyce.comments_top_mat" */
	voyce_comments_top_mat:ModelTypes["voyce_comments_top_mat"][],
	/** fetch aggregated fields from the table: "voyce.comments_top_mat" */
	voyce_comments_top_mat_aggregate:ModelTypes["voyce_comments_top_mat_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.comments_top_mat" */
	voyce_comments_top_mat_stream:ModelTypes["voyce_comments_top_mat"][],
	/** fetch data from the table: "voyce.community_blurbs" */
	voyce_community_blurbs:ModelTypes["voyce_community_blurbs"][],
	/** fetch aggregated fields from the table: "voyce.community_blurbs" */
	voyce_community_blurbs_aggregate:ModelTypes["voyce_community_blurbs_aggregate"],
	/** fetch data from the table: "voyce.community_blurbs" using primary key columns */
	voyce_community_blurbs_by_pk?:ModelTypes["voyce_community_blurbs"],
	/** fetch data from the table in a streaming manner: "voyce.community_blurbs" */
	voyce_community_blurbs_stream:ModelTypes["voyce_community_blurbs"][],
	/** fetch data from the table: "voyce.contact_us" */
	voyce_contact_us:ModelTypes["voyce_contact_us"][],
	/** fetch aggregated fields from the table: "voyce.contact_us" */
	voyce_contact_us_aggregate:ModelTypes["voyce_contact_us_aggregate"],
	/** fetch data from the table: "voyce.contact_us" using primary key columns */
	voyce_contact_us_by_pk?:ModelTypes["voyce_contact_us"],
	/** fetch data from the table in a streaming manner: "voyce.contact_us" */
	voyce_contact_us_stream:ModelTypes["voyce_contact_us"][],
	/** fetch data from the table: "voyce.content_report" */
	voyce_content_report:ModelTypes["voyce_content_report"][],
	/** fetch aggregated fields from the table: "voyce.content_report" */
	voyce_content_report_aggregate:ModelTypes["voyce_content_report_aggregate"],
	/** fetch data from the table: "voyce.content_report" using primary key columns */
	voyce_content_report_by_pk?:ModelTypes["voyce_content_report"],
	/** fetch data from the table in a streaming manner: "voyce.content_report" */
	voyce_content_report_stream:ModelTypes["voyce_content_report"][],
	/** fetch data from the table: "voyce.contest_data" */
	voyce_contest_data:ModelTypes["voyce_contest_data"][],
	/** fetch aggregated fields from the table: "voyce.contest_data" */
	voyce_contest_data_aggregate:ModelTypes["voyce_contest_data_aggregate"],
	/** fetch data from the table: "voyce.contest_data" using primary key columns */
	voyce_contest_data_by_pk?:ModelTypes["voyce_contest_data"],
	/** fetch data from the table in a streaming manner: "voyce.contest_data" */
	voyce_contest_data_stream:ModelTypes["voyce_contest_data"][],
	/** fetch data from the table: "voyce.countries" */
	voyce_countries:ModelTypes["voyce_countries"][],
	/** fetch aggregated fields from the table: "voyce.countries" */
	voyce_countries_aggregate:ModelTypes["voyce_countries_aggregate"],
	/** fetch data from the table: "voyce.countries" using primary key columns */
	voyce_countries_by_pk?:ModelTypes["voyce_countries"],
	/** fetch data from the table in a streaming manner: "voyce.countries" */
	voyce_countries_stream:ModelTypes["voyce_countries"][],
	/** fetch data from the table: "voyce.csat_ratings" */
	voyce_csat_ratings:ModelTypes["voyce_csat_ratings"][],
	/** fetch aggregated fields from the table: "voyce.csat_ratings" */
	voyce_csat_ratings_aggregate:ModelTypes["voyce_csat_ratings_aggregate"],
	/** fetch data from the table: "voyce.csat_ratings" using primary key columns */
	voyce_csat_ratings_by_pk?:ModelTypes["voyce_csat_ratings"],
	/** fetch data from the table: "voyce.csat_ratings_data" */
	voyce_csat_ratings_data:ModelTypes["voyce_csat_ratings_data"][],
	/** fetch aggregated fields from the table: "voyce.csat_ratings_data" */
	voyce_csat_ratings_data_aggregate:ModelTypes["voyce_csat_ratings_data_aggregate"],
	/** fetch data from the table: "voyce.csat_ratings_data" using primary key columns */
	voyce_csat_ratings_data_by_pk?:ModelTypes["voyce_csat_ratings_data"],
	/** fetch data from the table in a streaming manner: "voyce.csat_ratings_data" */
	voyce_csat_ratings_data_stream:ModelTypes["voyce_csat_ratings_data"][],
	/** fetch data from the table in a streaming manner: "voyce.csat_ratings" */
	voyce_csat_ratings_stream:ModelTypes["voyce_csat_ratings"][],
	/** fetch data from the table: "voyce.education_types" */
	voyce_education_types:ModelTypes["voyce_education_types"][],
	/** fetch aggregated fields from the table: "voyce.education_types" */
	voyce_education_types_aggregate:ModelTypes["voyce_education_types_aggregate"],
	/** fetch data from the table: "voyce.education_types" using primary key columns */
	voyce_education_types_by_pk?:ModelTypes["voyce_education_types"],
	/** fetch data from the table in a streaming manner: "voyce.education_types" */
	voyce_education_types_stream:ModelTypes["voyce_education_types"][],
	/** fetch data from the table: "voyce.erp_login_roles" */
	voyce_erp_login_roles:ModelTypes["voyce_erp_login_roles"][],
	/** fetch aggregated fields from the table: "voyce.erp_login_roles" */
	voyce_erp_login_roles_aggregate:ModelTypes["voyce_erp_login_roles_aggregate"],
	/** fetch data from the table: "voyce.erp_login_roles" using primary key columns */
	voyce_erp_login_roles_by_pk?:ModelTypes["voyce_erp_login_roles"],
	/** fetch data from the table in a streaming manner: "voyce.erp_login_roles" */
	voyce_erp_login_roles_stream:ModelTypes["voyce_erp_login_roles"][],
	/** fetch data from the table: "voyce.etl_series_views_logs" */
	voyce_etl_series_views_logs:ModelTypes["voyce_etl_series_views_logs"][],
	/** fetch aggregated fields from the table: "voyce.etl_series_views_logs" */
	voyce_etl_series_views_logs_aggregate:ModelTypes["voyce_etl_series_views_logs_aggregate"],
	/** fetch data from the table: "voyce.etl_series_views_logs" using primary key columns */
	voyce_etl_series_views_logs_by_pk?:ModelTypes["voyce_etl_series_views_logs"],
	/** fetch data from the table in a streaming manner: "voyce.etl_series_views_logs" */
	voyce_etl_series_views_logs_stream:ModelTypes["voyce_etl_series_views_logs"][],
	/** fetch data from the table: "voyce.event_schedules" */
	voyce_event_schedules:ModelTypes["voyce_event_schedules"][],
	/** fetch aggregated fields from the table: "voyce.event_schedules" */
	voyce_event_schedules_aggregate:ModelTypes["voyce_event_schedules_aggregate"],
	/** fetch data from the table: "voyce.event_schedules" using primary key columns */
	voyce_event_schedules_by_pk?:ModelTypes["voyce_event_schedules"],
	/** fetch data from the table in a streaming manner: "voyce.event_schedules" */
	voyce_event_schedules_stream:ModelTypes["voyce_event_schedules"][],
	/** fetch data from the table: "voyce.genres" */
	voyce_genres:ModelTypes["voyce_genres"][],
	/** fetch aggregated fields from the table: "voyce.genres" */
	voyce_genres_aggregate:ModelTypes["voyce_genres_aggregate"],
	/** fetch data from the table: "voyce.genres" using primary key columns */
	voyce_genres_by_pk?:ModelTypes["voyce_genres"],
	/** fetch data from the table in a streaming manner: "voyce.genres" */
	voyce_genres_stream:ModelTypes["voyce_genres"][],
	/** fetch data from the table: "voyce.guest_gtags" */
	voyce_guest_gtags:ModelTypes["voyce_guest_gtags"][],
	/** fetch aggregated fields from the table: "voyce.guest_gtags" */
	voyce_guest_gtags_aggregate:ModelTypes["voyce_guest_gtags_aggregate"],
	/** fetch data from the table: "voyce.guest_gtags" using primary key columns */
	voyce_guest_gtags_by_pk?:ModelTypes["voyce_guest_gtags"],
	/** fetch data from the table in a streaming manner: "voyce.guest_gtags" */
	voyce_guest_gtags_stream:ModelTypes["voyce_guest_gtags"][],
	/** fetch data from the table: "voyce.metabases" */
	voyce_metabases:ModelTypes["voyce_metabases"][],
	/** fetch data from the table: "voyce.metabases_access" */
	voyce_metabases_access:ModelTypes["voyce_metabases_access"][],
	/** fetch aggregated fields from the table: "voyce.metabases_access" */
	voyce_metabases_access_aggregate:ModelTypes["voyce_metabases_access_aggregate"],
	/** fetch data from the table: "voyce.metabases_access" using primary key columns */
	voyce_metabases_access_by_pk?:ModelTypes["voyce_metabases_access"],
	/** fetch data from the table in a streaming manner: "voyce.metabases_access" */
	voyce_metabases_access_stream:ModelTypes["voyce_metabases_access"][],
	/** fetch aggregated fields from the table: "voyce.metabases" */
	voyce_metabases_aggregate:ModelTypes["voyce_metabases_aggregate"],
	/** fetch data from the table: "voyce.metabases" using primary key columns */
	voyce_metabases_by_pk?:ModelTypes["voyce_metabases"],
	/** fetch data from the table in a streaming manner: "voyce.metabases" */
	voyce_metabases_stream:ModelTypes["voyce_metabases"][],
	/** fetch data from the table: "voyce.newsletter" */
	voyce_newsletter:ModelTypes["voyce_newsletter"][],
	/** fetch aggregated fields from the table: "voyce.newsletter" */
	voyce_newsletter_aggregate:ModelTypes["voyce_newsletter_aggregate"],
	/** fetch data from the table: "voyce.newsletter" using primary key columns */
	voyce_newsletter_by_pk?:ModelTypes["voyce_newsletter"],
	/** fetch data from the table in a streaming manner: "voyce.newsletter" */
	voyce_newsletter_stream:ModelTypes["voyce_newsletter"][],
	/** fetch data from the table: "voyce.notification_series_settings" */
	voyce_notification_series_settings:ModelTypes["voyce_notification_series_settings"][],
	/** fetch aggregated fields from the table: "voyce.notification_series_settings" */
	voyce_notification_series_settings_aggregate:ModelTypes["voyce_notification_series_settings_aggregate"],
	/** fetch data from the table: "voyce.notification_series_settings" using primary key columns */
	voyce_notification_series_settings_by_pk?:ModelTypes["voyce_notification_series_settings"],
	/** fetch data from the table in a streaming manner: "voyce.notification_series_settings" */
	voyce_notification_series_settings_stream:ModelTypes["voyce_notification_series_settings"][],
	/** fetch data from the table: "voyce.notification_settings" */
	voyce_notification_settings:ModelTypes["voyce_notification_settings"][],
	/** fetch aggregated fields from the table: "voyce.notification_settings" */
	voyce_notification_settings_aggregate:ModelTypes["voyce_notification_settings_aggregate"],
	/** fetch data from the table: "voyce.notification_settings" using primary key columns */
	voyce_notification_settings_by_pk?:ModelTypes["voyce_notification_settings"],
	/** fetch data from the table in a streaming manner: "voyce.notification_settings" */
	voyce_notification_settings_stream:ModelTypes["voyce_notification_settings"][],
	/** fetch data from the table: "voyce.notifications" */
	voyce_notifications:ModelTypes["voyce_notifications"][],
	/** fetch aggregated fields from the table: "voyce.notifications" */
	voyce_notifications_aggregate:ModelTypes["voyce_notifications_aggregate"],
	/** fetch data from the table: "voyce.notifications" using primary key columns */
	voyce_notifications_by_pk?:ModelTypes["voyce_notifications"],
	/** fetch data from the table in a streaming manner: "voyce.notifications" */
	voyce_notifications_stream:ModelTypes["voyce_notifications"][],
	/** fetch data from the table: "voyce.originals_feed" */
	voyce_originals_feed:ModelTypes["voyce_originals_feed"][],
	/** fetch aggregated fields from the table: "voyce.originals_feed" */
	voyce_originals_feed_aggregate:ModelTypes["voyce_originals_feed_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.originals_feed" */
	voyce_originals_feed_stream:ModelTypes["voyce_originals_feed"][],
	/** fetch data from the table: "voyce.page_banners" */
	voyce_page_banners:ModelTypes["voyce_page_banners"][],
	/** fetch aggregated fields from the table: "voyce.page_banners" */
	voyce_page_banners_aggregate:ModelTypes["voyce_page_banners_aggregate"],
	/** fetch data from the table: "voyce.page_banners" using primary key columns */
	voyce_page_banners_by_pk?:ModelTypes["voyce_page_banners"],
	/** fetch data from the table in a streaming manner: "voyce.page_banners" */
	voyce_page_banners_stream:ModelTypes["voyce_page_banners"][],
	/** fetch data from the table: "voyce.page_components" */
	voyce_page_components:ModelTypes["voyce_page_components"][],
	/** fetch aggregated fields from the table: "voyce.page_components" */
	voyce_page_components_aggregate:ModelTypes["voyce_page_components_aggregate"],
	/** fetch data from the table: "voyce.page_components" using primary key columns */
	voyce_page_components_by_pk?:ModelTypes["voyce_page_components"],
	/** fetch data from the table in a streaming manner: "voyce.page_components" */
	voyce_page_components_stream:ModelTypes["voyce_page_components"][],
	/** fetch data from the table: "voyce.pages" */
	voyce_pages:ModelTypes["voyce_pages"][],
	/** fetch aggregated fields from the table: "voyce.pages" */
	voyce_pages_aggregate:ModelTypes["voyce_pages_aggregate"],
	/** fetch data from the table: "voyce.pages" using primary key columns */
	voyce_pages_by_pk?:ModelTypes["voyce_pages"],
	/** fetch data from the table in a streaming manner: "voyce.pages" */
	voyce_pages_stream:ModelTypes["voyce_pages"][],
	/** fetch data from the table: "voyce.panels_comments" */
	voyce_panels_comments:ModelTypes["voyce_panels_comments"][],
	/** fetch aggregated fields from the table: "voyce.panels_comments" */
	voyce_panels_comments_aggregate:ModelTypes["voyce_panels_comments_aggregate"],
	/** fetch data from the table: "voyce.panels_comments" using primary key columns */
	voyce_panels_comments_by_pk?:ModelTypes["voyce_panels_comments"],
	/** fetch data from the table in a streaming manner: "voyce.panels_comments" */
	voyce_panels_comments_stream:ModelTypes["voyce_panels_comments"][],
	/** fetch data from the table: "voyce.panels_reactions" */
	voyce_panels_reactions:ModelTypes["voyce_panels_reactions"][],
	/** fetch aggregated fields from the table: "voyce.panels_reactions" */
	voyce_panels_reactions_aggregate:ModelTypes["voyce_panels_reactions_aggregate"],
	/** fetch data from the table: "voyce.panels_reactions" using primary key columns */
	voyce_panels_reactions_by_pk?:ModelTypes["voyce_panels_reactions"],
	/** fetch data from the table: "voyce.panels_reactions_count" */
	voyce_panels_reactions_count:ModelTypes["voyce_panels_reactions_count"][],
	/** fetch aggregated fields from the table: "voyce.panels_reactions_count" */
	voyce_panels_reactions_count_aggregate:ModelTypes["voyce_panels_reactions_count_aggregate"],
	/** fetch data from the table: "voyce.panels_reactions_count" using primary key columns */
	voyce_panels_reactions_count_by_pk?:ModelTypes["voyce_panels_reactions_count"],
	/** fetch data from the table in a streaming manner: "voyce.panels_reactions_count" */
	voyce_panels_reactions_count_stream:ModelTypes["voyce_panels_reactions_count"][],
	/** fetch data from the table in a streaming manner: "voyce.panels_reactions" */
	voyce_panels_reactions_stream:ModelTypes["voyce_panels_reactions"][],
	/** fetch data from the table: "voyce.payments_to_creators" */
	voyce_payments_to_creators:ModelTypes["voyce_payments_to_creators"][],
	/** fetch aggregated fields from the table: "voyce.payments_to_creators" */
	voyce_payments_to_creators_aggregate:ModelTypes["voyce_payments_to_creators_aggregate"],
	/** fetch data from the table: "voyce.payments_to_creators" using primary key columns */
	voyce_payments_to_creators_by_pk?:ModelTypes["voyce_payments_to_creators"],
	/** fetch data from the table in a streaming manner: "voyce.payments_to_creators" */
	voyce_payments_to_creators_stream:ModelTypes["voyce_payments_to_creators"][],
	/** fetch data from the table: "voyce.pg_rs_series_views_count" */
	voyce_pg_rs_series_views_count:ModelTypes["voyce_pg_rs_series_views_count"][],
	/** fetch aggregated fields from the table: "voyce.pg_rs_series_views_count" */
	voyce_pg_rs_series_views_count_aggregate:ModelTypes["voyce_pg_rs_series_views_count_aggregate"],
	/** fetch data from the table: "voyce.pg_rs_series_views_count" using primary key columns */
	voyce_pg_rs_series_views_count_by_pk?:ModelTypes["voyce_pg_rs_series_views_count"],
	/** fetch data from the table in a streaming manner: "voyce.pg_rs_series_views_count" */
	voyce_pg_rs_series_views_count_stream:ModelTypes["voyce_pg_rs_series_views_count"][],
	/** fetch data from the table: "voyce.polls" */
	voyce_polls:ModelTypes["voyce_polls"][],
	/** fetch aggregated fields from the table: "voyce.polls" */
	voyce_polls_aggregate:ModelTypes["voyce_polls_aggregate"],
	/** fetch data from the table: "voyce.polls_answers" */
	voyce_polls_answers:ModelTypes["voyce_polls_answers"][],
	/** fetch aggregated fields from the table: "voyce.polls_answers" */
	voyce_polls_answers_aggregate:ModelTypes["voyce_polls_answers_aggregate"],
	/** fetch data from the table: "voyce.polls_answers" using primary key columns */
	voyce_polls_answers_by_pk?:ModelTypes["voyce_polls_answers"],
	/** fetch data from the table in a streaming manner: "voyce.polls_answers" */
	voyce_polls_answers_stream:ModelTypes["voyce_polls_answers"][],
	/** fetch data from the table: "voyce.polls" using primary key columns */
	voyce_polls_by_pk?:ModelTypes["voyce_polls"],
	/** fetch data from the table: "voyce.polls_comments" */
	voyce_polls_comments:ModelTypes["voyce_polls_comments"][],
	/** fetch aggregated fields from the table: "voyce.polls_comments" */
	voyce_polls_comments_aggregate:ModelTypes["voyce_polls_comments_aggregate"],
	/** fetch data from the table: "voyce.polls_comments" using primary key columns */
	voyce_polls_comments_by_pk?:ModelTypes["voyce_polls_comments"],
	/** fetch data from the table in a streaming manner: "voyce.polls_comments" */
	voyce_polls_comments_stream:ModelTypes["voyce_polls_comments"][],
	/** fetch data from the table: "voyce.polls_reactions" */
	voyce_polls_reactions:ModelTypes["voyce_polls_reactions"][],
	/** fetch aggregated fields from the table: "voyce.polls_reactions" */
	voyce_polls_reactions_aggregate:ModelTypes["voyce_polls_reactions_aggregate"],
	/** fetch data from the table: "voyce.polls_reactions" using primary key columns */
	voyce_polls_reactions_by_pk?:ModelTypes["voyce_polls_reactions"],
	/** fetch data from the table in a streaming manner: "voyce.polls_reactions" */
	voyce_polls_reactions_stream:ModelTypes["voyce_polls_reactions"][],
	/** fetch data from the table in a streaming manner: "voyce.polls" */
	voyce_polls_stream:ModelTypes["voyce_polls"][],
	/** fetch data from the table: "voyce.polls_votes" */
	voyce_polls_votes:ModelTypes["voyce_polls_votes"][],
	/** fetch aggregated fields from the table: "voyce.polls_votes" */
	voyce_polls_votes_aggregate:ModelTypes["voyce_polls_votes_aggregate"],
	/** fetch data from the table: "voyce.polls_votes" using primary key columns */
	voyce_polls_votes_by_pk?:ModelTypes["voyce_polls_votes"],
	/** fetch data from the table in a streaming manner: "voyce.polls_votes" */
	voyce_polls_votes_stream:ModelTypes["voyce_polls_votes"][],
	/** fetch data from the table: "voyce.popup_components" */
	voyce_popup_components:ModelTypes["voyce_popup_components"][],
	/** fetch aggregated fields from the table: "voyce.popup_components" */
	voyce_popup_components_aggregate:ModelTypes["voyce_popup_components_aggregate"],
	/** fetch data from the table: "voyce.popup_components" using primary key columns */
	voyce_popup_components_by_pk?:ModelTypes["voyce_popup_components"],
	/** fetch data from the table in a streaming manner: "voyce.popup_components" */
	voyce_popup_components_stream:ModelTypes["voyce_popup_components"][],
	/** fetch data from the table: "voyce.popup_emails" */
	voyce_popup_emails:ModelTypes["voyce_popup_emails"][],
	/** fetch aggregated fields from the table: "voyce.popup_emails" */
	voyce_popup_emails_aggregate:ModelTypes["voyce_popup_emails_aggregate"],
	/** fetch data from the table: "voyce.popup_emails" using primary key columns */
	voyce_popup_emails_by_pk?:ModelTypes["voyce_popup_emails"],
	/** fetch data from the table in a streaming manner: "voyce.popup_emails" */
	voyce_popup_emails_stream:ModelTypes["voyce_popup_emails"][],
	/** fetch data from the table: "voyce.posts" */
	voyce_posts:ModelTypes["voyce_posts"][],
	/** fetch aggregated fields from the table: "voyce.posts" */
	voyce_posts_aggregate:ModelTypes["voyce_posts_aggregate"],
	/** fetch data from the table: "voyce.posts" using primary key columns */
	voyce_posts_by_pk?:ModelTypes["voyce_posts"],
	/** fetch data from the table: "voyce.posts_comments" */
	voyce_posts_comments:ModelTypes["voyce_posts_comments"][],
	/** fetch aggregated fields from the table: "voyce.posts_comments" */
	voyce_posts_comments_aggregate:ModelTypes["voyce_posts_comments_aggregate"],
	/** fetch data from the table: "voyce.posts_comments" using primary key columns */
	voyce_posts_comments_by_pk?:ModelTypes["voyce_posts_comments"],
	/** fetch data from the table in a streaming manner: "voyce.posts_comments" */
	voyce_posts_comments_stream:ModelTypes["voyce_posts_comments"][],
	/** fetch data from the table: "voyce.posts_reactions" */
	voyce_posts_reactions:ModelTypes["voyce_posts_reactions"][],
	/** fetch aggregated fields from the table: "voyce.posts_reactions" */
	voyce_posts_reactions_aggregate:ModelTypes["voyce_posts_reactions_aggregate"],
	/** fetch data from the table: "voyce.posts_reactions" using primary key columns */
	voyce_posts_reactions_by_pk?:ModelTypes["voyce_posts_reactions"],
	/** fetch data from the table in a streaming manner: "voyce.posts_reactions" */
	voyce_posts_reactions_stream:ModelTypes["voyce_posts_reactions"][],
	/** fetch data from the table in a streaming manner: "voyce.posts" */
	voyce_posts_stream:ModelTypes["voyce_posts"][],
	/** fetch data from the table: "voyce.quest_difficulties" */
	voyce_quest_difficulties:ModelTypes["voyce_quest_difficulties"][],
	/** fetch aggregated fields from the table: "voyce.quest_difficulties" */
	voyce_quest_difficulties_aggregate:ModelTypes["voyce_quest_difficulties_aggregate"],
	/** fetch data from the table: "voyce.quest_difficulties" using primary key columns */
	voyce_quest_difficulties_by_pk?:ModelTypes["voyce_quest_difficulties"],
	/** fetch data from the table in a streaming manner: "voyce.quest_difficulties" */
	voyce_quest_difficulties_stream:ModelTypes["voyce_quest_difficulties"][],
	/** fetch data from the table: "voyce.quests" */
	voyce_quests:ModelTypes["voyce_quests"][],
	/** fetch aggregated fields from the table: "voyce.quests" */
	voyce_quests_aggregate:ModelTypes["voyce_quests_aggregate"],
	/** fetch data from the table: "voyce.quests" using primary key columns */
	voyce_quests_by_pk?:ModelTypes["voyce_quests"],
	/** fetch data from the table: "voyce.quests_old" */
	voyce_quests_old:ModelTypes["voyce_quests_old"][],
	/** fetch aggregated fields from the table: "voyce.quests_old" */
	voyce_quests_old_aggregate:ModelTypes["voyce_quests_old_aggregate"],
	/** fetch data from the table: "voyce.quests_old" using primary key columns */
	voyce_quests_old_by_pk?:ModelTypes["voyce_quests_old"],
	/** fetch data from the table in a streaming manner: "voyce.quests_old" */
	voyce_quests_old_stream:ModelTypes["voyce_quests_old"][],
	/** fetch data from the table in a streaming manner: "voyce.quests" */
	voyce_quests_stream:ModelTypes["voyce_quests"][],
	/** fetch data from the table: "voyce.recommended_series" */
	voyce_recommended_series:ModelTypes["voyce_recommended_series"][],
	/** fetch aggregated fields from the table: "voyce.recommended_series" */
	voyce_recommended_series_aggregate:ModelTypes["voyce_recommended_series_aggregate"],
	/** fetch data from the table: "voyce.recommended_series" using primary key columns */
	voyce_recommended_series_by_pk?:ModelTypes["voyce_recommended_series"],
	/** fetch data from the table in a streaming manner: "voyce.recommended_series" */
	voyce_recommended_series_stream:ModelTypes["voyce_recommended_series"][],
	/** fetch data from the table: "voyce.release_schedules" */
	voyce_release_schedules:ModelTypes["voyce_release_schedules"][],
	/** fetch aggregated fields from the table: "voyce.release_schedules" */
	voyce_release_schedules_aggregate:ModelTypes["voyce_release_schedules_aggregate"],
	/** fetch data from the table: "voyce.release_schedules" using primary key columns */
	voyce_release_schedules_by_pk?:ModelTypes["voyce_release_schedules"],
	/** fetch data from the table in a streaming manner: "voyce.release_schedules" */
	voyce_release_schedules_stream:ModelTypes["voyce_release_schedules"][],
	/** fetch data from the table: "voyce.rewards" */
	voyce_rewards:ModelTypes["voyce_rewards"][],
	/** fetch aggregated fields from the table: "voyce.rewards" */
	voyce_rewards_aggregate:ModelTypes["voyce_rewards_aggregate"],
	/** fetch data from the table: "voyce.rewards" using primary key columns */
	voyce_rewards_by_pk?:ModelTypes["voyce_rewards"],
	/** fetch data from the table: "voyce.rewards_leaderboard" */
	voyce_rewards_leaderboard:ModelTypes["voyce_rewards_leaderboard"][],
	/** fetch aggregated fields from the table: "voyce.rewards_leaderboard" */
	voyce_rewards_leaderboard_aggregate:ModelTypes["voyce_rewards_leaderboard_aggregate"],
	/** fetch data from the table: "voyce.rewards_leaderboard" using primary key columns */
	voyce_rewards_leaderboard_by_pk?:ModelTypes["voyce_rewards_leaderboard"],
	/** fetch data from the table in a streaming manner: "voyce.rewards_leaderboard" */
	voyce_rewards_leaderboard_stream:ModelTypes["voyce_rewards_leaderboard"][],
	/** fetch data from the table: "voyce.rewards_quest" */
	voyce_rewards_quest:ModelTypes["voyce_rewards_quest"][],
	/** fetch aggregated fields from the table: "voyce.rewards_quest" */
	voyce_rewards_quest_aggregate:ModelTypes["voyce_rewards_quest_aggregate"],
	/** fetch data from the table: "voyce.rewards_quest" using primary key columns */
	voyce_rewards_quest_by_pk?:ModelTypes["voyce_rewards_quest"],
	/** fetch data from the table in a streaming manner: "voyce.rewards_quest" */
	voyce_rewards_quest_stream:ModelTypes["voyce_rewards_quest"][],
	/** fetch data from the table in a streaming manner: "voyce.rewards" */
	voyce_rewards_stream:ModelTypes["voyce_rewards"][],
	/** fetch data from the table: "voyce.sections" */
	voyce_sections:ModelTypes["voyce_sections"][],
	/** fetch aggregated fields from the table: "voyce.sections" */
	voyce_sections_aggregate:ModelTypes["voyce_sections_aggregate"],
	/** fetch data from the table: "voyce.sections" using primary key columns */
	voyce_sections_by_pk?:ModelTypes["voyce_sections"],
	/** fetch data from the table in a streaming manner: "voyce.sections" */
	voyce_sections_stream:ModelTypes["voyce_sections"][],
	/** fetch data from the table: "voyce.series" */
	voyce_series:ModelTypes["voyce_series"][],
	/** fetch aggregated fields from the table: "voyce.series" */
	voyce_series_aggregate:ModelTypes["voyce_series_aggregate"],
	/** fetch data from the table: "voyce.series" using primary key columns */
	voyce_series_by_pk?:ModelTypes["voyce_series"],
	/** fetch data from the table: "voyce.series_collaborators" */
	voyce_series_collaborators:ModelTypes["voyce_series_collaborators"][],
	/** fetch aggregated fields from the table: "voyce.series_collaborators" */
	voyce_series_collaborators_aggregate:ModelTypes["voyce_series_collaborators_aggregate"],
	/** fetch data from the table: "voyce.series_collaborators" using primary key columns */
	voyce_series_collaborators_by_pk?:ModelTypes["voyce_series_collaborators"],
	/** fetch data from the table in a streaming manner: "voyce.series_collaborators" */
	voyce_series_collaborators_stream:ModelTypes["voyce_series_collaborators"][],
	/** fetch data from the table: "voyce.series_donations" */
	voyce_series_donations:ModelTypes["voyce_series_donations"][],
	/** fetch aggregated fields from the table: "voyce.series_donations" */
	voyce_series_donations_aggregate:ModelTypes["voyce_series_donations_aggregate"],
	/** fetch data from the table: "voyce.series_donations" using primary key columns */
	voyce_series_donations_by_pk?:ModelTypes["voyce_series_donations"],
	/** fetch data from the table in a streaming manner: "voyce.series_donations" */
	voyce_series_donations_stream:ModelTypes["voyce_series_donations"][],
	/** fetch data from the table: "voyce.series_favorites" */
	voyce_series_favorites:ModelTypes["voyce_series_favorites"][],
	/** fetch aggregated fields from the table: "voyce.series_favorites" */
	voyce_series_favorites_aggregate:ModelTypes["voyce_series_favorites_aggregate"],
	/** fetch data from the table: "voyce.series_favorites" using primary key columns */
	voyce_series_favorites_by_pk?:ModelTypes["voyce_series_favorites"],
	/** fetch data from the table in a streaming manner: "voyce.series_favorites" */
	voyce_series_favorites_stream:ModelTypes["voyce_series_favorites"][],
	/** fetch data from the table: "voyce.series_featured_panels" */
	voyce_series_featured_panels:ModelTypes["voyce_series_featured_panels"][],
	/** fetch aggregated fields from the table: "voyce.series_featured_panels" */
	voyce_series_featured_panels_aggregate:ModelTypes["voyce_series_featured_panels_aggregate"],
	/** fetch data from the table: "voyce.series_featured_panels" using primary key columns */
	voyce_series_featured_panels_by_pk?:ModelTypes["voyce_series_featured_panels"],
	/** fetch data from the table in a streaming manner: "voyce.series_featured_panels" */
	voyce_series_featured_panels_stream:ModelTypes["voyce_series_featured_panels"][],
	/** fetch data from the table: "voyce.series_genre" */
	voyce_series_genre:ModelTypes["voyce_series_genre"][],
	/** fetch aggregated fields from the table: "voyce.series_genre" */
	voyce_series_genre_aggregate:ModelTypes["voyce_series_genre_aggregate"],
	/** fetch data from the table: "voyce.series_genre" using primary key columns */
	voyce_series_genre_by_pk?:ModelTypes["voyce_series_genre"],
	/** fetch data from the table in a streaming manner: "voyce.series_genre" */
	voyce_series_genre_stream:ModelTypes["voyce_series_genre"][],
	/** fetch data from the table: "voyce.series_giveaway_participants" */
	voyce_series_giveaway_participants:ModelTypes["voyce_series_giveaway_participants"][],
	/** fetch aggregated fields from the table: "voyce.series_giveaway_participants" */
	voyce_series_giveaway_participants_aggregate:ModelTypes["voyce_series_giveaway_participants_aggregate"],
	/** fetch data from the table: "voyce.series_giveaway_participants" using primary key columns */
	voyce_series_giveaway_participants_by_pk?:ModelTypes["voyce_series_giveaway_participants"],
	/** fetch data from the table in a streaming manner: "voyce.series_giveaway_participants" */
	voyce_series_giveaway_participants_stream:ModelTypes["voyce_series_giveaway_participants"][],
	/** fetch data from the table: "voyce.series_hot_panels" */
	voyce_series_hot_panels:ModelTypes["voyce_series_hot_panels"][],
	/** fetch aggregated fields from the table: "voyce.series_hot_panels" */
	voyce_series_hot_panels_aggregate:ModelTypes["voyce_series_hot_panels_aggregate"],
	/** fetch data from the table: "voyce.series_hot_panels" using primary key columns */
	voyce_series_hot_panels_by_pk?:ModelTypes["voyce_series_hot_panels"],
	/** fetch data from the table in a streaming manner: "voyce.series_hot_panels" */
	voyce_series_hot_panels_stream:ModelTypes["voyce_series_hot_panels"][],
	/** fetch data from the table: "voyce.series_layouts" */
	voyce_series_layouts:ModelTypes["voyce_series_layouts"][],
	/** fetch aggregated fields from the table: "voyce.series_layouts" */
	voyce_series_layouts_aggregate:ModelTypes["voyce_series_layouts_aggregate"],
	/** fetch data from the table: "voyce.series_layouts" using primary key columns */
	voyce_series_layouts_by_pk?:ModelTypes["voyce_series_layouts"],
	/** fetch data from the table in a streaming manner: "voyce.series_layouts" */
	voyce_series_layouts_stream:ModelTypes["voyce_series_layouts"][],
	/** fetch data from the table: "voyce.series_leaderboard" */
	voyce_series_leaderboard:ModelTypes["voyce_series_leaderboard"][],
	/** fetch aggregated fields from the table: "voyce.series_leaderboard" */
	voyce_series_leaderboard_aggregate:ModelTypes["voyce_series_leaderboard_aggregate"],
	/** fetch data from the table: "voyce.series_leaderboard" using primary key columns */
	voyce_series_leaderboard_by_pk?:ModelTypes["voyce_series_leaderboard"],
	/** fetch data from the table in a streaming manner: "voyce.series_leaderboard" */
	voyce_series_leaderboard_stream:ModelTypes["voyce_series_leaderboard"][],
	/** fetch data from the table: "voyce.series_likes_count" */
	voyce_series_likes_count:ModelTypes["voyce_series_likes_count"][],
	/** fetch aggregated fields from the table: "voyce.series_likes_count" */
	voyce_series_likes_count_aggregate:ModelTypes["voyce_series_likes_count_aggregate"],
	/** fetch data from the table: "voyce.series_likes_count_mat" */
	voyce_series_likes_count_mat:ModelTypes["voyce_series_likes_count_mat"][],
	/** fetch aggregated fields from the table: "voyce.series_likes_count_mat" */
	voyce_series_likes_count_mat_aggregate:ModelTypes["voyce_series_likes_count_mat_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.series_likes_count_mat" */
	voyce_series_likes_count_mat_stream:ModelTypes["voyce_series_likes_count_mat"][],
	/** fetch data from the table in a streaming manner: "voyce.series_likes_count" */
	voyce_series_likes_count_stream:ModelTypes["voyce_series_likes_count"][],
	/** fetch data from the table: "voyce.series_lock_config" */
	voyce_series_lock_config:ModelTypes["voyce_series_lock_config"][],
	/** fetch aggregated fields from the table: "voyce.series_lock_config" */
	voyce_series_lock_config_aggregate:ModelTypes["voyce_series_lock_config_aggregate"],
	/** fetch data from the table: "voyce.series_lock_config" using primary key columns */
	voyce_series_lock_config_by_pk?:ModelTypes["voyce_series_lock_config"],
	/** fetch data from the table in a streaming manner: "voyce.series_lock_config" */
	voyce_series_lock_config_stream:ModelTypes["voyce_series_lock_config"][],
	/** fetch data from the table: "voyce.series_sponsorships_configuration" */
	voyce_series_sponsorships_configuration:ModelTypes["voyce_series_sponsorships_configuration"][],
	/** fetch aggregated fields from the table: "voyce.series_sponsorships_configuration" */
	voyce_series_sponsorships_configuration_aggregate:ModelTypes["voyce_series_sponsorships_configuration_aggregate"],
	/** fetch data from the table: "voyce.series_sponsorships_configuration" using primary key columns */
	voyce_series_sponsorships_configuration_by_pk?:ModelTypes["voyce_series_sponsorships_configuration"],
	/** fetch data from the table in a streaming manner: "voyce.series_sponsorships_configuration" */
	voyce_series_sponsorships_configuration_stream:ModelTypes["voyce_series_sponsorships_configuration"][],
	/** fetch data from the table in a streaming manner: "voyce.series" */
	voyce_series_stream:ModelTypes["voyce_series"][],
	/** fetch data from the table: "voyce.series_subscription" */
	voyce_series_subscription:ModelTypes["voyce_series_subscription"][],
	/** fetch aggregated fields from the table: "voyce.series_subscription" */
	voyce_series_subscription_aggregate:ModelTypes["voyce_series_subscription_aggregate"],
	/** fetch data from the table: "voyce.series_subscription" using primary key columns */
	voyce_series_subscription_by_pk?:ModelTypes["voyce_series_subscription"],
	/** fetch data from the table in a streaming manner: "voyce.series_subscription" */
	voyce_series_subscription_stream:ModelTypes["voyce_series_subscription"][],
	/** fetch data from the table: "voyce.series_tags" */
	voyce_series_tags:ModelTypes["voyce_series_tags"][],
	/** fetch aggregated fields from the table: "voyce.series_tags" */
	voyce_series_tags_aggregate:ModelTypes["voyce_series_tags_aggregate"],
	/** fetch data from the table: "voyce.series_tags" using primary key columns */
	voyce_series_tags_by_pk?:ModelTypes["voyce_series_tags"],
	/** fetch data from the table: "voyce.series_tags_junction" */
	voyce_series_tags_junction:ModelTypes["voyce_series_tags_junction"][],
	/** fetch aggregated fields from the table: "voyce.series_tags_junction" */
	voyce_series_tags_junction_aggregate:ModelTypes["voyce_series_tags_junction_aggregate"],
	/** fetch data from the table: "voyce.series_tags_junction" using primary key columns */
	voyce_series_tags_junction_by_pk?:ModelTypes["voyce_series_tags_junction"],
	/** fetch data from the table in a streaming manner: "voyce.series_tags_junction" */
	voyce_series_tags_junction_stream:ModelTypes["voyce_series_tags_junction"][],
	/** fetch data from the table in a streaming manner: "voyce.series_tags" */
	voyce_series_tags_stream:ModelTypes["voyce_series_tags"][],
	/** fetch data from the table: "voyce.series_top_comments" */
	voyce_series_top_comments:ModelTypes["voyce_series_top_comments"][],
	/** fetch aggregated fields from the table: "voyce.series_top_comments" */
	voyce_series_top_comments_aggregate:ModelTypes["voyce_series_top_comments_aggregate"],
	/** fetch data from the table: "voyce.series_top_comments" using primary key columns */
	voyce_series_top_comments_by_pk?:ModelTypes["voyce_series_top_comments"],
	/** fetch data from the table in a streaming manner: "voyce.series_top_comments" */
	voyce_series_top_comments_stream:ModelTypes["voyce_series_top_comments"][],
	/** fetch data from the table: "voyce.series_top_likes" */
	voyce_series_top_likes:ModelTypes["voyce_series_top_likes"][],
	/** fetch aggregated fields from the table: "voyce.series_top_likes" */
	voyce_series_top_likes_aggregate:ModelTypes["voyce_series_top_likes_aggregate"],
	/** fetch data from the table: "voyce.series_top_likes" using primary key columns */
	voyce_series_top_likes_by_pk?:ModelTypes["voyce_series_top_likes"],
	/** fetch data from the table in a streaming manner: "voyce.series_top_likes" */
	voyce_series_top_likes_stream:ModelTypes["voyce_series_top_likes"][],
	/** fetch data from the table: "voyce.series_top_views" */
	voyce_series_top_views:ModelTypes["voyce_series_top_views"][],
	/** fetch aggregated fields from the table: "voyce.series_top_views" */
	voyce_series_top_views_aggregate:ModelTypes["voyce_series_top_views_aggregate"],
	/** fetch data from the table: "voyce.series_top_views" using primary key columns */
	voyce_series_top_views_by_pk?:ModelTypes["voyce_series_top_views"],
	/** fetch data from the table in a streaming manner: "voyce.series_top_views" */
	voyce_series_top_views_stream:ModelTypes["voyce_series_top_views"][],
	/** fetch data from the table: "voyce.series_views_adjustment" */
	voyce_series_views_adjustment:ModelTypes["voyce_series_views_adjustment"][],
	/** fetch aggregated fields from the table: "voyce.series_views_adjustment" */
	voyce_series_views_adjustment_aggregate:ModelTypes["voyce_series_views_adjustment_aggregate"],
	/** fetch data from the table: "voyce.series_views_adjustment" using primary key columns */
	voyce_series_views_adjustment_by_pk?:ModelTypes["voyce_series_views_adjustment"],
	/** fetch data from the table in a streaming manner: "voyce.series_views_adjustment" */
	voyce_series_views_adjustment_stream:ModelTypes["voyce_series_views_adjustment"][],
	/** fetch data from the table: "voyce.series_views_count" */
	voyce_series_views_count:ModelTypes["voyce_series_views_count"][],
	/** fetch aggregated fields from the table: "voyce.series_views_count" */
	voyce_series_views_count_aggregate:ModelTypes["voyce_series_views_count_aggregate"],
	/** fetch data from the table: "voyce.series_views_count_mat" */
	voyce_series_views_count_mat:ModelTypes["voyce_series_views_count_mat"][],
	/** fetch aggregated fields from the table: "voyce.series_views_count_mat" */
	voyce_series_views_count_mat_aggregate:ModelTypes["voyce_series_views_count_mat_aggregate"],
	/** fetch data from the table: "voyce.series_views_count_mat_sep30" */
	voyce_series_views_count_mat_sep30:ModelTypes["voyce_series_views_count_mat_sep30"][],
	/** fetch aggregated fields from the table: "voyce.series_views_count_mat_sep30" */
	voyce_series_views_count_mat_sep30_aggregate:ModelTypes["voyce_series_views_count_mat_sep30_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.series_views_count_mat_sep30" */
	voyce_series_views_count_mat_sep30_stream:ModelTypes["voyce_series_views_count_mat_sep30"][],
	/** fetch data from the table in a streaming manner: "voyce.series_views_count_mat" */
	voyce_series_views_count_mat_stream:ModelTypes["voyce_series_views_count_mat"][],
	/** fetch data from the table: "voyce.series_views_count_mat_v2" */
	voyce_series_views_count_mat_v2:ModelTypes["voyce_series_views_count_mat_v2"][],
	/** fetch aggregated fields from the table: "voyce.series_views_count_mat_v2" */
	voyce_series_views_count_mat_v2_aggregate:ModelTypes["voyce_series_views_count_mat_v2_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.series_views_count_mat_v2" */
	voyce_series_views_count_mat_v2_stream:ModelTypes["voyce_series_views_count_mat_v2"][],
	/** fetch data from the table in a streaming manner: "voyce.series_views_count" */
	voyce_series_views_count_stream:ModelTypes["voyce_series_views_count"][],
	/** fetch data from the table: "voyce.series_votes" */
	voyce_series_votes:ModelTypes["voyce_series_votes"][],
	/** fetch aggregated fields from the table: "voyce.series_votes" */
	voyce_series_votes_aggregate:ModelTypes["voyce_series_votes_aggregate"],
	/** fetch data from the table: "voyce.series_votes" using primary key columns */
	voyce_series_votes_by_pk?:ModelTypes["voyce_series_votes"],
	/** fetch data from the table: "voyce.series_votes_count" */
	voyce_series_votes_count:ModelTypes["voyce_series_votes_count"][],
	/** fetch aggregated fields from the table: "voyce.series_votes_count" */
	voyce_series_votes_count_aggregate:ModelTypes["voyce_series_votes_count_aggregate"],
	/** fetch data from the table: "voyce.series_votes_count" using primary key columns */
	voyce_series_votes_count_by_pk?:ModelTypes["voyce_series_votes_count"],
	/** fetch data from the table in a streaming manner: "voyce.series_votes_count" */
	voyce_series_votes_count_stream:ModelTypes["voyce_series_votes_count"][],
	/** fetch data from the table in a streaming manner: "voyce.series_votes" */
	voyce_series_votes_stream:ModelTypes["voyce_series_votes"][],
	/** fetch data from the table: "voyce.series_voting_schedules" */
	voyce_series_voting_schedules:ModelTypes["voyce_series_voting_schedules"][],
	/** fetch aggregated fields from the table: "voyce.series_voting_schedules" */
	voyce_series_voting_schedules_aggregate:ModelTypes["voyce_series_voting_schedules_aggregate"],
	/** fetch data from the table: "voyce.series_voting_schedules" using primary key columns */
	voyce_series_voting_schedules_by_pk?:ModelTypes["voyce_series_voting_schedules"],
	/** fetch data from the table in a streaming manner: "voyce.series_voting_schedules" */
	voyce_series_voting_schedules_stream:ModelTypes["voyce_series_voting_schedules"][],
	/** fetch data from the table: "voyce.sponsorships" */
	voyce_sponsorships:ModelTypes["voyce_sponsorships"][],
	/** fetch aggregated fields from the table: "voyce.sponsorships" */
	voyce_sponsorships_aggregate:ModelTypes["voyce_sponsorships_aggregate"],
	/** fetch data from the table: "voyce.sponsorships" using primary key columns */
	voyce_sponsorships_by_pk?:ModelTypes["voyce_sponsorships"],
	/** fetch data from the table in a streaming manner: "voyce.sponsorships" */
	voyce_sponsorships_stream:ModelTypes["voyce_sponsorships"][],
	/** fetch data from the table: "voyce.states" */
	voyce_states:ModelTypes["voyce_states"][],
	/** fetch aggregated fields from the table: "voyce.states" */
	voyce_states_aggregate:ModelTypes["voyce_states_aggregate"],
	/** fetch data from the table: "voyce.states" using primary key columns */
	voyce_states_by_pk?:ModelTypes["voyce_states"],
	/** fetch data from the table in a streaming manner: "voyce.states" */
	voyce_states_stream:ModelTypes["voyce_states"][],
	/** fetch data from the table: "voyce.stripe_payouts_setup" */
	voyce_stripe_payouts_setup:ModelTypes["voyce_stripe_payouts_setup"][],
	/** fetch aggregated fields from the table: "voyce.stripe_payouts_setup" */
	voyce_stripe_payouts_setup_aggregate:ModelTypes["voyce_stripe_payouts_setup_aggregate"],
	/** fetch data from the table: "voyce.stripe_payouts_setup" using primary key columns */
	voyce_stripe_payouts_setup_by_pk?:ModelTypes["voyce_stripe_payouts_setup"],
	/** fetch data from the table in a streaming manner: "voyce.stripe_payouts_setup" */
	voyce_stripe_payouts_setup_stream:ModelTypes["voyce_stripe_payouts_setup"][],
	/** fetch data from the table: "voyce.studio" */
	voyce_studio:ModelTypes["voyce_studio"][],
	/** fetch aggregated fields from the table: "voyce.studio" */
	voyce_studio_aggregate:ModelTypes["voyce_studio_aggregate"],
	/** fetch data from the table: "voyce.studio" using primary key columns */
	voyce_studio_by_pk?:ModelTypes["voyce_studio"],
	/** fetch data from the table: "voyce.studio_followers" */
	voyce_studio_followers:ModelTypes["voyce_studio_followers"][],
	/** fetch aggregated fields from the table: "voyce.studio_followers" */
	voyce_studio_followers_aggregate:ModelTypes["voyce_studio_followers_aggregate"],
	/** fetch data from the table: "voyce.studio_followers" using primary key columns */
	voyce_studio_followers_by_pk?:ModelTypes["voyce_studio_followers"],
	/** fetch data from the table in a streaming manner: "voyce.studio_followers" */
	voyce_studio_followers_stream:ModelTypes["voyce_studio_followers"][],
	/** fetch data from the table: "voyce.studio_series" */
	voyce_studio_series:ModelTypes["voyce_studio_series"][],
	/** fetch aggregated fields from the table: "voyce.studio_series" */
	voyce_studio_series_aggregate:ModelTypes["voyce_studio_series_aggregate"],
	/** fetch data from the table: "voyce.studio_series" using primary key columns */
	voyce_studio_series_by_pk?:ModelTypes["voyce_studio_series"],
	/** fetch data from the table in a streaming manner: "voyce.studio_series" */
	voyce_studio_series_stream:ModelTypes["voyce_studio_series"][],
	/** fetch data from the table in a streaming manner: "voyce.studio" */
	voyce_studio_stream:ModelTypes["voyce_studio"][],
	/** fetch data from the table: "voyce.studio_views" */
	voyce_studio_views:ModelTypes["voyce_studio_views"][],
	/** fetch aggregated fields from the table: "voyce.studio_views" */
	voyce_studio_views_aggregate:ModelTypes["voyce_studio_views_aggregate"],
	/** fetch data from the table: "voyce.studio_views" using primary key columns */
	voyce_studio_views_by_pk?:ModelTypes["voyce_studio_views"],
	/** fetch data from the table in a streaming manner: "voyce.studio_views" */
	voyce_studio_views_stream:ModelTypes["voyce_studio_views"][],
	/** fetch data from the table: "voyce.system_settings" */
	voyce_system_settings:ModelTypes["voyce_system_settings"][],
	/** fetch aggregated fields from the table: "voyce.system_settings" */
	voyce_system_settings_aggregate:ModelTypes["voyce_system_settings_aggregate"],
	/** fetch data from the table: "voyce.system_settings" using primary key columns */
	voyce_system_settings_by_pk?:ModelTypes["voyce_system_settings"],
	/** fetch data from the table in a streaming manner: "voyce.system_settings" */
	voyce_system_settings_stream:ModelTypes["voyce_system_settings"][],
	/** fetch data from the table: "voyce.top_genres" */
	voyce_top_genres:ModelTypes["voyce_top_genres"][],
	/** fetch aggregated fields from the table: "voyce.top_genres" */
	voyce_top_genres_aggregate:ModelTypes["voyce_top_genres_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.top_genres" */
	voyce_top_genres_stream:ModelTypes["voyce_top_genres"][],
	/** fetch data from the table: "voyce.transaction_logs" */
	voyce_transaction_logs:ModelTypes["voyce_transaction_logs"][],
	/** fetch aggregated fields from the table: "voyce.transaction_logs" */
	voyce_transaction_logs_aggregate:ModelTypes["voyce_transaction_logs_aggregate"],
	/** fetch data from the table: "voyce.transaction_logs" using primary key columns */
	voyce_transaction_logs_by_pk?:ModelTypes["voyce_transaction_logs"],
	/** fetch data from the table in a streaming manner: "voyce.transaction_logs" */
	voyce_transaction_logs_stream:ModelTypes["voyce_transaction_logs"][],
	/** fetch data from the table: "voyce.url_redirects" */
	voyce_url_redirects:ModelTypes["voyce_url_redirects"][],
	/** fetch aggregated fields from the table: "voyce.url_redirects" */
	voyce_url_redirects_aggregate:ModelTypes["voyce_url_redirects_aggregate"],
	/** fetch data from the table: "voyce.url_redirects" using primary key columns */
	voyce_url_redirects_by_pk?:ModelTypes["voyce_url_redirects"],
	/** fetch data from the table in a streaming manner: "voyce.url_redirects" */
	voyce_url_redirects_stream:ModelTypes["voyce_url_redirects"][],
	/** fetch data from the table: "voyce.user_addresses" */
	voyce_user_addresses:ModelTypes["voyce_user_addresses"][],
	/** fetch aggregated fields from the table: "voyce.user_addresses" */
	voyce_user_addresses_aggregate:ModelTypes["voyce_user_addresses_aggregate"],
	/** fetch data from the table: "voyce.user_addresses" using primary key columns */
	voyce_user_addresses_by_pk?:ModelTypes["voyce_user_addresses"],
	/** fetch data from the table in a streaming manner: "voyce.user_addresses" */
	voyce_user_addresses_stream:ModelTypes["voyce_user_addresses"][],
	/** fetch data from the table: "voyce.user_blocks" */
	voyce_user_blocks:ModelTypes["voyce_user_blocks"][],
	/** fetch aggregated fields from the table: "voyce.user_blocks" */
	voyce_user_blocks_aggregate:ModelTypes["voyce_user_blocks_aggregate"],
	/** fetch data from the table: "voyce.user_blocks" using primary key columns */
	voyce_user_blocks_by_pk?:ModelTypes["voyce_user_blocks"],
	/** fetch data from the table in a streaming manner: "voyce.user_blocks" */
	voyce_user_blocks_stream:ModelTypes["voyce_user_blocks"][],
	/** fetch data from the table: "voyce.user_chapters_unlocked" */
	voyce_user_chapters_unlocked:ModelTypes["voyce_user_chapters_unlocked"][],
	/** fetch aggregated fields from the table: "voyce.user_chapters_unlocked" */
	voyce_user_chapters_unlocked_aggregate:ModelTypes["voyce_user_chapters_unlocked_aggregate"],
	/** fetch data from the table: "voyce.user_chapters_unlocked" using primary key columns */
	voyce_user_chapters_unlocked_by_pk?:ModelTypes["voyce_user_chapters_unlocked"],
	/** fetch data from the table in a streaming manner: "voyce.user_chapters_unlocked" */
	voyce_user_chapters_unlocked_stream:ModelTypes["voyce_user_chapters_unlocked"][],
	/** fetch data from the table: "voyce.user_contacts" */
	voyce_user_contacts:ModelTypes["voyce_user_contacts"][],
	/** fetch aggregated fields from the table: "voyce.user_contacts" */
	voyce_user_contacts_aggregate:ModelTypes["voyce_user_contacts_aggregate"],
	/** fetch data from the table: "voyce.user_contacts" using primary key columns */
	voyce_user_contacts_by_pk?:ModelTypes["voyce_user_contacts"],
	/** fetch data from the table in a streaming manner: "voyce.user_contacts" */
	voyce_user_contacts_stream:ModelTypes["voyce_user_contacts"][],
	/** fetch data from the table: "voyce.user_csat_ratings" */
	voyce_user_csat_ratings:ModelTypes["voyce_user_csat_ratings"][],
	/** fetch aggregated fields from the table: "voyce.user_csat_ratings" */
	voyce_user_csat_ratings_aggregate:ModelTypes["voyce_user_csat_ratings_aggregate"],
	/** fetch data from the table: "voyce.user_csat_ratings" using primary key columns */
	voyce_user_csat_ratings_by_pk?:ModelTypes["voyce_user_csat_ratings"],
	/** fetch data from the table in a streaming manner: "voyce.user_csat_ratings" */
	voyce_user_csat_ratings_stream:ModelTypes["voyce_user_csat_ratings"][],
	/** fetch data from the table: "voyce.user_education" */
	voyce_user_education:ModelTypes["voyce_user_education"][],
	/** fetch aggregated fields from the table: "voyce.user_education" */
	voyce_user_education_aggregate:ModelTypes["voyce_user_education_aggregate"],
	/** fetch data from the table: "voyce.user_education" using primary key columns */
	voyce_user_education_by_pk?:ModelTypes["voyce_user_education"],
	/** fetch data from the table in a streaming manner: "voyce.user_education" */
	voyce_user_education_stream:ModelTypes["voyce_user_education"][],
	/** fetch data from the table: "voyce.user_level" */
	voyce_user_level:ModelTypes["voyce_user_level"][],
	/** fetch aggregated fields from the table: "voyce.user_level" */
	voyce_user_level_aggregate:ModelTypes["voyce_user_level_aggregate"],
	/** fetch data from the table: "voyce.user_level" using primary key columns */
	voyce_user_level_by_pk?:ModelTypes["voyce_user_level"],
	/** fetch data from the table in a streaming manner: "voyce.user_level" */
	voyce_user_level_stream:ModelTypes["voyce_user_level"][],
	/** fetch data from the table: "voyce.user_linked_accounts" */
	voyce_user_linked_accounts:ModelTypes["voyce_user_linked_accounts"][],
	/** fetch aggregated fields from the table: "voyce.user_linked_accounts" */
	voyce_user_linked_accounts_aggregate:ModelTypes["voyce_user_linked_accounts_aggregate"],
	/** fetch data from the table: "voyce.user_linked_accounts" using primary key columns */
	voyce_user_linked_accounts_by_pk?:ModelTypes["voyce_user_linked_accounts"],
	/** fetch data from the table in a streaming manner: "voyce.user_linked_accounts" */
	voyce_user_linked_accounts_stream:ModelTypes["voyce_user_linked_accounts"][],
	/** fetch data from the table: "voyce.user_login_history" */
	voyce_user_login_history:ModelTypes["voyce_user_login_history"][],
	/** fetch aggregated fields from the table: "voyce.user_login_history" */
	voyce_user_login_history_aggregate:ModelTypes["voyce_user_login_history_aggregate"],
	/** fetch data from the table: "voyce.user_login_history" using primary key columns */
	voyce_user_login_history_by_pk?:ModelTypes["voyce_user_login_history"],
	/** fetch data from the table in a streaming manner: "voyce.user_login_history" */
	voyce_user_login_history_stream:ModelTypes["voyce_user_login_history"][],
	/** fetch data from the table: "voyce.user_quest_ids" */
	voyce_user_quest_ids:ModelTypes["voyce_user_quest_ids"][],
	/** fetch aggregated fields from the table: "voyce.user_quest_ids" */
	voyce_user_quest_ids_aggregate:ModelTypes["voyce_user_quest_ids_aggregate"],
	/** fetch data from the table: "voyce.user_quest_ids" using primary key columns */
	voyce_user_quest_ids_by_pk?:ModelTypes["voyce_user_quest_ids"],
	/** fetch data from the table in a streaming manner: "voyce.user_quest_ids" */
	voyce_user_quest_ids_stream:ModelTypes["voyce_user_quest_ids"][],
	/** fetch data from the table: "voyce.user_quest_rewards" */
	voyce_user_quest_rewards:ModelTypes["voyce_user_quest_rewards"][],
	/** fetch aggregated fields from the table: "voyce.user_quest_rewards" */
	voyce_user_quest_rewards_aggregate:ModelTypes["voyce_user_quest_rewards_aggregate"],
	/** fetch data from the table: "voyce.user_quest_rewards" using primary key columns */
	voyce_user_quest_rewards_by_pk?:ModelTypes["voyce_user_quest_rewards"],
	/** fetch data from the table in a streaming manner: "voyce.user_quest_rewards" */
	voyce_user_quest_rewards_stream:ModelTypes["voyce_user_quest_rewards"][],
	/** fetch data from the table: "voyce.user_quests" */
	voyce_user_quests:ModelTypes["voyce_user_quests"][],
	/** fetch aggregated fields from the table: "voyce.user_quests" */
	voyce_user_quests_aggregate:ModelTypes["voyce_user_quests_aggregate"],
	/** fetch data from the table: "voyce.user_quests" using primary key columns */
	voyce_user_quests_by_pk?:ModelTypes["voyce_user_quests"],
	/** fetch data from the table: "voyce.user_quests_old" */
	voyce_user_quests_old:ModelTypes["voyce_user_quests_old"][],
	/** fetch aggregated fields from the table: "voyce.user_quests_old" */
	voyce_user_quests_old_aggregate:ModelTypes["voyce_user_quests_old_aggregate"],
	/** fetch data from the table: "voyce.user_quests_old" using primary key columns */
	voyce_user_quests_old_by_pk?:ModelTypes["voyce_user_quests_old"],
	/** fetch data from the table in a streaming manner: "voyce.user_quests_old" */
	voyce_user_quests_old_stream:ModelTypes["voyce_user_quests_old"][],
	/** fetch data from the table in a streaming manner: "voyce.user_quests" */
	voyce_user_quests_stream:ModelTypes["voyce_user_quests"][],
	/** fetch data from the table: "voyce.user_ranking" */
	voyce_user_ranking:ModelTypes["voyce_user_ranking"][],
	/** fetch aggregated fields from the table: "voyce.user_ranking" */
	voyce_user_ranking_aggregate:ModelTypes["voyce_user_ranking_aggregate"],
	/** fetch data from the table: "voyce.user_ranking" using primary key columns */
	voyce_user_ranking_by_pk?:ModelTypes["voyce_user_ranking"],
	/** fetch data from the table in a streaming manner: "voyce.user_ranking" */
	voyce_user_ranking_stream:ModelTypes["voyce_user_ranking"][],
	/** fetch data from the table: "voyce.user_reviews" */
	voyce_user_reviews:ModelTypes["voyce_user_reviews"][],
	/** fetch aggregated fields from the table: "voyce.user_reviews" */
	voyce_user_reviews_aggregate:ModelTypes["voyce_user_reviews_aggregate"],
	/** fetch data from the table: "voyce.user_reviews" using primary key columns */
	voyce_user_reviews_by_pk?:ModelTypes["voyce_user_reviews"],
	/** fetch data from the table in a streaming manner: "voyce.user_reviews" */
	voyce_user_reviews_stream:ModelTypes["voyce_user_reviews"][],
	/** fetch data from the table: "voyce.user_rewards" */
	voyce_user_rewards:ModelTypes["voyce_user_rewards"][],
	/** fetch aggregated fields from the table: "voyce.user_rewards" */
	voyce_user_rewards_aggregate:ModelTypes["voyce_user_rewards_aggregate"],
	/** fetch data from the table: "voyce.user_rewards" using primary key columns */
	voyce_user_rewards_by_pk?:ModelTypes["voyce_user_rewards"],
	/** fetch data from the table in a streaming manner: "voyce.user_rewards" */
	voyce_user_rewards_stream:ModelTypes["voyce_user_rewards"][],
	/** fetch data from the table: "voyce.user_roles" */
	voyce_user_roles:ModelTypes["voyce_user_roles"][],
	/** fetch aggregated fields from the table: "voyce.user_roles" */
	voyce_user_roles_aggregate:ModelTypes["voyce_user_roles_aggregate"],
	/** fetch data from the table: "voyce.user_roles" using primary key columns */
	voyce_user_roles_by_pk?:ModelTypes["voyce_user_roles"],
	/** fetch data from the table in a streaming manner: "voyce.user_roles" */
	voyce_user_roles_stream:ModelTypes["voyce_user_roles"][],
	/** fetch data from the table: "voyce.user_series_subscription_count" */
	voyce_user_series_subscription_count:ModelTypes["voyce_user_series_subscription_count"][],
	/** fetch aggregated fields from the table: "voyce.user_series_subscription_count" */
	voyce_user_series_subscription_count_aggregate:ModelTypes["voyce_user_series_subscription_count_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.user_series_subscription_count" */
	voyce_user_series_subscription_count_stream:ModelTypes["voyce_user_series_subscription_count"][],
	/** fetch data from the table: "voyce.user_settings" */
	voyce_user_settings:ModelTypes["voyce_user_settings"][],
	/** fetch aggregated fields from the table: "voyce.user_settings" */
	voyce_user_settings_aggregate:ModelTypes["voyce_user_settings_aggregate"],
	/** fetch data from the table: "voyce.user_settings" using primary key columns */
	voyce_user_settings_by_pk?:ModelTypes["voyce_user_settings"],
	/** fetch data from the table in a streaming manner: "voyce.user_settings" */
	voyce_user_settings_stream:ModelTypes["voyce_user_settings"][],
	/** fetch data from the table: "voyce.user_skills" */
	voyce_user_skills:ModelTypes["voyce_user_skills"][],
	/** fetch aggregated fields from the table: "voyce.user_skills" */
	voyce_user_skills_aggregate:ModelTypes["voyce_user_skills_aggregate"],
	/** fetch data from the table: "voyce.user_skills" using primary key columns */
	voyce_user_skills_by_pk?:ModelTypes["voyce_user_skills"],
	/** fetch data from the table in a streaming manner: "voyce.user_skills" */
	voyce_user_skills_stream:ModelTypes["voyce_user_skills"][],
	/** fetch data from the table: "voyce.user_socials" */
	voyce_user_socials:ModelTypes["voyce_user_socials"][],
	/** fetch aggregated fields from the table: "voyce.user_socials" */
	voyce_user_socials_aggregate:ModelTypes["voyce_user_socials_aggregate"],
	/** fetch data from the table: "voyce.user_socials" using primary key columns */
	voyce_user_socials_by_pk?:ModelTypes["voyce_user_socials"],
	/** fetch data from the table in a streaming manner: "voyce.user_socials" */
	voyce_user_socials_stream:ModelTypes["voyce_user_socials"][],
	/** fetch data from the table: "voyce.user_soft_deleted" */
	voyce_user_soft_deleted:ModelTypes["voyce_user_soft_deleted"][],
	/** fetch aggregated fields from the table: "voyce.user_soft_deleted" */
	voyce_user_soft_deleted_aggregate:ModelTypes["voyce_user_soft_deleted_aggregate"],
	/** fetch data from the table: "voyce.user_soft_deleted" using primary key columns */
	voyce_user_soft_deleted_by_pk?:ModelTypes["voyce_user_soft_deleted"],
	/** fetch data from the table in a streaming manner: "voyce.user_soft_deleted" */
	voyce_user_soft_deleted_stream:ModelTypes["voyce_user_soft_deleted"][],
	/** fetch data from the table: "voyce.user_vtags" */
	voyce_user_vtags:ModelTypes["voyce_user_vtags"][],
	/** fetch aggregated fields from the table: "voyce.user_vtags" */
	voyce_user_vtags_aggregate:ModelTypes["voyce_user_vtags_aggregate"],
	/** fetch data from the table: "voyce.user_vtags" using primary key columns */
	voyce_user_vtags_by_pk?:ModelTypes["voyce_user_vtags"],
	/** fetch data from the table in a streaming manner: "voyce.user_vtags" */
	voyce_user_vtags_stream:ModelTypes["voyce_user_vtags"][],
	/** fetch data from the table: "voyce.user_wins" */
	voyce_user_wins:ModelTypes["voyce_user_wins"][],
	/** fetch aggregated fields from the table: "voyce.user_wins" */
	voyce_user_wins_aggregate:ModelTypes["voyce_user_wins_aggregate"],
	/** fetch data from the table: "voyce.user_wins" using primary key columns */
	voyce_user_wins_by_pk?:ModelTypes["voyce_user_wins"],
	/** fetch data from the table in a streaming manner: "voyce.user_wins" */
	voyce_user_wins_stream:ModelTypes["voyce_user_wins"][],
	/** fetch data from the table: "voyce.users" */
	voyce_users:ModelTypes["voyce_users"][],
	/** fetch aggregated fields from the table: "voyce.users" */
	voyce_users_aggregate:ModelTypes["voyce_users_aggregate"],
	/** fetch data from the table: "voyce.users" using primary key columns */
	voyce_users_by_pk?:ModelTypes["voyce_users"],
	/** fetch data from the table: "voyce.users_followers" */
	voyce_users_followers:ModelTypes["voyce_users_followers"][],
	/** fetch aggregated fields from the table: "voyce.users_followers" */
	voyce_users_followers_aggregate:ModelTypes["voyce_users_followers_aggregate"],
	/** fetch data from the table: "voyce.users_followers" using primary key columns */
	voyce_users_followers_by_pk?:ModelTypes["voyce_users_followers"],
	/** fetch data from the table in a streaming manner: "voyce.users_followers" */
	voyce_users_followers_stream:ModelTypes["voyce_users_followers"][],
	/** fetch data from the table: "voyce.users_private" */
	voyce_users_private:ModelTypes["voyce_users_private"][],
	/** fetch aggregated fields from the table: "voyce.users_private" */
	voyce_users_private_aggregate:ModelTypes["voyce_users_private_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.users_private" */
	voyce_users_private_stream:ModelTypes["voyce_users_private"][],
	/** fetch data from the table: "voyce.users_series_junction" */
	voyce_users_series_junction:ModelTypes["voyce_users_series_junction"][],
	/** fetch aggregated fields from the table: "voyce.users_series_junction" */
	voyce_users_series_junction_aggregate:ModelTypes["voyce_users_series_junction_aggregate"],
	/** fetch data from the table: "voyce.users_series_junction" using primary key columns */
	voyce_users_series_junction_by_pk?:ModelTypes["voyce_users_series_junction"],
	/** fetch data from the table in a streaming manner: "voyce.users_series_junction" */
	voyce_users_series_junction_stream:ModelTypes["voyce_users_series_junction"][],
	/** fetch data from the table in a streaming manner: "voyce.users" */
	voyce_users_stream:ModelTypes["voyce_users"][],
	/** fetch data from the table: "voyce.versions" */
	voyce_versions:ModelTypes["voyce_versions"][],
	/** fetch aggregated fields from the table: "voyce.versions" */
	voyce_versions_aggregate:ModelTypes["voyce_versions_aggregate"],
	/** fetch data from the table: "voyce.versions" using primary key columns */
	voyce_versions_by_pk?:ModelTypes["voyce_versions"],
	/** fetch data from the table in a streaming manner: "voyce.versions" */
	voyce_versions_stream:ModelTypes["voyce_versions"][],
	/** fetch data from the table: "voyce.voting_analytics" */
	voyce_voting_analytics:ModelTypes["voyce_voting_analytics"][],
	/** fetch aggregated fields from the table: "voyce.voting_analytics" */
	voyce_voting_analytics_aggregate:ModelTypes["voyce_voting_analytics_aggregate"],
	/** fetch data from the table: "voyce.voting_analytics" using primary key columns */
	voyce_voting_analytics_by_pk?:ModelTypes["voyce_voting_analytics"],
	/** fetch data from the table in a streaming manner: "voyce.voting_analytics" */
	voyce_voting_analytics_stream:ModelTypes["voyce_voting_analytics"][],
	/** fetch data from the table: "voyce.voting_panels" */
	voyce_voting_panels:ModelTypes["voyce_voting_panels"][],
	/** fetch aggregated fields from the table: "voyce.voting_panels" */
	voyce_voting_panels_aggregate:ModelTypes["voyce_voting_panels_aggregate"],
	/** fetch data from the table: "voyce.voting_panels" using primary key columns */
	voyce_voting_panels_by_pk?:ModelTypes["voyce_voting_panels"],
	/** fetch data from the table in a streaming manner: "voyce.voting_panels" */
	voyce_voting_panels_stream:ModelTypes["voyce_voting_panels"][],
	/** fetch data from the table: "voyce.voting_series" */
	voyce_voting_series:ModelTypes["voyce_voting_series"][],
	/** fetch aggregated fields from the table: "voyce.voting_series" */
	voyce_voting_series_aggregate:ModelTypes["voyce_voting_series_aggregate"],
	/** fetch data from the table: "voyce.voting_series" using primary key columns */
	voyce_voting_series_by_pk?:ModelTypes["voyce_voting_series"],
	/** fetch data from the table in a streaming manner: "voyce.voting_series" */
	voyce_voting_series_stream:ModelTypes["voyce_voting_series"][],
	/** fetch data from the table: "voyce.voting_user_votes" */
	voyce_voting_user_votes:ModelTypes["voyce_voting_user_votes"][],
	/** fetch aggregated fields from the table: "voyce.voting_user_votes" */
	voyce_voting_user_votes_aggregate:ModelTypes["voyce_voting_user_votes_aggregate"],
	/** fetch data from the table: "voyce.voting_user_votes" using primary key columns */
	voyce_voting_user_votes_by_pk?:ModelTypes["voyce_voting_user_votes"],
	/** fetch data from the table in a streaming manner: "voyce.voting_user_votes" */
	voyce_voting_user_votes_stream:ModelTypes["voyce_voting_user_votes"][]
};
	["time"]:any;
	/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
["time_comparison_exp"]: GraphQLTypes["time_comparison_exp"];
	["timestamp"]:any;
	/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
["timestamp_comparison_exp"]: GraphQLTypes["timestamp_comparison_exp"];
	["timestamptz"]:any;
	/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
["timestamptz_comparison_exp"]: GraphQLTypes["timestamptz_comparison_exp"];
	["uuid"]:any;
	/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
["uuid_comparison_exp"]: GraphQLTypes["uuid_comparison_exp"];
	/** columns and relationships of "voyce.admin_roles" */
["voyce_admin_roles"]: {
		created_at:ModelTypes["timestamptz"],
	id:number,
	role:string,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.admin_roles" */
["voyce_admin_roles_aggregate"]: {
		aggregate?:ModelTypes["voyce_admin_roles_aggregate_fields"],
	nodes:ModelTypes["voyce_admin_roles"][]
};
	/** aggregate fields of "voyce.admin_roles" */
["voyce_admin_roles_aggregate_fields"]: {
		avg?:ModelTypes["voyce_admin_roles_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_admin_roles_max_fields"],
	min?:ModelTypes["voyce_admin_roles_min_fields"],
	stddev?:ModelTypes["voyce_admin_roles_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_admin_roles_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_admin_roles_stddev_samp_fields"],
	sum?:ModelTypes["voyce_admin_roles_sum_fields"],
	var_pop?:ModelTypes["voyce_admin_roles_var_pop_fields"],
	var_samp?:ModelTypes["voyce_admin_roles_var_samp_fields"],
	variance?:ModelTypes["voyce_admin_roles_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_admin_roles_avg_fields"]: {
		id?:number
};
	/** Boolean expression to filter rows from the table "voyce.admin_roles". All fields are combined with a logical 'AND'. */
["voyce_admin_roles_bool_exp"]: GraphQLTypes["voyce_admin_roles_bool_exp"];
	/** unique or primary key constraints on table "voyce.admin_roles" */
["voyce_admin_roles_constraint"]: GraphQLTypes["voyce_admin_roles_constraint"];
	/** input type for incrementing numeric columns in table "voyce.admin_roles" */
["voyce_admin_roles_inc_input"]: GraphQLTypes["voyce_admin_roles_inc_input"];
	/** input type for inserting data into table "voyce.admin_roles" */
["voyce_admin_roles_insert_input"]: GraphQLTypes["voyce_admin_roles_insert_input"];
	/** aggregate max on columns */
["voyce_admin_roles_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	role?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_admin_roles_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	role?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.admin_roles" */
["voyce_admin_roles_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_admin_roles"][]
};
	/** input type for inserting object relation for remote table "voyce.admin_roles" */
["voyce_admin_roles_obj_rel_insert_input"]: GraphQLTypes["voyce_admin_roles_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.admin_roles" */
["voyce_admin_roles_on_conflict"]: GraphQLTypes["voyce_admin_roles_on_conflict"];
	/** Ordering options when selecting data from "voyce.admin_roles". */
["voyce_admin_roles_order_by"]: GraphQLTypes["voyce_admin_roles_order_by"];
	/** primary key columns input for table: voyce.admin_roles */
["voyce_admin_roles_pk_columns_input"]: GraphQLTypes["voyce_admin_roles_pk_columns_input"];
	/** select columns of table "voyce.admin_roles" */
["voyce_admin_roles_select_column"]: GraphQLTypes["voyce_admin_roles_select_column"];
	/** input type for updating data in table "voyce.admin_roles" */
["voyce_admin_roles_set_input"]: GraphQLTypes["voyce_admin_roles_set_input"];
	/** aggregate stddev on columns */
["voyce_admin_roles_stddev_fields"]: {
		id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_admin_roles_stddev_pop_fields"]: {
		id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_admin_roles_stddev_samp_fields"]: {
		id?:number
};
	/** Streaming cursor of the table "voyce_admin_roles" */
["voyce_admin_roles_stream_cursor_input"]: GraphQLTypes["voyce_admin_roles_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_admin_roles_stream_cursor_value_input"]: GraphQLTypes["voyce_admin_roles_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_admin_roles_sum_fields"]: {
		id?:number
};
	/** update columns of table "voyce.admin_roles" */
["voyce_admin_roles_update_column"]: GraphQLTypes["voyce_admin_roles_update_column"];
	["voyce_admin_roles_updates"]: GraphQLTypes["voyce_admin_roles_updates"];
	/** aggregate var_pop on columns */
["voyce_admin_roles_var_pop_fields"]: {
		id?:number
};
	/** aggregate var_samp on columns */
["voyce_admin_roles_var_samp_fields"]: {
		id?:number
};
	/** aggregate variance on columns */
["voyce_admin_roles_variance_fields"]: {
		id?:number
};
	/** columns and relationships of "voyce.app_banners" */
["voyce_app_banners"]: {
		active:boolean,
	background?:string,
	created_at:ModelTypes["timestamptz"],
	id:number,
	link?:string,
	mobile?:string,
	overlap:boolean,
	screen:string,
	sort:ModelTypes["smallint"],
	tablet?:string,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.app_banners" */
["voyce_app_banners_aggregate"]: {
		aggregate?:ModelTypes["voyce_app_banners_aggregate_fields"],
	nodes:ModelTypes["voyce_app_banners"][]
};
	/** aggregate fields of "voyce.app_banners" */
["voyce_app_banners_aggregate_fields"]: {
		avg?:ModelTypes["voyce_app_banners_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_app_banners_max_fields"],
	min?:ModelTypes["voyce_app_banners_min_fields"],
	stddev?:ModelTypes["voyce_app_banners_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_app_banners_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_app_banners_stddev_samp_fields"],
	sum?:ModelTypes["voyce_app_banners_sum_fields"],
	var_pop?:ModelTypes["voyce_app_banners_var_pop_fields"],
	var_samp?:ModelTypes["voyce_app_banners_var_samp_fields"],
	variance?:ModelTypes["voyce_app_banners_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_app_banners_avg_fields"]: {
		id?:number,
	sort?:number
};
	/** Boolean expression to filter rows from the table "voyce.app_banners". All fields are combined with a logical 'AND'. */
["voyce_app_banners_bool_exp"]: GraphQLTypes["voyce_app_banners_bool_exp"];
	/** unique or primary key constraints on table "voyce.app_banners" */
["voyce_app_banners_constraint"]: GraphQLTypes["voyce_app_banners_constraint"];
	/** input type for incrementing numeric columns in table "voyce.app_banners" */
["voyce_app_banners_inc_input"]: GraphQLTypes["voyce_app_banners_inc_input"];
	/** input type for inserting data into table "voyce.app_banners" */
["voyce_app_banners_insert_input"]: GraphQLTypes["voyce_app_banners_insert_input"];
	/** aggregate max on columns */
["voyce_app_banners_max_fields"]: {
		background?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	link?:string,
	mobile?:string,
	screen?:string,
	sort?:ModelTypes["smallint"],
	tablet?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_app_banners_min_fields"]: {
		background?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	link?:string,
	mobile?:string,
	screen?:string,
	sort?:ModelTypes["smallint"],
	tablet?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.app_banners" */
["voyce_app_banners_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_app_banners"][]
};
	/** on_conflict condition type for table "voyce.app_banners" */
["voyce_app_banners_on_conflict"]: GraphQLTypes["voyce_app_banners_on_conflict"];
	/** Ordering options when selecting data from "voyce.app_banners". */
["voyce_app_banners_order_by"]: GraphQLTypes["voyce_app_banners_order_by"];
	/** primary key columns input for table: voyce.app_banners */
["voyce_app_banners_pk_columns_input"]: GraphQLTypes["voyce_app_banners_pk_columns_input"];
	/** select columns of table "voyce.app_banners" */
["voyce_app_banners_select_column"]: GraphQLTypes["voyce_app_banners_select_column"];
	/** input type for updating data in table "voyce.app_banners" */
["voyce_app_banners_set_input"]: GraphQLTypes["voyce_app_banners_set_input"];
	/** aggregate stddev on columns */
["voyce_app_banners_stddev_fields"]: {
		id?:number,
	sort?:number
};
	/** aggregate stddev_pop on columns */
["voyce_app_banners_stddev_pop_fields"]: {
		id?:number,
	sort?:number
};
	/** aggregate stddev_samp on columns */
["voyce_app_banners_stddev_samp_fields"]: {
		id?:number,
	sort?:number
};
	/** Streaming cursor of the table "voyce_app_banners" */
["voyce_app_banners_stream_cursor_input"]: GraphQLTypes["voyce_app_banners_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_app_banners_stream_cursor_value_input"]: GraphQLTypes["voyce_app_banners_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_app_banners_sum_fields"]: {
		id?:number,
	sort?:ModelTypes["smallint"]
};
	/** update columns of table "voyce.app_banners" */
["voyce_app_banners_update_column"]: GraphQLTypes["voyce_app_banners_update_column"];
	["voyce_app_banners_updates"]: GraphQLTypes["voyce_app_banners_updates"];
	/** aggregate var_pop on columns */
["voyce_app_banners_var_pop_fields"]: {
		id?:number,
	sort?:number
};
	/** aggregate var_samp on columns */
["voyce_app_banners_var_samp_fields"]: {
		id?:number,
	sort?:number
};
	/** aggregate variance on columns */
["voyce_app_banners_variance_fields"]: {
		id?:number,
	sort?:number
};
	/** columns and relationships of "voyce.app_components" */
["voyce_app_components"]: {
		active:boolean,
	auth?:boolean,
	component:string,
	created_at:ModelTypes["timestamptz"],
	device:string,
	id:number,
	props?:ModelTypes["jsonb"],
	screen:string,
	sort:ModelTypes["smallint"],
	title?:string,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.app_components" */
["voyce_app_components_aggregate"]: {
		aggregate?:ModelTypes["voyce_app_components_aggregate_fields"],
	nodes:ModelTypes["voyce_app_components"][]
};
	/** aggregate fields of "voyce.app_components" */
["voyce_app_components_aggregate_fields"]: {
		avg?:ModelTypes["voyce_app_components_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_app_components_max_fields"],
	min?:ModelTypes["voyce_app_components_min_fields"],
	stddev?:ModelTypes["voyce_app_components_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_app_components_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_app_components_stddev_samp_fields"],
	sum?:ModelTypes["voyce_app_components_sum_fields"],
	var_pop?:ModelTypes["voyce_app_components_var_pop_fields"],
	var_samp?:ModelTypes["voyce_app_components_var_samp_fields"],
	variance?:ModelTypes["voyce_app_components_variance_fields"]
};
	/** append existing jsonb value of filtered columns with new jsonb value */
["voyce_app_components_append_input"]: GraphQLTypes["voyce_app_components_append_input"];
	/** aggregate avg on columns */
["voyce_app_components_avg_fields"]: {
		id?:number,
	sort?:number
};
	/** Boolean expression to filter rows from the table "voyce.app_components". All fields are combined with a logical 'AND'. */
["voyce_app_components_bool_exp"]: GraphQLTypes["voyce_app_components_bool_exp"];
	/** unique or primary key constraints on table "voyce.app_components" */
["voyce_app_components_constraint"]: GraphQLTypes["voyce_app_components_constraint"];
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["voyce_app_components_delete_at_path_input"]: GraphQLTypes["voyce_app_components_delete_at_path_input"];
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["voyce_app_components_delete_elem_input"]: GraphQLTypes["voyce_app_components_delete_elem_input"];
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["voyce_app_components_delete_key_input"]: GraphQLTypes["voyce_app_components_delete_key_input"];
	/** input type for incrementing numeric columns in table "voyce.app_components" */
["voyce_app_components_inc_input"]: GraphQLTypes["voyce_app_components_inc_input"];
	/** input type for inserting data into table "voyce.app_components" */
["voyce_app_components_insert_input"]: GraphQLTypes["voyce_app_components_insert_input"];
	/** aggregate max on columns */
["voyce_app_components_max_fields"]: {
		component?:string,
	created_at?:ModelTypes["timestamptz"],
	device?:string,
	id?:number,
	screen?:string,
	sort?:ModelTypes["smallint"],
	title?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_app_components_min_fields"]: {
		component?:string,
	created_at?:ModelTypes["timestamptz"],
	device?:string,
	id?:number,
	screen?:string,
	sort?:ModelTypes["smallint"],
	title?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.app_components" */
["voyce_app_components_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_app_components"][]
};
	/** on_conflict condition type for table "voyce.app_components" */
["voyce_app_components_on_conflict"]: GraphQLTypes["voyce_app_components_on_conflict"];
	/** Ordering options when selecting data from "voyce.app_components". */
["voyce_app_components_order_by"]: GraphQLTypes["voyce_app_components_order_by"];
	/** primary key columns input for table: voyce.app_components */
["voyce_app_components_pk_columns_input"]: GraphQLTypes["voyce_app_components_pk_columns_input"];
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["voyce_app_components_prepend_input"]: GraphQLTypes["voyce_app_components_prepend_input"];
	/** select columns of table "voyce.app_components" */
["voyce_app_components_select_column"]: GraphQLTypes["voyce_app_components_select_column"];
	/** input type for updating data in table "voyce.app_components" */
["voyce_app_components_set_input"]: GraphQLTypes["voyce_app_components_set_input"];
	/** aggregate stddev on columns */
["voyce_app_components_stddev_fields"]: {
		id?:number,
	sort?:number
};
	/** aggregate stddev_pop on columns */
["voyce_app_components_stddev_pop_fields"]: {
		id?:number,
	sort?:number
};
	/** aggregate stddev_samp on columns */
["voyce_app_components_stddev_samp_fields"]: {
		id?:number,
	sort?:number
};
	/** Streaming cursor of the table "voyce_app_components" */
["voyce_app_components_stream_cursor_input"]: GraphQLTypes["voyce_app_components_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_app_components_stream_cursor_value_input"]: GraphQLTypes["voyce_app_components_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_app_components_sum_fields"]: {
		id?:number,
	sort?:ModelTypes["smallint"]
};
	/** update columns of table "voyce.app_components" */
["voyce_app_components_update_column"]: GraphQLTypes["voyce_app_components_update_column"];
	["voyce_app_components_updates"]: GraphQLTypes["voyce_app_components_updates"];
	/** aggregate var_pop on columns */
["voyce_app_components_var_pop_fields"]: {
		id?:number,
	sort?:number
};
	/** aggregate var_samp on columns */
["voyce_app_components_var_samp_fields"]: {
		id?:number,
	sort?:number
};
	/** aggregate variance on columns */
["voyce_app_components_variance_fields"]: {
		id?:number,
	sort?:number
};
	/** columns and relationships of "voyce.audit_trail" */
["voyce_audit_trail"]: {
		action:string,
	created_at:ModelTypes["timestamptz"],
	id:number,
	module:string,
	payload?:ModelTypes["json"],
	/** An object relationship */
	user?:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.audit_trail" */
["voyce_audit_trail_aggregate"]: {
		aggregate?:ModelTypes["voyce_audit_trail_aggregate_fields"],
	nodes:ModelTypes["voyce_audit_trail"][]
};
	/** aggregate fields of "voyce.audit_trail" */
["voyce_audit_trail_aggregate_fields"]: {
		avg?:ModelTypes["voyce_audit_trail_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_audit_trail_max_fields"],
	min?:ModelTypes["voyce_audit_trail_min_fields"],
	stddev?:ModelTypes["voyce_audit_trail_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_audit_trail_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_audit_trail_stddev_samp_fields"],
	sum?:ModelTypes["voyce_audit_trail_sum_fields"],
	var_pop?:ModelTypes["voyce_audit_trail_var_pop_fields"],
	var_samp?:ModelTypes["voyce_audit_trail_var_samp_fields"],
	variance?:ModelTypes["voyce_audit_trail_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_audit_trail_avg_fields"]: {
		id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.audit_trail". All fields are combined with a logical 'AND'. */
["voyce_audit_trail_bool_exp"]: GraphQLTypes["voyce_audit_trail_bool_exp"];
	/** unique or primary key constraints on table "voyce.audit_trail" */
["voyce_audit_trail_constraint"]: GraphQLTypes["voyce_audit_trail_constraint"];
	/** input type for incrementing numeric columns in table "voyce.audit_trail" */
["voyce_audit_trail_inc_input"]: GraphQLTypes["voyce_audit_trail_inc_input"];
	/** input type for inserting data into table "voyce.audit_trail" */
["voyce_audit_trail_insert_input"]: GraphQLTypes["voyce_audit_trail_insert_input"];
	/** aggregate max on columns */
["voyce_audit_trail_max_fields"]: {
		action?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	module?:string,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_audit_trail_min_fields"]: {
		action?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	module?:string,
	user_id?:number
};
	/** response of any mutation on the table "voyce.audit_trail" */
["voyce_audit_trail_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_audit_trail"][]
};
	/** on_conflict condition type for table "voyce.audit_trail" */
["voyce_audit_trail_on_conflict"]: GraphQLTypes["voyce_audit_trail_on_conflict"];
	/** Ordering options when selecting data from "voyce.audit_trail". */
["voyce_audit_trail_order_by"]: GraphQLTypes["voyce_audit_trail_order_by"];
	/** primary key columns input for table: voyce.audit_trail */
["voyce_audit_trail_pk_columns_input"]: GraphQLTypes["voyce_audit_trail_pk_columns_input"];
	/** select columns of table "voyce.audit_trail" */
["voyce_audit_trail_select_column"]: GraphQLTypes["voyce_audit_trail_select_column"];
	/** input type for updating data in table "voyce.audit_trail" */
["voyce_audit_trail_set_input"]: GraphQLTypes["voyce_audit_trail_set_input"];
	/** aggregate stddev on columns */
["voyce_audit_trail_stddev_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_audit_trail_stddev_pop_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_audit_trail_stddev_samp_fields"]: {
		id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_audit_trail" */
["voyce_audit_trail_stream_cursor_input"]: GraphQLTypes["voyce_audit_trail_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_audit_trail_stream_cursor_value_input"]: GraphQLTypes["voyce_audit_trail_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_audit_trail_sum_fields"]: {
		id?:number,
	user_id?:number
};
	/** update columns of table "voyce.audit_trail" */
["voyce_audit_trail_update_column"]: GraphQLTypes["voyce_audit_trail_update_column"];
	["voyce_audit_trail_updates"]: GraphQLTypes["voyce_audit_trail_updates"];
	/** aggregate var_pop on columns */
["voyce_audit_trail_var_pop_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_audit_trail_var_samp_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_audit_trail_variance_fields"]: {
		id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.author_profiles" */
["voyce_author_profiles"]: {
		created_at:ModelTypes["timestamp"],
	followers_count?:number,
	id:number,
	last_activity_date:ModelTypes["timestamptz"],
	likes_count:number,
	outdated:boolean,
	popularity:number,
	total_popularity:number,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number,
	views_count:number
};
	/** aggregated selection of "voyce.author_profiles" */
["voyce_author_profiles_aggregate"]: {
		aggregate?:ModelTypes["voyce_author_profiles_aggregate_fields"],
	nodes:ModelTypes["voyce_author_profiles"][]
};
	["voyce_author_profiles_aggregate_bool_exp"]: GraphQLTypes["voyce_author_profiles_aggregate_bool_exp"];
	["voyce_author_profiles_aggregate_bool_exp_bool_and"]: GraphQLTypes["voyce_author_profiles_aggregate_bool_exp_bool_and"];
	["voyce_author_profiles_aggregate_bool_exp_bool_or"]: GraphQLTypes["voyce_author_profiles_aggregate_bool_exp_bool_or"];
	["voyce_author_profiles_aggregate_bool_exp_count"]: GraphQLTypes["voyce_author_profiles_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.author_profiles" */
["voyce_author_profiles_aggregate_fields"]: {
		avg?:ModelTypes["voyce_author_profiles_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_author_profiles_max_fields"],
	min?:ModelTypes["voyce_author_profiles_min_fields"],
	stddev?:ModelTypes["voyce_author_profiles_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_author_profiles_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_author_profiles_stddev_samp_fields"],
	sum?:ModelTypes["voyce_author_profiles_sum_fields"],
	var_pop?:ModelTypes["voyce_author_profiles_var_pop_fields"],
	var_samp?:ModelTypes["voyce_author_profiles_var_samp_fields"],
	variance?:ModelTypes["voyce_author_profiles_variance_fields"]
};
	/** order by aggregate values of table "voyce.author_profiles" */
["voyce_author_profiles_aggregate_order_by"]: GraphQLTypes["voyce_author_profiles_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.author_profiles" */
["voyce_author_profiles_arr_rel_insert_input"]: GraphQLTypes["voyce_author_profiles_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_author_profiles_avg_fields"]: {
		followers_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	total_popularity?:number,
	user_id?:number,
	views_count?:number
};
	/** order by avg() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_avg_order_by"]: GraphQLTypes["voyce_author_profiles_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.author_profiles". All fields are combined with a logical 'AND'. */
["voyce_author_profiles_bool_exp"]: GraphQLTypes["voyce_author_profiles_bool_exp"];
	/** unique or primary key constraints on table "voyce.author_profiles" */
["voyce_author_profiles_constraint"]: GraphQLTypes["voyce_author_profiles_constraint"];
	/** input type for incrementing numeric columns in table "voyce.author_profiles" */
["voyce_author_profiles_inc_input"]: GraphQLTypes["voyce_author_profiles_inc_input"];
	/** input type for inserting data into table "voyce.author_profiles" */
["voyce_author_profiles_insert_input"]: GraphQLTypes["voyce_author_profiles_insert_input"];
	/** aggregate max on columns */
["voyce_author_profiles_max_fields"]: {
		created_at?:ModelTypes["timestamp"],
	followers_count?:number,
	id?:number,
	last_activity_date?:ModelTypes["timestamptz"],
	likes_count?:number,
	popularity?:number,
	total_popularity?:number,
	user_id?:number,
	views_count?:number
};
	/** order by max() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_max_order_by"]: GraphQLTypes["voyce_author_profiles_max_order_by"];
	/** aggregate min on columns */
["voyce_author_profiles_min_fields"]: {
		created_at?:ModelTypes["timestamp"],
	followers_count?:number,
	id?:number,
	last_activity_date?:ModelTypes["timestamptz"],
	likes_count?:number,
	popularity?:number,
	total_popularity?:number,
	user_id?:number,
	views_count?:number
};
	/** order by min() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_min_order_by"]: GraphQLTypes["voyce_author_profiles_min_order_by"];
	/** response of any mutation on the table "voyce.author_profiles" */
["voyce_author_profiles_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_author_profiles"][]
};
	/** input type for inserting object relation for remote table "voyce.author_profiles" */
["voyce_author_profiles_obj_rel_insert_input"]: GraphQLTypes["voyce_author_profiles_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.author_profiles" */
["voyce_author_profiles_on_conflict"]: GraphQLTypes["voyce_author_profiles_on_conflict"];
	/** Ordering options when selecting data from "voyce.author_profiles". */
["voyce_author_profiles_order_by"]: GraphQLTypes["voyce_author_profiles_order_by"];
	/** primary key columns input for table: voyce.author_profiles */
["voyce_author_profiles_pk_columns_input"]: GraphQLTypes["voyce_author_profiles_pk_columns_input"];
	/** select columns of table "voyce.author_profiles" */
["voyce_author_profiles_select_column"]: GraphQLTypes["voyce_author_profiles_select_column"];
	/** select "voyce_author_profiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.author_profiles" */
["voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_and_arguments_columns"]: GraphQLTypes["voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_and_arguments_columns"];
	/** select "voyce_author_profiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.author_profiles" */
["voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_or_arguments_columns"]: GraphQLTypes["voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_or_arguments_columns"];
	/** input type for updating data in table "voyce.author_profiles" */
["voyce_author_profiles_set_input"]: GraphQLTypes["voyce_author_profiles_set_input"];
	/** aggregate stddev on columns */
["voyce_author_profiles_stddev_fields"]: {
		followers_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	total_popularity?:number,
	user_id?:number,
	views_count?:number
};
	/** order by stddev() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_stddev_order_by"]: GraphQLTypes["voyce_author_profiles_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_author_profiles_stddev_pop_fields"]: {
		followers_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	total_popularity?:number,
	user_id?:number,
	views_count?:number
};
	/** order by stddev_pop() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_stddev_pop_order_by"]: GraphQLTypes["voyce_author_profiles_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_author_profiles_stddev_samp_fields"]: {
		followers_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	total_popularity?:number,
	user_id?:number,
	views_count?:number
};
	/** order by stddev_samp() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_stddev_samp_order_by"]: GraphQLTypes["voyce_author_profiles_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_author_profiles" */
["voyce_author_profiles_stream_cursor_input"]: GraphQLTypes["voyce_author_profiles_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_author_profiles_stream_cursor_value_input"]: GraphQLTypes["voyce_author_profiles_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_author_profiles_sum_fields"]: {
		followers_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	total_popularity?:number,
	user_id?:number,
	views_count?:number
};
	/** order by sum() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_sum_order_by"]: GraphQLTypes["voyce_author_profiles_sum_order_by"];
	/** update columns of table "voyce.author_profiles" */
["voyce_author_profiles_update_column"]: GraphQLTypes["voyce_author_profiles_update_column"];
	["voyce_author_profiles_updates"]: GraphQLTypes["voyce_author_profiles_updates"];
	/** aggregate var_pop on columns */
["voyce_author_profiles_var_pop_fields"]: {
		followers_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	total_popularity?:number,
	user_id?:number,
	views_count?:number
};
	/** order by var_pop() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_var_pop_order_by"]: GraphQLTypes["voyce_author_profiles_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_author_profiles_var_samp_fields"]: {
		followers_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	total_popularity?:number,
	user_id?:number,
	views_count?:number
};
	/** order by var_samp() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_var_samp_order_by"]: GraphQLTypes["voyce_author_profiles_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_author_profiles_variance_fields"]: {
		followers_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	total_popularity?:number,
	user_id?:number,
	views_count?:number
};
	/** order by variance() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_variance_order_by"]: GraphQLTypes["voyce_author_profiles_variance_order_by"];
	/** columns and relationships of "voyce.balances" */
["voyce_balances"]: {
		amount?:number,
	created_at:ModelTypes["timestamp"],
	id:number,
	transferable_amount?:number,
	type:string,
	updated_at?:ModelTypes["timestamp"],
	/** An object relationship */
	user?:ModelTypes["voyce_users"],
	user_id?:number
};
	/** aggregated selection of "voyce.balances" */
["voyce_balances_aggregate"]: {
		aggregate?:ModelTypes["voyce_balances_aggregate_fields"],
	nodes:ModelTypes["voyce_balances"][]
};
	/** aggregate fields of "voyce.balances" */
["voyce_balances_aggregate_fields"]: {
		avg?:ModelTypes["voyce_balances_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_balances_max_fields"],
	min?:ModelTypes["voyce_balances_min_fields"],
	stddev?:ModelTypes["voyce_balances_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_balances_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_balances_stddev_samp_fields"],
	sum?:ModelTypes["voyce_balances_sum_fields"],
	var_pop?:ModelTypes["voyce_balances_var_pop_fields"],
	var_samp?:ModelTypes["voyce_balances_var_samp_fields"],
	variance?:ModelTypes["voyce_balances_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_balances_avg_fields"]: {
		amount?:number,
	id?:number,
	transferable_amount?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.balances". All fields are combined with a logical 'AND'. */
["voyce_balances_bool_exp"]: GraphQLTypes["voyce_balances_bool_exp"];
	/** unique or primary key constraints on table "voyce.balances" */
["voyce_balances_constraint"]: GraphQLTypes["voyce_balances_constraint"];
	/** input type for incrementing numeric columns in table "voyce.balances" */
["voyce_balances_inc_input"]: GraphQLTypes["voyce_balances_inc_input"];
	/** input type for inserting data into table "voyce.balances" */
["voyce_balances_insert_input"]: GraphQLTypes["voyce_balances_insert_input"];
	/** aggregate max on columns */
["voyce_balances_max_fields"]: {
		amount?:number,
	created_at?:ModelTypes["timestamp"],
	id?:number,
	transferable_amount?:number,
	type?:string,
	updated_at?:ModelTypes["timestamp"],
	user_id?:number
};
	/** aggregate min on columns */
["voyce_balances_min_fields"]: {
		amount?:number,
	created_at?:ModelTypes["timestamp"],
	id?:number,
	transferable_amount?:number,
	type?:string,
	updated_at?:ModelTypes["timestamp"],
	user_id?:number
};
	/** response of any mutation on the table "voyce.balances" */
["voyce_balances_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_balances"][]
};
	/** on_conflict condition type for table "voyce.balances" */
["voyce_balances_on_conflict"]: GraphQLTypes["voyce_balances_on_conflict"];
	/** Ordering options when selecting data from "voyce.balances". */
["voyce_balances_order_by"]: GraphQLTypes["voyce_balances_order_by"];
	/** primary key columns input for table: voyce.balances */
["voyce_balances_pk_columns_input"]: GraphQLTypes["voyce_balances_pk_columns_input"];
	/** select columns of table "voyce.balances" */
["voyce_balances_select_column"]: GraphQLTypes["voyce_balances_select_column"];
	/** input type for updating data in table "voyce.balances" */
["voyce_balances_set_input"]: GraphQLTypes["voyce_balances_set_input"];
	/** aggregate stddev on columns */
["voyce_balances_stddev_fields"]: {
		amount?:number,
	id?:number,
	transferable_amount?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_balances_stddev_pop_fields"]: {
		amount?:number,
	id?:number,
	transferable_amount?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_balances_stddev_samp_fields"]: {
		amount?:number,
	id?:number,
	transferable_amount?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_balances" */
["voyce_balances_stream_cursor_input"]: GraphQLTypes["voyce_balances_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_balances_stream_cursor_value_input"]: GraphQLTypes["voyce_balances_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_balances_sum_fields"]: {
		amount?:number,
	id?:number,
	transferable_amount?:number,
	user_id?:number
};
	/** update columns of table "voyce.balances" */
["voyce_balances_update_column"]: GraphQLTypes["voyce_balances_update_column"];
	["voyce_balances_updates"]: GraphQLTypes["voyce_balances_updates"];
	/** aggregate var_pop on columns */
["voyce_balances_var_pop_fields"]: {
		amount?:number,
	id?:number,
	transferable_amount?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_balances_var_samp_fields"]: {
		amount?:number,
	id?:number,
	transferable_amount?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_balances_variance_fields"]: {
		amount?:number,
	id?:number,
	transferable_amount?:number,
	user_id?:number
};
	/** Series Banners */
["voyce_banners"]: {
		back_image:string,
	front_image?:string,
	id:number,
	order:number,
	/** An object relationship */
	series:ModelTypes["voyce_series"],
	series_id:number,
	subtitle?:string,
	tag:number
};
	/** aggregated selection of "voyce.banners" */
["voyce_banners_aggregate"]: {
		aggregate?:ModelTypes["voyce_banners_aggregate_fields"],
	nodes:ModelTypes["voyce_banners"][]
};
	/** aggregate fields of "voyce.banners" */
["voyce_banners_aggregate_fields"]: {
		avg?:ModelTypes["voyce_banners_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_banners_max_fields"],
	min?:ModelTypes["voyce_banners_min_fields"],
	stddev?:ModelTypes["voyce_banners_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_banners_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_banners_stddev_samp_fields"],
	sum?:ModelTypes["voyce_banners_sum_fields"],
	var_pop?:ModelTypes["voyce_banners_var_pop_fields"],
	var_samp?:ModelTypes["voyce_banners_var_samp_fields"],
	variance?:ModelTypes["voyce_banners_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_banners_avg_fields"]: {
		id?:number,
	order?:number,
	series_id?:number,
	tag?:number
};
	/** Boolean expression to filter rows from the table "voyce.banners". All fields are combined with a logical 'AND'. */
["voyce_banners_bool_exp"]: GraphQLTypes["voyce_banners_bool_exp"];
	/** unique or primary key constraints on table "voyce.banners" */
["voyce_banners_constraint"]: GraphQLTypes["voyce_banners_constraint"];
	/** input type for incrementing numeric columns in table "voyce.banners" */
["voyce_banners_inc_input"]: GraphQLTypes["voyce_banners_inc_input"];
	/** input type for inserting data into table "voyce.banners" */
["voyce_banners_insert_input"]: GraphQLTypes["voyce_banners_insert_input"];
	/** aggregate max on columns */
["voyce_banners_max_fields"]: {
		back_image?:string,
	front_image?:string,
	id?:number,
	order?:number,
	series_id?:number,
	subtitle?:string,
	tag?:number
};
	/** aggregate min on columns */
["voyce_banners_min_fields"]: {
		back_image?:string,
	front_image?:string,
	id?:number,
	order?:number,
	series_id?:number,
	subtitle?:string,
	tag?:number
};
	/** response of any mutation on the table "voyce.banners" */
["voyce_banners_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_banners"][]
};
	/** on_conflict condition type for table "voyce.banners" */
["voyce_banners_on_conflict"]: GraphQLTypes["voyce_banners_on_conflict"];
	/** Ordering options when selecting data from "voyce.banners". */
["voyce_banners_order_by"]: GraphQLTypes["voyce_banners_order_by"];
	/** primary key columns input for table: voyce.banners */
["voyce_banners_pk_columns_input"]: GraphQLTypes["voyce_banners_pk_columns_input"];
	/** select columns of table "voyce.banners" */
["voyce_banners_select_column"]: GraphQLTypes["voyce_banners_select_column"];
	/** input type for updating data in table "voyce.banners" */
["voyce_banners_set_input"]: GraphQLTypes["voyce_banners_set_input"];
	/** aggregate stddev on columns */
["voyce_banners_stddev_fields"]: {
		id?:number,
	order?:number,
	series_id?:number,
	tag?:number
};
	/** aggregate stddev_pop on columns */
["voyce_banners_stddev_pop_fields"]: {
		id?:number,
	order?:number,
	series_id?:number,
	tag?:number
};
	/** aggregate stddev_samp on columns */
["voyce_banners_stddev_samp_fields"]: {
		id?:number,
	order?:number,
	series_id?:number,
	tag?:number
};
	/** Streaming cursor of the table "voyce_banners" */
["voyce_banners_stream_cursor_input"]: GraphQLTypes["voyce_banners_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_banners_stream_cursor_value_input"]: GraphQLTypes["voyce_banners_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_banners_sum_fields"]: {
		id?:number,
	order?:number,
	series_id?:number,
	tag?:number
};
	/** update columns of table "voyce.banners" */
["voyce_banners_update_column"]: GraphQLTypes["voyce_banners_update_column"];
	["voyce_banners_updates"]: GraphQLTypes["voyce_banners_updates"];
	/** aggregate var_pop on columns */
["voyce_banners_var_pop_fields"]: {
		id?:number,
	order?:number,
	series_id?:number,
	tag?:number
};
	/** aggregate var_samp on columns */
["voyce_banners_var_samp_fields"]: {
		id?:number,
	order?:number,
	series_id?:number,
	tag?:number
};
	/** aggregate variance on columns */
["voyce_banners_variance_fields"]: {
		id?:number,
	order?:number,
	series_id?:number,
	tag?:number
};
	/** columns and relationships of "voyce.chapter_assets" */
["voyce_chapter_assets"]: {
		/** An object relationship */
	chapter_effect?:ModelTypes["voyce_chapter_effects"],
	chapter_effect_id?:number,
	id:number,
	order:number,
	type:string,
	url?:string
};
	/** aggregated selection of "voyce.chapter_assets" */
["voyce_chapter_assets_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_assets_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_assets"][]
};
	["voyce_chapter_assets_aggregate_bool_exp"]: GraphQLTypes["voyce_chapter_assets_aggregate_bool_exp"];
	["voyce_chapter_assets_aggregate_bool_exp_count"]: GraphQLTypes["voyce_chapter_assets_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.chapter_assets" */
["voyce_chapter_assets_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_assets_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_assets_max_fields"],
	min?:ModelTypes["voyce_chapter_assets_min_fields"],
	stddev?:ModelTypes["voyce_chapter_assets_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_assets_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_assets_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_assets_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_assets_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_assets_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_assets_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_assets" */
["voyce_chapter_assets_aggregate_order_by"]: GraphQLTypes["voyce_chapter_assets_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.chapter_assets" */
["voyce_chapter_assets_arr_rel_insert_input"]: GraphQLTypes["voyce_chapter_assets_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_chapter_assets_avg_fields"]: {
		chapter_effect_id?:number,
	id?:number,
	order?:number
};
	/** order by avg() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_avg_order_by"]: GraphQLTypes["voyce_chapter_assets_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.chapter_assets". All fields are combined with a logical 'AND'. */
["voyce_chapter_assets_bool_exp"]: GraphQLTypes["voyce_chapter_assets_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_assets" */
["voyce_chapter_assets_constraint"]: GraphQLTypes["voyce_chapter_assets_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_assets" */
["voyce_chapter_assets_inc_input"]: GraphQLTypes["voyce_chapter_assets_inc_input"];
	/** input type for inserting data into table "voyce.chapter_assets" */
["voyce_chapter_assets_insert_input"]: GraphQLTypes["voyce_chapter_assets_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_assets_max_fields"]: {
		chapter_effect_id?:number,
	id?:number,
	order?:number,
	type?:string,
	url?:string
};
	/** order by max() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_max_order_by"]: GraphQLTypes["voyce_chapter_assets_max_order_by"];
	/** aggregate min on columns */
["voyce_chapter_assets_min_fields"]: {
		chapter_effect_id?:number,
	id?:number,
	order?:number,
	type?:string,
	url?:string
};
	/** order by min() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_min_order_by"]: GraphQLTypes["voyce_chapter_assets_min_order_by"];
	/** response of any mutation on the table "voyce.chapter_assets" */
["voyce_chapter_assets_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_assets"][]
};
	/** on_conflict condition type for table "voyce.chapter_assets" */
["voyce_chapter_assets_on_conflict"]: GraphQLTypes["voyce_chapter_assets_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_assets". */
["voyce_chapter_assets_order_by"]: GraphQLTypes["voyce_chapter_assets_order_by"];
	/** primary key columns input for table: voyce.chapter_assets */
["voyce_chapter_assets_pk_columns_input"]: GraphQLTypes["voyce_chapter_assets_pk_columns_input"];
	/** select columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_select_column"]: GraphQLTypes["voyce_chapter_assets_select_column"];
	/** input type for updating data in table "voyce.chapter_assets" */
["voyce_chapter_assets_set_input"]: GraphQLTypes["voyce_chapter_assets_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_assets_stddev_fields"]: {
		chapter_effect_id?:number,
	id?:number,
	order?:number
};
	/** order by stddev() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_stddev_order_by"]: GraphQLTypes["voyce_chapter_assets_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_chapter_assets_stddev_pop_fields"]: {
		chapter_effect_id?:number,
	id?:number,
	order?:number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_stddev_pop_order_by"]: GraphQLTypes["voyce_chapter_assets_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_chapter_assets_stddev_samp_fields"]: {
		chapter_effect_id?:number,
	id?:number,
	order?:number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_stddev_samp_order_by"]: GraphQLTypes["voyce_chapter_assets_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_chapter_assets" */
["voyce_chapter_assets_stream_cursor_input"]: GraphQLTypes["voyce_chapter_assets_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_assets_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_assets_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_assets_sum_fields"]: {
		chapter_effect_id?:number,
	id?:number,
	order?:number
};
	/** order by sum() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_sum_order_by"]: GraphQLTypes["voyce_chapter_assets_sum_order_by"];
	/** update columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_update_column"]: GraphQLTypes["voyce_chapter_assets_update_column"];
	["voyce_chapter_assets_updates"]: GraphQLTypes["voyce_chapter_assets_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_assets_var_pop_fields"]: {
		chapter_effect_id?:number,
	id?:number,
	order?:number
};
	/** order by var_pop() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_var_pop_order_by"]: GraphQLTypes["voyce_chapter_assets_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_chapter_assets_var_samp_fields"]: {
		chapter_effect_id?:number,
	id?:number,
	order?:number
};
	/** order by var_samp() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_var_samp_order_by"]: GraphQLTypes["voyce_chapter_assets_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_chapter_assets_variance_fields"]: {
		chapter_effect_id?:number,
	id?:number,
	order?:number
};
	/** order by variance() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_variance_order_by"]: GraphQLTypes["voyce_chapter_assets_variance_order_by"];
	/** columns and relationships of "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks"]: {
		chapter_id:number,
	created_at:ModelTypes["timestamptz"],
	id:number,
	/** An object relationship */
	series:ModelTypes["voyce_series"],
	series_id:number,
	user_id:number
};
	/** aggregated selection of "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_bookmarks_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_bookmarks"][]
};
	/** aggregate fields of "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_bookmarks_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_bookmarks_max_fields"],
	min?:ModelTypes["voyce_chapter_bookmarks_min_fields"],
	stddev?:ModelTypes["voyce_chapter_bookmarks_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_bookmarks_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_bookmarks_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_bookmarks_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_bookmarks_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_bookmarks_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_bookmarks_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapter_bookmarks_avg_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.chapter_bookmarks". All fields are combined with a logical 'AND'. */
["voyce_chapter_bookmarks_bool_exp"]: GraphQLTypes["voyce_chapter_bookmarks_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_constraint"]: GraphQLTypes["voyce_chapter_bookmarks_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_inc_input"]: GraphQLTypes["voyce_chapter_bookmarks_inc_input"];
	/** input type for inserting data into table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_insert_input"]: GraphQLTypes["voyce_chapter_bookmarks_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_bookmarks_max_fields"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_chapter_bookmarks_min_fields"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** response of any mutation on the table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_bookmarks"][]
};
	/** on_conflict condition type for table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_on_conflict"]: GraphQLTypes["voyce_chapter_bookmarks_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_bookmarks". */
["voyce_chapter_bookmarks_order_by"]: GraphQLTypes["voyce_chapter_bookmarks_order_by"];
	/** primary key columns input for table: voyce.chapter_bookmarks */
["voyce_chapter_bookmarks_pk_columns_input"]: GraphQLTypes["voyce_chapter_bookmarks_pk_columns_input"];
	/** select columns of table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_select_column"]: GraphQLTypes["voyce_chapter_bookmarks_select_column"];
	/** input type for updating data in table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_set_input"]: GraphQLTypes["voyce_chapter_bookmarks_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_bookmarks_stddev_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_bookmarks_stddev_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_bookmarks_stddev_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_chapter_bookmarks" */
["voyce_chapter_bookmarks_stream_cursor_input"]: GraphQLTypes["voyce_chapter_bookmarks_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_bookmarks_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_bookmarks_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_bookmarks_sum_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** update columns of table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_update_column"]: GraphQLTypes["voyce_chapter_bookmarks_update_column"];
	["voyce_chapter_bookmarks_updates"]: GraphQLTypes["voyce_chapter_bookmarks_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_bookmarks_var_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_chapter_bookmarks_var_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_chapter_bookmarks_variance_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.chapter_comments" */
["voyce_chapter_comments"]: {
		animated_image?:string,
	attachment_url?:string,
	/** An object relationship */
	chapter?:ModelTypes["voyce_chapters"],
	/** An object relationship */
	chapter_comment?:ModelTypes["voyce_chapter_comments"],
	/** An array relationship */
	chapter_comments:ModelTypes["voyce_chapter_comments"][],
	/** An aggregate relationship */
	chapter_comments_aggregate:ModelTypes["voyce_chapter_comments_aggregate"],
	/** An array relationship */
	chapter_comments_reactions:ModelTypes["voyce_chapter_comments_reaction"][],
	/** An aggregate relationship */
	chapter_comments_reactions_aggregate:ModelTypes["voyce_chapter_comments_reaction_aggregate"],
	/** An array relationship */
	chapter_comments_reports:ModelTypes["voyce_chapter_comments_reports"][],
	/** An aggregate relationship */
	chapter_comments_reports_aggregate:ModelTypes["voyce_chapter_comments_reports_aggregate"],
	chapter_id?:number,
	comment:string,
	created_at:ModelTypes["timestamptz"],
	id:number,
	last_reacted_at?:ModelTypes["timestamptz"],
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	/** An array relationship */
	reactions:ModelTypes["voyce_chapter_comments_reaction_count"][],
	/** An aggregate relationship */
	reactions_aggregate:ModelTypes["voyce_chapter_comments_reaction_count_aggregate"],
	replies_count?:number,
	/** An object relationship */
	series?:ModelTypes["voyce_series"],
	series_id?:number,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** columns and relationships of "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024"]: {
		animated_image?:string,
	attachment_url?:string,
	chapter_id?:number,
	comment?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	last_reacted_at?:ModelTypes["timestamptz"],
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregated selection of "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_comments_07252024_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_comments_07252024"][]
};
	/** aggregate fields of "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_comments_07252024_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_comments_07252024_max_fields"],
	min?:ModelTypes["voyce_chapter_comments_07252024_min_fields"],
	stddev?:ModelTypes["voyce_chapter_comments_07252024_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_comments_07252024_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_comments_07252024_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_comments_07252024_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_comments_07252024_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_comments_07252024_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_comments_07252024_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapter_comments_07252024_avg_fields"]: {
		chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.chapter_comments_07252024". All fields are combined with a logical 'AND'. */
["voyce_chapter_comments_07252024_bool_exp"]: GraphQLTypes["voyce_chapter_comments_07252024_bool_exp"];
	/** input type for incrementing numeric columns in table "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_inc_input"]: GraphQLTypes["voyce_chapter_comments_07252024_inc_input"];
	/** input type for inserting data into table "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_insert_input"]: GraphQLTypes["voyce_chapter_comments_07252024_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_comments_07252024_max_fields"]: {
		animated_image?:string,
	attachment_url?:string,
	chapter_id?:number,
	comment?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	last_reacted_at?:ModelTypes["timestamptz"],
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_chapter_comments_07252024_min_fields"]: {
		animated_image?:string,
	attachment_url?:string,
	chapter_id?:number,
	comment?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	last_reacted_at?:ModelTypes["timestamptz"],
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** response of any mutation on the table "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_comments_07252024"][]
};
	/** Ordering options when selecting data from "voyce.chapter_comments_07252024". */
["voyce_chapter_comments_07252024_order_by"]: GraphQLTypes["voyce_chapter_comments_07252024_order_by"];
	/** select columns of table "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_select_column"]: GraphQLTypes["voyce_chapter_comments_07252024_select_column"];
	/** input type for updating data in table "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_set_input"]: GraphQLTypes["voyce_chapter_comments_07252024_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_comments_07252024_stddev_fields"]: {
		chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_comments_07252024_stddev_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_comments_07252024_stddev_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_chapter_comments_07252024" */
["voyce_chapter_comments_07252024_stream_cursor_input"]: GraphQLTypes["voyce_chapter_comments_07252024_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_comments_07252024_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_comments_07252024_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_comments_07252024_sum_fields"]: {
		chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	["voyce_chapter_comments_07252024_updates"]: GraphQLTypes["voyce_chapter_comments_07252024_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_comments_07252024_var_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_chapter_comments_07252024_var_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_chapter_comments_07252024_variance_fields"]: {
		chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregated selection of "voyce.chapter_comments" */
["voyce_chapter_comments_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_comments_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_comments"][]
};
	["voyce_chapter_comments_aggregate_bool_exp"]: GraphQLTypes["voyce_chapter_comments_aggregate_bool_exp"];
	["voyce_chapter_comments_aggregate_bool_exp_count"]: GraphQLTypes["voyce_chapter_comments_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.chapter_comments" */
["voyce_chapter_comments_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_comments_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_comments_max_fields"],
	min?:ModelTypes["voyce_chapter_comments_min_fields"],
	stddev?:ModelTypes["voyce_chapter_comments_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_comments_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_comments_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_comments_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_comments_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_comments_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_comments_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_comments" */
["voyce_chapter_comments_aggregate_order_by"]: GraphQLTypes["voyce_chapter_comments_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.chapter_comments" */
["voyce_chapter_comments_arr_rel_insert_input"]: GraphQLTypes["voyce_chapter_comments_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_chapter_comments_avg_fields"]: {
		chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_avg_order_by"]: GraphQLTypes["voyce_chapter_comments_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.chapter_comments". All fields are combined with a logical 'AND'. */
["voyce_chapter_comments_bool_exp"]: GraphQLTypes["voyce_chapter_comments_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_comments" */
["voyce_chapter_comments_constraint"]: GraphQLTypes["voyce_chapter_comments_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_comments" */
["voyce_chapter_comments_inc_input"]: GraphQLTypes["voyce_chapter_comments_inc_input"];
	/** input type for inserting data into table "voyce.chapter_comments" */
["voyce_chapter_comments_insert_input"]: GraphQLTypes["voyce_chapter_comments_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_comments_max_fields"]: {
		animated_image?:string,
	attachment_url?:string,
	chapter_id?:number,
	comment?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	last_reacted_at?:ModelTypes["timestamptz"],
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** order by max() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_max_order_by"]: GraphQLTypes["voyce_chapter_comments_max_order_by"];
	/** aggregate min on columns */
["voyce_chapter_comments_min_fields"]: {
		animated_image?:string,
	attachment_url?:string,
	chapter_id?:number,
	comment?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	last_reacted_at?:ModelTypes["timestamptz"],
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** order by min() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_min_order_by"]: GraphQLTypes["voyce_chapter_comments_min_order_by"];
	/** response of any mutation on the table "voyce.chapter_comments" */
["voyce_chapter_comments_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_comments"][]
};
	/** input type for inserting object relation for remote table "voyce.chapter_comments" */
["voyce_chapter_comments_obj_rel_insert_input"]: GraphQLTypes["voyce_chapter_comments_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.chapter_comments" */
["voyce_chapter_comments_on_conflict"]: GraphQLTypes["voyce_chapter_comments_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_comments". */
["voyce_chapter_comments_order_by"]: GraphQLTypes["voyce_chapter_comments_order_by"];
	/** primary key columns input for table: voyce.chapter_comments */
["voyce_chapter_comments_pk_columns_input"]: GraphQLTypes["voyce_chapter_comments_pk_columns_input"];
	/** columns and relationships of "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction"]: {
		/** An object relationship */
	chapter_comment:ModelTypes["voyce_chapter_comments"],
	comment_id:number,
	created_at:ModelTypes["timestamptz"],
	id:number,
	reaction:number,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_comments_reaction_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_comments_reaction"][]
};
	["voyce_chapter_comments_reaction_aggregate_bool_exp"]: GraphQLTypes["voyce_chapter_comments_reaction_aggregate_bool_exp"];
	["voyce_chapter_comments_reaction_aggregate_bool_exp_count"]: GraphQLTypes["voyce_chapter_comments_reaction_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_comments_reaction_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_comments_reaction_max_fields"],
	min?:ModelTypes["voyce_chapter_comments_reaction_min_fields"],
	stddev?:ModelTypes["voyce_chapter_comments_reaction_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_comments_reaction_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_comments_reaction_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_comments_reaction_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_comments_reaction_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_comments_reaction_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_comments_reaction_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_aggregate_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_arr_rel_insert_input"]: GraphQLTypes["voyce_chapter_comments_reaction_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_chapter_comments_reaction_avg_fields"]: {
		comment_id?:number,
	id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_avg_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.chapter_comments_reaction". All fields are combined with a logical 'AND'. */
["voyce_chapter_comments_reaction_bool_exp"]: GraphQLTypes["voyce_chapter_comments_reaction_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_constraint"]: GraphQLTypes["voyce_chapter_comments_reaction_constraint"];
	/** columns and relationships of "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count"]: {
		comment_id:number,
	count:number,
	id:number,
	reaction:number
};
	/** aggregated selection of "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_comments_reaction_count_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_comments_reaction_count"][]
};
	["voyce_chapter_comments_reaction_count_aggregate_bool_exp"]: GraphQLTypes["voyce_chapter_comments_reaction_count_aggregate_bool_exp"];
	["voyce_chapter_comments_reaction_count_aggregate_bool_exp_count"]: GraphQLTypes["voyce_chapter_comments_reaction_count_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_comments_reaction_count_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_comments_reaction_count_max_fields"],
	min?:ModelTypes["voyce_chapter_comments_reaction_count_min_fields"],
	stddev?:ModelTypes["voyce_chapter_comments_reaction_count_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_comments_reaction_count_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_comments_reaction_count_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_comments_reaction_count_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_comments_reaction_count_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_comments_reaction_count_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_comments_reaction_count_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_aggregate_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_count_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_arr_rel_insert_input"]: GraphQLTypes["voyce_chapter_comments_reaction_count_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_chapter_comments_reaction_count_avg_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** order by avg() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_avg_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_count_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.chapter_comments_reaction_count". All fields are combined with a logical 'AND'. */
["voyce_chapter_comments_reaction_count_bool_exp"]: GraphQLTypes["voyce_chapter_comments_reaction_count_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_constraint"]: GraphQLTypes["voyce_chapter_comments_reaction_count_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_inc_input"]: GraphQLTypes["voyce_chapter_comments_reaction_count_inc_input"];
	/** input type for inserting data into table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_insert_input"]: GraphQLTypes["voyce_chapter_comments_reaction_count_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_comments_reaction_count_max_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** order by max() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_max_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_count_max_order_by"];
	/** aggregate min on columns */
["voyce_chapter_comments_reaction_count_min_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** order by min() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_min_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_count_min_order_by"];
	/** response of any mutation on the table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_comments_reaction_count"][]
};
	/** on_conflict condition type for table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_on_conflict"]: GraphQLTypes["voyce_chapter_comments_reaction_count_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_comments_reaction_count". */
["voyce_chapter_comments_reaction_count_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_count_order_by"];
	/** primary key columns input for table: voyce.chapter_comments_reaction_count */
["voyce_chapter_comments_reaction_count_pk_columns_input"]: GraphQLTypes["voyce_chapter_comments_reaction_count_pk_columns_input"];
	/** select columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_select_column"]: GraphQLTypes["voyce_chapter_comments_reaction_count_select_column"];
	/** input type for updating data in table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_set_input"]: GraphQLTypes["voyce_chapter_comments_reaction_count_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_comments_reaction_count_stddev_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** order by stddev() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_stddev_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_count_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_chapter_comments_reaction_count_stddev_pop_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_stddev_pop_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_count_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_chapter_comments_reaction_count_stddev_samp_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_stddev_samp_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_count_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_stream_cursor_input"]: GraphQLTypes["voyce_chapter_comments_reaction_count_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_comments_reaction_count_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_comments_reaction_count_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_comments_reaction_count_sum_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** order by sum() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_sum_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_count_sum_order_by"];
	/** update columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_update_column"]: GraphQLTypes["voyce_chapter_comments_reaction_count_update_column"];
	["voyce_chapter_comments_reaction_count_updates"]: GraphQLTypes["voyce_chapter_comments_reaction_count_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_comments_reaction_count_var_pop_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** order by var_pop() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_var_pop_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_count_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_chapter_comments_reaction_count_var_samp_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** order by var_samp() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_var_samp_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_count_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_chapter_comments_reaction_count_variance_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** order by variance() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_variance_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_count_variance_order_by"];
	/** input type for incrementing numeric columns in table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_inc_input"]: GraphQLTypes["voyce_chapter_comments_reaction_inc_input"];
	/** input type for inserting data into table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_insert_input"]: GraphQLTypes["voyce_chapter_comments_reaction_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_comments_reaction_max_fields"]: {
		comment_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by max() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_max_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_max_order_by"];
	/** aggregate min on columns */
["voyce_chapter_comments_reaction_min_fields"]: {
		comment_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by min() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_min_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_min_order_by"];
	/** response of any mutation on the table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_comments_reaction"][]
};
	/** on_conflict condition type for table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_on_conflict"]: GraphQLTypes["voyce_chapter_comments_reaction_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_comments_reaction". */
["voyce_chapter_comments_reaction_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_order_by"];
	/** primary key columns input for table: voyce.chapter_comments_reaction */
["voyce_chapter_comments_reaction_pk_columns_input"]: GraphQLTypes["voyce_chapter_comments_reaction_pk_columns_input"];
	/** select columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_select_column"]: GraphQLTypes["voyce_chapter_comments_reaction_select_column"];
	/** input type for updating data in table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_set_input"]: GraphQLTypes["voyce_chapter_comments_reaction_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_comments_reaction_stddev_fields"]: {
		comment_id?:number,
	id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_stddev_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_chapter_comments_reaction_stddev_pop_fields"]: {
		comment_id?:number,
	id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_stddev_pop_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_chapter_comments_reaction_stddev_samp_fields"]: {
		comment_id?:number,
	id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_stddev_samp_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_chapter_comments_reaction" */
["voyce_chapter_comments_reaction_stream_cursor_input"]: GraphQLTypes["voyce_chapter_comments_reaction_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_comments_reaction_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_comments_reaction_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_comments_reaction_sum_fields"]: {
		comment_id?:number,
	id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_sum_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_sum_order_by"];
	/** update columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_update_column"]: GraphQLTypes["voyce_chapter_comments_reaction_update_column"];
	["voyce_chapter_comments_reaction_updates"]: GraphQLTypes["voyce_chapter_comments_reaction_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_comments_reaction_var_pop_fields"]: {
		comment_id?:number,
	id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_var_pop_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_chapter_comments_reaction_var_samp_fields"]: {
		comment_id?:number,
	id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_var_samp_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_chapter_comments_reaction_variance_fields"]: {
		comment_id?:number,
	id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_variance_order_by"]: GraphQLTypes["voyce_chapter_comments_reaction_variance_order_by"];
	/** columns and relationships of "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports"]: {
		/** An object relationship */
	chapter_comment:ModelTypes["voyce_chapter_comments"],
	comment_id:number,
	id:number,
	message?:string,
	type_id:number,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_comments_reports_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_comments_reports"][]
};
	["voyce_chapter_comments_reports_aggregate_bool_exp"]: GraphQLTypes["voyce_chapter_comments_reports_aggregate_bool_exp"];
	["voyce_chapter_comments_reports_aggregate_bool_exp_count"]: GraphQLTypes["voyce_chapter_comments_reports_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_comments_reports_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_comments_reports_max_fields"],
	min?:ModelTypes["voyce_chapter_comments_reports_min_fields"],
	stddev?:ModelTypes["voyce_chapter_comments_reports_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_comments_reports_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_comments_reports_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_comments_reports_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_comments_reports_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_comments_reports_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_comments_reports_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_aggregate_order_by"]: GraphQLTypes["voyce_chapter_comments_reports_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_arr_rel_insert_input"]: GraphQLTypes["voyce_chapter_comments_reports_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_chapter_comments_reports_avg_fields"]: {
		comment_id?:number,
	id?:number,
	type_id?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_avg_order_by"]: GraphQLTypes["voyce_chapter_comments_reports_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.chapter_comments_reports". All fields are combined with a logical 'AND'. */
["voyce_chapter_comments_reports_bool_exp"]: GraphQLTypes["voyce_chapter_comments_reports_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_constraint"]: GraphQLTypes["voyce_chapter_comments_reports_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_inc_input"]: GraphQLTypes["voyce_chapter_comments_reports_inc_input"];
	/** input type for inserting data into table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_insert_input"]: GraphQLTypes["voyce_chapter_comments_reports_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_comments_reports_max_fields"]: {
		comment_id?:number,
	id?:number,
	message?:string,
	type_id?:number,
	user_id?:number
};
	/** order by max() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_max_order_by"]: GraphQLTypes["voyce_chapter_comments_reports_max_order_by"];
	/** aggregate min on columns */
["voyce_chapter_comments_reports_min_fields"]: {
		comment_id?:number,
	id?:number,
	message?:string,
	type_id?:number,
	user_id?:number
};
	/** order by min() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_min_order_by"]: GraphQLTypes["voyce_chapter_comments_reports_min_order_by"];
	/** response of any mutation on the table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_comments_reports"][]
};
	/** on_conflict condition type for table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_on_conflict"]: GraphQLTypes["voyce_chapter_comments_reports_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_comments_reports". */
["voyce_chapter_comments_reports_order_by"]: GraphQLTypes["voyce_chapter_comments_reports_order_by"];
	/** primary key columns input for table: voyce.chapter_comments_reports */
["voyce_chapter_comments_reports_pk_columns_input"]: GraphQLTypes["voyce_chapter_comments_reports_pk_columns_input"];
	/** select columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_select_column"]: GraphQLTypes["voyce_chapter_comments_reports_select_column"];
	/** input type for updating data in table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_set_input"]: GraphQLTypes["voyce_chapter_comments_reports_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_comments_reports_stddev_fields"]: {
		comment_id?:number,
	id?:number,
	type_id?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_stddev_order_by"]: GraphQLTypes["voyce_chapter_comments_reports_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_chapter_comments_reports_stddev_pop_fields"]: {
		comment_id?:number,
	id?:number,
	type_id?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_stddev_pop_order_by"]: GraphQLTypes["voyce_chapter_comments_reports_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_chapter_comments_reports_stddev_samp_fields"]: {
		comment_id?:number,
	id?:number,
	type_id?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_stddev_samp_order_by"]: GraphQLTypes["voyce_chapter_comments_reports_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_chapter_comments_reports" */
["voyce_chapter_comments_reports_stream_cursor_input"]: GraphQLTypes["voyce_chapter_comments_reports_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_comments_reports_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_comments_reports_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_comments_reports_sum_fields"]: {
		comment_id?:number,
	id?:number,
	type_id?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_sum_order_by"]: GraphQLTypes["voyce_chapter_comments_reports_sum_order_by"];
	/** update columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_update_column"]: GraphQLTypes["voyce_chapter_comments_reports_update_column"];
	["voyce_chapter_comments_reports_updates"]: GraphQLTypes["voyce_chapter_comments_reports_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_comments_reports_var_pop_fields"]: {
		comment_id?:number,
	id?:number,
	type_id?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_var_pop_order_by"]: GraphQLTypes["voyce_chapter_comments_reports_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_chapter_comments_reports_var_samp_fields"]: {
		comment_id?:number,
	id?:number,
	type_id?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_var_samp_order_by"]: GraphQLTypes["voyce_chapter_comments_reports_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_chapter_comments_reports_variance_fields"]: {
		comment_id?:number,
	id?:number,
	type_id?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_variance_order_by"]: GraphQLTypes["voyce_chapter_comments_reports_variance_order_by"];
	/** select columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_select_column"]: GraphQLTypes["voyce_chapter_comments_select_column"];
	/** input type for updating data in table "voyce.chapter_comments" */
["voyce_chapter_comments_set_input"]: GraphQLTypes["voyce_chapter_comments_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_comments_stddev_fields"]: {
		chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_stddev_order_by"]: GraphQLTypes["voyce_chapter_comments_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_chapter_comments_stddev_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_stddev_pop_order_by"]: GraphQLTypes["voyce_chapter_comments_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_chapter_comments_stddev_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_stddev_samp_order_by"]: GraphQLTypes["voyce_chapter_comments_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_chapter_comments" */
["voyce_chapter_comments_stream_cursor_input"]: GraphQLTypes["voyce_chapter_comments_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_comments_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_comments_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_comments_sum_fields"]: {
		chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_sum_order_by"]: GraphQLTypes["voyce_chapter_comments_sum_order_by"];
	/** update columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_update_column"]: GraphQLTypes["voyce_chapter_comments_update_column"];
	["voyce_chapter_comments_updates"]: GraphQLTypes["voyce_chapter_comments_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_comments_var_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_var_pop_order_by"]: GraphQLTypes["voyce_chapter_comments_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_chapter_comments_var_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_var_samp_order_by"]: GraphQLTypes["voyce_chapter_comments_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_chapter_comments_variance_fields"]: {
		chapter_id?:number,
	id?:number,
	last_reaction_count?:number,
	original_comment_id?:number,
	reaction_count?:number,
	replies_count?:number,
	series_id?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_variance_order_by"]: GraphQLTypes["voyce_chapter_comments_variance_order_by"];
	/** series novel content for each chapter */
["voyce_chapter_contents"]: {
		/** An object relationship */
	chapter:ModelTypes["voyce_chapters"],
	chapter_id:number,
	content:string,
	content_short?:string,
	created_at:ModelTypes["timestamptz"],
	id:number,
	mobile_content?:string,
	mobile_content_old?:string,
	order:number,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.chapter_contents" */
["voyce_chapter_contents_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_contents_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_contents"][]
};
	/** aggregate fields of "voyce.chapter_contents" */
["voyce_chapter_contents_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_contents_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_contents_max_fields"],
	min?:ModelTypes["voyce_chapter_contents_min_fields"],
	stddev?:ModelTypes["voyce_chapter_contents_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_contents_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_contents_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_contents_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_contents_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_contents_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_contents_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapter_contents_avg_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number
};
	/** Boolean expression to filter rows from the table "voyce.chapter_contents". All fields are combined with a logical 'AND'. */
["voyce_chapter_contents_bool_exp"]: GraphQLTypes["voyce_chapter_contents_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_contents" */
["voyce_chapter_contents_constraint"]: GraphQLTypes["voyce_chapter_contents_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_contents" */
["voyce_chapter_contents_inc_input"]: GraphQLTypes["voyce_chapter_contents_inc_input"];
	/** input type for inserting data into table "voyce.chapter_contents" */
["voyce_chapter_contents_insert_input"]: GraphQLTypes["voyce_chapter_contents_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_contents_max_fields"]: {
		chapter_id?:number,
	content?:string,
	content_short?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	mobile_content?:string,
	mobile_content_old?:string,
	order?:number,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_chapter_contents_min_fields"]: {
		chapter_id?:number,
	content?:string,
	content_short?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	mobile_content?:string,
	mobile_content_old?:string,
	order?:number,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.chapter_contents" */
["voyce_chapter_contents_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_contents"][]
};
	/** input type for inserting object relation for remote table "voyce.chapter_contents" */
["voyce_chapter_contents_obj_rel_insert_input"]: GraphQLTypes["voyce_chapter_contents_obj_rel_insert_input"];
	/** columns and relationships of "voyce.chapter_contents_old" */
["voyce_chapter_contents_old"]: {
		/** An object relationship */
	chapter:ModelTypes["voyce_chapters"],
	chapter_id:number,
	content:string,
	created_at:ModelTypes["timestamptz"],
	id:number,
	mobile_content?:string,
	mobile_content_old?:string,
	order:number,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_contents_old_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_contents_old"][]
};
	/** aggregate fields of "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_contents_old_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_contents_old_max_fields"],
	min?:ModelTypes["voyce_chapter_contents_old_min_fields"],
	stddev?:ModelTypes["voyce_chapter_contents_old_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_contents_old_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_contents_old_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_contents_old_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_contents_old_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_contents_old_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_contents_old_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapter_contents_old_avg_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number
};
	/** Boolean expression to filter rows from the table "voyce.chapter_contents_old". All fields are combined with a logical 'AND'. */
["voyce_chapter_contents_old_bool_exp"]: GraphQLTypes["voyce_chapter_contents_old_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_constraint"]: GraphQLTypes["voyce_chapter_contents_old_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_inc_input"]: GraphQLTypes["voyce_chapter_contents_old_inc_input"];
	/** input type for inserting data into table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_insert_input"]: GraphQLTypes["voyce_chapter_contents_old_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_contents_old_max_fields"]: {
		chapter_id?:number,
	content?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	mobile_content?:string,
	mobile_content_old?:string,
	order?:number,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_chapter_contents_old_min_fields"]: {
		chapter_id?:number,
	content?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	mobile_content?:string,
	mobile_content_old?:string,
	order?:number,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_contents_old"][]
};
	/** on_conflict condition type for table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_on_conflict"]: GraphQLTypes["voyce_chapter_contents_old_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_contents_old". */
["voyce_chapter_contents_old_order_by"]: GraphQLTypes["voyce_chapter_contents_old_order_by"];
	/** primary key columns input for table: voyce.chapter_contents_old */
["voyce_chapter_contents_old_pk_columns_input"]: GraphQLTypes["voyce_chapter_contents_old_pk_columns_input"];
	/** select columns of table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_select_column"]: GraphQLTypes["voyce_chapter_contents_old_select_column"];
	/** input type for updating data in table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_set_input"]: GraphQLTypes["voyce_chapter_contents_old_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_contents_old_stddev_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_contents_old_stddev_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_contents_old_stddev_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number
};
	/** Streaming cursor of the table "voyce_chapter_contents_old" */
["voyce_chapter_contents_old_stream_cursor_input"]: GraphQLTypes["voyce_chapter_contents_old_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_contents_old_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_contents_old_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_contents_old_sum_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number
};
	/** update columns of table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_update_column"]: GraphQLTypes["voyce_chapter_contents_old_update_column"];
	["voyce_chapter_contents_old_updates"]: GraphQLTypes["voyce_chapter_contents_old_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_contents_old_var_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number
};
	/** aggregate var_samp on columns */
["voyce_chapter_contents_old_var_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number
};
	/** aggregate variance on columns */
["voyce_chapter_contents_old_variance_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number
};
	/** on_conflict condition type for table "voyce.chapter_contents" */
["voyce_chapter_contents_on_conflict"]: GraphQLTypes["voyce_chapter_contents_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_contents". */
["voyce_chapter_contents_order_by"]: GraphQLTypes["voyce_chapter_contents_order_by"];
	/** primary key columns input for table: voyce.chapter_contents */
["voyce_chapter_contents_pk_columns_input"]: GraphQLTypes["voyce_chapter_contents_pk_columns_input"];
	/** select columns of table "voyce.chapter_contents" */
["voyce_chapter_contents_select_column"]: GraphQLTypes["voyce_chapter_contents_select_column"];
	/** input type for updating data in table "voyce.chapter_contents" */
["voyce_chapter_contents_set_input"]: GraphQLTypes["voyce_chapter_contents_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_contents_stddev_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_contents_stddev_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_contents_stddev_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number
};
	/** Streaming cursor of the table "voyce_chapter_contents" */
["voyce_chapter_contents_stream_cursor_input"]: GraphQLTypes["voyce_chapter_contents_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_contents_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_contents_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_contents_sum_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number
};
	/** update columns of table "voyce.chapter_contents" */
["voyce_chapter_contents_update_column"]: GraphQLTypes["voyce_chapter_contents_update_column"];
	["voyce_chapter_contents_updates"]: GraphQLTypes["voyce_chapter_contents_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_contents_var_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number
};
	/** aggregate var_samp on columns */
["voyce_chapter_contents_var_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number
};
	/** aggregate variance on columns */
["voyce_chapter_contents_variance_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number
};
	/** To match scroll speed: duration = end_x || end_y */
["voyce_chapter_effects"]: {
		animation_offset?:number,
	/** An object relationship */
	chapter:ModelTypes["voyce_chapters"],
	/** An array relationship */
	chapter_assets:ModelTypes["voyce_chapter_assets"][],
	/** An aggregate relationship */
	chapter_assets_aggregate:ModelTypes["voyce_chapter_assets_aggregate"],
	chapter_id:number,
	/** An object relationship */
	chapter_image?:ModelTypes["voyce_chapter_images"],
	chapter_img_id?:number,
	duration?:number,
	effect_type:number,
	end_x?:number,
	end_y?:number,
	id:number,
	strength?:number
};
	/** aggregated selection of "voyce.chapter_effects" */
["voyce_chapter_effects_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_effects_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_effects"][]
};
	["voyce_chapter_effects_aggregate_bool_exp"]: GraphQLTypes["voyce_chapter_effects_aggregate_bool_exp"];
	["voyce_chapter_effects_aggregate_bool_exp_count"]: GraphQLTypes["voyce_chapter_effects_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.chapter_effects" */
["voyce_chapter_effects_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_effects_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_effects_max_fields"],
	min?:ModelTypes["voyce_chapter_effects_min_fields"],
	stddev?:ModelTypes["voyce_chapter_effects_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_effects_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_effects_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_effects_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_effects_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_effects_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_effects_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_effects" */
["voyce_chapter_effects_aggregate_order_by"]: GraphQLTypes["voyce_chapter_effects_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.chapter_effects" */
["voyce_chapter_effects_arr_rel_insert_input"]: GraphQLTypes["voyce_chapter_effects_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_chapter_effects_avg_fields"]: {
		animation_offset?:number,
	chapter_id?:number,
	chapter_img_id?:number,
	duration?:number,
	effect_type?:number,
	end_x?:number,
	end_y?:number,
	id?:number,
	strength?:number
};
	/** order by avg() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_avg_order_by"]: GraphQLTypes["voyce_chapter_effects_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.chapter_effects". All fields are combined with a logical 'AND'. */
["voyce_chapter_effects_bool_exp"]: GraphQLTypes["voyce_chapter_effects_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_effects" */
["voyce_chapter_effects_constraint"]: GraphQLTypes["voyce_chapter_effects_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_effects" */
["voyce_chapter_effects_inc_input"]: GraphQLTypes["voyce_chapter_effects_inc_input"];
	/** input type for inserting data into table "voyce.chapter_effects" */
["voyce_chapter_effects_insert_input"]: GraphQLTypes["voyce_chapter_effects_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_effects_max_fields"]: {
		animation_offset?:number,
	chapter_id?:number,
	chapter_img_id?:number,
	duration?:number,
	effect_type?:number,
	end_x?:number,
	end_y?:number,
	id?:number,
	strength?:number
};
	/** order by max() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_max_order_by"]: GraphQLTypes["voyce_chapter_effects_max_order_by"];
	/** aggregate min on columns */
["voyce_chapter_effects_min_fields"]: {
		animation_offset?:number,
	chapter_id?:number,
	chapter_img_id?:number,
	duration?:number,
	effect_type?:number,
	end_x?:number,
	end_y?:number,
	id?:number,
	strength?:number
};
	/** order by min() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_min_order_by"]: GraphQLTypes["voyce_chapter_effects_min_order_by"];
	/** response of any mutation on the table "voyce.chapter_effects" */
["voyce_chapter_effects_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_effects"][]
};
	/** input type for inserting object relation for remote table "voyce.chapter_effects" */
["voyce_chapter_effects_obj_rel_insert_input"]: GraphQLTypes["voyce_chapter_effects_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.chapter_effects" */
["voyce_chapter_effects_on_conflict"]: GraphQLTypes["voyce_chapter_effects_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_effects". */
["voyce_chapter_effects_order_by"]: GraphQLTypes["voyce_chapter_effects_order_by"];
	/** primary key columns input for table: voyce.chapter_effects */
["voyce_chapter_effects_pk_columns_input"]: GraphQLTypes["voyce_chapter_effects_pk_columns_input"];
	/** select columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_select_column"]: GraphQLTypes["voyce_chapter_effects_select_column"];
	/** input type for updating data in table "voyce.chapter_effects" */
["voyce_chapter_effects_set_input"]: GraphQLTypes["voyce_chapter_effects_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_effects_stddev_fields"]: {
		animation_offset?:number,
	chapter_id?:number,
	chapter_img_id?:number,
	duration?:number,
	effect_type?:number,
	end_x?:number,
	end_y?:number,
	id?:number,
	strength?:number
};
	/** order by stddev() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_stddev_order_by"]: GraphQLTypes["voyce_chapter_effects_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_chapter_effects_stddev_pop_fields"]: {
		animation_offset?:number,
	chapter_id?:number,
	chapter_img_id?:number,
	duration?:number,
	effect_type?:number,
	end_x?:number,
	end_y?:number,
	id?:number,
	strength?:number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_stddev_pop_order_by"]: GraphQLTypes["voyce_chapter_effects_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_chapter_effects_stddev_samp_fields"]: {
		animation_offset?:number,
	chapter_id?:number,
	chapter_img_id?:number,
	duration?:number,
	effect_type?:number,
	end_x?:number,
	end_y?:number,
	id?:number,
	strength?:number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_stddev_samp_order_by"]: GraphQLTypes["voyce_chapter_effects_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_chapter_effects" */
["voyce_chapter_effects_stream_cursor_input"]: GraphQLTypes["voyce_chapter_effects_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_effects_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_effects_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_effects_sum_fields"]: {
		animation_offset?:number,
	chapter_id?:number,
	chapter_img_id?:number,
	duration?:number,
	effect_type?:number,
	end_x?:number,
	end_y?:number,
	id?:number,
	strength?:number
};
	/** order by sum() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_sum_order_by"]: GraphQLTypes["voyce_chapter_effects_sum_order_by"];
	/** update columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_update_column"]: GraphQLTypes["voyce_chapter_effects_update_column"];
	["voyce_chapter_effects_updates"]: GraphQLTypes["voyce_chapter_effects_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_effects_var_pop_fields"]: {
		animation_offset?:number,
	chapter_id?:number,
	chapter_img_id?:number,
	duration?:number,
	effect_type?:number,
	end_x?:number,
	end_y?:number,
	id?:number,
	strength?:number
};
	/** order by var_pop() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_var_pop_order_by"]: GraphQLTypes["voyce_chapter_effects_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_chapter_effects_var_samp_fields"]: {
		animation_offset?:number,
	chapter_id?:number,
	chapter_img_id?:number,
	duration?:number,
	effect_type?:number,
	end_x?:number,
	end_y?:number,
	id?:number,
	strength?:number
};
	/** order by var_samp() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_var_samp_order_by"]: GraphQLTypes["voyce_chapter_effects_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_chapter_effects_variance_fields"]: {
		animation_offset?:number,
	chapter_id?:number,
	chapter_img_id?:number,
	duration?:number,
	effect_type?:number,
	end_x?:number,
	end_y?:number,
	id?:number,
	strength?:number
};
	/** order by variance() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_variance_order_by"]: GraphQLTypes["voyce_chapter_effects_variance_order_by"];
	/** columns and relationships of "voyce.chapter_images" */
["voyce_chapter_images"]: {
		/** An object relationship */
	chapter?:ModelTypes["voyce_chapters"],
	/** An array relationship */
	chapter_effects:ModelTypes["voyce_chapter_effects"][],
	/** An aggregate relationship */
	chapter_effects_aggregate:ModelTypes["voyce_chapter_effects_aggregate"],
	chapter_id:number,
	/** An array relationship */
	chapter_panels:ModelTypes["voyce_chapter_panels"][],
	/** An aggregate relationship */
	chapter_panels_aggregate:ModelTypes["voyce_chapter_panels_aggregate"],
	created_at:ModelTypes["timestamp"],
	group:ModelTypes["smallint"],
	id:number,
	image:string,
	sort_order?:number,
	updated_at?:ModelTypes["timestamp"]
};
	/** columns and relationships of "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamp"],
	group?:ModelTypes["smallint"],
	id?:number,
	image?:string,
	sort_order?:number,
	updated_at?:ModelTypes["timestamp"]
};
	/** aggregated selection of "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_images_07252024_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_images_07252024"][]
};
	/** aggregate fields of "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_images_07252024_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_images_07252024_max_fields"],
	min?:ModelTypes["voyce_chapter_images_07252024_min_fields"],
	stddev?:ModelTypes["voyce_chapter_images_07252024_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_images_07252024_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_images_07252024_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_images_07252024_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_images_07252024_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_images_07252024_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_images_07252024_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapter_images_07252024_avg_fields"]: {
		chapter_id?:number,
	group?:number,
	id?:number,
	sort_order?:number
};
	/** Boolean expression to filter rows from the table "voyce.chapter_images_07252024". All fields are combined with a logical 'AND'. */
["voyce_chapter_images_07252024_bool_exp"]: GraphQLTypes["voyce_chapter_images_07252024_bool_exp"];
	/** input type for incrementing numeric columns in table "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_inc_input"]: GraphQLTypes["voyce_chapter_images_07252024_inc_input"];
	/** input type for inserting data into table "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_insert_input"]: GraphQLTypes["voyce_chapter_images_07252024_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_images_07252024_max_fields"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamp"],
	group?:ModelTypes["smallint"],
	id?:number,
	image?:string,
	sort_order?:number,
	updated_at?:ModelTypes["timestamp"]
};
	/** aggregate min on columns */
["voyce_chapter_images_07252024_min_fields"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamp"],
	group?:ModelTypes["smallint"],
	id?:number,
	image?:string,
	sort_order?:number,
	updated_at?:ModelTypes["timestamp"]
};
	/** response of any mutation on the table "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_images_07252024"][]
};
	/** Ordering options when selecting data from "voyce.chapter_images_07252024". */
["voyce_chapter_images_07252024_order_by"]: GraphQLTypes["voyce_chapter_images_07252024_order_by"];
	/** select columns of table "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_select_column"]: GraphQLTypes["voyce_chapter_images_07252024_select_column"];
	/** input type for updating data in table "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_set_input"]: GraphQLTypes["voyce_chapter_images_07252024_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_images_07252024_stddev_fields"]: {
		chapter_id?:number,
	group?:number,
	id?:number,
	sort_order?:number
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_images_07252024_stddev_pop_fields"]: {
		chapter_id?:number,
	group?:number,
	id?:number,
	sort_order?:number
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_images_07252024_stddev_samp_fields"]: {
		chapter_id?:number,
	group?:number,
	id?:number,
	sort_order?:number
};
	/** Streaming cursor of the table "voyce_chapter_images_07252024" */
["voyce_chapter_images_07252024_stream_cursor_input"]: GraphQLTypes["voyce_chapter_images_07252024_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_images_07252024_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_images_07252024_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_images_07252024_sum_fields"]: {
		chapter_id?:number,
	group?:ModelTypes["smallint"],
	id?:number,
	sort_order?:number
};
	["voyce_chapter_images_07252024_updates"]: GraphQLTypes["voyce_chapter_images_07252024_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_images_07252024_var_pop_fields"]: {
		chapter_id?:number,
	group?:number,
	id?:number,
	sort_order?:number
};
	/** aggregate var_samp on columns */
["voyce_chapter_images_07252024_var_samp_fields"]: {
		chapter_id?:number,
	group?:number,
	id?:number,
	sort_order?:number
};
	/** aggregate variance on columns */
["voyce_chapter_images_07252024_variance_fields"]: {
		chapter_id?:number,
	group?:number,
	id?:number,
	sort_order?:number
};
	/** aggregated selection of "voyce.chapter_images" */
["voyce_chapter_images_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_images_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_images"][]
};
	["voyce_chapter_images_aggregate_bool_exp"]: GraphQLTypes["voyce_chapter_images_aggregate_bool_exp"];
	["voyce_chapter_images_aggregate_bool_exp_count"]: GraphQLTypes["voyce_chapter_images_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.chapter_images" */
["voyce_chapter_images_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_images_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_images_max_fields"],
	min?:ModelTypes["voyce_chapter_images_min_fields"],
	stddev?:ModelTypes["voyce_chapter_images_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_images_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_images_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_images_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_images_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_images_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_images_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_images" */
["voyce_chapter_images_aggregate_order_by"]: GraphQLTypes["voyce_chapter_images_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.chapter_images" */
["voyce_chapter_images_arr_rel_insert_input"]: GraphQLTypes["voyce_chapter_images_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_chapter_images_avg_fields"]: {
		chapter_id?:number,
	group?:number,
	id?:number,
	sort_order?:number
};
	/** order by avg() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_avg_order_by"]: GraphQLTypes["voyce_chapter_images_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.chapter_images". All fields are combined with a logical 'AND'. */
["voyce_chapter_images_bool_exp"]: GraphQLTypes["voyce_chapter_images_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_images" */
["voyce_chapter_images_constraint"]: GraphQLTypes["voyce_chapter_images_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_images" */
["voyce_chapter_images_inc_input"]: GraphQLTypes["voyce_chapter_images_inc_input"];
	/** input type for inserting data into table "voyce.chapter_images" */
["voyce_chapter_images_insert_input"]: GraphQLTypes["voyce_chapter_images_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_images_max_fields"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamp"],
	group?:ModelTypes["smallint"],
	id?:number,
	image?:string,
	sort_order?:number,
	updated_at?:ModelTypes["timestamp"]
};
	/** order by max() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_max_order_by"]: GraphQLTypes["voyce_chapter_images_max_order_by"];
	/** aggregate min on columns */
["voyce_chapter_images_min_fields"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamp"],
	group?:ModelTypes["smallint"],
	id?:number,
	image?:string,
	sort_order?:number,
	updated_at?:ModelTypes["timestamp"]
};
	/** order by min() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_min_order_by"]: GraphQLTypes["voyce_chapter_images_min_order_by"];
	/** response of any mutation on the table "voyce.chapter_images" */
["voyce_chapter_images_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_images"][]
};
	/** input type for inserting object relation for remote table "voyce.chapter_images" */
["voyce_chapter_images_obj_rel_insert_input"]: GraphQLTypes["voyce_chapter_images_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.chapter_images" */
["voyce_chapter_images_on_conflict"]: GraphQLTypes["voyce_chapter_images_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_images". */
["voyce_chapter_images_order_by"]: GraphQLTypes["voyce_chapter_images_order_by"];
	/** primary key columns input for table: voyce.chapter_images */
["voyce_chapter_images_pk_columns_input"]: GraphQLTypes["voyce_chapter_images_pk_columns_input"];
	/** select columns of table "voyce.chapter_images" */
["voyce_chapter_images_select_column"]: GraphQLTypes["voyce_chapter_images_select_column"];
	/** input type for updating data in table "voyce.chapter_images" */
["voyce_chapter_images_set_input"]: GraphQLTypes["voyce_chapter_images_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_images_stddev_fields"]: {
		chapter_id?:number,
	group?:number,
	id?:number,
	sort_order?:number
};
	/** order by stddev() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_stddev_order_by"]: GraphQLTypes["voyce_chapter_images_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_chapter_images_stddev_pop_fields"]: {
		chapter_id?:number,
	group?:number,
	id?:number,
	sort_order?:number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_stddev_pop_order_by"]: GraphQLTypes["voyce_chapter_images_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_chapter_images_stddev_samp_fields"]: {
		chapter_id?:number,
	group?:number,
	id?:number,
	sort_order?:number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_stddev_samp_order_by"]: GraphQLTypes["voyce_chapter_images_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_chapter_images" */
["voyce_chapter_images_stream_cursor_input"]: GraphQLTypes["voyce_chapter_images_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_images_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_images_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_images_sum_fields"]: {
		chapter_id?:number,
	group?:ModelTypes["smallint"],
	id?:number,
	sort_order?:number
};
	/** order by sum() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_sum_order_by"]: GraphQLTypes["voyce_chapter_images_sum_order_by"];
	/** update columns of table "voyce.chapter_images" */
["voyce_chapter_images_update_column"]: GraphQLTypes["voyce_chapter_images_update_column"];
	["voyce_chapter_images_updates"]: GraphQLTypes["voyce_chapter_images_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_images_var_pop_fields"]: {
		chapter_id?:number,
	group?:number,
	id?:number,
	sort_order?:number
};
	/** order by var_pop() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_var_pop_order_by"]: GraphQLTypes["voyce_chapter_images_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_chapter_images_var_samp_fields"]: {
		chapter_id?:number,
	group?:number,
	id?:number,
	sort_order?:number
};
	/** order by var_samp() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_var_samp_order_by"]: GraphQLTypes["voyce_chapter_images_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_chapter_images_variance_fields"]: {
		chapter_id?:number,
	group?:number,
	id?:number,
	sort_order?:number
};
	/** order by variance() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_variance_order_by"]: GraphQLTypes["voyce_chapter_images_variance_order_by"];
	/** columns and relationships of "voyce.chapter_likes" */
["voyce_chapter_likes"]: {
		/** An object relationship */
	chapter?:ModelTypes["voyce_chapters"],
	chapter_id:number,
	created_at:ModelTypes["timestamptz"],
	id:number,
	is_poll_like:boolean,
	series_id:number,
	/** An object relationship */
	user?:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.chapter_likes" */
["voyce_chapter_likes_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_likes_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_likes"][]
};
	["voyce_chapter_likes_aggregate_bool_exp"]: GraphQLTypes["voyce_chapter_likes_aggregate_bool_exp"];
	["voyce_chapter_likes_aggregate_bool_exp_bool_and"]: GraphQLTypes["voyce_chapter_likes_aggregate_bool_exp_bool_and"];
	["voyce_chapter_likes_aggregate_bool_exp_bool_or"]: GraphQLTypes["voyce_chapter_likes_aggregate_bool_exp_bool_or"];
	["voyce_chapter_likes_aggregate_bool_exp_count"]: GraphQLTypes["voyce_chapter_likes_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.chapter_likes" */
["voyce_chapter_likes_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_likes_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_likes_max_fields"],
	min?:ModelTypes["voyce_chapter_likes_min_fields"],
	stddev?:ModelTypes["voyce_chapter_likes_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_likes_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_likes_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_likes_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_likes_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_likes_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_likes_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_likes" */
["voyce_chapter_likes_aggregate_order_by"]: GraphQLTypes["voyce_chapter_likes_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.chapter_likes" */
["voyce_chapter_likes_arr_rel_insert_input"]: GraphQLTypes["voyce_chapter_likes_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_chapter_likes_avg_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_avg_order_by"]: GraphQLTypes["voyce_chapter_likes_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.chapter_likes". All fields are combined with a logical 'AND'. */
["voyce_chapter_likes_bool_exp"]: GraphQLTypes["voyce_chapter_likes_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_likes" */
["voyce_chapter_likes_constraint"]: GraphQLTypes["voyce_chapter_likes_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_likes" */
["voyce_chapter_likes_inc_input"]: GraphQLTypes["voyce_chapter_likes_inc_input"];
	/** input type for inserting data into table "voyce.chapter_likes" */
["voyce_chapter_likes_insert_input"]: GraphQLTypes["voyce_chapter_likes_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_likes_max_fields"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by max() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_max_order_by"]: GraphQLTypes["voyce_chapter_likes_max_order_by"];
	/** aggregate min on columns */
["voyce_chapter_likes_min_fields"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by min() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_min_order_by"]: GraphQLTypes["voyce_chapter_likes_min_order_by"];
	/** response of any mutation on the table "voyce.chapter_likes" */
["voyce_chapter_likes_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_likes"][]
};
	/** on_conflict condition type for table "voyce.chapter_likes" */
["voyce_chapter_likes_on_conflict"]: GraphQLTypes["voyce_chapter_likes_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_likes". */
["voyce_chapter_likes_order_by"]: GraphQLTypes["voyce_chapter_likes_order_by"];
	/** primary key columns input for table: voyce.chapter_likes */
["voyce_chapter_likes_pk_columns_input"]: GraphQLTypes["voyce_chapter_likes_pk_columns_input"];
	/** select columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_select_column"]: GraphQLTypes["voyce_chapter_likes_select_column"];
	/** select "voyce_chapter_likes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_and_arguments_columns"]: GraphQLTypes["voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_and_arguments_columns"];
	/** select "voyce_chapter_likes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_or_arguments_columns"]: GraphQLTypes["voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_or_arguments_columns"];
	/** input type for updating data in table "voyce.chapter_likes" */
["voyce_chapter_likes_set_input"]: GraphQLTypes["voyce_chapter_likes_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_likes_stddev_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_stddev_order_by"]: GraphQLTypes["voyce_chapter_likes_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_chapter_likes_stddev_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_stddev_pop_order_by"]: GraphQLTypes["voyce_chapter_likes_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_chapter_likes_stddev_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_stddev_samp_order_by"]: GraphQLTypes["voyce_chapter_likes_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_chapter_likes" */
["voyce_chapter_likes_stream_cursor_input"]: GraphQLTypes["voyce_chapter_likes_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_likes_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_likes_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_likes_sum_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_sum_order_by"]: GraphQLTypes["voyce_chapter_likes_sum_order_by"];
	/** update columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_update_column"]: GraphQLTypes["voyce_chapter_likes_update_column"];
	["voyce_chapter_likes_updates"]: GraphQLTypes["voyce_chapter_likes_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_likes_var_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_var_pop_order_by"]: GraphQLTypes["voyce_chapter_likes_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_chapter_likes_var_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_var_samp_order_by"]: GraphQLTypes["voyce_chapter_likes_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_chapter_likes_variance_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_variance_order_by"]: GraphQLTypes["voyce_chapter_likes_variance_order_by"];
	/** columns and relationships of "voyce.chapter_locks" */
["voyce_chapter_locks"]: {
		buy_currency?:string,
	buy_price?:ModelTypes["numeric"],
	/** An object relationship */
	chapter:ModelTypes["voyce_chapters"],
	chapter_id:number,
	created_at:ModelTypes["timestamptz"],
	creator_id:number,
	end_date?:ModelTypes["timestamptz"],
	id:number,
	is_active:boolean,
	rent_currency?:string,
	rent_duration?:ModelTypes["numeric"],
	rent_price?:ModelTypes["numeric"],
	/** An object relationship */
	series:ModelTypes["voyce_series"],
	series_id:number,
	start_date:ModelTypes["timestamptz"],
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.chapter_locks" */
["voyce_chapter_locks_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_locks_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_locks"][]
};
	["voyce_chapter_locks_aggregate_bool_exp"]: GraphQLTypes["voyce_chapter_locks_aggregate_bool_exp"];
	["voyce_chapter_locks_aggregate_bool_exp_bool_and"]: GraphQLTypes["voyce_chapter_locks_aggregate_bool_exp_bool_and"];
	["voyce_chapter_locks_aggregate_bool_exp_bool_or"]: GraphQLTypes["voyce_chapter_locks_aggregate_bool_exp_bool_or"];
	["voyce_chapter_locks_aggregate_bool_exp_count"]: GraphQLTypes["voyce_chapter_locks_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.chapter_locks" */
["voyce_chapter_locks_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_locks_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_locks_max_fields"],
	min?:ModelTypes["voyce_chapter_locks_min_fields"],
	stddev?:ModelTypes["voyce_chapter_locks_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_locks_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_locks_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_locks_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_locks_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_locks_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_locks_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_locks" */
["voyce_chapter_locks_aggregate_order_by"]: GraphQLTypes["voyce_chapter_locks_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.chapter_locks" */
["voyce_chapter_locks_arr_rel_insert_input"]: GraphQLTypes["voyce_chapter_locks_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_chapter_locks_avg_fields"]: {
		buy_price?:number,
	chapter_id?:number,
	creator_id?:number,
	id?:number,
	rent_duration?:number,
	rent_price?:number,
	series_id?:number
};
	/** order by avg() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_avg_order_by"]: GraphQLTypes["voyce_chapter_locks_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.chapter_locks". All fields are combined with a logical 'AND'. */
["voyce_chapter_locks_bool_exp"]: GraphQLTypes["voyce_chapter_locks_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_locks" */
["voyce_chapter_locks_constraint"]: GraphQLTypes["voyce_chapter_locks_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_locks" */
["voyce_chapter_locks_inc_input"]: GraphQLTypes["voyce_chapter_locks_inc_input"];
	/** input type for inserting data into table "voyce.chapter_locks" */
["voyce_chapter_locks_insert_input"]: GraphQLTypes["voyce_chapter_locks_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_locks_max_fields"]: {
		buy_currency?:string,
	buy_price?:ModelTypes["numeric"],
	chapter_id?:number,
	created_at?:ModelTypes["timestamptz"],
	creator_id?:number,
	end_date?:ModelTypes["timestamptz"],
	id?:number,
	rent_currency?:string,
	rent_duration?:ModelTypes["numeric"],
	rent_price?:ModelTypes["numeric"],
	series_id?:number,
	start_date?:ModelTypes["timestamptz"],
	updated_at?:ModelTypes["timestamptz"]
};
	/** order by max() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_max_order_by"]: GraphQLTypes["voyce_chapter_locks_max_order_by"];
	/** aggregate min on columns */
["voyce_chapter_locks_min_fields"]: {
		buy_currency?:string,
	buy_price?:ModelTypes["numeric"],
	chapter_id?:number,
	created_at?:ModelTypes["timestamptz"],
	creator_id?:number,
	end_date?:ModelTypes["timestamptz"],
	id?:number,
	rent_currency?:string,
	rent_duration?:ModelTypes["numeric"],
	rent_price?:ModelTypes["numeric"],
	series_id?:number,
	start_date?:ModelTypes["timestamptz"],
	updated_at?:ModelTypes["timestamptz"]
};
	/** order by min() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_min_order_by"]: GraphQLTypes["voyce_chapter_locks_min_order_by"];
	/** response of any mutation on the table "voyce.chapter_locks" */
["voyce_chapter_locks_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_locks"][]
};
	/** on_conflict condition type for table "voyce.chapter_locks" */
["voyce_chapter_locks_on_conflict"]: GraphQLTypes["voyce_chapter_locks_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_locks". */
["voyce_chapter_locks_order_by"]: GraphQLTypes["voyce_chapter_locks_order_by"];
	/** primary key columns input for table: voyce.chapter_locks */
["voyce_chapter_locks_pk_columns_input"]: GraphQLTypes["voyce_chapter_locks_pk_columns_input"];
	/** select columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_select_column"]: GraphQLTypes["voyce_chapter_locks_select_column"];
	/** select "voyce_chapter_locks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_and_arguments_columns"]: GraphQLTypes["voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_and_arguments_columns"];
	/** select "voyce_chapter_locks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_or_arguments_columns"]: GraphQLTypes["voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_or_arguments_columns"];
	/** input type for updating data in table "voyce.chapter_locks" */
["voyce_chapter_locks_set_input"]: GraphQLTypes["voyce_chapter_locks_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_locks_stddev_fields"]: {
		buy_price?:number,
	chapter_id?:number,
	creator_id?:number,
	id?:number,
	rent_duration?:number,
	rent_price?:number,
	series_id?:number
};
	/** order by stddev() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_stddev_order_by"]: GraphQLTypes["voyce_chapter_locks_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_chapter_locks_stddev_pop_fields"]: {
		buy_price?:number,
	chapter_id?:number,
	creator_id?:number,
	id?:number,
	rent_duration?:number,
	rent_price?:number,
	series_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_stddev_pop_order_by"]: GraphQLTypes["voyce_chapter_locks_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_chapter_locks_stddev_samp_fields"]: {
		buy_price?:number,
	chapter_id?:number,
	creator_id?:number,
	id?:number,
	rent_duration?:number,
	rent_price?:number,
	series_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_stddev_samp_order_by"]: GraphQLTypes["voyce_chapter_locks_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_chapter_locks" */
["voyce_chapter_locks_stream_cursor_input"]: GraphQLTypes["voyce_chapter_locks_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_locks_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_locks_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_locks_sum_fields"]: {
		buy_price?:ModelTypes["numeric"],
	chapter_id?:number,
	creator_id?:number,
	id?:number,
	rent_duration?:ModelTypes["numeric"],
	rent_price?:ModelTypes["numeric"],
	series_id?:number
};
	/** order by sum() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_sum_order_by"]: GraphQLTypes["voyce_chapter_locks_sum_order_by"];
	/** update columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_update_column"]: GraphQLTypes["voyce_chapter_locks_update_column"];
	["voyce_chapter_locks_updates"]: GraphQLTypes["voyce_chapter_locks_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_locks_var_pop_fields"]: {
		buy_price?:number,
	chapter_id?:number,
	creator_id?:number,
	id?:number,
	rent_duration?:number,
	rent_price?:number,
	series_id?:number
};
	/** order by var_pop() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_var_pop_order_by"]: GraphQLTypes["voyce_chapter_locks_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_chapter_locks_var_samp_fields"]: {
		buy_price?:number,
	chapter_id?:number,
	creator_id?:number,
	id?:number,
	rent_duration?:number,
	rent_price?:number,
	series_id?:number
};
	/** order by var_samp() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_var_samp_order_by"]: GraphQLTypes["voyce_chapter_locks_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_chapter_locks_variance_fields"]: {
		buy_price?:number,
	chapter_id?:number,
	creator_id?:number,
	id?:number,
	rent_duration?:number,
	rent_price?:number,
	series_id?:number
};
	/** order by variance() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_variance_order_by"]: GraphQLTypes["voyce_chapter_locks_variance_order_by"];
	/** columns and relationships of "voyce.chapter_panel_views" */
["voyce_chapter_panel_views"]: {
		/** An object relationship */
	chapter?:ModelTypes["voyce_chapters"],
	chapter_id?:number,
	current_panel:number,
	id:number,
	panel_id?:number,
	panels?:ModelTypes["bpchar"],
	percentage:number,
	/** An object relationship */
	series:ModelTypes["voyce_series"],
	series_id:number,
	total_panels:number,
	type:string,
	updated_at:ModelTypes["timestamptz"],
	user_id:number,
	word_idx:number
};
	/** aggregated selection of "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_panel_views_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_panel_views"][]
};
	/** aggregate fields of "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_panel_views_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_panel_views_max_fields"],
	min?:ModelTypes["voyce_chapter_panel_views_min_fields"],
	stddev?:ModelTypes["voyce_chapter_panel_views_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_panel_views_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_panel_views_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_panel_views_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_panel_views_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_panel_views_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_panel_views_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapter_panel_views_avg_fields"]: {
		chapter_id?:number,
	current_panel?:number,
	id?:number,
	panel_id?:number,
	percentage?:number,
	series_id?:number,
	total_panels?:number,
	user_id?:number,
	word_idx?:number
};
	/** Boolean expression to filter rows from the table "voyce.chapter_panel_views". All fields are combined with a logical 'AND'. */
["voyce_chapter_panel_views_bool_exp"]: GraphQLTypes["voyce_chapter_panel_views_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_constraint"]: GraphQLTypes["voyce_chapter_panel_views_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_inc_input"]: GraphQLTypes["voyce_chapter_panel_views_inc_input"];
	/** input type for inserting data into table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_insert_input"]: GraphQLTypes["voyce_chapter_panel_views_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_panel_views_max_fields"]: {
		chapter_id?:number,
	current_panel?:number,
	id?:number,
	panel_id?:number,
	panels?:ModelTypes["bpchar"],
	percentage?:number,
	series_id?:number,
	total_panels?:number,
	type?:string,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number,
	word_idx?:number
};
	/** aggregate min on columns */
["voyce_chapter_panel_views_min_fields"]: {
		chapter_id?:number,
	current_panel?:number,
	id?:number,
	panel_id?:number,
	panels?:ModelTypes["bpchar"],
	percentage?:number,
	series_id?:number,
	total_panels?:number,
	type?:string,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number,
	word_idx?:number
};
	/** response of any mutation on the table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_panel_views"][]
};
	/** on_conflict condition type for table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_on_conflict"]: GraphQLTypes["voyce_chapter_panel_views_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_panel_views". */
["voyce_chapter_panel_views_order_by"]: GraphQLTypes["voyce_chapter_panel_views_order_by"];
	/** primary key columns input for table: voyce.chapter_panel_views */
["voyce_chapter_panel_views_pk_columns_input"]: GraphQLTypes["voyce_chapter_panel_views_pk_columns_input"];
	/** select columns of table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_select_column"]: GraphQLTypes["voyce_chapter_panel_views_select_column"];
	/** input type for updating data in table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_set_input"]: GraphQLTypes["voyce_chapter_panel_views_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_panel_views_stddev_fields"]: {
		chapter_id?:number,
	current_panel?:number,
	id?:number,
	panel_id?:number,
	percentage?:number,
	series_id?:number,
	total_panels?:number,
	user_id?:number,
	word_idx?:number
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_panel_views_stddev_pop_fields"]: {
		chapter_id?:number,
	current_panel?:number,
	id?:number,
	panel_id?:number,
	percentage?:number,
	series_id?:number,
	total_panels?:number,
	user_id?:number,
	word_idx?:number
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_panel_views_stddev_samp_fields"]: {
		chapter_id?:number,
	current_panel?:number,
	id?:number,
	panel_id?:number,
	percentage?:number,
	series_id?:number,
	total_panels?:number,
	user_id?:number,
	word_idx?:number
};
	/** Streaming cursor of the table "voyce_chapter_panel_views" */
["voyce_chapter_panel_views_stream_cursor_input"]: GraphQLTypes["voyce_chapter_panel_views_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_panel_views_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_panel_views_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_panel_views_sum_fields"]: {
		chapter_id?:number,
	current_panel?:number,
	id?:number,
	panel_id?:number,
	percentage?:number,
	series_id?:number,
	total_panels?:number,
	user_id?:number,
	word_idx?:number
};
	/** update columns of table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_update_column"]: GraphQLTypes["voyce_chapter_panel_views_update_column"];
	["voyce_chapter_panel_views_updates"]: GraphQLTypes["voyce_chapter_panel_views_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_panel_views_var_pop_fields"]: {
		chapter_id?:number,
	current_panel?:number,
	id?:number,
	panel_id?:number,
	percentage?:number,
	series_id?:number,
	total_panels?:number,
	user_id?:number,
	word_idx?:number
};
	/** aggregate var_samp on columns */
["voyce_chapter_panel_views_var_samp_fields"]: {
		chapter_id?:number,
	current_panel?:number,
	id?:number,
	panel_id?:number,
	percentage?:number,
	series_id?:number,
	total_panels?:number,
	user_id?:number,
	word_idx?:number
};
	/** aggregate variance on columns */
["voyce_chapter_panel_views_variance_fields"]: {
		chapter_id?:number,
	current_panel?:number,
	id?:number,
	panel_id?:number,
	percentage?:number,
	series_id?:number,
	total_panels?:number,
	user_id?:number,
	word_idx?:number
};
	/** columns and relationships of "voyce.chapter_panels" */
["voyce_chapter_panels"]: {
		/** An object relationship */
	chapter:ModelTypes["voyce_chapters"],
	chapter_id:number,
	comments_count?:number,
	created_at:ModelTypes["timestamptz"],
	id:number,
	image:string,
	image_id?:number,
	last_activity_date:ModelTypes["timestamptz"],
	likes_count?:number,
	outdated:boolean,
	/** An array relationship */
	panels_comments:ModelTypes["voyce_panels_comments"][],
	/** An aggregate relationship */
	panels_comments_aggregate:ModelTypes["voyce_panels_comments_aggregate"],
	/** An array relationship */
	panels_reactions:ModelTypes["voyce_panels_reactions"][],
	/** An aggregate relationship */
	panels_reactions_aggregate:ModelTypes["voyce_panels_reactions_aggregate"],
	/** An array relationship */
	panels_reactions_counts:ModelTypes["voyce_panels_reactions_count"][],
	/** An aggregate relationship */
	panels_reactions_counts_aggregate:ModelTypes["voyce_panels_reactions_count_aggregate"],
	popularity:number,
	popularity_weekly:number,
	reactions_count?:number,
	/** An object relationship */
	series:ModelTypes["voyce_series"],
	series_id:number,
	spoiler:boolean,
	total_popularity:number
};
	/** aggregated selection of "voyce.chapter_panels" */
["voyce_chapter_panels_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_panels_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_panels"][]
};
	["voyce_chapter_panels_aggregate_bool_exp"]: GraphQLTypes["voyce_chapter_panels_aggregate_bool_exp"];
	["voyce_chapter_panels_aggregate_bool_exp_bool_and"]: GraphQLTypes["voyce_chapter_panels_aggregate_bool_exp_bool_and"];
	["voyce_chapter_panels_aggregate_bool_exp_bool_or"]: GraphQLTypes["voyce_chapter_panels_aggregate_bool_exp_bool_or"];
	["voyce_chapter_panels_aggregate_bool_exp_count"]: GraphQLTypes["voyce_chapter_panels_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.chapter_panels" */
["voyce_chapter_panels_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_panels_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_panels_max_fields"],
	min?:ModelTypes["voyce_chapter_panels_min_fields"],
	stddev?:ModelTypes["voyce_chapter_panels_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_panels_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_panels_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_panels_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_panels_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_panels_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_panels_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_panels" */
["voyce_chapter_panels_aggregate_order_by"]: GraphQLTypes["voyce_chapter_panels_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.chapter_panels" */
["voyce_chapter_panels_arr_rel_insert_input"]: GraphQLTypes["voyce_chapter_panels_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_chapter_panels_avg_fields"]: {
		chapter_id?:number,
	comments_count?:number,
	id?:number,
	image_id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	series_id?:number,
	total_popularity?:number
};
	/** order by avg() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_avg_order_by"]: GraphQLTypes["voyce_chapter_panels_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.chapter_panels". All fields are combined with a logical 'AND'. */
["voyce_chapter_panels_bool_exp"]: GraphQLTypes["voyce_chapter_panels_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_panels" */
["voyce_chapter_panels_constraint"]: GraphQLTypes["voyce_chapter_panels_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_panels" */
["voyce_chapter_panels_inc_input"]: GraphQLTypes["voyce_chapter_panels_inc_input"];
	/** input type for inserting data into table "voyce.chapter_panels" */
["voyce_chapter_panels_insert_input"]: GraphQLTypes["voyce_chapter_panels_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_panels_max_fields"]: {
		chapter_id?:number,
	comments_count?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	image?:string,
	image_id?:number,
	last_activity_date?:ModelTypes["timestamptz"],
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	series_id?:number,
	total_popularity?:number
};
	/** order by max() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_max_order_by"]: GraphQLTypes["voyce_chapter_panels_max_order_by"];
	/** aggregate min on columns */
["voyce_chapter_panels_min_fields"]: {
		chapter_id?:number,
	comments_count?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	image?:string,
	image_id?:number,
	last_activity_date?:ModelTypes["timestamptz"],
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	series_id?:number,
	total_popularity?:number
};
	/** order by min() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_min_order_by"]: GraphQLTypes["voyce_chapter_panels_min_order_by"];
	/** response of any mutation on the table "voyce.chapter_panels" */
["voyce_chapter_panels_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_panels"][]
};
	/** input type for inserting object relation for remote table "voyce.chapter_panels" */
["voyce_chapter_panels_obj_rel_insert_input"]: GraphQLTypes["voyce_chapter_panels_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.chapter_panels" */
["voyce_chapter_panels_on_conflict"]: GraphQLTypes["voyce_chapter_panels_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_panels". */
["voyce_chapter_panels_order_by"]: GraphQLTypes["voyce_chapter_panels_order_by"];
	/** primary key columns input for table: voyce.chapter_panels */
["voyce_chapter_panels_pk_columns_input"]: GraphQLTypes["voyce_chapter_panels_pk_columns_input"];
	/** select columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_select_column"]: GraphQLTypes["voyce_chapter_panels_select_column"];
	/** select "voyce_chapter_panels_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_and_arguments_columns"]: GraphQLTypes["voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_and_arguments_columns"];
	/** select "voyce_chapter_panels_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_or_arguments_columns"]: GraphQLTypes["voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_or_arguments_columns"];
	/** input type for updating data in table "voyce.chapter_panels" */
["voyce_chapter_panels_set_input"]: GraphQLTypes["voyce_chapter_panels_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_panels_stddev_fields"]: {
		chapter_id?:number,
	comments_count?:number,
	id?:number,
	image_id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	series_id?:number,
	total_popularity?:number
};
	/** order by stddev() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_stddev_order_by"]: GraphQLTypes["voyce_chapter_panels_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_chapter_panels_stddev_pop_fields"]: {
		chapter_id?:number,
	comments_count?:number,
	id?:number,
	image_id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	series_id?:number,
	total_popularity?:number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_stddev_pop_order_by"]: GraphQLTypes["voyce_chapter_panels_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_chapter_panels_stddev_samp_fields"]: {
		chapter_id?:number,
	comments_count?:number,
	id?:number,
	image_id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	series_id?:number,
	total_popularity?:number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_stddev_samp_order_by"]: GraphQLTypes["voyce_chapter_panels_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_chapter_panels" */
["voyce_chapter_panels_stream_cursor_input"]: GraphQLTypes["voyce_chapter_panels_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_panels_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_panels_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_panels_sum_fields"]: {
		chapter_id?:number,
	comments_count?:number,
	id?:number,
	image_id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	series_id?:number,
	total_popularity?:number
};
	/** order by sum() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_sum_order_by"]: GraphQLTypes["voyce_chapter_panels_sum_order_by"];
	/** update columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_update_column"]: GraphQLTypes["voyce_chapter_panels_update_column"];
	["voyce_chapter_panels_updates"]: GraphQLTypes["voyce_chapter_panels_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_panels_var_pop_fields"]: {
		chapter_id?:number,
	comments_count?:number,
	id?:number,
	image_id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	series_id?:number,
	total_popularity?:number
};
	/** order by var_pop() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_var_pop_order_by"]: GraphQLTypes["voyce_chapter_panels_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_chapter_panels_var_samp_fields"]: {
		chapter_id?:number,
	comments_count?:number,
	id?:number,
	image_id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	series_id?:number,
	total_popularity?:number
};
	/** order by var_samp() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_var_samp_order_by"]: GraphQLTypes["voyce_chapter_panels_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_chapter_panels_variance_fields"]: {
		chapter_id?:number,
	comments_count?:number,
	id?:number,
	image_id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	series_id?:number,
	total_popularity?:number
};
	/** order by variance() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_variance_order_by"]: GraphQLTypes["voyce_chapter_panels_variance_order_by"];
	/** columns and relationships of "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules"]: {
		chapter_id:number,
	created_at:ModelTypes["timestamptz"],
	date:ModelTypes["date"],
	/** in seconds */
	epoch:ModelTypes["numeric"],
	gmt_offset:ModelTypes["numeric"],
	id:number,
	is_done:ModelTypes["numeric"],
	publish_date?:ModelTypes["timestamp"],
	/** An object relationship */
	series:ModelTypes["voyce_series"],
	series_id:number,
	time:ModelTypes["time"]
};
	/** aggregated selection of "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_publish_schedules_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_publish_schedules"][]
};
	["voyce_chapter_publish_schedules_aggregate_bool_exp"]: GraphQLTypes["voyce_chapter_publish_schedules_aggregate_bool_exp"];
	["voyce_chapter_publish_schedules_aggregate_bool_exp_count"]: GraphQLTypes["voyce_chapter_publish_schedules_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_publish_schedules_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_publish_schedules_max_fields"],
	min?:ModelTypes["voyce_chapter_publish_schedules_min_fields"],
	stddev?:ModelTypes["voyce_chapter_publish_schedules_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_publish_schedules_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_publish_schedules_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_publish_schedules_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_publish_schedules_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_publish_schedules_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_publish_schedules_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_aggregate_order_by"]: GraphQLTypes["voyce_chapter_publish_schedules_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_arr_rel_insert_input"]: GraphQLTypes["voyce_chapter_publish_schedules_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_chapter_publish_schedules_avg_fields"]: {
		chapter_id?:number,
	/** in seconds */
	epoch?:number,
	gmt_offset?:number,
	id?:number,
	is_done?:number,
	series_id?:number
};
	/** order by avg() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_avg_order_by"]: GraphQLTypes["voyce_chapter_publish_schedules_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.chapter_publish_schedules". All fields are combined with a logical 'AND'. */
["voyce_chapter_publish_schedules_bool_exp"]: GraphQLTypes["voyce_chapter_publish_schedules_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_constraint"]: GraphQLTypes["voyce_chapter_publish_schedules_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_inc_input"]: GraphQLTypes["voyce_chapter_publish_schedules_inc_input"];
	/** input type for inserting data into table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_insert_input"]: GraphQLTypes["voyce_chapter_publish_schedules_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_publish_schedules_max_fields"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamptz"],
	date?:ModelTypes["date"],
	/** in seconds */
	epoch?:ModelTypes["numeric"],
	gmt_offset?:ModelTypes["numeric"],
	id?:number,
	is_done?:ModelTypes["numeric"],
	publish_date?:ModelTypes["timestamp"],
	series_id?:number
};
	/** order by max() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_max_order_by"]: GraphQLTypes["voyce_chapter_publish_schedules_max_order_by"];
	/** aggregate min on columns */
["voyce_chapter_publish_schedules_min_fields"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamptz"],
	date?:ModelTypes["date"],
	/** in seconds */
	epoch?:ModelTypes["numeric"],
	gmt_offset?:ModelTypes["numeric"],
	id?:number,
	is_done?:ModelTypes["numeric"],
	publish_date?:ModelTypes["timestamp"],
	series_id?:number
};
	/** order by min() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_min_order_by"]: GraphQLTypes["voyce_chapter_publish_schedules_min_order_by"];
	/** response of any mutation on the table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_publish_schedules"][]
};
	/** on_conflict condition type for table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_on_conflict"]: GraphQLTypes["voyce_chapter_publish_schedules_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_publish_schedules". */
["voyce_chapter_publish_schedules_order_by"]: GraphQLTypes["voyce_chapter_publish_schedules_order_by"];
	/** primary key columns input for table: voyce.chapter_publish_schedules */
["voyce_chapter_publish_schedules_pk_columns_input"]: GraphQLTypes["voyce_chapter_publish_schedules_pk_columns_input"];
	/** select columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_select_column"]: GraphQLTypes["voyce_chapter_publish_schedules_select_column"];
	/** input type for updating data in table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_set_input"]: GraphQLTypes["voyce_chapter_publish_schedules_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_publish_schedules_stddev_fields"]: {
		chapter_id?:number,
	/** in seconds */
	epoch?:number,
	gmt_offset?:number,
	id?:number,
	is_done?:number,
	series_id?:number
};
	/** order by stddev() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_stddev_order_by"]: GraphQLTypes["voyce_chapter_publish_schedules_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_chapter_publish_schedules_stddev_pop_fields"]: {
		chapter_id?:number,
	/** in seconds */
	epoch?:number,
	gmt_offset?:number,
	id?:number,
	is_done?:number,
	series_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_stddev_pop_order_by"]: GraphQLTypes["voyce_chapter_publish_schedules_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_chapter_publish_schedules_stddev_samp_fields"]: {
		chapter_id?:number,
	/** in seconds */
	epoch?:number,
	gmt_offset?:number,
	id?:number,
	is_done?:number,
	series_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_stddev_samp_order_by"]: GraphQLTypes["voyce_chapter_publish_schedules_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_chapter_publish_schedules" */
["voyce_chapter_publish_schedules_stream_cursor_input"]: GraphQLTypes["voyce_chapter_publish_schedules_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_publish_schedules_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_publish_schedules_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_publish_schedules_sum_fields"]: {
		chapter_id?:number,
	/** in seconds */
	epoch?:ModelTypes["numeric"],
	gmt_offset?:ModelTypes["numeric"],
	id?:number,
	is_done?:ModelTypes["numeric"],
	series_id?:number
};
	/** order by sum() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_sum_order_by"]: GraphQLTypes["voyce_chapter_publish_schedules_sum_order_by"];
	/** update columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_update_column"]: GraphQLTypes["voyce_chapter_publish_schedules_update_column"];
	["voyce_chapter_publish_schedules_updates"]: GraphQLTypes["voyce_chapter_publish_schedules_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_publish_schedules_var_pop_fields"]: {
		chapter_id?:number,
	/** in seconds */
	epoch?:number,
	gmt_offset?:number,
	id?:number,
	is_done?:number,
	series_id?:number
};
	/** order by var_pop() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_var_pop_order_by"]: GraphQLTypes["voyce_chapter_publish_schedules_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_chapter_publish_schedules_var_samp_fields"]: {
		chapter_id?:number,
	/** in seconds */
	epoch?:number,
	gmt_offset?:number,
	id?:number,
	is_done?:number,
	series_id?:number
};
	/** order by var_samp() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_var_samp_order_by"]: GraphQLTypes["voyce_chapter_publish_schedules_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_chapter_publish_schedules_variance_fields"]: {
		chapter_id?:number,
	/** in seconds */
	epoch?:number,
	gmt_offset?:number,
	id?:number,
	is_done?:number,
	series_id?:number
};
	/** order by variance() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_variance_order_by"]: GraphQLTypes["voyce_chapter_publish_schedules_variance_order_by"];
	/** columns and relationships of "voyce.chapter_reactions" */
["voyce_chapter_reactions"]: {
		/** An object relationship */
	chapter:ModelTypes["voyce_chapters"],
	created_at:ModelTypes["timestamptz"],
	id:number,
	linked_entity_id:number,
	reaction:number,
	/** An object relationship */
	user?:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.chapter_reactions" */
["voyce_chapter_reactions_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_reactions_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_reactions"][]
};
	["voyce_chapter_reactions_aggregate_bool_exp"]: GraphQLTypes["voyce_chapter_reactions_aggregate_bool_exp"];
	["voyce_chapter_reactions_aggregate_bool_exp_count"]: GraphQLTypes["voyce_chapter_reactions_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.chapter_reactions" */
["voyce_chapter_reactions_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_reactions_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_reactions_max_fields"],
	min?:ModelTypes["voyce_chapter_reactions_min_fields"],
	stddev?:ModelTypes["voyce_chapter_reactions_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_reactions_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_reactions_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_reactions_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_reactions_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_reactions_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_reactions_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_aggregate_order_by"]: GraphQLTypes["voyce_chapter_reactions_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.chapter_reactions" */
["voyce_chapter_reactions_arr_rel_insert_input"]: GraphQLTypes["voyce_chapter_reactions_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_chapter_reactions_avg_fields"]: {
		id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_avg_order_by"]: GraphQLTypes["voyce_chapter_reactions_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.chapter_reactions". All fields are combined with a logical 'AND'. */
["voyce_chapter_reactions_bool_exp"]: GraphQLTypes["voyce_chapter_reactions_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_reactions" */
["voyce_chapter_reactions_constraint"]: GraphQLTypes["voyce_chapter_reactions_constraint"];
	/** Table to aggregate chapter reactions count by reaction type */
["voyce_chapter_reactions_count"]: {
		/** An object relationship */
	chapter:ModelTypes["voyce_chapters"],
	chapter_id:number,
	count?:number,
	id:number,
	reaction?:number
};
	/** aggregated selection of "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_reactions_count_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_reactions_count"][]
};
	/** aggregate fields of "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_reactions_count_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_reactions_count_max_fields"],
	min?:ModelTypes["voyce_chapter_reactions_count_min_fields"],
	stddev?:ModelTypes["voyce_chapter_reactions_count_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_reactions_count_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_reactions_count_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_reactions_count_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_reactions_count_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_reactions_count_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_reactions_count_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapter_reactions_count_avg_fields"]: {
		chapter_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** Boolean expression to filter rows from the table "voyce.chapter_reactions_count". All fields are combined with a logical 'AND'. */
["voyce_chapter_reactions_count_bool_exp"]: GraphQLTypes["voyce_chapter_reactions_count_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_constraint"]: GraphQLTypes["voyce_chapter_reactions_count_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_inc_input"]: GraphQLTypes["voyce_chapter_reactions_count_inc_input"];
	/** input type for inserting data into table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_insert_input"]: GraphQLTypes["voyce_chapter_reactions_count_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_reactions_count_max_fields"]: {
		chapter_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** aggregate min on columns */
["voyce_chapter_reactions_count_min_fields"]: {
		chapter_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** response of any mutation on the table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_reactions_count"][]
};
	/** on_conflict condition type for table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_on_conflict"]: GraphQLTypes["voyce_chapter_reactions_count_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_reactions_count". */
["voyce_chapter_reactions_count_order_by"]: GraphQLTypes["voyce_chapter_reactions_count_order_by"];
	/** primary key columns input for table: voyce.chapter_reactions_count */
["voyce_chapter_reactions_count_pk_columns_input"]: GraphQLTypes["voyce_chapter_reactions_count_pk_columns_input"];
	/** select columns of table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_select_column"]: GraphQLTypes["voyce_chapter_reactions_count_select_column"];
	/** input type for updating data in table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_set_input"]: GraphQLTypes["voyce_chapter_reactions_count_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_reactions_count_stddev_fields"]: {
		chapter_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_reactions_count_stddev_pop_fields"]: {
		chapter_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_reactions_count_stddev_samp_fields"]: {
		chapter_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** Streaming cursor of the table "voyce_chapter_reactions_count" */
["voyce_chapter_reactions_count_stream_cursor_input"]: GraphQLTypes["voyce_chapter_reactions_count_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_reactions_count_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_reactions_count_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_reactions_count_sum_fields"]: {
		chapter_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** update columns of table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_update_column"]: GraphQLTypes["voyce_chapter_reactions_count_update_column"];
	["voyce_chapter_reactions_count_updates"]: GraphQLTypes["voyce_chapter_reactions_count_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_reactions_count_var_pop_fields"]: {
		chapter_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** aggregate var_samp on columns */
["voyce_chapter_reactions_count_var_samp_fields"]: {
		chapter_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** aggregate variance on columns */
["voyce_chapter_reactions_count_variance_fields"]: {
		chapter_id?:number,
	count?:number,
	id?:number,
	reaction?:number
};
	/** input type for incrementing numeric columns in table "voyce.chapter_reactions" */
["voyce_chapter_reactions_inc_input"]: GraphQLTypes["voyce_chapter_reactions_inc_input"];
	/** input type for inserting data into table "voyce.chapter_reactions" */
["voyce_chapter_reactions_insert_input"]: GraphQLTypes["voyce_chapter_reactions_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_reactions_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by max() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_max_order_by"]: GraphQLTypes["voyce_chapter_reactions_max_order_by"];
	/** aggregate min on columns */
["voyce_chapter_reactions_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by min() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_min_order_by"]: GraphQLTypes["voyce_chapter_reactions_min_order_by"];
	/** response of any mutation on the table "voyce.chapter_reactions" */
["voyce_chapter_reactions_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_reactions"][]
};
	/** on_conflict condition type for table "voyce.chapter_reactions" */
["voyce_chapter_reactions_on_conflict"]: GraphQLTypes["voyce_chapter_reactions_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_reactions". */
["voyce_chapter_reactions_order_by"]: GraphQLTypes["voyce_chapter_reactions_order_by"];
	/** primary key columns input for table: voyce.chapter_reactions */
["voyce_chapter_reactions_pk_columns_input"]: GraphQLTypes["voyce_chapter_reactions_pk_columns_input"];
	/** select columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_select_column"]: GraphQLTypes["voyce_chapter_reactions_select_column"];
	/** input type for updating data in table "voyce.chapter_reactions" */
["voyce_chapter_reactions_set_input"]: GraphQLTypes["voyce_chapter_reactions_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_reactions_stddev_fields"]: {
		id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_stddev_order_by"]: GraphQLTypes["voyce_chapter_reactions_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_chapter_reactions_stddev_pop_fields"]: {
		id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_stddev_pop_order_by"]: GraphQLTypes["voyce_chapter_reactions_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_chapter_reactions_stddev_samp_fields"]: {
		id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_stddev_samp_order_by"]: GraphQLTypes["voyce_chapter_reactions_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_chapter_reactions" */
["voyce_chapter_reactions_stream_cursor_input"]: GraphQLTypes["voyce_chapter_reactions_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_reactions_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_reactions_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_reactions_sum_fields"]: {
		id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_sum_order_by"]: GraphQLTypes["voyce_chapter_reactions_sum_order_by"];
	/** update columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_update_column"]: GraphQLTypes["voyce_chapter_reactions_update_column"];
	["voyce_chapter_reactions_updates"]: GraphQLTypes["voyce_chapter_reactions_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_reactions_var_pop_fields"]: {
		id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_var_pop_order_by"]: GraphQLTypes["voyce_chapter_reactions_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_chapter_reactions_var_samp_fields"]: {
		id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_var_samp_order_by"]: GraphQLTypes["voyce_chapter_reactions_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_chapter_reactions_variance_fields"]: {
		id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_variance_order_by"]: GraphQLTypes["voyce_chapter_reactions_variance_order_by"];
	/** columns and relationships of "voyce.chapter_view_logs" */
["voyce_chapter_view_logs"]: {
		chapter_id:number,
	created_at:ModelTypes["timestamptz"],
	device_id?:string,
	gtag?:string,
	id:number,
	origin?:string,
	page?:string,
	panel_id?:number,
	series_id:number,
	session_api?:string,
	session_id?:string,
	source?:string,
	user_id:number,
	user_ip:string
};
	/** aggregated selection of "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_view_logs_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_view_logs"][]
};
	/** aggregate fields of "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_view_logs_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_view_logs_max_fields"],
	min?:ModelTypes["voyce_chapter_view_logs_min_fields"],
	stddev?:ModelTypes["voyce_chapter_view_logs_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_view_logs_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_view_logs_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_view_logs_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_view_logs_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_view_logs_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_view_logs_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapter_view_logs_avg_fields"]: {
		chapter_id?:number,
	id?:number,
	panel_id?:number,
	series_id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.chapter_view_logs". All fields are combined with a logical 'AND'. */
["voyce_chapter_view_logs_bool_exp"]: GraphQLTypes["voyce_chapter_view_logs_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_constraint"]: GraphQLTypes["voyce_chapter_view_logs_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_inc_input"]: GraphQLTypes["voyce_chapter_view_logs_inc_input"];
	/** input type for inserting data into table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_insert_input"]: GraphQLTypes["voyce_chapter_view_logs_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_view_logs_max_fields"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamptz"],
	device_id?:string,
	gtag?:string,
	id?:number,
	origin?:string,
	page?:string,
	panel_id?:number,
	series_id?:number,
	session_api?:string,
	session_id?:string,
	source?:string,
	user_id?:number,
	user_ip?:string
};
	/** aggregate min on columns */
["voyce_chapter_view_logs_min_fields"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamptz"],
	device_id?:string,
	gtag?:string,
	id?:number,
	origin?:string,
	page?:string,
	panel_id?:number,
	series_id?:number,
	session_api?:string,
	session_id?:string,
	source?:string,
	user_id?:number,
	user_ip?:string
};
	/** response of any mutation on the table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_view_logs"][]
};
	/** on_conflict condition type for table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_on_conflict"]: GraphQLTypes["voyce_chapter_view_logs_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_view_logs". */
["voyce_chapter_view_logs_order_by"]: GraphQLTypes["voyce_chapter_view_logs_order_by"];
	/** primary key columns input for table: voyce.chapter_view_logs */
["voyce_chapter_view_logs_pk_columns_input"]: GraphQLTypes["voyce_chapter_view_logs_pk_columns_input"];
	/** select columns of table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_select_column"]: GraphQLTypes["voyce_chapter_view_logs_select_column"];
	/** input type for updating data in table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_set_input"]: GraphQLTypes["voyce_chapter_view_logs_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_view_logs_stddev_fields"]: {
		chapter_id?:number,
	id?:number,
	panel_id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_view_logs_stddev_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	panel_id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_view_logs_stddev_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	panel_id?:number,
	series_id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_chapter_view_logs" */
["voyce_chapter_view_logs_stream_cursor_input"]: GraphQLTypes["voyce_chapter_view_logs_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_view_logs_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_view_logs_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_view_logs_sum_fields"]: {
		chapter_id?:number,
	id?:number,
	panel_id?:number,
	series_id?:number,
	user_id?:number
};
	/** update columns of table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_update_column"]: GraphQLTypes["voyce_chapter_view_logs_update_column"];
	["voyce_chapter_view_logs_updates"]: GraphQLTypes["voyce_chapter_view_logs_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_view_logs_var_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	panel_id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_chapter_view_logs_var_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	panel_id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_chapter_view_logs_variance_fields"]: {
		chapter_id?:number,
	id?:number,
	panel_id?:number,
	series_id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.chapter_views" */
["voyce_chapter_views"]: {
		chapter_id?:number,
	id:number,
	is_read:boolean,
	page?:string,
	/** An object relationship */
	series?:ModelTypes["voyce_series"],
	series_id:number,
	updated_at?:ModelTypes["timestamp"],
	user_id:number,
	user_ip:ModelTypes["bigint"],
	view_hash?:string
};
	/** aggregated selection of "voyce.chapter_views" */
["voyce_chapter_views_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapter_views_aggregate_fields"],
	nodes:ModelTypes["voyce_chapter_views"][]
};
	["voyce_chapter_views_aggregate_bool_exp"]: GraphQLTypes["voyce_chapter_views_aggregate_bool_exp"];
	["voyce_chapter_views_aggregate_bool_exp_bool_and"]: GraphQLTypes["voyce_chapter_views_aggregate_bool_exp_bool_and"];
	["voyce_chapter_views_aggregate_bool_exp_bool_or"]: GraphQLTypes["voyce_chapter_views_aggregate_bool_exp_bool_or"];
	["voyce_chapter_views_aggregate_bool_exp_count"]: GraphQLTypes["voyce_chapter_views_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.chapter_views" */
["voyce_chapter_views_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapter_views_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapter_views_max_fields"],
	min?:ModelTypes["voyce_chapter_views_min_fields"],
	stddev?:ModelTypes["voyce_chapter_views_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapter_views_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapter_views_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapter_views_sum_fields"],
	var_pop?:ModelTypes["voyce_chapter_views_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapter_views_var_samp_fields"],
	variance?:ModelTypes["voyce_chapter_views_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_views" */
["voyce_chapter_views_aggregate_order_by"]: GraphQLTypes["voyce_chapter_views_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.chapter_views" */
["voyce_chapter_views_arr_rel_insert_input"]: GraphQLTypes["voyce_chapter_views_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_chapter_views_avg_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	user_ip?:number
};
	/** order by avg() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_avg_order_by"]: GraphQLTypes["voyce_chapter_views_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.chapter_views". All fields are combined with a logical 'AND'. */
["voyce_chapter_views_bool_exp"]: GraphQLTypes["voyce_chapter_views_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapter_views" */
["voyce_chapter_views_constraint"]: GraphQLTypes["voyce_chapter_views_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapter_views" */
["voyce_chapter_views_inc_input"]: GraphQLTypes["voyce_chapter_views_inc_input"];
	/** input type for inserting data into table "voyce.chapter_views" */
["voyce_chapter_views_insert_input"]: GraphQLTypes["voyce_chapter_views_insert_input"];
	/** aggregate max on columns */
["voyce_chapter_views_max_fields"]: {
		chapter_id?:number,
	id?:number,
	page?:string,
	series_id?:number,
	updated_at?:ModelTypes["timestamp"],
	user_id?:number,
	user_ip?:ModelTypes["bigint"],
	view_hash?:string
};
	/** order by max() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_max_order_by"]: GraphQLTypes["voyce_chapter_views_max_order_by"];
	/** aggregate min on columns */
["voyce_chapter_views_min_fields"]: {
		chapter_id?:number,
	id?:number,
	page?:string,
	series_id?:number,
	updated_at?:ModelTypes["timestamp"],
	user_id?:number,
	user_ip?:ModelTypes["bigint"],
	view_hash?:string
};
	/** order by min() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_min_order_by"]: GraphQLTypes["voyce_chapter_views_min_order_by"];
	/** response of any mutation on the table "voyce.chapter_views" */
["voyce_chapter_views_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapter_views"][]
};
	/** on_conflict condition type for table "voyce.chapter_views" */
["voyce_chapter_views_on_conflict"]: GraphQLTypes["voyce_chapter_views_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapter_views". */
["voyce_chapter_views_order_by"]: GraphQLTypes["voyce_chapter_views_order_by"];
	/** primary key columns input for table: voyce.chapter_views */
["voyce_chapter_views_pk_columns_input"]: GraphQLTypes["voyce_chapter_views_pk_columns_input"];
	/** select columns of table "voyce.chapter_views" */
["voyce_chapter_views_select_column"]: GraphQLTypes["voyce_chapter_views_select_column"];
	/** select "voyce_chapter_views_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapter_views" */
["voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_and_arguments_columns"]: GraphQLTypes["voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_and_arguments_columns"];
	/** select "voyce_chapter_views_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapter_views" */
["voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_or_arguments_columns"]: GraphQLTypes["voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_or_arguments_columns"];
	/** input type for updating data in table "voyce.chapter_views" */
["voyce_chapter_views_set_input"]: GraphQLTypes["voyce_chapter_views_set_input"];
	/** aggregate stddev on columns */
["voyce_chapter_views_stddev_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	user_ip?:number
};
	/** order by stddev() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_stddev_order_by"]: GraphQLTypes["voyce_chapter_views_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_chapter_views_stddev_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	user_ip?:number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_stddev_pop_order_by"]: GraphQLTypes["voyce_chapter_views_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_chapter_views_stddev_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	user_ip?:number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_stddev_samp_order_by"]: GraphQLTypes["voyce_chapter_views_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_chapter_views" */
["voyce_chapter_views_stream_cursor_input"]: GraphQLTypes["voyce_chapter_views_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_views_stream_cursor_value_input"]: GraphQLTypes["voyce_chapter_views_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapter_views_sum_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	user_ip?:ModelTypes["bigint"]
};
	/** order by sum() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_sum_order_by"]: GraphQLTypes["voyce_chapter_views_sum_order_by"];
	/** update columns of table "voyce.chapter_views" */
["voyce_chapter_views_update_column"]: GraphQLTypes["voyce_chapter_views_update_column"];
	["voyce_chapter_views_updates"]: GraphQLTypes["voyce_chapter_views_updates"];
	/** aggregate var_pop on columns */
["voyce_chapter_views_var_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	user_ip?:number
};
	/** order by var_pop() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_var_pop_order_by"]: GraphQLTypes["voyce_chapter_views_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_chapter_views_var_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	user_ip?:number
};
	/** order by var_samp() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_var_samp_order_by"]: GraphQLTypes["voyce_chapter_views_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_chapter_views_variance_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	user_ip?:number
};
	/** order by variance() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_variance_order_by"]: GraphQLTypes["voyce_chapter_views_variance_order_by"];
	/** columns and relationships of "voyce.chapters" */
["voyce_chapters"]: {
		/** An array relationship */
	chapter_comments:ModelTypes["voyce_chapter_comments"][],
	/** An aggregate relationship */
	chapter_comments_aggregate:ModelTypes["voyce_chapter_comments_aggregate"],
	/** An array relationship */
	chapter_effects:ModelTypes["voyce_chapter_effects"][],
	/** An aggregate relationship */
	chapter_effects_aggregate:ModelTypes["voyce_chapter_effects_aggregate"],
	/** An array relationship */
	chapter_panels:ModelTypes["voyce_chapter_panels"][],
	/** An aggregate relationship */
	chapter_panels_aggregate:ModelTypes["voyce_chapter_panels_aggregate"],
	/** An array relationship */
	chapter_reactions:ModelTypes["voyce_chapter_reactions"][],
	/** An aggregate relationship */
	chapter_reactions_aggregate:ModelTypes["voyce_chapter_reactions_aggregate"],
	/** An object relationship */
	content?:ModelTypes["voyce_chapter_contents"],
	created_at:ModelTypes["timestamp"],
	description?:string,
	description_old?:string,
	displayed_exclusive:boolean,
	formatted_content?:string,
	formatted_content_v2?:string,
	formatted_content_v3?:string,
	has_text:boolean,
	id:number,
	/** An array relationship */
	images:ModelTypes["voyce_chapter_images"][],
	/** An aggregate relationship */
	images_aggregate:ModelTypes["voyce_chapter_images_aggregate"],
	is_deleted:boolean,
	/** An array relationship */
	likes:ModelTypes["voyce_chapter_likes"][],
	/** An aggregate relationship */
	likes_aggregate:ModelTypes["voyce_chapter_likes_aggregate"],
	/** An array relationship */
	locks:ModelTypes["voyce_chapter_locks"][],
	/** An aggregate relationship */
	locks_aggregate:ModelTypes["voyce_chapter_locks_aggregate"],
	order?:ModelTypes["smallint"],
	publish?:ModelTypes["smallint"],
	publish_date?:ModelTypes["timestamptz"],
	reactions_count?:number,
	reading_view:string,
	/** An array relationship */
	schedule:ModelTypes["voyce_chapter_publish_schedules"][],
	/** An aggregate relationship */
	schedule_aggregate:ModelTypes["voyce_chapter_publish_schedules_aggregate"],
	/** An object relationship */
	series?:ModelTypes["voyce_series"],
	series_id:number,
	status?:string,
	thumbnail?:string,
	title:string,
	type?:string,
	updated_at?:ModelTypes["timestamptz"],
	view_count?:number,
	/** An array relationship */
	views:ModelTypes["voyce_chapter_views"][],
	/** An aggregate relationship */
	views_aggregate:ModelTypes["voyce_chapter_views_aggregate"]
};
	/** columns and relationships of "voyce.chapters_07252024" */
["voyce_chapters_07252024"]: {
		created_at?:ModelTypes["timestamp"],
	description?:string,
	description_old?:string,
	displayed_exclusive?:boolean,
	formatted_content?:string,
	formatted_content_v2?:string,
	formatted_content_v3?:string,
	has_text?:boolean,
	id?:number,
	is_deleted?:boolean,
	order?:ModelTypes["smallint"],
	publish?:ModelTypes["smallint"],
	publish_date?:ModelTypes["timestamptz"],
	reactions_count?:number,
	reading_view?:string,
	series_id?:number,
	status?:string,
	thumbnail?:string,
	title?:string,
	type?:string,
	updated_at?:ModelTypes["timestamptz"],
	view_count?:number
};
	/** aggregated selection of "voyce.chapters_07252024" */
["voyce_chapters_07252024_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapters_07252024_aggregate_fields"],
	nodes:ModelTypes["voyce_chapters_07252024"][]
};
	/** aggregate fields of "voyce.chapters_07252024" */
["voyce_chapters_07252024_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapters_07252024_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapters_07252024_max_fields"],
	min?:ModelTypes["voyce_chapters_07252024_min_fields"],
	stddev?:ModelTypes["voyce_chapters_07252024_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapters_07252024_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapters_07252024_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapters_07252024_sum_fields"],
	var_pop?:ModelTypes["voyce_chapters_07252024_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapters_07252024_var_samp_fields"],
	variance?:ModelTypes["voyce_chapters_07252024_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapters_07252024_avg_fields"]: {
		id?:number,
	order?:number,
	publish?:number,
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** Boolean expression to filter rows from the table "voyce.chapters_07252024". All fields are combined with a logical 'AND'. */
["voyce_chapters_07252024_bool_exp"]: GraphQLTypes["voyce_chapters_07252024_bool_exp"];
	/** input type for incrementing numeric columns in table "voyce.chapters_07252024" */
["voyce_chapters_07252024_inc_input"]: GraphQLTypes["voyce_chapters_07252024_inc_input"];
	/** input type for inserting data into table "voyce.chapters_07252024" */
["voyce_chapters_07252024_insert_input"]: GraphQLTypes["voyce_chapters_07252024_insert_input"];
	/** aggregate max on columns */
["voyce_chapters_07252024_max_fields"]: {
		created_at?:ModelTypes["timestamp"],
	description?:string,
	description_old?:string,
	formatted_content?:string,
	formatted_content_v2?:string,
	formatted_content_v3?:string,
	id?:number,
	order?:ModelTypes["smallint"],
	publish?:ModelTypes["smallint"],
	publish_date?:ModelTypes["timestamptz"],
	reactions_count?:number,
	reading_view?:string,
	series_id?:number,
	status?:string,
	thumbnail?:string,
	title?:string,
	type?:string,
	updated_at?:ModelTypes["timestamptz"],
	view_count?:number
};
	/** aggregate min on columns */
["voyce_chapters_07252024_min_fields"]: {
		created_at?:ModelTypes["timestamp"],
	description?:string,
	description_old?:string,
	formatted_content?:string,
	formatted_content_v2?:string,
	formatted_content_v3?:string,
	id?:number,
	order?:ModelTypes["smallint"],
	publish?:ModelTypes["smallint"],
	publish_date?:ModelTypes["timestamptz"],
	reactions_count?:number,
	reading_view?:string,
	series_id?:number,
	status?:string,
	thumbnail?:string,
	title?:string,
	type?:string,
	updated_at?:ModelTypes["timestamptz"],
	view_count?:number
};
	/** response of any mutation on the table "voyce.chapters_07252024" */
["voyce_chapters_07252024_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapters_07252024"][]
};
	/** Ordering options when selecting data from "voyce.chapters_07252024". */
["voyce_chapters_07252024_order_by"]: GraphQLTypes["voyce_chapters_07252024_order_by"];
	/** select columns of table "voyce.chapters_07252024" */
["voyce_chapters_07252024_select_column"]: GraphQLTypes["voyce_chapters_07252024_select_column"];
	/** input type for updating data in table "voyce.chapters_07252024" */
["voyce_chapters_07252024_set_input"]: GraphQLTypes["voyce_chapters_07252024_set_input"];
	/** aggregate stddev on columns */
["voyce_chapters_07252024_stddev_fields"]: {
		id?:number,
	order?:number,
	publish?:number,
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** aggregate stddev_pop on columns */
["voyce_chapters_07252024_stddev_pop_fields"]: {
		id?:number,
	order?:number,
	publish?:number,
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** aggregate stddev_samp on columns */
["voyce_chapters_07252024_stddev_samp_fields"]: {
		id?:number,
	order?:number,
	publish?:number,
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** Streaming cursor of the table "voyce_chapters_07252024" */
["voyce_chapters_07252024_stream_cursor_input"]: GraphQLTypes["voyce_chapters_07252024_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapters_07252024_stream_cursor_value_input"]: GraphQLTypes["voyce_chapters_07252024_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapters_07252024_sum_fields"]: {
		id?:number,
	order?:ModelTypes["smallint"],
	publish?:ModelTypes["smallint"],
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	["voyce_chapters_07252024_updates"]: GraphQLTypes["voyce_chapters_07252024_updates"];
	/** aggregate var_pop on columns */
["voyce_chapters_07252024_var_pop_fields"]: {
		id?:number,
	order?:number,
	publish?:number,
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** aggregate var_samp on columns */
["voyce_chapters_07252024_var_samp_fields"]: {
		id?:number,
	order?:number,
	publish?:number,
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** aggregate variance on columns */
["voyce_chapters_07252024_variance_fields"]: {
		id?:number,
	order?:number,
	publish?:number,
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** aggregated selection of "voyce.chapters" */
["voyce_chapters_aggregate"]: {
		aggregate?:ModelTypes["voyce_chapters_aggregate_fields"],
	nodes:ModelTypes["voyce_chapters"][]
};
	["voyce_chapters_aggregate_bool_exp"]: GraphQLTypes["voyce_chapters_aggregate_bool_exp"];
	["voyce_chapters_aggregate_bool_exp_bool_and"]: GraphQLTypes["voyce_chapters_aggregate_bool_exp_bool_and"];
	["voyce_chapters_aggregate_bool_exp_bool_or"]: GraphQLTypes["voyce_chapters_aggregate_bool_exp_bool_or"];
	["voyce_chapters_aggregate_bool_exp_count"]: GraphQLTypes["voyce_chapters_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.chapters" */
["voyce_chapters_aggregate_fields"]: {
		avg?:ModelTypes["voyce_chapters_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_chapters_max_fields"],
	min?:ModelTypes["voyce_chapters_min_fields"],
	stddev?:ModelTypes["voyce_chapters_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_chapters_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_chapters_stddev_samp_fields"],
	sum?:ModelTypes["voyce_chapters_sum_fields"],
	var_pop?:ModelTypes["voyce_chapters_var_pop_fields"],
	var_samp?:ModelTypes["voyce_chapters_var_samp_fields"],
	variance?:ModelTypes["voyce_chapters_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapters" */
["voyce_chapters_aggregate_order_by"]: GraphQLTypes["voyce_chapters_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.chapters" */
["voyce_chapters_arr_rel_insert_input"]: GraphQLTypes["voyce_chapters_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_chapters_avg_fields"]: {
		id?:number,
	order?:number,
	publish?:number,
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** order by avg() on columns of table "voyce.chapters" */
["voyce_chapters_avg_order_by"]: GraphQLTypes["voyce_chapters_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.chapters". All fields are combined with a logical 'AND'. */
["voyce_chapters_bool_exp"]: GraphQLTypes["voyce_chapters_bool_exp"];
	/** unique or primary key constraints on table "voyce.chapters" */
["voyce_chapters_constraint"]: GraphQLTypes["voyce_chapters_constraint"];
	/** input type for incrementing numeric columns in table "voyce.chapters" */
["voyce_chapters_inc_input"]: GraphQLTypes["voyce_chapters_inc_input"];
	/** input type for inserting data into table "voyce.chapters" */
["voyce_chapters_insert_input"]: GraphQLTypes["voyce_chapters_insert_input"];
	/** aggregate max on columns */
["voyce_chapters_max_fields"]: {
		created_at?:ModelTypes["timestamp"],
	description?:string,
	description_old?:string,
	formatted_content?:string,
	formatted_content_v2?:string,
	formatted_content_v3?:string,
	id?:number,
	order?:ModelTypes["smallint"],
	publish?:ModelTypes["smallint"],
	publish_date?:ModelTypes["timestamptz"],
	reactions_count?:number,
	reading_view?:string,
	series_id?:number,
	status?:string,
	thumbnail?:string,
	title?:string,
	type?:string,
	updated_at?:ModelTypes["timestamptz"],
	view_count?:number
};
	/** order by max() on columns of table "voyce.chapters" */
["voyce_chapters_max_order_by"]: GraphQLTypes["voyce_chapters_max_order_by"];
	/** aggregate min on columns */
["voyce_chapters_min_fields"]: {
		created_at?:ModelTypes["timestamp"],
	description?:string,
	description_old?:string,
	formatted_content?:string,
	formatted_content_v2?:string,
	formatted_content_v3?:string,
	id?:number,
	order?:ModelTypes["smallint"],
	publish?:ModelTypes["smallint"],
	publish_date?:ModelTypes["timestamptz"],
	reactions_count?:number,
	reading_view?:string,
	series_id?:number,
	status?:string,
	thumbnail?:string,
	title?:string,
	type?:string,
	updated_at?:ModelTypes["timestamptz"],
	view_count?:number
};
	/** order by min() on columns of table "voyce.chapters" */
["voyce_chapters_min_order_by"]: GraphQLTypes["voyce_chapters_min_order_by"];
	/** response of any mutation on the table "voyce.chapters" */
["voyce_chapters_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_chapters"][]
};
	/** input type for inserting object relation for remote table "voyce.chapters" */
["voyce_chapters_obj_rel_insert_input"]: GraphQLTypes["voyce_chapters_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.chapters" */
["voyce_chapters_on_conflict"]: GraphQLTypes["voyce_chapters_on_conflict"];
	/** Ordering options when selecting data from "voyce.chapters". */
["voyce_chapters_order_by"]: GraphQLTypes["voyce_chapters_order_by"];
	/** primary key columns input for table: voyce.chapters */
["voyce_chapters_pk_columns_input"]: GraphQLTypes["voyce_chapters_pk_columns_input"];
	/** select columns of table "voyce.chapters" */
["voyce_chapters_select_column"]: GraphQLTypes["voyce_chapters_select_column"];
	/** select "voyce_chapters_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapters" */
["voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_and_arguments_columns"]: GraphQLTypes["voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_and_arguments_columns"];
	/** select "voyce_chapters_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapters" */
["voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_or_arguments_columns"]: GraphQLTypes["voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_or_arguments_columns"];
	/** input type for updating data in table "voyce.chapters" */
["voyce_chapters_set_input"]: GraphQLTypes["voyce_chapters_set_input"];
	/** aggregate stddev on columns */
["voyce_chapters_stddev_fields"]: {
		id?:number,
	order?:number,
	publish?:number,
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** order by stddev() on columns of table "voyce.chapters" */
["voyce_chapters_stddev_order_by"]: GraphQLTypes["voyce_chapters_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_chapters_stddev_pop_fields"]: {
		id?:number,
	order?:number,
	publish?:number,
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** order by stddev_pop() on columns of table "voyce.chapters" */
["voyce_chapters_stddev_pop_order_by"]: GraphQLTypes["voyce_chapters_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_chapters_stddev_samp_fields"]: {
		id?:number,
	order?:number,
	publish?:number,
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** order by stddev_samp() on columns of table "voyce.chapters" */
["voyce_chapters_stddev_samp_order_by"]: GraphQLTypes["voyce_chapters_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_chapters" */
["voyce_chapters_stream_cursor_input"]: GraphQLTypes["voyce_chapters_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_chapters_stream_cursor_value_input"]: GraphQLTypes["voyce_chapters_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_chapters_sum_fields"]: {
		id?:number,
	order?:ModelTypes["smallint"],
	publish?:ModelTypes["smallint"],
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** order by sum() on columns of table "voyce.chapters" */
["voyce_chapters_sum_order_by"]: GraphQLTypes["voyce_chapters_sum_order_by"];
	/** update columns of table "voyce.chapters" */
["voyce_chapters_update_column"]: GraphQLTypes["voyce_chapters_update_column"];
	["voyce_chapters_updates"]: GraphQLTypes["voyce_chapters_updates"];
	/** aggregate var_pop on columns */
["voyce_chapters_var_pop_fields"]: {
		id?:number,
	order?:number,
	publish?:number,
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** order by var_pop() on columns of table "voyce.chapters" */
["voyce_chapters_var_pop_order_by"]: GraphQLTypes["voyce_chapters_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_chapters_var_samp_fields"]: {
		id?:number,
	order?:number,
	publish?:number,
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** order by var_samp() on columns of table "voyce.chapters" */
["voyce_chapters_var_samp_order_by"]: GraphQLTypes["voyce_chapters_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_chapters_variance_fields"]: {
		id?:number,
	order?:number,
	publish?:number,
	reactions_count?:number,
	series_id?:number,
	view_count?:number
};
	/** order by variance() on columns of table "voyce.chapters" */
["voyce_chapters_variance_order_by"]: GraphQLTypes["voyce_chapters_variance_order_by"];
	/** columns and relationships of "voyce.collaborator_roles" */
["voyce_collaborator_roles"]: {
		created_at?:ModelTypes["timestamptz"],
	id:number,
	role:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.collaborator_roles" */
["voyce_collaborator_roles_aggregate"]: {
		aggregate?:ModelTypes["voyce_collaborator_roles_aggregate_fields"],
	nodes:ModelTypes["voyce_collaborator_roles"][]
};
	/** aggregate fields of "voyce.collaborator_roles" */
["voyce_collaborator_roles_aggregate_fields"]: {
		avg?:ModelTypes["voyce_collaborator_roles_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_collaborator_roles_max_fields"],
	min?:ModelTypes["voyce_collaborator_roles_min_fields"],
	stddev?:ModelTypes["voyce_collaborator_roles_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_collaborator_roles_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_collaborator_roles_stddev_samp_fields"],
	sum?:ModelTypes["voyce_collaborator_roles_sum_fields"],
	var_pop?:ModelTypes["voyce_collaborator_roles_var_pop_fields"],
	var_samp?:ModelTypes["voyce_collaborator_roles_var_samp_fields"],
	variance?:ModelTypes["voyce_collaborator_roles_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_collaborator_roles_avg_fields"]: {
		id?:number
};
	/** Boolean expression to filter rows from the table "voyce.collaborator_roles". All fields are combined with a logical 'AND'. */
["voyce_collaborator_roles_bool_exp"]: GraphQLTypes["voyce_collaborator_roles_bool_exp"];
	/** unique or primary key constraints on table "voyce.collaborator_roles" */
["voyce_collaborator_roles_constraint"]: GraphQLTypes["voyce_collaborator_roles_constraint"];
	/** input type for incrementing numeric columns in table "voyce.collaborator_roles" */
["voyce_collaborator_roles_inc_input"]: GraphQLTypes["voyce_collaborator_roles_inc_input"];
	/** input type for inserting data into table "voyce.collaborator_roles" */
["voyce_collaborator_roles_insert_input"]: GraphQLTypes["voyce_collaborator_roles_insert_input"];
	/** aggregate max on columns */
["voyce_collaborator_roles_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	role?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_collaborator_roles_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	role?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.collaborator_roles" */
["voyce_collaborator_roles_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_collaborator_roles"][]
};
	/** input type for inserting object relation for remote table "voyce.collaborator_roles" */
["voyce_collaborator_roles_obj_rel_insert_input"]: GraphQLTypes["voyce_collaborator_roles_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.collaborator_roles" */
["voyce_collaborator_roles_on_conflict"]: GraphQLTypes["voyce_collaborator_roles_on_conflict"];
	/** Ordering options when selecting data from "voyce.collaborator_roles". */
["voyce_collaborator_roles_order_by"]: GraphQLTypes["voyce_collaborator_roles_order_by"];
	/** primary key columns input for table: voyce.collaborator_roles */
["voyce_collaborator_roles_pk_columns_input"]: GraphQLTypes["voyce_collaborator_roles_pk_columns_input"];
	/** select columns of table "voyce.collaborator_roles" */
["voyce_collaborator_roles_select_column"]: GraphQLTypes["voyce_collaborator_roles_select_column"];
	/** input type for updating data in table "voyce.collaborator_roles" */
["voyce_collaborator_roles_set_input"]: GraphQLTypes["voyce_collaborator_roles_set_input"];
	/** aggregate stddev on columns */
["voyce_collaborator_roles_stddev_fields"]: {
		id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_collaborator_roles_stddev_pop_fields"]: {
		id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_collaborator_roles_stddev_samp_fields"]: {
		id?:number
};
	/** Streaming cursor of the table "voyce_collaborator_roles" */
["voyce_collaborator_roles_stream_cursor_input"]: GraphQLTypes["voyce_collaborator_roles_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_collaborator_roles_stream_cursor_value_input"]: GraphQLTypes["voyce_collaborator_roles_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_collaborator_roles_sum_fields"]: {
		id?:number
};
	/** update columns of table "voyce.collaborator_roles" */
["voyce_collaborator_roles_update_column"]: GraphQLTypes["voyce_collaborator_roles_update_column"];
	["voyce_collaborator_roles_updates"]: GraphQLTypes["voyce_collaborator_roles_updates"];
	/** aggregate var_pop on columns */
["voyce_collaborator_roles_var_pop_fields"]: {
		id?:number
};
	/** aggregate var_samp on columns */
["voyce_collaborator_roles_var_samp_fields"]: {
		id?:number
};
	/** aggregate variance on columns */
["voyce_collaborator_roles_variance_fields"]: {
		id?:number
};
	/** columns and relationships of "voyce.comic_types" */
["voyce_comic_types"]: {
		created_at:ModelTypes["timestamp"],
	id:number,
	klaviyo_id?:string,
	title:string,
	updated_at?:ModelTypes["timestamp"]
};
	/** aggregated selection of "voyce.comic_types" */
["voyce_comic_types_aggregate"]: {
		aggregate?:ModelTypes["voyce_comic_types_aggregate_fields"],
	nodes:ModelTypes["voyce_comic_types"][]
};
	/** aggregate fields of "voyce.comic_types" */
["voyce_comic_types_aggregate_fields"]: {
		avg?:ModelTypes["voyce_comic_types_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_comic_types_max_fields"],
	min?:ModelTypes["voyce_comic_types_min_fields"],
	stddev?:ModelTypes["voyce_comic_types_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_comic_types_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_comic_types_stddev_samp_fields"],
	sum?:ModelTypes["voyce_comic_types_sum_fields"],
	var_pop?:ModelTypes["voyce_comic_types_var_pop_fields"],
	var_samp?:ModelTypes["voyce_comic_types_var_samp_fields"],
	variance?:ModelTypes["voyce_comic_types_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_comic_types_avg_fields"]: {
		id?:number
};
	/** Boolean expression to filter rows from the table "voyce.comic_types". All fields are combined with a logical 'AND'. */
["voyce_comic_types_bool_exp"]: GraphQLTypes["voyce_comic_types_bool_exp"];
	/** unique or primary key constraints on table "voyce.comic_types" */
["voyce_comic_types_constraint"]: GraphQLTypes["voyce_comic_types_constraint"];
	/** input type for incrementing numeric columns in table "voyce.comic_types" */
["voyce_comic_types_inc_input"]: GraphQLTypes["voyce_comic_types_inc_input"];
	/** input type for inserting data into table "voyce.comic_types" */
["voyce_comic_types_insert_input"]: GraphQLTypes["voyce_comic_types_insert_input"];
	/** aggregate max on columns */
["voyce_comic_types_max_fields"]: {
		created_at?:ModelTypes["timestamp"],
	id?:number,
	klaviyo_id?:string,
	title?:string,
	updated_at?:ModelTypes["timestamp"]
};
	/** aggregate min on columns */
["voyce_comic_types_min_fields"]: {
		created_at?:ModelTypes["timestamp"],
	id?:number,
	klaviyo_id?:string,
	title?:string,
	updated_at?:ModelTypes["timestamp"]
};
	/** response of any mutation on the table "voyce.comic_types" */
["voyce_comic_types_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_comic_types"][]
};
	/** input type for inserting object relation for remote table "voyce.comic_types" */
["voyce_comic_types_obj_rel_insert_input"]: GraphQLTypes["voyce_comic_types_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.comic_types" */
["voyce_comic_types_on_conflict"]: GraphQLTypes["voyce_comic_types_on_conflict"];
	/** Ordering options when selecting data from "voyce.comic_types". */
["voyce_comic_types_order_by"]: GraphQLTypes["voyce_comic_types_order_by"];
	/** primary key columns input for table: voyce.comic_types */
["voyce_comic_types_pk_columns_input"]: GraphQLTypes["voyce_comic_types_pk_columns_input"];
	/** select columns of table "voyce.comic_types" */
["voyce_comic_types_select_column"]: GraphQLTypes["voyce_comic_types_select_column"];
	/** input type for updating data in table "voyce.comic_types" */
["voyce_comic_types_set_input"]: GraphQLTypes["voyce_comic_types_set_input"];
	/** aggregate stddev on columns */
["voyce_comic_types_stddev_fields"]: {
		id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_comic_types_stddev_pop_fields"]: {
		id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_comic_types_stddev_samp_fields"]: {
		id?:number
};
	/** Streaming cursor of the table "voyce_comic_types" */
["voyce_comic_types_stream_cursor_input"]: GraphQLTypes["voyce_comic_types_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_comic_types_stream_cursor_value_input"]: GraphQLTypes["voyce_comic_types_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_comic_types_sum_fields"]: {
		id?:number
};
	/** update columns of table "voyce.comic_types" */
["voyce_comic_types_update_column"]: GraphQLTypes["voyce_comic_types_update_column"];
	["voyce_comic_types_updates"]: GraphQLTypes["voyce_comic_types_updates"];
	/** aggregate var_pop on columns */
["voyce_comic_types_var_pop_fields"]: {
		id?:number
};
	/** aggregate var_samp on columns */
["voyce_comic_types_var_samp_fields"]: {
		id?:number
};
	/** aggregate variance on columns */
["voyce_comic_types_variance_fields"]: {
		id?:number
};
	/** columns and relationships of "voyce.comments_top_mat" */
["voyce_comments_top_mat"]: {
		comment_type?:string,
	id?:number,
	reaction_count?:number,
	user_id?:number
};
	/** aggregated selection of "voyce.comments_top_mat" */
["voyce_comments_top_mat_aggregate"]: {
		aggregate?:ModelTypes["voyce_comments_top_mat_aggregate_fields"],
	nodes:ModelTypes["voyce_comments_top_mat"][]
};
	/** aggregate fields of "voyce.comments_top_mat" */
["voyce_comments_top_mat_aggregate_fields"]: {
		avg?:ModelTypes["voyce_comments_top_mat_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_comments_top_mat_max_fields"],
	min?:ModelTypes["voyce_comments_top_mat_min_fields"],
	stddev?:ModelTypes["voyce_comments_top_mat_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_comments_top_mat_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_comments_top_mat_stddev_samp_fields"],
	sum?:ModelTypes["voyce_comments_top_mat_sum_fields"],
	var_pop?:ModelTypes["voyce_comments_top_mat_var_pop_fields"],
	var_samp?:ModelTypes["voyce_comments_top_mat_var_samp_fields"],
	variance?:ModelTypes["voyce_comments_top_mat_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_comments_top_mat_avg_fields"]: {
		id?:number,
	reaction_count?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.comments_top_mat". All fields are combined with a logical 'AND'. */
["voyce_comments_top_mat_bool_exp"]: GraphQLTypes["voyce_comments_top_mat_bool_exp"];
	/** aggregate max on columns */
["voyce_comments_top_mat_max_fields"]: {
		comment_type?:string,
	id?:number,
	reaction_count?:number,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_comments_top_mat_min_fields"]: {
		comment_type?:string,
	id?:number,
	reaction_count?:number,
	user_id?:number
};
	/** Ordering options when selecting data from "voyce.comments_top_mat". */
["voyce_comments_top_mat_order_by"]: GraphQLTypes["voyce_comments_top_mat_order_by"];
	/** select columns of table "voyce.comments_top_mat" */
["voyce_comments_top_mat_select_column"]: GraphQLTypes["voyce_comments_top_mat_select_column"];
	/** aggregate stddev on columns */
["voyce_comments_top_mat_stddev_fields"]: {
		id?:number,
	reaction_count?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_comments_top_mat_stddev_pop_fields"]: {
		id?:number,
	reaction_count?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_comments_top_mat_stddev_samp_fields"]: {
		id?:number,
	reaction_count?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_comments_top_mat" */
["voyce_comments_top_mat_stream_cursor_input"]: GraphQLTypes["voyce_comments_top_mat_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_comments_top_mat_stream_cursor_value_input"]: GraphQLTypes["voyce_comments_top_mat_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_comments_top_mat_sum_fields"]: {
		id?:number,
	reaction_count?:number,
	user_id?:number
};
	/** aggregate var_pop on columns */
["voyce_comments_top_mat_var_pop_fields"]: {
		id?:number,
	reaction_count?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_comments_top_mat_var_samp_fields"]: {
		id?:number,
	reaction_count?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_comments_top_mat_variance_fields"]: {
		id?:number,
	reaction_count?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.community_blurbs" */
["voyce_community_blurbs"]: {
		avatar:string,
	content:string,
	id:number,
	name:string,
	order:number,
	title:string,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.community_blurbs" */
["voyce_community_blurbs_aggregate"]: {
		aggregate?:ModelTypes["voyce_community_blurbs_aggregate_fields"],
	nodes:ModelTypes["voyce_community_blurbs"][]
};
	/** aggregate fields of "voyce.community_blurbs" */
["voyce_community_blurbs_aggregate_fields"]: {
		avg?:ModelTypes["voyce_community_blurbs_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_community_blurbs_max_fields"],
	min?:ModelTypes["voyce_community_blurbs_min_fields"],
	stddev?:ModelTypes["voyce_community_blurbs_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_community_blurbs_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_community_blurbs_stddev_samp_fields"],
	sum?:ModelTypes["voyce_community_blurbs_sum_fields"],
	var_pop?:ModelTypes["voyce_community_blurbs_var_pop_fields"],
	var_samp?:ModelTypes["voyce_community_blurbs_var_samp_fields"],
	variance?:ModelTypes["voyce_community_blurbs_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_community_blurbs_avg_fields"]: {
		id?:number,
	order?:number
};
	/** Boolean expression to filter rows from the table "voyce.community_blurbs". All fields are combined with a logical 'AND'. */
["voyce_community_blurbs_bool_exp"]: GraphQLTypes["voyce_community_blurbs_bool_exp"];
	/** unique or primary key constraints on table "voyce.community_blurbs" */
["voyce_community_blurbs_constraint"]: GraphQLTypes["voyce_community_blurbs_constraint"];
	/** input type for incrementing numeric columns in table "voyce.community_blurbs" */
["voyce_community_blurbs_inc_input"]: GraphQLTypes["voyce_community_blurbs_inc_input"];
	/** input type for inserting data into table "voyce.community_blurbs" */
["voyce_community_blurbs_insert_input"]: GraphQLTypes["voyce_community_blurbs_insert_input"];
	/** aggregate max on columns */
["voyce_community_blurbs_max_fields"]: {
		avatar?:string,
	content?:string,
	id?:number,
	name?:string,
	order?:number,
	title?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_community_blurbs_min_fields"]: {
		avatar?:string,
	content?:string,
	id?:number,
	name?:string,
	order?:number,
	title?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.community_blurbs" */
["voyce_community_blurbs_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_community_blurbs"][]
};
	/** on_conflict condition type for table "voyce.community_blurbs" */
["voyce_community_blurbs_on_conflict"]: GraphQLTypes["voyce_community_blurbs_on_conflict"];
	/** Ordering options when selecting data from "voyce.community_blurbs". */
["voyce_community_blurbs_order_by"]: GraphQLTypes["voyce_community_blurbs_order_by"];
	/** primary key columns input for table: voyce.community_blurbs */
["voyce_community_blurbs_pk_columns_input"]: GraphQLTypes["voyce_community_blurbs_pk_columns_input"];
	/** select columns of table "voyce.community_blurbs" */
["voyce_community_blurbs_select_column"]: GraphQLTypes["voyce_community_blurbs_select_column"];
	/** input type for updating data in table "voyce.community_blurbs" */
["voyce_community_blurbs_set_input"]: GraphQLTypes["voyce_community_blurbs_set_input"];
	/** aggregate stddev on columns */
["voyce_community_blurbs_stddev_fields"]: {
		id?:number,
	order?:number
};
	/** aggregate stddev_pop on columns */
["voyce_community_blurbs_stddev_pop_fields"]: {
		id?:number,
	order?:number
};
	/** aggregate stddev_samp on columns */
["voyce_community_blurbs_stddev_samp_fields"]: {
		id?:number,
	order?:number
};
	/** Streaming cursor of the table "voyce_community_blurbs" */
["voyce_community_blurbs_stream_cursor_input"]: GraphQLTypes["voyce_community_blurbs_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_community_blurbs_stream_cursor_value_input"]: GraphQLTypes["voyce_community_blurbs_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_community_blurbs_sum_fields"]: {
		id?:number,
	order?:number
};
	/** update columns of table "voyce.community_blurbs" */
["voyce_community_blurbs_update_column"]: GraphQLTypes["voyce_community_blurbs_update_column"];
	["voyce_community_blurbs_updates"]: GraphQLTypes["voyce_community_blurbs_updates"];
	/** aggregate var_pop on columns */
["voyce_community_blurbs_var_pop_fields"]: {
		id?:number,
	order?:number
};
	/** aggregate var_samp on columns */
["voyce_community_blurbs_var_samp_fields"]: {
		id?:number,
	order?:number
};
	/** aggregate variance on columns */
["voyce_community_blurbs_variance_fields"]: {
		id?:number,
	order?:number
};
	/** columns and relationships of "voyce.contact_us" */
["voyce_contact_us"]: {
		created_at:ModelTypes["timestamptz"],
	email:string,
	first_name:string,
	id:number,
	last_name:string,
	message:string,
	number:string,
	reason:string
};
	/** aggregated selection of "voyce.contact_us" */
["voyce_contact_us_aggregate"]: {
		aggregate?:ModelTypes["voyce_contact_us_aggregate_fields"],
	nodes:ModelTypes["voyce_contact_us"][]
};
	/** aggregate fields of "voyce.contact_us" */
["voyce_contact_us_aggregate_fields"]: {
		avg?:ModelTypes["voyce_contact_us_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_contact_us_max_fields"],
	min?:ModelTypes["voyce_contact_us_min_fields"],
	stddev?:ModelTypes["voyce_contact_us_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_contact_us_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_contact_us_stddev_samp_fields"],
	sum?:ModelTypes["voyce_contact_us_sum_fields"],
	var_pop?:ModelTypes["voyce_contact_us_var_pop_fields"],
	var_samp?:ModelTypes["voyce_contact_us_var_samp_fields"],
	variance?:ModelTypes["voyce_contact_us_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_contact_us_avg_fields"]: {
		id?:number
};
	/** Boolean expression to filter rows from the table "voyce.contact_us". All fields are combined with a logical 'AND'. */
["voyce_contact_us_bool_exp"]: GraphQLTypes["voyce_contact_us_bool_exp"];
	/** unique or primary key constraints on table "voyce.contact_us" */
["voyce_contact_us_constraint"]: GraphQLTypes["voyce_contact_us_constraint"];
	/** input type for incrementing numeric columns in table "voyce.contact_us" */
["voyce_contact_us_inc_input"]: GraphQLTypes["voyce_contact_us_inc_input"];
	/** input type for inserting data into table "voyce.contact_us" */
["voyce_contact_us_insert_input"]: GraphQLTypes["voyce_contact_us_insert_input"];
	/** aggregate max on columns */
["voyce_contact_us_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	email?:string,
	first_name?:string,
	id?:number,
	last_name?:string,
	message?:string,
	number?:string,
	reason?:string
};
	/** aggregate min on columns */
["voyce_contact_us_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	email?:string,
	first_name?:string,
	id?:number,
	last_name?:string,
	message?:string,
	number?:string,
	reason?:string
};
	/** response of any mutation on the table "voyce.contact_us" */
["voyce_contact_us_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_contact_us"][]
};
	/** on_conflict condition type for table "voyce.contact_us" */
["voyce_contact_us_on_conflict"]: GraphQLTypes["voyce_contact_us_on_conflict"];
	/** Ordering options when selecting data from "voyce.contact_us". */
["voyce_contact_us_order_by"]: GraphQLTypes["voyce_contact_us_order_by"];
	/** primary key columns input for table: voyce.contact_us */
["voyce_contact_us_pk_columns_input"]: GraphQLTypes["voyce_contact_us_pk_columns_input"];
	/** select columns of table "voyce.contact_us" */
["voyce_contact_us_select_column"]: GraphQLTypes["voyce_contact_us_select_column"];
	/** input type for updating data in table "voyce.contact_us" */
["voyce_contact_us_set_input"]: GraphQLTypes["voyce_contact_us_set_input"];
	/** aggregate stddev on columns */
["voyce_contact_us_stddev_fields"]: {
		id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_contact_us_stddev_pop_fields"]: {
		id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_contact_us_stddev_samp_fields"]: {
		id?:number
};
	/** Streaming cursor of the table "voyce_contact_us" */
["voyce_contact_us_stream_cursor_input"]: GraphQLTypes["voyce_contact_us_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_contact_us_stream_cursor_value_input"]: GraphQLTypes["voyce_contact_us_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_contact_us_sum_fields"]: {
		id?:number
};
	/** update columns of table "voyce.contact_us" */
["voyce_contact_us_update_column"]: GraphQLTypes["voyce_contact_us_update_column"];
	["voyce_contact_us_updates"]: GraphQLTypes["voyce_contact_us_updates"];
	/** aggregate var_pop on columns */
["voyce_contact_us_var_pop_fields"]: {
		id?:number
};
	/** aggregate var_samp on columns */
["voyce_contact_us_var_samp_fields"]: {
		id?:number
};
	/** aggregate variance on columns */
["voyce_contact_us_variance_fields"]: {
		id?:number
};
	/** columns and relationships of "voyce.content_report" */
["voyce_content_report"]: {
		created_at:ModelTypes["timestamptz"],
	extras?:string,
	id:number,
	linked_id:number,
	reason:string,
	type:string,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.content_report" */
["voyce_content_report_aggregate"]: {
		aggregate?:ModelTypes["voyce_content_report_aggregate_fields"],
	nodes:ModelTypes["voyce_content_report"][]
};
	/** aggregate fields of "voyce.content_report" */
["voyce_content_report_aggregate_fields"]: {
		avg?:ModelTypes["voyce_content_report_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_content_report_max_fields"],
	min?:ModelTypes["voyce_content_report_min_fields"],
	stddev?:ModelTypes["voyce_content_report_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_content_report_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_content_report_stddev_samp_fields"],
	sum?:ModelTypes["voyce_content_report_sum_fields"],
	var_pop?:ModelTypes["voyce_content_report_var_pop_fields"],
	var_samp?:ModelTypes["voyce_content_report_var_samp_fields"],
	variance?:ModelTypes["voyce_content_report_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_content_report_avg_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.content_report". All fields are combined with a logical 'AND'. */
["voyce_content_report_bool_exp"]: GraphQLTypes["voyce_content_report_bool_exp"];
	/** unique or primary key constraints on table "voyce.content_report" */
["voyce_content_report_constraint"]: GraphQLTypes["voyce_content_report_constraint"];
	/** input type for incrementing numeric columns in table "voyce.content_report" */
["voyce_content_report_inc_input"]: GraphQLTypes["voyce_content_report_inc_input"];
	/** input type for inserting data into table "voyce.content_report" */
["voyce_content_report_insert_input"]: GraphQLTypes["voyce_content_report_insert_input"];
	/** aggregate max on columns */
["voyce_content_report_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	extras?:string,
	id?:number,
	linked_id?:number,
	reason?:string,
	type?:string,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_content_report_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	extras?:string,
	id?:number,
	linked_id?:number,
	reason?:string,
	type?:string,
	user_id?:number
};
	/** response of any mutation on the table "voyce.content_report" */
["voyce_content_report_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_content_report"][]
};
	/** on_conflict condition type for table "voyce.content_report" */
["voyce_content_report_on_conflict"]: GraphQLTypes["voyce_content_report_on_conflict"];
	/** Ordering options when selecting data from "voyce.content_report". */
["voyce_content_report_order_by"]: GraphQLTypes["voyce_content_report_order_by"];
	/** primary key columns input for table: voyce.content_report */
["voyce_content_report_pk_columns_input"]: GraphQLTypes["voyce_content_report_pk_columns_input"];
	/** select columns of table "voyce.content_report" */
["voyce_content_report_select_column"]: GraphQLTypes["voyce_content_report_select_column"];
	/** input type for updating data in table "voyce.content_report" */
["voyce_content_report_set_input"]: GraphQLTypes["voyce_content_report_set_input"];
	/** aggregate stddev on columns */
["voyce_content_report_stddev_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_content_report_stddev_pop_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_content_report_stddev_samp_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_content_report" */
["voyce_content_report_stream_cursor_input"]: GraphQLTypes["voyce_content_report_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_content_report_stream_cursor_value_input"]: GraphQLTypes["voyce_content_report_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_content_report_sum_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** update columns of table "voyce.content_report" */
["voyce_content_report_update_column"]: GraphQLTypes["voyce_content_report_update_column"];
	["voyce_content_report_updates"]: GraphQLTypes["voyce_content_report_updates"];
	/** aggregate var_pop on columns */
["voyce_content_report_var_pop_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_content_report_var_samp_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_content_report_variance_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** Table for storing data about contest submits */
["voyce_contest_data"]: {
		age?:number,
	created_at:ModelTypes["timestamp"],
	discord_username?:string,
	first_arc?:string,
	id:number,
	name?:string,
	pdf?:string,
	social_media?:string,
	story_pitch?:string,
	story_summary?:string,
	target_audience?:string,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number,
	where_did_hear?:string
};
	/** aggregated selection of "voyce.contest_data" */
["voyce_contest_data_aggregate"]: {
		aggregate?:ModelTypes["voyce_contest_data_aggregate_fields"],
	nodes:ModelTypes["voyce_contest_data"][]
};
	/** aggregate fields of "voyce.contest_data" */
["voyce_contest_data_aggregate_fields"]: {
		avg?:ModelTypes["voyce_contest_data_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_contest_data_max_fields"],
	min?:ModelTypes["voyce_contest_data_min_fields"],
	stddev?:ModelTypes["voyce_contest_data_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_contest_data_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_contest_data_stddev_samp_fields"],
	sum?:ModelTypes["voyce_contest_data_sum_fields"],
	var_pop?:ModelTypes["voyce_contest_data_var_pop_fields"],
	var_samp?:ModelTypes["voyce_contest_data_var_samp_fields"],
	variance?:ModelTypes["voyce_contest_data_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_contest_data_avg_fields"]: {
		age?:number,
	id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.contest_data". All fields are combined with a logical 'AND'. */
["voyce_contest_data_bool_exp"]: GraphQLTypes["voyce_contest_data_bool_exp"];
	/** unique or primary key constraints on table "voyce.contest_data" */
["voyce_contest_data_constraint"]: GraphQLTypes["voyce_contest_data_constraint"];
	/** input type for incrementing numeric columns in table "voyce.contest_data" */
["voyce_contest_data_inc_input"]: GraphQLTypes["voyce_contest_data_inc_input"];
	/** input type for inserting data into table "voyce.contest_data" */
["voyce_contest_data_insert_input"]: GraphQLTypes["voyce_contest_data_insert_input"];
	/** aggregate max on columns */
["voyce_contest_data_max_fields"]: {
		age?:number,
	created_at?:ModelTypes["timestamp"],
	discord_username?:string,
	first_arc?:string,
	id?:number,
	name?:string,
	pdf?:string,
	social_media?:string,
	story_pitch?:string,
	story_summary?:string,
	target_audience?:string,
	user_id?:number,
	where_did_hear?:string
};
	/** aggregate min on columns */
["voyce_contest_data_min_fields"]: {
		age?:number,
	created_at?:ModelTypes["timestamp"],
	discord_username?:string,
	first_arc?:string,
	id?:number,
	name?:string,
	pdf?:string,
	social_media?:string,
	story_pitch?:string,
	story_summary?:string,
	target_audience?:string,
	user_id?:number,
	where_did_hear?:string
};
	/** response of any mutation on the table "voyce.contest_data" */
["voyce_contest_data_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_contest_data"][]
};
	/** on_conflict condition type for table "voyce.contest_data" */
["voyce_contest_data_on_conflict"]: GraphQLTypes["voyce_contest_data_on_conflict"];
	/** Ordering options when selecting data from "voyce.contest_data". */
["voyce_contest_data_order_by"]: GraphQLTypes["voyce_contest_data_order_by"];
	/** primary key columns input for table: voyce.contest_data */
["voyce_contest_data_pk_columns_input"]: GraphQLTypes["voyce_contest_data_pk_columns_input"];
	/** select columns of table "voyce.contest_data" */
["voyce_contest_data_select_column"]: GraphQLTypes["voyce_contest_data_select_column"];
	/** input type for updating data in table "voyce.contest_data" */
["voyce_contest_data_set_input"]: GraphQLTypes["voyce_contest_data_set_input"];
	/** aggregate stddev on columns */
["voyce_contest_data_stddev_fields"]: {
		age?:number,
	id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_contest_data_stddev_pop_fields"]: {
		age?:number,
	id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_contest_data_stddev_samp_fields"]: {
		age?:number,
	id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_contest_data" */
["voyce_contest_data_stream_cursor_input"]: GraphQLTypes["voyce_contest_data_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_contest_data_stream_cursor_value_input"]: GraphQLTypes["voyce_contest_data_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_contest_data_sum_fields"]: {
		age?:number,
	id?:number,
	user_id?:number
};
	/** update columns of table "voyce.contest_data" */
["voyce_contest_data_update_column"]: GraphQLTypes["voyce_contest_data_update_column"];
	["voyce_contest_data_updates"]: GraphQLTypes["voyce_contest_data_updates"];
	/** aggregate var_pop on columns */
["voyce_contest_data_var_pop_fields"]: {
		age?:number,
	id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_contest_data_var_samp_fields"]: {
		age?:number,
	id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_contest_data_variance_fields"]: {
		age?:number,
	id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.countries" */
["voyce_countries"]: {
		date_create:ModelTypes["timestamp"],
	description?:string,
	feature:ModelTypes["smallint"],
	flag:string,
	id:number,
	name:string,
	phone_code:number,
	publish:ModelTypes["smallint"],
	sort_name:string
};
	/** aggregated selection of "voyce.countries" */
["voyce_countries_aggregate"]: {
		aggregate?:ModelTypes["voyce_countries_aggregate_fields"],
	nodes:ModelTypes["voyce_countries"][]
};
	/** aggregate fields of "voyce.countries" */
["voyce_countries_aggregate_fields"]: {
		avg?:ModelTypes["voyce_countries_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_countries_max_fields"],
	min?:ModelTypes["voyce_countries_min_fields"],
	stddev?:ModelTypes["voyce_countries_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_countries_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_countries_stddev_samp_fields"],
	sum?:ModelTypes["voyce_countries_sum_fields"],
	var_pop?:ModelTypes["voyce_countries_var_pop_fields"],
	var_samp?:ModelTypes["voyce_countries_var_samp_fields"],
	variance?:ModelTypes["voyce_countries_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_countries_avg_fields"]: {
		feature?:number,
	id?:number,
	phone_code?:number,
	publish?:number
};
	/** Boolean expression to filter rows from the table "voyce.countries". All fields are combined with a logical 'AND'. */
["voyce_countries_bool_exp"]: GraphQLTypes["voyce_countries_bool_exp"];
	/** unique or primary key constraints on table "voyce.countries" */
["voyce_countries_constraint"]: GraphQLTypes["voyce_countries_constraint"];
	/** input type for incrementing numeric columns in table "voyce.countries" */
["voyce_countries_inc_input"]: GraphQLTypes["voyce_countries_inc_input"];
	/** input type for inserting data into table "voyce.countries" */
["voyce_countries_insert_input"]: GraphQLTypes["voyce_countries_insert_input"];
	/** aggregate max on columns */
["voyce_countries_max_fields"]: {
		date_create?:ModelTypes["timestamp"],
	description?:string,
	feature?:ModelTypes["smallint"],
	flag?:string,
	id?:number,
	name?:string,
	phone_code?:number,
	publish?:ModelTypes["smallint"],
	sort_name?:string
};
	/** aggregate min on columns */
["voyce_countries_min_fields"]: {
		date_create?:ModelTypes["timestamp"],
	description?:string,
	feature?:ModelTypes["smallint"],
	flag?:string,
	id?:number,
	name?:string,
	phone_code?:number,
	publish?:ModelTypes["smallint"],
	sort_name?:string
};
	/** response of any mutation on the table "voyce.countries" */
["voyce_countries_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_countries"][]
};
	/** on_conflict condition type for table "voyce.countries" */
["voyce_countries_on_conflict"]: GraphQLTypes["voyce_countries_on_conflict"];
	/** Ordering options when selecting data from "voyce.countries". */
["voyce_countries_order_by"]: GraphQLTypes["voyce_countries_order_by"];
	/** primary key columns input for table: voyce.countries */
["voyce_countries_pk_columns_input"]: GraphQLTypes["voyce_countries_pk_columns_input"];
	/** select columns of table "voyce.countries" */
["voyce_countries_select_column"]: GraphQLTypes["voyce_countries_select_column"];
	/** input type for updating data in table "voyce.countries" */
["voyce_countries_set_input"]: GraphQLTypes["voyce_countries_set_input"];
	/** aggregate stddev on columns */
["voyce_countries_stddev_fields"]: {
		feature?:number,
	id?:number,
	phone_code?:number,
	publish?:number
};
	/** aggregate stddev_pop on columns */
["voyce_countries_stddev_pop_fields"]: {
		feature?:number,
	id?:number,
	phone_code?:number,
	publish?:number
};
	/** aggregate stddev_samp on columns */
["voyce_countries_stddev_samp_fields"]: {
		feature?:number,
	id?:number,
	phone_code?:number,
	publish?:number
};
	/** Streaming cursor of the table "voyce_countries" */
["voyce_countries_stream_cursor_input"]: GraphQLTypes["voyce_countries_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_countries_stream_cursor_value_input"]: GraphQLTypes["voyce_countries_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_countries_sum_fields"]: {
		feature?:ModelTypes["smallint"],
	id?:number,
	phone_code?:number,
	publish?:ModelTypes["smallint"]
};
	/** update columns of table "voyce.countries" */
["voyce_countries_update_column"]: GraphQLTypes["voyce_countries_update_column"];
	["voyce_countries_updates"]: GraphQLTypes["voyce_countries_updates"];
	/** aggregate var_pop on columns */
["voyce_countries_var_pop_fields"]: {
		feature?:number,
	id?:number,
	phone_code?:number,
	publish?:number
};
	/** aggregate var_samp on columns */
["voyce_countries_var_samp_fields"]: {
		feature?:number,
	id?:number,
	phone_code?:number,
	publish?:number
};
	/** aggregate variance on columns */
["voyce_countries_variance_fields"]: {
		feature?:number,
	id?:number,
	phone_code?:number,
	publish?:number
};
	/** columns and relationships of "voyce.csat_ratings" */
["voyce_csat_ratings"]: {
		cancel_text?:string,
	comment:boolean,
	created_at:ModelTypes["timestamptz"],
	/** An array relationship */
	data:ModelTypes["voyce_csat_ratings_data"][],
	/** An aggregate relationship */
	data_aggregate:ModelTypes["voyce_csat_ratings_data_aggregate"],
	desc:string,
	id:number,
	screen:number,
	title:string,
	version:ModelTypes["smallint"]
};
	/** aggregated selection of "voyce.csat_ratings" */
["voyce_csat_ratings_aggregate"]: {
		aggregate?:ModelTypes["voyce_csat_ratings_aggregate_fields"],
	nodes:ModelTypes["voyce_csat_ratings"][]
};
	/** aggregate fields of "voyce.csat_ratings" */
["voyce_csat_ratings_aggregate_fields"]: {
		avg?:ModelTypes["voyce_csat_ratings_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_csat_ratings_max_fields"],
	min?:ModelTypes["voyce_csat_ratings_min_fields"],
	stddev?:ModelTypes["voyce_csat_ratings_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_csat_ratings_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_csat_ratings_stddev_samp_fields"],
	sum?:ModelTypes["voyce_csat_ratings_sum_fields"],
	var_pop?:ModelTypes["voyce_csat_ratings_var_pop_fields"],
	var_samp?:ModelTypes["voyce_csat_ratings_var_samp_fields"],
	variance?:ModelTypes["voyce_csat_ratings_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_csat_ratings_avg_fields"]: {
		id?:number,
	screen?:number,
	version?:number
};
	/** Boolean expression to filter rows from the table "voyce.csat_ratings". All fields are combined with a logical 'AND'. */
["voyce_csat_ratings_bool_exp"]: GraphQLTypes["voyce_csat_ratings_bool_exp"];
	/** unique or primary key constraints on table "voyce.csat_ratings" */
["voyce_csat_ratings_constraint"]: GraphQLTypes["voyce_csat_ratings_constraint"];
	/** columns and relationships of "voyce.csat_ratings_data" */
["voyce_csat_ratings_data"]: {
		created_at:ModelTypes["timestamptz"],
	csat_rating_id:number,
	id:number,
	image?:string,
	label?:string,
	order:ModelTypes["smallint"],
	rating?:string
};
	/** aggregated selection of "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_aggregate"]: {
		aggregate?:ModelTypes["voyce_csat_ratings_data_aggregate_fields"],
	nodes:ModelTypes["voyce_csat_ratings_data"][]
};
	["voyce_csat_ratings_data_aggregate_bool_exp"]: GraphQLTypes["voyce_csat_ratings_data_aggregate_bool_exp"];
	["voyce_csat_ratings_data_aggregate_bool_exp_count"]: GraphQLTypes["voyce_csat_ratings_data_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_aggregate_fields"]: {
		avg?:ModelTypes["voyce_csat_ratings_data_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_csat_ratings_data_max_fields"],
	min?:ModelTypes["voyce_csat_ratings_data_min_fields"],
	stddev?:ModelTypes["voyce_csat_ratings_data_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_csat_ratings_data_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_csat_ratings_data_stddev_samp_fields"],
	sum?:ModelTypes["voyce_csat_ratings_data_sum_fields"],
	var_pop?:ModelTypes["voyce_csat_ratings_data_var_pop_fields"],
	var_samp?:ModelTypes["voyce_csat_ratings_data_var_samp_fields"],
	variance?:ModelTypes["voyce_csat_ratings_data_variance_fields"]
};
	/** order by aggregate values of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_aggregate_order_by"]: GraphQLTypes["voyce_csat_ratings_data_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_arr_rel_insert_input"]: GraphQLTypes["voyce_csat_ratings_data_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_csat_ratings_data_avg_fields"]: {
		csat_rating_id?:number,
	id?:number,
	order?:number
};
	/** order by avg() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_avg_order_by"]: GraphQLTypes["voyce_csat_ratings_data_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.csat_ratings_data". All fields are combined with a logical 'AND'. */
["voyce_csat_ratings_data_bool_exp"]: GraphQLTypes["voyce_csat_ratings_data_bool_exp"];
	/** unique or primary key constraints on table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_constraint"]: GraphQLTypes["voyce_csat_ratings_data_constraint"];
	/** input type for incrementing numeric columns in table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_inc_input"]: GraphQLTypes["voyce_csat_ratings_data_inc_input"];
	/** input type for inserting data into table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_insert_input"]: GraphQLTypes["voyce_csat_ratings_data_insert_input"];
	/** aggregate max on columns */
["voyce_csat_ratings_data_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	csat_rating_id?:number,
	id?:number,
	image?:string,
	label?:string,
	order?:ModelTypes["smallint"],
	rating?:string
};
	/** order by max() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_max_order_by"]: GraphQLTypes["voyce_csat_ratings_data_max_order_by"];
	/** aggregate min on columns */
["voyce_csat_ratings_data_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	csat_rating_id?:number,
	id?:number,
	image?:string,
	label?:string,
	order?:ModelTypes["smallint"],
	rating?:string
};
	/** order by min() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_min_order_by"]: GraphQLTypes["voyce_csat_ratings_data_min_order_by"];
	/** response of any mutation on the table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_csat_ratings_data"][]
};
	/** on_conflict condition type for table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_on_conflict"]: GraphQLTypes["voyce_csat_ratings_data_on_conflict"];
	/** Ordering options when selecting data from "voyce.csat_ratings_data". */
["voyce_csat_ratings_data_order_by"]: GraphQLTypes["voyce_csat_ratings_data_order_by"];
	/** primary key columns input for table: voyce.csat_ratings_data */
["voyce_csat_ratings_data_pk_columns_input"]: GraphQLTypes["voyce_csat_ratings_data_pk_columns_input"];
	/** select columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_select_column"]: GraphQLTypes["voyce_csat_ratings_data_select_column"];
	/** input type for updating data in table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_set_input"]: GraphQLTypes["voyce_csat_ratings_data_set_input"];
	/** aggregate stddev on columns */
["voyce_csat_ratings_data_stddev_fields"]: {
		csat_rating_id?:number,
	id?:number,
	order?:number
};
	/** order by stddev() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_stddev_order_by"]: GraphQLTypes["voyce_csat_ratings_data_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_csat_ratings_data_stddev_pop_fields"]: {
		csat_rating_id?:number,
	id?:number,
	order?:number
};
	/** order by stddev_pop() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_stddev_pop_order_by"]: GraphQLTypes["voyce_csat_ratings_data_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_csat_ratings_data_stddev_samp_fields"]: {
		csat_rating_id?:number,
	id?:number,
	order?:number
};
	/** order by stddev_samp() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_stddev_samp_order_by"]: GraphQLTypes["voyce_csat_ratings_data_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_csat_ratings_data" */
["voyce_csat_ratings_data_stream_cursor_input"]: GraphQLTypes["voyce_csat_ratings_data_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_csat_ratings_data_stream_cursor_value_input"]: GraphQLTypes["voyce_csat_ratings_data_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_csat_ratings_data_sum_fields"]: {
		csat_rating_id?:number,
	id?:number,
	order?:ModelTypes["smallint"]
};
	/** order by sum() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_sum_order_by"]: GraphQLTypes["voyce_csat_ratings_data_sum_order_by"];
	/** update columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_update_column"]: GraphQLTypes["voyce_csat_ratings_data_update_column"];
	["voyce_csat_ratings_data_updates"]: GraphQLTypes["voyce_csat_ratings_data_updates"];
	/** aggregate var_pop on columns */
["voyce_csat_ratings_data_var_pop_fields"]: {
		csat_rating_id?:number,
	id?:number,
	order?:number
};
	/** order by var_pop() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_var_pop_order_by"]: GraphQLTypes["voyce_csat_ratings_data_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_csat_ratings_data_var_samp_fields"]: {
		csat_rating_id?:number,
	id?:number,
	order?:number
};
	/** order by var_samp() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_var_samp_order_by"]: GraphQLTypes["voyce_csat_ratings_data_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_csat_ratings_data_variance_fields"]: {
		csat_rating_id?:number,
	id?:number,
	order?:number
};
	/** order by variance() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_variance_order_by"]: GraphQLTypes["voyce_csat_ratings_data_variance_order_by"];
	/** input type for incrementing numeric columns in table "voyce.csat_ratings" */
["voyce_csat_ratings_inc_input"]: GraphQLTypes["voyce_csat_ratings_inc_input"];
	/** input type for inserting data into table "voyce.csat_ratings" */
["voyce_csat_ratings_insert_input"]: GraphQLTypes["voyce_csat_ratings_insert_input"];
	/** aggregate max on columns */
["voyce_csat_ratings_max_fields"]: {
		cancel_text?:string,
	created_at?:ModelTypes["timestamptz"],
	desc?:string,
	id?:number,
	screen?:number,
	title?:string,
	version?:ModelTypes["smallint"]
};
	/** aggregate min on columns */
["voyce_csat_ratings_min_fields"]: {
		cancel_text?:string,
	created_at?:ModelTypes["timestamptz"],
	desc?:string,
	id?:number,
	screen?:number,
	title?:string,
	version?:ModelTypes["smallint"]
};
	/** response of any mutation on the table "voyce.csat_ratings" */
["voyce_csat_ratings_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_csat_ratings"][]
};
	/** on_conflict condition type for table "voyce.csat_ratings" */
["voyce_csat_ratings_on_conflict"]: GraphQLTypes["voyce_csat_ratings_on_conflict"];
	/** Ordering options when selecting data from "voyce.csat_ratings". */
["voyce_csat_ratings_order_by"]: GraphQLTypes["voyce_csat_ratings_order_by"];
	/** primary key columns input for table: voyce.csat_ratings */
["voyce_csat_ratings_pk_columns_input"]: GraphQLTypes["voyce_csat_ratings_pk_columns_input"];
	/** select columns of table "voyce.csat_ratings" */
["voyce_csat_ratings_select_column"]: GraphQLTypes["voyce_csat_ratings_select_column"];
	/** input type for updating data in table "voyce.csat_ratings" */
["voyce_csat_ratings_set_input"]: GraphQLTypes["voyce_csat_ratings_set_input"];
	/** aggregate stddev on columns */
["voyce_csat_ratings_stddev_fields"]: {
		id?:number,
	screen?:number,
	version?:number
};
	/** aggregate stddev_pop on columns */
["voyce_csat_ratings_stddev_pop_fields"]: {
		id?:number,
	screen?:number,
	version?:number
};
	/** aggregate stddev_samp on columns */
["voyce_csat_ratings_stddev_samp_fields"]: {
		id?:number,
	screen?:number,
	version?:number
};
	/** Streaming cursor of the table "voyce_csat_ratings" */
["voyce_csat_ratings_stream_cursor_input"]: GraphQLTypes["voyce_csat_ratings_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_csat_ratings_stream_cursor_value_input"]: GraphQLTypes["voyce_csat_ratings_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_csat_ratings_sum_fields"]: {
		id?:number,
	screen?:number,
	version?:ModelTypes["smallint"]
};
	/** update columns of table "voyce.csat_ratings" */
["voyce_csat_ratings_update_column"]: GraphQLTypes["voyce_csat_ratings_update_column"];
	["voyce_csat_ratings_updates"]: GraphQLTypes["voyce_csat_ratings_updates"];
	/** aggregate var_pop on columns */
["voyce_csat_ratings_var_pop_fields"]: {
		id?:number,
	screen?:number,
	version?:number
};
	/** aggregate var_samp on columns */
["voyce_csat_ratings_var_samp_fields"]: {
		id?:number,
	screen?:number,
	version?:number
};
	/** aggregate variance on columns */
["voyce_csat_ratings_variance_fields"]: {
		id?:number,
	screen?:number,
	version?:number
};
	/** columns and relationships of "voyce.education_types" */
["voyce_education_types"]: {
		date_create:ModelTypes["timestamp"],
	id:number,
	title:string
};
	/** aggregated selection of "voyce.education_types" */
["voyce_education_types_aggregate"]: {
		aggregate?:ModelTypes["voyce_education_types_aggregate_fields"],
	nodes:ModelTypes["voyce_education_types"][]
};
	/** aggregate fields of "voyce.education_types" */
["voyce_education_types_aggregate_fields"]: {
		avg?:ModelTypes["voyce_education_types_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_education_types_max_fields"],
	min?:ModelTypes["voyce_education_types_min_fields"],
	stddev?:ModelTypes["voyce_education_types_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_education_types_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_education_types_stddev_samp_fields"],
	sum?:ModelTypes["voyce_education_types_sum_fields"],
	var_pop?:ModelTypes["voyce_education_types_var_pop_fields"],
	var_samp?:ModelTypes["voyce_education_types_var_samp_fields"],
	variance?:ModelTypes["voyce_education_types_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_education_types_avg_fields"]: {
		id?:number
};
	/** Boolean expression to filter rows from the table "voyce.education_types". All fields are combined with a logical 'AND'. */
["voyce_education_types_bool_exp"]: GraphQLTypes["voyce_education_types_bool_exp"];
	/** unique or primary key constraints on table "voyce.education_types" */
["voyce_education_types_constraint"]: GraphQLTypes["voyce_education_types_constraint"];
	/** input type for incrementing numeric columns in table "voyce.education_types" */
["voyce_education_types_inc_input"]: GraphQLTypes["voyce_education_types_inc_input"];
	/** input type for inserting data into table "voyce.education_types" */
["voyce_education_types_insert_input"]: GraphQLTypes["voyce_education_types_insert_input"];
	/** aggregate max on columns */
["voyce_education_types_max_fields"]: {
		date_create?:ModelTypes["timestamp"],
	id?:number,
	title?:string
};
	/** aggregate min on columns */
["voyce_education_types_min_fields"]: {
		date_create?:ModelTypes["timestamp"],
	id?:number,
	title?:string
};
	/** response of any mutation on the table "voyce.education_types" */
["voyce_education_types_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_education_types"][]
};
	/** on_conflict condition type for table "voyce.education_types" */
["voyce_education_types_on_conflict"]: GraphQLTypes["voyce_education_types_on_conflict"];
	/** Ordering options when selecting data from "voyce.education_types". */
["voyce_education_types_order_by"]: GraphQLTypes["voyce_education_types_order_by"];
	/** primary key columns input for table: voyce.education_types */
["voyce_education_types_pk_columns_input"]: GraphQLTypes["voyce_education_types_pk_columns_input"];
	/** select columns of table "voyce.education_types" */
["voyce_education_types_select_column"]: GraphQLTypes["voyce_education_types_select_column"];
	/** input type for updating data in table "voyce.education_types" */
["voyce_education_types_set_input"]: GraphQLTypes["voyce_education_types_set_input"];
	/** aggregate stddev on columns */
["voyce_education_types_stddev_fields"]: {
		id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_education_types_stddev_pop_fields"]: {
		id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_education_types_stddev_samp_fields"]: {
		id?:number
};
	/** Streaming cursor of the table "voyce_education_types" */
["voyce_education_types_stream_cursor_input"]: GraphQLTypes["voyce_education_types_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_education_types_stream_cursor_value_input"]: GraphQLTypes["voyce_education_types_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_education_types_sum_fields"]: {
		id?:number
};
	/** update columns of table "voyce.education_types" */
["voyce_education_types_update_column"]: GraphQLTypes["voyce_education_types_update_column"];
	["voyce_education_types_updates"]: GraphQLTypes["voyce_education_types_updates"];
	/** aggregate var_pop on columns */
["voyce_education_types_var_pop_fields"]: {
		id?:number
};
	/** aggregate var_samp on columns */
["voyce_education_types_var_samp_fields"]: {
		id?:number
};
	/** aggregate variance on columns */
["voyce_education_types_variance_fields"]: {
		id?:number
};
	/** columns and relationships of "voyce.erp_login_roles" */
["voyce_erp_login_roles"]: {
		id:number,
	role_id:number
};
	/** aggregated selection of "voyce.erp_login_roles" */
["voyce_erp_login_roles_aggregate"]: {
		aggregate?:ModelTypes["voyce_erp_login_roles_aggregate_fields"],
	nodes:ModelTypes["voyce_erp_login_roles"][]
};
	/** aggregate fields of "voyce.erp_login_roles" */
["voyce_erp_login_roles_aggregate_fields"]: {
		avg?:ModelTypes["voyce_erp_login_roles_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_erp_login_roles_max_fields"],
	min?:ModelTypes["voyce_erp_login_roles_min_fields"],
	stddev?:ModelTypes["voyce_erp_login_roles_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_erp_login_roles_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_erp_login_roles_stddev_samp_fields"],
	sum?:ModelTypes["voyce_erp_login_roles_sum_fields"],
	var_pop?:ModelTypes["voyce_erp_login_roles_var_pop_fields"],
	var_samp?:ModelTypes["voyce_erp_login_roles_var_samp_fields"],
	variance?:ModelTypes["voyce_erp_login_roles_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_erp_login_roles_avg_fields"]: {
		id?:number,
	role_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.erp_login_roles". All fields are combined with a logical 'AND'. */
["voyce_erp_login_roles_bool_exp"]: GraphQLTypes["voyce_erp_login_roles_bool_exp"];
	/** unique or primary key constraints on table "voyce.erp_login_roles" */
["voyce_erp_login_roles_constraint"]: GraphQLTypes["voyce_erp_login_roles_constraint"];
	/** input type for incrementing numeric columns in table "voyce.erp_login_roles" */
["voyce_erp_login_roles_inc_input"]: GraphQLTypes["voyce_erp_login_roles_inc_input"];
	/** input type for inserting data into table "voyce.erp_login_roles" */
["voyce_erp_login_roles_insert_input"]: GraphQLTypes["voyce_erp_login_roles_insert_input"];
	/** aggregate max on columns */
["voyce_erp_login_roles_max_fields"]: {
		id?:number,
	role_id?:number
};
	/** aggregate min on columns */
["voyce_erp_login_roles_min_fields"]: {
		id?:number,
	role_id?:number
};
	/** response of any mutation on the table "voyce.erp_login_roles" */
["voyce_erp_login_roles_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_erp_login_roles"][]
};
	/** on_conflict condition type for table "voyce.erp_login_roles" */
["voyce_erp_login_roles_on_conflict"]: GraphQLTypes["voyce_erp_login_roles_on_conflict"];
	/** Ordering options when selecting data from "voyce.erp_login_roles". */
["voyce_erp_login_roles_order_by"]: GraphQLTypes["voyce_erp_login_roles_order_by"];
	/** primary key columns input for table: voyce.erp_login_roles */
["voyce_erp_login_roles_pk_columns_input"]: GraphQLTypes["voyce_erp_login_roles_pk_columns_input"];
	/** select columns of table "voyce.erp_login_roles" */
["voyce_erp_login_roles_select_column"]: GraphQLTypes["voyce_erp_login_roles_select_column"];
	/** input type for updating data in table "voyce.erp_login_roles" */
["voyce_erp_login_roles_set_input"]: GraphQLTypes["voyce_erp_login_roles_set_input"];
	/** aggregate stddev on columns */
["voyce_erp_login_roles_stddev_fields"]: {
		id?:number,
	role_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_erp_login_roles_stddev_pop_fields"]: {
		id?:number,
	role_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_erp_login_roles_stddev_samp_fields"]: {
		id?:number,
	role_id?:number
};
	/** Streaming cursor of the table "voyce_erp_login_roles" */
["voyce_erp_login_roles_stream_cursor_input"]: GraphQLTypes["voyce_erp_login_roles_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_erp_login_roles_stream_cursor_value_input"]: GraphQLTypes["voyce_erp_login_roles_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_erp_login_roles_sum_fields"]: {
		id?:number,
	role_id?:number
};
	/** update columns of table "voyce.erp_login_roles" */
["voyce_erp_login_roles_update_column"]: GraphQLTypes["voyce_erp_login_roles_update_column"];
	["voyce_erp_login_roles_updates"]: GraphQLTypes["voyce_erp_login_roles_updates"];
	/** aggregate var_pop on columns */
["voyce_erp_login_roles_var_pop_fields"]: {
		id?:number,
	role_id?:number
};
	/** aggregate var_samp on columns */
["voyce_erp_login_roles_var_samp_fields"]: {
		id?:number,
	role_id?:number
};
	/** aggregate variance on columns */
["voyce_erp_login_roles_variance_fields"]: {
		id?:number,
	role_id?:number
};
	/** columns and relationships of "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs"]: {
		created_at:ModelTypes["timestamptz"],
	glue_epoc:number,
	id:number
};
	/** aggregated selection of "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_aggregate"]: {
		aggregate?:ModelTypes["voyce_etl_series_views_logs_aggregate_fields"],
	nodes:ModelTypes["voyce_etl_series_views_logs"][]
};
	/** aggregate fields of "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_aggregate_fields"]: {
		avg?:ModelTypes["voyce_etl_series_views_logs_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_etl_series_views_logs_max_fields"],
	min?:ModelTypes["voyce_etl_series_views_logs_min_fields"],
	stddev?:ModelTypes["voyce_etl_series_views_logs_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_etl_series_views_logs_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_etl_series_views_logs_stddev_samp_fields"],
	sum?:ModelTypes["voyce_etl_series_views_logs_sum_fields"],
	var_pop?:ModelTypes["voyce_etl_series_views_logs_var_pop_fields"],
	var_samp?:ModelTypes["voyce_etl_series_views_logs_var_samp_fields"],
	variance?:ModelTypes["voyce_etl_series_views_logs_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_etl_series_views_logs_avg_fields"]: {
		glue_epoc?:number,
	id?:number
};
	/** Boolean expression to filter rows from the table "voyce.etl_series_views_logs". All fields are combined with a logical 'AND'. */
["voyce_etl_series_views_logs_bool_exp"]: GraphQLTypes["voyce_etl_series_views_logs_bool_exp"];
	/** unique or primary key constraints on table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_constraint"]: GraphQLTypes["voyce_etl_series_views_logs_constraint"];
	/** input type for incrementing numeric columns in table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_inc_input"]: GraphQLTypes["voyce_etl_series_views_logs_inc_input"];
	/** input type for inserting data into table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_insert_input"]: GraphQLTypes["voyce_etl_series_views_logs_insert_input"];
	/** aggregate max on columns */
["voyce_etl_series_views_logs_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	glue_epoc?:number,
	id?:number
};
	/** aggregate min on columns */
["voyce_etl_series_views_logs_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	glue_epoc?:number,
	id?:number
};
	/** response of any mutation on the table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_etl_series_views_logs"][]
};
	/** on_conflict condition type for table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_on_conflict"]: GraphQLTypes["voyce_etl_series_views_logs_on_conflict"];
	/** Ordering options when selecting data from "voyce.etl_series_views_logs". */
["voyce_etl_series_views_logs_order_by"]: GraphQLTypes["voyce_etl_series_views_logs_order_by"];
	/** primary key columns input for table: voyce.etl_series_views_logs */
["voyce_etl_series_views_logs_pk_columns_input"]: GraphQLTypes["voyce_etl_series_views_logs_pk_columns_input"];
	/** select columns of table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_select_column"]: GraphQLTypes["voyce_etl_series_views_logs_select_column"];
	/** input type for updating data in table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_set_input"]: GraphQLTypes["voyce_etl_series_views_logs_set_input"];
	/** aggregate stddev on columns */
["voyce_etl_series_views_logs_stddev_fields"]: {
		glue_epoc?:number,
	id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_etl_series_views_logs_stddev_pop_fields"]: {
		glue_epoc?:number,
	id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_etl_series_views_logs_stddev_samp_fields"]: {
		glue_epoc?:number,
	id?:number
};
	/** Streaming cursor of the table "voyce_etl_series_views_logs" */
["voyce_etl_series_views_logs_stream_cursor_input"]: GraphQLTypes["voyce_etl_series_views_logs_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_etl_series_views_logs_stream_cursor_value_input"]: GraphQLTypes["voyce_etl_series_views_logs_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_etl_series_views_logs_sum_fields"]: {
		glue_epoc?:number,
	id?:number
};
	/** update columns of table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_update_column"]: GraphQLTypes["voyce_etl_series_views_logs_update_column"];
	["voyce_etl_series_views_logs_updates"]: GraphQLTypes["voyce_etl_series_views_logs_updates"];
	/** aggregate var_pop on columns */
["voyce_etl_series_views_logs_var_pop_fields"]: {
		glue_epoc?:number,
	id?:number
};
	/** aggregate var_samp on columns */
["voyce_etl_series_views_logs_var_samp_fields"]: {
		glue_epoc?:number,
	id?:number
};
	/** aggregate variance on columns */
["voyce_etl_series_views_logs_variance_fields"]: {
		glue_epoc?:number,
	id?:number
};
	/** columns and relationships of "voyce.event_schedules" */
["voyce_event_schedules"]: {
		active?:boolean,
	bg_image?:string,
	created_at:ModelTypes["timestamptz"],
	date_time:ModelTypes["timestamptz"],
	description:string,
	id:number,
	link:string,
	main_event:boolean,
	main_image:string,
	title:string,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.event_schedules" */
["voyce_event_schedules_aggregate"]: {
		aggregate?:ModelTypes["voyce_event_schedules_aggregate_fields"],
	nodes:ModelTypes["voyce_event_schedules"][]
};
	/** aggregate fields of "voyce.event_schedules" */
["voyce_event_schedules_aggregate_fields"]: {
		avg?:ModelTypes["voyce_event_schedules_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_event_schedules_max_fields"],
	min?:ModelTypes["voyce_event_schedules_min_fields"],
	stddev?:ModelTypes["voyce_event_schedules_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_event_schedules_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_event_schedules_stddev_samp_fields"],
	sum?:ModelTypes["voyce_event_schedules_sum_fields"],
	var_pop?:ModelTypes["voyce_event_schedules_var_pop_fields"],
	var_samp?:ModelTypes["voyce_event_schedules_var_samp_fields"],
	variance?:ModelTypes["voyce_event_schedules_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_event_schedules_avg_fields"]: {
		id?:number
};
	/** Boolean expression to filter rows from the table "voyce.event_schedules". All fields are combined with a logical 'AND'. */
["voyce_event_schedules_bool_exp"]: GraphQLTypes["voyce_event_schedules_bool_exp"];
	/** unique or primary key constraints on table "voyce.event_schedules" */
["voyce_event_schedules_constraint"]: GraphQLTypes["voyce_event_schedules_constraint"];
	/** input type for incrementing numeric columns in table "voyce.event_schedules" */
["voyce_event_schedules_inc_input"]: GraphQLTypes["voyce_event_schedules_inc_input"];
	/** input type for inserting data into table "voyce.event_schedules" */
["voyce_event_schedules_insert_input"]: GraphQLTypes["voyce_event_schedules_insert_input"];
	/** aggregate max on columns */
["voyce_event_schedules_max_fields"]: {
		bg_image?:string,
	created_at?:ModelTypes["timestamptz"],
	date_time?:ModelTypes["timestamptz"],
	description?:string,
	id?:number,
	link?:string,
	main_image?:string,
	title?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_event_schedules_min_fields"]: {
		bg_image?:string,
	created_at?:ModelTypes["timestamptz"],
	date_time?:ModelTypes["timestamptz"],
	description?:string,
	id?:number,
	link?:string,
	main_image?:string,
	title?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.event_schedules" */
["voyce_event_schedules_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_event_schedules"][]
};
	/** on_conflict condition type for table "voyce.event_schedules" */
["voyce_event_schedules_on_conflict"]: GraphQLTypes["voyce_event_schedules_on_conflict"];
	/** Ordering options when selecting data from "voyce.event_schedules". */
["voyce_event_schedules_order_by"]: GraphQLTypes["voyce_event_schedules_order_by"];
	/** primary key columns input for table: voyce.event_schedules */
["voyce_event_schedules_pk_columns_input"]: GraphQLTypes["voyce_event_schedules_pk_columns_input"];
	/** select columns of table "voyce.event_schedules" */
["voyce_event_schedules_select_column"]: GraphQLTypes["voyce_event_schedules_select_column"];
	/** input type for updating data in table "voyce.event_schedules" */
["voyce_event_schedules_set_input"]: GraphQLTypes["voyce_event_schedules_set_input"];
	/** aggregate stddev on columns */
["voyce_event_schedules_stddev_fields"]: {
		id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_event_schedules_stddev_pop_fields"]: {
		id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_event_schedules_stddev_samp_fields"]: {
		id?:number
};
	/** Streaming cursor of the table "voyce_event_schedules" */
["voyce_event_schedules_stream_cursor_input"]: GraphQLTypes["voyce_event_schedules_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_event_schedules_stream_cursor_value_input"]: GraphQLTypes["voyce_event_schedules_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_event_schedules_sum_fields"]: {
		id?:number
};
	/** update columns of table "voyce.event_schedules" */
["voyce_event_schedules_update_column"]: GraphQLTypes["voyce_event_schedules_update_column"];
	["voyce_event_schedules_updates"]: GraphQLTypes["voyce_event_schedules_updates"];
	/** aggregate var_pop on columns */
["voyce_event_schedules_var_pop_fields"]: {
		id?:number
};
	/** aggregate var_samp on columns */
["voyce_event_schedules_var_samp_fields"]: {
		id?:number
};
	/** aggregate variance on columns */
["voyce_event_schedules_variance_fields"]: {
		id?:number
};
	/** columns and relationships of "voyce.genres" */
["voyce_genres"]: {
		color?:string,
	created_at:ModelTypes["timestamp"],
	id:number,
	is_trending?:boolean,
	klaviyo_id?:string,
	thumbnail?:string,
	title:string,
	updated_at?:ModelTypes["timestamp"]
};
	/** aggregated selection of "voyce.genres" */
["voyce_genres_aggregate"]: {
		aggregate?:ModelTypes["voyce_genres_aggregate_fields"],
	nodes:ModelTypes["voyce_genres"][]
};
	/** aggregate fields of "voyce.genres" */
["voyce_genres_aggregate_fields"]: {
		avg?:ModelTypes["voyce_genres_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_genres_max_fields"],
	min?:ModelTypes["voyce_genres_min_fields"],
	stddev?:ModelTypes["voyce_genres_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_genres_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_genres_stddev_samp_fields"],
	sum?:ModelTypes["voyce_genres_sum_fields"],
	var_pop?:ModelTypes["voyce_genres_var_pop_fields"],
	var_samp?:ModelTypes["voyce_genres_var_samp_fields"],
	variance?:ModelTypes["voyce_genres_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_genres_avg_fields"]: {
		id?:number
};
	/** Boolean expression to filter rows from the table "voyce.genres". All fields are combined with a logical 'AND'. */
["voyce_genres_bool_exp"]: GraphQLTypes["voyce_genres_bool_exp"];
	/** unique or primary key constraints on table "voyce.genres" */
["voyce_genres_constraint"]: GraphQLTypes["voyce_genres_constraint"];
	/** input type for incrementing numeric columns in table "voyce.genres" */
["voyce_genres_inc_input"]: GraphQLTypes["voyce_genres_inc_input"];
	/** input type for inserting data into table "voyce.genres" */
["voyce_genres_insert_input"]: GraphQLTypes["voyce_genres_insert_input"];
	/** aggregate max on columns */
["voyce_genres_max_fields"]: {
		color?:string,
	created_at?:ModelTypes["timestamp"],
	id?:number,
	klaviyo_id?:string,
	thumbnail?:string,
	title?:string,
	updated_at?:ModelTypes["timestamp"]
};
	/** aggregate min on columns */
["voyce_genres_min_fields"]: {
		color?:string,
	created_at?:ModelTypes["timestamp"],
	id?:number,
	klaviyo_id?:string,
	thumbnail?:string,
	title?:string,
	updated_at?:ModelTypes["timestamp"]
};
	/** response of any mutation on the table "voyce.genres" */
["voyce_genres_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_genres"][]
};
	/** input type for inserting object relation for remote table "voyce.genres" */
["voyce_genres_obj_rel_insert_input"]: GraphQLTypes["voyce_genres_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.genres" */
["voyce_genres_on_conflict"]: GraphQLTypes["voyce_genres_on_conflict"];
	/** Ordering options when selecting data from "voyce.genres". */
["voyce_genres_order_by"]: GraphQLTypes["voyce_genres_order_by"];
	/** primary key columns input for table: voyce.genres */
["voyce_genres_pk_columns_input"]: GraphQLTypes["voyce_genres_pk_columns_input"];
	/** select columns of table "voyce.genres" */
["voyce_genres_select_column"]: GraphQLTypes["voyce_genres_select_column"];
	/** input type for updating data in table "voyce.genres" */
["voyce_genres_set_input"]: GraphQLTypes["voyce_genres_set_input"];
	/** aggregate stddev on columns */
["voyce_genres_stddev_fields"]: {
		id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_genres_stddev_pop_fields"]: {
		id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_genres_stddev_samp_fields"]: {
		id?:number
};
	/** Streaming cursor of the table "voyce_genres" */
["voyce_genres_stream_cursor_input"]: GraphQLTypes["voyce_genres_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_genres_stream_cursor_value_input"]: GraphQLTypes["voyce_genres_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_genres_sum_fields"]: {
		id?:number
};
	/** update columns of table "voyce.genres" */
["voyce_genres_update_column"]: GraphQLTypes["voyce_genres_update_column"];
	["voyce_genres_updates"]: GraphQLTypes["voyce_genres_updates"];
	/** aggregate var_pop on columns */
["voyce_genres_var_pop_fields"]: {
		id?:number
};
	/** aggregate var_samp on columns */
["voyce_genres_var_samp_fields"]: {
		id?:number
};
	/** aggregate variance on columns */
["voyce_genres_variance_fields"]: {
		id?:number
};
	/** columns and relationships of "voyce.guest_gtags" */
["voyce_guest_gtags"]: {
		created_at:ModelTypes["timestamptz"],
	device_id:string,
	/** An array relationship */
	emails:ModelTypes["voyce_popup_emails"][],
	/** An aggregate relationship */
	emails_aggregate:ModelTypes["voyce_popup_emails_aggregate"],
	gtag:string,
	gtag_v2:string,
	id:number,
	klaviyo_id?:string,
	source:string,
	user_ip:string,
	username:string
};
	/** aggregated selection of "voyce.guest_gtags" */
["voyce_guest_gtags_aggregate"]: {
		aggregate?:ModelTypes["voyce_guest_gtags_aggregate_fields"],
	nodes:ModelTypes["voyce_guest_gtags"][]
};
	/** aggregate fields of "voyce.guest_gtags" */
["voyce_guest_gtags_aggregate_fields"]: {
		avg?:ModelTypes["voyce_guest_gtags_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_guest_gtags_max_fields"],
	min?:ModelTypes["voyce_guest_gtags_min_fields"],
	stddev?:ModelTypes["voyce_guest_gtags_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_guest_gtags_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_guest_gtags_stddev_samp_fields"],
	sum?:ModelTypes["voyce_guest_gtags_sum_fields"],
	var_pop?:ModelTypes["voyce_guest_gtags_var_pop_fields"],
	var_samp?:ModelTypes["voyce_guest_gtags_var_samp_fields"],
	variance?:ModelTypes["voyce_guest_gtags_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_guest_gtags_avg_fields"]: {
		id?:number
};
	/** Boolean expression to filter rows from the table "voyce.guest_gtags". All fields are combined with a logical 'AND'. */
["voyce_guest_gtags_bool_exp"]: GraphQLTypes["voyce_guest_gtags_bool_exp"];
	/** unique or primary key constraints on table "voyce.guest_gtags" */
["voyce_guest_gtags_constraint"]: GraphQLTypes["voyce_guest_gtags_constraint"];
	/** input type for incrementing numeric columns in table "voyce.guest_gtags" */
["voyce_guest_gtags_inc_input"]: GraphQLTypes["voyce_guest_gtags_inc_input"];
	/** input type for inserting data into table "voyce.guest_gtags" */
["voyce_guest_gtags_insert_input"]: GraphQLTypes["voyce_guest_gtags_insert_input"];
	/** aggregate max on columns */
["voyce_guest_gtags_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	device_id?:string,
	gtag?:string,
	gtag_v2?:string,
	id?:number,
	klaviyo_id?:string,
	source?:string,
	user_ip?:string,
	username?:string
};
	/** aggregate min on columns */
["voyce_guest_gtags_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	device_id?:string,
	gtag?:string,
	gtag_v2?:string,
	id?:number,
	klaviyo_id?:string,
	source?:string,
	user_ip?:string,
	username?:string
};
	/** response of any mutation on the table "voyce.guest_gtags" */
["voyce_guest_gtags_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_guest_gtags"][]
};
	/** input type for inserting object relation for remote table "voyce.guest_gtags" */
["voyce_guest_gtags_obj_rel_insert_input"]: GraphQLTypes["voyce_guest_gtags_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.guest_gtags" */
["voyce_guest_gtags_on_conflict"]: GraphQLTypes["voyce_guest_gtags_on_conflict"];
	/** Ordering options when selecting data from "voyce.guest_gtags". */
["voyce_guest_gtags_order_by"]: GraphQLTypes["voyce_guest_gtags_order_by"];
	/** primary key columns input for table: voyce.guest_gtags */
["voyce_guest_gtags_pk_columns_input"]: GraphQLTypes["voyce_guest_gtags_pk_columns_input"];
	/** select columns of table "voyce.guest_gtags" */
["voyce_guest_gtags_select_column"]: GraphQLTypes["voyce_guest_gtags_select_column"];
	/** input type for updating data in table "voyce.guest_gtags" */
["voyce_guest_gtags_set_input"]: GraphQLTypes["voyce_guest_gtags_set_input"];
	/** aggregate stddev on columns */
["voyce_guest_gtags_stddev_fields"]: {
		id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_guest_gtags_stddev_pop_fields"]: {
		id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_guest_gtags_stddev_samp_fields"]: {
		id?:number
};
	/** Streaming cursor of the table "voyce_guest_gtags" */
["voyce_guest_gtags_stream_cursor_input"]: GraphQLTypes["voyce_guest_gtags_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_guest_gtags_stream_cursor_value_input"]: GraphQLTypes["voyce_guest_gtags_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_guest_gtags_sum_fields"]: {
		id?:number
};
	/** update columns of table "voyce.guest_gtags" */
["voyce_guest_gtags_update_column"]: GraphQLTypes["voyce_guest_gtags_update_column"];
	["voyce_guest_gtags_updates"]: GraphQLTypes["voyce_guest_gtags_updates"];
	/** aggregate var_pop on columns */
["voyce_guest_gtags_var_pop_fields"]: {
		id?:number
};
	/** aggregate var_samp on columns */
["voyce_guest_gtags_var_samp_fields"]: {
		id?:number
};
	/** aggregate variance on columns */
["voyce_guest_gtags_variance_fields"]: {
		id?:number
};
	/** columns and relationships of "voyce.metabases" */
["voyce_metabases"]: {
		/** An object relationship */
	access?:ModelTypes["voyce_metabases_access"],
	created_at:ModelTypes["timestamptz"],
	dashboard_id:number,
	id:number,
	is_active:boolean,
	order:number,
	title:string,
	updated_at:ModelTypes["timestamptz"]
};
	/** columns and relationships of "voyce.metabases_access" */
["voyce_metabases_access"]: {
		created_at:ModelTypes["timestamptz"],
	id:number,
	metabase_id:number,
	role_id?:number,
	updated_at:ModelTypes["timestamptz"],
	user_id?:number
};
	/** aggregated selection of "voyce.metabases_access" */
["voyce_metabases_access_aggregate"]: {
		aggregate?:ModelTypes["voyce_metabases_access_aggregate_fields"],
	nodes:ModelTypes["voyce_metabases_access"][]
};
	/** aggregate fields of "voyce.metabases_access" */
["voyce_metabases_access_aggregate_fields"]: {
		avg?:ModelTypes["voyce_metabases_access_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_metabases_access_max_fields"],
	min?:ModelTypes["voyce_metabases_access_min_fields"],
	stddev?:ModelTypes["voyce_metabases_access_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_metabases_access_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_metabases_access_stddev_samp_fields"],
	sum?:ModelTypes["voyce_metabases_access_sum_fields"],
	var_pop?:ModelTypes["voyce_metabases_access_var_pop_fields"],
	var_samp?:ModelTypes["voyce_metabases_access_var_samp_fields"],
	variance?:ModelTypes["voyce_metabases_access_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_metabases_access_avg_fields"]: {
		id?:number,
	metabase_id?:number,
	role_id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.metabases_access". All fields are combined with a logical 'AND'. */
["voyce_metabases_access_bool_exp"]: GraphQLTypes["voyce_metabases_access_bool_exp"];
	/** unique or primary key constraints on table "voyce.metabases_access" */
["voyce_metabases_access_constraint"]: GraphQLTypes["voyce_metabases_access_constraint"];
	/** input type for incrementing numeric columns in table "voyce.metabases_access" */
["voyce_metabases_access_inc_input"]: GraphQLTypes["voyce_metabases_access_inc_input"];
	/** input type for inserting data into table "voyce.metabases_access" */
["voyce_metabases_access_insert_input"]: GraphQLTypes["voyce_metabases_access_insert_input"];
	/** aggregate max on columns */
["voyce_metabases_access_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	metabase_id?:number,
	role_id?:number,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number
};
	/** aggregate min on columns */
["voyce_metabases_access_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	metabase_id?:number,
	role_id?:number,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number
};
	/** response of any mutation on the table "voyce.metabases_access" */
["voyce_metabases_access_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_metabases_access"][]
};
	/** input type for inserting object relation for remote table "voyce.metabases_access" */
["voyce_metabases_access_obj_rel_insert_input"]: GraphQLTypes["voyce_metabases_access_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.metabases_access" */
["voyce_metabases_access_on_conflict"]: GraphQLTypes["voyce_metabases_access_on_conflict"];
	/** Ordering options when selecting data from "voyce.metabases_access". */
["voyce_metabases_access_order_by"]: GraphQLTypes["voyce_metabases_access_order_by"];
	/** primary key columns input for table: voyce.metabases_access */
["voyce_metabases_access_pk_columns_input"]: GraphQLTypes["voyce_metabases_access_pk_columns_input"];
	/** select columns of table "voyce.metabases_access" */
["voyce_metabases_access_select_column"]: GraphQLTypes["voyce_metabases_access_select_column"];
	/** input type for updating data in table "voyce.metabases_access" */
["voyce_metabases_access_set_input"]: GraphQLTypes["voyce_metabases_access_set_input"];
	/** aggregate stddev on columns */
["voyce_metabases_access_stddev_fields"]: {
		id?:number,
	metabase_id?:number,
	role_id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_metabases_access_stddev_pop_fields"]: {
		id?:number,
	metabase_id?:number,
	role_id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_metabases_access_stddev_samp_fields"]: {
		id?:number,
	metabase_id?:number,
	role_id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_metabases_access" */
["voyce_metabases_access_stream_cursor_input"]: GraphQLTypes["voyce_metabases_access_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_metabases_access_stream_cursor_value_input"]: GraphQLTypes["voyce_metabases_access_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_metabases_access_sum_fields"]: {
		id?:number,
	metabase_id?:number,
	role_id?:number,
	user_id?:number
};
	/** update columns of table "voyce.metabases_access" */
["voyce_metabases_access_update_column"]: GraphQLTypes["voyce_metabases_access_update_column"];
	["voyce_metabases_access_updates"]: GraphQLTypes["voyce_metabases_access_updates"];
	/** aggregate var_pop on columns */
["voyce_metabases_access_var_pop_fields"]: {
		id?:number,
	metabase_id?:number,
	role_id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_metabases_access_var_samp_fields"]: {
		id?:number,
	metabase_id?:number,
	role_id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_metabases_access_variance_fields"]: {
		id?:number,
	metabase_id?:number,
	role_id?:number,
	user_id?:number
};
	/** aggregated selection of "voyce.metabases" */
["voyce_metabases_aggregate"]: {
		aggregate?:ModelTypes["voyce_metabases_aggregate_fields"],
	nodes:ModelTypes["voyce_metabases"][]
};
	/** aggregate fields of "voyce.metabases" */
["voyce_metabases_aggregate_fields"]: {
		avg?:ModelTypes["voyce_metabases_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_metabases_max_fields"],
	min?:ModelTypes["voyce_metabases_min_fields"],
	stddev?:ModelTypes["voyce_metabases_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_metabases_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_metabases_stddev_samp_fields"],
	sum?:ModelTypes["voyce_metabases_sum_fields"],
	var_pop?:ModelTypes["voyce_metabases_var_pop_fields"],
	var_samp?:ModelTypes["voyce_metabases_var_samp_fields"],
	variance?:ModelTypes["voyce_metabases_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_metabases_avg_fields"]: {
		dashboard_id?:number,
	id?:number,
	order?:number
};
	/** Boolean expression to filter rows from the table "voyce.metabases". All fields are combined with a logical 'AND'. */
["voyce_metabases_bool_exp"]: GraphQLTypes["voyce_metabases_bool_exp"];
	/** unique or primary key constraints on table "voyce.metabases" */
["voyce_metabases_constraint"]: GraphQLTypes["voyce_metabases_constraint"];
	/** input type for incrementing numeric columns in table "voyce.metabases" */
["voyce_metabases_inc_input"]: GraphQLTypes["voyce_metabases_inc_input"];
	/** input type for inserting data into table "voyce.metabases" */
["voyce_metabases_insert_input"]: GraphQLTypes["voyce_metabases_insert_input"];
	/** aggregate max on columns */
["voyce_metabases_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	dashboard_id?:number,
	id?:number,
	order?:number,
	title?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_metabases_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	dashboard_id?:number,
	id?:number,
	order?:number,
	title?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.metabases" */
["voyce_metabases_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_metabases"][]
};
	/** on_conflict condition type for table "voyce.metabases" */
["voyce_metabases_on_conflict"]: GraphQLTypes["voyce_metabases_on_conflict"];
	/** Ordering options when selecting data from "voyce.metabases". */
["voyce_metabases_order_by"]: GraphQLTypes["voyce_metabases_order_by"];
	/** primary key columns input for table: voyce.metabases */
["voyce_metabases_pk_columns_input"]: GraphQLTypes["voyce_metabases_pk_columns_input"];
	/** select columns of table "voyce.metabases" */
["voyce_metabases_select_column"]: GraphQLTypes["voyce_metabases_select_column"];
	/** input type for updating data in table "voyce.metabases" */
["voyce_metabases_set_input"]: GraphQLTypes["voyce_metabases_set_input"];
	/** aggregate stddev on columns */
["voyce_metabases_stddev_fields"]: {
		dashboard_id?:number,
	id?:number,
	order?:number
};
	/** aggregate stddev_pop on columns */
["voyce_metabases_stddev_pop_fields"]: {
		dashboard_id?:number,
	id?:number,
	order?:number
};
	/** aggregate stddev_samp on columns */
["voyce_metabases_stddev_samp_fields"]: {
		dashboard_id?:number,
	id?:number,
	order?:number
};
	/** Streaming cursor of the table "voyce_metabases" */
["voyce_metabases_stream_cursor_input"]: GraphQLTypes["voyce_metabases_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_metabases_stream_cursor_value_input"]: GraphQLTypes["voyce_metabases_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_metabases_sum_fields"]: {
		dashboard_id?:number,
	id?:number,
	order?:number
};
	/** update columns of table "voyce.metabases" */
["voyce_metabases_update_column"]: GraphQLTypes["voyce_metabases_update_column"];
	["voyce_metabases_updates"]: GraphQLTypes["voyce_metabases_updates"];
	/** aggregate var_pop on columns */
["voyce_metabases_var_pop_fields"]: {
		dashboard_id?:number,
	id?:number,
	order?:number
};
	/** aggregate var_samp on columns */
["voyce_metabases_var_samp_fields"]: {
		dashboard_id?:number,
	id?:number,
	order?:number
};
	/** aggregate variance on columns */
["voyce_metabases_variance_fields"]: {
		dashboard_id?:number,
	id?:number,
	order?:number
};
	/** columns and relationships of "voyce.newsletter" */
["voyce_newsletter"]: {
		created_at:ModelTypes["timestamptz"],
	email:string,
	id:number,
	user_id?:number
};
	/** aggregated selection of "voyce.newsletter" */
["voyce_newsletter_aggregate"]: {
		aggregate?:ModelTypes["voyce_newsletter_aggregate_fields"],
	nodes:ModelTypes["voyce_newsletter"][]
};
	/** aggregate fields of "voyce.newsletter" */
["voyce_newsletter_aggregate_fields"]: {
		avg?:ModelTypes["voyce_newsletter_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_newsletter_max_fields"],
	min?:ModelTypes["voyce_newsletter_min_fields"],
	stddev?:ModelTypes["voyce_newsletter_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_newsletter_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_newsletter_stddev_samp_fields"],
	sum?:ModelTypes["voyce_newsletter_sum_fields"],
	var_pop?:ModelTypes["voyce_newsletter_var_pop_fields"],
	var_samp?:ModelTypes["voyce_newsletter_var_samp_fields"],
	variance?:ModelTypes["voyce_newsletter_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_newsletter_avg_fields"]: {
		id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.newsletter". All fields are combined with a logical 'AND'. */
["voyce_newsletter_bool_exp"]: GraphQLTypes["voyce_newsletter_bool_exp"];
	/** unique or primary key constraints on table "voyce.newsletter" */
["voyce_newsletter_constraint"]: GraphQLTypes["voyce_newsletter_constraint"];
	/** input type for incrementing numeric columns in table "voyce.newsletter" */
["voyce_newsletter_inc_input"]: GraphQLTypes["voyce_newsletter_inc_input"];
	/** input type for inserting data into table "voyce.newsletter" */
["voyce_newsletter_insert_input"]: GraphQLTypes["voyce_newsletter_insert_input"];
	/** aggregate max on columns */
["voyce_newsletter_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	email?:string,
	id?:number,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_newsletter_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	email?:string,
	id?:number,
	user_id?:number
};
	/** response of any mutation on the table "voyce.newsletter" */
["voyce_newsletter_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_newsletter"][]
};
	/** on_conflict condition type for table "voyce.newsletter" */
["voyce_newsletter_on_conflict"]: GraphQLTypes["voyce_newsletter_on_conflict"];
	/** Ordering options when selecting data from "voyce.newsletter". */
["voyce_newsletter_order_by"]: GraphQLTypes["voyce_newsletter_order_by"];
	/** primary key columns input for table: voyce.newsletter */
["voyce_newsletter_pk_columns_input"]: GraphQLTypes["voyce_newsletter_pk_columns_input"];
	/** select columns of table "voyce.newsletter" */
["voyce_newsletter_select_column"]: GraphQLTypes["voyce_newsletter_select_column"];
	/** input type for updating data in table "voyce.newsletter" */
["voyce_newsletter_set_input"]: GraphQLTypes["voyce_newsletter_set_input"];
	/** aggregate stddev on columns */
["voyce_newsletter_stddev_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_newsletter_stddev_pop_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_newsletter_stddev_samp_fields"]: {
		id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_newsletter" */
["voyce_newsletter_stream_cursor_input"]: GraphQLTypes["voyce_newsletter_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_newsletter_stream_cursor_value_input"]: GraphQLTypes["voyce_newsletter_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_newsletter_sum_fields"]: {
		id?:number,
	user_id?:number
};
	/** update columns of table "voyce.newsletter" */
["voyce_newsletter_update_column"]: GraphQLTypes["voyce_newsletter_update_column"];
	["voyce_newsletter_updates"]: GraphQLTypes["voyce_newsletter_updates"];
	/** aggregate var_pop on columns */
["voyce_newsletter_var_pop_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_newsletter_var_samp_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_newsletter_variance_fields"]: {
		id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.notification_series_settings" */
["voyce_notification_series_settings"]: {
		chapter_comment_email:ModelTypes["smallint"],
	chapter_comment_push:ModelTypes["smallint"],
	chapter_like_email:ModelTypes["smallint"],
	chapter_like_push:ModelTypes["smallint"],
	chapter_react_email:ModelTypes["smallint"],
	chapter_react_push:ModelTypes["smallint"],
	id:number,
	series_id:number,
	subscription_email:ModelTypes["smallint"],
	subscription_push:ModelTypes["smallint"],
	user_id:number
};
	/** aggregated selection of "voyce.notification_series_settings" */
["voyce_notification_series_settings_aggregate"]: {
		aggregate?:ModelTypes["voyce_notification_series_settings_aggregate_fields"],
	nodes:ModelTypes["voyce_notification_series_settings"][]
};
	["voyce_notification_series_settings_aggregate_bool_exp"]: GraphQLTypes["voyce_notification_series_settings_aggregate_bool_exp"];
	["voyce_notification_series_settings_aggregate_bool_exp_count"]: GraphQLTypes["voyce_notification_series_settings_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.notification_series_settings" */
["voyce_notification_series_settings_aggregate_fields"]: {
		avg?:ModelTypes["voyce_notification_series_settings_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_notification_series_settings_max_fields"],
	min?:ModelTypes["voyce_notification_series_settings_min_fields"],
	stddev?:ModelTypes["voyce_notification_series_settings_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_notification_series_settings_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_notification_series_settings_stddev_samp_fields"],
	sum?:ModelTypes["voyce_notification_series_settings_sum_fields"],
	var_pop?:ModelTypes["voyce_notification_series_settings_var_pop_fields"],
	var_samp?:ModelTypes["voyce_notification_series_settings_var_samp_fields"],
	variance?:ModelTypes["voyce_notification_series_settings_variance_fields"]
};
	/** order by aggregate values of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_aggregate_order_by"]: GraphQLTypes["voyce_notification_series_settings_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.notification_series_settings" */
["voyce_notification_series_settings_arr_rel_insert_input"]: GraphQLTypes["voyce_notification_series_settings_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_notification_series_settings_avg_fields"]: {
		chapter_comment_email?:number,
	chapter_comment_push?:number,
	chapter_like_email?:number,
	chapter_like_push?:number,
	chapter_react_email?:number,
	chapter_react_push?:number,
	id?:number,
	series_id?:number,
	subscription_email?:number,
	subscription_push?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_avg_order_by"]: GraphQLTypes["voyce_notification_series_settings_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.notification_series_settings". All fields are combined with a logical 'AND'. */
["voyce_notification_series_settings_bool_exp"]: GraphQLTypes["voyce_notification_series_settings_bool_exp"];
	/** unique or primary key constraints on table "voyce.notification_series_settings" */
["voyce_notification_series_settings_constraint"]: GraphQLTypes["voyce_notification_series_settings_constraint"];
	/** input type for incrementing numeric columns in table "voyce.notification_series_settings" */
["voyce_notification_series_settings_inc_input"]: GraphQLTypes["voyce_notification_series_settings_inc_input"];
	/** input type for inserting data into table "voyce.notification_series_settings" */
["voyce_notification_series_settings_insert_input"]: GraphQLTypes["voyce_notification_series_settings_insert_input"];
	/** aggregate max on columns */
["voyce_notification_series_settings_max_fields"]: {
		chapter_comment_email?:ModelTypes["smallint"],
	chapter_comment_push?:ModelTypes["smallint"],
	chapter_like_email?:ModelTypes["smallint"],
	chapter_like_push?:ModelTypes["smallint"],
	chapter_react_email?:ModelTypes["smallint"],
	chapter_react_push?:ModelTypes["smallint"],
	id?:number,
	series_id?:number,
	subscription_email?:ModelTypes["smallint"],
	subscription_push?:ModelTypes["smallint"],
	user_id?:number
};
	/** order by max() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_max_order_by"]: GraphQLTypes["voyce_notification_series_settings_max_order_by"];
	/** aggregate min on columns */
["voyce_notification_series_settings_min_fields"]: {
		chapter_comment_email?:ModelTypes["smallint"],
	chapter_comment_push?:ModelTypes["smallint"],
	chapter_like_email?:ModelTypes["smallint"],
	chapter_like_push?:ModelTypes["smallint"],
	chapter_react_email?:ModelTypes["smallint"],
	chapter_react_push?:ModelTypes["smallint"],
	id?:number,
	series_id?:number,
	subscription_email?:ModelTypes["smallint"],
	subscription_push?:ModelTypes["smallint"],
	user_id?:number
};
	/** order by min() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_min_order_by"]: GraphQLTypes["voyce_notification_series_settings_min_order_by"];
	/** response of any mutation on the table "voyce.notification_series_settings" */
["voyce_notification_series_settings_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_notification_series_settings"][]
};
	/** input type for inserting object relation for remote table "voyce.notification_series_settings" */
["voyce_notification_series_settings_obj_rel_insert_input"]: GraphQLTypes["voyce_notification_series_settings_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.notification_series_settings" */
["voyce_notification_series_settings_on_conflict"]: GraphQLTypes["voyce_notification_series_settings_on_conflict"];
	/** Ordering options when selecting data from "voyce.notification_series_settings". */
["voyce_notification_series_settings_order_by"]: GraphQLTypes["voyce_notification_series_settings_order_by"];
	/** primary key columns input for table: voyce.notification_series_settings */
["voyce_notification_series_settings_pk_columns_input"]: GraphQLTypes["voyce_notification_series_settings_pk_columns_input"];
	/** select columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_select_column"]: GraphQLTypes["voyce_notification_series_settings_select_column"];
	/** input type for updating data in table "voyce.notification_series_settings" */
["voyce_notification_series_settings_set_input"]: GraphQLTypes["voyce_notification_series_settings_set_input"];
	/** aggregate stddev on columns */
["voyce_notification_series_settings_stddev_fields"]: {
		chapter_comment_email?:number,
	chapter_comment_push?:number,
	chapter_like_email?:number,
	chapter_like_push?:number,
	chapter_react_email?:number,
	chapter_react_push?:number,
	id?:number,
	series_id?:number,
	subscription_email?:number,
	subscription_push?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_stddev_order_by"]: GraphQLTypes["voyce_notification_series_settings_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_notification_series_settings_stddev_pop_fields"]: {
		chapter_comment_email?:number,
	chapter_comment_push?:number,
	chapter_like_email?:number,
	chapter_like_push?:number,
	chapter_react_email?:number,
	chapter_react_push?:number,
	id?:number,
	series_id?:number,
	subscription_email?:number,
	subscription_push?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_stddev_pop_order_by"]: GraphQLTypes["voyce_notification_series_settings_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_notification_series_settings_stddev_samp_fields"]: {
		chapter_comment_email?:number,
	chapter_comment_push?:number,
	chapter_like_email?:number,
	chapter_like_push?:number,
	chapter_react_email?:number,
	chapter_react_push?:number,
	id?:number,
	series_id?:number,
	subscription_email?:number,
	subscription_push?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_stddev_samp_order_by"]: GraphQLTypes["voyce_notification_series_settings_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_notification_series_settings" */
["voyce_notification_series_settings_stream_cursor_input"]: GraphQLTypes["voyce_notification_series_settings_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_notification_series_settings_stream_cursor_value_input"]: GraphQLTypes["voyce_notification_series_settings_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_notification_series_settings_sum_fields"]: {
		chapter_comment_email?:ModelTypes["smallint"],
	chapter_comment_push?:ModelTypes["smallint"],
	chapter_like_email?:ModelTypes["smallint"],
	chapter_like_push?:ModelTypes["smallint"],
	chapter_react_email?:ModelTypes["smallint"],
	chapter_react_push?:ModelTypes["smallint"],
	id?:number,
	series_id?:number,
	subscription_email?:ModelTypes["smallint"],
	subscription_push?:ModelTypes["smallint"],
	user_id?:number
};
	/** order by sum() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_sum_order_by"]: GraphQLTypes["voyce_notification_series_settings_sum_order_by"];
	/** update columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_update_column"]: GraphQLTypes["voyce_notification_series_settings_update_column"];
	["voyce_notification_series_settings_updates"]: GraphQLTypes["voyce_notification_series_settings_updates"];
	/** aggregate var_pop on columns */
["voyce_notification_series_settings_var_pop_fields"]: {
		chapter_comment_email?:number,
	chapter_comment_push?:number,
	chapter_like_email?:number,
	chapter_like_push?:number,
	chapter_react_email?:number,
	chapter_react_push?:number,
	id?:number,
	series_id?:number,
	subscription_email?:number,
	subscription_push?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_var_pop_order_by"]: GraphQLTypes["voyce_notification_series_settings_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_notification_series_settings_var_samp_fields"]: {
		chapter_comment_email?:number,
	chapter_comment_push?:number,
	chapter_like_email?:number,
	chapter_like_push?:number,
	chapter_react_email?:number,
	chapter_react_push?:number,
	id?:number,
	series_id?:number,
	subscription_email?:number,
	subscription_push?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_var_samp_order_by"]: GraphQLTypes["voyce_notification_series_settings_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_notification_series_settings_variance_fields"]: {
		chapter_comment_email?:number,
	chapter_comment_push?:number,
	chapter_like_email?:number,
	chapter_like_push?:number,
	chapter_react_email?:number,
	chapter_react_push?:number,
	id?:number,
	series_id?:number,
	subscription_email?:number,
	subscription_push?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_variance_order_by"]: GraphQLTypes["voyce_notification_series_settings_variance_order_by"];
	/** columns and relationships of "voyce.notification_settings" */
["voyce_notification_settings"]: {
		chapter_comment_email:ModelTypes["smallint"],
	chapter_comment_push:ModelTypes["smallint"],
	chapter_like_email:ModelTypes["smallint"],
	chapter_like_push:ModelTypes["smallint"],
	chapter_react_email:ModelTypes["smallint"],
	chapter_react_push:ModelTypes["smallint"],
	chapter_release_email:ModelTypes["smallint"],
	chapter_release_push:ModelTypes["smallint"],
	comment_react_email:ModelTypes["smallint"],
	comment_react_push:ModelTypes["smallint"],
	comment_reply_email:ModelTypes["smallint"],
	comment_reply_push:ModelTypes["smallint"],
	event_email:ModelTypes["smallint"],
	event_push:ModelTypes["smallint"],
	follow_email:ModelTypes["smallint"],
	follow_push:ModelTypes["smallint"],
	id:number,
	promotion_email:ModelTypes["smallint"],
	promotion_push:ModelTypes["smallint"],
	recommended_series_email:ModelTypes["smallint"],
	recommended_series_push:ModelTypes["smallint"],
	reward_email:ModelTypes["smallint"],
	reward_push:ModelTypes["smallint"],
	subscription_email:ModelTypes["smallint"],
	subscription_push:ModelTypes["smallint"],
	/** An object relationship */
	user?:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.notification_settings" */
["voyce_notification_settings_aggregate"]: {
		aggregate?:ModelTypes["voyce_notification_settings_aggregate_fields"],
	nodes:ModelTypes["voyce_notification_settings"][]
};
	/** aggregate fields of "voyce.notification_settings" */
["voyce_notification_settings_aggregate_fields"]: {
		avg?:ModelTypes["voyce_notification_settings_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_notification_settings_max_fields"],
	min?:ModelTypes["voyce_notification_settings_min_fields"],
	stddev?:ModelTypes["voyce_notification_settings_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_notification_settings_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_notification_settings_stddev_samp_fields"],
	sum?:ModelTypes["voyce_notification_settings_sum_fields"],
	var_pop?:ModelTypes["voyce_notification_settings_var_pop_fields"],
	var_samp?:ModelTypes["voyce_notification_settings_var_samp_fields"],
	variance?:ModelTypes["voyce_notification_settings_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_notification_settings_avg_fields"]: {
		chapter_comment_email?:number,
	chapter_comment_push?:number,
	chapter_like_email?:number,
	chapter_like_push?:number,
	chapter_react_email?:number,
	chapter_react_push?:number,
	chapter_release_email?:number,
	chapter_release_push?:number,
	comment_react_email?:number,
	comment_react_push?:number,
	comment_reply_email?:number,
	comment_reply_push?:number,
	event_email?:number,
	event_push?:number,
	follow_email?:number,
	follow_push?:number,
	id?:number,
	promotion_email?:number,
	promotion_push?:number,
	recommended_series_email?:number,
	recommended_series_push?:number,
	reward_email?:number,
	reward_push?:number,
	subscription_email?:number,
	subscription_push?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.notification_settings". All fields are combined with a logical 'AND'. */
["voyce_notification_settings_bool_exp"]: GraphQLTypes["voyce_notification_settings_bool_exp"];
	/** unique or primary key constraints on table "voyce.notification_settings" */
["voyce_notification_settings_constraint"]: GraphQLTypes["voyce_notification_settings_constraint"];
	/** input type for incrementing numeric columns in table "voyce.notification_settings" */
["voyce_notification_settings_inc_input"]: GraphQLTypes["voyce_notification_settings_inc_input"];
	/** input type for inserting data into table "voyce.notification_settings" */
["voyce_notification_settings_insert_input"]: GraphQLTypes["voyce_notification_settings_insert_input"];
	/** aggregate max on columns */
["voyce_notification_settings_max_fields"]: {
		chapter_comment_email?:ModelTypes["smallint"],
	chapter_comment_push?:ModelTypes["smallint"],
	chapter_like_email?:ModelTypes["smallint"],
	chapter_like_push?:ModelTypes["smallint"],
	chapter_react_email?:ModelTypes["smallint"],
	chapter_react_push?:ModelTypes["smallint"],
	chapter_release_email?:ModelTypes["smallint"],
	chapter_release_push?:ModelTypes["smallint"],
	comment_react_email?:ModelTypes["smallint"],
	comment_react_push?:ModelTypes["smallint"],
	comment_reply_email?:ModelTypes["smallint"],
	comment_reply_push?:ModelTypes["smallint"],
	event_email?:ModelTypes["smallint"],
	event_push?:ModelTypes["smallint"],
	follow_email?:ModelTypes["smallint"],
	follow_push?:ModelTypes["smallint"],
	id?:number,
	promotion_email?:ModelTypes["smallint"],
	promotion_push?:ModelTypes["smallint"],
	recommended_series_email?:ModelTypes["smallint"],
	recommended_series_push?:ModelTypes["smallint"],
	reward_email?:ModelTypes["smallint"],
	reward_push?:ModelTypes["smallint"],
	subscription_email?:ModelTypes["smallint"],
	subscription_push?:ModelTypes["smallint"],
	user_id?:number
};
	/** aggregate min on columns */
["voyce_notification_settings_min_fields"]: {
		chapter_comment_email?:ModelTypes["smallint"],
	chapter_comment_push?:ModelTypes["smallint"],
	chapter_like_email?:ModelTypes["smallint"],
	chapter_like_push?:ModelTypes["smallint"],
	chapter_react_email?:ModelTypes["smallint"],
	chapter_react_push?:ModelTypes["smallint"],
	chapter_release_email?:ModelTypes["smallint"],
	chapter_release_push?:ModelTypes["smallint"],
	comment_react_email?:ModelTypes["smallint"],
	comment_react_push?:ModelTypes["smallint"],
	comment_reply_email?:ModelTypes["smallint"],
	comment_reply_push?:ModelTypes["smallint"],
	event_email?:ModelTypes["smallint"],
	event_push?:ModelTypes["smallint"],
	follow_email?:ModelTypes["smallint"],
	follow_push?:ModelTypes["smallint"],
	id?:number,
	promotion_email?:ModelTypes["smallint"],
	promotion_push?:ModelTypes["smallint"],
	recommended_series_email?:ModelTypes["smallint"],
	recommended_series_push?:ModelTypes["smallint"],
	reward_email?:ModelTypes["smallint"],
	reward_push?:ModelTypes["smallint"],
	subscription_email?:ModelTypes["smallint"],
	subscription_push?:ModelTypes["smallint"],
	user_id?:number
};
	/** response of any mutation on the table "voyce.notification_settings" */
["voyce_notification_settings_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_notification_settings"][]
};
	/** input type for inserting object relation for remote table "voyce.notification_settings" */
["voyce_notification_settings_obj_rel_insert_input"]: GraphQLTypes["voyce_notification_settings_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.notification_settings" */
["voyce_notification_settings_on_conflict"]: GraphQLTypes["voyce_notification_settings_on_conflict"];
	/** Ordering options when selecting data from "voyce.notification_settings". */
["voyce_notification_settings_order_by"]: GraphQLTypes["voyce_notification_settings_order_by"];
	/** primary key columns input for table: voyce.notification_settings */
["voyce_notification_settings_pk_columns_input"]: GraphQLTypes["voyce_notification_settings_pk_columns_input"];
	/** select columns of table "voyce.notification_settings" */
["voyce_notification_settings_select_column"]: GraphQLTypes["voyce_notification_settings_select_column"];
	/** input type for updating data in table "voyce.notification_settings" */
["voyce_notification_settings_set_input"]: GraphQLTypes["voyce_notification_settings_set_input"];
	/** aggregate stddev on columns */
["voyce_notification_settings_stddev_fields"]: {
		chapter_comment_email?:number,
	chapter_comment_push?:number,
	chapter_like_email?:number,
	chapter_like_push?:number,
	chapter_react_email?:number,
	chapter_react_push?:number,
	chapter_release_email?:number,
	chapter_release_push?:number,
	comment_react_email?:number,
	comment_react_push?:number,
	comment_reply_email?:number,
	comment_reply_push?:number,
	event_email?:number,
	event_push?:number,
	follow_email?:number,
	follow_push?:number,
	id?:number,
	promotion_email?:number,
	promotion_push?:number,
	recommended_series_email?:number,
	recommended_series_push?:number,
	reward_email?:number,
	reward_push?:number,
	subscription_email?:number,
	subscription_push?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_notification_settings_stddev_pop_fields"]: {
		chapter_comment_email?:number,
	chapter_comment_push?:number,
	chapter_like_email?:number,
	chapter_like_push?:number,
	chapter_react_email?:number,
	chapter_react_push?:number,
	chapter_release_email?:number,
	chapter_release_push?:number,
	comment_react_email?:number,
	comment_react_push?:number,
	comment_reply_email?:number,
	comment_reply_push?:number,
	event_email?:number,
	event_push?:number,
	follow_email?:number,
	follow_push?:number,
	id?:number,
	promotion_email?:number,
	promotion_push?:number,
	recommended_series_email?:number,
	recommended_series_push?:number,
	reward_email?:number,
	reward_push?:number,
	subscription_email?:number,
	subscription_push?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_notification_settings_stddev_samp_fields"]: {
		chapter_comment_email?:number,
	chapter_comment_push?:number,
	chapter_like_email?:number,
	chapter_like_push?:number,
	chapter_react_email?:number,
	chapter_react_push?:number,
	chapter_release_email?:number,
	chapter_release_push?:number,
	comment_react_email?:number,
	comment_react_push?:number,
	comment_reply_email?:number,
	comment_reply_push?:number,
	event_email?:number,
	event_push?:number,
	follow_email?:number,
	follow_push?:number,
	id?:number,
	promotion_email?:number,
	promotion_push?:number,
	recommended_series_email?:number,
	recommended_series_push?:number,
	reward_email?:number,
	reward_push?:number,
	subscription_email?:number,
	subscription_push?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_notification_settings" */
["voyce_notification_settings_stream_cursor_input"]: GraphQLTypes["voyce_notification_settings_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_notification_settings_stream_cursor_value_input"]: GraphQLTypes["voyce_notification_settings_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_notification_settings_sum_fields"]: {
		chapter_comment_email?:ModelTypes["smallint"],
	chapter_comment_push?:ModelTypes["smallint"],
	chapter_like_email?:ModelTypes["smallint"],
	chapter_like_push?:ModelTypes["smallint"],
	chapter_react_email?:ModelTypes["smallint"],
	chapter_react_push?:ModelTypes["smallint"],
	chapter_release_email?:ModelTypes["smallint"],
	chapter_release_push?:ModelTypes["smallint"],
	comment_react_email?:ModelTypes["smallint"],
	comment_react_push?:ModelTypes["smallint"],
	comment_reply_email?:ModelTypes["smallint"],
	comment_reply_push?:ModelTypes["smallint"],
	event_email?:ModelTypes["smallint"],
	event_push?:ModelTypes["smallint"],
	follow_email?:ModelTypes["smallint"],
	follow_push?:ModelTypes["smallint"],
	id?:number,
	promotion_email?:ModelTypes["smallint"],
	promotion_push?:ModelTypes["smallint"],
	recommended_series_email?:ModelTypes["smallint"],
	recommended_series_push?:ModelTypes["smallint"],
	reward_email?:ModelTypes["smallint"],
	reward_push?:ModelTypes["smallint"],
	subscription_email?:ModelTypes["smallint"],
	subscription_push?:ModelTypes["smallint"],
	user_id?:number
};
	/** update columns of table "voyce.notification_settings" */
["voyce_notification_settings_update_column"]: GraphQLTypes["voyce_notification_settings_update_column"];
	["voyce_notification_settings_updates"]: GraphQLTypes["voyce_notification_settings_updates"];
	/** aggregate var_pop on columns */
["voyce_notification_settings_var_pop_fields"]: {
		chapter_comment_email?:number,
	chapter_comment_push?:number,
	chapter_like_email?:number,
	chapter_like_push?:number,
	chapter_react_email?:number,
	chapter_react_push?:number,
	chapter_release_email?:number,
	chapter_release_push?:number,
	comment_react_email?:number,
	comment_react_push?:number,
	comment_reply_email?:number,
	comment_reply_push?:number,
	event_email?:number,
	event_push?:number,
	follow_email?:number,
	follow_push?:number,
	id?:number,
	promotion_email?:number,
	promotion_push?:number,
	recommended_series_email?:number,
	recommended_series_push?:number,
	reward_email?:number,
	reward_push?:number,
	subscription_email?:number,
	subscription_push?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_notification_settings_var_samp_fields"]: {
		chapter_comment_email?:number,
	chapter_comment_push?:number,
	chapter_like_email?:number,
	chapter_like_push?:number,
	chapter_react_email?:number,
	chapter_react_push?:number,
	chapter_release_email?:number,
	chapter_release_push?:number,
	comment_react_email?:number,
	comment_react_push?:number,
	comment_reply_email?:number,
	comment_reply_push?:number,
	event_email?:number,
	event_push?:number,
	follow_email?:number,
	follow_push?:number,
	id?:number,
	promotion_email?:number,
	promotion_push?:number,
	recommended_series_email?:number,
	recommended_series_push?:number,
	reward_email?:number,
	reward_push?:number,
	subscription_email?:number,
	subscription_push?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_notification_settings_variance_fields"]: {
		chapter_comment_email?:number,
	chapter_comment_push?:number,
	chapter_like_email?:number,
	chapter_like_push?:number,
	chapter_react_email?:number,
	chapter_react_push?:number,
	chapter_release_email?:number,
	chapter_release_push?:number,
	comment_react_email?:number,
	comment_react_push?:number,
	comment_reply_email?:number,
	comment_reply_push?:number,
	event_email?:number,
	event_push?:number,
	follow_email?:number,
	follow_push?:number,
	id?:number,
	promotion_email?:number,
	promotion_push?:number,
	recommended_series_email?:number,
	recommended_series_push?:number,
	reward_email?:number,
	reward_push?:number,
	subscription_email?:number,
	subscription_push?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.notifications" */
["voyce_notifications"]: {
		child_count:number,
	created_at:ModelTypes["timestamptz"],
	data?:ModelTypes["jsonb"],
	email_status?:string,
	emailed_at?:ModelTypes["timestamptz"],
	id:number,
	linked_id?:number,
	parent_id?:number,
	pushed_at?:ModelTypes["timestamptz"],
	read_at?:ModelTypes["timestamptz"],
	type:string,
	unsubscribe_id:ModelTypes["uuid"],
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.notifications" */
["voyce_notifications_aggregate"]: {
		aggregate?:ModelTypes["voyce_notifications_aggregate_fields"],
	nodes:ModelTypes["voyce_notifications"][]
};
	["voyce_notifications_aggregate_bool_exp"]: GraphQLTypes["voyce_notifications_aggregate_bool_exp"];
	["voyce_notifications_aggregate_bool_exp_count"]: GraphQLTypes["voyce_notifications_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.notifications" */
["voyce_notifications_aggregate_fields"]: {
		avg?:ModelTypes["voyce_notifications_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_notifications_max_fields"],
	min?:ModelTypes["voyce_notifications_min_fields"],
	stddev?:ModelTypes["voyce_notifications_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_notifications_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_notifications_stddev_samp_fields"],
	sum?:ModelTypes["voyce_notifications_sum_fields"],
	var_pop?:ModelTypes["voyce_notifications_var_pop_fields"],
	var_samp?:ModelTypes["voyce_notifications_var_samp_fields"],
	variance?:ModelTypes["voyce_notifications_variance_fields"]
};
	/** order by aggregate values of table "voyce.notifications" */
["voyce_notifications_aggregate_order_by"]: GraphQLTypes["voyce_notifications_aggregate_order_by"];
	/** append existing jsonb value of filtered columns with new jsonb value */
["voyce_notifications_append_input"]: GraphQLTypes["voyce_notifications_append_input"];
	/** input type for inserting array relation for remote table "voyce.notifications" */
["voyce_notifications_arr_rel_insert_input"]: GraphQLTypes["voyce_notifications_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_notifications_avg_fields"]: {
		child_count?:number,
	id?:number,
	linked_id?:number,
	parent_id?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.notifications" */
["voyce_notifications_avg_order_by"]: GraphQLTypes["voyce_notifications_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.notifications". All fields are combined with a logical 'AND'. */
["voyce_notifications_bool_exp"]: GraphQLTypes["voyce_notifications_bool_exp"];
	/** unique or primary key constraints on table "voyce.notifications" */
["voyce_notifications_constraint"]: GraphQLTypes["voyce_notifications_constraint"];
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["voyce_notifications_delete_at_path_input"]: GraphQLTypes["voyce_notifications_delete_at_path_input"];
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["voyce_notifications_delete_elem_input"]: GraphQLTypes["voyce_notifications_delete_elem_input"];
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["voyce_notifications_delete_key_input"]: GraphQLTypes["voyce_notifications_delete_key_input"];
	/** input type for incrementing numeric columns in table "voyce.notifications" */
["voyce_notifications_inc_input"]: GraphQLTypes["voyce_notifications_inc_input"];
	/** input type for inserting data into table "voyce.notifications" */
["voyce_notifications_insert_input"]: GraphQLTypes["voyce_notifications_insert_input"];
	/** aggregate max on columns */
["voyce_notifications_max_fields"]: {
		child_count?:number,
	created_at?:ModelTypes["timestamptz"],
	email_status?:string,
	emailed_at?:ModelTypes["timestamptz"],
	id?:number,
	linked_id?:number,
	parent_id?:number,
	pushed_at?:ModelTypes["timestamptz"],
	read_at?:ModelTypes["timestamptz"],
	type?:string,
	unsubscribe_id?:ModelTypes["uuid"],
	user_id?:number
};
	/** order by max() on columns of table "voyce.notifications" */
["voyce_notifications_max_order_by"]: GraphQLTypes["voyce_notifications_max_order_by"];
	/** aggregate min on columns */
["voyce_notifications_min_fields"]: {
		child_count?:number,
	created_at?:ModelTypes["timestamptz"],
	email_status?:string,
	emailed_at?:ModelTypes["timestamptz"],
	id?:number,
	linked_id?:number,
	parent_id?:number,
	pushed_at?:ModelTypes["timestamptz"],
	read_at?:ModelTypes["timestamptz"],
	type?:string,
	unsubscribe_id?:ModelTypes["uuid"],
	user_id?:number
};
	/** order by min() on columns of table "voyce.notifications" */
["voyce_notifications_min_order_by"]: GraphQLTypes["voyce_notifications_min_order_by"];
	/** response of any mutation on the table "voyce.notifications" */
["voyce_notifications_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_notifications"][]
};
	/** on_conflict condition type for table "voyce.notifications" */
["voyce_notifications_on_conflict"]: GraphQLTypes["voyce_notifications_on_conflict"];
	/** Ordering options when selecting data from "voyce.notifications". */
["voyce_notifications_order_by"]: GraphQLTypes["voyce_notifications_order_by"];
	/** primary key columns input for table: voyce.notifications */
["voyce_notifications_pk_columns_input"]: GraphQLTypes["voyce_notifications_pk_columns_input"];
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["voyce_notifications_prepend_input"]: GraphQLTypes["voyce_notifications_prepend_input"];
	/** select columns of table "voyce.notifications" */
["voyce_notifications_select_column"]: GraphQLTypes["voyce_notifications_select_column"];
	/** input type for updating data in table "voyce.notifications" */
["voyce_notifications_set_input"]: GraphQLTypes["voyce_notifications_set_input"];
	/** aggregate stddev on columns */
["voyce_notifications_stddev_fields"]: {
		child_count?:number,
	id?:number,
	linked_id?:number,
	parent_id?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.notifications" */
["voyce_notifications_stddev_order_by"]: GraphQLTypes["voyce_notifications_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_notifications_stddev_pop_fields"]: {
		child_count?:number,
	id?:number,
	linked_id?:number,
	parent_id?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.notifications" */
["voyce_notifications_stddev_pop_order_by"]: GraphQLTypes["voyce_notifications_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_notifications_stddev_samp_fields"]: {
		child_count?:number,
	id?:number,
	linked_id?:number,
	parent_id?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.notifications" */
["voyce_notifications_stddev_samp_order_by"]: GraphQLTypes["voyce_notifications_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_notifications" */
["voyce_notifications_stream_cursor_input"]: GraphQLTypes["voyce_notifications_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_notifications_stream_cursor_value_input"]: GraphQLTypes["voyce_notifications_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_notifications_sum_fields"]: {
		child_count?:number,
	id?:number,
	linked_id?:number,
	parent_id?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.notifications" */
["voyce_notifications_sum_order_by"]: GraphQLTypes["voyce_notifications_sum_order_by"];
	/** update columns of table "voyce.notifications" */
["voyce_notifications_update_column"]: GraphQLTypes["voyce_notifications_update_column"];
	["voyce_notifications_updates"]: GraphQLTypes["voyce_notifications_updates"];
	/** aggregate var_pop on columns */
["voyce_notifications_var_pop_fields"]: {
		child_count?:number,
	id?:number,
	linked_id?:number,
	parent_id?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.notifications" */
["voyce_notifications_var_pop_order_by"]: GraphQLTypes["voyce_notifications_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_notifications_var_samp_fields"]: {
		child_count?:number,
	id?:number,
	linked_id?:number,
	parent_id?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.notifications" */
["voyce_notifications_var_samp_order_by"]: GraphQLTypes["voyce_notifications_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_notifications_variance_fields"]: {
		child_count?:number,
	id?:number,
	linked_id?:number,
	parent_id?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.notifications" */
["voyce_notifications_variance_order_by"]: GraphQLTypes["voyce_notifications_variance_order_by"];
	/** columns and relationships of "voyce.originals_feed" */
["voyce_originals_feed"]: {
		category?:string,
	created_at?:ModelTypes["timestamptz"],
	desc?:string,
	id?:string,
	image?:string,
	title?:string,
	url?:string
};
	/** aggregated selection of "voyce.originals_feed" */
["voyce_originals_feed_aggregate"]: {
		aggregate?:ModelTypes["voyce_originals_feed_aggregate_fields"],
	nodes:ModelTypes["voyce_originals_feed"][]
};
	/** aggregate fields of "voyce.originals_feed" */
["voyce_originals_feed_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["voyce_originals_feed_max_fields"],
	min?:ModelTypes["voyce_originals_feed_min_fields"]
};
	/** Boolean expression to filter rows from the table "voyce.originals_feed". All fields are combined with a logical 'AND'. */
["voyce_originals_feed_bool_exp"]: GraphQLTypes["voyce_originals_feed_bool_exp"];
	/** aggregate max on columns */
["voyce_originals_feed_max_fields"]: {
		category?:string,
	created_at?:ModelTypes["timestamptz"],
	desc?:string,
	id?:string,
	image?:string,
	title?:string,
	url?:string
};
	/** aggregate min on columns */
["voyce_originals_feed_min_fields"]: {
		category?:string,
	created_at?:ModelTypes["timestamptz"],
	desc?:string,
	id?:string,
	image?:string,
	title?:string,
	url?:string
};
	/** Ordering options when selecting data from "voyce.originals_feed". */
["voyce_originals_feed_order_by"]: GraphQLTypes["voyce_originals_feed_order_by"];
	/** select columns of table "voyce.originals_feed" */
["voyce_originals_feed_select_column"]: GraphQLTypes["voyce_originals_feed_select_column"];
	/** Streaming cursor of the table "voyce_originals_feed" */
["voyce_originals_feed_stream_cursor_input"]: GraphQLTypes["voyce_originals_feed_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_originals_feed_stream_cursor_value_input"]: GraphQLTypes["voyce_originals_feed_stream_cursor_value_input"];
	/** columns and relationships of "voyce.page_banners" */
["voyce_page_banners"]: {
		active:boolean,
	background?:string,
	created_at:ModelTypes["timestamptz"],
	desktop:string,
	id:number,
	link?:string,
	mobile?:string,
	overlap:boolean,
	page:string,
	sort:ModelTypes["smallint"],
	tablet?:string,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.page_banners" */
["voyce_page_banners_aggregate"]: {
		aggregate?:ModelTypes["voyce_page_banners_aggregate_fields"],
	nodes:ModelTypes["voyce_page_banners"][]
};
	/** aggregate fields of "voyce.page_banners" */
["voyce_page_banners_aggregate_fields"]: {
		avg?:ModelTypes["voyce_page_banners_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_page_banners_max_fields"],
	min?:ModelTypes["voyce_page_banners_min_fields"],
	stddev?:ModelTypes["voyce_page_banners_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_page_banners_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_page_banners_stddev_samp_fields"],
	sum?:ModelTypes["voyce_page_banners_sum_fields"],
	var_pop?:ModelTypes["voyce_page_banners_var_pop_fields"],
	var_samp?:ModelTypes["voyce_page_banners_var_samp_fields"],
	variance?:ModelTypes["voyce_page_banners_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_page_banners_avg_fields"]: {
		id?:number,
	sort?:number
};
	/** Boolean expression to filter rows from the table "voyce.page_banners". All fields are combined with a logical 'AND'. */
["voyce_page_banners_bool_exp"]: GraphQLTypes["voyce_page_banners_bool_exp"];
	/** unique or primary key constraints on table "voyce.page_banners" */
["voyce_page_banners_constraint"]: GraphQLTypes["voyce_page_banners_constraint"];
	/** input type for incrementing numeric columns in table "voyce.page_banners" */
["voyce_page_banners_inc_input"]: GraphQLTypes["voyce_page_banners_inc_input"];
	/** input type for inserting data into table "voyce.page_banners" */
["voyce_page_banners_insert_input"]: GraphQLTypes["voyce_page_banners_insert_input"];
	/** aggregate max on columns */
["voyce_page_banners_max_fields"]: {
		background?:string,
	created_at?:ModelTypes["timestamptz"],
	desktop?:string,
	id?:number,
	link?:string,
	mobile?:string,
	page?:string,
	sort?:ModelTypes["smallint"],
	tablet?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_page_banners_min_fields"]: {
		background?:string,
	created_at?:ModelTypes["timestamptz"],
	desktop?:string,
	id?:number,
	link?:string,
	mobile?:string,
	page?:string,
	sort?:ModelTypes["smallint"],
	tablet?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.page_banners" */
["voyce_page_banners_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_page_banners"][]
};
	/** on_conflict condition type for table "voyce.page_banners" */
["voyce_page_banners_on_conflict"]: GraphQLTypes["voyce_page_banners_on_conflict"];
	/** Ordering options when selecting data from "voyce.page_banners". */
["voyce_page_banners_order_by"]: GraphQLTypes["voyce_page_banners_order_by"];
	/** primary key columns input for table: voyce.page_banners */
["voyce_page_banners_pk_columns_input"]: GraphQLTypes["voyce_page_banners_pk_columns_input"];
	/** select columns of table "voyce.page_banners" */
["voyce_page_banners_select_column"]: GraphQLTypes["voyce_page_banners_select_column"];
	/** input type for updating data in table "voyce.page_banners" */
["voyce_page_banners_set_input"]: GraphQLTypes["voyce_page_banners_set_input"];
	/** aggregate stddev on columns */
["voyce_page_banners_stddev_fields"]: {
		id?:number,
	sort?:number
};
	/** aggregate stddev_pop on columns */
["voyce_page_banners_stddev_pop_fields"]: {
		id?:number,
	sort?:number
};
	/** aggregate stddev_samp on columns */
["voyce_page_banners_stddev_samp_fields"]: {
		id?:number,
	sort?:number
};
	/** Streaming cursor of the table "voyce_page_banners" */
["voyce_page_banners_stream_cursor_input"]: GraphQLTypes["voyce_page_banners_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_page_banners_stream_cursor_value_input"]: GraphQLTypes["voyce_page_banners_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_page_banners_sum_fields"]: {
		id?:number,
	sort?:ModelTypes["smallint"]
};
	/** update columns of table "voyce.page_banners" */
["voyce_page_banners_update_column"]: GraphQLTypes["voyce_page_banners_update_column"];
	["voyce_page_banners_updates"]: GraphQLTypes["voyce_page_banners_updates"];
	/** aggregate var_pop on columns */
["voyce_page_banners_var_pop_fields"]: {
		id?:number,
	sort?:number
};
	/** aggregate var_samp on columns */
["voyce_page_banners_var_samp_fields"]: {
		id?:number,
	sort?:number
};
	/** aggregate variance on columns */
["voyce_page_banners_variance_fields"]: {
		id?:number,
	sort?:number
};
	/** Setup Page Sections / Components */
["voyce_page_components"]: {
		active:boolean,
	auth?:boolean,
	component:string,
	created_at:ModelTypes["timestamptz"],
	device:string,
	id:number,
	page:string,
	props?:ModelTypes["json"],
	sort:ModelTypes["smallint"],
	title?:string,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.page_components" */
["voyce_page_components_aggregate"]: {
		aggregate?:ModelTypes["voyce_page_components_aggregate_fields"],
	nodes:ModelTypes["voyce_page_components"][]
};
	/** aggregate fields of "voyce.page_components" */
["voyce_page_components_aggregate_fields"]: {
		avg?:ModelTypes["voyce_page_components_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_page_components_max_fields"],
	min?:ModelTypes["voyce_page_components_min_fields"],
	stddev?:ModelTypes["voyce_page_components_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_page_components_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_page_components_stddev_samp_fields"],
	sum?:ModelTypes["voyce_page_components_sum_fields"],
	var_pop?:ModelTypes["voyce_page_components_var_pop_fields"],
	var_samp?:ModelTypes["voyce_page_components_var_samp_fields"],
	variance?:ModelTypes["voyce_page_components_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_page_components_avg_fields"]: {
		id?:number,
	sort?:number
};
	/** Boolean expression to filter rows from the table "voyce.page_components". All fields are combined with a logical 'AND'. */
["voyce_page_components_bool_exp"]: GraphQLTypes["voyce_page_components_bool_exp"];
	/** unique or primary key constraints on table "voyce.page_components" */
["voyce_page_components_constraint"]: GraphQLTypes["voyce_page_components_constraint"];
	/** input type for incrementing numeric columns in table "voyce.page_components" */
["voyce_page_components_inc_input"]: GraphQLTypes["voyce_page_components_inc_input"];
	/** input type for inserting data into table "voyce.page_components" */
["voyce_page_components_insert_input"]: GraphQLTypes["voyce_page_components_insert_input"];
	/** aggregate max on columns */
["voyce_page_components_max_fields"]: {
		component?:string,
	created_at?:ModelTypes["timestamptz"],
	device?:string,
	id?:number,
	page?:string,
	sort?:ModelTypes["smallint"],
	title?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_page_components_min_fields"]: {
		component?:string,
	created_at?:ModelTypes["timestamptz"],
	device?:string,
	id?:number,
	page?:string,
	sort?:ModelTypes["smallint"],
	title?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.page_components" */
["voyce_page_components_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_page_components"][]
};
	/** on_conflict condition type for table "voyce.page_components" */
["voyce_page_components_on_conflict"]: GraphQLTypes["voyce_page_components_on_conflict"];
	/** Ordering options when selecting data from "voyce.page_components". */
["voyce_page_components_order_by"]: GraphQLTypes["voyce_page_components_order_by"];
	/** primary key columns input for table: voyce.page_components */
["voyce_page_components_pk_columns_input"]: GraphQLTypes["voyce_page_components_pk_columns_input"];
	/** select columns of table "voyce.page_components" */
["voyce_page_components_select_column"]: GraphQLTypes["voyce_page_components_select_column"];
	/** input type for updating data in table "voyce.page_components" */
["voyce_page_components_set_input"]: GraphQLTypes["voyce_page_components_set_input"];
	/** aggregate stddev on columns */
["voyce_page_components_stddev_fields"]: {
		id?:number,
	sort?:number
};
	/** aggregate stddev_pop on columns */
["voyce_page_components_stddev_pop_fields"]: {
		id?:number,
	sort?:number
};
	/** aggregate stddev_samp on columns */
["voyce_page_components_stddev_samp_fields"]: {
		id?:number,
	sort?:number
};
	/** Streaming cursor of the table "voyce_page_components" */
["voyce_page_components_stream_cursor_input"]: GraphQLTypes["voyce_page_components_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_page_components_stream_cursor_value_input"]: GraphQLTypes["voyce_page_components_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_page_components_sum_fields"]: {
		id?:number,
	sort?:ModelTypes["smallint"]
};
	/** update columns of table "voyce.page_components" */
["voyce_page_components_update_column"]: GraphQLTypes["voyce_page_components_update_column"];
	["voyce_page_components_updates"]: GraphQLTypes["voyce_page_components_updates"];
	/** aggregate var_pop on columns */
["voyce_page_components_var_pop_fields"]: {
		id?:number,
	sort?:number
};
	/** aggregate var_samp on columns */
["voyce_page_components_var_samp_fields"]: {
		id?:number,
	sort?:number
};
	/** aggregate variance on columns */
["voyce_page_components_variance_fields"]: {
		id?:number,
	sort?:number
};
	/** columns and relationships of "voyce.pages" */
["voyce_pages"]: {
		content?:string,
	created_at:ModelTypes["timestamp"],
	id:number,
	slug:string,
	title:string,
	updated_at?:ModelTypes["timestamp"]
};
	/** aggregated selection of "voyce.pages" */
["voyce_pages_aggregate"]: {
		aggregate?:ModelTypes["voyce_pages_aggregate_fields"],
	nodes:ModelTypes["voyce_pages"][]
};
	/** aggregate fields of "voyce.pages" */
["voyce_pages_aggregate_fields"]: {
		avg?:ModelTypes["voyce_pages_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_pages_max_fields"],
	min?:ModelTypes["voyce_pages_min_fields"],
	stddev?:ModelTypes["voyce_pages_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_pages_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_pages_stddev_samp_fields"],
	sum?:ModelTypes["voyce_pages_sum_fields"],
	var_pop?:ModelTypes["voyce_pages_var_pop_fields"],
	var_samp?:ModelTypes["voyce_pages_var_samp_fields"],
	variance?:ModelTypes["voyce_pages_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_pages_avg_fields"]: {
		id?:number
};
	/** Boolean expression to filter rows from the table "voyce.pages". All fields are combined with a logical 'AND'. */
["voyce_pages_bool_exp"]: GraphQLTypes["voyce_pages_bool_exp"];
	/** unique or primary key constraints on table "voyce.pages" */
["voyce_pages_constraint"]: GraphQLTypes["voyce_pages_constraint"];
	/** input type for incrementing numeric columns in table "voyce.pages" */
["voyce_pages_inc_input"]: GraphQLTypes["voyce_pages_inc_input"];
	/** input type for inserting data into table "voyce.pages" */
["voyce_pages_insert_input"]: GraphQLTypes["voyce_pages_insert_input"];
	/** aggregate max on columns */
["voyce_pages_max_fields"]: {
		content?:string,
	created_at?:ModelTypes["timestamp"],
	id?:number,
	slug?:string,
	title?:string,
	updated_at?:ModelTypes["timestamp"]
};
	/** aggregate min on columns */
["voyce_pages_min_fields"]: {
		content?:string,
	created_at?:ModelTypes["timestamp"],
	id?:number,
	slug?:string,
	title?:string,
	updated_at?:ModelTypes["timestamp"]
};
	/** response of any mutation on the table "voyce.pages" */
["voyce_pages_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_pages"][]
};
	/** on_conflict condition type for table "voyce.pages" */
["voyce_pages_on_conflict"]: GraphQLTypes["voyce_pages_on_conflict"];
	/** Ordering options when selecting data from "voyce.pages". */
["voyce_pages_order_by"]: GraphQLTypes["voyce_pages_order_by"];
	/** primary key columns input for table: voyce.pages */
["voyce_pages_pk_columns_input"]: GraphQLTypes["voyce_pages_pk_columns_input"];
	/** select columns of table "voyce.pages" */
["voyce_pages_select_column"]: GraphQLTypes["voyce_pages_select_column"];
	/** input type for updating data in table "voyce.pages" */
["voyce_pages_set_input"]: GraphQLTypes["voyce_pages_set_input"];
	/** aggregate stddev on columns */
["voyce_pages_stddev_fields"]: {
		id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_pages_stddev_pop_fields"]: {
		id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_pages_stddev_samp_fields"]: {
		id?:number
};
	/** Streaming cursor of the table "voyce_pages" */
["voyce_pages_stream_cursor_input"]: GraphQLTypes["voyce_pages_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_pages_stream_cursor_value_input"]: GraphQLTypes["voyce_pages_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_pages_sum_fields"]: {
		id?:number
};
	/** update columns of table "voyce.pages" */
["voyce_pages_update_column"]: GraphQLTypes["voyce_pages_update_column"];
	["voyce_pages_updates"]: GraphQLTypes["voyce_pages_updates"];
	/** aggregate var_pop on columns */
["voyce_pages_var_pop_fields"]: {
		id?:number
};
	/** aggregate var_samp on columns */
["voyce_pages_var_samp_fields"]: {
		id?:number
};
	/** aggregate variance on columns */
["voyce_pages_variance_fields"]: {
		id?:number
};
	/** columns and relationships of "voyce.panels_comments" */
["voyce_panels_comments"]: {
		animated_image?:string,
	/** An object relationship */
	chapter_panel:ModelTypes["voyce_chapter_panels"],
	created_at:ModelTypes["timestamptz"],
	id:number,
	image?:string,
	linked_id:number,
	/** An object relationship */
	panels_comment?:ModelTypes["voyce_panels_comments"],
	/** An array relationship */
	panels_comments:ModelTypes["voyce_panels_comments"][],
	/** An aggregate relationship */
	panels_comments_aggregate:ModelTypes["voyce_panels_comments_aggregate"],
	/** An array relationship */
	panels_reactions:ModelTypes["voyce_panels_reactions"][],
	/** An aggregate relationship */
	panels_reactions_aggregate:ModelTypes["voyce_panels_reactions_aggregate"],
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	text:string,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.panels_comments" */
["voyce_panels_comments_aggregate"]: {
		aggregate?:ModelTypes["voyce_panels_comments_aggregate_fields"],
	nodes:ModelTypes["voyce_panels_comments"][]
};
	["voyce_panels_comments_aggregate_bool_exp"]: GraphQLTypes["voyce_panels_comments_aggregate_bool_exp"];
	["voyce_panels_comments_aggregate_bool_exp_count"]: GraphQLTypes["voyce_panels_comments_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.panels_comments" */
["voyce_panels_comments_aggregate_fields"]: {
		avg?:ModelTypes["voyce_panels_comments_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_panels_comments_max_fields"],
	min?:ModelTypes["voyce_panels_comments_min_fields"],
	stddev?:ModelTypes["voyce_panels_comments_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_panels_comments_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_panels_comments_stddev_samp_fields"],
	sum?:ModelTypes["voyce_panels_comments_sum_fields"],
	var_pop?:ModelTypes["voyce_panels_comments_var_pop_fields"],
	var_samp?:ModelTypes["voyce_panels_comments_var_samp_fields"],
	variance?:ModelTypes["voyce_panels_comments_variance_fields"]
};
	/** order by aggregate values of table "voyce.panels_comments" */
["voyce_panels_comments_aggregate_order_by"]: GraphQLTypes["voyce_panels_comments_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.panels_comments" */
["voyce_panels_comments_arr_rel_insert_input"]: GraphQLTypes["voyce_panels_comments_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_panels_comments_avg_fields"]: {
		id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_avg_order_by"]: GraphQLTypes["voyce_panels_comments_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.panels_comments". All fields are combined with a logical 'AND'. */
["voyce_panels_comments_bool_exp"]: GraphQLTypes["voyce_panels_comments_bool_exp"];
	/** unique or primary key constraints on table "voyce.panels_comments" */
["voyce_panels_comments_constraint"]: GraphQLTypes["voyce_panels_comments_constraint"];
	/** input type for incrementing numeric columns in table "voyce.panels_comments" */
["voyce_panels_comments_inc_input"]: GraphQLTypes["voyce_panels_comments_inc_input"];
	/** input type for inserting data into table "voyce.panels_comments" */
["voyce_panels_comments_insert_input"]: GraphQLTypes["voyce_panels_comments_insert_input"];
	/** aggregate max on columns */
["voyce_panels_comments_max_fields"]: {
		animated_image?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	image?:string,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	text?:string,
	user_id?:number
};
	/** order by max() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_max_order_by"]: GraphQLTypes["voyce_panels_comments_max_order_by"];
	/** aggregate min on columns */
["voyce_panels_comments_min_fields"]: {
		animated_image?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	image?:string,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	text?:string,
	user_id?:number
};
	/** order by min() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_min_order_by"]: GraphQLTypes["voyce_panels_comments_min_order_by"];
	/** response of any mutation on the table "voyce.panels_comments" */
["voyce_panels_comments_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_panels_comments"][]
};
	/** input type for inserting object relation for remote table "voyce.panels_comments" */
["voyce_panels_comments_obj_rel_insert_input"]: GraphQLTypes["voyce_panels_comments_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.panels_comments" */
["voyce_panels_comments_on_conflict"]: GraphQLTypes["voyce_panels_comments_on_conflict"];
	/** Ordering options when selecting data from "voyce.panels_comments". */
["voyce_panels_comments_order_by"]: GraphQLTypes["voyce_panels_comments_order_by"];
	/** primary key columns input for table: voyce.panels_comments */
["voyce_panels_comments_pk_columns_input"]: GraphQLTypes["voyce_panels_comments_pk_columns_input"];
	/** select columns of table "voyce.panels_comments" */
["voyce_panels_comments_select_column"]: GraphQLTypes["voyce_panels_comments_select_column"];
	/** input type for updating data in table "voyce.panels_comments" */
["voyce_panels_comments_set_input"]: GraphQLTypes["voyce_panels_comments_set_input"];
	/** aggregate stddev on columns */
["voyce_panels_comments_stddev_fields"]: {
		id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_stddev_order_by"]: GraphQLTypes["voyce_panels_comments_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_panels_comments_stddev_pop_fields"]: {
		id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_stddev_pop_order_by"]: GraphQLTypes["voyce_panels_comments_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_panels_comments_stddev_samp_fields"]: {
		id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_stddev_samp_order_by"]: GraphQLTypes["voyce_panels_comments_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_panels_comments" */
["voyce_panels_comments_stream_cursor_input"]: GraphQLTypes["voyce_panels_comments_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_panels_comments_stream_cursor_value_input"]: GraphQLTypes["voyce_panels_comments_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_panels_comments_sum_fields"]: {
		id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_sum_order_by"]: GraphQLTypes["voyce_panels_comments_sum_order_by"];
	/** update columns of table "voyce.panels_comments" */
["voyce_panels_comments_update_column"]: GraphQLTypes["voyce_panels_comments_update_column"];
	["voyce_panels_comments_updates"]: GraphQLTypes["voyce_panels_comments_updates"];
	/** aggregate var_pop on columns */
["voyce_panels_comments_var_pop_fields"]: {
		id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_var_pop_order_by"]: GraphQLTypes["voyce_panels_comments_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_panels_comments_var_samp_fields"]: {
		id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_var_samp_order_by"]: GraphQLTypes["voyce_panels_comments_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_panels_comments_variance_fields"]: {
		id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_variance_order_by"]: GraphQLTypes["voyce_panels_comments_variance_order_by"];
	/** columns and relationships of "voyce.panels_reactions" */
["voyce_panels_reactions"]: {
		/** An object relationship */
	chapter_panel:ModelTypes["voyce_chapter_panels"],
	comment_id?:number,
	created_at:ModelTypes["timestamptz"],
	id:number,
	linked_entity_id:number,
	/** An object relationship */
	panels_comment?:ModelTypes["voyce_panels_comments"],
	reaction:number,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.panels_reactions" */
["voyce_panels_reactions_aggregate"]: {
		aggregate?:ModelTypes["voyce_panels_reactions_aggregate_fields"],
	nodes:ModelTypes["voyce_panels_reactions"][]
};
	["voyce_panels_reactions_aggregate_bool_exp"]: GraphQLTypes["voyce_panels_reactions_aggregate_bool_exp"];
	["voyce_panels_reactions_aggregate_bool_exp_count"]: GraphQLTypes["voyce_panels_reactions_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.panels_reactions" */
["voyce_panels_reactions_aggregate_fields"]: {
		avg?:ModelTypes["voyce_panels_reactions_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_panels_reactions_max_fields"],
	min?:ModelTypes["voyce_panels_reactions_min_fields"],
	stddev?:ModelTypes["voyce_panels_reactions_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_panels_reactions_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_panels_reactions_stddev_samp_fields"],
	sum?:ModelTypes["voyce_panels_reactions_sum_fields"],
	var_pop?:ModelTypes["voyce_panels_reactions_var_pop_fields"],
	var_samp?:ModelTypes["voyce_panels_reactions_var_samp_fields"],
	variance?:ModelTypes["voyce_panels_reactions_variance_fields"]
};
	/** order by aggregate values of table "voyce.panels_reactions" */
["voyce_panels_reactions_aggregate_order_by"]: GraphQLTypes["voyce_panels_reactions_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.panels_reactions" */
["voyce_panels_reactions_arr_rel_insert_input"]: GraphQLTypes["voyce_panels_reactions_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_panels_reactions_avg_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_avg_order_by"]: GraphQLTypes["voyce_panels_reactions_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.panels_reactions". All fields are combined with a logical 'AND'. */
["voyce_panels_reactions_bool_exp"]: GraphQLTypes["voyce_panels_reactions_bool_exp"];
	/** unique or primary key constraints on table "voyce.panels_reactions" */
["voyce_panels_reactions_constraint"]: GraphQLTypes["voyce_panels_reactions_constraint"];
	/** Tabel for reactions count */
["voyce_panels_reactions_count"]: {
		/** An object relationship */
	chapter_panel:ModelTypes["voyce_chapter_panels"],
	count?:number,
	id:number,
	panel_id:number,
	reaction?:number
};
	/** aggregated selection of "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_aggregate"]: {
		aggregate?:ModelTypes["voyce_panels_reactions_count_aggregate_fields"],
	nodes:ModelTypes["voyce_panels_reactions_count"][]
};
	["voyce_panels_reactions_count_aggregate_bool_exp"]: GraphQLTypes["voyce_panels_reactions_count_aggregate_bool_exp"];
	["voyce_panels_reactions_count_aggregate_bool_exp_count"]: GraphQLTypes["voyce_panels_reactions_count_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_aggregate_fields"]: {
		avg?:ModelTypes["voyce_panels_reactions_count_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_panels_reactions_count_max_fields"],
	min?:ModelTypes["voyce_panels_reactions_count_min_fields"],
	stddev?:ModelTypes["voyce_panels_reactions_count_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_panels_reactions_count_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_panels_reactions_count_stddev_samp_fields"],
	sum?:ModelTypes["voyce_panels_reactions_count_sum_fields"],
	var_pop?:ModelTypes["voyce_panels_reactions_count_var_pop_fields"],
	var_samp?:ModelTypes["voyce_panels_reactions_count_var_samp_fields"],
	variance?:ModelTypes["voyce_panels_reactions_count_variance_fields"]
};
	/** order by aggregate values of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_aggregate_order_by"]: GraphQLTypes["voyce_panels_reactions_count_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_arr_rel_insert_input"]: GraphQLTypes["voyce_panels_reactions_count_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_panels_reactions_count_avg_fields"]: {
		count?:number,
	id?:number,
	panel_id?:number,
	reaction?:number
};
	/** order by avg() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_avg_order_by"]: GraphQLTypes["voyce_panels_reactions_count_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.panels_reactions_count". All fields are combined with a logical 'AND'. */
["voyce_panels_reactions_count_bool_exp"]: GraphQLTypes["voyce_panels_reactions_count_bool_exp"];
	/** unique or primary key constraints on table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_constraint"]: GraphQLTypes["voyce_panels_reactions_count_constraint"];
	/** input type for incrementing numeric columns in table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_inc_input"]: GraphQLTypes["voyce_panels_reactions_count_inc_input"];
	/** input type for inserting data into table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_insert_input"]: GraphQLTypes["voyce_panels_reactions_count_insert_input"];
	/** aggregate max on columns */
["voyce_panels_reactions_count_max_fields"]: {
		count?:number,
	id?:number,
	panel_id?:number,
	reaction?:number
};
	/** order by max() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_max_order_by"]: GraphQLTypes["voyce_panels_reactions_count_max_order_by"];
	/** aggregate min on columns */
["voyce_panels_reactions_count_min_fields"]: {
		count?:number,
	id?:number,
	panel_id?:number,
	reaction?:number
};
	/** order by min() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_min_order_by"]: GraphQLTypes["voyce_panels_reactions_count_min_order_by"];
	/** response of any mutation on the table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_panels_reactions_count"][]
};
	/** on_conflict condition type for table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_on_conflict"]: GraphQLTypes["voyce_panels_reactions_count_on_conflict"];
	/** Ordering options when selecting data from "voyce.panels_reactions_count". */
["voyce_panels_reactions_count_order_by"]: GraphQLTypes["voyce_panels_reactions_count_order_by"];
	/** primary key columns input for table: voyce.panels_reactions_count */
["voyce_panels_reactions_count_pk_columns_input"]: GraphQLTypes["voyce_panels_reactions_count_pk_columns_input"];
	/** select columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_select_column"]: GraphQLTypes["voyce_panels_reactions_count_select_column"];
	/** input type for updating data in table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_set_input"]: GraphQLTypes["voyce_panels_reactions_count_set_input"];
	/** aggregate stddev on columns */
["voyce_panels_reactions_count_stddev_fields"]: {
		count?:number,
	id?:number,
	panel_id?:number,
	reaction?:number
};
	/** order by stddev() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_stddev_order_by"]: GraphQLTypes["voyce_panels_reactions_count_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_panels_reactions_count_stddev_pop_fields"]: {
		count?:number,
	id?:number,
	panel_id?:number,
	reaction?:number
};
	/** order by stddev_pop() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_stddev_pop_order_by"]: GraphQLTypes["voyce_panels_reactions_count_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_panels_reactions_count_stddev_samp_fields"]: {
		count?:number,
	id?:number,
	panel_id?:number,
	reaction?:number
};
	/** order by stddev_samp() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_stddev_samp_order_by"]: GraphQLTypes["voyce_panels_reactions_count_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_panels_reactions_count" */
["voyce_panels_reactions_count_stream_cursor_input"]: GraphQLTypes["voyce_panels_reactions_count_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_panels_reactions_count_stream_cursor_value_input"]: GraphQLTypes["voyce_panels_reactions_count_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_panels_reactions_count_sum_fields"]: {
		count?:number,
	id?:number,
	panel_id?:number,
	reaction?:number
};
	/** order by sum() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_sum_order_by"]: GraphQLTypes["voyce_panels_reactions_count_sum_order_by"];
	/** update columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_update_column"]: GraphQLTypes["voyce_panels_reactions_count_update_column"];
	["voyce_panels_reactions_count_updates"]: GraphQLTypes["voyce_panels_reactions_count_updates"];
	/** aggregate var_pop on columns */
["voyce_panels_reactions_count_var_pop_fields"]: {
		count?:number,
	id?:number,
	panel_id?:number,
	reaction?:number
};
	/** order by var_pop() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_var_pop_order_by"]: GraphQLTypes["voyce_panels_reactions_count_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_panels_reactions_count_var_samp_fields"]: {
		count?:number,
	id?:number,
	panel_id?:number,
	reaction?:number
};
	/** order by var_samp() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_var_samp_order_by"]: GraphQLTypes["voyce_panels_reactions_count_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_panels_reactions_count_variance_fields"]: {
		count?:number,
	id?:number,
	panel_id?:number,
	reaction?:number
};
	/** order by variance() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_variance_order_by"]: GraphQLTypes["voyce_panels_reactions_count_variance_order_by"];
	/** input type for incrementing numeric columns in table "voyce.panels_reactions" */
["voyce_panels_reactions_inc_input"]: GraphQLTypes["voyce_panels_reactions_inc_input"];
	/** input type for inserting data into table "voyce.panels_reactions" */
["voyce_panels_reactions_insert_input"]: GraphQLTypes["voyce_panels_reactions_insert_input"];
	/** aggregate max on columns */
["voyce_panels_reactions_max_fields"]: {
		comment_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by max() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_max_order_by"]: GraphQLTypes["voyce_panels_reactions_max_order_by"];
	/** aggregate min on columns */
["voyce_panels_reactions_min_fields"]: {
		comment_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by min() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_min_order_by"]: GraphQLTypes["voyce_panels_reactions_min_order_by"];
	/** response of any mutation on the table "voyce.panels_reactions" */
["voyce_panels_reactions_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_panels_reactions"][]
};
	/** on_conflict condition type for table "voyce.panels_reactions" */
["voyce_panels_reactions_on_conflict"]: GraphQLTypes["voyce_panels_reactions_on_conflict"];
	/** Ordering options when selecting data from "voyce.panels_reactions". */
["voyce_panels_reactions_order_by"]: GraphQLTypes["voyce_panels_reactions_order_by"];
	/** primary key columns input for table: voyce.panels_reactions */
["voyce_panels_reactions_pk_columns_input"]: GraphQLTypes["voyce_panels_reactions_pk_columns_input"];
	/** select columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_select_column"]: GraphQLTypes["voyce_panels_reactions_select_column"];
	/** input type for updating data in table "voyce.panels_reactions" */
["voyce_panels_reactions_set_input"]: GraphQLTypes["voyce_panels_reactions_set_input"];
	/** aggregate stddev on columns */
["voyce_panels_reactions_stddev_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_stddev_order_by"]: GraphQLTypes["voyce_panels_reactions_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_panels_reactions_stddev_pop_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_stddev_pop_order_by"]: GraphQLTypes["voyce_panels_reactions_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_panels_reactions_stddev_samp_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_stddev_samp_order_by"]: GraphQLTypes["voyce_panels_reactions_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_panels_reactions" */
["voyce_panels_reactions_stream_cursor_input"]: GraphQLTypes["voyce_panels_reactions_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_panels_reactions_stream_cursor_value_input"]: GraphQLTypes["voyce_panels_reactions_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_panels_reactions_sum_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_sum_order_by"]: GraphQLTypes["voyce_panels_reactions_sum_order_by"];
	/** update columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_update_column"]: GraphQLTypes["voyce_panels_reactions_update_column"];
	["voyce_panels_reactions_updates"]: GraphQLTypes["voyce_panels_reactions_updates"];
	/** aggregate var_pop on columns */
["voyce_panels_reactions_var_pop_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_var_pop_order_by"]: GraphQLTypes["voyce_panels_reactions_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_panels_reactions_var_samp_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_var_samp_order_by"]: GraphQLTypes["voyce_panels_reactions_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_panels_reactions_variance_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_variance_order_by"]: GraphQLTypes["voyce_panels_reactions_variance_order_by"];
	/** columns and relationships of "voyce.payments_to_creators" */
["voyce_payments_to_creators"]: {
		amount:number,
	created_at:ModelTypes["timestamptz"],
	id:number,
	stripe_id:string,
	/** An object relationship */
	user_from:ModelTypes["voyce_users"],
	user_from_id:number,
	/** An object relationship */
	user_to:ModelTypes["voyce_users"],
	user_to_id:number
};
	/** aggregated selection of "voyce.payments_to_creators" */
["voyce_payments_to_creators_aggregate"]: {
		aggregate?:ModelTypes["voyce_payments_to_creators_aggregate_fields"],
	nodes:ModelTypes["voyce_payments_to_creators"][]
};
	["voyce_payments_to_creators_aggregate_bool_exp"]: GraphQLTypes["voyce_payments_to_creators_aggregate_bool_exp"];
	["voyce_payments_to_creators_aggregate_bool_exp_count"]: GraphQLTypes["voyce_payments_to_creators_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.payments_to_creators" */
["voyce_payments_to_creators_aggregate_fields"]: {
		avg?:ModelTypes["voyce_payments_to_creators_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_payments_to_creators_max_fields"],
	min?:ModelTypes["voyce_payments_to_creators_min_fields"],
	stddev?:ModelTypes["voyce_payments_to_creators_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_payments_to_creators_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_payments_to_creators_stddev_samp_fields"],
	sum?:ModelTypes["voyce_payments_to_creators_sum_fields"],
	var_pop?:ModelTypes["voyce_payments_to_creators_var_pop_fields"],
	var_samp?:ModelTypes["voyce_payments_to_creators_var_samp_fields"],
	variance?:ModelTypes["voyce_payments_to_creators_variance_fields"]
};
	/** order by aggregate values of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_aggregate_order_by"]: GraphQLTypes["voyce_payments_to_creators_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.payments_to_creators" */
["voyce_payments_to_creators_arr_rel_insert_input"]: GraphQLTypes["voyce_payments_to_creators_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_payments_to_creators_avg_fields"]: {
		amount?:number,
	id?:number,
	user_from_id?:number,
	user_to_id?:number
};
	/** order by avg() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_avg_order_by"]: GraphQLTypes["voyce_payments_to_creators_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.payments_to_creators". All fields are combined with a logical 'AND'. */
["voyce_payments_to_creators_bool_exp"]: GraphQLTypes["voyce_payments_to_creators_bool_exp"];
	/** unique or primary key constraints on table "voyce.payments_to_creators" */
["voyce_payments_to_creators_constraint"]: GraphQLTypes["voyce_payments_to_creators_constraint"];
	/** input type for incrementing numeric columns in table "voyce.payments_to_creators" */
["voyce_payments_to_creators_inc_input"]: GraphQLTypes["voyce_payments_to_creators_inc_input"];
	/** input type for inserting data into table "voyce.payments_to_creators" */
["voyce_payments_to_creators_insert_input"]: GraphQLTypes["voyce_payments_to_creators_insert_input"];
	/** aggregate max on columns */
["voyce_payments_to_creators_max_fields"]: {
		amount?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	stripe_id?:string,
	user_from_id?:number,
	user_to_id?:number
};
	/** order by max() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_max_order_by"]: GraphQLTypes["voyce_payments_to_creators_max_order_by"];
	/** aggregate min on columns */
["voyce_payments_to_creators_min_fields"]: {
		amount?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	stripe_id?:string,
	user_from_id?:number,
	user_to_id?:number
};
	/** order by min() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_min_order_by"]: GraphQLTypes["voyce_payments_to_creators_min_order_by"];
	/** response of any mutation on the table "voyce.payments_to_creators" */
["voyce_payments_to_creators_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_payments_to_creators"][]
};
	/** on_conflict condition type for table "voyce.payments_to_creators" */
["voyce_payments_to_creators_on_conflict"]: GraphQLTypes["voyce_payments_to_creators_on_conflict"];
	/** Ordering options when selecting data from "voyce.payments_to_creators". */
["voyce_payments_to_creators_order_by"]: GraphQLTypes["voyce_payments_to_creators_order_by"];
	/** primary key columns input for table: voyce.payments_to_creators */
["voyce_payments_to_creators_pk_columns_input"]: GraphQLTypes["voyce_payments_to_creators_pk_columns_input"];
	/** select columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_select_column"]: GraphQLTypes["voyce_payments_to_creators_select_column"];
	/** input type for updating data in table "voyce.payments_to_creators" */
["voyce_payments_to_creators_set_input"]: GraphQLTypes["voyce_payments_to_creators_set_input"];
	/** aggregate stddev on columns */
["voyce_payments_to_creators_stddev_fields"]: {
		amount?:number,
	id?:number,
	user_from_id?:number,
	user_to_id?:number
};
	/** order by stddev() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_stddev_order_by"]: GraphQLTypes["voyce_payments_to_creators_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_payments_to_creators_stddev_pop_fields"]: {
		amount?:number,
	id?:number,
	user_from_id?:number,
	user_to_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_stddev_pop_order_by"]: GraphQLTypes["voyce_payments_to_creators_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_payments_to_creators_stddev_samp_fields"]: {
		amount?:number,
	id?:number,
	user_from_id?:number,
	user_to_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_stddev_samp_order_by"]: GraphQLTypes["voyce_payments_to_creators_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_payments_to_creators" */
["voyce_payments_to_creators_stream_cursor_input"]: GraphQLTypes["voyce_payments_to_creators_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_payments_to_creators_stream_cursor_value_input"]: GraphQLTypes["voyce_payments_to_creators_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_payments_to_creators_sum_fields"]: {
		amount?:number,
	id?:number,
	user_from_id?:number,
	user_to_id?:number
};
	/** order by sum() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_sum_order_by"]: GraphQLTypes["voyce_payments_to_creators_sum_order_by"];
	/** update columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_update_column"]: GraphQLTypes["voyce_payments_to_creators_update_column"];
	["voyce_payments_to_creators_updates"]: GraphQLTypes["voyce_payments_to_creators_updates"];
	/** aggregate var_pop on columns */
["voyce_payments_to_creators_var_pop_fields"]: {
		amount?:number,
	id?:number,
	user_from_id?:number,
	user_to_id?:number
};
	/** order by var_pop() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_var_pop_order_by"]: GraphQLTypes["voyce_payments_to_creators_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_payments_to_creators_var_samp_fields"]: {
		amount?:number,
	id?:number,
	user_from_id?:number,
	user_to_id?:number
};
	/** order by var_samp() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_var_samp_order_by"]: GraphQLTypes["voyce_payments_to_creators_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_payments_to_creators_variance_fields"]: {
		amount?:number,
	id?:number,
	user_from_id?:number,
	user_to_id?:number
};
	/** order by variance() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_variance_order_by"]: GraphQLTypes["voyce_payments_to_creators_variance_order_by"];
	/** columns and relationships of "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count"]: {
		count:ModelTypes["bigint"],
	series_id:number
};
	/** aggregated selection of "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_aggregate"]: {
		aggregate?:ModelTypes["voyce_pg_rs_series_views_count_aggregate_fields"],
	nodes:ModelTypes["voyce_pg_rs_series_views_count"][]
};
	/** aggregate fields of "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_aggregate_fields"]: {
		avg?:ModelTypes["voyce_pg_rs_series_views_count_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_pg_rs_series_views_count_max_fields"],
	min?:ModelTypes["voyce_pg_rs_series_views_count_min_fields"],
	stddev?:ModelTypes["voyce_pg_rs_series_views_count_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_pg_rs_series_views_count_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_pg_rs_series_views_count_stddev_samp_fields"],
	sum?:ModelTypes["voyce_pg_rs_series_views_count_sum_fields"],
	var_pop?:ModelTypes["voyce_pg_rs_series_views_count_var_pop_fields"],
	var_samp?:ModelTypes["voyce_pg_rs_series_views_count_var_samp_fields"],
	variance?:ModelTypes["voyce_pg_rs_series_views_count_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_pg_rs_series_views_count_avg_fields"]: {
		count?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.pg_rs_series_views_count". All fields are combined with a logical 'AND'. */
["voyce_pg_rs_series_views_count_bool_exp"]: GraphQLTypes["voyce_pg_rs_series_views_count_bool_exp"];
	/** unique or primary key constraints on table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_constraint"]: GraphQLTypes["voyce_pg_rs_series_views_count_constraint"];
	/** input type for incrementing numeric columns in table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_inc_input"]: GraphQLTypes["voyce_pg_rs_series_views_count_inc_input"];
	/** input type for inserting data into table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_insert_input"]: GraphQLTypes["voyce_pg_rs_series_views_count_insert_input"];
	/** aggregate max on columns */
["voyce_pg_rs_series_views_count_max_fields"]: {
		count?:ModelTypes["bigint"],
	series_id?:number
};
	/** aggregate min on columns */
["voyce_pg_rs_series_views_count_min_fields"]: {
		count?:ModelTypes["bigint"],
	series_id?:number
};
	/** response of any mutation on the table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_pg_rs_series_views_count"][]
};
	/** input type for inserting object relation for remote table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_obj_rel_insert_input"]: GraphQLTypes["voyce_pg_rs_series_views_count_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_on_conflict"]: GraphQLTypes["voyce_pg_rs_series_views_count_on_conflict"];
	/** Ordering options when selecting data from "voyce.pg_rs_series_views_count". */
["voyce_pg_rs_series_views_count_order_by"]: GraphQLTypes["voyce_pg_rs_series_views_count_order_by"];
	/** primary key columns input for table: voyce.pg_rs_series_views_count */
["voyce_pg_rs_series_views_count_pk_columns_input"]: GraphQLTypes["voyce_pg_rs_series_views_count_pk_columns_input"];
	/** select columns of table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_select_column"]: GraphQLTypes["voyce_pg_rs_series_views_count_select_column"];
	/** input type for updating data in table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_set_input"]: GraphQLTypes["voyce_pg_rs_series_views_count_set_input"];
	/** aggregate stddev on columns */
["voyce_pg_rs_series_views_count_stddev_fields"]: {
		count?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_pg_rs_series_views_count_stddev_pop_fields"]: {
		count?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_pg_rs_series_views_count_stddev_samp_fields"]: {
		count?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_stream_cursor_input"]: GraphQLTypes["voyce_pg_rs_series_views_count_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_pg_rs_series_views_count_stream_cursor_value_input"]: GraphQLTypes["voyce_pg_rs_series_views_count_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_pg_rs_series_views_count_sum_fields"]: {
		count?:ModelTypes["bigint"],
	series_id?:number
};
	/** update columns of table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_update_column"]: GraphQLTypes["voyce_pg_rs_series_views_count_update_column"];
	["voyce_pg_rs_series_views_count_updates"]: GraphQLTypes["voyce_pg_rs_series_views_count_updates"];
	/** aggregate var_pop on columns */
["voyce_pg_rs_series_views_count_var_pop_fields"]: {
		count?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_pg_rs_series_views_count_var_samp_fields"]: {
		count?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_pg_rs_series_views_count_variance_fields"]: {
		count?:number,
	series_id?:number
};
	/** columns and relationships of "voyce.polls" */
["voyce_polls"]: {
		comments_count?:number,
	created_at:ModelTypes["timestamp"],
	end_date?:ModelTypes["timestamp"],
	id:number,
	last_activity_date:ModelTypes["timestamp"],
	likes_count?:number,
	outdated:boolean,
	/** An array relationship */
	polls_answers:ModelTypes["voyce_polls_answers"][],
	/** An aggregate relationship */
	polls_answers_aggregate:ModelTypes["voyce_polls_answers_aggregate"],
	/** An array relationship */
	polls_comments:ModelTypes["voyce_polls_comments"][],
	/** An aggregate relationship */
	polls_comments_aggregate:ModelTypes["voyce_polls_comments_aggregate"],
	/** An array relationship */
	polls_reactions:ModelTypes["voyce_polls_reactions"][],
	/** An aggregate relationship */
	polls_reactions_aggregate:ModelTypes["voyce_polls_reactions_aggregate"],
	/** An array relationship */
	polls_votes:ModelTypes["voyce_polls_votes"][],
	/** An aggregate relationship */
	polls_votes_aggregate:ModelTypes["voyce_polls_votes_aggregate"],
	popularity:number,
	popularity_weekly:number,
	reactions_count?:number,
	spoiler:boolean,
	title?:string,
	total_popularity:number,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number,
	votes_count?:number
};
	/** aggregated selection of "voyce.polls" */
["voyce_polls_aggregate"]: {
		aggregate?:ModelTypes["voyce_polls_aggregate_fields"],
	nodes:ModelTypes["voyce_polls"][]
};
	["voyce_polls_aggregate_bool_exp"]: GraphQLTypes["voyce_polls_aggregate_bool_exp"];
	["voyce_polls_aggregate_bool_exp_bool_and"]: GraphQLTypes["voyce_polls_aggregate_bool_exp_bool_and"];
	["voyce_polls_aggregate_bool_exp_bool_or"]: GraphQLTypes["voyce_polls_aggregate_bool_exp_bool_or"];
	["voyce_polls_aggregate_bool_exp_count"]: GraphQLTypes["voyce_polls_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.polls" */
["voyce_polls_aggregate_fields"]: {
		avg?:ModelTypes["voyce_polls_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_polls_max_fields"],
	min?:ModelTypes["voyce_polls_min_fields"],
	stddev?:ModelTypes["voyce_polls_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_polls_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_polls_stddev_samp_fields"],
	sum?:ModelTypes["voyce_polls_sum_fields"],
	var_pop?:ModelTypes["voyce_polls_var_pop_fields"],
	var_samp?:ModelTypes["voyce_polls_var_samp_fields"],
	variance?:ModelTypes["voyce_polls_variance_fields"]
};
	/** order by aggregate values of table "voyce.polls" */
["voyce_polls_aggregate_order_by"]: GraphQLTypes["voyce_polls_aggregate_order_by"];
	/** columns and relationships of "voyce.polls_answers" */
["voyce_polls_answers"]: {
		id:number,
	image?:string,
	order:number,
	/** An object relationship */
	poll:ModelTypes["voyce_polls"],
	poll_id:number,
	/** An array relationship */
	polls_votes:ModelTypes["voyce_polls_votes"][],
	/** An aggregate relationship */
	polls_votes_aggregate:ModelTypes["voyce_polls_votes_aggregate"],
	text?:string,
	votes_count?:number
};
	/** aggregated selection of "voyce.polls_answers" */
["voyce_polls_answers_aggregate"]: {
		aggregate?:ModelTypes["voyce_polls_answers_aggregate_fields"],
	nodes:ModelTypes["voyce_polls_answers"][]
};
	["voyce_polls_answers_aggregate_bool_exp"]: GraphQLTypes["voyce_polls_answers_aggregate_bool_exp"];
	["voyce_polls_answers_aggregate_bool_exp_count"]: GraphQLTypes["voyce_polls_answers_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.polls_answers" */
["voyce_polls_answers_aggregate_fields"]: {
		avg?:ModelTypes["voyce_polls_answers_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_polls_answers_max_fields"],
	min?:ModelTypes["voyce_polls_answers_min_fields"],
	stddev?:ModelTypes["voyce_polls_answers_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_polls_answers_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_polls_answers_stddev_samp_fields"],
	sum?:ModelTypes["voyce_polls_answers_sum_fields"],
	var_pop?:ModelTypes["voyce_polls_answers_var_pop_fields"],
	var_samp?:ModelTypes["voyce_polls_answers_var_samp_fields"],
	variance?:ModelTypes["voyce_polls_answers_variance_fields"]
};
	/** order by aggregate values of table "voyce.polls_answers" */
["voyce_polls_answers_aggregate_order_by"]: GraphQLTypes["voyce_polls_answers_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.polls_answers" */
["voyce_polls_answers_arr_rel_insert_input"]: GraphQLTypes["voyce_polls_answers_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_polls_answers_avg_fields"]: {
		id?:number,
	order?:number,
	poll_id?:number,
	votes_count?:number
};
	/** order by avg() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_avg_order_by"]: GraphQLTypes["voyce_polls_answers_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.polls_answers". All fields are combined with a logical 'AND'. */
["voyce_polls_answers_bool_exp"]: GraphQLTypes["voyce_polls_answers_bool_exp"];
	/** unique or primary key constraints on table "voyce.polls_answers" */
["voyce_polls_answers_constraint"]: GraphQLTypes["voyce_polls_answers_constraint"];
	/** input type for incrementing numeric columns in table "voyce.polls_answers" */
["voyce_polls_answers_inc_input"]: GraphQLTypes["voyce_polls_answers_inc_input"];
	/** input type for inserting data into table "voyce.polls_answers" */
["voyce_polls_answers_insert_input"]: GraphQLTypes["voyce_polls_answers_insert_input"];
	/** aggregate max on columns */
["voyce_polls_answers_max_fields"]: {
		id?:number,
	image?:string,
	order?:number,
	poll_id?:number,
	text?:string,
	votes_count?:number
};
	/** order by max() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_max_order_by"]: GraphQLTypes["voyce_polls_answers_max_order_by"];
	/** aggregate min on columns */
["voyce_polls_answers_min_fields"]: {
		id?:number,
	image?:string,
	order?:number,
	poll_id?:number,
	text?:string,
	votes_count?:number
};
	/** order by min() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_min_order_by"]: GraphQLTypes["voyce_polls_answers_min_order_by"];
	/** response of any mutation on the table "voyce.polls_answers" */
["voyce_polls_answers_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_polls_answers"][]
};
	/** input type for inserting object relation for remote table "voyce.polls_answers" */
["voyce_polls_answers_obj_rel_insert_input"]: GraphQLTypes["voyce_polls_answers_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.polls_answers" */
["voyce_polls_answers_on_conflict"]: GraphQLTypes["voyce_polls_answers_on_conflict"];
	/** Ordering options when selecting data from "voyce.polls_answers". */
["voyce_polls_answers_order_by"]: GraphQLTypes["voyce_polls_answers_order_by"];
	/** primary key columns input for table: voyce.polls_answers */
["voyce_polls_answers_pk_columns_input"]: GraphQLTypes["voyce_polls_answers_pk_columns_input"];
	/** select columns of table "voyce.polls_answers" */
["voyce_polls_answers_select_column"]: GraphQLTypes["voyce_polls_answers_select_column"];
	/** input type for updating data in table "voyce.polls_answers" */
["voyce_polls_answers_set_input"]: GraphQLTypes["voyce_polls_answers_set_input"];
	/** aggregate stddev on columns */
["voyce_polls_answers_stddev_fields"]: {
		id?:number,
	order?:number,
	poll_id?:number,
	votes_count?:number
};
	/** order by stddev() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_stddev_order_by"]: GraphQLTypes["voyce_polls_answers_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_polls_answers_stddev_pop_fields"]: {
		id?:number,
	order?:number,
	poll_id?:number,
	votes_count?:number
};
	/** order by stddev_pop() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_stddev_pop_order_by"]: GraphQLTypes["voyce_polls_answers_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_polls_answers_stddev_samp_fields"]: {
		id?:number,
	order?:number,
	poll_id?:number,
	votes_count?:number
};
	/** order by stddev_samp() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_stddev_samp_order_by"]: GraphQLTypes["voyce_polls_answers_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_polls_answers" */
["voyce_polls_answers_stream_cursor_input"]: GraphQLTypes["voyce_polls_answers_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_polls_answers_stream_cursor_value_input"]: GraphQLTypes["voyce_polls_answers_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_polls_answers_sum_fields"]: {
		id?:number,
	order?:number,
	poll_id?:number,
	votes_count?:number
};
	/** order by sum() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_sum_order_by"]: GraphQLTypes["voyce_polls_answers_sum_order_by"];
	/** update columns of table "voyce.polls_answers" */
["voyce_polls_answers_update_column"]: GraphQLTypes["voyce_polls_answers_update_column"];
	["voyce_polls_answers_updates"]: GraphQLTypes["voyce_polls_answers_updates"];
	/** aggregate var_pop on columns */
["voyce_polls_answers_var_pop_fields"]: {
		id?:number,
	order?:number,
	poll_id?:number,
	votes_count?:number
};
	/** order by var_pop() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_var_pop_order_by"]: GraphQLTypes["voyce_polls_answers_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_polls_answers_var_samp_fields"]: {
		id?:number,
	order?:number,
	poll_id?:number,
	votes_count?:number
};
	/** order by var_samp() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_var_samp_order_by"]: GraphQLTypes["voyce_polls_answers_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_polls_answers_variance_fields"]: {
		id?:number,
	order?:number,
	poll_id?:number,
	votes_count?:number
};
	/** order by variance() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_variance_order_by"]: GraphQLTypes["voyce_polls_answers_variance_order_by"];
	/** input type for inserting array relation for remote table "voyce.polls" */
["voyce_polls_arr_rel_insert_input"]: GraphQLTypes["voyce_polls_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_polls_avg_fields"]: {
		comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number,
	votes_count?:number
};
	/** order by avg() on columns of table "voyce.polls" */
["voyce_polls_avg_order_by"]: GraphQLTypes["voyce_polls_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.polls". All fields are combined with a logical 'AND'. */
["voyce_polls_bool_exp"]: GraphQLTypes["voyce_polls_bool_exp"];
	/** columns and relationships of "voyce.polls_comments" */
["voyce_polls_comments"]: {
		animated_image?:string,
	created_at:ModelTypes["timestamptz"],
	id:number,
	image?:string,
	linked_id:number,
	parent_id?:number,
	/** An object relationship */
	poll:ModelTypes["voyce_polls"],
	/** An object relationship */
	polls_comment?:ModelTypes["voyce_polls_comments"],
	/** An array relationship */
	polls_comments:ModelTypes["voyce_polls_comments"][],
	/** An aggregate relationship */
	polls_comments_aggregate:ModelTypes["voyce_polls_comments_aggregate"],
	/** An array relationship */
	polls_reactions:ModelTypes["voyce_polls_reactions"][],
	/** An aggregate relationship */
	polls_reactions_aggregate:ModelTypes["voyce_polls_reactions_aggregate"],
	reaction_count?:number,
	replies_count?:number,
	text:string,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.polls_comments" */
["voyce_polls_comments_aggregate"]: {
		aggregate?:ModelTypes["voyce_polls_comments_aggregate_fields"],
	nodes:ModelTypes["voyce_polls_comments"][]
};
	["voyce_polls_comments_aggregate_bool_exp"]: GraphQLTypes["voyce_polls_comments_aggregate_bool_exp"];
	["voyce_polls_comments_aggregate_bool_exp_count"]: GraphQLTypes["voyce_polls_comments_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.polls_comments" */
["voyce_polls_comments_aggregate_fields"]: {
		avg?:ModelTypes["voyce_polls_comments_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_polls_comments_max_fields"],
	min?:ModelTypes["voyce_polls_comments_min_fields"],
	stddev?:ModelTypes["voyce_polls_comments_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_polls_comments_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_polls_comments_stddev_samp_fields"],
	sum?:ModelTypes["voyce_polls_comments_sum_fields"],
	var_pop?:ModelTypes["voyce_polls_comments_var_pop_fields"],
	var_samp?:ModelTypes["voyce_polls_comments_var_samp_fields"],
	variance?:ModelTypes["voyce_polls_comments_variance_fields"]
};
	/** order by aggregate values of table "voyce.polls_comments" */
["voyce_polls_comments_aggregate_order_by"]: GraphQLTypes["voyce_polls_comments_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.polls_comments" */
["voyce_polls_comments_arr_rel_insert_input"]: GraphQLTypes["voyce_polls_comments_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_polls_comments_avg_fields"]: {
		id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_avg_order_by"]: GraphQLTypes["voyce_polls_comments_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.polls_comments". All fields are combined with a logical 'AND'. */
["voyce_polls_comments_bool_exp"]: GraphQLTypes["voyce_polls_comments_bool_exp"];
	/** unique or primary key constraints on table "voyce.polls_comments" */
["voyce_polls_comments_constraint"]: GraphQLTypes["voyce_polls_comments_constraint"];
	/** input type for incrementing numeric columns in table "voyce.polls_comments" */
["voyce_polls_comments_inc_input"]: GraphQLTypes["voyce_polls_comments_inc_input"];
	/** input type for inserting data into table "voyce.polls_comments" */
["voyce_polls_comments_insert_input"]: GraphQLTypes["voyce_polls_comments_insert_input"];
	/** aggregate max on columns */
["voyce_polls_comments_max_fields"]: {
		animated_image?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	image?:string,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	text?:string,
	user_id?:number
};
	/** order by max() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_max_order_by"]: GraphQLTypes["voyce_polls_comments_max_order_by"];
	/** aggregate min on columns */
["voyce_polls_comments_min_fields"]: {
		animated_image?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	image?:string,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	text?:string,
	user_id?:number
};
	/** order by min() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_min_order_by"]: GraphQLTypes["voyce_polls_comments_min_order_by"];
	/** response of any mutation on the table "voyce.polls_comments" */
["voyce_polls_comments_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_polls_comments"][]
};
	/** input type for inserting object relation for remote table "voyce.polls_comments" */
["voyce_polls_comments_obj_rel_insert_input"]: GraphQLTypes["voyce_polls_comments_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.polls_comments" */
["voyce_polls_comments_on_conflict"]: GraphQLTypes["voyce_polls_comments_on_conflict"];
	/** Ordering options when selecting data from "voyce.polls_comments". */
["voyce_polls_comments_order_by"]: GraphQLTypes["voyce_polls_comments_order_by"];
	/** primary key columns input for table: voyce.polls_comments */
["voyce_polls_comments_pk_columns_input"]: GraphQLTypes["voyce_polls_comments_pk_columns_input"];
	/** select columns of table "voyce.polls_comments" */
["voyce_polls_comments_select_column"]: GraphQLTypes["voyce_polls_comments_select_column"];
	/** input type for updating data in table "voyce.polls_comments" */
["voyce_polls_comments_set_input"]: GraphQLTypes["voyce_polls_comments_set_input"];
	/** aggregate stddev on columns */
["voyce_polls_comments_stddev_fields"]: {
		id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_stddev_order_by"]: GraphQLTypes["voyce_polls_comments_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_polls_comments_stddev_pop_fields"]: {
		id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_stddev_pop_order_by"]: GraphQLTypes["voyce_polls_comments_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_polls_comments_stddev_samp_fields"]: {
		id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_stddev_samp_order_by"]: GraphQLTypes["voyce_polls_comments_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_polls_comments" */
["voyce_polls_comments_stream_cursor_input"]: GraphQLTypes["voyce_polls_comments_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_polls_comments_stream_cursor_value_input"]: GraphQLTypes["voyce_polls_comments_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_polls_comments_sum_fields"]: {
		id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_sum_order_by"]: GraphQLTypes["voyce_polls_comments_sum_order_by"];
	/** update columns of table "voyce.polls_comments" */
["voyce_polls_comments_update_column"]: GraphQLTypes["voyce_polls_comments_update_column"];
	["voyce_polls_comments_updates"]: GraphQLTypes["voyce_polls_comments_updates"];
	/** aggregate var_pop on columns */
["voyce_polls_comments_var_pop_fields"]: {
		id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_var_pop_order_by"]: GraphQLTypes["voyce_polls_comments_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_polls_comments_var_samp_fields"]: {
		id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_var_samp_order_by"]: GraphQLTypes["voyce_polls_comments_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_polls_comments_variance_fields"]: {
		id?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_variance_order_by"]: GraphQLTypes["voyce_polls_comments_variance_order_by"];
	/** unique or primary key constraints on table "voyce.polls" */
["voyce_polls_constraint"]: GraphQLTypes["voyce_polls_constraint"];
	/** input type for incrementing numeric columns in table "voyce.polls" */
["voyce_polls_inc_input"]: GraphQLTypes["voyce_polls_inc_input"];
	/** input type for inserting data into table "voyce.polls" */
["voyce_polls_insert_input"]: GraphQLTypes["voyce_polls_insert_input"];
	/** aggregate max on columns */
["voyce_polls_max_fields"]: {
		comments_count?:number,
	created_at?:ModelTypes["timestamp"],
	end_date?:ModelTypes["timestamp"],
	id?:number,
	last_activity_date?:ModelTypes["timestamp"],
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	title?:string,
	total_popularity?:number,
	user_id?:number,
	votes_count?:number
};
	/** order by max() on columns of table "voyce.polls" */
["voyce_polls_max_order_by"]: GraphQLTypes["voyce_polls_max_order_by"];
	/** aggregate min on columns */
["voyce_polls_min_fields"]: {
		comments_count?:number,
	created_at?:ModelTypes["timestamp"],
	end_date?:ModelTypes["timestamp"],
	id?:number,
	last_activity_date?:ModelTypes["timestamp"],
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	title?:string,
	total_popularity?:number,
	user_id?:number,
	votes_count?:number
};
	/** order by min() on columns of table "voyce.polls" */
["voyce_polls_min_order_by"]: GraphQLTypes["voyce_polls_min_order_by"];
	/** response of any mutation on the table "voyce.polls" */
["voyce_polls_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_polls"][]
};
	/** input type for inserting object relation for remote table "voyce.polls" */
["voyce_polls_obj_rel_insert_input"]: GraphQLTypes["voyce_polls_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.polls" */
["voyce_polls_on_conflict"]: GraphQLTypes["voyce_polls_on_conflict"];
	/** Ordering options when selecting data from "voyce.polls". */
["voyce_polls_order_by"]: GraphQLTypes["voyce_polls_order_by"];
	/** primary key columns input for table: voyce.polls */
["voyce_polls_pk_columns_input"]: GraphQLTypes["voyce_polls_pk_columns_input"];
	/** columns and relationships of "voyce.polls_reactions" */
["voyce_polls_reactions"]: {
		comment_id?:number,
	created_at:ModelTypes["timestamptz"],
	id:number,
	linked_entity_id:number,
	/** An object relationship */
	poll:ModelTypes["voyce_polls"],
	/** An object relationship */
	polls_comment?:ModelTypes["voyce_polls_comments"],
	reaction:number,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.polls_reactions" */
["voyce_polls_reactions_aggregate"]: {
		aggregate?:ModelTypes["voyce_polls_reactions_aggregate_fields"],
	nodes:ModelTypes["voyce_polls_reactions"][]
};
	["voyce_polls_reactions_aggregate_bool_exp"]: GraphQLTypes["voyce_polls_reactions_aggregate_bool_exp"];
	["voyce_polls_reactions_aggregate_bool_exp_count"]: GraphQLTypes["voyce_polls_reactions_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.polls_reactions" */
["voyce_polls_reactions_aggregate_fields"]: {
		avg?:ModelTypes["voyce_polls_reactions_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_polls_reactions_max_fields"],
	min?:ModelTypes["voyce_polls_reactions_min_fields"],
	stddev?:ModelTypes["voyce_polls_reactions_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_polls_reactions_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_polls_reactions_stddev_samp_fields"],
	sum?:ModelTypes["voyce_polls_reactions_sum_fields"],
	var_pop?:ModelTypes["voyce_polls_reactions_var_pop_fields"],
	var_samp?:ModelTypes["voyce_polls_reactions_var_samp_fields"],
	variance?:ModelTypes["voyce_polls_reactions_variance_fields"]
};
	/** order by aggregate values of table "voyce.polls_reactions" */
["voyce_polls_reactions_aggregate_order_by"]: GraphQLTypes["voyce_polls_reactions_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.polls_reactions" */
["voyce_polls_reactions_arr_rel_insert_input"]: GraphQLTypes["voyce_polls_reactions_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_polls_reactions_avg_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_avg_order_by"]: GraphQLTypes["voyce_polls_reactions_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.polls_reactions". All fields are combined with a logical 'AND'. */
["voyce_polls_reactions_bool_exp"]: GraphQLTypes["voyce_polls_reactions_bool_exp"];
	/** unique or primary key constraints on table "voyce.polls_reactions" */
["voyce_polls_reactions_constraint"]: GraphQLTypes["voyce_polls_reactions_constraint"];
	/** input type for incrementing numeric columns in table "voyce.polls_reactions" */
["voyce_polls_reactions_inc_input"]: GraphQLTypes["voyce_polls_reactions_inc_input"];
	/** input type for inserting data into table "voyce.polls_reactions" */
["voyce_polls_reactions_insert_input"]: GraphQLTypes["voyce_polls_reactions_insert_input"];
	/** aggregate max on columns */
["voyce_polls_reactions_max_fields"]: {
		comment_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by max() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_max_order_by"]: GraphQLTypes["voyce_polls_reactions_max_order_by"];
	/** aggregate min on columns */
["voyce_polls_reactions_min_fields"]: {
		comment_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by min() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_min_order_by"]: GraphQLTypes["voyce_polls_reactions_min_order_by"];
	/** response of any mutation on the table "voyce.polls_reactions" */
["voyce_polls_reactions_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_polls_reactions"][]
};
	/** on_conflict condition type for table "voyce.polls_reactions" */
["voyce_polls_reactions_on_conflict"]: GraphQLTypes["voyce_polls_reactions_on_conflict"];
	/** Ordering options when selecting data from "voyce.polls_reactions". */
["voyce_polls_reactions_order_by"]: GraphQLTypes["voyce_polls_reactions_order_by"];
	/** primary key columns input for table: voyce.polls_reactions */
["voyce_polls_reactions_pk_columns_input"]: GraphQLTypes["voyce_polls_reactions_pk_columns_input"];
	/** select columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_select_column"]: GraphQLTypes["voyce_polls_reactions_select_column"];
	/** input type for updating data in table "voyce.polls_reactions" */
["voyce_polls_reactions_set_input"]: GraphQLTypes["voyce_polls_reactions_set_input"];
	/** aggregate stddev on columns */
["voyce_polls_reactions_stddev_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_stddev_order_by"]: GraphQLTypes["voyce_polls_reactions_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_polls_reactions_stddev_pop_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_stddev_pop_order_by"]: GraphQLTypes["voyce_polls_reactions_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_polls_reactions_stddev_samp_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_stddev_samp_order_by"]: GraphQLTypes["voyce_polls_reactions_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_polls_reactions" */
["voyce_polls_reactions_stream_cursor_input"]: GraphQLTypes["voyce_polls_reactions_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_polls_reactions_stream_cursor_value_input"]: GraphQLTypes["voyce_polls_reactions_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_polls_reactions_sum_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_sum_order_by"]: GraphQLTypes["voyce_polls_reactions_sum_order_by"];
	/** update columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_update_column"]: GraphQLTypes["voyce_polls_reactions_update_column"];
	["voyce_polls_reactions_updates"]: GraphQLTypes["voyce_polls_reactions_updates"];
	/** aggregate var_pop on columns */
["voyce_polls_reactions_var_pop_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_var_pop_order_by"]: GraphQLTypes["voyce_polls_reactions_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_polls_reactions_var_samp_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_var_samp_order_by"]: GraphQLTypes["voyce_polls_reactions_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_polls_reactions_variance_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_variance_order_by"]: GraphQLTypes["voyce_polls_reactions_variance_order_by"];
	/** select columns of table "voyce.polls" */
["voyce_polls_select_column"]: GraphQLTypes["voyce_polls_select_column"];
	/** select "voyce_polls_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.polls" */
["voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_and_arguments_columns"]: GraphQLTypes["voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_and_arguments_columns"];
	/** select "voyce_polls_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.polls" */
["voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_or_arguments_columns"]: GraphQLTypes["voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_or_arguments_columns"];
	/** input type for updating data in table "voyce.polls" */
["voyce_polls_set_input"]: GraphQLTypes["voyce_polls_set_input"];
	/** aggregate stddev on columns */
["voyce_polls_stddev_fields"]: {
		comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number,
	votes_count?:number
};
	/** order by stddev() on columns of table "voyce.polls" */
["voyce_polls_stddev_order_by"]: GraphQLTypes["voyce_polls_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_polls_stddev_pop_fields"]: {
		comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number,
	votes_count?:number
};
	/** order by stddev_pop() on columns of table "voyce.polls" */
["voyce_polls_stddev_pop_order_by"]: GraphQLTypes["voyce_polls_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_polls_stddev_samp_fields"]: {
		comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number,
	votes_count?:number
};
	/** order by stddev_samp() on columns of table "voyce.polls" */
["voyce_polls_stddev_samp_order_by"]: GraphQLTypes["voyce_polls_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_polls" */
["voyce_polls_stream_cursor_input"]: GraphQLTypes["voyce_polls_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_polls_stream_cursor_value_input"]: GraphQLTypes["voyce_polls_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_polls_sum_fields"]: {
		comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number,
	votes_count?:number
};
	/** order by sum() on columns of table "voyce.polls" */
["voyce_polls_sum_order_by"]: GraphQLTypes["voyce_polls_sum_order_by"];
	/** update columns of table "voyce.polls" */
["voyce_polls_update_column"]: GraphQLTypes["voyce_polls_update_column"];
	["voyce_polls_updates"]: GraphQLTypes["voyce_polls_updates"];
	/** aggregate var_pop on columns */
["voyce_polls_var_pop_fields"]: {
		comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number,
	votes_count?:number
};
	/** order by var_pop() on columns of table "voyce.polls" */
["voyce_polls_var_pop_order_by"]: GraphQLTypes["voyce_polls_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_polls_var_samp_fields"]: {
		comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number,
	votes_count?:number
};
	/** order by var_samp() on columns of table "voyce.polls" */
["voyce_polls_var_samp_order_by"]: GraphQLTypes["voyce_polls_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_polls_variance_fields"]: {
		comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number,
	votes_count?:number
};
	/** order by variance() on columns of table "voyce.polls" */
["voyce_polls_variance_order_by"]: GraphQLTypes["voyce_polls_variance_order_by"];
	/** columns and relationships of "voyce.polls_votes" */
["voyce_polls_votes"]: {
		answer_id:number,
	created_at:ModelTypes["timestamptz"],
	id:number,
	/** An object relationship */
	poll:ModelTypes["voyce_polls"],
	poll_id:number,
	/** An object relationship */
	polls_answer:ModelTypes["voyce_polls_answers"],
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.polls_votes" */
["voyce_polls_votes_aggregate"]: {
		aggregate?:ModelTypes["voyce_polls_votes_aggregate_fields"],
	nodes:ModelTypes["voyce_polls_votes"][]
};
	["voyce_polls_votes_aggregate_bool_exp"]: GraphQLTypes["voyce_polls_votes_aggregate_bool_exp"];
	["voyce_polls_votes_aggregate_bool_exp_count"]: GraphQLTypes["voyce_polls_votes_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.polls_votes" */
["voyce_polls_votes_aggregate_fields"]: {
		avg?:ModelTypes["voyce_polls_votes_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_polls_votes_max_fields"],
	min?:ModelTypes["voyce_polls_votes_min_fields"],
	stddev?:ModelTypes["voyce_polls_votes_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_polls_votes_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_polls_votes_stddev_samp_fields"],
	sum?:ModelTypes["voyce_polls_votes_sum_fields"],
	var_pop?:ModelTypes["voyce_polls_votes_var_pop_fields"],
	var_samp?:ModelTypes["voyce_polls_votes_var_samp_fields"],
	variance?:ModelTypes["voyce_polls_votes_variance_fields"]
};
	/** order by aggregate values of table "voyce.polls_votes" */
["voyce_polls_votes_aggregate_order_by"]: GraphQLTypes["voyce_polls_votes_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.polls_votes" */
["voyce_polls_votes_arr_rel_insert_input"]: GraphQLTypes["voyce_polls_votes_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_polls_votes_avg_fields"]: {
		answer_id?:number,
	id?:number,
	poll_id?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_avg_order_by"]: GraphQLTypes["voyce_polls_votes_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.polls_votes". All fields are combined with a logical 'AND'. */
["voyce_polls_votes_bool_exp"]: GraphQLTypes["voyce_polls_votes_bool_exp"];
	/** unique or primary key constraints on table "voyce.polls_votes" */
["voyce_polls_votes_constraint"]: GraphQLTypes["voyce_polls_votes_constraint"];
	/** input type for incrementing numeric columns in table "voyce.polls_votes" */
["voyce_polls_votes_inc_input"]: GraphQLTypes["voyce_polls_votes_inc_input"];
	/** input type for inserting data into table "voyce.polls_votes" */
["voyce_polls_votes_insert_input"]: GraphQLTypes["voyce_polls_votes_insert_input"];
	/** aggregate max on columns */
["voyce_polls_votes_max_fields"]: {
		answer_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	poll_id?:number,
	user_id?:number
};
	/** order by max() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_max_order_by"]: GraphQLTypes["voyce_polls_votes_max_order_by"];
	/** aggregate min on columns */
["voyce_polls_votes_min_fields"]: {
		answer_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	poll_id?:number,
	user_id?:number
};
	/** order by min() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_min_order_by"]: GraphQLTypes["voyce_polls_votes_min_order_by"];
	/** response of any mutation on the table "voyce.polls_votes" */
["voyce_polls_votes_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_polls_votes"][]
};
	/** on_conflict condition type for table "voyce.polls_votes" */
["voyce_polls_votes_on_conflict"]: GraphQLTypes["voyce_polls_votes_on_conflict"];
	/** Ordering options when selecting data from "voyce.polls_votes". */
["voyce_polls_votes_order_by"]: GraphQLTypes["voyce_polls_votes_order_by"];
	/** primary key columns input for table: voyce.polls_votes */
["voyce_polls_votes_pk_columns_input"]: GraphQLTypes["voyce_polls_votes_pk_columns_input"];
	/** select columns of table "voyce.polls_votes" */
["voyce_polls_votes_select_column"]: GraphQLTypes["voyce_polls_votes_select_column"];
	/** input type for updating data in table "voyce.polls_votes" */
["voyce_polls_votes_set_input"]: GraphQLTypes["voyce_polls_votes_set_input"];
	/** aggregate stddev on columns */
["voyce_polls_votes_stddev_fields"]: {
		answer_id?:number,
	id?:number,
	poll_id?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_stddev_order_by"]: GraphQLTypes["voyce_polls_votes_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_polls_votes_stddev_pop_fields"]: {
		answer_id?:number,
	id?:number,
	poll_id?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_stddev_pop_order_by"]: GraphQLTypes["voyce_polls_votes_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_polls_votes_stddev_samp_fields"]: {
		answer_id?:number,
	id?:number,
	poll_id?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_stddev_samp_order_by"]: GraphQLTypes["voyce_polls_votes_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_polls_votes" */
["voyce_polls_votes_stream_cursor_input"]: GraphQLTypes["voyce_polls_votes_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_polls_votes_stream_cursor_value_input"]: GraphQLTypes["voyce_polls_votes_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_polls_votes_sum_fields"]: {
		answer_id?:number,
	id?:number,
	poll_id?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_sum_order_by"]: GraphQLTypes["voyce_polls_votes_sum_order_by"];
	/** update columns of table "voyce.polls_votes" */
["voyce_polls_votes_update_column"]: GraphQLTypes["voyce_polls_votes_update_column"];
	["voyce_polls_votes_updates"]: GraphQLTypes["voyce_polls_votes_updates"];
	/** aggregate var_pop on columns */
["voyce_polls_votes_var_pop_fields"]: {
		answer_id?:number,
	id?:number,
	poll_id?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_var_pop_order_by"]: GraphQLTypes["voyce_polls_votes_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_polls_votes_var_samp_fields"]: {
		answer_id?:number,
	id?:number,
	poll_id?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_var_samp_order_by"]: GraphQLTypes["voyce_polls_votes_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_polls_votes_variance_fields"]: {
		answer_id?:number,
	id?:number,
	poll_id?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_variance_order_by"]: GraphQLTypes["voyce_polls_votes_variance_order_by"];
	/** columns and relationships of "voyce.popup_components" */
["voyce_popup_components"]: {
		auth?:boolean,
	component:string,
	created_at?:ModelTypes["timestamptz"],
	email_form:boolean,
	exclusive_series:boolean,
	followers?:boolean,
	id:number,
	is_active:boolean,
	klaviyo_list_id?:string,
	layout?:number,
	page:string,
	props?:ModelTypes["json"],
	series_visibility?:boolean,
	sort?:ModelTypes["smallint"],
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.popup_components" */
["voyce_popup_components_aggregate"]: {
		aggregate?:ModelTypes["voyce_popup_components_aggregate_fields"],
	nodes:ModelTypes["voyce_popup_components"][]
};
	/** aggregate fields of "voyce.popup_components" */
["voyce_popup_components_aggregate_fields"]: {
		avg?:ModelTypes["voyce_popup_components_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_popup_components_max_fields"],
	min?:ModelTypes["voyce_popup_components_min_fields"],
	stddev?:ModelTypes["voyce_popup_components_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_popup_components_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_popup_components_stddev_samp_fields"],
	sum?:ModelTypes["voyce_popup_components_sum_fields"],
	var_pop?:ModelTypes["voyce_popup_components_var_pop_fields"],
	var_samp?:ModelTypes["voyce_popup_components_var_samp_fields"],
	variance?:ModelTypes["voyce_popup_components_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_popup_components_avg_fields"]: {
		id?:number,
	layout?:number,
	sort?:number
};
	/** Boolean expression to filter rows from the table "voyce.popup_components". All fields are combined with a logical 'AND'. */
["voyce_popup_components_bool_exp"]: GraphQLTypes["voyce_popup_components_bool_exp"];
	/** unique or primary key constraints on table "voyce.popup_components" */
["voyce_popup_components_constraint"]: GraphQLTypes["voyce_popup_components_constraint"];
	/** input type for incrementing numeric columns in table "voyce.popup_components" */
["voyce_popup_components_inc_input"]: GraphQLTypes["voyce_popup_components_inc_input"];
	/** input type for inserting data into table "voyce.popup_components" */
["voyce_popup_components_insert_input"]: GraphQLTypes["voyce_popup_components_insert_input"];
	/** aggregate max on columns */
["voyce_popup_components_max_fields"]: {
		component?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	klaviyo_list_id?:string,
	layout?:number,
	page?:string,
	sort?:ModelTypes["smallint"],
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_popup_components_min_fields"]: {
		component?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	klaviyo_list_id?:string,
	layout?:number,
	page?:string,
	sort?:ModelTypes["smallint"],
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.popup_components" */
["voyce_popup_components_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_popup_components"][]
};
	/** input type for inserting object relation for remote table "voyce.popup_components" */
["voyce_popup_components_obj_rel_insert_input"]: GraphQLTypes["voyce_popup_components_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.popup_components" */
["voyce_popup_components_on_conflict"]: GraphQLTypes["voyce_popup_components_on_conflict"];
	/** Ordering options when selecting data from "voyce.popup_components". */
["voyce_popup_components_order_by"]: GraphQLTypes["voyce_popup_components_order_by"];
	/** primary key columns input for table: voyce.popup_components */
["voyce_popup_components_pk_columns_input"]: GraphQLTypes["voyce_popup_components_pk_columns_input"];
	/** select columns of table "voyce.popup_components" */
["voyce_popup_components_select_column"]: GraphQLTypes["voyce_popup_components_select_column"];
	/** input type for updating data in table "voyce.popup_components" */
["voyce_popup_components_set_input"]: GraphQLTypes["voyce_popup_components_set_input"];
	/** aggregate stddev on columns */
["voyce_popup_components_stddev_fields"]: {
		id?:number,
	layout?:number,
	sort?:number
};
	/** aggregate stddev_pop on columns */
["voyce_popup_components_stddev_pop_fields"]: {
		id?:number,
	layout?:number,
	sort?:number
};
	/** aggregate stddev_samp on columns */
["voyce_popup_components_stddev_samp_fields"]: {
		id?:number,
	layout?:number,
	sort?:number
};
	/** Streaming cursor of the table "voyce_popup_components" */
["voyce_popup_components_stream_cursor_input"]: GraphQLTypes["voyce_popup_components_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_popup_components_stream_cursor_value_input"]: GraphQLTypes["voyce_popup_components_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_popup_components_sum_fields"]: {
		id?:number,
	layout?:number,
	sort?:ModelTypes["smallint"]
};
	/** update columns of table "voyce.popup_components" */
["voyce_popup_components_update_column"]: GraphQLTypes["voyce_popup_components_update_column"];
	["voyce_popup_components_updates"]: GraphQLTypes["voyce_popup_components_updates"];
	/** aggregate var_pop on columns */
["voyce_popup_components_var_pop_fields"]: {
		id?:number,
	layout?:number,
	sort?:number
};
	/** aggregate var_samp on columns */
["voyce_popup_components_var_samp_fields"]: {
		id?:number,
	layout?:number,
	sort?:number
};
	/** aggregate variance on columns */
["voyce_popup_components_variance_fields"]: {
		id?:number,
	layout?:number,
	sort?:number
};
	/** columns and relationships of "voyce.popup_emails" */
["voyce_popup_emails"]: {
		/** An object relationship */
	component?:ModelTypes["voyce_popup_components"],
	created_at:ModelTypes["timestamptz"],
	email:string,
	gtag:string,
	/** An object relationship */
	guest_gtag?:ModelTypes["voyce_guest_gtags"],
	id:number,
	klaviyo_id?:string,
	page:string,
	popup_id:number,
	/** An object relationship */
	series?:ModelTypes["voyce_series"],
	series_id?:number,
	/** An object relationship */
	user?:ModelTypes["voyce_users"],
	user_id?:number
};
	/** aggregated selection of "voyce.popup_emails" */
["voyce_popup_emails_aggregate"]: {
		aggregate?:ModelTypes["voyce_popup_emails_aggregate_fields"],
	nodes:ModelTypes["voyce_popup_emails"][]
};
	["voyce_popup_emails_aggregate_bool_exp"]: GraphQLTypes["voyce_popup_emails_aggregate_bool_exp"];
	["voyce_popup_emails_aggregate_bool_exp_count"]: GraphQLTypes["voyce_popup_emails_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.popup_emails" */
["voyce_popup_emails_aggregate_fields"]: {
		avg?:ModelTypes["voyce_popup_emails_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_popup_emails_max_fields"],
	min?:ModelTypes["voyce_popup_emails_min_fields"],
	stddev?:ModelTypes["voyce_popup_emails_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_popup_emails_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_popup_emails_stddev_samp_fields"],
	sum?:ModelTypes["voyce_popup_emails_sum_fields"],
	var_pop?:ModelTypes["voyce_popup_emails_var_pop_fields"],
	var_samp?:ModelTypes["voyce_popup_emails_var_samp_fields"],
	variance?:ModelTypes["voyce_popup_emails_variance_fields"]
};
	/** order by aggregate values of table "voyce.popup_emails" */
["voyce_popup_emails_aggregate_order_by"]: GraphQLTypes["voyce_popup_emails_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.popup_emails" */
["voyce_popup_emails_arr_rel_insert_input"]: GraphQLTypes["voyce_popup_emails_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_popup_emails_avg_fields"]: {
		id?:number,
	popup_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_avg_order_by"]: GraphQLTypes["voyce_popup_emails_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.popup_emails". All fields are combined with a logical 'AND'. */
["voyce_popup_emails_bool_exp"]: GraphQLTypes["voyce_popup_emails_bool_exp"];
	/** unique or primary key constraints on table "voyce.popup_emails" */
["voyce_popup_emails_constraint"]: GraphQLTypes["voyce_popup_emails_constraint"];
	/** input type for incrementing numeric columns in table "voyce.popup_emails" */
["voyce_popup_emails_inc_input"]: GraphQLTypes["voyce_popup_emails_inc_input"];
	/** input type for inserting data into table "voyce.popup_emails" */
["voyce_popup_emails_insert_input"]: GraphQLTypes["voyce_popup_emails_insert_input"];
	/** aggregate max on columns */
["voyce_popup_emails_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	email?:string,
	gtag?:string,
	id?:number,
	klaviyo_id?:string,
	page?:string,
	popup_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by max() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_max_order_by"]: GraphQLTypes["voyce_popup_emails_max_order_by"];
	/** aggregate min on columns */
["voyce_popup_emails_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	email?:string,
	gtag?:string,
	id?:number,
	klaviyo_id?:string,
	page?:string,
	popup_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by min() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_min_order_by"]: GraphQLTypes["voyce_popup_emails_min_order_by"];
	/** response of any mutation on the table "voyce.popup_emails" */
["voyce_popup_emails_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_popup_emails"][]
};
	/** on_conflict condition type for table "voyce.popup_emails" */
["voyce_popup_emails_on_conflict"]: GraphQLTypes["voyce_popup_emails_on_conflict"];
	/** Ordering options when selecting data from "voyce.popup_emails". */
["voyce_popup_emails_order_by"]: GraphQLTypes["voyce_popup_emails_order_by"];
	/** primary key columns input for table: voyce.popup_emails */
["voyce_popup_emails_pk_columns_input"]: GraphQLTypes["voyce_popup_emails_pk_columns_input"];
	/** select columns of table "voyce.popup_emails" */
["voyce_popup_emails_select_column"]: GraphQLTypes["voyce_popup_emails_select_column"];
	/** input type for updating data in table "voyce.popup_emails" */
["voyce_popup_emails_set_input"]: GraphQLTypes["voyce_popup_emails_set_input"];
	/** aggregate stddev on columns */
["voyce_popup_emails_stddev_fields"]: {
		id?:number,
	popup_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_stddev_order_by"]: GraphQLTypes["voyce_popup_emails_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_popup_emails_stddev_pop_fields"]: {
		id?:number,
	popup_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_stddev_pop_order_by"]: GraphQLTypes["voyce_popup_emails_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_popup_emails_stddev_samp_fields"]: {
		id?:number,
	popup_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_stddev_samp_order_by"]: GraphQLTypes["voyce_popup_emails_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_popup_emails" */
["voyce_popup_emails_stream_cursor_input"]: GraphQLTypes["voyce_popup_emails_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_popup_emails_stream_cursor_value_input"]: GraphQLTypes["voyce_popup_emails_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_popup_emails_sum_fields"]: {
		id?:number,
	popup_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_sum_order_by"]: GraphQLTypes["voyce_popup_emails_sum_order_by"];
	/** update columns of table "voyce.popup_emails" */
["voyce_popup_emails_update_column"]: GraphQLTypes["voyce_popup_emails_update_column"];
	["voyce_popup_emails_updates"]: GraphQLTypes["voyce_popup_emails_updates"];
	/** aggregate var_pop on columns */
["voyce_popup_emails_var_pop_fields"]: {
		id?:number,
	popup_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_var_pop_order_by"]: GraphQLTypes["voyce_popup_emails_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_popup_emails_var_samp_fields"]: {
		id?:number,
	popup_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_var_samp_order_by"]: GraphQLTypes["voyce_popup_emails_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_popup_emails_variance_fields"]: {
		id?:number,
	popup_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_variance_order_by"]: GraphQLTypes["voyce_popup_emails_variance_order_by"];
	/** columns and relationships of "voyce.posts" */
["voyce_posts"]: {
		comments_count?:number,
	created_at:ModelTypes["timestamp"],
	giphy_url?:string,
	id:number,
	image_url?:string,
	last_activity_date:ModelTypes["timestamp"],
	likes_count?:number,
	outdated:boolean,
	popularity:number,
	popularity_weekly:number,
	/** An array relationship */
	posts_comments:ModelTypes["voyce_posts_comments"][],
	/** An aggregate relationship */
	posts_comments_aggregate:ModelTypes["voyce_posts_comments_aggregate"],
	/** An array relationship */
	posts_reactions:ModelTypes["voyce_posts_reactions"][],
	/** An aggregate relationship */
	posts_reactions_aggregate:ModelTypes["voyce_posts_reactions_aggregate"],
	reactions_count?:number,
	spoiler:boolean,
	text?:string,
	total_popularity:number,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number,
	video_url?:string
};
	/** aggregated selection of "voyce.posts" */
["voyce_posts_aggregate"]: {
		aggregate?:ModelTypes["voyce_posts_aggregate_fields"],
	nodes:ModelTypes["voyce_posts"][]
};
	["voyce_posts_aggregate_bool_exp"]: GraphQLTypes["voyce_posts_aggregate_bool_exp"];
	["voyce_posts_aggregate_bool_exp_bool_and"]: GraphQLTypes["voyce_posts_aggregate_bool_exp_bool_and"];
	["voyce_posts_aggregate_bool_exp_bool_or"]: GraphQLTypes["voyce_posts_aggregate_bool_exp_bool_or"];
	["voyce_posts_aggregate_bool_exp_count"]: GraphQLTypes["voyce_posts_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.posts" */
["voyce_posts_aggregate_fields"]: {
		avg?:ModelTypes["voyce_posts_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_posts_max_fields"],
	min?:ModelTypes["voyce_posts_min_fields"],
	stddev?:ModelTypes["voyce_posts_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_posts_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_posts_stddev_samp_fields"],
	sum?:ModelTypes["voyce_posts_sum_fields"],
	var_pop?:ModelTypes["voyce_posts_var_pop_fields"],
	var_samp?:ModelTypes["voyce_posts_var_samp_fields"],
	variance?:ModelTypes["voyce_posts_variance_fields"]
};
	/** order by aggregate values of table "voyce.posts" */
["voyce_posts_aggregate_order_by"]: GraphQLTypes["voyce_posts_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.posts" */
["voyce_posts_arr_rel_insert_input"]: GraphQLTypes["voyce_posts_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_posts_avg_fields"]: {
		comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.posts" */
["voyce_posts_avg_order_by"]: GraphQLTypes["voyce_posts_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.posts". All fields are combined with a logical 'AND'. */
["voyce_posts_bool_exp"]: GraphQLTypes["voyce_posts_bool_exp"];
	/** columns and relationships of "voyce.posts_comments" */
["voyce_posts_comments"]: {
		animated_image?:string,
	created_at:ModelTypes["timestamptz"],
	id:number,
	image?:string,
	last_reacted_at?:ModelTypes["timestamptz"],
	last_reaction_count?:number,
	linked_id:number,
	parent_id?:number,
	/** An object relationship */
	post:ModelTypes["voyce_posts"],
	/** An object relationship */
	posts_comment?:ModelTypes["voyce_posts_comments"],
	/** An array relationship */
	posts_comments:ModelTypes["voyce_posts_comments"][],
	/** An aggregate relationship */
	posts_comments_aggregate:ModelTypes["voyce_posts_comments_aggregate"],
	/** An array relationship */
	posts_reactions:ModelTypes["voyce_posts_reactions"][],
	/** An aggregate relationship */
	posts_reactions_aggregate:ModelTypes["voyce_posts_reactions_aggregate"],
	reaction_count?:number,
	replies_count?:number,
	text:string,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.posts_comments" */
["voyce_posts_comments_aggregate"]: {
		aggregate?:ModelTypes["voyce_posts_comments_aggregate_fields"],
	nodes:ModelTypes["voyce_posts_comments"][]
};
	["voyce_posts_comments_aggregate_bool_exp"]: GraphQLTypes["voyce_posts_comments_aggregate_bool_exp"];
	["voyce_posts_comments_aggregate_bool_exp_count"]: GraphQLTypes["voyce_posts_comments_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.posts_comments" */
["voyce_posts_comments_aggregate_fields"]: {
		avg?:ModelTypes["voyce_posts_comments_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_posts_comments_max_fields"],
	min?:ModelTypes["voyce_posts_comments_min_fields"],
	stddev?:ModelTypes["voyce_posts_comments_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_posts_comments_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_posts_comments_stddev_samp_fields"],
	sum?:ModelTypes["voyce_posts_comments_sum_fields"],
	var_pop?:ModelTypes["voyce_posts_comments_var_pop_fields"],
	var_samp?:ModelTypes["voyce_posts_comments_var_samp_fields"],
	variance?:ModelTypes["voyce_posts_comments_variance_fields"]
};
	/** order by aggregate values of table "voyce.posts_comments" */
["voyce_posts_comments_aggregate_order_by"]: GraphQLTypes["voyce_posts_comments_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.posts_comments" */
["voyce_posts_comments_arr_rel_insert_input"]: GraphQLTypes["voyce_posts_comments_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_posts_comments_avg_fields"]: {
		id?:number,
	last_reaction_count?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_avg_order_by"]: GraphQLTypes["voyce_posts_comments_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.posts_comments". All fields are combined with a logical 'AND'. */
["voyce_posts_comments_bool_exp"]: GraphQLTypes["voyce_posts_comments_bool_exp"];
	/** unique or primary key constraints on table "voyce.posts_comments" */
["voyce_posts_comments_constraint"]: GraphQLTypes["voyce_posts_comments_constraint"];
	/** input type for incrementing numeric columns in table "voyce.posts_comments" */
["voyce_posts_comments_inc_input"]: GraphQLTypes["voyce_posts_comments_inc_input"];
	/** input type for inserting data into table "voyce.posts_comments" */
["voyce_posts_comments_insert_input"]: GraphQLTypes["voyce_posts_comments_insert_input"];
	/** aggregate max on columns */
["voyce_posts_comments_max_fields"]: {
		animated_image?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	image?:string,
	last_reacted_at?:ModelTypes["timestamptz"],
	last_reaction_count?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	text?:string,
	user_id?:number
};
	/** order by max() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_max_order_by"]: GraphQLTypes["voyce_posts_comments_max_order_by"];
	/** aggregate min on columns */
["voyce_posts_comments_min_fields"]: {
		animated_image?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	image?:string,
	last_reacted_at?:ModelTypes["timestamptz"],
	last_reaction_count?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	text?:string,
	user_id?:number
};
	/** order by min() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_min_order_by"]: GraphQLTypes["voyce_posts_comments_min_order_by"];
	/** response of any mutation on the table "voyce.posts_comments" */
["voyce_posts_comments_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_posts_comments"][]
};
	/** input type for inserting object relation for remote table "voyce.posts_comments" */
["voyce_posts_comments_obj_rel_insert_input"]: GraphQLTypes["voyce_posts_comments_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.posts_comments" */
["voyce_posts_comments_on_conflict"]: GraphQLTypes["voyce_posts_comments_on_conflict"];
	/** Ordering options when selecting data from "voyce.posts_comments". */
["voyce_posts_comments_order_by"]: GraphQLTypes["voyce_posts_comments_order_by"];
	/** primary key columns input for table: voyce.posts_comments */
["voyce_posts_comments_pk_columns_input"]: GraphQLTypes["voyce_posts_comments_pk_columns_input"];
	/** select columns of table "voyce.posts_comments" */
["voyce_posts_comments_select_column"]: GraphQLTypes["voyce_posts_comments_select_column"];
	/** input type for updating data in table "voyce.posts_comments" */
["voyce_posts_comments_set_input"]: GraphQLTypes["voyce_posts_comments_set_input"];
	/** aggregate stddev on columns */
["voyce_posts_comments_stddev_fields"]: {
		id?:number,
	last_reaction_count?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_stddev_order_by"]: GraphQLTypes["voyce_posts_comments_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_posts_comments_stddev_pop_fields"]: {
		id?:number,
	last_reaction_count?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_stddev_pop_order_by"]: GraphQLTypes["voyce_posts_comments_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_posts_comments_stddev_samp_fields"]: {
		id?:number,
	last_reaction_count?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_stddev_samp_order_by"]: GraphQLTypes["voyce_posts_comments_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_posts_comments" */
["voyce_posts_comments_stream_cursor_input"]: GraphQLTypes["voyce_posts_comments_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_posts_comments_stream_cursor_value_input"]: GraphQLTypes["voyce_posts_comments_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_posts_comments_sum_fields"]: {
		id?:number,
	last_reaction_count?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_sum_order_by"]: GraphQLTypes["voyce_posts_comments_sum_order_by"];
	/** update columns of table "voyce.posts_comments" */
["voyce_posts_comments_update_column"]: GraphQLTypes["voyce_posts_comments_update_column"];
	["voyce_posts_comments_updates"]: GraphQLTypes["voyce_posts_comments_updates"];
	/** aggregate var_pop on columns */
["voyce_posts_comments_var_pop_fields"]: {
		id?:number,
	last_reaction_count?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_var_pop_order_by"]: GraphQLTypes["voyce_posts_comments_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_posts_comments_var_samp_fields"]: {
		id?:number,
	last_reaction_count?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_var_samp_order_by"]: GraphQLTypes["voyce_posts_comments_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_posts_comments_variance_fields"]: {
		id?:number,
	last_reaction_count?:number,
	linked_id?:number,
	parent_id?:number,
	reaction_count?:number,
	replies_count?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_variance_order_by"]: GraphQLTypes["voyce_posts_comments_variance_order_by"];
	/** unique or primary key constraints on table "voyce.posts" */
["voyce_posts_constraint"]: GraphQLTypes["voyce_posts_constraint"];
	/** input type for incrementing numeric columns in table "voyce.posts" */
["voyce_posts_inc_input"]: GraphQLTypes["voyce_posts_inc_input"];
	/** input type for inserting data into table "voyce.posts" */
["voyce_posts_insert_input"]: GraphQLTypes["voyce_posts_insert_input"];
	/** aggregate max on columns */
["voyce_posts_max_fields"]: {
		comments_count?:number,
	created_at?:ModelTypes["timestamp"],
	giphy_url?:string,
	id?:number,
	image_url?:string,
	last_activity_date?:ModelTypes["timestamp"],
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	text?:string,
	total_popularity?:number,
	user_id?:number,
	video_url?:string
};
	/** order by max() on columns of table "voyce.posts" */
["voyce_posts_max_order_by"]: GraphQLTypes["voyce_posts_max_order_by"];
	/** aggregate min on columns */
["voyce_posts_min_fields"]: {
		comments_count?:number,
	created_at?:ModelTypes["timestamp"],
	giphy_url?:string,
	id?:number,
	image_url?:string,
	last_activity_date?:ModelTypes["timestamp"],
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	text?:string,
	total_popularity?:number,
	user_id?:number,
	video_url?:string
};
	/** order by min() on columns of table "voyce.posts" */
["voyce_posts_min_order_by"]: GraphQLTypes["voyce_posts_min_order_by"];
	/** response of any mutation on the table "voyce.posts" */
["voyce_posts_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_posts"][]
};
	/** input type for inserting object relation for remote table "voyce.posts" */
["voyce_posts_obj_rel_insert_input"]: GraphQLTypes["voyce_posts_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.posts" */
["voyce_posts_on_conflict"]: GraphQLTypes["voyce_posts_on_conflict"];
	/** Ordering options when selecting data from "voyce.posts". */
["voyce_posts_order_by"]: GraphQLTypes["voyce_posts_order_by"];
	/** primary key columns input for table: voyce.posts */
["voyce_posts_pk_columns_input"]: GraphQLTypes["voyce_posts_pk_columns_input"];
	/** columns and relationships of "voyce.posts_reactions" */
["voyce_posts_reactions"]: {
		comment_id?:number,
	created_at:ModelTypes["timestamptz"],
	id:number,
	linked_entity_id:number,
	/** An object relationship */
	post:ModelTypes["voyce_posts"],
	/** An object relationship */
	posts_comment?:ModelTypes["voyce_posts_comments"],
	reaction:number,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.posts_reactions" */
["voyce_posts_reactions_aggregate"]: {
		aggregate?:ModelTypes["voyce_posts_reactions_aggregate_fields"],
	nodes:ModelTypes["voyce_posts_reactions"][]
};
	["voyce_posts_reactions_aggregate_bool_exp"]: GraphQLTypes["voyce_posts_reactions_aggregate_bool_exp"];
	["voyce_posts_reactions_aggregate_bool_exp_count"]: GraphQLTypes["voyce_posts_reactions_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.posts_reactions" */
["voyce_posts_reactions_aggregate_fields"]: {
		avg?:ModelTypes["voyce_posts_reactions_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_posts_reactions_max_fields"],
	min?:ModelTypes["voyce_posts_reactions_min_fields"],
	stddev?:ModelTypes["voyce_posts_reactions_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_posts_reactions_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_posts_reactions_stddev_samp_fields"],
	sum?:ModelTypes["voyce_posts_reactions_sum_fields"],
	var_pop?:ModelTypes["voyce_posts_reactions_var_pop_fields"],
	var_samp?:ModelTypes["voyce_posts_reactions_var_samp_fields"],
	variance?:ModelTypes["voyce_posts_reactions_variance_fields"]
};
	/** order by aggregate values of table "voyce.posts_reactions" */
["voyce_posts_reactions_aggregate_order_by"]: GraphQLTypes["voyce_posts_reactions_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.posts_reactions" */
["voyce_posts_reactions_arr_rel_insert_input"]: GraphQLTypes["voyce_posts_reactions_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_posts_reactions_avg_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_avg_order_by"]: GraphQLTypes["voyce_posts_reactions_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.posts_reactions". All fields are combined with a logical 'AND'. */
["voyce_posts_reactions_bool_exp"]: GraphQLTypes["voyce_posts_reactions_bool_exp"];
	/** unique or primary key constraints on table "voyce.posts_reactions" */
["voyce_posts_reactions_constraint"]: GraphQLTypes["voyce_posts_reactions_constraint"];
	/** input type for incrementing numeric columns in table "voyce.posts_reactions" */
["voyce_posts_reactions_inc_input"]: GraphQLTypes["voyce_posts_reactions_inc_input"];
	/** input type for inserting data into table "voyce.posts_reactions" */
["voyce_posts_reactions_insert_input"]: GraphQLTypes["voyce_posts_reactions_insert_input"];
	/** aggregate max on columns */
["voyce_posts_reactions_max_fields"]: {
		comment_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by max() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_max_order_by"]: GraphQLTypes["voyce_posts_reactions_max_order_by"];
	/** aggregate min on columns */
["voyce_posts_reactions_min_fields"]: {
		comment_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by min() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_min_order_by"]: GraphQLTypes["voyce_posts_reactions_min_order_by"];
	/** response of any mutation on the table "voyce.posts_reactions" */
["voyce_posts_reactions_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_posts_reactions"][]
};
	/** on_conflict condition type for table "voyce.posts_reactions" */
["voyce_posts_reactions_on_conflict"]: GraphQLTypes["voyce_posts_reactions_on_conflict"];
	/** Ordering options when selecting data from "voyce.posts_reactions". */
["voyce_posts_reactions_order_by"]: GraphQLTypes["voyce_posts_reactions_order_by"];
	/** primary key columns input for table: voyce.posts_reactions */
["voyce_posts_reactions_pk_columns_input"]: GraphQLTypes["voyce_posts_reactions_pk_columns_input"];
	/** select columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_select_column"]: GraphQLTypes["voyce_posts_reactions_select_column"];
	/** input type for updating data in table "voyce.posts_reactions" */
["voyce_posts_reactions_set_input"]: GraphQLTypes["voyce_posts_reactions_set_input"];
	/** aggregate stddev on columns */
["voyce_posts_reactions_stddev_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_stddev_order_by"]: GraphQLTypes["voyce_posts_reactions_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_posts_reactions_stddev_pop_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_stddev_pop_order_by"]: GraphQLTypes["voyce_posts_reactions_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_posts_reactions_stddev_samp_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_stddev_samp_order_by"]: GraphQLTypes["voyce_posts_reactions_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_posts_reactions" */
["voyce_posts_reactions_stream_cursor_input"]: GraphQLTypes["voyce_posts_reactions_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_posts_reactions_stream_cursor_value_input"]: GraphQLTypes["voyce_posts_reactions_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_posts_reactions_sum_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_sum_order_by"]: GraphQLTypes["voyce_posts_reactions_sum_order_by"];
	/** update columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_update_column"]: GraphQLTypes["voyce_posts_reactions_update_column"];
	["voyce_posts_reactions_updates"]: GraphQLTypes["voyce_posts_reactions_updates"];
	/** aggregate var_pop on columns */
["voyce_posts_reactions_var_pop_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_var_pop_order_by"]: GraphQLTypes["voyce_posts_reactions_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_posts_reactions_var_samp_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_var_samp_order_by"]: GraphQLTypes["voyce_posts_reactions_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_posts_reactions_variance_fields"]: {
		comment_id?:number,
	id?:number,
	linked_entity_id?:number,
	reaction?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_variance_order_by"]: GraphQLTypes["voyce_posts_reactions_variance_order_by"];
	/** select columns of table "voyce.posts" */
["voyce_posts_select_column"]: GraphQLTypes["voyce_posts_select_column"];
	/** select "voyce_posts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.posts" */
["voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_and_arguments_columns"]: GraphQLTypes["voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_and_arguments_columns"];
	/** select "voyce_posts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.posts" */
["voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_or_arguments_columns"]: GraphQLTypes["voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_or_arguments_columns"];
	/** input type for updating data in table "voyce.posts" */
["voyce_posts_set_input"]: GraphQLTypes["voyce_posts_set_input"];
	/** aggregate stddev on columns */
["voyce_posts_stddev_fields"]: {
		comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.posts" */
["voyce_posts_stddev_order_by"]: GraphQLTypes["voyce_posts_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_posts_stddev_pop_fields"]: {
		comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.posts" */
["voyce_posts_stddev_pop_order_by"]: GraphQLTypes["voyce_posts_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_posts_stddev_samp_fields"]: {
		comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.posts" */
["voyce_posts_stddev_samp_order_by"]: GraphQLTypes["voyce_posts_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_posts" */
["voyce_posts_stream_cursor_input"]: GraphQLTypes["voyce_posts_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_posts_stream_cursor_value_input"]: GraphQLTypes["voyce_posts_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_posts_sum_fields"]: {
		comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.posts" */
["voyce_posts_sum_order_by"]: GraphQLTypes["voyce_posts_sum_order_by"];
	/** update columns of table "voyce.posts" */
["voyce_posts_update_column"]: GraphQLTypes["voyce_posts_update_column"];
	["voyce_posts_updates"]: GraphQLTypes["voyce_posts_updates"];
	/** aggregate var_pop on columns */
["voyce_posts_var_pop_fields"]: {
		comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.posts" */
["voyce_posts_var_pop_order_by"]: GraphQLTypes["voyce_posts_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_posts_var_samp_fields"]: {
		comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.posts" */
["voyce_posts_var_samp_order_by"]: GraphQLTypes["voyce_posts_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_posts_variance_fields"]: {
		comments_count?:number,
	id?:number,
	likes_count?:number,
	popularity?:number,
	popularity_weekly?:number,
	reactions_count?:number,
	total_popularity?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.posts" */
["voyce_posts_variance_order_by"]: GraphQLTypes["voyce_posts_variance_order_by"];
	/** columns and relationships of "voyce.quest_difficulties" */
["voyce_quest_difficulties"]: {
		coins:number,
	created_at:ModelTypes["timestamptz"],
	experience:number,
	id:number,
	title:string,
	updated_at:ModelTypes["timestamptz"],
	vox:number
};
	/** aggregated selection of "voyce.quest_difficulties" */
["voyce_quest_difficulties_aggregate"]: {
		aggregate?:ModelTypes["voyce_quest_difficulties_aggregate_fields"],
	nodes:ModelTypes["voyce_quest_difficulties"][]
};
	/** aggregate fields of "voyce.quest_difficulties" */
["voyce_quest_difficulties_aggregate_fields"]: {
		avg?:ModelTypes["voyce_quest_difficulties_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_quest_difficulties_max_fields"],
	min?:ModelTypes["voyce_quest_difficulties_min_fields"],
	stddev?:ModelTypes["voyce_quest_difficulties_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_quest_difficulties_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_quest_difficulties_stddev_samp_fields"],
	sum?:ModelTypes["voyce_quest_difficulties_sum_fields"],
	var_pop?:ModelTypes["voyce_quest_difficulties_var_pop_fields"],
	var_samp?:ModelTypes["voyce_quest_difficulties_var_samp_fields"],
	variance?:ModelTypes["voyce_quest_difficulties_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_quest_difficulties_avg_fields"]: {
		coins?:number,
	experience?:number,
	id?:number,
	vox?:number
};
	/** Boolean expression to filter rows from the table "voyce.quest_difficulties". All fields are combined with a logical 'AND'. */
["voyce_quest_difficulties_bool_exp"]: GraphQLTypes["voyce_quest_difficulties_bool_exp"];
	/** unique or primary key constraints on table "voyce.quest_difficulties" */
["voyce_quest_difficulties_constraint"]: GraphQLTypes["voyce_quest_difficulties_constraint"];
	/** input type for incrementing numeric columns in table "voyce.quest_difficulties" */
["voyce_quest_difficulties_inc_input"]: GraphQLTypes["voyce_quest_difficulties_inc_input"];
	/** input type for inserting data into table "voyce.quest_difficulties" */
["voyce_quest_difficulties_insert_input"]: GraphQLTypes["voyce_quest_difficulties_insert_input"];
	/** aggregate max on columns */
["voyce_quest_difficulties_max_fields"]: {
		coins?:number,
	created_at?:ModelTypes["timestamptz"],
	experience?:number,
	id?:number,
	title?:string,
	updated_at?:ModelTypes["timestamptz"],
	vox?:number
};
	/** aggregate min on columns */
["voyce_quest_difficulties_min_fields"]: {
		coins?:number,
	created_at?:ModelTypes["timestamptz"],
	experience?:number,
	id?:number,
	title?:string,
	updated_at?:ModelTypes["timestamptz"],
	vox?:number
};
	/** response of any mutation on the table "voyce.quest_difficulties" */
["voyce_quest_difficulties_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_quest_difficulties"][]
};
	/** input type for inserting object relation for remote table "voyce.quest_difficulties" */
["voyce_quest_difficulties_obj_rel_insert_input"]: GraphQLTypes["voyce_quest_difficulties_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.quest_difficulties" */
["voyce_quest_difficulties_on_conflict"]: GraphQLTypes["voyce_quest_difficulties_on_conflict"];
	/** Ordering options when selecting data from "voyce.quest_difficulties". */
["voyce_quest_difficulties_order_by"]: GraphQLTypes["voyce_quest_difficulties_order_by"];
	/** primary key columns input for table: voyce.quest_difficulties */
["voyce_quest_difficulties_pk_columns_input"]: GraphQLTypes["voyce_quest_difficulties_pk_columns_input"];
	/** select columns of table "voyce.quest_difficulties" */
["voyce_quest_difficulties_select_column"]: GraphQLTypes["voyce_quest_difficulties_select_column"];
	/** input type for updating data in table "voyce.quest_difficulties" */
["voyce_quest_difficulties_set_input"]: GraphQLTypes["voyce_quest_difficulties_set_input"];
	/** aggregate stddev on columns */
["voyce_quest_difficulties_stddev_fields"]: {
		coins?:number,
	experience?:number,
	id?:number,
	vox?:number
};
	/** aggregate stddev_pop on columns */
["voyce_quest_difficulties_stddev_pop_fields"]: {
		coins?:number,
	experience?:number,
	id?:number,
	vox?:number
};
	/** aggregate stddev_samp on columns */
["voyce_quest_difficulties_stddev_samp_fields"]: {
		coins?:number,
	experience?:number,
	id?:number,
	vox?:number
};
	/** Streaming cursor of the table "voyce_quest_difficulties" */
["voyce_quest_difficulties_stream_cursor_input"]: GraphQLTypes["voyce_quest_difficulties_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_quest_difficulties_stream_cursor_value_input"]: GraphQLTypes["voyce_quest_difficulties_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_quest_difficulties_sum_fields"]: {
		coins?:number,
	experience?:number,
	id?:number,
	vox?:number
};
	/** update columns of table "voyce.quest_difficulties" */
["voyce_quest_difficulties_update_column"]: GraphQLTypes["voyce_quest_difficulties_update_column"];
	["voyce_quest_difficulties_updates"]: GraphQLTypes["voyce_quest_difficulties_updates"];
	/** aggregate var_pop on columns */
["voyce_quest_difficulties_var_pop_fields"]: {
		coins?:number,
	experience?:number,
	id?:number,
	vox?:number
};
	/** aggregate var_samp on columns */
["voyce_quest_difficulties_var_samp_fields"]: {
		coins?:number,
	experience?:number,
	id?:number,
	vox?:number
};
	/** aggregate variance on columns */
["voyce_quest_difficulties_variance_fields"]: {
		coins?:number,
	experience?:number,
	id?:number,
	vox?:number
};
	/** columns and relationships of "voyce.quests" */
["voyce_quests"]: {
		created_at:ModelTypes["timestamptz"],
	description?:string,
	/** An object relationship */
	difficulty?:ModelTypes["voyce_quest_difficulties"],
	difficulty_id:number,
	/** e.g: { chapters_viewed: 3, chapter_completion: 0.5 } */
	event_data?:ModelTypes["json"],
	/** SubscribeSeries, DailyCheckIn, etc.. */
	event_trigger:string,
	experience_points?:number,
	/** once, daily, weekly */
	frequency:string,
	id:number,
	/** Qualified, Started, Completed */
	initial_status:string,
	notif_message?:string,
	/** e.g: { type: coin, value: 100 } */
	reward?:ModelTypes["json"],
	/** e.g. { page: shop, is_rewarded: false } */
	start_data?:ModelTypes["json"],
	/** auto, manual */
	start_type:string,
	tag:string,
	title:string,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.quests" */
["voyce_quests_aggregate"]: {
		aggregate?:ModelTypes["voyce_quests_aggregate_fields"],
	nodes:ModelTypes["voyce_quests"][]
};
	/** aggregate fields of "voyce.quests" */
["voyce_quests_aggregate_fields"]: {
		avg?:ModelTypes["voyce_quests_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_quests_max_fields"],
	min?:ModelTypes["voyce_quests_min_fields"],
	stddev?:ModelTypes["voyce_quests_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_quests_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_quests_stddev_samp_fields"],
	sum?:ModelTypes["voyce_quests_sum_fields"],
	var_pop?:ModelTypes["voyce_quests_var_pop_fields"],
	var_samp?:ModelTypes["voyce_quests_var_samp_fields"],
	variance?:ModelTypes["voyce_quests_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_quests_avg_fields"]: {
		difficulty_id?:number,
	experience_points?:number,
	id?:number
};
	/** Boolean expression to filter rows from the table "voyce.quests". All fields are combined with a logical 'AND'. */
["voyce_quests_bool_exp"]: GraphQLTypes["voyce_quests_bool_exp"];
	/** unique or primary key constraints on table "voyce.quests" */
["voyce_quests_constraint"]: GraphQLTypes["voyce_quests_constraint"];
	/** input type for incrementing numeric columns in table "voyce.quests" */
["voyce_quests_inc_input"]: GraphQLTypes["voyce_quests_inc_input"];
	/** input type for inserting data into table "voyce.quests" */
["voyce_quests_insert_input"]: GraphQLTypes["voyce_quests_insert_input"];
	/** aggregate max on columns */
["voyce_quests_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	description?:string,
	difficulty_id?:number,
	/** SubscribeSeries, DailyCheckIn, etc.. */
	event_trigger?:string,
	experience_points?:number,
	/** once, daily, weekly */
	frequency?:string,
	id?:number,
	/** Qualified, Started, Completed */
	initial_status?:string,
	notif_message?:string,
	/** auto, manual */
	start_type?:string,
	tag?:string,
	title?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_quests_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	description?:string,
	difficulty_id?:number,
	/** SubscribeSeries, DailyCheckIn, etc.. */
	event_trigger?:string,
	experience_points?:number,
	/** once, daily, weekly */
	frequency?:string,
	id?:number,
	/** Qualified, Started, Completed */
	initial_status?:string,
	notif_message?:string,
	/** auto, manual */
	start_type?:string,
	tag?:string,
	title?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.quests" */
["voyce_quests_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_quests"][]
};
	/** input type for inserting object relation for remote table "voyce.quests" */
["voyce_quests_obj_rel_insert_input"]: GraphQLTypes["voyce_quests_obj_rel_insert_input"];
	/** columns and relationships of "voyce.quests_old" */
["voyce_quests_old"]: {
		end_date?:ModelTypes["timestamp"],
	id:number,
	image?:string,
	recurrency?:number,
	recurrent?:boolean,
	/** An array relationship */
	rewards_quests:ModelTypes["voyce_rewards_quest"][],
	/** An aggregate relationship */
	rewards_quests_aggregate:ModelTypes["voyce_rewards_quest_aggregate"],
	start_date:ModelTypes["timestamp"],
	steps?:number,
	title?:string,
	/** An array relationship */
	user_quests:ModelTypes["voyce_user_quests_old"][],
	/** An aggregate relationship */
	user_quests_aggregate:ModelTypes["voyce_user_quests_old_aggregate"]
};
	/** aggregated selection of "voyce.quests_old" */
["voyce_quests_old_aggregate"]: {
		aggregate?:ModelTypes["voyce_quests_old_aggregate_fields"],
	nodes:ModelTypes["voyce_quests_old"][]
};
	/** aggregate fields of "voyce.quests_old" */
["voyce_quests_old_aggregate_fields"]: {
		avg?:ModelTypes["voyce_quests_old_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_quests_old_max_fields"],
	min?:ModelTypes["voyce_quests_old_min_fields"],
	stddev?:ModelTypes["voyce_quests_old_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_quests_old_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_quests_old_stddev_samp_fields"],
	sum?:ModelTypes["voyce_quests_old_sum_fields"],
	var_pop?:ModelTypes["voyce_quests_old_var_pop_fields"],
	var_samp?:ModelTypes["voyce_quests_old_var_samp_fields"],
	variance?:ModelTypes["voyce_quests_old_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_quests_old_avg_fields"]: {
		id?:number,
	recurrency?:number,
	steps?:number
};
	/** Boolean expression to filter rows from the table "voyce.quests_old". All fields are combined with a logical 'AND'. */
["voyce_quests_old_bool_exp"]: GraphQLTypes["voyce_quests_old_bool_exp"];
	/** unique or primary key constraints on table "voyce.quests_old" */
["voyce_quests_old_constraint"]: GraphQLTypes["voyce_quests_old_constraint"];
	/** input type for incrementing numeric columns in table "voyce.quests_old" */
["voyce_quests_old_inc_input"]: GraphQLTypes["voyce_quests_old_inc_input"];
	/** input type for inserting data into table "voyce.quests_old" */
["voyce_quests_old_insert_input"]: GraphQLTypes["voyce_quests_old_insert_input"];
	/** aggregate max on columns */
["voyce_quests_old_max_fields"]: {
		end_date?:ModelTypes["timestamp"],
	id?:number,
	image?:string,
	recurrency?:number,
	start_date?:ModelTypes["timestamp"],
	steps?:number,
	title?:string
};
	/** aggregate min on columns */
["voyce_quests_old_min_fields"]: {
		end_date?:ModelTypes["timestamp"],
	id?:number,
	image?:string,
	recurrency?:number,
	start_date?:ModelTypes["timestamp"],
	steps?:number,
	title?:string
};
	/** response of any mutation on the table "voyce.quests_old" */
["voyce_quests_old_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_quests_old"][]
};
	/** input type for inserting object relation for remote table "voyce.quests_old" */
["voyce_quests_old_obj_rel_insert_input"]: GraphQLTypes["voyce_quests_old_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.quests_old" */
["voyce_quests_old_on_conflict"]: GraphQLTypes["voyce_quests_old_on_conflict"];
	/** Ordering options when selecting data from "voyce.quests_old". */
["voyce_quests_old_order_by"]: GraphQLTypes["voyce_quests_old_order_by"];
	/** primary key columns input for table: voyce.quests_old */
["voyce_quests_old_pk_columns_input"]: GraphQLTypes["voyce_quests_old_pk_columns_input"];
	/** select columns of table "voyce.quests_old" */
["voyce_quests_old_select_column"]: GraphQLTypes["voyce_quests_old_select_column"];
	/** input type for updating data in table "voyce.quests_old" */
["voyce_quests_old_set_input"]: GraphQLTypes["voyce_quests_old_set_input"];
	/** aggregate stddev on columns */
["voyce_quests_old_stddev_fields"]: {
		id?:number,
	recurrency?:number,
	steps?:number
};
	/** aggregate stddev_pop on columns */
["voyce_quests_old_stddev_pop_fields"]: {
		id?:number,
	recurrency?:number,
	steps?:number
};
	/** aggregate stddev_samp on columns */
["voyce_quests_old_stddev_samp_fields"]: {
		id?:number,
	recurrency?:number,
	steps?:number
};
	/** Streaming cursor of the table "voyce_quests_old" */
["voyce_quests_old_stream_cursor_input"]: GraphQLTypes["voyce_quests_old_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_quests_old_stream_cursor_value_input"]: GraphQLTypes["voyce_quests_old_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_quests_old_sum_fields"]: {
		id?:number,
	recurrency?:number,
	steps?:number
};
	/** update columns of table "voyce.quests_old" */
["voyce_quests_old_update_column"]: GraphQLTypes["voyce_quests_old_update_column"];
	["voyce_quests_old_updates"]: GraphQLTypes["voyce_quests_old_updates"];
	/** aggregate var_pop on columns */
["voyce_quests_old_var_pop_fields"]: {
		id?:number,
	recurrency?:number,
	steps?:number
};
	/** aggregate var_samp on columns */
["voyce_quests_old_var_samp_fields"]: {
		id?:number,
	recurrency?:number,
	steps?:number
};
	/** aggregate variance on columns */
["voyce_quests_old_variance_fields"]: {
		id?:number,
	recurrency?:number,
	steps?:number
};
	/** on_conflict condition type for table "voyce.quests" */
["voyce_quests_on_conflict"]: GraphQLTypes["voyce_quests_on_conflict"];
	/** Ordering options when selecting data from "voyce.quests". */
["voyce_quests_order_by"]: GraphQLTypes["voyce_quests_order_by"];
	/** primary key columns input for table: voyce.quests */
["voyce_quests_pk_columns_input"]: GraphQLTypes["voyce_quests_pk_columns_input"];
	/** select columns of table "voyce.quests" */
["voyce_quests_select_column"]: GraphQLTypes["voyce_quests_select_column"];
	/** input type for updating data in table "voyce.quests" */
["voyce_quests_set_input"]: GraphQLTypes["voyce_quests_set_input"];
	/** aggregate stddev on columns */
["voyce_quests_stddev_fields"]: {
		difficulty_id?:number,
	experience_points?:number,
	id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_quests_stddev_pop_fields"]: {
		difficulty_id?:number,
	experience_points?:number,
	id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_quests_stddev_samp_fields"]: {
		difficulty_id?:number,
	experience_points?:number,
	id?:number
};
	/** Streaming cursor of the table "voyce_quests" */
["voyce_quests_stream_cursor_input"]: GraphQLTypes["voyce_quests_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_quests_stream_cursor_value_input"]: GraphQLTypes["voyce_quests_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_quests_sum_fields"]: {
		difficulty_id?:number,
	experience_points?:number,
	id?:number
};
	/** update columns of table "voyce.quests" */
["voyce_quests_update_column"]: GraphQLTypes["voyce_quests_update_column"];
	["voyce_quests_updates"]: GraphQLTypes["voyce_quests_updates"];
	/** aggregate var_pop on columns */
["voyce_quests_var_pop_fields"]: {
		difficulty_id?:number,
	experience_points?:number,
	id?:number
};
	/** aggregate var_samp on columns */
["voyce_quests_var_samp_fields"]: {
		difficulty_id?:number,
	experience_points?:number,
	id?:number
};
	/** aggregate variance on columns */
["voyce_quests_variance_fields"]: {
		difficulty_id?:number,
	experience_points?:number,
	id?:number
};
	/** columns and relationships of "voyce.recommended_series" */
["voyce_recommended_series"]: {
		created_at:ModelTypes["timestamptz"],
	id:number,
	notification_image?:string,
	notification_status:string,
	notification_text:string,
	notification_title:string,
	/** An object relationship */
	series:ModelTypes["voyce_series"],
	series_id:number
};
	/** aggregated selection of "voyce.recommended_series" */
["voyce_recommended_series_aggregate"]: {
		aggregate?:ModelTypes["voyce_recommended_series_aggregate_fields"],
	nodes:ModelTypes["voyce_recommended_series"][]
};
	/** aggregate fields of "voyce.recommended_series" */
["voyce_recommended_series_aggregate_fields"]: {
		avg?:ModelTypes["voyce_recommended_series_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_recommended_series_max_fields"],
	min?:ModelTypes["voyce_recommended_series_min_fields"],
	stddev?:ModelTypes["voyce_recommended_series_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_recommended_series_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_recommended_series_stddev_samp_fields"],
	sum?:ModelTypes["voyce_recommended_series_sum_fields"],
	var_pop?:ModelTypes["voyce_recommended_series_var_pop_fields"],
	var_samp?:ModelTypes["voyce_recommended_series_var_samp_fields"],
	variance?:ModelTypes["voyce_recommended_series_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_recommended_series_avg_fields"]: {
		id?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.recommended_series". All fields are combined with a logical 'AND'. */
["voyce_recommended_series_bool_exp"]: GraphQLTypes["voyce_recommended_series_bool_exp"];
	/** unique or primary key constraints on table "voyce.recommended_series" */
["voyce_recommended_series_constraint"]: GraphQLTypes["voyce_recommended_series_constraint"];
	/** input type for incrementing numeric columns in table "voyce.recommended_series" */
["voyce_recommended_series_inc_input"]: GraphQLTypes["voyce_recommended_series_inc_input"];
	/** input type for inserting data into table "voyce.recommended_series" */
["voyce_recommended_series_insert_input"]: GraphQLTypes["voyce_recommended_series_insert_input"];
	/** aggregate max on columns */
["voyce_recommended_series_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	notification_image?:string,
	notification_status?:string,
	notification_text?:string,
	notification_title?:string,
	series_id?:number
};
	/** aggregate min on columns */
["voyce_recommended_series_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	notification_image?:string,
	notification_status?:string,
	notification_text?:string,
	notification_title?:string,
	series_id?:number
};
	/** response of any mutation on the table "voyce.recommended_series" */
["voyce_recommended_series_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_recommended_series"][]
};
	/** on_conflict condition type for table "voyce.recommended_series" */
["voyce_recommended_series_on_conflict"]: GraphQLTypes["voyce_recommended_series_on_conflict"];
	/** Ordering options when selecting data from "voyce.recommended_series". */
["voyce_recommended_series_order_by"]: GraphQLTypes["voyce_recommended_series_order_by"];
	/** primary key columns input for table: voyce.recommended_series */
["voyce_recommended_series_pk_columns_input"]: GraphQLTypes["voyce_recommended_series_pk_columns_input"];
	/** select columns of table "voyce.recommended_series" */
["voyce_recommended_series_select_column"]: GraphQLTypes["voyce_recommended_series_select_column"];
	/** input type for updating data in table "voyce.recommended_series" */
["voyce_recommended_series_set_input"]: GraphQLTypes["voyce_recommended_series_set_input"];
	/** aggregate stddev on columns */
["voyce_recommended_series_stddev_fields"]: {
		id?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_recommended_series_stddev_pop_fields"]: {
		id?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_recommended_series_stddev_samp_fields"]: {
		id?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_recommended_series" */
["voyce_recommended_series_stream_cursor_input"]: GraphQLTypes["voyce_recommended_series_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_recommended_series_stream_cursor_value_input"]: GraphQLTypes["voyce_recommended_series_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_recommended_series_sum_fields"]: {
		id?:number,
	series_id?:number
};
	/** update columns of table "voyce.recommended_series" */
["voyce_recommended_series_update_column"]: GraphQLTypes["voyce_recommended_series_update_column"];
	["voyce_recommended_series_updates"]: GraphQLTypes["voyce_recommended_series_updates"];
	/** aggregate var_pop on columns */
["voyce_recommended_series_var_pop_fields"]: {
		id?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_recommended_series_var_samp_fields"]: {
		id?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_recommended_series_variance_fields"]: {
		id?:number,
	series_id?:number
};
	/** columns and relationships of "voyce.release_schedules" */
["voyce_release_schedules"]: {
		/** An object relationship */
	chapter?:ModelTypes["voyce_chapters"],
	chapter_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id:number,
	schedule:ModelTypes["timestamptz"],
	/** An object relationship */
	series?:ModelTypes["voyce_series"],
	series_id:number,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.release_schedules" */
["voyce_release_schedules_aggregate"]: {
		aggregate?:ModelTypes["voyce_release_schedules_aggregate_fields"],
	nodes:ModelTypes["voyce_release_schedules"][]
};
	/** aggregate fields of "voyce.release_schedules" */
["voyce_release_schedules_aggregate_fields"]: {
		avg?:ModelTypes["voyce_release_schedules_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_release_schedules_max_fields"],
	min?:ModelTypes["voyce_release_schedules_min_fields"],
	stddev?:ModelTypes["voyce_release_schedules_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_release_schedules_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_release_schedules_stddev_samp_fields"],
	sum?:ModelTypes["voyce_release_schedules_sum_fields"],
	var_pop?:ModelTypes["voyce_release_schedules_var_pop_fields"],
	var_samp?:ModelTypes["voyce_release_schedules_var_samp_fields"],
	variance?:ModelTypes["voyce_release_schedules_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_release_schedules_avg_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.release_schedules". All fields are combined with a logical 'AND'. */
["voyce_release_schedules_bool_exp"]: GraphQLTypes["voyce_release_schedules_bool_exp"];
	/** unique or primary key constraints on table "voyce.release_schedules" */
["voyce_release_schedules_constraint"]: GraphQLTypes["voyce_release_schedules_constraint"];
	/** input type for incrementing numeric columns in table "voyce.release_schedules" */
["voyce_release_schedules_inc_input"]: GraphQLTypes["voyce_release_schedules_inc_input"];
	/** input type for inserting data into table "voyce.release_schedules" */
["voyce_release_schedules_insert_input"]: GraphQLTypes["voyce_release_schedules_insert_input"];
	/** aggregate max on columns */
["voyce_release_schedules_max_fields"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	schedule?:ModelTypes["timestamptz"],
	series_id?:number,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_release_schedules_min_fields"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	schedule?:ModelTypes["timestamptz"],
	series_id?:number,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.release_schedules" */
["voyce_release_schedules_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_release_schedules"][]
};
	/** on_conflict condition type for table "voyce.release_schedules" */
["voyce_release_schedules_on_conflict"]: GraphQLTypes["voyce_release_schedules_on_conflict"];
	/** Ordering options when selecting data from "voyce.release_schedules". */
["voyce_release_schedules_order_by"]: GraphQLTypes["voyce_release_schedules_order_by"];
	/** primary key columns input for table: voyce.release_schedules */
["voyce_release_schedules_pk_columns_input"]: GraphQLTypes["voyce_release_schedules_pk_columns_input"];
	/** select columns of table "voyce.release_schedules" */
["voyce_release_schedules_select_column"]: GraphQLTypes["voyce_release_schedules_select_column"];
	/** input type for updating data in table "voyce.release_schedules" */
["voyce_release_schedules_set_input"]: GraphQLTypes["voyce_release_schedules_set_input"];
	/** aggregate stddev on columns */
["voyce_release_schedules_stddev_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_release_schedules_stddev_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_release_schedules_stddev_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_release_schedules" */
["voyce_release_schedules_stream_cursor_input"]: GraphQLTypes["voyce_release_schedules_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_release_schedules_stream_cursor_value_input"]: GraphQLTypes["voyce_release_schedules_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_release_schedules_sum_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number
};
	/** update columns of table "voyce.release_schedules" */
["voyce_release_schedules_update_column"]: GraphQLTypes["voyce_release_schedules_update_column"];
	["voyce_release_schedules_updates"]: GraphQLTypes["voyce_release_schedules_updates"];
	/** aggregate var_pop on columns */
["voyce_release_schedules_var_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_release_schedules_var_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_release_schedules_variance_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number
};
	/** table to describe all possible rewards users can get */
["voyce_rewards"]: {
		end_date?:ModelTypes["timestamp"],
	expiry_date?:ModelTypes["timestamp"],
	free:boolean,
	id:number,
	image?:string,
	purchaseId:string,
	start_date:ModelTypes["timestamp"],
	title?:string,
	transferable:boolean,
	type:string,
	value?:number
};
	/** aggregated selection of "voyce.rewards" */
["voyce_rewards_aggregate"]: {
		aggregate?:ModelTypes["voyce_rewards_aggregate_fields"],
	nodes:ModelTypes["voyce_rewards"][]
};
	/** aggregate fields of "voyce.rewards" */
["voyce_rewards_aggregate_fields"]: {
		avg?:ModelTypes["voyce_rewards_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_rewards_max_fields"],
	min?:ModelTypes["voyce_rewards_min_fields"],
	stddev?:ModelTypes["voyce_rewards_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_rewards_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_rewards_stddev_samp_fields"],
	sum?:ModelTypes["voyce_rewards_sum_fields"],
	var_pop?:ModelTypes["voyce_rewards_var_pop_fields"],
	var_samp?:ModelTypes["voyce_rewards_var_samp_fields"],
	variance?:ModelTypes["voyce_rewards_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_rewards_avg_fields"]: {
		id?:number,
	value?:number
};
	/** Boolean expression to filter rows from the table "voyce.rewards". All fields are combined with a logical 'AND'. */
["voyce_rewards_bool_exp"]: GraphQLTypes["voyce_rewards_bool_exp"];
	/** unique or primary key constraints on table "voyce.rewards" */
["voyce_rewards_constraint"]: GraphQLTypes["voyce_rewards_constraint"];
	/** input type for incrementing numeric columns in table "voyce.rewards" */
["voyce_rewards_inc_input"]: GraphQLTypes["voyce_rewards_inc_input"];
	/** input type for inserting data into table "voyce.rewards" */
["voyce_rewards_insert_input"]: GraphQLTypes["voyce_rewards_insert_input"];
	/** table to keep rewards configuration for each leaderboards */
["voyce_rewards_leaderboard"]: {
		id:number,
	order?:number,
	/** An object relationship */
	reward:ModelTypes["voyce_rewards"],
	reward_id:number,
	tag?:string,
	title?:string
};
	/** aggregated selection of "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_aggregate"]: {
		aggregate?:ModelTypes["voyce_rewards_leaderboard_aggregate_fields"],
	nodes:ModelTypes["voyce_rewards_leaderboard"][]
};
	/** aggregate fields of "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_aggregate_fields"]: {
		avg?:ModelTypes["voyce_rewards_leaderboard_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_rewards_leaderboard_max_fields"],
	min?:ModelTypes["voyce_rewards_leaderboard_min_fields"],
	stddev?:ModelTypes["voyce_rewards_leaderboard_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_rewards_leaderboard_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_rewards_leaderboard_stddev_samp_fields"],
	sum?:ModelTypes["voyce_rewards_leaderboard_sum_fields"],
	var_pop?:ModelTypes["voyce_rewards_leaderboard_var_pop_fields"],
	var_samp?:ModelTypes["voyce_rewards_leaderboard_var_samp_fields"],
	variance?:ModelTypes["voyce_rewards_leaderboard_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_rewards_leaderboard_avg_fields"]: {
		id?:number,
	order?:number,
	reward_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.rewards_leaderboard". All fields are combined with a logical 'AND'. */
["voyce_rewards_leaderboard_bool_exp"]: GraphQLTypes["voyce_rewards_leaderboard_bool_exp"];
	/** unique or primary key constraints on table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_constraint"]: GraphQLTypes["voyce_rewards_leaderboard_constraint"];
	/** input type for incrementing numeric columns in table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_inc_input"]: GraphQLTypes["voyce_rewards_leaderboard_inc_input"];
	/** input type for inserting data into table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_insert_input"]: GraphQLTypes["voyce_rewards_leaderboard_insert_input"];
	/** aggregate max on columns */
["voyce_rewards_leaderboard_max_fields"]: {
		id?:number,
	order?:number,
	reward_id?:number,
	tag?:string,
	title?:string
};
	/** aggregate min on columns */
["voyce_rewards_leaderboard_min_fields"]: {
		id?:number,
	order?:number,
	reward_id?:number,
	tag?:string,
	title?:string
};
	/** response of any mutation on the table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_rewards_leaderboard"][]
};
	/** on_conflict condition type for table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_on_conflict"]: GraphQLTypes["voyce_rewards_leaderboard_on_conflict"];
	/** Ordering options when selecting data from "voyce.rewards_leaderboard". */
["voyce_rewards_leaderboard_order_by"]: GraphQLTypes["voyce_rewards_leaderboard_order_by"];
	/** primary key columns input for table: voyce.rewards_leaderboard */
["voyce_rewards_leaderboard_pk_columns_input"]: GraphQLTypes["voyce_rewards_leaderboard_pk_columns_input"];
	/** select columns of table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_select_column"]: GraphQLTypes["voyce_rewards_leaderboard_select_column"];
	/** input type for updating data in table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_set_input"]: GraphQLTypes["voyce_rewards_leaderboard_set_input"];
	/** aggregate stddev on columns */
["voyce_rewards_leaderboard_stddev_fields"]: {
		id?:number,
	order?:number,
	reward_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_rewards_leaderboard_stddev_pop_fields"]: {
		id?:number,
	order?:number,
	reward_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_rewards_leaderboard_stddev_samp_fields"]: {
		id?:number,
	order?:number,
	reward_id?:number
};
	/** Streaming cursor of the table "voyce_rewards_leaderboard" */
["voyce_rewards_leaderboard_stream_cursor_input"]: GraphQLTypes["voyce_rewards_leaderboard_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_rewards_leaderboard_stream_cursor_value_input"]: GraphQLTypes["voyce_rewards_leaderboard_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_rewards_leaderboard_sum_fields"]: {
		id?:number,
	order?:number,
	reward_id?:number
};
	/** update columns of table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_update_column"]: GraphQLTypes["voyce_rewards_leaderboard_update_column"];
	["voyce_rewards_leaderboard_updates"]: GraphQLTypes["voyce_rewards_leaderboard_updates"];
	/** aggregate var_pop on columns */
["voyce_rewards_leaderboard_var_pop_fields"]: {
		id?:number,
	order?:number,
	reward_id?:number
};
	/** aggregate var_samp on columns */
["voyce_rewards_leaderboard_var_samp_fields"]: {
		id?:number,
	order?:number,
	reward_id?:number
};
	/** aggregate variance on columns */
["voyce_rewards_leaderboard_variance_fields"]: {
		id?:number,
	order?:number,
	reward_id?:number
};
	/** aggregate max on columns */
["voyce_rewards_max_fields"]: {
		end_date?:ModelTypes["timestamp"],
	expiry_date?:ModelTypes["timestamp"],
	id?:number,
	image?:string,
	purchaseId?:string,
	start_date?:ModelTypes["timestamp"],
	title?:string,
	type?:string,
	value?:number
};
	/** aggregate min on columns */
["voyce_rewards_min_fields"]: {
		end_date?:ModelTypes["timestamp"],
	expiry_date?:ModelTypes["timestamp"],
	id?:number,
	image?:string,
	purchaseId?:string,
	start_date?:ModelTypes["timestamp"],
	title?:string,
	type?:string,
	value?:number
};
	/** response of any mutation on the table "voyce.rewards" */
["voyce_rewards_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_rewards"][]
};
	/** input type for inserting object relation for remote table "voyce.rewards" */
["voyce_rewards_obj_rel_insert_input"]: GraphQLTypes["voyce_rewards_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.rewards" */
["voyce_rewards_on_conflict"]: GraphQLTypes["voyce_rewards_on_conflict"];
	/** Ordering options when selecting data from "voyce.rewards". */
["voyce_rewards_order_by"]: GraphQLTypes["voyce_rewards_order_by"];
	/** primary key columns input for table: voyce.rewards */
["voyce_rewards_pk_columns_input"]: GraphQLTypes["voyce_rewards_pk_columns_input"];
	/** table to describe rewards of a given quest */
["voyce_rewards_quest"]: {
		id:number,
	/** An object relationship */
	quest:ModelTypes["voyce_quests_old"],
	quest_id:number,
	/** An object relationship */
	reward:ModelTypes["voyce_rewards"],
	rewards_id:number
};
	/** aggregated selection of "voyce.rewards_quest" */
["voyce_rewards_quest_aggregate"]: {
		aggregate?:ModelTypes["voyce_rewards_quest_aggregate_fields"],
	nodes:ModelTypes["voyce_rewards_quest"][]
};
	["voyce_rewards_quest_aggregate_bool_exp"]: GraphQLTypes["voyce_rewards_quest_aggregate_bool_exp"];
	["voyce_rewards_quest_aggregate_bool_exp_count"]: GraphQLTypes["voyce_rewards_quest_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.rewards_quest" */
["voyce_rewards_quest_aggregate_fields"]: {
		avg?:ModelTypes["voyce_rewards_quest_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_rewards_quest_max_fields"],
	min?:ModelTypes["voyce_rewards_quest_min_fields"],
	stddev?:ModelTypes["voyce_rewards_quest_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_rewards_quest_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_rewards_quest_stddev_samp_fields"],
	sum?:ModelTypes["voyce_rewards_quest_sum_fields"],
	var_pop?:ModelTypes["voyce_rewards_quest_var_pop_fields"],
	var_samp?:ModelTypes["voyce_rewards_quest_var_samp_fields"],
	variance?:ModelTypes["voyce_rewards_quest_variance_fields"]
};
	/** order by aggregate values of table "voyce.rewards_quest" */
["voyce_rewards_quest_aggregate_order_by"]: GraphQLTypes["voyce_rewards_quest_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.rewards_quest" */
["voyce_rewards_quest_arr_rel_insert_input"]: GraphQLTypes["voyce_rewards_quest_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_rewards_quest_avg_fields"]: {
		id?:number,
	quest_id?:number,
	rewards_id?:number
};
	/** order by avg() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_avg_order_by"]: GraphQLTypes["voyce_rewards_quest_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.rewards_quest". All fields are combined with a logical 'AND'. */
["voyce_rewards_quest_bool_exp"]: GraphQLTypes["voyce_rewards_quest_bool_exp"];
	/** unique or primary key constraints on table "voyce.rewards_quest" */
["voyce_rewards_quest_constraint"]: GraphQLTypes["voyce_rewards_quest_constraint"];
	/** input type for incrementing numeric columns in table "voyce.rewards_quest" */
["voyce_rewards_quest_inc_input"]: GraphQLTypes["voyce_rewards_quest_inc_input"];
	/** input type for inserting data into table "voyce.rewards_quest" */
["voyce_rewards_quest_insert_input"]: GraphQLTypes["voyce_rewards_quest_insert_input"];
	/** aggregate max on columns */
["voyce_rewards_quest_max_fields"]: {
		id?:number,
	quest_id?:number,
	rewards_id?:number
};
	/** order by max() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_max_order_by"]: GraphQLTypes["voyce_rewards_quest_max_order_by"];
	/** aggregate min on columns */
["voyce_rewards_quest_min_fields"]: {
		id?:number,
	quest_id?:number,
	rewards_id?:number
};
	/** order by min() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_min_order_by"]: GraphQLTypes["voyce_rewards_quest_min_order_by"];
	/** response of any mutation on the table "voyce.rewards_quest" */
["voyce_rewards_quest_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_rewards_quest"][]
};
	/** on_conflict condition type for table "voyce.rewards_quest" */
["voyce_rewards_quest_on_conflict"]: GraphQLTypes["voyce_rewards_quest_on_conflict"];
	/** Ordering options when selecting data from "voyce.rewards_quest". */
["voyce_rewards_quest_order_by"]: GraphQLTypes["voyce_rewards_quest_order_by"];
	/** primary key columns input for table: voyce.rewards_quest */
["voyce_rewards_quest_pk_columns_input"]: GraphQLTypes["voyce_rewards_quest_pk_columns_input"];
	/** select columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_select_column"]: GraphQLTypes["voyce_rewards_quest_select_column"];
	/** input type for updating data in table "voyce.rewards_quest" */
["voyce_rewards_quest_set_input"]: GraphQLTypes["voyce_rewards_quest_set_input"];
	/** aggregate stddev on columns */
["voyce_rewards_quest_stddev_fields"]: {
		id?:number,
	quest_id?:number,
	rewards_id?:number
};
	/** order by stddev() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_stddev_order_by"]: GraphQLTypes["voyce_rewards_quest_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_rewards_quest_stddev_pop_fields"]: {
		id?:number,
	quest_id?:number,
	rewards_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_stddev_pop_order_by"]: GraphQLTypes["voyce_rewards_quest_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_rewards_quest_stddev_samp_fields"]: {
		id?:number,
	quest_id?:number,
	rewards_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_stddev_samp_order_by"]: GraphQLTypes["voyce_rewards_quest_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_rewards_quest" */
["voyce_rewards_quest_stream_cursor_input"]: GraphQLTypes["voyce_rewards_quest_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_rewards_quest_stream_cursor_value_input"]: GraphQLTypes["voyce_rewards_quest_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_rewards_quest_sum_fields"]: {
		id?:number,
	quest_id?:number,
	rewards_id?:number
};
	/** order by sum() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_sum_order_by"]: GraphQLTypes["voyce_rewards_quest_sum_order_by"];
	/** update columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_update_column"]: GraphQLTypes["voyce_rewards_quest_update_column"];
	["voyce_rewards_quest_updates"]: GraphQLTypes["voyce_rewards_quest_updates"];
	/** aggregate var_pop on columns */
["voyce_rewards_quest_var_pop_fields"]: {
		id?:number,
	quest_id?:number,
	rewards_id?:number
};
	/** order by var_pop() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_var_pop_order_by"]: GraphQLTypes["voyce_rewards_quest_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_rewards_quest_var_samp_fields"]: {
		id?:number,
	quest_id?:number,
	rewards_id?:number
};
	/** order by var_samp() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_var_samp_order_by"]: GraphQLTypes["voyce_rewards_quest_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_rewards_quest_variance_fields"]: {
		id?:number,
	quest_id?:number,
	rewards_id?:number
};
	/** order by variance() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_variance_order_by"]: GraphQLTypes["voyce_rewards_quest_variance_order_by"];
	/** select columns of table "voyce.rewards" */
["voyce_rewards_select_column"]: GraphQLTypes["voyce_rewards_select_column"];
	/** input type for updating data in table "voyce.rewards" */
["voyce_rewards_set_input"]: GraphQLTypes["voyce_rewards_set_input"];
	/** aggregate stddev on columns */
["voyce_rewards_stddev_fields"]: {
		id?:number,
	value?:number
};
	/** aggregate stddev_pop on columns */
["voyce_rewards_stddev_pop_fields"]: {
		id?:number,
	value?:number
};
	/** aggregate stddev_samp on columns */
["voyce_rewards_stddev_samp_fields"]: {
		id?:number,
	value?:number
};
	/** Streaming cursor of the table "voyce_rewards" */
["voyce_rewards_stream_cursor_input"]: GraphQLTypes["voyce_rewards_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_rewards_stream_cursor_value_input"]: GraphQLTypes["voyce_rewards_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_rewards_sum_fields"]: {
		id?:number,
	value?:number
};
	/** update columns of table "voyce.rewards" */
["voyce_rewards_update_column"]: GraphQLTypes["voyce_rewards_update_column"];
	["voyce_rewards_updates"]: GraphQLTypes["voyce_rewards_updates"];
	/** aggregate var_pop on columns */
["voyce_rewards_var_pop_fields"]: {
		id?:number,
	value?:number
};
	/** aggregate var_samp on columns */
["voyce_rewards_var_samp_fields"]: {
		id?:number,
	value?:number
};
	/** aggregate variance on columns */
["voyce_rewards_variance_fields"]: {
		id?:number,
	value?:number
};
	/** columns and relationships of "voyce.sections" */
["voyce_sections"]: {
		content?:string,
	created_at?:ModelTypes["timestamp"],
	id:number,
	title?:string,
	updated_at?:ModelTypes["timestamp"]
};
	/** aggregated selection of "voyce.sections" */
["voyce_sections_aggregate"]: {
		aggregate?:ModelTypes["voyce_sections_aggregate_fields"],
	nodes:ModelTypes["voyce_sections"][]
};
	/** aggregate fields of "voyce.sections" */
["voyce_sections_aggregate_fields"]: {
		avg?:ModelTypes["voyce_sections_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_sections_max_fields"],
	min?:ModelTypes["voyce_sections_min_fields"],
	stddev?:ModelTypes["voyce_sections_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_sections_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_sections_stddev_samp_fields"],
	sum?:ModelTypes["voyce_sections_sum_fields"],
	var_pop?:ModelTypes["voyce_sections_var_pop_fields"],
	var_samp?:ModelTypes["voyce_sections_var_samp_fields"],
	variance?:ModelTypes["voyce_sections_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_sections_avg_fields"]: {
		id?:number
};
	/** Boolean expression to filter rows from the table "voyce.sections". All fields are combined with a logical 'AND'. */
["voyce_sections_bool_exp"]: GraphQLTypes["voyce_sections_bool_exp"];
	/** unique or primary key constraints on table "voyce.sections" */
["voyce_sections_constraint"]: GraphQLTypes["voyce_sections_constraint"];
	/** input type for incrementing numeric columns in table "voyce.sections" */
["voyce_sections_inc_input"]: GraphQLTypes["voyce_sections_inc_input"];
	/** input type for inserting data into table "voyce.sections" */
["voyce_sections_insert_input"]: GraphQLTypes["voyce_sections_insert_input"];
	/** aggregate max on columns */
["voyce_sections_max_fields"]: {
		content?:string,
	created_at?:ModelTypes["timestamp"],
	id?:number,
	title?:string,
	updated_at?:ModelTypes["timestamp"]
};
	/** aggregate min on columns */
["voyce_sections_min_fields"]: {
		content?:string,
	created_at?:ModelTypes["timestamp"],
	id?:number,
	title?:string,
	updated_at?:ModelTypes["timestamp"]
};
	/** response of any mutation on the table "voyce.sections" */
["voyce_sections_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_sections"][]
};
	/** on_conflict condition type for table "voyce.sections" */
["voyce_sections_on_conflict"]: GraphQLTypes["voyce_sections_on_conflict"];
	/** Ordering options when selecting data from "voyce.sections". */
["voyce_sections_order_by"]: GraphQLTypes["voyce_sections_order_by"];
	/** primary key columns input for table: voyce.sections */
["voyce_sections_pk_columns_input"]: GraphQLTypes["voyce_sections_pk_columns_input"];
	/** select columns of table "voyce.sections" */
["voyce_sections_select_column"]: GraphQLTypes["voyce_sections_select_column"];
	/** input type for updating data in table "voyce.sections" */
["voyce_sections_set_input"]: GraphQLTypes["voyce_sections_set_input"];
	/** aggregate stddev on columns */
["voyce_sections_stddev_fields"]: {
		id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_sections_stddev_pop_fields"]: {
		id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_sections_stddev_samp_fields"]: {
		id?:number
};
	/** Streaming cursor of the table "voyce_sections" */
["voyce_sections_stream_cursor_input"]: GraphQLTypes["voyce_sections_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_sections_stream_cursor_value_input"]: GraphQLTypes["voyce_sections_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_sections_sum_fields"]: {
		id?:number
};
	/** update columns of table "voyce.sections" */
["voyce_sections_update_column"]: GraphQLTypes["voyce_sections_update_column"];
	["voyce_sections_updates"]: GraphQLTypes["voyce_sections_updates"];
	/** aggregate var_pop on columns */
["voyce_sections_var_pop_fields"]: {
		id?:number
};
	/** aggregate var_samp on columns */
["voyce_sections_var_samp_fields"]: {
		id?:number
};
	/** aggregate variance on columns */
["voyce_sections_variance_fields"]: {
		id?:number
};
	/** columns and relationships of "voyce.series" */
["voyce_series"]: {
		animated_cover_image?:string,
	audio_allowed:boolean,
	/** An object relationship */
	author?:ModelTypes["voyce_users"],
	average_rating?:ModelTypes["float8"],
	back_image?:string,
	banner_bg?:string,
	book_reverse_view:boolean,
	book_view:boolean,
	/** An array relationship */
	chapter_comments:ModelTypes["voyce_chapter_comments"][],
	/** An aggregate relationship */
	chapter_comments_aggregate:ModelTypes["voyce_chapter_comments_aggregate"],
	chapter_count?:number,
	/** An array relationship */
	chapter_panels:ModelTypes["voyce_chapter_panels"][],
	/** An aggregate relationship */
	chapter_panels_aggregate:ModelTypes["voyce_chapter_panels_aggregate"],
	/** An array relationship */
	chapters:ModelTypes["voyce_chapters"][],
	/** An aggregate relationship */
	chapters_aggregate:ModelTypes["voyce_chapters_aggregate"],
	chapters_hide:string,
	character_cover?:string,
	coin_price?:ModelTypes["smallint"],
	/** An array relationship */
	collaborators:ModelTypes["voyce_series_collaborators"][],
	/** An aggregate relationship */
	collaborators_aggregate:ModelTypes["voyce_series_collaborators_aggregate"],
	comic_type_id?:number,
	cover_image?:string,
	cover_image_mobile?:string,
	created_at:ModelTypes["timestamp"],
	csat:boolean,
	default_view?:string,
	description?:string,
	feature?:ModelTypes["bit"],
	/** An array relationship */
	featured_panels:ModelTypes["voyce_series_featured_panels"][],
	/** An aggregate relationship */
	featured_panels_aggregate:ModelTypes["voyce_series_featured_panels_aggregate"],
	/** An array relationship */
	followers:ModelTypes["voyce_series_subscription"][],
	/** An aggregate relationship */
	followers_aggregate:ModelTypes["voyce_series_subscription_aggregate"],
	frequency?:string,
	frequency_mask?:number,
	front_image?:string,
	/** An array relationship */
	genres:ModelTypes["voyce_series_genre"][],
	/** An aggregate relationship */
	genres_aggregate:ModelTypes["voyce_series_genre_aggregate"],
	/** An array relationship */
	hot_panels:ModelTypes["voyce_series_hot_panels"][],
	/** An aggregate relationship */
	hot_panels_aggregate:ModelTypes["voyce_series_hot_panels_aggregate"],
	id:number,
	is_animated_cover_allowed:boolean,
	is_approved?:ModelTypes["smallint"],
	is_deleted:boolean,
	is_intro?:ModelTypes["smallint"],
	is_locked:boolean,
	is_original?:ModelTypes["smallint"],
	is_pilots:ModelTypes["smallint"],
	is_recommended?:ModelTypes["smallint"],
	klaviyo_id?:string,
	klaviyo_list_id?:string,
	latest_chapter_update?:ModelTypes["timestamp"],
	/** An array relationship */
	likes:ModelTypes["voyce_chapter_likes"][],
	/** An aggregate relationship */
	likes_aggregate:ModelTypes["voyce_chapter_likes_aggregate"],
	/** An object relationship */
	likes_count?:ModelTypes["voyce_series_likes_count_mat"],
	/** An object relationship */
	lock_config?:ModelTypes["voyce_series_lock_config"],
	locked:boolean,
	locked_chapters_amount?:number,
	/** An object relationship */
	notification_settings?:ModelTypes["voyce_notification_series_settings"],
	paginated_view:boolean,
	pilots_votes_count?:number,
	portfolio_desc?:string,
	publish?:ModelTypes["smallint"],
	rating_count?:number,
	reading_view:string,
	scroll_view:boolean,
	/** An array relationship */
	series_tags_junctions:ModelTypes["voyce_series_tags_junction"][],
	/** An aggregate relationship */
	series_tags_junctions_aggregate:ModelTypes["voyce_series_tags_junction_aggregate"],
	short_desc?:string,
	slug?:string,
	/** An object relationship */
	sponsorship_config?:ModelTypes["voyce_series_sponsorships_configuration"],
	/** An array relationship */
	sponsorships:ModelTypes["voyce_sponsorships"][],
	/** An aggregate relationship */
	sponsorships_aggregate:ModelTypes["voyce_sponsorships_aggregate"],
	status?:string,
	subscriber_count?:number,
	support_msg?:string,
	team_spotlight:boolean,
	thumbnail?:string,
	thumbnail_box?:string,
	title:string,
	trending?:ModelTypes["bit"],
	trending_position?:number,
	/** An object relationship */
	type?:ModelTypes["voyce_comic_types"],
	updated_at?:ModelTypes["timestamp"],
	user_display_field?:string,
	user_id:number,
	/** An array relationship */
	users_series_junctions:ModelTypes["voyce_users_series_junction"][],
	/** An aggregate relationship */
	users_series_junctions_aggregate:ModelTypes["voyce_users_series_junction_aggregate"],
	view_count?:number,
	/** An array relationship */
	views:ModelTypes["voyce_chapter_views"][],
	/** An aggregate relationship */
	views_aggregate:ModelTypes["voyce_chapter_views_aggregate"],
	/** An object relationship */
	views_counts?:ModelTypes["voyce_pg_rs_series_views_count"],
	welcome_msg?:string
};
	/** aggregated selection of "voyce.series" */
["voyce_series_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_aggregate_fields"],
	nodes:ModelTypes["voyce_series"][]
};
	/** aggregate fields of "voyce.series" */
["voyce_series_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_max_fields"],
	min?:ModelTypes["voyce_series_min_fields"],
	stddev?:ModelTypes["voyce_series_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_sum_fields"],
	var_pop?:ModelTypes["voyce_series_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_var_samp_fields"],
	variance?:ModelTypes["voyce_series_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_avg_fields"]: {
		average_rating?:number,
	chapter_count?:number,
	coin_price?:number,
	comic_type_id?:number,
	frequency_mask?:number,
	id?:number,
	is_approved?:number,
	is_intro?:number,
	is_original?:number,
	is_pilots?:number,
	is_recommended?:number,
	locked_chapters_amount?:number,
	pilots_votes_count?:number,
	publish?:number,
	rating_count?:number,
	subscriber_count?:number,
	trending_position?:number,
	user_id?:number,
	view_count?:number
};
	/** Boolean expression to filter rows from the table "voyce.series". All fields are combined with a logical 'AND'. */
["voyce_series_bool_exp"]: GraphQLTypes["voyce_series_bool_exp"];
	/** columns and relationships of "voyce.series_collaborators" */
["voyce_series_collaborators"]: {
		/** An object relationship */
	collaborator_role?:ModelTypes["voyce_collaborator_roles"],
	created_at:ModelTypes["timestamptz"],
	id:number,
	role_id:number,
	/** An object relationship */
	series:ModelTypes["voyce_series"],
	series_id:number,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.series_collaborators" */
["voyce_series_collaborators_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_collaborators_aggregate_fields"],
	nodes:ModelTypes["voyce_series_collaborators"][]
};
	["voyce_series_collaborators_aggregate_bool_exp"]: GraphQLTypes["voyce_series_collaborators_aggregate_bool_exp"];
	["voyce_series_collaborators_aggregate_bool_exp_count"]: GraphQLTypes["voyce_series_collaborators_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.series_collaborators" */
["voyce_series_collaborators_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_collaborators_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_collaborators_max_fields"],
	min?:ModelTypes["voyce_series_collaborators_min_fields"],
	stddev?:ModelTypes["voyce_series_collaborators_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_collaborators_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_collaborators_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_collaborators_sum_fields"],
	var_pop?:ModelTypes["voyce_series_collaborators_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_collaborators_var_samp_fields"],
	variance?:ModelTypes["voyce_series_collaborators_variance_fields"]
};
	/** order by aggregate values of table "voyce.series_collaborators" */
["voyce_series_collaborators_aggregate_order_by"]: GraphQLTypes["voyce_series_collaborators_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.series_collaborators" */
["voyce_series_collaborators_arr_rel_insert_input"]: GraphQLTypes["voyce_series_collaborators_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_series_collaborators_avg_fields"]: {
		id?:number,
	role_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_avg_order_by"]: GraphQLTypes["voyce_series_collaborators_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.series_collaborators". All fields are combined with a logical 'AND'. */
["voyce_series_collaborators_bool_exp"]: GraphQLTypes["voyce_series_collaborators_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_collaborators" */
["voyce_series_collaborators_constraint"]: GraphQLTypes["voyce_series_collaborators_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_collaborators" */
["voyce_series_collaborators_inc_input"]: GraphQLTypes["voyce_series_collaborators_inc_input"];
	/** input type for inserting data into table "voyce.series_collaborators" */
["voyce_series_collaborators_insert_input"]: GraphQLTypes["voyce_series_collaborators_insert_input"];
	/** aggregate max on columns */
["voyce_series_collaborators_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	role_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by max() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_max_order_by"]: GraphQLTypes["voyce_series_collaborators_max_order_by"];
	/** aggregate min on columns */
["voyce_series_collaborators_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	role_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by min() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_min_order_by"]: GraphQLTypes["voyce_series_collaborators_min_order_by"];
	/** response of any mutation on the table "voyce.series_collaborators" */
["voyce_series_collaborators_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_collaborators"][]
};
	/** on_conflict condition type for table "voyce.series_collaborators" */
["voyce_series_collaborators_on_conflict"]: GraphQLTypes["voyce_series_collaborators_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_collaborators". */
["voyce_series_collaborators_order_by"]: GraphQLTypes["voyce_series_collaborators_order_by"];
	/** primary key columns input for table: voyce.series_collaborators */
["voyce_series_collaborators_pk_columns_input"]: GraphQLTypes["voyce_series_collaborators_pk_columns_input"];
	/** select columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_select_column"]: GraphQLTypes["voyce_series_collaborators_select_column"];
	/** input type for updating data in table "voyce.series_collaborators" */
["voyce_series_collaborators_set_input"]: GraphQLTypes["voyce_series_collaborators_set_input"];
	/** aggregate stddev on columns */
["voyce_series_collaborators_stddev_fields"]: {
		id?:number,
	role_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_stddev_order_by"]: GraphQLTypes["voyce_series_collaborators_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_series_collaborators_stddev_pop_fields"]: {
		id?:number,
	role_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_stddev_pop_order_by"]: GraphQLTypes["voyce_series_collaborators_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_series_collaborators_stddev_samp_fields"]: {
		id?:number,
	role_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_stddev_samp_order_by"]: GraphQLTypes["voyce_series_collaborators_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_series_collaborators" */
["voyce_series_collaborators_stream_cursor_input"]: GraphQLTypes["voyce_series_collaborators_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_collaborators_stream_cursor_value_input"]: GraphQLTypes["voyce_series_collaborators_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_collaborators_sum_fields"]: {
		id?:number,
	role_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_sum_order_by"]: GraphQLTypes["voyce_series_collaborators_sum_order_by"];
	/** update columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_update_column"]: GraphQLTypes["voyce_series_collaborators_update_column"];
	["voyce_series_collaborators_updates"]: GraphQLTypes["voyce_series_collaborators_updates"];
	/** aggregate var_pop on columns */
["voyce_series_collaborators_var_pop_fields"]: {
		id?:number,
	role_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_var_pop_order_by"]: GraphQLTypes["voyce_series_collaborators_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_series_collaborators_var_samp_fields"]: {
		id?:number,
	role_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_var_samp_order_by"]: GraphQLTypes["voyce_series_collaborators_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_series_collaborators_variance_fields"]: {
		id?:number,
	role_id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_variance_order_by"]: GraphQLTypes["voyce_series_collaborators_variance_order_by"];
	/** unique or primary key constraints on table "voyce.series" */
["voyce_series_constraint"]: GraphQLTypes["voyce_series_constraint"];
	/** columns and relationships of "voyce.series_donations" */
["voyce_series_donations"]: {
		amount:ModelTypes["numeric"],
	created_at:ModelTypes["timestamptz"],
	currency:string,
	id:number,
	message:string,
	series_id:number,
	user_id:number
};
	/** aggregated selection of "voyce.series_donations" */
["voyce_series_donations_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_donations_aggregate_fields"],
	nodes:ModelTypes["voyce_series_donations"][]
};
	/** aggregate fields of "voyce.series_donations" */
["voyce_series_donations_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_donations_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_donations_max_fields"],
	min?:ModelTypes["voyce_series_donations_min_fields"],
	stddev?:ModelTypes["voyce_series_donations_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_donations_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_donations_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_donations_sum_fields"],
	var_pop?:ModelTypes["voyce_series_donations_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_donations_var_samp_fields"],
	variance?:ModelTypes["voyce_series_donations_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_donations_avg_fields"]: {
		amount?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_donations". All fields are combined with a logical 'AND'. */
["voyce_series_donations_bool_exp"]: GraphQLTypes["voyce_series_donations_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_donations" */
["voyce_series_donations_constraint"]: GraphQLTypes["voyce_series_donations_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_donations" */
["voyce_series_donations_inc_input"]: GraphQLTypes["voyce_series_donations_inc_input"];
	/** input type for inserting data into table "voyce.series_donations" */
["voyce_series_donations_insert_input"]: GraphQLTypes["voyce_series_donations_insert_input"];
	/** aggregate max on columns */
["voyce_series_donations_max_fields"]: {
		amount?:ModelTypes["numeric"],
	created_at?:ModelTypes["timestamptz"],
	currency?:string,
	id?:number,
	message?:string,
	series_id?:number,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_series_donations_min_fields"]: {
		amount?:ModelTypes["numeric"],
	created_at?:ModelTypes["timestamptz"],
	currency?:string,
	id?:number,
	message?:string,
	series_id?:number,
	user_id?:number
};
	/** response of any mutation on the table "voyce.series_donations" */
["voyce_series_donations_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_donations"][]
};
	/** on_conflict condition type for table "voyce.series_donations" */
["voyce_series_donations_on_conflict"]: GraphQLTypes["voyce_series_donations_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_donations". */
["voyce_series_donations_order_by"]: GraphQLTypes["voyce_series_donations_order_by"];
	/** primary key columns input for table: voyce.series_donations */
["voyce_series_donations_pk_columns_input"]: GraphQLTypes["voyce_series_donations_pk_columns_input"];
	/** select columns of table "voyce.series_donations" */
["voyce_series_donations_select_column"]: GraphQLTypes["voyce_series_donations_select_column"];
	/** input type for updating data in table "voyce.series_donations" */
["voyce_series_donations_set_input"]: GraphQLTypes["voyce_series_donations_set_input"];
	/** aggregate stddev on columns */
["voyce_series_donations_stddev_fields"]: {
		amount?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_donations_stddev_pop_fields"]: {
		amount?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_donations_stddev_samp_fields"]: {
		amount?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_series_donations" */
["voyce_series_donations_stream_cursor_input"]: GraphQLTypes["voyce_series_donations_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_donations_stream_cursor_value_input"]: GraphQLTypes["voyce_series_donations_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_donations_sum_fields"]: {
		amount?:ModelTypes["numeric"],
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** update columns of table "voyce.series_donations" */
["voyce_series_donations_update_column"]: GraphQLTypes["voyce_series_donations_update_column"];
	["voyce_series_donations_updates"]: GraphQLTypes["voyce_series_donations_updates"];
	/** aggregate var_pop on columns */
["voyce_series_donations_var_pop_fields"]: {
		amount?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_donations_var_samp_fields"]: {
		amount?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_series_donations_variance_fields"]: {
		amount?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.series_favorites" */
["voyce_series_favorites"]: {
		created_at:ModelTypes["timestamptz"],
	id:number,
	/** An object relationship */
	series:ModelTypes["voyce_series"],
	series_id:number,
	user_id:number
};
	/** aggregated selection of "voyce.series_favorites" */
["voyce_series_favorites_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_favorites_aggregate_fields"],
	nodes:ModelTypes["voyce_series_favorites"][]
};
	/** aggregate fields of "voyce.series_favorites" */
["voyce_series_favorites_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_favorites_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_favorites_max_fields"],
	min?:ModelTypes["voyce_series_favorites_min_fields"],
	stddev?:ModelTypes["voyce_series_favorites_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_favorites_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_favorites_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_favorites_sum_fields"],
	var_pop?:ModelTypes["voyce_series_favorites_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_favorites_var_samp_fields"],
	variance?:ModelTypes["voyce_series_favorites_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_favorites_avg_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_favorites". All fields are combined with a logical 'AND'. */
["voyce_series_favorites_bool_exp"]: GraphQLTypes["voyce_series_favorites_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_favorites" */
["voyce_series_favorites_constraint"]: GraphQLTypes["voyce_series_favorites_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_favorites" */
["voyce_series_favorites_inc_input"]: GraphQLTypes["voyce_series_favorites_inc_input"];
	/** input type for inserting data into table "voyce.series_favorites" */
["voyce_series_favorites_insert_input"]: GraphQLTypes["voyce_series_favorites_insert_input"];
	/** aggregate max on columns */
["voyce_series_favorites_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_series_favorites_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** response of any mutation on the table "voyce.series_favorites" */
["voyce_series_favorites_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_favorites"][]
};
	/** on_conflict condition type for table "voyce.series_favorites" */
["voyce_series_favorites_on_conflict"]: GraphQLTypes["voyce_series_favorites_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_favorites". */
["voyce_series_favorites_order_by"]: GraphQLTypes["voyce_series_favorites_order_by"];
	/** primary key columns input for table: voyce.series_favorites */
["voyce_series_favorites_pk_columns_input"]: GraphQLTypes["voyce_series_favorites_pk_columns_input"];
	/** select columns of table "voyce.series_favorites" */
["voyce_series_favorites_select_column"]: GraphQLTypes["voyce_series_favorites_select_column"];
	/** input type for updating data in table "voyce.series_favorites" */
["voyce_series_favorites_set_input"]: GraphQLTypes["voyce_series_favorites_set_input"];
	/** aggregate stddev on columns */
["voyce_series_favorites_stddev_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_favorites_stddev_pop_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_favorites_stddev_samp_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_series_favorites" */
["voyce_series_favorites_stream_cursor_input"]: GraphQLTypes["voyce_series_favorites_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_favorites_stream_cursor_value_input"]: GraphQLTypes["voyce_series_favorites_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_favorites_sum_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** update columns of table "voyce.series_favorites" */
["voyce_series_favorites_update_column"]: GraphQLTypes["voyce_series_favorites_update_column"];
	["voyce_series_favorites_updates"]: GraphQLTypes["voyce_series_favorites_updates"];
	/** aggregate var_pop on columns */
["voyce_series_favorites_var_pop_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_favorites_var_samp_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_series_favorites_variance_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.series_featured_panels" */
["voyce_series_featured_panels"]: {
		id:number,
	image?:string,
	series_id?:number
};
	/** aggregated selection of "voyce.series_featured_panels" */
["voyce_series_featured_panels_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_featured_panels_aggregate_fields"],
	nodes:ModelTypes["voyce_series_featured_panels"][]
};
	["voyce_series_featured_panels_aggregate_bool_exp"]: GraphQLTypes["voyce_series_featured_panels_aggregate_bool_exp"];
	["voyce_series_featured_panels_aggregate_bool_exp_count"]: GraphQLTypes["voyce_series_featured_panels_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.series_featured_panels" */
["voyce_series_featured_panels_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_featured_panels_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_featured_panels_max_fields"],
	min?:ModelTypes["voyce_series_featured_panels_min_fields"],
	stddev?:ModelTypes["voyce_series_featured_panels_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_featured_panels_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_featured_panels_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_featured_panels_sum_fields"],
	var_pop?:ModelTypes["voyce_series_featured_panels_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_featured_panels_var_samp_fields"],
	variance?:ModelTypes["voyce_series_featured_panels_variance_fields"]
};
	/** order by aggregate values of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_aggregate_order_by"]: GraphQLTypes["voyce_series_featured_panels_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.series_featured_panels" */
["voyce_series_featured_panels_arr_rel_insert_input"]: GraphQLTypes["voyce_series_featured_panels_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_series_featured_panels_avg_fields"]: {
		id?:number,
	series_id?:number
};
	/** order by avg() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_avg_order_by"]: GraphQLTypes["voyce_series_featured_panels_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.series_featured_panels". All fields are combined with a logical 'AND'. */
["voyce_series_featured_panels_bool_exp"]: GraphQLTypes["voyce_series_featured_panels_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_featured_panels" */
["voyce_series_featured_panels_constraint"]: GraphQLTypes["voyce_series_featured_panels_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_featured_panels" */
["voyce_series_featured_panels_inc_input"]: GraphQLTypes["voyce_series_featured_panels_inc_input"];
	/** input type for inserting data into table "voyce.series_featured_panels" */
["voyce_series_featured_panels_insert_input"]: GraphQLTypes["voyce_series_featured_panels_insert_input"];
	/** aggregate max on columns */
["voyce_series_featured_panels_max_fields"]: {
		id?:number,
	image?:string,
	series_id?:number
};
	/** order by max() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_max_order_by"]: GraphQLTypes["voyce_series_featured_panels_max_order_by"];
	/** aggregate min on columns */
["voyce_series_featured_panels_min_fields"]: {
		id?:number,
	image?:string,
	series_id?:number
};
	/** order by min() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_min_order_by"]: GraphQLTypes["voyce_series_featured_panels_min_order_by"];
	/** response of any mutation on the table "voyce.series_featured_panels" */
["voyce_series_featured_panels_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_featured_panels"][]
};
	/** on_conflict condition type for table "voyce.series_featured_panels" */
["voyce_series_featured_panels_on_conflict"]: GraphQLTypes["voyce_series_featured_panels_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_featured_panels". */
["voyce_series_featured_panels_order_by"]: GraphQLTypes["voyce_series_featured_panels_order_by"];
	/** primary key columns input for table: voyce.series_featured_panels */
["voyce_series_featured_panels_pk_columns_input"]: GraphQLTypes["voyce_series_featured_panels_pk_columns_input"];
	/** select columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_select_column"]: GraphQLTypes["voyce_series_featured_panels_select_column"];
	/** input type for updating data in table "voyce.series_featured_panels" */
["voyce_series_featured_panels_set_input"]: GraphQLTypes["voyce_series_featured_panels_set_input"];
	/** aggregate stddev on columns */
["voyce_series_featured_panels_stddev_fields"]: {
		id?:number,
	series_id?:number
};
	/** order by stddev() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_stddev_order_by"]: GraphQLTypes["voyce_series_featured_panels_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_series_featured_panels_stddev_pop_fields"]: {
		id?:number,
	series_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_stddev_pop_order_by"]: GraphQLTypes["voyce_series_featured_panels_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_series_featured_panels_stddev_samp_fields"]: {
		id?:number,
	series_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_stddev_samp_order_by"]: GraphQLTypes["voyce_series_featured_panels_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_series_featured_panels" */
["voyce_series_featured_panels_stream_cursor_input"]: GraphQLTypes["voyce_series_featured_panels_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_featured_panels_stream_cursor_value_input"]: GraphQLTypes["voyce_series_featured_panels_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_featured_panels_sum_fields"]: {
		id?:number,
	series_id?:number
};
	/** order by sum() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_sum_order_by"]: GraphQLTypes["voyce_series_featured_panels_sum_order_by"];
	/** update columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_update_column"]: GraphQLTypes["voyce_series_featured_panels_update_column"];
	["voyce_series_featured_panels_updates"]: GraphQLTypes["voyce_series_featured_panels_updates"];
	/** aggregate var_pop on columns */
["voyce_series_featured_panels_var_pop_fields"]: {
		id?:number,
	series_id?:number
};
	/** order by var_pop() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_var_pop_order_by"]: GraphQLTypes["voyce_series_featured_panels_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_series_featured_panels_var_samp_fields"]: {
		id?:number,
	series_id?:number
};
	/** order by var_samp() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_var_samp_order_by"]: GraphQLTypes["voyce_series_featured_panels_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_series_featured_panels_variance_fields"]: {
		id?:number,
	series_id?:number
};
	/** order by variance() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_variance_order_by"]: GraphQLTypes["voyce_series_featured_panels_variance_order_by"];
	/** columns and relationships of "voyce.series_genre" */
["voyce_series_genre"]: {
		created_at:ModelTypes["timestamp"],
	/** An object relationship */
	genre?:ModelTypes["voyce_genres"],
	genre_id:number,
	id:number,
	/** An object relationship */
	series?:ModelTypes["voyce_series"],
	series_id:number,
	sub_genre:boolean
};
	/** aggregated selection of "voyce.series_genre" */
["voyce_series_genre_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_genre_aggregate_fields"],
	nodes:ModelTypes["voyce_series_genre"][]
};
	["voyce_series_genre_aggregate_bool_exp"]: GraphQLTypes["voyce_series_genre_aggregate_bool_exp"];
	["voyce_series_genre_aggregate_bool_exp_bool_and"]: GraphQLTypes["voyce_series_genre_aggregate_bool_exp_bool_and"];
	["voyce_series_genre_aggregate_bool_exp_bool_or"]: GraphQLTypes["voyce_series_genre_aggregate_bool_exp_bool_or"];
	["voyce_series_genre_aggregate_bool_exp_count"]: GraphQLTypes["voyce_series_genre_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.series_genre" */
["voyce_series_genre_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_genre_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_genre_max_fields"],
	min?:ModelTypes["voyce_series_genre_min_fields"],
	stddev?:ModelTypes["voyce_series_genre_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_genre_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_genre_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_genre_sum_fields"],
	var_pop?:ModelTypes["voyce_series_genre_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_genre_var_samp_fields"],
	variance?:ModelTypes["voyce_series_genre_variance_fields"]
};
	/** order by aggregate values of table "voyce.series_genre" */
["voyce_series_genre_aggregate_order_by"]: GraphQLTypes["voyce_series_genre_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.series_genre" */
["voyce_series_genre_arr_rel_insert_input"]: GraphQLTypes["voyce_series_genre_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_series_genre_avg_fields"]: {
		genre_id?:number,
	id?:number,
	series_id?:number
};
	/** order by avg() on columns of table "voyce.series_genre" */
["voyce_series_genre_avg_order_by"]: GraphQLTypes["voyce_series_genre_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.series_genre". All fields are combined with a logical 'AND'. */
["voyce_series_genre_bool_exp"]: GraphQLTypes["voyce_series_genre_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_genre" */
["voyce_series_genre_constraint"]: GraphQLTypes["voyce_series_genre_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_genre" */
["voyce_series_genre_inc_input"]: GraphQLTypes["voyce_series_genre_inc_input"];
	/** input type for inserting data into table "voyce.series_genre" */
["voyce_series_genre_insert_input"]: GraphQLTypes["voyce_series_genre_insert_input"];
	/** aggregate max on columns */
["voyce_series_genre_max_fields"]: {
		created_at?:ModelTypes["timestamp"],
	genre_id?:number,
	id?:number,
	series_id?:number
};
	/** order by max() on columns of table "voyce.series_genre" */
["voyce_series_genre_max_order_by"]: GraphQLTypes["voyce_series_genre_max_order_by"];
	/** aggregate min on columns */
["voyce_series_genre_min_fields"]: {
		created_at?:ModelTypes["timestamp"],
	genre_id?:number,
	id?:number,
	series_id?:number
};
	/** order by min() on columns of table "voyce.series_genre" */
["voyce_series_genre_min_order_by"]: GraphQLTypes["voyce_series_genre_min_order_by"];
	/** response of any mutation on the table "voyce.series_genre" */
["voyce_series_genre_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_genre"][]
};
	/** on_conflict condition type for table "voyce.series_genre" */
["voyce_series_genre_on_conflict"]: GraphQLTypes["voyce_series_genre_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_genre". */
["voyce_series_genre_order_by"]: GraphQLTypes["voyce_series_genre_order_by"];
	/** primary key columns input for table: voyce.series_genre */
["voyce_series_genre_pk_columns_input"]: GraphQLTypes["voyce_series_genre_pk_columns_input"];
	/** select columns of table "voyce.series_genre" */
["voyce_series_genre_select_column"]: GraphQLTypes["voyce_series_genre_select_column"];
	/** select "voyce_series_genre_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.series_genre" */
["voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_and_arguments_columns"]: GraphQLTypes["voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_and_arguments_columns"];
	/** select "voyce_series_genre_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.series_genre" */
["voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_or_arguments_columns"]: GraphQLTypes["voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_or_arguments_columns"];
	/** input type for updating data in table "voyce.series_genre" */
["voyce_series_genre_set_input"]: GraphQLTypes["voyce_series_genre_set_input"];
	/** aggregate stddev on columns */
["voyce_series_genre_stddev_fields"]: {
		genre_id?:number,
	id?:number,
	series_id?:number
};
	/** order by stddev() on columns of table "voyce.series_genre" */
["voyce_series_genre_stddev_order_by"]: GraphQLTypes["voyce_series_genre_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_series_genre_stddev_pop_fields"]: {
		genre_id?:number,
	id?:number,
	series_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.series_genre" */
["voyce_series_genre_stddev_pop_order_by"]: GraphQLTypes["voyce_series_genre_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_series_genre_stddev_samp_fields"]: {
		genre_id?:number,
	id?:number,
	series_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.series_genre" */
["voyce_series_genre_stddev_samp_order_by"]: GraphQLTypes["voyce_series_genre_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_series_genre" */
["voyce_series_genre_stream_cursor_input"]: GraphQLTypes["voyce_series_genre_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_genre_stream_cursor_value_input"]: GraphQLTypes["voyce_series_genre_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_genre_sum_fields"]: {
		genre_id?:number,
	id?:number,
	series_id?:number
};
	/** order by sum() on columns of table "voyce.series_genre" */
["voyce_series_genre_sum_order_by"]: GraphQLTypes["voyce_series_genre_sum_order_by"];
	/** update columns of table "voyce.series_genre" */
["voyce_series_genre_update_column"]: GraphQLTypes["voyce_series_genre_update_column"];
	["voyce_series_genre_updates"]: GraphQLTypes["voyce_series_genre_updates"];
	/** aggregate var_pop on columns */
["voyce_series_genre_var_pop_fields"]: {
		genre_id?:number,
	id?:number,
	series_id?:number
};
	/** order by var_pop() on columns of table "voyce.series_genre" */
["voyce_series_genre_var_pop_order_by"]: GraphQLTypes["voyce_series_genre_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_series_genre_var_samp_fields"]: {
		genre_id?:number,
	id?:number,
	series_id?:number
};
	/** order by var_samp() on columns of table "voyce.series_genre" */
["voyce_series_genre_var_samp_order_by"]: GraphQLTypes["voyce_series_genre_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_series_genre_variance_fields"]: {
		genre_id?:number,
	id?:number,
	series_id?:number
};
	/** order by variance() on columns of table "voyce.series_genre" */
["voyce_series_genre_variance_order_by"]: GraphQLTypes["voyce_series_genre_variance_order_by"];
	/** columns and relationships of "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants"]: {
		created_at:ModelTypes["timestamptz"],
	email:string,
	first_name:string,
	from_series:boolean,
	id:number,
	last_name:string,
	series_id:number
};
	/** aggregated selection of "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_giveaway_participants_aggregate_fields"],
	nodes:ModelTypes["voyce_series_giveaway_participants"][]
};
	/** aggregate fields of "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_giveaway_participants_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_giveaway_participants_max_fields"],
	min?:ModelTypes["voyce_series_giveaway_participants_min_fields"],
	stddev?:ModelTypes["voyce_series_giveaway_participants_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_giveaway_participants_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_giveaway_participants_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_giveaway_participants_sum_fields"],
	var_pop?:ModelTypes["voyce_series_giveaway_participants_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_giveaway_participants_var_samp_fields"],
	variance?:ModelTypes["voyce_series_giveaway_participants_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_giveaway_participants_avg_fields"]: {
		id?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_giveaway_participants". All fields are combined with a logical 'AND'. */
["voyce_series_giveaway_participants_bool_exp"]: GraphQLTypes["voyce_series_giveaway_participants_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_constraint"]: GraphQLTypes["voyce_series_giveaway_participants_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_inc_input"]: GraphQLTypes["voyce_series_giveaway_participants_inc_input"];
	/** input type for inserting data into table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_insert_input"]: GraphQLTypes["voyce_series_giveaway_participants_insert_input"];
	/** aggregate max on columns */
["voyce_series_giveaway_participants_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	email?:string,
	first_name?:string,
	id?:number,
	last_name?:string,
	series_id?:number
};
	/** aggregate min on columns */
["voyce_series_giveaway_participants_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	email?:string,
	first_name?:string,
	id?:number,
	last_name?:string,
	series_id?:number
};
	/** response of any mutation on the table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_giveaway_participants"][]
};
	/** on_conflict condition type for table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_on_conflict"]: GraphQLTypes["voyce_series_giveaway_participants_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_giveaway_participants". */
["voyce_series_giveaway_participants_order_by"]: GraphQLTypes["voyce_series_giveaway_participants_order_by"];
	/** primary key columns input for table: voyce.series_giveaway_participants */
["voyce_series_giveaway_participants_pk_columns_input"]: GraphQLTypes["voyce_series_giveaway_participants_pk_columns_input"];
	/** select columns of table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_select_column"]: GraphQLTypes["voyce_series_giveaway_participants_select_column"];
	/** input type for updating data in table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_set_input"]: GraphQLTypes["voyce_series_giveaway_participants_set_input"];
	/** aggregate stddev on columns */
["voyce_series_giveaway_participants_stddev_fields"]: {
		id?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_giveaway_participants_stddev_pop_fields"]: {
		id?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_giveaway_participants_stddev_samp_fields"]: {
		id?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_series_giveaway_participants" */
["voyce_series_giveaway_participants_stream_cursor_input"]: GraphQLTypes["voyce_series_giveaway_participants_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_giveaway_participants_stream_cursor_value_input"]: GraphQLTypes["voyce_series_giveaway_participants_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_giveaway_participants_sum_fields"]: {
		id?:number,
	series_id?:number
};
	/** update columns of table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_update_column"]: GraphQLTypes["voyce_series_giveaway_participants_update_column"];
	["voyce_series_giveaway_participants_updates"]: GraphQLTypes["voyce_series_giveaway_participants_updates"];
	/** aggregate var_pop on columns */
["voyce_series_giveaway_participants_var_pop_fields"]: {
		id?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_giveaway_participants_var_samp_fields"]: {
		id?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_series_giveaway_participants_variance_fields"]: {
		id?:number,
	series_id?:number
};
	/** columns and relationships of "voyce.series_hot_panels" */
["voyce_series_hot_panels"]: {
		active:boolean,
	created_at:ModelTypes["timestamptz"],
	id:number,
	image:string,
	order:ModelTypes["smallint"],
	series_id:number,
	spoiler:boolean,
	updated_at:ModelTypes["timestamptz"],
	user_id:number
};
	/** aggregated selection of "voyce.series_hot_panels" */
["voyce_series_hot_panels_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_hot_panels_aggregate_fields"],
	nodes:ModelTypes["voyce_series_hot_panels"][]
};
	["voyce_series_hot_panels_aggregate_bool_exp"]: GraphQLTypes["voyce_series_hot_panels_aggregate_bool_exp"];
	["voyce_series_hot_panels_aggregate_bool_exp_bool_and"]: GraphQLTypes["voyce_series_hot_panels_aggregate_bool_exp_bool_and"];
	["voyce_series_hot_panels_aggregate_bool_exp_bool_or"]: GraphQLTypes["voyce_series_hot_panels_aggregate_bool_exp_bool_or"];
	["voyce_series_hot_panels_aggregate_bool_exp_count"]: GraphQLTypes["voyce_series_hot_panels_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.series_hot_panels" */
["voyce_series_hot_panels_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_hot_panels_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_hot_panels_max_fields"],
	min?:ModelTypes["voyce_series_hot_panels_min_fields"],
	stddev?:ModelTypes["voyce_series_hot_panels_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_hot_panels_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_hot_panels_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_hot_panels_sum_fields"],
	var_pop?:ModelTypes["voyce_series_hot_panels_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_hot_panels_var_samp_fields"],
	variance?:ModelTypes["voyce_series_hot_panels_variance_fields"]
};
	/** order by aggregate values of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_aggregate_order_by"]: GraphQLTypes["voyce_series_hot_panels_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.series_hot_panels" */
["voyce_series_hot_panels_arr_rel_insert_input"]: GraphQLTypes["voyce_series_hot_panels_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_series_hot_panels_avg_fields"]: {
		id?:number,
	order?:number,
	series_id?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_avg_order_by"]: GraphQLTypes["voyce_series_hot_panels_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.series_hot_panels". All fields are combined with a logical 'AND'. */
["voyce_series_hot_panels_bool_exp"]: GraphQLTypes["voyce_series_hot_panels_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_hot_panels" */
["voyce_series_hot_panels_constraint"]: GraphQLTypes["voyce_series_hot_panels_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_hot_panels" */
["voyce_series_hot_panels_inc_input"]: GraphQLTypes["voyce_series_hot_panels_inc_input"];
	/** input type for inserting data into table "voyce.series_hot_panels" */
["voyce_series_hot_panels_insert_input"]: GraphQLTypes["voyce_series_hot_panels_insert_input"];
	/** aggregate max on columns */
["voyce_series_hot_panels_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	image?:string,
	order?:ModelTypes["smallint"],
	series_id?:number,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number
};
	/** order by max() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_max_order_by"]: GraphQLTypes["voyce_series_hot_panels_max_order_by"];
	/** aggregate min on columns */
["voyce_series_hot_panels_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	image?:string,
	order?:ModelTypes["smallint"],
	series_id?:number,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number
};
	/** order by min() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_min_order_by"]: GraphQLTypes["voyce_series_hot_panels_min_order_by"];
	/** response of any mutation on the table "voyce.series_hot_panels" */
["voyce_series_hot_panels_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_hot_panels"][]
};
	/** on_conflict condition type for table "voyce.series_hot_panels" */
["voyce_series_hot_panels_on_conflict"]: GraphQLTypes["voyce_series_hot_panels_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_hot_panels". */
["voyce_series_hot_panels_order_by"]: GraphQLTypes["voyce_series_hot_panels_order_by"];
	/** primary key columns input for table: voyce.series_hot_panels */
["voyce_series_hot_panels_pk_columns_input"]: GraphQLTypes["voyce_series_hot_panels_pk_columns_input"];
	/** select columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_select_column"]: GraphQLTypes["voyce_series_hot_panels_select_column"];
	/** select "voyce_series_hot_panels_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_and_arguments_columns"]: GraphQLTypes["voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_and_arguments_columns"];
	/** select "voyce_series_hot_panels_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_or_arguments_columns"]: GraphQLTypes["voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_or_arguments_columns"];
	/** input type for updating data in table "voyce.series_hot_panels" */
["voyce_series_hot_panels_set_input"]: GraphQLTypes["voyce_series_hot_panels_set_input"];
	/** aggregate stddev on columns */
["voyce_series_hot_panels_stddev_fields"]: {
		id?:number,
	order?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_stddev_order_by"]: GraphQLTypes["voyce_series_hot_panels_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_series_hot_panels_stddev_pop_fields"]: {
		id?:number,
	order?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_stddev_pop_order_by"]: GraphQLTypes["voyce_series_hot_panels_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_series_hot_panels_stddev_samp_fields"]: {
		id?:number,
	order?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_stddev_samp_order_by"]: GraphQLTypes["voyce_series_hot_panels_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_series_hot_panels" */
["voyce_series_hot_panels_stream_cursor_input"]: GraphQLTypes["voyce_series_hot_panels_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_hot_panels_stream_cursor_value_input"]: GraphQLTypes["voyce_series_hot_panels_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_hot_panels_sum_fields"]: {
		id?:number,
	order?:ModelTypes["smallint"],
	series_id?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_sum_order_by"]: GraphQLTypes["voyce_series_hot_panels_sum_order_by"];
	/** update columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_update_column"]: GraphQLTypes["voyce_series_hot_panels_update_column"];
	["voyce_series_hot_panels_updates"]: GraphQLTypes["voyce_series_hot_panels_updates"];
	/** aggregate var_pop on columns */
["voyce_series_hot_panels_var_pop_fields"]: {
		id?:number,
	order?:number,
	series_id?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_var_pop_order_by"]: GraphQLTypes["voyce_series_hot_panels_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_series_hot_panels_var_samp_fields"]: {
		id?:number,
	order?:number,
	series_id?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_var_samp_order_by"]: GraphQLTypes["voyce_series_hot_panels_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_series_hot_panels_variance_fields"]: {
		id?:number,
	order?:number,
	series_id?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_variance_order_by"]: GraphQLTypes["voyce_series_hot_panels_variance_order_by"];
	/** input type for incrementing numeric columns in table "voyce.series" */
["voyce_series_inc_input"]: GraphQLTypes["voyce_series_inc_input"];
	/** input type for inserting data into table "voyce.series" */
["voyce_series_insert_input"]: GraphQLTypes["voyce_series_insert_input"];
	/** columns and relationships of "voyce.series_layouts" */
["voyce_series_layouts"]: {
		active:boolean,
	bg_image:string,
	characters:ModelTypes["json"],
	comments:ModelTypes["json"],
	dl_banner:ModelTypes["json"],
	id:number,
	layout:ModelTypes["smallint"],
	logo:string,
	menu:ModelTypes["json"],
	series_id:number,
	socials:ModelTypes["json"],
	story:ModelTypes["json"]
};
	/** aggregated selection of "voyce.series_layouts" */
["voyce_series_layouts_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_layouts_aggregate_fields"],
	nodes:ModelTypes["voyce_series_layouts"][]
};
	/** aggregate fields of "voyce.series_layouts" */
["voyce_series_layouts_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_layouts_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_layouts_max_fields"],
	min?:ModelTypes["voyce_series_layouts_min_fields"],
	stddev?:ModelTypes["voyce_series_layouts_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_layouts_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_layouts_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_layouts_sum_fields"],
	var_pop?:ModelTypes["voyce_series_layouts_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_layouts_var_samp_fields"],
	variance?:ModelTypes["voyce_series_layouts_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_layouts_avg_fields"]: {
		id?:number,
	layout?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_layouts". All fields are combined with a logical 'AND'. */
["voyce_series_layouts_bool_exp"]: GraphQLTypes["voyce_series_layouts_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_layouts" */
["voyce_series_layouts_constraint"]: GraphQLTypes["voyce_series_layouts_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_layouts" */
["voyce_series_layouts_inc_input"]: GraphQLTypes["voyce_series_layouts_inc_input"];
	/** input type for inserting data into table "voyce.series_layouts" */
["voyce_series_layouts_insert_input"]: GraphQLTypes["voyce_series_layouts_insert_input"];
	/** aggregate max on columns */
["voyce_series_layouts_max_fields"]: {
		bg_image?:string,
	id?:number,
	layout?:ModelTypes["smallint"],
	logo?:string,
	series_id?:number
};
	/** aggregate min on columns */
["voyce_series_layouts_min_fields"]: {
		bg_image?:string,
	id?:number,
	layout?:ModelTypes["smallint"],
	logo?:string,
	series_id?:number
};
	/** response of any mutation on the table "voyce.series_layouts" */
["voyce_series_layouts_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_layouts"][]
};
	/** on_conflict condition type for table "voyce.series_layouts" */
["voyce_series_layouts_on_conflict"]: GraphQLTypes["voyce_series_layouts_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_layouts". */
["voyce_series_layouts_order_by"]: GraphQLTypes["voyce_series_layouts_order_by"];
	/** primary key columns input for table: voyce.series_layouts */
["voyce_series_layouts_pk_columns_input"]: GraphQLTypes["voyce_series_layouts_pk_columns_input"];
	/** select columns of table "voyce.series_layouts" */
["voyce_series_layouts_select_column"]: GraphQLTypes["voyce_series_layouts_select_column"];
	/** input type for updating data in table "voyce.series_layouts" */
["voyce_series_layouts_set_input"]: GraphQLTypes["voyce_series_layouts_set_input"];
	/** aggregate stddev on columns */
["voyce_series_layouts_stddev_fields"]: {
		id?:number,
	layout?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_layouts_stddev_pop_fields"]: {
		id?:number,
	layout?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_layouts_stddev_samp_fields"]: {
		id?:number,
	layout?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_series_layouts" */
["voyce_series_layouts_stream_cursor_input"]: GraphQLTypes["voyce_series_layouts_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_layouts_stream_cursor_value_input"]: GraphQLTypes["voyce_series_layouts_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_layouts_sum_fields"]: {
		id?:number,
	layout?:ModelTypes["smallint"],
	series_id?:number
};
	/** update columns of table "voyce.series_layouts" */
["voyce_series_layouts_update_column"]: GraphQLTypes["voyce_series_layouts_update_column"];
	["voyce_series_layouts_updates"]: GraphQLTypes["voyce_series_layouts_updates"];
	/** aggregate var_pop on columns */
["voyce_series_layouts_var_pop_fields"]: {
		id?:number,
	layout?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_layouts_var_samp_fields"]: {
		id?:number,
	layout?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_series_layouts_variance_fields"]: {
		id?:number,
	layout?:number,
	series_id?:number
};
	/** columns and relationships of "voyce.series_leaderboard" */
["voyce_series_leaderboard"]: {
		amount:ModelTypes["numeric"],
	arrow?:string,
	created_at:ModelTypes["timestamptz"],
	currency:string,
	date:ModelTypes["date"],
	id:number,
	rank:number,
	series_id:number,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.series_leaderboard" */
["voyce_series_leaderboard_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_leaderboard_aggregate_fields"],
	nodes:ModelTypes["voyce_series_leaderboard"][]
};
	/** aggregate fields of "voyce.series_leaderboard" */
["voyce_series_leaderboard_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_leaderboard_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_leaderboard_max_fields"],
	min?:ModelTypes["voyce_series_leaderboard_min_fields"],
	stddev?:ModelTypes["voyce_series_leaderboard_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_leaderboard_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_leaderboard_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_leaderboard_sum_fields"],
	var_pop?:ModelTypes["voyce_series_leaderboard_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_leaderboard_var_samp_fields"],
	variance?:ModelTypes["voyce_series_leaderboard_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_leaderboard_avg_fields"]: {
		amount?:number,
	id?:number,
	rank?:number,
	series_id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_leaderboard". All fields are combined with a logical 'AND'. */
["voyce_series_leaderboard_bool_exp"]: GraphQLTypes["voyce_series_leaderboard_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_leaderboard" */
["voyce_series_leaderboard_constraint"]: GraphQLTypes["voyce_series_leaderboard_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_leaderboard" */
["voyce_series_leaderboard_inc_input"]: GraphQLTypes["voyce_series_leaderboard_inc_input"];
	/** input type for inserting data into table "voyce.series_leaderboard" */
["voyce_series_leaderboard_insert_input"]: GraphQLTypes["voyce_series_leaderboard_insert_input"];
	/** aggregate max on columns */
["voyce_series_leaderboard_max_fields"]: {
		amount?:ModelTypes["numeric"],
	arrow?:string,
	created_at?:ModelTypes["timestamptz"],
	currency?:string,
	date?:ModelTypes["date"],
	id?:number,
	rank?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_series_leaderboard_min_fields"]: {
		amount?:ModelTypes["numeric"],
	arrow?:string,
	created_at?:ModelTypes["timestamptz"],
	currency?:string,
	date?:ModelTypes["date"],
	id?:number,
	rank?:number,
	series_id?:number,
	user_id?:number
};
	/** response of any mutation on the table "voyce.series_leaderboard" */
["voyce_series_leaderboard_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_leaderboard"][]
};
	/** on_conflict condition type for table "voyce.series_leaderboard" */
["voyce_series_leaderboard_on_conflict"]: GraphQLTypes["voyce_series_leaderboard_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_leaderboard". */
["voyce_series_leaderboard_order_by"]: GraphQLTypes["voyce_series_leaderboard_order_by"];
	/** primary key columns input for table: voyce.series_leaderboard */
["voyce_series_leaderboard_pk_columns_input"]: GraphQLTypes["voyce_series_leaderboard_pk_columns_input"];
	/** select columns of table "voyce.series_leaderboard" */
["voyce_series_leaderboard_select_column"]: GraphQLTypes["voyce_series_leaderboard_select_column"];
	/** input type for updating data in table "voyce.series_leaderboard" */
["voyce_series_leaderboard_set_input"]: GraphQLTypes["voyce_series_leaderboard_set_input"];
	/** aggregate stddev on columns */
["voyce_series_leaderboard_stddev_fields"]: {
		amount?:number,
	id?:number,
	rank?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_leaderboard_stddev_pop_fields"]: {
		amount?:number,
	id?:number,
	rank?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_leaderboard_stddev_samp_fields"]: {
		amount?:number,
	id?:number,
	rank?:number,
	series_id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_series_leaderboard" */
["voyce_series_leaderboard_stream_cursor_input"]: GraphQLTypes["voyce_series_leaderboard_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_leaderboard_stream_cursor_value_input"]: GraphQLTypes["voyce_series_leaderboard_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_leaderboard_sum_fields"]: {
		amount?:ModelTypes["numeric"],
	id?:number,
	rank?:number,
	series_id?:number,
	user_id?:number
};
	/** update columns of table "voyce.series_leaderboard" */
["voyce_series_leaderboard_update_column"]: GraphQLTypes["voyce_series_leaderboard_update_column"];
	["voyce_series_leaderboard_updates"]: GraphQLTypes["voyce_series_leaderboard_updates"];
	/** aggregate var_pop on columns */
["voyce_series_leaderboard_var_pop_fields"]: {
		amount?:number,
	id?:number,
	rank?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_leaderboard_var_samp_fields"]: {
		amount?:number,
	id?:number,
	rank?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_series_leaderboard_variance_fields"]: {
		amount?:number,
	id?:number,
	rank?:number,
	series_id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.series_likes_count" */
["voyce_series_likes_count"]: {
		count?:ModelTypes["bigint"],
	series_id?:number
};
	/** aggregated selection of "voyce.series_likes_count" */
["voyce_series_likes_count_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_likes_count_aggregate_fields"],
	nodes:ModelTypes["voyce_series_likes_count"][]
};
	/** aggregate fields of "voyce.series_likes_count" */
["voyce_series_likes_count_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_likes_count_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_likes_count_max_fields"],
	min?:ModelTypes["voyce_series_likes_count_min_fields"],
	stddev?:ModelTypes["voyce_series_likes_count_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_likes_count_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_likes_count_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_likes_count_sum_fields"],
	var_pop?:ModelTypes["voyce_series_likes_count_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_likes_count_var_samp_fields"],
	variance?:ModelTypes["voyce_series_likes_count_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_likes_count_avg_fields"]: {
		count?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_likes_count". All fields are combined with a logical 'AND'. */
["voyce_series_likes_count_bool_exp"]: GraphQLTypes["voyce_series_likes_count_bool_exp"];
	/** columns and relationships of "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat"]: {
		actual_count?:ModelTypes["bigint"],
	count?:ModelTypes["float8"],
	series_id?:number
};
	/** aggregated selection of "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_likes_count_mat_aggregate_fields"],
	nodes:ModelTypes["voyce_series_likes_count_mat"][]
};
	/** aggregate fields of "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_likes_count_mat_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_likes_count_mat_max_fields"],
	min?:ModelTypes["voyce_series_likes_count_mat_min_fields"],
	stddev?:ModelTypes["voyce_series_likes_count_mat_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_likes_count_mat_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_likes_count_mat_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_likes_count_mat_sum_fields"],
	var_pop?:ModelTypes["voyce_series_likes_count_mat_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_likes_count_mat_var_samp_fields"],
	variance?:ModelTypes["voyce_series_likes_count_mat_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_likes_count_mat_avg_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_likes_count_mat". All fields are combined with a logical 'AND'. */
["voyce_series_likes_count_mat_bool_exp"]: GraphQLTypes["voyce_series_likes_count_mat_bool_exp"];
	/** input type for inserting data into table "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat_insert_input"]: GraphQLTypes["voyce_series_likes_count_mat_insert_input"];
	/** aggregate max on columns */
["voyce_series_likes_count_mat_max_fields"]: {
		actual_count?:ModelTypes["bigint"],
	count?:ModelTypes["float8"],
	series_id?:number
};
	/** aggregate min on columns */
["voyce_series_likes_count_mat_min_fields"]: {
		actual_count?:ModelTypes["bigint"],
	count?:ModelTypes["float8"],
	series_id?:number
};
	/** input type for inserting object relation for remote table "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat_obj_rel_insert_input"]: GraphQLTypes["voyce_series_likes_count_mat_obj_rel_insert_input"];
	/** Ordering options when selecting data from "voyce.series_likes_count_mat". */
["voyce_series_likes_count_mat_order_by"]: GraphQLTypes["voyce_series_likes_count_mat_order_by"];
	/** select columns of table "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat_select_column"]: GraphQLTypes["voyce_series_likes_count_mat_select_column"];
	/** aggregate stddev on columns */
["voyce_series_likes_count_mat_stddev_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_likes_count_mat_stddev_pop_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_likes_count_mat_stddev_samp_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_series_likes_count_mat" */
["voyce_series_likes_count_mat_stream_cursor_input"]: GraphQLTypes["voyce_series_likes_count_mat_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_likes_count_mat_stream_cursor_value_input"]: GraphQLTypes["voyce_series_likes_count_mat_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_likes_count_mat_sum_fields"]: {
		actual_count?:ModelTypes["bigint"],
	count?:ModelTypes["float8"],
	series_id?:number
};
	/** aggregate var_pop on columns */
["voyce_series_likes_count_mat_var_pop_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_likes_count_mat_var_samp_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_series_likes_count_mat_variance_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate max on columns */
["voyce_series_likes_count_max_fields"]: {
		count?:ModelTypes["bigint"],
	series_id?:number
};
	/** aggregate min on columns */
["voyce_series_likes_count_min_fields"]: {
		count?:ModelTypes["bigint"],
	series_id?:number
};
	/** Ordering options when selecting data from "voyce.series_likes_count". */
["voyce_series_likes_count_order_by"]: GraphQLTypes["voyce_series_likes_count_order_by"];
	/** select columns of table "voyce.series_likes_count" */
["voyce_series_likes_count_select_column"]: GraphQLTypes["voyce_series_likes_count_select_column"];
	/** aggregate stddev on columns */
["voyce_series_likes_count_stddev_fields"]: {
		count?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_likes_count_stddev_pop_fields"]: {
		count?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_likes_count_stddev_samp_fields"]: {
		count?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_series_likes_count" */
["voyce_series_likes_count_stream_cursor_input"]: GraphQLTypes["voyce_series_likes_count_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_likes_count_stream_cursor_value_input"]: GraphQLTypes["voyce_series_likes_count_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_likes_count_sum_fields"]: {
		count?:ModelTypes["bigint"],
	series_id?:number
};
	/** aggregate var_pop on columns */
["voyce_series_likes_count_var_pop_fields"]: {
		count?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_likes_count_var_samp_fields"]: {
		count?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_series_likes_count_variance_fields"]: {
		count?:number,
	series_id?:number
};
	/** columns and relationships of "voyce.series_lock_config" */
["voyce_series_lock_config"]: {
		buy_currency?:string,
	buy_price?:ModelTypes["numeric"],
	created_at:ModelTypes["timestamptz"],
	id:number,
	lock_duration:ModelTypes["numeric"],
	lock_latest_chapters:number,
	rent_currency?:string,
	rent_duration?:ModelTypes["numeric"],
	rent_price?:ModelTypes["numeric"],
	series_id:number,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.series_lock_config" */
["voyce_series_lock_config_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_lock_config_aggregate_fields"],
	nodes:ModelTypes["voyce_series_lock_config"][]
};
	/** aggregate fields of "voyce.series_lock_config" */
["voyce_series_lock_config_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_lock_config_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_lock_config_max_fields"],
	min?:ModelTypes["voyce_series_lock_config_min_fields"],
	stddev?:ModelTypes["voyce_series_lock_config_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_lock_config_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_lock_config_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_lock_config_sum_fields"],
	var_pop?:ModelTypes["voyce_series_lock_config_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_lock_config_var_samp_fields"],
	variance?:ModelTypes["voyce_series_lock_config_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_lock_config_avg_fields"]: {
		buy_price?:number,
	id?:number,
	lock_duration?:number,
	lock_latest_chapters?:number,
	rent_duration?:number,
	rent_price?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_lock_config". All fields are combined with a logical 'AND'. */
["voyce_series_lock_config_bool_exp"]: GraphQLTypes["voyce_series_lock_config_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_lock_config" */
["voyce_series_lock_config_constraint"]: GraphQLTypes["voyce_series_lock_config_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_lock_config" */
["voyce_series_lock_config_inc_input"]: GraphQLTypes["voyce_series_lock_config_inc_input"];
	/** input type for inserting data into table "voyce.series_lock_config" */
["voyce_series_lock_config_insert_input"]: GraphQLTypes["voyce_series_lock_config_insert_input"];
	/** aggregate max on columns */
["voyce_series_lock_config_max_fields"]: {
		buy_currency?:string,
	buy_price?:ModelTypes["numeric"],
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	lock_duration?:ModelTypes["numeric"],
	lock_latest_chapters?:number,
	rent_currency?:string,
	rent_duration?:ModelTypes["numeric"],
	rent_price?:ModelTypes["numeric"],
	series_id?:number,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_series_lock_config_min_fields"]: {
		buy_currency?:string,
	buy_price?:ModelTypes["numeric"],
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	lock_duration?:ModelTypes["numeric"],
	lock_latest_chapters?:number,
	rent_currency?:string,
	rent_duration?:ModelTypes["numeric"],
	rent_price?:ModelTypes["numeric"],
	series_id?:number,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.series_lock_config" */
["voyce_series_lock_config_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_lock_config"][]
};
	/** input type for inserting object relation for remote table "voyce.series_lock_config" */
["voyce_series_lock_config_obj_rel_insert_input"]: GraphQLTypes["voyce_series_lock_config_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.series_lock_config" */
["voyce_series_lock_config_on_conflict"]: GraphQLTypes["voyce_series_lock_config_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_lock_config". */
["voyce_series_lock_config_order_by"]: GraphQLTypes["voyce_series_lock_config_order_by"];
	/** primary key columns input for table: voyce.series_lock_config */
["voyce_series_lock_config_pk_columns_input"]: GraphQLTypes["voyce_series_lock_config_pk_columns_input"];
	/** select columns of table "voyce.series_lock_config" */
["voyce_series_lock_config_select_column"]: GraphQLTypes["voyce_series_lock_config_select_column"];
	/** input type for updating data in table "voyce.series_lock_config" */
["voyce_series_lock_config_set_input"]: GraphQLTypes["voyce_series_lock_config_set_input"];
	/** aggregate stddev on columns */
["voyce_series_lock_config_stddev_fields"]: {
		buy_price?:number,
	id?:number,
	lock_duration?:number,
	lock_latest_chapters?:number,
	rent_duration?:number,
	rent_price?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_lock_config_stddev_pop_fields"]: {
		buy_price?:number,
	id?:number,
	lock_duration?:number,
	lock_latest_chapters?:number,
	rent_duration?:number,
	rent_price?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_lock_config_stddev_samp_fields"]: {
		buy_price?:number,
	id?:number,
	lock_duration?:number,
	lock_latest_chapters?:number,
	rent_duration?:number,
	rent_price?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_series_lock_config" */
["voyce_series_lock_config_stream_cursor_input"]: GraphQLTypes["voyce_series_lock_config_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_lock_config_stream_cursor_value_input"]: GraphQLTypes["voyce_series_lock_config_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_lock_config_sum_fields"]: {
		buy_price?:ModelTypes["numeric"],
	id?:number,
	lock_duration?:ModelTypes["numeric"],
	lock_latest_chapters?:number,
	rent_duration?:ModelTypes["numeric"],
	rent_price?:ModelTypes["numeric"],
	series_id?:number
};
	/** update columns of table "voyce.series_lock_config" */
["voyce_series_lock_config_update_column"]: GraphQLTypes["voyce_series_lock_config_update_column"];
	["voyce_series_lock_config_updates"]: GraphQLTypes["voyce_series_lock_config_updates"];
	/** aggregate var_pop on columns */
["voyce_series_lock_config_var_pop_fields"]: {
		buy_price?:number,
	id?:number,
	lock_duration?:number,
	lock_latest_chapters?:number,
	rent_duration?:number,
	rent_price?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_lock_config_var_samp_fields"]: {
		buy_price?:number,
	id?:number,
	lock_duration?:number,
	lock_latest_chapters?:number,
	rent_duration?:number,
	rent_price?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_series_lock_config_variance_fields"]: {
		buy_price?:number,
	id?:number,
	lock_duration?:number,
	lock_latest_chapters?:number,
	rent_duration?:number,
	rent_price?:number,
	series_id?:number
};
	/** aggregate max on columns */
["voyce_series_max_fields"]: {
		animated_cover_image?:string,
	average_rating?:ModelTypes["float8"],
	back_image?:string,
	banner_bg?:string,
	chapter_count?:number,
	chapters_hide?:string,
	character_cover?:string,
	coin_price?:ModelTypes["smallint"],
	comic_type_id?:number,
	cover_image?:string,
	cover_image_mobile?:string,
	created_at?:ModelTypes["timestamp"],
	default_view?:string,
	description?:string,
	frequency?:string,
	frequency_mask?:number,
	front_image?:string,
	id?:number,
	is_approved?:ModelTypes["smallint"],
	is_intro?:ModelTypes["smallint"],
	is_original?:ModelTypes["smallint"],
	is_pilots?:ModelTypes["smallint"],
	is_recommended?:ModelTypes["smallint"],
	klaviyo_id?:string,
	klaviyo_list_id?:string,
	latest_chapter_update?:ModelTypes["timestamp"],
	locked_chapters_amount?:number,
	pilots_votes_count?:number,
	portfolio_desc?:string,
	publish?:ModelTypes["smallint"],
	rating_count?:number,
	reading_view?:string,
	short_desc?:string,
	slug?:string,
	status?:string,
	subscriber_count?:number,
	support_msg?:string,
	thumbnail?:string,
	thumbnail_box?:string,
	title?:string,
	trending_position?:number,
	updated_at?:ModelTypes["timestamp"],
	user_display_field?:string,
	user_id?:number,
	view_count?:number,
	welcome_msg?:string
};
	/** aggregate min on columns */
["voyce_series_min_fields"]: {
		animated_cover_image?:string,
	average_rating?:ModelTypes["float8"],
	back_image?:string,
	banner_bg?:string,
	chapter_count?:number,
	chapters_hide?:string,
	character_cover?:string,
	coin_price?:ModelTypes["smallint"],
	comic_type_id?:number,
	cover_image?:string,
	cover_image_mobile?:string,
	created_at?:ModelTypes["timestamp"],
	default_view?:string,
	description?:string,
	frequency?:string,
	frequency_mask?:number,
	front_image?:string,
	id?:number,
	is_approved?:ModelTypes["smallint"],
	is_intro?:ModelTypes["smallint"],
	is_original?:ModelTypes["smallint"],
	is_pilots?:ModelTypes["smallint"],
	is_recommended?:ModelTypes["smallint"],
	klaviyo_id?:string,
	klaviyo_list_id?:string,
	latest_chapter_update?:ModelTypes["timestamp"],
	locked_chapters_amount?:number,
	pilots_votes_count?:number,
	portfolio_desc?:string,
	publish?:ModelTypes["smallint"],
	rating_count?:number,
	reading_view?:string,
	short_desc?:string,
	slug?:string,
	status?:string,
	subscriber_count?:number,
	support_msg?:string,
	thumbnail?:string,
	thumbnail_box?:string,
	title?:string,
	trending_position?:number,
	updated_at?:ModelTypes["timestamp"],
	user_display_field?:string,
	user_id?:number,
	view_count?:number,
	welcome_msg?:string
};
	/** response of any mutation on the table "voyce.series" */
["voyce_series_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series"][]
};
	/** input type for inserting object relation for remote table "voyce.series" */
["voyce_series_obj_rel_insert_input"]: GraphQLTypes["voyce_series_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.series" */
["voyce_series_on_conflict"]: GraphQLTypes["voyce_series_on_conflict"];
	/** Ordering options when selecting data from "voyce.series". */
["voyce_series_order_by"]: GraphQLTypes["voyce_series_order_by"];
	/** primary key columns input for table: voyce.series */
["voyce_series_pk_columns_input"]: GraphQLTypes["voyce_series_pk_columns_input"];
	/** select columns of table "voyce.series" */
["voyce_series_select_column"]: GraphQLTypes["voyce_series_select_column"];
	/** input type for updating data in table "voyce.series" */
["voyce_series_set_input"]: GraphQLTypes["voyce_series_set_input"];
	/** columns and relationships of "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration"]: {
		enabled:boolean,
	id:number,
	message?:string,
	/** An object relationship */
	series?:ModelTypes["voyce_series"],
	series_id?:number,
	voyceme_payee:boolean
};
	/** aggregated selection of "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_sponsorships_configuration_aggregate_fields"],
	nodes:ModelTypes["voyce_series_sponsorships_configuration"][]
};
	/** aggregate fields of "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_sponsorships_configuration_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_sponsorships_configuration_max_fields"],
	min?:ModelTypes["voyce_series_sponsorships_configuration_min_fields"],
	stddev?:ModelTypes["voyce_series_sponsorships_configuration_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_sponsorships_configuration_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_sponsorships_configuration_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_sponsorships_configuration_sum_fields"],
	var_pop?:ModelTypes["voyce_series_sponsorships_configuration_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_sponsorships_configuration_var_samp_fields"],
	variance?:ModelTypes["voyce_series_sponsorships_configuration_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_sponsorships_configuration_avg_fields"]: {
		id?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_sponsorships_configuration". All fields are combined with a logical 'AND'. */
["voyce_series_sponsorships_configuration_bool_exp"]: GraphQLTypes["voyce_series_sponsorships_configuration_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_constraint"]: GraphQLTypes["voyce_series_sponsorships_configuration_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_inc_input"]: GraphQLTypes["voyce_series_sponsorships_configuration_inc_input"];
	/** input type for inserting data into table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_insert_input"]: GraphQLTypes["voyce_series_sponsorships_configuration_insert_input"];
	/** aggregate max on columns */
["voyce_series_sponsorships_configuration_max_fields"]: {
		id?:number,
	message?:string,
	series_id?:number
};
	/** aggregate min on columns */
["voyce_series_sponsorships_configuration_min_fields"]: {
		id?:number,
	message?:string,
	series_id?:number
};
	/** response of any mutation on the table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_sponsorships_configuration"][]
};
	/** input type for inserting object relation for remote table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_obj_rel_insert_input"]: GraphQLTypes["voyce_series_sponsorships_configuration_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_on_conflict"]: GraphQLTypes["voyce_series_sponsorships_configuration_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_sponsorships_configuration". */
["voyce_series_sponsorships_configuration_order_by"]: GraphQLTypes["voyce_series_sponsorships_configuration_order_by"];
	/** primary key columns input for table: voyce.series_sponsorships_configuration */
["voyce_series_sponsorships_configuration_pk_columns_input"]: GraphQLTypes["voyce_series_sponsorships_configuration_pk_columns_input"];
	/** select columns of table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_select_column"]: GraphQLTypes["voyce_series_sponsorships_configuration_select_column"];
	/** input type for updating data in table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_set_input"]: GraphQLTypes["voyce_series_sponsorships_configuration_set_input"];
	/** aggregate stddev on columns */
["voyce_series_sponsorships_configuration_stddev_fields"]: {
		id?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_sponsorships_configuration_stddev_pop_fields"]: {
		id?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_sponsorships_configuration_stddev_samp_fields"]: {
		id?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_stream_cursor_input"]: GraphQLTypes["voyce_series_sponsorships_configuration_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_sponsorships_configuration_stream_cursor_value_input"]: GraphQLTypes["voyce_series_sponsorships_configuration_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_sponsorships_configuration_sum_fields"]: {
		id?:number,
	series_id?:number
};
	/** update columns of table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_update_column"]: GraphQLTypes["voyce_series_sponsorships_configuration_update_column"];
	["voyce_series_sponsorships_configuration_updates"]: GraphQLTypes["voyce_series_sponsorships_configuration_updates"];
	/** aggregate var_pop on columns */
["voyce_series_sponsorships_configuration_var_pop_fields"]: {
		id?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_sponsorships_configuration_var_samp_fields"]: {
		id?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_series_sponsorships_configuration_variance_fields"]: {
		id?:number,
	series_id?:number
};
	/** aggregate stddev on columns */
["voyce_series_stddev_fields"]: {
		average_rating?:number,
	chapter_count?:number,
	coin_price?:number,
	comic_type_id?:number,
	frequency_mask?:number,
	id?:number,
	is_approved?:number,
	is_intro?:number,
	is_original?:number,
	is_pilots?:number,
	is_recommended?:number,
	locked_chapters_amount?:number,
	pilots_votes_count?:number,
	publish?:number,
	rating_count?:number,
	subscriber_count?:number,
	trending_position?:number,
	user_id?:number,
	view_count?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_stddev_pop_fields"]: {
		average_rating?:number,
	chapter_count?:number,
	coin_price?:number,
	comic_type_id?:number,
	frequency_mask?:number,
	id?:number,
	is_approved?:number,
	is_intro?:number,
	is_original?:number,
	is_pilots?:number,
	is_recommended?:number,
	locked_chapters_amount?:number,
	pilots_votes_count?:number,
	publish?:number,
	rating_count?:number,
	subscriber_count?:number,
	trending_position?:number,
	user_id?:number,
	view_count?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_stddev_samp_fields"]: {
		average_rating?:number,
	chapter_count?:number,
	coin_price?:number,
	comic_type_id?:number,
	frequency_mask?:number,
	id?:number,
	is_approved?:number,
	is_intro?:number,
	is_original?:number,
	is_pilots?:number,
	is_recommended?:number,
	locked_chapters_amount?:number,
	pilots_votes_count?:number,
	publish?:number,
	rating_count?:number,
	subscriber_count?:number,
	trending_position?:number,
	user_id?:number,
	view_count?:number
};
	/** Streaming cursor of the table "voyce_series" */
["voyce_series_stream_cursor_input"]: GraphQLTypes["voyce_series_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_stream_cursor_value_input"]: GraphQLTypes["voyce_series_stream_cursor_value_input"];
	/** columns and relationships of "voyce.series_subscription" */
["voyce_series_subscription"]: {
		created_at:ModelTypes["timestamp"],
	id:number,
	klaviyo_id?:string,
	/** An object relationship */
	series?:ModelTypes["voyce_series"],
	series_id:number,
	/** An object relationship */
	user?:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.series_subscription" */
["voyce_series_subscription_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_subscription_aggregate_fields"],
	nodes:ModelTypes["voyce_series_subscription"][]
};
	["voyce_series_subscription_aggregate_bool_exp"]: GraphQLTypes["voyce_series_subscription_aggregate_bool_exp"];
	["voyce_series_subscription_aggregate_bool_exp_count"]: GraphQLTypes["voyce_series_subscription_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.series_subscription" */
["voyce_series_subscription_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_subscription_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_subscription_max_fields"],
	min?:ModelTypes["voyce_series_subscription_min_fields"],
	stddev?:ModelTypes["voyce_series_subscription_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_subscription_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_subscription_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_subscription_sum_fields"],
	var_pop?:ModelTypes["voyce_series_subscription_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_subscription_var_samp_fields"],
	variance?:ModelTypes["voyce_series_subscription_variance_fields"]
};
	/** order by aggregate values of table "voyce.series_subscription" */
["voyce_series_subscription_aggregate_order_by"]: GraphQLTypes["voyce_series_subscription_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.series_subscription" */
["voyce_series_subscription_arr_rel_insert_input"]: GraphQLTypes["voyce_series_subscription_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_series_subscription_avg_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_avg_order_by"]: GraphQLTypes["voyce_series_subscription_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.series_subscription". All fields are combined with a logical 'AND'. */
["voyce_series_subscription_bool_exp"]: GraphQLTypes["voyce_series_subscription_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_subscription" */
["voyce_series_subscription_constraint"]: GraphQLTypes["voyce_series_subscription_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_subscription" */
["voyce_series_subscription_inc_input"]: GraphQLTypes["voyce_series_subscription_inc_input"];
	/** input type for inserting data into table "voyce.series_subscription" */
["voyce_series_subscription_insert_input"]: GraphQLTypes["voyce_series_subscription_insert_input"];
	/** aggregate max on columns */
["voyce_series_subscription_max_fields"]: {
		created_at?:ModelTypes["timestamp"],
	id?:number,
	klaviyo_id?:string,
	series_id?:number,
	user_id?:number
};
	/** order by max() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_max_order_by"]: GraphQLTypes["voyce_series_subscription_max_order_by"];
	/** aggregate min on columns */
["voyce_series_subscription_min_fields"]: {
		created_at?:ModelTypes["timestamp"],
	id?:number,
	klaviyo_id?:string,
	series_id?:number,
	user_id?:number
};
	/** order by min() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_min_order_by"]: GraphQLTypes["voyce_series_subscription_min_order_by"];
	/** response of any mutation on the table "voyce.series_subscription" */
["voyce_series_subscription_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_subscription"][]
};
	/** on_conflict condition type for table "voyce.series_subscription" */
["voyce_series_subscription_on_conflict"]: GraphQLTypes["voyce_series_subscription_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_subscription". */
["voyce_series_subscription_order_by"]: GraphQLTypes["voyce_series_subscription_order_by"];
	/** primary key columns input for table: voyce.series_subscription */
["voyce_series_subscription_pk_columns_input"]: GraphQLTypes["voyce_series_subscription_pk_columns_input"];
	/** select columns of table "voyce.series_subscription" */
["voyce_series_subscription_select_column"]: GraphQLTypes["voyce_series_subscription_select_column"];
	/** input type for updating data in table "voyce.series_subscription" */
["voyce_series_subscription_set_input"]: GraphQLTypes["voyce_series_subscription_set_input"];
	/** aggregate stddev on columns */
["voyce_series_subscription_stddev_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_stddev_order_by"]: GraphQLTypes["voyce_series_subscription_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_series_subscription_stddev_pop_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_stddev_pop_order_by"]: GraphQLTypes["voyce_series_subscription_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_series_subscription_stddev_samp_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_stddev_samp_order_by"]: GraphQLTypes["voyce_series_subscription_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_series_subscription" */
["voyce_series_subscription_stream_cursor_input"]: GraphQLTypes["voyce_series_subscription_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_subscription_stream_cursor_value_input"]: GraphQLTypes["voyce_series_subscription_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_subscription_sum_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_sum_order_by"]: GraphQLTypes["voyce_series_subscription_sum_order_by"];
	/** update columns of table "voyce.series_subscription" */
["voyce_series_subscription_update_column"]: GraphQLTypes["voyce_series_subscription_update_column"];
	["voyce_series_subscription_updates"]: GraphQLTypes["voyce_series_subscription_updates"];
	/** aggregate var_pop on columns */
["voyce_series_subscription_var_pop_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_var_pop_order_by"]: GraphQLTypes["voyce_series_subscription_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_series_subscription_var_samp_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_var_samp_order_by"]: GraphQLTypes["voyce_series_subscription_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_series_subscription_variance_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_variance_order_by"]: GraphQLTypes["voyce_series_subscription_variance_order_by"];
	/** aggregate sum on columns */
["voyce_series_sum_fields"]: {
		average_rating?:ModelTypes["float8"],
	chapter_count?:number,
	coin_price?:ModelTypes["smallint"],
	comic_type_id?:number,
	frequency_mask?:number,
	id?:number,
	is_approved?:ModelTypes["smallint"],
	is_intro?:ModelTypes["smallint"],
	is_original?:ModelTypes["smallint"],
	is_pilots?:ModelTypes["smallint"],
	is_recommended?:ModelTypes["smallint"],
	locked_chapters_amount?:number,
	pilots_votes_count?:number,
	publish?:ModelTypes["smallint"],
	rating_count?:number,
	subscriber_count?:number,
	trending_position?:number,
	user_id?:number,
	view_count?:number
};
	/** This table keeps tags assigned series. */
["voyce_series_tags"]: {
		id:number,
	name:string,
	/** An array relationship */
	series_tags_junctions:ModelTypes["voyce_series_tags_junction"][],
	/** An aggregate relationship */
	series_tags_junctions_aggregate:ModelTypes["voyce_series_tags_junction_aggregate"]
};
	/** aggregated selection of "voyce.series_tags" */
["voyce_series_tags_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_tags_aggregate_fields"],
	nodes:ModelTypes["voyce_series_tags"][]
};
	/** aggregate fields of "voyce.series_tags" */
["voyce_series_tags_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_tags_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_tags_max_fields"],
	min?:ModelTypes["voyce_series_tags_min_fields"],
	stddev?:ModelTypes["voyce_series_tags_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_tags_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_tags_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_tags_sum_fields"],
	var_pop?:ModelTypes["voyce_series_tags_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_tags_var_samp_fields"],
	variance?:ModelTypes["voyce_series_tags_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_tags_avg_fields"]: {
		id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_tags". All fields are combined with a logical 'AND'. */
["voyce_series_tags_bool_exp"]: GraphQLTypes["voyce_series_tags_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_tags" */
["voyce_series_tags_constraint"]: GraphQLTypes["voyce_series_tags_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_tags" */
["voyce_series_tags_inc_input"]: GraphQLTypes["voyce_series_tags_inc_input"];
	/** input type for inserting data into table "voyce.series_tags" */
["voyce_series_tags_insert_input"]: GraphQLTypes["voyce_series_tags_insert_input"];
	/** Many-to-many junction table for series_tags and series */
["voyce_series_tags_junction"]: {
		admin_created:boolean,
	/** An object relationship */
	chapter?:ModelTypes["voyce_chapters"],
	chapter_id?:number,
	id:number,
	images?:ModelTypes["jsonb"],
	order:number,
	/** An object relationship */
	series:ModelTypes["voyce_series"],
	series_id:number,
	/** An object relationship */
	series_tag:ModelTypes["voyce_series_tags"],
	tag_id:number
};
	/** aggregated selection of "voyce.series_tags_junction" */
["voyce_series_tags_junction_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_tags_junction_aggregate_fields"],
	nodes:ModelTypes["voyce_series_tags_junction"][]
};
	["voyce_series_tags_junction_aggregate_bool_exp"]: GraphQLTypes["voyce_series_tags_junction_aggregate_bool_exp"];
	["voyce_series_tags_junction_aggregate_bool_exp_bool_and"]: GraphQLTypes["voyce_series_tags_junction_aggregate_bool_exp_bool_and"];
	["voyce_series_tags_junction_aggregate_bool_exp_bool_or"]: GraphQLTypes["voyce_series_tags_junction_aggregate_bool_exp_bool_or"];
	["voyce_series_tags_junction_aggregate_bool_exp_count"]: GraphQLTypes["voyce_series_tags_junction_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.series_tags_junction" */
["voyce_series_tags_junction_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_tags_junction_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_tags_junction_max_fields"],
	min?:ModelTypes["voyce_series_tags_junction_min_fields"],
	stddev?:ModelTypes["voyce_series_tags_junction_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_tags_junction_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_tags_junction_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_tags_junction_sum_fields"],
	var_pop?:ModelTypes["voyce_series_tags_junction_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_tags_junction_var_samp_fields"],
	variance?:ModelTypes["voyce_series_tags_junction_variance_fields"]
};
	/** order by aggregate values of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_aggregate_order_by"]: GraphQLTypes["voyce_series_tags_junction_aggregate_order_by"];
	/** append existing jsonb value of filtered columns with new jsonb value */
["voyce_series_tags_junction_append_input"]: GraphQLTypes["voyce_series_tags_junction_append_input"];
	/** input type for inserting array relation for remote table "voyce.series_tags_junction" */
["voyce_series_tags_junction_arr_rel_insert_input"]: GraphQLTypes["voyce_series_tags_junction_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_series_tags_junction_avg_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number,
	series_id?:number,
	tag_id?:number
};
	/** order by avg() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_avg_order_by"]: GraphQLTypes["voyce_series_tags_junction_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.series_tags_junction". All fields are combined with a logical 'AND'. */
["voyce_series_tags_junction_bool_exp"]: GraphQLTypes["voyce_series_tags_junction_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_tags_junction" */
["voyce_series_tags_junction_constraint"]: GraphQLTypes["voyce_series_tags_junction_constraint"];
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["voyce_series_tags_junction_delete_at_path_input"]: GraphQLTypes["voyce_series_tags_junction_delete_at_path_input"];
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["voyce_series_tags_junction_delete_elem_input"]: GraphQLTypes["voyce_series_tags_junction_delete_elem_input"];
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["voyce_series_tags_junction_delete_key_input"]: GraphQLTypes["voyce_series_tags_junction_delete_key_input"];
	/** input type for incrementing numeric columns in table "voyce.series_tags_junction" */
["voyce_series_tags_junction_inc_input"]: GraphQLTypes["voyce_series_tags_junction_inc_input"];
	/** input type for inserting data into table "voyce.series_tags_junction" */
["voyce_series_tags_junction_insert_input"]: GraphQLTypes["voyce_series_tags_junction_insert_input"];
	/** aggregate max on columns */
["voyce_series_tags_junction_max_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number,
	series_id?:number,
	tag_id?:number
};
	/** order by max() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_max_order_by"]: GraphQLTypes["voyce_series_tags_junction_max_order_by"];
	/** aggregate min on columns */
["voyce_series_tags_junction_min_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number,
	series_id?:number,
	tag_id?:number
};
	/** order by min() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_min_order_by"]: GraphQLTypes["voyce_series_tags_junction_min_order_by"];
	/** response of any mutation on the table "voyce.series_tags_junction" */
["voyce_series_tags_junction_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_tags_junction"][]
};
	/** on_conflict condition type for table "voyce.series_tags_junction" */
["voyce_series_tags_junction_on_conflict"]: GraphQLTypes["voyce_series_tags_junction_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_tags_junction". */
["voyce_series_tags_junction_order_by"]: GraphQLTypes["voyce_series_tags_junction_order_by"];
	/** primary key columns input for table: voyce.series_tags_junction */
["voyce_series_tags_junction_pk_columns_input"]: GraphQLTypes["voyce_series_tags_junction_pk_columns_input"];
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["voyce_series_tags_junction_prepend_input"]: GraphQLTypes["voyce_series_tags_junction_prepend_input"];
	/** select columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_select_column"]: GraphQLTypes["voyce_series_tags_junction_select_column"];
	/** select "voyce_series_tags_junction_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_and_arguments_columns"]: GraphQLTypes["voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_and_arguments_columns"];
	/** select "voyce_series_tags_junction_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_or_arguments_columns"]: GraphQLTypes["voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_or_arguments_columns"];
	/** input type for updating data in table "voyce.series_tags_junction" */
["voyce_series_tags_junction_set_input"]: GraphQLTypes["voyce_series_tags_junction_set_input"];
	/** aggregate stddev on columns */
["voyce_series_tags_junction_stddev_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number,
	series_id?:number,
	tag_id?:number
};
	/** order by stddev() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_stddev_order_by"]: GraphQLTypes["voyce_series_tags_junction_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_series_tags_junction_stddev_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number,
	series_id?:number,
	tag_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_stddev_pop_order_by"]: GraphQLTypes["voyce_series_tags_junction_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_series_tags_junction_stddev_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number,
	series_id?:number,
	tag_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_stddev_samp_order_by"]: GraphQLTypes["voyce_series_tags_junction_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_series_tags_junction" */
["voyce_series_tags_junction_stream_cursor_input"]: GraphQLTypes["voyce_series_tags_junction_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_tags_junction_stream_cursor_value_input"]: GraphQLTypes["voyce_series_tags_junction_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_tags_junction_sum_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number,
	series_id?:number,
	tag_id?:number
};
	/** order by sum() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_sum_order_by"]: GraphQLTypes["voyce_series_tags_junction_sum_order_by"];
	/** update columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_update_column"]: GraphQLTypes["voyce_series_tags_junction_update_column"];
	["voyce_series_tags_junction_updates"]: GraphQLTypes["voyce_series_tags_junction_updates"];
	/** aggregate var_pop on columns */
["voyce_series_tags_junction_var_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number,
	series_id?:number,
	tag_id?:number
};
	/** order by var_pop() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_var_pop_order_by"]: GraphQLTypes["voyce_series_tags_junction_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_series_tags_junction_var_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number,
	series_id?:number,
	tag_id?:number
};
	/** order by var_samp() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_var_samp_order_by"]: GraphQLTypes["voyce_series_tags_junction_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_series_tags_junction_variance_fields"]: {
		chapter_id?:number,
	id?:number,
	order?:number,
	series_id?:number,
	tag_id?:number
};
	/** order by variance() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_variance_order_by"]: GraphQLTypes["voyce_series_tags_junction_variance_order_by"];
	/** aggregate max on columns */
["voyce_series_tags_max_fields"]: {
		id?:number,
	name?:string
};
	/** aggregate min on columns */
["voyce_series_tags_min_fields"]: {
		id?:number,
	name?:string
};
	/** response of any mutation on the table "voyce.series_tags" */
["voyce_series_tags_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_tags"][]
};
	/** input type for inserting object relation for remote table "voyce.series_tags" */
["voyce_series_tags_obj_rel_insert_input"]: GraphQLTypes["voyce_series_tags_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.series_tags" */
["voyce_series_tags_on_conflict"]: GraphQLTypes["voyce_series_tags_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_tags". */
["voyce_series_tags_order_by"]: GraphQLTypes["voyce_series_tags_order_by"];
	/** primary key columns input for table: voyce.series_tags */
["voyce_series_tags_pk_columns_input"]: GraphQLTypes["voyce_series_tags_pk_columns_input"];
	/** select columns of table "voyce.series_tags" */
["voyce_series_tags_select_column"]: GraphQLTypes["voyce_series_tags_select_column"];
	/** input type for updating data in table "voyce.series_tags" */
["voyce_series_tags_set_input"]: GraphQLTypes["voyce_series_tags_set_input"];
	/** aggregate stddev on columns */
["voyce_series_tags_stddev_fields"]: {
		id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_tags_stddev_pop_fields"]: {
		id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_tags_stddev_samp_fields"]: {
		id?:number
};
	/** Streaming cursor of the table "voyce_series_tags" */
["voyce_series_tags_stream_cursor_input"]: GraphQLTypes["voyce_series_tags_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_tags_stream_cursor_value_input"]: GraphQLTypes["voyce_series_tags_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_tags_sum_fields"]: {
		id?:number
};
	/** update columns of table "voyce.series_tags" */
["voyce_series_tags_update_column"]: GraphQLTypes["voyce_series_tags_update_column"];
	["voyce_series_tags_updates"]: GraphQLTypes["voyce_series_tags_updates"];
	/** aggregate var_pop on columns */
["voyce_series_tags_var_pop_fields"]: {
		id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_tags_var_samp_fields"]: {
		id?:number
};
	/** aggregate variance on columns */
["voyce_series_tags_variance_fields"]: {
		id?:number
};
	/** columns and relationships of "voyce.series_top_comments" */
["voyce_series_top_comments"]: {
		/** An object relationship */
	comment?:ModelTypes["voyce_chapter_comments"],
	comment_id:number,
	count:number,
	created_at:ModelTypes["timestamptz"],
	id:number,
	series_id:number,
	tag:string,
	type:string
};
	/** aggregated selection of "voyce.series_top_comments" */
["voyce_series_top_comments_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_top_comments_aggregate_fields"],
	nodes:ModelTypes["voyce_series_top_comments"][]
};
	/** aggregate fields of "voyce.series_top_comments" */
["voyce_series_top_comments_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_top_comments_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_top_comments_max_fields"],
	min?:ModelTypes["voyce_series_top_comments_min_fields"],
	stddev?:ModelTypes["voyce_series_top_comments_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_top_comments_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_top_comments_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_top_comments_sum_fields"],
	var_pop?:ModelTypes["voyce_series_top_comments_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_top_comments_var_samp_fields"],
	variance?:ModelTypes["voyce_series_top_comments_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_top_comments_avg_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_top_comments". All fields are combined with a logical 'AND'. */
["voyce_series_top_comments_bool_exp"]: GraphQLTypes["voyce_series_top_comments_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_top_comments" */
["voyce_series_top_comments_constraint"]: GraphQLTypes["voyce_series_top_comments_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_top_comments" */
["voyce_series_top_comments_inc_input"]: GraphQLTypes["voyce_series_top_comments_inc_input"];
	/** input type for inserting data into table "voyce.series_top_comments" */
["voyce_series_top_comments_insert_input"]: GraphQLTypes["voyce_series_top_comments_insert_input"];
	/** aggregate max on columns */
["voyce_series_top_comments_max_fields"]: {
		comment_id?:number,
	count?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	series_id?:number,
	tag?:string,
	type?:string
};
	/** aggregate min on columns */
["voyce_series_top_comments_min_fields"]: {
		comment_id?:number,
	count?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	series_id?:number,
	tag?:string,
	type?:string
};
	/** response of any mutation on the table "voyce.series_top_comments" */
["voyce_series_top_comments_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_top_comments"][]
};
	/** on_conflict condition type for table "voyce.series_top_comments" */
["voyce_series_top_comments_on_conflict"]: GraphQLTypes["voyce_series_top_comments_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_top_comments". */
["voyce_series_top_comments_order_by"]: GraphQLTypes["voyce_series_top_comments_order_by"];
	/** primary key columns input for table: voyce.series_top_comments */
["voyce_series_top_comments_pk_columns_input"]: GraphQLTypes["voyce_series_top_comments_pk_columns_input"];
	/** select columns of table "voyce.series_top_comments" */
["voyce_series_top_comments_select_column"]: GraphQLTypes["voyce_series_top_comments_select_column"];
	/** input type for updating data in table "voyce.series_top_comments" */
["voyce_series_top_comments_set_input"]: GraphQLTypes["voyce_series_top_comments_set_input"];
	/** aggregate stddev on columns */
["voyce_series_top_comments_stddev_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_top_comments_stddev_pop_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_top_comments_stddev_samp_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_series_top_comments" */
["voyce_series_top_comments_stream_cursor_input"]: GraphQLTypes["voyce_series_top_comments_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_top_comments_stream_cursor_value_input"]: GraphQLTypes["voyce_series_top_comments_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_top_comments_sum_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	series_id?:number
};
	/** update columns of table "voyce.series_top_comments" */
["voyce_series_top_comments_update_column"]: GraphQLTypes["voyce_series_top_comments_update_column"];
	["voyce_series_top_comments_updates"]: GraphQLTypes["voyce_series_top_comments_updates"];
	/** aggregate var_pop on columns */
["voyce_series_top_comments_var_pop_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_top_comments_var_samp_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_series_top_comments_variance_fields"]: {
		comment_id?:number,
	count?:number,
	id?:number,
	series_id?:number
};
	/** columns and relationships of "voyce.series_top_likes" */
["voyce_series_top_likes"]: {
		count:number,
	created_at:ModelTypes["timestamptz"],
	id:number,
	is_original:ModelTypes["smallint"],
	/** An object relationship */
	series:ModelTypes["voyce_series"],
	series_id:number,
	type:string
};
	/** aggregated selection of "voyce.series_top_likes" */
["voyce_series_top_likes_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_top_likes_aggregate_fields"],
	nodes:ModelTypes["voyce_series_top_likes"][]
};
	/** aggregate fields of "voyce.series_top_likes" */
["voyce_series_top_likes_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_top_likes_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_top_likes_max_fields"],
	min?:ModelTypes["voyce_series_top_likes_min_fields"],
	stddev?:ModelTypes["voyce_series_top_likes_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_top_likes_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_top_likes_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_top_likes_sum_fields"],
	var_pop?:ModelTypes["voyce_series_top_likes_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_top_likes_var_samp_fields"],
	variance?:ModelTypes["voyce_series_top_likes_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_top_likes_avg_fields"]: {
		count?:number,
	id?:number,
	is_original?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_top_likes". All fields are combined with a logical 'AND'. */
["voyce_series_top_likes_bool_exp"]: GraphQLTypes["voyce_series_top_likes_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_top_likes" */
["voyce_series_top_likes_constraint"]: GraphQLTypes["voyce_series_top_likes_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_top_likes" */
["voyce_series_top_likes_inc_input"]: GraphQLTypes["voyce_series_top_likes_inc_input"];
	/** input type for inserting data into table "voyce.series_top_likes" */
["voyce_series_top_likes_insert_input"]: GraphQLTypes["voyce_series_top_likes_insert_input"];
	/** aggregate max on columns */
["voyce_series_top_likes_max_fields"]: {
		count?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	is_original?:ModelTypes["smallint"],
	series_id?:number,
	type?:string
};
	/** aggregate min on columns */
["voyce_series_top_likes_min_fields"]: {
		count?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	is_original?:ModelTypes["smallint"],
	series_id?:number,
	type?:string
};
	/** response of any mutation on the table "voyce.series_top_likes" */
["voyce_series_top_likes_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_top_likes"][]
};
	/** on_conflict condition type for table "voyce.series_top_likes" */
["voyce_series_top_likes_on_conflict"]: GraphQLTypes["voyce_series_top_likes_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_top_likes". */
["voyce_series_top_likes_order_by"]: GraphQLTypes["voyce_series_top_likes_order_by"];
	/** primary key columns input for table: voyce.series_top_likes */
["voyce_series_top_likes_pk_columns_input"]: GraphQLTypes["voyce_series_top_likes_pk_columns_input"];
	/** select columns of table "voyce.series_top_likes" */
["voyce_series_top_likes_select_column"]: GraphQLTypes["voyce_series_top_likes_select_column"];
	/** input type for updating data in table "voyce.series_top_likes" */
["voyce_series_top_likes_set_input"]: GraphQLTypes["voyce_series_top_likes_set_input"];
	/** aggregate stddev on columns */
["voyce_series_top_likes_stddev_fields"]: {
		count?:number,
	id?:number,
	is_original?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_top_likes_stddev_pop_fields"]: {
		count?:number,
	id?:number,
	is_original?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_top_likes_stddev_samp_fields"]: {
		count?:number,
	id?:number,
	is_original?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_series_top_likes" */
["voyce_series_top_likes_stream_cursor_input"]: GraphQLTypes["voyce_series_top_likes_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_top_likes_stream_cursor_value_input"]: GraphQLTypes["voyce_series_top_likes_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_top_likes_sum_fields"]: {
		count?:number,
	id?:number,
	is_original?:ModelTypes["smallint"],
	series_id?:number
};
	/** update columns of table "voyce.series_top_likes" */
["voyce_series_top_likes_update_column"]: GraphQLTypes["voyce_series_top_likes_update_column"];
	["voyce_series_top_likes_updates"]: GraphQLTypes["voyce_series_top_likes_updates"];
	/** aggregate var_pop on columns */
["voyce_series_top_likes_var_pop_fields"]: {
		count?:number,
	id?:number,
	is_original?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_top_likes_var_samp_fields"]: {
		count?:number,
	id?:number,
	is_original?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_series_top_likes_variance_fields"]: {
		count?:number,
	id?:number,
	is_original?:number,
	series_id?:number
};
	/** columns and relationships of "voyce.series_top_views" */
["voyce_series_top_views"]: {
		comic_type?:string,
	count:number,
	created_at:ModelTypes["timestamptz"],
	id:number,
	prev_rank:number,
	rank:number,
	/** An object relationship */
	series:ModelTypes["voyce_series"],
	series_id:number,
	tag?:string,
	type:string
};
	/** aggregated selection of "voyce.series_top_views" */
["voyce_series_top_views_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_top_views_aggregate_fields"],
	nodes:ModelTypes["voyce_series_top_views"][]
};
	/** aggregate fields of "voyce.series_top_views" */
["voyce_series_top_views_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_top_views_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_top_views_max_fields"],
	min?:ModelTypes["voyce_series_top_views_min_fields"],
	stddev?:ModelTypes["voyce_series_top_views_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_top_views_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_top_views_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_top_views_sum_fields"],
	var_pop?:ModelTypes["voyce_series_top_views_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_top_views_var_samp_fields"],
	variance?:ModelTypes["voyce_series_top_views_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_top_views_avg_fields"]: {
		count?:number,
	id?:number,
	prev_rank?:number,
	rank?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_top_views". All fields are combined with a logical 'AND'. */
["voyce_series_top_views_bool_exp"]: GraphQLTypes["voyce_series_top_views_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_top_views" */
["voyce_series_top_views_constraint"]: GraphQLTypes["voyce_series_top_views_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_top_views" */
["voyce_series_top_views_inc_input"]: GraphQLTypes["voyce_series_top_views_inc_input"];
	/** input type for inserting data into table "voyce.series_top_views" */
["voyce_series_top_views_insert_input"]: GraphQLTypes["voyce_series_top_views_insert_input"];
	/** aggregate max on columns */
["voyce_series_top_views_max_fields"]: {
		comic_type?:string,
	count?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	prev_rank?:number,
	rank?:number,
	series_id?:number,
	tag?:string,
	type?:string
};
	/** aggregate min on columns */
["voyce_series_top_views_min_fields"]: {
		comic_type?:string,
	count?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	prev_rank?:number,
	rank?:number,
	series_id?:number,
	tag?:string,
	type?:string
};
	/** response of any mutation on the table "voyce.series_top_views" */
["voyce_series_top_views_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_top_views"][]
};
	/** on_conflict condition type for table "voyce.series_top_views" */
["voyce_series_top_views_on_conflict"]: GraphQLTypes["voyce_series_top_views_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_top_views". */
["voyce_series_top_views_order_by"]: GraphQLTypes["voyce_series_top_views_order_by"];
	/** primary key columns input for table: voyce.series_top_views */
["voyce_series_top_views_pk_columns_input"]: GraphQLTypes["voyce_series_top_views_pk_columns_input"];
	/** select columns of table "voyce.series_top_views" */
["voyce_series_top_views_select_column"]: GraphQLTypes["voyce_series_top_views_select_column"];
	/** input type for updating data in table "voyce.series_top_views" */
["voyce_series_top_views_set_input"]: GraphQLTypes["voyce_series_top_views_set_input"];
	/** aggregate stddev on columns */
["voyce_series_top_views_stddev_fields"]: {
		count?:number,
	id?:number,
	prev_rank?:number,
	rank?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_top_views_stddev_pop_fields"]: {
		count?:number,
	id?:number,
	prev_rank?:number,
	rank?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_top_views_stddev_samp_fields"]: {
		count?:number,
	id?:number,
	prev_rank?:number,
	rank?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_series_top_views" */
["voyce_series_top_views_stream_cursor_input"]: GraphQLTypes["voyce_series_top_views_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_top_views_stream_cursor_value_input"]: GraphQLTypes["voyce_series_top_views_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_top_views_sum_fields"]: {
		count?:number,
	id?:number,
	prev_rank?:number,
	rank?:number,
	series_id?:number
};
	/** update columns of table "voyce.series_top_views" */
["voyce_series_top_views_update_column"]: GraphQLTypes["voyce_series_top_views_update_column"];
	["voyce_series_top_views_updates"]: GraphQLTypes["voyce_series_top_views_updates"];
	/** aggregate var_pop on columns */
["voyce_series_top_views_var_pop_fields"]: {
		count?:number,
	id?:number,
	prev_rank?:number,
	rank?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_top_views_var_samp_fields"]: {
		count?:number,
	id?:number,
	prev_rank?:number,
	rank?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_series_top_views_variance_fields"]: {
		count?:number,
	id?:number,
	prev_rank?:number,
	rank?:number,
	series_id?:number
};
	/** update columns of table "voyce.series" */
["voyce_series_update_column"]: GraphQLTypes["voyce_series_update_column"];
	["voyce_series_updates"]: GraphQLTypes["voyce_series_updates"];
	/** aggregate var_pop on columns */
["voyce_series_var_pop_fields"]: {
		average_rating?:number,
	chapter_count?:number,
	coin_price?:number,
	comic_type_id?:number,
	frequency_mask?:number,
	id?:number,
	is_approved?:number,
	is_intro?:number,
	is_original?:number,
	is_pilots?:number,
	is_recommended?:number,
	locked_chapters_amount?:number,
	pilots_votes_count?:number,
	publish?:number,
	rating_count?:number,
	subscriber_count?:number,
	trending_position?:number,
	user_id?:number,
	view_count?:number
};
	/** aggregate var_samp on columns */
["voyce_series_var_samp_fields"]: {
		average_rating?:number,
	chapter_count?:number,
	coin_price?:number,
	comic_type_id?:number,
	frequency_mask?:number,
	id?:number,
	is_approved?:number,
	is_intro?:number,
	is_original?:number,
	is_pilots?:number,
	is_recommended?:number,
	locked_chapters_amount?:number,
	pilots_votes_count?:number,
	publish?:number,
	rating_count?:number,
	subscriber_count?:number,
	trending_position?:number,
	user_id?:number,
	view_count?:number
};
	/** aggregate variance on columns */
["voyce_series_variance_fields"]: {
		average_rating?:number,
	chapter_count?:number,
	coin_price?:number,
	comic_type_id?:number,
	frequency_mask?:number,
	id?:number,
	is_approved?:number,
	is_intro?:number,
	is_original?:number,
	is_pilots?:number,
	is_recommended?:number,
	locked_chapters_amount?:number,
	pilots_votes_count?:number,
	publish?:number,
	rating_count?:number,
	subscriber_count?:number,
	trending_position?:number,
	user_id?:number,
	view_count?:number
};
	/** columns and relationships of "voyce.series_views_adjustment" */
["voyce_series_views_adjustment"]: {
		count:number,
	created_at:ModelTypes["timestamptz"],
	id:number,
	series_id:number,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_views_adjustment_aggregate_fields"],
	nodes:ModelTypes["voyce_series_views_adjustment"][]
};
	/** aggregate fields of "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_views_adjustment_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_views_adjustment_max_fields"],
	min?:ModelTypes["voyce_series_views_adjustment_min_fields"],
	stddev?:ModelTypes["voyce_series_views_adjustment_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_views_adjustment_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_views_adjustment_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_views_adjustment_sum_fields"],
	var_pop?:ModelTypes["voyce_series_views_adjustment_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_views_adjustment_var_samp_fields"],
	variance?:ModelTypes["voyce_series_views_adjustment_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_views_adjustment_avg_fields"]: {
		count?:number,
	id?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_views_adjustment". All fields are combined with a logical 'AND'. */
["voyce_series_views_adjustment_bool_exp"]: GraphQLTypes["voyce_series_views_adjustment_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_constraint"]: GraphQLTypes["voyce_series_views_adjustment_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_inc_input"]: GraphQLTypes["voyce_series_views_adjustment_inc_input"];
	/** input type for inserting data into table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_insert_input"]: GraphQLTypes["voyce_series_views_adjustment_insert_input"];
	/** aggregate max on columns */
["voyce_series_views_adjustment_max_fields"]: {
		count?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	series_id?:number,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_series_views_adjustment_min_fields"]: {
		count?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	series_id?:number,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_views_adjustment"][]
};
	/** on_conflict condition type for table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_on_conflict"]: GraphQLTypes["voyce_series_views_adjustment_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_views_adjustment". */
["voyce_series_views_adjustment_order_by"]: GraphQLTypes["voyce_series_views_adjustment_order_by"];
	/** primary key columns input for table: voyce.series_views_adjustment */
["voyce_series_views_adjustment_pk_columns_input"]: GraphQLTypes["voyce_series_views_adjustment_pk_columns_input"];
	/** select columns of table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_select_column"]: GraphQLTypes["voyce_series_views_adjustment_select_column"];
	/** input type for updating data in table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_set_input"]: GraphQLTypes["voyce_series_views_adjustment_set_input"];
	/** aggregate stddev on columns */
["voyce_series_views_adjustment_stddev_fields"]: {
		count?:number,
	id?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_views_adjustment_stddev_pop_fields"]: {
		count?:number,
	id?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_views_adjustment_stddev_samp_fields"]: {
		count?:number,
	id?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_series_views_adjustment" */
["voyce_series_views_adjustment_stream_cursor_input"]: GraphQLTypes["voyce_series_views_adjustment_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_views_adjustment_stream_cursor_value_input"]: GraphQLTypes["voyce_series_views_adjustment_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_views_adjustment_sum_fields"]: {
		count?:number,
	id?:number,
	series_id?:number
};
	/** update columns of table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_update_column"]: GraphQLTypes["voyce_series_views_adjustment_update_column"];
	["voyce_series_views_adjustment_updates"]: GraphQLTypes["voyce_series_views_adjustment_updates"];
	/** aggregate var_pop on columns */
["voyce_series_views_adjustment_var_pop_fields"]: {
		count?:number,
	id?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_views_adjustment_var_samp_fields"]: {
		count?:number,
	id?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_series_views_adjustment_variance_fields"]: {
		count?:number,
	id?:number,
	series_id?:number
};
	/** columns and relationships of "voyce.series_views_count" */
["voyce_series_views_count"]: {
		count?:ModelTypes["bigint"],
	series_id?:number
};
	/** aggregated selection of "voyce.series_views_count" */
["voyce_series_views_count_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_views_count_aggregate_fields"],
	nodes:ModelTypes["voyce_series_views_count"][]
};
	/** aggregate fields of "voyce.series_views_count" */
["voyce_series_views_count_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_views_count_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_views_count_max_fields"],
	min?:ModelTypes["voyce_series_views_count_min_fields"],
	stddev?:ModelTypes["voyce_series_views_count_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_views_count_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_views_count_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_views_count_sum_fields"],
	var_pop?:ModelTypes["voyce_series_views_count_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_views_count_var_samp_fields"],
	variance?:ModelTypes["voyce_series_views_count_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_views_count_avg_fields"]: {
		count?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_views_count". All fields are combined with a logical 'AND'. */
["voyce_series_views_count_bool_exp"]: GraphQLTypes["voyce_series_views_count_bool_exp"];
	/** columns and relationships of "voyce.series_views_count_mat" */
["voyce_series_views_count_mat"]: {
		actual_count?:ModelTypes["bigint"],
	count?:ModelTypes["float8"],
	series_id?:number
};
	/** aggregated selection of "voyce.series_views_count_mat" */
["voyce_series_views_count_mat_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_views_count_mat_aggregate_fields"],
	nodes:ModelTypes["voyce_series_views_count_mat"][]
};
	/** aggregate fields of "voyce.series_views_count_mat" */
["voyce_series_views_count_mat_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_views_count_mat_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_views_count_mat_max_fields"],
	min?:ModelTypes["voyce_series_views_count_mat_min_fields"],
	stddev?:ModelTypes["voyce_series_views_count_mat_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_views_count_mat_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_views_count_mat_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_views_count_mat_sum_fields"],
	var_pop?:ModelTypes["voyce_series_views_count_mat_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_views_count_mat_var_samp_fields"],
	variance?:ModelTypes["voyce_series_views_count_mat_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_views_count_mat_avg_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_views_count_mat". All fields are combined with a logical 'AND'. */
["voyce_series_views_count_mat_bool_exp"]: GraphQLTypes["voyce_series_views_count_mat_bool_exp"];
	/** aggregate max on columns */
["voyce_series_views_count_mat_max_fields"]: {
		actual_count?:ModelTypes["bigint"],
	count?:ModelTypes["float8"],
	series_id?:number
};
	/** aggregate min on columns */
["voyce_series_views_count_mat_min_fields"]: {
		actual_count?:ModelTypes["bigint"],
	count?:ModelTypes["float8"],
	series_id?:number
};
	/** Ordering options when selecting data from "voyce.series_views_count_mat". */
["voyce_series_views_count_mat_order_by"]: GraphQLTypes["voyce_series_views_count_mat_order_by"];
	/** select columns of table "voyce.series_views_count_mat" */
["voyce_series_views_count_mat_select_column"]: GraphQLTypes["voyce_series_views_count_mat_select_column"];
	/** columns and relationships of "voyce.series_views_count_mat_sep30" */
["voyce_series_views_count_mat_sep30"]: {
		actual_count?:ModelTypes["bigint"],
	count?:ModelTypes["float8"],
	series_id?:number
};
	/** aggregated selection of "voyce.series_views_count_mat_sep30" */
["voyce_series_views_count_mat_sep30_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_views_count_mat_sep30_aggregate_fields"],
	nodes:ModelTypes["voyce_series_views_count_mat_sep30"][]
};
	/** aggregate fields of "voyce.series_views_count_mat_sep30" */
["voyce_series_views_count_mat_sep30_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_views_count_mat_sep30_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_views_count_mat_sep30_max_fields"],
	min?:ModelTypes["voyce_series_views_count_mat_sep30_min_fields"],
	stddev?:ModelTypes["voyce_series_views_count_mat_sep30_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_views_count_mat_sep30_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_views_count_mat_sep30_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_views_count_mat_sep30_sum_fields"],
	var_pop?:ModelTypes["voyce_series_views_count_mat_sep30_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_views_count_mat_sep30_var_samp_fields"],
	variance?:ModelTypes["voyce_series_views_count_mat_sep30_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_views_count_mat_sep30_avg_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_views_count_mat_sep30". All fields are combined with a logical 'AND'. */
["voyce_series_views_count_mat_sep30_bool_exp"]: GraphQLTypes["voyce_series_views_count_mat_sep30_bool_exp"];
	/** aggregate max on columns */
["voyce_series_views_count_mat_sep30_max_fields"]: {
		actual_count?:ModelTypes["bigint"],
	count?:ModelTypes["float8"],
	series_id?:number
};
	/** aggregate min on columns */
["voyce_series_views_count_mat_sep30_min_fields"]: {
		actual_count?:ModelTypes["bigint"],
	count?:ModelTypes["float8"],
	series_id?:number
};
	/** Ordering options when selecting data from "voyce.series_views_count_mat_sep30". */
["voyce_series_views_count_mat_sep30_order_by"]: GraphQLTypes["voyce_series_views_count_mat_sep30_order_by"];
	/** select columns of table "voyce.series_views_count_mat_sep30" */
["voyce_series_views_count_mat_sep30_select_column"]: GraphQLTypes["voyce_series_views_count_mat_sep30_select_column"];
	/** aggregate stddev on columns */
["voyce_series_views_count_mat_sep30_stddev_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_views_count_mat_sep30_stddev_pop_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_views_count_mat_sep30_stddev_samp_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_series_views_count_mat_sep30" */
["voyce_series_views_count_mat_sep30_stream_cursor_input"]: GraphQLTypes["voyce_series_views_count_mat_sep30_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_views_count_mat_sep30_stream_cursor_value_input"]: GraphQLTypes["voyce_series_views_count_mat_sep30_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_views_count_mat_sep30_sum_fields"]: {
		actual_count?:ModelTypes["bigint"],
	count?:ModelTypes["float8"],
	series_id?:number
};
	/** aggregate var_pop on columns */
["voyce_series_views_count_mat_sep30_var_pop_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_views_count_mat_sep30_var_samp_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_series_views_count_mat_sep30_variance_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate stddev on columns */
["voyce_series_views_count_mat_stddev_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_views_count_mat_stddev_pop_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_views_count_mat_stddev_samp_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_series_views_count_mat" */
["voyce_series_views_count_mat_stream_cursor_input"]: GraphQLTypes["voyce_series_views_count_mat_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_views_count_mat_stream_cursor_value_input"]: GraphQLTypes["voyce_series_views_count_mat_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_views_count_mat_sum_fields"]: {
		actual_count?:ModelTypes["bigint"],
	count?:ModelTypes["float8"],
	series_id?:number
};
	/** columns and relationships of "voyce.series_views_count_mat_v2" */
["voyce_series_views_count_mat_v2"]: {
		actual_count?:ModelTypes["bigint"],
	count?:ModelTypes["float8"],
	series_id?:number
};
	/** aggregated selection of "voyce.series_views_count_mat_v2" */
["voyce_series_views_count_mat_v2_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_views_count_mat_v2_aggregate_fields"],
	nodes:ModelTypes["voyce_series_views_count_mat_v2"][]
};
	/** aggregate fields of "voyce.series_views_count_mat_v2" */
["voyce_series_views_count_mat_v2_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_views_count_mat_v2_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_views_count_mat_v2_max_fields"],
	min?:ModelTypes["voyce_series_views_count_mat_v2_min_fields"],
	stddev?:ModelTypes["voyce_series_views_count_mat_v2_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_views_count_mat_v2_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_views_count_mat_v2_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_views_count_mat_v2_sum_fields"],
	var_pop?:ModelTypes["voyce_series_views_count_mat_v2_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_views_count_mat_v2_var_samp_fields"],
	variance?:ModelTypes["voyce_series_views_count_mat_v2_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_views_count_mat_v2_avg_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_views_count_mat_v2". All fields are combined with a logical 'AND'. */
["voyce_series_views_count_mat_v2_bool_exp"]: GraphQLTypes["voyce_series_views_count_mat_v2_bool_exp"];
	/** aggregate max on columns */
["voyce_series_views_count_mat_v2_max_fields"]: {
		actual_count?:ModelTypes["bigint"],
	count?:ModelTypes["float8"],
	series_id?:number
};
	/** aggregate min on columns */
["voyce_series_views_count_mat_v2_min_fields"]: {
		actual_count?:ModelTypes["bigint"],
	count?:ModelTypes["float8"],
	series_id?:number
};
	/** Ordering options when selecting data from "voyce.series_views_count_mat_v2". */
["voyce_series_views_count_mat_v2_order_by"]: GraphQLTypes["voyce_series_views_count_mat_v2_order_by"];
	/** select columns of table "voyce.series_views_count_mat_v2" */
["voyce_series_views_count_mat_v2_select_column"]: GraphQLTypes["voyce_series_views_count_mat_v2_select_column"];
	/** aggregate stddev on columns */
["voyce_series_views_count_mat_v2_stddev_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_views_count_mat_v2_stddev_pop_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_views_count_mat_v2_stddev_samp_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_series_views_count_mat_v2" */
["voyce_series_views_count_mat_v2_stream_cursor_input"]: GraphQLTypes["voyce_series_views_count_mat_v2_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_views_count_mat_v2_stream_cursor_value_input"]: GraphQLTypes["voyce_series_views_count_mat_v2_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_views_count_mat_v2_sum_fields"]: {
		actual_count?:ModelTypes["bigint"],
	count?:ModelTypes["float8"],
	series_id?:number
};
	/** aggregate var_pop on columns */
["voyce_series_views_count_mat_v2_var_pop_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_views_count_mat_v2_var_samp_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_series_views_count_mat_v2_variance_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate var_pop on columns */
["voyce_series_views_count_mat_var_pop_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_views_count_mat_var_samp_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_series_views_count_mat_variance_fields"]: {
		actual_count?:number,
	count?:number,
	series_id?:number
};
	/** aggregate max on columns */
["voyce_series_views_count_max_fields"]: {
		count?:ModelTypes["bigint"],
	series_id?:number
};
	/** aggregate min on columns */
["voyce_series_views_count_min_fields"]: {
		count?:ModelTypes["bigint"],
	series_id?:number
};
	/** Ordering options when selecting data from "voyce.series_views_count". */
["voyce_series_views_count_order_by"]: GraphQLTypes["voyce_series_views_count_order_by"];
	/** select columns of table "voyce.series_views_count" */
["voyce_series_views_count_select_column"]: GraphQLTypes["voyce_series_views_count_select_column"];
	/** aggregate stddev on columns */
["voyce_series_views_count_stddev_fields"]: {
		count?:number,
	series_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_views_count_stddev_pop_fields"]: {
		count?:number,
	series_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_views_count_stddev_samp_fields"]: {
		count?:number,
	series_id?:number
};
	/** Streaming cursor of the table "voyce_series_views_count" */
["voyce_series_views_count_stream_cursor_input"]: GraphQLTypes["voyce_series_views_count_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_views_count_stream_cursor_value_input"]: GraphQLTypes["voyce_series_views_count_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_views_count_sum_fields"]: {
		count?:ModelTypes["bigint"],
	series_id?:number
};
	/** aggregate var_pop on columns */
["voyce_series_views_count_var_pop_fields"]: {
		count?:number,
	series_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_views_count_var_samp_fields"]: {
		count?:number,
	series_id?:number
};
	/** aggregate variance on columns */
["voyce_series_views_count_variance_fields"]: {
		count?:number,
	series_id?:number
};
	/** columns and relationships of "voyce.series_votes" */
["voyce_series_votes"]: {
		created_at:ModelTypes["timestamp"],
	date?:ModelTypes["date"],
	gtag_id?:string,
	id:number,
	series_id?:number,
	tag?:string,
	user_id?:number
};
	/** aggregated selection of "voyce.series_votes" */
["voyce_series_votes_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_votes_aggregate_fields"],
	nodes:ModelTypes["voyce_series_votes"][]
};
	/** aggregate fields of "voyce.series_votes" */
["voyce_series_votes_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_votes_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_votes_max_fields"],
	min?:ModelTypes["voyce_series_votes_min_fields"],
	stddev?:ModelTypes["voyce_series_votes_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_votes_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_votes_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_votes_sum_fields"],
	var_pop?:ModelTypes["voyce_series_votes_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_votes_var_samp_fields"],
	variance?:ModelTypes["voyce_series_votes_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_votes_avg_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_votes". All fields are combined with a logical 'AND'. */
["voyce_series_votes_bool_exp"]: GraphQLTypes["voyce_series_votes_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_votes" */
["voyce_series_votes_constraint"]: GraphQLTypes["voyce_series_votes_constraint"];
	/** columns and relationships of "voyce.series_votes_count" */
["voyce_series_votes_count"]: {
		count:number,
	created_at:ModelTypes["timestamptz"],
	date:ModelTypes["date"],
	id:number,
	series_id?:number,
	tag:string,
	total:number,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.series_votes_count" */
["voyce_series_votes_count_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_votes_count_aggregate_fields"],
	nodes:ModelTypes["voyce_series_votes_count"][]
};
	/** aggregate fields of "voyce.series_votes_count" */
["voyce_series_votes_count_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_votes_count_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_votes_count_max_fields"],
	min?:ModelTypes["voyce_series_votes_count_min_fields"],
	stddev?:ModelTypes["voyce_series_votes_count_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_votes_count_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_votes_count_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_votes_count_sum_fields"],
	var_pop?:ModelTypes["voyce_series_votes_count_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_votes_count_var_samp_fields"],
	variance?:ModelTypes["voyce_series_votes_count_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_votes_count_avg_fields"]: {
		count?:number,
	id?:number,
	series_id?:number,
	total?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_votes_count". All fields are combined with a logical 'AND'. */
["voyce_series_votes_count_bool_exp"]: GraphQLTypes["voyce_series_votes_count_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_votes_count" */
["voyce_series_votes_count_constraint"]: GraphQLTypes["voyce_series_votes_count_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_votes_count" */
["voyce_series_votes_count_inc_input"]: GraphQLTypes["voyce_series_votes_count_inc_input"];
	/** input type for inserting data into table "voyce.series_votes_count" */
["voyce_series_votes_count_insert_input"]: GraphQLTypes["voyce_series_votes_count_insert_input"];
	/** aggregate max on columns */
["voyce_series_votes_count_max_fields"]: {
		count?:number,
	created_at?:ModelTypes["timestamptz"],
	date?:ModelTypes["date"],
	id?:number,
	series_id?:number,
	tag?:string,
	total?:number,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_series_votes_count_min_fields"]: {
		count?:number,
	created_at?:ModelTypes["timestamptz"],
	date?:ModelTypes["date"],
	id?:number,
	series_id?:number,
	tag?:string,
	total?:number,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.series_votes_count" */
["voyce_series_votes_count_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_votes_count"][]
};
	/** on_conflict condition type for table "voyce.series_votes_count" */
["voyce_series_votes_count_on_conflict"]: GraphQLTypes["voyce_series_votes_count_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_votes_count". */
["voyce_series_votes_count_order_by"]: GraphQLTypes["voyce_series_votes_count_order_by"];
	/** primary key columns input for table: voyce.series_votes_count */
["voyce_series_votes_count_pk_columns_input"]: GraphQLTypes["voyce_series_votes_count_pk_columns_input"];
	/** select columns of table "voyce.series_votes_count" */
["voyce_series_votes_count_select_column"]: GraphQLTypes["voyce_series_votes_count_select_column"];
	/** input type for updating data in table "voyce.series_votes_count" */
["voyce_series_votes_count_set_input"]: GraphQLTypes["voyce_series_votes_count_set_input"];
	/** aggregate stddev on columns */
["voyce_series_votes_count_stddev_fields"]: {
		count?:number,
	id?:number,
	series_id?:number,
	total?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_votes_count_stddev_pop_fields"]: {
		count?:number,
	id?:number,
	series_id?:number,
	total?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_votes_count_stddev_samp_fields"]: {
		count?:number,
	id?:number,
	series_id?:number,
	total?:number
};
	/** Streaming cursor of the table "voyce_series_votes_count" */
["voyce_series_votes_count_stream_cursor_input"]: GraphQLTypes["voyce_series_votes_count_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_votes_count_stream_cursor_value_input"]: GraphQLTypes["voyce_series_votes_count_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_votes_count_sum_fields"]: {
		count?:number,
	id?:number,
	series_id?:number,
	total?:number
};
	/** update columns of table "voyce.series_votes_count" */
["voyce_series_votes_count_update_column"]: GraphQLTypes["voyce_series_votes_count_update_column"];
	["voyce_series_votes_count_updates"]: GraphQLTypes["voyce_series_votes_count_updates"];
	/** aggregate var_pop on columns */
["voyce_series_votes_count_var_pop_fields"]: {
		count?:number,
	id?:number,
	series_id?:number,
	total?:number
};
	/** aggregate var_samp on columns */
["voyce_series_votes_count_var_samp_fields"]: {
		count?:number,
	id?:number,
	series_id?:number,
	total?:number
};
	/** aggregate variance on columns */
["voyce_series_votes_count_variance_fields"]: {
		count?:number,
	id?:number,
	series_id?:number,
	total?:number
};
	/** input type for incrementing numeric columns in table "voyce.series_votes" */
["voyce_series_votes_inc_input"]: GraphQLTypes["voyce_series_votes_inc_input"];
	/** input type for inserting data into table "voyce.series_votes" */
["voyce_series_votes_insert_input"]: GraphQLTypes["voyce_series_votes_insert_input"];
	/** aggregate max on columns */
["voyce_series_votes_max_fields"]: {
		created_at?:ModelTypes["timestamp"],
	date?:ModelTypes["date"],
	gtag_id?:string,
	id?:number,
	series_id?:number,
	tag?:string,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_series_votes_min_fields"]: {
		created_at?:ModelTypes["timestamp"],
	date?:ModelTypes["date"],
	gtag_id?:string,
	id?:number,
	series_id?:number,
	tag?:string,
	user_id?:number
};
	/** response of any mutation on the table "voyce.series_votes" */
["voyce_series_votes_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_votes"][]
};
	/** on_conflict condition type for table "voyce.series_votes" */
["voyce_series_votes_on_conflict"]: GraphQLTypes["voyce_series_votes_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_votes". */
["voyce_series_votes_order_by"]: GraphQLTypes["voyce_series_votes_order_by"];
	/** primary key columns input for table: voyce.series_votes */
["voyce_series_votes_pk_columns_input"]: GraphQLTypes["voyce_series_votes_pk_columns_input"];
	/** select columns of table "voyce.series_votes" */
["voyce_series_votes_select_column"]: GraphQLTypes["voyce_series_votes_select_column"];
	/** input type for updating data in table "voyce.series_votes" */
["voyce_series_votes_set_input"]: GraphQLTypes["voyce_series_votes_set_input"];
	/** aggregate stddev on columns */
["voyce_series_votes_stddev_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_votes_stddev_pop_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_votes_stddev_samp_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_series_votes" */
["voyce_series_votes_stream_cursor_input"]: GraphQLTypes["voyce_series_votes_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_votes_stream_cursor_value_input"]: GraphQLTypes["voyce_series_votes_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_votes_sum_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** update columns of table "voyce.series_votes" */
["voyce_series_votes_update_column"]: GraphQLTypes["voyce_series_votes_update_column"];
	["voyce_series_votes_updates"]: GraphQLTypes["voyce_series_votes_updates"];
	/** aggregate var_pop on columns */
["voyce_series_votes_var_pop_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_votes_var_samp_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_series_votes_variance_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.series_voting_schedules" */
["voyce_series_voting_schedules"]: {
		created_at:ModelTypes["timestamptz"],
	desc:string,
	end_date:ModelTypes["timestamptz"],
	id:number,
	start_date:ModelTypes["timestamptz"],
	tag:string,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_aggregate"]: {
		aggregate?:ModelTypes["voyce_series_voting_schedules_aggregate_fields"],
	nodes:ModelTypes["voyce_series_voting_schedules"][]
};
	/** aggregate fields of "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_aggregate_fields"]: {
		avg?:ModelTypes["voyce_series_voting_schedules_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_series_voting_schedules_max_fields"],
	min?:ModelTypes["voyce_series_voting_schedules_min_fields"],
	stddev?:ModelTypes["voyce_series_voting_schedules_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_series_voting_schedules_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_series_voting_schedules_stddev_samp_fields"],
	sum?:ModelTypes["voyce_series_voting_schedules_sum_fields"],
	var_pop?:ModelTypes["voyce_series_voting_schedules_var_pop_fields"],
	var_samp?:ModelTypes["voyce_series_voting_schedules_var_samp_fields"],
	variance?:ModelTypes["voyce_series_voting_schedules_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_voting_schedules_avg_fields"]: {
		id?:number
};
	/** Boolean expression to filter rows from the table "voyce.series_voting_schedules". All fields are combined with a logical 'AND'. */
["voyce_series_voting_schedules_bool_exp"]: GraphQLTypes["voyce_series_voting_schedules_bool_exp"];
	/** unique or primary key constraints on table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_constraint"]: GraphQLTypes["voyce_series_voting_schedules_constraint"];
	/** input type for incrementing numeric columns in table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_inc_input"]: GraphQLTypes["voyce_series_voting_schedules_inc_input"];
	/** input type for inserting data into table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_insert_input"]: GraphQLTypes["voyce_series_voting_schedules_insert_input"];
	/** aggregate max on columns */
["voyce_series_voting_schedules_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	desc?:string,
	end_date?:ModelTypes["timestamptz"],
	id?:number,
	start_date?:ModelTypes["timestamptz"],
	tag?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_series_voting_schedules_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	desc?:string,
	end_date?:ModelTypes["timestamptz"],
	id?:number,
	start_date?:ModelTypes["timestamptz"],
	tag?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_series_voting_schedules"][]
};
	/** on_conflict condition type for table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_on_conflict"]: GraphQLTypes["voyce_series_voting_schedules_on_conflict"];
	/** Ordering options when selecting data from "voyce.series_voting_schedules". */
["voyce_series_voting_schedules_order_by"]: GraphQLTypes["voyce_series_voting_schedules_order_by"];
	/** primary key columns input for table: voyce.series_voting_schedules */
["voyce_series_voting_schedules_pk_columns_input"]: GraphQLTypes["voyce_series_voting_schedules_pk_columns_input"];
	/** select columns of table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_select_column"]: GraphQLTypes["voyce_series_voting_schedules_select_column"];
	/** input type for updating data in table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_set_input"]: GraphQLTypes["voyce_series_voting_schedules_set_input"];
	/** aggregate stddev on columns */
["voyce_series_voting_schedules_stddev_fields"]: {
		id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_series_voting_schedules_stddev_pop_fields"]: {
		id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_series_voting_schedules_stddev_samp_fields"]: {
		id?:number
};
	/** Streaming cursor of the table "voyce_series_voting_schedules" */
["voyce_series_voting_schedules_stream_cursor_input"]: GraphQLTypes["voyce_series_voting_schedules_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_series_voting_schedules_stream_cursor_value_input"]: GraphQLTypes["voyce_series_voting_schedules_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_series_voting_schedules_sum_fields"]: {
		id?:number
};
	/** update columns of table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_update_column"]: GraphQLTypes["voyce_series_voting_schedules_update_column"];
	["voyce_series_voting_schedules_updates"]: GraphQLTypes["voyce_series_voting_schedules_updates"];
	/** aggregate var_pop on columns */
["voyce_series_voting_schedules_var_pop_fields"]: {
		id?:number
};
	/** aggregate var_samp on columns */
["voyce_series_voting_schedules_var_samp_fields"]: {
		id?:number
};
	/** aggregate variance on columns */
["voyce_series_voting_schedules_variance_fields"]: {
		id?:number
};
	/** columns and relationships of "voyce.sponsorships" */
["voyce_sponsorships"]: {
		amount:number,
	created_at:ModelTypes["timestamptz"],
	id:number,
	message?:string,
	/** An object relationship */
	series:ModelTypes["voyce_series"],
	series_id:number,
	/** An object relationship */
	sponsored_user:ModelTypes["voyce_users"],
	sponsored_user_id:number,
	/** An object relationship */
	sponsoring_user:ModelTypes["voyce_users"],
	sponsoring_user_id:number,
	stripe_subscription?:string,
	updated_at:ModelTypes["timestamptz"]
};
	/** aggregated selection of "voyce.sponsorships" */
["voyce_sponsorships_aggregate"]: {
		aggregate?:ModelTypes["voyce_sponsorships_aggregate_fields"],
	nodes:ModelTypes["voyce_sponsorships"][]
};
	["voyce_sponsorships_aggregate_bool_exp"]: GraphQLTypes["voyce_sponsorships_aggregate_bool_exp"];
	["voyce_sponsorships_aggregate_bool_exp_count"]: GraphQLTypes["voyce_sponsorships_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.sponsorships" */
["voyce_sponsorships_aggregate_fields"]: {
		avg?:ModelTypes["voyce_sponsorships_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_sponsorships_max_fields"],
	min?:ModelTypes["voyce_sponsorships_min_fields"],
	stddev?:ModelTypes["voyce_sponsorships_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_sponsorships_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_sponsorships_stddev_samp_fields"],
	sum?:ModelTypes["voyce_sponsorships_sum_fields"],
	var_pop?:ModelTypes["voyce_sponsorships_var_pop_fields"],
	var_samp?:ModelTypes["voyce_sponsorships_var_samp_fields"],
	variance?:ModelTypes["voyce_sponsorships_variance_fields"]
};
	/** order by aggregate values of table "voyce.sponsorships" */
["voyce_sponsorships_aggregate_order_by"]: GraphQLTypes["voyce_sponsorships_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.sponsorships" */
["voyce_sponsorships_arr_rel_insert_input"]: GraphQLTypes["voyce_sponsorships_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_sponsorships_avg_fields"]: {
		amount?:number,
	id?:number,
	series_id?:number,
	sponsored_user_id?:number,
	sponsoring_user_id?:number
};
	/** order by avg() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_avg_order_by"]: GraphQLTypes["voyce_sponsorships_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.sponsorships". All fields are combined with a logical 'AND'. */
["voyce_sponsorships_bool_exp"]: GraphQLTypes["voyce_sponsorships_bool_exp"];
	/** unique or primary key constraints on table "voyce.sponsorships" */
["voyce_sponsorships_constraint"]: GraphQLTypes["voyce_sponsorships_constraint"];
	/** input type for incrementing numeric columns in table "voyce.sponsorships" */
["voyce_sponsorships_inc_input"]: GraphQLTypes["voyce_sponsorships_inc_input"];
	/** input type for inserting data into table "voyce.sponsorships" */
["voyce_sponsorships_insert_input"]: GraphQLTypes["voyce_sponsorships_insert_input"];
	/** aggregate max on columns */
["voyce_sponsorships_max_fields"]: {
		amount?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	message?:string,
	series_id?:number,
	sponsored_user_id?:number,
	sponsoring_user_id?:number,
	stripe_subscription?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** order by max() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_max_order_by"]: GraphQLTypes["voyce_sponsorships_max_order_by"];
	/** aggregate min on columns */
["voyce_sponsorships_min_fields"]: {
		amount?:number,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	message?:string,
	series_id?:number,
	sponsored_user_id?:number,
	sponsoring_user_id?:number,
	stripe_subscription?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** order by min() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_min_order_by"]: GraphQLTypes["voyce_sponsorships_min_order_by"];
	/** response of any mutation on the table "voyce.sponsorships" */
["voyce_sponsorships_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_sponsorships"][]
};
	/** on_conflict condition type for table "voyce.sponsorships" */
["voyce_sponsorships_on_conflict"]: GraphQLTypes["voyce_sponsorships_on_conflict"];
	/** Ordering options when selecting data from "voyce.sponsorships". */
["voyce_sponsorships_order_by"]: GraphQLTypes["voyce_sponsorships_order_by"];
	/** primary key columns input for table: voyce.sponsorships */
["voyce_sponsorships_pk_columns_input"]: GraphQLTypes["voyce_sponsorships_pk_columns_input"];
	/** select columns of table "voyce.sponsorships" */
["voyce_sponsorships_select_column"]: GraphQLTypes["voyce_sponsorships_select_column"];
	/** input type for updating data in table "voyce.sponsorships" */
["voyce_sponsorships_set_input"]: GraphQLTypes["voyce_sponsorships_set_input"];
	/** aggregate stddev on columns */
["voyce_sponsorships_stddev_fields"]: {
		amount?:number,
	id?:number,
	series_id?:number,
	sponsored_user_id?:number,
	sponsoring_user_id?:number
};
	/** order by stddev() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_stddev_order_by"]: GraphQLTypes["voyce_sponsorships_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_sponsorships_stddev_pop_fields"]: {
		amount?:number,
	id?:number,
	series_id?:number,
	sponsored_user_id?:number,
	sponsoring_user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_stddev_pop_order_by"]: GraphQLTypes["voyce_sponsorships_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_sponsorships_stddev_samp_fields"]: {
		amount?:number,
	id?:number,
	series_id?:number,
	sponsored_user_id?:number,
	sponsoring_user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_stddev_samp_order_by"]: GraphQLTypes["voyce_sponsorships_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_sponsorships" */
["voyce_sponsorships_stream_cursor_input"]: GraphQLTypes["voyce_sponsorships_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_sponsorships_stream_cursor_value_input"]: GraphQLTypes["voyce_sponsorships_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_sponsorships_sum_fields"]: {
		amount?:number,
	id?:number,
	series_id?:number,
	sponsored_user_id?:number,
	sponsoring_user_id?:number
};
	/** order by sum() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_sum_order_by"]: GraphQLTypes["voyce_sponsorships_sum_order_by"];
	/** update columns of table "voyce.sponsorships" */
["voyce_sponsorships_update_column"]: GraphQLTypes["voyce_sponsorships_update_column"];
	["voyce_sponsorships_updates"]: GraphQLTypes["voyce_sponsorships_updates"];
	/** aggregate var_pop on columns */
["voyce_sponsorships_var_pop_fields"]: {
		amount?:number,
	id?:number,
	series_id?:number,
	sponsored_user_id?:number,
	sponsoring_user_id?:number
};
	/** order by var_pop() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_var_pop_order_by"]: GraphQLTypes["voyce_sponsorships_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_sponsorships_var_samp_fields"]: {
		amount?:number,
	id?:number,
	series_id?:number,
	sponsored_user_id?:number,
	sponsoring_user_id?:number
};
	/** order by var_samp() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_var_samp_order_by"]: GraphQLTypes["voyce_sponsorships_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_sponsorships_variance_fields"]: {
		amount?:number,
	id?:number,
	series_id?:number,
	sponsored_user_id?:number,
	sponsoring_user_id?:number
};
	/** order by variance() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_variance_order_by"]: GraphQLTypes["voyce_sponsorships_variance_order_by"];
	/** columns and relationships of "voyce.states" */
["voyce_states"]: {
		country_id:number,
	date_create:ModelTypes["timestamp"],
	description?:string,
	feature:ModelTypes["smallint"],
	id:number,
	name:string,
	publish:ModelTypes["smallint"]
};
	/** aggregated selection of "voyce.states" */
["voyce_states_aggregate"]: {
		aggregate?:ModelTypes["voyce_states_aggregate_fields"],
	nodes:ModelTypes["voyce_states"][]
};
	/** aggregate fields of "voyce.states" */
["voyce_states_aggregate_fields"]: {
		avg?:ModelTypes["voyce_states_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_states_max_fields"],
	min?:ModelTypes["voyce_states_min_fields"],
	stddev?:ModelTypes["voyce_states_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_states_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_states_stddev_samp_fields"],
	sum?:ModelTypes["voyce_states_sum_fields"],
	var_pop?:ModelTypes["voyce_states_var_pop_fields"],
	var_samp?:ModelTypes["voyce_states_var_samp_fields"],
	variance?:ModelTypes["voyce_states_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_states_avg_fields"]: {
		country_id?:number,
	feature?:number,
	id?:number,
	publish?:number
};
	/** Boolean expression to filter rows from the table "voyce.states". All fields are combined with a logical 'AND'. */
["voyce_states_bool_exp"]: GraphQLTypes["voyce_states_bool_exp"];
	/** unique or primary key constraints on table "voyce.states" */
["voyce_states_constraint"]: GraphQLTypes["voyce_states_constraint"];
	/** input type for incrementing numeric columns in table "voyce.states" */
["voyce_states_inc_input"]: GraphQLTypes["voyce_states_inc_input"];
	/** input type for inserting data into table "voyce.states" */
["voyce_states_insert_input"]: GraphQLTypes["voyce_states_insert_input"];
	/** aggregate max on columns */
["voyce_states_max_fields"]: {
		country_id?:number,
	date_create?:ModelTypes["timestamp"],
	description?:string,
	feature?:ModelTypes["smallint"],
	id?:number,
	name?:string,
	publish?:ModelTypes["smallint"]
};
	/** aggregate min on columns */
["voyce_states_min_fields"]: {
		country_id?:number,
	date_create?:ModelTypes["timestamp"],
	description?:string,
	feature?:ModelTypes["smallint"],
	id?:number,
	name?:string,
	publish?:ModelTypes["smallint"]
};
	/** response of any mutation on the table "voyce.states" */
["voyce_states_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_states"][]
};
	/** on_conflict condition type for table "voyce.states" */
["voyce_states_on_conflict"]: GraphQLTypes["voyce_states_on_conflict"];
	/** Ordering options when selecting data from "voyce.states". */
["voyce_states_order_by"]: GraphQLTypes["voyce_states_order_by"];
	/** primary key columns input for table: voyce.states */
["voyce_states_pk_columns_input"]: GraphQLTypes["voyce_states_pk_columns_input"];
	/** select columns of table "voyce.states" */
["voyce_states_select_column"]: GraphQLTypes["voyce_states_select_column"];
	/** input type for updating data in table "voyce.states" */
["voyce_states_set_input"]: GraphQLTypes["voyce_states_set_input"];
	/** aggregate stddev on columns */
["voyce_states_stddev_fields"]: {
		country_id?:number,
	feature?:number,
	id?:number,
	publish?:number
};
	/** aggregate stddev_pop on columns */
["voyce_states_stddev_pop_fields"]: {
		country_id?:number,
	feature?:number,
	id?:number,
	publish?:number
};
	/** aggregate stddev_samp on columns */
["voyce_states_stddev_samp_fields"]: {
		country_id?:number,
	feature?:number,
	id?:number,
	publish?:number
};
	/** Streaming cursor of the table "voyce_states" */
["voyce_states_stream_cursor_input"]: GraphQLTypes["voyce_states_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_states_stream_cursor_value_input"]: GraphQLTypes["voyce_states_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_states_sum_fields"]: {
		country_id?:number,
	feature?:ModelTypes["smallint"],
	id?:number,
	publish?:ModelTypes["smallint"]
};
	/** update columns of table "voyce.states" */
["voyce_states_update_column"]: GraphQLTypes["voyce_states_update_column"];
	["voyce_states_updates"]: GraphQLTypes["voyce_states_updates"];
	/** aggregate var_pop on columns */
["voyce_states_var_pop_fields"]: {
		country_id?:number,
	feature?:number,
	id?:number,
	publish?:number
};
	/** aggregate var_samp on columns */
["voyce_states_var_samp_fields"]: {
		country_id?:number,
	feature?:number,
	id?:number,
	publish?:number
};
	/** aggregate variance on columns */
["voyce_states_variance_fields"]: {
		country_id?:number,
	feature?:number,
	id?:number,
	publish?:number
};
	/** columns and relationships of "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup"]: {
		completed_stripe_onboarding:boolean,
	cut:number,
	id:number,
	stripe_account_id?:string,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_aggregate"]: {
		aggregate?:ModelTypes["voyce_stripe_payouts_setup_aggregate_fields"],
	nodes:ModelTypes["voyce_stripe_payouts_setup"][]
};
	/** aggregate fields of "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_aggregate_fields"]: {
		avg?:ModelTypes["voyce_stripe_payouts_setup_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_stripe_payouts_setup_max_fields"],
	min?:ModelTypes["voyce_stripe_payouts_setup_min_fields"],
	stddev?:ModelTypes["voyce_stripe_payouts_setup_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_stripe_payouts_setup_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_stripe_payouts_setup_stddev_samp_fields"],
	sum?:ModelTypes["voyce_stripe_payouts_setup_sum_fields"],
	var_pop?:ModelTypes["voyce_stripe_payouts_setup_var_pop_fields"],
	var_samp?:ModelTypes["voyce_stripe_payouts_setup_var_samp_fields"],
	variance?:ModelTypes["voyce_stripe_payouts_setup_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_stripe_payouts_setup_avg_fields"]: {
		cut?:number,
	id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.stripe_payouts_setup". All fields are combined with a logical 'AND'. */
["voyce_stripe_payouts_setup_bool_exp"]: GraphQLTypes["voyce_stripe_payouts_setup_bool_exp"];
	/** unique or primary key constraints on table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_constraint"]: GraphQLTypes["voyce_stripe_payouts_setup_constraint"];
	/** input type for incrementing numeric columns in table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_inc_input"]: GraphQLTypes["voyce_stripe_payouts_setup_inc_input"];
	/** input type for inserting data into table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_insert_input"]: GraphQLTypes["voyce_stripe_payouts_setup_insert_input"];
	/** aggregate max on columns */
["voyce_stripe_payouts_setup_max_fields"]: {
		cut?:number,
	id?:number,
	stripe_account_id?:string,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_stripe_payouts_setup_min_fields"]: {
		cut?:number,
	id?:number,
	stripe_account_id?:string,
	user_id?:number
};
	/** response of any mutation on the table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_stripe_payouts_setup"][]
};
	/** input type for inserting object relation for remote table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_obj_rel_insert_input"]: GraphQLTypes["voyce_stripe_payouts_setup_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_on_conflict"]: GraphQLTypes["voyce_stripe_payouts_setup_on_conflict"];
	/** Ordering options when selecting data from "voyce.stripe_payouts_setup". */
["voyce_stripe_payouts_setup_order_by"]: GraphQLTypes["voyce_stripe_payouts_setup_order_by"];
	/** primary key columns input for table: voyce.stripe_payouts_setup */
["voyce_stripe_payouts_setup_pk_columns_input"]: GraphQLTypes["voyce_stripe_payouts_setup_pk_columns_input"];
	/** select columns of table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_select_column"]: GraphQLTypes["voyce_stripe_payouts_setup_select_column"];
	/** input type for updating data in table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_set_input"]: GraphQLTypes["voyce_stripe_payouts_setup_set_input"];
	/** aggregate stddev on columns */
["voyce_stripe_payouts_setup_stddev_fields"]: {
		cut?:number,
	id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_stripe_payouts_setup_stddev_pop_fields"]: {
		cut?:number,
	id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_stripe_payouts_setup_stddev_samp_fields"]: {
		cut?:number,
	id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_stripe_payouts_setup" */
["voyce_stripe_payouts_setup_stream_cursor_input"]: GraphQLTypes["voyce_stripe_payouts_setup_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_stripe_payouts_setup_stream_cursor_value_input"]: GraphQLTypes["voyce_stripe_payouts_setup_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_stripe_payouts_setup_sum_fields"]: {
		cut?:number,
	id?:number,
	user_id?:number
};
	/** update columns of table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_update_column"]: GraphQLTypes["voyce_stripe_payouts_setup_update_column"];
	["voyce_stripe_payouts_setup_updates"]: GraphQLTypes["voyce_stripe_payouts_setup_updates"];
	/** aggregate var_pop on columns */
["voyce_stripe_payouts_setup_var_pop_fields"]: {
		cut?:number,
	id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_stripe_payouts_setup_var_samp_fields"]: {
		cut?:number,
	id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_stripe_payouts_setup_variance_fields"]: {
		cut?:number,
	id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.studio" */
["voyce_studio"]: {
		active:boolean,
	avatar:string,
	created_at:ModelTypes["timestamptz"],
	description:string,
	discord?:string,
	facebook?:string,
	/** An array relationship */
	followers:ModelTypes["voyce_studio_followers"][],
	/** An aggregate relationship */
	followers_aggregate:ModelTypes["voyce_studio_followers_aggregate"],
	id:number,
	instagram?:string,
	layout:ModelTypes["smallint"],
	logo:string,
	order:number,
	props:ModelTypes["json"],
	/** An array relationship */
	series:ModelTypes["voyce_studio_series"][],
	/** An aggregate relationship */
	series_aggregate:ModelTypes["voyce_studio_series_aggregate"],
	slug?:string,
	tiktok?:string,
	title:string,
	twitter?:string,
	updated_at:ModelTypes["timestamptz"],
	/** An object relationship */
	views?:ModelTypes["voyce_studio_views"],
	website?:string
};
	/** aggregated selection of "voyce.studio" */
["voyce_studio_aggregate"]: {
		aggregate?:ModelTypes["voyce_studio_aggregate_fields"],
	nodes:ModelTypes["voyce_studio"][]
};
	/** aggregate fields of "voyce.studio" */
["voyce_studio_aggregate_fields"]: {
		avg?:ModelTypes["voyce_studio_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_studio_max_fields"],
	min?:ModelTypes["voyce_studio_min_fields"],
	stddev?:ModelTypes["voyce_studio_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_studio_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_studio_stddev_samp_fields"],
	sum?:ModelTypes["voyce_studio_sum_fields"],
	var_pop?:ModelTypes["voyce_studio_var_pop_fields"],
	var_samp?:ModelTypes["voyce_studio_var_samp_fields"],
	variance?:ModelTypes["voyce_studio_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_studio_avg_fields"]: {
		id?:number,
	layout?:number,
	order?:number
};
	/** Boolean expression to filter rows from the table "voyce.studio". All fields are combined with a logical 'AND'. */
["voyce_studio_bool_exp"]: GraphQLTypes["voyce_studio_bool_exp"];
	/** unique or primary key constraints on table "voyce.studio" */
["voyce_studio_constraint"]: GraphQLTypes["voyce_studio_constraint"];
	/** columns and relationships of "voyce.studio_followers" */
["voyce_studio_followers"]: {
		created_at:ModelTypes["timestamptz"],
	id:number,
	studio_id:number,
	user_id:number
};
	/** aggregated selection of "voyce.studio_followers" */
["voyce_studio_followers_aggregate"]: {
		aggregate?:ModelTypes["voyce_studio_followers_aggregate_fields"],
	nodes:ModelTypes["voyce_studio_followers"][]
};
	["voyce_studio_followers_aggregate_bool_exp"]: GraphQLTypes["voyce_studio_followers_aggregate_bool_exp"];
	["voyce_studio_followers_aggregate_bool_exp_count"]: GraphQLTypes["voyce_studio_followers_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.studio_followers" */
["voyce_studio_followers_aggregate_fields"]: {
		avg?:ModelTypes["voyce_studio_followers_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_studio_followers_max_fields"],
	min?:ModelTypes["voyce_studio_followers_min_fields"],
	stddev?:ModelTypes["voyce_studio_followers_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_studio_followers_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_studio_followers_stddev_samp_fields"],
	sum?:ModelTypes["voyce_studio_followers_sum_fields"],
	var_pop?:ModelTypes["voyce_studio_followers_var_pop_fields"],
	var_samp?:ModelTypes["voyce_studio_followers_var_samp_fields"],
	variance?:ModelTypes["voyce_studio_followers_variance_fields"]
};
	/** order by aggregate values of table "voyce.studio_followers" */
["voyce_studio_followers_aggregate_order_by"]: GraphQLTypes["voyce_studio_followers_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.studio_followers" */
["voyce_studio_followers_arr_rel_insert_input"]: GraphQLTypes["voyce_studio_followers_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_studio_followers_avg_fields"]: {
		id?:number,
	studio_id?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_avg_order_by"]: GraphQLTypes["voyce_studio_followers_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.studio_followers". All fields are combined with a logical 'AND'. */
["voyce_studio_followers_bool_exp"]: GraphQLTypes["voyce_studio_followers_bool_exp"];
	/** unique or primary key constraints on table "voyce.studio_followers" */
["voyce_studio_followers_constraint"]: GraphQLTypes["voyce_studio_followers_constraint"];
	/** input type for incrementing numeric columns in table "voyce.studio_followers" */
["voyce_studio_followers_inc_input"]: GraphQLTypes["voyce_studio_followers_inc_input"];
	/** input type for inserting data into table "voyce.studio_followers" */
["voyce_studio_followers_insert_input"]: GraphQLTypes["voyce_studio_followers_insert_input"];
	/** aggregate max on columns */
["voyce_studio_followers_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	studio_id?:number,
	user_id?:number
};
	/** order by max() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_max_order_by"]: GraphQLTypes["voyce_studio_followers_max_order_by"];
	/** aggregate min on columns */
["voyce_studio_followers_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	studio_id?:number,
	user_id?:number
};
	/** order by min() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_min_order_by"]: GraphQLTypes["voyce_studio_followers_min_order_by"];
	/** response of any mutation on the table "voyce.studio_followers" */
["voyce_studio_followers_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_studio_followers"][]
};
	/** on_conflict condition type for table "voyce.studio_followers" */
["voyce_studio_followers_on_conflict"]: GraphQLTypes["voyce_studio_followers_on_conflict"];
	/** Ordering options when selecting data from "voyce.studio_followers". */
["voyce_studio_followers_order_by"]: GraphQLTypes["voyce_studio_followers_order_by"];
	/** primary key columns input for table: voyce.studio_followers */
["voyce_studio_followers_pk_columns_input"]: GraphQLTypes["voyce_studio_followers_pk_columns_input"];
	/** select columns of table "voyce.studio_followers" */
["voyce_studio_followers_select_column"]: GraphQLTypes["voyce_studio_followers_select_column"];
	/** input type for updating data in table "voyce.studio_followers" */
["voyce_studio_followers_set_input"]: GraphQLTypes["voyce_studio_followers_set_input"];
	/** aggregate stddev on columns */
["voyce_studio_followers_stddev_fields"]: {
		id?:number,
	studio_id?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_stddev_order_by"]: GraphQLTypes["voyce_studio_followers_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_studio_followers_stddev_pop_fields"]: {
		id?:number,
	studio_id?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_stddev_pop_order_by"]: GraphQLTypes["voyce_studio_followers_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_studio_followers_stddev_samp_fields"]: {
		id?:number,
	studio_id?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_stddev_samp_order_by"]: GraphQLTypes["voyce_studio_followers_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_studio_followers" */
["voyce_studio_followers_stream_cursor_input"]: GraphQLTypes["voyce_studio_followers_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_studio_followers_stream_cursor_value_input"]: GraphQLTypes["voyce_studio_followers_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_studio_followers_sum_fields"]: {
		id?:number,
	studio_id?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_sum_order_by"]: GraphQLTypes["voyce_studio_followers_sum_order_by"];
	/** update columns of table "voyce.studio_followers" */
["voyce_studio_followers_update_column"]: GraphQLTypes["voyce_studio_followers_update_column"];
	["voyce_studio_followers_updates"]: GraphQLTypes["voyce_studio_followers_updates"];
	/** aggregate var_pop on columns */
["voyce_studio_followers_var_pop_fields"]: {
		id?:number,
	studio_id?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_var_pop_order_by"]: GraphQLTypes["voyce_studio_followers_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_studio_followers_var_samp_fields"]: {
		id?:number,
	studio_id?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_var_samp_order_by"]: GraphQLTypes["voyce_studio_followers_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_studio_followers_variance_fields"]: {
		id?:number,
	studio_id?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_variance_order_by"]: GraphQLTypes["voyce_studio_followers_variance_order_by"];
	/** input type for incrementing numeric columns in table "voyce.studio" */
["voyce_studio_inc_input"]: GraphQLTypes["voyce_studio_inc_input"];
	/** input type for inserting data into table "voyce.studio" */
["voyce_studio_insert_input"]: GraphQLTypes["voyce_studio_insert_input"];
	/** aggregate max on columns */
["voyce_studio_max_fields"]: {
		avatar?:string,
	created_at?:ModelTypes["timestamptz"],
	description?:string,
	discord?:string,
	facebook?:string,
	id?:number,
	instagram?:string,
	layout?:ModelTypes["smallint"],
	logo?:string,
	order?:number,
	slug?:string,
	tiktok?:string,
	title?:string,
	twitter?:string,
	updated_at?:ModelTypes["timestamptz"],
	website?:string
};
	/** aggregate min on columns */
["voyce_studio_min_fields"]: {
		avatar?:string,
	created_at?:ModelTypes["timestamptz"],
	description?:string,
	discord?:string,
	facebook?:string,
	id?:number,
	instagram?:string,
	layout?:ModelTypes["smallint"],
	logo?:string,
	order?:number,
	slug?:string,
	tiktok?:string,
	title?:string,
	twitter?:string,
	updated_at?:ModelTypes["timestamptz"],
	website?:string
};
	/** response of any mutation on the table "voyce.studio" */
["voyce_studio_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_studio"][]
};
	/** on_conflict condition type for table "voyce.studio" */
["voyce_studio_on_conflict"]: GraphQLTypes["voyce_studio_on_conflict"];
	/** Ordering options when selecting data from "voyce.studio". */
["voyce_studio_order_by"]: GraphQLTypes["voyce_studio_order_by"];
	/** primary key columns input for table: voyce.studio */
["voyce_studio_pk_columns_input"]: GraphQLTypes["voyce_studio_pk_columns_input"];
	/** select columns of table "voyce.studio" */
["voyce_studio_select_column"]: GraphQLTypes["voyce_studio_select_column"];
	/** columns and relationships of "voyce.studio_series" */
["voyce_studio_series"]: {
		id:number,
	/** An object relationship */
	series?:ModelTypes["voyce_series"],
	series_id:number,
	/** An object relationship */
	series_views?:ModelTypes["voyce_pg_rs_series_views_count"],
	studio_id:number
};
	/** aggregated selection of "voyce.studio_series" */
["voyce_studio_series_aggregate"]: {
		aggregate?:ModelTypes["voyce_studio_series_aggregate_fields"],
	nodes:ModelTypes["voyce_studio_series"][]
};
	["voyce_studio_series_aggregate_bool_exp"]: GraphQLTypes["voyce_studio_series_aggregate_bool_exp"];
	["voyce_studio_series_aggregate_bool_exp_count"]: GraphQLTypes["voyce_studio_series_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.studio_series" */
["voyce_studio_series_aggregate_fields"]: {
		avg?:ModelTypes["voyce_studio_series_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_studio_series_max_fields"],
	min?:ModelTypes["voyce_studio_series_min_fields"],
	stddev?:ModelTypes["voyce_studio_series_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_studio_series_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_studio_series_stddev_samp_fields"],
	sum?:ModelTypes["voyce_studio_series_sum_fields"],
	var_pop?:ModelTypes["voyce_studio_series_var_pop_fields"],
	var_samp?:ModelTypes["voyce_studio_series_var_samp_fields"],
	variance?:ModelTypes["voyce_studio_series_variance_fields"]
};
	/** order by aggregate values of table "voyce.studio_series" */
["voyce_studio_series_aggregate_order_by"]: GraphQLTypes["voyce_studio_series_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.studio_series" */
["voyce_studio_series_arr_rel_insert_input"]: GraphQLTypes["voyce_studio_series_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_studio_series_avg_fields"]: {
		id?:number,
	series_id?:number,
	studio_id?:number
};
	/** order by avg() on columns of table "voyce.studio_series" */
["voyce_studio_series_avg_order_by"]: GraphQLTypes["voyce_studio_series_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.studio_series". All fields are combined with a logical 'AND'. */
["voyce_studio_series_bool_exp"]: GraphQLTypes["voyce_studio_series_bool_exp"];
	/** unique or primary key constraints on table "voyce.studio_series" */
["voyce_studio_series_constraint"]: GraphQLTypes["voyce_studio_series_constraint"];
	/** input type for incrementing numeric columns in table "voyce.studio_series" */
["voyce_studio_series_inc_input"]: GraphQLTypes["voyce_studio_series_inc_input"];
	/** input type for inserting data into table "voyce.studio_series" */
["voyce_studio_series_insert_input"]: GraphQLTypes["voyce_studio_series_insert_input"];
	/** aggregate max on columns */
["voyce_studio_series_max_fields"]: {
		id?:number,
	series_id?:number,
	studio_id?:number
};
	/** order by max() on columns of table "voyce.studio_series" */
["voyce_studio_series_max_order_by"]: GraphQLTypes["voyce_studio_series_max_order_by"];
	/** aggregate min on columns */
["voyce_studio_series_min_fields"]: {
		id?:number,
	series_id?:number,
	studio_id?:number
};
	/** order by min() on columns of table "voyce.studio_series" */
["voyce_studio_series_min_order_by"]: GraphQLTypes["voyce_studio_series_min_order_by"];
	/** response of any mutation on the table "voyce.studio_series" */
["voyce_studio_series_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_studio_series"][]
};
	/** on_conflict condition type for table "voyce.studio_series" */
["voyce_studio_series_on_conflict"]: GraphQLTypes["voyce_studio_series_on_conflict"];
	/** Ordering options when selecting data from "voyce.studio_series". */
["voyce_studio_series_order_by"]: GraphQLTypes["voyce_studio_series_order_by"];
	/** primary key columns input for table: voyce.studio_series */
["voyce_studio_series_pk_columns_input"]: GraphQLTypes["voyce_studio_series_pk_columns_input"];
	/** select columns of table "voyce.studio_series" */
["voyce_studio_series_select_column"]: GraphQLTypes["voyce_studio_series_select_column"];
	/** input type for updating data in table "voyce.studio_series" */
["voyce_studio_series_set_input"]: GraphQLTypes["voyce_studio_series_set_input"];
	/** aggregate stddev on columns */
["voyce_studio_series_stddev_fields"]: {
		id?:number,
	series_id?:number,
	studio_id?:number
};
	/** order by stddev() on columns of table "voyce.studio_series" */
["voyce_studio_series_stddev_order_by"]: GraphQLTypes["voyce_studio_series_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_studio_series_stddev_pop_fields"]: {
		id?:number,
	series_id?:number,
	studio_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.studio_series" */
["voyce_studio_series_stddev_pop_order_by"]: GraphQLTypes["voyce_studio_series_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_studio_series_stddev_samp_fields"]: {
		id?:number,
	series_id?:number,
	studio_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.studio_series" */
["voyce_studio_series_stddev_samp_order_by"]: GraphQLTypes["voyce_studio_series_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_studio_series" */
["voyce_studio_series_stream_cursor_input"]: GraphQLTypes["voyce_studio_series_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_studio_series_stream_cursor_value_input"]: GraphQLTypes["voyce_studio_series_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_studio_series_sum_fields"]: {
		id?:number,
	series_id?:number,
	studio_id?:number
};
	/** order by sum() on columns of table "voyce.studio_series" */
["voyce_studio_series_sum_order_by"]: GraphQLTypes["voyce_studio_series_sum_order_by"];
	/** update columns of table "voyce.studio_series" */
["voyce_studio_series_update_column"]: GraphQLTypes["voyce_studio_series_update_column"];
	["voyce_studio_series_updates"]: GraphQLTypes["voyce_studio_series_updates"];
	/** aggregate var_pop on columns */
["voyce_studio_series_var_pop_fields"]: {
		id?:number,
	series_id?:number,
	studio_id?:number
};
	/** order by var_pop() on columns of table "voyce.studio_series" */
["voyce_studio_series_var_pop_order_by"]: GraphQLTypes["voyce_studio_series_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_studio_series_var_samp_fields"]: {
		id?:number,
	series_id?:number,
	studio_id?:number
};
	/** order by var_samp() on columns of table "voyce.studio_series" */
["voyce_studio_series_var_samp_order_by"]: GraphQLTypes["voyce_studio_series_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_studio_series_variance_fields"]: {
		id?:number,
	series_id?:number,
	studio_id?:number
};
	/** order by variance() on columns of table "voyce.studio_series" */
["voyce_studio_series_variance_order_by"]: GraphQLTypes["voyce_studio_series_variance_order_by"];
	/** input type for updating data in table "voyce.studio" */
["voyce_studio_set_input"]: GraphQLTypes["voyce_studio_set_input"];
	/** aggregate stddev on columns */
["voyce_studio_stddev_fields"]: {
		id?:number,
	layout?:number,
	order?:number
};
	/** aggregate stddev_pop on columns */
["voyce_studio_stddev_pop_fields"]: {
		id?:number,
	layout?:number,
	order?:number
};
	/** aggregate stddev_samp on columns */
["voyce_studio_stddev_samp_fields"]: {
		id?:number,
	layout?:number,
	order?:number
};
	/** Streaming cursor of the table "voyce_studio" */
["voyce_studio_stream_cursor_input"]: GraphQLTypes["voyce_studio_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_studio_stream_cursor_value_input"]: GraphQLTypes["voyce_studio_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_studio_sum_fields"]: {
		id?:number,
	layout?:ModelTypes["smallint"],
	order?:number
};
	/** update columns of table "voyce.studio" */
["voyce_studio_update_column"]: GraphQLTypes["voyce_studio_update_column"];
	["voyce_studio_updates"]: GraphQLTypes["voyce_studio_updates"];
	/** aggregate var_pop on columns */
["voyce_studio_var_pop_fields"]: {
		id?:number,
	layout?:number,
	order?:number
};
	/** aggregate var_samp on columns */
["voyce_studio_var_samp_fields"]: {
		id?:number,
	layout?:number,
	order?:number
};
	/** aggregate variance on columns */
["voyce_studio_variance_fields"]: {
		id?:number,
	layout?:number,
	order?:number
};
	/** columns and relationships of "voyce.studio_views" */
["voyce_studio_views"]: {
		count:number,
	id:number,
	studio_id:number
};
	/** aggregated selection of "voyce.studio_views" */
["voyce_studio_views_aggregate"]: {
		aggregate?:ModelTypes["voyce_studio_views_aggregate_fields"],
	nodes:ModelTypes["voyce_studio_views"][]
};
	/** aggregate fields of "voyce.studio_views" */
["voyce_studio_views_aggregate_fields"]: {
		avg?:ModelTypes["voyce_studio_views_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_studio_views_max_fields"],
	min?:ModelTypes["voyce_studio_views_min_fields"],
	stddev?:ModelTypes["voyce_studio_views_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_studio_views_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_studio_views_stddev_samp_fields"],
	sum?:ModelTypes["voyce_studio_views_sum_fields"],
	var_pop?:ModelTypes["voyce_studio_views_var_pop_fields"],
	var_samp?:ModelTypes["voyce_studio_views_var_samp_fields"],
	variance?:ModelTypes["voyce_studio_views_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_studio_views_avg_fields"]: {
		count?:number,
	id?:number,
	studio_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.studio_views". All fields are combined with a logical 'AND'. */
["voyce_studio_views_bool_exp"]: GraphQLTypes["voyce_studio_views_bool_exp"];
	/** unique or primary key constraints on table "voyce.studio_views" */
["voyce_studio_views_constraint"]: GraphQLTypes["voyce_studio_views_constraint"];
	/** input type for incrementing numeric columns in table "voyce.studio_views" */
["voyce_studio_views_inc_input"]: GraphQLTypes["voyce_studio_views_inc_input"];
	/** input type for inserting data into table "voyce.studio_views" */
["voyce_studio_views_insert_input"]: GraphQLTypes["voyce_studio_views_insert_input"];
	/** aggregate max on columns */
["voyce_studio_views_max_fields"]: {
		count?:number,
	id?:number,
	studio_id?:number
};
	/** aggregate min on columns */
["voyce_studio_views_min_fields"]: {
		count?:number,
	id?:number,
	studio_id?:number
};
	/** response of any mutation on the table "voyce.studio_views" */
["voyce_studio_views_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_studio_views"][]
};
	/** input type for inserting object relation for remote table "voyce.studio_views" */
["voyce_studio_views_obj_rel_insert_input"]: GraphQLTypes["voyce_studio_views_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.studio_views" */
["voyce_studio_views_on_conflict"]: GraphQLTypes["voyce_studio_views_on_conflict"];
	/** Ordering options when selecting data from "voyce.studio_views". */
["voyce_studio_views_order_by"]: GraphQLTypes["voyce_studio_views_order_by"];
	/** primary key columns input for table: voyce.studio_views */
["voyce_studio_views_pk_columns_input"]: GraphQLTypes["voyce_studio_views_pk_columns_input"];
	/** select columns of table "voyce.studio_views" */
["voyce_studio_views_select_column"]: GraphQLTypes["voyce_studio_views_select_column"];
	/** input type for updating data in table "voyce.studio_views" */
["voyce_studio_views_set_input"]: GraphQLTypes["voyce_studio_views_set_input"];
	/** aggregate stddev on columns */
["voyce_studio_views_stddev_fields"]: {
		count?:number,
	id?:number,
	studio_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_studio_views_stddev_pop_fields"]: {
		count?:number,
	id?:number,
	studio_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_studio_views_stddev_samp_fields"]: {
		count?:number,
	id?:number,
	studio_id?:number
};
	/** Streaming cursor of the table "voyce_studio_views" */
["voyce_studio_views_stream_cursor_input"]: GraphQLTypes["voyce_studio_views_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_studio_views_stream_cursor_value_input"]: GraphQLTypes["voyce_studio_views_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_studio_views_sum_fields"]: {
		count?:number,
	id?:number,
	studio_id?:number
};
	/** update columns of table "voyce.studio_views" */
["voyce_studio_views_update_column"]: GraphQLTypes["voyce_studio_views_update_column"];
	["voyce_studio_views_updates"]: GraphQLTypes["voyce_studio_views_updates"];
	/** aggregate var_pop on columns */
["voyce_studio_views_var_pop_fields"]: {
		count?:number,
	id?:number,
	studio_id?:number
};
	/** aggregate var_samp on columns */
["voyce_studio_views_var_samp_fields"]: {
		count?:number,
	id?:number,
	studio_id?:number
};
	/** aggregate variance on columns */
["voyce_studio_views_variance_fields"]: {
		count?:number,
	id?:number,
	studio_id?:number
};
	/** columns and relationships of "voyce.system_settings" */
["voyce_system_settings"]: {
		created_at:ModelTypes["timestamp"],
	id:number,
	key:string,
	updated_at?:ModelTypes["timestamp"],
	value:string
};
	/** aggregated selection of "voyce.system_settings" */
["voyce_system_settings_aggregate"]: {
		aggregate?:ModelTypes["voyce_system_settings_aggregate_fields"],
	nodes:ModelTypes["voyce_system_settings"][]
};
	/** aggregate fields of "voyce.system_settings" */
["voyce_system_settings_aggregate_fields"]: {
		avg?:ModelTypes["voyce_system_settings_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_system_settings_max_fields"],
	min?:ModelTypes["voyce_system_settings_min_fields"],
	stddev?:ModelTypes["voyce_system_settings_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_system_settings_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_system_settings_stddev_samp_fields"],
	sum?:ModelTypes["voyce_system_settings_sum_fields"],
	var_pop?:ModelTypes["voyce_system_settings_var_pop_fields"],
	var_samp?:ModelTypes["voyce_system_settings_var_samp_fields"],
	variance?:ModelTypes["voyce_system_settings_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_system_settings_avg_fields"]: {
		id?:number
};
	/** Boolean expression to filter rows from the table "voyce.system_settings". All fields are combined with a logical 'AND'. */
["voyce_system_settings_bool_exp"]: GraphQLTypes["voyce_system_settings_bool_exp"];
	/** unique or primary key constraints on table "voyce.system_settings" */
["voyce_system_settings_constraint"]: GraphQLTypes["voyce_system_settings_constraint"];
	/** input type for incrementing numeric columns in table "voyce.system_settings" */
["voyce_system_settings_inc_input"]: GraphQLTypes["voyce_system_settings_inc_input"];
	/** input type for inserting data into table "voyce.system_settings" */
["voyce_system_settings_insert_input"]: GraphQLTypes["voyce_system_settings_insert_input"];
	/** aggregate max on columns */
["voyce_system_settings_max_fields"]: {
		created_at?:ModelTypes["timestamp"],
	id?:number,
	key?:string,
	updated_at?:ModelTypes["timestamp"],
	value?:string
};
	/** aggregate min on columns */
["voyce_system_settings_min_fields"]: {
		created_at?:ModelTypes["timestamp"],
	id?:number,
	key?:string,
	updated_at?:ModelTypes["timestamp"],
	value?:string
};
	/** response of any mutation on the table "voyce.system_settings" */
["voyce_system_settings_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_system_settings"][]
};
	/** on_conflict condition type for table "voyce.system_settings" */
["voyce_system_settings_on_conflict"]: GraphQLTypes["voyce_system_settings_on_conflict"];
	/** Ordering options when selecting data from "voyce.system_settings". */
["voyce_system_settings_order_by"]: GraphQLTypes["voyce_system_settings_order_by"];
	/** primary key columns input for table: voyce.system_settings */
["voyce_system_settings_pk_columns_input"]: GraphQLTypes["voyce_system_settings_pk_columns_input"];
	/** select columns of table "voyce.system_settings" */
["voyce_system_settings_select_column"]: GraphQLTypes["voyce_system_settings_select_column"];
	/** input type for updating data in table "voyce.system_settings" */
["voyce_system_settings_set_input"]: GraphQLTypes["voyce_system_settings_set_input"];
	/** aggregate stddev on columns */
["voyce_system_settings_stddev_fields"]: {
		id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_system_settings_stddev_pop_fields"]: {
		id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_system_settings_stddev_samp_fields"]: {
		id?:number
};
	/** Streaming cursor of the table "voyce_system_settings" */
["voyce_system_settings_stream_cursor_input"]: GraphQLTypes["voyce_system_settings_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_system_settings_stream_cursor_value_input"]: GraphQLTypes["voyce_system_settings_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_system_settings_sum_fields"]: {
		id?:number
};
	/** update columns of table "voyce.system_settings" */
["voyce_system_settings_update_column"]: GraphQLTypes["voyce_system_settings_update_column"];
	["voyce_system_settings_updates"]: GraphQLTypes["voyce_system_settings_updates"];
	/** aggregate var_pop on columns */
["voyce_system_settings_var_pop_fields"]: {
		id?:number
};
	/** aggregate var_samp on columns */
["voyce_system_settings_var_samp_fields"]: {
		id?:number
};
	/** aggregate variance on columns */
["voyce_system_settings_variance_fields"]: {
		id?:number
};
	/** columns and relationships of "voyce.top_genres" */
["voyce_top_genres"]: {
		count?:ModelTypes["bigint"],
	/** An object relationship */
	genre?:ModelTypes["voyce_genres"],
	genre_id?:number,
	/** An array relationship */
	series_genres:ModelTypes["voyce_series_genre"][],
	/** An aggregate relationship */
	series_genres_aggregate:ModelTypes["voyce_series_genre_aggregate"]
};
	/** aggregated selection of "voyce.top_genres" */
["voyce_top_genres_aggregate"]: {
		aggregate?:ModelTypes["voyce_top_genres_aggregate_fields"],
	nodes:ModelTypes["voyce_top_genres"][]
};
	/** aggregate fields of "voyce.top_genres" */
["voyce_top_genres_aggregate_fields"]: {
		avg?:ModelTypes["voyce_top_genres_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_top_genres_max_fields"],
	min?:ModelTypes["voyce_top_genres_min_fields"],
	stddev?:ModelTypes["voyce_top_genres_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_top_genres_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_top_genres_stddev_samp_fields"],
	sum?:ModelTypes["voyce_top_genres_sum_fields"],
	var_pop?:ModelTypes["voyce_top_genres_var_pop_fields"],
	var_samp?:ModelTypes["voyce_top_genres_var_samp_fields"],
	variance?:ModelTypes["voyce_top_genres_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_top_genres_avg_fields"]: {
		count?:number,
	genre_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.top_genres". All fields are combined with a logical 'AND'. */
["voyce_top_genres_bool_exp"]: GraphQLTypes["voyce_top_genres_bool_exp"];
	/** aggregate max on columns */
["voyce_top_genres_max_fields"]: {
		count?:ModelTypes["bigint"],
	genre_id?:number
};
	/** aggregate min on columns */
["voyce_top_genres_min_fields"]: {
		count?:ModelTypes["bigint"],
	genre_id?:number
};
	/** Ordering options when selecting data from "voyce.top_genres". */
["voyce_top_genres_order_by"]: GraphQLTypes["voyce_top_genres_order_by"];
	/** select columns of table "voyce.top_genres" */
["voyce_top_genres_select_column"]: GraphQLTypes["voyce_top_genres_select_column"];
	/** aggregate stddev on columns */
["voyce_top_genres_stddev_fields"]: {
		count?:number,
	genre_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_top_genres_stddev_pop_fields"]: {
		count?:number,
	genre_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_top_genres_stddev_samp_fields"]: {
		count?:number,
	genre_id?:number
};
	/** Streaming cursor of the table "voyce_top_genres" */
["voyce_top_genres_stream_cursor_input"]: GraphQLTypes["voyce_top_genres_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_top_genres_stream_cursor_value_input"]: GraphQLTypes["voyce_top_genres_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_top_genres_sum_fields"]: {
		count?:ModelTypes["bigint"],
	genre_id?:number
};
	/** aggregate var_pop on columns */
["voyce_top_genres_var_pop_fields"]: {
		count?:number,
	genre_id?:number
};
	/** aggregate var_samp on columns */
["voyce_top_genres_var_samp_fields"]: {
		count?:number,
	genre_id?:number
};
	/** aggregate variance on columns */
["voyce_top_genres_variance_fields"]: {
		count?:number,
	genre_id?:number
};
	/** columns and relationships of "voyce.transaction_logs" */
["voyce_transaction_logs"]: {
		amount?:number,
	coins_type?:string,
	created_at:ModelTypes["timestamp"],
	id:number,
	item_id?:number,
	product_id?:number,
	receipt_id?:number,
	receiver_id?:number,
	sender_id?:number,
	title?:string,
	type?:string,
	/** An object relationship */
	userByReceiverId?:ModelTypes["voyce_users"],
	/** An object relationship */
	userBySenderId?:ModelTypes["voyce_users"]
};
	/** aggregated selection of "voyce.transaction_logs" */
["voyce_transaction_logs_aggregate"]: {
		aggregate?:ModelTypes["voyce_transaction_logs_aggregate_fields"],
	nodes:ModelTypes["voyce_transaction_logs"][]
};
	/** aggregate fields of "voyce.transaction_logs" */
["voyce_transaction_logs_aggregate_fields"]: {
		avg?:ModelTypes["voyce_transaction_logs_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_transaction_logs_max_fields"],
	min?:ModelTypes["voyce_transaction_logs_min_fields"],
	stddev?:ModelTypes["voyce_transaction_logs_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_transaction_logs_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_transaction_logs_stddev_samp_fields"],
	sum?:ModelTypes["voyce_transaction_logs_sum_fields"],
	var_pop?:ModelTypes["voyce_transaction_logs_var_pop_fields"],
	var_samp?:ModelTypes["voyce_transaction_logs_var_samp_fields"],
	variance?:ModelTypes["voyce_transaction_logs_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_transaction_logs_avg_fields"]: {
		amount?:number,
	id?:number,
	item_id?:number,
	product_id?:number,
	receipt_id?:number,
	receiver_id?:number,
	sender_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.transaction_logs". All fields are combined with a logical 'AND'. */
["voyce_transaction_logs_bool_exp"]: GraphQLTypes["voyce_transaction_logs_bool_exp"];
	/** unique or primary key constraints on table "voyce.transaction_logs" */
["voyce_transaction_logs_constraint"]: GraphQLTypes["voyce_transaction_logs_constraint"];
	/** input type for incrementing numeric columns in table "voyce.transaction_logs" */
["voyce_transaction_logs_inc_input"]: GraphQLTypes["voyce_transaction_logs_inc_input"];
	/** input type for inserting data into table "voyce.transaction_logs" */
["voyce_transaction_logs_insert_input"]: GraphQLTypes["voyce_transaction_logs_insert_input"];
	/** aggregate max on columns */
["voyce_transaction_logs_max_fields"]: {
		amount?:number,
	coins_type?:string,
	created_at?:ModelTypes["timestamp"],
	id?:number,
	item_id?:number,
	product_id?:number,
	receipt_id?:number,
	receiver_id?:number,
	sender_id?:number,
	title?:string,
	type?:string
};
	/** aggregate min on columns */
["voyce_transaction_logs_min_fields"]: {
		amount?:number,
	coins_type?:string,
	created_at?:ModelTypes["timestamp"],
	id?:number,
	item_id?:number,
	product_id?:number,
	receipt_id?:number,
	receiver_id?:number,
	sender_id?:number,
	title?:string,
	type?:string
};
	/** response of any mutation on the table "voyce.transaction_logs" */
["voyce_transaction_logs_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_transaction_logs"][]
};
	/** on_conflict condition type for table "voyce.transaction_logs" */
["voyce_transaction_logs_on_conflict"]: GraphQLTypes["voyce_transaction_logs_on_conflict"];
	/** Ordering options when selecting data from "voyce.transaction_logs". */
["voyce_transaction_logs_order_by"]: GraphQLTypes["voyce_transaction_logs_order_by"];
	/** primary key columns input for table: voyce.transaction_logs */
["voyce_transaction_logs_pk_columns_input"]: GraphQLTypes["voyce_transaction_logs_pk_columns_input"];
	/** select columns of table "voyce.transaction_logs" */
["voyce_transaction_logs_select_column"]: GraphQLTypes["voyce_transaction_logs_select_column"];
	/** input type for updating data in table "voyce.transaction_logs" */
["voyce_transaction_logs_set_input"]: GraphQLTypes["voyce_transaction_logs_set_input"];
	/** aggregate stddev on columns */
["voyce_transaction_logs_stddev_fields"]: {
		amount?:number,
	id?:number,
	item_id?:number,
	product_id?:number,
	receipt_id?:number,
	receiver_id?:number,
	sender_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_transaction_logs_stddev_pop_fields"]: {
		amount?:number,
	id?:number,
	item_id?:number,
	product_id?:number,
	receipt_id?:number,
	receiver_id?:number,
	sender_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_transaction_logs_stddev_samp_fields"]: {
		amount?:number,
	id?:number,
	item_id?:number,
	product_id?:number,
	receipt_id?:number,
	receiver_id?:number,
	sender_id?:number
};
	/** Streaming cursor of the table "voyce_transaction_logs" */
["voyce_transaction_logs_stream_cursor_input"]: GraphQLTypes["voyce_transaction_logs_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_transaction_logs_stream_cursor_value_input"]: GraphQLTypes["voyce_transaction_logs_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_transaction_logs_sum_fields"]: {
		amount?:number,
	id?:number,
	item_id?:number,
	product_id?:number,
	receipt_id?:number,
	receiver_id?:number,
	sender_id?:number
};
	/** update columns of table "voyce.transaction_logs" */
["voyce_transaction_logs_update_column"]: GraphQLTypes["voyce_transaction_logs_update_column"];
	["voyce_transaction_logs_updates"]: GraphQLTypes["voyce_transaction_logs_updates"];
	/** aggregate var_pop on columns */
["voyce_transaction_logs_var_pop_fields"]: {
		amount?:number,
	id?:number,
	item_id?:number,
	product_id?:number,
	receipt_id?:number,
	receiver_id?:number,
	sender_id?:number
};
	/** aggregate var_samp on columns */
["voyce_transaction_logs_var_samp_fields"]: {
		amount?:number,
	id?:number,
	item_id?:number,
	product_id?:number,
	receipt_id?:number,
	receiver_id?:number,
	sender_id?:number
};
	/** aggregate variance on columns */
["voyce_transaction_logs_variance_fields"]: {
		amount?:number,
	id?:number,
	item_id?:number,
	product_id?:number,
	receipt_id?:number,
	receiver_id?:number,
	sender_id?:number
};
	/** columns and relationships of "voyce.url_redirects" */
["voyce_url_redirects"]: {
		id:number,
	path:string,
	redirect_url:string,
	views:number
};
	/** aggregated selection of "voyce.url_redirects" */
["voyce_url_redirects_aggregate"]: {
		aggregate?:ModelTypes["voyce_url_redirects_aggregate_fields"],
	nodes:ModelTypes["voyce_url_redirects"][]
};
	/** aggregate fields of "voyce.url_redirects" */
["voyce_url_redirects_aggregate_fields"]: {
		avg?:ModelTypes["voyce_url_redirects_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_url_redirects_max_fields"],
	min?:ModelTypes["voyce_url_redirects_min_fields"],
	stddev?:ModelTypes["voyce_url_redirects_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_url_redirects_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_url_redirects_stddev_samp_fields"],
	sum?:ModelTypes["voyce_url_redirects_sum_fields"],
	var_pop?:ModelTypes["voyce_url_redirects_var_pop_fields"],
	var_samp?:ModelTypes["voyce_url_redirects_var_samp_fields"],
	variance?:ModelTypes["voyce_url_redirects_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_url_redirects_avg_fields"]: {
		id?:number,
	views?:number
};
	/** Boolean expression to filter rows from the table "voyce.url_redirects". All fields are combined with a logical 'AND'. */
["voyce_url_redirects_bool_exp"]: GraphQLTypes["voyce_url_redirects_bool_exp"];
	/** unique or primary key constraints on table "voyce.url_redirects" */
["voyce_url_redirects_constraint"]: GraphQLTypes["voyce_url_redirects_constraint"];
	/** input type for incrementing numeric columns in table "voyce.url_redirects" */
["voyce_url_redirects_inc_input"]: GraphQLTypes["voyce_url_redirects_inc_input"];
	/** input type for inserting data into table "voyce.url_redirects" */
["voyce_url_redirects_insert_input"]: GraphQLTypes["voyce_url_redirects_insert_input"];
	/** aggregate max on columns */
["voyce_url_redirects_max_fields"]: {
		id?:number,
	path?:string,
	redirect_url?:string,
	views?:number
};
	/** aggregate min on columns */
["voyce_url_redirects_min_fields"]: {
		id?:number,
	path?:string,
	redirect_url?:string,
	views?:number
};
	/** response of any mutation on the table "voyce.url_redirects" */
["voyce_url_redirects_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_url_redirects"][]
};
	/** on_conflict condition type for table "voyce.url_redirects" */
["voyce_url_redirects_on_conflict"]: GraphQLTypes["voyce_url_redirects_on_conflict"];
	/** Ordering options when selecting data from "voyce.url_redirects". */
["voyce_url_redirects_order_by"]: GraphQLTypes["voyce_url_redirects_order_by"];
	/** primary key columns input for table: voyce.url_redirects */
["voyce_url_redirects_pk_columns_input"]: GraphQLTypes["voyce_url_redirects_pk_columns_input"];
	/** select columns of table "voyce.url_redirects" */
["voyce_url_redirects_select_column"]: GraphQLTypes["voyce_url_redirects_select_column"];
	/** input type for updating data in table "voyce.url_redirects" */
["voyce_url_redirects_set_input"]: GraphQLTypes["voyce_url_redirects_set_input"];
	/** aggregate stddev on columns */
["voyce_url_redirects_stddev_fields"]: {
		id?:number,
	views?:number
};
	/** aggregate stddev_pop on columns */
["voyce_url_redirects_stddev_pop_fields"]: {
		id?:number,
	views?:number
};
	/** aggregate stddev_samp on columns */
["voyce_url_redirects_stddev_samp_fields"]: {
		id?:number,
	views?:number
};
	/** Streaming cursor of the table "voyce_url_redirects" */
["voyce_url_redirects_stream_cursor_input"]: GraphQLTypes["voyce_url_redirects_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_url_redirects_stream_cursor_value_input"]: GraphQLTypes["voyce_url_redirects_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_url_redirects_sum_fields"]: {
		id?:number,
	views?:number
};
	/** update columns of table "voyce.url_redirects" */
["voyce_url_redirects_update_column"]: GraphQLTypes["voyce_url_redirects_update_column"];
	["voyce_url_redirects_updates"]: GraphQLTypes["voyce_url_redirects_updates"];
	/** aggregate var_pop on columns */
["voyce_url_redirects_var_pop_fields"]: {
		id?:number,
	views?:number
};
	/** aggregate var_samp on columns */
["voyce_url_redirects_var_samp_fields"]: {
		id?:number,
	views?:number
};
	/** aggregate variance on columns */
["voyce_url_redirects_variance_fields"]: {
		id?:number,
	views?:number
};
	/** columns and relationships of "voyce.user_addresses" */
["voyce_user_addresses"]: {
		address:string,
	city_id:number,
	country_id:number,
	date_create:ModelTypes["timestamp"],
	id:number,
	is_billing_address?:ModelTypes["smallint"],
	is_shipping_address?:ModelTypes["smallint"],
	state_id:number,
	user_id:number,
	zip_code?:string
};
	/** aggregated selection of "voyce.user_addresses" */
["voyce_user_addresses_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_addresses_aggregate_fields"],
	nodes:ModelTypes["voyce_user_addresses"][]
};
	/** aggregate fields of "voyce.user_addresses" */
["voyce_user_addresses_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_addresses_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_addresses_max_fields"],
	min?:ModelTypes["voyce_user_addresses_min_fields"],
	stddev?:ModelTypes["voyce_user_addresses_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_addresses_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_addresses_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_addresses_sum_fields"],
	var_pop?:ModelTypes["voyce_user_addresses_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_addresses_var_samp_fields"],
	variance?:ModelTypes["voyce_user_addresses_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_addresses_avg_fields"]: {
		city_id?:number,
	country_id?:number,
	id?:number,
	is_billing_address?:number,
	is_shipping_address?:number,
	state_id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_addresses". All fields are combined with a logical 'AND'. */
["voyce_user_addresses_bool_exp"]: GraphQLTypes["voyce_user_addresses_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_addresses" */
["voyce_user_addresses_constraint"]: GraphQLTypes["voyce_user_addresses_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_addresses" */
["voyce_user_addresses_inc_input"]: GraphQLTypes["voyce_user_addresses_inc_input"];
	/** input type for inserting data into table "voyce.user_addresses" */
["voyce_user_addresses_insert_input"]: GraphQLTypes["voyce_user_addresses_insert_input"];
	/** aggregate max on columns */
["voyce_user_addresses_max_fields"]: {
		address?:string,
	city_id?:number,
	country_id?:number,
	date_create?:ModelTypes["timestamp"],
	id?:number,
	is_billing_address?:ModelTypes["smallint"],
	is_shipping_address?:ModelTypes["smallint"],
	state_id?:number,
	user_id?:number,
	zip_code?:string
};
	/** aggregate min on columns */
["voyce_user_addresses_min_fields"]: {
		address?:string,
	city_id?:number,
	country_id?:number,
	date_create?:ModelTypes["timestamp"],
	id?:number,
	is_billing_address?:ModelTypes["smallint"],
	is_shipping_address?:ModelTypes["smallint"],
	state_id?:number,
	user_id?:number,
	zip_code?:string
};
	/** response of any mutation on the table "voyce.user_addresses" */
["voyce_user_addresses_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_addresses"][]
};
	/** on_conflict condition type for table "voyce.user_addresses" */
["voyce_user_addresses_on_conflict"]: GraphQLTypes["voyce_user_addresses_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_addresses". */
["voyce_user_addresses_order_by"]: GraphQLTypes["voyce_user_addresses_order_by"];
	/** primary key columns input for table: voyce.user_addresses */
["voyce_user_addresses_pk_columns_input"]: GraphQLTypes["voyce_user_addresses_pk_columns_input"];
	/** select columns of table "voyce.user_addresses" */
["voyce_user_addresses_select_column"]: GraphQLTypes["voyce_user_addresses_select_column"];
	/** input type for updating data in table "voyce.user_addresses" */
["voyce_user_addresses_set_input"]: GraphQLTypes["voyce_user_addresses_set_input"];
	/** aggregate stddev on columns */
["voyce_user_addresses_stddev_fields"]: {
		city_id?:number,
	country_id?:number,
	id?:number,
	is_billing_address?:number,
	is_shipping_address?:number,
	state_id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_addresses_stddev_pop_fields"]: {
		city_id?:number,
	country_id?:number,
	id?:number,
	is_billing_address?:number,
	is_shipping_address?:number,
	state_id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_addresses_stddev_samp_fields"]: {
		city_id?:number,
	country_id?:number,
	id?:number,
	is_billing_address?:number,
	is_shipping_address?:number,
	state_id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_addresses" */
["voyce_user_addresses_stream_cursor_input"]: GraphQLTypes["voyce_user_addresses_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_addresses_stream_cursor_value_input"]: GraphQLTypes["voyce_user_addresses_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_addresses_sum_fields"]: {
		city_id?:number,
	country_id?:number,
	id?:number,
	is_billing_address?:ModelTypes["smallint"],
	is_shipping_address?:ModelTypes["smallint"],
	state_id?:number,
	user_id?:number
};
	/** update columns of table "voyce.user_addresses" */
["voyce_user_addresses_update_column"]: GraphQLTypes["voyce_user_addresses_update_column"];
	["voyce_user_addresses_updates"]: GraphQLTypes["voyce_user_addresses_updates"];
	/** aggregate var_pop on columns */
["voyce_user_addresses_var_pop_fields"]: {
		city_id?:number,
	country_id?:number,
	id?:number,
	is_billing_address?:number,
	is_shipping_address?:number,
	state_id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_addresses_var_samp_fields"]: {
		city_id?:number,
	country_id?:number,
	id?:number,
	is_billing_address?:number,
	is_shipping_address?:number,
	state_id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_addresses_variance_fields"]: {
		city_id?:number,
	country_id?:number,
	id?:number,
	is_billing_address?:number,
	is_shipping_address?:number,
	state_id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.user_blocks" */
["voyce_user_blocks"]: {
		created_at:ModelTypes["timestamptz"],
	extras:string,
	id:number,
	linked_id:number,
	reason:string,
	type:string,
	user_id:number
};
	/** aggregated selection of "voyce.user_blocks" */
["voyce_user_blocks_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_blocks_aggregate_fields"],
	nodes:ModelTypes["voyce_user_blocks"][]
};
	/** aggregate fields of "voyce.user_blocks" */
["voyce_user_blocks_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_blocks_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_blocks_max_fields"],
	min?:ModelTypes["voyce_user_blocks_min_fields"],
	stddev?:ModelTypes["voyce_user_blocks_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_blocks_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_blocks_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_blocks_sum_fields"],
	var_pop?:ModelTypes["voyce_user_blocks_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_blocks_var_samp_fields"],
	variance?:ModelTypes["voyce_user_blocks_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_blocks_avg_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_blocks". All fields are combined with a logical 'AND'. */
["voyce_user_blocks_bool_exp"]: GraphQLTypes["voyce_user_blocks_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_blocks" */
["voyce_user_blocks_constraint"]: GraphQLTypes["voyce_user_blocks_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_blocks" */
["voyce_user_blocks_inc_input"]: GraphQLTypes["voyce_user_blocks_inc_input"];
	/** input type for inserting data into table "voyce.user_blocks" */
["voyce_user_blocks_insert_input"]: GraphQLTypes["voyce_user_blocks_insert_input"];
	/** aggregate max on columns */
["voyce_user_blocks_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	extras?:string,
	id?:number,
	linked_id?:number,
	reason?:string,
	type?:string,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_user_blocks_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	extras?:string,
	id?:number,
	linked_id?:number,
	reason?:string,
	type?:string,
	user_id?:number
};
	/** response of any mutation on the table "voyce.user_blocks" */
["voyce_user_blocks_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_blocks"][]
};
	/** on_conflict condition type for table "voyce.user_blocks" */
["voyce_user_blocks_on_conflict"]: GraphQLTypes["voyce_user_blocks_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_blocks". */
["voyce_user_blocks_order_by"]: GraphQLTypes["voyce_user_blocks_order_by"];
	/** primary key columns input for table: voyce.user_blocks */
["voyce_user_blocks_pk_columns_input"]: GraphQLTypes["voyce_user_blocks_pk_columns_input"];
	/** select columns of table "voyce.user_blocks" */
["voyce_user_blocks_select_column"]: GraphQLTypes["voyce_user_blocks_select_column"];
	/** input type for updating data in table "voyce.user_blocks" */
["voyce_user_blocks_set_input"]: GraphQLTypes["voyce_user_blocks_set_input"];
	/** aggregate stddev on columns */
["voyce_user_blocks_stddev_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_blocks_stddev_pop_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_blocks_stddev_samp_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_blocks" */
["voyce_user_blocks_stream_cursor_input"]: GraphQLTypes["voyce_user_blocks_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_blocks_stream_cursor_value_input"]: GraphQLTypes["voyce_user_blocks_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_blocks_sum_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** update columns of table "voyce.user_blocks" */
["voyce_user_blocks_update_column"]: GraphQLTypes["voyce_user_blocks_update_column"];
	["voyce_user_blocks_updates"]: GraphQLTypes["voyce_user_blocks_updates"];
	/** aggregate var_pop on columns */
["voyce_user_blocks_var_pop_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_blocks_var_samp_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_blocks_variance_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked"]: {
		chapter_id:number,
	created_at:ModelTypes["timestamptz"],
	end_date?:ModelTypes["date"],
	id:number,
	is_expired:boolean,
	/** An object relationship */
	series:ModelTypes["voyce_series"],
	series_id:number,
	start_date:ModelTypes["date"],
	updated_at:ModelTypes["timestamptz"],
	user_id:number
};
	/** aggregated selection of "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_chapters_unlocked_aggregate_fields"],
	nodes:ModelTypes["voyce_user_chapters_unlocked"][]
};
	/** aggregate fields of "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_chapters_unlocked_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_chapters_unlocked_max_fields"],
	min?:ModelTypes["voyce_user_chapters_unlocked_min_fields"],
	stddev?:ModelTypes["voyce_user_chapters_unlocked_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_chapters_unlocked_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_chapters_unlocked_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_chapters_unlocked_sum_fields"],
	var_pop?:ModelTypes["voyce_user_chapters_unlocked_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_chapters_unlocked_var_samp_fields"],
	variance?:ModelTypes["voyce_user_chapters_unlocked_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_chapters_unlocked_avg_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_chapters_unlocked". All fields are combined with a logical 'AND'. */
["voyce_user_chapters_unlocked_bool_exp"]: GraphQLTypes["voyce_user_chapters_unlocked_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_constraint"]: GraphQLTypes["voyce_user_chapters_unlocked_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_inc_input"]: GraphQLTypes["voyce_user_chapters_unlocked_inc_input"];
	/** input type for inserting data into table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_insert_input"]: GraphQLTypes["voyce_user_chapters_unlocked_insert_input"];
	/** aggregate max on columns */
["voyce_user_chapters_unlocked_max_fields"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamptz"],
	end_date?:ModelTypes["date"],
	id?:number,
	series_id?:number,
	start_date?:ModelTypes["date"],
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number
};
	/** aggregate min on columns */
["voyce_user_chapters_unlocked_min_fields"]: {
		chapter_id?:number,
	created_at?:ModelTypes["timestamptz"],
	end_date?:ModelTypes["date"],
	id?:number,
	series_id?:number,
	start_date?:ModelTypes["date"],
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number
};
	/** response of any mutation on the table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_chapters_unlocked"][]
};
	/** on_conflict condition type for table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_on_conflict"]: GraphQLTypes["voyce_user_chapters_unlocked_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_chapters_unlocked". */
["voyce_user_chapters_unlocked_order_by"]: GraphQLTypes["voyce_user_chapters_unlocked_order_by"];
	/** primary key columns input for table: voyce.user_chapters_unlocked */
["voyce_user_chapters_unlocked_pk_columns_input"]: GraphQLTypes["voyce_user_chapters_unlocked_pk_columns_input"];
	/** select columns of table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_select_column"]: GraphQLTypes["voyce_user_chapters_unlocked_select_column"];
	/** input type for updating data in table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_set_input"]: GraphQLTypes["voyce_user_chapters_unlocked_set_input"];
	/** aggregate stddev on columns */
["voyce_user_chapters_unlocked_stddev_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_chapters_unlocked_stddev_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_chapters_unlocked_stddev_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_chapters_unlocked" */
["voyce_user_chapters_unlocked_stream_cursor_input"]: GraphQLTypes["voyce_user_chapters_unlocked_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_chapters_unlocked_stream_cursor_value_input"]: GraphQLTypes["voyce_user_chapters_unlocked_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_chapters_unlocked_sum_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** update columns of table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_update_column"]: GraphQLTypes["voyce_user_chapters_unlocked_update_column"];
	["voyce_user_chapters_unlocked_updates"]: GraphQLTypes["voyce_user_chapters_unlocked_updates"];
	/** aggregate var_pop on columns */
["voyce_user_chapters_unlocked_var_pop_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_chapters_unlocked_var_samp_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_chapters_unlocked_variance_fields"]: {
		chapter_id?:number,
	id?:number,
	series_id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.user_contacts" */
["voyce_user_contacts"]: {
		contact:string,
	country_code:string,
	date_create:ModelTypes["timestamp"],
	id:number,
	is_primary:ModelTypes["smallint"],
	user_id:number
};
	/** aggregated selection of "voyce.user_contacts" */
["voyce_user_contacts_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_contacts_aggregate_fields"],
	nodes:ModelTypes["voyce_user_contacts"][]
};
	/** aggregate fields of "voyce.user_contacts" */
["voyce_user_contacts_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_contacts_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_contacts_max_fields"],
	min?:ModelTypes["voyce_user_contacts_min_fields"],
	stddev?:ModelTypes["voyce_user_contacts_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_contacts_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_contacts_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_contacts_sum_fields"],
	var_pop?:ModelTypes["voyce_user_contacts_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_contacts_var_samp_fields"],
	variance?:ModelTypes["voyce_user_contacts_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_contacts_avg_fields"]: {
		id?:number,
	is_primary?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_contacts". All fields are combined with a logical 'AND'. */
["voyce_user_contacts_bool_exp"]: GraphQLTypes["voyce_user_contacts_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_contacts" */
["voyce_user_contacts_constraint"]: GraphQLTypes["voyce_user_contacts_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_contacts" */
["voyce_user_contacts_inc_input"]: GraphQLTypes["voyce_user_contacts_inc_input"];
	/** input type for inserting data into table "voyce.user_contacts" */
["voyce_user_contacts_insert_input"]: GraphQLTypes["voyce_user_contacts_insert_input"];
	/** aggregate max on columns */
["voyce_user_contacts_max_fields"]: {
		contact?:string,
	country_code?:string,
	date_create?:ModelTypes["timestamp"],
	id?:number,
	is_primary?:ModelTypes["smallint"],
	user_id?:number
};
	/** aggregate min on columns */
["voyce_user_contacts_min_fields"]: {
		contact?:string,
	country_code?:string,
	date_create?:ModelTypes["timestamp"],
	id?:number,
	is_primary?:ModelTypes["smallint"],
	user_id?:number
};
	/** response of any mutation on the table "voyce.user_contacts" */
["voyce_user_contacts_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_contacts"][]
};
	/** on_conflict condition type for table "voyce.user_contacts" */
["voyce_user_contacts_on_conflict"]: GraphQLTypes["voyce_user_contacts_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_contacts". */
["voyce_user_contacts_order_by"]: GraphQLTypes["voyce_user_contacts_order_by"];
	/** primary key columns input for table: voyce.user_contacts */
["voyce_user_contacts_pk_columns_input"]: GraphQLTypes["voyce_user_contacts_pk_columns_input"];
	/** select columns of table "voyce.user_contacts" */
["voyce_user_contacts_select_column"]: GraphQLTypes["voyce_user_contacts_select_column"];
	/** input type for updating data in table "voyce.user_contacts" */
["voyce_user_contacts_set_input"]: GraphQLTypes["voyce_user_contacts_set_input"];
	/** aggregate stddev on columns */
["voyce_user_contacts_stddev_fields"]: {
		id?:number,
	is_primary?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_contacts_stddev_pop_fields"]: {
		id?:number,
	is_primary?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_contacts_stddev_samp_fields"]: {
		id?:number,
	is_primary?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_contacts" */
["voyce_user_contacts_stream_cursor_input"]: GraphQLTypes["voyce_user_contacts_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_contacts_stream_cursor_value_input"]: GraphQLTypes["voyce_user_contacts_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_contacts_sum_fields"]: {
		id?:number,
	is_primary?:ModelTypes["smallint"],
	user_id?:number
};
	/** update columns of table "voyce.user_contacts" */
["voyce_user_contacts_update_column"]: GraphQLTypes["voyce_user_contacts_update_column"];
	["voyce_user_contacts_updates"]: GraphQLTypes["voyce_user_contacts_updates"];
	/** aggregate var_pop on columns */
["voyce_user_contacts_var_pop_fields"]: {
		id?:number,
	is_primary?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_contacts_var_samp_fields"]: {
		id?:number,
	is_primary?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_contacts_variance_fields"]: {
		id?:number,
	is_primary?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.user_csat_ratings" */
["voyce_user_csat_ratings"]: {
		comment?:string,
	created_at:ModelTypes["timestamptz"],
	gtag_id?:string,
	id:number,
	is_answered?:boolean,
	more_info?:string,
	page_uri:string,
	rating?:string,
	tag:string,
	user_id?:number
};
	/** aggregated selection of "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_csat_ratings_aggregate_fields"],
	nodes:ModelTypes["voyce_user_csat_ratings"][]
};
	/** aggregate fields of "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_csat_ratings_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_csat_ratings_max_fields"],
	min?:ModelTypes["voyce_user_csat_ratings_min_fields"],
	stddev?:ModelTypes["voyce_user_csat_ratings_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_csat_ratings_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_csat_ratings_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_csat_ratings_sum_fields"],
	var_pop?:ModelTypes["voyce_user_csat_ratings_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_csat_ratings_var_samp_fields"],
	variance?:ModelTypes["voyce_user_csat_ratings_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_csat_ratings_avg_fields"]: {
		id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_csat_ratings". All fields are combined with a logical 'AND'. */
["voyce_user_csat_ratings_bool_exp"]: GraphQLTypes["voyce_user_csat_ratings_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_constraint"]: GraphQLTypes["voyce_user_csat_ratings_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_inc_input"]: GraphQLTypes["voyce_user_csat_ratings_inc_input"];
	/** input type for inserting data into table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_insert_input"]: GraphQLTypes["voyce_user_csat_ratings_insert_input"];
	/** aggregate max on columns */
["voyce_user_csat_ratings_max_fields"]: {
		comment?:string,
	created_at?:ModelTypes["timestamptz"],
	gtag_id?:string,
	id?:number,
	more_info?:string,
	page_uri?:string,
	rating?:string,
	tag?:string,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_user_csat_ratings_min_fields"]: {
		comment?:string,
	created_at?:ModelTypes["timestamptz"],
	gtag_id?:string,
	id?:number,
	more_info?:string,
	page_uri?:string,
	rating?:string,
	tag?:string,
	user_id?:number
};
	/** response of any mutation on the table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_csat_ratings"][]
};
	/** on_conflict condition type for table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_on_conflict"]: GraphQLTypes["voyce_user_csat_ratings_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_csat_ratings". */
["voyce_user_csat_ratings_order_by"]: GraphQLTypes["voyce_user_csat_ratings_order_by"];
	/** primary key columns input for table: voyce.user_csat_ratings */
["voyce_user_csat_ratings_pk_columns_input"]: GraphQLTypes["voyce_user_csat_ratings_pk_columns_input"];
	/** select columns of table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_select_column"]: GraphQLTypes["voyce_user_csat_ratings_select_column"];
	/** input type for updating data in table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_set_input"]: GraphQLTypes["voyce_user_csat_ratings_set_input"];
	/** aggregate stddev on columns */
["voyce_user_csat_ratings_stddev_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_csat_ratings_stddev_pop_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_csat_ratings_stddev_samp_fields"]: {
		id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_csat_ratings" */
["voyce_user_csat_ratings_stream_cursor_input"]: GraphQLTypes["voyce_user_csat_ratings_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_csat_ratings_stream_cursor_value_input"]: GraphQLTypes["voyce_user_csat_ratings_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_csat_ratings_sum_fields"]: {
		id?:number,
	user_id?:number
};
	/** update columns of table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_update_column"]: GraphQLTypes["voyce_user_csat_ratings_update_column"];
	["voyce_user_csat_ratings_updates"]: GraphQLTypes["voyce_user_csat_ratings_updates"];
	/** aggregate var_pop on columns */
["voyce_user_csat_ratings_var_pop_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_csat_ratings_var_samp_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_csat_ratings_variance_fields"]: {
		id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.user_education" */
["voyce_user_education"]: {
		date_create:ModelTypes["timestamp"],
	education_type_id:number,
	id:number,
	in_progress?:ModelTypes["smallint"],
	institute:string,
	passing_date?:ModelTypes["date"],
	title:string,
	user_id:number
};
	/** aggregated selection of "voyce.user_education" */
["voyce_user_education_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_education_aggregate_fields"],
	nodes:ModelTypes["voyce_user_education"][]
};
	/** aggregate fields of "voyce.user_education" */
["voyce_user_education_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_education_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_education_max_fields"],
	min?:ModelTypes["voyce_user_education_min_fields"],
	stddev?:ModelTypes["voyce_user_education_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_education_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_education_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_education_sum_fields"],
	var_pop?:ModelTypes["voyce_user_education_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_education_var_samp_fields"],
	variance?:ModelTypes["voyce_user_education_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_education_avg_fields"]: {
		education_type_id?:number,
	id?:number,
	in_progress?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_education". All fields are combined with a logical 'AND'. */
["voyce_user_education_bool_exp"]: GraphQLTypes["voyce_user_education_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_education" */
["voyce_user_education_constraint"]: GraphQLTypes["voyce_user_education_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_education" */
["voyce_user_education_inc_input"]: GraphQLTypes["voyce_user_education_inc_input"];
	/** input type for inserting data into table "voyce.user_education" */
["voyce_user_education_insert_input"]: GraphQLTypes["voyce_user_education_insert_input"];
	/** aggregate max on columns */
["voyce_user_education_max_fields"]: {
		date_create?:ModelTypes["timestamp"],
	education_type_id?:number,
	id?:number,
	in_progress?:ModelTypes["smallint"],
	institute?:string,
	passing_date?:ModelTypes["date"],
	title?:string,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_user_education_min_fields"]: {
		date_create?:ModelTypes["timestamp"],
	education_type_id?:number,
	id?:number,
	in_progress?:ModelTypes["smallint"],
	institute?:string,
	passing_date?:ModelTypes["date"],
	title?:string,
	user_id?:number
};
	/** response of any mutation on the table "voyce.user_education" */
["voyce_user_education_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_education"][]
};
	/** on_conflict condition type for table "voyce.user_education" */
["voyce_user_education_on_conflict"]: GraphQLTypes["voyce_user_education_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_education". */
["voyce_user_education_order_by"]: GraphQLTypes["voyce_user_education_order_by"];
	/** primary key columns input for table: voyce.user_education */
["voyce_user_education_pk_columns_input"]: GraphQLTypes["voyce_user_education_pk_columns_input"];
	/** select columns of table "voyce.user_education" */
["voyce_user_education_select_column"]: GraphQLTypes["voyce_user_education_select_column"];
	/** input type for updating data in table "voyce.user_education" */
["voyce_user_education_set_input"]: GraphQLTypes["voyce_user_education_set_input"];
	/** aggregate stddev on columns */
["voyce_user_education_stddev_fields"]: {
		education_type_id?:number,
	id?:number,
	in_progress?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_education_stddev_pop_fields"]: {
		education_type_id?:number,
	id?:number,
	in_progress?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_education_stddev_samp_fields"]: {
		education_type_id?:number,
	id?:number,
	in_progress?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_education" */
["voyce_user_education_stream_cursor_input"]: GraphQLTypes["voyce_user_education_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_education_stream_cursor_value_input"]: GraphQLTypes["voyce_user_education_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_education_sum_fields"]: {
		education_type_id?:number,
	id?:number,
	in_progress?:ModelTypes["smallint"],
	user_id?:number
};
	/** update columns of table "voyce.user_education" */
["voyce_user_education_update_column"]: GraphQLTypes["voyce_user_education_update_column"];
	["voyce_user_education_updates"]: GraphQLTypes["voyce_user_education_updates"];
	/** aggregate var_pop on columns */
["voyce_user_education_var_pop_fields"]: {
		education_type_id?:number,
	id?:number,
	in_progress?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_education_var_samp_fields"]: {
		education_type_id?:number,
	id?:number,
	in_progress?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_education_variance_fields"]: {
		education_type_id?:number,
	id?:number,
	in_progress?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.user_level" */
["voyce_user_level"]: {
		created_at:ModelTypes["timestamptz"],
	experience:number,
	id:number,
	level:number,
	updated_at:ModelTypes["timestamptz"],
	user_id:number
};
	/** aggregated selection of "voyce.user_level" */
["voyce_user_level_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_level_aggregate_fields"],
	nodes:ModelTypes["voyce_user_level"][]
};
	/** aggregate fields of "voyce.user_level" */
["voyce_user_level_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_level_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_level_max_fields"],
	min?:ModelTypes["voyce_user_level_min_fields"],
	stddev?:ModelTypes["voyce_user_level_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_level_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_level_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_level_sum_fields"],
	var_pop?:ModelTypes["voyce_user_level_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_level_var_samp_fields"],
	variance?:ModelTypes["voyce_user_level_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_level_avg_fields"]: {
		experience?:number,
	id?:number,
	level?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_level". All fields are combined with a logical 'AND'. */
["voyce_user_level_bool_exp"]: GraphQLTypes["voyce_user_level_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_level" */
["voyce_user_level_constraint"]: GraphQLTypes["voyce_user_level_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_level" */
["voyce_user_level_inc_input"]: GraphQLTypes["voyce_user_level_inc_input"];
	/** input type for inserting data into table "voyce.user_level" */
["voyce_user_level_insert_input"]: GraphQLTypes["voyce_user_level_insert_input"];
	/** aggregate max on columns */
["voyce_user_level_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	experience?:number,
	id?:number,
	level?:number,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number
};
	/** aggregate min on columns */
["voyce_user_level_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	experience?:number,
	id?:number,
	level?:number,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number
};
	/** response of any mutation on the table "voyce.user_level" */
["voyce_user_level_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_level"][]
};
	/** on_conflict condition type for table "voyce.user_level" */
["voyce_user_level_on_conflict"]: GraphQLTypes["voyce_user_level_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_level". */
["voyce_user_level_order_by"]: GraphQLTypes["voyce_user_level_order_by"];
	/** primary key columns input for table: voyce.user_level */
["voyce_user_level_pk_columns_input"]: GraphQLTypes["voyce_user_level_pk_columns_input"];
	/** select columns of table "voyce.user_level" */
["voyce_user_level_select_column"]: GraphQLTypes["voyce_user_level_select_column"];
	/** input type for updating data in table "voyce.user_level" */
["voyce_user_level_set_input"]: GraphQLTypes["voyce_user_level_set_input"];
	/** aggregate stddev on columns */
["voyce_user_level_stddev_fields"]: {
		experience?:number,
	id?:number,
	level?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_level_stddev_pop_fields"]: {
		experience?:number,
	id?:number,
	level?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_level_stddev_samp_fields"]: {
		experience?:number,
	id?:number,
	level?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_level" */
["voyce_user_level_stream_cursor_input"]: GraphQLTypes["voyce_user_level_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_level_stream_cursor_value_input"]: GraphQLTypes["voyce_user_level_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_level_sum_fields"]: {
		experience?:number,
	id?:number,
	level?:number,
	user_id?:number
};
	/** update columns of table "voyce.user_level" */
["voyce_user_level_update_column"]: GraphQLTypes["voyce_user_level_update_column"];
	["voyce_user_level_updates"]: GraphQLTypes["voyce_user_level_updates"];
	/** aggregate var_pop on columns */
["voyce_user_level_var_pop_fields"]: {
		experience?:number,
	id?:number,
	level?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_level_var_samp_fields"]: {
		experience?:number,
	id?:number,
	level?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_level_variance_fields"]: {
		experience?:number,
	id?:number,
	level?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.user_linked_accounts" */
["voyce_user_linked_accounts"]: {
		created_at?:ModelTypes["timestamptz"],
	hash:string,
	id:number,
	identity:string,
	main_account:boolean,
	provider:string,
	sub:string,
	/** An object relationship */
	user?:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_linked_accounts_aggregate_fields"],
	nodes:ModelTypes["voyce_user_linked_accounts"][]
};
	/** aggregate fields of "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_linked_accounts_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_linked_accounts_max_fields"],
	min?:ModelTypes["voyce_user_linked_accounts_min_fields"],
	stddev?:ModelTypes["voyce_user_linked_accounts_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_linked_accounts_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_linked_accounts_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_linked_accounts_sum_fields"],
	var_pop?:ModelTypes["voyce_user_linked_accounts_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_linked_accounts_var_samp_fields"],
	variance?:ModelTypes["voyce_user_linked_accounts_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_linked_accounts_avg_fields"]: {
		id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_linked_accounts". All fields are combined with a logical 'AND'. */
["voyce_user_linked_accounts_bool_exp"]: GraphQLTypes["voyce_user_linked_accounts_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_constraint"]: GraphQLTypes["voyce_user_linked_accounts_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_inc_input"]: GraphQLTypes["voyce_user_linked_accounts_inc_input"];
	/** input type for inserting data into table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_insert_input"]: GraphQLTypes["voyce_user_linked_accounts_insert_input"];
	/** aggregate max on columns */
["voyce_user_linked_accounts_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	hash?:string,
	id?:number,
	identity?:string,
	provider?:string,
	sub?:string,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_user_linked_accounts_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	hash?:string,
	id?:number,
	identity?:string,
	provider?:string,
	sub?:string,
	user_id?:number
};
	/** response of any mutation on the table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_linked_accounts"][]
};
	/** on_conflict condition type for table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_on_conflict"]: GraphQLTypes["voyce_user_linked_accounts_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_linked_accounts". */
["voyce_user_linked_accounts_order_by"]: GraphQLTypes["voyce_user_linked_accounts_order_by"];
	/** primary key columns input for table: voyce.user_linked_accounts */
["voyce_user_linked_accounts_pk_columns_input"]: GraphQLTypes["voyce_user_linked_accounts_pk_columns_input"];
	/** select columns of table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_select_column"]: GraphQLTypes["voyce_user_linked_accounts_select_column"];
	/** input type for updating data in table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_set_input"]: GraphQLTypes["voyce_user_linked_accounts_set_input"];
	/** aggregate stddev on columns */
["voyce_user_linked_accounts_stddev_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_linked_accounts_stddev_pop_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_linked_accounts_stddev_samp_fields"]: {
		id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_linked_accounts" */
["voyce_user_linked_accounts_stream_cursor_input"]: GraphQLTypes["voyce_user_linked_accounts_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_linked_accounts_stream_cursor_value_input"]: GraphQLTypes["voyce_user_linked_accounts_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_linked_accounts_sum_fields"]: {
		id?:number,
	user_id?:number
};
	/** update columns of table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_update_column"]: GraphQLTypes["voyce_user_linked_accounts_update_column"];
	["voyce_user_linked_accounts_updates"]: GraphQLTypes["voyce_user_linked_accounts_updates"];
	/** aggregate var_pop on columns */
["voyce_user_linked_accounts_var_pop_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_linked_accounts_var_samp_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_linked_accounts_variance_fields"]: {
		id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.user_login_history" */
["voyce_user_login_history"]: {
		browser:string,
	city?:string,
	country?:string,
	id:number,
	ip:string,
	is_login:ModelTypes["smallint"],
	latitude?:string,
	login_time?:ModelTypes["timestamp"],
	logout_time?:ModelTypes["timestamp"],
	longitude?:string,
	os?:string,
	region?:string,
	token?:string,
	user_agent?:string,
	user_id:number
};
	/** aggregated selection of "voyce.user_login_history" */
["voyce_user_login_history_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_login_history_aggregate_fields"],
	nodes:ModelTypes["voyce_user_login_history"][]
};
	/** aggregate fields of "voyce.user_login_history" */
["voyce_user_login_history_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_login_history_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_login_history_max_fields"],
	min?:ModelTypes["voyce_user_login_history_min_fields"],
	stddev?:ModelTypes["voyce_user_login_history_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_login_history_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_login_history_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_login_history_sum_fields"],
	var_pop?:ModelTypes["voyce_user_login_history_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_login_history_var_samp_fields"],
	variance?:ModelTypes["voyce_user_login_history_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_login_history_avg_fields"]: {
		id?:number,
	is_login?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_login_history". All fields are combined with a logical 'AND'. */
["voyce_user_login_history_bool_exp"]: GraphQLTypes["voyce_user_login_history_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_login_history" */
["voyce_user_login_history_constraint"]: GraphQLTypes["voyce_user_login_history_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_login_history" */
["voyce_user_login_history_inc_input"]: GraphQLTypes["voyce_user_login_history_inc_input"];
	/** input type for inserting data into table "voyce.user_login_history" */
["voyce_user_login_history_insert_input"]: GraphQLTypes["voyce_user_login_history_insert_input"];
	/** aggregate max on columns */
["voyce_user_login_history_max_fields"]: {
		browser?:string,
	city?:string,
	country?:string,
	id?:number,
	ip?:string,
	is_login?:ModelTypes["smallint"],
	latitude?:string,
	login_time?:ModelTypes["timestamp"],
	logout_time?:ModelTypes["timestamp"],
	longitude?:string,
	os?:string,
	region?:string,
	token?:string,
	user_agent?:string,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_user_login_history_min_fields"]: {
		browser?:string,
	city?:string,
	country?:string,
	id?:number,
	ip?:string,
	is_login?:ModelTypes["smallint"],
	latitude?:string,
	login_time?:ModelTypes["timestamp"],
	logout_time?:ModelTypes["timestamp"],
	longitude?:string,
	os?:string,
	region?:string,
	token?:string,
	user_agent?:string,
	user_id?:number
};
	/** response of any mutation on the table "voyce.user_login_history" */
["voyce_user_login_history_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_login_history"][]
};
	/** on_conflict condition type for table "voyce.user_login_history" */
["voyce_user_login_history_on_conflict"]: GraphQLTypes["voyce_user_login_history_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_login_history". */
["voyce_user_login_history_order_by"]: GraphQLTypes["voyce_user_login_history_order_by"];
	/** primary key columns input for table: voyce.user_login_history */
["voyce_user_login_history_pk_columns_input"]: GraphQLTypes["voyce_user_login_history_pk_columns_input"];
	/** select columns of table "voyce.user_login_history" */
["voyce_user_login_history_select_column"]: GraphQLTypes["voyce_user_login_history_select_column"];
	/** input type for updating data in table "voyce.user_login_history" */
["voyce_user_login_history_set_input"]: GraphQLTypes["voyce_user_login_history_set_input"];
	/** aggregate stddev on columns */
["voyce_user_login_history_stddev_fields"]: {
		id?:number,
	is_login?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_login_history_stddev_pop_fields"]: {
		id?:number,
	is_login?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_login_history_stddev_samp_fields"]: {
		id?:number,
	is_login?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_login_history" */
["voyce_user_login_history_stream_cursor_input"]: GraphQLTypes["voyce_user_login_history_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_login_history_stream_cursor_value_input"]: GraphQLTypes["voyce_user_login_history_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_login_history_sum_fields"]: {
		id?:number,
	is_login?:ModelTypes["smallint"],
	user_id?:number
};
	/** update columns of table "voyce.user_login_history" */
["voyce_user_login_history_update_column"]: GraphQLTypes["voyce_user_login_history_update_column"];
	["voyce_user_login_history_updates"]: GraphQLTypes["voyce_user_login_history_updates"];
	/** aggregate var_pop on columns */
["voyce_user_login_history_var_pop_fields"]: {
		id?:number,
	is_login?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_login_history_var_samp_fields"]: {
		id?:number,
	is_login?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_login_history_variance_fields"]: {
		id?:number,
	is_login?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.user_quest_ids" */
["voyce_user_quest_ids"]: {
		created_at:ModelTypes["timestamptz"],
	id:number,
	/** An object relationship */
	quest?:ModelTypes["voyce_user_quests"],
	tag:string,
	type:string,
	type_id:number,
	user_id:number,
	user_quest_id:number
};
	/** aggregated selection of "voyce.user_quest_ids" */
["voyce_user_quest_ids_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_quest_ids_aggregate_fields"],
	nodes:ModelTypes["voyce_user_quest_ids"][]
};
	/** aggregate fields of "voyce.user_quest_ids" */
["voyce_user_quest_ids_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_quest_ids_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_quest_ids_max_fields"],
	min?:ModelTypes["voyce_user_quest_ids_min_fields"],
	stddev?:ModelTypes["voyce_user_quest_ids_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_quest_ids_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_quest_ids_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_quest_ids_sum_fields"],
	var_pop?:ModelTypes["voyce_user_quest_ids_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_quest_ids_var_samp_fields"],
	variance?:ModelTypes["voyce_user_quest_ids_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_quest_ids_avg_fields"]: {
		id?:number,
	type_id?:number,
	user_id?:number,
	user_quest_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_quest_ids". All fields are combined with a logical 'AND'. */
["voyce_user_quest_ids_bool_exp"]: GraphQLTypes["voyce_user_quest_ids_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_quest_ids" */
["voyce_user_quest_ids_constraint"]: GraphQLTypes["voyce_user_quest_ids_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_quest_ids" */
["voyce_user_quest_ids_inc_input"]: GraphQLTypes["voyce_user_quest_ids_inc_input"];
	/** input type for inserting data into table "voyce.user_quest_ids" */
["voyce_user_quest_ids_insert_input"]: GraphQLTypes["voyce_user_quest_ids_insert_input"];
	/** aggregate max on columns */
["voyce_user_quest_ids_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	tag?:string,
	type?:string,
	type_id?:number,
	user_id?:number,
	user_quest_id?:number
};
	/** aggregate min on columns */
["voyce_user_quest_ids_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	tag?:string,
	type?:string,
	type_id?:number,
	user_id?:number,
	user_quest_id?:number
};
	/** response of any mutation on the table "voyce.user_quest_ids" */
["voyce_user_quest_ids_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_quest_ids"][]
};
	/** on_conflict condition type for table "voyce.user_quest_ids" */
["voyce_user_quest_ids_on_conflict"]: GraphQLTypes["voyce_user_quest_ids_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_quest_ids". */
["voyce_user_quest_ids_order_by"]: GraphQLTypes["voyce_user_quest_ids_order_by"];
	/** primary key columns input for table: voyce.user_quest_ids */
["voyce_user_quest_ids_pk_columns_input"]: GraphQLTypes["voyce_user_quest_ids_pk_columns_input"];
	/** select columns of table "voyce.user_quest_ids" */
["voyce_user_quest_ids_select_column"]: GraphQLTypes["voyce_user_quest_ids_select_column"];
	/** input type for updating data in table "voyce.user_quest_ids" */
["voyce_user_quest_ids_set_input"]: GraphQLTypes["voyce_user_quest_ids_set_input"];
	/** aggregate stddev on columns */
["voyce_user_quest_ids_stddev_fields"]: {
		id?:number,
	type_id?:number,
	user_id?:number,
	user_quest_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_quest_ids_stddev_pop_fields"]: {
		id?:number,
	type_id?:number,
	user_id?:number,
	user_quest_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_quest_ids_stddev_samp_fields"]: {
		id?:number,
	type_id?:number,
	user_id?:number,
	user_quest_id?:number
};
	/** Streaming cursor of the table "voyce_user_quest_ids" */
["voyce_user_quest_ids_stream_cursor_input"]: GraphQLTypes["voyce_user_quest_ids_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_quest_ids_stream_cursor_value_input"]: GraphQLTypes["voyce_user_quest_ids_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_quest_ids_sum_fields"]: {
		id?:number,
	type_id?:number,
	user_id?:number,
	user_quest_id?:number
};
	/** update columns of table "voyce.user_quest_ids" */
["voyce_user_quest_ids_update_column"]: GraphQLTypes["voyce_user_quest_ids_update_column"];
	["voyce_user_quest_ids_updates"]: GraphQLTypes["voyce_user_quest_ids_updates"];
	/** aggregate var_pop on columns */
["voyce_user_quest_ids_var_pop_fields"]: {
		id?:number,
	type_id?:number,
	user_id?:number,
	user_quest_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_quest_ids_var_samp_fields"]: {
		id?:number,
	type_id?:number,
	user_id?:number,
	user_quest_id?:number
};
	/** aggregate variance on columns */
["voyce_user_quest_ids_variance_fields"]: {
		id?:number,
	type_id?:number,
	user_id?:number,
	user_quest_id?:number
};
	/** columns and relationships of "voyce.user_quest_rewards" */
["voyce_user_quest_rewards"]: {
		created_at:ModelTypes["timestamptz"],
	description:string,
	id:number,
	metadata:string,
	type:string,
	user_id:number,
	user_quest_id:number,
	value:number
};
	/** aggregated selection of "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_quest_rewards_aggregate_fields"],
	nodes:ModelTypes["voyce_user_quest_rewards"][]
};
	/** aggregate fields of "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_quest_rewards_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_quest_rewards_max_fields"],
	min?:ModelTypes["voyce_user_quest_rewards_min_fields"],
	stddev?:ModelTypes["voyce_user_quest_rewards_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_quest_rewards_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_quest_rewards_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_quest_rewards_sum_fields"],
	var_pop?:ModelTypes["voyce_user_quest_rewards_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_quest_rewards_var_samp_fields"],
	variance?:ModelTypes["voyce_user_quest_rewards_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_quest_rewards_avg_fields"]: {
		id?:number,
	user_id?:number,
	user_quest_id?:number,
	value?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_quest_rewards". All fields are combined with a logical 'AND'. */
["voyce_user_quest_rewards_bool_exp"]: GraphQLTypes["voyce_user_quest_rewards_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_constraint"]: GraphQLTypes["voyce_user_quest_rewards_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_inc_input"]: GraphQLTypes["voyce_user_quest_rewards_inc_input"];
	/** input type for inserting data into table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_insert_input"]: GraphQLTypes["voyce_user_quest_rewards_insert_input"];
	/** aggregate max on columns */
["voyce_user_quest_rewards_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	description?:string,
	id?:number,
	metadata?:string,
	type?:string,
	user_id?:number,
	user_quest_id?:number,
	value?:number
};
	/** aggregate min on columns */
["voyce_user_quest_rewards_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	description?:string,
	id?:number,
	metadata?:string,
	type?:string,
	user_id?:number,
	user_quest_id?:number,
	value?:number
};
	/** response of any mutation on the table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_quest_rewards"][]
};
	/** on_conflict condition type for table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_on_conflict"]: GraphQLTypes["voyce_user_quest_rewards_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_quest_rewards". */
["voyce_user_quest_rewards_order_by"]: GraphQLTypes["voyce_user_quest_rewards_order_by"];
	/** primary key columns input for table: voyce.user_quest_rewards */
["voyce_user_quest_rewards_pk_columns_input"]: GraphQLTypes["voyce_user_quest_rewards_pk_columns_input"];
	/** select columns of table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_select_column"]: GraphQLTypes["voyce_user_quest_rewards_select_column"];
	/** input type for updating data in table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_set_input"]: GraphQLTypes["voyce_user_quest_rewards_set_input"];
	/** aggregate stddev on columns */
["voyce_user_quest_rewards_stddev_fields"]: {
		id?:number,
	user_id?:number,
	user_quest_id?:number,
	value?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_quest_rewards_stddev_pop_fields"]: {
		id?:number,
	user_id?:number,
	user_quest_id?:number,
	value?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_quest_rewards_stddev_samp_fields"]: {
		id?:number,
	user_id?:number,
	user_quest_id?:number,
	value?:number
};
	/** Streaming cursor of the table "voyce_user_quest_rewards" */
["voyce_user_quest_rewards_stream_cursor_input"]: GraphQLTypes["voyce_user_quest_rewards_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_quest_rewards_stream_cursor_value_input"]: GraphQLTypes["voyce_user_quest_rewards_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_quest_rewards_sum_fields"]: {
		id?:number,
	user_id?:number,
	user_quest_id?:number,
	value?:number
};
	/** update columns of table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_update_column"]: GraphQLTypes["voyce_user_quest_rewards_update_column"];
	["voyce_user_quest_rewards_updates"]: GraphQLTypes["voyce_user_quest_rewards_updates"];
	/** aggregate var_pop on columns */
["voyce_user_quest_rewards_var_pop_fields"]: {
		id?:number,
	user_id?:number,
	user_quest_id?:number,
	value?:number
};
	/** aggregate var_samp on columns */
["voyce_user_quest_rewards_var_samp_fields"]: {
		id?:number,
	user_id?:number,
	user_quest_id?:number,
	value?:number
};
	/** aggregate variance on columns */
["voyce_user_quest_rewards_variance_fields"]: {
		id?:number,
	user_id?:number,
	user_quest_id?:number,
	value?:number
};
	/** columns and relationships of "voyce.user_quests" */
["voyce_user_quests"]: {
		completed_at:ModelTypes["timestamptz"],
	created_at:ModelTypes["timestamptz"],
	data:ModelTypes["json"],
	/** e.g. once = once, daily = date (2024-06-08), weekly = week # or week first day (2024-06-08) */
	frequency_tag:string,
	id:number,
	notified:boolean,
	/** An object relationship */
	quest:ModelTypes["voyce_quests"],
	quest_id:number,
	rewarded_at?:ModelTypes["timestamptz"],
	rewards_data:ModelTypes["json"],
	status:string,
	updated_at:ModelTypes["timestamptz"],
	user_id:number,
	/** An object relationship */
	vtag?:ModelTypes["voyce_user_vtags"]
};
	/** aggregated selection of "voyce.user_quests" */
["voyce_user_quests_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_quests_aggregate_fields"],
	nodes:ModelTypes["voyce_user_quests"][]
};
	/** aggregate fields of "voyce.user_quests" */
["voyce_user_quests_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_quests_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_quests_max_fields"],
	min?:ModelTypes["voyce_user_quests_min_fields"],
	stddev?:ModelTypes["voyce_user_quests_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_quests_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_quests_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_quests_sum_fields"],
	var_pop?:ModelTypes["voyce_user_quests_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_quests_var_samp_fields"],
	variance?:ModelTypes["voyce_user_quests_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_quests_avg_fields"]: {
		id?:number,
	quest_id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_quests". All fields are combined with a logical 'AND'. */
["voyce_user_quests_bool_exp"]: GraphQLTypes["voyce_user_quests_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_quests" */
["voyce_user_quests_constraint"]: GraphQLTypes["voyce_user_quests_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_quests" */
["voyce_user_quests_inc_input"]: GraphQLTypes["voyce_user_quests_inc_input"];
	/** input type for inserting data into table "voyce.user_quests" */
["voyce_user_quests_insert_input"]: GraphQLTypes["voyce_user_quests_insert_input"];
	/** aggregate max on columns */
["voyce_user_quests_max_fields"]: {
		completed_at?:ModelTypes["timestamptz"],
	created_at?:ModelTypes["timestamptz"],
	/** e.g. once = once, daily = date (2024-06-08), weekly = week # or week first day (2024-06-08) */
	frequency_tag?:string,
	id?:number,
	quest_id?:number,
	rewarded_at?:ModelTypes["timestamptz"],
	status?:string,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number
};
	/** aggregate min on columns */
["voyce_user_quests_min_fields"]: {
		completed_at?:ModelTypes["timestamptz"],
	created_at?:ModelTypes["timestamptz"],
	/** e.g. once = once, daily = date (2024-06-08), weekly = week # or week first day (2024-06-08) */
	frequency_tag?:string,
	id?:number,
	quest_id?:number,
	rewarded_at?:ModelTypes["timestamptz"],
	status?:string,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number
};
	/** response of any mutation on the table "voyce.user_quests" */
["voyce_user_quests_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_quests"][]
};
	/** input type for inserting object relation for remote table "voyce.user_quests" */
["voyce_user_quests_obj_rel_insert_input"]: GraphQLTypes["voyce_user_quests_obj_rel_insert_input"];
	/** columns and relationships of "voyce.user_quests_old" */
["voyce_user_quests_old"]: {
		created_at:ModelTypes["timestamp"],
	id:number,
	progress?:ModelTypes["numeric"],
	/** An object relationship */
	quest:ModelTypes["voyce_quests_old"],
	quest_id:number,
	updated_at?:ModelTypes["timestamp"],
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.user_quests_old" */
["voyce_user_quests_old_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_quests_old_aggregate_fields"],
	nodes:ModelTypes["voyce_user_quests_old"][]
};
	["voyce_user_quests_old_aggregate_bool_exp"]: GraphQLTypes["voyce_user_quests_old_aggregate_bool_exp"];
	["voyce_user_quests_old_aggregate_bool_exp_count"]: GraphQLTypes["voyce_user_quests_old_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.user_quests_old" */
["voyce_user_quests_old_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_quests_old_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_quests_old_max_fields"],
	min?:ModelTypes["voyce_user_quests_old_min_fields"],
	stddev?:ModelTypes["voyce_user_quests_old_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_quests_old_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_quests_old_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_quests_old_sum_fields"],
	var_pop?:ModelTypes["voyce_user_quests_old_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_quests_old_var_samp_fields"],
	variance?:ModelTypes["voyce_user_quests_old_variance_fields"]
};
	/** order by aggregate values of table "voyce.user_quests_old" */
["voyce_user_quests_old_aggregate_order_by"]: GraphQLTypes["voyce_user_quests_old_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.user_quests_old" */
["voyce_user_quests_old_arr_rel_insert_input"]: GraphQLTypes["voyce_user_quests_old_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_user_quests_old_avg_fields"]: {
		id?:number,
	progress?:number,
	quest_id?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_avg_order_by"]: GraphQLTypes["voyce_user_quests_old_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.user_quests_old". All fields are combined with a logical 'AND'. */
["voyce_user_quests_old_bool_exp"]: GraphQLTypes["voyce_user_quests_old_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_quests_old" */
["voyce_user_quests_old_constraint"]: GraphQLTypes["voyce_user_quests_old_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_quests_old" */
["voyce_user_quests_old_inc_input"]: GraphQLTypes["voyce_user_quests_old_inc_input"];
	/** input type for inserting data into table "voyce.user_quests_old" */
["voyce_user_quests_old_insert_input"]: GraphQLTypes["voyce_user_quests_old_insert_input"];
	/** aggregate max on columns */
["voyce_user_quests_old_max_fields"]: {
		created_at?:ModelTypes["timestamp"],
	id?:number,
	progress?:ModelTypes["numeric"],
	quest_id?:number,
	updated_at?:ModelTypes["timestamp"],
	user_id?:number
};
	/** order by max() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_max_order_by"]: GraphQLTypes["voyce_user_quests_old_max_order_by"];
	/** aggregate min on columns */
["voyce_user_quests_old_min_fields"]: {
		created_at?:ModelTypes["timestamp"],
	id?:number,
	progress?:ModelTypes["numeric"],
	quest_id?:number,
	updated_at?:ModelTypes["timestamp"],
	user_id?:number
};
	/** order by min() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_min_order_by"]: GraphQLTypes["voyce_user_quests_old_min_order_by"];
	/** response of any mutation on the table "voyce.user_quests_old" */
["voyce_user_quests_old_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_quests_old"][]
};
	/** on_conflict condition type for table "voyce.user_quests_old" */
["voyce_user_quests_old_on_conflict"]: GraphQLTypes["voyce_user_quests_old_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_quests_old". */
["voyce_user_quests_old_order_by"]: GraphQLTypes["voyce_user_quests_old_order_by"];
	/** primary key columns input for table: voyce.user_quests_old */
["voyce_user_quests_old_pk_columns_input"]: GraphQLTypes["voyce_user_quests_old_pk_columns_input"];
	/** select columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_select_column"]: GraphQLTypes["voyce_user_quests_old_select_column"];
	/** input type for updating data in table "voyce.user_quests_old" */
["voyce_user_quests_old_set_input"]: GraphQLTypes["voyce_user_quests_old_set_input"];
	/** aggregate stddev on columns */
["voyce_user_quests_old_stddev_fields"]: {
		id?:number,
	progress?:number,
	quest_id?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_stddev_order_by"]: GraphQLTypes["voyce_user_quests_old_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_user_quests_old_stddev_pop_fields"]: {
		id?:number,
	progress?:number,
	quest_id?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_stddev_pop_order_by"]: GraphQLTypes["voyce_user_quests_old_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_user_quests_old_stddev_samp_fields"]: {
		id?:number,
	progress?:number,
	quest_id?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_stddev_samp_order_by"]: GraphQLTypes["voyce_user_quests_old_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_user_quests_old" */
["voyce_user_quests_old_stream_cursor_input"]: GraphQLTypes["voyce_user_quests_old_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_quests_old_stream_cursor_value_input"]: GraphQLTypes["voyce_user_quests_old_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_quests_old_sum_fields"]: {
		id?:number,
	progress?:ModelTypes["numeric"],
	quest_id?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_sum_order_by"]: GraphQLTypes["voyce_user_quests_old_sum_order_by"];
	/** update columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_update_column"]: GraphQLTypes["voyce_user_quests_old_update_column"];
	["voyce_user_quests_old_updates"]: GraphQLTypes["voyce_user_quests_old_updates"];
	/** aggregate var_pop on columns */
["voyce_user_quests_old_var_pop_fields"]: {
		id?:number,
	progress?:number,
	quest_id?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_var_pop_order_by"]: GraphQLTypes["voyce_user_quests_old_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_user_quests_old_var_samp_fields"]: {
		id?:number,
	progress?:number,
	quest_id?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_var_samp_order_by"]: GraphQLTypes["voyce_user_quests_old_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_user_quests_old_variance_fields"]: {
		id?:number,
	progress?:number,
	quest_id?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_variance_order_by"]: GraphQLTypes["voyce_user_quests_old_variance_order_by"];
	/** on_conflict condition type for table "voyce.user_quests" */
["voyce_user_quests_on_conflict"]: GraphQLTypes["voyce_user_quests_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_quests". */
["voyce_user_quests_order_by"]: GraphQLTypes["voyce_user_quests_order_by"];
	/** primary key columns input for table: voyce.user_quests */
["voyce_user_quests_pk_columns_input"]: GraphQLTypes["voyce_user_quests_pk_columns_input"];
	/** select columns of table "voyce.user_quests" */
["voyce_user_quests_select_column"]: GraphQLTypes["voyce_user_quests_select_column"];
	/** input type for updating data in table "voyce.user_quests" */
["voyce_user_quests_set_input"]: GraphQLTypes["voyce_user_quests_set_input"];
	/** aggregate stddev on columns */
["voyce_user_quests_stddev_fields"]: {
		id?:number,
	quest_id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_quests_stddev_pop_fields"]: {
		id?:number,
	quest_id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_quests_stddev_samp_fields"]: {
		id?:number,
	quest_id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_quests" */
["voyce_user_quests_stream_cursor_input"]: GraphQLTypes["voyce_user_quests_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_quests_stream_cursor_value_input"]: GraphQLTypes["voyce_user_quests_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_quests_sum_fields"]: {
		id?:number,
	quest_id?:number,
	user_id?:number
};
	/** update columns of table "voyce.user_quests" */
["voyce_user_quests_update_column"]: GraphQLTypes["voyce_user_quests_update_column"];
	["voyce_user_quests_updates"]: GraphQLTypes["voyce_user_quests_updates"];
	/** aggregate var_pop on columns */
["voyce_user_quests_var_pop_fields"]: {
		id?:number,
	quest_id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_quests_var_samp_fields"]: {
		id?:number,
	quest_id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_quests_variance_fields"]: {
		id?:number,
	quest_id?:number,
	user_id?:number
};
	/** table to keep relation between user and leaderboards */
["voyce_user_ranking"]: {
		counter?:number,
	day?:string,
	id:number,
	/** An object relationship */
	series?:ModelTypes["voyce_series"],
	series_id?:number,
	/** An object relationship */
	user?:ModelTypes["voyce_users"],
	user_id?:number,
	xp_count?:number
};
	/** aggregated selection of "voyce.user_ranking" */
["voyce_user_ranking_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_ranking_aggregate_fields"],
	nodes:ModelTypes["voyce_user_ranking"][]
};
	/** aggregate fields of "voyce.user_ranking" */
["voyce_user_ranking_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_ranking_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_ranking_max_fields"],
	min?:ModelTypes["voyce_user_ranking_min_fields"],
	stddev?:ModelTypes["voyce_user_ranking_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_ranking_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_ranking_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_ranking_sum_fields"],
	var_pop?:ModelTypes["voyce_user_ranking_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_ranking_var_samp_fields"],
	variance?:ModelTypes["voyce_user_ranking_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_ranking_avg_fields"]: {
		counter?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	xp_count?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_ranking". All fields are combined with a logical 'AND'. */
["voyce_user_ranking_bool_exp"]: GraphQLTypes["voyce_user_ranking_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_ranking" */
["voyce_user_ranking_constraint"]: GraphQLTypes["voyce_user_ranking_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_ranking" */
["voyce_user_ranking_inc_input"]: GraphQLTypes["voyce_user_ranking_inc_input"];
	/** input type for inserting data into table "voyce.user_ranking" */
["voyce_user_ranking_insert_input"]: GraphQLTypes["voyce_user_ranking_insert_input"];
	/** aggregate max on columns */
["voyce_user_ranking_max_fields"]: {
		counter?:number,
	day?:string,
	id?:number,
	series_id?:number,
	user_id?:number,
	xp_count?:number
};
	/** aggregate min on columns */
["voyce_user_ranking_min_fields"]: {
		counter?:number,
	day?:string,
	id?:number,
	series_id?:number,
	user_id?:number,
	xp_count?:number
};
	/** response of any mutation on the table "voyce.user_ranking" */
["voyce_user_ranking_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_ranking"][]
};
	/** on_conflict condition type for table "voyce.user_ranking" */
["voyce_user_ranking_on_conflict"]: GraphQLTypes["voyce_user_ranking_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_ranking". */
["voyce_user_ranking_order_by"]: GraphQLTypes["voyce_user_ranking_order_by"];
	/** primary key columns input for table: voyce.user_ranking */
["voyce_user_ranking_pk_columns_input"]: GraphQLTypes["voyce_user_ranking_pk_columns_input"];
	/** select columns of table "voyce.user_ranking" */
["voyce_user_ranking_select_column"]: GraphQLTypes["voyce_user_ranking_select_column"];
	/** input type for updating data in table "voyce.user_ranking" */
["voyce_user_ranking_set_input"]: GraphQLTypes["voyce_user_ranking_set_input"];
	/** aggregate stddev on columns */
["voyce_user_ranking_stddev_fields"]: {
		counter?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	xp_count?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_ranking_stddev_pop_fields"]: {
		counter?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	xp_count?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_ranking_stddev_samp_fields"]: {
		counter?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	xp_count?:number
};
	/** Streaming cursor of the table "voyce_user_ranking" */
["voyce_user_ranking_stream_cursor_input"]: GraphQLTypes["voyce_user_ranking_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_ranking_stream_cursor_value_input"]: GraphQLTypes["voyce_user_ranking_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_ranking_sum_fields"]: {
		counter?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	xp_count?:number
};
	/** update columns of table "voyce.user_ranking" */
["voyce_user_ranking_update_column"]: GraphQLTypes["voyce_user_ranking_update_column"];
	["voyce_user_ranking_updates"]: GraphQLTypes["voyce_user_ranking_updates"];
	/** aggregate var_pop on columns */
["voyce_user_ranking_var_pop_fields"]: {
		counter?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	xp_count?:number
};
	/** aggregate var_samp on columns */
["voyce_user_ranking_var_samp_fields"]: {
		counter?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	xp_count?:number
};
	/** aggregate variance on columns */
["voyce_user_ranking_variance_fields"]: {
		counter?:number,
	id?:number,
	series_id?:number,
	user_id?:number,
	xp_count?:number
};
	/** columns and relationships of "voyce.user_reviews" */
["voyce_user_reviews"]: {
		date_create:ModelTypes["timestamp"],
	id:number,
	publish:ModelTypes["smallint"],
	rating:number,
	review?:string,
	reviewer_id:number,
	user_id:number
};
	/** aggregated selection of "voyce.user_reviews" */
["voyce_user_reviews_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_reviews_aggregate_fields"],
	nodes:ModelTypes["voyce_user_reviews"][]
};
	/** aggregate fields of "voyce.user_reviews" */
["voyce_user_reviews_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_reviews_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_reviews_max_fields"],
	min?:ModelTypes["voyce_user_reviews_min_fields"],
	stddev?:ModelTypes["voyce_user_reviews_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_reviews_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_reviews_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_reviews_sum_fields"],
	var_pop?:ModelTypes["voyce_user_reviews_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_reviews_var_samp_fields"],
	variance?:ModelTypes["voyce_user_reviews_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_reviews_avg_fields"]: {
		id?:number,
	publish?:number,
	rating?:number,
	reviewer_id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_reviews". All fields are combined with a logical 'AND'. */
["voyce_user_reviews_bool_exp"]: GraphQLTypes["voyce_user_reviews_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_reviews" */
["voyce_user_reviews_constraint"]: GraphQLTypes["voyce_user_reviews_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_reviews" */
["voyce_user_reviews_inc_input"]: GraphQLTypes["voyce_user_reviews_inc_input"];
	/** input type for inserting data into table "voyce.user_reviews" */
["voyce_user_reviews_insert_input"]: GraphQLTypes["voyce_user_reviews_insert_input"];
	/** aggregate max on columns */
["voyce_user_reviews_max_fields"]: {
		date_create?:ModelTypes["timestamp"],
	id?:number,
	publish?:ModelTypes["smallint"],
	rating?:number,
	review?:string,
	reviewer_id?:number,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_user_reviews_min_fields"]: {
		date_create?:ModelTypes["timestamp"],
	id?:number,
	publish?:ModelTypes["smallint"],
	rating?:number,
	review?:string,
	reviewer_id?:number,
	user_id?:number
};
	/** response of any mutation on the table "voyce.user_reviews" */
["voyce_user_reviews_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_reviews"][]
};
	/** on_conflict condition type for table "voyce.user_reviews" */
["voyce_user_reviews_on_conflict"]: GraphQLTypes["voyce_user_reviews_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_reviews". */
["voyce_user_reviews_order_by"]: GraphQLTypes["voyce_user_reviews_order_by"];
	/** primary key columns input for table: voyce.user_reviews */
["voyce_user_reviews_pk_columns_input"]: GraphQLTypes["voyce_user_reviews_pk_columns_input"];
	/** select columns of table "voyce.user_reviews" */
["voyce_user_reviews_select_column"]: GraphQLTypes["voyce_user_reviews_select_column"];
	/** input type for updating data in table "voyce.user_reviews" */
["voyce_user_reviews_set_input"]: GraphQLTypes["voyce_user_reviews_set_input"];
	/** aggregate stddev on columns */
["voyce_user_reviews_stddev_fields"]: {
		id?:number,
	publish?:number,
	rating?:number,
	reviewer_id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_reviews_stddev_pop_fields"]: {
		id?:number,
	publish?:number,
	rating?:number,
	reviewer_id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_reviews_stddev_samp_fields"]: {
		id?:number,
	publish?:number,
	rating?:number,
	reviewer_id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_reviews" */
["voyce_user_reviews_stream_cursor_input"]: GraphQLTypes["voyce_user_reviews_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_reviews_stream_cursor_value_input"]: GraphQLTypes["voyce_user_reviews_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_reviews_sum_fields"]: {
		id?:number,
	publish?:ModelTypes["smallint"],
	rating?:number,
	reviewer_id?:number,
	user_id?:number
};
	/** update columns of table "voyce.user_reviews" */
["voyce_user_reviews_update_column"]: GraphQLTypes["voyce_user_reviews_update_column"];
	["voyce_user_reviews_updates"]: GraphQLTypes["voyce_user_reviews_updates"];
	/** aggregate var_pop on columns */
["voyce_user_reviews_var_pop_fields"]: {
		id?:number,
	publish?:number,
	rating?:number,
	reviewer_id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_reviews_var_samp_fields"]: {
		id?:number,
	publish?:number,
	rating?:number,
	reviewer_id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_reviews_variance_fields"]: {
		id?:number,
	publish?:number,
	rating?:number,
	reviewer_id?:number,
	user_id?:number
};
	/** table to describe rewards won by users */
["voyce_user_rewards"]: {
		coin_amount:ModelTypes["numeric"],
	coin_type:string,
	created_at:ModelTypes["timestamptz"],
	id:number,
	type:string,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.user_rewards" */
["voyce_user_rewards_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_rewards_aggregate_fields"],
	nodes:ModelTypes["voyce_user_rewards"][]
};
	/** aggregate fields of "voyce.user_rewards" */
["voyce_user_rewards_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_rewards_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_rewards_max_fields"],
	min?:ModelTypes["voyce_user_rewards_min_fields"],
	stddev?:ModelTypes["voyce_user_rewards_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_rewards_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_rewards_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_rewards_sum_fields"],
	var_pop?:ModelTypes["voyce_user_rewards_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_rewards_var_samp_fields"],
	variance?:ModelTypes["voyce_user_rewards_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_rewards_avg_fields"]: {
		coin_amount?:number,
	id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_rewards". All fields are combined with a logical 'AND'. */
["voyce_user_rewards_bool_exp"]: GraphQLTypes["voyce_user_rewards_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_rewards" */
["voyce_user_rewards_constraint"]: GraphQLTypes["voyce_user_rewards_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_rewards" */
["voyce_user_rewards_inc_input"]: GraphQLTypes["voyce_user_rewards_inc_input"];
	/** input type for inserting data into table "voyce.user_rewards" */
["voyce_user_rewards_insert_input"]: GraphQLTypes["voyce_user_rewards_insert_input"];
	/** aggregate max on columns */
["voyce_user_rewards_max_fields"]: {
		coin_amount?:ModelTypes["numeric"],
	coin_type?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	type?:string,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_user_rewards_min_fields"]: {
		coin_amount?:ModelTypes["numeric"],
	coin_type?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	type?:string,
	user_id?:number
};
	/** response of any mutation on the table "voyce.user_rewards" */
["voyce_user_rewards_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_rewards"][]
};
	/** on_conflict condition type for table "voyce.user_rewards" */
["voyce_user_rewards_on_conflict"]: GraphQLTypes["voyce_user_rewards_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_rewards". */
["voyce_user_rewards_order_by"]: GraphQLTypes["voyce_user_rewards_order_by"];
	/** primary key columns input for table: voyce.user_rewards */
["voyce_user_rewards_pk_columns_input"]: GraphQLTypes["voyce_user_rewards_pk_columns_input"];
	/** select columns of table "voyce.user_rewards" */
["voyce_user_rewards_select_column"]: GraphQLTypes["voyce_user_rewards_select_column"];
	/** input type for updating data in table "voyce.user_rewards" */
["voyce_user_rewards_set_input"]: GraphQLTypes["voyce_user_rewards_set_input"];
	/** aggregate stddev on columns */
["voyce_user_rewards_stddev_fields"]: {
		coin_amount?:number,
	id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_rewards_stddev_pop_fields"]: {
		coin_amount?:number,
	id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_rewards_stddev_samp_fields"]: {
		coin_amount?:number,
	id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_rewards" */
["voyce_user_rewards_stream_cursor_input"]: GraphQLTypes["voyce_user_rewards_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_rewards_stream_cursor_value_input"]: GraphQLTypes["voyce_user_rewards_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_rewards_sum_fields"]: {
		coin_amount?:ModelTypes["numeric"],
	id?:number,
	user_id?:number
};
	/** update columns of table "voyce.user_rewards" */
["voyce_user_rewards_update_column"]: GraphQLTypes["voyce_user_rewards_update_column"];
	["voyce_user_rewards_updates"]: GraphQLTypes["voyce_user_rewards_updates"];
	/** aggregate var_pop on columns */
["voyce_user_rewards_var_pop_fields"]: {
		coin_amount?:number,
	id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_rewards_var_samp_fields"]: {
		coin_amount?:number,
	id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_rewards_variance_fields"]: {
		coin_amount?:number,
	id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.user_roles" */
["voyce_user_roles"]: {
		date_create:ModelTypes["timestamp"],
	id:number,
	title:string
};
	/** aggregated selection of "voyce.user_roles" */
["voyce_user_roles_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_roles_aggregate_fields"],
	nodes:ModelTypes["voyce_user_roles"][]
};
	/** aggregate fields of "voyce.user_roles" */
["voyce_user_roles_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_roles_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_roles_max_fields"],
	min?:ModelTypes["voyce_user_roles_min_fields"],
	stddev?:ModelTypes["voyce_user_roles_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_roles_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_roles_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_roles_sum_fields"],
	var_pop?:ModelTypes["voyce_user_roles_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_roles_var_samp_fields"],
	variance?:ModelTypes["voyce_user_roles_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_roles_avg_fields"]: {
		id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_roles". All fields are combined with a logical 'AND'. */
["voyce_user_roles_bool_exp"]: GraphQLTypes["voyce_user_roles_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_roles" */
["voyce_user_roles_constraint"]: GraphQLTypes["voyce_user_roles_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_roles" */
["voyce_user_roles_inc_input"]: GraphQLTypes["voyce_user_roles_inc_input"];
	/** input type for inserting data into table "voyce.user_roles" */
["voyce_user_roles_insert_input"]: GraphQLTypes["voyce_user_roles_insert_input"];
	/** aggregate max on columns */
["voyce_user_roles_max_fields"]: {
		date_create?:ModelTypes["timestamp"],
	id?:number,
	title?:string
};
	/** aggregate min on columns */
["voyce_user_roles_min_fields"]: {
		date_create?:ModelTypes["timestamp"],
	id?:number,
	title?:string
};
	/** response of any mutation on the table "voyce.user_roles" */
["voyce_user_roles_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_roles"][]
};
	/** input type for inserting object relation for remote table "voyce.user_roles" */
["voyce_user_roles_obj_rel_insert_input"]: GraphQLTypes["voyce_user_roles_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.user_roles" */
["voyce_user_roles_on_conflict"]: GraphQLTypes["voyce_user_roles_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_roles". */
["voyce_user_roles_order_by"]: GraphQLTypes["voyce_user_roles_order_by"];
	/** primary key columns input for table: voyce.user_roles */
["voyce_user_roles_pk_columns_input"]: GraphQLTypes["voyce_user_roles_pk_columns_input"];
	/** select columns of table "voyce.user_roles" */
["voyce_user_roles_select_column"]: GraphQLTypes["voyce_user_roles_select_column"];
	/** input type for updating data in table "voyce.user_roles" */
["voyce_user_roles_set_input"]: GraphQLTypes["voyce_user_roles_set_input"];
	/** aggregate stddev on columns */
["voyce_user_roles_stddev_fields"]: {
		id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_roles_stddev_pop_fields"]: {
		id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_roles_stddev_samp_fields"]: {
		id?:number
};
	/** Streaming cursor of the table "voyce_user_roles" */
["voyce_user_roles_stream_cursor_input"]: GraphQLTypes["voyce_user_roles_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_roles_stream_cursor_value_input"]: GraphQLTypes["voyce_user_roles_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_roles_sum_fields"]: {
		id?:number
};
	/** update columns of table "voyce.user_roles" */
["voyce_user_roles_update_column"]: GraphQLTypes["voyce_user_roles_update_column"];
	["voyce_user_roles_updates"]: GraphQLTypes["voyce_user_roles_updates"];
	/** aggregate var_pop on columns */
["voyce_user_roles_var_pop_fields"]: {
		id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_roles_var_samp_fields"]: {
		id?:number
};
	/** aggregate variance on columns */
["voyce_user_roles_variance_fields"]: {
		id?:number
};
	/** columns and relationships of "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count"]: {
		count?:ModelTypes["bigint"],
	user_id?:number
};
	/** aggregated selection of "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_series_subscription_count_aggregate_fields"],
	nodes:ModelTypes["voyce_user_series_subscription_count"][]
};
	/** aggregate fields of "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_series_subscription_count_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_series_subscription_count_max_fields"],
	min?:ModelTypes["voyce_user_series_subscription_count_min_fields"],
	stddev?:ModelTypes["voyce_user_series_subscription_count_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_series_subscription_count_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_series_subscription_count_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_series_subscription_count_sum_fields"],
	var_pop?:ModelTypes["voyce_user_series_subscription_count_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_series_subscription_count_var_samp_fields"],
	variance?:ModelTypes["voyce_user_series_subscription_count_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_series_subscription_count_avg_fields"]: {
		count?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_series_subscription_count". All fields are combined with a logical 'AND'. */
["voyce_user_series_subscription_count_bool_exp"]: GraphQLTypes["voyce_user_series_subscription_count_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_constraint"]: GraphQLTypes["voyce_user_series_subscription_count_constraint"];
	/** input type for inserting data into table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_insert_input"]: GraphQLTypes["voyce_user_series_subscription_count_insert_input"];
	/** aggregate max on columns */
["voyce_user_series_subscription_count_max_fields"]: {
		count?:ModelTypes["bigint"],
	user_id?:number
};
	/** aggregate min on columns */
["voyce_user_series_subscription_count_min_fields"]: {
		count?:ModelTypes["bigint"],
	user_id?:number
};
	/** input type for inserting object relation for remote table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_obj_rel_insert_input"]: GraphQLTypes["voyce_user_series_subscription_count_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_on_conflict"]: GraphQLTypes["voyce_user_series_subscription_count_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_series_subscription_count". */
["voyce_user_series_subscription_count_order_by"]: GraphQLTypes["voyce_user_series_subscription_count_order_by"];
	/** select columns of table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_select_column"]: GraphQLTypes["voyce_user_series_subscription_count_select_column"];
	/** aggregate stddev on columns */
["voyce_user_series_subscription_count_stddev_fields"]: {
		count?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_series_subscription_count_stddev_pop_fields"]: {
		count?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_series_subscription_count_stddev_samp_fields"]: {
		count?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_series_subscription_count" */
["voyce_user_series_subscription_count_stream_cursor_input"]: GraphQLTypes["voyce_user_series_subscription_count_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_series_subscription_count_stream_cursor_value_input"]: GraphQLTypes["voyce_user_series_subscription_count_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_series_subscription_count_sum_fields"]: {
		count?:ModelTypes["bigint"],
	user_id?:number
};
	/** update columns of table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_update_column"]: GraphQLTypes["voyce_user_series_subscription_count_update_column"];
	/** aggregate var_pop on columns */
["voyce_user_series_subscription_count_var_pop_fields"]: {
		count?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_series_subscription_count_var_samp_fields"]: {
		count?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_series_subscription_count_variance_fields"]: {
		count?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.user_settings" */
["voyce_user_settings"]: {
		content_mature:ModelTypes["smallint"],
	content_spoilers:ModelTypes["smallint"],
	id:number,
	page_animation:ModelTypes["smallint"],
	reading_comic:string,
	reading_manga:string,
	reading_novel:string,
	theme:string,
	updated_at:ModelTypes["timestamptz"],
	user_id:number
};
	/** aggregated selection of "voyce.user_settings" */
["voyce_user_settings_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_settings_aggregate_fields"],
	nodes:ModelTypes["voyce_user_settings"][]
};
	/** aggregate fields of "voyce.user_settings" */
["voyce_user_settings_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_settings_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_settings_max_fields"],
	min?:ModelTypes["voyce_user_settings_min_fields"],
	stddev?:ModelTypes["voyce_user_settings_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_settings_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_settings_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_settings_sum_fields"],
	var_pop?:ModelTypes["voyce_user_settings_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_settings_var_samp_fields"],
	variance?:ModelTypes["voyce_user_settings_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_settings_avg_fields"]: {
		content_mature?:number,
	content_spoilers?:number,
	id?:number,
	page_animation?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_settings". All fields are combined with a logical 'AND'. */
["voyce_user_settings_bool_exp"]: GraphQLTypes["voyce_user_settings_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_settings" */
["voyce_user_settings_constraint"]: GraphQLTypes["voyce_user_settings_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_settings" */
["voyce_user_settings_inc_input"]: GraphQLTypes["voyce_user_settings_inc_input"];
	/** input type for inserting data into table "voyce.user_settings" */
["voyce_user_settings_insert_input"]: GraphQLTypes["voyce_user_settings_insert_input"];
	/** aggregate max on columns */
["voyce_user_settings_max_fields"]: {
		content_mature?:ModelTypes["smallint"],
	content_spoilers?:ModelTypes["smallint"],
	id?:number,
	page_animation?:ModelTypes["smallint"],
	reading_comic?:string,
	reading_manga?:string,
	reading_novel?:string,
	theme?:string,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number
};
	/** aggregate min on columns */
["voyce_user_settings_min_fields"]: {
		content_mature?:ModelTypes["smallint"],
	content_spoilers?:ModelTypes["smallint"],
	id?:number,
	page_animation?:ModelTypes["smallint"],
	reading_comic?:string,
	reading_manga?:string,
	reading_novel?:string,
	theme?:string,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number
};
	/** response of any mutation on the table "voyce.user_settings" */
["voyce_user_settings_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_settings"][]
};
	/** on_conflict condition type for table "voyce.user_settings" */
["voyce_user_settings_on_conflict"]: GraphQLTypes["voyce_user_settings_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_settings". */
["voyce_user_settings_order_by"]: GraphQLTypes["voyce_user_settings_order_by"];
	/** primary key columns input for table: voyce.user_settings */
["voyce_user_settings_pk_columns_input"]: GraphQLTypes["voyce_user_settings_pk_columns_input"];
	/** select columns of table "voyce.user_settings" */
["voyce_user_settings_select_column"]: GraphQLTypes["voyce_user_settings_select_column"];
	/** input type for updating data in table "voyce.user_settings" */
["voyce_user_settings_set_input"]: GraphQLTypes["voyce_user_settings_set_input"];
	/** aggregate stddev on columns */
["voyce_user_settings_stddev_fields"]: {
		content_mature?:number,
	content_spoilers?:number,
	id?:number,
	page_animation?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_settings_stddev_pop_fields"]: {
		content_mature?:number,
	content_spoilers?:number,
	id?:number,
	page_animation?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_settings_stddev_samp_fields"]: {
		content_mature?:number,
	content_spoilers?:number,
	id?:number,
	page_animation?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_settings" */
["voyce_user_settings_stream_cursor_input"]: GraphQLTypes["voyce_user_settings_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_settings_stream_cursor_value_input"]: GraphQLTypes["voyce_user_settings_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_settings_sum_fields"]: {
		content_mature?:ModelTypes["smallint"],
	content_spoilers?:ModelTypes["smallint"],
	id?:number,
	page_animation?:ModelTypes["smallint"],
	user_id?:number
};
	/** update columns of table "voyce.user_settings" */
["voyce_user_settings_update_column"]: GraphQLTypes["voyce_user_settings_update_column"];
	["voyce_user_settings_updates"]: GraphQLTypes["voyce_user_settings_updates"];
	/** aggregate var_pop on columns */
["voyce_user_settings_var_pop_fields"]: {
		content_mature?:number,
	content_spoilers?:number,
	id?:number,
	page_animation?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_settings_var_samp_fields"]: {
		content_mature?:number,
	content_spoilers?:number,
	id?:number,
	page_animation?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_settings_variance_fields"]: {
		content_mature?:number,
	content_spoilers?:number,
	id?:number,
	page_animation?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.user_skills" */
["voyce_user_skills"]: {
		date_create:ModelTypes["timestamp"],
	id:number,
	skill:string,
	user_id:number
};
	/** aggregated selection of "voyce.user_skills" */
["voyce_user_skills_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_skills_aggregate_fields"],
	nodes:ModelTypes["voyce_user_skills"][]
};
	/** aggregate fields of "voyce.user_skills" */
["voyce_user_skills_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_skills_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_skills_max_fields"],
	min?:ModelTypes["voyce_user_skills_min_fields"],
	stddev?:ModelTypes["voyce_user_skills_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_skills_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_skills_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_skills_sum_fields"],
	var_pop?:ModelTypes["voyce_user_skills_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_skills_var_samp_fields"],
	variance?:ModelTypes["voyce_user_skills_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_skills_avg_fields"]: {
		id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_skills". All fields are combined with a logical 'AND'. */
["voyce_user_skills_bool_exp"]: GraphQLTypes["voyce_user_skills_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_skills" */
["voyce_user_skills_constraint"]: GraphQLTypes["voyce_user_skills_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_skills" */
["voyce_user_skills_inc_input"]: GraphQLTypes["voyce_user_skills_inc_input"];
	/** input type for inserting data into table "voyce.user_skills" */
["voyce_user_skills_insert_input"]: GraphQLTypes["voyce_user_skills_insert_input"];
	/** aggregate max on columns */
["voyce_user_skills_max_fields"]: {
		date_create?:ModelTypes["timestamp"],
	id?:number,
	skill?:string,
	user_id?:number
};
	/** aggregate min on columns */
["voyce_user_skills_min_fields"]: {
		date_create?:ModelTypes["timestamp"],
	id?:number,
	skill?:string,
	user_id?:number
};
	/** response of any mutation on the table "voyce.user_skills" */
["voyce_user_skills_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_skills"][]
};
	/** on_conflict condition type for table "voyce.user_skills" */
["voyce_user_skills_on_conflict"]: GraphQLTypes["voyce_user_skills_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_skills". */
["voyce_user_skills_order_by"]: GraphQLTypes["voyce_user_skills_order_by"];
	/** primary key columns input for table: voyce.user_skills */
["voyce_user_skills_pk_columns_input"]: GraphQLTypes["voyce_user_skills_pk_columns_input"];
	/** select columns of table "voyce.user_skills" */
["voyce_user_skills_select_column"]: GraphQLTypes["voyce_user_skills_select_column"];
	/** input type for updating data in table "voyce.user_skills" */
["voyce_user_skills_set_input"]: GraphQLTypes["voyce_user_skills_set_input"];
	/** aggregate stddev on columns */
["voyce_user_skills_stddev_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_skills_stddev_pop_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_skills_stddev_samp_fields"]: {
		id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_skills" */
["voyce_user_skills_stream_cursor_input"]: GraphQLTypes["voyce_user_skills_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_skills_stream_cursor_value_input"]: GraphQLTypes["voyce_user_skills_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_skills_sum_fields"]: {
		id?:number,
	user_id?:number
};
	/** update columns of table "voyce.user_skills" */
["voyce_user_skills_update_column"]: GraphQLTypes["voyce_user_skills_update_column"];
	["voyce_user_skills_updates"]: GraphQLTypes["voyce_user_skills_updates"];
	/** aggregate var_pop on columns */
["voyce_user_skills_var_pop_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_skills_var_samp_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_skills_variance_fields"]: {
		id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.user_socials" */
["voyce_user_socials"]: {
		access_token:string,
	created_at:ModelTypes["timestamptz"],
	expired_at:ModelTypes["timestamptz"],
	expires_in?:ModelTypes["numeric"],
	id:number,
	provider:string,
	refresh_token:string,
	scope?:string,
	token_type?:string,
	updated_at:ModelTypes["timestamptz"],
	user_id:number
};
	/** aggregated selection of "voyce.user_socials" */
["voyce_user_socials_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_socials_aggregate_fields"],
	nodes:ModelTypes["voyce_user_socials"][]
};
	/** aggregate fields of "voyce.user_socials" */
["voyce_user_socials_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_socials_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_socials_max_fields"],
	min?:ModelTypes["voyce_user_socials_min_fields"],
	stddev?:ModelTypes["voyce_user_socials_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_socials_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_socials_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_socials_sum_fields"],
	var_pop?:ModelTypes["voyce_user_socials_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_socials_var_samp_fields"],
	variance?:ModelTypes["voyce_user_socials_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_socials_avg_fields"]: {
		expires_in?:number,
	id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_socials". All fields are combined with a logical 'AND'. */
["voyce_user_socials_bool_exp"]: GraphQLTypes["voyce_user_socials_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_socials" */
["voyce_user_socials_constraint"]: GraphQLTypes["voyce_user_socials_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_socials" */
["voyce_user_socials_inc_input"]: GraphQLTypes["voyce_user_socials_inc_input"];
	/** input type for inserting data into table "voyce.user_socials" */
["voyce_user_socials_insert_input"]: GraphQLTypes["voyce_user_socials_insert_input"];
	/** aggregate max on columns */
["voyce_user_socials_max_fields"]: {
		access_token?:string,
	created_at?:ModelTypes["timestamptz"],
	expired_at?:ModelTypes["timestamptz"],
	expires_in?:ModelTypes["numeric"],
	id?:number,
	provider?:string,
	refresh_token?:string,
	scope?:string,
	token_type?:string,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number
};
	/** aggregate min on columns */
["voyce_user_socials_min_fields"]: {
		access_token?:string,
	created_at?:ModelTypes["timestamptz"],
	expired_at?:ModelTypes["timestamptz"],
	expires_in?:ModelTypes["numeric"],
	id?:number,
	provider?:string,
	refresh_token?:string,
	scope?:string,
	token_type?:string,
	updated_at?:ModelTypes["timestamptz"],
	user_id?:number
};
	/** response of any mutation on the table "voyce.user_socials" */
["voyce_user_socials_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_socials"][]
};
	/** on_conflict condition type for table "voyce.user_socials" */
["voyce_user_socials_on_conflict"]: GraphQLTypes["voyce_user_socials_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_socials". */
["voyce_user_socials_order_by"]: GraphQLTypes["voyce_user_socials_order_by"];
	/** primary key columns input for table: voyce.user_socials */
["voyce_user_socials_pk_columns_input"]: GraphQLTypes["voyce_user_socials_pk_columns_input"];
	/** select columns of table "voyce.user_socials" */
["voyce_user_socials_select_column"]: GraphQLTypes["voyce_user_socials_select_column"];
	/** input type for updating data in table "voyce.user_socials" */
["voyce_user_socials_set_input"]: GraphQLTypes["voyce_user_socials_set_input"];
	/** aggregate stddev on columns */
["voyce_user_socials_stddev_fields"]: {
		expires_in?:number,
	id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_socials_stddev_pop_fields"]: {
		expires_in?:number,
	id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_socials_stddev_samp_fields"]: {
		expires_in?:number,
	id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_socials" */
["voyce_user_socials_stream_cursor_input"]: GraphQLTypes["voyce_user_socials_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_socials_stream_cursor_value_input"]: GraphQLTypes["voyce_user_socials_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_socials_sum_fields"]: {
		expires_in?:ModelTypes["numeric"],
	id?:number,
	user_id?:number
};
	/** update columns of table "voyce.user_socials" */
["voyce_user_socials_update_column"]: GraphQLTypes["voyce_user_socials_update_column"];
	["voyce_user_socials_updates"]: GraphQLTypes["voyce_user_socials_updates"];
	/** aggregate var_pop on columns */
["voyce_user_socials_var_pop_fields"]: {
		expires_in?:number,
	id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_socials_var_samp_fields"]: {
		expires_in?:number,
	id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_socials_variance_fields"]: {
		expires_in?:number,
	id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.user_soft_deleted" */
["voyce_user_soft_deleted"]: {
		about?:string,
	avatar?:string,
	banner?:string,
	created_at?:ModelTypes["timestamptz"],
	deleted_at?:ModelTypes["timestamptz"],
	email?:string,
	first_name?:string,
	id:number,
	is_deleted?:boolean,
	last_name?:string,
	nickname?:string,
	social_discord?:string,
	social_fb?:string,
	social_instagram?:string,
	social_tiktok?:string,
	social_twitter?:string,
	/** An object relationship */
	user?:ModelTypes["voyce_users"],
	user_id:number,
	username?:string
};
	/** aggregated selection of "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_soft_deleted_aggregate_fields"],
	nodes:ModelTypes["voyce_user_soft_deleted"][]
};
	/** aggregate fields of "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_soft_deleted_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_soft_deleted_max_fields"],
	min?:ModelTypes["voyce_user_soft_deleted_min_fields"],
	stddev?:ModelTypes["voyce_user_soft_deleted_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_soft_deleted_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_soft_deleted_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_soft_deleted_sum_fields"],
	var_pop?:ModelTypes["voyce_user_soft_deleted_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_soft_deleted_var_samp_fields"],
	variance?:ModelTypes["voyce_user_soft_deleted_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_soft_deleted_avg_fields"]: {
		id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_soft_deleted". All fields are combined with a logical 'AND'. */
["voyce_user_soft_deleted_bool_exp"]: GraphQLTypes["voyce_user_soft_deleted_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_constraint"]: GraphQLTypes["voyce_user_soft_deleted_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_inc_input"]: GraphQLTypes["voyce_user_soft_deleted_inc_input"];
	/** input type for inserting data into table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_insert_input"]: GraphQLTypes["voyce_user_soft_deleted_insert_input"];
	/** aggregate max on columns */
["voyce_user_soft_deleted_max_fields"]: {
		about?:string,
	avatar?:string,
	banner?:string,
	created_at?:ModelTypes["timestamptz"],
	deleted_at?:ModelTypes["timestamptz"],
	email?:string,
	first_name?:string,
	id?:number,
	last_name?:string,
	nickname?:string,
	social_discord?:string,
	social_fb?:string,
	social_instagram?:string,
	social_tiktok?:string,
	social_twitter?:string,
	user_id?:number,
	username?:string
};
	/** aggregate min on columns */
["voyce_user_soft_deleted_min_fields"]: {
		about?:string,
	avatar?:string,
	banner?:string,
	created_at?:ModelTypes["timestamptz"],
	deleted_at?:ModelTypes["timestamptz"],
	email?:string,
	first_name?:string,
	id?:number,
	last_name?:string,
	nickname?:string,
	social_discord?:string,
	social_fb?:string,
	social_instagram?:string,
	social_tiktok?:string,
	social_twitter?:string,
	user_id?:number,
	username?:string
};
	/** response of any mutation on the table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_soft_deleted"][]
};
	/** on_conflict condition type for table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_on_conflict"]: GraphQLTypes["voyce_user_soft_deleted_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_soft_deleted". */
["voyce_user_soft_deleted_order_by"]: GraphQLTypes["voyce_user_soft_deleted_order_by"];
	/** primary key columns input for table: voyce.user_soft_deleted */
["voyce_user_soft_deleted_pk_columns_input"]: GraphQLTypes["voyce_user_soft_deleted_pk_columns_input"];
	/** select columns of table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_select_column"]: GraphQLTypes["voyce_user_soft_deleted_select_column"];
	/** input type for updating data in table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_set_input"]: GraphQLTypes["voyce_user_soft_deleted_set_input"];
	/** aggregate stddev on columns */
["voyce_user_soft_deleted_stddev_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_soft_deleted_stddev_pop_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_soft_deleted_stddev_samp_fields"]: {
		id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_soft_deleted" */
["voyce_user_soft_deleted_stream_cursor_input"]: GraphQLTypes["voyce_user_soft_deleted_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_soft_deleted_stream_cursor_value_input"]: GraphQLTypes["voyce_user_soft_deleted_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_soft_deleted_sum_fields"]: {
		id?:number,
	user_id?:number
};
	/** update columns of table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_update_column"]: GraphQLTypes["voyce_user_soft_deleted_update_column"];
	["voyce_user_soft_deleted_updates"]: GraphQLTypes["voyce_user_soft_deleted_updates"];
	/** aggregate var_pop on columns */
["voyce_user_soft_deleted_var_pop_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_soft_deleted_var_samp_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_soft_deleted_variance_fields"]: {
		id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.user_vtags" */
["voyce_user_vtags"]: {
		id:number,
	user_id:number,
	vtag:string,
	vtag_v2?:string
};
	/** aggregated selection of "voyce.user_vtags" */
["voyce_user_vtags_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_vtags_aggregate_fields"],
	nodes:ModelTypes["voyce_user_vtags"][]
};
	/** aggregate fields of "voyce.user_vtags" */
["voyce_user_vtags_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_vtags_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_vtags_max_fields"],
	min?:ModelTypes["voyce_user_vtags_min_fields"],
	stddev?:ModelTypes["voyce_user_vtags_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_vtags_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_vtags_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_vtags_sum_fields"],
	var_pop?:ModelTypes["voyce_user_vtags_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_vtags_var_samp_fields"],
	variance?:ModelTypes["voyce_user_vtags_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_vtags_avg_fields"]: {
		id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_vtags". All fields are combined with a logical 'AND'. */
["voyce_user_vtags_bool_exp"]: GraphQLTypes["voyce_user_vtags_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_vtags" */
["voyce_user_vtags_constraint"]: GraphQLTypes["voyce_user_vtags_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_vtags" */
["voyce_user_vtags_inc_input"]: GraphQLTypes["voyce_user_vtags_inc_input"];
	/** input type for inserting data into table "voyce.user_vtags" */
["voyce_user_vtags_insert_input"]: GraphQLTypes["voyce_user_vtags_insert_input"];
	/** aggregate max on columns */
["voyce_user_vtags_max_fields"]: {
		id?:number,
	user_id?:number,
	vtag?:string,
	vtag_v2?:string
};
	/** aggregate min on columns */
["voyce_user_vtags_min_fields"]: {
		id?:number,
	user_id?:number,
	vtag?:string,
	vtag_v2?:string
};
	/** response of any mutation on the table "voyce.user_vtags" */
["voyce_user_vtags_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_vtags"][]
};
	/** input type for inserting object relation for remote table "voyce.user_vtags" */
["voyce_user_vtags_obj_rel_insert_input"]: GraphQLTypes["voyce_user_vtags_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.user_vtags" */
["voyce_user_vtags_on_conflict"]: GraphQLTypes["voyce_user_vtags_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_vtags". */
["voyce_user_vtags_order_by"]: GraphQLTypes["voyce_user_vtags_order_by"];
	/** primary key columns input for table: voyce.user_vtags */
["voyce_user_vtags_pk_columns_input"]: GraphQLTypes["voyce_user_vtags_pk_columns_input"];
	/** select columns of table "voyce.user_vtags" */
["voyce_user_vtags_select_column"]: GraphQLTypes["voyce_user_vtags_select_column"];
	/** input type for updating data in table "voyce.user_vtags" */
["voyce_user_vtags_set_input"]: GraphQLTypes["voyce_user_vtags_set_input"];
	/** aggregate stddev on columns */
["voyce_user_vtags_stddev_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_vtags_stddev_pop_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_vtags_stddev_samp_fields"]: {
		id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_vtags" */
["voyce_user_vtags_stream_cursor_input"]: GraphQLTypes["voyce_user_vtags_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_vtags_stream_cursor_value_input"]: GraphQLTypes["voyce_user_vtags_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_vtags_sum_fields"]: {
		id?:number,
	user_id?:number
};
	/** update columns of table "voyce.user_vtags" */
["voyce_user_vtags_update_column"]: GraphQLTypes["voyce_user_vtags_update_column"];
	["voyce_user_vtags_updates"]: GraphQLTypes["voyce_user_vtags_updates"];
	/** aggregate var_pop on columns */
["voyce_user_vtags_var_pop_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_vtags_var_samp_fields"]: {
		id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_vtags_variance_fields"]: {
		id?:number,
	user_id?:number
};
	/** table to describe quests user is taking part in */
["voyce_user_wins"]: {
		claimed?:boolean,
	created_at:ModelTypes["timestamp"],
	id:number,
	linked_id?:number,
	source?:string,
	title?:string,
	updated_at?:ModelTypes["timestamp"],
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.user_wins" */
["voyce_user_wins_aggregate"]: {
		aggregate?:ModelTypes["voyce_user_wins_aggregate_fields"],
	nodes:ModelTypes["voyce_user_wins"][]
};
	/** aggregate fields of "voyce.user_wins" */
["voyce_user_wins_aggregate_fields"]: {
		avg?:ModelTypes["voyce_user_wins_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_user_wins_max_fields"],
	min?:ModelTypes["voyce_user_wins_min_fields"],
	stddev?:ModelTypes["voyce_user_wins_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_user_wins_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_user_wins_stddev_samp_fields"],
	sum?:ModelTypes["voyce_user_wins_sum_fields"],
	var_pop?:ModelTypes["voyce_user_wins_var_pop_fields"],
	var_samp?:ModelTypes["voyce_user_wins_var_samp_fields"],
	variance?:ModelTypes["voyce_user_wins_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_wins_avg_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.user_wins". All fields are combined with a logical 'AND'. */
["voyce_user_wins_bool_exp"]: GraphQLTypes["voyce_user_wins_bool_exp"];
	/** unique or primary key constraints on table "voyce.user_wins" */
["voyce_user_wins_constraint"]: GraphQLTypes["voyce_user_wins_constraint"];
	/** input type for incrementing numeric columns in table "voyce.user_wins" */
["voyce_user_wins_inc_input"]: GraphQLTypes["voyce_user_wins_inc_input"];
	/** input type for inserting data into table "voyce.user_wins" */
["voyce_user_wins_insert_input"]: GraphQLTypes["voyce_user_wins_insert_input"];
	/** aggregate max on columns */
["voyce_user_wins_max_fields"]: {
		created_at?:ModelTypes["timestamp"],
	id?:number,
	linked_id?:number,
	source?:string,
	title?:string,
	updated_at?:ModelTypes["timestamp"],
	user_id?:number
};
	/** aggregate min on columns */
["voyce_user_wins_min_fields"]: {
		created_at?:ModelTypes["timestamp"],
	id?:number,
	linked_id?:number,
	source?:string,
	title?:string,
	updated_at?:ModelTypes["timestamp"],
	user_id?:number
};
	/** response of any mutation on the table "voyce.user_wins" */
["voyce_user_wins_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_user_wins"][]
};
	/** on_conflict condition type for table "voyce.user_wins" */
["voyce_user_wins_on_conflict"]: GraphQLTypes["voyce_user_wins_on_conflict"];
	/** Ordering options when selecting data from "voyce.user_wins". */
["voyce_user_wins_order_by"]: GraphQLTypes["voyce_user_wins_order_by"];
	/** primary key columns input for table: voyce.user_wins */
["voyce_user_wins_pk_columns_input"]: GraphQLTypes["voyce_user_wins_pk_columns_input"];
	/** select columns of table "voyce.user_wins" */
["voyce_user_wins_select_column"]: GraphQLTypes["voyce_user_wins_select_column"];
	/** input type for updating data in table "voyce.user_wins" */
["voyce_user_wins_set_input"]: GraphQLTypes["voyce_user_wins_set_input"];
	/** aggregate stddev on columns */
["voyce_user_wins_stddev_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_user_wins_stddev_pop_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_user_wins_stddev_samp_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** Streaming cursor of the table "voyce_user_wins" */
["voyce_user_wins_stream_cursor_input"]: GraphQLTypes["voyce_user_wins_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_user_wins_stream_cursor_value_input"]: GraphQLTypes["voyce_user_wins_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_user_wins_sum_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** update columns of table "voyce.user_wins" */
["voyce_user_wins_update_column"]: GraphQLTypes["voyce_user_wins_update_column"];
	["voyce_user_wins_updates"]: GraphQLTypes["voyce_user_wins_updates"];
	/** aggregate var_pop on columns */
["voyce_user_wins_var_pop_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** aggregate var_samp on columns */
["voyce_user_wins_var_samp_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** aggregate variance on columns */
["voyce_user_wins_variance_fields"]: {
		id?:number,
	linked_id?:number,
	user_id?:number
};
	/** columns and relationships of "voyce.users" */
["voyce_users"]: {
		about?:string,
	account_disabled:boolean,
	ad_share?:number,
	/** An object relationship */
	admin_role?:ModelTypes["voyce_admin_roles"],
	admin_role_id?:number,
	/** An object relationship */
	author_profile?:ModelTypes["voyce_author_profiles"],
	/** An array relationship */
	author_profiles:ModelTypes["voyce_author_profiles"][],
	/** An aggregate relationship */
	author_profiles_aggregate:ModelTypes["voyce_author_profiles_aggregate"],
	avatar?:string,
	avatar_bgcolor?:string,
	banner?:string,
	birth_date?:ModelTypes["date"],
	/** An array relationship */
	chapter_comments:ModelTypes["voyce_chapter_comments"][],
	/** An aggregate relationship */
	chapter_comments_aggregate:ModelTypes["voyce_chapter_comments_aggregate"],
	/** An array relationship */
	chapter_comments_reactions:ModelTypes["voyce_chapter_comments_reaction"][],
	/** An aggregate relationship */
	chapter_comments_reactions_aggregate:ModelTypes["voyce_chapter_comments_reaction_aggregate"],
	cognito_id?:string,
	cognito_provider?:string,
	cognito_username?:string,
	country_id?:number,
	creator_type_preferences?:ModelTypes["jsonb"],
	dark_mode?:ModelTypes["smallint"],
	date_create:ModelTypes["timestamp"],
	delete_code?:string,
	email:string,
	email_verified:boolean,
	/** mobile or web or null */
	email_verified_source?:string,
	fcm_token?:string,
	fcm_tokens?:ModelTypes["jsonb"],
	first_name?:string,
	/** An array relationship */
	following:ModelTypes["voyce_series_subscription"][],
	/** An aggregate relationship */
	following_aggregate:ModelTypes["voyce_series_subscription_aggregate"],
	frame?:string,
	gender?:string,
	guest_gtag?:string,
	/** An array relationship */
	hot_panels:ModelTypes["voyce_series_hot_panels"][],
	/** An aggregate relationship */
	hot_panels_aggregate:ModelTypes["voyce_series_hot_panels_aggregate"],
	id:number,
	is_account_active?:ModelTypes["smallint"],
	is_approved:boolean,
	is_onboarded?:number,
	is_publisher_verified:boolean,
	klaviyo_id?:string,
	last_login?:ModelTypes["timestamp"],
	last_name?:string,
	linked_account_status?:string,
	new_email_code?:string,
	newsletter_subscribe:boolean,
	nickname?:string,
	/** An array relationship */
	notification_series_settings:ModelTypes["voyce_notification_series_settings"][],
	/** An aggregate relationship */
	notification_series_settings_aggregate:ModelTypes["voyce_notification_series_settings_aggregate"],
	/** An object relationship */
	notification_setting?:ModelTypes["voyce_notification_settings"],
	/** An array relationship */
	notifications:ModelTypes["voyce_notifications"][],
	/** An aggregate relationship */
	notifications_aggregate:ModelTypes["voyce_notifications_aggregate"],
	/** An array relationship */
	panels_comments:ModelTypes["voyce_panels_comments"][],
	/** An aggregate relationship */
	panels_comments_aggregate:ModelTypes["voyce_panels_comments_aggregate"],
	/** An array relationship */
	panels_reactions:ModelTypes["voyce_panels_reactions"][],
	/** An aggregate relationship */
	panels_reactions_aggregate:ModelTypes["voyce_panels_reactions_aggregate"],
	password:string,
	password_secure?:string,
	/** An array relationship */
	payments_to:ModelTypes["voyce_payments_to_creators"][],
	/** An aggregate relationship */
	payments_to_aggregate:ModelTypes["voyce_payments_to_creators_aggregate"],
	/** An array relationship */
	polls:ModelTypes["voyce_polls"][],
	/** An aggregate relationship */
	polls_aggregate:ModelTypes["voyce_polls_aggregate"],
	/** An array relationship */
	polls_comments:ModelTypes["voyce_polls_comments"][],
	/** An aggregate relationship */
	polls_comments_aggregate:ModelTypes["voyce_polls_comments_aggregate"],
	/** An array relationship */
	polls_reactions:ModelTypes["voyce_polls_reactions"][],
	/** An aggregate relationship */
	polls_reactions_aggregate:ModelTypes["voyce_polls_reactions_aggregate"],
	/** An array relationship */
	polls_votes:ModelTypes["voyce_polls_votes"][],
	/** An aggregate relationship */
	polls_votes_aggregate:ModelTypes["voyce_polls_votes_aggregate"],
	/** An array relationship */
	posts:ModelTypes["voyce_posts"][],
	/** An aggregate relationship */
	posts_aggregate:ModelTypes["voyce_posts_aggregate"],
	/** An array relationship */
	posts_comments:ModelTypes["voyce_posts_comments"][],
	/** An aggregate relationship */
	posts_comments_aggregate:ModelTypes["voyce_posts_comments_aggregate"],
	/** An array relationship */
	posts_reactions:ModelTypes["voyce_posts_reactions"][],
	/** An aggregate relationship */
	posts_reactions_aggregate:ModelTypes["voyce_posts_reactions_aggregate"],
	/** An object relationship */
	private_fields?:ModelTypes["voyce_users_private"],
	publisher_verification_code?:string,
	quest_onboarded:boolean,
	remember_code?:string,
	reset_code?:string,
	reset_code_created_at?:ModelTypes["timestamptz"],
	reset_otp?:string,
	/** An object relationship */
	role?:ModelTypes["voyce_user_roles"],
	role_id:number,
	/** An object relationship */
	series_subscription_count?:ModelTypes["voyce_user_series_subscription_count"],
	/** mobile or web or null */
	signup_source?:string,
	social_discord?:string,
	social_fb?:string,
	social_instagram?:string,
	social_tiktok?:string,
	social_twitter?:string,
	/** An array relationship */
	sponsorships:ModelTypes["voyce_sponsorships"][],
	/** An array relationship */
	sponsorshipsBySponsoredUserId:ModelTypes["voyce_sponsorships"][],
	/** An aggregate relationship */
	sponsorshipsBySponsoredUserId_aggregate:ModelTypes["voyce_sponsorships_aggregate"],
	/** An aggregate relationship */
	sponsorships_aggregate:ModelTypes["voyce_sponsorships_aggregate"],
	/** An object relationship */
	stripe_setup?:ModelTypes["voyce_stripe_payouts_setup"],
	unsubscribe:boolean,
	updated_at?:ModelTypes["timestamptz"],
	user_type_preference?:string,
	username:string,
	/** An array relationship */
	usersFollowersByFollowingUid:ModelTypes["voyce_users_followers"][],
	/** An aggregate relationship */
	usersFollowersByFollowingUid_aggregate:ModelTypes["voyce_users_followers_aggregate"],
	/** An array relationship */
	users_followers:ModelTypes["voyce_users_followers"][],
	/** An aggregate relationship */
	users_followers_aggregate:ModelTypes["voyce_users_followers_aggregate"],
	/** An array relationship */
	users_series_junctions:ModelTypes["voyce_users_series_junction"][],
	/** An aggregate relationship */
	users_series_junctions_aggregate:ModelTypes["voyce_users_series_junction_aggregate"],
	vmuser:boolean,
	/** An object relationship */
	vtag?:ModelTypes["voyce_user_vtags"]
};
	/** aggregated selection of "voyce.users" */
["voyce_users_aggregate"]: {
		aggregate?:ModelTypes["voyce_users_aggregate_fields"],
	nodes:ModelTypes["voyce_users"][]
};
	/** aggregate fields of "voyce.users" */
["voyce_users_aggregate_fields"]: {
		avg?:ModelTypes["voyce_users_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_users_max_fields"],
	min?:ModelTypes["voyce_users_min_fields"],
	stddev?:ModelTypes["voyce_users_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_users_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_users_stddev_samp_fields"],
	sum?:ModelTypes["voyce_users_sum_fields"],
	var_pop?:ModelTypes["voyce_users_var_pop_fields"],
	var_samp?:ModelTypes["voyce_users_var_samp_fields"],
	variance?:ModelTypes["voyce_users_variance_fields"]
};
	/** append existing jsonb value of filtered columns with new jsonb value */
["voyce_users_append_input"]: GraphQLTypes["voyce_users_append_input"];
	/** aggregate avg on columns */
["voyce_users_avg_fields"]: {
		ad_share?:number,
	admin_role_id?:number,
	country_id?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	is_onboarded?:number,
	role_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.users". All fields are combined with a logical 'AND'. */
["voyce_users_bool_exp"]: GraphQLTypes["voyce_users_bool_exp"];
	/** unique or primary key constraints on table "voyce.users" */
["voyce_users_constraint"]: GraphQLTypes["voyce_users_constraint"];
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["voyce_users_delete_at_path_input"]: GraphQLTypes["voyce_users_delete_at_path_input"];
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["voyce_users_delete_elem_input"]: GraphQLTypes["voyce_users_delete_elem_input"];
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["voyce_users_delete_key_input"]: GraphQLTypes["voyce_users_delete_key_input"];
	/** columns and relationships of "voyce.users_followers" */
["voyce_users_followers"]: {
		created_at:ModelTypes["timestamptz"],
	follower_uid:number,
	following_uid:number,
	id:number,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	/** An object relationship */
	userByFollowingUid:ModelTypes["voyce_users"]
};
	/** aggregated selection of "voyce.users_followers" */
["voyce_users_followers_aggregate"]: {
		aggregate?:ModelTypes["voyce_users_followers_aggregate_fields"],
	nodes:ModelTypes["voyce_users_followers"][]
};
	["voyce_users_followers_aggregate_bool_exp"]: GraphQLTypes["voyce_users_followers_aggregate_bool_exp"];
	["voyce_users_followers_aggregate_bool_exp_count"]: GraphQLTypes["voyce_users_followers_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.users_followers" */
["voyce_users_followers_aggregate_fields"]: {
		avg?:ModelTypes["voyce_users_followers_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_users_followers_max_fields"],
	min?:ModelTypes["voyce_users_followers_min_fields"],
	stddev?:ModelTypes["voyce_users_followers_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_users_followers_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_users_followers_stddev_samp_fields"],
	sum?:ModelTypes["voyce_users_followers_sum_fields"],
	var_pop?:ModelTypes["voyce_users_followers_var_pop_fields"],
	var_samp?:ModelTypes["voyce_users_followers_var_samp_fields"],
	variance?:ModelTypes["voyce_users_followers_variance_fields"]
};
	/** order by aggregate values of table "voyce.users_followers" */
["voyce_users_followers_aggregate_order_by"]: GraphQLTypes["voyce_users_followers_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.users_followers" */
["voyce_users_followers_arr_rel_insert_input"]: GraphQLTypes["voyce_users_followers_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_users_followers_avg_fields"]: {
		follower_uid?:number,
	following_uid?:number,
	id?:number
};
	/** order by avg() on columns of table "voyce.users_followers" */
["voyce_users_followers_avg_order_by"]: GraphQLTypes["voyce_users_followers_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.users_followers". All fields are combined with a logical 'AND'. */
["voyce_users_followers_bool_exp"]: GraphQLTypes["voyce_users_followers_bool_exp"];
	/** unique or primary key constraints on table "voyce.users_followers" */
["voyce_users_followers_constraint"]: GraphQLTypes["voyce_users_followers_constraint"];
	/** input type for incrementing numeric columns in table "voyce.users_followers" */
["voyce_users_followers_inc_input"]: GraphQLTypes["voyce_users_followers_inc_input"];
	/** input type for inserting data into table "voyce.users_followers" */
["voyce_users_followers_insert_input"]: GraphQLTypes["voyce_users_followers_insert_input"];
	/** aggregate max on columns */
["voyce_users_followers_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	follower_uid?:number,
	following_uid?:number,
	id?:number
};
	/** order by max() on columns of table "voyce.users_followers" */
["voyce_users_followers_max_order_by"]: GraphQLTypes["voyce_users_followers_max_order_by"];
	/** aggregate min on columns */
["voyce_users_followers_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	follower_uid?:number,
	following_uid?:number,
	id?:number
};
	/** order by min() on columns of table "voyce.users_followers" */
["voyce_users_followers_min_order_by"]: GraphQLTypes["voyce_users_followers_min_order_by"];
	/** response of any mutation on the table "voyce.users_followers" */
["voyce_users_followers_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_users_followers"][]
};
	/** on_conflict condition type for table "voyce.users_followers" */
["voyce_users_followers_on_conflict"]: GraphQLTypes["voyce_users_followers_on_conflict"];
	/** Ordering options when selecting data from "voyce.users_followers". */
["voyce_users_followers_order_by"]: GraphQLTypes["voyce_users_followers_order_by"];
	/** primary key columns input for table: voyce.users_followers */
["voyce_users_followers_pk_columns_input"]: GraphQLTypes["voyce_users_followers_pk_columns_input"];
	/** select columns of table "voyce.users_followers" */
["voyce_users_followers_select_column"]: GraphQLTypes["voyce_users_followers_select_column"];
	/** input type for updating data in table "voyce.users_followers" */
["voyce_users_followers_set_input"]: GraphQLTypes["voyce_users_followers_set_input"];
	/** aggregate stddev on columns */
["voyce_users_followers_stddev_fields"]: {
		follower_uid?:number,
	following_uid?:number,
	id?:number
};
	/** order by stddev() on columns of table "voyce.users_followers" */
["voyce_users_followers_stddev_order_by"]: GraphQLTypes["voyce_users_followers_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_users_followers_stddev_pop_fields"]: {
		follower_uid?:number,
	following_uid?:number,
	id?:number
};
	/** order by stddev_pop() on columns of table "voyce.users_followers" */
["voyce_users_followers_stddev_pop_order_by"]: GraphQLTypes["voyce_users_followers_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_users_followers_stddev_samp_fields"]: {
		follower_uid?:number,
	following_uid?:number,
	id?:number
};
	/** order by stddev_samp() on columns of table "voyce.users_followers" */
["voyce_users_followers_stddev_samp_order_by"]: GraphQLTypes["voyce_users_followers_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_users_followers" */
["voyce_users_followers_stream_cursor_input"]: GraphQLTypes["voyce_users_followers_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_users_followers_stream_cursor_value_input"]: GraphQLTypes["voyce_users_followers_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_users_followers_sum_fields"]: {
		follower_uid?:number,
	following_uid?:number,
	id?:number
};
	/** order by sum() on columns of table "voyce.users_followers" */
["voyce_users_followers_sum_order_by"]: GraphQLTypes["voyce_users_followers_sum_order_by"];
	/** update columns of table "voyce.users_followers" */
["voyce_users_followers_update_column"]: GraphQLTypes["voyce_users_followers_update_column"];
	["voyce_users_followers_updates"]: GraphQLTypes["voyce_users_followers_updates"];
	/** aggregate var_pop on columns */
["voyce_users_followers_var_pop_fields"]: {
		follower_uid?:number,
	following_uid?:number,
	id?:number
};
	/** order by var_pop() on columns of table "voyce.users_followers" */
["voyce_users_followers_var_pop_order_by"]: GraphQLTypes["voyce_users_followers_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_users_followers_var_samp_fields"]: {
		follower_uid?:number,
	following_uid?:number,
	id?:number
};
	/** order by var_samp() on columns of table "voyce.users_followers" */
["voyce_users_followers_var_samp_order_by"]: GraphQLTypes["voyce_users_followers_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_users_followers_variance_fields"]: {
		follower_uid?:number,
	following_uid?:number,
	id?:number
};
	/** order by variance() on columns of table "voyce.users_followers" */
["voyce_users_followers_variance_order_by"]: GraphQLTypes["voyce_users_followers_variance_order_by"];
	/** input type for incrementing numeric columns in table "voyce.users" */
["voyce_users_inc_input"]: GraphQLTypes["voyce_users_inc_input"];
	/** input type for inserting data into table "voyce.users" */
["voyce_users_insert_input"]: GraphQLTypes["voyce_users_insert_input"];
	/** aggregate max on columns */
["voyce_users_max_fields"]: {
		about?:string,
	ad_share?:number,
	admin_role_id?:number,
	avatar?:string,
	avatar_bgcolor?:string,
	banner?:string,
	birth_date?:ModelTypes["date"],
	cognito_id?:string,
	cognito_provider?:string,
	cognito_username?:string,
	country_id?:number,
	dark_mode?:ModelTypes["smallint"],
	date_create?:ModelTypes["timestamp"],
	delete_code?:string,
	email?:string,
	/** mobile or web or null */
	email_verified_source?:string,
	fcm_token?:string,
	first_name?:string,
	frame?:string,
	gender?:string,
	guest_gtag?:string,
	id?:number,
	is_account_active?:ModelTypes["smallint"],
	is_onboarded?:number,
	klaviyo_id?:string,
	last_login?:ModelTypes["timestamp"],
	last_name?:string,
	linked_account_status?:string,
	new_email_code?:string,
	nickname?:string,
	password?:string,
	password_secure?:string,
	publisher_verification_code?:string,
	remember_code?:string,
	reset_code?:string,
	reset_code_created_at?:ModelTypes["timestamptz"],
	reset_otp?:string,
	role_id?:number,
	/** mobile or web or null */
	signup_source?:string,
	social_discord?:string,
	social_fb?:string,
	social_instagram?:string,
	social_tiktok?:string,
	social_twitter?:string,
	updated_at?:ModelTypes["timestamptz"],
	user_type_preference?:string,
	username?:string
};
	/** aggregate min on columns */
["voyce_users_min_fields"]: {
		about?:string,
	ad_share?:number,
	admin_role_id?:number,
	avatar?:string,
	avatar_bgcolor?:string,
	banner?:string,
	birth_date?:ModelTypes["date"],
	cognito_id?:string,
	cognito_provider?:string,
	cognito_username?:string,
	country_id?:number,
	dark_mode?:ModelTypes["smallint"],
	date_create?:ModelTypes["timestamp"],
	delete_code?:string,
	email?:string,
	/** mobile or web or null */
	email_verified_source?:string,
	fcm_token?:string,
	first_name?:string,
	frame?:string,
	gender?:string,
	guest_gtag?:string,
	id?:number,
	is_account_active?:ModelTypes["smallint"],
	is_onboarded?:number,
	klaviyo_id?:string,
	last_login?:ModelTypes["timestamp"],
	last_name?:string,
	linked_account_status?:string,
	new_email_code?:string,
	nickname?:string,
	password?:string,
	password_secure?:string,
	publisher_verification_code?:string,
	remember_code?:string,
	reset_code?:string,
	reset_code_created_at?:ModelTypes["timestamptz"],
	reset_otp?:string,
	role_id?:number,
	/** mobile or web or null */
	signup_source?:string,
	social_discord?:string,
	social_fb?:string,
	social_instagram?:string,
	social_tiktok?:string,
	social_twitter?:string,
	updated_at?:ModelTypes["timestamptz"],
	user_type_preference?:string,
	username?:string
};
	/** response of any mutation on the table "voyce.users" */
["voyce_users_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_users"][]
};
	/** input type for inserting object relation for remote table "voyce.users" */
["voyce_users_obj_rel_insert_input"]: GraphQLTypes["voyce_users_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.users" */
["voyce_users_on_conflict"]: GraphQLTypes["voyce_users_on_conflict"];
	/** Ordering options when selecting data from "voyce.users". */
["voyce_users_order_by"]: GraphQLTypes["voyce_users_order_by"];
	/** primary key columns input for table: voyce.users */
["voyce_users_pk_columns_input"]: GraphQLTypes["voyce_users_pk_columns_input"];
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["voyce_users_prepend_input"]: GraphQLTypes["voyce_users_prepend_input"];
	/** columns and relationships of "voyce.users_private" */
["voyce_users_private"]: {
		about?:string,
	ad_share?:number,
	avatar?:string,
	banner?:string,
	birth_date?:ModelTypes["date"],
	dark_mode?:ModelTypes["smallint"],
	date_create?:ModelTypes["timestamp"],
	email?:string,
	first_name?:string,
	gender?:string,
	id?:number,
	is_account_active?:ModelTypes["smallint"],
	last_login?:ModelTypes["timestamp"],
	last_name?:string,
	nickname?:string,
	password?:string,
	remember_code?:string,
	role_id?:number,
	username?:string,
	vmuser?:boolean
};
	/** aggregated selection of "voyce.users_private" */
["voyce_users_private_aggregate"]: {
		aggregate?:ModelTypes["voyce_users_private_aggregate_fields"],
	nodes:ModelTypes["voyce_users_private"][]
};
	/** aggregate fields of "voyce.users_private" */
["voyce_users_private_aggregate_fields"]: {
		avg?:ModelTypes["voyce_users_private_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_users_private_max_fields"],
	min?:ModelTypes["voyce_users_private_min_fields"],
	stddev?:ModelTypes["voyce_users_private_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_users_private_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_users_private_stddev_samp_fields"],
	sum?:ModelTypes["voyce_users_private_sum_fields"],
	var_pop?:ModelTypes["voyce_users_private_var_pop_fields"],
	var_samp?:ModelTypes["voyce_users_private_var_samp_fields"],
	variance?:ModelTypes["voyce_users_private_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_users_private_avg_fields"]: {
		ad_share?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	role_id?:number
};
	/** Boolean expression to filter rows from the table "voyce.users_private". All fields are combined with a logical 'AND'. */
["voyce_users_private_bool_exp"]: GraphQLTypes["voyce_users_private_bool_exp"];
	/** input type for incrementing numeric columns in table "voyce.users_private" */
["voyce_users_private_inc_input"]: GraphQLTypes["voyce_users_private_inc_input"];
	/** input type for inserting data into table "voyce.users_private" */
["voyce_users_private_insert_input"]: GraphQLTypes["voyce_users_private_insert_input"];
	/** aggregate max on columns */
["voyce_users_private_max_fields"]: {
		about?:string,
	ad_share?:number,
	avatar?:string,
	banner?:string,
	birth_date?:ModelTypes["date"],
	dark_mode?:ModelTypes["smallint"],
	date_create?:ModelTypes["timestamp"],
	email?:string,
	first_name?:string,
	gender?:string,
	id?:number,
	is_account_active?:ModelTypes["smallint"],
	last_login?:ModelTypes["timestamp"],
	last_name?:string,
	nickname?:string,
	password?:string,
	remember_code?:string,
	role_id?:number,
	username?:string
};
	/** aggregate min on columns */
["voyce_users_private_min_fields"]: {
		about?:string,
	ad_share?:number,
	avatar?:string,
	banner?:string,
	birth_date?:ModelTypes["date"],
	dark_mode?:ModelTypes["smallint"],
	date_create?:ModelTypes["timestamp"],
	email?:string,
	first_name?:string,
	gender?:string,
	id?:number,
	is_account_active?:ModelTypes["smallint"],
	last_login?:ModelTypes["timestamp"],
	last_name?:string,
	nickname?:string,
	password?:string,
	remember_code?:string,
	role_id?:number,
	username?:string
};
	/** response of any mutation on the table "voyce.users_private" */
["voyce_users_private_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_users_private"][]
};
	/** input type for inserting object relation for remote table "voyce.users_private" */
["voyce_users_private_obj_rel_insert_input"]: GraphQLTypes["voyce_users_private_obj_rel_insert_input"];
	/** Ordering options when selecting data from "voyce.users_private". */
["voyce_users_private_order_by"]: GraphQLTypes["voyce_users_private_order_by"];
	/** select columns of table "voyce.users_private" */
["voyce_users_private_select_column"]: GraphQLTypes["voyce_users_private_select_column"];
	/** input type for updating data in table "voyce.users_private" */
["voyce_users_private_set_input"]: GraphQLTypes["voyce_users_private_set_input"];
	/** aggregate stddev on columns */
["voyce_users_private_stddev_fields"]: {
		ad_share?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	role_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_users_private_stddev_pop_fields"]: {
		ad_share?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	role_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_users_private_stddev_samp_fields"]: {
		ad_share?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	role_id?:number
};
	/** Streaming cursor of the table "voyce_users_private" */
["voyce_users_private_stream_cursor_input"]: GraphQLTypes["voyce_users_private_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_users_private_stream_cursor_value_input"]: GraphQLTypes["voyce_users_private_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_users_private_sum_fields"]: {
		ad_share?:number,
	dark_mode?:ModelTypes["smallint"],
	id?:number,
	is_account_active?:ModelTypes["smallint"],
	role_id?:number
};
	["voyce_users_private_updates"]: GraphQLTypes["voyce_users_private_updates"];
	/** aggregate var_pop on columns */
["voyce_users_private_var_pop_fields"]: {
		ad_share?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	role_id?:number
};
	/** aggregate var_samp on columns */
["voyce_users_private_var_samp_fields"]: {
		ad_share?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	role_id?:number
};
	/** aggregate variance on columns */
["voyce_users_private_variance_fields"]: {
		ad_share?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	role_id?:number
};
	/** select columns of table "voyce.users" */
["voyce_users_select_column"]: GraphQLTypes["voyce_users_select_column"];
	/** columns and relationships of "voyce.users_series_junction" */
["voyce_users_series_junction"]: {
		id:number,
	/** An object relationship */
	series:ModelTypes["voyce_series"],
	series_id:number,
	/** An object relationship */
	user:ModelTypes["voyce_users"],
	user_id:number
};
	/** aggregated selection of "voyce.users_series_junction" */
["voyce_users_series_junction_aggregate"]: {
		aggregate?:ModelTypes["voyce_users_series_junction_aggregate_fields"],
	nodes:ModelTypes["voyce_users_series_junction"][]
};
	["voyce_users_series_junction_aggregate_bool_exp"]: GraphQLTypes["voyce_users_series_junction_aggregate_bool_exp"];
	["voyce_users_series_junction_aggregate_bool_exp_count"]: GraphQLTypes["voyce_users_series_junction_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.users_series_junction" */
["voyce_users_series_junction_aggregate_fields"]: {
		avg?:ModelTypes["voyce_users_series_junction_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_users_series_junction_max_fields"],
	min?:ModelTypes["voyce_users_series_junction_min_fields"],
	stddev?:ModelTypes["voyce_users_series_junction_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_users_series_junction_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_users_series_junction_stddev_samp_fields"],
	sum?:ModelTypes["voyce_users_series_junction_sum_fields"],
	var_pop?:ModelTypes["voyce_users_series_junction_var_pop_fields"],
	var_samp?:ModelTypes["voyce_users_series_junction_var_samp_fields"],
	variance?:ModelTypes["voyce_users_series_junction_variance_fields"]
};
	/** order by aggregate values of table "voyce.users_series_junction" */
["voyce_users_series_junction_aggregate_order_by"]: GraphQLTypes["voyce_users_series_junction_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.users_series_junction" */
["voyce_users_series_junction_arr_rel_insert_input"]: GraphQLTypes["voyce_users_series_junction_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_users_series_junction_avg_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by avg() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_avg_order_by"]: GraphQLTypes["voyce_users_series_junction_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.users_series_junction". All fields are combined with a logical 'AND'. */
["voyce_users_series_junction_bool_exp"]: GraphQLTypes["voyce_users_series_junction_bool_exp"];
	/** unique or primary key constraints on table "voyce.users_series_junction" */
["voyce_users_series_junction_constraint"]: GraphQLTypes["voyce_users_series_junction_constraint"];
	/** input type for incrementing numeric columns in table "voyce.users_series_junction" */
["voyce_users_series_junction_inc_input"]: GraphQLTypes["voyce_users_series_junction_inc_input"];
	/** input type for inserting data into table "voyce.users_series_junction" */
["voyce_users_series_junction_insert_input"]: GraphQLTypes["voyce_users_series_junction_insert_input"];
	/** aggregate max on columns */
["voyce_users_series_junction_max_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by max() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_max_order_by"]: GraphQLTypes["voyce_users_series_junction_max_order_by"];
	/** aggregate min on columns */
["voyce_users_series_junction_min_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by min() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_min_order_by"]: GraphQLTypes["voyce_users_series_junction_min_order_by"];
	/** response of any mutation on the table "voyce.users_series_junction" */
["voyce_users_series_junction_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_users_series_junction"][]
};
	/** on_conflict condition type for table "voyce.users_series_junction" */
["voyce_users_series_junction_on_conflict"]: GraphQLTypes["voyce_users_series_junction_on_conflict"];
	/** Ordering options when selecting data from "voyce.users_series_junction". */
["voyce_users_series_junction_order_by"]: GraphQLTypes["voyce_users_series_junction_order_by"];
	/** primary key columns input for table: voyce.users_series_junction */
["voyce_users_series_junction_pk_columns_input"]: GraphQLTypes["voyce_users_series_junction_pk_columns_input"];
	/** select columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_select_column"]: GraphQLTypes["voyce_users_series_junction_select_column"];
	/** input type for updating data in table "voyce.users_series_junction" */
["voyce_users_series_junction_set_input"]: GraphQLTypes["voyce_users_series_junction_set_input"];
	/** aggregate stddev on columns */
["voyce_users_series_junction_stddev_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_stddev_order_by"]: GraphQLTypes["voyce_users_series_junction_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_users_series_junction_stddev_pop_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_stddev_pop_order_by"]: GraphQLTypes["voyce_users_series_junction_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_users_series_junction_stddev_samp_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_stddev_samp_order_by"]: GraphQLTypes["voyce_users_series_junction_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_users_series_junction" */
["voyce_users_series_junction_stream_cursor_input"]: GraphQLTypes["voyce_users_series_junction_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_users_series_junction_stream_cursor_value_input"]: GraphQLTypes["voyce_users_series_junction_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_users_series_junction_sum_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by sum() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_sum_order_by"]: GraphQLTypes["voyce_users_series_junction_sum_order_by"];
	/** update columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_update_column"]: GraphQLTypes["voyce_users_series_junction_update_column"];
	["voyce_users_series_junction_updates"]: GraphQLTypes["voyce_users_series_junction_updates"];
	/** aggregate var_pop on columns */
["voyce_users_series_junction_var_pop_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by var_pop() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_var_pop_order_by"]: GraphQLTypes["voyce_users_series_junction_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_users_series_junction_var_samp_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by var_samp() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_var_samp_order_by"]: GraphQLTypes["voyce_users_series_junction_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_users_series_junction_variance_fields"]: {
		id?:number,
	series_id?:number,
	user_id?:number
};
	/** order by variance() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_variance_order_by"]: GraphQLTypes["voyce_users_series_junction_variance_order_by"];
	/** input type for updating data in table "voyce.users" */
["voyce_users_set_input"]: GraphQLTypes["voyce_users_set_input"];
	/** aggregate stddev on columns */
["voyce_users_stddev_fields"]: {
		ad_share?:number,
	admin_role_id?:number,
	country_id?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	is_onboarded?:number,
	role_id?:number
};
	/** aggregate stddev_pop on columns */
["voyce_users_stddev_pop_fields"]: {
		ad_share?:number,
	admin_role_id?:number,
	country_id?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	is_onboarded?:number,
	role_id?:number
};
	/** aggregate stddev_samp on columns */
["voyce_users_stddev_samp_fields"]: {
		ad_share?:number,
	admin_role_id?:number,
	country_id?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	is_onboarded?:number,
	role_id?:number
};
	/** Streaming cursor of the table "voyce_users" */
["voyce_users_stream_cursor_input"]: GraphQLTypes["voyce_users_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_users_stream_cursor_value_input"]: GraphQLTypes["voyce_users_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_users_sum_fields"]: {
		ad_share?:number,
	admin_role_id?:number,
	country_id?:number,
	dark_mode?:ModelTypes["smallint"],
	id?:number,
	is_account_active?:ModelTypes["smallint"],
	is_onboarded?:number,
	role_id?:number
};
	/** update columns of table "voyce.users" */
["voyce_users_update_column"]: GraphQLTypes["voyce_users_update_column"];
	["voyce_users_updates"]: GraphQLTypes["voyce_users_updates"];
	/** aggregate var_pop on columns */
["voyce_users_var_pop_fields"]: {
		ad_share?:number,
	admin_role_id?:number,
	country_id?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	is_onboarded?:number,
	role_id?:number
};
	/** aggregate var_samp on columns */
["voyce_users_var_samp_fields"]: {
		ad_share?:number,
	admin_role_id?:number,
	country_id?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	is_onboarded?:number,
	role_id?:number
};
	/** aggregate variance on columns */
["voyce_users_variance_fields"]: {
		ad_share?:number,
	admin_role_id?:number,
	country_id?:number,
	dark_mode?:number,
	id?:number,
	is_account_active?:number,
	is_onboarded?:number,
	role_id?:number
};
	/** columns and relationships of "voyce.versions" */
["voyce_versions"]: {
		id:number,
	table:string,
	updated_at:ModelTypes["timestamptz"],
	version:number
};
	/** aggregated selection of "voyce.versions" */
["voyce_versions_aggregate"]: {
		aggregate?:ModelTypes["voyce_versions_aggregate_fields"],
	nodes:ModelTypes["voyce_versions"][]
};
	/** aggregate fields of "voyce.versions" */
["voyce_versions_aggregate_fields"]: {
		avg?:ModelTypes["voyce_versions_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_versions_max_fields"],
	min?:ModelTypes["voyce_versions_min_fields"],
	stddev?:ModelTypes["voyce_versions_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_versions_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_versions_stddev_samp_fields"],
	sum?:ModelTypes["voyce_versions_sum_fields"],
	var_pop?:ModelTypes["voyce_versions_var_pop_fields"],
	var_samp?:ModelTypes["voyce_versions_var_samp_fields"],
	variance?:ModelTypes["voyce_versions_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_versions_avg_fields"]: {
		id?:number,
	version?:number
};
	/** Boolean expression to filter rows from the table "voyce.versions". All fields are combined with a logical 'AND'. */
["voyce_versions_bool_exp"]: GraphQLTypes["voyce_versions_bool_exp"];
	/** unique or primary key constraints on table "voyce.versions" */
["voyce_versions_constraint"]: GraphQLTypes["voyce_versions_constraint"];
	/** input type for incrementing numeric columns in table "voyce.versions" */
["voyce_versions_inc_input"]: GraphQLTypes["voyce_versions_inc_input"];
	/** input type for inserting data into table "voyce.versions" */
["voyce_versions_insert_input"]: GraphQLTypes["voyce_versions_insert_input"];
	/** aggregate max on columns */
["voyce_versions_max_fields"]: {
		id?:number,
	table?:string,
	updated_at?:ModelTypes["timestamptz"],
	version?:number
};
	/** aggregate min on columns */
["voyce_versions_min_fields"]: {
		id?:number,
	table?:string,
	updated_at?:ModelTypes["timestamptz"],
	version?:number
};
	/** response of any mutation on the table "voyce.versions" */
["voyce_versions_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_versions"][]
};
	/** on_conflict condition type for table "voyce.versions" */
["voyce_versions_on_conflict"]: GraphQLTypes["voyce_versions_on_conflict"];
	/** Ordering options when selecting data from "voyce.versions". */
["voyce_versions_order_by"]: GraphQLTypes["voyce_versions_order_by"];
	/** primary key columns input for table: voyce.versions */
["voyce_versions_pk_columns_input"]: GraphQLTypes["voyce_versions_pk_columns_input"];
	/** select columns of table "voyce.versions" */
["voyce_versions_select_column"]: GraphQLTypes["voyce_versions_select_column"];
	/** input type for updating data in table "voyce.versions" */
["voyce_versions_set_input"]: GraphQLTypes["voyce_versions_set_input"];
	/** aggregate stddev on columns */
["voyce_versions_stddev_fields"]: {
		id?:number,
	version?:number
};
	/** aggregate stddev_pop on columns */
["voyce_versions_stddev_pop_fields"]: {
		id?:number,
	version?:number
};
	/** aggregate stddev_samp on columns */
["voyce_versions_stddev_samp_fields"]: {
		id?:number,
	version?:number
};
	/** Streaming cursor of the table "voyce_versions" */
["voyce_versions_stream_cursor_input"]: GraphQLTypes["voyce_versions_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_versions_stream_cursor_value_input"]: GraphQLTypes["voyce_versions_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_versions_sum_fields"]: {
		id?:number,
	version?:number
};
	/** update columns of table "voyce.versions" */
["voyce_versions_update_column"]: GraphQLTypes["voyce_versions_update_column"];
	["voyce_versions_updates"]: GraphQLTypes["voyce_versions_updates"];
	/** aggregate var_pop on columns */
["voyce_versions_var_pop_fields"]: {
		id?:number,
	version?:number
};
	/** aggregate var_samp on columns */
["voyce_versions_var_samp_fields"]: {
		id?:number,
	version?:number
};
	/** aggregate variance on columns */
["voyce_versions_variance_fields"]: {
		id?:number,
	version?:number
};
	/** columns and relationships of "voyce.voting_analytics" */
["voyce_voting_analytics"]: {
		created_at:ModelTypes["timestamptz"],
	cumulative_votes:number,
	daily_votes:number,
	id:number,
	meta?:string,
	updated_at:ModelTypes["timestamptz"],
	vote_date:ModelTypes["date"],
	/** An object relationship */
	voting_series?:ModelTypes["voyce_voting_series"],
	voting_series_id:number
};
	/** aggregated selection of "voyce.voting_analytics" */
["voyce_voting_analytics_aggregate"]: {
		aggregate?:ModelTypes["voyce_voting_analytics_aggregate_fields"],
	nodes:ModelTypes["voyce_voting_analytics"][]
};
	["voyce_voting_analytics_aggregate_bool_exp"]: GraphQLTypes["voyce_voting_analytics_aggregate_bool_exp"];
	["voyce_voting_analytics_aggregate_bool_exp_count"]: GraphQLTypes["voyce_voting_analytics_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.voting_analytics" */
["voyce_voting_analytics_aggregate_fields"]: {
		avg?:ModelTypes["voyce_voting_analytics_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_voting_analytics_max_fields"],
	min?:ModelTypes["voyce_voting_analytics_min_fields"],
	stddev?:ModelTypes["voyce_voting_analytics_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_voting_analytics_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_voting_analytics_stddev_samp_fields"],
	sum?:ModelTypes["voyce_voting_analytics_sum_fields"],
	var_pop?:ModelTypes["voyce_voting_analytics_var_pop_fields"],
	var_samp?:ModelTypes["voyce_voting_analytics_var_samp_fields"],
	variance?:ModelTypes["voyce_voting_analytics_variance_fields"]
};
	/** order by aggregate values of table "voyce.voting_analytics" */
["voyce_voting_analytics_aggregate_order_by"]: GraphQLTypes["voyce_voting_analytics_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.voting_analytics" */
["voyce_voting_analytics_arr_rel_insert_input"]: GraphQLTypes["voyce_voting_analytics_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_voting_analytics_avg_fields"]: {
		cumulative_votes?:number,
	daily_votes?:number,
	id?:number,
	voting_series_id?:number
};
	/** order by avg() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_avg_order_by"]: GraphQLTypes["voyce_voting_analytics_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.voting_analytics". All fields are combined with a logical 'AND'. */
["voyce_voting_analytics_bool_exp"]: GraphQLTypes["voyce_voting_analytics_bool_exp"];
	/** unique or primary key constraints on table "voyce.voting_analytics" */
["voyce_voting_analytics_constraint"]: GraphQLTypes["voyce_voting_analytics_constraint"];
	/** input type for incrementing numeric columns in table "voyce.voting_analytics" */
["voyce_voting_analytics_inc_input"]: GraphQLTypes["voyce_voting_analytics_inc_input"];
	/** input type for inserting data into table "voyce.voting_analytics" */
["voyce_voting_analytics_insert_input"]: GraphQLTypes["voyce_voting_analytics_insert_input"];
	/** aggregate max on columns */
["voyce_voting_analytics_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	cumulative_votes?:number,
	daily_votes?:number,
	id?:number,
	meta?:string,
	updated_at?:ModelTypes["timestamptz"],
	vote_date?:ModelTypes["date"],
	voting_series_id?:number
};
	/** order by max() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_max_order_by"]: GraphQLTypes["voyce_voting_analytics_max_order_by"];
	/** aggregate min on columns */
["voyce_voting_analytics_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	cumulative_votes?:number,
	daily_votes?:number,
	id?:number,
	meta?:string,
	updated_at?:ModelTypes["timestamptz"],
	vote_date?:ModelTypes["date"],
	voting_series_id?:number
};
	/** order by min() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_min_order_by"]: GraphQLTypes["voyce_voting_analytics_min_order_by"];
	/** response of any mutation on the table "voyce.voting_analytics" */
["voyce_voting_analytics_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_voting_analytics"][]
};
	/** on_conflict condition type for table "voyce.voting_analytics" */
["voyce_voting_analytics_on_conflict"]: GraphQLTypes["voyce_voting_analytics_on_conflict"];
	/** Ordering options when selecting data from "voyce.voting_analytics". */
["voyce_voting_analytics_order_by"]: GraphQLTypes["voyce_voting_analytics_order_by"];
	/** primary key columns input for table: voyce.voting_analytics */
["voyce_voting_analytics_pk_columns_input"]: GraphQLTypes["voyce_voting_analytics_pk_columns_input"];
	/** select columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_select_column"]: GraphQLTypes["voyce_voting_analytics_select_column"];
	/** input type for updating data in table "voyce.voting_analytics" */
["voyce_voting_analytics_set_input"]: GraphQLTypes["voyce_voting_analytics_set_input"];
	/** aggregate stddev on columns */
["voyce_voting_analytics_stddev_fields"]: {
		cumulative_votes?:number,
	daily_votes?:number,
	id?:number,
	voting_series_id?:number
};
	/** order by stddev() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_stddev_order_by"]: GraphQLTypes["voyce_voting_analytics_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_voting_analytics_stddev_pop_fields"]: {
		cumulative_votes?:number,
	daily_votes?:number,
	id?:number,
	voting_series_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_stddev_pop_order_by"]: GraphQLTypes["voyce_voting_analytics_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_voting_analytics_stddev_samp_fields"]: {
		cumulative_votes?:number,
	daily_votes?:number,
	id?:number,
	voting_series_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_stddev_samp_order_by"]: GraphQLTypes["voyce_voting_analytics_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_voting_analytics" */
["voyce_voting_analytics_stream_cursor_input"]: GraphQLTypes["voyce_voting_analytics_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_voting_analytics_stream_cursor_value_input"]: GraphQLTypes["voyce_voting_analytics_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_voting_analytics_sum_fields"]: {
		cumulative_votes?:number,
	daily_votes?:number,
	id?:number,
	voting_series_id?:number
};
	/** order by sum() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_sum_order_by"]: GraphQLTypes["voyce_voting_analytics_sum_order_by"];
	/** update columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_update_column"]: GraphQLTypes["voyce_voting_analytics_update_column"];
	["voyce_voting_analytics_updates"]: GraphQLTypes["voyce_voting_analytics_updates"];
	/** aggregate var_pop on columns */
["voyce_voting_analytics_var_pop_fields"]: {
		cumulative_votes?:number,
	daily_votes?:number,
	id?:number,
	voting_series_id?:number
};
	/** order by var_pop() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_var_pop_order_by"]: GraphQLTypes["voyce_voting_analytics_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_voting_analytics_var_samp_fields"]: {
		cumulative_votes?:number,
	daily_votes?:number,
	id?:number,
	voting_series_id?:number
};
	/** order by var_samp() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_var_samp_order_by"]: GraphQLTypes["voyce_voting_analytics_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_voting_analytics_variance_fields"]: {
		cumulative_votes?:number,
	daily_votes?:number,
	id?:number,
	voting_series_id?:number
};
	/** order by variance() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_variance_order_by"]: GraphQLTypes["voyce_voting_analytics_variance_order_by"];
	/** columns and relationships of "voyce.voting_panels" */
["voyce_voting_panels"]: {
		created_at:ModelTypes["timestamptz"],
	id:number,
	panel_image:string,
	updated_at:ModelTypes["timestamptz"],
	/** An object relationship */
	voting_series?:ModelTypes["voyce_voting_series"],
	voting_series_id:number
};
	/** aggregated selection of "voyce.voting_panels" */
["voyce_voting_panels_aggregate"]: {
		aggregate?:ModelTypes["voyce_voting_panels_aggregate_fields"],
	nodes:ModelTypes["voyce_voting_panels"][]
};
	["voyce_voting_panels_aggregate_bool_exp"]: GraphQLTypes["voyce_voting_panels_aggregate_bool_exp"];
	["voyce_voting_panels_aggregate_bool_exp_count"]: GraphQLTypes["voyce_voting_panels_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.voting_panels" */
["voyce_voting_panels_aggregate_fields"]: {
		avg?:ModelTypes["voyce_voting_panels_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_voting_panels_max_fields"],
	min?:ModelTypes["voyce_voting_panels_min_fields"],
	stddev?:ModelTypes["voyce_voting_panels_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_voting_panels_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_voting_panels_stddev_samp_fields"],
	sum?:ModelTypes["voyce_voting_panels_sum_fields"],
	var_pop?:ModelTypes["voyce_voting_panels_var_pop_fields"],
	var_samp?:ModelTypes["voyce_voting_panels_var_samp_fields"],
	variance?:ModelTypes["voyce_voting_panels_variance_fields"]
};
	/** order by aggregate values of table "voyce.voting_panels" */
["voyce_voting_panels_aggregate_order_by"]: GraphQLTypes["voyce_voting_panels_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.voting_panels" */
["voyce_voting_panels_arr_rel_insert_input"]: GraphQLTypes["voyce_voting_panels_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_voting_panels_avg_fields"]: {
		id?:number,
	voting_series_id?:number
};
	/** order by avg() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_avg_order_by"]: GraphQLTypes["voyce_voting_panels_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.voting_panels". All fields are combined with a logical 'AND'. */
["voyce_voting_panels_bool_exp"]: GraphQLTypes["voyce_voting_panels_bool_exp"];
	/** unique or primary key constraints on table "voyce.voting_panels" */
["voyce_voting_panels_constraint"]: GraphQLTypes["voyce_voting_panels_constraint"];
	/** input type for incrementing numeric columns in table "voyce.voting_panels" */
["voyce_voting_panels_inc_input"]: GraphQLTypes["voyce_voting_panels_inc_input"];
	/** input type for inserting data into table "voyce.voting_panels" */
["voyce_voting_panels_insert_input"]: GraphQLTypes["voyce_voting_panels_insert_input"];
	/** aggregate max on columns */
["voyce_voting_panels_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	panel_image?:string,
	updated_at?:ModelTypes["timestamptz"],
	voting_series_id?:number
};
	/** order by max() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_max_order_by"]: GraphQLTypes["voyce_voting_panels_max_order_by"];
	/** aggregate min on columns */
["voyce_voting_panels_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	id?:number,
	panel_image?:string,
	updated_at?:ModelTypes["timestamptz"],
	voting_series_id?:number
};
	/** order by min() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_min_order_by"]: GraphQLTypes["voyce_voting_panels_min_order_by"];
	/** response of any mutation on the table "voyce.voting_panels" */
["voyce_voting_panels_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_voting_panels"][]
};
	/** on_conflict condition type for table "voyce.voting_panels" */
["voyce_voting_panels_on_conflict"]: GraphQLTypes["voyce_voting_panels_on_conflict"];
	/** Ordering options when selecting data from "voyce.voting_panels". */
["voyce_voting_panels_order_by"]: GraphQLTypes["voyce_voting_panels_order_by"];
	/** primary key columns input for table: voyce.voting_panels */
["voyce_voting_panels_pk_columns_input"]: GraphQLTypes["voyce_voting_panels_pk_columns_input"];
	/** select columns of table "voyce.voting_panels" */
["voyce_voting_panels_select_column"]: GraphQLTypes["voyce_voting_panels_select_column"];
	/** input type for updating data in table "voyce.voting_panels" */
["voyce_voting_panels_set_input"]: GraphQLTypes["voyce_voting_panels_set_input"];
	/** aggregate stddev on columns */
["voyce_voting_panels_stddev_fields"]: {
		id?:number,
	voting_series_id?:number
};
	/** order by stddev() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_stddev_order_by"]: GraphQLTypes["voyce_voting_panels_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_voting_panels_stddev_pop_fields"]: {
		id?:number,
	voting_series_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_stddev_pop_order_by"]: GraphQLTypes["voyce_voting_panels_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_voting_panels_stddev_samp_fields"]: {
		id?:number,
	voting_series_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_stddev_samp_order_by"]: GraphQLTypes["voyce_voting_panels_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_voting_panels" */
["voyce_voting_panels_stream_cursor_input"]: GraphQLTypes["voyce_voting_panels_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_voting_panels_stream_cursor_value_input"]: GraphQLTypes["voyce_voting_panels_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_voting_panels_sum_fields"]: {
		id?:number,
	voting_series_id?:number
};
	/** order by sum() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_sum_order_by"]: GraphQLTypes["voyce_voting_panels_sum_order_by"];
	/** update columns of table "voyce.voting_panels" */
["voyce_voting_panels_update_column"]: GraphQLTypes["voyce_voting_panels_update_column"];
	["voyce_voting_panels_updates"]: GraphQLTypes["voyce_voting_panels_updates"];
	/** aggregate var_pop on columns */
["voyce_voting_panels_var_pop_fields"]: {
		id?:number,
	voting_series_id?:number
};
	/** order by var_pop() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_var_pop_order_by"]: GraphQLTypes["voyce_voting_panels_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_voting_panels_var_samp_fields"]: {
		id?:number,
	voting_series_id?:number
};
	/** order by var_samp() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_var_samp_order_by"]: GraphQLTypes["voyce_voting_panels_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_voting_panels_variance_fields"]: {
		id?:number,
	voting_series_id?:number
};
	/** order by variance() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_variance_order_by"]: GraphQLTypes["voyce_voting_panels_variance_order_by"];
	/** columns and relationships of "voyce.voting_series" */
["voyce_voting_series"]: {
		author_image?:string,
	author_name:string,
	collaborators?:string,
	created_at:ModelTypes["timestamptz"],
	id:number,
	/** An object relationship */
	series?:ModelTypes["voyce_series"],
	series_id:number,
	sort:number,
	spotlight_caption?:string,
	spotlight_img1?:string,
	spotlight_img2?:string,
	spotlight_img3?:string,
	updated_at:ModelTypes["timestamptz"],
	/** An array relationship */
	voting_analytics:ModelTypes["voyce_voting_analytics"][],
	/** An aggregate relationship */
	voting_analytics_aggregate:ModelTypes["voyce_voting_analytics_aggregate"],
	/** An array relationship */
	voting_panels:ModelTypes["voyce_voting_panels"][],
	/** An aggregate relationship */
	voting_panels_aggregate:ModelTypes["voyce_voting_panels_aggregate"],
	/** An array relationship */
	voting_user_votes:ModelTypes["voyce_voting_user_votes"][],
	/** An aggregate relationship */
	voting_user_votes_aggregate:ModelTypes["voyce_voting_user_votes_aggregate"]
};
	/** aggregated selection of "voyce.voting_series" */
["voyce_voting_series_aggregate"]: {
		aggregate?:ModelTypes["voyce_voting_series_aggregate_fields"],
	nodes:ModelTypes["voyce_voting_series"][]
};
	/** aggregate fields of "voyce.voting_series" */
["voyce_voting_series_aggregate_fields"]: {
		avg?:ModelTypes["voyce_voting_series_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_voting_series_max_fields"],
	min?:ModelTypes["voyce_voting_series_min_fields"],
	stddev?:ModelTypes["voyce_voting_series_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_voting_series_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_voting_series_stddev_samp_fields"],
	sum?:ModelTypes["voyce_voting_series_sum_fields"],
	var_pop?:ModelTypes["voyce_voting_series_var_pop_fields"],
	var_samp?:ModelTypes["voyce_voting_series_var_samp_fields"],
	variance?:ModelTypes["voyce_voting_series_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_voting_series_avg_fields"]: {
		id?:number,
	series_id?:number,
	sort?:number
};
	/** Boolean expression to filter rows from the table "voyce.voting_series". All fields are combined with a logical 'AND'. */
["voyce_voting_series_bool_exp"]: GraphQLTypes["voyce_voting_series_bool_exp"];
	/** unique or primary key constraints on table "voyce.voting_series" */
["voyce_voting_series_constraint"]: GraphQLTypes["voyce_voting_series_constraint"];
	/** input type for incrementing numeric columns in table "voyce.voting_series" */
["voyce_voting_series_inc_input"]: GraphQLTypes["voyce_voting_series_inc_input"];
	/** input type for inserting data into table "voyce.voting_series" */
["voyce_voting_series_insert_input"]: GraphQLTypes["voyce_voting_series_insert_input"];
	/** aggregate max on columns */
["voyce_voting_series_max_fields"]: {
		author_image?:string,
	author_name?:string,
	collaborators?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	series_id?:number,
	sort?:number,
	spotlight_caption?:string,
	spotlight_img1?:string,
	spotlight_img2?:string,
	spotlight_img3?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_voting_series_min_fields"]: {
		author_image?:string,
	author_name?:string,
	collaborators?:string,
	created_at?:ModelTypes["timestamptz"],
	id?:number,
	series_id?:number,
	sort?:number,
	spotlight_caption?:string,
	spotlight_img1?:string,
	spotlight_img2?:string,
	spotlight_img3?:string,
	updated_at?:ModelTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.voting_series" */
["voyce_voting_series_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_voting_series"][]
};
	/** input type for inserting object relation for remote table "voyce.voting_series" */
["voyce_voting_series_obj_rel_insert_input"]: GraphQLTypes["voyce_voting_series_obj_rel_insert_input"];
	/** on_conflict condition type for table "voyce.voting_series" */
["voyce_voting_series_on_conflict"]: GraphQLTypes["voyce_voting_series_on_conflict"];
	/** Ordering options when selecting data from "voyce.voting_series". */
["voyce_voting_series_order_by"]: GraphQLTypes["voyce_voting_series_order_by"];
	/** primary key columns input for table: voyce.voting_series */
["voyce_voting_series_pk_columns_input"]: GraphQLTypes["voyce_voting_series_pk_columns_input"];
	/** select columns of table "voyce.voting_series" */
["voyce_voting_series_select_column"]: GraphQLTypes["voyce_voting_series_select_column"];
	/** input type for updating data in table "voyce.voting_series" */
["voyce_voting_series_set_input"]: GraphQLTypes["voyce_voting_series_set_input"];
	/** aggregate stddev on columns */
["voyce_voting_series_stddev_fields"]: {
		id?:number,
	series_id?:number,
	sort?:number
};
	/** aggregate stddev_pop on columns */
["voyce_voting_series_stddev_pop_fields"]: {
		id?:number,
	series_id?:number,
	sort?:number
};
	/** aggregate stddev_samp on columns */
["voyce_voting_series_stddev_samp_fields"]: {
		id?:number,
	series_id?:number,
	sort?:number
};
	/** Streaming cursor of the table "voyce_voting_series" */
["voyce_voting_series_stream_cursor_input"]: GraphQLTypes["voyce_voting_series_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_voting_series_stream_cursor_value_input"]: GraphQLTypes["voyce_voting_series_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_voting_series_sum_fields"]: {
		id?:number,
	series_id?:number,
	sort?:number
};
	/** update columns of table "voyce.voting_series" */
["voyce_voting_series_update_column"]: GraphQLTypes["voyce_voting_series_update_column"];
	["voyce_voting_series_updates"]: GraphQLTypes["voyce_voting_series_updates"];
	/** aggregate var_pop on columns */
["voyce_voting_series_var_pop_fields"]: {
		id?:number,
	series_id?:number,
	sort?:number
};
	/** aggregate var_samp on columns */
["voyce_voting_series_var_samp_fields"]: {
		id?:number,
	series_id?:number,
	sort?:number
};
	/** aggregate variance on columns */
["voyce_voting_series_variance_fields"]: {
		id?:number,
	series_id?:number,
	sort?:number
};
	/** columns and relationships of "voyce.voting_user_votes" */
["voyce_voting_user_votes"]: {
		date_voted:ModelTypes["date"],
	id:number,
	time_voted:ModelTypes["timestamptz"],
	/** An object relationship */
	user?:ModelTypes["voyce_users"],
	user_id:number,
	/** An object relationship */
	voting_series?:ModelTypes["voyce_voting_series"],
	voting_series_id:number
};
	/** aggregated selection of "voyce.voting_user_votes" */
["voyce_voting_user_votes_aggregate"]: {
		aggregate?:ModelTypes["voyce_voting_user_votes_aggregate_fields"],
	nodes:ModelTypes["voyce_voting_user_votes"][]
};
	["voyce_voting_user_votes_aggregate_bool_exp"]: GraphQLTypes["voyce_voting_user_votes_aggregate_bool_exp"];
	["voyce_voting_user_votes_aggregate_bool_exp_count"]: GraphQLTypes["voyce_voting_user_votes_aggregate_bool_exp_count"];
	/** aggregate fields of "voyce.voting_user_votes" */
["voyce_voting_user_votes_aggregate_fields"]: {
		avg?:ModelTypes["voyce_voting_user_votes_avg_fields"],
	count:number,
	max?:ModelTypes["voyce_voting_user_votes_max_fields"],
	min?:ModelTypes["voyce_voting_user_votes_min_fields"],
	stddev?:ModelTypes["voyce_voting_user_votes_stddev_fields"],
	stddev_pop?:ModelTypes["voyce_voting_user_votes_stddev_pop_fields"],
	stddev_samp?:ModelTypes["voyce_voting_user_votes_stddev_samp_fields"],
	sum?:ModelTypes["voyce_voting_user_votes_sum_fields"],
	var_pop?:ModelTypes["voyce_voting_user_votes_var_pop_fields"],
	var_samp?:ModelTypes["voyce_voting_user_votes_var_samp_fields"],
	variance?:ModelTypes["voyce_voting_user_votes_variance_fields"]
};
	/** order by aggregate values of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_aggregate_order_by"]: GraphQLTypes["voyce_voting_user_votes_aggregate_order_by"];
	/** input type for inserting array relation for remote table "voyce.voting_user_votes" */
["voyce_voting_user_votes_arr_rel_insert_input"]: GraphQLTypes["voyce_voting_user_votes_arr_rel_insert_input"];
	/** aggregate avg on columns */
["voyce_voting_user_votes_avg_fields"]: {
		id?:number,
	user_id?:number,
	voting_series_id?:number
};
	/** order by avg() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_avg_order_by"]: GraphQLTypes["voyce_voting_user_votes_avg_order_by"];
	/** Boolean expression to filter rows from the table "voyce.voting_user_votes". All fields are combined with a logical 'AND'. */
["voyce_voting_user_votes_bool_exp"]: GraphQLTypes["voyce_voting_user_votes_bool_exp"];
	/** unique or primary key constraints on table "voyce.voting_user_votes" */
["voyce_voting_user_votes_constraint"]: GraphQLTypes["voyce_voting_user_votes_constraint"];
	/** input type for incrementing numeric columns in table "voyce.voting_user_votes" */
["voyce_voting_user_votes_inc_input"]: GraphQLTypes["voyce_voting_user_votes_inc_input"];
	/** input type for inserting data into table "voyce.voting_user_votes" */
["voyce_voting_user_votes_insert_input"]: GraphQLTypes["voyce_voting_user_votes_insert_input"];
	/** aggregate max on columns */
["voyce_voting_user_votes_max_fields"]: {
		date_voted?:ModelTypes["date"],
	id?:number,
	time_voted?:ModelTypes["timestamptz"],
	user_id?:number,
	voting_series_id?:number
};
	/** order by max() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_max_order_by"]: GraphQLTypes["voyce_voting_user_votes_max_order_by"];
	/** aggregate min on columns */
["voyce_voting_user_votes_min_fields"]: {
		date_voted?:ModelTypes["date"],
	id?:number,
	time_voted?:ModelTypes["timestamptz"],
	user_id?:number,
	voting_series_id?:number
};
	/** order by min() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_min_order_by"]: GraphQLTypes["voyce_voting_user_votes_min_order_by"];
	/** response of any mutation on the table "voyce.voting_user_votes" */
["voyce_voting_user_votes_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["voyce_voting_user_votes"][]
};
	/** on_conflict condition type for table "voyce.voting_user_votes" */
["voyce_voting_user_votes_on_conflict"]: GraphQLTypes["voyce_voting_user_votes_on_conflict"];
	/** Ordering options when selecting data from "voyce.voting_user_votes". */
["voyce_voting_user_votes_order_by"]: GraphQLTypes["voyce_voting_user_votes_order_by"];
	/** primary key columns input for table: voyce.voting_user_votes */
["voyce_voting_user_votes_pk_columns_input"]: GraphQLTypes["voyce_voting_user_votes_pk_columns_input"];
	/** select columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_select_column"]: GraphQLTypes["voyce_voting_user_votes_select_column"];
	/** input type for updating data in table "voyce.voting_user_votes" */
["voyce_voting_user_votes_set_input"]: GraphQLTypes["voyce_voting_user_votes_set_input"];
	/** aggregate stddev on columns */
["voyce_voting_user_votes_stddev_fields"]: {
		id?:number,
	user_id?:number,
	voting_series_id?:number
};
	/** order by stddev() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_stddev_order_by"]: GraphQLTypes["voyce_voting_user_votes_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["voyce_voting_user_votes_stddev_pop_fields"]: {
		id?:number,
	user_id?:number,
	voting_series_id?:number
};
	/** order by stddev_pop() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_stddev_pop_order_by"]: GraphQLTypes["voyce_voting_user_votes_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["voyce_voting_user_votes_stddev_samp_fields"]: {
		id?:number,
	user_id?:number,
	voting_series_id?:number
};
	/** order by stddev_samp() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_stddev_samp_order_by"]: GraphQLTypes["voyce_voting_user_votes_stddev_samp_order_by"];
	/** Streaming cursor of the table "voyce_voting_user_votes" */
["voyce_voting_user_votes_stream_cursor_input"]: GraphQLTypes["voyce_voting_user_votes_stream_cursor_input"];
	/** Initial value of the column from where the streaming should start */
["voyce_voting_user_votes_stream_cursor_value_input"]: GraphQLTypes["voyce_voting_user_votes_stream_cursor_value_input"];
	/** aggregate sum on columns */
["voyce_voting_user_votes_sum_fields"]: {
		id?:number,
	user_id?:number,
	voting_series_id?:number
};
	/** order by sum() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_sum_order_by"]: GraphQLTypes["voyce_voting_user_votes_sum_order_by"];
	/** update columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_update_column"]: GraphQLTypes["voyce_voting_user_votes_update_column"];
	["voyce_voting_user_votes_updates"]: GraphQLTypes["voyce_voting_user_votes_updates"];
	/** aggregate var_pop on columns */
["voyce_voting_user_votes_var_pop_fields"]: {
		id?:number,
	user_id?:number,
	voting_series_id?:number
};
	/** order by var_pop() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_var_pop_order_by"]: GraphQLTypes["voyce_voting_user_votes_var_pop_order_by"];
	/** aggregate var_samp on columns */
["voyce_voting_user_votes_var_samp_fields"]: {
		id?:number,
	user_id?:number,
	voting_series_id?:number
};
	/** order by var_samp() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_var_samp_order_by"]: GraphQLTypes["voyce_voting_user_votes_var_samp_order_by"];
	/** aggregate variance on columns */
["voyce_voting_user_votes_variance_fields"]: {
		id?:number,
	user_id?:number,
	voting_series_id?:number
};
	/** order by variance() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_variance_order_by"]: GraphQLTypes["voyce_voting_user_votes_variance_order_by"]
    }

export type GraphQLTypes = {
    ["AddResult"]: {
	__typename: "AddResult",
	sum?: number
};
	/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
["Boolean_comparison_exp"]: {
		_eq?: boolean,
	_gt?: boolean,
	_gte?: boolean,
	_in?: Array<boolean>,
	_is_null?: boolean,
	_lt?: boolean,
	_lte?: boolean,
	_neq?: boolean,
	_nin?: Array<boolean>
};
	/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
["Int_comparison_exp"]: {
		_eq?: number,
	_gt?: number,
	_gte?: number,
	_in?: Array<number>,
	_is_null?: boolean,
	_lt?: number,
	_lte?: number,
	_neq?: number,
	_nin?: Array<number>
};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
["String_comparison_exp"]: {
		_eq?: string,
	_gt?: string,
	_gte?: string,
	/** does the column match the given case-insensitive pattern */
	_ilike?: string,
	_in?: Array<string>,
	/** does the column match the given POSIX regular expression, case insensitive */
	_iregex?: string,
	_is_null?: boolean,
	/** does the column match the given pattern */
	_like?: string,
	_lt?: string,
	_lte?: string,
	_neq?: string,
	/** does the column NOT match the given case-insensitive pattern */
	_nilike?: string,
	_nin?: Array<string>,
	/** does the column NOT match the given POSIX regular expression, case insensitive */
	_niregex?: string,
	/** does the column NOT match the given pattern */
	_nlike?: string,
	/** does the column NOT match the given POSIX regular expression, case sensitive */
	_nregex?: string,
	/** does the column NOT match the given SQL regular expression */
	_nsimilar?: string,
	/** does the column match the given POSIX regular expression, case sensitive */
	_regex?: string,
	/** does the column match the given SQL regular expression */
	_similar?: string
};
	/** columns and relationships of "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1"]: {
	__typename: "awsdms_validation_failures_v1",
	DETAILS: string,
	FAILURE_TIME: GraphQLTypes["timestamp"],
	FAILURE_TYPE: string,
	KEY: string,
	KEY_TYPE: string,
	TABLE_NAME: string,
	TABLE_OWNER: string,
	TASK_NAME: string
};
	/** aggregated selection of "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_aggregate"]: {
	__typename: "awsdms_validation_failures_v1_aggregate",
	aggregate?: GraphQLTypes["awsdms_validation_failures_v1_aggregate_fields"],
	nodes: Array<GraphQLTypes["awsdms_validation_failures_v1"]>
};
	/** aggregate fields of "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_aggregate_fields"]: {
	__typename: "awsdms_validation_failures_v1_aggregate_fields",
	count: number,
	max?: GraphQLTypes["awsdms_validation_failures_v1_max_fields"],
	min?: GraphQLTypes["awsdms_validation_failures_v1_min_fields"]
};
	/** Boolean expression to filter rows from the table "awsdms_validation_failures_v1". All fields are combined with a logical 'AND'. */
["awsdms_validation_failures_v1_bool_exp"]: {
		DETAILS?: GraphQLTypes["String_comparison_exp"],
	FAILURE_TIME?: GraphQLTypes["timestamp_comparison_exp"],
	FAILURE_TYPE?: GraphQLTypes["String_comparison_exp"],
	KEY?: GraphQLTypes["String_comparison_exp"],
	KEY_TYPE?: GraphQLTypes["String_comparison_exp"],
	TABLE_NAME?: GraphQLTypes["String_comparison_exp"],
	TABLE_OWNER?: GraphQLTypes["String_comparison_exp"],
	TASK_NAME?: GraphQLTypes["String_comparison_exp"],
	_and?: Array<GraphQLTypes["awsdms_validation_failures_v1_bool_exp"]>,
	_not?: GraphQLTypes["awsdms_validation_failures_v1_bool_exp"],
	_or?: Array<GraphQLTypes["awsdms_validation_failures_v1_bool_exp"]>
};
	/** input type for inserting data into table "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_insert_input"]: {
		DETAILS?: string,
	FAILURE_TIME?: GraphQLTypes["timestamp"],
	FAILURE_TYPE?: string,
	KEY?: string,
	KEY_TYPE?: string,
	TABLE_NAME?: string,
	TABLE_OWNER?: string,
	TASK_NAME?: string
};
	/** aggregate max on columns */
["awsdms_validation_failures_v1_max_fields"]: {
	__typename: "awsdms_validation_failures_v1_max_fields",
	DETAILS?: string,
	FAILURE_TIME?: GraphQLTypes["timestamp"],
	FAILURE_TYPE?: string,
	KEY?: string,
	KEY_TYPE?: string,
	TABLE_NAME?: string,
	TABLE_OWNER?: string,
	TASK_NAME?: string
};
	/** aggregate min on columns */
["awsdms_validation_failures_v1_min_fields"]: {
	__typename: "awsdms_validation_failures_v1_min_fields",
	DETAILS?: string,
	FAILURE_TIME?: GraphQLTypes["timestamp"],
	FAILURE_TYPE?: string,
	KEY?: string,
	KEY_TYPE?: string,
	TABLE_NAME?: string,
	TABLE_OWNER?: string,
	TASK_NAME?: string
};
	/** response of any mutation on the table "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_mutation_response"]: {
	__typename: "awsdms_validation_failures_v1_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["awsdms_validation_failures_v1"]>
};
	/** Ordering options when selecting data from "awsdms_validation_failures_v1". */
["awsdms_validation_failures_v1_order_by"]: {
		DETAILS?: GraphQLTypes["order_by"],
	FAILURE_TIME?: GraphQLTypes["order_by"],
	FAILURE_TYPE?: GraphQLTypes["order_by"],
	KEY?: GraphQLTypes["order_by"],
	KEY_TYPE?: GraphQLTypes["order_by"],
	TABLE_NAME?: GraphQLTypes["order_by"],
	TABLE_OWNER?: GraphQLTypes["order_by"],
	TASK_NAME?: GraphQLTypes["order_by"]
};
	/** select columns of table "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_select_column"]: awsdms_validation_failures_v1_select_column;
	/** input type for updating data in table "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_set_input"]: {
		DETAILS?: string,
	FAILURE_TIME?: GraphQLTypes["timestamp"],
	FAILURE_TYPE?: string,
	KEY?: string,
	KEY_TYPE?: string,
	TABLE_NAME?: string,
	TABLE_OWNER?: string,
	TASK_NAME?: string
};
	/** Streaming cursor of the table "awsdms_validation_failures_v1" */
["awsdms_validation_failures_v1_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["awsdms_validation_failures_v1_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["awsdms_validation_failures_v1_stream_cursor_value_input"]: {
		DETAILS?: string,
	FAILURE_TIME?: GraphQLTypes["timestamp"],
	FAILURE_TYPE?: string,
	KEY?: string,
	KEY_TYPE?: string,
	TABLE_NAME?: string,
	TABLE_OWNER?: string,
	TASK_NAME?: string
};
	["awsdms_validation_failures_v1_updates"]: {
		/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["awsdms_validation_failures_v1_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["awsdms_validation_failures_v1_bool_exp"]
};
	["bigint"]:any;
	/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
["bigint_comparison_exp"]: {
		_eq?: GraphQLTypes["bigint"],
	_gt?: GraphQLTypes["bigint"],
	_gte?: GraphQLTypes["bigint"],
	_in?: Array<GraphQLTypes["bigint"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["bigint"],
	_lte?: GraphQLTypes["bigint"],
	_neq?: GraphQLTypes["bigint"],
	_nin?: Array<GraphQLTypes["bigint"]>
};
	["bit"]:any;
	/** Boolean expression to compare columns of type "bit". All fields are combined with logical 'AND'. */
["bit_comparison_exp"]: {
		_eq?: GraphQLTypes["bit"],
	_gt?: GraphQLTypes["bit"],
	_gte?: GraphQLTypes["bit"],
	_in?: Array<GraphQLTypes["bit"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["bit"],
	_lte?: GraphQLTypes["bit"],
	_neq?: GraphQLTypes["bit"],
	_nin?: Array<GraphQLTypes["bit"]>
};
	["bpchar"]:any;
	/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
["bpchar_comparison_exp"]: {
		_eq?: GraphQLTypes["bpchar"],
	_gt?: GraphQLTypes["bpchar"],
	_gte?: GraphQLTypes["bpchar"],
	/** does the column match the given case-insensitive pattern */
	_ilike?: GraphQLTypes["bpchar"],
	_in?: Array<GraphQLTypes["bpchar"]>,
	/** does the column match the given POSIX regular expression, case insensitive */
	_iregex?: GraphQLTypes["bpchar"],
	_is_null?: boolean,
	/** does the column match the given pattern */
	_like?: GraphQLTypes["bpchar"],
	_lt?: GraphQLTypes["bpchar"],
	_lte?: GraphQLTypes["bpchar"],
	_neq?: GraphQLTypes["bpchar"],
	/** does the column NOT match the given case-insensitive pattern */
	_nilike?: GraphQLTypes["bpchar"],
	_nin?: Array<GraphQLTypes["bpchar"]>,
	/** does the column NOT match the given POSIX regular expression, case insensitive */
	_niregex?: GraphQLTypes["bpchar"],
	/** does the column NOT match the given pattern */
	_nlike?: GraphQLTypes["bpchar"],
	/** does the column NOT match the given POSIX regular expression, case sensitive */
	_nregex?: GraphQLTypes["bpchar"],
	/** does the column NOT match the given SQL regular expression */
	_nsimilar?: GraphQLTypes["bpchar"],
	/** does the column match the given POSIX regular expression, case sensitive */
	_regex?: GraphQLTypes["bpchar"],
	/** does the column match the given SQL regular expression */
	_similar?: GraphQLTypes["bpchar"]
};
	["bytea"]:any;
	/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
["bytea_comparison_exp"]: {
		_eq?: GraphQLTypes["bytea"],
	_gt?: GraphQLTypes["bytea"],
	_gte?: GraphQLTypes["bytea"],
	_in?: Array<GraphQLTypes["bytea"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["bytea"],
	_lte?: GraphQLTypes["bytea"],
	_neq?: GraphQLTypes["bytea"],
	_nin?: Array<GraphQLTypes["bytea"]>
};
	/** columns and relationships of "coinsystem.balances" */
["coinsystem_balances"]: {
	__typename: "coinsystem_balances",
	balance: GraphQLTypes["numeric"],
	/** An object relationship */
	coin: GraphQLTypes["coinsystem_coins"],
	coin_id: number,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	updated_at: GraphQLTypes["timestamptz"],
	/** An object relationship */
	user?: GraphQLTypes["voyce_users"],
	user_id?: number,
	/** An object relationship */
	wallet: GraphQLTypes["coinsystem_wallets"],
	wallet_id: number
};
	/** aggregated selection of "coinsystem.balances" */
["coinsystem_balances_aggregate"]: {
	__typename: "coinsystem_balances_aggregate",
	aggregate?: GraphQLTypes["coinsystem_balances_aggregate_fields"],
	nodes: Array<GraphQLTypes["coinsystem_balances"]>
};
	/** aggregate fields of "coinsystem.balances" */
["coinsystem_balances_aggregate_fields"]: {
	__typename: "coinsystem_balances_aggregate_fields",
	avg?: GraphQLTypes["coinsystem_balances_avg_fields"],
	count: number,
	max?: GraphQLTypes["coinsystem_balances_max_fields"],
	min?: GraphQLTypes["coinsystem_balances_min_fields"],
	stddev?: GraphQLTypes["coinsystem_balances_stddev_fields"],
	stddev_pop?: GraphQLTypes["coinsystem_balances_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["coinsystem_balances_stddev_samp_fields"],
	sum?: GraphQLTypes["coinsystem_balances_sum_fields"],
	var_pop?: GraphQLTypes["coinsystem_balances_var_pop_fields"],
	var_samp?: GraphQLTypes["coinsystem_balances_var_samp_fields"],
	variance?: GraphQLTypes["coinsystem_balances_variance_fields"]
};
	/** aggregate avg on columns */
["coinsystem_balances_avg_fields"]: {
	__typename: "coinsystem_balances_avg_fields",
	balance?: number,
	coin_id?: number,
	id?: number,
	user_id?: number,
	wallet_id?: number
};
	/** Boolean expression to filter rows from the table "coinsystem.balances". All fields are combined with a logical 'AND'. */
["coinsystem_balances_bool_exp"]: {
		_and?: Array<GraphQLTypes["coinsystem_balances_bool_exp"]>,
	_not?: GraphQLTypes["coinsystem_balances_bool_exp"],
	_or?: Array<GraphQLTypes["coinsystem_balances_bool_exp"]>,
	balance?: GraphQLTypes["numeric_comparison_exp"],
	coin?: GraphQLTypes["coinsystem_coins_bool_exp"],
	coin_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	wallet?: GraphQLTypes["coinsystem_wallets_bool_exp"],
	wallet_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "coinsystem.balances" */
["coinsystem_balances_constraint"]: coinsystem_balances_constraint;
	/** input type for incrementing numeric columns in table "coinsystem.balances" */
["coinsystem_balances_inc_input"]: {
		balance?: GraphQLTypes["numeric"],
	coin_id?: number,
	id?: number,
	user_id?: number,
	wallet_id?: number
};
	/** input type for inserting data into table "coinsystem.balances" */
["coinsystem_balances_insert_input"]: {
		balance?: GraphQLTypes["numeric"],
	coin?: GraphQLTypes["coinsystem_coins_obj_rel_insert_input"],
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number,
	wallet?: GraphQLTypes["coinsystem_wallets_obj_rel_insert_input"],
	wallet_id?: number
};
	/** aggregate max on columns */
["coinsystem_balances_max_fields"]: {
	__typename: "coinsystem_balances_max_fields",
	balance?: GraphQLTypes["numeric"],
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number,
	wallet_id?: number
};
	/** aggregate min on columns */
["coinsystem_balances_min_fields"]: {
	__typename: "coinsystem_balances_min_fields",
	balance?: GraphQLTypes["numeric"],
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number,
	wallet_id?: number
};
	/** response of any mutation on the table "coinsystem.balances" */
["coinsystem_balances_mutation_response"]: {
	__typename: "coinsystem_balances_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["coinsystem_balances"]>
};
	/** on_conflict condition type for table "coinsystem.balances" */
["coinsystem_balances_on_conflict"]: {
		constraint: GraphQLTypes["coinsystem_balances_constraint"],
	update_columns: Array<GraphQLTypes["coinsystem_balances_update_column"]>,
	where?: GraphQLTypes["coinsystem_balances_bool_exp"]
};
	/** Ordering options when selecting data from "coinsystem.balances". */
["coinsystem_balances_order_by"]: {
		balance?: GraphQLTypes["order_by"],
	coin?: GraphQLTypes["coinsystem_coins_order_by"],
	coin_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"],
	wallet?: GraphQLTypes["coinsystem_wallets_order_by"],
	wallet_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: coinsystem.balances */
["coinsystem_balances_pk_columns_input"]: {
		id: number
};
	/** select columns of table "coinsystem.balances" */
["coinsystem_balances_select_column"]: coinsystem_balances_select_column;
	/** input type for updating data in table "coinsystem.balances" */
["coinsystem_balances_set_input"]: {
		balance?: GraphQLTypes["numeric"],
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number,
	wallet_id?: number
};
	/** aggregate stddev on columns */
["coinsystem_balances_stddev_fields"]: {
	__typename: "coinsystem_balances_stddev_fields",
	balance?: number,
	coin_id?: number,
	id?: number,
	user_id?: number,
	wallet_id?: number
};
	/** aggregate stddev_pop on columns */
["coinsystem_balances_stddev_pop_fields"]: {
	__typename: "coinsystem_balances_stddev_pop_fields",
	balance?: number,
	coin_id?: number,
	id?: number,
	user_id?: number,
	wallet_id?: number
};
	/** aggregate stddev_samp on columns */
["coinsystem_balances_stddev_samp_fields"]: {
	__typename: "coinsystem_balances_stddev_samp_fields",
	balance?: number,
	coin_id?: number,
	id?: number,
	user_id?: number,
	wallet_id?: number
};
	/** Streaming cursor of the table "coinsystem_balances" */
["coinsystem_balances_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["coinsystem_balances_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["coinsystem_balances_stream_cursor_value_input"]: {
		balance?: GraphQLTypes["numeric"],
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number,
	wallet_id?: number
};
	/** aggregate sum on columns */
["coinsystem_balances_sum_fields"]: {
	__typename: "coinsystem_balances_sum_fields",
	balance?: GraphQLTypes["numeric"],
	coin_id?: number,
	id?: number,
	user_id?: number,
	wallet_id?: number
};
	/** update columns of table "coinsystem.balances" */
["coinsystem_balances_update_column"]: coinsystem_balances_update_column;
	["coinsystem_balances_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["coinsystem_balances_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["coinsystem_balances_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["coinsystem_balances_bool_exp"]
};
	/** aggregate var_pop on columns */
["coinsystem_balances_var_pop_fields"]: {
	__typename: "coinsystem_balances_var_pop_fields",
	balance?: number,
	coin_id?: number,
	id?: number,
	user_id?: number,
	wallet_id?: number
};
	/** aggregate var_samp on columns */
["coinsystem_balances_var_samp_fields"]: {
	__typename: "coinsystem_balances_var_samp_fields",
	balance?: number,
	coin_id?: number,
	id?: number,
	user_id?: number,
	wallet_id?: number
};
	/** aggregate variance on columns */
["coinsystem_balances_variance_fields"]: {
	__typename: "coinsystem_balances_variance_fields",
	balance?: number,
	coin_id?: number,
	id?: number,
	user_id?: number,
	wallet_id?: number
};
	/** columns and relationships of "coinsystem.bonus_coins" */
["coinsystem_bonus_coins"]: {
	__typename: "coinsystem_bonus_coins",
	active: boolean,
	bonus_amount: GraphQLTypes["numeric"],
	coin_id: number,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	max_amount: GraphQLTypes["numeric"],
	min_amount: GraphQLTypes["numeric"]
};
	/** aggregated selection of "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_aggregate"]: {
	__typename: "coinsystem_bonus_coins_aggregate",
	aggregate?: GraphQLTypes["coinsystem_bonus_coins_aggregate_fields"],
	nodes: Array<GraphQLTypes["coinsystem_bonus_coins"]>
};
	/** aggregate fields of "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_aggregate_fields"]: {
	__typename: "coinsystem_bonus_coins_aggregate_fields",
	avg?: GraphQLTypes["coinsystem_bonus_coins_avg_fields"],
	count: number,
	max?: GraphQLTypes["coinsystem_bonus_coins_max_fields"],
	min?: GraphQLTypes["coinsystem_bonus_coins_min_fields"],
	stddev?: GraphQLTypes["coinsystem_bonus_coins_stddev_fields"],
	stddev_pop?: GraphQLTypes["coinsystem_bonus_coins_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["coinsystem_bonus_coins_stddev_samp_fields"],
	sum?: GraphQLTypes["coinsystem_bonus_coins_sum_fields"],
	var_pop?: GraphQLTypes["coinsystem_bonus_coins_var_pop_fields"],
	var_samp?: GraphQLTypes["coinsystem_bonus_coins_var_samp_fields"],
	variance?: GraphQLTypes["coinsystem_bonus_coins_variance_fields"]
};
	/** aggregate avg on columns */
["coinsystem_bonus_coins_avg_fields"]: {
	__typename: "coinsystem_bonus_coins_avg_fields",
	bonus_amount?: number,
	coin_id?: number,
	id?: number,
	max_amount?: number,
	min_amount?: number
};
	/** Boolean expression to filter rows from the table "coinsystem.bonus_coins". All fields are combined with a logical 'AND'. */
["coinsystem_bonus_coins_bool_exp"]: {
		_and?: Array<GraphQLTypes["coinsystem_bonus_coins_bool_exp"]>,
	_not?: GraphQLTypes["coinsystem_bonus_coins_bool_exp"],
	_or?: Array<GraphQLTypes["coinsystem_bonus_coins_bool_exp"]>,
	active?: GraphQLTypes["Boolean_comparison_exp"],
	bonus_amount?: GraphQLTypes["numeric_comparison_exp"],
	coin_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	max_amount?: GraphQLTypes["numeric_comparison_exp"],
	min_amount?: GraphQLTypes["numeric_comparison_exp"]
};
	/** unique or primary key constraints on table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_constraint"]: coinsystem_bonus_coins_constraint;
	/** input type for incrementing numeric columns in table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_inc_input"]: {
		bonus_amount?: GraphQLTypes["numeric"],
	coin_id?: number,
	id?: number,
	max_amount?: GraphQLTypes["numeric"],
	min_amount?: GraphQLTypes["numeric"]
};
	/** input type for inserting data into table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_insert_input"]: {
		active?: boolean,
	bonus_amount?: GraphQLTypes["numeric"],
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	max_amount?: GraphQLTypes["numeric"],
	min_amount?: GraphQLTypes["numeric"]
};
	/** aggregate max on columns */
["coinsystem_bonus_coins_max_fields"]: {
	__typename: "coinsystem_bonus_coins_max_fields",
	bonus_amount?: GraphQLTypes["numeric"],
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	max_amount?: GraphQLTypes["numeric"],
	min_amount?: GraphQLTypes["numeric"]
};
	/** aggregate min on columns */
["coinsystem_bonus_coins_min_fields"]: {
	__typename: "coinsystem_bonus_coins_min_fields",
	bonus_amount?: GraphQLTypes["numeric"],
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	max_amount?: GraphQLTypes["numeric"],
	min_amount?: GraphQLTypes["numeric"]
};
	/** response of any mutation on the table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_mutation_response"]: {
	__typename: "coinsystem_bonus_coins_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["coinsystem_bonus_coins"]>
};
	/** on_conflict condition type for table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_on_conflict"]: {
		constraint: GraphQLTypes["coinsystem_bonus_coins_constraint"],
	update_columns: Array<GraphQLTypes["coinsystem_bonus_coins_update_column"]>,
	where?: GraphQLTypes["coinsystem_bonus_coins_bool_exp"]
};
	/** Ordering options when selecting data from "coinsystem.bonus_coins". */
["coinsystem_bonus_coins_order_by"]: {
		active?: GraphQLTypes["order_by"],
	bonus_amount?: GraphQLTypes["order_by"],
	coin_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	max_amount?: GraphQLTypes["order_by"],
	min_amount?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: coinsystem.bonus_coins */
["coinsystem_bonus_coins_pk_columns_input"]: {
		id: number
};
	/** select columns of table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_select_column"]: coinsystem_bonus_coins_select_column;
	/** input type for updating data in table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_set_input"]: {
		active?: boolean,
	bonus_amount?: GraphQLTypes["numeric"],
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	max_amount?: GraphQLTypes["numeric"],
	min_amount?: GraphQLTypes["numeric"]
};
	/** aggregate stddev on columns */
["coinsystem_bonus_coins_stddev_fields"]: {
	__typename: "coinsystem_bonus_coins_stddev_fields",
	bonus_amount?: number,
	coin_id?: number,
	id?: number,
	max_amount?: number,
	min_amount?: number
};
	/** aggregate stddev_pop on columns */
["coinsystem_bonus_coins_stddev_pop_fields"]: {
	__typename: "coinsystem_bonus_coins_stddev_pop_fields",
	bonus_amount?: number,
	coin_id?: number,
	id?: number,
	max_amount?: number,
	min_amount?: number
};
	/** aggregate stddev_samp on columns */
["coinsystem_bonus_coins_stddev_samp_fields"]: {
	__typename: "coinsystem_bonus_coins_stddev_samp_fields",
	bonus_amount?: number,
	coin_id?: number,
	id?: number,
	max_amount?: number,
	min_amount?: number
};
	/** Streaming cursor of the table "coinsystem_bonus_coins" */
["coinsystem_bonus_coins_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["coinsystem_bonus_coins_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["coinsystem_bonus_coins_stream_cursor_value_input"]: {
		active?: boolean,
	bonus_amount?: GraphQLTypes["numeric"],
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	max_amount?: GraphQLTypes["numeric"],
	min_amount?: GraphQLTypes["numeric"]
};
	/** aggregate sum on columns */
["coinsystem_bonus_coins_sum_fields"]: {
	__typename: "coinsystem_bonus_coins_sum_fields",
	bonus_amount?: GraphQLTypes["numeric"],
	coin_id?: number,
	id?: number,
	max_amount?: GraphQLTypes["numeric"],
	min_amount?: GraphQLTypes["numeric"]
};
	/** update columns of table "coinsystem.bonus_coins" */
["coinsystem_bonus_coins_update_column"]: coinsystem_bonus_coins_update_column;
	["coinsystem_bonus_coins_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["coinsystem_bonus_coins_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["coinsystem_bonus_coins_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["coinsystem_bonus_coins_bool_exp"]
};
	/** aggregate var_pop on columns */
["coinsystem_bonus_coins_var_pop_fields"]: {
	__typename: "coinsystem_bonus_coins_var_pop_fields",
	bonus_amount?: number,
	coin_id?: number,
	id?: number,
	max_amount?: number,
	min_amount?: number
};
	/** aggregate var_samp on columns */
["coinsystem_bonus_coins_var_samp_fields"]: {
	__typename: "coinsystem_bonus_coins_var_samp_fields",
	bonus_amount?: number,
	coin_id?: number,
	id?: number,
	max_amount?: number,
	min_amount?: number
};
	/** aggregate variance on columns */
["coinsystem_bonus_coins_variance_fields"]: {
	__typename: "coinsystem_bonus_coins_variance_fields",
	bonus_amount?: number,
	coin_id?: number,
	id?: number,
	max_amount?: number,
	min_amount?: number
};
	/** columns and relationships of "coinsystem.coins" */
["coinsystem_coins"]: {
	__typename: "coinsystem_coins",
	can_mint: boolean,
	coin_decimal: GraphQLTypes["smallint"],
	coin_logo: string,
	coin_name: string,
	coin_symbol: string,
	created_at: GraphQLTypes["timestamptz"],
	default: boolean,
	id: number,
	in_app: boolean,
	is_withdrawable: boolean,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "coinsystem.coins" */
["coinsystem_coins_aggregate"]: {
	__typename: "coinsystem_coins_aggregate",
	aggregate?: GraphQLTypes["coinsystem_coins_aggregate_fields"],
	nodes: Array<GraphQLTypes["coinsystem_coins"]>
};
	/** aggregate fields of "coinsystem.coins" */
["coinsystem_coins_aggregate_fields"]: {
	__typename: "coinsystem_coins_aggregate_fields",
	avg?: GraphQLTypes["coinsystem_coins_avg_fields"],
	count: number,
	max?: GraphQLTypes["coinsystem_coins_max_fields"],
	min?: GraphQLTypes["coinsystem_coins_min_fields"],
	stddev?: GraphQLTypes["coinsystem_coins_stddev_fields"],
	stddev_pop?: GraphQLTypes["coinsystem_coins_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["coinsystem_coins_stddev_samp_fields"],
	sum?: GraphQLTypes["coinsystem_coins_sum_fields"],
	var_pop?: GraphQLTypes["coinsystem_coins_var_pop_fields"],
	var_samp?: GraphQLTypes["coinsystem_coins_var_samp_fields"],
	variance?: GraphQLTypes["coinsystem_coins_variance_fields"]
};
	/** aggregate avg on columns */
["coinsystem_coins_avg_fields"]: {
	__typename: "coinsystem_coins_avg_fields",
	coin_decimal?: number,
	id?: number
};
	/** Boolean expression to filter rows from the table "coinsystem.coins". All fields are combined with a logical 'AND'. */
["coinsystem_coins_bool_exp"]: {
		_and?: Array<GraphQLTypes["coinsystem_coins_bool_exp"]>,
	_not?: GraphQLTypes["coinsystem_coins_bool_exp"],
	_or?: Array<GraphQLTypes["coinsystem_coins_bool_exp"]>,
	can_mint?: GraphQLTypes["Boolean_comparison_exp"],
	coin_decimal?: GraphQLTypes["smallint_comparison_exp"],
	coin_logo?: GraphQLTypes["String_comparison_exp"],
	coin_name?: GraphQLTypes["String_comparison_exp"],
	coin_symbol?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	default?: GraphQLTypes["Boolean_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	in_app?: GraphQLTypes["Boolean_comparison_exp"],
	is_withdrawable?: GraphQLTypes["Boolean_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "coinsystem.coins" */
["coinsystem_coins_constraint"]: coinsystem_coins_constraint;
	/** input type for incrementing numeric columns in table "coinsystem.coins" */
["coinsystem_coins_inc_input"]: {
		coin_decimal?: GraphQLTypes["smallint"],
	id?: number
};
	/** input type for inserting data into table "coinsystem.coins" */
["coinsystem_coins_insert_input"]: {
		can_mint?: boolean,
	coin_decimal?: GraphQLTypes["smallint"],
	coin_logo?: string,
	coin_name?: string,
	coin_symbol?: string,
	created_at?: GraphQLTypes["timestamptz"],
	default?: boolean,
	id?: number,
	in_app?: boolean,
	is_withdrawable?: boolean,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["coinsystem_coins_max_fields"]: {
	__typename: "coinsystem_coins_max_fields",
	coin_decimal?: GraphQLTypes["smallint"],
	coin_logo?: string,
	coin_name?: string,
	coin_symbol?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["coinsystem_coins_min_fields"]: {
	__typename: "coinsystem_coins_min_fields",
	coin_decimal?: GraphQLTypes["smallint"],
	coin_logo?: string,
	coin_name?: string,
	coin_symbol?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "coinsystem.coins" */
["coinsystem_coins_mutation_response"]: {
	__typename: "coinsystem_coins_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["coinsystem_coins"]>
};
	/** input type for inserting object relation for remote table "coinsystem.coins" */
["coinsystem_coins_obj_rel_insert_input"]: {
		data: GraphQLTypes["coinsystem_coins_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["coinsystem_coins_on_conflict"]
};
	/** on_conflict condition type for table "coinsystem.coins" */
["coinsystem_coins_on_conflict"]: {
		constraint: GraphQLTypes["coinsystem_coins_constraint"],
	update_columns: Array<GraphQLTypes["coinsystem_coins_update_column"]>,
	where?: GraphQLTypes["coinsystem_coins_bool_exp"]
};
	/** Ordering options when selecting data from "coinsystem.coins". */
["coinsystem_coins_order_by"]: {
		can_mint?: GraphQLTypes["order_by"],
	coin_decimal?: GraphQLTypes["order_by"],
	coin_logo?: GraphQLTypes["order_by"],
	coin_name?: GraphQLTypes["order_by"],
	coin_symbol?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	default?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	in_app?: GraphQLTypes["order_by"],
	is_withdrawable?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: coinsystem.coins */
["coinsystem_coins_pk_columns_input"]: {
		id: number
};
	/** select columns of table "coinsystem.coins" */
["coinsystem_coins_select_column"]: coinsystem_coins_select_column;
	/** input type for updating data in table "coinsystem.coins" */
["coinsystem_coins_set_input"]: {
		can_mint?: boolean,
	coin_decimal?: GraphQLTypes["smallint"],
	coin_logo?: string,
	coin_name?: string,
	coin_symbol?: string,
	created_at?: GraphQLTypes["timestamptz"],
	default?: boolean,
	id?: number,
	in_app?: boolean,
	is_withdrawable?: boolean,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["coinsystem_coins_stddev_fields"]: {
	__typename: "coinsystem_coins_stddev_fields",
	coin_decimal?: number,
	id?: number
};
	/** aggregate stddev_pop on columns */
["coinsystem_coins_stddev_pop_fields"]: {
	__typename: "coinsystem_coins_stddev_pop_fields",
	coin_decimal?: number,
	id?: number
};
	/** aggregate stddev_samp on columns */
["coinsystem_coins_stddev_samp_fields"]: {
	__typename: "coinsystem_coins_stddev_samp_fields",
	coin_decimal?: number,
	id?: number
};
	/** Streaming cursor of the table "coinsystem_coins" */
["coinsystem_coins_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["coinsystem_coins_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["coinsystem_coins_stream_cursor_value_input"]: {
		can_mint?: boolean,
	coin_decimal?: GraphQLTypes["smallint"],
	coin_logo?: string,
	coin_name?: string,
	coin_symbol?: string,
	created_at?: GraphQLTypes["timestamptz"],
	default?: boolean,
	id?: number,
	in_app?: boolean,
	is_withdrawable?: boolean,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["coinsystem_coins_sum_fields"]: {
	__typename: "coinsystem_coins_sum_fields",
	coin_decimal?: GraphQLTypes["smallint"],
	id?: number
};
	/** update columns of table "coinsystem.coins" */
["coinsystem_coins_update_column"]: coinsystem_coins_update_column;
	["coinsystem_coins_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["coinsystem_coins_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["coinsystem_coins_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["coinsystem_coins_bool_exp"]
};
	/** aggregate var_pop on columns */
["coinsystem_coins_var_pop_fields"]: {
	__typename: "coinsystem_coins_var_pop_fields",
	coin_decimal?: number,
	id?: number
};
	/** aggregate var_samp on columns */
["coinsystem_coins_var_samp_fields"]: {
	__typename: "coinsystem_coins_var_samp_fields",
	coin_decimal?: number,
	id?: number
};
	/** aggregate variance on columns */
["coinsystem_coins_variance_fields"]: {
	__typename: "coinsystem_coins_variance_fields",
	coin_decimal?: number,
	id?: number
};
	/** columns and relationships of "coinsystem.exchange_rates" */
["coinsystem_exchange_rates"]: {
	__typename: "coinsystem_exchange_rates",
	buy_rate: GraphQLTypes["numeric"],
	created_at: GraphQLTypes["timestamptz"],
	destination_coin_id: number,
	id: number,
	sell_rate: GraphQLTypes["numeric"],
	source_coin_id: number
};
	/** aggregated selection of "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_aggregate"]: {
	__typename: "coinsystem_exchange_rates_aggregate",
	aggregate?: GraphQLTypes["coinsystem_exchange_rates_aggregate_fields"],
	nodes: Array<GraphQLTypes["coinsystem_exchange_rates"]>
};
	/** aggregate fields of "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_aggregate_fields"]: {
	__typename: "coinsystem_exchange_rates_aggregate_fields",
	avg?: GraphQLTypes["coinsystem_exchange_rates_avg_fields"],
	count: number,
	max?: GraphQLTypes["coinsystem_exchange_rates_max_fields"],
	min?: GraphQLTypes["coinsystem_exchange_rates_min_fields"],
	stddev?: GraphQLTypes["coinsystem_exchange_rates_stddev_fields"],
	stddev_pop?: GraphQLTypes["coinsystem_exchange_rates_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["coinsystem_exchange_rates_stddev_samp_fields"],
	sum?: GraphQLTypes["coinsystem_exchange_rates_sum_fields"],
	var_pop?: GraphQLTypes["coinsystem_exchange_rates_var_pop_fields"],
	var_samp?: GraphQLTypes["coinsystem_exchange_rates_var_samp_fields"],
	variance?: GraphQLTypes["coinsystem_exchange_rates_variance_fields"]
};
	/** aggregate avg on columns */
["coinsystem_exchange_rates_avg_fields"]: {
	__typename: "coinsystem_exchange_rates_avg_fields",
	buy_rate?: number,
	destination_coin_id?: number,
	id?: number,
	sell_rate?: number,
	source_coin_id?: number
};
	/** Boolean expression to filter rows from the table "coinsystem.exchange_rates". All fields are combined with a logical 'AND'. */
["coinsystem_exchange_rates_bool_exp"]: {
		_and?: Array<GraphQLTypes["coinsystem_exchange_rates_bool_exp"]>,
	_not?: GraphQLTypes["coinsystem_exchange_rates_bool_exp"],
	_or?: Array<GraphQLTypes["coinsystem_exchange_rates_bool_exp"]>,
	buy_rate?: GraphQLTypes["numeric_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	destination_coin_id?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	sell_rate?: GraphQLTypes["numeric_comparison_exp"],
	source_coin_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_constraint"]: coinsystem_exchange_rates_constraint;
	/** input type for incrementing numeric columns in table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_inc_input"]: {
		buy_rate?: GraphQLTypes["numeric"],
	destination_coin_id?: number,
	id?: number,
	sell_rate?: GraphQLTypes["numeric"],
	source_coin_id?: number
};
	/** input type for inserting data into table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_insert_input"]: {
		buy_rate?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	destination_coin_id?: number,
	id?: number,
	sell_rate?: GraphQLTypes["numeric"],
	source_coin_id?: number
};
	/** aggregate max on columns */
["coinsystem_exchange_rates_max_fields"]: {
	__typename: "coinsystem_exchange_rates_max_fields",
	buy_rate?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	destination_coin_id?: number,
	id?: number,
	sell_rate?: GraphQLTypes["numeric"],
	source_coin_id?: number
};
	/** aggregate min on columns */
["coinsystem_exchange_rates_min_fields"]: {
	__typename: "coinsystem_exchange_rates_min_fields",
	buy_rate?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	destination_coin_id?: number,
	id?: number,
	sell_rate?: GraphQLTypes["numeric"],
	source_coin_id?: number
};
	/** response of any mutation on the table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_mutation_response"]: {
	__typename: "coinsystem_exchange_rates_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["coinsystem_exchange_rates"]>
};
	/** on_conflict condition type for table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_on_conflict"]: {
		constraint: GraphQLTypes["coinsystem_exchange_rates_constraint"],
	update_columns: Array<GraphQLTypes["coinsystem_exchange_rates_update_column"]>,
	where?: GraphQLTypes["coinsystem_exchange_rates_bool_exp"]
};
	/** Ordering options when selecting data from "coinsystem.exchange_rates". */
["coinsystem_exchange_rates_order_by"]: {
		buy_rate?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	destination_coin_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	sell_rate?: GraphQLTypes["order_by"],
	source_coin_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: coinsystem.exchange_rates */
["coinsystem_exchange_rates_pk_columns_input"]: {
		id: number
};
	/** select columns of table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_select_column"]: coinsystem_exchange_rates_select_column;
	/** input type for updating data in table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_set_input"]: {
		buy_rate?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	destination_coin_id?: number,
	id?: number,
	sell_rate?: GraphQLTypes["numeric"],
	source_coin_id?: number
};
	/** aggregate stddev on columns */
["coinsystem_exchange_rates_stddev_fields"]: {
	__typename: "coinsystem_exchange_rates_stddev_fields",
	buy_rate?: number,
	destination_coin_id?: number,
	id?: number,
	sell_rate?: number,
	source_coin_id?: number
};
	/** aggregate stddev_pop on columns */
["coinsystem_exchange_rates_stddev_pop_fields"]: {
	__typename: "coinsystem_exchange_rates_stddev_pop_fields",
	buy_rate?: number,
	destination_coin_id?: number,
	id?: number,
	sell_rate?: number,
	source_coin_id?: number
};
	/** aggregate stddev_samp on columns */
["coinsystem_exchange_rates_stddev_samp_fields"]: {
	__typename: "coinsystem_exchange_rates_stddev_samp_fields",
	buy_rate?: number,
	destination_coin_id?: number,
	id?: number,
	sell_rate?: number,
	source_coin_id?: number
};
	/** Streaming cursor of the table "coinsystem_exchange_rates" */
["coinsystem_exchange_rates_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["coinsystem_exchange_rates_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["coinsystem_exchange_rates_stream_cursor_value_input"]: {
		buy_rate?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	destination_coin_id?: number,
	id?: number,
	sell_rate?: GraphQLTypes["numeric"],
	source_coin_id?: number
};
	/** aggregate sum on columns */
["coinsystem_exchange_rates_sum_fields"]: {
	__typename: "coinsystem_exchange_rates_sum_fields",
	buy_rate?: GraphQLTypes["numeric"],
	destination_coin_id?: number,
	id?: number,
	sell_rate?: GraphQLTypes["numeric"],
	source_coin_id?: number
};
	/** update columns of table "coinsystem.exchange_rates" */
["coinsystem_exchange_rates_update_column"]: coinsystem_exchange_rates_update_column;
	["coinsystem_exchange_rates_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["coinsystem_exchange_rates_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["coinsystem_exchange_rates_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["coinsystem_exchange_rates_bool_exp"]
};
	/** aggregate var_pop on columns */
["coinsystem_exchange_rates_var_pop_fields"]: {
	__typename: "coinsystem_exchange_rates_var_pop_fields",
	buy_rate?: number,
	destination_coin_id?: number,
	id?: number,
	sell_rate?: number,
	source_coin_id?: number
};
	/** aggregate var_samp on columns */
["coinsystem_exchange_rates_var_samp_fields"]: {
	__typename: "coinsystem_exchange_rates_var_samp_fields",
	buy_rate?: number,
	destination_coin_id?: number,
	id?: number,
	sell_rate?: number,
	source_coin_id?: number
};
	/** aggregate variance on columns */
["coinsystem_exchange_rates_variance_fields"]: {
	__typename: "coinsystem_exchange_rates_variance_fields",
	buy_rate?: number,
	destination_coin_id?: number,
	id?: number,
	sell_rate?: number,
	source_coin_id?: number
};
	/** columns and relationships of "coinsystem.logs" */
["coinsystem_logs"]: {
	__typename: "coinsystem_logs",
	created_at: GraphQLTypes["timestamptz"],
	description: GraphQLTypes["bpchar"],
	id: number,
	transaction_id: string
};
	/** aggregated selection of "coinsystem.logs" */
["coinsystem_logs_aggregate"]: {
	__typename: "coinsystem_logs_aggregate",
	aggregate?: GraphQLTypes["coinsystem_logs_aggregate_fields"],
	nodes: Array<GraphQLTypes["coinsystem_logs"]>
};
	/** aggregate fields of "coinsystem.logs" */
["coinsystem_logs_aggregate_fields"]: {
	__typename: "coinsystem_logs_aggregate_fields",
	avg?: GraphQLTypes["coinsystem_logs_avg_fields"],
	count: number,
	max?: GraphQLTypes["coinsystem_logs_max_fields"],
	min?: GraphQLTypes["coinsystem_logs_min_fields"],
	stddev?: GraphQLTypes["coinsystem_logs_stddev_fields"],
	stddev_pop?: GraphQLTypes["coinsystem_logs_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["coinsystem_logs_stddev_samp_fields"],
	sum?: GraphQLTypes["coinsystem_logs_sum_fields"],
	var_pop?: GraphQLTypes["coinsystem_logs_var_pop_fields"],
	var_samp?: GraphQLTypes["coinsystem_logs_var_samp_fields"],
	variance?: GraphQLTypes["coinsystem_logs_variance_fields"]
};
	/** aggregate avg on columns */
["coinsystem_logs_avg_fields"]: {
	__typename: "coinsystem_logs_avg_fields",
	id?: number
};
	/** Boolean expression to filter rows from the table "coinsystem.logs". All fields are combined with a logical 'AND'. */
["coinsystem_logs_bool_exp"]: {
		_and?: Array<GraphQLTypes["coinsystem_logs_bool_exp"]>,
	_not?: GraphQLTypes["coinsystem_logs_bool_exp"],
	_or?: Array<GraphQLTypes["coinsystem_logs_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	description?: GraphQLTypes["bpchar_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	transaction_id?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "coinsystem.logs" */
["coinsystem_logs_constraint"]: coinsystem_logs_constraint;
	/** input type for incrementing numeric columns in table "coinsystem.logs" */
["coinsystem_logs_inc_input"]: {
		id?: number
};
	/** input type for inserting data into table "coinsystem.logs" */
["coinsystem_logs_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	description?: GraphQLTypes["bpchar"],
	id?: number,
	transaction_id?: string
};
	/** aggregate max on columns */
["coinsystem_logs_max_fields"]: {
	__typename: "coinsystem_logs_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	description?: GraphQLTypes["bpchar"],
	id?: number,
	transaction_id?: string
};
	/** aggregate min on columns */
["coinsystem_logs_min_fields"]: {
	__typename: "coinsystem_logs_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	description?: GraphQLTypes["bpchar"],
	id?: number,
	transaction_id?: string
};
	/** response of any mutation on the table "coinsystem.logs" */
["coinsystem_logs_mutation_response"]: {
	__typename: "coinsystem_logs_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["coinsystem_logs"]>
};
	/** on_conflict condition type for table "coinsystem.logs" */
["coinsystem_logs_on_conflict"]: {
		constraint: GraphQLTypes["coinsystem_logs_constraint"],
	update_columns: Array<GraphQLTypes["coinsystem_logs_update_column"]>,
	where?: GraphQLTypes["coinsystem_logs_bool_exp"]
};
	/** Ordering options when selecting data from "coinsystem.logs". */
["coinsystem_logs_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	transaction_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: coinsystem.logs */
["coinsystem_logs_pk_columns_input"]: {
		id: number
};
	/** select columns of table "coinsystem.logs" */
["coinsystem_logs_select_column"]: coinsystem_logs_select_column;
	/** input type for updating data in table "coinsystem.logs" */
["coinsystem_logs_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	description?: GraphQLTypes["bpchar"],
	id?: number,
	transaction_id?: string
};
	/** aggregate stddev on columns */
["coinsystem_logs_stddev_fields"]: {
	__typename: "coinsystem_logs_stddev_fields",
	id?: number
};
	/** aggregate stddev_pop on columns */
["coinsystem_logs_stddev_pop_fields"]: {
	__typename: "coinsystem_logs_stddev_pop_fields",
	id?: number
};
	/** aggregate stddev_samp on columns */
["coinsystem_logs_stddev_samp_fields"]: {
	__typename: "coinsystem_logs_stddev_samp_fields",
	id?: number
};
	/** Streaming cursor of the table "coinsystem_logs" */
["coinsystem_logs_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["coinsystem_logs_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["coinsystem_logs_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	description?: GraphQLTypes["bpchar"],
	id?: number,
	transaction_id?: string
};
	/** aggregate sum on columns */
["coinsystem_logs_sum_fields"]: {
	__typename: "coinsystem_logs_sum_fields",
	id?: number
};
	/** update columns of table "coinsystem.logs" */
["coinsystem_logs_update_column"]: coinsystem_logs_update_column;
	["coinsystem_logs_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["coinsystem_logs_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["coinsystem_logs_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["coinsystem_logs_bool_exp"]
};
	/** aggregate var_pop on columns */
["coinsystem_logs_var_pop_fields"]: {
	__typename: "coinsystem_logs_var_pop_fields",
	id?: number
};
	/** aggregate var_samp on columns */
["coinsystem_logs_var_samp_fields"]: {
	__typename: "coinsystem_logs_var_samp_fields",
	id?: number
};
	/** aggregate variance on columns */
["coinsystem_logs_variance_fields"]: {
	__typename: "coinsystem_logs_variance_fields",
	id?: number
};
	/** columns and relationships of "coinsystem.transactions" */
["coinsystem_transactions"]: {
	__typename: "coinsystem_transactions",
	coin_amount: GraphQLTypes["numeric"],
	coin_id: number,
	created_at: GraphQLTypes["timestamptz"],
	description: GraphQLTypes["bpchar"],
	id: number,
	recipient_id?: number,
	recipient_wallet_address?: string,
	sender_id?: number,
	sender_wallet_address?: string,
	transaction_id: string,
	transaction_type: string
};
	/** aggregated selection of "coinsystem.transactions" */
["coinsystem_transactions_aggregate"]: {
	__typename: "coinsystem_transactions_aggregate",
	aggregate?: GraphQLTypes["coinsystem_transactions_aggregate_fields"],
	nodes: Array<GraphQLTypes["coinsystem_transactions"]>
};
	/** aggregate fields of "coinsystem.transactions" */
["coinsystem_transactions_aggregate_fields"]: {
	__typename: "coinsystem_transactions_aggregate_fields",
	avg?: GraphQLTypes["coinsystem_transactions_avg_fields"],
	count: number,
	max?: GraphQLTypes["coinsystem_transactions_max_fields"],
	min?: GraphQLTypes["coinsystem_transactions_min_fields"],
	stddev?: GraphQLTypes["coinsystem_transactions_stddev_fields"],
	stddev_pop?: GraphQLTypes["coinsystem_transactions_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["coinsystem_transactions_stddev_samp_fields"],
	sum?: GraphQLTypes["coinsystem_transactions_sum_fields"],
	var_pop?: GraphQLTypes["coinsystem_transactions_var_pop_fields"],
	var_samp?: GraphQLTypes["coinsystem_transactions_var_samp_fields"],
	variance?: GraphQLTypes["coinsystem_transactions_variance_fields"]
};
	/** aggregate avg on columns */
["coinsystem_transactions_avg_fields"]: {
	__typename: "coinsystem_transactions_avg_fields",
	coin_amount?: number,
	coin_id?: number,
	id?: number,
	recipient_id?: number,
	sender_id?: number
};
	/** Boolean expression to filter rows from the table "coinsystem.transactions". All fields are combined with a logical 'AND'. */
["coinsystem_transactions_bool_exp"]: {
		_and?: Array<GraphQLTypes["coinsystem_transactions_bool_exp"]>,
	_not?: GraphQLTypes["coinsystem_transactions_bool_exp"],
	_or?: Array<GraphQLTypes["coinsystem_transactions_bool_exp"]>,
	coin_amount?: GraphQLTypes["numeric_comparison_exp"],
	coin_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	description?: GraphQLTypes["bpchar_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	recipient_id?: GraphQLTypes["Int_comparison_exp"],
	recipient_wallet_address?: GraphQLTypes["String_comparison_exp"],
	sender_id?: GraphQLTypes["Int_comparison_exp"],
	sender_wallet_address?: GraphQLTypes["String_comparison_exp"],
	transaction_id?: GraphQLTypes["String_comparison_exp"],
	transaction_type?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "coinsystem.transactions" */
["coinsystem_transactions_constraint"]: coinsystem_transactions_constraint;
	/** input type for incrementing numeric columns in table "coinsystem.transactions" */
["coinsystem_transactions_inc_input"]: {
		coin_amount?: GraphQLTypes["numeric"],
	coin_id?: number,
	id?: number,
	recipient_id?: number,
	sender_id?: number
};
	/** input type for inserting data into table "coinsystem.transactions" */
["coinsystem_transactions_insert_input"]: {
		coin_amount?: GraphQLTypes["numeric"],
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	description?: GraphQLTypes["bpchar"],
	id?: number,
	recipient_id?: number,
	recipient_wallet_address?: string,
	sender_id?: number,
	sender_wallet_address?: string,
	transaction_id?: string,
	transaction_type?: string
};
	/** aggregate max on columns */
["coinsystem_transactions_max_fields"]: {
	__typename: "coinsystem_transactions_max_fields",
	coin_amount?: GraphQLTypes["numeric"],
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	description?: GraphQLTypes["bpchar"],
	id?: number,
	recipient_id?: number,
	recipient_wallet_address?: string,
	sender_id?: number,
	sender_wallet_address?: string,
	transaction_id?: string,
	transaction_type?: string
};
	/** aggregate min on columns */
["coinsystem_transactions_min_fields"]: {
	__typename: "coinsystem_transactions_min_fields",
	coin_amount?: GraphQLTypes["numeric"],
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	description?: GraphQLTypes["bpchar"],
	id?: number,
	recipient_id?: number,
	recipient_wallet_address?: string,
	sender_id?: number,
	sender_wallet_address?: string,
	transaction_id?: string,
	transaction_type?: string
};
	/** response of any mutation on the table "coinsystem.transactions" */
["coinsystem_transactions_mutation_response"]: {
	__typename: "coinsystem_transactions_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["coinsystem_transactions"]>
};
	/** on_conflict condition type for table "coinsystem.transactions" */
["coinsystem_transactions_on_conflict"]: {
		constraint: GraphQLTypes["coinsystem_transactions_constraint"],
	update_columns: Array<GraphQLTypes["coinsystem_transactions_update_column"]>,
	where?: GraphQLTypes["coinsystem_transactions_bool_exp"]
};
	/** Ordering options when selecting data from "coinsystem.transactions". */
["coinsystem_transactions_order_by"]: {
		coin_amount?: GraphQLTypes["order_by"],
	coin_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	recipient_id?: GraphQLTypes["order_by"],
	recipient_wallet_address?: GraphQLTypes["order_by"],
	sender_id?: GraphQLTypes["order_by"],
	sender_wallet_address?: GraphQLTypes["order_by"],
	transaction_id?: GraphQLTypes["order_by"],
	transaction_type?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: coinsystem.transactions */
["coinsystem_transactions_pk_columns_input"]: {
		id: number
};
	/** select columns of table "coinsystem.transactions" */
["coinsystem_transactions_select_column"]: coinsystem_transactions_select_column;
	/** input type for updating data in table "coinsystem.transactions" */
["coinsystem_transactions_set_input"]: {
		coin_amount?: GraphQLTypes["numeric"],
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	description?: GraphQLTypes["bpchar"],
	id?: number,
	recipient_id?: number,
	recipient_wallet_address?: string,
	sender_id?: number,
	sender_wallet_address?: string,
	transaction_id?: string,
	transaction_type?: string
};
	/** aggregate stddev on columns */
["coinsystem_transactions_stddev_fields"]: {
	__typename: "coinsystem_transactions_stddev_fields",
	coin_amount?: number,
	coin_id?: number,
	id?: number,
	recipient_id?: number,
	sender_id?: number
};
	/** aggregate stddev_pop on columns */
["coinsystem_transactions_stddev_pop_fields"]: {
	__typename: "coinsystem_transactions_stddev_pop_fields",
	coin_amount?: number,
	coin_id?: number,
	id?: number,
	recipient_id?: number,
	sender_id?: number
};
	/** aggregate stddev_samp on columns */
["coinsystem_transactions_stddev_samp_fields"]: {
	__typename: "coinsystem_transactions_stddev_samp_fields",
	coin_amount?: number,
	coin_id?: number,
	id?: number,
	recipient_id?: number,
	sender_id?: number
};
	/** Streaming cursor of the table "coinsystem_transactions" */
["coinsystem_transactions_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["coinsystem_transactions_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["coinsystem_transactions_stream_cursor_value_input"]: {
		coin_amount?: GraphQLTypes["numeric"],
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	description?: GraphQLTypes["bpchar"],
	id?: number,
	recipient_id?: number,
	recipient_wallet_address?: string,
	sender_id?: number,
	sender_wallet_address?: string,
	transaction_id?: string,
	transaction_type?: string
};
	/** aggregate sum on columns */
["coinsystem_transactions_sum_fields"]: {
	__typename: "coinsystem_transactions_sum_fields",
	coin_amount?: GraphQLTypes["numeric"],
	coin_id?: number,
	id?: number,
	recipient_id?: number,
	sender_id?: number
};
	/** update columns of table "coinsystem.transactions" */
["coinsystem_transactions_update_column"]: coinsystem_transactions_update_column;
	["coinsystem_transactions_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["coinsystem_transactions_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["coinsystem_transactions_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["coinsystem_transactions_bool_exp"]
};
	/** aggregate var_pop on columns */
["coinsystem_transactions_var_pop_fields"]: {
	__typename: "coinsystem_transactions_var_pop_fields",
	coin_amount?: number,
	coin_id?: number,
	id?: number,
	recipient_id?: number,
	sender_id?: number
};
	/** aggregate var_samp on columns */
["coinsystem_transactions_var_samp_fields"]: {
	__typename: "coinsystem_transactions_var_samp_fields",
	coin_amount?: number,
	coin_id?: number,
	id?: number,
	recipient_id?: number,
	sender_id?: number
};
	/** aggregate variance on columns */
["coinsystem_transactions_variance_fields"]: {
	__typename: "coinsystem_transactions_variance_fields",
	coin_amount?: number,
	coin_id?: number,
	id?: number,
	recipient_id?: number,
	sender_id?: number
};
	/** columns and relationships of "coinsystem.wallets" */
["coinsystem_wallets"]: {
	__typename: "coinsystem_wallets",
	can_mint: boolean,
	coin_id: number,
	created_at: GraphQLTypes["timestamptz"],
	default: boolean,
	id: number,
	is_vm: boolean,
	tag?: string,
	updated_at: GraphQLTypes["timestamptz"],
	user_id?: number,
	wallet_address: string
};
	/** aggregated selection of "coinsystem.wallets" */
["coinsystem_wallets_aggregate"]: {
	__typename: "coinsystem_wallets_aggregate",
	aggregate?: GraphQLTypes["coinsystem_wallets_aggregate_fields"],
	nodes: Array<GraphQLTypes["coinsystem_wallets"]>
};
	/** aggregate fields of "coinsystem.wallets" */
["coinsystem_wallets_aggregate_fields"]: {
	__typename: "coinsystem_wallets_aggregate_fields",
	avg?: GraphQLTypes["coinsystem_wallets_avg_fields"],
	count: number,
	max?: GraphQLTypes["coinsystem_wallets_max_fields"],
	min?: GraphQLTypes["coinsystem_wallets_min_fields"],
	stddev?: GraphQLTypes["coinsystem_wallets_stddev_fields"],
	stddev_pop?: GraphQLTypes["coinsystem_wallets_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["coinsystem_wallets_stddev_samp_fields"],
	sum?: GraphQLTypes["coinsystem_wallets_sum_fields"],
	var_pop?: GraphQLTypes["coinsystem_wallets_var_pop_fields"],
	var_samp?: GraphQLTypes["coinsystem_wallets_var_samp_fields"],
	variance?: GraphQLTypes["coinsystem_wallets_variance_fields"]
};
	/** aggregate avg on columns */
["coinsystem_wallets_avg_fields"]: {
	__typename: "coinsystem_wallets_avg_fields",
	coin_id?: number,
	id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "coinsystem.wallets". All fields are combined with a logical 'AND'. */
["coinsystem_wallets_bool_exp"]: {
		_and?: Array<GraphQLTypes["coinsystem_wallets_bool_exp"]>,
	_not?: GraphQLTypes["coinsystem_wallets_bool_exp"],
	_or?: Array<GraphQLTypes["coinsystem_wallets_bool_exp"]>,
	can_mint?: GraphQLTypes["Boolean_comparison_exp"],
	coin_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	default?: GraphQLTypes["Boolean_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_vm?: GraphQLTypes["Boolean_comparison_exp"],
	tag?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	wallet_address?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "coinsystem.wallets" */
["coinsystem_wallets_constraint"]: coinsystem_wallets_constraint;
	/** input type for incrementing numeric columns in table "coinsystem.wallets" */
["coinsystem_wallets_inc_input"]: {
		coin_id?: number,
	id?: number,
	user_id?: number
};
	/** input type for inserting data into table "coinsystem.wallets" */
["coinsystem_wallets_insert_input"]: {
		can_mint?: boolean,
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	default?: boolean,
	id?: number,
	is_vm?: boolean,
	tag?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number,
	wallet_address?: string
};
	/** aggregate max on columns */
["coinsystem_wallets_max_fields"]: {
	__typename: "coinsystem_wallets_max_fields",
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	tag?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number,
	wallet_address?: string
};
	/** aggregate min on columns */
["coinsystem_wallets_min_fields"]: {
	__typename: "coinsystem_wallets_min_fields",
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	tag?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number,
	wallet_address?: string
};
	/** response of any mutation on the table "coinsystem.wallets" */
["coinsystem_wallets_mutation_response"]: {
	__typename: "coinsystem_wallets_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["coinsystem_wallets"]>
};
	/** input type for inserting object relation for remote table "coinsystem.wallets" */
["coinsystem_wallets_obj_rel_insert_input"]: {
		data: GraphQLTypes["coinsystem_wallets_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["coinsystem_wallets_on_conflict"]
};
	/** on_conflict condition type for table "coinsystem.wallets" */
["coinsystem_wallets_on_conflict"]: {
		constraint: GraphQLTypes["coinsystem_wallets_constraint"],
	update_columns: Array<GraphQLTypes["coinsystem_wallets_update_column"]>,
	where?: GraphQLTypes["coinsystem_wallets_bool_exp"]
};
	/** Ordering options when selecting data from "coinsystem.wallets". */
["coinsystem_wallets_order_by"]: {
		can_mint?: GraphQLTypes["order_by"],
	coin_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	default?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_vm?: GraphQLTypes["order_by"],
	tag?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	wallet_address?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: coinsystem.wallets */
["coinsystem_wallets_pk_columns_input"]: {
		id: number
};
	/** select columns of table "coinsystem.wallets" */
["coinsystem_wallets_select_column"]: coinsystem_wallets_select_column;
	/** input type for updating data in table "coinsystem.wallets" */
["coinsystem_wallets_set_input"]: {
		can_mint?: boolean,
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	default?: boolean,
	id?: number,
	is_vm?: boolean,
	tag?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number,
	wallet_address?: string
};
	/** aggregate stddev on columns */
["coinsystem_wallets_stddev_fields"]: {
	__typename: "coinsystem_wallets_stddev_fields",
	coin_id?: number,
	id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["coinsystem_wallets_stddev_pop_fields"]: {
	__typename: "coinsystem_wallets_stddev_pop_fields",
	coin_id?: number,
	id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["coinsystem_wallets_stddev_samp_fields"]: {
	__typename: "coinsystem_wallets_stddev_samp_fields",
	coin_id?: number,
	id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "coinsystem_wallets" */
["coinsystem_wallets_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["coinsystem_wallets_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["coinsystem_wallets_stream_cursor_value_input"]: {
		can_mint?: boolean,
	coin_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	default?: boolean,
	id?: number,
	is_vm?: boolean,
	tag?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number,
	wallet_address?: string
};
	/** aggregate sum on columns */
["coinsystem_wallets_sum_fields"]: {
	__typename: "coinsystem_wallets_sum_fields",
	coin_id?: number,
	id?: number,
	user_id?: number
};
	/** update columns of table "coinsystem.wallets" */
["coinsystem_wallets_update_column"]: coinsystem_wallets_update_column;
	["coinsystem_wallets_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["coinsystem_wallets_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["coinsystem_wallets_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["coinsystem_wallets_bool_exp"]
};
	/** aggregate var_pop on columns */
["coinsystem_wallets_var_pop_fields"]: {
	__typename: "coinsystem_wallets_var_pop_fields",
	coin_id?: number,
	id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["coinsystem_wallets_var_samp_fields"]: {
	__typename: "coinsystem_wallets_var_samp_fields",
	coin_id?: number,
	id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["coinsystem_wallets_variance_fields"]: {
	__typename: "coinsystem_wallets_variance_fields",
	coin_id?: number,
	id?: number,
	user_id?: number
};
	/** ordering argument of a cursor */
["cursor_ordering"]: cursor_ordering;
	["date"]:any;
	/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
["date_comparison_exp"]: {
		_eq?: GraphQLTypes["date"],
	_gt?: GraphQLTypes["date"],
	_gte?: GraphQLTypes["date"],
	_in?: Array<GraphQLTypes["date"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["date"],
	_lte?: GraphQLTypes["date"],
	_neq?: GraphQLTypes["date"],
	_nin?: Array<GraphQLTypes["date"]>
};
	["float8"]:any;
	/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
["float8_comparison_exp"]: {
		_eq?: GraphQLTypes["float8"],
	_gt?: GraphQLTypes["float8"],
	_gte?: GraphQLTypes["float8"],
	_in?: Array<GraphQLTypes["float8"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["float8"],
	_lte?: GraphQLTypes["float8"],
	_neq?: GraphQLTypes["float8"],
	_nin?: Array<GraphQLTypes["float8"]>
};
	["json"]:any;
	/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
["json_comparison_exp"]: {
		_eq?: GraphQLTypes["json"],
	_gt?: GraphQLTypes["json"],
	_gte?: GraphQLTypes["json"],
	_in?: Array<GraphQLTypes["json"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["json"],
	_lte?: GraphQLTypes["json"],
	_neq?: GraphQLTypes["json"],
	_nin?: Array<GraphQLTypes["json"]>
};
	["jsonb"]:any;
	["jsonb_cast_exp"]: {
		String?: GraphQLTypes["String_comparison_exp"]
};
	/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
["jsonb_comparison_exp"]: {
		_cast?: GraphQLTypes["jsonb_cast_exp"],
	/** is the column contained in the given json value */
	_contained_in?: GraphQLTypes["jsonb"],
	/** does the column contain the given json value at the top level */
	_contains?: GraphQLTypes["jsonb"],
	_eq?: GraphQLTypes["jsonb"],
	_gt?: GraphQLTypes["jsonb"],
	_gte?: GraphQLTypes["jsonb"],
	/** does the string exist as a top-level key in the column */
	_has_key?: string,
	/** do all of these strings exist as top-level keys in the column */
	_has_keys_all?: Array<string>,
	/** do any of these strings exist as top-level keys in the column */
	_has_keys_any?: Array<string>,
	_in?: Array<GraphQLTypes["jsonb"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["jsonb"],
	_lte?: GraphQLTypes["jsonb"],
	_neq?: GraphQLTypes["jsonb"],
	_nin?: Array<GraphQLTypes["jsonb"]>
};
	/** mutation root */
["mutation_root"]: {
	__typename: "mutation_root",
	/** delete data from the table: "awsdms_validation_failures_v1" */
	delete_awsdms_validation_failures_v1?: GraphQLTypes["awsdms_validation_failures_v1_mutation_response"],
	/** delete data from the table: "coinsystem.balances" */
	delete_coinsystem_balances?: GraphQLTypes["coinsystem_balances_mutation_response"],
	/** delete single row from the table: "coinsystem.balances" */
	delete_coinsystem_balances_by_pk?: GraphQLTypes["coinsystem_balances"],
	/** delete data from the table: "coinsystem.bonus_coins" */
	delete_coinsystem_bonus_coins?: GraphQLTypes["coinsystem_bonus_coins_mutation_response"],
	/** delete single row from the table: "coinsystem.bonus_coins" */
	delete_coinsystem_bonus_coins_by_pk?: GraphQLTypes["coinsystem_bonus_coins"],
	/** delete data from the table: "coinsystem.coins" */
	delete_coinsystem_coins?: GraphQLTypes["coinsystem_coins_mutation_response"],
	/** delete single row from the table: "coinsystem.coins" */
	delete_coinsystem_coins_by_pk?: GraphQLTypes["coinsystem_coins"],
	/** delete data from the table: "coinsystem.exchange_rates" */
	delete_coinsystem_exchange_rates?: GraphQLTypes["coinsystem_exchange_rates_mutation_response"],
	/** delete single row from the table: "coinsystem.exchange_rates" */
	delete_coinsystem_exchange_rates_by_pk?: GraphQLTypes["coinsystem_exchange_rates"],
	/** delete data from the table: "coinsystem.logs" */
	delete_coinsystem_logs?: GraphQLTypes["coinsystem_logs_mutation_response"],
	/** delete single row from the table: "coinsystem.logs" */
	delete_coinsystem_logs_by_pk?: GraphQLTypes["coinsystem_logs"],
	/** delete data from the table: "coinsystem.transactions" */
	delete_coinsystem_transactions?: GraphQLTypes["coinsystem_transactions_mutation_response"],
	/** delete single row from the table: "coinsystem.transactions" */
	delete_coinsystem_transactions_by_pk?: GraphQLTypes["coinsystem_transactions"],
	/** delete data from the table: "coinsystem.wallets" */
	delete_coinsystem_wallets?: GraphQLTypes["coinsystem_wallets_mutation_response"],
	/** delete single row from the table: "coinsystem.wallets" */
	delete_coinsystem_wallets_by_pk?: GraphQLTypes["coinsystem_wallets"],
	/** delete data from the table: "mysql.user" */
	delete_mysql_user?: GraphQLTypes["mysql_user_mutation_response"],
	/** delete single row from the table: "mysql.user" */
	delete_mysql_user_by_pk?: GraphQLTypes["mysql_user"],
	/** delete data from the table: "store.payments" */
	delete_store_payments?: GraphQLTypes["store_payments_mutation_response"],
	/** delete single row from the table: "store.payments" */
	delete_store_payments_by_pk?: GraphQLTypes["store_payments"],
	/** delete data from the table: "store.products" */
	delete_store_products?: GraphQLTypes["store_products_mutation_response"],
	/** delete single row from the table: "store.products" */
	delete_store_products_by_pk?: GraphQLTypes["store_products"],
	/** delete data from the table: "store.purchases" */
	delete_store_purchases?: GraphQLTypes["store_purchases_mutation_response"],
	/** delete single row from the table: "store.purchases" */
	delete_store_purchases_by_pk?: GraphQLTypes["store_purchases"],
	/** delete data from the table: "store.users" */
	delete_store_users?: GraphQLTypes["store_users_mutation_response"],
	/** delete data from the table: "voyce.admin_roles" */
	delete_voyce_admin_roles?: GraphQLTypes["voyce_admin_roles_mutation_response"],
	/** delete single row from the table: "voyce.admin_roles" */
	delete_voyce_admin_roles_by_pk?: GraphQLTypes["voyce_admin_roles"],
	/** delete data from the table: "voyce.app_banners" */
	delete_voyce_app_banners?: GraphQLTypes["voyce_app_banners_mutation_response"],
	/** delete single row from the table: "voyce.app_banners" */
	delete_voyce_app_banners_by_pk?: GraphQLTypes["voyce_app_banners"],
	/** delete data from the table: "voyce.app_components" */
	delete_voyce_app_components?: GraphQLTypes["voyce_app_components_mutation_response"],
	/** delete single row from the table: "voyce.app_components" */
	delete_voyce_app_components_by_pk?: GraphQLTypes["voyce_app_components"],
	/** delete data from the table: "voyce.audit_trail" */
	delete_voyce_audit_trail?: GraphQLTypes["voyce_audit_trail_mutation_response"],
	/** delete single row from the table: "voyce.audit_trail" */
	delete_voyce_audit_trail_by_pk?: GraphQLTypes["voyce_audit_trail"],
	/** delete data from the table: "voyce.author_profiles" */
	delete_voyce_author_profiles?: GraphQLTypes["voyce_author_profiles_mutation_response"],
	/** delete single row from the table: "voyce.author_profiles" */
	delete_voyce_author_profiles_by_pk?: GraphQLTypes["voyce_author_profiles"],
	/** delete data from the table: "voyce.balances" */
	delete_voyce_balances?: GraphQLTypes["voyce_balances_mutation_response"],
	/** delete single row from the table: "voyce.balances" */
	delete_voyce_balances_by_pk?: GraphQLTypes["voyce_balances"],
	/** delete data from the table: "voyce.banners" */
	delete_voyce_banners?: GraphQLTypes["voyce_banners_mutation_response"],
	/** delete single row from the table: "voyce.banners" */
	delete_voyce_banners_by_pk?: GraphQLTypes["voyce_banners"],
	/** delete data from the table: "voyce.chapter_assets" */
	delete_voyce_chapter_assets?: GraphQLTypes["voyce_chapter_assets_mutation_response"],
	/** delete single row from the table: "voyce.chapter_assets" */
	delete_voyce_chapter_assets_by_pk?: GraphQLTypes["voyce_chapter_assets"],
	/** delete data from the table: "voyce.chapter_bookmarks" */
	delete_voyce_chapter_bookmarks?: GraphQLTypes["voyce_chapter_bookmarks_mutation_response"],
	/** delete single row from the table: "voyce.chapter_bookmarks" */
	delete_voyce_chapter_bookmarks_by_pk?: GraphQLTypes["voyce_chapter_bookmarks"],
	/** delete data from the table: "voyce.chapter_comments" */
	delete_voyce_chapter_comments?: GraphQLTypes["voyce_chapter_comments_mutation_response"],
	/** delete data from the table: "voyce.chapter_comments_07252024" */
	delete_voyce_chapter_comments_07252024?: GraphQLTypes["voyce_chapter_comments_07252024_mutation_response"],
	/** delete single row from the table: "voyce.chapter_comments" */
	delete_voyce_chapter_comments_by_pk?: GraphQLTypes["voyce_chapter_comments"],
	/** delete data from the table: "voyce.chapter_comments_reaction" */
	delete_voyce_chapter_comments_reaction?: GraphQLTypes["voyce_chapter_comments_reaction_mutation_response"],
	/** delete single row from the table: "voyce.chapter_comments_reaction" */
	delete_voyce_chapter_comments_reaction_by_pk?: GraphQLTypes["voyce_chapter_comments_reaction"],
	/** delete data from the table: "voyce.chapter_comments_reaction_count" */
	delete_voyce_chapter_comments_reaction_count?: GraphQLTypes["voyce_chapter_comments_reaction_count_mutation_response"],
	/** delete single row from the table: "voyce.chapter_comments_reaction_count" */
	delete_voyce_chapter_comments_reaction_count_by_pk?: GraphQLTypes["voyce_chapter_comments_reaction_count"],
	/** delete data from the table: "voyce.chapter_comments_reports" */
	delete_voyce_chapter_comments_reports?: GraphQLTypes["voyce_chapter_comments_reports_mutation_response"],
	/** delete single row from the table: "voyce.chapter_comments_reports" */
	delete_voyce_chapter_comments_reports_by_pk?: GraphQLTypes["voyce_chapter_comments_reports"],
	/** delete data from the table: "voyce.chapter_contents" */
	delete_voyce_chapter_contents?: GraphQLTypes["voyce_chapter_contents_mutation_response"],
	/** delete single row from the table: "voyce.chapter_contents" */
	delete_voyce_chapter_contents_by_pk?: GraphQLTypes["voyce_chapter_contents"],
	/** delete data from the table: "voyce.chapter_contents_old" */
	delete_voyce_chapter_contents_old?: GraphQLTypes["voyce_chapter_contents_old_mutation_response"],
	/** delete single row from the table: "voyce.chapter_contents_old" */
	delete_voyce_chapter_contents_old_by_pk?: GraphQLTypes["voyce_chapter_contents_old"],
	/** delete data from the table: "voyce.chapter_effects" */
	delete_voyce_chapter_effects?: GraphQLTypes["voyce_chapter_effects_mutation_response"],
	/** delete single row from the table: "voyce.chapter_effects" */
	delete_voyce_chapter_effects_by_pk?: GraphQLTypes["voyce_chapter_effects"],
	/** delete data from the table: "voyce.chapter_images" */
	delete_voyce_chapter_images?: GraphQLTypes["voyce_chapter_images_mutation_response"],
	/** delete data from the table: "voyce.chapter_images_07252024" */
	delete_voyce_chapter_images_07252024?: GraphQLTypes["voyce_chapter_images_07252024_mutation_response"],
	/** delete single row from the table: "voyce.chapter_images" */
	delete_voyce_chapter_images_by_pk?: GraphQLTypes["voyce_chapter_images"],
	/** delete data from the table: "voyce.chapter_likes" */
	delete_voyce_chapter_likes?: GraphQLTypes["voyce_chapter_likes_mutation_response"],
	/** delete single row from the table: "voyce.chapter_likes" */
	delete_voyce_chapter_likes_by_pk?: GraphQLTypes["voyce_chapter_likes"],
	/** delete data from the table: "voyce.chapter_locks" */
	delete_voyce_chapter_locks?: GraphQLTypes["voyce_chapter_locks_mutation_response"],
	/** delete single row from the table: "voyce.chapter_locks" */
	delete_voyce_chapter_locks_by_pk?: GraphQLTypes["voyce_chapter_locks"],
	/** delete data from the table: "voyce.chapter_panel_views" */
	delete_voyce_chapter_panel_views?: GraphQLTypes["voyce_chapter_panel_views_mutation_response"],
	/** delete single row from the table: "voyce.chapter_panel_views" */
	delete_voyce_chapter_panel_views_by_pk?: GraphQLTypes["voyce_chapter_panel_views"],
	/** delete data from the table: "voyce.chapter_panels" */
	delete_voyce_chapter_panels?: GraphQLTypes["voyce_chapter_panels_mutation_response"],
	/** delete single row from the table: "voyce.chapter_panels" */
	delete_voyce_chapter_panels_by_pk?: GraphQLTypes["voyce_chapter_panels"],
	/** delete data from the table: "voyce.chapter_publish_schedules" */
	delete_voyce_chapter_publish_schedules?: GraphQLTypes["voyce_chapter_publish_schedules_mutation_response"],
	/** delete single row from the table: "voyce.chapter_publish_schedules" */
	delete_voyce_chapter_publish_schedules_by_pk?: GraphQLTypes["voyce_chapter_publish_schedules"],
	/** delete data from the table: "voyce.chapter_reactions" */
	delete_voyce_chapter_reactions?: GraphQLTypes["voyce_chapter_reactions_mutation_response"],
	/** delete single row from the table: "voyce.chapter_reactions" */
	delete_voyce_chapter_reactions_by_pk?: GraphQLTypes["voyce_chapter_reactions"],
	/** delete data from the table: "voyce.chapter_reactions_count" */
	delete_voyce_chapter_reactions_count?: GraphQLTypes["voyce_chapter_reactions_count_mutation_response"],
	/** delete single row from the table: "voyce.chapter_reactions_count" */
	delete_voyce_chapter_reactions_count_by_pk?: GraphQLTypes["voyce_chapter_reactions_count"],
	/** delete data from the table: "voyce.chapter_view_logs" */
	delete_voyce_chapter_view_logs?: GraphQLTypes["voyce_chapter_view_logs_mutation_response"],
	/** delete single row from the table: "voyce.chapter_view_logs" */
	delete_voyce_chapter_view_logs_by_pk?: GraphQLTypes["voyce_chapter_view_logs"],
	/** delete data from the table: "voyce.chapter_views" */
	delete_voyce_chapter_views?: GraphQLTypes["voyce_chapter_views_mutation_response"],
	/** delete single row from the table: "voyce.chapter_views" */
	delete_voyce_chapter_views_by_pk?: GraphQLTypes["voyce_chapter_views"],
	/** delete data from the table: "voyce.chapters" */
	delete_voyce_chapters?: GraphQLTypes["voyce_chapters_mutation_response"],
	/** delete data from the table: "voyce.chapters_07252024" */
	delete_voyce_chapters_07252024?: GraphQLTypes["voyce_chapters_07252024_mutation_response"],
	/** delete single row from the table: "voyce.chapters" */
	delete_voyce_chapters_by_pk?: GraphQLTypes["voyce_chapters"],
	/** delete data from the table: "voyce.collaborator_roles" */
	delete_voyce_collaborator_roles?: GraphQLTypes["voyce_collaborator_roles_mutation_response"],
	/** delete single row from the table: "voyce.collaborator_roles" */
	delete_voyce_collaborator_roles_by_pk?: GraphQLTypes["voyce_collaborator_roles"],
	/** delete data from the table: "voyce.comic_types" */
	delete_voyce_comic_types?: GraphQLTypes["voyce_comic_types_mutation_response"],
	/** delete single row from the table: "voyce.comic_types" */
	delete_voyce_comic_types_by_pk?: GraphQLTypes["voyce_comic_types"],
	/** delete data from the table: "voyce.community_blurbs" */
	delete_voyce_community_blurbs?: GraphQLTypes["voyce_community_blurbs_mutation_response"],
	/** delete single row from the table: "voyce.community_blurbs" */
	delete_voyce_community_blurbs_by_pk?: GraphQLTypes["voyce_community_blurbs"],
	/** delete data from the table: "voyce.contact_us" */
	delete_voyce_contact_us?: GraphQLTypes["voyce_contact_us_mutation_response"],
	/** delete single row from the table: "voyce.contact_us" */
	delete_voyce_contact_us_by_pk?: GraphQLTypes["voyce_contact_us"],
	/** delete data from the table: "voyce.content_report" */
	delete_voyce_content_report?: GraphQLTypes["voyce_content_report_mutation_response"],
	/** delete single row from the table: "voyce.content_report" */
	delete_voyce_content_report_by_pk?: GraphQLTypes["voyce_content_report"],
	/** delete data from the table: "voyce.contest_data" */
	delete_voyce_contest_data?: GraphQLTypes["voyce_contest_data_mutation_response"],
	/** delete single row from the table: "voyce.contest_data" */
	delete_voyce_contest_data_by_pk?: GraphQLTypes["voyce_contest_data"],
	/** delete data from the table: "voyce.countries" */
	delete_voyce_countries?: GraphQLTypes["voyce_countries_mutation_response"],
	/** delete single row from the table: "voyce.countries" */
	delete_voyce_countries_by_pk?: GraphQLTypes["voyce_countries"],
	/** delete data from the table: "voyce.csat_ratings" */
	delete_voyce_csat_ratings?: GraphQLTypes["voyce_csat_ratings_mutation_response"],
	/** delete single row from the table: "voyce.csat_ratings" */
	delete_voyce_csat_ratings_by_pk?: GraphQLTypes["voyce_csat_ratings"],
	/** delete data from the table: "voyce.csat_ratings_data" */
	delete_voyce_csat_ratings_data?: GraphQLTypes["voyce_csat_ratings_data_mutation_response"],
	/** delete single row from the table: "voyce.csat_ratings_data" */
	delete_voyce_csat_ratings_data_by_pk?: GraphQLTypes["voyce_csat_ratings_data"],
	/** delete data from the table: "voyce.education_types" */
	delete_voyce_education_types?: GraphQLTypes["voyce_education_types_mutation_response"],
	/** delete single row from the table: "voyce.education_types" */
	delete_voyce_education_types_by_pk?: GraphQLTypes["voyce_education_types"],
	/** delete data from the table: "voyce.erp_login_roles" */
	delete_voyce_erp_login_roles?: GraphQLTypes["voyce_erp_login_roles_mutation_response"],
	/** delete single row from the table: "voyce.erp_login_roles" */
	delete_voyce_erp_login_roles_by_pk?: GraphQLTypes["voyce_erp_login_roles"],
	/** delete data from the table: "voyce.etl_series_views_logs" */
	delete_voyce_etl_series_views_logs?: GraphQLTypes["voyce_etl_series_views_logs_mutation_response"],
	/** delete single row from the table: "voyce.etl_series_views_logs" */
	delete_voyce_etl_series_views_logs_by_pk?: GraphQLTypes["voyce_etl_series_views_logs"],
	/** delete data from the table: "voyce.event_schedules" */
	delete_voyce_event_schedules?: GraphQLTypes["voyce_event_schedules_mutation_response"],
	/** delete single row from the table: "voyce.event_schedules" */
	delete_voyce_event_schedules_by_pk?: GraphQLTypes["voyce_event_schedules"],
	/** delete data from the table: "voyce.genres" */
	delete_voyce_genres?: GraphQLTypes["voyce_genres_mutation_response"],
	/** delete single row from the table: "voyce.genres" */
	delete_voyce_genres_by_pk?: GraphQLTypes["voyce_genres"],
	/** delete data from the table: "voyce.guest_gtags" */
	delete_voyce_guest_gtags?: GraphQLTypes["voyce_guest_gtags_mutation_response"],
	/** delete single row from the table: "voyce.guest_gtags" */
	delete_voyce_guest_gtags_by_pk?: GraphQLTypes["voyce_guest_gtags"],
	/** delete data from the table: "voyce.metabases" */
	delete_voyce_metabases?: GraphQLTypes["voyce_metabases_mutation_response"],
	/** delete data from the table: "voyce.metabases_access" */
	delete_voyce_metabases_access?: GraphQLTypes["voyce_metabases_access_mutation_response"],
	/** delete single row from the table: "voyce.metabases_access" */
	delete_voyce_metabases_access_by_pk?: GraphQLTypes["voyce_metabases_access"],
	/** delete single row from the table: "voyce.metabases" */
	delete_voyce_metabases_by_pk?: GraphQLTypes["voyce_metabases"],
	/** delete data from the table: "voyce.newsletter" */
	delete_voyce_newsletter?: GraphQLTypes["voyce_newsletter_mutation_response"],
	/** delete single row from the table: "voyce.newsletter" */
	delete_voyce_newsletter_by_pk?: GraphQLTypes["voyce_newsletter"],
	/** delete data from the table: "voyce.notification_series_settings" */
	delete_voyce_notification_series_settings?: GraphQLTypes["voyce_notification_series_settings_mutation_response"],
	/** delete single row from the table: "voyce.notification_series_settings" */
	delete_voyce_notification_series_settings_by_pk?: GraphQLTypes["voyce_notification_series_settings"],
	/** delete data from the table: "voyce.notification_settings" */
	delete_voyce_notification_settings?: GraphQLTypes["voyce_notification_settings_mutation_response"],
	/** delete single row from the table: "voyce.notification_settings" */
	delete_voyce_notification_settings_by_pk?: GraphQLTypes["voyce_notification_settings"],
	/** delete data from the table: "voyce.notifications" */
	delete_voyce_notifications?: GraphQLTypes["voyce_notifications_mutation_response"],
	/** delete single row from the table: "voyce.notifications" */
	delete_voyce_notifications_by_pk?: GraphQLTypes["voyce_notifications"],
	/** delete data from the table: "voyce.page_banners" */
	delete_voyce_page_banners?: GraphQLTypes["voyce_page_banners_mutation_response"],
	/** delete single row from the table: "voyce.page_banners" */
	delete_voyce_page_banners_by_pk?: GraphQLTypes["voyce_page_banners"],
	/** delete data from the table: "voyce.page_components" */
	delete_voyce_page_components?: GraphQLTypes["voyce_page_components_mutation_response"],
	/** delete single row from the table: "voyce.page_components" */
	delete_voyce_page_components_by_pk?: GraphQLTypes["voyce_page_components"],
	/** delete data from the table: "voyce.pages" */
	delete_voyce_pages?: GraphQLTypes["voyce_pages_mutation_response"],
	/** delete single row from the table: "voyce.pages" */
	delete_voyce_pages_by_pk?: GraphQLTypes["voyce_pages"],
	/** delete data from the table: "voyce.panels_comments" */
	delete_voyce_panels_comments?: GraphQLTypes["voyce_panels_comments_mutation_response"],
	/** delete single row from the table: "voyce.panels_comments" */
	delete_voyce_panels_comments_by_pk?: GraphQLTypes["voyce_panels_comments"],
	/** delete data from the table: "voyce.panels_reactions" */
	delete_voyce_panels_reactions?: GraphQLTypes["voyce_panels_reactions_mutation_response"],
	/** delete single row from the table: "voyce.panels_reactions" */
	delete_voyce_panels_reactions_by_pk?: GraphQLTypes["voyce_panels_reactions"],
	/** delete data from the table: "voyce.panels_reactions_count" */
	delete_voyce_panels_reactions_count?: GraphQLTypes["voyce_panels_reactions_count_mutation_response"],
	/** delete single row from the table: "voyce.panels_reactions_count" */
	delete_voyce_panels_reactions_count_by_pk?: GraphQLTypes["voyce_panels_reactions_count"],
	/** delete data from the table: "voyce.payments_to_creators" */
	delete_voyce_payments_to_creators?: GraphQLTypes["voyce_payments_to_creators_mutation_response"],
	/** delete single row from the table: "voyce.payments_to_creators" */
	delete_voyce_payments_to_creators_by_pk?: GraphQLTypes["voyce_payments_to_creators"],
	/** delete data from the table: "voyce.pg_rs_series_views_count" */
	delete_voyce_pg_rs_series_views_count?: GraphQLTypes["voyce_pg_rs_series_views_count_mutation_response"],
	/** delete single row from the table: "voyce.pg_rs_series_views_count" */
	delete_voyce_pg_rs_series_views_count_by_pk?: GraphQLTypes["voyce_pg_rs_series_views_count"],
	/** delete data from the table: "voyce.polls" */
	delete_voyce_polls?: GraphQLTypes["voyce_polls_mutation_response"],
	/** delete data from the table: "voyce.polls_answers" */
	delete_voyce_polls_answers?: GraphQLTypes["voyce_polls_answers_mutation_response"],
	/** delete single row from the table: "voyce.polls_answers" */
	delete_voyce_polls_answers_by_pk?: GraphQLTypes["voyce_polls_answers"],
	/** delete single row from the table: "voyce.polls" */
	delete_voyce_polls_by_pk?: GraphQLTypes["voyce_polls"],
	/** delete data from the table: "voyce.polls_comments" */
	delete_voyce_polls_comments?: GraphQLTypes["voyce_polls_comments_mutation_response"],
	/** delete single row from the table: "voyce.polls_comments" */
	delete_voyce_polls_comments_by_pk?: GraphQLTypes["voyce_polls_comments"],
	/** delete data from the table: "voyce.polls_reactions" */
	delete_voyce_polls_reactions?: GraphQLTypes["voyce_polls_reactions_mutation_response"],
	/** delete single row from the table: "voyce.polls_reactions" */
	delete_voyce_polls_reactions_by_pk?: GraphQLTypes["voyce_polls_reactions"],
	/** delete data from the table: "voyce.polls_votes" */
	delete_voyce_polls_votes?: GraphQLTypes["voyce_polls_votes_mutation_response"],
	/** delete single row from the table: "voyce.polls_votes" */
	delete_voyce_polls_votes_by_pk?: GraphQLTypes["voyce_polls_votes"],
	/** delete data from the table: "voyce.popup_components" */
	delete_voyce_popup_components?: GraphQLTypes["voyce_popup_components_mutation_response"],
	/** delete single row from the table: "voyce.popup_components" */
	delete_voyce_popup_components_by_pk?: GraphQLTypes["voyce_popup_components"],
	/** delete data from the table: "voyce.popup_emails" */
	delete_voyce_popup_emails?: GraphQLTypes["voyce_popup_emails_mutation_response"],
	/** delete single row from the table: "voyce.popup_emails" */
	delete_voyce_popup_emails_by_pk?: GraphQLTypes["voyce_popup_emails"],
	/** delete data from the table: "voyce.posts" */
	delete_voyce_posts?: GraphQLTypes["voyce_posts_mutation_response"],
	/** delete single row from the table: "voyce.posts" */
	delete_voyce_posts_by_pk?: GraphQLTypes["voyce_posts"],
	/** delete data from the table: "voyce.posts_comments" */
	delete_voyce_posts_comments?: GraphQLTypes["voyce_posts_comments_mutation_response"],
	/** delete single row from the table: "voyce.posts_comments" */
	delete_voyce_posts_comments_by_pk?: GraphQLTypes["voyce_posts_comments"],
	/** delete data from the table: "voyce.posts_reactions" */
	delete_voyce_posts_reactions?: GraphQLTypes["voyce_posts_reactions_mutation_response"],
	/** delete single row from the table: "voyce.posts_reactions" */
	delete_voyce_posts_reactions_by_pk?: GraphQLTypes["voyce_posts_reactions"],
	/** delete data from the table: "voyce.quest_difficulties" */
	delete_voyce_quest_difficulties?: GraphQLTypes["voyce_quest_difficulties_mutation_response"],
	/** delete single row from the table: "voyce.quest_difficulties" */
	delete_voyce_quest_difficulties_by_pk?: GraphQLTypes["voyce_quest_difficulties"],
	/** delete data from the table: "voyce.quests" */
	delete_voyce_quests?: GraphQLTypes["voyce_quests_mutation_response"],
	/** delete single row from the table: "voyce.quests" */
	delete_voyce_quests_by_pk?: GraphQLTypes["voyce_quests"],
	/** delete data from the table: "voyce.quests_old" */
	delete_voyce_quests_old?: GraphQLTypes["voyce_quests_old_mutation_response"],
	/** delete single row from the table: "voyce.quests_old" */
	delete_voyce_quests_old_by_pk?: GraphQLTypes["voyce_quests_old"],
	/** delete data from the table: "voyce.recommended_series" */
	delete_voyce_recommended_series?: GraphQLTypes["voyce_recommended_series_mutation_response"],
	/** delete single row from the table: "voyce.recommended_series" */
	delete_voyce_recommended_series_by_pk?: GraphQLTypes["voyce_recommended_series"],
	/** delete data from the table: "voyce.release_schedules" */
	delete_voyce_release_schedules?: GraphQLTypes["voyce_release_schedules_mutation_response"],
	/** delete single row from the table: "voyce.release_schedules" */
	delete_voyce_release_schedules_by_pk?: GraphQLTypes["voyce_release_schedules"],
	/** delete data from the table: "voyce.rewards" */
	delete_voyce_rewards?: GraphQLTypes["voyce_rewards_mutation_response"],
	/** delete single row from the table: "voyce.rewards" */
	delete_voyce_rewards_by_pk?: GraphQLTypes["voyce_rewards"],
	/** delete data from the table: "voyce.rewards_leaderboard" */
	delete_voyce_rewards_leaderboard?: GraphQLTypes["voyce_rewards_leaderboard_mutation_response"],
	/** delete single row from the table: "voyce.rewards_leaderboard" */
	delete_voyce_rewards_leaderboard_by_pk?: GraphQLTypes["voyce_rewards_leaderboard"],
	/** delete data from the table: "voyce.rewards_quest" */
	delete_voyce_rewards_quest?: GraphQLTypes["voyce_rewards_quest_mutation_response"],
	/** delete single row from the table: "voyce.rewards_quest" */
	delete_voyce_rewards_quest_by_pk?: GraphQLTypes["voyce_rewards_quest"],
	/** delete data from the table: "voyce.sections" */
	delete_voyce_sections?: GraphQLTypes["voyce_sections_mutation_response"],
	/** delete single row from the table: "voyce.sections" */
	delete_voyce_sections_by_pk?: GraphQLTypes["voyce_sections"],
	/** delete data from the table: "voyce.series" */
	delete_voyce_series?: GraphQLTypes["voyce_series_mutation_response"],
	/** delete single row from the table: "voyce.series" */
	delete_voyce_series_by_pk?: GraphQLTypes["voyce_series"],
	/** delete data from the table: "voyce.series_collaborators" */
	delete_voyce_series_collaborators?: GraphQLTypes["voyce_series_collaborators_mutation_response"],
	/** delete single row from the table: "voyce.series_collaborators" */
	delete_voyce_series_collaborators_by_pk?: GraphQLTypes["voyce_series_collaborators"],
	/** delete data from the table: "voyce.series_donations" */
	delete_voyce_series_donations?: GraphQLTypes["voyce_series_donations_mutation_response"],
	/** delete single row from the table: "voyce.series_donations" */
	delete_voyce_series_donations_by_pk?: GraphQLTypes["voyce_series_donations"],
	/** delete data from the table: "voyce.series_favorites" */
	delete_voyce_series_favorites?: GraphQLTypes["voyce_series_favorites_mutation_response"],
	/** delete single row from the table: "voyce.series_favorites" */
	delete_voyce_series_favorites_by_pk?: GraphQLTypes["voyce_series_favorites"],
	/** delete data from the table: "voyce.series_featured_panels" */
	delete_voyce_series_featured_panels?: GraphQLTypes["voyce_series_featured_panels_mutation_response"],
	/** delete single row from the table: "voyce.series_featured_panels" */
	delete_voyce_series_featured_panels_by_pk?: GraphQLTypes["voyce_series_featured_panels"],
	/** delete data from the table: "voyce.series_genre" */
	delete_voyce_series_genre?: GraphQLTypes["voyce_series_genre_mutation_response"],
	/** delete single row from the table: "voyce.series_genre" */
	delete_voyce_series_genre_by_pk?: GraphQLTypes["voyce_series_genre"],
	/** delete data from the table: "voyce.series_giveaway_participants" */
	delete_voyce_series_giveaway_participants?: GraphQLTypes["voyce_series_giveaway_participants_mutation_response"],
	/** delete single row from the table: "voyce.series_giveaway_participants" */
	delete_voyce_series_giveaway_participants_by_pk?: GraphQLTypes["voyce_series_giveaway_participants"],
	/** delete data from the table: "voyce.series_hot_panels" */
	delete_voyce_series_hot_panels?: GraphQLTypes["voyce_series_hot_panels_mutation_response"],
	/** delete single row from the table: "voyce.series_hot_panels" */
	delete_voyce_series_hot_panels_by_pk?: GraphQLTypes["voyce_series_hot_panels"],
	/** delete data from the table: "voyce.series_layouts" */
	delete_voyce_series_layouts?: GraphQLTypes["voyce_series_layouts_mutation_response"],
	/** delete single row from the table: "voyce.series_layouts" */
	delete_voyce_series_layouts_by_pk?: GraphQLTypes["voyce_series_layouts"],
	/** delete data from the table: "voyce.series_leaderboard" */
	delete_voyce_series_leaderboard?: GraphQLTypes["voyce_series_leaderboard_mutation_response"],
	/** delete single row from the table: "voyce.series_leaderboard" */
	delete_voyce_series_leaderboard_by_pk?: GraphQLTypes["voyce_series_leaderboard"],
	/** delete data from the table: "voyce.series_lock_config" */
	delete_voyce_series_lock_config?: GraphQLTypes["voyce_series_lock_config_mutation_response"],
	/** delete single row from the table: "voyce.series_lock_config" */
	delete_voyce_series_lock_config_by_pk?: GraphQLTypes["voyce_series_lock_config"],
	/** delete data from the table: "voyce.series_sponsorships_configuration" */
	delete_voyce_series_sponsorships_configuration?: GraphQLTypes["voyce_series_sponsorships_configuration_mutation_response"],
	/** delete single row from the table: "voyce.series_sponsorships_configuration" */
	delete_voyce_series_sponsorships_configuration_by_pk?: GraphQLTypes["voyce_series_sponsorships_configuration"],
	/** delete data from the table: "voyce.series_subscription" */
	delete_voyce_series_subscription?: GraphQLTypes["voyce_series_subscription_mutation_response"],
	/** delete single row from the table: "voyce.series_subscription" */
	delete_voyce_series_subscription_by_pk?: GraphQLTypes["voyce_series_subscription"],
	/** delete data from the table: "voyce.series_tags" */
	delete_voyce_series_tags?: GraphQLTypes["voyce_series_tags_mutation_response"],
	/** delete single row from the table: "voyce.series_tags" */
	delete_voyce_series_tags_by_pk?: GraphQLTypes["voyce_series_tags"],
	/** delete data from the table: "voyce.series_tags_junction" */
	delete_voyce_series_tags_junction?: GraphQLTypes["voyce_series_tags_junction_mutation_response"],
	/** delete single row from the table: "voyce.series_tags_junction" */
	delete_voyce_series_tags_junction_by_pk?: GraphQLTypes["voyce_series_tags_junction"],
	/** delete data from the table: "voyce.series_top_comments" */
	delete_voyce_series_top_comments?: GraphQLTypes["voyce_series_top_comments_mutation_response"],
	/** delete single row from the table: "voyce.series_top_comments" */
	delete_voyce_series_top_comments_by_pk?: GraphQLTypes["voyce_series_top_comments"],
	/** delete data from the table: "voyce.series_top_likes" */
	delete_voyce_series_top_likes?: GraphQLTypes["voyce_series_top_likes_mutation_response"],
	/** delete single row from the table: "voyce.series_top_likes" */
	delete_voyce_series_top_likes_by_pk?: GraphQLTypes["voyce_series_top_likes"],
	/** delete data from the table: "voyce.series_top_views" */
	delete_voyce_series_top_views?: GraphQLTypes["voyce_series_top_views_mutation_response"],
	/** delete single row from the table: "voyce.series_top_views" */
	delete_voyce_series_top_views_by_pk?: GraphQLTypes["voyce_series_top_views"],
	/** delete data from the table: "voyce.series_views_adjustment" */
	delete_voyce_series_views_adjustment?: GraphQLTypes["voyce_series_views_adjustment_mutation_response"],
	/** delete single row from the table: "voyce.series_views_adjustment" */
	delete_voyce_series_views_adjustment_by_pk?: GraphQLTypes["voyce_series_views_adjustment"],
	/** delete data from the table: "voyce.series_votes" */
	delete_voyce_series_votes?: GraphQLTypes["voyce_series_votes_mutation_response"],
	/** delete single row from the table: "voyce.series_votes" */
	delete_voyce_series_votes_by_pk?: GraphQLTypes["voyce_series_votes"],
	/** delete data from the table: "voyce.series_votes_count" */
	delete_voyce_series_votes_count?: GraphQLTypes["voyce_series_votes_count_mutation_response"],
	/** delete single row from the table: "voyce.series_votes_count" */
	delete_voyce_series_votes_count_by_pk?: GraphQLTypes["voyce_series_votes_count"],
	/** delete data from the table: "voyce.series_voting_schedules" */
	delete_voyce_series_voting_schedules?: GraphQLTypes["voyce_series_voting_schedules_mutation_response"],
	/** delete single row from the table: "voyce.series_voting_schedules" */
	delete_voyce_series_voting_schedules_by_pk?: GraphQLTypes["voyce_series_voting_schedules"],
	/** delete data from the table: "voyce.sponsorships" */
	delete_voyce_sponsorships?: GraphQLTypes["voyce_sponsorships_mutation_response"],
	/** delete single row from the table: "voyce.sponsorships" */
	delete_voyce_sponsorships_by_pk?: GraphQLTypes["voyce_sponsorships"],
	/** delete data from the table: "voyce.states" */
	delete_voyce_states?: GraphQLTypes["voyce_states_mutation_response"],
	/** delete single row from the table: "voyce.states" */
	delete_voyce_states_by_pk?: GraphQLTypes["voyce_states"],
	/** delete data from the table: "voyce.stripe_payouts_setup" */
	delete_voyce_stripe_payouts_setup?: GraphQLTypes["voyce_stripe_payouts_setup_mutation_response"],
	/** delete single row from the table: "voyce.stripe_payouts_setup" */
	delete_voyce_stripe_payouts_setup_by_pk?: GraphQLTypes["voyce_stripe_payouts_setup"],
	/** delete data from the table: "voyce.studio" */
	delete_voyce_studio?: GraphQLTypes["voyce_studio_mutation_response"],
	/** delete single row from the table: "voyce.studio" */
	delete_voyce_studio_by_pk?: GraphQLTypes["voyce_studio"],
	/** delete data from the table: "voyce.studio_followers" */
	delete_voyce_studio_followers?: GraphQLTypes["voyce_studio_followers_mutation_response"],
	/** delete single row from the table: "voyce.studio_followers" */
	delete_voyce_studio_followers_by_pk?: GraphQLTypes["voyce_studio_followers"],
	/** delete data from the table: "voyce.studio_series" */
	delete_voyce_studio_series?: GraphQLTypes["voyce_studio_series_mutation_response"],
	/** delete single row from the table: "voyce.studio_series" */
	delete_voyce_studio_series_by_pk?: GraphQLTypes["voyce_studio_series"],
	/** delete data from the table: "voyce.studio_views" */
	delete_voyce_studio_views?: GraphQLTypes["voyce_studio_views_mutation_response"],
	/** delete single row from the table: "voyce.studio_views" */
	delete_voyce_studio_views_by_pk?: GraphQLTypes["voyce_studio_views"],
	/** delete data from the table: "voyce.system_settings" */
	delete_voyce_system_settings?: GraphQLTypes["voyce_system_settings_mutation_response"],
	/** delete single row from the table: "voyce.system_settings" */
	delete_voyce_system_settings_by_pk?: GraphQLTypes["voyce_system_settings"],
	/** delete data from the table: "voyce.transaction_logs" */
	delete_voyce_transaction_logs?: GraphQLTypes["voyce_transaction_logs_mutation_response"],
	/** delete single row from the table: "voyce.transaction_logs" */
	delete_voyce_transaction_logs_by_pk?: GraphQLTypes["voyce_transaction_logs"],
	/** delete data from the table: "voyce.url_redirects" */
	delete_voyce_url_redirects?: GraphQLTypes["voyce_url_redirects_mutation_response"],
	/** delete single row from the table: "voyce.url_redirects" */
	delete_voyce_url_redirects_by_pk?: GraphQLTypes["voyce_url_redirects"],
	/** delete data from the table: "voyce.user_addresses" */
	delete_voyce_user_addresses?: GraphQLTypes["voyce_user_addresses_mutation_response"],
	/** delete single row from the table: "voyce.user_addresses" */
	delete_voyce_user_addresses_by_pk?: GraphQLTypes["voyce_user_addresses"],
	/** delete data from the table: "voyce.user_blocks" */
	delete_voyce_user_blocks?: GraphQLTypes["voyce_user_blocks_mutation_response"],
	/** delete single row from the table: "voyce.user_blocks" */
	delete_voyce_user_blocks_by_pk?: GraphQLTypes["voyce_user_blocks"],
	/** delete data from the table: "voyce.user_chapters_unlocked" */
	delete_voyce_user_chapters_unlocked?: GraphQLTypes["voyce_user_chapters_unlocked_mutation_response"],
	/** delete single row from the table: "voyce.user_chapters_unlocked" */
	delete_voyce_user_chapters_unlocked_by_pk?: GraphQLTypes["voyce_user_chapters_unlocked"],
	/** delete data from the table: "voyce.user_contacts" */
	delete_voyce_user_contacts?: GraphQLTypes["voyce_user_contacts_mutation_response"],
	/** delete single row from the table: "voyce.user_contacts" */
	delete_voyce_user_contacts_by_pk?: GraphQLTypes["voyce_user_contacts"],
	/** delete data from the table: "voyce.user_csat_ratings" */
	delete_voyce_user_csat_ratings?: GraphQLTypes["voyce_user_csat_ratings_mutation_response"],
	/** delete single row from the table: "voyce.user_csat_ratings" */
	delete_voyce_user_csat_ratings_by_pk?: GraphQLTypes["voyce_user_csat_ratings"],
	/** delete data from the table: "voyce.user_education" */
	delete_voyce_user_education?: GraphQLTypes["voyce_user_education_mutation_response"],
	/** delete single row from the table: "voyce.user_education" */
	delete_voyce_user_education_by_pk?: GraphQLTypes["voyce_user_education"],
	/** delete data from the table: "voyce.user_level" */
	delete_voyce_user_level?: GraphQLTypes["voyce_user_level_mutation_response"],
	/** delete single row from the table: "voyce.user_level" */
	delete_voyce_user_level_by_pk?: GraphQLTypes["voyce_user_level"],
	/** delete data from the table: "voyce.user_linked_accounts" */
	delete_voyce_user_linked_accounts?: GraphQLTypes["voyce_user_linked_accounts_mutation_response"],
	/** delete single row from the table: "voyce.user_linked_accounts" */
	delete_voyce_user_linked_accounts_by_pk?: GraphQLTypes["voyce_user_linked_accounts"],
	/** delete data from the table: "voyce.user_login_history" */
	delete_voyce_user_login_history?: GraphQLTypes["voyce_user_login_history_mutation_response"],
	/** delete single row from the table: "voyce.user_login_history" */
	delete_voyce_user_login_history_by_pk?: GraphQLTypes["voyce_user_login_history"],
	/** delete data from the table: "voyce.user_quest_ids" */
	delete_voyce_user_quest_ids?: GraphQLTypes["voyce_user_quest_ids_mutation_response"],
	/** delete single row from the table: "voyce.user_quest_ids" */
	delete_voyce_user_quest_ids_by_pk?: GraphQLTypes["voyce_user_quest_ids"],
	/** delete data from the table: "voyce.user_quest_rewards" */
	delete_voyce_user_quest_rewards?: GraphQLTypes["voyce_user_quest_rewards_mutation_response"],
	/** delete single row from the table: "voyce.user_quest_rewards" */
	delete_voyce_user_quest_rewards_by_pk?: GraphQLTypes["voyce_user_quest_rewards"],
	/** delete data from the table: "voyce.user_quests" */
	delete_voyce_user_quests?: GraphQLTypes["voyce_user_quests_mutation_response"],
	/** delete single row from the table: "voyce.user_quests" */
	delete_voyce_user_quests_by_pk?: GraphQLTypes["voyce_user_quests"],
	/** delete data from the table: "voyce.user_quests_old" */
	delete_voyce_user_quests_old?: GraphQLTypes["voyce_user_quests_old_mutation_response"],
	/** delete single row from the table: "voyce.user_quests_old" */
	delete_voyce_user_quests_old_by_pk?: GraphQLTypes["voyce_user_quests_old"],
	/** delete data from the table: "voyce.user_ranking" */
	delete_voyce_user_ranking?: GraphQLTypes["voyce_user_ranking_mutation_response"],
	/** delete single row from the table: "voyce.user_ranking" */
	delete_voyce_user_ranking_by_pk?: GraphQLTypes["voyce_user_ranking"],
	/** delete data from the table: "voyce.user_reviews" */
	delete_voyce_user_reviews?: GraphQLTypes["voyce_user_reviews_mutation_response"],
	/** delete single row from the table: "voyce.user_reviews" */
	delete_voyce_user_reviews_by_pk?: GraphQLTypes["voyce_user_reviews"],
	/** delete data from the table: "voyce.user_rewards" */
	delete_voyce_user_rewards?: GraphQLTypes["voyce_user_rewards_mutation_response"],
	/** delete single row from the table: "voyce.user_rewards" */
	delete_voyce_user_rewards_by_pk?: GraphQLTypes["voyce_user_rewards"],
	/** delete data from the table: "voyce.user_roles" */
	delete_voyce_user_roles?: GraphQLTypes["voyce_user_roles_mutation_response"],
	/** delete single row from the table: "voyce.user_roles" */
	delete_voyce_user_roles_by_pk?: GraphQLTypes["voyce_user_roles"],
	/** delete data from the table: "voyce.user_settings" */
	delete_voyce_user_settings?: GraphQLTypes["voyce_user_settings_mutation_response"],
	/** delete single row from the table: "voyce.user_settings" */
	delete_voyce_user_settings_by_pk?: GraphQLTypes["voyce_user_settings"],
	/** delete data from the table: "voyce.user_skills" */
	delete_voyce_user_skills?: GraphQLTypes["voyce_user_skills_mutation_response"],
	/** delete single row from the table: "voyce.user_skills" */
	delete_voyce_user_skills_by_pk?: GraphQLTypes["voyce_user_skills"],
	/** delete data from the table: "voyce.user_socials" */
	delete_voyce_user_socials?: GraphQLTypes["voyce_user_socials_mutation_response"],
	/** delete single row from the table: "voyce.user_socials" */
	delete_voyce_user_socials_by_pk?: GraphQLTypes["voyce_user_socials"],
	/** delete data from the table: "voyce.user_soft_deleted" */
	delete_voyce_user_soft_deleted?: GraphQLTypes["voyce_user_soft_deleted_mutation_response"],
	/** delete single row from the table: "voyce.user_soft_deleted" */
	delete_voyce_user_soft_deleted_by_pk?: GraphQLTypes["voyce_user_soft_deleted"],
	/** delete data from the table: "voyce.user_vtags" */
	delete_voyce_user_vtags?: GraphQLTypes["voyce_user_vtags_mutation_response"],
	/** delete single row from the table: "voyce.user_vtags" */
	delete_voyce_user_vtags_by_pk?: GraphQLTypes["voyce_user_vtags"],
	/** delete data from the table: "voyce.user_wins" */
	delete_voyce_user_wins?: GraphQLTypes["voyce_user_wins_mutation_response"],
	/** delete single row from the table: "voyce.user_wins" */
	delete_voyce_user_wins_by_pk?: GraphQLTypes["voyce_user_wins"],
	/** delete data from the table: "voyce.users" */
	delete_voyce_users?: GraphQLTypes["voyce_users_mutation_response"],
	/** delete single row from the table: "voyce.users" */
	delete_voyce_users_by_pk?: GraphQLTypes["voyce_users"],
	/** delete data from the table: "voyce.users_followers" */
	delete_voyce_users_followers?: GraphQLTypes["voyce_users_followers_mutation_response"],
	/** delete single row from the table: "voyce.users_followers" */
	delete_voyce_users_followers_by_pk?: GraphQLTypes["voyce_users_followers"],
	/** delete data from the table: "voyce.users_private" */
	delete_voyce_users_private?: GraphQLTypes["voyce_users_private_mutation_response"],
	/** delete data from the table: "voyce.users_series_junction" */
	delete_voyce_users_series_junction?: GraphQLTypes["voyce_users_series_junction_mutation_response"],
	/** delete single row from the table: "voyce.users_series_junction" */
	delete_voyce_users_series_junction_by_pk?: GraphQLTypes["voyce_users_series_junction"],
	/** delete data from the table: "voyce.versions" */
	delete_voyce_versions?: GraphQLTypes["voyce_versions_mutation_response"],
	/** delete single row from the table: "voyce.versions" */
	delete_voyce_versions_by_pk?: GraphQLTypes["voyce_versions"],
	/** delete data from the table: "voyce.voting_analytics" */
	delete_voyce_voting_analytics?: GraphQLTypes["voyce_voting_analytics_mutation_response"],
	/** delete single row from the table: "voyce.voting_analytics" */
	delete_voyce_voting_analytics_by_pk?: GraphQLTypes["voyce_voting_analytics"],
	/** delete data from the table: "voyce.voting_panels" */
	delete_voyce_voting_panels?: GraphQLTypes["voyce_voting_panels_mutation_response"],
	/** delete single row from the table: "voyce.voting_panels" */
	delete_voyce_voting_panels_by_pk?: GraphQLTypes["voyce_voting_panels"],
	/** delete data from the table: "voyce.voting_series" */
	delete_voyce_voting_series?: GraphQLTypes["voyce_voting_series_mutation_response"],
	/** delete single row from the table: "voyce.voting_series" */
	delete_voyce_voting_series_by_pk?: GraphQLTypes["voyce_voting_series"],
	/** delete data from the table: "voyce.voting_user_votes" */
	delete_voyce_voting_user_votes?: GraphQLTypes["voyce_voting_user_votes_mutation_response"],
	/** delete single row from the table: "voyce.voting_user_votes" */
	delete_voyce_voting_user_votes_by_pk?: GraphQLTypes["voyce_voting_user_votes"],
	/** insert data into the table: "awsdms_validation_failures_v1" */
	insert_awsdms_validation_failures_v1?: GraphQLTypes["awsdms_validation_failures_v1_mutation_response"],
	/** insert a single row into the table: "awsdms_validation_failures_v1" */
	insert_awsdms_validation_failures_v1_one?: GraphQLTypes["awsdms_validation_failures_v1"],
	/** insert data into the table: "coinsystem.balances" */
	insert_coinsystem_balances?: GraphQLTypes["coinsystem_balances_mutation_response"],
	/** insert a single row into the table: "coinsystem.balances" */
	insert_coinsystem_balances_one?: GraphQLTypes["coinsystem_balances"],
	/** insert data into the table: "coinsystem.bonus_coins" */
	insert_coinsystem_bonus_coins?: GraphQLTypes["coinsystem_bonus_coins_mutation_response"],
	/** insert a single row into the table: "coinsystem.bonus_coins" */
	insert_coinsystem_bonus_coins_one?: GraphQLTypes["coinsystem_bonus_coins"],
	/** insert data into the table: "coinsystem.coins" */
	insert_coinsystem_coins?: GraphQLTypes["coinsystem_coins_mutation_response"],
	/** insert a single row into the table: "coinsystem.coins" */
	insert_coinsystem_coins_one?: GraphQLTypes["coinsystem_coins"],
	/** insert data into the table: "coinsystem.exchange_rates" */
	insert_coinsystem_exchange_rates?: GraphQLTypes["coinsystem_exchange_rates_mutation_response"],
	/** insert a single row into the table: "coinsystem.exchange_rates" */
	insert_coinsystem_exchange_rates_one?: GraphQLTypes["coinsystem_exchange_rates"],
	/** insert data into the table: "coinsystem.logs" */
	insert_coinsystem_logs?: GraphQLTypes["coinsystem_logs_mutation_response"],
	/** insert a single row into the table: "coinsystem.logs" */
	insert_coinsystem_logs_one?: GraphQLTypes["coinsystem_logs"],
	/** insert data into the table: "coinsystem.transactions" */
	insert_coinsystem_transactions?: GraphQLTypes["coinsystem_transactions_mutation_response"],
	/** insert a single row into the table: "coinsystem.transactions" */
	insert_coinsystem_transactions_one?: GraphQLTypes["coinsystem_transactions"],
	/** insert data into the table: "coinsystem.wallets" */
	insert_coinsystem_wallets?: GraphQLTypes["coinsystem_wallets_mutation_response"],
	/** insert a single row into the table: "coinsystem.wallets" */
	insert_coinsystem_wallets_one?: GraphQLTypes["coinsystem_wallets"],
	/** insert data into the table: "mysql.user" */
	insert_mysql_user?: GraphQLTypes["mysql_user_mutation_response"],
	/** insert a single row into the table: "mysql.user" */
	insert_mysql_user_one?: GraphQLTypes["mysql_user"],
	/** insert data into the table: "store.payments" */
	insert_store_payments?: GraphQLTypes["store_payments_mutation_response"],
	/** insert a single row into the table: "store.payments" */
	insert_store_payments_one?: GraphQLTypes["store_payments"],
	/** insert data into the table: "store.products" */
	insert_store_products?: GraphQLTypes["store_products_mutation_response"],
	/** insert a single row into the table: "store.products" */
	insert_store_products_one?: GraphQLTypes["store_products"],
	/** insert data into the table: "store.purchases" */
	insert_store_purchases?: GraphQLTypes["store_purchases_mutation_response"],
	/** insert a single row into the table: "store.purchases" */
	insert_store_purchases_one?: GraphQLTypes["store_purchases"],
	/** insert data into the table: "store.users" */
	insert_store_users?: GraphQLTypes["store_users_mutation_response"],
	/** insert a single row into the table: "store.users" */
	insert_store_users_one?: GraphQLTypes["store_users"],
	/** insert data into the table: "voyce.admin_roles" */
	insert_voyce_admin_roles?: GraphQLTypes["voyce_admin_roles_mutation_response"],
	/** insert a single row into the table: "voyce.admin_roles" */
	insert_voyce_admin_roles_one?: GraphQLTypes["voyce_admin_roles"],
	/** insert data into the table: "voyce.app_banners" */
	insert_voyce_app_banners?: GraphQLTypes["voyce_app_banners_mutation_response"],
	/** insert a single row into the table: "voyce.app_banners" */
	insert_voyce_app_banners_one?: GraphQLTypes["voyce_app_banners"],
	/** insert data into the table: "voyce.app_components" */
	insert_voyce_app_components?: GraphQLTypes["voyce_app_components_mutation_response"],
	/** insert a single row into the table: "voyce.app_components" */
	insert_voyce_app_components_one?: GraphQLTypes["voyce_app_components"],
	/** insert data into the table: "voyce.audit_trail" */
	insert_voyce_audit_trail?: GraphQLTypes["voyce_audit_trail_mutation_response"],
	/** insert a single row into the table: "voyce.audit_trail" */
	insert_voyce_audit_trail_one?: GraphQLTypes["voyce_audit_trail"],
	/** insert data into the table: "voyce.author_profiles" */
	insert_voyce_author_profiles?: GraphQLTypes["voyce_author_profiles_mutation_response"],
	/** insert a single row into the table: "voyce.author_profiles" */
	insert_voyce_author_profiles_one?: GraphQLTypes["voyce_author_profiles"],
	/** insert data into the table: "voyce.balances" */
	insert_voyce_balances?: GraphQLTypes["voyce_balances_mutation_response"],
	/** insert a single row into the table: "voyce.balances" */
	insert_voyce_balances_one?: GraphQLTypes["voyce_balances"],
	/** insert data into the table: "voyce.banners" */
	insert_voyce_banners?: GraphQLTypes["voyce_banners_mutation_response"],
	/** insert a single row into the table: "voyce.banners" */
	insert_voyce_banners_one?: GraphQLTypes["voyce_banners"],
	/** insert data into the table: "voyce.chapter_assets" */
	insert_voyce_chapter_assets?: GraphQLTypes["voyce_chapter_assets_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_assets" */
	insert_voyce_chapter_assets_one?: GraphQLTypes["voyce_chapter_assets"],
	/** insert data into the table: "voyce.chapter_bookmarks" */
	insert_voyce_chapter_bookmarks?: GraphQLTypes["voyce_chapter_bookmarks_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_bookmarks" */
	insert_voyce_chapter_bookmarks_one?: GraphQLTypes["voyce_chapter_bookmarks"],
	/** insert data into the table: "voyce.chapter_comments" */
	insert_voyce_chapter_comments?: GraphQLTypes["voyce_chapter_comments_mutation_response"],
	/** insert data into the table: "voyce.chapter_comments_07252024" */
	insert_voyce_chapter_comments_07252024?: GraphQLTypes["voyce_chapter_comments_07252024_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_comments_07252024" */
	insert_voyce_chapter_comments_07252024_one?: GraphQLTypes["voyce_chapter_comments_07252024"],
	/** insert a single row into the table: "voyce.chapter_comments" */
	insert_voyce_chapter_comments_one?: GraphQLTypes["voyce_chapter_comments"],
	/** insert data into the table: "voyce.chapter_comments_reaction" */
	insert_voyce_chapter_comments_reaction?: GraphQLTypes["voyce_chapter_comments_reaction_mutation_response"],
	/** insert data into the table: "voyce.chapter_comments_reaction_count" */
	insert_voyce_chapter_comments_reaction_count?: GraphQLTypes["voyce_chapter_comments_reaction_count_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_comments_reaction_count" */
	insert_voyce_chapter_comments_reaction_count_one?: GraphQLTypes["voyce_chapter_comments_reaction_count"],
	/** insert a single row into the table: "voyce.chapter_comments_reaction" */
	insert_voyce_chapter_comments_reaction_one?: GraphQLTypes["voyce_chapter_comments_reaction"],
	/** insert data into the table: "voyce.chapter_comments_reports" */
	insert_voyce_chapter_comments_reports?: GraphQLTypes["voyce_chapter_comments_reports_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_comments_reports" */
	insert_voyce_chapter_comments_reports_one?: GraphQLTypes["voyce_chapter_comments_reports"],
	/** insert data into the table: "voyce.chapter_contents" */
	insert_voyce_chapter_contents?: GraphQLTypes["voyce_chapter_contents_mutation_response"],
	/** insert data into the table: "voyce.chapter_contents_old" */
	insert_voyce_chapter_contents_old?: GraphQLTypes["voyce_chapter_contents_old_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_contents_old" */
	insert_voyce_chapter_contents_old_one?: GraphQLTypes["voyce_chapter_contents_old"],
	/** insert a single row into the table: "voyce.chapter_contents" */
	insert_voyce_chapter_contents_one?: GraphQLTypes["voyce_chapter_contents"],
	/** insert data into the table: "voyce.chapter_effects" */
	insert_voyce_chapter_effects?: GraphQLTypes["voyce_chapter_effects_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_effects" */
	insert_voyce_chapter_effects_one?: GraphQLTypes["voyce_chapter_effects"],
	/** insert data into the table: "voyce.chapter_images" */
	insert_voyce_chapter_images?: GraphQLTypes["voyce_chapter_images_mutation_response"],
	/** insert data into the table: "voyce.chapter_images_07252024" */
	insert_voyce_chapter_images_07252024?: GraphQLTypes["voyce_chapter_images_07252024_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_images_07252024" */
	insert_voyce_chapter_images_07252024_one?: GraphQLTypes["voyce_chapter_images_07252024"],
	/** insert a single row into the table: "voyce.chapter_images" */
	insert_voyce_chapter_images_one?: GraphQLTypes["voyce_chapter_images"],
	/** insert data into the table: "voyce.chapter_likes" */
	insert_voyce_chapter_likes?: GraphQLTypes["voyce_chapter_likes_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_likes" */
	insert_voyce_chapter_likes_one?: GraphQLTypes["voyce_chapter_likes"],
	/** insert data into the table: "voyce.chapter_locks" */
	insert_voyce_chapter_locks?: GraphQLTypes["voyce_chapter_locks_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_locks" */
	insert_voyce_chapter_locks_one?: GraphQLTypes["voyce_chapter_locks"],
	/** insert data into the table: "voyce.chapter_panel_views" */
	insert_voyce_chapter_panel_views?: GraphQLTypes["voyce_chapter_panel_views_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_panel_views" */
	insert_voyce_chapter_panel_views_one?: GraphQLTypes["voyce_chapter_panel_views"],
	/** insert data into the table: "voyce.chapter_panels" */
	insert_voyce_chapter_panels?: GraphQLTypes["voyce_chapter_panels_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_panels" */
	insert_voyce_chapter_panels_one?: GraphQLTypes["voyce_chapter_panels"],
	/** insert data into the table: "voyce.chapter_publish_schedules" */
	insert_voyce_chapter_publish_schedules?: GraphQLTypes["voyce_chapter_publish_schedules_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_publish_schedules" */
	insert_voyce_chapter_publish_schedules_one?: GraphQLTypes["voyce_chapter_publish_schedules"],
	/** insert data into the table: "voyce.chapter_reactions" */
	insert_voyce_chapter_reactions?: GraphQLTypes["voyce_chapter_reactions_mutation_response"],
	/** insert data into the table: "voyce.chapter_reactions_count" */
	insert_voyce_chapter_reactions_count?: GraphQLTypes["voyce_chapter_reactions_count_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_reactions_count" */
	insert_voyce_chapter_reactions_count_one?: GraphQLTypes["voyce_chapter_reactions_count"],
	/** insert a single row into the table: "voyce.chapter_reactions" */
	insert_voyce_chapter_reactions_one?: GraphQLTypes["voyce_chapter_reactions"],
	/** insert data into the table: "voyce.chapter_view_logs" */
	insert_voyce_chapter_view_logs?: GraphQLTypes["voyce_chapter_view_logs_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_view_logs" */
	insert_voyce_chapter_view_logs_one?: GraphQLTypes["voyce_chapter_view_logs"],
	/** insert data into the table: "voyce.chapter_views" */
	insert_voyce_chapter_views?: GraphQLTypes["voyce_chapter_views_mutation_response"],
	/** insert a single row into the table: "voyce.chapter_views" */
	insert_voyce_chapter_views_one?: GraphQLTypes["voyce_chapter_views"],
	/** insert data into the table: "voyce.chapters" */
	insert_voyce_chapters?: GraphQLTypes["voyce_chapters_mutation_response"],
	/** insert data into the table: "voyce.chapters_07252024" */
	insert_voyce_chapters_07252024?: GraphQLTypes["voyce_chapters_07252024_mutation_response"],
	/** insert a single row into the table: "voyce.chapters_07252024" */
	insert_voyce_chapters_07252024_one?: GraphQLTypes["voyce_chapters_07252024"],
	/** insert a single row into the table: "voyce.chapters" */
	insert_voyce_chapters_one?: GraphQLTypes["voyce_chapters"],
	/** insert data into the table: "voyce.collaborator_roles" */
	insert_voyce_collaborator_roles?: GraphQLTypes["voyce_collaborator_roles_mutation_response"],
	/** insert a single row into the table: "voyce.collaborator_roles" */
	insert_voyce_collaborator_roles_one?: GraphQLTypes["voyce_collaborator_roles"],
	/** insert data into the table: "voyce.comic_types" */
	insert_voyce_comic_types?: GraphQLTypes["voyce_comic_types_mutation_response"],
	/** insert a single row into the table: "voyce.comic_types" */
	insert_voyce_comic_types_one?: GraphQLTypes["voyce_comic_types"],
	/** insert data into the table: "voyce.community_blurbs" */
	insert_voyce_community_blurbs?: GraphQLTypes["voyce_community_blurbs_mutation_response"],
	/** insert a single row into the table: "voyce.community_blurbs" */
	insert_voyce_community_blurbs_one?: GraphQLTypes["voyce_community_blurbs"],
	/** insert data into the table: "voyce.contact_us" */
	insert_voyce_contact_us?: GraphQLTypes["voyce_contact_us_mutation_response"],
	/** insert a single row into the table: "voyce.contact_us" */
	insert_voyce_contact_us_one?: GraphQLTypes["voyce_contact_us"],
	/** insert data into the table: "voyce.content_report" */
	insert_voyce_content_report?: GraphQLTypes["voyce_content_report_mutation_response"],
	/** insert a single row into the table: "voyce.content_report" */
	insert_voyce_content_report_one?: GraphQLTypes["voyce_content_report"],
	/** insert data into the table: "voyce.contest_data" */
	insert_voyce_contest_data?: GraphQLTypes["voyce_contest_data_mutation_response"],
	/** insert a single row into the table: "voyce.contest_data" */
	insert_voyce_contest_data_one?: GraphQLTypes["voyce_contest_data"],
	/** insert data into the table: "voyce.countries" */
	insert_voyce_countries?: GraphQLTypes["voyce_countries_mutation_response"],
	/** insert a single row into the table: "voyce.countries" */
	insert_voyce_countries_one?: GraphQLTypes["voyce_countries"],
	/** insert data into the table: "voyce.csat_ratings" */
	insert_voyce_csat_ratings?: GraphQLTypes["voyce_csat_ratings_mutation_response"],
	/** insert data into the table: "voyce.csat_ratings_data" */
	insert_voyce_csat_ratings_data?: GraphQLTypes["voyce_csat_ratings_data_mutation_response"],
	/** insert a single row into the table: "voyce.csat_ratings_data" */
	insert_voyce_csat_ratings_data_one?: GraphQLTypes["voyce_csat_ratings_data"],
	/** insert a single row into the table: "voyce.csat_ratings" */
	insert_voyce_csat_ratings_one?: GraphQLTypes["voyce_csat_ratings"],
	/** insert data into the table: "voyce.education_types" */
	insert_voyce_education_types?: GraphQLTypes["voyce_education_types_mutation_response"],
	/** insert a single row into the table: "voyce.education_types" */
	insert_voyce_education_types_one?: GraphQLTypes["voyce_education_types"],
	/** insert data into the table: "voyce.erp_login_roles" */
	insert_voyce_erp_login_roles?: GraphQLTypes["voyce_erp_login_roles_mutation_response"],
	/** insert a single row into the table: "voyce.erp_login_roles" */
	insert_voyce_erp_login_roles_one?: GraphQLTypes["voyce_erp_login_roles"],
	/** insert data into the table: "voyce.etl_series_views_logs" */
	insert_voyce_etl_series_views_logs?: GraphQLTypes["voyce_etl_series_views_logs_mutation_response"],
	/** insert a single row into the table: "voyce.etl_series_views_logs" */
	insert_voyce_etl_series_views_logs_one?: GraphQLTypes["voyce_etl_series_views_logs"],
	/** insert data into the table: "voyce.event_schedules" */
	insert_voyce_event_schedules?: GraphQLTypes["voyce_event_schedules_mutation_response"],
	/** insert a single row into the table: "voyce.event_schedules" */
	insert_voyce_event_schedules_one?: GraphQLTypes["voyce_event_schedules"],
	/** insert data into the table: "voyce.genres" */
	insert_voyce_genres?: GraphQLTypes["voyce_genres_mutation_response"],
	/** insert a single row into the table: "voyce.genres" */
	insert_voyce_genres_one?: GraphQLTypes["voyce_genres"],
	/** insert data into the table: "voyce.guest_gtags" */
	insert_voyce_guest_gtags?: GraphQLTypes["voyce_guest_gtags_mutation_response"],
	/** insert a single row into the table: "voyce.guest_gtags" */
	insert_voyce_guest_gtags_one?: GraphQLTypes["voyce_guest_gtags"],
	/** insert data into the table: "voyce.metabases" */
	insert_voyce_metabases?: GraphQLTypes["voyce_metabases_mutation_response"],
	/** insert data into the table: "voyce.metabases_access" */
	insert_voyce_metabases_access?: GraphQLTypes["voyce_metabases_access_mutation_response"],
	/** insert a single row into the table: "voyce.metabases_access" */
	insert_voyce_metabases_access_one?: GraphQLTypes["voyce_metabases_access"],
	/** insert a single row into the table: "voyce.metabases" */
	insert_voyce_metabases_one?: GraphQLTypes["voyce_metabases"],
	/** insert data into the table: "voyce.newsletter" */
	insert_voyce_newsletter?: GraphQLTypes["voyce_newsletter_mutation_response"],
	/** insert a single row into the table: "voyce.newsletter" */
	insert_voyce_newsletter_one?: GraphQLTypes["voyce_newsletter"],
	/** insert data into the table: "voyce.notification_series_settings" */
	insert_voyce_notification_series_settings?: GraphQLTypes["voyce_notification_series_settings_mutation_response"],
	/** insert a single row into the table: "voyce.notification_series_settings" */
	insert_voyce_notification_series_settings_one?: GraphQLTypes["voyce_notification_series_settings"],
	/** insert data into the table: "voyce.notification_settings" */
	insert_voyce_notification_settings?: GraphQLTypes["voyce_notification_settings_mutation_response"],
	/** insert a single row into the table: "voyce.notification_settings" */
	insert_voyce_notification_settings_one?: GraphQLTypes["voyce_notification_settings"],
	/** insert data into the table: "voyce.notifications" */
	insert_voyce_notifications?: GraphQLTypes["voyce_notifications_mutation_response"],
	/** insert a single row into the table: "voyce.notifications" */
	insert_voyce_notifications_one?: GraphQLTypes["voyce_notifications"],
	/** insert data into the table: "voyce.page_banners" */
	insert_voyce_page_banners?: GraphQLTypes["voyce_page_banners_mutation_response"],
	/** insert a single row into the table: "voyce.page_banners" */
	insert_voyce_page_banners_one?: GraphQLTypes["voyce_page_banners"],
	/** insert data into the table: "voyce.page_components" */
	insert_voyce_page_components?: GraphQLTypes["voyce_page_components_mutation_response"],
	/** insert a single row into the table: "voyce.page_components" */
	insert_voyce_page_components_one?: GraphQLTypes["voyce_page_components"],
	/** insert data into the table: "voyce.pages" */
	insert_voyce_pages?: GraphQLTypes["voyce_pages_mutation_response"],
	/** insert a single row into the table: "voyce.pages" */
	insert_voyce_pages_one?: GraphQLTypes["voyce_pages"],
	/** insert data into the table: "voyce.panels_comments" */
	insert_voyce_panels_comments?: GraphQLTypes["voyce_panels_comments_mutation_response"],
	/** insert a single row into the table: "voyce.panels_comments" */
	insert_voyce_panels_comments_one?: GraphQLTypes["voyce_panels_comments"],
	/** insert data into the table: "voyce.panels_reactions" */
	insert_voyce_panels_reactions?: GraphQLTypes["voyce_panels_reactions_mutation_response"],
	/** insert data into the table: "voyce.panels_reactions_count" */
	insert_voyce_panels_reactions_count?: GraphQLTypes["voyce_panels_reactions_count_mutation_response"],
	/** insert a single row into the table: "voyce.panels_reactions_count" */
	insert_voyce_panels_reactions_count_one?: GraphQLTypes["voyce_panels_reactions_count"],
	/** insert a single row into the table: "voyce.panels_reactions" */
	insert_voyce_panels_reactions_one?: GraphQLTypes["voyce_panels_reactions"],
	/** insert data into the table: "voyce.payments_to_creators" */
	insert_voyce_payments_to_creators?: GraphQLTypes["voyce_payments_to_creators_mutation_response"],
	/** insert a single row into the table: "voyce.payments_to_creators" */
	insert_voyce_payments_to_creators_one?: GraphQLTypes["voyce_payments_to_creators"],
	/** insert data into the table: "voyce.pg_rs_series_views_count" */
	insert_voyce_pg_rs_series_views_count?: GraphQLTypes["voyce_pg_rs_series_views_count_mutation_response"],
	/** insert a single row into the table: "voyce.pg_rs_series_views_count" */
	insert_voyce_pg_rs_series_views_count_one?: GraphQLTypes["voyce_pg_rs_series_views_count"],
	/** insert data into the table: "voyce.polls" */
	insert_voyce_polls?: GraphQLTypes["voyce_polls_mutation_response"],
	/** insert data into the table: "voyce.polls_answers" */
	insert_voyce_polls_answers?: GraphQLTypes["voyce_polls_answers_mutation_response"],
	/** insert a single row into the table: "voyce.polls_answers" */
	insert_voyce_polls_answers_one?: GraphQLTypes["voyce_polls_answers"],
	/** insert data into the table: "voyce.polls_comments" */
	insert_voyce_polls_comments?: GraphQLTypes["voyce_polls_comments_mutation_response"],
	/** insert a single row into the table: "voyce.polls_comments" */
	insert_voyce_polls_comments_one?: GraphQLTypes["voyce_polls_comments"],
	/** insert a single row into the table: "voyce.polls" */
	insert_voyce_polls_one?: GraphQLTypes["voyce_polls"],
	/** insert data into the table: "voyce.polls_reactions" */
	insert_voyce_polls_reactions?: GraphQLTypes["voyce_polls_reactions_mutation_response"],
	/** insert a single row into the table: "voyce.polls_reactions" */
	insert_voyce_polls_reactions_one?: GraphQLTypes["voyce_polls_reactions"],
	/** insert data into the table: "voyce.polls_votes" */
	insert_voyce_polls_votes?: GraphQLTypes["voyce_polls_votes_mutation_response"],
	/** insert a single row into the table: "voyce.polls_votes" */
	insert_voyce_polls_votes_one?: GraphQLTypes["voyce_polls_votes"],
	/** insert data into the table: "voyce.popup_components" */
	insert_voyce_popup_components?: GraphQLTypes["voyce_popup_components_mutation_response"],
	/** insert a single row into the table: "voyce.popup_components" */
	insert_voyce_popup_components_one?: GraphQLTypes["voyce_popup_components"],
	/** insert data into the table: "voyce.popup_emails" */
	insert_voyce_popup_emails?: GraphQLTypes["voyce_popup_emails_mutation_response"],
	/** insert a single row into the table: "voyce.popup_emails" */
	insert_voyce_popup_emails_one?: GraphQLTypes["voyce_popup_emails"],
	/** insert data into the table: "voyce.posts" */
	insert_voyce_posts?: GraphQLTypes["voyce_posts_mutation_response"],
	/** insert data into the table: "voyce.posts_comments" */
	insert_voyce_posts_comments?: GraphQLTypes["voyce_posts_comments_mutation_response"],
	/** insert a single row into the table: "voyce.posts_comments" */
	insert_voyce_posts_comments_one?: GraphQLTypes["voyce_posts_comments"],
	/** insert a single row into the table: "voyce.posts" */
	insert_voyce_posts_one?: GraphQLTypes["voyce_posts"],
	/** insert data into the table: "voyce.posts_reactions" */
	insert_voyce_posts_reactions?: GraphQLTypes["voyce_posts_reactions_mutation_response"],
	/** insert a single row into the table: "voyce.posts_reactions" */
	insert_voyce_posts_reactions_one?: GraphQLTypes["voyce_posts_reactions"],
	/** insert data into the table: "voyce.quest_difficulties" */
	insert_voyce_quest_difficulties?: GraphQLTypes["voyce_quest_difficulties_mutation_response"],
	/** insert a single row into the table: "voyce.quest_difficulties" */
	insert_voyce_quest_difficulties_one?: GraphQLTypes["voyce_quest_difficulties"],
	/** insert data into the table: "voyce.quests" */
	insert_voyce_quests?: GraphQLTypes["voyce_quests_mutation_response"],
	/** insert data into the table: "voyce.quests_old" */
	insert_voyce_quests_old?: GraphQLTypes["voyce_quests_old_mutation_response"],
	/** insert a single row into the table: "voyce.quests_old" */
	insert_voyce_quests_old_one?: GraphQLTypes["voyce_quests_old"],
	/** insert a single row into the table: "voyce.quests" */
	insert_voyce_quests_one?: GraphQLTypes["voyce_quests"],
	/** insert data into the table: "voyce.recommended_series" */
	insert_voyce_recommended_series?: GraphQLTypes["voyce_recommended_series_mutation_response"],
	/** insert a single row into the table: "voyce.recommended_series" */
	insert_voyce_recommended_series_one?: GraphQLTypes["voyce_recommended_series"],
	/** insert data into the table: "voyce.release_schedules" */
	insert_voyce_release_schedules?: GraphQLTypes["voyce_release_schedules_mutation_response"],
	/** insert a single row into the table: "voyce.release_schedules" */
	insert_voyce_release_schedules_one?: GraphQLTypes["voyce_release_schedules"],
	/** insert data into the table: "voyce.rewards" */
	insert_voyce_rewards?: GraphQLTypes["voyce_rewards_mutation_response"],
	/** insert data into the table: "voyce.rewards_leaderboard" */
	insert_voyce_rewards_leaderboard?: GraphQLTypes["voyce_rewards_leaderboard_mutation_response"],
	/** insert a single row into the table: "voyce.rewards_leaderboard" */
	insert_voyce_rewards_leaderboard_one?: GraphQLTypes["voyce_rewards_leaderboard"],
	/** insert a single row into the table: "voyce.rewards" */
	insert_voyce_rewards_one?: GraphQLTypes["voyce_rewards"],
	/** insert data into the table: "voyce.rewards_quest" */
	insert_voyce_rewards_quest?: GraphQLTypes["voyce_rewards_quest_mutation_response"],
	/** insert a single row into the table: "voyce.rewards_quest" */
	insert_voyce_rewards_quest_one?: GraphQLTypes["voyce_rewards_quest"],
	/** insert data into the table: "voyce.sections" */
	insert_voyce_sections?: GraphQLTypes["voyce_sections_mutation_response"],
	/** insert a single row into the table: "voyce.sections" */
	insert_voyce_sections_one?: GraphQLTypes["voyce_sections"],
	/** insert data into the table: "voyce.series" */
	insert_voyce_series?: GraphQLTypes["voyce_series_mutation_response"],
	/** insert data into the table: "voyce.series_collaborators" */
	insert_voyce_series_collaborators?: GraphQLTypes["voyce_series_collaborators_mutation_response"],
	/** insert a single row into the table: "voyce.series_collaborators" */
	insert_voyce_series_collaborators_one?: GraphQLTypes["voyce_series_collaborators"],
	/** insert data into the table: "voyce.series_donations" */
	insert_voyce_series_donations?: GraphQLTypes["voyce_series_donations_mutation_response"],
	/** insert a single row into the table: "voyce.series_donations" */
	insert_voyce_series_donations_one?: GraphQLTypes["voyce_series_donations"],
	/** insert data into the table: "voyce.series_favorites" */
	insert_voyce_series_favorites?: GraphQLTypes["voyce_series_favorites_mutation_response"],
	/** insert a single row into the table: "voyce.series_favorites" */
	insert_voyce_series_favorites_one?: GraphQLTypes["voyce_series_favorites"],
	/** insert data into the table: "voyce.series_featured_panels" */
	insert_voyce_series_featured_panels?: GraphQLTypes["voyce_series_featured_panels_mutation_response"],
	/** insert a single row into the table: "voyce.series_featured_panels" */
	insert_voyce_series_featured_panels_one?: GraphQLTypes["voyce_series_featured_panels"],
	/** insert data into the table: "voyce.series_genre" */
	insert_voyce_series_genre?: GraphQLTypes["voyce_series_genre_mutation_response"],
	/** insert a single row into the table: "voyce.series_genre" */
	insert_voyce_series_genre_one?: GraphQLTypes["voyce_series_genre"],
	/** insert data into the table: "voyce.series_giveaway_participants" */
	insert_voyce_series_giveaway_participants?: GraphQLTypes["voyce_series_giveaway_participants_mutation_response"],
	/** insert a single row into the table: "voyce.series_giveaway_participants" */
	insert_voyce_series_giveaway_participants_one?: GraphQLTypes["voyce_series_giveaway_participants"],
	/** insert data into the table: "voyce.series_hot_panels" */
	insert_voyce_series_hot_panels?: GraphQLTypes["voyce_series_hot_panels_mutation_response"],
	/** insert a single row into the table: "voyce.series_hot_panels" */
	insert_voyce_series_hot_panels_one?: GraphQLTypes["voyce_series_hot_panels"],
	/** insert data into the table: "voyce.series_layouts" */
	insert_voyce_series_layouts?: GraphQLTypes["voyce_series_layouts_mutation_response"],
	/** insert a single row into the table: "voyce.series_layouts" */
	insert_voyce_series_layouts_one?: GraphQLTypes["voyce_series_layouts"],
	/** insert data into the table: "voyce.series_leaderboard" */
	insert_voyce_series_leaderboard?: GraphQLTypes["voyce_series_leaderboard_mutation_response"],
	/** insert a single row into the table: "voyce.series_leaderboard" */
	insert_voyce_series_leaderboard_one?: GraphQLTypes["voyce_series_leaderboard"],
	/** insert data into the table: "voyce.series_lock_config" */
	insert_voyce_series_lock_config?: GraphQLTypes["voyce_series_lock_config_mutation_response"],
	/** insert a single row into the table: "voyce.series_lock_config" */
	insert_voyce_series_lock_config_one?: GraphQLTypes["voyce_series_lock_config"],
	/** insert a single row into the table: "voyce.series" */
	insert_voyce_series_one?: GraphQLTypes["voyce_series"],
	/** insert data into the table: "voyce.series_sponsorships_configuration" */
	insert_voyce_series_sponsorships_configuration?: GraphQLTypes["voyce_series_sponsorships_configuration_mutation_response"],
	/** insert a single row into the table: "voyce.series_sponsorships_configuration" */
	insert_voyce_series_sponsorships_configuration_one?: GraphQLTypes["voyce_series_sponsorships_configuration"],
	/** insert data into the table: "voyce.series_subscription" */
	insert_voyce_series_subscription?: GraphQLTypes["voyce_series_subscription_mutation_response"],
	/** insert a single row into the table: "voyce.series_subscription" */
	insert_voyce_series_subscription_one?: GraphQLTypes["voyce_series_subscription"],
	/** insert data into the table: "voyce.series_tags" */
	insert_voyce_series_tags?: GraphQLTypes["voyce_series_tags_mutation_response"],
	/** insert data into the table: "voyce.series_tags_junction" */
	insert_voyce_series_tags_junction?: GraphQLTypes["voyce_series_tags_junction_mutation_response"],
	/** insert a single row into the table: "voyce.series_tags_junction" */
	insert_voyce_series_tags_junction_one?: GraphQLTypes["voyce_series_tags_junction"],
	/** insert a single row into the table: "voyce.series_tags" */
	insert_voyce_series_tags_one?: GraphQLTypes["voyce_series_tags"],
	/** insert data into the table: "voyce.series_top_comments" */
	insert_voyce_series_top_comments?: GraphQLTypes["voyce_series_top_comments_mutation_response"],
	/** insert a single row into the table: "voyce.series_top_comments" */
	insert_voyce_series_top_comments_one?: GraphQLTypes["voyce_series_top_comments"],
	/** insert data into the table: "voyce.series_top_likes" */
	insert_voyce_series_top_likes?: GraphQLTypes["voyce_series_top_likes_mutation_response"],
	/** insert a single row into the table: "voyce.series_top_likes" */
	insert_voyce_series_top_likes_one?: GraphQLTypes["voyce_series_top_likes"],
	/** insert data into the table: "voyce.series_top_views" */
	insert_voyce_series_top_views?: GraphQLTypes["voyce_series_top_views_mutation_response"],
	/** insert a single row into the table: "voyce.series_top_views" */
	insert_voyce_series_top_views_one?: GraphQLTypes["voyce_series_top_views"],
	/** insert data into the table: "voyce.series_views_adjustment" */
	insert_voyce_series_views_adjustment?: GraphQLTypes["voyce_series_views_adjustment_mutation_response"],
	/** insert a single row into the table: "voyce.series_views_adjustment" */
	insert_voyce_series_views_adjustment_one?: GraphQLTypes["voyce_series_views_adjustment"],
	/** insert data into the table: "voyce.series_votes" */
	insert_voyce_series_votes?: GraphQLTypes["voyce_series_votes_mutation_response"],
	/** insert data into the table: "voyce.series_votes_count" */
	insert_voyce_series_votes_count?: GraphQLTypes["voyce_series_votes_count_mutation_response"],
	/** insert a single row into the table: "voyce.series_votes_count" */
	insert_voyce_series_votes_count_one?: GraphQLTypes["voyce_series_votes_count"],
	/** insert a single row into the table: "voyce.series_votes" */
	insert_voyce_series_votes_one?: GraphQLTypes["voyce_series_votes"],
	/** insert data into the table: "voyce.series_voting_schedules" */
	insert_voyce_series_voting_schedules?: GraphQLTypes["voyce_series_voting_schedules_mutation_response"],
	/** insert a single row into the table: "voyce.series_voting_schedules" */
	insert_voyce_series_voting_schedules_one?: GraphQLTypes["voyce_series_voting_schedules"],
	/** insert data into the table: "voyce.sponsorships" */
	insert_voyce_sponsorships?: GraphQLTypes["voyce_sponsorships_mutation_response"],
	/** insert a single row into the table: "voyce.sponsorships" */
	insert_voyce_sponsorships_one?: GraphQLTypes["voyce_sponsorships"],
	/** insert data into the table: "voyce.states" */
	insert_voyce_states?: GraphQLTypes["voyce_states_mutation_response"],
	/** insert a single row into the table: "voyce.states" */
	insert_voyce_states_one?: GraphQLTypes["voyce_states"],
	/** insert data into the table: "voyce.stripe_payouts_setup" */
	insert_voyce_stripe_payouts_setup?: GraphQLTypes["voyce_stripe_payouts_setup_mutation_response"],
	/** insert a single row into the table: "voyce.stripe_payouts_setup" */
	insert_voyce_stripe_payouts_setup_one?: GraphQLTypes["voyce_stripe_payouts_setup"],
	/** insert data into the table: "voyce.studio" */
	insert_voyce_studio?: GraphQLTypes["voyce_studio_mutation_response"],
	/** insert data into the table: "voyce.studio_followers" */
	insert_voyce_studio_followers?: GraphQLTypes["voyce_studio_followers_mutation_response"],
	/** insert a single row into the table: "voyce.studio_followers" */
	insert_voyce_studio_followers_one?: GraphQLTypes["voyce_studio_followers"],
	/** insert a single row into the table: "voyce.studio" */
	insert_voyce_studio_one?: GraphQLTypes["voyce_studio"],
	/** insert data into the table: "voyce.studio_series" */
	insert_voyce_studio_series?: GraphQLTypes["voyce_studio_series_mutation_response"],
	/** insert a single row into the table: "voyce.studio_series" */
	insert_voyce_studio_series_one?: GraphQLTypes["voyce_studio_series"],
	/** insert data into the table: "voyce.studio_views" */
	insert_voyce_studio_views?: GraphQLTypes["voyce_studio_views_mutation_response"],
	/** insert a single row into the table: "voyce.studio_views" */
	insert_voyce_studio_views_one?: GraphQLTypes["voyce_studio_views"],
	/** insert data into the table: "voyce.system_settings" */
	insert_voyce_system_settings?: GraphQLTypes["voyce_system_settings_mutation_response"],
	/** insert a single row into the table: "voyce.system_settings" */
	insert_voyce_system_settings_one?: GraphQLTypes["voyce_system_settings"],
	/** insert data into the table: "voyce.transaction_logs" */
	insert_voyce_transaction_logs?: GraphQLTypes["voyce_transaction_logs_mutation_response"],
	/** insert a single row into the table: "voyce.transaction_logs" */
	insert_voyce_transaction_logs_one?: GraphQLTypes["voyce_transaction_logs"],
	/** insert data into the table: "voyce.url_redirects" */
	insert_voyce_url_redirects?: GraphQLTypes["voyce_url_redirects_mutation_response"],
	/** insert a single row into the table: "voyce.url_redirects" */
	insert_voyce_url_redirects_one?: GraphQLTypes["voyce_url_redirects"],
	/** insert data into the table: "voyce.user_addresses" */
	insert_voyce_user_addresses?: GraphQLTypes["voyce_user_addresses_mutation_response"],
	/** insert a single row into the table: "voyce.user_addresses" */
	insert_voyce_user_addresses_one?: GraphQLTypes["voyce_user_addresses"],
	/** insert data into the table: "voyce.user_blocks" */
	insert_voyce_user_blocks?: GraphQLTypes["voyce_user_blocks_mutation_response"],
	/** insert a single row into the table: "voyce.user_blocks" */
	insert_voyce_user_blocks_one?: GraphQLTypes["voyce_user_blocks"],
	/** insert data into the table: "voyce.user_chapters_unlocked" */
	insert_voyce_user_chapters_unlocked?: GraphQLTypes["voyce_user_chapters_unlocked_mutation_response"],
	/** insert a single row into the table: "voyce.user_chapters_unlocked" */
	insert_voyce_user_chapters_unlocked_one?: GraphQLTypes["voyce_user_chapters_unlocked"],
	/** insert data into the table: "voyce.user_contacts" */
	insert_voyce_user_contacts?: GraphQLTypes["voyce_user_contacts_mutation_response"],
	/** insert a single row into the table: "voyce.user_contacts" */
	insert_voyce_user_contacts_one?: GraphQLTypes["voyce_user_contacts"],
	/** insert data into the table: "voyce.user_csat_ratings" */
	insert_voyce_user_csat_ratings?: GraphQLTypes["voyce_user_csat_ratings_mutation_response"],
	/** insert a single row into the table: "voyce.user_csat_ratings" */
	insert_voyce_user_csat_ratings_one?: GraphQLTypes["voyce_user_csat_ratings"],
	/** insert data into the table: "voyce.user_education" */
	insert_voyce_user_education?: GraphQLTypes["voyce_user_education_mutation_response"],
	/** insert a single row into the table: "voyce.user_education" */
	insert_voyce_user_education_one?: GraphQLTypes["voyce_user_education"],
	/** insert data into the table: "voyce.user_level" */
	insert_voyce_user_level?: GraphQLTypes["voyce_user_level_mutation_response"],
	/** insert a single row into the table: "voyce.user_level" */
	insert_voyce_user_level_one?: GraphQLTypes["voyce_user_level"],
	/** insert data into the table: "voyce.user_linked_accounts" */
	insert_voyce_user_linked_accounts?: GraphQLTypes["voyce_user_linked_accounts_mutation_response"],
	/** insert a single row into the table: "voyce.user_linked_accounts" */
	insert_voyce_user_linked_accounts_one?: GraphQLTypes["voyce_user_linked_accounts"],
	/** insert data into the table: "voyce.user_login_history" */
	insert_voyce_user_login_history?: GraphQLTypes["voyce_user_login_history_mutation_response"],
	/** insert a single row into the table: "voyce.user_login_history" */
	insert_voyce_user_login_history_one?: GraphQLTypes["voyce_user_login_history"],
	/** insert data into the table: "voyce.user_quest_ids" */
	insert_voyce_user_quest_ids?: GraphQLTypes["voyce_user_quest_ids_mutation_response"],
	/** insert a single row into the table: "voyce.user_quest_ids" */
	insert_voyce_user_quest_ids_one?: GraphQLTypes["voyce_user_quest_ids"],
	/** insert data into the table: "voyce.user_quest_rewards" */
	insert_voyce_user_quest_rewards?: GraphQLTypes["voyce_user_quest_rewards_mutation_response"],
	/** insert a single row into the table: "voyce.user_quest_rewards" */
	insert_voyce_user_quest_rewards_one?: GraphQLTypes["voyce_user_quest_rewards"],
	/** insert data into the table: "voyce.user_quests" */
	insert_voyce_user_quests?: GraphQLTypes["voyce_user_quests_mutation_response"],
	/** insert data into the table: "voyce.user_quests_old" */
	insert_voyce_user_quests_old?: GraphQLTypes["voyce_user_quests_old_mutation_response"],
	/** insert a single row into the table: "voyce.user_quests_old" */
	insert_voyce_user_quests_old_one?: GraphQLTypes["voyce_user_quests_old"],
	/** insert a single row into the table: "voyce.user_quests" */
	insert_voyce_user_quests_one?: GraphQLTypes["voyce_user_quests"],
	/** insert data into the table: "voyce.user_ranking" */
	insert_voyce_user_ranking?: GraphQLTypes["voyce_user_ranking_mutation_response"],
	/** insert a single row into the table: "voyce.user_ranking" */
	insert_voyce_user_ranking_one?: GraphQLTypes["voyce_user_ranking"],
	/** insert data into the table: "voyce.user_reviews" */
	insert_voyce_user_reviews?: GraphQLTypes["voyce_user_reviews_mutation_response"],
	/** insert a single row into the table: "voyce.user_reviews" */
	insert_voyce_user_reviews_one?: GraphQLTypes["voyce_user_reviews"],
	/** insert data into the table: "voyce.user_rewards" */
	insert_voyce_user_rewards?: GraphQLTypes["voyce_user_rewards_mutation_response"],
	/** insert a single row into the table: "voyce.user_rewards" */
	insert_voyce_user_rewards_one?: GraphQLTypes["voyce_user_rewards"],
	/** insert data into the table: "voyce.user_roles" */
	insert_voyce_user_roles?: GraphQLTypes["voyce_user_roles_mutation_response"],
	/** insert a single row into the table: "voyce.user_roles" */
	insert_voyce_user_roles_one?: GraphQLTypes["voyce_user_roles"],
	/** insert data into the table: "voyce.user_settings" */
	insert_voyce_user_settings?: GraphQLTypes["voyce_user_settings_mutation_response"],
	/** insert a single row into the table: "voyce.user_settings" */
	insert_voyce_user_settings_one?: GraphQLTypes["voyce_user_settings"],
	/** insert data into the table: "voyce.user_skills" */
	insert_voyce_user_skills?: GraphQLTypes["voyce_user_skills_mutation_response"],
	/** insert a single row into the table: "voyce.user_skills" */
	insert_voyce_user_skills_one?: GraphQLTypes["voyce_user_skills"],
	/** insert data into the table: "voyce.user_socials" */
	insert_voyce_user_socials?: GraphQLTypes["voyce_user_socials_mutation_response"],
	/** insert a single row into the table: "voyce.user_socials" */
	insert_voyce_user_socials_one?: GraphQLTypes["voyce_user_socials"],
	/** insert data into the table: "voyce.user_soft_deleted" */
	insert_voyce_user_soft_deleted?: GraphQLTypes["voyce_user_soft_deleted_mutation_response"],
	/** insert a single row into the table: "voyce.user_soft_deleted" */
	insert_voyce_user_soft_deleted_one?: GraphQLTypes["voyce_user_soft_deleted"],
	/** insert data into the table: "voyce.user_vtags" */
	insert_voyce_user_vtags?: GraphQLTypes["voyce_user_vtags_mutation_response"],
	/** insert a single row into the table: "voyce.user_vtags" */
	insert_voyce_user_vtags_one?: GraphQLTypes["voyce_user_vtags"],
	/** insert data into the table: "voyce.user_wins" */
	insert_voyce_user_wins?: GraphQLTypes["voyce_user_wins_mutation_response"],
	/** insert a single row into the table: "voyce.user_wins" */
	insert_voyce_user_wins_one?: GraphQLTypes["voyce_user_wins"],
	/** insert data into the table: "voyce.users" */
	insert_voyce_users?: GraphQLTypes["voyce_users_mutation_response"],
	/** insert data into the table: "voyce.users_followers" */
	insert_voyce_users_followers?: GraphQLTypes["voyce_users_followers_mutation_response"],
	/** insert a single row into the table: "voyce.users_followers" */
	insert_voyce_users_followers_one?: GraphQLTypes["voyce_users_followers"],
	/** insert a single row into the table: "voyce.users" */
	insert_voyce_users_one?: GraphQLTypes["voyce_users"],
	/** insert data into the table: "voyce.users_private" */
	insert_voyce_users_private?: GraphQLTypes["voyce_users_private_mutation_response"],
	/** insert a single row into the table: "voyce.users_private" */
	insert_voyce_users_private_one?: GraphQLTypes["voyce_users_private"],
	/** insert data into the table: "voyce.users_series_junction" */
	insert_voyce_users_series_junction?: GraphQLTypes["voyce_users_series_junction_mutation_response"],
	/** insert a single row into the table: "voyce.users_series_junction" */
	insert_voyce_users_series_junction_one?: GraphQLTypes["voyce_users_series_junction"],
	/** insert data into the table: "voyce.versions" */
	insert_voyce_versions?: GraphQLTypes["voyce_versions_mutation_response"],
	/** insert a single row into the table: "voyce.versions" */
	insert_voyce_versions_one?: GraphQLTypes["voyce_versions"],
	/** insert data into the table: "voyce.voting_analytics" */
	insert_voyce_voting_analytics?: GraphQLTypes["voyce_voting_analytics_mutation_response"],
	/** insert a single row into the table: "voyce.voting_analytics" */
	insert_voyce_voting_analytics_one?: GraphQLTypes["voyce_voting_analytics"],
	/** insert data into the table: "voyce.voting_panels" */
	insert_voyce_voting_panels?: GraphQLTypes["voyce_voting_panels_mutation_response"],
	/** insert a single row into the table: "voyce.voting_panels" */
	insert_voyce_voting_panels_one?: GraphQLTypes["voyce_voting_panels"],
	/** insert data into the table: "voyce.voting_series" */
	insert_voyce_voting_series?: GraphQLTypes["voyce_voting_series_mutation_response"],
	/** insert a single row into the table: "voyce.voting_series" */
	insert_voyce_voting_series_one?: GraphQLTypes["voyce_voting_series"],
	/** insert data into the table: "voyce.voting_user_votes" */
	insert_voyce_voting_user_votes?: GraphQLTypes["voyce_voting_user_votes_mutation_response"],
	/** insert a single row into the table: "voyce.voting_user_votes" */
	insert_voyce_voting_user_votes_one?: GraphQLTypes["voyce_voting_user_votes"],
	/** update data of the table: "awsdms_validation_failures_v1" */
	update_awsdms_validation_failures_v1?: GraphQLTypes["awsdms_validation_failures_v1_mutation_response"],
	/** update multiples rows of table: "awsdms_validation_failures_v1" */
	update_awsdms_validation_failures_v1_many?: Array<GraphQLTypes["awsdms_validation_failures_v1_mutation_response"] | undefined>,
	/** update data of the table: "coinsystem.balances" */
	update_coinsystem_balances?: GraphQLTypes["coinsystem_balances_mutation_response"],
	/** update single row of the table: "coinsystem.balances" */
	update_coinsystem_balances_by_pk?: GraphQLTypes["coinsystem_balances"],
	/** update multiples rows of table: "coinsystem.balances" */
	update_coinsystem_balances_many?: Array<GraphQLTypes["coinsystem_balances_mutation_response"] | undefined>,
	/** update data of the table: "coinsystem.bonus_coins" */
	update_coinsystem_bonus_coins?: GraphQLTypes["coinsystem_bonus_coins_mutation_response"],
	/** update single row of the table: "coinsystem.bonus_coins" */
	update_coinsystem_bonus_coins_by_pk?: GraphQLTypes["coinsystem_bonus_coins"],
	/** update multiples rows of table: "coinsystem.bonus_coins" */
	update_coinsystem_bonus_coins_many?: Array<GraphQLTypes["coinsystem_bonus_coins_mutation_response"] | undefined>,
	/** update data of the table: "coinsystem.coins" */
	update_coinsystem_coins?: GraphQLTypes["coinsystem_coins_mutation_response"],
	/** update single row of the table: "coinsystem.coins" */
	update_coinsystem_coins_by_pk?: GraphQLTypes["coinsystem_coins"],
	/** update multiples rows of table: "coinsystem.coins" */
	update_coinsystem_coins_many?: Array<GraphQLTypes["coinsystem_coins_mutation_response"] | undefined>,
	/** update data of the table: "coinsystem.exchange_rates" */
	update_coinsystem_exchange_rates?: GraphQLTypes["coinsystem_exchange_rates_mutation_response"],
	/** update single row of the table: "coinsystem.exchange_rates" */
	update_coinsystem_exchange_rates_by_pk?: GraphQLTypes["coinsystem_exchange_rates"],
	/** update multiples rows of table: "coinsystem.exchange_rates" */
	update_coinsystem_exchange_rates_many?: Array<GraphQLTypes["coinsystem_exchange_rates_mutation_response"] | undefined>,
	/** update data of the table: "coinsystem.logs" */
	update_coinsystem_logs?: GraphQLTypes["coinsystem_logs_mutation_response"],
	/** update single row of the table: "coinsystem.logs" */
	update_coinsystem_logs_by_pk?: GraphQLTypes["coinsystem_logs"],
	/** update multiples rows of table: "coinsystem.logs" */
	update_coinsystem_logs_many?: Array<GraphQLTypes["coinsystem_logs_mutation_response"] | undefined>,
	/** update data of the table: "coinsystem.transactions" */
	update_coinsystem_transactions?: GraphQLTypes["coinsystem_transactions_mutation_response"],
	/** update single row of the table: "coinsystem.transactions" */
	update_coinsystem_transactions_by_pk?: GraphQLTypes["coinsystem_transactions"],
	/** update multiples rows of table: "coinsystem.transactions" */
	update_coinsystem_transactions_many?: Array<GraphQLTypes["coinsystem_transactions_mutation_response"] | undefined>,
	/** update data of the table: "coinsystem.wallets" */
	update_coinsystem_wallets?: GraphQLTypes["coinsystem_wallets_mutation_response"],
	/** update single row of the table: "coinsystem.wallets" */
	update_coinsystem_wallets_by_pk?: GraphQLTypes["coinsystem_wallets"],
	/** update multiples rows of table: "coinsystem.wallets" */
	update_coinsystem_wallets_many?: Array<GraphQLTypes["coinsystem_wallets_mutation_response"] | undefined>,
	/** update data of the table: "mysql.user" */
	update_mysql_user?: GraphQLTypes["mysql_user_mutation_response"],
	/** update single row of the table: "mysql.user" */
	update_mysql_user_by_pk?: GraphQLTypes["mysql_user"],
	/** update multiples rows of table: "mysql.user" */
	update_mysql_user_many?: Array<GraphQLTypes["mysql_user_mutation_response"] | undefined>,
	/** update data of the table: "store.payments" */
	update_store_payments?: GraphQLTypes["store_payments_mutation_response"],
	/** update single row of the table: "store.payments" */
	update_store_payments_by_pk?: GraphQLTypes["store_payments"],
	/** update multiples rows of table: "store.payments" */
	update_store_payments_many?: Array<GraphQLTypes["store_payments_mutation_response"] | undefined>,
	/** update data of the table: "store.products" */
	update_store_products?: GraphQLTypes["store_products_mutation_response"],
	/** update single row of the table: "store.products" */
	update_store_products_by_pk?: GraphQLTypes["store_products"],
	/** update multiples rows of table: "store.products" */
	update_store_products_many?: Array<GraphQLTypes["store_products_mutation_response"] | undefined>,
	/** update data of the table: "store.purchases" */
	update_store_purchases?: GraphQLTypes["store_purchases_mutation_response"],
	/** update single row of the table: "store.purchases" */
	update_store_purchases_by_pk?: GraphQLTypes["store_purchases"],
	/** update multiples rows of table: "store.purchases" */
	update_store_purchases_many?: Array<GraphQLTypes["store_purchases_mutation_response"] | undefined>,
	/** update data of the table: "store.users" */
	update_store_users?: GraphQLTypes["store_users_mutation_response"],
	/** update multiples rows of table: "store.users" */
	update_store_users_many?: Array<GraphQLTypes["store_users_mutation_response"] | undefined>,
	/** update data of the table: "voyce.admin_roles" */
	update_voyce_admin_roles?: GraphQLTypes["voyce_admin_roles_mutation_response"],
	/** update single row of the table: "voyce.admin_roles" */
	update_voyce_admin_roles_by_pk?: GraphQLTypes["voyce_admin_roles"],
	/** update multiples rows of table: "voyce.admin_roles" */
	update_voyce_admin_roles_many?: Array<GraphQLTypes["voyce_admin_roles_mutation_response"] | undefined>,
	/** update data of the table: "voyce.app_banners" */
	update_voyce_app_banners?: GraphQLTypes["voyce_app_banners_mutation_response"],
	/** update single row of the table: "voyce.app_banners" */
	update_voyce_app_banners_by_pk?: GraphQLTypes["voyce_app_banners"],
	/** update multiples rows of table: "voyce.app_banners" */
	update_voyce_app_banners_many?: Array<GraphQLTypes["voyce_app_banners_mutation_response"] | undefined>,
	/** update data of the table: "voyce.app_components" */
	update_voyce_app_components?: GraphQLTypes["voyce_app_components_mutation_response"],
	/** update single row of the table: "voyce.app_components" */
	update_voyce_app_components_by_pk?: GraphQLTypes["voyce_app_components"],
	/** update multiples rows of table: "voyce.app_components" */
	update_voyce_app_components_many?: Array<GraphQLTypes["voyce_app_components_mutation_response"] | undefined>,
	/** update data of the table: "voyce.audit_trail" */
	update_voyce_audit_trail?: GraphQLTypes["voyce_audit_trail_mutation_response"],
	/** update single row of the table: "voyce.audit_trail" */
	update_voyce_audit_trail_by_pk?: GraphQLTypes["voyce_audit_trail"],
	/** update multiples rows of table: "voyce.audit_trail" */
	update_voyce_audit_trail_many?: Array<GraphQLTypes["voyce_audit_trail_mutation_response"] | undefined>,
	/** update data of the table: "voyce.author_profiles" */
	update_voyce_author_profiles?: GraphQLTypes["voyce_author_profiles_mutation_response"],
	/** update single row of the table: "voyce.author_profiles" */
	update_voyce_author_profiles_by_pk?: GraphQLTypes["voyce_author_profiles"],
	/** update multiples rows of table: "voyce.author_profiles" */
	update_voyce_author_profiles_many?: Array<GraphQLTypes["voyce_author_profiles_mutation_response"] | undefined>,
	/** update data of the table: "voyce.balances" */
	update_voyce_balances?: GraphQLTypes["voyce_balances_mutation_response"],
	/** update single row of the table: "voyce.balances" */
	update_voyce_balances_by_pk?: GraphQLTypes["voyce_balances"],
	/** update multiples rows of table: "voyce.balances" */
	update_voyce_balances_many?: Array<GraphQLTypes["voyce_balances_mutation_response"] | undefined>,
	/** update data of the table: "voyce.banners" */
	update_voyce_banners?: GraphQLTypes["voyce_banners_mutation_response"],
	/** update single row of the table: "voyce.banners" */
	update_voyce_banners_by_pk?: GraphQLTypes["voyce_banners"],
	/** update multiples rows of table: "voyce.banners" */
	update_voyce_banners_many?: Array<GraphQLTypes["voyce_banners_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_assets" */
	update_voyce_chapter_assets?: GraphQLTypes["voyce_chapter_assets_mutation_response"],
	/** update single row of the table: "voyce.chapter_assets" */
	update_voyce_chapter_assets_by_pk?: GraphQLTypes["voyce_chapter_assets"],
	/** update multiples rows of table: "voyce.chapter_assets" */
	update_voyce_chapter_assets_many?: Array<GraphQLTypes["voyce_chapter_assets_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_bookmarks" */
	update_voyce_chapter_bookmarks?: GraphQLTypes["voyce_chapter_bookmarks_mutation_response"],
	/** update single row of the table: "voyce.chapter_bookmarks" */
	update_voyce_chapter_bookmarks_by_pk?: GraphQLTypes["voyce_chapter_bookmarks"],
	/** update multiples rows of table: "voyce.chapter_bookmarks" */
	update_voyce_chapter_bookmarks_many?: Array<GraphQLTypes["voyce_chapter_bookmarks_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_comments" */
	update_voyce_chapter_comments?: GraphQLTypes["voyce_chapter_comments_mutation_response"],
	/** update data of the table: "voyce.chapter_comments_07252024" */
	update_voyce_chapter_comments_07252024?: GraphQLTypes["voyce_chapter_comments_07252024_mutation_response"],
	/** update multiples rows of table: "voyce.chapter_comments_07252024" */
	update_voyce_chapter_comments_07252024_many?: Array<GraphQLTypes["voyce_chapter_comments_07252024_mutation_response"] | undefined>,
	/** update single row of the table: "voyce.chapter_comments" */
	update_voyce_chapter_comments_by_pk?: GraphQLTypes["voyce_chapter_comments"],
	/** update multiples rows of table: "voyce.chapter_comments" */
	update_voyce_chapter_comments_many?: Array<GraphQLTypes["voyce_chapter_comments_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_comments_reaction" */
	update_voyce_chapter_comments_reaction?: GraphQLTypes["voyce_chapter_comments_reaction_mutation_response"],
	/** update single row of the table: "voyce.chapter_comments_reaction" */
	update_voyce_chapter_comments_reaction_by_pk?: GraphQLTypes["voyce_chapter_comments_reaction"],
	/** update data of the table: "voyce.chapter_comments_reaction_count" */
	update_voyce_chapter_comments_reaction_count?: GraphQLTypes["voyce_chapter_comments_reaction_count_mutation_response"],
	/** update single row of the table: "voyce.chapter_comments_reaction_count" */
	update_voyce_chapter_comments_reaction_count_by_pk?: GraphQLTypes["voyce_chapter_comments_reaction_count"],
	/** update multiples rows of table: "voyce.chapter_comments_reaction_count" */
	update_voyce_chapter_comments_reaction_count_many?: Array<GraphQLTypes["voyce_chapter_comments_reaction_count_mutation_response"] | undefined>,
	/** update multiples rows of table: "voyce.chapter_comments_reaction" */
	update_voyce_chapter_comments_reaction_many?: Array<GraphQLTypes["voyce_chapter_comments_reaction_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_comments_reports" */
	update_voyce_chapter_comments_reports?: GraphQLTypes["voyce_chapter_comments_reports_mutation_response"],
	/** update single row of the table: "voyce.chapter_comments_reports" */
	update_voyce_chapter_comments_reports_by_pk?: GraphQLTypes["voyce_chapter_comments_reports"],
	/** update multiples rows of table: "voyce.chapter_comments_reports" */
	update_voyce_chapter_comments_reports_many?: Array<GraphQLTypes["voyce_chapter_comments_reports_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_contents" */
	update_voyce_chapter_contents?: GraphQLTypes["voyce_chapter_contents_mutation_response"],
	/** update single row of the table: "voyce.chapter_contents" */
	update_voyce_chapter_contents_by_pk?: GraphQLTypes["voyce_chapter_contents"],
	/** update multiples rows of table: "voyce.chapter_contents" */
	update_voyce_chapter_contents_many?: Array<GraphQLTypes["voyce_chapter_contents_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_contents_old" */
	update_voyce_chapter_contents_old?: GraphQLTypes["voyce_chapter_contents_old_mutation_response"],
	/** update single row of the table: "voyce.chapter_contents_old" */
	update_voyce_chapter_contents_old_by_pk?: GraphQLTypes["voyce_chapter_contents_old"],
	/** update multiples rows of table: "voyce.chapter_contents_old" */
	update_voyce_chapter_contents_old_many?: Array<GraphQLTypes["voyce_chapter_contents_old_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_effects" */
	update_voyce_chapter_effects?: GraphQLTypes["voyce_chapter_effects_mutation_response"],
	/** update single row of the table: "voyce.chapter_effects" */
	update_voyce_chapter_effects_by_pk?: GraphQLTypes["voyce_chapter_effects"],
	/** update multiples rows of table: "voyce.chapter_effects" */
	update_voyce_chapter_effects_many?: Array<GraphQLTypes["voyce_chapter_effects_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_images" */
	update_voyce_chapter_images?: GraphQLTypes["voyce_chapter_images_mutation_response"],
	/** update data of the table: "voyce.chapter_images_07252024" */
	update_voyce_chapter_images_07252024?: GraphQLTypes["voyce_chapter_images_07252024_mutation_response"],
	/** update multiples rows of table: "voyce.chapter_images_07252024" */
	update_voyce_chapter_images_07252024_many?: Array<GraphQLTypes["voyce_chapter_images_07252024_mutation_response"] | undefined>,
	/** update single row of the table: "voyce.chapter_images" */
	update_voyce_chapter_images_by_pk?: GraphQLTypes["voyce_chapter_images"],
	/** update multiples rows of table: "voyce.chapter_images" */
	update_voyce_chapter_images_many?: Array<GraphQLTypes["voyce_chapter_images_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_likes" */
	update_voyce_chapter_likes?: GraphQLTypes["voyce_chapter_likes_mutation_response"],
	/** update single row of the table: "voyce.chapter_likes" */
	update_voyce_chapter_likes_by_pk?: GraphQLTypes["voyce_chapter_likes"],
	/** update multiples rows of table: "voyce.chapter_likes" */
	update_voyce_chapter_likes_many?: Array<GraphQLTypes["voyce_chapter_likes_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_locks" */
	update_voyce_chapter_locks?: GraphQLTypes["voyce_chapter_locks_mutation_response"],
	/** update single row of the table: "voyce.chapter_locks" */
	update_voyce_chapter_locks_by_pk?: GraphQLTypes["voyce_chapter_locks"],
	/** update multiples rows of table: "voyce.chapter_locks" */
	update_voyce_chapter_locks_many?: Array<GraphQLTypes["voyce_chapter_locks_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_panel_views" */
	update_voyce_chapter_panel_views?: GraphQLTypes["voyce_chapter_panel_views_mutation_response"],
	/** update single row of the table: "voyce.chapter_panel_views" */
	update_voyce_chapter_panel_views_by_pk?: GraphQLTypes["voyce_chapter_panel_views"],
	/** update multiples rows of table: "voyce.chapter_panel_views" */
	update_voyce_chapter_panel_views_many?: Array<GraphQLTypes["voyce_chapter_panel_views_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_panels" */
	update_voyce_chapter_panels?: GraphQLTypes["voyce_chapter_panels_mutation_response"],
	/** update single row of the table: "voyce.chapter_panels" */
	update_voyce_chapter_panels_by_pk?: GraphQLTypes["voyce_chapter_panels"],
	/** update multiples rows of table: "voyce.chapter_panels" */
	update_voyce_chapter_panels_many?: Array<GraphQLTypes["voyce_chapter_panels_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_publish_schedules" */
	update_voyce_chapter_publish_schedules?: GraphQLTypes["voyce_chapter_publish_schedules_mutation_response"],
	/** update single row of the table: "voyce.chapter_publish_schedules" */
	update_voyce_chapter_publish_schedules_by_pk?: GraphQLTypes["voyce_chapter_publish_schedules"],
	/** update multiples rows of table: "voyce.chapter_publish_schedules" */
	update_voyce_chapter_publish_schedules_many?: Array<GraphQLTypes["voyce_chapter_publish_schedules_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_reactions" */
	update_voyce_chapter_reactions?: GraphQLTypes["voyce_chapter_reactions_mutation_response"],
	/** update single row of the table: "voyce.chapter_reactions" */
	update_voyce_chapter_reactions_by_pk?: GraphQLTypes["voyce_chapter_reactions"],
	/** update data of the table: "voyce.chapter_reactions_count" */
	update_voyce_chapter_reactions_count?: GraphQLTypes["voyce_chapter_reactions_count_mutation_response"],
	/** update single row of the table: "voyce.chapter_reactions_count" */
	update_voyce_chapter_reactions_count_by_pk?: GraphQLTypes["voyce_chapter_reactions_count"],
	/** update multiples rows of table: "voyce.chapter_reactions_count" */
	update_voyce_chapter_reactions_count_many?: Array<GraphQLTypes["voyce_chapter_reactions_count_mutation_response"] | undefined>,
	/** update multiples rows of table: "voyce.chapter_reactions" */
	update_voyce_chapter_reactions_many?: Array<GraphQLTypes["voyce_chapter_reactions_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_view_logs" */
	update_voyce_chapter_view_logs?: GraphQLTypes["voyce_chapter_view_logs_mutation_response"],
	/** update single row of the table: "voyce.chapter_view_logs" */
	update_voyce_chapter_view_logs_by_pk?: GraphQLTypes["voyce_chapter_view_logs"],
	/** update multiples rows of table: "voyce.chapter_view_logs" */
	update_voyce_chapter_view_logs_many?: Array<GraphQLTypes["voyce_chapter_view_logs_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapter_views" */
	update_voyce_chapter_views?: GraphQLTypes["voyce_chapter_views_mutation_response"],
	/** update single row of the table: "voyce.chapter_views" */
	update_voyce_chapter_views_by_pk?: GraphQLTypes["voyce_chapter_views"],
	/** update multiples rows of table: "voyce.chapter_views" */
	update_voyce_chapter_views_many?: Array<GraphQLTypes["voyce_chapter_views_mutation_response"] | undefined>,
	/** update data of the table: "voyce.chapters" */
	update_voyce_chapters?: GraphQLTypes["voyce_chapters_mutation_response"],
	/** update data of the table: "voyce.chapters_07252024" */
	update_voyce_chapters_07252024?: GraphQLTypes["voyce_chapters_07252024_mutation_response"],
	/** update multiples rows of table: "voyce.chapters_07252024" */
	update_voyce_chapters_07252024_many?: Array<GraphQLTypes["voyce_chapters_07252024_mutation_response"] | undefined>,
	/** update single row of the table: "voyce.chapters" */
	update_voyce_chapters_by_pk?: GraphQLTypes["voyce_chapters"],
	/** update multiples rows of table: "voyce.chapters" */
	update_voyce_chapters_many?: Array<GraphQLTypes["voyce_chapters_mutation_response"] | undefined>,
	/** update data of the table: "voyce.collaborator_roles" */
	update_voyce_collaborator_roles?: GraphQLTypes["voyce_collaborator_roles_mutation_response"],
	/** update single row of the table: "voyce.collaborator_roles" */
	update_voyce_collaborator_roles_by_pk?: GraphQLTypes["voyce_collaborator_roles"],
	/** update multiples rows of table: "voyce.collaborator_roles" */
	update_voyce_collaborator_roles_many?: Array<GraphQLTypes["voyce_collaborator_roles_mutation_response"] | undefined>,
	/** update data of the table: "voyce.comic_types" */
	update_voyce_comic_types?: GraphQLTypes["voyce_comic_types_mutation_response"],
	/** update single row of the table: "voyce.comic_types" */
	update_voyce_comic_types_by_pk?: GraphQLTypes["voyce_comic_types"],
	/** update multiples rows of table: "voyce.comic_types" */
	update_voyce_comic_types_many?: Array<GraphQLTypes["voyce_comic_types_mutation_response"] | undefined>,
	/** update data of the table: "voyce.community_blurbs" */
	update_voyce_community_blurbs?: GraphQLTypes["voyce_community_blurbs_mutation_response"],
	/** update single row of the table: "voyce.community_blurbs" */
	update_voyce_community_blurbs_by_pk?: GraphQLTypes["voyce_community_blurbs"],
	/** update multiples rows of table: "voyce.community_blurbs" */
	update_voyce_community_blurbs_many?: Array<GraphQLTypes["voyce_community_blurbs_mutation_response"] | undefined>,
	/** update data of the table: "voyce.contact_us" */
	update_voyce_contact_us?: GraphQLTypes["voyce_contact_us_mutation_response"],
	/** update single row of the table: "voyce.contact_us" */
	update_voyce_contact_us_by_pk?: GraphQLTypes["voyce_contact_us"],
	/** update multiples rows of table: "voyce.contact_us" */
	update_voyce_contact_us_many?: Array<GraphQLTypes["voyce_contact_us_mutation_response"] | undefined>,
	/** update data of the table: "voyce.content_report" */
	update_voyce_content_report?: GraphQLTypes["voyce_content_report_mutation_response"],
	/** update single row of the table: "voyce.content_report" */
	update_voyce_content_report_by_pk?: GraphQLTypes["voyce_content_report"],
	/** update multiples rows of table: "voyce.content_report" */
	update_voyce_content_report_many?: Array<GraphQLTypes["voyce_content_report_mutation_response"] | undefined>,
	/** update data of the table: "voyce.contest_data" */
	update_voyce_contest_data?: GraphQLTypes["voyce_contest_data_mutation_response"],
	/** update single row of the table: "voyce.contest_data" */
	update_voyce_contest_data_by_pk?: GraphQLTypes["voyce_contest_data"],
	/** update multiples rows of table: "voyce.contest_data" */
	update_voyce_contest_data_many?: Array<GraphQLTypes["voyce_contest_data_mutation_response"] | undefined>,
	/** update data of the table: "voyce.countries" */
	update_voyce_countries?: GraphQLTypes["voyce_countries_mutation_response"],
	/** update single row of the table: "voyce.countries" */
	update_voyce_countries_by_pk?: GraphQLTypes["voyce_countries"],
	/** update multiples rows of table: "voyce.countries" */
	update_voyce_countries_many?: Array<GraphQLTypes["voyce_countries_mutation_response"] | undefined>,
	/** update data of the table: "voyce.csat_ratings" */
	update_voyce_csat_ratings?: GraphQLTypes["voyce_csat_ratings_mutation_response"],
	/** update single row of the table: "voyce.csat_ratings" */
	update_voyce_csat_ratings_by_pk?: GraphQLTypes["voyce_csat_ratings"],
	/** update data of the table: "voyce.csat_ratings_data" */
	update_voyce_csat_ratings_data?: GraphQLTypes["voyce_csat_ratings_data_mutation_response"],
	/** update single row of the table: "voyce.csat_ratings_data" */
	update_voyce_csat_ratings_data_by_pk?: GraphQLTypes["voyce_csat_ratings_data"],
	/** update multiples rows of table: "voyce.csat_ratings_data" */
	update_voyce_csat_ratings_data_many?: Array<GraphQLTypes["voyce_csat_ratings_data_mutation_response"] | undefined>,
	/** update multiples rows of table: "voyce.csat_ratings" */
	update_voyce_csat_ratings_many?: Array<GraphQLTypes["voyce_csat_ratings_mutation_response"] | undefined>,
	/** update data of the table: "voyce.education_types" */
	update_voyce_education_types?: GraphQLTypes["voyce_education_types_mutation_response"],
	/** update single row of the table: "voyce.education_types" */
	update_voyce_education_types_by_pk?: GraphQLTypes["voyce_education_types"],
	/** update multiples rows of table: "voyce.education_types" */
	update_voyce_education_types_many?: Array<GraphQLTypes["voyce_education_types_mutation_response"] | undefined>,
	/** update data of the table: "voyce.erp_login_roles" */
	update_voyce_erp_login_roles?: GraphQLTypes["voyce_erp_login_roles_mutation_response"],
	/** update single row of the table: "voyce.erp_login_roles" */
	update_voyce_erp_login_roles_by_pk?: GraphQLTypes["voyce_erp_login_roles"],
	/** update multiples rows of table: "voyce.erp_login_roles" */
	update_voyce_erp_login_roles_many?: Array<GraphQLTypes["voyce_erp_login_roles_mutation_response"] | undefined>,
	/** update data of the table: "voyce.etl_series_views_logs" */
	update_voyce_etl_series_views_logs?: GraphQLTypes["voyce_etl_series_views_logs_mutation_response"],
	/** update single row of the table: "voyce.etl_series_views_logs" */
	update_voyce_etl_series_views_logs_by_pk?: GraphQLTypes["voyce_etl_series_views_logs"],
	/** update multiples rows of table: "voyce.etl_series_views_logs" */
	update_voyce_etl_series_views_logs_many?: Array<GraphQLTypes["voyce_etl_series_views_logs_mutation_response"] | undefined>,
	/** update data of the table: "voyce.event_schedules" */
	update_voyce_event_schedules?: GraphQLTypes["voyce_event_schedules_mutation_response"],
	/** update single row of the table: "voyce.event_schedules" */
	update_voyce_event_schedules_by_pk?: GraphQLTypes["voyce_event_schedules"],
	/** update multiples rows of table: "voyce.event_schedules" */
	update_voyce_event_schedules_many?: Array<GraphQLTypes["voyce_event_schedules_mutation_response"] | undefined>,
	/** update data of the table: "voyce.genres" */
	update_voyce_genres?: GraphQLTypes["voyce_genres_mutation_response"],
	/** update single row of the table: "voyce.genres" */
	update_voyce_genres_by_pk?: GraphQLTypes["voyce_genres"],
	/** update multiples rows of table: "voyce.genres" */
	update_voyce_genres_many?: Array<GraphQLTypes["voyce_genres_mutation_response"] | undefined>,
	/** update data of the table: "voyce.guest_gtags" */
	update_voyce_guest_gtags?: GraphQLTypes["voyce_guest_gtags_mutation_response"],
	/** update single row of the table: "voyce.guest_gtags" */
	update_voyce_guest_gtags_by_pk?: GraphQLTypes["voyce_guest_gtags"],
	/** update multiples rows of table: "voyce.guest_gtags" */
	update_voyce_guest_gtags_many?: Array<GraphQLTypes["voyce_guest_gtags_mutation_response"] | undefined>,
	/** update data of the table: "voyce.metabases" */
	update_voyce_metabases?: GraphQLTypes["voyce_metabases_mutation_response"],
	/** update data of the table: "voyce.metabases_access" */
	update_voyce_metabases_access?: GraphQLTypes["voyce_metabases_access_mutation_response"],
	/** update single row of the table: "voyce.metabases_access" */
	update_voyce_metabases_access_by_pk?: GraphQLTypes["voyce_metabases_access"],
	/** update multiples rows of table: "voyce.metabases_access" */
	update_voyce_metabases_access_many?: Array<GraphQLTypes["voyce_metabases_access_mutation_response"] | undefined>,
	/** update single row of the table: "voyce.metabases" */
	update_voyce_metabases_by_pk?: GraphQLTypes["voyce_metabases"],
	/** update multiples rows of table: "voyce.metabases" */
	update_voyce_metabases_many?: Array<GraphQLTypes["voyce_metabases_mutation_response"] | undefined>,
	/** update data of the table: "voyce.newsletter" */
	update_voyce_newsletter?: GraphQLTypes["voyce_newsletter_mutation_response"],
	/** update single row of the table: "voyce.newsletter" */
	update_voyce_newsletter_by_pk?: GraphQLTypes["voyce_newsletter"],
	/** update multiples rows of table: "voyce.newsletter" */
	update_voyce_newsletter_many?: Array<GraphQLTypes["voyce_newsletter_mutation_response"] | undefined>,
	/** update data of the table: "voyce.notification_series_settings" */
	update_voyce_notification_series_settings?: GraphQLTypes["voyce_notification_series_settings_mutation_response"],
	/** update single row of the table: "voyce.notification_series_settings" */
	update_voyce_notification_series_settings_by_pk?: GraphQLTypes["voyce_notification_series_settings"],
	/** update multiples rows of table: "voyce.notification_series_settings" */
	update_voyce_notification_series_settings_many?: Array<GraphQLTypes["voyce_notification_series_settings_mutation_response"] | undefined>,
	/** update data of the table: "voyce.notification_settings" */
	update_voyce_notification_settings?: GraphQLTypes["voyce_notification_settings_mutation_response"],
	/** update single row of the table: "voyce.notification_settings" */
	update_voyce_notification_settings_by_pk?: GraphQLTypes["voyce_notification_settings"],
	/** update multiples rows of table: "voyce.notification_settings" */
	update_voyce_notification_settings_many?: Array<GraphQLTypes["voyce_notification_settings_mutation_response"] | undefined>,
	/** update data of the table: "voyce.notifications" */
	update_voyce_notifications?: GraphQLTypes["voyce_notifications_mutation_response"],
	/** update single row of the table: "voyce.notifications" */
	update_voyce_notifications_by_pk?: GraphQLTypes["voyce_notifications"],
	/** update multiples rows of table: "voyce.notifications" */
	update_voyce_notifications_many?: Array<GraphQLTypes["voyce_notifications_mutation_response"] | undefined>,
	/** update data of the table: "voyce.page_banners" */
	update_voyce_page_banners?: GraphQLTypes["voyce_page_banners_mutation_response"],
	/** update single row of the table: "voyce.page_banners" */
	update_voyce_page_banners_by_pk?: GraphQLTypes["voyce_page_banners"],
	/** update multiples rows of table: "voyce.page_banners" */
	update_voyce_page_banners_many?: Array<GraphQLTypes["voyce_page_banners_mutation_response"] | undefined>,
	/** update data of the table: "voyce.page_components" */
	update_voyce_page_components?: GraphQLTypes["voyce_page_components_mutation_response"],
	/** update single row of the table: "voyce.page_components" */
	update_voyce_page_components_by_pk?: GraphQLTypes["voyce_page_components"],
	/** update multiples rows of table: "voyce.page_components" */
	update_voyce_page_components_many?: Array<GraphQLTypes["voyce_page_components_mutation_response"] | undefined>,
	/** update data of the table: "voyce.pages" */
	update_voyce_pages?: GraphQLTypes["voyce_pages_mutation_response"],
	/** update single row of the table: "voyce.pages" */
	update_voyce_pages_by_pk?: GraphQLTypes["voyce_pages"],
	/** update multiples rows of table: "voyce.pages" */
	update_voyce_pages_many?: Array<GraphQLTypes["voyce_pages_mutation_response"] | undefined>,
	/** update data of the table: "voyce.panels_comments" */
	update_voyce_panels_comments?: GraphQLTypes["voyce_panels_comments_mutation_response"],
	/** update single row of the table: "voyce.panels_comments" */
	update_voyce_panels_comments_by_pk?: GraphQLTypes["voyce_panels_comments"],
	/** update multiples rows of table: "voyce.panels_comments" */
	update_voyce_panels_comments_many?: Array<GraphQLTypes["voyce_panels_comments_mutation_response"] | undefined>,
	/** update data of the table: "voyce.panels_reactions" */
	update_voyce_panels_reactions?: GraphQLTypes["voyce_panels_reactions_mutation_response"],
	/** update single row of the table: "voyce.panels_reactions" */
	update_voyce_panels_reactions_by_pk?: GraphQLTypes["voyce_panels_reactions"],
	/** update data of the table: "voyce.panels_reactions_count" */
	update_voyce_panels_reactions_count?: GraphQLTypes["voyce_panels_reactions_count_mutation_response"],
	/** update single row of the table: "voyce.panels_reactions_count" */
	update_voyce_panels_reactions_count_by_pk?: GraphQLTypes["voyce_panels_reactions_count"],
	/** update multiples rows of table: "voyce.panels_reactions_count" */
	update_voyce_panels_reactions_count_many?: Array<GraphQLTypes["voyce_panels_reactions_count_mutation_response"] | undefined>,
	/** update multiples rows of table: "voyce.panels_reactions" */
	update_voyce_panels_reactions_many?: Array<GraphQLTypes["voyce_panels_reactions_mutation_response"] | undefined>,
	/** update data of the table: "voyce.payments_to_creators" */
	update_voyce_payments_to_creators?: GraphQLTypes["voyce_payments_to_creators_mutation_response"],
	/** update single row of the table: "voyce.payments_to_creators" */
	update_voyce_payments_to_creators_by_pk?: GraphQLTypes["voyce_payments_to_creators"],
	/** update multiples rows of table: "voyce.payments_to_creators" */
	update_voyce_payments_to_creators_many?: Array<GraphQLTypes["voyce_payments_to_creators_mutation_response"] | undefined>,
	/** update data of the table: "voyce.pg_rs_series_views_count" */
	update_voyce_pg_rs_series_views_count?: GraphQLTypes["voyce_pg_rs_series_views_count_mutation_response"],
	/** update single row of the table: "voyce.pg_rs_series_views_count" */
	update_voyce_pg_rs_series_views_count_by_pk?: GraphQLTypes["voyce_pg_rs_series_views_count"],
	/** update multiples rows of table: "voyce.pg_rs_series_views_count" */
	update_voyce_pg_rs_series_views_count_many?: Array<GraphQLTypes["voyce_pg_rs_series_views_count_mutation_response"] | undefined>,
	/** update data of the table: "voyce.polls" */
	update_voyce_polls?: GraphQLTypes["voyce_polls_mutation_response"],
	/** update data of the table: "voyce.polls_answers" */
	update_voyce_polls_answers?: GraphQLTypes["voyce_polls_answers_mutation_response"],
	/** update single row of the table: "voyce.polls_answers" */
	update_voyce_polls_answers_by_pk?: GraphQLTypes["voyce_polls_answers"],
	/** update multiples rows of table: "voyce.polls_answers" */
	update_voyce_polls_answers_many?: Array<GraphQLTypes["voyce_polls_answers_mutation_response"] | undefined>,
	/** update single row of the table: "voyce.polls" */
	update_voyce_polls_by_pk?: GraphQLTypes["voyce_polls"],
	/** update data of the table: "voyce.polls_comments" */
	update_voyce_polls_comments?: GraphQLTypes["voyce_polls_comments_mutation_response"],
	/** update single row of the table: "voyce.polls_comments" */
	update_voyce_polls_comments_by_pk?: GraphQLTypes["voyce_polls_comments"],
	/** update multiples rows of table: "voyce.polls_comments" */
	update_voyce_polls_comments_many?: Array<GraphQLTypes["voyce_polls_comments_mutation_response"] | undefined>,
	/** update multiples rows of table: "voyce.polls" */
	update_voyce_polls_many?: Array<GraphQLTypes["voyce_polls_mutation_response"] | undefined>,
	/** update data of the table: "voyce.polls_reactions" */
	update_voyce_polls_reactions?: GraphQLTypes["voyce_polls_reactions_mutation_response"],
	/** update single row of the table: "voyce.polls_reactions" */
	update_voyce_polls_reactions_by_pk?: GraphQLTypes["voyce_polls_reactions"],
	/** update multiples rows of table: "voyce.polls_reactions" */
	update_voyce_polls_reactions_many?: Array<GraphQLTypes["voyce_polls_reactions_mutation_response"] | undefined>,
	/** update data of the table: "voyce.polls_votes" */
	update_voyce_polls_votes?: GraphQLTypes["voyce_polls_votes_mutation_response"],
	/** update single row of the table: "voyce.polls_votes" */
	update_voyce_polls_votes_by_pk?: GraphQLTypes["voyce_polls_votes"],
	/** update multiples rows of table: "voyce.polls_votes" */
	update_voyce_polls_votes_many?: Array<GraphQLTypes["voyce_polls_votes_mutation_response"] | undefined>,
	/** update data of the table: "voyce.popup_components" */
	update_voyce_popup_components?: GraphQLTypes["voyce_popup_components_mutation_response"],
	/** update single row of the table: "voyce.popup_components" */
	update_voyce_popup_components_by_pk?: GraphQLTypes["voyce_popup_components"],
	/** update multiples rows of table: "voyce.popup_components" */
	update_voyce_popup_components_many?: Array<GraphQLTypes["voyce_popup_components_mutation_response"] | undefined>,
	/** update data of the table: "voyce.popup_emails" */
	update_voyce_popup_emails?: GraphQLTypes["voyce_popup_emails_mutation_response"],
	/** update single row of the table: "voyce.popup_emails" */
	update_voyce_popup_emails_by_pk?: GraphQLTypes["voyce_popup_emails"],
	/** update multiples rows of table: "voyce.popup_emails" */
	update_voyce_popup_emails_many?: Array<GraphQLTypes["voyce_popup_emails_mutation_response"] | undefined>,
	/** update data of the table: "voyce.posts" */
	update_voyce_posts?: GraphQLTypes["voyce_posts_mutation_response"],
	/** update single row of the table: "voyce.posts" */
	update_voyce_posts_by_pk?: GraphQLTypes["voyce_posts"],
	/** update data of the table: "voyce.posts_comments" */
	update_voyce_posts_comments?: GraphQLTypes["voyce_posts_comments_mutation_response"],
	/** update single row of the table: "voyce.posts_comments" */
	update_voyce_posts_comments_by_pk?: GraphQLTypes["voyce_posts_comments"],
	/** update multiples rows of table: "voyce.posts_comments" */
	update_voyce_posts_comments_many?: Array<GraphQLTypes["voyce_posts_comments_mutation_response"] | undefined>,
	/** update multiples rows of table: "voyce.posts" */
	update_voyce_posts_many?: Array<GraphQLTypes["voyce_posts_mutation_response"] | undefined>,
	/** update data of the table: "voyce.posts_reactions" */
	update_voyce_posts_reactions?: GraphQLTypes["voyce_posts_reactions_mutation_response"],
	/** update single row of the table: "voyce.posts_reactions" */
	update_voyce_posts_reactions_by_pk?: GraphQLTypes["voyce_posts_reactions"],
	/** update multiples rows of table: "voyce.posts_reactions" */
	update_voyce_posts_reactions_many?: Array<GraphQLTypes["voyce_posts_reactions_mutation_response"] | undefined>,
	/** update data of the table: "voyce.quest_difficulties" */
	update_voyce_quest_difficulties?: GraphQLTypes["voyce_quest_difficulties_mutation_response"],
	/** update single row of the table: "voyce.quest_difficulties" */
	update_voyce_quest_difficulties_by_pk?: GraphQLTypes["voyce_quest_difficulties"],
	/** update multiples rows of table: "voyce.quest_difficulties" */
	update_voyce_quest_difficulties_many?: Array<GraphQLTypes["voyce_quest_difficulties_mutation_response"] | undefined>,
	/** update data of the table: "voyce.quests" */
	update_voyce_quests?: GraphQLTypes["voyce_quests_mutation_response"],
	/** update single row of the table: "voyce.quests" */
	update_voyce_quests_by_pk?: GraphQLTypes["voyce_quests"],
	/** update multiples rows of table: "voyce.quests" */
	update_voyce_quests_many?: Array<GraphQLTypes["voyce_quests_mutation_response"] | undefined>,
	/** update data of the table: "voyce.quests_old" */
	update_voyce_quests_old?: GraphQLTypes["voyce_quests_old_mutation_response"],
	/** update single row of the table: "voyce.quests_old" */
	update_voyce_quests_old_by_pk?: GraphQLTypes["voyce_quests_old"],
	/** update multiples rows of table: "voyce.quests_old" */
	update_voyce_quests_old_many?: Array<GraphQLTypes["voyce_quests_old_mutation_response"] | undefined>,
	/** update data of the table: "voyce.recommended_series" */
	update_voyce_recommended_series?: GraphQLTypes["voyce_recommended_series_mutation_response"],
	/** update single row of the table: "voyce.recommended_series" */
	update_voyce_recommended_series_by_pk?: GraphQLTypes["voyce_recommended_series"],
	/** update multiples rows of table: "voyce.recommended_series" */
	update_voyce_recommended_series_many?: Array<GraphQLTypes["voyce_recommended_series_mutation_response"] | undefined>,
	/** update data of the table: "voyce.release_schedules" */
	update_voyce_release_schedules?: GraphQLTypes["voyce_release_schedules_mutation_response"],
	/** update single row of the table: "voyce.release_schedules" */
	update_voyce_release_schedules_by_pk?: GraphQLTypes["voyce_release_schedules"],
	/** update multiples rows of table: "voyce.release_schedules" */
	update_voyce_release_schedules_many?: Array<GraphQLTypes["voyce_release_schedules_mutation_response"] | undefined>,
	/** update data of the table: "voyce.rewards" */
	update_voyce_rewards?: GraphQLTypes["voyce_rewards_mutation_response"],
	/** update single row of the table: "voyce.rewards" */
	update_voyce_rewards_by_pk?: GraphQLTypes["voyce_rewards"],
	/** update data of the table: "voyce.rewards_leaderboard" */
	update_voyce_rewards_leaderboard?: GraphQLTypes["voyce_rewards_leaderboard_mutation_response"],
	/** update single row of the table: "voyce.rewards_leaderboard" */
	update_voyce_rewards_leaderboard_by_pk?: GraphQLTypes["voyce_rewards_leaderboard"],
	/** update multiples rows of table: "voyce.rewards_leaderboard" */
	update_voyce_rewards_leaderboard_many?: Array<GraphQLTypes["voyce_rewards_leaderboard_mutation_response"] | undefined>,
	/** update multiples rows of table: "voyce.rewards" */
	update_voyce_rewards_many?: Array<GraphQLTypes["voyce_rewards_mutation_response"] | undefined>,
	/** update data of the table: "voyce.rewards_quest" */
	update_voyce_rewards_quest?: GraphQLTypes["voyce_rewards_quest_mutation_response"],
	/** update single row of the table: "voyce.rewards_quest" */
	update_voyce_rewards_quest_by_pk?: GraphQLTypes["voyce_rewards_quest"],
	/** update multiples rows of table: "voyce.rewards_quest" */
	update_voyce_rewards_quest_many?: Array<GraphQLTypes["voyce_rewards_quest_mutation_response"] | undefined>,
	/** update data of the table: "voyce.sections" */
	update_voyce_sections?: GraphQLTypes["voyce_sections_mutation_response"],
	/** update single row of the table: "voyce.sections" */
	update_voyce_sections_by_pk?: GraphQLTypes["voyce_sections"],
	/** update multiples rows of table: "voyce.sections" */
	update_voyce_sections_many?: Array<GraphQLTypes["voyce_sections_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series" */
	update_voyce_series?: GraphQLTypes["voyce_series_mutation_response"],
	/** update single row of the table: "voyce.series" */
	update_voyce_series_by_pk?: GraphQLTypes["voyce_series"],
	/** update data of the table: "voyce.series_collaborators" */
	update_voyce_series_collaborators?: GraphQLTypes["voyce_series_collaborators_mutation_response"],
	/** update single row of the table: "voyce.series_collaborators" */
	update_voyce_series_collaborators_by_pk?: GraphQLTypes["voyce_series_collaborators"],
	/** update multiples rows of table: "voyce.series_collaborators" */
	update_voyce_series_collaborators_many?: Array<GraphQLTypes["voyce_series_collaborators_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_donations" */
	update_voyce_series_donations?: GraphQLTypes["voyce_series_donations_mutation_response"],
	/** update single row of the table: "voyce.series_donations" */
	update_voyce_series_donations_by_pk?: GraphQLTypes["voyce_series_donations"],
	/** update multiples rows of table: "voyce.series_donations" */
	update_voyce_series_donations_many?: Array<GraphQLTypes["voyce_series_donations_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_favorites" */
	update_voyce_series_favorites?: GraphQLTypes["voyce_series_favorites_mutation_response"],
	/** update single row of the table: "voyce.series_favorites" */
	update_voyce_series_favorites_by_pk?: GraphQLTypes["voyce_series_favorites"],
	/** update multiples rows of table: "voyce.series_favorites" */
	update_voyce_series_favorites_many?: Array<GraphQLTypes["voyce_series_favorites_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_featured_panels" */
	update_voyce_series_featured_panels?: GraphQLTypes["voyce_series_featured_panels_mutation_response"],
	/** update single row of the table: "voyce.series_featured_panels" */
	update_voyce_series_featured_panels_by_pk?: GraphQLTypes["voyce_series_featured_panels"],
	/** update multiples rows of table: "voyce.series_featured_panels" */
	update_voyce_series_featured_panels_many?: Array<GraphQLTypes["voyce_series_featured_panels_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_genre" */
	update_voyce_series_genre?: GraphQLTypes["voyce_series_genre_mutation_response"],
	/** update single row of the table: "voyce.series_genre" */
	update_voyce_series_genre_by_pk?: GraphQLTypes["voyce_series_genre"],
	/** update multiples rows of table: "voyce.series_genre" */
	update_voyce_series_genre_many?: Array<GraphQLTypes["voyce_series_genre_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_giveaway_participants" */
	update_voyce_series_giveaway_participants?: GraphQLTypes["voyce_series_giveaway_participants_mutation_response"],
	/** update single row of the table: "voyce.series_giveaway_participants" */
	update_voyce_series_giveaway_participants_by_pk?: GraphQLTypes["voyce_series_giveaway_participants"],
	/** update multiples rows of table: "voyce.series_giveaway_participants" */
	update_voyce_series_giveaway_participants_many?: Array<GraphQLTypes["voyce_series_giveaway_participants_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_hot_panels" */
	update_voyce_series_hot_panels?: GraphQLTypes["voyce_series_hot_panels_mutation_response"],
	/** update single row of the table: "voyce.series_hot_panels" */
	update_voyce_series_hot_panels_by_pk?: GraphQLTypes["voyce_series_hot_panels"],
	/** update multiples rows of table: "voyce.series_hot_panels" */
	update_voyce_series_hot_panels_many?: Array<GraphQLTypes["voyce_series_hot_panels_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_layouts" */
	update_voyce_series_layouts?: GraphQLTypes["voyce_series_layouts_mutation_response"],
	/** update single row of the table: "voyce.series_layouts" */
	update_voyce_series_layouts_by_pk?: GraphQLTypes["voyce_series_layouts"],
	/** update multiples rows of table: "voyce.series_layouts" */
	update_voyce_series_layouts_many?: Array<GraphQLTypes["voyce_series_layouts_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_leaderboard" */
	update_voyce_series_leaderboard?: GraphQLTypes["voyce_series_leaderboard_mutation_response"],
	/** update single row of the table: "voyce.series_leaderboard" */
	update_voyce_series_leaderboard_by_pk?: GraphQLTypes["voyce_series_leaderboard"],
	/** update multiples rows of table: "voyce.series_leaderboard" */
	update_voyce_series_leaderboard_many?: Array<GraphQLTypes["voyce_series_leaderboard_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_lock_config" */
	update_voyce_series_lock_config?: GraphQLTypes["voyce_series_lock_config_mutation_response"],
	/** update single row of the table: "voyce.series_lock_config" */
	update_voyce_series_lock_config_by_pk?: GraphQLTypes["voyce_series_lock_config"],
	/** update multiples rows of table: "voyce.series_lock_config" */
	update_voyce_series_lock_config_many?: Array<GraphQLTypes["voyce_series_lock_config_mutation_response"] | undefined>,
	/** update multiples rows of table: "voyce.series" */
	update_voyce_series_many?: Array<GraphQLTypes["voyce_series_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_sponsorships_configuration" */
	update_voyce_series_sponsorships_configuration?: GraphQLTypes["voyce_series_sponsorships_configuration_mutation_response"],
	/** update single row of the table: "voyce.series_sponsorships_configuration" */
	update_voyce_series_sponsorships_configuration_by_pk?: GraphQLTypes["voyce_series_sponsorships_configuration"],
	/** update multiples rows of table: "voyce.series_sponsorships_configuration" */
	update_voyce_series_sponsorships_configuration_many?: Array<GraphQLTypes["voyce_series_sponsorships_configuration_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_subscription" */
	update_voyce_series_subscription?: GraphQLTypes["voyce_series_subscription_mutation_response"],
	/** update single row of the table: "voyce.series_subscription" */
	update_voyce_series_subscription_by_pk?: GraphQLTypes["voyce_series_subscription"],
	/** update multiples rows of table: "voyce.series_subscription" */
	update_voyce_series_subscription_many?: Array<GraphQLTypes["voyce_series_subscription_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_tags" */
	update_voyce_series_tags?: GraphQLTypes["voyce_series_tags_mutation_response"],
	/** update single row of the table: "voyce.series_tags" */
	update_voyce_series_tags_by_pk?: GraphQLTypes["voyce_series_tags"],
	/** update data of the table: "voyce.series_tags_junction" */
	update_voyce_series_tags_junction?: GraphQLTypes["voyce_series_tags_junction_mutation_response"],
	/** update single row of the table: "voyce.series_tags_junction" */
	update_voyce_series_tags_junction_by_pk?: GraphQLTypes["voyce_series_tags_junction"],
	/** update multiples rows of table: "voyce.series_tags_junction" */
	update_voyce_series_tags_junction_many?: Array<GraphQLTypes["voyce_series_tags_junction_mutation_response"] | undefined>,
	/** update multiples rows of table: "voyce.series_tags" */
	update_voyce_series_tags_many?: Array<GraphQLTypes["voyce_series_tags_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_top_comments" */
	update_voyce_series_top_comments?: GraphQLTypes["voyce_series_top_comments_mutation_response"],
	/** update single row of the table: "voyce.series_top_comments" */
	update_voyce_series_top_comments_by_pk?: GraphQLTypes["voyce_series_top_comments"],
	/** update multiples rows of table: "voyce.series_top_comments" */
	update_voyce_series_top_comments_many?: Array<GraphQLTypes["voyce_series_top_comments_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_top_likes" */
	update_voyce_series_top_likes?: GraphQLTypes["voyce_series_top_likes_mutation_response"],
	/** update single row of the table: "voyce.series_top_likes" */
	update_voyce_series_top_likes_by_pk?: GraphQLTypes["voyce_series_top_likes"],
	/** update multiples rows of table: "voyce.series_top_likes" */
	update_voyce_series_top_likes_many?: Array<GraphQLTypes["voyce_series_top_likes_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_top_views" */
	update_voyce_series_top_views?: GraphQLTypes["voyce_series_top_views_mutation_response"],
	/** update single row of the table: "voyce.series_top_views" */
	update_voyce_series_top_views_by_pk?: GraphQLTypes["voyce_series_top_views"],
	/** update multiples rows of table: "voyce.series_top_views" */
	update_voyce_series_top_views_many?: Array<GraphQLTypes["voyce_series_top_views_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_views_adjustment" */
	update_voyce_series_views_adjustment?: GraphQLTypes["voyce_series_views_adjustment_mutation_response"],
	/** update single row of the table: "voyce.series_views_adjustment" */
	update_voyce_series_views_adjustment_by_pk?: GraphQLTypes["voyce_series_views_adjustment"],
	/** update multiples rows of table: "voyce.series_views_adjustment" */
	update_voyce_series_views_adjustment_many?: Array<GraphQLTypes["voyce_series_views_adjustment_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_votes" */
	update_voyce_series_votes?: GraphQLTypes["voyce_series_votes_mutation_response"],
	/** update single row of the table: "voyce.series_votes" */
	update_voyce_series_votes_by_pk?: GraphQLTypes["voyce_series_votes"],
	/** update data of the table: "voyce.series_votes_count" */
	update_voyce_series_votes_count?: GraphQLTypes["voyce_series_votes_count_mutation_response"],
	/** update single row of the table: "voyce.series_votes_count" */
	update_voyce_series_votes_count_by_pk?: GraphQLTypes["voyce_series_votes_count"],
	/** update multiples rows of table: "voyce.series_votes_count" */
	update_voyce_series_votes_count_many?: Array<GraphQLTypes["voyce_series_votes_count_mutation_response"] | undefined>,
	/** update multiples rows of table: "voyce.series_votes" */
	update_voyce_series_votes_many?: Array<GraphQLTypes["voyce_series_votes_mutation_response"] | undefined>,
	/** update data of the table: "voyce.series_voting_schedules" */
	update_voyce_series_voting_schedules?: GraphQLTypes["voyce_series_voting_schedules_mutation_response"],
	/** update single row of the table: "voyce.series_voting_schedules" */
	update_voyce_series_voting_schedules_by_pk?: GraphQLTypes["voyce_series_voting_schedules"],
	/** update multiples rows of table: "voyce.series_voting_schedules" */
	update_voyce_series_voting_schedules_many?: Array<GraphQLTypes["voyce_series_voting_schedules_mutation_response"] | undefined>,
	/** update data of the table: "voyce.sponsorships" */
	update_voyce_sponsorships?: GraphQLTypes["voyce_sponsorships_mutation_response"],
	/** update single row of the table: "voyce.sponsorships" */
	update_voyce_sponsorships_by_pk?: GraphQLTypes["voyce_sponsorships"],
	/** update multiples rows of table: "voyce.sponsorships" */
	update_voyce_sponsorships_many?: Array<GraphQLTypes["voyce_sponsorships_mutation_response"] | undefined>,
	/** update data of the table: "voyce.states" */
	update_voyce_states?: GraphQLTypes["voyce_states_mutation_response"],
	/** update single row of the table: "voyce.states" */
	update_voyce_states_by_pk?: GraphQLTypes["voyce_states"],
	/** update multiples rows of table: "voyce.states" */
	update_voyce_states_many?: Array<GraphQLTypes["voyce_states_mutation_response"] | undefined>,
	/** update data of the table: "voyce.stripe_payouts_setup" */
	update_voyce_stripe_payouts_setup?: GraphQLTypes["voyce_stripe_payouts_setup_mutation_response"],
	/** update single row of the table: "voyce.stripe_payouts_setup" */
	update_voyce_stripe_payouts_setup_by_pk?: GraphQLTypes["voyce_stripe_payouts_setup"],
	/** update multiples rows of table: "voyce.stripe_payouts_setup" */
	update_voyce_stripe_payouts_setup_many?: Array<GraphQLTypes["voyce_stripe_payouts_setup_mutation_response"] | undefined>,
	/** update data of the table: "voyce.studio" */
	update_voyce_studio?: GraphQLTypes["voyce_studio_mutation_response"],
	/** update single row of the table: "voyce.studio" */
	update_voyce_studio_by_pk?: GraphQLTypes["voyce_studio"],
	/** update data of the table: "voyce.studio_followers" */
	update_voyce_studio_followers?: GraphQLTypes["voyce_studio_followers_mutation_response"],
	/** update single row of the table: "voyce.studio_followers" */
	update_voyce_studio_followers_by_pk?: GraphQLTypes["voyce_studio_followers"],
	/** update multiples rows of table: "voyce.studio_followers" */
	update_voyce_studio_followers_many?: Array<GraphQLTypes["voyce_studio_followers_mutation_response"] | undefined>,
	/** update multiples rows of table: "voyce.studio" */
	update_voyce_studio_many?: Array<GraphQLTypes["voyce_studio_mutation_response"] | undefined>,
	/** update data of the table: "voyce.studio_series" */
	update_voyce_studio_series?: GraphQLTypes["voyce_studio_series_mutation_response"],
	/** update single row of the table: "voyce.studio_series" */
	update_voyce_studio_series_by_pk?: GraphQLTypes["voyce_studio_series"],
	/** update multiples rows of table: "voyce.studio_series" */
	update_voyce_studio_series_many?: Array<GraphQLTypes["voyce_studio_series_mutation_response"] | undefined>,
	/** update data of the table: "voyce.studio_views" */
	update_voyce_studio_views?: GraphQLTypes["voyce_studio_views_mutation_response"],
	/** update single row of the table: "voyce.studio_views" */
	update_voyce_studio_views_by_pk?: GraphQLTypes["voyce_studio_views"],
	/** update multiples rows of table: "voyce.studio_views" */
	update_voyce_studio_views_many?: Array<GraphQLTypes["voyce_studio_views_mutation_response"] | undefined>,
	/** update data of the table: "voyce.system_settings" */
	update_voyce_system_settings?: GraphQLTypes["voyce_system_settings_mutation_response"],
	/** update single row of the table: "voyce.system_settings" */
	update_voyce_system_settings_by_pk?: GraphQLTypes["voyce_system_settings"],
	/** update multiples rows of table: "voyce.system_settings" */
	update_voyce_system_settings_many?: Array<GraphQLTypes["voyce_system_settings_mutation_response"] | undefined>,
	/** update data of the table: "voyce.transaction_logs" */
	update_voyce_transaction_logs?: GraphQLTypes["voyce_transaction_logs_mutation_response"],
	/** update single row of the table: "voyce.transaction_logs" */
	update_voyce_transaction_logs_by_pk?: GraphQLTypes["voyce_transaction_logs"],
	/** update multiples rows of table: "voyce.transaction_logs" */
	update_voyce_transaction_logs_many?: Array<GraphQLTypes["voyce_transaction_logs_mutation_response"] | undefined>,
	/** update data of the table: "voyce.url_redirects" */
	update_voyce_url_redirects?: GraphQLTypes["voyce_url_redirects_mutation_response"],
	/** update single row of the table: "voyce.url_redirects" */
	update_voyce_url_redirects_by_pk?: GraphQLTypes["voyce_url_redirects"],
	/** update multiples rows of table: "voyce.url_redirects" */
	update_voyce_url_redirects_many?: Array<GraphQLTypes["voyce_url_redirects_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_addresses" */
	update_voyce_user_addresses?: GraphQLTypes["voyce_user_addresses_mutation_response"],
	/** update single row of the table: "voyce.user_addresses" */
	update_voyce_user_addresses_by_pk?: GraphQLTypes["voyce_user_addresses"],
	/** update multiples rows of table: "voyce.user_addresses" */
	update_voyce_user_addresses_many?: Array<GraphQLTypes["voyce_user_addresses_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_blocks" */
	update_voyce_user_blocks?: GraphQLTypes["voyce_user_blocks_mutation_response"],
	/** update single row of the table: "voyce.user_blocks" */
	update_voyce_user_blocks_by_pk?: GraphQLTypes["voyce_user_blocks"],
	/** update multiples rows of table: "voyce.user_blocks" */
	update_voyce_user_blocks_many?: Array<GraphQLTypes["voyce_user_blocks_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_chapters_unlocked" */
	update_voyce_user_chapters_unlocked?: GraphQLTypes["voyce_user_chapters_unlocked_mutation_response"],
	/** update single row of the table: "voyce.user_chapters_unlocked" */
	update_voyce_user_chapters_unlocked_by_pk?: GraphQLTypes["voyce_user_chapters_unlocked"],
	/** update multiples rows of table: "voyce.user_chapters_unlocked" */
	update_voyce_user_chapters_unlocked_many?: Array<GraphQLTypes["voyce_user_chapters_unlocked_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_contacts" */
	update_voyce_user_contacts?: GraphQLTypes["voyce_user_contacts_mutation_response"],
	/** update single row of the table: "voyce.user_contacts" */
	update_voyce_user_contacts_by_pk?: GraphQLTypes["voyce_user_contacts"],
	/** update multiples rows of table: "voyce.user_contacts" */
	update_voyce_user_contacts_many?: Array<GraphQLTypes["voyce_user_contacts_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_csat_ratings" */
	update_voyce_user_csat_ratings?: GraphQLTypes["voyce_user_csat_ratings_mutation_response"],
	/** update single row of the table: "voyce.user_csat_ratings" */
	update_voyce_user_csat_ratings_by_pk?: GraphQLTypes["voyce_user_csat_ratings"],
	/** update multiples rows of table: "voyce.user_csat_ratings" */
	update_voyce_user_csat_ratings_many?: Array<GraphQLTypes["voyce_user_csat_ratings_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_education" */
	update_voyce_user_education?: GraphQLTypes["voyce_user_education_mutation_response"],
	/** update single row of the table: "voyce.user_education" */
	update_voyce_user_education_by_pk?: GraphQLTypes["voyce_user_education"],
	/** update multiples rows of table: "voyce.user_education" */
	update_voyce_user_education_many?: Array<GraphQLTypes["voyce_user_education_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_level" */
	update_voyce_user_level?: GraphQLTypes["voyce_user_level_mutation_response"],
	/** update single row of the table: "voyce.user_level" */
	update_voyce_user_level_by_pk?: GraphQLTypes["voyce_user_level"],
	/** update multiples rows of table: "voyce.user_level" */
	update_voyce_user_level_many?: Array<GraphQLTypes["voyce_user_level_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_linked_accounts" */
	update_voyce_user_linked_accounts?: GraphQLTypes["voyce_user_linked_accounts_mutation_response"],
	/** update single row of the table: "voyce.user_linked_accounts" */
	update_voyce_user_linked_accounts_by_pk?: GraphQLTypes["voyce_user_linked_accounts"],
	/** update multiples rows of table: "voyce.user_linked_accounts" */
	update_voyce_user_linked_accounts_many?: Array<GraphQLTypes["voyce_user_linked_accounts_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_login_history" */
	update_voyce_user_login_history?: GraphQLTypes["voyce_user_login_history_mutation_response"],
	/** update single row of the table: "voyce.user_login_history" */
	update_voyce_user_login_history_by_pk?: GraphQLTypes["voyce_user_login_history"],
	/** update multiples rows of table: "voyce.user_login_history" */
	update_voyce_user_login_history_many?: Array<GraphQLTypes["voyce_user_login_history_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_quest_ids" */
	update_voyce_user_quest_ids?: GraphQLTypes["voyce_user_quest_ids_mutation_response"],
	/** update single row of the table: "voyce.user_quest_ids" */
	update_voyce_user_quest_ids_by_pk?: GraphQLTypes["voyce_user_quest_ids"],
	/** update multiples rows of table: "voyce.user_quest_ids" */
	update_voyce_user_quest_ids_many?: Array<GraphQLTypes["voyce_user_quest_ids_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_quest_rewards" */
	update_voyce_user_quest_rewards?: GraphQLTypes["voyce_user_quest_rewards_mutation_response"],
	/** update single row of the table: "voyce.user_quest_rewards" */
	update_voyce_user_quest_rewards_by_pk?: GraphQLTypes["voyce_user_quest_rewards"],
	/** update multiples rows of table: "voyce.user_quest_rewards" */
	update_voyce_user_quest_rewards_many?: Array<GraphQLTypes["voyce_user_quest_rewards_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_quests" */
	update_voyce_user_quests?: GraphQLTypes["voyce_user_quests_mutation_response"],
	/** update single row of the table: "voyce.user_quests" */
	update_voyce_user_quests_by_pk?: GraphQLTypes["voyce_user_quests"],
	/** update multiples rows of table: "voyce.user_quests" */
	update_voyce_user_quests_many?: Array<GraphQLTypes["voyce_user_quests_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_quests_old" */
	update_voyce_user_quests_old?: GraphQLTypes["voyce_user_quests_old_mutation_response"],
	/** update single row of the table: "voyce.user_quests_old" */
	update_voyce_user_quests_old_by_pk?: GraphQLTypes["voyce_user_quests_old"],
	/** update multiples rows of table: "voyce.user_quests_old" */
	update_voyce_user_quests_old_many?: Array<GraphQLTypes["voyce_user_quests_old_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_ranking" */
	update_voyce_user_ranking?: GraphQLTypes["voyce_user_ranking_mutation_response"],
	/** update single row of the table: "voyce.user_ranking" */
	update_voyce_user_ranking_by_pk?: GraphQLTypes["voyce_user_ranking"],
	/** update multiples rows of table: "voyce.user_ranking" */
	update_voyce_user_ranking_many?: Array<GraphQLTypes["voyce_user_ranking_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_reviews" */
	update_voyce_user_reviews?: GraphQLTypes["voyce_user_reviews_mutation_response"],
	/** update single row of the table: "voyce.user_reviews" */
	update_voyce_user_reviews_by_pk?: GraphQLTypes["voyce_user_reviews"],
	/** update multiples rows of table: "voyce.user_reviews" */
	update_voyce_user_reviews_many?: Array<GraphQLTypes["voyce_user_reviews_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_rewards" */
	update_voyce_user_rewards?: GraphQLTypes["voyce_user_rewards_mutation_response"],
	/** update single row of the table: "voyce.user_rewards" */
	update_voyce_user_rewards_by_pk?: GraphQLTypes["voyce_user_rewards"],
	/** update multiples rows of table: "voyce.user_rewards" */
	update_voyce_user_rewards_many?: Array<GraphQLTypes["voyce_user_rewards_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_roles" */
	update_voyce_user_roles?: GraphQLTypes["voyce_user_roles_mutation_response"],
	/** update single row of the table: "voyce.user_roles" */
	update_voyce_user_roles_by_pk?: GraphQLTypes["voyce_user_roles"],
	/** update multiples rows of table: "voyce.user_roles" */
	update_voyce_user_roles_many?: Array<GraphQLTypes["voyce_user_roles_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_settings" */
	update_voyce_user_settings?: GraphQLTypes["voyce_user_settings_mutation_response"],
	/** update single row of the table: "voyce.user_settings" */
	update_voyce_user_settings_by_pk?: GraphQLTypes["voyce_user_settings"],
	/** update multiples rows of table: "voyce.user_settings" */
	update_voyce_user_settings_many?: Array<GraphQLTypes["voyce_user_settings_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_skills" */
	update_voyce_user_skills?: GraphQLTypes["voyce_user_skills_mutation_response"],
	/** update single row of the table: "voyce.user_skills" */
	update_voyce_user_skills_by_pk?: GraphQLTypes["voyce_user_skills"],
	/** update multiples rows of table: "voyce.user_skills" */
	update_voyce_user_skills_many?: Array<GraphQLTypes["voyce_user_skills_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_socials" */
	update_voyce_user_socials?: GraphQLTypes["voyce_user_socials_mutation_response"],
	/** update single row of the table: "voyce.user_socials" */
	update_voyce_user_socials_by_pk?: GraphQLTypes["voyce_user_socials"],
	/** update multiples rows of table: "voyce.user_socials" */
	update_voyce_user_socials_many?: Array<GraphQLTypes["voyce_user_socials_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_soft_deleted" */
	update_voyce_user_soft_deleted?: GraphQLTypes["voyce_user_soft_deleted_mutation_response"],
	/** update single row of the table: "voyce.user_soft_deleted" */
	update_voyce_user_soft_deleted_by_pk?: GraphQLTypes["voyce_user_soft_deleted"],
	/** update multiples rows of table: "voyce.user_soft_deleted" */
	update_voyce_user_soft_deleted_many?: Array<GraphQLTypes["voyce_user_soft_deleted_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_vtags" */
	update_voyce_user_vtags?: GraphQLTypes["voyce_user_vtags_mutation_response"],
	/** update single row of the table: "voyce.user_vtags" */
	update_voyce_user_vtags_by_pk?: GraphQLTypes["voyce_user_vtags"],
	/** update multiples rows of table: "voyce.user_vtags" */
	update_voyce_user_vtags_many?: Array<GraphQLTypes["voyce_user_vtags_mutation_response"] | undefined>,
	/** update data of the table: "voyce.user_wins" */
	update_voyce_user_wins?: GraphQLTypes["voyce_user_wins_mutation_response"],
	/** update single row of the table: "voyce.user_wins" */
	update_voyce_user_wins_by_pk?: GraphQLTypes["voyce_user_wins"],
	/** update multiples rows of table: "voyce.user_wins" */
	update_voyce_user_wins_many?: Array<GraphQLTypes["voyce_user_wins_mutation_response"] | undefined>,
	/** update data of the table: "voyce.users" */
	update_voyce_users?: GraphQLTypes["voyce_users_mutation_response"],
	/** update single row of the table: "voyce.users" */
	update_voyce_users_by_pk?: GraphQLTypes["voyce_users"],
	/** update data of the table: "voyce.users_followers" */
	update_voyce_users_followers?: GraphQLTypes["voyce_users_followers_mutation_response"],
	/** update single row of the table: "voyce.users_followers" */
	update_voyce_users_followers_by_pk?: GraphQLTypes["voyce_users_followers"],
	/** update multiples rows of table: "voyce.users_followers" */
	update_voyce_users_followers_many?: Array<GraphQLTypes["voyce_users_followers_mutation_response"] | undefined>,
	/** update multiples rows of table: "voyce.users" */
	update_voyce_users_many?: Array<GraphQLTypes["voyce_users_mutation_response"] | undefined>,
	/** update data of the table: "voyce.users_private" */
	update_voyce_users_private?: GraphQLTypes["voyce_users_private_mutation_response"],
	/** update multiples rows of table: "voyce.users_private" */
	update_voyce_users_private_many?: Array<GraphQLTypes["voyce_users_private_mutation_response"] | undefined>,
	/** update data of the table: "voyce.users_series_junction" */
	update_voyce_users_series_junction?: GraphQLTypes["voyce_users_series_junction_mutation_response"],
	/** update single row of the table: "voyce.users_series_junction" */
	update_voyce_users_series_junction_by_pk?: GraphQLTypes["voyce_users_series_junction"],
	/** update multiples rows of table: "voyce.users_series_junction" */
	update_voyce_users_series_junction_many?: Array<GraphQLTypes["voyce_users_series_junction_mutation_response"] | undefined>,
	/** update data of the table: "voyce.versions" */
	update_voyce_versions?: GraphQLTypes["voyce_versions_mutation_response"],
	/** update single row of the table: "voyce.versions" */
	update_voyce_versions_by_pk?: GraphQLTypes["voyce_versions"],
	/** update multiples rows of table: "voyce.versions" */
	update_voyce_versions_many?: Array<GraphQLTypes["voyce_versions_mutation_response"] | undefined>,
	/** update data of the table: "voyce.voting_analytics" */
	update_voyce_voting_analytics?: GraphQLTypes["voyce_voting_analytics_mutation_response"],
	/** update single row of the table: "voyce.voting_analytics" */
	update_voyce_voting_analytics_by_pk?: GraphQLTypes["voyce_voting_analytics"],
	/** update multiples rows of table: "voyce.voting_analytics" */
	update_voyce_voting_analytics_many?: Array<GraphQLTypes["voyce_voting_analytics_mutation_response"] | undefined>,
	/** update data of the table: "voyce.voting_panels" */
	update_voyce_voting_panels?: GraphQLTypes["voyce_voting_panels_mutation_response"],
	/** update single row of the table: "voyce.voting_panels" */
	update_voyce_voting_panels_by_pk?: GraphQLTypes["voyce_voting_panels"],
	/** update multiples rows of table: "voyce.voting_panels" */
	update_voyce_voting_panels_many?: Array<GraphQLTypes["voyce_voting_panels_mutation_response"] | undefined>,
	/** update data of the table: "voyce.voting_series" */
	update_voyce_voting_series?: GraphQLTypes["voyce_voting_series_mutation_response"],
	/** update single row of the table: "voyce.voting_series" */
	update_voyce_voting_series_by_pk?: GraphQLTypes["voyce_voting_series"],
	/** update multiples rows of table: "voyce.voting_series" */
	update_voyce_voting_series_many?: Array<GraphQLTypes["voyce_voting_series_mutation_response"] | undefined>,
	/** update data of the table: "voyce.voting_user_votes" */
	update_voyce_voting_user_votes?: GraphQLTypes["voyce_voting_user_votes_mutation_response"],
	/** update single row of the table: "voyce.voting_user_votes" */
	update_voyce_voting_user_votes_by_pk?: GraphQLTypes["voyce_voting_user_votes"],
	/** update multiples rows of table: "voyce.voting_user_votes" */
	update_voyce_voting_user_votes_many?: Array<GraphQLTypes["voyce_voting_user_votes_mutation_response"] | undefined>
};
	/** columns and relationships of "mysql.user" */
["mysql_user"]: {
	__typename: "mysql_user",
	account_locked: string,
	alter_priv: string,
	alter_routine_priv: string,
	authentication_string?: string,
	create_priv: string,
	create_role_priv: string,
	create_routine_priv: string,
	create_tablespace_priv: string,
	create_tmp_table_priv: string,
	create_user_priv: string,
	create_view_priv: string,
	delete_priv: string,
	drop_priv: string,
	drop_role_priv: string,
	event_priv: string,
	execute_priv: string,
	file_priv: string,
	grant_priv: string,
	host: string,
	index_priv: string,
	insert_priv: string,
	lock_tables_priv: string,
	max_connections: GraphQLTypes["bigint"],
	max_questions: GraphQLTypes["bigint"],
	max_updates: GraphQLTypes["bigint"],
	max_user_connections: GraphQLTypes["bigint"],
	password_expired: string,
	password_last_changed?: GraphQLTypes["timestamp"],
	password_lifetime?: number,
	password_require_current?: string,
	password_reuse_history?: number,
	password_reuse_time?: number,
	plugin: string,
	process_priv: string,
	references_priv: string,
	reload_priv: string,
	repl_client_priv: string,
	repl_slave_priv: string,
	select_priv: string,
	show_db_priv: string,
	show_view_priv: string,
	shutdown_priv: string,
	ssl_cipher: GraphQLTypes["bytea"],
	ssl_type: string,
	super_priv: string,
	trigger_priv: string,
	update_priv: string,
	user: string,
	user_attributes?: string,
	x509_issuer: GraphQLTypes["bytea"],
	x509_subject: GraphQLTypes["bytea"]
};
	/** aggregated selection of "mysql.user" */
["mysql_user_aggregate"]: {
	__typename: "mysql_user_aggregate",
	aggregate?: GraphQLTypes["mysql_user_aggregate_fields"],
	nodes: Array<GraphQLTypes["mysql_user"]>
};
	/** aggregate fields of "mysql.user" */
["mysql_user_aggregate_fields"]: {
	__typename: "mysql_user_aggregate_fields",
	avg?: GraphQLTypes["mysql_user_avg_fields"],
	count: number,
	max?: GraphQLTypes["mysql_user_max_fields"],
	min?: GraphQLTypes["mysql_user_min_fields"],
	stddev?: GraphQLTypes["mysql_user_stddev_fields"],
	stddev_pop?: GraphQLTypes["mysql_user_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["mysql_user_stddev_samp_fields"],
	sum?: GraphQLTypes["mysql_user_sum_fields"],
	var_pop?: GraphQLTypes["mysql_user_var_pop_fields"],
	var_samp?: GraphQLTypes["mysql_user_var_samp_fields"],
	variance?: GraphQLTypes["mysql_user_variance_fields"]
};
	/** aggregate avg on columns */
["mysql_user_avg_fields"]: {
	__typename: "mysql_user_avg_fields",
	max_connections?: number,
	max_questions?: number,
	max_updates?: number,
	max_user_connections?: number,
	password_lifetime?: number,
	password_reuse_history?: number,
	password_reuse_time?: number
};
	/** Boolean expression to filter rows from the table "mysql.user". All fields are combined with a logical 'AND'. */
["mysql_user_bool_exp"]: {
		_and?: Array<GraphQLTypes["mysql_user_bool_exp"]>,
	_not?: GraphQLTypes["mysql_user_bool_exp"],
	_or?: Array<GraphQLTypes["mysql_user_bool_exp"]>,
	account_locked?: GraphQLTypes["String_comparison_exp"],
	alter_priv?: GraphQLTypes["String_comparison_exp"],
	alter_routine_priv?: GraphQLTypes["String_comparison_exp"],
	authentication_string?: GraphQLTypes["String_comparison_exp"],
	create_priv?: GraphQLTypes["String_comparison_exp"],
	create_role_priv?: GraphQLTypes["String_comparison_exp"],
	create_routine_priv?: GraphQLTypes["String_comparison_exp"],
	create_tablespace_priv?: GraphQLTypes["String_comparison_exp"],
	create_tmp_table_priv?: GraphQLTypes["String_comparison_exp"],
	create_user_priv?: GraphQLTypes["String_comparison_exp"],
	create_view_priv?: GraphQLTypes["String_comparison_exp"],
	delete_priv?: GraphQLTypes["String_comparison_exp"],
	drop_priv?: GraphQLTypes["String_comparison_exp"],
	drop_role_priv?: GraphQLTypes["String_comparison_exp"],
	event_priv?: GraphQLTypes["String_comparison_exp"],
	execute_priv?: GraphQLTypes["String_comparison_exp"],
	file_priv?: GraphQLTypes["String_comparison_exp"],
	grant_priv?: GraphQLTypes["String_comparison_exp"],
	host?: GraphQLTypes["String_comparison_exp"],
	index_priv?: GraphQLTypes["String_comparison_exp"],
	insert_priv?: GraphQLTypes["String_comparison_exp"],
	lock_tables_priv?: GraphQLTypes["String_comparison_exp"],
	max_connections?: GraphQLTypes["bigint_comparison_exp"],
	max_questions?: GraphQLTypes["bigint_comparison_exp"],
	max_updates?: GraphQLTypes["bigint_comparison_exp"],
	max_user_connections?: GraphQLTypes["bigint_comparison_exp"],
	password_expired?: GraphQLTypes["String_comparison_exp"],
	password_last_changed?: GraphQLTypes["timestamp_comparison_exp"],
	password_lifetime?: GraphQLTypes["Int_comparison_exp"],
	password_require_current?: GraphQLTypes["String_comparison_exp"],
	password_reuse_history?: GraphQLTypes["Int_comparison_exp"],
	password_reuse_time?: GraphQLTypes["Int_comparison_exp"],
	plugin?: GraphQLTypes["String_comparison_exp"],
	process_priv?: GraphQLTypes["String_comparison_exp"],
	references_priv?: GraphQLTypes["String_comparison_exp"],
	reload_priv?: GraphQLTypes["String_comparison_exp"],
	repl_client_priv?: GraphQLTypes["String_comparison_exp"],
	repl_slave_priv?: GraphQLTypes["String_comparison_exp"],
	select_priv?: GraphQLTypes["String_comparison_exp"],
	show_db_priv?: GraphQLTypes["String_comparison_exp"],
	show_view_priv?: GraphQLTypes["String_comparison_exp"],
	shutdown_priv?: GraphQLTypes["String_comparison_exp"],
	ssl_cipher?: GraphQLTypes["bytea_comparison_exp"],
	ssl_type?: GraphQLTypes["String_comparison_exp"],
	super_priv?: GraphQLTypes["String_comparison_exp"],
	trigger_priv?: GraphQLTypes["String_comparison_exp"],
	update_priv?: GraphQLTypes["String_comparison_exp"],
	user?: GraphQLTypes["String_comparison_exp"],
	user_attributes?: GraphQLTypes["String_comparison_exp"],
	x509_issuer?: GraphQLTypes["bytea_comparison_exp"],
	x509_subject?: GraphQLTypes["bytea_comparison_exp"]
};
	/** unique or primary key constraints on table "mysql.user" */
["mysql_user_constraint"]: mysql_user_constraint;
	/** input type for incrementing numeric columns in table "mysql.user" */
["mysql_user_inc_input"]: {
		max_connections?: GraphQLTypes["bigint"],
	max_questions?: GraphQLTypes["bigint"],
	max_updates?: GraphQLTypes["bigint"],
	max_user_connections?: GraphQLTypes["bigint"],
	password_lifetime?: number,
	password_reuse_history?: number,
	password_reuse_time?: number
};
	/** input type for inserting data into table "mysql.user" */
["mysql_user_insert_input"]: {
		account_locked?: string,
	alter_priv?: string,
	alter_routine_priv?: string,
	authentication_string?: string,
	create_priv?: string,
	create_role_priv?: string,
	create_routine_priv?: string,
	create_tablespace_priv?: string,
	create_tmp_table_priv?: string,
	create_user_priv?: string,
	create_view_priv?: string,
	delete_priv?: string,
	drop_priv?: string,
	drop_role_priv?: string,
	event_priv?: string,
	execute_priv?: string,
	file_priv?: string,
	grant_priv?: string,
	host?: string,
	index_priv?: string,
	insert_priv?: string,
	lock_tables_priv?: string,
	max_connections?: GraphQLTypes["bigint"],
	max_questions?: GraphQLTypes["bigint"],
	max_updates?: GraphQLTypes["bigint"],
	max_user_connections?: GraphQLTypes["bigint"],
	password_expired?: string,
	password_last_changed?: GraphQLTypes["timestamp"],
	password_lifetime?: number,
	password_require_current?: string,
	password_reuse_history?: number,
	password_reuse_time?: number,
	plugin?: string,
	process_priv?: string,
	references_priv?: string,
	reload_priv?: string,
	repl_client_priv?: string,
	repl_slave_priv?: string,
	select_priv?: string,
	show_db_priv?: string,
	show_view_priv?: string,
	shutdown_priv?: string,
	ssl_cipher?: GraphQLTypes["bytea"],
	ssl_type?: string,
	super_priv?: string,
	trigger_priv?: string,
	update_priv?: string,
	user?: string,
	user_attributes?: string,
	x509_issuer?: GraphQLTypes["bytea"],
	x509_subject?: GraphQLTypes["bytea"]
};
	/** aggregate max on columns */
["mysql_user_max_fields"]: {
	__typename: "mysql_user_max_fields",
	account_locked?: string,
	alter_priv?: string,
	alter_routine_priv?: string,
	authentication_string?: string,
	create_priv?: string,
	create_role_priv?: string,
	create_routine_priv?: string,
	create_tablespace_priv?: string,
	create_tmp_table_priv?: string,
	create_user_priv?: string,
	create_view_priv?: string,
	delete_priv?: string,
	drop_priv?: string,
	drop_role_priv?: string,
	event_priv?: string,
	execute_priv?: string,
	file_priv?: string,
	grant_priv?: string,
	host?: string,
	index_priv?: string,
	insert_priv?: string,
	lock_tables_priv?: string,
	max_connections?: GraphQLTypes["bigint"],
	max_questions?: GraphQLTypes["bigint"],
	max_updates?: GraphQLTypes["bigint"],
	max_user_connections?: GraphQLTypes["bigint"],
	password_expired?: string,
	password_last_changed?: GraphQLTypes["timestamp"],
	password_lifetime?: number,
	password_require_current?: string,
	password_reuse_history?: number,
	password_reuse_time?: number,
	plugin?: string,
	process_priv?: string,
	references_priv?: string,
	reload_priv?: string,
	repl_client_priv?: string,
	repl_slave_priv?: string,
	select_priv?: string,
	show_db_priv?: string,
	show_view_priv?: string,
	shutdown_priv?: string,
	ssl_type?: string,
	super_priv?: string,
	trigger_priv?: string,
	update_priv?: string,
	user?: string,
	user_attributes?: string
};
	/** aggregate min on columns */
["mysql_user_min_fields"]: {
	__typename: "mysql_user_min_fields",
	account_locked?: string,
	alter_priv?: string,
	alter_routine_priv?: string,
	authentication_string?: string,
	create_priv?: string,
	create_role_priv?: string,
	create_routine_priv?: string,
	create_tablespace_priv?: string,
	create_tmp_table_priv?: string,
	create_user_priv?: string,
	create_view_priv?: string,
	delete_priv?: string,
	drop_priv?: string,
	drop_role_priv?: string,
	event_priv?: string,
	execute_priv?: string,
	file_priv?: string,
	grant_priv?: string,
	host?: string,
	index_priv?: string,
	insert_priv?: string,
	lock_tables_priv?: string,
	max_connections?: GraphQLTypes["bigint"],
	max_questions?: GraphQLTypes["bigint"],
	max_updates?: GraphQLTypes["bigint"],
	max_user_connections?: GraphQLTypes["bigint"],
	password_expired?: string,
	password_last_changed?: GraphQLTypes["timestamp"],
	password_lifetime?: number,
	password_require_current?: string,
	password_reuse_history?: number,
	password_reuse_time?: number,
	plugin?: string,
	process_priv?: string,
	references_priv?: string,
	reload_priv?: string,
	repl_client_priv?: string,
	repl_slave_priv?: string,
	select_priv?: string,
	show_db_priv?: string,
	show_view_priv?: string,
	shutdown_priv?: string,
	ssl_type?: string,
	super_priv?: string,
	trigger_priv?: string,
	update_priv?: string,
	user?: string,
	user_attributes?: string
};
	/** response of any mutation on the table "mysql.user" */
["mysql_user_mutation_response"]: {
	__typename: "mysql_user_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["mysql_user"]>
};
	/** on_conflict condition type for table "mysql.user" */
["mysql_user_on_conflict"]: {
		constraint: GraphQLTypes["mysql_user_constraint"],
	update_columns: Array<GraphQLTypes["mysql_user_update_column"]>,
	where?: GraphQLTypes["mysql_user_bool_exp"]
};
	/** Ordering options when selecting data from "mysql.user". */
["mysql_user_order_by"]: {
		account_locked?: GraphQLTypes["order_by"],
	alter_priv?: GraphQLTypes["order_by"],
	alter_routine_priv?: GraphQLTypes["order_by"],
	authentication_string?: GraphQLTypes["order_by"],
	create_priv?: GraphQLTypes["order_by"],
	create_role_priv?: GraphQLTypes["order_by"],
	create_routine_priv?: GraphQLTypes["order_by"],
	create_tablespace_priv?: GraphQLTypes["order_by"],
	create_tmp_table_priv?: GraphQLTypes["order_by"],
	create_user_priv?: GraphQLTypes["order_by"],
	create_view_priv?: GraphQLTypes["order_by"],
	delete_priv?: GraphQLTypes["order_by"],
	drop_priv?: GraphQLTypes["order_by"],
	drop_role_priv?: GraphQLTypes["order_by"],
	event_priv?: GraphQLTypes["order_by"],
	execute_priv?: GraphQLTypes["order_by"],
	file_priv?: GraphQLTypes["order_by"],
	grant_priv?: GraphQLTypes["order_by"],
	host?: GraphQLTypes["order_by"],
	index_priv?: GraphQLTypes["order_by"],
	insert_priv?: GraphQLTypes["order_by"],
	lock_tables_priv?: GraphQLTypes["order_by"],
	max_connections?: GraphQLTypes["order_by"],
	max_questions?: GraphQLTypes["order_by"],
	max_updates?: GraphQLTypes["order_by"],
	max_user_connections?: GraphQLTypes["order_by"],
	password_expired?: GraphQLTypes["order_by"],
	password_last_changed?: GraphQLTypes["order_by"],
	password_lifetime?: GraphQLTypes["order_by"],
	password_require_current?: GraphQLTypes["order_by"],
	password_reuse_history?: GraphQLTypes["order_by"],
	password_reuse_time?: GraphQLTypes["order_by"],
	plugin?: GraphQLTypes["order_by"],
	process_priv?: GraphQLTypes["order_by"],
	references_priv?: GraphQLTypes["order_by"],
	reload_priv?: GraphQLTypes["order_by"],
	repl_client_priv?: GraphQLTypes["order_by"],
	repl_slave_priv?: GraphQLTypes["order_by"],
	select_priv?: GraphQLTypes["order_by"],
	show_db_priv?: GraphQLTypes["order_by"],
	show_view_priv?: GraphQLTypes["order_by"],
	shutdown_priv?: GraphQLTypes["order_by"],
	ssl_cipher?: GraphQLTypes["order_by"],
	ssl_type?: GraphQLTypes["order_by"],
	super_priv?: GraphQLTypes["order_by"],
	trigger_priv?: GraphQLTypes["order_by"],
	update_priv?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["order_by"],
	user_attributes?: GraphQLTypes["order_by"],
	x509_issuer?: GraphQLTypes["order_by"],
	x509_subject?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: mysql.user */
["mysql_user_pk_columns_input"]: {
		host: string,
	user: string
};
	/** select columns of table "mysql.user" */
["mysql_user_select_column"]: mysql_user_select_column;
	/** input type for updating data in table "mysql.user" */
["mysql_user_set_input"]: {
		account_locked?: string,
	alter_priv?: string,
	alter_routine_priv?: string,
	authentication_string?: string,
	create_priv?: string,
	create_role_priv?: string,
	create_routine_priv?: string,
	create_tablespace_priv?: string,
	create_tmp_table_priv?: string,
	create_user_priv?: string,
	create_view_priv?: string,
	delete_priv?: string,
	drop_priv?: string,
	drop_role_priv?: string,
	event_priv?: string,
	execute_priv?: string,
	file_priv?: string,
	grant_priv?: string,
	host?: string,
	index_priv?: string,
	insert_priv?: string,
	lock_tables_priv?: string,
	max_connections?: GraphQLTypes["bigint"],
	max_questions?: GraphQLTypes["bigint"],
	max_updates?: GraphQLTypes["bigint"],
	max_user_connections?: GraphQLTypes["bigint"],
	password_expired?: string,
	password_last_changed?: GraphQLTypes["timestamp"],
	password_lifetime?: number,
	password_require_current?: string,
	password_reuse_history?: number,
	password_reuse_time?: number,
	plugin?: string,
	process_priv?: string,
	references_priv?: string,
	reload_priv?: string,
	repl_client_priv?: string,
	repl_slave_priv?: string,
	select_priv?: string,
	show_db_priv?: string,
	show_view_priv?: string,
	shutdown_priv?: string,
	ssl_cipher?: GraphQLTypes["bytea"],
	ssl_type?: string,
	super_priv?: string,
	trigger_priv?: string,
	update_priv?: string,
	user?: string,
	user_attributes?: string,
	x509_issuer?: GraphQLTypes["bytea"],
	x509_subject?: GraphQLTypes["bytea"]
};
	/** aggregate stddev on columns */
["mysql_user_stddev_fields"]: {
	__typename: "mysql_user_stddev_fields",
	max_connections?: number,
	max_questions?: number,
	max_updates?: number,
	max_user_connections?: number,
	password_lifetime?: number,
	password_reuse_history?: number,
	password_reuse_time?: number
};
	/** aggregate stddev_pop on columns */
["mysql_user_stddev_pop_fields"]: {
	__typename: "mysql_user_stddev_pop_fields",
	max_connections?: number,
	max_questions?: number,
	max_updates?: number,
	max_user_connections?: number,
	password_lifetime?: number,
	password_reuse_history?: number,
	password_reuse_time?: number
};
	/** aggregate stddev_samp on columns */
["mysql_user_stddev_samp_fields"]: {
	__typename: "mysql_user_stddev_samp_fields",
	max_connections?: number,
	max_questions?: number,
	max_updates?: number,
	max_user_connections?: number,
	password_lifetime?: number,
	password_reuse_history?: number,
	password_reuse_time?: number
};
	/** Streaming cursor of the table "mysql_user" */
["mysql_user_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["mysql_user_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["mysql_user_stream_cursor_value_input"]: {
		account_locked?: string,
	alter_priv?: string,
	alter_routine_priv?: string,
	authentication_string?: string,
	create_priv?: string,
	create_role_priv?: string,
	create_routine_priv?: string,
	create_tablespace_priv?: string,
	create_tmp_table_priv?: string,
	create_user_priv?: string,
	create_view_priv?: string,
	delete_priv?: string,
	drop_priv?: string,
	drop_role_priv?: string,
	event_priv?: string,
	execute_priv?: string,
	file_priv?: string,
	grant_priv?: string,
	host?: string,
	index_priv?: string,
	insert_priv?: string,
	lock_tables_priv?: string,
	max_connections?: GraphQLTypes["bigint"],
	max_questions?: GraphQLTypes["bigint"],
	max_updates?: GraphQLTypes["bigint"],
	max_user_connections?: GraphQLTypes["bigint"],
	password_expired?: string,
	password_last_changed?: GraphQLTypes["timestamp"],
	password_lifetime?: number,
	password_require_current?: string,
	password_reuse_history?: number,
	password_reuse_time?: number,
	plugin?: string,
	process_priv?: string,
	references_priv?: string,
	reload_priv?: string,
	repl_client_priv?: string,
	repl_slave_priv?: string,
	select_priv?: string,
	show_db_priv?: string,
	show_view_priv?: string,
	shutdown_priv?: string,
	ssl_cipher?: GraphQLTypes["bytea"],
	ssl_type?: string,
	super_priv?: string,
	trigger_priv?: string,
	update_priv?: string,
	user?: string,
	user_attributes?: string,
	x509_issuer?: GraphQLTypes["bytea"],
	x509_subject?: GraphQLTypes["bytea"]
};
	/** aggregate sum on columns */
["mysql_user_sum_fields"]: {
	__typename: "mysql_user_sum_fields",
	max_connections?: GraphQLTypes["bigint"],
	max_questions?: GraphQLTypes["bigint"],
	max_updates?: GraphQLTypes["bigint"],
	max_user_connections?: GraphQLTypes["bigint"],
	password_lifetime?: number,
	password_reuse_history?: number,
	password_reuse_time?: number
};
	/** update columns of table "mysql.user" */
["mysql_user_update_column"]: mysql_user_update_column;
	["mysql_user_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["mysql_user_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["mysql_user_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["mysql_user_bool_exp"]
};
	/** aggregate var_pop on columns */
["mysql_user_var_pop_fields"]: {
	__typename: "mysql_user_var_pop_fields",
	max_connections?: number,
	max_questions?: number,
	max_updates?: number,
	max_user_connections?: number,
	password_lifetime?: number,
	password_reuse_history?: number,
	password_reuse_time?: number
};
	/** aggregate var_samp on columns */
["mysql_user_var_samp_fields"]: {
	__typename: "mysql_user_var_samp_fields",
	max_connections?: number,
	max_questions?: number,
	max_updates?: number,
	max_user_connections?: number,
	password_lifetime?: number,
	password_reuse_history?: number,
	password_reuse_time?: number
};
	/** aggregate variance on columns */
["mysql_user_variance_fields"]: {
	__typename: "mysql_user_variance_fields",
	max_connections?: number,
	max_questions?: number,
	max_updates?: number,
	max_user_connections?: number,
	password_lifetime?: number,
	password_reuse_history?: number,
	password_reuse_time?: number
};
	["numeric"]:any;
	/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
["numeric_comparison_exp"]: {
		_eq?: GraphQLTypes["numeric"],
	_gt?: GraphQLTypes["numeric"],
	_gte?: GraphQLTypes["numeric"],
	_in?: Array<GraphQLTypes["numeric"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["numeric"],
	_lte?: GraphQLTypes["numeric"],
	_neq?: GraphQLTypes["numeric"],
	_nin?: Array<GraphQLTypes["numeric"]>
};
	/** column ordering options */
["order_by"]: order_by;
	["query_root"]: {
	__typename: "query_root",
	/** fetch data from the table: "awsdms_validation_failures_v1" */
	awsdms_validation_failures_v1: Array<GraphQLTypes["awsdms_validation_failures_v1"]>,
	/** fetch aggregated fields from the table: "awsdms_validation_failures_v1" */
	awsdms_validation_failures_v1_aggregate: GraphQLTypes["awsdms_validation_failures_v1_aggregate"],
	/** fetch data from the table: "coinsystem.balances" */
	coinsystem_balances: Array<GraphQLTypes["coinsystem_balances"]>,
	/** fetch aggregated fields from the table: "coinsystem.balances" */
	coinsystem_balances_aggregate: GraphQLTypes["coinsystem_balances_aggregate"],
	/** fetch data from the table: "coinsystem.balances" using primary key columns */
	coinsystem_balances_by_pk?: GraphQLTypes["coinsystem_balances"],
	/** fetch data from the table: "coinsystem.bonus_coins" */
	coinsystem_bonus_coins: Array<GraphQLTypes["coinsystem_bonus_coins"]>,
	/** fetch aggregated fields from the table: "coinsystem.bonus_coins" */
	coinsystem_bonus_coins_aggregate: GraphQLTypes["coinsystem_bonus_coins_aggregate"],
	/** fetch data from the table: "coinsystem.bonus_coins" using primary key columns */
	coinsystem_bonus_coins_by_pk?: GraphQLTypes["coinsystem_bonus_coins"],
	/** fetch data from the table: "coinsystem.coins" */
	coinsystem_coins: Array<GraphQLTypes["coinsystem_coins"]>,
	/** fetch aggregated fields from the table: "coinsystem.coins" */
	coinsystem_coins_aggregate: GraphQLTypes["coinsystem_coins_aggregate"],
	/** fetch data from the table: "coinsystem.coins" using primary key columns */
	coinsystem_coins_by_pk?: GraphQLTypes["coinsystem_coins"],
	/** fetch data from the table: "coinsystem.exchange_rates" */
	coinsystem_exchange_rates: Array<GraphQLTypes["coinsystem_exchange_rates"]>,
	/** fetch aggregated fields from the table: "coinsystem.exchange_rates" */
	coinsystem_exchange_rates_aggregate: GraphQLTypes["coinsystem_exchange_rates_aggregate"],
	/** fetch data from the table: "coinsystem.exchange_rates" using primary key columns */
	coinsystem_exchange_rates_by_pk?: GraphQLTypes["coinsystem_exchange_rates"],
	/** fetch data from the table: "coinsystem.logs" */
	coinsystem_logs: Array<GraphQLTypes["coinsystem_logs"]>,
	/** fetch aggregated fields from the table: "coinsystem.logs" */
	coinsystem_logs_aggregate: GraphQLTypes["coinsystem_logs_aggregate"],
	/** fetch data from the table: "coinsystem.logs" using primary key columns */
	coinsystem_logs_by_pk?: GraphQLTypes["coinsystem_logs"],
	/** fetch data from the table: "coinsystem.transactions" */
	coinsystem_transactions: Array<GraphQLTypes["coinsystem_transactions"]>,
	/** fetch aggregated fields from the table: "coinsystem.transactions" */
	coinsystem_transactions_aggregate: GraphQLTypes["coinsystem_transactions_aggregate"],
	/** fetch data from the table: "coinsystem.transactions" using primary key columns */
	coinsystem_transactions_by_pk?: GraphQLTypes["coinsystem_transactions"],
	/** fetch data from the table: "coinsystem.wallets" */
	coinsystem_wallets: Array<GraphQLTypes["coinsystem_wallets"]>,
	/** fetch aggregated fields from the table: "coinsystem.wallets" */
	coinsystem_wallets_aggregate: GraphQLTypes["coinsystem_wallets_aggregate"],
	/** fetch data from the table: "coinsystem.wallets" using primary key columns */
	coinsystem_wallets_by_pk?: GraphQLTypes["coinsystem_wallets"],
	/** fetch data from the table: "mysql.user" */
	mysql_user: Array<GraphQLTypes["mysql_user"]>,
	/** fetch aggregated fields from the table: "mysql.user" */
	mysql_user_aggregate: GraphQLTypes["mysql_user_aggregate"],
	/** fetch data from the table: "mysql.user" using primary key columns */
	mysql_user_by_pk?: GraphQLTypes["mysql_user"],
	/** fetch data from the table: "store.payments" */
	store_payments: Array<GraphQLTypes["store_payments"]>,
	/** fetch aggregated fields from the table: "store.payments" */
	store_payments_aggregate: GraphQLTypes["store_payments_aggregate"],
	/** fetch data from the table: "store.payments" using primary key columns */
	store_payments_by_pk?: GraphQLTypes["store_payments"],
	/** fetch data from the table: "store.products" */
	store_products: Array<GraphQLTypes["store_products"]>,
	/** fetch aggregated fields from the table: "store.products" */
	store_products_aggregate: GraphQLTypes["store_products_aggregate"],
	/** fetch data from the table: "store.products" using primary key columns */
	store_products_by_pk?: GraphQLTypes["store_products"],
	/** fetch data from the table: "store.purchases" */
	store_purchases: Array<GraphQLTypes["store_purchases"]>,
	/** fetch aggregated fields from the table: "store.purchases" */
	store_purchases_aggregate: GraphQLTypes["store_purchases_aggregate"],
	/** fetch data from the table: "store.purchases" using primary key columns */
	store_purchases_by_pk?: GraphQLTypes["store_purchases"],
	/** fetch data from the table: "store.users" */
	store_users: Array<GraphQLTypes["store_users"]>,
	/** fetch aggregated fields from the table: "store.users" */
	store_users_aggregate: GraphQLTypes["store_users_aggregate"],
	test?: GraphQLTypes["AddResult"],
	/** fetch data from the table: "voyce.admin_roles" */
	voyce_admin_roles: Array<GraphQLTypes["voyce_admin_roles"]>,
	/** fetch aggregated fields from the table: "voyce.admin_roles" */
	voyce_admin_roles_aggregate: GraphQLTypes["voyce_admin_roles_aggregate"],
	/** fetch data from the table: "voyce.admin_roles" using primary key columns */
	voyce_admin_roles_by_pk?: GraphQLTypes["voyce_admin_roles"],
	/** fetch data from the table: "voyce.app_banners" */
	voyce_app_banners: Array<GraphQLTypes["voyce_app_banners"]>,
	/** fetch aggregated fields from the table: "voyce.app_banners" */
	voyce_app_banners_aggregate: GraphQLTypes["voyce_app_banners_aggregate"],
	/** fetch data from the table: "voyce.app_banners" using primary key columns */
	voyce_app_banners_by_pk?: GraphQLTypes["voyce_app_banners"],
	/** fetch data from the table: "voyce.app_components" */
	voyce_app_components: Array<GraphQLTypes["voyce_app_components"]>,
	/** fetch aggregated fields from the table: "voyce.app_components" */
	voyce_app_components_aggregate: GraphQLTypes["voyce_app_components_aggregate"],
	/** fetch data from the table: "voyce.app_components" using primary key columns */
	voyce_app_components_by_pk?: GraphQLTypes["voyce_app_components"],
	/** fetch data from the table: "voyce.audit_trail" */
	voyce_audit_trail: Array<GraphQLTypes["voyce_audit_trail"]>,
	/** fetch aggregated fields from the table: "voyce.audit_trail" */
	voyce_audit_trail_aggregate: GraphQLTypes["voyce_audit_trail_aggregate"],
	/** fetch data from the table: "voyce.audit_trail" using primary key columns */
	voyce_audit_trail_by_pk?: GraphQLTypes["voyce_audit_trail"],
	/** fetch data from the table: "voyce.author_profiles" */
	voyce_author_profiles: Array<GraphQLTypes["voyce_author_profiles"]>,
	/** fetch aggregated fields from the table: "voyce.author_profiles" */
	voyce_author_profiles_aggregate: GraphQLTypes["voyce_author_profiles_aggregate"],
	/** fetch data from the table: "voyce.author_profiles" using primary key columns */
	voyce_author_profiles_by_pk?: GraphQLTypes["voyce_author_profiles"],
	/** fetch data from the table: "voyce.balances" */
	voyce_balances: Array<GraphQLTypes["voyce_balances"]>,
	/** fetch aggregated fields from the table: "voyce.balances" */
	voyce_balances_aggregate: GraphQLTypes["voyce_balances_aggregate"],
	/** fetch data from the table: "voyce.balances" using primary key columns */
	voyce_balances_by_pk?: GraphQLTypes["voyce_balances"],
	/** fetch data from the table: "voyce.banners" */
	voyce_banners: Array<GraphQLTypes["voyce_banners"]>,
	/** fetch aggregated fields from the table: "voyce.banners" */
	voyce_banners_aggregate: GraphQLTypes["voyce_banners_aggregate"],
	/** fetch data from the table: "voyce.banners" using primary key columns */
	voyce_banners_by_pk?: GraphQLTypes["voyce_banners"],
	/** fetch data from the table: "voyce.chapter_assets" */
	voyce_chapter_assets: Array<GraphQLTypes["voyce_chapter_assets"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_assets" */
	voyce_chapter_assets_aggregate: GraphQLTypes["voyce_chapter_assets_aggregate"],
	/** fetch data from the table: "voyce.chapter_assets" using primary key columns */
	voyce_chapter_assets_by_pk?: GraphQLTypes["voyce_chapter_assets"],
	/** fetch data from the table: "voyce.chapter_bookmarks" */
	voyce_chapter_bookmarks: Array<GraphQLTypes["voyce_chapter_bookmarks"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_bookmarks" */
	voyce_chapter_bookmarks_aggregate: GraphQLTypes["voyce_chapter_bookmarks_aggregate"],
	/** fetch data from the table: "voyce.chapter_bookmarks" using primary key columns */
	voyce_chapter_bookmarks_by_pk?: GraphQLTypes["voyce_chapter_bookmarks"],
	/** fetch data from the table: "voyce.chapter_comments" */
	voyce_chapter_comments: Array<GraphQLTypes["voyce_chapter_comments"]>,
	/** fetch data from the table: "voyce.chapter_comments_07252024" */
	voyce_chapter_comments_07252024: Array<GraphQLTypes["voyce_chapter_comments_07252024"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_comments_07252024" */
	voyce_chapter_comments_07252024_aggregate: GraphQLTypes["voyce_chapter_comments_07252024_aggregate"],
	/** fetch aggregated fields from the table: "voyce.chapter_comments" */
	voyce_chapter_comments_aggregate: GraphQLTypes["voyce_chapter_comments_aggregate"],
	/** fetch data from the table: "voyce.chapter_comments" using primary key columns */
	voyce_chapter_comments_by_pk?: GraphQLTypes["voyce_chapter_comments"],
	/** fetch data from the table: "voyce.chapter_comments_reaction" */
	voyce_chapter_comments_reaction: Array<GraphQLTypes["voyce_chapter_comments_reaction"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_comments_reaction" */
	voyce_chapter_comments_reaction_aggregate: GraphQLTypes["voyce_chapter_comments_reaction_aggregate"],
	/** fetch data from the table: "voyce.chapter_comments_reaction" using primary key columns */
	voyce_chapter_comments_reaction_by_pk?: GraphQLTypes["voyce_chapter_comments_reaction"],
	/** fetch data from the table: "voyce.chapter_comments_reaction_count" */
	voyce_chapter_comments_reaction_count: Array<GraphQLTypes["voyce_chapter_comments_reaction_count"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_comments_reaction_count" */
	voyce_chapter_comments_reaction_count_aggregate: GraphQLTypes["voyce_chapter_comments_reaction_count_aggregate"],
	/** fetch data from the table: "voyce.chapter_comments_reaction_count" using primary key columns */
	voyce_chapter_comments_reaction_count_by_pk?: GraphQLTypes["voyce_chapter_comments_reaction_count"],
	/** fetch data from the table: "voyce.chapter_comments_reports" */
	voyce_chapter_comments_reports: Array<GraphQLTypes["voyce_chapter_comments_reports"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_comments_reports" */
	voyce_chapter_comments_reports_aggregate: GraphQLTypes["voyce_chapter_comments_reports_aggregate"],
	/** fetch data from the table: "voyce.chapter_comments_reports" using primary key columns */
	voyce_chapter_comments_reports_by_pk?: GraphQLTypes["voyce_chapter_comments_reports"],
	/** fetch data from the table: "voyce.chapter_contents" */
	voyce_chapter_contents: Array<GraphQLTypes["voyce_chapter_contents"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_contents" */
	voyce_chapter_contents_aggregate: GraphQLTypes["voyce_chapter_contents_aggregate"],
	/** fetch data from the table: "voyce.chapter_contents" using primary key columns */
	voyce_chapter_contents_by_pk?: GraphQLTypes["voyce_chapter_contents"],
	/** fetch data from the table: "voyce.chapter_contents_old" */
	voyce_chapter_contents_old: Array<GraphQLTypes["voyce_chapter_contents_old"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_contents_old" */
	voyce_chapter_contents_old_aggregate: GraphQLTypes["voyce_chapter_contents_old_aggregate"],
	/** fetch data from the table: "voyce.chapter_contents_old" using primary key columns */
	voyce_chapter_contents_old_by_pk?: GraphQLTypes["voyce_chapter_contents_old"],
	/** fetch data from the table: "voyce.chapter_effects" */
	voyce_chapter_effects: Array<GraphQLTypes["voyce_chapter_effects"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_effects" */
	voyce_chapter_effects_aggregate: GraphQLTypes["voyce_chapter_effects_aggregate"],
	/** fetch data from the table: "voyce.chapter_effects" using primary key columns */
	voyce_chapter_effects_by_pk?: GraphQLTypes["voyce_chapter_effects"],
	/** fetch data from the table: "voyce.chapter_images" */
	voyce_chapter_images: Array<GraphQLTypes["voyce_chapter_images"]>,
	/** fetch data from the table: "voyce.chapter_images_07252024" */
	voyce_chapter_images_07252024: Array<GraphQLTypes["voyce_chapter_images_07252024"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_images_07252024" */
	voyce_chapter_images_07252024_aggregate: GraphQLTypes["voyce_chapter_images_07252024_aggregate"],
	/** fetch aggregated fields from the table: "voyce.chapter_images" */
	voyce_chapter_images_aggregate: GraphQLTypes["voyce_chapter_images_aggregate"],
	/** fetch data from the table: "voyce.chapter_images" using primary key columns */
	voyce_chapter_images_by_pk?: GraphQLTypes["voyce_chapter_images"],
	/** fetch data from the table: "voyce.chapter_likes" */
	voyce_chapter_likes: Array<GraphQLTypes["voyce_chapter_likes"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_likes" */
	voyce_chapter_likes_aggregate: GraphQLTypes["voyce_chapter_likes_aggregate"],
	/** fetch data from the table: "voyce.chapter_likes" using primary key columns */
	voyce_chapter_likes_by_pk?: GraphQLTypes["voyce_chapter_likes"],
	/** fetch data from the table: "voyce.chapter_locks" */
	voyce_chapter_locks: Array<GraphQLTypes["voyce_chapter_locks"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_locks" */
	voyce_chapter_locks_aggregate: GraphQLTypes["voyce_chapter_locks_aggregate"],
	/** fetch data from the table: "voyce.chapter_locks" using primary key columns */
	voyce_chapter_locks_by_pk?: GraphQLTypes["voyce_chapter_locks"],
	/** fetch data from the table: "voyce.chapter_panel_views" */
	voyce_chapter_panel_views: Array<GraphQLTypes["voyce_chapter_panel_views"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_panel_views" */
	voyce_chapter_panel_views_aggregate: GraphQLTypes["voyce_chapter_panel_views_aggregate"],
	/** fetch data from the table: "voyce.chapter_panel_views" using primary key columns */
	voyce_chapter_panel_views_by_pk?: GraphQLTypes["voyce_chapter_panel_views"],
	/** fetch data from the table: "voyce.chapter_panels" */
	voyce_chapter_panels: Array<GraphQLTypes["voyce_chapter_panels"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_panels" */
	voyce_chapter_panels_aggregate: GraphQLTypes["voyce_chapter_panels_aggregate"],
	/** fetch data from the table: "voyce.chapter_panels" using primary key columns */
	voyce_chapter_panels_by_pk?: GraphQLTypes["voyce_chapter_panels"],
	/** fetch data from the table: "voyce.chapter_publish_schedules" */
	voyce_chapter_publish_schedules: Array<GraphQLTypes["voyce_chapter_publish_schedules"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_publish_schedules" */
	voyce_chapter_publish_schedules_aggregate: GraphQLTypes["voyce_chapter_publish_schedules_aggregate"],
	/** fetch data from the table: "voyce.chapter_publish_schedules" using primary key columns */
	voyce_chapter_publish_schedules_by_pk?: GraphQLTypes["voyce_chapter_publish_schedules"],
	/** fetch data from the table: "voyce.chapter_reactions" */
	voyce_chapter_reactions: Array<GraphQLTypes["voyce_chapter_reactions"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_reactions" */
	voyce_chapter_reactions_aggregate: GraphQLTypes["voyce_chapter_reactions_aggregate"],
	/** fetch data from the table: "voyce.chapter_reactions" using primary key columns */
	voyce_chapter_reactions_by_pk?: GraphQLTypes["voyce_chapter_reactions"],
	/** fetch data from the table: "voyce.chapter_reactions_count" */
	voyce_chapter_reactions_count: Array<GraphQLTypes["voyce_chapter_reactions_count"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_reactions_count" */
	voyce_chapter_reactions_count_aggregate: GraphQLTypes["voyce_chapter_reactions_count_aggregate"],
	/** fetch data from the table: "voyce.chapter_reactions_count" using primary key columns */
	voyce_chapter_reactions_count_by_pk?: GraphQLTypes["voyce_chapter_reactions_count"],
	/** fetch data from the table: "voyce.chapter_view_logs" */
	voyce_chapter_view_logs: Array<GraphQLTypes["voyce_chapter_view_logs"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_view_logs" */
	voyce_chapter_view_logs_aggregate: GraphQLTypes["voyce_chapter_view_logs_aggregate"],
	/** fetch data from the table: "voyce.chapter_view_logs" using primary key columns */
	voyce_chapter_view_logs_by_pk?: GraphQLTypes["voyce_chapter_view_logs"],
	/** fetch data from the table: "voyce.chapter_views" */
	voyce_chapter_views: Array<GraphQLTypes["voyce_chapter_views"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_views" */
	voyce_chapter_views_aggregate: GraphQLTypes["voyce_chapter_views_aggregate"],
	/** fetch data from the table: "voyce.chapter_views" using primary key columns */
	voyce_chapter_views_by_pk?: GraphQLTypes["voyce_chapter_views"],
	/** fetch data from the table: "voyce.chapters" */
	voyce_chapters: Array<GraphQLTypes["voyce_chapters"]>,
	/** fetch data from the table: "voyce.chapters_07252024" */
	voyce_chapters_07252024: Array<GraphQLTypes["voyce_chapters_07252024"]>,
	/** fetch aggregated fields from the table: "voyce.chapters_07252024" */
	voyce_chapters_07252024_aggregate: GraphQLTypes["voyce_chapters_07252024_aggregate"],
	/** fetch aggregated fields from the table: "voyce.chapters" */
	voyce_chapters_aggregate: GraphQLTypes["voyce_chapters_aggregate"],
	/** fetch data from the table: "voyce.chapters" using primary key columns */
	voyce_chapters_by_pk?: GraphQLTypes["voyce_chapters"],
	/** fetch data from the table: "voyce.collaborator_roles" */
	voyce_collaborator_roles: Array<GraphQLTypes["voyce_collaborator_roles"]>,
	/** fetch aggregated fields from the table: "voyce.collaborator_roles" */
	voyce_collaborator_roles_aggregate: GraphQLTypes["voyce_collaborator_roles_aggregate"],
	/** fetch data from the table: "voyce.collaborator_roles" using primary key columns */
	voyce_collaborator_roles_by_pk?: GraphQLTypes["voyce_collaborator_roles"],
	/** fetch data from the table: "voyce.comic_types" */
	voyce_comic_types: Array<GraphQLTypes["voyce_comic_types"]>,
	/** fetch aggregated fields from the table: "voyce.comic_types" */
	voyce_comic_types_aggregate: GraphQLTypes["voyce_comic_types_aggregate"],
	/** fetch data from the table: "voyce.comic_types" using primary key columns */
	voyce_comic_types_by_pk?: GraphQLTypes["voyce_comic_types"],
	/** fetch data from the table: "voyce.comments_top_mat" */
	voyce_comments_top_mat: Array<GraphQLTypes["voyce_comments_top_mat"]>,
	/** fetch aggregated fields from the table: "voyce.comments_top_mat" */
	voyce_comments_top_mat_aggregate: GraphQLTypes["voyce_comments_top_mat_aggregate"],
	/** fetch data from the table: "voyce.community_blurbs" */
	voyce_community_blurbs: Array<GraphQLTypes["voyce_community_blurbs"]>,
	/** fetch aggregated fields from the table: "voyce.community_blurbs" */
	voyce_community_blurbs_aggregate: GraphQLTypes["voyce_community_blurbs_aggregate"],
	/** fetch data from the table: "voyce.community_blurbs" using primary key columns */
	voyce_community_blurbs_by_pk?: GraphQLTypes["voyce_community_blurbs"],
	/** fetch data from the table: "voyce.contact_us" */
	voyce_contact_us: Array<GraphQLTypes["voyce_contact_us"]>,
	/** fetch aggregated fields from the table: "voyce.contact_us" */
	voyce_contact_us_aggregate: GraphQLTypes["voyce_contact_us_aggregate"],
	/** fetch data from the table: "voyce.contact_us" using primary key columns */
	voyce_contact_us_by_pk?: GraphQLTypes["voyce_contact_us"],
	/** fetch data from the table: "voyce.content_report" */
	voyce_content_report: Array<GraphQLTypes["voyce_content_report"]>,
	/** fetch aggregated fields from the table: "voyce.content_report" */
	voyce_content_report_aggregate: GraphQLTypes["voyce_content_report_aggregate"],
	/** fetch data from the table: "voyce.content_report" using primary key columns */
	voyce_content_report_by_pk?: GraphQLTypes["voyce_content_report"],
	/** fetch data from the table: "voyce.contest_data" */
	voyce_contest_data: Array<GraphQLTypes["voyce_contest_data"]>,
	/** fetch aggregated fields from the table: "voyce.contest_data" */
	voyce_contest_data_aggregate: GraphQLTypes["voyce_contest_data_aggregate"],
	/** fetch data from the table: "voyce.contest_data" using primary key columns */
	voyce_contest_data_by_pk?: GraphQLTypes["voyce_contest_data"],
	/** fetch data from the table: "voyce.countries" */
	voyce_countries: Array<GraphQLTypes["voyce_countries"]>,
	/** fetch aggregated fields from the table: "voyce.countries" */
	voyce_countries_aggregate: GraphQLTypes["voyce_countries_aggregate"],
	/** fetch data from the table: "voyce.countries" using primary key columns */
	voyce_countries_by_pk?: GraphQLTypes["voyce_countries"],
	/** fetch data from the table: "voyce.csat_ratings" */
	voyce_csat_ratings: Array<GraphQLTypes["voyce_csat_ratings"]>,
	/** fetch aggregated fields from the table: "voyce.csat_ratings" */
	voyce_csat_ratings_aggregate: GraphQLTypes["voyce_csat_ratings_aggregate"],
	/** fetch data from the table: "voyce.csat_ratings" using primary key columns */
	voyce_csat_ratings_by_pk?: GraphQLTypes["voyce_csat_ratings"],
	/** fetch data from the table: "voyce.csat_ratings_data" */
	voyce_csat_ratings_data: Array<GraphQLTypes["voyce_csat_ratings_data"]>,
	/** fetch aggregated fields from the table: "voyce.csat_ratings_data" */
	voyce_csat_ratings_data_aggregate: GraphQLTypes["voyce_csat_ratings_data_aggregate"],
	/** fetch data from the table: "voyce.csat_ratings_data" using primary key columns */
	voyce_csat_ratings_data_by_pk?: GraphQLTypes["voyce_csat_ratings_data"],
	/** fetch data from the table: "voyce.education_types" */
	voyce_education_types: Array<GraphQLTypes["voyce_education_types"]>,
	/** fetch aggregated fields from the table: "voyce.education_types" */
	voyce_education_types_aggregate: GraphQLTypes["voyce_education_types_aggregate"],
	/** fetch data from the table: "voyce.education_types" using primary key columns */
	voyce_education_types_by_pk?: GraphQLTypes["voyce_education_types"],
	/** fetch data from the table: "voyce.erp_login_roles" */
	voyce_erp_login_roles: Array<GraphQLTypes["voyce_erp_login_roles"]>,
	/** fetch aggregated fields from the table: "voyce.erp_login_roles" */
	voyce_erp_login_roles_aggregate: GraphQLTypes["voyce_erp_login_roles_aggregate"],
	/** fetch data from the table: "voyce.erp_login_roles" using primary key columns */
	voyce_erp_login_roles_by_pk?: GraphQLTypes["voyce_erp_login_roles"],
	/** fetch data from the table: "voyce.etl_series_views_logs" */
	voyce_etl_series_views_logs: Array<GraphQLTypes["voyce_etl_series_views_logs"]>,
	/** fetch aggregated fields from the table: "voyce.etl_series_views_logs" */
	voyce_etl_series_views_logs_aggregate: GraphQLTypes["voyce_etl_series_views_logs_aggregate"],
	/** fetch data from the table: "voyce.etl_series_views_logs" using primary key columns */
	voyce_etl_series_views_logs_by_pk?: GraphQLTypes["voyce_etl_series_views_logs"],
	/** fetch data from the table: "voyce.event_schedules" */
	voyce_event_schedules: Array<GraphQLTypes["voyce_event_schedules"]>,
	/** fetch aggregated fields from the table: "voyce.event_schedules" */
	voyce_event_schedules_aggregate: GraphQLTypes["voyce_event_schedules_aggregate"],
	/** fetch data from the table: "voyce.event_schedules" using primary key columns */
	voyce_event_schedules_by_pk?: GraphQLTypes["voyce_event_schedules"],
	/** fetch data from the table: "voyce.genres" */
	voyce_genres: Array<GraphQLTypes["voyce_genres"]>,
	/** fetch aggregated fields from the table: "voyce.genres" */
	voyce_genres_aggregate: GraphQLTypes["voyce_genres_aggregate"],
	/** fetch data from the table: "voyce.genres" using primary key columns */
	voyce_genres_by_pk?: GraphQLTypes["voyce_genres"],
	/** fetch data from the table: "voyce.guest_gtags" */
	voyce_guest_gtags: Array<GraphQLTypes["voyce_guest_gtags"]>,
	/** fetch aggregated fields from the table: "voyce.guest_gtags" */
	voyce_guest_gtags_aggregate: GraphQLTypes["voyce_guest_gtags_aggregate"],
	/** fetch data from the table: "voyce.guest_gtags" using primary key columns */
	voyce_guest_gtags_by_pk?: GraphQLTypes["voyce_guest_gtags"],
	/** fetch data from the table: "voyce.metabases" */
	voyce_metabases: Array<GraphQLTypes["voyce_metabases"]>,
	/** fetch data from the table: "voyce.metabases_access" */
	voyce_metabases_access: Array<GraphQLTypes["voyce_metabases_access"]>,
	/** fetch aggregated fields from the table: "voyce.metabases_access" */
	voyce_metabases_access_aggregate: GraphQLTypes["voyce_metabases_access_aggregate"],
	/** fetch data from the table: "voyce.metabases_access" using primary key columns */
	voyce_metabases_access_by_pk?: GraphQLTypes["voyce_metabases_access"],
	/** fetch aggregated fields from the table: "voyce.metabases" */
	voyce_metabases_aggregate: GraphQLTypes["voyce_metabases_aggregate"],
	/** fetch data from the table: "voyce.metabases" using primary key columns */
	voyce_metabases_by_pk?: GraphQLTypes["voyce_metabases"],
	/** fetch data from the table: "voyce.newsletter" */
	voyce_newsletter: Array<GraphQLTypes["voyce_newsletter"]>,
	/** fetch aggregated fields from the table: "voyce.newsletter" */
	voyce_newsletter_aggregate: GraphQLTypes["voyce_newsletter_aggregate"],
	/** fetch data from the table: "voyce.newsletter" using primary key columns */
	voyce_newsletter_by_pk?: GraphQLTypes["voyce_newsletter"],
	/** fetch data from the table: "voyce.notification_series_settings" */
	voyce_notification_series_settings: Array<GraphQLTypes["voyce_notification_series_settings"]>,
	/** fetch aggregated fields from the table: "voyce.notification_series_settings" */
	voyce_notification_series_settings_aggregate: GraphQLTypes["voyce_notification_series_settings_aggregate"],
	/** fetch data from the table: "voyce.notification_series_settings" using primary key columns */
	voyce_notification_series_settings_by_pk?: GraphQLTypes["voyce_notification_series_settings"],
	/** fetch data from the table: "voyce.notification_settings" */
	voyce_notification_settings: Array<GraphQLTypes["voyce_notification_settings"]>,
	/** fetch aggregated fields from the table: "voyce.notification_settings" */
	voyce_notification_settings_aggregate: GraphQLTypes["voyce_notification_settings_aggregate"],
	/** fetch data from the table: "voyce.notification_settings" using primary key columns */
	voyce_notification_settings_by_pk?: GraphQLTypes["voyce_notification_settings"],
	/** fetch data from the table: "voyce.notifications" */
	voyce_notifications: Array<GraphQLTypes["voyce_notifications"]>,
	/** fetch aggregated fields from the table: "voyce.notifications" */
	voyce_notifications_aggregate: GraphQLTypes["voyce_notifications_aggregate"],
	/** fetch data from the table: "voyce.notifications" using primary key columns */
	voyce_notifications_by_pk?: GraphQLTypes["voyce_notifications"],
	/** fetch data from the table: "voyce.originals_feed" */
	voyce_originals_feed: Array<GraphQLTypes["voyce_originals_feed"]>,
	/** fetch aggregated fields from the table: "voyce.originals_feed" */
	voyce_originals_feed_aggregate: GraphQLTypes["voyce_originals_feed_aggregate"],
	/** fetch data from the table: "voyce.page_banners" */
	voyce_page_banners: Array<GraphQLTypes["voyce_page_banners"]>,
	/** fetch aggregated fields from the table: "voyce.page_banners" */
	voyce_page_banners_aggregate: GraphQLTypes["voyce_page_banners_aggregate"],
	/** fetch data from the table: "voyce.page_banners" using primary key columns */
	voyce_page_banners_by_pk?: GraphQLTypes["voyce_page_banners"],
	/** fetch data from the table: "voyce.page_components" */
	voyce_page_components: Array<GraphQLTypes["voyce_page_components"]>,
	/** fetch aggregated fields from the table: "voyce.page_components" */
	voyce_page_components_aggregate: GraphQLTypes["voyce_page_components_aggregate"],
	/** fetch data from the table: "voyce.page_components" using primary key columns */
	voyce_page_components_by_pk?: GraphQLTypes["voyce_page_components"],
	/** fetch data from the table: "voyce.pages" */
	voyce_pages: Array<GraphQLTypes["voyce_pages"]>,
	/** fetch aggregated fields from the table: "voyce.pages" */
	voyce_pages_aggregate: GraphQLTypes["voyce_pages_aggregate"],
	/** fetch data from the table: "voyce.pages" using primary key columns */
	voyce_pages_by_pk?: GraphQLTypes["voyce_pages"],
	/** fetch data from the table: "voyce.panels_comments" */
	voyce_panels_comments: Array<GraphQLTypes["voyce_panels_comments"]>,
	/** fetch aggregated fields from the table: "voyce.panels_comments" */
	voyce_panels_comments_aggregate: GraphQLTypes["voyce_panels_comments_aggregate"],
	/** fetch data from the table: "voyce.panels_comments" using primary key columns */
	voyce_panels_comments_by_pk?: GraphQLTypes["voyce_panels_comments"],
	/** fetch data from the table: "voyce.panels_reactions" */
	voyce_panels_reactions: Array<GraphQLTypes["voyce_panels_reactions"]>,
	/** fetch aggregated fields from the table: "voyce.panels_reactions" */
	voyce_panels_reactions_aggregate: GraphQLTypes["voyce_panels_reactions_aggregate"],
	/** fetch data from the table: "voyce.panels_reactions" using primary key columns */
	voyce_panels_reactions_by_pk?: GraphQLTypes["voyce_panels_reactions"],
	/** fetch data from the table: "voyce.panels_reactions_count" */
	voyce_panels_reactions_count: Array<GraphQLTypes["voyce_panels_reactions_count"]>,
	/** fetch aggregated fields from the table: "voyce.panels_reactions_count" */
	voyce_panels_reactions_count_aggregate: GraphQLTypes["voyce_panels_reactions_count_aggregate"],
	/** fetch data from the table: "voyce.panels_reactions_count" using primary key columns */
	voyce_panels_reactions_count_by_pk?: GraphQLTypes["voyce_panels_reactions_count"],
	/** fetch data from the table: "voyce.payments_to_creators" */
	voyce_payments_to_creators: Array<GraphQLTypes["voyce_payments_to_creators"]>,
	/** fetch aggregated fields from the table: "voyce.payments_to_creators" */
	voyce_payments_to_creators_aggregate: GraphQLTypes["voyce_payments_to_creators_aggregate"],
	/** fetch data from the table: "voyce.payments_to_creators" using primary key columns */
	voyce_payments_to_creators_by_pk?: GraphQLTypes["voyce_payments_to_creators"],
	/** fetch data from the table: "voyce.pg_rs_series_views_count" */
	voyce_pg_rs_series_views_count: Array<GraphQLTypes["voyce_pg_rs_series_views_count"]>,
	/** fetch aggregated fields from the table: "voyce.pg_rs_series_views_count" */
	voyce_pg_rs_series_views_count_aggregate: GraphQLTypes["voyce_pg_rs_series_views_count_aggregate"],
	/** fetch data from the table: "voyce.pg_rs_series_views_count" using primary key columns */
	voyce_pg_rs_series_views_count_by_pk?: GraphQLTypes["voyce_pg_rs_series_views_count"],
	/** fetch data from the table: "voyce.polls" */
	voyce_polls: Array<GraphQLTypes["voyce_polls"]>,
	/** fetch aggregated fields from the table: "voyce.polls" */
	voyce_polls_aggregate: GraphQLTypes["voyce_polls_aggregate"],
	/** fetch data from the table: "voyce.polls_answers" */
	voyce_polls_answers: Array<GraphQLTypes["voyce_polls_answers"]>,
	/** fetch aggregated fields from the table: "voyce.polls_answers" */
	voyce_polls_answers_aggregate: GraphQLTypes["voyce_polls_answers_aggregate"],
	/** fetch data from the table: "voyce.polls_answers" using primary key columns */
	voyce_polls_answers_by_pk?: GraphQLTypes["voyce_polls_answers"],
	/** fetch data from the table: "voyce.polls" using primary key columns */
	voyce_polls_by_pk?: GraphQLTypes["voyce_polls"],
	/** fetch data from the table: "voyce.polls_comments" */
	voyce_polls_comments: Array<GraphQLTypes["voyce_polls_comments"]>,
	/** fetch aggregated fields from the table: "voyce.polls_comments" */
	voyce_polls_comments_aggregate: GraphQLTypes["voyce_polls_comments_aggregate"],
	/** fetch data from the table: "voyce.polls_comments" using primary key columns */
	voyce_polls_comments_by_pk?: GraphQLTypes["voyce_polls_comments"],
	/** fetch data from the table: "voyce.polls_reactions" */
	voyce_polls_reactions: Array<GraphQLTypes["voyce_polls_reactions"]>,
	/** fetch aggregated fields from the table: "voyce.polls_reactions" */
	voyce_polls_reactions_aggregate: GraphQLTypes["voyce_polls_reactions_aggregate"],
	/** fetch data from the table: "voyce.polls_reactions" using primary key columns */
	voyce_polls_reactions_by_pk?: GraphQLTypes["voyce_polls_reactions"],
	/** fetch data from the table: "voyce.polls_votes" */
	voyce_polls_votes: Array<GraphQLTypes["voyce_polls_votes"]>,
	/** fetch aggregated fields from the table: "voyce.polls_votes" */
	voyce_polls_votes_aggregate: GraphQLTypes["voyce_polls_votes_aggregate"],
	/** fetch data from the table: "voyce.polls_votes" using primary key columns */
	voyce_polls_votes_by_pk?: GraphQLTypes["voyce_polls_votes"],
	/** fetch data from the table: "voyce.popup_components" */
	voyce_popup_components: Array<GraphQLTypes["voyce_popup_components"]>,
	/** fetch aggregated fields from the table: "voyce.popup_components" */
	voyce_popup_components_aggregate: GraphQLTypes["voyce_popup_components_aggregate"],
	/** fetch data from the table: "voyce.popup_components" using primary key columns */
	voyce_popup_components_by_pk?: GraphQLTypes["voyce_popup_components"],
	/** fetch data from the table: "voyce.popup_emails" */
	voyce_popup_emails: Array<GraphQLTypes["voyce_popup_emails"]>,
	/** fetch aggregated fields from the table: "voyce.popup_emails" */
	voyce_popup_emails_aggregate: GraphQLTypes["voyce_popup_emails_aggregate"],
	/** fetch data from the table: "voyce.popup_emails" using primary key columns */
	voyce_popup_emails_by_pk?: GraphQLTypes["voyce_popup_emails"],
	/** fetch data from the table: "voyce.posts" */
	voyce_posts: Array<GraphQLTypes["voyce_posts"]>,
	/** fetch aggregated fields from the table: "voyce.posts" */
	voyce_posts_aggregate: GraphQLTypes["voyce_posts_aggregate"],
	/** fetch data from the table: "voyce.posts" using primary key columns */
	voyce_posts_by_pk?: GraphQLTypes["voyce_posts"],
	/** fetch data from the table: "voyce.posts_comments" */
	voyce_posts_comments: Array<GraphQLTypes["voyce_posts_comments"]>,
	/** fetch aggregated fields from the table: "voyce.posts_comments" */
	voyce_posts_comments_aggregate: GraphQLTypes["voyce_posts_comments_aggregate"],
	/** fetch data from the table: "voyce.posts_comments" using primary key columns */
	voyce_posts_comments_by_pk?: GraphQLTypes["voyce_posts_comments"],
	/** fetch data from the table: "voyce.posts_reactions" */
	voyce_posts_reactions: Array<GraphQLTypes["voyce_posts_reactions"]>,
	/** fetch aggregated fields from the table: "voyce.posts_reactions" */
	voyce_posts_reactions_aggregate: GraphQLTypes["voyce_posts_reactions_aggregate"],
	/** fetch data from the table: "voyce.posts_reactions" using primary key columns */
	voyce_posts_reactions_by_pk?: GraphQLTypes["voyce_posts_reactions"],
	/** fetch data from the table: "voyce.quest_difficulties" */
	voyce_quest_difficulties: Array<GraphQLTypes["voyce_quest_difficulties"]>,
	/** fetch aggregated fields from the table: "voyce.quest_difficulties" */
	voyce_quest_difficulties_aggregate: GraphQLTypes["voyce_quest_difficulties_aggregate"],
	/** fetch data from the table: "voyce.quest_difficulties" using primary key columns */
	voyce_quest_difficulties_by_pk?: GraphQLTypes["voyce_quest_difficulties"],
	/** fetch data from the table: "voyce.quests" */
	voyce_quests: Array<GraphQLTypes["voyce_quests"]>,
	/** fetch aggregated fields from the table: "voyce.quests" */
	voyce_quests_aggregate: GraphQLTypes["voyce_quests_aggregate"],
	/** fetch data from the table: "voyce.quests" using primary key columns */
	voyce_quests_by_pk?: GraphQLTypes["voyce_quests"],
	/** fetch data from the table: "voyce.quests_old" */
	voyce_quests_old: Array<GraphQLTypes["voyce_quests_old"]>,
	/** fetch aggregated fields from the table: "voyce.quests_old" */
	voyce_quests_old_aggregate: GraphQLTypes["voyce_quests_old_aggregate"],
	/** fetch data from the table: "voyce.quests_old" using primary key columns */
	voyce_quests_old_by_pk?: GraphQLTypes["voyce_quests_old"],
	/** fetch data from the table: "voyce.recommended_series" */
	voyce_recommended_series: Array<GraphQLTypes["voyce_recommended_series"]>,
	/** fetch aggregated fields from the table: "voyce.recommended_series" */
	voyce_recommended_series_aggregate: GraphQLTypes["voyce_recommended_series_aggregate"],
	/** fetch data from the table: "voyce.recommended_series" using primary key columns */
	voyce_recommended_series_by_pk?: GraphQLTypes["voyce_recommended_series"],
	/** fetch data from the table: "voyce.release_schedules" */
	voyce_release_schedules: Array<GraphQLTypes["voyce_release_schedules"]>,
	/** fetch aggregated fields from the table: "voyce.release_schedules" */
	voyce_release_schedules_aggregate: GraphQLTypes["voyce_release_schedules_aggregate"],
	/** fetch data from the table: "voyce.release_schedules" using primary key columns */
	voyce_release_schedules_by_pk?: GraphQLTypes["voyce_release_schedules"],
	/** fetch data from the table: "voyce.rewards" */
	voyce_rewards: Array<GraphQLTypes["voyce_rewards"]>,
	/** fetch aggregated fields from the table: "voyce.rewards" */
	voyce_rewards_aggregate: GraphQLTypes["voyce_rewards_aggregate"],
	/** fetch data from the table: "voyce.rewards" using primary key columns */
	voyce_rewards_by_pk?: GraphQLTypes["voyce_rewards"],
	/** fetch data from the table: "voyce.rewards_leaderboard" */
	voyce_rewards_leaderboard: Array<GraphQLTypes["voyce_rewards_leaderboard"]>,
	/** fetch aggregated fields from the table: "voyce.rewards_leaderboard" */
	voyce_rewards_leaderboard_aggregate: GraphQLTypes["voyce_rewards_leaderboard_aggregate"],
	/** fetch data from the table: "voyce.rewards_leaderboard" using primary key columns */
	voyce_rewards_leaderboard_by_pk?: GraphQLTypes["voyce_rewards_leaderboard"],
	/** fetch data from the table: "voyce.rewards_quest" */
	voyce_rewards_quest: Array<GraphQLTypes["voyce_rewards_quest"]>,
	/** fetch aggregated fields from the table: "voyce.rewards_quest" */
	voyce_rewards_quest_aggregate: GraphQLTypes["voyce_rewards_quest_aggregate"],
	/** fetch data from the table: "voyce.rewards_quest" using primary key columns */
	voyce_rewards_quest_by_pk?: GraphQLTypes["voyce_rewards_quest"],
	/** fetch data from the table: "voyce.sections" */
	voyce_sections: Array<GraphQLTypes["voyce_sections"]>,
	/** fetch aggregated fields from the table: "voyce.sections" */
	voyce_sections_aggregate: GraphQLTypes["voyce_sections_aggregate"],
	/** fetch data from the table: "voyce.sections" using primary key columns */
	voyce_sections_by_pk?: GraphQLTypes["voyce_sections"],
	/** fetch data from the table: "voyce.series" */
	voyce_series: Array<GraphQLTypes["voyce_series"]>,
	/** fetch aggregated fields from the table: "voyce.series" */
	voyce_series_aggregate: GraphQLTypes["voyce_series_aggregate"],
	/** fetch data from the table: "voyce.series" using primary key columns */
	voyce_series_by_pk?: GraphQLTypes["voyce_series"],
	/** fetch data from the table: "voyce.series_collaborators" */
	voyce_series_collaborators: Array<GraphQLTypes["voyce_series_collaborators"]>,
	/** fetch aggregated fields from the table: "voyce.series_collaborators" */
	voyce_series_collaborators_aggregate: GraphQLTypes["voyce_series_collaborators_aggregate"],
	/** fetch data from the table: "voyce.series_collaborators" using primary key columns */
	voyce_series_collaborators_by_pk?: GraphQLTypes["voyce_series_collaborators"],
	/** fetch data from the table: "voyce.series_donations" */
	voyce_series_donations: Array<GraphQLTypes["voyce_series_donations"]>,
	/** fetch aggregated fields from the table: "voyce.series_donations" */
	voyce_series_donations_aggregate: GraphQLTypes["voyce_series_donations_aggregate"],
	/** fetch data from the table: "voyce.series_donations" using primary key columns */
	voyce_series_donations_by_pk?: GraphQLTypes["voyce_series_donations"],
	/** fetch data from the table: "voyce.series_favorites" */
	voyce_series_favorites: Array<GraphQLTypes["voyce_series_favorites"]>,
	/** fetch aggregated fields from the table: "voyce.series_favorites" */
	voyce_series_favorites_aggregate: GraphQLTypes["voyce_series_favorites_aggregate"],
	/** fetch data from the table: "voyce.series_favorites" using primary key columns */
	voyce_series_favorites_by_pk?: GraphQLTypes["voyce_series_favorites"],
	/** fetch data from the table: "voyce.series_featured_panels" */
	voyce_series_featured_panels: Array<GraphQLTypes["voyce_series_featured_panels"]>,
	/** fetch aggregated fields from the table: "voyce.series_featured_panels" */
	voyce_series_featured_panels_aggregate: GraphQLTypes["voyce_series_featured_panels_aggregate"],
	/** fetch data from the table: "voyce.series_featured_panels" using primary key columns */
	voyce_series_featured_panels_by_pk?: GraphQLTypes["voyce_series_featured_panels"],
	/** fetch data from the table: "voyce.series_genre" */
	voyce_series_genre: Array<GraphQLTypes["voyce_series_genre"]>,
	/** fetch aggregated fields from the table: "voyce.series_genre" */
	voyce_series_genre_aggregate: GraphQLTypes["voyce_series_genre_aggregate"],
	/** fetch data from the table: "voyce.series_genre" using primary key columns */
	voyce_series_genre_by_pk?: GraphQLTypes["voyce_series_genre"],
	/** fetch data from the table: "voyce.series_giveaway_participants" */
	voyce_series_giveaway_participants: Array<GraphQLTypes["voyce_series_giveaway_participants"]>,
	/** fetch aggregated fields from the table: "voyce.series_giveaway_participants" */
	voyce_series_giveaway_participants_aggregate: GraphQLTypes["voyce_series_giveaway_participants_aggregate"],
	/** fetch data from the table: "voyce.series_giveaway_participants" using primary key columns */
	voyce_series_giveaway_participants_by_pk?: GraphQLTypes["voyce_series_giveaway_participants"],
	/** fetch data from the table: "voyce.series_hot_panels" */
	voyce_series_hot_panels: Array<GraphQLTypes["voyce_series_hot_panels"]>,
	/** fetch aggregated fields from the table: "voyce.series_hot_panels" */
	voyce_series_hot_panels_aggregate: GraphQLTypes["voyce_series_hot_panels_aggregate"],
	/** fetch data from the table: "voyce.series_hot_panels" using primary key columns */
	voyce_series_hot_panels_by_pk?: GraphQLTypes["voyce_series_hot_panels"],
	/** fetch data from the table: "voyce.series_layouts" */
	voyce_series_layouts: Array<GraphQLTypes["voyce_series_layouts"]>,
	/** fetch aggregated fields from the table: "voyce.series_layouts" */
	voyce_series_layouts_aggregate: GraphQLTypes["voyce_series_layouts_aggregate"],
	/** fetch data from the table: "voyce.series_layouts" using primary key columns */
	voyce_series_layouts_by_pk?: GraphQLTypes["voyce_series_layouts"],
	/** fetch data from the table: "voyce.series_leaderboard" */
	voyce_series_leaderboard: Array<GraphQLTypes["voyce_series_leaderboard"]>,
	/** fetch aggregated fields from the table: "voyce.series_leaderboard" */
	voyce_series_leaderboard_aggregate: GraphQLTypes["voyce_series_leaderboard_aggregate"],
	/** fetch data from the table: "voyce.series_leaderboard" using primary key columns */
	voyce_series_leaderboard_by_pk?: GraphQLTypes["voyce_series_leaderboard"],
	/** fetch data from the table: "voyce.series_likes_count" */
	voyce_series_likes_count: Array<GraphQLTypes["voyce_series_likes_count"]>,
	/** fetch aggregated fields from the table: "voyce.series_likes_count" */
	voyce_series_likes_count_aggregate: GraphQLTypes["voyce_series_likes_count_aggregate"],
	/** fetch data from the table: "voyce.series_likes_count_mat" */
	voyce_series_likes_count_mat: Array<GraphQLTypes["voyce_series_likes_count_mat"]>,
	/** fetch aggregated fields from the table: "voyce.series_likes_count_mat" */
	voyce_series_likes_count_mat_aggregate: GraphQLTypes["voyce_series_likes_count_mat_aggregate"],
	/** fetch data from the table: "voyce.series_lock_config" */
	voyce_series_lock_config: Array<GraphQLTypes["voyce_series_lock_config"]>,
	/** fetch aggregated fields from the table: "voyce.series_lock_config" */
	voyce_series_lock_config_aggregate: GraphQLTypes["voyce_series_lock_config_aggregate"],
	/** fetch data from the table: "voyce.series_lock_config" using primary key columns */
	voyce_series_lock_config_by_pk?: GraphQLTypes["voyce_series_lock_config"],
	/** fetch data from the table: "voyce.series_sponsorships_configuration" */
	voyce_series_sponsorships_configuration: Array<GraphQLTypes["voyce_series_sponsorships_configuration"]>,
	/** fetch aggregated fields from the table: "voyce.series_sponsorships_configuration" */
	voyce_series_sponsorships_configuration_aggregate: GraphQLTypes["voyce_series_sponsorships_configuration_aggregate"],
	/** fetch data from the table: "voyce.series_sponsorships_configuration" using primary key columns */
	voyce_series_sponsorships_configuration_by_pk?: GraphQLTypes["voyce_series_sponsorships_configuration"],
	/** fetch data from the table: "voyce.series_subscription" */
	voyce_series_subscription: Array<GraphQLTypes["voyce_series_subscription"]>,
	/** fetch aggregated fields from the table: "voyce.series_subscription" */
	voyce_series_subscription_aggregate: GraphQLTypes["voyce_series_subscription_aggregate"],
	/** fetch data from the table: "voyce.series_subscription" using primary key columns */
	voyce_series_subscription_by_pk?: GraphQLTypes["voyce_series_subscription"],
	/** fetch data from the table: "voyce.series_tags" */
	voyce_series_tags: Array<GraphQLTypes["voyce_series_tags"]>,
	/** fetch aggregated fields from the table: "voyce.series_tags" */
	voyce_series_tags_aggregate: GraphQLTypes["voyce_series_tags_aggregate"],
	/** fetch data from the table: "voyce.series_tags" using primary key columns */
	voyce_series_tags_by_pk?: GraphQLTypes["voyce_series_tags"],
	/** fetch data from the table: "voyce.series_tags_junction" */
	voyce_series_tags_junction: Array<GraphQLTypes["voyce_series_tags_junction"]>,
	/** fetch aggregated fields from the table: "voyce.series_tags_junction" */
	voyce_series_tags_junction_aggregate: GraphQLTypes["voyce_series_tags_junction_aggregate"],
	/** fetch data from the table: "voyce.series_tags_junction" using primary key columns */
	voyce_series_tags_junction_by_pk?: GraphQLTypes["voyce_series_tags_junction"],
	/** fetch data from the table: "voyce.series_top_comments" */
	voyce_series_top_comments: Array<GraphQLTypes["voyce_series_top_comments"]>,
	/** fetch aggregated fields from the table: "voyce.series_top_comments" */
	voyce_series_top_comments_aggregate: GraphQLTypes["voyce_series_top_comments_aggregate"],
	/** fetch data from the table: "voyce.series_top_comments" using primary key columns */
	voyce_series_top_comments_by_pk?: GraphQLTypes["voyce_series_top_comments"],
	/** fetch data from the table: "voyce.series_top_likes" */
	voyce_series_top_likes: Array<GraphQLTypes["voyce_series_top_likes"]>,
	/** fetch aggregated fields from the table: "voyce.series_top_likes" */
	voyce_series_top_likes_aggregate: GraphQLTypes["voyce_series_top_likes_aggregate"],
	/** fetch data from the table: "voyce.series_top_likes" using primary key columns */
	voyce_series_top_likes_by_pk?: GraphQLTypes["voyce_series_top_likes"],
	/** fetch data from the table: "voyce.series_top_views" */
	voyce_series_top_views: Array<GraphQLTypes["voyce_series_top_views"]>,
	/** fetch aggregated fields from the table: "voyce.series_top_views" */
	voyce_series_top_views_aggregate: GraphQLTypes["voyce_series_top_views_aggregate"],
	/** fetch data from the table: "voyce.series_top_views" using primary key columns */
	voyce_series_top_views_by_pk?: GraphQLTypes["voyce_series_top_views"],
	/** fetch data from the table: "voyce.series_views_adjustment" */
	voyce_series_views_adjustment: Array<GraphQLTypes["voyce_series_views_adjustment"]>,
	/** fetch aggregated fields from the table: "voyce.series_views_adjustment" */
	voyce_series_views_adjustment_aggregate: GraphQLTypes["voyce_series_views_adjustment_aggregate"],
	/** fetch data from the table: "voyce.series_views_adjustment" using primary key columns */
	voyce_series_views_adjustment_by_pk?: GraphQLTypes["voyce_series_views_adjustment"],
	/** fetch data from the table: "voyce.series_views_count" */
	voyce_series_views_count: Array<GraphQLTypes["voyce_series_views_count"]>,
	/** fetch aggregated fields from the table: "voyce.series_views_count" */
	voyce_series_views_count_aggregate: GraphQLTypes["voyce_series_views_count_aggregate"],
	/** fetch data from the table: "voyce.series_views_count_mat" */
	voyce_series_views_count_mat: Array<GraphQLTypes["voyce_series_views_count_mat"]>,
	/** fetch aggregated fields from the table: "voyce.series_views_count_mat" */
	voyce_series_views_count_mat_aggregate: GraphQLTypes["voyce_series_views_count_mat_aggregate"],
	/** fetch data from the table: "voyce.series_views_count_mat_sep30" */
	voyce_series_views_count_mat_sep30: Array<GraphQLTypes["voyce_series_views_count_mat_sep30"]>,
	/** fetch aggregated fields from the table: "voyce.series_views_count_mat_sep30" */
	voyce_series_views_count_mat_sep30_aggregate: GraphQLTypes["voyce_series_views_count_mat_sep30_aggregate"],
	/** fetch data from the table: "voyce.series_views_count_mat_v2" */
	voyce_series_views_count_mat_v2: Array<GraphQLTypes["voyce_series_views_count_mat_v2"]>,
	/** fetch aggregated fields from the table: "voyce.series_views_count_mat_v2" */
	voyce_series_views_count_mat_v2_aggregate: GraphQLTypes["voyce_series_views_count_mat_v2_aggregate"],
	/** fetch data from the table: "voyce.series_votes" */
	voyce_series_votes: Array<GraphQLTypes["voyce_series_votes"]>,
	/** fetch aggregated fields from the table: "voyce.series_votes" */
	voyce_series_votes_aggregate: GraphQLTypes["voyce_series_votes_aggregate"],
	/** fetch data from the table: "voyce.series_votes" using primary key columns */
	voyce_series_votes_by_pk?: GraphQLTypes["voyce_series_votes"],
	/** fetch data from the table: "voyce.series_votes_count" */
	voyce_series_votes_count: Array<GraphQLTypes["voyce_series_votes_count"]>,
	/** fetch aggregated fields from the table: "voyce.series_votes_count" */
	voyce_series_votes_count_aggregate: GraphQLTypes["voyce_series_votes_count_aggregate"],
	/** fetch data from the table: "voyce.series_votes_count" using primary key columns */
	voyce_series_votes_count_by_pk?: GraphQLTypes["voyce_series_votes_count"],
	/** fetch data from the table: "voyce.series_voting_schedules" */
	voyce_series_voting_schedules: Array<GraphQLTypes["voyce_series_voting_schedules"]>,
	/** fetch aggregated fields from the table: "voyce.series_voting_schedules" */
	voyce_series_voting_schedules_aggregate: GraphQLTypes["voyce_series_voting_schedules_aggregate"],
	/** fetch data from the table: "voyce.series_voting_schedules" using primary key columns */
	voyce_series_voting_schedules_by_pk?: GraphQLTypes["voyce_series_voting_schedules"],
	/** fetch data from the table: "voyce.sponsorships" */
	voyce_sponsorships: Array<GraphQLTypes["voyce_sponsorships"]>,
	/** fetch aggregated fields from the table: "voyce.sponsorships" */
	voyce_sponsorships_aggregate: GraphQLTypes["voyce_sponsorships_aggregate"],
	/** fetch data from the table: "voyce.sponsorships" using primary key columns */
	voyce_sponsorships_by_pk?: GraphQLTypes["voyce_sponsorships"],
	/** fetch data from the table: "voyce.states" */
	voyce_states: Array<GraphQLTypes["voyce_states"]>,
	/** fetch aggregated fields from the table: "voyce.states" */
	voyce_states_aggregate: GraphQLTypes["voyce_states_aggregate"],
	/** fetch data from the table: "voyce.states" using primary key columns */
	voyce_states_by_pk?: GraphQLTypes["voyce_states"],
	/** fetch data from the table: "voyce.stripe_payouts_setup" */
	voyce_stripe_payouts_setup: Array<GraphQLTypes["voyce_stripe_payouts_setup"]>,
	/** fetch aggregated fields from the table: "voyce.stripe_payouts_setup" */
	voyce_stripe_payouts_setup_aggregate: GraphQLTypes["voyce_stripe_payouts_setup_aggregate"],
	/** fetch data from the table: "voyce.stripe_payouts_setup" using primary key columns */
	voyce_stripe_payouts_setup_by_pk?: GraphQLTypes["voyce_stripe_payouts_setup"],
	/** fetch data from the table: "voyce.studio" */
	voyce_studio: Array<GraphQLTypes["voyce_studio"]>,
	/** fetch aggregated fields from the table: "voyce.studio" */
	voyce_studio_aggregate: GraphQLTypes["voyce_studio_aggregate"],
	/** fetch data from the table: "voyce.studio" using primary key columns */
	voyce_studio_by_pk?: GraphQLTypes["voyce_studio"],
	/** fetch data from the table: "voyce.studio_followers" */
	voyce_studio_followers: Array<GraphQLTypes["voyce_studio_followers"]>,
	/** fetch aggregated fields from the table: "voyce.studio_followers" */
	voyce_studio_followers_aggregate: GraphQLTypes["voyce_studio_followers_aggregate"],
	/** fetch data from the table: "voyce.studio_followers" using primary key columns */
	voyce_studio_followers_by_pk?: GraphQLTypes["voyce_studio_followers"],
	/** fetch data from the table: "voyce.studio_series" */
	voyce_studio_series: Array<GraphQLTypes["voyce_studio_series"]>,
	/** fetch aggregated fields from the table: "voyce.studio_series" */
	voyce_studio_series_aggregate: GraphQLTypes["voyce_studio_series_aggregate"],
	/** fetch data from the table: "voyce.studio_series" using primary key columns */
	voyce_studio_series_by_pk?: GraphQLTypes["voyce_studio_series"],
	/** fetch data from the table: "voyce.studio_views" */
	voyce_studio_views: Array<GraphQLTypes["voyce_studio_views"]>,
	/** fetch aggregated fields from the table: "voyce.studio_views" */
	voyce_studio_views_aggregate: GraphQLTypes["voyce_studio_views_aggregate"],
	/** fetch data from the table: "voyce.studio_views" using primary key columns */
	voyce_studio_views_by_pk?: GraphQLTypes["voyce_studio_views"],
	/** fetch data from the table: "voyce.system_settings" */
	voyce_system_settings: Array<GraphQLTypes["voyce_system_settings"]>,
	/** fetch aggregated fields from the table: "voyce.system_settings" */
	voyce_system_settings_aggregate: GraphQLTypes["voyce_system_settings_aggregate"],
	/** fetch data from the table: "voyce.system_settings" using primary key columns */
	voyce_system_settings_by_pk?: GraphQLTypes["voyce_system_settings"],
	/** fetch data from the table: "voyce.top_genres" */
	voyce_top_genres: Array<GraphQLTypes["voyce_top_genres"]>,
	/** fetch aggregated fields from the table: "voyce.top_genres" */
	voyce_top_genres_aggregate: GraphQLTypes["voyce_top_genres_aggregate"],
	/** fetch data from the table: "voyce.transaction_logs" */
	voyce_transaction_logs: Array<GraphQLTypes["voyce_transaction_logs"]>,
	/** fetch aggregated fields from the table: "voyce.transaction_logs" */
	voyce_transaction_logs_aggregate: GraphQLTypes["voyce_transaction_logs_aggregate"],
	/** fetch data from the table: "voyce.transaction_logs" using primary key columns */
	voyce_transaction_logs_by_pk?: GraphQLTypes["voyce_transaction_logs"],
	/** fetch data from the table: "voyce.url_redirects" */
	voyce_url_redirects: Array<GraphQLTypes["voyce_url_redirects"]>,
	/** fetch aggregated fields from the table: "voyce.url_redirects" */
	voyce_url_redirects_aggregate: GraphQLTypes["voyce_url_redirects_aggregate"],
	/** fetch data from the table: "voyce.url_redirects" using primary key columns */
	voyce_url_redirects_by_pk?: GraphQLTypes["voyce_url_redirects"],
	/** fetch data from the table: "voyce.user_addresses" */
	voyce_user_addresses: Array<GraphQLTypes["voyce_user_addresses"]>,
	/** fetch aggregated fields from the table: "voyce.user_addresses" */
	voyce_user_addresses_aggregate: GraphQLTypes["voyce_user_addresses_aggregate"],
	/** fetch data from the table: "voyce.user_addresses" using primary key columns */
	voyce_user_addresses_by_pk?: GraphQLTypes["voyce_user_addresses"],
	/** fetch data from the table: "voyce.user_blocks" */
	voyce_user_blocks: Array<GraphQLTypes["voyce_user_blocks"]>,
	/** fetch aggregated fields from the table: "voyce.user_blocks" */
	voyce_user_blocks_aggregate: GraphQLTypes["voyce_user_blocks_aggregate"],
	/** fetch data from the table: "voyce.user_blocks" using primary key columns */
	voyce_user_blocks_by_pk?: GraphQLTypes["voyce_user_blocks"],
	/** fetch data from the table: "voyce.user_chapters_unlocked" */
	voyce_user_chapters_unlocked: Array<GraphQLTypes["voyce_user_chapters_unlocked"]>,
	/** fetch aggregated fields from the table: "voyce.user_chapters_unlocked" */
	voyce_user_chapters_unlocked_aggregate: GraphQLTypes["voyce_user_chapters_unlocked_aggregate"],
	/** fetch data from the table: "voyce.user_chapters_unlocked" using primary key columns */
	voyce_user_chapters_unlocked_by_pk?: GraphQLTypes["voyce_user_chapters_unlocked"],
	/** fetch data from the table: "voyce.user_contacts" */
	voyce_user_contacts: Array<GraphQLTypes["voyce_user_contacts"]>,
	/** fetch aggregated fields from the table: "voyce.user_contacts" */
	voyce_user_contacts_aggregate: GraphQLTypes["voyce_user_contacts_aggregate"],
	/** fetch data from the table: "voyce.user_contacts" using primary key columns */
	voyce_user_contacts_by_pk?: GraphQLTypes["voyce_user_contacts"],
	/** fetch data from the table: "voyce.user_csat_ratings" */
	voyce_user_csat_ratings: Array<GraphQLTypes["voyce_user_csat_ratings"]>,
	/** fetch aggregated fields from the table: "voyce.user_csat_ratings" */
	voyce_user_csat_ratings_aggregate: GraphQLTypes["voyce_user_csat_ratings_aggregate"],
	/** fetch data from the table: "voyce.user_csat_ratings" using primary key columns */
	voyce_user_csat_ratings_by_pk?: GraphQLTypes["voyce_user_csat_ratings"],
	/** fetch data from the table: "voyce.user_education" */
	voyce_user_education: Array<GraphQLTypes["voyce_user_education"]>,
	/** fetch aggregated fields from the table: "voyce.user_education" */
	voyce_user_education_aggregate: GraphQLTypes["voyce_user_education_aggregate"],
	/** fetch data from the table: "voyce.user_education" using primary key columns */
	voyce_user_education_by_pk?: GraphQLTypes["voyce_user_education"],
	/** fetch data from the table: "voyce.user_level" */
	voyce_user_level: Array<GraphQLTypes["voyce_user_level"]>,
	/** fetch aggregated fields from the table: "voyce.user_level" */
	voyce_user_level_aggregate: GraphQLTypes["voyce_user_level_aggregate"],
	/** fetch data from the table: "voyce.user_level" using primary key columns */
	voyce_user_level_by_pk?: GraphQLTypes["voyce_user_level"],
	/** fetch data from the table: "voyce.user_linked_accounts" */
	voyce_user_linked_accounts: Array<GraphQLTypes["voyce_user_linked_accounts"]>,
	/** fetch aggregated fields from the table: "voyce.user_linked_accounts" */
	voyce_user_linked_accounts_aggregate: GraphQLTypes["voyce_user_linked_accounts_aggregate"],
	/** fetch data from the table: "voyce.user_linked_accounts" using primary key columns */
	voyce_user_linked_accounts_by_pk?: GraphQLTypes["voyce_user_linked_accounts"],
	/** fetch data from the table: "voyce.user_login_history" */
	voyce_user_login_history: Array<GraphQLTypes["voyce_user_login_history"]>,
	/** fetch aggregated fields from the table: "voyce.user_login_history" */
	voyce_user_login_history_aggregate: GraphQLTypes["voyce_user_login_history_aggregate"],
	/** fetch data from the table: "voyce.user_login_history" using primary key columns */
	voyce_user_login_history_by_pk?: GraphQLTypes["voyce_user_login_history"],
	/** fetch data from the table: "voyce.user_quest_ids" */
	voyce_user_quest_ids: Array<GraphQLTypes["voyce_user_quest_ids"]>,
	/** fetch aggregated fields from the table: "voyce.user_quest_ids" */
	voyce_user_quest_ids_aggregate: GraphQLTypes["voyce_user_quest_ids_aggregate"],
	/** fetch data from the table: "voyce.user_quest_ids" using primary key columns */
	voyce_user_quest_ids_by_pk?: GraphQLTypes["voyce_user_quest_ids"],
	/** fetch data from the table: "voyce.user_quest_rewards" */
	voyce_user_quest_rewards: Array<GraphQLTypes["voyce_user_quest_rewards"]>,
	/** fetch aggregated fields from the table: "voyce.user_quest_rewards" */
	voyce_user_quest_rewards_aggregate: GraphQLTypes["voyce_user_quest_rewards_aggregate"],
	/** fetch data from the table: "voyce.user_quest_rewards" using primary key columns */
	voyce_user_quest_rewards_by_pk?: GraphQLTypes["voyce_user_quest_rewards"],
	/** fetch data from the table: "voyce.user_quests" */
	voyce_user_quests: Array<GraphQLTypes["voyce_user_quests"]>,
	/** fetch aggregated fields from the table: "voyce.user_quests" */
	voyce_user_quests_aggregate: GraphQLTypes["voyce_user_quests_aggregate"],
	/** fetch data from the table: "voyce.user_quests" using primary key columns */
	voyce_user_quests_by_pk?: GraphQLTypes["voyce_user_quests"],
	/** fetch data from the table: "voyce.user_quests_old" */
	voyce_user_quests_old: Array<GraphQLTypes["voyce_user_quests_old"]>,
	/** fetch aggregated fields from the table: "voyce.user_quests_old" */
	voyce_user_quests_old_aggregate: GraphQLTypes["voyce_user_quests_old_aggregate"],
	/** fetch data from the table: "voyce.user_quests_old" using primary key columns */
	voyce_user_quests_old_by_pk?: GraphQLTypes["voyce_user_quests_old"],
	/** fetch data from the table: "voyce.user_ranking" */
	voyce_user_ranking: Array<GraphQLTypes["voyce_user_ranking"]>,
	/** fetch aggregated fields from the table: "voyce.user_ranking" */
	voyce_user_ranking_aggregate: GraphQLTypes["voyce_user_ranking_aggregate"],
	/** fetch data from the table: "voyce.user_ranking" using primary key columns */
	voyce_user_ranking_by_pk?: GraphQLTypes["voyce_user_ranking"],
	/** fetch data from the table: "voyce.user_reviews" */
	voyce_user_reviews: Array<GraphQLTypes["voyce_user_reviews"]>,
	/** fetch aggregated fields from the table: "voyce.user_reviews" */
	voyce_user_reviews_aggregate: GraphQLTypes["voyce_user_reviews_aggregate"],
	/** fetch data from the table: "voyce.user_reviews" using primary key columns */
	voyce_user_reviews_by_pk?: GraphQLTypes["voyce_user_reviews"],
	/** fetch data from the table: "voyce.user_rewards" */
	voyce_user_rewards: Array<GraphQLTypes["voyce_user_rewards"]>,
	/** fetch aggregated fields from the table: "voyce.user_rewards" */
	voyce_user_rewards_aggregate: GraphQLTypes["voyce_user_rewards_aggregate"],
	/** fetch data from the table: "voyce.user_rewards" using primary key columns */
	voyce_user_rewards_by_pk?: GraphQLTypes["voyce_user_rewards"],
	/** fetch data from the table: "voyce.user_roles" */
	voyce_user_roles: Array<GraphQLTypes["voyce_user_roles"]>,
	/** fetch aggregated fields from the table: "voyce.user_roles" */
	voyce_user_roles_aggregate: GraphQLTypes["voyce_user_roles_aggregate"],
	/** fetch data from the table: "voyce.user_roles" using primary key columns */
	voyce_user_roles_by_pk?: GraphQLTypes["voyce_user_roles"],
	/** fetch data from the table: "voyce.user_series_subscription_count" */
	voyce_user_series_subscription_count: Array<GraphQLTypes["voyce_user_series_subscription_count"]>,
	/** fetch aggregated fields from the table: "voyce.user_series_subscription_count" */
	voyce_user_series_subscription_count_aggregate: GraphQLTypes["voyce_user_series_subscription_count_aggregate"],
	/** fetch data from the table: "voyce.user_settings" */
	voyce_user_settings: Array<GraphQLTypes["voyce_user_settings"]>,
	/** fetch aggregated fields from the table: "voyce.user_settings" */
	voyce_user_settings_aggregate: GraphQLTypes["voyce_user_settings_aggregate"],
	/** fetch data from the table: "voyce.user_settings" using primary key columns */
	voyce_user_settings_by_pk?: GraphQLTypes["voyce_user_settings"],
	/** fetch data from the table: "voyce.user_skills" */
	voyce_user_skills: Array<GraphQLTypes["voyce_user_skills"]>,
	/** fetch aggregated fields from the table: "voyce.user_skills" */
	voyce_user_skills_aggregate: GraphQLTypes["voyce_user_skills_aggregate"],
	/** fetch data from the table: "voyce.user_skills" using primary key columns */
	voyce_user_skills_by_pk?: GraphQLTypes["voyce_user_skills"],
	/** fetch data from the table: "voyce.user_socials" */
	voyce_user_socials: Array<GraphQLTypes["voyce_user_socials"]>,
	/** fetch aggregated fields from the table: "voyce.user_socials" */
	voyce_user_socials_aggregate: GraphQLTypes["voyce_user_socials_aggregate"],
	/** fetch data from the table: "voyce.user_socials" using primary key columns */
	voyce_user_socials_by_pk?: GraphQLTypes["voyce_user_socials"],
	/** fetch data from the table: "voyce.user_soft_deleted" */
	voyce_user_soft_deleted: Array<GraphQLTypes["voyce_user_soft_deleted"]>,
	/** fetch aggregated fields from the table: "voyce.user_soft_deleted" */
	voyce_user_soft_deleted_aggregate: GraphQLTypes["voyce_user_soft_deleted_aggregate"],
	/** fetch data from the table: "voyce.user_soft_deleted" using primary key columns */
	voyce_user_soft_deleted_by_pk?: GraphQLTypes["voyce_user_soft_deleted"],
	/** fetch data from the table: "voyce.user_vtags" */
	voyce_user_vtags: Array<GraphQLTypes["voyce_user_vtags"]>,
	/** fetch aggregated fields from the table: "voyce.user_vtags" */
	voyce_user_vtags_aggregate: GraphQLTypes["voyce_user_vtags_aggregate"],
	/** fetch data from the table: "voyce.user_vtags" using primary key columns */
	voyce_user_vtags_by_pk?: GraphQLTypes["voyce_user_vtags"],
	/** fetch data from the table: "voyce.user_wins" */
	voyce_user_wins: Array<GraphQLTypes["voyce_user_wins"]>,
	/** fetch aggregated fields from the table: "voyce.user_wins" */
	voyce_user_wins_aggregate: GraphQLTypes["voyce_user_wins_aggregate"],
	/** fetch data from the table: "voyce.user_wins" using primary key columns */
	voyce_user_wins_by_pk?: GraphQLTypes["voyce_user_wins"],
	/** fetch data from the table: "voyce.users" */
	voyce_users: Array<GraphQLTypes["voyce_users"]>,
	/** fetch aggregated fields from the table: "voyce.users" */
	voyce_users_aggregate: GraphQLTypes["voyce_users_aggregate"],
	/** fetch data from the table: "voyce.users" using primary key columns */
	voyce_users_by_pk?: GraphQLTypes["voyce_users"],
	/** fetch data from the table: "voyce.users_followers" */
	voyce_users_followers: Array<GraphQLTypes["voyce_users_followers"]>,
	/** fetch aggregated fields from the table: "voyce.users_followers" */
	voyce_users_followers_aggregate: GraphQLTypes["voyce_users_followers_aggregate"],
	/** fetch data from the table: "voyce.users_followers" using primary key columns */
	voyce_users_followers_by_pk?: GraphQLTypes["voyce_users_followers"],
	/** fetch data from the table: "voyce.users_private" */
	voyce_users_private: Array<GraphQLTypes["voyce_users_private"]>,
	/** fetch aggregated fields from the table: "voyce.users_private" */
	voyce_users_private_aggregate: GraphQLTypes["voyce_users_private_aggregate"],
	/** fetch data from the table: "voyce.users_series_junction" */
	voyce_users_series_junction: Array<GraphQLTypes["voyce_users_series_junction"]>,
	/** fetch aggregated fields from the table: "voyce.users_series_junction" */
	voyce_users_series_junction_aggregate: GraphQLTypes["voyce_users_series_junction_aggregate"],
	/** fetch data from the table: "voyce.users_series_junction" using primary key columns */
	voyce_users_series_junction_by_pk?: GraphQLTypes["voyce_users_series_junction"],
	/** fetch data from the table: "voyce.versions" */
	voyce_versions: Array<GraphQLTypes["voyce_versions"]>,
	/** fetch aggregated fields from the table: "voyce.versions" */
	voyce_versions_aggregate: GraphQLTypes["voyce_versions_aggregate"],
	/** fetch data from the table: "voyce.versions" using primary key columns */
	voyce_versions_by_pk?: GraphQLTypes["voyce_versions"],
	/** fetch data from the table: "voyce.voting_analytics" */
	voyce_voting_analytics: Array<GraphQLTypes["voyce_voting_analytics"]>,
	/** fetch aggregated fields from the table: "voyce.voting_analytics" */
	voyce_voting_analytics_aggregate: GraphQLTypes["voyce_voting_analytics_aggregate"],
	/** fetch data from the table: "voyce.voting_analytics" using primary key columns */
	voyce_voting_analytics_by_pk?: GraphQLTypes["voyce_voting_analytics"],
	/** fetch data from the table: "voyce.voting_panels" */
	voyce_voting_panels: Array<GraphQLTypes["voyce_voting_panels"]>,
	/** fetch aggregated fields from the table: "voyce.voting_panels" */
	voyce_voting_panels_aggregate: GraphQLTypes["voyce_voting_panels_aggregate"],
	/** fetch data from the table: "voyce.voting_panels" using primary key columns */
	voyce_voting_panels_by_pk?: GraphQLTypes["voyce_voting_panels"],
	/** fetch data from the table: "voyce.voting_series" */
	voyce_voting_series: Array<GraphQLTypes["voyce_voting_series"]>,
	/** fetch aggregated fields from the table: "voyce.voting_series" */
	voyce_voting_series_aggregate: GraphQLTypes["voyce_voting_series_aggregate"],
	/** fetch data from the table: "voyce.voting_series" using primary key columns */
	voyce_voting_series_by_pk?: GraphQLTypes["voyce_voting_series"],
	/** fetch data from the table: "voyce.voting_user_votes" */
	voyce_voting_user_votes: Array<GraphQLTypes["voyce_voting_user_votes"]>,
	/** fetch aggregated fields from the table: "voyce.voting_user_votes" */
	voyce_voting_user_votes_aggregate: GraphQLTypes["voyce_voting_user_votes_aggregate"],
	/** fetch data from the table: "voyce.voting_user_votes" using primary key columns */
	voyce_voting_user_votes_by_pk?: GraphQLTypes["voyce_voting_user_votes"]
};
	["smallint"]:any;
	/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
["smallint_comparison_exp"]: {
		_eq?: GraphQLTypes["smallint"],
	_gt?: GraphQLTypes["smallint"],
	_gte?: GraphQLTypes["smallint"],
	_in?: Array<GraphQLTypes["smallint"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["smallint"],
	_lte?: GraphQLTypes["smallint"],
	_neq?: GraphQLTypes["smallint"],
	_nin?: Array<GraphQLTypes["smallint"]>
};
	/** columns and relationships of "store.payments" */
["store_payments"]: {
	__typename: "store_payments",
	amount: GraphQLTypes["numeric"],
	created_at: GraphQLTypes["timestamptz"],
	currency: string,
	gateway: string,
	id: GraphQLTypes["uuid"],
	purchase_id: string,
	remarks?: string,
	status: string
};
	/** aggregated selection of "store.payments" */
["store_payments_aggregate"]: {
	__typename: "store_payments_aggregate",
	aggregate?: GraphQLTypes["store_payments_aggregate_fields"],
	nodes: Array<GraphQLTypes["store_payments"]>
};
	/** aggregate fields of "store.payments" */
["store_payments_aggregate_fields"]: {
	__typename: "store_payments_aggregate_fields",
	avg?: GraphQLTypes["store_payments_avg_fields"],
	count: number,
	max?: GraphQLTypes["store_payments_max_fields"],
	min?: GraphQLTypes["store_payments_min_fields"],
	stddev?: GraphQLTypes["store_payments_stddev_fields"],
	stddev_pop?: GraphQLTypes["store_payments_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["store_payments_stddev_samp_fields"],
	sum?: GraphQLTypes["store_payments_sum_fields"],
	var_pop?: GraphQLTypes["store_payments_var_pop_fields"],
	var_samp?: GraphQLTypes["store_payments_var_samp_fields"],
	variance?: GraphQLTypes["store_payments_variance_fields"]
};
	/** aggregate avg on columns */
["store_payments_avg_fields"]: {
	__typename: "store_payments_avg_fields",
	amount?: number
};
	/** Boolean expression to filter rows from the table "store.payments". All fields are combined with a logical 'AND'. */
["store_payments_bool_exp"]: {
		_and?: Array<GraphQLTypes["store_payments_bool_exp"]>,
	_not?: GraphQLTypes["store_payments_bool_exp"],
	_or?: Array<GraphQLTypes["store_payments_bool_exp"]>,
	amount?: GraphQLTypes["numeric_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	currency?: GraphQLTypes["String_comparison_exp"],
	gateway?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	purchase_id?: GraphQLTypes["String_comparison_exp"],
	remarks?: GraphQLTypes["String_comparison_exp"],
	status?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "store.payments" */
["store_payments_constraint"]: store_payments_constraint;
	/** input type for incrementing numeric columns in table "store.payments" */
["store_payments_inc_input"]: {
		amount?: GraphQLTypes["numeric"]
};
	/** input type for inserting data into table "store.payments" */
["store_payments_insert_input"]: {
		amount?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	currency?: string,
	gateway?: string,
	id?: GraphQLTypes["uuid"],
	purchase_id?: string,
	remarks?: string,
	status?: string
};
	/** aggregate max on columns */
["store_payments_max_fields"]: {
	__typename: "store_payments_max_fields",
	amount?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	currency?: string,
	gateway?: string,
	id?: GraphQLTypes["uuid"],
	purchase_id?: string,
	remarks?: string,
	status?: string
};
	/** aggregate min on columns */
["store_payments_min_fields"]: {
	__typename: "store_payments_min_fields",
	amount?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	currency?: string,
	gateway?: string,
	id?: GraphQLTypes["uuid"],
	purchase_id?: string,
	remarks?: string,
	status?: string
};
	/** response of any mutation on the table "store.payments" */
["store_payments_mutation_response"]: {
	__typename: "store_payments_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["store_payments"]>
};
	/** on_conflict condition type for table "store.payments" */
["store_payments_on_conflict"]: {
		constraint: GraphQLTypes["store_payments_constraint"],
	update_columns: Array<GraphQLTypes["store_payments_update_column"]>,
	where?: GraphQLTypes["store_payments_bool_exp"]
};
	/** Ordering options when selecting data from "store.payments". */
["store_payments_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	currency?: GraphQLTypes["order_by"],
	gateway?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	purchase_id?: GraphQLTypes["order_by"],
	remarks?: GraphQLTypes["order_by"],
	status?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: store.payments */
["store_payments_pk_columns_input"]: {
		id: GraphQLTypes["uuid"],
	purchase_id: string
};
	/** select columns of table "store.payments" */
["store_payments_select_column"]: store_payments_select_column;
	/** input type for updating data in table "store.payments" */
["store_payments_set_input"]: {
		amount?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	currency?: string,
	gateway?: string,
	id?: GraphQLTypes["uuid"],
	purchase_id?: string,
	remarks?: string,
	status?: string
};
	/** aggregate stddev on columns */
["store_payments_stddev_fields"]: {
	__typename: "store_payments_stddev_fields",
	amount?: number
};
	/** aggregate stddev_pop on columns */
["store_payments_stddev_pop_fields"]: {
	__typename: "store_payments_stddev_pop_fields",
	amount?: number
};
	/** aggregate stddev_samp on columns */
["store_payments_stddev_samp_fields"]: {
	__typename: "store_payments_stddev_samp_fields",
	amount?: number
};
	/** Streaming cursor of the table "store_payments" */
["store_payments_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["store_payments_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["store_payments_stream_cursor_value_input"]: {
		amount?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	currency?: string,
	gateway?: string,
	id?: GraphQLTypes["uuid"],
	purchase_id?: string,
	remarks?: string,
	status?: string
};
	/** aggregate sum on columns */
["store_payments_sum_fields"]: {
	__typename: "store_payments_sum_fields",
	amount?: GraphQLTypes["numeric"]
};
	/** update columns of table "store.payments" */
["store_payments_update_column"]: store_payments_update_column;
	["store_payments_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["store_payments_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["store_payments_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["store_payments_bool_exp"]
};
	/** aggregate var_pop on columns */
["store_payments_var_pop_fields"]: {
	__typename: "store_payments_var_pop_fields",
	amount?: number
};
	/** aggregate var_samp on columns */
["store_payments_var_samp_fields"]: {
	__typename: "store_payments_var_samp_fields",
	amount?: number
};
	/** aggregate variance on columns */
["store_payments_variance_fields"]: {
	__typename: "store_payments_variance_fields",
	amount?: number
};
	/** columns and relationships of "store.products" */
["store_products"]: {
	__typename: "store_products",
	category?: string,
	chapter_type?: string,
	currency: string,
	description: string,
	enabled: boolean,
	id: GraphQLTypes["uuid"],
	image: string,
	is_store_visible: boolean,
	label?: string,
	name: string,
	price: GraphQLTypes["numeric"],
	purchase_limit: GraphQLTypes["numeric"],
	quantity: number,
	/** An object relationship */
	series?: GraphQLTypes["voyce_series"],
	series_id?: GraphQLTypes["numeric"],
	sort_num: GraphQLTypes["numeric"],
	tag?: string,
	type: string
};
	/** aggregated selection of "store.products" */
["store_products_aggregate"]: {
	__typename: "store_products_aggregate",
	aggregate?: GraphQLTypes["store_products_aggregate_fields"],
	nodes: Array<GraphQLTypes["store_products"]>
};
	/** aggregate fields of "store.products" */
["store_products_aggregate_fields"]: {
	__typename: "store_products_aggregate_fields",
	avg?: GraphQLTypes["store_products_avg_fields"],
	count: number,
	max?: GraphQLTypes["store_products_max_fields"],
	min?: GraphQLTypes["store_products_min_fields"],
	stddev?: GraphQLTypes["store_products_stddev_fields"],
	stddev_pop?: GraphQLTypes["store_products_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["store_products_stddev_samp_fields"],
	sum?: GraphQLTypes["store_products_sum_fields"],
	var_pop?: GraphQLTypes["store_products_var_pop_fields"],
	var_samp?: GraphQLTypes["store_products_var_samp_fields"],
	variance?: GraphQLTypes["store_products_variance_fields"]
};
	/** aggregate avg on columns */
["store_products_avg_fields"]: {
	__typename: "store_products_avg_fields",
	price?: number,
	purchase_limit?: number,
	quantity?: number,
	series_id?: number,
	sort_num?: number
};
	/** Boolean expression to filter rows from the table "store.products". All fields are combined with a logical 'AND'. */
["store_products_bool_exp"]: {
		_and?: Array<GraphQLTypes["store_products_bool_exp"]>,
	_not?: GraphQLTypes["store_products_bool_exp"],
	_or?: Array<GraphQLTypes["store_products_bool_exp"]>,
	category?: GraphQLTypes["String_comparison_exp"],
	chapter_type?: GraphQLTypes["String_comparison_exp"],
	currency?: GraphQLTypes["String_comparison_exp"],
	description?: GraphQLTypes["String_comparison_exp"],
	enabled?: GraphQLTypes["Boolean_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	image?: GraphQLTypes["String_comparison_exp"],
	is_store_visible?: GraphQLTypes["Boolean_comparison_exp"],
	label?: GraphQLTypes["String_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	price?: GraphQLTypes["numeric_comparison_exp"],
	purchase_limit?: GraphQLTypes["numeric_comparison_exp"],
	quantity?: GraphQLTypes["Int_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["numeric_comparison_exp"],
	sort_num?: GraphQLTypes["numeric_comparison_exp"],
	tag?: GraphQLTypes["String_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "store.products" */
["store_products_constraint"]: store_products_constraint;
	/** input type for incrementing numeric columns in table "store.products" */
["store_products_inc_input"]: {
		price?: GraphQLTypes["numeric"],
	purchase_limit?: GraphQLTypes["numeric"],
	quantity?: number,
	series_id?: GraphQLTypes["numeric"],
	sort_num?: GraphQLTypes["numeric"]
};
	/** input type for inserting data into table "store.products" */
["store_products_insert_input"]: {
		category?: string,
	chapter_type?: string,
	currency?: string,
	description?: string,
	enabled?: boolean,
	id?: GraphQLTypes["uuid"],
	image?: string,
	is_store_visible?: boolean,
	label?: string,
	name?: string,
	price?: GraphQLTypes["numeric"],
	purchase_limit?: GraphQLTypes["numeric"],
	quantity?: number,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: GraphQLTypes["numeric"],
	sort_num?: GraphQLTypes["numeric"],
	tag?: string,
	type?: string
};
	/** aggregate max on columns */
["store_products_max_fields"]: {
	__typename: "store_products_max_fields",
	category?: string,
	chapter_type?: string,
	currency?: string,
	description?: string,
	id?: GraphQLTypes["uuid"],
	image?: string,
	label?: string,
	name?: string,
	price?: GraphQLTypes["numeric"],
	purchase_limit?: GraphQLTypes["numeric"],
	quantity?: number,
	series_id?: GraphQLTypes["numeric"],
	sort_num?: GraphQLTypes["numeric"],
	tag?: string,
	type?: string
};
	/** aggregate min on columns */
["store_products_min_fields"]: {
	__typename: "store_products_min_fields",
	category?: string,
	chapter_type?: string,
	currency?: string,
	description?: string,
	id?: GraphQLTypes["uuid"],
	image?: string,
	label?: string,
	name?: string,
	price?: GraphQLTypes["numeric"],
	purchase_limit?: GraphQLTypes["numeric"],
	quantity?: number,
	series_id?: GraphQLTypes["numeric"],
	sort_num?: GraphQLTypes["numeric"],
	tag?: string,
	type?: string
};
	/** response of any mutation on the table "store.products" */
["store_products_mutation_response"]: {
	__typename: "store_products_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["store_products"]>
};
	/** on_conflict condition type for table "store.products" */
["store_products_on_conflict"]: {
		constraint: GraphQLTypes["store_products_constraint"],
	update_columns: Array<GraphQLTypes["store_products_update_column"]>,
	where?: GraphQLTypes["store_products_bool_exp"]
};
	/** Ordering options when selecting data from "store.products". */
["store_products_order_by"]: {
		category?: GraphQLTypes["order_by"],
	chapter_type?: GraphQLTypes["order_by"],
	currency?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	enabled?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	is_store_visible?: GraphQLTypes["order_by"],
	label?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	price?: GraphQLTypes["order_by"],
	purchase_limit?: GraphQLTypes["order_by"],
	quantity?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	sort_num?: GraphQLTypes["order_by"],
	tag?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: store.products */
["store_products_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "store.products" */
["store_products_select_column"]: store_products_select_column;
	/** input type for updating data in table "store.products" */
["store_products_set_input"]: {
		category?: string,
	chapter_type?: string,
	currency?: string,
	description?: string,
	enabled?: boolean,
	id?: GraphQLTypes["uuid"],
	image?: string,
	is_store_visible?: boolean,
	label?: string,
	name?: string,
	price?: GraphQLTypes["numeric"],
	purchase_limit?: GraphQLTypes["numeric"],
	quantity?: number,
	series_id?: GraphQLTypes["numeric"],
	sort_num?: GraphQLTypes["numeric"],
	tag?: string,
	type?: string
};
	/** aggregate stddev on columns */
["store_products_stddev_fields"]: {
	__typename: "store_products_stddev_fields",
	price?: number,
	purchase_limit?: number,
	quantity?: number,
	series_id?: number,
	sort_num?: number
};
	/** aggregate stddev_pop on columns */
["store_products_stddev_pop_fields"]: {
	__typename: "store_products_stddev_pop_fields",
	price?: number,
	purchase_limit?: number,
	quantity?: number,
	series_id?: number,
	sort_num?: number
};
	/** aggregate stddev_samp on columns */
["store_products_stddev_samp_fields"]: {
	__typename: "store_products_stddev_samp_fields",
	price?: number,
	purchase_limit?: number,
	quantity?: number,
	series_id?: number,
	sort_num?: number
};
	/** Streaming cursor of the table "store_products" */
["store_products_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["store_products_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["store_products_stream_cursor_value_input"]: {
		category?: string,
	chapter_type?: string,
	currency?: string,
	description?: string,
	enabled?: boolean,
	id?: GraphQLTypes["uuid"],
	image?: string,
	is_store_visible?: boolean,
	label?: string,
	name?: string,
	price?: GraphQLTypes["numeric"],
	purchase_limit?: GraphQLTypes["numeric"],
	quantity?: number,
	series_id?: GraphQLTypes["numeric"],
	sort_num?: GraphQLTypes["numeric"],
	tag?: string,
	type?: string
};
	/** aggregate sum on columns */
["store_products_sum_fields"]: {
	__typename: "store_products_sum_fields",
	price?: GraphQLTypes["numeric"],
	purchase_limit?: GraphQLTypes["numeric"],
	quantity?: number,
	series_id?: GraphQLTypes["numeric"],
	sort_num?: GraphQLTypes["numeric"]
};
	/** update columns of table "store.products" */
["store_products_update_column"]: store_products_update_column;
	["store_products_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["store_products_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["store_products_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["store_products_bool_exp"]
};
	/** aggregate var_pop on columns */
["store_products_var_pop_fields"]: {
	__typename: "store_products_var_pop_fields",
	price?: number,
	purchase_limit?: number,
	quantity?: number,
	series_id?: number,
	sort_num?: number
};
	/** aggregate var_samp on columns */
["store_products_var_samp_fields"]: {
	__typename: "store_products_var_samp_fields",
	price?: number,
	purchase_limit?: number,
	quantity?: number,
	series_id?: number,
	sort_num?: number
};
	/** aggregate variance on columns */
["store_products_variance_fields"]: {
	__typename: "store_products_variance_fields",
	price?: number,
	purchase_limit?: number,
	quantity?: number,
	series_id?: number,
	sort_num?: number
};
	/** columns and relationships of "store.purchases" */
["store_purchases"]: {
	__typename: "store_purchases",
	created_at: GraphQLTypes["timestamptz"],
	id: GraphQLTypes["uuid"],
	product_category?: string,
	product_currency: string,
	product_description?: string,
	product_image_url?: string,
	product_label?: string,
	product_meta?: GraphQLTypes["json"],
	product_name: string,
	product_price: GraphQLTypes["numeric"],
	product_type?: string,
	quantity: GraphQLTypes["numeric"],
	reference_id?: string,
	remarks?: string,
	series_id?: number,
	status: string,
	total_price: GraphQLTypes["numeric"],
	updated_at: GraphQLTypes["timestamptz"],
	user_id: GraphQLTypes["numeric"]
};
	/** aggregated selection of "store.purchases" */
["store_purchases_aggregate"]: {
	__typename: "store_purchases_aggregate",
	aggregate?: GraphQLTypes["store_purchases_aggregate_fields"],
	nodes: Array<GraphQLTypes["store_purchases"]>
};
	/** aggregate fields of "store.purchases" */
["store_purchases_aggregate_fields"]: {
	__typename: "store_purchases_aggregate_fields",
	avg?: GraphQLTypes["store_purchases_avg_fields"],
	count: number,
	max?: GraphQLTypes["store_purchases_max_fields"],
	min?: GraphQLTypes["store_purchases_min_fields"],
	stddev?: GraphQLTypes["store_purchases_stddev_fields"],
	stddev_pop?: GraphQLTypes["store_purchases_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["store_purchases_stddev_samp_fields"],
	sum?: GraphQLTypes["store_purchases_sum_fields"],
	var_pop?: GraphQLTypes["store_purchases_var_pop_fields"],
	var_samp?: GraphQLTypes["store_purchases_var_samp_fields"],
	variance?: GraphQLTypes["store_purchases_variance_fields"]
};
	/** aggregate avg on columns */
["store_purchases_avg_fields"]: {
	__typename: "store_purchases_avg_fields",
	product_price?: number,
	quantity?: number,
	series_id?: number,
	total_price?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "store.purchases". All fields are combined with a logical 'AND'. */
["store_purchases_bool_exp"]: {
		_and?: Array<GraphQLTypes["store_purchases_bool_exp"]>,
	_not?: GraphQLTypes["store_purchases_bool_exp"],
	_or?: Array<GraphQLTypes["store_purchases_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	product_category?: GraphQLTypes["String_comparison_exp"],
	product_currency?: GraphQLTypes["String_comparison_exp"],
	product_description?: GraphQLTypes["String_comparison_exp"],
	product_image_url?: GraphQLTypes["String_comparison_exp"],
	product_label?: GraphQLTypes["String_comparison_exp"],
	product_meta?: GraphQLTypes["json_comparison_exp"],
	product_name?: GraphQLTypes["String_comparison_exp"],
	product_price?: GraphQLTypes["numeric_comparison_exp"],
	product_type?: GraphQLTypes["String_comparison_exp"],
	quantity?: GraphQLTypes["numeric_comparison_exp"],
	reference_id?: GraphQLTypes["String_comparison_exp"],
	remarks?: GraphQLTypes["String_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	status?: GraphQLTypes["String_comparison_exp"],
	total_price?: GraphQLTypes["numeric_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	user_id?: GraphQLTypes["numeric_comparison_exp"]
};
	/** unique or primary key constraints on table "store.purchases" */
["store_purchases_constraint"]: store_purchases_constraint;
	/** input type for incrementing numeric columns in table "store.purchases" */
["store_purchases_inc_input"]: {
		product_price?: GraphQLTypes["numeric"],
	quantity?: GraphQLTypes["numeric"],
	series_id?: number,
	total_price?: GraphQLTypes["numeric"],
	user_id?: GraphQLTypes["numeric"]
};
	/** input type for inserting data into table "store.purchases" */
["store_purchases_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	product_category?: string,
	product_currency?: string,
	product_description?: string,
	product_image_url?: string,
	product_label?: string,
	product_meta?: GraphQLTypes["json"],
	product_name?: string,
	product_price?: GraphQLTypes["numeric"],
	product_type?: string,
	quantity?: GraphQLTypes["numeric"],
	reference_id?: string,
	remarks?: string,
	series_id?: number,
	status?: string,
	total_price?: GraphQLTypes["numeric"],
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: GraphQLTypes["numeric"]
};
	/** aggregate max on columns */
["store_purchases_max_fields"]: {
	__typename: "store_purchases_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	product_category?: string,
	product_currency?: string,
	product_description?: string,
	product_image_url?: string,
	product_label?: string,
	product_name?: string,
	product_price?: GraphQLTypes["numeric"],
	product_type?: string,
	quantity?: GraphQLTypes["numeric"],
	reference_id?: string,
	remarks?: string,
	series_id?: number,
	status?: string,
	total_price?: GraphQLTypes["numeric"],
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: GraphQLTypes["numeric"]
};
	/** aggregate min on columns */
["store_purchases_min_fields"]: {
	__typename: "store_purchases_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	product_category?: string,
	product_currency?: string,
	product_description?: string,
	product_image_url?: string,
	product_label?: string,
	product_name?: string,
	product_price?: GraphQLTypes["numeric"],
	product_type?: string,
	quantity?: GraphQLTypes["numeric"],
	reference_id?: string,
	remarks?: string,
	series_id?: number,
	status?: string,
	total_price?: GraphQLTypes["numeric"],
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: GraphQLTypes["numeric"]
};
	/** response of any mutation on the table "store.purchases" */
["store_purchases_mutation_response"]: {
	__typename: "store_purchases_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["store_purchases"]>
};
	/** on_conflict condition type for table "store.purchases" */
["store_purchases_on_conflict"]: {
		constraint: GraphQLTypes["store_purchases_constraint"],
	update_columns: Array<GraphQLTypes["store_purchases_update_column"]>,
	where?: GraphQLTypes["store_purchases_bool_exp"]
};
	/** Ordering options when selecting data from "store.purchases". */
["store_purchases_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	product_category?: GraphQLTypes["order_by"],
	product_currency?: GraphQLTypes["order_by"],
	product_description?: GraphQLTypes["order_by"],
	product_image_url?: GraphQLTypes["order_by"],
	product_label?: GraphQLTypes["order_by"],
	product_meta?: GraphQLTypes["order_by"],
	product_name?: GraphQLTypes["order_by"],
	product_price?: GraphQLTypes["order_by"],
	product_type?: GraphQLTypes["order_by"],
	quantity?: GraphQLTypes["order_by"],
	reference_id?: GraphQLTypes["order_by"],
	remarks?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	status?: GraphQLTypes["order_by"],
	total_price?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: store.purchases */
["store_purchases_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "store.purchases" */
["store_purchases_select_column"]: store_purchases_select_column;
	/** input type for updating data in table "store.purchases" */
["store_purchases_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	product_category?: string,
	product_currency?: string,
	product_description?: string,
	product_image_url?: string,
	product_label?: string,
	product_meta?: GraphQLTypes["json"],
	product_name?: string,
	product_price?: GraphQLTypes["numeric"],
	product_type?: string,
	quantity?: GraphQLTypes["numeric"],
	reference_id?: string,
	remarks?: string,
	series_id?: number,
	status?: string,
	total_price?: GraphQLTypes["numeric"],
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: GraphQLTypes["numeric"]
};
	/** aggregate stddev on columns */
["store_purchases_stddev_fields"]: {
	__typename: "store_purchases_stddev_fields",
	product_price?: number,
	quantity?: number,
	series_id?: number,
	total_price?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["store_purchases_stddev_pop_fields"]: {
	__typename: "store_purchases_stddev_pop_fields",
	product_price?: number,
	quantity?: number,
	series_id?: number,
	total_price?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["store_purchases_stddev_samp_fields"]: {
	__typename: "store_purchases_stddev_samp_fields",
	product_price?: number,
	quantity?: number,
	series_id?: number,
	total_price?: number,
	user_id?: number
};
	/** Streaming cursor of the table "store_purchases" */
["store_purchases_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["store_purchases_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["store_purchases_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	product_category?: string,
	product_currency?: string,
	product_description?: string,
	product_image_url?: string,
	product_label?: string,
	product_meta?: GraphQLTypes["json"],
	product_name?: string,
	product_price?: GraphQLTypes["numeric"],
	product_type?: string,
	quantity?: GraphQLTypes["numeric"],
	reference_id?: string,
	remarks?: string,
	series_id?: number,
	status?: string,
	total_price?: GraphQLTypes["numeric"],
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: GraphQLTypes["numeric"]
};
	/** aggregate sum on columns */
["store_purchases_sum_fields"]: {
	__typename: "store_purchases_sum_fields",
	product_price?: GraphQLTypes["numeric"],
	quantity?: GraphQLTypes["numeric"],
	series_id?: number,
	total_price?: GraphQLTypes["numeric"],
	user_id?: GraphQLTypes["numeric"]
};
	/** update columns of table "store.purchases" */
["store_purchases_update_column"]: store_purchases_update_column;
	["store_purchases_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["store_purchases_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["store_purchases_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["store_purchases_bool_exp"]
};
	/** aggregate var_pop on columns */
["store_purchases_var_pop_fields"]: {
	__typename: "store_purchases_var_pop_fields",
	product_price?: number,
	quantity?: number,
	series_id?: number,
	total_price?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["store_purchases_var_samp_fields"]: {
	__typename: "store_purchases_var_samp_fields",
	product_price?: number,
	quantity?: number,
	series_id?: number,
	total_price?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["store_purchases_variance_fields"]: {
	__typename: "store_purchases_variance_fields",
	product_price?: number,
	quantity?: number,
	series_id?: number,
	total_price?: number,
	user_id?: number
};
	/** columns and relationships of "store.users" */
["store_users"]: {
	__typename: "store_users",
	about?: string,
	ad_share?: number,
	avatar?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	email?: string,
	first_name?: string,
	gender?: string,
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	nickname?: string,
	password?: string,
	remember_code?: string,
	role_id?: number,
	username?: string,
	vmuser?: boolean
};
	/** aggregated selection of "store.users" */
["store_users_aggregate"]: {
	__typename: "store_users_aggregate",
	aggregate?: GraphQLTypes["store_users_aggregate_fields"],
	nodes: Array<GraphQLTypes["store_users"]>
};
	/** aggregate fields of "store.users" */
["store_users_aggregate_fields"]: {
	__typename: "store_users_aggregate_fields",
	avg?: GraphQLTypes["store_users_avg_fields"],
	count: number,
	max?: GraphQLTypes["store_users_max_fields"],
	min?: GraphQLTypes["store_users_min_fields"],
	stddev?: GraphQLTypes["store_users_stddev_fields"],
	stddev_pop?: GraphQLTypes["store_users_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["store_users_stddev_samp_fields"],
	sum?: GraphQLTypes["store_users_sum_fields"],
	var_pop?: GraphQLTypes["store_users_var_pop_fields"],
	var_samp?: GraphQLTypes["store_users_var_samp_fields"],
	variance?: GraphQLTypes["store_users_variance_fields"]
};
	/** aggregate avg on columns */
["store_users_avg_fields"]: {
	__typename: "store_users_avg_fields",
	ad_share?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	role_id?: number
};
	/** Boolean expression to filter rows from the table "store.users". All fields are combined with a logical 'AND'. */
["store_users_bool_exp"]: {
		_and?: Array<GraphQLTypes["store_users_bool_exp"]>,
	_not?: GraphQLTypes["store_users_bool_exp"],
	_or?: Array<GraphQLTypes["store_users_bool_exp"]>,
	about?: GraphQLTypes["String_comparison_exp"],
	ad_share?: GraphQLTypes["Int_comparison_exp"],
	avatar?: GraphQLTypes["String_comparison_exp"],
	banner?: GraphQLTypes["String_comparison_exp"],
	birth_date?: GraphQLTypes["date_comparison_exp"],
	dark_mode?: GraphQLTypes["smallint_comparison_exp"],
	date_create?: GraphQLTypes["timestamp_comparison_exp"],
	email?: GraphQLTypes["String_comparison_exp"],
	first_name?: GraphQLTypes["String_comparison_exp"],
	gender?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_account_active?: GraphQLTypes["smallint_comparison_exp"],
	last_login?: GraphQLTypes["timestamp_comparison_exp"],
	last_name?: GraphQLTypes["String_comparison_exp"],
	nickname?: GraphQLTypes["String_comparison_exp"],
	password?: GraphQLTypes["String_comparison_exp"],
	remember_code?: GraphQLTypes["String_comparison_exp"],
	role_id?: GraphQLTypes["Int_comparison_exp"],
	username?: GraphQLTypes["String_comparison_exp"],
	vmuser?: GraphQLTypes["Boolean_comparison_exp"]
};
	/** input type for incrementing numeric columns in table "store.users" */
["store_users_inc_input"]: {
		ad_share?: number,
	dark_mode?: GraphQLTypes["smallint"],
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	role_id?: number
};
	/** input type for inserting data into table "store.users" */
["store_users_insert_input"]: {
		about?: string,
	ad_share?: number,
	avatar?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	email?: string,
	first_name?: string,
	gender?: string,
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	nickname?: string,
	password?: string,
	remember_code?: string,
	role_id?: number,
	username?: string,
	vmuser?: boolean
};
	/** aggregate max on columns */
["store_users_max_fields"]: {
	__typename: "store_users_max_fields",
	about?: string,
	ad_share?: number,
	avatar?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	email?: string,
	first_name?: string,
	gender?: string,
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	nickname?: string,
	password?: string,
	remember_code?: string,
	role_id?: number,
	username?: string
};
	/** aggregate min on columns */
["store_users_min_fields"]: {
	__typename: "store_users_min_fields",
	about?: string,
	ad_share?: number,
	avatar?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	email?: string,
	first_name?: string,
	gender?: string,
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	nickname?: string,
	password?: string,
	remember_code?: string,
	role_id?: number,
	username?: string
};
	/** response of any mutation on the table "store.users" */
["store_users_mutation_response"]: {
	__typename: "store_users_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["store_users"]>
};
	/** Ordering options when selecting data from "store.users". */
["store_users_order_by"]: {
		about?: GraphQLTypes["order_by"],
	ad_share?: GraphQLTypes["order_by"],
	avatar?: GraphQLTypes["order_by"],
	banner?: GraphQLTypes["order_by"],
	birth_date?: GraphQLTypes["order_by"],
	dark_mode?: GraphQLTypes["order_by"],
	date_create?: GraphQLTypes["order_by"],
	email?: GraphQLTypes["order_by"],
	first_name?: GraphQLTypes["order_by"],
	gender?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_account_active?: GraphQLTypes["order_by"],
	last_login?: GraphQLTypes["order_by"],
	last_name?: GraphQLTypes["order_by"],
	nickname?: GraphQLTypes["order_by"],
	password?: GraphQLTypes["order_by"],
	remember_code?: GraphQLTypes["order_by"],
	role_id?: GraphQLTypes["order_by"],
	username?: GraphQLTypes["order_by"],
	vmuser?: GraphQLTypes["order_by"]
};
	/** select columns of table "store.users" */
["store_users_select_column"]: store_users_select_column;
	/** input type for updating data in table "store.users" */
["store_users_set_input"]: {
		about?: string,
	ad_share?: number,
	avatar?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	email?: string,
	first_name?: string,
	gender?: string,
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	nickname?: string,
	password?: string,
	remember_code?: string,
	role_id?: number,
	username?: string,
	vmuser?: boolean
};
	/** aggregate stddev on columns */
["store_users_stddev_fields"]: {
	__typename: "store_users_stddev_fields",
	ad_share?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	role_id?: number
};
	/** aggregate stddev_pop on columns */
["store_users_stddev_pop_fields"]: {
	__typename: "store_users_stddev_pop_fields",
	ad_share?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	role_id?: number
};
	/** aggregate stddev_samp on columns */
["store_users_stddev_samp_fields"]: {
	__typename: "store_users_stddev_samp_fields",
	ad_share?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	role_id?: number
};
	/** Streaming cursor of the table "store_users" */
["store_users_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["store_users_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["store_users_stream_cursor_value_input"]: {
		about?: string,
	ad_share?: number,
	avatar?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	email?: string,
	first_name?: string,
	gender?: string,
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	nickname?: string,
	password?: string,
	remember_code?: string,
	role_id?: number,
	username?: string,
	vmuser?: boolean
};
	/** aggregate sum on columns */
["store_users_sum_fields"]: {
	__typename: "store_users_sum_fields",
	ad_share?: number,
	dark_mode?: GraphQLTypes["smallint"],
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	role_id?: number
};
	["store_users_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["store_users_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["store_users_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["store_users_bool_exp"]
};
	/** aggregate var_pop on columns */
["store_users_var_pop_fields"]: {
	__typename: "store_users_var_pop_fields",
	ad_share?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	role_id?: number
};
	/** aggregate var_samp on columns */
["store_users_var_samp_fields"]: {
	__typename: "store_users_var_samp_fields",
	ad_share?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	role_id?: number
};
	/** aggregate variance on columns */
["store_users_variance_fields"]: {
	__typename: "store_users_variance_fields",
	ad_share?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	role_id?: number
};
	["subscription_root"]: {
	__typename: "subscription_root",
	/** fetch data from the table: "awsdms_validation_failures_v1" */
	awsdms_validation_failures_v1: Array<GraphQLTypes["awsdms_validation_failures_v1"]>,
	/** fetch aggregated fields from the table: "awsdms_validation_failures_v1" */
	awsdms_validation_failures_v1_aggregate: GraphQLTypes["awsdms_validation_failures_v1_aggregate"],
	/** fetch data from the table in a streaming manner: "awsdms_validation_failures_v1" */
	awsdms_validation_failures_v1_stream: Array<GraphQLTypes["awsdms_validation_failures_v1"]>,
	/** fetch data from the table: "coinsystem.balances" */
	coinsystem_balances: Array<GraphQLTypes["coinsystem_balances"]>,
	/** fetch aggregated fields from the table: "coinsystem.balances" */
	coinsystem_balances_aggregate: GraphQLTypes["coinsystem_balances_aggregate"],
	/** fetch data from the table: "coinsystem.balances" using primary key columns */
	coinsystem_balances_by_pk?: GraphQLTypes["coinsystem_balances"],
	/** fetch data from the table in a streaming manner: "coinsystem.balances" */
	coinsystem_balances_stream: Array<GraphQLTypes["coinsystem_balances"]>,
	/** fetch data from the table: "coinsystem.bonus_coins" */
	coinsystem_bonus_coins: Array<GraphQLTypes["coinsystem_bonus_coins"]>,
	/** fetch aggregated fields from the table: "coinsystem.bonus_coins" */
	coinsystem_bonus_coins_aggregate: GraphQLTypes["coinsystem_bonus_coins_aggregate"],
	/** fetch data from the table: "coinsystem.bonus_coins" using primary key columns */
	coinsystem_bonus_coins_by_pk?: GraphQLTypes["coinsystem_bonus_coins"],
	/** fetch data from the table in a streaming manner: "coinsystem.bonus_coins" */
	coinsystem_bonus_coins_stream: Array<GraphQLTypes["coinsystem_bonus_coins"]>,
	/** fetch data from the table: "coinsystem.coins" */
	coinsystem_coins: Array<GraphQLTypes["coinsystem_coins"]>,
	/** fetch aggregated fields from the table: "coinsystem.coins" */
	coinsystem_coins_aggregate: GraphQLTypes["coinsystem_coins_aggregate"],
	/** fetch data from the table: "coinsystem.coins" using primary key columns */
	coinsystem_coins_by_pk?: GraphQLTypes["coinsystem_coins"],
	/** fetch data from the table in a streaming manner: "coinsystem.coins" */
	coinsystem_coins_stream: Array<GraphQLTypes["coinsystem_coins"]>,
	/** fetch data from the table: "coinsystem.exchange_rates" */
	coinsystem_exchange_rates: Array<GraphQLTypes["coinsystem_exchange_rates"]>,
	/** fetch aggregated fields from the table: "coinsystem.exchange_rates" */
	coinsystem_exchange_rates_aggregate: GraphQLTypes["coinsystem_exchange_rates_aggregate"],
	/** fetch data from the table: "coinsystem.exchange_rates" using primary key columns */
	coinsystem_exchange_rates_by_pk?: GraphQLTypes["coinsystem_exchange_rates"],
	/** fetch data from the table in a streaming manner: "coinsystem.exchange_rates" */
	coinsystem_exchange_rates_stream: Array<GraphQLTypes["coinsystem_exchange_rates"]>,
	/** fetch data from the table: "coinsystem.logs" */
	coinsystem_logs: Array<GraphQLTypes["coinsystem_logs"]>,
	/** fetch aggregated fields from the table: "coinsystem.logs" */
	coinsystem_logs_aggregate: GraphQLTypes["coinsystem_logs_aggregate"],
	/** fetch data from the table: "coinsystem.logs" using primary key columns */
	coinsystem_logs_by_pk?: GraphQLTypes["coinsystem_logs"],
	/** fetch data from the table in a streaming manner: "coinsystem.logs" */
	coinsystem_logs_stream: Array<GraphQLTypes["coinsystem_logs"]>,
	/** fetch data from the table: "coinsystem.transactions" */
	coinsystem_transactions: Array<GraphQLTypes["coinsystem_transactions"]>,
	/** fetch aggregated fields from the table: "coinsystem.transactions" */
	coinsystem_transactions_aggregate: GraphQLTypes["coinsystem_transactions_aggregate"],
	/** fetch data from the table: "coinsystem.transactions" using primary key columns */
	coinsystem_transactions_by_pk?: GraphQLTypes["coinsystem_transactions"],
	/** fetch data from the table in a streaming manner: "coinsystem.transactions" */
	coinsystem_transactions_stream: Array<GraphQLTypes["coinsystem_transactions"]>,
	/** fetch data from the table: "coinsystem.wallets" */
	coinsystem_wallets: Array<GraphQLTypes["coinsystem_wallets"]>,
	/** fetch aggregated fields from the table: "coinsystem.wallets" */
	coinsystem_wallets_aggregate: GraphQLTypes["coinsystem_wallets_aggregate"],
	/** fetch data from the table: "coinsystem.wallets" using primary key columns */
	coinsystem_wallets_by_pk?: GraphQLTypes["coinsystem_wallets"],
	/** fetch data from the table in a streaming manner: "coinsystem.wallets" */
	coinsystem_wallets_stream: Array<GraphQLTypes["coinsystem_wallets"]>,
	/** fetch data from the table: "mysql.user" */
	mysql_user: Array<GraphQLTypes["mysql_user"]>,
	/** fetch aggregated fields from the table: "mysql.user" */
	mysql_user_aggregate: GraphQLTypes["mysql_user_aggregate"],
	/** fetch data from the table: "mysql.user" using primary key columns */
	mysql_user_by_pk?: GraphQLTypes["mysql_user"],
	/** fetch data from the table in a streaming manner: "mysql.user" */
	mysql_user_stream: Array<GraphQLTypes["mysql_user"]>,
	/** fetch data from the table: "store.payments" */
	store_payments: Array<GraphQLTypes["store_payments"]>,
	/** fetch aggregated fields from the table: "store.payments" */
	store_payments_aggregate: GraphQLTypes["store_payments_aggregate"],
	/** fetch data from the table: "store.payments" using primary key columns */
	store_payments_by_pk?: GraphQLTypes["store_payments"],
	/** fetch data from the table in a streaming manner: "store.payments" */
	store_payments_stream: Array<GraphQLTypes["store_payments"]>,
	/** fetch data from the table: "store.products" */
	store_products: Array<GraphQLTypes["store_products"]>,
	/** fetch aggregated fields from the table: "store.products" */
	store_products_aggregate: GraphQLTypes["store_products_aggregate"],
	/** fetch data from the table: "store.products" using primary key columns */
	store_products_by_pk?: GraphQLTypes["store_products"],
	/** fetch data from the table in a streaming manner: "store.products" */
	store_products_stream: Array<GraphQLTypes["store_products"]>,
	/** fetch data from the table: "store.purchases" */
	store_purchases: Array<GraphQLTypes["store_purchases"]>,
	/** fetch aggregated fields from the table: "store.purchases" */
	store_purchases_aggregate: GraphQLTypes["store_purchases_aggregate"],
	/** fetch data from the table: "store.purchases" using primary key columns */
	store_purchases_by_pk?: GraphQLTypes["store_purchases"],
	/** fetch data from the table in a streaming manner: "store.purchases" */
	store_purchases_stream: Array<GraphQLTypes["store_purchases"]>,
	/** fetch data from the table: "store.users" */
	store_users: Array<GraphQLTypes["store_users"]>,
	/** fetch aggregated fields from the table: "store.users" */
	store_users_aggregate: GraphQLTypes["store_users_aggregate"],
	/** fetch data from the table in a streaming manner: "store.users" */
	store_users_stream: Array<GraphQLTypes["store_users"]>,
	/** fetch data from the table: "voyce.admin_roles" */
	voyce_admin_roles: Array<GraphQLTypes["voyce_admin_roles"]>,
	/** fetch aggregated fields from the table: "voyce.admin_roles" */
	voyce_admin_roles_aggregate: GraphQLTypes["voyce_admin_roles_aggregate"],
	/** fetch data from the table: "voyce.admin_roles" using primary key columns */
	voyce_admin_roles_by_pk?: GraphQLTypes["voyce_admin_roles"],
	/** fetch data from the table in a streaming manner: "voyce.admin_roles" */
	voyce_admin_roles_stream: Array<GraphQLTypes["voyce_admin_roles"]>,
	/** fetch data from the table: "voyce.app_banners" */
	voyce_app_banners: Array<GraphQLTypes["voyce_app_banners"]>,
	/** fetch aggregated fields from the table: "voyce.app_banners" */
	voyce_app_banners_aggregate: GraphQLTypes["voyce_app_banners_aggregate"],
	/** fetch data from the table: "voyce.app_banners" using primary key columns */
	voyce_app_banners_by_pk?: GraphQLTypes["voyce_app_banners"],
	/** fetch data from the table in a streaming manner: "voyce.app_banners" */
	voyce_app_banners_stream: Array<GraphQLTypes["voyce_app_banners"]>,
	/** fetch data from the table: "voyce.app_components" */
	voyce_app_components: Array<GraphQLTypes["voyce_app_components"]>,
	/** fetch aggregated fields from the table: "voyce.app_components" */
	voyce_app_components_aggregate: GraphQLTypes["voyce_app_components_aggregate"],
	/** fetch data from the table: "voyce.app_components" using primary key columns */
	voyce_app_components_by_pk?: GraphQLTypes["voyce_app_components"],
	/** fetch data from the table in a streaming manner: "voyce.app_components" */
	voyce_app_components_stream: Array<GraphQLTypes["voyce_app_components"]>,
	/** fetch data from the table: "voyce.audit_trail" */
	voyce_audit_trail: Array<GraphQLTypes["voyce_audit_trail"]>,
	/** fetch aggregated fields from the table: "voyce.audit_trail" */
	voyce_audit_trail_aggregate: GraphQLTypes["voyce_audit_trail_aggregate"],
	/** fetch data from the table: "voyce.audit_trail" using primary key columns */
	voyce_audit_trail_by_pk?: GraphQLTypes["voyce_audit_trail"],
	/** fetch data from the table in a streaming manner: "voyce.audit_trail" */
	voyce_audit_trail_stream: Array<GraphQLTypes["voyce_audit_trail"]>,
	/** fetch data from the table: "voyce.author_profiles" */
	voyce_author_profiles: Array<GraphQLTypes["voyce_author_profiles"]>,
	/** fetch aggregated fields from the table: "voyce.author_profiles" */
	voyce_author_profiles_aggregate: GraphQLTypes["voyce_author_profiles_aggregate"],
	/** fetch data from the table: "voyce.author_profiles" using primary key columns */
	voyce_author_profiles_by_pk?: GraphQLTypes["voyce_author_profiles"],
	/** fetch data from the table in a streaming manner: "voyce.author_profiles" */
	voyce_author_profiles_stream: Array<GraphQLTypes["voyce_author_profiles"]>,
	/** fetch data from the table: "voyce.balances" */
	voyce_balances: Array<GraphQLTypes["voyce_balances"]>,
	/** fetch aggregated fields from the table: "voyce.balances" */
	voyce_balances_aggregate: GraphQLTypes["voyce_balances_aggregate"],
	/** fetch data from the table: "voyce.balances" using primary key columns */
	voyce_balances_by_pk?: GraphQLTypes["voyce_balances"],
	/** fetch data from the table in a streaming manner: "voyce.balances" */
	voyce_balances_stream: Array<GraphQLTypes["voyce_balances"]>,
	/** fetch data from the table: "voyce.banners" */
	voyce_banners: Array<GraphQLTypes["voyce_banners"]>,
	/** fetch aggregated fields from the table: "voyce.banners" */
	voyce_banners_aggregate: GraphQLTypes["voyce_banners_aggregate"],
	/** fetch data from the table: "voyce.banners" using primary key columns */
	voyce_banners_by_pk?: GraphQLTypes["voyce_banners"],
	/** fetch data from the table in a streaming manner: "voyce.banners" */
	voyce_banners_stream: Array<GraphQLTypes["voyce_banners"]>,
	/** fetch data from the table: "voyce.chapter_assets" */
	voyce_chapter_assets: Array<GraphQLTypes["voyce_chapter_assets"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_assets" */
	voyce_chapter_assets_aggregate: GraphQLTypes["voyce_chapter_assets_aggregate"],
	/** fetch data from the table: "voyce.chapter_assets" using primary key columns */
	voyce_chapter_assets_by_pk?: GraphQLTypes["voyce_chapter_assets"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_assets" */
	voyce_chapter_assets_stream: Array<GraphQLTypes["voyce_chapter_assets"]>,
	/** fetch data from the table: "voyce.chapter_bookmarks" */
	voyce_chapter_bookmarks: Array<GraphQLTypes["voyce_chapter_bookmarks"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_bookmarks" */
	voyce_chapter_bookmarks_aggregate: GraphQLTypes["voyce_chapter_bookmarks_aggregate"],
	/** fetch data from the table: "voyce.chapter_bookmarks" using primary key columns */
	voyce_chapter_bookmarks_by_pk?: GraphQLTypes["voyce_chapter_bookmarks"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_bookmarks" */
	voyce_chapter_bookmarks_stream: Array<GraphQLTypes["voyce_chapter_bookmarks"]>,
	/** fetch data from the table: "voyce.chapter_comments" */
	voyce_chapter_comments: Array<GraphQLTypes["voyce_chapter_comments"]>,
	/** fetch data from the table: "voyce.chapter_comments_07252024" */
	voyce_chapter_comments_07252024: Array<GraphQLTypes["voyce_chapter_comments_07252024"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_comments_07252024" */
	voyce_chapter_comments_07252024_aggregate: GraphQLTypes["voyce_chapter_comments_07252024_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_comments_07252024" */
	voyce_chapter_comments_07252024_stream: Array<GraphQLTypes["voyce_chapter_comments_07252024"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_comments" */
	voyce_chapter_comments_aggregate: GraphQLTypes["voyce_chapter_comments_aggregate"],
	/** fetch data from the table: "voyce.chapter_comments" using primary key columns */
	voyce_chapter_comments_by_pk?: GraphQLTypes["voyce_chapter_comments"],
	/** fetch data from the table: "voyce.chapter_comments_reaction" */
	voyce_chapter_comments_reaction: Array<GraphQLTypes["voyce_chapter_comments_reaction"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_comments_reaction" */
	voyce_chapter_comments_reaction_aggregate: GraphQLTypes["voyce_chapter_comments_reaction_aggregate"],
	/** fetch data from the table: "voyce.chapter_comments_reaction" using primary key columns */
	voyce_chapter_comments_reaction_by_pk?: GraphQLTypes["voyce_chapter_comments_reaction"],
	/** fetch data from the table: "voyce.chapter_comments_reaction_count" */
	voyce_chapter_comments_reaction_count: Array<GraphQLTypes["voyce_chapter_comments_reaction_count"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_comments_reaction_count" */
	voyce_chapter_comments_reaction_count_aggregate: GraphQLTypes["voyce_chapter_comments_reaction_count_aggregate"],
	/** fetch data from the table: "voyce.chapter_comments_reaction_count" using primary key columns */
	voyce_chapter_comments_reaction_count_by_pk?: GraphQLTypes["voyce_chapter_comments_reaction_count"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_comments_reaction_count" */
	voyce_chapter_comments_reaction_count_stream: Array<GraphQLTypes["voyce_chapter_comments_reaction_count"]>,
	/** fetch data from the table in a streaming manner: "voyce.chapter_comments_reaction" */
	voyce_chapter_comments_reaction_stream: Array<GraphQLTypes["voyce_chapter_comments_reaction"]>,
	/** fetch data from the table: "voyce.chapter_comments_reports" */
	voyce_chapter_comments_reports: Array<GraphQLTypes["voyce_chapter_comments_reports"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_comments_reports" */
	voyce_chapter_comments_reports_aggregate: GraphQLTypes["voyce_chapter_comments_reports_aggregate"],
	/** fetch data from the table: "voyce.chapter_comments_reports" using primary key columns */
	voyce_chapter_comments_reports_by_pk?: GraphQLTypes["voyce_chapter_comments_reports"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_comments_reports" */
	voyce_chapter_comments_reports_stream: Array<GraphQLTypes["voyce_chapter_comments_reports"]>,
	/** fetch data from the table in a streaming manner: "voyce.chapter_comments" */
	voyce_chapter_comments_stream: Array<GraphQLTypes["voyce_chapter_comments"]>,
	/** fetch data from the table: "voyce.chapter_contents" */
	voyce_chapter_contents: Array<GraphQLTypes["voyce_chapter_contents"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_contents" */
	voyce_chapter_contents_aggregate: GraphQLTypes["voyce_chapter_contents_aggregate"],
	/** fetch data from the table: "voyce.chapter_contents" using primary key columns */
	voyce_chapter_contents_by_pk?: GraphQLTypes["voyce_chapter_contents"],
	/** fetch data from the table: "voyce.chapter_contents_old" */
	voyce_chapter_contents_old: Array<GraphQLTypes["voyce_chapter_contents_old"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_contents_old" */
	voyce_chapter_contents_old_aggregate: GraphQLTypes["voyce_chapter_contents_old_aggregate"],
	/** fetch data from the table: "voyce.chapter_contents_old" using primary key columns */
	voyce_chapter_contents_old_by_pk?: GraphQLTypes["voyce_chapter_contents_old"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_contents_old" */
	voyce_chapter_contents_old_stream: Array<GraphQLTypes["voyce_chapter_contents_old"]>,
	/** fetch data from the table in a streaming manner: "voyce.chapter_contents" */
	voyce_chapter_contents_stream: Array<GraphQLTypes["voyce_chapter_contents"]>,
	/** fetch data from the table: "voyce.chapter_effects" */
	voyce_chapter_effects: Array<GraphQLTypes["voyce_chapter_effects"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_effects" */
	voyce_chapter_effects_aggregate: GraphQLTypes["voyce_chapter_effects_aggregate"],
	/** fetch data from the table: "voyce.chapter_effects" using primary key columns */
	voyce_chapter_effects_by_pk?: GraphQLTypes["voyce_chapter_effects"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_effects" */
	voyce_chapter_effects_stream: Array<GraphQLTypes["voyce_chapter_effects"]>,
	/** fetch data from the table: "voyce.chapter_images" */
	voyce_chapter_images: Array<GraphQLTypes["voyce_chapter_images"]>,
	/** fetch data from the table: "voyce.chapter_images_07252024" */
	voyce_chapter_images_07252024: Array<GraphQLTypes["voyce_chapter_images_07252024"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_images_07252024" */
	voyce_chapter_images_07252024_aggregate: GraphQLTypes["voyce_chapter_images_07252024_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_images_07252024" */
	voyce_chapter_images_07252024_stream: Array<GraphQLTypes["voyce_chapter_images_07252024"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_images" */
	voyce_chapter_images_aggregate: GraphQLTypes["voyce_chapter_images_aggregate"],
	/** fetch data from the table: "voyce.chapter_images" using primary key columns */
	voyce_chapter_images_by_pk?: GraphQLTypes["voyce_chapter_images"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_images" */
	voyce_chapter_images_stream: Array<GraphQLTypes["voyce_chapter_images"]>,
	/** fetch data from the table: "voyce.chapter_likes" */
	voyce_chapter_likes: Array<GraphQLTypes["voyce_chapter_likes"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_likes" */
	voyce_chapter_likes_aggregate: GraphQLTypes["voyce_chapter_likes_aggregate"],
	/** fetch data from the table: "voyce.chapter_likes" using primary key columns */
	voyce_chapter_likes_by_pk?: GraphQLTypes["voyce_chapter_likes"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_likes" */
	voyce_chapter_likes_stream: Array<GraphQLTypes["voyce_chapter_likes"]>,
	/** fetch data from the table: "voyce.chapter_locks" */
	voyce_chapter_locks: Array<GraphQLTypes["voyce_chapter_locks"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_locks" */
	voyce_chapter_locks_aggregate: GraphQLTypes["voyce_chapter_locks_aggregate"],
	/** fetch data from the table: "voyce.chapter_locks" using primary key columns */
	voyce_chapter_locks_by_pk?: GraphQLTypes["voyce_chapter_locks"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_locks" */
	voyce_chapter_locks_stream: Array<GraphQLTypes["voyce_chapter_locks"]>,
	/** fetch data from the table: "voyce.chapter_panel_views" */
	voyce_chapter_panel_views: Array<GraphQLTypes["voyce_chapter_panel_views"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_panel_views" */
	voyce_chapter_panel_views_aggregate: GraphQLTypes["voyce_chapter_panel_views_aggregate"],
	/** fetch data from the table: "voyce.chapter_panel_views" using primary key columns */
	voyce_chapter_panel_views_by_pk?: GraphQLTypes["voyce_chapter_panel_views"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_panel_views" */
	voyce_chapter_panel_views_stream: Array<GraphQLTypes["voyce_chapter_panel_views"]>,
	/** fetch data from the table: "voyce.chapter_panels" */
	voyce_chapter_panels: Array<GraphQLTypes["voyce_chapter_panels"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_panels" */
	voyce_chapter_panels_aggregate: GraphQLTypes["voyce_chapter_panels_aggregate"],
	/** fetch data from the table: "voyce.chapter_panels" using primary key columns */
	voyce_chapter_panels_by_pk?: GraphQLTypes["voyce_chapter_panels"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_panels" */
	voyce_chapter_panels_stream: Array<GraphQLTypes["voyce_chapter_panels"]>,
	/** fetch data from the table: "voyce.chapter_publish_schedules" */
	voyce_chapter_publish_schedules: Array<GraphQLTypes["voyce_chapter_publish_schedules"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_publish_schedules" */
	voyce_chapter_publish_schedules_aggregate: GraphQLTypes["voyce_chapter_publish_schedules_aggregate"],
	/** fetch data from the table: "voyce.chapter_publish_schedules" using primary key columns */
	voyce_chapter_publish_schedules_by_pk?: GraphQLTypes["voyce_chapter_publish_schedules"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_publish_schedules" */
	voyce_chapter_publish_schedules_stream: Array<GraphQLTypes["voyce_chapter_publish_schedules"]>,
	/** fetch data from the table: "voyce.chapter_reactions" */
	voyce_chapter_reactions: Array<GraphQLTypes["voyce_chapter_reactions"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_reactions" */
	voyce_chapter_reactions_aggregate: GraphQLTypes["voyce_chapter_reactions_aggregate"],
	/** fetch data from the table: "voyce.chapter_reactions" using primary key columns */
	voyce_chapter_reactions_by_pk?: GraphQLTypes["voyce_chapter_reactions"],
	/** fetch data from the table: "voyce.chapter_reactions_count" */
	voyce_chapter_reactions_count: Array<GraphQLTypes["voyce_chapter_reactions_count"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_reactions_count" */
	voyce_chapter_reactions_count_aggregate: GraphQLTypes["voyce_chapter_reactions_count_aggregate"],
	/** fetch data from the table: "voyce.chapter_reactions_count" using primary key columns */
	voyce_chapter_reactions_count_by_pk?: GraphQLTypes["voyce_chapter_reactions_count"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_reactions_count" */
	voyce_chapter_reactions_count_stream: Array<GraphQLTypes["voyce_chapter_reactions_count"]>,
	/** fetch data from the table in a streaming manner: "voyce.chapter_reactions" */
	voyce_chapter_reactions_stream: Array<GraphQLTypes["voyce_chapter_reactions"]>,
	/** fetch data from the table: "voyce.chapter_view_logs" */
	voyce_chapter_view_logs: Array<GraphQLTypes["voyce_chapter_view_logs"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_view_logs" */
	voyce_chapter_view_logs_aggregate: GraphQLTypes["voyce_chapter_view_logs_aggregate"],
	/** fetch data from the table: "voyce.chapter_view_logs" using primary key columns */
	voyce_chapter_view_logs_by_pk?: GraphQLTypes["voyce_chapter_view_logs"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_view_logs" */
	voyce_chapter_view_logs_stream: Array<GraphQLTypes["voyce_chapter_view_logs"]>,
	/** fetch data from the table: "voyce.chapter_views" */
	voyce_chapter_views: Array<GraphQLTypes["voyce_chapter_views"]>,
	/** fetch aggregated fields from the table: "voyce.chapter_views" */
	voyce_chapter_views_aggregate: GraphQLTypes["voyce_chapter_views_aggregate"],
	/** fetch data from the table: "voyce.chapter_views" using primary key columns */
	voyce_chapter_views_by_pk?: GraphQLTypes["voyce_chapter_views"],
	/** fetch data from the table in a streaming manner: "voyce.chapter_views" */
	voyce_chapter_views_stream: Array<GraphQLTypes["voyce_chapter_views"]>,
	/** fetch data from the table: "voyce.chapters" */
	voyce_chapters: Array<GraphQLTypes["voyce_chapters"]>,
	/** fetch data from the table: "voyce.chapters_07252024" */
	voyce_chapters_07252024: Array<GraphQLTypes["voyce_chapters_07252024"]>,
	/** fetch aggregated fields from the table: "voyce.chapters_07252024" */
	voyce_chapters_07252024_aggregate: GraphQLTypes["voyce_chapters_07252024_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.chapters_07252024" */
	voyce_chapters_07252024_stream: Array<GraphQLTypes["voyce_chapters_07252024"]>,
	/** fetch aggregated fields from the table: "voyce.chapters" */
	voyce_chapters_aggregate: GraphQLTypes["voyce_chapters_aggregate"],
	/** fetch data from the table: "voyce.chapters" using primary key columns */
	voyce_chapters_by_pk?: GraphQLTypes["voyce_chapters"],
	/** fetch data from the table in a streaming manner: "voyce.chapters" */
	voyce_chapters_stream: Array<GraphQLTypes["voyce_chapters"]>,
	/** fetch data from the table: "voyce.collaborator_roles" */
	voyce_collaborator_roles: Array<GraphQLTypes["voyce_collaborator_roles"]>,
	/** fetch aggregated fields from the table: "voyce.collaborator_roles" */
	voyce_collaborator_roles_aggregate: GraphQLTypes["voyce_collaborator_roles_aggregate"],
	/** fetch data from the table: "voyce.collaborator_roles" using primary key columns */
	voyce_collaborator_roles_by_pk?: GraphQLTypes["voyce_collaborator_roles"],
	/** fetch data from the table in a streaming manner: "voyce.collaborator_roles" */
	voyce_collaborator_roles_stream: Array<GraphQLTypes["voyce_collaborator_roles"]>,
	/** fetch data from the table: "voyce.comic_types" */
	voyce_comic_types: Array<GraphQLTypes["voyce_comic_types"]>,
	/** fetch aggregated fields from the table: "voyce.comic_types" */
	voyce_comic_types_aggregate: GraphQLTypes["voyce_comic_types_aggregate"],
	/** fetch data from the table: "voyce.comic_types" using primary key columns */
	voyce_comic_types_by_pk?: GraphQLTypes["voyce_comic_types"],
	/** fetch data from the table in a streaming manner: "voyce.comic_types" */
	voyce_comic_types_stream: Array<GraphQLTypes["voyce_comic_types"]>,
	/** fetch data from the table: "voyce.comments_top_mat" */
	voyce_comments_top_mat: Array<GraphQLTypes["voyce_comments_top_mat"]>,
	/** fetch aggregated fields from the table: "voyce.comments_top_mat" */
	voyce_comments_top_mat_aggregate: GraphQLTypes["voyce_comments_top_mat_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.comments_top_mat" */
	voyce_comments_top_mat_stream: Array<GraphQLTypes["voyce_comments_top_mat"]>,
	/** fetch data from the table: "voyce.community_blurbs" */
	voyce_community_blurbs: Array<GraphQLTypes["voyce_community_blurbs"]>,
	/** fetch aggregated fields from the table: "voyce.community_blurbs" */
	voyce_community_blurbs_aggregate: GraphQLTypes["voyce_community_blurbs_aggregate"],
	/** fetch data from the table: "voyce.community_blurbs" using primary key columns */
	voyce_community_blurbs_by_pk?: GraphQLTypes["voyce_community_blurbs"],
	/** fetch data from the table in a streaming manner: "voyce.community_blurbs" */
	voyce_community_blurbs_stream: Array<GraphQLTypes["voyce_community_blurbs"]>,
	/** fetch data from the table: "voyce.contact_us" */
	voyce_contact_us: Array<GraphQLTypes["voyce_contact_us"]>,
	/** fetch aggregated fields from the table: "voyce.contact_us" */
	voyce_contact_us_aggregate: GraphQLTypes["voyce_contact_us_aggregate"],
	/** fetch data from the table: "voyce.contact_us" using primary key columns */
	voyce_contact_us_by_pk?: GraphQLTypes["voyce_contact_us"],
	/** fetch data from the table in a streaming manner: "voyce.contact_us" */
	voyce_contact_us_stream: Array<GraphQLTypes["voyce_contact_us"]>,
	/** fetch data from the table: "voyce.content_report" */
	voyce_content_report: Array<GraphQLTypes["voyce_content_report"]>,
	/** fetch aggregated fields from the table: "voyce.content_report" */
	voyce_content_report_aggregate: GraphQLTypes["voyce_content_report_aggregate"],
	/** fetch data from the table: "voyce.content_report" using primary key columns */
	voyce_content_report_by_pk?: GraphQLTypes["voyce_content_report"],
	/** fetch data from the table in a streaming manner: "voyce.content_report" */
	voyce_content_report_stream: Array<GraphQLTypes["voyce_content_report"]>,
	/** fetch data from the table: "voyce.contest_data" */
	voyce_contest_data: Array<GraphQLTypes["voyce_contest_data"]>,
	/** fetch aggregated fields from the table: "voyce.contest_data" */
	voyce_contest_data_aggregate: GraphQLTypes["voyce_contest_data_aggregate"],
	/** fetch data from the table: "voyce.contest_data" using primary key columns */
	voyce_contest_data_by_pk?: GraphQLTypes["voyce_contest_data"],
	/** fetch data from the table in a streaming manner: "voyce.contest_data" */
	voyce_contest_data_stream: Array<GraphQLTypes["voyce_contest_data"]>,
	/** fetch data from the table: "voyce.countries" */
	voyce_countries: Array<GraphQLTypes["voyce_countries"]>,
	/** fetch aggregated fields from the table: "voyce.countries" */
	voyce_countries_aggregate: GraphQLTypes["voyce_countries_aggregate"],
	/** fetch data from the table: "voyce.countries" using primary key columns */
	voyce_countries_by_pk?: GraphQLTypes["voyce_countries"],
	/** fetch data from the table in a streaming manner: "voyce.countries" */
	voyce_countries_stream: Array<GraphQLTypes["voyce_countries"]>,
	/** fetch data from the table: "voyce.csat_ratings" */
	voyce_csat_ratings: Array<GraphQLTypes["voyce_csat_ratings"]>,
	/** fetch aggregated fields from the table: "voyce.csat_ratings" */
	voyce_csat_ratings_aggregate: GraphQLTypes["voyce_csat_ratings_aggregate"],
	/** fetch data from the table: "voyce.csat_ratings" using primary key columns */
	voyce_csat_ratings_by_pk?: GraphQLTypes["voyce_csat_ratings"],
	/** fetch data from the table: "voyce.csat_ratings_data" */
	voyce_csat_ratings_data: Array<GraphQLTypes["voyce_csat_ratings_data"]>,
	/** fetch aggregated fields from the table: "voyce.csat_ratings_data" */
	voyce_csat_ratings_data_aggregate: GraphQLTypes["voyce_csat_ratings_data_aggregate"],
	/** fetch data from the table: "voyce.csat_ratings_data" using primary key columns */
	voyce_csat_ratings_data_by_pk?: GraphQLTypes["voyce_csat_ratings_data"],
	/** fetch data from the table in a streaming manner: "voyce.csat_ratings_data" */
	voyce_csat_ratings_data_stream: Array<GraphQLTypes["voyce_csat_ratings_data"]>,
	/** fetch data from the table in a streaming manner: "voyce.csat_ratings" */
	voyce_csat_ratings_stream: Array<GraphQLTypes["voyce_csat_ratings"]>,
	/** fetch data from the table: "voyce.education_types" */
	voyce_education_types: Array<GraphQLTypes["voyce_education_types"]>,
	/** fetch aggregated fields from the table: "voyce.education_types" */
	voyce_education_types_aggregate: GraphQLTypes["voyce_education_types_aggregate"],
	/** fetch data from the table: "voyce.education_types" using primary key columns */
	voyce_education_types_by_pk?: GraphQLTypes["voyce_education_types"],
	/** fetch data from the table in a streaming manner: "voyce.education_types" */
	voyce_education_types_stream: Array<GraphQLTypes["voyce_education_types"]>,
	/** fetch data from the table: "voyce.erp_login_roles" */
	voyce_erp_login_roles: Array<GraphQLTypes["voyce_erp_login_roles"]>,
	/** fetch aggregated fields from the table: "voyce.erp_login_roles" */
	voyce_erp_login_roles_aggregate: GraphQLTypes["voyce_erp_login_roles_aggregate"],
	/** fetch data from the table: "voyce.erp_login_roles" using primary key columns */
	voyce_erp_login_roles_by_pk?: GraphQLTypes["voyce_erp_login_roles"],
	/** fetch data from the table in a streaming manner: "voyce.erp_login_roles" */
	voyce_erp_login_roles_stream: Array<GraphQLTypes["voyce_erp_login_roles"]>,
	/** fetch data from the table: "voyce.etl_series_views_logs" */
	voyce_etl_series_views_logs: Array<GraphQLTypes["voyce_etl_series_views_logs"]>,
	/** fetch aggregated fields from the table: "voyce.etl_series_views_logs" */
	voyce_etl_series_views_logs_aggregate: GraphQLTypes["voyce_etl_series_views_logs_aggregate"],
	/** fetch data from the table: "voyce.etl_series_views_logs" using primary key columns */
	voyce_etl_series_views_logs_by_pk?: GraphQLTypes["voyce_etl_series_views_logs"],
	/** fetch data from the table in a streaming manner: "voyce.etl_series_views_logs" */
	voyce_etl_series_views_logs_stream: Array<GraphQLTypes["voyce_etl_series_views_logs"]>,
	/** fetch data from the table: "voyce.event_schedules" */
	voyce_event_schedules: Array<GraphQLTypes["voyce_event_schedules"]>,
	/** fetch aggregated fields from the table: "voyce.event_schedules" */
	voyce_event_schedules_aggregate: GraphQLTypes["voyce_event_schedules_aggregate"],
	/** fetch data from the table: "voyce.event_schedules" using primary key columns */
	voyce_event_schedules_by_pk?: GraphQLTypes["voyce_event_schedules"],
	/** fetch data from the table in a streaming manner: "voyce.event_schedules" */
	voyce_event_schedules_stream: Array<GraphQLTypes["voyce_event_schedules"]>,
	/** fetch data from the table: "voyce.genres" */
	voyce_genres: Array<GraphQLTypes["voyce_genres"]>,
	/** fetch aggregated fields from the table: "voyce.genres" */
	voyce_genres_aggregate: GraphQLTypes["voyce_genres_aggregate"],
	/** fetch data from the table: "voyce.genres" using primary key columns */
	voyce_genres_by_pk?: GraphQLTypes["voyce_genres"],
	/** fetch data from the table in a streaming manner: "voyce.genres" */
	voyce_genres_stream: Array<GraphQLTypes["voyce_genres"]>,
	/** fetch data from the table: "voyce.guest_gtags" */
	voyce_guest_gtags: Array<GraphQLTypes["voyce_guest_gtags"]>,
	/** fetch aggregated fields from the table: "voyce.guest_gtags" */
	voyce_guest_gtags_aggregate: GraphQLTypes["voyce_guest_gtags_aggregate"],
	/** fetch data from the table: "voyce.guest_gtags" using primary key columns */
	voyce_guest_gtags_by_pk?: GraphQLTypes["voyce_guest_gtags"],
	/** fetch data from the table in a streaming manner: "voyce.guest_gtags" */
	voyce_guest_gtags_stream: Array<GraphQLTypes["voyce_guest_gtags"]>,
	/** fetch data from the table: "voyce.metabases" */
	voyce_metabases: Array<GraphQLTypes["voyce_metabases"]>,
	/** fetch data from the table: "voyce.metabases_access" */
	voyce_metabases_access: Array<GraphQLTypes["voyce_metabases_access"]>,
	/** fetch aggregated fields from the table: "voyce.metabases_access" */
	voyce_metabases_access_aggregate: GraphQLTypes["voyce_metabases_access_aggregate"],
	/** fetch data from the table: "voyce.metabases_access" using primary key columns */
	voyce_metabases_access_by_pk?: GraphQLTypes["voyce_metabases_access"],
	/** fetch data from the table in a streaming manner: "voyce.metabases_access" */
	voyce_metabases_access_stream: Array<GraphQLTypes["voyce_metabases_access"]>,
	/** fetch aggregated fields from the table: "voyce.metabases" */
	voyce_metabases_aggregate: GraphQLTypes["voyce_metabases_aggregate"],
	/** fetch data from the table: "voyce.metabases" using primary key columns */
	voyce_metabases_by_pk?: GraphQLTypes["voyce_metabases"],
	/** fetch data from the table in a streaming manner: "voyce.metabases" */
	voyce_metabases_stream: Array<GraphQLTypes["voyce_metabases"]>,
	/** fetch data from the table: "voyce.newsletter" */
	voyce_newsletter: Array<GraphQLTypes["voyce_newsletter"]>,
	/** fetch aggregated fields from the table: "voyce.newsletter" */
	voyce_newsletter_aggregate: GraphQLTypes["voyce_newsletter_aggregate"],
	/** fetch data from the table: "voyce.newsletter" using primary key columns */
	voyce_newsletter_by_pk?: GraphQLTypes["voyce_newsletter"],
	/** fetch data from the table in a streaming manner: "voyce.newsletter" */
	voyce_newsletter_stream: Array<GraphQLTypes["voyce_newsletter"]>,
	/** fetch data from the table: "voyce.notification_series_settings" */
	voyce_notification_series_settings: Array<GraphQLTypes["voyce_notification_series_settings"]>,
	/** fetch aggregated fields from the table: "voyce.notification_series_settings" */
	voyce_notification_series_settings_aggregate: GraphQLTypes["voyce_notification_series_settings_aggregate"],
	/** fetch data from the table: "voyce.notification_series_settings" using primary key columns */
	voyce_notification_series_settings_by_pk?: GraphQLTypes["voyce_notification_series_settings"],
	/** fetch data from the table in a streaming manner: "voyce.notification_series_settings" */
	voyce_notification_series_settings_stream: Array<GraphQLTypes["voyce_notification_series_settings"]>,
	/** fetch data from the table: "voyce.notification_settings" */
	voyce_notification_settings: Array<GraphQLTypes["voyce_notification_settings"]>,
	/** fetch aggregated fields from the table: "voyce.notification_settings" */
	voyce_notification_settings_aggregate: GraphQLTypes["voyce_notification_settings_aggregate"],
	/** fetch data from the table: "voyce.notification_settings" using primary key columns */
	voyce_notification_settings_by_pk?: GraphQLTypes["voyce_notification_settings"],
	/** fetch data from the table in a streaming manner: "voyce.notification_settings" */
	voyce_notification_settings_stream: Array<GraphQLTypes["voyce_notification_settings"]>,
	/** fetch data from the table: "voyce.notifications" */
	voyce_notifications: Array<GraphQLTypes["voyce_notifications"]>,
	/** fetch aggregated fields from the table: "voyce.notifications" */
	voyce_notifications_aggregate: GraphQLTypes["voyce_notifications_aggregate"],
	/** fetch data from the table: "voyce.notifications" using primary key columns */
	voyce_notifications_by_pk?: GraphQLTypes["voyce_notifications"],
	/** fetch data from the table in a streaming manner: "voyce.notifications" */
	voyce_notifications_stream: Array<GraphQLTypes["voyce_notifications"]>,
	/** fetch data from the table: "voyce.originals_feed" */
	voyce_originals_feed: Array<GraphQLTypes["voyce_originals_feed"]>,
	/** fetch aggregated fields from the table: "voyce.originals_feed" */
	voyce_originals_feed_aggregate: GraphQLTypes["voyce_originals_feed_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.originals_feed" */
	voyce_originals_feed_stream: Array<GraphQLTypes["voyce_originals_feed"]>,
	/** fetch data from the table: "voyce.page_banners" */
	voyce_page_banners: Array<GraphQLTypes["voyce_page_banners"]>,
	/** fetch aggregated fields from the table: "voyce.page_banners" */
	voyce_page_banners_aggregate: GraphQLTypes["voyce_page_banners_aggregate"],
	/** fetch data from the table: "voyce.page_banners" using primary key columns */
	voyce_page_banners_by_pk?: GraphQLTypes["voyce_page_banners"],
	/** fetch data from the table in a streaming manner: "voyce.page_banners" */
	voyce_page_banners_stream: Array<GraphQLTypes["voyce_page_banners"]>,
	/** fetch data from the table: "voyce.page_components" */
	voyce_page_components: Array<GraphQLTypes["voyce_page_components"]>,
	/** fetch aggregated fields from the table: "voyce.page_components" */
	voyce_page_components_aggregate: GraphQLTypes["voyce_page_components_aggregate"],
	/** fetch data from the table: "voyce.page_components" using primary key columns */
	voyce_page_components_by_pk?: GraphQLTypes["voyce_page_components"],
	/** fetch data from the table in a streaming manner: "voyce.page_components" */
	voyce_page_components_stream: Array<GraphQLTypes["voyce_page_components"]>,
	/** fetch data from the table: "voyce.pages" */
	voyce_pages: Array<GraphQLTypes["voyce_pages"]>,
	/** fetch aggregated fields from the table: "voyce.pages" */
	voyce_pages_aggregate: GraphQLTypes["voyce_pages_aggregate"],
	/** fetch data from the table: "voyce.pages" using primary key columns */
	voyce_pages_by_pk?: GraphQLTypes["voyce_pages"],
	/** fetch data from the table in a streaming manner: "voyce.pages" */
	voyce_pages_stream: Array<GraphQLTypes["voyce_pages"]>,
	/** fetch data from the table: "voyce.panels_comments" */
	voyce_panels_comments: Array<GraphQLTypes["voyce_panels_comments"]>,
	/** fetch aggregated fields from the table: "voyce.panels_comments" */
	voyce_panels_comments_aggregate: GraphQLTypes["voyce_panels_comments_aggregate"],
	/** fetch data from the table: "voyce.panels_comments" using primary key columns */
	voyce_panels_comments_by_pk?: GraphQLTypes["voyce_panels_comments"],
	/** fetch data from the table in a streaming manner: "voyce.panels_comments" */
	voyce_panels_comments_stream: Array<GraphQLTypes["voyce_panels_comments"]>,
	/** fetch data from the table: "voyce.panels_reactions" */
	voyce_panels_reactions: Array<GraphQLTypes["voyce_panels_reactions"]>,
	/** fetch aggregated fields from the table: "voyce.panels_reactions" */
	voyce_panels_reactions_aggregate: GraphQLTypes["voyce_panels_reactions_aggregate"],
	/** fetch data from the table: "voyce.panels_reactions" using primary key columns */
	voyce_panels_reactions_by_pk?: GraphQLTypes["voyce_panels_reactions"],
	/** fetch data from the table: "voyce.panels_reactions_count" */
	voyce_panels_reactions_count: Array<GraphQLTypes["voyce_panels_reactions_count"]>,
	/** fetch aggregated fields from the table: "voyce.panels_reactions_count" */
	voyce_panels_reactions_count_aggregate: GraphQLTypes["voyce_panels_reactions_count_aggregate"],
	/** fetch data from the table: "voyce.panels_reactions_count" using primary key columns */
	voyce_panels_reactions_count_by_pk?: GraphQLTypes["voyce_panels_reactions_count"],
	/** fetch data from the table in a streaming manner: "voyce.panels_reactions_count" */
	voyce_panels_reactions_count_stream: Array<GraphQLTypes["voyce_panels_reactions_count"]>,
	/** fetch data from the table in a streaming manner: "voyce.panels_reactions" */
	voyce_panels_reactions_stream: Array<GraphQLTypes["voyce_panels_reactions"]>,
	/** fetch data from the table: "voyce.payments_to_creators" */
	voyce_payments_to_creators: Array<GraphQLTypes["voyce_payments_to_creators"]>,
	/** fetch aggregated fields from the table: "voyce.payments_to_creators" */
	voyce_payments_to_creators_aggregate: GraphQLTypes["voyce_payments_to_creators_aggregate"],
	/** fetch data from the table: "voyce.payments_to_creators" using primary key columns */
	voyce_payments_to_creators_by_pk?: GraphQLTypes["voyce_payments_to_creators"],
	/** fetch data from the table in a streaming manner: "voyce.payments_to_creators" */
	voyce_payments_to_creators_stream: Array<GraphQLTypes["voyce_payments_to_creators"]>,
	/** fetch data from the table: "voyce.pg_rs_series_views_count" */
	voyce_pg_rs_series_views_count: Array<GraphQLTypes["voyce_pg_rs_series_views_count"]>,
	/** fetch aggregated fields from the table: "voyce.pg_rs_series_views_count" */
	voyce_pg_rs_series_views_count_aggregate: GraphQLTypes["voyce_pg_rs_series_views_count_aggregate"],
	/** fetch data from the table: "voyce.pg_rs_series_views_count" using primary key columns */
	voyce_pg_rs_series_views_count_by_pk?: GraphQLTypes["voyce_pg_rs_series_views_count"],
	/** fetch data from the table in a streaming manner: "voyce.pg_rs_series_views_count" */
	voyce_pg_rs_series_views_count_stream: Array<GraphQLTypes["voyce_pg_rs_series_views_count"]>,
	/** fetch data from the table: "voyce.polls" */
	voyce_polls: Array<GraphQLTypes["voyce_polls"]>,
	/** fetch aggregated fields from the table: "voyce.polls" */
	voyce_polls_aggregate: GraphQLTypes["voyce_polls_aggregate"],
	/** fetch data from the table: "voyce.polls_answers" */
	voyce_polls_answers: Array<GraphQLTypes["voyce_polls_answers"]>,
	/** fetch aggregated fields from the table: "voyce.polls_answers" */
	voyce_polls_answers_aggregate: GraphQLTypes["voyce_polls_answers_aggregate"],
	/** fetch data from the table: "voyce.polls_answers" using primary key columns */
	voyce_polls_answers_by_pk?: GraphQLTypes["voyce_polls_answers"],
	/** fetch data from the table in a streaming manner: "voyce.polls_answers" */
	voyce_polls_answers_stream: Array<GraphQLTypes["voyce_polls_answers"]>,
	/** fetch data from the table: "voyce.polls" using primary key columns */
	voyce_polls_by_pk?: GraphQLTypes["voyce_polls"],
	/** fetch data from the table: "voyce.polls_comments" */
	voyce_polls_comments: Array<GraphQLTypes["voyce_polls_comments"]>,
	/** fetch aggregated fields from the table: "voyce.polls_comments" */
	voyce_polls_comments_aggregate: GraphQLTypes["voyce_polls_comments_aggregate"],
	/** fetch data from the table: "voyce.polls_comments" using primary key columns */
	voyce_polls_comments_by_pk?: GraphQLTypes["voyce_polls_comments"],
	/** fetch data from the table in a streaming manner: "voyce.polls_comments" */
	voyce_polls_comments_stream: Array<GraphQLTypes["voyce_polls_comments"]>,
	/** fetch data from the table: "voyce.polls_reactions" */
	voyce_polls_reactions: Array<GraphQLTypes["voyce_polls_reactions"]>,
	/** fetch aggregated fields from the table: "voyce.polls_reactions" */
	voyce_polls_reactions_aggregate: GraphQLTypes["voyce_polls_reactions_aggregate"],
	/** fetch data from the table: "voyce.polls_reactions" using primary key columns */
	voyce_polls_reactions_by_pk?: GraphQLTypes["voyce_polls_reactions"],
	/** fetch data from the table in a streaming manner: "voyce.polls_reactions" */
	voyce_polls_reactions_stream: Array<GraphQLTypes["voyce_polls_reactions"]>,
	/** fetch data from the table in a streaming manner: "voyce.polls" */
	voyce_polls_stream: Array<GraphQLTypes["voyce_polls"]>,
	/** fetch data from the table: "voyce.polls_votes" */
	voyce_polls_votes: Array<GraphQLTypes["voyce_polls_votes"]>,
	/** fetch aggregated fields from the table: "voyce.polls_votes" */
	voyce_polls_votes_aggregate: GraphQLTypes["voyce_polls_votes_aggregate"],
	/** fetch data from the table: "voyce.polls_votes" using primary key columns */
	voyce_polls_votes_by_pk?: GraphQLTypes["voyce_polls_votes"],
	/** fetch data from the table in a streaming manner: "voyce.polls_votes" */
	voyce_polls_votes_stream: Array<GraphQLTypes["voyce_polls_votes"]>,
	/** fetch data from the table: "voyce.popup_components" */
	voyce_popup_components: Array<GraphQLTypes["voyce_popup_components"]>,
	/** fetch aggregated fields from the table: "voyce.popup_components" */
	voyce_popup_components_aggregate: GraphQLTypes["voyce_popup_components_aggregate"],
	/** fetch data from the table: "voyce.popup_components" using primary key columns */
	voyce_popup_components_by_pk?: GraphQLTypes["voyce_popup_components"],
	/** fetch data from the table in a streaming manner: "voyce.popup_components" */
	voyce_popup_components_stream: Array<GraphQLTypes["voyce_popup_components"]>,
	/** fetch data from the table: "voyce.popup_emails" */
	voyce_popup_emails: Array<GraphQLTypes["voyce_popup_emails"]>,
	/** fetch aggregated fields from the table: "voyce.popup_emails" */
	voyce_popup_emails_aggregate: GraphQLTypes["voyce_popup_emails_aggregate"],
	/** fetch data from the table: "voyce.popup_emails" using primary key columns */
	voyce_popup_emails_by_pk?: GraphQLTypes["voyce_popup_emails"],
	/** fetch data from the table in a streaming manner: "voyce.popup_emails" */
	voyce_popup_emails_stream: Array<GraphQLTypes["voyce_popup_emails"]>,
	/** fetch data from the table: "voyce.posts" */
	voyce_posts: Array<GraphQLTypes["voyce_posts"]>,
	/** fetch aggregated fields from the table: "voyce.posts" */
	voyce_posts_aggregate: GraphQLTypes["voyce_posts_aggregate"],
	/** fetch data from the table: "voyce.posts" using primary key columns */
	voyce_posts_by_pk?: GraphQLTypes["voyce_posts"],
	/** fetch data from the table: "voyce.posts_comments" */
	voyce_posts_comments: Array<GraphQLTypes["voyce_posts_comments"]>,
	/** fetch aggregated fields from the table: "voyce.posts_comments" */
	voyce_posts_comments_aggregate: GraphQLTypes["voyce_posts_comments_aggregate"],
	/** fetch data from the table: "voyce.posts_comments" using primary key columns */
	voyce_posts_comments_by_pk?: GraphQLTypes["voyce_posts_comments"],
	/** fetch data from the table in a streaming manner: "voyce.posts_comments" */
	voyce_posts_comments_stream: Array<GraphQLTypes["voyce_posts_comments"]>,
	/** fetch data from the table: "voyce.posts_reactions" */
	voyce_posts_reactions: Array<GraphQLTypes["voyce_posts_reactions"]>,
	/** fetch aggregated fields from the table: "voyce.posts_reactions" */
	voyce_posts_reactions_aggregate: GraphQLTypes["voyce_posts_reactions_aggregate"],
	/** fetch data from the table: "voyce.posts_reactions" using primary key columns */
	voyce_posts_reactions_by_pk?: GraphQLTypes["voyce_posts_reactions"],
	/** fetch data from the table in a streaming manner: "voyce.posts_reactions" */
	voyce_posts_reactions_stream: Array<GraphQLTypes["voyce_posts_reactions"]>,
	/** fetch data from the table in a streaming manner: "voyce.posts" */
	voyce_posts_stream: Array<GraphQLTypes["voyce_posts"]>,
	/** fetch data from the table: "voyce.quest_difficulties" */
	voyce_quest_difficulties: Array<GraphQLTypes["voyce_quest_difficulties"]>,
	/** fetch aggregated fields from the table: "voyce.quest_difficulties" */
	voyce_quest_difficulties_aggregate: GraphQLTypes["voyce_quest_difficulties_aggregate"],
	/** fetch data from the table: "voyce.quest_difficulties" using primary key columns */
	voyce_quest_difficulties_by_pk?: GraphQLTypes["voyce_quest_difficulties"],
	/** fetch data from the table in a streaming manner: "voyce.quest_difficulties" */
	voyce_quest_difficulties_stream: Array<GraphQLTypes["voyce_quest_difficulties"]>,
	/** fetch data from the table: "voyce.quests" */
	voyce_quests: Array<GraphQLTypes["voyce_quests"]>,
	/** fetch aggregated fields from the table: "voyce.quests" */
	voyce_quests_aggregate: GraphQLTypes["voyce_quests_aggregate"],
	/** fetch data from the table: "voyce.quests" using primary key columns */
	voyce_quests_by_pk?: GraphQLTypes["voyce_quests"],
	/** fetch data from the table: "voyce.quests_old" */
	voyce_quests_old: Array<GraphQLTypes["voyce_quests_old"]>,
	/** fetch aggregated fields from the table: "voyce.quests_old" */
	voyce_quests_old_aggregate: GraphQLTypes["voyce_quests_old_aggregate"],
	/** fetch data from the table: "voyce.quests_old" using primary key columns */
	voyce_quests_old_by_pk?: GraphQLTypes["voyce_quests_old"],
	/** fetch data from the table in a streaming manner: "voyce.quests_old" */
	voyce_quests_old_stream: Array<GraphQLTypes["voyce_quests_old"]>,
	/** fetch data from the table in a streaming manner: "voyce.quests" */
	voyce_quests_stream: Array<GraphQLTypes["voyce_quests"]>,
	/** fetch data from the table: "voyce.recommended_series" */
	voyce_recommended_series: Array<GraphQLTypes["voyce_recommended_series"]>,
	/** fetch aggregated fields from the table: "voyce.recommended_series" */
	voyce_recommended_series_aggregate: GraphQLTypes["voyce_recommended_series_aggregate"],
	/** fetch data from the table: "voyce.recommended_series" using primary key columns */
	voyce_recommended_series_by_pk?: GraphQLTypes["voyce_recommended_series"],
	/** fetch data from the table in a streaming manner: "voyce.recommended_series" */
	voyce_recommended_series_stream: Array<GraphQLTypes["voyce_recommended_series"]>,
	/** fetch data from the table: "voyce.release_schedules" */
	voyce_release_schedules: Array<GraphQLTypes["voyce_release_schedules"]>,
	/** fetch aggregated fields from the table: "voyce.release_schedules" */
	voyce_release_schedules_aggregate: GraphQLTypes["voyce_release_schedules_aggregate"],
	/** fetch data from the table: "voyce.release_schedules" using primary key columns */
	voyce_release_schedules_by_pk?: GraphQLTypes["voyce_release_schedules"],
	/** fetch data from the table in a streaming manner: "voyce.release_schedules" */
	voyce_release_schedules_stream: Array<GraphQLTypes["voyce_release_schedules"]>,
	/** fetch data from the table: "voyce.rewards" */
	voyce_rewards: Array<GraphQLTypes["voyce_rewards"]>,
	/** fetch aggregated fields from the table: "voyce.rewards" */
	voyce_rewards_aggregate: GraphQLTypes["voyce_rewards_aggregate"],
	/** fetch data from the table: "voyce.rewards" using primary key columns */
	voyce_rewards_by_pk?: GraphQLTypes["voyce_rewards"],
	/** fetch data from the table: "voyce.rewards_leaderboard" */
	voyce_rewards_leaderboard: Array<GraphQLTypes["voyce_rewards_leaderboard"]>,
	/** fetch aggregated fields from the table: "voyce.rewards_leaderboard" */
	voyce_rewards_leaderboard_aggregate: GraphQLTypes["voyce_rewards_leaderboard_aggregate"],
	/** fetch data from the table: "voyce.rewards_leaderboard" using primary key columns */
	voyce_rewards_leaderboard_by_pk?: GraphQLTypes["voyce_rewards_leaderboard"],
	/** fetch data from the table in a streaming manner: "voyce.rewards_leaderboard" */
	voyce_rewards_leaderboard_stream: Array<GraphQLTypes["voyce_rewards_leaderboard"]>,
	/** fetch data from the table: "voyce.rewards_quest" */
	voyce_rewards_quest: Array<GraphQLTypes["voyce_rewards_quest"]>,
	/** fetch aggregated fields from the table: "voyce.rewards_quest" */
	voyce_rewards_quest_aggregate: GraphQLTypes["voyce_rewards_quest_aggregate"],
	/** fetch data from the table: "voyce.rewards_quest" using primary key columns */
	voyce_rewards_quest_by_pk?: GraphQLTypes["voyce_rewards_quest"],
	/** fetch data from the table in a streaming manner: "voyce.rewards_quest" */
	voyce_rewards_quest_stream: Array<GraphQLTypes["voyce_rewards_quest"]>,
	/** fetch data from the table in a streaming manner: "voyce.rewards" */
	voyce_rewards_stream: Array<GraphQLTypes["voyce_rewards"]>,
	/** fetch data from the table: "voyce.sections" */
	voyce_sections: Array<GraphQLTypes["voyce_sections"]>,
	/** fetch aggregated fields from the table: "voyce.sections" */
	voyce_sections_aggregate: GraphQLTypes["voyce_sections_aggregate"],
	/** fetch data from the table: "voyce.sections" using primary key columns */
	voyce_sections_by_pk?: GraphQLTypes["voyce_sections"],
	/** fetch data from the table in a streaming manner: "voyce.sections" */
	voyce_sections_stream: Array<GraphQLTypes["voyce_sections"]>,
	/** fetch data from the table: "voyce.series" */
	voyce_series: Array<GraphQLTypes["voyce_series"]>,
	/** fetch aggregated fields from the table: "voyce.series" */
	voyce_series_aggregate: GraphQLTypes["voyce_series_aggregate"],
	/** fetch data from the table: "voyce.series" using primary key columns */
	voyce_series_by_pk?: GraphQLTypes["voyce_series"],
	/** fetch data from the table: "voyce.series_collaborators" */
	voyce_series_collaborators: Array<GraphQLTypes["voyce_series_collaborators"]>,
	/** fetch aggregated fields from the table: "voyce.series_collaborators" */
	voyce_series_collaborators_aggregate: GraphQLTypes["voyce_series_collaborators_aggregate"],
	/** fetch data from the table: "voyce.series_collaborators" using primary key columns */
	voyce_series_collaborators_by_pk?: GraphQLTypes["voyce_series_collaborators"],
	/** fetch data from the table in a streaming manner: "voyce.series_collaborators" */
	voyce_series_collaborators_stream: Array<GraphQLTypes["voyce_series_collaborators"]>,
	/** fetch data from the table: "voyce.series_donations" */
	voyce_series_donations: Array<GraphQLTypes["voyce_series_donations"]>,
	/** fetch aggregated fields from the table: "voyce.series_donations" */
	voyce_series_donations_aggregate: GraphQLTypes["voyce_series_donations_aggregate"],
	/** fetch data from the table: "voyce.series_donations" using primary key columns */
	voyce_series_donations_by_pk?: GraphQLTypes["voyce_series_donations"],
	/** fetch data from the table in a streaming manner: "voyce.series_donations" */
	voyce_series_donations_stream: Array<GraphQLTypes["voyce_series_donations"]>,
	/** fetch data from the table: "voyce.series_favorites" */
	voyce_series_favorites: Array<GraphQLTypes["voyce_series_favorites"]>,
	/** fetch aggregated fields from the table: "voyce.series_favorites" */
	voyce_series_favorites_aggregate: GraphQLTypes["voyce_series_favorites_aggregate"],
	/** fetch data from the table: "voyce.series_favorites" using primary key columns */
	voyce_series_favorites_by_pk?: GraphQLTypes["voyce_series_favorites"],
	/** fetch data from the table in a streaming manner: "voyce.series_favorites" */
	voyce_series_favorites_stream: Array<GraphQLTypes["voyce_series_favorites"]>,
	/** fetch data from the table: "voyce.series_featured_panels" */
	voyce_series_featured_panels: Array<GraphQLTypes["voyce_series_featured_panels"]>,
	/** fetch aggregated fields from the table: "voyce.series_featured_panels" */
	voyce_series_featured_panels_aggregate: GraphQLTypes["voyce_series_featured_panels_aggregate"],
	/** fetch data from the table: "voyce.series_featured_panels" using primary key columns */
	voyce_series_featured_panels_by_pk?: GraphQLTypes["voyce_series_featured_panels"],
	/** fetch data from the table in a streaming manner: "voyce.series_featured_panels" */
	voyce_series_featured_panels_stream: Array<GraphQLTypes["voyce_series_featured_panels"]>,
	/** fetch data from the table: "voyce.series_genre" */
	voyce_series_genre: Array<GraphQLTypes["voyce_series_genre"]>,
	/** fetch aggregated fields from the table: "voyce.series_genre" */
	voyce_series_genre_aggregate: GraphQLTypes["voyce_series_genre_aggregate"],
	/** fetch data from the table: "voyce.series_genre" using primary key columns */
	voyce_series_genre_by_pk?: GraphQLTypes["voyce_series_genre"],
	/** fetch data from the table in a streaming manner: "voyce.series_genre" */
	voyce_series_genre_stream: Array<GraphQLTypes["voyce_series_genre"]>,
	/** fetch data from the table: "voyce.series_giveaway_participants" */
	voyce_series_giveaway_participants: Array<GraphQLTypes["voyce_series_giveaway_participants"]>,
	/** fetch aggregated fields from the table: "voyce.series_giveaway_participants" */
	voyce_series_giveaway_participants_aggregate: GraphQLTypes["voyce_series_giveaway_participants_aggregate"],
	/** fetch data from the table: "voyce.series_giveaway_participants" using primary key columns */
	voyce_series_giveaway_participants_by_pk?: GraphQLTypes["voyce_series_giveaway_participants"],
	/** fetch data from the table in a streaming manner: "voyce.series_giveaway_participants" */
	voyce_series_giveaway_participants_stream: Array<GraphQLTypes["voyce_series_giveaway_participants"]>,
	/** fetch data from the table: "voyce.series_hot_panels" */
	voyce_series_hot_panels: Array<GraphQLTypes["voyce_series_hot_panels"]>,
	/** fetch aggregated fields from the table: "voyce.series_hot_panels" */
	voyce_series_hot_panels_aggregate: GraphQLTypes["voyce_series_hot_panels_aggregate"],
	/** fetch data from the table: "voyce.series_hot_panels" using primary key columns */
	voyce_series_hot_panels_by_pk?: GraphQLTypes["voyce_series_hot_panels"],
	/** fetch data from the table in a streaming manner: "voyce.series_hot_panels" */
	voyce_series_hot_panels_stream: Array<GraphQLTypes["voyce_series_hot_panels"]>,
	/** fetch data from the table: "voyce.series_layouts" */
	voyce_series_layouts: Array<GraphQLTypes["voyce_series_layouts"]>,
	/** fetch aggregated fields from the table: "voyce.series_layouts" */
	voyce_series_layouts_aggregate: GraphQLTypes["voyce_series_layouts_aggregate"],
	/** fetch data from the table: "voyce.series_layouts" using primary key columns */
	voyce_series_layouts_by_pk?: GraphQLTypes["voyce_series_layouts"],
	/** fetch data from the table in a streaming manner: "voyce.series_layouts" */
	voyce_series_layouts_stream: Array<GraphQLTypes["voyce_series_layouts"]>,
	/** fetch data from the table: "voyce.series_leaderboard" */
	voyce_series_leaderboard: Array<GraphQLTypes["voyce_series_leaderboard"]>,
	/** fetch aggregated fields from the table: "voyce.series_leaderboard" */
	voyce_series_leaderboard_aggregate: GraphQLTypes["voyce_series_leaderboard_aggregate"],
	/** fetch data from the table: "voyce.series_leaderboard" using primary key columns */
	voyce_series_leaderboard_by_pk?: GraphQLTypes["voyce_series_leaderboard"],
	/** fetch data from the table in a streaming manner: "voyce.series_leaderboard" */
	voyce_series_leaderboard_stream: Array<GraphQLTypes["voyce_series_leaderboard"]>,
	/** fetch data from the table: "voyce.series_likes_count" */
	voyce_series_likes_count: Array<GraphQLTypes["voyce_series_likes_count"]>,
	/** fetch aggregated fields from the table: "voyce.series_likes_count" */
	voyce_series_likes_count_aggregate: GraphQLTypes["voyce_series_likes_count_aggregate"],
	/** fetch data from the table: "voyce.series_likes_count_mat" */
	voyce_series_likes_count_mat: Array<GraphQLTypes["voyce_series_likes_count_mat"]>,
	/** fetch aggregated fields from the table: "voyce.series_likes_count_mat" */
	voyce_series_likes_count_mat_aggregate: GraphQLTypes["voyce_series_likes_count_mat_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.series_likes_count_mat" */
	voyce_series_likes_count_mat_stream: Array<GraphQLTypes["voyce_series_likes_count_mat"]>,
	/** fetch data from the table in a streaming manner: "voyce.series_likes_count" */
	voyce_series_likes_count_stream: Array<GraphQLTypes["voyce_series_likes_count"]>,
	/** fetch data from the table: "voyce.series_lock_config" */
	voyce_series_lock_config: Array<GraphQLTypes["voyce_series_lock_config"]>,
	/** fetch aggregated fields from the table: "voyce.series_lock_config" */
	voyce_series_lock_config_aggregate: GraphQLTypes["voyce_series_lock_config_aggregate"],
	/** fetch data from the table: "voyce.series_lock_config" using primary key columns */
	voyce_series_lock_config_by_pk?: GraphQLTypes["voyce_series_lock_config"],
	/** fetch data from the table in a streaming manner: "voyce.series_lock_config" */
	voyce_series_lock_config_stream: Array<GraphQLTypes["voyce_series_lock_config"]>,
	/** fetch data from the table: "voyce.series_sponsorships_configuration" */
	voyce_series_sponsorships_configuration: Array<GraphQLTypes["voyce_series_sponsorships_configuration"]>,
	/** fetch aggregated fields from the table: "voyce.series_sponsorships_configuration" */
	voyce_series_sponsorships_configuration_aggregate: GraphQLTypes["voyce_series_sponsorships_configuration_aggregate"],
	/** fetch data from the table: "voyce.series_sponsorships_configuration" using primary key columns */
	voyce_series_sponsorships_configuration_by_pk?: GraphQLTypes["voyce_series_sponsorships_configuration"],
	/** fetch data from the table in a streaming manner: "voyce.series_sponsorships_configuration" */
	voyce_series_sponsorships_configuration_stream: Array<GraphQLTypes["voyce_series_sponsorships_configuration"]>,
	/** fetch data from the table in a streaming manner: "voyce.series" */
	voyce_series_stream: Array<GraphQLTypes["voyce_series"]>,
	/** fetch data from the table: "voyce.series_subscription" */
	voyce_series_subscription: Array<GraphQLTypes["voyce_series_subscription"]>,
	/** fetch aggregated fields from the table: "voyce.series_subscription" */
	voyce_series_subscription_aggregate: GraphQLTypes["voyce_series_subscription_aggregate"],
	/** fetch data from the table: "voyce.series_subscription" using primary key columns */
	voyce_series_subscription_by_pk?: GraphQLTypes["voyce_series_subscription"],
	/** fetch data from the table in a streaming manner: "voyce.series_subscription" */
	voyce_series_subscription_stream: Array<GraphQLTypes["voyce_series_subscription"]>,
	/** fetch data from the table: "voyce.series_tags" */
	voyce_series_tags: Array<GraphQLTypes["voyce_series_tags"]>,
	/** fetch aggregated fields from the table: "voyce.series_tags" */
	voyce_series_tags_aggregate: GraphQLTypes["voyce_series_tags_aggregate"],
	/** fetch data from the table: "voyce.series_tags" using primary key columns */
	voyce_series_tags_by_pk?: GraphQLTypes["voyce_series_tags"],
	/** fetch data from the table: "voyce.series_tags_junction" */
	voyce_series_tags_junction: Array<GraphQLTypes["voyce_series_tags_junction"]>,
	/** fetch aggregated fields from the table: "voyce.series_tags_junction" */
	voyce_series_tags_junction_aggregate: GraphQLTypes["voyce_series_tags_junction_aggregate"],
	/** fetch data from the table: "voyce.series_tags_junction" using primary key columns */
	voyce_series_tags_junction_by_pk?: GraphQLTypes["voyce_series_tags_junction"],
	/** fetch data from the table in a streaming manner: "voyce.series_tags_junction" */
	voyce_series_tags_junction_stream: Array<GraphQLTypes["voyce_series_tags_junction"]>,
	/** fetch data from the table in a streaming manner: "voyce.series_tags" */
	voyce_series_tags_stream: Array<GraphQLTypes["voyce_series_tags"]>,
	/** fetch data from the table: "voyce.series_top_comments" */
	voyce_series_top_comments: Array<GraphQLTypes["voyce_series_top_comments"]>,
	/** fetch aggregated fields from the table: "voyce.series_top_comments" */
	voyce_series_top_comments_aggregate: GraphQLTypes["voyce_series_top_comments_aggregate"],
	/** fetch data from the table: "voyce.series_top_comments" using primary key columns */
	voyce_series_top_comments_by_pk?: GraphQLTypes["voyce_series_top_comments"],
	/** fetch data from the table in a streaming manner: "voyce.series_top_comments" */
	voyce_series_top_comments_stream: Array<GraphQLTypes["voyce_series_top_comments"]>,
	/** fetch data from the table: "voyce.series_top_likes" */
	voyce_series_top_likes: Array<GraphQLTypes["voyce_series_top_likes"]>,
	/** fetch aggregated fields from the table: "voyce.series_top_likes" */
	voyce_series_top_likes_aggregate: GraphQLTypes["voyce_series_top_likes_aggregate"],
	/** fetch data from the table: "voyce.series_top_likes" using primary key columns */
	voyce_series_top_likes_by_pk?: GraphQLTypes["voyce_series_top_likes"],
	/** fetch data from the table in a streaming manner: "voyce.series_top_likes" */
	voyce_series_top_likes_stream: Array<GraphQLTypes["voyce_series_top_likes"]>,
	/** fetch data from the table: "voyce.series_top_views" */
	voyce_series_top_views: Array<GraphQLTypes["voyce_series_top_views"]>,
	/** fetch aggregated fields from the table: "voyce.series_top_views" */
	voyce_series_top_views_aggregate: GraphQLTypes["voyce_series_top_views_aggregate"],
	/** fetch data from the table: "voyce.series_top_views" using primary key columns */
	voyce_series_top_views_by_pk?: GraphQLTypes["voyce_series_top_views"],
	/** fetch data from the table in a streaming manner: "voyce.series_top_views" */
	voyce_series_top_views_stream: Array<GraphQLTypes["voyce_series_top_views"]>,
	/** fetch data from the table: "voyce.series_views_adjustment" */
	voyce_series_views_adjustment: Array<GraphQLTypes["voyce_series_views_adjustment"]>,
	/** fetch aggregated fields from the table: "voyce.series_views_adjustment" */
	voyce_series_views_adjustment_aggregate: GraphQLTypes["voyce_series_views_adjustment_aggregate"],
	/** fetch data from the table: "voyce.series_views_adjustment" using primary key columns */
	voyce_series_views_adjustment_by_pk?: GraphQLTypes["voyce_series_views_adjustment"],
	/** fetch data from the table in a streaming manner: "voyce.series_views_adjustment" */
	voyce_series_views_adjustment_stream: Array<GraphQLTypes["voyce_series_views_adjustment"]>,
	/** fetch data from the table: "voyce.series_views_count" */
	voyce_series_views_count: Array<GraphQLTypes["voyce_series_views_count"]>,
	/** fetch aggregated fields from the table: "voyce.series_views_count" */
	voyce_series_views_count_aggregate: GraphQLTypes["voyce_series_views_count_aggregate"],
	/** fetch data from the table: "voyce.series_views_count_mat" */
	voyce_series_views_count_mat: Array<GraphQLTypes["voyce_series_views_count_mat"]>,
	/** fetch aggregated fields from the table: "voyce.series_views_count_mat" */
	voyce_series_views_count_mat_aggregate: GraphQLTypes["voyce_series_views_count_mat_aggregate"],
	/** fetch data from the table: "voyce.series_views_count_mat_sep30" */
	voyce_series_views_count_mat_sep30: Array<GraphQLTypes["voyce_series_views_count_mat_sep30"]>,
	/** fetch aggregated fields from the table: "voyce.series_views_count_mat_sep30" */
	voyce_series_views_count_mat_sep30_aggregate: GraphQLTypes["voyce_series_views_count_mat_sep30_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.series_views_count_mat_sep30" */
	voyce_series_views_count_mat_sep30_stream: Array<GraphQLTypes["voyce_series_views_count_mat_sep30"]>,
	/** fetch data from the table in a streaming manner: "voyce.series_views_count_mat" */
	voyce_series_views_count_mat_stream: Array<GraphQLTypes["voyce_series_views_count_mat"]>,
	/** fetch data from the table: "voyce.series_views_count_mat_v2" */
	voyce_series_views_count_mat_v2: Array<GraphQLTypes["voyce_series_views_count_mat_v2"]>,
	/** fetch aggregated fields from the table: "voyce.series_views_count_mat_v2" */
	voyce_series_views_count_mat_v2_aggregate: GraphQLTypes["voyce_series_views_count_mat_v2_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.series_views_count_mat_v2" */
	voyce_series_views_count_mat_v2_stream: Array<GraphQLTypes["voyce_series_views_count_mat_v2"]>,
	/** fetch data from the table in a streaming manner: "voyce.series_views_count" */
	voyce_series_views_count_stream: Array<GraphQLTypes["voyce_series_views_count"]>,
	/** fetch data from the table: "voyce.series_votes" */
	voyce_series_votes: Array<GraphQLTypes["voyce_series_votes"]>,
	/** fetch aggregated fields from the table: "voyce.series_votes" */
	voyce_series_votes_aggregate: GraphQLTypes["voyce_series_votes_aggregate"],
	/** fetch data from the table: "voyce.series_votes" using primary key columns */
	voyce_series_votes_by_pk?: GraphQLTypes["voyce_series_votes"],
	/** fetch data from the table: "voyce.series_votes_count" */
	voyce_series_votes_count: Array<GraphQLTypes["voyce_series_votes_count"]>,
	/** fetch aggregated fields from the table: "voyce.series_votes_count" */
	voyce_series_votes_count_aggregate: GraphQLTypes["voyce_series_votes_count_aggregate"],
	/** fetch data from the table: "voyce.series_votes_count" using primary key columns */
	voyce_series_votes_count_by_pk?: GraphQLTypes["voyce_series_votes_count"],
	/** fetch data from the table in a streaming manner: "voyce.series_votes_count" */
	voyce_series_votes_count_stream: Array<GraphQLTypes["voyce_series_votes_count"]>,
	/** fetch data from the table in a streaming manner: "voyce.series_votes" */
	voyce_series_votes_stream: Array<GraphQLTypes["voyce_series_votes"]>,
	/** fetch data from the table: "voyce.series_voting_schedules" */
	voyce_series_voting_schedules: Array<GraphQLTypes["voyce_series_voting_schedules"]>,
	/** fetch aggregated fields from the table: "voyce.series_voting_schedules" */
	voyce_series_voting_schedules_aggregate: GraphQLTypes["voyce_series_voting_schedules_aggregate"],
	/** fetch data from the table: "voyce.series_voting_schedules" using primary key columns */
	voyce_series_voting_schedules_by_pk?: GraphQLTypes["voyce_series_voting_schedules"],
	/** fetch data from the table in a streaming manner: "voyce.series_voting_schedules" */
	voyce_series_voting_schedules_stream: Array<GraphQLTypes["voyce_series_voting_schedules"]>,
	/** fetch data from the table: "voyce.sponsorships" */
	voyce_sponsorships: Array<GraphQLTypes["voyce_sponsorships"]>,
	/** fetch aggregated fields from the table: "voyce.sponsorships" */
	voyce_sponsorships_aggregate: GraphQLTypes["voyce_sponsorships_aggregate"],
	/** fetch data from the table: "voyce.sponsorships" using primary key columns */
	voyce_sponsorships_by_pk?: GraphQLTypes["voyce_sponsorships"],
	/** fetch data from the table in a streaming manner: "voyce.sponsorships" */
	voyce_sponsorships_stream: Array<GraphQLTypes["voyce_sponsorships"]>,
	/** fetch data from the table: "voyce.states" */
	voyce_states: Array<GraphQLTypes["voyce_states"]>,
	/** fetch aggregated fields from the table: "voyce.states" */
	voyce_states_aggregate: GraphQLTypes["voyce_states_aggregate"],
	/** fetch data from the table: "voyce.states" using primary key columns */
	voyce_states_by_pk?: GraphQLTypes["voyce_states"],
	/** fetch data from the table in a streaming manner: "voyce.states" */
	voyce_states_stream: Array<GraphQLTypes["voyce_states"]>,
	/** fetch data from the table: "voyce.stripe_payouts_setup" */
	voyce_stripe_payouts_setup: Array<GraphQLTypes["voyce_stripe_payouts_setup"]>,
	/** fetch aggregated fields from the table: "voyce.stripe_payouts_setup" */
	voyce_stripe_payouts_setup_aggregate: GraphQLTypes["voyce_stripe_payouts_setup_aggregate"],
	/** fetch data from the table: "voyce.stripe_payouts_setup" using primary key columns */
	voyce_stripe_payouts_setup_by_pk?: GraphQLTypes["voyce_stripe_payouts_setup"],
	/** fetch data from the table in a streaming manner: "voyce.stripe_payouts_setup" */
	voyce_stripe_payouts_setup_stream: Array<GraphQLTypes["voyce_stripe_payouts_setup"]>,
	/** fetch data from the table: "voyce.studio" */
	voyce_studio: Array<GraphQLTypes["voyce_studio"]>,
	/** fetch aggregated fields from the table: "voyce.studio" */
	voyce_studio_aggregate: GraphQLTypes["voyce_studio_aggregate"],
	/** fetch data from the table: "voyce.studio" using primary key columns */
	voyce_studio_by_pk?: GraphQLTypes["voyce_studio"],
	/** fetch data from the table: "voyce.studio_followers" */
	voyce_studio_followers: Array<GraphQLTypes["voyce_studio_followers"]>,
	/** fetch aggregated fields from the table: "voyce.studio_followers" */
	voyce_studio_followers_aggregate: GraphQLTypes["voyce_studio_followers_aggregate"],
	/** fetch data from the table: "voyce.studio_followers" using primary key columns */
	voyce_studio_followers_by_pk?: GraphQLTypes["voyce_studio_followers"],
	/** fetch data from the table in a streaming manner: "voyce.studio_followers" */
	voyce_studio_followers_stream: Array<GraphQLTypes["voyce_studio_followers"]>,
	/** fetch data from the table: "voyce.studio_series" */
	voyce_studio_series: Array<GraphQLTypes["voyce_studio_series"]>,
	/** fetch aggregated fields from the table: "voyce.studio_series" */
	voyce_studio_series_aggregate: GraphQLTypes["voyce_studio_series_aggregate"],
	/** fetch data from the table: "voyce.studio_series" using primary key columns */
	voyce_studio_series_by_pk?: GraphQLTypes["voyce_studio_series"],
	/** fetch data from the table in a streaming manner: "voyce.studio_series" */
	voyce_studio_series_stream: Array<GraphQLTypes["voyce_studio_series"]>,
	/** fetch data from the table in a streaming manner: "voyce.studio" */
	voyce_studio_stream: Array<GraphQLTypes["voyce_studio"]>,
	/** fetch data from the table: "voyce.studio_views" */
	voyce_studio_views: Array<GraphQLTypes["voyce_studio_views"]>,
	/** fetch aggregated fields from the table: "voyce.studio_views" */
	voyce_studio_views_aggregate: GraphQLTypes["voyce_studio_views_aggregate"],
	/** fetch data from the table: "voyce.studio_views" using primary key columns */
	voyce_studio_views_by_pk?: GraphQLTypes["voyce_studio_views"],
	/** fetch data from the table in a streaming manner: "voyce.studio_views" */
	voyce_studio_views_stream: Array<GraphQLTypes["voyce_studio_views"]>,
	/** fetch data from the table: "voyce.system_settings" */
	voyce_system_settings: Array<GraphQLTypes["voyce_system_settings"]>,
	/** fetch aggregated fields from the table: "voyce.system_settings" */
	voyce_system_settings_aggregate: GraphQLTypes["voyce_system_settings_aggregate"],
	/** fetch data from the table: "voyce.system_settings" using primary key columns */
	voyce_system_settings_by_pk?: GraphQLTypes["voyce_system_settings"],
	/** fetch data from the table in a streaming manner: "voyce.system_settings" */
	voyce_system_settings_stream: Array<GraphQLTypes["voyce_system_settings"]>,
	/** fetch data from the table: "voyce.top_genres" */
	voyce_top_genres: Array<GraphQLTypes["voyce_top_genres"]>,
	/** fetch aggregated fields from the table: "voyce.top_genres" */
	voyce_top_genres_aggregate: GraphQLTypes["voyce_top_genres_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.top_genres" */
	voyce_top_genres_stream: Array<GraphQLTypes["voyce_top_genres"]>,
	/** fetch data from the table: "voyce.transaction_logs" */
	voyce_transaction_logs: Array<GraphQLTypes["voyce_transaction_logs"]>,
	/** fetch aggregated fields from the table: "voyce.transaction_logs" */
	voyce_transaction_logs_aggregate: GraphQLTypes["voyce_transaction_logs_aggregate"],
	/** fetch data from the table: "voyce.transaction_logs" using primary key columns */
	voyce_transaction_logs_by_pk?: GraphQLTypes["voyce_transaction_logs"],
	/** fetch data from the table in a streaming manner: "voyce.transaction_logs" */
	voyce_transaction_logs_stream: Array<GraphQLTypes["voyce_transaction_logs"]>,
	/** fetch data from the table: "voyce.url_redirects" */
	voyce_url_redirects: Array<GraphQLTypes["voyce_url_redirects"]>,
	/** fetch aggregated fields from the table: "voyce.url_redirects" */
	voyce_url_redirects_aggregate: GraphQLTypes["voyce_url_redirects_aggregate"],
	/** fetch data from the table: "voyce.url_redirects" using primary key columns */
	voyce_url_redirects_by_pk?: GraphQLTypes["voyce_url_redirects"],
	/** fetch data from the table in a streaming manner: "voyce.url_redirects" */
	voyce_url_redirects_stream: Array<GraphQLTypes["voyce_url_redirects"]>,
	/** fetch data from the table: "voyce.user_addresses" */
	voyce_user_addresses: Array<GraphQLTypes["voyce_user_addresses"]>,
	/** fetch aggregated fields from the table: "voyce.user_addresses" */
	voyce_user_addresses_aggregate: GraphQLTypes["voyce_user_addresses_aggregate"],
	/** fetch data from the table: "voyce.user_addresses" using primary key columns */
	voyce_user_addresses_by_pk?: GraphQLTypes["voyce_user_addresses"],
	/** fetch data from the table in a streaming manner: "voyce.user_addresses" */
	voyce_user_addresses_stream: Array<GraphQLTypes["voyce_user_addresses"]>,
	/** fetch data from the table: "voyce.user_blocks" */
	voyce_user_blocks: Array<GraphQLTypes["voyce_user_blocks"]>,
	/** fetch aggregated fields from the table: "voyce.user_blocks" */
	voyce_user_blocks_aggregate: GraphQLTypes["voyce_user_blocks_aggregate"],
	/** fetch data from the table: "voyce.user_blocks" using primary key columns */
	voyce_user_blocks_by_pk?: GraphQLTypes["voyce_user_blocks"],
	/** fetch data from the table in a streaming manner: "voyce.user_blocks" */
	voyce_user_blocks_stream: Array<GraphQLTypes["voyce_user_blocks"]>,
	/** fetch data from the table: "voyce.user_chapters_unlocked" */
	voyce_user_chapters_unlocked: Array<GraphQLTypes["voyce_user_chapters_unlocked"]>,
	/** fetch aggregated fields from the table: "voyce.user_chapters_unlocked" */
	voyce_user_chapters_unlocked_aggregate: GraphQLTypes["voyce_user_chapters_unlocked_aggregate"],
	/** fetch data from the table: "voyce.user_chapters_unlocked" using primary key columns */
	voyce_user_chapters_unlocked_by_pk?: GraphQLTypes["voyce_user_chapters_unlocked"],
	/** fetch data from the table in a streaming manner: "voyce.user_chapters_unlocked" */
	voyce_user_chapters_unlocked_stream: Array<GraphQLTypes["voyce_user_chapters_unlocked"]>,
	/** fetch data from the table: "voyce.user_contacts" */
	voyce_user_contacts: Array<GraphQLTypes["voyce_user_contacts"]>,
	/** fetch aggregated fields from the table: "voyce.user_contacts" */
	voyce_user_contacts_aggregate: GraphQLTypes["voyce_user_contacts_aggregate"],
	/** fetch data from the table: "voyce.user_contacts" using primary key columns */
	voyce_user_contacts_by_pk?: GraphQLTypes["voyce_user_contacts"],
	/** fetch data from the table in a streaming manner: "voyce.user_contacts" */
	voyce_user_contacts_stream: Array<GraphQLTypes["voyce_user_contacts"]>,
	/** fetch data from the table: "voyce.user_csat_ratings" */
	voyce_user_csat_ratings: Array<GraphQLTypes["voyce_user_csat_ratings"]>,
	/** fetch aggregated fields from the table: "voyce.user_csat_ratings" */
	voyce_user_csat_ratings_aggregate: GraphQLTypes["voyce_user_csat_ratings_aggregate"],
	/** fetch data from the table: "voyce.user_csat_ratings" using primary key columns */
	voyce_user_csat_ratings_by_pk?: GraphQLTypes["voyce_user_csat_ratings"],
	/** fetch data from the table in a streaming manner: "voyce.user_csat_ratings" */
	voyce_user_csat_ratings_stream: Array<GraphQLTypes["voyce_user_csat_ratings"]>,
	/** fetch data from the table: "voyce.user_education" */
	voyce_user_education: Array<GraphQLTypes["voyce_user_education"]>,
	/** fetch aggregated fields from the table: "voyce.user_education" */
	voyce_user_education_aggregate: GraphQLTypes["voyce_user_education_aggregate"],
	/** fetch data from the table: "voyce.user_education" using primary key columns */
	voyce_user_education_by_pk?: GraphQLTypes["voyce_user_education"],
	/** fetch data from the table in a streaming manner: "voyce.user_education" */
	voyce_user_education_stream: Array<GraphQLTypes["voyce_user_education"]>,
	/** fetch data from the table: "voyce.user_level" */
	voyce_user_level: Array<GraphQLTypes["voyce_user_level"]>,
	/** fetch aggregated fields from the table: "voyce.user_level" */
	voyce_user_level_aggregate: GraphQLTypes["voyce_user_level_aggregate"],
	/** fetch data from the table: "voyce.user_level" using primary key columns */
	voyce_user_level_by_pk?: GraphQLTypes["voyce_user_level"],
	/** fetch data from the table in a streaming manner: "voyce.user_level" */
	voyce_user_level_stream: Array<GraphQLTypes["voyce_user_level"]>,
	/** fetch data from the table: "voyce.user_linked_accounts" */
	voyce_user_linked_accounts: Array<GraphQLTypes["voyce_user_linked_accounts"]>,
	/** fetch aggregated fields from the table: "voyce.user_linked_accounts" */
	voyce_user_linked_accounts_aggregate: GraphQLTypes["voyce_user_linked_accounts_aggregate"],
	/** fetch data from the table: "voyce.user_linked_accounts" using primary key columns */
	voyce_user_linked_accounts_by_pk?: GraphQLTypes["voyce_user_linked_accounts"],
	/** fetch data from the table in a streaming manner: "voyce.user_linked_accounts" */
	voyce_user_linked_accounts_stream: Array<GraphQLTypes["voyce_user_linked_accounts"]>,
	/** fetch data from the table: "voyce.user_login_history" */
	voyce_user_login_history: Array<GraphQLTypes["voyce_user_login_history"]>,
	/** fetch aggregated fields from the table: "voyce.user_login_history" */
	voyce_user_login_history_aggregate: GraphQLTypes["voyce_user_login_history_aggregate"],
	/** fetch data from the table: "voyce.user_login_history" using primary key columns */
	voyce_user_login_history_by_pk?: GraphQLTypes["voyce_user_login_history"],
	/** fetch data from the table in a streaming manner: "voyce.user_login_history" */
	voyce_user_login_history_stream: Array<GraphQLTypes["voyce_user_login_history"]>,
	/** fetch data from the table: "voyce.user_quest_ids" */
	voyce_user_quest_ids: Array<GraphQLTypes["voyce_user_quest_ids"]>,
	/** fetch aggregated fields from the table: "voyce.user_quest_ids" */
	voyce_user_quest_ids_aggregate: GraphQLTypes["voyce_user_quest_ids_aggregate"],
	/** fetch data from the table: "voyce.user_quest_ids" using primary key columns */
	voyce_user_quest_ids_by_pk?: GraphQLTypes["voyce_user_quest_ids"],
	/** fetch data from the table in a streaming manner: "voyce.user_quest_ids" */
	voyce_user_quest_ids_stream: Array<GraphQLTypes["voyce_user_quest_ids"]>,
	/** fetch data from the table: "voyce.user_quest_rewards" */
	voyce_user_quest_rewards: Array<GraphQLTypes["voyce_user_quest_rewards"]>,
	/** fetch aggregated fields from the table: "voyce.user_quest_rewards" */
	voyce_user_quest_rewards_aggregate: GraphQLTypes["voyce_user_quest_rewards_aggregate"],
	/** fetch data from the table: "voyce.user_quest_rewards" using primary key columns */
	voyce_user_quest_rewards_by_pk?: GraphQLTypes["voyce_user_quest_rewards"],
	/** fetch data from the table in a streaming manner: "voyce.user_quest_rewards" */
	voyce_user_quest_rewards_stream: Array<GraphQLTypes["voyce_user_quest_rewards"]>,
	/** fetch data from the table: "voyce.user_quests" */
	voyce_user_quests: Array<GraphQLTypes["voyce_user_quests"]>,
	/** fetch aggregated fields from the table: "voyce.user_quests" */
	voyce_user_quests_aggregate: GraphQLTypes["voyce_user_quests_aggregate"],
	/** fetch data from the table: "voyce.user_quests" using primary key columns */
	voyce_user_quests_by_pk?: GraphQLTypes["voyce_user_quests"],
	/** fetch data from the table: "voyce.user_quests_old" */
	voyce_user_quests_old: Array<GraphQLTypes["voyce_user_quests_old"]>,
	/** fetch aggregated fields from the table: "voyce.user_quests_old" */
	voyce_user_quests_old_aggregate: GraphQLTypes["voyce_user_quests_old_aggregate"],
	/** fetch data from the table: "voyce.user_quests_old" using primary key columns */
	voyce_user_quests_old_by_pk?: GraphQLTypes["voyce_user_quests_old"],
	/** fetch data from the table in a streaming manner: "voyce.user_quests_old" */
	voyce_user_quests_old_stream: Array<GraphQLTypes["voyce_user_quests_old"]>,
	/** fetch data from the table in a streaming manner: "voyce.user_quests" */
	voyce_user_quests_stream: Array<GraphQLTypes["voyce_user_quests"]>,
	/** fetch data from the table: "voyce.user_ranking" */
	voyce_user_ranking: Array<GraphQLTypes["voyce_user_ranking"]>,
	/** fetch aggregated fields from the table: "voyce.user_ranking" */
	voyce_user_ranking_aggregate: GraphQLTypes["voyce_user_ranking_aggregate"],
	/** fetch data from the table: "voyce.user_ranking" using primary key columns */
	voyce_user_ranking_by_pk?: GraphQLTypes["voyce_user_ranking"],
	/** fetch data from the table in a streaming manner: "voyce.user_ranking" */
	voyce_user_ranking_stream: Array<GraphQLTypes["voyce_user_ranking"]>,
	/** fetch data from the table: "voyce.user_reviews" */
	voyce_user_reviews: Array<GraphQLTypes["voyce_user_reviews"]>,
	/** fetch aggregated fields from the table: "voyce.user_reviews" */
	voyce_user_reviews_aggregate: GraphQLTypes["voyce_user_reviews_aggregate"],
	/** fetch data from the table: "voyce.user_reviews" using primary key columns */
	voyce_user_reviews_by_pk?: GraphQLTypes["voyce_user_reviews"],
	/** fetch data from the table in a streaming manner: "voyce.user_reviews" */
	voyce_user_reviews_stream: Array<GraphQLTypes["voyce_user_reviews"]>,
	/** fetch data from the table: "voyce.user_rewards" */
	voyce_user_rewards: Array<GraphQLTypes["voyce_user_rewards"]>,
	/** fetch aggregated fields from the table: "voyce.user_rewards" */
	voyce_user_rewards_aggregate: GraphQLTypes["voyce_user_rewards_aggregate"],
	/** fetch data from the table: "voyce.user_rewards" using primary key columns */
	voyce_user_rewards_by_pk?: GraphQLTypes["voyce_user_rewards"],
	/** fetch data from the table in a streaming manner: "voyce.user_rewards" */
	voyce_user_rewards_stream: Array<GraphQLTypes["voyce_user_rewards"]>,
	/** fetch data from the table: "voyce.user_roles" */
	voyce_user_roles: Array<GraphQLTypes["voyce_user_roles"]>,
	/** fetch aggregated fields from the table: "voyce.user_roles" */
	voyce_user_roles_aggregate: GraphQLTypes["voyce_user_roles_aggregate"],
	/** fetch data from the table: "voyce.user_roles" using primary key columns */
	voyce_user_roles_by_pk?: GraphQLTypes["voyce_user_roles"],
	/** fetch data from the table in a streaming manner: "voyce.user_roles" */
	voyce_user_roles_stream: Array<GraphQLTypes["voyce_user_roles"]>,
	/** fetch data from the table: "voyce.user_series_subscription_count" */
	voyce_user_series_subscription_count: Array<GraphQLTypes["voyce_user_series_subscription_count"]>,
	/** fetch aggregated fields from the table: "voyce.user_series_subscription_count" */
	voyce_user_series_subscription_count_aggregate: GraphQLTypes["voyce_user_series_subscription_count_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.user_series_subscription_count" */
	voyce_user_series_subscription_count_stream: Array<GraphQLTypes["voyce_user_series_subscription_count"]>,
	/** fetch data from the table: "voyce.user_settings" */
	voyce_user_settings: Array<GraphQLTypes["voyce_user_settings"]>,
	/** fetch aggregated fields from the table: "voyce.user_settings" */
	voyce_user_settings_aggregate: GraphQLTypes["voyce_user_settings_aggregate"],
	/** fetch data from the table: "voyce.user_settings" using primary key columns */
	voyce_user_settings_by_pk?: GraphQLTypes["voyce_user_settings"],
	/** fetch data from the table in a streaming manner: "voyce.user_settings" */
	voyce_user_settings_stream: Array<GraphQLTypes["voyce_user_settings"]>,
	/** fetch data from the table: "voyce.user_skills" */
	voyce_user_skills: Array<GraphQLTypes["voyce_user_skills"]>,
	/** fetch aggregated fields from the table: "voyce.user_skills" */
	voyce_user_skills_aggregate: GraphQLTypes["voyce_user_skills_aggregate"],
	/** fetch data from the table: "voyce.user_skills" using primary key columns */
	voyce_user_skills_by_pk?: GraphQLTypes["voyce_user_skills"],
	/** fetch data from the table in a streaming manner: "voyce.user_skills" */
	voyce_user_skills_stream: Array<GraphQLTypes["voyce_user_skills"]>,
	/** fetch data from the table: "voyce.user_socials" */
	voyce_user_socials: Array<GraphQLTypes["voyce_user_socials"]>,
	/** fetch aggregated fields from the table: "voyce.user_socials" */
	voyce_user_socials_aggregate: GraphQLTypes["voyce_user_socials_aggregate"],
	/** fetch data from the table: "voyce.user_socials" using primary key columns */
	voyce_user_socials_by_pk?: GraphQLTypes["voyce_user_socials"],
	/** fetch data from the table in a streaming manner: "voyce.user_socials" */
	voyce_user_socials_stream: Array<GraphQLTypes["voyce_user_socials"]>,
	/** fetch data from the table: "voyce.user_soft_deleted" */
	voyce_user_soft_deleted: Array<GraphQLTypes["voyce_user_soft_deleted"]>,
	/** fetch aggregated fields from the table: "voyce.user_soft_deleted" */
	voyce_user_soft_deleted_aggregate: GraphQLTypes["voyce_user_soft_deleted_aggregate"],
	/** fetch data from the table: "voyce.user_soft_deleted" using primary key columns */
	voyce_user_soft_deleted_by_pk?: GraphQLTypes["voyce_user_soft_deleted"],
	/** fetch data from the table in a streaming manner: "voyce.user_soft_deleted" */
	voyce_user_soft_deleted_stream: Array<GraphQLTypes["voyce_user_soft_deleted"]>,
	/** fetch data from the table: "voyce.user_vtags" */
	voyce_user_vtags: Array<GraphQLTypes["voyce_user_vtags"]>,
	/** fetch aggregated fields from the table: "voyce.user_vtags" */
	voyce_user_vtags_aggregate: GraphQLTypes["voyce_user_vtags_aggregate"],
	/** fetch data from the table: "voyce.user_vtags" using primary key columns */
	voyce_user_vtags_by_pk?: GraphQLTypes["voyce_user_vtags"],
	/** fetch data from the table in a streaming manner: "voyce.user_vtags" */
	voyce_user_vtags_stream: Array<GraphQLTypes["voyce_user_vtags"]>,
	/** fetch data from the table: "voyce.user_wins" */
	voyce_user_wins: Array<GraphQLTypes["voyce_user_wins"]>,
	/** fetch aggregated fields from the table: "voyce.user_wins" */
	voyce_user_wins_aggregate: GraphQLTypes["voyce_user_wins_aggregate"],
	/** fetch data from the table: "voyce.user_wins" using primary key columns */
	voyce_user_wins_by_pk?: GraphQLTypes["voyce_user_wins"],
	/** fetch data from the table in a streaming manner: "voyce.user_wins" */
	voyce_user_wins_stream: Array<GraphQLTypes["voyce_user_wins"]>,
	/** fetch data from the table: "voyce.users" */
	voyce_users: Array<GraphQLTypes["voyce_users"]>,
	/** fetch aggregated fields from the table: "voyce.users" */
	voyce_users_aggregate: GraphQLTypes["voyce_users_aggregate"],
	/** fetch data from the table: "voyce.users" using primary key columns */
	voyce_users_by_pk?: GraphQLTypes["voyce_users"],
	/** fetch data from the table: "voyce.users_followers" */
	voyce_users_followers: Array<GraphQLTypes["voyce_users_followers"]>,
	/** fetch aggregated fields from the table: "voyce.users_followers" */
	voyce_users_followers_aggregate: GraphQLTypes["voyce_users_followers_aggregate"],
	/** fetch data from the table: "voyce.users_followers" using primary key columns */
	voyce_users_followers_by_pk?: GraphQLTypes["voyce_users_followers"],
	/** fetch data from the table in a streaming manner: "voyce.users_followers" */
	voyce_users_followers_stream: Array<GraphQLTypes["voyce_users_followers"]>,
	/** fetch data from the table: "voyce.users_private" */
	voyce_users_private: Array<GraphQLTypes["voyce_users_private"]>,
	/** fetch aggregated fields from the table: "voyce.users_private" */
	voyce_users_private_aggregate: GraphQLTypes["voyce_users_private_aggregate"],
	/** fetch data from the table in a streaming manner: "voyce.users_private" */
	voyce_users_private_stream: Array<GraphQLTypes["voyce_users_private"]>,
	/** fetch data from the table: "voyce.users_series_junction" */
	voyce_users_series_junction: Array<GraphQLTypes["voyce_users_series_junction"]>,
	/** fetch aggregated fields from the table: "voyce.users_series_junction" */
	voyce_users_series_junction_aggregate: GraphQLTypes["voyce_users_series_junction_aggregate"],
	/** fetch data from the table: "voyce.users_series_junction" using primary key columns */
	voyce_users_series_junction_by_pk?: GraphQLTypes["voyce_users_series_junction"],
	/** fetch data from the table in a streaming manner: "voyce.users_series_junction" */
	voyce_users_series_junction_stream: Array<GraphQLTypes["voyce_users_series_junction"]>,
	/** fetch data from the table in a streaming manner: "voyce.users" */
	voyce_users_stream: Array<GraphQLTypes["voyce_users"]>,
	/** fetch data from the table: "voyce.versions" */
	voyce_versions: Array<GraphQLTypes["voyce_versions"]>,
	/** fetch aggregated fields from the table: "voyce.versions" */
	voyce_versions_aggregate: GraphQLTypes["voyce_versions_aggregate"],
	/** fetch data from the table: "voyce.versions" using primary key columns */
	voyce_versions_by_pk?: GraphQLTypes["voyce_versions"],
	/** fetch data from the table in a streaming manner: "voyce.versions" */
	voyce_versions_stream: Array<GraphQLTypes["voyce_versions"]>,
	/** fetch data from the table: "voyce.voting_analytics" */
	voyce_voting_analytics: Array<GraphQLTypes["voyce_voting_analytics"]>,
	/** fetch aggregated fields from the table: "voyce.voting_analytics" */
	voyce_voting_analytics_aggregate: GraphQLTypes["voyce_voting_analytics_aggregate"],
	/** fetch data from the table: "voyce.voting_analytics" using primary key columns */
	voyce_voting_analytics_by_pk?: GraphQLTypes["voyce_voting_analytics"],
	/** fetch data from the table in a streaming manner: "voyce.voting_analytics" */
	voyce_voting_analytics_stream: Array<GraphQLTypes["voyce_voting_analytics"]>,
	/** fetch data from the table: "voyce.voting_panels" */
	voyce_voting_panels: Array<GraphQLTypes["voyce_voting_panels"]>,
	/** fetch aggregated fields from the table: "voyce.voting_panels" */
	voyce_voting_panels_aggregate: GraphQLTypes["voyce_voting_panels_aggregate"],
	/** fetch data from the table: "voyce.voting_panels" using primary key columns */
	voyce_voting_panels_by_pk?: GraphQLTypes["voyce_voting_panels"],
	/** fetch data from the table in a streaming manner: "voyce.voting_panels" */
	voyce_voting_panels_stream: Array<GraphQLTypes["voyce_voting_panels"]>,
	/** fetch data from the table: "voyce.voting_series" */
	voyce_voting_series: Array<GraphQLTypes["voyce_voting_series"]>,
	/** fetch aggregated fields from the table: "voyce.voting_series" */
	voyce_voting_series_aggregate: GraphQLTypes["voyce_voting_series_aggregate"],
	/** fetch data from the table: "voyce.voting_series" using primary key columns */
	voyce_voting_series_by_pk?: GraphQLTypes["voyce_voting_series"],
	/** fetch data from the table in a streaming manner: "voyce.voting_series" */
	voyce_voting_series_stream: Array<GraphQLTypes["voyce_voting_series"]>,
	/** fetch data from the table: "voyce.voting_user_votes" */
	voyce_voting_user_votes: Array<GraphQLTypes["voyce_voting_user_votes"]>,
	/** fetch aggregated fields from the table: "voyce.voting_user_votes" */
	voyce_voting_user_votes_aggregate: GraphQLTypes["voyce_voting_user_votes_aggregate"],
	/** fetch data from the table: "voyce.voting_user_votes" using primary key columns */
	voyce_voting_user_votes_by_pk?: GraphQLTypes["voyce_voting_user_votes"],
	/** fetch data from the table in a streaming manner: "voyce.voting_user_votes" */
	voyce_voting_user_votes_stream: Array<GraphQLTypes["voyce_voting_user_votes"]>
};
	["time"]:any;
	/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
["time_comparison_exp"]: {
		_eq?: GraphQLTypes["time"],
	_gt?: GraphQLTypes["time"],
	_gte?: GraphQLTypes["time"],
	_in?: Array<GraphQLTypes["time"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["time"],
	_lte?: GraphQLTypes["time"],
	_neq?: GraphQLTypes["time"],
	_nin?: Array<GraphQLTypes["time"]>
};
	["timestamp"]:any;
	/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
["timestamp_comparison_exp"]: {
		_eq?: GraphQLTypes["timestamp"],
	_gt?: GraphQLTypes["timestamp"],
	_gte?: GraphQLTypes["timestamp"],
	_in?: Array<GraphQLTypes["timestamp"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["timestamp"],
	_lte?: GraphQLTypes["timestamp"],
	_neq?: GraphQLTypes["timestamp"],
	_nin?: Array<GraphQLTypes["timestamp"]>
};
	["timestamptz"]:any;
	/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
["timestamptz_comparison_exp"]: {
		_eq?: GraphQLTypes["timestamptz"],
	_gt?: GraphQLTypes["timestamptz"],
	_gte?: GraphQLTypes["timestamptz"],
	_in?: Array<GraphQLTypes["timestamptz"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["timestamptz"],
	_lte?: GraphQLTypes["timestamptz"],
	_neq?: GraphQLTypes["timestamptz"],
	_nin?: Array<GraphQLTypes["timestamptz"]>
};
	["uuid"]:any;
	/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
["uuid_comparison_exp"]: {
		_eq?: GraphQLTypes["uuid"],
	_gt?: GraphQLTypes["uuid"],
	_gte?: GraphQLTypes["uuid"],
	_in?: Array<GraphQLTypes["uuid"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["uuid"],
	_lte?: GraphQLTypes["uuid"],
	_neq?: GraphQLTypes["uuid"],
	_nin?: Array<GraphQLTypes["uuid"]>
};
	/** columns and relationships of "voyce.admin_roles" */
["voyce_admin_roles"]: {
	__typename: "voyce_admin_roles",
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	role: string,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.admin_roles" */
["voyce_admin_roles_aggregate"]: {
	__typename: "voyce_admin_roles_aggregate",
	aggregate?: GraphQLTypes["voyce_admin_roles_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_admin_roles"]>
};
	/** aggregate fields of "voyce.admin_roles" */
["voyce_admin_roles_aggregate_fields"]: {
	__typename: "voyce_admin_roles_aggregate_fields",
	avg?: GraphQLTypes["voyce_admin_roles_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_admin_roles_max_fields"],
	min?: GraphQLTypes["voyce_admin_roles_min_fields"],
	stddev?: GraphQLTypes["voyce_admin_roles_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_admin_roles_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_admin_roles_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_admin_roles_sum_fields"],
	var_pop?: GraphQLTypes["voyce_admin_roles_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_admin_roles_var_samp_fields"],
	variance?: GraphQLTypes["voyce_admin_roles_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_admin_roles_avg_fields"]: {
	__typename: "voyce_admin_roles_avg_fields",
	id?: number
};
	/** Boolean expression to filter rows from the table "voyce.admin_roles". All fields are combined with a logical 'AND'. */
["voyce_admin_roles_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_admin_roles_bool_exp"]>,
	_not?: GraphQLTypes["voyce_admin_roles_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_admin_roles_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	role?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.admin_roles" */
["voyce_admin_roles_constraint"]: voyce_admin_roles_constraint;
	/** input type for incrementing numeric columns in table "voyce.admin_roles" */
["voyce_admin_roles_inc_input"]: {
		id?: number
};
	/** input type for inserting data into table "voyce.admin_roles" */
["voyce_admin_roles_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	role?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_admin_roles_max_fields"]: {
	__typename: "voyce_admin_roles_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	role?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_admin_roles_min_fields"]: {
	__typename: "voyce_admin_roles_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	role?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.admin_roles" */
["voyce_admin_roles_mutation_response"]: {
	__typename: "voyce_admin_roles_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_admin_roles"]>
};
	/** input type for inserting object relation for remote table "voyce.admin_roles" */
["voyce_admin_roles_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_admin_roles_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_admin_roles_on_conflict"]
};
	/** on_conflict condition type for table "voyce.admin_roles" */
["voyce_admin_roles_on_conflict"]: {
		constraint: GraphQLTypes["voyce_admin_roles_constraint"],
	update_columns: Array<GraphQLTypes["voyce_admin_roles_update_column"]>,
	where?: GraphQLTypes["voyce_admin_roles_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.admin_roles". */
["voyce_admin_roles_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	role?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.admin_roles */
["voyce_admin_roles_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.admin_roles" */
["voyce_admin_roles_select_column"]: voyce_admin_roles_select_column;
	/** input type for updating data in table "voyce.admin_roles" */
["voyce_admin_roles_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	role?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_admin_roles_stddev_fields"]: {
	__typename: "voyce_admin_roles_stddev_fields",
	id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_admin_roles_stddev_pop_fields"]: {
	__typename: "voyce_admin_roles_stddev_pop_fields",
	id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_admin_roles_stddev_samp_fields"]: {
	__typename: "voyce_admin_roles_stddev_samp_fields",
	id?: number
};
	/** Streaming cursor of the table "voyce_admin_roles" */
["voyce_admin_roles_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_admin_roles_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_admin_roles_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	role?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_admin_roles_sum_fields"]: {
	__typename: "voyce_admin_roles_sum_fields",
	id?: number
};
	/** update columns of table "voyce.admin_roles" */
["voyce_admin_roles_update_column"]: voyce_admin_roles_update_column;
	["voyce_admin_roles_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_admin_roles_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_admin_roles_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_admin_roles_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_admin_roles_var_pop_fields"]: {
	__typename: "voyce_admin_roles_var_pop_fields",
	id?: number
};
	/** aggregate var_samp on columns */
["voyce_admin_roles_var_samp_fields"]: {
	__typename: "voyce_admin_roles_var_samp_fields",
	id?: number
};
	/** aggregate variance on columns */
["voyce_admin_roles_variance_fields"]: {
	__typename: "voyce_admin_roles_variance_fields",
	id?: number
};
	/** columns and relationships of "voyce.app_banners" */
["voyce_app_banners"]: {
	__typename: "voyce_app_banners",
	active: boolean,
	background?: string,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	link?: string,
	mobile?: string,
	overlap: boolean,
	screen: string,
	sort: GraphQLTypes["smallint"],
	tablet?: string,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.app_banners" */
["voyce_app_banners_aggregate"]: {
	__typename: "voyce_app_banners_aggregate",
	aggregate?: GraphQLTypes["voyce_app_banners_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_app_banners"]>
};
	/** aggregate fields of "voyce.app_banners" */
["voyce_app_banners_aggregate_fields"]: {
	__typename: "voyce_app_banners_aggregate_fields",
	avg?: GraphQLTypes["voyce_app_banners_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_app_banners_max_fields"],
	min?: GraphQLTypes["voyce_app_banners_min_fields"],
	stddev?: GraphQLTypes["voyce_app_banners_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_app_banners_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_app_banners_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_app_banners_sum_fields"],
	var_pop?: GraphQLTypes["voyce_app_banners_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_app_banners_var_samp_fields"],
	variance?: GraphQLTypes["voyce_app_banners_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_app_banners_avg_fields"]: {
	__typename: "voyce_app_banners_avg_fields",
	id?: number,
	sort?: number
};
	/** Boolean expression to filter rows from the table "voyce.app_banners". All fields are combined with a logical 'AND'. */
["voyce_app_banners_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_app_banners_bool_exp"]>,
	_not?: GraphQLTypes["voyce_app_banners_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_app_banners_bool_exp"]>,
	active?: GraphQLTypes["Boolean_comparison_exp"],
	background?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	link?: GraphQLTypes["String_comparison_exp"],
	mobile?: GraphQLTypes["String_comparison_exp"],
	overlap?: GraphQLTypes["Boolean_comparison_exp"],
	screen?: GraphQLTypes["String_comparison_exp"],
	sort?: GraphQLTypes["smallint_comparison_exp"],
	tablet?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.app_banners" */
["voyce_app_banners_constraint"]: voyce_app_banners_constraint;
	/** input type for incrementing numeric columns in table "voyce.app_banners" */
["voyce_app_banners_inc_input"]: {
		id?: number,
	sort?: GraphQLTypes["smallint"]
};
	/** input type for inserting data into table "voyce.app_banners" */
["voyce_app_banners_insert_input"]: {
		active?: boolean,
	background?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	link?: string,
	mobile?: string,
	overlap?: boolean,
	screen?: string,
	sort?: GraphQLTypes["smallint"],
	tablet?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_app_banners_max_fields"]: {
	__typename: "voyce_app_banners_max_fields",
	background?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	link?: string,
	mobile?: string,
	screen?: string,
	sort?: GraphQLTypes["smallint"],
	tablet?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_app_banners_min_fields"]: {
	__typename: "voyce_app_banners_min_fields",
	background?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	link?: string,
	mobile?: string,
	screen?: string,
	sort?: GraphQLTypes["smallint"],
	tablet?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.app_banners" */
["voyce_app_banners_mutation_response"]: {
	__typename: "voyce_app_banners_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_app_banners"]>
};
	/** on_conflict condition type for table "voyce.app_banners" */
["voyce_app_banners_on_conflict"]: {
		constraint: GraphQLTypes["voyce_app_banners_constraint"],
	update_columns: Array<GraphQLTypes["voyce_app_banners_update_column"]>,
	where?: GraphQLTypes["voyce_app_banners_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.app_banners". */
["voyce_app_banners_order_by"]: {
		active?: GraphQLTypes["order_by"],
	background?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	link?: GraphQLTypes["order_by"],
	mobile?: GraphQLTypes["order_by"],
	overlap?: GraphQLTypes["order_by"],
	screen?: GraphQLTypes["order_by"],
	sort?: GraphQLTypes["order_by"],
	tablet?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.app_banners */
["voyce_app_banners_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.app_banners" */
["voyce_app_banners_select_column"]: voyce_app_banners_select_column;
	/** input type for updating data in table "voyce.app_banners" */
["voyce_app_banners_set_input"]: {
		active?: boolean,
	background?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	link?: string,
	mobile?: string,
	overlap?: boolean,
	screen?: string,
	sort?: GraphQLTypes["smallint"],
	tablet?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_app_banners_stddev_fields"]: {
	__typename: "voyce_app_banners_stddev_fields",
	id?: number,
	sort?: number
};
	/** aggregate stddev_pop on columns */
["voyce_app_banners_stddev_pop_fields"]: {
	__typename: "voyce_app_banners_stddev_pop_fields",
	id?: number,
	sort?: number
};
	/** aggregate stddev_samp on columns */
["voyce_app_banners_stddev_samp_fields"]: {
	__typename: "voyce_app_banners_stddev_samp_fields",
	id?: number,
	sort?: number
};
	/** Streaming cursor of the table "voyce_app_banners" */
["voyce_app_banners_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_app_banners_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_app_banners_stream_cursor_value_input"]: {
		active?: boolean,
	background?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	link?: string,
	mobile?: string,
	overlap?: boolean,
	screen?: string,
	sort?: GraphQLTypes["smallint"],
	tablet?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_app_banners_sum_fields"]: {
	__typename: "voyce_app_banners_sum_fields",
	id?: number,
	sort?: GraphQLTypes["smallint"]
};
	/** update columns of table "voyce.app_banners" */
["voyce_app_banners_update_column"]: voyce_app_banners_update_column;
	["voyce_app_banners_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_app_banners_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_app_banners_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_app_banners_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_app_banners_var_pop_fields"]: {
	__typename: "voyce_app_banners_var_pop_fields",
	id?: number,
	sort?: number
};
	/** aggregate var_samp on columns */
["voyce_app_banners_var_samp_fields"]: {
	__typename: "voyce_app_banners_var_samp_fields",
	id?: number,
	sort?: number
};
	/** aggregate variance on columns */
["voyce_app_banners_variance_fields"]: {
	__typename: "voyce_app_banners_variance_fields",
	id?: number,
	sort?: number
};
	/** columns and relationships of "voyce.app_components" */
["voyce_app_components"]: {
	__typename: "voyce_app_components",
	active: boolean,
	auth?: boolean,
	component: string,
	created_at: GraphQLTypes["timestamptz"],
	device: string,
	id: number,
	props?: GraphQLTypes["jsonb"],
	screen: string,
	sort: GraphQLTypes["smallint"],
	title?: string,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.app_components" */
["voyce_app_components_aggregate"]: {
	__typename: "voyce_app_components_aggregate",
	aggregate?: GraphQLTypes["voyce_app_components_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_app_components"]>
};
	/** aggregate fields of "voyce.app_components" */
["voyce_app_components_aggregate_fields"]: {
	__typename: "voyce_app_components_aggregate_fields",
	avg?: GraphQLTypes["voyce_app_components_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_app_components_max_fields"],
	min?: GraphQLTypes["voyce_app_components_min_fields"],
	stddev?: GraphQLTypes["voyce_app_components_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_app_components_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_app_components_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_app_components_sum_fields"],
	var_pop?: GraphQLTypes["voyce_app_components_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_app_components_var_samp_fields"],
	variance?: GraphQLTypes["voyce_app_components_variance_fields"]
};
	/** append existing jsonb value of filtered columns with new jsonb value */
["voyce_app_components_append_input"]: {
		props?: GraphQLTypes["jsonb"]
};
	/** aggregate avg on columns */
["voyce_app_components_avg_fields"]: {
	__typename: "voyce_app_components_avg_fields",
	id?: number,
	sort?: number
};
	/** Boolean expression to filter rows from the table "voyce.app_components". All fields are combined with a logical 'AND'. */
["voyce_app_components_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_app_components_bool_exp"]>,
	_not?: GraphQLTypes["voyce_app_components_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_app_components_bool_exp"]>,
	active?: GraphQLTypes["Boolean_comparison_exp"],
	auth?: GraphQLTypes["Boolean_comparison_exp"],
	component?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	device?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	props?: GraphQLTypes["jsonb_comparison_exp"],
	screen?: GraphQLTypes["String_comparison_exp"],
	sort?: GraphQLTypes["smallint_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.app_components" */
["voyce_app_components_constraint"]: voyce_app_components_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["voyce_app_components_delete_at_path_input"]: {
		props?: Array<string>
};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["voyce_app_components_delete_elem_input"]: {
		props?: number
};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["voyce_app_components_delete_key_input"]: {
		props?: string
};
	/** input type for incrementing numeric columns in table "voyce.app_components" */
["voyce_app_components_inc_input"]: {
		id?: number,
	sort?: GraphQLTypes["smallint"]
};
	/** input type for inserting data into table "voyce.app_components" */
["voyce_app_components_insert_input"]: {
		active?: boolean,
	auth?: boolean,
	component?: string,
	created_at?: GraphQLTypes["timestamptz"],
	device?: string,
	id?: number,
	props?: GraphQLTypes["jsonb"],
	screen?: string,
	sort?: GraphQLTypes["smallint"],
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_app_components_max_fields"]: {
	__typename: "voyce_app_components_max_fields",
	component?: string,
	created_at?: GraphQLTypes["timestamptz"],
	device?: string,
	id?: number,
	screen?: string,
	sort?: GraphQLTypes["smallint"],
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_app_components_min_fields"]: {
	__typename: "voyce_app_components_min_fields",
	component?: string,
	created_at?: GraphQLTypes["timestamptz"],
	device?: string,
	id?: number,
	screen?: string,
	sort?: GraphQLTypes["smallint"],
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.app_components" */
["voyce_app_components_mutation_response"]: {
	__typename: "voyce_app_components_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_app_components"]>
};
	/** on_conflict condition type for table "voyce.app_components" */
["voyce_app_components_on_conflict"]: {
		constraint: GraphQLTypes["voyce_app_components_constraint"],
	update_columns: Array<GraphQLTypes["voyce_app_components_update_column"]>,
	where?: GraphQLTypes["voyce_app_components_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.app_components". */
["voyce_app_components_order_by"]: {
		active?: GraphQLTypes["order_by"],
	auth?: GraphQLTypes["order_by"],
	component?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	device?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	props?: GraphQLTypes["order_by"],
	screen?: GraphQLTypes["order_by"],
	sort?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.app_components */
["voyce_app_components_pk_columns_input"]: {
		id: number
};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["voyce_app_components_prepend_input"]: {
		props?: GraphQLTypes["jsonb"]
};
	/** select columns of table "voyce.app_components" */
["voyce_app_components_select_column"]: voyce_app_components_select_column;
	/** input type for updating data in table "voyce.app_components" */
["voyce_app_components_set_input"]: {
		active?: boolean,
	auth?: boolean,
	component?: string,
	created_at?: GraphQLTypes["timestamptz"],
	device?: string,
	id?: number,
	props?: GraphQLTypes["jsonb"],
	screen?: string,
	sort?: GraphQLTypes["smallint"],
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_app_components_stddev_fields"]: {
	__typename: "voyce_app_components_stddev_fields",
	id?: number,
	sort?: number
};
	/** aggregate stddev_pop on columns */
["voyce_app_components_stddev_pop_fields"]: {
	__typename: "voyce_app_components_stddev_pop_fields",
	id?: number,
	sort?: number
};
	/** aggregate stddev_samp on columns */
["voyce_app_components_stddev_samp_fields"]: {
	__typename: "voyce_app_components_stddev_samp_fields",
	id?: number,
	sort?: number
};
	/** Streaming cursor of the table "voyce_app_components" */
["voyce_app_components_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_app_components_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_app_components_stream_cursor_value_input"]: {
		active?: boolean,
	auth?: boolean,
	component?: string,
	created_at?: GraphQLTypes["timestamptz"],
	device?: string,
	id?: number,
	props?: GraphQLTypes["jsonb"],
	screen?: string,
	sort?: GraphQLTypes["smallint"],
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_app_components_sum_fields"]: {
	__typename: "voyce_app_components_sum_fields",
	id?: number,
	sort?: GraphQLTypes["smallint"]
};
	/** update columns of table "voyce.app_components" */
["voyce_app_components_update_column"]: voyce_app_components_update_column;
	["voyce_app_components_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: GraphQLTypes["voyce_app_components_append_input"],
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: GraphQLTypes["voyce_app_components_delete_at_path_input"],
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: GraphQLTypes["voyce_app_components_delete_elem_input"],
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: GraphQLTypes["voyce_app_components_delete_key_input"],
	/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_app_components_inc_input"],
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: GraphQLTypes["voyce_app_components_prepend_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_app_components_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_app_components_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_app_components_var_pop_fields"]: {
	__typename: "voyce_app_components_var_pop_fields",
	id?: number,
	sort?: number
};
	/** aggregate var_samp on columns */
["voyce_app_components_var_samp_fields"]: {
	__typename: "voyce_app_components_var_samp_fields",
	id?: number,
	sort?: number
};
	/** aggregate variance on columns */
["voyce_app_components_variance_fields"]: {
	__typename: "voyce_app_components_variance_fields",
	id?: number,
	sort?: number
};
	/** columns and relationships of "voyce.audit_trail" */
["voyce_audit_trail"]: {
	__typename: "voyce_audit_trail",
	action: string,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	module: string,
	payload?: GraphQLTypes["json"],
	/** An object relationship */
	user?: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.audit_trail" */
["voyce_audit_trail_aggregate"]: {
	__typename: "voyce_audit_trail_aggregate",
	aggregate?: GraphQLTypes["voyce_audit_trail_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_audit_trail"]>
};
	/** aggregate fields of "voyce.audit_trail" */
["voyce_audit_trail_aggregate_fields"]: {
	__typename: "voyce_audit_trail_aggregate_fields",
	avg?: GraphQLTypes["voyce_audit_trail_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_audit_trail_max_fields"],
	min?: GraphQLTypes["voyce_audit_trail_min_fields"],
	stddev?: GraphQLTypes["voyce_audit_trail_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_audit_trail_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_audit_trail_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_audit_trail_sum_fields"],
	var_pop?: GraphQLTypes["voyce_audit_trail_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_audit_trail_var_samp_fields"],
	variance?: GraphQLTypes["voyce_audit_trail_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_audit_trail_avg_fields"]: {
	__typename: "voyce_audit_trail_avg_fields",
	id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.audit_trail". All fields are combined with a logical 'AND'. */
["voyce_audit_trail_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_audit_trail_bool_exp"]>,
	_not?: GraphQLTypes["voyce_audit_trail_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_audit_trail_bool_exp"]>,
	action?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	module?: GraphQLTypes["String_comparison_exp"],
	payload?: GraphQLTypes["json_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.audit_trail" */
["voyce_audit_trail_constraint"]: voyce_audit_trail_constraint;
	/** input type for incrementing numeric columns in table "voyce.audit_trail" */
["voyce_audit_trail_inc_input"]: {
		id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.audit_trail" */
["voyce_audit_trail_insert_input"]: {
		action?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	module?: string,
	payload?: GraphQLTypes["json"],
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_audit_trail_max_fields"]: {
	__typename: "voyce_audit_trail_max_fields",
	action?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	module?: string,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_audit_trail_min_fields"]: {
	__typename: "voyce_audit_trail_min_fields",
	action?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	module?: string,
	user_id?: number
};
	/** response of any mutation on the table "voyce.audit_trail" */
["voyce_audit_trail_mutation_response"]: {
	__typename: "voyce_audit_trail_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_audit_trail"]>
};
	/** on_conflict condition type for table "voyce.audit_trail" */
["voyce_audit_trail_on_conflict"]: {
		constraint: GraphQLTypes["voyce_audit_trail_constraint"],
	update_columns: Array<GraphQLTypes["voyce_audit_trail_update_column"]>,
	where?: GraphQLTypes["voyce_audit_trail_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.audit_trail". */
["voyce_audit_trail_order_by"]: {
		action?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	module?: GraphQLTypes["order_by"],
	payload?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.audit_trail */
["voyce_audit_trail_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.audit_trail" */
["voyce_audit_trail_select_column"]: voyce_audit_trail_select_column;
	/** input type for updating data in table "voyce.audit_trail" */
["voyce_audit_trail_set_input"]: {
		action?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	module?: string,
	payload?: GraphQLTypes["json"],
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_audit_trail_stddev_fields"]: {
	__typename: "voyce_audit_trail_stddev_fields",
	id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_audit_trail_stddev_pop_fields"]: {
	__typename: "voyce_audit_trail_stddev_pop_fields",
	id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_audit_trail_stddev_samp_fields"]: {
	__typename: "voyce_audit_trail_stddev_samp_fields",
	id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_audit_trail" */
["voyce_audit_trail_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_audit_trail_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_audit_trail_stream_cursor_value_input"]: {
		action?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	module?: string,
	payload?: GraphQLTypes["json"],
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_audit_trail_sum_fields"]: {
	__typename: "voyce_audit_trail_sum_fields",
	id?: number,
	user_id?: number
};
	/** update columns of table "voyce.audit_trail" */
["voyce_audit_trail_update_column"]: voyce_audit_trail_update_column;
	["voyce_audit_trail_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_audit_trail_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_audit_trail_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_audit_trail_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_audit_trail_var_pop_fields"]: {
	__typename: "voyce_audit_trail_var_pop_fields",
	id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_audit_trail_var_samp_fields"]: {
	__typename: "voyce_audit_trail_var_samp_fields",
	id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_audit_trail_variance_fields"]: {
	__typename: "voyce_audit_trail_variance_fields",
	id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.author_profiles" */
["voyce_author_profiles"]: {
	__typename: "voyce_author_profiles",
	created_at: GraphQLTypes["timestamp"],
	followers_count?: number,
	id: number,
	last_activity_date: GraphQLTypes["timestamptz"],
	likes_count: number,
	outdated: boolean,
	popularity: number,
	total_popularity: number,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number,
	views_count: number
};
	/** aggregated selection of "voyce.author_profiles" */
["voyce_author_profiles_aggregate"]: {
	__typename: "voyce_author_profiles_aggregate",
	aggregate?: GraphQLTypes["voyce_author_profiles_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_author_profiles"]>
};
	["voyce_author_profiles_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["voyce_author_profiles_aggregate_bool_exp_bool_and"],
	bool_or?: GraphQLTypes["voyce_author_profiles_aggregate_bool_exp_bool_or"],
	count?: GraphQLTypes["voyce_author_profiles_aggregate_bool_exp_count"]
};
	["voyce_author_profiles_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_author_profiles_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_author_profiles_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_author_profiles_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_author_profiles_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_author_profiles_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_author_profiles_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.author_profiles" */
["voyce_author_profiles_aggregate_fields"]: {
	__typename: "voyce_author_profiles_aggregate_fields",
	avg?: GraphQLTypes["voyce_author_profiles_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_author_profiles_max_fields"],
	min?: GraphQLTypes["voyce_author_profiles_min_fields"],
	stddev?: GraphQLTypes["voyce_author_profiles_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_author_profiles_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_author_profiles_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_author_profiles_sum_fields"],
	var_pop?: GraphQLTypes["voyce_author_profiles_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_author_profiles_var_samp_fields"],
	variance?: GraphQLTypes["voyce_author_profiles_variance_fields"]
};
	/** order by aggregate values of table "voyce.author_profiles" */
["voyce_author_profiles_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_author_profiles_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_author_profiles_max_order_by"],
	min?: GraphQLTypes["voyce_author_profiles_min_order_by"],
	stddev?: GraphQLTypes["voyce_author_profiles_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_author_profiles_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_author_profiles_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_author_profiles_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_author_profiles_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_author_profiles_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_author_profiles_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.author_profiles" */
["voyce_author_profiles_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_author_profiles_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_author_profiles_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_author_profiles_avg_fields"]: {
	__typename: "voyce_author_profiles_avg_fields",
	followers_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	total_popularity?: number,
	user_id?: number,
	views_count?: number
};
	/** order by avg() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_avg_order_by"]: {
		followers_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	views_count?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.author_profiles". All fields are combined with a logical 'AND'. */
["voyce_author_profiles_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_author_profiles_bool_exp"]>,
	_not?: GraphQLTypes["voyce_author_profiles_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_author_profiles_bool_exp"]>,
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	followers_count?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	last_activity_date?: GraphQLTypes["timestamptz_comparison_exp"],
	likes_count?: GraphQLTypes["Int_comparison_exp"],
	outdated?: GraphQLTypes["Boolean_comparison_exp"],
	popularity?: GraphQLTypes["Int_comparison_exp"],
	total_popularity?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	views_count?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.author_profiles" */
["voyce_author_profiles_constraint"]: voyce_author_profiles_constraint;
	/** input type for incrementing numeric columns in table "voyce.author_profiles" */
["voyce_author_profiles_inc_input"]: {
		followers_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	total_popularity?: number,
	user_id?: number,
	views_count?: number
};
	/** input type for inserting data into table "voyce.author_profiles" */
["voyce_author_profiles_insert_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	followers_count?: number,
	id?: number,
	last_activity_date?: GraphQLTypes["timestamptz"],
	likes_count?: number,
	outdated?: boolean,
	popularity?: number,
	total_popularity?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number,
	views_count?: number
};
	/** aggregate max on columns */
["voyce_author_profiles_max_fields"]: {
	__typename: "voyce_author_profiles_max_fields",
	created_at?: GraphQLTypes["timestamp"],
	followers_count?: number,
	id?: number,
	last_activity_date?: GraphQLTypes["timestamptz"],
	likes_count?: number,
	popularity?: number,
	total_popularity?: number,
	user_id?: number,
	views_count?: number
};
	/** order by max() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_max_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	followers_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_activity_date?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	views_count?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_author_profiles_min_fields"]: {
	__typename: "voyce_author_profiles_min_fields",
	created_at?: GraphQLTypes["timestamp"],
	followers_count?: number,
	id?: number,
	last_activity_date?: GraphQLTypes["timestamptz"],
	likes_count?: number,
	popularity?: number,
	total_popularity?: number,
	user_id?: number,
	views_count?: number
};
	/** order by min() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_min_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	followers_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_activity_date?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	views_count?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.author_profiles" */
["voyce_author_profiles_mutation_response"]: {
	__typename: "voyce_author_profiles_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_author_profiles"]>
};
	/** input type for inserting object relation for remote table "voyce.author_profiles" */
["voyce_author_profiles_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_author_profiles_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_author_profiles_on_conflict"]
};
	/** on_conflict condition type for table "voyce.author_profiles" */
["voyce_author_profiles_on_conflict"]: {
		constraint: GraphQLTypes["voyce_author_profiles_constraint"],
	update_columns: Array<GraphQLTypes["voyce_author_profiles_update_column"]>,
	where?: GraphQLTypes["voyce_author_profiles_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.author_profiles". */
["voyce_author_profiles_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	followers_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_activity_date?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	outdated?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"],
	views_count?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.author_profiles */
["voyce_author_profiles_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.author_profiles" */
["voyce_author_profiles_select_column"]: voyce_author_profiles_select_column;
	/** select "voyce_author_profiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.author_profiles" */
["voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_and_arguments_columns"]: voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_author_profiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.author_profiles" */
["voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_or_arguments_columns"]: voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.author_profiles" */
["voyce_author_profiles_set_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	followers_count?: number,
	id?: number,
	last_activity_date?: GraphQLTypes["timestamptz"],
	likes_count?: number,
	outdated?: boolean,
	popularity?: number,
	total_popularity?: number,
	user_id?: number,
	views_count?: number
};
	/** aggregate stddev on columns */
["voyce_author_profiles_stddev_fields"]: {
	__typename: "voyce_author_profiles_stddev_fields",
	followers_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	total_popularity?: number,
	user_id?: number,
	views_count?: number
};
	/** order by stddev() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_stddev_order_by"]: {
		followers_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	views_count?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_author_profiles_stddev_pop_fields"]: {
	__typename: "voyce_author_profiles_stddev_pop_fields",
	followers_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	total_popularity?: number,
	user_id?: number,
	views_count?: number
};
	/** order by stddev_pop() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_stddev_pop_order_by"]: {
		followers_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	views_count?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_author_profiles_stddev_samp_fields"]: {
	__typename: "voyce_author_profiles_stddev_samp_fields",
	followers_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	total_popularity?: number,
	user_id?: number,
	views_count?: number
};
	/** order by stddev_samp() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_stddev_samp_order_by"]: {
		followers_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	views_count?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_author_profiles" */
["voyce_author_profiles_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_author_profiles_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_author_profiles_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	followers_count?: number,
	id?: number,
	last_activity_date?: GraphQLTypes["timestamptz"],
	likes_count?: number,
	outdated?: boolean,
	popularity?: number,
	total_popularity?: number,
	user_id?: number,
	views_count?: number
};
	/** aggregate sum on columns */
["voyce_author_profiles_sum_fields"]: {
	__typename: "voyce_author_profiles_sum_fields",
	followers_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	total_popularity?: number,
	user_id?: number,
	views_count?: number
};
	/** order by sum() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_sum_order_by"]: {
		followers_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	views_count?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.author_profiles" */
["voyce_author_profiles_update_column"]: voyce_author_profiles_update_column;
	["voyce_author_profiles_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_author_profiles_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_author_profiles_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_author_profiles_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_author_profiles_var_pop_fields"]: {
	__typename: "voyce_author_profiles_var_pop_fields",
	followers_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	total_popularity?: number,
	user_id?: number,
	views_count?: number
};
	/** order by var_pop() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_var_pop_order_by"]: {
		followers_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	views_count?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_author_profiles_var_samp_fields"]: {
	__typename: "voyce_author_profiles_var_samp_fields",
	followers_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	total_popularity?: number,
	user_id?: number,
	views_count?: number
};
	/** order by var_samp() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_var_samp_order_by"]: {
		followers_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	views_count?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_author_profiles_variance_fields"]: {
	__typename: "voyce_author_profiles_variance_fields",
	followers_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	total_popularity?: number,
	user_id?: number,
	views_count?: number
};
	/** order by variance() on columns of table "voyce.author_profiles" */
["voyce_author_profiles_variance_order_by"]: {
		followers_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	views_count?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.balances" */
["voyce_balances"]: {
	__typename: "voyce_balances",
	amount?: number,
	created_at: GraphQLTypes["timestamp"],
	id: number,
	transferable_amount?: number,
	type: string,
	updated_at?: GraphQLTypes["timestamp"],
	/** An object relationship */
	user?: GraphQLTypes["voyce_users"],
	user_id?: number
};
	/** aggregated selection of "voyce.balances" */
["voyce_balances_aggregate"]: {
	__typename: "voyce_balances_aggregate",
	aggregate?: GraphQLTypes["voyce_balances_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_balances"]>
};
	/** aggregate fields of "voyce.balances" */
["voyce_balances_aggregate_fields"]: {
	__typename: "voyce_balances_aggregate_fields",
	avg?: GraphQLTypes["voyce_balances_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_balances_max_fields"],
	min?: GraphQLTypes["voyce_balances_min_fields"],
	stddev?: GraphQLTypes["voyce_balances_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_balances_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_balances_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_balances_sum_fields"],
	var_pop?: GraphQLTypes["voyce_balances_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_balances_var_samp_fields"],
	variance?: GraphQLTypes["voyce_balances_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_balances_avg_fields"]: {
	__typename: "voyce_balances_avg_fields",
	amount?: number,
	id?: number,
	transferable_amount?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.balances". All fields are combined with a logical 'AND'. */
["voyce_balances_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_balances_bool_exp"]>,
	_not?: GraphQLTypes["voyce_balances_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_balances_bool_exp"]>,
	amount?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	transferable_amount?: GraphQLTypes["Int_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamp_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.balances" */
["voyce_balances_constraint"]: voyce_balances_constraint;
	/** input type for incrementing numeric columns in table "voyce.balances" */
["voyce_balances_inc_input"]: {
		amount?: number,
	id?: number,
	transferable_amount?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.balances" */
["voyce_balances_insert_input"]: {
		amount?: number,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	transferable_amount?: number,
	type?: string,
	updated_at?: GraphQLTypes["timestamp"],
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_balances_max_fields"]: {
	__typename: "voyce_balances_max_fields",
	amount?: number,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	transferable_amount?: number,
	type?: string,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number
};
	/** aggregate min on columns */
["voyce_balances_min_fields"]: {
	__typename: "voyce_balances_min_fields",
	amount?: number,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	transferable_amount?: number,
	type?: string,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number
};
	/** response of any mutation on the table "voyce.balances" */
["voyce_balances_mutation_response"]: {
	__typename: "voyce_balances_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_balances"]>
};
	/** on_conflict condition type for table "voyce.balances" */
["voyce_balances_on_conflict"]: {
		constraint: GraphQLTypes["voyce_balances_constraint"],
	update_columns: Array<GraphQLTypes["voyce_balances_update_column"]>,
	where?: GraphQLTypes["voyce_balances_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.balances". */
["voyce_balances_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	transferable_amount?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.balances */
["voyce_balances_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.balances" */
["voyce_balances_select_column"]: voyce_balances_select_column;
	/** input type for updating data in table "voyce.balances" */
["voyce_balances_set_input"]: {
		amount?: number,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	transferable_amount?: number,
	type?: string,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_balances_stddev_fields"]: {
	__typename: "voyce_balances_stddev_fields",
	amount?: number,
	id?: number,
	transferable_amount?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_balances_stddev_pop_fields"]: {
	__typename: "voyce_balances_stddev_pop_fields",
	amount?: number,
	id?: number,
	transferable_amount?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_balances_stddev_samp_fields"]: {
	__typename: "voyce_balances_stddev_samp_fields",
	amount?: number,
	id?: number,
	transferable_amount?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_balances" */
["voyce_balances_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_balances_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_balances_stream_cursor_value_input"]: {
		amount?: number,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	transferable_amount?: number,
	type?: string,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_balances_sum_fields"]: {
	__typename: "voyce_balances_sum_fields",
	amount?: number,
	id?: number,
	transferable_amount?: number,
	user_id?: number
};
	/** update columns of table "voyce.balances" */
["voyce_balances_update_column"]: voyce_balances_update_column;
	["voyce_balances_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_balances_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_balances_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_balances_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_balances_var_pop_fields"]: {
	__typename: "voyce_balances_var_pop_fields",
	amount?: number,
	id?: number,
	transferable_amount?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_balances_var_samp_fields"]: {
	__typename: "voyce_balances_var_samp_fields",
	amount?: number,
	id?: number,
	transferable_amount?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_balances_variance_fields"]: {
	__typename: "voyce_balances_variance_fields",
	amount?: number,
	id?: number,
	transferable_amount?: number,
	user_id?: number
};
	/** Series Banners */
["voyce_banners"]: {
	__typename: "voyce_banners",
	back_image: string,
	front_image?: string,
	id: number,
	order: number,
	/** An object relationship */
	series: GraphQLTypes["voyce_series"],
	series_id: number,
	subtitle?: string,
	tag: number
};
	/** aggregated selection of "voyce.banners" */
["voyce_banners_aggregate"]: {
	__typename: "voyce_banners_aggregate",
	aggregate?: GraphQLTypes["voyce_banners_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_banners"]>
};
	/** aggregate fields of "voyce.banners" */
["voyce_banners_aggregate_fields"]: {
	__typename: "voyce_banners_aggregate_fields",
	avg?: GraphQLTypes["voyce_banners_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_banners_max_fields"],
	min?: GraphQLTypes["voyce_banners_min_fields"],
	stddev?: GraphQLTypes["voyce_banners_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_banners_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_banners_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_banners_sum_fields"],
	var_pop?: GraphQLTypes["voyce_banners_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_banners_var_samp_fields"],
	variance?: GraphQLTypes["voyce_banners_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_banners_avg_fields"]: {
	__typename: "voyce_banners_avg_fields",
	id?: number,
	order?: number,
	series_id?: number,
	tag?: number
};
	/** Boolean expression to filter rows from the table "voyce.banners". All fields are combined with a logical 'AND'. */
["voyce_banners_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_banners_bool_exp"]>,
	_not?: GraphQLTypes["voyce_banners_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_banners_bool_exp"]>,
	back_image?: GraphQLTypes["String_comparison_exp"],
	front_image?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	order?: GraphQLTypes["Int_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	subtitle?: GraphQLTypes["String_comparison_exp"],
	tag?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.banners" */
["voyce_banners_constraint"]: voyce_banners_constraint;
	/** input type for incrementing numeric columns in table "voyce.banners" */
["voyce_banners_inc_input"]: {
		id?: number,
	order?: number,
	series_id?: number,
	tag?: number
};
	/** input type for inserting data into table "voyce.banners" */
["voyce_banners_insert_input"]: {
		back_image?: string,
	front_image?: string,
	id?: number,
	order?: number,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	subtitle?: string,
	tag?: number
};
	/** aggregate max on columns */
["voyce_banners_max_fields"]: {
	__typename: "voyce_banners_max_fields",
	back_image?: string,
	front_image?: string,
	id?: number,
	order?: number,
	series_id?: number,
	subtitle?: string,
	tag?: number
};
	/** aggregate min on columns */
["voyce_banners_min_fields"]: {
	__typename: "voyce_banners_min_fields",
	back_image?: string,
	front_image?: string,
	id?: number,
	order?: number,
	series_id?: number,
	subtitle?: string,
	tag?: number
};
	/** response of any mutation on the table "voyce.banners" */
["voyce_banners_mutation_response"]: {
	__typename: "voyce_banners_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_banners"]>
};
	/** on_conflict condition type for table "voyce.banners" */
["voyce_banners_on_conflict"]: {
		constraint: GraphQLTypes["voyce_banners_constraint"],
	update_columns: Array<GraphQLTypes["voyce_banners_update_column"]>,
	where?: GraphQLTypes["voyce_banners_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.banners". */
["voyce_banners_order_by"]: {
		back_image?: GraphQLTypes["order_by"],
	front_image?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	subtitle?: GraphQLTypes["order_by"],
	tag?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.banners */
["voyce_banners_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.banners" */
["voyce_banners_select_column"]: voyce_banners_select_column;
	/** input type for updating data in table "voyce.banners" */
["voyce_banners_set_input"]: {
		back_image?: string,
	front_image?: string,
	id?: number,
	order?: number,
	series_id?: number,
	subtitle?: string,
	tag?: number
};
	/** aggregate stddev on columns */
["voyce_banners_stddev_fields"]: {
	__typename: "voyce_banners_stddev_fields",
	id?: number,
	order?: number,
	series_id?: number,
	tag?: number
};
	/** aggregate stddev_pop on columns */
["voyce_banners_stddev_pop_fields"]: {
	__typename: "voyce_banners_stddev_pop_fields",
	id?: number,
	order?: number,
	series_id?: number,
	tag?: number
};
	/** aggregate stddev_samp on columns */
["voyce_banners_stddev_samp_fields"]: {
	__typename: "voyce_banners_stddev_samp_fields",
	id?: number,
	order?: number,
	series_id?: number,
	tag?: number
};
	/** Streaming cursor of the table "voyce_banners" */
["voyce_banners_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_banners_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_banners_stream_cursor_value_input"]: {
		back_image?: string,
	front_image?: string,
	id?: number,
	order?: number,
	series_id?: number,
	subtitle?: string,
	tag?: number
};
	/** aggregate sum on columns */
["voyce_banners_sum_fields"]: {
	__typename: "voyce_banners_sum_fields",
	id?: number,
	order?: number,
	series_id?: number,
	tag?: number
};
	/** update columns of table "voyce.banners" */
["voyce_banners_update_column"]: voyce_banners_update_column;
	["voyce_banners_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_banners_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_banners_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_banners_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_banners_var_pop_fields"]: {
	__typename: "voyce_banners_var_pop_fields",
	id?: number,
	order?: number,
	series_id?: number,
	tag?: number
};
	/** aggregate var_samp on columns */
["voyce_banners_var_samp_fields"]: {
	__typename: "voyce_banners_var_samp_fields",
	id?: number,
	order?: number,
	series_id?: number,
	tag?: number
};
	/** aggregate variance on columns */
["voyce_banners_variance_fields"]: {
	__typename: "voyce_banners_variance_fields",
	id?: number,
	order?: number,
	series_id?: number,
	tag?: number
};
	/** columns and relationships of "voyce.chapter_assets" */
["voyce_chapter_assets"]: {
	__typename: "voyce_chapter_assets",
	/** An object relationship */
	chapter_effect?: GraphQLTypes["voyce_chapter_effects"],
	chapter_effect_id?: number,
	id: number,
	order: number,
	type: string,
	url?: string
};
	/** aggregated selection of "voyce.chapter_assets" */
["voyce_chapter_assets_aggregate"]: {
	__typename: "voyce_chapter_assets_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_assets_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_assets"]>
};
	["voyce_chapter_assets_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_chapter_assets_aggregate_bool_exp_count"]
};
	["voyce_chapter_assets_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_chapter_assets_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_assets_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_assets" */
["voyce_chapter_assets_aggregate_fields"]: {
	__typename: "voyce_chapter_assets_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_assets_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_assets_max_fields"],
	min?: GraphQLTypes["voyce_chapter_assets_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_assets_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_assets_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_assets_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_assets_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_assets_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_assets_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_assets_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_assets" */
["voyce_chapter_assets_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_chapter_assets_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_chapter_assets_max_order_by"],
	min?: GraphQLTypes["voyce_chapter_assets_min_order_by"],
	stddev?: GraphQLTypes["voyce_chapter_assets_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_chapter_assets_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_chapter_assets_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_chapter_assets_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_chapter_assets_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_chapter_assets_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_chapter_assets_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_assets" */
["voyce_chapter_assets_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_chapter_assets_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_assets_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_assets_avg_fields"]: {
	__typename: "voyce_chapter_assets_avg_fields",
	chapter_effect_id?: number,
	id?: number,
	order?: number
};
	/** order by avg() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_avg_order_by"]: {
		chapter_effect_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_assets". All fields are combined with a logical 'AND'. */
["voyce_chapter_assets_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_assets_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_assets_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_assets_bool_exp"]>,
	chapter_effect?: GraphQLTypes["voyce_chapter_effects_bool_exp"],
	chapter_effect_id?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	order?: GraphQLTypes["Int_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"],
	url?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_assets" */
["voyce_chapter_assets_constraint"]: voyce_chapter_assets_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_assets" */
["voyce_chapter_assets_inc_input"]: {
		chapter_effect_id?: number,
	id?: number,
	order?: number
};
	/** input type for inserting data into table "voyce.chapter_assets" */
["voyce_chapter_assets_insert_input"]: {
		chapter_effect?: GraphQLTypes["voyce_chapter_effects_obj_rel_insert_input"],
	chapter_effect_id?: number,
	id?: number,
	order?: number,
	type?: string,
	url?: string
};
	/** aggregate max on columns */
["voyce_chapter_assets_max_fields"]: {
	__typename: "voyce_chapter_assets_max_fields",
	chapter_effect_id?: number,
	id?: number,
	order?: number,
	type?: string,
	url?: string
};
	/** order by max() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_max_order_by"]: {
		chapter_effect_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	url?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_assets_min_fields"]: {
	__typename: "voyce_chapter_assets_min_fields",
	chapter_effect_id?: number,
	id?: number,
	order?: number,
	type?: string,
	url?: string
};
	/** order by min() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_min_order_by"]: {
		chapter_effect_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	url?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_assets" */
["voyce_chapter_assets_mutation_response"]: {
	__typename: "voyce_chapter_assets_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_assets"]>
};
	/** on_conflict condition type for table "voyce.chapter_assets" */
["voyce_chapter_assets_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_assets_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_assets_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_assets_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_assets". */
["voyce_chapter_assets_order_by"]: {
		chapter_effect?: GraphQLTypes["voyce_chapter_effects_order_by"],
	chapter_effect_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	url?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_assets */
["voyce_chapter_assets_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_select_column"]: voyce_chapter_assets_select_column;
	/** input type for updating data in table "voyce.chapter_assets" */
["voyce_chapter_assets_set_input"]: {
		chapter_effect_id?: number,
	id?: number,
	order?: number,
	type?: string,
	url?: string
};
	/** aggregate stddev on columns */
["voyce_chapter_assets_stddev_fields"]: {
	__typename: "voyce_chapter_assets_stddev_fields",
	chapter_effect_id?: number,
	id?: number,
	order?: number
};
	/** order by stddev() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_stddev_order_by"]: {
		chapter_effect_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_assets_stddev_pop_fields"]: {
	__typename: "voyce_chapter_assets_stddev_pop_fields",
	chapter_effect_id?: number,
	id?: number,
	order?: number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_stddev_pop_order_by"]: {
		chapter_effect_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_assets_stddev_samp_fields"]: {
	__typename: "voyce_chapter_assets_stddev_samp_fields",
	chapter_effect_id?: number,
	id?: number,
	order?: number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_stddev_samp_order_by"]: {
		chapter_effect_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_assets" */
["voyce_chapter_assets_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_assets_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_assets_stream_cursor_value_input"]: {
		chapter_effect_id?: number,
	id?: number,
	order?: number,
	type?: string,
	url?: string
};
	/** aggregate sum on columns */
["voyce_chapter_assets_sum_fields"]: {
	__typename: "voyce_chapter_assets_sum_fields",
	chapter_effect_id?: number,
	id?: number,
	order?: number
};
	/** order by sum() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_sum_order_by"]: {
		chapter_effect_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_update_column"]: voyce_chapter_assets_update_column;
	["voyce_chapter_assets_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_assets_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_assets_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_assets_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_assets_var_pop_fields"]: {
	__typename: "voyce_chapter_assets_var_pop_fields",
	chapter_effect_id?: number,
	id?: number,
	order?: number
};
	/** order by var_pop() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_var_pop_order_by"]: {
		chapter_effect_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_assets_var_samp_fields"]: {
	__typename: "voyce_chapter_assets_var_samp_fields",
	chapter_effect_id?: number,
	id?: number,
	order?: number
};
	/** order by var_samp() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_var_samp_order_by"]: {
		chapter_effect_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_assets_variance_fields"]: {
	__typename: "voyce_chapter_assets_variance_fields",
	chapter_effect_id?: number,
	id?: number,
	order?: number
};
	/** order by variance() on columns of table "voyce.chapter_assets" */
["voyce_chapter_assets_variance_order_by"]: {
		chapter_effect_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks"]: {
	__typename: "voyce_chapter_bookmarks",
	chapter_id: number,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	/** An object relationship */
	series: GraphQLTypes["voyce_series"],
	series_id: number,
	user_id: number
};
	/** aggregated selection of "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_aggregate"]: {
	__typename: "voyce_chapter_bookmarks_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_bookmarks_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_bookmarks"]>
};
	/** aggregate fields of "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_aggregate_fields"]: {
	__typename: "voyce_chapter_bookmarks_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_bookmarks_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_bookmarks_max_fields"],
	min?: GraphQLTypes["voyce_chapter_bookmarks_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_bookmarks_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_bookmarks_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_bookmarks_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_bookmarks_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_bookmarks_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_bookmarks_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_bookmarks_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapter_bookmarks_avg_fields"]: {
	__typename: "voyce_chapter_bookmarks_avg_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.chapter_bookmarks". All fields are combined with a logical 'AND'. */
["voyce_chapter_bookmarks_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_bookmarks_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_bookmarks_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_bookmarks_bool_exp"]>,
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_constraint"]: voyce_chapter_bookmarks_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_inc_input"]: {
		chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_insert_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	user_id?: number
};
	/** aggregate max on columns */
["voyce_chapter_bookmarks_max_fields"]: {
	__typename: "voyce_chapter_bookmarks_max_fields",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_chapter_bookmarks_min_fields"]: {
	__typename: "voyce_chapter_bookmarks_min_fields",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** response of any mutation on the table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_mutation_response"]: {
	__typename: "voyce_chapter_bookmarks_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_bookmarks"]>
};
	/** on_conflict condition type for table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_bookmarks_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_bookmarks_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_bookmarks_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_bookmarks". */
["voyce_chapter_bookmarks_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_bookmarks */
["voyce_chapter_bookmarks_pk_columns_input"]: {
		chapter_id: number,
	user_id: number
};
	/** select columns of table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_select_column"]: voyce_chapter_bookmarks_select_column;
	/** input type for updating data in table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_set_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_chapter_bookmarks_stddev_fields"]: {
	__typename: "voyce_chapter_bookmarks_stddev_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_bookmarks_stddev_pop_fields"]: {
	__typename: "voyce_chapter_bookmarks_stddev_pop_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_bookmarks_stddev_samp_fields"]: {
	__typename: "voyce_chapter_bookmarks_stddev_samp_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_chapter_bookmarks" */
["voyce_chapter_bookmarks_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_bookmarks_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_bookmarks_stream_cursor_value_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_chapter_bookmarks_sum_fields"]: {
	__typename: "voyce_chapter_bookmarks_sum_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** update columns of table "voyce.chapter_bookmarks" */
["voyce_chapter_bookmarks_update_column"]: voyce_chapter_bookmarks_update_column;
	["voyce_chapter_bookmarks_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_bookmarks_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_bookmarks_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_bookmarks_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_bookmarks_var_pop_fields"]: {
	__typename: "voyce_chapter_bookmarks_var_pop_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_chapter_bookmarks_var_samp_fields"]: {
	__typename: "voyce_chapter_bookmarks_var_samp_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_chapter_bookmarks_variance_fields"]: {
	__typename: "voyce_chapter_bookmarks_variance_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.chapter_comments" */
["voyce_chapter_comments"]: {
	__typename: "voyce_chapter_comments",
	animated_image?: string,
	attachment_url?: string,
	/** An object relationship */
	chapter?: GraphQLTypes["voyce_chapters"],
	/** An object relationship */
	chapter_comment?: GraphQLTypes["voyce_chapter_comments"],
	/** An array relationship */
	chapter_comments: Array<GraphQLTypes["voyce_chapter_comments"]>,
	/** An aggregate relationship */
	chapter_comments_aggregate: GraphQLTypes["voyce_chapter_comments_aggregate"],
	/** An array relationship */
	chapter_comments_reactions: Array<GraphQLTypes["voyce_chapter_comments_reaction"]>,
	/** An aggregate relationship */
	chapter_comments_reactions_aggregate: GraphQLTypes["voyce_chapter_comments_reaction_aggregate"],
	/** An array relationship */
	chapter_comments_reports: Array<GraphQLTypes["voyce_chapter_comments_reports"]>,
	/** An aggregate relationship */
	chapter_comments_reports_aggregate: GraphQLTypes["voyce_chapter_comments_reports_aggregate"],
	chapter_id?: number,
	comment: string,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	/** An array relationship */
	reactions: Array<GraphQLTypes["voyce_chapter_comments_reaction_count"]>,
	/** An aggregate relationship */
	reactions_aggregate: GraphQLTypes["voyce_chapter_comments_reaction_count_aggregate"],
	replies_count?: number,
	/** An object relationship */
	series?: GraphQLTypes["voyce_series"],
	series_id?: number,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** columns and relationships of "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024"]: {
	__typename: "voyce_chapter_comments_07252024",
	animated_image?: string,
	attachment_url?: string,
	chapter_id?: number,
	comment?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregated selection of "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_aggregate"]: {
	__typename: "voyce_chapter_comments_07252024_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_comments_07252024_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_comments_07252024"]>
};
	/** aggregate fields of "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_aggregate_fields"]: {
	__typename: "voyce_chapter_comments_07252024_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_comments_07252024_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_comments_07252024_max_fields"],
	min?: GraphQLTypes["voyce_chapter_comments_07252024_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_comments_07252024_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_comments_07252024_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_comments_07252024_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_comments_07252024_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_comments_07252024_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_comments_07252024_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_comments_07252024_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapter_comments_07252024_avg_fields"]: {
	__typename: "voyce_chapter_comments_07252024_avg_fields",
	chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.chapter_comments_07252024". All fields are combined with a logical 'AND'. */
["voyce_chapter_comments_07252024_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_comments_07252024_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_comments_07252024_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_comments_07252024_bool_exp"]>,
	animated_image?: GraphQLTypes["String_comparison_exp"],
	attachment_url?: GraphQLTypes["String_comparison_exp"],
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	comment?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	last_reacted_at?: GraphQLTypes["timestamptz_comparison_exp"],
	last_reaction_count?: GraphQLTypes["Int_comparison_exp"],
	original_comment_id?: GraphQLTypes["Int_comparison_exp"],
	reaction_count?: GraphQLTypes["Int_comparison_exp"],
	replies_count?: GraphQLTypes["Int_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** input type for incrementing numeric columns in table "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_inc_input"]: {
		chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_insert_input"]: {
		animated_image?: string,
	attachment_url?: string,
	chapter_id?: number,
	comment?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate max on columns */
["voyce_chapter_comments_07252024_max_fields"]: {
	__typename: "voyce_chapter_comments_07252024_max_fields",
	animated_image?: string,
	attachment_url?: string,
	chapter_id?: number,
	comment?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_chapter_comments_07252024_min_fields"]: {
	__typename: "voyce_chapter_comments_07252024_min_fields",
	animated_image?: string,
	attachment_url?: string,
	chapter_id?: number,
	comment?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** response of any mutation on the table "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_mutation_response"]: {
	__typename: "voyce_chapter_comments_07252024_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_comments_07252024"]>
};
	/** Ordering options when selecting data from "voyce.chapter_comments_07252024". */
["voyce_chapter_comments_07252024_order_by"]: {
		animated_image?: GraphQLTypes["order_by"],
	attachment_url?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	comment?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_reacted_at?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	original_comment_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_select_column"]: voyce_chapter_comments_07252024_select_column;
	/** input type for updating data in table "voyce.chapter_comments_07252024" */
["voyce_chapter_comments_07252024_set_input"]: {
		animated_image?: string,
	attachment_url?: string,
	chapter_id?: number,
	comment?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_chapter_comments_07252024_stddev_fields"]: {
	__typename: "voyce_chapter_comments_07252024_stddev_fields",
	chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_comments_07252024_stddev_pop_fields"]: {
	__typename: "voyce_chapter_comments_07252024_stddev_pop_fields",
	chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_comments_07252024_stddev_samp_fields"]: {
	__typename: "voyce_chapter_comments_07252024_stddev_samp_fields",
	chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_chapter_comments_07252024" */
["voyce_chapter_comments_07252024_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_comments_07252024_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_comments_07252024_stream_cursor_value_input"]: {
		animated_image?: string,
	attachment_url?: string,
	chapter_id?: number,
	comment?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_chapter_comments_07252024_sum_fields"]: {
	__typename: "voyce_chapter_comments_07252024_sum_fields",
	chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	["voyce_chapter_comments_07252024_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_comments_07252024_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_comments_07252024_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_comments_07252024_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_comments_07252024_var_pop_fields"]: {
	__typename: "voyce_chapter_comments_07252024_var_pop_fields",
	chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_chapter_comments_07252024_var_samp_fields"]: {
	__typename: "voyce_chapter_comments_07252024_var_samp_fields",
	chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_chapter_comments_07252024_variance_fields"]: {
	__typename: "voyce_chapter_comments_07252024_variance_fields",
	chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregated selection of "voyce.chapter_comments" */
["voyce_chapter_comments_aggregate"]: {
	__typename: "voyce_chapter_comments_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_comments_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_comments"]>
};
	["voyce_chapter_comments_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_chapter_comments_aggregate_bool_exp_count"]
};
	["voyce_chapter_comments_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_chapter_comments_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_comments_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_comments" */
["voyce_chapter_comments_aggregate_fields"]: {
	__typename: "voyce_chapter_comments_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_comments_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_comments_max_fields"],
	min?: GraphQLTypes["voyce_chapter_comments_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_comments_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_comments_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_comments_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_comments_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_comments_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_comments_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_comments_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_comments" */
["voyce_chapter_comments_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_chapter_comments_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_chapter_comments_max_order_by"],
	min?: GraphQLTypes["voyce_chapter_comments_min_order_by"],
	stddev?: GraphQLTypes["voyce_chapter_comments_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_chapter_comments_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_chapter_comments_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_chapter_comments_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_chapter_comments_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_chapter_comments_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_chapter_comments_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_comments" */
["voyce_chapter_comments_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_chapter_comments_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_comments_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_comments_avg_fields"]: {
	__typename: "voyce_chapter_comments_avg_fields",
	chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_avg_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	original_comment_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_comments". All fields are combined with a logical 'AND'. */
["voyce_chapter_comments_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_comments_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_comments_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_comments_bool_exp"]>,
	animated_image?: GraphQLTypes["String_comparison_exp"],
	attachment_url?: GraphQLTypes["String_comparison_exp"],
	chapter?: GraphQLTypes["voyce_chapters_bool_exp"],
	chapter_comment?: GraphQLTypes["voyce_chapter_comments_bool_exp"],
	chapter_comments?: GraphQLTypes["voyce_chapter_comments_bool_exp"],
	chapter_comments_aggregate?: GraphQLTypes["voyce_chapter_comments_aggregate_bool_exp"],
	chapter_comments_reactions?: GraphQLTypes["voyce_chapter_comments_reaction_bool_exp"],
	chapter_comments_reactions_aggregate?: GraphQLTypes["voyce_chapter_comments_reaction_aggregate_bool_exp"],
	chapter_comments_reports?: GraphQLTypes["voyce_chapter_comments_reports_bool_exp"],
	chapter_comments_reports_aggregate?: GraphQLTypes["voyce_chapter_comments_reports_aggregate_bool_exp"],
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	comment?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	last_reacted_at?: GraphQLTypes["timestamptz_comparison_exp"],
	last_reaction_count?: GraphQLTypes["Int_comparison_exp"],
	original_comment_id?: GraphQLTypes["Int_comparison_exp"],
	reaction_count?: GraphQLTypes["Int_comparison_exp"],
	reactions?: GraphQLTypes["voyce_chapter_comments_reaction_count_bool_exp"],
	reactions_aggregate?: GraphQLTypes["voyce_chapter_comments_reaction_count_aggregate_bool_exp"],
	replies_count?: GraphQLTypes["Int_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_comments" */
["voyce_chapter_comments_constraint"]: voyce_chapter_comments_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_comments" */
["voyce_chapter_comments_inc_input"]: {
		chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.chapter_comments" */
["voyce_chapter_comments_insert_input"]: {
		animated_image?: string,
	attachment_url?: string,
	chapter?: GraphQLTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_comment?: GraphQLTypes["voyce_chapter_comments_obj_rel_insert_input"],
	chapter_comments?: GraphQLTypes["voyce_chapter_comments_arr_rel_insert_input"],
	chapter_comments_reactions?: GraphQLTypes["voyce_chapter_comments_reaction_arr_rel_insert_input"],
	chapter_comments_reports?: GraphQLTypes["voyce_chapter_comments_reports_arr_rel_insert_input"],
	chapter_id?: number,
	comment?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	reactions?: GraphQLTypes["voyce_chapter_comments_reaction_count_arr_rel_insert_input"],
	replies_count?: number,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_chapter_comments_max_fields"]: {
	__typename: "voyce_chapter_comments_max_fields",
	animated_image?: string,
	attachment_url?: string,
	chapter_id?: number,
	comment?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** order by max() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_max_order_by"]: {
		animated_image?: GraphQLTypes["order_by"],
	attachment_url?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	comment?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_reacted_at?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	original_comment_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_comments_min_fields"]: {
	__typename: "voyce_chapter_comments_min_fields",
	animated_image?: string,
	attachment_url?: string,
	chapter_id?: number,
	comment?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** order by min() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_min_order_by"]: {
		animated_image?: GraphQLTypes["order_by"],
	attachment_url?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	comment?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_reacted_at?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	original_comment_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_comments" */
["voyce_chapter_comments_mutation_response"]: {
	__typename: "voyce_chapter_comments_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_comments"]>
};
	/** input type for inserting object relation for remote table "voyce.chapter_comments" */
["voyce_chapter_comments_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_chapter_comments_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_comments_on_conflict"]
};
	/** on_conflict condition type for table "voyce.chapter_comments" */
["voyce_chapter_comments_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_comments_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_comments_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_comments_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_comments". */
["voyce_chapter_comments_order_by"]: {
		animated_image?: GraphQLTypes["order_by"],
	attachment_url?: GraphQLTypes["order_by"],
	chapter?: GraphQLTypes["voyce_chapters_order_by"],
	chapter_comment?: GraphQLTypes["voyce_chapter_comments_order_by"],
	chapter_comments_aggregate?: GraphQLTypes["voyce_chapter_comments_aggregate_order_by"],
	chapter_comments_reactions_aggregate?: GraphQLTypes["voyce_chapter_comments_reaction_aggregate_order_by"],
	chapter_comments_reports_aggregate?: GraphQLTypes["voyce_chapter_comments_reports_aggregate_order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	comment?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_reacted_at?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	original_comment_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	reactions_aggregate?: GraphQLTypes["voyce_chapter_comments_reaction_count_aggregate_order_by"],
	replies_count?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_comments */
["voyce_chapter_comments_pk_columns_input"]: {
		id: number
};
	/** columns and relationships of "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction"]: {
	__typename: "voyce_chapter_comments_reaction",
	/** An object relationship */
	chapter_comment: GraphQLTypes["voyce_chapter_comments"],
	comment_id: number,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	reaction: number,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_aggregate"]: {
	__typename: "voyce_chapter_comments_reaction_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_comments_reaction_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_comments_reaction"]>
};
	["voyce_chapter_comments_reaction_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_chapter_comments_reaction_aggregate_bool_exp_count"]
};
	["voyce_chapter_comments_reaction_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_chapter_comments_reaction_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_comments_reaction_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_aggregate_fields"]: {
	__typename: "voyce_chapter_comments_reaction_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_comments_reaction_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_comments_reaction_max_fields"],
	min?: GraphQLTypes["voyce_chapter_comments_reaction_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_comments_reaction_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_comments_reaction_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_comments_reaction_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_comments_reaction_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_comments_reaction_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_comments_reaction_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_comments_reaction_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_chapter_comments_reaction_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_chapter_comments_reaction_max_order_by"],
	min?: GraphQLTypes["voyce_chapter_comments_reaction_min_order_by"],
	stddev?: GraphQLTypes["voyce_chapter_comments_reaction_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_chapter_comments_reaction_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_chapter_comments_reaction_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_chapter_comments_reaction_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_chapter_comments_reaction_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_chapter_comments_reaction_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_chapter_comments_reaction_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_chapter_comments_reaction_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_comments_reaction_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_comments_reaction_avg_fields"]: {
	__typename: "voyce_chapter_comments_reaction_avg_fields",
	comment_id?: number,
	id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_avg_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_comments_reaction". All fields are combined with a logical 'AND'. */
["voyce_chapter_comments_reaction_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_comments_reaction_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_comments_reaction_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_comments_reaction_bool_exp"]>,
	chapter_comment?: GraphQLTypes["voyce_chapter_comments_bool_exp"],
	comment_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	reaction?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_constraint"]: voyce_chapter_comments_reaction_constraint;
	/** columns and relationships of "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count"]: {
	__typename: "voyce_chapter_comments_reaction_count",
	comment_id: number,
	count: number,
	id: number,
	reaction: number
};
	/** aggregated selection of "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_aggregate"]: {
	__typename: "voyce_chapter_comments_reaction_count_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_comments_reaction_count_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_comments_reaction_count"]>
};
	["voyce_chapter_comments_reaction_count_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_chapter_comments_reaction_count_aggregate_bool_exp_count"]
};
	["voyce_chapter_comments_reaction_count_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_chapter_comments_reaction_count_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_comments_reaction_count_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_aggregate_fields"]: {
	__typename: "voyce_chapter_comments_reaction_count_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_comments_reaction_count_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_comments_reaction_count_max_fields"],
	min?: GraphQLTypes["voyce_chapter_comments_reaction_count_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_comments_reaction_count_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_comments_reaction_count_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_comments_reaction_count_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_comments_reaction_count_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_comments_reaction_count_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_comments_reaction_count_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_comments_reaction_count_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_chapter_comments_reaction_count_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_chapter_comments_reaction_count_max_order_by"],
	min?: GraphQLTypes["voyce_chapter_comments_reaction_count_min_order_by"],
	stddev?: GraphQLTypes["voyce_chapter_comments_reaction_count_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_chapter_comments_reaction_count_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_chapter_comments_reaction_count_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_chapter_comments_reaction_count_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_chapter_comments_reaction_count_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_chapter_comments_reaction_count_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_chapter_comments_reaction_count_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_chapter_comments_reaction_count_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_comments_reaction_count_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_comments_reaction_count_avg_fields"]: {
	__typename: "voyce_chapter_comments_reaction_count_avg_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** order by avg() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_avg_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_comments_reaction_count". All fields are combined with a logical 'AND'. */
["voyce_chapter_comments_reaction_count_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_comments_reaction_count_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_comments_reaction_count_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_comments_reaction_count_bool_exp"]>,
	comment_id?: GraphQLTypes["Int_comparison_exp"],
	count?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	reaction?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_constraint"]: voyce_chapter_comments_reaction_count_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_inc_input"]: {
		comment_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** input type for inserting data into table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_insert_input"]: {
		comment_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** aggregate max on columns */
["voyce_chapter_comments_reaction_count_max_fields"]: {
	__typename: "voyce_chapter_comments_reaction_count_max_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** order by max() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_max_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_comments_reaction_count_min_fields"]: {
	__typename: "voyce_chapter_comments_reaction_count_min_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** order by min() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_min_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_mutation_response"]: {
	__typename: "voyce_chapter_comments_reaction_count_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_comments_reaction_count"]>
};
	/** on_conflict condition type for table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_comments_reaction_count_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_comments_reaction_count_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_comments_reaction_count_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_comments_reaction_count". */
["voyce_chapter_comments_reaction_count_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_comments_reaction_count */
["voyce_chapter_comments_reaction_count_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_select_column"]: voyce_chapter_comments_reaction_count_select_column;
	/** input type for updating data in table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_set_input"]: {
		comment_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** aggregate stddev on columns */
["voyce_chapter_comments_reaction_count_stddev_fields"]: {
	__typename: "voyce_chapter_comments_reaction_count_stddev_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** order by stddev() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_stddev_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_comments_reaction_count_stddev_pop_fields"]: {
	__typename: "voyce_chapter_comments_reaction_count_stddev_pop_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_stddev_pop_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_comments_reaction_count_stddev_samp_fields"]: {
	__typename: "voyce_chapter_comments_reaction_count_stddev_samp_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_stddev_samp_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_comments_reaction_count_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_comments_reaction_count_stream_cursor_value_input"]: {
		comment_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** aggregate sum on columns */
["voyce_chapter_comments_reaction_count_sum_fields"]: {
	__typename: "voyce_chapter_comments_reaction_count_sum_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** order by sum() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_sum_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_update_column"]: voyce_chapter_comments_reaction_count_update_column;
	["voyce_chapter_comments_reaction_count_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_comments_reaction_count_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_comments_reaction_count_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_comments_reaction_count_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_comments_reaction_count_var_pop_fields"]: {
	__typename: "voyce_chapter_comments_reaction_count_var_pop_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** order by var_pop() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_var_pop_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_comments_reaction_count_var_samp_fields"]: {
	__typename: "voyce_chapter_comments_reaction_count_var_samp_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** order by var_samp() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_var_samp_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_comments_reaction_count_variance_fields"]: {
	__typename: "voyce_chapter_comments_reaction_count_variance_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** order by variance() on columns of table "voyce.chapter_comments_reaction_count" */
["voyce_chapter_comments_reaction_count_variance_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** input type for incrementing numeric columns in table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_inc_input"]: {
		comment_id?: number,
	id?: number,
	reaction?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_insert_input"]: {
		chapter_comment?: GraphQLTypes["voyce_chapter_comments_obj_rel_insert_input"],
	comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	reaction?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_chapter_comments_reaction_max_fields"]: {
	__typename: "voyce_chapter_comments_reaction_max_fields",
	comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by max() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_max_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_comments_reaction_min_fields"]: {
	__typename: "voyce_chapter_comments_reaction_min_fields",
	comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by min() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_min_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_mutation_response"]: {
	__typename: "voyce_chapter_comments_reaction_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_comments_reaction"]>
};
	/** on_conflict condition type for table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_comments_reaction_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_comments_reaction_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_comments_reaction_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_comments_reaction". */
["voyce_chapter_comments_reaction_order_by"]: {
		chapter_comment?: GraphQLTypes["voyce_chapter_comments_order_by"],
	comment_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_comments_reaction */
["voyce_chapter_comments_reaction_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_select_column"]: voyce_chapter_comments_reaction_select_column;
	/** input type for updating data in table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_set_input"]: {
		comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	reaction?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_chapter_comments_reaction_stddev_fields"]: {
	__typename: "voyce_chapter_comments_reaction_stddev_fields",
	comment_id?: number,
	id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_stddev_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_comments_reaction_stddev_pop_fields"]: {
	__typename: "voyce_chapter_comments_reaction_stddev_pop_fields",
	comment_id?: number,
	id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_stddev_pop_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_comments_reaction_stddev_samp_fields"]: {
	__typename: "voyce_chapter_comments_reaction_stddev_samp_fields",
	comment_id?: number,
	id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_stddev_samp_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_comments_reaction" */
["voyce_chapter_comments_reaction_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_comments_reaction_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_comments_reaction_stream_cursor_value_input"]: {
		comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	reaction?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_chapter_comments_reaction_sum_fields"]: {
	__typename: "voyce_chapter_comments_reaction_sum_fields",
	comment_id?: number,
	id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_sum_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_update_column"]: voyce_chapter_comments_reaction_update_column;
	["voyce_chapter_comments_reaction_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_comments_reaction_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_comments_reaction_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_comments_reaction_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_comments_reaction_var_pop_fields"]: {
	__typename: "voyce_chapter_comments_reaction_var_pop_fields",
	comment_id?: number,
	id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_var_pop_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_comments_reaction_var_samp_fields"]: {
	__typename: "voyce_chapter_comments_reaction_var_samp_fields",
	comment_id?: number,
	id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_var_samp_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_comments_reaction_variance_fields"]: {
	__typename: "voyce_chapter_comments_reaction_variance_fields",
	comment_id?: number,
	id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.chapter_comments_reaction" */
["voyce_chapter_comments_reaction_variance_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports"]: {
	__typename: "voyce_chapter_comments_reports",
	/** An object relationship */
	chapter_comment: GraphQLTypes["voyce_chapter_comments"],
	comment_id: number,
	id: number,
	message?: string,
	type_id: number,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_aggregate"]: {
	__typename: "voyce_chapter_comments_reports_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_comments_reports_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_comments_reports"]>
};
	["voyce_chapter_comments_reports_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_chapter_comments_reports_aggregate_bool_exp_count"]
};
	["voyce_chapter_comments_reports_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_chapter_comments_reports_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_comments_reports_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_aggregate_fields"]: {
	__typename: "voyce_chapter_comments_reports_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_comments_reports_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_comments_reports_max_fields"],
	min?: GraphQLTypes["voyce_chapter_comments_reports_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_comments_reports_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_comments_reports_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_comments_reports_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_comments_reports_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_comments_reports_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_comments_reports_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_comments_reports_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_chapter_comments_reports_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_chapter_comments_reports_max_order_by"],
	min?: GraphQLTypes["voyce_chapter_comments_reports_min_order_by"],
	stddev?: GraphQLTypes["voyce_chapter_comments_reports_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_chapter_comments_reports_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_chapter_comments_reports_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_chapter_comments_reports_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_chapter_comments_reports_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_chapter_comments_reports_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_chapter_comments_reports_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_chapter_comments_reports_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_comments_reports_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_comments_reports_avg_fields"]: {
	__typename: "voyce_chapter_comments_reports_avg_fields",
	comment_id?: number,
	id?: number,
	type_id?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_avg_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	type_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_comments_reports". All fields are combined with a logical 'AND'. */
["voyce_chapter_comments_reports_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_comments_reports_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_comments_reports_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_comments_reports_bool_exp"]>,
	chapter_comment?: GraphQLTypes["voyce_chapter_comments_bool_exp"],
	comment_id?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	message?: GraphQLTypes["String_comparison_exp"],
	type_id?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_constraint"]: voyce_chapter_comments_reports_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_inc_input"]: {
		comment_id?: number,
	id?: number,
	type_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_insert_input"]: {
		chapter_comment?: GraphQLTypes["voyce_chapter_comments_obj_rel_insert_input"],
	comment_id?: number,
	id?: number,
	message?: string,
	type_id?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_chapter_comments_reports_max_fields"]: {
	__typename: "voyce_chapter_comments_reports_max_fields",
	comment_id?: number,
	id?: number,
	message?: string,
	type_id?: number,
	user_id?: number
};
	/** order by max() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_max_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	message?: GraphQLTypes["order_by"],
	type_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_comments_reports_min_fields"]: {
	__typename: "voyce_chapter_comments_reports_min_fields",
	comment_id?: number,
	id?: number,
	message?: string,
	type_id?: number,
	user_id?: number
};
	/** order by min() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_min_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	message?: GraphQLTypes["order_by"],
	type_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_mutation_response"]: {
	__typename: "voyce_chapter_comments_reports_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_comments_reports"]>
};
	/** on_conflict condition type for table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_comments_reports_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_comments_reports_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_comments_reports_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_comments_reports". */
["voyce_chapter_comments_reports_order_by"]: {
		chapter_comment?: GraphQLTypes["voyce_chapter_comments_order_by"],
	comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	message?: GraphQLTypes["order_by"],
	type_id?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_comments_reports */
["voyce_chapter_comments_reports_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_select_column"]: voyce_chapter_comments_reports_select_column;
	/** input type for updating data in table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_set_input"]: {
		comment_id?: number,
	id?: number,
	message?: string,
	type_id?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_chapter_comments_reports_stddev_fields"]: {
	__typename: "voyce_chapter_comments_reports_stddev_fields",
	comment_id?: number,
	id?: number,
	type_id?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_stddev_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	type_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_comments_reports_stddev_pop_fields"]: {
	__typename: "voyce_chapter_comments_reports_stddev_pop_fields",
	comment_id?: number,
	id?: number,
	type_id?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_stddev_pop_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	type_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_comments_reports_stddev_samp_fields"]: {
	__typename: "voyce_chapter_comments_reports_stddev_samp_fields",
	comment_id?: number,
	id?: number,
	type_id?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_stddev_samp_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	type_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_comments_reports" */
["voyce_chapter_comments_reports_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_comments_reports_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_comments_reports_stream_cursor_value_input"]: {
		comment_id?: number,
	id?: number,
	message?: string,
	type_id?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_chapter_comments_reports_sum_fields"]: {
	__typename: "voyce_chapter_comments_reports_sum_fields",
	comment_id?: number,
	id?: number,
	type_id?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_sum_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	type_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_update_column"]: voyce_chapter_comments_reports_update_column;
	["voyce_chapter_comments_reports_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_comments_reports_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_comments_reports_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_comments_reports_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_comments_reports_var_pop_fields"]: {
	__typename: "voyce_chapter_comments_reports_var_pop_fields",
	comment_id?: number,
	id?: number,
	type_id?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_var_pop_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	type_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_comments_reports_var_samp_fields"]: {
	__typename: "voyce_chapter_comments_reports_var_samp_fields",
	comment_id?: number,
	id?: number,
	type_id?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_var_samp_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	type_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_comments_reports_variance_fields"]: {
	__typename: "voyce_chapter_comments_reports_variance_fields",
	comment_id?: number,
	id?: number,
	type_id?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.chapter_comments_reports" */
["voyce_chapter_comments_reports_variance_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	type_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_select_column"]: voyce_chapter_comments_select_column;
	/** input type for updating data in table "voyce.chapter_comments" */
["voyce_chapter_comments_set_input"]: {
		animated_image?: string,
	attachment_url?: string,
	chapter_id?: number,
	comment?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_chapter_comments_stddev_fields"]: {
	__typename: "voyce_chapter_comments_stddev_fields",
	chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_stddev_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	original_comment_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_comments_stddev_pop_fields"]: {
	__typename: "voyce_chapter_comments_stddev_pop_fields",
	chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_stddev_pop_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	original_comment_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_comments_stddev_samp_fields"]: {
	__typename: "voyce_chapter_comments_stddev_samp_fields",
	chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_stddev_samp_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	original_comment_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_comments" */
["voyce_chapter_comments_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_comments_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_comments_stream_cursor_value_input"]: {
		animated_image?: string,
	attachment_url?: string,
	chapter_id?: number,
	comment?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_chapter_comments_sum_fields"]: {
	__typename: "voyce_chapter_comments_sum_fields",
	chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_sum_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	original_comment_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_update_column"]: voyce_chapter_comments_update_column;
	["voyce_chapter_comments_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_comments_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_comments_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_comments_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_comments_var_pop_fields"]: {
	__typename: "voyce_chapter_comments_var_pop_fields",
	chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_var_pop_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	original_comment_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_comments_var_samp_fields"]: {
	__typename: "voyce_chapter_comments_var_samp_fields",
	chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_var_samp_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	original_comment_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_comments_variance_fields"]: {
	__typename: "voyce_chapter_comments_variance_fields",
	chapter_id?: number,
	id?: number,
	last_reaction_count?: number,
	original_comment_id?: number,
	reaction_count?: number,
	replies_count?: number,
	series_id?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.chapter_comments" */
["voyce_chapter_comments_variance_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	original_comment_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** series novel content for each chapter */
["voyce_chapter_contents"]: {
	__typename: "voyce_chapter_contents",
	/** An object relationship */
	chapter: GraphQLTypes["voyce_chapters"],
	chapter_id: number,
	content: string,
	content_short?: string,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	mobile_content?: string,
	mobile_content_old?: string,
	order: number,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.chapter_contents" */
["voyce_chapter_contents_aggregate"]: {
	__typename: "voyce_chapter_contents_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_contents_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_contents"]>
};
	/** aggregate fields of "voyce.chapter_contents" */
["voyce_chapter_contents_aggregate_fields"]: {
	__typename: "voyce_chapter_contents_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_contents_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_contents_max_fields"],
	min?: GraphQLTypes["voyce_chapter_contents_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_contents_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_contents_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_contents_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_contents_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_contents_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_contents_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_contents_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapter_contents_avg_fields"]: {
	__typename: "voyce_chapter_contents_avg_fields",
	chapter_id?: number,
	id?: number,
	order?: number
};
	/** Boolean expression to filter rows from the table "voyce.chapter_contents". All fields are combined with a logical 'AND'. */
["voyce_chapter_contents_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_contents_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_contents_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_contents_bool_exp"]>,
	chapter?: GraphQLTypes["voyce_chapters_bool_exp"],
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	content?: GraphQLTypes["String_comparison_exp"],
	content_short?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	mobile_content?: GraphQLTypes["String_comparison_exp"],
	mobile_content_old?: GraphQLTypes["String_comparison_exp"],
	order?: GraphQLTypes["Int_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_contents" */
["voyce_chapter_contents_constraint"]: voyce_chapter_contents_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_contents" */
["voyce_chapter_contents_inc_input"]: {
		chapter_id?: number,
	id?: number,
	order?: number
};
	/** input type for inserting data into table "voyce.chapter_contents" */
["voyce_chapter_contents_insert_input"]: {
		chapter?: GraphQLTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?: number,
	content?: string,
	content_short?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	mobile_content?: string,
	mobile_content_old?: string,
	order?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_chapter_contents_max_fields"]: {
	__typename: "voyce_chapter_contents_max_fields",
	chapter_id?: number,
	content?: string,
	content_short?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	mobile_content?: string,
	mobile_content_old?: string,
	order?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_chapter_contents_min_fields"]: {
	__typename: "voyce_chapter_contents_min_fields",
	chapter_id?: number,
	content?: string,
	content_short?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	mobile_content?: string,
	mobile_content_old?: string,
	order?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.chapter_contents" */
["voyce_chapter_contents_mutation_response"]: {
	__typename: "voyce_chapter_contents_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_contents"]>
};
	/** input type for inserting object relation for remote table "voyce.chapter_contents" */
["voyce_chapter_contents_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_chapter_contents_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_contents_on_conflict"]
};
	/** columns and relationships of "voyce.chapter_contents_old" */
["voyce_chapter_contents_old"]: {
	__typename: "voyce_chapter_contents_old",
	/** An object relationship */
	chapter: GraphQLTypes["voyce_chapters"],
	chapter_id: number,
	content: string,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	mobile_content?: string,
	mobile_content_old?: string,
	order: number,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_aggregate"]: {
	__typename: "voyce_chapter_contents_old_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_contents_old_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_contents_old"]>
};
	/** aggregate fields of "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_aggregate_fields"]: {
	__typename: "voyce_chapter_contents_old_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_contents_old_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_contents_old_max_fields"],
	min?: GraphQLTypes["voyce_chapter_contents_old_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_contents_old_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_contents_old_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_contents_old_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_contents_old_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_contents_old_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_contents_old_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_contents_old_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapter_contents_old_avg_fields"]: {
	__typename: "voyce_chapter_contents_old_avg_fields",
	chapter_id?: number,
	id?: number,
	order?: number
};
	/** Boolean expression to filter rows from the table "voyce.chapter_contents_old". All fields are combined with a logical 'AND'. */
["voyce_chapter_contents_old_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_contents_old_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_contents_old_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_contents_old_bool_exp"]>,
	chapter?: GraphQLTypes["voyce_chapters_bool_exp"],
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	content?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	mobile_content?: GraphQLTypes["String_comparison_exp"],
	mobile_content_old?: GraphQLTypes["String_comparison_exp"],
	order?: GraphQLTypes["Int_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_constraint"]: voyce_chapter_contents_old_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_inc_input"]: {
		chapter_id?: number,
	id?: number,
	order?: number
};
	/** input type for inserting data into table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_insert_input"]: {
		chapter?: GraphQLTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?: number,
	content?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	mobile_content?: string,
	mobile_content_old?: string,
	order?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_chapter_contents_old_max_fields"]: {
	__typename: "voyce_chapter_contents_old_max_fields",
	chapter_id?: number,
	content?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	mobile_content?: string,
	mobile_content_old?: string,
	order?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_chapter_contents_old_min_fields"]: {
	__typename: "voyce_chapter_contents_old_min_fields",
	chapter_id?: number,
	content?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	mobile_content?: string,
	mobile_content_old?: string,
	order?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_mutation_response"]: {
	__typename: "voyce_chapter_contents_old_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_contents_old"]>
};
	/** on_conflict condition type for table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_contents_old_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_contents_old_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_contents_old_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_contents_old". */
["voyce_chapter_contents_old_order_by"]: {
		chapter?: GraphQLTypes["voyce_chapters_order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	content?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	mobile_content?: GraphQLTypes["order_by"],
	mobile_content_old?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_contents_old */
["voyce_chapter_contents_old_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_select_column"]: voyce_chapter_contents_old_select_column;
	/** input type for updating data in table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_set_input"]: {
		chapter_id?: number,
	content?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	mobile_content?: string,
	mobile_content_old?: string,
	order?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_chapter_contents_old_stddev_fields"]: {
	__typename: "voyce_chapter_contents_old_stddev_fields",
	chapter_id?: number,
	id?: number,
	order?: number
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_contents_old_stddev_pop_fields"]: {
	__typename: "voyce_chapter_contents_old_stddev_pop_fields",
	chapter_id?: number,
	id?: number,
	order?: number
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_contents_old_stddev_samp_fields"]: {
	__typename: "voyce_chapter_contents_old_stddev_samp_fields",
	chapter_id?: number,
	id?: number,
	order?: number
};
	/** Streaming cursor of the table "voyce_chapter_contents_old" */
["voyce_chapter_contents_old_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_contents_old_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_contents_old_stream_cursor_value_input"]: {
		chapter_id?: number,
	content?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	mobile_content?: string,
	mobile_content_old?: string,
	order?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_chapter_contents_old_sum_fields"]: {
	__typename: "voyce_chapter_contents_old_sum_fields",
	chapter_id?: number,
	id?: number,
	order?: number
};
	/** update columns of table "voyce.chapter_contents_old" */
["voyce_chapter_contents_old_update_column"]: voyce_chapter_contents_old_update_column;
	["voyce_chapter_contents_old_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_contents_old_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_contents_old_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_contents_old_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_contents_old_var_pop_fields"]: {
	__typename: "voyce_chapter_contents_old_var_pop_fields",
	chapter_id?: number,
	id?: number,
	order?: number
};
	/** aggregate var_samp on columns */
["voyce_chapter_contents_old_var_samp_fields"]: {
	__typename: "voyce_chapter_contents_old_var_samp_fields",
	chapter_id?: number,
	id?: number,
	order?: number
};
	/** aggregate variance on columns */
["voyce_chapter_contents_old_variance_fields"]: {
	__typename: "voyce_chapter_contents_old_variance_fields",
	chapter_id?: number,
	id?: number,
	order?: number
};
	/** on_conflict condition type for table "voyce.chapter_contents" */
["voyce_chapter_contents_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_contents_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_contents_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_contents_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_contents". */
["voyce_chapter_contents_order_by"]: {
		chapter?: GraphQLTypes["voyce_chapters_order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	content?: GraphQLTypes["order_by"],
	content_short?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	mobile_content?: GraphQLTypes["order_by"],
	mobile_content_old?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_contents */
["voyce_chapter_contents_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapter_contents" */
["voyce_chapter_contents_select_column"]: voyce_chapter_contents_select_column;
	/** input type for updating data in table "voyce.chapter_contents" */
["voyce_chapter_contents_set_input"]: {
		chapter_id?: number,
	content?: string,
	content_short?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	mobile_content?: string,
	mobile_content_old?: string,
	order?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_chapter_contents_stddev_fields"]: {
	__typename: "voyce_chapter_contents_stddev_fields",
	chapter_id?: number,
	id?: number,
	order?: number
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_contents_stddev_pop_fields"]: {
	__typename: "voyce_chapter_contents_stddev_pop_fields",
	chapter_id?: number,
	id?: number,
	order?: number
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_contents_stddev_samp_fields"]: {
	__typename: "voyce_chapter_contents_stddev_samp_fields",
	chapter_id?: number,
	id?: number,
	order?: number
};
	/** Streaming cursor of the table "voyce_chapter_contents" */
["voyce_chapter_contents_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_contents_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_contents_stream_cursor_value_input"]: {
		chapter_id?: number,
	content?: string,
	content_short?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	mobile_content?: string,
	mobile_content_old?: string,
	order?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_chapter_contents_sum_fields"]: {
	__typename: "voyce_chapter_contents_sum_fields",
	chapter_id?: number,
	id?: number,
	order?: number
};
	/** update columns of table "voyce.chapter_contents" */
["voyce_chapter_contents_update_column"]: voyce_chapter_contents_update_column;
	["voyce_chapter_contents_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_contents_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_contents_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_contents_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_contents_var_pop_fields"]: {
	__typename: "voyce_chapter_contents_var_pop_fields",
	chapter_id?: number,
	id?: number,
	order?: number
};
	/** aggregate var_samp on columns */
["voyce_chapter_contents_var_samp_fields"]: {
	__typename: "voyce_chapter_contents_var_samp_fields",
	chapter_id?: number,
	id?: number,
	order?: number
};
	/** aggregate variance on columns */
["voyce_chapter_contents_variance_fields"]: {
	__typename: "voyce_chapter_contents_variance_fields",
	chapter_id?: number,
	id?: number,
	order?: number
};
	/** To match scroll speed: duration = end_x || end_y */
["voyce_chapter_effects"]: {
	__typename: "voyce_chapter_effects",
	animation_offset?: number,
	/** An object relationship */
	chapter: GraphQLTypes["voyce_chapters"],
	/** An array relationship */
	chapter_assets: Array<GraphQLTypes["voyce_chapter_assets"]>,
	/** An aggregate relationship */
	chapter_assets_aggregate: GraphQLTypes["voyce_chapter_assets_aggregate"],
	chapter_id: number,
	/** An object relationship */
	chapter_image?: GraphQLTypes["voyce_chapter_images"],
	chapter_img_id?: number,
	duration?: number,
	effect_type: number,
	end_x?: number,
	end_y?: number,
	id: number,
	strength?: number
};
	/** aggregated selection of "voyce.chapter_effects" */
["voyce_chapter_effects_aggregate"]: {
	__typename: "voyce_chapter_effects_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_effects_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_effects"]>
};
	["voyce_chapter_effects_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_chapter_effects_aggregate_bool_exp_count"]
};
	["voyce_chapter_effects_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_chapter_effects_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_effects_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_effects" */
["voyce_chapter_effects_aggregate_fields"]: {
	__typename: "voyce_chapter_effects_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_effects_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_effects_max_fields"],
	min?: GraphQLTypes["voyce_chapter_effects_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_effects_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_effects_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_effects_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_effects_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_effects_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_effects_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_effects_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_effects" */
["voyce_chapter_effects_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_chapter_effects_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_chapter_effects_max_order_by"],
	min?: GraphQLTypes["voyce_chapter_effects_min_order_by"],
	stddev?: GraphQLTypes["voyce_chapter_effects_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_chapter_effects_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_chapter_effects_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_chapter_effects_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_chapter_effects_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_chapter_effects_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_chapter_effects_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_effects" */
["voyce_chapter_effects_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_chapter_effects_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_effects_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_effects_avg_fields"]: {
	__typename: "voyce_chapter_effects_avg_fields",
	animation_offset?: number,
	chapter_id?: number,
	chapter_img_id?: number,
	duration?: number,
	effect_type?: number,
	end_x?: number,
	end_y?: number,
	id?: number,
	strength?: number
};
	/** order by avg() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_avg_order_by"]: {
		animation_offset?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	chapter_img_id?: GraphQLTypes["order_by"],
	duration?: GraphQLTypes["order_by"],
	effect_type?: GraphQLTypes["order_by"],
	end_x?: GraphQLTypes["order_by"],
	end_y?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	strength?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_effects". All fields are combined with a logical 'AND'. */
["voyce_chapter_effects_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_effects_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_effects_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_effects_bool_exp"]>,
	animation_offset?: GraphQLTypes["Int_comparison_exp"],
	chapter?: GraphQLTypes["voyce_chapters_bool_exp"],
	chapter_assets?: GraphQLTypes["voyce_chapter_assets_bool_exp"],
	chapter_assets_aggregate?: GraphQLTypes["voyce_chapter_assets_aggregate_bool_exp"],
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	chapter_image?: GraphQLTypes["voyce_chapter_images_bool_exp"],
	chapter_img_id?: GraphQLTypes["Int_comparison_exp"],
	duration?: GraphQLTypes["Int_comparison_exp"],
	effect_type?: GraphQLTypes["Int_comparison_exp"],
	end_x?: GraphQLTypes["Int_comparison_exp"],
	end_y?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	strength?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_effects" */
["voyce_chapter_effects_constraint"]: voyce_chapter_effects_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_effects" */
["voyce_chapter_effects_inc_input"]: {
		animation_offset?: number,
	chapter_id?: number,
	chapter_img_id?: number,
	duration?: number,
	effect_type?: number,
	end_x?: number,
	end_y?: number,
	id?: number,
	strength?: number
};
	/** input type for inserting data into table "voyce.chapter_effects" */
["voyce_chapter_effects_insert_input"]: {
		animation_offset?: number,
	chapter?: GraphQLTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_assets?: GraphQLTypes["voyce_chapter_assets_arr_rel_insert_input"],
	chapter_id?: number,
	chapter_image?: GraphQLTypes["voyce_chapter_images_obj_rel_insert_input"],
	chapter_img_id?: number,
	duration?: number,
	effect_type?: number,
	end_x?: number,
	end_y?: number,
	id?: number,
	strength?: number
};
	/** aggregate max on columns */
["voyce_chapter_effects_max_fields"]: {
	__typename: "voyce_chapter_effects_max_fields",
	animation_offset?: number,
	chapter_id?: number,
	chapter_img_id?: number,
	duration?: number,
	effect_type?: number,
	end_x?: number,
	end_y?: number,
	id?: number,
	strength?: number
};
	/** order by max() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_max_order_by"]: {
		animation_offset?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	chapter_img_id?: GraphQLTypes["order_by"],
	duration?: GraphQLTypes["order_by"],
	effect_type?: GraphQLTypes["order_by"],
	end_x?: GraphQLTypes["order_by"],
	end_y?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	strength?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_effects_min_fields"]: {
	__typename: "voyce_chapter_effects_min_fields",
	animation_offset?: number,
	chapter_id?: number,
	chapter_img_id?: number,
	duration?: number,
	effect_type?: number,
	end_x?: number,
	end_y?: number,
	id?: number,
	strength?: number
};
	/** order by min() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_min_order_by"]: {
		animation_offset?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	chapter_img_id?: GraphQLTypes["order_by"],
	duration?: GraphQLTypes["order_by"],
	effect_type?: GraphQLTypes["order_by"],
	end_x?: GraphQLTypes["order_by"],
	end_y?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	strength?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_effects" */
["voyce_chapter_effects_mutation_response"]: {
	__typename: "voyce_chapter_effects_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_effects"]>
};
	/** input type for inserting object relation for remote table "voyce.chapter_effects" */
["voyce_chapter_effects_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_chapter_effects_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_effects_on_conflict"]
};
	/** on_conflict condition type for table "voyce.chapter_effects" */
["voyce_chapter_effects_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_effects_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_effects_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_effects_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_effects". */
["voyce_chapter_effects_order_by"]: {
		animation_offset?: GraphQLTypes["order_by"],
	chapter?: GraphQLTypes["voyce_chapters_order_by"],
	chapter_assets_aggregate?: GraphQLTypes["voyce_chapter_assets_aggregate_order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	chapter_image?: GraphQLTypes["voyce_chapter_images_order_by"],
	chapter_img_id?: GraphQLTypes["order_by"],
	duration?: GraphQLTypes["order_by"],
	effect_type?: GraphQLTypes["order_by"],
	end_x?: GraphQLTypes["order_by"],
	end_y?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	strength?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_effects */
["voyce_chapter_effects_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_select_column"]: voyce_chapter_effects_select_column;
	/** input type for updating data in table "voyce.chapter_effects" */
["voyce_chapter_effects_set_input"]: {
		animation_offset?: number,
	chapter_id?: number,
	chapter_img_id?: number,
	duration?: number,
	effect_type?: number,
	end_x?: number,
	end_y?: number,
	id?: number,
	strength?: number
};
	/** aggregate stddev on columns */
["voyce_chapter_effects_stddev_fields"]: {
	__typename: "voyce_chapter_effects_stddev_fields",
	animation_offset?: number,
	chapter_id?: number,
	chapter_img_id?: number,
	duration?: number,
	effect_type?: number,
	end_x?: number,
	end_y?: number,
	id?: number,
	strength?: number
};
	/** order by stddev() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_stddev_order_by"]: {
		animation_offset?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	chapter_img_id?: GraphQLTypes["order_by"],
	duration?: GraphQLTypes["order_by"],
	effect_type?: GraphQLTypes["order_by"],
	end_x?: GraphQLTypes["order_by"],
	end_y?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	strength?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_effects_stddev_pop_fields"]: {
	__typename: "voyce_chapter_effects_stddev_pop_fields",
	animation_offset?: number,
	chapter_id?: number,
	chapter_img_id?: number,
	duration?: number,
	effect_type?: number,
	end_x?: number,
	end_y?: number,
	id?: number,
	strength?: number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_stddev_pop_order_by"]: {
		animation_offset?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	chapter_img_id?: GraphQLTypes["order_by"],
	duration?: GraphQLTypes["order_by"],
	effect_type?: GraphQLTypes["order_by"],
	end_x?: GraphQLTypes["order_by"],
	end_y?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	strength?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_effects_stddev_samp_fields"]: {
	__typename: "voyce_chapter_effects_stddev_samp_fields",
	animation_offset?: number,
	chapter_id?: number,
	chapter_img_id?: number,
	duration?: number,
	effect_type?: number,
	end_x?: number,
	end_y?: number,
	id?: number,
	strength?: number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_stddev_samp_order_by"]: {
		animation_offset?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	chapter_img_id?: GraphQLTypes["order_by"],
	duration?: GraphQLTypes["order_by"],
	effect_type?: GraphQLTypes["order_by"],
	end_x?: GraphQLTypes["order_by"],
	end_y?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	strength?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_effects" */
["voyce_chapter_effects_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_effects_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_effects_stream_cursor_value_input"]: {
		animation_offset?: number,
	chapter_id?: number,
	chapter_img_id?: number,
	duration?: number,
	effect_type?: number,
	end_x?: number,
	end_y?: number,
	id?: number,
	strength?: number
};
	/** aggregate sum on columns */
["voyce_chapter_effects_sum_fields"]: {
	__typename: "voyce_chapter_effects_sum_fields",
	animation_offset?: number,
	chapter_id?: number,
	chapter_img_id?: number,
	duration?: number,
	effect_type?: number,
	end_x?: number,
	end_y?: number,
	id?: number,
	strength?: number
};
	/** order by sum() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_sum_order_by"]: {
		animation_offset?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	chapter_img_id?: GraphQLTypes["order_by"],
	duration?: GraphQLTypes["order_by"],
	effect_type?: GraphQLTypes["order_by"],
	end_x?: GraphQLTypes["order_by"],
	end_y?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	strength?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_update_column"]: voyce_chapter_effects_update_column;
	["voyce_chapter_effects_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_effects_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_effects_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_effects_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_effects_var_pop_fields"]: {
	__typename: "voyce_chapter_effects_var_pop_fields",
	animation_offset?: number,
	chapter_id?: number,
	chapter_img_id?: number,
	duration?: number,
	effect_type?: number,
	end_x?: number,
	end_y?: number,
	id?: number,
	strength?: number
};
	/** order by var_pop() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_var_pop_order_by"]: {
		animation_offset?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	chapter_img_id?: GraphQLTypes["order_by"],
	duration?: GraphQLTypes["order_by"],
	effect_type?: GraphQLTypes["order_by"],
	end_x?: GraphQLTypes["order_by"],
	end_y?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	strength?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_effects_var_samp_fields"]: {
	__typename: "voyce_chapter_effects_var_samp_fields",
	animation_offset?: number,
	chapter_id?: number,
	chapter_img_id?: number,
	duration?: number,
	effect_type?: number,
	end_x?: number,
	end_y?: number,
	id?: number,
	strength?: number
};
	/** order by var_samp() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_var_samp_order_by"]: {
		animation_offset?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	chapter_img_id?: GraphQLTypes["order_by"],
	duration?: GraphQLTypes["order_by"],
	effect_type?: GraphQLTypes["order_by"],
	end_x?: GraphQLTypes["order_by"],
	end_y?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	strength?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_effects_variance_fields"]: {
	__typename: "voyce_chapter_effects_variance_fields",
	animation_offset?: number,
	chapter_id?: number,
	chapter_img_id?: number,
	duration?: number,
	effect_type?: number,
	end_x?: number,
	end_y?: number,
	id?: number,
	strength?: number
};
	/** order by variance() on columns of table "voyce.chapter_effects" */
["voyce_chapter_effects_variance_order_by"]: {
		animation_offset?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	chapter_img_id?: GraphQLTypes["order_by"],
	duration?: GraphQLTypes["order_by"],
	effect_type?: GraphQLTypes["order_by"],
	end_x?: GraphQLTypes["order_by"],
	end_y?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	strength?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_images" */
["voyce_chapter_images"]: {
	__typename: "voyce_chapter_images",
	/** An object relationship */
	chapter?: GraphQLTypes["voyce_chapters"],
	/** An array relationship */
	chapter_effects: Array<GraphQLTypes["voyce_chapter_effects"]>,
	/** An aggregate relationship */
	chapter_effects_aggregate: GraphQLTypes["voyce_chapter_effects_aggregate"],
	chapter_id: number,
	/** An array relationship */
	chapter_panels: Array<GraphQLTypes["voyce_chapter_panels"]>,
	/** An aggregate relationship */
	chapter_panels_aggregate: GraphQLTypes["voyce_chapter_panels_aggregate"],
	created_at: GraphQLTypes["timestamp"],
	group: GraphQLTypes["smallint"],
	id: number,
	image: string,
	sort_order?: number,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** columns and relationships of "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024"]: {
	__typename: "voyce_chapter_images_07252024",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamp"],
	group?: GraphQLTypes["smallint"],
	id?: number,
	image?: string,
	sort_order?: number,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregated selection of "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_aggregate"]: {
	__typename: "voyce_chapter_images_07252024_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_images_07252024_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_images_07252024"]>
};
	/** aggregate fields of "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_aggregate_fields"]: {
	__typename: "voyce_chapter_images_07252024_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_images_07252024_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_images_07252024_max_fields"],
	min?: GraphQLTypes["voyce_chapter_images_07252024_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_images_07252024_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_images_07252024_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_images_07252024_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_images_07252024_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_images_07252024_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_images_07252024_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_images_07252024_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapter_images_07252024_avg_fields"]: {
	__typename: "voyce_chapter_images_07252024_avg_fields",
	chapter_id?: number,
	group?: number,
	id?: number,
	sort_order?: number
};
	/** Boolean expression to filter rows from the table "voyce.chapter_images_07252024". All fields are combined with a logical 'AND'. */
["voyce_chapter_images_07252024_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_images_07252024_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_images_07252024_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_images_07252024_bool_exp"]>,
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	group?: GraphQLTypes["smallint_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	image?: GraphQLTypes["String_comparison_exp"],
	sort_order?: GraphQLTypes["Int_comparison_exp"],
	updated_at?: GraphQLTypes["timestamp_comparison_exp"]
};
	/** input type for incrementing numeric columns in table "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_inc_input"]: {
		chapter_id?: number,
	group?: GraphQLTypes["smallint"],
	id?: number,
	sort_order?: number
};
	/** input type for inserting data into table "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_insert_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamp"],
	group?: GraphQLTypes["smallint"],
	id?: number,
	image?: string,
	sort_order?: number,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate max on columns */
["voyce_chapter_images_07252024_max_fields"]: {
	__typename: "voyce_chapter_images_07252024_max_fields",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamp"],
	group?: GraphQLTypes["smallint"],
	id?: number,
	image?: string,
	sort_order?: number,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate min on columns */
["voyce_chapter_images_07252024_min_fields"]: {
	__typename: "voyce_chapter_images_07252024_min_fields",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamp"],
	group?: GraphQLTypes["smallint"],
	id?: number,
	image?: string,
	sort_order?: number,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** response of any mutation on the table "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_mutation_response"]: {
	__typename: "voyce_chapter_images_07252024_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_images_07252024"]>
};
	/** Ordering options when selecting data from "voyce.chapter_images_07252024". */
["voyce_chapter_images_07252024_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	group?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	sort_order?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_select_column"]: voyce_chapter_images_07252024_select_column;
	/** input type for updating data in table "voyce.chapter_images_07252024" */
["voyce_chapter_images_07252024_set_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamp"],
	group?: GraphQLTypes["smallint"],
	id?: number,
	image?: string,
	sort_order?: number,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate stddev on columns */
["voyce_chapter_images_07252024_stddev_fields"]: {
	__typename: "voyce_chapter_images_07252024_stddev_fields",
	chapter_id?: number,
	group?: number,
	id?: number,
	sort_order?: number
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_images_07252024_stddev_pop_fields"]: {
	__typename: "voyce_chapter_images_07252024_stddev_pop_fields",
	chapter_id?: number,
	group?: number,
	id?: number,
	sort_order?: number
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_images_07252024_stddev_samp_fields"]: {
	__typename: "voyce_chapter_images_07252024_stddev_samp_fields",
	chapter_id?: number,
	group?: number,
	id?: number,
	sort_order?: number
};
	/** Streaming cursor of the table "voyce_chapter_images_07252024" */
["voyce_chapter_images_07252024_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_images_07252024_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_images_07252024_stream_cursor_value_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamp"],
	group?: GraphQLTypes["smallint"],
	id?: number,
	image?: string,
	sort_order?: number,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate sum on columns */
["voyce_chapter_images_07252024_sum_fields"]: {
	__typename: "voyce_chapter_images_07252024_sum_fields",
	chapter_id?: number,
	group?: GraphQLTypes["smallint"],
	id?: number,
	sort_order?: number
};
	["voyce_chapter_images_07252024_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_images_07252024_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_images_07252024_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_images_07252024_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_images_07252024_var_pop_fields"]: {
	__typename: "voyce_chapter_images_07252024_var_pop_fields",
	chapter_id?: number,
	group?: number,
	id?: number,
	sort_order?: number
};
	/** aggregate var_samp on columns */
["voyce_chapter_images_07252024_var_samp_fields"]: {
	__typename: "voyce_chapter_images_07252024_var_samp_fields",
	chapter_id?: number,
	group?: number,
	id?: number,
	sort_order?: number
};
	/** aggregate variance on columns */
["voyce_chapter_images_07252024_variance_fields"]: {
	__typename: "voyce_chapter_images_07252024_variance_fields",
	chapter_id?: number,
	group?: number,
	id?: number,
	sort_order?: number
};
	/** aggregated selection of "voyce.chapter_images" */
["voyce_chapter_images_aggregate"]: {
	__typename: "voyce_chapter_images_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_images_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_images"]>
};
	["voyce_chapter_images_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_chapter_images_aggregate_bool_exp_count"]
};
	["voyce_chapter_images_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_chapter_images_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_images_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_images" */
["voyce_chapter_images_aggregate_fields"]: {
	__typename: "voyce_chapter_images_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_images_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_images_max_fields"],
	min?: GraphQLTypes["voyce_chapter_images_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_images_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_images_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_images_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_images_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_images_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_images_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_images_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_images" */
["voyce_chapter_images_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_chapter_images_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_chapter_images_max_order_by"],
	min?: GraphQLTypes["voyce_chapter_images_min_order_by"],
	stddev?: GraphQLTypes["voyce_chapter_images_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_chapter_images_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_chapter_images_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_chapter_images_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_chapter_images_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_chapter_images_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_chapter_images_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_images" */
["voyce_chapter_images_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_chapter_images_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_images_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_images_avg_fields"]: {
	__typename: "voyce_chapter_images_avg_fields",
	chapter_id?: number,
	group?: number,
	id?: number,
	sort_order?: number
};
	/** order by avg() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_avg_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	group?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	sort_order?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_images". All fields are combined with a logical 'AND'. */
["voyce_chapter_images_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_images_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_images_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_images_bool_exp"]>,
	chapter?: GraphQLTypes["voyce_chapters_bool_exp"],
	chapter_effects?: GraphQLTypes["voyce_chapter_effects_bool_exp"],
	chapter_effects_aggregate?: GraphQLTypes["voyce_chapter_effects_aggregate_bool_exp"],
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	chapter_panels?: GraphQLTypes["voyce_chapter_panels_bool_exp"],
	chapter_panels_aggregate?: GraphQLTypes["voyce_chapter_panels_aggregate_bool_exp"],
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	group?: GraphQLTypes["smallint_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	image?: GraphQLTypes["String_comparison_exp"],
	sort_order?: GraphQLTypes["Int_comparison_exp"],
	updated_at?: GraphQLTypes["timestamp_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_images" */
["voyce_chapter_images_constraint"]: voyce_chapter_images_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_images" */
["voyce_chapter_images_inc_input"]: {
		chapter_id?: number,
	group?: GraphQLTypes["smallint"],
	id?: number,
	sort_order?: number
};
	/** input type for inserting data into table "voyce.chapter_images" */
["voyce_chapter_images_insert_input"]: {
		chapter?: GraphQLTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_effects?: GraphQLTypes["voyce_chapter_effects_arr_rel_insert_input"],
	chapter_id?: number,
	chapter_panels?: GraphQLTypes["voyce_chapter_panels_arr_rel_insert_input"],
	created_at?: GraphQLTypes["timestamp"],
	group?: GraphQLTypes["smallint"],
	id?: number,
	image?: string,
	sort_order?: number,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate max on columns */
["voyce_chapter_images_max_fields"]: {
	__typename: "voyce_chapter_images_max_fields",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamp"],
	group?: GraphQLTypes["smallint"],
	id?: number,
	image?: string,
	sort_order?: number,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** order by max() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_max_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	group?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	sort_order?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_images_min_fields"]: {
	__typename: "voyce_chapter_images_min_fields",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamp"],
	group?: GraphQLTypes["smallint"],
	id?: number,
	image?: string,
	sort_order?: number,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** order by min() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_min_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	group?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	sort_order?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_images" */
["voyce_chapter_images_mutation_response"]: {
	__typename: "voyce_chapter_images_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_images"]>
};
	/** input type for inserting object relation for remote table "voyce.chapter_images" */
["voyce_chapter_images_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_chapter_images_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_images_on_conflict"]
};
	/** on_conflict condition type for table "voyce.chapter_images" */
["voyce_chapter_images_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_images_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_images_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_images_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_images". */
["voyce_chapter_images_order_by"]: {
		chapter?: GraphQLTypes["voyce_chapters_order_by"],
	chapter_effects_aggregate?: GraphQLTypes["voyce_chapter_effects_aggregate_order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	chapter_panels_aggregate?: GraphQLTypes["voyce_chapter_panels_aggregate_order_by"],
	created_at?: GraphQLTypes["order_by"],
	group?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	sort_order?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_images */
["voyce_chapter_images_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapter_images" */
["voyce_chapter_images_select_column"]: voyce_chapter_images_select_column;
	/** input type for updating data in table "voyce.chapter_images" */
["voyce_chapter_images_set_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamp"],
	group?: GraphQLTypes["smallint"],
	id?: number,
	image?: string,
	sort_order?: number,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate stddev on columns */
["voyce_chapter_images_stddev_fields"]: {
	__typename: "voyce_chapter_images_stddev_fields",
	chapter_id?: number,
	group?: number,
	id?: number,
	sort_order?: number
};
	/** order by stddev() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_stddev_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	group?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	sort_order?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_images_stddev_pop_fields"]: {
	__typename: "voyce_chapter_images_stddev_pop_fields",
	chapter_id?: number,
	group?: number,
	id?: number,
	sort_order?: number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_stddev_pop_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	group?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	sort_order?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_images_stddev_samp_fields"]: {
	__typename: "voyce_chapter_images_stddev_samp_fields",
	chapter_id?: number,
	group?: number,
	id?: number,
	sort_order?: number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_stddev_samp_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	group?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	sort_order?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_images" */
["voyce_chapter_images_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_images_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_images_stream_cursor_value_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamp"],
	group?: GraphQLTypes["smallint"],
	id?: number,
	image?: string,
	sort_order?: number,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate sum on columns */
["voyce_chapter_images_sum_fields"]: {
	__typename: "voyce_chapter_images_sum_fields",
	chapter_id?: number,
	group?: GraphQLTypes["smallint"],
	id?: number,
	sort_order?: number
};
	/** order by sum() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_sum_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	group?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	sort_order?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.chapter_images" */
["voyce_chapter_images_update_column"]: voyce_chapter_images_update_column;
	["voyce_chapter_images_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_images_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_images_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_images_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_images_var_pop_fields"]: {
	__typename: "voyce_chapter_images_var_pop_fields",
	chapter_id?: number,
	group?: number,
	id?: number,
	sort_order?: number
};
	/** order by var_pop() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_var_pop_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	group?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	sort_order?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_images_var_samp_fields"]: {
	__typename: "voyce_chapter_images_var_samp_fields",
	chapter_id?: number,
	group?: number,
	id?: number,
	sort_order?: number
};
	/** order by var_samp() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_var_samp_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	group?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	sort_order?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_images_variance_fields"]: {
	__typename: "voyce_chapter_images_variance_fields",
	chapter_id?: number,
	group?: number,
	id?: number,
	sort_order?: number
};
	/** order by variance() on columns of table "voyce.chapter_images" */
["voyce_chapter_images_variance_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	group?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	sort_order?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_likes" */
["voyce_chapter_likes"]: {
	__typename: "voyce_chapter_likes",
	/** An object relationship */
	chapter?: GraphQLTypes["voyce_chapters"],
	chapter_id: number,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	is_poll_like: boolean,
	series_id: number,
	/** An object relationship */
	user?: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.chapter_likes" */
["voyce_chapter_likes_aggregate"]: {
	__typename: "voyce_chapter_likes_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_likes_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_likes"]>
};
	["voyce_chapter_likes_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["voyce_chapter_likes_aggregate_bool_exp_bool_and"],
	bool_or?: GraphQLTypes["voyce_chapter_likes_aggregate_bool_exp_bool_or"],
	count?: GraphQLTypes["voyce_chapter_likes_aggregate_bool_exp_count"]
};
	["voyce_chapter_likes_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_likes_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_chapter_likes_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_likes_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_chapter_likes_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_chapter_likes_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_likes_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_likes" */
["voyce_chapter_likes_aggregate_fields"]: {
	__typename: "voyce_chapter_likes_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_likes_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_likes_max_fields"],
	min?: GraphQLTypes["voyce_chapter_likes_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_likes_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_likes_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_likes_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_likes_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_likes_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_likes_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_likes_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_likes" */
["voyce_chapter_likes_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_chapter_likes_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_chapter_likes_max_order_by"],
	min?: GraphQLTypes["voyce_chapter_likes_min_order_by"],
	stddev?: GraphQLTypes["voyce_chapter_likes_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_chapter_likes_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_chapter_likes_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_chapter_likes_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_chapter_likes_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_chapter_likes_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_chapter_likes_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_likes" */
["voyce_chapter_likes_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_chapter_likes_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_likes_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_likes_avg_fields"]: {
	__typename: "voyce_chapter_likes_avg_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_avg_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_likes". All fields are combined with a logical 'AND'. */
["voyce_chapter_likes_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_likes_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_likes_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_likes_bool_exp"]>,
	chapter?: GraphQLTypes["voyce_chapters_bool_exp"],
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_poll_like?: GraphQLTypes["Boolean_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_likes" */
["voyce_chapter_likes_constraint"]: voyce_chapter_likes_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_likes" */
["voyce_chapter_likes_inc_input"]: {
		chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.chapter_likes" */
["voyce_chapter_likes_insert_input"]: {
		chapter?: GraphQLTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	is_poll_like?: boolean,
	series_id?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_chapter_likes_max_fields"]: {
	__typename: "voyce_chapter_likes_max_fields",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by max() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_max_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_likes_min_fields"]: {
	__typename: "voyce_chapter_likes_min_fields",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by min() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_min_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_likes" */
["voyce_chapter_likes_mutation_response"]: {
	__typename: "voyce_chapter_likes_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_likes"]>
};
	/** on_conflict condition type for table "voyce.chapter_likes" */
["voyce_chapter_likes_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_likes_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_likes_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_likes_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_likes". */
["voyce_chapter_likes_order_by"]: {
		chapter?: GraphQLTypes["voyce_chapters_order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_poll_like?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_likes */
["voyce_chapter_likes_pk_columns_input"]: {
		chapter_id: number,
	created_at: GraphQLTypes["timestamptz"]
};
	/** select columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_select_column"]: voyce_chapter_likes_select_column;
	/** select "voyce_chapter_likes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_and_arguments_columns"]: voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_chapter_likes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_or_arguments_columns"]: voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.chapter_likes" */
["voyce_chapter_likes_set_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	is_poll_like?: boolean,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_chapter_likes_stddev_fields"]: {
	__typename: "voyce_chapter_likes_stddev_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_stddev_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_likes_stddev_pop_fields"]: {
	__typename: "voyce_chapter_likes_stddev_pop_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_stddev_pop_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_likes_stddev_samp_fields"]: {
	__typename: "voyce_chapter_likes_stddev_samp_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_stddev_samp_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_likes" */
["voyce_chapter_likes_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_likes_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_likes_stream_cursor_value_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	is_poll_like?: boolean,
	series_id?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_chapter_likes_sum_fields"]: {
	__typename: "voyce_chapter_likes_sum_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_sum_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_update_column"]: voyce_chapter_likes_update_column;
	["voyce_chapter_likes_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_likes_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_likes_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_likes_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_likes_var_pop_fields"]: {
	__typename: "voyce_chapter_likes_var_pop_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_var_pop_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_likes_var_samp_fields"]: {
	__typename: "voyce_chapter_likes_var_samp_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_var_samp_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_likes_variance_fields"]: {
	__typename: "voyce_chapter_likes_variance_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.chapter_likes" */
["voyce_chapter_likes_variance_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_locks" */
["voyce_chapter_locks"]: {
	__typename: "voyce_chapter_locks",
	buy_currency?: string,
	buy_price?: GraphQLTypes["numeric"],
	/** An object relationship */
	chapter: GraphQLTypes["voyce_chapters"],
	chapter_id: number,
	created_at: GraphQLTypes["timestamptz"],
	creator_id: number,
	end_date?: GraphQLTypes["timestamptz"],
	id: number,
	is_active: boolean,
	rent_currency?: string,
	rent_duration?: GraphQLTypes["numeric"],
	rent_price?: GraphQLTypes["numeric"],
	/** An object relationship */
	series: GraphQLTypes["voyce_series"],
	series_id: number,
	start_date: GraphQLTypes["timestamptz"],
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.chapter_locks" */
["voyce_chapter_locks_aggregate"]: {
	__typename: "voyce_chapter_locks_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_locks_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_locks"]>
};
	["voyce_chapter_locks_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["voyce_chapter_locks_aggregate_bool_exp_bool_and"],
	bool_or?: GraphQLTypes["voyce_chapter_locks_aggregate_bool_exp_bool_or"],
	count?: GraphQLTypes["voyce_chapter_locks_aggregate_bool_exp_count"]
};
	["voyce_chapter_locks_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_locks_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_chapter_locks_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_locks_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_chapter_locks_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_chapter_locks_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_locks_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_locks" */
["voyce_chapter_locks_aggregate_fields"]: {
	__typename: "voyce_chapter_locks_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_locks_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_locks_max_fields"],
	min?: GraphQLTypes["voyce_chapter_locks_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_locks_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_locks_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_locks_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_locks_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_locks_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_locks_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_locks_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_locks" */
["voyce_chapter_locks_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_chapter_locks_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_chapter_locks_max_order_by"],
	min?: GraphQLTypes["voyce_chapter_locks_min_order_by"],
	stddev?: GraphQLTypes["voyce_chapter_locks_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_chapter_locks_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_chapter_locks_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_chapter_locks_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_chapter_locks_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_chapter_locks_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_chapter_locks_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_locks" */
["voyce_chapter_locks_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_chapter_locks_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_locks_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_locks_avg_fields"]: {
	__typename: "voyce_chapter_locks_avg_fields",
	buy_price?: number,
	chapter_id?: number,
	creator_id?: number,
	id?: number,
	rent_duration?: number,
	rent_price?: number,
	series_id?: number
};
	/** order by avg() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_avg_order_by"]: {
		buy_price?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	creator_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rent_duration?: GraphQLTypes["order_by"],
	rent_price?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_locks". All fields are combined with a logical 'AND'. */
["voyce_chapter_locks_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_locks_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_locks_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_locks_bool_exp"]>,
	buy_currency?: GraphQLTypes["String_comparison_exp"],
	buy_price?: GraphQLTypes["numeric_comparison_exp"],
	chapter?: GraphQLTypes["voyce_chapters_bool_exp"],
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	creator_id?: GraphQLTypes["Int_comparison_exp"],
	end_date?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_active?: GraphQLTypes["Boolean_comparison_exp"],
	rent_currency?: GraphQLTypes["String_comparison_exp"],
	rent_duration?: GraphQLTypes["numeric_comparison_exp"],
	rent_price?: GraphQLTypes["numeric_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	start_date?: GraphQLTypes["timestamptz_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_locks" */
["voyce_chapter_locks_constraint"]: voyce_chapter_locks_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_locks" */
["voyce_chapter_locks_inc_input"]: {
		buy_price?: GraphQLTypes["numeric"],
	chapter_id?: number,
	creator_id?: number,
	id?: number,
	rent_duration?: GraphQLTypes["numeric"],
	rent_price?: GraphQLTypes["numeric"],
	series_id?: number
};
	/** input type for inserting data into table "voyce.chapter_locks" */
["voyce_chapter_locks_insert_input"]: {
		buy_currency?: string,
	buy_price?: GraphQLTypes["numeric"],
	chapter?: GraphQLTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	creator_id?: number,
	end_date?: GraphQLTypes["timestamptz"],
	id?: number,
	is_active?: boolean,
	rent_currency?: string,
	rent_duration?: GraphQLTypes["numeric"],
	rent_price?: GraphQLTypes["numeric"],
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	start_date?: GraphQLTypes["timestamptz"],
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_chapter_locks_max_fields"]: {
	__typename: "voyce_chapter_locks_max_fields",
	buy_currency?: string,
	buy_price?: GraphQLTypes["numeric"],
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	creator_id?: number,
	end_date?: GraphQLTypes["timestamptz"],
	id?: number,
	rent_currency?: string,
	rent_duration?: GraphQLTypes["numeric"],
	rent_price?: GraphQLTypes["numeric"],
	series_id?: number,
	start_date?: GraphQLTypes["timestamptz"],
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** order by max() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_max_order_by"]: {
		buy_currency?: GraphQLTypes["order_by"],
	buy_price?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	creator_id?: GraphQLTypes["order_by"],
	end_date?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rent_currency?: GraphQLTypes["order_by"],
	rent_duration?: GraphQLTypes["order_by"],
	rent_price?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	start_date?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_locks_min_fields"]: {
	__typename: "voyce_chapter_locks_min_fields",
	buy_currency?: string,
	buy_price?: GraphQLTypes["numeric"],
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	creator_id?: number,
	end_date?: GraphQLTypes["timestamptz"],
	id?: number,
	rent_currency?: string,
	rent_duration?: GraphQLTypes["numeric"],
	rent_price?: GraphQLTypes["numeric"],
	series_id?: number,
	start_date?: GraphQLTypes["timestamptz"],
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** order by min() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_min_order_by"]: {
		buy_currency?: GraphQLTypes["order_by"],
	buy_price?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	creator_id?: GraphQLTypes["order_by"],
	end_date?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rent_currency?: GraphQLTypes["order_by"],
	rent_duration?: GraphQLTypes["order_by"],
	rent_price?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	start_date?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_locks" */
["voyce_chapter_locks_mutation_response"]: {
	__typename: "voyce_chapter_locks_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_locks"]>
};
	/** on_conflict condition type for table "voyce.chapter_locks" */
["voyce_chapter_locks_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_locks_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_locks_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_locks_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_locks". */
["voyce_chapter_locks_order_by"]: {
		buy_currency?: GraphQLTypes["order_by"],
	buy_price?: GraphQLTypes["order_by"],
	chapter?: GraphQLTypes["voyce_chapters_order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	creator_id?: GraphQLTypes["order_by"],
	end_date?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_active?: GraphQLTypes["order_by"],
	rent_currency?: GraphQLTypes["order_by"],
	rent_duration?: GraphQLTypes["order_by"],
	rent_price?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	start_date?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_locks */
["voyce_chapter_locks_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_select_column"]: voyce_chapter_locks_select_column;
	/** select "voyce_chapter_locks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_and_arguments_columns"]: voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_chapter_locks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_or_arguments_columns"]: voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.chapter_locks" */
["voyce_chapter_locks_set_input"]: {
		buy_currency?: string,
	buy_price?: GraphQLTypes["numeric"],
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	creator_id?: number,
	end_date?: GraphQLTypes["timestamptz"],
	id?: number,
	is_active?: boolean,
	rent_currency?: string,
	rent_duration?: GraphQLTypes["numeric"],
	rent_price?: GraphQLTypes["numeric"],
	series_id?: number,
	start_date?: GraphQLTypes["timestamptz"],
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_chapter_locks_stddev_fields"]: {
	__typename: "voyce_chapter_locks_stddev_fields",
	buy_price?: number,
	chapter_id?: number,
	creator_id?: number,
	id?: number,
	rent_duration?: number,
	rent_price?: number,
	series_id?: number
};
	/** order by stddev() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_stddev_order_by"]: {
		buy_price?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	creator_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rent_duration?: GraphQLTypes["order_by"],
	rent_price?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_locks_stddev_pop_fields"]: {
	__typename: "voyce_chapter_locks_stddev_pop_fields",
	buy_price?: number,
	chapter_id?: number,
	creator_id?: number,
	id?: number,
	rent_duration?: number,
	rent_price?: number,
	series_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_stddev_pop_order_by"]: {
		buy_price?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	creator_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rent_duration?: GraphQLTypes["order_by"],
	rent_price?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_locks_stddev_samp_fields"]: {
	__typename: "voyce_chapter_locks_stddev_samp_fields",
	buy_price?: number,
	chapter_id?: number,
	creator_id?: number,
	id?: number,
	rent_duration?: number,
	rent_price?: number,
	series_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_stddev_samp_order_by"]: {
		buy_price?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	creator_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rent_duration?: GraphQLTypes["order_by"],
	rent_price?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_locks" */
["voyce_chapter_locks_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_locks_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_locks_stream_cursor_value_input"]: {
		buy_currency?: string,
	buy_price?: GraphQLTypes["numeric"],
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	creator_id?: number,
	end_date?: GraphQLTypes["timestamptz"],
	id?: number,
	is_active?: boolean,
	rent_currency?: string,
	rent_duration?: GraphQLTypes["numeric"],
	rent_price?: GraphQLTypes["numeric"],
	series_id?: number,
	start_date?: GraphQLTypes["timestamptz"],
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_chapter_locks_sum_fields"]: {
	__typename: "voyce_chapter_locks_sum_fields",
	buy_price?: GraphQLTypes["numeric"],
	chapter_id?: number,
	creator_id?: number,
	id?: number,
	rent_duration?: GraphQLTypes["numeric"],
	rent_price?: GraphQLTypes["numeric"],
	series_id?: number
};
	/** order by sum() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_sum_order_by"]: {
		buy_price?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	creator_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rent_duration?: GraphQLTypes["order_by"],
	rent_price?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_update_column"]: voyce_chapter_locks_update_column;
	["voyce_chapter_locks_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_locks_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_locks_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_locks_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_locks_var_pop_fields"]: {
	__typename: "voyce_chapter_locks_var_pop_fields",
	buy_price?: number,
	chapter_id?: number,
	creator_id?: number,
	id?: number,
	rent_duration?: number,
	rent_price?: number,
	series_id?: number
};
	/** order by var_pop() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_var_pop_order_by"]: {
		buy_price?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	creator_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rent_duration?: GraphQLTypes["order_by"],
	rent_price?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_locks_var_samp_fields"]: {
	__typename: "voyce_chapter_locks_var_samp_fields",
	buy_price?: number,
	chapter_id?: number,
	creator_id?: number,
	id?: number,
	rent_duration?: number,
	rent_price?: number,
	series_id?: number
};
	/** order by var_samp() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_var_samp_order_by"]: {
		buy_price?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	creator_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rent_duration?: GraphQLTypes["order_by"],
	rent_price?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_locks_variance_fields"]: {
	__typename: "voyce_chapter_locks_variance_fields",
	buy_price?: number,
	chapter_id?: number,
	creator_id?: number,
	id?: number,
	rent_duration?: number,
	rent_price?: number,
	series_id?: number
};
	/** order by variance() on columns of table "voyce.chapter_locks" */
["voyce_chapter_locks_variance_order_by"]: {
		buy_price?: GraphQLTypes["order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	creator_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rent_duration?: GraphQLTypes["order_by"],
	rent_price?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_panel_views" */
["voyce_chapter_panel_views"]: {
	__typename: "voyce_chapter_panel_views",
	/** An object relationship */
	chapter?: GraphQLTypes["voyce_chapters"],
	chapter_id?: number,
	current_panel: number,
	id: number,
	panel_id?: number,
	panels?: GraphQLTypes["bpchar"],
	percentage: number,
	/** An object relationship */
	series: GraphQLTypes["voyce_series"],
	series_id: number,
	total_panels: number,
	type: string,
	updated_at: GraphQLTypes["timestamptz"],
	user_id: number,
	word_idx: number
};
	/** aggregated selection of "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_aggregate"]: {
	__typename: "voyce_chapter_panel_views_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_panel_views_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_panel_views"]>
};
	/** aggregate fields of "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_aggregate_fields"]: {
	__typename: "voyce_chapter_panel_views_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_panel_views_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_panel_views_max_fields"],
	min?: GraphQLTypes["voyce_chapter_panel_views_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_panel_views_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_panel_views_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_panel_views_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_panel_views_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_panel_views_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_panel_views_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_panel_views_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapter_panel_views_avg_fields"]: {
	__typename: "voyce_chapter_panel_views_avg_fields",
	chapter_id?: number,
	current_panel?: number,
	id?: number,
	panel_id?: number,
	percentage?: number,
	series_id?: number,
	total_panels?: number,
	user_id?: number,
	word_idx?: number
};
	/** Boolean expression to filter rows from the table "voyce.chapter_panel_views". All fields are combined with a logical 'AND'. */
["voyce_chapter_panel_views_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_panel_views_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_panel_views_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_panel_views_bool_exp"]>,
	chapter?: GraphQLTypes["voyce_chapters_bool_exp"],
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	current_panel?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	panel_id?: GraphQLTypes["Int_comparison_exp"],
	panels?: GraphQLTypes["bpchar_comparison_exp"],
	percentage?: GraphQLTypes["Int_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	total_panels?: GraphQLTypes["Int_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	word_idx?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_constraint"]: voyce_chapter_panel_views_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_inc_input"]: {
		chapter_id?: number,
	current_panel?: number,
	id?: number,
	panel_id?: number,
	percentage?: number,
	series_id?: number,
	total_panels?: number,
	user_id?: number,
	word_idx?: number
};
	/** input type for inserting data into table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_insert_input"]: {
		chapter?: GraphQLTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?: number,
	current_panel?: number,
	id?: number,
	panel_id?: number,
	panels?: GraphQLTypes["bpchar"],
	percentage?: number,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	total_panels?: number,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number,
	word_idx?: number
};
	/** aggregate max on columns */
["voyce_chapter_panel_views_max_fields"]: {
	__typename: "voyce_chapter_panel_views_max_fields",
	chapter_id?: number,
	current_panel?: number,
	id?: number,
	panel_id?: number,
	panels?: GraphQLTypes["bpchar"],
	percentage?: number,
	series_id?: number,
	total_panels?: number,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number,
	word_idx?: number
};
	/** aggregate min on columns */
["voyce_chapter_panel_views_min_fields"]: {
	__typename: "voyce_chapter_panel_views_min_fields",
	chapter_id?: number,
	current_panel?: number,
	id?: number,
	panel_id?: number,
	panels?: GraphQLTypes["bpchar"],
	percentage?: number,
	series_id?: number,
	total_panels?: number,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number,
	word_idx?: number
};
	/** response of any mutation on the table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_mutation_response"]: {
	__typename: "voyce_chapter_panel_views_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_panel_views"]>
};
	/** on_conflict condition type for table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_panel_views_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_panel_views_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_panel_views_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_panel_views". */
["voyce_chapter_panel_views_order_by"]: {
		chapter?: GraphQLTypes["voyce_chapters_order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	current_panel?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	panel_id?: GraphQLTypes["order_by"],
	panels?: GraphQLTypes["order_by"],
	percentage?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	total_panels?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	word_idx?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_panel_views */
["voyce_chapter_panel_views_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_select_column"]: voyce_chapter_panel_views_select_column;
	/** input type for updating data in table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_set_input"]: {
		chapter_id?: number,
	current_panel?: number,
	id?: number,
	panel_id?: number,
	panels?: GraphQLTypes["bpchar"],
	percentage?: number,
	series_id?: number,
	total_panels?: number,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number,
	word_idx?: number
};
	/** aggregate stddev on columns */
["voyce_chapter_panel_views_stddev_fields"]: {
	__typename: "voyce_chapter_panel_views_stddev_fields",
	chapter_id?: number,
	current_panel?: number,
	id?: number,
	panel_id?: number,
	percentage?: number,
	series_id?: number,
	total_panels?: number,
	user_id?: number,
	word_idx?: number
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_panel_views_stddev_pop_fields"]: {
	__typename: "voyce_chapter_panel_views_stddev_pop_fields",
	chapter_id?: number,
	current_panel?: number,
	id?: number,
	panel_id?: number,
	percentage?: number,
	series_id?: number,
	total_panels?: number,
	user_id?: number,
	word_idx?: number
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_panel_views_stddev_samp_fields"]: {
	__typename: "voyce_chapter_panel_views_stddev_samp_fields",
	chapter_id?: number,
	current_panel?: number,
	id?: number,
	panel_id?: number,
	percentage?: number,
	series_id?: number,
	total_panels?: number,
	user_id?: number,
	word_idx?: number
};
	/** Streaming cursor of the table "voyce_chapter_panel_views" */
["voyce_chapter_panel_views_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_panel_views_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_panel_views_stream_cursor_value_input"]: {
		chapter_id?: number,
	current_panel?: number,
	id?: number,
	panel_id?: number,
	panels?: GraphQLTypes["bpchar"],
	percentage?: number,
	series_id?: number,
	total_panels?: number,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number,
	word_idx?: number
};
	/** aggregate sum on columns */
["voyce_chapter_panel_views_sum_fields"]: {
	__typename: "voyce_chapter_panel_views_sum_fields",
	chapter_id?: number,
	current_panel?: number,
	id?: number,
	panel_id?: number,
	percentage?: number,
	series_id?: number,
	total_panels?: number,
	user_id?: number,
	word_idx?: number
};
	/** update columns of table "voyce.chapter_panel_views" */
["voyce_chapter_panel_views_update_column"]: voyce_chapter_panel_views_update_column;
	["voyce_chapter_panel_views_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_panel_views_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_panel_views_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_panel_views_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_panel_views_var_pop_fields"]: {
	__typename: "voyce_chapter_panel_views_var_pop_fields",
	chapter_id?: number,
	current_panel?: number,
	id?: number,
	panel_id?: number,
	percentage?: number,
	series_id?: number,
	total_panels?: number,
	user_id?: number,
	word_idx?: number
};
	/** aggregate var_samp on columns */
["voyce_chapter_panel_views_var_samp_fields"]: {
	__typename: "voyce_chapter_panel_views_var_samp_fields",
	chapter_id?: number,
	current_panel?: number,
	id?: number,
	panel_id?: number,
	percentage?: number,
	series_id?: number,
	total_panels?: number,
	user_id?: number,
	word_idx?: number
};
	/** aggregate variance on columns */
["voyce_chapter_panel_views_variance_fields"]: {
	__typename: "voyce_chapter_panel_views_variance_fields",
	chapter_id?: number,
	current_panel?: number,
	id?: number,
	panel_id?: number,
	percentage?: number,
	series_id?: number,
	total_panels?: number,
	user_id?: number,
	word_idx?: number
};
	/** columns and relationships of "voyce.chapter_panels" */
["voyce_chapter_panels"]: {
	__typename: "voyce_chapter_panels",
	/** An object relationship */
	chapter: GraphQLTypes["voyce_chapters"],
	chapter_id: number,
	comments_count?: number,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	image: string,
	image_id?: number,
	last_activity_date: GraphQLTypes["timestamptz"],
	likes_count?: number,
	outdated: boolean,
	/** An array relationship */
	panels_comments: Array<GraphQLTypes["voyce_panels_comments"]>,
	/** An aggregate relationship */
	panels_comments_aggregate: GraphQLTypes["voyce_panels_comments_aggregate"],
	/** An array relationship */
	panels_reactions: Array<GraphQLTypes["voyce_panels_reactions"]>,
	/** An aggregate relationship */
	panels_reactions_aggregate: GraphQLTypes["voyce_panels_reactions_aggregate"],
	/** An array relationship */
	panels_reactions_counts: Array<GraphQLTypes["voyce_panels_reactions_count"]>,
	/** An aggregate relationship */
	panels_reactions_counts_aggregate: GraphQLTypes["voyce_panels_reactions_count_aggregate"],
	popularity: number,
	popularity_weekly: number,
	reactions_count?: number,
	/** An object relationship */
	series: GraphQLTypes["voyce_series"],
	series_id: number,
	spoiler: boolean,
	total_popularity: number
};
	/** aggregated selection of "voyce.chapter_panels" */
["voyce_chapter_panels_aggregate"]: {
	__typename: "voyce_chapter_panels_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_panels_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_panels"]>
};
	["voyce_chapter_panels_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["voyce_chapter_panels_aggregate_bool_exp_bool_and"],
	bool_or?: GraphQLTypes["voyce_chapter_panels_aggregate_bool_exp_bool_or"],
	count?: GraphQLTypes["voyce_chapter_panels_aggregate_bool_exp_count"]
};
	["voyce_chapter_panels_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_panels_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_chapter_panels_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_panels_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_chapter_panels_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_chapter_panels_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_panels_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_panels" */
["voyce_chapter_panels_aggregate_fields"]: {
	__typename: "voyce_chapter_panels_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_panels_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_panels_max_fields"],
	min?: GraphQLTypes["voyce_chapter_panels_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_panels_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_panels_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_panels_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_panels_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_panels_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_panels_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_panels_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_panels" */
["voyce_chapter_panels_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_chapter_panels_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_chapter_panels_max_order_by"],
	min?: GraphQLTypes["voyce_chapter_panels_min_order_by"],
	stddev?: GraphQLTypes["voyce_chapter_panels_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_chapter_panels_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_chapter_panels_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_chapter_panels_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_chapter_panels_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_chapter_panels_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_chapter_panels_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_panels" */
["voyce_chapter_panels_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_chapter_panels_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_panels_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_panels_avg_fields"]: {
	__typename: "voyce_chapter_panels_avg_fields",
	chapter_id?: number,
	comments_count?: number,
	id?: number,
	image_id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	series_id?: number,
	total_popularity?: number
};
	/** order by avg() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_avg_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image_id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_panels". All fields are combined with a logical 'AND'. */
["voyce_chapter_panels_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_panels_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_panels_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_panels_bool_exp"]>,
	chapter?: GraphQLTypes["voyce_chapters_bool_exp"],
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	comments_count?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	image?: GraphQLTypes["String_comparison_exp"],
	image_id?: GraphQLTypes["Int_comparison_exp"],
	last_activity_date?: GraphQLTypes["timestamptz_comparison_exp"],
	likes_count?: GraphQLTypes["Int_comparison_exp"],
	outdated?: GraphQLTypes["Boolean_comparison_exp"],
	panels_comments?: GraphQLTypes["voyce_panels_comments_bool_exp"],
	panels_comments_aggregate?: GraphQLTypes["voyce_panels_comments_aggregate_bool_exp"],
	panels_reactions?: GraphQLTypes["voyce_panels_reactions_bool_exp"],
	panels_reactions_aggregate?: GraphQLTypes["voyce_panels_reactions_aggregate_bool_exp"],
	panels_reactions_counts?: GraphQLTypes["voyce_panels_reactions_count_bool_exp"],
	panels_reactions_counts_aggregate?: GraphQLTypes["voyce_panels_reactions_count_aggregate_bool_exp"],
	popularity?: GraphQLTypes["Int_comparison_exp"],
	popularity_weekly?: GraphQLTypes["Int_comparison_exp"],
	reactions_count?: GraphQLTypes["Int_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	spoiler?: GraphQLTypes["Boolean_comparison_exp"],
	total_popularity?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_panels" */
["voyce_chapter_panels_constraint"]: voyce_chapter_panels_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_panels" */
["voyce_chapter_panels_inc_input"]: {
		chapter_id?: number,
	comments_count?: number,
	id?: number,
	image_id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	series_id?: number,
	total_popularity?: number
};
	/** input type for inserting data into table "voyce.chapter_panels" */
["voyce_chapter_panels_insert_input"]: {
		chapter?: GraphQLTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?: number,
	comments_count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	image_id?: number,
	last_activity_date?: GraphQLTypes["timestamptz"],
	likes_count?: number,
	outdated?: boolean,
	panels_comments?: GraphQLTypes["voyce_panels_comments_arr_rel_insert_input"],
	panels_reactions?: GraphQLTypes["voyce_panels_reactions_arr_rel_insert_input"],
	panels_reactions_counts?: GraphQLTypes["voyce_panels_reactions_count_arr_rel_insert_input"],
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	spoiler?: boolean,
	total_popularity?: number
};
	/** aggregate max on columns */
["voyce_chapter_panels_max_fields"]: {
	__typename: "voyce_chapter_panels_max_fields",
	chapter_id?: number,
	comments_count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	image_id?: number,
	last_activity_date?: GraphQLTypes["timestamptz"],
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	series_id?: number,
	total_popularity?: number
};
	/** order by max() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_max_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	comments_count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	image_id?: GraphQLTypes["order_by"],
	last_activity_date?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_panels_min_fields"]: {
	__typename: "voyce_chapter_panels_min_fields",
	chapter_id?: number,
	comments_count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	image_id?: number,
	last_activity_date?: GraphQLTypes["timestamptz"],
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	series_id?: number,
	total_popularity?: number
};
	/** order by min() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_min_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	comments_count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	image_id?: GraphQLTypes["order_by"],
	last_activity_date?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_panels" */
["voyce_chapter_panels_mutation_response"]: {
	__typename: "voyce_chapter_panels_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_panels"]>
};
	/** input type for inserting object relation for remote table "voyce.chapter_panels" */
["voyce_chapter_panels_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_chapter_panels_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_panels_on_conflict"]
};
	/** on_conflict condition type for table "voyce.chapter_panels" */
["voyce_chapter_panels_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_panels_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_panels_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_panels_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_panels". */
["voyce_chapter_panels_order_by"]: {
		chapter?: GraphQLTypes["voyce_chapters_order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	comments_count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	image_id?: GraphQLTypes["order_by"],
	last_activity_date?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	outdated?: GraphQLTypes["order_by"],
	panels_comments_aggregate?: GraphQLTypes["voyce_panels_comments_aggregate_order_by"],
	panels_reactions_aggregate?: GraphQLTypes["voyce_panels_reactions_aggregate_order_by"],
	panels_reactions_counts_aggregate?: GraphQLTypes["voyce_panels_reactions_count_aggregate_order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	spoiler?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_panels */
["voyce_chapter_panels_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_select_column"]: voyce_chapter_panels_select_column;
	/** select "voyce_chapter_panels_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_and_arguments_columns"]: voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_chapter_panels_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_or_arguments_columns"]: voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.chapter_panels" */
["voyce_chapter_panels_set_input"]: {
		chapter_id?: number,
	comments_count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	image_id?: number,
	last_activity_date?: GraphQLTypes["timestamptz"],
	likes_count?: number,
	outdated?: boolean,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	series_id?: number,
	spoiler?: boolean,
	total_popularity?: number
};
	/** aggregate stddev on columns */
["voyce_chapter_panels_stddev_fields"]: {
	__typename: "voyce_chapter_panels_stddev_fields",
	chapter_id?: number,
	comments_count?: number,
	id?: number,
	image_id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	series_id?: number,
	total_popularity?: number
};
	/** order by stddev() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_stddev_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image_id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_panels_stddev_pop_fields"]: {
	__typename: "voyce_chapter_panels_stddev_pop_fields",
	chapter_id?: number,
	comments_count?: number,
	id?: number,
	image_id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	series_id?: number,
	total_popularity?: number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_stddev_pop_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image_id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_panels_stddev_samp_fields"]: {
	__typename: "voyce_chapter_panels_stddev_samp_fields",
	chapter_id?: number,
	comments_count?: number,
	id?: number,
	image_id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	series_id?: number,
	total_popularity?: number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_stddev_samp_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image_id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_panels" */
["voyce_chapter_panels_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_panels_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_panels_stream_cursor_value_input"]: {
		chapter_id?: number,
	comments_count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	image_id?: number,
	last_activity_date?: GraphQLTypes["timestamptz"],
	likes_count?: number,
	outdated?: boolean,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	series_id?: number,
	spoiler?: boolean,
	total_popularity?: number
};
	/** aggregate sum on columns */
["voyce_chapter_panels_sum_fields"]: {
	__typename: "voyce_chapter_panels_sum_fields",
	chapter_id?: number,
	comments_count?: number,
	id?: number,
	image_id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	series_id?: number,
	total_popularity?: number
};
	/** order by sum() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_sum_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image_id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_update_column"]: voyce_chapter_panels_update_column;
	["voyce_chapter_panels_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_panels_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_panels_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_panels_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_panels_var_pop_fields"]: {
	__typename: "voyce_chapter_panels_var_pop_fields",
	chapter_id?: number,
	comments_count?: number,
	id?: number,
	image_id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	series_id?: number,
	total_popularity?: number
};
	/** order by var_pop() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_var_pop_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image_id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_panels_var_samp_fields"]: {
	__typename: "voyce_chapter_panels_var_samp_fields",
	chapter_id?: number,
	comments_count?: number,
	id?: number,
	image_id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	series_id?: number,
	total_popularity?: number
};
	/** order by var_samp() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_var_samp_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image_id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_panels_variance_fields"]: {
	__typename: "voyce_chapter_panels_variance_fields",
	chapter_id?: number,
	comments_count?: number,
	id?: number,
	image_id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	series_id?: number,
	total_popularity?: number
};
	/** order by variance() on columns of table "voyce.chapter_panels" */
["voyce_chapter_panels_variance_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image_id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules"]: {
	__typename: "voyce_chapter_publish_schedules",
	chapter_id: number,
	created_at: GraphQLTypes["timestamptz"],
	date: GraphQLTypes["date"],
	/** in seconds */
	epoch: GraphQLTypes["numeric"],
	gmt_offset: GraphQLTypes["numeric"],
	id: number,
	is_done: GraphQLTypes["numeric"],
	publish_date?: GraphQLTypes["timestamp"],
	/** An object relationship */
	series: GraphQLTypes["voyce_series"],
	series_id: number,
	time: GraphQLTypes["time"]
};
	/** aggregated selection of "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_aggregate"]: {
	__typename: "voyce_chapter_publish_schedules_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_publish_schedules_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_publish_schedules"]>
};
	["voyce_chapter_publish_schedules_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_chapter_publish_schedules_aggregate_bool_exp_count"]
};
	["voyce_chapter_publish_schedules_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_chapter_publish_schedules_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_publish_schedules_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_aggregate_fields"]: {
	__typename: "voyce_chapter_publish_schedules_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_publish_schedules_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_publish_schedules_max_fields"],
	min?: GraphQLTypes["voyce_chapter_publish_schedules_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_publish_schedules_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_publish_schedules_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_publish_schedules_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_publish_schedules_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_publish_schedules_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_publish_schedules_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_publish_schedules_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_chapter_publish_schedules_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_chapter_publish_schedules_max_order_by"],
	min?: GraphQLTypes["voyce_chapter_publish_schedules_min_order_by"],
	stddev?: GraphQLTypes["voyce_chapter_publish_schedules_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_chapter_publish_schedules_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_chapter_publish_schedules_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_chapter_publish_schedules_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_chapter_publish_schedules_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_chapter_publish_schedules_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_chapter_publish_schedules_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_chapter_publish_schedules_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_publish_schedules_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_publish_schedules_avg_fields"]: {
	__typename: "voyce_chapter_publish_schedules_avg_fields",
	chapter_id?: number,
	/** in seconds */
	epoch?: number,
	gmt_offset?: number,
	id?: number,
	is_done?: number,
	series_id?: number
};
	/** order by avg() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_avg_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	/** in seconds */
	epoch?: GraphQLTypes["order_by"],
	gmt_offset?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_done?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_publish_schedules". All fields are combined with a logical 'AND'. */
["voyce_chapter_publish_schedules_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_publish_schedules_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_publish_schedules_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_publish_schedules_bool_exp"]>,
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	date?: GraphQLTypes["date_comparison_exp"],
	epoch?: GraphQLTypes["numeric_comparison_exp"],
	gmt_offset?: GraphQLTypes["numeric_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_done?: GraphQLTypes["numeric_comparison_exp"],
	publish_date?: GraphQLTypes["timestamp_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	time?: GraphQLTypes["time_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_constraint"]: voyce_chapter_publish_schedules_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_inc_input"]: {
		chapter_id?: number,
	/** in seconds */
	epoch?: GraphQLTypes["numeric"],
	gmt_offset?: GraphQLTypes["numeric"],
	id?: number,
	is_done?: GraphQLTypes["numeric"],
	series_id?: number
};
	/** input type for inserting data into table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_insert_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	date?: GraphQLTypes["date"],
	/** in seconds */
	epoch?: GraphQLTypes["numeric"],
	gmt_offset?: GraphQLTypes["numeric"],
	id?: number,
	is_done?: GraphQLTypes["numeric"],
	publish_date?: GraphQLTypes["timestamp"],
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	time?: GraphQLTypes["time"]
};
	/** aggregate max on columns */
["voyce_chapter_publish_schedules_max_fields"]: {
	__typename: "voyce_chapter_publish_schedules_max_fields",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	date?: GraphQLTypes["date"],
	/** in seconds */
	epoch?: GraphQLTypes["numeric"],
	gmt_offset?: GraphQLTypes["numeric"],
	id?: number,
	is_done?: GraphQLTypes["numeric"],
	publish_date?: GraphQLTypes["timestamp"],
	series_id?: number
};
	/** order by max() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_max_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	date?: GraphQLTypes["order_by"],
	/** in seconds */
	epoch?: GraphQLTypes["order_by"],
	gmt_offset?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_done?: GraphQLTypes["order_by"],
	publish_date?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_publish_schedules_min_fields"]: {
	__typename: "voyce_chapter_publish_schedules_min_fields",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	date?: GraphQLTypes["date"],
	/** in seconds */
	epoch?: GraphQLTypes["numeric"],
	gmt_offset?: GraphQLTypes["numeric"],
	id?: number,
	is_done?: GraphQLTypes["numeric"],
	publish_date?: GraphQLTypes["timestamp"],
	series_id?: number
};
	/** order by min() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_min_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	date?: GraphQLTypes["order_by"],
	/** in seconds */
	epoch?: GraphQLTypes["order_by"],
	gmt_offset?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_done?: GraphQLTypes["order_by"],
	publish_date?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_mutation_response"]: {
	__typename: "voyce_chapter_publish_schedules_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_publish_schedules"]>
};
	/** on_conflict condition type for table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_publish_schedules_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_publish_schedules_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_publish_schedules_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_publish_schedules". */
["voyce_chapter_publish_schedules_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	date?: GraphQLTypes["order_by"],
	epoch?: GraphQLTypes["order_by"],
	gmt_offset?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_done?: GraphQLTypes["order_by"],
	publish_date?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	time?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_publish_schedules */
["voyce_chapter_publish_schedules_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_select_column"]: voyce_chapter_publish_schedules_select_column;
	/** input type for updating data in table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_set_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	date?: GraphQLTypes["date"],
	/** in seconds */
	epoch?: GraphQLTypes["numeric"],
	gmt_offset?: GraphQLTypes["numeric"],
	id?: number,
	is_done?: GraphQLTypes["numeric"],
	publish_date?: GraphQLTypes["timestamp"],
	series_id?: number,
	time?: GraphQLTypes["time"]
};
	/** aggregate stddev on columns */
["voyce_chapter_publish_schedules_stddev_fields"]: {
	__typename: "voyce_chapter_publish_schedules_stddev_fields",
	chapter_id?: number,
	/** in seconds */
	epoch?: number,
	gmt_offset?: number,
	id?: number,
	is_done?: number,
	series_id?: number
};
	/** order by stddev() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_stddev_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	/** in seconds */
	epoch?: GraphQLTypes["order_by"],
	gmt_offset?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_done?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_publish_schedules_stddev_pop_fields"]: {
	__typename: "voyce_chapter_publish_schedules_stddev_pop_fields",
	chapter_id?: number,
	/** in seconds */
	epoch?: number,
	gmt_offset?: number,
	id?: number,
	is_done?: number,
	series_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_stddev_pop_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	/** in seconds */
	epoch?: GraphQLTypes["order_by"],
	gmt_offset?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_done?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_publish_schedules_stddev_samp_fields"]: {
	__typename: "voyce_chapter_publish_schedules_stddev_samp_fields",
	chapter_id?: number,
	/** in seconds */
	epoch?: number,
	gmt_offset?: number,
	id?: number,
	is_done?: number,
	series_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_stddev_samp_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	/** in seconds */
	epoch?: GraphQLTypes["order_by"],
	gmt_offset?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_done?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_publish_schedules" */
["voyce_chapter_publish_schedules_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_publish_schedules_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_publish_schedules_stream_cursor_value_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	date?: GraphQLTypes["date"],
	/** in seconds */
	epoch?: GraphQLTypes["numeric"],
	gmt_offset?: GraphQLTypes["numeric"],
	id?: number,
	is_done?: GraphQLTypes["numeric"],
	publish_date?: GraphQLTypes["timestamp"],
	series_id?: number,
	time?: GraphQLTypes["time"]
};
	/** aggregate sum on columns */
["voyce_chapter_publish_schedules_sum_fields"]: {
	__typename: "voyce_chapter_publish_schedules_sum_fields",
	chapter_id?: number,
	/** in seconds */
	epoch?: GraphQLTypes["numeric"],
	gmt_offset?: GraphQLTypes["numeric"],
	id?: number,
	is_done?: GraphQLTypes["numeric"],
	series_id?: number
};
	/** order by sum() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_sum_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	/** in seconds */
	epoch?: GraphQLTypes["order_by"],
	gmt_offset?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_done?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_update_column"]: voyce_chapter_publish_schedules_update_column;
	["voyce_chapter_publish_schedules_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_publish_schedules_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_publish_schedules_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_publish_schedules_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_publish_schedules_var_pop_fields"]: {
	__typename: "voyce_chapter_publish_schedules_var_pop_fields",
	chapter_id?: number,
	/** in seconds */
	epoch?: number,
	gmt_offset?: number,
	id?: number,
	is_done?: number,
	series_id?: number
};
	/** order by var_pop() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_var_pop_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	/** in seconds */
	epoch?: GraphQLTypes["order_by"],
	gmt_offset?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_done?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_publish_schedules_var_samp_fields"]: {
	__typename: "voyce_chapter_publish_schedules_var_samp_fields",
	chapter_id?: number,
	/** in seconds */
	epoch?: number,
	gmt_offset?: number,
	id?: number,
	is_done?: number,
	series_id?: number
};
	/** order by var_samp() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_var_samp_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	/** in seconds */
	epoch?: GraphQLTypes["order_by"],
	gmt_offset?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_done?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_publish_schedules_variance_fields"]: {
	__typename: "voyce_chapter_publish_schedules_variance_fields",
	chapter_id?: number,
	/** in seconds */
	epoch?: number,
	gmt_offset?: number,
	id?: number,
	is_done?: number,
	series_id?: number
};
	/** order by variance() on columns of table "voyce.chapter_publish_schedules" */
["voyce_chapter_publish_schedules_variance_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	/** in seconds */
	epoch?: GraphQLTypes["order_by"],
	gmt_offset?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_done?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_reactions" */
["voyce_chapter_reactions"]: {
	__typename: "voyce_chapter_reactions",
	/** An object relationship */
	chapter: GraphQLTypes["voyce_chapters"],
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	linked_entity_id: number,
	reaction: number,
	/** An object relationship */
	user?: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.chapter_reactions" */
["voyce_chapter_reactions_aggregate"]: {
	__typename: "voyce_chapter_reactions_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_reactions_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_reactions"]>
};
	["voyce_chapter_reactions_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_chapter_reactions_aggregate_bool_exp_count"]
};
	["voyce_chapter_reactions_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_chapter_reactions_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_reactions_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_reactions" */
["voyce_chapter_reactions_aggregate_fields"]: {
	__typename: "voyce_chapter_reactions_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_reactions_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_reactions_max_fields"],
	min?: GraphQLTypes["voyce_chapter_reactions_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_reactions_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_reactions_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_reactions_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_reactions_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_reactions_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_reactions_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_reactions_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_chapter_reactions_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_chapter_reactions_max_order_by"],
	min?: GraphQLTypes["voyce_chapter_reactions_min_order_by"],
	stddev?: GraphQLTypes["voyce_chapter_reactions_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_chapter_reactions_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_chapter_reactions_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_chapter_reactions_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_chapter_reactions_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_chapter_reactions_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_chapter_reactions_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_reactions" */
["voyce_chapter_reactions_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_chapter_reactions_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_reactions_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_reactions_avg_fields"]: {
	__typename: "voyce_chapter_reactions_avg_fields",
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_reactions". All fields are combined with a logical 'AND'. */
["voyce_chapter_reactions_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_reactions_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_reactions_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_reactions_bool_exp"]>,
	chapter?: GraphQLTypes["voyce_chapters_bool_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	linked_entity_id?: GraphQLTypes["Int_comparison_exp"],
	reaction?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_reactions" */
["voyce_chapter_reactions_constraint"]: voyce_chapter_reactions_constraint;
	/** Table to aggregate chapter reactions count by reaction type */
["voyce_chapter_reactions_count"]: {
	__typename: "voyce_chapter_reactions_count",
	/** An object relationship */
	chapter: GraphQLTypes["voyce_chapters"],
	chapter_id: number,
	count?: number,
	id: number,
	reaction?: number
};
	/** aggregated selection of "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_aggregate"]: {
	__typename: "voyce_chapter_reactions_count_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_reactions_count_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_reactions_count"]>
};
	/** aggregate fields of "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_aggregate_fields"]: {
	__typename: "voyce_chapter_reactions_count_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_reactions_count_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_reactions_count_max_fields"],
	min?: GraphQLTypes["voyce_chapter_reactions_count_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_reactions_count_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_reactions_count_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_reactions_count_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_reactions_count_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_reactions_count_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_reactions_count_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_reactions_count_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapter_reactions_count_avg_fields"]: {
	__typename: "voyce_chapter_reactions_count_avg_fields",
	chapter_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** Boolean expression to filter rows from the table "voyce.chapter_reactions_count". All fields are combined with a logical 'AND'. */
["voyce_chapter_reactions_count_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_reactions_count_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_reactions_count_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_reactions_count_bool_exp"]>,
	chapter?: GraphQLTypes["voyce_chapters_bool_exp"],
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	count?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	reaction?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_constraint"]: voyce_chapter_reactions_count_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_inc_input"]: {
		chapter_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** input type for inserting data into table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_insert_input"]: {
		chapter?: GraphQLTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** aggregate max on columns */
["voyce_chapter_reactions_count_max_fields"]: {
	__typename: "voyce_chapter_reactions_count_max_fields",
	chapter_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** aggregate min on columns */
["voyce_chapter_reactions_count_min_fields"]: {
	__typename: "voyce_chapter_reactions_count_min_fields",
	chapter_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** response of any mutation on the table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_mutation_response"]: {
	__typename: "voyce_chapter_reactions_count_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_reactions_count"]>
};
	/** on_conflict condition type for table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_reactions_count_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_reactions_count_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_reactions_count_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_reactions_count". */
["voyce_chapter_reactions_count_order_by"]: {
		chapter?: GraphQLTypes["voyce_chapters_order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_reactions_count */
["voyce_chapter_reactions_count_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_select_column"]: voyce_chapter_reactions_count_select_column;
	/** input type for updating data in table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_set_input"]: {
		chapter_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** aggregate stddev on columns */
["voyce_chapter_reactions_count_stddev_fields"]: {
	__typename: "voyce_chapter_reactions_count_stddev_fields",
	chapter_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_reactions_count_stddev_pop_fields"]: {
	__typename: "voyce_chapter_reactions_count_stddev_pop_fields",
	chapter_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_reactions_count_stddev_samp_fields"]: {
	__typename: "voyce_chapter_reactions_count_stddev_samp_fields",
	chapter_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** Streaming cursor of the table "voyce_chapter_reactions_count" */
["voyce_chapter_reactions_count_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_reactions_count_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_reactions_count_stream_cursor_value_input"]: {
		chapter_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** aggregate sum on columns */
["voyce_chapter_reactions_count_sum_fields"]: {
	__typename: "voyce_chapter_reactions_count_sum_fields",
	chapter_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** update columns of table "voyce.chapter_reactions_count" */
["voyce_chapter_reactions_count_update_column"]: voyce_chapter_reactions_count_update_column;
	["voyce_chapter_reactions_count_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_reactions_count_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_reactions_count_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_reactions_count_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_reactions_count_var_pop_fields"]: {
	__typename: "voyce_chapter_reactions_count_var_pop_fields",
	chapter_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** aggregate var_samp on columns */
["voyce_chapter_reactions_count_var_samp_fields"]: {
	__typename: "voyce_chapter_reactions_count_var_samp_fields",
	chapter_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** aggregate variance on columns */
["voyce_chapter_reactions_count_variance_fields"]: {
	__typename: "voyce_chapter_reactions_count_variance_fields",
	chapter_id?: number,
	count?: number,
	id?: number,
	reaction?: number
};
	/** input type for incrementing numeric columns in table "voyce.chapter_reactions" */
["voyce_chapter_reactions_inc_input"]: {
		id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.chapter_reactions" */
["voyce_chapter_reactions_insert_input"]: {
		chapter?: GraphQLTypes["voyce_chapters_obj_rel_insert_input"],
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_chapter_reactions_max_fields"]: {
	__typename: "voyce_chapter_reactions_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by max() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_max_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_reactions_min_fields"]: {
	__typename: "voyce_chapter_reactions_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by min() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_min_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_reactions" */
["voyce_chapter_reactions_mutation_response"]: {
	__typename: "voyce_chapter_reactions_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_reactions"]>
};
	/** on_conflict condition type for table "voyce.chapter_reactions" */
["voyce_chapter_reactions_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_reactions_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_reactions_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_reactions_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_reactions". */
["voyce_chapter_reactions_order_by"]: {
		chapter?: GraphQLTypes["voyce_chapters_order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_reactions */
["voyce_chapter_reactions_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_select_column"]: voyce_chapter_reactions_select_column;
	/** input type for updating data in table "voyce.chapter_reactions" */
["voyce_chapter_reactions_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_chapter_reactions_stddev_fields"]: {
	__typename: "voyce_chapter_reactions_stddev_fields",
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_reactions_stddev_pop_fields"]: {
	__typename: "voyce_chapter_reactions_stddev_pop_fields",
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_reactions_stddev_samp_fields"]: {
	__typename: "voyce_chapter_reactions_stddev_samp_fields",
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_reactions" */
["voyce_chapter_reactions_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_reactions_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_reactions_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_chapter_reactions_sum_fields"]: {
	__typename: "voyce_chapter_reactions_sum_fields",
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_update_column"]: voyce_chapter_reactions_update_column;
	["voyce_chapter_reactions_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_reactions_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_reactions_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_reactions_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_reactions_var_pop_fields"]: {
	__typename: "voyce_chapter_reactions_var_pop_fields",
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_reactions_var_samp_fields"]: {
	__typename: "voyce_chapter_reactions_var_samp_fields",
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_reactions_variance_fields"]: {
	__typename: "voyce_chapter_reactions_variance_fields",
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.chapter_reactions" */
["voyce_chapter_reactions_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.chapter_view_logs" */
["voyce_chapter_view_logs"]: {
	__typename: "voyce_chapter_view_logs",
	chapter_id: number,
	created_at: GraphQLTypes["timestamptz"],
	device_id?: string,
	gtag?: string,
	id: number,
	origin?: string,
	page?: string,
	panel_id?: number,
	series_id: number,
	session_api?: string,
	session_id?: string,
	source?: string,
	user_id: number,
	user_ip: string
};
	/** aggregated selection of "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_aggregate"]: {
	__typename: "voyce_chapter_view_logs_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_view_logs_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_view_logs"]>
};
	/** aggregate fields of "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_aggregate_fields"]: {
	__typename: "voyce_chapter_view_logs_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_view_logs_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_view_logs_max_fields"],
	min?: GraphQLTypes["voyce_chapter_view_logs_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_view_logs_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_view_logs_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_view_logs_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_view_logs_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_view_logs_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_view_logs_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_view_logs_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapter_view_logs_avg_fields"]: {
	__typename: "voyce_chapter_view_logs_avg_fields",
	chapter_id?: number,
	id?: number,
	panel_id?: number,
	series_id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.chapter_view_logs". All fields are combined with a logical 'AND'. */
["voyce_chapter_view_logs_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_view_logs_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_view_logs_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_view_logs_bool_exp"]>,
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	device_id?: GraphQLTypes["String_comparison_exp"],
	gtag?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	origin?: GraphQLTypes["String_comparison_exp"],
	page?: GraphQLTypes["String_comparison_exp"],
	panel_id?: GraphQLTypes["Int_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	session_api?: GraphQLTypes["String_comparison_exp"],
	session_id?: GraphQLTypes["String_comparison_exp"],
	source?: GraphQLTypes["String_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	user_ip?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_constraint"]: voyce_chapter_view_logs_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_inc_input"]: {
		chapter_id?: number,
	id?: number,
	panel_id?: number,
	series_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_insert_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	device_id?: string,
	gtag?: string,
	id?: number,
	origin?: string,
	page?: string,
	panel_id?: number,
	series_id?: number,
	session_api?: string,
	session_id?: string,
	source?: string,
	user_id?: number,
	user_ip?: string
};
	/** aggregate max on columns */
["voyce_chapter_view_logs_max_fields"]: {
	__typename: "voyce_chapter_view_logs_max_fields",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	device_id?: string,
	gtag?: string,
	id?: number,
	origin?: string,
	page?: string,
	panel_id?: number,
	series_id?: number,
	session_api?: string,
	session_id?: string,
	source?: string,
	user_id?: number,
	user_ip?: string
};
	/** aggregate min on columns */
["voyce_chapter_view_logs_min_fields"]: {
	__typename: "voyce_chapter_view_logs_min_fields",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	device_id?: string,
	gtag?: string,
	id?: number,
	origin?: string,
	page?: string,
	panel_id?: number,
	series_id?: number,
	session_api?: string,
	session_id?: string,
	source?: string,
	user_id?: number,
	user_ip?: string
};
	/** response of any mutation on the table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_mutation_response"]: {
	__typename: "voyce_chapter_view_logs_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_view_logs"]>
};
	/** on_conflict condition type for table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_view_logs_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_view_logs_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_view_logs_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_view_logs". */
["voyce_chapter_view_logs_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	device_id?: GraphQLTypes["order_by"],
	gtag?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	origin?: GraphQLTypes["order_by"],
	page?: GraphQLTypes["order_by"],
	panel_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	session_api?: GraphQLTypes["order_by"],
	session_id?: GraphQLTypes["order_by"],
	source?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	user_ip?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_view_logs */
["voyce_chapter_view_logs_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_select_column"]: voyce_chapter_view_logs_select_column;
	/** input type for updating data in table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_set_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	device_id?: string,
	gtag?: string,
	id?: number,
	origin?: string,
	page?: string,
	panel_id?: number,
	series_id?: number,
	session_api?: string,
	session_id?: string,
	source?: string,
	user_id?: number,
	user_ip?: string
};
	/** aggregate stddev on columns */
["voyce_chapter_view_logs_stddev_fields"]: {
	__typename: "voyce_chapter_view_logs_stddev_fields",
	chapter_id?: number,
	id?: number,
	panel_id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_view_logs_stddev_pop_fields"]: {
	__typename: "voyce_chapter_view_logs_stddev_pop_fields",
	chapter_id?: number,
	id?: number,
	panel_id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_view_logs_stddev_samp_fields"]: {
	__typename: "voyce_chapter_view_logs_stddev_samp_fields",
	chapter_id?: number,
	id?: number,
	panel_id?: number,
	series_id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_chapter_view_logs" */
["voyce_chapter_view_logs_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_view_logs_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_view_logs_stream_cursor_value_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	device_id?: string,
	gtag?: string,
	id?: number,
	origin?: string,
	page?: string,
	panel_id?: number,
	series_id?: number,
	session_api?: string,
	session_id?: string,
	source?: string,
	user_id?: number,
	user_ip?: string
};
	/** aggregate sum on columns */
["voyce_chapter_view_logs_sum_fields"]: {
	__typename: "voyce_chapter_view_logs_sum_fields",
	chapter_id?: number,
	id?: number,
	panel_id?: number,
	series_id?: number,
	user_id?: number
};
	/** update columns of table "voyce.chapter_view_logs" */
["voyce_chapter_view_logs_update_column"]: voyce_chapter_view_logs_update_column;
	["voyce_chapter_view_logs_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_view_logs_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_view_logs_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_view_logs_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_view_logs_var_pop_fields"]: {
	__typename: "voyce_chapter_view_logs_var_pop_fields",
	chapter_id?: number,
	id?: number,
	panel_id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_chapter_view_logs_var_samp_fields"]: {
	__typename: "voyce_chapter_view_logs_var_samp_fields",
	chapter_id?: number,
	id?: number,
	panel_id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_chapter_view_logs_variance_fields"]: {
	__typename: "voyce_chapter_view_logs_variance_fields",
	chapter_id?: number,
	id?: number,
	panel_id?: number,
	series_id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.chapter_views" */
["voyce_chapter_views"]: {
	__typename: "voyce_chapter_views",
	chapter_id?: number,
	id: number,
	is_read: boolean,
	page?: string,
	/** An object relationship */
	series?: GraphQLTypes["voyce_series"],
	series_id: number,
	updated_at?: GraphQLTypes["timestamp"],
	user_id: number,
	user_ip: GraphQLTypes["bigint"],
	view_hash?: string
};
	/** aggregated selection of "voyce.chapter_views" */
["voyce_chapter_views_aggregate"]: {
	__typename: "voyce_chapter_views_aggregate",
	aggregate?: GraphQLTypes["voyce_chapter_views_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapter_views"]>
};
	["voyce_chapter_views_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["voyce_chapter_views_aggregate_bool_exp_bool_and"],
	bool_or?: GraphQLTypes["voyce_chapter_views_aggregate_bool_exp_bool_or"],
	count?: GraphQLTypes["voyce_chapter_views_aggregate_bool_exp_count"]
};
	["voyce_chapter_views_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_views_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_chapter_views_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_views_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_chapter_views_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_chapter_views_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapter_views_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapter_views" */
["voyce_chapter_views_aggregate_fields"]: {
	__typename: "voyce_chapter_views_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapter_views_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapter_views_max_fields"],
	min?: GraphQLTypes["voyce_chapter_views_min_fields"],
	stddev?: GraphQLTypes["voyce_chapter_views_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapter_views_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapter_views_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapter_views_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapter_views_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapter_views_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapter_views_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapter_views" */
["voyce_chapter_views_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_chapter_views_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_chapter_views_max_order_by"],
	min?: GraphQLTypes["voyce_chapter_views_min_order_by"],
	stddev?: GraphQLTypes["voyce_chapter_views_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_chapter_views_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_chapter_views_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_chapter_views_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_chapter_views_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_chapter_views_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_chapter_views_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapter_views" */
["voyce_chapter_views_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_chapter_views_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapter_views_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapter_views_avg_fields"]: {
	__typename: "voyce_chapter_views_avg_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	user_ip?: number
};
	/** order by avg() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_avg_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	user_ip?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapter_views". All fields are combined with a logical 'AND'. */
["voyce_chapter_views_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapter_views_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapter_views_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapter_views_bool_exp"]>,
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_read?: GraphQLTypes["Boolean_comparison_exp"],
	page?: GraphQLTypes["String_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	updated_at?: GraphQLTypes["timestamp_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	user_ip?: GraphQLTypes["bigint_comparison_exp"],
	view_hash?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.chapter_views" */
["voyce_chapter_views_constraint"]: voyce_chapter_views_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapter_views" */
["voyce_chapter_views_inc_input"]: {
		chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	user_ip?: GraphQLTypes["bigint"]
};
	/** input type for inserting data into table "voyce.chapter_views" */
["voyce_chapter_views_insert_input"]: {
		chapter_id?: number,
	id?: number,
	is_read?: boolean,
	page?: string,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number,
	user_ip?: GraphQLTypes["bigint"],
	view_hash?: string
};
	/** aggregate max on columns */
["voyce_chapter_views_max_fields"]: {
	__typename: "voyce_chapter_views_max_fields",
	chapter_id?: number,
	id?: number,
	page?: string,
	series_id?: number,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number,
	user_ip?: GraphQLTypes["bigint"],
	view_hash?: string
};
	/** order by max() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_max_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	page?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	user_ip?: GraphQLTypes["order_by"],
	view_hash?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapter_views_min_fields"]: {
	__typename: "voyce_chapter_views_min_fields",
	chapter_id?: number,
	id?: number,
	page?: string,
	series_id?: number,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number,
	user_ip?: GraphQLTypes["bigint"],
	view_hash?: string
};
	/** order by min() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_min_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	page?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	user_ip?: GraphQLTypes["order_by"],
	view_hash?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapter_views" */
["voyce_chapter_views_mutation_response"]: {
	__typename: "voyce_chapter_views_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapter_views"]>
};
	/** on_conflict condition type for table "voyce.chapter_views" */
["voyce_chapter_views_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapter_views_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapter_views_update_column"]>,
	where?: GraphQLTypes["voyce_chapter_views_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapter_views". */
["voyce_chapter_views_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_read?: GraphQLTypes["order_by"],
	page?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	user_ip?: GraphQLTypes["order_by"],
	view_hash?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.chapter_views */
["voyce_chapter_views_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapter_views" */
["voyce_chapter_views_select_column"]: voyce_chapter_views_select_column;
	/** select "voyce_chapter_views_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapter_views" */
["voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_and_arguments_columns"]: voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_chapter_views_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapter_views" */
["voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_or_arguments_columns"]: voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.chapter_views" */
["voyce_chapter_views_set_input"]: {
		chapter_id?: number,
	id?: number,
	is_read?: boolean,
	page?: string,
	series_id?: number,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number,
	user_ip?: GraphQLTypes["bigint"],
	view_hash?: string
};
	/** aggregate stddev on columns */
["voyce_chapter_views_stddev_fields"]: {
	__typename: "voyce_chapter_views_stddev_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	user_ip?: number
};
	/** order by stddev() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_stddev_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	user_ip?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapter_views_stddev_pop_fields"]: {
	__typename: "voyce_chapter_views_stddev_pop_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	user_ip?: number
};
	/** order by stddev_pop() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_stddev_pop_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	user_ip?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapter_views_stddev_samp_fields"]: {
	__typename: "voyce_chapter_views_stddev_samp_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	user_ip?: number
};
	/** order by stddev_samp() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_stddev_samp_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	user_ip?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapter_views" */
["voyce_chapter_views_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapter_views_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapter_views_stream_cursor_value_input"]: {
		chapter_id?: number,
	id?: number,
	is_read?: boolean,
	page?: string,
	series_id?: number,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number,
	user_ip?: GraphQLTypes["bigint"],
	view_hash?: string
};
	/** aggregate sum on columns */
["voyce_chapter_views_sum_fields"]: {
	__typename: "voyce_chapter_views_sum_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	user_ip?: GraphQLTypes["bigint"]
};
	/** order by sum() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_sum_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	user_ip?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.chapter_views" */
["voyce_chapter_views_update_column"]: voyce_chapter_views_update_column;
	["voyce_chapter_views_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapter_views_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapter_views_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapter_views_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapter_views_var_pop_fields"]: {
	__typename: "voyce_chapter_views_var_pop_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	user_ip?: number
};
	/** order by var_pop() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_var_pop_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	user_ip?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapter_views_var_samp_fields"]: {
	__typename: "voyce_chapter_views_var_samp_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	user_ip?: number
};
	/** order by var_samp() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_var_samp_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	user_ip?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapter_views_variance_fields"]: {
	__typename: "voyce_chapter_views_variance_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	user_ip?: number
};
	/** order by variance() on columns of table "voyce.chapter_views" */
["voyce_chapter_views_variance_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	user_ip?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.chapters" */
["voyce_chapters"]: {
	__typename: "voyce_chapters",
	/** An array relationship */
	chapter_comments: Array<GraphQLTypes["voyce_chapter_comments"]>,
	/** An aggregate relationship */
	chapter_comments_aggregate: GraphQLTypes["voyce_chapter_comments_aggregate"],
	/** An array relationship */
	chapter_effects: Array<GraphQLTypes["voyce_chapter_effects"]>,
	/** An aggregate relationship */
	chapter_effects_aggregate: GraphQLTypes["voyce_chapter_effects_aggregate"],
	/** An array relationship */
	chapter_panels: Array<GraphQLTypes["voyce_chapter_panels"]>,
	/** An aggregate relationship */
	chapter_panels_aggregate: GraphQLTypes["voyce_chapter_panels_aggregate"],
	/** An array relationship */
	chapter_reactions: Array<GraphQLTypes["voyce_chapter_reactions"]>,
	/** An aggregate relationship */
	chapter_reactions_aggregate: GraphQLTypes["voyce_chapter_reactions_aggregate"],
	/** An object relationship */
	content?: GraphQLTypes["voyce_chapter_contents"],
	created_at: GraphQLTypes["timestamp"],
	description?: string,
	description_old?: string,
	displayed_exclusive: boolean,
	formatted_content?: string,
	formatted_content_v2?: string,
	formatted_content_v3?: string,
	has_text: boolean,
	id: number,
	/** An array relationship */
	images: Array<GraphQLTypes["voyce_chapter_images"]>,
	/** An aggregate relationship */
	images_aggregate: GraphQLTypes["voyce_chapter_images_aggregate"],
	is_deleted: boolean,
	/** An array relationship */
	likes: Array<GraphQLTypes["voyce_chapter_likes"]>,
	/** An aggregate relationship */
	likes_aggregate: GraphQLTypes["voyce_chapter_likes_aggregate"],
	/** An array relationship */
	locks: Array<GraphQLTypes["voyce_chapter_locks"]>,
	/** An aggregate relationship */
	locks_aggregate: GraphQLTypes["voyce_chapter_locks_aggregate"],
	order?: GraphQLTypes["smallint"],
	publish?: GraphQLTypes["smallint"],
	publish_date?: GraphQLTypes["timestamptz"],
	reactions_count?: number,
	reading_view: string,
	/** An array relationship */
	schedule: Array<GraphQLTypes["voyce_chapter_publish_schedules"]>,
	/** An aggregate relationship */
	schedule_aggregate: GraphQLTypes["voyce_chapter_publish_schedules_aggregate"],
	/** An object relationship */
	series?: GraphQLTypes["voyce_series"],
	series_id: number,
	status?: string,
	thumbnail?: string,
	title: string,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	view_count?: number,
	/** An array relationship */
	views: Array<GraphQLTypes["voyce_chapter_views"]>,
	/** An aggregate relationship */
	views_aggregate: GraphQLTypes["voyce_chapter_views_aggregate"]
};
	/** columns and relationships of "voyce.chapters_07252024" */
["voyce_chapters_07252024"]: {
	__typename: "voyce_chapters_07252024",
	created_at?: GraphQLTypes["timestamp"],
	description?: string,
	description_old?: string,
	displayed_exclusive?: boolean,
	formatted_content?: string,
	formatted_content_v2?: string,
	formatted_content_v3?: string,
	has_text?: boolean,
	id?: number,
	is_deleted?: boolean,
	order?: GraphQLTypes["smallint"],
	publish?: GraphQLTypes["smallint"],
	publish_date?: GraphQLTypes["timestamptz"],
	reactions_count?: number,
	reading_view?: string,
	series_id?: number,
	status?: string,
	thumbnail?: string,
	title?: string,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	view_count?: number
};
	/** aggregated selection of "voyce.chapters_07252024" */
["voyce_chapters_07252024_aggregate"]: {
	__typename: "voyce_chapters_07252024_aggregate",
	aggregate?: GraphQLTypes["voyce_chapters_07252024_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapters_07252024"]>
};
	/** aggregate fields of "voyce.chapters_07252024" */
["voyce_chapters_07252024_aggregate_fields"]: {
	__typename: "voyce_chapters_07252024_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapters_07252024_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapters_07252024_max_fields"],
	min?: GraphQLTypes["voyce_chapters_07252024_min_fields"],
	stddev?: GraphQLTypes["voyce_chapters_07252024_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapters_07252024_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapters_07252024_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapters_07252024_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapters_07252024_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapters_07252024_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapters_07252024_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_chapters_07252024_avg_fields"]: {
	__typename: "voyce_chapters_07252024_avg_fields",
	id?: number,
	order?: number,
	publish?: number,
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** Boolean expression to filter rows from the table "voyce.chapters_07252024". All fields are combined with a logical 'AND'. */
["voyce_chapters_07252024_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapters_07252024_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapters_07252024_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapters_07252024_bool_exp"]>,
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	description?: GraphQLTypes["String_comparison_exp"],
	description_old?: GraphQLTypes["String_comparison_exp"],
	displayed_exclusive?: GraphQLTypes["Boolean_comparison_exp"],
	formatted_content?: GraphQLTypes["String_comparison_exp"],
	formatted_content_v2?: GraphQLTypes["String_comparison_exp"],
	formatted_content_v3?: GraphQLTypes["String_comparison_exp"],
	has_text?: GraphQLTypes["Boolean_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_deleted?: GraphQLTypes["Boolean_comparison_exp"],
	order?: GraphQLTypes["smallint_comparison_exp"],
	publish?: GraphQLTypes["smallint_comparison_exp"],
	publish_date?: GraphQLTypes["timestamptz_comparison_exp"],
	reactions_count?: GraphQLTypes["Int_comparison_exp"],
	reading_view?: GraphQLTypes["String_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	status?: GraphQLTypes["String_comparison_exp"],
	thumbnail?: GraphQLTypes["String_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	view_count?: GraphQLTypes["Int_comparison_exp"]
};
	/** input type for incrementing numeric columns in table "voyce.chapters_07252024" */
["voyce_chapters_07252024_inc_input"]: {
		id?: number,
	order?: GraphQLTypes["smallint"],
	publish?: GraphQLTypes["smallint"],
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** input type for inserting data into table "voyce.chapters_07252024" */
["voyce_chapters_07252024_insert_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	description?: string,
	description_old?: string,
	displayed_exclusive?: boolean,
	formatted_content?: string,
	formatted_content_v2?: string,
	formatted_content_v3?: string,
	has_text?: boolean,
	id?: number,
	is_deleted?: boolean,
	order?: GraphQLTypes["smallint"],
	publish?: GraphQLTypes["smallint"],
	publish_date?: GraphQLTypes["timestamptz"],
	reactions_count?: number,
	reading_view?: string,
	series_id?: number,
	status?: string,
	thumbnail?: string,
	title?: string,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	view_count?: number
};
	/** aggregate max on columns */
["voyce_chapters_07252024_max_fields"]: {
	__typename: "voyce_chapters_07252024_max_fields",
	created_at?: GraphQLTypes["timestamp"],
	description?: string,
	description_old?: string,
	formatted_content?: string,
	formatted_content_v2?: string,
	formatted_content_v3?: string,
	id?: number,
	order?: GraphQLTypes["smallint"],
	publish?: GraphQLTypes["smallint"],
	publish_date?: GraphQLTypes["timestamptz"],
	reactions_count?: number,
	reading_view?: string,
	series_id?: number,
	status?: string,
	thumbnail?: string,
	title?: string,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	view_count?: number
};
	/** aggregate min on columns */
["voyce_chapters_07252024_min_fields"]: {
	__typename: "voyce_chapters_07252024_min_fields",
	created_at?: GraphQLTypes["timestamp"],
	description?: string,
	description_old?: string,
	formatted_content?: string,
	formatted_content_v2?: string,
	formatted_content_v3?: string,
	id?: number,
	order?: GraphQLTypes["smallint"],
	publish?: GraphQLTypes["smallint"],
	publish_date?: GraphQLTypes["timestamptz"],
	reactions_count?: number,
	reading_view?: string,
	series_id?: number,
	status?: string,
	thumbnail?: string,
	title?: string,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	view_count?: number
};
	/** response of any mutation on the table "voyce.chapters_07252024" */
["voyce_chapters_07252024_mutation_response"]: {
	__typename: "voyce_chapters_07252024_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapters_07252024"]>
};
	/** Ordering options when selecting data from "voyce.chapters_07252024". */
["voyce_chapters_07252024_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	description_old?: GraphQLTypes["order_by"],
	displayed_exclusive?: GraphQLTypes["order_by"],
	formatted_content?: GraphQLTypes["order_by"],
	formatted_content_v2?: GraphQLTypes["order_by"],
	formatted_content_v3?: GraphQLTypes["order_by"],
	has_text?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_deleted?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	publish?: GraphQLTypes["order_by"],
	publish_date?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	reading_view?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	status?: GraphQLTypes["order_by"],
	thumbnail?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	view_count?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.chapters_07252024" */
["voyce_chapters_07252024_select_column"]: voyce_chapters_07252024_select_column;
	/** input type for updating data in table "voyce.chapters_07252024" */
["voyce_chapters_07252024_set_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	description?: string,
	description_old?: string,
	displayed_exclusive?: boolean,
	formatted_content?: string,
	formatted_content_v2?: string,
	formatted_content_v3?: string,
	has_text?: boolean,
	id?: number,
	is_deleted?: boolean,
	order?: GraphQLTypes["smallint"],
	publish?: GraphQLTypes["smallint"],
	publish_date?: GraphQLTypes["timestamptz"],
	reactions_count?: number,
	reading_view?: string,
	series_id?: number,
	status?: string,
	thumbnail?: string,
	title?: string,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	view_count?: number
};
	/** aggregate stddev on columns */
["voyce_chapters_07252024_stddev_fields"]: {
	__typename: "voyce_chapters_07252024_stddev_fields",
	id?: number,
	order?: number,
	publish?: number,
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** aggregate stddev_pop on columns */
["voyce_chapters_07252024_stddev_pop_fields"]: {
	__typename: "voyce_chapters_07252024_stddev_pop_fields",
	id?: number,
	order?: number,
	publish?: number,
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** aggregate stddev_samp on columns */
["voyce_chapters_07252024_stddev_samp_fields"]: {
	__typename: "voyce_chapters_07252024_stddev_samp_fields",
	id?: number,
	order?: number,
	publish?: number,
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** Streaming cursor of the table "voyce_chapters_07252024" */
["voyce_chapters_07252024_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapters_07252024_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapters_07252024_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	description?: string,
	description_old?: string,
	displayed_exclusive?: boolean,
	formatted_content?: string,
	formatted_content_v2?: string,
	formatted_content_v3?: string,
	has_text?: boolean,
	id?: number,
	is_deleted?: boolean,
	order?: GraphQLTypes["smallint"],
	publish?: GraphQLTypes["smallint"],
	publish_date?: GraphQLTypes["timestamptz"],
	reactions_count?: number,
	reading_view?: string,
	series_id?: number,
	status?: string,
	thumbnail?: string,
	title?: string,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	view_count?: number
};
	/** aggregate sum on columns */
["voyce_chapters_07252024_sum_fields"]: {
	__typename: "voyce_chapters_07252024_sum_fields",
	id?: number,
	order?: GraphQLTypes["smallint"],
	publish?: GraphQLTypes["smallint"],
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	["voyce_chapters_07252024_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapters_07252024_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapters_07252024_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapters_07252024_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapters_07252024_var_pop_fields"]: {
	__typename: "voyce_chapters_07252024_var_pop_fields",
	id?: number,
	order?: number,
	publish?: number,
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** aggregate var_samp on columns */
["voyce_chapters_07252024_var_samp_fields"]: {
	__typename: "voyce_chapters_07252024_var_samp_fields",
	id?: number,
	order?: number,
	publish?: number,
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** aggregate variance on columns */
["voyce_chapters_07252024_variance_fields"]: {
	__typename: "voyce_chapters_07252024_variance_fields",
	id?: number,
	order?: number,
	publish?: number,
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** aggregated selection of "voyce.chapters" */
["voyce_chapters_aggregate"]: {
	__typename: "voyce_chapters_aggregate",
	aggregate?: GraphQLTypes["voyce_chapters_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_chapters"]>
};
	["voyce_chapters_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["voyce_chapters_aggregate_bool_exp_bool_and"],
	bool_or?: GraphQLTypes["voyce_chapters_aggregate_bool_exp_bool_or"],
	count?: GraphQLTypes["voyce_chapters_aggregate_bool_exp_count"]
};
	["voyce_chapters_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapters_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_chapters_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapters_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_chapters_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_chapters_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_chapters_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.chapters" */
["voyce_chapters_aggregate_fields"]: {
	__typename: "voyce_chapters_aggregate_fields",
	avg?: GraphQLTypes["voyce_chapters_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_chapters_max_fields"],
	min?: GraphQLTypes["voyce_chapters_min_fields"],
	stddev?: GraphQLTypes["voyce_chapters_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_chapters_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_chapters_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_chapters_sum_fields"],
	var_pop?: GraphQLTypes["voyce_chapters_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_chapters_var_samp_fields"],
	variance?: GraphQLTypes["voyce_chapters_variance_fields"]
};
	/** order by aggregate values of table "voyce.chapters" */
["voyce_chapters_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_chapters_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_chapters_max_order_by"],
	min?: GraphQLTypes["voyce_chapters_min_order_by"],
	stddev?: GraphQLTypes["voyce_chapters_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_chapters_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_chapters_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_chapters_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_chapters_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_chapters_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_chapters_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.chapters" */
["voyce_chapters_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_chapters_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapters_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_chapters_avg_fields"]: {
	__typename: "voyce_chapters_avg_fields",
	id?: number,
	order?: number,
	publish?: number,
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** order by avg() on columns of table "voyce.chapters" */
["voyce_chapters_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	publish?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	view_count?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.chapters". All fields are combined with a logical 'AND'. */
["voyce_chapters_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_chapters_bool_exp"]>,
	_not?: GraphQLTypes["voyce_chapters_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_chapters_bool_exp"]>,
	chapter_comments?: GraphQLTypes["voyce_chapter_comments_bool_exp"],
	chapter_comments_aggregate?: GraphQLTypes["voyce_chapter_comments_aggregate_bool_exp"],
	chapter_effects?: GraphQLTypes["voyce_chapter_effects_bool_exp"],
	chapter_effects_aggregate?: GraphQLTypes["voyce_chapter_effects_aggregate_bool_exp"],
	chapter_panels?: GraphQLTypes["voyce_chapter_panels_bool_exp"],
	chapter_panels_aggregate?: GraphQLTypes["voyce_chapter_panels_aggregate_bool_exp"],
	chapter_reactions?: GraphQLTypes["voyce_chapter_reactions_bool_exp"],
	chapter_reactions_aggregate?: GraphQLTypes["voyce_chapter_reactions_aggregate_bool_exp"],
	content?: GraphQLTypes["voyce_chapter_contents_bool_exp"],
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	description?: GraphQLTypes["String_comparison_exp"],
	description_old?: GraphQLTypes["String_comparison_exp"],
	displayed_exclusive?: GraphQLTypes["Boolean_comparison_exp"],
	formatted_content?: GraphQLTypes["String_comparison_exp"],
	formatted_content_v2?: GraphQLTypes["String_comparison_exp"],
	formatted_content_v3?: GraphQLTypes["String_comparison_exp"],
	has_text?: GraphQLTypes["Boolean_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	images?: GraphQLTypes["voyce_chapter_images_bool_exp"],
	images_aggregate?: GraphQLTypes["voyce_chapter_images_aggregate_bool_exp"],
	is_deleted?: GraphQLTypes["Boolean_comparison_exp"],
	likes?: GraphQLTypes["voyce_chapter_likes_bool_exp"],
	likes_aggregate?: GraphQLTypes["voyce_chapter_likes_aggregate_bool_exp"],
	locks?: GraphQLTypes["voyce_chapter_locks_bool_exp"],
	locks_aggregate?: GraphQLTypes["voyce_chapter_locks_aggregate_bool_exp"],
	order?: GraphQLTypes["smallint_comparison_exp"],
	publish?: GraphQLTypes["smallint_comparison_exp"],
	publish_date?: GraphQLTypes["timestamptz_comparison_exp"],
	reactions_count?: GraphQLTypes["Int_comparison_exp"],
	reading_view?: GraphQLTypes["String_comparison_exp"],
	schedule?: GraphQLTypes["voyce_chapter_publish_schedules_bool_exp"],
	schedule_aggregate?: GraphQLTypes["voyce_chapter_publish_schedules_aggregate_bool_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	status?: GraphQLTypes["String_comparison_exp"],
	thumbnail?: GraphQLTypes["String_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	view_count?: GraphQLTypes["Int_comparison_exp"],
	views?: GraphQLTypes["voyce_chapter_views_bool_exp"],
	views_aggregate?: GraphQLTypes["voyce_chapter_views_aggregate_bool_exp"]
};
	/** unique or primary key constraints on table "voyce.chapters" */
["voyce_chapters_constraint"]: voyce_chapters_constraint;
	/** input type for incrementing numeric columns in table "voyce.chapters" */
["voyce_chapters_inc_input"]: {
		id?: number,
	order?: GraphQLTypes["smallint"],
	publish?: GraphQLTypes["smallint"],
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** input type for inserting data into table "voyce.chapters" */
["voyce_chapters_insert_input"]: {
		chapter_comments?: GraphQLTypes["voyce_chapter_comments_arr_rel_insert_input"],
	chapter_effects?: GraphQLTypes["voyce_chapter_effects_arr_rel_insert_input"],
	chapter_panels?: GraphQLTypes["voyce_chapter_panels_arr_rel_insert_input"],
	chapter_reactions?: GraphQLTypes["voyce_chapter_reactions_arr_rel_insert_input"],
	content?: GraphQLTypes["voyce_chapter_contents_obj_rel_insert_input"],
	created_at?: GraphQLTypes["timestamp"],
	description?: string,
	description_old?: string,
	displayed_exclusive?: boolean,
	formatted_content?: string,
	formatted_content_v2?: string,
	formatted_content_v3?: string,
	has_text?: boolean,
	id?: number,
	images?: GraphQLTypes["voyce_chapter_images_arr_rel_insert_input"],
	is_deleted?: boolean,
	likes?: GraphQLTypes["voyce_chapter_likes_arr_rel_insert_input"],
	locks?: GraphQLTypes["voyce_chapter_locks_arr_rel_insert_input"],
	order?: GraphQLTypes["smallint"],
	publish?: GraphQLTypes["smallint"],
	publish_date?: GraphQLTypes["timestamptz"],
	reactions_count?: number,
	reading_view?: string,
	schedule?: GraphQLTypes["voyce_chapter_publish_schedules_arr_rel_insert_input"],
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	status?: string,
	thumbnail?: string,
	title?: string,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	view_count?: number,
	views?: GraphQLTypes["voyce_chapter_views_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["voyce_chapters_max_fields"]: {
	__typename: "voyce_chapters_max_fields",
	created_at?: GraphQLTypes["timestamp"],
	description?: string,
	description_old?: string,
	formatted_content?: string,
	formatted_content_v2?: string,
	formatted_content_v3?: string,
	id?: number,
	order?: GraphQLTypes["smallint"],
	publish?: GraphQLTypes["smallint"],
	publish_date?: GraphQLTypes["timestamptz"],
	reactions_count?: number,
	reading_view?: string,
	series_id?: number,
	status?: string,
	thumbnail?: string,
	title?: string,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	view_count?: number
};
	/** order by max() on columns of table "voyce.chapters" */
["voyce_chapters_max_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	description_old?: GraphQLTypes["order_by"],
	formatted_content?: GraphQLTypes["order_by"],
	formatted_content_v2?: GraphQLTypes["order_by"],
	formatted_content_v3?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	publish?: GraphQLTypes["order_by"],
	publish_date?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	reading_view?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	status?: GraphQLTypes["order_by"],
	thumbnail?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	view_count?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_chapters_min_fields"]: {
	__typename: "voyce_chapters_min_fields",
	created_at?: GraphQLTypes["timestamp"],
	description?: string,
	description_old?: string,
	formatted_content?: string,
	formatted_content_v2?: string,
	formatted_content_v3?: string,
	id?: number,
	order?: GraphQLTypes["smallint"],
	publish?: GraphQLTypes["smallint"],
	publish_date?: GraphQLTypes["timestamptz"],
	reactions_count?: number,
	reading_view?: string,
	series_id?: number,
	status?: string,
	thumbnail?: string,
	title?: string,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	view_count?: number
};
	/** order by min() on columns of table "voyce.chapters" */
["voyce_chapters_min_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	description_old?: GraphQLTypes["order_by"],
	formatted_content?: GraphQLTypes["order_by"],
	formatted_content_v2?: GraphQLTypes["order_by"],
	formatted_content_v3?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	publish?: GraphQLTypes["order_by"],
	publish_date?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	reading_view?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	status?: GraphQLTypes["order_by"],
	thumbnail?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	view_count?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.chapters" */
["voyce_chapters_mutation_response"]: {
	__typename: "voyce_chapters_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_chapters"]>
};
	/** input type for inserting object relation for remote table "voyce.chapters" */
["voyce_chapters_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_chapters_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_chapters_on_conflict"]
};
	/** on_conflict condition type for table "voyce.chapters" */
["voyce_chapters_on_conflict"]: {
		constraint: GraphQLTypes["voyce_chapters_constraint"],
	update_columns: Array<GraphQLTypes["voyce_chapters_update_column"]>,
	where?: GraphQLTypes["voyce_chapters_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.chapters". */
["voyce_chapters_order_by"]: {
		chapter_comments_aggregate?: GraphQLTypes["voyce_chapter_comments_aggregate_order_by"],
	chapter_effects_aggregate?: GraphQLTypes["voyce_chapter_effects_aggregate_order_by"],
	chapter_panels_aggregate?: GraphQLTypes["voyce_chapter_panels_aggregate_order_by"],
	chapter_reactions_aggregate?: GraphQLTypes["voyce_chapter_reactions_aggregate_order_by"],
	content?: GraphQLTypes["voyce_chapter_contents_order_by"],
	created_at?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	description_old?: GraphQLTypes["order_by"],
	displayed_exclusive?: GraphQLTypes["order_by"],
	formatted_content?: GraphQLTypes["order_by"],
	formatted_content_v2?: GraphQLTypes["order_by"],
	formatted_content_v3?: GraphQLTypes["order_by"],
	has_text?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	images_aggregate?: GraphQLTypes["voyce_chapter_images_aggregate_order_by"],
	is_deleted?: GraphQLTypes["order_by"],
	likes_aggregate?: GraphQLTypes["voyce_chapter_likes_aggregate_order_by"],
	locks_aggregate?: GraphQLTypes["voyce_chapter_locks_aggregate_order_by"],
	order?: GraphQLTypes["order_by"],
	publish?: GraphQLTypes["order_by"],
	publish_date?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	reading_view?: GraphQLTypes["order_by"],
	schedule_aggregate?: GraphQLTypes["voyce_chapter_publish_schedules_aggregate_order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	status?: GraphQLTypes["order_by"],
	thumbnail?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	view_count?: GraphQLTypes["order_by"],
	views_aggregate?: GraphQLTypes["voyce_chapter_views_aggregate_order_by"]
};
	/** primary key columns input for table: voyce.chapters */
["voyce_chapters_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.chapters" */
["voyce_chapters_select_column"]: voyce_chapters_select_column;
	/** select "voyce_chapters_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapters" */
["voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_and_arguments_columns"]: voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_chapters_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapters" */
["voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_or_arguments_columns"]: voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.chapters" */
["voyce_chapters_set_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	description?: string,
	description_old?: string,
	displayed_exclusive?: boolean,
	formatted_content?: string,
	formatted_content_v2?: string,
	formatted_content_v3?: string,
	has_text?: boolean,
	id?: number,
	is_deleted?: boolean,
	order?: GraphQLTypes["smallint"],
	publish?: GraphQLTypes["smallint"],
	publish_date?: GraphQLTypes["timestamptz"],
	reactions_count?: number,
	reading_view?: string,
	series_id?: number,
	status?: string,
	thumbnail?: string,
	title?: string,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	view_count?: number
};
	/** aggregate stddev on columns */
["voyce_chapters_stddev_fields"]: {
	__typename: "voyce_chapters_stddev_fields",
	id?: number,
	order?: number,
	publish?: number,
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** order by stddev() on columns of table "voyce.chapters" */
["voyce_chapters_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	publish?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	view_count?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_chapters_stddev_pop_fields"]: {
	__typename: "voyce_chapters_stddev_pop_fields",
	id?: number,
	order?: number,
	publish?: number,
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** order by stddev_pop() on columns of table "voyce.chapters" */
["voyce_chapters_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	publish?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	view_count?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_chapters_stddev_samp_fields"]: {
	__typename: "voyce_chapters_stddev_samp_fields",
	id?: number,
	order?: number,
	publish?: number,
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** order by stddev_samp() on columns of table "voyce.chapters" */
["voyce_chapters_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	publish?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	view_count?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_chapters" */
["voyce_chapters_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_chapters_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_chapters_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	description?: string,
	description_old?: string,
	displayed_exclusive?: boolean,
	formatted_content?: string,
	formatted_content_v2?: string,
	formatted_content_v3?: string,
	has_text?: boolean,
	id?: number,
	is_deleted?: boolean,
	order?: GraphQLTypes["smallint"],
	publish?: GraphQLTypes["smallint"],
	publish_date?: GraphQLTypes["timestamptz"],
	reactions_count?: number,
	reading_view?: string,
	series_id?: number,
	status?: string,
	thumbnail?: string,
	title?: string,
	type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	view_count?: number
};
	/** aggregate sum on columns */
["voyce_chapters_sum_fields"]: {
	__typename: "voyce_chapters_sum_fields",
	id?: number,
	order?: GraphQLTypes["smallint"],
	publish?: GraphQLTypes["smallint"],
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** order by sum() on columns of table "voyce.chapters" */
["voyce_chapters_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	publish?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	view_count?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.chapters" */
["voyce_chapters_update_column"]: voyce_chapters_update_column;
	["voyce_chapters_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_chapters_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_chapters_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_chapters_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_chapters_var_pop_fields"]: {
	__typename: "voyce_chapters_var_pop_fields",
	id?: number,
	order?: number,
	publish?: number,
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** order by var_pop() on columns of table "voyce.chapters" */
["voyce_chapters_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	publish?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	view_count?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_chapters_var_samp_fields"]: {
	__typename: "voyce_chapters_var_samp_fields",
	id?: number,
	order?: number,
	publish?: number,
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** order by var_samp() on columns of table "voyce.chapters" */
["voyce_chapters_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	publish?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	view_count?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_chapters_variance_fields"]: {
	__typename: "voyce_chapters_variance_fields",
	id?: number,
	order?: number,
	publish?: number,
	reactions_count?: number,
	series_id?: number,
	view_count?: number
};
	/** order by variance() on columns of table "voyce.chapters" */
["voyce_chapters_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	publish?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	view_count?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.collaborator_roles" */
["voyce_collaborator_roles"]: {
	__typename: "voyce_collaborator_roles",
	created_at?: GraphQLTypes["timestamptz"],
	id: number,
	role: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.collaborator_roles" */
["voyce_collaborator_roles_aggregate"]: {
	__typename: "voyce_collaborator_roles_aggregate",
	aggregate?: GraphQLTypes["voyce_collaborator_roles_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_collaborator_roles"]>
};
	/** aggregate fields of "voyce.collaborator_roles" */
["voyce_collaborator_roles_aggregate_fields"]: {
	__typename: "voyce_collaborator_roles_aggregate_fields",
	avg?: GraphQLTypes["voyce_collaborator_roles_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_collaborator_roles_max_fields"],
	min?: GraphQLTypes["voyce_collaborator_roles_min_fields"],
	stddev?: GraphQLTypes["voyce_collaborator_roles_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_collaborator_roles_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_collaborator_roles_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_collaborator_roles_sum_fields"],
	var_pop?: GraphQLTypes["voyce_collaborator_roles_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_collaborator_roles_var_samp_fields"],
	variance?: GraphQLTypes["voyce_collaborator_roles_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_collaborator_roles_avg_fields"]: {
	__typename: "voyce_collaborator_roles_avg_fields",
	id?: number
};
	/** Boolean expression to filter rows from the table "voyce.collaborator_roles". All fields are combined with a logical 'AND'. */
["voyce_collaborator_roles_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_collaborator_roles_bool_exp"]>,
	_not?: GraphQLTypes["voyce_collaborator_roles_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_collaborator_roles_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	role?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.collaborator_roles" */
["voyce_collaborator_roles_constraint"]: voyce_collaborator_roles_constraint;
	/** input type for incrementing numeric columns in table "voyce.collaborator_roles" */
["voyce_collaborator_roles_inc_input"]: {
		id?: number
};
	/** input type for inserting data into table "voyce.collaborator_roles" */
["voyce_collaborator_roles_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	role?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_collaborator_roles_max_fields"]: {
	__typename: "voyce_collaborator_roles_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	role?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_collaborator_roles_min_fields"]: {
	__typename: "voyce_collaborator_roles_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	role?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.collaborator_roles" */
["voyce_collaborator_roles_mutation_response"]: {
	__typename: "voyce_collaborator_roles_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_collaborator_roles"]>
};
	/** input type for inserting object relation for remote table "voyce.collaborator_roles" */
["voyce_collaborator_roles_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_collaborator_roles_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_collaborator_roles_on_conflict"]
};
	/** on_conflict condition type for table "voyce.collaborator_roles" */
["voyce_collaborator_roles_on_conflict"]: {
		constraint: GraphQLTypes["voyce_collaborator_roles_constraint"],
	update_columns: Array<GraphQLTypes["voyce_collaborator_roles_update_column"]>,
	where?: GraphQLTypes["voyce_collaborator_roles_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.collaborator_roles". */
["voyce_collaborator_roles_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	role?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.collaborator_roles */
["voyce_collaborator_roles_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.collaborator_roles" */
["voyce_collaborator_roles_select_column"]: voyce_collaborator_roles_select_column;
	/** input type for updating data in table "voyce.collaborator_roles" */
["voyce_collaborator_roles_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	role?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_collaborator_roles_stddev_fields"]: {
	__typename: "voyce_collaborator_roles_stddev_fields",
	id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_collaborator_roles_stddev_pop_fields"]: {
	__typename: "voyce_collaborator_roles_stddev_pop_fields",
	id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_collaborator_roles_stddev_samp_fields"]: {
	__typename: "voyce_collaborator_roles_stddev_samp_fields",
	id?: number
};
	/** Streaming cursor of the table "voyce_collaborator_roles" */
["voyce_collaborator_roles_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_collaborator_roles_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_collaborator_roles_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	role?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_collaborator_roles_sum_fields"]: {
	__typename: "voyce_collaborator_roles_sum_fields",
	id?: number
};
	/** update columns of table "voyce.collaborator_roles" */
["voyce_collaborator_roles_update_column"]: voyce_collaborator_roles_update_column;
	["voyce_collaborator_roles_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_collaborator_roles_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_collaborator_roles_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_collaborator_roles_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_collaborator_roles_var_pop_fields"]: {
	__typename: "voyce_collaborator_roles_var_pop_fields",
	id?: number
};
	/** aggregate var_samp on columns */
["voyce_collaborator_roles_var_samp_fields"]: {
	__typename: "voyce_collaborator_roles_var_samp_fields",
	id?: number
};
	/** aggregate variance on columns */
["voyce_collaborator_roles_variance_fields"]: {
	__typename: "voyce_collaborator_roles_variance_fields",
	id?: number
};
	/** columns and relationships of "voyce.comic_types" */
["voyce_comic_types"]: {
	__typename: "voyce_comic_types",
	created_at: GraphQLTypes["timestamp"],
	id: number,
	klaviyo_id?: string,
	title: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregated selection of "voyce.comic_types" */
["voyce_comic_types_aggregate"]: {
	__typename: "voyce_comic_types_aggregate",
	aggregate?: GraphQLTypes["voyce_comic_types_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_comic_types"]>
};
	/** aggregate fields of "voyce.comic_types" */
["voyce_comic_types_aggregate_fields"]: {
	__typename: "voyce_comic_types_aggregate_fields",
	avg?: GraphQLTypes["voyce_comic_types_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_comic_types_max_fields"],
	min?: GraphQLTypes["voyce_comic_types_min_fields"],
	stddev?: GraphQLTypes["voyce_comic_types_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_comic_types_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_comic_types_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_comic_types_sum_fields"],
	var_pop?: GraphQLTypes["voyce_comic_types_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_comic_types_var_samp_fields"],
	variance?: GraphQLTypes["voyce_comic_types_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_comic_types_avg_fields"]: {
	__typename: "voyce_comic_types_avg_fields",
	id?: number
};
	/** Boolean expression to filter rows from the table "voyce.comic_types". All fields are combined with a logical 'AND'. */
["voyce_comic_types_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_comic_types_bool_exp"]>,
	_not?: GraphQLTypes["voyce_comic_types_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_comic_types_bool_exp"]>,
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	klaviyo_id?: GraphQLTypes["String_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamp_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.comic_types" */
["voyce_comic_types_constraint"]: voyce_comic_types_constraint;
	/** input type for incrementing numeric columns in table "voyce.comic_types" */
["voyce_comic_types_inc_input"]: {
		id?: number
};
	/** input type for inserting data into table "voyce.comic_types" */
["voyce_comic_types_insert_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	id?: number,
	klaviyo_id?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate max on columns */
["voyce_comic_types_max_fields"]: {
	__typename: "voyce_comic_types_max_fields",
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	klaviyo_id?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate min on columns */
["voyce_comic_types_min_fields"]: {
	__typename: "voyce_comic_types_min_fields",
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	klaviyo_id?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** response of any mutation on the table "voyce.comic_types" */
["voyce_comic_types_mutation_response"]: {
	__typename: "voyce_comic_types_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_comic_types"]>
};
	/** input type for inserting object relation for remote table "voyce.comic_types" */
["voyce_comic_types_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_comic_types_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_comic_types_on_conflict"]
};
	/** on_conflict condition type for table "voyce.comic_types" */
["voyce_comic_types_on_conflict"]: {
		constraint: GraphQLTypes["voyce_comic_types_constraint"],
	update_columns: Array<GraphQLTypes["voyce_comic_types_update_column"]>,
	where?: GraphQLTypes["voyce_comic_types_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.comic_types". */
["voyce_comic_types_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	klaviyo_id?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.comic_types */
["voyce_comic_types_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.comic_types" */
["voyce_comic_types_select_column"]: voyce_comic_types_select_column;
	/** input type for updating data in table "voyce.comic_types" */
["voyce_comic_types_set_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	id?: number,
	klaviyo_id?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate stddev on columns */
["voyce_comic_types_stddev_fields"]: {
	__typename: "voyce_comic_types_stddev_fields",
	id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_comic_types_stddev_pop_fields"]: {
	__typename: "voyce_comic_types_stddev_pop_fields",
	id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_comic_types_stddev_samp_fields"]: {
	__typename: "voyce_comic_types_stddev_samp_fields",
	id?: number
};
	/** Streaming cursor of the table "voyce_comic_types" */
["voyce_comic_types_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_comic_types_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_comic_types_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	id?: number,
	klaviyo_id?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate sum on columns */
["voyce_comic_types_sum_fields"]: {
	__typename: "voyce_comic_types_sum_fields",
	id?: number
};
	/** update columns of table "voyce.comic_types" */
["voyce_comic_types_update_column"]: voyce_comic_types_update_column;
	["voyce_comic_types_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_comic_types_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_comic_types_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_comic_types_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_comic_types_var_pop_fields"]: {
	__typename: "voyce_comic_types_var_pop_fields",
	id?: number
};
	/** aggregate var_samp on columns */
["voyce_comic_types_var_samp_fields"]: {
	__typename: "voyce_comic_types_var_samp_fields",
	id?: number
};
	/** aggregate variance on columns */
["voyce_comic_types_variance_fields"]: {
	__typename: "voyce_comic_types_variance_fields",
	id?: number
};
	/** columns and relationships of "voyce.comments_top_mat" */
["voyce_comments_top_mat"]: {
	__typename: "voyce_comments_top_mat",
	comment_type?: string,
	id?: number,
	reaction_count?: number,
	user_id?: number
};
	/** aggregated selection of "voyce.comments_top_mat" */
["voyce_comments_top_mat_aggregate"]: {
	__typename: "voyce_comments_top_mat_aggregate",
	aggregate?: GraphQLTypes["voyce_comments_top_mat_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_comments_top_mat"]>
};
	/** aggregate fields of "voyce.comments_top_mat" */
["voyce_comments_top_mat_aggregate_fields"]: {
	__typename: "voyce_comments_top_mat_aggregate_fields",
	avg?: GraphQLTypes["voyce_comments_top_mat_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_comments_top_mat_max_fields"],
	min?: GraphQLTypes["voyce_comments_top_mat_min_fields"],
	stddev?: GraphQLTypes["voyce_comments_top_mat_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_comments_top_mat_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_comments_top_mat_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_comments_top_mat_sum_fields"],
	var_pop?: GraphQLTypes["voyce_comments_top_mat_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_comments_top_mat_var_samp_fields"],
	variance?: GraphQLTypes["voyce_comments_top_mat_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_comments_top_mat_avg_fields"]: {
	__typename: "voyce_comments_top_mat_avg_fields",
	id?: number,
	reaction_count?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.comments_top_mat". All fields are combined with a logical 'AND'. */
["voyce_comments_top_mat_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_comments_top_mat_bool_exp"]>,
	_not?: GraphQLTypes["voyce_comments_top_mat_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_comments_top_mat_bool_exp"]>,
	comment_type?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	reaction_count?: GraphQLTypes["Int_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate max on columns */
["voyce_comments_top_mat_max_fields"]: {
	__typename: "voyce_comments_top_mat_max_fields",
	comment_type?: string,
	id?: number,
	reaction_count?: number,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_comments_top_mat_min_fields"]: {
	__typename: "voyce_comments_top_mat_min_fields",
	comment_type?: string,
	id?: number,
	reaction_count?: number,
	user_id?: number
};
	/** Ordering options when selecting data from "voyce.comments_top_mat". */
["voyce_comments_top_mat_order_by"]: {
		comment_type?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.comments_top_mat" */
["voyce_comments_top_mat_select_column"]: voyce_comments_top_mat_select_column;
	/** aggregate stddev on columns */
["voyce_comments_top_mat_stddev_fields"]: {
	__typename: "voyce_comments_top_mat_stddev_fields",
	id?: number,
	reaction_count?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_comments_top_mat_stddev_pop_fields"]: {
	__typename: "voyce_comments_top_mat_stddev_pop_fields",
	id?: number,
	reaction_count?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_comments_top_mat_stddev_samp_fields"]: {
	__typename: "voyce_comments_top_mat_stddev_samp_fields",
	id?: number,
	reaction_count?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_comments_top_mat" */
["voyce_comments_top_mat_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_comments_top_mat_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_comments_top_mat_stream_cursor_value_input"]: {
		comment_type?: string,
	id?: number,
	reaction_count?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_comments_top_mat_sum_fields"]: {
	__typename: "voyce_comments_top_mat_sum_fields",
	id?: number,
	reaction_count?: number,
	user_id?: number
};
	/** aggregate var_pop on columns */
["voyce_comments_top_mat_var_pop_fields"]: {
	__typename: "voyce_comments_top_mat_var_pop_fields",
	id?: number,
	reaction_count?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_comments_top_mat_var_samp_fields"]: {
	__typename: "voyce_comments_top_mat_var_samp_fields",
	id?: number,
	reaction_count?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_comments_top_mat_variance_fields"]: {
	__typename: "voyce_comments_top_mat_variance_fields",
	id?: number,
	reaction_count?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.community_blurbs" */
["voyce_community_blurbs"]: {
	__typename: "voyce_community_blurbs",
	avatar: string,
	content: string,
	id: number,
	name: string,
	order: number,
	title: string,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.community_blurbs" */
["voyce_community_blurbs_aggregate"]: {
	__typename: "voyce_community_blurbs_aggregate",
	aggregate?: GraphQLTypes["voyce_community_blurbs_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_community_blurbs"]>
};
	/** aggregate fields of "voyce.community_blurbs" */
["voyce_community_blurbs_aggregate_fields"]: {
	__typename: "voyce_community_blurbs_aggregate_fields",
	avg?: GraphQLTypes["voyce_community_blurbs_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_community_blurbs_max_fields"],
	min?: GraphQLTypes["voyce_community_blurbs_min_fields"],
	stddev?: GraphQLTypes["voyce_community_blurbs_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_community_blurbs_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_community_blurbs_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_community_blurbs_sum_fields"],
	var_pop?: GraphQLTypes["voyce_community_blurbs_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_community_blurbs_var_samp_fields"],
	variance?: GraphQLTypes["voyce_community_blurbs_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_community_blurbs_avg_fields"]: {
	__typename: "voyce_community_blurbs_avg_fields",
	id?: number,
	order?: number
};
	/** Boolean expression to filter rows from the table "voyce.community_blurbs". All fields are combined with a logical 'AND'. */
["voyce_community_blurbs_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_community_blurbs_bool_exp"]>,
	_not?: GraphQLTypes["voyce_community_blurbs_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_community_blurbs_bool_exp"]>,
	avatar?: GraphQLTypes["String_comparison_exp"],
	content?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	order?: GraphQLTypes["Int_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.community_blurbs" */
["voyce_community_blurbs_constraint"]: voyce_community_blurbs_constraint;
	/** input type for incrementing numeric columns in table "voyce.community_blurbs" */
["voyce_community_blurbs_inc_input"]: {
		id?: number,
	order?: number
};
	/** input type for inserting data into table "voyce.community_blurbs" */
["voyce_community_blurbs_insert_input"]: {
		avatar?: string,
	content?: string,
	id?: number,
	name?: string,
	order?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_community_blurbs_max_fields"]: {
	__typename: "voyce_community_blurbs_max_fields",
	avatar?: string,
	content?: string,
	id?: number,
	name?: string,
	order?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_community_blurbs_min_fields"]: {
	__typename: "voyce_community_blurbs_min_fields",
	avatar?: string,
	content?: string,
	id?: number,
	name?: string,
	order?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.community_blurbs" */
["voyce_community_blurbs_mutation_response"]: {
	__typename: "voyce_community_blurbs_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_community_blurbs"]>
};
	/** on_conflict condition type for table "voyce.community_blurbs" */
["voyce_community_blurbs_on_conflict"]: {
		constraint: GraphQLTypes["voyce_community_blurbs_constraint"],
	update_columns: Array<GraphQLTypes["voyce_community_blurbs_update_column"]>,
	where?: GraphQLTypes["voyce_community_blurbs_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.community_blurbs". */
["voyce_community_blurbs_order_by"]: {
		avatar?: GraphQLTypes["order_by"],
	content?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.community_blurbs */
["voyce_community_blurbs_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.community_blurbs" */
["voyce_community_blurbs_select_column"]: voyce_community_blurbs_select_column;
	/** input type for updating data in table "voyce.community_blurbs" */
["voyce_community_blurbs_set_input"]: {
		avatar?: string,
	content?: string,
	id?: number,
	name?: string,
	order?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_community_blurbs_stddev_fields"]: {
	__typename: "voyce_community_blurbs_stddev_fields",
	id?: number,
	order?: number
};
	/** aggregate stddev_pop on columns */
["voyce_community_blurbs_stddev_pop_fields"]: {
	__typename: "voyce_community_blurbs_stddev_pop_fields",
	id?: number,
	order?: number
};
	/** aggregate stddev_samp on columns */
["voyce_community_blurbs_stddev_samp_fields"]: {
	__typename: "voyce_community_blurbs_stddev_samp_fields",
	id?: number,
	order?: number
};
	/** Streaming cursor of the table "voyce_community_blurbs" */
["voyce_community_blurbs_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_community_blurbs_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_community_blurbs_stream_cursor_value_input"]: {
		avatar?: string,
	content?: string,
	id?: number,
	name?: string,
	order?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_community_blurbs_sum_fields"]: {
	__typename: "voyce_community_blurbs_sum_fields",
	id?: number,
	order?: number
};
	/** update columns of table "voyce.community_blurbs" */
["voyce_community_blurbs_update_column"]: voyce_community_blurbs_update_column;
	["voyce_community_blurbs_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_community_blurbs_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_community_blurbs_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_community_blurbs_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_community_blurbs_var_pop_fields"]: {
	__typename: "voyce_community_blurbs_var_pop_fields",
	id?: number,
	order?: number
};
	/** aggregate var_samp on columns */
["voyce_community_blurbs_var_samp_fields"]: {
	__typename: "voyce_community_blurbs_var_samp_fields",
	id?: number,
	order?: number
};
	/** aggregate variance on columns */
["voyce_community_blurbs_variance_fields"]: {
	__typename: "voyce_community_blurbs_variance_fields",
	id?: number,
	order?: number
};
	/** columns and relationships of "voyce.contact_us" */
["voyce_contact_us"]: {
	__typename: "voyce_contact_us",
	created_at: GraphQLTypes["timestamptz"],
	email: string,
	first_name: string,
	id: number,
	last_name: string,
	message: string,
	number: string,
	reason: string
};
	/** aggregated selection of "voyce.contact_us" */
["voyce_contact_us_aggregate"]: {
	__typename: "voyce_contact_us_aggregate",
	aggregate?: GraphQLTypes["voyce_contact_us_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_contact_us"]>
};
	/** aggregate fields of "voyce.contact_us" */
["voyce_contact_us_aggregate_fields"]: {
	__typename: "voyce_contact_us_aggregate_fields",
	avg?: GraphQLTypes["voyce_contact_us_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_contact_us_max_fields"],
	min?: GraphQLTypes["voyce_contact_us_min_fields"],
	stddev?: GraphQLTypes["voyce_contact_us_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_contact_us_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_contact_us_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_contact_us_sum_fields"],
	var_pop?: GraphQLTypes["voyce_contact_us_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_contact_us_var_samp_fields"],
	variance?: GraphQLTypes["voyce_contact_us_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_contact_us_avg_fields"]: {
	__typename: "voyce_contact_us_avg_fields",
	id?: number
};
	/** Boolean expression to filter rows from the table "voyce.contact_us". All fields are combined with a logical 'AND'. */
["voyce_contact_us_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_contact_us_bool_exp"]>,
	_not?: GraphQLTypes["voyce_contact_us_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_contact_us_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	email?: GraphQLTypes["String_comparison_exp"],
	first_name?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	last_name?: GraphQLTypes["String_comparison_exp"],
	message?: GraphQLTypes["String_comparison_exp"],
	number?: GraphQLTypes["String_comparison_exp"],
	reason?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.contact_us" */
["voyce_contact_us_constraint"]: voyce_contact_us_constraint;
	/** input type for incrementing numeric columns in table "voyce.contact_us" */
["voyce_contact_us_inc_input"]: {
		id?: number
};
	/** input type for inserting data into table "voyce.contact_us" */
["voyce_contact_us_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	first_name?: string,
	id?: number,
	last_name?: string,
	message?: string,
	number?: string,
	reason?: string
};
	/** aggregate max on columns */
["voyce_contact_us_max_fields"]: {
	__typename: "voyce_contact_us_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	first_name?: string,
	id?: number,
	last_name?: string,
	message?: string,
	number?: string,
	reason?: string
};
	/** aggregate min on columns */
["voyce_contact_us_min_fields"]: {
	__typename: "voyce_contact_us_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	first_name?: string,
	id?: number,
	last_name?: string,
	message?: string,
	number?: string,
	reason?: string
};
	/** response of any mutation on the table "voyce.contact_us" */
["voyce_contact_us_mutation_response"]: {
	__typename: "voyce_contact_us_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_contact_us"]>
};
	/** on_conflict condition type for table "voyce.contact_us" */
["voyce_contact_us_on_conflict"]: {
		constraint: GraphQLTypes["voyce_contact_us_constraint"],
	update_columns: Array<GraphQLTypes["voyce_contact_us_update_column"]>,
	where?: GraphQLTypes["voyce_contact_us_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.contact_us". */
["voyce_contact_us_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	email?: GraphQLTypes["order_by"],
	first_name?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_name?: GraphQLTypes["order_by"],
	message?: GraphQLTypes["order_by"],
	number?: GraphQLTypes["order_by"],
	reason?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.contact_us */
["voyce_contact_us_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.contact_us" */
["voyce_contact_us_select_column"]: voyce_contact_us_select_column;
	/** input type for updating data in table "voyce.contact_us" */
["voyce_contact_us_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	first_name?: string,
	id?: number,
	last_name?: string,
	message?: string,
	number?: string,
	reason?: string
};
	/** aggregate stddev on columns */
["voyce_contact_us_stddev_fields"]: {
	__typename: "voyce_contact_us_stddev_fields",
	id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_contact_us_stddev_pop_fields"]: {
	__typename: "voyce_contact_us_stddev_pop_fields",
	id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_contact_us_stddev_samp_fields"]: {
	__typename: "voyce_contact_us_stddev_samp_fields",
	id?: number
};
	/** Streaming cursor of the table "voyce_contact_us" */
["voyce_contact_us_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_contact_us_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_contact_us_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	first_name?: string,
	id?: number,
	last_name?: string,
	message?: string,
	number?: string,
	reason?: string
};
	/** aggregate sum on columns */
["voyce_contact_us_sum_fields"]: {
	__typename: "voyce_contact_us_sum_fields",
	id?: number
};
	/** update columns of table "voyce.contact_us" */
["voyce_contact_us_update_column"]: voyce_contact_us_update_column;
	["voyce_contact_us_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_contact_us_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_contact_us_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_contact_us_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_contact_us_var_pop_fields"]: {
	__typename: "voyce_contact_us_var_pop_fields",
	id?: number
};
	/** aggregate var_samp on columns */
["voyce_contact_us_var_samp_fields"]: {
	__typename: "voyce_contact_us_var_samp_fields",
	id?: number
};
	/** aggregate variance on columns */
["voyce_contact_us_variance_fields"]: {
	__typename: "voyce_contact_us_variance_fields",
	id?: number
};
	/** columns and relationships of "voyce.content_report" */
["voyce_content_report"]: {
	__typename: "voyce_content_report",
	created_at: GraphQLTypes["timestamptz"],
	extras?: string,
	id: number,
	linked_id: number,
	reason: string,
	type: string,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.content_report" */
["voyce_content_report_aggregate"]: {
	__typename: "voyce_content_report_aggregate",
	aggregate?: GraphQLTypes["voyce_content_report_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_content_report"]>
};
	/** aggregate fields of "voyce.content_report" */
["voyce_content_report_aggregate_fields"]: {
	__typename: "voyce_content_report_aggregate_fields",
	avg?: GraphQLTypes["voyce_content_report_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_content_report_max_fields"],
	min?: GraphQLTypes["voyce_content_report_min_fields"],
	stddev?: GraphQLTypes["voyce_content_report_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_content_report_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_content_report_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_content_report_sum_fields"],
	var_pop?: GraphQLTypes["voyce_content_report_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_content_report_var_samp_fields"],
	variance?: GraphQLTypes["voyce_content_report_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_content_report_avg_fields"]: {
	__typename: "voyce_content_report_avg_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.content_report". All fields are combined with a logical 'AND'. */
["voyce_content_report_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_content_report_bool_exp"]>,
	_not?: GraphQLTypes["voyce_content_report_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_content_report_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	extras?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	linked_id?: GraphQLTypes["Int_comparison_exp"],
	reason?: GraphQLTypes["String_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.content_report" */
["voyce_content_report_constraint"]: voyce_content_report_constraint;
	/** input type for incrementing numeric columns in table "voyce.content_report" */
["voyce_content_report_inc_input"]: {
		id?: number,
	linked_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.content_report" */
["voyce_content_report_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	extras?: string,
	id?: number,
	linked_id?: number,
	reason?: string,
	type?: string,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_content_report_max_fields"]: {
	__typename: "voyce_content_report_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	extras?: string,
	id?: number,
	linked_id?: number,
	reason?: string,
	type?: string,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_content_report_min_fields"]: {
	__typename: "voyce_content_report_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	extras?: string,
	id?: number,
	linked_id?: number,
	reason?: string,
	type?: string,
	user_id?: number
};
	/** response of any mutation on the table "voyce.content_report" */
["voyce_content_report_mutation_response"]: {
	__typename: "voyce_content_report_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_content_report"]>
};
	/** on_conflict condition type for table "voyce.content_report" */
["voyce_content_report_on_conflict"]: {
		constraint: GraphQLTypes["voyce_content_report_constraint"],
	update_columns: Array<GraphQLTypes["voyce_content_report_update_column"]>,
	where?: GraphQLTypes["voyce_content_report_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.content_report". */
["voyce_content_report_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	extras?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	reason?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.content_report */
["voyce_content_report_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.content_report" */
["voyce_content_report_select_column"]: voyce_content_report_select_column;
	/** input type for updating data in table "voyce.content_report" */
["voyce_content_report_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	extras?: string,
	id?: number,
	linked_id?: number,
	reason?: string,
	type?: string,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_content_report_stddev_fields"]: {
	__typename: "voyce_content_report_stddev_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_content_report_stddev_pop_fields"]: {
	__typename: "voyce_content_report_stddev_pop_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_content_report_stddev_samp_fields"]: {
	__typename: "voyce_content_report_stddev_samp_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_content_report" */
["voyce_content_report_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_content_report_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_content_report_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	extras?: string,
	id?: number,
	linked_id?: number,
	reason?: string,
	type?: string,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_content_report_sum_fields"]: {
	__typename: "voyce_content_report_sum_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** update columns of table "voyce.content_report" */
["voyce_content_report_update_column"]: voyce_content_report_update_column;
	["voyce_content_report_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_content_report_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_content_report_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_content_report_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_content_report_var_pop_fields"]: {
	__typename: "voyce_content_report_var_pop_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_content_report_var_samp_fields"]: {
	__typename: "voyce_content_report_var_samp_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_content_report_variance_fields"]: {
	__typename: "voyce_content_report_variance_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** Table for storing data about contest submits */
["voyce_contest_data"]: {
	__typename: "voyce_contest_data",
	age?: number,
	created_at: GraphQLTypes["timestamp"],
	discord_username?: string,
	first_arc?: string,
	id: number,
	name?: string,
	pdf?: string,
	social_media?: string,
	story_pitch?: string,
	story_summary?: string,
	target_audience?: string,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number,
	where_did_hear?: string
};
	/** aggregated selection of "voyce.contest_data" */
["voyce_contest_data_aggregate"]: {
	__typename: "voyce_contest_data_aggregate",
	aggregate?: GraphQLTypes["voyce_contest_data_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_contest_data"]>
};
	/** aggregate fields of "voyce.contest_data" */
["voyce_contest_data_aggregate_fields"]: {
	__typename: "voyce_contest_data_aggregate_fields",
	avg?: GraphQLTypes["voyce_contest_data_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_contest_data_max_fields"],
	min?: GraphQLTypes["voyce_contest_data_min_fields"],
	stddev?: GraphQLTypes["voyce_contest_data_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_contest_data_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_contest_data_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_contest_data_sum_fields"],
	var_pop?: GraphQLTypes["voyce_contest_data_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_contest_data_var_samp_fields"],
	variance?: GraphQLTypes["voyce_contest_data_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_contest_data_avg_fields"]: {
	__typename: "voyce_contest_data_avg_fields",
	age?: number,
	id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.contest_data". All fields are combined with a logical 'AND'. */
["voyce_contest_data_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_contest_data_bool_exp"]>,
	_not?: GraphQLTypes["voyce_contest_data_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_contest_data_bool_exp"]>,
	age?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	discord_username?: GraphQLTypes["String_comparison_exp"],
	first_arc?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	pdf?: GraphQLTypes["String_comparison_exp"],
	social_media?: GraphQLTypes["String_comparison_exp"],
	story_pitch?: GraphQLTypes["String_comparison_exp"],
	story_summary?: GraphQLTypes["String_comparison_exp"],
	target_audience?: GraphQLTypes["String_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	where_did_hear?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.contest_data" */
["voyce_contest_data_constraint"]: voyce_contest_data_constraint;
	/** input type for incrementing numeric columns in table "voyce.contest_data" */
["voyce_contest_data_inc_input"]: {
		age?: number,
	id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.contest_data" */
["voyce_contest_data_insert_input"]: {
		age?: number,
	created_at?: GraphQLTypes["timestamp"],
	discord_username?: string,
	first_arc?: string,
	id?: number,
	name?: string,
	pdf?: string,
	social_media?: string,
	story_pitch?: string,
	story_summary?: string,
	target_audience?: string,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number,
	where_did_hear?: string
};
	/** aggregate max on columns */
["voyce_contest_data_max_fields"]: {
	__typename: "voyce_contest_data_max_fields",
	age?: number,
	created_at?: GraphQLTypes["timestamp"],
	discord_username?: string,
	first_arc?: string,
	id?: number,
	name?: string,
	pdf?: string,
	social_media?: string,
	story_pitch?: string,
	story_summary?: string,
	target_audience?: string,
	user_id?: number,
	where_did_hear?: string
};
	/** aggregate min on columns */
["voyce_contest_data_min_fields"]: {
	__typename: "voyce_contest_data_min_fields",
	age?: number,
	created_at?: GraphQLTypes["timestamp"],
	discord_username?: string,
	first_arc?: string,
	id?: number,
	name?: string,
	pdf?: string,
	social_media?: string,
	story_pitch?: string,
	story_summary?: string,
	target_audience?: string,
	user_id?: number,
	where_did_hear?: string
};
	/** response of any mutation on the table "voyce.contest_data" */
["voyce_contest_data_mutation_response"]: {
	__typename: "voyce_contest_data_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_contest_data"]>
};
	/** on_conflict condition type for table "voyce.contest_data" */
["voyce_contest_data_on_conflict"]: {
		constraint: GraphQLTypes["voyce_contest_data_constraint"],
	update_columns: Array<GraphQLTypes["voyce_contest_data_update_column"]>,
	where?: GraphQLTypes["voyce_contest_data_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.contest_data". */
["voyce_contest_data_order_by"]: {
		age?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	discord_username?: GraphQLTypes["order_by"],
	first_arc?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	pdf?: GraphQLTypes["order_by"],
	social_media?: GraphQLTypes["order_by"],
	story_pitch?: GraphQLTypes["order_by"],
	story_summary?: GraphQLTypes["order_by"],
	target_audience?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"],
	where_did_hear?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.contest_data */
["voyce_contest_data_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.contest_data" */
["voyce_contest_data_select_column"]: voyce_contest_data_select_column;
	/** input type for updating data in table "voyce.contest_data" */
["voyce_contest_data_set_input"]: {
		age?: number,
	created_at?: GraphQLTypes["timestamp"],
	discord_username?: string,
	first_arc?: string,
	id?: number,
	name?: string,
	pdf?: string,
	social_media?: string,
	story_pitch?: string,
	story_summary?: string,
	target_audience?: string,
	user_id?: number,
	where_did_hear?: string
};
	/** aggregate stddev on columns */
["voyce_contest_data_stddev_fields"]: {
	__typename: "voyce_contest_data_stddev_fields",
	age?: number,
	id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_contest_data_stddev_pop_fields"]: {
	__typename: "voyce_contest_data_stddev_pop_fields",
	age?: number,
	id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_contest_data_stddev_samp_fields"]: {
	__typename: "voyce_contest_data_stddev_samp_fields",
	age?: number,
	id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_contest_data" */
["voyce_contest_data_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_contest_data_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_contest_data_stream_cursor_value_input"]: {
		age?: number,
	created_at?: GraphQLTypes["timestamp"],
	discord_username?: string,
	first_arc?: string,
	id?: number,
	name?: string,
	pdf?: string,
	social_media?: string,
	story_pitch?: string,
	story_summary?: string,
	target_audience?: string,
	user_id?: number,
	where_did_hear?: string
};
	/** aggregate sum on columns */
["voyce_contest_data_sum_fields"]: {
	__typename: "voyce_contest_data_sum_fields",
	age?: number,
	id?: number,
	user_id?: number
};
	/** update columns of table "voyce.contest_data" */
["voyce_contest_data_update_column"]: voyce_contest_data_update_column;
	["voyce_contest_data_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_contest_data_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_contest_data_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_contest_data_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_contest_data_var_pop_fields"]: {
	__typename: "voyce_contest_data_var_pop_fields",
	age?: number,
	id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_contest_data_var_samp_fields"]: {
	__typename: "voyce_contest_data_var_samp_fields",
	age?: number,
	id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_contest_data_variance_fields"]: {
	__typename: "voyce_contest_data_variance_fields",
	age?: number,
	id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.countries" */
["voyce_countries"]: {
	__typename: "voyce_countries",
	date_create: GraphQLTypes["timestamp"],
	description?: string,
	feature: GraphQLTypes["smallint"],
	flag: string,
	id: number,
	name: string,
	phone_code: number,
	publish: GraphQLTypes["smallint"],
	sort_name: string
};
	/** aggregated selection of "voyce.countries" */
["voyce_countries_aggregate"]: {
	__typename: "voyce_countries_aggregate",
	aggregate?: GraphQLTypes["voyce_countries_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_countries"]>
};
	/** aggregate fields of "voyce.countries" */
["voyce_countries_aggregate_fields"]: {
	__typename: "voyce_countries_aggregate_fields",
	avg?: GraphQLTypes["voyce_countries_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_countries_max_fields"],
	min?: GraphQLTypes["voyce_countries_min_fields"],
	stddev?: GraphQLTypes["voyce_countries_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_countries_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_countries_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_countries_sum_fields"],
	var_pop?: GraphQLTypes["voyce_countries_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_countries_var_samp_fields"],
	variance?: GraphQLTypes["voyce_countries_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_countries_avg_fields"]: {
	__typename: "voyce_countries_avg_fields",
	feature?: number,
	id?: number,
	phone_code?: number,
	publish?: number
};
	/** Boolean expression to filter rows from the table "voyce.countries". All fields are combined with a logical 'AND'. */
["voyce_countries_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_countries_bool_exp"]>,
	_not?: GraphQLTypes["voyce_countries_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_countries_bool_exp"]>,
	date_create?: GraphQLTypes["timestamp_comparison_exp"],
	description?: GraphQLTypes["String_comparison_exp"],
	feature?: GraphQLTypes["smallint_comparison_exp"],
	flag?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	phone_code?: GraphQLTypes["Int_comparison_exp"],
	publish?: GraphQLTypes["smallint_comparison_exp"],
	sort_name?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.countries" */
["voyce_countries_constraint"]: voyce_countries_constraint;
	/** input type for incrementing numeric columns in table "voyce.countries" */
["voyce_countries_inc_input"]: {
		feature?: GraphQLTypes["smallint"],
	id?: number,
	phone_code?: number,
	publish?: GraphQLTypes["smallint"]
};
	/** input type for inserting data into table "voyce.countries" */
["voyce_countries_insert_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	description?: string,
	feature?: GraphQLTypes["smallint"],
	flag?: string,
	id?: number,
	name?: string,
	phone_code?: number,
	publish?: GraphQLTypes["smallint"],
	sort_name?: string
};
	/** aggregate max on columns */
["voyce_countries_max_fields"]: {
	__typename: "voyce_countries_max_fields",
	date_create?: GraphQLTypes["timestamp"],
	description?: string,
	feature?: GraphQLTypes["smallint"],
	flag?: string,
	id?: number,
	name?: string,
	phone_code?: number,
	publish?: GraphQLTypes["smallint"],
	sort_name?: string
};
	/** aggregate min on columns */
["voyce_countries_min_fields"]: {
	__typename: "voyce_countries_min_fields",
	date_create?: GraphQLTypes["timestamp"],
	description?: string,
	feature?: GraphQLTypes["smallint"],
	flag?: string,
	id?: number,
	name?: string,
	phone_code?: number,
	publish?: GraphQLTypes["smallint"],
	sort_name?: string
};
	/** response of any mutation on the table "voyce.countries" */
["voyce_countries_mutation_response"]: {
	__typename: "voyce_countries_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_countries"]>
};
	/** on_conflict condition type for table "voyce.countries" */
["voyce_countries_on_conflict"]: {
		constraint: GraphQLTypes["voyce_countries_constraint"],
	update_columns: Array<GraphQLTypes["voyce_countries_update_column"]>,
	where?: GraphQLTypes["voyce_countries_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.countries". */
["voyce_countries_order_by"]: {
		date_create?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	feature?: GraphQLTypes["order_by"],
	flag?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	phone_code?: GraphQLTypes["order_by"],
	publish?: GraphQLTypes["order_by"],
	sort_name?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.countries */
["voyce_countries_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.countries" */
["voyce_countries_select_column"]: voyce_countries_select_column;
	/** input type for updating data in table "voyce.countries" */
["voyce_countries_set_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	description?: string,
	feature?: GraphQLTypes["smallint"],
	flag?: string,
	id?: number,
	name?: string,
	phone_code?: number,
	publish?: GraphQLTypes["smallint"],
	sort_name?: string
};
	/** aggregate stddev on columns */
["voyce_countries_stddev_fields"]: {
	__typename: "voyce_countries_stddev_fields",
	feature?: number,
	id?: number,
	phone_code?: number,
	publish?: number
};
	/** aggregate stddev_pop on columns */
["voyce_countries_stddev_pop_fields"]: {
	__typename: "voyce_countries_stddev_pop_fields",
	feature?: number,
	id?: number,
	phone_code?: number,
	publish?: number
};
	/** aggregate stddev_samp on columns */
["voyce_countries_stddev_samp_fields"]: {
	__typename: "voyce_countries_stddev_samp_fields",
	feature?: number,
	id?: number,
	phone_code?: number,
	publish?: number
};
	/** Streaming cursor of the table "voyce_countries" */
["voyce_countries_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_countries_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_countries_stream_cursor_value_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	description?: string,
	feature?: GraphQLTypes["smallint"],
	flag?: string,
	id?: number,
	name?: string,
	phone_code?: number,
	publish?: GraphQLTypes["smallint"],
	sort_name?: string
};
	/** aggregate sum on columns */
["voyce_countries_sum_fields"]: {
	__typename: "voyce_countries_sum_fields",
	feature?: GraphQLTypes["smallint"],
	id?: number,
	phone_code?: number,
	publish?: GraphQLTypes["smallint"]
};
	/** update columns of table "voyce.countries" */
["voyce_countries_update_column"]: voyce_countries_update_column;
	["voyce_countries_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_countries_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_countries_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_countries_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_countries_var_pop_fields"]: {
	__typename: "voyce_countries_var_pop_fields",
	feature?: number,
	id?: number,
	phone_code?: number,
	publish?: number
};
	/** aggregate var_samp on columns */
["voyce_countries_var_samp_fields"]: {
	__typename: "voyce_countries_var_samp_fields",
	feature?: number,
	id?: number,
	phone_code?: number,
	publish?: number
};
	/** aggregate variance on columns */
["voyce_countries_variance_fields"]: {
	__typename: "voyce_countries_variance_fields",
	feature?: number,
	id?: number,
	phone_code?: number,
	publish?: number
};
	/** columns and relationships of "voyce.csat_ratings" */
["voyce_csat_ratings"]: {
	__typename: "voyce_csat_ratings",
	cancel_text?: string,
	comment: boolean,
	created_at: GraphQLTypes["timestamptz"],
	/** An array relationship */
	data: Array<GraphQLTypes["voyce_csat_ratings_data"]>,
	/** An aggregate relationship */
	data_aggregate: GraphQLTypes["voyce_csat_ratings_data_aggregate"],
	desc: string,
	id: number,
	screen: number,
	title: string,
	version: GraphQLTypes["smallint"]
};
	/** aggregated selection of "voyce.csat_ratings" */
["voyce_csat_ratings_aggregate"]: {
	__typename: "voyce_csat_ratings_aggregate",
	aggregate?: GraphQLTypes["voyce_csat_ratings_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_csat_ratings"]>
};
	/** aggregate fields of "voyce.csat_ratings" */
["voyce_csat_ratings_aggregate_fields"]: {
	__typename: "voyce_csat_ratings_aggregate_fields",
	avg?: GraphQLTypes["voyce_csat_ratings_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_csat_ratings_max_fields"],
	min?: GraphQLTypes["voyce_csat_ratings_min_fields"],
	stddev?: GraphQLTypes["voyce_csat_ratings_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_csat_ratings_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_csat_ratings_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_csat_ratings_sum_fields"],
	var_pop?: GraphQLTypes["voyce_csat_ratings_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_csat_ratings_var_samp_fields"],
	variance?: GraphQLTypes["voyce_csat_ratings_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_csat_ratings_avg_fields"]: {
	__typename: "voyce_csat_ratings_avg_fields",
	id?: number,
	screen?: number,
	version?: number
};
	/** Boolean expression to filter rows from the table "voyce.csat_ratings". All fields are combined with a logical 'AND'. */
["voyce_csat_ratings_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_csat_ratings_bool_exp"]>,
	_not?: GraphQLTypes["voyce_csat_ratings_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_csat_ratings_bool_exp"]>,
	cancel_text?: GraphQLTypes["String_comparison_exp"],
	comment?: GraphQLTypes["Boolean_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	data?: GraphQLTypes["voyce_csat_ratings_data_bool_exp"],
	data_aggregate?: GraphQLTypes["voyce_csat_ratings_data_aggregate_bool_exp"],
	desc?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	screen?: GraphQLTypes["Int_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	version?: GraphQLTypes["smallint_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.csat_ratings" */
["voyce_csat_ratings_constraint"]: voyce_csat_ratings_constraint;
	/** columns and relationships of "voyce.csat_ratings_data" */
["voyce_csat_ratings_data"]: {
	__typename: "voyce_csat_ratings_data",
	created_at: GraphQLTypes["timestamptz"],
	csat_rating_id: number,
	id: number,
	image?: string,
	label?: string,
	order: GraphQLTypes["smallint"],
	rating?: string
};
	/** aggregated selection of "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_aggregate"]: {
	__typename: "voyce_csat_ratings_data_aggregate",
	aggregate?: GraphQLTypes["voyce_csat_ratings_data_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_csat_ratings_data"]>
};
	["voyce_csat_ratings_data_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_csat_ratings_data_aggregate_bool_exp_count"]
};
	["voyce_csat_ratings_data_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_csat_ratings_data_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_csat_ratings_data_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_aggregate_fields"]: {
	__typename: "voyce_csat_ratings_data_aggregate_fields",
	avg?: GraphQLTypes["voyce_csat_ratings_data_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_csat_ratings_data_max_fields"],
	min?: GraphQLTypes["voyce_csat_ratings_data_min_fields"],
	stddev?: GraphQLTypes["voyce_csat_ratings_data_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_csat_ratings_data_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_csat_ratings_data_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_csat_ratings_data_sum_fields"],
	var_pop?: GraphQLTypes["voyce_csat_ratings_data_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_csat_ratings_data_var_samp_fields"],
	variance?: GraphQLTypes["voyce_csat_ratings_data_variance_fields"]
};
	/** order by aggregate values of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_csat_ratings_data_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_csat_ratings_data_max_order_by"],
	min?: GraphQLTypes["voyce_csat_ratings_data_min_order_by"],
	stddev?: GraphQLTypes["voyce_csat_ratings_data_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_csat_ratings_data_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_csat_ratings_data_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_csat_ratings_data_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_csat_ratings_data_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_csat_ratings_data_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_csat_ratings_data_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_csat_ratings_data_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_csat_ratings_data_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_csat_ratings_data_avg_fields"]: {
	__typename: "voyce_csat_ratings_data_avg_fields",
	csat_rating_id?: number,
	id?: number,
	order?: number
};
	/** order by avg() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_avg_order_by"]: {
		csat_rating_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.csat_ratings_data". All fields are combined with a logical 'AND'. */
["voyce_csat_ratings_data_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_csat_ratings_data_bool_exp"]>,
	_not?: GraphQLTypes["voyce_csat_ratings_data_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_csat_ratings_data_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	csat_rating_id?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	image?: GraphQLTypes["String_comparison_exp"],
	label?: GraphQLTypes["String_comparison_exp"],
	order?: GraphQLTypes["smallint_comparison_exp"],
	rating?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_constraint"]: voyce_csat_ratings_data_constraint;
	/** input type for incrementing numeric columns in table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_inc_input"]: {
		csat_rating_id?: number,
	id?: number,
	order?: GraphQLTypes["smallint"]
};
	/** input type for inserting data into table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	csat_rating_id?: number,
	id?: number,
	image?: string,
	label?: string,
	order?: GraphQLTypes["smallint"],
	rating?: string
};
	/** aggregate max on columns */
["voyce_csat_ratings_data_max_fields"]: {
	__typename: "voyce_csat_ratings_data_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	csat_rating_id?: number,
	id?: number,
	image?: string,
	label?: string,
	order?: GraphQLTypes["smallint"],
	rating?: string
};
	/** order by max() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_max_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	csat_rating_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	label?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	rating?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_csat_ratings_data_min_fields"]: {
	__typename: "voyce_csat_ratings_data_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	csat_rating_id?: number,
	id?: number,
	image?: string,
	label?: string,
	order?: GraphQLTypes["smallint"],
	rating?: string
};
	/** order by min() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_min_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	csat_rating_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	label?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	rating?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_mutation_response"]: {
	__typename: "voyce_csat_ratings_data_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_csat_ratings_data"]>
};
	/** on_conflict condition type for table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_on_conflict"]: {
		constraint: GraphQLTypes["voyce_csat_ratings_data_constraint"],
	update_columns: Array<GraphQLTypes["voyce_csat_ratings_data_update_column"]>,
	where?: GraphQLTypes["voyce_csat_ratings_data_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.csat_ratings_data". */
["voyce_csat_ratings_data_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	csat_rating_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	label?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	rating?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.csat_ratings_data */
["voyce_csat_ratings_data_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_select_column"]: voyce_csat_ratings_data_select_column;
	/** input type for updating data in table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	csat_rating_id?: number,
	id?: number,
	image?: string,
	label?: string,
	order?: GraphQLTypes["smallint"],
	rating?: string
};
	/** aggregate stddev on columns */
["voyce_csat_ratings_data_stddev_fields"]: {
	__typename: "voyce_csat_ratings_data_stddev_fields",
	csat_rating_id?: number,
	id?: number,
	order?: number
};
	/** order by stddev() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_stddev_order_by"]: {
		csat_rating_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_csat_ratings_data_stddev_pop_fields"]: {
	__typename: "voyce_csat_ratings_data_stddev_pop_fields",
	csat_rating_id?: number,
	id?: number,
	order?: number
};
	/** order by stddev_pop() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_stddev_pop_order_by"]: {
		csat_rating_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_csat_ratings_data_stddev_samp_fields"]: {
	__typename: "voyce_csat_ratings_data_stddev_samp_fields",
	csat_rating_id?: number,
	id?: number,
	order?: number
};
	/** order by stddev_samp() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_stddev_samp_order_by"]: {
		csat_rating_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_csat_ratings_data" */
["voyce_csat_ratings_data_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_csat_ratings_data_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_csat_ratings_data_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	csat_rating_id?: number,
	id?: number,
	image?: string,
	label?: string,
	order?: GraphQLTypes["smallint"],
	rating?: string
};
	/** aggregate sum on columns */
["voyce_csat_ratings_data_sum_fields"]: {
	__typename: "voyce_csat_ratings_data_sum_fields",
	csat_rating_id?: number,
	id?: number,
	order?: GraphQLTypes["smallint"]
};
	/** order by sum() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_sum_order_by"]: {
		csat_rating_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_update_column"]: voyce_csat_ratings_data_update_column;
	["voyce_csat_ratings_data_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_csat_ratings_data_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_csat_ratings_data_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_csat_ratings_data_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_csat_ratings_data_var_pop_fields"]: {
	__typename: "voyce_csat_ratings_data_var_pop_fields",
	csat_rating_id?: number,
	id?: number,
	order?: number
};
	/** order by var_pop() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_var_pop_order_by"]: {
		csat_rating_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_csat_ratings_data_var_samp_fields"]: {
	__typename: "voyce_csat_ratings_data_var_samp_fields",
	csat_rating_id?: number,
	id?: number,
	order?: number
};
	/** order by var_samp() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_var_samp_order_by"]: {
		csat_rating_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_csat_ratings_data_variance_fields"]: {
	__typename: "voyce_csat_ratings_data_variance_fields",
	csat_rating_id?: number,
	id?: number,
	order?: number
};
	/** order by variance() on columns of table "voyce.csat_ratings_data" */
["voyce_csat_ratings_data_variance_order_by"]: {
		csat_rating_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"]
};
	/** input type for incrementing numeric columns in table "voyce.csat_ratings" */
["voyce_csat_ratings_inc_input"]: {
		id?: number,
	screen?: number,
	version?: GraphQLTypes["smallint"]
};
	/** input type for inserting data into table "voyce.csat_ratings" */
["voyce_csat_ratings_insert_input"]: {
		cancel_text?: string,
	comment?: boolean,
	created_at?: GraphQLTypes["timestamptz"],
	data?: GraphQLTypes["voyce_csat_ratings_data_arr_rel_insert_input"],
	desc?: string,
	id?: number,
	screen?: number,
	title?: string,
	version?: GraphQLTypes["smallint"]
};
	/** aggregate max on columns */
["voyce_csat_ratings_max_fields"]: {
	__typename: "voyce_csat_ratings_max_fields",
	cancel_text?: string,
	created_at?: GraphQLTypes["timestamptz"],
	desc?: string,
	id?: number,
	screen?: number,
	title?: string,
	version?: GraphQLTypes["smallint"]
};
	/** aggregate min on columns */
["voyce_csat_ratings_min_fields"]: {
	__typename: "voyce_csat_ratings_min_fields",
	cancel_text?: string,
	created_at?: GraphQLTypes["timestamptz"],
	desc?: string,
	id?: number,
	screen?: number,
	title?: string,
	version?: GraphQLTypes["smallint"]
};
	/** response of any mutation on the table "voyce.csat_ratings" */
["voyce_csat_ratings_mutation_response"]: {
	__typename: "voyce_csat_ratings_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_csat_ratings"]>
};
	/** on_conflict condition type for table "voyce.csat_ratings" */
["voyce_csat_ratings_on_conflict"]: {
		constraint: GraphQLTypes["voyce_csat_ratings_constraint"],
	update_columns: Array<GraphQLTypes["voyce_csat_ratings_update_column"]>,
	where?: GraphQLTypes["voyce_csat_ratings_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.csat_ratings". */
["voyce_csat_ratings_order_by"]: {
		cancel_text?: GraphQLTypes["order_by"],
	comment?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	data_aggregate?: GraphQLTypes["voyce_csat_ratings_data_aggregate_order_by"],
	desc?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	screen?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	version?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.csat_ratings */
["voyce_csat_ratings_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.csat_ratings" */
["voyce_csat_ratings_select_column"]: voyce_csat_ratings_select_column;
	/** input type for updating data in table "voyce.csat_ratings" */
["voyce_csat_ratings_set_input"]: {
		cancel_text?: string,
	comment?: boolean,
	created_at?: GraphQLTypes["timestamptz"],
	desc?: string,
	id?: number,
	screen?: number,
	title?: string,
	version?: GraphQLTypes["smallint"]
};
	/** aggregate stddev on columns */
["voyce_csat_ratings_stddev_fields"]: {
	__typename: "voyce_csat_ratings_stddev_fields",
	id?: number,
	screen?: number,
	version?: number
};
	/** aggregate stddev_pop on columns */
["voyce_csat_ratings_stddev_pop_fields"]: {
	__typename: "voyce_csat_ratings_stddev_pop_fields",
	id?: number,
	screen?: number,
	version?: number
};
	/** aggregate stddev_samp on columns */
["voyce_csat_ratings_stddev_samp_fields"]: {
	__typename: "voyce_csat_ratings_stddev_samp_fields",
	id?: number,
	screen?: number,
	version?: number
};
	/** Streaming cursor of the table "voyce_csat_ratings" */
["voyce_csat_ratings_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_csat_ratings_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_csat_ratings_stream_cursor_value_input"]: {
		cancel_text?: string,
	comment?: boolean,
	created_at?: GraphQLTypes["timestamptz"],
	desc?: string,
	id?: number,
	screen?: number,
	title?: string,
	version?: GraphQLTypes["smallint"]
};
	/** aggregate sum on columns */
["voyce_csat_ratings_sum_fields"]: {
	__typename: "voyce_csat_ratings_sum_fields",
	id?: number,
	screen?: number,
	version?: GraphQLTypes["smallint"]
};
	/** update columns of table "voyce.csat_ratings" */
["voyce_csat_ratings_update_column"]: voyce_csat_ratings_update_column;
	["voyce_csat_ratings_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_csat_ratings_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_csat_ratings_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_csat_ratings_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_csat_ratings_var_pop_fields"]: {
	__typename: "voyce_csat_ratings_var_pop_fields",
	id?: number,
	screen?: number,
	version?: number
};
	/** aggregate var_samp on columns */
["voyce_csat_ratings_var_samp_fields"]: {
	__typename: "voyce_csat_ratings_var_samp_fields",
	id?: number,
	screen?: number,
	version?: number
};
	/** aggregate variance on columns */
["voyce_csat_ratings_variance_fields"]: {
	__typename: "voyce_csat_ratings_variance_fields",
	id?: number,
	screen?: number,
	version?: number
};
	/** columns and relationships of "voyce.education_types" */
["voyce_education_types"]: {
	__typename: "voyce_education_types",
	date_create: GraphQLTypes["timestamp"],
	id: number,
	title: string
};
	/** aggregated selection of "voyce.education_types" */
["voyce_education_types_aggregate"]: {
	__typename: "voyce_education_types_aggregate",
	aggregate?: GraphQLTypes["voyce_education_types_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_education_types"]>
};
	/** aggregate fields of "voyce.education_types" */
["voyce_education_types_aggregate_fields"]: {
	__typename: "voyce_education_types_aggregate_fields",
	avg?: GraphQLTypes["voyce_education_types_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_education_types_max_fields"],
	min?: GraphQLTypes["voyce_education_types_min_fields"],
	stddev?: GraphQLTypes["voyce_education_types_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_education_types_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_education_types_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_education_types_sum_fields"],
	var_pop?: GraphQLTypes["voyce_education_types_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_education_types_var_samp_fields"],
	variance?: GraphQLTypes["voyce_education_types_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_education_types_avg_fields"]: {
	__typename: "voyce_education_types_avg_fields",
	id?: number
};
	/** Boolean expression to filter rows from the table "voyce.education_types". All fields are combined with a logical 'AND'. */
["voyce_education_types_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_education_types_bool_exp"]>,
	_not?: GraphQLTypes["voyce_education_types_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_education_types_bool_exp"]>,
	date_create?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.education_types" */
["voyce_education_types_constraint"]: voyce_education_types_constraint;
	/** input type for incrementing numeric columns in table "voyce.education_types" */
["voyce_education_types_inc_input"]: {
		id?: number
};
	/** input type for inserting data into table "voyce.education_types" */
["voyce_education_types_insert_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	id?: number,
	title?: string
};
	/** aggregate max on columns */
["voyce_education_types_max_fields"]: {
	__typename: "voyce_education_types_max_fields",
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	title?: string
};
	/** aggregate min on columns */
["voyce_education_types_min_fields"]: {
	__typename: "voyce_education_types_min_fields",
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	title?: string
};
	/** response of any mutation on the table "voyce.education_types" */
["voyce_education_types_mutation_response"]: {
	__typename: "voyce_education_types_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_education_types"]>
};
	/** on_conflict condition type for table "voyce.education_types" */
["voyce_education_types_on_conflict"]: {
		constraint: GraphQLTypes["voyce_education_types_constraint"],
	update_columns: Array<GraphQLTypes["voyce_education_types_update_column"]>,
	where?: GraphQLTypes["voyce_education_types_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.education_types". */
["voyce_education_types_order_by"]: {
		date_create?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.education_types */
["voyce_education_types_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.education_types" */
["voyce_education_types_select_column"]: voyce_education_types_select_column;
	/** input type for updating data in table "voyce.education_types" */
["voyce_education_types_set_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	id?: number,
	title?: string
};
	/** aggregate stddev on columns */
["voyce_education_types_stddev_fields"]: {
	__typename: "voyce_education_types_stddev_fields",
	id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_education_types_stddev_pop_fields"]: {
	__typename: "voyce_education_types_stddev_pop_fields",
	id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_education_types_stddev_samp_fields"]: {
	__typename: "voyce_education_types_stddev_samp_fields",
	id?: number
};
	/** Streaming cursor of the table "voyce_education_types" */
["voyce_education_types_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_education_types_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_education_types_stream_cursor_value_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	id?: number,
	title?: string
};
	/** aggregate sum on columns */
["voyce_education_types_sum_fields"]: {
	__typename: "voyce_education_types_sum_fields",
	id?: number
};
	/** update columns of table "voyce.education_types" */
["voyce_education_types_update_column"]: voyce_education_types_update_column;
	["voyce_education_types_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_education_types_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_education_types_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_education_types_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_education_types_var_pop_fields"]: {
	__typename: "voyce_education_types_var_pop_fields",
	id?: number
};
	/** aggregate var_samp on columns */
["voyce_education_types_var_samp_fields"]: {
	__typename: "voyce_education_types_var_samp_fields",
	id?: number
};
	/** aggregate variance on columns */
["voyce_education_types_variance_fields"]: {
	__typename: "voyce_education_types_variance_fields",
	id?: number
};
	/** columns and relationships of "voyce.erp_login_roles" */
["voyce_erp_login_roles"]: {
	__typename: "voyce_erp_login_roles",
	id: number,
	role_id: number
};
	/** aggregated selection of "voyce.erp_login_roles" */
["voyce_erp_login_roles_aggregate"]: {
	__typename: "voyce_erp_login_roles_aggregate",
	aggregate?: GraphQLTypes["voyce_erp_login_roles_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_erp_login_roles"]>
};
	/** aggregate fields of "voyce.erp_login_roles" */
["voyce_erp_login_roles_aggregate_fields"]: {
	__typename: "voyce_erp_login_roles_aggregate_fields",
	avg?: GraphQLTypes["voyce_erp_login_roles_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_erp_login_roles_max_fields"],
	min?: GraphQLTypes["voyce_erp_login_roles_min_fields"],
	stddev?: GraphQLTypes["voyce_erp_login_roles_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_erp_login_roles_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_erp_login_roles_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_erp_login_roles_sum_fields"],
	var_pop?: GraphQLTypes["voyce_erp_login_roles_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_erp_login_roles_var_samp_fields"],
	variance?: GraphQLTypes["voyce_erp_login_roles_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_erp_login_roles_avg_fields"]: {
	__typename: "voyce_erp_login_roles_avg_fields",
	id?: number,
	role_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.erp_login_roles". All fields are combined with a logical 'AND'. */
["voyce_erp_login_roles_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_erp_login_roles_bool_exp"]>,
	_not?: GraphQLTypes["voyce_erp_login_roles_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_erp_login_roles_bool_exp"]>,
	id?: GraphQLTypes["Int_comparison_exp"],
	role_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.erp_login_roles" */
["voyce_erp_login_roles_constraint"]: voyce_erp_login_roles_constraint;
	/** input type for incrementing numeric columns in table "voyce.erp_login_roles" */
["voyce_erp_login_roles_inc_input"]: {
		id?: number,
	role_id?: number
};
	/** input type for inserting data into table "voyce.erp_login_roles" */
["voyce_erp_login_roles_insert_input"]: {
		id?: number,
	role_id?: number
};
	/** aggregate max on columns */
["voyce_erp_login_roles_max_fields"]: {
	__typename: "voyce_erp_login_roles_max_fields",
	id?: number,
	role_id?: number
};
	/** aggregate min on columns */
["voyce_erp_login_roles_min_fields"]: {
	__typename: "voyce_erp_login_roles_min_fields",
	id?: number,
	role_id?: number
};
	/** response of any mutation on the table "voyce.erp_login_roles" */
["voyce_erp_login_roles_mutation_response"]: {
	__typename: "voyce_erp_login_roles_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_erp_login_roles"]>
};
	/** on_conflict condition type for table "voyce.erp_login_roles" */
["voyce_erp_login_roles_on_conflict"]: {
		constraint: GraphQLTypes["voyce_erp_login_roles_constraint"],
	update_columns: Array<GraphQLTypes["voyce_erp_login_roles_update_column"]>,
	where?: GraphQLTypes["voyce_erp_login_roles_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.erp_login_roles". */
["voyce_erp_login_roles_order_by"]: {
		id?: GraphQLTypes["order_by"],
	role_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.erp_login_roles */
["voyce_erp_login_roles_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.erp_login_roles" */
["voyce_erp_login_roles_select_column"]: voyce_erp_login_roles_select_column;
	/** input type for updating data in table "voyce.erp_login_roles" */
["voyce_erp_login_roles_set_input"]: {
		id?: number,
	role_id?: number
};
	/** aggregate stddev on columns */
["voyce_erp_login_roles_stddev_fields"]: {
	__typename: "voyce_erp_login_roles_stddev_fields",
	id?: number,
	role_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_erp_login_roles_stddev_pop_fields"]: {
	__typename: "voyce_erp_login_roles_stddev_pop_fields",
	id?: number,
	role_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_erp_login_roles_stddev_samp_fields"]: {
	__typename: "voyce_erp_login_roles_stddev_samp_fields",
	id?: number,
	role_id?: number
};
	/** Streaming cursor of the table "voyce_erp_login_roles" */
["voyce_erp_login_roles_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_erp_login_roles_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_erp_login_roles_stream_cursor_value_input"]: {
		id?: number,
	role_id?: number
};
	/** aggregate sum on columns */
["voyce_erp_login_roles_sum_fields"]: {
	__typename: "voyce_erp_login_roles_sum_fields",
	id?: number,
	role_id?: number
};
	/** update columns of table "voyce.erp_login_roles" */
["voyce_erp_login_roles_update_column"]: voyce_erp_login_roles_update_column;
	["voyce_erp_login_roles_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_erp_login_roles_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_erp_login_roles_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_erp_login_roles_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_erp_login_roles_var_pop_fields"]: {
	__typename: "voyce_erp_login_roles_var_pop_fields",
	id?: number,
	role_id?: number
};
	/** aggregate var_samp on columns */
["voyce_erp_login_roles_var_samp_fields"]: {
	__typename: "voyce_erp_login_roles_var_samp_fields",
	id?: number,
	role_id?: number
};
	/** aggregate variance on columns */
["voyce_erp_login_roles_variance_fields"]: {
	__typename: "voyce_erp_login_roles_variance_fields",
	id?: number,
	role_id?: number
};
	/** columns and relationships of "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs"]: {
	__typename: "voyce_etl_series_views_logs",
	created_at: GraphQLTypes["timestamptz"],
	glue_epoc: number,
	id: number
};
	/** aggregated selection of "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_aggregate"]: {
	__typename: "voyce_etl_series_views_logs_aggregate",
	aggregate?: GraphQLTypes["voyce_etl_series_views_logs_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_etl_series_views_logs"]>
};
	/** aggregate fields of "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_aggregate_fields"]: {
	__typename: "voyce_etl_series_views_logs_aggregate_fields",
	avg?: GraphQLTypes["voyce_etl_series_views_logs_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_etl_series_views_logs_max_fields"],
	min?: GraphQLTypes["voyce_etl_series_views_logs_min_fields"],
	stddev?: GraphQLTypes["voyce_etl_series_views_logs_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_etl_series_views_logs_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_etl_series_views_logs_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_etl_series_views_logs_sum_fields"],
	var_pop?: GraphQLTypes["voyce_etl_series_views_logs_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_etl_series_views_logs_var_samp_fields"],
	variance?: GraphQLTypes["voyce_etl_series_views_logs_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_etl_series_views_logs_avg_fields"]: {
	__typename: "voyce_etl_series_views_logs_avg_fields",
	glue_epoc?: number,
	id?: number
};
	/** Boolean expression to filter rows from the table "voyce.etl_series_views_logs". All fields are combined with a logical 'AND'. */
["voyce_etl_series_views_logs_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_etl_series_views_logs_bool_exp"]>,
	_not?: GraphQLTypes["voyce_etl_series_views_logs_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_etl_series_views_logs_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	glue_epoc?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_constraint"]: voyce_etl_series_views_logs_constraint;
	/** input type for incrementing numeric columns in table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_inc_input"]: {
		glue_epoc?: number,
	id?: number
};
	/** input type for inserting data into table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	glue_epoc?: number,
	id?: number
};
	/** aggregate max on columns */
["voyce_etl_series_views_logs_max_fields"]: {
	__typename: "voyce_etl_series_views_logs_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	glue_epoc?: number,
	id?: number
};
	/** aggregate min on columns */
["voyce_etl_series_views_logs_min_fields"]: {
	__typename: "voyce_etl_series_views_logs_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	glue_epoc?: number,
	id?: number
};
	/** response of any mutation on the table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_mutation_response"]: {
	__typename: "voyce_etl_series_views_logs_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_etl_series_views_logs"]>
};
	/** on_conflict condition type for table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_on_conflict"]: {
		constraint: GraphQLTypes["voyce_etl_series_views_logs_constraint"],
	update_columns: Array<GraphQLTypes["voyce_etl_series_views_logs_update_column"]>,
	where?: GraphQLTypes["voyce_etl_series_views_logs_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.etl_series_views_logs". */
["voyce_etl_series_views_logs_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	glue_epoc?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.etl_series_views_logs */
["voyce_etl_series_views_logs_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_select_column"]: voyce_etl_series_views_logs_select_column;
	/** input type for updating data in table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	glue_epoc?: number,
	id?: number
};
	/** aggregate stddev on columns */
["voyce_etl_series_views_logs_stddev_fields"]: {
	__typename: "voyce_etl_series_views_logs_stddev_fields",
	glue_epoc?: number,
	id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_etl_series_views_logs_stddev_pop_fields"]: {
	__typename: "voyce_etl_series_views_logs_stddev_pop_fields",
	glue_epoc?: number,
	id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_etl_series_views_logs_stddev_samp_fields"]: {
	__typename: "voyce_etl_series_views_logs_stddev_samp_fields",
	glue_epoc?: number,
	id?: number
};
	/** Streaming cursor of the table "voyce_etl_series_views_logs" */
["voyce_etl_series_views_logs_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_etl_series_views_logs_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_etl_series_views_logs_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	glue_epoc?: number,
	id?: number
};
	/** aggregate sum on columns */
["voyce_etl_series_views_logs_sum_fields"]: {
	__typename: "voyce_etl_series_views_logs_sum_fields",
	glue_epoc?: number,
	id?: number
};
	/** update columns of table "voyce.etl_series_views_logs" */
["voyce_etl_series_views_logs_update_column"]: voyce_etl_series_views_logs_update_column;
	["voyce_etl_series_views_logs_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_etl_series_views_logs_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_etl_series_views_logs_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_etl_series_views_logs_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_etl_series_views_logs_var_pop_fields"]: {
	__typename: "voyce_etl_series_views_logs_var_pop_fields",
	glue_epoc?: number,
	id?: number
};
	/** aggregate var_samp on columns */
["voyce_etl_series_views_logs_var_samp_fields"]: {
	__typename: "voyce_etl_series_views_logs_var_samp_fields",
	glue_epoc?: number,
	id?: number
};
	/** aggregate variance on columns */
["voyce_etl_series_views_logs_variance_fields"]: {
	__typename: "voyce_etl_series_views_logs_variance_fields",
	glue_epoc?: number,
	id?: number
};
	/** columns and relationships of "voyce.event_schedules" */
["voyce_event_schedules"]: {
	__typename: "voyce_event_schedules",
	active?: boolean,
	bg_image?: string,
	created_at: GraphQLTypes["timestamptz"],
	date_time: GraphQLTypes["timestamptz"],
	description: string,
	id: number,
	link: string,
	main_event: boolean,
	main_image: string,
	title: string,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.event_schedules" */
["voyce_event_schedules_aggregate"]: {
	__typename: "voyce_event_schedules_aggregate",
	aggregate?: GraphQLTypes["voyce_event_schedules_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_event_schedules"]>
};
	/** aggregate fields of "voyce.event_schedules" */
["voyce_event_schedules_aggregate_fields"]: {
	__typename: "voyce_event_schedules_aggregate_fields",
	avg?: GraphQLTypes["voyce_event_schedules_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_event_schedules_max_fields"],
	min?: GraphQLTypes["voyce_event_schedules_min_fields"],
	stddev?: GraphQLTypes["voyce_event_schedules_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_event_schedules_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_event_schedules_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_event_schedules_sum_fields"],
	var_pop?: GraphQLTypes["voyce_event_schedules_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_event_schedules_var_samp_fields"],
	variance?: GraphQLTypes["voyce_event_schedules_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_event_schedules_avg_fields"]: {
	__typename: "voyce_event_schedules_avg_fields",
	id?: number
};
	/** Boolean expression to filter rows from the table "voyce.event_schedules". All fields are combined with a logical 'AND'. */
["voyce_event_schedules_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_event_schedules_bool_exp"]>,
	_not?: GraphQLTypes["voyce_event_schedules_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_event_schedules_bool_exp"]>,
	active?: GraphQLTypes["Boolean_comparison_exp"],
	bg_image?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	date_time?: GraphQLTypes["timestamptz_comparison_exp"],
	description?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	link?: GraphQLTypes["String_comparison_exp"],
	main_event?: GraphQLTypes["Boolean_comparison_exp"],
	main_image?: GraphQLTypes["String_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.event_schedules" */
["voyce_event_schedules_constraint"]: voyce_event_schedules_constraint;
	/** input type for incrementing numeric columns in table "voyce.event_schedules" */
["voyce_event_schedules_inc_input"]: {
		id?: number
};
	/** input type for inserting data into table "voyce.event_schedules" */
["voyce_event_schedules_insert_input"]: {
		active?: boolean,
	bg_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	date_time?: GraphQLTypes["timestamptz"],
	description?: string,
	id?: number,
	link?: string,
	main_event?: boolean,
	main_image?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_event_schedules_max_fields"]: {
	__typename: "voyce_event_schedules_max_fields",
	bg_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	date_time?: GraphQLTypes["timestamptz"],
	description?: string,
	id?: number,
	link?: string,
	main_image?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_event_schedules_min_fields"]: {
	__typename: "voyce_event_schedules_min_fields",
	bg_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	date_time?: GraphQLTypes["timestamptz"],
	description?: string,
	id?: number,
	link?: string,
	main_image?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.event_schedules" */
["voyce_event_schedules_mutation_response"]: {
	__typename: "voyce_event_schedules_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_event_schedules"]>
};
	/** on_conflict condition type for table "voyce.event_schedules" */
["voyce_event_schedules_on_conflict"]: {
		constraint: GraphQLTypes["voyce_event_schedules_constraint"],
	update_columns: Array<GraphQLTypes["voyce_event_schedules_update_column"]>,
	where?: GraphQLTypes["voyce_event_schedules_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.event_schedules". */
["voyce_event_schedules_order_by"]: {
		active?: GraphQLTypes["order_by"],
	bg_image?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	date_time?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	link?: GraphQLTypes["order_by"],
	main_event?: GraphQLTypes["order_by"],
	main_image?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.event_schedules */
["voyce_event_schedules_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.event_schedules" */
["voyce_event_schedules_select_column"]: voyce_event_schedules_select_column;
	/** input type for updating data in table "voyce.event_schedules" */
["voyce_event_schedules_set_input"]: {
		active?: boolean,
	bg_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	date_time?: GraphQLTypes["timestamptz"],
	description?: string,
	id?: number,
	link?: string,
	main_event?: boolean,
	main_image?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_event_schedules_stddev_fields"]: {
	__typename: "voyce_event_schedules_stddev_fields",
	id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_event_schedules_stddev_pop_fields"]: {
	__typename: "voyce_event_schedules_stddev_pop_fields",
	id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_event_schedules_stddev_samp_fields"]: {
	__typename: "voyce_event_schedules_stddev_samp_fields",
	id?: number
};
	/** Streaming cursor of the table "voyce_event_schedules" */
["voyce_event_schedules_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_event_schedules_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_event_schedules_stream_cursor_value_input"]: {
		active?: boolean,
	bg_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	date_time?: GraphQLTypes["timestamptz"],
	description?: string,
	id?: number,
	link?: string,
	main_event?: boolean,
	main_image?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_event_schedules_sum_fields"]: {
	__typename: "voyce_event_schedules_sum_fields",
	id?: number
};
	/** update columns of table "voyce.event_schedules" */
["voyce_event_schedules_update_column"]: voyce_event_schedules_update_column;
	["voyce_event_schedules_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_event_schedules_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_event_schedules_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_event_schedules_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_event_schedules_var_pop_fields"]: {
	__typename: "voyce_event_schedules_var_pop_fields",
	id?: number
};
	/** aggregate var_samp on columns */
["voyce_event_schedules_var_samp_fields"]: {
	__typename: "voyce_event_schedules_var_samp_fields",
	id?: number
};
	/** aggregate variance on columns */
["voyce_event_schedules_variance_fields"]: {
	__typename: "voyce_event_schedules_variance_fields",
	id?: number
};
	/** columns and relationships of "voyce.genres" */
["voyce_genres"]: {
	__typename: "voyce_genres",
	color?: string,
	created_at: GraphQLTypes["timestamp"],
	id: number,
	is_trending?: boolean,
	klaviyo_id?: string,
	thumbnail?: string,
	title: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregated selection of "voyce.genres" */
["voyce_genres_aggregate"]: {
	__typename: "voyce_genres_aggregate",
	aggregate?: GraphQLTypes["voyce_genres_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_genres"]>
};
	/** aggregate fields of "voyce.genres" */
["voyce_genres_aggregate_fields"]: {
	__typename: "voyce_genres_aggregate_fields",
	avg?: GraphQLTypes["voyce_genres_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_genres_max_fields"],
	min?: GraphQLTypes["voyce_genres_min_fields"],
	stddev?: GraphQLTypes["voyce_genres_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_genres_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_genres_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_genres_sum_fields"],
	var_pop?: GraphQLTypes["voyce_genres_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_genres_var_samp_fields"],
	variance?: GraphQLTypes["voyce_genres_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_genres_avg_fields"]: {
	__typename: "voyce_genres_avg_fields",
	id?: number
};
	/** Boolean expression to filter rows from the table "voyce.genres". All fields are combined with a logical 'AND'. */
["voyce_genres_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_genres_bool_exp"]>,
	_not?: GraphQLTypes["voyce_genres_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_genres_bool_exp"]>,
	color?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_trending?: GraphQLTypes["Boolean_comparison_exp"],
	klaviyo_id?: GraphQLTypes["String_comparison_exp"],
	thumbnail?: GraphQLTypes["String_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamp_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.genres" */
["voyce_genres_constraint"]: voyce_genres_constraint;
	/** input type for incrementing numeric columns in table "voyce.genres" */
["voyce_genres_inc_input"]: {
		id?: number
};
	/** input type for inserting data into table "voyce.genres" */
["voyce_genres_insert_input"]: {
		color?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	is_trending?: boolean,
	klaviyo_id?: string,
	thumbnail?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate max on columns */
["voyce_genres_max_fields"]: {
	__typename: "voyce_genres_max_fields",
	color?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	klaviyo_id?: string,
	thumbnail?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate min on columns */
["voyce_genres_min_fields"]: {
	__typename: "voyce_genres_min_fields",
	color?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	klaviyo_id?: string,
	thumbnail?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** response of any mutation on the table "voyce.genres" */
["voyce_genres_mutation_response"]: {
	__typename: "voyce_genres_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_genres"]>
};
	/** input type for inserting object relation for remote table "voyce.genres" */
["voyce_genres_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_genres_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_genres_on_conflict"]
};
	/** on_conflict condition type for table "voyce.genres" */
["voyce_genres_on_conflict"]: {
		constraint: GraphQLTypes["voyce_genres_constraint"],
	update_columns: Array<GraphQLTypes["voyce_genres_update_column"]>,
	where?: GraphQLTypes["voyce_genres_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.genres". */
["voyce_genres_order_by"]: {
		color?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_trending?: GraphQLTypes["order_by"],
	klaviyo_id?: GraphQLTypes["order_by"],
	thumbnail?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.genres */
["voyce_genres_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.genres" */
["voyce_genres_select_column"]: voyce_genres_select_column;
	/** input type for updating data in table "voyce.genres" */
["voyce_genres_set_input"]: {
		color?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	is_trending?: boolean,
	klaviyo_id?: string,
	thumbnail?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate stddev on columns */
["voyce_genres_stddev_fields"]: {
	__typename: "voyce_genres_stddev_fields",
	id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_genres_stddev_pop_fields"]: {
	__typename: "voyce_genres_stddev_pop_fields",
	id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_genres_stddev_samp_fields"]: {
	__typename: "voyce_genres_stddev_samp_fields",
	id?: number
};
	/** Streaming cursor of the table "voyce_genres" */
["voyce_genres_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_genres_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_genres_stream_cursor_value_input"]: {
		color?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	is_trending?: boolean,
	klaviyo_id?: string,
	thumbnail?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate sum on columns */
["voyce_genres_sum_fields"]: {
	__typename: "voyce_genres_sum_fields",
	id?: number
};
	/** update columns of table "voyce.genres" */
["voyce_genres_update_column"]: voyce_genres_update_column;
	["voyce_genres_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_genres_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_genres_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_genres_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_genres_var_pop_fields"]: {
	__typename: "voyce_genres_var_pop_fields",
	id?: number
};
	/** aggregate var_samp on columns */
["voyce_genres_var_samp_fields"]: {
	__typename: "voyce_genres_var_samp_fields",
	id?: number
};
	/** aggregate variance on columns */
["voyce_genres_variance_fields"]: {
	__typename: "voyce_genres_variance_fields",
	id?: number
};
	/** columns and relationships of "voyce.guest_gtags" */
["voyce_guest_gtags"]: {
	__typename: "voyce_guest_gtags",
	created_at: GraphQLTypes["timestamptz"],
	device_id: string,
	/** An array relationship */
	emails: Array<GraphQLTypes["voyce_popup_emails"]>,
	/** An aggregate relationship */
	emails_aggregate: GraphQLTypes["voyce_popup_emails_aggregate"],
	gtag: string,
	gtag_v2: string,
	id: number,
	klaviyo_id?: string,
	source: string,
	user_ip: string,
	username: string
};
	/** aggregated selection of "voyce.guest_gtags" */
["voyce_guest_gtags_aggregate"]: {
	__typename: "voyce_guest_gtags_aggregate",
	aggregate?: GraphQLTypes["voyce_guest_gtags_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_guest_gtags"]>
};
	/** aggregate fields of "voyce.guest_gtags" */
["voyce_guest_gtags_aggregate_fields"]: {
	__typename: "voyce_guest_gtags_aggregate_fields",
	avg?: GraphQLTypes["voyce_guest_gtags_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_guest_gtags_max_fields"],
	min?: GraphQLTypes["voyce_guest_gtags_min_fields"],
	stddev?: GraphQLTypes["voyce_guest_gtags_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_guest_gtags_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_guest_gtags_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_guest_gtags_sum_fields"],
	var_pop?: GraphQLTypes["voyce_guest_gtags_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_guest_gtags_var_samp_fields"],
	variance?: GraphQLTypes["voyce_guest_gtags_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_guest_gtags_avg_fields"]: {
	__typename: "voyce_guest_gtags_avg_fields",
	id?: number
};
	/** Boolean expression to filter rows from the table "voyce.guest_gtags". All fields are combined with a logical 'AND'. */
["voyce_guest_gtags_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_guest_gtags_bool_exp"]>,
	_not?: GraphQLTypes["voyce_guest_gtags_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_guest_gtags_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	device_id?: GraphQLTypes["String_comparison_exp"],
	emails?: GraphQLTypes["voyce_popup_emails_bool_exp"],
	emails_aggregate?: GraphQLTypes["voyce_popup_emails_aggregate_bool_exp"],
	gtag?: GraphQLTypes["String_comparison_exp"],
	gtag_v2?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	klaviyo_id?: GraphQLTypes["String_comparison_exp"],
	source?: GraphQLTypes["String_comparison_exp"],
	user_ip?: GraphQLTypes["String_comparison_exp"],
	username?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.guest_gtags" */
["voyce_guest_gtags_constraint"]: voyce_guest_gtags_constraint;
	/** input type for incrementing numeric columns in table "voyce.guest_gtags" */
["voyce_guest_gtags_inc_input"]: {
		id?: number
};
	/** input type for inserting data into table "voyce.guest_gtags" */
["voyce_guest_gtags_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	device_id?: string,
	emails?: GraphQLTypes["voyce_popup_emails_arr_rel_insert_input"],
	gtag?: string,
	gtag_v2?: string,
	id?: number,
	klaviyo_id?: string,
	source?: string,
	user_ip?: string,
	username?: string
};
	/** aggregate max on columns */
["voyce_guest_gtags_max_fields"]: {
	__typename: "voyce_guest_gtags_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	device_id?: string,
	gtag?: string,
	gtag_v2?: string,
	id?: number,
	klaviyo_id?: string,
	source?: string,
	user_ip?: string,
	username?: string
};
	/** aggregate min on columns */
["voyce_guest_gtags_min_fields"]: {
	__typename: "voyce_guest_gtags_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	device_id?: string,
	gtag?: string,
	gtag_v2?: string,
	id?: number,
	klaviyo_id?: string,
	source?: string,
	user_ip?: string,
	username?: string
};
	/** response of any mutation on the table "voyce.guest_gtags" */
["voyce_guest_gtags_mutation_response"]: {
	__typename: "voyce_guest_gtags_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_guest_gtags"]>
};
	/** input type for inserting object relation for remote table "voyce.guest_gtags" */
["voyce_guest_gtags_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_guest_gtags_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_guest_gtags_on_conflict"]
};
	/** on_conflict condition type for table "voyce.guest_gtags" */
["voyce_guest_gtags_on_conflict"]: {
		constraint: GraphQLTypes["voyce_guest_gtags_constraint"],
	update_columns: Array<GraphQLTypes["voyce_guest_gtags_update_column"]>,
	where?: GraphQLTypes["voyce_guest_gtags_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.guest_gtags". */
["voyce_guest_gtags_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	device_id?: GraphQLTypes["order_by"],
	emails_aggregate?: GraphQLTypes["voyce_popup_emails_aggregate_order_by"],
	gtag?: GraphQLTypes["order_by"],
	gtag_v2?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	klaviyo_id?: GraphQLTypes["order_by"],
	source?: GraphQLTypes["order_by"],
	user_ip?: GraphQLTypes["order_by"],
	username?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.guest_gtags */
["voyce_guest_gtags_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.guest_gtags" */
["voyce_guest_gtags_select_column"]: voyce_guest_gtags_select_column;
	/** input type for updating data in table "voyce.guest_gtags" */
["voyce_guest_gtags_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	device_id?: string,
	gtag?: string,
	gtag_v2?: string,
	id?: number,
	klaviyo_id?: string,
	source?: string,
	user_ip?: string,
	username?: string
};
	/** aggregate stddev on columns */
["voyce_guest_gtags_stddev_fields"]: {
	__typename: "voyce_guest_gtags_stddev_fields",
	id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_guest_gtags_stddev_pop_fields"]: {
	__typename: "voyce_guest_gtags_stddev_pop_fields",
	id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_guest_gtags_stddev_samp_fields"]: {
	__typename: "voyce_guest_gtags_stddev_samp_fields",
	id?: number
};
	/** Streaming cursor of the table "voyce_guest_gtags" */
["voyce_guest_gtags_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_guest_gtags_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_guest_gtags_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	device_id?: string,
	gtag?: string,
	gtag_v2?: string,
	id?: number,
	klaviyo_id?: string,
	source?: string,
	user_ip?: string,
	username?: string
};
	/** aggregate sum on columns */
["voyce_guest_gtags_sum_fields"]: {
	__typename: "voyce_guest_gtags_sum_fields",
	id?: number
};
	/** update columns of table "voyce.guest_gtags" */
["voyce_guest_gtags_update_column"]: voyce_guest_gtags_update_column;
	["voyce_guest_gtags_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_guest_gtags_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_guest_gtags_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_guest_gtags_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_guest_gtags_var_pop_fields"]: {
	__typename: "voyce_guest_gtags_var_pop_fields",
	id?: number
};
	/** aggregate var_samp on columns */
["voyce_guest_gtags_var_samp_fields"]: {
	__typename: "voyce_guest_gtags_var_samp_fields",
	id?: number
};
	/** aggregate variance on columns */
["voyce_guest_gtags_variance_fields"]: {
	__typename: "voyce_guest_gtags_variance_fields",
	id?: number
};
	/** columns and relationships of "voyce.metabases" */
["voyce_metabases"]: {
	__typename: "voyce_metabases",
	/** An object relationship */
	access?: GraphQLTypes["voyce_metabases_access"],
	created_at: GraphQLTypes["timestamptz"],
	dashboard_id: number,
	id: number,
	is_active: boolean,
	order: number,
	title: string,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** columns and relationships of "voyce.metabases_access" */
["voyce_metabases_access"]: {
	__typename: "voyce_metabases_access",
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	metabase_id: number,
	role_id?: number,
	updated_at: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregated selection of "voyce.metabases_access" */
["voyce_metabases_access_aggregate"]: {
	__typename: "voyce_metabases_access_aggregate",
	aggregate?: GraphQLTypes["voyce_metabases_access_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_metabases_access"]>
};
	/** aggregate fields of "voyce.metabases_access" */
["voyce_metabases_access_aggregate_fields"]: {
	__typename: "voyce_metabases_access_aggregate_fields",
	avg?: GraphQLTypes["voyce_metabases_access_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_metabases_access_max_fields"],
	min?: GraphQLTypes["voyce_metabases_access_min_fields"],
	stddev?: GraphQLTypes["voyce_metabases_access_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_metabases_access_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_metabases_access_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_metabases_access_sum_fields"],
	var_pop?: GraphQLTypes["voyce_metabases_access_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_metabases_access_var_samp_fields"],
	variance?: GraphQLTypes["voyce_metabases_access_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_metabases_access_avg_fields"]: {
	__typename: "voyce_metabases_access_avg_fields",
	id?: number,
	metabase_id?: number,
	role_id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.metabases_access". All fields are combined with a logical 'AND'. */
["voyce_metabases_access_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_metabases_access_bool_exp"]>,
	_not?: GraphQLTypes["voyce_metabases_access_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_metabases_access_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	metabase_id?: GraphQLTypes["Int_comparison_exp"],
	role_id?: GraphQLTypes["Int_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.metabases_access" */
["voyce_metabases_access_constraint"]: voyce_metabases_access_constraint;
	/** input type for incrementing numeric columns in table "voyce.metabases_access" */
["voyce_metabases_access_inc_input"]: {
		id?: number,
	metabase_id?: number,
	role_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.metabases_access" */
["voyce_metabases_access_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	metabase_id?: number,
	role_id?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_metabases_access_max_fields"]: {
	__typename: "voyce_metabases_access_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	metabase_id?: number,
	role_id?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate min on columns */
["voyce_metabases_access_min_fields"]: {
	__typename: "voyce_metabases_access_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	metabase_id?: number,
	role_id?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** response of any mutation on the table "voyce.metabases_access" */
["voyce_metabases_access_mutation_response"]: {
	__typename: "voyce_metabases_access_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_metabases_access"]>
};
	/** input type for inserting object relation for remote table "voyce.metabases_access" */
["voyce_metabases_access_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_metabases_access_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_metabases_access_on_conflict"]
};
	/** on_conflict condition type for table "voyce.metabases_access" */
["voyce_metabases_access_on_conflict"]: {
		constraint: GraphQLTypes["voyce_metabases_access_constraint"],
	update_columns: Array<GraphQLTypes["voyce_metabases_access_update_column"]>,
	where?: GraphQLTypes["voyce_metabases_access_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.metabases_access". */
["voyce_metabases_access_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	metabase_id?: GraphQLTypes["order_by"],
	role_id?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.metabases_access */
["voyce_metabases_access_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.metabases_access" */
["voyce_metabases_access_select_column"]: voyce_metabases_access_select_column;
	/** input type for updating data in table "voyce.metabases_access" */
["voyce_metabases_access_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	metabase_id?: number,
	role_id?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_metabases_access_stddev_fields"]: {
	__typename: "voyce_metabases_access_stddev_fields",
	id?: number,
	metabase_id?: number,
	role_id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_metabases_access_stddev_pop_fields"]: {
	__typename: "voyce_metabases_access_stddev_pop_fields",
	id?: number,
	metabase_id?: number,
	role_id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_metabases_access_stddev_samp_fields"]: {
	__typename: "voyce_metabases_access_stddev_samp_fields",
	id?: number,
	metabase_id?: number,
	role_id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_metabases_access" */
["voyce_metabases_access_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_metabases_access_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_metabases_access_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	metabase_id?: number,
	role_id?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_metabases_access_sum_fields"]: {
	__typename: "voyce_metabases_access_sum_fields",
	id?: number,
	metabase_id?: number,
	role_id?: number,
	user_id?: number
};
	/** update columns of table "voyce.metabases_access" */
["voyce_metabases_access_update_column"]: voyce_metabases_access_update_column;
	["voyce_metabases_access_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_metabases_access_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_metabases_access_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_metabases_access_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_metabases_access_var_pop_fields"]: {
	__typename: "voyce_metabases_access_var_pop_fields",
	id?: number,
	metabase_id?: number,
	role_id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_metabases_access_var_samp_fields"]: {
	__typename: "voyce_metabases_access_var_samp_fields",
	id?: number,
	metabase_id?: number,
	role_id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_metabases_access_variance_fields"]: {
	__typename: "voyce_metabases_access_variance_fields",
	id?: number,
	metabase_id?: number,
	role_id?: number,
	user_id?: number
};
	/** aggregated selection of "voyce.metabases" */
["voyce_metabases_aggregate"]: {
	__typename: "voyce_metabases_aggregate",
	aggregate?: GraphQLTypes["voyce_metabases_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_metabases"]>
};
	/** aggregate fields of "voyce.metabases" */
["voyce_metabases_aggregate_fields"]: {
	__typename: "voyce_metabases_aggregate_fields",
	avg?: GraphQLTypes["voyce_metabases_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_metabases_max_fields"],
	min?: GraphQLTypes["voyce_metabases_min_fields"],
	stddev?: GraphQLTypes["voyce_metabases_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_metabases_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_metabases_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_metabases_sum_fields"],
	var_pop?: GraphQLTypes["voyce_metabases_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_metabases_var_samp_fields"],
	variance?: GraphQLTypes["voyce_metabases_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_metabases_avg_fields"]: {
	__typename: "voyce_metabases_avg_fields",
	dashboard_id?: number,
	id?: number,
	order?: number
};
	/** Boolean expression to filter rows from the table "voyce.metabases". All fields are combined with a logical 'AND'. */
["voyce_metabases_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_metabases_bool_exp"]>,
	_not?: GraphQLTypes["voyce_metabases_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_metabases_bool_exp"]>,
	access?: GraphQLTypes["voyce_metabases_access_bool_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	dashboard_id?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_active?: GraphQLTypes["Boolean_comparison_exp"],
	order?: GraphQLTypes["Int_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.metabases" */
["voyce_metabases_constraint"]: voyce_metabases_constraint;
	/** input type for incrementing numeric columns in table "voyce.metabases" */
["voyce_metabases_inc_input"]: {
		dashboard_id?: number,
	id?: number,
	order?: number
};
	/** input type for inserting data into table "voyce.metabases" */
["voyce_metabases_insert_input"]: {
		access?: GraphQLTypes["voyce_metabases_access_obj_rel_insert_input"],
	created_at?: GraphQLTypes["timestamptz"],
	dashboard_id?: number,
	id?: number,
	is_active?: boolean,
	order?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_metabases_max_fields"]: {
	__typename: "voyce_metabases_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	dashboard_id?: number,
	id?: number,
	order?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_metabases_min_fields"]: {
	__typename: "voyce_metabases_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	dashboard_id?: number,
	id?: number,
	order?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.metabases" */
["voyce_metabases_mutation_response"]: {
	__typename: "voyce_metabases_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_metabases"]>
};
	/** on_conflict condition type for table "voyce.metabases" */
["voyce_metabases_on_conflict"]: {
		constraint: GraphQLTypes["voyce_metabases_constraint"],
	update_columns: Array<GraphQLTypes["voyce_metabases_update_column"]>,
	where?: GraphQLTypes["voyce_metabases_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.metabases". */
["voyce_metabases_order_by"]: {
		access?: GraphQLTypes["voyce_metabases_access_order_by"],
	created_at?: GraphQLTypes["order_by"],
	dashboard_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_active?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.metabases */
["voyce_metabases_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.metabases" */
["voyce_metabases_select_column"]: voyce_metabases_select_column;
	/** input type for updating data in table "voyce.metabases" */
["voyce_metabases_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	dashboard_id?: number,
	id?: number,
	is_active?: boolean,
	order?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_metabases_stddev_fields"]: {
	__typename: "voyce_metabases_stddev_fields",
	dashboard_id?: number,
	id?: number,
	order?: number
};
	/** aggregate stddev_pop on columns */
["voyce_metabases_stddev_pop_fields"]: {
	__typename: "voyce_metabases_stddev_pop_fields",
	dashboard_id?: number,
	id?: number,
	order?: number
};
	/** aggregate stddev_samp on columns */
["voyce_metabases_stddev_samp_fields"]: {
	__typename: "voyce_metabases_stddev_samp_fields",
	dashboard_id?: number,
	id?: number,
	order?: number
};
	/** Streaming cursor of the table "voyce_metabases" */
["voyce_metabases_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_metabases_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_metabases_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	dashboard_id?: number,
	id?: number,
	is_active?: boolean,
	order?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_metabases_sum_fields"]: {
	__typename: "voyce_metabases_sum_fields",
	dashboard_id?: number,
	id?: number,
	order?: number
};
	/** update columns of table "voyce.metabases" */
["voyce_metabases_update_column"]: voyce_metabases_update_column;
	["voyce_metabases_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_metabases_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_metabases_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_metabases_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_metabases_var_pop_fields"]: {
	__typename: "voyce_metabases_var_pop_fields",
	dashboard_id?: number,
	id?: number,
	order?: number
};
	/** aggregate var_samp on columns */
["voyce_metabases_var_samp_fields"]: {
	__typename: "voyce_metabases_var_samp_fields",
	dashboard_id?: number,
	id?: number,
	order?: number
};
	/** aggregate variance on columns */
["voyce_metabases_variance_fields"]: {
	__typename: "voyce_metabases_variance_fields",
	dashboard_id?: number,
	id?: number,
	order?: number
};
	/** columns and relationships of "voyce.newsletter" */
["voyce_newsletter"]: {
	__typename: "voyce_newsletter",
	created_at: GraphQLTypes["timestamptz"],
	email: string,
	id: number,
	user_id?: number
};
	/** aggregated selection of "voyce.newsletter" */
["voyce_newsletter_aggregate"]: {
	__typename: "voyce_newsletter_aggregate",
	aggregate?: GraphQLTypes["voyce_newsletter_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_newsletter"]>
};
	/** aggregate fields of "voyce.newsletter" */
["voyce_newsletter_aggregate_fields"]: {
	__typename: "voyce_newsletter_aggregate_fields",
	avg?: GraphQLTypes["voyce_newsletter_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_newsletter_max_fields"],
	min?: GraphQLTypes["voyce_newsletter_min_fields"],
	stddev?: GraphQLTypes["voyce_newsletter_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_newsletter_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_newsletter_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_newsletter_sum_fields"],
	var_pop?: GraphQLTypes["voyce_newsletter_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_newsletter_var_samp_fields"],
	variance?: GraphQLTypes["voyce_newsletter_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_newsletter_avg_fields"]: {
	__typename: "voyce_newsletter_avg_fields",
	id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.newsletter". All fields are combined with a logical 'AND'. */
["voyce_newsletter_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_newsletter_bool_exp"]>,
	_not?: GraphQLTypes["voyce_newsletter_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_newsletter_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	email?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.newsletter" */
["voyce_newsletter_constraint"]: voyce_newsletter_constraint;
	/** input type for incrementing numeric columns in table "voyce.newsletter" */
["voyce_newsletter_inc_input"]: {
		id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.newsletter" */
["voyce_newsletter_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	id?: number,
	user_id?: number
};
	/** aggregate max on columns */
["voyce_newsletter_max_fields"]: {
	__typename: "voyce_newsletter_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	id?: number,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_newsletter_min_fields"]: {
	__typename: "voyce_newsletter_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	id?: number,
	user_id?: number
};
	/** response of any mutation on the table "voyce.newsletter" */
["voyce_newsletter_mutation_response"]: {
	__typename: "voyce_newsletter_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_newsletter"]>
};
	/** on_conflict condition type for table "voyce.newsletter" */
["voyce_newsletter_on_conflict"]: {
		constraint: GraphQLTypes["voyce_newsletter_constraint"],
	update_columns: Array<GraphQLTypes["voyce_newsletter_update_column"]>,
	where?: GraphQLTypes["voyce_newsletter_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.newsletter". */
["voyce_newsletter_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	email?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.newsletter */
["voyce_newsletter_pk_columns_input"]: {
		email: string
};
	/** select columns of table "voyce.newsletter" */
["voyce_newsletter_select_column"]: voyce_newsletter_select_column;
	/** input type for updating data in table "voyce.newsletter" */
["voyce_newsletter_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	id?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_newsletter_stddev_fields"]: {
	__typename: "voyce_newsletter_stddev_fields",
	id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_newsletter_stddev_pop_fields"]: {
	__typename: "voyce_newsletter_stddev_pop_fields",
	id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_newsletter_stddev_samp_fields"]: {
	__typename: "voyce_newsletter_stddev_samp_fields",
	id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_newsletter" */
["voyce_newsletter_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_newsletter_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_newsletter_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	id?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_newsletter_sum_fields"]: {
	__typename: "voyce_newsletter_sum_fields",
	id?: number,
	user_id?: number
};
	/** update columns of table "voyce.newsletter" */
["voyce_newsletter_update_column"]: voyce_newsletter_update_column;
	["voyce_newsletter_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_newsletter_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_newsletter_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_newsletter_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_newsletter_var_pop_fields"]: {
	__typename: "voyce_newsletter_var_pop_fields",
	id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_newsletter_var_samp_fields"]: {
	__typename: "voyce_newsletter_var_samp_fields",
	id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_newsletter_variance_fields"]: {
	__typename: "voyce_newsletter_variance_fields",
	id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.notification_series_settings" */
["voyce_notification_series_settings"]: {
	__typename: "voyce_notification_series_settings",
	chapter_comment_email: GraphQLTypes["smallint"],
	chapter_comment_push: GraphQLTypes["smallint"],
	chapter_like_email: GraphQLTypes["smallint"],
	chapter_like_push: GraphQLTypes["smallint"],
	chapter_react_email: GraphQLTypes["smallint"],
	chapter_react_push: GraphQLTypes["smallint"],
	id: number,
	series_id: number,
	subscription_email: GraphQLTypes["smallint"],
	subscription_push: GraphQLTypes["smallint"],
	user_id: number
};
	/** aggregated selection of "voyce.notification_series_settings" */
["voyce_notification_series_settings_aggregate"]: {
	__typename: "voyce_notification_series_settings_aggregate",
	aggregate?: GraphQLTypes["voyce_notification_series_settings_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_notification_series_settings"]>
};
	["voyce_notification_series_settings_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_notification_series_settings_aggregate_bool_exp_count"]
};
	["voyce_notification_series_settings_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_notification_series_settings_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_notification_series_settings_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.notification_series_settings" */
["voyce_notification_series_settings_aggregate_fields"]: {
	__typename: "voyce_notification_series_settings_aggregate_fields",
	avg?: GraphQLTypes["voyce_notification_series_settings_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_notification_series_settings_max_fields"],
	min?: GraphQLTypes["voyce_notification_series_settings_min_fields"],
	stddev?: GraphQLTypes["voyce_notification_series_settings_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_notification_series_settings_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_notification_series_settings_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_notification_series_settings_sum_fields"],
	var_pop?: GraphQLTypes["voyce_notification_series_settings_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_notification_series_settings_var_samp_fields"],
	variance?: GraphQLTypes["voyce_notification_series_settings_variance_fields"]
};
	/** order by aggregate values of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_notification_series_settings_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_notification_series_settings_max_order_by"],
	min?: GraphQLTypes["voyce_notification_series_settings_min_order_by"],
	stddev?: GraphQLTypes["voyce_notification_series_settings_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_notification_series_settings_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_notification_series_settings_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_notification_series_settings_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_notification_series_settings_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_notification_series_settings_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_notification_series_settings_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.notification_series_settings" */
["voyce_notification_series_settings_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_notification_series_settings_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_notification_series_settings_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_notification_series_settings_avg_fields"]: {
	__typename: "voyce_notification_series_settings_avg_fields",
	chapter_comment_email?: number,
	chapter_comment_push?: number,
	chapter_like_email?: number,
	chapter_like_push?: number,
	chapter_react_email?: number,
	chapter_react_push?: number,
	id?: number,
	series_id?: number,
	subscription_email?: number,
	subscription_push?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_avg_order_by"]: {
		chapter_comment_email?: GraphQLTypes["order_by"],
	chapter_comment_push?: GraphQLTypes["order_by"],
	chapter_like_email?: GraphQLTypes["order_by"],
	chapter_like_push?: GraphQLTypes["order_by"],
	chapter_react_email?: GraphQLTypes["order_by"],
	chapter_react_push?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	subscription_email?: GraphQLTypes["order_by"],
	subscription_push?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.notification_series_settings". All fields are combined with a logical 'AND'. */
["voyce_notification_series_settings_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_notification_series_settings_bool_exp"]>,
	_not?: GraphQLTypes["voyce_notification_series_settings_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_notification_series_settings_bool_exp"]>,
	chapter_comment_email?: GraphQLTypes["smallint_comparison_exp"],
	chapter_comment_push?: GraphQLTypes["smallint_comparison_exp"],
	chapter_like_email?: GraphQLTypes["smallint_comparison_exp"],
	chapter_like_push?: GraphQLTypes["smallint_comparison_exp"],
	chapter_react_email?: GraphQLTypes["smallint_comparison_exp"],
	chapter_react_push?: GraphQLTypes["smallint_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	subscription_email?: GraphQLTypes["smallint_comparison_exp"],
	subscription_push?: GraphQLTypes["smallint_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.notification_series_settings" */
["voyce_notification_series_settings_constraint"]: voyce_notification_series_settings_constraint;
	/** input type for incrementing numeric columns in table "voyce.notification_series_settings" */
["voyce_notification_series_settings_inc_input"]: {
		chapter_comment_email?: GraphQLTypes["smallint"],
	chapter_comment_push?: GraphQLTypes["smallint"],
	chapter_like_email?: GraphQLTypes["smallint"],
	chapter_like_push?: GraphQLTypes["smallint"],
	chapter_react_email?: GraphQLTypes["smallint"],
	chapter_react_push?: GraphQLTypes["smallint"],
	id?: number,
	series_id?: number,
	subscription_email?: GraphQLTypes["smallint"],
	subscription_push?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** input type for inserting data into table "voyce.notification_series_settings" */
["voyce_notification_series_settings_insert_input"]: {
		chapter_comment_email?: GraphQLTypes["smallint"],
	chapter_comment_push?: GraphQLTypes["smallint"],
	chapter_like_email?: GraphQLTypes["smallint"],
	chapter_like_push?: GraphQLTypes["smallint"],
	chapter_react_email?: GraphQLTypes["smallint"],
	chapter_react_push?: GraphQLTypes["smallint"],
	id?: number,
	series_id?: number,
	subscription_email?: GraphQLTypes["smallint"],
	subscription_push?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_notification_series_settings_max_fields"]: {
	__typename: "voyce_notification_series_settings_max_fields",
	chapter_comment_email?: GraphQLTypes["smallint"],
	chapter_comment_push?: GraphQLTypes["smallint"],
	chapter_like_email?: GraphQLTypes["smallint"],
	chapter_like_push?: GraphQLTypes["smallint"],
	chapter_react_email?: GraphQLTypes["smallint"],
	chapter_react_push?: GraphQLTypes["smallint"],
	id?: number,
	series_id?: number,
	subscription_email?: GraphQLTypes["smallint"],
	subscription_push?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** order by max() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_max_order_by"]: {
		chapter_comment_email?: GraphQLTypes["order_by"],
	chapter_comment_push?: GraphQLTypes["order_by"],
	chapter_like_email?: GraphQLTypes["order_by"],
	chapter_like_push?: GraphQLTypes["order_by"],
	chapter_react_email?: GraphQLTypes["order_by"],
	chapter_react_push?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	subscription_email?: GraphQLTypes["order_by"],
	subscription_push?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_notification_series_settings_min_fields"]: {
	__typename: "voyce_notification_series_settings_min_fields",
	chapter_comment_email?: GraphQLTypes["smallint"],
	chapter_comment_push?: GraphQLTypes["smallint"],
	chapter_like_email?: GraphQLTypes["smallint"],
	chapter_like_push?: GraphQLTypes["smallint"],
	chapter_react_email?: GraphQLTypes["smallint"],
	chapter_react_push?: GraphQLTypes["smallint"],
	id?: number,
	series_id?: number,
	subscription_email?: GraphQLTypes["smallint"],
	subscription_push?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** order by min() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_min_order_by"]: {
		chapter_comment_email?: GraphQLTypes["order_by"],
	chapter_comment_push?: GraphQLTypes["order_by"],
	chapter_like_email?: GraphQLTypes["order_by"],
	chapter_like_push?: GraphQLTypes["order_by"],
	chapter_react_email?: GraphQLTypes["order_by"],
	chapter_react_push?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	subscription_email?: GraphQLTypes["order_by"],
	subscription_push?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.notification_series_settings" */
["voyce_notification_series_settings_mutation_response"]: {
	__typename: "voyce_notification_series_settings_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_notification_series_settings"]>
};
	/** input type for inserting object relation for remote table "voyce.notification_series_settings" */
["voyce_notification_series_settings_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_notification_series_settings_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_notification_series_settings_on_conflict"]
};
	/** on_conflict condition type for table "voyce.notification_series_settings" */
["voyce_notification_series_settings_on_conflict"]: {
		constraint: GraphQLTypes["voyce_notification_series_settings_constraint"],
	update_columns: Array<GraphQLTypes["voyce_notification_series_settings_update_column"]>,
	where?: GraphQLTypes["voyce_notification_series_settings_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.notification_series_settings". */
["voyce_notification_series_settings_order_by"]: {
		chapter_comment_email?: GraphQLTypes["order_by"],
	chapter_comment_push?: GraphQLTypes["order_by"],
	chapter_like_email?: GraphQLTypes["order_by"],
	chapter_like_push?: GraphQLTypes["order_by"],
	chapter_react_email?: GraphQLTypes["order_by"],
	chapter_react_push?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	subscription_email?: GraphQLTypes["order_by"],
	subscription_push?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.notification_series_settings */
["voyce_notification_series_settings_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_select_column"]: voyce_notification_series_settings_select_column;
	/** input type for updating data in table "voyce.notification_series_settings" */
["voyce_notification_series_settings_set_input"]: {
		chapter_comment_email?: GraphQLTypes["smallint"],
	chapter_comment_push?: GraphQLTypes["smallint"],
	chapter_like_email?: GraphQLTypes["smallint"],
	chapter_like_push?: GraphQLTypes["smallint"],
	chapter_react_email?: GraphQLTypes["smallint"],
	chapter_react_push?: GraphQLTypes["smallint"],
	id?: number,
	series_id?: number,
	subscription_email?: GraphQLTypes["smallint"],
	subscription_push?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_notification_series_settings_stddev_fields"]: {
	__typename: "voyce_notification_series_settings_stddev_fields",
	chapter_comment_email?: number,
	chapter_comment_push?: number,
	chapter_like_email?: number,
	chapter_like_push?: number,
	chapter_react_email?: number,
	chapter_react_push?: number,
	id?: number,
	series_id?: number,
	subscription_email?: number,
	subscription_push?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_stddev_order_by"]: {
		chapter_comment_email?: GraphQLTypes["order_by"],
	chapter_comment_push?: GraphQLTypes["order_by"],
	chapter_like_email?: GraphQLTypes["order_by"],
	chapter_like_push?: GraphQLTypes["order_by"],
	chapter_react_email?: GraphQLTypes["order_by"],
	chapter_react_push?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	subscription_email?: GraphQLTypes["order_by"],
	subscription_push?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_notification_series_settings_stddev_pop_fields"]: {
	__typename: "voyce_notification_series_settings_stddev_pop_fields",
	chapter_comment_email?: number,
	chapter_comment_push?: number,
	chapter_like_email?: number,
	chapter_like_push?: number,
	chapter_react_email?: number,
	chapter_react_push?: number,
	id?: number,
	series_id?: number,
	subscription_email?: number,
	subscription_push?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_stddev_pop_order_by"]: {
		chapter_comment_email?: GraphQLTypes["order_by"],
	chapter_comment_push?: GraphQLTypes["order_by"],
	chapter_like_email?: GraphQLTypes["order_by"],
	chapter_like_push?: GraphQLTypes["order_by"],
	chapter_react_email?: GraphQLTypes["order_by"],
	chapter_react_push?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	subscription_email?: GraphQLTypes["order_by"],
	subscription_push?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_notification_series_settings_stddev_samp_fields"]: {
	__typename: "voyce_notification_series_settings_stddev_samp_fields",
	chapter_comment_email?: number,
	chapter_comment_push?: number,
	chapter_like_email?: number,
	chapter_like_push?: number,
	chapter_react_email?: number,
	chapter_react_push?: number,
	id?: number,
	series_id?: number,
	subscription_email?: number,
	subscription_push?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_stddev_samp_order_by"]: {
		chapter_comment_email?: GraphQLTypes["order_by"],
	chapter_comment_push?: GraphQLTypes["order_by"],
	chapter_like_email?: GraphQLTypes["order_by"],
	chapter_like_push?: GraphQLTypes["order_by"],
	chapter_react_email?: GraphQLTypes["order_by"],
	chapter_react_push?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	subscription_email?: GraphQLTypes["order_by"],
	subscription_push?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_notification_series_settings" */
["voyce_notification_series_settings_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_notification_series_settings_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_notification_series_settings_stream_cursor_value_input"]: {
		chapter_comment_email?: GraphQLTypes["smallint"],
	chapter_comment_push?: GraphQLTypes["smallint"],
	chapter_like_email?: GraphQLTypes["smallint"],
	chapter_like_push?: GraphQLTypes["smallint"],
	chapter_react_email?: GraphQLTypes["smallint"],
	chapter_react_push?: GraphQLTypes["smallint"],
	id?: number,
	series_id?: number,
	subscription_email?: GraphQLTypes["smallint"],
	subscription_push?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_notification_series_settings_sum_fields"]: {
	__typename: "voyce_notification_series_settings_sum_fields",
	chapter_comment_email?: GraphQLTypes["smallint"],
	chapter_comment_push?: GraphQLTypes["smallint"],
	chapter_like_email?: GraphQLTypes["smallint"],
	chapter_like_push?: GraphQLTypes["smallint"],
	chapter_react_email?: GraphQLTypes["smallint"],
	chapter_react_push?: GraphQLTypes["smallint"],
	id?: number,
	series_id?: number,
	subscription_email?: GraphQLTypes["smallint"],
	subscription_push?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** order by sum() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_sum_order_by"]: {
		chapter_comment_email?: GraphQLTypes["order_by"],
	chapter_comment_push?: GraphQLTypes["order_by"],
	chapter_like_email?: GraphQLTypes["order_by"],
	chapter_like_push?: GraphQLTypes["order_by"],
	chapter_react_email?: GraphQLTypes["order_by"],
	chapter_react_push?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	subscription_email?: GraphQLTypes["order_by"],
	subscription_push?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_update_column"]: voyce_notification_series_settings_update_column;
	["voyce_notification_series_settings_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_notification_series_settings_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_notification_series_settings_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_notification_series_settings_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_notification_series_settings_var_pop_fields"]: {
	__typename: "voyce_notification_series_settings_var_pop_fields",
	chapter_comment_email?: number,
	chapter_comment_push?: number,
	chapter_like_email?: number,
	chapter_like_push?: number,
	chapter_react_email?: number,
	chapter_react_push?: number,
	id?: number,
	series_id?: number,
	subscription_email?: number,
	subscription_push?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_var_pop_order_by"]: {
		chapter_comment_email?: GraphQLTypes["order_by"],
	chapter_comment_push?: GraphQLTypes["order_by"],
	chapter_like_email?: GraphQLTypes["order_by"],
	chapter_like_push?: GraphQLTypes["order_by"],
	chapter_react_email?: GraphQLTypes["order_by"],
	chapter_react_push?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	subscription_email?: GraphQLTypes["order_by"],
	subscription_push?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_notification_series_settings_var_samp_fields"]: {
	__typename: "voyce_notification_series_settings_var_samp_fields",
	chapter_comment_email?: number,
	chapter_comment_push?: number,
	chapter_like_email?: number,
	chapter_like_push?: number,
	chapter_react_email?: number,
	chapter_react_push?: number,
	id?: number,
	series_id?: number,
	subscription_email?: number,
	subscription_push?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_var_samp_order_by"]: {
		chapter_comment_email?: GraphQLTypes["order_by"],
	chapter_comment_push?: GraphQLTypes["order_by"],
	chapter_like_email?: GraphQLTypes["order_by"],
	chapter_like_push?: GraphQLTypes["order_by"],
	chapter_react_email?: GraphQLTypes["order_by"],
	chapter_react_push?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	subscription_email?: GraphQLTypes["order_by"],
	subscription_push?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_notification_series_settings_variance_fields"]: {
	__typename: "voyce_notification_series_settings_variance_fields",
	chapter_comment_email?: number,
	chapter_comment_push?: number,
	chapter_like_email?: number,
	chapter_like_push?: number,
	chapter_react_email?: number,
	chapter_react_push?: number,
	id?: number,
	series_id?: number,
	subscription_email?: number,
	subscription_push?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.notification_series_settings" */
["voyce_notification_series_settings_variance_order_by"]: {
		chapter_comment_email?: GraphQLTypes["order_by"],
	chapter_comment_push?: GraphQLTypes["order_by"],
	chapter_like_email?: GraphQLTypes["order_by"],
	chapter_like_push?: GraphQLTypes["order_by"],
	chapter_react_email?: GraphQLTypes["order_by"],
	chapter_react_push?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	subscription_email?: GraphQLTypes["order_by"],
	subscription_push?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.notification_settings" */
["voyce_notification_settings"]: {
	__typename: "voyce_notification_settings",
	chapter_comment_email: GraphQLTypes["smallint"],
	chapter_comment_push: GraphQLTypes["smallint"],
	chapter_like_email: GraphQLTypes["smallint"],
	chapter_like_push: GraphQLTypes["smallint"],
	chapter_react_email: GraphQLTypes["smallint"],
	chapter_react_push: GraphQLTypes["smallint"],
	chapter_release_email: GraphQLTypes["smallint"],
	chapter_release_push: GraphQLTypes["smallint"],
	comment_react_email: GraphQLTypes["smallint"],
	comment_react_push: GraphQLTypes["smallint"],
	comment_reply_email: GraphQLTypes["smallint"],
	comment_reply_push: GraphQLTypes["smallint"],
	event_email: GraphQLTypes["smallint"],
	event_push: GraphQLTypes["smallint"],
	follow_email: GraphQLTypes["smallint"],
	follow_push: GraphQLTypes["smallint"],
	id: number,
	promotion_email: GraphQLTypes["smallint"],
	promotion_push: GraphQLTypes["smallint"],
	recommended_series_email: GraphQLTypes["smallint"],
	recommended_series_push: GraphQLTypes["smallint"],
	reward_email: GraphQLTypes["smallint"],
	reward_push: GraphQLTypes["smallint"],
	subscription_email: GraphQLTypes["smallint"],
	subscription_push: GraphQLTypes["smallint"],
	/** An object relationship */
	user?: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.notification_settings" */
["voyce_notification_settings_aggregate"]: {
	__typename: "voyce_notification_settings_aggregate",
	aggregate?: GraphQLTypes["voyce_notification_settings_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_notification_settings"]>
};
	/** aggregate fields of "voyce.notification_settings" */
["voyce_notification_settings_aggregate_fields"]: {
	__typename: "voyce_notification_settings_aggregate_fields",
	avg?: GraphQLTypes["voyce_notification_settings_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_notification_settings_max_fields"],
	min?: GraphQLTypes["voyce_notification_settings_min_fields"],
	stddev?: GraphQLTypes["voyce_notification_settings_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_notification_settings_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_notification_settings_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_notification_settings_sum_fields"],
	var_pop?: GraphQLTypes["voyce_notification_settings_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_notification_settings_var_samp_fields"],
	variance?: GraphQLTypes["voyce_notification_settings_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_notification_settings_avg_fields"]: {
	__typename: "voyce_notification_settings_avg_fields",
	chapter_comment_email?: number,
	chapter_comment_push?: number,
	chapter_like_email?: number,
	chapter_like_push?: number,
	chapter_react_email?: number,
	chapter_react_push?: number,
	chapter_release_email?: number,
	chapter_release_push?: number,
	comment_react_email?: number,
	comment_react_push?: number,
	comment_reply_email?: number,
	comment_reply_push?: number,
	event_email?: number,
	event_push?: number,
	follow_email?: number,
	follow_push?: number,
	id?: number,
	promotion_email?: number,
	promotion_push?: number,
	recommended_series_email?: number,
	recommended_series_push?: number,
	reward_email?: number,
	reward_push?: number,
	subscription_email?: number,
	subscription_push?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.notification_settings". All fields are combined with a logical 'AND'. */
["voyce_notification_settings_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_notification_settings_bool_exp"]>,
	_not?: GraphQLTypes["voyce_notification_settings_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_notification_settings_bool_exp"]>,
	chapter_comment_email?: GraphQLTypes["smallint_comparison_exp"],
	chapter_comment_push?: GraphQLTypes["smallint_comparison_exp"],
	chapter_like_email?: GraphQLTypes["smallint_comparison_exp"],
	chapter_like_push?: GraphQLTypes["smallint_comparison_exp"],
	chapter_react_email?: GraphQLTypes["smallint_comparison_exp"],
	chapter_react_push?: GraphQLTypes["smallint_comparison_exp"],
	chapter_release_email?: GraphQLTypes["smallint_comparison_exp"],
	chapter_release_push?: GraphQLTypes["smallint_comparison_exp"],
	comment_react_email?: GraphQLTypes["smallint_comparison_exp"],
	comment_react_push?: GraphQLTypes["smallint_comparison_exp"],
	comment_reply_email?: GraphQLTypes["smallint_comparison_exp"],
	comment_reply_push?: GraphQLTypes["smallint_comparison_exp"],
	event_email?: GraphQLTypes["smallint_comparison_exp"],
	event_push?: GraphQLTypes["smallint_comparison_exp"],
	follow_email?: GraphQLTypes["smallint_comparison_exp"],
	follow_push?: GraphQLTypes["smallint_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	promotion_email?: GraphQLTypes["smallint_comparison_exp"],
	promotion_push?: GraphQLTypes["smallint_comparison_exp"],
	recommended_series_email?: GraphQLTypes["smallint_comparison_exp"],
	recommended_series_push?: GraphQLTypes["smallint_comparison_exp"],
	reward_email?: GraphQLTypes["smallint_comparison_exp"],
	reward_push?: GraphQLTypes["smallint_comparison_exp"],
	subscription_email?: GraphQLTypes["smallint_comparison_exp"],
	subscription_push?: GraphQLTypes["smallint_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.notification_settings" */
["voyce_notification_settings_constraint"]: voyce_notification_settings_constraint;
	/** input type for incrementing numeric columns in table "voyce.notification_settings" */
["voyce_notification_settings_inc_input"]: {
		chapter_comment_email?: GraphQLTypes["smallint"],
	chapter_comment_push?: GraphQLTypes["smallint"],
	chapter_like_email?: GraphQLTypes["smallint"],
	chapter_like_push?: GraphQLTypes["smallint"],
	chapter_react_email?: GraphQLTypes["smallint"],
	chapter_react_push?: GraphQLTypes["smallint"],
	chapter_release_email?: GraphQLTypes["smallint"],
	chapter_release_push?: GraphQLTypes["smallint"],
	comment_react_email?: GraphQLTypes["smallint"],
	comment_react_push?: GraphQLTypes["smallint"],
	comment_reply_email?: GraphQLTypes["smallint"],
	comment_reply_push?: GraphQLTypes["smallint"],
	event_email?: GraphQLTypes["smallint"],
	event_push?: GraphQLTypes["smallint"],
	follow_email?: GraphQLTypes["smallint"],
	follow_push?: GraphQLTypes["smallint"],
	id?: number,
	promotion_email?: GraphQLTypes["smallint"],
	promotion_push?: GraphQLTypes["smallint"],
	recommended_series_email?: GraphQLTypes["smallint"],
	recommended_series_push?: GraphQLTypes["smallint"],
	reward_email?: GraphQLTypes["smallint"],
	reward_push?: GraphQLTypes["smallint"],
	subscription_email?: GraphQLTypes["smallint"],
	subscription_push?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** input type for inserting data into table "voyce.notification_settings" */
["voyce_notification_settings_insert_input"]: {
		chapter_comment_email?: GraphQLTypes["smallint"],
	chapter_comment_push?: GraphQLTypes["smallint"],
	chapter_like_email?: GraphQLTypes["smallint"],
	chapter_like_push?: GraphQLTypes["smallint"],
	chapter_react_email?: GraphQLTypes["smallint"],
	chapter_react_push?: GraphQLTypes["smallint"],
	chapter_release_email?: GraphQLTypes["smallint"],
	chapter_release_push?: GraphQLTypes["smallint"],
	comment_react_email?: GraphQLTypes["smallint"],
	comment_react_push?: GraphQLTypes["smallint"],
	comment_reply_email?: GraphQLTypes["smallint"],
	comment_reply_push?: GraphQLTypes["smallint"],
	event_email?: GraphQLTypes["smallint"],
	event_push?: GraphQLTypes["smallint"],
	follow_email?: GraphQLTypes["smallint"],
	follow_push?: GraphQLTypes["smallint"],
	id?: number,
	promotion_email?: GraphQLTypes["smallint"],
	promotion_push?: GraphQLTypes["smallint"],
	recommended_series_email?: GraphQLTypes["smallint"],
	recommended_series_push?: GraphQLTypes["smallint"],
	reward_email?: GraphQLTypes["smallint"],
	reward_push?: GraphQLTypes["smallint"],
	subscription_email?: GraphQLTypes["smallint"],
	subscription_push?: GraphQLTypes["smallint"],
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_notification_settings_max_fields"]: {
	__typename: "voyce_notification_settings_max_fields",
	chapter_comment_email?: GraphQLTypes["smallint"],
	chapter_comment_push?: GraphQLTypes["smallint"],
	chapter_like_email?: GraphQLTypes["smallint"],
	chapter_like_push?: GraphQLTypes["smallint"],
	chapter_react_email?: GraphQLTypes["smallint"],
	chapter_react_push?: GraphQLTypes["smallint"],
	chapter_release_email?: GraphQLTypes["smallint"],
	chapter_release_push?: GraphQLTypes["smallint"],
	comment_react_email?: GraphQLTypes["smallint"],
	comment_react_push?: GraphQLTypes["smallint"],
	comment_reply_email?: GraphQLTypes["smallint"],
	comment_reply_push?: GraphQLTypes["smallint"],
	event_email?: GraphQLTypes["smallint"],
	event_push?: GraphQLTypes["smallint"],
	follow_email?: GraphQLTypes["smallint"],
	follow_push?: GraphQLTypes["smallint"],
	id?: number,
	promotion_email?: GraphQLTypes["smallint"],
	promotion_push?: GraphQLTypes["smallint"],
	recommended_series_email?: GraphQLTypes["smallint"],
	recommended_series_push?: GraphQLTypes["smallint"],
	reward_email?: GraphQLTypes["smallint"],
	reward_push?: GraphQLTypes["smallint"],
	subscription_email?: GraphQLTypes["smallint"],
	subscription_push?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** aggregate min on columns */
["voyce_notification_settings_min_fields"]: {
	__typename: "voyce_notification_settings_min_fields",
	chapter_comment_email?: GraphQLTypes["smallint"],
	chapter_comment_push?: GraphQLTypes["smallint"],
	chapter_like_email?: GraphQLTypes["smallint"],
	chapter_like_push?: GraphQLTypes["smallint"],
	chapter_react_email?: GraphQLTypes["smallint"],
	chapter_react_push?: GraphQLTypes["smallint"],
	chapter_release_email?: GraphQLTypes["smallint"],
	chapter_release_push?: GraphQLTypes["smallint"],
	comment_react_email?: GraphQLTypes["smallint"],
	comment_react_push?: GraphQLTypes["smallint"],
	comment_reply_email?: GraphQLTypes["smallint"],
	comment_reply_push?: GraphQLTypes["smallint"],
	event_email?: GraphQLTypes["smallint"],
	event_push?: GraphQLTypes["smallint"],
	follow_email?: GraphQLTypes["smallint"],
	follow_push?: GraphQLTypes["smallint"],
	id?: number,
	promotion_email?: GraphQLTypes["smallint"],
	promotion_push?: GraphQLTypes["smallint"],
	recommended_series_email?: GraphQLTypes["smallint"],
	recommended_series_push?: GraphQLTypes["smallint"],
	reward_email?: GraphQLTypes["smallint"],
	reward_push?: GraphQLTypes["smallint"],
	subscription_email?: GraphQLTypes["smallint"],
	subscription_push?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** response of any mutation on the table "voyce.notification_settings" */
["voyce_notification_settings_mutation_response"]: {
	__typename: "voyce_notification_settings_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_notification_settings"]>
};
	/** input type for inserting object relation for remote table "voyce.notification_settings" */
["voyce_notification_settings_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_notification_settings_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_notification_settings_on_conflict"]
};
	/** on_conflict condition type for table "voyce.notification_settings" */
["voyce_notification_settings_on_conflict"]: {
		constraint: GraphQLTypes["voyce_notification_settings_constraint"],
	update_columns: Array<GraphQLTypes["voyce_notification_settings_update_column"]>,
	where?: GraphQLTypes["voyce_notification_settings_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.notification_settings". */
["voyce_notification_settings_order_by"]: {
		chapter_comment_email?: GraphQLTypes["order_by"],
	chapter_comment_push?: GraphQLTypes["order_by"],
	chapter_like_email?: GraphQLTypes["order_by"],
	chapter_like_push?: GraphQLTypes["order_by"],
	chapter_react_email?: GraphQLTypes["order_by"],
	chapter_react_push?: GraphQLTypes["order_by"],
	chapter_release_email?: GraphQLTypes["order_by"],
	chapter_release_push?: GraphQLTypes["order_by"],
	comment_react_email?: GraphQLTypes["order_by"],
	comment_react_push?: GraphQLTypes["order_by"],
	comment_reply_email?: GraphQLTypes["order_by"],
	comment_reply_push?: GraphQLTypes["order_by"],
	event_email?: GraphQLTypes["order_by"],
	event_push?: GraphQLTypes["order_by"],
	follow_email?: GraphQLTypes["order_by"],
	follow_push?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	promotion_email?: GraphQLTypes["order_by"],
	promotion_push?: GraphQLTypes["order_by"],
	recommended_series_email?: GraphQLTypes["order_by"],
	recommended_series_push?: GraphQLTypes["order_by"],
	reward_email?: GraphQLTypes["order_by"],
	reward_push?: GraphQLTypes["order_by"],
	subscription_email?: GraphQLTypes["order_by"],
	subscription_push?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.notification_settings */
["voyce_notification_settings_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.notification_settings" */
["voyce_notification_settings_select_column"]: voyce_notification_settings_select_column;
	/** input type for updating data in table "voyce.notification_settings" */
["voyce_notification_settings_set_input"]: {
		chapter_comment_email?: GraphQLTypes["smallint"],
	chapter_comment_push?: GraphQLTypes["smallint"],
	chapter_like_email?: GraphQLTypes["smallint"],
	chapter_like_push?: GraphQLTypes["smallint"],
	chapter_react_email?: GraphQLTypes["smallint"],
	chapter_react_push?: GraphQLTypes["smallint"],
	chapter_release_email?: GraphQLTypes["smallint"],
	chapter_release_push?: GraphQLTypes["smallint"],
	comment_react_email?: GraphQLTypes["smallint"],
	comment_react_push?: GraphQLTypes["smallint"],
	comment_reply_email?: GraphQLTypes["smallint"],
	comment_reply_push?: GraphQLTypes["smallint"],
	event_email?: GraphQLTypes["smallint"],
	event_push?: GraphQLTypes["smallint"],
	follow_email?: GraphQLTypes["smallint"],
	follow_push?: GraphQLTypes["smallint"],
	id?: number,
	promotion_email?: GraphQLTypes["smallint"],
	promotion_push?: GraphQLTypes["smallint"],
	recommended_series_email?: GraphQLTypes["smallint"],
	recommended_series_push?: GraphQLTypes["smallint"],
	reward_email?: GraphQLTypes["smallint"],
	reward_push?: GraphQLTypes["smallint"],
	subscription_email?: GraphQLTypes["smallint"],
	subscription_push?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_notification_settings_stddev_fields"]: {
	__typename: "voyce_notification_settings_stddev_fields",
	chapter_comment_email?: number,
	chapter_comment_push?: number,
	chapter_like_email?: number,
	chapter_like_push?: number,
	chapter_react_email?: number,
	chapter_react_push?: number,
	chapter_release_email?: number,
	chapter_release_push?: number,
	comment_react_email?: number,
	comment_react_push?: number,
	comment_reply_email?: number,
	comment_reply_push?: number,
	event_email?: number,
	event_push?: number,
	follow_email?: number,
	follow_push?: number,
	id?: number,
	promotion_email?: number,
	promotion_push?: number,
	recommended_series_email?: number,
	recommended_series_push?: number,
	reward_email?: number,
	reward_push?: number,
	subscription_email?: number,
	subscription_push?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_notification_settings_stddev_pop_fields"]: {
	__typename: "voyce_notification_settings_stddev_pop_fields",
	chapter_comment_email?: number,
	chapter_comment_push?: number,
	chapter_like_email?: number,
	chapter_like_push?: number,
	chapter_react_email?: number,
	chapter_react_push?: number,
	chapter_release_email?: number,
	chapter_release_push?: number,
	comment_react_email?: number,
	comment_react_push?: number,
	comment_reply_email?: number,
	comment_reply_push?: number,
	event_email?: number,
	event_push?: number,
	follow_email?: number,
	follow_push?: number,
	id?: number,
	promotion_email?: number,
	promotion_push?: number,
	recommended_series_email?: number,
	recommended_series_push?: number,
	reward_email?: number,
	reward_push?: number,
	subscription_email?: number,
	subscription_push?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_notification_settings_stddev_samp_fields"]: {
	__typename: "voyce_notification_settings_stddev_samp_fields",
	chapter_comment_email?: number,
	chapter_comment_push?: number,
	chapter_like_email?: number,
	chapter_like_push?: number,
	chapter_react_email?: number,
	chapter_react_push?: number,
	chapter_release_email?: number,
	chapter_release_push?: number,
	comment_react_email?: number,
	comment_react_push?: number,
	comment_reply_email?: number,
	comment_reply_push?: number,
	event_email?: number,
	event_push?: number,
	follow_email?: number,
	follow_push?: number,
	id?: number,
	promotion_email?: number,
	promotion_push?: number,
	recommended_series_email?: number,
	recommended_series_push?: number,
	reward_email?: number,
	reward_push?: number,
	subscription_email?: number,
	subscription_push?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_notification_settings" */
["voyce_notification_settings_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_notification_settings_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_notification_settings_stream_cursor_value_input"]: {
		chapter_comment_email?: GraphQLTypes["smallint"],
	chapter_comment_push?: GraphQLTypes["smallint"],
	chapter_like_email?: GraphQLTypes["smallint"],
	chapter_like_push?: GraphQLTypes["smallint"],
	chapter_react_email?: GraphQLTypes["smallint"],
	chapter_react_push?: GraphQLTypes["smallint"],
	chapter_release_email?: GraphQLTypes["smallint"],
	chapter_release_push?: GraphQLTypes["smallint"],
	comment_react_email?: GraphQLTypes["smallint"],
	comment_react_push?: GraphQLTypes["smallint"],
	comment_reply_email?: GraphQLTypes["smallint"],
	comment_reply_push?: GraphQLTypes["smallint"],
	event_email?: GraphQLTypes["smallint"],
	event_push?: GraphQLTypes["smallint"],
	follow_email?: GraphQLTypes["smallint"],
	follow_push?: GraphQLTypes["smallint"],
	id?: number,
	promotion_email?: GraphQLTypes["smallint"],
	promotion_push?: GraphQLTypes["smallint"],
	recommended_series_email?: GraphQLTypes["smallint"],
	recommended_series_push?: GraphQLTypes["smallint"],
	reward_email?: GraphQLTypes["smallint"],
	reward_push?: GraphQLTypes["smallint"],
	subscription_email?: GraphQLTypes["smallint"],
	subscription_push?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_notification_settings_sum_fields"]: {
	__typename: "voyce_notification_settings_sum_fields",
	chapter_comment_email?: GraphQLTypes["smallint"],
	chapter_comment_push?: GraphQLTypes["smallint"],
	chapter_like_email?: GraphQLTypes["smallint"],
	chapter_like_push?: GraphQLTypes["smallint"],
	chapter_react_email?: GraphQLTypes["smallint"],
	chapter_react_push?: GraphQLTypes["smallint"],
	chapter_release_email?: GraphQLTypes["smallint"],
	chapter_release_push?: GraphQLTypes["smallint"],
	comment_react_email?: GraphQLTypes["smallint"],
	comment_react_push?: GraphQLTypes["smallint"],
	comment_reply_email?: GraphQLTypes["smallint"],
	comment_reply_push?: GraphQLTypes["smallint"],
	event_email?: GraphQLTypes["smallint"],
	event_push?: GraphQLTypes["smallint"],
	follow_email?: GraphQLTypes["smallint"],
	follow_push?: GraphQLTypes["smallint"],
	id?: number,
	promotion_email?: GraphQLTypes["smallint"],
	promotion_push?: GraphQLTypes["smallint"],
	recommended_series_email?: GraphQLTypes["smallint"],
	recommended_series_push?: GraphQLTypes["smallint"],
	reward_email?: GraphQLTypes["smallint"],
	reward_push?: GraphQLTypes["smallint"],
	subscription_email?: GraphQLTypes["smallint"],
	subscription_push?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** update columns of table "voyce.notification_settings" */
["voyce_notification_settings_update_column"]: voyce_notification_settings_update_column;
	["voyce_notification_settings_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_notification_settings_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_notification_settings_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_notification_settings_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_notification_settings_var_pop_fields"]: {
	__typename: "voyce_notification_settings_var_pop_fields",
	chapter_comment_email?: number,
	chapter_comment_push?: number,
	chapter_like_email?: number,
	chapter_like_push?: number,
	chapter_react_email?: number,
	chapter_react_push?: number,
	chapter_release_email?: number,
	chapter_release_push?: number,
	comment_react_email?: number,
	comment_react_push?: number,
	comment_reply_email?: number,
	comment_reply_push?: number,
	event_email?: number,
	event_push?: number,
	follow_email?: number,
	follow_push?: number,
	id?: number,
	promotion_email?: number,
	promotion_push?: number,
	recommended_series_email?: number,
	recommended_series_push?: number,
	reward_email?: number,
	reward_push?: number,
	subscription_email?: number,
	subscription_push?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_notification_settings_var_samp_fields"]: {
	__typename: "voyce_notification_settings_var_samp_fields",
	chapter_comment_email?: number,
	chapter_comment_push?: number,
	chapter_like_email?: number,
	chapter_like_push?: number,
	chapter_react_email?: number,
	chapter_react_push?: number,
	chapter_release_email?: number,
	chapter_release_push?: number,
	comment_react_email?: number,
	comment_react_push?: number,
	comment_reply_email?: number,
	comment_reply_push?: number,
	event_email?: number,
	event_push?: number,
	follow_email?: number,
	follow_push?: number,
	id?: number,
	promotion_email?: number,
	promotion_push?: number,
	recommended_series_email?: number,
	recommended_series_push?: number,
	reward_email?: number,
	reward_push?: number,
	subscription_email?: number,
	subscription_push?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_notification_settings_variance_fields"]: {
	__typename: "voyce_notification_settings_variance_fields",
	chapter_comment_email?: number,
	chapter_comment_push?: number,
	chapter_like_email?: number,
	chapter_like_push?: number,
	chapter_react_email?: number,
	chapter_react_push?: number,
	chapter_release_email?: number,
	chapter_release_push?: number,
	comment_react_email?: number,
	comment_react_push?: number,
	comment_reply_email?: number,
	comment_reply_push?: number,
	event_email?: number,
	event_push?: number,
	follow_email?: number,
	follow_push?: number,
	id?: number,
	promotion_email?: number,
	promotion_push?: number,
	recommended_series_email?: number,
	recommended_series_push?: number,
	reward_email?: number,
	reward_push?: number,
	subscription_email?: number,
	subscription_push?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.notifications" */
["voyce_notifications"]: {
	__typename: "voyce_notifications",
	child_count: number,
	created_at: GraphQLTypes["timestamptz"],
	data?: GraphQLTypes["jsonb"],
	email_status?: string,
	emailed_at?: GraphQLTypes["timestamptz"],
	id: number,
	linked_id?: number,
	parent_id?: number,
	pushed_at?: GraphQLTypes["timestamptz"],
	read_at?: GraphQLTypes["timestamptz"],
	type: string,
	unsubscribe_id: GraphQLTypes["uuid"],
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.notifications" */
["voyce_notifications_aggregate"]: {
	__typename: "voyce_notifications_aggregate",
	aggregate?: GraphQLTypes["voyce_notifications_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_notifications"]>
};
	["voyce_notifications_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_notifications_aggregate_bool_exp_count"]
};
	["voyce_notifications_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_notifications_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_notifications_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.notifications" */
["voyce_notifications_aggregate_fields"]: {
	__typename: "voyce_notifications_aggregate_fields",
	avg?: GraphQLTypes["voyce_notifications_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_notifications_max_fields"],
	min?: GraphQLTypes["voyce_notifications_min_fields"],
	stddev?: GraphQLTypes["voyce_notifications_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_notifications_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_notifications_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_notifications_sum_fields"],
	var_pop?: GraphQLTypes["voyce_notifications_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_notifications_var_samp_fields"],
	variance?: GraphQLTypes["voyce_notifications_variance_fields"]
};
	/** order by aggregate values of table "voyce.notifications" */
["voyce_notifications_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_notifications_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_notifications_max_order_by"],
	min?: GraphQLTypes["voyce_notifications_min_order_by"],
	stddev?: GraphQLTypes["voyce_notifications_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_notifications_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_notifications_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_notifications_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_notifications_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_notifications_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_notifications_variance_order_by"]
};
	/** append existing jsonb value of filtered columns with new jsonb value */
["voyce_notifications_append_input"]: {
		data?: GraphQLTypes["jsonb"]
};
	/** input type for inserting array relation for remote table "voyce.notifications" */
["voyce_notifications_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_notifications_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_notifications_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_notifications_avg_fields"]: {
	__typename: "voyce_notifications_avg_fields",
	child_count?: number,
	id?: number,
	linked_id?: number,
	parent_id?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.notifications" */
["voyce_notifications_avg_order_by"]: {
		child_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.notifications". All fields are combined with a logical 'AND'. */
["voyce_notifications_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_notifications_bool_exp"]>,
	_not?: GraphQLTypes["voyce_notifications_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_notifications_bool_exp"]>,
	child_count?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	data?: GraphQLTypes["jsonb_comparison_exp"],
	email_status?: GraphQLTypes["String_comparison_exp"],
	emailed_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	linked_id?: GraphQLTypes["Int_comparison_exp"],
	parent_id?: GraphQLTypes["Int_comparison_exp"],
	pushed_at?: GraphQLTypes["timestamptz_comparison_exp"],
	read_at?: GraphQLTypes["timestamptz_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"],
	unsubscribe_id?: GraphQLTypes["uuid_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.notifications" */
["voyce_notifications_constraint"]: voyce_notifications_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["voyce_notifications_delete_at_path_input"]: {
		data?: Array<string>
};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["voyce_notifications_delete_elem_input"]: {
		data?: number
};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["voyce_notifications_delete_key_input"]: {
		data?: string
};
	/** input type for incrementing numeric columns in table "voyce.notifications" */
["voyce_notifications_inc_input"]: {
		child_count?: number,
	id?: number,
	linked_id?: number,
	parent_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.notifications" */
["voyce_notifications_insert_input"]: {
		child_count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	data?: GraphQLTypes["jsonb"],
	email_status?: string,
	emailed_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_id?: number,
	parent_id?: number,
	pushed_at?: GraphQLTypes["timestamptz"],
	read_at?: GraphQLTypes["timestamptz"],
	type?: string,
	unsubscribe_id?: GraphQLTypes["uuid"],
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_notifications_max_fields"]: {
	__typename: "voyce_notifications_max_fields",
	child_count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	email_status?: string,
	emailed_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_id?: number,
	parent_id?: number,
	pushed_at?: GraphQLTypes["timestamptz"],
	read_at?: GraphQLTypes["timestamptz"],
	type?: string,
	unsubscribe_id?: GraphQLTypes["uuid"],
	user_id?: number
};
	/** order by max() on columns of table "voyce.notifications" */
["voyce_notifications_max_order_by"]: {
		child_count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	email_status?: GraphQLTypes["order_by"],
	emailed_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	pushed_at?: GraphQLTypes["order_by"],
	read_at?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	unsubscribe_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_notifications_min_fields"]: {
	__typename: "voyce_notifications_min_fields",
	child_count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	email_status?: string,
	emailed_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_id?: number,
	parent_id?: number,
	pushed_at?: GraphQLTypes["timestamptz"],
	read_at?: GraphQLTypes["timestamptz"],
	type?: string,
	unsubscribe_id?: GraphQLTypes["uuid"],
	user_id?: number
};
	/** order by min() on columns of table "voyce.notifications" */
["voyce_notifications_min_order_by"]: {
		child_count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	email_status?: GraphQLTypes["order_by"],
	emailed_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	pushed_at?: GraphQLTypes["order_by"],
	read_at?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	unsubscribe_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.notifications" */
["voyce_notifications_mutation_response"]: {
	__typename: "voyce_notifications_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_notifications"]>
};
	/** on_conflict condition type for table "voyce.notifications" */
["voyce_notifications_on_conflict"]: {
		constraint: GraphQLTypes["voyce_notifications_constraint"],
	update_columns: Array<GraphQLTypes["voyce_notifications_update_column"]>,
	where?: GraphQLTypes["voyce_notifications_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.notifications". */
["voyce_notifications_order_by"]: {
		child_count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	data?: GraphQLTypes["order_by"],
	email_status?: GraphQLTypes["order_by"],
	emailed_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	pushed_at?: GraphQLTypes["order_by"],
	read_at?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	unsubscribe_id?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.notifications */
["voyce_notifications_pk_columns_input"]: {
		id: number
};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["voyce_notifications_prepend_input"]: {
		data?: GraphQLTypes["jsonb"]
};
	/** select columns of table "voyce.notifications" */
["voyce_notifications_select_column"]: voyce_notifications_select_column;
	/** input type for updating data in table "voyce.notifications" */
["voyce_notifications_set_input"]: {
		child_count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	data?: GraphQLTypes["jsonb"],
	email_status?: string,
	emailed_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_id?: number,
	parent_id?: number,
	pushed_at?: GraphQLTypes["timestamptz"],
	read_at?: GraphQLTypes["timestamptz"],
	type?: string,
	unsubscribe_id?: GraphQLTypes["uuid"],
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_notifications_stddev_fields"]: {
	__typename: "voyce_notifications_stddev_fields",
	child_count?: number,
	id?: number,
	linked_id?: number,
	parent_id?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.notifications" */
["voyce_notifications_stddev_order_by"]: {
		child_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_notifications_stddev_pop_fields"]: {
	__typename: "voyce_notifications_stddev_pop_fields",
	child_count?: number,
	id?: number,
	linked_id?: number,
	parent_id?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.notifications" */
["voyce_notifications_stddev_pop_order_by"]: {
		child_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_notifications_stddev_samp_fields"]: {
	__typename: "voyce_notifications_stddev_samp_fields",
	child_count?: number,
	id?: number,
	linked_id?: number,
	parent_id?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.notifications" */
["voyce_notifications_stddev_samp_order_by"]: {
		child_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_notifications" */
["voyce_notifications_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_notifications_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_notifications_stream_cursor_value_input"]: {
		child_count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	data?: GraphQLTypes["jsonb"],
	email_status?: string,
	emailed_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_id?: number,
	parent_id?: number,
	pushed_at?: GraphQLTypes["timestamptz"],
	read_at?: GraphQLTypes["timestamptz"],
	type?: string,
	unsubscribe_id?: GraphQLTypes["uuid"],
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_notifications_sum_fields"]: {
	__typename: "voyce_notifications_sum_fields",
	child_count?: number,
	id?: number,
	linked_id?: number,
	parent_id?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.notifications" */
["voyce_notifications_sum_order_by"]: {
		child_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.notifications" */
["voyce_notifications_update_column"]: voyce_notifications_update_column;
	["voyce_notifications_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: GraphQLTypes["voyce_notifications_append_input"],
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: GraphQLTypes["voyce_notifications_delete_at_path_input"],
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: GraphQLTypes["voyce_notifications_delete_elem_input"],
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: GraphQLTypes["voyce_notifications_delete_key_input"],
	/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_notifications_inc_input"],
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: GraphQLTypes["voyce_notifications_prepend_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_notifications_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_notifications_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_notifications_var_pop_fields"]: {
	__typename: "voyce_notifications_var_pop_fields",
	child_count?: number,
	id?: number,
	linked_id?: number,
	parent_id?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.notifications" */
["voyce_notifications_var_pop_order_by"]: {
		child_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_notifications_var_samp_fields"]: {
	__typename: "voyce_notifications_var_samp_fields",
	child_count?: number,
	id?: number,
	linked_id?: number,
	parent_id?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.notifications" */
["voyce_notifications_var_samp_order_by"]: {
		child_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_notifications_variance_fields"]: {
	__typename: "voyce_notifications_variance_fields",
	child_count?: number,
	id?: number,
	linked_id?: number,
	parent_id?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.notifications" */
["voyce_notifications_variance_order_by"]: {
		child_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.originals_feed" */
["voyce_originals_feed"]: {
	__typename: "voyce_originals_feed",
	category?: string,
	created_at?: GraphQLTypes["timestamptz"],
	desc?: string,
	id?: string,
	image?: string,
	title?: string,
	url?: string
};
	/** aggregated selection of "voyce.originals_feed" */
["voyce_originals_feed_aggregate"]: {
	__typename: "voyce_originals_feed_aggregate",
	aggregate?: GraphQLTypes["voyce_originals_feed_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_originals_feed"]>
};
	/** aggregate fields of "voyce.originals_feed" */
["voyce_originals_feed_aggregate_fields"]: {
	__typename: "voyce_originals_feed_aggregate_fields",
	count: number,
	max?: GraphQLTypes["voyce_originals_feed_max_fields"],
	min?: GraphQLTypes["voyce_originals_feed_min_fields"]
};
	/** Boolean expression to filter rows from the table "voyce.originals_feed". All fields are combined with a logical 'AND'. */
["voyce_originals_feed_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_originals_feed_bool_exp"]>,
	_not?: GraphQLTypes["voyce_originals_feed_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_originals_feed_bool_exp"]>,
	category?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	desc?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["String_comparison_exp"],
	image?: GraphQLTypes["String_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	url?: GraphQLTypes["String_comparison_exp"]
};
	/** aggregate max on columns */
["voyce_originals_feed_max_fields"]: {
	__typename: "voyce_originals_feed_max_fields",
	category?: string,
	created_at?: GraphQLTypes["timestamptz"],
	desc?: string,
	id?: string,
	image?: string,
	title?: string,
	url?: string
};
	/** aggregate min on columns */
["voyce_originals_feed_min_fields"]: {
	__typename: "voyce_originals_feed_min_fields",
	category?: string,
	created_at?: GraphQLTypes["timestamptz"],
	desc?: string,
	id?: string,
	image?: string,
	title?: string,
	url?: string
};
	/** Ordering options when selecting data from "voyce.originals_feed". */
["voyce_originals_feed_order_by"]: {
		category?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	desc?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	url?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.originals_feed" */
["voyce_originals_feed_select_column"]: voyce_originals_feed_select_column;
	/** Streaming cursor of the table "voyce_originals_feed" */
["voyce_originals_feed_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_originals_feed_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_originals_feed_stream_cursor_value_input"]: {
		category?: string,
	created_at?: GraphQLTypes["timestamptz"],
	desc?: string,
	id?: string,
	image?: string,
	title?: string,
	url?: string
};
	/** columns and relationships of "voyce.page_banners" */
["voyce_page_banners"]: {
	__typename: "voyce_page_banners",
	active: boolean,
	background?: string,
	created_at: GraphQLTypes["timestamptz"],
	desktop: string,
	id: number,
	link?: string,
	mobile?: string,
	overlap: boolean,
	page: string,
	sort: GraphQLTypes["smallint"],
	tablet?: string,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.page_banners" */
["voyce_page_banners_aggregate"]: {
	__typename: "voyce_page_banners_aggregate",
	aggregate?: GraphQLTypes["voyce_page_banners_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_page_banners"]>
};
	/** aggregate fields of "voyce.page_banners" */
["voyce_page_banners_aggregate_fields"]: {
	__typename: "voyce_page_banners_aggregate_fields",
	avg?: GraphQLTypes["voyce_page_banners_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_page_banners_max_fields"],
	min?: GraphQLTypes["voyce_page_banners_min_fields"],
	stddev?: GraphQLTypes["voyce_page_banners_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_page_banners_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_page_banners_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_page_banners_sum_fields"],
	var_pop?: GraphQLTypes["voyce_page_banners_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_page_banners_var_samp_fields"],
	variance?: GraphQLTypes["voyce_page_banners_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_page_banners_avg_fields"]: {
	__typename: "voyce_page_banners_avg_fields",
	id?: number,
	sort?: number
};
	/** Boolean expression to filter rows from the table "voyce.page_banners". All fields are combined with a logical 'AND'. */
["voyce_page_banners_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_page_banners_bool_exp"]>,
	_not?: GraphQLTypes["voyce_page_banners_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_page_banners_bool_exp"]>,
	active?: GraphQLTypes["Boolean_comparison_exp"],
	background?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	desktop?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	link?: GraphQLTypes["String_comparison_exp"],
	mobile?: GraphQLTypes["String_comparison_exp"],
	overlap?: GraphQLTypes["Boolean_comparison_exp"],
	page?: GraphQLTypes["String_comparison_exp"],
	sort?: GraphQLTypes["smallint_comparison_exp"],
	tablet?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.page_banners" */
["voyce_page_banners_constraint"]: voyce_page_banners_constraint;
	/** input type for incrementing numeric columns in table "voyce.page_banners" */
["voyce_page_banners_inc_input"]: {
		id?: number,
	sort?: GraphQLTypes["smallint"]
};
	/** input type for inserting data into table "voyce.page_banners" */
["voyce_page_banners_insert_input"]: {
		active?: boolean,
	background?: string,
	created_at?: GraphQLTypes["timestamptz"],
	desktop?: string,
	id?: number,
	link?: string,
	mobile?: string,
	overlap?: boolean,
	page?: string,
	sort?: GraphQLTypes["smallint"],
	tablet?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_page_banners_max_fields"]: {
	__typename: "voyce_page_banners_max_fields",
	background?: string,
	created_at?: GraphQLTypes["timestamptz"],
	desktop?: string,
	id?: number,
	link?: string,
	mobile?: string,
	page?: string,
	sort?: GraphQLTypes["smallint"],
	tablet?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_page_banners_min_fields"]: {
	__typename: "voyce_page_banners_min_fields",
	background?: string,
	created_at?: GraphQLTypes["timestamptz"],
	desktop?: string,
	id?: number,
	link?: string,
	mobile?: string,
	page?: string,
	sort?: GraphQLTypes["smallint"],
	tablet?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.page_banners" */
["voyce_page_banners_mutation_response"]: {
	__typename: "voyce_page_banners_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_page_banners"]>
};
	/** on_conflict condition type for table "voyce.page_banners" */
["voyce_page_banners_on_conflict"]: {
		constraint: GraphQLTypes["voyce_page_banners_constraint"],
	update_columns: Array<GraphQLTypes["voyce_page_banners_update_column"]>,
	where?: GraphQLTypes["voyce_page_banners_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.page_banners". */
["voyce_page_banners_order_by"]: {
		active?: GraphQLTypes["order_by"],
	background?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	desktop?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	link?: GraphQLTypes["order_by"],
	mobile?: GraphQLTypes["order_by"],
	overlap?: GraphQLTypes["order_by"],
	page?: GraphQLTypes["order_by"],
	sort?: GraphQLTypes["order_by"],
	tablet?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.page_banners */
["voyce_page_banners_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.page_banners" */
["voyce_page_banners_select_column"]: voyce_page_banners_select_column;
	/** input type for updating data in table "voyce.page_banners" */
["voyce_page_banners_set_input"]: {
		active?: boolean,
	background?: string,
	created_at?: GraphQLTypes["timestamptz"],
	desktop?: string,
	id?: number,
	link?: string,
	mobile?: string,
	overlap?: boolean,
	page?: string,
	sort?: GraphQLTypes["smallint"],
	tablet?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_page_banners_stddev_fields"]: {
	__typename: "voyce_page_banners_stddev_fields",
	id?: number,
	sort?: number
};
	/** aggregate stddev_pop on columns */
["voyce_page_banners_stddev_pop_fields"]: {
	__typename: "voyce_page_banners_stddev_pop_fields",
	id?: number,
	sort?: number
};
	/** aggregate stddev_samp on columns */
["voyce_page_banners_stddev_samp_fields"]: {
	__typename: "voyce_page_banners_stddev_samp_fields",
	id?: number,
	sort?: number
};
	/** Streaming cursor of the table "voyce_page_banners" */
["voyce_page_banners_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_page_banners_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_page_banners_stream_cursor_value_input"]: {
		active?: boolean,
	background?: string,
	created_at?: GraphQLTypes["timestamptz"],
	desktop?: string,
	id?: number,
	link?: string,
	mobile?: string,
	overlap?: boolean,
	page?: string,
	sort?: GraphQLTypes["smallint"],
	tablet?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_page_banners_sum_fields"]: {
	__typename: "voyce_page_banners_sum_fields",
	id?: number,
	sort?: GraphQLTypes["smallint"]
};
	/** update columns of table "voyce.page_banners" */
["voyce_page_banners_update_column"]: voyce_page_banners_update_column;
	["voyce_page_banners_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_page_banners_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_page_banners_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_page_banners_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_page_banners_var_pop_fields"]: {
	__typename: "voyce_page_banners_var_pop_fields",
	id?: number,
	sort?: number
};
	/** aggregate var_samp on columns */
["voyce_page_banners_var_samp_fields"]: {
	__typename: "voyce_page_banners_var_samp_fields",
	id?: number,
	sort?: number
};
	/** aggregate variance on columns */
["voyce_page_banners_variance_fields"]: {
	__typename: "voyce_page_banners_variance_fields",
	id?: number,
	sort?: number
};
	/** Setup Page Sections / Components */
["voyce_page_components"]: {
	__typename: "voyce_page_components",
	active: boolean,
	auth?: boolean,
	component: string,
	created_at: GraphQLTypes["timestamptz"],
	device: string,
	id: number,
	page: string,
	props?: GraphQLTypes["json"],
	sort: GraphQLTypes["smallint"],
	title?: string,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.page_components" */
["voyce_page_components_aggregate"]: {
	__typename: "voyce_page_components_aggregate",
	aggregate?: GraphQLTypes["voyce_page_components_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_page_components"]>
};
	/** aggregate fields of "voyce.page_components" */
["voyce_page_components_aggregate_fields"]: {
	__typename: "voyce_page_components_aggregate_fields",
	avg?: GraphQLTypes["voyce_page_components_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_page_components_max_fields"],
	min?: GraphQLTypes["voyce_page_components_min_fields"],
	stddev?: GraphQLTypes["voyce_page_components_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_page_components_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_page_components_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_page_components_sum_fields"],
	var_pop?: GraphQLTypes["voyce_page_components_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_page_components_var_samp_fields"],
	variance?: GraphQLTypes["voyce_page_components_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_page_components_avg_fields"]: {
	__typename: "voyce_page_components_avg_fields",
	id?: number,
	sort?: number
};
	/** Boolean expression to filter rows from the table "voyce.page_components". All fields are combined with a logical 'AND'. */
["voyce_page_components_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_page_components_bool_exp"]>,
	_not?: GraphQLTypes["voyce_page_components_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_page_components_bool_exp"]>,
	active?: GraphQLTypes["Boolean_comparison_exp"],
	auth?: GraphQLTypes["Boolean_comparison_exp"],
	component?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	device?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	page?: GraphQLTypes["String_comparison_exp"],
	props?: GraphQLTypes["json_comparison_exp"],
	sort?: GraphQLTypes["smallint_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.page_components" */
["voyce_page_components_constraint"]: voyce_page_components_constraint;
	/** input type for incrementing numeric columns in table "voyce.page_components" */
["voyce_page_components_inc_input"]: {
		id?: number,
	sort?: GraphQLTypes["smallint"]
};
	/** input type for inserting data into table "voyce.page_components" */
["voyce_page_components_insert_input"]: {
		active?: boolean,
	auth?: boolean,
	component?: string,
	created_at?: GraphQLTypes["timestamptz"],
	device?: string,
	id?: number,
	page?: string,
	props?: GraphQLTypes["json"],
	sort?: GraphQLTypes["smallint"],
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_page_components_max_fields"]: {
	__typename: "voyce_page_components_max_fields",
	component?: string,
	created_at?: GraphQLTypes["timestamptz"],
	device?: string,
	id?: number,
	page?: string,
	sort?: GraphQLTypes["smallint"],
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_page_components_min_fields"]: {
	__typename: "voyce_page_components_min_fields",
	component?: string,
	created_at?: GraphQLTypes["timestamptz"],
	device?: string,
	id?: number,
	page?: string,
	sort?: GraphQLTypes["smallint"],
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.page_components" */
["voyce_page_components_mutation_response"]: {
	__typename: "voyce_page_components_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_page_components"]>
};
	/** on_conflict condition type for table "voyce.page_components" */
["voyce_page_components_on_conflict"]: {
		constraint: GraphQLTypes["voyce_page_components_constraint"],
	update_columns: Array<GraphQLTypes["voyce_page_components_update_column"]>,
	where?: GraphQLTypes["voyce_page_components_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.page_components". */
["voyce_page_components_order_by"]: {
		active?: GraphQLTypes["order_by"],
	auth?: GraphQLTypes["order_by"],
	component?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	device?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	page?: GraphQLTypes["order_by"],
	props?: GraphQLTypes["order_by"],
	sort?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.page_components */
["voyce_page_components_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.page_components" */
["voyce_page_components_select_column"]: voyce_page_components_select_column;
	/** input type for updating data in table "voyce.page_components" */
["voyce_page_components_set_input"]: {
		active?: boolean,
	auth?: boolean,
	component?: string,
	created_at?: GraphQLTypes["timestamptz"],
	device?: string,
	id?: number,
	page?: string,
	props?: GraphQLTypes["json"],
	sort?: GraphQLTypes["smallint"],
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_page_components_stddev_fields"]: {
	__typename: "voyce_page_components_stddev_fields",
	id?: number,
	sort?: number
};
	/** aggregate stddev_pop on columns */
["voyce_page_components_stddev_pop_fields"]: {
	__typename: "voyce_page_components_stddev_pop_fields",
	id?: number,
	sort?: number
};
	/** aggregate stddev_samp on columns */
["voyce_page_components_stddev_samp_fields"]: {
	__typename: "voyce_page_components_stddev_samp_fields",
	id?: number,
	sort?: number
};
	/** Streaming cursor of the table "voyce_page_components" */
["voyce_page_components_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_page_components_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_page_components_stream_cursor_value_input"]: {
		active?: boolean,
	auth?: boolean,
	component?: string,
	created_at?: GraphQLTypes["timestamptz"],
	device?: string,
	id?: number,
	page?: string,
	props?: GraphQLTypes["json"],
	sort?: GraphQLTypes["smallint"],
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_page_components_sum_fields"]: {
	__typename: "voyce_page_components_sum_fields",
	id?: number,
	sort?: GraphQLTypes["smallint"]
};
	/** update columns of table "voyce.page_components" */
["voyce_page_components_update_column"]: voyce_page_components_update_column;
	["voyce_page_components_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_page_components_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_page_components_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_page_components_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_page_components_var_pop_fields"]: {
	__typename: "voyce_page_components_var_pop_fields",
	id?: number,
	sort?: number
};
	/** aggregate var_samp on columns */
["voyce_page_components_var_samp_fields"]: {
	__typename: "voyce_page_components_var_samp_fields",
	id?: number,
	sort?: number
};
	/** aggregate variance on columns */
["voyce_page_components_variance_fields"]: {
	__typename: "voyce_page_components_variance_fields",
	id?: number,
	sort?: number
};
	/** columns and relationships of "voyce.pages" */
["voyce_pages"]: {
	__typename: "voyce_pages",
	content?: string,
	created_at: GraphQLTypes["timestamp"],
	id: number,
	slug: string,
	title: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregated selection of "voyce.pages" */
["voyce_pages_aggregate"]: {
	__typename: "voyce_pages_aggregate",
	aggregate?: GraphQLTypes["voyce_pages_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_pages"]>
};
	/** aggregate fields of "voyce.pages" */
["voyce_pages_aggregate_fields"]: {
	__typename: "voyce_pages_aggregate_fields",
	avg?: GraphQLTypes["voyce_pages_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_pages_max_fields"],
	min?: GraphQLTypes["voyce_pages_min_fields"],
	stddev?: GraphQLTypes["voyce_pages_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_pages_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_pages_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_pages_sum_fields"],
	var_pop?: GraphQLTypes["voyce_pages_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_pages_var_samp_fields"],
	variance?: GraphQLTypes["voyce_pages_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_pages_avg_fields"]: {
	__typename: "voyce_pages_avg_fields",
	id?: number
};
	/** Boolean expression to filter rows from the table "voyce.pages". All fields are combined with a logical 'AND'. */
["voyce_pages_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_pages_bool_exp"]>,
	_not?: GraphQLTypes["voyce_pages_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_pages_bool_exp"]>,
	content?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	slug?: GraphQLTypes["String_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamp_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.pages" */
["voyce_pages_constraint"]: voyce_pages_constraint;
	/** input type for incrementing numeric columns in table "voyce.pages" */
["voyce_pages_inc_input"]: {
		id?: number
};
	/** input type for inserting data into table "voyce.pages" */
["voyce_pages_insert_input"]: {
		content?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	slug?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate max on columns */
["voyce_pages_max_fields"]: {
	__typename: "voyce_pages_max_fields",
	content?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	slug?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate min on columns */
["voyce_pages_min_fields"]: {
	__typename: "voyce_pages_min_fields",
	content?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	slug?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** response of any mutation on the table "voyce.pages" */
["voyce_pages_mutation_response"]: {
	__typename: "voyce_pages_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_pages"]>
};
	/** on_conflict condition type for table "voyce.pages" */
["voyce_pages_on_conflict"]: {
		constraint: GraphQLTypes["voyce_pages_constraint"],
	update_columns: Array<GraphQLTypes["voyce_pages_update_column"]>,
	where?: GraphQLTypes["voyce_pages_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.pages". */
["voyce_pages_order_by"]: {
		content?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	slug?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.pages */
["voyce_pages_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.pages" */
["voyce_pages_select_column"]: voyce_pages_select_column;
	/** input type for updating data in table "voyce.pages" */
["voyce_pages_set_input"]: {
		content?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	slug?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate stddev on columns */
["voyce_pages_stddev_fields"]: {
	__typename: "voyce_pages_stddev_fields",
	id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_pages_stddev_pop_fields"]: {
	__typename: "voyce_pages_stddev_pop_fields",
	id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_pages_stddev_samp_fields"]: {
	__typename: "voyce_pages_stddev_samp_fields",
	id?: number
};
	/** Streaming cursor of the table "voyce_pages" */
["voyce_pages_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_pages_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_pages_stream_cursor_value_input"]: {
		content?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	slug?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate sum on columns */
["voyce_pages_sum_fields"]: {
	__typename: "voyce_pages_sum_fields",
	id?: number
};
	/** update columns of table "voyce.pages" */
["voyce_pages_update_column"]: voyce_pages_update_column;
	["voyce_pages_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_pages_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_pages_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_pages_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_pages_var_pop_fields"]: {
	__typename: "voyce_pages_var_pop_fields",
	id?: number
};
	/** aggregate var_samp on columns */
["voyce_pages_var_samp_fields"]: {
	__typename: "voyce_pages_var_samp_fields",
	id?: number
};
	/** aggregate variance on columns */
["voyce_pages_variance_fields"]: {
	__typename: "voyce_pages_variance_fields",
	id?: number
};
	/** columns and relationships of "voyce.panels_comments" */
["voyce_panels_comments"]: {
	__typename: "voyce_panels_comments",
	animated_image?: string,
	/** An object relationship */
	chapter_panel: GraphQLTypes["voyce_chapter_panels"],
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	image?: string,
	linked_id: number,
	/** An object relationship */
	panels_comment?: GraphQLTypes["voyce_panels_comments"],
	/** An array relationship */
	panels_comments: Array<GraphQLTypes["voyce_panels_comments"]>,
	/** An aggregate relationship */
	panels_comments_aggregate: GraphQLTypes["voyce_panels_comments_aggregate"],
	/** An array relationship */
	panels_reactions: Array<GraphQLTypes["voyce_panels_reactions"]>,
	/** An aggregate relationship */
	panels_reactions_aggregate: GraphQLTypes["voyce_panels_reactions_aggregate"],
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	text: string,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.panels_comments" */
["voyce_panels_comments_aggregate"]: {
	__typename: "voyce_panels_comments_aggregate",
	aggregate?: GraphQLTypes["voyce_panels_comments_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_panels_comments"]>
};
	["voyce_panels_comments_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_panels_comments_aggregate_bool_exp_count"]
};
	["voyce_panels_comments_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_panels_comments_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_panels_comments_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.panels_comments" */
["voyce_panels_comments_aggregate_fields"]: {
	__typename: "voyce_panels_comments_aggregate_fields",
	avg?: GraphQLTypes["voyce_panels_comments_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_panels_comments_max_fields"],
	min?: GraphQLTypes["voyce_panels_comments_min_fields"],
	stddev?: GraphQLTypes["voyce_panels_comments_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_panels_comments_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_panels_comments_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_panels_comments_sum_fields"],
	var_pop?: GraphQLTypes["voyce_panels_comments_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_panels_comments_var_samp_fields"],
	variance?: GraphQLTypes["voyce_panels_comments_variance_fields"]
};
	/** order by aggregate values of table "voyce.panels_comments" */
["voyce_panels_comments_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_panels_comments_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_panels_comments_max_order_by"],
	min?: GraphQLTypes["voyce_panels_comments_min_order_by"],
	stddev?: GraphQLTypes["voyce_panels_comments_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_panels_comments_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_panels_comments_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_panels_comments_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_panels_comments_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_panels_comments_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_panels_comments_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.panels_comments" */
["voyce_panels_comments_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_panels_comments_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_panels_comments_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_panels_comments_avg_fields"]: {
	__typename: "voyce_panels_comments_avg_fields",
	id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.panels_comments". All fields are combined with a logical 'AND'. */
["voyce_panels_comments_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_panels_comments_bool_exp"]>,
	_not?: GraphQLTypes["voyce_panels_comments_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_panels_comments_bool_exp"]>,
	animated_image?: GraphQLTypes["String_comparison_exp"],
	chapter_panel?: GraphQLTypes["voyce_chapter_panels_bool_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	image?: GraphQLTypes["String_comparison_exp"],
	linked_id?: GraphQLTypes["Int_comparison_exp"],
	panels_comment?: GraphQLTypes["voyce_panels_comments_bool_exp"],
	panels_comments?: GraphQLTypes["voyce_panels_comments_bool_exp"],
	panels_comments_aggregate?: GraphQLTypes["voyce_panels_comments_aggregate_bool_exp"],
	panels_reactions?: GraphQLTypes["voyce_panels_reactions_bool_exp"],
	panels_reactions_aggregate?: GraphQLTypes["voyce_panels_reactions_aggregate_bool_exp"],
	parent_id?: GraphQLTypes["Int_comparison_exp"],
	reaction_count?: GraphQLTypes["Int_comparison_exp"],
	replies_count?: GraphQLTypes["Int_comparison_exp"],
	text?: GraphQLTypes["String_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.panels_comments" */
["voyce_panels_comments_constraint"]: voyce_panels_comments_constraint;
	/** input type for incrementing numeric columns in table "voyce.panels_comments" */
["voyce_panels_comments_inc_input"]: {
		id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.panels_comments" */
["voyce_panels_comments_insert_input"]: {
		animated_image?: string,
	chapter_panel?: GraphQLTypes["voyce_chapter_panels_obj_rel_insert_input"],
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	linked_id?: number,
	panels_comment?: GraphQLTypes["voyce_panels_comments_obj_rel_insert_input"],
	panels_comments?: GraphQLTypes["voyce_panels_comments_arr_rel_insert_input"],
	panels_reactions?: GraphQLTypes["voyce_panels_reactions_arr_rel_insert_input"],
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	text?: string,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_panels_comments_max_fields"]: {
	__typename: "voyce_panels_comments_max_fields",
	animated_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	text?: string,
	user_id?: number
};
	/** order by max() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_max_order_by"]: {
		animated_image?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	text?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_panels_comments_min_fields"]: {
	__typename: "voyce_panels_comments_min_fields",
	animated_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	text?: string,
	user_id?: number
};
	/** order by min() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_min_order_by"]: {
		animated_image?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	text?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.panels_comments" */
["voyce_panels_comments_mutation_response"]: {
	__typename: "voyce_panels_comments_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_panels_comments"]>
};
	/** input type for inserting object relation for remote table "voyce.panels_comments" */
["voyce_panels_comments_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_panels_comments_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_panels_comments_on_conflict"]
};
	/** on_conflict condition type for table "voyce.panels_comments" */
["voyce_panels_comments_on_conflict"]: {
		constraint: GraphQLTypes["voyce_panels_comments_constraint"],
	update_columns: Array<GraphQLTypes["voyce_panels_comments_update_column"]>,
	where?: GraphQLTypes["voyce_panels_comments_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.panels_comments". */
["voyce_panels_comments_order_by"]: {
		animated_image?: GraphQLTypes["order_by"],
	chapter_panel?: GraphQLTypes["voyce_chapter_panels_order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	panels_comment?: GraphQLTypes["voyce_panels_comments_order_by"],
	panels_comments_aggregate?: GraphQLTypes["voyce_panels_comments_aggregate_order_by"],
	panels_reactions_aggregate?: GraphQLTypes["voyce_panels_reactions_aggregate_order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	text?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.panels_comments */
["voyce_panels_comments_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.panels_comments" */
["voyce_panels_comments_select_column"]: voyce_panels_comments_select_column;
	/** input type for updating data in table "voyce.panels_comments" */
["voyce_panels_comments_set_input"]: {
		animated_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	text?: string,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_panels_comments_stddev_fields"]: {
	__typename: "voyce_panels_comments_stddev_fields",
	id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_panels_comments_stddev_pop_fields"]: {
	__typename: "voyce_panels_comments_stddev_pop_fields",
	id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_panels_comments_stddev_samp_fields"]: {
	__typename: "voyce_panels_comments_stddev_samp_fields",
	id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_panels_comments" */
["voyce_panels_comments_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_panels_comments_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_panels_comments_stream_cursor_value_input"]: {
		animated_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	text?: string,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_panels_comments_sum_fields"]: {
	__typename: "voyce_panels_comments_sum_fields",
	id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.panels_comments" */
["voyce_panels_comments_update_column"]: voyce_panels_comments_update_column;
	["voyce_panels_comments_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_panels_comments_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_panels_comments_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_panels_comments_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_panels_comments_var_pop_fields"]: {
	__typename: "voyce_panels_comments_var_pop_fields",
	id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_panels_comments_var_samp_fields"]: {
	__typename: "voyce_panels_comments_var_samp_fields",
	id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_panels_comments_variance_fields"]: {
	__typename: "voyce_panels_comments_variance_fields",
	id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.panels_comments" */
["voyce_panels_comments_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.panels_reactions" */
["voyce_panels_reactions"]: {
	__typename: "voyce_panels_reactions",
	/** An object relationship */
	chapter_panel: GraphQLTypes["voyce_chapter_panels"],
	comment_id?: number,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	linked_entity_id: number,
	/** An object relationship */
	panels_comment?: GraphQLTypes["voyce_panels_comments"],
	reaction: number,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.panels_reactions" */
["voyce_panels_reactions_aggregate"]: {
	__typename: "voyce_panels_reactions_aggregate",
	aggregate?: GraphQLTypes["voyce_panels_reactions_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_panels_reactions"]>
};
	["voyce_panels_reactions_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_panels_reactions_aggregate_bool_exp_count"]
};
	["voyce_panels_reactions_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_panels_reactions_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_panels_reactions_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.panels_reactions" */
["voyce_panels_reactions_aggregate_fields"]: {
	__typename: "voyce_panels_reactions_aggregate_fields",
	avg?: GraphQLTypes["voyce_panels_reactions_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_panels_reactions_max_fields"],
	min?: GraphQLTypes["voyce_panels_reactions_min_fields"],
	stddev?: GraphQLTypes["voyce_panels_reactions_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_panels_reactions_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_panels_reactions_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_panels_reactions_sum_fields"],
	var_pop?: GraphQLTypes["voyce_panels_reactions_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_panels_reactions_var_samp_fields"],
	variance?: GraphQLTypes["voyce_panels_reactions_variance_fields"]
};
	/** order by aggregate values of table "voyce.panels_reactions" */
["voyce_panels_reactions_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_panels_reactions_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_panels_reactions_max_order_by"],
	min?: GraphQLTypes["voyce_panels_reactions_min_order_by"],
	stddev?: GraphQLTypes["voyce_panels_reactions_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_panels_reactions_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_panels_reactions_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_panels_reactions_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_panels_reactions_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_panels_reactions_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_panels_reactions_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.panels_reactions" */
["voyce_panels_reactions_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_panels_reactions_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_panels_reactions_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_panels_reactions_avg_fields"]: {
	__typename: "voyce_panels_reactions_avg_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_avg_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.panels_reactions". All fields are combined with a logical 'AND'. */
["voyce_panels_reactions_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_panels_reactions_bool_exp"]>,
	_not?: GraphQLTypes["voyce_panels_reactions_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_panels_reactions_bool_exp"]>,
	chapter_panel?: GraphQLTypes["voyce_chapter_panels_bool_exp"],
	comment_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	linked_entity_id?: GraphQLTypes["Int_comparison_exp"],
	panels_comment?: GraphQLTypes["voyce_panels_comments_bool_exp"],
	reaction?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.panels_reactions" */
["voyce_panels_reactions_constraint"]: voyce_panels_reactions_constraint;
	/** Tabel for reactions count */
["voyce_panels_reactions_count"]: {
	__typename: "voyce_panels_reactions_count",
	/** An object relationship */
	chapter_panel: GraphQLTypes["voyce_chapter_panels"],
	count?: number,
	id: number,
	panel_id: number,
	reaction?: number
};
	/** aggregated selection of "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_aggregate"]: {
	__typename: "voyce_panels_reactions_count_aggregate",
	aggregate?: GraphQLTypes["voyce_panels_reactions_count_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_panels_reactions_count"]>
};
	["voyce_panels_reactions_count_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_panels_reactions_count_aggregate_bool_exp_count"]
};
	["voyce_panels_reactions_count_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_panels_reactions_count_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_panels_reactions_count_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_aggregate_fields"]: {
	__typename: "voyce_panels_reactions_count_aggregate_fields",
	avg?: GraphQLTypes["voyce_panels_reactions_count_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_panels_reactions_count_max_fields"],
	min?: GraphQLTypes["voyce_panels_reactions_count_min_fields"],
	stddev?: GraphQLTypes["voyce_panels_reactions_count_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_panels_reactions_count_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_panels_reactions_count_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_panels_reactions_count_sum_fields"],
	var_pop?: GraphQLTypes["voyce_panels_reactions_count_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_panels_reactions_count_var_samp_fields"],
	variance?: GraphQLTypes["voyce_panels_reactions_count_variance_fields"]
};
	/** order by aggregate values of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_panels_reactions_count_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_panels_reactions_count_max_order_by"],
	min?: GraphQLTypes["voyce_panels_reactions_count_min_order_by"],
	stddev?: GraphQLTypes["voyce_panels_reactions_count_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_panels_reactions_count_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_panels_reactions_count_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_panels_reactions_count_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_panels_reactions_count_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_panels_reactions_count_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_panels_reactions_count_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_panels_reactions_count_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_panels_reactions_count_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_panels_reactions_count_avg_fields"]: {
	__typename: "voyce_panels_reactions_count_avg_fields",
	count?: number,
	id?: number,
	panel_id?: number,
	reaction?: number
};
	/** order by avg() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_avg_order_by"]: {
		count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	panel_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.panels_reactions_count". All fields are combined with a logical 'AND'. */
["voyce_panels_reactions_count_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_panels_reactions_count_bool_exp"]>,
	_not?: GraphQLTypes["voyce_panels_reactions_count_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_panels_reactions_count_bool_exp"]>,
	chapter_panel?: GraphQLTypes["voyce_chapter_panels_bool_exp"],
	count?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	panel_id?: GraphQLTypes["Int_comparison_exp"],
	reaction?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_constraint"]: voyce_panels_reactions_count_constraint;
	/** input type for incrementing numeric columns in table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_inc_input"]: {
		count?: number,
	id?: number,
	panel_id?: number,
	reaction?: number
};
	/** input type for inserting data into table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_insert_input"]: {
		chapter_panel?: GraphQLTypes["voyce_chapter_panels_obj_rel_insert_input"],
	count?: number,
	id?: number,
	panel_id?: number,
	reaction?: number
};
	/** aggregate max on columns */
["voyce_panels_reactions_count_max_fields"]: {
	__typename: "voyce_panels_reactions_count_max_fields",
	count?: number,
	id?: number,
	panel_id?: number,
	reaction?: number
};
	/** order by max() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_max_order_by"]: {
		count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	panel_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_panels_reactions_count_min_fields"]: {
	__typename: "voyce_panels_reactions_count_min_fields",
	count?: number,
	id?: number,
	panel_id?: number,
	reaction?: number
};
	/** order by min() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_min_order_by"]: {
		count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	panel_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_mutation_response"]: {
	__typename: "voyce_panels_reactions_count_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_panels_reactions_count"]>
};
	/** on_conflict condition type for table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_on_conflict"]: {
		constraint: GraphQLTypes["voyce_panels_reactions_count_constraint"],
	update_columns: Array<GraphQLTypes["voyce_panels_reactions_count_update_column"]>,
	where?: GraphQLTypes["voyce_panels_reactions_count_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.panels_reactions_count". */
["voyce_panels_reactions_count_order_by"]: {
		chapter_panel?: GraphQLTypes["voyce_chapter_panels_order_by"],
	count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	panel_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.panels_reactions_count */
["voyce_panels_reactions_count_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_select_column"]: voyce_panels_reactions_count_select_column;
	/** input type for updating data in table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_set_input"]: {
		count?: number,
	id?: number,
	panel_id?: number,
	reaction?: number
};
	/** aggregate stddev on columns */
["voyce_panels_reactions_count_stddev_fields"]: {
	__typename: "voyce_panels_reactions_count_stddev_fields",
	count?: number,
	id?: number,
	panel_id?: number,
	reaction?: number
};
	/** order by stddev() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_stddev_order_by"]: {
		count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	panel_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_panels_reactions_count_stddev_pop_fields"]: {
	__typename: "voyce_panels_reactions_count_stddev_pop_fields",
	count?: number,
	id?: number,
	panel_id?: number,
	reaction?: number
};
	/** order by stddev_pop() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_stddev_pop_order_by"]: {
		count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	panel_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_panels_reactions_count_stddev_samp_fields"]: {
	__typename: "voyce_panels_reactions_count_stddev_samp_fields",
	count?: number,
	id?: number,
	panel_id?: number,
	reaction?: number
};
	/** order by stddev_samp() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_stddev_samp_order_by"]: {
		count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	panel_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_panels_reactions_count" */
["voyce_panels_reactions_count_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_panels_reactions_count_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_panels_reactions_count_stream_cursor_value_input"]: {
		count?: number,
	id?: number,
	panel_id?: number,
	reaction?: number
};
	/** aggregate sum on columns */
["voyce_panels_reactions_count_sum_fields"]: {
	__typename: "voyce_panels_reactions_count_sum_fields",
	count?: number,
	id?: number,
	panel_id?: number,
	reaction?: number
};
	/** order by sum() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_sum_order_by"]: {
		count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	panel_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_update_column"]: voyce_panels_reactions_count_update_column;
	["voyce_panels_reactions_count_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_panels_reactions_count_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_panels_reactions_count_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_panels_reactions_count_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_panels_reactions_count_var_pop_fields"]: {
	__typename: "voyce_panels_reactions_count_var_pop_fields",
	count?: number,
	id?: number,
	panel_id?: number,
	reaction?: number
};
	/** order by var_pop() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_var_pop_order_by"]: {
		count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	panel_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_panels_reactions_count_var_samp_fields"]: {
	__typename: "voyce_panels_reactions_count_var_samp_fields",
	count?: number,
	id?: number,
	panel_id?: number,
	reaction?: number
};
	/** order by var_samp() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_var_samp_order_by"]: {
		count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	panel_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_panels_reactions_count_variance_fields"]: {
	__typename: "voyce_panels_reactions_count_variance_fields",
	count?: number,
	id?: number,
	panel_id?: number,
	reaction?: number
};
	/** order by variance() on columns of table "voyce.panels_reactions_count" */
["voyce_panels_reactions_count_variance_order_by"]: {
		count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	panel_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"]
};
	/** input type for incrementing numeric columns in table "voyce.panels_reactions" */
["voyce_panels_reactions_inc_input"]: {
		comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.panels_reactions" */
["voyce_panels_reactions_insert_input"]: {
		chapter_panel?: GraphQLTypes["voyce_chapter_panels_obj_rel_insert_input"],
	comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	panels_comment?: GraphQLTypes["voyce_panels_comments_obj_rel_insert_input"],
	reaction?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_panels_reactions_max_fields"]: {
	__typename: "voyce_panels_reactions_max_fields",
	comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by max() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_max_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_panels_reactions_min_fields"]: {
	__typename: "voyce_panels_reactions_min_fields",
	comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by min() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_min_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.panels_reactions" */
["voyce_panels_reactions_mutation_response"]: {
	__typename: "voyce_panels_reactions_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_panels_reactions"]>
};
	/** on_conflict condition type for table "voyce.panels_reactions" */
["voyce_panels_reactions_on_conflict"]: {
		constraint: GraphQLTypes["voyce_panels_reactions_constraint"],
	update_columns: Array<GraphQLTypes["voyce_panels_reactions_update_column"]>,
	where?: GraphQLTypes["voyce_panels_reactions_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.panels_reactions". */
["voyce_panels_reactions_order_by"]: {
		chapter_panel?: GraphQLTypes["voyce_chapter_panels_order_by"],
	comment_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	panels_comment?: GraphQLTypes["voyce_panels_comments_order_by"],
	reaction?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.panels_reactions */
["voyce_panels_reactions_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_select_column"]: voyce_panels_reactions_select_column;
	/** input type for updating data in table "voyce.panels_reactions" */
["voyce_panels_reactions_set_input"]: {
		comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_panels_reactions_stddev_fields"]: {
	__typename: "voyce_panels_reactions_stddev_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_stddev_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_panels_reactions_stddev_pop_fields"]: {
	__typename: "voyce_panels_reactions_stddev_pop_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_stddev_pop_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_panels_reactions_stddev_samp_fields"]: {
	__typename: "voyce_panels_reactions_stddev_samp_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_stddev_samp_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_panels_reactions" */
["voyce_panels_reactions_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_panels_reactions_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_panels_reactions_stream_cursor_value_input"]: {
		comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_panels_reactions_sum_fields"]: {
	__typename: "voyce_panels_reactions_sum_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_sum_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_update_column"]: voyce_panels_reactions_update_column;
	["voyce_panels_reactions_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_panels_reactions_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_panels_reactions_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_panels_reactions_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_panels_reactions_var_pop_fields"]: {
	__typename: "voyce_panels_reactions_var_pop_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_var_pop_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_panels_reactions_var_samp_fields"]: {
	__typename: "voyce_panels_reactions_var_samp_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_var_samp_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_panels_reactions_variance_fields"]: {
	__typename: "voyce_panels_reactions_variance_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.panels_reactions" */
["voyce_panels_reactions_variance_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.payments_to_creators" */
["voyce_payments_to_creators"]: {
	__typename: "voyce_payments_to_creators",
	amount: number,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	stripe_id: string,
	/** An object relationship */
	user_from: GraphQLTypes["voyce_users"],
	user_from_id: number,
	/** An object relationship */
	user_to: GraphQLTypes["voyce_users"],
	user_to_id: number
};
	/** aggregated selection of "voyce.payments_to_creators" */
["voyce_payments_to_creators_aggregate"]: {
	__typename: "voyce_payments_to_creators_aggregate",
	aggregate?: GraphQLTypes["voyce_payments_to_creators_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_payments_to_creators"]>
};
	["voyce_payments_to_creators_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_payments_to_creators_aggregate_bool_exp_count"]
};
	["voyce_payments_to_creators_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_payments_to_creators_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_payments_to_creators_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.payments_to_creators" */
["voyce_payments_to_creators_aggregate_fields"]: {
	__typename: "voyce_payments_to_creators_aggregate_fields",
	avg?: GraphQLTypes["voyce_payments_to_creators_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_payments_to_creators_max_fields"],
	min?: GraphQLTypes["voyce_payments_to_creators_min_fields"],
	stddev?: GraphQLTypes["voyce_payments_to_creators_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_payments_to_creators_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_payments_to_creators_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_payments_to_creators_sum_fields"],
	var_pop?: GraphQLTypes["voyce_payments_to_creators_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_payments_to_creators_var_samp_fields"],
	variance?: GraphQLTypes["voyce_payments_to_creators_variance_fields"]
};
	/** order by aggregate values of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_payments_to_creators_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_payments_to_creators_max_order_by"],
	min?: GraphQLTypes["voyce_payments_to_creators_min_order_by"],
	stddev?: GraphQLTypes["voyce_payments_to_creators_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_payments_to_creators_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_payments_to_creators_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_payments_to_creators_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_payments_to_creators_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_payments_to_creators_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_payments_to_creators_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.payments_to_creators" */
["voyce_payments_to_creators_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_payments_to_creators_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_payments_to_creators_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_payments_to_creators_avg_fields"]: {
	__typename: "voyce_payments_to_creators_avg_fields",
	amount?: number,
	id?: number,
	user_from_id?: number,
	user_to_id?: number
};
	/** order by avg() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_avg_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	user_from_id?: GraphQLTypes["order_by"],
	user_to_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.payments_to_creators". All fields are combined with a logical 'AND'. */
["voyce_payments_to_creators_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_payments_to_creators_bool_exp"]>,
	_not?: GraphQLTypes["voyce_payments_to_creators_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_payments_to_creators_bool_exp"]>,
	amount?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	stripe_id?: GraphQLTypes["String_comparison_exp"],
	user_from?: GraphQLTypes["voyce_users_bool_exp"],
	user_from_id?: GraphQLTypes["Int_comparison_exp"],
	user_to?: GraphQLTypes["voyce_users_bool_exp"],
	user_to_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.payments_to_creators" */
["voyce_payments_to_creators_constraint"]: voyce_payments_to_creators_constraint;
	/** input type for incrementing numeric columns in table "voyce.payments_to_creators" */
["voyce_payments_to_creators_inc_input"]: {
		amount?: number,
	id?: number,
	user_from_id?: number,
	user_to_id?: number
};
	/** input type for inserting data into table "voyce.payments_to_creators" */
["voyce_payments_to_creators_insert_input"]: {
		amount?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	stripe_id?: string,
	user_from?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_from_id?: number,
	user_to?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_to_id?: number
};
	/** aggregate max on columns */
["voyce_payments_to_creators_max_fields"]: {
	__typename: "voyce_payments_to_creators_max_fields",
	amount?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	stripe_id?: string,
	user_from_id?: number,
	user_to_id?: number
};
	/** order by max() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_max_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	stripe_id?: GraphQLTypes["order_by"],
	user_from_id?: GraphQLTypes["order_by"],
	user_to_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_payments_to_creators_min_fields"]: {
	__typename: "voyce_payments_to_creators_min_fields",
	amount?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	stripe_id?: string,
	user_from_id?: number,
	user_to_id?: number
};
	/** order by min() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_min_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	stripe_id?: GraphQLTypes["order_by"],
	user_from_id?: GraphQLTypes["order_by"],
	user_to_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.payments_to_creators" */
["voyce_payments_to_creators_mutation_response"]: {
	__typename: "voyce_payments_to_creators_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_payments_to_creators"]>
};
	/** on_conflict condition type for table "voyce.payments_to_creators" */
["voyce_payments_to_creators_on_conflict"]: {
		constraint: GraphQLTypes["voyce_payments_to_creators_constraint"],
	update_columns: Array<GraphQLTypes["voyce_payments_to_creators_update_column"]>,
	where?: GraphQLTypes["voyce_payments_to_creators_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.payments_to_creators". */
["voyce_payments_to_creators_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	stripe_id?: GraphQLTypes["order_by"],
	user_from?: GraphQLTypes["voyce_users_order_by"],
	user_from_id?: GraphQLTypes["order_by"],
	user_to?: GraphQLTypes["voyce_users_order_by"],
	user_to_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.payments_to_creators */
["voyce_payments_to_creators_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_select_column"]: voyce_payments_to_creators_select_column;
	/** input type for updating data in table "voyce.payments_to_creators" */
["voyce_payments_to_creators_set_input"]: {
		amount?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	stripe_id?: string,
	user_from_id?: number,
	user_to_id?: number
};
	/** aggregate stddev on columns */
["voyce_payments_to_creators_stddev_fields"]: {
	__typename: "voyce_payments_to_creators_stddev_fields",
	amount?: number,
	id?: number,
	user_from_id?: number,
	user_to_id?: number
};
	/** order by stddev() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_stddev_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	user_from_id?: GraphQLTypes["order_by"],
	user_to_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_payments_to_creators_stddev_pop_fields"]: {
	__typename: "voyce_payments_to_creators_stddev_pop_fields",
	amount?: number,
	id?: number,
	user_from_id?: number,
	user_to_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_stddev_pop_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	user_from_id?: GraphQLTypes["order_by"],
	user_to_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_payments_to_creators_stddev_samp_fields"]: {
	__typename: "voyce_payments_to_creators_stddev_samp_fields",
	amount?: number,
	id?: number,
	user_from_id?: number,
	user_to_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_stddev_samp_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	user_from_id?: GraphQLTypes["order_by"],
	user_to_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_payments_to_creators" */
["voyce_payments_to_creators_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_payments_to_creators_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_payments_to_creators_stream_cursor_value_input"]: {
		amount?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	stripe_id?: string,
	user_from_id?: number,
	user_to_id?: number
};
	/** aggregate sum on columns */
["voyce_payments_to_creators_sum_fields"]: {
	__typename: "voyce_payments_to_creators_sum_fields",
	amount?: number,
	id?: number,
	user_from_id?: number,
	user_to_id?: number
};
	/** order by sum() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_sum_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	user_from_id?: GraphQLTypes["order_by"],
	user_to_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_update_column"]: voyce_payments_to_creators_update_column;
	["voyce_payments_to_creators_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_payments_to_creators_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_payments_to_creators_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_payments_to_creators_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_payments_to_creators_var_pop_fields"]: {
	__typename: "voyce_payments_to_creators_var_pop_fields",
	amount?: number,
	id?: number,
	user_from_id?: number,
	user_to_id?: number
};
	/** order by var_pop() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_var_pop_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	user_from_id?: GraphQLTypes["order_by"],
	user_to_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_payments_to_creators_var_samp_fields"]: {
	__typename: "voyce_payments_to_creators_var_samp_fields",
	amount?: number,
	id?: number,
	user_from_id?: number,
	user_to_id?: number
};
	/** order by var_samp() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_var_samp_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	user_from_id?: GraphQLTypes["order_by"],
	user_to_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_payments_to_creators_variance_fields"]: {
	__typename: "voyce_payments_to_creators_variance_fields",
	amount?: number,
	id?: number,
	user_from_id?: number,
	user_to_id?: number
};
	/** order by variance() on columns of table "voyce.payments_to_creators" */
["voyce_payments_to_creators_variance_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	user_from_id?: GraphQLTypes["order_by"],
	user_to_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count"]: {
	__typename: "voyce_pg_rs_series_views_count",
	count: GraphQLTypes["bigint"],
	series_id: number
};
	/** aggregated selection of "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_aggregate"]: {
	__typename: "voyce_pg_rs_series_views_count_aggregate",
	aggregate?: GraphQLTypes["voyce_pg_rs_series_views_count_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_pg_rs_series_views_count"]>
};
	/** aggregate fields of "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_aggregate_fields"]: {
	__typename: "voyce_pg_rs_series_views_count_aggregate_fields",
	avg?: GraphQLTypes["voyce_pg_rs_series_views_count_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_pg_rs_series_views_count_max_fields"],
	min?: GraphQLTypes["voyce_pg_rs_series_views_count_min_fields"],
	stddev?: GraphQLTypes["voyce_pg_rs_series_views_count_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_pg_rs_series_views_count_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_pg_rs_series_views_count_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_pg_rs_series_views_count_sum_fields"],
	var_pop?: GraphQLTypes["voyce_pg_rs_series_views_count_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_pg_rs_series_views_count_var_samp_fields"],
	variance?: GraphQLTypes["voyce_pg_rs_series_views_count_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_pg_rs_series_views_count_avg_fields"]: {
	__typename: "voyce_pg_rs_series_views_count_avg_fields",
	count?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.pg_rs_series_views_count". All fields are combined with a logical 'AND'. */
["voyce_pg_rs_series_views_count_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_pg_rs_series_views_count_bool_exp"]>,
	_not?: GraphQLTypes["voyce_pg_rs_series_views_count_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_pg_rs_series_views_count_bool_exp"]>,
	count?: GraphQLTypes["bigint_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_constraint"]: voyce_pg_rs_series_views_count_constraint;
	/** input type for incrementing numeric columns in table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_inc_input"]: {
		count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** input type for inserting data into table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_insert_input"]: {
		count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** aggregate max on columns */
["voyce_pg_rs_series_views_count_max_fields"]: {
	__typename: "voyce_pg_rs_series_views_count_max_fields",
	count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** aggregate min on columns */
["voyce_pg_rs_series_views_count_min_fields"]: {
	__typename: "voyce_pg_rs_series_views_count_min_fields",
	count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** response of any mutation on the table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_mutation_response"]: {
	__typename: "voyce_pg_rs_series_views_count_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_pg_rs_series_views_count"]>
};
	/** input type for inserting object relation for remote table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_pg_rs_series_views_count_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_pg_rs_series_views_count_on_conflict"]
};
	/** on_conflict condition type for table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_on_conflict"]: {
		constraint: GraphQLTypes["voyce_pg_rs_series_views_count_constraint"],
	update_columns: Array<GraphQLTypes["voyce_pg_rs_series_views_count_update_column"]>,
	where?: GraphQLTypes["voyce_pg_rs_series_views_count_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.pg_rs_series_views_count". */
["voyce_pg_rs_series_views_count_order_by"]: {
		count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.pg_rs_series_views_count */
["voyce_pg_rs_series_views_count_pk_columns_input"]: {
		series_id: number
};
	/** select columns of table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_select_column"]: voyce_pg_rs_series_views_count_select_column;
	/** input type for updating data in table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_set_input"]: {
		count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** aggregate stddev on columns */
["voyce_pg_rs_series_views_count_stddev_fields"]: {
	__typename: "voyce_pg_rs_series_views_count_stddev_fields",
	count?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_pg_rs_series_views_count_stddev_pop_fields"]: {
	__typename: "voyce_pg_rs_series_views_count_stddev_pop_fields",
	count?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_pg_rs_series_views_count_stddev_samp_fields"]: {
	__typename: "voyce_pg_rs_series_views_count_stddev_samp_fields",
	count?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_pg_rs_series_views_count_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_pg_rs_series_views_count_stream_cursor_value_input"]: {
		count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** aggregate sum on columns */
["voyce_pg_rs_series_views_count_sum_fields"]: {
	__typename: "voyce_pg_rs_series_views_count_sum_fields",
	count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** update columns of table "voyce.pg_rs_series_views_count" */
["voyce_pg_rs_series_views_count_update_column"]: voyce_pg_rs_series_views_count_update_column;
	["voyce_pg_rs_series_views_count_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_pg_rs_series_views_count_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_pg_rs_series_views_count_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_pg_rs_series_views_count_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_pg_rs_series_views_count_var_pop_fields"]: {
	__typename: "voyce_pg_rs_series_views_count_var_pop_fields",
	count?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_pg_rs_series_views_count_var_samp_fields"]: {
	__typename: "voyce_pg_rs_series_views_count_var_samp_fields",
	count?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_pg_rs_series_views_count_variance_fields"]: {
	__typename: "voyce_pg_rs_series_views_count_variance_fields",
	count?: number,
	series_id?: number
};
	/** columns and relationships of "voyce.polls" */
["voyce_polls"]: {
	__typename: "voyce_polls",
	comments_count?: number,
	created_at: GraphQLTypes["timestamp"],
	end_date?: GraphQLTypes["timestamp"],
	id: number,
	last_activity_date: GraphQLTypes["timestamp"],
	likes_count?: number,
	outdated: boolean,
	/** An array relationship */
	polls_answers: Array<GraphQLTypes["voyce_polls_answers"]>,
	/** An aggregate relationship */
	polls_answers_aggregate: GraphQLTypes["voyce_polls_answers_aggregate"],
	/** An array relationship */
	polls_comments: Array<GraphQLTypes["voyce_polls_comments"]>,
	/** An aggregate relationship */
	polls_comments_aggregate: GraphQLTypes["voyce_polls_comments_aggregate"],
	/** An array relationship */
	polls_reactions: Array<GraphQLTypes["voyce_polls_reactions"]>,
	/** An aggregate relationship */
	polls_reactions_aggregate: GraphQLTypes["voyce_polls_reactions_aggregate"],
	/** An array relationship */
	polls_votes: Array<GraphQLTypes["voyce_polls_votes"]>,
	/** An aggregate relationship */
	polls_votes_aggregate: GraphQLTypes["voyce_polls_votes_aggregate"],
	popularity: number,
	popularity_weekly: number,
	reactions_count?: number,
	spoiler: boolean,
	title?: string,
	total_popularity: number,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number,
	votes_count?: number
};
	/** aggregated selection of "voyce.polls" */
["voyce_polls_aggregate"]: {
	__typename: "voyce_polls_aggregate",
	aggregate?: GraphQLTypes["voyce_polls_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_polls"]>
};
	["voyce_polls_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["voyce_polls_aggregate_bool_exp_bool_and"],
	bool_or?: GraphQLTypes["voyce_polls_aggregate_bool_exp_bool_or"],
	count?: GraphQLTypes["voyce_polls_aggregate_bool_exp_count"]
};
	["voyce_polls_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_polls_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_polls_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_polls_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_polls_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_polls_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_polls_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.polls" */
["voyce_polls_aggregate_fields"]: {
	__typename: "voyce_polls_aggregate_fields",
	avg?: GraphQLTypes["voyce_polls_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_polls_max_fields"],
	min?: GraphQLTypes["voyce_polls_min_fields"],
	stddev?: GraphQLTypes["voyce_polls_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_polls_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_polls_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_polls_sum_fields"],
	var_pop?: GraphQLTypes["voyce_polls_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_polls_var_samp_fields"],
	variance?: GraphQLTypes["voyce_polls_variance_fields"]
};
	/** order by aggregate values of table "voyce.polls" */
["voyce_polls_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_polls_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_polls_max_order_by"],
	min?: GraphQLTypes["voyce_polls_min_order_by"],
	stddev?: GraphQLTypes["voyce_polls_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_polls_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_polls_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_polls_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_polls_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_polls_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_polls_variance_order_by"]
};
	/** columns and relationships of "voyce.polls_answers" */
["voyce_polls_answers"]: {
	__typename: "voyce_polls_answers",
	id: number,
	image?: string,
	order: number,
	/** An object relationship */
	poll: GraphQLTypes["voyce_polls"],
	poll_id: number,
	/** An array relationship */
	polls_votes: Array<GraphQLTypes["voyce_polls_votes"]>,
	/** An aggregate relationship */
	polls_votes_aggregate: GraphQLTypes["voyce_polls_votes_aggregate"],
	text?: string,
	votes_count?: number
};
	/** aggregated selection of "voyce.polls_answers" */
["voyce_polls_answers_aggregate"]: {
	__typename: "voyce_polls_answers_aggregate",
	aggregate?: GraphQLTypes["voyce_polls_answers_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_polls_answers"]>
};
	["voyce_polls_answers_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_polls_answers_aggregate_bool_exp_count"]
};
	["voyce_polls_answers_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_polls_answers_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_polls_answers_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.polls_answers" */
["voyce_polls_answers_aggregate_fields"]: {
	__typename: "voyce_polls_answers_aggregate_fields",
	avg?: GraphQLTypes["voyce_polls_answers_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_polls_answers_max_fields"],
	min?: GraphQLTypes["voyce_polls_answers_min_fields"],
	stddev?: GraphQLTypes["voyce_polls_answers_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_polls_answers_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_polls_answers_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_polls_answers_sum_fields"],
	var_pop?: GraphQLTypes["voyce_polls_answers_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_polls_answers_var_samp_fields"],
	variance?: GraphQLTypes["voyce_polls_answers_variance_fields"]
};
	/** order by aggregate values of table "voyce.polls_answers" */
["voyce_polls_answers_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_polls_answers_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_polls_answers_max_order_by"],
	min?: GraphQLTypes["voyce_polls_answers_min_order_by"],
	stddev?: GraphQLTypes["voyce_polls_answers_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_polls_answers_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_polls_answers_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_polls_answers_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_polls_answers_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_polls_answers_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_polls_answers_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.polls_answers" */
["voyce_polls_answers_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_polls_answers_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_polls_answers_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_polls_answers_avg_fields"]: {
	__typename: "voyce_polls_answers_avg_fields",
	id?: number,
	order?: number,
	poll_id?: number,
	votes_count?: number
};
	/** order by avg() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.polls_answers". All fields are combined with a logical 'AND'. */
["voyce_polls_answers_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_polls_answers_bool_exp"]>,
	_not?: GraphQLTypes["voyce_polls_answers_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_polls_answers_bool_exp"]>,
	id?: GraphQLTypes["Int_comparison_exp"],
	image?: GraphQLTypes["String_comparison_exp"],
	order?: GraphQLTypes["Int_comparison_exp"],
	poll?: GraphQLTypes["voyce_polls_bool_exp"],
	poll_id?: GraphQLTypes["Int_comparison_exp"],
	polls_votes?: GraphQLTypes["voyce_polls_votes_bool_exp"],
	polls_votes_aggregate?: GraphQLTypes["voyce_polls_votes_aggregate_bool_exp"],
	text?: GraphQLTypes["String_comparison_exp"],
	votes_count?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.polls_answers" */
["voyce_polls_answers_constraint"]: voyce_polls_answers_constraint;
	/** input type for incrementing numeric columns in table "voyce.polls_answers" */
["voyce_polls_answers_inc_input"]: {
		id?: number,
	order?: number,
	poll_id?: number,
	votes_count?: number
};
	/** input type for inserting data into table "voyce.polls_answers" */
["voyce_polls_answers_insert_input"]: {
		id?: number,
	image?: string,
	order?: number,
	poll?: GraphQLTypes["voyce_polls_obj_rel_insert_input"],
	poll_id?: number,
	polls_votes?: GraphQLTypes["voyce_polls_votes_arr_rel_insert_input"],
	text?: string,
	votes_count?: number
};
	/** aggregate max on columns */
["voyce_polls_answers_max_fields"]: {
	__typename: "voyce_polls_answers_max_fields",
	id?: number,
	image?: string,
	order?: number,
	poll_id?: number,
	text?: string,
	votes_count?: number
};
	/** order by max() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_max_order_by"]: {
		id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	text?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_polls_answers_min_fields"]: {
	__typename: "voyce_polls_answers_min_fields",
	id?: number,
	image?: string,
	order?: number,
	poll_id?: number,
	text?: string,
	votes_count?: number
};
	/** order by min() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_min_order_by"]: {
		id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	text?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.polls_answers" */
["voyce_polls_answers_mutation_response"]: {
	__typename: "voyce_polls_answers_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_polls_answers"]>
};
	/** input type for inserting object relation for remote table "voyce.polls_answers" */
["voyce_polls_answers_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_polls_answers_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_polls_answers_on_conflict"]
};
	/** on_conflict condition type for table "voyce.polls_answers" */
["voyce_polls_answers_on_conflict"]: {
		constraint: GraphQLTypes["voyce_polls_answers_constraint"],
	update_columns: Array<GraphQLTypes["voyce_polls_answers_update_column"]>,
	where?: GraphQLTypes["voyce_polls_answers_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.polls_answers". */
["voyce_polls_answers_order_by"]: {
		id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	poll?: GraphQLTypes["voyce_polls_order_by"],
	poll_id?: GraphQLTypes["order_by"],
	polls_votes_aggregate?: GraphQLTypes["voyce_polls_votes_aggregate_order_by"],
	text?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.polls_answers */
["voyce_polls_answers_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.polls_answers" */
["voyce_polls_answers_select_column"]: voyce_polls_answers_select_column;
	/** input type for updating data in table "voyce.polls_answers" */
["voyce_polls_answers_set_input"]: {
		id?: number,
	image?: string,
	order?: number,
	poll_id?: number,
	text?: string,
	votes_count?: number
};
	/** aggregate stddev on columns */
["voyce_polls_answers_stddev_fields"]: {
	__typename: "voyce_polls_answers_stddev_fields",
	id?: number,
	order?: number,
	poll_id?: number,
	votes_count?: number
};
	/** order by stddev() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_polls_answers_stddev_pop_fields"]: {
	__typename: "voyce_polls_answers_stddev_pop_fields",
	id?: number,
	order?: number,
	poll_id?: number,
	votes_count?: number
};
	/** order by stddev_pop() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_polls_answers_stddev_samp_fields"]: {
	__typename: "voyce_polls_answers_stddev_samp_fields",
	id?: number,
	order?: number,
	poll_id?: number,
	votes_count?: number
};
	/** order by stddev_samp() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_polls_answers" */
["voyce_polls_answers_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_polls_answers_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_polls_answers_stream_cursor_value_input"]: {
		id?: number,
	image?: string,
	order?: number,
	poll_id?: number,
	text?: string,
	votes_count?: number
};
	/** aggregate sum on columns */
["voyce_polls_answers_sum_fields"]: {
	__typename: "voyce_polls_answers_sum_fields",
	id?: number,
	order?: number,
	poll_id?: number,
	votes_count?: number
};
	/** order by sum() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.polls_answers" */
["voyce_polls_answers_update_column"]: voyce_polls_answers_update_column;
	["voyce_polls_answers_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_polls_answers_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_polls_answers_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_polls_answers_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_polls_answers_var_pop_fields"]: {
	__typename: "voyce_polls_answers_var_pop_fields",
	id?: number,
	order?: number,
	poll_id?: number,
	votes_count?: number
};
	/** order by var_pop() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_polls_answers_var_samp_fields"]: {
	__typename: "voyce_polls_answers_var_samp_fields",
	id?: number,
	order?: number,
	poll_id?: number,
	votes_count?: number
};
	/** order by var_samp() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_polls_answers_variance_fields"]: {
	__typename: "voyce_polls_answers_variance_fields",
	id?: number,
	order?: number,
	poll_id?: number,
	votes_count?: number
};
	/** order by variance() on columns of table "voyce.polls_answers" */
["voyce_polls_answers_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** input type for inserting array relation for remote table "voyce.polls" */
["voyce_polls_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_polls_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_polls_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_polls_avg_fields"]: {
	__typename: "voyce_polls_avg_fields",
	comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number,
	votes_count?: number
};
	/** order by avg() on columns of table "voyce.polls" */
["voyce_polls_avg_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.polls". All fields are combined with a logical 'AND'. */
["voyce_polls_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_polls_bool_exp"]>,
	_not?: GraphQLTypes["voyce_polls_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_polls_bool_exp"]>,
	comments_count?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	end_date?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	last_activity_date?: GraphQLTypes["timestamp_comparison_exp"],
	likes_count?: GraphQLTypes["Int_comparison_exp"],
	outdated?: GraphQLTypes["Boolean_comparison_exp"],
	polls_answers?: GraphQLTypes["voyce_polls_answers_bool_exp"],
	polls_answers_aggregate?: GraphQLTypes["voyce_polls_answers_aggregate_bool_exp"],
	polls_comments?: GraphQLTypes["voyce_polls_comments_bool_exp"],
	polls_comments_aggregate?: GraphQLTypes["voyce_polls_comments_aggregate_bool_exp"],
	polls_reactions?: GraphQLTypes["voyce_polls_reactions_bool_exp"],
	polls_reactions_aggregate?: GraphQLTypes["voyce_polls_reactions_aggregate_bool_exp"],
	polls_votes?: GraphQLTypes["voyce_polls_votes_bool_exp"],
	polls_votes_aggregate?: GraphQLTypes["voyce_polls_votes_aggregate_bool_exp"],
	popularity?: GraphQLTypes["Int_comparison_exp"],
	popularity_weekly?: GraphQLTypes["Int_comparison_exp"],
	reactions_count?: GraphQLTypes["Int_comparison_exp"],
	spoiler?: GraphQLTypes["Boolean_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	total_popularity?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	votes_count?: GraphQLTypes["Int_comparison_exp"]
};
	/** columns and relationships of "voyce.polls_comments" */
["voyce_polls_comments"]: {
	__typename: "voyce_polls_comments",
	animated_image?: string,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	image?: string,
	linked_id: number,
	parent_id?: number,
	/** An object relationship */
	poll: GraphQLTypes["voyce_polls"],
	/** An object relationship */
	polls_comment?: GraphQLTypes["voyce_polls_comments"],
	/** An array relationship */
	polls_comments: Array<GraphQLTypes["voyce_polls_comments"]>,
	/** An aggregate relationship */
	polls_comments_aggregate: GraphQLTypes["voyce_polls_comments_aggregate"],
	/** An array relationship */
	polls_reactions: Array<GraphQLTypes["voyce_polls_reactions"]>,
	/** An aggregate relationship */
	polls_reactions_aggregate: GraphQLTypes["voyce_polls_reactions_aggregate"],
	reaction_count?: number,
	replies_count?: number,
	text: string,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.polls_comments" */
["voyce_polls_comments_aggregate"]: {
	__typename: "voyce_polls_comments_aggregate",
	aggregate?: GraphQLTypes["voyce_polls_comments_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_polls_comments"]>
};
	["voyce_polls_comments_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_polls_comments_aggregate_bool_exp_count"]
};
	["voyce_polls_comments_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_polls_comments_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_polls_comments_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.polls_comments" */
["voyce_polls_comments_aggregate_fields"]: {
	__typename: "voyce_polls_comments_aggregate_fields",
	avg?: GraphQLTypes["voyce_polls_comments_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_polls_comments_max_fields"],
	min?: GraphQLTypes["voyce_polls_comments_min_fields"],
	stddev?: GraphQLTypes["voyce_polls_comments_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_polls_comments_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_polls_comments_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_polls_comments_sum_fields"],
	var_pop?: GraphQLTypes["voyce_polls_comments_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_polls_comments_var_samp_fields"],
	variance?: GraphQLTypes["voyce_polls_comments_variance_fields"]
};
	/** order by aggregate values of table "voyce.polls_comments" */
["voyce_polls_comments_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_polls_comments_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_polls_comments_max_order_by"],
	min?: GraphQLTypes["voyce_polls_comments_min_order_by"],
	stddev?: GraphQLTypes["voyce_polls_comments_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_polls_comments_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_polls_comments_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_polls_comments_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_polls_comments_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_polls_comments_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_polls_comments_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.polls_comments" */
["voyce_polls_comments_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_polls_comments_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_polls_comments_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_polls_comments_avg_fields"]: {
	__typename: "voyce_polls_comments_avg_fields",
	id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.polls_comments". All fields are combined with a logical 'AND'. */
["voyce_polls_comments_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_polls_comments_bool_exp"]>,
	_not?: GraphQLTypes["voyce_polls_comments_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_polls_comments_bool_exp"]>,
	animated_image?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	image?: GraphQLTypes["String_comparison_exp"],
	linked_id?: GraphQLTypes["Int_comparison_exp"],
	parent_id?: GraphQLTypes["Int_comparison_exp"],
	poll?: GraphQLTypes["voyce_polls_bool_exp"],
	polls_comment?: GraphQLTypes["voyce_polls_comments_bool_exp"],
	polls_comments?: GraphQLTypes["voyce_polls_comments_bool_exp"],
	polls_comments_aggregate?: GraphQLTypes["voyce_polls_comments_aggregate_bool_exp"],
	polls_reactions?: GraphQLTypes["voyce_polls_reactions_bool_exp"],
	polls_reactions_aggregate?: GraphQLTypes["voyce_polls_reactions_aggregate_bool_exp"],
	reaction_count?: GraphQLTypes["Int_comparison_exp"],
	replies_count?: GraphQLTypes["Int_comparison_exp"],
	text?: GraphQLTypes["String_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.polls_comments" */
["voyce_polls_comments_constraint"]: voyce_polls_comments_constraint;
	/** input type for incrementing numeric columns in table "voyce.polls_comments" */
["voyce_polls_comments_inc_input"]: {
		id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.polls_comments" */
["voyce_polls_comments_insert_input"]: {
		animated_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	linked_id?: number,
	parent_id?: number,
	poll?: GraphQLTypes["voyce_polls_obj_rel_insert_input"],
	polls_comment?: GraphQLTypes["voyce_polls_comments_obj_rel_insert_input"],
	polls_comments?: GraphQLTypes["voyce_polls_comments_arr_rel_insert_input"],
	polls_reactions?: GraphQLTypes["voyce_polls_reactions_arr_rel_insert_input"],
	reaction_count?: number,
	replies_count?: number,
	text?: string,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_polls_comments_max_fields"]: {
	__typename: "voyce_polls_comments_max_fields",
	animated_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	text?: string,
	user_id?: number
};
	/** order by max() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_max_order_by"]: {
		animated_image?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	text?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_polls_comments_min_fields"]: {
	__typename: "voyce_polls_comments_min_fields",
	animated_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	text?: string,
	user_id?: number
};
	/** order by min() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_min_order_by"]: {
		animated_image?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	text?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.polls_comments" */
["voyce_polls_comments_mutation_response"]: {
	__typename: "voyce_polls_comments_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_polls_comments"]>
};
	/** input type for inserting object relation for remote table "voyce.polls_comments" */
["voyce_polls_comments_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_polls_comments_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_polls_comments_on_conflict"]
};
	/** on_conflict condition type for table "voyce.polls_comments" */
["voyce_polls_comments_on_conflict"]: {
		constraint: GraphQLTypes["voyce_polls_comments_constraint"],
	update_columns: Array<GraphQLTypes["voyce_polls_comments_update_column"]>,
	where?: GraphQLTypes["voyce_polls_comments_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.polls_comments". */
["voyce_polls_comments_order_by"]: {
		animated_image?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	poll?: GraphQLTypes["voyce_polls_order_by"],
	polls_comment?: GraphQLTypes["voyce_polls_comments_order_by"],
	polls_comments_aggregate?: GraphQLTypes["voyce_polls_comments_aggregate_order_by"],
	polls_reactions_aggregate?: GraphQLTypes["voyce_polls_reactions_aggregate_order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	text?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.polls_comments */
["voyce_polls_comments_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.polls_comments" */
["voyce_polls_comments_select_column"]: voyce_polls_comments_select_column;
	/** input type for updating data in table "voyce.polls_comments" */
["voyce_polls_comments_set_input"]: {
		animated_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	text?: string,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_polls_comments_stddev_fields"]: {
	__typename: "voyce_polls_comments_stddev_fields",
	id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_polls_comments_stddev_pop_fields"]: {
	__typename: "voyce_polls_comments_stddev_pop_fields",
	id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_polls_comments_stddev_samp_fields"]: {
	__typename: "voyce_polls_comments_stddev_samp_fields",
	id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_polls_comments" */
["voyce_polls_comments_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_polls_comments_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_polls_comments_stream_cursor_value_input"]: {
		animated_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	text?: string,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_polls_comments_sum_fields"]: {
	__typename: "voyce_polls_comments_sum_fields",
	id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.polls_comments" */
["voyce_polls_comments_update_column"]: voyce_polls_comments_update_column;
	["voyce_polls_comments_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_polls_comments_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_polls_comments_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_polls_comments_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_polls_comments_var_pop_fields"]: {
	__typename: "voyce_polls_comments_var_pop_fields",
	id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_polls_comments_var_samp_fields"]: {
	__typename: "voyce_polls_comments_var_samp_fields",
	id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_polls_comments_variance_fields"]: {
	__typename: "voyce_polls_comments_variance_fields",
	id?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.polls_comments" */
["voyce_polls_comments_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** unique or primary key constraints on table "voyce.polls" */
["voyce_polls_constraint"]: voyce_polls_constraint;
	/** input type for incrementing numeric columns in table "voyce.polls" */
["voyce_polls_inc_input"]: {
		comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number,
	votes_count?: number
};
	/** input type for inserting data into table "voyce.polls" */
["voyce_polls_insert_input"]: {
		comments_count?: number,
	created_at?: GraphQLTypes["timestamp"],
	end_date?: GraphQLTypes["timestamp"],
	id?: number,
	last_activity_date?: GraphQLTypes["timestamp"],
	likes_count?: number,
	outdated?: boolean,
	polls_answers?: GraphQLTypes["voyce_polls_answers_arr_rel_insert_input"],
	polls_comments?: GraphQLTypes["voyce_polls_comments_arr_rel_insert_input"],
	polls_reactions?: GraphQLTypes["voyce_polls_reactions_arr_rel_insert_input"],
	polls_votes?: GraphQLTypes["voyce_polls_votes_arr_rel_insert_input"],
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	spoiler?: boolean,
	title?: string,
	total_popularity?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number,
	votes_count?: number
};
	/** aggregate max on columns */
["voyce_polls_max_fields"]: {
	__typename: "voyce_polls_max_fields",
	comments_count?: number,
	created_at?: GraphQLTypes["timestamp"],
	end_date?: GraphQLTypes["timestamp"],
	id?: number,
	last_activity_date?: GraphQLTypes["timestamp"],
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	title?: string,
	total_popularity?: number,
	user_id?: number,
	votes_count?: number
};
	/** order by max() on columns of table "voyce.polls" */
["voyce_polls_max_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	end_date?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_activity_date?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_polls_min_fields"]: {
	__typename: "voyce_polls_min_fields",
	comments_count?: number,
	created_at?: GraphQLTypes["timestamp"],
	end_date?: GraphQLTypes["timestamp"],
	id?: number,
	last_activity_date?: GraphQLTypes["timestamp"],
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	title?: string,
	total_popularity?: number,
	user_id?: number,
	votes_count?: number
};
	/** order by min() on columns of table "voyce.polls" */
["voyce_polls_min_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	end_date?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_activity_date?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.polls" */
["voyce_polls_mutation_response"]: {
	__typename: "voyce_polls_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_polls"]>
};
	/** input type for inserting object relation for remote table "voyce.polls" */
["voyce_polls_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_polls_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_polls_on_conflict"]
};
	/** on_conflict condition type for table "voyce.polls" */
["voyce_polls_on_conflict"]: {
		constraint: GraphQLTypes["voyce_polls_constraint"],
	update_columns: Array<GraphQLTypes["voyce_polls_update_column"]>,
	where?: GraphQLTypes["voyce_polls_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.polls". */
["voyce_polls_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	end_date?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_activity_date?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	outdated?: GraphQLTypes["order_by"],
	polls_answers_aggregate?: GraphQLTypes["voyce_polls_answers_aggregate_order_by"],
	polls_comments_aggregate?: GraphQLTypes["voyce_polls_comments_aggregate_order_by"],
	polls_reactions_aggregate?: GraphQLTypes["voyce_polls_reactions_aggregate_order_by"],
	polls_votes_aggregate?: GraphQLTypes["voyce_polls_votes_aggregate_order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	spoiler?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.polls */
["voyce_polls_pk_columns_input"]: {
		id: number
};
	/** columns and relationships of "voyce.polls_reactions" */
["voyce_polls_reactions"]: {
	__typename: "voyce_polls_reactions",
	comment_id?: number,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	linked_entity_id: number,
	/** An object relationship */
	poll: GraphQLTypes["voyce_polls"],
	/** An object relationship */
	polls_comment?: GraphQLTypes["voyce_polls_comments"],
	reaction: number,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.polls_reactions" */
["voyce_polls_reactions_aggregate"]: {
	__typename: "voyce_polls_reactions_aggregate",
	aggregate?: GraphQLTypes["voyce_polls_reactions_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_polls_reactions"]>
};
	["voyce_polls_reactions_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_polls_reactions_aggregate_bool_exp_count"]
};
	["voyce_polls_reactions_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_polls_reactions_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_polls_reactions_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.polls_reactions" */
["voyce_polls_reactions_aggregate_fields"]: {
	__typename: "voyce_polls_reactions_aggregate_fields",
	avg?: GraphQLTypes["voyce_polls_reactions_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_polls_reactions_max_fields"],
	min?: GraphQLTypes["voyce_polls_reactions_min_fields"],
	stddev?: GraphQLTypes["voyce_polls_reactions_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_polls_reactions_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_polls_reactions_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_polls_reactions_sum_fields"],
	var_pop?: GraphQLTypes["voyce_polls_reactions_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_polls_reactions_var_samp_fields"],
	variance?: GraphQLTypes["voyce_polls_reactions_variance_fields"]
};
	/** order by aggregate values of table "voyce.polls_reactions" */
["voyce_polls_reactions_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_polls_reactions_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_polls_reactions_max_order_by"],
	min?: GraphQLTypes["voyce_polls_reactions_min_order_by"],
	stddev?: GraphQLTypes["voyce_polls_reactions_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_polls_reactions_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_polls_reactions_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_polls_reactions_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_polls_reactions_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_polls_reactions_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_polls_reactions_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.polls_reactions" */
["voyce_polls_reactions_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_polls_reactions_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_polls_reactions_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_polls_reactions_avg_fields"]: {
	__typename: "voyce_polls_reactions_avg_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_avg_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.polls_reactions". All fields are combined with a logical 'AND'. */
["voyce_polls_reactions_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_polls_reactions_bool_exp"]>,
	_not?: GraphQLTypes["voyce_polls_reactions_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_polls_reactions_bool_exp"]>,
	comment_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	linked_entity_id?: GraphQLTypes["Int_comparison_exp"],
	poll?: GraphQLTypes["voyce_polls_bool_exp"],
	polls_comment?: GraphQLTypes["voyce_polls_comments_bool_exp"],
	reaction?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.polls_reactions" */
["voyce_polls_reactions_constraint"]: voyce_polls_reactions_constraint;
	/** input type for incrementing numeric columns in table "voyce.polls_reactions" */
["voyce_polls_reactions_inc_input"]: {
		comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.polls_reactions" */
["voyce_polls_reactions_insert_input"]: {
		comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	poll?: GraphQLTypes["voyce_polls_obj_rel_insert_input"],
	polls_comment?: GraphQLTypes["voyce_polls_comments_obj_rel_insert_input"],
	reaction?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_polls_reactions_max_fields"]: {
	__typename: "voyce_polls_reactions_max_fields",
	comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by max() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_max_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_polls_reactions_min_fields"]: {
	__typename: "voyce_polls_reactions_min_fields",
	comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by min() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_min_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.polls_reactions" */
["voyce_polls_reactions_mutation_response"]: {
	__typename: "voyce_polls_reactions_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_polls_reactions"]>
};
	/** on_conflict condition type for table "voyce.polls_reactions" */
["voyce_polls_reactions_on_conflict"]: {
		constraint: GraphQLTypes["voyce_polls_reactions_constraint"],
	update_columns: Array<GraphQLTypes["voyce_polls_reactions_update_column"]>,
	where?: GraphQLTypes["voyce_polls_reactions_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.polls_reactions". */
["voyce_polls_reactions_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	poll?: GraphQLTypes["voyce_polls_order_by"],
	polls_comment?: GraphQLTypes["voyce_polls_comments_order_by"],
	reaction?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.polls_reactions */
["voyce_polls_reactions_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_select_column"]: voyce_polls_reactions_select_column;
	/** input type for updating data in table "voyce.polls_reactions" */
["voyce_polls_reactions_set_input"]: {
		comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_polls_reactions_stddev_fields"]: {
	__typename: "voyce_polls_reactions_stddev_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_stddev_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_polls_reactions_stddev_pop_fields"]: {
	__typename: "voyce_polls_reactions_stddev_pop_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_stddev_pop_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_polls_reactions_stddev_samp_fields"]: {
	__typename: "voyce_polls_reactions_stddev_samp_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_stddev_samp_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_polls_reactions" */
["voyce_polls_reactions_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_polls_reactions_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_polls_reactions_stream_cursor_value_input"]: {
		comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_polls_reactions_sum_fields"]: {
	__typename: "voyce_polls_reactions_sum_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_sum_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_update_column"]: voyce_polls_reactions_update_column;
	["voyce_polls_reactions_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_polls_reactions_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_polls_reactions_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_polls_reactions_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_polls_reactions_var_pop_fields"]: {
	__typename: "voyce_polls_reactions_var_pop_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_var_pop_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_polls_reactions_var_samp_fields"]: {
	__typename: "voyce_polls_reactions_var_samp_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_var_samp_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_polls_reactions_variance_fields"]: {
	__typename: "voyce_polls_reactions_variance_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.polls_reactions" */
["voyce_polls_reactions_variance_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.polls" */
["voyce_polls_select_column"]: voyce_polls_select_column;
	/** select "voyce_polls_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.polls" */
["voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_and_arguments_columns"]: voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_polls_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.polls" */
["voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_or_arguments_columns"]: voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.polls" */
["voyce_polls_set_input"]: {
		comments_count?: number,
	created_at?: GraphQLTypes["timestamp"],
	end_date?: GraphQLTypes["timestamp"],
	id?: number,
	last_activity_date?: GraphQLTypes["timestamp"],
	likes_count?: number,
	outdated?: boolean,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	spoiler?: boolean,
	title?: string,
	total_popularity?: number,
	user_id?: number,
	votes_count?: number
};
	/** aggregate stddev on columns */
["voyce_polls_stddev_fields"]: {
	__typename: "voyce_polls_stddev_fields",
	comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number,
	votes_count?: number
};
	/** order by stddev() on columns of table "voyce.polls" */
["voyce_polls_stddev_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_polls_stddev_pop_fields"]: {
	__typename: "voyce_polls_stddev_pop_fields",
	comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number,
	votes_count?: number
};
	/** order by stddev_pop() on columns of table "voyce.polls" */
["voyce_polls_stddev_pop_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_polls_stddev_samp_fields"]: {
	__typename: "voyce_polls_stddev_samp_fields",
	comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number,
	votes_count?: number
};
	/** order by stddev_samp() on columns of table "voyce.polls" */
["voyce_polls_stddev_samp_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_polls" */
["voyce_polls_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_polls_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_polls_stream_cursor_value_input"]: {
		comments_count?: number,
	created_at?: GraphQLTypes["timestamp"],
	end_date?: GraphQLTypes["timestamp"],
	id?: number,
	last_activity_date?: GraphQLTypes["timestamp"],
	likes_count?: number,
	outdated?: boolean,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	spoiler?: boolean,
	title?: string,
	total_popularity?: number,
	user_id?: number,
	votes_count?: number
};
	/** aggregate sum on columns */
["voyce_polls_sum_fields"]: {
	__typename: "voyce_polls_sum_fields",
	comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number,
	votes_count?: number
};
	/** order by sum() on columns of table "voyce.polls" */
["voyce_polls_sum_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.polls" */
["voyce_polls_update_column"]: voyce_polls_update_column;
	["voyce_polls_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_polls_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_polls_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_polls_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_polls_var_pop_fields"]: {
	__typename: "voyce_polls_var_pop_fields",
	comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number,
	votes_count?: number
};
	/** order by var_pop() on columns of table "voyce.polls" */
["voyce_polls_var_pop_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_polls_var_samp_fields"]: {
	__typename: "voyce_polls_var_samp_fields",
	comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number,
	votes_count?: number
};
	/** order by var_samp() on columns of table "voyce.polls" */
["voyce_polls_var_samp_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_polls_variance_fields"]: {
	__typename: "voyce_polls_variance_fields",
	comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number,
	votes_count?: number
};
	/** order by variance() on columns of table "voyce.polls" */
["voyce_polls_variance_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	votes_count?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.polls_votes" */
["voyce_polls_votes"]: {
	__typename: "voyce_polls_votes",
	answer_id: number,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	/** An object relationship */
	poll: GraphQLTypes["voyce_polls"],
	poll_id: number,
	/** An object relationship */
	polls_answer: GraphQLTypes["voyce_polls_answers"],
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.polls_votes" */
["voyce_polls_votes_aggregate"]: {
	__typename: "voyce_polls_votes_aggregate",
	aggregate?: GraphQLTypes["voyce_polls_votes_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_polls_votes"]>
};
	["voyce_polls_votes_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_polls_votes_aggregate_bool_exp_count"]
};
	["voyce_polls_votes_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_polls_votes_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_polls_votes_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.polls_votes" */
["voyce_polls_votes_aggregate_fields"]: {
	__typename: "voyce_polls_votes_aggregate_fields",
	avg?: GraphQLTypes["voyce_polls_votes_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_polls_votes_max_fields"],
	min?: GraphQLTypes["voyce_polls_votes_min_fields"],
	stddev?: GraphQLTypes["voyce_polls_votes_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_polls_votes_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_polls_votes_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_polls_votes_sum_fields"],
	var_pop?: GraphQLTypes["voyce_polls_votes_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_polls_votes_var_samp_fields"],
	variance?: GraphQLTypes["voyce_polls_votes_variance_fields"]
};
	/** order by aggregate values of table "voyce.polls_votes" */
["voyce_polls_votes_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_polls_votes_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_polls_votes_max_order_by"],
	min?: GraphQLTypes["voyce_polls_votes_min_order_by"],
	stddev?: GraphQLTypes["voyce_polls_votes_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_polls_votes_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_polls_votes_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_polls_votes_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_polls_votes_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_polls_votes_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_polls_votes_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.polls_votes" */
["voyce_polls_votes_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_polls_votes_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_polls_votes_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_polls_votes_avg_fields"]: {
	__typename: "voyce_polls_votes_avg_fields",
	answer_id?: number,
	id?: number,
	poll_id?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_avg_order_by"]: {
		answer_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.polls_votes". All fields are combined with a logical 'AND'. */
["voyce_polls_votes_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_polls_votes_bool_exp"]>,
	_not?: GraphQLTypes["voyce_polls_votes_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_polls_votes_bool_exp"]>,
	answer_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	poll?: GraphQLTypes["voyce_polls_bool_exp"],
	poll_id?: GraphQLTypes["Int_comparison_exp"],
	polls_answer?: GraphQLTypes["voyce_polls_answers_bool_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.polls_votes" */
["voyce_polls_votes_constraint"]: voyce_polls_votes_constraint;
	/** input type for incrementing numeric columns in table "voyce.polls_votes" */
["voyce_polls_votes_inc_input"]: {
		answer_id?: number,
	id?: number,
	poll_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.polls_votes" */
["voyce_polls_votes_insert_input"]: {
		answer_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	poll?: GraphQLTypes["voyce_polls_obj_rel_insert_input"],
	poll_id?: number,
	polls_answer?: GraphQLTypes["voyce_polls_answers_obj_rel_insert_input"],
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_polls_votes_max_fields"]: {
	__typename: "voyce_polls_votes_max_fields",
	answer_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	poll_id?: number,
	user_id?: number
};
	/** order by max() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_max_order_by"]: {
		answer_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_polls_votes_min_fields"]: {
	__typename: "voyce_polls_votes_min_fields",
	answer_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	poll_id?: number,
	user_id?: number
};
	/** order by min() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_min_order_by"]: {
		answer_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.polls_votes" */
["voyce_polls_votes_mutation_response"]: {
	__typename: "voyce_polls_votes_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_polls_votes"]>
};
	/** on_conflict condition type for table "voyce.polls_votes" */
["voyce_polls_votes_on_conflict"]: {
		constraint: GraphQLTypes["voyce_polls_votes_constraint"],
	update_columns: Array<GraphQLTypes["voyce_polls_votes_update_column"]>,
	where?: GraphQLTypes["voyce_polls_votes_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.polls_votes". */
["voyce_polls_votes_order_by"]: {
		answer_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	poll?: GraphQLTypes["voyce_polls_order_by"],
	poll_id?: GraphQLTypes["order_by"],
	polls_answer?: GraphQLTypes["voyce_polls_answers_order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.polls_votes */
["voyce_polls_votes_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.polls_votes" */
["voyce_polls_votes_select_column"]: voyce_polls_votes_select_column;
	/** input type for updating data in table "voyce.polls_votes" */
["voyce_polls_votes_set_input"]: {
		answer_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	poll_id?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_polls_votes_stddev_fields"]: {
	__typename: "voyce_polls_votes_stddev_fields",
	answer_id?: number,
	id?: number,
	poll_id?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_stddev_order_by"]: {
		answer_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_polls_votes_stddev_pop_fields"]: {
	__typename: "voyce_polls_votes_stddev_pop_fields",
	answer_id?: number,
	id?: number,
	poll_id?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_stddev_pop_order_by"]: {
		answer_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_polls_votes_stddev_samp_fields"]: {
	__typename: "voyce_polls_votes_stddev_samp_fields",
	answer_id?: number,
	id?: number,
	poll_id?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_stddev_samp_order_by"]: {
		answer_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_polls_votes" */
["voyce_polls_votes_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_polls_votes_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_polls_votes_stream_cursor_value_input"]: {
		answer_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	poll_id?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_polls_votes_sum_fields"]: {
	__typename: "voyce_polls_votes_sum_fields",
	answer_id?: number,
	id?: number,
	poll_id?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_sum_order_by"]: {
		answer_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.polls_votes" */
["voyce_polls_votes_update_column"]: voyce_polls_votes_update_column;
	["voyce_polls_votes_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_polls_votes_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_polls_votes_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_polls_votes_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_polls_votes_var_pop_fields"]: {
	__typename: "voyce_polls_votes_var_pop_fields",
	answer_id?: number,
	id?: number,
	poll_id?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_var_pop_order_by"]: {
		answer_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_polls_votes_var_samp_fields"]: {
	__typename: "voyce_polls_votes_var_samp_fields",
	answer_id?: number,
	id?: number,
	poll_id?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_var_samp_order_by"]: {
		answer_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_polls_votes_variance_fields"]: {
	__typename: "voyce_polls_votes_variance_fields",
	answer_id?: number,
	id?: number,
	poll_id?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.polls_votes" */
["voyce_polls_votes_variance_order_by"]: {
		answer_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	poll_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.popup_components" */
["voyce_popup_components"]: {
	__typename: "voyce_popup_components",
	auth?: boolean,
	component: string,
	created_at?: GraphQLTypes["timestamptz"],
	email_form: boolean,
	exclusive_series: boolean,
	followers?: boolean,
	id: number,
	is_active: boolean,
	klaviyo_list_id?: string,
	layout?: number,
	page: string,
	props?: GraphQLTypes["json"],
	series_visibility?: boolean,
	sort?: GraphQLTypes["smallint"],
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.popup_components" */
["voyce_popup_components_aggregate"]: {
	__typename: "voyce_popup_components_aggregate",
	aggregate?: GraphQLTypes["voyce_popup_components_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_popup_components"]>
};
	/** aggregate fields of "voyce.popup_components" */
["voyce_popup_components_aggregate_fields"]: {
	__typename: "voyce_popup_components_aggregate_fields",
	avg?: GraphQLTypes["voyce_popup_components_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_popup_components_max_fields"],
	min?: GraphQLTypes["voyce_popup_components_min_fields"],
	stddev?: GraphQLTypes["voyce_popup_components_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_popup_components_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_popup_components_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_popup_components_sum_fields"],
	var_pop?: GraphQLTypes["voyce_popup_components_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_popup_components_var_samp_fields"],
	variance?: GraphQLTypes["voyce_popup_components_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_popup_components_avg_fields"]: {
	__typename: "voyce_popup_components_avg_fields",
	id?: number,
	layout?: number,
	sort?: number
};
	/** Boolean expression to filter rows from the table "voyce.popup_components". All fields are combined with a logical 'AND'. */
["voyce_popup_components_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_popup_components_bool_exp"]>,
	_not?: GraphQLTypes["voyce_popup_components_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_popup_components_bool_exp"]>,
	auth?: GraphQLTypes["Boolean_comparison_exp"],
	component?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	email_form?: GraphQLTypes["Boolean_comparison_exp"],
	exclusive_series?: GraphQLTypes["Boolean_comparison_exp"],
	followers?: GraphQLTypes["Boolean_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_active?: GraphQLTypes["Boolean_comparison_exp"],
	klaviyo_list_id?: GraphQLTypes["String_comparison_exp"],
	layout?: GraphQLTypes["Int_comparison_exp"],
	page?: GraphQLTypes["String_comparison_exp"],
	props?: GraphQLTypes["json_comparison_exp"],
	series_visibility?: GraphQLTypes["Boolean_comparison_exp"],
	sort?: GraphQLTypes["smallint_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.popup_components" */
["voyce_popup_components_constraint"]: voyce_popup_components_constraint;
	/** input type for incrementing numeric columns in table "voyce.popup_components" */
["voyce_popup_components_inc_input"]: {
		id?: number,
	layout?: number,
	sort?: GraphQLTypes["smallint"]
};
	/** input type for inserting data into table "voyce.popup_components" */
["voyce_popup_components_insert_input"]: {
		auth?: boolean,
	component?: string,
	created_at?: GraphQLTypes["timestamptz"],
	email_form?: boolean,
	exclusive_series?: boolean,
	followers?: boolean,
	id?: number,
	is_active?: boolean,
	klaviyo_list_id?: string,
	layout?: number,
	page?: string,
	props?: GraphQLTypes["json"],
	series_visibility?: boolean,
	sort?: GraphQLTypes["smallint"],
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_popup_components_max_fields"]: {
	__typename: "voyce_popup_components_max_fields",
	component?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	klaviyo_list_id?: string,
	layout?: number,
	page?: string,
	sort?: GraphQLTypes["smallint"],
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_popup_components_min_fields"]: {
	__typename: "voyce_popup_components_min_fields",
	component?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	klaviyo_list_id?: string,
	layout?: number,
	page?: string,
	sort?: GraphQLTypes["smallint"],
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.popup_components" */
["voyce_popup_components_mutation_response"]: {
	__typename: "voyce_popup_components_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_popup_components"]>
};
	/** input type for inserting object relation for remote table "voyce.popup_components" */
["voyce_popup_components_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_popup_components_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_popup_components_on_conflict"]
};
	/** on_conflict condition type for table "voyce.popup_components" */
["voyce_popup_components_on_conflict"]: {
		constraint: GraphQLTypes["voyce_popup_components_constraint"],
	update_columns: Array<GraphQLTypes["voyce_popup_components_update_column"]>,
	where?: GraphQLTypes["voyce_popup_components_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.popup_components". */
["voyce_popup_components_order_by"]: {
		auth?: GraphQLTypes["order_by"],
	component?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	email_form?: GraphQLTypes["order_by"],
	exclusive_series?: GraphQLTypes["order_by"],
	followers?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_active?: GraphQLTypes["order_by"],
	klaviyo_list_id?: GraphQLTypes["order_by"],
	layout?: GraphQLTypes["order_by"],
	page?: GraphQLTypes["order_by"],
	props?: GraphQLTypes["order_by"],
	series_visibility?: GraphQLTypes["order_by"],
	sort?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.popup_components */
["voyce_popup_components_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.popup_components" */
["voyce_popup_components_select_column"]: voyce_popup_components_select_column;
	/** input type for updating data in table "voyce.popup_components" */
["voyce_popup_components_set_input"]: {
		auth?: boolean,
	component?: string,
	created_at?: GraphQLTypes["timestamptz"],
	email_form?: boolean,
	exclusive_series?: boolean,
	followers?: boolean,
	id?: number,
	is_active?: boolean,
	klaviyo_list_id?: string,
	layout?: number,
	page?: string,
	props?: GraphQLTypes["json"],
	series_visibility?: boolean,
	sort?: GraphQLTypes["smallint"],
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_popup_components_stddev_fields"]: {
	__typename: "voyce_popup_components_stddev_fields",
	id?: number,
	layout?: number,
	sort?: number
};
	/** aggregate stddev_pop on columns */
["voyce_popup_components_stddev_pop_fields"]: {
	__typename: "voyce_popup_components_stddev_pop_fields",
	id?: number,
	layout?: number,
	sort?: number
};
	/** aggregate stddev_samp on columns */
["voyce_popup_components_stddev_samp_fields"]: {
	__typename: "voyce_popup_components_stddev_samp_fields",
	id?: number,
	layout?: number,
	sort?: number
};
	/** Streaming cursor of the table "voyce_popup_components" */
["voyce_popup_components_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_popup_components_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_popup_components_stream_cursor_value_input"]: {
		auth?: boolean,
	component?: string,
	created_at?: GraphQLTypes["timestamptz"],
	email_form?: boolean,
	exclusive_series?: boolean,
	followers?: boolean,
	id?: number,
	is_active?: boolean,
	klaviyo_list_id?: string,
	layout?: number,
	page?: string,
	props?: GraphQLTypes["json"],
	series_visibility?: boolean,
	sort?: GraphQLTypes["smallint"],
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_popup_components_sum_fields"]: {
	__typename: "voyce_popup_components_sum_fields",
	id?: number,
	layout?: number,
	sort?: GraphQLTypes["smallint"]
};
	/** update columns of table "voyce.popup_components" */
["voyce_popup_components_update_column"]: voyce_popup_components_update_column;
	["voyce_popup_components_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_popup_components_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_popup_components_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_popup_components_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_popup_components_var_pop_fields"]: {
	__typename: "voyce_popup_components_var_pop_fields",
	id?: number,
	layout?: number,
	sort?: number
};
	/** aggregate var_samp on columns */
["voyce_popup_components_var_samp_fields"]: {
	__typename: "voyce_popup_components_var_samp_fields",
	id?: number,
	layout?: number,
	sort?: number
};
	/** aggregate variance on columns */
["voyce_popup_components_variance_fields"]: {
	__typename: "voyce_popup_components_variance_fields",
	id?: number,
	layout?: number,
	sort?: number
};
	/** columns and relationships of "voyce.popup_emails" */
["voyce_popup_emails"]: {
	__typename: "voyce_popup_emails",
	/** An object relationship */
	component?: GraphQLTypes["voyce_popup_components"],
	created_at: GraphQLTypes["timestamptz"],
	email: string,
	gtag: string,
	/** An object relationship */
	guest_gtag?: GraphQLTypes["voyce_guest_gtags"],
	id: number,
	klaviyo_id?: string,
	page: string,
	popup_id: number,
	/** An object relationship */
	series?: GraphQLTypes["voyce_series"],
	series_id?: number,
	/** An object relationship */
	user?: GraphQLTypes["voyce_users"],
	user_id?: number
};
	/** aggregated selection of "voyce.popup_emails" */
["voyce_popup_emails_aggregate"]: {
	__typename: "voyce_popup_emails_aggregate",
	aggregate?: GraphQLTypes["voyce_popup_emails_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_popup_emails"]>
};
	["voyce_popup_emails_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_popup_emails_aggregate_bool_exp_count"]
};
	["voyce_popup_emails_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_popup_emails_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_popup_emails_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.popup_emails" */
["voyce_popup_emails_aggregate_fields"]: {
	__typename: "voyce_popup_emails_aggregate_fields",
	avg?: GraphQLTypes["voyce_popup_emails_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_popup_emails_max_fields"],
	min?: GraphQLTypes["voyce_popup_emails_min_fields"],
	stddev?: GraphQLTypes["voyce_popup_emails_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_popup_emails_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_popup_emails_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_popup_emails_sum_fields"],
	var_pop?: GraphQLTypes["voyce_popup_emails_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_popup_emails_var_samp_fields"],
	variance?: GraphQLTypes["voyce_popup_emails_variance_fields"]
};
	/** order by aggregate values of table "voyce.popup_emails" */
["voyce_popup_emails_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_popup_emails_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_popup_emails_max_order_by"],
	min?: GraphQLTypes["voyce_popup_emails_min_order_by"],
	stddev?: GraphQLTypes["voyce_popup_emails_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_popup_emails_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_popup_emails_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_popup_emails_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_popup_emails_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_popup_emails_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_popup_emails_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.popup_emails" */
["voyce_popup_emails_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_popup_emails_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_popup_emails_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_popup_emails_avg_fields"]: {
	__typename: "voyce_popup_emails_avg_fields",
	id?: number,
	popup_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	popup_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.popup_emails". All fields are combined with a logical 'AND'. */
["voyce_popup_emails_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_popup_emails_bool_exp"]>,
	_not?: GraphQLTypes["voyce_popup_emails_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_popup_emails_bool_exp"]>,
	component?: GraphQLTypes["voyce_popup_components_bool_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	email?: GraphQLTypes["String_comparison_exp"],
	gtag?: GraphQLTypes["String_comparison_exp"],
	guest_gtag?: GraphQLTypes["voyce_guest_gtags_bool_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	klaviyo_id?: GraphQLTypes["String_comparison_exp"],
	page?: GraphQLTypes["String_comparison_exp"],
	popup_id?: GraphQLTypes["Int_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.popup_emails" */
["voyce_popup_emails_constraint"]: voyce_popup_emails_constraint;
	/** input type for incrementing numeric columns in table "voyce.popup_emails" */
["voyce_popup_emails_inc_input"]: {
		id?: number,
	popup_id?: number,
	series_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.popup_emails" */
["voyce_popup_emails_insert_input"]: {
		component?: GraphQLTypes["voyce_popup_components_obj_rel_insert_input"],
	created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	gtag?: string,
	guest_gtag?: GraphQLTypes["voyce_guest_gtags_obj_rel_insert_input"],
	id?: number,
	klaviyo_id?: string,
	page?: string,
	popup_id?: number,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_popup_emails_max_fields"]: {
	__typename: "voyce_popup_emails_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	gtag?: string,
	id?: number,
	klaviyo_id?: string,
	page?: string,
	popup_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by max() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_max_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	email?: GraphQLTypes["order_by"],
	gtag?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	klaviyo_id?: GraphQLTypes["order_by"],
	page?: GraphQLTypes["order_by"],
	popup_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_popup_emails_min_fields"]: {
	__typename: "voyce_popup_emails_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	gtag?: string,
	id?: number,
	klaviyo_id?: string,
	page?: string,
	popup_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by min() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_min_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	email?: GraphQLTypes["order_by"],
	gtag?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	klaviyo_id?: GraphQLTypes["order_by"],
	page?: GraphQLTypes["order_by"],
	popup_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.popup_emails" */
["voyce_popup_emails_mutation_response"]: {
	__typename: "voyce_popup_emails_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_popup_emails"]>
};
	/** on_conflict condition type for table "voyce.popup_emails" */
["voyce_popup_emails_on_conflict"]: {
		constraint: GraphQLTypes["voyce_popup_emails_constraint"],
	update_columns: Array<GraphQLTypes["voyce_popup_emails_update_column"]>,
	where?: GraphQLTypes["voyce_popup_emails_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.popup_emails". */
["voyce_popup_emails_order_by"]: {
		component?: GraphQLTypes["voyce_popup_components_order_by"],
	created_at?: GraphQLTypes["order_by"],
	email?: GraphQLTypes["order_by"],
	gtag?: GraphQLTypes["order_by"],
	guest_gtag?: GraphQLTypes["voyce_guest_gtags_order_by"],
	id?: GraphQLTypes["order_by"],
	klaviyo_id?: GraphQLTypes["order_by"],
	page?: GraphQLTypes["order_by"],
	popup_id?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.popup_emails */
["voyce_popup_emails_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.popup_emails" */
["voyce_popup_emails_select_column"]: voyce_popup_emails_select_column;
	/** input type for updating data in table "voyce.popup_emails" */
["voyce_popup_emails_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	gtag?: string,
	id?: number,
	klaviyo_id?: string,
	page?: string,
	popup_id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_popup_emails_stddev_fields"]: {
	__typename: "voyce_popup_emails_stddev_fields",
	id?: number,
	popup_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	popup_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_popup_emails_stddev_pop_fields"]: {
	__typename: "voyce_popup_emails_stddev_pop_fields",
	id?: number,
	popup_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	popup_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_popup_emails_stddev_samp_fields"]: {
	__typename: "voyce_popup_emails_stddev_samp_fields",
	id?: number,
	popup_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	popup_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_popup_emails" */
["voyce_popup_emails_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_popup_emails_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_popup_emails_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	gtag?: string,
	id?: number,
	klaviyo_id?: string,
	page?: string,
	popup_id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_popup_emails_sum_fields"]: {
	__typename: "voyce_popup_emails_sum_fields",
	id?: number,
	popup_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	popup_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.popup_emails" */
["voyce_popup_emails_update_column"]: voyce_popup_emails_update_column;
	["voyce_popup_emails_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_popup_emails_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_popup_emails_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_popup_emails_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_popup_emails_var_pop_fields"]: {
	__typename: "voyce_popup_emails_var_pop_fields",
	id?: number,
	popup_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	popup_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_popup_emails_var_samp_fields"]: {
	__typename: "voyce_popup_emails_var_samp_fields",
	id?: number,
	popup_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	popup_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_popup_emails_variance_fields"]: {
	__typename: "voyce_popup_emails_variance_fields",
	id?: number,
	popup_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.popup_emails" */
["voyce_popup_emails_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	popup_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.posts" */
["voyce_posts"]: {
	__typename: "voyce_posts",
	comments_count?: number,
	created_at: GraphQLTypes["timestamp"],
	giphy_url?: string,
	id: number,
	image_url?: string,
	last_activity_date: GraphQLTypes["timestamp"],
	likes_count?: number,
	outdated: boolean,
	popularity: number,
	popularity_weekly: number,
	/** An array relationship */
	posts_comments: Array<GraphQLTypes["voyce_posts_comments"]>,
	/** An aggregate relationship */
	posts_comments_aggregate: GraphQLTypes["voyce_posts_comments_aggregate"],
	/** An array relationship */
	posts_reactions: Array<GraphQLTypes["voyce_posts_reactions"]>,
	/** An aggregate relationship */
	posts_reactions_aggregate: GraphQLTypes["voyce_posts_reactions_aggregate"],
	reactions_count?: number,
	spoiler: boolean,
	text?: string,
	total_popularity: number,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number,
	video_url?: string
};
	/** aggregated selection of "voyce.posts" */
["voyce_posts_aggregate"]: {
	__typename: "voyce_posts_aggregate",
	aggregate?: GraphQLTypes["voyce_posts_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_posts"]>
};
	["voyce_posts_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["voyce_posts_aggregate_bool_exp_bool_and"],
	bool_or?: GraphQLTypes["voyce_posts_aggregate_bool_exp_bool_or"],
	count?: GraphQLTypes["voyce_posts_aggregate_bool_exp_count"]
};
	["voyce_posts_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_posts_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_posts_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_posts_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_posts_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_posts_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_posts_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.posts" */
["voyce_posts_aggregate_fields"]: {
	__typename: "voyce_posts_aggregate_fields",
	avg?: GraphQLTypes["voyce_posts_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_posts_max_fields"],
	min?: GraphQLTypes["voyce_posts_min_fields"],
	stddev?: GraphQLTypes["voyce_posts_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_posts_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_posts_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_posts_sum_fields"],
	var_pop?: GraphQLTypes["voyce_posts_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_posts_var_samp_fields"],
	variance?: GraphQLTypes["voyce_posts_variance_fields"]
};
	/** order by aggregate values of table "voyce.posts" */
["voyce_posts_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_posts_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_posts_max_order_by"],
	min?: GraphQLTypes["voyce_posts_min_order_by"],
	stddev?: GraphQLTypes["voyce_posts_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_posts_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_posts_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_posts_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_posts_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_posts_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_posts_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.posts" */
["voyce_posts_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_posts_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_posts_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_posts_avg_fields"]: {
	__typename: "voyce_posts_avg_fields",
	comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.posts" */
["voyce_posts_avg_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.posts". All fields are combined with a logical 'AND'. */
["voyce_posts_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_posts_bool_exp"]>,
	_not?: GraphQLTypes["voyce_posts_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_posts_bool_exp"]>,
	comments_count?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	giphy_url?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	image_url?: GraphQLTypes["String_comparison_exp"],
	last_activity_date?: GraphQLTypes["timestamp_comparison_exp"],
	likes_count?: GraphQLTypes["Int_comparison_exp"],
	outdated?: GraphQLTypes["Boolean_comparison_exp"],
	popularity?: GraphQLTypes["Int_comparison_exp"],
	popularity_weekly?: GraphQLTypes["Int_comparison_exp"],
	posts_comments?: GraphQLTypes["voyce_posts_comments_bool_exp"],
	posts_comments_aggregate?: GraphQLTypes["voyce_posts_comments_aggregate_bool_exp"],
	posts_reactions?: GraphQLTypes["voyce_posts_reactions_bool_exp"],
	posts_reactions_aggregate?: GraphQLTypes["voyce_posts_reactions_aggregate_bool_exp"],
	reactions_count?: GraphQLTypes["Int_comparison_exp"],
	spoiler?: GraphQLTypes["Boolean_comparison_exp"],
	text?: GraphQLTypes["String_comparison_exp"],
	total_popularity?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	video_url?: GraphQLTypes["String_comparison_exp"]
};
	/** columns and relationships of "voyce.posts_comments" */
["voyce_posts_comments"]: {
	__typename: "voyce_posts_comments",
	animated_image?: string,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	image?: string,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	linked_id: number,
	parent_id?: number,
	/** An object relationship */
	post: GraphQLTypes["voyce_posts"],
	/** An object relationship */
	posts_comment?: GraphQLTypes["voyce_posts_comments"],
	/** An array relationship */
	posts_comments: Array<GraphQLTypes["voyce_posts_comments"]>,
	/** An aggregate relationship */
	posts_comments_aggregate: GraphQLTypes["voyce_posts_comments_aggregate"],
	/** An array relationship */
	posts_reactions: Array<GraphQLTypes["voyce_posts_reactions"]>,
	/** An aggregate relationship */
	posts_reactions_aggregate: GraphQLTypes["voyce_posts_reactions_aggregate"],
	reaction_count?: number,
	replies_count?: number,
	text: string,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.posts_comments" */
["voyce_posts_comments_aggregate"]: {
	__typename: "voyce_posts_comments_aggregate",
	aggregate?: GraphQLTypes["voyce_posts_comments_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_posts_comments"]>
};
	["voyce_posts_comments_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_posts_comments_aggregate_bool_exp_count"]
};
	["voyce_posts_comments_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_posts_comments_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_posts_comments_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.posts_comments" */
["voyce_posts_comments_aggregate_fields"]: {
	__typename: "voyce_posts_comments_aggregate_fields",
	avg?: GraphQLTypes["voyce_posts_comments_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_posts_comments_max_fields"],
	min?: GraphQLTypes["voyce_posts_comments_min_fields"],
	stddev?: GraphQLTypes["voyce_posts_comments_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_posts_comments_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_posts_comments_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_posts_comments_sum_fields"],
	var_pop?: GraphQLTypes["voyce_posts_comments_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_posts_comments_var_samp_fields"],
	variance?: GraphQLTypes["voyce_posts_comments_variance_fields"]
};
	/** order by aggregate values of table "voyce.posts_comments" */
["voyce_posts_comments_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_posts_comments_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_posts_comments_max_order_by"],
	min?: GraphQLTypes["voyce_posts_comments_min_order_by"],
	stddev?: GraphQLTypes["voyce_posts_comments_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_posts_comments_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_posts_comments_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_posts_comments_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_posts_comments_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_posts_comments_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_posts_comments_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.posts_comments" */
["voyce_posts_comments_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_posts_comments_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_posts_comments_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_posts_comments_avg_fields"]: {
	__typename: "voyce_posts_comments_avg_fields",
	id?: number,
	last_reaction_count?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.posts_comments". All fields are combined with a logical 'AND'. */
["voyce_posts_comments_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_posts_comments_bool_exp"]>,
	_not?: GraphQLTypes["voyce_posts_comments_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_posts_comments_bool_exp"]>,
	animated_image?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	image?: GraphQLTypes["String_comparison_exp"],
	last_reacted_at?: GraphQLTypes["timestamptz_comparison_exp"],
	last_reaction_count?: GraphQLTypes["Int_comparison_exp"],
	linked_id?: GraphQLTypes["Int_comparison_exp"],
	parent_id?: GraphQLTypes["Int_comparison_exp"],
	post?: GraphQLTypes["voyce_posts_bool_exp"],
	posts_comment?: GraphQLTypes["voyce_posts_comments_bool_exp"],
	posts_comments?: GraphQLTypes["voyce_posts_comments_bool_exp"],
	posts_comments_aggregate?: GraphQLTypes["voyce_posts_comments_aggregate_bool_exp"],
	posts_reactions?: GraphQLTypes["voyce_posts_reactions_bool_exp"],
	posts_reactions_aggregate?: GraphQLTypes["voyce_posts_reactions_aggregate_bool_exp"],
	reaction_count?: GraphQLTypes["Int_comparison_exp"],
	replies_count?: GraphQLTypes["Int_comparison_exp"],
	text?: GraphQLTypes["String_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.posts_comments" */
["voyce_posts_comments_constraint"]: voyce_posts_comments_constraint;
	/** input type for incrementing numeric columns in table "voyce.posts_comments" */
["voyce_posts_comments_inc_input"]: {
		id?: number,
	last_reaction_count?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.posts_comments" */
["voyce_posts_comments_insert_input"]: {
		animated_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	linked_id?: number,
	parent_id?: number,
	post?: GraphQLTypes["voyce_posts_obj_rel_insert_input"],
	posts_comment?: GraphQLTypes["voyce_posts_comments_obj_rel_insert_input"],
	posts_comments?: GraphQLTypes["voyce_posts_comments_arr_rel_insert_input"],
	posts_reactions?: GraphQLTypes["voyce_posts_reactions_arr_rel_insert_input"],
	reaction_count?: number,
	replies_count?: number,
	text?: string,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_posts_comments_max_fields"]: {
	__typename: "voyce_posts_comments_max_fields",
	animated_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	text?: string,
	user_id?: number
};
	/** order by max() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_max_order_by"]: {
		animated_image?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	last_reacted_at?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	text?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_posts_comments_min_fields"]: {
	__typename: "voyce_posts_comments_min_fields",
	animated_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	text?: string,
	user_id?: number
};
	/** order by min() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_min_order_by"]: {
		animated_image?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	last_reacted_at?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	text?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.posts_comments" */
["voyce_posts_comments_mutation_response"]: {
	__typename: "voyce_posts_comments_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_posts_comments"]>
};
	/** input type for inserting object relation for remote table "voyce.posts_comments" */
["voyce_posts_comments_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_posts_comments_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_posts_comments_on_conflict"]
};
	/** on_conflict condition type for table "voyce.posts_comments" */
["voyce_posts_comments_on_conflict"]: {
		constraint: GraphQLTypes["voyce_posts_comments_constraint"],
	update_columns: Array<GraphQLTypes["voyce_posts_comments_update_column"]>,
	where?: GraphQLTypes["voyce_posts_comments_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.posts_comments". */
["voyce_posts_comments_order_by"]: {
		animated_image?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	last_reacted_at?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	post?: GraphQLTypes["voyce_posts_order_by"],
	posts_comment?: GraphQLTypes["voyce_posts_comments_order_by"],
	posts_comments_aggregate?: GraphQLTypes["voyce_posts_comments_aggregate_order_by"],
	posts_reactions_aggregate?: GraphQLTypes["voyce_posts_reactions_aggregate_order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	text?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.posts_comments */
["voyce_posts_comments_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.posts_comments" */
["voyce_posts_comments_select_column"]: voyce_posts_comments_select_column;
	/** input type for updating data in table "voyce.posts_comments" */
["voyce_posts_comments_set_input"]: {
		animated_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	text?: string,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_posts_comments_stddev_fields"]: {
	__typename: "voyce_posts_comments_stddev_fields",
	id?: number,
	last_reaction_count?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_posts_comments_stddev_pop_fields"]: {
	__typename: "voyce_posts_comments_stddev_pop_fields",
	id?: number,
	last_reaction_count?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_posts_comments_stddev_samp_fields"]: {
	__typename: "voyce_posts_comments_stddev_samp_fields",
	id?: number,
	last_reaction_count?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_posts_comments" */
["voyce_posts_comments_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_posts_comments_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_posts_comments_stream_cursor_value_input"]: {
		animated_image?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	last_reacted_at?: GraphQLTypes["timestamptz"],
	last_reaction_count?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	text?: string,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_posts_comments_sum_fields"]: {
	__typename: "voyce_posts_comments_sum_fields",
	id?: number,
	last_reaction_count?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.posts_comments" */
["voyce_posts_comments_update_column"]: voyce_posts_comments_update_column;
	["voyce_posts_comments_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_posts_comments_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_posts_comments_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_posts_comments_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_posts_comments_var_pop_fields"]: {
	__typename: "voyce_posts_comments_var_pop_fields",
	id?: number,
	last_reaction_count?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_posts_comments_var_samp_fields"]: {
	__typename: "voyce_posts_comments_var_samp_fields",
	id?: number,
	last_reaction_count?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_posts_comments_variance_fields"]: {
	__typename: "voyce_posts_comments_variance_fields",
	id?: number,
	last_reaction_count?: number,
	linked_id?: number,
	parent_id?: number,
	reaction_count?: number,
	replies_count?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.posts_comments" */
["voyce_posts_comments_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	last_reaction_count?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	parent_id?: GraphQLTypes["order_by"],
	reaction_count?: GraphQLTypes["order_by"],
	replies_count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** unique or primary key constraints on table "voyce.posts" */
["voyce_posts_constraint"]: voyce_posts_constraint;
	/** input type for incrementing numeric columns in table "voyce.posts" */
["voyce_posts_inc_input"]: {
		comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.posts" */
["voyce_posts_insert_input"]: {
		comments_count?: number,
	created_at?: GraphQLTypes["timestamp"],
	giphy_url?: string,
	id?: number,
	image_url?: string,
	last_activity_date?: GraphQLTypes["timestamp"],
	likes_count?: number,
	outdated?: boolean,
	popularity?: number,
	popularity_weekly?: number,
	posts_comments?: GraphQLTypes["voyce_posts_comments_arr_rel_insert_input"],
	posts_reactions?: GraphQLTypes["voyce_posts_reactions_arr_rel_insert_input"],
	reactions_count?: number,
	spoiler?: boolean,
	text?: string,
	total_popularity?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number,
	video_url?: string
};
	/** aggregate max on columns */
["voyce_posts_max_fields"]: {
	__typename: "voyce_posts_max_fields",
	comments_count?: number,
	created_at?: GraphQLTypes["timestamp"],
	giphy_url?: string,
	id?: number,
	image_url?: string,
	last_activity_date?: GraphQLTypes["timestamp"],
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	text?: string,
	total_popularity?: number,
	user_id?: number,
	video_url?: string
};
	/** order by max() on columns of table "voyce.posts" */
["voyce_posts_max_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	giphy_url?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image_url?: GraphQLTypes["order_by"],
	last_activity_date?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	text?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	video_url?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_posts_min_fields"]: {
	__typename: "voyce_posts_min_fields",
	comments_count?: number,
	created_at?: GraphQLTypes["timestamp"],
	giphy_url?: string,
	id?: number,
	image_url?: string,
	last_activity_date?: GraphQLTypes["timestamp"],
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	text?: string,
	total_popularity?: number,
	user_id?: number,
	video_url?: string
};
	/** order by min() on columns of table "voyce.posts" */
["voyce_posts_min_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	giphy_url?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image_url?: GraphQLTypes["order_by"],
	last_activity_date?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	text?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	video_url?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.posts" */
["voyce_posts_mutation_response"]: {
	__typename: "voyce_posts_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_posts"]>
};
	/** input type for inserting object relation for remote table "voyce.posts" */
["voyce_posts_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_posts_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_posts_on_conflict"]
};
	/** on_conflict condition type for table "voyce.posts" */
["voyce_posts_on_conflict"]: {
		constraint: GraphQLTypes["voyce_posts_constraint"],
	update_columns: Array<GraphQLTypes["voyce_posts_update_column"]>,
	where?: GraphQLTypes["voyce_posts_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.posts". */
["voyce_posts_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	giphy_url?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image_url?: GraphQLTypes["order_by"],
	last_activity_date?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	outdated?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	posts_comments_aggregate?: GraphQLTypes["voyce_posts_comments_aggregate_order_by"],
	posts_reactions_aggregate?: GraphQLTypes["voyce_posts_reactions_aggregate_order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	spoiler?: GraphQLTypes["order_by"],
	text?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"],
	video_url?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.posts */
["voyce_posts_pk_columns_input"]: {
		id: number
};
	/** columns and relationships of "voyce.posts_reactions" */
["voyce_posts_reactions"]: {
	__typename: "voyce_posts_reactions",
	comment_id?: number,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	linked_entity_id: number,
	/** An object relationship */
	post: GraphQLTypes["voyce_posts"],
	/** An object relationship */
	posts_comment?: GraphQLTypes["voyce_posts_comments"],
	reaction: number,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.posts_reactions" */
["voyce_posts_reactions_aggregate"]: {
	__typename: "voyce_posts_reactions_aggregate",
	aggregate?: GraphQLTypes["voyce_posts_reactions_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_posts_reactions"]>
};
	["voyce_posts_reactions_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_posts_reactions_aggregate_bool_exp_count"]
};
	["voyce_posts_reactions_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_posts_reactions_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_posts_reactions_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.posts_reactions" */
["voyce_posts_reactions_aggregate_fields"]: {
	__typename: "voyce_posts_reactions_aggregate_fields",
	avg?: GraphQLTypes["voyce_posts_reactions_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_posts_reactions_max_fields"],
	min?: GraphQLTypes["voyce_posts_reactions_min_fields"],
	stddev?: GraphQLTypes["voyce_posts_reactions_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_posts_reactions_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_posts_reactions_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_posts_reactions_sum_fields"],
	var_pop?: GraphQLTypes["voyce_posts_reactions_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_posts_reactions_var_samp_fields"],
	variance?: GraphQLTypes["voyce_posts_reactions_variance_fields"]
};
	/** order by aggregate values of table "voyce.posts_reactions" */
["voyce_posts_reactions_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_posts_reactions_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_posts_reactions_max_order_by"],
	min?: GraphQLTypes["voyce_posts_reactions_min_order_by"],
	stddev?: GraphQLTypes["voyce_posts_reactions_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_posts_reactions_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_posts_reactions_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_posts_reactions_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_posts_reactions_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_posts_reactions_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_posts_reactions_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.posts_reactions" */
["voyce_posts_reactions_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_posts_reactions_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_posts_reactions_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_posts_reactions_avg_fields"]: {
	__typename: "voyce_posts_reactions_avg_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_avg_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.posts_reactions". All fields are combined with a logical 'AND'. */
["voyce_posts_reactions_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_posts_reactions_bool_exp"]>,
	_not?: GraphQLTypes["voyce_posts_reactions_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_posts_reactions_bool_exp"]>,
	comment_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	linked_entity_id?: GraphQLTypes["Int_comparison_exp"],
	post?: GraphQLTypes["voyce_posts_bool_exp"],
	posts_comment?: GraphQLTypes["voyce_posts_comments_bool_exp"],
	reaction?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.posts_reactions" */
["voyce_posts_reactions_constraint"]: voyce_posts_reactions_constraint;
	/** input type for incrementing numeric columns in table "voyce.posts_reactions" */
["voyce_posts_reactions_inc_input"]: {
		comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.posts_reactions" */
["voyce_posts_reactions_insert_input"]: {
		comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	post?: GraphQLTypes["voyce_posts_obj_rel_insert_input"],
	posts_comment?: GraphQLTypes["voyce_posts_comments_obj_rel_insert_input"],
	reaction?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_posts_reactions_max_fields"]: {
	__typename: "voyce_posts_reactions_max_fields",
	comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by max() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_max_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_posts_reactions_min_fields"]: {
	__typename: "voyce_posts_reactions_min_fields",
	comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by min() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_min_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.posts_reactions" */
["voyce_posts_reactions_mutation_response"]: {
	__typename: "voyce_posts_reactions_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_posts_reactions"]>
};
	/** on_conflict condition type for table "voyce.posts_reactions" */
["voyce_posts_reactions_on_conflict"]: {
		constraint: GraphQLTypes["voyce_posts_reactions_constraint"],
	update_columns: Array<GraphQLTypes["voyce_posts_reactions_update_column"]>,
	where?: GraphQLTypes["voyce_posts_reactions_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.posts_reactions". */
["voyce_posts_reactions_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	post?: GraphQLTypes["voyce_posts_order_by"],
	posts_comment?: GraphQLTypes["voyce_posts_comments_order_by"],
	reaction?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.posts_reactions */
["voyce_posts_reactions_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_select_column"]: voyce_posts_reactions_select_column;
	/** input type for updating data in table "voyce.posts_reactions" */
["voyce_posts_reactions_set_input"]: {
		comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_posts_reactions_stddev_fields"]: {
	__typename: "voyce_posts_reactions_stddev_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_stddev_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_posts_reactions_stddev_pop_fields"]: {
	__typename: "voyce_posts_reactions_stddev_pop_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_stddev_pop_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_posts_reactions_stddev_samp_fields"]: {
	__typename: "voyce_posts_reactions_stddev_samp_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_stddev_samp_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_posts_reactions" */
["voyce_posts_reactions_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_posts_reactions_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_posts_reactions_stream_cursor_value_input"]: {
		comment_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_posts_reactions_sum_fields"]: {
	__typename: "voyce_posts_reactions_sum_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_sum_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_update_column"]: voyce_posts_reactions_update_column;
	["voyce_posts_reactions_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_posts_reactions_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_posts_reactions_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_posts_reactions_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_posts_reactions_var_pop_fields"]: {
	__typename: "voyce_posts_reactions_var_pop_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_var_pop_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_posts_reactions_var_samp_fields"]: {
	__typename: "voyce_posts_reactions_var_samp_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_var_samp_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_posts_reactions_variance_fields"]: {
	__typename: "voyce_posts_reactions_variance_fields",
	comment_id?: number,
	id?: number,
	linked_entity_id?: number,
	reaction?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.posts_reactions" */
["voyce_posts_reactions_variance_order_by"]: {
		comment_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_entity_id?: GraphQLTypes["order_by"],
	reaction?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.posts" */
["voyce_posts_select_column"]: voyce_posts_select_column;
	/** select "voyce_posts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.posts" */
["voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_and_arguments_columns"]: voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_posts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.posts" */
["voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_or_arguments_columns"]: voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.posts" */
["voyce_posts_set_input"]: {
		comments_count?: number,
	created_at?: GraphQLTypes["timestamp"],
	giphy_url?: string,
	id?: number,
	image_url?: string,
	last_activity_date?: GraphQLTypes["timestamp"],
	likes_count?: number,
	outdated?: boolean,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	spoiler?: boolean,
	text?: string,
	total_popularity?: number,
	user_id?: number,
	video_url?: string
};
	/** aggregate stddev on columns */
["voyce_posts_stddev_fields"]: {
	__typename: "voyce_posts_stddev_fields",
	comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.posts" */
["voyce_posts_stddev_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_posts_stddev_pop_fields"]: {
	__typename: "voyce_posts_stddev_pop_fields",
	comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.posts" */
["voyce_posts_stddev_pop_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_posts_stddev_samp_fields"]: {
	__typename: "voyce_posts_stddev_samp_fields",
	comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.posts" */
["voyce_posts_stddev_samp_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_posts" */
["voyce_posts_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_posts_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_posts_stream_cursor_value_input"]: {
		comments_count?: number,
	created_at?: GraphQLTypes["timestamp"],
	giphy_url?: string,
	id?: number,
	image_url?: string,
	last_activity_date?: GraphQLTypes["timestamp"],
	likes_count?: number,
	outdated?: boolean,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	spoiler?: boolean,
	text?: string,
	total_popularity?: number,
	user_id?: number,
	video_url?: string
};
	/** aggregate sum on columns */
["voyce_posts_sum_fields"]: {
	__typename: "voyce_posts_sum_fields",
	comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.posts" */
["voyce_posts_sum_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.posts" */
["voyce_posts_update_column"]: voyce_posts_update_column;
	["voyce_posts_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_posts_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_posts_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_posts_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_posts_var_pop_fields"]: {
	__typename: "voyce_posts_var_pop_fields",
	comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.posts" */
["voyce_posts_var_pop_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_posts_var_samp_fields"]: {
	__typename: "voyce_posts_var_samp_fields",
	comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.posts" */
["voyce_posts_var_samp_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_posts_variance_fields"]: {
	__typename: "voyce_posts_variance_fields",
	comments_count?: number,
	id?: number,
	likes_count?: number,
	popularity?: number,
	popularity_weekly?: number,
	reactions_count?: number,
	total_popularity?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.posts" */
["voyce_posts_variance_order_by"]: {
		comments_count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	likes_count?: GraphQLTypes["order_by"],
	popularity?: GraphQLTypes["order_by"],
	popularity_weekly?: GraphQLTypes["order_by"],
	reactions_count?: GraphQLTypes["order_by"],
	total_popularity?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.quest_difficulties" */
["voyce_quest_difficulties"]: {
	__typename: "voyce_quest_difficulties",
	coins: number,
	created_at: GraphQLTypes["timestamptz"],
	experience: number,
	id: number,
	title: string,
	updated_at: GraphQLTypes["timestamptz"],
	vox: number
};
	/** aggregated selection of "voyce.quest_difficulties" */
["voyce_quest_difficulties_aggregate"]: {
	__typename: "voyce_quest_difficulties_aggregate",
	aggregate?: GraphQLTypes["voyce_quest_difficulties_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_quest_difficulties"]>
};
	/** aggregate fields of "voyce.quest_difficulties" */
["voyce_quest_difficulties_aggregate_fields"]: {
	__typename: "voyce_quest_difficulties_aggregate_fields",
	avg?: GraphQLTypes["voyce_quest_difficulties_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_quest_difficulties_max_fields"],
	min?: GraphQLTypes["voyce_quest_difficulties_min_fields"],
	stddev?: GraphQLTypes["voyce_quest_difficulties_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_quest_difficulties_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_quest_difficulties_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_quest_difficulties_sum_fields"],
	var_pop?: GraphQLTypes["voyce_quest_difficulties_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_quest_difficulties_var_samp_fields"],
	variance?: GraphQLTypes["voyce_quest_difficulties_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_quest_difficulties_avg_fields"]: {
	__typename: "voyce_quest_difficulties_avg_fields",
	coins?: number,
	experience?: number,
	id?: number,
	vox?: number
};
	/** Boolean expression to filter rows from the table "voyce.quest_difficulties". All fields are combined with a logical 'AND'. */
["voyce_quest_difficulties_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_quest_difficulties_bool_exp"]>,
	_not?: GraphQLTypes["voyce_quest_difficulties_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_quest_difficulties_bool_exp"]>,
	coins?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	experience?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	vox?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.quest_difficulties" */
["voyce_quest_difficulties_constraint"]: voyce_quest_difficulties_constraint;
	/** input type for incrementing numeric columns in table "voyce.quest_difficulties" */
["voyce_quest_difficulties_inc_input"]: {
		coins?: number,
	experience?: number,
	id?: number,
	vox?: number
};
	/** input type for inserting data into table "voyce.quest_difficulties" */
["voyce_quest_difficulties_insert_input"]: {
		coins?: number,
	created_at?: GraphQLTypes["timestamptz"],
	experience?: number,
	id?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	vox?: number
};
	/** aggregate max on columns */
["voyce_quest_difficulties_max_fields"]: {
	__typename: "voyce_quest_difficulties_max_fields",
	coins?: number,
	created_at?: GraphQLTypes["timestamptz"],
	experience?: number,
	id?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	vox?: number
};
	/** aggregate min on columns */
["voyce_quest_difficulties_min_fields"]: {
	__typename: "voyce_quest_difficulties_min_fields",
	coins?: number,
	created_at?: GraphQLTypes["timestamptz"],
	experience?: number,
	id?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	vox?: number
};
	/** response of any mutation on the table "voyce.quest_difficulties" */
["voyce_quest_difficulties_mutation_response"]: {
	__typename: "voyce_quest_difficulties_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_quest_difficulties"]>
};
	/** input type for inserting object relation for remote table "voyce.quest_difficulties" */
["voyce_quest_difficulties_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_quest_difficulties_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_quest_difficulties_on_conflict"]
};
	/** on_conflict condition type for table "voyce.quest_difficulties" */
["voyce_quest_difficulties_on_conflict"]: {
		constraint: GraphQLTypes["voyce_quest_difficulties_constraint"],
	update_columns: Array<GraphQLTypes["voyce_quest_difficulties_update_column"]>,
	where?: GraphQLTypes["voyce_quest_difficulties_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.quest_difficulties". */
["voyce_quest_difficulties_order_by"]: {
		coins?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	experience?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	vox?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.quest_difficulties */
["voyce_quest_difficulties_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.quest_difficulties" */
["voyce_quest_difficulties_select_column"]: voyce_quest_difficulties_select_column;
	/** input type for updating data in table "voyce.quest_difficulties" */
["voyce_quest_difficulties_set_input"]: {
		coins?: number,
	created_at?: GraphQLTypes["timestamptz"],
	experience?: number,
	id?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	vox?: number
};
	/** aggregate stddev on columns */
["voyce_quest_difficulties_stddev_fields"]: {
	__typename: "voyce_quest_difficulties_stddev_fields",
	coins?: number,
	experience?: number,
	id?: number,
	vox?: number
};
	/** aggregate stddev_pop on columns */
["voyce_quest_difficulties_stddev_pop_fields"]: {
	__typename: "voyce_quest_difficulties_stddev_pop_fields",
	coins?: number,
	experience?: number,
	id?: number,
	vox?: number
};
	/** aggregate stddev_samp on columns */
["voyce_quest_difficulties_stddev_samp_fields"]: {
	__typename: "voyce_quest_difficulties_stddev_samp_fields",
	coins?: number,
	experience?: number,
	id?: number,
	vox?: number
};
	/** Streaming cursor of the table "voyce_quest_difficulties" */
["voyce_quest_difficulties_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_quest_difficulties_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_quest_difficulties_stream_cursor_value_input"]: {
		coins?: number,
	created_at?: GraphQLTypes["timestamptz"],
	experience?: number,
	id?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	vox?: number
};
	/** aggregate sum on columns */
["voyce_quest_difficulties_sum_fields"]: {
	__typename: "voyce_quest_difficulties_sum_fields",
	coins?: number,
	experience?: number,
	id?: number,
	vox?: number
};
	/** update columns of table "voyce.quest_difficulties" */
["voyce_quest_difficulties_update_column"]: voyce_quest_difficulties_update_column;
	["voyce_quest_difficulties_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_quest_difficulties_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_quest_difficulties_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_quest_difficulties_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_quest_difficulties_var_pop_fields"]: {
	__typename: "voyce_quest_difficulties_var_pop_fields",
	coins?: number,
	experience?: number,
	id?: number,
	vox?: number
};
	/** aggregate var_samp on columns */
["voyce_quest_difficulties_var_samp_fields"]: {
	__typename: "voyce_quest_difficulties_var_samp_fields",
	coins?: number,
	experience?: number,
	id?: number,
	vox?: number
};
	/** aggregate variance on columns */
["voyce_quest_difficulties_variance_fields"]: {
	__typename: "voyce_quest_difficulties_variance_fields",
	coins?: number,
	experience?: number,
	id?: number,
	vox?: number
};
	/** columns and relationships of "voyce.quests" */
["voyce_quests"]: {
	__typename: "voyce_quests",
	created_at: GraphQLTypes["timestamptz"],
	description?: string,
	/** An object relationship */
	difficulty?: GraphQLTypes["voyce_quest_difficulties"],
	difficulty_id: number,
	/** e.g: { chapters_viewed: 3, chapter_completion: 0.5 } */
	event_data?: GraphQLTypes["json"],
	/** SubscribeSeries, DailyCheckIn, etc.. */
	event_trigger: string,
	experience_points?: number,
	/** once, daily, weekly */
	frequency: string,
	id: number,
	/** Qualified, Started, Completed */
	initial_status: string,
	notif_message?: string,
	/** e.g: { type: coin, value: 100 } */
	reward?: GraphQLTypes["json"],
	/** e.g. { page: shop, is_rewarded: false } */
	start_data?: GraphQLTypes["json"],
	/** auto, manual */
	start_type: string,
	tag: string,
	title: string,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.quests" */
["voyce_quests_aggregate"]: {
	__typename: "voyce_quests_aggregate",
	aggregate?: GraphQLTypes["voyce_quests_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_quests"]>
};
	/** aggregate fields of "voyce.quests" */
["voyce_quests_aggregate_fields"]: {
	__typename: "voyce_quests_aggregate_fields",
	avg?: GraphQLTypes["voyce_quests_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_quests_max_fields"],
	min?: GraphQLTypes["voyce_quests_min_fields"],
	stddev?: GraphQLTypes["voyce_quests_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_quests_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_quests_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_quests_sum_fields"],
	var_pop?: GraphQLTypes["voyce_quests_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_quests_var_samp_fields"],
	variance?: GraphQLTypes["voyce_quests_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_quests_avg_fields"]: {
	__typename: "voyce_quests_avg_fields",
	difficulty_id?: number,
	experience_points?: number,
	id?: number
};
	/** Boolean expression to filter rows from the table "voyce.quests". All fields are combined with a logical 'AND'. */
["voyce_quests_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_quests_bool_exp"]>,
	_not?: GraphQLTypes["voyce_quests_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_quests_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	description?: GraphQLTypes["String_comparison_exp"],
	difficulty?: GraphQLTypes["voyce_quest_difficulties_bool_exp"],
	difficulty_id?: GraphQLTypes["Int_comparison_exp"],
	event_data?: GraphQLTypes["json_comparison_exp"],
	event_trigger?: GraphQLTypes["String_comparison_exp"],
	experience_points?: GraphQLTypes["Int_comparison_exp"],
	frequency?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	initial_status?: GraphQLTypes["String_comparison_exp"],
	notif_message?: GraphQLTypes["String_comparison_exp"],
	reward?: GraphQLTypes["json_comparison_exp"],
	start_data?: GraphQLTypes["json_comparison_exp"],
	start_type?: GraphQLTypes["String_comparison_exp"],
	tag?: GraphQLTypes["String_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.quests" */
["voyce_quests_constraint"]: voyce_quests_constraint;
	/** input type for incrementing numeric columns in table "voyce.quests" */
["voyce_quests_inc_input"]: {
		difficulty_id?: number,
	experience_points?: number,
	id?: number
};
	/** input type for inserting data into table "voyce.quests" */
["voyce_quests_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	difficulty?: GraphQLTypes["voyce_quest_difficulties_obj_rel_insert_input"],
	difficulty_id?: number,
	/** e.g: { chapters_viewed: 3, chapter_completion: 0.5 } */
	event_data?: GraphQLTypes["json"],
	/** SubscribeSeries, DailyCheckIn, etc.. */
	event_trigger?: string,
	experience_points?: number,
	/** once, daily, weekly */
	frequency?: string,
	id?: number,
	/** Qualified, Started, Completed */
	initial_status?: string,
	notif_message?: string,
	/** e.g: { type: coin, value: 100 } */
	reward?: GraphQLTypes["json"],
	/** e.g. { page: shop, is_rewarded: false } */
	start_data?: GraphQLTypes["json"],
	/** auto, manual */
	start_type?: string,
	tag?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_quests_max_fields"]: {
	__typename: "voyce_quests_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	difficulty_id?: number,
	/** SubscribeSeries, DailyCheckIn, etc.. */
	event_trigger?: string,
	experience_points?: number,
	/** once, daily, weekly */
	frequency?: string,
	id?: number,
	/** Qualified, Started, Completed */
	initial_status?: string,
	notif_message?: string,
	/** auto, manual */
	start_type?: string,
	tag?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_quests_min_fields"]: {
	__typename: "voyce_quests_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	difficulty_id?: number,
	/** SubscribeSeries, DailyCheckIn, etc.. */
	event_trigger?: string,
	experience_points?: number,
	/** once, daily, weekly */
	frequency?: string,
	id?: number,
	/** Qualified, Started, Completed */
	initial_status?: string,
	notif_message?: string,
	/** auto, manual */
	start_type?: string,
	tag?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.quests" */
["voyce_quests_mutation_response"]: {
	__typename: "voyce_quests_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_quests"]>
};
	/** input type for inserting object relation for remote table "voyce.quests" */
["voyce_quests_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_quests_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_quests_on_conflict"]
};
	/** columns and relationships of "voyce.quests_old" */
["voyce_quests_old"]: {
	__typename: "voyce_quests_old",
	end_date?: GraphQLTypes["timestamp"],
	id: number,
	image?: string,
	recurrency?: number,
	recurrent?: boolean,
	/** An array relationship */
	rewards_quests: Array<GraphQLTypes["voyce_rewards_quest"]>,
	/** An aggregate relationship */
	rewards_quests_aggregate: GraphQLTypes["voyce_rewards_quest_aggregate"],
	start_date: GraphQLTypes["timestamp"],
	steps?: number,
	title?: string,
	/** An array relationship */
	user_quests: Array<GraphQLTypes["voyce_user_quests_old"]>,
	/** An aggregate relationship */
	user_quests_aggregate: GraphQLTypes["voyce_user_quests_old_aggregate"]
};
	/** aggregated selection of "voyce.quests_old" */
["voyce_quests_old_aggregate"]: {
	__typename: "voyce_quests_old_aggregate",
	aggregate?: GraphQLTypes["voyce_quests_old_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_quests_old"]>
};
	/** aggregate fields of "voyce.quests_old" */
["voyce_quests_old_aggregate_fields"]: {
	__typename: "voyce_quests_old_aggregate_fields",
	avg?: GraphQLTypes["voyce_quests_old_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_quests_old_max_fields"],
	min?: GraphQLTypes["voyce_quests_old_min_fields"],
	stddev?: GraphQLTypes["voyce_quests_old_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_quests_old_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_quests_old_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_quests_old_sum_fields"],
	var_pop?: GraphQLTypes["voyce_quests_old_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_quests_old_var_samp_fields"],
	variance?: GraphQLTypes["voyce_quests_old_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_quests_old_avg_fields"]: {
	__typename: "voyce_quests_old_avg_fields",
	id?: number,
	recurrency?: number,
	steps?: number
};
	/** Boolean expression to filter rows from the table "voyce.quests_old". All fields are combined with a logical 'AND'. */
["voyce_quests_old_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_quests_old_bool_exp"]>,
	_not?: GraphQLTypes["voyce_quests_old_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_quests_old_bool_exp"]>,
	end_date?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	image?: GraphQLTypes["String_comparison_exp"],
	recurrency?: GraphQLTypes["Int_comparison_exp"],
	recurrent?: GraphQLTypes["Boolean_comparison_exp"],
	rewards_quests?: GraphQLTypes["voyce_rewards_quest_bool_exp"],
	rewards_quests_aggregate?: GraphQLTypes["voyce_rewards_quest_aggregate_bool_exp"],
	start_date?: GraphQLTypes["timestamp_comparison_exp"],
	steps?: GraphQLTypes["Int_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	user_quests?: GraphQLTypes["voyce_user_quests_old_bool_exp"],
	user_quests_aggregate?: GraphQLTypes["voyce_user_quests_old_aggregate_bool_exp"]
};
	/** unique or primary key constraints on table "voyce.quests_old" */
["voyce_quests_old_constraint"]: voyce_quests_old_constraint;
	/** input type for incrementing numeric columns in table "voyce.quests_old" */
["voyce_quests_old_inc_input"]: {
		id?: number,
	recurrency?: number,
	steps?: number
};
	/** input type for inserting data into table "voyce.quests_old" */
["voyce_quests_old_insert_input"]: {
		end_date?: GraphQLTypes["timestamp"],
	id?: number,
	image?: string,
	recurrency?: number,
	recurrent?: boolean,
	rewards_quests?: GraphQLTypes["voyce_rewards_quest_arr_rel_insert_input"],
	start_date?: GraphQLTypes["timestamp"],
	steps?: number,
	title?: string,
	user_quests?: GraphQLTypes["voyce_user_quests_old_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["voyce_quests_old_max_fields"]: {
	__typename: "voyce_quests_old_max_fields",
	end_date?: GraphQLTypes["timestamp"],
	id?: number,
	image?: string,
	recurrency?: number,
	start_date?: GraphQLTypes["timestamp"],
	steps?: number,
	title?: string
};
	/** aggregate min on columns */
["voyce_quests_old_min_fields"]: {
	__typename: "voyce_quests_old_min_fields",
	end_date?: GraphQLTypes["timestamp"],
	id?: number,
	image?: string,
	recurrency?: number,
	start_date?: GraphQLTypes["timestamp"],
	steps?: number,
	title?: string
};
	/** response of any mutation on the table "voyce.quests_old" */
["voyce_quests_old_mutation_response"]: {
	__typename: "voyce_quests_old_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_quests_old"]>
};
	/** input type for inserting object relation for remote table "voyce.quests_old" */
["voyce_quests_old_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_quests_old_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_quests_old_on_conflict"]
};
	/** on_conflict condition type for table "voyce.quests_old" */
["voyce_quests_old_on_conflict"]: {
		constraint: GraphQLTypes["voyce_quests_old_constraint"],
	update_columns: Array<GraphQLTypes["voyce_quests_old_update_column"]>,
	where?: GraphQLTypes["voyce_quests_old_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.quests_old". */
["voyce_quests_old_order_by"]: {
		end_date?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	recurrency?: GraphQLTypes["order_by"],
	recurrent?: GraphQLTypes["order_by"],
	rewards_quests_aggregate?: GraphQLTypes["voyce_rewards_quest_aggregate_order_by"],
	start_date?: GraphQLTypes["order_by"],
	steps?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	user_quests_aggregate?: GraphQLTypes["voyce_user_quests_old_aggregate_order_by"]
};
	/** primary key columns input for table: voyce.quests_old */
["voyce_quests_old_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.quests_old" */
["voyce_quests_old_select_column"]: voyce_quests_old_select_column;
	/** input type for updating data in table "voyce.quests_old" */
["voyce_quests_old_set_input"]: {
		end_date?: GraphQLTypes["timestamp"],
	id?: number,
	image?: string,
	recurrency?: number,
	recurrent?: boolean,
	start_date?: GraphQLTypes["timestamp"],
	steps?: number,
	title?: string
};
	/** aggregate stddev on columns */
["voyce_quests_old_stddev_fields"]: {
	__typename: "voyce_quests_old_stddev_fields",
	id?: number,
	recurrency?: number,
	steps?: number
};
	/** aggregate stddev_pop on columns */
["voyce_quests_old_stddev_pop_fields"]: {
	__typename: "voyce_quests_old_stddev_pop_fields",
	id?: number,
	recurrency?: number,
	steps?: number
};
	/** aggregate stddev_samp on columns */
["voyce_quests_old_stddev_samp_fields"]: {
	__typename: "voyce_quests_old_stddev_samp_fields",
	id?: number,
	recurrency?: number,
	steps?: number
};
	/** Streaming cursor of the table "voyce_quests_old" */
["voyce_quests_old_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_quests_old_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_quests_old_stream_cursor_value_input"]: {
		end_date?: GraphQLTypes["timestamp"],
	id?: number,
	image?: string,
	recurrency?: number,
	recurrent?: boolean,
	start_date?: GraphQLTypes["timestamp"],
	steps?: number,
	title?: string
};
	/** aggregate sum on columns */
["voyce_quests_old_sum_fields"]: {
	__typename: "voyce_quests_old_sum_fields",
	id?: number,
	recurrency?: number,
	steps?: number
};
	/** update columns of table "voyce.quests_old" */
["voyce_quests_old_update_column"]: voyce_quests_old_update_column;
	["voyce_quests_old_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_quests_old_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_quests_old_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_quests_old_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_quests_old_var_pop_fields"]: {
	__typename: "voyce_quests_old_var_pop_fields",
	id?: number,
	recurrency?: number,
	steps?: number
};
	/** aggregate var_samp on columns */
["voyce_quests_old_var_samp_fields"]: {
	__typename: "voyce_quests_old_var_samp_fields",
	id?: number,
	recurrency?: number,
	steps?: number
};
	/** aggregate variance on columns */
["voyce_quests_old_variance_fields"]: {
	__typename: "voyce_quests_old_variance_fields",
	id?: number,
	recurrency?: number,
	steps?: number
};
	/** on_conflict condition type for table "voyce.quests" */
["voyce_quests_on_conflict"]: {
		constraint: GraphQLTypes["voyce_quests_constraint"],
	update_columns: Array<GraphQLTypes["voyce_quests_update_column"]>,
	where?: GraphQLTypes["voyce_quests_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.quests". */
["voyce_quests_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	difficulty?: GraphQLTypes["voyce_quest_difficulties_order_by"],
	difficulty_id?: GraphQLTypes["order_by"],
	event_data?: GraphQLTypes["order_by"],
	event_trigger?: GraphQLTypes["order_by"],
	experience_points?: GraphQLTypes["order_by"],
	frequency?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	initial_status?: GraphQLTypes["order_by"],
	notif_message?: GraphQLTypes["order_by"],
	reward?: GraphQLTypes["order_by"],
	start_data?: GraphQLTypes["order_by"],
	start_type?: GraphQLTypes["order_by"],
	tag?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.quests */
["voyce_quests_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.quests" */
["voyce_quests_select_column"]: voyce_quests_select_column;
	/** input type for updating data in table "voyce.quests" */
["voyce_quests_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	difficulty_id?: number,
	/** e.g: { chapters_viewed: 3, chapter_completion: 0.5 } */
	event_data?: GraphQLTypes["json"],
	/** SubscribeSeries, DailyCheckIn, etc.. */
	event_trigger?: string,
	experience_points?: number,
	/** once, daily, weekly */
	frequency?: string,
	id?: number,
	/** Qualified, Started, Completed */
	initial_status?: string,
	notif_message?: string,
	/** e.g: { type: coin, value: 100 } */
	reward?: GraphQLTypes["json"],
	/** e.g. { page: shop, is_rewarded: false } */
	start_data?: GraphQLTypes["json"],
	/** auto, manual */
	start_type?: string,
	tag?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_quests_stddev_fields"]: {
	__typename: "voyce_quests_stddev_fields",
	difficulty_id?: number,
	experience_points?: number,
	id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_quests_stddev_pop_fields"]: {
	__typename: "voyce_quests_stddev_pop_fields",
	difficulty_id?: number,
	experience_points?: number,
	id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_quests_stddev_samp_fields"]: {
	__typename: "voyce_quests_stddev_samp_fields",
	difficulty_id?: number,
	experience_points?: number,
	id?: number
};
	/** Streaming cursor of the table "voyce_quests" */
["voyce_quests_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_quests_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_quests_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	difficulty_id?: number,
	/** e.g: { chapters_viewed: 3, chapter_completion: 0.5 } */
	event_data?: GraphQLTypes["json"],
	/** SubscribeSeries, DailyCheckIn, etc.. */
	event_trigger?: string,
	experience_points?: number,
	/** once, daily, weekly */
	frequency?: string,
	id?: number,
	/** Qualified, Started, Completed */
	initial_status?: string,
	notif_message?: string,
	/** e.g: { type: coin, value: 100 } */
	reward?: GraphQLTypes["json"],
	/** e.g. { page: shop, is_rewarded: false } */
	start_data?: GraphQLTypes["json"],
	/** auto, manual */
	start_type?: string,
	tag?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_quests_sum_fields"]: {
	__typename: "voyce_quests_sum_fields",
	difficulty_id?: number,
	experience_points?: number,
	id?: number
};
	/** update columns of table "voyce.quests" */
["voyce_quests_update_column"]: voyce_quests_update_column;
	["voyce_quests_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_quests_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_quests_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_quests_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_quests_var_pop_fields"]: {
	__typename: "voyce_quests_var_pop_fields",
	difficulty_id?: number,
	experience_points?: number,
	id?: number
};
	/** aggregate var_samp on columns */
["voyce_quests_var_samp_fields"]: {
	__typename: "voyce_quests_var_samp_fields",
	difficulty_id?: number,
	experience_points?: number,
	id?: number
};
	/** aggregate variance on columns */
["voyce_quests_variance_fields"]: {
	__typename: "voyce_quests_variance_fields",
	difficulty_id?: number,
	experience_points?: number,
	id?: number
};
	/** columns and relationships of "voyce.recommended_series" */
["voyce_recommended_series"]: {
	__typename: "voyce_recommended_series",
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	notification_image?: string,
	notification_status: string,
	notification_text: string,
	notification_title: string,
	/** An object relationship */
	series: GraphQLTypes["voyce_series"],
	series_id: number
};
	/** aggregated selection of "voyce.recommended_series" */
["voyce_recommended_series_aggregate"]: {
	__typename: "voyce_recommended_series_aggregate",
	aggregate?: GraphQLTypes["voyce_recommended_series_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_recommended_series"]>
};
	/** aggregate fields of "voyce.recommended_series" */
["voyce_recommended_series_aggregate_fields"]: {
	__typename: "voyce_recommended_series_aggregate_fields",
	avg?: GraphQLTypes["voyce_recommended_series_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_recommended_series_max_fields"],
	min?: GraphQLTypes["voyce_recommended_series_min_fields"],
	stddev?: GraphQLTypes["voyce_recommended_series_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_recommended_series_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_recommended_series_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_recommended_series_sum_fields"],
	var_pop?: GraphQLTypes["voyce_recommended_series_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_recommended_series_var_samp_fields"],
	variance?: GraphQLTypes["voyce_recommended_series_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_recommended_series_avg_fields"]: {
	__typename: "voyce_recommended_series_avg_fields",
	id?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.recommended_series". All fields are combined with a logical 'AND'. */
["voyce_recommended_series_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_recommended_series_bool_exp"]>,
	_not?: GraphQLTypes["voyce_recommended_series_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_recommended_series_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	notification_image?: GraphQLTypes["String_comparison_exp"],
	notification_status?: GraphQLTypes["String_comparison_exp"],
	notification_text?: GraphQLTypes["String_comparison_exp"],
	notification_title?: GraphQLTypes["String_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.recommended_series" */
["voyce_recommended_series_constraint"]: voyce_recommended_series_constraint;
	/** input type for incrementing numeric columns in table "voyce.recommended_series" */
["voyce_recommended_series_inc_input"]: {
		id?: number,
	series_id?: number
};
	/** input type for inserting data into table "voyce.recommended_series" */
["voyce_recommended_series_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	notification_image?: string,
	notification_status?: string,
	notification_text?: string,
	notification_title?: string,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number
};
	/** aggregate max on columns */
["voyce_recommended_series_max_fields"]: {
	__typename: "voyce_recommended_series_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	notification_image?: string,
	notification_status?: string,
	notification_text?: string,
	notification_title?: string,
	series_id?: number
};
	/** aggregate min on columns */
["voyce_recommended_series_min_fields"]: {
	__typename: "voyce_recommended_series_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	notification_image?: string,
	notification_status?: string,
	notification_text?: string,
	notification_title?: string,
	series_id?: number
};
	/** response of any mutation on the table "voyce.recommended_series" */
["voyce_recommended_series_mutation_response"]: {
	__typename: "voyce_recommended_series_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_recommended_series"]>
};
	/** on_conflict condition type for table "voyce.recommended_series" */
["voyce_recommended_series_on_conflict"]: {
		constraint: GraphQLTypes["voyce_recommended_series_constraint"],
	update_columns: Array<GraphQLTypes["voyce_recommended_series_update_column"]>,
	where?: GraphQLTypes["voyce_recommended_series_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.recommended_series". */
["voyce_recommended_series_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	notification_image?: GraphQLTypes["order_by"],
	notification_status?: GraphQLTypes["order_by"],
	notification_text?: GraphQLTypes["order_by"],
	notification_title?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.recommended_series */
["voyce_recommended_series_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.recommended_series" */
["voyce_recommended_series_select_column"]: voyce_recommended_series_select_column;
	/** input type for updating data in table "voyce.recommended_series" */
["voyce_recommended_series_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	notification_image?: string,
	notification_status?: string,
	notification_text?: string,
	notification_title?: string,
	series_id?: number
};
	/** aggregate stddev on columns */
["voyce_recommended_series_stddev_fields"]: {
	__typename: "voyce_recommended_series_stddev_fields",
	id?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_recommended_series_stddev_pop_fields"]: {
	__typename: "voyce_recommended_series_stddev_pop_fields",
	id?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_recommended_series_stddev_samp_fields"]: {
	__typename: "voyce_recommended_series_stddev_samp_fields",
	id?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_recommended_series" */
["voyce_recommended_series_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_recommended_series_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_recommended_series_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	notification_image?: string,
	notification_status?: string,
	notification_text?: string,
	notification_title?: string,
	series_id?: number
};
	/** aggregate sum on columns */
["voyce_recommended_series_sum_fields"]: {
	__typename: "voyce_recommended_series_sum_fields",
	id?: number,
	series_id?: number
};
	/** update columns of table "voyce.recommended_series" */
["voyce_recommended_series_update_column"]: voyce_recommended_series_update_column;
	["voyce_recommended_series_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_recommended_series_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_recommended_series_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_recommended_series_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_recommended_series_var_pop_fields"]: {
	__typename: "voyce_recommended_series_var_pop_fields",
	id?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_recommended_series_var_samp_fields"]: {
	__typename: "voyce_recommended_series_var_samp_fields",
	id?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_recommended_series_variance_fields"]: {
	__typename: "voyce_recommended_series_variance_fields",
	id?: number,
	series_id?: number
};
	/** columns and relationships of "voyce.release_schedules" */
["voyce_release_schedules"]: {
	__typename: "voyce_release_schedules",
	/** An object relationship */
	chapter?: GraphQLTypes["voyce_chapters"],
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id: number,
	schedule: GraphQLTypes["timestamptz"],
	/** An object relationship */
	series?: GraphQLTypes["voyce_series"],
	series_id: number,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.release_schedules" */
["voyce_release_schedules_aggregate"]: {
	__typename: "voyce_release_schedules_aggregate",
	aggregate?: GraphQLTypes["voyce_release_schedules_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_release_schedules"]>
};
	/** aggregate fields of "voyce.release_schedules" */
["voyce_release_schedules_aggregate_fields"]: {
	__typename: "voyce_release_schedules_aggregate_fields",
	avg?: GraphQLTypes["voyce_release_schedules_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_release_schedules_max_fields"],
	min?: GraphQLTypes["voyce_release_schedules_min_fields"],
	stddev?: GraphQLTypes["voyce_release_schedules_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_release_schedules_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_release_schedules_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_release_schedules_sum_fields"],
	var_pop?: GraphQLTypes["voyce_release_schedules_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_release_schedules_var_samp_fields"],
	variance?: GraphQLTypes["voyce_release_schedules_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_release_schedules_avg_fields"]: {
	__typename: "voyce_release_schedules_avg_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.release_schedules". All fields are combined with a logical 'AND'. */
["voyce_release_schedules_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_release_schedules_bool_exp"]>,
	_not?: GraphQLTypes["voyce_release_schedules_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_release_schedules_bool_exp"]>,
	chapter?: GraphQLTypes["voyce_chapters_bool_exp"],
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	schedule?: GraphQLTypes["timestamptz_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.release_schedules" */
["voyce_release_schedules_constraint"]: voyce_release_schedules_constraint;
	/** input type for incrementing numeric columns in table "voyce.release_schedules" */
["voyce_release_schedules_inc_input"]: {
		chapter_id?: number,
	id?: number,
	series_id?: number
};
	/** input type for inserting data into table "voyce.release_schedules" */
["voyce_release_schedules_insert_input"]: {
		chapter?: GraphQLTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	schedule?: GraphQLTypes["timestamptz"],
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_release_schedules_max_fields"]: {
	__typename: "voyce_release_schedules_max_fields",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	schedule?: GraphQLTypes["timestamptz"],
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_release_schedules_min_fields"]: {
	__typename: "voyce_release_schedules_min_fields",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	schedule?: GraphQLTypes["timestamptz"],
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.release_schedules" */
["voyce_release_schedules_mutation_response"]: {
	__typename: "voyce_release_schedules_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_release_schedules"]>
};
	/** on_conflict condition type for table "voyce.release_schedules" */
["voyce_release_schedules_on_conflict"]: {
		constraint: GraphQLTypes["voyce_release_schedules_constraint"],
	update_columns: Array<GraphQLTypes["voyce_release_schedules_update_column"]>,
	where?: GraphQLTypes["voyce_release_schedules_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.release_schedules". */
["voyce_release_schedules_order_by"]: {
		chapter?: GraphQLTypes["voyce_chapters_order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	schedule?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.release_schedules */
["voyce_release_schedules_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.release_schedules" */
["voyce_release_schedules_select_column"]: voyce_release_schedules_select_column;
	/** input type for updating data in table "voyce.release_schedules" */
["voyce_release_schedules_set_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	schedule?: GraphQLTypes["timestamptz"],
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_release_schedules_stddev_fields"]: {
	__typename: "voyce_release_schedules_stddev_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_release_schedules_stddev_pop_fields"]: {
	__typename: "voyce_release_schedules_stddev_pop_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_release_schedules_stddev_samp_fields"]: {
	__typename: "voyce_release_schedules_stddev_samp_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_release_schedules" */
["voyce_release_schedules_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_release_schedules_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_release_schedules_stream_cursor_value_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	schedule?: GraphQLTypes["timestamptz"],
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_release_schedules_sum_fields"]: {
	__typename: "voyce_release_schedules_sum_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number
};
	/** update columns of table "voyce.release_schedules" */
["voyce_release_schedules_update_column"]: voyce_release_schedules_update_column;
	["voyce_release_schedules_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_release_schedules_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_release_schedules_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_release_schedules_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_release_schedules_var_pop_fields"]: {
	__typename: "voyce_release_schedules_var_pop_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_release_schedules_var_samp_fields"]: {
	__typename: "voyce_release_schedules_var_samp_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_release_schedules_variance_fields"]: {
	__typename: "voyce_release_schedules_variance_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number
};
	/** table to describe all possible rewards users can get */
["voyce_rewards"]: {
	__typename: "voyce_rewards",
	end_date?: GraphQLTypes["timestamp"],
	expiry_date?: GraphQLTypes["timestamp"],
	free: boolean,
	id: number,
	image?: string,
	purchaseId: string,
	start_date: GraphQLTypes["timestamp"],
	title?: string,
	transferable: boolean,
	type: string,
	value?: number
};
	/** aggregated selection of "voyce.rewards" */
["voyce_rewards_aggregate"]: {
	__typename: "voyce_rewards_aggregate",
	aggregate?: GraphQLTypes["voyce_rewards_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_rewards"]>
};
	/** aggregate fields of "voyce.rewards" */
["voyce_rewards_aggregate_fields"]: {
	__typename: "voyce_rewards_aggregate_fields",
	avg?: GraphQLTypes["voyce_rewards_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_rewards_max_fields"],
	min?: GraphQLTypes["voyce_rewards_min_fields"],
	stddev?: GraphQLTypes["voyce_rewards_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_rewards_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_rewards_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_rewards_sum_fields"],
	var_pop?: GraphQLTypes["voyce_rewards_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_rewards_var_samp_fields"],
	variance?: GraphQLTypes["voyce_rewards_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_rewards_avg_fields"]: {
	__typename: "voyce_rewards_avg_fields",
	id?: number,
	value?: number
};
	/** Boolean expression to filter rows from the table "voyce.rewards". All fields are combined with a logical 'AND'. */
["voyce_rewards_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_rewards_bool_exp"]>,
	_not?: GraphQLTypes["voyce_rewards_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_rewards_bool_exp"]>,
	end_date?: GraphQLTypes["timestamp_comparison_exp"],
	expiry_date?: GraphQLTypes["timestamp_comparison_exp"],
	free?: GraphQLTypes["Boolean_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	image?: GraphQLTypes["String_comparison_exp"],
	purchaseId?: GraphQLTypes["String_comparison_exp"],
	start_date?: GraphQLTypes["timestamp_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	transferable?: GraphQLTypes["Boolean_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"],
	value?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.rewards" */
["voyce_rewards_constraint"]: voyce_rewards_constraint;
	/** input type for incrementing numeric columns in table "voyce.rewards" */
["voyce_rewards_inc_input"]: {
		id?: number,
	value?: number
};
	/** input type for inserting data into table "voyce.rewards" */
["voyce_rewards_insert_input"]: {
		end_date?: GraphQLTypes["timestamp"],
	expiry_date?: GraphQLTypes["timestamp"],
	free?: boolean,
	id?: number,
	image?: string,
	purchaseId?: string,
	start_date?: GraphQLTypes["timestamp"],
	title?: string,
	transferable?: boolean,
	type?: string,
	value?: number
};
	/** table to keep rewards configuration for each leaderboards */
["voyce_rewards_leaderboard"]: {
	__typename: "voyce_rewards_leaderboard",
	id: number,
	order?: number,
	/** An object relationship */
	reward: GraphQLTypes["voyce_rewards"],
	reward_id: number,
	tag?: string,
	title?: string
};
	/** aggregated selection of "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_aggregate"]: {
	__typename: "voyce_rewards_leaderboard_aggregate",
	aggregate?: GraphQLTypes["voyce_rewards_leaderboard_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_rewards_leaderboard"]>
};
	/** aggregate fields of "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_aggregate_fields"]: {
	__typename: "voyce_rewards_leaderboard_aggregate_fields",
	avg?: GraphQLTypes["voyce_rewards_leaderboard_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_rewards_leaderboard_max_fields"],
	min?: GraphQLTypes["voyce_rewards_leaderboard_min_fields"],
	stddev?: GraphQLTypes["voyce_rewards_leaderboard_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_rewards_leaderboard_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_rewards_leaderboard_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_rewards_leaderboard_sum_fields"],
	var_pop?: GraphQLTypes["voyce_rewards_leaderboard_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_rewards_leaderboard_var_samp_fields"],
	variance?: GraphQLTypes["voyce_rewards_leaderboard_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_rewards_leaderboard_avg_fields"]: {
	__typename: "voyce_rewards_leaderboard_avg_fields",
	id?: number,
	order?: number,
	reward_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.rewards_leaderboard". All fields are combined with a logical 'AND'. */
["voyce_rewards_leaderboard_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_rewards_leaderboard_bool_exp"]>,
	_not?: GraphQLTypes["voyce_rewards_leaderboard_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_rewards_leaderboard_bool_exp"]>,
	id?: GraphQLTypes["Int_comparison_exp"],
	order?: GraphQLTypes["Int_comparison_exp"],
	reward?: GraphQLTypes["voyce_rewards_bool_exp"],
	reward_id?: GraphQLTypes["Int_comparison_exp"],
	tag?: GraphQLTypes["String_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_constraint"]: voyce_rewards_leaderboard_constraint;
	/** input type for incrementing numeric columns in table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_inc_input"]: {
		id?: number,
	order?: number,
	reward_id?: number
};
	/** input type for inserting data into table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_insert_input"]: {
		id?: number,
	order?: number,
	reward?: GraphQLTypes["voyce_rewards_obj_rel_insert_input"],
	reward_id?: number,
	tag?: string,
	title?: string
};
	/** aggregate max on columns */
["voyce_rewards_leaderboard_max_fields"]: {
	__typename: "voyce_rewards_leaderboard_max_fields",
	id?: number,
	order?: number,
	reward_id?: number,
	tag?: string,
	title?: string
};
	/** aggregate min on columns */
["voyce_rewards_leaderboard_min_fields"]: {
	__typename: "voyce_rewards_leaderboard_min_fields",
	id?: number,
	order?: number,
	reward_id?: number,
	tag?: string,
	title?: string
};
	/** response of any mutation on the table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_mutation_response"]: {
	__typename: "voyce_rewards_leaderboard_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_rewards_leaderboard"]>
};
	/** on_conflict condition type for table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_on_conflict"]: {
		constraint: GraphQLTypes["voyce_rewards_leaderboard_constraint"],
	update_columns: Array<GraphQLTypes["voyce_rewards_leaderboard_update_column"]>,
	where?: GraphQLTypes["voyce_rewards_leaderboard_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.rewards_leaderboard". */
["voyce_rewards_leaderboard_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	reward?: GraphQLTypes["voyce_rewards_order_by"],
	reward_id?: GraphQLTypes["order_by"],
	tag?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.rewards_leaderboard */
["voyce_rewards_leaderboard_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_select_column"]: voyce_rewards_leaderboard_select_column;
	/** input type for updating data in table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_set_input"]: {
		id?: number,
	order?: number,
	reward_id?: number,
	tag?: string,
	title?: string
};
	/** aggregate stddev on columns */
["voyce_rewards_leaderboard_stddev_fields"]: {
	__typename: "voyce_rewards_leaderboard_stddev_fields",
	id?: number,
	order?: number,
	reward_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_rewards_leaderboard_stddev_pop_fields"]: {
	__typename: "voyce_rewards_leaderboard_stddev_pop_fields",
	id?: number,
	order?: number,
	reward_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_rewards_leaderboard_stddev_samp_fields"]: {
	__typename: "voyce_rewards_leaderboard_stddev_samp_fields",
	id?: number,
	order?: number,
	reward_id?: number
};
	/** Streaming cursor of the table "voyce_rewards_leaderboard" */
["voyce_rewards_leaderboard_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_rewards_leaderboard_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_rewards_leaderboard_stream_cursor_value_input"]: {
		id?: number,
	order?: number,
	reward_id?: number,
	tag?: string,
	title?: string
};
	/** aggregate sum on columns */
["voyce_rewards_leaderboard_sum_fields"]: {
	__typename: "voyce_rewards_leaderboard_sum_fields",
	id?: number,
	order?: number,
	reward_id?: number
};
	/** update columns of table "voyce.rewards_leaderboard" */
["voyce_rewards_leaderboard_update_column"]: voyce_rewards_leaderboard_update_column;
	["voyce_rewards_leaderboard_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_rewards_leaderboard_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_rewards_leaderboard_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_rewards_leaderboard_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_rewards_leaderboard_var_pop_fields"]: {
	__typename: "voyce_rewards_leaderboard_var_pop_fields",
	id?: number,
	order?: number,
	reward_id?: number
};
	/** aggregate var_samp on columns */
["voyce_rewards_leaderboard_var_samp_fields"]: {
	__typename: "voyce_rewards_leaderboard_var_samp_fields",
	id?: number,
	order?: number,
	reward_id?: number
};
	/** aggregate variance on columns */
["voyce_rewards_leaderboard_variance_fields"]: {
	__typename: "voyce_rewards_leaderboard_variance_fields",
	id?: number,
	order?: number,
	reward_id?: number
};
	/** aggregate max on columns */
["voyce_rewards_max_fields"]: {
	__typename: "voyce_rewards_max_fields",
	end_date?: GraphQLTypes["timestamp"],
	expiry_date?: GraphQLTypes["timestamp"],
	id?: number,
	image?: string,
	purchaseId?: string,
	start_date?: GraphQLTypes["timestamp"],
	title?: string,
	type?: string,
	value?: number
};
	/** aggregate min on columns */
["voyce_rewards_min_fields"]: {
	__typename: "voyce_rewards_min_fields",
	end_date?: GraphQLTypes["timestamp"],
	expiry_date?: GraphQLTypes["timestamp"],
	id?: number,
	image?: string,
	purchaseId?: string,
	start_date?: GraphQLTypes["timestamp"],
	title?: string,
	type?: string,
	value?: number
};
	/** response of any mutation on the table "voyce.rewards" */
["voyce_rewards_mutation_response"]: {
	__typename: "voyce_rewards_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_rewards"]>
};
	/** input type for inserting object relation for remote table "voyce.rewards" */
["voyce_rewards_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_rewards_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_rewards_on_conflict"]
};
	/** on_conflict condition type for table "voyce.rewards" */
["voyce_rewards_on_conflict"]: {
		constraint: GraphQLTypes["voyce_rewards_constraint"],
	update_columns: Array<GraphQLTypes["voyce_rewards_update_column"]>,
	where?: GraphQLTypes["voyce_rewards_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.rewards". */
["voyce_rewards_order_by"]: {
		end_date?: GraphQLTypes["order_by"],
	expiry_date?: GraphQLTypes["order_by"],
	free?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	purchaseId?: GraphQLTypes["order_by"],
	start_date?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	transferable?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	value?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.rewards */
["voyce_rewards_pk_columns_input"]: {
		id: number
};
	/** table to describe rewards of a given quest */
["voyce_rewards_quest"]: {
	__typename: "voyce_rewards_quest",
	id: number,
	/** An object relationship */
	quest: GraphQLTypes["voyce_quests_old"],
	quest_id: number,
	/** An object relationship */
	reward: GraphQLTypes["voyce_rewards"],
	rewards_id: number
};
	/** aggregated selection of "voyce.rewards_quest" */
["voyce_rewards_quest_aggregate"]: {
	__typename: "voyce_rewards_quest_aggregate",
	aggregate?: GraphQLTypes["voyce_rewards_quest_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_rewards_quest"]>
};
	["voyce_rewards_quest_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_rewards_quest_aggregate_bool_exp_count"]
};
	["voyce_rewards_quest_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_rewards_quest_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_rewards_quest_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.rewards_quest" */
["voyce_rewards_quest_aggregate_fields"]: {
	__typename: "voyce_rewards_quest_aggregate_fields",
	avg?: GraphQLTypes["voyce_rewards_quest_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_rewards_quest_max_fields"],
	min?: GraphQLTypes["voyce_rewards_quest_min_fields"],
	stddev?: GraphQLTypes["voyce_rewards_quest_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_rewards_quest_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_rewards_quest_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_rewards_quest_sum_fields"],
	var_pop?: GraphQLTypes["voyce_rewards_quest_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_rewards_quest_var_samp_fields"],
	variance?: GraphQLTypes["voyce_rewards_quest_variance_fields"]
};
	/** order by aggregate values of table "voyce.rewards_quest" */
["voyce_rewards_quest_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_rewards_quest_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_rewards_quest_max_order_by"],
	min?: GraphQLTypes["voyce_rewards_quest_min_order_by"],
	stddev?: GraphQLTypes["voyce_rewards_quest_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_rewards_quest_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_rewards_quest_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_rewards_quest_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_rewards_quest_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_rewards_quest_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_rewards_quest_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.rewards_quest" */
["voyce_rewards_quest_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_rewards_quest_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_rewards_quest_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_rewards_quest_avg_fields"]: {
	__typename: "voyce_rewards_quest_avg_fields",
	id?: number,
	quest_id?: number,
	rewards_id?: number
};
	/** order by avg() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	rewards_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.rewards_quest". All fields are combined with a logical 'AND'. */
["voyce_rewards_quest_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_rewards_quest_bool_exp"]>,
	_not?: GraphQLTypes["voyce_rewards_quest_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_rewards_quest_bool_exp"]>,
	id?: GraphQLTypes["Int_comparison_exp"],
	quest?: GraphQLTypes["voyce_quests_old_bool_exp"],
	quest_id?: GraphQLTypes["Int_comparison_exp"],
	reward?: GraphQLTypes["voyce_rewards_bool_exp"],
	rewards_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.rewards_quest" */
["voyce_rewards_quest_constraint"]: voyce_rewards_quest_constraint;
	/** input type for incrementing numeric columns in table "voyce.rewards_quest" */
["voyce_rewards_quest_inc_input"]: {
		id?: number,
	quest_id?: number,
	rewards_id?: number
};
	/** input type for inserting data into table "voyce.rewards_quest" */
["voyce_rewards_quest_insert_input"]: {
		id?: number,
	quest?: GraphQLTypes["voyce_quests_old_obj_rel_insert_input"],
	quest_id?: number,
	reward?: GraphQLTypes["voyce_rewards_obj_rel_insert_input"],
	rewards_id?: number
};
	/** aggregate max on columns */
["voyce_rewards_quest_max_fields"]: {
	__typename: "voyce_rewards_quest_max_fields",
	id?: number,
	quest_id?: number,
	rewards_id?: number
};
	/** order by max() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_max_order_by"]: {
		id?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	rewards_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_rewards_quest_min_fields"]: {
	__typename: "voyce_rewards_quest_min_fields",
	id?: number,
	quest_id?: number,
	rewards_id?: number
};
	/** order by min() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_min_order_by"]: {
		id?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	rewards_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.rewards_quest" */
["voyce_rewards_quest_mutation_response"]: {
	__typename: "voyce_rewards_quest_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_rewards_quest"]>
};
	/** on_conflict condition type for table "voyce.rewards_quest" */
["voyce_rewards_quest_on_conflict"]: {
		constraint: GraphQLTypes["voyce_rewards_quest_constraint"],
	update_columns: Array<GraphQLTypes["voyce_rewards_quest_update_column"]>,
	where?: GraphQLTypes["voyce_rewards_quest_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.rewards_quest". */
["voyce_rewards_quest_order_by"]: {
		id?: GraphQLTypes["order_by"],
	quest?: GraphQLTypes["voyce_quests_old_order_by"],
	quest_id?: GraphQLTypes["order_by"],
	reward?: GraphQLTypes["voyce_rewards_order_by"],
	rewards_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.rewards_quest */
["voyce_rewards_quest_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_select_column"]: voyce_rewards_quest_select_column;
	/** input type for updating data in table "voyce.rewards_quest" */
["voyce_rewards_quest_set_input"]: {
		id?: number,
	quest_id?: number,
	rewards_id?: number
};
	/** aggregate stddev on columns */
["voyce_rewards_quest_stddev_fields"]: {
	__typename: "voyce_rewards_quest_stddev_fields",
	id?: number,
	quest_id?: number,
	rewards_id?: number
};
	/** order by stddev() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	rewards_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_rewards_quest_stddev_pop_fields"]: {
	__typename: "voyce_rewards_quest_stddev_pop_fields",
	id?: number,
	quest_id?: number,
	rewards_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	rewards_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_rewards_quest_stddev_samp_fields"]: {
	__typename: "voyce_rewards_quest_stddev_samp_fields",
	id?: number,
	quest_id?: number,
	rewards_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	rewards_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_rewards_quest" */
["voyce_rewards_quest_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_rewards_quest_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_rewards_quest_stream_cursor_value_input"]: {
		id?: number,
	quest_id?: number,
	rewards_id?: number
};
	/** aggregate sum on columns */
["voyce_rewards_quest_sum_fields"]: {
	__typename: "voyce_rewards_quest_sum_fields",
	id?: number,
	quest_id?: number,
	rewards_id?: number
};
	/** order by sum() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	rewards_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_update_column"]: voyce_rewards_quest_update_column;
	["voyce_rewards_quest_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_rewards_quest_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_rewards_quest_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_rewards_quest_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_rewards_quest_var_pop_fields"]: {
	__typename: "voyce_rewards_quest_var_pop_fields",
	id?: number,
	quest_id?: number,
	rewards_id?: number
};
	/** order by var_pop() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	rewards_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_rewards_quest_var_samp_fields"]: {
	__typename: "voyce_rewards_quest_var_samp_fields",
	id?: number,
	quest_id?: number,
	rewards_id?: number
};
	/** order by var_samp() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	rewards_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_rewards_quest_variance_fields"]: {
	__typename: "voyce_rewards_quest_variance_fields",
	id?: number,
	quest_id?: number,
	rewards_id?: number
};
	/** order by variance() on columns of table "voyce.rewards_quest" */
["voyce_rewards_quest_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	rewards_id?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.rewards" */
["voyce_rewards_select_column"]: voyce_rewards_select_column;
	/** input type for updating data in table "voyce.rewards" */
["voyce_rewards_set_input"]: {
		end_date?: GraphQLTypes["timestamp"],
	expiry_date?: GraphQLTypes["timestamp"],
	free?: boolean,
	id?: number,
	image?: string,
	purchaseId?: string,
	start_date?: GraphQLTypes["timestamp"],
	title?: string,
	transferable?: boolean,
	type?: string,
	value?: number
};
	/** aggregate stddev on columns */
["voyce_rewards_stddev_fields"]: {
	__typename: "voyce_rewards_stddev_fields",
	id?: number,
	value?: number
};
	/** aggregate stddev_pop on columns */
["voyce_rewards_stddev_pop_fields"]: {
	__typename: "voyce_rewards_stddev_pop_fields",
	id?: number,
	value?: number
};
	/** aggregate stddev_samp on columns */
["voyce_rewards_stddev_samp_fields"]: {
	__typename: "voyce_rewards_stddev_samp_fields",
	id?: number,
	value?: number
};
	/** Streaming cursor of the table "voyce_rewards" */
["voyce_rewards_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_rewards_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_rewards_stream_cursor_value_input"]: {
		end_date?: GraphQLTypes["timestamp"],
	expiry_date?: GraphQLTypes["timestamp"],
	free?: boolean,
	id?: number,
	image?: string,
	purchaseId?: string,
	start_date?: GraphQLTypes["timestamp"],
	title?: string,
	transferable?: boolean,
	type?: string,
	value?: number
};
	/** aggregate sum on columns */
["voyce_rewards_sum_fields"]: {
	__typename: "voyce_rewards_sum_fields",
	id?: number,
	value?: number
};
	/** update columns of table "voyce.rewards" */
["voyce_rewards_update_column"]: voyce_rewards_update_column;
	["voyce_rewards_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_rewards_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_rewards_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_rewards_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_rewards_var_pop_fields"]: {
	__typename: "voyce_rewards_var_pop_fields",
	id?: number,
	value?: number
};
	/** aggregate var_samp on columns */
["voyce_rewards_var_samp_fields"]: {
	__typename: "voyce_rewards_var_samp_fields",
	id?: number,
	value?: number
};
	/** aggregate variance on columns */
["voyce_rewards_variance_fields"]: {
	__typename: "voyce_rewards_variance_fields",
	id?: number,
	value?: number
};
	/** columns and relationships of "voyce.sections" */
["voyce_sections"]: {
	__typename: "voyce_sections",
	content?: string,
	created_at?: GraphQLTypes["timestamp"],
	id: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregated selection of "voyce.sections" */
["voyce_sections_aggregate"]: {
	__typename: "voyce_sections_aggregate",
	aggregate?: GraphQLTypes["voyce_sections_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_sections"]>
};
	/** aggregate fields of "voyce.sections" */
["voyce_sections_aggregate_fields"]: {
	__typename: "voyce_sections_aggregate_fields",
	avg?: GraphQLTypes["voyce_sections_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_sections_max_fields"],
	min?: GraphQLTypes["voyce_sections_min_fields"],
	stddev?: GraphQLTypes["voyce_sections_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_sections_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_sections_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_sections_sum_fields"],
	var_pop?: GraphQLTypes["voyce_sections_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_sections_var_samp_fields"],
	variance?: GraphQLTypes["voyce_sections_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_sections_avg_fields"]: {
	__typename: "voyce_sections_avg_fields",
	id?: number
};
	/** Boolean expression to filter rows from the table "voyce.sections". All fields are combined with a logical 'AND'. */
["voyce_sections_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_sections_bool_exp"]>,
	_not?: GraphQLTypes["voyce_sections_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_sections_bool_exp"]>,
	content?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamp_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.sections" */
["voyce_sections_constraint"]: voyce_sections_constraint;
	/** input type for incrementing numeric columns in table "voyce.sections" */
["voyce_sections_inc_input"]: {
		id?: number
};
	/** input type for inserting data into table "voyce.sections" */
["voyce_sections_insert_input"]: {
		content?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate max on columns */
["voyce_sections_max_fields"]: {
	__typename: "voyce_sections_max_fields",
	content?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate min on columns */
["voyce_sections_min_fields"]: {
	__typename: "voyce_sections_min_fields",
	content?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** response of any mutation on the table "voyce.sections" */
["voyce_sections_mutation_response"]: {
	__typename: "voyce_sections_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_sections"]>
};
	/** on_conflict condition type for table "voyce.sections" */
["voyce_sections_on_conflict"]: {
		constraint: GraphQLTypes["voyce_sections_constraint"],
	update_columns: Array<GraphQLTypes["voyce_sections_update_column"]>,
	where?: GraphQLTypes["voyce_sections_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.sections". */
["voyce_sections_order_by"]: {
		content?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.sections */
["voyce_sections_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.sections" */
["voyce_sections_select_column"]: voyce_sections_select_column;
	/** input type for updating data in table "voyce.sections" */
["voyce_sections_set_input"]: {
		content?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate stddev on columns */
["voyce_sections_stddev_fields"]: {
	__typename: "voyce_sections_stddev_fields",
	id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_sections_stddev_pop_fields"]: {
	__typename: "voyce_sections_stddev_pop_fields",
	id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_sections_stddev_samp_fields"]: {
	__typename: "voyce_sections_stddev_samp_fields",
	id?: number
};
	/** Streaming cursor of the table "voyce_sections" */
["voyce_sections_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_sections_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_sections_stream_cursor_value_input"]: {
		content?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"]
};
	/** aggregate sum on columns */
["voyce_sections_sum_fields"]: {
	__typename: "voyce_sections_sum_fields",
	id?: number
};
	/** update columns of table "voyce.sections" */
["voyce_sections_update_column"]: voyce_sections_update_column;
	["voyce_sections_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_sections_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_sections_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_sections_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_sections_var_pop_fields"]: {
	__typename: "voyce_sections_var_pop_fields",
	id?: number
};
	/** aggregate var_samp on columns */
["voyce_sections_var_samp_fields"]: {
	__typename: "voyce_sections_var_samp_fields",
	id?: number
};
	/** aggregate variance on columns */
["voyce_sections_variance_fields"]: {
	__typename: "voyce_sections_variance_fields",
	id?: number
};
	/** columns and relationships of "voyce.series" */
["voyce_series"]: {
	__typename: "voyce_series",
	animated_cover_image?: string,
	audio_allowed: boolean,
	/** An object relationship */
	author?: GraphQLTypes["voyce_users"],
	average_rating?: GraphQLTypes["float8"],
	back_image?: string,
	banner_bg?: string,
	book_reverse_view: boolean,
	book_view: boolean,
	/** An array relationship */
	chapter_comments: Array<GraphQLTypes["voyce_chapter_comments"]>,
	/** An aggregate relationship */
	chapter_comments_aggregate: GraphQLTypes["voyce_chapter_comments_aggregate"],
	chapter_count?: number,
	/** An array relationship */
	chapter_panels: Array<GraphQLTypes["voyce_chapter_panels"]>,
	/** An aggregate relationship */
	chapter_panels_aggregate: GraphQLTypes["voyce_chapter_panels_aggregate"],
	/** An array relationship */
	chapters: Array<GraphQLTypes["voyce_chapters"]>,
	/** An aggregate relationship */
	chapters_aggregate: GraphQLTypes["voyce_chapters_aggregate"],
	chapters_hide: string,
	character_cover?: string,
	coin_price?: GraphQLTypes["smallint"],
	/** An array relationship */
	collaborators: Array<GraphQLTypes["voyce_series_collaborators"]>,
	/** An aggregate relationship */
	collaborators_aggregate: GraphQLTypes["voyce_series_collaborators_aggregate"],
	comic_type_id?: number,
	cover_image?: string,
	cover_image_mobile?: string,
	created_at: GraphQLTypes["timestamp"],
	csat: boolean,
	default_view?: string,
	description?: string,
	feature?: GraphQLTypes["bit"],
	/** An array relationship */
	featured_panels: Array<GraphQLTypes["voyce_series_featured_panels"]>,
	/** An aggregate relationship */
	featured_panels_aggregate: GraphQLTypes["voyce_series_featured_panels_aggregate"],
	/** An array relationship */
	followers: Array<GraphQLTypes["voyce_series_subscription"]>,
	/** An aggregate relationship */
	followers_aggregate: GraphQLTypes["voyce_series_subscription_aggregate"],
	frequency?: string,
	frequency_mask?: number,
	front_image?: string,
	/** An array relationship */
	genres: Array<GraphQLTypes["voyce_series_genre"]>,
	/** An aggregate relationship */
	genres_aggregate: GraphQLTypes["voyce_series_genre_aggregate"],
	/** An array relationship */
	hot_panels: Array<GraphQLTypes["voyce_series_hot_panels"]>,
	/** An aggregate relationship */
	hot_panels_aggregate: GraphQLTypes["voyce_series_hot_panels_aggregate"],
	id: number,
	is_animated_cover_allowed: boolean,
	is_approved?: GraphQLTypes["smallint"],
	is_deleted: boolean,
	is_intro?: GraphQLTypes["smallint"],
	is_locked: boolean,
	is_original?: GraphQLTypes["smallint"],
	is_pilots: GraphQLTypes["smallint"],
	is_recommended?: GraphQLTypes["smallint"],
	klaviyo_id?: string,
	klaviyo_list_id?: string,
	latest_chapter_update?: GraphQLTypes["timestamp"],
	/** An array relationship */
	likes: Array<GraphQLTypes["voyce_chapter_likes"]>,
	/** An aggregate relationship */
	likes_aggregate: GraphQLTypes["voyce_chapter_likes_aggregate"],
	/** An object relationship */
	likes_count?: GraphQLTypes["voyce_series_likes_count_mat"],
	/** An object relationship */
	lock_config?: GraphQLTypes["voyce_series_lock_config"],
	locked: boolean,
	locked_chapters_amount?: number,
	/** An object relationship */
	notification_settings?: GraphQLTypes["voyce_notification_series_settings"],
	paginated_view: boolean,
	pilots_votes_count?: number,
	portfolio_desc?: string,
	publish?: GraphQLTypes["smallint"],
	rating_count?: number,
	reading_view: string,
	scroll_view: boolean,
	/** An array relationship */
	series_tags_junctions: Array<GraphQLTypes["voyce_series_tags_junction"]>,
	/** An aggregate relationship */
	series_tags_junctions_aggregate: GraphQLTypes["voyce_series_tags_junction_aggregate"],
	short_desc?: string,
	slug?: string,
	/** An object relationship */
	sponsorship_config?: GraphQLTypes["voyce_series_sponsorships_configuration"],
	/** An array relationship */
	sponsorships: Array<GraphQLTypes["voyce_sponsorships"]>,
	/** An aggregate relationship */
	sponsorships_aggregate: GraphQLTypes["voyce_sponsorships_aggregate"],
	status?: string,
	subscriber_count?: number,
	support_msg?: string,
	team_spotlight: boolean,
	thumbnail?: string,
	thumbnail_box?: string,
	title: string,
	trending?: GraphQLTypes["bit"],
	trending_position?: number,
	/** An object relationship */
	type?: GraphQLTypes["voyce_comic_types"],
	updated_at?: GraphQLTypes["timestamp"],
	user_display_field?: string,
	user_id: number,
	/** An array relationship */
	users_series_junctions: Array<GraphQLTypes["voyce_users_series_junction"]>,
	/** An aggregate relationship */
	users_series_junctions_aggregate: GraphQLTypes["voyce_users_series_junction_aggregate"],
	view_count?: number,
	/** An array relationship */
	views: Array<GraphQLTypes["voyce_chapter_views"]>,
	/** An aggregate relationship */
	views_aggregate: GraphQLTypes["voyce_chapter_views_aggregate"],
	/** An object relationship */
	views_counts?: GraphQLTypes["voyce_pg_rs_series_views_count"],
	welcome_msg?: string
};
	/** aggregated selection of "voyce.series" */
["voyce_series_aggregate"]: {
	__typename: "voyce_series_aggregate",
	aggregate?: GraphQLTypes["voyce_series_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series"]>
};
	/** aggregate fields of "voyce.series" */
["voyce_series_aggregate_fields"]: {
	__typename: "voyce_series_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_max_fields"],
	min?: GraphQLTypes["voyce_series_min_fields"],
	stddev?: GraphQLTypes["voyce_series_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_avg_fields"]: {
	__typename: "voyce_series_avg_fields",
	average_rating?: number,
	chapter_count?: number,
	coin_price?: number,
	comic_type_id?: number,
	frequency_mask?: number,
	id?: number,
	is_approved?: number,
	is_intro?: number,
	is_original?: number,
	is_pilots?: number,
	is_recommended?: number,
	locked_chapters_amount?: number,
	pilots_votes_count?: number,
	publish?: number,
	rating_count?: number,
	subscriber_count?: number,
	trending_position?: number,
	user_id?: number,
	view_count?: number
};
	/** Boolean expression to filter rows from the table "voyce.series". All fields are combined with a logical 'AND'. */
["voyce_series_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_bool_exp"]>,
	animated_cover_image?: GraphQLTypes["String_comparison_exp"],
	audio_allowed?: GraphQLTypes["Boolean_comparison_exp"],
	author?: GraphQLTypes["voyce_users_bool_exp"],
	average_rating?: GraphQLTypes["float8_comparison_exp"],
	back_image?: GraphQLTypes["String_comparison_exp"],
	banner_bg?: GraphQLTypes["String_comparison_exp"],
	book_reverse_view?: GraphQLTypes["Boolean_comparison_exp"],
	book_view?: GraphQLTypes["Boolean_comparison_exp"],
	chapter_comments?: GraphQLTypes["voyce_chapter_comments_bool_exp"],
	chapter_comments_aggregate?: GraphQLTypes["voyce_chapter_comments_aggregate_bool_exp"],
	chapter_count?: GraphQLTypes["Int_comparison_exp"],
	chapter_panels?: GraphQLTypes["voyce_chapter_panels_bool_exp"],
	chapter_panels_aggregate?: GraphQLTypes["voyce_chapter_panels_aggregate_bool_exp"],
	chapters?: GraphQLTypes["voyce_chapters_bool_exp"],
	chapters_aggregate?: GraphQLTypes["voyce_chapters_aggregate_bool_exp"],
	chapters_hide?: GraphQLTypes["String_comparison_exp"],
	character_cover?: GraphQLTypes["String_comparison_exp"],
	coin_price?: GraphQLTypes["smallint_comparison_exp"],
	collaborators?: GraphQLTypes["voyce_series_collaborators_bool_exp"],
	collaborators_aggregate?: GraphQLTypes["voyce_series_collaborators_aggregate_bool_exp"],
	comic_type_id?: GraphQLTypes["Int_comparison_exp"],
	cover_image?: GraphQLTypes["String_comparison_exp"],
	cover_image_mobile?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	csat?: GraphQLTypes["Boolean_comparison_exp"],
	default_view?: GraphQLTypes["String_comparison_exp"],
	description?: GraphQLTypes["String_comparison_exp"],
	feature?: GraphQLTypes["bit_comparison_exp"],
	featured_panels?: GraphQLTypes["voyce_series_featured_panels_bool_exp"],
	featured_panels_aggregate?: GraphQLTypes["voyce_series_featured_panels_aggregate_bool_exp"],
	followers?: GraphQLTypes["voyce_series_subscription_bool_exp"],
	followers_aggregate?: GraphQLTypes["voyce_series_subscription_aggregate_bool_exp"],
	frequency?: GraphQLTypes["String_comparison_exp"],
	frequency_mask?: GraphQLTypes["Int_comparison_exp"],
	front_image?: GraphQLTypes["String_comparison_exp"],
	genres?: GraphQLTypes["voyce_series_genre_bool_exp"],
	genres_aggregate?: GraphQLTypes["voyce_series_genre_aggregate_bool_exp"],
	hot_panels?: GraphQLTypes["voyce_series_hot_panels_bool_exp"],
	hot_panels_aggregate?: GraphQLTypes["voyce_series_hot_panels_aggregate_bool_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_animated_cover_allowed?: GraphQLTypes["Boolean_comparison_exp"],
	is_approved?: GraphQLTypes["smallint_comparison_exp"],
	is_deleted?: GraphQLTypes["Boolean_comparison_exp"],
	is_intro?: GraphQLTypes["smallint_comparison_exp"],
	is_locked?: GraphQLTypes["Boolean_comparison_exp"],
	is_original?: GraphQLTypes["smallint_comparison_exp"],
	is_pilots?: GraphQLTypes["smallint_comparison_exp"],
	is_recommended?: GraphQLTypes["smallint_comparison_exp"],
	klaviyo_id?: GraphQLTypes["String_comparison_exp"],
	klaviyo_list_id?: GraphQLTypes["String_comparison_exp"],
	latest_chapter_update?: GraphQLTypes["timestamp_comparison_exp"],
	likes?: GraphQLTypes["voyce_chapter_likes_bool_exp"],
	likes_aggregate?: GraphQLTypes["voyce_chapter_likes_aggregate_bool_exp"],
	likes_count?: GraphQLTypes["voyce_series_likes_count_mat_bool_exp"],
	lock_config?: GraphQLTypes["voyce_series_lock_config_bool_exp"],
	locked?: GraphQLTypes["Boolean_comparison_exp"],
	locked_chapters_amount?: GraphQLTypes["Int_comparison_exp"],
	notification_settings?: GraphQLTypes["voyce_notification_series_settings_bool_exp"],
	paginated_view?: GraphQLTypes["Boolean_comparison_exp"],
	pilots_votes_count?: GraphQLTypes["Int_comparison_exp"],
	portfolio_desc?: GraphQLTypes["String_comparison_exp"],
	publish?: GraphQLTypes["smallint_comparison_exp"],
	rating_count?: GraphQLTypes["Int_comparison_exp"],
	reading_view?: GraphQLTypes["String_comparison_exp"],
	scroll_view?: GraphQLTypes["Boolean_comparison_exp"],
	series_tags_junctions?: GraphQLTypes["voyce_series_tags_junction_bool_exp"],
	series_tags_junctions_aggregate?: GraphQLTypes["voyce_series_tags_junction_aggregate_bool_exp"],
	short_desc?: GraphQLTypes["String_comparison_exp"],
	slug?: GraphQLTypes["String_comparison_exp"],
	sponsorship_config?: GraphQLTypes["voyce_series_sponsorships_configuration_bool_exp"],
	sponsorships?: GraphQLTypes["voyce_sponsorships_bool_exp"],
	sponsorships_aggregate?: GraphQLTypes["voyce_sponsorships_aggregate_bool_exp"],
	status?: GraphQLTypes["String_comparison_exp"],
	subscriber_count?: GraphQLTypes["Int_comparison_exp"],
	support_msg?: GraphQLTypes["String_comparison_exp"],
	team_spotlight?: GraphQLTypes["Boolean_comparison_exp"],
	thumbnail?: GraphQLTypes["String_comparison_exp"],
	thumbnail_box?: GraphQLTypes["String_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	trending?: GraphQLTypes["bit_comparison_exp"],
	trending_position?: GraphQLTypes["Int_comparison_exp"],
	type?: GraphQLTypes["voyce_comic_types_bool_exp"],
	updated_at?: GraphQLTypes["timestamp_comparison_exp"],
	user_display_field?: GraphQLTypes["String_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	users_series_junctions?: GraphQLTypes["voyce_users_series_junction_bool_exp"],
	users_series_junctions_aggregate?: GraphQLTypes["voyce_users_series_junction_aggregate_bool_exp"],
	view_count?: GraphQLTypes["Int_comparison_exp"],
	views?: GraphQLTypes["voyce_chapter_views_bool_exp"],
	views_aggregate?: GraphQLTypes["voyce_chapter_views_aggregate_bool_exp"],
	views_counts?: GraphQLTypes["voyce_pg_rs_series_views_count_bool_exp"],
	welcome_msg?: GraphQLTypes["String_comparison_exp"]
};
	/** columns and relationships of "voyce.series_collaborators" */
["voyce_series_collaborators"]: {
	__typename: "voyce_series_collaborators",
	/** An object relationship */
	collaborator_role?: GraphQLTypes["voyce_collaborator_roles"],
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	role_id: number,
	/** An object relationship */
	series: GraphQLTypes["voyce_series"],
	series_id: number,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.series_collaborators" */
["voyce_series_collaborators_aggregate"]: {
	__typename: "voyce_series_collaborators_aggregate",
	aggregate?: GraphQLTypes["voyce_series_collaborators_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_collaborators"]>
};
	["voyce_series_collaborators_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_series_collaborators_aggregate_bool_exp_count"]
};
	["voyce_series_collaborators_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_series_collaborators_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_series_collaborators_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.series_collaborators" */
["voyce_series_collaborators_aggregate_fields"]: {
	__typename: "voyce_series_collaborators_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_collaborators_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_collaborators_max_fields"],
	min?: GraphQLTypes["voyce_series_collaborators_min_fields"],
	stddev?: GraphQLTypes["voyce_series_collaborators_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_collaborators_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_collaborators_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_collaborators_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_collaborators_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_collaborators_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_collaborators_variance_fields"]
};
	/** order by aggregate values of table "voyce.series_collaborators" */
["voyce_series_collaborators_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_series_collaborators_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_series_collaborators_max_order_by"],
	min?: GraphQLTypes["voyce_series_collaborators_min_order_by"],
	stddev?: GraphQLTypes["voyce_series_collaborators_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_series_collaborators_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_series_collaborators_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_series_collaborators_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_series_collaborators_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_series_collaborators_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_series_collaborators_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.series_collaborators" */
["voyce_series_collaborators_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_series_collaborators_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_series_collaborators_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_series_collaborators_avg_fields"]: {
	__typename: "voyce_series_collaborators_avg_fields",
	id?: number,
	role_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	role_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.series_collaborators". All fields are combined with a logical 'AND'. */
["voyce_series_collaborators_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_collaborators_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_collaborators_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_collaborators_bool_exp"]>,
	collaborator_role?: GraphQLTypes["voyce_collaborator_roles_bool_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	role_id?: GraphQLTypes["Int_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_collaborators" */
["voyce_series_collaborators_constraint"]: voyce_series_collaborators_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_collaborators" */
["voyce_series_collaborators_inc_input"]: {
		id?: number,
	role_id?: number,
	series_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.series_collaborators" */
["voyce_series_collaborators_insert_input"]: {
		collaborator_role?: GraphQLTypes["voyce_collaborator_roles_obj_rel_insert_input"],
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	role_id?: number,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_series_collaborators_max_fields"]: {
	__typename: "voyce_series_collaborators_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	role_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by max() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_max_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	role_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_series_collaborators_min_fields"]: {
	__typename: "voyce_series_collaborators_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	role_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by min() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_min_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	role_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.series_collaborators" */
["voyce_series_collaborators_mutation_response"]: {
	__typename: "voyce_series_collaborators_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_collaborators"]>
};
	/** on_conflict condition type for table "voyce.series_collaborators" */
["voyce_series_collaborators_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_collaborators_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_collaborators_update_column"]>,
	where?: GraphQLTypes["voyce_series_collaborators_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_collaborators". */
["voyce_series_collaborators_order_by"]: {
		collaborator_role?: GraphQLTypes["voyce_collaborator_roles_order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	role_id?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_collaborators */
["voyce_series_collaborators_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_select_column"]: voyce_series_collaborators_select_column;
	/** input type for updating data in table "voyce.series_collaborators" */
["voyce_series_collaborators_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	role_id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_series_collaborators_stddev_fields"]: {
	__typename: "voyce_series_collaborators_stddev_fields",
	id?: number,
	role_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	role_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_series_collaborators_stddev_pop_fields"]: {
	__typename: "voyce_series_collaborators_stddev_pop_fields",
	id?: number,
	role_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	role_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_series_collaborators_stddev_samp_fields"]: {
	__typename: "voyce_series_collaborators_stddev_samp_fields",
	id?: number,
	role_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	role_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_series_collaborators" */
["voyce_series_collaborators_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_collaborators_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_collaborators_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	role_id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_series_collaborators_sum_fields"]: {
	__typename: "voyce_series_collaborators_sum_fields",
	id?: number,
	role_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	role_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_update_column"]: voyce_series_collaborators_update_column;
	["voyce_series_collaborators_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_collaborators_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_collaborators_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_collaborators_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_collaborators_var_pop_fields"]: {
	__typename: "voyce_series_collaborators_var_pop_fields",
	id?: number,
	role_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	role_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_series_collaborators_var_samp_fields"]: {
	__typename: "voyce_series_collaborators_var_samp_fields",
	id?: number,
	role_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	role_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_series_collaborators_variance_fields"]: {
	__typename: "voyce_series_collaborators_variance_fields",
	id?: number,
	role_id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.series_collaborators" */
["voyce_series_collaborators_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	role_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** unique or primary key constraints on table "voyce.series" */
["voyce_series_constraint"]: voyce_series_constraint;
	/** columns and relationships of "voyce.series_donations" */
["voyce_series_donations"]: {
	__typename: "voyce_series_donations",
	amount: GraphQLTypes["numeric"],
	created_at: GraphQLTypes["timestamptz"],
	currency: string,
	id: number,
	message: string,
	series_id: number,
	user_id: number
};
	/** aggregated selection of "voyce.series_donations" */
["voyce_series_donations_aggregate"]: {
	__typename: "voyce_series_donations_aggregate",
	aggregate?: GraphQLTypes["voyce_series_donations_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_donations"]>
};
	/** aggregate fields of "voyce.series_donations" */
["voyce_series_donations_aggregate_fields"]: {
	__typename: "voyce_series_donations_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_donations_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_donations_max_fields"],
	min?: GraphQLTypes["voyce_series_donations_min_fields"],
	stddev?: GraphQLTypes["voyce_series_donations_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_donations_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_donations_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_donations_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_donations_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_donations_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_donations_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_donations_avg_fields"]: {
	__typename: "voyce_series_donations_avg_fields",
	amount?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_donations". All fields are combined with a logical 'AND'. */
["voyce_series_donations_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_donations_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_donations_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_donations_bool_exp"]>,
	amount?: GraphQLTypes["numeric_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	currency?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	message?: GraphQLTypes["String_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_donations" */
["voyce_series_donations_constraint"]: voyce_series_donations_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_donations" */
["voyce_series_donations_inc_input"]: {
		amount?: GraphQLTypes["numeric"],
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.series_donations" */
["voyce_series_donations_insert_input"]: {
		amount?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	currency?: string,
	id?: number,
	message?: string,
	series_id?: number,
	user_id?: number
};
	/** aggregate max on columns */
["voyce_series_donations_max_fields"]: {
	__typename: "voyce_series_donations_max_fields",
	amount?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	currency?: string,
	id?: number,
	message?: string,
	series_id?: number,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_series_donations_min_fields"]: {
	__typename: "voyce_series_donations_min_fields",
	amount?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	currency?: string,
	id?: number,
	message?: string,
	series_id?: number,
	user_id?: number
};
	/** response of any mutation on the table "voyce.series_donations" */
["voyce_series_donations_mutation_response"]: {
	__typename: "voyce_series_donations_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_donations"]>
};
	/** on_conflict condition type for table "voyce.series_donations" */
["voyce_series_donations_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_donations_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_donations_update_column"]>,
	where?: GraphQLTypes["voyce_series_donations_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_donations". */
["voyce_series_donations_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	currency?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	message?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_donations */
["voyce_series_donations_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_donations" */
["voyce_series_donations_select_column"]: voyce_series_donations_select_column;
	/** input type for updating data in table "voyce.series_donations" */
["voyce_series_donations_set_input"]: {
		amount?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	currency?: string,
	id?: number,
	message?: string,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_series_donations_stddev_fields"]: {
	__typename: "voyce_series_donations_stddev_fields",
	amount?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_donations_stddev_pop_fields"]: {
	__typename: "voyce_series_donations_stddev_pop_fields",
	amount?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_donations_stddev_samp_fields"]: {
	__typename: "voyce_series_donations_stddev_samp_fields",
	amount?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_series_donations" */
["voyce_series_donations_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_donations_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_donations_stream_cursor_value_input"]: {
		amount?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	currency?: string,
	id?: number,
	message?: string,
	series_id?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_series_donations_sum_fields"]: {
	__typename: "voyce_series_donations_sum_fields",
	amount?: GraphQLTypes["numeric"],
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** update columns of table "voyce.series_donations" */
["voyce_series_donations_update_column"]: voyce_series_donations_update_column;
	["voyce_series_donations_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_donations_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_donations_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_donations_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_donations_var_pop_fields"]: {
	__typename: "voyce_series_donations_var_pop_fields",
	amount?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_donations_var_samp_fields"]: {
	__typename: "voyce_series_donations_var_samp_fields",
	amount?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_series_donations_variance_fields"]: {
	__typename: "voyce_series_donations_variance_fields",
	amount?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.series_favorites" */
["voyce_series_favorites"]: {
	__typename: "voyce_series_favorites",
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	/** An object relationship */
	series: GraphQLTypes["voyce_series"],
	series_id: number,
	user_id: number
};
	/** aggregated selection of "voyce.series_favorites" */
["voyce_series_favorites_aggregate"]: {
	__typename: "voyce_series_favorites_aggregate",
	aggregate?: GraphQLTypes["voyce_series_favorites_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_favorites"]>
};
	/** aggregate fields of "voyce.series_favorites" */
["voyce_series_favorites_aggregate_fields"]: {
	__typename: "voyce_series_favorites_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_favorites_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_favorites_max_fields"],
	min?: GraphQLTypes["voyce_series_favorites_min_fields"],
	stddev?: GraphQLTypes["voyce_series_favorites_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_favorites_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_favorites_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_favorites_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_favorites_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_favorites_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_favorites_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_favorites_avg_fields"]: {
	__typename: "voyce_series_favorites_avg_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_favorites". All fields are combined with a logical 'AND'. */
["voyce_series_favorites_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_favorites_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_favorites_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_favorites_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_favorites" */
["voyce_series_favorites_constraint"]: voyce_series_favorites_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_favorites" */
["voyce_series_favorites_inc_input"]: {
		id?: number,
	series_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.series_favorites" */
["voyce_series_favorites_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	user_id?: number
};
	/** aggregate max on columns */
["voyce_series_favorites_max_fields"]: {
	__typename: "voyce_series_favorites_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_series_favorites_min_fields"]: {
	__typename: "voyce_series_favorites_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** response of any mutation on the table "voyce.series_favorites" */
["voyce_series_favorites_mutation_response"]: {
	__typename: "voyce_series_favorites_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_favorites"]>
};
	/** on_conflict condition type for table "voyce.series_favorites" */
["voyce_series_favorites_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_favorites_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_favorites_update_column"]>,
	where?: GraphQLTypes["voyce_series_favorites_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_favorites". */
["voyce_series_favorites_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_favorites */
["voyce_series_favorites_pk_columns_input"]: {
		id: number,
	series_id: number
};
	/** select columns of table "voyce.series_favorites" */
["voyce_series_favorites_select_column"]: voyce_series_favorites_select_column;
	/** input type for updating data in table "voyce.series_favorites" */
["voyce_series_favorites_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_series_favorites_stddev_fields"]: {
	__typename: "voyce_series_favorites_stddev_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_favorites_stddev_pop_fields"]: {
	__typename: "voyce_series_favorites_stddev_pop_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_favorites_stddev_samp_fields"]: {
	__typename: "voyce_series_favorites_stddev_samp_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_series_favorites" */
["voyce_series_favorites_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_favorites_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_favorites_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_series_favorites_sum_fields"]: {
	__typename: "voyce_series_favorites_sum_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** update columns of table "voyce.series_favorites" */
["voyce_series_favorites_update_column"]: voyce_series_favorites_update_column;
	["voyce_series_favorites_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_favorites_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_favorites_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_favorites_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_favorites_var_pop_fields"]: {
	__typename: "voyce_series_favorites_var_pop_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_favorites_var_samp_fields"]: {
	__typename: "voyce_series_favorites_var_samp_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_series_favorites_variance_fields"]: {
	__typename: "voyce_series_favorites_variance_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.series_featured_panels" */
["voyce_series_featured_panels"]: {
	__typename: "voyce_series_featured_panels",
	id: number,
	image?: string,
	series_id?: number
};
	/** aggregated selection of "voyce.series_featured_panels" */
["voyce_series_featured_panels_aggregate"]: {
	__typename: "voyce_series_featured_panels_aggregate",
	aggregate?: GraphQLTypes["voyce_series_featured_panels_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_featured_panels"]>
};
	["voyce_series_featured_panels_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_series_featured_panels_aggregate_bool_exp_count"]
};
	["voyce_series_featured_panels_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_series_featured_panels_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_series_featured_panels_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.series_featured_panels" */
["voyce_series_featured_panels_aggregate_fields"]: {
	__typename: "voyce_series_featured_panels_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_featured_panels_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_featured_panels_max_fields"],
	min?: GraphQLTypes["voyce_series_featured_panels_min_fields"],
	stddev?: GraphQLTypes["voyce_series_featured_panels_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_featured_panels_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_featured_panels_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_featured_panels_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_featured_panels_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_featured_panels_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_featured_panels_variance_fields"]
};
	/** order by aggregate values of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_series_featured_panels_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_series_featured_panels_max_order_by"],
	min?: GraphQLTypes["voyce_series_featured_panels_min_order_by"],
	stddev?: GraphQLTypes["voyce_series_featured_panels_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_series_featured_panels_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_series_featured_panels_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_series_featured_panels_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_series_featured_panels_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_series_featured_panels_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_series_featured_panels_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.series_featured_panels" */
["voyce_series_featured_panels_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_series_featured_panels_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_series_featured_panels_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_series_featured_panels_avg_fields"]: {
	__typename: "voyce_series_featured_panels_avg_fields",
	id?: number,
	series_id?: number
};
	/** order by avg() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.series_featured_panels". All fields are combined with a logical 'AND'. */
["voyce_series_featured_panels_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_featured_panels_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_featured_panels_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_featured_panels_bool_exp"]>,
	id?: GraphQLTypes["Int_comparison_exp"],
	image?: GraphQLTypes["String_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_featured_panels" */
["voyce_series_featured_panels_constraint"]: voyce_series_featured_panels_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_featured_panels" */
["voyce_series_featured_panels_inc_input"]: {
		id?: number,
	series_id?: number
};
	/** input type for inserting data into table "voyce.series_featured_panels" */
["voyce_series_featured_panels_insert_input"]: {
		id?: number,
	image?: string,
	series_id?: number
};
	/** aggregate max on columns */
["voyce_series_featured_panels_max_fields"]: {
	__typename: "voyce_series_featured_panels_max_fields",
	id?: number,
	image?: string,
	series_id?: number
};
	/** order by max() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_max_order_by"]: {
		id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_series_featured_panels_min_fields"]: {
	__typename: "voyce_series_featured_panels_min_fields",
	id?: number,
	image?: string,
	series_id?: number
};
	/** order by min() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_min_order_by"]: {
		id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.series_featured_panels" */
["voyce_series_featured_panels_mutation_response"]: {
	__typename: "voyce_series_featured_panels_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_featured_panels"]>
};
	/** on_conflict condition type for table "voyce.series_featured_panels" */
["voyce_series_featured_panels_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_featured_panels_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_featured_panels_update_column"]>,
	where?: GraphQLTypes["voyce_series_featured_panels_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_featured_panels". */
["voyce_series_featured_panels_order_by"]: {
		id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_featured_panels */
["voyce_series_featured_panels_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_select_column"]: voyce_series_featured_panels_select_column;
	/** input type for updating data in table "voyce.series_featured_panels" */
["voyce_series_featured_panels_set_input"]: {
		id?: number,
	image?: string,
	series_id?: number
};
	/** aggregate stddev on columns */
["voyce_series_featured_panels_stddev_fields"]: {
	__typename: "voyce_series_featured_panels_stddev_fields",
	id?: number,
	series_id?: number
};
	/** order by stddev() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_series_featured_panels_stddev_pop_fields"]: {
	__typename: "voyce_series_featured_panels_stddev_pop_fields",
	id?: number,
	series_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_series_featured_panels_stddev_samp_fields"]: {
	__typename: "voyce_series_featured_panels_stddev_samp_fields",
	id?: number,
	series_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_series_featured_panels" */
["voyce_series_featured_panels_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_featured_panels_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_featured_panels_stream_cursor_value_input"]: {
		id?: number,
	image?: string,
	series_id?: number
};
	/** aggregate sum on columns */
["voyce_series_featured_panels_sum_fields"]: {
	__typename: "voyce_series_featured_panels_sum_fields",
	id?: number,
	series_id?: number
};
	/** order by sum() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_update_column"]: voyce_series_featured_panels_update_column;
	["voyce_series_featured_panels_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_featured_panels_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_featured_panels_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_featured_panels_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_featured_panels_var_pop_fields"]: {
	__typename: "voyce_series_featured_panels_var_pop_fields",
	id?: number,
	series_id?: number
};
	/** order by var_pop() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_series_featured_panels_var_samp_fields"]: {
	__typename: "voyce_series_featured_panels_var_samp_fields",
	id?: number,
	series_id?: number
};
	/** order by var_samp() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_series_featured_panels_variance_fields"]: {
	__typename: "voyce_series_featured_panels_variance_fields",
	id?: number,
	series_id?: number
};
	/** order by variance() on columns of table "voyce.series_featured_panels" */
["voyce_series_featured_panels_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.series_genre" */
["voyce_series_genre"]: {
	__typename: "voyce_series_genre",
	created_at: GraphQLTypes["timestamp"],
	/** An object relationship */
	genre?: GraphQLTypes["voyce_genres"],
	genre_id: number,
	id: number,
	/** An object relationship */
	series?: GraphQLTypes["voyce_series"],
	series_id: number,
	sub_genre: boolean
};
	/** aggregated selection of "voyce.series_genre" */
["voyce_series_genre_aggregate"]: {
	__typename: "voyce_series_genre_aggregate",
	aggregate?: GraphQLTypes["voyce_series_genre_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_genre"]>
};
	["voyce_series_genre_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["voyce_series_genre_aggregate_bool_exp_bool_and"],
	bool_or?: GraphQLTypes["voyce_series_genre_aggregate_bool_exp_bool_or"],
	count?: GraphQLTypes["voyce_series_genre_aggregate_bool_exp_count"]
};
	["voyce_series_genre_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_series_genre_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_series_genre_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_series_genre_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_series_genre_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_series_genre_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_series_genre_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.series_genre" */
["voyce_series_genre_aggregate_fields"]: {
	__typename: "voyce_series_genre_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_genre_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_genre_max_fields"],
	min?: GraphQLTypes["voyce_series_genre_min_fields"],
	stddev?: GraphQLTypes["voyce_series_genre_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_genre_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_genre_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_genre_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_genre_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_genre_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_genre_variance_fields"]
};
	/** order by aggregate values of table "voyce.series_genre" */
["voyce_series_genre_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_series_genre_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_series_genre_max_order_by"],
	min?: GraphQLTypes["voyce_series_genre_min_order_by"],
	stddev?: GraphQLTypes["voyce_series_genre_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_series_genre_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_series_genre_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_series_genre_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_series_genre_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_series_genre_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_series_genre_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.series_genre" */
["voyce_series_genre_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_series_genre_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_series_genre_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_series_genre_avg_fields"]: {
	__typename: "voyce_series_genre_avg_fields",
	genre_id?: number,
	id?: number,
	series_id?: number
};
	/** order by avg() on columns of table "voyce.series_genre" */
["voyce_series_genre_avg_order_by"]: {
		genre_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.series_genre". All fields are combined with a logical 'AND'. */
["voyce_series_genre_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_genre_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_genre_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_genre_bool_exp"]>,
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	genre?: GraphQLTypes["voyce_genres_bool_exp"],
	genre_id?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	sub_genre?: GraphQLTypes["Boolean_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_genre" */
["voyce_series_genre_constraint"]: voyce_series_genre_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_genre" */
["voyce_series_genre_inc_input"]: {
		genre_id?: number,
	id?: number,
	series_id?: number
};
	/** input type for inserting data into table "voyce.series_genre" */
["voyce_series_genre_insert_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	genre?: GraphQLTypes["voyce_genres_obj_rel_insert_input"],
	genre_id?: number,
	id?: number,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	sub_genre?: boolean
};
	/** aggregate max on columns */
["voyce_series_genre_max_fields"]: {
	__typename: "voyce_series_genre_max_fields",
	created_at?: GraphQLTypes["timestamp"],
	genre_id?: number,
	id?: number,
	series_id?: number
};
	/** order by max() on columns of table "voyce.series_genre" */
["voyce_series_genre_max_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	genre_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_series_genre_min_fields"]: {
	__typename: "voyce_series_genre_min_fields",
	created_at?: GraphQLTypes["timestamp"],
	genre_id?: number,
	id?: number,
	series_id?: number
};
	/** order by min() on columns of table "voyce.series_genre" */
["voyce_series_genre_min_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	genre_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.series_genre" */
["voyce_series_genre_mutation_response"]: {
	__typename: "voyce_series_genre_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_genre"]>
};
	/** on_conflict condition type for table "voyce.series_genre" */
["voyce_series_genre_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_genre_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_genre_update_column"]>,
	where?: GraphQLTypes["voyce_series_genre_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_genre". */
["voyce_series_genre_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	genre?: GraphQLTypes["voyce_genres_order_by"],
	genre_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	sub_genre?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_genre */
["voyce_series_genre_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_genre" */
["voyce_series_genre_select_column"]: voyce_series_genre_select_column;
	/** select "voyce_series_genre_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.series_genre" */
["voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_and_arguments_columns"]: voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_series_genre_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.series_genre" */
["voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_or_arguments_columns"]: voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.series_genre" */
["voyce_series_genre_set_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	genre_id?: number,
	id?: number,
	series_id?: number,
	sub_genre?: boolean
};
	/** aggregate stddev on columns */
["voyce_series_genre_stddev_fields"]: {
	__typename: "voyce_series_genre_stddev_fields",
	genre_id?: number,
	id?: number,
	series_id?: number
};
	/** order by stddev() on columns of table "voyce.series_genre" */
["voyce_series_genre_stddev_order_by"]: {
		genre_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_series_genre_stddev_pop_fields"]: {
	__typename: "voyce_series_genre_stddev_pop_fields",
	genre_id?: number,
	id?: number,
	series_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.series_genre" */
["voyce_series_genre_stddev_pop_order_by"]: {
		genre_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_series_genre_stddev_samp_fields"]: {
	__typename: "voyce_series_genre_stddev_samp_fields",
	genre_id?: number,
	id?: number,
	series_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.series_genre" */
["voyce_series_genre_stddev_samp_order_by"]: {
		genre_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_series_genre" */
["voyce_series_genre_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_genre_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_genre_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	genre_id?: number,
	id?: number,
	series_id?: number,
	sub_genre?: boolean
};
	/** aggregate sum on columns */
["voyce_series_genre_sum_fields"]: {
	__typename: "voyce_series_genre_sum_fields",
	genre_id?: number,
	id?: number,
	series_id?: number
};
	/** order by sum() on columns of table "voyce.series_genre" */
["voyce_series_genre_sum_order_by"]: {
		genre_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.series_genre" */
["voyce_series_genre_update_column"]: voyce_series_genre_update_column;
	["voyce_series_genre_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_genre_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_genre_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_genre_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_genre_var_pop_fields"]: {
	__typename: "voyce_series_genre_var_pop_fields",
	genre_id?: number,
	id?: number,
	series_id?: number
};
	/** order by var_pop() on columns of table "voyce.series_genre" */
["voyce_series_genre_var_pop_order_by"]: {
		genre_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_series_genre_var_samp_fields"]: {
	__typename: "voyce_series_genre_var_samp_fields",
	genre_id?: number,
	id?: number,
	series_id?: number
};
	/** order by var_samp() on columns of table "voyce.series_genre" */
["voyce_series_genre_var_samp_order_by"]: {
		genre_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_series_genre_variance_fields"]: {
	__typename: "voyce_series_genre_variance_fields",
	genre_id?: number,
	id?: number,
	series_id?: number
};
	/** order by variance() on columns of table "voyce.series_genre" */
["voyce_series_genre_variance_order_by"]: {
		genre_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants"]: {
	__typename: "voyce_series_giveaway_participants",
	created_at: GraphQLTypes["timestamptz"],
	email: string,
	first_name: string,
	from_series: boolean,
	id: number,
	last_name: string,
	series_id: number
};
	/** aggregated selection of "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_aggregate"]: {
	__typename: "voyce_series_giveaway_participants_aggregate",
	aggregate?: GraphQLTypes["voyce_series_giveaway_participants_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_giveaway_participants"]>
};
	/** aggregate fields of "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_aggregate_fields"]: {
	__typename: "voyce_series_giveaway_participants_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_giveaway_participants_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_giveaway_participants_max_fields"],
	min?: GraphQLTypes["voyce_series_giveaway_participants_min_fields"],
	stddev?: GraphQLTypes["voyce_series_giveaway_participants_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_giveaway_participants_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_giveaway_participants_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_giveaway_participants_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_giveaway_participants_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_giveaway_participants_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_giveaway_participants_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_giveaway_participants_avg_fields"]: {
	__typename: "voyce_series_giveaway_participants_avg_fields",
	id?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_giveaway_participants". All fields are combined with a logical 'AND'. */
["voyce_series_giveaway_participants_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_giveaway_participants_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_giveaway_participants_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_giveaway_participants_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	email?: GraphQLTypes["String_comparison_exp"],
	first_name?: GraphQLTypes["String_comparison_exp"],
	from_series?: GraphQLTypes["Boolean_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	last_name?: GraphQLTypes["String_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_constraint"]: voyce_series_giveaway_participants_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_inc_input"]: {
		id?: number,
	series_id?: number
};
	/** input type for inserting data into table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	first_name?: string,
	from_series?: boolean,
	id?: number,
	last_name?: string,
	series_id?: number
};
	/** aggregate max on columns */
["voyce_series_giveaway_participants_max_fields"]: {
	__typename: "voyce_series_giveaway_participants_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	first_name?: string,
	id?: number,
	last_name?: string,
	series_id?: number
};
	/** aggregate min on columns */
["voyce_series_giveaway_participants_min_fields"]: {
	__typename: "voyce_series_giveaway_participants_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	first_name?: string,
	id?: number,
	last_name?: string,
	series_id?: number
};
	/** response of any mutation on the table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_mutation_response"]: {
	__typename: "voyce_series_giveaway_participants_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_giveaway_participants"]>
};
	/** on_conflict condition type for table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_giveaway_participants_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_giveaway_participants_update_column"]>,
	where?: GraphQLTypes["voyce_series_giveaway_participants_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_giveaway_participants". */
["voyce_series_giveaway_participants_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	email?: GraphQLTypes["order_by"],
	first_name?: GraphQLTypes["order_by"],
	from_series?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	last_name?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_giveaway_participants */
["voyce_series_giveaway_participants_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_select_column"]: voyce_series_giveaway_participants_select_column;
	/** input type for updating data in table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	first_name?: string,
	from_series?: boolean,
	id?: number,
	last_name?: string,
	series_id?: number
};
	/** aggregate stddev on columns */
["voyce_series_giveaway_participants_stddev_fields"]: {
	__typename: "voyce_series_giveaway_participants_stddev_fields",
	id?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_giveaway_participants_stddev_pop_fields"]: {
	__typename: "voyce_series_giveaway_participants_stddev_pop_fields",
	id?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_giveaway_participants_stddev_samp_fields"]: {
	__typename: "voyce_series_giveaway_participants_stddev_samp_fields",
	id?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_series_giveaway_participants" */
["voyce_series_giveaway_participants_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_giveaway_participants_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_giveaway_participants_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	email?: string,
	first_name?: string,
	from_series?: boolean,
	id?: number,
	last_name?: string,
	series_id?: number
};
	/** aggregate sum on columns */
["voyce_series_giveaway_participants_sum_fields"]: {
	__typename: "voyce_series_giveaway_participants_sum_fields",
	id?: number,
	series_id?: number
};
	/** update columns of table "voyce.series_giveaway_participants" */
["voyce_series_giveaway_participants_update_column"]: voyce_series_giveaway_participants_update_column;
	["voyce_series_giveaway_participants_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_giveaway_participants_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_giveaway_participants_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_giveaway_participants_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_giveaway_participants_var_pop_fields"]: {
	__typename: "voyce_series_giveaway_participants_var_pop_fields",
	id?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_giveaway_participants_var_samp_fields"]: {
	__typename: "voyce_series_giveaway_participants_var_samp_fields",
	id?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_series_giveaway_participants_variance_fields"]: {
	__typename: "voyce_series_giveaway_participants_variance_fields",
	id?: number,
	series_id?: number
};
	/** columns and relationships of "voyce.series_hot_panels" */
["voyce_series_hot_panels"]: {
	__typename: "voyce_series_hot_panels",
	active: boolean,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	image: string,
	order: GraphQLTypes["smallint"],
	series_id: number,
	spoiler: boolean,
	updated_at: GraphQLTypes["timestamptz"],
	user_id: number
};
	/** aggregated selection of "voyce.series_hot_panels" */
["voyce_series_hot_panels_aggregate"]: {
	__typename: "voyce_series_hot_panels_aggregate",
	aggregate?: GraphQLTypes["voyce_series_hot_panels_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_hot_panels"]>
};
	["voyce_series_hot_panels_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["voyce_series_hot_panels_aggregate_bool_exp_bool_and"],
	bool_or?: GraphQLTypes["voyce_series_hot_panels_aggregate_bool_exp_bool_or"],
	count?: GraphQLTypes["voyce_series_hot_panels_aggregate_bool_exp_count"]
};
	["voyce_series_hot_panels_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_series_hot_panels_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_series_hot_panels_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_series_hot_panels_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_series_hot_panels_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_series_hot_panels_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_series_hot_panels_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.series_hot_panels" */
["voyce_series_hot_panels_aggregate_fields"]: {
	__typename: "voyce_series_hot_panels_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_hot_panels_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_hot_panels_max_fields"],
	min?: GraphQLTypes["voyce_series_hot_panels_min_fields"],
	stddev?: GraphQLTypes["voyce_series_hot_panels_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_hot_panels_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_hot_panels_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_hot_panels_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_hot_panels_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_hot_panels_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_hot_panels_variance_fields"]
};
	/** order by aggregate values of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_series_hot_panels_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_series_hot_panels_max_order_by"],
	min?: GraphQLTypes["voyce_series_hot_panels_min_order_by"],
	stddev?: GraphQLTypes["voyce_series_hot_panels_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_series_hot_panels_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_series_hot_panels_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_series_hot_panels_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_series_hot_panels_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_series_hot_panels_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_series_hot_panels_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.series_hot_panels" */
["voyce_series_hot_panels_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_series_hot_panels_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_series_hot_panels_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_series_hot_panels_avg_fields"]: {
	__typename: "voyce_series_hot_panels_avg_fields",
	id?: number,
	order?: number,
	series_id?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.series_hot_panels". All fields are combined with a logical 'AND'. */
["voyce_series_hot_panels_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_hot_panels_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_hot_panels_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_hot_panels_bool_exp"]>,
	active?: GraphQLTypes["Boolean_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	image?: GraphQLTypes["String_comparison_exp"],
	order?: GraphQLTypes["smallint_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	spoiler?: GraphQLTypes["Boolean_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_hot_panels" */
["voyce_series_hot_panels_constraint"]: voyce_series_hot_panels_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_hot_panels" */
["voyce_series_hot_panels_inc_input"]: {
		id?: number,
	order?: GraphQLTypes["smallint"],
	series_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.series_hot_panels" */
["voyce_series_hot_panels_insert_input"]: {
		active?: boolean,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	order?: GraphQLTypes["smallint"],
	series_id?: number,
	spoiler?: boolean,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_series_hot_panels_max_fields"]: {
	__typename: "voyce_series_hot_panels_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	order?: GraphQLTypes["smallint"],
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** order by max() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_max_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_series_hot_panels_min_fields"]: {
	__typename: "voyce_series_hot_panels_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	order?: GraphQLTypes["smallint"],
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** order by min() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_min_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.series_hot_panels" */
["voyce_series_hot_panels_mutation_response"]: {
	__typename: "voyce_series_hot_panels_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_hot_panels"]>
};
	/** on_conflict condition type for table "voyce.series_hot_panels" */
["voyce_series_hot_panels_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_hot_panels_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_hot_panels_update_column"]>,
	where?: GraphQLTypes["voyce_series_hot_panels_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_hot_panels". */
["voyce_series_hot_panels_order_by"]: {
		active?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	image?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	spoiler?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_hot_panels */
["voyce_series_hot_panels_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_select_column"]: voyce_series_hot_panels_select_column;
	/** select "voyce_series_hot_panels_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_and_arguments_columns"]: voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_series_hot_panels_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_or_arguments_columns"]: voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.series_hot_panels" */
["voyce_series_hot_panels_set_input"]: {
		active?: boolean,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	order?: GraphQLTypes["smallint"],
	series_id?: number,
	spoiler?: boolean,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_series_hot_panels_stddev_fields"]: {
	__typename: "voyce_series_hot_panels_stddev_fields",
	id?: number,
	order?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_series_hot_panels_stddev_pop_fields"]: {
	__typename: "voyce_series_hot_panels_stddev_pop_fields",
	id?: number,
	order?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_series_hot_panels_stddev_samp_fields"]: {
	__typename: "voyce_series_hot_panels_stddev_samp_fields",
	id?: number,
	order?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_series_hot_panels" */
["voyce_series_hot_panels_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_hot_panels_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_hot_panels_stream_cursor_value_input"]: {
		active?: boolean,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	image?: string,
	order?: GraphQLTypes["smallint"],
	series_id?: number,
	spoiler?: boolean,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_series_hot_panels_sum_fields"]: {
	__typename: "voyce_series_hot_panels_sum_fields",
	id?: number,
	order?: GraphQLTypes["smallint"],
	series_id?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_update_column"]: voyce_series_hot_panels_update_column;
	["voyce_series_hot_panels_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_hot_panels_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_hot_panels_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_hot_panels_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_hot_panels_var_pop_fields"]: {
	__typename: "voyce_series_hot_panels_var_pop_fields",
	id?: number,
	order?: number,
	series_id?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_series_hot_panels_var_samp_fields"]: {
	__typename: "voyce_series_hot_panels_var_samp_fields",
	id?: number,
	order?: number,
	series_id?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_series_hot_panels_variance_fields"]: {
	__typename: "voyce_series_hot_panels_variance_fields",
	id?: number,
	order?: number,
	series_id?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.series_hot_panels" */
["voyce_series_hot_panels_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** input type for incrementing numeric columns in table "voyce.series" */
["voyce_series_inc_input"]: {
		average_rating?: GraphQLTypes["float8"],
	chapter_count?: number,
	coin_price?: GraphQLTypes["smallint"],
	comic_type_id?: number,
	frequency_mask?: number,
	id?: number,
	is_approved?: GraphQLTypes["smallint"],
	is_intro?: GraphQLTypes["smallint"],
	is_original?: GraphQLTypes["smallint"],
	is_pilots?: GraphQLTypes["smallint"],
	is_recommended?: GraphQLTypes["smallint"],
	locked_chapters_amount?: number,
	pilots_votes_count?: number,
	publish?: GraphQLTypes["smallint"],
	rating_count?: number,
	subscriber_count?: number,
	trending_position?: number,
	user_id?: number,
	view_count?: number
};
	/** input type for inserting data into table "voyce.series" */
["voyce_series_insert_input"]: {
		animated_cover_image?: string,
	audio_allowed?: boolean,
	author?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	average_rating?: GraphQLTypes["float8"],
	back_image?: string,
	banner_bg?: string,
	book_reverse_view?: boolean,
	book_view?: boolean,
	chapter_comments?: GraphQLTypes["voyce_chapter_comments_arr_rel_insert_input"],
	chapter_count?: number,
	chapter_panels?: GraphQLTypes["voyce_chapter_panels_arr_rel_insert_input"],
	chapters?: GraphQLTypes["voyce_chapters_arr_rel_insert_input"],
	chapters_hide?: string,
	character_cover?: string,
	coin_price?: GraphQLTypes["smallint"],
	collaborators?: GraphQLTypes["voyce_series_collaborators_arr_rel_insert_input"],
	comic_type_id?: number,
	cover_image?: string,
	cover_image_mobile?: string,
	created_at?: GraphQLTypes["timestamp"],
	csat?: boolean,
	default_view?: string,
	description?: string,
	feature?: GraphQLTypes["bit"],
	featured_panels?: GraphQLTypes["voyce_series_featured_panels_arr_rel_insert_input"],
	followers?: GraphQLTypes["voyce_series_subscription_arr_rel_insert_input"],
	frequency?: string,
	frequency_mask?: number,
	front_image?: string,
	genres?: GraphQLTypes["voyce_series_genre_arr_rel_insert_input"],
	hot_panels?: GraphQLTypes["voyce_series_hot_panels_arr_rel_insert_input"],
	id?: number,
	is_animated_cover_allowed?: boolean,
	is_approved?: GraphQLTypes["smallint"],
	is_deleted?: boolean,
	is_intro?: GraphQLTypes["smallint"],
	is_locked?: boolean,
	is_original?: GraphQLTypes["smallint"],
	is_pilots?: GraphQLTypes["smallint"],
	is_recommended?: GraphQLTypes["smallint"],
	klaviyo_id?: string,
	klaviyo_list_id?: string,
	latest_chapter_update?: GraphQLTypes["timestamp"],
	likes?: GraphQLTypes["voyce_chapter_likes_arr_rel_insert_input"],
	likes_count?: GraphQLTypes["voyce_series_likes_count_mat_obj_rel_insert_input"],
	lock_config?: GraphQLTypes["voyce_series_lock_config_obj_rel_insert_input"],
	locked?: boolean,
	locked_chapters_amount?: number,
	notification_settings?: GraphQLTypes["voyce_notification_series_settings_obj_rel_insert_input"],
	paginated_view?: boolean,
	pilots_votes_count?: number,
	portfolio_desc?: string,
	publish?: GraphQLTypes["smallint"],
	rating_count?: number,
	reading_view?: string,
	scroll_view?: boolean,
	series_tags_junctions?: GraphQLTypes["voyce_series_tags_junction_arr_rel_insert_input"],
	short_desc?: string,
	slug?: string,
	sponsorship_config?: GraphQLTypes["voyce_series_sponsorships_configuration_obj_rel_insert_input"],
	sponsorships?: GraphQLTypes["voyce_sponsorships_arr_rel_insert_input"],
	status?: string,
	subscriber_count?: number,
	support_msg?: string,
	team_spotlight?: boolean,
	thumbnail?: string,
	thumbnail_box?: string,
	title?: string,
	trending?: GraphQLTypes["bit"],
	trending_position?: number,
	type?: GraphQLTypes["voyce_comic_types_obj_rel_insert_input"],
	updated_at?: GraphQLTypes["timestamp"],
	user_display_field?: string,
	user_id?: number,
	users_series_junctions?: GraphQLTypes["voyce_users_series_junction_arr_rel_insert_input"],
	view_count?: number,
	views?: GraphQLTypes["voyce_chapter_views_arr_rel_insert_input"],
	views_counts?: GraphQLTypes["voyce_pg_rs_series_views_count_obj_rel_insert_input"],
	welcome_msg?: string
};
	/** columns and relationships of "voyce.series_layouts" */
["voyce_series_layouts"]: {
	__typename: "voyce_series_layouts",
	active: boolean,
	bg_image: string,
	characters: GraphQLTypes["json"],
	comments: GraphQLTypes["json"],
	dl_banner: GraphQLTypes["json"],
	id: number,
	layout: GraphQLTypes["smallint"],
	logo: string,
	menu: GraphQLTypes["json"],
	series_id: number,
	socials: GraphQLTypes["json"],
	story: GraphQLTypes["json"]
};
	/** aggregated selection of "voyce.series_layouts" */
["voyce_series_layouts_aggregate"]: {
	__typename: "voyce_series_layouts_aggregate",
	aggregate?: GraphQLTypes["voyce_series_layouts_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_layouts"]>
};
	/** aggregate fields of "voyce.series_layouts" */
["voyce_series_layouts_aggregate_fields"]: {
	__typename: "voyce_series_layouts_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_layouts_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_layouts_max_fields"],
	min?: GraphQLTypes["voyce_series_layouts_min_fields"],
	stddev?: GraphQLTypes["voyce_series_layouts_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_layouts_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_layouts_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_layouts_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_layouts_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_layouts_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_layouts_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_layouts_avg_fields"]: {
	__typename: "voyce_series_layouts_avg_fields",
	id?: number,
	layout?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_layouts". All fields are combined with a logical 'AND'. */
["voyce_series_layouts_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_layouts_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_layouts_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_layouts_bool_exp"]>,
	active?: GraphQLTypes["Boolean_comparison_exp"],
	bg_image?: GraphQLTypes["String_comparison_exp"],
	characters?: GraphQLTypes["json_comparison_exp"],
	comments?: GraphQLTypes["json_comparison_exp"],
	dl_banner?: GraphQLTypes["json_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	layout?: GraphQLTypes["smallint_comparison_exp"],
	logo?: GraphQLTypes["String_comparison_exp"],
	menu?: GraphQLTypes["json_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	socials?: GraphQLTypes["json_comparison_exp"],
	story?: GraphQLTypes["json_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_layouts" */
["voyce_series_layouts_constraint"]: voyce_series_layouts_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_layouts" */
["voyce_series_layouts_inc_input"]: {
		id?: number,
	layout?: GraphQLTypes["smallint"],
	series_id?: number
};
	/** input type for inserting data into table "voyce.series_layouts" */
["voyce_series_layouts_insert_input"]: {
		active?: boolean,
	bg_image?: string,
	characters?: GraphQLTypes["json"],
	comments?: GraphQLTypes["json"],
	dl_banner?: GraphQLTypes["json"],
	id?: number,
	layout?: GraphQLTypes["smallint"],
	logo?: string,
	menu?: GraphQLTypes["json"],
	series_id?: number,
	socials?: GraphQLTypes["json"],
	story?: GraphQLTypes["json"]
};
	/** aggregate max on columns */
["voyce_series_layouts_max_fields"]: {
	__typename: "voyce_series_layouts_max_fields",
	bg_image?: string,
	id?: number,
	layout?: GraphQLTypes["smallint"],
	logo?: string,
	series_id?: number
};
	/** aggregate min on columns */
["voyce_series_layouts_min_fields"]: {
	__typename: "voyce_series_layouts_min_fields",
	bg_image?: string,
	id?: number,
	layout?: GraphQLTypes["smallint"],
	logo?: string,
	series_id?: number
};
	/** response of any mutation on the table "voyce.series_layouts" */
["voyce_series_layouts_mutation_response"]: {
	__typename: "voyce_series_layouts_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_layouts"]>
};
	/** on_conflict condition type for table "voyce.series_layouts" */
["voyce_series_layouts_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_layouts_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_layouts_update_column"]>,
	where?: GraphQLTypes["voyce_series_layouts_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_layouts". */
["voyce_series_layouts_order_by"]: {
		active?: GraphQLTypes["order_by"],
	bg_image?: GraphQLTypes["order_by"],
	characters?: GraphQLTypes["order_by"],
	comments?: GraphQLTypes["order_by"],
	dl_banner?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	layout?: GraphQLTypes["order_by"],
	logo?: GraphQLTypes["order_by"],
	menu?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	socials?: GraphQLTypes["order_by"],
	story?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_layouts */
["voyce_series_layouts_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_layouts" */
["voyce_series_layouts_select_column"]: voyce_series_layouts_select_column;
	/** input type for updating data in table "voyce.series_layouts" */
["voyce_series_layouts_set_input"]: {
		active?: boolean,
	bg_image?: string,
	characters?: GraphQLTypes["json"],
	comments?: GraphQLTypes["json"],
	dl_banner?: GraphQLTypes["json"],
	id?: number,
	layout?: GraphQLTypes["smallint"],
	logo?: string,
	menu?: GraphQLTypes["json"],
	series_id?: number,
	socials?: GraphQLTypes["json"],
	story?: GraphQLTypes["json"]
};
	/** aggregate stddev on columns */
["voyce_series_layouts_stddev_fields"]: {
	__typename: "voyce_series_layouts_stddev_fields",
	id?: number,
	layout?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_layouts_stddev_pop_fields"]: {
	__typename: "voyce_series_layouts_stddev_pop_fields",
	id?: number,
	layout?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_layouts_stddev_samp_fields"]: {
	__typename: "voyce_series_layouts_stddev_samp_fields",
	id?: number,
	layout?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_series_layouts" */
["voyce_series_layouts_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_layouts_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_layouts_stream_cursor_value_input"]: {
		active?: boolean,
	bg_image?: string,
	characters?: GraphQLTypes["json"],
	comments?: GraphQLTypes["json"],
	dl_banner?: GraphQLTypes["json"],
	id?: number,
	layout?: GraphQLTypes["smallint"],
	logo?: string,
	menu?: GraphQLTypes["json"],
	series_id?: number,
	socials?: GraphQLTypes["json"],
	story?: GraphQLTypes["json"]
};
	/** aggregate sum on columns */
["voyce_series_layouts_sum_fields"]: {
	__typename: "voyce_series_layouts_sum_fields",
	id?: number,
	layout?: GraphQLTypes["smallint"],
	series_id?: number
};
	/** update columns of table "voyce.series_layouts" */
["voyce_series_layouts_update_column"]: voyce_series_layouts_update_column;
	["voyce_series_layouts_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_layouts_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_layouts_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_layouts_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_layouts_var_pop_fields"]: {
	__typename: "voyce_series_layouts_var_pop_fields",
	id?: number,
	layout?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_layouts_var_samp_fields"]: {
	__typename: "voyce_series_layouts_var_samp_fields",
	id?: number,
	layout?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_series_layouts_variance_fields"]: {
	__typename: "voyce_series_layouts_variance_fields",
	id?: number,
	layout?: number,
	series_id?: number
};
	/** columns and relationships of "voyce.series_leaderboard" */
["voyce_series_leaderboard"]: {
	__typename: "voyce_series_leaderboard",
	amount: GraphQLTypes["numeric"],
	arrow?: string,
	created_at: GraphQLTypes["timestamptz"],
	currency: string,
	date: GraphQLTypes["date"],
	id: number,
	rank: number,
	series_id: number,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.series_leaderboard" */
["voyce_series_leaderboard_aggregate"]: {
	__typename: "voyce_series_leaderboard_aggregate",
	aggregate?: GraphQLTypes["voyce_series_leaderboard_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_leaderboard"]>
};
	/** aggregate fields of "voyce.series_leaderboard" */
["voyce_series_leaderboard_aggregate_fields"]: {
	__typename: "voyce_series_leaderboard_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_leaderboard_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_leaderboard_max_fields"],
	min?: GraphQLTypes["voyce_series_leaderboard_min_fields"],
	stddev?: GraphQLTypes["voyce_series_leaderboard_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_leaderboard_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_leaderboard_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_leaderboard_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_leaderboard_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_leaderboard_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_leaderboard_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_leaderboard_avg_fields"]: {
	__typename: "voyce_series_leaderboard_avg_fields",
	amount?: number,
	id?: number,
	rank?: number,
	series_id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_leaderboard". All fields are combined with a logical 'AND'. */
["voyce_series_leaderboard_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_leaderboard_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_leaderboard_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_leaderboard_bool_exp"]>,
	amount?: GraphQLTypes["numeric_comparison_exp"],
	arrow?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	currency?: GraphQLTypes["String_comparison_exp"],
	date?: GraphQLTypes["date_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	rank?: GraphQLTypes["Int_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_leaderboard" */
["voyce_series_leaderboard_constraint"]: voyce_series_leaderboard_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_leaderboard" */
["voyce_series_leaderboard_inc_input"]: {
		amount?: GraphQLTypes["numeric"],
	id?: number,
	rank?: number,
	series_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.series_leaderboard" */
["voyce_series_leaderboard_insert_input"]: {
		amount?: GraphQLTypes["numeric"],
	arrow?: string,
	created_at?: GraphQLTypes["timestamptz"],
	currency?: string,
	date?: GraphQLTypes["date"],
	id?: number,
	rank?: number,
	series_id?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_series_leaderboard_max_fields"]: {
	__typename: "voyce_series_leaderboard_max_fields",
	amount?: GraphQLTypes["numeric"],
	arrow?: string,
	created_at?: GraphQLTypes["timestamptz"],
	currency?: string,
	date?: GraphQLTypes["date"],
	id?: number,
	rank?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_series_leaderboard_min_fields"]: {
	__typename: "voyce_series_leaderboard_min_fields",
	amount?: GraphQLTypes["numeric"],
	arrow?: string,
	created_at?: GraphQLTypes["timestamptz"],
	currency?: string,
	date?: GraphQLTypes["date"],
	id?: number,
	rank?: number,
	series_id?: number,
	user_id?: number
};
	/** response of any mutation on the table "voyce.series_leaderboard" */
["voyce_series_leaderboard_mutation_response"]: {
	__typename: "voyce_series_leaderboard_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_leaderboard"]>
};
	/** on_conflict condition type for table "voyce.series_leaderboard" */
["voyce_series_leaderboard_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_leaderboard_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_leaderboard_update_column"]>,
	where?: GraphQLTypes["voyce_series_leaderboard_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_leaderboard". */
["voyce_series_leaderboard_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	arrow?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	currency?: GraphQLTypes["order_by"],
	date?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_leaderboard */
["voyce_series_leaderboard_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_leaderboard" */
["voyce_series_leaderboard_select_column"]: voyce_series_leaderboard_select_column;
	/** input type for updating data in table "voyce.series_leaderboard" */
["voyce_series_leaderboard_set_input"]: {
		amount?: GraphQLTypes["numeric"],
	arrow?: string,
	created_at?: GraphQLTypes["timestamptz"],
	currency?: string,
	date?: GraphQLTypes["date"],
	id?: number,
	rank?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_series_leaderboard_stddev_fields"]: {
	__typename: "voyce_series_leaderboard_stddev_fields",
	amount?: number,
	id?: number,
	rank?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_leaderboard_stddev_pop_fields"]: {
	__typename: "voyce_series_leaderboard_stddev_pop_fields",
	amount?: number,
	id?: number,
	rank?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_leaderboard_stddev_samp_fields"]: {
	__typename: "voyce_series_leaderboard_stddev_samp_fields",
	amount?: number,
	id?: number,
	rank?: number,
	series_id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_series_leaderboard" */
["voyce_series_leaderboard_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_leaderboard_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_leaderboard_stream_cursor_value_input"]: {
		amount?: GraphQLTypes["numeric"],
	arrow?: string,
	created_at?: GraphQLTypes["timestamptz"],
	currency?: string,
	date?: GraphQLTypes["date"],
	id?: number,
	rank?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_series_leaderboard_sum_fields"]: {
	__typename: "voyce_series_leaderboard_sum_fields",
	amount?: GraphQLTypes["numeric"],
	id?: number,
	rank?: number,
	series_id?: number,
	user_id?: number
};
	/** update columns of table "voyce.series_leaderboard" */
["voyce_series_leaderboard_update_column"]: voyce_series_leaderboard_update_column;
	["voyce_series_leaderboard_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_leaderboard_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_leaderboard_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_leaderboard_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_leaderboard_var_pop_fields"]: {
	__typename: "voyce_series_leaderboard_var_pop_fields",
	amount?: number,
	id?: number,
	rank?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_leaderboard_var_samp_fields"]: {
	__typename: "voyce_series_leaderboard_var_samp_fields",
	amount?: number,
	id?: number,
	rank?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_series_leaderboard_variance_fields"]: {
	__typename: "voyce_series_leaderboard_variance_fields",
	amount?: number,
	id?: number,
	rank?: number,
	series_id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.series_likes_count" */
["voyce_series_likes_count"]: {
	__typename: "voyce_series_likes_count",
	count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** aggregated selection of "voyce.series_likes_count" */
["voyce_series_likes_count_aggregate"]: {
	__typename: "voyce_series_likes_count_aggregate",
	aggregate?: GraphQLTypes["voyce_series_likes_count_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_likes_count"]>
};
	/** aggregate fields of "voyce.series_likes_count" */
["voyce_series_likes_count_aggregate_fields"]: {
	__typename: "voyce_series_likes_count_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_likes_count_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_likes_count_max_fields"],
	min?: GraphQLTypes["voyce_series_likes_count_min_fields"],
	stddev?: GraphQLTypes["voyce_series_likes_count_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_likes_count_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_likes_count_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_likes_count_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_likes_count_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_likes_count_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_likes_count_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_likes_count_avg_fields"]: {
	__typename: "voyce_series_likes_count_avg_fields",
	count?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_likes_count". All fields are combined with a logical 'AND'. */
["voyce_series_likes_count_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_likes_count_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_likes_count_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_likes_count_bool_exp"]>,
	count?: GraphQLTypes["bigint_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** columns and relationships of "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat"]: {
	__typename: "voyce_series_likes_count_mat",
	actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** aggregated selection of "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat_aggregate"]: {
	__typename: "voyce_series_likes_count_mat_aggregate",
	aggregate?: GraphQLTypes["voyce_series_likes_count_mat_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_likes_count_mat"]>
};
	/** aggregate fields of "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat_aggregate_fields"]: {
	__typename: "voyce_series_likes_count_mat_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_likes_count_mat_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_likes_count_mat_max_fields"],
	min?: GraphQLTypes["voyce_series_likes_count_mat_min_fields"],
	stddev?: GraphQLTypes["voyce_series_likes_count_mat_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_likes_count_mat_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_likes_count_mat_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_likes_count_mat_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_likes_count_mat_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_likes_count_mat_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_likes_count_mat_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_likes_count_mat_avg_fields"]: {
	__typename: "voyce_series_likes_count_mat_avg_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_likes_count_mat". All fields are combined with a logical 'AND'. */
["voyce_series_likes_count_mat_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_likes_count_mat_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_likes_count_mat_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_likes_count_mat_bool_exp"]>,
	actual_count?: GraphQLTypes["bigint_comparison_exp"],
	count?: GraphQLTypes["float8_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** input type for inserting data into table "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat_insert_input"]: {
		actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** aggregate max on columns */
["voyce_series_likes_count_mat_max_fields"]: {
	__typename: "voyce_series_likes_count_mat_max_fields",
	actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** aggregate min on columns */
["voyce_series_likes_count_mat_min_fields"]: {
	__typename: "voyce_series_likes_count_mat_min_fields",
	actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** input type for inserting object relation for remote table "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_series_likes_count_mat_insert_input"]
};
	/** Ordering options when selecting data from "voyce.series_likes_count_mat". */
["voyce_series_likes_count_mat_order_by"]: {
		actual_count?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.series_likes_count_mat" */
["voyce_series_likes_count_mat_select_column"]: voyce_series_likes_count_mat_select_column;
	/** aggregate stddev on columns */
["voyce_series_likes_count_mat_stddev_fields"]: {
	__typename: "voyce_series_likes_count_mat_stddev_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_likes_count_mat_stddev_pop_fields"]: {
	__typename: "voyce_series_likes_count_mat_stddev_pop_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_likes_count_mat_stddev_samp_fields"]: {
	__typename: "voyce_series_likes_count_mat_stddev_samp_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_series_likes_count_mat" */
["voyce_series_likes_count_mat_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_likes_count_mat_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_likes_count_mat_stream_cursor_value_input"]: {
		actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** aggregate sum on columns */
["voyce_series_likes_count_mat_sum_fields"]: {
	__typename: "voyce_series_likes_count_mat_sum_fields",
	actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** aggregate var_pop on columns */
["voyce_series_likes_count_mat_var_pop_fields"]: {
	__typename: "voyce_series_likes_count_mat_var_pop_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_likes_count_mat_var_samp_fields"]: {
	__typename: "voyce_series_likes_count_mat_var_samp_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_series_likes_count_mat_variance_fields"]: {
	__typename: "voyce_series_likes_count_mat_variance_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate max on columns */
["voyce_series_likes_count_max_fields"]: {
	__typename: "voyce_series_likes_count_max_fields",
	count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** aggregate min on columns */
["voyce_series_likes_count_min_fields"]: {
	__typename: "voyce_series_likes_count_min_fields",
	count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** Ordering options when selecting data from "voyce.series_likes_count". */
["voyce_series_likes_count_order_by"]: {
		count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.series_likes_count" */
["voyce_series_likes_count_select_column"]: voyce_series_likes_count_select_column;
	/** aggregate stddev on columns */
["voyce_series_likes_count_stddev_fields"]: {
	__typename: "voyce_series_likes_count_stddev_fields",
	count?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_likes_count_stddev_pop_fields"]: {
	__typename: "voyce_series_likes_count_stddev_pop_fields",
	count?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_likes_count_stddev_samp_fields"]: {
	__typename: "voyce_series_likes_count_stddev_samp_fields",
	count?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_series_likes_count" */
["voyce_series_likes_count_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_likes_count_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_likes_count_stream_cursor_value_input"]: {
		count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** aggregate sum on columns */
["voyce_series_likes_count_sum_fields"]: {
	__typename: "voyce_series_likes_count_sum_fields",
	count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** aggregate var_pop on columns */
["voyce_series_likes_count_var_pop_fields"]: {
	__typename: "voyce_series_likes_count_var_pop_fields",
	count?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_likes_count_var_samp_fields"]: {
	__typename: "voyce_series_likes_count_var_samp_fields",
	count?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_series_likes_count_variance_fields"]: {
	__typename: "voyce_series_likes_count_variance_fields",
	count?: number,
	series_id?: number
};
	/** columns and relationships of "voyce.series_lock_config" */
["voyce_series_lock_config"]: {
	__typename: "voyce_series_lock_config",
	buy_currency?: string,
	buy_price?: GraphQLTypes["numeric"],
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	lock_duration: GraphQLTypes["numeric"],
	lock_latest_chapters: number,
	rent_currency?: string,
	rent_duration?: GraphQLTypes["numeric"],
	rent_price?: GraphQLTypes["numeric"],
	series_id: number,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.series_lock_config" */
["voyce_series_lock_config_aggregate"]: {
	__typename: "voyce_series_lock_config_aggregate",
	aggregate?: GraphQLTypes["voyce_series_lock_config_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_lock_config"]>
};
	/** aggregate fields of "voyce.series_lock_config" */
["voyce_series_lock_config_aggregate_fields"]: {
	__typename: "voyce_series_lock_config_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_lock_config_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_lock_config_max_fields"],
	min?: GraphQLTypes["voyce_series_lock_config_min_fields"],
	stddev?: GraphQLTypes["voyce_series_lock_config_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_lock_config_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_lock_config_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_lock_config_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_lock_config_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_lock_config_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_lock_config_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_lock_config_avg_fields"]: {
	__typename: "voyce_series_lock_config_avg_fields",
	buy_price?: number,
	id?: number,
	lock_duration?: number,
	lock_latest_chapters?: number,
	rent_duration?: number,
	rent_price?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_lock_config". All fields are combined with a logical 'AND'. */
["voyce_series_lock_config_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_lock_config_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_lock_config_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_lock_config_bool_exp"]>,
	buy_currency?: GraphQLTypes["String_comparison_exp"],
	buy_price?: GraphQLTypes["numeric_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	lock_duration?: GraphQLTypes["numeric_comparison_exp"],
	lock_latest_chapters?: GraphQLTypes["Int_comparison_exp"],
	rent_currency?: GraphQLTypes["String_comparison_exp"],
	rent_duration?: GraphQLTypes["numeric_comparison_exp"],
	rent_price?: GraphQLTypes["numeric_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_lock_config" */
["voyce_series_lock_config_constraint"]: voyce_series_lock_config_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_lock_config" */
["voyce_series_lock_config_inc_input"]: {
		buy_price?: GraphQLTypes["numeric"],
	id?: number,
	lock_duration?: GraphQLTypes["numeric"],
	lock_latest_chapters?: number,
	rent_duration?: GraphQLTypes["numeric"],
	rent_price?: GraphQLTypes["numeric"],
	series_id?: number
};
	/** input type for inserting data into table "voyce.series_lock_config" */
["voyce_series_lock_config_insert_input"]: {
		buy_currency?: string,
	buy_price?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	lock_duration?: GraphQLTypes["numeric"],
	lock_latest_chapters?: number,
	rent_currency?: string,
	rent_duration?: GraphQLTypes["numeric"],
	rent_price?: GraphQLTypes["numeric"],
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_series_lock_config_max_fields"]: {
	__typename: "voyce_series_lock_config_max_fields",
	buy_currency?: string,
	buy_price?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	lock_duration?: GraphQLTypes["numeric"],
	lock_latest_chapters?: number,
	rent_currency?: string,
	rent_duration?: GraphQLTypes["numeric"],
	rent_price?: GraphQLTypes["numeric"],
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_series_lock_config_min_fields"]: {
	__typename: "voyce_series_lock_config_min_fields",
	buy_currency?: string,
	buy_price?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	lock_duration?: GraphQLTypes["numeric"],
	lock_latest_chapters?: number,
	rent_currency?: string,
	rent_duration?: GraphQLTypes["numeric"],
	rent_price?: GraphQLTypes["numeric"],
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.series_lock_config" */
["voyce_series_lock_config_mutation_response"]: {
	__typename: "voyce_series_lock_config_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_lock_config"]>
};
	/** input type for inserting object relation for remote table "voyce.series_lock_config" */
["voyce_series_lock_config_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_series_lock_config_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_series_lock_config_on_conflict"]
};
	/** on_conflict condition type for table "voyce.series_lock_config" */
["voyce_series_lock_config_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_lock_config_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_lock_config_update_column"]>,
	where?: GraphQLTypes["voyce_series_lock_config_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_lock_config". */
["voyce_series_lock_config_order_by"]: {
		buy_currency?: GraphQLTypes["order_by"],
	buy_price?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	lock_duration?: GraphQLTypes["order_by"],
	lock_latest_chapters?: GraphQLTypes["order_by"],
	rent_currency?: GraphQLTypes["order_by"],
	rent_duration?: GraphQLTypes["order_by"],
	rent_price?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_lock_config */
["voyce_series_lock_config_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_lock_config" */
["voyce_series_lock_config_select_column"]: voyce_series_lock_config_select_column;
	/** input type for updating data in table "voyce.series_lock_config" */
["voyce_series_lock_config_set_input"]: {
		buy_currency?: string,
	buy_price?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	lock_duration?: GraphQLTypes["numeric"],
	lock_latest_chapters?: number,
	rent_currency?: string,
	rent_duration?: GraphQLTypes["numeric"],
	rent_price?: GraphQLTypes["numeric"],
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_series_lock_config_stddev_fields"]: {
	__typename: "voyce_series_lock_config_stddev_fields",
	buy_price?: number,
	id?: number,
	lock_duration?: number,
	lock_latest_chapters?: number,
	rent_duration?: number,
	rent_price?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_lock_config_stddev_pop_fields"]: {
	__typename: "voyce_series_lock_config_stddev_pop_fields",
	buy_price?: number,
	id?: number,
	lock_duration?: number,
	lock_latest_chapters?: number,
	rent_duration?: number,
	rent_price?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_lock_config_stddev_samp_fields"]: {
	__typename: "voyce_series_lock_config_stddev_samp_fields",
	buy_price?: number,
	id?: number,
	lock_duration?: number,
	lock_latest_chapters?: number,
	rent_duration?: number,
	rent_price?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_series_lock_config" */
["voyce_series_lock_config_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_lock_config_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_lock_config_stream_cursor_value_input"]: {
		buy_currency?: string,
	buy_price?: GraphQLTypes["numeric"],
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	lock_duration?: GraphQLTypes["numeric"],
	lock_latest_chapters?: number,
	rent_currency?: string,
	rent_duration?: GraphQLTypes["numeric"],
	rent_price?: GraphQLTypes["numeric"],
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_series_lock_config_sum_fields"]: {
	__typename: "voyce_series_lock_config_sum_fields",
	buy_price?: GraphQLTypes["numeric"],
	id?: number,
	lock_duration?: GraphQLTypes["numeric"],
	lock_latest_chapters?: number,
	rent_duration?: GraphQLTypes["numeric"],
	rent_price?: GraphQLTypes["numeric"],
	series_id?: number
};
	/** update columns of table "voyce.series_lock_config" */
["voyce_series_lock_config_update_column"]: voyce_series_lock_config_update_column;
	["voyce_series_lock_config_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_lock_config_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_lock_config_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_lock_config_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_lock_config_var_pop_fields"]: {
	__typename: "voyce_series_lock_config_var_pop_fields",
	buy_price?: number,
	id?: number,
	lock_duration?: number,
	lock_latest_chapters?: number,
	rent_duration?: number,
	rent_price?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_lock_config_var_samp_fields"]: {
	__typename: "voyce_series_lock_config_var_samp_fields",
	buy_price?: number,
	id?: number,
	lock_duration?: number,
	lock_latest_chapters?: number,
	rent_duration?: number,
	rent_price?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_series_lock_config_variance_fields"]: {
	__typename: "voyce_series_lock_config_variance_fields",
	buy_price?: number,
	id?: number,
	lock_duration?: number,
	lock_latest_chapters?: number,
	rent_duration?: number,
	rent_price?: number,
	series_id?: number
};
	/** aggregate max on columns */
["voyce_series_max_fields"]: {
	__typename: "voyce_series_max_fields",
	animated_cover_image?: string,
	average_rating?: GraphQLTypes["float8"],
	back_image?: string,
	banner_bg?: string,
	chapter_count?: number,
	chapters_hide?: string,
	character_cover?: string,
	coin_price?: GraphQLTypes["smallint"],
	comic_type_id?: number,
	cover_image?: string,
	cover_image_mobile?: string,
	created_at?: GraphQLTypes["timestamp"],
	default_view?: string,
	description?: string,
	frequency?: string,
	frequency_mask?: number,
	front_image?: string,
	id?: number,
	is_approved?: GraphQLTypes["smallint"],
	is_intro?: GraphQLTypes["smallint"],
	is_original?: GraphQLTypes["smallint"],
	is_pilots?: GraphQLTypes["smallint"],
	is_recommended?: GraphQLTypes["smallint"],
	klaviyo_id?: string,
	klaviyo_list_id?: string,
	latest_chapter_update?: GraphQLTypes["timestamp"],
	locked_chapters_amount?: number,
	pilots_votes_count?: number,
	portfolio_desc?: string,
	publish?: GraphQLTypes["smallint"],
	rating_count?: number,
	reading_view?: string,
	short_desc?: string,
	slug?: string,
	status?: string,
	subscriber_count?: number,
	support_msg?: string,
	thumbnail?: string,
	thumbnail_box?: string,
	title?: string,
	trending_position?: number,
	updated_at?: GraphQLTypes["timestamp"],
	user_display_field?: string,
	user_id?: number,
	view_count?: number,
	welcome_msg?: string
};
	/** aggregate min on columns */
["voyce_series_min_fields"]: {
	__typename: "voyce_series_min_fields",
	animated_cover_image?: string,
	average_rating?: GraphQLTypes["float8"],
	back_image?: string,
	banner_bg?: string,
	chapter_count?: number,
	chapters_hide?: string,
	character_cover?: string,
	coin_price?: GraphQLTypes["smallint"],
	comic_type_id?: number,
	cover_image?: string,
	cover_image_mobile?: string,
	created_at?: GraphQLTypes["timestamp"],
	default_view?: string,
	description?: string,
	frequency?: string,
	frequency_mask?: number,
	front_image?: string,
	id?: number,
	is_approved?: GraphQLTypes["smallint"],
	is_intro?: GraphQLTypes["smallint"],
	is_original?: GraphQLTypes["smallint"],
	is_pilots?: GraphQLTypes["smallint"],
	is_recommended?: GraphQLTypes["smallint"],
	klaviyo_id?: string,
	klaviyo_list_id?: string,
	latest_chapter_update?: GraphQLTypes["timestamp"],
	locked_chapters_amount?: number,
	pilots_votes_count?: number,
	portfolio_desc?: string,
	publish?: GraphQLTypes["smallint"],
	rating_count?: number,
	reading_view?: string,
	short_desc?: string,
	slug?: string,
	status?: string,
	subscriber_count?: number,
	support_msg?: string,
	thumbnail?: string,
	thumbnail_box?: string,
	title?: string,
	trending_position?: number,
	updated_at?: GraphQLTypes["timestamp"],
	user_display_field?: string,
	user_id?: number,
	view_count?: number,
	welcome_msg?: string
};
	/** response of any mutation on the table "voyce.series" */
["voyce_series_mutation_response"]: {
	__typename: "voyce_series_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series"]>
};
	/** input type for inserting object relation for remote table "voyce.series" */
["voyce_series_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_series_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_series_on_conflict"]
};
	/** on_conflict condition type for table "voyce.series" */
["voyce_series_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_update_column"]>,
	where?: GraphQLTypes["voyce_series_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series". */
["voyce_series_order_by"]: {
		animated_cover_image?: GraphQLTypes["order_by"],
	audio_allowed?: GraphQLTypes["order_by"],
	author?: GraphQLTypes["voyce_users_order_by"],
	average_rating?: GraphQLTypes["order_by"],
	back_image?: GraphQLTypes["order_by"],
	banner_bg?: GraphQLTypes["order_by"],
	book_reverse_view?: GraphQLTypes["order_by"],
	book_view?: GraphQLTypes["order_by"],
	chapter_comments_aggregate?: GraphQLTypes["voyce_chapter_comments_aggregate_order_by"],
	chapter_count?: GraphQLTypes["order_by"],
	chapter_panels_aggregate?: GraphQLTypes["voyce_chapter_panels_aggregate_order_by"],
	chapters_aggregate?: GraphQLTypes["voyce_chapters_aggregate_order_by"],
	chapters_hide?: GraphQLTypes["order_by"],
	character_cover?: GraphQLTypes["order_by"],
	coin_price?: GraphQLTypes["order_by"],
	collaborators_aggregate?: GraphQLTypes["voyce_series_collaborators_aggregate_order_by"],
	comic_type_id?: GraphQLTypes["order_by"],
	cover_image?: GraphQLTypes["order_by"],
	cover_image_mobile?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	csat?: GraphQLTypes["order_by"],
	default_view?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	feature?: GraphQLTypes["order_by"],
	featured_panels_aggregate?: GraphQLTypes["voyce_series_featured_panels_aggregate_order_by"],
	followers_aggregate?: GraphQLTypes["voyce_series_subscription_aggregate_order_by"],
	frequency?: GraphQLTypes["order_by"],
	frequency_mask?: GraphQLTypes["order_by"],
	front_image?: GraphQLTypes["order_by"],
	genres_aggregate?: GraphQLTypes["voyce_series_genre_aggregate_order_by"],
	hot_panels_aggregate?: GraphQLTypes["voyce_series_hot_panels_aggregate_order_by"],
	id?: GraphQLTypes["order_by"],
	is_animated_cover_allowed?: GraphQLTypes["order_by"],
	is_approved?: GraphQLTypes["order_by"],
	is_deleted?: GraphQLTypes["order_by"],
	is_intro?: GraphQLTypes["order_by"],
	is_locked?: GraphQLTypes["order_by"],
	is_original?: GraphQLTypes["order_by"],
	is_pilots?: GraphQLTypes["order_by"],
	is_recommended?: GraphQLTypes["order_by"],
	klaviyo_id?: GraphQLTypes["order_by"],
	klaviyo_list_id?: GraphQLTypes["order_by"],
	latest_chapter_update?: GraphQLTypes["order_by"],
	likes_aggregate?: GraphQLTypes["voyce_chapter_likes_aggregate_order_by"],
	likes_count?: GraphQLTypes["voyce_series_likes_count_mat_order_by"],
	lock_config?: GraphQLTypes["voyce_series_lock_config_order_by"],
	locked?: GraphQLTypes["order_by"],
	locked_chapters_amount?: GraphQLTypes["order_by"],
	notification_settings?: GraphQLTypes["voyce_notification_series_settings_order_by"],
	paginated_view?: GraphQLTypes["order_by"],
	pilots_votes_count?: GraphQLTypes["order_by"],
	portfolio_desc?: GraphQLTypes["order_by"],
	publish?: GraphQLTypes["order_by"],
	rating_count?: GraphQLTypes["order_by"],
	reading_view?: GraphQLTypes["order_by"],
	scroll_view?: GraphQLTypes["order_by"],
	series_tags_junctions_aggregate?: GraphQLTypes["voyce_series_tags_junction_aggregate_order_by"],
	short_desc?: GraphQLTypes["order_by"],
	slug?: GraphQLTypes["order_by"],
	sponsorship_config?: GraphQLTypes["voyce_series_sponsorships_configuration_order_by"],
	sponsorships_aggregate?: GraphQLTypes["voyce_sponsorships_aggregate_order_by"],
	status?: GraphQLTypes["order_by"],
	subscriber_count?: GraphQLTypes["order_by"],
	support_msg?: GraphQLTypes["order_by"],
	team_spotlight?: GraphQLTypes["order_by"],
	thumbnail?: GraphQLTypes["order_by"],
	thumbnail_box?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	trending?: GraphQLTypes["order_by"],
	trending_position?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["voyce_comic_types_order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_display_field?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	users_series_junctions_aggregate?: GraphQLTypes["voyce_users_series_junction_aggregate_order_by"],
	view_count?: GraphQLTypes["order_by"],
	views_aggregate?: GraphQLTypes["voyce_chapter_views_aggregate_order_by"],
	views_counts?: GraphQLTypes["voyce_pg_rs_series_views_count_order_by"],
	welcome_msg?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series */
["voyce_series_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series" */
["voyce_series_select_column"]: voyce_series_select_column;
	/** input type for updating data in table "voyce.series" */
["voyce_series_set_input"]: {
		animated_cover_image?: string,
	audio_allowed?: boolean,
	average_rating?: GraphQLTypes["float8"],
	back_image?: string,
	banner_bg?: string,
	book_reverse_view?: boolean,
	book_view?: boolean,
	chapter_count?: number,
	chapters_hide?: string,
	character_cover?: string,
	coin_price?: GraphQLTypes["smallint"],
	comic_type_id?: number,
	cover_image?: string,
	cover_image_mobile?: string,
	created_at?: GraphQLTypes["timestamp"],
	csat?: boolean,
	default_view?: string,
	description?: string,
	feature?: GraphQLTypes["bit"],
	frequency?: string,
	frequency_mask?: number,
	front_image?: string,
	id?: number,
	is_animated_cover_allowed?: boolean,
	is_approved?: GraphQLTypes["smallint"],
	is_deleted?: boolean,
	is_intro?: GraphQLTypes["smallint"],
	is_locked?: boolean,
	is_original?: GraphQLTypes["smallint"],
	is_pilots?: GraphQLTypes["smallint"],
	is_recommended?: GraphQLTypes["smallint"],
	klaviyo_id?: string,
	klaviyo_list_id?: string,
	latest_chapter_update?: GraphQLTypes["timestamp"],
	locked?: boolean,
	locked_chapters_amount?: number,
	paginated_view?: boolean,
	pilots_votes_count?: number,
	portfolio_desc?: string,
	publish?: GraphQLTypes["smallint"],
	rating_count?: number,
	reading_view?: string,
	scroll_view?: boolean,
	short_desc?: string,
	slug?: string,
	status?: string,
	subscriber_count?: number,
	support_msg?: string,
	team_spotlight?: boolean,
	thumbnail?: string,
	thumbnail_box?: string,
	title?: string,
	trending?: GraphQLTypes["bit"],
	trending_position?: number,
	updated_at?: GraphQLTypes["timestamp"],
	user_display_field?: string,
	user_id?: number,
	view_count?: number,
	welcome_msg?: string
};
	/** columns and relationships of "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration"]: {
	__typename: "voyce_series_sponsorships_configuration",
	enabled: boolean,
	id: number,
	message?: string,
	/** An object relationship */
	series?: GraphQLTypes["voyce_series"],
	series_id?: number,
	voyceme_payee: boolean
};
	/** aggregated selection of "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_aggregate"]: {
	__typename: "voyce_series_sponsorships_configuration_aggregate",
	aggregate?: GraphQLTypes["voyce_series_sponsorships_configuration_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_sponsorships_configuration"]>
};
	/** aggregate fields of "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_aggregate_fields"]: {
	__typename: "voyce_series_sponsorships_configuration_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_sponsorships_configuration_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_sponsorships_configuration_max_fields"],
	min?: GraphQLTypes["voyce_series_sponsorships_configuration_min_fields"],
	stddev?: GraphQLTypes["voyce_series_sponsorships_configuration_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_sponsorships_configuration_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_sponsorships_configuration_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_sponsorships_configuration_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_sponsorships_configuration_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_sponsorships_configuration_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_sponsorships_configuration_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_sponsorships_configuration_avg_fields"]: {
	__typename: "voyce_series_sponsorships_configuration_avg_fields",
	id?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_sponsorships_configuration". All fields are combined with a logical 'AND'. */
["voyce_series_sponsorships_configuration_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_sponsorships_configuration_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_sponsorships_configuration_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_sponsorships_configuration_bool_exp"]>,
	enabled?: GraphQLTypes["Boolean_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	message?: GraphQLTypes["String_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	voyceme_payee?: GraphQLTypes["Boolean_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_constraint"]: voyce_series_sponsorships_configuration_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_inc_input"]: {
		id?: number,
	series_id?: number
};
	/** input type for inserting data into table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_insert_input"]: {
		enabled?: boolean,
	id?: number,
	message?: string,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	voyceme_payee?: boolean
};
	/** aggregate max on columns */
["voyce_series_sponsorships_configuration_max_fields"]: {
	__typename: "voyce_series_sponsorships_configuration_max_fields",
	id?: number,
	message?: string,
	series_id?: number
};
	/** aggregate min on columns */
["voyce_series_sponsorships_configuration_min_fields"]: {
	__typename: "voyce_series_sponsorships_configuration_min_fields",
	id?: number,
	message?: string,
	series_id?: number
};
	/** response of any mutation on the table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_mutation_response"]: {
	__typename: "voyce_series_sponsorships_configuration_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_sponsorships_configuration"]>
};
	/** input type for inserting object relation for remote table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_series_sponsorships_configuration_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_series_sponsorships_configuration_on_conflict"]
};
	/** on_conflict condition type for table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_sponsorships_configuration_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_sponsorships_configuration_update_column"]>,
	where?: GraphQLTypes["voyce_series_sponsorships_configuration_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_sponsorships_configuration". */
["voyce_series_sponsorships_configuration_order_by"]: {
		enabled?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	message?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	voyceme_payee?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_sponsorships_configuration */
["voyce_series_sponsorships_configuration_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_select_column"]: voyce_series_sponsorships_configuration_select_column;
	/** input type for updating data in table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_set_input"]: {
		enabled?: boolean,
	id?: number,
	message?: string,
	series_id?: number,
	voyceme_payee?: boolean
};
	/** aggregate stddev on columns */
["voyce_series_sponsorships_configuration_stddev_fields"]: {
	__typename: "voyce_series_sponsorships_configuration_stddev_fields",
	id?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_sponsorships_configuration_stddev_pop_fields"]: {
	__typename: "voyce_series_sponsorships_configuration_stddev_pop_fields",
	id?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_sponsorships_configuration_stddev_samp_fields"]: {
	__typename: "voyce_series_sponsorships_configuration_stddev_samp_fields",
	id?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_sponsorships_configuration_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_sponsorships_configuration_stream_cursor_value_input"]: {
		enabled?: boolean,
	id?: number,
	message?: string,
	series_id?: number,
	voyceme_payee?: boolean
};
	/** aggregate sum on columns */
["voyce_series_sponsorships_configuration_sum_fields"]: {
	__typename: "voyce_series_sponsorships_configuration_sum_fields",
	id?: number,
	series_id?: number
};
	/** update columns of table "voyce.series_sponsorships_configuration" */
["voyce_series_sponsorships_configuration_update_column"]: voyce_series_sponsorships_configuration_update_column;
	["voyce_series_sponsorships_configuration_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_sponsorships_configuration_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_sponsorships_configuration_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_sponsorships_configuration_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_sponsorships_configuration_var_pop_fields"]: {
	__typename: "voyce_series_sponsorships_configuration_var_pop_fields",
	id?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_sponsorships_configuration_var_samp_fields"]: {
	__typename: "voyce_series_sponsorships_configuration_var_samp_fields",
	id?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_series_sponsorships_configuration_variance_fields"]: {
	__typename: "voyce_series_sponsorships_configuration_variance_fields",
	id?: number,
	series_id?: number
};
	/** aggregate stddev on columns */
["voyce_series_stddev_fields"]: {
	__typename: "voyce_series_stddev_fields",
	average_rating?: number,
	chapter_count?: number,
	coin_price?: number,
	comic_type_id?: number,
	frequency_mask?: number,
	id?: number,
	is_approved?: number,
	is_intro?: number,
	is_original?: number,
	is_pilots?: number,
	is_recommended?: number,
	locked_chapters_amount?: number,
	pilots_votes_count?: number,
	publish?: number,
	rating_count?: number,
	subscriber_count?: number,
	trending_position?: number,
	user_id?: number,
	view_count?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_stddev_pop_fields"]: {
	__typename: "voyce_series_stddev_pop_fields",
	average_rating?: number,
	chapter_count?: number,
	coin_price?: number,
	comic_type_id?: number,
	frequency_mask?: number,
	id?: number,
	is_approved?: number,
	is_intro?: number,
	is_original?: number,
	is_pilots?: number,
	is_recommended?: number,
	locked_chapters_amount?: number,
	pilots_votes_count?: number,
	publish?: number,
	rating_count?: number,
	subscriber_count?: number,
	trending_position?: number,
	user_id?: number,
	view_count?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_stddev_samp_fields"]: {
	__typename: "voyce_series_stddev_samp_fields",
	average_rating?: number,
	chapter_count?: number,
	coin_price?: number,
	comic_type_id?: number,
	frequency_mask?: number,
	id?: number,
	is_approved?: number,
	is_intro?: number,
	is_original?: number,
	is_pilots?: number,
	is_recommended?: number,
	locked_chapters_amount?: number,
	pilots_votes_count?: number,
	publish?: number,
	rating_count?: number,
	subscriber_count?: number,
	trending_position?: number,
	user_id?: number,
	view_count?: number
};
	/** Streaming cursor of the table "voyce_series" */
["voyce_series_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_stream_cursor_value_input"]: {
		animated_cover_image?: string,
	audio_allowed?: boolean,
	average_rating?: GraphQLTypes["float8"],
	back_image?: string,
	banner_bg?: string,
	book_reverse_view?: boolean,
	book_view?: boolean,
	chapter_count?: number,
	chapters_hide?: string,
	character_cover?: string,
	coin_price?: GraphQLTypes["smallint"],
	comic_type_id?: number,
	cover_image?: string,
	cover_image_mobile?: string,
	created_at?: GraphQLTypes["timestamp"],
	csat?: boolean,
	default_view?: string,
	description?: string,
	feature?: GraphQLTypes["bit"],
	frequency?: string,
	frequency_mask?: number,
	front_image?: string,
	id?: number,
	is_animated_cover_allowed?: boolean,
	is_approved?: GraphQLTypes["smallint"],
	is_deleted?: boolean,
	is_intro?: GraphQLTypes["smallint"],
	is_locked?: boolean,
	is_original?: GraphQLTypes["smallint"],
	is_pilots?: GraphQLTypes["smallint"],
	is_recommended?: GraphQLTypes["smallint"],
	klaviyo_id?: string,
	klaviyo_list_id?: string,
	latest_chapter_update?: GraphQLTypes["timestamp"],
	locked?: boolean,
	locked_chapters_amount?: number,
	paginated_view?: boolean,
	pilots_votes_count?: number,
	portfolio_desc?: string,
	publish?: GraphQLTypes["smallint"],
	rating_count?: number,
	reading_view?: string,
	scroll_view?: boolean,
	short_desc?: string,
	slug?: string,
	status?: string,
	subscriber_count?: number,
	support_msg?: string,
	team_spotlight?: boolean,
	thumbnail?: string,
	thumbnail_box?: string,
	title?: string,
	trending?: GraphQLTypes["bit"],
	trending_position?: number,
	updated_at?: GraphQLTypes["timestamp"],
	user_display_field?: string,
	user_id?: number,
	view_count?: number,
	welcome_msg?: string
};
	/** columns and relationships of "voyce.series_subscription" */
["voyce_series_subscription"]: {
	__typename: "voyce_series_subscription",
	created_at: GraphQLTypes["timestamp"],
	id: number,
	klaviyo_id?: string,
	/** An object relationship */
	series?: GraphQLTypes["voyce_series"],
	series_id: number,
	/** An object relationship */
	user?: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.series_subscription" */
["voyce_series_subscription_aggregate"]: {
	__typename: "voyce_series_subscription_aggregate",
	aggregate?: GraphQLTypes["voyce_series_subscription_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_subscription"]>
};
	["voyce_series_subscription_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_series_subscription_aggregate_bool_exp_count"]
};
	["voyce_series_subscription_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_series_subscription_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_series_subscription_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.series_subscription" */
["voyce_series_subscription_aggregate_fields"]: {
	__typename: "voyce_series_subscription_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_subscription_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_subscription_max_fields"],
	min?: GraphQLTypes["voyce_series_subscription_min_fields"],
	stddev?: GraphQLTypes["voyce_series_subscription_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_subscription_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_subscription_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_subscription_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_subscription_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_subscription_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_subscription_variance_fields"]
};
	/** order by aggregate values of table "voyce.series_subscription" */
["voyce_series_subscription_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_series_subscription_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_series_subscription_max_order_by"],
	min?: GraphQLTypes["voyce_series_subscription_min_order_by"],
	stddev?: GraphQLTypes["voyce_series_subscription_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_series_subscription_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_series_subscription_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_series_subscription_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_series_subscription_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_series_subscription_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_series_subscription_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.series_subscription" */
["voyce_series_subscription_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_series_subscription_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_series_subscription_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_series_subscription_avg_fields"]: {
	__typename: "voyce_series_subscription_avg_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.series_subscription". All fields are combined with a logical 'AND'. */
["voyce_series_subscription_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_subscription_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_subscription_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_subscription_bool_exp"]>,
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	klaviyo_id?: GraphQLTypes["String_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_subscription" */
["voyce_series_subscription_constraint"]: voyce_series_subscription_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_subscription" */
["voyce_series_subscription_inc_input"]: {
		id?: number,
	series_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.series_subscription" */
["voyce_series_subscription_insert_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	id?: number,
	klaviyo_id?: string,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_series_subscription_max_fields"]: {
	__typename: "voyce_series_subscription_max_fields",
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	klaviyo_id?: string,
	series_id?: number,
	user_id?: number
};
	/** order by max() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_max_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	klaviyo_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_series_subscription_min_fields"]: {
	__typename: "voyce_series_subscription_min_fields",
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	klaviyo_id?: string,
	series_id?: number,
	user_id?: number
};
	/** order by min() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_min_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	klaviyo_id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.series_subscription" */
["voyce_series_subscription_mutation_response"]: {
	__typename: "voyce_series_subscription_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_subscription"]>
};
	/** on_conflict condition type for table "voyce.series_subscription" */
["voyce_series_subscription_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_subscription_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_subscription_update_column"]>,
	where?: GraphQLTypes["voyce_series_subscription_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_subscription". */
["voyce_series_subscription_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	klaviyo_id?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_subscription */
["voyce_series_subscription_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_subscription" */
["voyce_series_subscription_select_column"]: voyce_series_subscription_select_column;
	/** input type for updating data in table "voyce.series_subscription" */
["voyce_series_subscription_set_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	id?: number,
	klaviyo_id?: string,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_series_subscription_stddev_fields"]: {
	__typename: "voyce_series_subscription_stddev_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_series_subscription_stddev_pop_fields"]: {
	__typename: "voyce_series_subscription_stddev_pop_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_series_subscription_stddev_samp_fields"]: {
	__typename: "voyce_series_subscription_stddev_samp_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_series_subscription" */
["voyce_series_subscription_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_subscription_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_subscription_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	id?: number,
	klaviyo_id?: string,
	series_id?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_series_subscription_sum_fields"]: {
	__typename: "voyce_series_subscription_sum_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.series_subscription" */
["voyce_series_subscription_update_column"]: voyce_series_subscription_update_column;
	["voyce_series_subscription_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_subscription_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_subscription_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_subscription_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_subscription_var_pop_fields"]: {
	__typename: "voyce_series_subscription_var_pop_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_series_subscription_var_samp_fields"]: {
	__typename: "voyce_series_subscription_var_samp_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_series_subscription_variance_fields"]: {
	__typename: "voyce_series_subscription_variance_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.series_subscription" */
["voyce_series_subscription_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate sum on columns */
["voyce_series_sum_fields"]: {
	__typename: "voyce_series_sum_fields",
	average_rating?: GraphQLTypes["float8"],
	chapter_count?: number,
	coin_price?: GraphQLTypes["smallint"],
	comic_type_id?: number,
	frequency_mask?: number,
	id?: number,
	is_approved?: GraphQLTypes["smallint"],
	is_intro?: GraphQLTypes["smallint"],
	is_original?: GraphQLTypes["smallint"],
	is_pilots?: GraphQLTypes["smallint"],
	is_recommended?: GraphQLTypes["smallint"],
	locked_chapters_amount?: number,
	pilots_votes_count?: number,
	publish?: GraphQLTypes["smallint"],
	rating_count?: number,
	subscriber_count?: number,
	trending_position?: number,
	user_id?: number,
	view_count?: number
};
	/** This table keeps tags assigned series. */
["voyce_series_tags"]: {
	__typename: "voyce_series_tags",
	id: number,
	name: string,
	/** An array relationship */
	series_tags_junctions: Array<GraphQLTypes["voyce_series_tags_junction"]>,
	/** An aggregate relationship */
	series_tags_junctions_aggregate: GraphQLTypes["voyce_series_tags_junction_aggregate"]
};
	/** aggregated selection of "voyce.series_tags" */
["voyce_series_tags_aggregate"]: {
	__typename: "voyce_series_tags_aggregate",
	aggregate?: GraphQLTypes["voyce_series_tags_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_tags"]>
};
	/** aggregate fields of "voyce.series_tags" */
["voyce_series_tags_aggregate_fields"]: {
	__typename: "voyce_series_tags_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_tags_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_tags_max_fields"],
	min?: GraphQLTypes["voyce_series_tags_min_fields"],
	stddev?: GraphQLTypes["voyce_series_tags_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_tags_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_tags_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_tags_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_tags_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_tags_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_tags_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_tags_avg_fields"]: {
	__typename: "voyce_series_tags_avg_fields",
	id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_tags". All fields are combined with a logical 'AND'. */
["voyce_series_tags_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_tags_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_tags_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_tags_bool_exp"]>,
	id?: GraphQLTypes["Int_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	series_tags_junctions?: GraphQLTypes["voyce_series_tags_junction_bool_exp"],
	series_tags_junctions_aggregate?: GraphQLTypes["voyce_series_tags_junction_aggregate_bool_exp"]
};
	/** unique or primary key constraints on table "voyce.series_tags" */
["voyce_series_tags_constraint"]: voyce_series_tags_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_tags" */
["voyce_series_tags_inc_input"]: {
		id?: number
};
	/** input type for inserting data into table "voyce.series_tags" */
["voyce_series_tags_insert_input"]: {
		id?: number,
	name?: string,
	series_tags_junctions?: GraphQLTypes["voyce_series_tags_junction_arr_rel_insert_input"]
};
	/** Many-to-many junction table for series_tags and series */
["voyce_series_tags_junction"]: {
	__typename: "voyce_series_tags_junction",
	admin_created: boolean,
	/** An object relationship */
	chapter?: GraphQLTypes["voyce_chapters"],
	chapter_id?: number,
	id: number,
	images?: GraphQLTypes["jsonb"],
	order: number,
	/** An object relationship */
	series: GraphQLTypes["voyce_series"],
	series_id: number,
	/** An object relationship */
	series_tag: GraphQLTypes["voyce_series_tags"],
	tag_id: number
};
	/** aggregated selection of "voyce.series_tags_junction" */
["voyce_series_tags_junction_aggregate"]: {
	__typename: "voyce_series_tags_junction_aggregate",
	aggregate?: GraphQLTypes["voyce_series_tags_junction_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_tags_junction"]>
};
	["voyce_series_tags_junction_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["voyce_series_tags_junction_aggregate_bool_exp_bool_and"],
	bool_or?: GraphQLTypes["voyce_series_tags_junction_aggregate_bool_exp_bool_or"],
	count?: GraphQLTypes["voyce_series_tags_junction_aggregate_bool_exp_count"]
};
	["voyce_series_tags_junction_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_and_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_series_tags_junction_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_series_tags_junction_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_or_arguments_columns"],
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_series_tags_junction_bool_exp"],
	predicate: GraphQLTypes["Boolean_comparison_exp"]
};
	["voyce_series_tags_junction_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_series_tags_junction_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_series_tags_junction_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.series_tags_junction" */
["voyce_series_tags_junction_aggregate_fields"]: {
	__typename: "voyce_series_tags_junction_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_tags_junction_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_tags_junction_max_fields"],
	min?: GraphQLTypes["voyce_series_tags_junction_min_fields"],
	stddev?: GraphQLTypes["voyce_series_tags_junction_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_tags_junction_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_tags_junction_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_tags_junction_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_tags_junction_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_tags_junction_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_tags_junction_variance_fields"]
};
	/** order by aggregate values of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_series_tags_junction_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_series_tags_junction_max_order_by"],
	min?: GraphQLTypes["voyce_series_tags_junction_min_order_by"],
	stddev?: GraphQLTypes["voyce_series_tags_junction_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_series_tags_junction_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_series_tags_junction_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_series_tags_junction_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_series_tags_junction_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_series_tags_junction_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_series_tags_junction_variance_order_by"]
};
	/** append existing jsonb value of filtered columns with new jsonb value */
["voyce_series_tags_junction_append_input"]: {
		images?: GraphQLTypes["jsonb"]
};
	/** input type for inserting array relation for remote table "voyce.series_tags_junction" */
["voyce_series_tags_junction_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_series_tags_junction_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_series_tags_junction_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_series_tags_junction_avg_fields"]: {
	__typename: "voyce_series_tags_junction_avg_fields",
	chapter_id?: number,
	id?: number,
	order?: number,
	series_id?: number,
	tag_id?: number
};
	/** order by avg() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_avg_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	tag_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.series_tags_junction". All fields are combined with a logical 'AND'. */
["voyce_series_tags_junction_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_tags_junction_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_tags_junction_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_tags_junction_bool_exp"]>,
	admin_created?: GraphQLTypes["Boolean_comparison_exp"],
	chapter?: GraphQLTypes["voyce_chapters_bool_exp"],
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	images?: GraphQLTypes["jsonb_comparison_exp"],
	order?: GraphQLTypes["Int_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	series_tag?: GraphQLTypes["voyce_series_tags_bool_exp"],
	tag_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_tags_junction" */
["voyce_series_tags_junction_constraint"]: voyce_series_tags_junction_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["voyce_series_tags_junction_delete_at_path_input"]: {
		images?: Array<string>
};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["voyce_series_tags_junction_delete_elem_input"]: {
		images?: number
};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["voyce_series_tags_junction_delete_key_input"]: {
		images?: string
};
	/** input type for incrementing numeric columns in table "voyce.series_tags_junction" */
["voyce_series_tags_junction_inc_input"]: {
		chapter_id?: number,
	id?: number,
	order?: number,
	series_id?: number,
	tag_id?: number
};
	/** input type for inserting data into table "voyce.series_tags_junction" */
["voyce_series_tags_junction_insert_input"]: {
		admin_created?: boolean,
	chapter?: GraphQLTypes["voyce_chapters_obj_rel_insert_input"],
	chapter_id?: number,
	id?: number,
	images?: GraphQLTypes["jsonb"],
	order?: number,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	series_tag?: GraphQLTypes["voyce_series_tags_obj_rel_insert_input"],
	tag_id?: number
};
	/** aggregate max on columns */
["voyce_series_tags_junction_max_fields"]: {
	__typename: "voyce_series_tags_junction_max_fields",
	chapter_id?: number,
	id?: number,
	order?: number,
	series_id?: number,
	tag_id?: number
};
	/** order by max() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_max_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	tag_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_series_tags_junction_min_fields"]: {
	__typename: "voyce_series_tags_junction_min_fields",
	chapter_id?: number,
	id?: number,
	order?: number,
	series_id?: number,
	tag_id?: number
};
	/** order by min() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_min_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	tag_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.series_tags_junction" */
["voyce_series_tags_junction_mutation_response"]: {
	__typename: "voyce_series_tags_junction_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_tags_junction"]>
};
	/** on_conflict condition type for table "voyce.series_tags_junction" */
["voyce_series_tags_junction_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_tags_junction_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_tags_junction_update_column"]>,
	where?: GraphQLTypes["voyce_series_tags_junction_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_tags_junction". */
["voyce_series_tags_junction_order_by"]: {
		admin_created?: GraphQLTypes["order_by"],
	chapter?: GraphQLTypes["voyce_chapters_order_by"],
	chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	images?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	series_tag?: GraphQLTypes["voyce_series_tags_order_by"],
	tag_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_tags_junction */
["voyce_series_tags_junction_pk_columns_input"]: {
		id: number
};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["voyce_series_tags_junction_prepend_input"]: {
		images?: GraphQLTypes["jsonb"]
};
	/** select columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_select_column"]: voyce_series_tags_junction_select_column;
	/** select "voyce_series_tags_junction_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_and_arguments_columns"]: voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "voyce_series_tags_junction_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_or_arguments_columns"]: voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "voyce.series_tags_junction" */
["voyce_series_tags_junction_set_input"]: {
		admin_created?: boolean,
	chapter_id?: number,
	id?: number,
	images?: GraphQLTypes["jsonb"],
	order?: number,
	series_id?: number,
	tag_id?: number
};
	/** aggregate stddev on columns */
["voyce_series_tags_junction_stddev_fields"]: {
	__typename: "voyce_series_tags_junction_stddev_fields",
	chapter_id?: number,
	id?: number,
	order?: number,
	series_id?: number,
	tag_id?: number
};
	/** order by stddev() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_stddev_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	tag_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_series_tags_junction_stddev_pop_fields"]: {
	__typename: "voyce_series_tags_junction_stddev_pop_fields",
	chapter_id?: number,
	id?: number,
	order?: number,
	series_id?: number,
	tag_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_stddev_pop_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	tag_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_series_tags_junction_stddev_samp_fields"]: {
	__typename: "voyce_series_tags_junction_stddev_samp_fields",
	chapter_id?: number,
	id?: number,
	order?: number,
	series_id?: number,
	tag_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_stddev_samp_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	tag_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_series_tags_junction" */
["voyce_series_tags_junction_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_tags_junction_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_tags_junction_stream_cursor_value_input"]: {
		admin_created?: boolean,
	chapter_id?: number,
	id?: number,
	images?: GraphQLTypes["jsonb"],
	order?: number,
	series_id?: number,
	tag_id?: number
};
	/** aggregate sum on columns */
["voyce_series_tags_junction_sum_fields"]: {
	__typename: "voyce_series_tags_junction_sum_fields",
	chapter_id?: number,
	id?: number,
	order?: number,
	series_id?: number,
	tag_id?: number
};
	/** order by sum() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_sum_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	tag_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_update_column"]: voyce_series_tags_junction_update_column;
	["voyce_series_tags_junction_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: GraphQLTypes["voyce_series_tags_junction_append_input"],
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: GraphQLTypes["voyce_series_tags_junction_delete_at_path_input"],
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: GraphQLTypes["voyce_series_tags_junction_delete_elem_input"],
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: GraphQLTypes["voyce_series_tags_junction_delete_key_input"],
	/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_tags_junction_inc_input"],
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: GraphQLTypes["voyce_series_tags_junction_prepend_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_tags_junction_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_tags_junction_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_tags_junction_var_pop_fields"]: {
	__typename: "voyce_series_tags_junction_var_pop_fields",
	chapter_id?: number,
	id?: number,
	order?: number,
	series_id?: number,
	tag_id?: number
};
	/** order by var_pop() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_var_pop_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	tag_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_series_tags_junction_var_samp_fields"]: {
	__typename: "voyce_series_tags_junction_var_samp_fields",
	chapter_id?: number,
	id?: number,
	order?: number,
	series_id?: number,
	tag_id?: number
};
	/** order by var_samp() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_var_samp_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	tag_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_series_tags_junction_variance_fields"]: {
	__typename: "voyce_series_tags_junction_variance_fields",
	chapter_id?: number,
	id?: number,
	order?: number,
	series_id?: number,
	tag_id?: number
};
	/** order by variance() on columns of table "voyce.series_tags_junction" */
["voyce_series_tags_junction_variance_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	tag_id?: GraphQLTypes["order_by"]
};
	/** aggregate max on columns */
["voyce_series_tags_max_fields"]: {
	__typename: "voyce_series_tags_max_fields",
	id?: number,
	name?: string
};
	/** aggregate min on columns */
["voyce_series_tags_min_fields"]: {
	__typename: "voyce_series_tags_min_fields",
	id?: number,
	name?: string
};
	/** response of any mutation on the table "voyce.series_tags" */
["voyce_series_tags_mutation_response"]: {
	__typename: "voyce_series_tags_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_tags"]>
};
	/** input type for inserting object relation for remote table "voyce.series_tags" */
["voyce_series_tags_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_series_tags_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_series_tags_on_conflict"]
};
	/** on_conflict condition type for table "voyce.series_tags" */
["voyce_series_tags_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_tags_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_tags_update_column"]>,
	where?: GraphQLTypes["voyce_series_tags_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_tags". */
["voyce_series_tags_order_by"]: {
		id?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	series_tags_junctions_aggregate?: GraphQLTypes["voyce_series_tags_junction_aggregate_order_by"]
};
	/** primary key columns input for table: voyce.series_tags */
["voyce_series_tags_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_tags" */
["voyce_series_tags_select_column"]: voyce_series_tags_select_column;
	/** input type for updating data in table "voyce.series_tags" */
["voyce_series_tags_set_input"]: {
		id?: number,
	name?: string
};
	/** aggregate stddev on columns */
["voyce_series_tags_stddev_fields"]: {
	__typename: "voyce_series_tags_stddev_fields",
	id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_tags_stddev_pop_fields"]: {
	__typename: "voyce_series_tags_stddev_pop_fields",
	id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_tags_stddev_samp_fields"]: {
	__typename: "voyce_series_tags_stddev_samp_fields",
	id?: number
};
	/** Streaming cursor of the table "voyce_series_tags" */
["voyce_series_tags_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_tags_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_tags_stream_cursor_value_input"]: {
		id?: number,
	name?: string
};
	/** aggregate sum on columns */
["voyce_series_tags_sum_fields"]: {
	__typename: "voyce_series_tags_sum_fields",
	id?: number
};
	/** update columns of table "voyce.series_tags" */
["voyce_series_tags_update_column"]: voyce_series_tags_update_column;
	["voyce_series_tags_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_tags_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_tags_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_tags_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_tags_var_pop_fields"]: {
	__typename: "voyce_series_tags_var_pop_fields",
	id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_tags_var_samp_fields"]: {
	__typename: "voyce_series_tags_var_samp_fields",
	id?: number
};
	/** aggregate variance on columns */
["voyce_series_tags_variance_fields"]: {
	__typename: "voyce_series_tags_variance_fields",
	id?: number
};
	/** columns and relationships of "voyce.series_top_comments" */
["voyce_series_top_comments"]: {
	__typename: "voyce_series_top_comments",
	/** An object relationship */
	comment?: GraphQLTypes["voyce_chapter_comments"],
	comment_id: number,
	count: number,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	series_id: number,
	tag: string,
	type: string
};
	/** aggregated selection of "voyce.series_top_comments" */
["voyce_series_top_comments_aggregate"]: {
	__typename: "voyce_series_top_comments_aggregate",
	aggregate?: GraphQLTypes["voyce_series_top_comments_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_top_comments"]>
};
	/** aggregate fields of "voyce.series_top_comments" */
["voyce_series_top_comments_aggregate_fields"]: {
	__typename: "voyce_series_top_comments_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_top_comments_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_top_comments_max_fields"],
	min?: GraphQLTypes["voyce_series_top_comments_min_fields"],
	stddev?: GraphQLTypes["voyce_series_top_comments_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_top_comments_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_top_comments_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_top_comments_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_top_comments_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_top_comments_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_top_comments_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_top_comments_avg_fields"]: {
	__typename: "voyce_series_top_comments_avg_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_top_comments". All fields are combined with a logical 'AND'. */
["voyce_series_top_comments_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_top_comments_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_top_comments_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_top_comments_bool_exp"]>,
	comment?: GraphQLTypes["voyce_chapter_comments_bool_exp"],
	comment_id?: GraphQLTypes["Int_comparison_exp"],
	count?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	tag?: GraphQLTypes["String_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_top_comments" */
["voyce_series_top_comments_constraint"]: voyce_series_top_comments_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_top_comments" */
["voyce_series_top_comments_inc_input"]: {
		comment_id?: number,
	count?: number,
	id?: number,
	series_id?: number
};
	/** input type for inserting data into table "voyce.series_top_comments" */
["voyce_series_top_comments_insert_input"]: {
		comment?: GraphQLTypes["voyce_chapter_comments_obj_rel_insert_input"],
	comment_id?: number,
	count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	tag?: string,
	type?: string
};
	/** aggregate max on columns */
["voyce_series_top_comments_max_fields"]: {
	__typename: "voyce_series_top_comments_max_fields",
	comment_id?: number,
	count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	tag?: string,
	type?: string
};
	/** aggregate min on columns */
["voyce_series_top_comments_min_fields"]: {
	__typename: "voyce_series_top_comments_min_fields",
	comment_id?: number,
	count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	tag?: string,
	type?: string
};
	/** response of any mutation on the table "voyce.series_top_comments" */
["voyce_series_top_comments_mutation_response"]: {
	__typename: "voyce_series_top_comments_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_top_comments"]>
};
	/** on_conflict condition type for table "voyce.series_top_comments" */
["voyce_series_top_comments_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_top_comments_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_top_comments_update_column"]>,
	where?: GraphQLTypes["voyce_series_top_comments_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_top_comments". */
["voyce_series_top_comments_order_by"]: {
		comment?: GraphQLTypes["voyce_chapter_comments_order_by"],
	comment_id?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	tag?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_top_comments */
["voyce_series_top_comments_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_top_comments" */
["voyce_series_top_comments_select_column"]: voyce_series_top_comments_select_column;
	/** input type for updating data in table "voyce.series_top_comments" */
["voyce_series_top_comments_set_input"]: {
		comment_id?: number,
	count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	tag?: string,
	type?: string
};
	/** aggregate stddev on columns */
["voyce_series_top_comments_stddev_fields"]: {
	__typename: "voyce_series_top_comments_stddev_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_top_comments_stddev_pop_fields"]: {
	__typename: "voyce_series_top_comments_stddev_pop_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_top_comments_stddev_samp_fields"]: {
	__typename: "voyce_series_top_comments_stddev_samp_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_series_top_comments" */
["voyce_series_top_comments_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_top_comments_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_top_comments_stream_cursor_value_input"]: {
		comment_id?: number,
	count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	tag?: string,
	type?: string
};
	/** aggregate sum on columns */
["voyce_series_top_comments_sum_fields"]: {
	__typename: "voyce_series_top_comments_sum_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	series_id?: number
};
	/** update columns of table "voyce.series_top_comments" */
["voyce_series_top_comments_update_column"]: voyce_series_top_comments_update_column;
	["voyce_series_top_comments_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_top_comments_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_top_comments_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_top_comments_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_top_comments_var_pop_fields"]: {
	__typename: "voyce_series_top_comments_var_pop_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_top_comments_var_samp_fields"]: {
	__typename: "voyce_series_top_comments_var_samp_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_series_top_comments_variance_fields"]: {
	__typename: "voyce_series_top_comments_variance_fields",
	comment_id?: number,
	count?: number,
	id?: number,
	series_id?: number
};
	/** columns and relationships of "voyce.series_top_likes" */
["voyce_series_top_likes"]: {
	__typename: "voyce_series_top_likes",
	count: number,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	is_original: GraphQLTypes["smallint"],
	/** An object relationship */
	series: GraphQLTypes["voyce_series"],
	series_id: number,
	type: string
};
	/** aggregated selection of "voyce.series_top_likes" */
["voyce_series_top_likes_aggregate"]: {
	__typename: "voyce_series_top_likes_aggregate",
	aggregate?: GraphQLTypes["voyce_series_top_likes_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_top_likes"]>
};
	/** aggregate fields of "voyce.series_top_likes" */
["voyce_series_top_likes_aggregate_fields"]: {
	__typename: "voyce_series_top_likes_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_top_likes_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_top_likes_max_fields"],
	min?: GraphQLTypes["voyce_series_top_likes_min_fields"],
	stddev?: GraphQLTypes["voyce_series_top_likes_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_top_likes_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_top_likes_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_top_likes_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_top_likes_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_top_likes_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_top_likes_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_top_likes_avg_fields"]: {
	__typename: "voyce_series_top_likes_avg_fields",
	count?: number,
	id?: number,
	is_original?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_top_likes". All fields are combined with a logical 'AND'. */
["voyce_series_top_likes_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_top_likes_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_top_likes_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_top_likes_bool_exp"]>,
	count?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_original?: GraphQLTypes["smallint_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_top_likes" */
["voyce_series_top_likes_constraint"]: voyce_series_top_likes_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_top_likes" */
["voyce_series_top_likes_inc_input"]: {
		count?: number,
	id?: number,
	is_original?: GraphQLTypes["smallint"],
	series_id?: number
};
	/** input type for inserting data into table "voyce.series_top_likes" */
["voyce_series_top_likes_insert_input"]: {
		count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	is_original?: GraphQLTypes["smallint"],
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	type?: string
};
	/** aggregate max on columns */
["voyce_series_top_likes_max_fields"]: {
	__typename: "voyce_series_top_likes_max_fields",
	count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	is_original?: GraphQLTypes["smallint"],
	series_id?: number,
	type?: string
};
	/** aggregate min on columns */
["voyce_series_top_likes_min_fields"]: {
	__typename: "voyce_series_top_likes_min_fields",
	count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	is_original?: GraphQLTypes["smallint"],
	series_id?: number,
	type?: string
};
	/** response of any mutation on the table "voyce.series_top_likes" */
["voyce_series_top_likes_mutation_response"]: {
	__typename: "voyce_series_top_likes_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_top_likes"]>
};
	/** on_conflict condition type for table "voyce.series_top_likes" */
["voyce_series_top_likes_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_top_likes_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_top_likes_update_column"]>,
	where?: GraphQLTypes["voyce_series_top_likes_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_top_likes". */
["voyce_series_top_likes_order_by"]: {
		count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_original?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_top_likes */
["voyce_series_top_likes_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_top_likes" */
["voyce_series_top_likes_select_column"]: voyce_series_top_likes_select_column;
	/** input type for updating data in table "voyce.series_top_likes" */
["voyce_series_top_likes_set_input"]: {
		count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	is_original?: GraphQLTypes["smallint"],
	series_id?: number,
	type?: string
};
	/** aggregate stddev on columns */
["voyce_series_top_likes_stddev_fields"]: {
	__typename: "voyce_series_top_likes_stddev_fields",
	count?: number,
	id?: number,
	is_original?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_top_likes_stddev_pop_fields"]: {
	__typename: "voyce_series_top_likes_stddev_pop_fields",
	count?: number,
	id?: number,
	is_original?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_top_likes_stddev_samp_fields"]: {
	__typename: "voyce_series_top_likes_stddev_samp_fields",
	count?: number,
	id?: number,
	is_original?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_series_top_likes" */
["voyce_series_top_likes_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_top_likes_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_top_likes_stream_cursor_value_input"]: {
		count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	is_original?: GraphQLTypes["smallint"],
	series_id?: number,
	type?: string
};
	/** aggregate sum on columns */
["voyce_series_top_likes_sum_fields"]: {
	__typename: "voyce_series_top_likes_sum_fields",
	count?: number,
	id?: number,
	is_original?: GraphQLTypes["smallint"],
	series_id?: number
};
	/** update columns of table "voyce.series_top_likes" */
["voyce_series_top_likes_update_column"]: voyce_series_top_likes_update_column;
	["voyce_series_top_likes_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_top_likes_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_top_likes_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_top_likes_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_top_likes_var_pop_fields"]: {
	__typename: "voyce_series_top_likes_var_pop_fields",
	count?: number,
	id?: number,
	is_original?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_top_likes_var_samp_fields"]: {
	__typename: "voyce_series_top_likes_var_samp_fields",
	count?: number,
	id?: number,
	is_original?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_series_top_likes_variance_fields"]: {
	__typename: "voyce_series_top_likes_variance_fields",
	count?: number,
	id?: number,
	is_original?: number,
	series_id?: number
};
	/** columns and relationships of "voyce.series_top_views" */
["voyce_series_top_views"]: {
	__typename: "voyce_series_top_views",
	comic_type?: string,
	count: number,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	prev_rank: number,
	rank: number,
	/** An object relationship */
	series: GraphQLTypes["voyce_series"],
	series_id: number,
	tag?: string,
	type: string
};
	/** aggregated selection of "voyce.series_top_views" */
["voyce_series_top_views_aggregate"]: {
	__typename: "voyce_series_top_views_aggregate",
	aggregate?: GraphQLTypes["voyce_series_top_views_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_top_views"]>
};
	/** aggregate fields of "voyce.series_top_views" */
["voyce_series_top_views_aggregate_fields"]: {
	__typename: "voyce_series_top_views_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_top_views_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_top_views_max_fields"],
	min?: GraphQLTypes["voyce_series_top_views_min_fields"],
	stddev?: GraphQLTypes["voyce_series_top_views_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_top_views_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_top_views_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_top_views_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_top_views_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_top_views_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_top_views_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_top_views_avg_fields"]: {
	__typename: "voyce_series_top_views_avg_fields",
	count?: number,
	id?: number,
	prev_rank?: number,
	rank?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_top_views". All fields are combined with a logical 'AND'. */
["voyce_series_top_views_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_top_views_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_top_views_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_top_views_bool_exp"]>,
	comic_type?: GraphQLTypes["String_comparison_exp"],
	count?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	prev_rank?: GraphQLTypes["Int_comparison_exp"],
	rank?: GraphQLTypes["Int_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	tag?: GraphQLTypes["String_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_top_views" */
["voyce_series_top_views_constraint"]: voyce_series_top_views_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_top_views" */
["voyce_series_top_views_inc_input"]: {
		count?: number,
	id?: number,
	prev_rank?: number,
	rank?: number,
	series_id?: number
};
	/** input type for inserting data into table "voyce.series_top_views" */
["voyce_series_top_views_insert_input"]: {
		comic_type?: string,
	count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	prev_rank?: number,
	rank?: number,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	tag?: string,
	type?: string
};
	/** aggregate max on columns */
["voyce_series_top_views_max_fields"]: {
	__typename: "voyce_series_top_views_max_fields",
	comic_type?: string,
	count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	prev_rank?: number,
	rank?: number,
	series_id?: number,
	tag?: string,
	type?: string
};
	/** aggregate min on columns */
["voyce_series_top_views_min_fields"]: {
	__typename: "voyce_series_top_views_min_fields",
	comic_type?: string,
	count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	prev_rank?: number,
	rank?: number,
	series_id?: number,
	tag?: string,
	type?: string
};
	/** response of any mutation on the table "voyce.series_top_views" */
["voyce_series_top_views_mutation_response"]: {
	__typename: "voyce_series_top_views_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_top_views"]>
};
	/** on_conflict condition type for table "voyce.series_top_views" */
["voyce_series_top_views_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_top_views_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_top_views_update_column"]>,
	where?: GraphQLTypes["voyce_series_top_views_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_top_views". */
["voyce_series_top_views_order_by"]: {
		comic_type?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	prev_rank?: GraphQLTypes["order_by"],
	rank?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	tag?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_top_views */
["voyce_series_top_views_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_top_views" */
["voyce_series_top_views_select_column"]: voyce_series_top_views_select_column;
	/** input type for updating data in table "voyce.series_top_views" */
["voyce_series_top_views_set_input"]: {
		comic_type?: string,
	count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	prev_rank?: number,
	rank?: number,
	series_id?: number,
	tag?: string,
	type?: string
};
	/** aggregate stddev on columns */
["voyce_series_top_views_stddev_fields"]: {
	__typename: "voyce_series_top_views_stddev_fields",
	count?: number,
	id?: number,
	prev_rank?: number,
	rank?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_top_views_stddev_pop_fields"]: {
	__typename: "voyce_series_top_views_stddev_pop_fields",
	count?: number,
	id?: number,
	prev_rank?: number,
	rank?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_top_views_stddev_samp_fields"]: {
	__typename: "voyce_series_top_views_stddev_samp_fields",
	count?: number,
	id?: number,
	prev_rank?: number,
	rank?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_series_top_views" */
["voyce_series_top_views_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_top_views_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_top_views_stream_cursor_value_input"]: {
		comic_type?: string,
	count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	prev_rank?: number,
	rank?: number,
	series_id?: number,
	tag?: string,
	type?: string
};
	/** aggregate sum on columns */
["voyce_series_top_views_sum_fields"]: {
	__typename: "voyce_series_top_views_sum_fields",
	count?: number,
	id?: number,
	prev_rank?: number,
	rank?: number,
	series_id?: number
};
	/** update columns of table "voyce.series_top_views" */
["voyce_series_top_views_update_column"]: voyce_series_top_views_update_column;
	["voyce_series_top_views_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_top_views_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_top_views_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_top_views_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_top_views_var_pop_fields"]: {
	__typename: "voyce_series_top_views_var_pop_fields",
	count?: number,
	id?: number,
	prev_rank?: number,
	rank?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_top_views_var_samp_fields"]: {
	__typename: "voyce_series_top_views_var_samp_fields",
	count?: number,
	id?: number,
	prev_rank?: number,
	rank?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_series_top_views_variance_fields"]: {
	__typename: "voyce_series_top_views_variance_fields",
	count?: number,
	id?: number,
	prev_rank?: number,
	rank?: number,
	series_id?: number
};
	/** update columns of table "voyce.series" */
["voyce_series_update_column"]: voyce_series_update_column;
	["voyce_series_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_var_pop_fields"]: {
	__typename: "voyce_series_var_pop_fields",
	average_rating?: number,
	chapter_count?: number,
	coin_price?: number,
	comic_type_id?: number,
	frequency_mask?: number,
	id?: number,
	is_approved?: number,
	is_intro?: number,
	is_original?: number,
	is_pilots?: number,
	is_recommended?: number,
	locked_chapters_amount?: number,
	pilots_votes_count?: number,
	publish?: number,
	rating_count?: number,
	subscriber_count?: number,
	trending_position?: number,
	user_id?: number,
	view_count?: number
};
	/** aggregate var_samp on columns */
["voyce_series_var_samp_fields"]: {
	__typename: "voyce_series_var_samp_fields",
	average_rating?: number,
	chapter_count?: number,
	coin_price?: number,
	comic_type_id?: number,
	frequency_mask?: number,
	id?: number,
	is_approved?: number,
	is_intro?: number,
	is_original?: number,
	is_pilots?: number,
	is_recommended?: number,
	locked_chapters_amount?: number,
	pilots_votes_count?: number,
	publish?: number,
	rating_count?: number,
	subscriber_count?: number,
	trending_position?: number,
	user_id?: number,
	view_count?: number
};
	/** aggregate variance on columns */
["voyce_series_variance_fields"]: {
	__typename: "voyce_series_variance_fields",
	average_rating?: number,
	chapter_count?: number,
	coin_price?: number,
	comic_type_id?: number,
	frequency_mask?: number,
	id?: number,
	is_approved?: number,
	is_intro?: number,
	is_original?: number,
	is_pilots?: number,
	is_recommended?: number,
	locked_chapters_amount?: number,
	pilots_votes_count?: number,
	publish?: number,
	rating_count?: number,
	subscriber_count?: number,
	trending_position?: number,
	user_id?: number,
	view_count?: number
};
	/** columns and relationships of "voyce.series_views_adjustment" */
["voyce_series_views_adjustment"]: {
	__typename: "voyce_series_views_adjustment",
	count: number,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	series_id: number,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_aggregate"]: {
	__typename: "voyce_series_views_adjustment_aggregate",
	aggregate?: GraphQLTypes["voyce_series_views_adjustment_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_views_adjustment"]>
};
	/** aggregate fields of "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_aggregate_fields"]: {
	__typename: "voyce_series_views_adjustment_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_views_adjustment_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_views_adjustment_max_fields"],
	min?: GraphQLTypes["voyce_series_views_adjustment_min_fields"],
	stddev?: GraphQLTypes["voyce_series_views_adjustment_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_views_adjustment_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_views_adjustment_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_views_adjustment_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_views_adjustment_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_views_adjustment_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_views_adjustment_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_views_adjustment_avg_fields"]: {
	__typename: "voyce_series_views_adjustment_avg_fields",
	count?: number,
	id?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_views_adjustment". All fields are combined with a logical 'AND'. */
["voyce_series_views_adjustment_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_views_adjustment_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_views_adjustment_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_views_adjustment_bool_exp"]>,
	count?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_constraint"]: voyce_series_views_adjustment_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_inc_input"]: {
		count?: number,
	id?: number,
	series_id?: number
};
	/** input type for inserting data into table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_insert_input"]: {
		count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_series_views_adjustment_max_fields"]: {
	__typename: "voyce_series_views_adjustment_max_fields",
	count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_series_views_adjustment_min_fields"]: {
	__typename: "voyce_series_views_adjustment_min_fields",
	count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_mutation_response"]: {
	__typename: "voyce_series_views_adjustment_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_views_adjustment"]>
};
	/** on_conflict condition type for table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_views_adjustment_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_views_adjustment_update_column"]>,
	where?: GraphQLTypes["voyce_series_views_adjustment_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_views_adjustment". */
["voyce_series_views_adjustment_order_by"]: {
		count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_views_adjustment */
["voyce_series_views_adjustment_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_select_column"]: voyce_series_views_adjustment_select_column;
	/** input type for updating data in table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_set_input"]: {
		count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_series_views_adjustment_stddev_fields"]: {
	__typename: "voyce_series_views_adjustment_stddev_fields",
	count?: number,
	id?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_views_adjustment_stddev_pop_fields"]: {
	__typename: "voyce_series_views_adjustment_stddev_pop_fields",
	count?: number,
	id?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_views_adjustment_stddev_samp_fields"]: {
	__typename: "voyce_series_views_adjustment_stddev_samp_fields",
	count?: number,
	id?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_series_views_adjustment" */
["voyce_series_views_adjustment_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_views_adjustment_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_views_adjustment_stream_cursor_value_input"]: {
		count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_series_views_adjustment_sum_fields"]: {
	__typename: "voyce_series_views_adjustment_sum_fields",
	count?: number,
	id?: number,
	series_id?: number
};
	/** update columns of table "voyce.series_views_adjustment" */
["voyce_series_views_adjustment_update_column"]: voyce_series_views_adjustment_update_column;
	["voyce_series_views_adjustment_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_views_adjustment_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_views_adjustment_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_views_adjustment_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_views_adjustment_var_pop_fields"]: {
	__typename: "voyce_series_views_adjustment_var_pop_fields",
	count?: number,
	id?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_views_adjustment_var_samp_fields"]: {
	__typename: "voyce_series_views_adjustment_var_samp_fields",
	count?: number,
	id?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_series_views_adjustment_variance_fields"]: {
	__typename: "voyce_series_views_adjustment_variance_fields",
	count?: number,
	id?: number,
	series_id?: number
};
	/** columns and relationships of "voyce.series_views_count" */
["voyce_series_views_count"]: {
	__typename: "voyce_series_views_count",
	count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** aggregated selection of "voyce.series_views_count" */
["voyce_series_views_count_aggregate"]: {
	__typename: "voyce_series_views_count_aggregate",
	aggregate?: GraphQLTypes["voyce_series_views_count_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_views_count"]>
};
	/** aggregate fields of "voyce.series_views_count" */
["voyce_series_views_count_aggregate_fields"]: {
	__typename: "voyce_series_views_count_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_views_count_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_views_count_max_fields"],
	min?: GraphQLTypes["voyce_series_views_count_min_fields"],
	stddev?: GraphQLTypes["voyce_series_views_count_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_views_count_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_views_count_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_views_count_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_views_count_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_views_count_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_views_count_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_views_count_avg_fields"]: {
	__typename: "voyce_series_views_count_avg_fields",
	count?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_views_count". All fields are combined with a logical 'AND'. */
["voyce_series_views_count_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_views_count_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_views_count_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_views_count_bool_exp"]>,
	count?: GraphQLTypes["bigint_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** columns and relationships of "voyce.series_views_count_mat" */
["voyce_series_views_count_mat"]: {
	__typename: "voyce_series_views_count_mat",
	actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** aggregated selection of "voyce.series_views_count_mat" */
["voyce_series_views_count_mat_aggregate"]: {
	__typename: "voyce_series_views_count_mat_aggregate",
	aggregate?: GraphQLTypes["voyce_series_views_count_mat_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_views_count_mat"]>
};
	/** aggregate fields of "voyce.series_views_count_mat" */
["voyce_series_views_count_mat_aggregate_fields"]: {
	__typename: "voyce_series_views_count_mat_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_views_count_mat_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_views_count_mat_max_fields"],
	min?: GraphQLTypes["voyce_series_views_count_mat_min_fields"],
	stddev?: GraphQLTypes["voyce_series_views_count_mat_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_views_count_mat_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_views_count_mat_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_views_count_mat_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_views_count_mat_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_views_count_mat_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_views_count_mat_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_views_count_mat_avg_fields"]: {
	__typename: "voyce_series_views_count_mat_avg_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_views_count_mat". All fields are combined with a logical 'AND'. */
["voyce_series_views_count_mat_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_views_count_mat_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_views_count_mat_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_views_count_mat_bool_exp"]>,
	actual_count?: GraphQLTypes["bigint_comparison_exp"],
	count?: GraphQLTypes["float8_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate max on columns */
["voyce_series_views_count_mat_max_fields"]: {
	__typename: "voyce_series_views_count_mat_max_fields",
	actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** aggregate min on columns */
["voyce_series_views_count_mat_min_fields"]: {
	__typename: "voyce_series_views_count_mat_min_fields",
	actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** Ordering options when selecting data from "voyce.series_views_count_mat". */
["voyce_series_views_count_mat_order_by"]: {
		actual_count?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.series_views_count_mat" */
["voyce_series_views_count_mat_select_column"]: voyce_series_views_count_mat_select_column;
	/** columns and relationships of "voyce.series_views_count_mat_sep30" */
["voyce_series_views_count_mat_sep30"]: {
	__typename: "voyce_series_views_count_mat_sep30",
	actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** aggregated selection of "voyce.series_views_count_mat_sep30" */
["voyce_series_views_count_mat_sep30_aggregate"]: {
	__typename: "voyce_series_views_count_mat_sep30_aggregate",
	aggregate?: GraphQLTypes["voyce_series_views_count_mat_sep30_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_views_count_mat_sep30"]>
};
	/** aggregate fields of "voyce.series_views_count_mat_sep30" */
["voyce_series_views_count_mat_sep30_aggregate_fields"]: {
	__typename: "voyce_series_views_count_mat_sep30_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_views_count_mat_sep30_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_views_count_mat_sep30_max_fields"],
	min?: GraphQLTypes["voyce_series_views_count_mat_sep30_min_fields"],
	stddev?: GraphQLTypes["voyce_series_views_count_mat_sep30_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_views_count_mat_sep30_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_views_count_mat_sep30_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_views_count_mat_sep30_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_views_count_mat_sep30_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_views_count_mat_sep30_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_views_count_mat_sep30_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_views_count_mat_sep30_avg_fields"]: {
	__typename: "voyce_series_views_count_mat_sep30_avg_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_views_count_mat_sep30". All fields are combined with a logical 'AND'. */
["voyce_series_views_count_mat_sep30_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_views_count_mat_sep30_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_views_count_mat_sep30_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_views_count_mat_sep30_bool_exp"]>,
	actual_count?: GraphQLTypes["bigint_comparison_exp"],
	count?: GraphQLTypes["float8_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate max on columns */
["voyce_series_views_count_mat_sep30_max_fields"]: {
	__typename: "voyce_series_views_count_mat_sep30_max_fields",
	actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** aggregate min on columns */
["voyce_series_views_count_mat_sep30_min_fields"]: {
	__typename: "voyce_series_views_count_mat_sep30_min_fields",
	actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** Ordering options when selecting data from "voyce.series_views_count_mat_sep30". */
["voyce_series_views_count_mat_sep30_order_by"]: {
		actual_count?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.series_views_count_mat_sep30" */
["voyce_series_views_count_mat_sep30_select_column"]: voyce_series_views_count_mat_sep30_select_column;
	/** aggregate stddev on columns */
["voyce_series_views_count_mat_sep30_stddev_fields"]: {
	__typename: "voyce_series_views_count_mat_sep30_stddev_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_views_count_mat_sep30_stddev_pop_fields"]: {
	__typename: "voyce_series_views_count_mat_sep30_stddev_pop_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_views_count_mat_sep30_stddev_samp_fields"]: {
	__typename: "voyce_series_views_count_mat_sep30_stddev_samp_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_series_views_count_mat_sep30" */
["voyce_series_views_count_mat_sep30_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_views_count_mat_sep30_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_views_count_mat_sep30_stream_cursor_value_input"]: {
		actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** aggregate sum on columns */
["voyce_series_views_count_mat_sep30_sum_fields"]: {
	__typename: "voyce_series_views_count_mat_sep30_sum_fields",
	actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** aggregate var_pop on columns */
["voyce_series_views_count_mat_sep30_var_pop_fields"]: {
	__typename: "voyce_series_views_count_mat_sep30_var_pop_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_views_count_mat_sep30_var_samp_fields"]: {
	__typename: "voyce_series_views_count_mat_sep30_var_samp_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_series_views_count_mat_sep30_variance_fields"]: {
	__typename: "voyce_series_views_count_mat_sep30_variance_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate stddev on columns */
["voyce_series_views_count_mat_stddev_fields"]: {
	__typename: "voyce_series_views_count_mat_stddev_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_views_count_mat_stddev_pop_fields"]: {
	__typename: "voyce_series_views_count_mat_stddev_pop_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_views_count_mat_stddev_samp_fields"]: {
	__typename: "voyce_series_views_count_mat_stddev_samp_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_series_views_count_mat" */
["voyce_series_views_count_mat_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_views_count_mat_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_views_count_mat_stream_cursor_value_input"]: {
		actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** aggregate sum on columns */
["voyce_series_views_count_mat_sum_fields"]: {
	__typename: "voyce_series_views_count_mat_sum_fields",
	actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** columns and relationships of "voyce.series_views_count_mat_v2" */
["voyce_series_views_count_mat_v2"]: {
	__typename: "voyce_series_views_count_mat_v2",
	actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** aggregated selection of "voyce.series_views_count_mat_v2" */
["voyce_series_views_count_mat_v2_aggregate"]: {
	__typename: "voyce_series_views_count_mat_v2_aggregate",
	aggregate?: GraphQLTypes["voyce_series_views_count_mat_v2_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_views_count_mat_v2"]>
};
	/** aggregate fields of "voyce.series_views_count_mat_v2" */
["voyce_series_views_count_mat_v2_aggregate_fields"]: {
	__typename: "voyce_series_views_count_mat_v2_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_views_count_mat_v2_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_views_count_mat_v2_max_fields"],
	min?: GraphQLTypes["voyce_series_views_count_mat_v2_min_fields"],
	stddev?: GraphQLTypes["voyce_series_views_count_mat_v2_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_views_count_mat_v2_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_views_count_mat_v2_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_views_count_mat_v2_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_views_count_mat_v2_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_views_count_mat_v2_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_views_count_mat_v2_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_views_count_mat_v2_avg_fields"]: {
	__typename: "voyce_series_views_count_mat_v2_avg_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_views_count_mat_v2". All fields are combined with a logical 'AND'. */
["voyce_series_views_count_mat_v2_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_views_count_mat_v2_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_views_count_mat_v2_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_views_count_mat_v2_bool_exp"]>,
	actual_count?: GraphQLTypes["bigint_comparison_exp"],
	count?: GraphQLTypes["float8_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate max on columns */
["voyce_series_views_count_mat_v2_max_fields"]: {
	__typename: "voyce_series_views_count_mat_v2_max_fields",
	actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** aggregate min on columns */
["voyce_series_views_count_mat_v2_min_fields"]: {
	__typename: "voyce_series_views_count_mat_v2_min_fields",
	actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** Ordering options when selecting data from "voyce.series_views_count_mat_v2". */
["voyce_series_views_count_mat_v2_order_by"]: {
		actual_count?: GraphQLTypes["order_by"],
	count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.series_views_count_mat_v2" */
["voyce_series_views_count_mat_v2_select_column"]: voyce_series_views_count_mat_v2_select_column;
	/** aggregate stddev on columns */
["voyce_series_views_count_mat_v2_stddev_fields"]: {
	__typename: "voyce_series_views_count_mat_v2_stddev_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_views_count_mat_v2_stddev_pop_fields"]: {
	__typename: "voyce_series_views_count_mat_v2_stddev_pop_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_views_count_mat_v2_stddev_samp_fields"]: {
	__typename: "voyce_series_views_count_mat_v2_stddev_samp_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_series_views_count_mat_v2" */
["voyce_series_views_count_mat_v2_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_views_count_mat_v2_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_views_count_mat_v2_stream_cursor_value_input"]: {
		actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** aggregate sum on columns */
["voyce_series_views_count_mat_v2_sum_fields"]: {
	__typename: "voyce_series_views_count_mat_v2_sum_fields",
	actual_count?: GraphQLTypes["bigint"],
	count?: GraphQLTypes["float8"],
	series_id?: number
};
	/** aggregate var_pop on columns */
["voyce_series_views_count_mat_v2_var_pop_fields"]: {
	__typename: "voyce_series_views_count_mat_v2_var_pop_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_views_count_mat_v2_var_samp_fields"]: {
	__typename: "voyce_series_views_count_mat_v2_var_samp_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_series_views_count_mat_v2_variance_fields"]: {
	__typename: "voyce_series_views_count_mat_v2_variance_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate var_pop on columns */
["voyce_series_views_count_mat_var_pop_fields"]: {
	__typename: "voyce_series_views_count_mat_var_pop_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_views_count_mat_var_samp_fields"]: {
	__typename: "voyce_series_views_count_mat_var_samp_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_series_views_count_mat_variance_fields"]: {
	__typename: "voyce_series_views_count_mat_variance_fields",
	actual_count?: number,
	count?: number,
	series_id?: number
};
	/** aggregate max on columns */
["voyce_series_views_count_max_fields"]: {
	__typename: "voyce_series_views_count_max_fields",
	count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** aggregate min on columns */
["voyce_series_views_count_min_fields"]: {
	__typename: "voyce_series_views_count_min_fields",
	count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** Ordering options when selecting data from "voyce.series_views_count". */
["voyce_series_views_count_order_by"]: {
		count?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.series_views_count" */
["voyce_series_views_count_select_column"]: voyce_series_views_count_select_column;
	/** aggregate stddev on columns */
["voyce_series_views_count_stddev_fields"]: {
	__typename: "voyce_series_views_count_stddev_fields",
	count?: number,
	series_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_views_count_stddev_pop_fields"]: {
	__typename: "voyce_series_views_count_stddev_pop_fields",
	count?: number,
	series_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_views_count_stddev_samp_fields"]: {
	__typename: "voyce_series_views_count_stddev_samp_fields",
	count?: number,
	series_id?: number
};
	/** Streaming cursor of the table "voyce_series_views_count" */
["voyce_series_views_count_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_views_count_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_views_count_stream_cursor_value_input"]: {
		count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** aggregate sum on columns */
["voyce_series_views_count_sum_fields"]: {
	__typename: "voyce_series_views_count_sum_fields",
	count?: GraphQLTypes["bigint"],
	series_id?: number
};
	/** aggregate var_pop on columns */
["voyce_series_views_count_var_pop_fields"]: {
	__typename: "voyce_series_views_count_var_pop_fields",
	count?: number,
	series_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_views_count_var_samp_fields"]: {
	__typename: "voyce_series_views_count_var_samp_fields",
	count?: number,
	series_id?: number
};
	/** aggregate variance on columns */
["voyce_series_views_count_variance_fields"]: {
	__typename: "voyce_series_views_count_variance_fields",
	count?: number,
	series_id?: number
};
	/** columns and relationships of "voyce.series_votes" */
["voyce_series_votes"]: {
	__typename: "voyce_series_votes",
	created_at: GraphQLTypes["timestamp"],
	date?: GraphQLTypes["date"],
	gtag_id?: string,
	id: number,
	series_id?: number,
	tag?: string,
	user_id?: number
};
	/** aggregated selection of "voyce.series_votes" */
["voyce_series_votes_aggregate"]: {
	__typename: "voyce_series_votes_aggregate",
	aggregate?: GraphQLTypes["voyce_series_votes_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_votes"]>
};
	/** aggregate fields of "voyce.series_votes" */
["voyce_series_votes_aggregate_fields"]: {
	__typename: "voyce_series_votes_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_votes_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_votes_max_fields"],
	min?: GraphQLTypes["voyce_series_votes_min_fields"],
	stddev?: GraphQLTypes["voyce_series_votes_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_votes_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_votes_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_votes_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_votes_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_votes_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_votes_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_votes_avg_fields"]: {
	__typename: "voyce_series_votes_avg_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_votes". All fields are combined with a logical 'AND'. */
["voyce_series_votes_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_votes_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_votes_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_votes_bool_exp"]>,
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	date?: GraphQLTypes["date_comparison_exp"],
	gtag_id?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	tag?: GraphQLTypes["String_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_votes" */
["voyce_series_votes_constraint"]: voyce_series_votes_constraint;
	/** columns and relationships of "voyce.series_votes_count" */
["voyce_series_votes_count"]: {
	__typename: "voyce_series_votes_count",
	count: number,
	created_at: GraphQLTypes["timestamptz"],
	date: GraphQLTypes["date"],
	id: number,
	series_id?: number,
	tag: string,
	total: number,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.series_votes_count" */
["voyce_series_votes_count_aggregate"]: {
	__typename: "voyce_series_votes_count_aggregate",
	aggregate?: GraphQLTypes["voyce_series_votes_count_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_votes_count"]>
};
	/** aggregate fields of "voyce.series_votes_count" */
["voyce_series_votes_count_aggregate_fields"]: {
	__typename: "voyce_series_votes_count_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_votes_count_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_votes_count_max_fields"],
	min?: GraphQLTypes["voyce_series_votes_count_min_fields"],
	stddev?: GraphQLTypes["voyce_series_votes_count_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_votes_count_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_votes_count_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_votes_count_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_votes_count_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_votes_count_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_votes_count_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_votes_count_avg_fields"]: {
	__typename: "voyce_series_votes_count_avg_fields",
	count?: number,
	id?: number,
	series_id?: number,
	total?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_votes_count". All fields are combined with a logical 'AND'. */
["voyce_series_votes_count_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_votes_count_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_votes_count_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_votes_count_bool_exp"]>,
	count?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	date?: GraphQLTypes["date_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	tag?: GraphQLTypes["String_comparison_exp"],
	total?: GraphQLTypes["Int_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_votes_count" */
["voyce_series_votes_count_constraint"]: voyce_series_votes_count_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_votes_count" */
["voyce_series_votes_count_inc_input"]: {
		count?: number,
	id?: number,
	series_id?: number,
	total?: number
};
	/** input type for inserting data into table "voyce.series_votes_count" */
["voyce_series_votes_count_insert_input"]: {
		count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	date?: GraphQLTypes["date"],
	id?: number,
	series_id?: number,
	tag?: string,
	total?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_series_votes_count_max_fields"]: {
	__typename: "voyce_series_votes_count_max_fields",
	count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	date?: GraphQLTypes["date"],
	id?: number,
	series_id?: number,
	tag?: string,
	total?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_series_votes_count_min_fields"]: {
	__typename: "voyce_series_votes_count_min_fields",
	count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	date?: GraphQLTypes["date"],
	id?: number,
	series_id?: number,
	tag?: string,
	total?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.series_votes_count" */
["voyce_series_votes_count_mutation_response"]: {
	__typename: "voyce_series_votes_count_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_votes_count"]>
};
	/** on_conflict condition type for table "voyce.series_votes_count" */
["voyce_series_votes_count_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_votes_count_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_votes_count_update_column"]>,
	where?: GraphQLTypes["voyce_series_votes_count_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_votes_count". */
["voyce_series_votes_count_order_by"]: {
		count?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	date?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	tag?: GraphQLTypes["order_by"],
	total?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_votes_count */
["voyce_series_votes_count_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_votes_count" */
["voyce_series_votes_count_select_column"]: voyce_series_votes_count_select_column;
	/** input type for updating data in table "voyce.series_votes_count" */
["voyce_series_votes_count_set_input"]: {
		count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	date?: GraphQLTypes["date"],
	id?: number,
	series_id?: number,
	tag?: string,
	total?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_series_votes_count_stddev_fields"]: {
	__typename: "voyce_series_votes_count_stddev_fields",
	count?: number,
	id?: number,
	series_id?: number,
	total?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_votes_count_stddev_pop_fields"]: {
	__typename: "voyce_series_votes_count_stddev_pop_fields",
	count?: number,
	id?: number,
	series_id?: number,
	total?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_votes_count_stddev_samp_fields"]: {
	__typename: "voyce_series_votes_count_stddev_samp_fields",
	count?: number,
	id?: number,
	series_id?: number,
	total?: number
};
	/** Streaming cursor of the table "voyce_series_votes_count" */
["voyce_series_votes_count_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_votes_count_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_votes_count_stream_cursor_value_input"]: {
		count?: number,
	created_at?: GraphQLTypes["timestamptz"],
	date?: GraphQLTypes["date"],
	id?: number,
	series_id?: number,
	tag?: string,
	total?: number,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_series_votes_count_sum_fields"]: {
	__typename: "voyce_series_votes_count_sum_fields",
	count?: number,
	id?: number,
	series_id?: number,
	total?: number
};
	/** update columns of table "voyce.series_votes_count" */
["voyce_series_votes_count_update_column"]: voyce_series_votes_count_update_column;
	["voyce_series_votes_count_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_votes_count_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_votes_count_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_votes_count_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_votes_count_var_pop_fields"]: {
	__typename: "voyce_series_votes_count_var_pop_fields",
	count?: number,
	id?: number,
	series_id?: number,
	total?: number
};
	/** aggregate var_samp on columns */
["voyce_series_votes_count_var_samp_fields"]: {
	__typename: "voyce_series_votes_count_var_samp_fields",
	count?: number,
	id?: number,
	series_id?: number,
	total?: number
};
	/** aggregate variance on columns */
["voyce_series_votes_count_variance_fields"]: {
	__typename: "voyce_series_votes_count_variance_fields",
	count?: number,
	id?: number,
	series_id?: number,
	total?: number
};
	/** input type for incrementing numeric columns in table "voyce.series_votes" */
["voyce_series_votes_inc_input"]: {
		id?: number,
	series_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.series_votes" */
["voyce_series_votes_insert_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	date?: GraphQLTypes["date"],
	gtag_id?: string,
	id?: number,
	series_id?: number,
	tag?: string,
	user_id?: number
};
	/** aggregate max on columns */
["voyce_series_votes_max_fields"]: {
	__typename: "voyce_series_votes_max_fields",
	created_at?: GraphQLTypes["timestamp"],
	date?: GraphQLTypes["date"],
	gtag_id?: string,
	id?: number,
	series_id?: number,
	tag?: string,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_series_votes_min_fields"]: {
	__typename: "voyce_series_votes_min_fields",
	created_at?: GraphQLTypes["timestamp"],
	date?: GraphQLTypes["date"],
	gtag_id?: string,
	id?: number,
	series_id?: number,
	tag?: string,
	user_id?: number
};
	/** response of any mutation on the table "voyce.series_votes" */
["voyce_series_votes_mutation_response"]: {
	__typename: "voyce_series_votes_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_votes"]>
};
	/** on_conflict condition type for table "voyce.series_votes" */
["voyce_series_votes_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_votes_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_votes_update_column"]>,
	where?: GraphQLTypes["voyce_series_votes_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_votes". */
["voyce_series_votes_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	date?: GraphQLTypes["order_by"],
	gtag_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	tag?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_votes */
["voyce_series_votes_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_votes" */
["voyce_series_votes_select_column"]: voyce_series_votes_select_column;
	/** input type for updating data in table "voyce.series_votes" */
["voyce_series_votes_set_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	date?: GraphQLTypes["date"],
	gtag_id?: string,
	id?: number,
	series_id?: number,
	tag?: string,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_series_votes_stddev_fields"]: {
	__typename: "voyce_series_votes_stddev_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_votes_stddev_pop_fields"]: {
	__typename: "voyce_series_votes_stddev_pop_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_votes_stddev_samp_fields"]: {
	__typename: "voyce_series_votes_stddev_samp_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_series_votes" */
["voyce_series_votes_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_votes_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_votes_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	date?: GraphQLTypes["date"],
	gtag_id?: string,
	id?: number,
	series_id?: number,
	tag?: string,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_series_votes_sum_fields"]: {
	__typename: "voyce_series_votes_sum_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** update columns of table "voyce.series_votes" */
["voyce_series_votes_update_column"]: voyce_series_votes_update_column;
	["voyce_series_votes_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_votes_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_votes_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_votes_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_votes_var_pop_fields"]: {
	__typename: "voyce_series_votes_var_pop_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_votes_var_samp_fields"]: {
	__typename: "voyce_series_votes_var_samp_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_series_votes_variance_fields"]: {
	__typename: "voyce_series_votes_variance_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.series_voting_schedules" */
["voyce_series_voting_schedules"]: {
	__typename: "voyce_series_voting_schedules",
	created_at: GraphQLTypes["timestamptz"],
	desc: string,
	end_date: GraphQLTypes["timestamptz"],
	id: number,
	start_date: GraphQLTypes["timestamptz"],
	tag: string,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_aggregate"]: {
	__typename: "voyce_series_voting_schedules_aggregate",
	aggregate?: GraphQLTypes["voyce_series_voting_schedules_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_series_voting_schedules"]>
};
	/** aggregate fields of "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_aggregate_fields"]: {
	__typename: "voyce_series_voting_schedules_aggregate_fields",
	avg?: GraphQLTypes["voyce_series_voting_schedules_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_series_voting_schedules_max_fields"],
	min?: GraphQLTypes["voyce_series_voting_schedules_min_fields"],
	stddev?: GraphQLTypes["voyce_series_voting_schedules_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_series_voting_schedules_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_series_voting_schedules_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_series_voting_schedules_sum_fields"],
	var_pop?: GraphQLTypes["voyce_series_voting_schedules_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_series_voting_schedules_var_samp_fields"],
	variance?: GraphQLTypes["voyce_series_voting_schedules_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_series_voting_schedules_avg_fields"]: {
	__typename: "voyce_series_voting_schedules_avg_fields",
	id?: number
};
	/** Boolean expression to filter rows from the table "voyce.series_voting_schedules". All fields are combined with a logical 'AND'. */
["voyce_series_voting_schedules_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_series_voting_schedules_bool_exp"]>,
	_not?: GraphQLTypes["voyce_series_voting_schedules_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_series_voting_schedules_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	desc?: GraphQLTypes["String_comparison_exp"],
	end_date?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	start_date?: GraphQLTypes["timestamptz_comparison_exp"],
	tag?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_constraint"]: voyce_series_voting_schedules_constraint;
	/** input type for incrementing numeric columns in table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_inc_input"]: {
		id?: number
};
	/** input type for inserting data into table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	desc?: string,
	end_date?: GraphQLTypes["timestamptz"],
	id?: number,
	start_date?: GraphQLTypes["timestamptz"],
	tag?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_series_voting_schedules_max_fields"]: {
	__typename: "voyce_series_voting_schedules_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	desc?: string,
	end_date?: GraphQLTypes["timestamptz"],
	id?: number,
	start_date?: GraphQLTypes["timestamptz"],
	tag?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_series_voting_schedules_min_fields"]: {
	__typename: "voyce_series_voting_schedules_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	desc?: string,
	end_date?: GraphQLTypes["timestamptz"],
	id?: number,
	start_date?: GraphQLTypes["timestamptz"],
	tag?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_mutation_response"]: {
	__typename: "voyce_series_voting_schedules_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_series_voting_schedules"]>
};
	/** on_conflict condition type for table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_on_conflict"]: {
		constraint: GraphQLTypes["voyce_series_voting_schedules_constraint"],
	update_columns: Array<GraphQLTypes["voyce_series_voting_schedules_update_column"]>,
	where?: GraphQLTypes["voyce_series_voting_schedules_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.series_voting_schedules". */
["voyce_series_voting_schedules_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	desc?: GraphQLTypes["order_by"],
	end_date?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	start_date?: GraphQLTypes["order_by"],
	tag?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.series_voting_schedules */
["voyce_series_voting_schedules_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_select_column"]: voyce_series_voting_schedules_select_column;
	/** input type for updating data in table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	desc?: string,
	end_date?: GraphQLTypes["timestamptz"],
	id?: number,
	start_date?: GraphQLTypes["timestamptz"],
	tag?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_series_voting_schedules_stddev_fields"]: {
	__typename: "voyce_series_voting_schedules_stddev_fields",
	id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_series_voting_schedules_stddev_pop_fields"]: {
	__typename: "voyce_series_voting_schedules_stddev_pop_fields",
	id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_series_voting_schedules_stddev_samp_fields"]: {
	__typename: "voyce_series_voting_schedules_stddev_samp_fields",
	id?: number
};
	/** Streaming cursor of the table "voyce_series_voting_schedules" */
["voyce_series_voting_schedules_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_series_voting_schedules_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_series_voting_schedules_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	desc?: string,
	end_date?: GraphQLTypes["timestamptz"],
	id?: number,
	start_date?: GraphQLTypes["timestamptz"],
	tag?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_series_voting_schedules_sum_fields"]: {
	__typename: "voyce_series_voting_schedules_sum_fields",
	id?: number
};
	/** update columns of table "voyce.series_voting_schedules" */
["voyce_series_voting_schedules_update_column"]: voyce_series_voting_schedules_update_column;
	["voyce_series_voting_schedules_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_series_voting_schedules_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_series_voting_schedules_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_series_voting_schedules_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_series_voting_schedules_var_pop_fields"]: {
	__typename: "voyce_series_voting_schedules_var_pop_fields",
	id?: number
};
	/** aggregate var_samp on columns */
["voyce_series_voting_schedules_var_samp_fields"]: {
	__typename: "voyce_series_voting_schedules_var_samp_fields",
	id?: number
};
	/** aggregate variance on columns */
["voyce_series_voting_schedules_variance_fields"]: {
	__typename: "voyce_series_voting_schedules_variance_fields",
	id?: number
};
	/** columns and relationships of "voyce.sponsorships" */
["voyce_sponsorships"]: {
	__typename: "voyce_sponsorships",
	amount: number,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	message?: string,
	/** An object relationship */
	series: GraphQLTypes["voyce_series"],
	series_id: number,
	/** An object relationship */
	sponsored_user: GraphQLTypes["voyce_users"],
	sponsored_user_id: number,
	/** An object relationship */
	sponsoring_user: GraphQLTypes["voyce_users"],
	sponsoring_user_id: number,
	stripe_subscription?: string,
	updated_at: GraphQLTypes["timestamptz"]
};
	/** aggregated selection of "voyce.sponsorships" */
["voyce_sponsorships_aggregate"]: {
	__typename: "voyce_sponsorships_aggregate",
	aggregate?: GraphQLTypes["voyce_sponsorships_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_sponsorships"]>
};
	["voyce_sponsorships_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_sponsorships_aggregate_bool_exp_count"]
};
	["voyce_sponsorships_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_sponsorships_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_sponsorships_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.sponsorships" */
["voyce_sponsorships_aggregate_fields"]: {
	__typename: "voyce_sponsorships_aggregate_fields",
	avg?: GraphQLTypes["voyce_sponsorships_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_sponsorships_max_fields"],
	min?: GraphQLTypes["voyce_sponsorships_min_fields"],
	stddev?: GraphQLTypes["voyce_sponsorships_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_sponsorships_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_sponsorships_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_sponsorships_sum_fields"],
	var_pop?: GraphQLTypes["voyce_sponsorships_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_sponsorships_var_samp_fields"],
	variance?: GraphQLTypes["voyce_sponsorships_variance_fields"]
};
	/** order by aggregate values of table "voyce.sponsorships" */
["voyce_sponsorships_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_sponsorships_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_sponsorships_max_order_by"],
	min?: GraphQLTypes["voyce_sponsorships_min_order_by"],
	stddev?: GraphQLTypes["voyce_sponsorships_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_sponsorships_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_sponsorships_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_sponsorships_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_sponsorships_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_sponsorships_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_sponsorships_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.sponsorships" */
["voyce_sponsorships_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_sponsorships_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_sponsorships_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_sponsorships_avg_fields"]: {
	__typename: "voyce_sponsorships_avg_fields",
	amount?: number,
	id?: number,
	series_id?: number,
	sponsored_user_id?: number,
	sponsoring_user_id?: number
};
	/** order by avg() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_avg_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	sponsored_user_id?: GraphQLTypes["order_by"],
	sponsoring_user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.sponsorships". All fields are combined with a logical 'AND'. */
["voyce_sponsorships_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_sponsorships_bool_exp"]>,
	_not?: GraphQLTypes["voyce_sponsorships_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_sponsorships_bool_exp"]>,
	amount?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	message?: GraphQLTypes["String_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	sponsored_user?: GraphQLTypes["voyce_users_bool_exp"],
	sponsored_user_id?: GraphQLTypes["Int_comparison_exp"],
	sponsoring_user?: GraphQLTypes["voyce_users_bool_exp"],
	sponsoring_user_id?: GraphQLTypes["Int_comparison_exp"],
	stripe_subscription?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.sponsorships" */
["voyce_sponsorships_constraint"]: voyce_sponsorships_constraint;
	/** input type for incrementing numeric columns in table "voyce.sponsorships" */
["voyce_sponsorships_inc_input"]: {
		amount?: number,
	id?: number,
	series_id?: number,
	sponsored_user_id?: number,
	sponsoring_user_id?: number
};
	/** input type for inserting data into table "voyce.sponsorships" */
["voyce_sponsorships_insert_input"]: {
		amount?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	message?: string,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	sponsored_user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	sponsored_user_id?: number,
	sponsoring_user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	sponsoring_user_id?: number,
	stripe_subscription?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate max on columns */
["voyce_sponsorships_max_fields"]: {
	__typename: "voyce_sponsorships_max_fields",
	amount?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	message?: string,
	series_id?: number,
	sponsored_user_id?: number,
	sponsoring_user_id?: number,
	stripe_subscription?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** order by max() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_max_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	message?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	sponsored_user_id?: GraphQLTypes["order_by"],
	sponsoring_user_id?: GraphQLTypes["order_by"],
	stripe_subscription?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_sponsorships_min_fields"]: {
	__typename: "voyce_sponsorships_min_fields",
	amount?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	message?: string,
	series_id?: number,
	sponsored_user_id?: number,
	sponsoring_user_id?: number,
	stripe_subscription?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** order by min() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_min_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	message?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	sponsored_user_id?: GraphQLTypes["order_by"],
	sponsoring_user_id?: GraphQLTypes["order_by"],
	stripe_subscription?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.sponsorships" */
["voyce_sponsorships_mutation_response"]: {
	__typename: "voyce_sponsorships_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_sponsorships"]>
};
	/** on_conflict condition type for table "voyce.sponsorships" */
["voyce_sponsorships_on_conflict"]: {
		constraint: GraphQLTypes["voyce_sponsorships_constraint"],
	update_columns: Array<GraphQLTypes["voyce_sponsorships_update_column"]>,
	where?: GraphQLTypes["voyce_sponsorships_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.sponsorships". */
["voyce_sponsorships_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	message?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	sponsored_user?: GraphQLTypes["voyce_users_order_by"],
	sponsored_user_id?: GraphQLTypes["order_by"],
	sponsoring_user?: GraphQLTypes["voyce_users_order_by"],
	sponsoring_user_id?: GraphQLTypes["order_by"],
	stripe_subscription?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.sponsorships */
["voyce_sponsorships_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.sponsorships" */
["voyce_sponsorships_select_column"]: voyce_sponsorships_select_column;
	/** input type for updating data in table "voyce.sponsorships" */
["voyce_sponsorships_set_input"]: {
		amount?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	message?: string,
	series_id?: number,
	sponsored_user_id?: number,
	sponsoring_user_id?: number,
	stripe_subscription?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_sponsorships_stddev_fields"]: {
	__typename: "voyce_sponsorships_stddev_fields",
	amount?: number,
	id?: number,
	series_id?: number,
	sponsored_user_id?: number,
	sponsoring_user_id?: number
};
	/** order by stddev() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_stddev_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	sponsored_user_id?: GraphQLTypes["order_by"],
	sponsoring_user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_sponsorships_stddev_pop_fields"]: {
	__typename: "voyce_sponsorships_stddev_pop_fields",
	amount?: number,
	id?: number,
	series_id?: number,
	sponsored_user_id?: number,
	sponsoring_user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_stddev_pop_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	sponsored_user_id?: GraphQLTypes["order_by"],
	sponsoring_user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_sponsorships_stddev_samp_fields"]: {
	__typename: "voyce_sponsorships_stddev_samp_fields",
	amount?: number,
	id?: number,
	series_id?: number,
	sponsored_user_id?: number,
	sponsoring_user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_stddev_samp_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	sponsored_user_id?: GraphQLTypes["order_by"],
	sponsoring_user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_sponsorships" */
["voyce_sponsorships_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_sponsorships_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_sponsorships_stream_cursor_value_input"]: {
		amount?: number,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	message?: string,
	series_id?: number,
	sponsored_user_id?: number,
	sponsoring_user_id?: number,
	stripe_subscription?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_sponsorships_sum_fields"]: {
	__typename: "voyce_sponsorships_sum_fields",
	amount?: number,
	id?: number,
	series_id?: number,
	sponsored_user_id?: number,
	sponsoring_user_id?: number
};
	/** order by sum() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_sum_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	sponsored_user_id?: GraphQLTypes["order_by"],
	sponsoring_user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.sponsorships" */
["voyce_sponsorships_update_column"]: voyce_sponsorships_update_column;
	["voyce_sponsorships_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_sponsorships_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_sponsorships_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_sponsorships_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_sponsorships_var_pop_fields"]: {
	__typename: "voyce_sponsorships_var_pop_fields",
	amount?: number,
	id?: number,
	series_id?: number,
	sponsored_user_id?: number,
	sponsoring_user_id?: number
};
	/** order by var_pop() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_var_pop_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	sponsored_user_id?: GraphQLTypes["order_by"],
	sponsoring_user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_sponsorships_var_samp_fields"]: {
	__typename: "voyce_sponsorships_var_samp_fields",
	amount?: number,
	id?: number,
	series_id?: number,
	sponsored_user_id?: number,
	sponsoring_user_id?: number
};
	/** order by var_samp() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_var_samp_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	sponsored_user_id?: GraphQLTypes["order_by"],
	sponsoring_user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_sponsorships_variance_fields"]: {
	__typename: "voyce_sponsorships_variance_fields",
	amount?: number,
	id?: number,
	series_id?: number,
	sponsored_user_id?: number,
	sponsoring_user_id?: number
};
	/** order by variance() on columns of table "voyce.sponsorships" */
["voyce_sponsorships_variance_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	sponsored_user_id?: GraphQLTypes["order_by"],
	sponsoring_user_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.states" */
["voyce_states"]: {
	__typename: "voyce_states",
	country_id: number,
	date_create: GraphQLTypes["timestamp"],
	description?: string,
	feature: GraphQLTypes["smallint"],
	id: number,
	name: string,
	publish: GraphQLTypes["smallint"]
};
	/** aggregated selection of "voyce.states" */
["voyce_states_aggregate"]: {
	__typename: "voyce_states_aggregate",
	aggregate?: GraphQLTypes["voyce_states_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_states"]>
};
	/** aggregate fields of "voyce.states" */
["voyce_states_aggregate_fields"]: {
	__typename: "voyce_states_aggregate_fields",
	avg?: GraphQLTypes["voyce_states_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_states_max_fields"],
	min?: GraphQLTypes["voyce_states_min_fields"],
	stddev?: GraphQLTypes["voyce_states_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_states_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_states_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_states_sum_fields"],
	var_pop?: GraphQLTypes["voyce_states_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_states_var_samp_fields"],
	variance?: GraphQLTypes["voyce_states_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_states_avg_fields"]: {
	__typename: "voyce_states_avg_fields",
	country_id?: number,
	feature?: number,
	id?: number,
	publish?: number
};
	/** Boolean expression to filter rows from the table "voyce.states". All fields are combined with a logical 'AND'. */
["voyce_states_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_states_bool_exp"]>,
	_not?: GraphQLTypes["voyce_states_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_states_bool_exp"]>,
	country_id?: GraphQLTypes["Int_comparison_exp"],
	date_create?: GraphQLTypes["timestamp_comparison_exp"],
	description?: GraphQLTypes["String_comparison_exp"],
	feature?: GraphQLTypes["smallint_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	publish?: GraphQLTypes["smallint_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.states" */
["voyce_states_constraint"]: voyce_states_constraint;
	/** input type for incrementing numeric columns in table "voyce.states" */
["voyce_states_inc_input"]: {
		country_id?: number,
	feature?: GraphQLTypes["smallint"],
	id?: number,
	publish?: GraphQLTypes["smallint"]
};
	/** input type for inserting data into table "voyce.states" */
["voyce_states_insert_input"]: {
		country_id?: number,
	date_create?: GraphQLTypes["timestamp"],
	description?: string,
	feature?: GraphQLTypes["smallint"],
	id?: number,
	name?: string,
	publish?: GraphQLTypes["smallint"]
};
	/** aggregate max on columns */
["voyce_states_max_fields"]: {
	__typename: "voyce_states_max_fields",
	country_id?: number,
	date_create?: GraphQLTypes["timestamp"],
	description?: string,
	feature?: GraphQLTypes["smallint"],
	id?: number,
	name?: string,
	publish?: GraphQLTypes["smallint"]
};
	/** aggregate min on columns */
["voyce_states_min_fields"]: {
	__typename: "voyce_states_min_fields",
	country_id?: number,
	date_create?: GraphQLTypes["timestamp"],
	description?: string,
	feature?: GraphQLTypes["smallint"],
	id?: number,
	name?: string,
	publish?: GraphQLTypes["smallint"]
};
	/** response of any mutation on the table "voyce.states" */
["voyce_states_mutation_response"]: {
	__typename: "voyce_states_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_states"]>
};
	/** on_conflict condition type for table "voyce.states" */
["voyce_states_on_conflict"]: {
		constraint: GraphQLTypes["voyce_states_constraint"],
	update_columns: Array<GraphQLTypes["voyce_states_update_column"]>,
	where?: GraphQLTypes["voyce_states_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.states". */
["voyce_states_order_by"]: {
		country_id?: GraphQLTypes["order_by"],
	date_create?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	feature?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	publish?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.states */
["voyce_states_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.states" */
["voyce_states_select_column"]: voyce_states_select_column;
	/** input type for updating data in table "voyce.states" */
["voyce_states_set_input"]: {
		country_id?: number,
	date_create?: GraphQLTypes["timestamp"],
	description?: string,
	feature?: GraphQLTypes["smallint"],
	id?: number,
	name?: string,
	publish?: GraphQLTypes["smallint"]
};
	/** aggregate stddev on columns */
["voyce_states_stddev_fields"]: {
	__typename: "voyce_states_stddev_fields",
	country_id?: number,
	feature?: number,
	id?: number,
	publish?: number
};
	/** aggregate stddev_pop on columns */
["voyce_states_stddev_pop_fields"]: {
	__typename: "voyce_states_stddev_pop_fields",
	country_id?: number,
	feature?: number,
	id?: number,
	publish?: number
};
	/** aggregate stddev_samp on columns */
["voyce_states_stddev_samp_fields"]: {
	__typename: "voyce_states_stddev_samp_fields",
	country_id?: number,
	feature?: number,
	id?: number,
	publish?: number
};
	/** Streaming cursor of the table "voyce_states" */
["voyce_states_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_states_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_states_stream_cursor_value_input"]: {
		country_id?: number,
	date_create?: GraphQLTypes["timestamp"],
	description?: string,
	feature?: GraphQLTypes["smallint"],
	id?: number,
	name?: string,
	publish?: GraphQLTypes["smallint"]
};
	/** aggregate sum on columns */
["voyce_states_sum_fields"]: {
	__typename: "voyce_states_sum_fields",
	country_id?: number,
	feature?: GraphQLTypes["smallint"],
	id?: number,
	publish?: GraphQLTypes["smallint"]
};
	/** update columns of table "voyce.states" */
["voyce_states_update_column"]: voyce_states_update_column;
	["voyce_states_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_states_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_states_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_states_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_states_var_pop_fields"]: {
	__typename: "voyce_states_var_pop_fields",
	country_id?: number,
	feature?: number,
	id?: number,
	publish?: number
};
	/** aggregate var_samp on columns */
["voyce_states_var_samp_fields"]: {
	__typename: "voyce_states_var_samp_fields",
	country_id?: number,
	feature?: number,
	id?: number,
	publish?: number
};
	/** aggregate variance on columns */
["voyce_states_variance_fields"]: {
	__typename: "voyce_states_variance_fields",
	country_id?: number,
	feature?: number,
	id?: number,
	publish?: number
};
	/** columns and relationships of "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup"]: {
	__typename: "voyce_stripe_payouts_setup",
	completed_stripe_onboarding: boolean,
	cut: number,
	id: number,
	stripe_account_id?: string,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_aggregate"]: {
	__typename: "voyce_stripe_payouts_setup_aggregate",
	aggregate?: GraphQLTypes["voyce_stripe_payouts_setup_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_stripe_payouts_setup"]>
};
	/** aggregate fields of "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_aggregate_fields"]: {
	__typename: "voyce_stripe_payouts_setup_aggregate_fields",
	avg?: GraphQLTypes["voyce_stripe_payouts_setup_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_stripe_payouts_setup_max_fields"],
	min?: GraphQLTypes["voyce_stripe_payouts_setup_min_fields"],
	stddev?: GraphQLTypes["voyce_stripe_payouts_setup_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_stripe_payouts_setup_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_stripe_payouts_setup_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_stripe_payouts_setup_sum_fields"],
	var_pop?: GraphQLTypes["voyce_stripe_payouts_setup_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_stripe_payouts_setup_var_samp_fields"],
	variance?: GraphQLTypes["voyce_stripe_payouts_setup_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_stripe_payouts_setup_avg_fields"]: {
	__typename: "voyce_stripe_payouts_setup_avg_fields",
	cut?: number,
	id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.stripe_payouts_setup". All fields are combined with a logical 'AND'. */
["voyce_stripe_payouts_setup_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_stripe_payouts_setup_bool_exp"]>,
	_not?: GraphQLTypes["voyce_stripe_payouts_setup_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_stripe_payouts_setup_bool_exp"]>,
	completed_stripe_onboarding?: GraphQLTypes["Boolean_comparison_exp"],
	cut?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	stripe_account_id?: GraphQLTypes["String_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_constraint"]: voyce_stripe_payouts_setup_constraint;
	/** input type for incrementing numeric columns in table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_inc_input"]: {
		cut?: number,
	id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_insert_input"]: {
		completed_stripe_onboarding?: boolean,
	cut?: number,
	id?: number,
	stripe_account_id?: string,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_stripe_payouts_setup_max_fields"]: {
	__typename: "voyce_stripe_payouts_setup_max_fields",
	cut?: number,
	id?: number,
	stripe_account_id?: string,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_stripe_payouts_setup_min_fields"]: {
	__typename: "voyce_stripe_payouts_setup_min_fields",
	cut?: number,
	id?: number,
	stripe_account_id?: string,
	user_id?: number
};
	/** response of any mutation on the table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_mutation_response"]: {
	__typename: "voyce_stripe_payouts_setup_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_stripe_payouts_setup"]>
};
	/** input type for inserting object relation for remote table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_stripe_payouts_setup_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_stripe_payouts_setup_on_conflict"]
};
	/** on_conflict condition type for table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_on_conflict"]: {
		constraint: GraphQLTypes["voyce_stripe_payouts_setup_constraint"],
	update_columns: Array<GraphQLTypes["voyce_stripe_payouts_setup_update_column"]>,
	where?: GraphQLTypes["voyce_stripe_payouts_setup_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.stripe_payouts_setup". */
["voyce_stripe_payouts_setup_order_by"]: {
		completed_stripe_onboarding?: GraphQLTypes["order_by"],
	cut?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	stripe_account_id?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.stripe_payouts_setup */
["voyce_stripe_payouts_setup_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_select_column"]: voyce_stripe_payouts_setup_select_column;
	/** input type for updating data in table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_set_input"]: {
		completed_stripe_onboarding?: boolean,
	cut?: number,
	id?: number,
	stripe_account_id?: string,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_stripe_payouts_setup_stddev_fields"]: {
	__typename: "voyce_stripe_payouts_setup_stddev_fields",
	cut?: number,
	id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_stripe_payouts_setup_stddev_pop_fields"]: {
	__typename: "voyce_stripe_payouts_setup_stddev_pop_fields",
	cut?: number,
	id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_stripe_payouts_setup_stddev_samp_fields"]: {
	__typename: "voyce_stripe_payouts_setup_stddev_samp_fields",
	cut?: number,
	id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_stripe_payouts_setup" */
["voyce_stripe_payouts_setup_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_stripe_payouts_setup_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_stripe_payouts_setup_stream_cursor_value_input"]: {
		completed_stripe_onboarding?: boolean,
	cut?: number,
	id?: number,
	stripe_account_id?: string,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_stripe_payouts_setup_sum_fields"]: {
	__typename: "voyce_stripe_payouts_setup_sum_fields",
	cut?: number,
	id?: number,
	user_id?: number
};
	/** update columns of table "voyce.stripe_payouts_setup" */
["voyce_stripe_payouts_setup_update_column"]: voyce_stripe_payouts_setup_update_column;
	["voyce_stripe_payouts_setup_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_stripe_payouts_setup_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_stripe_payouts_setup_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_stripe_payouts_setup_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_stripe_payouts_setup_var_pop_fields"]: {
	__typename: "voyce_stripe_payouts_setup_var_pop_fields",
	cut?: number,
	id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_stripe_payouts_setup_var_samp_fields"]: {
	__typename: "voyce_stripe_payouts_setup_var_samp_fields",
	cut?: number,
	id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_stripe_payouts_setup_variance_fields"]: {
	__typename: "voyce_stripe_payouts_setup_variance_fields",
	cut?: number,
	id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.studio" */
["voyce_studio"]: {
	__typename: "voyce_studio",
	active: boolean,
	avatar: string,
	created_at: GraphQLTypes["timestamptz"],
	description: string,
	discord?: string,
	facebook?: string,
	/** An array relationship */
	followers: Array<GraphQLTypes["voyce_studio_followers"]>,
	/** An aggregate relationship */
	followers_aggregate: GraphQLTypes["voyce_studio_followers_aggregate"],
	id: number,
	instagram?: string,
	layout: GraphQLTypes["smallint"],
	logo: string,
	order: number,
	props: GraphQLTypes["json"],
	/** An array relationship */
	series: Array<GraphQLTypes["voyce_studio_series"]>,
	/** An aggregate relationship */
	series_aggregate: GraphQLTypes["voyce_studio_series_aggregate"],
	slug?: string,
	tiktok?: string,
	title: string,
	twitter?: string,
	updated_at: GraphQLTypes["timestamptz"],
	/** An object relationship */
	views?: GraphQLTypes["voyce_studio_views"],
	website?: string
};
	/** aggregated selection of "voyce.studio" */
["voyce_studio_aggregate"]: {
	__typename: "voyce_studio_aggregate",
	aggregate?: GraphQLTypes["voyce_studio_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_studio"]>
};
	/** aggregate fields of "voyce.studio" */
["voyce_studio_aggregate_fields"]: {
	__typename: "voyce_studio_aggregate_fields",
	avg?: GraphQLTypes["voyce_studio_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_studio_max_fields"],
	min?: GraphQLTypes["voyce_studio_min_fields"],
	stddev?: GraphQLTypes["voyce_studio_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_studio_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_studio_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_studio_sum_fields"],
	var_pop?: GraphQLTypes["voyce_studio_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_studio_var_samp_fields"],
	variance?: GraphQLTypes["voyce_studio_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_studio_avg_fields"]: {
	__typename: "voyce_studio_avg_fields",
	id?: number,
	layout?: number,
	order?: number
};
	/** Boolean expression to filter rows from the table "voyce.studio". All fields are combined with a logical 'AND'. */
["voyce_studio_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_studio_bool_exp"]>,
	_not?: GraphQLTypes["voyce_studio_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_studio_bool_exp"]>,
	active?: GraphQLTypes["Boolean_comparison_exp"],
	avatar?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	description?: GraphQLTypes["String_comparison_exp"],
	discord?: GraphQLTypes["String_comparison_exp"],
	facebook?: GraphQLTypes["String_comparison_exp"],
	followers?: GraphQLTypes["voyce_studio_followers_bool_exp"],
	followers_aggregate?: GraphQLTypes["voyce_studio_followers_aggregate_bool_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	instagram?: GraphQLTypes["String_comparison_exp"],
	layout?: GraphQLTypes["smallint_comparison_exp"],
	logo?: GraphQLTypes["String_comparison_exp"],
	order?: GraphQLTypes["Int_comparison_exp"],
	props?: GraphQLTypes["json_comparison_exp"],
	series?: GraphQLTypes["voyce_studio_series_bool_exp"],
	series_aggregate?: GraphQLTypes["voyce_studio_series_aggregate_bool_exp"],
	slug?: GraphQLTypes["String_comparison_exp"],
	tiktok?: GraphQLTypes["String_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	twitter?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	views?: GraphQLTypes["voyce_studio_views_bool_exp"],
	website?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.studio" */
["voyce_studio_constraint"]: voyce_studio_constraint;
	/** columns and relationships of "voyce.studio_followers" */
["voyce_studio_followers"]: {
	__typename: "voyce_studio_followers",
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	studio_id: number,
	user_id: number
};
	/** aggregated selection of "voyce.studio_followers" */
["voyce_studio_followers_aggregate"]: {
	__typename: "voyce_studio_followers_aggregate",
	aggregate?: GraphQLTypes["voyce_studio_followers_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_studio_followers"]>
};
	["voyce_studio_followers_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_studio_followers_aggregate_bool_exp_count"]
};
	["voyce_studio_followers_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_studio_followers_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_studio_followers_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.studio_followers" */
["voyce_studio_followers_aggregate_fields"]: {
	__typename: "voyce_studio_followers_aggregate_fields",
	avg?: GraphQLTypes["voyce_studio_followers_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_studio_followers_max_fields"],
	min?: GraphQLTypes["voyce_studio_followers_min_fields"],
	stddev?: GraphQLTypes["voyce_studio_followers_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_studio_followers_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_studio_followers_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_studio_followers_sum_fields"],
	var_pop?: GraphQLTypes["voyce_studio_followers_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_studio_followers_var_samp_fields"],
	variance?: GraphQLTypes["voyce_studio_followers_variance_fields"]
};
	/** order by aggregate values of table "voyce.studio_followers" */
["voyce_studio_followers_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_studio_followers_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_studio_followers_max_order_by"],
	min?: GraphQLTypes["voyce_studio_followers_min_order_by"],
	stddev?: GraphQLTypes["voyce_studio_followers_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_studio_followers_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_studio_followers_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_studio_followers_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_studio_followers_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_studio_followers_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_studio_followers_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.studio_followers" */
["voyce_studio_followers_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_studio_followers_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_studio_followers_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_studio_followers_avg_fields"]: {
	__typename: "voyce_studio_followers_avg_fields",
	id?: number,
	studio_id?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.studio_followers". All fields are combined with a logical 'AND'. */
["voyce_studio_followers_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_studio_followers_bool_exp"]>,
	_not?: GraphQLTypes["voyce_studio_followers_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_studio_followers_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	studio_id?: GraphQLTypes["Int_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.studio_followers" */
["voyce_studio_followers_constraint"]: voyce_studio_followers_constraint;
	/** input type for incrementing numeric columns in table "voyce.studio_followers" */
["voyce_studio_followers_inc_input"]: {
		id?: number,
	studio_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.studio_followers" */
["voyce_studio_followers_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	studio_id?: number,
	user_id?: number
};
	/** aggregate max on columns */
["voyce_studio_followers_max_fields"]: {
	__typename: "voyce_studio_followers_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	studio_id?: number,
	user_id?: number
};
	/** order by max() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_max_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_studio_followers_min_fields"]: {
	__typename: "voyce_studio_followers_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	studio_id?: number,
	user_id?: number
};
	/** order by min() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_min_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.studio_followers" */
["voyce_studio_followers_mutation_response"]: {
	__typename: "voyce_studio_followers_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_studio_followers"]>
};
	/** on_conflict condition type for table "voyce.studio_followers" */
["voyce_studio_followers_on_conflict"]: {
		constraint: GraphQLTypes["voyce_studio_followers_constraint"],
	update_columns: Array<GraphQLTypes["voyce_studio_followers_update_column"]>,
	where?: GraphQLTypes["voyce_studio_followers_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.studio_followers". */
["voyce_studio_followers_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.studio_followers */
["voyce_studio_followers_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.studio_followers" */
["voyce_studio_followers_select_column"]: voyce_studio_followers_select_column;
	/** input type for updating data in table "voyce.studio_followers" */
["voyce_studio_followers_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	studio_id?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_studio_followers_stddev_fields"]: {
	__typename: "voyce_studio_followers_stddev_fields",
	id?: number,
	studio_id?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_studio_followers_stddev_pop_fields"]: {
	__typename: "voyce_studio_followers_stddev_pop_fields",
	id?: number,
	studio_id?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_studio_followers_stddev_samp_fields"]: {
	__typename: "voyce_studio_followers_stddev_samp_fields",
	id?: number,
	studio_id?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_studio_followers" */
["voyce_studio_followers_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_studio_followers_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_studio_followers_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	studio_id?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_studio_followers_sum_fields"]: {
	__typename: "voyce_studio_followers_sum_fields",
	id?: number,
	studio_id?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.studio_followers" */
["voyce_studio_followers_update_column"]: voyce_studio_followers_update_column;
	["voyce_studio_followers_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_studio_followers_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_studio_followers_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_studio_followers_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_studio_followers_var_pop_fields"]: {
	__typename: "voyce_studio_followers_var_pop_fields",
	id?: number,
	studio_id?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_studio_followers_var_samp_fields"]: {
	__typename: "voyce_studio_followers_var_samp_fields",
	id?: number,
	studio_id?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_studio_followers_variance_fields"]: {
	__typename: "voyce_studio_followers_variance_fields",
	id?: number,
	studio_id?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.studio_followers" */
["voyce_studio_followers_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** input type for incrementing numeric columns in table "voyce.studio" */
["voyce_studio_inc_input"]: {
		id?: number,
	layout?: GraphQLTypes["smallint"],
	order?: number
};
	/** input type for inserting data into table "voyce.studio" */
["voyce_studio_insert_input"]: {
		active?: boolean,
	avatar?: string,
	created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	discord?: string,
	facebook?: string,
	followers?: GraphQLTypes["voyce_studio_followers_arr_rel_insert_input"],
	id?: number,
	instagram?: string,
	layout?: GraphQLTypes["smallint"],
	logo?: string,
	order?: number,
	props?: GraphQLTypes["json"],
	series?: GraphQLTypes["voyce_studio_series_arr_rel_insert_input"],
	slug?: string,
	tiktok?: string,
	title?: string,
	twitter?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	views?: GraphQLTypes["voyce_studio_views_obj_rel_insert_input"],
	website?: string
};
	/** aggregate max on columns */
["voyce_studio_max_fields"]: {
	__typename: "voyce_studio_max_fields",
	avatar?: string,
	created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	discord?: string,
	facebook?: string,
	id?: number,
	instagram?: string,
	layout?: GraphQLTypes["smallint"],
	logo?: string,
	order?: number,
	slug?: string,
	tiktok?: string,
	title?: string,
	twitter?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	website?: string
};
	/** aggregate min on columns */
["voyce_studio_min_fields"]: {
	__typename: "voyce_studio_min_fields",
	avatar?: string,
	created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	discord?: string,
	facebook?: string,
	id?: number,
	instagram?: string,
	layout?: GraphQLTypes["smallint"],
	logo?: string,
	order?: number,
	slug?: string,
	tiktok?: string,
	title?: string,
	twitter?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	website?: string
};
	/** response of any mutation on the table "voyce.studio" */
["voyce_studio_mutation_response"]: {
	__typename: "voyce_studio_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_studio"]>
};
	/** on_conflict condition type for table "voyce.studio" */
["voyce_studio_on_conflict"]: {
		constraint: GraphQLTypes["voyce_studio_constraint"],
	update_columns: Array<GraphQLTypes["voyce_studio_update_column"]>,
	where?: GraphQLTypes["voyce_studio_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.studio". */
["voyce_studio_order_by"]: {
		active?: GraphQLTypes["order_by"],
	avatar?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	discord?: GraphQLTypes["order_by"],
	facebook?: GraphQLTypes["order_by"],
	followers_aggregate?: GraphQLTypes["voyce_studio_followers_aggregate_order_by"],
	id?: GraphQLTypes["order_by"],
	instagram?: GraphQLTypes["order_by"],
	layout?: GraphQLTypes["order_by"],
	logo?: GraphQLTypes["order_by"],
	order?: GraphQLTypes["order_by"],
	props?: GraphQLTypes["order_by"],
	series_aggregate?: GraphQLTypes["voyce_studio_series_aggregate_order_by"],
	slug?: GraphQLTypes["order_by"],
	tiktok?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	twitter?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	views?: GraphQLTypes["voyce_studio_views_order_by"],
	website?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.studio */
["voyce_studio_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.studio" */
["voyce_studio_select_column"]: voyce_studio_select_column;
	/** columns and relationships of "voyce.studio_series" */
["voyce_studio_series"]: {
	__typename: "voyce_studio_series",
	id: number,
	/** An object relationship */
	series?: GraphQLTypes["voyce_series"],
	series_id: number,
	/** An object relationship */
	series_views?: GraphQLTypes["voyce_pg_rs_series_views_count"],
	studio_id: number
};
	/** aggregated selection of "voyce.studio_series" */
["voyce_studio_series_aggregate"]: {
	__typename: "voyce_studio_series_aggregate",
	aggregate?: GraphQLTypes["voyce_studio_series_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_studio_series"]>
};
	["voyce_studio_series_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_studio_series_aggregate_bool_exp_count"]
};
	["voyce_studio_series_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_studio_series_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_studio_series_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.studio_series" */
["voyce_studio_series_aggregate_fields"]: {
	__typename: "voyce_studio_series_aggregate_fields",
	avg?: GraphQLTypes["voyce_studio_series_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_studio_series_max_fields"],
	min?: GraphQLTypes["voyce_studio_series_min_fields"],
	stddev?: GraphQLTypes["voyce_studio_series_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_studio_series_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_studio_series_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_studio_series_sum_fields"],
	var_pop?: GraphQLTypes["voyce_studio_series_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_studio_series_var_samp_fields"],
	variance?: GraphQLTypes["voyce_studio_series_variance_fields"]
};
	/** order by aggregate values of table "voyce.studio_series" */
["voyce_studio_series_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_studio_series_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_studio_series_max_order_by"],
	min?: GraphQLTypes["voyce_studio_series_min_order_by"],
	stddev?: GraphQLTypes["voyce_studio_series_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_studio_series_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_studio_series_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_studio_series_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_studio_series_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_studio_series_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_studio_series_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.studio_series" */
["voyce_studio_series_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_studio_series_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_studio_series_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_studio_series_avg_fields"]: {
	__typename: "voyce_studio_series_avg_fields",
	id?: number,
	series_id?: number,
	studio_id?: number
};
	/** order by avg() on columns of table "voyce.studio_series" */
["voyce_studio_series_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.studio_series". All fields are combined with a logical 'AND'. */
["voyce_studio_series_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_studio_series_bool_exp"]>,
	_not?: GraphQLTypes["voyce_studio_series_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_studio_series_bool_exp"]>,
	id?: GraphQLTypes["Int_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	series_views?: GraphQLTypes["voyce_pg_rs_series_views_count_bool_exp"],
	studio_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.studio_series" */
["voyce_studio_series_constraint"]: voyce_studio_series_constraint;
	/** input type for incrementing numeric columns in table "voyce.studio_series" */
["voyce_studio_series_inc_input"]: {
		id?: number,
	series_id?: number,
	studio_id?: number
};
	/** input type for inserting data into table "voyce.studio_series" */
["voyce_studio_series_insert_input"]: {
		id?: number,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	series_views?: GraphQLTypes["voyce_pg_rs_series_views_count_obj_rel_insert_input"],
	studio_id?: number
};
	/** aggregate max on columns */
["voyce_studio_series_max_fields"]: {
	__typename: "voyce_studio_series_max_fields",
	id?: number,
	series_id?: number,
	studio_id?: number
};
	/** order by max() on columns of table "voyce.studio_series" */
["voyce_studio_series_max_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_studio_series_min_fields"]: {
	__typename: "voyce_studio_series_min_fields",
	id?: number,
	series_id?: number,
	studio_id?: number
};
	/** order by min() on columns of table "voyce.studio_series" */
["voyce_studio_series_min_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.studio_series" */
["voyce_studio_series_mutation_response"]: {
	__typename: "voyce_studio_series_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_studio_series"]>
};
	/** on_conflict condition type for table "voyce.studio_series" */
["voyce_studio_series_on_conflict"]: {
		constraint: GraphQLTypes["voyce_studio_series_constraint"],
	update_columns: Array<GraphQLTypes["voyce_studio_series_update_column"]>,
	where?: GraphQLTypes["voyce_studio_series_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.studio_series". */
["voyce_studio_series_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	series_views?: GraphQLTypes["voyce_pg_rs_series_views_count_order_by"],
	studio_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.studio_series */
["voyce_studio_series_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.studio_series" */
["voyce_studio_series_select_column"]: voyce_studio_series_select_column;
	/** input type for updating data in table "voyce.studio_series" */
["voyce_studio_series_set_input"]: {
		id?: number,
	series_id?: number,
	studio_id?: number
};
	/** aggregate stddev on columns */
["voyce_studio_series_stddev_fields"]: {
	__typename: "voyce_studio_series_stddev_fields",
	id?: number,
	series_id?: number,
	studio_id?: number
};
	/** order by stddev() on columns of table "voyce.studio_series" */
["voyce_studio_series_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_studio_series_stddev_pop_fields"]: {
	__typename: "voyce_studio_series_stddev_pop_fields",
	id?: number,
	series_id?: number,
	studio_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.studio_series" */
["voyce_studio_series_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_studio_series_stddev_samp_fields"]: {
	__typename: "voyce_studio_series_stddev_samp_fields",
	id?: number,
	series_id?: number,
	studio_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.studio_series" */
["voyce_studio_series_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_studio_series" */
["voyce_studio_series_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_studio_series_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_studio_series_stream_cursor_value_input"]: {
		id?: number,
	series_id?: number,
	studio_id?: number
};
	/** aggregate sum on columns */
["voyce_studio_series_sum_fields"]: {
	__typename: "voyce_studio_series_sum_fields",
	id?: number,
	series_id?: number,
	studio_id?: number
};
	/** order by sum() on columns of table "voyce.studio_series" */
["voyce_studio_series_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.studio_series" */
["voyce_studio_series_update_column"]: voyce_studio_series_update_column;
	["voyce_studio_series_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_studio_series_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_studio_series_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_studio_series_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_studio_series_var_pop_fields"]: {
	__typename: "voyce_studio_series_var_pop_fields",
	id?: number,
	series_id?: number,
	studio_id?: number
};
	/** order by var_pop() on columns of table "voyce.studio_series" */
["voyce_studio_series_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_studio_series_var_samp_fields"]: {
	__typename: "voyce_studio_series_var_samp_fields",
	id?: number,
	series_id?: number,
	studio_id?: number
};
	/** order by var_samp() on columns of table "voyce.studio_series" */
["voyce_studio_series_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_studio_series_variance_fields"]: {
	__typename: "voyce_studio_series_variance_fields",
	id?: number,
	series_id?: number,
	studio_id?: number
};
	/** order by variance() on columns of table "voyce.studio_series" */
["voyce_studio_series_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"]
};
	/** input type for updating data in table "voyce.studio" */
["voyce_studio_set_input"]: {
		active?: boolean,
	avatar?: string,
	created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	discord?: string,
	facebook?: string,
	id?: number,
	instagram?: string,
	layout?: GraphQLTypes["smallint"],
	logo?: string,
	order?: number,
	props?: GraphQLTypes["json"],
	slug?: string,
	tiktok?: string,
	title?: string,
	twitter?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	website?: string
};
	/** aggregate stddev on columns */
["voyce_studio_stddev_fields"]: {
	__typename: "voyce_studio_stddev_fields",
	id?: number,
	layout?: number,
	order?: number
};
	/** aggregate stddev_pop on columns */
["voyce_studio_stddev_pop_fields"]: {
	__typename: "voyce_studio_stddev_pop_fields",
	id?: number,
	layout?: number,
	order?: number
};
	/** aggregate stddev_samp on columns */
["voyce_studio_stddev_samp_fields"]: {
	__typename: "voyce_studio_stddev_samp_fields",
	id?: number,
	layout?: number,
	order?: number
};
	/** Streaming cursor of the table "voyce_studio" */
["voyce_studio_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_studio_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_studio_stream_cursor_value_input"]: {
		active?: boolean,
	avatar?: string,
	created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	discord?: string,
	facebook?: string,
	id?: number,
	instagram?: string,
	layout?: GraphQLTypes["smallint"],
	logo?: string,
	order?: number,
	props?: GraphQLTypes["json"],
	slug?: string,
	tiktok?: string,
	title?: string,
	twitter?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	website?: string
};
	/** aggregate sum on columns */
["voyce_studio_sum_fields"]: {
	__typename: "voyce_studio_sum_fields",
	id?: number,
	layout?: GraphQLTypes["smallint"],
	order?: number
};
	/** update columns of table "voyce.studio" */
["voyce_studio_update_column"]: voyce_studio_update_column;
	["voyce_studio_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_studio_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_studio_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_studio_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_studio_var_pop_fields"]: {
	__typename: "voyce_studio_var_pop_fields",
	id?: number,
	layout?: number,
	order?: number
};
	/** aggregate var_samp on columns */
["voyce_studio_var_samp_fields"]: {
	__typename: "voyce_studio_var_samp_fields",
	id?: number,
	layout?: number,
	order?: number
};
	/** aggregate variance on columns */
["voyce_studio_variance_fields"]: {
	__typename: "voyce_studio_variance_fields",
	id?: number,
	layout?: number,
	order?: number
};
	/** columns and relationships of "voyce.studio_views" */
["voyce_studio_views"]: {
	__typename: "voyce_studio_views",
	count: number,
	id: number,
	studio_id: number
};
	/** aggregated selection of "voyce.studio_views" */
["voyce_studio_views_aggregate"]: {
	__typename: "voyce_studio_views_aggregate",
	aggregate?: GraphQLTypes["voyce_studio_views_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_studio_views"]>
};
	/** aggregate fields of "voyce.studio_views" */
["voyce_studio_views_aggregate_fields"]: {
	__typename: "voyce_studio_views_aggregate_fields",
	avg?: GraphQLTypes["voyce_studio_views_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_studio_views_max_fields"],
	min?: GraphQLTypes["voyce_studio_views_min_fields"],
	stddev?: GraphQLTypes["voyce_studio_views_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_studio_views_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_studio_views_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_studio_views_sum_fields"],
	var_pop?: GraphQLTypes["voyce_studio_views_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_studio_views_var_samp_fields"],
	variance?: GraphQLTypes["voyce_studio_views_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_studio_views_avg_fields"]: {
	__typename: "voyce_studio_views_avg_fields",
	count?: number,
	id?: number,
	studio_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.studio_views". All fields are combined with a logical 'AND'. */
["voyce_studio_views_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_studio_views_bool_exp"]>,
	_not?: GraphQLTypes["voyce_studio_views_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_studio_views_bool_exp"]>,
	count?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	studio_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.studio_views" */
["voyce_studio_views_constraint"]: voyce_studio_views_constraint;
	/** input type for incrementing numeric columns in table "voyce.studio_views" */
["voyce_studio_views_inc_input"]: {
		count?: number,
	id?: number,
	studio_id?: number
};
	/** input type for inserting data into table "voyce.studio_views" */
["voyce_studio_views_insert_input"]: {
		count?: number,
	id?: number,
	studio_id?: number
};
	/** aggregate max on columns */
["voyce_studio_views_max_fields"]: {
	__typename: "voyce_studio_views_max_fields",
	count?: number,
	id?: number,
	studio_id?: number
};
	/** aggregate min on columns */
["voyce_studio_views_min_fields"]: {
	__typename: "voyce_studio_views_min_fields",
	count?: number,
	id?: number,
	studio_id?: number
};
	/** response of any mutation on the table "voyce.studio_views" */
["voyce_studio_views_mutation_response"]: {
	__typename: "voyce_studio_views_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_studio_views"]>
};
	/** input type for inserting object relation for remote table "voyce.studio_views" */
["voyce_studio_views_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_studio_views_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_studio_views_on_conflict"]
};
	/** on_conflict condition type for table "voyce.studio_views" */
["voyce_studio_views_on_conflict"]: {
		constraint: GraphQLTypes["voyce_studio_views_constraint"],
	update_columns: Array<GraphQLTypes["voyce_studio_views_update_column"]>,
	where?: GraphQLTypes["voyce_studio_views_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.studio_views". */
["voyce_studio_views_order_by"]: {
		count?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	studio_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.studio_views */
["voyce_studio_views_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.studio_views" */
["voyce_studio_views_select_column"]: voyce_studio_views_select_column;
	/** input type for updating data in table "voyce.studio_views" */
["voyce_studio_views_set_input"]: {
		count?: number,
	id?: number,
	studio_id?: number
};
	/** aggregate stddev on columns */
["voyce_studio_views_stddev_fields"]: {
	__typename: "voyce_studio_views_stddev_fields",
	count?: number,
	id?: number,
	studio_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_studio_views_stddev_pop_fields"]: {
	__typename: "voyce_studio_views_stddev_pop_fields",
	count?: number,
	id?: number,
	studio_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_studio_views_stddev_samp_fields"]: {
	__typename: "voyce_studio_views_stddev_samp_fields",
	count?: number,
	id?: number,
	studio_id?: number
};
	/** Streaming cursor of the table "voyce_studio_views" */
["voyce_studio_views_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_studio_views_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_studio_views_stream_cursor_value_input"]: {
		count?: number,
	id?: number,
	studio_id?: number
};
	/** aggregate sum on columns */
["voyce_studio_views_sum_fields"]: {
	__typename: "voyce_studio_views_sum_fields",
	count?: number,
	id?: number,
	studio_id?: number
};
	/** update columns of table "voyce.studio_views" */
["voyce_studio_views_update_column"]: voyce_studio_views_update_column;
	["voyce_studio_views_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_studio_views_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_studio_views_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_studio_views_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_studio_views_var_pop_fields"]: {
	__typename: "voyce_studio_views_var_pop_fields",
	count?: number,
	id?: number,
	studio_id?: number
};
	/** aggregate var_samp on columns */
["voyce_studio_views_var_samp_fields"]: {
	__typename: "voyce_studio_views_var_samp_fields",
	count?: number,
	id?: number,
	studio_id?: number
};
	/** aggregate variance on columns */
["voyce_studio_views_variance_fields"]: {
	__typename: "voyce_studio_views_variance_fields",
	count?: number,
	id?: number,
	studio_id?: number
};
	/** columns and relationships of "voyce.system_settings" */
["voyce_system_settings"]: {
	__typename: "voyce_system_settings",
	created_at: GraphQLTypes["timestamp"],
	id: number,
	key: string,
	updated_at?: GraphQLTypes["timestamp"],
	value: string
};
	/** aggregated selection of "voyce.system_settings" */
["voyce_system_settings_aggregate"]: {
	__typename: "voyce_system_settings_aggregate",
	aggregate?: GraphQLTypes["voyce_system_settings_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_system_settings"]>
};
	/** aggregate fields of "voyce.system_settings" */
["voyce_system_settings_aggregate_fields"]: {
	__typename: "voyce_system_settings_aggregate_fields",
	avg?: GraphQLTypes["voyce_system_settings_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_system_settings_max_fields"],
	min?: GraphQLTypes["voyce_system_settings_min_fields"],
	stddev?: GraphQLTypes["voyce_system_settings_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_system_settings_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_system_settings_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_system_settings_sum_fields"],
	var_pop?: GraphQLTypes["voyce_system_settings_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_system_settings_var_samp_fields"],
	variance?: GraphQLTypes["voyce_system_settings_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_system_settings_avg_fields"]: {
	__typename: "voyce_system_settings_avg_fields",
	id?: number
};
	/** Boolean expression to filter rows from the table "voyce.system_settings". All fields are combined with a logical 'AND'. */
["voyce_system_settings_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_system_settings_bool_exp"]>,
	_not?: GraphQLTypes["voyce_system_settings_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_system_settings_bool_exp"]>,
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	key?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamp_comparison_exp"],
	value?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.system_settings" */
["voyce_system_settings_constraint"]: voyce_system_settings_constraint;
	/** input type for incrementing numeric columns in table "voyce.system_settings" */
["voyce_system_settings_inc_input"]: {
		id?: number
};
	/** input type for inserting data into table "voyce.system_settings" */
["voyce_system_settings_insert_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	id?: number,
	key?: string,
	updated_at?: GraphQLTypes["timestamp"],
	value?: string
};
	/** aggregate max on columns */
["voyce_system_settings_max_fields"]: {
	__typename: "voyce_system_settings_max_fields",
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	key?: string,
	updated_at?: GraphQLTypes["timestamp"],
	value?: string
};
	/** aggregate min on columns */
["voyce_system_settings_min_fields"]: {
	__typename: "voyce_system_settings_min_fields",
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	key?: string,
	updated_at?: GraphQLTypes["timestamp"],
	value?: string
};
	/** response of any mutation on the table "voyce.system_settings" */
["voyce_system_settings_mutation_response"]: {
	__typename: "voyce_system_settings_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_system_settings"]>
};
	/** on_conflict condition type for table "voyce.system_settings" */
["voyce_system_settings_on_conflict"]: {
		constraint: GraphQLTypes["voyce_system_settings_constraint"],
	update_columns: Array<GraphQLTypes["voyce_system_settings_update_column"]>,
	where?: GraphQLTypes["voyce_system_settings_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.system_settings". */
["voyce_system_settings_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	key?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	value?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.system_settings */
["voyce_system_settings_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.system_settings" */
["voyce_system_settings_select_column"]: voyce_system_settings_select_column;
	/** input type for updating data in table "voyce.system_settings" */
["voyce_system_settings_set_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	id?: number,
	key?: string,
	updated_at?: GraphQLTypes["timestamp"],
	value?: string
};
	/** aggregate stddev on columns */
["voyce_system_settings_stddev_fields"]: {
	__typename: "voyce_system_settings_stddev_fields",
	id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_system_settings_stddev_pop_fields"]: {
	__typename: "voyce_system_settings_stddev_pop_fields",
	id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_system_settings_stddev_samp_fields"]: {
	__typename: "voyce_system_settings_stddev_samp_fields",
	id?: number
};
	/** Streaming cursor of the table "voyce_system_settings" */
["voyce_system_settings_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_system_settings_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_system_settings_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	id?: number,
	key?: string,
	updated_at?: GraphQLTypes["timestamp"],
	value?: string
};
	/** aggregate sum on columns */
["voyce_system_settings_sum_fields"]: {
	__typename: "voyce_system_settings_sum_fields",
	id?: number
};
	/** update columns of table "voyce.system_settings" */
["voyce_system_settings_update_column"]: voyce_system_settings_update_column;
	["voyce_system_settings_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_system_settings_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_system_settings_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_system_settings_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_system_settings_var_pop_fields"]: {
	__typename: "voyce_system_settings_var_pop_fields",
	id?: number
};
	/** aggregate var_samp on columns */
["voyce_system_settings_var_samp_fields"]: {
	__typename: "voyce_system_settings_var_samp_fields",
	id?: number
};
	/** aggregate variance on columns */
["voyce_system_settings_variance_fields"]: {
	__typename: "voyce_system_settings_variance_fields",
	id?: number
};
	/** columns and relationships of "voyce.top_genres" */
["voyce_top_genres"]: {
	__typename: "voyce_top_genres",
	count?: GraphQLTypes["bigint"],
	/** An object relationship */
	genre?: GraphQLTypes["voyce_genres"],
	genre_id?: number,
	/** An array relationship */
	series_genres: Array<GraphQLTypes["voyce_series_genre"]>,
	/** An aggregate relationship */
	series_genres_aggregate: GraphQLTypes["voyce_series_genre_aggregate"]
};
	/** aggregated selection of "voyce.top_genres" */
["voyce_top_genres_aggregate"]: {
	__typename: "voyce_top_genres_aggregate",
	aggregate?: GraphQLTypes["voyce_top_genres_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_top_genres"]>
};
	/** aggregate fields of "voyce.top_genres" */
["voyce_top_genres_aggregate_fields"]: {
	__typename: "voyce_top_genres_aggregate_fields",
	avg?: GraphQLTypes["voyce_top_genres_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_top_genres_max_fields"],
	min?: GraphQLTypes["voyce_top_genres_min_fields"],
	stddev?: GraphQLTypes["voyce_top_genres_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_top_genres_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_top_genres_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_top_genres_sum_fields"],
	var_pop?: GraphQLTypes["voyce_top_genres_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_top_genres_var_samp_fields"],
	variance?: GraphQLTypes["voyce_top_genres_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_top_genres_avg_fields"]: {
	__typename: "voyce_top_genres_avg_fields",
	count?: number,
	genre_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.top_genres". All fields are combined with a logical 'AND'. */
["voyce_top_genres_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_top_genres_bool_exp"]>,
	_not?: GraphQLTypes["voyce_top_genres_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_top_genres_bool_exp"]>,
	count?: GraphQLTypes["bigint_comparison_exp"],
	genre?: GraphQLTypes["voyce_genres_bool_exp"],
	genre_id?: GraphQLTypes["Int_comparison_exp"],
	series_genres?: GraphQLTypes["voyce_series_genre_bool_exp"],
	series_genres_aggregate?: GraphQLTypes["voyce_series_genre_aggregate_bool_exp"]
};
	/** aggregate max on columns */
["voyce_top_genres_max_fields"]: {
	__typename: "voyce_top_genres_max_fields",
	count?: GraphQLTypes["bigint"],
	genre_id?: number
};
	/** aggregate min on columns */
["voyce_top_genres_min_fields"]: {
	__typename: "voyce_top_genres_min_fields",
	count?: GraphQLTypes["bigint"],
	genre_id?: number
};
	/** Ordering options when selecting data from "voyce.top_genres". */
["voyce_top_genres_order_by"]: {
		count?: GraphQLTypes["order_by"],
	genre?: GraphQLTypes["voyce_genres_order_by"],
	genre_id?: GraphQLTypes["order_by"],
	series_genres_aggregate?: GraphQLTypes["voyce_series_genre_aggregate_order_by"]
};
	/** select columns of table "voyce.top_genres" */
["voyce_top_genres_select_column"]: voyce_top_genres_select_column;
	/** aggregate stddev on columns */
["voyce_top_genres_stddev_fields"]: {
	__typename: "voyce_top_genres_stddev_fields",
	count?: number,
	genre_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_top_genres_stddev_pop_fields"]: {
	__typename: "voyce_top_genres_stddev_pop_fields",
	count?: number,
	genre_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_top_genres_stddev_samp_fields"]: {
	__typename: "voyce_top_genres_stddev_samp_fields",
	count?: number,
	genre_id?: number
};
	/** Streaming cursor of the table "voyce_top_genres" */
["voyce_top_genres_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_top_genres_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_top_genres_stream_cursor_value_input"]: {
		count?: GraphQLTypes["bigint"],
	genre_id?: number
};
	/** aggregate sum on columns */
["voyce_top_genres_sum_fields"]: {
	__typename: "voyce_top_genres_sum_fields",
	count?: GraphQLTypes["bigint"],
	genre_id?: number
};
	/** aggregate var_pop on columns */
["voyce_top_genres_var_pop_fields"]: {
	__typename: "voyce_top_genres_var_pop_fields",
	count?: number,
	genre_id?: number
};
	/** aggregate var_samp on columns */
["voyce_top_genres_var_samp_fields"]: {
	__typename: "voyce_top_genres_var_samp_fields",
	count?: number,
	genre_id?: number
};
	/** aggregate variance on columns */
["voyce_top_genres_variance_fields"]: {
	__typename: "voyce_top_genres_variance_fields",
	count?: number,
	genre_id?: number
};
	/** columns and relationships of "voyce.transaction_logs" */
["voyce_transaction_logs"]: {
	__typename: "voyce_transaction_logs",
	amount?: number,
	coins_type?: string,
	created_at: GraphQLTypes["timestamp"],
	id: number,
	item_id?: number,
	product_id?: number,
	receipt_id?: number,
	receiver_id?: number,
	sender_id?: number,
	title?: string,
	type?: string,
	/** An object relationship */
	userByReceiverId?: GraphQLTypes["voyce_users"],
	/** An object relationship */
	userBySenderId?: GraphQLTypes["voyce_users"]
};
	/** aggregated selection of "voyce.transaction_logs" */
["voyce_transaction_logs_aggregate"]: {
	__typename: "voyce_transaction_logs_aggregate",
	aggregate?: GraphQLTypes["voyce_transaction_logs_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_transaction_logs"]>
};
	/** aggregate fields of "voyce.transaction_logs" */
["voyce_transaction_logs_aggregate_fields"]: {
	__typename: "voyce_transaction_logs_aggregate_fields",
	avg?: GraphQLTypes["voyce_transaction_logs_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_transaction_logs_max_fields"],
	min?: GraphQLTypes["voyce_transaction_logs_min_fields"],
	stddev?: GraphQLTypes["voyce_transaction_logs_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_transaction_logs_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_transaction_logs_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_transaction_logs_sum_fields"],
	var_pop?: GraphQLTypes["voyce_transaction_logs_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_transaction_logs_var_samp_fields"],
	variance?: GraphQLTypes["voyce_transaction_logs_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_transaction_logs_avg_fields"]: {
	__typename: "voyce_transaction_logs_avg_fields",
	amount?: number,
	id?: number,
	item_id?: number,
	product_id?: number,
	receipt_id?: number,
	receiver_id?: number,
	sender_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.transaction_logs". All fields are combined with a logical 'AND'. */
["voyce_transaction_logs_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_transaction_logs_bool_exp"]>,
	_not?: GraphQLTypes["voyce_transaction_logs_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_transaction_logs_bool_exp"]>,
	amount?: GraphQLTypes["Int_comparison_exp"],
	coins_type?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	item_id?: GraphQLTypes["Int_comparison_exp"],
	product_id?: GraphQLTypes["Int_comparison_exp"],
	receipt_id?: GraphQLTypes["Int_comparison_exp"],
	receiver_id?: GraphQLTypes["Int_comparison_exp"],
	sender_id?: GraphQLTypes["Int_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"],
	userByReceiverId?: GraphQLTypes["voyce_users_bool_exp"],
	userBySenderId?: GraphQLTypes["voyce_users_bool_exp"]
};
	/** unique or primary key constraints on table "voyce.transaction_logs" */
["voyce_transaction_logs_constraint"]: voyce_transaction_logs_constraint;
	/** input type for incrementing numeric columns in table "voyce.transaction_logs" */
["voyce_transaction_logs_inc_input"]: {
		amount?: number,
	id?: number,
	item_id?: number,
	product_id?: number,
	receipt_id?: number,
	receiver_id?: number,
	sender_id?: number
};
	/** input type for inserting data into table "voyce.transaction_logs" */
["voyce_transaction_logs_insert_input"]: {
		amount?: number,
	coins_type?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	item_id?: number,
	product_id?: number,
	receipt_id?: number,
	receiver_id?: number,
	sender_id?: number,
	title?: string,
	type?: string,
	userByReceiverId?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	userBySenderId?: GraphQLTypes["voyce_users_obj_rel_insert_input"]
};
	/** aggregate max on columns */
["voyce_transaction_logs_max_fields"]: {
	__typename: "voyce_transaction_logs_max_fields",
	amount?: number,
	coins_type?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	item_id?: number,
	product_id?: number,
	receipt_id?: number,
	receiver_id?: number,
	sender_id?: number,
	title?: string,
	type?: string
};
	/** aggregate min on columns */
["voyce_transaction_logs_min_fields"]: {
	__typename: "voyce_transaction_logs_min_fields",
	amount?: number,
	coins_type?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	item_id?: number,
	product_id?: number,
	receipt_id?: number,
	receiver_id?: number,
	sender_id?: number,
	title?: string,
	type?: string
};
	/** response of any mutation on the table "voyce.transaction_logs" */
["voyce_transaction_logs_mutation_response"]: {
	__typename: "voyce_transaction_logs_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_transaction_logs"]>
};
	/** on_conflict condition type for table "voyce.transaction_logs" */
["voyce_transaction_logs_on_conflict"]: {
		constraint: GraphQLTypes["voyce_transaction_logs_constraint"],
	update_columns: Array<GraphQLTypes["voyce_transaction_logs_update_column"]>,
	where?: GraphQLTypes["voyce_transaction_logs_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.transaction_logs". */
["voyce_transaction_logs_order_by"]: {
		amount?: GraphQLTypes["order_by"],
	coins_type?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	item_id?: GraphQLTypes["order_by"],
	product_id?: GraphQLTypes["order_by"],
	receipt_id?: GraphQLTypes["order_by"],
	receiver_id?: GraphQLTypes["order_by"],
	sender_id?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	userByReceiverId?: GraphQLTypes["voyce_users_order_by"],
	userBySenderId?: GraphQLTypes["voyce_users_order_by"]
};
	/** primary key columns input for table: voyce.transaction_logs */
["voyce_transaction_logs_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.transaction_logs" */
["voyce_transaction_logs_select_column"]: voyce_transaction_logs_select_column;
	/** input type for updating data in table "voyce.transaction_logs" */
["voyce_transaction_logs_set_input"]: {
		amount?: number,
	coins_type?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	item_id?: number,
	product_id?: number,
	receipt_id?: number,
	receiver_id?: number,
	sender_id?: number,
	title?: string,
	type?: string
};
	/** aggregate stddev on columns */
["voyce_transaction_logs_stddev_fields"]: {
	__typename: "voyce_transaction_logs_stddev_fields",
	amount?: number,
	id?: number,
	item_id?: number,
	product_id?: number,
	receipt_id?: number,
	receiver_id?: number,
	sender_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_transaction_logs_stddev_pop_fields"]: {
	__typename: "voyce_transaction_logs_stddev_pop_fields",
	amount?: number,
	id?: number,
	item_id?: number,
	product_id?: number,
	receipt_id?: number,
	receiver_id?: number,
	sender_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_transaction_logs_stddev_samp_fields"]: {
	__typename: "voyce_transaction_logs_stddev_samp_fields",
	amount?: number,
	id?: number,
	item_id?: number,
	product_id?: number,
	receipt_id?: number,
	receiver_id?: number,
	sender_id?: number
};
	/** Streaming cursor of the table "voyce_transaction_logs" */
["voyce_transaction_logs_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_transaction_logs_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_transaction_logs_stream_cursor_value_input"]: {
		amount?: number,
	coins_type?: string,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	item_id?: number,
	product_id?: number,
	receipt_id?: number,
	receiver_id?: number,
	sender_id?: number,
	title?: string,
	type?: string
};
	/** aggregate sum on columns */
["voyce_transaction_logs_sum_fields"]: {
	__typename: "voyce_transaction_logs_sum_fields",
	amount?: number,
	id?: number,
	item_id?: number,
	product_id?: number,
	receipt_id?: number,
	receiver_id?: number,
	sender_id?: number
};
	/** update columns of table "voyce.transaction_logs" */
["voyce_transaction_logs_update_column"]: voyce_transaction_logs_update_column;
	["voyce_transaction_logs_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_transaction_logs_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_transaction_logs_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_transaction_logs_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_transaction_logs_var_pop_fields"]: {
	__typename: "voyce_transaction_logs_var_pop_fields",
	amount?: number,
	id?: number,
	item_id?: number,
	product_id?: number,
	receipt_id?: number,
	receiver_id?: number,
	sender_id?: number
};
	/** aggregate var_samp on columns */
["voyce_transaction_logs_var_samp_fields"]: {
	__typename: "voyce_transaction_logs_var_samp_fields",
	amount?: number,
	id?: number,
	item_id?: number,
	product_id?: number,
	receipt_id?: number,
	receiver_id?: number,
	sender_id?: number
};
	/** aggregate variance on columns */
["voyce_transaction_logs_variance_fields"]: {
	__typename: "voyce_transaction_logs_variance_fields",
	amount?: number,
	id?: number,
	item_id?: number,
	product_id?: number,
	receipt_id?: number,
	receiver_id?: number,
	sender_id?: number
};
	/** columns and relationships of "voyce.url_redirects" */
["voyce_url_redirects"]: {
	__typename: "voyce_url_redirects",
	id: number,
	path: string,
	redirect_url: string,
	views: number
};
	/** aggregated selection of "voyce.url_redirects" */
["voyce_url_redirects_aggregate"]: {
	__typename: "voyce_url_redirects_aggregate",
	aggregate?: GraphQLTypes["voyce_url_redirects_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_url_redirects"]>
};
	/** aggregate fields of "voyce.url_redirects" */
["voyce_url_redirects_aggregate_fields"]: {
	__typename: "voyce_url_redirects_aggregate_fields",
	avg?: GraphQLTypes["voyce_url_redirects_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_url_redirects_max_fields"],
	min?: GraphQLTypes["voyce_url_redirects_min_fields"],
	stddev?: GraphQLTypes["voyce_url_redirects_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_url_redirects_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_url_redirects_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_url_redirects_sum_fields"],
	var_pop?: GraphQLTypes["voyce_url_redirects_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_url_redirects_var_samp_fields"],
	variance?: GraphQLTypes["voyce_url_redirects_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_url_redirects_avg_fields"]: {
	__typename: "voyce_url_redirects_avg_fields",
	id?: number,
	views?: number
};
	/** Boolean expression to filter rows from the table "voyce.url_redirects". All fields are combined with a logical 'AND'. */
["voyce_url_redirects_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_url_redirects_bool_exp"]>,
	_not?: GraphQLTypes["voyce_url_redirects_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_url_redirects_bool_exp"]>,
	id?: GraphQLTypes["Int_comparison_exp"],
	path?: GraphQLTypes["String_comparison_exp"],
	redirect_url?: GraphQLTypes["String_comparison_exp"],
	views?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.url_redirects" */
["voyce_url_redirects_constraint"]: voyce_url_redirects_constraint;
	/** input type for incrementing numeric columns in table "voyce.url_redirects" */
["voyce_url_redirects_inc_input"]: {
		id?: number,
	views?: number
};
	/** input type for inserting data into table "voyce.url_redirects" */
["voyce_url_redirects_insert_input"]: {
		id?: number,
	path?: string,
	redirect_url?: string,
	views?: number
};
	/** aggregate max on columns */
["voyce_url_redirects_max_fields"]: {
	__typename: "voyce_url_redirects_max_fields",
	id?: number,
	path?: string,
	redirect_url?: string,
	views?: number
};
	/** aggregate min on columns */
["voyce_url_redirects_min_fields"]: {
	__typename: "voyce_url_redirects_min_fields",
	id?: number,
	path?: string,
	redirect_url?: string,
	views?: number
};
	/** response of any mutation on the table "voyce.url_redirects" */
["voyce_url_redirects_mutation_response"]: {
	__typename: "voyce_url_redirects_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_url_redirects"]>
};
	/** on_conflict condition type for table "voyce.url_redirects" */
["voyce_url_redirects_on_conflict"]: {
		constraint: GraphQLTypes["voyce_url_redirects_constraint"],
	update_columns: Array<GraphQLTypes["voyce_url_redirects_update_column"]>,
	where?: GraphQLTypes["voyce_url_redirects_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.url_redirects". */
["voyce_url_redirects_order_by"]: {
		id?: GraphQLTypes["order_by"],
	path?: GraphQLTypes["order_by"],
	redirect_url?: GraphQLTypes["order_by"],
	views?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.url_redirects */
["voyce_url_redirects_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.url_redirects" */
["voyce_url_redirects_select_column"]: voyce_url_redirects_select_column;
	/** input type for updating data in table "voyce.url_redirects" */
["voyce_url_redirects_set_input"]: {
		id?: number,
	path?: string,
	redirect_url?: string,
	views?: number
};
	/** aggregate stddev on columns */
["voyce_url_redirects_stddev_fields"]: {
	__typename: "voyce_url_redirects_stddev_fields",
	id?: number,
	views?: number
};
	/** aggregate stddev_pop on columns */
["voyce_url_redirects_stddev_pop_fields"]: {
	__typename: "voyce_url_redirects_stddev_pop_fields",
	id?: number,
	views?: number
};
	/** aggregate stddev_samp on columns */
["voyce_url_redirects_stddev_samp_fields"]: {
	__typename: "voyce_url_redirects_stddev_samp_fields",
	id?: number,
	views?: number
};
	/** Streaming cursor of the table "voyce_url_redirects" */
["voyce_url_redirects_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_url_redirects_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_url_redirects_stream_cursor_value_input"]: {
		id?: number,
	path?: string,
	redirect_url?: string,
	views?: number
};
	/** aggregate sum on columns */
["voyce_url_redirects_sum_fields"]: {
	__typename: "voyce_url_redirects_sum_fields",
	id?: number,
	views?: number
};
	/** update columns of table "voyce.url_redirects" */
["voyce_url_redirects_update_column"]: voyce_url_redirects_update_column;
	["voyce_url_redirects_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_url_redirects_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_url_redirects_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_url_redirects_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_url_redirects_var_pop_fields"]: {
	__typename: "voyce_url_redirects_var_pop_fields",
	id?: number,
	views?: number
};
	/** aggregate var_samp on columns */
["voyce_url_redirects_var_samp_fields"]: {
	__typename: "voyce_url_redirects_var_samp_fields",
	id?: number,
	views?: number
};
	/** aggregate variance on columns */
["voyce_url_redirects_variance_fields"]: {
	__typename: "voyce_url_redirects_variance_fields",
	id?: number,
	views?: number
};
	/** columns and relationships of "voyce.user_addresses" */
["voyce_user_addresses"]: {
	__typename: "voyce_user_addresses",
	address: string,
	city_id: number,
	country_id: number,
	date_create: GraphQLTypes["timestamp"],
	id: number,
	is_billing_address?: GraphQLTypes["smallint"],
	is_shipping_address?: GraphQLTypes["smallint"],
	state_id: number,
	user_id: number,
	zip_code?: string
};
	/** aggregated selection of "voyce.user_addresses" */
["voyce_user_addresses_aggregate"]: {
	__typename: "voyce_user_addresses_aggregate",
	aggregate?: GraphQLTypes["voyce_user_addresses_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_addresses"]>
};
	/** aggregate fields of "voyce.user_addresses" */
["voyce_user_addresses_aggregate_fields"]: {
	__typename: "voyce_user_addresses_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_addresses_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_addresses_max_fields"],
	min?: GraphQLTypes["voyce_user_addresses_min_fields"],
	stddev?: GraphQLTypes["voyce_user_addresses_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_addresses_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_addresses_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_addresses_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_addresses_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_addresses_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_addresses_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_addresses_avg_fields"]: {
	__typename: "voyce_user_addresses_avg_fields",
	city_id?: number,
	country_id?: number,
	id?: number,
	is_billing_address?: number,
	is_shipping_address?: number,
	state_id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_addresses". All fields are combined with a logical 'AND'. */
["voyce_user_addresses_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_addresses_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_addresses_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_addresses_bool_exp"]>,
	address?: GraphQLTypes["String_comparison_exp"],
	city_id?: GraphQLTypes["Int_comparison_exp"],
	country_id?: GraphQLTypes["Int_comparison_exp"],
	date_create?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_billing_address?: GraphQLTypes["smallint_comparison_exp"],
	is_shipping_address?: GraphQLTypes["smallint_comparison_exp"],
	state_id?: GraphQLTypes["Int_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	zip_code?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_addresses" */
["voyce_user_addresses_constraint"]: voyce_user_addresses_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_addresses" */
["voyce_user_addresses_inc_input"]: {
		city_id?: number,
	country_id?: number,
	id?: number,
	is_billing_address?: GraphQLTypes["smallint"],
	is_shipping_address?: GraphQLTypes["smallint"],
	state_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_addresses" */
["voyce_user_addresses_insert_input"]: {
		address?: string,
	city_id?: number,
	country_id?: number,
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	is_billing_address?: GraphQLTypes["smallint"],
	is_shipping_address?: GraphQLTypes["smallint"],
	state_id?: number,
	user_id?: number,
	zip_code?: string
};
	/** aggregate max on columns */
["voyce_user_addresses_max_fields"]: {
	__typename: "voyce_user_addresses_max_fields",
	address?: string,
	city_id?: number,
	country_id?: number,
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	is_billing_address?: GraphQLTypes["smallint"],
	is_shipping_address?: GraphQLTypes["smallint"],
	state_id?: number,
	user_id?: number,
	zip_code?: string
};
	/** aggregate min on columns */
["voyce_user_addresses_min_fields"]: {
	__typename: "voyce_user_addresses_min_fields",
	address?: string,
	city_id?: number,
	country_id?: number,
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	is_billing_address?: GraphQLTypes["smallint"],
	is_shipping_address?: GraphQLTypes["smallint"],
	state_id?: number,
	user_id?: number,
	zip_code?: string
};
	/** response of any mutation on the table "voyce.user_addresses" */
["voyce_user_addresses_mutation_response"]: {
	__typename: "voyce_user_addresses_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_addresses"]>
};
	/** on_conflict condition type for table "voyce.user_addresses" */
["voyce_user_addresses_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_addresses_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_addresses_update_column"]>,
	where?: GraphQLTypes["voyce_user_addresses_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_addresses". */
["voyce_user_addresses_order_by"]: {
		address?: GraphQLTypes["order_by"],
	city_id?: GraphQLTypes["order_by"],
	country_id?: GraphQLTypes["order_by"],
	date_create?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_billing_address?: GraphQLTypes["order_by"],
	is_shipping_address?: GraphQLTypes["order_by"],
	state_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	zip_code?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_addresses */
["voyce_user_addresses_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_addresses" */
["voyce_user_addresses_select_column"]: voyce_user_addresses_select_column;
	/** input type for updating data in table "voyce.user_addresses" */
["voyce_user_addresses_set_input"]: {
		address?: string,
	city_id?: number,
	country_id?: number,
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	is_billing_address?: GraphQLTypes["smallint"],
	is_shipping_address?: GraphQLTypes["smallint"],
	state_id?: number,
	user_id?: number,
	zip_code?: string
};
	/** aggregate stddev on columns */
["voyce_user_addresses_stddev_fields"]: {
	__typename: "voyce_user_addresses_stddev_fields",
	city_id?: number,
	country_id?: number,
	id?: number,
	is_billing_address?: number,
	is_shipping_address?: number,
	state_id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_addresses_stddev_pop_fields"]: {
	__typename: "voyce_user_addresses_stddev_pop_fields",
	city_id?: number,
	country_id?: number,
	id?: number,
	is_billing_address?: number,
	is_shipping_address?: number,
	state_id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_addresses_stddev_samp_fields"]: {
	__typename: "voyce_user_addresses_stddev_samp_fields",
	city_id?: number,
	country_id?: number,
	id?: number,
	is_billing_address?: number,
	is_shipping_address?: number,
	state_id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_addresses" */
["voyce_user_addresses_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_addresses_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_addresses_stream_cursor_value_input"]: {
		address?: string,
	city_id?: number,
	country_id?: number,
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	is_billing_address?: GraphQLTypes["smallint"],
	is_shipping_address?: GraphQLTypes["smallint"],
	state_id?: number,
	user_id?: number,
	zip_code?: string
};
	/** aggregate sum on columns */
["voyce_user_addresses_sum_fields"]: {
	__typename: "voyce_user_addresses_sum_fields",
	city_id?: number,
	country_id?: number,
	id?: number,
	is_billing_address?: GraphQLTypes["smallint"],
	is_shipping_address?: GraphQLTypes["smallint"],
	state_id?: number,
	user_id?: number
};
	/** update columns of table "voyce.user_addresses" */
["voyce_user_addresses_update_column"]: voyce_user_addresses_update_column;
	["voyce_user_addresses_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_addresses_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_addresses_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_addresses_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_addresses_var_pop_fields"]: {
	__typename: "voyce_user_addresses_var_pop_fields",
	city_id?: number,
	country_id?: number,
	id?: number,
	is_billing_address?: number,
	is_shipping_address?: number,
	state_id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_addresses_var_samp_fields"]: {
	__typename: "voyce_user_addresses_var_samp_fields",
	city_id?: number,
	country_id?: number,
	id?: number,
	is_billing_address?: number,
	is_shipping_address?: number,
	state_id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_addresses_variance_fields"]: {
	__typename: "voyce_user_addresses_variance_fields",
	city_id?: number,
	country_id?: number,
	id?: number,
	is_billing_address?: number,
	is_shipping_address?: number,
	state_id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.user_blocks" */
["voyce_user_blocks"]: {
	__typename: "voyce_user_blocks",
	created_at: GraphQLTypes["timestamptz"],
	extras: string,
	id: number,
	linked_id: number,
	reason: string,
	type: string,
	user_id: number
};
	/** aggregated selection of "voyce.user_blocks" */
["voyce_user_blocks_aggregate"]: {
	__typename: "voyce_user_blocks_aggregate",
	aggregate?: GraphQLTypes["voyce_user_blocks_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_blocks"]>
};
	/** aggregate fields of "voyce.user_blocks" */
["voyce_user_blocks_aggregate_fields"]: {
	__typename: "voyce_user_blocks_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_blocks_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_blocks_max_fields"],
	min?: GraphQLTypes["voyce_user_blocks_min_fields"],
	stddev?: GraphQLTypes["voyce_user_blocks_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_blocks_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_blocks_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_blocks_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_blocks_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_blocks_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_blocks_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_blocks_avg_fields"]: {
	__typename: "voyce_user_blocks_avg_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_blocks". All fields are combined with a logical 'AND'. */
["voyce_user_blocks_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_blocks_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_blocks_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_blocks_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	extras?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	linked_id?: GraphQLTypes["Int_comparison_exp"],
	reason?: GraphQLTypes["String_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_blocks" */
["voyce_user_blocks_constraint"]: voyce_user_blocks_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_blocks" */
["voyce_user_blocks_inc_input"]: {
		id?: number,
	linked_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_blocks" */
["voyce_user_blocks_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	extras?: string,
	id?: number,
	linked_id?: number,
	reason?: string,
	type?: string,
	user_id?: number
};
	/** aggregate max on columns */
["voyce_user_blocks_max_fields"]: {
	__typename: "voyce_user_blocks_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	extras?: string,
	id?: number,
	linked_id?: number,
	reason?: string,
	type?: string,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_user_blocks_min_fields"]: {
	__typename: "voyce_user_blocks_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	extras?: string,
	id?: number,
	linked_id?: number,
	reason?: string,
	type?: string,
	user_id?: number
};
	/** response of any mutation on the table "voyce.user_blocks" */
["voyce_user_blocks_mutation_response"]: {
	__typename: "voyce_user_blocks_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_blocks"]>
};
	/** on_conflict condition type for table "voyce.user_blocks" */
["voyce_user_blocks_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_blocks_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_blocks_update_column"]>,
	where?: GraphQLTypes["voyce_user_blocks_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_blocks". */
["voyce_user_blocks_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	extras?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	reason?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_blocks */
["voyce_user_blocks_pk_columns_input"]: {
		linked_id: number,
	user_id: number
};
	/** select columns of table "voyce.user_blocks" */
["voyce_user_blocks_select_column"]: voyce_user_blocks_select_column;
	/** input type for updating data in table "voyce.user_blocks" */
["voyce_user_blocks_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	extras?: string,
	id?: number,
	linked_id?: number,
	reason?: string,
	type?: string,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_blocks_stddev_fields"]: {
	__typename: "voyce_user_blocks_stddev_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_blocks_stddev_pop_fields"]: {
	__typename: "voyce_user_blocks_stddev_pop_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_blocks_stddev_samp_fields"]: {
	__typename: "voyce_user_blocks_stddev_samp_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_blocks" */
["voyce_user_blocks_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_blocks_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_blocks_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	extras?: string,
	id?: number,
	linked_id?: number,
	reason?: string,
	type?: string,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_blocks_sum_fields"]: {
	__typename: "voyce_user_blocks_sum_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** update columns of table "voyce.user_blocks" */
["voyce_user_blocks_update_column"]: voyce_user_blocks_update_column;
	["voyce_user_blocks_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_blocks_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_blocks_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_blocks_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_blocks_var_pop_fields"]: {
	__typename: "voyce_user_blocks_var_pop_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_blocks_var_samp_fields"]: {
	__typename: "voyce_user_blocks_var_samp_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_blocks_variance_fields"]: {
	__typename: "voyce_user_blocks_variance_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked"]: {
	__typename: "voyce_user_chapters_unlocked",
	chapter_id: number,
	created_at: GraphQLTypes["timestamptz"],
	end_date?: GraphQLTypes["date"],
	id: number,
	is_expired: boolean,
	/** An object relationship */
	series: GraphQLTypes["voyce_series"],
	series_id: number,
	start_date: GraphQLTypes["date"],
	updated_at: GraphQLTypes["timestamptz"],
	user_id: number
};
	/** aggregated selection of "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_aggregate"]: {
	__typename: "voyce_user_chapters_unlocked_aggregate",
	aggregate?: GraphQLTypes["voyce_user_chapters_unlocked_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_chapters_unlocked"]>
};
	/** aggregate fields of "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_aggregate_fields"]: {
	__typename: "voyce_user_chapters_unlocked_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_chapters_unlocked_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_chapters_unlocked_max_fields"],
	min?: GraphQLTypes["voyce_user_chapters_unlocked_min_fields"],
	stddev?: GraphQLTypes["voyce_user_chapters_unlocked_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_chapters_unlocked_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_chapters_unlocked_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_chapters_unlocked_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_chapters_unlocked_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_chapters_unlocked_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_chapters_unlocked_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_chapters_unlocked_avg_fields"]: {
	__typename: "voyce_user_chapters_unlocked_avg_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_chapters_unlocked". All fields are combined with a logical 'AND'. */
["voyce_user_chapters_unlocked_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_chapters_unlocked_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_chapters_unlocked_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_chapters_unlocked_bool_exp"]>,
	chapter_id?: GraphQLTypes["Int_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	end_date?: GraphQLTypes["date_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_expired?: GraphQLTypes["Boolean_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	start_date?: GraphQLTypes["date_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_constraint"]: voyce_user_chapters_unlocked_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_inc_input"]: {
		chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_insert_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	end_date?: GraphQLTypes["date"],
	id?: number,
	is_expired?: boolean,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	start_date?: GraphQLTypes["date"],
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_user_chapters_unlocked_max_fields"]: {
	__typename: "voyce_user_chapters_unlocked_max_fields",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	end_date?: GraphQLTypes["date"],
	id?: number,
	series_id?: number,
	start_date?: GraphQLTypes["date"],
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate min on columns */
["voyce_user_chapters_unlocked_min_fields"]: {
	__typename: "voyce_user_chapters_unlocked_min_fields",
	chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	end_date?: GraphQLTypes["date"],
	id?: number,
	series_id?: number,
	start_date?: GraphQLTypes["date"],
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** response of any mutation on the table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_mutation_response"]: {
	__typename: "voyce_user_chapters_unlocked_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_chapters_unlocked"]>
};
	/** on_conflict condition type for table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_chapters_unlocked_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_chapters_unlocked_update_column"]>,
	where?: GraphQLTypes["voyce_user_chapters_unlocked_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_chapters_unlocked". */
["voyce_user_chapters_unlocked_order_by"]: {
		chapter_id?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	end_date?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_expired?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	start_date?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_chapters_unlocked */
["voyce_user_chapters_unlocked_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_select_column"]: voyce_user_chapters_unlocked_select_column;
	/** input type for updating data in table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_set_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	end_date?: GraphQLTypes["date"],
	id?: number,
	is_expired?: boolean,
	series_id?: number,
	start_date?: GraphQLTypes["date"],
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_chapters_unlocked_stddev_fields"]: {
	__typename: "voyce_user_chapters_unlocked_stddev_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_chapters_unlocked_stddev_pop_fields"]: {
	__typename: "voyce_user_chapters_unlocked_stddev_pop_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_chapters_unlocked_stddev_samp_fields"]: {
	__typename: "voyce_user_chapters_unlocked_stddev_samp_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_chapters_unlocked" */
["voyce_user_chapters_unlocked_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_chapters_unlocked_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_chapters_unlocked_stream_cursor_value_input"]: {
		chapter_id?: number,
	created_at?: GraphQLTypes["timestamptz"],
	end_date?: GraphQLTypes["date"],
	id?: number,
	is_expired?: boolean,
	series_id?: number,
	start_date?: GraphQLTypes["date"],
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_chapters_unlocked_sum_fields"]: {
	__typename: "voyce_user_chapters_unlocked_sum_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** update columns of table "voyce.user_chapters_unlocked" */
["voyce_user_chapters_unlocked_update_column"]: voyce_user_chapters_unlocked_update_column;
	["voyce_user_chapters_unlocked_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_chapters_unlocked_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_chapters_unlocked_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_chapters_unlocked_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_chapters_unlocked_var_pop_fields"]: {
	__typename: "voyce_user_chapters_unlocked_var_pop_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_chapters_unlocked_var_samp_fields"]: {
	__typename: "voyce_user_chapters_unlocked_var_samp_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_chapters_unlocked_variance_fields"]: {
	__typename: "voyce_user_chapters_unlocked_variance_fields",
	chapter_id?: number,
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.user_contacts" */
["voyce_user_contacts"]: {
	__typename: "voyce_user_contacts",
	contact: string,
	country_code: string,
	date_create: GraphQLTypes["timestamp"],
	id: number,
	is_primary: GraphQLTypes["smallint"],
	user_id: number
};
	/** aggregated selection of "voyce.user_contacts" */
["voyce_user_contacts_aggregate"]: {
	__typename: "voyce_user_contacts_aggregate",
	aggregate?: GraphQLTypes["voyce_user_contacts_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_contacts"]>
};
	/** aggregate fields of "voyce.user_contacts" */
["voyce_user_contacts_aggregate_fields"]: {
	__typename: "voyce_user_contacts_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_contacts_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_contacts_max_fields"],
	min?: GraphQLTypes["voyce_user_contacts_min_fields"],
	stddev?: GraphQLTypes["voyce_user_contacts_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_contacts_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_contacts_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_contacts_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_contacts_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_contacts_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_contacts_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_contacts_avg_fields"]: {
	__typename: "voyce_user_contacts_avg_fields",
	id?: number,
	is_primary?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_contacts". All fields are combined with a logical 'AND'. */
["voyce_user_contacts_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_contacts_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_contacts_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_contacts_bool_exp"]>,
	contact?: GraphQLTypes["String_comparison_exp"],
	country_code?: GraphQLTypes["String_comparison_exp"],
	date_create?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_primary?: GraphQLTypes["smallint_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_contacts" */
["voyce_user_contacts_constraint"]: voyce_user_contacts_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_contacts" */
["voyce_user_contacts_inc_input"]: {
		id?: number,
	is_primary?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_contacts" */
["voyce_user_contacts_insert_input"]: {
		contact?: string,
	country_code?: string,
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	is_primary?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_user_contacts_max_fields"]: {
	__typename: "voyce_user_contacts_max_fields",
	contact?: string,
	country_code?: string,
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	is_primary?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** aggregate min on columns */
["voyce_user_contacts_min_fields"]: {
	__typename: "voyce_user_contacts_min_fields",
	contact?: string,
	country_code?: string,
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	is_primary?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** response of any mutation on the table "voyce.user_contacts" */
["voyce_user_contacts_mutation_response"]: {
	__typename: "voyce_user_contacts_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_contacts"]>
};
	/** on_conflict condition type for table "voyce.user_contacts" */
["voyce_user_contacts_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_contacts_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_contacts_update_column"]>,
	where?: GraphQLTypes["voyce_user_contacts_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_contacts". */
["voyce_user_contacts_order_by"]: {
		contact?: GraphQLTypes["order_by"],
	country_code?: GraphQLTypes["order_by"],
	date_create?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_primary?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_contacts */
["voyce_user_contacts_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_contacts" */
["voyce_user_contacts_select_column"]: voyce_user_contacts_select_column;
	/** input type for updating data in table "voyce.user_contacts" */
["voyce_user_contacts_set_input"]: {
		contact?: string,
	country_code?: string,
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	is_primary?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_contacts_stddev_fields"]: {
	__typename: "voyce_user_contacts_stddev_fields",
	id?: number,
	is_primary?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_contacts_stddev_pop_fields"]: {
	__typename: "voyce_user_contacts_stddev_pop_fields",
	id?: number,
	is_primary?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_contacts_stddev_samp_fields"]: {
	__typename: "voyce_user_contacts_stddev_samp_fields",
	id?: number,
	is_primary?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_contacts" */
["voyce_user_contacts_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_contacts_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_contacts_stream_cursor_value_input"]: {
		contact?: string,
	country_code?: string,
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	is_primary?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_contacts_sum_fields"]: {
	__typename: "voyce_user_contacts_sum_fields",
	id?: number,
	is_primary?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** update columns of table "voyce.user_contacts" */
["voyce_user_contacts_update_column"]: voyce_user_contacts_update_column;
	["voyce_user_contacts_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_contacts_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_contacts_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_contacts_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_contacts_var_pop_fields"]: {
	__typename: "voyce_user_contacts_var_pop_fields",
	id?: number,
	is_primary?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_contacts_var_samp_fields"]: {
	__typename: "voyce_user_contacts_var_samp_fields",
	id?: number,
	is_primary?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_contacts_variance_fields"]: {
	__typename: "voyce_user_contacts_variance_fields",
	id?: number,
	is_primary?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.user_csat_ratings" */
["voyce_user_csat_ratings"]: {
	__typename: "voyce_user_csat_ratings",
	comment?: string,
	created_at: GraphQLTypes["timestamptz"],
	gtag_id?: string,
	id: number,
	is_answered?: boolean,
	more_info?: string,
	page_uri: string,
	rating?: string,
	tag: string,
	user_id?: number
};
	/** aggregated selection of "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_aggregate"]: {
	__typename: "voyce_user_csat_ratings_aggregate",
	aggregate?: GraphQLTypes["voyce_user_csat_ratings_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_csat_ratings"]>
};
	/** aggregate fields of "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_aggregate_fields"]: {
	__typename: "voyce_user_csat_ratings_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_csat_ratings_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_csat_ratings_max_fields"],
	min?: GraphQLTypes["voyce_user_csat_ratings_min_fields"],
	stddev?: GraphQLTypes["voyce_user_csat_ratings_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_csat_ratings_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_csat_ratings_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_csat_ratings_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_csat_ratings_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_csat_ratings_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_csat_ratings_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_csat_ratings_avg_fields"]: {
	__typename: "voyce_user_csat_ratings_avg_fields",
	id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_csat_ratings". All fields are combined with a logical 'AND'. */
["voyce_user_csat_ratings_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_csat_ratings_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_csat_ratings_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_csat_ratings_bool_exp"]>,
	comment?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	gtag_id?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_answered?: GraphQLTypes["Boolean_comparison_exp"],
	more_info?: GraphQLTypes["String_comparison_exp"],
	page_uri?: GraphQLTypes["String_comparison_exp"],
	rating?: GraphQLTypes["String_comparison_exp"],
	tag?: GraphQLTypes["String_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_constraint"]: voyce_user_csat_ratings_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_inc_input"]: {
		id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_insert_input"]: {
		comment?: string,
	created_at?: GraphQLTypes["timestamptz"],
	gtag_id?: string,
	id?: number,
	is_answered?: boolean,
	more_info?: string,
	page_uri?: string,
	rating?: string,
	tag?: string,
	user_id?: number
};
	/** aggregate max on columns */
["voyce_user_csat_ratings_max_fields"]: {
	__typename: "voyce_user_csat_ratings_max_fields",
	comment?: string,
	created_at?: GraphQLTypes["timestamptz"],
	gtag_id?: string,
	id?: number,
	more_info?: string,
	page_uri?: string,
	rating?: string,
	tag?: string,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_user_csat_ratings_min_fields"]: {
	__typename: "voyce_user_csat_ratings_min_fields",
	comment?: string,
	created_at?: GraphQLTypes["timestamptz"],
	gtag_id?: string,
	id?: number,
	more_info?: string,
	page_uri?: string,
	rating?: string,
	tag?: string,
	user_id?: number
};
	/** response of any mutation on the table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_mutation_response"]: {
	__typename: "voyce_user_csat_ratings_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_csat_ratings"]>
};
	/** on_conflict condition type for table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_csat_ratings_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_csat_ratings_update_column"]>,
	where?: GraphQLTypes["voyce_user_csat_ratings_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_csat_ratings". */
["voyce_user_csat_ratings_order_by"]: {
		comment?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	gtag_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_answered?: GraphQLTypes["order_by"],
	more_info?: GraphQLTypes["order_by"],
	page_uri?: GraphQLTypes["order_by"],
	rating?: GraphQLTypes["order_by"],
	tag?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_csat_ratings */
["voyce_user_csat_ratings_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_select_column"]: voyce_user_csat_ratings_select_column;
	/** input type for updating data in table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_set_input"]: {
		comment?: string,
	created_at?: GraphQLTypes["timestamptz"],
	gtag_id?: string,
	id?: number,
	is_answered?: boolean,
	more_info?: string,
	page_uri?: string,
	rating?: string,
	tag?: string,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_csat_ratings_stddev_fields"]: {
	__typename: "voyce_user_csat_ratings_stddev_fields",
	id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_csat_ratings_stddev_pop_fields"]: {
	__typename: "voyce_user_csat_ratings_stddev_pop_fields",
	id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_csat_ratings_stddev_samp_fields"]: {
	__typename: "voyce_user_csat_ratings_stddev_samp_fields",
	id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_csat_ratings" */
["voyce_user_csat_ratings_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_csat_ratings_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_csat_ratings_stream_cursor_value_input"]: {
		comment?: string,
	created_at?: GraphQLTypes["timestamptz"],
	gtag_id?: string,
	id?: number,
	is_answered?: boolean,
	more_info?: string,
	page_uri?: string,
	rating?: string,
	tag?: string,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_csat_ratings_sum_fields"]: {
	__typename: "voyce_user_csat_ratings_sum_fields",
	id?: number,
	user_id?: number
};
	/** update columns of table "voyce.user_csat_ratings" */
["voyce_user_csat_ratings_update_column"]: voyce_user_csat_ratings_update_column;
	["voyce_user_csat_ratings_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_csat_ratings_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_csat_ratings_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_csat_ratings_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_csat_ratings_var_pop_fields"]: {
	__typename: "voyce_user_csat_ratings_var_pop_fields",
	id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_csat_ratings_var_samp_fields"]: {
	__typename: "voyce_user_csat_ratings_var_samp_fields",
	id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_csat_ratings_variance_fields"]: {
	__typename: "voyce_user_csat_ratings_variance_fields",
	id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.user_education" */
["voyce_user_education"]: {
	__typename: "voyce_user_education",
	date_create: GraphQLTypes["timestamp"],
	education_type_id: number,
	id: number,
	in_progress?: GraphQLTypes["smallint"],
	institute: string,
	passing_date?: GraphQLTypes["date"],
	title: string,
	user_id: number
};
	/** aggregated selection of "voyce.user_education" */
["voyce_user_education_aggregate"]: {
	__typename: "voyce_user_education_aggregate",
	aggregate?: GraphQLTypes["voyce_user_education_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_education"]>
};
	/** aggregate fields of "voyce.user_education" */
["voyce_user_education_aggregate_fields"]: {
	__typename: "voyce_user_education_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_education_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_education_max_fields"],
	min?: GraphQLTypes["voyce_user_education_min_fields"],
	stddev?: GraphQLTypes["voyce_user_education_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_education_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_education_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_education_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_education_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_education_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_education_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_education_avg_fields"]: {
	__typename: "voyce_user_education_avg_fields",
	education_type_id?: number,
	id?: number,
	in_progress?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_education". All fields are combined with a logical 'AND'. */
["voyce_user_education_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_education_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_education_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_education_bool_exp"]>,
	date_create?: GraphQLTypes["timestamp_comparison_exp"],
	education_type_id?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	in_progress?: GraphQLTypes["smallint_comparison_exp"],
	institute?: GraphQLTypes["String_comparison_exp"],
	passing_date?: GraphQLTypes["date_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_education" */
["voyce_user_education_constraint"]: voyce_user_education_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_education" */
["voyce_user_education_inc_input"]: {
		education_type_id?: number,
	id?: number,
	in_progress?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_education" */
["voyce_user_education_insert_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	education_type_id?: number,
	id?: number,
	in_progress?: GraphQLTypes["smallint"],
	institute?: string,
	passing_date?: GraphQLTypes["date"],
	title?: string,
	user_id?: number
};
	/** aggregate max on columns */
["voyce_user_education_max_fields"]: {
	__typename: "voyce_user_education_max_fields",
	date_create?: GraphQLTypes["timestamp"],
	education_type_id?: number,
	id?: number,
	in_progress?: GraphQLTypes["smallint"],
	institute?: string,
	passing_date?: GraphQLTypes["date"],
	title?: string,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_user_education_min_fields"]: {
	__typename: "voyce_user_education_min_fields",
	date_create?: GraphQLTypes["timestamp"],
	education_type_id?: number,
	id?: number,
	in_progress?: GraphQLTypes["smallint"],
	institute?: string,
	passing_date?: GraphQLTypes["date"],
	title?: string,
	user_id?: number
};
	/** response of any mutation on the table "voyce.user_education" */
["voyce_user_education_mutation_response"]: {
	__typename: "voyce_user_education_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_education"]>
};
	/** on_conflict condition type for table "voyce.user_education" */
["voyce_user_education_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_education_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_education_update_column"]>,
	where?: GraphQLTypes["voyce_user_education_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_education". */
["voyce_user_education_order_by"]: {
		date_create?: GraphQLTypes["order_by"],
	education_type_id?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	in_progress?: GraphQLTypes["order_by"],
	institute?: GraphQLTypes["order_by"],
	passing_date?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_education */
["voyce_user_education_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_education" */
["voyce_user_education_select_column"]: voyce_user_education_select_column;
	/** input type for updating data in table "voyce.user_education" */
["voyce_user_education_set_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	education_type_id?: number,
	id?: number,
	in_progress?: GraphQLTypes["smallint"],
	institute?: string,
	passing_date?: GraphQLTypes["date"],
	title?: string,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_education_stddev_fields"]: {
	__typename: "voyce_user_education_stddev_fields",
	education_type_id?: number,
	id?: number,
	in_progress?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_education_stddev_pop_fields"]: {
	__typename: "voyce_user_education_stddev_pop_fields",
	education_type_id?: number,
	id?: number,
	in_progress?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_education_stddev_samp_fields"]: {
	__typename: "voyce_user_education_stddev_samp_fields",
	education_type_id?: number,
	id?: number,
	in_progress?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_education" */
["voyce_user_education_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_education_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_education_stream_cursor_value_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	education_type_id?: number,
	id?: number,
	in_progress?: GraphQLTypes["smallint"],
	institute?: string,
	passing_date?: GraphQLTypes["date"],
	title?: string,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_education_sum_fields"]: {
	__typename: "voyce_user_education_sum_fields",
	education_type_id?: number,
	id?: number,
	in_progress?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** update columns of table "voyce.user_education" */
["voyce_user_education_update_column"]: voyce_user_education_update_column;
	["voyce_user_education_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_education_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_education_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_education_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_education_var_pop_fields"]: {
	__typename: "voyce_user_education_var_pop_fields",
	education_type_id?: number,
	id?: number,
	in_progress?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_education_var_samp_fields"]: {
	__typename: "voyce_user_education_var_samp_fields",
	education_type_id?: number,
	id?: number,
	in_progress?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_education_variance_fields"]: {
	__typename: "voyce_user_education_variance_fields",
	education_type_id?: number,
	id?: number,
	in_progress?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.user_level" */
["voyce_user_level"]: {
	__typename: "voyce_user_level",
	created_at: GraphQLTypes["timestamptz"],
	experience: number,
	id: number,
	level: number,
	updated_at: GraphQLTypes["timestamptz"],
	user_id: number
};
	/** aggregated selection of "voyce.user_level" */
["voyce_user_level_aggregate"]: {
	__typename: "voyce_user_level_aggregate",
	aggregate?: GraphQLTypes["voyce_user_level_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_level"]>
};
	/** aggregate fields of "voyce.user_level" */
["voyce_user_level_aggregate_fields"]: {
	__typename: "voyce_user_level_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_level_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_level_max_fields"],
	min?: GraphQLTypes["voyce_user_level_min_fields"],
	stddev?: GraphQLTypes["voyce_user_level_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_level_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_level_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_level_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_level_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_level_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_level_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_level_avg_fields"]: {
	__typename: "voyce_user_level_avg_fields",
	experience?: number,
	id?: number,
	level?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_level". All fields are combined with a logical 'AND'. */
["voyce_user_level_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_level_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_level_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_level_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	experience?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	level?: GraphQLTypes["Int_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_level" */
["voyce_user_level_constraint"]: voyce_user_level_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_level" */
["voyce_user_level_inc_input"]: {
		experience?: number,
	id?: number,
	level?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_level" */
["voyce_user_level_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	experience?: number,
	id?: number,
	level?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_user_level_max_fields"]: {
	__typename: "voyce_user_level_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	experience?: number,
	id?: number,
	level?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate min on columns */
["voyce_user_level_min_fields"]: {
	__typename: "voyce_user_level_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	experience?: number,
	id?: number,
	level?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** response of any mutation on the table "voyce.user_level" */
["voyce_user_level_mutation_response"]: {
	__typename: "voyce_user_level_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_level"]>
};
	/** on_conflict condition type for table "voyce.user_level" */
["voyce_user_level_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_level_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_level_update_column"]>,
	where?: GraphQLTypes["voyce_user_level_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_level". */
["voyce_user_level_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	experience?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	level?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_level */
["voyce_user_level_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_level" */
["voyce_user_level_select_column"]: voyce_user_level_select_column;
	/** input type for updating data in table "voyce.user_level" */
["voyce_user_level_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	experience?: number,
	id?: number,
	level?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_level_stddev_fields"]: {
	__typename: "voyce_user_level_stddev_fields",
	experience?: number,
	id?: number,
	level?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_level_stddev_pop_fields"]: {
	__typename: "voyce_user_level_stddev_pop_fields",
	experience?: number,
	id?: number,
	level?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_level_stddev_samp_fields"]: {
	__typename: "voyce_user_level_stddev_samp_fields",
	experience?: number,
	id?: number,
	level?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_level" */
["voyce_user_level_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_level_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_level_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	experience?: number,
	id?: number,
	level?: number,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_level_sum_fields"]: {
	__typename: "voyce_user_level_sum_fields",
	experience?: number,
	id?: number,
	level?: number,
	user_id?: number
};
	/** update columns of table "voyce.user_level" */
["voyce_user_level_update_column"]: voyce_user_level_update_column;
	["voyce_user_level_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_level_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_level_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_level_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_level_var_pop_fields"]: {
	__typename: "voyce_user_level_var_pop_fields",
	experience?: number,
	id?: number,
	level?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_level_var_samp_fields"]: {
	__typename: "voyce_user_level_var_samp_fields",
	experience?: number,
	id?: number,
	level?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_level_variance_fields"]: {
	__typename: "voyce_user_level_variance_fields",
	experience?: number,
	id?: number,
	level?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.user_linked_accounts" */
["voyce_user_linked_accounts"]: {
	__typename: "voyce_user_linked_accounts",
	created_at?: GraphQLTypes["timestamptz"],
	hash: string,
	id: number,
	identity: string,
	main_account: boolean,
	provider: string,
	sub: string,
	/** An object relationship */
	user?: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_aggregate"]: {
	__typename: "voyce_user_linked_accounts_aggregate",
	aggregate?: GraphQLTypes["voyce_user_linked_accounts_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_linked_accounts"]>
};
	/** aggregate fields of "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_aggregate_fields"]: {
	__typename: "voyce_user_linked_accounts_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_linked_accounts_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_linked_accounts_max_fields"],
	min?: GraphQLTypes["voyce_user_linked_accounts_min_fields"],
	stddev?: GraphQLTypes["voyce_user_linked_accounts_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_linked_accounts_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_linked_accounts_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_linked_accounts_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_linked_accounts_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_linked_accounts_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_linked_accounts_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_linked_accounts_avg_fields"]: {
	__typename: "voyce_user_linked_accounts_avg_fields",
	id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_linked_accounts". All fields are combined with a logical 'AND'. */
["voyce_user_linked_accounts_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_linked_accounts_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_linked_accounts_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_linked_accounts_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	hash?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	identity?: GraphQLTypes["String_comparison_exp"],
	main_account?: GraphQLTypes["Boolean_comparison_exp"],
	provider?: GraphQLTypes["String_comparison_exp"],
	sub?: GraphQLTypes["String_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_constraint"]: voyce_user_linked_accounts_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_inc_input"]: {
		id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	hash?: string,
	id?: number,
	identity?: string,
	main_account?: boolean,
	provider?: string,
	sub?: string,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_user_linked_accounts_max_fields"]: {
	__typename: "voyce_user_linked_accounts_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	hash?: string,
	id?: number,
	identity?: string,
	provider?: string,
	sub?: string,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_user_linked_accounts_min_fields"]: {
	__typename: "voyce_user_linked_accounts_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	hash?: string,
	id?: number,
	identity?: string,
	provider?: string,
	sub?: string,
	user_id?: number
};
	/** response of any mutation on the table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_mutation_response"]: {
	__typename: "voyce_user_linked_accounts_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_linked_accounts"]>
};
	/** on_conflict condition type for table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_linked_accounts_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_linked_accounts_update_column"]>,
	where?: GraphQLTypes["voyce_user_linked_accounts_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_linked_accounts". */
["voyce_user_linked_accounts_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	hash?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	identity?: GraphQLTypes["order_by"],
	main_account?: GraphQLTypes["order_by"],
	provider?: GraphQLTypes["order_by"],
	sub?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_linked_accounts */
["voyce_user_linked_accounts_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_select_column"]: voyce_user_linked_accounts_select_column;
	/** input type for updating data in table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	hash?: string,
	id?: number,
	identity?: string,
	main_account?: boolean,
	provider?: string,
	sub?: string,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_linked_accounts_stddev_fields"]: {
	__typename: "voyce_user_linked_accounts_stddev_fields",
	id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_linked_accounts_stddev_pop_fields"]: {
	__typename: "voyce_user_linked_accounts_stddev_pop_fields",
	id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_linked_accounts_stddev_samp_fields"]: {
	__typename: "voyce_user_linked_accounts_stddev_samp_fields",
	id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_linked_accounts" */
["voyce_user_linked_accounts_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_linked_accounts_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_linked_accounts_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	hash?: string,
	id?: number,
	identity?: string,
	main_account?: boolean,
	provider?: string,
	sub?: string,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_linked_accounts_sum_fields"]: {
	__typename: "voyce_user_linked_accounts_sum_fields",
	id?: number,
	user_id?: number
};
	/** update columns of table "voyce.user_linked_accounts" */
["voyce_user_linked_accounts_update_column"]: voyce_user_linked_accounts_update_column;
	["voyce_user_linked_accounts_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_linked_accounts_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_linked_accounts_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_linked_accounts_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_linked_accounts_var_pop_fields"]: {
	__typename: "voyce_user_linked_accounts_var_pop_fields",
	id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_linked_accounts_var_samp_fields"]: {
	__typename: "voyce_user_linked_accounts_var_samp_fields",
	id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_linked_accounts_variance_fields"]: {
	__typename: "voyce_user_linked_accounts_variance_fields",
	id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.user_login_history" */
["voyce_user_login_history"]: {
	__typename: "voyce_user_login_history",
	browser: string,
	city?: string,
	country?: string,
	id: number,
	ip: string,
	is_login: GraphQLTypes["smallint"],
	latitude?: string,
	login_time?: GraphQLTypes["timestamp"],
	logout_time?: GraphQLTypes["timestamp"],
	longitude?: string,
	os?: string,
	region?: string,
	token?: string,
	user_agent?: string,
	user_id: number
};
	/** aggregated selection of "voyce.user_login_history" */
["voyce_user_login_history_aggregate"]: {
	__typename: "voyce_user_login_history_aggregate",
	aggregate?: GraphQLTypes["voyce_user_login_history_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_login_history"]>
};
	/** aggregate fields of "voyce.user_login_history" */
["voyce_user_login_history_aggregate_fields"]: {
	__typename: "voyce_user_login_history_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_login_history_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_login_history_max_fields"],
	min?: GraphQLTypes["voyce_user_login_history_min_fields"],
	stddev?: GraphQLTypes["voyce_user_login_history_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_login_history_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_login_history_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_login_history_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_login_history_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_login_history_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_login_history_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_login_history_avg_fields"]: {
	__typename: "voyce_user_login_history_avg_fields",
	id?: number,
	is_login?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_login_history". All fields are combined with a logical 'AND'. */
["voyce_user_login_history_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_login_history_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_login_history_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_login_history_bool_exp"]>,
	browser?: GraphQLTypes["String_comparison_exp"],
	city?: GraphQLTypes["String_comparison_exp"],
	country?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	ip?: GraphQLTypes["String_comparison_exp"],
	is_login?: GraphQLTypes["smallint_comparison_exp"],
	latitude?: GraphQLTypes["String_comparison_exp"],
	login_time?: GraphQLTypes["timestamp_comparison_exp"],
	logout_time?: GraphQLTypes["timestamp_comparison_exp"],
	longitude?: GraphQLTypes["String_comparison_exp"],
	os?: GraphQLTypes["String_comparison_exp"],
	region?: GraphQLTypes["String_comparison_exp"],
	token?: GraphQLTypes["String_comparison_exp"],
	user_agent?: GraphQLTypes["String_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_login_history" */
["voyce_user_login_history_constraint"]: voyce_user_login_history_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_login_history" */
["voyce_user_login_history_inc_input"]: {
		id?: number,
	is_login?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_login_history" */
["voyce_user_login_history_insert_input"]: {
		browser?: string,
	city?: string,
	country?: string,
	id?: number,
	ip?: string,
	is_login?: GraphQLTypes["smallint"],
	latitude?: string,
	login_time?: GraphQLTypes["timestamp"],
	logout_time?: GraphQLTypes["timestamp"],
	longitude?: string,
	os?: string,
	region?: string,
	token?: string,
	user_agent?: string,
	user_id?: number
};
	/** aggregate max on columns */
["voyce_user_login_history_max_fields"]: {
	__typename: "voyce_user_login_history_max_fields",
	browser?: string,
	city?: string,
	country?: string,
	id?: number,
	ip?: string,
	is_login?: GraphQLTypes["smallint"],
	latitude?: string,
	login_time?: GraphQLTypes["timestamp"],
	logout_time?: GraphQLTypes["timestamp"],
	longitude?: string,
	os?: string,
	region?: string,
	token?: string,
	user_agent?: string,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_user_login_history_min_fields"]: {
	__typename: "voyce_user_login_history_min_fields",
	browser?: string,
	city?: string,
	country?: string,
	id?: number,
	ip?: string,
	is_login?: GraphQLTypes["smallint"],
	latitude?: string,
	login_time?: GraphQLTypes["timestamp"],
	logout_time?: GraphQLTypes["timestamp"],
	longitude?: string,
	os?: string,
	region?: string,
	token?: string,
	user_agent?: string,
	user_id?: number
};
	/** response of any mutation on the table "voyce.user_login_history" */
["voyce_user_login_history_mutation_response"]: {
	__typename: "voyce_user_login_history_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_login_history"]>
};
	/** on_conflict condition type for table "voyce.user_login_history" */
["voyce_user_login_history_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_login_history_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_login_history_update_column"]>,
	where?: GraphQLTypes["voyce_user_login_history_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_login_history". */
["voyce_user_login_history_order_by"]: {
		browser?: GraphQLTypes["order_by"],
	city?: GraphQLTypes["order_by"],
	country?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	ip?: GraphQLTypes["order_by"],
	is_login?: GraphQLTypes["order_by"],
	latitude?: GraphQLTypes["order_by"],
	login_time?: GraphQLTypes["order_by"],
	logout_time?: GraphQLTypes["order_by"],
	longitude?: GraphQLTypes["order_by"],
	os?: GraphQLTypes["order_by"],
	region?: GraphQLTypes["order_by"],
	token?: GraphQLTypes["order_by"],
	user_agent?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_login_history */
["voyce_user_login_history_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_login_history" */
["voyce_user_login_history_select_column"]: voyce_user_login_history_select_column;
	/** input type for updating data in table "voyce.user_login_history" */
["voyce_user_login_history_set_input"]: {
		browser?: string,
	city?: string,
	country?: string,
	id?: number,
	ip?: string,
	is_login?: GraphQLTypes["smallint"],
	latitude?: string,
	login_time?: GraphQLTypes["timestamp"],
	logout_time?: GraphQLTypes["timestamp"],
	longitude?: string,
	os?: string,
	region?: string,
	token?: string,
	user_agent?: string,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_login_history_stddev_fields"]: {
	__typename: "voyce_user_login_history_stddev_fields",
	id?: number,
	is_login?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_login_history_stddev_pop_fields"]: {
	__typename: "voyce_user_login_history_stddev_pop_fields",
	id?: number,
	is_login?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_login_history_stddev_samp_fields"]: {
	__typename: "voyce_user_login_history_stddev_samp_fields",
	id?: number,
	is_login?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_login_history" */
["voyce_user_login_history_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_login_history_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_login_history_stream_cursor_value_input"]: {
		browser?: string,
	city?: string,
	country?: string,
	id?: number,
	ip?: string,
	is_login?: GraphQLTypes["smallint"],
	latitude?: string,
	login_time?: GraphQLTypes["timestamp"],
	logout_time?: GraphQLTypes["timestamp"],
	longitude?: string,
	os?: string,
	region?: string,
	token?: string,
	user_agent?: string,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_login_history_sum_fields"]: {
	__typename: "voyce_user_login_history_sum_fields",
	id?: number,
	is_login?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** update columns of table "voyce.user_login_history" */
["voyce_user_login_history_update_column"]: voyce_user_login_history_update_column;
	["voyce_user_login_history_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_login_history_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_login_history_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_login_history_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_login_history_var_pop_fields"]: {
	__typename: "voyce_user_login_history_var_pop_fields",
	id?: number,
	is_login?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_login_history_var_samp_fields"]: {
	__typename: "voyce_user_login_history_var_samp_fields",
	id?: number,
	is_login?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_login_history_variance_fields"]: {
	__typename: "voyce_user_login_history_variance_fields",
	id?: number,
	is_login?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.user_quest_ids" */
["voyce_user_quest_ids"]: {
	__typename: "voyce_user_quest_ids",
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	/** An object relationship */
	quest?: GraphQLTypes["voyce_user_quests"],
	tag: string,
	type: string,
	type_id: number,
	user_id: number,
	user_quest_id: number
};
	/** aggregated selection of "voyce.user_quest_ids" */
["voyce_user_quest_ids_aggregate"]: {
	__typename: "voyce_user_quest_ids_aggregate",
	aggregate?: GraphQLTypes["voyce_user_quest_ids_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_quest_ids"]>
};
	/** aggregate fields of "voyce.user_quest_ids" */
["voyce_user_quest_ids_aggregate_fields"]: {
	__typename: "voyce_user_quest_ids_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_quest_ids_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_quest_ids_max_fields"],
	min?: GraphQLTypes["voyce_user_quest_ids_min_fields"],
	stddev?: GraphQLTypes["voyce_user_quest_ids_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_quest_ids_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_quest_ids_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_quest_ids_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_quest_ids_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_quest_ids_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_quest_ids_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_quest_ids_avg_fields"]: {
	__typename: "voyce_user_quest_ids_avg_fields",
	id?: number,
	type_id?: number,
	user_id?: number,
	user_quest_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_quest_ids". All fields are combined with a logical 'AND'. */
["voyce_user_quest_ids_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_quest_ids_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_quest_ids_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_quest_ids_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	quest?: GraphQLTypes["voyce_user_quests_bool_exp"],
	tag?: GraphQLTypes["String_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"],
	type_id?: GraphQLTypes["Int_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	user_quest_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_quest_ids" */
["voyce_user_quest_ids_constraint"]: voyce_user_quest_ids_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_quest_ids" */
["voyce_user_quest_ids_inc_input"]: {
		id?: number,
	type_id?: number,
	user_id?: number,
	user_quest_id?: number
};
	/** input type for inserting data into table "voyce.user_quest_ids" */
["voyce_user_quest_ids_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	quest?: GraphQLTypes["voyce_user_quests_obj_rel_insert_input"],
	tag?: string,
	type?: string,
	type_id?: number,
	user_id?: number,
	user_quest_id?: number
};
	/** aggregate max on columns */
["voyce_user_quest_ids_max_fields"]: {
	__typename: "voyce_user_quest_ids_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	tag?: string,
	type?: string,
	type_id?: number,
	user_id?: number,
	user_quest_id?: number
};
	/** aggregate min on columns */
["voyce_user_quest_ids_min_fields"]: {
	__typename: "voyce_user_quest_ids_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	tag?: string,
	type?: string,
	type_id?: number,
	user_id?: number,
	user_quest_id?: number
};
	/** response of any mutation on the table "voyce.user_quest_ids" */
["voyce_user_quest_ids_mutation_response"]: {
	__typename: "voyce_user_quest_ids_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_quest_ids"]>
};
	/** on_conflict condition type for table "voyce.user_quest_ids" */
["voyce_user_quest_ids_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_quest_ids_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_quest_ids_update_column"]>,
	where?: GraphQLTypes["voyce_user_quest_ids_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_quest_ids". */
["voyce_user_quest_ids_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	quest?: GraphQLTypes["voyce_user_quests_order_by"],
	tag?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	type_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	user_quest_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_quest_ids */
["voyce_user_quest_ids_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_quest_ids" */
["voyce_user_quest_ids_select_column"]: voyce_user_quest_ids_select_column;
	/** input type for updating data in table "voyce.user_quest_ids" */
["voyce_user_quest_ids_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	tag?: string,
	type?: string,
	type_id?: number,
	user_id?: number,
	user_quest_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_quest_ids_stddev_fields"]: {
	__typename: "voyce_user_quest_ids_stddev_fields",
	id?: number,
	type_id?: number,
	user_id?: number,
	user_quest_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_quest_ids_stddev_pop_fields"]: {
	__typename: "voyce_user_quest_ids_stddev_pop_fields",
	id?: number,
	type_id?: number,
	user_id?: number,
	user_quest_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_quest_ids_stddev_samp_fields"]: {
	__typename: "voyce_user_quest_ids_stddev_samp_fields",
	id?: number,
	type_id?: number,
	user_id?: number,
	user_quest_id?: number
};
	/** Streaming cursor of the table "voyce_user_quest_ids" */
["voyce_user_quest_ids_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_quest_ids_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_quest_ids_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	tag?: string,
	type?: string,
	type_id?: number,
	user_id?: number,
	user_quest_id?: number
};
	/** aggregate sum on columns */
["voyce_user_quest_ids_sum_fields"]: {
	__typename: "voyce_user_quest_ids_sum_fields",
	id?: number,
	type_id?: number,
	user_id?: number,
	user_quest_id?: number
};
	/** update columns of table "voyce.user_quest_ids" */
["voyce_user_quest_ids_update_column"]: voyce_user_quest_ids_update_column;
	["voyce_user_quest_ids_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_quest_ids_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_quest_ids_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_quest_ids_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_quest_ids_var_pop_fields"]: {
	__typename: "voyce_user_quest_ids_var_pop_fields",
	id?: number,
	type_id?: number,
	user_id?: number,
	user_quest_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_quest_ids_var_samp_fields"]: {
	__typename: "voyce_user_quest_ids_var_samp_fields",
	id?: number,
	type_id?: number,
	user_id?: number,
	user_quest_id?: number
};
	/** aggregate variance on columns */
["voyce_user_quest_ids_variance_fields"]: {
	__typename: "voyce_user_quest_ids_variance_fields",
	id?: number,
	type_id?: number,
	user_id?: number,
	user_quest_id?: number
};
	/** columns and relationships of "voyce.user_quest_rewards" */
["voyce_user_quest_rewards"]: {
	__typename: "voyce_user_quest_rewards",
	created_at: GraphQLTypes["timestamptz"],
	description: string,
	id: number,
	metadata: string,
	type: string,
	user_id: number,
	user_quest_id: number,
	value: number
};
	/** aggregated selection of "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_aggregate"]: {
	__typename: "voyce_user_quest_rewards_aggregate",
	aggregate?: GraphQLTypes["voyce_user_quest_rewards_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_quest_rewards"]>
};
	/** aggregate fields of "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_aggregate_fields"]: {
	__typename: "voyce_user_quest_rewards_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_quest_rewards_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_quest_rewards_max_fields"],
	min?: GraphQLTypes["voyce_user_quest_rewards_min_fields"],
	stddev?: GraphQLTypes["voyce_user_quest_rewards_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_quest_rewards_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_quest_rewards_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_quest_rewards_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_quest_rewards_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_quest_rewards_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_quest_rewards_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_quest_rewards_avg_fields"]: {
	__typename: "voyce_user_quest_rewards_avg_fields",
	id?: number,
	user_id?: number,
	user_quest_id?: number,
	value?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_quest_rewards". All fields are combined with a logical 'AND'. */
["voyce_user_quest_rewards_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_quest_rewards_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_quest_rewards_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_quest_rewards_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	description?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	metadata?: GraphQLTypes["String_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	user_quest_id?: GraphQLTypes["Int_comparison_exp"],
	value?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_constraint"]: voyce_user_quest_rewards_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_inc_input"]: {
		id?: number,
	user_id?: number,
	user_quest_id?: number,
	value?: number
};
	/** input type for inserting data into table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	id?: number,
	metadata?: string,
	type?: string,
	user_id?: number,
	user_quest_id?: number,
	value?: number
};
	/** aggregate max on columns */
["voyce_user_quest_rewards_max_fields"]: {
	__typename: "voyce_user_quest_rewards_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	id?: number,
	metadata?: string,
	type?: string,
	user_id?: number,
	user_quest_id?: number,
	value?: number
};
	/** aggregate min on columns */
["voyce_user_quest_rewards_min_fields"]: {
	__typename: "voyce_user_quest_rewards_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	id?: number,
	metadata?: string,
	type?: string,
	user_id?: number,
	user_quest_id?: number,
	value?: number
};
	/** response of any mutation on the table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_mutation_response"]: {
	__typename: "voyce_user_quest_rewards_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_quest_rewards"]>
};
	/** on_conflict condition type for table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_quest_rewards_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_quest_rewards_update_column"]>,
	where?: GraphQLTypes["voyce_user_quest_rewards_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_quest_rewards". */
["voyce_user_quest_rewards_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	metadata?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	user_quest_id?: GraphQLTypes["order_by"],
	value?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_quest_rewards */
["voyce_user_quest_rewards_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_select_column"]: voyce_user_quest_rewards_select_column;
	/** input type for updating data in table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	id?: number,
	metadata?: string,
	type?: string,
	user_id?: number,
	user_quest_id?: number,
	value?: number
};
	/** aggregate stddev on columns */
["voyce_user_quest_rewards_stddev_fields"]: {
	__typename: "voyce_user_quest_rewards_stddev_fields",
	id?: number,
	user_id?: number,
	user_quest_id?: number,
	value?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_quest_rewards_stddev_pop_fields"]: {
	__typename: "voyce_user_quest_rewards_stddev_pop_fields",
	id?: number,
	user_id?: number,
	user_quest_id?: number,
	value?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_quest_rewards_stddev_samp_fields"]: {
	__typename: "voyce_user_quest_rewards_stddev_samp_fields",
	id?: number,
	user_id?: number,
	user_quest_id?: number,
	value?: number
};
	/** Streaming cursor of the table "voyce_user_quest_rewards" */
["voyce_user_quest_rewards_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_quest_rewards_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_quest_rewards_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	id?: number,
	metadata?: string,
	type?: string,
	user_id?: number,
	user_quest_id?: number,
	value?: number
};
	/** aggregate sum on columns */
["voyce_user_quest_rewards_sum_fields"]: {
	__typename: "voyce_user_quest_rewards_sum_fields",
	id?: number,
	user_id?: number,
	user_quest_id?: number,
	value?: number
};
	/** update columns of table "voyce.user_quest_rewards" */
["voyce_user_quest_rewards_update_column"]: voyce_user_quest_rewards_update_column;
	["voyce_user_quest_rewards_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_quest_rewards_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_quest_rewards_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_quest_rewards_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_quest_rewards_var_pop_fields"]: {
	__typename: "voyce_user_quest_rewards_var_pop_fields",
	id?: number,
	user_id?: number,
	user_quest_id?: number,
	value?: number
};
	/** aggregate var_samp on columns */
["voyce_user_quest_rewards_var_samp_fields"]: {
	__typename: "voyce_user_quest_rewards_var_samp_fields",
	id?: number,
	user_id?: number,
	user_quest_id?: number,
	value?: number
};
	/** aggregate variance on columns */
["voyce_user_quest_rewards_variance_fields"]: {
	__typename: "voyce_user_quest_rewards_variance_fields",
	id?: number,
	user_id?: number,
	user_quest_id?: number,
	value?: number
};
	/** columns and relationships of "voyce.user_quests" */
["voyce_user_quests"]: {
	__typename: "voyce_user_quests",
	completed_at: GraphQLTypes["timestamptz"],
	created_at: GraphQLTypes["timestamptz"],
	data: GraphQLTypes["json"],
	/** e.g. once = once, daily = date (2024-06-08), weekly = week # or week first day (2024-06-08) */
	frequency_tag: string,
	id: number,
	notified: boolean,
	/** An object relationship */
	quest: GraphQLTypes["voyce_quests"],
	quest_id: number,
	rewarded_at?: GraphQLTypes["timestamptz"],
	rewards_data: GraphQLTypes["json"],
	status: string,
	updated_at: GraphQLTypes["timestamptz"],
	user_id: number,
	/** An object relationship */
	vtag?: GraphQLTypes["voyce_user_vtags"]
};
	/** aggregated selection of "voyce.user_quests" */
["voyce_user_quests_aggregate"]: {
	__typename: "voyce_user_quests_aggregate",
	aggregate?: GraphQLTypes["voyce_user_quests_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_quests"]>
};
	/** aggregate fields of "voyce.user_quests" */
["voyce_user_quests_aggregate_fields"]: {
	__typename: "voyce_user_quests_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_quests_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_quests_max_fields"],
	min?: GraphQLTypes["voyce_user_quests_min_fields"],
	stddev?: GraphQLTypes["voyce_user_quests_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_quests_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_quests_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_quests_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_quests_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_quests_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_quests_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_quests_avg_fields"]: {
	__typename: "voyce_user_quests_avg_fields",
	id?: number,
	quest_id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_quests". All fields are combined with a logical 'AND'. */
["voyce_user_quests_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_quests_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_quests_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_quests_bool_exp"]>,
	completed_at?: GraphQLTypes["timestamptz_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	data?: GraphQLTypes["json_comparison_exp"],
	frequency_tag?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	notified?: GraphQLTypes["Boolean_comparison_exp"],
	quest?: GraphQLTypes["voyce_quests_bool_exp"],
	quest_id?: GraphQLTypes["Int_comparison_exp"],
	rewarded_at?: GraphQLTypes["timestamptz_comparison_exp"],
	rewards_data?: GraphQLTypes["json_comparison_exp"],
	status?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	vtag?: GraphQLTypes["voyce_user_vtags_bool_exp"]
};
	/** unique or primary key constraints on table "voyce.user_quests" */
["voyce_user_quests_constraint"]: voyce_user_quests_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_quests" */
["voyce_user_quests_inc_input"]: {
		id?: number,
	quest_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_quests" */
["voyce_user_quests_insert_input"]: {
		completed_at?: GraphQLTypes["timestamptz"],
	created_at?: GraphQLTypes["timestamptz"],
	data?: GraphQLTypes["json"],
	/** e.g. once = once, daily = date (2024-06-08), weekly = week # or week first day (2024-06-08) */
	frequency_tag?: string,
	id?: number,
	notified?: boolean,
	quest?: GraphQLTypes["voyce_quests_obj_rel_insert_input"],
	quest_id?: number,
	rewarded_at?: GraphQLTypes["timestamptz"],
	rewards_data?: GraphQLTypes["json"],
	status?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number,
	vtag?: GraphQLTypes["voyce_user_vtags_obj_rel_insert_input"]
};
	/** aggregate max on columns */
["voyce_user_quests_max_fields"]: {
	__typename: "voyce_user_quests_max_fields",
	completed_at?: GraphQLTypes["timestamptz"],
	created_at?: GraphQLTypes["timestamptz"],
	/** e.g. once = once, daily = date (2024-06-08), weekly = week # or week first day (2024-06-08) */
	frequency_tag?: string,
	id?: number,
	quest_id?: number,
	rewarded_at?: GraphQLTypes["timestamptz"],
	status?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate min on columns */
["voyce_user_quests_min_fields"]: {
	__typename: "voyce_user_quests_min_fields",
	completed_at?: GraphQLTypes["timestamptz"],
	created_at?: GraphQLTypes["timestamptz"],
	/** e.g. once = once, daily = date (2024-06-08), weekly = week # or week first day (2024-06-08) */
	frequency_tag?: string,
	id?: number,
	quest_id?: number,
	rewarded_at?: GraphQLTypes["timestamptz"],
	status?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** response of any mutation on the table "voyce.user_quests" */
["voyce_user_quests_mutation_response"]: {
	__typename: "voyce_user_quests_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_quests"]>
};
	/** input type for inserting object relation for remote table "voyce.user_quests" */
["voyce_user_quests_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_user_quests_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_user_quests_on_conflict"]
};
	/** columns and relationships of "voyce.user_quests_old" */
["voyce_user_quests_old"]: {
	__typename: "voyce_user_quests_old",
	created_at: GraphQLTypes["timestamp"],
	id: number,
	progress?: GraphQLTypes["numeric"],
	/** An object relationship */
	quest: GraphQLTypes["voyce_quests_old"],
	quest_id: number,
	updated_at?: GraphQLTypes["timestamp"],
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.user_quests_old" */
["voyce_user_quests_old_aggregate"]: {
	__typename: "voyce_user_quests_old_aggregate",
	aggregate?: GraphQLTypes["voyce_user_quests_old_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_quests_old"]>
};
	["voyce_user_quests_old_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_user_quests_old_aggregate_bool_exp_count"]
};
	["voyce_user_quests_old_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_user_quests_old_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_user_quests_old_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.user_quests_old" */
["voyce_user_quests_old_aggregate_fields"]: {
	__typename: "voyce_user_quests_old_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_quests_old_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_quests_old_max_fields"],
	min?: GraphQLTypes["voyce_user_quests_old_min_fields"],
	stddev?: GraphQLTypes["voyce_user_quests_old_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_quests_old_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_quests_old_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_quests_old_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_quests_old_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_quests_old_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_quests_old_variance_fields"]
};
	/** order by aggregate values of table "voyce.user_quests_old" */
["voyce_user_quests_old_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_user_quests_old_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_user_quests_old_max_order_by"],
	min?: GraphQLTypes["voyce_user_quests_old_min_order_by"],
	stddev?: GraphQLTypes["voyce_user_quests_old_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_user_quests_old_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_user_quests_old_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_user_quests_old_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_user_quests_old_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_user_quests_old_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_user_quests_old_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.user_quests_old" */
["voyce_user_quests_old_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_user_quests_old_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_user_quests_old_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_user_quests_old_avg_fields"]: {
	__typename: "voyce_user_quests_old_avg_fields",
	id?: number,
	progress?: number,
	quest_id?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	progress?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.user_quests_old". All fields are combined with a logical 'AND'. */
["voyce_user_quests_old_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_quests_old_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_quests_old_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_quests_old_bool_exp"]>,
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	progress?: GraphQLTypes["numeric_comparison_exp"],
	quest?: GraphQLTypes["voyce_quests_old_bool_exp"],
	quest_id?: GraphQLTypes["Int_comparison_exp"],
	updated_at?: GraphQLTypes["timestamp_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_quests_old" */
["voyce_user_quests_old_constraint"]: voyce_user_quests_old_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_quests_old" */
["voyce_user_quests_old_inc_input"]: {
		id?: number,
	progress?: GraphQLTypes["numeric"],
	quest_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_quests_old" */
["voyce_user_quests_old_insert_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	id?: number,
	progress?: GraphQLTypes["numeric"],
	quest?: GraphQLTypes["voyce_quests_old_obj_rel_insert_input"],
	quest_id?: number,
	updated_at?: GraphQLTypes["timestamp"],
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_user_quests_old_max_fields"]: {
	__typename: "voyce_user_quests_old_max_fields",
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	progress?: GraphQLTypes["numeric"],
	quest_id?: number,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number
};
	/** order by max() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_max_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	progress?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_user_quests_old_min_fields"]: {
	__typename: "voyce_user_quests_old_min_fields",
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	progress?: GraphQLTypes["numeric"],
	quest_id?: number,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number
};
	/** order by min() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_min_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	progress?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.user_quests_old" */
["voyce_user_quests_old_mutation_response"]: {
	__typename: "voyce_user_quests_old_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_quests_old"]>
};
	/** on_conflict condition type for table "voyce.user_quests_old" */
["voyce_user_quests_old_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_quests_old_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_quests_old_update_column"]>,
	where?: GraphQLTypes["voyce_user_quests_old_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_quests_old". */
["voyce_user_quests_old_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	progress?: GraphQLTypes["order_by"],
	quest?: GraphQLTypes["voyce_quests_old_order_by"],
	quest_id?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_quests_old */
["voyce_user_quests_old_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_select_column"]: voyce_user_quests_old_select_column;
	/** input type for updating data in table "voyce.user_quests_old" */
["voyce_user_quests_old_set_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	id?: number,
	progress?: GraphQLTypes["numeric"],
	quest_id?: number,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_quests_old_stddev_fields"]: {
	__typename: "voyce_user_quests_old_stddev_fields",
	id?: number,
	progress?: number,
	quest_id?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	progress?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_user_quests_old_stddev_pop_fields"]: {
	__typename: "voyce_user_quests_old_stddev_pop_fields",
	id?: number,
	progress?: number,
	quest_id?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	progress?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_user_quests_old_stddev_samp_fields"]: {
	__typename: "voyce_user_quests_old_stddev_samp_fields",
	id?: number,
	progress?: number,
	quest_id?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	progress?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_user_quests_old" */
["voyce_user_quests_old_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_quests_old_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_quests_old_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamp"],
	id?: number,
	progress?: GraphQLTypes["numeric"],
	quest_id?: number,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_quests_old_sum_fields"]: {
	__typename: "voyce_user_quests_old_sum_fields",
	id?: number,
	progress?: GraphQLTypes["numeric"],
	quest_id?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	progress?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_update_column"]: voyce_user_quests_old_update_column;
	["voyce_user_quests_old_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_quests_old_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_quests_old_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_quests_old_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_quests_old_var_pop_fields"]: {
	__typename: "voyce_user_quests_old_var_pop_fields",
	id?: number,
	progress?: number,
	quest_id?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	progress?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_user_quests_old_var_samp_fields"]: {
	__typename: "voyce_user_quests_old_var_samp_fields",
	id?: number,
	progress?: number,
	quest_id?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	progress?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_user_quests_old_variance_fields"]: {
	__typename: "voyce_user_quests_old_variance_fields",
	id?: number,
	progress?: number,
	quest_id?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.user_quests_old" */
["voyce_user_quests_old_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	progress?: GraphQLTypes["order_by"],
	quest_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** on_conflict condition type for table "voyce.user_quests" */
["voyce_user_quests_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_quests_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_quests_update_column"]>,
	where?: GraphQLTypes["voyce_user_quests_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_quests". */
["voyce_user_quests_order_by"]: {
		completed_at?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	data?: GraphQLTypes["order_by"],
	frequency_tag?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	notified?: GraphQLTypes["order_by"],
	quest?: GraphQLTypes["voyce_quests_order_by"],
	quest_id?: GraphQLTypes["order_by"],
	rewarded_at?: GraphQLTypes["order_by"],
	rewards_data?: GraphQLTypes["order_by"],
	status?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	vtag?: GraphQLTypes["voyce_user_vtags_order_by"]
};
	/** primary key columns input for table: voyce.user_quests */
["voyce_user_quests_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_quests" */
["voyce_user_quests_select_column"]: voyce_user_quests_select_column;
	/** input type for updating data in table "voyce.user_quests" */
["voyce_user_quests_set_input"]: {
		completed_at?: GraphQLTypes["timestamptz"],
	created_at?: GraphQLTypes["timestamptz"],
	data?: GraphQLTypes["json"],
	/** e.g. once = once, daily = date (2024-06-08), weekly = week # or week first day (2024-06-08) */
	frequency_tag?: string,
	id?: number,
	notified?: boolean,
	quest_id?: number,
	rewarded_at?: GraphQLTypes["timestamptz"],
	rewards_data?: GraphQLTypes["json"],
	status?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_quests_stddev_fields"]: {
	__typename: "voyce_user_quests_stddev_fields",
	id?: number,
	quest_id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_quests_stddev_pop_fields"]: {
	__typename: "voyce_user_quests_stddev_pop_fields",
	id?: number,
	quest_id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_quests_stddev_samp_fields"]: {
	__typename: "voyce_user_quests_stddev_samp_fields",
	id?: number,
	quest_id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_quests" */
["voyce_user_quests_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_quests_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_quests_stream_cursor_value_input"]: {
		completed_at?: GraphQLTypes["timestamptz"],
	created_at?: GraphQLTypes["timestamptz"],
	data?: GraphQLTypes["json"],
	/** e.g. once = once, daily = date (2024-06-08), weekly = week # or week first day (2024-06-08) */
	frequency_tag?: string,
	id?: number,
	notified?: boolean,
	quest_id?: number,
	rewarded_at?: GraphQLTypes["timestamptz"],
	rewards_data?: GraphQLTypes["json"],
	status?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_quests_sum_fields"]: {
	__typename: "voyce_user_quests_sum_fields",
	id?: number,
	quest_id?: number,
	user_id?: number
};
	/** update columns of table "voyce.user_quests" */
["voyce_user_quests_update_column"]: voyce_user_quests_update_column;
	["voyce_user_quests_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_quests_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_quests_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_quests_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_quests_var_pop_fields"]: {
	__typename: "voyce_user_quests_var_pop_fields",
	id?: number,
	quest_id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_quests_var_samp_fields"]: {
	__typename: "voyce_user_quests_var_samp_fields",
	id?: number,
	quest_id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_quests_variance_fields"]: {
	__typename: "voyce_user_quests_variance_fields",
	id?: number,
	quest_id?: number,
	user_id?: number
};
	/** table to keep relation between user and leaderboards */
["voyce_user_ranking"]: {
	__typename: "voyce_user_ranking",
	counter?: number,
	day?: string,
	id: number,
	/** An object relationship */
	series?: GraphQLTypes["voyce_series"],
	series_id?: number,
	/** An object relationship */
	user?: GraphQLTypes["voyce_users"],
	user_id?: number,
	xp_count?: number
};
	/** aggregated selection of "voyce.user_ranking" */
["voyce_user_ranking_aggregate"]: {
	__typename: "voyce_user_ranking_aggregate",
	aggregate?: GraphQLTypes["voyce_user_ranking_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_ranking"]>
};
	/** aggregate fields of "voyce.user_ranking" */
["voyce_user_ranking_aggregate_fields"]: {
	__typename: "voyce_user_ranking_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_ranking_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_ranking_max_fields"],
	min?: GraphQLTypes["voyce_user_ranking_min_fields"],
	stddev?: GraphQLTypes["voyce_user_ranking_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_ranking_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_ranking_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_ranking_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_ranking_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_ranking_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_ranking_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_ranking_avg_fields"]: {
	__typename: "voyce_user_ranking_avg_fields",
	counter?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	xp_count?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_ranking". All fields are combined with a logical 'AND'. */
["voyce_user_ranking_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_ranking_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_ranking_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_ranking_bool_exp"]>,
	counter?: GraphQLTypes["Int_comparison_exp"],
	day?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	xp_count?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_ranking" */
["voyce_user_ranking_constraint"]: voyce_user_ranking_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_ranking" */
["voyce_user_ranking_inc_input"]: {
		counter?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	xp_count?: number
};
	/** input type for inserting data into table "voyce.user_ranking" */
["voyce_user_ranking_insert_input"]: {
		counter?: number,
	day?: string,
	id?: number,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number,
	xp_count?: number
};
	/** aggregate max on columns */
["voyce_user_ranking_max_fields"]: {
	__typename: "voyce_user_ranking_max_fields",
	counter?: number,
	day?: string,
	id?: number,
	series_id?: number,
	user_id?: number,
	xp_count?: number
};
	/** aggregate min on columns */
["voyce_user_ranking_min_fields"]: {
	__typename: "voyce_user_ranking_min_fields",
	counter?: number,
	day?: string,
	id?: number,
	series_id?: number,
	user_id?: number,
	xp_count?: number
};
	/** response of any mutation on the table "voyce.user_ranking" */
["voyce_user_ranking_mutation_response"]: {
	__typename: "voyce_user_ranking_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_ranking"]>
};
	/** on_conflict condition type for table "voyce.user_ranking" */
["voyce_user_ranking_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_ranking_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_ranking_update_column"]>,
	where?: GraphQLTypes["voyce_user_ranking_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_ranking". */
["voyce_user_ranking_order_by"]: {
		counter?: GraphQLTypes["order_by"],
	day?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"],
	xp_count?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_ranking */
["voyce_user_ranking_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_ranking" */
["voyce_user_ranking_select_column"]: voyce_user_ranking_select_column;
	/** input type for updating data in table "voyce.user_ranking" */
["voyce_user_ranking_set_input"]: {
		counter?: number,
	day?: string,
	id?: number,
	series_id?: number,
	user_id?: number,
	xp_count?: number
};
	/** aggregate stddev on columns */
["voyce_user_ranking_stddev_fields"]: {
	__typename: "voyce_user_ranking_stddev_fields",
	counter?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	xp_count?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_ranking_stddev_pop_fields"]: {
	__typename: "voyce_user_ranking_stddev_pop_fields",
	counter?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	xp_count?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_ranking_stddev_samp_fields"]: {
	__typename: "voyce_user_ranking_stddev_samp_fields",
	counter?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	xp_count?: number
};
	/** Streaming cursor of the table "voyce_user_ranking" */
["voyce_user_ranking_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_ranking_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_ranking_stream_cursor_value_input"]: {
		counter?: number,
	day?: string,
	id?: number,
	series_id?: number,
	user_id?: number,
	xp_count?: number
};
	/** aggregate sum on columns */
["voyce_user_ranking_sum_fields"]: {
	__typename: "voyce_user_ranking_sum_fields",
	counter?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	xp_count?: number
};
	/** update columns of table "voyce.user_ranking" */
["voyce_user_ranking_update_column"]: voyce_user_ranking_update_column;
	["voyce_user_ranking_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_ranking_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_ranking_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_ranking_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_ranking_var_pop_fields"]: {
	__typename: "voyce_user_ranking_var_pop_fields",
	counter?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	xp_count?: number
};
	/** aggregate var_samp on columns */
["voyce_user_ranking_var_samp_fields"]: {
	__typename: "voyce_user_ranking_var_samp_fields",
	counter?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	xp_count?: number
};
	/** aggregate variance on columns */
["voyce_user_ranking_variance_fields"]: {
	__typename: "voyce_user_ranking_variance_fields",
	counter?: number,
	id?: number,
	series_id?: number,
	user_id?: number,
	xp_count?: number
};
	/** columns and relationships of "voyce.user_reviews" */
["voyce_user_reviews"]: {
	__typename: "voyce_user_reviews",
	date_create: GraphQLTypes["timestamp"],
	id: number,
	publish: GraphQLTypes["smallint"],
	rating: number,
	review?: string,
	reviewer_id: number,
	user_id: number
};
	/** aggregated selection of "voyce.user_reviews" */
["voyce_user_reviews_aggregate"]: {
	__typename: "voyce_user_reviews_aggregate",
	aggregate?: GraphQLTypes["voyce_user_reviews_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_reviews"]>
};
	/** aggregate fields of "voyce.user_reviews" */
["voyce_user_reviews_aggregate_fields"]: {
	__typename: "voyce_user_reviews_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_reviews_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_reviews_max_fields"],
	min?: GraphQLTypes["voyce_user_reviews_min_fields"],
	stddev?: GraphQLTypes["voyce_user_reviews_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_reviews_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_reviews_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_reviews_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_reviews_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_reviews_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_reviews_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_reviews_avg_fields"]: {
	__typename: "voyce_user_reviews_avg_fields",
	id?: number,
	publish?: number,
	rating?: number,
	reviewer_id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_reviews". All fields are combined with a logical 'AND'. */
["voyce_user_reviews_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_reviews_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_reviews_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_reviews_bool_exp"]>,
	date_create?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	publish?: GraphQLTypes["smallint_comparison_exp"],
	rating?: GraphQLTypes["Int_comparison_exp"],
	review?: GraphQLTypes["String_comparison_exp"],
	reviewer_id?: GraphQLTypes["Int_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_reviews" */
["voyce_user_reviews_constraint"]: voyce_user_reviews_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_reviews" */
["voyce_user_reviews_inc_input"]: {
		id?: number,
	publish?: GraphQLTypes["smallint"],
	rating?: number,
	reviewer_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_reviews" */
["voyce_user_reviews_insert_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	id?: number,
	publish?: GraphQLTypes["smallint"],
	rating?: number,
	review?: string,
	reviewer_id?: number,
	user_id?: number
};
	/** aggregate max on columns */
["voyce_user_reviews_max_fields"]: {
	__typename: "voyce_user_reviews_max_fields",
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	publish?: GraphQLTypes["smallint"],
	rating?: number,
	review?: string,
	reviewer_id?: number,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_user_reviews_min_fields"]: {
	__typename: "voyce_user_reviews_min_fields",
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	publish?: GraphQLTypes["smallint"],
	rating?: number,
	review?: string,
	reviewer_id?: number,
	user_id?: number
};
	/** response of any mutation on the table "voyce.user_reviews" */
["voyce_user_reviews_mutation_response"]: {
	__typename: "voyce_user_reviews_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_reviews"]>
};
	/** on_conflict condition type for table "voyce.user_reviews" */
["voyce_user_reviews_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_reviews_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_reviews_update_column"]>,
	where?: GraphQLTypes["voyce_user_reviews_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_reviews". */
["voyce_user_reviews_order_by"]: {
		date_create?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	publish?: GraphQLTypes["order_by"],
	rating?: GraphQLTypes["order_by"],
	review?: GraphQLTypes["order_by"],
	reviewer_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_reviews */
["voyce_user_reviews_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_reviews" */
["voyce_user_reviews_select_column"]: voyce_user_reviews_select_column;
	/** input type for updating data in table "voyce.user_reviews" */
["voyce_user_reviews_set_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	id?: number,
	publish?: GraphQLTypes["smallint"],
	rating?: number,
	review?: string,
	reviewer_id?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_reviews_stddev_fields"]: {
	__typename: "voyce_user_reviews_stddev_fields",
	id?: number,
	publish?: number,
	rating?: number,
	reviewer_id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_reviews_stddev_pop_fields"]: {
	__typename: "voyce_user_reviews_stddev_pop_fields",
	id?: number,
	publish?: number,
	rating?: number,
	reviewer_id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_reviews_stddev_samp_fields"]: {
	__typename: "voyce_user_reviews_stddev_samp_fields",
	id?: number,
	publish?: number,
	rating?: number,
	reviewer_id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_reviews" */
["voyce_user_reviews_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_reviews_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_reviews_stream_cursor_value_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	id?: number,
	publish?: GraphQLTypes["smallint"],
	rating?: number,
	review?: string,
	reviewer_id?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_reviews_sum_fields"]: {
	__typename: "voyce_user_reviews_sum_fields",
	id?: number,
	publish?: GraphQLTypes["smallint"],
	rating?: number,
	reviewer_id?: number,
	user_id?: number
};
	/** update columns of table "voyce.user_reviews" */
["voyce_user_reviews_update_column"]: voyce_user_reviews_update_column;
	["voyce_user_reviews_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_reviews_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_reviews_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_reviews_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_reviews_var_pop_fields"]: {
	__typename: "voyce_user_reviews_var_pop_fields",
	id?: number,
	publish?: number,
	rating?: number,
	reviewer_id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_reviews_var_samp_fields"]: {
	__typename: "voyce_user_reviews_var_samp_fields",
	id?: number,
	publish?: number,
	rating?: number,
	reviewer_id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_reviews_variance_fields"]: {
	__typename: "voyce_user_reviews_variance_fields",
	id?: number,
	publish?: number,
	rating?: number,
	reviewer_id?: number,
	user_id?: number
};
	/** table to describe rewards won by users */
["voyce_user_rewards"]: {
	__typename: "voyce_user_rewards",
	coin_amount: GraphQLTypes["numeric"],
	coin_type: string,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	type: string,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.user_rewards" */
["voyce_user_rewards_aggregate"]: {
	__typename: "voyce_user_rewards_aggregate",
	aggregate?: GraphQLTypes["voyce_user_rewards_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_rewards"]>
};
	/** aggregate fields of "voyce.user_rewards" */
["voyce_user_rewards_aggregate_fields"]: {
	__typename: "voyce_user_rewards_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_rewards_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_rewards_max_fields"],
	min?: GraphQLTypes["voyce_user_rewards_min_fields"],
	stddev?: GraphQLTypes["voyce_user_rewards_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_rewards_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_rewards_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_rewards_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_rewards_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_rewards_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_rewards_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_rewards_avg_fields"]: {
	__typename: "voyce_user_rewards_avg_fields",
	coin_amount?: number,
	id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_rewards". All fields are combined with a logical 'AND'. */
["voyce_user_rewards_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_rewards_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_rewards_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_rewards_bool_exp"]>,
	coin_amount?: GraphQLTypes["numeric_comparison_exp"],
	coin_type?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	type?: GraphQLTypes["String_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_rewards" */
["voyce_user_rewards_constraint"]: voyce_user_rewards_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_rewards" */
["voyce_user_rewards_inc_input"]: {
		coin_amount?: GraphQLTypes["numeric"],
	id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_rewards" */
["voyce_user_rewards_insert_input"]: {
		coin_amount?: GraphQLTypes["numeric"],
	coin_type?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	type?: string,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_user_rewards_max_fields"]: {
	__typename: "voyce_user_rewards_max_fields",
	coin_amount?: GraphQLTypes["numeric"],
	coin_type?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	type?: string,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_user_rewards_min_fields"]: {
	__typename: "voyce_user_rewards_min_fields",
	coin_amount?: GraphQLTypes["numeric"],
	coin_type?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	type?: string,
	user_id?: number
};
	/** response of any mutation on the table "voyce.user_rewards" */
["voyce_user_rewards_mutation_response"]: {
	__typename: "voyce_user_rewards_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_rewards"]>
};
	/** on_conflict condition type for table "voyce.user_rewards" */
["voyce_user_rewards_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_rewards_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_rewards_update_column"]>,
	where?: GraphQLTypes["voyce_user_rewards_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_rewards". */
["voyce_user_rewards_order_by"]: {
		coin_amount?: GraphQLTypes["order_by"],
	coin_type?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_rewards */
["voyce_user_rewards_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_rewards" */
["voyce_user_rewards_select_column"]: voyce_user_rewards_select_column;
	/** input type for updating data in table "voyce.user_rewards" */
["voyce_user_rewards_set_input"]: {
		coin_amount?: GraphQLTypes["numeric"],
	coin_type?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	type?: string,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_rewards_stddev_fields"]: {
	__typename: "voyce_user_rewards_stddev_fields",
	coin_amount?: number,
	id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_rewards_stddev_pop_fields"]: {
	__typename: "voyce_user_rewards_stddev_pop_fields",
	coin_amount?: number,
	id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_rewards_stddev_samp_fields"]: {
	__typename: "voyce_user_rewards_stddev_samp_fields",
	coin_amount?: number,
	id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_rewards" */
["voyce_user_rewards_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_rewards_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_rewards_stream_cursor_value_input"]: {
		coin_amount?: GraphQLTypes["numeric"],
	coin_type?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	type?: string,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_rewards_sum_fields"]: {
	__typename: "voyce_user_rewards_sum_fields",
	coin_amount?: GraphQLTypes["numeric"],
	id?: number,
	user_id?: number
};
	/** update columns of table "voyce.user_rewards" */
["voyce_user_rewards_update_column"]: voyce_user_rewards_update_column;
	["voyce_user_rewards_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_rewards_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_rewards_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_rewards_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_rewards_var_pop_fields"]: {
	__typename: "voyce_user_rewards_var_pop_fields",
	coin_amount?: number,
	id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_rewards_var_samp_fields"]: {
	__typename: "voyce_user_rewards_var_samp_fields",
	coin_amount?: number,
	id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_rewards_variance_fields"]: {
	__typename: "voyce_user_rewards_variance_fields",
	coin_amount?: number,
	id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.user_roles" */
["voyce_user_roles"]: {
	__typename: "voyce_user_roles",
	date_create: GraphQLTypes["timestamp"],
	id: number,
	title: string
};
	/** aggregated selection of "voyce.user_roles" */
["voyce_user_roles_aggregate"]: {
	__typename: "voyce_user_roles_aggregate",
	aggregate?: GraphQLTypes["voyce_user_roles_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_roles"]>
};
	/** aggregate fields of "voyce.user_roles" */
["voyce_user_roles_aggregate_fields"]: {
	__typename: "voyce_user_roles_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_roles_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_roles_max_fields"],
	min?: GraphQLTypes["voyce_user_roles_min_fields"],
	stddev?: GraphQLTypes["voyce_user_roles_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_roles_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_roles_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_roles_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_roles_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_roles_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_roles_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_roles_avg_fields"]: {
	__typename: "voyce_user_roles_avg_fields",
	id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_roles". All fields are combined with a logical 'AND'. */
["voyce_user_roles_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_roles_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_roles_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_roles_bool_exp"]>,
	date_create?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_roles" */
["voyce_user_roles_constraint"]: voyce_user_roles_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_roles" */
["voyce_user_roles_inc_input"]: {
		id?: number
};
	/** input type for inserting data into table "voyce.user_roles" */
["voyce_user_roles_insert_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	id?: number,
	title?: string
};
	/** aggregate max on columns */
["voyce_user_roles_max_fields"]: {
	__typename: "voyce_user_roles_max_fields",
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	title?: string
};
	/** aggregate min on columns */
["voyce_user_roles_min_fields"]: {
	__typename: "voyce_user_roles_min_fields",
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	title?: string
};
	/** response of any mutation on the table "voyce.user_roles" */
["voyce_user_roles_mutation_response"]: {
	__typename: "voyce_user_roles_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_roles"]>
};
	/** input type for inserting object relation for remote table "voyce.user_roles" */
["voyce_user_roles_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_user_roles_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_user_roles_on_conflict"]
};
	/** on_conflict condition type for table "voyce.user_roles" */
["voyce_user_roles_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_roles_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_roles_update_column"]>,
	where?: GraphQLTypes["voyce_user_roles_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_roles". */
["voyce_user_roles_order_by"]: {
		date_create?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_roles */
["voyce_user_roles_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_roles" */
["voyce_user_roles_select_column"]: voyce_user_roles_select_column;
	/** input type for updating data in table "voyce.user_roles" */
["voyce_user_roles_set_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	id?: number,
	title?: string
};
	/** aggregate stddev on columns */
["voyce_user_roles_stddev_fields"]: {
	__typename: "voyce_user_roles_stddev_fields",
	id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_roles_stddev_pop_fields"]: {
	__typename: "voyce_user_roles_stddev_pop_fields",
	id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_roles_stddev_samp_fields"]: {
	__typename: "voyce_user_roles_stddev_samp_fields",
	id?: number
};
	/** Streaming cursor of the table "voyce_user_roles" */
["voyce_user_roles_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_roles_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_roles_stream_cursor_value_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	id?: number,
	title?: string
};
	/** aggregate sum on columns */
["voyce_user_roles_sum_fields"]: {
	__typename: "voyce_user_roles_sum_fields",
	id?: number
};
	/** update columns of table "voyce.user_roles" */
["voyce_user_roles_update_column"]: voyce_user_roles_update_column;
	["voyce_user_roles_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_roles_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_roles_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_roles_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_roles_var_pop_fields"]: {
	__typename: "voyce_user_roles_var_pop_fields",
	id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_roles_var_samp_fields"]: {
	__typename: "voyce_user_roles_var_samp_fields",
	id?: number
};
	/** aggregate variance on columns */
["voyce_user_roles_variance_fields"]: {
	__typename: "voyce_user_roles_variance_fields",
	id?: number
};
	/** columns and relationships of "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count"]: {
	__typename: "voyce_user_series_subscription_count",
	count?: GraphQLTypes["bigint"],
	user_id?: number
};
	/** aggregated selection of "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_aggregate"]: {
	__typename: "voyce_user_series_subscription_count_aggregate",
	aggregate?: GraphQLTypes["voyce_user_series_subscription_count_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_series_subscription_count"]>
};
	/** aggregate fields of "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_aggregate_fields"]: {
	__typename: "voyce_user_series_subscription_count_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_series_subscription_count_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_series_subscription_count_max_fields"],
	min?: GraphQLTypes["voyce_user_series_subscription_count_min_fields"],
	stddev?: GraphQLTypes["voyce_user_series_subscription_count_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_series_subscription_count_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_series_subscription_count_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_series_subscription_count_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_series_subscription_count_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_series_subscription_count_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_series_subscription_count_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_series_subscription_count_avg_fields"]: {
	__typename: "voyce_user_series_subscription_count_avg_fields",
	count?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_series_subscription_count". All fields are combined with a logical 'AND'. */
["voyce_user_series_subscription_count_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_series_subscription_count_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_series_subscription_count_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_series_subscription_count_bool_exp"]>,
	count?: GraphQLTypes["bigint_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_constraint"]: voyce_user_series_subscription_count_constraint;
	/** input type for inserting data into table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_insert_input"]: {
		count?: GraphQLTypes["bigint"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_user_series_subscription_count_max_fields"]: {
	__typename: "voyce_user_series_subscription_count_max_fields",
	count?: GraphQLTypes["bigint"],
	user_id?: number
};
	/** aggregate min on columns */
["voyce_user_series_subscription_count_min_fields"]: {
	__typename: "voyce_user_series_subscription_count_min_fields",
	count?: GraphQLTypes["bigint"],
	user_id?: number
};
	/** input type for inserting object relation for remote table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_user_series_subscription_count_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_user_series_subscription_count_on_conflict"]
};
	/** on_conflict condition type for table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_series_subscription_count_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_series_subscription_count_update_column"]>,
	where?: GraphQLTypes["voyce_user_series_subscription_count_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_series_subscription_count". */
["voyce_user_series_subscription_count_order_by"]: {
		count?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_select_column"]: voyce_user_series_subscription_count_select_column;
	/** aggregate stddev on columns */
["voyce_user_series_subscription_count_stddev_fields"]: {
	__typename: "voyce_user_series_subscription_count_stddev_fields",
	count?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_series_subscription_count_stddev_pop_fields"]: {
	__typename: "voyce_user_series_subscription_count_stddev_pop_fields",
	count?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_series_subscription_count_stddev_samp_fields"]: {
	__typename: "voyce_user_series_subscription_count_stddev_samp_fields",
	count?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_series_subscription_count" */
["voyce_user_series_subscription_count_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_series_subscription_count_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_series_subscription_count_stream_cursor_value_input"]: {
		count?: GraphQLTypes["bigint"],
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_series_subscription_count_sum_fields"]: {
	__typename: "voyce_user_series_subscription_count_sum_fields",
	count?: GraphQLTypes["bigint"],
	user_id?: number
};
	/** update columns of table "voyce.user_series_subscription_count" */
["voyce_user_series_subscription_count_update_column"]: voyce_user_series_subscription_count_update_column;
	/** aggregate var_pop on columns */
["voyce_user_series_subscription_count_var_pop_fields"]: {
	__typename: "voyce_user_series_subscription_count_var_pop_fields",
	count?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_series_subscription_count_var_samp_fields"]: {
	__typename: "voyce_user_series_subscription_count_var_samp_fields",
	count?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_series_subscription_count_variance_fields"]: {
	__typename: "voyce_user_series_subscription_count_variance_fields",
	count?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.user_settings" */
["voyce_user_settings"]: {
	__typename: "voyce_user_settings",
	content_mature: GraphQLTypes["smallint"],
	content_spoilers: GraphQLTypes["smallint"],
	id: number,
	page_animation: GraphQLTypes["smallint"],
	reading_comic: string,
	reading_manga: string,
	reading_novel: string,
	theme: string,
	updated_at: GraphQLTypes["timestamptz"],
	user_id: number
};
	/** aggregated selection of "voyce.user_settings" */
["voyce_user_settings_aggregate"]: {
	__typename: "voyce_user_settings_aggregate",
	aggregate?: GraphQLTypes["voyce_user_settings_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_settings"]>
};
	/** aggregate fields of "voyce.user_settings" */
["voyce_user_settings_aggregate_fields"]: {
	__typename: "voyce_user_settings_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_settings_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_settings_max_fields"],
	min?: GraphQLTypes["voyce_user_settings_min_fields"],
	stddev?: GraphQLTypes["voyce_user_settings_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_settings_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_settings_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_settings_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_settings_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_settings_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_settings_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_settings_avg_fields"]: {
	__typename: "voyce_user_settings_avg_fields",
	content_mature?: number,
	content_spoilers?: number,
	id?: number,
	page_animation?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_settings". All fields are combined with a logical 'AND'. */
["voyce_user_settings_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_settings_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_settings_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_settings_bool_exp"]>,
	content_mature?: GraphQLTypes["smallint_comparison_exp"],
	content_spoilers?: GraphQLTypes["smallint_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	page_animation?: GraphQLTypes["smallint_comparison_exp"],
	reading_comic?: GraphQLTypes["String_comparison_exp"],
	reading_manga?: GraphQLTypes["String_comparison_exp"],
	reading_novel?: GraphQLTypes["String_comparison_exp"],
	theme?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_settings" */
["voyce_user_settings_constraint"]: voyce_user_settings_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_settings" */
["voyce_user_settings_inc_input"]: {
		content_mature?: GraphQLTypes["smallint"],
	content_spoilers?: GraphQLTypes["smallint"],
	id?: number,
	page_animation?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_settings" */
["voyce_user_settings_insert_input"]: {
		content_mature?: GraphQLTypes["smallint"],
	content_spoilers?: GraphQLTypes["smallint"],
	id?: number,
	page_animation?: GraphQLTypes["smallint"],
	reading_comic?: string,
	reading_manga?: string,
	reading_novel?: string,
	theme?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_user_settings_max_fields"]: {
	__typename: "voyce_user_settings_max_fields",
	content_mature?: GraphQLTypes["smallint"],
	content_spoilers?: GraphQLTypes["smallint"],
	id?: number,
	page_animation?: GraphQLTypes["smallint"],
	reading_comic?: string,
	reading_manga?: string,
	reading_novel?: string,
	theme?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate min on columns */
["voyce_user_settings_min_fields"]: {
	__typename: "voyce_user_settings_min_fields",
	content_mature?: GraphQLTypes["smallint"],
	content_spoilers?: GraphQLTypes["smallint"],
	id?: number,
	page_animation?: GraphQLTypes["smallint"],
	reading_comic?: string,
	reading_manga?: string,
	reading_novel?: string,
	theme?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** response of any mutation on the table "voyce.user_settings" */
["voyce_user_settings_mutation_response"]: {
	__typename: "voyce_user_settings_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_settings"]>
};
	/** on_conflict condition type for table "voyce.user_settings" */
["voyce_user_settings_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_settings_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_settings_update_column"]>,
	where?: GraphQLTypes["voyce_user_settings_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_settings". */
["voyce_user_settings_order_by"]: {
		content_mature?: GraphQLTypes["order_by"],
	content_spoilers?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	page_animation?: GraphQLTypes["order_by"],
	reading_comic?: GraphQLTypes["order_by"],
	reading_manga?: GraphQLTypes["order_by"],
	reading_novel?: GraphQLTypes["order_by"],
	theme?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_settings */
["voyce_user_settings_pk_columns_input"]: {
		user_id: number
};
	/** select columns of table "voyce.user_settings" */
["voyce_user_settings_select_column"]: voyce_user_settings_select_column;
	/** input type for updating data in table "voyce.user_settings" */
["voyce_user_settings_set_input"]: {
		content_mature?: GraphQLTypes["smallint"],
	content_spoilers?: GraphQLTypes["smallint"],
	id?: number,
	page_animation?: GraphQLTypes["smallint"],
	reading_comic?: string,
	reading_manga?: string,
	reading_novel?: string,
	theme?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_settings_stddev_fields"]: {
	__typename: "voyce_user_settings_stddev_fields",
	content_mature?: number,
	content_spoilers?: number,
	id?: number,
	page_animation?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_settings_stddev_pop_fields"]: {
	__typename: "voyce_user_settings_stddev_pop_fields",
	content_mature?: number,
	content_spoilers?: number,
	id?: number,
	page_animation?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_settings_stddev_samp_fields"]: {
	__typename: "voyce_user_settings_stddev_samp_fields",
	content_mature?: number,
	content_spoilers?: number,
	id?: number,
	page_animation?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_settings" */
["voyce_user_settings_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_settings_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_settings_stream_cursor_value_input"]: {
		content_mature?: GraphQLTypes["smallint"],
	content_spoilers?: GraphQLTypes["smallint"],
	id?: number,
	page_animation?: GraphQLTypes["smallint"],
	reading_comic?: string,
	reading_manga?: string,
	reading_novel?: string,
	theme?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_settings_sum_fields"]: {
	__typename: "voyce_user_settings_sum_fields",
	content_mature?: GraphQLTypes["smallint"],
	content_spoilers?: GraphQLTypes["smallint"],
	id?: number,
	page_animation?: GraphQLTypes["smallint"],
	user_id?: number
};
	/** update columns of table "voyce.user_settings" */
["voyce_user_settings_update_column"]: voyce_user_settings_update_column;
	["voyce_user_settings_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_settings_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_settings_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_settings_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_settings_var_pop_fields"]: {
	__typename: "voyce_user_settings_var_pop_fields",
	content_mature?: number,
	content_spoilers?: number,
	id?: number,
	page_animation?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_settings_var_samp_fields"]: {
	__typename: "voyce_user_settings_var_samp_fields",
	content_mature?: number,
	content_spoilers?: number,
	id?: number,
	page_animation?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_settings_variance_fields"]: {
	__typename: "voyce_user_settings_variance_fields",
	content_mature?: number,
	content_spoilers?: number,
	id?: number,
	page_animation?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.user_skills" */
["voyce_user_skills"]: {
	__typename: "voyce_user_skills",
	date_create: GraphQLTypes["timestamp"],
	id: number,
	skill: string,
	user_id: number
};
	/** aggregated selection of "voyce.user_skills" */
["voyce_user_skills_aggregate"]: {
	__typename: "voyce_user_skills_aggregate",
	aggregate?: GraphQLTypes["voyce_user_skills_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_skills"]>
};
	/** aggregate fields of "voyce.user_skills" */
["voyce_user_skills_aggregate_fields"]: {
	__typename: "voyce_user_skills_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_skills_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_skills_max_fields"],
	min?: GraphQLTypes["voyce_user_skills_min_fields"],
	stddev?: GraphQLTypes["voyce_user_skills_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_skills_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_skills_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_skills_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_skills_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_skills_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_skills_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_skills_avg_fields"]: {
	__typename: "voyce_user_skills_avg_fields",
	id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_skills". All fields are combined with a logical 'AND'. */
["voyce_user_skills_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_skills_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_skills_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_skills_bool_exp"]>,
	date_create?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	skill?: GraphQLTypes["String_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_skills" */
["voyce_user_skills_constraint"]: voyce_user_skills_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_skills" */
["voyce_user_skills_inc_input"]: {
		id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_skills" */
["voyce_user_skills_insert_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	id?: number,
	skill?: string,
	user_id?: number
};
	/** aggregate max on columns */
["voyce_user_skills_max_fields"]: {
	__typename: "voyce_user_skills_max_fields",
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	skill?: string,
	user_id?: number
};
	/** aggregate min on columns */
["voyce_user_skills_min_fields"]: {
	__typename: "voyce_user_skills_min_fields",
	date_create?: GraphQLTypes["timestamp"],
	id?: number,
	skill?: string,
	user_id?: number
};
	/** response of any mutation on the table "voyce.user_skills" */
["voyce_user_skills_mutation_response"]: {
	__typename: "voyce_user_skills_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_skills"]>
};
	/** on_conflict condition type for table "voyce.user_skills" */
["voyce_user_skills_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_skills_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_skills_update_column"]>,
	where?: GraphQLTypes["voyce_user_skills_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_skills". */
["voyce_user_skills_order_by"]: {
		date_create?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	skill?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_skills */
["voyce_user_skills_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_skills" */
["voyce_user_skills_select_column"]: voyce_user_skills_select_column;
	/** input type for updating data in table "voyce.user_skills" */
["voyce_user_skills_set_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	id?: number,
	skill?: string,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_skills_stddev_fields"]: {
	__typename: "voyce_user_skills_stddev_fields",
	id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_skills_stddev_pop_fields"]: {
	__typename: "voyce_user_skills_stddev_pop_fields",
	id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_skills_stddev_samp_fields"]: {
	__typename: "voyce_user_skills_stddev_samp_fields",
	id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_skills" */
["voyce_user_skills_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_skills_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_skills_stream_cursor_value_input"]: {
		date_create?: GraphQLTypes["timestamp"],
	id?: number,
	skill?: string,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_skills_sum_fields"]: {
	__typename: "voyce_user_skills_sum_fields",
	id?: number,
	user_id?: number
};
	/** update columns of table "voyce.user_skills" */
["voyce_user_skills_update_column"]: voyce_user_skills_update_column;
	["voyce_user_skills_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_skills_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_skills_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_skills_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_skills_var_pop_fields"]: {
	__typename: "voyce_user_skills_var_pop_fields",
	id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_skills_var_samp_fields"]: {
	__typename: "voyce_user_skills_var_samp_fields",
	id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_skills_variance_fields"]: {
	__typename: "voyce_user_skills_variance_fields",
	id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.user_socials" */
["voyce_user_socials"]: {
	__typename: "voyce_user_socials",
	access_token: string,
	created_at: GraphQLTypes["timestamptz"],
	expired_at: GraphQLTypes["timestamptz"],
	expires_in?: GraphQLTypes["numeric"],
	id: number,
	provider: string,
	refresh_token: string,
	scope?: string,
	token_type?: string,
	updated_at: GraphQLTypes["timestamptz"],
	user_id: number
};
	/** aggregated selection of "voyce.user_socials" */
["voyce_user_socials_aggregate"]: {
	__typename: "voyce_user_socials_aggregate",
	aggregate?: GraphQLTypes["voyce_user_socials_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_socials"]>
};
	/** aggregate fields of "voyce.user_socials" */
["voyce_user_socials_aggregate_fields"]: {
	__typename: "voyce_user_socials_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_socials_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_socials_max_fields"],
	min?: GraphQLTypes["voyce_user_socials_min_fields"],
	stddev?: GraphQLTypes["voyce_user_socials_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_socials_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_socials_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_socials_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_socials_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_socials_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_socials_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_socials_avg_fields"]: {
	__typename: "voyce_user_socials_avg_fields",
	expires_in?: number,
	id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_socials". All fields are combined with a logical 'AND'. */
["voyce_user_socials_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_socials_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_socials_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_socials_bool_exp"]>,
	access_token?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	expired_at?: GraphQLTypes["timestamptz_comparison_exp"],
	expires_in?: GraphQLTypes["numeric_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	provider?: GraphQLTypes["String_comparison_exp"],
	refresh_token?: GraphQLTypes["String_comparison_exp"],
	scope?: GraphQLTypes["String_comparison_exp"],
	token_type?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_socials" */
["voyce_user_socials_constraint"]: voyce_user_socials_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_socials" */
["voyce_user_socials_inc_input"]: {
		expires_in?: GraphQLTypes["numeric"],
	id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_socials" */
["voyce_user_socials_insert_input"]: {
		access_token?: string,
	created_at?: GraphQLTypes["timestamptz"],
	expired_at?: GraphQLTypes["timestamptz"],
	expires_in?: GraphQLTypes["numeric"],
	id?: number,
	provider?: string,
	refresh_token?: string,
	scope?: string,
	token_type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_user_socials_max_fields"]: {
	__typename: "voyce_user_socials_max_fields",
	access_token?: string,
	created_at?: GraphQLTypes["timestamptz"],
	expired_at?: GraphQLTypes["timestamptz"],
	expires_in?: GraphQLTypes["numeric"],
	id?: number,
	provider?: string,
	refresh_token?: string,
	scope?: string,
	token_type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate min on columns */
["voyce_user_socials_min_fields"]: {
	__typename: "voyce_user_socials_min_fields",
	access_token?: string,
	created_at?: GraphQLTypes["timestamptz"],
	expired_at?: GraphQLTypes["timestamptz"],
	expires_in?: GraphQLTypes["numeric"],
	id?: number,
	provider?: string,
	refresh_token?: string,
	scope?: string,
	token_type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** response of any mutation on the table "voyce.user_socials" */
["voyce_user_socials_mutation_response"]: {
	__typename: "voyce_user_socials_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_socials"]>
};
	/** on_conflict condition type for table "voyce.user_socials" */
["voyce_user_socials_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_socials_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_socials_update_column"]>,
	where?: GraphQLTypes["voyce_user_socials_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_socials". */
["voyce_user_socials_order_by"]: {
		access_token?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	expired_at?: GraphQLTypes["order_by"],
	expires_in?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	provider?: GraphQLTypes["order_by"],
	refresh_token?: GraphQLTypes["order_by"],
	scope?: GraphQLTypes["order_by"],
	token_type?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_socials */
["voyce_user_socials_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_socials" */
["voyce_user_socials_select_column"]: voyce_user_socials_select_column;
	/** input type for updating data in table "voyce.user_socials" */
["voyce_user_socials_set_input"]: {
		access_token?: string,
	created_at?: GraphQLTypes["timestamptz"],
	expired_at?: GraphQLTypes["timestamptz"],
	expires_in?: GraphQLTypes["numeric"],
	id?: number,
	provider?: string,
	refresh_token?: string,
	scope?: string,
	token_type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_socials_stddev_fields"]: {
	__typename: "voyce_user_socials_stddev_fields",
	expires_in?: number,
	id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_socials_stddev_pop_fields"]: {
	__typename: "voyce_user_socials_stddev_pop_fields",
	expires_in?: number,
	id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_socials_stddev_samp_fields"]: {
	__typename: "voyce_user_socials_stddev_samp_fields",
	expires_in?: number,
	id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_socials" */
["voyce_user_socials_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_socials_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_socials_stream_cursor_value_input"]: {
		access_token?: string,
	created_at?: GraphQLTypes["timestamptz"],
	expired_at?: GraphQLTypes["timestamptz"],
	expires_in?: GraphQLTypes["numeric"],
	id?: number,
	provider?: string,
	refresh_token?: string,
	scope?: string,
	token_type?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_socials_sum_fields"]: {
	__typename: "voyce_user_socials_sum_fields",
	expires_in?: GraphQLTypes["numeric"],
	id?: number,
	user_id?: number
};
	/** update columns of table "voyce.user_socials" */
["voyce_user_socials_update_column"]: voyce_user_socials_update_column;
	["voyce_user_socials_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_socials_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_socials_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_socials_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_socials_var_pop_fields"]: {
	__typename: "voyce_user_socials_var_pop_fields",
	expires_in?: number,
	id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_socials_var_samp_fields"]: {
	__typename: "voyce_user_socials_var_samp_fields",
	expires_in?: number,
	id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_socials_variance_fields"]: {
	__typename: "voyce_user_socials_variance_fields",
	expires_in?: number,
	id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.user_soft_deleted" */
["voyce_user_soft_deleted"]: {
	__typename: "voyce_user_soft_deleted",
	about?: string,
	avatar?: string,
	banner?: string,
	created_at?: GraphQLTypes["timestamptz"],
	deleted_at?: GraphQLTypes["timestamptz"],
	email?: string,
	first_name?: string,
	id: number,
	is_deleted?: boolean,
	last_name?: string,
	nickname?: string,
	social_discord?: string,
	social_fb?: string,
	social_instagram?: string,
	social_tiktok?: string,
	social_twitter?: string,
	/** An object relationship */
	user?: GraphQLTypes["voyce_users"],
	user_id: number,
	username?: string
};
	/** aggregated selection of "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_aggregate"]: {
	__typename: "voyce_user_soft_deleted_aggregate",
	aggregate?: GraphQLTypes["voyce_user_soft_deleted_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_soft_deleted"]>
};
	/** aggregate fields of "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_aggregate_fields"]: {
	__typename: "voyce_user_soft_deleted_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_soft_deleted_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_soft_deleted_max_fields"],
	min?: GraphQLTypes["voyce_user_soft_deleted_min_fields"],
	stddev?: GraphQLTypes["voyce_user_soft_deleted_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_soft_deleted_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_soft_deleted_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_soft_deleted_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_soft_deleted_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_soft_deleted_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_soft_deleted_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_soft_deleted_avg_fields"]: {
	__typename: "voyce_user_soft_deleted_avg_fields",
	id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_soft_deleted". All fields are combined with a logical 'AND'. */
["voyce_user_soft_deleted_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_soft_deleted_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_soft_deleted_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_soft_deleted_bool_exp"]>,
	about?: GraphQLTypes["String_comparison_exp"],
	avatar?: GraphQLTypes["String_comparison_exp"],
	banner?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	deleted_at?: GraphQLTypes["timestamptz_comparison_exp"],
	email?: GraphQLTypes["String_comparison_exp"],
	first_name?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_deleted?: GraphQLTypes["Boolean_comparison_exp"],
	last_name?: GraphQLTypes["String_comparison_exp"],
	nickname?: GraphQLTypes["String_comparison_exp"],
	social_discord?: GraphQLTypes["String_comparison_exp"],
	social_fb?: GraphQLTypes["String_comparison_exp"],
	social_instagram?: GraphQLTypes["String_comparison_exp"],
	social_tiktok?: GraphQLTypes["String_comparison_exp"],
	social_twitter?: GraphQLTypes["String_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	username?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_constraint"]: voyce_user_soft_deleted_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_inc_input"]: {
		id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_insert_input"]: {
		about?: string,
	avatar?: string,
	banner?: string,
	created_at?: GraphQLTypes["timestamptz"],
	deleted_at?: GraphQLTypes["timestamptz"],
	email?: string,
	first_name?: string,
	id?: number,
	is_deleted?: boolean,
	last_name?: string,
	nickname?: string,
	social_discord?: string,
	social_fb?: string,
	social_instagram?: string,
	social_tiktok?: string,
	social_twitter?: string,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number,
	username?: string
};
	/** aggregate max on columns */
["voyce_user_soft_deleted_max_fields"]: {
	__typename: "voyce_user_soft_deleted_max_fields",
	about?: string,
	avatar?: string,
	banner?: string,
	created_at?: GraphQLTypes["timestamptz"],
	deleted_at?: GraphQLTypes["timestamptz"],
	email?: string,
	first_name?: string,
	id?: number,
	last_name?: string,
	nickname?: string,
	social_discord?: string,
	social_fb?: string,
	social_instagram?: string,
	social_tiktok?: string,
	social_twitter?: string,
	user_id?: number,
	username?: string
};
	/** aggregate min on columns */
["voyce_user_soft_deleted_min_fields"]: {
	__typename: "voyce_user_soft_deleted_min_fields",
	about?: string,
	avatar?: string,
	banner?: string,
	created_at?: GraphQLTypes["timestamptz"],
	deleted_at?: GraphQLTypes["timestamptz"],
	email?: string,
	first_name?: string,
	id?: number,
	last_name?: string,
	nickname?: string,
	social_discord?: string,
	social_fb?: string,
	social_instagram?: string,
	social_tiktok?: string,
	social_twitter?: string,
	user_id?: number,
	username?: string
};
	/** response of any mutation on the table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_mutation_response"]: {
	__typename: "voyce_user_soft_deleted_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_soft_deleted"]>
};
	/** on_conflict condition type for table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_soft_deleted_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_soft_deleted_update_column"]>,
	where?: GraphQLTypes["voyce_user_soft_deleted_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_soft_deleted". */
["voyce_user_soft_deleted_order_by"]: {
		about?: GraphQLTypes["order_by"],
	avatar?: GraphQLTypes["order_by"],
	banner?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	deleted_at?: GraphQLTypes["order_by"],
	email?: GraphQLTypes["order_by"],
	first_name?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_deleted?: GraphQLTypes["order_by"],
	last_name?: GraphQLTypes["order_by"],
	nickname?: GraphQLTypes["order_by"],
	social_discord?: GraphQLTypes["order_by"],
	social_fb?: GraphQLTypes["order_by"],
	social_instagram?: GraphQLTypes["order_by"],
	social_tiktok?: GraphQLTypes["order_by"],
	social_twitter?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"],
	username?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_soft_deleted */
["voyce_user_soft_deleted_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_select_column"]: voyce_user_soft_deleted_select_column;
	/** input type for updating data in table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_set_input"]: {
		about?: string,
	avatar?: string,
	banner?: string,
	created_at?: GraphQLTypes["timestamptz"],
	deleted_at?: GraphQLTypes["timestamptz"],
	email?: string,
	first_name?: string,
	id?: number,
	is_deleted?: boolean,
	last_name?: string,
	nickname?: string,
	social_discord?: string,
	social_fb?: string,
	social_instagram?: string,
	social_tiktok?: string,
	social_twitter?: string,
	user_id?: number,
	username?: string
};
	/** aggregate stddev on columns */
["voyce_user_soft_deleted_stddev_fields"]: {
	__typename: "voyce_user_soft_deleted_stddev_fields",
	id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_soft_deleted_stddev_pop_fields"]: {
	__typename: "voyce_user_soft_deleted_stddev_pop_fields",
	id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_soft_deleted_stddev_samp_fields"]: {
	__typename: "voyce_user_soft_deleted_stddev_samp_fields",
	id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_soft_deleted" */
["voyce_user_soft_deleted_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_soft_deleted_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_soft_deleted_stream_cursor_value_input"]: {
		about?: string,
	avatar?: string,
	banner?: string,
	created_at?: GraphQLTypes["timestamptz"],
	deleted_at?: GraphQLTypes["timestamptz"],
	email?: string,
	first_name?: string,
	id?: number,
	is_deleted?: boolean,
	last_name?: string,
	nickname?: string,
	social_discord?: string,
	social_fb?: string,
	social_instagram?: string,
	social_tiktok?: string,
	social_twitter?: string,
	user_id?: number,
	username?: string
};
	/** aggregate sum on columns */
["voyce_user_soft_deleted_sum_fields"]: {
	__typename: "voyce_user_soft_deleted_sum_fields",
	id?: number,
	user_id?: number
};
	/** update columns of table "voyce.user_soft_deleted" */
["voyce_user_soft_deleted_update_column"]: voyce_user_soft_deleted_update_column;
	["voyce_user_soft_deleted_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_soft_deleted_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_soft_deleted_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_soft_deleted_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_soft_deleted_var_pop_fields"]: {
	__typename: "voyce_user_soft_deleted_var_pop_fields",
	id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_soft_deleted_var_samp_fields"]: {
	__typename: "voyce_user_soft_deleted_var_samp_fields",
	id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_soft_deleted_variance_fields"]: {
	__typename: "voyce_user_soft_deleted_variance_fields",
	id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.user_vtags" */
["voyce_user_vtags"]: {
	__typename: "voyce_user_vtags",
	id: number,
	user_id: number,
	vtag: string,
	vtag_v2?: string
};
	/** aggregated selection of "voyce.user_vtags" */
["voyce_user_vtags_aggregate"]: {
	__typename: "voyce_user_vtags_aggregate",
	aggregate?: GraphQLTypes["voyce_user_vtags_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_vtags"]>
};
	/** aggregate fields of "voyce.user_vtags" */
["voyce_user_vtags_aggregate_fields"]: {
	__typename: "voyce_user_vtags_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_vtags_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_vtags_max_fields"],
	min?: GraphQLTypes["voyce_user_vtags_min_fields"],
	stddev?: GraphQLTypes["voyce_user_vtags_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_vtags_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_vtags_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_vtags_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_vtags_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_vtags_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_vtags_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_vtags_avg_fields"]: {
	__typename: "voyce_user_vtags_avg_fields",
	id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_vtags". All fields are combined with a logical 'AND'. */
["voyce_user_vtags_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_vtags_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_vtags_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_vtags_bool_exp"]>,
	id?: GraphQLTypes["Int_comparison_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	vtag?: GraphQLTypes["String_comparison_exp"],
	vtag_v2?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_vtags" */
["voyce_user_vtags_constraint"]: voyce_user_vtags_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_vtags" */
["voyce_user_vtags_inc_input"]: {
		id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_vtags" */
["voyce_user_vtags_insert_input"]: {
		id?: number,
	user_id?: number,
	vtag?: string,
	vtag_v2?: string
};
	/** aggregate max on columns */
["voyce_user_vtags_max_fields"]: {
	__typename: "voyce_user_vtags_max_fields",
	id?: number,
	user_id?: number,
	vtag?: string,
	vtag_v2?: string
};
	/** aggregate min on columns */
["voyce_user_vtags_min_fields"]: {
	__typename: "voyce_user_vtags_min_fields",
	id?: number,
	user_id?: number,
	vtag?: string,
	vtag_v2?: string
};
	/** response of any mutation on the table "voyce.user_vtags" */
["voyce_user_vtags_mutation_response"]: {
	__typename: "voyce_user_vtags_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_vtags"]>
};
	/** input type for inserting object relation for remote table "voyce.user_vtags" */
["voyce_user_vtags_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_user_vtags_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_user_vtags_on_conflict"]
};
	/** on_conflict condition type for table "voyce.user_vtags" */
["voyce_user_vtags_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_vtags_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_vtags_update_column"]>,
	where?: GraphQLTypes["voyce_user_vtags_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_vtags". */
["voyce_user_vtags_order_by"]: {
		id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	vtag?: GraphQLTypes["order_by"],
	vtag_v2?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_vtags */
["voyce_user_vtags_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_vtags" */
["voyce_user_vtags_select_column"]: voyce_user_vtags_select_column;
	/** input type for updating data in table "voyce.user_vtags" */
["voyce_user_vtags_set_input"]: {
		id?: number,
	user_id?: number,
	vtag?: string,
	vtag_v2?: string
};
	/** aggregate stddev on columns */
["voyce_user_vtags_stddev_fields"]: {
	__typename: "voyce_user_vtags_stddev_fields",
	id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_vtags_stddev_pop_fields"]: {
	__typename: "voyce_user_vtags_stddev_pop_fields",
	id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_vtags_stddev_samp_fields"]: {
	__typename: "voyce_user_vtags_stddev_samp_fields",
	id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_vtags" */
["voyce_user_vtags_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_vtags_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_vtags_stream_cursor_value_input"]: {
		id?: number,
	user_id?: number,
	vtag?: string,
	vtag_v2?: string
};
	/** aggregate sum on columns */
["voyce_user_vtags_sum_fields"]: {
	__typename: "voyce_user_vtags_sum_fields",
	id?: number,
	user_id?: number
};
	/** update columns of table "voyce.user_vtags" */
["voyce_user_vtags_update_column"]: voyce_user_vtags_update_column;
	["voyce_user_vtags_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_vtags_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_vtags_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_vtags_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_vtags_var_pop_fields"]: {
	__typename: "voyce_user_vtags_var_pop_fields",
	id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_vtags_var_samp_fields"]: {
	__typename: "voyce_user_vtags_var_samp_fields",
	id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_vtags_variance_fields"]: {
	__typename: "voyce_user_vtags_variance_fields",
	id?: number,
	user_id?: number
};
	/** table to describe quests user is taking part in */
["voyce_user_wins"]: {
	__typename: "voyce_user_wins",
	claimed?: boolean,
	created_at: GraphQLTypes["timestamp"],
	id: number,
	linked_id?: number,
	source?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"],
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.user_wins" */
["voyce_user_wins_aggregate"]: {
	__typename: "voyce_user_wins_aggregate",
	aggregate?: GraphQLTypes["voyce_user_wins_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_user_wins"]>
};
	/** aggregate fields of "voyce.user_wins" */
["voyce_user_wins_aggregate_fields"]: {
	__typename: "voyce_user_wins_aggregate_fields",
	avg?: GraphQLTypes["voyce_user_wins_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_user_wins_max_fields"],
	min?: GraphQLTypes["voyce_user_wins_min_fields"],
	stddev?: GraphQLTypes["voyce_user_wins_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_user_wins_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_user_wins_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_user_wins_sum_fields"],
	var_pop?: GraphQLTypes["voyce_user_wins_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_user_wins_var_samp_fields"],
	variance?: GraphQLTypes["voyce_user_wins_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_user_wins_avg_fields"]: {
	__typename: "voyce_user_wins_avg_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.user_wins". All fields are combined with a logical 'AND'. */
["voyce_user_wins_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_user_wins_bool_exp"]>,
	_not?: GraphQLTypes["voyce_user_wins_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_user_wins_bool_exp"]>,
	claimed?: GraphQLTypes["Boolean_comparison_exp"],
	created_at?: GraphQLTypes["timestamp_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	linked_id?: GraphQLTypes["Int_comparison_exp"],
	source?: GraphQLTypes["String_comparison_exp"],
	title?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamp_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.user_wins" */
["voyce_user_wins_constraint"]: voyce_user_wins_constraint;
	/** input type for incrementing numeric columns in table "voyce.user_wins" */
["voyce_user_wins_inc_input"]: {
		id?: number,
	linked_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.user_wins" */
["voyce_user_wins_insert_input"]: {
		claimed?: boolean,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	linked_id?: number,
	source?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"],
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_user_wins_max_fields"]: {
	__typename: "voyce_user_wins_max_fields",
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	linked_id?: number,
	source?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number
};
	/** aggregate min on columns */
["voyce_user_wins_min_fields"]: {
	__typename: "voyce_user_wins_min_fields",
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	linked_id?: number,
	source?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number
};
	/** response of any mutation on the table "voyce.user_wins" */
["voyce_user_wins_mutation_response"]: {
	__typename: "voyce_user_wins_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_user_wins"]>
};
	/** on_conflict condition type for table "voyce.user_wins" */
["voyce_user_wins_on_conflict"]: {
		constraint: GraphQLTypes["voyce_user_wins_constraint"],
	update_columns: Array<GraphQLTypes["voyce_user_wins_update_column"]>,
	where?: GraphQLTypes["voyce_user_wins_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.user_wins". */
["voyce_user_wins_order_by"]: {
		claimed?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	linked_id?: GraphQLTypes["order_by"],
	source?: GraphQLTypes["order_by"],
	title?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.user_wins */
["voyce_user_wins_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.user_wins" */
["voyce_user_wins_select_column"]: voyce_user_wins_select_column;
	/** input type for updating data in table "voyce.user_wins" */
["voyce_user_wins_set_input"]: {
		claimed?: boolean,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	linked_id?: number,
	source?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_user_wins_stddev_fields"]: {
	__typename: "voyce_user_wins_stddev_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_user_wins_stddev_pop_fields"]: {
	__typename: "voyce_user_wins_stddev_pop_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_user_wins_stddev_samp_fields"]: {
	__typename: "voyce_user_wins_stddev_samp_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** Streaming cursor of the table "voyce_user_wins" */
["voyce_user_wins_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_user_wins_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_user_wins_stream_cursor_value_input"]: {
		claimed?: boolean,
	created_at?: GraphQLTypes["timestamp"],
	id?: number,
	linked_id?: number,
	source?: string,
	title?: string,
	updated_at?: GraphQLTypes["timestamp"],
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_user_wins_sum_fields"]: {
	__typename: "voyce_user_wins_sum_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** update columns of table "voyce.user_wins" */
["voyce_user_wins_update_column"]: voyce_user_wins_update_column;
	["voyce_user_wins_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_user_wins_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_user_wins_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_user_wins_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_user_wins_var_pop_fields"]: {
	__typename: "voyce_user_wins_var_pop_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** aggregate var_samp on columns */
["voyce_user_wins_var_samp_fields"]: {
	__typename: "voyce_user_wins_var_samp_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** aggregate variance on columns */
["voyce_user_wins_variance_fields"]: {
	__typename: "voyce_user_wins_variance_fields",
	id?: number,
	linked_id?: number,
	user_id?: number
};
	/** columns and relationships of "voyce.users" */
["voyce_users"]: {
	__typename: "voyce_users",
	about?: string,
	account_disabled: boolean,
	ad_share?: number,
	/** An object relationship */
	admin_role?: GraphQLTypes["voyce_admin_roles"],
	admin_role_id?: number,
	/** An object relationship */
	author_profile?: GraphQLTypes["voyce_author_profiles"],
	/** An array relationship */
	author_profiles: Array<GraphQLTypes["voyce_author_profiles"]>,
	/** An aggregate relationship */
	author_profiles_aggregate: GraphQLTypes["voyce_author_profiles_aggregate"],
	avatar?: string,
	avatar_bgcolor?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	/** An array relationship */
	chapter_comments: Array<GraphQLTypes["voyce_chapter_comments"]>,
	/** An aggregate relationship */
	chapter_comments_aggregate: GraphQLTypes["voyce_chapter_comments_aggregate"],
	/** An array relationship */
	chapter_comments_reactions: Array<GraphQLTypes["voyce_chapter_comments_reaction"]>,
	/** An aggregate relationship */
	chapter_comments_reactions_aggregate: GraphQLTypes["voyce_chapter_comments_reaction_aggregate"],
	cognito_id?: string,
	cognito_provider?: string,
	cognito_username?: string,
	country_id?: number,
	creator_type_preferences?: GraphQLTypes["jsonb"],
	dark_mode?: GraphQLTypes["smallint"],
	date_create: GraphQLTypes["timestamp"],
	delete_code?: string,
	email: string,
	email_verified: boolean,
	/** mobile or web or null */
	email_verified_source?: string,
	fcm_token?: string,
	fcm_tokens?: GraphQLTypes["jsonb"],
	first_name?: string,
	/** An array relationship */
	following: Array<GraphQLTypes["voyce_series_subscription"]>,
	/** An aggregate relationship */
	following_aggregate: GraphQLTypes["voyce_series_subscription_aggregate"],
	frame?: string,
	gender?: string,
	guest_gtag?: string,
	/** An array relationship */
	hot_panels: Array<GraphQLTypes["voyce_series_hot_panels"]>,
	/** An aggregate relationship */
	hot_panels_aggregate: GraphQLTypes["voyce_series_hot_panels_aggregate"],
	id: number,
	is_account_active?: GraphQLTypes["smallint"],
	is_approved: boolean,
	is_onboarded?: number,
	is_publisher_verified: boolean,
	klaviyo_id?: string,
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	linked_account_status?: string,
	new_email_code?: string,
	newsletter_subscribe: boolean,
	nickname?: string,
	/** An array relationship */
	notification_series_settings: Array<GraphQLTypes["voyce_notification_series_settings"]>,
	/** An aggregate relationship */
	notification_series_settings_aggregate: GraphQLTypes["voyce_notification_series_settings_aggregate"],
	/** An object relationship */
	notification_setting?: GraphQLTypes["voyce_notification_settings"],
	/** An array relationship */
	notifications: Array<GraphQLTypes["voyce_notifications"]>,
	/** An aggregate relationship */
	notifications_aggregate: GraphQLTypes["voyce_notifications_aggregate"],
	/** An array relationship */
	panels_comments: Array<GraphQLTypes["voyce_panels_comments"]>,
	/** An aggregate relationship */
	panels_comments_aggregate: GraphQLTypes["voyce_panels_comments_aggregate"],
	/** An array relationship */
	panels_reactions: Array<GraphQLTypes["voyce_panels_reactions"]>,
	/** An aggregate relationship */
	panels_reactions_aggregate: GraphQLTypes["voyce_panels_reactions_aggregate"],
	password: string,
	password_secure?: string,
	/** An array relationship */
	payments_to: Array<GraphQLTypes["voyce_payments_to_creators"]>,
	/** An aggregate relationship */
	payments_to_aggregate: GraphQLTypes["voyce_payments_to_creators_aggregate"],
	/** An array relationship */
	polls: Array<GraphQLTypes["voyce_polls"]>,
	/** An aggregate relationship */
	polls_aggregate: GraphQLTypes["voyce_polls_aggregate"],
	/** An array relationship */
	polls_comments: Array<GraphQLTypes["voyce_polls_comments"]>,
	/** An aggregate relationship */
	polls_comments_aggregate: GraphQLTypes["voyce_polls_comments_aggregate"],
	/** An array relationship */
	polls_reactions: Array<GraphQLTypes["voyce_polls_reactions"]>,
	/** An aggregate relationship */
	polls_reactions_aggregate: GraphQLTypes["voyce_polls_reactions_aggregate"],
	/** An array relationship */
	polls_votes: Array<GraphQLTypes["voyce_polls_votes"]>,
	/** An aggregate relationship */
	polls_votes_aggregate: GraphQLTypes["voyce_polls_votes_aggregate"],
	/** An array relationship */
	posts: Array<GraphQLTypes["voyce_posts"]>,
	/** An aggregate relationship */
	posts_aggregate: GraphQLTypes["voyce_posts_aggregate"],
	/** An array relationship */
	posts_comments: Array<GraphQLTypes["voyce_posts_comments"]>,
	/** An aggregate relationship */
	posts_comments_aggregate: GraphQLTypes["voyce_posts_comments_aggregate"],
	/** An array relationship */
	posts_reactions: Array<GraphQLTypes["voyce_posts_reactions"]>,
	/** An aggregate relationship */
	posts_reactions_aggregate: GraphQLTypes["voyce_posts_reactions_aggregate"],
	/** An object relationship */
	private_fields?: GraphQLTypes["voyce_users_private"],
	publisher_verification_code?: string,
	quest_onboarded: boolean,
	remember_code?: string,
	reset_code?: string,
	reset_code_created_at?: GraphQLTypes["timestamptz"],
	reset_otp?: string,
	/** An object relationship */
	role?: GraphQLTypes["voyce_user_roles"],
	role_id: number,
	/** An object relationship */
	series_subscription_count?: GraphQLTypes["voyce_user_series_subscription_count"],
	/** mobile or web or null */
	signup_source?: string,
	social_discord?: string,
	social_fb?: string,
	social_instagram?: string,
	social_tiktok?: string,
	social_twitter?: string,
	/** An array relationship */
	sponsorships: Array<GraphQLTypes["voyce_sponsorships"]>,
	/** An array relationship */
	sponsorshipsBySponsoredUserId: Array<GraphQLTypes["voyce_sponsorships"]>,
	/** An aggregate relationship */
	sponsorshipsBySponsoredUserId_aggregate: GraphQLTypes["voyce_sponsorships_aggregate"],
	/** An aggregate relationship */
	sponsorships_aggregate: GraphQLTypes["voyce_sponsorships_aggregate"],
	/** An object relationship */
	stripe_setup?: GraphQLTypes["voyce_stripe_payouts_setup"],
	unsubscribe: boolean,
	updated_at?: GraphQLTypes["timestamptz"],
	user_type_preference?: string,
	username: string,
	/** An array relationship */
	usersFollowersByFollowingUid: Array<GraphQLTypes["voyce_users_followers"]>,
	/** An aggregate relationship */
	usersFollowersByFollowingUid_aggregate: GraphQLTypes["voyce_users_followers_aggregate"],
	/** An array relationship */
	users_followers: Array<GraphQLTypes["voyce_users_followers"]>,
	/** An aggregate relationship */
	users_followers_aggregate: GraphQLTypes["voyce_users_followers_aggregate"],
	/** An array relationship */
	users_series_junctions: Array<GraphQLTypes["voyce_users_series_junction"]>,
	/** An aggregate relationship */
	users_series_junctions_aggregate: GraphQLTypes["voyce_users_series_junction_aggregate"],
	vmuser: boolean,
	/** An object relationship */
	vtag?: GraphQLTypes["voyce_user_vtags"]
};
	/** aggregated selection of "voyce.users" */
["voyce_users_aggregate"]: {
	__typename: "voyce_users_aggregate",
	aggregate?: GraphQLTypes["voyce_users_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_users"]>
};
	/** aggregate fields of "voyce.users" */
["voyce_users_aggregate_fields"]: {
	__typename: "voyce_users_aggregate_fields",
	avg?: GraphQLTypes["voyce_users_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_users_max_fields"],
	min?: GraphQLTypes["voyce_users_min_fields"],
	stddev?: GraphQLTypes["voyce_users_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_users_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_users_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_users_sum_fields"],
	var_pop?: GraphQLTypes["voyce_users_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_users_var_samp_fields"],
	variance?: GraphQLTypes["voyce_users_variance_fields"]
};
	/** append existing jsonb value of filtered columns with new jsonb value */
["voyce_users_append_input"]: {
		creator_type_preferences?: GraphQLTypes["jsonb"],
	fcm_tokens?: GraphQLTypes["jsonb"]
};
	/** aggregate avg on columns */
["voyce_users_avg_fields"]: {
	__typename: "voyce_users_avg_fields",
	ad_share?: number,
	admin_role_id?: number,
	country_id?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	is_onboarded?: number,
	role_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.users". All fields are combined with a logical 'AND'. */
["voyce_users_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_users_bool_exp"]>,
	_not?: GraphQLTypes["voyce_users_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_users_bool_exp"]>,
	about?: GraphQLTypes["String_comparison_exp"],
	account_disabled?: GraphQLTypes["Boolean_comparison_exp"],
	ad_share?: GraphQLTypes["Int_comparison_exp"],
	admin_role?: GraphQLTypes["voyce_admin_roles_bool_exp"],
	admin_role_id?: GraphQLTypes["Int_comparison_exp"],
	author_profile?: GraphQLTypes["voyce_author_profiles_bool_exp"],
	author_profiles?: GraphQLTypes["voyce_author_profiles_bool_exp"],
	author_profiles_aggregate?: GraphQLTypes["voyce_author_profiles_aggregate_bool_exp"],
	avatar?: GraphQLTypes["String_comparison_exp"],
	avatar_bgcolor?: GraphQLTypes["String_comparison_exp"],
	banner?: GraphQLTypes["String_comparison_exp"],
	birth_date?: GraphQLTypes["date_comparison_exp"],
	chapter_comments?: GraphQLTypes["voyce_chapter_comments_bool_exp"],
	chapter_comments_aggregate?: GraphQLTypes["voyce_chapter_comments_aggregate_bool_exp"],
	chapter_comments_reactions?: GraphQLTypes["voyce_chapter_comments_reaction_bool_exp"],
	chapter_comments_reactions_aggregate?: GraphQLTypes["voyce_chapter_comments_reaction_aggregate_bool_exp"],
	cognito_id?: GraphQLTypes["String_comparison_exp"],
	cognito_provider?: GraphQLTypes["String_comparison_exp"],
	cognito_username?: GraphQLTypes["String_comparison_exp"],
	country_id?: GraphQLTypes["Int_comparison_exp"],
	creator_type_preferences?: GraphQLTypes["jsonb_comparison_exp"],
	dark_mode?: GraphQLTypes["smallint_comparison_exp"],
	date_create?: GraphQLTypes["timestamp_comparison_exp"],
	delete_code?: GraphQLTypes["String_comparison_exp"],
	email?: GraphQLTypes["String_comparison_exp"],
	email_verified?: GraphQLTypes["Boolean_comparison_exp"],
	email_verified_source?: GraphQLTypes["String_comparison_exp"],
	fcm_token?: GraphQLTypes["String_comparison_exp"],
	fcm_tokens?: GraphQLTypes["jsonb_comparison_exp"],
	first_name?: GraphQLTypes["String_comparison_exp"],
	following?: GraphQLTypes["voyce_series_subscription_bool_exp"],
	following_aggregate?: GraphQLTypes["voyce_series_subscription_aggregate_bool_exp"],
	frame?: GraphQLTypes["String_comparison_exp"],
	gender?: GraphQLTypes["String_comparison_exp"],
	guest_gtag?: GraphQLTypes["String_comparison_exp"],
	hot_panels?: GraphQLTypes["voyce_series_hot_panels_bool_exp"],
	hot_panels_aggregate?: GraphQLTypes["voyce_series_hot_panels_aggregate_bool_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_account_active?: GraphQLTypes["smallint_comparison_exp"],
	is_approved?: GraphQLTypes["Boolean_comparison_exp"],
	is_onboarded?: GraphQLTypes["Int_comparison_exp"],
	is_publisher_verified?: GraphQLTypes["Boolean_comparison_exp"],
	klaviyo_id?: GraphQLTypes["String_comparison_exp"],
	last_login?: GraphQLTypes["timestamp_comparison_exp"],
	last_name?: GraphQLTypes["String_comparison_exp"],
	linked_account_status?: GraphQLTypes["String_comparison_exp"],
	new_email_code?: GraphQLTypes["String_comparison_exp"],
	newsletter_subscribe?: GraphQLTypes["Boolean_comparison_exp"],
	nickname?: GraphQLTypes["String_comparison_exp"],
	notification_series_settings?: GraphQLTypes["voyce_notification_series_settings_bool_exp"],
	notification_series_settings_aggregate?: GraphQLTypes["voyce_notification_series_settings_aggregate_bool_exp"],
	notification_setting?: GraphQLTypes["voyce_notification_settings_bool_exp"],
	notifications?: GraphQLTypes["voyce_notifications_bool_exp"],
	notifications_aggregate?: GraphQLTypes["voyce_notifications_aggregate_bool_exp"],
	panels_comments?: GraphQLTypes["voyce_panels_comments_bool_exp"],
	panels_comments_aggregate?: GraphQLTypes["voyce_panels_comments_aggregate_bool_exp"],
	panels_reactions?: GraphQLTypes["voyce_panels_reactions_bool_exp"],
	panels_reactions_aggregate?: GraphQLTypes["voyce_panels_reactions_aggregate_bool_exp"],
	password?: GraphQLTypes["String_comparison_exp"],
	password_secure?: GraphQLTypes["String_comparison_exp"],
	payments_to?: GraphQLTypes["voyce_payments_to_creators_bool_exp"],
	payments_to_aggregate?: GraphQLTypes["voyce_payments_to_creators_aggregate_bool_exp"],
	polls?: GraphQLTypes["voyce_polls_bool_exp"],
	polls_aggregate?: GraphQLTypes["voyce_polls_aggregate_bool_exp"],
	polls_comments?: GraphQLTypes["voyce_polls_comments_bool_exp"],
	polls_comments_aggregate?: GraphQLTypes["voyce_polls_comments_aggregate_bool_exp"],
	polls_reactions?: GraphQLTypes["voyce_polls_reactions_bool_exp"],
	polls_reactions_aggregate?: GraphQLTypes["voyce_polls_reactions_aggregate_bool_exp"],
	polls_votes?: GraphQLTypes["voyce_polls_votes_bool_exp"],
	polls_votes_aggregate?: GraphQLTypes["voyce_polls_votes_aggregate_bool_exp"],
	posts?: GraphQLTypes["voyce_posts_bool_exp"],
	posts_aggregate?: GraphQLTypes["voyce_posts_aggregate_bool_exp"],
	posts_comments?: GraphQLTypes["voyce_posts_comments_bool_exp"],
	posts_comments_aggregate?: GraphQLTypes["voyce_posts_comments_aggregate_bool_exp"],
	posts_reactions?: GraphQLTypes["voyce_posts_reactions_bool_exp"],
	posts_reactions_aggregate?: GraphQLTypes["voyce_posts_reactions_aggregate_bool_exp"],
	private_fields?: GraphQLTypes["voyce_users_private_bool_exp"],
	publisher_verification_code?: GraphQLTypes["String_comparison_exp"],
	quest_onboarded?: GraphQLTypes["Boolean_comparison_exp"],
	remember_code?: GraphQLTypes["String_comparison_exp"],
	reset_code?: GraphQLTypes["String_comparison_exp"],
	reset_code_created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	reset_otp?: GraphQLTypes["String_comparison_exp"],
	role?: GraphQLTypes["voyce_user_roles_bool_exp"],
	role_id?: GraphQLTypes["Int_comparison_exp"],
	series_subscription_count?: GraphQLTypes["voyce_user_series_subscription_count_bool_exp"],
	signup_source?: GraphQLTypes["String_comparison_exp"],
	social_discord?: GraphQLTypes["String_comparison_exp"],
	social_fb?: GraphQLTypes["String_comparison_exp"],
	social_instagram?: GraphQLTypes["String_comparison_exp"],
	social_tiktok?: GraphQLTypes["String_comparison_exp"],
	social_twitter?: GraphQLTypes["String_comparison_exp"],
	sponsorships?: GraphQLTypes["voyce_sponsorships_bool_exp"],
	sponsorshipsBySponsoredUserId?: GraphQLTypes["voyce_sponsorships_bool_exp"],
	sponsorshipsBySponsoredUserId_aggregate?: GraphQLTypes["voyce_sponsorships_aggregate_bool_exp"],
	sponsorships_aggregate?: GraphQLTypes["voyce_sponsorships_aggregate_bool_exp"],
	stripe_setup?: GraphQLTypes["voyce_stripe_payouts_setup_bool_exp"],
	unsubscribe?: GraphQLTypes["Boolean_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	user_type_preference?: GraphQLTypes["String_comparison_exp"],
	username?: GraphQLTypes["String_comparison_exp"],
	usersFollowersByFollowingUid?: GraphQLTypes["voyce_users_followers_bool_exp"],
	usersFollowersByFollowingUid_aggregate?: GraphQLTypes["voyce_users_followers_aggregate_bool_exp"],
	users_followers?: GraphQLTypes["voyce_users_followers_bool_exp"],
	users_followers_aggregate?: GraphQLTypes["voyce_users_followers_aggregate_bool_exp"],
	users_series_junctions?: GraphQLTypes["voyce_users_series_junction_bool_exp"],
	users_series_junctions_aggregate?: GraphQLTypes["voyce_users_series_junction_aggregate_bool_exp"],
	vmuser?: GraphQLTypes["Boolean_comparison_exp"],
	vtag?: GraphQLTypes["voyce_user_vtags_bool_exp"]
};
	/** unique or primary key constraints on table "voyce.users" */
["voyce_users_constraint"]: voyce_users_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["voyce_users_delete_at_path_input"]: {
		creator_type_preferences?: Array<string>,
	fcm_tokens?: Array<string>
};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["voyce_users_delete_elem_input"]: {
		creator_type_preferences?: number,
	fcm_tokens?: number
};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["voyce_users_delete_key_input"]: {
		creator_type_preferences?: string,
	fcm_tokens?: string
};
	/** columns and relationships of "voyce.users_followers" */
["voyce_users_followers"]: {
	__typename: "voyce_users_followers",
	created_at: GraphQLTypes["timestamptz"],
	follower_uid: number,
	following_uid: number,
	id: number,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	/** An object relationship */
	userByFollowingUid: GraphQLTypes["voyce_users"]
};
	/** aggregated selection of "voyce.users_followers" */
["voyce_users_followers_aggregate"]: {
	__typename: "voyce_users_followers_aggregate",
	aggregate?: GraphQLTypes["voyce_users_followers_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_users_followers"]>
};
	["voyce_users_followers_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_users_followers_aggregate_bool_exp_count"]
};
	["voyce_users_followers_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_users_followers_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_users_followers_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.users_followers" */
["voyce_users_followers_aggregate_fields"]: {
	__typename: "voyce_users_followers_aggregate_fields",
	avg?: GraphQLTypes["voyce_users_followers_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_users_followers_max_fields"],
	min?: GraphQLTypes["voyce_users_followers_min_fields"],
	stddev?: GraphQLTypes["voyce_users_followers_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_users_followers_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_users_followers_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_users_followers_sum_fields"],
	var_pop?: GraphQLTypes["voyce_users_followers_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_users_followers_var_samp_fields"],
	variance?: GraphQLTypes["voyce_users_followers_variance_fields"]
};
	/** order by aggregate values of table "voyce.users_followers" */
["voyce_users_followers_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_users_followers_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_users_followers_max_order_by"],
	min?: GraphQLTypes["voyce_users_followers_min_order_by"],
	stddev?: GraphQLTypes["voyce_users_followers_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_users_followers_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_users_followers_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_users_followers_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_users_followers_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_users_followers_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_users_followers_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.users_followers" */
["voyce_users_followers_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_users_followers_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_users_followers_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_users_followers_avg_fields"]: {
	__typename: "voyce_users_followers_avg_fields",
	follower_uid?: number,
	following_uid?: number,
	id?: number
};
	/** order by avg() on columns of table "voyce.users_followers" */
["voyce_users_followers_avg_order_by"]: {
		follower_uid?: GraphQLTypes["order_by"],
	following_uid?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.users_followers". All fields are combined with a logical 'AND'. */
["voyce_users_followers_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_users_followers_bool_exp"]>,
	_not?: GraphQLTypes["voyce_users_followers_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_users_followers_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	follower_uid?: GraphQLTypes["Int_comparison_exp"],
	following_uid?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	userByFollowingUid?: GraphQLTypes["voyce_users_bool_exp"]
};
	/** unique or primary key constraints on table "voyce.users_followers" */
["voyce_users_followers_constraint"]: voyce_users_followers_constraint;
	/** input type for incrementing numeric columns in table "voyce.users_followers" */
["voyce_users_followers_inc_input"]: {
		follower_uid?: number,
	following_uid?: number,
	id?: number
};
	/** input type for inserting data into table "voyce.users_followers" */
["voyce_users_followers_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	follower_uid?: number,
	following_uid?: number,
	id?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	userByFollowingUid?: GraphQLTypes["voyce_users_obj_rel_insert_input"]
};
	/** aggregate max on columns */
["voyce_users_followers_max_fields"]: {
	__typename: "voyce_users_followers_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	follower_uid?: number,
	following_uid?: number,
	id?: number
};
	/** order by max() on columns of table "voyce.users_followers" */
["voyce_users_followers_max_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	follower_uid?: GraphQLTypes["order_by"],
	following_uid?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_users_followers_min_fields"]: {
	__typename: "voyce_users_followers_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	follower_uid?: number,
	following_uid?: number,
	id?: number
};
	/** order by min() on columns of table "voyce.users_followers" */
["voyce_users_followers_min_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	follower_uid?: GraphQLTypes["order_by"],
	following_uid?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.users_followers" */
["voyce_users_followers_mutation_response"]: {
	__typename: "voyce_users_followers_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_users_followers"]>
};
	/** on_conflict condition type for table "voyce.users_followers" */
["voyce_users_followers_on_conflict"]: {
		constraint: GraphQLTypes["voyce_users_followers_constraint"],
	update_columns: Array<GraphQLTypes["voyce_users_followers_update_column"]>,
	where?: GraphQLTypes["voyce_users_followers_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.users_followers". */
["voyce_users_followers_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	follower_uid?: GraphQLTypes["order_by"],
	following_uid?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	userByFollowingUid?: GraphQLTypes["voyce_users_order_by"]
};
	/** primary key columns input for table: voyce.users_followers */
["voyce_users_followers_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.users_followers" */
["voyce_users_followers_select_column"]: voyce_users_followers_select_column;
	/** input type for updating data in table "voyce.users_followers" */
["voyce_users_followers_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	follower_uid?: number,
	following_uid?: number,
	id?: number
};
	/** aggregate stddev on columns */
["voyce_users_followers_stddev_fields"]: {
	__typename: "voyce_users_followers_stddev_fields",
	follower_uid?: number,
	following_uid?: number,
	id?: number
};
	/** order by stddev() on columns of table "voyce.users_followers" */
["voyce_users_followers_stddev_order_by"]: {
		follower_uid?: GraphQLTypes["order_by"],
	following_uid?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_users_followers_stddev_pop_fields"]: {
	__typename: "voyce_users_followers_stddev_pop_fields",
	follower_uid?: number,
	following_uid?: number,
	id?: number
};
	/** order by stddev_pop() on columns of table "voyce.users_followers" */
["voyce_users_followers_stddev_pop_order_by"]: {
		follower_uid?: GraphQLTypes["order_by"],
	following_uid?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_users_followers_stddev_samp_fields"]: {
	__typename: "voyce_users_followers_stddev_samp_fields",
	follower_uid?: number,
	following_uid?: number,
	id?: number
};
	/** order by stddev_samp() on columns of table "voyce.users_followers" */
["voyce_users_followers_stddev_samp_order_by"]: {
		follower_uid?: GraphQLTypes["order_by"],
	following_uid?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_users_followers" */
["voyce_users_followers_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_users_followers_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_users_followers_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	follower_uid?: number,
	following_uid?: number,
	id?: number
};
	/** aggregate sum on columns */
["voyce_users_followers_sum_fields"]: {
	__typename: "voyce_users_followers_sum_fields",
	follower_uid?: number,
	following_uid?: number,
	id?: number
};
	/** order by sum() on columns of table "voyce.users_followers" */
["voyce_users_followers_sum_order_by"]: {
		follower_uid?: GraphQLTypes["order_by"],
	following_uid?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.users_followers" */
["voyce_users_followers_update_column"]: voyce_users_followers_update_column;
	["voyce_users_followers_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_users_followers_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_users_followers_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_users_followers_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_users_followers_var_pop_fields"]: {
	__typename: "voyce_users_followers_var_pop_fields",
	follower_uid?: number,
	following_uid?: number,
	id?: number
};
	/** order by var_pop() on columns of table "voyce.users_followers" */
["voyce_users_followers_var_pop_order_by"]: {
		follower_uid?: GraphQLTypes["order_by"],
	following_uid?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_users_followers_var_samp_fields"]: {
	__typename: "voyce_users_followers_var_samp_fields",
	follower_uid?: number,
	following_uid?: number,
	id?: number
};
	/** order by var_samp() on columns of table "voyce.users_followers" */
["voyce_users_followers_var_samp_order_by"]: {
		follower_uid?: GraphQLTypes["order_by"],
	following_uid?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_users_followers_variance_fields"]: {
	__typename: "voyce_users_followers_variance_fields",
	follower_uid?: number,
	following_uid?: number,
	id?: number
};
	/** order by variance() on columns of table "voyce.users_followers" */
["voyce_users_followers_variance_order_by"]: {
		follower_uid?: GraphQLTypes["order_by"],
	following_uid?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"]
};
	/** input type for incrementing numeric columns in table "voyce.users" */
["voyce_users_inc_input"]: {
		ad_share?: number,
	admin_role_id?: number,
	country_id?: number,
	dark_mode?: GraphQLTypes["smallint"],
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	is_onboarded?: number,
	role_id?: number
};
	/** input type for inserting data into table "voyce.users" */
["voyce_users_insert_input"]: {
		about?: string,
	account_disabled?: boolean,
	ad_share?: number,
	admin_role?: GraphQLTypes["voyce_admin_roles_obj_rel_insert_input"],
	admin_role_id?: number,
	author_profile?: GraphQLTypes["voyce_author_profiles_obj_rel_insert_input"],
	author_profiles?: GraphQLTypes["voyce_author_profiles_arr_rel_insert_input"],
	avatar?: string,
	avatar_bgcolor?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	chapter_comments?: GraphQLTypes["voyce_chapter_comments_arr_rel_insert_input"],
	chapter_comments_reactions?: GraphQLTypes["voyce_chapter_comments_reaction_arr_rel_insert_input"],
	cognito_id?: string,
	cognito_provider?: string,
	cognito_username?: string,
	country_id?: number,
	creator_type_preferences?: GraphQLTypes["jsonb"],
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	delete_code?: string,
	email?: string,
	email_verified?: boolean,
	/** mobile or web or null */
	email_verified_source?: string,
	fcm_token?: string,
	fcm_tokens?: GraphQLTypes["jsonb"],
	first_name?: string,
	following?: GraphQLTypes["voyce_series_subscription_arr_rel_insert_input"],
	frame?: string,
	gender?: string,
	guest_gtag?: string,
	hot_panels?: GraphQLTypes["voyce_series_hot_panels_arr_rel_insert_input"],
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	is_approved?: boolean,
	is_onboarded?: number,
	is_publisher_verified?: boolean,
	klaviyo_id?: string,
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	linked_account_status?: string,
	new_email_code?: string,
	newsletter_subscribe?: boolean,
	nickname?: string,
	notification_series_settings?: GraphQLTypes["voyce_notification_series_settings_arr_rel_insert_input"],
	notification_setting?: GraphQLTypes["voyce_notification_settings_obj_rel_insert_input"],
	notifications?: GraphQLTypes["voyce_notifications_arr_rel_insert_input"],
	panels_comments?: GraphQLTypes["voyce_panels_comments_arr_rel_insert_input"],
	panels_reactions?: GraphQLTypes["voyce_panels_reactions_arr_rel_insert_input"],
	password?: string,
	password_secure?: string,
	payments_to?: GraphQLTypes["voyce_payments_to_creators_arr_rel_insert_input"],
	polls?: GraphQLTypes["voyce_polls_arr_rel_insert_input"],
	polls_comments?: GraphQLTypes["voyce_polls_comments_arr_rel_insert_input"],
	polls_reactions?: GraphQLTypes["voyce_polls_reactions_arr_rel_insert_input"],
	polls_votes?: GraphQLTypes["voyce_polls_votes_arr_rel_insert_input"],
	posts?: GraphQLTypes["voyce_posts_arr_rel_insert_input"],
	posts_comments?: GraphQLTypes["voyce_posts_comments_arr_rel_insert_input"],
	posts_reactions?: GraphQLTypes["voyce_posts_reactions_arr_rel_insert_input"],
	private_fields?: GraphQLTypes["voyce_users_private_obj_rel_insert_input"],
	publisher_verification_code?: string,
	quest_onboarded?: boolean,
	remember_code?: string,
	reset_code?: string,
	reset_code_created_at?: GraphQLTypes["timestamptz"],
	reset_otp?: string,
	role?: GraphQLTypes["voyce_user_roles_obj_rel_insert_input"],
	role_id?: number,
	series_subscription_count?: GraphQLTypes["voyce_user_series_subscription_count_obj_rel_insert_input"],
	/** mobile or web or null */
	signup_source?: string,
	social_discord?: string,
	social_fb?: string,
	social_instagram?: string,
	social_tiktok?: string,
	social_twitter?: string,
	sponsorships?: GraphQLTypes["voyce_sponsorships_arr_rel_insert_input"],
	sponsorshipsBySponsoredUserId?: GraphQLTypes["voyce_sponsorships_arr_rel_insert_input"],
	stripe_setup?: GraphQLTypes["voyce_stripe_payouts_setup_obj_rel_insert_input"],
	unsubscribe?: boolean,
	updated_at?: GraphQLTypes["timestamptz"],
	user_type_preference?: string,
	username?: string,
	usersFollowersByFollowingUid?: GraphQLTypes["voyce_users_followers_arr_rel_insert_input"],
	users_followers?: GraphQLTypes["voyce_users_followers_arr_rel_insert_input"],
	users_series_junctions?: GraphQLTypes["voyce_users_series_junction_arr_rel_insert_input"],
	vmuser?: boolean,
	vtag?: GraphQLTypes["voyce_user_vtags_obj_rel_insert_input"]
};
	/** aggregate max on columns */
["voyce_users_max_fields"]: {
	__typename: "voyce_users_max_fields",
	about?: string,
	ad_share?: number,
	admin_role_id?: number,
	avatar?: string,
	avatar_bgcolor?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	cognito_id?: string,
	cognito_provider?: string,
	cognito_username?: string,
	country_id?: number,
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	delete_code?: string,
	email?: string,
	/** mobile or web or null */
	email_verified_source?: string,
	fcm_token?: string,
	first_name?: string,
	frame?: string,
	gender?: string,
	guest_gtag?: string,
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	is_onboarded?: number,
	klaviyo_id?: string,
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	linked_account_status?: string,
	new_email_code?: string,
	nickname?: string,
	password?: string,
	password_secure?: string,
	publisher_verification_code?: string,
	remember_code?: string,
	reset_code?: string,
	reset_code_created_at?: GraphQLTypes["timestamptz"],
	reset_otp?: string,
	role_id?: number,
	/** mobile or web or null */
	signup_source?: string,
	social_discord?: string,
	social_fb?: string,
	social_instagram?: string,
	social_tiktok?: string,
	social_twitter?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_type_preference?: string,
	username?: string
};
	/** aggregate min on columns */
["voyce_users_min_fields"]: {
	__typename: "voyce_users_min_fields",
	about?: string,
	ad_share?: number,
	admin_role_id?: number,
	avatar?: string,
	avatar_bgcolor?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	cognito_id?: string,
	cognito_provider?: string,
	cognito_username?: string,
	country_id?: number,
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	delete_code?: string,
	email?: string,
	/** mobile or web or null */
	email_verified_source?: string,
	fcm_token?: string,
	first_name?: string,
	frame?: string,
	gender?: string,
	guest_gtag?: string,
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	is_onboarded?: number,
	klaviyo_id?: string,
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	linked_account_status?: string,
	new_email_code?: string,
	nickname?: string,
	password?: string,
	password_secure?: string,
	publisher_verification_code?: string,
	remember_code?: string,
	reset_code?: string,
	reset_code_created_at?: GraphQLTypes["timestamptz"],
	reset_otp?: string,
	role_id?: number,
	/** mobile or web or null */
	signup_source?: string,
	social_discord?: string,
	social_fb?: string,
	social_instagram?: string,
	social_tiktok?: string,
	social_twitter?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	user_type_preference?: string,
	username?: string
};
	/** response of any mutation on the table "voyce.users" */
["voyce_users_mutation_response"]: {
	__typename: "voyce_users_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_users"]>
};
	/** input type for inserting object relation for remote table "voyce.users" */
["voyce_users_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_users_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_users_on_conflict"]
};
	/** on_conflict condition type for table "voyce.users" */
["voyce_users_on_conflict"]: {
		constraint: GraphQLTypes["voyce_users_constraint"],
	update_columns: Array<GraphQLTypes["voyce_users_update_column"]>,
	where?: GraphQLTypes["voyce_users_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.users". */
["voyce_users_order_by"]: {
		about?: GraphQLTypes["order_by"],
	account_disabled?: GraphQLTypes["order_by"],
	ad_share?: GraphQLTypes["order_by"],
	admin_role?: GraphQLTypes["voyce_admin_roles_order_by"],
	admin_role_id?: GraphQLTypes["order_by"],
	author_profile?: GraphQLTypes["voyce_author_profiles_order_by"],
	author_profiles_aggregate?: GraphQLTypes["voyce_author_profiles_aggregate_order_by"],
	avatar?: GraphQLTypes["order_by"],
	avatar_bgcolor?: GraphQLTypes["order_by"],
	banner?: GraphQLTypes["order_by"],
	birth_date?: GraphQLTypes["order_by"],
	chapter_comments_aggregate?: GraphQLTypes["voyce_chapter_comments_aggregate_order_by"],
	chapter_comments_reactions_aggregate?: GraphQLTypes["voyce_chapter_comments_reaction_aggregate_order_by"],
	cognito_id?: GraphQLTypes["order_by"],
	cognito_provider?: GraphQLTypes["order_by"],
	cognito_username?: GraphQLTypes["order_by"],
	country_id?: GraphQLTypes["order_by"],
	creator_type_preferences?: GraphQLTypes["order_by"],
	dark_mode?: GraphQLTypes["order_by"],
	date_create?: GraphQLTypes["order_by"],
	delete_code?: GraphQLTypes["order_by"],
	email?: GraphQLTypes["order_by"],
	email_verified?: GraphQLTypes["order_by"],
	email_verified_source?: GraphQLTypes["order_by"],
	fcm_token?: GraphQLTypes["order_by"],
	fcm_tokens?: GraphQLTypes["order_by"],
	first_name?: GraphQLTypes["order_by"],
	following_aggregate?: GraphQLTypes["voyce_series_subscription_aggregate_order_by"],
	frame?: GraphQLTypes["order_by"],
	gender?: GraphQLTypes["order_by"],
	guest_gtag?: GraphQLTypes["order_by"],
	hot_panels_aggregate?: GraphQLTypes["voyce_series_hot_panels_aggregate_order_by"],
	id?: GraphQLTypes["order_by"],
	is_account_active?: GraphQLTypes["order_by"],
	is_approved?: GraphQLTypes["order_by"],
	is_onboarded?: GraphQLTypes["order_by"],
	is_publisher_verified?: GraphQLTypes["order_by"],
	klaviyo_id?: GraphQLTypes["order_by"],
	last_login?: GraphQLTypes["order_by"],
	last_name?: GraphQLTypes["order_by"],
	linked_account_status?: GraphQLTypes["order_by"],
	new_email_code?: GraphQLTypes["order_by"],
	newsletter_subscribe?: GraphQLTypes["order_by"],
	nickname?: GraphQLTypes["order_by"],
	notification_series_settings_aggregate?: GraphQLTypes["voyce_notification_series_settings_aggregate_order_by"],
	notification_setting?: GraphQLTypes["voyce_notification_settings_order_by"],
	notifications_aggregate?: GraphQLTypes["voyce_notifications_aggregate_order_by"],
	panels_comments_aggregate?: GraphQLTypes["voyce_panels_comments_aggregate_order_by"],
	panels_reactions_aggregate?: GraphQLTypes["voyce_panels_reactions_aggregate_order_by"],
	password?: GraphQLTypes["order_by"],
	password_secure?: GraphQLTypes["order_by"],
	payments_to_aggregate?: GraphQLTypes["voyce_payments_to_creators_aggregate_order_by"],
	polls_aggregate?: GraphQLTypes["voyce_polls_aggregate_order_by"],
	polls_comments_aggregate?: GraphQLTypes["voyce_polls_comments_aggregate_order_by"],
	polls_reactions_aggregate?: GraphQLTypes["voyce_polls_reactions_aggregate_order_by"],
	polls_votes_aggregate?: GraphQLTypes["voyce_polls_votes_aggregate_order_by"],
	posts_aggregate?: GraphQLTypes["voyce_posts_aggregate_order_by"],
	posts_comments_aggregate?: GraphQLTypes["voyce_posts_comments_aggregate_order_by"],
	posts_reactions_aggregate?: GraphQLTypes["voyce_posts_reactions_aggregate_order_by"],
	private_fields?: GraphQLTypes["voyce_users_private_order_by"],
	publisher_verification_code?: GraphQLTypes["order_by"],
	quest_onboarded?: GraphQLTypes["order_by"],
	remember_code?: GraphQLTypes["order_by"],
	reset_code?: GraphQLTypes["order_by"],
	reset_code_created_at?: GraphQLTypes["order_by"],
	reset_otp?: GraphQLTypes["order_by"],
	role?: GraphQLTypes["voyce_user_roles_order_by"],
	role_id?: GraphQLTypes["order_by"],
	series_subscription_count?: GraphQLTypes["voyce_user_series_subscription_count_order_by"],
	signup_source?: GraphQLTypes["order_by"],
	social_discord?: GraphQLTypes["order_by"],
	social_fb?: GraphQLTypes["order_by"],
	social_instagram?: GraphQLTypes["order_by"],
	social_tiktok?: GraphQLTypes["order_by"],
	social_twitter?: GraphQLTypes["order_by"],
	sponsorshipsBySponsoredUserId_aggregate?: GraphQLTypes["voyce_sponsorships_aggregate_order_by"],
	sponsorships_aggregate?: GraphQLTypes["voyce_sponsorships_aggregate_order_by"],
	stripe_setup?: GraphQLTypes["voyce_stripe_payouts_setup_order_by"],
	unsubscribe?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	user_type_preference?: GraphQLTypes["order_by"],
	username?: GraphQLTypes["order_by"],
	usersFollowersByFollowingUid_aggregate?: GraphQLTypes["voyce_users_followers_aggregate_order_by"],
	users_followers_aggregate?: GraphQLTypes["voyce_users_followers_aggregate_order_by"],
	users_series_junctions_aggregate?: GraphQLTypes["voyce_users_series_junction_aggregate_order_by"],
	vmuser?: GraphQLTypes["order_by"],
	vtag?: GraphQLTypes["voyce_user_vtags_order_by"]
};
	/** primary key columns input for table: voyce.users */
["voyce_users_pk_columns_input"]: {
		id: number
};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["voyce_users_prepend_input"]: {
		creator_type_preferences?: GraphQLTypes["jsonb"],
	fcm_tokens?: GraphQLTypes["jsonb"]
};
	/** columns and relationships of "voyce.users_private" */
["voyce_users_private"]: {
	__typename: "voyce_users_private",
	about?: string,
	ad_share?: number,
	avatar?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	email?: string,
	first_name?: string,
	gender?: string,
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	nickname?: string,
	password?: string,
	remember_code?: string,
	role_id?: number,
	username?: string,
	vmuser?: boolean
};
	/** aggregated selection of "voyce.users_private" */
["voyce_users_private_aggregate"]: {
	__typename: "voyce_users_private_aggregate",
	aggregate?: GraphQLTypes["voyce_users_private_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_users_private"]>
};
	/** aggregate fields of "voyce.users_private" */
["voyce_users_private_aggregate_fields"]: {
	__typename: "voyce_users_private_aggregate_fields",
	avg?: GraphQLTypes["voyce_users_private_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_users_private_max_fields"],
	min?: GraphQLTypes["voyce_users_private_min_fields"],
	stddev?: GraphQLTypes["voyce_users_private_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_users_private_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_users_private_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_users_private_sum_fields"],
	var_pop?: GraphQLTypes["voyce_users_private_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_users_private_var_samp_fields"],
	variance?: GraphQLTypes["voyce_users_private_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_users_private_avg_fields"]: {
	__typename: "voyce_users_private_avg_fields",
	ad_share?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	role_id?: number
};
	/** Boolean expression to filter rows from the table "voyce.users_private". All fields are combined with a logical 'AND'. */
["voyce_users_private_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_users_private_bool_exp"]>,
	_not?: GraphQLTypes["voyce_users_private_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_users_private_bool_exp"]>,
	about?: GraphQLTypes["String_comparison_exp"],
	ad_share?: GraphQLTypes["Int_comparison_exp"],
	avatar?: GraphQLTypes["String_comparison_exp"],
	banner?: GraphQLTypes["String_comparison_exp"],
	birth_date?: GraphQLTypes["date_comparison_exp"],
	dark_mode?: GraphQLTypes["smallint_comparison_exp"],
	date_create?: GraphQLTypes["timestamp_comparison_exp"],
	email?: GraphQLTypes["String_comparison_exp"],
	first_name?: GraphQLTypes["String_comparison_exp"],
	gender?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	is_account_active?: GraphQLTypes["smallint_comparison_exp"],
	last_login?: GraphQLTypes["timestamp_comparison_exp"],
	last_name?: GraphQLTypes["String_comparison_exp"],
	nickname?: GraphQLTypes["String_comparison_exp"],
	password?: GraphQLTypes["String_comparison_exp"],
	remember_code?: GraphQLTypes["String_comparison_exp"],
	role_id?: GraphQLTypes["Int_comparison_exp"],
	username?: GraphQLTypes["String_comparison_exp"],
	vmuser?: GraphQLTypes["Boolean_comparison_exp"]
};
	/** input type for incrementing numeric columns in table "voyce.users_private" */
["voyce_users_private_inc_input"]: {
		ad_share?: number,
	dark_mode?: GraphQLTypes["smallint"],
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	role_id?: number
};
	/** input type for inserting data into table "voyce.users_private" */
["voyce_users_private_insert_input"]: {
		about?: string,
	ad_share?: number,
	avatar?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	email?: string,
	first_name?: string,
	gender?: string,
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	nickname?: string,
	password?: string,
	remember_code?: string,
	role_id?: number,
	username?: string,
	vmuser?: boolean
};
	/** aggregate max on columns */
["voyce_users_private_max_fields"]: {
	__typename: "voyce_users_private_max_fields",
	about?: string,
	ad_share?: number,
	avatar?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	email?: string,
	first_name?: string,
	gender?: string,
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	nickname?: string,
	password?: string,
	remember_code?: string,
	role_id?: number,
	username?: string
};
	/** aggregate min on columns */
["voyce_users_private_min_fields"]: {
	__typename: "voyce_users_private_min_fields",
	about?: string,
	ad_share?: number,
	avatar?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	email?: string,
	first_name?: string,
	gender?: string,
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	nickname?: string,
	password?: string,
	remember_code?: string,
	role_id?: number,
	username?: string
};
	/** response of any mutation on the table "voyce.users_private" */
["voyce_users_private_mutation_response"]: {
	__typename: "voyce_users_private_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_users_private"]>
};
	/** input type for inserting object relation for remote table "voyce.users_private" */
["voyce_users_private_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_users_private_insert_input"]
};
	/** Ordering options when selecting data from "voyce.users_private". */
["voyce_users_private_order_by"]: {
		about?: GraphQLTypes["order_by"],
	ad_share?: GraphQLTypes["order_by"],
	avatar?: GraphQLTypes["order_by"],
	banner?: GraphQLTypes["order_by"],
	birth_date?: GraphQLTypes["order_by"],
	dark_mode?: GraphQLTypes["order_by"],
	date_create?: GraphQLTypes["order_by"],
	email?: GraphQLTypes["order_by"],
	first_name?: GraphQLTypes["order_by"],
	gender?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	is_account_active?: GraphQLTypes["order_by"],
	last_login?: GraphQLTypes["order_by"],
	last_name?: GraphQLTypes["order_by"],
	nickname?: GraphQLTypes["order_by"],
	password?: GraphQLTypes["order_by"],
	remember_code?: GraphQLTypes["order_by"],
	role_id?: GraphQLTypes["order_by"],
	username?: GraphQLTypes["order_by"],
	vmuser?: GraphQLTypes["order_by"]
};
	/** select columns of table "voyce.users_private" */
["voyce_users_private_select_column"]: voyce_users_private_select_column;
	/** input type for updating data in table "voyce.users_private" */
["voyce_users_private_set_input"]: {
		about?: string,
	ad_share?: number,
	avatar?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	email?: string,
	first_name?: string,
	gender?: string,
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	nickname?: string,
	password?: string,
	remember_code?: string,
	role_id?: number,
	username?: string,
	vmuser?: boolean
};
	/** aggregate stddev on columns */
["voyce_users_private_stddev_fields"]: {
	__typename: "voyce_users_private_stddev_fields",
	ad_share?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	role_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_users_private_stddev_pop_fields"]: {
	__typename: "voyce_users_private_stddev_pop_fields",
	ad_share?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	role_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_users_private_stddev_samp_fields"]: {
	__typename: "voyce_users_private_stddev_samp_fields",
	ad_share?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	role_id?: number
};
	/** Streaming cursor of the table "voyce_users_private" */
["voyce_users_private_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_users_private_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_users_private_stream_cursor_value_input"]: {
		about?: string,
	ad_share?: number,
	avatar?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	email?: string,
	first_name?: string,
	gender?: string,
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	nickname?: string,
	password?: string,
	remember_code?: string,
	role_id?: number,
	username?: string,
	vmuser?: boolean
};
	/** aggregate sum on columns */
["voyce_users_private_sum_fields"]: {
	__typename: "voyce_users_private_sum_fields",
	ad_share?: number,
	dark_mode?: GraphQLTypes["smallint"],
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	role_id?: number
};
	["voyce_users_private_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_users_private_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_users_private_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_users_private_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_users_private_var_pop_fields"]: {
	__typename: "voyce_users_private_var_pop_fields",
	ad_share?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	role_id?: number
};
	/** aggregate var_samp on columns */
["voyce_users_private_var_samp_fields"]: {
	__typename: "voyce_users_private_var_samp_fields",
	ad_share?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	role_id?: number
};
	/** aggregate variance on columns */
["voyce_users_private_variance_fields"]: {
	__typename: "voyce_users_private_variance_fields",
	ad_share?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	role_id?: number
};
	/** select columns of table "voyce.users" */
["voyce_users_select_column"]: voyce_users_select_column;
	/** columns and relationships of "voyce.users_series_junction" */
["voyce_users_series_junction"]: {
	__typename: "voyce_users_series_junction",
	id: number,
	/** An object relationship */
	series: GraphQLTypes["voyce_series"],
	series_id: number,
	/** An object relationship */
	user: GraphQLTypes["voyce_users"],
	user_id: number
};
	/** aggregated selection of "voyce.users_series_junction" */
["voyce_users_series_junction_aggregate"]: {
	__typename: "voyce_users_series_junction_aggregate",
	aggregate?: GraphQLTypes["voyce_users_series_junction_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_users_series_junction"]>
};
	["voyce_users_series_junction_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_users_series_junction_aggregate_bool_exp_count"]
};
	["voyce_users_series_junction_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_users_series_junction_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_users_series_junction_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.users_series_junction" */
["voyce_users_series_junction_aggregate_fields"]: {
	__typename: "voyce_users_series_junction_aggregate_fields",
	avg?: GraphQLTypes["voyce_users_series_junction_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_users_series_junction_max_fields"],
	min?: GraphQLTypes["voyce_users_series_junction_min_fields"],
	stddev?: GraphQLTypes["voyce_users_series_junction_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_users_series_junction_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_users_series_junction_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_users_series_junction_sum_fields"],
	var_pop?: GraphQLTypes["voyce_users_series_junction_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_users_series_junction_var_samp_fields"],
	variance?: GraphQLTypes["voyce_users_series_junction_variance_fields"]
};
	/** order by aggregate values of table "voyce.users_series_junction" */
["voyce_users_series_junction_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_users_series_junction_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_users_series_junction_max_order_by"],
	min?: GraphQLTypes["voyce_users_series_junction_min_order_by"],
	stddev?: GraphQLTypes["voyce_users_series_junction_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_users_series_junction_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_users_series_junction_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_users_series_junction_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_users_series_junction_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_users_series_junction_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_users_series_junction_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.users_series_junction" */
["voyce_users_series_junction_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_users_series_junction_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_users_series_junction_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_users_series_junction_avg_fields"]: {
	__typename: "voyce_users_series_junction_avg_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by avg() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.users_series_junction". All fields are combined with a logical 'AND'. */
["voyce_users_series_junction_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_users_series_junction_bool_exp"]>,
	_not?: GraphQLTypes["voyce_users_series_junction_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_users_series_junction_bool_exp"]>,
	id?: GraphQLTypes["Int_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.users_series_junction" */
["voyce_users_series_junction_constraint"]: voyce_users_series_junction_constraint;
	/** input type for incrementing numeric columns in table "voyce.users_series_junction" */
["voyce_users_series_junction_inc_input"]: {
		id?: number,
	series_id?: number,
	user_id?: number
};
	/** input type for inserting data into table "voyce.users_series_junction" */
["voyce_users_series_junction_insert_input"]: {
		id?: number,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number
};
	/** aggregate max on columns */
["voyce_users_series_junction_max_fields"]: {
	__typename: "voyce_users_series_junction_max_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by max() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_max_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_users_series_junction_min_fields"]: {
	__typename: "voyce_users_series_junction_min_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by min() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_min_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.users_series_junction" */
["voyce_users_series_junction_mutation_response"]: {
	__typename: "voyce_users_series_junction_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_users_series_junction"]>
};
	/** on_conflict condition type for table "voyce.users_series_junction" */
["voyce_users_series_junction_on_conflict"]: {
		constraint: GraphQLTypes["voyce_users_series_junction_constraint"],
	update_columns: Array<GraphQLTypes["voyce_users_series_junction_update_column"]>,
	where?: GraphQLTypes["voyce_users_series_junction_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.users_series_junction". */
["voyce_users_series_junction_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.users_series_junction */
["voyce_users_series_junction_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_select_column"]: voyce_users_series_junction_select_column;
	/** input type for updating data in table "voyce.users_series_junction" */
["voyce_users_series_junction_set_input"]: {
		id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate stddev on columns */
["voyce_users_series_junction_stddev_fields"]: {
	__typename: "voyce_users_series_junction_stddev_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_users_series_junction_stddev_pop_fields"]: {
	__typename: "voyce_users_series_junction_stddev_pop_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_users_series_junction_stddev_samp_fields"]: {
	__typename: "voyce_users_series_junction_stddev_samp_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_users_series_junction" */
["voyce_users_series_junction_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_users_series_junction_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_users_series_junction_stream_cursor_value_input"]: {
		id?: number,
	series_id?: number,
	user_id?: number
};
	/** aggregate sum on columns */
["voyce_users_series_junction_sum_fields"]: {
	__typename: "voyce_users_series_junction_sum_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by sum() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_update_column"]: voyce_users_series_junction_update_column;
	["voyce_users_series_junction_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_users_series_junction_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_users_series_junction_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_users_series_junction_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_users_series_junction_var_pop_fields"]: {
	__typename: "voyce_users_series_junction_var_pop_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by var_pop() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_users_series_junction_var_samp_fields"]: {
	__typename: "voyce_users_series_junction_var_samp_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by var_samp() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_users_series_junction_variance_fields"]: {
	__typename: "voyce_users_series_junction_variance_fields",
	id?: number,
	series_id?: number,
	user_id?: number
};
	/** order by variance() on columns of table "voyce.users_series_junction" */
["voyce_users_series_junction_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	series_id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"]
};
	/** input type for updating data in table "voyce.users" */
["voyce_users_set_input"]: {
		about?: string,
	account_disabled?: boolean,
	ad_share?: number,
	admin_role_id?: number,
	avatar?: string,
	avatar_bgcolor?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	cognito_id?: string,
	cognito_provider?: string,
	cognito_username?: string,
	country_id?: number,
	creator_type_preferences?: GraphQLTypes["jsonb"],
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	delete_code?: string,
	email?: string,
	email_verified?: boolean,
	/** mobile or web or null */
	email_verified_source?: string,
	fcm_token?: string,
	fcm_tokens?: GraphQLTypes["jsonb"],
	first_name?: string,
	frame?: string,
	gender?: string,
	guest_gtag?: string,
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	is_approved?: boolean,
	is_onboarded?: number,
	is_publisher_verified?: boolean,
	klaviyo_id?: string,
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	linked_account_status?: string,
	new_email_code?: string,
	newsletter_subscribe?: boolean,
	nickname?: string,
	password?: string,
	password_secure?: string,
	publisher_verification_code?: string,
	quest_onboarded?: boolean,
	remember_code?: string,
	reset_code?: string,
	reset_code_created_at?: GraphQLTypes["timestamptz"],
	reset_otp?: string,
	role_id?: number,
	/** mobile or web or null */
	signup_source?: string,
	social_discord?: string,
	social_fb?: string,
	social_instagram?: string,
	social_tiktok?: string,
	social_twitter?: string,
	unsubscribe?: boolean,
	updated_at?: GraphQLTypes["timestamptz"],
	user_type_preference?: string,
	username?: string,
	vmuser?: boolean
};
	/** aggregate stddev on columns */
["voyce_users_stddev_fields"]: {
	__typename: "voyce_users_stddev_fields",
	ad_share?: number,
	admin_role_id?: number,
	country_id?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	is_onboarded?: number,
	role_id?: number
};
	/** aggregate stddev_pop on columns */
["voyce_users_stddev_pop_fields"]: {
	__typename: "voyce_users_stddev_pop_fields",
	ad_share?: number,
	admin_role_id?: number,
	country_id?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	is_onboarded?: number,
	role_id?: number
};
	/** aggregate stddev_samp on columns */
["voyce_users_stddev_samp_fields"]: {
	__typename: "voyce_users_stddev_samp_fields",
	ad_share?: number,
	admin_role_id?: number,
	country_id?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	is_onboarded?: number,
	role_id?: number
};
	/** Streaming cursor of the table "voyce_users" */
["voyce_users_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_users_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_users_stream_cursor_value_input"]: {
		about?: string,
	account_disabled?: boolean,
	ad_share?: number,
	admin_role_id?: number,
	avatar?: string,
	avatar_bgcolor?: string,
	banner?: string,
	birth_date?: GraphQLTypes["date"],
	cognito_id?: string,
	cognito_provider?: string,
	cognito_username?: string,
	country_id?: number,
	creator_type_preferences?: GraphQLTypes["jsonb"],
	dark_mode?: GraphQLTypes["smallint"],
	date_create?: GraphQLTypes["timestamp"],
	delete_code?: string,
	email?: string,
	email_verified?: boolean,
	/** mobile or web or null */
	email_verified_source?: string,
	fcm_token?: string,
	fcm_tokens?: GraphQLTypes["jsonb"],
	first_name?: string,
	frame?: string,
	gender?: string,
	guest_gtag?: string,
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	is_approved?: boolean,
	is_onboarded?: number,
	is_publisher_verified?: boolean,
	klaviyo_id?: string,
	last_login?: GraphQLTypes["timestamp"],
	last_name?: string,
	linked_account_status?: string,
	new_email_code?: string,
	newsletter_subscribe?: boolean,
	nickname?: string,
	password?: string,
	password_secure?: string,
	publisher_verification_code?: string,
	quest_onboarded?: boolean,
	remember_code?: string,
	reset_code?: string,
	reset_code_created_at?: GraphQLTypes["timestamptz"],
	reset_otp?: string,
	role_id?: number,
	/** mobile or web or null */
	signup_source?: string,
	social_discord?: string,
	social_fb?: string,
	social_instagram?: string,
	social_tiktok?: string,
	social_twitter?: string,
	unsubscribe?: boolean,
	updated_at?: GraphQLTypes["timestamptz"],
	user_type_preference?: string,
	username?: string,
	vmuser?: boolean
};
	/** aggregate sum on columns */
["voyce_users_sum_fields"]: {
	__typename: "voyce_users_sum_fields",
	ad_share?: number,
	admin_role_id?: number,
	country_id?: number,
	dark_mode?: GraphQLTypes["smallint"],
	id?: number,
	is_account_active?: GraphQLTypes["smallint"],
	is_onboarded?: number,
	role_id?: number
};
	/** update columns of table "voyce.users" */
["voyce_users_update_column"]: voyce_users_update_column;
	["voyce_users_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: GraphQLTypes["voyce_users_append_input"],
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: GraphQLTypes["voyce_users_delete_at_path_input"],
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: GraphQLTypes["voyce_users_delete_elem_input"],
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: GraphQLTypes["voyce_users_delete_key_input"],
	/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_users_inc_input"],
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: GraphQLTypes["voyce_users_prepend_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_users_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_users_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_users_var_pop_fields"]: {
	__typename: "voyce_users_var_pop_fields",
	ad_share?: number,
	admin_role_id?: number,
	country_id?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	is_onboarded?: number,
	role_id?: number
};
	/** aggregate var_samp on columns */
["voyce_users_var_samp_fields"]: {
	__typename: "voyce_users_var_samp_fields",
	ad_share?: number,
	admin_role_id?: number,
	country_id?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	is_onboarded?: number,
	role_id?: number
};
	/** aggregate variance on columns */
["voyce_users_variance_fields"]: {
	__typename: "voyce_users_variance_fields",
	ad_share?: number,
	admin_role_id?: number,
	country_id?: number,
	dark_mode?: number,
	id?: number,
	is_account_active?: number,
	is_onboarded?: number,
	role_id?: number
};
	/** columns and relationships of "voyce.versions" */
["voyce_versions"]: {
	__typename: "voyce_versions",
	id: number,
	table: string,
	updated_at: GraphQLTypes["timestamptz"],
	version: number
};
	/** aggregated selection of "voyce.versions" */
["voyce_versions_aggregate"]: {
	__typename: "voyce_versions_aggregate",
	aggregate?: GraphQLTypes["voyce_versions_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_versions"]>
};
	/** aggregate fields of "voyce.versions" */
["voyce_versions_aggregate_fields"]: {
	__typename: "voyce_versions_aggregate_fields",
	avg?: GraphQLTypes["voyce_versions_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_versions_max_fields"],
	min?: GraphQLTypes["voyce_versions_min_fields"],
	stddev?: GraphQLTypes["voyce_versions_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_versions_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_versions_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_versions_sum_fields"],
	var_pop?: GraphQLTypes["voyce_versions_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_versions_var_samp_fields"],
	variance?: GraphQLTypes["voyce_versions_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_versions_avg_fields"]: {
	__typename: "voyce_versions_avg_fields",
	id?: number,
	version?: number
};
	/** Boolean expression to filter rows from the table "voyce.versions". All fields are combined with a logical 'AND'. */
["voyce_versions_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_versions_bool_exp"]>,
	_not?: GraphQLTypes["voyce_versions_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_versions_bool_exp"]>,
	id?: GraphQLTypes["Int_comparison_exp"],
	table?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	version?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.versions" */
["voyce_versions_constraint"]: voyce_versions_constraint;
	/** input type for incrementing numeric columns in table "voyce.versions" */
["voyce_versions_inc_input"]: {
		id?: number,
	version?: number
};
	/** input type for inserting data into table "voyce.versions" */
["voyce_versions_insert_input"]: {
		id?: number,
	table?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	version?: number
};
	/** aggregate max on columns */
["voyce_versions_max_fields"]: {
	__typename: "voyce_versions_max_fields",
	id?: number,
	table?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	version?: number
};
	/** aggregate min on columns */
["voyce_versions_min_fields"]: {
	__typename: "voyce_versions_min_fields",
	id?: number,
	table?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	version?: number
};
	/** response of any mutation on the table "voyce.versions" */
["voyce_versions_mutation_response"]: {
	__typename: "voyce_versions_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_versions"]>
};
	/** on_conflict condition type for table "voyce.versions" */
["voyce_versions_on_conflict"]: {
		constraint: GraphQLTypes["voyce_versions_constraint"],
	update_columns: Array<GraphQLTypes["voyce_versions_update_column"]>,
	where?: GraphQLTypes["voyce_versions_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.versions". */
["voyce_versions_order_by"]: {
		id?: GraphQLTypes["order_by"],
	table?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	version?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.versions */
["voyce_versions_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.versions" */
["voyce_versions_select_column"]: voyce_versions_select_column;
	/** input type for updating data in table "voyce.versions" */
["voyce_versions_set_input"]: {
		id?: number,
	table?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	version?: number
};
	/** aggregate stddev on columns */
["voyce_versions_stddev_fields"]: {
	__typename: "voyce_versions_stddev_fields",
	id?: number,
	version?: number
};
	/** aggregate stddev_pop on columns */
["voyce_versions_stddev_pop_fields"]: {
	__typename: "voyce_versions_stddev_pop_fields",
	id?: number,
	version?: number
};
	/** aggregate stddev_samp on columns */
["voyce_versions_stddev_samp_fields"]: {
	__typename: "voyce_versions_stddev_samp_fields",
	id?: number,
	version?: number
};
	/** Streaming cursor of the table "voyce_versions" */
["voyce_versions_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_versions_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_versions_stream_cursor_value_input"]: {
		id?: number,
	table?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	version?: number
};
	/** aggregate sum on columns */
["voyce_versions_sum_fields"]: {
	__typename: "voyce_versions_sum_fields",
	id?: number,
	version?: number
};
	/** update columns of table "voyce.versions" */
["voyce_versions_update_column"]: voyce_versions_update_column;
	["voyce_versions_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_versions_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_versions_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_versions_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_versions_var_pop_fields"]: {
	__typename: "voyce_versions_var_pop_fields",
	id?: number,
	version?: number
};
	/** aggregate var_samp on columns */
["voyce_versions_var_samp_fields"]: {
	__typename: "voyce_versions_var_samp_fields",
	id?: number,
	version?: number
};
	/** aggregate variance on columns */
["voyce_versions_variance_fields"]: {
	__typename: "voyce_versions_variance_fields",
	id?: number,
	version?: number
};
	/** columns and relationships of "voyce.voting_analytics" */
["voyce_voting_analytics"]: {
	__typename: "voyce_voting_analytics",
	created_at: GraphQLTypes["timestamptz"],
	cumulative_votes: number,
	daily_votes: number,
	id: number,
	meta?: string,
	updated_at: GraphQLTypes["timestamptz"],
	vote_date: GraphQLTypes["date"],
	/** An object relationship */
	voting_series?: GraphQLTypes["voyce_voting_series"],
	voting_series_id: number
};
	/** aggregated selection of "voyce.voting_analytics" */
["voyce_voting_analytics_aggregate"]: {
	__typename: "voyce_voting_analytics_aggregate",
	aggregate?: GraphQLTypes["voyce_voting_analytics_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_voting_analytics"]>
};
	["voyce_voting_analytics_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_voting_analytics_aggregate_bool_exp_count"]
};
	["voyce_voting_analytics_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_voting_analytics_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_voting_analytics_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.voting_analytics" */
["voyce_voting_analytics_aggregate_fields"]: {
	__typename: "voyce_voting_analytics_aggregate_fields",
	avg?: GraphQLTypes["voyce_voting_analytics_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_voting_analytics_max_fields"],
	min?: GraphQLTypes["voyce_voting_analytics_min_fields"],
	stddev?: GraphQLTypes["voyce_voting_analytics_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_voting_analytics_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_voting_analytics_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_voting_analytics_sum_fields"],
	var_pop?: GraphQLTypes["voyce_voting_analytics_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_voting_analytics_var_samp_fields"],
	variance?: GraphQLTypes["voyce_voting_analytics_variance_fields"]
};
	/** order by aggregate values of table "voyce.voting_analytics" */
["voyce_voting_analytics_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_voting_analytics_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_voting_analytics_max_order_by"],
	min?: GraphQLTypes["voyce_voting_analytics_min_order_by"],
	stddev?: GraphQLTypes["voyce_voting_analytics_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_voting_analytics_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_voting_analytics_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_voting_analytics_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_voting_analytics_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_voting_analytics_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_voting_analytics_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.voting_analytics" */
["voyce_voting_analytics_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_voting_analytics_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_voting_analytics_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_voting_analytics_avg_fields"]: {
	__typename: "voyce_voting_analytics_avg_fields",
	cumulative_votes?: number,
	daily_votes?: number,
	id?: number,
	voting_series_id?: number
};
	/** order by avg() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_avg_order_by"]: {
		cumulative_votes?: GraphQLTypes["order_by"],
	daily_votes?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.voting_analytics". All fields are combined with a logical 'AND'. */
["voyce_voting_analytics_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_voting_analytics_bool_exp"]>,
	_not?: GraphQLTypes["voyce_voting_analytics_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_voting_analytics_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	cumulative_votes?: GraphQLTypes["Int_comparison_exp"],
	daily_votes?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	meta?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	vote_date?: GraphQLTypes["date_comparison_exp"],
	voting_series?: GraphQLTypes["voyce_voting_series_bool_exp"],
	voting_series_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.voting_analytics" */
["voyce_voting_analytics_constraint"]: voyce_voting_analytics_constraint;
	/** input type for incrementing numeric columns in table "voyce.voting_analytics" */
["voyce_voting_analytics_inc_input"]: {
		cumulative_votes?: number,
	daily_votes?: number,
	id?: number,
	voting_series_id?: number
};
	/** input type for inserting data into table "voyce.voting_analytics" */
["voyce_voting_analytics_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	cumulative_votes?: number,
	daily_votes?: number,
	id?: number,
	meta?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	vote_date?: GraphQLTypes["date"],
	voting_series?: GraphQLTypes["voyce_voting_series_obj_rel_insert_input"],
	voting_series_id?: number
};
	/** aggregate max on columns */
["voyce_voting_analytics_max_fields"]: {
	__typename: "voyce_voting_analytics_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	cumulative_votes?: number,
	daily_votes?: number,
	id?: number,
	meta?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	vote_date?: GraphQLTypes["date"],
	voting_series_id?: number
};
	/** order by max() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_max_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	cumulative_votes?: GraphQLTypes["order_by"],
	daily_votes?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	meta?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	vote_date?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_voting_analytics_min_fields"]: {
	__typename: "voyce_voting_analytics_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	cumulative_votes?: number,
	daily_votes?: number,
	id?: number,
	meta?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	vote_date?: GraphQLTypes["date"],
	voting_series_id?: number
};
	/** order by min() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_min_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	cumulative_votes?: GraphQLTypes["order_by"],
	daily_votes?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	meta?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	vote_date?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.voting_analytics" */
["voyce_voting_analytics_mutation_response"]: {
	__typename: "voyce_voting_analytics_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_voting_analytics"]>
};
	/** on_conflict condition type for table "voyce.voting_analytics" */
["voyce_voting_analytics_on_conflict"]: {
		constraint: GraphQLTypes["voyce_voting_analytics_constraint"],
	update_columns: Array<GraphQLTypes["voyce_voting_analytics_update_column"]>,
	where?: GraphQLTypes["voyce_voting_analytics_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.voting_analytics". */
["voyce_voting_analytics_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	cumulative_votes?: GraphQLTypes["order_by"],
	daily_votes?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	meta?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	vote_date?: GraphQLTypes["order_by"],
	voting_series?: GraphQLTypes["voyce_voting_series_order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.voting_analytics */
["voyce_voting_analytics_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_select_column"]: voyce_voting_analytics_select_column;
	/** input type for updating data in table "voyce.voting_analytics" */
["voyce_voting_analytics_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	cumulative_votes?: number,
	daily_votes?: number,
	id?: number,
	meta?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	vote_date?: GraphQLTypes["date"],
	voting_series_id?: number
};
	/** aggregate stddev on columns */
["voyce_voting_analytics_stddev_fields"]: {
	__typename: "voyce_voting_analytics_stddev_fields",
	cumulative_votes?: number,
	daily_votes?: number,
	id?: number,
	voting_series_id?: number
};
	/** order by stddev() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_stddev_order_by"]: {
		cumulative_votes?: GraphQLTypes["order_by"],
	daily_votes?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_voting_analytics_stddev_pop_fields"]: {
	__typename: "voyce_voting_analytics_stddev_pop_fields",
	cumulative_votes?: number,
	daily_votes?: number,
	id?: number,
	voting_series_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_stddev_pop_order_by"]: {
		cumulative_votes?: GraphQLTypes["order_by"],
	daily_votes?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_voting_analytics_stddev_samp_fields"]: {
	__typename: "voyce_voting_analytics_stddev_samp_fields",
	cumulative_votes?: number,
	daily_votes?: number,
	id?: number,
	voting_series_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_stddev_samp_order_by"]: {
		cumulative_votes?: GraphQLTypes["order_by"],
	daily_votes?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_voting_analytics" */
["voyce_voting_analytics_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_voting_analytics_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_voting_analytics_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	cumulative_votes?: number,
	daily_votes?: number,
	id?: number,
	meta?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	vote_date?: GraphQLTypes["date"],
	voting_series_id?: number
};
	/** aggregate sum on columns */
["voyce_voting_analytics_sum_fields"]: {
	__typename: "voyce_voting_analytics_sum_fields",
	cumulative_votes?: number,
	daily_votes?: number,
	id?: number,
	voting_series_id?: number
};
	/** order by sum() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_sum_order_by"]: {
		cumulative_votes?: GraphQLTypes["order_by"],
	daily_votes?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_update_column"]: voyce_voting_analytics_update_column;
	["voyce_voting_analytics_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_voting_analytics_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_voting_analytics_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_voting_analytics_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_voting_analytics_var_pop_fields"]: {
	__typename: "voyce_voting_analytics_var_pop_fields",
	cumulative_votes?: number,
	daily_votes?: number,
	id?: number,
	voting_series_id?: number
};
	/** order by var_pop() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_var_pop_order_by"]: {
		cumulative_votes?: GraphQLTypes["order_by"],
	daily_votes?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_voting_analytics_var_samp_fields"]: {
	__typename: "voyce_voting_analytics_var_samp_fields",
	cumulative_votes?: number,
	daily_votes?: number,
	id?: number,
	voting_series_id?: number
};
	/** order by var_samp() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_var_samp_order_by"]: {
		cumulative_votes?: GraphQLTypes["order_by"],
	daily_votes?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_voting_analytics_variance_fields"]: {
	__typename: "voyce_voting_analytics_variance_fields",
	cumulative_votes?: number,
	daily_votes?: number,
	id?: number,
	voting_series_id?: number
};
	/** order by variance() on columns of table "voyce.voting_analytics" */
["voyce_voting_analytics_variance_order_by"]: {
		cumulative_votes?: GraphQLTypes["order_by"],
	daily_votes?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.voting_panels" */
["voyce_voting_panels"]: {
	__typename: "voyce_voting_panels",
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	panel_image: string,
	updated_at: GraphQLTypes["timestamptz"],
	/** An object relationship */
	voting_series?: GraphQLTypes["voyce_voting_series"],
	voting_series_id: number
};
	/** aggregated selection of "voyce.voting_panels" */
["voyce_voting_panels_aggregate"]: {
	__typename: "voyce_voting_panels_aggregate",
	aggregate?: GraphQLTypes["voyce_voting_panels_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_voting_panels"]>
};
	["voyce_voting_panels_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_voting_panels_aggregate_bool_exp_count"]
};
	["voyce_voting_panels_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_voting_panels_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_voting_panels_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.voting_panels" */
["voyce_voting_panels_aggregate_fields"]: {
	__typename: "voyce_voting_panels_aggregate_fields",
	avg?: GraphQLTypes["voyce_voting_panels_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_voting_panels_max_fields"],
	min?: GraphQLTypes["voyce_voting_panels_min_fields"],
	stddev?: GraphQLTypes["voyce_voting_panels_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_voting_panels_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_voting_panels_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_voting_panels_sum_fields"],
	var_pop?: GraphQLTypes["voyce_voting_panels_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_voting_panels_var_samp_fields"],
	variance?: GraphQLTypes["voyce_voting_panels_variance_fields"]
};
	/** order by aggregate values of table "voyce.voting_panels" */
["voyce_voting_panels_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_voting_panels_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_voting_panels_max_order_by"],
	min?: GraphQLTypes["voyce_voting_panels_min_order_by"],
	stddev?: GraphQLTypes["voyce_voting_panels_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_voting_panels_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_voting_panels_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_voting_panels_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_voting_panels_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_voting_panels_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_voting_panels_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.voting_panels" */
["voyce_voting_panels_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_voting_panels_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_voting_panels_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_voting_panels_avg_fields"]: {
	__typename: "voyce_voting_panels_avg_fields",
	id?: number,
	voting_series_id?: number
};
	/** order by avg() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.voting_panels". All fields are combined with a logical 'AND'. */
["voyce_voting_panels_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_voting_panels_bool_exp"]>,
	_not?: GraphQLTypes["voyce_voting_panels_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_voting_panels_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	panel_image?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	voting_series?: GraphQLTypes["voyce_voting_series_bool_exp"],
	voting_series_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.voting_panels" */
["voyce_voting_panels_constraint"]: voyce_voting_panels_constraint;
	/** input type for incrementing numeric columns in table "voyce.voting_panels" */
["voyce_voting_panels_inc_input"]: {
		id?: number,
	voting_series_id?: number
};
	/** input type for inserting data into table "voyce.voting_panels" */
["voyce_voting_panels_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	panel_image?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	voting_series?: GraphQLTypes["voyce_voting_series_obj_rel_insert_input"],
	voting_series_id?: number
};
	/** aggregate max on columns */
["voyce_voting_panels_max_fields"]: {
	__typename: "voyce_voting_panels_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	panel_image?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	voting_series_id?: number
};
	/** order by max() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_max_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	panel_image?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_voting_panels_min_fields"]: {
	__typename: "voyce_voting_panels_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	panel_image?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	voting_series_id?: number
};
	/** order by min() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_min_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	panel_image?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.voting_panels" */
["voyce_voting_panels_mutation_response"]: {
	__typename: "voyce_voting_panels_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_voting_panels"]>
};
	/** on_conflict condition type for table "voyce.voting_panels" */
["voyce_voting_panels_on_conflict"]: {
		constraint: GraphQLTypes["voyce_voting_panels_constraint"],
	update_columns: Array<GraphQLTypes["voyce_voting_panels_update_column"]>,
	where?: GraphQLTypes["voyce_voting_panels_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.voting_panels". */
["voyce_voting_panels_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	panel_image?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	voting_series?: GraphQLTypes["voyce_voting_series_order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.voting_panels */
["voyce_voting_panels_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.voting_panels" */
["voyce_voting_panels_select_column"]: voyce_voting_panels_select_column;
	/** input type for updating data in table "voyce.voting_panels" */
["voyce_voting_panels_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	panel_image?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	voting_series_id?: number
};
	/** aggregate stddev on columns */
["voyce_voting_panels_stddev_fields"]: {
	__typename: "voyce_voting_panels_stddev_fields",
	id?: number,
	voting_series_id?: number
};
	/** order by stddev() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_voting_panels_stddev_pop_fields"]: {
	__typename: "voyce_voting_panels_stddev_pop_fields",
	id?: number,
	voting_series_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_voting_panels_stddev_samp_fields"]: {
	__typename: "voyce_voting_panels_stddev_samp_fields",
	id?: number,
	voting_series_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_voting_panels" */
["voyce_voting_panels_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_voting_panels_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_voting_panels_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	panel_image?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	voting_series_id?: number
};
	/** aggregate sum on columns */
["voyce_voting_panels_sum_fields"]: {
	__typename: "voyce_voting_panels_sum_fields",
	id?: number,
	voting_series_id?: number
};
	/** order by sum() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.voting_panels" */
["voyce_voting_panels_update_column"]: voyce_voting_panels_update_column;
	["voyce_voting_panels_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_voting_panels_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_voting_panels_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_voting_panels_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_voting_panels_var_pop_fields"]: {
	__typename: "voyce_voting_panels_var_pop_fields",
	id?: number,
	voting_series_id?: number
};
	/** order by var_pop() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_voting_panels_var_samp_fields"]: {
	__typename: "voyce_voting_panels_var_samp_fields",
	id?: number,
	voting_series_id?: number
};
	/** order by var_samp() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_voting_panels_variance_fields"]: {
	__typename: "voyce_voting_panels_variance_fields",
	id?: number,
	voting_series_id?: number
};
	/** order by variance() on columns of table "voyce.voting_panels" */
["voyce_voting_panels_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "voyce.voting_series" */
["voyce_voting_series"]: {
	__typename: "voyce_voting_series",
	author_image?: string,
	author_name: string,
	collaborators?: string,
	created_at: GraphQLTypes["timestamptz"],
	id: number,
	/** An object relationship */
	series?: GraphQLTypes["voyce_series"],
	series_id: number,
	sort: number,
	spotlight_caption?: string,
	spotlight_img1?: string,
	spotlight_img2?: string,
	spotlight_img3?: string,
	updated_at: GraphQLTypes["timestamptz"],
	/** An array relationship */
	voting_analytics: Array<GraphQLTypes["voyce_voting_analytics"]>,
	/** An aggregate relationship */
	voting_analytics_aggregate: GraphQLTypes["voyce_voting_analytics_aggregate"],
	/** An array relationship */
	voting_panels: Array<GraphQLTypes["voyce_voting_panels"]>,
	/** An aggregate relationship */
	voting_panels_aggregate: GraphQLTypes["voyce_voting_panels_aggregate"],
	/** An array relationship */
	voting_user_votes: Array<GraphQLTypes["voyce_voting_user_votes"]>,
	/** An aggregate relationship */
	voting_user_votes_aggregate: GraphQLTypes["voyce_voting_user_votes_aggregate"]
};
	/** aggregated selection of "voyce.voting_series" */
["voyce_voting_series_aggregate"]: {
	__typename: "voyce_voting_series_aggregate",
	aggregate?: GraphQLTypes["voyce_voting_series_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_voting_series"]>
};
	/** aggregate fields of "voyce.voting_series" */
["voyce_voting_series_aggregate_fields"]: {
	__typename: "voyce_voting_series_aggregate_fields",
	avg?: GraphQLTypes["voyce_voting_series_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_voting_series_max_fields"],
	min?: GraphQLTypes["voyce_voting_series_min_fields"],
	stddev?: GraphQLTypes["voyce_voting_series_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_voting_series_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_voting_series_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_voting_series_sum_fields"],
	var_pop?: GraphQLTypes["voyce_voting_series_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_voting_series_var_samp_fields"],
	variance?: GraphQLTypes["voyce_voting_series_variance_fields"]
};
	/** aggregate avg on columns */
["voyce_voting_series_avg_fields"]: {
	__typename: "voyce_voting_series_avg_fields",
	id?: number,
	series_id?: number,
	sort?: number
};
	/** Boolean expression to filter rows from the table "voyce.voting_series". All fields are combined with a logical 'AND'. */
["voyce_voting_series_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_voting_series_bool_exp"]>,
	_not?: GraphQLTypes["voyce_voting_series_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_voting_series_bool_exp"]>,
	author_image?: GraphQLTypes["String_comparison_exp"],
	author_name?: GraphQLTypes["String_comparison_exp"],
	collaborators?: GraphQLTypes["String_comparison_exp"],
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	series?: GraphQLTypes["voyce_series_bool_exp"],
	series_id?: GraphQLTypes["Int_comparison_exp"],
	sort?: GraphQLTypes["Int_comparison_exp"],
	spotlight_caption?: GraphQLTypes["String_comparison_exp"],
	spotlight_img1?: GraphQLTypes["String_comparison_exp"],
	spotlight_img2?: GraphQLTypes["String_comparison_exp"],
	spotlight_img3?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	voting_analytics?: GraphQLTypes["voyce_voting_analytics_bool_exp"],
	voting_analytics_aggregate?: GraphQLTypes["voyce_voting_analytics_aggregate_bool_exp"],
	voting_panels?: GraphQLTypes["voyce_voting_panels_bool_exp"],
	voting_panels_aggregate?: GraphQLTypes["voyce_voting_panels_aggregate_bool_exp"],
	voting_user_votes?: GraphQLTypes["voyce_voting_user_votes_bool_exp"],
	voting_user_votes_aggregate?: GraphQLTypes["voyce_voting_user_votes_aggregate_bool_exp"]
};
	/** unique or primary key constraints on table "voyce.voting_series" */
["voyce_voting_series_constraint"]: voyce_voting_series_constraint;
	/** input type for incrementing numeric columns in table "voyce.voting_series" */
["voyce_voting_series_inc_input"]: {
		id?: number,
	series_id?: number,
	sort?: number
};
	/** input type for inserting data into table "voyce.voting_series" */
["voyce_voting_series_insert_input"]: {
		author_image?: string,
	author_name?: string,
	collaborators?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series?: GraphQLTypes["voyce_series_obj_rel_insert_input"],
	series_id?: number,
	sort?: number,
	spotlight_caption?: string,
	spotlight_img1?: string,
	spotlight_img2?: string,
	spotlight_img3?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	voting_analytics?: GraphQLTypes["voyce_voting_analytics_arr_rel_insert_input"],
	voting_panels?: GraphQLTypes["voyce_voting_panels_arr_rel_insert_input"],
	voting_user_votes?: GraphQLTypes["voyce_voting_user_votes_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["voyce_voting_series_max_fields"]: {
	__typename: "voyce_voting_series_max_fields",
	author_image?: string,
	author_name?: string,
	collaborators?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	sort?: number,
	spotlight_caption?: string,
	spotlight_img1?: string,
	spotlight_img2?: string,
	spotlight_img3?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate min on columns */
["voyce_voting_series_min_fields"]: {
	__typename: "voyce_voting_series_min_fields",
	author_image?: string,
	author_name?: string,
	collaborators?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	sort?: number,
	spotlight_caption?: string,
	spotlight_img1?: string,
	spotlight_img2?: string,
	spotlight_img3?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** response of any mutation on the table "voyce.voting_series" */
["voyce_voting_series_mutation_response"]: {
	__typename: "voyce_voting_series_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_voting_series"]>
};
	/** input type for inserting object relation for remote table "voyce.voting_series" */
["voyce_voting_series_obj_rel_insert_input"]: {
		data: GraphQLTypes["voyce_voting_series_insert_input"],
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_voting_series_on_conflict"]
};
	/** on_conflict condition type for table "voyce.voting_series" */
["voyce_voting_series_on_conflict"]: {
		constraint: GraphQLTypes["voyce_voting_series_constraint"],
	update_columns: Array<GraphQLTypes["voyce_voting_series_update_column"]>,
	where?: GraphQLTypes["voyce_voting_series_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.voting_series". */
["voyce_voting_series_order_by"]: {
		author_image?: GraphQLTypes["order_by"],
	author_name?: GraphQLTypes["order_by"],
	collaborators?: GraphQLTypes["order_by"],
	created_at?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	series?: GraphQLTypes["voyce_series_order_by"],
	series_id?: GraphQLTypes["order_by"],
	sort?: GraphQLTypes["order_by"],
	spotlight_caption?: GraphQLTypes["order_by"],
	spotlight_img1?: GraphQLTypes["order_by"],
	spotlight_img2?: GraphQLTypes["order_by"],
	spotlight_img3?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	voting_analytics_aggregate?: GraphQLTypes["voyce_voting_analytics_aggregate_order_by"],
	voting_panels_aggregate?: GraphQLTypes["voyce_voting_panels_aggregate_order_by"],
	voting_user_votes_aggregate?: GraphQLTypes["voyce_voting_user_votes_aggregate_order_by"]
};
	/** primary key columns input for table: voyce.voting_series */
["voyce_voting_series_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.voting_series" */
["voyce_voting_series_select_column"]: voyce_voting_series_select_column;
	/** input type for updating data in table "voyce.voting_series" */
["voyce_voting_series_set_input"]: {
		author_image?: string,
	author_name?: string,
	collaborators?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	sort?: number,
	spotlight_caption?: string,
	spotlight_img1?: string,
	spotlight_img2?: string,
	spotlight_img3?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate stddev on columns */
["voyce_voting_series_stddev_fields"]: {
	__typename: "voyce_voting_series_stddev_fields",
	id?: number,
	series_id?: number,
	sort?: number
};
	/** aggregate stddev_pop on columns */
["voyce_voting_series_stddev_pop_fields"]: {
	__typename: "voyce_voting_series_stddev_pop_fields",
	id?: number,
	series_id?: number,
	sort?: number
};
	/** aggregate stddev_samp on columns */
["voyce_voting_series_stddev_samp_fields"]: {
	__typename: "voyce_voting_series_stddev_samp_fields",
	id?: number,
	series_id?: number,
	sort?: number
};
	/** Streaming cursor of the table "voyce_voting_series" */
["voyce_voting_series_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_voting_series_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_voting_series_stream_cursor_value_input"]: {
		author_image?: string,
	author_name?: string,
	collaborators?: string,
	created_at?: GraphQLTypes["timestamptz"],
	id?: number,
	series_id?: number,
	sort?: number,
	spotlight_caption?: string,
	spotlight_img1?: string,
	spotlight_img2?: string,
	spotlight_img3?: string,
	updated_at?: GraphQLTypes["timestamptz"]
};
	/** aggregate sum on columns */
["voyce_voting_series_sum_fields"]: {
	__typename: "voyce_voting_series_sum_fields",
	id?: number,
	series_id?: number,
	sort?: number
};
	/** update columns of table "voyce.voting_series" */
["voyce_voting_series_update_column"]: voyce_voting_series_update_column;
	["voyce_voting_series_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_voting_series_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_voting_series_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_voting_series_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_voting_series_var_pop_fields"]: {
	__typename: "voyce_voting_series_var_pop_fields",
	id?: number,
	series_id?: number,
	sort?: number
};
	/** aggregate var_samp on columns */
["voyce_voting_series_var_samp_fields"]: {
	__typename: "voyce_voting_series_var_samp_fields",
	id?: number,
	series_id?: number,
	sort?: number
};
	/** aggregate variance on columns */
["voyce_voting_series_variance_fields"]: {
	__typename: "voyce_voting_series_variance_fields",
	id?: number,
	series_id?: number,
	sort?: number
};
	/** columns and relationships of "voyce.voting_user_votes" */
["voyce_voting_user_votes"]: {
	__typename: "voyce_voting_user_votes",
	date_voted: GraphQLTypes["date"],
	id: number,
	time_voted: GraphQLTypes["timestamptz"],
	/** An object relationship */
	user?: GraphQLTypes["voyce_users"],
	user_id: number,
	/** An object relationship */
	voting_series?: GraphQLTypes["voyce_voting_series"],
	voting_series_id: number
};
	/** aggregated selection of "voyce.voting_user_votes" */
["voyce_voting_user_votes_aggregate"]: {
	__typename: "voyce_voting_user_votes_aggregate",
	aggregate?: GraphQLTypes["voyce_voting_user_votes_aggregate_fields"],
	nodes: Array<GraphQLTypes["voyce_voting_user_votes"]>
};
	["voyce_voting_user_votes_aggregate_bool_exp"]: {
		count?: GraphQLTypes["voyce_voting_user_votes_aggregate_bool_exp_count"]
};
	["voyce_voting_user_votes_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["voyce_voting_user_votes_select_column"]>,
	distinct?: boolean,
	filter?: GraphQLTypes["voyce_voting_user_votes_bool_exp"],
	predicate: GraphQLTypes["Int_comparison_exp"]
};
	/** aggregate fields of "voyce.voting_user_votes" */
["voyce_voting_user_votes_aggregate_fields"]: {
	__typename: "voyce_voting_user_votes_aggregate_fields",
	avg?: GraphQLTypes["voyce_voting_user_votes_avg_fields"],
	count: number,
	max?: GraphQLTypes["voyce_voting_user_votes_max_fields"],
	min?: GraphQLTypes["voyce_voting_user_votes_min_fields"],
	stddev?: GraphQLTypes["voyce_voting_user_votes_stddev_fields"],
	stddev_pop?: GraphQLTypes["voyce_voting_user_votes_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["voyce_voting_user_votes_stddev_samp_fields"],
	sum?: GraphQLTypes["voyce_voting_user_votes_sum_fields"],
	var_pop?: GraphQLTypes["voyce_voting_user_votes_var_pop_fields"],
	var_samp?: GraphQLTypes["voyce_voting_user_votes_var_samp_fields"],
	variance?: GraphQLTypes["voyce_voting_user_votes_variance_fields"]
};
	/** order by aggregate values of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_aggregate_order_by"]: {
		avg?: GraphQLTypes["voyce_voting_user_votes_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["voyce_voting_user_votes_max_order_by"],
	min?: GraphQLTypes["voyce_voting_user_votes_min_order_by"],
	stddev?: GraphQLTypes["voyce_voting_user_votes_stddev_order_by"],
	stddev_pop?: GraphQLTypes["voyce_voting_user_votes_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["voyce_voting_user_votes_stddev_samp_order_by"],
	sum?: GraphQLTypes["voyce_voting_user_votes_sum_order_by"],
	var_pop?: GraphQLTypes["voyce_voting_user_votes_var_pop_order_by"],
	var_samp?: GraphQLTypes["voyce_voting_user_votes_var_samp_order_by"],
	variance?: GraphQLTypes["voyce_voting_user_votes_variance_order_by"]
};
	/** input type for inserting array relation for remote table "voyce.voting_user_votes" */
["voyce_voting_user_votes_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["voyce_voting_user_votes_insert_input"]>,
	/** upsert condition */
	on_conflict?: GraphQLTypes["voyce_voting_user_votes_on_conflict"]
};
	/** aggregate avg on columns */
["voyce_voting_user_votes_avg_fields"]: {
	__typename: "voyce_voting_user_votes_avg_fields",
	id?: number,
	user_id?: number,
	voting_series_id?: number
};
	/** order by avg() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_avg_order_by"]: {
		id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "voyce.voting_user_votes". All fields are combined with a logical 'AND'. */
["voyce_voting_user_votes_bool_exp"]: {
		_and?: Array<GraphQLTypes["voyce_voting_user_votes_bool_exp"]>,
	_not?: GraphQLTypes["voyce_voting_user_votes_bool_exp"],
	_or?: Array<GraphQLTypes["voyce_voting_user_votes_bool_exp"]>,
	date_voted?: GraphQLTypes["date_comparison_exp"],
	id?: GraphQLTypes["Int_comparison_exp"],
	time_voted?: GraphQLTypes["timestamptz_comparison_exp"],
	user?: GraphQLTypes["voyce_users_bool_exp"],
	user_id?: GraphQLTypes["Int_comparison_exp"],
	voting_series?: GraphQLTypes["voyce_voting_series_bool_exp"],
	voting_series_id?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "voyce.voting_user_votes" */
["voyce_voting_user_votes_constraint"]: voyce_voting_user_votes_constraint;
	/** input type for incrementing numeric columns in table "voyce.voting_user_votes" */
["voyce_voting_user_votes_inc_input"]: {
		id?: number,
	user_id?: number,
	voting_series_id?: number
};
	/** input type for inserting data into table "voyce.voting_user_votes" */
["voyce_voting_user_votes_insert_input"]: {
		date_voted?: GraphQLTypes["date"],
	id?: number,
	time_voted?: GraphQLTypes["timestamptz"],
	user?: GraphQLTypes["voyce_users_obj_rel_insert_input"],
	user_id?: number,
	voting_series?: GraphQLTypes["voyce_voting_series_obj_rel_insert_input"],
	voting_series_id?: number
};
	/** aggregate max on columns */
["voyce_voting_user_votes_max_fields"]: {
	__typename: "voyce_voting_user_votes_max_fields",
	date_voted?: GraphQLTypes["date"],
	id?: number,
	time_voted?: GraphQLTypes["timestamptz"],
	user_id?: number,
	voting_series_id?: number
};
	/** order by max() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_max_order_by"]: {
		date_voted?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	time_voted?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["voyce_voting_user_votes_min_fields"]: {
	__typename: "voyce_voting_user_votes_min_fields",
	date_voted?: GraphQLTypes["date"],
	id?: number,
	time_voted?: GraphQLTypes["timestamptz"],
	user_id?: number,
	voting_series_id?: number
};
	/** order by min() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_min_order_by"]: {
		date_voted?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	time_voted?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "voyce.voting_user_votes" */
["voyce_voting_user_votes_mutation_response"]: {
	__typename: "voyce_voting_user_votes_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["voyce_voting_user_votes"]>
};
	/** on_conflict condition type for table "voyce.voting_user_votes" */
["voyce_voting_user_votes_on_conflict"]: {
		constraint: GraphQLTypes["voyce_voting_user_votes_constraint"],
	update_columns: Array<GraphQLTypes["voyce_voting_user_votes_update_column"]>,
	where?: GraphQLTypes["voyce_voting_user_votes_bool_exp"]
};
	/** Ordering options when selecting data from "voyce.voting_user_votes". */
["voyce_voting_user_votes_order_by"]: {
		date_voted?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	time_voted?: GraphQLTypes["order_by"],
	user?: GraphQLTypes["voyce_users_order_by"],
	user_id?: GraphQLTypes["order_by"],
	voting_series?: GraphQLTypes["voyce_voting_series_order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: voyce.voting_user_votes */
["voyce_voting_user_votes_pk_columns_input"]: {
		id: number
};
	/** select columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_select_column"]: voyce_voting_user_votes_select_column;
	/** input type for updating data in table "voyce.voting_user_votes" */
["voyce_voting_user_votes_set_input"]: {
		date_voted?: GraphQLTypes["date"],
	id?: number,
	time_voted?: GraphQLTypes["timestamptz"],
	user_id?: number,
	voting_series_id?: number
};
	/** aggregate stddev on columns */
["voyce_voting_user_votes_stddev_fields"]: {
	__typename: "voyce_voting_user_votes_stddev_fields",
	id?: number,
	user_id?: number,
	voting_series_id?: number
};
	/** order by stddev() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_stddev_order_by"]: {
		id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["voyce_voting_user_votes_stddev_pop_fields"]: {
	__typename: "voyce_voting_user_votes_stddev_pop_fields",
	id?: number,
	user_id?: number,
	voting_series_id?: number
};
	/** order by stddev_pop() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_stddev_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["voyce_voting_user_votes_stddev_samp_fields"]: {
	__typename: "voyce_voting_user_votes_stddev_samp_fields",
	id?: number,
	user_id?: number,
	voting_series_id?: number
};
	/** order by stddev_samp() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_stddev_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** Streaming cursor of the table "voyce_voting_user_votes" */
["voyce_voting_user_votes_stream_cursor_input"]: {
		/** Stream column input with initial value */
	initial_value: GraphQLTypes["voyce_voting_user_votes_stream_cursor_value_input"],
	/** cursor ordering */
	ordering?: GraphQLTypes["cursor_ordering"]
};
	/** Initial value of the column from where the streaming should start */
["voyce_voting_user_votes_stream_cursor_value_input"]: {
		date_voted?: GraphQLTypes["date"],
	id?: number,
	time_voted?: GraphQLTypes["timestamptz"],
	user_id?: number,
	voting_series_id?: number
};
	/** aggregate sum on columns */
["voyce_voting_user_votes_sum_fields"]: {
	__typename: "voyce_voting_user_votes_sum_fields",
	id?: number,
	user_id?: number,
	voting_series_id?: number
};
	/** order by sum() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_sum_order_by"]: {
		id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** update columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_update_column"]: voyce_voting_user_votes_update_column;
	["voyce_voting_user_votes_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
	_inc?: GraphQLTypes["voyce_voting_user_votes_inc_input"],
	/** sets the columns of the filtered rows to the given values */
	_set?: GraphQLTypes["voyce_voting_user_votes_set_input"],
	/** filter the rows which have to be updated */
	where: GraphQLTypes["voyce_voting_user_votes_bool_exp"]
};
	/** aggregate var_pop on columns */
["voyce_voting_user_votes_var_pop_fields"]: {
	__typename: "voyce_voting_user_votes_var_pop_fields",
	id?: number,
	user_id?: number,
	voting_series_id?: number
};
	/** order by var_pop() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_var_pop_order_by"]: {
		id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["voyce_voting_user_votes_var_samp_fields"]: {
	__typename: "voyce_voting_user_votes_var_samp_fields",
	id?: number,
	user_id?: number,
	voting_series_id?: number
};
	/** order by var_samp() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_var_samp_order_by"]: {
		id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["voyce_voting_user_votes_variance_fields"]: {
	__typename: "voyce_voting_user_votes_variance_fields",
	id?: number,
	user_id?: number,
	voting_series_id?: number
};
	/** order by variance() on columns of table "voyce.voting_user_votes" */
["voyce_voting_user_votes_variance_order_by"]: {
		id?: GraphQLTypes["order_by"],
	user_id?: GraphQLTypes["order_by"],
	voting_series_id?: GraphQLTypes["order_by"]
}
    }
/** select columns of table "awsdms_validation_failures_v1" */
export enum awsdms_validation_failures_v1_select_column {
	DETAILS = "DETAILS",
	FAILURE_TIME = "FAILURE_TIME",
	FAILURE_TYPE = "FAILURE_TYPE",
	KEY = "KEY",
	KEY_TYPE = "KEY_TYPE",
	TABLE_NAME = "TABLE_NAME",
	TABLE_OWNER = "TABLE_OWNER",
	TASK_NAME = "TASK_NAME"
}
/** unique or primary key constraints on table "coinsystem.balances" */
export enum coinsystem_balances_constraint {
	balances_pkey = "balances_pkey"
}
/** select columns of table "coinsystem.balances" */
export enum coinsystem_balances_select_column {
	balance = "balance",
	coin_id = "coin_id",
	created_at = "created_at",
	id = "id",
	updated_at = "updated_at",
	user_id = "user_id",
	wallet_id = "wallet_id"
}
/** update columns of table "coinsystem.balances" */
export enum coinsystem_balances_update_column {
	balance = "balance",
	coin_id = "coin_id",
	created_at = "created_at",
	id = "id",
	updated_at = "updated_at",
	user_id = "user_id",
	wallet_id = "wallet_id"
}
/** unique or primary key constraints on table "coinsystem.bonus_coins" */
export enum coinsystem_bonus_coins_constraint {
	bonus_coins_pkey = "bonus_coins_pkey"
}
/** select columns of table "coinsystem.bonus_coins" */
export enum coinsystem_bonus_coins_select_column {
	active = "active",
	bonus_amount = "bonus_amount",
	coin_id = "coin_id",
	created_at = "created_at",
	id = "id",
	max_amount = "max_amount",
	min_amount = "min_amount"
}
/** update columns of table "coinsystem.bonus_coins" */
export enum coinsystem_bonus_coins_update_column {
	active = "active",
	bonus_amount = "bonus_amount",
	coin_id = "coin_id",
	created_at = "created_at",
	id = "id",
	max_amount = "max_amount",
	min_amount = "min_amount"
}
/** unique or primary key constraints on table "coinsystem.coins" */
export enum coinsystem_coins_constraint {
	coins_pkey = "coins_pkey"
}
/** select columns of table "coinsystem.coins" */
export enum coinsystem_coins_select_column {
	can_mint = "can_mint",
	coin_decimal = "coin_decimal",
	coin_logo = "coin_logo",
	coin_name = "coin_name",
	coin_symbol = "coin_symbol",
	created_at = "created_at",
	default = "default",
	id = "id",
	in_app = "in_app",
	is_withdrawable = "is_withdrawable",
	updated_at = "updated_at"
}
/** update columns of table "coinsystem.coins" */
export enum coinsystem_coins_update_column {
	can_mint = "can_mint",
	coin_decimal = "coin_decimal",
	coin_logo = "coin_logo",
	coin_name = "coin_name",
	coin_symbol = "coin_symbol",
	created_at = "created_at",
	default = "default",
	id = "id",
	in_app = "in_app",
	is_withdrawable = "is_withdrawable",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "coinsystem.exchange_rates" */
export enum coinsystem_exchange_rates_constraint {
	exchange_rates_pkey = "exchange_rates_pkey"
}
/** select columns of table "coinsystem.exchange_rates" */
export enum coinsystem_exchange_rates_select_column {
	buy_rate = "buy_rate",
	created_at = "created_at",
	destination_coin_id = "destination_coin_id",
	id = "id",
	sell_rate = "sell_rate",
	source_coin_id = "source_coin_id"
}
/** update columns of table "coinsystem.exchange_rates" */
export enum coinsystem_exchange_rates_update_column {
	buy_rate = "buy_rate",
	created_at = "created_at",
	destination_coin_id = "destination_coin_id",
	id = "id",
	sell_rate = "sell_rate",
	source_coin_id = "source_coin_id"
}
/** unique or primary key constraints on table "coinsystem.logs" */
export enum coinsystem_logs_constraint {
	logs_pkey = "logs_pkey"
}
/** select columns of table "coinsystem.logs" */
export enum coinsystem_logs_select_column {
	created_at = "created_at",
	description = "description",
	id = "id",
	transaction_id = "transaction_id"
}
/** update columns of table "coinsystem.logs" */
export enum coinsystem_logs_update_column {
	created_at = "created_at",
	description = "description",
	id = "id",
	transaction_id = "transaction_id"
}
/** unique or primary key constraints on table "coinsystem.transactions" */
export enum coinsystem_transactions_constraint {
	transactions_pkey = "transactions_pkey"
}
/** select columns of table "coinsystem.transactions" */
export enum coinsystem_transactions_select_column {
	coin_amount = "coin_amount",
	coin_id = "coin_id",
	created_at = "created_at",
	description = "description",
	id = "id",
	recipient_id = "recipient_id",
	recipient_wallet_address = "recipient_wallet_address",
	sender_id = "sender_id",
	sender_wallet_address = "sender_wallet_address",
	transaction_id = "transaction_id",
	transaction_type = "transaction_type"
}
/** update columns of table "coinsystem.transactions" */
export enum coinsystem_transactions_update_column {
	coin_amount = "coin_amount",
	coin_id = "coin_id",
	created_at = "created_at",
	description = "description",
	id = "id",
	recipient_id = "recipient_id",
	recipient_wallet_address = "recipient_wallet_address",
	sender_id = "sender_id",
	sender_wallet_address = "sender_wallet_address",
	transaction_id = "transaction_id",
	transaction_type = "transaction_type"
}
/** unique or primary key constraints on table "coinsystem.wallets" */
export enum coinsystem_wallets_constraint {
	wallets_pkey = "wallets_pkey",
	wallets_wallet_address_key = "wallets_wallet_address_key"
}
/** select columns of table "coinsystem.wallets" */
export enum coinsystem_wallets_select_column {
	can_mint = "can_mint",
	coin_id = "coin_id",
	created_at = "created_at",
	default = "default",
	id = "id",
	is_vm = "is_vm",
	tag = "tag",
	updated_at = "updated_at",
	user_id = "user_id",
	wallet_address = "wallet_address"
}
/** update columns of table "coinsystem.wallets" */
export enum coinsystem_wallets_update_column {
	can_mint = "can_mint",
	coin_id = "coin_id",
	created_at = "created_at",
	default = "default",
	id = "id",
	is_vm = "is_vm",
	tag = "tag",
	updated_at = "updated_at",
	user_id = "user_id",
	wallet_address = "wallet_address"
}
/** ordering argument of a cursor */
export enum cursor_ordering {
	ASC = "ASC",
	DESC = "DESC"
}
/** unique or primary key constraints on table "mysql.user" */
export enum mysql_user_constraint {
	user_pkey = "user_pkey"
}
/** select columns of table "mysql.user" */
export enum mysql_user_select_column {
	account_locked = "account_locked",
	alter_priv = "alter_priv",
	alter_routine_priv = "alter_routine_priv",
	authentication_string = "authentication_string",
	create_priv = "create_priv",
	create_role_priv = "create_role_priv",
	create_routine_priv = "create_routine_priv",
	create_tablespace_priv = "create_tablespace_priv",
	create_tmp_table_priv = "create_tmp_table_priv",
	create_user_priv = "create_user_priv",
	create_view_priv = "create_view_priv",
	delete_priv = "delete_priv",
	drop_priv = "drop_priv",
	drop_role_priv = "drop_role_priv",
	event_priv = "event_priv",
	execute_priv = "execute_priv",
	file_priv = "file_priv",
	grant_priv = "grant_priv",
	host = "host",
	index_priv = "index_priv",
	insert_priv = "insert_priv",
	lock_tables_priv = "lock_tables_priv",
	max_connections = "max_connections",
	max_questions = "max_questions",
	max_updates = "max_updates",
	max_user_connections = "max_user_connections",
	password_expired = "password_expired",
	password_last_changed = "password_last_changed",
	password_lifetime = "password_lifetime",
	password_require_current = "password_require_current",
	password_reuse_history = "password_reuse_history",
	password_reuse_time = "password_reuse_time",
	plugin = "plugin",
	process_priv = "process_priv",
	references_priv = "references_priv",
	reload_priv = "reload_priv",
	repl_client_priv = "repl_client_priv",
	repl_slave_priv = "repl_slave_priv",
	select_priv = "select_priv",
	show_db_priv = "show_db_priv",
	show_view_priv = "show_view_priv",
	shutdown_priv = "shutdown_priv",
	ssl_cipher = "ssl_cipher",
	ssl_type = "ssl_type",
	super_priv = "super_priv",
	trigger_priv = "trigger_priv",
	update_priv = "update_priv",
	user = "user",
	user_attributes = "user_attributes",
	x509_issuer = "x509_issuer",
	x509_subject = "x509_subject"
}
/** update columns of table "mysql.user" */
export enum mysql_user_update_column {
	account_locked = "account_locked",
	alter_priv = "alter_priv",
	alter_routine_priv = "alter_routine_priv",
	authentication_string = "authentication_string",
	create_priv = "create_priv",
	create_role_priv = "create_role_priv",
	create_routine_priv = "create_routine_priv",
	create_tablespace_priv = "create_tablespace_priv",
	create_tmp_table_priv = "create_tmp_table_priv",
	create_user_priv = "create_user_priv",
	create_view_priv = "create_view_priv",
	delete_priv = "delete_priv",
	drop_priv = "drop_priv",
	drop_role_priv = "drop_role_priv",
	event_priv = "event_priv",
	execute_priv = "execute_priv",
	file_priv = "file_priv",
	grant_priv = "grant_priv",
	host = "host",
	index_priv = "index_priv",
	insert_priv = "insert_priv",
	lock_tables_priv = "lock_tables_priv",
	max_connections = "max_connections",
	max_questions = "max_questions",
	max_updates = "max_updates",
	max_user_connections = "max_user_connections",
	password_expired = "password_expired",
	password_last_changed = "password_last_changed",
	password_lifetime = "password_lifetime",
	password_require_current = "password_require_current",
	password_reuse_history = "password_reuse_history",
	password_reuse_time = "password_reuse_time",
	plugin = "plugin",
	process_priv = "process_priv",
	references_priv = "references_priv",
	reload_priv = "reload_priv",
	repl_client_priv = "repl_client_priv",
	repl_slave_priv = "repl_slave_priv",
	select_priv = "select_priv",
	show_db_priv = "show_db_priv",
	show_view_priv = "show_view_priv",
	shutdown_priv = "shutdown_priv",
	ssl_cipher = "ssl_cipher",
	ssl_type = "ssl_type",
	super_priv = "super_priv",
	trigger_priv = "trigger_priv",
	update_priv = "update_priv",
	user = "user",
	user_attributes = "user_attributes",
	x509_issuer = "x509_issuer",
	x509_subject = "x509_subject"
}
/** column ordering options */
export enum order_by {
	asc = "asc",
	asc_nulls_first = "asc_nulls_first",
	asc_nulls_last = "asc_nulls_last",
	desc = "desc",
	desc_nulls_first = "desc_nulls_first",
	desc_nulls_last = "desc_nulls_last"
}
/** unique or primary key constraints on table "store.payments" */
export enum store_payments_constraint {
	payments_pkey = "payments_pkey"
}
/** select columns of table "store.payments" */
export enum store_payments_select_column {
	amount = "amount",
	created_at = "created_at",
	currency = "currency",
	gateway = "gateway",
	id = "id",
	purchase_id = "purchase_id",
	remarks = "remarks",
	status = "status"
}
/** update columns of table "store.payments" */
export enum store_payments_update_column {
	amount = "amount",
	created_at = "created_at",
	currency = "currency",
	gateway = "gateway",
	id = "id",
	purchase_id = "purchase_id",
	remarks = "remarks",
	status = "status"
}
/** unique or primary key constraints on table "store.products" */
export enum store_products_constraint {
	products_pkey = "products_pkey"
}
/** select columns of table "store.products" */
export enum store_products_select_column {
	category = "category",
	chapter_type = "chapter_type",
	currency = "currency",
	description = "description",
	enabled = "enabled",
	id = "id",
	image = "image",
	is_store_visible = "is_store_visible",
	label = "label",
	name = "name",
	price = "price",
	purchase_limit = "purchase_limit",
	quantity = "quantity",
	series_id = "series_id",
	sort_num = "sort_num",
	tag = "tag",
	type = "type"
}
/** update columns of table "store.products" */
export enum store_products_update_column {
	category = "category",
	chapter_type = "chapter_type",
	currency = "currency",
	description = "description",
	enabled = "enabled",
	id = "id",
	image = "image",
	is_store_visible = "is_store_visible",
	label = "label",
	name = "name",
	price = "price",
	purchase_limit = "purchase_limit",
	quantity = "quantity",
	series_id = "series_id",
	sort_num = "sort_num",
	tag = "tag",
	type = "type"
}
/** unique or primary key constraints on table "store.purchases" */
export enum store_purchases_constraint {
	purchases_pkey = "purchases_pkey"
}
/** select columns of table "store.purchases" */
export enum store_purchases_select_column {
	created_at = "created_at",
	id = "id",
	product_category = "product_category",
	product_currency = "product_currency",
	product_description = "product_description",
	product_image_url = "product_image_url",
	product_label = "product_label",
	product_meta = "product_meta",
	product_name = "product_name",
	product_price = "product_price",
	product_type = "product_type",
	quantity = "quantity",
	reference_id = "reference_id",
	remarks = "remarks",
	series_id = "series_id",
	status = "status",
	total_price = "total_price",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** update columns of table "store.purchases" */
export enum store_purchases_update_column {
	created_at = "created_at",
	id = "id",
	product_category = "product_category",
	product_currency = "product_currency",
	product_description = "product_description",
	product_image_url = "product_image_url",
	product_label = "product_label",
	product_meta = "product_meta",
	product_name = "product_name",
	product_price = "product_price",
	product_type = "product_type",
	quantity = "quantity",
	reference_id = "reference_id",
	remarks = "remarks",
	series_id = "series_id",
	status = "status",
	total_price = "total_price",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** select columns of table "store.users" */
export enum store_users_select_column {
	about = "about",
	ad_share = "ad_share",
	avatar = "avatar",
	banner = "banner",
	birth_date = "birth_date",
	dark_mode = "dark_mode",
	date_create = "date_create",
	email = "email",
	first_name = "first_name",
	gender = "gender",
	id = "id",
	is_account_active = "is_account_active",
	last_login = "last_login",
	last_name = "last_name",
	nickname = "nickname",
	password = "password",
	remember_code = "remember_code",
	role_id = "role_id",
	username = "username",
	vmuser = "vmuser"
}
/** unique or primary key constraints on table "voyce.admin_roles" */
export enum voyce_admin_roles_constraint {
	admin_roles_pkey = "admin_roles_pkey"
}
/** select columns of table "voyce.admin_roles" */
export enum voyce_admin_roles_select_column {
	created_at = "created_at",
	id = "id",
	role = "role",
	updated_at = "updated_at"
}
/** update columns of table "voyce.admin_roles" */
export enum voyce_admin_roles_update_column {
	created_at = "created_at",
	id = "id",
	role = "role",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.app_banners" */
export enum voyce_app_banners_constraint {
	app_banners_pkey = "app_banners_pkey"
}
/** select columns of table "voyce.app_banners" */
export enum voyce_app_banners_select_column {
	active = "active",
	background = "background",
	created_at = "created_at",
	id = "id",
	link = "link",
	mobile = "mobile",
	overlap = "overlap",
	screen = "screen",
	sort = "sort",
	tablet = "tablet",
	updated_at = "updated_at"
}
/** update columns of table "voyce.app_banners" */
export enum voyce_app_banners_update_column {
	active = "active",
	background = "background",
	created_at = "created_at",
	id = "id",
	link = "link",
	mobile = "mobile",
	overlap = "overlap",
	screen = "screen",
	sort = "sort",
	tablet = "tablet",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.app_components" */
export enum voyce_app_components_constraint {
	app_components_pkey = "app_components_pkey"
}
/** select columns of table "voyce.app_components" */
export enum voyce_app_components_select_column {
	active = "active",
	auth = "auth",
	component = "component",
	created_at = "created_at",
	device = "device",
	id = "id",
	props = "props",
	screen = "screen",
	sort = "sort",
	title = "title",
	updated_at = "updated_at"
}
/** update columns of table "voyce.app_components" */
export enum voyce_app_components_update_column {
	active = "active",
	auth = "auth",
	component = "component",
	created_at = "created_at",
	device = "device",
	id = "id",
	props = "props",
	screen = "screen",
	sort = "sort",
	title = "title",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.audit_trail" */
export enum voyce_audit_trail_constraint {
	audit_trail_pkey = "audit_trail_pkey"
}
/** select columns of table "voyce.audit_trail" */
export enum voyce_audit_trail_select_column {
	action = "action",
	created_at = "created_at",
	id = "id",
	module = "module",
	payload = "payload",
	user_id = "user_id"
}
/** update columns of table "voyce.audit_trail" */
export enum voyce_audit_trail_update_column {
	action = "action",
	created_at = "created_at",
	id = "id",
	module = "module",
	payload = "payload",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.author_profiles" */
export enum voyce_author_profiles_constraint {
	author_profiles_pkey = "author_profiles_pkey",
	author_profiles_user_id_key = "author_profiles_user_id_key"
}
/** select columns of table "voyce.author_profiles" */
export enum voyce_author_profiles_select_column {
	created_at = "created_at",
	followers_count = "followers_count",
	id = "id",
	last_activity_date = "last_activity_date",
	likes_count = "likes_count",
	outdated = "outdated",
	popularity = "popularity",
	total_popularity = "total_popularity",
	user_id = "user_id",
	views_count = "views_count"
}
/** select "voyce_author_profiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.author_profiles" */
export enum voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_and_arguments_columns {
	outdated = "outdated"
}
/** select "voyce_author_profiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.author_profiles" */
export enum voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_or_arguments_columns {
	outdated = "outdated"
}
/** update columns of table "voyce.author_profiles" */
export enum voyce_author_profiles_update_column {
	created_at = "created_at",
	followers_count = "followers_count",
	id = "id",
	last_activity_date = "last_activity_date",
	likes_count = "likes_count",
	outdated = "outdated",
	popularity = "popularity",
	total_popularity = "total_popularity",
	user_id = "user_id",
	views_count = "views_count"
}
/** unique or primary key constraints on table "voyce.balances" */
export enum voyce_balances_constraint {
	balances_pkey = "balances_pkey"
}
/** select columns of table "voyce.balances" */
export enum voyce_balances_select_column {
	amount = "amount",
	created_at = "created_at",
	id = "id",
	transferable_amount = "transferable_amount",
	type = "type",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** update columns of table "voyce.balances" */
export enum voyce_balances_update_column {
	amount = "amount",
	created_at = "created_at",
	id = "id",
	transferable_amount = "transferable_amount",
	type = "type",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.banners" */
export enum voyce_banners_constraint {
	banners_pkey = "banners_pkey"
}
/** select columns of table "voyce.banners" */
export enum voyce_banners_select_column {
	back_image = "back_image",
	front_image = "front_image",
	id = "id",
	order = "order",
	series_id = "series_id",
	subtitle = "subtitle",
	tag = "tag"
}
/** update columns of table "voyce.banners" */
export enum voyce_banners_update_column {
	back_image = "back_image",
	front_image = "front_image",
	id = "id",
	order = "order",
	series_id = "series_id",
	subtitle = "subtitle",
	tag = "tag"
}
/** unique or primary key constraints on table "voyce.chapter_assets" */
export enum voyce_chapter_assets_constraint {
	chapter_assets_pkey = "chapter_assets_pkey"
}
/** select columns of table "voyce.chapter_assets" */
export enum voyce_chapter_assets_select_column {
	chapter_effect_id = "chapter_effect_id",
	id = "id",
	order = "order",
	type = "type",
	url = "url"
}
/** update columns of table "voyce.chapter_assets" */
export enum voyce_chapter_assets_update_column {
	chapter_effect_id = "chapter_effect_id",
	id = "id",
	order = "order",
	type = "type",
	url = "url"
}
/** unique or primary key constraints on table "voyce.chapter_bookmarks" */
export enum voyce_chapter_bookmarks_constraint {
	chapter_bookmarks_id_key = "chapter_bookmarks_id_key",
	chapter_bookmarks_pkey = "chapter_bookmarks_pkey"
}
/** select columns of table "voyce.chapter_bookmarks" */
export enum voyce_chapter_bookmarks_select_column {
	chapter_id = "chapter_id",
	created_at = "created_at",
	id = "id",
	series_id = "series_id",
	user_id = "user_id"
}
/** update columns of table "voyce.chapter_bookmarks" */
export enum voyce_chapter_bookmarks_update_column {
	chapter_id = "chapter_id",
	created_at = "created_at",
	id = "id",
	series_id = "series_id",
	user_id = "user_id"
}
/** select columns of table "voyce.chapter_comments_07252024" */
export enum voyce_chapter_comments_07252024_select_column {
	animated_image = "animated_image",
	attachment_url = "attachment_url",
	chapter_id = "chapter_id",
	comment = "comment",
	created_at = "created_at",
	id = "id",
	last_reacted_at = "last_reacted_at",
	last_reaction_count = "last_reaction_count",
	original_comment_id = "original_comment_id",
	reaction_count = "reaction_count",
	replies_count = "replies_count",
	series_id = "series_id",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.chapter_comments" */
export enum voyce_chapter_comments_constraint {
	chapter_comments_pkey = "chapter_comments_pkey"
}
/** unique or primary key constraints on table "voyce.chapter_comments_reaction" */
export enum voyce_chapter_comments_reaction_constraint {
	chapter_comments_reaction_comment_id_user_id_reaction_key = "chapter_comments_reaction_comment_id_user_id_reaction_key",
	chapter_comments_reaction_pkey = "chapter_comments_reaction_pkey"
}
/** unique or primary key constraints on table "voyce.chapter_comments_reaction_count" */
export enum voyce_chapter_comments_reaction_count_constraint {
	chapter_commments_reaction_count_pkey = "chapter_commments_reaction_count_pkey"
}
/** select columns of table "voyce.chapter_comments_reaction_count" */
export enum voyce_chapter_comments_reaction_count_select_column {
	comment_id = "comment_id",
	count = "count",
	id = "id",
	reaction = "reaction"
}
/** update columns of table "voyce.chapter_comments_reaction_count" */
export enum voyce_chapter_comments_reaction_count_update_column {
	comment_id = "comment_id",
	count = "count",
	id = "id",
	reaction = "reaction"
}
/** select columns of table "voyce.chapter_comments_reaction" */
export enum voyce_chapter_comments_reaction_select_column {
	comment_id = "comment_id",
	created_at = "created_at",
	id = "id",
	reaction = "reaction",
	user_id = "user_id"
}
/** update columns of table "voyce.chapter_comments_reaction" */
export enum voyce_chapter_comments_reaction_update_column {
	comment_id = "comment_id",
	created_at = "created_at",
	id = "id",
	reaction = "reaction",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.chapter_comments_reports" */
export enum voyce_chapter_comments_reports_constraint {
	chapter_comments_reports_pkey = "chapter_comments_reports_pkey",
	chapter_comments_reports_user_id_comment_id_key = "chapter_comments_reports_user_id_comment_id_key"
}
/** select columns of table "voyce.chapter_comments_reports" */
export enum voyce_chapter_comments_reports_select_column {
	comment_id = "comment_id",
	id = "id",
	message = "message",
	type_id = "type_id",
	user_id = "user_id"
}
/** update columns of table "voyce.chapter_comments_reports" */
export enum voyce_chapter_comments_reports_update_column {
	comment_id = "comment_id",
	id = "id",
	message = "message",
	type_id = "type_id",
	user_id = "user_id"
}
/** select columns of table "voyce.chapter_comments" */
export enum voyce_chapter_comments_select_column {
	animated_image = "animated_image",
	attachment_url = "attachment_url",
	chapter_id = "chapter_id",
	comment = "comment",
	created_at = "created_at",
	id = "id",
	last_reacted_at = "last_reacted_at",
	last_reaction_count = "last_reaction_count",
	original_comment_id = "original_comment_id",
	reaction_count = "reaction_count",
	replies_count = "replies_count",
	series_id = "series_id",
	user_id = "user_id"
}
/** update columns of table "voyce.chapter_comments" */
export enum voyce_chapter_comments_update_column {
	animated_image = "animated_image",
	attachment_url = "attachment_url",
	chapter_id = "chapter_id",
	comment = "comment",
	created_at = "created_at",
	id = "id",
	last_reacted_at = "last_reacted_at",
	last_reaction_count = "last_reaction_count",
	original_comment_id = "original_comment_id",
	reaction_count = "reaction_count",
	replies_count = "replies_count",
	series_id = "series_id",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.chapter_contents" */
export enum voyce_chapter_contents_constraint {
	chapter_contents_pkey = "chapter_contents_pkey"
}
/** unique or primary key constraints on table "voyce.chapter_contents_old" */
export enum voyce_chapter_contents_old_constraint {
	chapter_contents_new_pkey = "chapter_contents_new_pkey"
}
/** select columns of table "voyce.chapter_contents_old" */
export enum voyce_chapter_contents_old_select_column {
	chapter_id = "chapter_id",
	content = "content",
	created_at = "created_at",
	id = "id",
	mobile_content = "mobile_content",
	mobile_content_old = "mobile_content_old",
	order = "order",
	updated_at = "updated_at"
}
/** update columns of table "voyce.chapter_contents_old" */
export enum voyce_chapter_contents_old_update_column {
	chapter_id = "chapter_id",
	content = "content",
	created_at = "created_at",
	id = "id",
	mobile_content = "mobile_content",
	mobile_content_old = "mobile_content_old",
	order = "order",
	updated_at = "updated_at"
}
/** select columns of table "voyce.chapter_contents" */
export enum voyce_chapter_contents_select_column {
	chapter_id = "chapter_id",
	content = "content",
	content_short = "content_short",
	created_at = "created_at",
	id = "id",
	mobile_content = "mobile_content",
	mobile_content_old = "mobile_content_old",
	order = "order",
	updated_at = "updated_at"
}
/** update columns of table "voyce.chapter_contents" */
export enum voyce_chapter_contents_update_column {
	chapter_id = "chapter_id",
	content = "content",
	content_short = "content_short",
	created_at = "created_at",
	id = "id",
	mobile_content = "mobile_content",
	mobile_content_old = "mobile_content_old",
	order = "order",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.chapter_effects" */
export enum voyce_chapter_effects_constraint {
	chapter_effects_pkey = "chapter_effects_pkey"
}
/** select columns of table "voyce.chapter_effects" */
export enum voyce_chapter_effects_select_column {
	animation_offset = "animation_offset",
	chapter_id = "chapter_id",
	chapter_img_id = "chapter_img_id",
	duration = "duration",
	effect_type = "effect_type",
	end_x = "end_x",
	end_y = "end_y",
	id = "id",
	strength = "strength"
}
/** update columns of table "voyce.chapter_effects" */
export enum voyce_chapter_effects_update_column {
	animation_offset = "animation_offset",
	chapter_id = "chapter_id",
	chapter_img_id = "chapter_img_id",
	duration = "duration",
	effect_type = "effect_type",
	end_x = "end_x",
	end_y = "end_y",
	id = "id",
	strength = "strength"
}
/** select columns of table "voyce.chapter_images_07252024" */
export enum voyce_chapter_images_07252024_select_column {
	chapter_id = "chapter_id",
	created_at = "created_at",
	group = "group",
	id = "id",
	image = "image",
	sort_order = "sort_order",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.chapter_images" */
export enum voyce_chapter_images_constraint {
	pk_chapter_images = "pk_chapter_images"
}
/** select columns of table "voyce.chapter_images" */
export enum voyce_chapter_images_select_column {
	chapter_id = "chapter_id",
	created_at = "created_at",
	group = "group",
	id = "id",
	image = "image",
	sort_order = "sort_order",
	updated_at = "updated_at"
}
/** update columns of table "voyce.chapter_images" */
export enum voyce_chapter_images_update_column {
	chapter_id = "chapter_id",
	created_at = "created_at",
	group = "group",
	id = "id",
	image = "image",
	sort_order = "sort_order",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.chapter_likes" */
export enum voyce_chapter_likes_constraint {
	chapter_likes_id_key = "chapter_likes_id_key",
	chapter_likes_pkey = "chapter_likes_pkey"
}
/** select columns of table "voyce.chapter_likes" */
export enum voyce_chapter_likes_select_column {
	chapter_id = "chapter_id",
	created_at = "created_at",
	id = "id",
	is_poll_like = "is_poll_like",
	series_id = "series_id",
	user_id = "user_id"
}
/** select "voyce_chapter_likes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapter_likes" */
export enum voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_and_arguments_columns {
	is_poll_like = "is_poll_like"
}
/** select "voyce_chapter_likes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapter_likes" */
export enum voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_or_arguments_columns {
	is_poll_like = "is_poll_like"
}
/** update columns of table "voyce.chapter_likes" */
export enum voyce_chapter_likes_update_column {
	chapter_id = "chapter_id",
	created_at = "created_at",
	id = "id",
	is_poll_like = "is_poll_like",
	series_id = "series_id",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.chapter_locks" */
export enum voyce_chapter_locks_constraint {
	chapter_locks_pkey = "chapter_locks_pkey"
}
/** select columns of table "voyce.chapter_locks" */
export enum voyce_chapter_locks_select_column {
	buy_currency = "buy_currency",
	buy_price = "buy_price",
	chapter_id = "chapter_id",
	created_at = "created_at",
	creator_id = "creator_id",
	end_date = "end_date",
	id = "id",
	is_active = "is_active",
	rent_currency = "rent_currency",
	rent_duration = "rent_duration",
	rent_price = "rent_price",
	series_id = "series_id",
	start_date = "start_date",
	updated_at = "updated_at"
}
/** select "voyce_chapter_locks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapter_locks" */
export enum voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_and_arguments_columns {
	is_active = "is_active"
}
/** select "voyce_chapter_locks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapter_locks" */
export enum voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_or_arguments_columns {
	is_active = "is_active"
}
/** update columns of table "voyce.chapter_locks" */
export enum voyce_chapter_locks_update_column {
	buy_currency = "buy_currency",
	buy_price = "buy_price",
	chapter_id = "chapter_id",
	created_at = "created_at",
	creator_id = "creator_id",
	end_date = "end_date",
	id = "id",
	is_active = "is_active",
	rent_currency = "rent_currency",
	rent_duration = "rent_duration",
	rent_price = "rent_price",
	series_id = "series_id",
	start_date = "start_date",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.chapter_panel_views" */
export enum voyce_chapter_panel_views_constraint {
	chapter_panel_views_pkey = "chapter_panel_views_pkey"
}
/** select columns of table "voyce.chapter_panel_views" */
export enum voyce_chapter_panel_views_select_column {
	chapter_id = "chapter_id",
	current_panel = "current_panel",
	id = "id",
	panel_id = "panel_id",
	panels = "panels",
	percentage = "percentage",
	series_id = "series_id",
	total_panels = "total_panels",
	type = "type",
	updated_at = "updated_at",
	user_id = "user_id",
	word_idx = "word_idx"
}
/** update columns of table "voyce.chapter_panel_views" */
export enum voyce_chapter_panel_views_update_column {
	chapter_id = "chapter_id",
	current_panel = "current_panel",
	id = "id",
	panel_id = "panel_id",
	panels = "panels",
	percentage = "percentage",
	series_id = "series_id",
	total_panels = "total_panels",
	type = "type",
	updated_at = "updated_at",
	user_id = "user_id",
	word_idx = "word_idx"
}
/** unique or primary key constraints on table "voyce.chapter_panels" */
export enum voyce_chapter_panels_constraint {
	chapter_panels_pkey = "chapter_panels_pkey"
}
/** select columns of table "voyce.chapter_panels" */
export enum voyce_chapter_panels_select_column {
	chapter_id = "chapter_id",
	comments_count = "comments_count",
	created_at = "created_at",
	id = "id",
	image = "image",
	image_id = "image_id",
	last_activity_date = "last_activity_date",
	likes_count = "likes_count",
	outdated = "outdated",
	popularity = "popularity",
	popularity_weekly = "popularity_weekly",
	reactions_count = "reactions_count",
	series_id = "series_id",
	spoiler = "spoiler",
	total_popularity = "total_popularity"
}
/** select "voyce_chapter_panels_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapter_panels" */
export enum voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_and_arguments_columns {
	outdated = "outdated",
	spoiler = "spoiler"
}
/** select "voyce_chapter_panels_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapter_panels" */
export enum voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_or_arguments_columns {
	outdated = "outdated",
	spoiler = "spoiler"
}
/** update columns of table "voyce.chapter_panels" */
export enum voyce_chapter_panels_update_column {
	chapter_id = "chapter_id",
	comments_count = "comments_count",
	created_at = "created_at",
	id = "id",
	image = "image",
	image_id = "image_id",
	last_activity_date = "last_activity_date",
	likes_count = "likes_count",
	outdated = "outdated",
	popularity = "popularity",
	popularity_weekly = "popularity_weekly",
	reactions_count = "reactions_count",
	series_id = "series_id",
	spoiler = "spoiler",
	total_popularity = "total_popularity"
}
/** unique or primary key constraints on table "voyce.chapter_publish_schedules" */
export enum voyce_chapter_publish_schedules_constraint {
	chapter_publish_schedules_pkey = "chapter_publish_schedules_pkey"
}
/** select columns of table "voyce.chapter_publish_schedules" */
export enum voyce_chapter_publish_schedules_select_column {
	chapter_id = "chapter_id",
	created_at = "created_at",
	date = "date",
	epoch = "epoch",
	gmt_offset = "gmt_offset",
	id = "id",
	is_done = "is_done",
	publish_date = "publish_date",
	series_id = "series_id",
	time = "time"
}
/** update columns of table "voyce.chapter_publish_schedules" */
export enum voyce_chapter_publish_schedules_update_column {
	chapter_id = "chapter_id",
	created_at = "created_at",
	date = "date",
	epoch = "epoch",
	gmt_offset = "gmt_offset",
	id = "id",
	is_done = "is_done",
	publish_date = "publish_date",
	series_id = "series_id",
	time = "time"
}
/** unique or primary key constraints on table "voyce.chapter_reactions" */
export enum voyce_chapter_reactions_constraint {
	chapter_reactions_pkey = "chapter_reactions_pkey"
}
/** unique or primary key constraints on table "voyce.chapter_reactions_count" */
export enum voyce_chapter_reactions_count_constraint {
	chapter_reactions_count_pkey = "chapter_reactions_count_pkey"
}
/** select columns of table "voyce.chapter_reactions_count" */
export enum voyce_chapter_reactions_count_select_column {
	chapter_id = "chapter_id",
	count = "count",
	id = "id",
	reaction = "reaction"
}
/** update columns of table "voyce.chapter_reactions_count" */
export enum voyce_chapter_reactions_count_update_column {
	chapter_id = "chapter_id",
	count = "count",
	id = "id",
	reaction = "reaction"
}
/** select columns of table "voyce.chapter_reactions" */
export enum voyce_chapter_reactions_select_column {
	created_at = "created_at",
	id = "id",
	linked_entity_id = "linked_entity_id",
	reaction = "reaction",
	user_id = "user_id"
}
/** update columns of table "voyce.chapter_reactions" */
export enum voyce_chapter_reactions_update_column {
	created_at = "created_at",
	id = "id",
	linked_entity_id = "linked_entity_id",
	reaction = "reaction",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.chapter_view_logs" */
export enum voyce_chapter_view_logs_constraint {
	chapter_view_logs_pkey = "chapter_view_logs_pkey"
}
/** select columns of table "voyce.chapter_view_logs" */
export enum voyce_chapter_view_logs_select_column {
	chapter_id = "chapter_id",
	created_at = "created_at",
	device_id = "device_id",
	gtag = "gtag",
	id = "id",
	origin = "origin",
	page = "page",
	panel_id = "panel_id",
	series_id = "series_id",
	session_api = "session_api",
	session_id = "session_id",
	source = "source",
	user_id = "user_id",
	user_ip = "user_ip"
}
/** update columns of table "voyce.chapter_view_logs" */
export enum voyce_chapter_view_logs_update_column {
	chapter_id = "chapter_id",
	created_at = "created_at",
	device_id = "device_id",
	gtag = "gtag",
	id = "id",
	origin = "origin",
	page = "page",
	panel_id = "panel_id",
	series_id = "series_id",
	session_api = "session_api",
	session_id = "session_id",
	source = "source",
	user_id = "user_id",
	user_ip = "user_ip"
}
/** unique or primary key constraints on table "voyce.chapter_views" */
export enum voyce_chapter_views_constraint {
	chapter_views_view_hash_key = "chapter_views_view_hash_key",
	pk_chapter_views = "pk_chapter_views"
}
/** select columns of table "voyce.chapter_views" */
export enum voyce_chapter_views_select_column {
	chapter_id = "chapter_id",
	id = "id",
	is_read = "is_read",
	page = "page",
	series_id = "series_id",
	updated_at = "updated_at",
	user_id = "user_id",
	user_ip = "user_ip",
	view_hash = "view_hash"
}
/** select "voyce_chapter_views_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapter_views" */
export enum voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_and_arguments_columns {
	is_read = "is_read"
}
/** select "voyce_chapter_views_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapter_views" */
export enum voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_or_arguments_columns {
	is_read = "is_read"
}
/** update columns of table "voyce.chapter_views" */
export enum voyce_chapter_views_update_column {
	chapter_id = "chapter_id",
	id = "id",
	is_read = "is_read",
	page = "page",
	series_id = "series_id",
	updated_at = "updated_at",
	user_id = "user_id",
	user_ip = "user_ip",
	view_hash = "view_hash"
}
/** select columns of table "voyce.chapters_07252024" */
export enum voyce_chapters_07252024_select_column {
	created_at = "created_at",
	description = "description",
	description_old = "description_old",
	displayed_exclusive = "displayed_exclusive",
	formatted_content = "formatted_content",
	formatted_content_v2 = "formatted_content_v2",
	formatted_content_v3 = "formatted_content_v3",
	has_text = "has_text",
	id = "id",
	is_deleted = "is_deleted",
	order = "order",
	publish = "publish",
	publish_date = "publish_date",
	reactions_count = "reactions_count",
	reading_view = "reading_view",
	series_id = "series_id",
	status = "status",
	thumbnail = "thumbnail",
	title = "title",
	type = "type",
	updated_at = "updated_at",
	view_count = "view_count"
}
/** unique or primary key constraints on table "voyce.chapters" */
export enum voyce_chapters_constraint {
	pk_chapters = "pk_chapters"
}
/** select columns of table "voyce.chapters" */
export enum voyce_chapters_select_column {
	created_at = "created_at",
	description = "description",
	description_old = "description_old",
	displayed_exclusive = "displayed_exclusive",
	formatted_content = "formatted_content",
	formatted_content_v2 = "formatted_content_v2",
	formatted_content_v3 = "formatted_content_v3",
	has_text = "has_text",
	id = "id",
	is_deleted = "is_deleted",
	order = "order",
	publish = "publish",
	publish_date = "publish_date",
	reactions_count = "reactions_count",
	reading_view = "reading_view",
	series_id = "series_id",
	status = "status",
	thumbnail = "thumbnail",
	title = "title",
	type = "type",
	updated_at = "updated_at",
	view_count = "view_count"
}
/** select "voyce_chapters_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.chapters" */
export enum voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_and_arguments_columns {
	displayed_exclusive = "displayed_exclusive",
	has_text = "has_text",
	is_deleted = "is_deleted"
}
/** select "voyce_chapters_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.chapters" */
export enum voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_or_arguments_columns {
	displayed_exclusive = "displayed_exclusive",
	has_text = "has_text",
	is_deleted = "is_deleted"
}
/** update columns of table "voyce.chapters" */
export enum voyce_chapters_update_column {
	created_at = "created_at",
	description = "description",
	description_old = "description_old",
	displayed_exclusive = "displayed_exclusive",
	formatted_content = "formatted_content",
	formatted_content_v2 = "formatted_content_v2",
	formatted_content_v3 = "formatted_content_v3",
	has_text = "has_text",
	id = "id",
	is_deleted = "is_deleted",
	order = "order",
	publish = "publish",
	publish_date = "publish_date",
	reactions_count = "reactions_count",
	reading_view = "reading_view",
	series_id = "series_id",
	status = "status",
	thumbnail = "thumbnail",
	title = "title",
	type = "type",
	updated_at = "updated_at",
	view_count = "view_count"
}
/** unique or primary key constraints on table "voyce.collaborator_roles" */
export enum voyce_collaborator_roles_constraint {
	collaborator_roles_pkey = "collaborator_roles_pkey"
}
/** select columns of table "voyce.collaborator_roles" */
export enum voyce_collaborator_roles_select_column {
	created_at = "created_at",
	id = "id",
	role = "role",
	updated_at = "updated_at"
}
/** update columns of table "voyce.collaborator_roles" */
export enum voyce_collaborator_roles_update_column {
	created_at = "created_at",
	id = "id",
	role = "role",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.comic_types" */
export enum voyce_comic_types_constraint {
	pk_comic_types = "pk_comic_types"
}
/** select columns of table "voyce.comic_types" */
export enum voyce_comic_types_select_column {
	created_at = "created_at",
	id = "id",
	klaviyo_id = "klaviyo_id",
	title = "title",
	updated_at = "updated_at"
}
/** update columns of table "voyce.comic_types" */
export enum voyce_comic_types_update_column {
	created_at = "created_at",
	id = "id",
	klaviyo_id = "klaviyo_id",
	title = "title",
	updated_at = "updated_at"
}
/** select columns of table "voyce.comments_top_mat" */
export enum voyce_comments_top_mat_select_column {
	comment_type = "comment_type",
	id = "id",
	reaction_count = "reaction_count",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.community_blurbs" */
export enum voyce_community_blurbs_constraint {
	community_blurbs_pkey = "community_blurbs_pkey"
}
/** select columns of table "voyce.community_blurbs" */
export enum voyce_community_blurbs_select_column {
	avatar = "avatar",
	content = "content",
	id = "id",
	name = "name",
	order = "order",
	title = "title",
	updated_at = "updated_at"
}
/** update columns of table "voyce.community_blurbs" */
export enum voyce_community_blurbs_update_column {
	avatar = "avatar",
	content = "content",
	id = "id",
	name = "name",
	order = "order",
	title = "title",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.contact_us" */
export enum voyce_contact_us_constraint {
	contact_us_pkey = "contact_us_pkey"
}
/** select columns of table "voyce.contact_us" */
export enum voyce_contact_us_select_column {
	created_at = "created_at",
	email = "email",
	first_name = "first_name",
	id = "id",
	last_name = "last_name",
	message = "message",
	number = "number",
	reason = "reason"
}
/** update columns of table "voyce.contact_us" */
export enum voyce_contact_us_update_column {
	created_at = "created_at",
	email = "email",
	first_name = "first_name",
	id = "id",
	last_name = "last_name",
	message = "message",
	number = "number",
	reason = "reason"
}
/** unique or primary key constraints on table "voyce.content_report" */
export enum voyce_content_report_constraint {
	content_report_pkey = "content_report_pkey"
}
/** select columns of table "voyce.content_report" */
export enum voyce_content_report_select_column {
	created_at = "created_at",
	extras = "extras",
	id = "id",
	linked_id = "linked_id",
	reason = "reason",
	type = "type",
	user_id = "user_id"
}
/** update columns of table "voyce.content_report" */
export enum voyce_content_report_update_column {
	created_at = "created_at",
	extras = "extras",
	id = "id",
	linked_id = "linked_id",
	reason = "reason",
	type = "type",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.contest_data" */
export enum voyce_contest_data_constraint {
	contest_data_pkey = "contest_data_pkey"
}
/** select columns of table "voyce.contest_data" */
export enum voyce_contest_data_select_column {
	age = "age",
	created_at = "created_at",
	discord_username = "discord_username",
	first_arc = "first_arc",
	id = "id",
	name = "name",
	pdf = "pdf",
	social_media = "social_media",
	story_pitch = "story_pitch",
	story_summary = "story_summary",
	target_audience = "target_audience",
	user_id = "user_id",
	where_did_hear = "where_did_hear"
}
/** update columns of table "voyce.contest_data" */
export enum voyce_contest_data_update_column {
	age = "age",
	created_at = "created_at",
	discord_username = "discord_username",
	first_arc = "first_arc",
	id = "id",
	name = "name",
	pdf = "pdf",
	social_media = "social_media",
	story_pitch = "story_pitch",
	story_summary = "story_summary",
	target_audience = "target_audience",
	user_id = "user_id",
	where_did_hear = "where_did_hear"
}
/** unique or primary key constraints on table "voyce.countries" */
export enum voyce_countries_constraint {
	pk_countries = "pk_countries"
}
/** select columns of table "voyce.countries" */
export enum voyce_countries_select_column {
	date_create = "date_create",
	description = "description",
	feature = "feature",
	flag = "flag",
	id = "id",
	name = "name",
	phone_code = "phone_code",
	publish = "publish",
	sort_name = "sort_name"
}
/** update columns of table "voyce.countries" */
export enum voyce_countries_update_column {
	date_create = "date_create",
	description = "description",
	feature = "feature",
	flag = "flag",
	id = "id",
	name = "name",
	phone_code = "phone_code",
	publish = "publish",
	sort_name = "sort_name"
}
/** unique or primary key constraints on table "voyce.csat_ratings" */
export enum voyce_csat_ratings_constraint {
	csat_ratings_pkey = "csat_ratings_pkey"
}
/** unique or primary key constraints on table "voyce.csat_ratings_data" */
export enum voyce_csat_ratings_data_constraint {
	csat_ratings_data_pkey = "csat_ratings_data_pkey"
}
/** select columns of table "voyce.csat_ratings_data" */
export enum voyce_csat_ratings_data_select_column {
	created_at = "created_at",
	csat_rating_id = "csat_rating_id",
	id = "id",
	image = "image",
	label = "label",
	order = "order",
	rating = "rating"
}
/** update columns of table "voyce.csat_ratings_data" */
export enum voyce_csat_ratings_data_update_column {
	created_at = "created_at",
	csat_rating_id = "csat_rating_id",
	id = "id",
	image = "image",
	label = "label",
	order = "order",
	rating = "rating"
}
/** select columns of table "voyce.csat_ratings" */
export enum voyce_csat_ratings_select_column {
	cancel_text = "cancel_text",
	comment = "comment",
	created_at = "created_at",
	desc = "desc",
	id = "id",
	screen = "screen",
	title = "title",
	version = "version"
}
/** update columns of table "voyce.csat_ratings" */
export enum voyce_csat_ratings_update_column {
	cancel_text = "cancel_text",
	comment = "comment",
	created_at = "created_at",
	desc = "desc",
	id = "id",
	screen = "screen",
	title = "title",
	version = "version"
}
/** unique or primary key constraints on table "voyce.education_types" */
export enum voyce_education_types_constraint {
	pk_education_types = "pk_education_types"
}
/** select columns of table "voyce.education_types" */
export enum voyce_education_types_select_column {
	date_create = "date_create",
	id = "id",
	title = "title"
}
/** update columns of table "voyce.education_types" */
export enum voyce_education_types_update_column {
	date_create = "date_create",
	id = "id",
	title = "title"
}
/** unique or primary key constraints on table "voyce.erp_login_roles" */
export enum voyce_erp_login_roles_constraint {
	pk_erp_login_roles = "pk_erp_login_roles"
}
/** select columns of table "voyce.erp_login_roles" */
export enum voyce_erp_login_roles_select_column {
	id = "id",
	role_id = "role_id"
}
/** update columns of table "voyce.erp_login_roles" */
export enum voyce_erp_login_roles_update_column {
	id = "id",
	role_id = "role_id"
}
/** unique or primary key constraints on table "voyce.etl_series_views_logs" */
export enum voyce_etl_series_views_logs_constraint {
	etl_series_views_logs_pkey = "etl_series_views_logs_pkey"
}
/** select columns of table "voyce.etl_series_views_logs" */
export enum voyce_etl_series_views_logs_select_column {
	created_at = "created_at",
	glue_epoc = "glue_epoc",
	id = "id"
}
/** update columns of table "voyce.etl_series_views_logs" */
export enum voyce_etl_series_views_logs_update_column {
	created_at = "created_at",
	glue_epoc = "glue_epoc",
	id = "id"
}
/** unique or primary key constraints on table "voyce.event_schedules" */
export enum voyce_event_schedules_constraint {
	event_schedules_pkey = "event_schedules_pkey"
}
/** select columns of table "voyce.event_schedules" */
export enum voyce_event_schedules_select_column {
	active = "active",
	bg_image = "bg_image",
	created_at = "created_at",
	date_time = "date_time",
	description = "description",
	id = "id",
	link = "link",
	main_event = "main_event",
	main_image = "main_image",
	title = "title",
	updated_at = "updated_at"
}
/** update columns of table "voyce.event_schedules" */
export enum voyce_event_schedules_update_column {
	active = "active",
	bg_image = "bg_image",
	created_at = "created_at",
	date_time = "date_time",
	description = "description",
	id = "id",
	link = "link",
	main_event = "main_event",
	main_image = "main_image",
	title = "title",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.genres" */
export enum voyce_genres_constraint {
	pk_genres = "pk_genres"
}
/** select columns of table "voyce.genres" */
export enum voyce_genres_select_column {
	color = "color",
	created_at = "created_at",
	id = "id",
	is_trending = "is_trending",
	klaviyo_id = "klaviyo_id",
	thumbnail = "thumbnail",
	title = "title",
	updated_at = "updated_at"
}
/** update columns of table "voyce.genres" */
export enum voyce_genres_update_column {
	color = "color",
	created_at = "created_at",
	id = "id",
	is_trending = "is_trending",
	klaviyo_id = "klaviyo_id",
	thumbnail = "thumbnail",
	title = "title",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.guest_gtags" */
export enum voyce_guest_gtags_constraint {
	guest_gtags_gtag_v2_key = "guest_gtags_gtag_v2_key",
	guest_gtags_pkey = "guest_gtags_pkey"
}
/** select columns of table "voyce.guest_gtags" */
export enum voyce_guest_gtags_select_column {
	created_at = "created_at",
	device_id = "device_id",
	gtag = "gtag",
	gtag_v2 = "gtag_v2",
	id = "id",
	klaviyo_id = "klaviyo_id",
	source = "source",
	user_ip = "user_ip",
	username = "username"
}
/** update columns of table "voyce.guest_gtags" */
export enum voyce_guest_gtags_update_column {
	created_at = "created_at",
	device_id = "device_id",
	gtag = "gtag",
	gtag_v2 = "gtag_v2",
	id = "id",
	klaviyo_id = "klaviyo_id",
	source = "source",
	user_ip = "user_ip",
	username = "username"
}
/** unique or primary key constraints on table "voyce.metabases_access" */
export enum voyce_metabases_access_constraint {
	metabases_access_pkey = "metabases_access_pkey"
}
/** select columns of table "voyce.metabases_access" */
export enum voyce_metabases_access_select_column {
	created_at = "created_at",
	id = "id",
	metabase_id = "metabase_id",
	role_id = "role_id",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** update columns of table "voyce.metabases_access" */
export enum voyce_metabases_access_update_column {
	created_at = "created_at",
	id = "id",
	metabase_id = "metabase_id",
	role_id = "role_id",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.metabases" */
export enum voyce_metabases_constraint {
	metabases_pkey = "metabases_pkey"
}
/** select columns of table "voyce.metabases" */
export enum voyce_metabases_select_column {
	created_at = "created_at",
	dashboard_id = "dashboard_id",
	id = "id",
	is_active = "is_active",
	order = "order",
	title = "title",
	updated_at = "updated_at"
}
/** update columns of table "voyce.metabases" */
export enum voyce_metabases_update_column {
	created_at = "created_at",
	dashboard_id = "dashboard_id",
	id = "id",
	is_active = "is_active",
	order = "order",
	title = "title",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.newsletter" */
export enum voyce_newsletter_constraint {
	newsletter_pkey = "newsletter_pkey"
}
/** select columns of table "voyce.newsletter" */
export enum voyce_newsletter_select_column {
	created_at = "created_at",
	email = "email",
	id = "id",
	user_id = "user_id"
}
/** update columns of table "voyce.newsletter" */
export enum voyce_newsletter_update_column {
	created_at = "created_at",
	email = "email",
	id = "id",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.notification_series_settings" */
export enum voyce_notification_series_settings_constraint {
	notification_series_settings_pkey = "notification_series_settings_pkey",
	notification_series_settings_series_id_key = "notification_series_settings_series_id_key"
}
/** select columns of table "voyce.notification_series_settings" */
export enum voyce_notification_series_settings_select_column {
	chapter_comment_email = "chapter_comment_email",
	chapter_comment_push = "chapter_comment_push",
	chapter_like_email = "chapter_like_email",
	chapter_like_push = "chapter_like_push",
	chapter_react_email = "chapter_react_email",
	chapter_react_push = "chapter_react_push",
	id = "id",
	series_id = "series_id",
	subscription_email = "subscription_email",
	subscription_push = "subscription_push",
	user_id = "user_id"
}
/** update columns of table "voyce.notification_series_settings" */
export enum voyce_notification_series_settings_update_column {
	chapter_comment_email = "chapter_comment_email",
	chapter_comment_push = "chapter_comment_push",
	chapter_like_email = "chapter_like_email",
	chapter_like_push = "chapter_like_push",
	chapter_react_email = "chapter_react_email",
	chapter_react_push = "chapter_react_push",
	id = "id",
	series_id = "series_id",
	subscription_email = "subscription_email",
	subscription_push = "subscription_push",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.notification_settings" */
export enum voyce_notification_settings_constraint {
	notification_settings_pkey = "notification_settings_pkey",
	notification_settings_user_id_key = "notification_settings_user_id_key"
}
/** select columns of table "voyce.notification_settings" */
export enum voyce_notification_settings_select_column {
	chapter_comment_email = "chapter_comment_email",
	chapter_comment_push = "chapter_comment_push",
	chapter_like_email = "chapter_like_email",
	chapter_like_push = "chapter_like_push",
	chapter_react_email = "chapter_react_email",
	chapter_react_push = "chapter_react_push",
	chapter_release_email = "chapter_release_email",
	chapter_release_push = "chapter_release_push",
	comment_react_email = "comment_react_email",
	comment_react_push = "comment_react_push",
	comment_reply_email = "comment_reply_email",
	comment_reply_push = "comment_reply_push",
	event_email = "event_email",
	event_push = "event_push",
	follow_email = "follow_email",
	follow_push = "follow_push",
	id = "id",
	promotion_email = "promotion_email",
	promotion_push = "promotion_push",
	recommended_series_email = "recommended_series_email",
	recommended_series_push = "recommended_series_push",
	reward_email = "reward_email",
	reward_push = "reward_push",
	subscription_email = "subscription_email",
	subscription_push = "subscription_push",
	user_id = "user_id"
}
/** update columns of table "voyce.notification_settings" */
export enum voyce_notification_settings_update_column {
	chapter_comment_email = "chapter_comment_email",
	chapter_comment_push = "chapter_comment_push",
	chapter_like_email = "chapter_like_email",
	chapter_like_push = "chapter_like_push",
	chapter_react_email = "chapter_react_email",
	chapter_react_push = "chapter_react_push",
	chapter_release_email = "chapter_release_email",
	chapter_release_push = "chapter_release_push",
	comment_react_email = "comment_react_email",
	comment_react_push = "comment_react_push",
	comment_reply_email = "comment_reply_email",
	comment_reply_push = "comment_reply_push",
	event_email = "event_email",
	event_push = "event_push",
	follow_email = "follow_email",
	follow_push = "follow_push",
	id = "id",
	promotion_email = "promotion_email",
	promotion_push = "promotion_push",
	recommended_series_email = "recommended_series_email",
	recommended_series_push = "recommended_series_push",
	reward_email = "reward_email",
	reward_push = "reward_push",
	subscription_email = "subscription_email",
	subscription_push = "subscription_push",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.notifications" */
export enum voyce_notifications_constraint {
	notifications_pkey = "notifications_pkey"
}
/** select columns of table "voyce.notifications" */
export enum voyce_notifications_select_column {
	child_count = "child_count",
	created_at = "created_at",
	data = "data",
	email_status = "email_status",
	emailed_at = "emailed_at",
	id = "id",
	linked_id = "linked_id",
	parent_id = "parent_id",
	pushed_at = "pushed_at",
	read_at = "read_at",
	type = "type",
	unsubscribe_id = "unsubscribe_id",
	user_id = "user_id"
}
/** update columns of table "voyce.notifications" */
export enum voyce_notifications_update_column {
	child_count = "child_count",
	created_at = "created_at",
	data = "data",
	email_status = "email_status",
	emailed_at = "emailed_at",
	id = "id",
	linked_id = "linked_id",
	parent_id = "parent_id",
	pushed_at = "pushed_at",
	read_at = "read_at",
	type = "type",
	unsubscribe_id = "unsubscribe_id",
	user_id = "user_id"
}
/** select columns of table "voyce.originals_feed" */
export enum voyce_originals_feed_select_column {
	category = "category",
	created_at = "created_at",
	desc = "desc",
	id = "id",
	image = "image",
	title = "title",
	url = "url"
}
/** unique or primary key constraints on table "voyce.page_banners" */
export enum voyce_page_banners_constraint {
	page_banners_pkey = "page_banners_pkey"
}
/** select columns of table "voyce.page_banners" */
export enum voyce_page_banners_select_column {
	active = "active",
	background = "background",
	created_at = "created_at",
	desktop = "desktop",
	id = "id",
	link = "link",
	mobile = "mobile",
	overlap = "overlap",
	page = "page",
	sort = "sort",
	tablet = "tablet",
	updated_at = "updated_at"
}
/** update columns of table "voyce.page_banners" */
export enum voyce_page_banners_update_column {
	active = "active",
	background = "background",
	created_at = "created_at",
	desktop = "desktop",
	id = "id",
	link = "link",
	mobile = "mobile",
	overlap = "overlap",
	page = "page",
	sort = "sort",
	tablet = "tablet",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.page_components" */
export enum voyce_page_components_constraint {
	page_components_pkey = "page_components_pkey"
}
/** select columns of table "voyce.page_components" */
export enum voyce_page_components_select_column {
	active = "active",
	auth = "auth",
	component = "component",
	created_at = "created_at",
	device = "device",
	id = "id",
	page = "page",
	props = "props",
	sort = "sort",
	title = "title",
	updated_at = "updated_at"
}
/** update columns of table "voyce.page_components" */
export enum voyce_page_components_update_column {
	active = "active",
	auth = "auth",
	component = "component",
	created_at = "created_at",
	device = "device",
	id = "id",
	page = "page",
	props = "props",
	sort = "sort",
	title = "title",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.pages" */
export enum voyce_pages_constraint {
	pk_pages = "pk_pages"
}
/** select columns of table "voyce.pages" */
export enum voyce_pages_select_column {
	content = "content",
	created_at = "created_at",
	id = "id",
	slug = "slug",
	title = "title",
	updated_at = "updated_at"
}
/** update columns of table "voyce.pages" */
export enum voyce_pages_update_column {
	content = "content",
	created_at = "created_at",
	id = "id",
	slug = "slug",
	title = "title",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.panels_comments" */
export enum voyce_panels_comments_constraint {
	panels_comments_pkey = "panels_comments_pkey"
}
/** select columns of table "voyce.panels_comments" */
export enum voyce_panels_comments_select_column {
	animated_image = "animated_image",
	created_at = "created_at",
	id = "id",
	image = "image",
	linked_id = "linked_id",
	parent_id = "parent_id",
	reaction_count = "reaction_count",
	replies_count = "replies_count",
	text = "text",
	user_id = "user_id"
}
/** update columns of table "voyce.panels_comments" */
export enum voyce_panels_comments_update_column {
	animated_image = "animated_image",
	created_at = "created_at",
	id = "id",
	image = "image",
	linked_id = "linked_id",
	parent_id = "parent_id",
	reaction_count = "reaction_count",
	replies_count = "replies_count",
	text = "text",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.panels_reactions" */
export enum voyce_panels_reactions_constraint {
	panels_reactions_pkey = "panels_reactions_pkey",
	panels_reactions_user_id_linked_entity_id_comment_id_reacti_key = "panels_reactions_user_id_linked_entity_id_comment_id_reacti_key"
}
/** unique or primary key constraints on table "voyce.panels_reactions_count" */
export enum voyce_panels_reactions_count_constraint {
	panels_reactions_count_pkey = "panels_reactions_count_pkey"
}
/** select columns of table "voyce.panels_reactions_count" */
export enum voyce_panels_reactions_count_select_column {
	count = "count",
	id = "id",
	panel_id = "panel_id",
	reaction = "reaction"
}
/** update columns of table "voyce.panels_reactions_count" */
export enum voyce_panels_reactions_count_update_column {
	count = "count",
	id = "id",
	panel_id = "panel_id",
	reaction = "reaction"
}
/** select columns of table "voyce.panels_reactions" */
export enum voyce_panels_reactions_select_column {
	comment_id = "comment_id",
	created_at = "created_at",
	id = "id",
	linked_entity_id = "linked_entity_id",
	reaction = "reaction",
	user_id = "user_id"
}
/** update columns of table "voyce.panels_reactions" */
export enum voyce_panels_reactions_update_column {
	comment_id = "comment_id",
	created_at = "created_at",
	id = "id",
	linked_entity_id = "linked_entity_id",
	reaction = "reaction",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.payments_to_creators" */
export enum voyce_payments_to_creators_constraint {
	payments_to_creators_pkey = "payments_to_creators_pkey"
}
/** select columns of table "voyce.payments_to_creators" */
export enum voyce_payments_to_creators_select_column {
	amount = "amount",
	created_at = "created_at",
	id = "id",
	stripe_id = "stripe_id",
	user_from_id = "user_from_id",
	user_to_id = "user_to_id"
}
/** update columns of table "voyce.payments_to_creators" */
export enum voyce_payments_to_creators_update_column {
	amount = "amount",
	created_at = "created_at",
	id = "id",
	stripe_id = "stripe_id",
	user_from_id = "user_from_id",
	user_to_id = "user_to_id"
}
/** unique or primary key constraints on table "voyce.pg_rs_series_views_count" */
export enum voyce_pg_rs_series_views_count_constraint {
	pg_rs_series_views_count_pkey = "pg_rs_series_views_count_pkey"
}
/** select columns of table "voyce.pg_rs_series_views_count" */
export enum voyce_pg_rs_series_views_count_select_column {
	count = "count",
	series_id = "series_id"
}
/** update columns of table "voyce.pg_rs_series_views_count" */
export enum voyce_pg_rs_series_views_count_update_column {
	count = "count",
	series_id = "series_id"
}
/** unique or primary key constraints on table "voyce.polls_answers" */
export enum voyce_polls_answers_constraint {
	polls_answers_pkey = "polls_answers_pkey",
	polls_answers_poll_id_order_key = "polls_answers_poll_id_order_key"
}
/** select columns of table "voyce.polls_answers" */
export enum voyce_polls_answers_select_column {
	id = "id",
	image = "image",
	order = "order",
	poll_id = "poll_id",
	text = "text",
	votes_count = "votes_count"
}
/** update columns of table "voyce.polls_answers" */
export enum voyce_polls_answers_update_column {
	id = "id",
	image = "image",
	order = "order",
	poll_id = "poll_id",
	text = "text",
	votes_count = "votes_count"
}
/** unique or primary key constraints on table "voyce.polls_comments" */
export enum voyce_polls_comments_constraint {
	polls_comments_pkey = "polls_comments_pkey"
}
/** select columns of table "voyce.polls_comments" */
export enum voyce_polls_comments_select_column {
	animated_image = "animated_image",
	created_at = "created_at",
	id = "id",
	image = "image",
	linked_id = "linked_id",
	parent_id = "parent_id",
	reaction_count = "reaction_count",
	replies_count = "replies_count",
	text = "text",
	user_id = "user_id"
}
/** update columns of table "voyce.polls_comments" */
export enum voyce_polls_comments_update_column {
	animated_image = "animated_image",
	created_at = "created_at",
	id = "id",
	image = "image",
	linked_id = "linked_id",
	parent_id = "parent_id",
	reaction_count = "reaction_count",
	replies_count = "replies_count",
	text = "text",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.polls" */
export enum voyce_polls_constraint {
	polls_pkey = "polls_pkey"
}
/** unique or primary key constraints on table "voyce.polls_reactions" */
export enum voyce_polls_reactions_constraint {
	polls_reactions_pkey = "polls_reactions_pkey"
}
/** select columns of table "voyce.polls_reactions" */
export enum voyce_polls_reactions_select_column {
	comment_id = "comment_id",
	created_at = "created_at",
	id = "id",
	linked_entity_id = "linked_entity_id",
	reaction = "reaction",
	user_id = "user_id"
}
/** update columns of table "voyce.polls_reactions" */
export enum voyce_polls_reactions_update_column {
	comment_id = "comment_id",
	created_at = "created_at",
	id = "id",
	linked_entity_id = "linked_entity_id",
	reaction = "reaction",
	user_id = "user_id"
}
/** select columns of table "voyce.polls" */
export enum voyce_polls_select_column {
	comments_count = "comments_count",
	created_at = "created_at",
	end_date = "end_date",
	id = "id",
	last_activity_date = "last_activity_date",
	likes_count = "likes_count",
	outdated = "outdated",
	popularity = "popularity",
	popularity_weekly = "popularity_weekly",
	reactions_count = "reactions_count",
	spoiler = "spoiler",
	title = "title",
	total_popularity = "total_popularity",
	user_id = "user_id",
	votes_count = "votes_count"
}
/** select "voyce_polls_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.polls" */
export enum voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_and_arguments_columns {
	outdated = "outdated",
	spoiler = "spoiler"
}
/** select "voyce_polls_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.polls" */
export enum voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_or_arguments_columns {
	outdated = "outdated",
	spoiler = "spoiler"
}
/** update columns of table "voyce.polls" */
export enum voyce_polls_update_column {
	comments_count = "comments_count",
	created_at = "created_at",
	end_date = "end_date",
	id = "id",
	last_activity_date = "last_activity_date",
	likes_count = "likes_count",
	outdated = "outdated",
	popularity = "popularity",
	popularity_weekly = "popularity_weekly",
	reactions_count = "reactions_count",
	spoiler = "spoiler",
	title = "title",
	total_popularity = "total_popularity",
	user_id = "user_id",
	votes_count = "votes_count"
}
/** unique or primary key constraints on table "voyce.polls_votes" */
export enum voyce_polls_votes_constraint {
	polls_votes_pkey = "polls_votes_pkey",
	polls_votes_poll_id_user_id_key = "polls_votes_poll_id_user_id_key"
}
/** select columns of table "voyce.polls_votes" */
export enum voyce_polls_votes_select_column {
	answer_id = "answer_id",
	created_at = "created_at",
	id = "id",
	poll_id = "poll_id",
	user_id = "user_id"
}
/** update columns of table "voyce.polls_votes" */
export enum voyce_polls_votes_update_column {
	answer_id = "answer_id",
	created_at = "created_at",
	id = "id",
	poll_id = "poll_id",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.popup_components" */
export enum voyce_popup_components_constraint {
	popup_components_pkey = "popup_components_pkey"
}
/** select columns of table "voyce.popup_components" */
export enum voyce_popup_components_select_column {
	auth = "auth",
	component = "component",
	created_at = "created_at",
	email_form = "email_form",
	exclusive_series = "exclusive_series",
	followers = "followers",
	id = "id",
	is_active = "is_active",
	klaviyo_list_id = "klaviyo_list_id",
	layout = "layout",
	page = "page",
	props = "props",
	series_visibility = "series_visibility",
	sort = "sort",
	updated_at = "updated_at"
}
/** update columns of table "voyce.popup_components" */
export enum voyce_popup_components_update_column {
	auth = "auth",
	component = "component",
	created_at = "created_at",
	email_form = "email_form",
	exclusive_series = "exclusive_series",
	followers = "followers",
	id = "id",
	is_active = "is_active",
	klaviyo_list_id = "klaviyo_list_id",
	layout = "layout",
	page = "page",
	props = "props",
	series_visibility = "series_visibility",
	sort = "sort",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.popup_emails" */
export enum voyce_popup_emails_constraint {
	popup_emails_pkey = "popup_emails_pkey"
}
/** select columns of table "voyce.popup_emails" */
export enum voyce_popup_emails_select_column {
	created_at = "created_at",
	email = "email",
	gtag = "gtag",
	id = "id",
	klaviyo_id = "klaviyo_id",
	page = "page",
	popup_id = "popup_id",
	series_id = "series_id",
	user_id = "user_id"
}
/** update columns of table "voyce.popup_emails" */
export enum voyce_popup_emails_update_column {
	created_at = "created_at",
	email = "email",
	gtag = "gtag",
	id = "id",
	klaviyo_id = "klaviyo_id",
	page = "page",
	popup_id = "popup_id",
	series_id = "series_id",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.posts_comments" */
export enum voyce_posts_comments_constraint {
	popsts_comments_pkey = "popsts_comments_pkey"
}
/** select columns of table "voyce.posts_comments" */
export enum voyce_posts_comments_select_column {
	animated_image = "animated_image",
	created_at = "created_at",
	id = "id",
	image = "image",
	last_reacted_at = "last_reacted_at",
	last_reaction_count = "last_reaction_count",
	linked_id = "linked_id",
	parent_id = "parent_id",
	reaction_count = "reaction_count",
	replies_count = "replies_count",
	text = "text",
	user_id = "user_id"
}
/** update columns of table "voyce.posts_comments" */
export enum voyce_posts_comments_update_column {
	animated_image = "animated_image",
	created_at = "created_at",
	id = "id",
	image = "image",
	last_reacted_at = "last_reacted_at",
	last_reaction_count = "last_reaction_count",
	linked_id = "linked_id",
	parent_id = "parent_id",
	reaction_count = "reaction_count",
	replies_count = "replies_count",
	text = "text",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.posts" */
export enum voyce_posts_constraint {
	posts_pkey = "posts_pkey"
}
/** unique or primary key constraints on table "voyce.posts_reactions" */
export enum voyce_posts_reactions_constraint {
	posts_reactions_pkey = "posts_reactions_pkey",
	posts_reactions_user_id_linked_entity_id_comment_id_reaction_ke = "posts_reactions_user_id_linked_entity_id_comment_id_reaction_ke"
}
/** select columns of table "voyce.posts_reactions" */
export enum voyce_posts_reactions_select_column {
	comment_id = "comment_id",
	created_at = "created_at",
	id = "id",
	linked_entity_id = "linked_entity_id",
	reaction = "reaction",
	user_id = "user_id"
}
/** update columns of table "voyce.posts_reactions" */
export enum voyce_posts_reactions_update_column {
	comment_id = "comment_id",
	created_at = "created_at",
	id = "id",
	linked_entity_id = "linked_entity_id",
	reaction = "reaction",
	user_id = "user_id"
}
/** select columns of table "voyce.posts" */
export enum voyce_posts_select_column {
	comments_count = "comments_count",
	created_at = "created_at",
	giphy_url = "giphy_url",
	id = "id",
	image_url = "image_url",
	last_activity_date = "last_activity_date",
	likes_count = "likes_count",
	outdated = "outdated",
	popularity = "popularity",
	popularity_weekly = "popularity_weekly",
	reactions_count = "reactions_count",
	spoiler = "spoiler",
	text = "text",
	total_popularity = "total_popularity",
	user_id = "user_id",
	video_url = "video_url"
}
/** select "voyce_posts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.posts" */
export enum voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_and_arguments_columns {
	outdated = "outdated",
	spoiler = "spoiler"
}
/** select "voyce_posts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.posts" */
export enum voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_or_arguments_columns {
	outdated = "outdated",
	spoiler = "spoiler"
}
/** update columns of table "voyce.posts" */
export enum voyce_posts_update_column {
	comments_count = "comments_count",
	created_at = "created_at",
	giphy_url = "giphy_url",
	id = "id",
	image_url = "image_url",
	last_activity_date = "last_activity_date",
	likes_count = "likes_count",
	outdated = "outdated",
	popularity = "popularity",
	popularity_weekly = "popularity_weekly",
	reactions_count = "reactions_count",
	spoiler = "spoiler",
	text = "text",
	total_popularity = "total_popularity",
	user_id = "user_id",
	video_url = "video_url"
}
/** unique or primary key constraints on table "voyce.quest_difficulties" */
export enum voyce_quest_difficulties_constraint {
	quest_difficulty_rewards_pkey = "quest_difficulty_rewards_pkey"
}
/** select columns of table "voyce.quest_difficulties" */
export enum voyce_quest_difficulties_select_column {
	coins = "coins",
	created_at = "created_at",
	experience = "experience",
	id = "id",
	title = "title",
	updated_at = "updated_at",
	vox = "vox"
}
/** update columns of table "voyce.quest_difficulties" */
export enum voyce_quest_difficulties_update_column {
	coins = "coins",
	created_at = "created_at",
	experience = "experience",
	id = "id",
	title = "title",
	updated_at = "updated_at",
	vox = "vox"
}
/** unique or primary key constraints on table "voyce.quests" */
export enum voyce_quests_constraint {
	quests_pkey1 = "quests_pkey1",
	quests_tag_key = "quests_tag_key"
}
/** unique or primary key constraints on table "voyce.quests_old" */
export enum voyce_quests_old_constraint {
	quests_pkey = "quests_pkey"
}
/** select columns of table "voyce.quests_old" */
export enum voyce_quests_old_select_column {
	end_date = "end_date",
	id = "id",
	image = "image",
	recurrency = "recurrency",
	recurrent = "recurrent",
	start_date = "start_date",
	steps = "steps",
	title = "title"
}
/** update columns of table "voyce.quests_old" */
export enum voyce_quests_old_update_column {
	end_date = "end_date",
	id = "id",
	image = "image",
	recurrency = "recurrency",
	recurrent = "recurrent",
	start_date = "start_date",
	steps = "steps",
	title = "title"
}
/** select columns of table "voyce.quests" */
export enum voyce_quests_select_column {
	created_at = "created_at",
	description = "description",
	difficulty_id = "difficulty_id",
	event_data = "event_data",
	event_trigger = "event_trigger",
	experience_points = "experience_points",
	frequency = "frequency",
	id = "id",
	initial_status = "initial_status",
	notif_message = "notif_message",
	reward = "reward",
	start_data = "start_data",
	start_type = "start_type",
	tag = "tag",
	title = "title",
	updated_at = "updated_at"
}
/** update columns of table "voyce.quests" */
export enum voyce_quests_update_column {
	created_at = "created_at",
	description = "description",
	difficulty_id = "difficulty_id",
	event_data = "event_data",
	event_trigger = "event_trigger",
	experience_points = "experience_points",
	frequency = "frequency",
	id = "id",
	initial_status = "initial_status",
	notif_message = "notif_message",
	reward = "reward",
	start_data = "start_data",
	start_type = "start_type",
	tag = "tag",
	title = "title",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.recommended_series" */
export enum voyce_recommended_series_constraint {
	recommended_series_pkey = "recommended_series_pkey"
}
/** select columns of table "voyce.recommended_series" */
export enum voyce_recommended_series_select_column {
	created_at = "created_at",
	id = "id",
	notification_image = "notification_image",
	notification_status = "notification_status",
	notification_text = "notification_text",
	notification_title = "notification_title",
	series_id = "series_id"
}
/** update columns of table "voyce.recommended_series" */
export enum voyce_recommended_series_update_column {
	created_at = "created_at",
	id = "id",
	notification_image = "notification_image",
	notification_status = "notification_status",
	notification_text = "notification_text",
	notification_title = "notification_title",
	series_id = "series_id"
}
/** unique or primary key constraints on table "voyce.release_schedules" */
export enum voyce_release_schedules_constraint {
	release_schedules_pkey = "release_schedules_pkey"
}
/** select columns of table "voyce.release_schedules" */
export enum voyce_release_schedules_select_column {
	chapter_id = "chapter_id",
	created_at = "created_at",
	id = "id",
	schedule = "schedule",
	series_id = "series_id",
	updated_at = "updated_at"
}
/** update columns of table "voyce.release_schedules" */
export enum voyce_release_schedules_update_column {
	chapter_id = "chapter_id",
	created_at = "created_at",
	id = "id",
	schedule = "schedule",
	series_id = "series_id",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.rewards" */
export enum voyce_rewards_constraint {
	rewards_pkey = "rewards_pkey"
}
/** unique or primary key constraints on table "voyce.rewards_leaderboard" */
export enum voyce_rewards_leaderboard_constraint {
	rewards_leaderboard_pkey = "rewards_leaderboard_pkey"
}
/** select columns of table "voyce.rewards_leaderboard" */
export enum voyce_rewards_leaderboard_select_column {
	id = "id",
	order = "order",
	reward_id = "reward_id",
	tag = "tag",
	title = "title"
}
/** update columns of table "voyce.rewards_leaderboard" */
export enum voyce_rewards_leaderboard_update_column {
	id = "id",
	order = "order",
	reward_id = "reward_id",
	tag = "tag",
	title = "title"
}
/** unique or primary key constraints on table "voyce.rewards_quest" */
export enum voyce_rewards_quest_constraint {
	rewards_quest_pkey = "rewards_quest_pkey"
}
/** select columns of table "voyce.rewards_quest" */
export enum voyce_rewards_quest_select_column {
	id = "id",
	quest_id = "quest_id",
	rewards_id = "rewards_id"
}
/** update columns of table "voyce.rewards_quest" */
export enum voyce_rewards_quest_update_column {
	id = "id",
	quest_id = "quest_id",
	rewards_id = "rewards_id"
}
/** select columns of table "voyce.rewards" */
export enum voyce_rewards_select_column {
	end_date = "end_date",
	expiry_date = "expiry_date",
	free = "free",
	id = "id",
	image = "image",
	purchaseId = "purchaseId",
	start_date = "start_date",
	title = "title",
	transferable = "transferable",
	type = "type",
	value = "value"
}
/** update columns of table "voyce.rewards" */
export enum voyce_rewards_update_column {
	end_date = "end_date",
	expiry_date = "expiry_date",
	free = "free",
	id = "id",
	image = "image",
	purchaseId = "purchaseId",
	start_date = "start_date",
	title = "title",
	transferable = "transferable",
	type = "type",
	value = "value"
}
/** unique or primary key constraints on table "voyce.sections" */
export enum voyce_sections_constraint {
	pk_sections = "pk_sections"
}
/** select columns of table "voyce.sections" */
export enum voyce_sections_select_column {
	content = "content",
	created_at = "created_at",
	id = "id",
	title = "title",
	updated_at = "updated_at"
}
/** update columns of table "voyce.sections" */
export enum voyce_sections_update_column {
	content = "content",
	created_at = "created_at",
	id = "id",
	title = "title",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.series_collaborators" */
export enum voyce_series_collaborators_constraint {
	series_collaborators_pkey = "series_collaborators_pkey",
	series_collaborators_series_id_user_id_key = "series_collaborators_series_id_user_id_key"
}
/** select columns of table "voyce.series_collaborators" */
export enum voyce_series_collaborators_select_column {
	created_at = "created_at",
	id = "id",
	role_id = "role_id",
	series_id = "series_id",
	user_id = "user_id"
}
/** update columns of table "voyce.series_collaborators" */
export enum voyce_series_collaborators_update_column {
	created_at = "created_at",
	id = "id",
	role_id = "role_id",
	series_id = "series_id",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.series" */
export enum voyce_series_constraint {
	pk_series = "pk_series"
}
/** unique or primary key constraints on table "voyce.series_donations" */
export enum voyce_series_donations_constraint {
	series_donations_pkey = "series_donations_pkey"
}
/** select columns of table "voyce.series_donations" */
export enum voyce_series_donations_select_column {
	amount = "amount",
	created_at = "created_at",
	currency = "currency",
	id = "id",
	message = "message",
	series_id = "series_id",
	user_id = "user_id"
}
/** update columns of table "voyce.series_donations" */
export enum voyce_series_donations_update_column {
	amount = "amount",
	created_at = "created_at",
	currency = "currency",
	id = "id",
	message = "message",
	series_id = "series_id",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.series_favorites" */
export enum voyce_series_favorites_constraint {
	series_favorites_pkey = "series_favorites_pkey"
}
/** select columns of table "voyce.series_favorites" */
export enum voyce_series_favorites_select_column {
	created_at = "created_at",
	id = "id",
	series_id = "series_id",
	user_id = "user_id"
}
/** update columns of table "voyce.series_favorites" */
export enum voyce_series_favorites_update_column {
	created_at = "created_at",
	id = "id",
	series_id = "series_id",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.series_featured_panels" */
export enum voyce_series_featured_panels_constraint {
	series_featured_panels_pkey = "series_featured_panels_pkey"
}
/** select columns of table "voyce.series_featured_panels" */
export enum voyce_series_featured_panels_select_column {
	id = "id",
	image = "image",
	series_id = "series_id"
}
/** update columns of table "voyce.series_featured_panels" */
export enum voyce_series_featured_panels_update_column {
	id = "id",
	image = "image",
	series_id = "series_id"
}
/** unique or primary key constraints on table "voyce.series_genre" */
export enum voyce_series_genre_constraint {
	pk_series_genre = "pk_series_genre"
}
/** select columns of table "voyce.series_genre" */
export enum voyce_series_genre_select_column {
	created_at = "created_at",
	genre_id = "genre_id",
	id = "id",
	series_id = "series_id",
	sub_genre = "sub_genre"
}
/** select "voyce_series_genre_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.series_genre" */
export enum voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_and_arguments_columns {
	sub_genre = "sub_genre"
}
/** select "voyce_series_genre_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.series_genre" */
export enum voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_or_arguments_columns {
	sub_genre = "sub_genre"
}
/** update columns of table "voyce.series_genre" */
export enum voyce_series_genre_update_column {
	created_at = "created_at",
	genre_id = "genre_id",
	id = "id",
	series_id = "series_id",
	sub_genre = "sub_genre"
}
/** unique or primary key constraints on table "voyce.series_giveaway_participants" */
export enum voyce_series_giveaway_participants_constraint {
	series_giveaway_paricipants_pkey = "series_giveaway_paricipants_pkey"
}
/** select columns of table "voyce.series_giveaway_participants" */
export enum voyce_series_giveaway_participants_select_column {
	created_at = "created_at",
	email = "email",
	first_name = "first_name",
	from_series = "from_series",
	id = "id",
	last_name = "last_name",
	series_id = "series_id"
}
/** update columns of table "voyce.series_giveaway_participants" */
export enum voyce_series_giveaway_participants_update_column {
	created_at = "created_at",
	email = "email",
	first_name = "first_name",
	from_series = "from_series",
	id = "id",
	last_name = "last_name",
	series_id = "series_id"
}
/** unique or primary key constraints on table "voyce.series_hot_panels" */
export enum voyce_series_hot_panels_constraint {
	series_hot_panels_pkey = "series_hot_panels_pkey"
}
/** select columns of table "voyce.series_hot_panels" */
export enum voyce_series_hot_panels_select_column {
	active = "active",
	created_at = "created_at",
	id = "id",
	image = "image",
	order = "order",
	series_id = "series_id",
	spoiler = "spoiler",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** select "voyce_series_hot_panels_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.series_hot_panels" */
export enum voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_and_arguments_columns {
	active = "active",
	spoiler = "spoiler"
}
/** select "voyce_series_hot_panels_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.series_hot_panels" */
export enum voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_or_arguments_columns {
	active = "active",
	spoiler = "spoiler"
}
/** update columns of table "voyce.series_hot_panels" */
export enum voyce_series_hot_panels_update_column {
	active = "active",
	created_at = "created_at",
	id = "id",
	image = "image",
	order = "order",
	series_id = "series_id",
	spoiler = "spoiler",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.series_layouts" */
export enum voyce_series_layouts_constraint {
	series_layouts_pkey = "series_layouts_pkey"
}
/** select columns of table "voyce.series_layouts" */
export enum voyce_series_layouts_select_column {
	active = "active",
	bg_image = "bg_image",
	characters = "characters",
	comments = "comments",
	dl_banner = "dl_banner",
	id = "id",
	layout = "layout",
	logo = "logo",
	menu = "menu",
	series_id = "series_id",
	socials = "socials",
	story = "story"
}
/** update columns of table "voyce.series_layouts" */
export enum voyce_series_layouts_update_column {
	active = "active",
	bg_image = "bg_image",
	characters = "characters",
	comments = "comments",
	dl_banner = "dl_banner",
	id = "id",
	layout = "layout",
	logo = "logo",
	menu = "menu",
	series_id = "series_id",
	socials = "socials",
	story = "story"
}
/** unique or primary key constraints on table "voyce.series_leaderboard" */
export enum voyce_series_leaderboard_constraint {
	series_leaderboard_pkey = "series_leaderboard_pkey"
}
/** select columns of table "voyce.series_leaderboard" */
export enum voyce_series_leaderboard_select_column {
	amount = "amount",
	arrow = "arrow",
	created_at = "created_at",
	currency = "currency",
	date = "date",
	id = "id",
	rank = "rank",
	series_id = "series_id",
	user_id = "user_id"
}
/** update columns of table "voyce.series_leaderboard" */
export enum voyce_series_leaderboard_update_column {
	amount = "amount",
	arrow = "arrow",
	created_at = "created_at",
	currency = "currency",
	date = "date",
	id = "id",
	rank = "rank",
	series_id = "series_id",
	user_id = "user_id"
}
/** select columns of table "voyce.series_likes_count_mat" */
export enum voyce_series_likes_count_mat_select_column {
	actual_count = "actual_count",
	count = "count",
	series_id = "series_id"
}
/** select columns of table "voyce.series_likes_count" */
export enum voyce_series_likes_count_select_column {
	count = "count",
	series_id = "series_id"
}
/** unique or primary key constraints on table "voyce.series_lock_config" */
export enum voyce_series_lock_config_constraint {
	series_lock_config_pkey = "series_lock_config_pkey"
}
/** select columns of table "voyce.series_lock_config" */
export enum voyce_series_lock_config_select_column {
	buy_currency = "buy_currency",
	buy_price = "buy_price",
	created_at = "created_at",
	id = "id",
	lock_duration = "lock_duration",
	lock_latest_chapters = "lock_latest_chapters",
	rent_currency = "rent_currency",
	rent_duration = "rent_duration",
	rent_price = "rent_price",
	series_id = "series_id",
	updated_at = "updated_at"
}
/** update columns of table "voyce.series_lock_config" */
export enum voyce_series_lock_config_update_column {
	buy_currency = "buy_currency",
	buy_price = "buy_price",
	created_at = "created_at",
	id = "id",
	lock_duration = "lock_duration",
	lock_latest_chapters = "lock_latest_chapters",
	rent_currency = "rent_currency",
	rent_duration = "rent_duration",
	rent_price = "rent_price",
	series_id = "series_id",
	updated_at = "updated_at"
}
/** select columns of table "voyce.series" */
export enum voyce_series_select_column {
	animated_cover_image = "animated_cover_image",
	audio_allowed = "audio_allowed",
	average_rating = "average_rating",
	back_image = "back_image",
	banner_bg = "banner_bg",
	book_reverse_view = "book_reverse_view",
	book_view = "book_view",
	chapter_count = "chapter_count",
	chapters_hide = "chapters_hide",
	character_cover = "character_cover",
	coin_price = "coin_price",
	comic_type_id = "comic_type_id",
	cover_image = "cover_image",
	cover_image_mobile = "cover_image_mobile",
	created_at = "created_at",
	csat = "csat",
	default_view = "default_view",
	description = "description",
	feature = "feature",
	frequency = "frequency",
	frequency_mask = "frequency_mask",
	front_image = "front_image",
	id = "id",
	is_animated_cover_allowed = "is_animated_cover_allowed",
	is_approved = "is_approved",
	is_deleted = "is_deleted",
	is_intro = "is_intro",
	is_locked = "is_locked",
	is_original = "is_original",
	is_pilots = "is_pilots",
	is_recommended = "is_recommended",
	klaviyo_id = "klaviyo_id",
	klaviyo_list_id = "klaviyo_list_id",
	latest_chapter_update = "latest_chapter_update",
	locked = "locked",
	locked_chapters_amount = "locked_chapters_amount",
	paginated_view = "paginated_view",
	pilots_votes_count = "pilots_votes_count",
	portfolio_desc = "portfolio_desc",
	publish = "publish",
	rating_count = "rating_count",
	reading_view = "reading_view",
	scroll_view = "scroll_view",
	short_desc = "short_desc",
	slug = "slug",
	status = "status",
	subscriber_count = "subscriber_count",
	support_msg = "support_msg",
	team_spotlight = "team_spotlight",
	thumbnail = "thumbnail",
	thumbnail_box = "thumbnail_box",
	title = "title",
	trending = "trending",
	trending_position = "trending_position",
	updated_at = "updated_at",
	user_display_field = "user_display_field",
	user_id = "user_id",
	view_count = "view_count",
	welcome_msg = "welcome_msg"
}
/** unique or primary key constraints on table "voyce.series_sponsorships_configuration" */
export enum voyce_series_sponsorships_configuration_constraint {
	series_sponsorships_configuration_pkey = "series_sponsorships_configuration_pkey"
}
/** select columns of table "voyce.series_sponsorships_configuration" */
export enum voyce_series_sponsorships_configuration_select_column {
	enabled = "enabled",
	id = "id",
	message = "message",
	series_id = "series_id",
	voyceme_payee = "voyceme_payee"
}
/** update columns of table "voyce.series_sponsorships_configuration" */
export enum voyce_series_sponsorships_configuration_update_column {
	enabled = "enabled",
	id = "id",
	message = "message",
	series_id = "series_id",
	voyceme_payee = "voyceme_payee"
}
/** unique or primary key constraints on table "voyce.series_subscription" */
export enum voyce_series_subscription_constraint {
	pk_series_subscription = "pk_series_subscription"
}
/** select columns of table "voyce.series_subscription" */
export enum voyce_series_subscription_select_column {
	created_at = "created_at",
	id = "id",
	klaviyo_id = "klaviyo_id",
	series_id = "series_id",
	user_id = "user_id"
}
/** update columns of table "voyce.series_subscription" */
export enum voyce_series_subscription_update_column {
	created_at = "created_at",
	id = "id",
	klaviyo_id = "klaviyo_id",
	series_id = "series_id",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.series_tags" */
export enum voyce_series_tags_constraint {
	series_tags_name_key = "series_tags_name_key",
	series_tags_pkey = "series_tags_pkey"
}
/** unique or primary key constraints on table "voyce.series_tags_junction" */
export enum voyce_series_tags_junction_constraint {
	series_tags_junction_pkey = "series_tags_junction_pkey"
}
/** select columns of table "voyce.series_tags_junction" */
export enum voyce_series_tags_junction_select_column {
	admin_created = "admin_created",
	chapter_id = "chapter_id",
	id = "id",
	images = "images",
	order = "order",
	series_id = "series_id",
	tag_id = "tag_id"
}
/** select "voyce_series_tags_junction_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voyce.series_tags_junction" */
export enum voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_and_arguments_columns {
	admin_created = "admin_created"
}
/** select "voyce_series_tags_junction_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voyce.series_tags_junction" */
export enum voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_or_arguments_columns {
	admin_created = "admin_created"
}
/** update columns of table "voyce.series_tags_junction" */
export enum voyce_series_tags_junction_update_column {
	admin_created = "admin_created",
	chapter_id = "chapter_id",
	id = "id",
	images = "images",
	order = "order",
	series_id = "series_id",
	tag_id = "tag_id"
}
/** select columns of table "voyce.series_tags" */
export enum voyce_series_tags_select_column {
	id = "id",
	name = "name"
}
/** update columns of table "voyce.series_tags" */
export enum voyce_series_tags_update_column {
	id = "id",
	name = "name"
}
/** unique or primary key constraints on table "voyce.series_top_comments" */
export enum voyce_series_top_comments_constraint {
	series_top_comments_pkey = "series_top_comments_pkey"
}
/** select columns of table "voyce.series_top_comments" */
export enum voyce_series_top_comments_select_column {
	comment_id = "comment_id",
	count = "count",
	created_at = "created_at",
	id = "id",
	series_id = "series_id",
	tag = "tag",
	type = "type"
}
/** update columns of table "voyce.series_top_comments" */
export enum voyce_series_top_comments_update_column {
	comment_id = "comment_id",
	count = "count",
	created_at = "created_at",
	id = "id",
	series_id = "series_id",
	tag = "tag",
	type = "type"
}
/** unique or primary key constraints on table "voyce.series_top_likes" */
export enum voyce_series_top_likes_constraint {
	series_top_likes_pkey = "series_top_likes_pkey"
}
/** select columns of table "voyce.series_top_likes" */
export enum voyce_series_top_likes_select_column {
	count = "count",
	created_at = "created_at",
	id = "id",
	is_original = "is_original",
	series_id = "series_id",
	type = "type"
}
/** update columns of table "voyce.series_top_likes" */
export enum voyce_series_top_likes_update_column {
	count = "count",
	created_at = "created_at",
	id = "id",
	is_original = "is_original",
	series_id = "series_id",
	type = "type"
}
/** unique or primary key constraints on table "voyce.series_top_views" */
export enum voyce_series_top_views_constraint {
	series_top_views_pkey = "series_top_views_pkey"
}
/** select columns of table "voyce.series_top_views" */
export enum voyce_series_top_views_select_column {
	comic_type = "comic_type",
	count = "count",
	created_at = "created_at",
	id = "id",
	prev_rank = "prev_rank",
	rank = "rank",
	series_id = "series_id",
	tag = "tag",
	type = "type"
}
/** update columns of table "voyce.series_top_views" */
export enum voyce_series_top_views_update_column {
	comic_type = "comic_type",
	count = "count",
	created_at = "created_at",
	id = "id",
	prev_rank = "prev_rank",
	rank = "rank",
	series_id = "series_id",
	tag = "tag",
	type = "type"
}
/** update columns of table "voyce.series" */
export enum voyce_series_update_column {
	animated_cover_image = "animated_cover_image",
	audio_allowed = "audio_allowed",
	average_rating = "average_rating",
	back_image = "back_image",
	banner_bg = "banner_bg",
	book_reverse_view = "book_reverse_view",
	book_view = "book_view",
	chapter_count = "chapter_count",
	chapters_hide = "chapters_hide",
	character_cover = "character_cover",
	coin_price = "coin_price",
	comic_type_id = "comic_type_id",
	cover_image = "cover_image",
	cover_image_mobile = "cover_image_mobile",
	created_at = "created_at",
	csat = "csat",
	default_view = "default_view",
	description = "description",
	feature = "feature",
	frequency = "frequency",
	frequency_mask = "frequency_mask",
	front_image = "front_image",
	id = "id",
	is_animated_cover_allowed = "is_animated_cover_allowed",
	is_approved = "is_approved",
	is_deleted = "is_deleted",
	is_intro = "is_intro",
	is_locked = "is_locked",
	is_original = "is_original",
	is_pilots = "is_pilots",
	is_recommended = "is_recommended",
	klaviyo_id = "klaviyo_id",
	klaviyo_list_id = "klaviyo_list_id",
	latest_chapter_update = "latest_chapter_update",
	locked = "locked",
	locked_chapters_amount = "locked_chapters_amount",
	paginated_view = "paginated_view",
	pilots_votes_count = "pilots_votes_count",
	portfolio_desc = "portfolio_desc",
	publish = "publish",
	rating_count = "rating_count",
	reading_view = "reading_view",
	scroll_view = "scroll_view",
	short_desc = "short_desc",
	slug = "slug",
	status = "status",
	subscriber_count = "subscriber_count",
	support_msg = "support_msg",
	team_spotlight = "team_spotlight",
	thumbnail = "thumbnail",
	thumbnail_box = "thumbnail_box",
	title = "title",
	trending = "trending",
	trending_position = "trending_position",
	updated_at = "updated_at",
	user_display_field = "user_display_field",
	user_id = "user_id",
	view_count = "view_count",
	welcome_msg = "welcome_msg"
}
/** unique or primary key constraints on table "voyce.series_views_adjustment" */
export enum voyce_series_views_adjustment_constraint {
	series_views_adjustment_pkey = "series_views_adjustment_pkey"
}
/** select columns of table "voyce.series_views_adjustment" */
export enum voyce_series_views_adjustment_select_column {
	count = "count",
	created_at = "created_at",
	id = "id",
	series_id = "series_id",
	updated_at = "updated_at"
}
/** update columns of table "voyce.series_views_adjustment" */
export enum voyce_series_views_adjustment_update_column {
	count = "count",
	created_at = "created_at",
	id = "id",
	series_id = "series_id",
	updated_at = "updated_at"
}
/** select columns of table "voyce.series_views_count_mat" */
export enum voyce_series_views_count_mat_select_column {
	actual_count = "actual_count",
	count = "count",
	series_id = "series_id"
}
/** select columns of table "voyce.series_views_count_mat_sep30" */
export enum voyce_series_views_count_mat_sep30_select_column {
	actual_count = "actual_count",
	count = "count",
	series_id = "series_id"
}
/** select columns of table "voyce.series_views_count_mat_v2" */
export enum voyce_series_views_count_mat_v2_select_column {
	actual_count = "actual_count",
	count = "count",
	series_id = "series_id"
}
/** select columns of table "voyce.series_views_count" */
export enum voyce_series_views_count_select_column {
	count = "count",
	series_id = "series_id"
}
/** unique or primary key constraints on table "voyce.series_votes" */
export enum voyce_series_votes_constraint {
	series_votes_gtag_id_series_id_tag_date_key = "series_votes_gtag_id_series_id_tag_date_key",
	series_votes_pkey = "series_votes_pkey"
}
/** unique or primary key constraints on table "voyce.series_votes_count" */
export enum voyce_series_votes_count_constraint {
	series_votes_count_pkey = "series_votes_count_pkey"
}
/** select columns of table "voyce.series_votes_count" */
export enum voyce_series_votes_count_select_column {
	count = "count",
	created_at = "created_at",
	date = "date",
	id = "id",
	series_id = "series_id",
	tag = "tag",
	total = "total",
	updated_at = "updated_at"
}
/** update columns of table "voyce.series_votes_count" */
export enum voyce_series_votes_count_update_column {
	count = "count",
	created_at = "created_at",
	date = "date",
	id = "id",
	series_id = "series_id",
	tag = "tag",
	total = "total",
	updated_at = "updated_at"
}
/** select columns of table "voyce.series_votes" */
export enum voyce_series_votes_select_column {
	created_at = "created_at",
	date = "date",
	gtag_id = "gtag_id",
	id = "id",
	series_id = "series_id",
	tag = "tag",
	user_id = "user_id"
}
/** update columns of table "voyce.series_votes" */
export enum voyce_series_votes_update_column {
	created_at = "created_at",
	date = "date",
	gtag_id = "gtag_id",
	id = "id",
	series_id = "series_id",
	tag = "tag",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.series_voting_schedules" */
export enum voyce_series_voting_schedules_constraint {
	series_voting_schedules_pkey = "series_voting_schedules_pkey"
}
/** select columns of table "voyce.series_voting_schedules" */
export enum voyce_series_voting_schedules_select_column {
	created_at = "created_at",
	desc = "desc",
	end_date = "end_date",
	id = "id",
	start_date = "start_date",
	tag = "tag",
	updated_at = "updated_at"
}
/** update columns of table "voyce.series_voting_schedules" */
export enum voyce_series_voting_schedules_update_column {
	created_at = "created_at",
	desc = "desc",
	end_date = "end_date",
	id = "id",
	start_date = "start_date",
	tag = "tag",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.sponsorships" */
export enum voyce_sponsorships_constraint {
	sponsorships_pkey = "sponsorships_pkey"
}
/** select columns of table "voyce.sponsorships" */
export enum voyce_sponsorships_select_column {
	amount = "amount",
	created_at = "created_at",
	id = "id",
	message = "message",
	series_id = "series_id",
	sponsored_user_id = "sponsored_user_id",
	sponsoring_user_id = "sponsoring_user_id",
	stripe_subscription = "stripe_subscription",
	updated_at = "updated_at"
}
/** update columns of table "voyce.sponsorships" */
export enum voyce_sponsorships_update_column {
	amount = "amount",
	created_at = "created_at",
	id = "id",
	message = "message",
	series_id = "series_id",
	sponsored_user_id = "sponsored_user_id",
	sponsoring_user_id = "sponsoring_user_id",
	stripe_subscription = "stripe_subscription",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.states" */
export enum voyce_states_constraint {
	pk_states = "pk_states"
}
/** select columns of table "voyce.states" */
export enum voyce_states_select_column {
	country_id = "country_id",
	date_create = "date_create",
	description = "description",
	feature = "feature",
	id = "id",
	name = "name",
	publish = "publish"
}
/** update columns of table "voyce.states" */
export enum voyce_states_update_column {
	country_id = "country_id",
	date_create = "date_create",
	description = "description",
	feature = "feature",
	id = "id",
	name = "name",
	publish = "publish"
}
/** unique or primary key constraints on table "voyce.stripe_payouts_setup" */
export enum voyce_stripe_payouts_setup_constraint {
	stripe_payouts_setup_pkey = "stripe_payouts_setup_pkey",
	stripe_payouts_setup_user_id_key = "stripe_payouts_setup_user_id_key"
}
/** select columns of table "voyce.stripe_payouts_setup" */
export enum voyce_stripe_payouts_setup_select_column {
	completed_stripe_onboarding = "completed_stripe_onboarding",
	cut = "cut",
	id = "id",
	stripe_account_id = "stripe_account_id",
	user_id = "user_id"
}
/** update columns of table "voyce.stripe_payouts_setup" */
export enum voyce_stripe_payouts_setup_update_column {
	completed_stripe_onboarding = "completed_stripe_onboarding",
	cut = "cut",
	id = "id",
	stripe_account_id = "stripe_account_id",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.studio" */
export enum voyce_studio_constraint {
	studio_pkey = "studio_pkey",
	studio_slug_key = "studio_slug_key"
}
/** unique or primary key constraints on table "voyce.studio_followers" */
export enum voyce_studio_followers_constraint {
	studio_followers_pkey = "studio_followers_pkey"
}
/** select columns of table "voyce.studio_followers" */
export enum voyce_studio_followers_select_column {
	created_at = "created_at",
	id = "id",
	studio_id = "studio_id",
	user_id = "user_id"
}
/** update columns of table "voyce.studio_followers" */
export enum voyce_studio_followers_update_column {
	created_at = "created_at",
	id = "id",
	studio_id = "studio_id",
	user_id = "user_id"
}
/** select columns of table "voyce.studio" */
export enum voyce_studio_select_column {
	active = "active",
	avatar = "avatar",
	created_at = "created_at",
	description = "description",
	discord = "discord",
	facebook = "facebook",
	id = "id",
	instagram = "instagram",
	layout = "layout",
	logo = "logo",
	order = "order",
	props = "props",
	slug = "slug",
	tiktok = "tiktok",
	title = "title",
	twitter = "twitter",
	updated_at = "updated_at",
	website = "website"
}
/** unique or primary key constraints on table "voyce.studio_series" */
export enum voyce_studio_series_constraint {
	studio_series_pkey = "studio_series_pkey"
}
/** select columns of table "voyce.studio_series" */
export enum voyce_studio_series_select_column {
	id = "id",
	series_id = "series_id",
	studio_id = "studio_id"
}
/** update columns of table "voyce.studio_series" */
export enum voyce_studio_series_update_column {
	id = "id",
	series_id = "series_id",
	studio_id = "studio_id"
}
/** update columns of table "voyce.studio" */
export enum voyce_studio_update_column {
	active = "active",
	avatar = "avatar",
	created_at = "created_at",
	description = "description",
	discord = "discord",
	facebook = "facebook",
	id = "id",
	instagram = "instagram",
	layout = "layout",
	logo = "logo",
	order = "order",
	props = "props",
	slug = "slug",
	tiktok = "tiktok",
	title = "title",
	twitter = "twitter",
	updated_at = "updated_at",
	website = "website"
}
/** unique or primary key constraints on table "voyce.studio_views" */
export enum voyce_studio_views_constraint {
	studio_views_pkey = "studio_views_pkey"
}
/** select columns of table "voyce.studio_views" */
export enum voyce_studio_views_select_column {
	count = "count",
	id = "id",
	studio_id = "studio_id"
}
/** update columns of table "voyce.studio_views" */
export enum voyce_studio_views_update_column {
	count = "count",
	id = "id",
	studio_id = "studio_id"
}
/** unique or primary key constraints on table "voyce.system_settings" */
export enum voyce_system_settings_constraint {
	pk_system_settings = "pk_system_settings"
}
/** select columns of table "voyce.system_settings" */
export enum voyce_system_settings_select_column {
	created_at = "created_at",
	id = "id",
	key = "key",
	updated_at = "updated_at",
	value = "value"
}
/** update columns of table "voyce.system_settings" */
export enum voyce_system_settings_update_column {
	created_at = "created_at",
	id = "id",
	key = "key",
	updated_at = "updated_at",
	value = "value"
}
/** select columns of table "voyce.top_genres" */
export enum voyce_top_genres_select_column {
	count = "count",
	genre_id = "genre_id"
}
/** unique or primary key constraints on table "voyce.transaction_logs" */
export enum voyce_transaction_logs_constraint {
	transaction_logs_pkey = "transaction_logs_pkey"
}
/** select columns of table "voyce.transaction_logs" */
export enum voyce_transaction_logs_select_column {
	amount = "amount",
	coins_type = "coins_type",
	created_at = "created_at",
	id = "id",
	item_id = "item_id",
	product_id = "product_id",
	receipt_id = "receipt_id",
	receiver_id = "receiver_id",
	sender_id = "sender_id",
	title = "title",
	type = "type"
}
/** update columns of table "voyce.transaction_logs" */
export enum voyce_transaction_logs_update_column {
	amount = "amount",
	coins_type = "coins_type",
	created_at = "created_at",
	id = "id",
	item_id = "item_id",
	product_id = "product_id",
	receipt_id = "receipt_id",
	receiver_id = "receiver_id",
	sender_id = "sender_id",
	title = "title",
	type = "type"
}
/** unique or primary key constraints on table "voyce.url_redirects" */
export enum voyce_url_redirects_constraint {
	url_redirects_path_key = "url_redirects_path_key",
	url_redirects_pkey = "url_redirects_pkey"
}
/** select columns of table "voyce.url_redirects" */
export enum voyce_url_redirects_select_column {
	id = "id",
	path = "path",
	redirect_url = "redirect_url",
	views = "views"
}
/** update columns of table "voyce.url_redirects" */
export enum voyce_url_redirects_update_column {
	id = "id",
	path = "path",
	redirect_url = "redirect_url",
	views = "views"
}
/** unique or primary key constraints on table "voyce.user_addresses" */
export enum voyce_user_addresses_constraint {
	pk_user_addresses = "pk_user_addresses"
}
/** select columns of table "voyce.user_addresses" */
export enum voyce_user_addresses_select_column {
	address = "address",
	city_id = "city_id",
	country_id = "country_id",
	date_create = "date_create",
	id = "id",
	is_billing_address = "is_billing_address",
	is_shipping_address = "is_shipping_address",
	state_id = "state_id",
	user_id = "user_id",
	zip_code = "zip_code"
}
/** update columns of table "voyce.user_addresses" */
export enum voyce_user_addresses_update_column {
	address = "address",
	city_id = "city_id",
	country_id = "country_id",
	date_create = "date_create",
	id = "id",
	is_billing_address = "is_billing_address",
	is_shipping_address = "is_shipping_address",
	state_id = "state_id",
	user_id = "user_id",
	zip_code = "zip_code"
}
/** unique or primary key constraints on table "voyce.user_blocks" */
export enum voyce_user_blocks_constraint {
	user_blocks_pkey = "user_blocks_pkey"
}
/** select columns of table "voyce.user_blocks" */
export enum voyce_user_blocks_select_column {
	created_at = "created_at",
	extras = "extras",
	id = "id",
	linked_id = "linked_id",
	reason = "reason",
	type = "type",
	user_id = "user_id"
}
/** update columns of table "voyce.user_blocks" */
export enum voyce_user_blocks_update_column {
	created_at = "created_at",
	extras = "extras",
	id = "id",
	linked_id = "linked_id",
	reason = "reason",
	type = "type",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.user_chapters_unlocked" */
export enum voyce_user_chapters_unlocked_constraint {
	user_chapters_unlocked_pkey = "user_chapters_unlocked_pkey"
}
/** select columns of table "voyce.user_chapters_unlocked" */
export enum voyce_user_chapters_unlocked_select_column {
	chapter_id = "chapter_id",
	created_at = "created_at",
	end_date = "end_date",
	id = "id",
	is_expired = "is_expired",
	series_id = "series_id",
	start_date = "start_date",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** update columns of table "voyce.user_chapters_unlocked" */
export enum voyce_user_chapters_unlocked_update_column {
	chapter_id = "chapter_id",
	created_at = "created_at",
	end_date = "end_date",
	id = "id",
	is_expired = "is_expired",
	series_id = "series_id",
	start_date = "start_date",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.user_contacts" */
export enum voyce_user_contacts_constraint {
	pk_user_contacts = "pk_user_contacts"
}
/** select columns of table "voyce.user_contacts" */
export enum voyce_user_contacts_select_column {
	contact = "contact",
	country_code = "country_code",
	date_create = "date_create",
	id = "id",
	is_primary = "is_primary",
	user_id = "user_id"
}
/** update columns of table "voyce.user_contacts" */
export enum voyce_user_contacts_update_column {
	contact = "contact",
	country_code = "country_code",
	date_create = "date_create",
	id = "id",
	is_primary = "is_primary",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.user_csat_ratings" */
export enum voyce_user_csat_ratings_constraint {
	user_csat_ratings_pkey = "user_csat_ratings_pkey"
}
/** select columns of table "voyce.user_csat_ratings" */
export enum voyce_user_csat_ratings_select_column {
	comment = "comment",
	created_at = "created_at",
	gtag_id = "gtag_id",
	id = "id",
	is_answered = "is_answered",
	more_info = "more_info",
	page_uri = "page_uri",
	rating = "rating",
	tag = "tag",
	user_id = "user_id"
}
/** update columns of table "voyce.user_csat_ratings" */
export enum voyce_user_csat_ratings_update_column {
	comment = "comment",
	created_at = "created_at",
	gtag_id = "gtag_id",
	id = "id",
	is_answered = "is_answered",
	more_info = "more_info",
	page_uri = "page_uri",
	rating = "rating",
	tag = "tag",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.user_education" */
export enum voyce_user_education_constraint {
	pk_user_education = "pk_user_education"
}
/** select columns of table "voyce.user_education" */
export enum voyce_user_education_select_column {
	date_create = "date_create",
	education_type_id = "education_type_id",
	id = "id",
	in_progress = "in_progress",
	institute = "institute",
	passing_date = "passing_date",
	title = "title",
	user_id = "user_id"
}
/** update columns of table "voyce.user_education" */
export enum voyce_user_education_update_column {
	date_create = "date_create",
	education_type_id = "education_type_id",
	id = "id",
	in_progress = "in_progress",
	institute = "institute",
	passing_date = "passing_date",
	title = "title",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.user_level" */
export enum voyce_user_level_constraint {
	user_level_pkey = "user_level_pkey"
}
/** select columns of table "voyce.user_level" */
export enum voyce_user_level_select_column {
	created_at = "created_at",
	experience = "experience",
	id = "id",
	level = "level",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** update columns of table "voyce.user_level" */
export enum voyce_user_level_update_column {
	created_at = "created_at",
	experience = "experience",
	id = "id",
	level = "level",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.user_linked_accounts" */
export enum voyce_user_linked_accounts_constraint {
	user_linked_accounts_hash_identity_key = "user_linked_accounts_hash_identity_key",
	user_linked_accounts_pkey = "user_linked_accounts_pkey"
}
/** select columns of table "voyce.user_linked_accounts" */
export enum voyce_user_linked_accounts_select_column {
	created_at = "created_at",
	hash = "hash",
	id = "id",
	identity = "identity",
	main_account = "main_account",
	provider = "provider",
	sub = "sub",
	user_id = "user_id"
}
/** update columns of table "voyce.user_linked_accounts" */
export enum voyce_user_linked_accounts_update_column {
	created_at = "created_at",
	hash = "hash",
	id = "id",
	identity = "identity",
	main_account = "main_account",
	provider = "provider",
	sub = "sub",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.user_login_history" */
export enum voyce_user_login_history_constraint {
	pk_user_login_history = "pk_user_login_history"
}
/** select columns of table "voyce.user_login_history" */
export enum voyce_user_login_history_select_column {
	browser = "browser",
	city = "city",
	country = "country",
	id = "id",
	ip = "ip",
	is_login = "is_login",
	latitude = "latitude",
	login_time = "login_time",
	logout_time = "logout_time",
	longitude = "longitude",
	os = "os",
	region = "region",
	token = "token",
	user_agent = "user_agent",
	user_id = "user_id"
}
/** update columns of table "voyce.user_login_history" */
export enum voyce_user_login_history_update_column {
	browser = "browser",
	city = "city",
	country = "country",
	id = "id",
	ip = "ip",
	is_login = "is_login",
	latitude = "latitude",
	login_time = "login_time",
	logout_time = "logout_time",
	longitude = "longitude",
	os = "os",
	region = "region",
	token = "token",
	user_agent = "user_agent",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.user_quest_ids" */
export enum voyce_user_quest_ids_constraint {
	user_quest_ids_pkey = "user_quest_ids_pkey"
}
/** select columns of table "voyce.user_quest_ids" */
export enum voyce_user_quest_ids_select_column {
	created_at = "created_at",
	id = "id",
	tag = "tag",
	type = "type",
	type_id = "type_id",
	user_id = "user_id",
	user_quest_id = "user_quest_id"
}
/** update columns of table "voyce.user_quest_ids" */
export enum voyce_user_quest_ids_update_column {
	created_at = "created_at",
	id = "id",
	tag = "tag",
	type = "type",
	type_id = "type_id",
	user_id = "user_id",
	user_quest_id = "user_quest_id"
}
/** unique or primary key constraints on table "voyce.user_quest_rewards" */
export enum voyce_user_quest_rewards_constraint {
	user_rewards_new_pkey = "user_rewards_new_pkey"
}
/** select columns of table "voyce.user_quest_rewards" */
export enum voyce_user_quest_rewards_select_column {
	created_at = "created_at",
	description = "description",
	id = "id",
	metadata = "metadata",
	type = "type",
	user_id = "user_id",
	user_quest_id = "user_quest_id",
	value = "value"
}
/** update columns of table "voyce.user_quest_rewards" */
export enum voyce_user_quest_rewards_update_column {
	created_at = "created_at",
	description = "description",
	id = "id",
	metadata = "metadata",
	type = "type",
	user_id = "user_id",
	user_quest_id = "user_quest_id",
	value = "value"
}
/** unique or primary key constraints on table "voyce.user_quests" */
export enum voyce_user_quests_constraint {
	user_quests_frequency_tag_user_id_quest_id_key = "user_quests_frequency_tag_user_id_quest_id_key",
	user_quests_pkey1 = "user_quests_pkey1"
}
/** unique or primary key constraints on table "voyce.user_quests_old" */
export enum voyce_user_quests_old_constraint {
	user_quests_pkey = "user_quests_pkey"
}
/** select columns of table "voyce.user_quests_old" */
export enum voyce_user_quests_old_select_column {
	created_at = "created_at",
	id = "id",
	progress = "progress",
	quest_id = "quest_id",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** update columns of table "voyce.user_quests_old" */
export enum voyce_user_quests_old_update_column {
	created_at = "created_at",
	id = "id",
	progress = "progress",
	quest_id = "quest_id",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** select columns of table "voyce.user_quests" */
export enum voyce_user_quests_select_column {
	completed_at = "completed_at",
	created_at = "created_at",
	data = "data",
	frequency_tag = "frequency_tag",
	id = "id",
	notified = "notified",
	quest_id = "quest_id",
	rewarded_at = "rewarded_at",
	rewards_data = "rewards_data",
	status = "status",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** update columns of table "voyce.user_quests" */
export enum voyce_user_quests_update_column {
	completed_at = "completed_at",
	created_at = "created_at",
	data = "data",
	frequency_tag = "frequency_tag",
	id = "id",
	notified = "notified",
	quest_id = "quest_id",
	rewarded_at = "rewarded_at",
	rewards_data = "rewards_data",
	status = "status",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.user_ranking" */
export enum voyce_user_ranking_constraint {
	user_ranking_pkey = "user_ranking_pkey"
}
/** select columns of table "voyce.user_ranking" */
export enum voyce_user_ranking_select_column {
	counter = "counter",
	day = "day",
	id = "id",
	series_id = "series_id",
	user_id = "user_id",
	xp_count = "xp_count"
}
/** update columns of table "voyce.user_ranking" */
export enum voyce_user_ranking_update_column {
	counter = "counter",
	day = "day",
	id = "id",
	series_id = "series_id",
	user_id = "user_id",
	xp_count = "xp_count"
}
/** unique or primary key constraints on table "voyce.user_reviews" */
export enum voyce_user_reviews_constraint {
	pk_user_reviews = "pk_user_reviews"
}
/** select columns of table "voyce.user_reviews" */
export enum voyce_user_reviews_select_column {
	date_create = "date_create",
	id = "id",
	publish = "publish",
	rating = "rating",
	review = "review",
	reviewer_id = "reviewer_id",
	user_id = "user_id"
}
/** update columns of table "voyce.user_reviews" */
export enum voyce_user_reviews_update_column {
	date_create = "date_create",
	id = "id",
	publish = "publish",
	rating = "rating",
	review = "review",
	reviewer_id = "reviewer_id",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.user_rewards" */
export enum voyce_user_rewards_constraint {
	user_rewards_pkey = "user_rewards_pkey"
}
/** select columns of table "voyce.user_rewards" */
export enum voyce_user_rewards_select_column {
	coin_amount = "coin_amount",
	coin_type = "coin_type",
	created_at = "created_at",
	id = "id",
	type = "type",
	user_id = "user_id"
}
/** update columns of table "voyce.user_rewards" */
export enum voyce_user_rewards_update_column {
	coin_amount = "coin_amount",
	coin_type = "coin_type",
	created_at = "created_at",
	id = "id",
	type = "type",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.user_roles" */
export enum voyce_user_roles_constraint {
	pk_user_roles = "pk_user_roles"
}
/** select columns of table "voyce.user_roles" */
export enum voyce_user_roles_select_column {
	date_create = "date_create",
	id = "id",
	title = "title"
}
/** update columns of table "voyce.user_roles" */
export enum voyce_user_roles_update_column {
	date_create = "date_create",
	id = "id",
	title = "title"
}
/** unique or primary key constraints on table "voyce.user_series_subscription_count" */
export enum voyce_user_series_subscription_count_constraint {
	user_series_subscription_count_user_id_idx = "user_series_subscription_count_user_id_idx"
}
/** select columns of table "voyce.user_series_subscription_count" */
export enum voyce_user_series_subscription_count_select_column {
	count = "count",
	user_id = "user_id"
}
/** update columns of table "voyce.user_series_subscription_count" */
export enum voyce_user_series_subscription_count_update_column {
	count = "count",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.user_settings" */
export enum voyce_user_settings_constraint {
	user_settings_pkey = "user_settings_pkey"
}
/** select columns of table "voyce.user_settings" */
export enum voyce_user_settings_select_column {
	content_mature = "content_mature",
	content_spoilers = "content_spoilers",
	id = "id",
	page_animation = "page_animation",
	reading_comic = "reading_comic",
	reading_manga = "reading_manga",
	reading_novel = "reading_novel",
	theme = "theme",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** update columns of table "voyce.user_settings" */
export enum voyce_user_settings_update_column {
	content_mature = "content_mature",
	content_spoilers = "content_spoilers",
	id = "id",
	page_animation = "page_animation",
	reading_comic = "reading_comic",
	reading_manga = "reading_manga",
	reading_novel = "reading_novel",
	theme = "theme",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.user_skills" */
export enum voyce_user_skills_constraint {
	pk_user_skills = "pk_user_skills"
}
/** select columns of table "voyce.user_skills" */
export enum voyce_user_skills_select_column {
	date_create = "date_create",
	id = "id",
	skill = "skill",
	user_id = "user_id"
}
/** update columns of table "voyce.user_skills" */
export enum voyce_user_skills_update_column {
	date_create = "date_create",
	id = "id",
	skill = "skill",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.user_socials" */
export enum voyce_user_socials_constraint {
	user_socials_pkey = "user_socials_pkey"
}
/** select columns of table "voyce.user_socials" */
export enum voyce_user_socials_select_column {
	access_token = "access_token",
	created_at = "created_at",
	expired_at = "expired_at",
	expires_in = "expires_in",
	id = "id",
	provider = "provider",
	refresh_token = "refresh_token",
	scope = "scope",
	token_type = "token_type",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** update columns of table "voyce.user_socials" */
export enum voyce_user_socials_update_column {
	access_token = "access_token",
	created_at = "created_at",
	expired_at = "expired_at",
	expires_in = "expires_in",
	id = "id",
	provider = "provider",
	refresh_token = "refresh_token",
	scope = "scope",
	token_type = "token_type",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.user_soft_deleted" */
export enum voyce_user_soft_deleted_constraint {
	user_soft_deleted_pkey = "user_soft_deleted_pkey",
	user_soft_deleted_user_id_key = "user_soft_deleted_user_id_key"
}
/** select columns of table "voyce.user_soft_deleted" */
export enum voyce_user_soft_deleted_select_column {
	about = "about",
	avatar = "avatar",
	banner = "banner",
	created_at = "created_at",
	deleted_at = "deleted_at",
	email = "email",
	first_name = "first_name",
	id = "id",
	is_deleted = "is_deleted",
	last_name = "last_name",
	nickname = "nickname",
	social_discord = "social_discord",
	social_fb = "social_fb",
	social_instagram = "social_instagram",
	social_tiktok = "social_tiktok",
	social_twitter = "social_twitter",
	user_id = "user_id",
	username = "username"
}
/** update columns of table "voyce.user_soft_deleted" */
export enum voyce_user_soft_deleted_update_column {
	about = "about",
	avatar = "avatar",
	banner = "banner",
	created_at = "created_at",
	deleted_at = "deleted_at",
	email = "email",
	first_name = "first_name",
	id = "id",
	is_deleted = "is_deleted",
	last_name = "last_name",
	nickname = "nickname",
	social_discord = "social_discord",
	social_fb = "social_fb",
	social_instagram = "social_instagram",
	social_tiktok = "social_tiktok",
	social_twitter = "social_twitter",
	user_id = "user_id",
	username = "username"
}
/** unique or primary key constraints on table "voyce.user_vtags" */
export enum voyce_user_vtags_constraint {
	user_vtags_pkey = "user_vtags_pkey",
	user_vtags_user_id_key = "user_vtags_user_id_key",
	user_vtags_vtag_v2_key = "user_vtags_vtag_v2_key"
}
/** select columns of table "voyce.user_vtags" */
export enum voyce_user_vtags_select_column {
	id = "id",
	user_id = "user_id",
	vtag = "vtag",
	vtag_v2 = "vtag_v2"
}
/** update columns of table "voyce.user_vtags" */
export enum voyce_user_vtags_update_column {
	id = "id",
	user_id = "user_id",
	vtag = "vtag",
	vtag_v2 = "vtag_v2"
}
/** unique or primary key constraints on table "voyce.user_wins" */
export enum voyce_user_wins_constraint {
	user_wins_pkey = "user_wins_pkey"
}
/** select columns of table "voyce.user_wins" */
export enum voyce_user_wins_select_column {
	claimed = "claimed",
	created_at = "created_at",
	id = "id",
	linked_id = "linked_id",
	source = "source",
	title = "title",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** update columns of table "voyce.user_wins" */
export enum voyce_user_wins_update_column {
	claimed = "claimed",
	created_at = "created_at",
	id = "id",
	linked_id = "linked_id",
	source = "source",
	title = "title",
	updated_at = "updated_at",
	user_id = "user_id"
}
/** unique or primary key constraints on table "voyce.users" */
export enum voyce_users_constraint {
	pk_users = "pk_users",
	users_cognito_id_key = "users_cognito_id_key",
	users_email_key = "users_email_key"
}
/** unique or primary key constraints on table "voyce.users_followers" */
export enum voyce_users_followers_constraint {
	users_followers_following_uid_follower_uid_key = "users_followers_following_uid_follower_uid_key",
	users_followers_pkey = "users_followers_pkey"
}
/** select columns of table "voyce.users_followers" */
export enum voyce_users_followers_select_column {
	created_at = "created_at",
	follower_uid = "follower_uid",
	following_uid = "following_uid",
	id = "id"
}
/** update columns of table "voyce.users_followers" */
export enum voyce_users_followers_update_column {
	created_at = "created_at",
	follower_uid = "follower_uid",
	following_uid = "following_uid",
	id = "id"
}
/** select columns of table "voyce.users_private" */
export enum voyce_users_private_select_column {
	about = "about",
	ad_share = "ad_share",
	avatar = "avatar",
	banner = "banner",
	birth_date = "birth_date",
	dark_mode = "dark_mode",
	date_create = "date_create",
	email = "email",
	first_name = "first_name",
	gender = "gender",
	id = "id",
	is_account_active = "is_account_active",
	last_login = "last_login",
	last_name = "last_name",
	nickname = "nickname",
	password = "password",
	remember_code = "remember_code",
	role_id = "role_id",
	username = "username",
	vmuser = "vmuser"
}
/** select columns of table "voyce.users" */
export enum voyce_users_select_column {
	about = "about",
	account_disabled = "account_disabled",
	ad_share = "ad_share",
	admin_role_id = "admin_role_id",
	avatar = "avatar",
	avatar_bgcolor = "avatar_bgcolor",
	banner = "banner",
	birth_date = "birth_date",
	cognito_id = "cognito_id",
	cognito_provider = "cognito_provider",
	cognito_username = "cognito_username",
	country_id = "country_id",
	creator_type_preferences = "creator_type_preferences",
	dark_mode = "dark_mode",
	date_create = "date_create",
	delete_code = "delete_code",
	email = "email",
	email_verified = "email_verified",
	email_verified_source = "email_verified_source",
	fcm_token = "fcm_token",
	fcm_tokens = "fcm_tokens",
	first_name = "first_name",
	frame = "frame",
	gender = "gender",
	guest_gtag = "guest_gtag",
	id = "id",
	is_account_active = "is_account_active",
	is_approved = "is_approved",
	is_onboarded = "is_onboarded",
	is_publisher_verified = "is_publisher_verified",
	klaviyo_id = "klaviyo_id",
	last_login = "last_login",
	last_name = "last_name",
	linked_account_status = "linked_account_status",
	new_email_code = "new_email_code",
	newsletter_subscribe = "newsletter_subscribe",
	nickname = "nickname",
	password = "password",
	password_secure = "password_secure",
	publisher_verification_code = "publisher_verification_code",
	quest_onboarded = "quest_onboarded",
	remember_code = "remember_code",
	reset_code = "reset_code",
	reset_code_created_at = "reset_code_created_at",
	reset_otp = "reset_otp",
	role_id = "role_id",
	signup_source = "signup_source",
	social_discord = "social_discord",
	social_fb = "social_fb",
	social_instagram = "social_instagram",
	social_tiktok = "social_tiktok",
	social_twitter = "social_twitter",
	unsubscribe = "unsubscribe",
	updated_at = "updated_at",
	user_type_preference = "user_type_preference",
	username = "username",
	vmuser = "vmuser"
}
/** unique or primary key constraints on table "voyce.users_series_junction" */
export enum voyce_users_series_junction_constraint {
	users_series_junction_id_key = "users_series_junction_id_key",
	users_series_junction_pkey = "users_series_junction_pkey"
}
/** select columns of table "voyce.users_series_junction" */
export enum voyce_users_series_junction_select_column {
	id = "id",
	series_id = "series_id",
	user_id = "user_id"
}
/** update columns of table "voyce.users_series_junction" */
export enum voyce_users_series_junction_update_column {
	id = "id",
	series_id = "series_id",
	user_id = "user_id"
}
/** update columns of table "voyce.users" */
export enum voyce_users_update_column {
	about = "about",
	account_disabled = "account_disabled",
	ad_share = "ad_share",
	admin_role_id = "admin_role_id",
	avatar = "avatar",
	avatar_bgcolor = "avatar_bgcolor",
	banner = "banner",
	birth_date = "birth_date",
	cognito_id = "cognito_id",
	cognito_provider = "cognito_provider",
	cognito_username = "cognito_username",
	country_id = "country_id",
	creator_type_preferences = "creator_type_preferences",
	dark_mode = "dark_mode",
	date_create = "date_create",
	delete_code = "delete_code",
	email = "email",
	email_verified = "email_verified",
	email_verified_source = "email_verified_source",
	fcm_token = "fcm_token",
	fcm_tokens = "fcm_tokens",
	first_name = "first_name",
	frame = "frame",
	gender = "gender",
	guest_gtag = "guest_gtag",
	id = "id",
	is_account_active = "is_account_active",
	is_approved = "is_approved",
	is_onboarded = "is_onboarded",
	is_publisher_verified = "is_publisher_verified",
	klaviyo_id = "klaviyo_id",
	last_login = "last_login",
	last_name = "last_name",
	linked_account_status = "linked_account_status",
	new_email_code = "new_email_code",
	newsletter_subscribe = "newsletter_subscribe",
	nickname = "nickname",
	password = "password",
	password_secure = "password_secure",
	publisher_verification_code = "publisher_verification_code",
	quest_onboarded = "quest_onboarded",
	remember_code = "remember_code",
	reset_code = "reset_code",
	reset_code_created_at = "reset_code_created_at",
	reset_otp = "reset_otp",
	role_id = "role_id",
	signup_source = "signup_source",
	social_discord = "social_discord",
	social_fb = "social_fb",
	social_instagram = "social_instagram",
	social_tiktok = "social_tiktok",
	social_twitter = "social_twitter",
	unsubscribe = "unsubscribe",
	updated_at = "updated_at",
	user_type_preference = "user_type_preference",
	username = "username",
	vmuser = "vmuser"
}
/** unique or primary key constraints on table "voyce.versions" */
export enum voyce_versions_constraint {
	versions_pkey = "versions_pkey"
}
/** select columns of table "voyce.versions" */
export enum voyce_versions_select_column {
	id = "id",
	table = "table",
	updated_at = "updated_at",
	version = "version"
}
/** update columns of table "voyce.versions" */
export enum voyce_versions_update_column {
	id = "id",
	table = "table",
	updated_at = "updated_at",
	version = "version"
}
/** unique or primary key constraints on table "voyce.voting_analytics" */
export enum voyce_voting_analytics_constraint {
	voting_analytics_pkey = "voting_analytics_pkey",
	voting_analytics_voting_series_id_vote_date_key = "voting_analytics_voting_series_id_vote_date_key"
}
/** select columns of table "voyce.voting_analytics" */
export enum voyce_voting_analytics_select_column {
	created_at = "created_at",
	cumulative_votes = "cumulative_votes",
	daily_votes = "daily_votes",
	id = "id",
	meta = "meta",
	updated_at = "updated_at",
	vote_date = "vote_date",
	voting_series_id = "voting_series_id"
}
/** update columns of table "voyce.voting_analytics" */
export enum voyce_voting_analytics_update_column {
	created_at = "created_at",
	cumulative_votes = "cumulative_votes",
	daily_votes = "daily_votes",
	id = "id",
	meta = "meta",
	updated_at = "updated_at",
	vote_date = "vote_date",
	voting_series_id = "voting_series_id"
}
/** unique or primary key constraints on table "voyce.voting_panels" */
export enum voyce_voting_panels_constraint {
	voting_panels_pkey = "voting_panels_pkey"
}
/** select columns of table "voyce.voting_panels" */
export enum voyce_voting_panels_select_column {
	created_at = "created_at",
	id = "id",
	panel_image = "panel_image",
	updated_at = "updated_at",
	voting_series_id = "voting_series_id"
}
/** update columns of table "voyce.voting_panels" */
export enum voyce_voting_panels_update_column {
	created_at = "created_at",
	id = "id",
	panel_image = "panel_image",
	updated_at = "updated_at",
	voting_series_id = "voting_series_id"
}
/** unique or primary key constraints on table "voyce.voting_series" */
export enum voyce_voting_series_constraint {
	voting_series_pkey = "voting_series_pkey"
}
/** select columns of table "voyce.voting_series" */
export enum voyce_voting_series_select_column {
	author_image = "author_image",
	author_name = "author_name",
	collaborators = "collaborators",
	created_at = "created_at",
	id = "id",
	series_id = "series_id",
	sort = "sort",
	spotlight_caption = "spotlight_caption",
	spotlight_img1 = "spotlight_img1",
	spotlight_img2 = "spotlight_img2",
	spotlight_img3 = "spotlight_img3",
	updated_at = "updated_at"
}
/** update columns of table "voyce.voting_series" */
export enum voyce_voting_series_update_column {
	author_image = "author_image",
	author_name = "author_name",
	collaborators = "collaborators",
	created_at = "created_at",
	id = "id",
	series_id = "series_id",
	sort = "sort",
	spotlight_caption = "spotlight_caption",
	spotlight_img1 = "spotlight_img1",
	spotlight_img2 = "spotlight_img2",
	spotlight_img3 = "spotlight_img3",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "voyce.voting_user_votes" */
export enum voyce_voting_user_votes_constraint {
	voting_user_votes_pkey = "voting_user_votes_pkey",
	voting_user_votes_user_id_date_voted_key = "voting_user_votes_user_id_date_voted_key"
}
/** select columns of table "voyce.voting_user_votes" */
export enum voyce_voting_user_votes_select_column {
	date_voted = "date_voted",
	id = "id",
	time_voted = "time_voted",
	user_id = "user_id",
	voting_series_id = "voting_series_id"
}
/** update columns of table "voyce.voting_user_votes" */
export enum voyce_voting_user_votes_update_column {
	date_voted = "date_voted",
	id = "id",
	time_voted = "time_voted",
	user_id = "user_id",
	voting_series_id = "voting_series_id"
}
export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    toString() {
      return "GraphQL Response Error";
    }
  }


export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

type WithTypeNameValue<T> = T & {
  __typename?: true;
};
type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;

type NotUnionTypes<SRC extends DeepAnify<DST>, DST> = {
  [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
}[keyof DST];

type ExtractUnions<SRC extends DeepAnify<DST>, DST> = {
  [P in keyof SRC]: SRC[P] extends '__union' & infer R
    ? P extends keyof DST
      ? IsArray<R, DST[P] & { __typename: true }>
      : {}
    : never;
}[keyof SRC];

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
  ? ExtractUnions<SRC, DST> &
      {
        [P in keyof Omit<Pick<SRC, NotUnionTypes<SRC, DST>>, '__typename'>]: DST[P] extends true
          ? SRC[P]
          : IsArray<SRC[P], DST[P]>;
      }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends true ? SRC[P] : IsArray<SRC[P], DST[P]>;
    };



export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>;
    } &
      MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;
export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;
export type OperationToGraphQL<V, T> = <Z extends V>(o: Z | V, variables?: Record<string, any>) => Promise<InputType<T, Z>>;
export type SubscriptionToGraphQL<V, T> = <Z extends V>(
  o: Z | V,
  variables?: Record<string, any>,
) => {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void;
  error: (e: { data?: InputType<T, Z>; message?: string }) => void;
  open: () => void;
};
export type CastToGraphQL<V, T> = (resultOfYourQuery: any) => <Z extends V>(o: Z | V) => InputType<T, Z>;
export type SelectionFunction<V> = <T>(t: T | V) => T;
export type fetchOptions = ArgsType<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (
  ...args: infer R
) => WebSocket
  ? R
  : never;
export type chainOptions =
  | [fetchOptions[0], fetchOptions[1] & {websocket?: websocketOptions}]
  | [fetchOptions[0]];
export type FetchFunction = (
  query: string,
  variables?: Record<string, any>,
) => Promise<any>;
export type SubscriptionFunction = (
  query: string,
  variables?: Record<string, any>,
) => void;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .map((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).map((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


export const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${inspectVariables(buildQuery(tName, o))}`;
  

const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  variables?: Record<string, any>,
) => fn(queryConstruct(t, tName)(o), variables).then((r:any) => { 
  seekForAliases(r)
  return r
});

export const fullChainConstructor = <F extends FetchFunction, R extends keyof ValueTypes>(
  fn: F,
  operation: 'query' | 'mutation' | 'subscription',
  key: R,
) =>
  ((o, variables) => fullChainConstruct(fn)(operation, key)(o as any, variables)) as OperationToGraphQL<
    ValueTypes[R],
    GraphQLTypes[R]
  >;


const fullSubscriptionConstruct = (fn: SubscriptionFunction) => (
  t: 'query' | 'mutation' | 'subscription',
  tName: string,
) => (o: Record<any, any>, variables?: Record<string, any>) =>
  fn(queryConstruct(t, tName)(o), variables);

export const fullSubscriptionConstructor = <F extends SubscriptionFunction, R extends keyof ValueTypes>(
  fn: F,
  operation: 'query' | 'mutation' | 'subscription',
  key: R,
) =>
  ((o, variables) => fullSubscriptionConstruct(fn)(operation, key)(o as any, variables)) as SubscriptionToGraphQL<
    ValueTypes[R],
    GraphQLTypes[R]
  >;


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


export const resolverFor = <
  T extends keyof ValueTypes,
  Z extends keyof ValueTypes[T],
  Y extends (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> : any
>(
  type: T,
  field: Z,
  fn: Y,
) => fn as (args?: any,source?: any) => any;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

export const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  

export const apiSubscription = (options: chainOptions) => (
    query: string,
    variables: Record<string, any> = {},
  ) => {
    try {
      const queryString = options[0] + '?query=' + encodeURIComponent(query);
      const wsString = queryString.replace('http', 'ws');
      const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
      const webSocketOptions = options[1]?.websocket || [host];
      const ws = new WebSocket(...webSocketOptions);
      return {
        ws,
        on: (e: (args: any) => void) => {
          ws.onmessage = (event:any) => {
            if(event.data){
              const parsed = JSON.parse(event.data)
              const data = parsed.data
              if (data) {
                seekForAliases(data);
              }
              return e(data);
            }
          };
        },
        off: (e: (args: any) => void) => {
          ws.onclose = e;
        },
        error: (e: (args: any) => void) => {
          ws.onerror = e;
        },
        open: (e: () => void) => {
          ws.onopen = e;
        },
      };
    } catch {
      throw new Error('No websockets implemented');
    }
  };


export const Thunder = (fn: FetchFunction, subscriptionFn: SubscriptionFunction) => ({
  query: fullChainConstructor(fn,'query', 'query_root'),
mutation: fullChainConstructor(fn,'mutation', 'mutation_root'),
subscription: fullSubscriptionConstructor(subscriptionFn,'subscription', 'subscription_root')
});

export const Chain = (...options: chainOptions) => ({
  query: fullChainConstructor(apiFetch(options),'query', 'query_root'),
mutation: fullChainConstructor(apiFetch(options),'mutation', 'mutation_root'),
subscription: fullSubscriptionConstructor(apiSubscription(options),'subscription', 'subscription_root')
});
export const Zeus = {
  query: (o:ValueTypes["query_root"]) => queryConstruct('query', 'query_root')(o),
mutation: (o:ValueTypes["mutation_root"]) => queryConstruct('mutation', 'mutation_root')(o),
subscription: (o:ValueTypes["subscription_root"]) => queryConstruct('subscription', 'subscription_root')(o)
};
export const Cast = {
  query: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["query_root"],
  GraphQLTypes["query_root"]
>,
mutation: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["mutation_root"],
  GraphQLTypes["mutation_root"]
>,
subscription: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["subscription_root"],
  GraphQLTypes["subscription_root"]
>
};
export const Selectors = {
  query: ZeusSelect<ValueTypes["query_root"]>(),
mutation: ZeusSelect<ValueTypes["mutation_root"]>(),
subscription: ZeusSelect<ValueTypes["subscription_root"]>()
};
  

export const Gql = Chain('https://graphql.voyce.me/v1/graphql')