import { Chain as _chain } from './generatedGraphqlClient/zeus';
import Cookies from 'universal-cookie';
import { parseJwt, TOKEN_KEY } from './jwt';
import { GRAPHQL_URL, HASURA_SECRET } from '../env/consts';

export const Chain = () => {
  if (typeof window !== 'undefined') {
    const cookies = new Cookies();
    let token = cookies.get(TOKEN_KEY);

    if (token) {
      const authInfo = parseJwt(token);
  
      if (Date.now() >= authInfo.exp * 1000) {
        cookies.remove(TOKEN_KEY, {
          path: '/',
          domain: '.voyce.me'
        });
        token = undefined;
      }
    }

    if (!token) {
      return _chain(GRAPHQL_URL, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    }

    return _chain(GRAPHQL_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
  }

  return _chain(GRAPHQL_URL, {
    headers: {
      'Content-Type': 'application/json',
      'x-hasura-admin-secret': HASURA_SECRET //TODO replace with aws fetching
    }
  });
};

export const ChainWithToken = (token) =>{
  return _chain(GRAPHQL_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    });
}
