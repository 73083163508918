/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Boolean_comparison_exp:{
		_eq:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"Boolean",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"Boolean",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	Int_comparison_exp:{
		_eq:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	String_comparison_exp:{
		_eq:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_ilike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_iregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_like:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nilike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_niregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nlike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nsimilar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_regex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_similar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	awsdms_validation_failures_v1_aggregate_fields:{
		count:{
			columns:{
				type:"awsdms_validation_failures_v1_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	awsdms_validation_failures_v1_bool_exp:{
		DETAILS:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		FAILURE_TIME:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		FAILURE_TYPE:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		KEY:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		KEY_TYPE:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TABLE_NAME:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TABLE_OWNER:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		TASK_NAME:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_and:{
			type:"awsdms_validation_failures_v1_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"awsdms_validation_failures_v1_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"awsdms_validation_failures_v1_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	awsdms_validation_failures_v1_insert_input:{
		DETAILS:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		FAILURE_TIME:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		FAILURE_TYPE:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		KEY:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		KEY_TYPE:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		TABLE_NAME:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		TABLE_OWNER:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		TASK_NAME:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	awsdms_validation_failures_v1_order_by:{
		DETAILS:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		FAILURE_TIME:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		FAILURE_TYPE:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		KEY:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		KEY_TYPE:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		TABLE_NAME:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		TABLE_OWNER:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		TASK_NAME:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	awsdms_validation_failures_v1_select_column: "enum",
	awsdms_validation_failures_v1_set_input:{
		DETAILS:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		FAILURE_TIME:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		FAILURE_TYPE:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		KEY:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		KEY_TYPE:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		TABLE_NAME:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		TABLE_OWNER:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		TASK_NAME:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	awsdms_validation_failures_v1_stream_cursor_input:{
		initial_value:{
			type:"awsdms_validation_failures_v1_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	awsdms_validation_failures_v1_stream_cursor_value_input:{
		DETAILS:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		FAILURE_TIME:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		FAILURE_TYPE:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		KEY:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		KEY_TYPE:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		TABLE_NAME:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		TABLE_OWNER:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		TASK_NAME:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	awsdms_validation_failures_v1_updates:{
		_set:{
			type:"awsdms_validation_failures_v1_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"awsdms_validation_failures_v1_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	bigint: "String",
	bigint_comparison_exp:{
		_eq:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"bigint",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"bigint",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	bit: "String",
	bit_comparison_exp:{
		_eq:{
			type:"bit",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"bit",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"bit",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"bit",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"bit",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"bit",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"bit",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"bit",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	bpchar: "String",
	bpchar_comparison_exp:{
		_eq:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		_ilike:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"bpchar",
			array:true,
			arrayRequired:false,
			required:true
		},
		_iregex:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_like:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nilike:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"bpchar",
			array:true,
			arrayRequired:false,
			required:true
		},
		_niregex:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nlike:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nregex:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nsimilar:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		_regex:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		_similar:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	bytea: "String",
	bytea_comparison_exp:{
		_eq:{
			type:"bytea",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"bytea",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"bytea",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"bytea",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"bytea",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"bytea",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"bytea",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"bytea",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	coinsystem_balances_aggregate_fields:{
		count:{
			columns:{
				type:"coinsystem_balances_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	coinsystem_balances_bool_exp:{
		_and:{
			type:"coinsystem_balances_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"coinsystem_balances_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"coinsystem_balances_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		balance:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin:{
			type:"coinsystem_coins_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		wallet:{
			type:"coinsystem_wallets_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		wallet_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_balances_constraint: "enum",
	coinsystem_balances_inc_input:{
		balance:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		wallet_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_balances_insert_input:{
		balance:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin:{
			type:"coinsystem_coins_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		wallet:{
			type:"coinsystem_wallets_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		wallet_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_balances_on_conflict:{
		constraint:{
			type:"coinsystem_balances_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"coinsystem_balances_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"coinsystem_balances_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_balances_order_by:{
		balance:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin:{
			type:"coinsystem_coins_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		wallet:{
			type:"coinsystem_wallets_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		wallet_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_balances_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	coinsystem_balances_select_column: "enum",
	coinsystem_balances_set_input:{
		balance:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		wallet_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_balances_stream_cursor_input:{
		initial_value:{
			type:"coinsystem_balances_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_balances_stream_cursor_value_input:{
		balance:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		wallet_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_balances_update_column: "enum",
	coinsystem_balances_updates:{
		_inc:{
			type:"coinsystem_balances_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"coinsystem_balances_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"coinsystem_balances_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	coinsystem_bonus_coins_aggregate_fields:{
		count:{
			columns:{
				type:"coinsystem_bonus_coins_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	coinsystem_bonus_coins_bool_exp:{
		_and:{
			type:"coinsystem_bonus_coins_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"coinsystem_bonus_coins_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"coinsystem_bonus_coins_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		active:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		bonus_amount:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_amount:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		min_amount:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_bonus_coins_constraint: "enum",
	coinsystem_bonus_coins_inc_input:{
		bonus_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		min_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_bonus_coins_insert_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		bonus_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		min_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_bonus_coins_on_conflict:{
		constraint:{
			type:"coinsystem_bonus_coins_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"coinsystem_bonus_coins_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"coinsystem_bonus_coins_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_bonus_coins_order_by:{
		active:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		bonus_amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min_amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_bonus_coins_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	coinsystem_bonus_coins_select_column: "enum",
	coinsystem_bonus_coins_set_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		bonus_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		min_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_bonus_coins_stream_cursor_input:{
		initial_value:{
			type:"coinsystem_bonus_coins_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_bonus_coins_stream_cursor_value_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		bonus_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		min_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_bonus_coins_update_column: "enum",
	coinsystem_bonus_coins_updates:{
		_inc:{
			type:"coinsystem_bonus_coins_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"coinsystem_bonus_coins_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"coinsystem_bonus_coins_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	coinsystem_coins_aggregate_fields:{
		count:{
			columns:{
				type:"coinsystem_coins_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	coinsystem_coins_bool_exp:{
		_and:{
			type:"coinsystem_coins_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"coinsystem_coins_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"coinsystem_coins_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		can_mint:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_decimal:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_logo:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_symbol:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		default:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		in_app:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_withdrawable:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_coins_constraint: "enum",
	coinsystem_coins_inc_input:{
		coin_decimal:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_coins_insert_input:{
		can_mint:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_decimal:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_logo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_symbol:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		default:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		in_app:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_withdrawable:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_coins_obj_rel_insert_input:{
		data:{
			type:"coinsystem_coins_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"coinsystem_coins_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_coins_on_conflict:{
		constraint:{
			type:"coinsystem_coins_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"coinsystem_coins_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"coinsystem_coins_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_coins_order_by:{
		can_mint:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_decimal:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_logo:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_symbol:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		default:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		in_app:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_withdrawable:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_coins_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	coinsystem_coins_select_column: "enum",
	coinsystem_coins_set_input:{
		can_mint:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_decimal:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_logo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_symbol:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		default:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		in_app:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_withdrawable:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_coins_stream_cursor_input:{
		initial_value:{
			type:"coinsystem_coins_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_coins_stream_cursor_value_input:{
		can_mint:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_decimal:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_logo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_symbol:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		default:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		in_app:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_withdrawable:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_coins_update_column: "enum",
	coinsystem_coins_updates:{
		_inc:{
			type:"coinsystem_coins_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"coinsystem_coins_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"coinsystem_coins_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	coinsystem_exchange_rates_aggregate_fields:{
		count:{
			columns:{
				type:"coinsystem_exchange_rates_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	coinsystem_exchange_rates_bool_exp:{
		_and:{
			type:"coinsystem_exchange_rates_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"coinsystem_exchange_rates_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"coinsystem_exchange_rates_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		buy_rate:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		destination_coin_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sell_rate:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		source_coin_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_exchange_rates_constraint: "enum",
	coinsystem_exchange_rates_inc_input:{
		buy_rate:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		destination_coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sell_rate:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		source_coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_exchange_rates_insert_input:{
		buy_rate:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		destination_coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sell_rate:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		source_coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_exchange_rates_on_conflict:{
		constraint:{
			type:"coinsystem_exchange_rates_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"coinsystem_exchange_rates_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"coinsystem_exchange_rates_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_exchange_rates_order_by:{
		buy_rate:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		destination_coin_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sell_rate:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		source_coin_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_exchange_rates_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	coinsystem_exchange_rates_select_column: "enum",
	coinsystem_exchange_rates_set_input:{
		buy_rate:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		destination_coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sell_rate:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		source_coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_exchange_rates_stream_cursor_input:{
		initial_value:{
			type:"coinsystem_exchange_rates_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_exchange_rates_stream_cursor_value_input:{
		buy_rate:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		destination_coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sell_rate:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		source_coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_exchange_rates_update_column: "enum",
	coinsystem_exchange_rates_updates:{
		_inc:{
			type:"coinsystem_exchange_rates_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"coinsystem_exchange_rates_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"coinsystem_exchange_rates_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	coinsystem_logs_aggregate_fields:{
		count:{
			columns:{
				type:"coinsystem_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	coinsystem_logs_bool_exp:{
		_and:{
			type:"coinsystem_logs_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"coinsystem_logs_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"coinsystem_logs_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"bpchar_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		transaction_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_logs_constraint: "enum",
	coinsystem_logs_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_logs_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		transaction_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_logs_on_conflict:{
		constraint:{
			type:"coinsystem_logs_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"coinsystem_logs_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"coinsystem_logs_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_logs_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		transaction_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_logs_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	coinsystem_logs_select_column: "enum",
	coinsystem_logs_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		transaction_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_logs_stream_cursor_input:{
		initial_value:{
			type:"coinsystem_logs_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_logs_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		transaction_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_logs_update_column: "enum",
	coinsystem_logs_updates:{
		_inc:{
			type:"coinsystem_logs_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"coinsystem_logs_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"coinsystem_logs_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	coinsystem_transactions_aggregate_fields:{
		count:{
			columns:{
				type:"coinsystem_transactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	coinsystem_transactions_bool_exp:{
		_and:{
			type:"coinsystem_transactions_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"coinsystem_transactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"coinsystem_transactions_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		coin_amount:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"bpchar_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		recipient_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		recipient_wallet_address:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_wallet_address:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		transaction_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		transaction_type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_transactions_constraint: "enum",
	coinsystem_transactions_inc_input:{
		coin_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		recipient_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_transactions_insert_input:{
		coin_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		recipient_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		recipient_wallet_address:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_wallet_address:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		transaction_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		transaction_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_transactions_on_conflict:{
		constraint:{
			type:"coinsystem_transactions_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"coinsystem_transactions_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"coinsystem_transactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_transactions_order_by:{
		coin_amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		recipient_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		recipient_wallet_address:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_wallet_address:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		transaction_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		transaction_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_transactions_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	coinsystem_transactions_select_column: "enum",
	coinsystem_transactions_set_input:{
		coin_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		recipient_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		recipient_wallet_address:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_wallet_address:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		transaction_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		transaction_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_transactions_stream_cursor_input:{
		initial_value:{
			type:"coinsystem_transactions_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_transactions_stream_cursor_value_input:{
		coin_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		recipient_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		recipient_wallet_address:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_wallet_address:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		transaction_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		transaction_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_transactions_update_column: "enum",
	coinsystem_transactions_updates:{
		_inc:{
			type:"coinsystem_transactions_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"coinsystem_transactions_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"coinsystem_transactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	coinsystem_wallets_aggregate_fields:{
		count:{
			columns:{
				type:"coinsystem_wallets_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	coinsystem_wallets_bool_exp:{
		_and:{
			type:"coinsystem_wallets_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"coinsystem_wallets_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"coinsystem_wallets_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		can_mint:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		default:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_vm:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		wallet_address:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_wallets_constraint: "enum",
	coinsystem_wallets_inc_input:{
		coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_wallets_insert_input:{
		can_mint:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		default:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_vm:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		wallet_address:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_wallets_obj_rel_insert_input:{
		data:{
			type:"coinsystem_wallets_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"coinsystem_wallets_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_wallets_on_conflict:{
		constraint:{
			type:"coinsystem_wallets_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"coinsystem_wallets_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"coinsystem_wallets_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_wallets_order_by:{
		can_mint:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		default:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_vm:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		wallet_address:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_wallets_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	coinsystem_wallets_select_column: "enum",
	coinsystem_wallets_set_input:{
		can_mint:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		default:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_vm:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		wallet_address:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_wallets_stream_cursor_input:{
		initial_value:{
			type:"coinsystem_wallets_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_wallets_stream_cursor_value_input:{
		can_mint:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		default:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_vm:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		wallet_address:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	coinsystem_wallets_update_column: "enum",
	coinsystem_wallets_updates:{
		_inc:{
			type:"coinsystem_wallets_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"coinsystem_wallets_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"coinsystem_wallets_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	cursor_ordering: "enum",
	date: "String",
	date_comparison_exp:{
		_eq:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"date",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"date",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	float8: "String",
	float8_comparison_exp:{
		_eq:{
			type:"float8",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"float8",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"float8",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"float8",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"float8",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"float8",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"float8",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"float8",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	json: "String",
	json_comparison_exp:{
		_eq:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"json",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"json",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	jsonb: "String",
	jsonb_cast_exp:{
		String:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	jsonb_comparison_exp:{
		_cast:{
			type:"jsonb_cast_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_contained_in:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_contains:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_eq:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_has_key:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_has_keys_all:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_has_keys_any:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_in:{
			type:"jsonb",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"jsonb",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	mutation_root:{
		delete_awsdms_validation_failures_v1:{
			where:{
				type:"awsdms_validation_failures_v1_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_coinsystem_balances:{
			where:{
				type:"coinsystem_balances_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_coinsystem_balances_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_coinsystem_bonus_coins:{
			where:{
				type:"coinsystem_bonus_coins_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_coinsystem_bonus_coins_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_coinsystem_coins:{
			where:{
				type:"coinsystem_coins_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_coinsystem_coins_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_coinsystem_exchange_rates:{
			where:{
				type:"coinsystem_exchange_rates_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_coinsystem_exchange_rates_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_coinsystem_logs:{
			where:{
				type:"coinsystem_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_coinsystem_logs_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_coinsystem_transactions:{
			where:{
				type:"coinsystem_transactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_coinsystem_transactions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_coinsystem_wallets:{
			where:{
				type:"coinsystem_wallets_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_coinsystem_wallets_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_mysql_user:{
			where:{
				type:"mysql_user_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_mysql_user_by_pk:{
			host:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			user:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_store_payments:{
			where:{
				type:"store_payments_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_store_payments_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			},
			purchase_id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_store_products:{
			where:{
				type:"store_products_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_store_products_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_store_purchases:{
			where:{
				type:"store_purchases_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_store_purchases_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_store_users:{
			where:{
				type:"store_users_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_admin_roles:{
			where:{
				type:"voyce_admin_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_admin_roles_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_app_banners:{
			where:{
				type:"voyce_app_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_app_banners_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_app_components:{
			where:{
				type:"voyce_app_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_app_components_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_audit_trail:{
			where:{
				type:"voyce_audit_trail_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_audit_trail_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_author_profiles:{
			where:{
				type:"voyce_author_profiles_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_author_profiles_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_balances:{
			where:{
				type:"voyce_balances_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_balances_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_banners:{
			where:{
				type:"voyce_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_banners_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_assets:{
			where:{
				type:"voyce_chapter_assets_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_assets_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_bookmarks:{
			where:{
				type:"voyce_chapter_bookmarks_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_bookmarks_by_pk:{
			chapter_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			user_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_comments:{
			where:{
				type:"voyce_chapter_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_comments_07252024:{
			where:{
				type:"voyce_chapter_comments_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_comments_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_comments_reaction:{
			where:{
				type:"voyce_chapter_comments_reaction_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_comments_reaction_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_comments_reaction_count:{
			where:{
				type:"voyce_chapter_comments_reaction_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_comments_reaction_count_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_comments_reports:{
			where:{
				type:"voyce_chapter_comments_reports_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_comments_reports_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_contents:{
			where:{
				type:"voyce_chapter_contents_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_contents_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_contents_old:{
			where:{
				type:"voyce_chapter_contents_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_contents_old_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_effects:{
			where:{
				type:"voyce_chapter_effects_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_effects_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_images:{
			where:{
				type:"voyce_chapter_images_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_images_07252024:{
			where:{
				type:"voyce_chapter_images_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_images_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_likes:{
			where:{
				type:"voyce_chapter_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_likes_by_pk:{
			chapter_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			created_at:{
				type:"timestamptz",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_locks:{
			where:{
				type:"voyce_chapter_locks_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_locks_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_panel_views:{
			where:{
				type:"voyce_chapter_panel_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_panel_views_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_panels:{
			where:{
				type:"voyce_chapter_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_panels_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_publish_schedules:{
			where:{
				type:"voyce_chapter_publish_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_publish_schedules_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_reactions:{
			where:{
				type:"voyce_chapter_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_reactions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_reactions_count:{
			where:{
				type:"voyce_chapter_reactions_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_reactions_count_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_view_logs:{
			where:{
				type:"voyce_chapter_view_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_view_logs_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_views:{
			where:{
				type:"voyce_chapter_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapter_views_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapters:{
			where:{
				type:"voyce_chapters_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapters_07252024:{
			where:{
				type:"voyce_chapters_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_chapters_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_collaborator_roles:{
			where:{
				type:"voyce_collaborator_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_collaborator_roles_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_comic_types:{
			where:{
				type:"voyce_comic_types_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_comic_types_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_community_blurbs:{
			where:{
				type:"voyce_community_blurbs_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_community_blurbs_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_contact_us:{
			where:{
				type:"voyce_contact_us_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_contact_us_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_content_report:{
			where:{
				type:"voyce_content_report_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_content_report_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_contest_data:{
			where:{
				type:"voyce_contest_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_contest_data_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_countries:{
			where:{
				type:"voyce_countries_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_countries_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_csat_ratings:{
			where:{
				type:"voyce_csat_ratings_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_csat_ratings_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_csat_ratings_data:{
			where:{
				type:"voyce_csat_ratings_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_csat_ratings_data_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_education_types:{
			where:{
				type:"voyce_education_types_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_education_types_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_erp_login_roles:{
			where:{
				type:"voyce_erp_login_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_erp_login_roles_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_etl_series_views_logs:{
			where:{
				type:"voyce_etl_series_views_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_etl_series_views_logs_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_event_schedules:{
			where:{
				type:"voyce_event_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_event_schedules_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_genres:{
			where:{
				type:"voyce_genres_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_genres_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_guest_gtags:{
			where:{
				type:"voyce_guest_gtags_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_guest_gtags_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_metabases:{
			where:{
				type:"voyce_metabases_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_metabases_access:{
			where:{
				type:"voyce_metabases_access_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_metabases_access_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_metabases_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_newsletter:{
			where:{
				type:"voyce_newsletter_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_newsletter_by_pk:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_notification_series_settings:{
			where:{
				type:"voyce_notification_series_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_notification_series_settings_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_notification_settings:{
			where:{
				type:"voyce_notification_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_notification_settings_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_notifications:{
			where:{
				type:"voyce_notifications_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_notifications_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_page_banners:{
			where:{
				type:"voyce_page_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_page_banners_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_page_components:{
			where:{
				type:"voyce_page_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_page_components_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_pages:{
			where:{
				type:"voyce_pages_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_pages_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_panels_comments:{
			where:{
				type:"voyce_panels_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_panels_comments_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_panels_reactions:{
			where:{
				type:"voyce_panels_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_panels_reactions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_panels_reactions_count:{
			where:{
				type:"voyce_panels_reactions_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_panels_reactions_count_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_payments_to_creators:{
			where:{
				type:"voyce_payments_to_creators_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_payments_to_creators_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_pg_rs_series_views_count:{
			where:{
				type:"voyce_pg_rs_series_views_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_pg_rs_series_views_count_by_pk:{
			series_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_polls:{
			where:{
				type:"voyce_polls_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_polls_answers:{
			where:{
				type:"voyce_polls_answers_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_polls_answers_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_polls_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_polls_comments:{
			where:{
				type:"voyce_polls_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_polls_comments_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_polls_reactions:{
			where:{
				type:"voyce_polls_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_polls_reactions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_polls_votes:{
			where:{
				type:"voyce_polls_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_polls_votes_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_popup_components:{
			where:{
				type:"voyce_popup_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_popup_components_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_popup_emails:{
			where:{
				type:"voyce_popup_emails_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_popup_emails_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_posts:{
			where:{
				type:"voyce_posts_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_posts_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_posts_comments:{
			where:{
				type:"voyce_posts_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_posts_comments_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_posts_reactions:{
			where:{
				type:"voyce_posts_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_posts_reactions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_quest_difficulties:{
			where:{
				type:"voyce_quest_difficulties_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_quest_difficulties_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_quests:{
			where:{
				type:"voyce_quests_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_quests_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_quests_old:{
			where:{
				type:"voyce_quests_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_quests_old_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_recommended_series:{
			where:{
				type:"voyce_recommended_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_recommended_series_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_release_schedules:{
			where:{
				type:"voyce_release_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_release_schedules_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_rewards:{
			where:{
				type:"voyce_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_rewards_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_rewards_leaderboard:{
			where:{
				type:"voyce_rewards_leaderboard_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_rewards_leaderboard_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_rewards_quest:{
			where:{
				type:"voyce_rewards_quest_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_rewards_quest_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_sections:{
			where:{
				type:"voyce_sections_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_sections_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series:{
			where:{
				type:"voyce_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_collaborators:{
			where:{
				type:"voyce_series_collaborators_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_collaborators_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_donations:{
			where:{
				type:"voyce_series_donations_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_donations_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_favorites:{
			where:{
				type:"voyce_series_favorites_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_favorites_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			series_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_featured_panels:{
			where:{
				type:"voyce_series_featured_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_featured_panels_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_genre:{
			where:{
				type:"voyce_series_genre_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_genre_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_giveaway_participants:{
			where:{
				type:"voyce_series_giveaway_participants_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_giveaway_participants_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_hot_panels:{
			where:{
				type:"voyce_series_hot_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_hot_panels_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_layouts:{
			where:{
				type:"voyce_series_layouts_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_layouts_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_leaderboard:{
			where:{
				type:"voyce_series_leaderboard_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_leaderboard_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_lock_config:{
			where:{
				type:"voyce_series_lock_config_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_lock_config_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_sponsorships_configuration:{
			where:{
				type:"voyce_series_sponsorships_configuration_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_sponsorships_configuration_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_subscription:{
			where:{
				type:"voyce_series_subscription_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_subscription_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_tags:{
			where:{
				type:"voyce_series_tags_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_tags_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_tags_junction:{
			where:{
				type:"voyce_series_tags_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_tags_junction_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_top_comments:{
			where:{
				type:"voyce_series_top_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_top_comments_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_top_likes:{
			where:{
				type:"voyce_series_top_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_top_likes_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_top_views:{
			where:{
				type:"voyce_series_top_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_top_views_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_views_adjustment:{
			where:{
				type:"voyce_series_views_adjustment_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_views_adjustment_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_votes:{
			where:{
				type:"voyce_series_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_votes_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_votes_count:{
			where:{
				type:"voyce_series_votes_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_votes_count_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_voting_schedules:{
			where:{
				type:"voyce_series_voting_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_series_voting_schedules_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_sponsorships:{
			where:{
				type:"voyce_sponsorships_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_sponsorships_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_states:{
			where:{
				type:"voyce_states_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_states_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_stripe_payouts_setup:{
			where:{
				type:"voyce_stripe_payouts_setup_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_stripe_payouts_setup_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_studio:{
			where:{
				type:"voyce_studio_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_studio_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_studio_followers:{
			where:{
				type:"voyce_studio_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_studio_followers_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_studio_series:{
			where:{
				type:"voyce_studio_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_studio_series_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_studio_views:{
			where:{
				type:"voyce_studio_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_studio_views_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_system_settings:{
			where:{
				type:"voyce_system_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_system_settings_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_transaction_logs:{
			where:{
				type:"voyce_transaction_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_transaction_logs_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_url_redirects:{
			where:{
				type:"voyce_url_redirects_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_url_redirects_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_addresses:{
			where:{
				type:"voyce_user_addresses_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_addresses_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_blocks:{
			where:{
				type:"voyce_user_blocks_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_blocks_by_pk:{
			linked_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			user_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_chapters_unlocked:{
			where:{
				type:"voyce_user_chapters_unlocked_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_chapters_unlocked_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_contacts:{
			where:{
				type:"voyce_user_contacts_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_contacts_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_csat_ratings:{
			where:{
				type:"voyce_user_csat_ratings_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_csat_ratings_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_education:{
			where:{
				type:"voyce_user_education_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_education_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_level:{
			where:{
				type:"voyce_user_level_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_level_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_linked_accounts:{
			where:{
				type:"voyce_user_linked_accounts_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_linked_accounts_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_login_history:{
			where:{
				type:"voyce_user_login_history_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_login_history_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_quest_ids:{
			where:{
				type:"voyce_user_quest_ids_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_quest_ids_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_quest_rewards:{
			where:{
				type:"voyce_user_quest_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_quest_rewards_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_quests:{
			where:{
				type:"voyce_user_quests_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_quests_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_quests_old:{
			where:{
				type:"voyce_user_quests_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_quests_old_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_ranking:{
			where:{
				type:"voyce_user_ranking_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_ranking_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_reviews:{
			where:{
				type:"voyce_user_reviews_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_reviews_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_rewards:{
			where:{
				type:"voyce_user_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_rewards_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_roles:{
			where:{
				type:"voyce_user_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_roles_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_settings:{
			where:{
				type:"voyce_user_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_settings_by_pk:{
			user_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_skills:{
			where:{
				type:"voyce_user_skills_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_skills_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_socials:{
			where:{
				type:"voyce_user_socials_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_socials_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_soft_deleted:{
			where:{
				type:"voyce_user_soft_deleted_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_soft_deleted_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_vtags:{
			where:{
				type:"voyce_user_vtags_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_vtags_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_wins:{
			where:{
				type:"voyce_user_wins_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_user_wins_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_users:{
			where:{
				type:"voyce_users_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_users_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_users_followers:{
			where:{
				type:"voyce_users_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_users_followers_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_users_private:{
			where:{
				type:"voyce_users_private_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_users_series_junction:{
			where:{
				type:"voyce_users_series_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_users_series_junction_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_versions:{
			where:{
				type:"voyce_versions_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_versions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_voting_analytics:{
			where:{
				type:"voyce_voting_analytics_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_voting_analytics_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_voting_panels:{
			where:{
				type:"voyce_voting_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_voting_panels_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_voting_series:{
			where:{
				type:"voyce_voting_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_voting_series_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_voting_user_votes:{
			where:{
				type:"voyce_voting_user_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_voyce_voting_user_votes_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		insert_awsdms_validation_failures_v1:{
			objects:{
				type:"awsdms_validation_failures_v1_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		insert_awsdms_validation_failures_v1_one:{
			object:{
				type:"awsdms_validation_failures_v1_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		insert_coinsystem_balances:{
			objects:{
				type:"coinsystem_balances_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"coinsystem_balances_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_coinsystem_balances_one:{
			object:{
				type:"coinsystem_balances_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"coinsystem_balances_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_coinsystem_bonus_coins:{
			objects:{
				type:"coinsystem_bonus_coins_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"coinsystem_bonus_coins_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_coinsystem_bonus_coins_one:{
			object:{
				type:"coinsystem_bonus_coins_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"coinsystem_bonus_coins_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_coinsystem_coins:{
			objects:{
				type:"coinsystem_coins_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"coinsystem_coins_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_coinsystem_coins_one:{
			object:{
				type:"coinsystem_coins_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"coinsystem_coins_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_coinsystem_exchange_rates:{
			objects:{
				type:"coinsystem_exchange_rates_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"coinsystem_exchange_rates_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_coinsystem_exchange_rates_one:{
			object:{
				type:"coinsystem_exchange_rates_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"coinsystem_exchange_rates_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_coinsystem_logs:{
			objects:{
				type:"coinsystem_logs_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"coinsystem_logs_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_coinsystem_logs_one:{
			object:{
				type:"coinsystem_logs_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"coinsystem_logs_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_coinsystem_transactions:{
			objects:{
				type:"coinsystem_transactions_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"coinsystem_transactions_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_coinsystem_transactions_one:{
			object:{
				type:"coinsystem_transactions_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"coinsystem_transactions_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_coinsystem_wallets:{
			objects:{
				type:"coinsystem_wallets_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"coinsystem_wallets_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_coinsystem_wallets_one:{
			object:{
				type:"coinsystem_wallets_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"coinsystem_wallets_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_mysql_user:{
			objects:{
				type:"mysql_user_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"mysql_user_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_mysql_user_one:{
			object:{
				type:"mysql_user_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"mysql_user_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_store_payments:{
			objects:{
				type:"store_payments_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"store_payments_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_store_payments_one:{
			object:{
				type:"store_payments_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"store_payments_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_store_products:{
			objects:{
				type:"store_products_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"store_products_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_store_products_one:{
			object:{
				type:"store_products_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"store_products_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_store_purchases:{
			objects:{
				type:"store_purchases_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"store_purchases_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_store_purchases_one:{
			object:{
				type:"store_purchases_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"store_purchases_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_store_users:{
			objects:{
				type:"store_users_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		insert_store_users_one:{
			object:{
				type:"store_users_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		insert_voyce_admin_roles:{
			objects:{
				type:"voyce_admin_roles_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_admin_roles_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_admin_roles_one:{
			object:{
				type:"voyce_admin_roles_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_admin_roles_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_app_banners:{
			objects:{
				type:"voyce_app_banners_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_app_banners_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_app_banners_one:{
			object:{
				type:"voyce_app_banners_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_app_banners_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_app_components:{
			objects:{
				type:"voyce_app_components_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_app_components_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_app_components_one:{
			object:{
				type:"voyce_app_components_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_app_components_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_audit_trail:{
			objects:{
				type:"voyce_audit_trail_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_audit_trail_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_audit_trail_one:{
			object:{
				type:"voyce_audit_trail_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_audit_trail_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_author_profiles:{
			objects:{
				type:"voyce_author_profiles_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_author_profiles_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_author_profiles_one:{
			object:{
				type:"voyce_author_profiles_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_author_profiles_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_balances:{
			objects:{
				type:"voyce_balances_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_balances_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_balances_one:{
			object:{
				type:"voyce_balances_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_balances_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_banners:{
			objects:{
				type:"voyce_banners_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_banners_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_banners_one:{
			object:{
				type:"voyce_banners_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_banners_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_assets:{
			objects:{
				type:"voyce_chapter_assets_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_assets_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_assets_one:{
			object:{
				type:"voyce_chapter_assets_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_assets_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_bookmarks:{
			objects:{
				type:"voyce_chapter_bookmarks_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_bookmarks_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_bookmarks_one:{
			object:{
				type:"voyce_chapter_bookmarks_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_bookmarks_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_comments:{
			objects:{
				type:"voyce_chapter_comments_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_comments_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_comments_07252024:{
			objects:{
				type:"voyce_chapter_comments_07252024_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		insert_voyce_chapter_comments_07252024_one:{
			object:{
				type:"voyce_chapter_comments_07252024_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		insert_voyce_chapter_comments_one:{
			object:{
				type:"voyce_chapter_comments_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_comments_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_comments_reaction:{
			objects:{
				type:"voyce_chapter_comments_reaction_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_comments_reaction_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_comments_reaction_count:{
			objects:{
				type:"voyce_chapter_comments_reaction_count_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_comments_reaction_count_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_comments_reaction_count_one:{
			object:{
				type:"voyce_chapter_comments_reaction_count_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_comments_reaction_count_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_comments_reaction_one:{
			object:{
				type:"voyce_chapter_comments_reaction_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_comments_reaction_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_comments_reports:{
			objects:{
				type:"voyce_chapter_comments_reports_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_comments_reports_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_comments_reports_one:{
			object:{
				type:"voyce_chapter_comments_reports_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_comments_reports_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_contents:{
			objects:{
				type:"voyce_chapter_contents_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_contents_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_contents_old:{
			objects:{
				type:"voyce_chapter_contents_old_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_contents_old_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_contents_old_one:{
			object:{
				type:"voyce_chapter_contents_old_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_contents_old_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_contents_one:{
			object:{
				type:"voyce_chapter_contents_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_contents_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_effects:{
			objects:{
				type:"voyce_chapter_effects_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_effects_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_effects_one:{
			object:{
				type:"voyce_chapter_effects_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_effects_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_images:{
			objects:{
				type:"voyce_chapter_images_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_images_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_images_07252024:{
			objects:{
				type:"voyce_chapter_images_07252024_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		insert_voyce_chapter_images_07252024_one:{
			object:{
				type:"voyce_chapter_images_07252024_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		insert_voyce_chapter_images_one:{
			object:{
				type:"voyce_chapter_images_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_images_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_likes:{
			objects:{
				type:"voyce_chapter_likes_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_likes_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_likes_one:{
			object:{
				type:"voyce_chapter_likes_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_likes_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_locks:{
			objects:{
				type:"voyce_chapter_locks_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_locks_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_locks_one:{
			object:{
				type:"voyce_chapter_locks_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_locks_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_panel_views:{
			objects:{
				type:"voyce_chapter_panel_views_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_panel_views_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_panel_views_one:{
			object:{
				type:"voyce_chapter_panel_views_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_panel_views_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_panels:{
			objects:{
				type:"voyce_chapter_panels_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_panels_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_panels_one:{
			object:{
				type:"voyce_chapter_panels_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_panels_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_publish_schedules:{
			objects:{
				type:"voyce_chapter_publish_schedules_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_publish_schedules_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_publish_schedules_one:{
			object:{
				type:"voyce_chapter_publish_schedules_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_publish_schedules_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_reactions:{
			objects:{
				type:"voyce_chapter_reactions_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_reactions_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_reactions_count:{
			objects:{
				type:"voyce_chapter_reactions_count_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_reactions_count_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_reactions_count_one:{
			object:{
				type:"voyce_chapter_reactions_count_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_reactions_count_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_reactions_one:{
			object:{
				type:"voyce_chapter_reactions_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_reactions_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_view_logs:{
			objects:{
				type:"voyce_chapter_view_logs_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_view_logs_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_view_logs_one:{
			object:{
				type:"voyce_chapter_view_logs_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_view_logs_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_views:{
			objects:{
				type:"voyce_chapter_views_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_views_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapter_views_one:{
			object:{
				type:"voyce_chapter_views_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapter_views_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapters:{
			objects:{
				type:"voyce_chapters_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_chapters_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_chapters_07252024:{
			objects:{
				type:"voyce_chapters_07252024_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		insert_voyce_chapters_07252024_one:{
			object:{
				type:"voyce_chapters_07252024_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		insert_voyce_chapters_one:{
			object:{
				type:"voyce_chapters_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_chapters_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_collaborator_roles:{
			objects:{
				type:"voyce_collaborator_roles_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_collaborator_roles_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_collaborator_roles_one:{
			object:{
				type:"voyce_collaborator_roles_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_collaborator_roles_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_comic_types:{
			objects:{
				type:"voyce_comic_types_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_comic_types_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_comic_types_one:{
			object:{
				type:"voyce_comic_types_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_comic_types_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_community_blurbs:{
			objects:{
				type:"voyce_community_blurbs_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_community_blurbs_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_community_blurbs_one:{
			object:{
				type:"voyce_community_blurbs_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_community_blurbs_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_contact_us:{
			objects:{
				type:"voyce_contact_us_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_contact_us_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_contact_us_one:{
			object:{
				type:"voyce_contact_us_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_contact_us_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_content_report:{
			objects:{
				type:"voyce_content_report_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_content_report_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_content_report_one:{
			object:{
				type:"voyce_content_report_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_content_report_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_contest_data:{
			objects:{
				type:"voyce_contest_data_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_contest_data_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_contest_data_one:{
			object:{
				type:"voyce_contest_data_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_contest_data_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_countries:{
			objects:{
				type:"voyce_countries_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_countries_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_countries_one:{
			object:{
				type:"voyce_countries_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_countries_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_csat_ratings:{
			objects:{
				type:"voyce_csat_ratings_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_csat_ratings_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_csat_ratings_data:{
			objects:{
				type:"voyce_csat_ratings_data_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_csat_ratings_data_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_csat_ratings_data_one:{
			object:{
				type:"voyce_csat_ratings_data_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_csat_ratings_data_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_csat_ratings_one:{
			object:{
				type:"voyce_csat_ratings_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_csat_ratings_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_education_types:{
			objects:{
				type:"voyce_education_types_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_education_types_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_education_types_one:{
			object:{
				type:"voyce_education_types_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_education_types_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_erp_login_roles:{
			objects:{
				type:"voyce_erp_login_roles_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_erp_login_roles_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_erp_login_roles_one:{
			object:{
				type:"voyce_erp_login_roles_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_erp_login_roles_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_etl_series_views_logs:{
			objects:{
				type:"voyce_etl_series_views_logs_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_etl_series_views_logs_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_etl_series_views_logs_one:{
			object:{
				type:"voyce_etl_series_views_logs_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_etl_series_views_logs_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_event_schedules:{
			objects:{
				type:"voyce_event_schedules_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_event_schedules_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_event_schedules_one:{
			object:{
				type:"voyce_event_schedules_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_event_schedules_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_genres:{
			objects:{
				type:"voyce_genres_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_genres_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_genres_one:{
			object:{
				type:"voyce_genres_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_genres_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_guest_gtags:{
			objects:{
				type:"voyce_guest_gtags_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_guest_gtags_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_guest_gtags_one:{
			object:{
				type:"voyce_guest_gtags_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_guest_gtags_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_metabases:{
			objects:{
				type:"voyce_metabases_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_metabases_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_metabases_access:{
			objects:{
				type:"voyce_metabases_access_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_metabases_access_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_metabases_access_one:{
			object:{
				type:"voyce_metabases_access_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_metabases_access_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_metabases_one:{
			object:{
				type:"voyce_metabases_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_metabases_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_newsletter:{
			objects:{
				type:"voyce_newsletter_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_newsletter_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_newsletter_one:{
			object:{
				type:"voyce_newsletter_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_newsletter_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_notification_series_settings:{
			objects:{
				type:"voyce_notification_series_settings_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_notification_series_settings_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_notification_series_settings_one:{
			object:{
				type:"voyce_notification_series_settings_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_notification_series_settings_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_notification_settings:{
			objects:{
				type:"voyce_notification_settings_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_notification_settings_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_notification_settings_one:{
			object:{
				type:"voyce_notification_settings_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_notification_settings_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_notifications:{
			objects:{
				type:"voyce_notifications_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_notifications_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_notifications_one:{
			object:{
				type:"voyce_notifications_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_notifications_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_page_banners:{
			objects:{
				type:"voyce_page_banners_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_page_banners_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_page_banners_one:{
			object:{
				type:"voyce_page_banners_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_page_banners_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_page_components:{
			objects:{
				type:"voyce_page_components_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_page_components_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_page_components_one:{
			object:{
				type:"voyce_page_components_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_page_components_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_pages:{
			objects:{
				type:"voyce_pages_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_pages_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_pages_one:{
			object:{
				type:"voyce_pages_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_pages_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_panels_comments:{
			objects:{
				type:"voyce_panels_comments_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_panels_comments_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_panels_comments_one:{
			object:{
				type:"voyce_panels_comments_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_panels_comments_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_panels_reactions:{
			objects:{
				type:"voyce_panels_reactions_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_panels_reactions_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_panels_reactions_count:{
			objects:{
				type:"voyce_panels_reactions_count_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_panels_reactions_count_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_panels_reactions_count_one:{
			object:{
				type:"voyce_panels_reactions_count_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_panels_reactions_count_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_panels_reactions_one:{
			object:{
				type:"voyce_panels_reactions_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_panels_reactions_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_payments_to_creators:{
			objects:{
				type:"voyce_payments_to_creators_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_payments_to_creators_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_payments_to_creators_one:{
			object:{
				type:"voyce_payments_to_creators_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_payments_to_creators_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_pg_rs_series_views_count:{
			objects:{
				type:"voyce_pg_rs_series_views_count_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_pg_rs_series_views_count_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_pg_rs_series_views_count_one:{
			object:{
				type:"voyce_pg_rs_series_views_count_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_pg_rs_series_views_count_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_polls:{
			objects:{
				type:"voyce_polls_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_polls_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_polls_answers:{
			objects:{
				type:"voyce_polls_answers_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_polls_answers_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_polls_answers_one:{
			object:{
				type:"voyce_polls_answers_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_polls_answers_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_polls_comments:{
			objects:{
				type:"voyce_polls_comments_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_polls_comments_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_polls_comments_one:{
			object:{
				type:"voyce_polls_comments_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_polls_comments_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_polls_one:{
			object:{
				type:"voyce_polls_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_polls_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_polls_reactions:{
			objects:{
				type:"voyce_polls_reactions_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_polls_reactions_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_polls_reactions_one:{
			object:{
				type:"voyce_polls_reactions_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_polls_reactions_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_polls_votes:{
			objects:{
				type:"voyce_polls_votes_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_polls_votes_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_polls_votes_one:{
			object:{
				type:"voyce_polls_votes_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_polls_votes_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_popup_components:{
			objects:{
				type:"voyce_popup_components_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_popup_components_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_popup_components_one:{
			object:{
				type:"voyce_popup_components_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_popup_components_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_popup_emails:{
			objects:{
				type:"voyce_popup_emails_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_popup_emails_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_popup_emails_one:{
			object:{
				type:"voyce_popup_emails_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_popup_emails_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_posts:{
			objects:{
				type:"voyce_posts_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_posts_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_posts_comments:{
			objects:{
				type:"voyce_posts_comments_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_posts_comments_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_posts_comments_one:{
			object:{
				type:"voyce_posts_comments_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_posts_comments_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_posts_one:{
			object:{
				type:"voyce_posts_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_posts_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_posts_reactions:{
			objects:{
				type:"voyce_posts_reactions_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_posts_reactions_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_posts_reactions_one:{
			object:{
				type:"voyce_posts_reactions_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_posts_reactions_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_quest_difficulties:{
			objects:{
				type:"voyce_quest_difficulties_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_quest_difficulties_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_quest_difficulties_one:{
			object:{
				type:"voyce_quest_difficulties_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_quest_difficulties_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_quests:{
			objects:{
				type:"voyce_quests_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_quests_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_quests_old:{
			objects:{
				type:"voyce_quests_old_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_quests_old_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_quests_old_one:{
			object:{
				type:"voyce_quests_old_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_quests_old_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_quests_one:{
			object:{
				type:"voyce_quests_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_quests_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_recommended_series:{
			objects:{
				type:"voyce_recommended_series_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_recommended_series_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_recommended_series_one:{
			object:{
				type:"voyce_recommended_series_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_recommended_series_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_release_schedules:{
			objects:{
				type:"voyce_release_schedules_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_release_schedules_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_release_schedules_one:{
			object:{
				type:"voyce_release_schedules_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_release_schedules_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_rewards:{
			objects:{
				type:"voyce_rewards_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_rewards_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_rewards_leaderboard:{
			objects:{
				type:"voyce_rewards_leaderboard_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_rewards_leaderboard_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_rewards_leaderboard_one:{
			object:{
				type:"voyce_rewards_leaderboard_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_rewards_leaderboard_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_rewards_one:{
			object:{
				type:"voyce_rewards_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_rewards_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_rewards_quest:{
			objects:{
				type:"voyce_rewards_quest_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_rewards_quest_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_rewards_quest_one:{
			object:{
				type:"voyce_rewards_quest_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_rewards_quest_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_sections:{
			objects:{
				type:"voyce_sections_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_sections_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_sections_one:{
			object:{
				type:"voyce_sections_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_sections_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series:{
			objects:{
				type:"voyce_series_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_collaborators:{
			objects:{
				type:"voyce_series_collaborators_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_collaborators_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_collaborators_one:{
			object:{
				type:"voyce_series_collaborators_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_collaborators_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_donations:{
			objects:{
				type:"voyce_series_donations_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_donations_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_donations_one:{
			object:{
				type:"voyce_series_donations_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_donations_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_favorites:{
			objects:{
				type:"voyce_series_favorites_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_favorites_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_favorites_one:{
			object:{
				type:"voyce_series_favorites_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_favorites_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_featured_panels:{
			objects:{
				type:"voyce_series_featured_panels_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_featured_panels_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_featured_panels_one:{
			object:{
				type:"voyce_series_featured_panels_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_featured_panels_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_genre:{
			objects:{
				type:"voyce_series_genre_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_genre_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_genre_one:{
			object:{
				type:"voyce_series_genre_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_genre_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_giveaway_participants:{
			objects:{
				type:"voyce_series_giveaway_participants_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_giveaway_participants_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_giveaway_participants_one:{
			object:{
				type:"voyce_series_giveaway_participants_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_giveaway_participants_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_hot_panels:{
			objects:{
				type:"voyce_series_hot_panels_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_hot_panels_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_hot_panels_one:{
			object:{
				type:"voyce_series_hot_panels_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_hot_panels_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_layouts:{
			objects:{
				type:"voyce_series_layouts_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_layouts_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_layouts_one:{
			object:{
				type:"voyce_series_layouts_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_layouts_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_leaderboard:{
			objects:{
				type:"voyce_series_leaderboard_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_leaderboard_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_leaderboard_one:{
			object:{
				type:"voyce_series_leaderboard_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_leaderboard_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_lock_config:{
			objects:{
				type:"voyce_series_lock_config_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_lock_config_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_lock_config_one:{
			object:{
				type:"voyce_series_lock_config_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_lock_config_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_one:{
			object:{
				type:"voyce_series_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_sponsorships_configuration:{
			objects:{
				type:"voyce_series_sponsorships_configuration_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_sponsorships_configuration_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_sponsorships_configuration_one:{
			object:{
				type:"voyce_series_sponsorships_configuration_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_sponsorships_configuration_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_subscription:{
			objects:{
				type:"voyce_series_subscription_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_subscription_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_subscription_one:{
			object:{
				type:"voyce_series_subscription_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_subscription_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_tags:{
			objects:{
				type:"voyce_series_tags_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_tags_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_tags_junction:{
			objects:{
				type:"voyce_series_tags_junction_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_tags_junction_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_tags_junction_one:{
			object:{
				type:"voyce_series_tags_junction_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_tags_junction_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_tags_one:{
			object:{
				type:"voyce_series_tags_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_tags_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_top_comments:{
			objects:{
				type:"voyce_series_top_comments_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_top_comments_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_top_comments_one:{
			object:{
				type:"voyce_series_top_comments_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_top_comments_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_top_likes:{
			objects:{
				type:"voyce_series_top_likes_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_top_likes_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_top_likes_one:{
			object:{
				type:"voyce_series_top_likes_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_top_likes_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_top_views:{
			objects:{
				type:"voyce_series_top_views_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_top_views_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_top_views_one:{
			object:{
				type:"voyce_series_top_views_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_top_views_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_views_adjustment:{
			objects:{
				type:"voyce_series_views_adjustment_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_views_adjustment_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_views_adjustment_one:{
			object:{
				type:"voyce_series_views_adjustment_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_views_adjustment_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_votes:{
			objects:{
				type:"voyce_series_votes_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_votes_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_votes_count:{
			objects:{
				type:"voyce_series_votes_count_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_votes_count_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_votes_count_one:{
			object:{
				type:"voyce_series_votes_count_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_votes_count_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_votes_one:{
			object:{
				type:"voyce_series_votes_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_votes_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_voting_schedules:{
			objects:{
				type:"voyce_series_voting_schedules_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_series_voting_schedules_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_series_voting_schedules_one:{
			object:{
				type:"voyce_series_voting_schedules_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_series_voting_schedules_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_sponsorships:{
			objects:{
				type:"voyce_sponsorships_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_sponsorships_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_sponsorships_one:{
			object:{
				type:"voyce_sponsorships_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_sponsorships_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_states:{
			objects:{
				type:"voyce_states_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_states_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_states_one:{
			object:{
				type:"voyce_states_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_states_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_stripe_payouts_setup:{
			objects:{
				type:"voyce_stripe_payouts_setup_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_stripe_payouts_setup_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_stripe_payouts_setup_one:{
			object:{
				type:"voyce_stripe_payouts_setup_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_stripe_payouts_setup_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_studio:{
			objects:{
				type:"voyce_studio_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_studio_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_studio_followers:{
			objects:{
				type:"voyce_studio_followers_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_studio_followers_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_studio_followers_one:{
			object:{
				type:"voyce_studio_followers_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_studio_followers_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_studio_one:{
			object:{
				type:"voyce_studio_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_studio_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_studio_series:{
			objects:{
				type:"voyce_studio_series_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_studio_series_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_studio_series_one:{
			object:{
				type:"voyce_studio_series_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_studio_series_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_studio_views:{
			objects:{
				type:"voyce_studio_views_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_studio_views_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_studio_views_one:{
			object:{
				type:"voyce_studio_views_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_studio_views_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_system_settings:{
			objects:{
				type:"voyce_system_settings_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_system_settings_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_system_settings_one:{
			object:{
				type:"voyce_system_settings_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_system_settings_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_transaction_logs:{
			objects:{
				type:"voyce_transaction_logs_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_transaction_logs_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_transaction_logs_one:{
			object:{
				type:"voyce_transaction_logs_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_transaction_logs_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_url_redirects:{
			objects:{
				type:"voyce_url_redirects_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_url_redirects_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_url_redirects_one:{
			object:{
				type:"voyce_url_redirects_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_url_redirects_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_addresses:{
			objects:{
				type:"voyce_user_addresses_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_addresses_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_addresses_one:{
			object:{
				type:"voyce_user_addresses_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_addresses_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_blocks:{
			objects:{
				type:"voyce_user_blocks_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_blocks_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_blocks_one:{
			object:{
				type:"voyce_user_blocks_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_blocks_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_chapters_unlocked:{
			objects:{
				type:"voyce_user_chapters_unlocked_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_chapters_unlocked_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_chapters_unlocked_one:{
			object:{
				type:"voyce_user_chapters_unlocked_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_chapters_unlocked_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_contacts:{
			objects:{
				type:"voyce_user_contacts_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_contacts_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_contacts_one:{
			object:{
				type:"voyce_user_contacts_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_contacts_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_csat_ratings:{
			objects:{
				type:"voyce_user_csat_ratings_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_csat_ratings_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_csat_ratings_one:{
			object:{
				type:"voyce_user_csat_ratings_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_csat_ratings_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_education:{
			objects:{
				type:"voyce_user_education_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_education_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_education_one:{
			object:{
				type:"voyce_user_education_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_education_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_level:{
			objects:{
				type:"voyce_user_level_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_level_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_level_one:{
			object:{
				type:"voyce_user_level_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_level_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_linked_accounts:{
			objects:{
				type:"voyce_user_linked_accounts_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_linked_accounts_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_linked_accounts_one:{
			object:{
				type:"voyce_user_linked_accounts_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_linked_accounts_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_login_history:{
			objects:{
				type:"voyce_user_login_history_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_login_history_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_login_history_one:{
			object:{
				type:"voyce_user_login_history_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_login_history_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_quest_ids:{
			objects:{
				type:"voyce_user_quest_ids_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_quest_ids_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_quest_ids_one:{
			object:{
				type:"voyce_user_quest_ids_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_quest_ids_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_quest_rewards:{
			objects:{
				type:"voyce_user_quest_rewards_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_quest_rewards_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_quest_rewards_one:{
			object:{
				type:"voyce_user_quest_rewards_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_quest_rewards_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_quests:{
			objects:{
				type:"voyce_user_quests_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_quests_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_quests_old:{
			objects:{
				type:"voyce_user_quests_old_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_quests_old_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_quests_old_one:{
			object:{
				type:"voyce_user_quests_old_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_quests_old_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_quests_one:{
			object:{
				type:"voyce_user_quests_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_quests_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_ranking:{
			objects:{
				type:"voyce_user_ranking_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_ranking_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_ranking_one:{
			object:{
				type:"voyce_user_ranking_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_ranking_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_reviews:{
			objects:{
				type:"voyce_user_reviews_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_reviews_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_reviews_one:{
			object:{
				type:"voyce_user_reviews_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_reviews_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_rewards:{
			objects:{
				type:"voyce_user_rewards_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_rewards_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_rewards_one:{
			object:{
				type:"voyce_user_rewards_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_rewards_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_roles:{
			objects:{
				type:"voyce_user_roles_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_roles_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_roles_one:{
			object:{
				type:"voyce_user_roles_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_roles_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_settings:{
			objects:{
				type:"voyce_user_settings_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_settings_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_settings_one:{
			object:{
				type:"voyce_user_settings_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_settings_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_skills:{
			objects:{
				type:"voyce_user_skills_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_skills_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_skills_one:{
			object:{
				type:"voyce_user_skills_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_skills_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_socials:{
			objects:{
				type:"voyce_user_socials_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_socials_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_socials_one:{
			object:{
				type:"voyce_user_socials_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_socials_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_soft_deleted:{
			objects:{
				type:"voyce_user_soft_deleted_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_soft_deleted_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_soft_deleted_one:{
			object:{
				type:"voyce_user_soft_deleted_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_soft_deleted_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_vtags:{
			objects:{
				type:"voyce_user_vtags_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_vtags_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_vtags_one:{
			object:{
				type:"voyce_user_vtags_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_vtags_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_wins:{
			objects:{
				type:"voyce_user_wins_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_user_wins_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_user_wins_one:{
			object:{
				type:"voyce_user_wins_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_user_wins_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_users:{
			objects:{
				type:"voyce_users_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_users_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_users_followers:{
			objects:{
				type:"voyce_users_followers_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_users_followers_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_users_followers_one:{
			object:{
				type:"voyce_users_followers_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_users_followers_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_users_one:{
			object:{
				type:"voyce_users_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_users_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_users_private:{
			objects:{
				type:"voyce_users_private_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		insert_voyce_users_private_one:{
			object:{
				type:"voyce_users_private_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		insert_voyce_users_series_junction:{
			objects:{
				type:"voyce_users_series_junction_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_users_series_junction_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_users_series_junction_one:{
			object:{
				type:"voyce_users_series_junction_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_users_series_junction_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_versions:{
			objects:{
				type:"voyce_versions_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_versions_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_versions_one:{
			object:{
				type:"voyce_versions_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_versions_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_voting_analytics:{
			objects:{
				type:"voyce_voting_analytics_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_voting_analytics_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_voting_analytics_one:{
			object:{
				type:"voyce_voting_analytics_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_voting_analytics_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_voting_panels:{
			objects:{
				type:"voyce_voting_panels_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_voting_panels_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_voting_panels_one:{
			object:{
				type:"voyce_voting_panels_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_voting_panels_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_voting_series:{
			objects:{
				type:"voyce_voting_series_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_voting_series_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_voting_series_one:{
			object:{
				type:"voyce_voting_series_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_voting_series_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_voting_user_votes:{
			objects:{
				type:"voyce_voting_user_votes_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"voyce_voting_user_votes_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_voyce_voting_user_votes_one:{
			object:{
				type:"voyce_voting_user_votes_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"voyce_voting_user_votes_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		update_awsdms_validation_failures_v1:{
			_set:{
				type:"awsdms_validation_failures_v1_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"awsdms_validation_failures_v1_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_awsdms_validation_failures_v1_many:{
			updates:{
				type:"awsdms_validation_failures_v1_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_coinsystem_balances:{
			_inc:{
				type:"coinsystem_balances_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"coinsystem_balances_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"coinsystem_balances_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_coinsystem_balances_by_pk:{
			_inc:{
				type:"coinsystem_balances_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"coinsystem_balances_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"coinsystem_balances_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_coinsystem_balances_many:{
			updates:{
				type:"coinsystem_balances_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_coinsystem_bonus_coins:{
			_inc:{
				type:"coinsystem_bonus_coins_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"coinsystem_bonus_coins_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"coinsystem_bonus_coins_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_coinsystem_bonus_coins_by_pk:{
			_inc:{
				type:"coinsystem_bonus_coins_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"coinsystem_bonus_coins_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"coinsystem_bonus_coins_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_coinsystem_bonus_coins_many:{
			updates:{
				type:"coinsystem_bonus_coins_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_coinsystem_coins:{
			_inc:{
				type:"coinsystem_coins_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"coinsystem_coins_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"coinsystem_coins_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_coinsystem_coins_by_pk:{
			_inc:{
				type:"coinsystem_coins_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"coinsystem_coins_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"coinsystem_coins_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_coinsystem_coins_many:{
			updates:{
				type:"coinsystem_coins_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_coinsystem_exchange_rates:{
			_inc:{
				type:"coinsystem_exchange_rates_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"coinsystem_exchange_rates_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"coinsystem_exchange_rates_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_coinsystem_exchange_rates_by_pk:{
			_inc:{
				type:"coinsystem_exchange_rates_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"coinsystem_exchange_rates_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"coinsystem_exchange_rates_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_coinsystem_exchange_rates_many:{
			updates:{
				type:"coinsystem_exchange_rates_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_coinsystem_logs:{
			_inc:{
				type:"coinsystem_logs_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"coinsystem_logs_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"coinsystem_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_coinsystem_logs_by_pk:{
			_inc:{
				type:"coinsystem_logs_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"coinsystem_logs_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"coinsystem_logs_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_coinsystem_logs_many:{
			updates:{
				type:"coinsystem_logs_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_coinsystem_transactions:{
			_inc:{
				type:"coinsystem_transactions_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"coinsystem_transactions_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"coinsystem_transactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_coinsystem_transactions_by_pk:{
			_inc:{
				type:"coinsystem_transactions_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"coinsystem_transactions_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"coinsystem_transactions_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_coinsystem_transactions_many:{
			updates:{
				type:"coinsystem_transactions_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_coinsystem_wallets:{
			_inc:{
				type:"coinsystem_wallets_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"coinsystem_wallets_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"coinsystem_wallets_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_coinsystem_wallets_by_pk:{
			_inc:{
				type:"coinsystem_wallets_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"coinsystem_wallets_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"coinsystem_wallets_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_coinsystem_wallets_many:{
			updates:{
				type:"coinsystem_wallets_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_mysql_user:{
			_inc:{
				type:"mysql_user_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"mysql_user_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"mysql_user_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_mysql_user_by_pk:{
			_inc:{
				type:"mysql_user_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"mysql_user_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"mysql_user_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_mysql_user_many:{
			updates:{
				type:"mysql_user_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_store_payments:{
			_inc:{
				type:"store_payments_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"store_payments_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"store_payments_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_store_payments_by_pk:{
			_inc:{
				type:"store_payments_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"store_payments_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"store_payments_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_store_payments_many:{
			updates:{
				type:"store_payments_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_store_products:{
			_inc:{
				type:"store_products_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"store_products_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"store_products_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_store_products_by_pk:{
			_inc:{
				type:"store_products_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"store_products_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"store_products_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_store_products_many:{
			updates:{
				type:"store_products_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_store_purchases:{
			_inc:{
				type:"store_purchases_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"store_purchases_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"store_purchases_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_store_purchases_by_pk:{
			_inc:{
				type:"store_purchases_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"store_purchases_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"store_purchases_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_store_purchases_many:{
			updates:{
				type:"store_purchases_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_store_users:{
			_inc:{
				type:"store_users_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"store_users_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"store_users_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_store_users_many:{
			updates:{
				type:"store_users_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_admin_roles:{
			_inc:{
				type:"voyce_admin_roles_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_admin_roles_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_admin_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_admin_roles_by_pk:{
			_inc:{
				type:"voyce_admin_roles_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_admin_roles_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_admin_roles_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_admin_roles_many:{
			updates:{
				type:"voyce_admin_roles_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_app_banners:{
			_inc:{
				type:"voyce_app_banners_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_app_banners_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_app_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_app_banners_by_pk:{
			_inc:{
				type:"voyce_app_banners_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_app_banners_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_app_banners_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_app_banners_many:{
			updates:{
				type:"voyce_app_banners_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_app_components:{
			_append:{
				type:"voyce_app_components_append_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_at_path:{
				type:"voyce_app_components_delete_at_path_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_elem:{
				type:"voyce_app_components_delete_elem_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_key:{
				type:"voyce_app_components_delete_key_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_inc:{
				type:"voyce_app_components_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_prepend:{
				type:"voyce_app_components_prepend_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_app_components_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_app_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_app_components_by_pk:{
			_append:{
				type:"voyce_app_components_append_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_at_path:{
				type:"voyce_app_components_delete_at_path_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_elem:{
				type:"voyce_app_components_delete_elem_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_key:{
				type:"voyce_app_components_delete_key_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_inc:{
				type:"voyce_app_components_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_prepend:{
				type:"voyce_app_components_prepend_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_app_components_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_app_components_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_app_components_many:{
			updates:{
				type:"voyce_app_components_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_audit_trail:{
			_inc:{
				type:"voyce_audit_trail_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_audit_trail_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_audit_trail_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_audit_trail_by_pk:{
			_inc:{
				type:"voyce_audit_trail_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_audit_trail_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_audit_trail_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_audit_trail_many:{
			updates:{
				type:"voyce_audit_trail_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_author_profiles:{
			_inc:{
				type:"voyce_author_profiles_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_author_profiles_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_author_profiles_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_author_profiles_by_pk:{
			_inc:{
				type:"voyce_author_profiles_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_author_profiles_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_author_profiles_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_author_profiles_many:{
			updates:{
				type:"voyce_author_profiles_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_balances:{
			_inc:{
				type:"voyce_balances_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_balances_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_balances_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_balances_by_pk:{
			_inc:{
				type:"voyce_balances_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_balances_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_balances_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_balances_many:{
			updates:{
				type:"voyce_balances_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_banners:{
			_inc:{
				type:"voyce_banners_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_banners_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_banners_by_pk:{
			_inc:{
				type:"voyce_banners_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_banners_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_banners_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_banners_many:{
			updates:{
				type:"voyce_banners_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_assets:{
			_inc:{
				type:"voyce_chapter_assets_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_assets_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_assets_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_assets_by_pk:{
			_inc:{
				type:"voyce_chapter_assets_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_assets_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_assets_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_assets_many:{
			updates:{
				type:"voyce_chapter_assets_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_bookmarks:{
			_inc:{
				type:"voyce_chapter_bookmarks_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_bookmarks_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_bookmarks_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_bookmarks_by_pk:{
			_inc:{
				type:"voyce_chapter_bookmarks_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_bookmarks_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_bookmarks_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_bookmarks_many:{
			updates:{
				type:"voyce_chapter_bookmarks_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_comments:{
			_inc:{
				type:"voyce_chapter_comments_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_comments_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_comments_07252024:{
			_inc:{
				type:"voyce_chapter_comments_07252024_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_comments_07252024_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_comments_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_comments_07252024_many:{
			updates:{
				type:"voyce_chapter_comments_07252024_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_comments_by_pk:{
			_inc:{
				type:"voyce_chapter_comments_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_comments_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_comments_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_comments_many:{
			updates:{
				type:"voyce_chapter_comments_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_comments_reaction:{
			_inc:{
				type:"voyce_chapter_comments_reaction_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_comments_reaction_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_comments_reaction_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_comments_reaction_by_pk:{
			_inc:{
				type:"voyce_chapter_comments_reaction_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_comments_reaction_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_comments_reaction_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_comments_reaction_count:{
			_inc:{
				type:"voyce_chapter_comments_reaction_count_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_comments_reaction_count_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_comments_reaction_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_comments_reaction_count_by_pk:{
			_inc:{
				type:"voyce_chapter_comments_reaction_count_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_comments_reaction_count_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_comments_reaction_count_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_comments_reaction_count_many:{
			updates:{
				type:"voyce_chapter_comments_reaction_count_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_comments_reaction_many:{
			updates:{
				type:"voyce_chapter_comments_reaction_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_comments_reports:{
			_inc:{
				type:"voyce_chapter_comments_reports_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_comments_reports_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_comments_reports_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_comments_reports_by_pk:{
			_inc:{
				type:"voyce_chapter_comments_reports_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_comments_reports_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_comments_reports_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_comments_reports_many:{
			updates:{
				type:"voyce_chapter_comments_reports_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_contents:{
			_inc:{
				type:"voyce_chapter_contents_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_contents_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_contents_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_contents_by_pk:{
			_inc:{
				type:"voyce_chapter_contents_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_contents_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_contents_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_contents_many:{
			updates:{
				type:"voyce_chapter_contents_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_contents_old:{
			_inc:{
				type:"voyce_chapter_contents_old_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_contents_old_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_contents_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_contents_old_by_pk:{
			_inc:{
				type:"voyce_chapter_contents_old_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_contents_old_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_contents_old_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_contents_old_many:{
			updates:{
				type:"voyce_chapter_contents_old_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_effects:{
			_inc:{
				type:"voyce_chapter_effects_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_effects_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_effects_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_effects_by_pk:{
			_inc:{
				type:"voyce_chapter_effects_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_effects_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_effects_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_effects_many:{
			updates:{
				type:"voyce_chapter_effects_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_images:{
			_inc:{
				type:"voyce_chapter_images_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_images_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_images_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_images_07252024:{
			_inc:{
				type:"voyce_chapter_images_07252024_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_images_07252024_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_images_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_images_07252024_many:{
			updates:{
				type:"voyce_chapter_images_07252024_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_images_by_pk:{
			_inc:{
				type:"voyce_chapter_images_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_images_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_images_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_images_many:{
			updates:{
				type:"voyce_chapter_images_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_likes:{
			_inc:{
				type:"voyce_chapter_likes_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_likes_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_likes_by_pk:{
			_inc:{
				type:"voyce_chapter_likes_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_likes_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_likes_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_likes_many:{
			updates:{
				type:"voyce_chapter_likes_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_locks:{
			_inc:{
				type:"voyce_chapter_locks_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_locks_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_locks_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_locks_by_pk:{
			_inc:{
				type:"voyce_chapter_locks_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_locks_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_locks_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_locks_many:{
			updates:{
				type:"voyce_chapter_locks_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_panel_views:{
			_inc:{
				type:"voyce_chapter_panel_views_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_panel_views_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_panel_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_panel_views_by_pk:{
			_inc:{
				type:"voyce_chapter_panel_views_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_panel_views_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_panel_views_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_panel_views_many:{
			updates:{
				type:"voyce_chapter_panel_views_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_panels:{
			_inc:{
				type:"voyce_chapter_panels_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_panels_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_panels_by_pk:{
			_inc:{
				type:"voyce_chapter_panels_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_panels_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_panels_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_panels_many:{
			updates:{
				type:"voyce_chapter_panels_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_publish_schedules:{
			_inc:{
				type:"voyce_chapter_publish_schedules_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_publish_schedules_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_publish_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_publish_schedules_by_pk:{
			_inc:{
				type:"voyce_chapter_publish_schedules_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_publish_schedules_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_publish_schedules_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_publish_schedules_many:{
			updates:{
				type:"voyce_chapter_publish_schedules_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_reactions:{
			_inc:{
				type:"voyce_chapter_reactions_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_reactions_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_reactions_by_pk:{
			_inc:{
				type:"voyce_chapter_reactions_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_reactions_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_reactions_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_reactions_count:{
			_inc:{
				type:"voyce_chapter_reactions_count_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_reactions_count_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_reactions_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_reactions_count_by_pk:{
			_inc:{
				type:"voyce_chapter_reactions_count_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_reactions_count_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_reactions_count_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_reactions_count_many:{
			updates:{
				type:"voyce_chapter_reactions_count_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_reactions_many:{
			updates:{
				type:"voyce_chapter_reactions_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_view_logs:{
			_inc:{
				type:"voyce_chapter_view_logs_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_view_logs_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_view_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_view_logs_by_pk:{
			_inc:{
				type:"voyce_chapter_view_logs_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_view_logs_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_view_logs_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_view_logs_many:{
			updates:{
				type:"voyce_chapter_view_logs_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapter_views:{
			_inc:{
				type:"voyce_chapter_views_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_views_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapter_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_views_by_pk:{
			_inc:{
				type:"voyce_chapter_views_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapter_views_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapter_views_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapter_views_many:{
			updates:{
				type:"voyce_chapter_views_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapters:{
			_inc:{
				type:"voyce_chapters_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapters_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapters_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapters_07252024:{
			_inc:{
				type:"voyce_chapters_07252024_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapters_07252024_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_chapters_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapters_07252024_many:{
			updates:{
				type:"voyce_chapters_07252024_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_chapters_by_pk:{
			_inc:{
				type:"voyce_chapters_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_chapters_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_chapters_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_chapters_many:{
			updates:{
				type:"voyce_chapters_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_collaborator_roles:{
			_inc:{
				type:"voyce_collaborator_roles_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_collaborator_roles_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_collaborator_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_collaborator_roles_by_pk:{
			_inc:{
				type:"voyce_collaborator_roles_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_collaborator_roles_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_collaborator_roles_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_collaborator_roles_many:{
			updates:{
				type:"voyce_collaborator_roles_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_comic_types:{
			_inc:{
				type:"voyce_comic_types_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_comic_types_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_comic_types_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_comic_types_by_pk:{
			_inc:{
				type:"voyce_comic_types_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_comic_types_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_comic_types_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_comic_types_many:{
			updates:{
				type:"voyce_comic_types_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_community_blurbs:{
			_inc:{
				type:"voyce_community_blurbs_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_community_blurbs_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_community_blurbs_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_community_blurbs_by_pk:{
			_inc:{
				type:"voyce_community_blurbs_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_community_blurbs_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_community_blurbs_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_community_blurbs_many:{
			updates:{
				type:"voyce_community_blurbs_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_contact_us:{
			_inc:{
				type:"voyce_contact_us_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_contact_us_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_contact_us_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_contact_us_by_pk:{
			_inc:{
				type:"voyce_contact_us_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_contact_us_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_contact_us_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_contact_us_many:{
			updates:{
				type:"voyce_contact_us_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_content_report:{
			_inc:{
				type:"voyce_content_report_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_content_report_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_content_report_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_content_report_by_pk:{
			_inc:{
				type:"voyce_content_report_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_content_report_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_content_report_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_content_report_many:{
			updates:{
				type:"voyce_content_report_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_contest_data:{
			_inc:{
				type:"voyce_contest_data_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_contest_data_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_contest_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_contest_data_by_pk:{
			_inc:{
				type:"voyce_contest_data_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_contest_data_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_contest_data_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_contest_data_many:{
			updates:{
				type:"voyce_contest_data_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_countries:{
			_inc:{
				type:"voyce_countries_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_countries_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_countries_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_countries_by_pk:{
			_inc:{
				type:"voyce_countries_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_countries_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_countries_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_countries_many:{
			updates:{
				type:"voyce_countries_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_csat_ratings:{
			_inc:{
				type:"voyce_csat_ratings_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_csat_ratings_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_csat_ratings_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_csat_ratings_by_pk:{
			_inc:{
				type:"voyce_csat_ratings_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_csat_ratings_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_csat_ratings_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_csat_ratings_data:{
			_inc:{
				type:"voyce_csat_ratings_data_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_csat_ratings_data_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_csat_ratings_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_csat_ratings_data_by_pk:{
			_inc:{
				type:"voyce_csat_ratings_data_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_csat_ratings_data_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_csat_ratings_data_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_csat_ratings_data_many:{
			updates:{
				type:"voyce_csat_ratings_data_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_csat_ratings_many:{
			updates:{
				type:"voyce_csat_ratings_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_education_types:{
			_inc:{
				type:"voyce_education_types_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_education_types_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_education_types_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_education_types_by_pk:{
			_inc:{
				type:"voyce_education_types_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_education_types_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_education_types_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_education_types_many:{
			updates:{
				type:"voyce_education_types_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_erp_login_roles:{
			_inc:{
				type:"voyce_erp_login_roles_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_erp_login_roles_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_erp_login_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_erp_login_roles_by_pk:{
			_inc:{
				type:"voyce_erp_login_roles_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_erp_login_roles_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_erp_login_roles_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_erp_login_roles_many:{
			updates:{
				type:"voyce_erp_login_roles_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_etl_series_views_logs:{
			_inc:{
				type:"voyce_etl_series_views_logs_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_etl_series_views_logs_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_etl_series_views_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_etl_series_views_logs_by_pk:{
			_inc:{
				type:"voyce_etl_series_views_logs_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_etl_series_views_logs_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_etl_series_views_logs_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_etl_series_views_logs_many:{
			updates:{
				type:"voyce_etl_series_views_logs_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_event_schedules:{
			_inc:{
				type:"voyce_event_schedules_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_event_schedules_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_event_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_event_schedules_by_pk:{
			_inc:{
				type:"voyce_event_schedules_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_event_schedules_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_event_schedules_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_event_schedules_many:{
			updates:{
				type:"voyce_event_schedules_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_genres:{
			_inc:{
				type:"voyce_genres_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_genres_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_genres_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_genres_by_pk:{
			_inc:{
				type:"voyce_genres_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_genres_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_genres_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_genres_many:{
			updates:{
				type:"voyce_genres_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_guest_gtags:{
			_inc:{
				type:"voyce_guest_gtags_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_guest_gtags_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_guest_gtags_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_guest_gtags_by_pk:{
			_inc:{
				type:"voyce_guest_gtags_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_guest_gtags_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_guest_gtags_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_guest_gtags_many:{
			updates:{
				type:"voyce_guest_gtags_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_metabases:{
			_inc:{
				type:"voyce_metabases_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_metabases_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_metabases_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_metabases_access:{
			_inc:{
				type:"voyce_metabases_access_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_metabases_access_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_metabases_access_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_metabases_access_by_pk:{
			_inc:{
				type:"voyce_metabases_access_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_metabases_access_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_metabases_access_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_metabases_access_many:{
			updates:{
				type:"voyce_metabases_access_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_metabases_by_pk:{
			_inc:{
				type:"voyce_metabases_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_metabases_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_metabases_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_metabases_many:{
			updates:{
				type:"voyce_metabases_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_newsletter:{
			_inc:{
				type:"voyce_newsletter_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_newsletter_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_newsletter_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_newsletter_by_pk:{
			_inc:{
				type:"voyce_newsletter_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_newsletter_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_newsletter_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_newsletter_many:{
			updates:{
				type:"voyce_newsletter_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_notification_series_settings:{
			_inc:{
				type:"voyce_notification_series_settings_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_notification_series_settings_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_notification_series_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_notification_series_settings_by_pk:{
			_inc:{
				type:"voyce_notification_series_settings_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_notification_series_settings_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_notification_series_settings_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_notification_series_settings_many:{
			updates:{
				type:"voyce_notification_series_settings_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_notification_settings:{
			_inc:{
				type:"voyce_notification_settings_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_notification_settings_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_notification_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_notification_settings_by_pk:{
			_inc:{
				type:"voyce_notification_settings_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_notification_settings_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_notification_settings_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_notification_settings_many:{
			updates:{
				type:"voyce_notification_settings_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_notifications:{
			_append:{
				type:"voyce_notifications_append_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_at_path:{
				type:"voyce_notifications_delete_at_path_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_elem:{
				type:"voyce_notifications_delete_elem_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_key:{
				type:"voyce_notifications_delete_key_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_inc:{
				type:"voyce_notifications_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_prepend:{
				type:"voyce_notifications_prepend_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_notifications_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_notifications_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_notifications_by_pk:{
			_append:{
				type:"voyce_notifications_append_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_at_path:{
				type:"voyce_notifications_delete_at_path_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_elem:{
				type:"voyce_notifications_delete_elem_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_key:{
				type:"voyce_notifications_delete_key_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_inc:{
				type:"voyce_notifications_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_prepend:{
				type:"voyce_notifications_prepend_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_notifications_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_notifications_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_notifications_many:{
			updates:{
				type:"voyce_notifications_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_page_banners:{
			_inc:{
				type:"voyce_page_banners_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_page_banners_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_page_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_page_banners_by_pk:{
			_inc:{
				type:"voyce_page_banners_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_page_banners_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_page_banners_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_page_banners_many:{
			updates:{
				type:"voyce_page_banners_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_page_components:{
			_inc:{
				type:"voyce_page_components_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_page_components_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_page_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_page_components_by_pk:{
			_inc:{
				type:"voyce_page_components_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_page_components_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_page_components_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_page_components_many:{
			updates:{
				type:"voyce_page_components_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_pages:{
			_inc:{
				type:"voyce_pages_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_pages_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_pages_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_pages_by_pk:{
			_inc:{
				type:"voyce_pages_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_pages_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_pages_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_pages_many:{
			updates:{
				type:"voyce_pages_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_panels_comments:{
			_inc:{
				type:"voyce_panels_comments_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_panels_comments_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_panels_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_panels_comments_by_pk:{
			_inc:{
				type:"voyce_panels_comments_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_panels_comments_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_panels_comments_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_panels_comments_many:{
			updates:{
				type:"voyce_panels_comments_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_panels_reactions:{
			_inc:{
				type:"voyce_panels_reactions_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_panels_reactions_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_panels_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_panels_reactions_by_pk:{
			_inc:{
				type:"voyce_panels_reactions_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_panels_reactions_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_panels_reactions_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_panels_reactions_count:{
			_inc:{
				type:"voyce_panels_reactions_count_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_panels_reactions_count_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_panels_reactions_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_panels_reactions_count_by_pk:{
			_inc:{
				type:"voyce_panels_reactions_count_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_panels_reactions_count_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_panels_reactions_count_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_panels_reactions_count_many:{
			updates:{
				type:"voyce_panels_reactions_count_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_panels_reactions_many:{
			updates:{
				type:"voyce_panels_reactions_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_payments_to_creators:{
			_inc:{
				type:"voyce_payments_to_creators_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_payments_to_creators_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_payments_to_creators_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_payments_to_creators_by_pk:{
			_inc:{
				type:"voyce_payments_to_creators_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_payments_to_creators_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_payments_to_creators_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_payments_to_creators_many:{
			updates:{
				type:"voyce_payments_to_creators_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_pg_rs_series_views_count:{
			_inc:{
				type:"voyce_pg_rs_series_views_count_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_pg_rs_series_views_count_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_pg_rs_series_views_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_pg_rs_series_views_count_by_pk:{
			_inc:{
				type:"voyce_pg_rs_series_views_count_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_pg_rs_series_views_count_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_pg_rs_series_views_count_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_pg_rs_series_views_count_many:{
			updates:{
				type:"voyce_pg_rs_series_views_count_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_polls:{
			_inc:{
				type:"voyce_polls_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_polls_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_polls_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_polls_answers:{
			_inc:{
				type:"voyce_polls_answers_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_polls_answers_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_polls_answers_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_polls_answers_by_pk:{
			_inc:{
				type:"voyce_polls_answers_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_polls_answers_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_polls_answers_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_polls_answers_many:{
			updates:{
				type:"voyce_polls_answers_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_polls_by_pk:{
			_inc:{
				type:"voyce_polls_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_polls_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_polls_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_polls_comments:{
			_inc:{
				type:"voyce_polls_comments_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_polls_comments_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_polls_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_polls_comments_by_pk:{
			_inc:{
				type:"voyce_polls_comments_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_polls_comments_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_polls_comments_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_polls_comments_many:{
			updates:{
				type:"voyce_polls_comments_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_polls_many:{
			updates:{
				type:"voyce_polls_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_polls_reactions:{
			_inc:{
				type:"voyce_polls_reactions_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_polls_reactions_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_polls_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_polls_reactions_by_pk:{
			_inc:{
				type:"voyce_polls_reactions_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_polls_reactions_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_polls_reactions_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_polls_reactions_many:{
			updates:{
				type:"voyce_polls_reactions_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_polls_votes:{
			_inc:{
				type:"voyce_polls_votes_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_polls_votes_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_polls_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_polls_votes_by_pk:{
			_inc:{
				type:"voyce_polls_votes_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_polls_votes_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_polls_votes_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_polls_votes_many:{
			updates:{
				type:"voyce_polls_votes_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_popup_components:{
			_inc:{
				type:"voyce_popup_components_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_popup_components_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_popup_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_popup_components_by_pk:{
			_inc:{
				type:"voyce_popup_components_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_popup_components_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_popup_components_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_popup_components_many:{
			updates:{
				type:"voyce_popup_components_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_popup_emails:{
			_inc:{
				type:"voyce_popup_emails_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_popup_emails_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_popup_emails_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_popup_emails_by_pk:{
			_inc:{
				type:"voyce_popup_emails_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_popup_emails_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_popup_emails_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_popup_emails_many:{
			updates:{
				type:"voyce_popup_emails_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_posts:{
			_inc:{
				type:"voyce_posts_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_posts_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_posts_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_posts_by_pk:{
			_inc:{
				type:"voyce_posts_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_posts_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_posts_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_posts_comments:{
			_inc:{
				type:"voyce_posts_comments_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_posts_comments_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_posts_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_posts_comments_by_pk:{
			_inc:{
				type:"voyce_posts_comments_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_posts_comments_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_posts_comments_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_posts_comments_many:{
			updates:{
				type:"voyce_posts_comments_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_posts_many:{
			updates:{
				type:"voyce_posts_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_posts_reactions:{
			_inc:{
				type:"voyce_posts_reactions_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_posts_reactions_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_posts_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_posts_reactions_by_pk:{
			_inc:{
				type:"voyce_posts_reactions_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_posts_reactions_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_posts_reactions_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_posts_reactions_many:{
			updates:{
				type:"voyce_posts_reactions_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_quest_difficulties:{
			_inc:{
				type:"voyce_quest_difficulties_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_quest_difficulties_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_quest_difficulties_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_quest_difficulties_by_pk:{
			_inc:{
				type:"voyce_quest_difficulties_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_quest_difficulties_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_quest_difficulties_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_quest_difficulties_many:{
			updates:{
				type:"voyce_quest_difficulties_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_quests:{
			_inc:{
				type:"voyce_quests_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_quests_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_quests_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_quests_by_pk:{
			_inc:{
				type:"voyce_quests_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_quests_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_quests_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_quests_many:{
			updates:{
				type:"voyce_quests_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_quests_old:{
			_inc:{
				type:"voyce_quests_old_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_quests_old_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_quests_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_quests_old_by_pk:{
			_inc:{
				type:"voyce_quests_old_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_quests_old_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_quests_old_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_quests_old_many:{
			updates:{
				type:"voyce_quests_old_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_recommended_series:{
			_inc:{
				type:"voyce_recommended_series_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_recommended_series_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_recommended_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_recommended_series_by_pk:{
			_inc:{
				type:"voyce_recommended_series_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_recommended_series_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_recommended_series_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_recommended_series_many:{
			updates:{
				type:"voyce_recommended_series_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_release_schedules:{
			_inc:{
				type:"voyce_release_schedules_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_release_schedules_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_release_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_release_schedules_by_pk:{
			_inc:{
				type:"voyce_release_schedules_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_release_schedules_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_release_schedules_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_release_schedules_many:{
			updates:{
				type:"voyce_release_schedules_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_rewards:{
			_inc:{
				type:"voyce_rewards_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_rewards_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_rewards_by_pk:{
			_inc:{
				type:"voyce_rewards_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_rewards_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_rewards_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_rewards_leaderboard:{
			_inc:{
				type:"voyce_rewards_leaderboard_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_rewards_leaderboard_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_rewards_leaderboard_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_rewards_leaderboard_by_pk:{
			_inc:{
				type:"voyce_rewards_leaderboard_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_rewards_leaderboard_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_rewards_leaderboard_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_rewards_leaderboard_many:{
			updates:{
				type:"voyce_rewards_leaderboard_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_rewards_many:{
			updates:{
				type:"voyce_rewards_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_rewards_quest:{
			_inc:{
				type:"voyce_rewards_quest_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_rewards_quest_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_rewards_quest_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_rewards_quest_by_pk:{
			_inc:{
				type:"voyce_rewards_quest_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_rewards_quest_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_rewards_quest_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_rewards_quest_many:{
			updates:{
				type:"voyce_rewards_quest_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_sections:{
			_inc:{
				type:"voyce_sections_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_sections_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_sections_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_sections_by_pk:{
			_inc:{
				type:"voyce_sections_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_sections_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_sections_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_sections_many:{
			updates:{
				type:"voyce_sections_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series:{
			_inc:{
				type:"voyce_series_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_by_pk:{
			_inc:{
				type:"voyce_series_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_collaborators:{
			_inc:{
				type:"voyce_series_collaborators_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_collaborators_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_collaborators_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_collaborators_by_pk:{
			_inc:{
				type:"voyce_series_collaborators_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_collaborators_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_collaborators_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_collaborators_many:{
			updates:{
				type:"voyce_series_collaborators_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_donations:{
			_inc:{
				type:"voyce_series_donations_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_donations_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_donations_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_donations_by_pk:{
			_inc:{
				type:"voyce_series_donations_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_donations_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_donations_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_donations_many:{
			updates:{
				type:"voyce_series_donations_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_favorites:{
			_inc:{
				type:"voyce_series_favorites_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_favorites_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_favorites_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_favorites_by_pk:{
			_inc:{
				type:"voyce_series_favorites_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_favorites_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_favorites_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_favorites_many:{
			updates:{
				type:"voyce_series_favorites_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_featured_panels:{
			_inc:{
				type:"voyce_series_featured_panels_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_featured_panels_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_featured_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_featured_panels_by_pk:{
			_inc:{
				type:"voyce_series_featured_panels_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_featured_panels_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_featured_panels_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_featured_panels_many:{
			updates:{
				type:"voyce_series_featured_panels_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_genre:{
			_inc:{
				type:"voyce_series_genre_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_genre_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_genre_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_genre_by_pk:{
			_inc:{
				type:"voyce_series_genre_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_genre_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_genre_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_genre_many:{
			updates:{
				type:"voyce_series_genre_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_giveaway_participants:{
			_inc:{
				type:"voyce_series_giveaway_participants_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_giveaway_participants_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_giveaway_participants_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_giveaway_participants_by_pk:{
			_inc:{
				type:"voyce_series_giveaway_participants_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_giveaway_participants_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_giveaway_participants_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_giveaway_participants_many:{
			updates:{
				type:"voyce_series_giveaway_participants_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_hot_panels:{
			_inc:{
				type:"voyce_series_hot_panels_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_hot_panels_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_hot_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_hot_panels_by_pk:{
			_inc:{
				type:"voyce_series_hot_panels_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_hot_panels_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_hot_panels_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_hot_panels_many:{
			updates:{
				type:"voyce_series_hot_panels_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_layouts:{
			_inc:{
				type:"voyce_series_layouts_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_layouts_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_layouts_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_layouts_by_pk:{
			_inc:{
				type:"voyce_series_layouts_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_layouts_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_layouts_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_layouts_many:{
			updates:{
				type:"voyce_series_layouts_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_leaderboard:{
			_inc:{
				type:"voyce_series_leaderboard_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_leaderboard_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_leaderboard_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_leaderboard_by_pk:{
			_inc:{
				type:"voyce_series_leaderboard_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_leaderboard_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_leaderboard_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_leaderboard_many:{
			updates:{
				type:"voyce_series_leaderboard_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_lock_config:{
			_inc:{
				type:"voyce_series_lock_config_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_lock_config_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_lock_config_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_lock_config_by_pk:{
			_inc:{
				type:"voyce_series_lock_config_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_lock_config_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_lock_config_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_lock_config_many:{
			updates:{
				type:"voyce_series_lock_config_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_many:{
			updates:{
				type:"voyce_series_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_sponsorships_configuration:{
			_inc:{
				type:"voyce_series_sponsorships_configuration_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_sponsorships_configuration_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_sponsorships_configuration_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_sponsorships_configuration_by_pk:{
			_inc:{
				type:"voyce_series_sponsorships_configuration_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_sponsorships_configuration_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_sponsorships_configuration_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_sponsorships_configuration_many:{
			updates:{
				type:"voyce_series_sponsorships_configuration_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_subscription:{
			_inc:{
				type:"voyce_series_subscription_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_subscription_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_subscription_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_subscription_by_pk:{
			_inc:{
				type:"voyce_series_subscription_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_subscription_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_subscription_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_subscription_many:{
			updates:{
				type:"voyce_series_subscription_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_tags:{
			_inc:{
				type:"voyce_series_tags_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_tags_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_tags_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_tags_by_pk:{
			_inc:{
				type:"voyce_series_tags_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_tags_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_tags_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_tags_junction:{
			_append:{
				type:"voyce_series_tags_junction_append_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_at_path:{
				type:"voyce_series_tags_junction_delete_at_path_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_elem:{
				type:"voyce_series_tags_junction_delete_elem_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_key:{
				type:"voyce_series_tags_junction_delete_key_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_inc:{
				type:"voyce_series_tags_junction_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_prepend:{
				type:"voyce_series_tags_junction_prepend_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_tags_junction_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_tags_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_tags_junction_by_pk:{
			_append:{
				type:"voyce_series_tags_junction_append_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_at_path:{
				type:"voyce_series_tags_junction_delete_at_path_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_elem:{
				type:"voyce_series_tags_junction_delete_elem_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_key:{
				type:"voyce_series_tags_junction_delete_key_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_inc:{
				type:"voyce_series_tags_junction_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_prepend:{
				type:"voyce_series_tags_junction_prepend_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_tags_junction_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_tags_junction_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_tags_junction_many:{
			updates:{
				type:"voyce_series_tags_junction_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_tags_many:{
			updates:{
				type:"voyce_series_tags_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_top_comments:{
			_inc:{
				type:"voyce_series_top_comments_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_top_comments_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_top_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_top_comments_by_pk:{
			_inc:{
				type:"voyce_series_top_comments_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_top_comments_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_top_comments_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_top_comments_many:{
			updates:{
				type:"voyce_series_top_comments_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_top_likes:{
			_inc:{
				type:"voyce_series_top_likes_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_top_likes_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_top_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_top_likes_by_pk:{
			_inc:{
				type:"voyce_series_top_likes_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_top_likes_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_top_likes_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_top_likes_many:{
			updates:{
				type:"voyce_series_top_likes_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_top_views:{
			_inc:{
				type:"voyce_series_top_views_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_top_views_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_top_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_top_views_by_pk:{
			_inc:{
				type:"voyce_series_top_views_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_top_views_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_top_views_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_top_views_many:{
			updates:{
				type:"voyce_series_top_views_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_views_adjustment:{
			_inc:{
				type:"voyce_series_views_adjustment_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_views_adjustment_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_views_adjustment_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_views_adjustment_by_pk:{
			_inc:{
				type:"voyce_series_views_adjustment_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_views_adjustment_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_views_adjustment_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_views_adjustment_many:{
			updates:{
				type:"voyce_series_views_adjustment_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_votes:{
			_inc:{
				type:"voyce_series_votes_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_votes_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_votes_by_pk:{
			_inc:{
				type:"voyce_series_votes_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_votes_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_votes_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_votes_count:{
			_inc:{
				type:"voyce_series_votes_count_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_votes_count_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_votes_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_votes_count_by_pk:{
			_inc:{
				type:"voyce_series_votes_count_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_votes_count_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_votes_count_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_votes_count_many:{
			updates:{
				type:"voyce_series_votes_count_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_votes_many:{
			updates:{
				type:"voyce_series_votes_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_series_voting_schedules:{
			_inc:{
				type:"voyce_series_voting_schedules_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_voting_schedules_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_series_voting_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_voting_schedules_by_pk:{
			_inc:{
				type:"voyce_series_voting_schedules_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_series_voting_schedules_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_series_voting_schedules_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_series_voting_schedules_many:{
			updates:{
				type:"voyce_series_voting_schedules_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_sponsorships:{
			_inc:{
				type:"voyce_sponsorships_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_sponsorships_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_sponsorships_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_sponsorships_by_pk:{
			_inc:{
				type:"voyce_sponsorships_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_sponsorships_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_sponsorships_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_sponsorships_many:{
			updates:{
				type:"voyce_sponsorships_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_states:{
			_inc:{
				type:"voyce_states_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_states_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_states_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_states_by_pk:{
			_inc:{
				type:"voyce_states_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_states_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_states_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_states_many:{
			updates:{
				type:"voyce_states_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_stripe_payouts_setup:{
			_inc:{
				type:"voyce_stripe_payouts_setup_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_stripe_payouts_setup_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_stripe_payouts_setup_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_stripe_payouts_setup_by_pk:{
			_inc:{
				type:"voyce_stripe_payouts_setup_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_stripe_payouts_setup_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_stripe_payouts_setup_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_stripe_payouts_setup_many:{
			updates:{
				type:"voyce_stripe_payouts_setup_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_studio:{
			_inc:{
				type:"voyce_studio_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_studio_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_studio_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_studio_by_pk:{
			_inc:{
				type:"voyce_studio_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_studio_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_studio_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_studio_followers:{
			_inc:{
				type:"voyce_studio_followers_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_studio_followers_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_studio_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_studio_followers_by_pk:{
			_inc:{
				type:"voyce_studio_followers_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_studio_followers_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_studio_followers_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_studio_followers_many:{
			updates:{
				type:"voyce_studio_followers_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_studio_many:{
			updates:{
				type:"voyce_studio_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_studio_series:{
			_inc:{
				type:"voyce_studio_series_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_studio_series_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_studio_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_studio_series_by_pk:{
			_inc:{
				type:"voyce_studio_series_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_studio_series_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_studio_series_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_studio_series_many:{
			updates:{
				type:"voyce_studio_series_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_studio_views:{
			_inc:{
				type:"voyce_studio_views_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_studio_views_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_studio_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_studio_views_by_pk:{
			_inc:{
				type:"voyce_studio_views_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_studio_views_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_studio_views_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_studio_views_many:{
			updates:{
				type:"voyce_studio_views_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_system_settings:{
			_inc:{
				type:"voyce_system_settings_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_system_settings_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_system_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_system_settings_by_pk:{
			_inc:{
				type:"voyce_system_settings_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_system_settings_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_system_settings_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_system_settings_many:{
			updates:{
				type:"voyce_system_settings_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_transaction_logs:{
			_inc:{
				type:"voyce_transaction_logs_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_transaction_logs_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_transaction_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_transaction_logs_by_pk:{
			_inc:{
				type:"voyce_transaction_logs_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_transaction_logs_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_transaction_logs_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_transaction_logs_many:{
			updates:{
				type:"voyce_transaction_logs_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_url_redirects:{
			_inc:{
				type:"voyce_url_redirects_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_url_redirects_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_url_redirects_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_url_redirects_by_pk:{
			_inc:{
				type:"voyce_url_redirects_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_url_redirects_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_url_redirects_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_url_redirects_many:{
			updates:{
				type:"voyce_url_redirects_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_addresses:{
			_inc:{
				type:"voyce_user_addresses_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_addresses_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_addresses_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_addresses_by_pk:{
			_inc:{
				type:"voyce_user_addresses_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_addresses_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_addresses_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_addresses_many:{
			updates:{
				type:"voyce_user_addresses_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_blocks:{
			_inc:{
				type:"voyce_user_blocks_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_blocks_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_blocks_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_blocks_by_pk:{
			_inc:{
				type:"voyce_user_blocks_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_blocks_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_blocks_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_blocks_many:{
			updates:{
				type:"voyce_user_blocks_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_chapters_unlocked:{
			_inc:{
				type:"voyce_user_chapters_unlocked_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_chapters_unlocked_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_chapters_unlocked_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_chapters_unlocked_by_pk:{
			_inc:{
				type:"voyce_user_chapters_unlocked_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_chapters_unlocked_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_chapters_unlocked_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_chapters_unlocked_many:{
			updates:{
				type:"voyce_user_chapters_unlocked_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_contacts:{
			_inc:{
				type:"voyce_user_contacts_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_contacts_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_contacts_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_contacts_by_pk:{
			_inc:{
				type:"voyce_user_contacts_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_contacts_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_contacts_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_contacts_many:{
			updates:{
				type:"voyce_user_contacts_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_csat_ratings:{
			_inc:{
				type:"voyce_user_csat_ratings_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_csat_ratings_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_csat_ratings_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_csat_ratings_by_pk:{
			_inc:{
				type:"voyce_user_csat_ratings_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_csat_ratings_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_csat_ratings_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_csat_ratings_many:{
			updates:{
				type:"voyce_user_csat_ratings_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_education:{
			_inc:{
				type:"voyce_user_education_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_education_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_education_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_education_by_pk:{
			_inc:{
				type:"voyce_user_education_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_education_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_education_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_education_many:{
			updates:{
				type:"voyce_user_education_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_level:{
			_inc:{
				type:"voyce_user_level_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_level_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_level_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_level_by_pk:{
			_inc:{
				type:"voyce_user_level_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_level_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_level_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_level_many:{
			updates:{
				type:"voyce_user_level_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_linked_accounts:{
			_inc:{
				type:"voyce_user_linked_accounts_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_linked_accounts_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_linked_accounts_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_linked_accounts_by_pk:{
			_inc:{
				type:"voyce_user_linked_accounts_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_linked_accounts_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_linked_accounts_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_linked_accounts_many:{
			updates:{
				type:"voyce_user_linked_accounts_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_login_history:{
			_inc:{
				type:"voyce_user_login_history_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_login_history_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_login_history_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_login_history_by_pk:{
			_inc:{
				type:"voyce_user_login_history_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_login_history_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_login_history_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_login_history_many:{
			updates:{
				type:"voyce_user_login_history_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_quest_ids:{
			_inc:{
				type:"voyce_user_quest_ids_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_quest_ids_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_quest_ids_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_quest_ids_by_pk:{
			_inc:{
				type:"voyce_user_quest_ids_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_quest_ids_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_quest_ids_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_quest_ids_many:{
			updates:{
				type:"voyce_user_quest_ids_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_quest_rewards:{
			_inc:{
				type:"voyce_user_quest_rewards_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_quest_rewards_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_quest_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_quest_rewards_by_pk:{
			_inc:{
				type:"voyce_user_quest_rewards_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_quest_rewards_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_quest_rewards_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_quest_rewards_many:{
			updates:{
				type:"voyce_user_quest_rewards_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_quests:{
			_inc:{
				type:"voyce_user_quests_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_quests_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_quests_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_quests_by_pk:{
			_inc:{
				type:"voyce_user_quests_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_quests_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_quests_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_quests_many:{
			updates:{
				type:"voyce_user_quests_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_quests_old:{
			_inc:{
				type:"voyce_user_quests_old_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_quests_old_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_quests_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_quests_old_by_pk:{
			_inc:{
				type:"voyce_user_quests_old_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_quests_old_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_quests_old_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_quests_old_many:{
			updates:{
				type:"voyce_user_quests_old_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_ranking:{
			_inc:{
				type:"voyce_user_ranking_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_ranking_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_ranking_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_ranking_by_pk:{
			_inc:{
				type:"voyce_user_ranking_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_ranking_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_ranking_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_ranking_many:{
			updates:{
				type:"voyce_user_ranking_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_reviews:{
			_inc:{
				type:"voyce_user_reviews_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_reviews_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_reviews_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_reviews_by_pk:{
			_inc:{
				type:"voyce_user_reviews_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_reviews_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_reviews_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_reviews_many:{
			updates:{
				type:"voyce_user_reviews_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_rewards:{
			_inc:{
				type:"voyce_user_rewards_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_rewards_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_rewards_by_pk:{
			_inc:{
				type:"voyce_user_rewards_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_rewards_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_rewards_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_rewards_many:{
			updates:{
				type:"voyce_user_rewards_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_roles:{
			_inc:{
				type:"voyce_user_roles_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_roles_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_roles_by_pk:{
			_inc:{
				type:"voyce_user_roles_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_roles_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_roles_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_roles_many:{
			updates:{
				type:"voyce_user_roles_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_settings:{
			_inc:{
				type:"voyce_user_settings_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_settings_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_settings_by_pk:{
			_inc:{
				type:"voyce_user_settings_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_settings_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_settings_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_settings_many:{
			updates:{
				type:"voyce_user_settings_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_skills:{
			_inc:{
				type:"voyce_user_skills_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_skills_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_skills_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_skills_by_pk:{
			_inc:{
				type:"voyce_user_skills_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_skills_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_skills_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_skills_many:{
			updates:{
				type:"voyce_user_skills_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_socials:{
			_inc:{
				type:"voyce_user_socials_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_socials_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_socials_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_socials_by_pk:{
			_inc:{
				type:"voyce_user_socials_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_socials_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_socials_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_socials_many:{
			updates:{
				type:"voyce_user_socials_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_soft_deleted:{
			_inc:{
				type:"voyce_user_soft_deleted_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_soft_deleted_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_soft_deleted_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_soft_deleted_by_pk:{
			_inc:{
				type:"voyce_user_soft_deleted_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_soft_deleted_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_soft_deleted_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_soft_deleted_many:{
			updates:{
				type:"voyce_user_soft_deleted_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_vtags:{
			_inc:{
				type:"voyce_user_vtags_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_vtags_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_vtags_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_vtags_by_pk:{
			_inc:{
				type:"voyce_user_vtags_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_vtags_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_vtags_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_vtags_many:{
			updates:{
				type:"voyce_user_vtags_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_user_wins:{
			_inc:{
				type:"voyce_user_wins_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_wins_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_user_wins_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_wins_by_pk:{
			_inc:{
				type:"voyce_user_wins_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_user_wins_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_user_wins_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_user_wins_many:{
			updates:{
				type:"voyce_user_wins_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_users:{
			_append:{
				type:"voyce_users_append_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_at_path:{
				type:"voyce_users_delete_at_path_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_elem:{
				type:"voyce_users_delete_elem_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_key:{
				type:"voyce_users_delete_key_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_inc:{
				type:"voyce_users_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_prepend:{
				type:"voyce_users_prepend_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_users_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_users_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_users_by_pk:{
			_append:{
				type:"voyce_users_append_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_at_path:{
				type:"voyce_users_delete_at_path_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_elem:{
				type:"voyce_users_delete_elem_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_key:{
				type:"voyce_users_delete_key_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_inc:{
				type:"voyce_users_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_prepend:{
				type:"voyce_users_prepend_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_users_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_users_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_users_followers:{
			_inc:{
				type:"voyce_users_followers_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_users_followers_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_users_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_users_followers_by_pk:{
			_inc:{
				type:"voyce_users_followers_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_users_followers_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_users_followers_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_users_followers_many:{
			updates:{
				type:"voyce_users_followers_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_users_many:{
			updates:{
				type:"voyce_users_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_users_private:{
			_inc:{
				type:"voyce_users_private_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_users_private_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_users_private_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_users_private_many:{
			updates:{
				type:"voyce_users_private_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_users_series_junction:{
			_inc:{
				type:"voyce_users_series_junction_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_users_series_junction_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_users_series_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_users_series_junction_by_pk:{
			_inc:{
				type:"voyce_users_series_junction_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_users_series_junction_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_users_series_junction_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_users_series_junction_many:{
			updates:{
				type:"voyce_users_series_junction_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_versions:{
			_inc:{
				type:"voyce_versions_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_versions_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_versions_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_versions_by_pk:{
			_inc:{
				type:"voyce_versions_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_versions_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_versions_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_versions_many:{
			updates:{
				type:"voyce_versions_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_voting_analytics:{
			_inc:{
				type:"voyce_voting_analytics_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_voting_analytics_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_voting_analytics_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_voting_analytics_by_pk:{
			_inc:{
				type:"voyce_voting_analytics_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_voting_analytics_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_voting_analytics_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_voting_analytics_many:{
			updates:{
				type:"voyce_voting_analytics_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_voting_panels:{
			_inc:{
				type:"voyce_voting_panels_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_voting_panels_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_voting_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_voting_panels_by_pk:{
			_inc:{
				type:"voyce_voting_panels_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_voting_panels_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_voting_panels_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_voting_panels_many:{
			updates:{
				type:"voyce_voting_panels_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_voting_series:{
			_inc:{
				type:"voyce_voting_series_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_voting_series_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_voting_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_voting_series_by_pk:{
			_inc:{
				type:"voyce_voting_series_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_voting_series_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_voting_series_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_voting_series_many:{
			updates:{
				type:"voyce_voting_series_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		update_voyce_voting_user_votes:{
			_inc:{
				type:"voyce_voting_user_votes_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_voting_user_votes_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"voyce_voting_user_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_voting_user_votes_by_pk:{
			_inc:{
				type:"voyce_voting_user_votes_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"voyce_voting_user_votes_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"voyce_voting_user_votes_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_voyce_voting_user_votes_many:{
			updates:{
				type:"voyce_voting_user_votes_updates",
				array:true,
				arrayRequired:true,
				required:true
			}
		}
	},
	mysql_user_aggregate_fields:{
		count:{
			columns:{
				type:"mysql_user_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	mysql_user_bool_exp:{
		_and:{
			type:"mysql_user_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"mysql_user_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"mysql_user_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		account_locked:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		alter_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		alter_routine_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		authentication_string:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_role_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_routine_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_tablespace_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_tmp_table_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_user_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_view_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		drop_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		drop_role_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		execute_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		file_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		grant_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		host:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		index_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		insert_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_tables_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_connections:{
			type:"bigint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_questions:{
			type:"bigint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_updates:{
			type:"bigint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_user_connections:{
			type:"bigint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_expired:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_last_changed:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_lifetime:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_require_current:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_reuse_history:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_reuse_time:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		plugin:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		process_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		references_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reload_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		repl_client_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		repl_slave_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		select_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		show_db_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		show_view_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		shutdown_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ssl_cipher:{
			type:"bytea_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ssl_type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		super_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		trigger_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		update_priv:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_attributes:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		x509_issuer:{
			type:"bytea_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		x509_subject:{
			type:"bytea_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	mysql_user_constraint: "enum",
	mysql_user_inc_input:{
		max_connections:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_questions:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_updates:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_user_connections:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_lifetime:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_reuse_history:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_reuse_time:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	mysql_user_insert_input:{
		account_locked:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alter_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alter_routine_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		authentication_string:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_role_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_routine_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_tablespace_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_tmp_table_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_user_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_view_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		drop_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		drop_role_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		execute_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		file_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		grant_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		host:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		index_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		insert_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_tables_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_connections:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_questions:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_updates:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_user_connections:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_expired:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_last_changed:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_lifetime:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_require_current:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_reuse_history:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_reuse_time:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		plugin:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		process_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		references_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reload_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		repl_client_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		repl_slave_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		select_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		show_db_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		show_view_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shutdown_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ssl_cipher:{
			type:"bytea",
			array:false,
			arrayRequired:false,
			required:false
		},
		ssl_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		super_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		trigger_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		update_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_attributes:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		x509_issuer:{
			type:"bytea",
			array:false,
			arrayRequired:false,
			required:false
		},
		x509_subject:{
			type:"bytea",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	mysql_user_on_conflict:{
		constraint:{
			type:"mysql_user_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"mysql_user_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"mysql_user_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	mysql_user_order_by:{
		account_locked:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		alter_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		alter_routine_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		authentication_string:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_role_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_routine_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_tablespace_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_tmp_table_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_user_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_view_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		drop_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		drop_role_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		execute_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		file_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		grant_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		host:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		index_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		insert_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_tables_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_connections:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_questions:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_updates:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_user_connections:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_expired:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_last_changed:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_lifetime:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_require_current:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_reuse_history:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_reuse_time:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		plugin:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		process_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		references_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reload_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		repl_client_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		repl_slave_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		select_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		show_db_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		show_view_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		shutdown_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ssl_cipher:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ssl_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		super_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		trigger_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		update_priv:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_attributes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		x509_issuer:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		x509_subject:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	mysql_user_pk_columns_input:{
		host:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		user:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	mysql_user_select_column: "enum",
	mysql_user_set_input:{
		account_locked:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alter_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alter_routine_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		authentication_string:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_role_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_routine_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_tablespace_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_tmp_table_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_user_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_view_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		drop_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		drop_role_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		execute_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		file_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		grant_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		host:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		index_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		insert_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_tables_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_connections:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_questions:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_updates:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_user_connections:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_expired:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_last_changed:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_lifetime:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_require_current:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_reuse_history:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_reuse_time:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		plugin:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		process_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		references_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reload_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		repl_client_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		repl_slave_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		select_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		show_db_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		show_view_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shutdown_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ssl_cipher:{
			type:"bytea",
			array:false,
			arrayRequired:false,
			required:false
		},
		ssl_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		super_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		trigger_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		update_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_attributes:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		x509_issuer:{
			type:"bytea",
			array:false,
			arrayRequired:false,
			required:false
		},
		x509_subject:{
			type:"bytea",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	mysql_user_stream_cursor_input:{
		initial_value:{
			type:"mysql_user_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	mysql_user_stream_cursor_value_input:{
		account_locked:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alter_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alter_routine_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		authentication_string:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_role_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_routine_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_tablespace_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_tmp_table_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_user_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		create_view_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		drop_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		drop_role_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		execute_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		file_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		grant_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		host:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		index_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		insert_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_tables_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_connections:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_questions:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_updates:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		max_user_connections:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_expired:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_last_changed:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_lifetime:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_require_current:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_reuse_history:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_reuse_time:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		plugin:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		process_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		references_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reload_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		repl_client_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		repl_slave_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		select_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		show_db_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		show_view_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shutdown_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ssl_cipher:{
			type:"bytea",
			array:false,
			arrayRequired:false,
			required:false
		},
		ssl_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		super_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		trigger_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		update_priv:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_attributes:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		x509_issuer:{
			type:"bytea",
			array:false,
			arrayRequired:false,
			required:false
		},
		x509_subject:{
			type:"bytea",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	mysql_user_update_column: "enum",
	mysql_user_updates:{
		_inc:{
			type:"mysql_user_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"mysql_user_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"mysql_user_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	numeric: "String",
	numeric_comparison_exp:{
		_eq:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"numeric",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"numeric",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	order_by: "enum",
	query_root:{
		awsdms_validation_failures_v1:{
			distinct_on:{
				type:"awsdms_validation_failures_v1_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"awsdms_validation_failures_v1_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"awsdms_validation_failures_v1_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		awsdms_validation_failures_v1_aggregate:{
			distinct_on:{
				type:"awsdms_validation_failures_v1_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"awsdms_validation_failures_v1_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"awsdms_validation_failures_v1_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_balances:{
			distinct_on:{
				type:"coinsystem_balances_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_balances_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_balances_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_balances_aggregate:{
			distinct_on:{
				type:"coinsystem_balances_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_balances_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_balances_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_balances_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		coinsystem_bonus_coins:{
			distinct_on:{
				type:"coinsystem_bonus_coins_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_bonus_coins_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_bonus_coins_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_bonus_coins_aggregate:{
			distinct_on:{
				type:"coinsystem_bonus_coins_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_bonus_coins_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_bonus_coins_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_bonus_coins_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		coinsystem_coins:{
			distinct_on:{
				type:"coinsystem_coins_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_coins_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_coins_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_coins_aggregate:{
			distinct_on:{
				type:"coinsystem_coins_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_coins_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_coins_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_coins_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		coinsystem_exchange_rates:{
			distinct_on:{
				type:"coinsystem_exchange_rates_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_exchange_rates_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_exchange_rates_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_exchange_rates_aggregate:{
			distinct_on:{
				type:"coinsystem_exchange_rates_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_exchange_rates_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_exchange_rates_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_exchange_rates_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		coinsystem_logs:{
			distinct_on:{
				type:"coinsystem_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_logs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_logs_aggregate:{
			distinct_on:{
				type:"coinsystem_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_logs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_logs_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		coinsystem_transactions:{
			distinct_on:{
				type:"coinsystem_transactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_transactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_transactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_transactions_aggregate:{
			distinct_on:{
				type:"coinsystem_transactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_transactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_transactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_transactions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		coinsystem_wallets:{
			distinct_on:{
				type:"coinsystem_wallets_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_wallets_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_wallets_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_wallets_aggregate:{
			distinct_on:{
				type:"coinsystem_wallets_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_wallets_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_wallets_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_wallets_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		mysql_user:{
			distinct_on:{
				type:"mysql_user_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"mysql_user_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"mysql_user_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		mysql_user_aggregate:{
			distinct_on:{
				type:"mysql_user_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"mysql_user_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"mysql_user_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		mysql_user_by_pk:{
			host:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			user:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		store_payments:{
			distinct_on:{
				type:"store_payments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"store_payments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_payments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_payments_aggregate:{
			distinct_on:{
				type:"store_payments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"store_payments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_payments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_payments_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			},
			purchase_id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		store_products:{
			distinct_on:{
				type:"store_products_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"store_products_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_products_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_products_aggregate:{
			distinct_on:{
				type:"store_products_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"store_products_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_products_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_products_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		store_purchases:{
			distinct_on:{
				type:"store_purchases_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"store_purchases_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_purchases_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_purchases_aggregate:{
			distinct_on:{
				type:"store_purchases_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"store_purchases_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_purchases_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_purchases_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		store_users:{
			distinct_on:{
				type:"store_users_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"store_users_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_users_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_users_aggregate:{
			distinct_on:{
				type:"store_users_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"store_users_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_users_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		test:{
			numbers:{
				type:"Int",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		voyce_admin_roles:{
			distinct_on:{
				type:"voyce_admin_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_admin_roles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_admin_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_admin_roles_aggregate:{
			distinct_on:{
				type:"voyce_admin_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_admin_roles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_admin_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_admin_roles_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_app_banners:{
			distinct_on:{
				type:"voyce_app_banners_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_app_banners_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_app_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_app_banners_aggregate:{
			distinct_on:{
				type:"voyce_app_banners_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_app_banners_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_app_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_app_banners_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_app_components:{
			distinct_on:{
				type:"voyce_app_components_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_app_components_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_app_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_app_components_aggregate:{
			distinct_on:{
				type:"voyce_app_components_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_app_components_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_app_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_app_components_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_audit_trail:{
			distinct_on:{
				type:"voyce_audit_trail_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_audit_trail_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_audit_trail_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_audit_trail_aggregate:{
			distinct_on:{
				type:"voyce_audit_trail_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_audit_trail_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_audit_trail_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_audit_trail_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_author_profiles:{
			distinct_on:{
				type:"voyce_author_profiles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_author_profiles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_author_profiles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_author_profiles_aggregate:{
			distinct_on:{
				type:"voyce_author_profiles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_author_profiles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_author_profiles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_author_profiles_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_balances:{
			distinct_on:{
				type:"voyce_balances_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_balances_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_balances_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_balances_aggregate:{
			distinct_on:{
				type:"voyce_balances_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_balances_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_balances_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_balances_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_banners:{
			distinct_on:{
				type:"voyce_banners_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_banners_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_banners_aggregate:{
			distinct_on:{
				type:"voyce_banners_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_banners_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_banners_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_assets:{
			distinct_on:{
				type:"voyce_chapter_assets_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_assets_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_assets_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_assets_aggregate:{
			distinct_on:{
				type:"voyce_chapter_assets_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_assets_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_assets_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_assets_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_bookmarks:{
			distinct_on:{
				type:"voyce_chapter_bookmarks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_bookmarks_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_bookmarks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_bookmarks_aggregate:{
			distinct_on:{
				type:"voyce_chapter_bookmarks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_bookmarks_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_bookmarks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_bookmarks_by_pk:{
			chapter_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			user_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_comments:{
			distinct_on:{
				type:"voyce_chapter_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_07252024:{
			distinct_on:{
				type:"voyce_chapter_comments_07252024_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_07252024_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_07252024_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_07252024_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_07252024_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_comments_reaction:{
			distinct_on:{
				type:"voyce_chapter_comments_reaction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reaction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reaction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_reaction_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_reaction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reaction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reaction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_reaction_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_comments_reaction_count:{
			distinct_on:{
				type:"voyce_chapter_comments_reaction_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reaction_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reaction_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_reaction_count_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_reaction_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reaction_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reaction_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_reaction_count_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_comments_reports:{
			distinct_on:{
				type:"voyce_chapter_comments_reports_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reports_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reports_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_reports_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_reports_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reports_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reports_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_reports_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_contents:{
			distinct_on:{
				type:"voyce_chapter_contents_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_contents_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_contents_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_contents_aggregate:{
			distinct_on:{
				type:"voyce_chapter_contents_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_contents_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_contents_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_contents_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_contents_old:{
			distinct_on:{
				type:"voyce_chapter_contents_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_contents_old_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_contents_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_contents_old_aggregate:{
			distinct_on:{
				type:"voyce_chapter_contents_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_contents_old_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_contents_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_contents_old_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_effects:{
			distinct_on:{
				type:"voyce_chapter_effects_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_effects_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_effects_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_effects_aggregate:{
			distinct_on:{
				type:"voyce_chapter_effects_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_effects_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_effects_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_effects_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_images:{
			distinct_on:{
				type:"voyce_chapter_images_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_images_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_images_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_images_07252024:{
			distinct_on:{
				type:"voyce_chapter_images_07252024_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_images_07252024_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_images_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_images_07252024_aggregate:{
			distinct_on:{
				type:"voyce_chapter_images_07252024_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_images_07252024_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_images_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_images_aggregate:{
			distinct_on:{
				type:"voyce_chapter_images_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_images_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_images_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_images_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_likes:{
			distinct_on:{
				type:"voyce_chapter_likes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_likes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_likes_aggregate:{
			distinct_on:{
				type:"voyce_chapter_likes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_likes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_likes_by_pk:{
			chapter_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			created_at:{
				type:"timestamptz",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_locks:{
			distinct_on:{
				type:"voyce_chapter_locks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_locks_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_locks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_locks_aggregate:{
			distinct_on:{
				type:"voyce_chapter_locks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_locks_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_locks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_locks_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_panel_views:{
			distinct_on:{
				type:"voyce_chapter_panel_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_panel_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_panel_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_panel_views_aggregate:{
			distinct_on:{
				type:"voyce_chapter_panel_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_panel_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_panel_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_panel_views_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_panels:{
			distinct_on:{
				type:"voyce_chapter_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_panels_aggregate:{
			distinct_on:{
				type:"voyce_chapter_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_panels_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_publish_schedules:{
			distinct_on:{
				type:"voyce_chapter_publish_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_publish_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_publish_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_publish_schedules_aggregate:{
			distinct_on:{
				type:"voyce_chapter_publish_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_publish_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_publish_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_publish_schedules_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_reactions:{
			distinct_on:{
				type:"voyce_chapter_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_reactions_aggregate:{
			distinct_on:{
				type:"voyce_chapter_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_reactions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_reactions_count:{
			distinct_on:{
				type:"voyce_chapter_reactions_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_reactions_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_reactions_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_reactions_count_aggregate:{
			distinct_on:{
				type:"voyce_chapter_reactions_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_reactions_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_reactions_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_reactions_count_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_view_logs:{
			distinct_on:{
				type:"voyce_chapter_view_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_view_logs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_view_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_view_logs_aggregate:{
			distinct_on:{
				type:"voyce_chapter_view_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_view_logs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_view_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_view_logs_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_views:{
			distinct_on:{
				type:"voyce_chapter_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_views_aggregate:{
			distinct_on:{
				type:"voyce_chapter_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_views_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapters:{
			distinct_on:{
				type:"voyce_chapters_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapters_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapters_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapters_07252024:{
			distinct_on:{
				type:"voyce_chapters_07252024_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapters_07252024_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapters_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapters_07252024_aggregate:{
			distinct_on:{
				type:"voyce_chapters_07252024_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapters_07252024_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapters_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapters_aggregate:{
			distinct_on:{
				type:"voyce_chapters_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapters_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapters_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapters_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_collaborator_roles:{
			distinct_on:{
				type:"voyce_collaborator_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_collaborator_roles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_collaborator_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_collaborator_roles_aggregate:{
			distinct_on:{
				type:"voyce_collaborator_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_collaborator_roles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_collaborator_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_collaborator_roles_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_comic_types:{
			distinct_on:{
				type:"voyce_comic_types_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_comic_types_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_comic_types_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_comic_types_aggregate:{
			distinct_on:{
				type:"voyce_comic_types_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_comic_types_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_comic_types_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_comic_types_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_comments_top_mat:{
			distinct_on:{
				type:"voyce_comments_top_mat_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_comments_top_mat_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_comments_top_mat_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_comments_top_mat_aggregate:{
			distinct_on:{
				type:"voyce_comments_top_mat_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_comments_top_mat_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_comments_top_mat_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_community_blurbs:{
			distinct_on:{
				type:"voyce_community_blurbs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_community_blurbs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_community_blurbs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_community_blurbs_aggregate:{
			distinct_on:{
				type:"voyce_community_blurbs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_community_blurbs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_community_blurbs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_community_blurbs_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_contact_us:{
			distinct_on:{
				type:"voyce_contact_us_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_contact_us_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_contact_us_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_contact_us_aggregate:{
			distinct_on:{
				type:"voyce_contact_us_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_contact_us_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_contact_us_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_contact_us_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_content_report:{
			distinct_on:{
				type:"voyce_content_report_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_content_report_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_content_report_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_content_report_aggregate:{
			distinct_on:{
				type:"voyce_content_report_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_content_report_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_content_report_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_content_report_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_contest_data:{
			distinct_on:{
				type:"voyce_contest_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_contest_data_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_contest_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_contest_data_aggregate:{
			distinct_on:{
				type:"voyce_contest_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_contest_data_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_contest_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_contest_data_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_countries:{
			distinct_on:{
				type:"voyce_countries_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_countries_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_countries_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_countries_aggregate:{
			distinct_on:{
				type:"voyce_countries_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_countries_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_countries_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_countries_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_csat_ratings:{
			distinct_on:{
				type:"voyce_csat_ratings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_csat_ratings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_csat_ratings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_csat_ratings_aggregate:{
			distinct_on:{
				type:"voyce_csat_ratings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_csat_ratings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_csat_ratings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_csat_ratings_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_csat_ratings_data:{
			distinct_on:{
				type:"voyce_csat_ratings_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_csat_ratings_data_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_csat_ratings_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_csat_ratings_data_aggregate:{
			distinct_on:{
				type:"voyce_csat_ratings_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_csat_ratings_data_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_csat_ratings_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_csat_ratings_data_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_education_types:{
			distinct_on:{
				type:"voyce_education_types_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_education_types_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_education_types_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_education_types_aggregate:{
			distinct_on:{
				type:"voyce_education_types_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_education_types_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_education_types_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_education_types_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_erp_login_roles:{
			distinct_on:{
				type:"voyce_erp_login_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_erp_login_roles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_erp_login_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_erp_login_roles_aggregate:{
			distinct_on:{
				type:"voyce_erp_login_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_erp_login_roles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_erp_login_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_erp_login_roles_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_etl_series_views_logs:{
			distinct_on:{
				type:"voyce_etl_series_views_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_etl_series_views_logs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_etl_series_views_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_etl_series_views_logs_aggregate:{
			distinct_on:{
				type:"voyce_etl_series_views_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_etl_series_views_logs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_etl_series_views_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_etl_series_views_logs_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_event_schedules:{
			distinct_on:{
				type:"voyce_event_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_event_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_event_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_event_schedules_aggregate:{
			distinct_on:{
				type:"voyce_event_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_event_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_event_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_event_schedules_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_genres:{
			distinct_on:{
				type:"voyce_genres_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_genres_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_genres_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_genres_aggregate:{
			distinct_on:{
				type:"voyce_genres_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_genres_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_genres_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_genres_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_guest_gtags:{
			distinct_on:{
				type:"voyce_guest_gtags_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_guest_gtags_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_guest_gtags_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_guest_gtags_aggregate:{
			distinct_on:{
				type:"voyce_guest_gtags_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_guest_gtags_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_guest_gtags_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_guest_gtags_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_metabases:{
			distinct_on:{
				type:"voyce_metabases_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_metabases_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_metabases_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_metabases_access:{
			distinct_on:{
				type:"voyce_metabases_access_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_metabases_access_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_metabases_access_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_metabases_access_aggregate:{
			distinct_on:{
				type:"voyce_metabases_access_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_metabases_access_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_metabases_access_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_metabases_access_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_metabases_aggregate:{
			distinct_on:{
				type:"voyce_metabases_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_metabases_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_metabases_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_metabases_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_newsletter:{
			distinct_on:{
				type:"voyce_newsletter_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_newsletter_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_newsletter_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_newsletter_aggregate:{
			distinct_on:{
				type:"voyce_newsletter_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_newsletter_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_newsletter_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_newsletter_by_pk:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_notification_series_settings:{
			distinct_on:{
				type:"voyce_notification_series_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_notification_series_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notification_series_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_notification_series_settings_aggregate:{
			distinct_on:{
				type:"voyce_notification_series_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_notification_series_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notification_series_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_notification_series_settings_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_notification_settings:{
			distinct_on:{
				type:"voyce_notification_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_notification_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notification_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_notification_settings_aggregate:{
			distinct_on:{
				type:"voyce_notification_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_notification_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notification_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_notification_settings_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_notifications:{
			distinct_on:{
				type:"voyce_notifications_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_notifications_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notifications_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_notifications_aggregate:{
			distinct_on:{
				type:"voyce_notifications_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_notifications_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notifications_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_notifications_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_originals_feed:{
			distinct_on:{
				type:"voyce_originals_feed_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_originals_feed_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_originals_feed_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_originals_feed_aggregate:{
			distinct_on:{
				type:"voyce_originals_feed_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_originals_feed_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_originals_feed_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_page_banners:{
			distinct_on:{
				type:"voyce_page_banners_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_page_banners_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_page_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_page_banners_aggregate:{
			distinct_on:{
				type:"voyce_page_banners_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_page_banners_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_page_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_page_banners_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_page_components:{
			distinct_on:{
				type:"voyce_page_components_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_page_components_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_page_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_page_components_aggregate:{
			distinct_on:{
				type:"voyce_page_components_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_page_components_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_page_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_page_components_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_pages:{
			distinct_on:{
				type:"voyce_pages_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_pages_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_pages_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_pages_aggregate:{
			distinct_on:{
				type:"voyce_pages_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_pages_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_pages_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_pages_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_panels_comments:{
			distinct_on:{
				type:"voyce_panels_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_panels_comments_aggregate:{
			distinct_on:{
				type:"voyce_panels_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_panels_comments_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_panels_reactions:{
			distinct_on:{
				type:"voyce_panels_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_panels_reactions_aggregate:{
			distinct_on:{
				type:"voyce_panels_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_panels_reactions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_panels_reactions_count:{
			distinct_on:{
				type:"voyce_panels_reactions_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_reactions_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_panels_reactions_count_aggregate:{
			distinct_on:{
				type:"voyce_panels_reactions_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_reactions_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_panels_reactions_count_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_payments_to_creators:{
			distinct_on:{
				type:"voyce_payments_to_creators_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_payments_to_creators_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_payments_to_creators_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_payments_to_creators_aggregate:{
			distinct_on:{
				type:"voyce_payments_to_creators_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_payments_to_creators_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_payments_to_creators_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_payments_to_creators_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_pg_rs_series_views_count:{
			distinct_on:{
				type:"voyce_pg_rs_series_views_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_pg_rs_series_views_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_pg_rs_series_views_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_pg_rs_series_views_count_aggregate:{
			distinct_on:{
				type:"voyce_pg_rs_series_views_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_pg_rs_series_views_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_pg_rs_series_views_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_pg_rs_series_views_count_by_pk:{
			series_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_polls:{
			distinct_on:{
				type:"voyce_polls_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_aggregate:{
			distinct_on:{
				type:"voyce_polls_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_answers:{
			distinct_on:{
				type:"voyce_polls_answers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_answers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_answers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_answers_aggregate:{
			distinct_on:{
				type:"voyce_polls_answers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_answers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_answers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_answers_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_polls_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_polls_comments:{
			distinct_on:{
				type:"voyce_polls_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_comments_aggregate:{
			distinct_on:{
				type:"voyce_polls_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_comments_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_polls_reactions:{
			distinct_on:{
				type:"voyce_polls_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_reactions_aggregate:{
			distinct_on:{
				type:"voyce_polls_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_reactions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_polls_votes:{
			distinct_on:{
				type:"voyce_polls_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_votes_aggregate:{
			distinct_on:{
				type:"voyce_polls_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_votes_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_popup_components:{
			distinct_on:{
				type:"voyce_popup_components_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_popup_components_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_popup_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_popup_components_aggregate:{
			distinct_on:{
				type:"voyce_popup_components_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_popup_components_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_popup_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_popup_components_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_popup_emails:{
			distinct_on:{
				type:"voyce_popup_emails_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_popup_emails_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_popup_emails_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_popup_emails_aggregate:{
			distinct_on:{
				type:"voyce_popup_emails_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_popup_emails_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_popup_emails_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_popup_emails_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_posts:{
			distinct_on:{
				type:"voyce_posts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_posts_aggregate:{
			distinct_on:{
				type:"voyce_posts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_posts_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_posts_comments:{
			distinct_on:{
				type:"voyce_posts_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_posts_comments_aggregate:{
			distinct_on:{
				type:"voyce_posts_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_posts_comments_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_posts_reactions:{
			distinct_on:{
				type:"voyce_posts_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_posts_reactions_aggregate:{
			distinct_on:{
				type:"voyce_posts_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_posts_reactions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_quest_difficulties:{
			distinct_on:{
				type:"voyce_quest_difficulties_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_quest_difficulties_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_quest_difficulties_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_quest_difficulties_aggregate:{
			distinct_on:{
				type:"voyce_quest_difficulties_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_quest_difficulties_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_quest_difficulties_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_quest_difficulties_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_quests:{
			distinct_on:{
				type:"voyce_quests_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_quests_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_quests_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_quests_aggregate:{
			distinct_on:{
				type:"voyce_quests_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_quests_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_quests_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_quests_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_quests_old:{
			distinct_on:{
				type:"voyce_quests_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_quests_old_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_quests_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_quests_old_aggregate:{
			distinct_on:{
				type:"voyce_quests_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_quests_old_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_quests_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_quests_old_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_recommended_series:{
			distinct_on:{
				type:"voyce_recommended_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_recommended_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_recommended_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_recommended_series_aggregate:{
			distinct_on:{
				type:"voyce_recommended_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_recommended_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_recommended_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_recommended_series_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_release_schedules:{
			distinct_on:{
				type:"voyce_release_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_release_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_release_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_release_schedules_aggregate:{
			distinct_on:{
				type:"voyce_release_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_release_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_release_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_release_schedules_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_rewards:{
			distinct_on:{
				type:"voyce_rewards_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_rewards_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_rewards_aggregate:{
			distinct_on:{
				type:"voyce_rewards_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_rewards_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_rewards_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_rewards_leaderboard:{
			distinct_on:{
				type:"voyce_rewards_leaderboard_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_rewards_leaderboard_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_leaderboard_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_rewards_leaderboard_aggregate:{
			distinct_on:{
				type:"voyce_rewards_leaderboard_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_rewards_leaderboard_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_leaderboard_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_rewards_leaderboard_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_rewards_quest:{
			distinct_on:{
				type:"voyce_rewards_quest_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_rewards_quest_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_quest_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_rewards_quest_aggregate:{
			distinct_on:{
				type:"voyce_rewards_quest_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_rewards_quest_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_quest_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_rewards_quest_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_sections:{
			distinct_on:{
				type:"voyce_sections_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_sections_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_sections_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_sections_aggregate:{
			distinct_on:{
				type:"voyce_sections_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_sections_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_sections_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_sections_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series:{
			distinct_on:{
				type:"voyce_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_aggregate:{
			distinct_on:{
				type:"voyce_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_collaborators:{
			distinct_on:{
				type:"voyce_series_collaborators_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_collaborators_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_collaborators_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_collaborators_aggregate:{
			distinct_on:{
				type:"voyce_series_collaborators_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_collaborators_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_collaborators_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_collaborators_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_donations:{
			distinct_on:{
				type:"voyce_series_donations_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_donations_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_donations_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_donations_aggregate:{
			distinct_on:{
				type:"voyce_series_donations_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_donations_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_donations_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_donations_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_favorites:{
			distinct_on:{
				type:"voyce_series_favorites_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_favorites_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_favorites_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_favorites_aggregate:{
			distinct_on:{
				type:"voyce_series_favorites_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_favorites_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_favorites_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_favorites_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			series_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_featured_panels:{
			distinct_on:{
				type:"voyce_series_featured_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_featured_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_featured_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_featured_panels_aggregate:{
			distinct_on:{
				type:"voyce_series_featured_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_featured_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_featured_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_featured_panels_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_genre:{
			distinct_on:{
				type:"voyce_series_genre_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_genre_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_genre_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_genre_aggregate:{
			distinct_on:{
				type:"voyce_series_genre_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_genre_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_genre_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_genre_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_giveaway_participants:{
			distinct_on:{
				type:"voyce_series_giveaway_participants_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_giveaway_participants_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_giveaway_participants_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_giveaway_participants_aggregate:{
			distinct_on:{
				type:"voyce_series_giveaway_participants_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_giveaway_participants_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_giveaway_participants_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_giveaway_participants_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_hot_panels:{
			distinct_on:{
				type:"voyce_series_hot_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_hot_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_hot_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_hot_panels_aggregate:{
			distinct_on:{
				type:"voyce_series_hot_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_hot_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_hot_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_hot_panels_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_layouts:{
			distinct_on:{
				type:"voyce_series_layouts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_layouts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_layouts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_layouts_aggregate:{
			distinct_on:{
				type:"voyce_series_layouts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_layouts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_layouts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_layouts_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_leaderboard:{
			distinct_on:{
				type:"voyce_series_leaderboard_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_leaderboard_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_leaderboard_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_leaderboard_aggregate:{
			distinct_on:{
				type:"voyce_series_leaderboard_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_leaderboard_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_leaderboard_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_leaderboard_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_likes_count:{
			distinct_on:{
				type:"voyce_series_likes_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_likes_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_likes_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_likes_count_aggregate:{
			distinct_on:{
				type:"voyce_series_likes_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_likes_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_likes_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_likes_count_mat:{
			distinct_on:{
				type:"voyce_series_likes_count_mat_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_likes_count_mat_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_likes_count_mat_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_likes_count_mat_aggregate:{
			distinct_on:{
				type:"voyce_series_likes_count_mat_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_likes_count_mat_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_likes_count_mat_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_lock_config:{
			distinct_on:{
				type:"voyce_series_lock_config_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_lock_config_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_lock_config_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_lock_config_aggregate:{
			distinct_on:{
				type:"voyce_series_lock_config_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_lock_config_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_lock_config_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_lock_config_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_sponsorships_configuration:{
			distinct_on:{
				type:"voyce_series_sponsorships_configuration_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_sponsorships_configuration_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_sponsorships_configuration_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_sponsorships_configuration_aggregate:{
			distinct_on:{
				type:"voyce_series_sponsorships_configuration_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_sponsorships_configuration_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_sponsorships_configuration_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_sponsorships_configuration_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_subscription:{
			distinct_on:{
				type:"voyce_series_subscription_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_subscription_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_subscription_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_subscription_aggregate:{
			distinct_on:{
				type:"voyce_series_subscription_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_subscription_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_subscription_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_subscription_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_tags:{
			distinct_on:{
				type:"voyce_series_tags_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_tags_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_tags_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_tags_aggregate:{
			distinct_on:{
				type:"voyce_series_tags_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_tags_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_tags_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_tags_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_tags_junction:{
			distinct_on:{
				type:"voyce_series_tags_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_tags_junction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_tags_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_tags_junction_aggregate:{
			distinct_on:{
				type:"voyce_series_tags_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_tags_junction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_tags_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_tags_junction_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_top_comments:{
			distinct_on:{
				type:"voyce_series_top_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_top_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_top_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_top_comments_aggregate:{
			distinct_on:{
				type:"voyce_series_top_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_top_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_top_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_top_comments_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_top_likes:{
			distinct_on:{
				type:"voyce_series_top_likes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_top_likes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_top_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_top_likes_aggregate:{
			distinct_on:{
				type:"voyce_series_top_likes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_top_likes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_top_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_top_likes_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_top_views:{
			distinct_on:{
				type:"voyce_series_top_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_top_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_top_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_top_views_aggregate:{
			distinct_on:{
				type:"voyce_series_top_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_top_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_top_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_top_views_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_views_adjustment:{
			distinct_on:{
				type:"voyce_series_views_adjustment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_adjustment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_adjustment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_adjustment_aggregate:{
			distinct_on:{
				type:"voyce_series_views_adjustment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_adjustment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_adjustment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_adjustment_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_views_count:{
			distinct_on:{
				type:"voyce_series_views_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_aggregate:{
			distinct_on:{
				type:"voyce_series_views_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_mat:{
			distinct_on:{
				type:"voyce_series_views_count_mat_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_count_mat_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_mat_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_mat_aggregate:{
			distinct_on:{
				type:"voyce_series_views_count_mat_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_count_mat_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_mat_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_mat_sep30:{
			distinct_on:{
				type:"voyce_series_views_count_mat_sep30_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_count_mat_sep30_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_mat_sep30_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_mat_sep30_aggregate:{
			distinct_on:{
				type:"voyce_series_views_count_mat_sep30_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_count_mat_sep30_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_mat_sep30_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_mat_v2:{
			distinct_on:{
				type:"voyce_series_views_count_mat_v2_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_count_mat_v2_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_mat_v2_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_mat_v2_aggregate:{
			distinct_on:{
				type:"voyce_series_views_count_mat_v2_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_count_mat_v2_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_mat_v2_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_votes:{
			distinct_on:{
				type:"voyce_series_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_votes_aggregate:{
			distinct_on:{
				type:"voyce_series_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_votes_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_votes_count:{
			distinct_on:{
				type:"voyce_series_votes_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_votes_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_votes_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_votes_count_aggregate:{
			distinct_on:{
				type:"voyce_series_votes_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_votes_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_votes_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_votes_count_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_voting_schedules:{
			distinct_on:{
				type:"voyce_series_voting_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_voting_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_voting_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_voting_schedules_aggregate:{
			distinct_on:{
				type:"voyce_series_voting_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_voting_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_voting_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_voting_schedules_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_sponsorships:{
			distinct_on:{
				type:"voyce_sponsorships_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_sponsorships_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_sponsorships_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_sponsorships_aggregate:{
			distinct_on:{
				type:"voyce_sponsorships_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_sponsorships_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_sponsorships_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_sponsorships_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_states:{
			distinct_on:{
				type:"voyce_states_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_states_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_states_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_states_aggregate:{
			distinct_on:{
				type:"voyce_states_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_states_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_states_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_states_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_stripe_payouts_setup:{
			distinct_on:{
				type:"voyce_stripe_payouts_setup_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_stripe_payouts_setup_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_stripe_payouts_setup_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_stripe_payouts_setup_aggregate:{
			distinct_on:{
				type:"voyce_stripe_payouts_setup_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_stripe_payouts_setup_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_stripe_payouts_setup_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_stripe_payouts_setup_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_studio:{
			distinct_on:{
				type:"voyce_studio_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_aggregate:{
			distinct_on:{
				type:"voyce_studio_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_studio_followers:{
			distinct_on:{
				type:"voyce_studio_followers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_followers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_followers_aggregate:{
			distinct_on:{
				type:"voyce_studio_followers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_followers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_followers_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_studio_series:{
			distinct_on:{
				type:"voyce_studio_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_series_aggregate:{
			distinct_on:{
				type:"voyce_studio_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_series_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_studio_views:{
			distinct_on:{
				type:"voyce_studio_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_views_aggregate:{
			distinct_on:{
				type:"voyce_studio_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_views_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_system_settings:{
			distinct_on:{
				type:"voyce_system_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_system_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_system_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_system_settings_aggregate:{
			distinct_on:{
				type:"voyce_system_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_system_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_system_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_system_settings_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_top_genres:{
			distinct_on:{
				type:"voyce_top_genres_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_top_genres_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_top_genres_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_top_genres_aggregate:{
			distinct_on:{
				type:"voyce_top_genres_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_top_genres_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_top_genres_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_transaction_logs:{
			distinct_on:{
				type:"voyce_transaction_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_transaction_logs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_transaction_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_transaction_logs_aggregate:{
			distinct_on:{
				type:"voyce_transaction_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_transaction_logs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_transaction_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_transaction_logs_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_url_redirects:{
			distinct_on:{
				type:"voyce_url_redirects_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_url_redirects_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_url_redirects_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_url_redirects_aggregate:{
			distinct_on:{
				type:"voyce_url_redirects_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_url_redirects_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_url_redirects_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_url_redirects_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_addresses:{
			distinct_on:{
				type:"voyce_user_addresses_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_addresses_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_addresses_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_addresses_aggregate:{
			distinct_on:{
				type:"voyce_user_addresses_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_addresses_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_addresses_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_addresses_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_blocks:{
			distinct_on:{
				type:"voyce_user_blocks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_blocks_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_blocks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_blocks_aggregate:{
			distinct_on:{
				type:"voyce_user_blocks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_blocks_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_blocks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_blocks_by_pk:{
			linked_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			user_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_chapters_unlocked:{
			distinct_on:{
				type:"voyce_user_chapters_unlocked_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_chapters_unlocked_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_chapters_unlocked_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_chapters_unlocked_aggregate:{
			distinct_on:{
				type:"voyce_user_chapters_unlocked_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_chapters_unlocked_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_chapters_unlocked_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_chapters_unlocked_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_contacts:{
			distinct_on:{
				type:"voyce_user_contacts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_contacts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_contacts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_contacts_aggregate:{
			distinct_on:{
				type:"voyce_user_contacts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_contacts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_contacts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_contacts_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_csat_ratings:{
			distinct_on:{
				type:"voyce_user_csat_ratings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_csat_ratings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_csat_ratings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_csat_ratings_aggregate:{
			distinct_on:{
				type:"voyce_user_csat_ratings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_csat_ratings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_csat_ratings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_csat_ratings_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_education:{
			distinct_on:{
				type:"voyce_user_education_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_education_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_education_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_education_aggregate:{
			distinct_on:{
				type:"voyce_user_education_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_education_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_education_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_education_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_level:{
			distinct_on:{
				type:"voyce_user_level_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_level_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_level_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_level_aggregate:{
			distinct_on:{
				type:"voyce_user_level_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_level_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_level_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_level_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_linked_accounts:{
			distinct_on:{
				type:"voyce_user_linked_accounts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_linked_accounts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_linked_accounts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_linked_accounts_aggregate:{
			distinct_on:{
				type:"voyce_user_linked_accounts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_linked_accounts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_linked_accounts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_linked_accounts_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_login_history:{
			distinct_on:{
				type:"voyce_user_login_history_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_login_history_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_login_history_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_login_history_aggregate:{
			distinct_on:{
				type:"voyce_user_login_history_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_login_history_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_login_history_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_login_history_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_quest_ids:{
			distinct_on:{
				type:"voyce_user_quest_ids_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quest_ids_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quest_ids_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quest_ids_aggregate:{
			distinct_on:{
				type:"voyce_user_quest_ids_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quest_ids_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quest_ids_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quest_ids_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_quest_rewards:{
			distinct_on:{
				type:"voyce_user_quest_rewards_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quest_rewards_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quest_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quest_rewards_aggregate:{
			distinct_on:{
				type:"voyce_user_quest_rewards_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quest_rewards_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quest_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quest_rewards_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_quests:{
			distinct_on:{
				type:"voyce_user_quests_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quests_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quests_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quests_aggregate:{
			distinct_on:{
				type:"voyce_user_quests_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quests_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quests_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quests_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_quests_old:{
			distinct_on:{
				type:"voyce_user_quests_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quests_old_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quests_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quests_old_aggregate:{
			distinct_on:{
				type:"voyce_user_quests_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quests_old_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quests_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quests_old_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_ranking:{
			distinct_on:{
				type:"voyce_user_ranking_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_ranking_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_ranking_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_ranking_aggregate:{
			distinct_on:{
				type:"voyce_user_ranking_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_ranking_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_ranking_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_ranking_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_reviews:{
			distinct_on:{
				type:"voyce_user_reviews_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_reviews_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_reviews_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_reviews_aggregate:{
			distinct_on:{
				type:"voyce_user_reviews_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_reviews_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_reviews_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_reviews_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_rewards:{
			distinct_on:{
				type:"voyce_user_rewards_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_rewards_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_rewards_aggregate:{
			distinct_on:{
				type:"voyce_user_rewards_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_rewards_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_rewards_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_roles:{
			distinct_on:{
				type:"voyce_user_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_roles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_roles_aggregate:{
			distinct_on:{
				type:"voyce_user_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_roles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_roles_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_series_subscription_count:{
			distinct_on:{
				type:"voyce_user_series_subscription_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_series_subscription_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_series_subscription_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_series_subscription_count_aggregate:{
			distinct_on:{
				type:"voyce_user_series_subscription_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_series_subscription_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_series_subscription_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_settings:{
			distinct_on:{
				type:"voyce_user_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_settings_aggregate:{
			distinct_on:{
				type:"voyce_user_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_settings_by_pk:{
			user_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_skills:{
			distinct_on:{
				type:"voyce_user_skills_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_skills_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_skills_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_skills_aggregate:{
			distinct_on:{
				type:"voyce_user_skills_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_skills_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_skills_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_skills_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_socials:{
			distinct_on:{
				type:"voyce_user_socials_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_socials_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_socials_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_socials_aggregate:{
			distinct_on:{
				type:"voyce_user_socials_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_socials_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_socials_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_socials_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_soft_deleted:{
			distinct_on:{
				type:"voyce_user_soft_deleted_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_soft_deleted_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_soft_deleted_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_soft_deleted_aggregate:{
			distinct_on:{
				type:"voyce_user_soft_deleted_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_soft_deleted_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_soft_deleted_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_soft_deleted_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_vtags:{
			distinct_on:{
				type:"voyce_user_vtags_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_vtags_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_vtags_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_vtags_aggregate:{
			distinct_on:{
				type:"voyce_user_vtags_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_vtags_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_vtags_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_vtags_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_wins:{
			distinct_on:{
				type:"voyce_user_wins_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_wins_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_wins_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_wins_aggregate:{
			distinct_on:{
				type:"voyce_user_wins_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_wins_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_wins_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_wins_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_users:{
			distinct_on:{
				type:"voyce_users_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_aggregate:{
			distinct_on:{
				type:"voyce_users_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_users_followers:{
			distinct_on:{
				type:"voyce_users_followers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_followers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_followers_aggregate:{
			distinct_on:{
				type:"voyce_users_followers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_followers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_followers_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_users_private:{
			distinct_on:{
				type:"voyce_users_private_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_private_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_private_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_private_aggregate:{
			distinct_on:{
				type:"voyce_users_private_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_private_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_private_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_series_junction:{
			distinct_on:{
				type:"voyce_users_series_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_series_junction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_series_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_series_junction_aggregate:{
			distinct_on:{
				type:"voyce_users_series_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_series_junction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_series_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_series_junction_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_versions:{
			distinct_on:{
				type:"voyce_versions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_versions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_versions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_versions_aggregate:{
			distinct_on:{
				type:"voyce_versions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_versions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_versions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_versions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_voting_analytics:{
			distinct_on:{
				type:"voyce_voting_analytics_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_analytics_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_analytics_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_analytics_aggregate:{
			distinct_on:{
				type:"voyce_voting_analytics_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_analytics_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_analytics_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_analytics_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_voting_panels:{
			distinct_on:{
				type:"voyce_voting_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_panels_aggregate:{
			distinct_on:{
				type:"voyce_voting_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_panels_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_voting_series:{
			distinct_on:{
				type:"voyce_voting_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_series_aggregate:{
			distinct_on:{
				type:"voyce_voting_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_series_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_voting_user_votes:{
			distinct_on:{
				type:"voyce_voting_user_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_user_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_user_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_user_votes_aggregate:{
			distinct_on:{
				type:"voyce_voting_user_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_user_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_user_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_user_votes_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	smallint: "String",
	smallint_comparison_exp:{
		_eq:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"smallint",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"smallint",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	store_payments_aggregate_fields:{
		count:{
			columns:{
				type:"store_payments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	store_payments_bool_exp:{
		_and:{
			type:"store_payments_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"store_payments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"store_payments_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		amount:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		gateway:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchase_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		remarks:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_payments_constraint: "enum",
	store_payments_inc_input:{
		amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_payments_insert_input:{
		amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gateway:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchase_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		remarks:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_payments_on_conflict:{
		constraint:{
			type:"store_payments_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"store_payments_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"store_payments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_payments_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gateway:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchase_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		remarks:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_payments_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		},
		purchase_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	store_payments_select_column: "enum",
	store_payments_set_input:{
		amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gateway:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchase_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		remarks:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_payments_stream_cursor_input:{
		initial_value:{
			type:"store_payments_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_payments_stream_cursor_value_input:{
		amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gateway:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchase_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		remarks:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_payments_update_column: "enum",
	store_payments_updates:{
		_inc:{
			type:"store_payments_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"store_payments_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"store_payments_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	store_products_aggregate_fields:{
		count:{
			columns:{
				type:"store_products_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	store_products_bool_exp:{
		_and:{
			type:"store_products_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"store_products_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"store_products_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		category:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		enabled:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_store_visible:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		price:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchase_limit:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		quantity:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_num:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_products_constraint: "enum",
	store_products_inc_input:{
		price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchase_limit:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		quantity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_num:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_products_insert_input:{
		category:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_store_visible:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchase_limit:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		quantity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_num:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_products_on_conflict:{
		constraint:{
			type:"store_products_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"store_products_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"store_products_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_products_order_by:{
		category:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		enabled:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_store_visible:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchase_limit:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quantity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_num:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_products_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	store_products_select_column: "enum",
	store_products_set_input:{
		category:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_store_visible:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchase_limit:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		quantity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_num:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_products_stream_cursor_input:{
		initial_value:{
			type:"store_products_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_products_stream_cursor_value_input:{
		category:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_store_visible:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchase_limit:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		quantity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_num:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_products_update_column: "enum",
	store_products_updates:{
		_inc:{
			type:"store_products_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"store_products_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"store_products_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	store_purchases:{
		product_meta:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	store_purchases_aggregate_fields:{
		count:{
			columns:{
				type:"store_purchases_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	store_purchases_bool_exp:{
		_and:{
			type:"store_purchases_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"store_purchases_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"store_purchases_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_category:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_currency:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_image_url:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_label:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_meta:{
			type:"json_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_price:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		quantity:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reference_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		remarks:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_price:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_purchases_constraint: "enum",
	store_purchases_inc_input:{
		product_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		quantity:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_purchases_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_category:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_image_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_label:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_meta:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		quantity:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		reference_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		remarks:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_purchases_on_conflict:{
		constraint:{
			type:"store_purchases_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"store_purchases_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"store_purchases_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_purchases_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_category:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_currency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_image_url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_label:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_meta:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quantity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reference_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		remarks:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_purchases_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	store_purchases_select_column: "enum",
	store_purchases_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_category:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_image_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_label:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_meta:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		quantity:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		reference_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		remarks:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_purchases_stream_cursor_input:{
		initial_value:{
			type:"store_purchases_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_purchases_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_category:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_image_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_label:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_meta:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		quantity:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		reference_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		remarks:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_purchases_update_column: "enum",
	store_purchases_updates:{
		_inc:{
			type:"store_purchases_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"store_purchases_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"store_purchases_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	store_users_aggregate_fields:{
		count:{
			columns:{
				type:"store_users_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	store_users_bool_exp:{
		_and:{
			type:"store_users_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"store_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"store_users_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		about:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ad_share:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_date:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_login:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		remember_code:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		vmuser:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_users_inc_input:{
		ad_share:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_users_insert_input:{
		about:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ad_share:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_login:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		remember_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		vmuser:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_users_order_by:{
		about:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ad_share:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_login:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		remember_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vmuser:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_users_select_column: "enum",
	store_users_set_input:{
		about:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ad_share:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_login:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		remember_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		vmuser:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_users_stream_cursor_input:{
		initial_value:{
			type:"store_users_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_users_stream_cursor_value_input:{
		about:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ad_share:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_login:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		remember_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		vmuser:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	store_users_updates:{
		_inc:{
			type:"store_users_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"store_users_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"store_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	subscription_root:{
		awsdms_validation_failures_v1:{
			distinct_on:{
				type:"awsdms_validation_failures_v1_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"awsdms_validation_failures_v1_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"awsdms_validation_failures_v1_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		awsdms_validation_failures_v1_aggregate:{
			distinct_on:{
				type:"awsdms_validation_failures_v1_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"awsdms_validation_failures_v1_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"awsdms_validation_failures_v1_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		awsdms_validation_failures_v1_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"awsdms_validation_failures_v1_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"awsdms_validation_failures_v1_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_balances:{
			distinct_on:{
				type:"coinsystem_balances_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_balances_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_balances_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_balances_aggregate:{
			distinct_on:{
				type:"coinsystem_balances_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_balances_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_balances_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_balances_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		coinsystem_balances_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"coinsystem_balances_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_balances_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_bonus_coins:{
			distinct_on:{
				type:"coinsystem_bonus_coins_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_bonus_coins_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_bonus_coins_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_bonus_coins_aggregate:{
			distinct_on:{
				type:"coinsystem_bonus_coins_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_bonus_coins_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_bonus_coins_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_bonus_coins_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		coinsystem_bonus_coins_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"coinsystem_bonus_coins_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_bonus_coins_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_coins:{
			distinct_on:{
				type:"coinsystem_coins_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_coins_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_coins_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_coins_aggregate:{
			distinct_on:{
				type:"coinsystem_coins_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_coins_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_coins_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_coins_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		coinsystem_coins_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"coinsystem_coins_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_coins_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_exchange_rates:{
			distinct_on:{
				type:"coinsystem_exchange_rates_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_exchange_rates_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_exchange_rates_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_exchange_rates_aggregate:{
			distinct_on:{
				type:"coinsystem_exchange_rates_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_exchange_rates_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_exchange_rates_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_exchange_rates_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		coinsystem_exchange_rates_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"coinsystem_exchange_rates_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_exchange_rates_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_logs:{
			distinct_on:{
				type:"coinsystem_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_logs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_logs_aggregate:{
			distinct_on:{
				type:"coinsystem_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_logs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_logs_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		coinsystem_logs_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"coinsystem_logs_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_transactions:{
			distinct_on:{
				type:"coinsystem_transactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_transactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_transactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_transactions_aggregate:{
			distinct_on:{
				type:"coinsystem_transactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_transactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_transactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_transactions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		coinsystem_transactions_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"coinsystem_transactions_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_transactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_wallets:{
			distinct_on:{
				type:"coinsystem_wallets_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_wallets_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_wallets_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_wallets_aggregate:{
			distinct_on:{
				type:"coinsystem_wallets_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"coinsystem_wallets_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_wallets_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		coinsystem_wallets_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		coinsystem_wallets_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"coinsystem_wallets_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"coinsystem_wallets_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		mysql_user:{
			distinct_on:{
				type:"mysql_user_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"mysql_user_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"mysql_user_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		mysql_user_aggregate:{
			distinct_on:{
				type:"mysql_user_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"mysql_user_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"mysql_user_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		mysql_user_by_pk:{
			host:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			user:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		mysql_user_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"mysql_user_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"mysql_user_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_payments:{
			distinct_on:{
				type:"store_payments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"store_payments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_payments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_payments_aggregate:{
			distinct_on:{
				type:"store_payments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"store_payments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_payments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_payments_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			},
			purchase_id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		store_payments_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"store_payments_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_payments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_products:{
			distinct_on:{
				type:"store_products_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"store_products_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_products_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_products_aggregate:{
			distinct_on:{
				type:"store_products_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"store_products_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_products_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_products_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		store_products_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"store_products_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_products_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_purchases:{
			distinct_on:{
				type:"store_purchases_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"store_purchases_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_purchases_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_purchases_aggregate:{
			distinct_on:{
				type:"store_purchases_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"store_purchases_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_purchases_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_purchases_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		store_purchases_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"store_purchases_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_purchases_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_users:{
			distinct_on:{
				type:"store_users_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"store_users_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_users_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_users_aggregate:{
			distinct_on:{
				type:"store_users_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"store_users_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_users_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store_users_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"store_users_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"store_users_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_admin_roles:{
			distinct_on:{
				type:"voyce_admin_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_admin_roles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_admin_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_admin_roles_aggregate:{
			distinct_on:{
				type:"voyce_admin_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_admin_roles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_admin_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_admin_roles_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_admin_roles_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_admin_roles_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_admin_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_app_banners:{
			distinct_on:{
				type:"voyce_app_banners_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_app_banners_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_app_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_app_banners_aggregate:{
			distinct_on:{
				type:"voyce_app_banners_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_app_banners_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_app_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_app_banners_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_app_banners_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_app_banners_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_app_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_app_components:{
			distinct_on:{
				type:"voyce_app_components_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_app_components_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_app_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_app_components_aggregate:{
			distinct_on:{
				type:"voyce_app_components_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_app_components_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_app_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_app_components_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_app_components_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_app_components_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_app_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_audit_trail:{
			distinct_on:{
				type:"voyce_audit_trail_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_audit_trail_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_audit_trail_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_audit_trail_aggregate:{
			distinct_on:{
				type:"voyce_audit_trail_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_audit_trail_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_audit_trail_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_audit_trail_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_audit_trail_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_audit_trail_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_audit_trail_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_author_profiles:{
			distinct_on:{
				type:"voyce_author_profiles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_author_profiles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_author_profiles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_author_profiles_aggregate:{
			distinct_on:{
				type:"voyce_author_profiles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_author_profiles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_author_profiles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_author_profiles_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_author_profiles_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_author_profiles_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_author_profiles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_balances:{
			distinct_on:{
				type:"voyce_balances_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_balances_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_balances_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_balances_aggregate:{
			distinct_on:{
				type:"voyce_balances_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_balances_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_balances_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_balances_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_balances_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_balances_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_balances_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_banners:{
			distinct_on:{
				type:"voyce_banners_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_banners_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_banners_aggregate:{
			distinct_on:{
				type:"voyce_banners_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_banners_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_banners_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_banners_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_banners_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_assets:{
			distinct_on:{
				type:"voyce_chapter_assets_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_assets_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_assets_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_assets_aggregate:{
			distinct_on:{
				type:"voyce_chapter_assets_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_assets_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_assets_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_assets_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_assets_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_assets_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_assets_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_bookmarks:{
			distinct_on:{
				type:"voyce_chapter_bookmarks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_bookmarks_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_bookmarks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_bookmarks_aggregate:{
			distinct_on:{
				type:"voyce_chapter_bookmarks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_bookmarks_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_bookmarks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_bookmarks_by_pk:{
			chapter_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			user_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_bookmarks_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_bookmarks_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_bookmarks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments:{
			distinct_on:{
				type:"voyce_chapter_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_07252024:{
			distinct_on:{
				type:"voyce_chapter_comments_07252024_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_07252024_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_07252024_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_07252024_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_07252024_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_07252024_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_comments_07252024_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_comments_reaction:{
			distinct_on:{
				type:"voyce_chapter_comments_reaction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reaction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reaction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_reaction_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_reaction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reaction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reaction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_reaction_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_comments_reaction_count:{
			distinct_on:{
				type:"voyce_chapter_comments_reaction_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reaction_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reaction_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_reaction_count_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_reaction_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reaction_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reaction_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_reaction_count_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_comments_reaction_count_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_comments_reaction_count_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reaction_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_reaction_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_comments_reaction_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reaction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_reports:{
			distinct_on:{
				type:"voyce_chapter_comments_reports_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reports_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reports_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_reports_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_reports_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reports_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reports_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_reports_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_comments_reports_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_comments_reports_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reports_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_comments_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_comments_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_contents:{
			distinct_on:{
				type:"voyce_chapter_contents_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_contents_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_contents_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_contents_aggregate:{
			distinct_on:{
				type:"voyce_chapter_contents_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_contents_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_contents_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_contents_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_contents_old:{
			distinct_on:{
				type:"voyce_chapter_contents_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_contents_old_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_contents_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_contents_old_aggregate:{
			distinct_on:{
				type:"voyce_chapter_contents_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_contents_old_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_contents_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_contents_old_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_contents_old_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_contents_old_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_contents_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_contents_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_contents_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_contents_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_effects:{
			distinct_on:{
				type:"voyce_chapter_effects_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_effects_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_effects_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_effects_aggregate:{
			distinct_on:{
				type:"voyce_chapter_effects_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_effects_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_effects_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_effects_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_effects_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_effects_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_effects_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_images:{
			distinct_on:{
				type:"voyce_chapter_images_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_images_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_images_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_images_07252024:{
			distinct_on:{
				type:"voyce_chapter_images_07252024_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_images_07252024_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_images_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_images_07252024_aggregate:{
			distinct_on:{
				type:"voyce_chapter_images_07252024_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_images_07252024_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_images_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_images_07252024_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_images_07252024_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_images_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_images_aggregate:{
			distinct_on:{
				type:"voyce_chapter_images_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_images_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_images_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_images_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_images_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_images_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_images_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_likes:{
			distinct_on:{
				type:"voyce_chapter_likes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_likes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_likes_aggregate:{
			distinct_on:{
				type:"voyce_chapter_likes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_likes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_likes_by_pk:{
			chapter_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			created_at:{
				type:"timestamptz",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_likes_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_likes_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_locks:{
			distinct_on:{
				type:"voyce_chapter_locks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_locks_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_locks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_locks_aggregate:{
			distinct_on:{
				type:"voyce_chapter_locks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_locks_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_locks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_locks_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_locks_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_locks_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_locks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_panel_views:{
			distinct_on:{
				type:"voyce_chapter_panel_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_panel_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_panel_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_panel_views_aggregate:{
			distinct_on:{
				type:"voyce_chapter_panel_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_panel_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_panel_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_panel_views_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_panel_views_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_panel_views_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_panel_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_panels:{
			distinct_on:{
				type:"voyce_chapter_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_panels_aggregate:{
			distinct_on:{
				type:"voyce_chapter_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_panels_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_panels_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_panels_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_publish_schedules:{
			distinct_on:{
				type:"voyce_chapter_publish_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_publish_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_publish_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_publish_schedules_aggregate:{
			distinct_on:{
				type:"voyce_chapter_publish_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_publish_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_publish_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_publish_schedules_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_publish_schedules_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_publish_schedules_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_publish_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_reactions:{
			distinct_on:{
				type:"voyce_chapter_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_reactions_aggregate:{
			distinct_on:{
				type:"voyce_chapter_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_reactions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_reactions_count:{
			distinct_on:{
				type:"voyce_chapter_reactions_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_reactions_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_reactions_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_reactions_count_aggregate:{
			distinct_on:{
				type:"voyce_chapter_reactions_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_reactions_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_reactions_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_reactions_count_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_reactions_count_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_reactions_count_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_reactions_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_reactions_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_reactions_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_view_logs:{
			distinct_on:{
				type:"voyce_chapter_view_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_view_logs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_view_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_view_logs_aggregate:{
			distinct_on:{
				type:"voyce_chapter_view_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_view_logs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_view_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_view_logs_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_view_logs_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_view_logs_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_view_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_views:{
			distinct_on:{
				type:"voyce_chapter_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_views_aggregate:{
			distinct_on:{
				type:"voyce_chapter_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapter_views_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapter_views_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapter_views_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapters:{
			distinct_on:{
				type:"voyce_chapters_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapters_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapters_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapters_07252024:{
			distinct_on:{
				type:"voyce_chapters_07252024_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapters_07252024_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapters_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapters_07252024_aggregate:{
			distinct_on:{
				type:"voyce_chapters_07252024_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapters_07252024_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapters_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapters_07252024_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapters_07252024_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapters_07252024_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapters_aggregate:{
			distinct_on:{
				type:"voyce_chapters_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapters_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapters_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_chapters_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_chapters_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_chapters_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapters_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_collaborator_roles:{
			distinct_on:{
				type:"voyce_collaborator_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_collaborator_roles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_collaborator_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_collaborator_roles_aggregate:{
			distinct_on:{
				type:"voyce_collaborator_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_collaborator_roles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_collaborator_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_collaborator_roles_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_collaborator_roles_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_collaborator_roles_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_collaborator_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_comic_types:{
			distinct_on:{
				type:"voyce_comic_types_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_comic_types_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_comic_types_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_comic_types_aggregate:{
			distinct_on:{
				type:"voyce_comic_types_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_comic_types_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_comic_types_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_comic_types_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_comic_types_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_comic_types_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_comic_types_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_comments_top_mat:{
			distinct_on:{
				type:"voyce_comments_top_mat_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_comments_top_mat_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_comments_top_mat_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_comments_top_mat_aggregate:{
			distinct_on:{
				type:"voyce_comments_top_mat_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_comments_top_mat_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_comments_top_mat_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_comments_top_mat_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_comments_top_mat_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_comments_top_mat_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_community_blurbs:{
			distinct_on:{
				type:"voyce_community_blurbs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_community_blurbs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_community_blurbs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_community_blurbs_aggregate:{
			distinct_on:{
				type:"voyce_community_blurbs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_community_blurbs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_community_blurbs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_community_blurbs_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_community_blurbs_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_community_blurbs_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_community_blurbs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_contact_us:{
			distinct_on:{
				type:"voyce_contact_us_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_contact_us_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_contact_us_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_contact_us_aggregate:{
			distinct_on:{
				type:"voyce_contact_us_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_contact_us_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_contact_us_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_contact_us_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_contact_us_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_contact_us_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_contact_us_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_content_report:{
			distinct_on:{
				type:"voyce_content_report_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_content_report_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_content_report_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_content_report_aggregate:{
			distinct_on:{
				type:"voyce_content_report_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_content_report_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_content_report_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_content_report_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_content_report_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_content_report_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_content_report_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_contest_data:{
			distinct_on:{
				type:"voyce_contest_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_contest_data_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_contest_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_contest_data_aggregate:{
			distinct_on:{
				type:"voyce_contest_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_contest_data_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_contest_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_contest_data_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_contest_data_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_contest_data_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_contest_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_countries:{
			distinct_on:{
				type:"voyce_countries_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_countries_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_countries_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_countries_aggregate:{
			distinct_on:{
				type:"voyce_countries_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_countries_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_countries_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_countries_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_countries_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_countries_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_countries_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_csat_ratings:{
			distinct_on:{
				type:"voyce_csat_ratings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_csat_ratings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_csat_ratings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_csat_ratings_aggregate:{
			distinct_on:{
				type:"voyce_csat_ratings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_csat_ratings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_csat_ratings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_csat_ratings_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_csat_ratings_data:{
			distinct_on:{
				type:"voyce_csat_ratings_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_csat_ratings_data_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_csat_ratings_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_csat_ratings_data_aggregate:{
			distinct_on:{
				type:"voyce_csat_ratings_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_csat_ratings_data_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_csat_ratings_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_csat_ratings_data_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_csat_ratings_data_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_csat_ratings_data_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_csat_ratings_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_csat_ratings_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_csat_ratings_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_csat_ratings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_education_types:{
			distinct_on:{
				type:"voyce_education_types_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_education_types_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_education_types_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_education_types_aggregate:{
			distinct_on:{
				type:"voyce_education_types_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_education_types_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_education_types_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_education_types_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_education_types_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_education_types_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_education_types_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_erp_login_roles:{
			distinct_on:{
				type:"voyce_erp_login_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_erp_login_roles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_erp_login_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_erp_login_roles_aggregate:{
			distinct_on:{
				type:"voyce_erp_login_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_erp_login_roles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_erp_login_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_erp_login_roles_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_erp_login_roles_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_erp_login_roles_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_erp_login_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_etl_series_views_logs:{
			distinct_on:{
				type:"voyce_etl_series_views_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_etl_series_views_logs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_etl_series_views_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_etl_series_views_logs_aggregate:{
			distinct_on:{
				type:"voyce_etl_series_views_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_etl_series_views_logs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_etl_series_views_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_etl_series_views_logs_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_etl_series_views_logs_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_etl_series_views_logs_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_etl_series_views_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_event_schedules:{
			distinct_on:{
				type:"voyce_event_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_event_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_event_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_event_schedules_aggregate:{
			distinct_on:{
				type:"voyce_event_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_event_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_event_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_event_schedules_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_event_schedules_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_event_schedules_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_event_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_genres:{
			distinct_on:{
				type:"voyce_genres_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_genres_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_genres_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_genres_aggregate:{
			distinct_on:{
				type:"voyce_genres_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_genres_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_genres_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_genres_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_genres_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_genres_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_genres_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_guest_gtags:{
			distinct_on:{
				type:"voyce_guest_gtags_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_guest_gtags_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_guest_gtags_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_guest_gtags_aggregate:{
			distinct_on:{
				type:"voyce_guest_gtags_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_guest_gtags_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_guest_gtags_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_guest_gtags_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_guest_gtags_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_guest_gtags_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_guest_gtags_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_metabases:{
			distinct_on:{
				type:"voyce_metabases_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_metabases_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_metabases_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_metabases_access:{
			distinct_on:{
				type:"voyce_metabases_access_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_metabases_access_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_metabases_access_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_metabases_access_aggregate:{
			distinct_on:{
				type:"voyce_metabases_access_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_metabases_access_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_metabases_access_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_metabases_access_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_metabases_access_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_metabases_access_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_metabases_access_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_metabases_aggregate:{
			distinct_on:{
				type:"voyce_metabases_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_metabases_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_metabases_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_metabases_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_metabases_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_metabases_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_metabases_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_newsletter:{
			distinct_on:{
				type:"voyce_newsletter_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_newsletter_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_newsletter_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_newsletter_aggregate:{
			distinct_on:{
				type:"voyce_newsletter_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_newsletter_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_newsletter_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_newsletter_by_pk:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_newsletter_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_newsletter_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_newsletter_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_notification_series_settings:{
			distinct_on:{
				type:"voyce_notification_series_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_notification_series_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notification_series_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_notification_series_settings_aggregate:{
			distinct_on:{
				type:"voyce_notification_series_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_notification_series_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notification_series_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_notification_series_settings_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_notification_series_settings_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_notification_series_settings_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notification_series_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_notification_settings:{
			distinct_on:{
				type:"voyce_notification_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_notification_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notification_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_notification_settings_aggregate:{
			distinct_on:{
				type:"voyce_notification_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_notification_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notification_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_notification_settings_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_notification_settings_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_notification_settings_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notification_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_notifications:{
			distinct_on:{
				type:"voyce_notifications_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_notifications_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notifications_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_notifications_aggregate:{
			distinct_on:{
				type:"voyce_notifications_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_notifications_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notifications_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_notifications_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_notifications_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_notifications_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notifications_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_originals_feed:{
			distinct_on:{
				type:"voyce_originals_feed_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_originals_feed_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_originals_feed_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_originals_feed_aggregate:{
			distinct_on:{
				type:"voyce_originals_feed_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_originals_feed_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_originals_feed_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_originals_feed_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_originals_feed_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_originals_feed_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_page_banners:{
			distinct_on:{
				type:"voyce_page_banners_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_page_banners_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_page_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_page_banners_aggregate:{
			distinct_on:{
				type:"voyce_page_banners_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_page_banners_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_page_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_page_banners_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_page_banners_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_page_banners_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_page_banners_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_page_components:{
			distinct_on:{
				type:"voyce_page_components_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_page_components_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_page_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_page_components_aggregate:{
			distinct_on:{
				type:"voyce_page_components_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_page_components_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_page_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_page_components_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_page_components_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_page_components_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_page_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_pages:{
			distinct_on:{
				type:"voyce_pages_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_pages_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_pages_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_pages_aggregate:{
			distinct_on:{
				type:"voyce_pages_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_pages_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_pages_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_pages_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_pages_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_pages_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_pages_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_panels_comments:{
			distinct_on:{
				type:"voyce_panels_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_panels_comments_aggregate:{
			distinct_on:{
				type:"voyce_panels_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_panels_comments_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_panels_comments_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_panels_comments_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_panels_reactions:{
			distinct_on:{
				type:"voyce_panels_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_panels_reactions_aggregate:{
			distinct_on:{
				type:"voyce_panels_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_panels_reactions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_panels_reactions_count:{
			distinct_on:{
				type:"voyce_panels_reactions_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_reactions_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_panels_reactions_count_aggregate:{
			distinct_on:{
				type:"voyce_panels_reactions_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_reactions_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_panels_reactions_count_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_panels_reactions_count_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_panels_reactions_count_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_panels_reactions_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_panels_reactions_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_payments_to_creators:{
			distinct_on:{
				type:"voyce_payments_to_creators_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_payments_to_creators_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_payments_to_creators_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_payments_to_creators_aggregate:{
			distinct_on:{
				type:"voyce_payments_to_creators_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_payments_to_creators_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_payments_to_creators_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_payments_to_creators_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_payments_to_creators_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_payments_to_creators_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_payments_to_creators_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_pg_rs_series_views_count:{
			distinct_on:{
				type:"voyce_pg_rs_series_views_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_pg_rs_series_views_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_pg_rs_series_views_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_pg_rs_series_views_count_aggregate:{
			distinct_on:{
				type:"voyce_pg_rs_series_views_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_pg_rs_series_views_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_pg_rs_series_views_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_pg_rs_series_views_count_by_pk:{
			series_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_pg_rs_series_views_count_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_pg_rs_series_views_count_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_pg_rs_series_views_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls:{
			distinct_on:{
				type:"voyce_polls_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_aggregate:{
			distinct_on:{
				type:"voyce_polls_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_answers:{
			distinct_on:{
				type:"voyce_polls_answers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_answers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_answers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_answers_aggregate:{
			distinct_on:{
				type:"voyce_polls_answers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_answers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_answers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_answers_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_polls_answers_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_polls_answers_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_answers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_polls_comments:{
			distinct_on:{
				type:"voyce_polls_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_comments_aggregate:{
			distinct_on:{
				type:"voyce_polls_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_comments_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_polls_comments_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_polls_comments_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_reactions:{
			distinct_on:{
				type:"voyce_polls_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_reactions_aggregate:{
			distinct_on:{
				type:"voyce_polls_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_reactions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_polls_reactions_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_polls_reactions_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_polls_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_votes:{
			distinct_on:{
				type:"voyce_polls_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_votes_aggregate:{
			distinct_on:{
				type:"voyce_polls_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_polls_votes_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_polls_votes_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_polls_votes_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_popup_components:{
			distinct_on:{
				type:"voyce_popup_components_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_popup_components_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_popup_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_popup_components_aggregate:{
			distinct_on:{
				type:"voyce_popup_components_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_popup_components_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_popup_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_popup_components_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_popup_components_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_popup_components_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_popup_components_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_popup_emails:{
			distinct_on:{
				type:"voyce_popup_emails_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_popup_emails_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_popup_emails_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_popup_emails_aggregate:{
			distinct_on:{
				type:"voyce_popup_emails_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_popup_emails_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_popup_emails_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_popup_emails_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_popup_emails_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_popup_emails_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_popup_emails_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_posts:{
			distinct_on:{
				type:"voyce_posts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_posts_aggregate:{
			distinct_on:{
				type:"voyce_posts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_posts_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_posts_comments:{
			distinct_on:{
				type:"voyce_posts_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_posts_comments_aggregate:{
			distinct_on:{
				type:"voyce_posts_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_posts_comments_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_posts_comments_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_posts_comments_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_posts_reactions:{
			distinct_on:{
				type:"voyce_posts_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_posts_reactions_aggregate:{
			distinct_on:{
				type:"voyce_posts_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_posts_reactions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_posts_reactions_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_posts_reactions_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_posts_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_posts_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_quest_difficulties:{
			distinct_on:{
				type:"voyce_quest_difficulties_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_quest_difficulties_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_quest_difficulties_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_quest_difficulties_aggregate:{
			distinct_on:{
				type:"voyce_quest_difficulties_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_quest_difficulties_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_quest_difficulties_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_quest_difficulties_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_quest_difficulties_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_quest_difficulties_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_quest_difficulties_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_quests:{
			distinct_on:{
				type:"voyce_quests_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_quests_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_quests_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_quests_aggregate:{
			distinct_on:{
				type:"voyce_quests_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_quests_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_quests_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_quests_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_quests_old:{
			distinct_on:{
				type:"voyce_quests_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_quests_old_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_quests_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_quests_old_aggregate:{
			distinct_on:{
				type:"voyce_quests_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_quests_old_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_quests_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_quests_old_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_quests_old_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_quests_old_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_quests_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_quests_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_quests_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_quests_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_recommended_series:{
			distinct_on:{
				type:"voyce_recommended_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_recommended_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_recommended_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_recommended_series_aggregate:{
			distinct_on:{
				type:"voyce_recommended_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_recommended_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_recommended_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_recommended_series_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_recommended_series_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_recommended_series_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_recommended_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_release_schedules:{
			distinct_on:{
				type:"voyce_release_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_release_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_release_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_release_schedules_aggregate:{
			distinct_on:{
				type:"voyce_release_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_release_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_release_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_release_schedules_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_release_schedules_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_release_schedules_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_release_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_rewards:{
			distinct_on:{
				type:"voyce_rewards_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_rewards_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_rewards_aggregate:{
			distinct_on:{
				type:"voyce_rewards_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_rewards_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_rewards_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_rewards_leaderboard:{
			distinct_on:{
				type:"voyce_rewards_leaderboard_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_rewards_leaderboard_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_leaderboard_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_rewards_leaderboard_aggregate:{
			distinct_on:{
				type:"voyce_rewards_leaderboard_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_rewards_leaderboard_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_leaderboard_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_rewards_leaderboard_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_rewards_leaderboard_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_rewards_leaderboard_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_leaderboard_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_rewards_quest:{
			distinct_on:{
				type:"voyce_rewards_quest_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_rewards_quest_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_quest_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_rewards_quest_aggregate:{
			distinct_on:{
				type:"voyce_rewards_quest_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_rewards_quest_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_quest_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_rewards_quest_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_rewards_quest_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_rewards_quest_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_quest_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_rewards_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_rewards_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_sections:{
			distinct_on:{
				type:"voyce_sections_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_sections_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_sections_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_sections_aggregate:{
			distinct_on:{
				type:"voyce_sections_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_sections_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_sections_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_sections_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_sections_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_sections_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_sections_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series:{
			distinct_on:{
				type:"voyce_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_aggregate:{
			distinct_on:{
				type:"voyce_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_collaborators:{
			distinct_on:{
				type:"voyce_series_collaborators_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_collaborators_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_collaborators_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_collaborators_aggregate:{
			distinct_on:{
				type:"voyce_series_collaborators_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_collaborators_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_collaborators_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_collaborators_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_collaborators_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_collaborators_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_collaborators_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_donations:{
			distinct_on:{
				type:"voyce_series_donations_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_donations_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_donations_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_donations_aggregate:{
			distinct_on:{
				type:"voyce_series_donations_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_donations_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_donations_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_donations_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_donations_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_donations_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_donations_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_favorites:{
			distinct_on:{
				type:"voyce_series_favorites_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_favorites_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_favorites_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_favorites_aggregate:{
			distinct_on:{
				type:"voyce_series_favorites_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_favorites_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_favorites_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_favorites_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			series_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_favorites_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_favorites_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_favorites_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_featured_panels:{
			distinct_on:{
				type:"voyce_series_featured_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_featured_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_featured_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_featured_panels_aggregate:{
			distinct_on:{
				type:"voyce_series_featured_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_featured_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_featured_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_featured_panels_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_featured_panels_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_featured_panels_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_featured_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_genre:{
			distinct_on:{
				type:"voyce_series_genre_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_genre_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_genre_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_genre_aggregate:{
			distinct_on:{
				type:"voyce_series_genre_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_genre_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_genre_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_genre_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_genre_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_genre_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_genre_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_giveaway_participants:{
			distinct_on:{
				type:"voyce_series_giveaway_participants_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_giveaway_participants_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_giveaway_participants_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_giveaway_participants_aggregate:{
			distinct_on:{
				type:"voyce_series_giveaway_participants_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_giveaway_participants_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_giveaway_participants_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_giveaway_participants_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_giveaway_participants_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_giveaway_participants_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_giveaway_participants_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_hot_panels:{
			distinct_on:{
				type:"voyce_series_hot_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_hot_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_hot_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_hot_panels_aggregate:{
			distinct_on:{
				type:"voyce_series_hot_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_hot_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_hot_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_hot_panels_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_hot_panels_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_hot_panels_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_hot_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_layouts:{
			distinct_on:{
				type:"voyce_series_layouts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_layouts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_layouts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_layouts_aggregate:{
			distinct_on:{
				type:"voyce_series_layouts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_layouts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_layouts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_layouts_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_layouts_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_layouts_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_layouts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_leaderboard:{
			distinct_on:{
				type:"voyce_series_leaderboard_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_leaderboard_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_leaderboard_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_leaderboard_aggregate:{
			distinct_on:{
				type:"voyce_series_leaderboard_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_leaderboard_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_leaderboard_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_leaderboard_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_leaderboard_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_leaderboard_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_leaderboard_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_likes_count:{
			distinct_on:{
				type:"voyce_series_likes_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_likes_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_likes_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_likes_count_aggregate:{
			distinct_on:{
				type:"voyce_series_likes_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_likes_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_likes_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_likes_count_mat:{
			distinct_on:{
				type:"voyce_series_likes_count_mat_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_likes_count_mat_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_likes_count_mat_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_likes_count_mat_aggregate:{
			distinct_on:{
				type:"voyce_series_likes_count_mat_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_likes_count_mat_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_likes_count_mat_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_likes_count_mat_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_likes_count_mat_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_likes_count_mat_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_likes_count_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_likes_count_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_likes_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_lock_config:{
			distinct_on:{
				type:"voyce_series_lock_config_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_lock_config_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_lock_config_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_lock_config_aggregate:{
			distinct_on:{
				type:"voyce_series_lock_config_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_lock_config_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_lock_config_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_lock_config_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_lock_config_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_lock_config_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_lock_config_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_sponsorships_configuration:{
			distinct_on:{
				type:"voyce_series_sponsorships_configuration_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_sponsorships_configuration_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_sponsorships_configuration_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_sponsorships_configuration_aggregate:{
			distinct_on:{
				type:"voyce_series_sponsorships_configuration_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_sponsorships_configuration_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_sponsorships_configuration_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_sponsorships_configuration_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_sponsorships_configuration_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_sponsorships_configuration_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_sponsorships_configuration_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_subscription:{
			distinct_on:{
				type:"voyce_series_subscription_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_subscription_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_subscription_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_subscription_aggregate:{
			distinct_on:{
				type:"voyce_series_subscription_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_subscription_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_subscription_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_subscription_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_subscription_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_subscription_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_subscription_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_tags:{
			distinct_on:{
				type:"voyce_series_tags_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_tags_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_tags_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_tags_aggregate:{
			distinct_on:{
				type:"voyce_series_tags_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_tags_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_tags_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_tags_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_tags_junction:{
			distinct_on:{
				type:"voyce_series_tags_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_tags_junction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_tags_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_tags_junction_aggregate:{
			distinct_on:{
				type:"voyce_series_tags_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_tags_junction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_tags_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_tags_junction_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_tags_junction_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_tags_junction_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_tags_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_tags_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_tags_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_tags_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_top_comments:{
			distinct_on:{
				type:"voyce_series_top_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_top_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_top_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_top_comments_aggregate:{
			distinct_on:{
				type:"voyce_series_top_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_top_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_top_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_top_comments_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_top_comments_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_top_comments_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_top_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_top_likes:{
			distinct_on:{
				type:"voyce_series_top_likes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_top_likes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_top_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_top_likes_aggregate:{
			distinct_on:{
				type:"voyce_series_top_likes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_top_likes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_top_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_top_likes_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_top_likes_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_top_likes_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_top_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_top_views:{
			distinct_on:{
				type:"voyce_series_top_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_top_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_top_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_top_views_aggregate:{
			distinct_on:{
				type:"voyce_series_top_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_top_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_top_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_top_views_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_top_views_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_top_views_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_top_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_adjustment:{
			distinct_on:{
				type:"voyce_series_views_adjustment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_adjustment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_adjustment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_adjustment_aggregate:{
			distinct_on:{
				type:"voyce_series_views_adjustment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_adjustment_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_adjustment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_adjustment_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_views_adjustment_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_views_adjustment_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_adjustment_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count:{
			distinct_on:{
				type:"voyce_series_views_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_aggregate:{
			distinct_on:{
				type:"voyce_series_views_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_mat:{
			distinct_on:{
				type:"voyce_series_views_count_mat_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_count_mat_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_mat_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_mat_aggregate:{
			distinct_on:{
				type:"voyce_series_views_count_mat_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_count_mat_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_mat_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_mat_sep30:{
			distinct_on:{
				type:"voyce_series_views_count_mat_sep30_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_count_mat_sep30_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_mat_sep30_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_mat_sep30_aggregate:{
			distinct_on:{
				type:"voyce_series_views_count_mat_sep30_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_count_mat_sep30_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_mat_sep30_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_mat_sep30_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_views_count_mat_sep30_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_mat_sep30_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_mat_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_views_count_mat_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_mat_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_mat_v2:{
			distinct_on:{
				type:"voyce_series_views_count_mat_v2_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_count_mat_v2_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_mat_v2_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_mat_v2_aggregate:{
			distinct_on:{
				type:"voyce_series_views_count_mat_v2_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_views_count_mat_v2_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_mat_v2_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_mat_v2_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_views_count_mat_v2_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_mat_v2_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_views_count_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_views_count_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_views_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_votes:{
			distinct_on:{
				type:"voyce_series_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_votes_aggregate:{
			distinct_on:{
				type:"voyce_series_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_votes_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_votes_count:{
			distinct_on:{
				type:"voyce_series_votes_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_votes_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_votes_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_votes_count_aggregate:{
			distinct_on:{
				type:"voyce_series_votes_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_votes_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_votes_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_votes_count_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_votes_count_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_votes_count_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_votes_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_votes_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_votes_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_voting_schedules:{
			distinct_on:{
				type:"voyce_series_voting_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_voting_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_voting_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_voting_schedules_aggregate:{
			distinct_on:{
				type:"voyce_series_voting_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_voting_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_voting_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_series_voting_schedules_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_series_voting_schedules_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_series_voting_schedules_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_voting_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_sponsorships:{
			distinct_on:{
				type:"voyce_sponsorships_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_sponsorships_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_sponsorships_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_sponsorships_aggregate:{
			distinct_on:{
				type:"voyce_sponsorships_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_sponsorships_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_sponsorships_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_sponsorships_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_sponsorships_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_sponsorships_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_sponsorships_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_states:{
			distinct_on:{
				type:"voyce_states_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_states_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_states_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_states_aggregate:{
			distinct_on:{
				type:"voyce_states_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_states_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_states_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_states_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_states_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_states_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_states_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_stripe_payouts_setup:{
			distinct_on:{
				type:"voyce_stripe_payouts_setup_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_stripe_payouts_setup_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_stripe_payouts_setup_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_stripe_payouts_setup_aggregate:{
			distinct_on:{
				type:"voyce_stripe_payouts_setup_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_stripe_payouts_setup_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_stripe_payouts_setup_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_stripe_payouts_setup_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_stripe_payouts_setup_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_stripe_payouts_setup_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_stripe_payouts_setup_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio:{
			distinct_on:{
				type:"voyce_studio_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_aggregate:{
			distinct_on:{
				type:"voyce_studio_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_studio_followers:{
			distinct_on:{
				type:"voyce_studio_followers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_followers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_followers_aggregate:{
			distinct_on:{
				type:"voyce_studio_followers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_followers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_followers_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_studio_followers_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_studio_followers_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_series:{
			distinct_on:{
				type:"voyce_studio_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_series_aggregate:{
			distinct_on:{
				type:"voyce_studio_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_series_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_studio_series_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_studio_series_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_studio_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_views:{
			distinct_on:{
				type:"voyce_studio_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_views_aggregate:{
			distinct_on:{
				type:"voyce_studio_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_studio_views_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_studio_views_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_studio_views_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_system_settings:{
			distinct_on:{
				type:"voyce_system_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_system_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_system_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_system_settings_aggregate:{
			distinct_on:{
				type:"voyce_system_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_system_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_system_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_system_settings_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_system_settings_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_system_settings_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_system_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_top_genres:{
			distinct_on:{
				type:"voyce_top_genres_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_top_genres_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_top_genres_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_top_genres_aggregate:{
			distinct_on:{
				type:"voyce_top_genres_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_top_genres_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_top_genres_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_top_genres_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_top_genres_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_top_genres_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_transaction_logs:{
			distinct_on:{
				type:"voyce_transaction_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_transaction_logs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_transaction_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_transaction_logs_aggregate:{
			distinct_on:{
				type:"voyce_transaction_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_transaction_logs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_transaction_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_transaction_logs_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_transaction_logs_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_transaction_logs_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_transaction_logs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_url_redirects:{
			distinct_on:{
				type:"voyce_url_redirects_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_url_redirects_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_url_redirects_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_url_redirects_aggregate:{
			distinct_on:{
				type:"voyce_url_redirects_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_url_redirects_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_url_redirects_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_url_redirects_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_url_redirects_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_url_redirects_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_url_redirects_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_addresses:{
			distinct_on:{
				type:"voyce_user_addresses_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_addresses_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_addresses_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_addresses_aggregate:{
			distinct_on:{
				type:"voyce_user_addresses_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_addresses_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_addresses_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_addresses_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_addresses_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_addresses_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_addresses_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_blocks:{
			distinct_on:{
				type:"voyce_user_blocks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_blocks_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_blocks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_blocks_aggregate:{
			distinct_on:{
				type:"voyce_user_blocks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_blocks_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_blocks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_blocks_by_pk:{
			linked_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			user_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_blocks_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_blocks_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_blocks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_chapters_unlocked:{
			distinct_on:{
				type:"voyce_user_chapters_unlocked_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_chapters_unlocked_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_chapters_unlocked_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_chapters_unlocked_aggregate:{
			distinct_on:{
				type:"voyce_user_chapters_unlocked_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_chapters_unlocked_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_chapters_unlocked_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_chapters_unlocked_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_chapters_unlocked_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_chapters_unlocked_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_chapters_unlocked_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_contacts:{
			distinct_on:{
				type:"voyce_user_contacts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_contacts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_contacts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_contacts_aggregate:{
			distinct_on:{
				type:"voyce_user_contacts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_contacts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_contacts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_contacts_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_contacts_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_contacts_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_contacts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_csat_ratings:{
			distinct_on:{
				type:"voyce_user_csat_ratings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_csat_ratings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_csat_ratings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_csat_ratings_aggregate:{
			distinct_on:{
				type:"voyce_user_csat_ratings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_csat_ratings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_csat_ratings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_csat_ratings_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_csat_ratings_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_csat_ratings_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_csat_ratings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_education:{
			distinct_on:{
				type:"voyce_user_education_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_education_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_education_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_education_aggregate:{
			distinct_on:{
				type:"voyce_user_education_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_education_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_education_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_education_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_education_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_education_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_education_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_level:{
			distinct_on:{
				type:"voyce_user_level_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_level_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_level_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_level_aggregate:{
			distinct_on:{
				type:"voyce_user_level_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_level_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_level_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_level_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_level_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_level_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_level_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_linked_accounts:{
			distinct_on:{
				type:"voyce_user_linked_accounts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_linked_accounts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_linked_accounts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_linked_accounts_aggregate:{
			distinct_on:{
				type:"voyce_user_linked_accounts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_linked_accounts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_linked_accounts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_linked_accounts_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_linked_accounts_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_linked_accounts_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_linked_accounts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_login_history:{
			distinct_on:{
				type:"voyce_user_login_history_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_login_history_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_login_history_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_login_history_aggregate:{
			distinct_on:{
				type:"voyce_user_login_history_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_login_history_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_login_history_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_login_history_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_login_history_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_login_history_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_login_history_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quest_ids:{
			distinct_on:{
				type:"voyce_user_quest_ids_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quest_ids_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quest_ids_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quest_ids_aggregate:{
			distinct_on:{
				type:"voyce_user_quest_ids_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quest_ids_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quest_ids_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quest_ids_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_quest_ids_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_quest_ids_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quest_ids_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quest_rewards:{
			distinct_on:{
				type:"voyce_user_quest_rewards_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quest_rewards_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quest_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quest_rewards_aggregate:{
			distinct_on:{
				type:"voyce_user_quest_rewards_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quest_rewards_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quest_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quest_rewards_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_quest_rewards_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_quest_rewards_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quest_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quests:{
			distinct_on:{
				type:"voyce_user_quests_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quests_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quests_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quests_aggregate:{
			distinct_on:{
				type:"voyce_user_quests_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quests_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quests_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quests_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_quests_old:{
			distinct_on:{
				type:"voyce_user_quests_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quests_old_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quests_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quests_old_aggregate:{
			distinct_on:{
				type:"voyce_user_quests_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quests_old_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quests_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quests_old_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_quests_old_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_quests_old_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quests_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_quests_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_quests_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quests_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_ranking:{
			distinct_on:{
				type:"voyce_user_ranking_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_ranking_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_ranking_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_ranking_aggregate:{
			distinct_on:{
				type:"voyce_user_ranking_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_ranking_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_ranking_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_ranking_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_ranking_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_ranking_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_ranking_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_reviews:{
			distinct_on:{
				type:"voyce_user_reviews_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_reviews_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_reviews_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_reviews_aggregate:{
			distinct_on:{
				type:"voyce_user_reviews_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_reviews_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_reviews_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_reviews_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_reviews_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_reviews_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_reviews_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_rewards:{
			distinct_on:{
				type:"voyce_user_rewards_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_rewards_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_rewards_aggregate:{
			distinct_on:{
				type:"voyce_user_rewards_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_rewards_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_rewards_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_rewards_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_rewards_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_rewards_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_roles:{
			distinct_on:{
				type:"voyce_user_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_roles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_roles_aggregate:{
			distinct_on:{
				type:"voyce_user_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_roles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_roles_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_roles_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_roles_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_roles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_series_subscription_count:{
			distinct_on:{
				type:"voyce_user_series_subscription_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_series_subscription_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_series_subscription_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_series_subscription_count_aggregate:{
			distinct_on:{
				type:"voyce_user_series_subscription_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_series_subscription_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_series_subscription_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_series_subscription_count_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_series_subscription_count_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_series_subscription_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_settings:{
			distinct_on:{
				type:"voyce_user_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_settings_aggregate:{
			distinct_on:{
				type:"voyce_user_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_settings_by_pk:{
			user_id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_settings_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_settings_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_skills:{
			distinct_on:{
				type:"voyce_user_skills_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_skills_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_skills_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_skills_aggregate:{
			distinct_on:{
				type:"voyce_user_skills_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_skills_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_skills_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_skills_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_skills_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_skills_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_skills_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_socials:{
			distinct_on:{
				type:"voyce_user_socials_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_socials_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_socials_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_socials_aggregate:{
			distinct_on:{
				type:"voyce_user_socials_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_socials_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_socials_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_socials_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_socials_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_socials_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_socials_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_soft_deleted:{
			distinct_on:{
				type:"voyce_user_soft_deleted_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_soft_deleted_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_soft_deleted_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_soft_deleted_aggregate:{
			distinct_on:{
				type:"voyce_user_soft_deleted_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_soft_deleted_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_soft_deleted_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_soft_deleted_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_soft_deleted_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_soft_deleted_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_soft_deleted_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_vtags:{
			distinct_on:{
				type:"voyce_user_vtags_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_vtags_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_vtags_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_vtags_aggregate:{
			distinct_on:{
				type:"voyce_user_vtags_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_vtags_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_vtags_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_vtags_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_vtags_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_vtags_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_vtags_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_wins:{
			distinct_on:{
				type:"voyce_user_wins_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_wins_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_wins_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_wins_aggregate:{
			distinct_on:{
				type:"voyce_user_wins_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_wins_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_wins_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_user_wins_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_user_wins_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_user_wins_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_wins_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users:{
			distinct_on:{
				type:"voyce_users_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_aggregate:{
			distinct_on:{
				type:"voyce_users_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_users_followers:{
			distinct_on:{
				type:"voyce_users_followers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_followers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_followers_aggregate:{
			distinct_on:{
				type:"voyce_users_followers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_followers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_followers_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_users_followers_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_users_followers_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_private:{
			distinct_on:{
				type:"voyce_users_private_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_private_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_private_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_private_aggregate:{
			distinct_on:{
				type:"voyce_users_private_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_private_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_private_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_private_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_users_private_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_private_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_series_junction:{
			distinct_on:{
				type:"voyce_users_series_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_series_junction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_series_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_series_junction_aggregate:{
			distinct_on:{
				type:"voyce_users_series_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_series_junction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_series_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_series_junction_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_users_series_junction_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_users_series_junction_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_series_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_users_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_users_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_versions:{
			distinct_on:{
				type:"voyce_versions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_versions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_versions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_versions_aggregate:{
			distinct_on:{
				type:"voyce_versions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_versions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_versions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_versions_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_versions_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_versions_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_versions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_analytics:{
			distinct_on:{
				type:"voyce_voting_analytics_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_analytics_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_analytics_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_analytics_aggregate:{
			distinct_on:{
				type:"voyce_voting_analytics_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_analytics_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_analytics_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_analytics_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_voting_analytics_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_voting_analytics_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_analytics_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_panels:{
			distinct_on:{
				type:"voyce_voting_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_panels_aggregate:{
			distinct_on:{
				type:"voyce_voting_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_panels_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_voting_panels_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_voting_panels_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_series:{
			distinct_on:{
				type:"voyce_voting_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_series_aggregate:{
			distinct_on:{
				type:"voyce_voting_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_series_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_voting_series_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_voting_series_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_user_votes:{
			distinct_on:{
				type:"voyce_voting_user_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_user_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_user_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_user_votes_aggregate:{
			distinct_on:{
				type:"voyce_voting_user_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_user_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_user_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voyce_voting_user_votes_by_pk:{
			id:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		voyce_voting_user_votes_stream:{
			batch_size:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			cursor:{
				type:"voyce_voting_user_votes_stream_cursor_input",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_user_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	time: "String",
	time_comparison_exp:{
		_eq:{
			type:"time",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"time",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"time",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"time",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"time",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"time",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"time",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"time",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	timestamp: "String",
	timestamp_comparison_exp:{
		_eq:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"timestamp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"timestamp",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	timestamptz: "String",
	timestamptz_comparison_exp:{
		_eq:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"timestamptz",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"timestamptz",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	uuid: "String",
	uuid_comparison_exp:{
		_eq:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"uuid",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"uuid",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	voyce_admin_roles_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_admin_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_admin_roles_bool_exp:{
		_and:{
			type:"voyce_admin_roles_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_admin_roles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_admin_roles_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		role:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_admin_roles_constraint: "enum",
	voyce_admin_roles_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_admin_roles_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_admin_roles_obj_rel_insert_input:{
		data:{
			type:"voyce_admin_roles_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_admin_roles_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_admin_roles_on_conflict:{
		constraint:{
			type:"voyce_admin_roles_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_admin_roles_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_admin_roles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_admin_roles_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_admin_roles_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_admin_roles_select_column: "enum",
	voyce_admin_roles_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_admin_roles_stream_cursor_input:{
		initial_value:{
			type:"voyce_admin_roles_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_admin_roles_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_admin_roles_update_column: "enum",
	voyce_admin_roles_updates:{
		_inc:{
			type:"voyce_admin_roles_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_admin_roles_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_admin_roles_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_app_banners_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_app_banners_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_app_banners_bool_exp:{
		_and:{
			type:"voyce_app_banners_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_app_banners_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_app_banners_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		active:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		background:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		overlap:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		screen:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablet:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_banners_constraint: "enum",
	voyce_app_banners_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_banners_insert_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		background:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		overlap:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		screen:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablet:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_banners_on_conflict:{
		constraint:{
			type:"voyce_app_banners_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_app_banners_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_app_banners_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_banners_order_by:{
		active:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		background:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		overlap:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		screen:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablet:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_banners_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_app_banners_select_column: "enum",
	voyce_app_banners_set_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		background:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		overlap:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		screen:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablet:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_banners_stream_cursor_input:{
		initial_value:{
			type:"voyce_app_banners_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_banners_stream_cursor_value_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		background:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		overlap:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		screen:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablet:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_banners_update_column: "enum",
	voyce_app_banners_updates:{
		_inc:{
			type:"voyce_app_banners_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_app_banners_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_app_banners_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_app_components:{
		props:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_app_components_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_app_components_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_app_components_append_input:{
		props:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_components_bool_exp:{
		_and:{
			type:"voyce_app_components_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_app_components_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_app_components_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		active:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		auth:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		component:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		device:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"jsonb_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		screen:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_components_constraint: "enum",
	voyce_app_components_delete_at_path_input:{
		props:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	voyce_app_components_delete_elem_input:{
		props:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_components_delete_key_input:{
		props:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_components_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_components_insert_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		auth:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		component:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		device:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		screen:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_components_on_conflict:{
		constraint:{
			type:"voyce_app_components_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_app_components_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_app_components_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_components_order_by:{
		active:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		auth:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		component:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		device:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		screen:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_components_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_app_components_prepend_input:{
		props:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_components_select_column: "enum",
	voyce_app_components_set_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		auth:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		component:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		device:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		screen:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_components_stream_cursor_input:{
		initial_value:{
			type:"voyce_app_components_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_components_stream_cursor_value_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		auth:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		component:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		device:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		screen:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_app_components_update_column: "enum",
	voyce_app_components_updates:{
		_append:{
			type:"voyce_app_components_append_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_delete_at_path:{
			type:"voyce_app_components_delete_at_path_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_delete_elem:{
			type:"voyce_app_components_delete_elem_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_delete_key:{
			type:"voyce_app_components_delete_key_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_inc:{
			type:"voyce_app_components_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_prepend:{
			type:"voyce_app_components_prepend_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_app_components_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_app_components_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_audit_trail:{
		payload:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_audit_trail_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_audit_trail_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_audit_trail_bool_exp:{
		_and:{
			type:"voyce_audit_trail_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_audit_trail_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_audit_trail_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		action:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		module:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		payload:{
			type:"json_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_audit_trail_constraint: "enum",
	voyce_audit_trail_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_audit_trail_insert_input:{
		action:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		module:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		payload:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_audit_trail_on_conflict:{
		constraint:{
			type:"voyce_audit_trail_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_audit_trail_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_audit_trail_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_audit_trail_order_by:{
		action:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		module:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		payload:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_audit_trail_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_audit_trail_select_column: "enum",
	voyce_audit_trail_set_input:{
		action:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		module:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		payload:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_audit_trail_stream_cursor_input:{
		initial_value:{
			type:"voyce_audit_trail_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_audit_trail_stream_cursor_value_input:{
		action:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		module:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		payload:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_audit_trail_update_column: "enum",
	voyce_audit_trail_updates:{
		_inc:{
			type:"voyce_audit_trail_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_audit_trail_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_audit_trail_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_author_profiles_aggregate_bool_exp:{
		bool_and:{
			type:"voyce_author_profiles_aggregate_bool_exp_bool_and",
			array:false,
			arrayRequired:false,
			required:false
		},
		bool_or:{
			type:"voyce_author_profiles_aggregate_bool_exp_bool_or",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"voyce_author_profiles_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_aggregate_bool_exp_bool_and:{
		arguments:{
			type:"voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_and_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_author_profiles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_author_profiles_aggregate_bool_exp_bool_or:{
		arguments:{
			type:"voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_or_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_author_profiles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_author_profiles_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_author_profiles_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_author_profiles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_author_profiles_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_author_profiles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_author_profiles_aggregate_order_by:{
		avg:{
			type:"voyce_author_profiles_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_author_profiles_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_author_profiles_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_author_profiles_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_author_profiles_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_author_profiles_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_author_profiles_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_author_profiles_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_author_profiles_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_author_profiles_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_arr_rel_insert_input:{
		data:{
			type:"voyce_author_profiles_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_author_profiles_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_avg_order_by:{
		followers_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_bool_exp:{
		_and:{
			type:"voyce_author_profiles_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_author_profiles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_author_profiles_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_constraint: "enum",
	voyce_author_profiles_inc_input:{
		followers_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_insert_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_max_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_min_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_obj_rel_insert_input:{
		data:{
			type:"voyce_author_profiles_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_author_profiles_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_on_conflict:{
		constraint:{
			type:"voyce_author_profiles_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_author_profiles_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_author_profiles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_author_profiles_select_column: "enum",
	voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_and_arguments_columns: "enum",
	voyce_author_profiles_select_column_voyce_author_profiles_aggregate_bool_exp_bool_or_arguments_columns: "enum",
	voyce_author_profiles_set_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_stddev_order_by:{
		followers_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_stddev_pop_order_by:{
		followers_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_stddev_samp_order_by:{
		followers_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_stream_cursor_input:{
		initial_value:{
			type:"voyce_author_profiles_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_stream_cursor_value_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_sum_order_by:{
		followers_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_update_column: "enum",
	voyce_author_profiles_updates:{
		_inc:{
			type:"voyce_author_profiles_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_author_profiles_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_author_profiles_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_author_profiles_var_pop_order_by:{
		followers_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_var_samp_order_by:{
		followers_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_author_profiles_variance_order_by:{
		followers_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_balances_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_balances_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_balances_bool_exp:{
		_and:{
			type:"voyce_balances_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_balances_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_balances_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		amount:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		transferable_amount:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_balances_constraint: "enum",
	voyce_balances_inc_input:{
		amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		transferable_amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_balances_insert_input:{
		amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		transferable_amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_balances_on_conflict:{
		constraint:{
			type:"voyce_balances_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_balances_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_balances_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_balances_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		transferable_amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_balances_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_balances_select_column: "enum",
	voyce_balances_set_input:{
		amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		transferable_amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_balances_stream_cursor_input:{
		initial_value:{
			type:"voyce_balances_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_balances_stream_cursor_value_input:{
		amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		transferable_amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_balances_update_column: "enum",
	voyce_balances_updates:{
		_inc:{
			type:"voyce_balances_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_balances_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_balances_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_banners_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_banners_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_banners_bool_exp:{
		_and:{
			type:"voyce_banners_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_banners_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_banners_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		back_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		front_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		subtitle:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_banners_constraint: "enum",
	voyce_banners_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_banners_insert_input:{
		back_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		front_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		subtitle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_banners_on_conflict:{
		constraint:{
			type:"voyce_banners_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_banners_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_banners_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_banners_order_by:{
		back_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		front_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subtitle:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_banners_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_banners_select_column: "enum",
	voyce_banners_set_input:{
		back_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		front_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		subtitle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_banners_stream_cursor_input:{
		initial_value:{
			type:"voyce_banners_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_banners_stream_cursor_value_input:{
		back_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		front_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		subtitle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_banners_update_column: "enum",
	voyce_banners_updates:{
		_inc:{
			type:"voyce_banners_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_banners_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_banners_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_assets_aggregate_bool_exp:{
		count:{
			type:"voyce_chapter_assets_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_chapter_assets_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_assets_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_assets_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_assets_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_assets_aggregate_order_by:{
		avg:{
			type:"voyce_chapter_assets_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_chapter_assets_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_chapter_assets_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_chapter_assets_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_chapter_assets_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_chapter_assets_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_chapter_assets_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_chapter_assets_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_chapter_assets_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_chapter_assets_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_arr_rel_insert_input:{
		data:{
			type:"voyce_chapter_assets_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_assets_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_avg_order_by:{
		chapter_effect_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_bool_exp:{
		_and:{
			type:"voyce_chapter_assets_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_assets_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_assets_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter_effect:{
			type:"voyce_chapter_effects_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_effect_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_constraint: "enum",
	voyce_chapter_assets_inc_input:{
		chapter_effect_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_insert_input:{
		chapter_effect:{
			type:"voyce_chapter_effects_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_effect_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_max_order_by:{
		chapter_effect_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_min_order_by:{
		chapter_effect_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_on_conflict:{
		constraint:{
			type:"voyce_chapter_assets_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_assets_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_assets_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_order_by:{
		chapter_effect:{
			type:"voyce_chapter_effects_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_effect_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_assets_select_column: "enum",
	voyce_chapter_assets_set_input:{
		chapter_effect_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_stddev_order_by:{
		chapter_effect_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_stddev_pop_order_by:{
		chapter_effect_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_stddev_samp_order_by:{
		chapter_effect_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_assets_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_stream_cursor_value_input:{
		chapter_effect_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_sum_order_by:{
		chapter_effect_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_update_column: "enum",
	voyce_chapter_assets_updates:{
		_inc:{
			type:"voyce_chapter_assets_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_assets_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_assets_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_assets_var_pop_order_by:{
		chapter_effect_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_var_samp_order_by:{
		chapter_effect_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_assets_variance_order_by:{
		chapter_effect_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_bookmarks_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_bookmarks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_bookmarks_bool_exp:{
		_and:{
			type:"voyce_chapter_bookmarks_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_bookmarks_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_bookmarks_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_bookmarks_constraint: "enum",
	voyce_chapter_bookmarks_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_bookmarks_insert_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_bookmarks_on_conflict:{
		constraint:{
			type:"voyce_chapter_bookmarks_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_bookmarks_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_bookmarks_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_bookmarks_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_bookmarks_pk_columns_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_bookmarks_select_column: "enum",
	voyce_chapter_bookmarks_set_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_bookmarks_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_bookmarks_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_bookmarks_stream_cursor_value_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_bookmarks_update_column: "enum",
	voyce_chapter_bookmarks_updates:{
		_inc:{
			type:"voyce_chapter_bookmarks_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_bookmarks_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_bookmarks_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_comments:{
		chapter_comments:{
			distinct_on:{
				type:"voyce_chapter_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_comments_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_comments_reactions:{
			distinct_on:{
				type:"voyce_chapter_comments_reaction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reaction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reaction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_comments_reactions_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_reaction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reaction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reaction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_comments_reports:{
			distinct_on:{
				type:"voyce_chapter_comments_reports_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reports_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reports_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_comments_reports_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_reports_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reports_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reports_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		reactions:{
			distinct_on:{
				type:"voyce_chapter_comments_reaction_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reaction_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reaction_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		reactions_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_reaction_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reaction_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reaction_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_comments_07252024_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_comments_07252024_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_comments_07252024_bool_exp:{
		_and:{
			type:"voyce_chapter_comments_07252024_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_comments_07252024_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_comments_07252024_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		animated_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachment_url:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_07252024_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_07252024_insert_input:{
		animated_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachment_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_07252024_order_by:{
		animated_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachment_url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_07252024_select_column: "enum",
	voyce_chapter_comments_07252024_set_input:{
		animated_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachment_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_07252024_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_comments_07252024_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_07252024_stream_cursor_value_input:{
		animated_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachment_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_07252024_updates:{
		_inc:{
			type:"voyce_chapter_comments_07252024_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_comments_07252024_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_comments_07252024_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_comments_aggregate_bool_exp:{
		count:{
			type:"voyce_chapter_comments_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_chapter_comments_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_comments_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_comments_aggregate_order_by:{
		avg:{
			type:"voyce_chapter_comments_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_chapter_comments_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_chapter_comments_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_chapter_comments_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_chapter_comments_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_chapter_comments_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_chapter_comments_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_chapter_comments_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_chapter_comments_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_chapter_comments_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_arr_rel_insert_input:{
		data:{
			type:"voyce_chapter_comments_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_comments_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_avg_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_bool_exp:{
		_and:{
			type:"voyce_chapter_comments_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_comments_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		animated_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachment_url:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment:{
			type:"voyce_chapter_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments:{
			type:"voyce_chapter_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_aggregate:{
			type:"voyce_chapter_comments_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_reactions:{
			type:"voyce_chapter_comments_reaction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_reactions_aggregate:{
			type:"voyce_chapter_comments_reaction_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_reports:{
			type:"voyce_chapter_comments_reports_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_reports_aggregate:{
			type:"voyce_chapter_comments_reports_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions:{
			type:"voyce_chapter_comments_reaction_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_aggregate:{
			type:"voyce_chapter_comments_reaction_count_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_constraint: "enum",
	voyce_chapter_comments_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_insert_input:{
		animated_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachment_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter:{
			type:"voyce_chapters_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment:{
			type:"voyce_chapter_comments_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments:{
			type:"voyce_chapter_comments_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_reactions:{
			type:"voyce_chapter_comments_reaction_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_reports:{
			type:"voyce_chapter_comments_reports_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions:{
			type:"voyce_chapter_comments_reaction_count_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_max_order_by:{
		animated_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachment_url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_min_order_by:{
		animated_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachment_url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_obj_rel_insert_input:{
		data:{
			type:"voyce_chapter_comments_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_comments_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_on_conflict:{
		constraint:{
			type:"voyce_chapter_comments_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_comments_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_order_by:{
		animated_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachment_url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter:{
			type:"voyce_chapters_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment:{
			type:"voyce_chapter_comments_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_aggregate:{
			type:"voyce_chapter_comments_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_reactions_aggregate:{
			type:"voyce_chapter_comments_reaction_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_reports_aggregate:{
			type:"voyce_chapter_comments_reports_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_aggregate:{
			type:"voyce_chapter_comments_reaction_count_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_comments_reaction_aggregate_bool_exp:{
		count:{
			type:"voyce_chapter_comments_reaction_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_chapter_comments_reaction_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_comments_reaction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_comments_reaction_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_comments_reaction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_comments_reaction_aggregate_order_by:{
		avg:{
			type:"voyce_chapter_comments_reaction_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_chapter_comments_reaction_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_chapter_comments_reaction_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_chapter_comments_reaction_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_chapter_comments_reaction_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_chapter_comments_reaction_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_chapter_comments_reaction_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_chapter_comments_reaction_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_chapter_comments_reaction_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_chapter_comments_reaction_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_arr_rel_insert_input:{
		data:{
			type:"voyce_chapter_comments_reaction_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_comments_reaction_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_avg_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_bool_exp:{
		_and:{
			type:"voyce_chapter_comments_reaction_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_comments_reaction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_comments_reaction_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter_comment:{
			type:"voyce_chapter_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_constraint: "enum",
	voyce_chapter_comments_reaction_count_aggregate_bool_exp:{
		count:{
			type:"voyce_chapter_comments_reaction_count_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_chapter_comments_reaction_count_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_comments_reaction_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_comments_reaction_count_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_comments_reaction_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_comments_reaction_count_aggregate_order_by:{
		avg:{
			type:"voyce_chapter_comments_reaction_count_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_chapter_comments_reaction_count_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_chapter_comments_reaction_count_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_chapter_comments_reaction_count_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_chapter_comments_reaction_count_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_chapter_comments_reaction_count_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_chapter_comments_reaction_count_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_chapter_comments_reaction_count_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_chapter_comments_reaction_count_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_chapter_comments_reaction_count_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_arr_rel_insert_input:{
		data:{
			type:"voyce_chapter_comments_reaction_count_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_comments_reaction_count_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_avg_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_bool_exp:{
		_and:{
			type:"voyce_chapter_comments_reaction_count_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_comments_reaction_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_comments_reaction_count_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		comment_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_constraint: "enum",
	voyce_chapter_comments_reaction_count_inc_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_insert_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_max_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_min_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_on_conflict:{
		constraint:{
			type:"voyce_chapter_comments_reaction_count_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_comments_reaction_count_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_comments_reaction_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_comments_reaction_count_select_column: "enum",
	voyce_chapter_comments_reaction_count_set_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_stddev_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_stddev_pop_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_stddev_samp_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_comments_reaction_count_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_stream_cursor_value_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_sum_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_update_column: "enum",
	voyce_chapter_comments_reaction_count_updates:{
		_inc:{
			type:"voyce_chapter_comments_reaction_count_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_comments_reaction_count_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_comments_reaction_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_comments_reaction_count_var_pop_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_var_samp_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_count_variance_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_inc_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_insert_input:{
		chapter_comment:{
			type:"voyce_chapter_comments_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_max_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_min_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_on_conflict:{
		constraint:{
			type:"voyce_chapter_comments_reaction_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_comments_reaction_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_comments_reaction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_order_by:{
		chapter_comment:{
			type:"voyce_chapter_comments_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_comments_reaction_select_column: "enum",
	voyce_chapter_comments_reaction_set_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_stddev_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_stddev_pop_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_stddev_samp_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_comments_reaction_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_stream_cursor_value_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_sum_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_update_column: "enum",
	voyce_chapter_comments_reaction_updates:{
		_inc:{
			type:"voyce_chapter_comments_reaction_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_comments_reaction_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_comments_reaction_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_comments_reaction_var_pop_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_var_samp_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reaction_variance_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_aggregate_bool_exp:{
		count:{
			type:"voyce_chapter_comments_reports_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_chapter_comments_reports_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_comments_reports_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_comments_reports_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_comments_reports_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_comments_reports_aggregate_order_by:{
		avg:{
			type:"voyce_chapter_comments_reports_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_chapter_comments_reports_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_chapter_comments_reports_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_chapter_comments_reports_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_chapter_comments_reports_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_chapter_comments_reports_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_chapter_comments_reports_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_chapter_comments_reports_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_chapter_comments_reports_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_chapter_comments_reports_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_arr_rel_insert_input:{
		data:{
			type:"voyce_chapter_comments_reports_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_comments_reports_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_avg_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_bool_exp:{
		_and:{
			type:"voyce_chapter_comments_reports_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_comments_reports_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_comments_reports_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter_comment:{
			type:"voyce_chapter_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_constraint: "enum",
	voyce_chapter_comments_reports_inc_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_insert_input:{
		chapter_comment:{
			type:"voyce_chapter_comments_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_max_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_min_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_on_conflict:{
		constraint:{
			type:"voyce_chapter_comments_reports_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_comments_reports_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_comments_reports_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_order_by:{
		chapter_comment:{
			type:"voyce_chapter_comments_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_comments_reports_select_column: "enum",
	voyce_chapter_comments_reports_set_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_stddev_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_stddev_pop_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_stddev_samp_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_comments_reports_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_stream_cursor_value_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_sum_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_update_column: "enum",
	voyce_chapter_comments_reports_updates:{
		_inc:{
			type:"voyce_chapter_comments_reports_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_comments_reports_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_comments_reports_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_comments_reports_var_pop_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_var_samp_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_reports_variance_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_select_column: "enum",
	voyce_chapter_comments_set_input:{
		animated_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachment_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_stddev_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_stddev_pop_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_stddev_samp_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_comments_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_stream_cursor_value_input:{
		animated_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attachment_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_sum_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_update_column: "enum",
	voyce_chapter_comments_updates:{
		_inc:{
			type:"voyce_chapter_comments_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_comments_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_comments_var_pop_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_var_samp_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_comments_variance_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		original_comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_contents_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_contents_bool_exp:{
		_and:{
			type:"voyce_chapter_contents_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_contents_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_contents_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		content_short:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content_old:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_constraint: "enum",
	voyce_chapter_contents_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_insert_input:{
		chapter:{
			type:"voyce_chapters_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		content_short:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content_old:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_obj_rel_insert_input:{
		data:{
			type:"voyce_chapter_contents_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_contents_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_old_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_contents_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_contents_old_bool_exp:{
		_and:{
			type:"voyce_chapter_contents_old_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_contents_old_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_contents_old_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content_old:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_old_constraint: "enum",
	voyce_chapter_contents_old_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_old_insert_input:{
		chapter:{
			type:"voyce_chapters_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content_old:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_old_on_conflict:{
		constraint:{
			type:"voyce_chapter_contents_old_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_contents_old_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_contents_old_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_old_order_by:{
		chapter:{
			type:"voyce_chapters_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content_old:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_old_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_contents_old_select_column: "enum",
	voyce_chapter_contents_old_set_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content_old:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_old_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_contents_old_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_old_stream_cursor_value_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content_old:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_old_update_column: "enum",
	voyce_chapter_contents_old_updates:{
		_inc:{
			type:"voyce_chapter_contents_old_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_contents_old_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_contents_old_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_contents_on_conflict:{
		constraint:{
			type:"voyce_chapter_contents_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_contents_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_contents_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_order_by:{
		chapter:{
			type:"voyce_chapters_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		content_short:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content_old:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_contents_select_column: "enum",
	voyce_chapter_contents_set_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		content_short:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content_old:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_contents_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_stream_cursor_value_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		content_short:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile_content_old:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_contents_update_column: "enum",
	voyce_chapter_contents_updates:{
		_inc:{
			type:"voyce_chapter_contents_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_contents_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_contents_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_effects:{
		chapter_assets:{
			distinct_on:{
				type:"voyce_chapter_assets_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_assets_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_assets_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_assets_aggregate:{
			distinct_on:{
				type:"voyce_chapter_assets_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_assets_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_assets_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_effects_aggregate_bool_exp:{
		count:{
			type:"voyce_chapter_effects_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_chapter_effects_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_effects_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_effects_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_effects_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_effects_aggregate_order_by:{
		avg:{
			type:"voyce_chapter_effects_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_chapter_effects_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_chapter_effects_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_chapter_effects_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_chapter_effects_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_chapter_effects_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_chapter_effects_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_chapter_effects_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_chapter_effects_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_chapter_effects_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_arr_rel_insert_input:{
		data:{
			type:"voyce_chapter_effects_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_effects_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_avg_order_by:{
		animation_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_img_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		effect_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_x:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_y:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		strength:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_bool_exp:{
		_and:{
			type:"voyce_chapter_effects_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_effects_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_effects_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		animation_offset:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_assets:{
			type:"voyce_chapter_assets_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_assets_aggregate:{
			type:"voyce_chapter_assets_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_image:{
			type:"voyce_chapter_images_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_img_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		effect_type:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_x:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_y:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		strength:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_constraint: "enum",
	voyce_chapter_effects_inc_input:{
		animation_offset:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_img_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		effect_type:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_x:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_y:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		strength:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_insert_input:{
		animation_offset:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter:{
			type:"voyce_chapters_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_assets:{
			type:"voyce_chapter_assets_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_image:{
			type:"voyce_chapter_images_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_img_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		effect_type:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_x:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_y:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		strength:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_max_order_by:{
		animation_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_img_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		effect_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_x:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_y:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		strength:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_min_order_by:{
		animation_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_img_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		effect_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_x:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_y:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		strength:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_obj_rel_insert_input:{
		data:{
			type:"voyce_chapter_effects_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_effects_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_on_conflict:{
		constraint:{
			type:"voyce_chapter_effects_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_effects_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_effects_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_order_by:{
		animation_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter:{
			type:"voyce_chapters_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_assets_aggregate:{
			type:"voyce_chapter_assets_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_image:{
			type:"voyce_chapter_images_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_img_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		effect_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_x:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_y:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		strength:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_effects_select_column: "enum",
	voyce_chapter_effects_set_input:{
		animation_offset:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_img_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		effect_type:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_x:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_y:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		strength:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_stddev_order_by:{
		animation_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_img_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		effect_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_x:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_y:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		strength:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_stddev_pop_order_by:{
		animation_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_img_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		effect_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_x:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_y:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		strength:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_stddev_samp_order_by:{
		animation_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_img_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		effect_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_x:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_y:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		strength:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_effects_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_stream_cursor_value_input:{
		animation_offset:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_img_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		effect_type:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_x:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_y:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		strength:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_sum_order_by:{
		animation_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_img_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		effect_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_x:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_y:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		strength:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_update_column: "enum",
	voyce_chapter_effects_updates:{
		_inc:{
			type:"voyce_chapter_effects_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_effects_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_effects_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_effects_var_pop_order_by:{
		animation_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_img_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		effect_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_x:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_y:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		strength:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_var_samp_order_by:{
		animation_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_img_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		effect_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_x:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_y:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		strength:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_effects_variance_order_by:{
		animation_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_img_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		effect_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_x:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_y:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		strength:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images:{
		chapter_effects:{
			distinct_on:{
				type:"voyce_chapter_effects_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_effects_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_effects_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_effects_aggregate:{
			distinct_on:{
				type:"voyce_chapter_effects_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_effects_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_effects_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_panels:{
			distinct_on:{
				type:"voyce_chapter_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_panels_aggregate:{
			distinct_on:{
				type:"voyce_chapter_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_images_07252024_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_images_07252024_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_images_07252024_bool_exp:{
		_and:{
			type:"voyce_chapter_images_07252024_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_images_07252024_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_images_07252024_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_07252024_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_07252024_insert_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_07252024_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_07252024_select_column: "enum",
	voyce_chapter_images_07252024_set_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_07252024_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_images_07252024_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_07252024_stream_cursor_value_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_07252024_updates:{
		_inc:{
			type:"voyce_chapter_images_07252024_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_images_07252024_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_images_07252024_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_images_aggregate_bool_exp:{
		count:{
			type:"voyce_chapter_images_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_chapter_images_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_images_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_images_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_images_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_images_aggregate_order_by:{
		avg:{
			type:"voyce_chapter_images_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_chapter_images_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_chapter_images_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_chapter_images_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_chapter_images_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_chapter_images_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_chapter_images_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_chapter_images_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_chapter_images_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_chapter_images_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_arr_rel_insert_input:{
		data:{
			type:"voyce_chapter_images_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_images_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_avg_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_bool_exp:{
		_and:{
			type:"voyce_chapter_images_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_images_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_images_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_effects:{
			type:"voyce_chapter_effects_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_effects_aggregate:{
			type:"voyce_chapter_effects_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_panels:{
			type:"voyce_chapter_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_panels_aggregate:{
			type:"voyce_chapter_panels_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_constraint: "enum",
	voyce_chapter_images_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_insert_input:{
		chapter:{
			type:"voyce_chapters_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_effects:{
			type:"voyce_chapter_effects_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_panels:{
			type:"voyce_chapter_panels_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_max_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_min_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_obj_rel_insert_input:{
		data:{
			type:"voyce_chapter_images_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_images_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_on_conflict:{
		constraint:{
			type:"voyce_chapter_images_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_images_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_images_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_order_by:{
		chapter:{
			type:"voyce_chapters_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_effects_aggregate:{
			type:"voyce_chapter_effects_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_panels_aggregate:{
			type:"voyce_chapter_panels_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_images_select_column: "enum",
	voyce_chapter_images_set_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_stddev_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_stddev_pop_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_stddev_samp_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_images_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_stream_cursor_value_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_sum_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_update_column: "enum",
	voyce_chapter_images_updates:{
		_inc:{
			type:"voyce_chapter_images_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_images_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_images_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_images_var_pop_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_var_samp_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_images_variance_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_aggregate_bool_exp:{
		bool_and:{
			type:"voyce_chapter_likes_aggregate_bool_exp_bool_and",
			array:false,
			arrayRequired:false,
			required:false
		},
		bool_or:{
			type:"voyce_chapter_likes_aggregate_bool_exp_bool_or",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"voyce_chapter_likes_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_aggregate_bool_exp_bool_and:{
		arguments:{
			type:"voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_and_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_likes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_likes_aggregate_bool_exp_bool_or:{
		arguments:{
			type:"voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_or_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_likes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_likes_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_chapter_likes_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_likes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_likes_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_likes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_likes_aggregate_order_by:{
		avg:{
			type:"voyce_chapter_likes_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_chapter_likes_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_chapter_likes_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_chapter_likes_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_chapter_likes_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_chapter_likes_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_chapter_likes_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_chapter_likes_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_chapter_likes_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_chapter_likes_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_arr_rel_insert_input:{
		data:{
			type:"voyce_chapter_likes_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_likes_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_avg_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_bool_exp:{
		_and:{
			type:"voyce_chapter_likes_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_likes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_likes_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_poll_like:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_constraint: "enum",
	voyce_chapter_likes_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_insert_input:{
		chapter:{
			type:"voyce_chapters_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_poll_like:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_max_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_min_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_on_conflict:{
		constraint:{
			type:"voyce_chapter_likes_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_likes_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_likes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_order_by:{
		chapter:{
			type:"voyce_chapters_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_poll_like:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_pk_columns_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_likes_select_column: "enum",
	voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_and_arguments_columns: "enum",
	voyce_chapter_likes_select_column_voyce_chapter_likes_aggregate_bool_exp_bool_or_arguments_columns: "enum",
	voyce_chapter_likes_set_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_poll_like:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_stddev_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_stddev_pop_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_stddev_samp_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_likes_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_stream_cursor_value_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_poll_like:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_sum_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_update_column: "enum",
	voyce_chapter_likes_updates:{
		_inc:{
			type:"voyce_chapter_likes_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_likes_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_likes_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_likes_var_pop_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_var_samp_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_likes_variance_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_aggregate_bool_exp:{
		bool_and:{
			type:"voyce_chapter_locks_aggregate_bool_exp_bool_and",
			array:false,
			arrayRequired:false,
			required:false
		},
		bool_or:{
			type:"voyce_chapter_locks_aggregate_bool_exp_bool_or",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"voyce_chapter_locks_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_aggregate_bool_exp_bool_and:{
		arguments:{
			type:"voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_and_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_locks_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_locks_aggregate_bool_exp_bool_or:{
		arguments:{
			type:"voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_or_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_locks_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_locks_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_chapter_locks_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_locks_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_locks_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_locks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_locks_aggregate_order_by:{
		avg:{
			type:"voyce_chapter_locks_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_chapter_locks_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_chapter_locks_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_chapter_locks_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_chapter_locks_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_chapter_locks_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_chapter_locks_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_chapter_locks_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_chapter_locks_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_chapter_locks_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_arr_rel_insert_input:{
		data:{
			type:"voyce_chapter_locks_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_locks_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_avg_order_by:{
		buy_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_bool_exp:{
		_and:{
			type:"voyce_chapter_locks_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_locks_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_locks_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		buy_currency:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		buy_price:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_active:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_currency:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_constraint: "enum",
	voyce_chapter_locks_inc_input:{
		buy_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_insert_input:{
		buy_currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		buy_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter:{
			type:"voyce_chapters_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_max_order_by:{
		buy_currency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		buy_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_currency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_min_order_by:{
		buy_currency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		buy_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_currency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_on_conflict:{
		constraint:{
			type:"voyce_chapter_locks_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_locks_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_locks_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_order_by:{
		buy_currency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		buy_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter:{
			type:"voyce_chapters_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_active:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_currency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_locks_select_column: "enum",
	voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_and_arguments_columns: "enum",
	voyce_chapter_locks_select_column_voyce_chapter_locks_aggregate_bool_exp_bool_or_arguments_columns: "enum",
	voyce_chapter_locks_set_input:{
		buy_currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		buy_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_stddev_order_by:{
		buy_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_stddev_pop_order_by:{
		buy_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_stddev_samp_order_by:{
		buy_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_locks_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_stream_cursor_value_input:{
		buy_currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		buy_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_sum_order_by:{
		buy_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_update_column: "enum",
	voyce_chapter_locks_updates:{
		_inc:{
			type:"voyce_chapter_locks_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_locks_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_locks_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_locks_var_pop_order_by:{
		buy_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_var_samp_order_by:{
		buy_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_locks_variance_order_by:{
		buy_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panel_views_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_panel_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_panel_views_bool_exp:{
		_and:{
			type:"voyce_chapter_panel_views_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_panel_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_panel_views_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		current_panel:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels:{
			type:"bpchar_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		percentage:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_panels:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		word_idx:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panel_views_constraint: "enum",
	voyce_chapter_panel_views_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		current_panel:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		percentage:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_panels:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		word_idx:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panel_views_insert_input:{
		chapter:{
			type:"voyce_chapters_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		current_panel:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		percentage:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_panels:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		word_idx:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panel_views_on_conflict:{
		constraint:{
			type:"voyce_chapter_panel_views_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_panel_views_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_panel_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panel_views_order_by:{
		chapter:{
			type:"voyce_chapters_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		current_panel:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		percentage:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_panels:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		word_idx:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panel_views_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_panel_views_select_column: "enum",
	voyce_chapter_panel_views_set_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		current_panel:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		percentage:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_panels:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		word_idx:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panel_views_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_panel_views_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panel_views_stream_cursor_value_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		current_panel:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels:{
			type:"bpchar",
			array:false,
			arrayRequired:false,
			required:false
		},
		percentage:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_panels:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		word_idx:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panel_views_update_column: "enum",
	voyce_chapter_panel_views_updates:{
		_inc:{
			type:"voyce_chapter_panel_views_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_panel_views_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_panel_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_panels:{
		panels_comments:{
			distinct_on:{
				type:"voyce_panels_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		panels_comments_aggregate:{
			distinct_on:{
				type:"voyce_panels_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		panels_reactions:{
			distinct_on:{
				type:"voyce_panels_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		panels_reactions_aggregate:{
			distinct_on:{
				type:"voyce_panels_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		panels_reactions_counts:{
			distinct_on:{
				type:"voyce_panels_reactions_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_reactions_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		panels_reactions_counts_aggregate:{
			distinct_on:{
				type:"voyce_panels_reactions_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_reactions_count_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_count_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_panels_aggregate_bool_exp:{
		bool_and:{
			type:"voyce_chapter_panels_aggregate_bool_exp_bool_and",
			array:false,
			arrayRequired:false,
			required:false
		},
		bool_or:{
			type:"voyce_chapter_panels_aggregate_bool_exp_bool_or",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"voyce_chapter_panels_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_aggregate_bool_exp_bool_and:{
		arguments:{
			type:"voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_and_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_panels_aggregate_bool_exp_bool_or:{
		arguments:{
			type:"voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_or_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_panels_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_chapter_panels_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_panels_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_panels_aggregate_order_by:{
		avg:{
			type:"voyce_chapter_panels_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_chapter_panels_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_chapter_panels_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_chapter_panels_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_chapter_panels_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_chapter_panels_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_chapter_panels_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_chapter_panels_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_chapter_panels_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_chapter_panels_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_arr_rel_insert_input:{
		data:{
			type:"voyce_chapter_panels_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_panels_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_avg_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_bool_exp:{
		_and:{
			type:"voyce_chapter_panels_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_panels_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comments:{
			type:"voyce_panels_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comments_aggregate:{
			type:"voyce_panels_comments_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_reactions:{
			type:"voyce_panels_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_reactions_aggregate:{
			type:"voyce_panels_reactions_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_reactions_counts:{
			type:"voyce_panels_reactions_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_reactions_counts_aggregate:{
			type:"voyce_panels_reactions_count_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_constraint: "enum",
	voyce_chapter_panels_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_insert_input:{
		chapter:{
			type:"voyce_chapters_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comments:{
			type:"voyce_panels_comments_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_reactions:{
			type:"voyce_panels_reactions_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_reactions_counts:{
			type:"voyce_panels_reactions_count_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_max_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_min_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_obj_rel_insert_input:{
		data:{
			type:"voyce_chapter_panels_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_panels_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_on_conflict:{
		constraint:{
			type:"voyce_chapter_panels_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_panels_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_order_by:{
		chapter:{
			type:"voyce_chapters_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comments_aggregate:{
			type:"voyce_panels_comments_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_reactions_aggregate:{
			type:"voyce_panels_reactions_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_reactions_counts_aggregate:{
			type:"voyce_panels_reactions_count_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_panels_select_column: "enum",
	voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_and_arguments_columns: "enum",
	voyce_chapter_panels_select_column_voyce_chapter_panels_aggregate_bool_exp_bool_or_arguments_columns: "enum",
	voyce_chapter_panels_set_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_stddev_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_stddev_pop_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_stddev_samp_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_panels_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_stream_cursor_value_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_sum_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_update_column: "enum",
	voyce_chapter_panels_updates:{
		_inc:{
			type:"voyce_chapter_panels_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_panels_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_panels_var_pop_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_var_samp_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_panels_variance_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_aggregate_bool_exp:{
		count:{
			type:"voyce_chapter_publish_schedules_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_chapter_publish_schedules_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_publish_schedules_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_publish_schedules_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_publish_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_publish_schedules_aggregate_order_by:{
		avg:{
			type:"voyce_chapter_publish_schedules_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_chapter_publish_schedules_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_chapter_publish_schedules_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_chapter_publish_schedules_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_chapter_publish_schedules_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_chapter_publish_schedules_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_chapter_publish_schedules_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_chapter_publish_schedules_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_chapter_publish_schedules_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_chapter_publish_schedules_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_arr_rel_insert_input:{
		data:{
			type:"voyce_chapter_publish_schedules_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_publish_schedules_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_avg_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		epoch:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gmt_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_done:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_bool_exp:{
		_and:{
			type:"voyce_chapter_publish_schedules_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_publish_schedules_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_publish_schedules_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		epoch:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		gmt_offset:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_done:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		time:{
			type:"time_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_constraint: "enum",
	voyce_chapter_publish_schedules_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		epoch:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		gmt_offset:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_done:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_insert_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		epoch:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		gmt_offset:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_done:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		time:{
			type:"time",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_max_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		epoch:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gmt_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_done:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_min_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		epoch:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gmt_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_done:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_on_conflict:{
		constraint:{
			type:"voyce_chapter_publish_schedules_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_publish_schedules_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_publish_schedules_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		epoch:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gmt_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_done:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		time:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_publish_schedules_select_column: "enum",
	voyce_chapter_publish_schedules_set_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		epoch:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		gmt_offset:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_done:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		time:{
			type:"time",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_stddev_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		epoch:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gmt_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_done:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_stddev_pop_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		epoch:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gmt_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_done:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_stddev_samp_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		epoch:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gmt_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_done:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_publish_schedules_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_stream_cursor_value_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		epoch:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		gmt_offset:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_done:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		time:{
			type:"time",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_sum_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		epoch:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gmt_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_done:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_update_column: "enum",
	voyce_chapter_publish_schedules_updates:{
		_inc:{
			type:"voyce_chapter_publish_schedules_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_publish_schedules_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_publish_schedules_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_publish_schedules_var_pop_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		epoch:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gmt_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_done:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_var_samp_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		epoch:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gmt_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_done:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_publish_schedules_variance_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		epoch:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gmt_offset:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_done:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_aggregate_bool_exp:{
		count:{
			type:"voyce_chapter_reactions_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_chapter_reactions_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_reactions_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_reactions_aggregate_order_by:{
		avg:{
			type:"voyce_chapter_reactions_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_chapter_reactions_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_chapter_reactions_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_chapter_reactions_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_chapter_reactions_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_chapter_reactions_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_chapter_reactions_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_chapter_reactions_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_chapter_reactions_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_chapter_reactions_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_arr_rel_insert_input:{
		data:{
			type:"voyce_chapter_reactions_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_reactions_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_bool_exp:{
		_and:{
			type:"voyce_chapter_reactions_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_reactions_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_constraint: "enum",
	voyce_chapter_reactions_count_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_reactions_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_reactions_count_bool_exp:{
		_and:{
			type:"voyce_chapter_reactions_count_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_reactions_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_reactions_count_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_count_constraint: "enum",
	voyce_chapter_reactions_count_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_count_insert_input:{
		chapter:{
			type:"voyce_chapters_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_count_on_conflict:{
		constraint:{
			type:"voyce_chapter_reactions_count_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_reactions_count_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_reactions_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_count_order_by:{
		chapter:{
			type:"voyce_chapters_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_count_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_reactions_count_select_column: "enum",
	voyce_chapter_reactions_count_set_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_count_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_reactions_count_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_count_stream_cursor_value_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_count_update_column: "enum",
	voyce_chapter_reactions_count_updates:{
		_inc:{
			type:"voyce_chapter_reactions_count_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_reactions_count_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_reactions_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_reactions_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_insert_input:{
		chapter:{
			type:"voyce_chapters_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_max_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_min_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_on_conflict:{
		constraint:{
			type:"voyce_chapter_reactions_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_reactions_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_order_by:{
		chapter:{
			type:"voyce_chapters_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_reactions_select_column: "enum",
	voyce_chapter_reactions_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_reactions_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_update_column: "enum",
	voyce_chapter_reactions_updates:{
		_inc:{
			type:"voyce_chapter_reactions_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_reactions_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_reactions_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_reactions_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_view_logs_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_view_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_view_logs_bool_exp:{
		_and:{
			type:"voyce_chapter_view_logs_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_view_logs_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_view_logs_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		device_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		origin:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		session_api:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		session_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_view_logs_constraint: "enum",
	voyce_chapter_view_logs_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_view_logs_insert_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		device_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		origin:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		session_api:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		session_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_view_logs_on_conflict:{
		constraint:{
			type:"voyce_chapter_view_logs_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_view_logs_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_view_logs_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_view_logs_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		device_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		origin:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		session_api:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		session_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_view_logs_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_view_logs_select_column: "enum",
	voyce_chapter_view_logs_set_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		device_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		origin:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		session_api:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		session_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_view_logs_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_view_logs_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_view_logs_stream_cursor_value_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		device_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		origin:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		session_api:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		session_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_view_logs_update_column: "enum",
	voyce_chapter_view_logs_updates:{
		_inc:{
			type:"voyce_chapter_view_logs_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_view_logs_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_view_logs_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_views_aggregate_bool_exp:{
		bool_and:{
			type:"voyce_chapter_views_aggregate_bool_exp_bool_and",
			array:false,
			arrayRequired:false,
			required:false
		},
		bool_or:{
			type:"voyce_chapter_views_aggregate_bool_exp_bool_or",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"voyce_chapter_views_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_aggregate_bool_exp_bool_and:{
		arguments:{
			type:"voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_and_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_views_aggregate_bool_exp_bool_or:{
		arguments:{
			type:"voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_or_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_views_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_chapter_views_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapter_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_views_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapter_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapter_views_aggregate_order_by:{
		avg:{
			type:"voyce_chapter_views_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_chapter_views_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_chapter_views_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_chapter_views_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_chapter_views_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_chapter_views_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_chapter_views_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_chapter_views_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_chapter_views_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_chapter_views_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_arr_rel_insert_input:{
		data:{
			type:"voyce_chapter_views_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_chapter_views_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_avg_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_bool_exp:{
		_and:{
			type:"voyce_chapter_views_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapter_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapter_views_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_read:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"bigint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_hash:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_constraint: "enum",
	voyce_chapter_views_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_insert_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_read:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_hash:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_max_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_hash:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_min_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_hash:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_on_conflict:{
		constraint:{
			type:"voyce_chapter_views_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapter_views_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapter_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_read:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_hash:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_views_select_column: "enum",
	voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_and_arguments_columns: "enum",
	voyce_chapter_views_select_column_voyce_chapter_views_aggregate_bool_exp_bool_or_arguments_columns: "enum",
	voyce_chapter_views_set_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_read:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_hash:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_stddev_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_stddev_pop_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_stddev_samp_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapter_views_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_stream_cursor_value_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_read:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_hash:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_sum_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_update_column: "enum",
	voyce_chapter_views_updates:{
		_inc:{
			type:"voyce_chapter_views_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapter_views_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapter_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapter_views_var_pop_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_var_samp_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapter_views_variance_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters:{
		chapter_comments:{
			distinct_on:{
				type:"voyce_chapter_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_comments_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_effects:{
			distinct_on:{
				type:"voyce_chapter_effects_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_effects_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_effects_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_effects_aggregate:{
			distinct_on:{
				type:"voyce_chapter_effects_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_effects_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_effects_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_panels:{
			distinct_on:{
				type:"voyce_chapter_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_panels_aggregate:{
			distinct_on:{
				type:"voyce_chapter_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_reactions:{
			distinct_on:{
				type:"voyce_chapter_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_reactions_aggregate:{
			distinct_on:{
				type:"voyce_chapter_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		images:{
			distinct_on:{
				type:"voyce_chapter_images_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_images_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_images_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		images_aggregate:{
			distinct_on:{
				type:"voyce_chapter_images_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_images_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_images_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		likes:{
			distinct_on:{
				type:"voyce_chapter_likes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_likes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		likes_aggregate:{
			distinct_on:{
				type:"voyce_chapter_likes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_likes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		locks:{
			distinct_on:{
				type:"voyce_chapter_locks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_locks_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_locks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		locks_aggregate:{
			distinct_on:{
				type:"voyce_chapter_locks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_locks_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_locks_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		schedule:{
			distinct_on:{
				type:"voyce_chapter_publish_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_publish_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_publish_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		schedule_aggregate:{
			distinct_on:{
				type:"voyce_chapter_publish_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_publish_schedules_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_publish_schedules_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		views:{
			distinct_on:{
				type:"voyce_chapter_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		views_aggregate:{
			distinct_on:{
				type:"voyce_chapter_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapters_07252024_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapters_07252024_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapters_07252024_bool_exp:{
		_and:{
			type:"voyce_chapters_07252024_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapters_07252024_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapters_07252024_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description_old:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayed_exclusive:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v2:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v3:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		has_text:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_07252024_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_07252024_insert_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description_old:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayed_exclusive:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v2:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v3:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		has_text:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_07252024_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description_old:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayed_exclusive:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v2:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v3:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		has_text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_07252024_select_column: "enum",
	voyce_chapters_07252024_set_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description_old:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayed_exclusive:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v2:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v3:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		has_text:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_07252024_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapters_07252024_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_07252024_stream_cursor_value_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description_old:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayed_exclusive:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v2:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v3:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		has_text:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_07252024_updates:{
		_inc:{
			type:"voyce_chapters_07252024_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapters_07252024_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapters_07252024_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapters_aggregate_bool_exp:{
		bool_and:{
			type:"voyce_chapters_aggregate_bool_exp_bool_and",
			array:false,
			arrayRequired:false,
			required:false
		},
		bool_or:{
			type:"voyce_chapters_aggregate_bool_exp_bool_or",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"voyce_chapters_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_aggregate_bool_exp_bool_and:{
		arguments:{
			type:"voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_and_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapters_aggregate_bool_exp_bool_or:{
		arguments:{
			type:"voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_or_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapters_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_chapters_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapters_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_chapters_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_chapters_aggregate_order_by:{
		avg:{
			type:"voyce_chapters_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_chapters_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_chapters_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_chapters_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_chapters_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_chapters_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_chapters_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_chapters_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_chapters_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_chapters_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_arr_rel_insert_input:{
		data:{
			type:"voyce_chapters_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_chapters_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_bool_exp:{
		_and:{
			type:"voyce_chapters_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_chapters_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter_comments:{
			type:"voyce_chapter_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_aggregate:{
			type:"voyce_chapter_comments_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_effects:{
			type:"voyce_chapter_effects_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_effects_aggregate:{
			type:"voyce_chapter_effects_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_panels:{
			type:"voyce_chapter_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_panels_aggregate:{
			type:"voyce_chapter_panels_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_reactions:{
			type:"voyce_chapter_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_reactions_aggregate:{
			type:"voyce_chapter_reactions_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"voyce_chapter_contents_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description_old:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayed_exclusive:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v2:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v3:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		has_text:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		images:{
			type:"voyce_chapter_images_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		images_aggregate:{
			type:"voyce_chapter_images_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes:{
			type:"voyce_chapter_likes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_aggregate:{
			type:"voyce_chapter_likes_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		locks:{
			type:"voyce_chapter_locks_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		locks_aggregate:{
			type:"voyce_chapter_locks_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		schedule:{
			type:"voyce_chapter_publish_schedules_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		schedule_aggregate:{
			type:"voyce_chapter_publish_schedules_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		views:{
			type:"voyce_chapter_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_aggregate:{
			type:"voyce_chapter_views_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_constraint: "enum",
	voyce_chapters_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_insert_input:{
		chapter_comments:{
			type:"voyce_chapter_comments_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_effects:{
			type:"voyce_chapter_effects_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_panels:{
			type:"voyce_chapter_panels_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_reactions:{
			type:"voyce_chapter_reactions_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"voyce_chapter_contents_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description_old:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayed_exclusive:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v2:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v3:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		has_text:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		images:{
			type:"voyce_chapter_images_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes:{
			type:"voyce_chapter_likes_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		locks:{
			type:"voyce_chapter_locks_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		schedule:{
			type:"voyce_chapter_publish_schedules_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		views:{
			type:"voyce_chapter_views_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_max_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description_old:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v2:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v3:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_min_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description_old:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v2:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v3:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_obj_rel_insert_input:{
		data:{
			type:"voyce_chapters_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_chapters_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_on_conflict:{
		constraint:{
			type:"voyce_chapters_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_chapters_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_order_by:{
		chapter_comments_aggregate:{
			type:"voyce_chapter_comments_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_effects_aggregate:{
			type:"voyce_chapter_effects_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_panels_aggregate:{
			type:"voyce_chapter_panels_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_reactions_aggregate:{
			type:"voyce_chapter_reactions_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"voyce_chapter_contents_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description_old:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayed_exclusive:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v2:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v3:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		has_text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		images_aggregate:{
			type:"voyce_chapter_images_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_aggregate:{
			type:"voyce_chapter_likes_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		locks_aggregate:{
			type:"voyce_chapter_locks_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		schedule_aggregate:{
			type:"voyce_chapter_publish_schedules_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_aggregate:{
			type:"voyce_chapter_views_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapters_select_column: "enum",
	voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_and_arguments_columns: "enum",
	voyce_chapters_select_column_voyce_chapters_aggregate_bool_exp_bool_or_arguments_columns: "enum",
	voyce_chapters_set_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description_old:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayed_exclusive:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v2:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v3:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		has_text:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_stream_cursor_input:{
		initial_value:{
			type:"voyce_chapters_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_stream_cursor_value_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description_old:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		displayed_exclusive:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v2:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatted_content_v3:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		has_text:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_update_column: "enum",
	voyce_chapters_updates:{
		_inc:{
			type:"voyce_chapters_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_chapters_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_chapters_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_chapters_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_collaborator_roles_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_collaborator_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_collaborator_roles_bool_exp:{
		_and:{
			type:"voyce_collaborator_roles_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_collaborator_roles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_collaborator_roles_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		role:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_collaborator_roles_constraint: "enum",
	voyce_collaborator_roles_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_collaborator_roles_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_collaborator_roles_obj_rel_insert_input:{
		data:{
			type:"voyce_collaborator_roles_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_collaborator_roles_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_collaborator_roles_on_conflict:{
		constraint:{
			type:"voyce_collaborator_roles_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_collaborator_roles_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_collaborator_roles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_collaborator_roles_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_collaborator_roles_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_collaborator_roles_select_column: "enum",
	voyce_collaborator_roles_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_collaborator_roles_stream_cursor_input:{
		initial_value:{
			type:"voyce_collaborator_roles_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_collaborator_roles_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_collaborator_roles_update_column: "enum",
	voyce_collaborator_roles_updates:{
		_inc:{
			type:"voyce_collaborator_roles_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_collaborator_roles_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_collaborator_roles_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_comic_types_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_comic_types_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_comic_types_bool_exp:{
		_and:{
			type:"voyce_comic_types_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_comic_types_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_comic_types_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_comic_types_constraint: "enum",
	voyce_comic_types_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_comic_types_insert_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_comic_types_obj_rel_insert_input:{
		data:{
			type:"voyce_comic_types_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_comic_types_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_comic_types_on_conflict:{
		constraint:{
			type:"voyce_comic_types_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_comic_types_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_comic_types_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_comic_types_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_comic_types_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_comic_types_select_column: "enum",
	voyce_comic_types_set_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_comic_types_stream_cursor_input:{
		initial_value:{
			type:"voyce_comic_types_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_comic_types_stream_cursor_value_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_comic_types_update_column: "enum",
	voyce_comic_types_updates:{
		_inc:{
			type:"voyce_comic_types_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_comic_types_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_comic_types_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_comments_top_mat_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_comments_top_mat_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_comments_top_mat_bool_exp:{
		_and:{
			type:"voyce_comments_top_mat_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_comments_top_mat_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_comments_top_mat_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		comment_type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_comments_top_mat_order_by:{
		comment_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_comments_top_mat_select_column: "enum",
	voyce_comments_top_mat_stream_cursor_input:{
		initial_value:{
			type:"voyce_comments_top_mat_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_comments_top_mat_stream_cursor_value_input:{
		comment_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_community_blurbs_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_community_blurbs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_community_blurbs_bool_exp:{
		_and:{
			type:"voyce_community_blurbs_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_community_blurbs_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_community_blurbs_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		avatar:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_community_blurbs_constraint: "enum",
	voyce_community_blurbs_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_community_blurbs_insert_input:{
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_community_blurbs_on_conflict:{
		constraint:{
			type:"voyce_community_blurbs_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_community_blurbs_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_community_blurbs_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_community_blurbs_order_by:{
		avatar:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_community_blurbs_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_community_blurbs_select_column: "enum",
	voyce_community_blurbs_set_input:{
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_community_blurbs_stream_cursor_input:{
		initial_value:{
			type:"voyce_community_blurbs_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_community_blurbs_stream_cursor_value_input:{
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_community_blurbs_update_column: "enum",
	voyce_community_blurbs_updates:{
		_inc:{
			type:"voyce_community_blurbs_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_community_blurbs_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_community_blurbs_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_contact_us_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_contact_us_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_contact_us_bool_exp:{
		_and:{
			type:"voyce_contact_us_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_contact_us_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_contact_us_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		number:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_contact_us_constraint: "enum",
	voyce_contact_us_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_contact_us_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_contact_us_on_conflict:{
		constraint:{
			type:"voyce_contact_us_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_contact_us_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_contact_us_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_contact_us_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		number:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_contact_us_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_contact_us_select_column: "enum",
	voyce_contact_us_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_contact_us_stream_cursor_input:{
		initial_value:{
			type:"voyce_contact_us_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_contact_us_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_contact_us_update_column: "enum",
	voyce_contact_us_updates:{
		_inc:{
			type:"voyce_contact_us_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_contact_us_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_contact_us_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_content_report_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_content_report_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_content_report_bool_exp:{
		_and:{
			type:"voyce_content_report_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_content_report_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_content_report_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		extras:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_content_report_constraint: "enum",
	voyce_content_report_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_content_report_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		extras:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_content_report_on_conflict:{
		constraint:{
			type:"voyce_content_report_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_content_report_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_content_report_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_content_report_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		extras:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_content_report_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_content_report_select_column: "enum",
	voyce_content_report_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		extras:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_content_report_stream_cursor_input:{
		initial_value:{
			type:"voyce_content_report_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_content_report_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		extras:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_content_report_update_column: "enum",
	voyce_content_report_updates:{
		_inc:{
			type:"voyce_content_report_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_content_report_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_content_report_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_contest_data_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_contest_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_contest_data_bool_exp:{
		_and:{
			type:"voyce_contest_data_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_contest_data_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_contest_data_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		age:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		discord_username:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_arc:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		pdf:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_media:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		story_pitch:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		story_summary:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		target_audience:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		where_did_hear:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_contest_data_constraint: "enum",
	voyce_contest_data_inc_input:{
		age:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_contest_data_insert_input:{
		age:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		discord_username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_arc:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		pdf:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_media:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		story_pitch:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		story_summary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		target_audience:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		where_did_hear:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_contest_data_on_conflict:{
		constraint:{
			type:"voyce_contest_data_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_contest_data_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_contest_data_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_contest_data_order_by:{
		age:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		discord_username:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_arc:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		pdf:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_media:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		story_pitch:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		story_summary:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		target_audience:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		where_did_hear:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_contest_data_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_contest_data_select_column: "enum",
	voyce_contest_data_set_input:{
		age:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		discord_username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_arc:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		pdf:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_media:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		story_pitch:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		story_summary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		target_audience:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		where_did_hear:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_contest_data_stream_cursor_input:{
		initial_value:{
			type:"voyce_contest_data_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_contest_data_stream_cursor_value_input:{
		age:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		discord_username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_arc:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		pdf:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_media:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		story_pitch:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		story_summary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		target_audience:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		where_did_hear:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_contest_data_update_column: "enum",
	voyce_contest_data_updates:{
		_inc:{
			type:"voyce_contest_data_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_contest_data_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_contest_data_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_countries_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_countries_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_countries_bool_exp:{
		_and:{
			type:"voyce_countries_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_countries_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_countries_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		date_create:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		feature:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		flag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		phone_code:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_countries_constraint: "enum",
	voyce_countries_inc_input:{
		feature:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		phone_code:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_countries_insert_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		feature:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		flag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phone_code:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_countries_on_conflict:{
		constraint:{
			type:"voyce_countries_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_countries_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_countries_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_countries_order_by:{
		date_create:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		feature:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		flag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		phone_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_countries_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_countries_select_column: "enum",
	voyce_countries_set_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		feature:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		flag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phone_code:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_countries_stream_cursor_input:{
		initial_value:{
			type:"voyce_countries_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_countries_stream_cursor_value_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		feature:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		flag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phone_code:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_countries_update_column: "enum",
	voyce_countries_updates:{
		_inc:{
			type:"voyce_countries_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_countries_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_countries_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_csat_ratings:{
		data:{
			distinct_on:{
				type:"voyce_csat_ratings_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_csat_ratings_data_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_csat_ratings_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		data_aggregate:{
			distinct_on:{
				type:"voyce_csat_ratings_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_csat_ratings_data_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_csat_ratings_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_csat_ratings_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_csat_ratings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_csat_ratings_bool_exp:{
		_and:{
			type:"voyce_csat_ratings_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_csat_ratings_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_csat_ratings_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		cancel_text:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"voyce_csat_ratings_data_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		data_aggregate:{
			type:"voyce_csat_ratings_data_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		desc:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		screen:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		version:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_constraint: "enum",
	voyce_csat_ratings_data_aggregate_bool_exp:{
		count:{
			type:"voyce_csat_ratings_data_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_csat_ratings_data_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_csat_ratings_data_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_csat_ratings_data_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_csat_ratings_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_csat_ratings_data_aggregate_order_by:{
		avg:{
			type:"voyce_csat_ratings_data_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_csat_ratings_data_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_csat_ratings_data_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_csat_ratings_data_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_csat_ratings_data_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_csat_ratings_data_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_csat_ratings_data_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_csat_ratings_data_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_csat_ratings_data_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_csat_ratings_data_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_arr_rel_insert_input:{
		data:{
			type:"voyce_csat_ratings_data_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_csat_ratings_data_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_avg_order_by:{
		csat_rating_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_bool_exp:{
		_and:{
			type:"voyce_csat_ratings_data_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_csat_ratings_data_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_csat_ratings_data_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		csat_rating_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_constraint: "enum",
	voyce_csat_ratings_data_inc_input:{
		csat_rating_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		csat_rating_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_max_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		csat_rating_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_min_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		csat_rating_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_on_conflict:{
		constraint:{
			type:"voyce_csat_ratings_data_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_csat_ratings_data_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_csat_ratings_data_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		csat_rating_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_csat_ratings_data_select_column: "enum",
	voyce_csat_ratings_data_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		csat_rating_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_stddev_order_by:{
		csat_rating_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_stddev_pop_order_by:{
		csat_rating_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_stddev_samp_order_by:{
		csat_rating_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_stream_cursor_input:{
		initial_value:{
			type:"voyce_csat_ratings_data_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		csat_rating_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_sum_order_by:{
		csat_rating_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_update_column: "enum",
	voyce_csat_ratings_data_updates:{
		_inc:{
			type:"voyce_csat_ratings_data_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_csat_ratings_data_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_csat_ratings_data_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_csat_ratings_data_var_pop_order_by:{
		csat_rating_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_var_samp_order_by:{
		csat_rating_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_data_variance_order_by:{
		csat_rating_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		screen:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		version:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_insert_input:{
		cancel_text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"voyce_csat_ratings_data_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		desc:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		screen:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		version:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_on_conflict:{
		constraint:{
			type:"voyce_csat_ratings_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_csat_ratings_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_csat_ratings_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_order_by:{
		cancel_text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		data_aggregate:{
			type:"voyce_csat_ratings_data_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		desc:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		screen:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		version:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_csat_ratings_select_column: "enum",
	voyce_csat_ratings_set_input:{
		cancel_text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		desc:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		screen:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		version:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_stream_cursor_input:{
		initial_value:{
			type:"voyce_csat_ratings_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_stream_cursor_value_input:{
		cancel_text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		desc:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		screen:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		version:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_csat_ratings_update_column: "enum",
	voyce_csat_ratings_updates:{
		_inc:{
			type:"voyce_csat_ratings_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_csat_ratings_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_csat_ratings_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_education_types_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_education_types_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_education_types_bool_exp:{
		_and:{
			type:"voyce_education_types_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_education_types_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_education_types_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		date_create:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_education_types_constraint: "enum",
	voyce_education_types_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_education_types_insert_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_education_types_on_conflict:{
		constraint:{
			type:"voyce_education_types_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_education_types_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_education_types_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_education_types_order_by:{
		date_create:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_education_types_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_education_types_select_column: "enum",
	voyce_education_types_set_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_education_types_stream_cursor_input:{
		initial_value:{
			type:"voyce_education_types_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_education_types_stream_cursor_value_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_education_types_update_column: "enum",
	voyce_education_types_updates:{
		_inc:{
			type:"voyce_education_types_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_education_types_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_education_types_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_erp_login_roles_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_erp_login_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_erp_login_roles_bool_exp:{
		_and:{
			type:"voyce_erp_login_roles_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_erp_login_roles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_erp_login_roles_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_erp_login_roles_constraint: "enum",
	voyce_erp_login_roles_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_erp_login_roles_insert_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_erp_login_roles_on_conflict:{
		constraint:{
			type:"voyce_erp_login_roles_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_erp_login_roles_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_erp_login_roles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_erp_login_roles_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_erp_login_roles_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_erp_login_roles_select_column: "enum",
	voyce_erp_login_roles_set_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_erp_login_roles_stream_cursor_input:{
		initial_value:{
			type:"voyce_erp_login_roles_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_erp_login_roles_stream_cursor_value_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_erp_login_roles_update_column: "enum",
	voyce_erp_login_roles_updates:{
		_inc:{
			type:"voyce_erp_login_roles_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_erp_login_roles_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_erp_login_roles_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_etl_series_views_logs_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_etl_series_views_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_etl_series_views_logs_bool_exp:{
		_and:{
			type:"voyce_etl_series_views_logs_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_etl_series_views_logs_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_etl_series_views_logs_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		glue_epoc:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_etl_series_views_logs_constraint: "enum",
	voyce_etl_series_views_logs_inc_input:{
		glue_epoc:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_etl_series_views_logs_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		glue_epoc:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_etl_series_views_logs_on_conflict:{
		constraint:{
			type:"voyce_etl_series_views_logs_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_etl_series_views_logs_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_etl_series_views_logs_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_etl_series_views_logs_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		glue_epoc:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_etl_series_views_logs_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_etl_series_views_logs_select_column: "enum",
	voyce_etl_series_views_logs_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		glue_epoc:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_etl_series_views_logs_stream_cursor_input:{
		initial_value:{
			type:"voyce_etl_series_views_logs_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_etl_series_views_logs_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		glue_epoc:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_etl_series_views_logs_update_column: "enum",
	voyce_etl_series_views_logs_updates:{
		_inc:{
			type:"voyce_etl_series_views_logs_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_etl_series_views_logs_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_etl_series_views_logs_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_event_schedules_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_event_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_event_schedules_bool_exp:{
		_and:{
			type:"voyce_event_schedules_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_event_schedules_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_event_schedules_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		active:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		bg_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_time:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		main_event:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		main_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_event_schedules_constraint: "enum",
	voyce_event_schedules_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_event_schedules_insert_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		bg_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_time:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		main_event:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		main_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_event_schedules_on_conflict:{
		constraint:{
			type:"voyce_event_schedules_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_event_schedules_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_event_schedules_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_event_schedules_order_by:{
		active:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		bg_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_time:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		main_event:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		main_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_event_schedules_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_event_schedules_select_column: "enum",
	voyce_event_schedules_set_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		bg_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_time:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		main_event:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		main_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_event_schedules_stream_cursor_input:{
		initial_value:{
			type:"voyce_event_schedules_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_event_schedules_stream_cursor_value_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		bg_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_time:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		main_event:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		main_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_event_schedules_update_column: "enum",
	voyce_event_schedules_updates:{
		_inc:{
			type:"voyce_event_schedules_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_event_schedules_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_event_schedules_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_genres_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_genres_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_genres_bool_exp:{
		_and:{
			type:"voyce_genres_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_genres_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_genres_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		color:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_trending:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_genres_constraint: "enum",
	voyce_genres_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_genres_insert_input:{
		color:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_trending:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_genres_obj_rel_insert_input:{
		data:{
			type:"voyce_genres_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_genres_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_genres_on_conflict:{
		constraint:{
			type:"voyce_genres_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_genres_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_genres_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_genres_order_by:{
		color:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_trending:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_genres_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_genres_select_column: "enum",
	voyce_genres_set_input:{
		color:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_trending:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_genres_stream_cursor_input:{
		initial_value:{
			type:"voyce_genres_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_genres_stream_cursor_value_input:{
		color:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_trending:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_genres_update_column: "enum",
	voyce_genres_updates:{
		_inc:{
			type:"voyce_genres_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_genres_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_genres_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_guest_gtags:{
		emails:{
			distinct_on:{
				type:"voyce_popup_emails_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_popup_emails_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_popup_emails_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		emails_aggregate:{
			distinct_on:{
				type:"voyce_popup_emails_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_popup_emails_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_popup_emails_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_guest_gtags_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_guest_gtags_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_guest_gtags_bool_exp:{
		_and:{
			type:"voyce_guest_gtags_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_guest_gtags_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_guest_gtags_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		device_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		emails:{
			type:"voyce_popup_emails_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		emails_aggregate:{
			type:"voyce_popup_emails_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag_v2:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_guest_gtags_constraint: "enum",
	voyce_guest_gtags_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_guest_gtags_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		device_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		emails:{
			type:"voyce_popup_emails_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag_v2:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_guest_gtags_obj_rel_insert_input:{
		data:{
			type:"voyce_guest_gtags_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_guest_gtags_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_guest_gtags_on_conflict:{
		constraint:{
			type:"voyce_guest_gtags_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_guest_gtags_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_guest_gtags_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_guest_gtags_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		device_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		emails_aggregate:{
			type:"voyce_popup_emails_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag_v2:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_guest_gtags_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_guest_gtags_select_column: "enum",
	voyce_guest_gtags_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		device_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag_v2:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_guest_gtags_stream_cursor_input:{
		initial_value:{
			type:"voyce_guest_gtags_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_guest_gtags_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		device_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag_v2:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_ip:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_guest_gtags_update_column: "enum",
	voyce_guest_gtags_updates:{
		_inc:{
			type:"voyce_guest_gtags_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_guest_gtags_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_guest_gtags_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_metabases_access_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_metabases_access_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_metabases_access_bool_exp:{
		_and:{
			type:"voyce_metabases_access_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_metabases_access_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_metabases_access_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		metabase_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_access_constraint: "enum",
	voyce_metabases_access_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		metabase_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_access_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		metabase_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_access_obj_rel_insert_input:{
		data:{
			type:"voyce_metabases_access_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_metabases_access_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_access_on_conflict:{
		constraint:{
			type:"voyce_metabases_access_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_metabases_access_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_metabases_access_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_access_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		metabase_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_access_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_metabases_access_select_column: "enum",
	voyce_metabases_access_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		metabase_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_access_stream_cursor_input:{
		initial_value:{
			type:"voyce_metabases_access_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_access_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		metabase_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_access_update_column: "enum",
	voyce_metabases_access_updates:{
		_inc:{
			type:"voyce_metabases_access_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_metabases_access_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_metabases_access_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_metabases_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_metabases_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_metabases_bool_exp:{
		_and:{
			type:"voyce_metabases_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_metabases_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_metabases_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		access:{
			type:"voyce_metabases_access_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		dashboard_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_active:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_constraint: "enum",
	voyce_metabases_inc_input:{
		dashboard_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_insert_input:{
		access:{
			type:"voyce_metabases_access_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		dashboard_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_on_conflict:{
		constraint:{
			type:"voyce_metabases_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_metabases_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_metabases_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_order_by:{
		access:{
			type:"voyce_metabases_access_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		dashboard_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_active:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_metabases_select_column: "enum",
	voyce_metabases_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		dashboard_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_stream_cursor_input:{
		initial_value:{
			type:"voyce_metabases_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		dashboard_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_metabases_update_column: "enum",
	voyce_metabases_updates:{
		_inc:{
			type:"voyce_metabases_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_metabases_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_metabases_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_newsletter_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_newsletter_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_newsletter_bool_exp:{
		_and:{
			type:"voyce_newsletter_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_newsletter_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_newsletter_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_newsletter_constraint: "enum",
	voyce_newsletter_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_newsletter_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_newsletter_on_conflict:{
		constraint:{
			type:"voyce_newsletter_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_newsletter_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_newsletter_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_newsletter_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_newsletter_pk_columns_input:{
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_newsletter_select_column: "enum",
	voyce_newsletter_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_newsletter_stream_cursor_input:{
		initial_value:{
			type:"voyce_newsletter_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_newsletter_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_newsletter_update_column: "enum",
	voyce_newsletter_updates:{
		_inc:{
			type:"voyce_newsletter_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_newsletter_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_newsletter_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_notification_series_settings_aggregate_bool_exp:{
		count:{
			type:"voyce_notification_series_settings_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_notification_series_settings_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_notification_series_settings_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_notification_series_settings_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_notification_series_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_notification_series_settings_aggregate_order_by:{
		avg:{
			type:"voyce_notification_series_settings_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_notification_series_settings_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_notification_series_settings_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_notification_series_settings_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_notification_series_settings_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_notification_series_settings_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_notification_series_settings_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_notification_series_settings_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_notification_series_settings_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_notification_series_settings_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_arr_rel_insert_input:{
		data:{
			type:"voyce_notification_series_settings_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_notification_series_settings_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_avg_order_by:{
		chapter_comment_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_bool_exp:{
		_and:{
			type:"voyce_notification_series_settings_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_notification_series_settings_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_notification_series_settings_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter_comment_email:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_constraint: "enum",
	voyce_notification_series_settings_inc_input:{
		chapter_comment_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_insert_input:{
		chapter_comment_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_max_order_by:{
		chapter_comment_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_min_order_by:{
		chapter_comment_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_obj_rel_insert_input:{
		data:{
			type:"voyce_notification_series_settings_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_notification_series_settings_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_on_conflict:{
		constraint:{
			type:"voyce_notification_series_settings_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_notification_series_settings_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_notification_series_settings_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_order_by:{
		chapter_comment_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_notification_series_settings_select_column: "enum",
	voyce_notification_series_settings_set_input:{
		chapter_comment_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_stddev_order_by:{
		chapter_comment_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_stddev_pop_order_by:{
		chapter_comment_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_stddev_samp_order_by:{
		chapter_comment_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_stream_cursor_input:{
		initial_value:{
			type:"voyce_notification_series_settings_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_stream_cursor_value_input:{
		chapter_comment_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_sum_order_by:{
		chapter_comment_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_update_column: "enum",
	voyce_notification_series_settings_updates:{
		_inc:{
			type:"voyce_notification_series_settings_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_notification_series_settings_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_notification_series_settings_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_notification_series_settings_var_pop_order_by:{
		chapter_comment_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_var_samp_order_by:{
		chapter_comment_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_series_settings_variance_order_by:{
		chapter_comment_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_settings_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_notification_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_notification_settings_bool_exp:{
		_and:{
			type:"voyce_notification_settings_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_notification_settings_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_notification_settings_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter_comment_email:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_release_email:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_release_push:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_react_email:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_react_push:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_reply_email:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_reply_push:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_email:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_push:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		follow_email:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		follow_push:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		promotion_email:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		promotion_push:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		recommended_series_email:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		recommended_series_push:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_email:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_push:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_settings_constraint: "enum",
	voyce_notification_settings_inc_input:{
		chapter_comment_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_release_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_release_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_react_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_react_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_reply_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_reply_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		follow_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		follow_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		promotion_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		promotion_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		recommended_series_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		recommended_series_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_settings_insert_input:{
		chapter_comment_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_release_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_release_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_react_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_react_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_reply_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_reply_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		follow_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		follow_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		promotion_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		promotion_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		recommended_series_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		recommended_series_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_settings_obj_rel_insert_input:{
		data:{
			type:"voyce_notification_settings_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_notification_settings_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_settings_on_conflict:{
		constraint:{
			type:"voyce_notification_settings_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_notification_settings_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_notification_settings_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_settings_order_by:{
		chapter_comment_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_release_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_release_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_react_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_react_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_reply_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_reply_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		follow_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		follow_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		promotion_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		promotion_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		recommended_series_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		recommended_series_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_settings_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_notification_settings_select_column: "enum",
	voyce_notification_settings_set_input:{
		chapter_comment_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_release_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_release_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_react_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_react_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_reply_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_reply_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		follow_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		follow_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		promotion_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		promotion_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		recommended_series_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		recommended_series_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_settings_stream_cursor_input:{
		initial_value:{
			type:"voyce_notification_settings_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_settings_stream_cursor_value_input:{
		chapter_comment_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comment_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_like_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_react_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_release_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_release_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_react_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_react_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_reply_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_reply_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		follow_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		follow_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		promotion_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		promotion_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		recommended_series_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		recommended_series_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_email:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscription_push:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notification_settings_update_column: "enum",
	voyce_notification_settings_updates:{
		_inc:{
			type:"voyce_notification_settings_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_notification_settings_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_notification_settings_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_notifications:{
		data:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_notifications_aggregate_bool_exp:{
		count:{
			type:"voyce_notifications_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_notifications_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_notifications_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_notifications_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_notifications_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_notifications_aggregate_order_by:{
		avg:{
			type:"voyce_notifications_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_notifications_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_notifications_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_notifications_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_notifications_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_notifications_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_notifications_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_notifications_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_notifications_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_notifications_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_append_input:{
		data:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_arr_rel_insert_input:{
		data:{
			type:"voyce_notifications_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_notifications_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_avg_order_by:{
		child_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_bool_exp:{
		_and:{
			type:"voyce_notifications_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_notifications_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_notifications_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		child_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"jsonb_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_status:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		emailed_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		pushed_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		read_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		unsubscribe_id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_constraint: "enum",
	voyce_notifications_delete_at_path_input:{
		data:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	voyce_notifications_delete_elem_input:{
		data:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_delete_key_input:{
		data:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_inc_input:{
		child_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_insert_input:{
		child_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		emailed_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		pushed_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		read_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		unsubscribe_id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_max_order_by:{
		child_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		emailed_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		pushed_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		read_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		unsubscribe_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_min_order_by:{
		child_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		emailed_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		pushed_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		read_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		unsubscribe_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_on_conflict:{
		constraint:{
			type:"voyce_notifications_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_notifications_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_notifications_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_order_by:{
		child_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		emailed_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		pushed_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		read_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		unsubscribe_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_notifications_prepend_input:{
		data:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_select_column: "enum",
	voyce_notifications_set_input:{
		child_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		emailed_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		pushed_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		read_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		unsubscribe_id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_stddev_order_by:{
		child_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_stddev_pop_order_by:{
		child_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_stddev_samp_order_by:{
		child_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_stream_cursor_input:{
		initial_value:{
			type:"voyce_notifications_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_stream_cursor_value_input:{
		child_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		emailed_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		pushed_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		read_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		unsubscribe_id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_sum_order_by:{
		child_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_update_column: "enum",
	voyce_notifications_updates:{
		_append:{
			type:"voyce_notifications_append_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_delete_at_path:{
			type:"voyce_notifications_delete_at_path_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_delete_elem:{
			type:"voyce_notifications_delete_elem_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_delete_key:{
			type:"voyce_notifications_delete_key_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_inc:{
			type:"voyce_notifications_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_prepend:{
			type:"voyce_notifications_prepend_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_notifications_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_notifications_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_notifications_var_pop_order_by:{
		child_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_var_samp_order_by:{
		child_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_notifications_variance_order_by:{
		child_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_originals_feed_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_originals_feed_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_originals_feed_bool_exp:{
		_and:{
			type:"voyce_originals_feed_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_originals_feed_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_originals_feed_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		category:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		desc:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_originals_feed_order_by:{
		category:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		desc:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_originals_feed_select_column: "enum",
	voyce_originals_feed_stream_cursor_input:{
		initial_value:{
			type:"voyce_originals_feed_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_originals_feed_stream_cursor_value_input:{
		category:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		desc:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_banners_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_page_banners_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_page_banners_bool_exp:{
		_and:{
			type:"voyce_page_banners_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_page_banners_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_page_banners_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		active:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		background:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		desktop:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		overlap:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablet:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_banners_constraint: "enum",
	voyce_page_banners_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_banners_insert_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		background:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		desktop:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		overlap:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablet:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_banners_on_conflict:{
		constraint:{
			type:"voyce_page_banners_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_page_banners_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_page_banners_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_banners_order_by:{
		active:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		background:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		desktop:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		overlap:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablet:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_banners_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_page_banners_select_column: "enum",
	voyce_page_banners_set_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		background:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		desktop:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		overlap:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablet:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_banners_stream_cursor_input:{
		initial_value:{
			type:"voyce_page_banners_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_banners_stream_cursor_value_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		background:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		desktop:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobile:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		overlap:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		tablet:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_banners_update_column: "enum",
	voyce_page_banners_updates:{
		_inc:{
			type:"voyce_page_banners_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_page_banners_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_page_banners_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_page_components:{
		props:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_page_components_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_page_components_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_page_components_bool_exp:{
		_and:{
			type:"voyce_page_components_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_page_components_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_page_components_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		active:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		auth:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		component:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		device:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"json_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_components_constraint: "enum",
	voyce_page_components_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_components_insert_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		auth:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		component:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		device:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_components_on_conflict:{
		constraint:{
			type:"voyce_page_components_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_page_components_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_page_components_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_components_order_by:{
		active:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		auth:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		component:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		device:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_components_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_page_components_select_column: "enum",
	voyce_page_components_set_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		auth:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		component:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		device:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_components_stream_cursor_input:{
		initial_value:{
			type:"voyce_page_components_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_components_stream_cursor_value_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		auth:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		component:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		device:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_page_components_update_column: "enum",
	voyce_page_components_updates:{
		_inc:{
			type:"voyce_page_components_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_page_components_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_page_components_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_pages_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_pages_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_pages_bool_exp:{
		_and:{
			type:"voyce_pages_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_pages_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_pages_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		content:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		slug:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pages_constraint: "enum",
	voyce_pages_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pages_insert_input:{
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		slug:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pages_on_conflict:{
		constraint:{
			type:"voyce_pages_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_pages_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_pages_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pages_order_by:{
		content:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		slug:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pages_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_pages_select_column: "enum",
	voyce_pages_set_input:{
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		slug:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pages_stream_cursor_input:{
		initial_value:{
			type:"voyce_pages_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pages_stream_cursor_value_input:{
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		slug:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pages_update_column: "enum",
	voyce_pages_updates:{
		_inc:{
			type:"voyce_pages_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_pages_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_pages_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_panels_comments:{
		panels_comments:{
			distinct_on:{
				type:"voyce_panels_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		panels_comments_aggregate:{
			distinct_on:{
				type:"voyce_panels_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		panels_reactions:{
			distinct_on:{
				type:"voyce_panels_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		panels_reactions_aggregate:{
			distinct_on:{
				type:"voyce_panels_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_panels_comments_aggregate_bool_exp:{
		count:{
			type:"voyce_panels_comments_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_panels_comments_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_panels_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_panels_comments_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_panels_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_panels_comments_aggregate_order_by:{
		avg:{
			type:"voyce_panels_comments_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_panels_comments_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_panels_comments_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_panels_comments_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_panels_comments_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_panels_comments_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_panels_comments_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_panels_comments_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_panels_comments_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_panels_comments_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_arr_rel_insert_input:{
		data:{
			type:"voyce_panels_comments_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_panels_comments_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_bool_exp:{
		_and:{
			type:"voyce_panels_comments_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_panels_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_panels_comments_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		animated_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_panel:{
			type:"voyce_chapter_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comment:{
			type:"voyce_panels_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comments:{
			type:"voyce_panels_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comments_aggregate:{
			type:"voyce_panels_comments_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_reactions:{
			type:"voyce_panels_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_reactions_aggregate:{
			type:"voyce_panels_reactions_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_constraint: "enum",
	voyce_panels_comments_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_insert_input:{
		animated_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_panel:{
			type:"voyce_chapter_panels_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comment:{
			type:"voyce_panels_comments_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comments:{
			type:"voyce_panels_comments_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_reactions:{
			type:"voyce_panels_reactions_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_max_order_by:{
		animated_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_min_order_by:{
		animated_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_obj_rel_insert_input:{
		data:{
			type:"voyce_panels_comments_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_panels_comments_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_on_conflict:{
		constraint:{
			type:"voyce_panels_comments_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_panels_comments_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_panels_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_order_by:{
		animated_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_panel:{
			type:"voyce_chapter_panels_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comment:{
			type:"voyce_panels_comments_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comments_aggregate:{
			type:"voyce_panels_comments_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_reactions_aggregate:{
			type:"voyce_panels_reactions_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_panels_comments_select_column: "enum",
	voyce_panels_comments_set_input:{
		animated_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_stream_cursor_input:{
		initial_value:{
			type:"voyce_panels_comments_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_stream_cursor_value_input:{
		animated_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_update_column: "enum",
	voyce_panels_comments_updates:{
		_inc:{
			type:"voyce_panels_comments_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_panels_comments_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_panels_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_panels_comments_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_comments_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_aggregate_bool_exp:{
		count:{
			type:"voyce_panels_reactions_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_panels_reactions_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_panels_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_panels_reactions_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_panels_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_panels_reactions_aggregate_order_by:{
		avg:{
			type:"voyce_panels_reactions_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_panels_reactions_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_panels_reactions_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_panels_reactions_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_panels_reactions_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_panels_reactions_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_panels_reactions_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_panels_reactions_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_panels_reactions_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_panels_reactions_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_arr_rel_insert_input:{
		data:{
			type:"voyce_panels_reactions_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_panels_reactions_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_avg_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_bool_exp:{
		_and:{
			type:"voyce_panels_reactions_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_panels_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_panels_reactions_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter_panel:{
			type:"voyce_chapter_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comment:{
			type:"voyce_panels_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_constraint: "enum",
	voyce_panels_reactions_count_aggregate_bool_exp:{
		count:{
			type:"voyce_panels_reactions_count_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_panels_reactions_count_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_panels_reactions_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_panels_reactions_count_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_panels_reactions_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_panels_reactions_count_aggregate_order_by:{
		avg:{
			type:"voyce_panels_reactions_count_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_panels_reactions_count_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_panels_reactions_count_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_panels_reactions_count_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_panels_reactions_count_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_panels_reactions_count_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_panels_reactions_count_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_panels_reactions_count_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_panels_reactions_count_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_panels_reactions_count_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_arr_rel_insert_input:{
		data:{
			type:"voyce_panels_reactions_count_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_panels_reactions_count_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_avg_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_bool_exp:{
		_and:{
			type:"voyce_panels_reactions_count_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_panels_reactions_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_panels_reactions_count_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter_panel:{
			type:"voyce_chapter_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_constraint: "enum",
	voyce_panels_reactions_count_inc_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_insert_input:{
		chapter_panel:{
			type:"voyce_chapter_panels_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_max_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_min_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_on_conflict:{
		constraint:{
			type:"voyce_panels_reactions_count_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_panels_reactions_count_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_panels_reactions_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_order_by:{
		chapter_panel:{
			type:"voyce_chapter_panels_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_panels_reactions_count_select_column: "enum",
	voyce_panels_reactions_count_set_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_stddev_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_stddev_pop_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_stddev_samp_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_stream_cursor_input:{
		initial_value:{
			type:"voyce_panels_reactions_count_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_stream_cursor_value_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_sum_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_update_column: "enum",
	voyce_panels_reactions_count_updates:{
		_inc:{
			type:"voyce_panels_reactions_count_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_panels_reactions_count_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_panels_reactions_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_panels_reactions_count_var_pop_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_var_samp_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_count_variance_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_inc_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_insert_input:{
		chapter_panel:{
			type:"voyce_chapter_panels_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comment:{
			type:"voyce_panels_comments_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_max_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_min_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_on_conflict:{
		constraint:{
			type:"voyce_panels_reactions_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_panels_reactions_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_panels_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_order_by:{
		chapter_panel:{
			type:"voyce_chapter_panels_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comment:{
			type:"voyce_panels_comments_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_panels_reactions_select_column: "enum",
	voyce_panels_reactions_set_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_stddev_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_stddev_pop_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_stddev_samp_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_stream_cursor_input:{
		initial_value:{
			type:"voyce_panels_reactions_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_stream_cursor_value_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_sum_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_update_column: "enum",
	voyce_panels_reactions_updates:{
		_inc:{
			type:"voyce_panels_reactions_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_panels_reactions_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_panels_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_panels_reactions_var_pop_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_var_samp_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_panels_reactions_variance_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_aggregate_bool_exp:{
		count:{
			type:"voyce_payments_to_creators_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_payments_to_creators_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_payments_to_creators_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_payments_to_creators_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_payments_to_creators_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_payments_to_creators_aggregate_order_by:{
		avg:{
			type:"voyce_payments_to_creators_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_payments_to_creators_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_payments_to_creators_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_payments_to_creators_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_payments_to_creators_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_payments_to_creators_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_payments_to_creators_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_payments_to_creators_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_payments_to_creators_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_payments_to_creators_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_arr_rel_insert_input:{
		data:{
			type:"voyce_payments_to_creators_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_payments_to_creators_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_avg_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_bool_exp:{
		_and:{
			type:"voyce_payments_to_creators_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_payments_to_creators_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_payments_to_creators_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		amount:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_constraint: "enum",
	voyce_payments_to_creators_inc_input:{
		amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_insert_input:{
		amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_max_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_min_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_on_conflict:{
		constraint:{
			type:"voyce_payments_to_creators_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_payments_to_creators_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_payments_to_creators_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_payments_to_creators_select_column: "enum",
	voyce_payments_to_creators_set_input:{
		amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_stddev_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_stddev_pop_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_stddev_samp_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_stream_cursor_input:{
		initial_value:{
			type:"voyce_payments_to_creators_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_stream_cursor_value_input:{
		amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_sum_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_update_column: "enum",
	voyce_payments_to_creators_updates:{
		_inc:{
			type:"voyce_payments_to_creators_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_payments_to_creators_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_payments_to_creators_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_payments_to_creators_var_pop_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_var_samp_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_payments_to_creators_variance_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_from_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_to_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pg_rs_series_views_count_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_pg_rs_series_views_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_pg_rs_series_views_count_bool_exp:{
		_and:{
			type:"voyce_pg_rs_series_views_count_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_pg_rs_series_views_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_pg_rs_series_views_count_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		count:{
			type:"bigint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pg_rs_series_views_count_constraint: "enum",
	voyce_pg_rs_series_views_count_inc_input:{
		count:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pg_rs_series_views_count_insert_input:{
		count:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pg_rs_series_views_count_obj_rel_insert_input:{
		data:{
			type:"voyce_pg_rs_series_views_count_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_pg_rs_series_views_count_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pg_rs_series_views_count_on_conflict:{
		constraint:{
			type:"voyce_pg_rs_series_views_count_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_pg_rs_series_views_count_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_pg_rs_series_views_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pg_rs_series_views_count_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pg_rs_series_views_count_pk_columns_input:{
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_pg_rs_series_views_count_select_column: "enum",
	voyce_pg_rs_series_views_count_set_input:{
		count:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pg_rs_series_views_count_stream_cursor_input:{
		initial_value:{
			type:"voyce_pg_rs_series_views_count_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pg_rs_series_views_count_stream_cursor_value_input:{
		count:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_pg_rs_series_views_count_update_column: "enum",
	voyce_pg_rs_series_views_count_updates:{
		_inc:{
			type:"voyce_pg_rs_series_views_count_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_pg_rs_series_views_count_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_pg_rs_series_views_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls:{
		polls_answers:{
			distinct_on:{
				type:"voyce_polls_answers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_answers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_answers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_answers_aggregate:{
			distinct_on:{
				type:"voyce_polls_answers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_answers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_answers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_comments:{
			distinct_on:{
				type:"voyce_polls_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_comments_aggregate:{
			distinct_on:{
				type:"voyce_polls_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_reactions:{
			distinct_on:{
				type:"voyce_polls_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_reactions_aggregate:{
			distinct_on:{
				type:"voyce_polls_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_votes:{
			distinct_on:{
				type:"voyce_polls_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_votes_aggregate:{
			distinct_on:{
				type:"voyce_polls_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_polls_aggregate_bool_exp:{
		bool_and:{
			type:"voyce_polls_aggregate_bool_exp_bool_and",
			array:false,
			arrayRequired:false,
			required:false
		},
		bool_or:{
			type:"voyce_polls_aggregate_bool_exp_bool_or",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"voyce_polls_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_aggregate_bool_exp_bool_and:{
		arguments:{
			type:"voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_and_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_polls_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_aggregate_bool_exp_bool_or:{
		arguments:{
			type:"voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_or_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_polls_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_polls_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_polls_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_polls_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_polls_aggregate_order_by:{
		avg:{
			type:"voyce_polls_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_polls_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_polls_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_polls_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_polls_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_polls_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_polls_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_polls_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_polls_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_polls_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers:{
		polls_votes:{
			distinct_on:{
				type:"voyce_polls_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_votes_aggregate:{
			distinct_on:{
				type:"voyce_polls_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_polls_answers_aggregate_bool_exp:{
		count:{
			type:"voyce_polls_answers_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_polls_answers_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_polls_answers_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_answers_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_polls_answers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_polls_answers_aggregate_order_by:{
		avg:{
			type:"voyce_polls_answers_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_polls_answers_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_polls_answers_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_polls_answers_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_polls_answers_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_polls_answers_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_polls_answers_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_polls_answers_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_polls_answers_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_polls_answers_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_arr_rel_insert_input:{
		data:{
			type:"voyce_polls_answers_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_polls_answers_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_bool_exp:{
		_and:{
			type:"voyce_polls_answers_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_polls_answers_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_polls_answers_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll:{
			type:"voyce_polls_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_votes:{
			type:"voyce_polls_votes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_votes_aggregate:{
			type:"voyce_polls_votes_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_constraint: "enum",
	voyce_polls_answers_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_insert_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll:{
			type:"voyce_polls_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_votes:{
			type:"voyce_polls_votes_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_max_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_min_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_obj_rel_insert_input:{
		data:{
			type:"voyce_polls_answers_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_polls_answers_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_on_conflict:{
		constraint:{
			type:"voyce_polls_answers_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_polls_answers_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_polls_answers_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll:{
			type:"voyce_polls_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_votes_aggregate:{
			type:"voyce_polls_votes_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_answers_select_column: "enum",
	voyce_polls_answers_set_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_stream_cursor_input:{
		initial_value:{
			type:"voyce_polls_answers_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_stream_cursor_value_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_update_column: "enum",
	voyce_polls_answers_updates:{
		_inc:{
			type:"voyce_polls_answers_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_polls_answers_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_polls_answers_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_answers_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_answers_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_arr_rel_insert_input:{
		data:{
			type:"voyce_polls_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_polls_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_avg_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_bool_exp:{
		_and:{
			type:"voyce_polls_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_polls_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_polls_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		comments_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_answers:{
			type:"voyce_polls_answers_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_answers_aggregate:{
			type:"voyce_polls_answers_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comments:{
			type:"voyce_polls_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comments_aggregate:{
			type:"voyce_polls_comments_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_reactions:{
			type:"voyce_polls_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_reactions_aggregate:{
			type:"voyce_polls_reactions_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_votes:{
			type:"voyce_polls_votes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_votes_aggregate:{
			type:"voyce_polls_votes_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments:{
		polls_comments:{
			distinct_on:{
				type:"voyce_polls_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_comments_aggregate:{
			distinct_on:{
				type:"voyce_polls_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_reactions:{
			distinct_on:{
				type:"voyce_polls_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_reactions_aggregate:{
			distinct_on:{
				type:"voyce_polls_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_polls_comments_aggregate_bool_exp:{
		count:{
			type:"voyce_polls_comments_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_polls_comments_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_polls_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_comments_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_polls_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_polls_comments_aggregate_order_by:{
		avg:{
			type:"voyce_polls_comments_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_polls_comments_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_polls_comments_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_polls_comments_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_polls_comments_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_polls_comments_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_polls_comments_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_polls_comments_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_polls_comments_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_polls_comments_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_arr_rel_insert_input:{
		data:{
			type:"voyce_polls_comments_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_polls_comments_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_bool_exp:{
		_and:{
			type:"voyce_polls_comments_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_polls_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_polls_comments_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		animated_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll:{
			type:"voyce_polls_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comment:{
			type:"voyce_polls_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comments:{
			type:"voyce_polls_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comments_aggregate:{
			type:"voyce_polls_comments_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_reactions:{
			type:"voyce_polls_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_reactions_aggregate:{
			type:"voyce_polls_reactions_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_constraint: "enum",
	voyce_polls_comments_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_insert_input:{
		animated_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll:{
			type:"voyce_polls_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comment:{
			type:"voyce_polls_comments_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comments:{
			type:"voyce_polls_comments_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_reactions:{
			type:"voyce_polls_reactions_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_max_order_by:{
		animated_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_min_order_by:{
		animated_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_obj_rel_insert_input:{
		data:{
			type:"voyce_polls_comments_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_polls_comments_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_on_conflict:{
		constraint:{
			type:"voyce_polls_comments_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_polls_comments_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_polls_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_order_by:{
		animated_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll:{
			type:"voyce_polls_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comment:{
			type:"voyce_polls_comments_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comments_aggregate:{
			type:"voyce_polls_comments_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_reactions_aggregate:{
			type:"voyce_polls_reactions_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_comments_select_column: "enum",
	voyce_polls_comments_set_input:{
		animated_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_stream_cursor_input:{
		initial_value:{
			type:"voyce_polls_comments_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_stream_cursor_value_input:{
		animated_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_update_column: "enum",
	voyce_polls_comments_updates:{
		_inc:{
			type:"voyce_polls_comments_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_polls_comments_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_polls_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_comments_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_comments_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_constraint: "enum",
	voyce_polls_inc_input:{
		comments_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_insert_input:{
		comments_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_answers:{
			type:"voyce_polls_answers_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comments:{
			type:"voyce_polls_comments_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_reactions:{
			type:"voyce_polls_reactions_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_votes:{
			type:"voyce_polls_votes_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_max_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_min_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_obj_rel_insert_input:{
		data:{
			type:"voyce_polls_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_polls_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_on_conflict:{
		constraint:{
			type:"voyce_polls_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_polls_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_polls_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_answers_aggregate:{
			type:"voyce_polls_answers_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comments_aggregate:{
			type:"voyce_polls_comments_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_reactions_aggregate:{
			type:"voyce_polls_reactions_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_votes_aggregate:{
			type:"voyce_polls_votes_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_reactions_aggregate_bool_exp:{
		count:{
			type:"voyce_polls_reactions_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_polls_reactions_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_polls_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_reactions_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_polls_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_polls_reactions_aggregate_order_by:{
		avg:{
			type:"voyce_polls_reactions_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_polls_reactions_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_polls_reactions_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_polls_reactions_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_polls_reactions_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_polls_reactions_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_polls_reactions_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_polls_reactions_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_polls_reactions_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_polls_reactions_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_arr_rel_insert_input:{
		data:{
			type:"voyce_polls_reactions_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_polls_reactions_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_avg_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_bool_exp:{
		_and:{
			type:"voyce_polls_reactions_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_polls_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_polls_reactions_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		comment_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll:{
			type:"voyce_polls_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comment:{
			type:"voyce_polls_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_constraint: "enum",
	voyce_polls_reactions_inc_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_insert_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll:{
			type:"voyce_polls_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comment:{
			type:"voyce_polls_comments_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_max_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_min_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_on_conflict:{
		constraint:{
			type:"voyce_polls_reactions_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_polls_reactions_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_polls_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll:{
			type:"voyce_polls_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comment:{
			type:"voyce_polls_comments_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_reactions_select_column: "enum",
	voyce_polls_reactions_set_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_stddev_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_stddev_pop_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_stddev_samp_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_stream_cursor_input:{
		initial_value:{
			type:"voyce_polls_reactions_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_stream_cursor_value_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_sum_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_update_column: "enum",
	voyce_polls_reactions_updates:{
		_inc:{
			type:"voyce_polls_reactions_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_polls_reactions_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_polls_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_reactions_var_pop_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_var_samp_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_reactions_variance_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_select_column: "enum",
	voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_and_arguments_columns: "enum",
	voyce_polls_select_column_voyce_polls_aggregate_bool_exp_bool_or_arguments_columns: "enum",
	voyce_polls_set_input:{
		comments_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_stddev_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_stddev_pop_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_stddev_samp_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_stream_cursor_input:{
		initial_value:{
			type:"voyce_polls_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_stream_cursor_value_input:{
		comments_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_sum_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_update_column: "enum",
	voyce_polls_updates:{
		_inc:{
			type:"voyce_polls_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_polls_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_polls_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_var_pop_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_var_samp_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_variance_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_aggregate_bool_exp:{
		count:{
			type:"voyce_polls_votes_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_polls_votes_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_polls_votes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_votes_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_polls_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_polls_votes_aggregate_order_by:{
		avg:{
			type:"voyce_polls_votes_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_polls_votes_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_polls_votes_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_polls_votes_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_polls_votes_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_polls_votes_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_polls_votes_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_polls_votes_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_polls_votes_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_polls_votes_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_arr_rel_insert_input:{
		data:{
			type:"voyce_polls_votes_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_polls_votes_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_avg_order_by:{
		answer_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_bool_exp:{
		_and:{
			type:"voyce_polls_votes_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_polls_votes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_polls_votes_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		answer_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll:{
			type:"voyce_polls_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_answer:{
			type:"voyce_polls_answers_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_constraint: "enum",
	voyce_polls_votes_inc_input:{
		answer_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_insert_input:{
		answer_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll:{
			type:"voyce_polls_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_answer:{
			type:"voyce_polls_answers_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_max_order_by:{
		answer_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_min_order_by:{
		answer_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_on_conflict:{
		constraint:{
			type:"voyce_polls_votes_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_polls_votes_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_polls_votes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_order_by:{
		answer_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll:{
			type:"voyce_polls_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_answer:{
			type:"voyce_polls_answers_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_votes_select_column: "enum",
	voyce_polls_votes_set_input:{
		answer_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_stddev_order_by:{
		answer_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_stddev_pop_order_by:{
		answer_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_stddev_samp_order_by:{
		answer_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_stream_cursor_input:{
		initial_value:{
			type:"voyce_polls_votes_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_stream_cursor_value_input:{
		answer_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_sum_order_by:{
		answer_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_update_column: "enum",
	voyce_polls_votes_updates:{
		_inc:{
			type:"voyce_polls_votes_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_polls_votes_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_polls_votes_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_polls_votes_var_pop_order_by:{
		answer_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_var_samp_order_by:{
		answer_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_polls_votes_variance_order_by:{
		answer_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		poll_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_components:{
		props:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_popup_components_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_popup_components_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_popup_components_bool_exp:{
		_and:{
			type:"voyce_popup_components_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_popup_components_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_popup_components_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		auth:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		component:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_form:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		exclusive_series:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_active:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_list_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"json_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_visibility:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_components_constraint: "enum",
	voyce_popup_components_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_components_insert_input:{
		auth:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		component:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_form:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		exclusive_series:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_list_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_visibility:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_components_obj_rel_insert_input:{
		data:{
			type:"voyce_popup_components_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_popup_components_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_components_on_conflict:{
		constraint:{
			type:"voyce_popup_components_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_popup_components_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_popup_components_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_components_order_by:{
		auth:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		component:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_form:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		exclusive_series:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_active:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_list_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_visibility:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_components_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_popup_components_select_column: "enum",
	voyce_popup_components_set_input:{
		auth:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		component:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_form:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		exclusive_series:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_list_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_visibility:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_components_stream_cursor_input:{
		initial_value:{
			type:"voyce_popup_components_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_components_stream_cursor_value_input:{
		auth:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		component:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_form:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		exclusive_series:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_list_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_visibility:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_components_update_column: "enum",
	voyce_popup_components_updates:{
		_inc:{
			type:"voyce_popup_components_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_popup_components_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_popup_components_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_popup_emails_aggregate_bool_exp:{
		count:{
			type:"voyce_popup_emails_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_popup_emails_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_popup_emails_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_popup_emails_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_popup_emails_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_popup_emails_aggregate_order_by:{
		avg:{
			type:"voyce_popup_emails_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_popup_emails_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_popup_emails_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_popup_emails_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_popup_emails_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_popup_emails_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_popup_emails_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_popup_emails_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_popup_emails_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_popup_emails_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_arr_rel_insert_input:{
		data:{
			type:"voyce_popup_emails_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_popup_emails_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popup_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_bool_exp:{
		_and:{
			type:"voyce_popup_emails_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_popup_emails_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_popup_emails_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		component:{
			type:"voyce_popup_components_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		guest_gtag:{
			type:"voyce_guest_gtags_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		popup_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_constraint: "enum",
	voyce_popup_emails_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popup_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_insert_input:{
		component:{
			type:"voyce_popup_components_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		guest_gtag:{
			type:"voyce_guest_gtags_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		popup_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_max_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popup_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_min_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popup_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_on_conflict:{
		constraint:{
			type:"voyce_popup_emails_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_popup_emails_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_popup_emails_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_order_by:{
		component:{
			type:"voyce_popup_components_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		guest_gtag:{
			type:"voyce_guest_gtags_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popup_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_popup_emails_select_column: "enum",
	voyce_popup_emails_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		popup_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popup_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popup_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popup_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_stream_cursor_input:{
		initial_value:{
			type:"voyce_popup_emails_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		popup_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popup_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_update_column: "enum",
	voyce_popup_emails_updates:{
		_inc:{
			type:"voyce_popup_emails_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_popup_emails_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_popup_emails_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_popup_emails_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popup_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popup_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_popup_emails_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popup_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts:{
		posts_comments:{
			distinct_on:{
				type:"voyce_posts_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posts_comments_aggregate:{
			distinct_on:{
				type:"voyce_posts_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posts_reactions:{
			distinct_on:{
				type:"voyce_posts_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posts_reactions_aggregate:{
			distinct_on:{
				type:"voyce_posts_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_posts_aggregate_bool_exp:{
		bool_and:{
			type:"voyce_posts_aggregate_bool_exp_bool_and",
			array:false,
			arrayRequired:false,
			required:false
		},
		bool_or:{
			type:"voyce_posts_aggregate_bool_exp_bool_or",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"voyce_posts_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_aggregate_bool_exp_bool_and:{
		arguments:{
			type:"voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_and_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_posts_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_posts_aggregate_bool_exp_bool_or:{
		arguments:{
			type:"voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_or_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_posts_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_posts_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_posts_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_posts_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_posts_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_posts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_posts_aggregate_order_by:{
		avg:{
			type:"voyce_posts_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_posts_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_posts_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_posts_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_posts_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_posts_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_posts_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_posts_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_posts_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_posts_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_arr_rel_insert_input:{
		data:{
			type:"voyce_posts_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_posts_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_avg_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_bool_exp:{
		_and:{
			type:"voyce_posts_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_posts_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_posts_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		comments_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		giphy_url:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_url:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comments:{
			type:"voyce_posts_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comments_aggregate:{
			type:"voyce_posts_comments_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_reactions:{
			type:"voyce_posts_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_reactions_aggregate:{
			type:"voyce_posts_reactions_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		video_url:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments:{
		posts_comments:{
			distinct_on:{
				type:"voyce_posts_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posts_comments_aggregate:{
			distinct_on:{
				type:"voyce_posts_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posts_reactions:{
			distinct_on:{
				type:"voyce_posts_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posts_reactions_aggregate:{
			distinct_on:{
				type:"voyce_posts_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_posts_comments_aggregate_bool_exp:{
		count:{
			type:"voyce_posts_comments_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_posts_comments_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_posts_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_posts_comments_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_posts_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_posts_comments_aggregate_order_by:{
		avg:{
			type:"voyce_posts_comments_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_posts_comments_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_posts_comments_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_posts_comments_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_posts_comments_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_posts_comments_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_posts_comments_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_posts_comments_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_posts_comments_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_posts_comments_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_arr_rel_insert_input:{
		data:{
			type:"voyce_posts_comments_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_posts_comments_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_bool_exp:{
		_and:{
			type:"voyce_posts_comments_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_posts_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_posts_comments_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		animated_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		post:{
			type:"voyce_posts_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comment:{
			type:"voyce_posts_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comments:{
			type:"voyce_posts_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comments_aggregate:{
			type:"voyce_posts_comments_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_reactions:{
			type:"voyce_posts_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_reactions_aggregate:{
			type:"voyce_posts_reactions_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_constraint: "enum",
	voyce_posts_comments_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_insert_input:{
		animated_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		post:{
			type:"voyce_posts_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comment:{
			type:"voyce_posts_comments_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comments:{
			type:"voyce_posts_comments_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_reactions:{
			type:"voyce_posts_reactions_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_max_order_by:{
		animated_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_min_order_by:{
		animated_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_obj_rel_insert_input:{
		data:{
			type:"voyce_posts_comments_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_posts_comments_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_on_conflict:{
		constraint:{
			type:"voyce_posts_comments_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_posts_comments_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_posts_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_order_by:{
		animated_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		post:{
			type:"voyce_posts_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comment:{
			type:"voyce_posts_comments_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comments_aggregate:{
			type:"voyce_posts_comments_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_reactions_aggregate:{
			type:"voyce_posts_reactions_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_posts_comments_select_column: "enum",
	voyce_posts_comments_set_input:{
		animated_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_stream_cursor_input:{
		initial_value:{
			type:"voyce_posts_comments_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_stream_cursor_value_input:{
		animated_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reacted_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_update_column: "enum",
	voyce_posts_comments_updates:{
		_inc:{
			type:"voyce_posts_comments_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_posts_comments_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_posts_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_posts_comments_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_comments_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		replies_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_constraint: "enum",
	voyce_posts_inc_input:{
		comments_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_insert_input:{
		comments_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		giphy_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comments:{
			type:"voyce_posts_comments_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_reactions:{
			type:"voyce_posts_reactions_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		video_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_max_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		giphy_url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		video_url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_min_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		giphy_url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		video_url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_obj_rel_insert_input:{
		data:{
			type:"voyce_posts_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_posts_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_on_conflict:{
		constraint:{
			type:"voyce_posts_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_posts_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_posts_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		giphy_url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comments_aggregate:{
			type:"voyce_posts_comments_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_reactions_aggregate:{
			type:"voyce_posts_reactions_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		video_url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_posts_reactions_aggregate_bool_exp:{
		count:{
			type:"voyce_posts_reactions_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_posts_reactions_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_posts_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_posts_reactions_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_posts_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_posts_reactions_aggregate_order_by:{
		avg:{
			type:"voyce_posts_reactions_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_posts_reactions_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_posts_reactions_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_posts_reactions_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_posts_reactions_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_posts_reactions_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_posts_reactions_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_posts_reactions_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_posts_reactions_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_posts_reactions_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_arr_rel_insert_input:{
		data:{
			type:"voyce_posts_reactions_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_posts_reactions_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_avg_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_bool_exp:{
		_and:{
			type:"voyce_posts_reactions_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_posts_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_posts_reactions_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		comment_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		post:{
			type:"voyce_posts_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comment:{
			type:"voyce_posts_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_constraint: "enum",
	voyce_posts_reactions_inc_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_insert_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		post:{
			type:"voyce_posts_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comment:{
			type:"voyce_posts_comments_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_max_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_min_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_on_conflict:{
		constraint:{
			type:"voyce_posts_reactions_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_posts_reactions_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_posts_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		post:{
			type:"voyce_posts_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comment:{
			type:"voyce_posts_comments_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_posts_reactions_select_column: "enum",
	voyce_posts_reactions_set_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_stddev_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_stddev_pop_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_stddev_samp_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_stream_cursor_input:{
		initial_value:{
			type:"voyce_posts_reactions_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_stream_cursor_value_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_sum_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_update_column: "enum",
	voyce_posts_reactions_updates:{
		_inc:{
			type:"voyce_posts_reactions_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_posts_reactions_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_posts_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_posts_reactions_var_pop_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_var_samp_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_reactions_variance_order_by:{
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_entity_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reaction:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_select_column: "enum",
	voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_and_arguments_columns: "enum",
	voyce_posts_select_column_voyce_posts_aggregate_bool_exp_bool_or_arguments_columns: "enum",
	voyce_posts_set_input:{
		comments_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		giphy_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		video_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_stddev_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_stddev_pop_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_stddev_samp_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_stream_cursor_input:{
		initial_value:{
			type:"voyce_posts_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_stream_cursor_value_input:{
		comments_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		giphy_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_activity_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		outdated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		video_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_sum_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_update_column: "enum",
	voyce_posts_updates:{
		_inc:{
			type:"voyce_posts_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_posts_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_posts_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_posts_var_pop_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_var_samp_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_posts_variance_order_by:{
		comments_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		popularity_weekly:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reactions_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total_popularity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quest_difficulties_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_quest_difficulties_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_quest_difficulties_bool_exp:{
		_and:{
			type:"voyce_quest_difficulties_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_quest_difficulties_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_quest_difficulties_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		coins:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		vox:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quest_difficulties_constraint: "enum",
	voyce_quest_difficulties_inc_input:{
		coins:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		vox:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quest_difficulties_insert_input:{
		coins:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		vox:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quest_difficulties_obj_rel_insert_input:{
		data:{
			type:"voyce_quest_difficulties_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_quest_difficulties_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quest_difficulties_on_conflict:{
		constraint:{
			type:"voyce_quest_difficulties_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_quest_difficulties_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_quest_difficulties_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quest_difficulties_order_by:{
		coins:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vox:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quest_difficulties_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_quest_difficulties_select_column: "enum",
	voyce_quest_difficulties_set_input:{
		coins:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		vox:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quest_difficulties_stream_cursor_input:{
		initial_value:{
			type:"voyce_quest_difficulties_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quest_difficulties_stream_cursor_value_input:{
		coins:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		vox:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quest_difficulties_update_column: "enum",
	voyce_quest_difficulties_updates:{
		_inc:{
			type:"voyce_quest_difficulties_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_quest_difficulties_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_quest_difficulties_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_quests:{
		event_data:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		reward:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		start_data:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_quests_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_quests_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_quests_bool_exp:{
		_and:{
			type:"voyce_quests_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_quests_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_quests_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		difficulty:{
			type:"voyce_quest_difficulties_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		difficulty_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_data:{
			type:"json_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_trigger:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience_points:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		initial_status:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		notif_message:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward:{
			type:"json_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_data:{
			type:"json_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_constraint: "enum",
	voyce_quests_inc_input:{
		difficulty_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience_points:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		difficulty:{
			type:"voyce_quest_difficulties_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		difficulty_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_data:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_trigger:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience_points:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		initial_status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notif_message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_data:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_obj_rel_insert_input:{
		data:{
			type:"voyce_quests_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_quests_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_old:{
		rewards_quests:{
			distinct_on:{
				type:"voyce_rewards_quest_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_rewards_quest_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_quest_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rewards_quests_aggregate:{
			distinct_on:{
				type:"voyce_rewards_quest_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_rewards_quest_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_rewards_quest_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		user_quests:{
			distinct_on:{
				type:"voyce_user_quests_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quests_old_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quests_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		user_quests_aggregate:{
			distinct_on:{
				type:"voyce_user_quests_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_user_quests_old_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_user_quests_old_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_quests_old_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_quests_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_quests_old_bool_exp:{
		_and:{
			type:"voyce_quests_old_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_quests_old_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_quests_old_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		end_date:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		recurrency:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		recurrent:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_quests:{
			type:"voyce_rewards_quest_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_quests_aggregate:{
			type:"voyce_rewards_quest_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		steps:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_quests:{
			type:"voyce_user_quests_old_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_quests_aggregate:{
			type:"voyce_user_quests_old_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_old_constraint: "enum",
	voyce_quests_old_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		recurrency:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		steps:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_old_insert_input:{
		end_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		recurrency:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		recurrent:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_quests:{
			type:"voyce_rewards_quest_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		steps:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_quests:{
			type:"voyce_user_quests_old_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_old_obj_rel_insert_input:{
		data:{
			type:"voyce_quests_old_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_quests_old_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_old_on_conflict:{
		constraint:{
			type:"voyce_quests_old_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_quests_old_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_quests_old_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_old_order_by:{
		end_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		recurrency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		recurrent:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_quests_aggregate:{
			type:"voyce_rewards_quest_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		steps:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_quests_aggregate:{
			type:"voyce_user_quests_old_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_old_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_quests_old_select_column: "enum",
	voyce_quests_old_set_input:{
		end_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		recurrency:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		recurrent:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		steps:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_old_stream_cursor_input:{
		initial_value:{
			type:"voyce_quests_old_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_old_stream_cursor_value_input:{
		end_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		recurrency:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		recurrent:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		steps:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_old_update_column: "enum",
	voyce_quests_old_updates:{
		_inc:{
			type:"voyce_quests_old_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_quests_old_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_quests_old_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_quests_on_conflict:{
		constraint:{
			type:"voyce_quests_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_quests_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_quests_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		difficulty:{
			type:"voyce_quest_difficulties_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		difficulty_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_data:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_trigger:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience_points:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		initial_status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notif_message:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_data:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_quests_select_column: "enum",
	voyce_quests_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		difficulty_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_data:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_trigger:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience_points:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		initial_status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notif_message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_data:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_stream_cursor_input:{
		initial_value:{
			type:"voyce_quests_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		difficulty_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_data:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		event_trigger:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience_points:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		initial_status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notif_message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_data:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_quests_update_column: "enum",
	voyce_quests_updates:{
		_inc:{
			type:"voyce_quests_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_quests_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_quests_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_recommended_series_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_recommended_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_recommended_series_bool_exp:{
		_and:{
			type:"voyce_recommended_series_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_recommended_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_recommended_series_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_status:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_text:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_recommended_series_constraint: "enum",
	voyce_recommended_series_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_recommended_series_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_recommended_series_on_conflict:{
		constraint:{
			type:"voyce_recommended_series_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_recommended_series_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_recommended_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_recommended_series_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_text:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_recommended_series_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_recommended_series_select_column: "enum",
	voyce_recommended_series_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_recommended_series_stream_cursor_input:{
		initial_value:{
			type:"voyce_recommended_series_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_recommended_series_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_recommended_series_update_column: "enum",
	voyce_recommended_series_updates:{
		_inc:{
			type:"voyce_recommended_series_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_recommended_series_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_recommended_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_release_schedules_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_release_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_release_schedules_bool_exp:{
		_and:{
			type:"voyce_release_schedules_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_release_schedules_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_release_schedules_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		schedule:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_release_schedules_constraint: "enum",
	voyce_release_schedules_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_release_schedules_insert_input:{
		chapter:{
			type:"voyce_chapters_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		schedule:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_release_schedules_on_conflict:{
		constraint:{
			type:"voyce_release_schedules_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_release_schedules_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_release_schedules_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_release_schedules_order_by:{
		chapter:{
			type:"voyce_chapters_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		schedule:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_release_schedules_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_release_schedules_select_column: "enum",
	voyce_release_schedules_set_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		schedule:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_release_schedules_stream_cursor_input:{
		initial_value:{
			type:"voyce_release_schedules_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_release_schedules_stream_cursor_value_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		schedule:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_release_schedules_update_column: "enum",
	voyce_release_schedules_updates:{
		_inc:{
			type:"voyce_release_schedules_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_release_schedules_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_release_schedules_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_rewards_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_rewards_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_rewards_bool_exp:{
		_and:{
			type:"voyce_rewards_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_rewards_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_rewards_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		end_date:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		expiry_date:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		free:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchaseId:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		transferable:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_constraint: "enum",
	voyce_rewards_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_insert_input:{
		end_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		expiry_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		free:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchaseId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		transferable:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_leaderboard_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_rewards_leaderboard_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_rewards_leaderboard_bool_exp:{
		_and:{
			type:"voyce_rewards_leaderboard_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_rewards_leaderboard_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_rewards_leaderboard_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward:{
			type:"voyce_rewards_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_leaderboard_constraint: "enum",
	voyce_rewards_leaderboard_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_leaderboard_insert_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward:{
			type:"voyce_rewards_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_leaderboard_on_conflict:{
		constraint:{
			type:"voyce_rewards_leaderboard_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_rewards_leaderboard_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_rewards_leaderboard_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_leaderboard_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward:{
			type:"voyce_rewards_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_leaderboard_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_rewards_leaderboard_select_column: "enum",
	voyce_rewards_leaderboard_set_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_leaderboard_stream_cursor_input:{
		initial_value:{
			type:"voyce_rewards_leaderboard_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_leaderboard_stream_cursor_value_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_leaderboard_update_column: "enum",
	voyce_rewards_leaderboard_updates:{
		_inc:{
			type:"voyce_rewards_leaderboard_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_rewards_leaderboard_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_rewards_leaderboard_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_rewards_obj_rel_insert_input:{
		data:{
			type:"voyce_rewards_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_rewards_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_on_conflict:{
		constraint:{
			type:"voyce_rewards_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_rewards_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_rewards_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_order_by:{
		end_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		expiry_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		free:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchaseId:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		transferable:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_rewards_quest_aggregate_bool_exp:{
		count:{
			type:"voyce_rewards_quest_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_rewards_quest_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_rewards_quest_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_rewards_quest_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_rewards_quest_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_rewards_quest_aggregate_order_by:{
		avg:{
			type:"voyce_rewards_quest_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_rewards_quest_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_rewards_quest_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_rewards_quest_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_rewards_quest_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_rewards_quest_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_rewards_quest_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_rewards_quest_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_rewards_quest_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_rewards_quest_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_arr_rel_insert_input:{
		data:{
			type:"voyce_rewards_quest_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_rewards_quest_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_bool_exp:{
		_and:{
			type:"voyce_rewards_quest_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_rewards_quest_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_rewards_quest_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest:{
			type:"voyce_quests_old_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward:{
			type:"voyce_rewards_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_constraint: "enum",
	voyce_rewards_quest_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_insert_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest:{
			type:"voyce_quests_old_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward:{
			type:"voyce_rewards_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_max_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_min_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_on_conflict:{
		constraint:{
			type:"voyce_rewards_quest_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_rewards_quest_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_rewards_quest_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest:{
			type:"voyce_quests_old_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reward:{
			type:"voyce_rewards_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_rewards_quest_select_column: "enum",
	voyce_rewards_quest_set_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_stream_cursor_input:{
		initial_value:{
			type:"voyce_rewards_quest_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_stream_cursor_value_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_update_column: "enum",
	voyce_rewards_quest_updates:{
		_inc:{
			type:"voyce_rewards_quest_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_rewards_quest_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_rewards_quest_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_rewards_quest_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_quest_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_select_column: "enum",
	voyce_rewards_set_input:{
		end_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		expiry_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		free:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchaseId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		transferable:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_stream_cursor_input:{
		initial_value:{
			type:"voyce_rewards_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_stream_cursor_value_input:{
		end_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		expiry_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		free:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		purchaseId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		transferable:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_rewards_update_column: "enum",
	voyce_rewards_updates:{
		_inc:{
			type:"voyce_rewards_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_rewards_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_rewards_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_sections_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_sections_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_sections_bool_exp:{
		_and:{
			type:"voyce_sections_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_sections_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_sections_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		content:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sections_constraint: "enum",
	voyce_sections_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sections_insert_input:{
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sections_on_conflict:{
		constraint:{
			type:"voyce_sections_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_sections_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_sections_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sections_order_by:{
		content:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sections_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_sections_select_column: "enum",
	voyce_sections_set_input:{
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sections_stream_cursor_input:{
		initial_value:{
			type:"voyce_sections_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sections_stream_cursor_value_input:{
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sections_update_column: "enum",
	voyce_sections_updates:{
		_inc:{
			type:"voyce_sections_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_sections_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_sections_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series:{
		chapter_comments:{
			distinct_on:{
				type:"voyce_chapter_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_comments_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_panels:{
			distinct_on:{
				type:"voyce_chapter_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_panels_aggregate:{
			distinct_on:{
				type:"voyce_chapter_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapters:{
			distinct_on:{
				type:"voyce_chapters_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapters_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapters_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapters_aggregate:{
			distinct_on:{
				type:"voyce_chapters_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapters_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapters_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		collaborators:{
			distinct_on:{
				type:"voyce_series_collaborators_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_collaborators_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_collaborators_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		collaborators_aggregate:{
			distinct_on:{
				type:"voyce_series_collaborators_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_collaborators_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_collaborators_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		featured_panels:{
			distinct_on:{
				type:"voyce_series_featured_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_featured_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_featured_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		featured_panels_aggregate:{
			distinct_on:{
				type:"voyce_series_featured_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_featured_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_featured_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		followers:{
			distinct_on:{
				type:"voyce_series_subscription_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_subscription_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_subscription_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		followers_aggregate:{
			distinct_on:{
				type:"voyce_series_subscription_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_subscription_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_subscription_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		genres:{
			distinct_on:{
				type:"voyce_series_genre_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_genre_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_genre_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		genres_aggregate:{
			distinct_on:{
				type:"voyce_series_genre_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_genre_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_genre_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		hot_panels:{
			distinct_on:{
				type:"voyce_series_hot_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_hot_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_hot_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		hot_panels_aggregate:{
			distinct_on:{
				type:"voyce_series_hot_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_hot_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_hot_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		likes:{
			distinct_on:{
				type:"voyce_chapter_likes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_likes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		likes_aggregate:{
			distinct_on:{
				type:"voyce_chapter_likes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_likes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_likes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		series_tags_junctions:{
			distinct_on:{
				type:"voyce_series_tags_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_tags_junction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_tags_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		series_tags_junctions_aggregate:{
			distinct_on:{
				type:"voyce_series_tags_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_tags_junction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_tags_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		sponsorships:{
			distinct_on:{
				type:"voyce_sponsorships_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_sponsorships_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_sponsorships_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		sponsorships_aggregate:{
			distinct_on:{
				type:"voyce_sponsorships_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_sponsorships_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_sponsorships_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		users_series_junctions:{
			distinct_on:{
				type:"voyce_users_series_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_series_junction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_series_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		users_series_junctions_aggregate:{
			distinct_on:{
				type:"voyce_users_series_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_series_junction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_series_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		views:{
			distinct_on:{
				type:"voyce_chapter_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		views_aggregate:{
			distinct_on:{
				type:"voyce_chapter_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_views_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_views_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_bool_exp:{
		_and:{
			type:"voyce_series_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		animated_cover_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		audio_allowed:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		author:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		average_rating:{
			type:"float8_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		back_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner_bg:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		book_reverse_view:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		book_view:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments:{
			type:"voyce_chapter_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_aggregate:{
			type:"voyce_chapter_comments_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_panels:{
			type:"voyce_chapter_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_panels_aggregate:{
			type:"voyce_chapter_panels_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapters:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapters_aggregate:{
			type:"voyce_chapters_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapters_hide:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		character_cover:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_price:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		collaborators:{
			type:"voyce_series_collaborators_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		collaborators_aggregate:{
			type:"voyce_series_collaborators_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		comic_type_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		cover_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		cover_image_mobile:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		csat:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		default_view:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		feature:{
			type:"bit_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		featured_panels:{
			type:"voyce_series_featured_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		featured_panels_aggregate:{
			type:"voyce_series_featured_panels_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers:{
			type:"voyce_series_subscription_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers_aggregate:{
			type:"voyce_series_subscription_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency_mask:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		front_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		genres:{
			type:"voyce_series_genre_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		genres_aggregate:{
			type:"voyce_series_genre_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		hot_panels:{
			type:"voyce_series_hot_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		hot_panels_aggregate:{
			type:"voyce_series_hot_panels_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_animated_cover_allowed:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_approved:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_intro:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_locked:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_original:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_pilots:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_recommended:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_list_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		latest_chapter_update:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes:{
			type:"voyce_chapter_likes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_aggregate:{
			type:"voyce_chapter_likes_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"voyce_series_likes_count_mat_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_config:{
			type:"voyce_series_lock_config_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		locked:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		locked_chapters_amount:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_settings:{
			type:"voyce_notification_series_settings_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		paginated_view:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		pilots_votes_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		portfolio_desc:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		scroll_view:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_tags_junctions:{
			type:"voyce_series_tags_junction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_tags_junctions_aggregate:{
			type:"voyce_series_tags_junction_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		short_desc:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		slug:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsorship_config:{
			type:"voyce_series_sponsorships_configuration_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsorships:{
			type:"voyce_sponsorships_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsorships_aggregate:{
			type:"voyce_sponsorships_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscriber_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		support_msg:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		team_spotlight:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail_box:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		trending:{
			type:"bit_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		trending_position:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"voyce_comic_types_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_display_field:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		users_series_junctions:{
			type:"voyce_users_series_junction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		users_series_junctions_aggregate:{
			type:"voyce_users_series_junction_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		views:{
			type:"voyce_chapter_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_aggregate:{
			type:"voyce_chapter_views_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_counts:{
			type:"voyce_pg_rs_series_views_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		welcome_msg:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_aggregate_bool_exp:{
		count:{
			type:"voyce_series_collaborators_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_series_collaborators_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_series_collaborators_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_collaborators_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_collaborators_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_collaborators_aggregate_order_by:{
		avg:{
			type:"voyce_series_collaborators_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_series_collaborators_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_series_collaborators_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_series_collaborators_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_series_collaborators_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_series_collaborators_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_series_collaborators_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_series_collaborators_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_series_collaborators_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_series_collaborators_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_arr_rel_insert_input:{
		data:{
			type:"voyce_series_collaborators_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_series_collaborators_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_bool_exp:{
		_and:{
			type:"voyce_series_collaborators_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_collaborators_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_collaborators_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		collaborator_role:{
			type:"voyce_collaborator_roles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_constraint: "enum",
	voyce_series_collaborators_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_insert_input:{
		collaborator_role:{
			type:"voyce_collaborator_roles_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_max_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_min_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_on_conflict:{
		constraint:{
			type:"voyce_series_collaborators_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_collaborators_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_collaborators_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_order_by:{
		collaborator_role:{
			type:"voyce_collaborator_roles_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_collaborators_select_column: "enum",
	voyce_series_collaborators_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_collaborators_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_update_column: "enum",
	voyce_series_collaborators_updates:{
		_inc:{
			type:"voyce_series_collaborators_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_collaborators_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_collaborators_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_collaborators_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_collaborators_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_constraint: "enum",
	voyce_series_donations_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_donations_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_donations_bool_exp:{
		_and:{
			type:"voyce_series_donations_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_donations_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_donations_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		amount:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_donations_constraint: "enum",
	voyce_series_donations_inc_input:{
		amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_donations_insert_input:{
		amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_donations_on_conflict:{
		constraint:{
			type:"voyce_series_donations_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_donations_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_donations_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_donations_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_donations_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_donations_select_column: "enum",
	voyce_series_donations_set_input:{
		amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_donations_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_donations_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_donations_stream_cursor_value_input:{
		amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_donations_update_column: "enum",
	voyce_series_donations_updates:{
		_inc:{
			type:"voyce_series_donations_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_donations_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_donations_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_favorites_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_favorites_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_favorites_bool_exp:{
		_and:{
			type:"voyce_series_favorites_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_favorites_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_favorites_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_favorites_constraint: "enum",
	voyce_series_favorites_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_favorites_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_favorites_on_conflict:{
		constraint:{
			type:"voyce_series_favorites_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_favorites_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_favorites_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_favorites_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_favorites_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_favorites_select_column: "enum",
	voyce_series_favorites_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_favorites_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_favorites_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_favorites_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_favorites_update_column: "enum",
	voyce_series_favorites_updates:{
		_inc:{
			type:"voyce_series_favorites_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_favorites_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_favorites_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_featured_panels_aggregate_bool_exp:{
		count:{
			type:"voyce_series_featured_panels_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_series_featured_panels_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_series_featured_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_featured_panels_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_featured_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_featured_panels_aggregate_order_by:{
		avg:{
			type:"voyce_series_featured_panels_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_series_featured_panels_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_series_featured_panels_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_series_featured_panels_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_series_featured_panels_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_series_featured_panels_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_series_featured_panels_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_series_featured_panels_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_series_featured_panels_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_series_featured_panels_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_arr_rel_insert_input:{
		data:{
			type:"voyce_series_featured_panels_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_series_featured_panels_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_bool_exp:{
		_and:{
			type:"voyce_series_featured_panels_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_featured_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_featured_panels_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_constraint: "enum",
	voyce_series_featured_panels_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_insert_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_max_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_min_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_on_conflict:{
		constraint:{
			type:"voyce_series_featured_panels_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_featured_panels_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_featured_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_featured_panels_select_column: "enum",
	voyce_series_featured_panels_set_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_featured_panels_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_stream_cursor_value_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_update_column: "enum",
	voyce_series_featured_panels_updates:{
		_inc:{
			type:"voyce_series_featured_panels_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_featured_panels_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_featured_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_featured_panels_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_featured_panels_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_aggregate_bool_exp:{
		bool_and:{
			type:"voyce_series_genre_aggregate_bool_exp_bool_and",
			array:false,
			arrayRequired:false,
			required:false
		},
		bool_or:{
			type:"voyce_series_genre_aggregate_bool_exp_bool_or",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"voyce_series_genre_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_aggregate_bool_exp_bool_and:{
		arguments:{
			type:"voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_and_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_series_genre_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_genre_aggregate_bool_exp_bool_or:{
		arguments:{
			type:"voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_or_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_series_genre_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_genre_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_series_genre_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_series_genre_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_genre_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_genre_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_genre_aggregate_order_by:{
		avg:{
			type:"voyce_series_genre_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_series_genre_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_series_genre_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_series_genre_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_series_genre_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_series_genre_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_series_genre_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_series_genre_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_series_genre_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_series_genre_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_arr_rel_insert_input:{
		data:{
			type:"voyce_series_genre_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_series_genre_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_avg_order_by:{
		genre_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_bool_exp:{
		_and:{
			type:"voyce_series_genre_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_genre_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_genre_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		genre:{
			type:"voyce_genres_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		genre_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sub_genre:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_constraint: "enum",
	voyce_series_genre_inc_input:{
		genre_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_insert_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		genre:{
			type:"voyce_genres_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		genre_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sub_genre:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_max_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		genre_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_min_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		genre_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_on_conflict:{
		constraint:{
			type:"voyce_series_genre_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_genre_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_genre_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		genre:{
			type:"voyce_genres_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		genre_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sub_genre:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_genre_select_column: "enum",
	voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_and_arguments_columns: "enum",
	voyce_series_genre_select_column_voyce_series_genre_aggregate_bool_exp_bool_or_arguments_columns: "enum",
	voyce_series_genre_set_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		genre_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sub_genre:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_stddev_order_by:{
		genre_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_stddev_pop_order_by:{
		genre_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_stddev_samp_order_by:{
		genre_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_genre_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_stream_cursor_value_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		genre_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sub_genre:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_sum_order_by:{
		genre_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_update_column: "enum",
	voyce_series_genre_updates:{
		_inc:{
			type:"voyce_series_genre_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_genre_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_genre_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_genre_var_pop_order_by:{
		genre_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_var_samp_order_by:{
		genre_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_genre_variance_order_by:{
		genre_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_giveaway_participants_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_giveaway_participants_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_giveaway_participants_bool_exp:{
		_and:{
			type:"voyce_series_giveaway_participants_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_giveaway_participants_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_giveaway_participants_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		from_series:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_giveaway_participants_constraint: "enum",
	voyce_series_giveaway_participants_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_giveaway_participants_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		from_series:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_giveaway_participants_on_conflict:{
		constraint:{
			type:"voyce_series_giveaway_participants_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_giveaway_participants_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_giveaway_participants_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_giveaway_participants_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		from_series:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_giveaway_participants_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_giveaway_participants_select_column: "enum",
	voyce_series_giveaway_participants_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		from_series:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_giveaway_participants_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_giveaway_participants_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_giveaway_participants_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		from_series:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_giveaway_participants_update_column: "enum",
	voyce_series_giveaway_participants_updates:{
		_inc:{
			type:"voyce_series_giveaway_participants_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_giveaway_participants_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_giveaway_participants_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_hot_panels_aggregate_bool_exp:{
		bool_and:{
			type:"voyce_series_hot_panels_aggregate_bool_exp_bool_and",
			array:false,
			arrayRequired:false,
			required:false
		},
		bool_or:{
			type:"voyce_series_hot_panels_aggregate_bool_exp_bool_or",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"voyce_series_hot_panels_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_aggregate_bool_exp_bool_and:{
		arguments:{
			type:"voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_and_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_series_hot_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_hot_panels_aggregate_bool_exp_bool_or:{
		arguments:{
			type:"voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_or_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_series_hot_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_hot_panels_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_series_hot_panels_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_series_hot_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_hot_panels_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_hot_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_hot_panels_aggregate_order_by:{
		avg:{
			type:"voyce_series_hot_panels_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_series_hot_panels_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_series_hot_panels_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_series_hot_panels_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_series_hot_panels_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_series_hot_panels_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_series_hot_panels_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_series_hot_panels_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_series_hot_panels_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_series_hot_panels_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_arr_rel_insert_input:{
		data:{
			type:"voyce_series_hot_panels_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_series_hot_panels_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_bool_exp:{
		_and:{
			type:"voyce_series_hot_panels_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_hot_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_hot_panels_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		active:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_constraint: "enum",
	voyce_series_hot_panels_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_insert_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_max_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_min_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_on_conflict:{
		constraint:{
			type:"voyce_series_hot_panels_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_hot_panels_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_hot_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_order_by:{
		active:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_hot_panels_select_column: "enum",
	voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_and_arguments_columns: "enum",
	voyce_series_hot_panels_select_column_voyce_series_hot_panels_aggregate_bool_exp_bool_or_arguments_columns: "enum",
	voyce_series_hot_panels_set_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_hot_panels_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_stream_cursor_value_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		spoiler:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_update_column: "enum",
	voyce_series_hot_panels_updates:{
		_inc:{
			type:"voyce_series_hot_panels_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_hot_panels_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_hot_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_hot_panels_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_hot_panels_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_inc_input:{
		average_rating:{
			type:"float8",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_price:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comic_type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency_mask:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_approved:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_intro:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_original:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_pilots:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_recommended:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		locked_chapters_amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		pilots_votes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscriber_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		trending_position:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_insert_input:{
		animated_cover_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		audio_allowed:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		author:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		average_rating:{
			type:"float8",
			array:false,
			arrayRequired:false,
			required:false
		},
		back_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner_bg:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		book_reverse_view:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		book_view:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments:{
			type:"voyce_chapter_comments_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_panels:{
			type:"voyce_chapter_panels_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapters:{
			type:"voyce_chapters_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapters_hide:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		character_cover:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_price:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		collaborators:{
			type:"voyce_series_collaborators_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		comic_type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		cover_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		cover_image_mobile:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		csat:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		default_view:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		feature:{
			type:"bit",
			array:false,
			arrayRequired:false,
			required:false
		},
		featured_panels:{
			type:"voyce_series_featured_panels_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers:{
			type:"voyce_series_subscription_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency_mask:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		front_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		genres:{
			type:"voyce_series_genre_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		hot_panels:{
			type:"voyce_series_hot_panels_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_animated_cover_allowed:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_approved:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_intro:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_locked:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_original:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_pilots:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_recommended:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_list_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		latest_chapter_update:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes:{
			type:"voyce_chapter_likes_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"voyce_series_likes_count_mat_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_config:{
			type:"voyce_series_lock_config_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		locked:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		locked_chapters_amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_settings:{
			type:"voyce_notification_series_settings_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		paginated_view:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		pilots_votes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		portfolio_desc:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		scroll_view:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_tags_junctions:{
			type:"voyce_series_tags_junction_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		short_desc:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		slug:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsorship_config:{
			type:"voyce_series_sponsorships_configuration_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsorships:{
			type:"voyce_sponsorships_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscriber_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		support_msg:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		team_spotlight:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail_box:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		trending:{
			type:"bit",
			array:false,
			arrayRequired:false,
			required:false
		},
		trending_position:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"voyce_comic_types_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_display_field:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		users_series_junctions:{
			type:"voyce_users_series_junction_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		views:{
			type:"voyce_chapter_views_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_counts:{
			type:"voyce_pg_rs_series_views_count_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		welcome_msg:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_layouts:{
		characters:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		comments:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		dl_banner:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		menu:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		socials:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		story:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_layouts_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_layouts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_layouts_bool_exp:{
		_and:{
			type:"voyce_series_layouts_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_layouts_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_layouts_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		active:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		bg_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		characters:{
			type:"json_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments:{
			type:"json_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		dl_banner:{
			type:"json_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		menu:{
			type:"json_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		socials:{
			type:"json_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		story:{
			type:"json_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_layouts_constraint: "enum",
	voyce_series_layouts_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_layouts_insert_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		bg_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		characters:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		dl_banner:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		menu:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		socials:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		story:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_layouts_on_conflict:{
		constraint:{
			type:"voyce_series_layouts_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_layouts_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_layouts_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_layouts_order_by:{
		active:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		bg_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		characters:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		dl_banner:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		menu:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		socials:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		story:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_layouts_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_layouts_select_column: "enum",
	voyce_series_layouts_set_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		bg_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		characters:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		dl_banner:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		menu:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		socials:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		story:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_layouts_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_layouts_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_layouts_stream_cursor_value_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		bg_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		characters:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		dl_banner:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		menu:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		socials:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		story:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_layouts_update_column: "enum",
	voyce_series_layouts_updates:{
		_inc:{
			type:"voyce_series_layouts_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_layouts_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_layouts_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_leaderboard_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_leaderboard_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_leaderboard_bool_exp:{
		_and:{
			type:"voyce_series_leaderboard_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_leaderboard_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_leaderboard_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		amount:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		arrow:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_leaderboard_constraint: "enum",
	voyce_series_leaderboard_inc_input:{
		amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_leaderboard_insert_input:{
		amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		arrow:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_leaderboard_on_conflict:{
		constraint:{
			type:"voyce_series_leaderboard_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_leaderboard_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_leaderboard_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_leaderboard_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		arrow:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_leaderboard_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_leaderboard_select_column: "enum",
	voyce_series_leaderboard_set_input:{
		amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		arrow:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_leaderboard_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_leaderboard_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_leaderboard_stream_cursor_value_input:{
		amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		arrow:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_leaderboard_update_column: "enum",
	voyce_series_leaderboard_updates:{
		_inc:{
			type:"voyce_series_leaderboard_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_leaderboard_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_leaderboard_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_likes_count_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_likes_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_likes_count_bool_exp:{
		_and:{
			type:"voyce_series_likes_count_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_likes_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_likes_count_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		count:{
			type:"bigint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_likes_count_mat_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_likes_count_mat_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_likes_count_mat_bool_exp:{
		_and:{
			type:"voyce_series_likes_count_mat_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_likes_count_mat_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_likes_count_mat_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		actual_count:{
			type:"bigint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"float8_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_likes_count_mat_insert_input:{
		actual_count:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"float8",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_likes_count_mat_obj_rel_insert_input:{
		data:{
			type:"voyce_series_likes_count_mat_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_likes_count_mat_order_by:{
		actual_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_likes_count_mat_select_column: "enum",
	voyce_series_likes_count_mat_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_likes_count_mat_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_likes_count_mat_stream_cursor_value_input:{
		actual_count:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"float8",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_likes_count_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_likes_count_select_column: "enum",
	voyce_series_likes_count_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_likes_count_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_likes_count_stream_cursor_value_input:{
		count:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_lock_config_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_lock_config_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_lock_config_bool_exp:{
		_and:{
			type:"voyce_series_lock_config_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_lock_config_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_lock_config_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		buy_currency:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		buy_price:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_duration:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_latest_chapters:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_currency:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_lock_config_constraint: "enum",
	voyce_series_lock_config_inc_input:{
		buy_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_duration:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_latest_chapters:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_lock_config_insert_input:{
		buy_currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		buy_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_duration:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_latest_chapters:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_lock_config_obj_rel_insert_input:{
		data:{
			type:"voyce_series_lock_config_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_series_lock_config_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_lock_config_on_conflict:{
		constraint:{
			type:"voyce_series_lock_config_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_lock_config_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_lock_config_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_lock_config_order_by:{
		buy_currency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		buy_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_latest_chapters:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_currency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_lock_config_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_lock_config_select_column: "enum",
	voyce_series_lock_config_set_input:{
		buy_currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		buy_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_duration:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_latest_chapters:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_lock_config_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_lock_config_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_lock_config_stream_cursor_value_input:{
		buy_currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		buy_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_duration:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_latest_chapters:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_duration:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_price:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_lock_config_update_column: "enum",
	voyce_series_lock_config_updates:{
		_inc:{
			type:"voyce_series_lock_config_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_lock_config_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_lock_config_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_obj_rel_insert_input:{
		data:{
			type:"voyce_series_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_series_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_on_conflict:{
		constraint:{
			type:"voyce_series_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_order_by:{
		animated_cover_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		audio_allowed:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		author:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		average_rating:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		back_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner_bg:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		book_reverse_view:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		book_view:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_aggregate:{
			type:"voyce_chapter_comments_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_panels_aggregate:{
			type:"voyce_chapter_panels_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapters_aggregate:{
			type:"voyce_chapters_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapters_hide:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		character_cover:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_price:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		collaborators_aggregate:{
			type:"voyce_series_collaborators_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comic_type_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cover_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cover_image_mobile:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		csat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		default_view:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		feature:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		featured_panels_aggregate:{
			type:"voyce_series_featured_panels_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers_aggregate:{
			type:"voyce_series_subscription_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency_mask:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		front_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		genres_aggregate:{
			type:"voyce_series_genre_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		hot_panels_aggregate:{
			type:"voyce_series_hot_panels_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_animated_cover_allowed:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_approved:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_intro:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_locked:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_original:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_pilots:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_recommended:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_list_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		latest_chapter_update:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_aggregate:{
			type:"voyce_chapter_likes_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		likes_count:{
			type:"voyce_series_likes_count_mat_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lock_config:{
			type:"voyce_series_lock_config_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		locked:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		locked_chapters_amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_settings:{
			type:"voyce_notification_series_settings_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		paginated_view:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		pilots_votes_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		portfolio_desc:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		scroll_view:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_tags_junctions_aggregate:{
			type:"voyce_series_tags_junction_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		short_desc:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		slug:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsorship_config:{
			type:"voyce_series_sponsorships_configuration_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsorships_aggregate:{
			type:"voyce_sponsorships_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscriber_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		support_msg:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		team_spotlight:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail_box:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		trending:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		trending_position:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"voyce_comic_types_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_display_field:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		users_series_junctions_aggregate:{
			type:"voyce_users_series_junction_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_aggregate:{
			type:"voyce_chapter_views_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		views_counts:{
			type:"voyce_pg_rs_series_views_count_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		welcome_msg:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_select_column: "enum",
	voyce_series_set_input:{
		animated_cover_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		audio_allowed:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		average_rating:{
			type:"float8",
			array:false,
			arrayRequired:false,
			required:false
		},
		back_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner_bg:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		book_reverse_view:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		book_view:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapters_hide:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		character_cover:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_price:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comic_type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		cover_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		cover_image_mobile:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		csat:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		default_view:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		feature:{
			type:"bit",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency_mask:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		front_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_animated_cover_allowed:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_approved:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_intro:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_locked:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_original:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_pilots:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_recommended:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_list_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		latest_chapter_update:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		locked:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		locked_chapters_amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		paginated_view:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		pilots_votes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		portfolio_desc:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		scroll_view:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		short_desc:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		slug:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscriber_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		support_msg:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		team_spotlight:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail_box:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		trending:{
			type:"bit",
			array:false,
			arrayRequired:false,
			required:false
		},
		trending_position:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_display_field:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		welcome_msg:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_sponsorships_configuration_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_sponsorships_configuration_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_sponsorships_configuration_bool_exp:{
		_and:{
			type:"voyce_series_sponsorships_configuration_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_sponsorships_configuration_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_sponsorships_configuration_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		enabled:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		voyceme_payee:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_sponsorships_configuration_constraint: "enum",
	voyce_series_sponsorships_configuration_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_sponsorships_configuration_insert_input:{
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		voyceme_payee:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_sponsorships_configuration_obj_rel_insert_input:{
		data:{
			type:"voyce_series_sponsorships_configuration_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_series_sponsorships_configuration_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_sponsorships_configuration_on_conflict:{
		constraint:{
			type:"voyce_series_sponsorships_configuration_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_sponsorships_configuration_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_sponsorships_configuration_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_sponsorships_configuration_order_by:{
		enabled:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voyceme_payee:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_sponsorships_configuration_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_sponsorships_configuration_select_column: "enum",
	voyce_series_sponsorships_configuration_set_input:{
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		voyceme_payee:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_sponsorships_configuration_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_sponsorships_configuration_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_sponsorships_configuration_stream_cursor_value_input:{
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		voyceme_payee:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_sponsorships_configuration_update_column: "enum",
	voyce_series_sponsorships_configuration_updates:{
		_inc:{
			type:"voyce_series_sponsorships_configuration_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_sponsorships_configuration_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_sponsorships_configuration_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_stream_cursor_value_input:{
		animated_cover_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		audio_allowed:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		average_rating:{
			type:"float8",
			array:false,
			arrayRequired:false,
			required:false
		},
		back_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner_bg:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		book_reverse_view:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		book_view:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapters_hide:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		character_cover:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_price:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		comic_type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		cover_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		cover_image_mobile:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		csat:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		default_view:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		feature:{
			type:"bit",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency_mask:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		front_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_animated_cover_allowed:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_approved:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_intro:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_locked:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_original:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_pilots:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_recommended:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_list_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		latest_chapter_update:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		locked:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		locked_chapters_amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		paginated_view:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		pilots_votes_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		portfolio_desc:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_view:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		scroll_view:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		short_desc:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		slug:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		subscriber_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		support_msg:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		team_spotlight:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		thumbnail_box:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		trending:{
			type:"bit",
			array:false,
			arrayRequired:false,
			required:false
		},
		trending_position:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_display_field:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		view_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		welcome_msg:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_aggregate_bool_exp:{
		count:{
			type:"voyce_series_subscription_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_series_subscription_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_series_subscription_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_subscription_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_subscription_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_subscription_aggregate_order_by:{
		avg:{
			type:"voyce_series_subscription_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_series_subscription_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_series_subscription_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_series_subscription_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_series_subscription_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_series_subscription_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_series_subscription_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_series_subscription_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_series_subscription_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_series_subscription_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_arr_rel_insert_input:{
		data:{
			type:"voyce_series_subscription_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_series_subscription_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_bool_exp:{
		_and:{
			type:"voyce_series_subscription_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_subscription_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_subscription_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_constraint: "enum",
	voyce_series_subscription_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_insert_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_max_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_min_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_on_conflict:{
		constraint:{
			type:"voyce_series_subscription_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_subscription_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_subscription_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_subscription_select_column: "enum",
	voyce_series_subscription_set_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_subscription_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_stream_cursor_value_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_update_column: "enum",
	voyce_series_subscription_updates:{
		_inc:{
			type:"voyce_series_subscription_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_subscription_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_subscription_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_subscription_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_subscription_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags:{
		series_tags_junctions:{
			distinct_on:{
				type:"voyce_series_tags_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_tags_junction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_tags_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		series_tags_junctions_aggregate:{
			distinct_on:{
				type:"voyce_series_tags_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_tags_junction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_tags_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_tags_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_tags_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_tags_bool_exp:{
		_and:{
			type:"voyce_series_tags_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_tags_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_tags_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_tags_junctions:{
			type:"voyce_series_tags_junction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_tags_junctions_aggregate:{
			type:"voyce_series_tags_junction_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_constraint: "enum",
	voyce_series_tags_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_insert_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_tags_junctions:{
			type:"voyce_series_tags_junction_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction:{
		images:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_tags_junction_aggregate_bool_exp:{
		bool_and:{
			type:"voyce_series_tags_junction_aggregate_bool_exp_bool_and",
			array:false,
			arrayRequired:false,
			required:false
		},
		bool_or:{
			type:"voyce_series_tags_junction_aggregate_bool_exp_bool_or",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"voyce_series_tags_junction_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_aggregate_bool_exp_bool_and:{
		arguments:{
			type:"voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_and_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_series_tags_junction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_tags_junction_aggregate_bool_exp_bool_or:{
		arguments:{
			type:"voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_or_arguments_columns",
			array:false,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_series_tags_junction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_tags_junction_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_series_tags_junction_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_series_tags_junction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_tags_junction_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_tags_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_tags_junction_aggregate_order_by:{
		avg:{
			type:"voyce_series_tags_junction_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_series_tags_junction_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_series_tags_junction_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_series_tags_junction_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_series_tags_junction_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_series_tags_junction_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_series_tags_junction_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_series_tags_junction_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_series_tags_junction_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_series_tags_junction_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_append_input:{
		images:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_arr_rel_insert_input:{
		data:{
			type:"voyce_series_tags_junction_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_series_tags_junction_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_avg_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_bool_exp:{
		_and:{
			type:"voyce_series_tags_junction_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_tags_junction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_tags_junction_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		admin_created:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter:{
			type:"voyce_chapters_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		images:{
			type:"jsonb_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_tag:{
			type:"voyce_series_tags_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_constraint: "enum",
	voyce_series_tags_junction_delete_at_path_input:{
		images:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_tags_junction_delete_elem_input:{
		images:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_delete_key_input:{
		images:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_insert_input:{
		admin_created:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter:{
			type:"voyce_chapters_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		images:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_tag:{
			type:"voyce_series_tags_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_max_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_min_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_on_conflict:{
		constraint:{
			type:"voyce_series_tags_junction_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_tags_junction_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_tags_junction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_order_by:{
		admin_created:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter:{
			type:"voyce_chapters_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		images:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_tag:{
			type:"voyce_series_tags_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_tags_junction_prepend_input:{
		images:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_select_column: "enum",
	voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_and_arguments_columns: "enum",
	voyce_series_tags_junction_select_column_voyce_series_tags_junction_aggregate_bool_exp_bool_or_arguments_columns: "enum",
	voyce_series_tags_junction_set_input:{
		admin_created:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		images:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_stddev_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_stddev_pop_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_stddev_samp_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_tags_junction_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_stream_cursor_value_input:{
		admin_created:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		images:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_sum_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_update_column: "enum",
	voyce_series_tags_junction_updates:{
		_append:{
			type:"voyce_series_tags_junction_append_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_delete_at_path:{
			type:"voyce_series_tags_junction_delete_at_path_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_delete_elem:{
			type:"voyce_series_tags_junction_delete_elem_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_delete_key:{
			type:"voyce_series_tags_junction_delete_key_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_inc:{
			type:"voyce_series_tags_junction_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_prepend:{
			type:"voyce_series_tags_junction_prepend_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_tags_junction_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_tags_junction_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_tags_junction_var_pop_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_var_samp_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_junction_variance_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_obj_rel_insert_input:{
		data:{
			type:"voyce_series_tags_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_series_tags_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_on_conflict:{
		constraint:{
			type:"voyce_series_tags_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_tags_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_tags_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_tags_junctions_aggregate:{
			type:"voyce_series_tags_junction_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_tags_select_column: "enum",
	voyce_series_tags_set_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_tags_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_stream_cursor_value_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_tags_update_column: "enum",
	voyce_series_tags_updates:{
		_inc:{
			type:"voyce_series_tags_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_tags_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_tags_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_top_comments_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_top_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_top_comments_bool_exp:{
		_and:{
			type:"voyce_series_top_comments_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_top_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_top_comments_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		comment:{
			type:"voyce_chapter_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_comments_constraint: "enum",
	voyce_series_top_comments_inc_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_comments_insert_input:{
		comment:{
			type:"voyce_chapter_comments_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_comments_on_conflict:{
		constraint:{
			type:"voyce_series_top_comments_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_top_comments_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_top_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_comments_order_by:{
		comment:{
			type:"voyce_chapter_comments_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_comments_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_top_comments_select_column: "enum",
	voyce_series_top_comments_set_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_comments_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_top_comments_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_comments_stream_cursor_value_input:{
		comment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_comments_update_column: "enum",
	voyce_series_top_comments_updates:{
		_inc:{
			type:"voyce_series_top_comments_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_top_comments_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_top_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_top_likes_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_top_likes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_top_likes_bool_exp:{
		_and:{
			type:"voyce_series_top_likes_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_top_likes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_top_likes_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_original:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_likes_constraint: "enum",
	voyce_series_top_likes_inc_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_original:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_likes_insert_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_original:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_likes_on_conflict:{
		constraint:{
			type:"voyce_series_top_likes_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_top_likes_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_top_likes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_likes_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_original:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_likes_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_top_likes_select_column: "enum",
	voyce_series_top_likes_set_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_original:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_likes_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_top_likes_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_likes_stream_cursor_value_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_original:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_likes_update_column: "enum",
	voyce_series_top_likes_updates:{
		_inc:{
			type:"voyce_series_top_likes_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_top_likes_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_top_likes_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_top_views_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_top_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_top_views_bool_exp:{
		_and:{
			type:"voyce_series_top_views_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_top_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_top_views_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		comic_type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		prev_rank:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_views_constraint: "enum",
	voyce_series_top_views_inc_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		prev_rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_views_insert_input:{
		comic_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		prev_rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_views_on_conflict:{
		constraint:{
			type:"voyce_series_top_views_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_top_views_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_top_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_views_order_by:{
		comic_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		prev_rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_views_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_top_views_select_column: "enum",
	voyce_series_top_views_set_input:{
		comic_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		prev_rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_views_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_top_views_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_views_stream_cursor_value_input:{
		comic_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		prev_rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rank:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_top_views_update_column: "enum",
	voyce_series_top_views_updates:{
		_inc:{
			type:"voyce_series_top_views_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_top_views_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_top_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_update_column: "enum",
	voyce_series_updates:{
		_inc:{
			type:"voyce_series_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_views_adjustment_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_views_adjustment_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_views_adjustment_bool_exp:{
		_and:{
			type:"voyce_series_views_adjustment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_views_adjustment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_views_adjustment_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_adjustment_constraint: "enum",
	voyce_series_views_adjustment_inc_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_adjustment_insert_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_adjustment_on_conflict:{
		constraint:{
			type:"voyce_series_views_adjustment_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_views_adjustment_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_views_adjustment_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_adjustment_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_adjustment_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_views_adjustment_select_column: "enum",
	voyce_series_views_adjustment_set_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_adjustment_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_views_adjustment_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_adjustment_stream_cursor_value_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_adjustment_update_column: "enum",
	voyce_series_views_adjustment_updates:{
		_inc:{
			type:"voyce_series_views_adjustment_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_views_adjustment_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_views_adjustment_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_views_count_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_views_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_views_count_bool_exp:{
		_and:{
			type:"voyce_series_views_count_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_views_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_views_count_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		count:{
			type:"bigint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_count_mat_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_views_count_mat_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_views_count_mat_bool_exp:{
		_and:{
			type:"voyce_series_views_count_mat_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_views_count_mat_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_views_count_mat_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		actual_count:{
			type:"bigint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"float8_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_count_mat_order_by:{
		actual_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_count_mat_select_column: "enum",
	voyce_series_views_count_mat_sep30_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_views_count_mat_sep30_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_views_count_mat_sep30_bool_exp:{
		_and:{
			type:"voyce_series_views_count_mat_sep30_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_views_count_mat_sep30_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_views_count_mat_sep30_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		actual_count:{
			type:"bigint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"float8_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_count_mat_sep30_order_by:{
		actual_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_count_mat_sep30_select_column: "enum",
	voyce_series_views_count_mat_sep30_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_views_count_mat_sep30_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_count_mat_sep30_stream_cursor_value_input:{
		actual_count:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"float8",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_count_mat_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_views_count_mat_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_count_mat_stream_cursor_value_input:{
		actual_count:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"float8",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_count_mat_v2_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_views_count_mat_v2_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_views_count_mat_v2_bool_exp:{
		_and:{
			type:"voyce_series_views_count_mat_v2_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_views_count_mat_v2_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_views_count_mat_v2_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		actual_count:{
			type:"bigint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"float8_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_count_mat_v2_order_by:{
		actual_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_count_mat_v2_select_column: "enum",
	voyce_series_views_count_mat_v2_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_views_count_mat_v2_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_count_mat_v2_stream_cursor_value_input:{
		actual_count:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"float8",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_count_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_count_select_column: "enum",
	voyce_series_views_count_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_views_count_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_views_count_stream_cursor_value_input:{
		count:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_votes_bool_exp:{
		_and:{
			type:"voyce_series_votes_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_votes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_votes_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_constraint: "enum",
	voyce_series_votes_count_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_votes_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_votes_count_bool_exp:{
		_and:{
			type:"voyce_series_votes_count_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_votes_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_votes_count_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		total:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_count_constraint: "enum",
	voyce_series_votes_count_inc_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		total:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_count_insert_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		total:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_count_on_conflict:{
		constraint:{
			type:"voyce_series_votes_count_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_votes_count_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_votes_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_count_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		total:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_count_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_votes_count_select_column: "enum",
	voyce_series_votes_count_set_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		total:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_count_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_votes_count_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_count_stream_cursor_value_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		total:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_count_update_column: "enum",
	voyce_series_votes_count_updates:{
		_inc:{
			type:"voyce_series_votes_count_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_votes_count_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_votes_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_votes_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_insert_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_on_conflict:{
		constraint:{
			type:"voyce_series_votes_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_votes_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_votes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_votes_select_column: "enum",
	voyce_series_votes_set_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_votes_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_stream_cursor_value_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_votes_update_column: "enum",
	voyce_series_votes_updates:{
		_inc:{
			type:"voyce_series_votes_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_votes_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_votes_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_voting_schedules_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_series_voting_schedules_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_series_voting_schedules_bool_exp:{
		_and:{
			type:"voyce_series_voting_schedules_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_series_voting_schedules_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_series_voting_schedules_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		desc:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_voting_schedules_constraint: "enum",
	voyce_series_voting_schedules_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_voting_schedules_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		desc:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_voting_schedules_on_conflict:{
		constraint:{
			type:"voyce_series_voting_schedules_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_series_voting_schedules_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_series_voting_schedules_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_voting_schedules_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		desc:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_voting_schedules_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_series_voting_schedules_select_column: "enum",
	voyce_series_voting_schedules_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		desc:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_voting_schedules_stream_cursor_input:{
		initial_value:{
			type:"voyce_series_voting_schedules_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_voting_schedules_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		desc:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_series_voting_schedules_update_column: "enum",
	voyce_series_voting_schedules_updates:{
		_inc:{
			type:"voyce_series_voting_schedules_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_series_voting_schedules_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_series_voting_schedules_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_sponsorships_aggregate_bool_exp:{
		count:{
			type:"voyce_sponsorships_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_sponsorships_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_sponsorships_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_sponsorships_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_sponsorships_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_sponsorships_aggregate_order_by:{
		avg:{
			type:"voyce_sponsorships_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_sponsorships_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_sponsorships_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_sponsorships_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_sponsorships_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_sponsorships_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_sponsorships_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_sponsorships_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_sponsorships_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_sponsorships_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_arr_rel_insert_input:{
		data:{
			type:"voyce_sponsorships_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_sponsorships_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_avg_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_bool_exp:{
		_and:{
			type:"voyce_sponsorships_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_sponsorships_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_sponsorships_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		amount:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_subscription:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_constraint: "enum",
	voyce_sponsorships_inc_input:{
		amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_insert_input:{
		amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_subscription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_max_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_subscription:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_min_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_subscription:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_on_conflict:{
		constraint:{
			type:"voyce_sponsorships_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_sponsorships_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_sponsorships_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_subscription:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_sponsorships_select_column: "enum",
	voyce_sponsorships_set_input:{
		amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_subscription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_stddev_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_stddev_pop_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_stddev_samp_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_stream_cursor_input:{
		initial_value:{
			type:"voyce_sponsorships_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_stream_cursor_value_input:{
		amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_subscription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_sum_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_update_column: "enum",
	voyce_sponsorships_updates:{
		_inc:{
			type:"voyce_sponsorships_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_sponsorships_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_sponsorships_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_sponsorships_var_pop_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_var_samp_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_sponsorships_variance_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsored_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsoring_user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_states_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_states_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_states_bool_exp:{
		_and:{
			type:"voyce_states_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_states_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_states_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		country_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		feature:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_states_constraint: "enum",
	voyce_states_inc_input:{
		country_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		feature:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_states_insert_input:{
		country_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		feature:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_states_on_conflict:{
		constraint:{
			type:"voyce_states_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_states_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_states_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_states_order_by:{
		country_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		feature:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_states_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_states_select_column: "enum",
	voyce_states_set_input:{
		country_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		feature:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_states_stream_cursor_input:{
		initial_value:{
			type:"voyce_states_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_states_stream_cursor_value_input:{
		country_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		feature:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_states_update_column: "enum",
	voyce_states_updates:{
		_inc:{
			type:"voyce_states_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_states_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_states_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_stripe_payouts_setup_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_stripe_payouts_setup_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_stripe_payouts_setup_bool_exp:{
		_and:{
			type:"voyce_stripe_payouts_setup_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_stripe_payouts_setup_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_stripe_payouts_setup_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		completed_stripe_onboarding:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		cut:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_account_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_stripe_payouts_setup_constraint: "enum",
	voyce_stripe_payouts_setup_inc_input:{
		cut:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_stripe_payouts_setup_insert_input:{
		completed_stripe_onboarding:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		cut:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_account_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_stripe_payouts_setup_obj_rel_insert_input:{
		data:{
			type:"voyce_stripe_payouts_setup_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_stripe_payouts_setup_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_stripe_payouts_setup_on_conflict:{
		constraint:{
			type:"voyce_stripe_payouts_setup_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_stripe_payouts_setup_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_stripe_payouts_setup_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_stripe_payouts_setup_order_by:{
		completed_stripe_onboarding:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cut:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_account_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_stripe_payouts_setup_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_stripe_payouts_setup_select_column: "enum",
	voyce_stripe_payouts_setup_set_input:{
		completed_stripe_onboarding:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		cut:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_account_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_stripe_payouts_setup_stream_cursor_input:{
		initial_value:{
			type:"voyce_stripe_payouts_setup_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_stripe_payouts_setup_stream_cursor_value_input:{
		completed_stripe_onboarding:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		cut:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_account_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_stripe_payouts_setup_update_column: "enum",
	voyce_stripe_payouts_setup_updates:{
		_inc:{
			type:"voyce_stripe_payouts_setup_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_stripe_payouts_setup_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_stripe_payouts_setup_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_studio:{
		followers:{
			distinct_on:{
				type:"voyce_studio_followers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_followers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		followers_aggregate:{
			distinct_on:{
				type:"voyce_studio_followers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_followers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		props:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		series:{
			distinct_on:{
				type:"voyce_studio_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		series_aggregate:{
			distinct_on:{
				type:"voyce_studio_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_studio_series_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_studio_series_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_studio_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_studio_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_studio_bool_exp:{
		_and:{
			type:"voyce_studio_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_studio_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_studio_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		active:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		discord:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		facebook:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers:{
			type:"voyce_studio_followers_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers_aggregate:{
			type:"voyce_studio_followers_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		instagram:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"json_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_studio_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_aggregate:{
			type:"voyce_studio_series_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		slug:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		tiktok:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		twitter:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		views:{
			type:"voyce_studio_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		website:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_constraint: "enum",
	voyce_studio_followers_aggregate_bool_exp:{
		count:{
			type:"voyce_studio_followers_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_studio_followers_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_studio_followers_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_studio_followers_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_studio_followers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_studio_followers_aggregate_order_by:{
		avg:{
			type:"voyce_studio_followers_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_studio_followers_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_studio_followers_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_studio_followers_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_studio_followers_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_studio_followers_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_studio_followers_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_studio_followers_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_studio_followers_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_studio_followers_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_arr_rel_insert_input:{
		data:{
			type:"voyce_studio_followers_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_studio_followers_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_bool_exp:{
		_and:{
			type:"voyce_studio_followers_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_studio_followers_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_studio_followers_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_constraint: "enum",
	voyce_studio_followers_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_max_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_min_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_on_conflict:{
		constraint:{
			type:"voyce_studio_followers_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_studio_followers_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_studio_followers_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_studio_followers_select_column: "enum",
	voyce_studio_followers_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_stream_cursor_input:{
		initial_value:{
			type:"voyce_studio_followers_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_update_column: "enum",
	voyce_studio_followers_updates:{
		_inc:{
			type:"voyce_studio_followers_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_studio_followers_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_studio_followers_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_studio_followers_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_followers_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_insert_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		discord:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		facebook:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers:{
			type:"voyce_studio_followers_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		instagram:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_studio_series_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		slug:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tiktok:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		twitter:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		views:{
			type:"voyce_studio_views_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		website:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_on_conflict:{
		constraint:{
			type:"voyce_studio_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_studio_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_studio_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_order_by:{
		active:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		discord:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		facebook:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		followers_aggregate:{
			type:"voyce_studio_followers_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		instagram:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_aggregate:{
			type:"voyce_studio_series_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		slug:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tiktok:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		twitter:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		views:{
			type:"voyce_studio_views_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		website:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_studio_select_column: "enum",
	voyce_studio_series_aggregate_bool_exp:{
		count:{
			type:"voyce_studio_series_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_studio_series_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_studio_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_studio_series_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_studio_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_studio_series_aggregate_order_by:{
		avg:{
			type:"voyce_studio_series_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_studio_series_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_studio_series_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_studio_series_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_studio_series_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_studio_series_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_studio_series_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_studio_series_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_studio_series_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_studio_series_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_arr_rel_insert_input:{
		data:{
			type:"voyce_studio_series_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_studio_series_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_bool_exp:{
		_and:{
			type:"voyce_studio_series_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_studio_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_studio_series_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_views:{
			type:"voyce_pg_rs_series_views_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_constraint: "enum",
	voyce_studio_series_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_insert_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_views:{
			type:"voyce_pg_rs_series_views_count_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_max_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_min_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_on_conflict:{
		constraint:{
			type:"voyce_studio_series_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_studio_series_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_studio_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_views:{
			type:"voyce_pg_rs_series_views_count_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_studio_series_select_column: "enum",
	voyce_studio_series_set_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_stream_cursor_input:{
		initial_value:{
			type:"voyce_studio_series_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_stream_cursor_value_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_update_column: "enum",
	voyce_studio_series_updates:{
		_inc:{
			type:"voyce_studio_series_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_studio_series_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_studio_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_studio_series_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_series_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_set_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		discord:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		facebook:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		instagram:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		slug:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tiktok:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		twitter:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		website:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_stream_cursor_input:{
		initial_value:{
			type:"voyce_studio_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_stream_cursor_value_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		discord:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		facebook:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		instagram:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		logo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		props:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		slug:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tiktok:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		twitter:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		website:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_update_column: "enum",
	voyce_studio_updates:{
		_inc:{
			type:"voyce_studio_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_studio_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_studio_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_studio_views_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_studio_views_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_studio_views_bool_exp:{
		_and:{
			type:"voyce_studio_views_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_studio_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_studio_views_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_views_constraint: "enum",
	voyce_studio_views_inc_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_views_insert_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_views_obj_rel_insert_input:{
		data:{
			type:"voyce_studio_views_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_studio_views_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_views_on_conflict:{
		constraint:{
			type:"voyce_studio_views_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_studio_views_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_studio_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_views_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_views_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_studio_views_select_column: "enum",
	voyce_studio_views_set_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_views_stream_cursor_input:{
		initial_value:{
			type:"voyce_studio_views_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_views_stream_cursor_value_input:{
		count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		studio_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_studio_views_update_column: "enum",
	voyce_studio_views_updates:{
		_inc:{
			type:"voyce_studio_views_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_studio_views_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_studio_views_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_system_settings_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_system_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_system_settings_bool_exp:{
		_and:{
			type:"voyce_system_settings_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_system_settings_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_system_settings_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_system_settings_constraint: "enum",
	voyce_system_settings_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_system_settings_insert_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_system_settings_on_conflict:{
		constraint:{
			type:"voyce_system_settings_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_system_settings_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_system_settings_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_system_settings_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_system_settings_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_system_settings_select_column: "enum",
	voyce_system_settings_set_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_system_settings_stream_cursor_input:{
		initial_value:{
			type:"voyce_system_settings_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_system_settings_stream_cursor_value_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_system_settings_update_column: "enum",
	voyce_system_settings_updates:{
		_inc:{
			type:"voyce_system_settings_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_system_settings_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_system_settings_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_top_genres:{
		series_genres:{
			distinct_on:{
				type:"voyce_series_genre_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_genre_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_genre_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		series_genres_aggregate:{
			distinct_on:{
				type:"voyce_series_genre_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_genre_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_genre_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_top_genres_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_top_genres_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_top_genres_bool_exp:{
		_and:{
			type:"voyce_top_genres_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_top_genres_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_top_genres_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		count:{
			type:"bigint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		genre:{
			type:"voyce_genres_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		genre_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_genres:{
			type:"voyce_series_genre_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_genres_aggregate:{
			type:"voyce_series_genre_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_top_genres_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		genre:{
			type:"voyce_genres_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		genre_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_genres_aggregate:{
			type:"voyce_series_genre_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_top_genres_select_column: "enum",
	voyce_top_genres_stream_cursor_input:{
		initial_value:{
			type:"voyce_top_genres_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_top_genres_stream_cursor_value_input:{
		count:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		genre_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_transaction_logs_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_transaction_logs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_transaction_logs_bool_exp:{
		_and:{
			type:"voyce_transaction_logs_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_transaction_logs_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_transaction_logs_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		amount:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		coins_type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		item_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		receipt_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		receiver_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		userByReceiverId:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		userBySenderId:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_transaction_logs_constraint: "enum",
	voyce_transaction_logs_inc_input:{
		amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		item_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		receipt_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		receiver_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_transaction_logs_insert_input:{
		amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		coins_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		item_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		receipt_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		receiver_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userByReceiverId:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		userBySenderId:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_transaction_logs_on_conflict:{
		constraint:{
			type:"voyce_transaction_logs_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_transaction_logs_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_transaction_logs_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_transaction_logs_order_by:{
		amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		coins_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		item_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		receipt_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		receiver_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		userByReceiverId:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		userBySenderId:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_transaction_logs_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_transaction_logs_select_column: "enum",
	voyce_transaction_logs_set_input:{
		amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		coins_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		item_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		receipt_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		receiver_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_transaction_logs_stream_cursor_input:{
		initial_value:{
			type:"voyce_transaction_logs_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_transaction_logs_stream_cursor_value_input:{
		amount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		coins_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		item_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		product_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		receipt_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		receiver_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sender_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_transaction_logs_update_column: "enum",
	voyce_transaction_logs_updates:{
		_inc:{
			type:"voyce_transaction_logs_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_transaction_logs_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_transaction_logs_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_url_redirects_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_url_redirects_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_url_redirects_bool_exp:{
		_and:{
			type:"voyce_url_redirects_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_url_redirects_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_url_redirects_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		path:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		redirect_url:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		views:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_url_redirects_constraint: "enum",
	voyce_url_redirects_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		views:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_url_redirects_insert_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		path:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		redirect_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		views:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_url_redirects_on_conflict:{
		constraint:{
			type:"voyce_url_redirects_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_url_redirects_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_url_redirects_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_url_redirects_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		path:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		redirect_url:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		views:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_url_redirects_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_url_redirects_select_column: "enum",
	voyce_url_redirects_set_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		path:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		redirect_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		views:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_url_redirects_stream_cursor_input:{
		initial_value:{
			type:"voyce_url_redirects_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_url_redirects_stream_cursor_value_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		path:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		redirect_url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		views:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_url_redirects_update_column: "enum",
	voyce_url_redirects_updates:{
		_inc:{
			type:"voyce_url_redirects_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_url_redirects_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_url_redirects_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_addresses_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_addresses_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_addresses_bool_exp:{
		_and:{
			type:"voyce_user_addresses_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_addresses_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_addresses_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		address:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		city_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_billing_address:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_shipping_address:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		state_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		zip_code:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_addresses_constraint: "enum",
	voyce_user_addresses_inc_input:{
		city_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_billing_address:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_shipping_address:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		state_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_addresses_insert_input:{
		address:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		city_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_billing_address:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_shipping_address:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		state_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		zip_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_addresses_on_conflict:{
		constraint:{
			type:"voyce_user_addresses_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_addresses_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_addresses_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_addresses_order_by:{
		address:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		city_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_billing_address:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_shipping_address:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		state_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		zip_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_addresses_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_addresses_select_column: "enum",
	voyce_user_addresses_set_input:{
		address:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		city_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_billing_address:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_shipping_address:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		state_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		zip_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_addresses_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_addresses_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_addresses_stream_cursor_value_input:{
		address:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		city_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_billing_address:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_shipping_address:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		state_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		zip_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_addresses_update_column: "enum",
	voyce_user_addresses_updates:{
		_inc:{
			type:"voyce_user_addresses_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_addresses_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_addresses_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_blocks_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_blocks_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_blocks_bool_exp:{
		_and:{
			type:"voyce_user_blocks_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_blocks_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_blocks_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		extras:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_blocks_constraint: "enum",
	voyce_user_blocks_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_blocks_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		extras:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_blocks_on_conflict:{
		constraint:{
			type:"voyce_user_blocks_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_blocks_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_blocks_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_blocks_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		extras:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_blocks_pk_columns_input:{
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_blocks_select_column: "enum",
	voyce_user_blocks_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		extras:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_blocks_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_blocks_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_blocks_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		extras:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_blocks_update_column: "enum",
	voyce_user_blocks_updates:{
		_inc:{
			type:"voyce_user_blocks_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_blocks_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_blocks_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_chapters_unlocked_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_chapters_unlocked_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_chapters_unlocked_bool_exp:{
		_and:{
			type:"voyce_user_chapters_unlocked_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_chapters_unlocked_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_chapters_unlocked_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		chapter_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_expired:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_chapters_unlocked_constraint: "enum",
	voyce_user_chapters_unlocked_inc_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_chapters_unlocked_insert_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_expired:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_chapters_unlocked_on_conflict:{
		constraint:{
			type:"voyce_user_chapters_unlocked_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_chapters_unlocked_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_chapters_unlocked_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_chapters_unlocked_order_by:{
		chapter_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_expired:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_chapters_unlocked_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_chapters_unlocked_select_column: "enum",
	voyce_user_chapters_unlocked_set_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_expired:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_chapters_unlocked_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_chapters_unlocked_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_chapters_unlocked_stream_cursor_value_input:{
		chapter_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_expired:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_chapters_unlocked_update_column: "enum",
	voyce_user_chapters_unlocked_updates:{
		_inc:{
			type:"voyce_user_chapters_unlocked_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_chapters_unlocked_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_chapters_unlocked_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_contacts_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_contacts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_contacts_bool_exp:{
		_and:{
			type:"voyce_user_contacts_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_contacts_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_contacts_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		contact:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_code:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_primary:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_contacts_constraint: "enum",
	voyce_user_contacts_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_primary:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_contacts_insert_input:{
		contact:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_primary:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_contacts_on_conflict:{
		constraint:{
			type:"voyce_user_contacts_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_contacts_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_contacts_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_contacts_order_by:{
		contact:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_primary:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_contacts_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_contacts_select_column: "enum",
	voyce_user_contacts_set_input:{
		contact:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_primary:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_contacts_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_contacts_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_contacts_stream_cursor_value_input:{
		contact:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_primary:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_contacts_update_column: "enum",
	voyce_user_contacts_updates:{
		_inc:{
			type:"voyce_user_contacts_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_contacts_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_contacts_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_csat_ratings_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_csat_ratings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_csat_ratings_bool_exp:{
		_and:{
			type:"voyce_user_csat_ratings_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_csat_ratings_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_csat_ratings_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		comment:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_answered:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		more_info:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		page_uri:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_csat_ratings_constraint: "enum",
	voyce_user_csat_ratings_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_csat_ratings_insert_input:{
		comment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_answered:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		more_info:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		page_uri:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_csat_ratings_on_conflict:{
		constraint:{
			type:"voyce_user_csat_ratings_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_csat_ratings_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_csat_ratings_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_csat_ratings_order_by:{
		comment:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_answered:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		more_info:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		page_uri:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_csat_ratings_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_csat_ratings_select_column: "enum",
	voyce_user_csat_ratings_set_input:{
		comment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_answered:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		more_info:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		page_uri:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_csat_ratings_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_csat_ratings_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_csat_ratings_stream_cursor_value_input:{
		comment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		gtag_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_answered:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		more_info:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		page_uri:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_csat_ratings_update_column: "enum",
	voyce_user_csat_ratings_updates:{
		_inc:{
			type:"voyce_user_csat_ratings_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_csat_ratings_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_csat_ratings_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_education_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_education_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_education_bool_exp:{
		_and:{
			type:"voyce_user_education_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_education_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_education_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		date_create:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		education_type_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		in_progress:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		institute:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		passing_date:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_education_constraint: "enum",
	voyce_user_education_inc_input:{
		education_type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		in_progress:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_education_insert_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		education_type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		in_progress:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		institute:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		passing_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_education_on_conflict:{
		constraint:{
			type:"voyce_user_education_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_education_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_education_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_education_order_by:{
		date_create:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		education_type_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		in_progress:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		institute:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		passing_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_education_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_education_select_column: "enum",
	voyce_user_education_set_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		education_type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		in_progress:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		institute:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		passing_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_education_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_education_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_education_stream_cursor_value_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		education_type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		in_progress:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		institute:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		passing_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_education_update_column: "enum",
	voyce_user_education_updates:{
		_inc:{
			type:"voyce_user_education_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_education_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_education_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_level_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_level_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_level_bool_exp:{
		_and:{
			type:"voyce_user_level_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_level_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_level_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		level:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_level_constraint: "enum",
	voyce_user_level_inc_input:{
		experience:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		level:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_level_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		level:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_level_on_conflict:{
		constraint:{
			type:"voyce_user_level_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_level_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_level_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_level_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		level:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_level_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_level_select_column: "enum",
	voyce_user_level_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		level:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_level_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_level_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_level_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		experience:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		level:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_level_update_column: "enum",
	voyce_user_level_updates:{
		_inc:{
			type:"voyce_user_level_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_level_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_level_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_linked_accounts_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_linked_accounts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_linked_accounts_bool_exp:{
		_and:{
			type:"voyce_user_linked_accounts_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_linked_accounts_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_linked_accounts_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		hash:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		identity:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		main_account:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		provider:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sub:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_linked_accounts_constraint: "enum",
	voyce_user_linked_accounts_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_linked_accounts_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		hash:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		identity:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		main_account:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		provider:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sub:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_linked_accounts_on_conflict:{
		constraint:{
			type:"voyce_user_linked_accounts_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_linked_accounts_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_linked_accounts_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_linked_accounts_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		hash:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		identity:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		main_account:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		provider:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sub:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_linked_accounts_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_linked_accounts_select_column: "enum",
	voyce_user_linked_accounts_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		hash:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		identity:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		main_account:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		provider:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sub:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_linked_accounts_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_linked_accounts_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_linked_accounts_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		hash:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		identity:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		main_account:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		provider:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sub:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_linked_accounts_update_column: "enum",
	voyce_user_linked_accounts_updates:{
		_inc:{
			type:"voyce_user_linked_accounts_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_linked_accounts_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_linked_accounts_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_login_history_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_login_history_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_login_history_bool_exp:{
		_and:{
			type:"voyce_user_login_history_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_login_history_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_login_history_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		browser:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		city:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ip:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_login:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		latitude:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		login_time:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		logout_time:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		longitude:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		os:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		region:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		token:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_agent:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_login_history_constraint: "enum",
	voyce_user_login_history_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_login:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_login_history_insert_input:{
		browser:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		ip:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_login:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		latitude:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		login_time:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		logout_time:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		longitude:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		os:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_agent:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_login_history_on_conflict:{
		constraint:{
			type:"voyce_user_login_history_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_login_history_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_login_history_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_login_history_order_by:{
		browser:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		city:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ip:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_login:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		latitude:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		login_time:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		logout_time:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		longitude:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		os:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		region:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		token:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_agent:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_login_history_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_login_history_select_column: "enum",
	voyce_user_login_history_set_input:{
		browser:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		ip:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_login:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		latitude:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		login_time:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		logout_time:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		longitude:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		os:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_agent:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_login_history_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_login_history_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_login_history_stream_cursor_value_input:{
		browser:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		ip:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_login:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		latitude:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		login_time:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		logout_time:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		longitude:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		os:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_agent:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_login_history_update_column: "enum",
	voyce_user_login_history_updates:{
		_inc:{
			type:"voyce_user_login_history_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_login_history_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_login_history_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_quest_ids_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_quest_ids_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_quest_ids_bool_exp:{
		_and:{
			type:"voyce_user_quest_ids_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_quest_ids_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_quest_ids_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest:{
			type:"voyce_user_quests_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_quest_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quest_ids_constraint: "enum",
	voyce_user_quest_ids_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quest_ids_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest:{
			type:"voyce_user_quests_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quest_ids_on_conflict:{
		constraint:{
			type:"voyce_user_quest_ids_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_quest_ids_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_quest_ids_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quest_ids_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest:{
			type:"voyce_user_quests_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quest_ids_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_quest_ids_select_column: "enum",
	voyce_user_quest_ids_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quest_ids_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_quest_ids_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quest_ids_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quest_ids_update_column: "enum",
	voyce_user_quest_ids_updates:{
		_inc:{
			type:"voyce_user_quest_ids_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_quest_ids_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_quest_ids_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_quest_rewards_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_quest_rewards_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_quest_rewards_bool_exp:{
		_and:{
			type:"voyce_user_quest_rewards_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_quest_rewards_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_quest_rewards_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		metadata:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_quest_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quest_rewards_constraint: "enum",
	voyce_user_quest_rewards_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quest_rewards_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		metadata:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quest_rewards_on_conflict:{
		constraint:{
			type:"voyce_user_quest_rewards_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_quest_rewards_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_quest_rewards_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quest_rewards_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		metadata:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quest_rewards_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_quest_rewards_select_column: "enum",
	voyce_user_quest_rewards_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		metadata:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quest_rewards_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_quest_rewards_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quest_rewards_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		metadata:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quest_rewards_update_column: "enum",
	voyce_user_quest_rewards_updates:{
		_inc:{
			type:"voyce_user_quest_rewards_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_quest_rewards_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_quest_rewards_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_quests:{
		data:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rewards_data:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_quests_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_quests_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_quests_bool_exp:{
		_and:{
			type:"voyce_user_quests_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_quests_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_quests_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		completed_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"json_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency_tag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		notified:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest:{
			type:"voyce_quests_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewarded_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_data:{
			type:"json_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		vtag:{
			type:"voyce_user_vtags_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_constraint: "enum",
	voyce_user_quests_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_insert_input:{
		completed_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency_tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		notified:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest:{
			type:"voyce_quests_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewarded_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_data:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		vtag:{
			type:"voyce_user_vtags_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_obj_rel_insert_input:{
		data:{
			type:"voyce_user_quests_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_user_quests_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_aggregate_bool_exp:{
		count:{
			type:"voyce_user_quests_old_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_user_quests_old_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_user_quests_old_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_quests_old_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_quests_old_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_quests_old_aggregate_order_by:{
		avg:{
			type:"voyce_user_quests_old_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_user_quests_old_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_user_quests_old_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_user_quests_old_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_user_quests_old_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_user_quests_old_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_user_quests_old_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_user_quests_old_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_user_quests_old_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_user_quests_old_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_arr_rel_insert_input:{
		data:{
			type:"voyce_user_quests_old_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_user_quests_old_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		progress:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_bool_exp:{
		_and:{
			type:"voyce_user_quests_old_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_quests_old_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_quests_old_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		progress:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest:{
			type:"voyce_quests_old_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_constraint: "enum",
	voyce_user_quests_old_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		progress:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_insert_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		progress:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest:{
			type:"voyce_quests_old_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_max_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		progress:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_min_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		progress:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_on_conflict:{
		constraint:{
			type:"voyce_user_quests_old_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_quests_old_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_quests_old_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		progress:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest:{
			type:"voyce_quests_old_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_quests_old_select_column: "enum",
	voyce_user_quests_old_set_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		progress:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		progress:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		progress:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		progress:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_quests_old_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_stream_cursor_value_input:{
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		progress:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		progress:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_update_column: "enum",
	voyce_user_quests_old_updates:{
		_inc:{
			type:"voyce_user_quests_old_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_quests_old_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_quests_old_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_quests_old_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		progress:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		progress:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_old_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		progress:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_on_conflict:{
		constraint:{
			type:"voyce_user_quests_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_quests_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_quests_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_order_by:{
		completed_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency_tag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notified:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest:{
			type:"voyce_quests_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewarded_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_data:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vtag:{
			type:"voyce_user_vtags_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_quests_select_column: "enum",
	voyce_user_quests_set_input:{
		completed_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency_tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		notified:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewarded_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_data:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_quests_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_stream_cursor_value_input:{
		completed_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		frequency_tag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		notified:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewarded_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		rewards_data:{
			type:"json",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_quests_update_column: "enum",
	voyce_user_quests_updates:{
		_inc:{
			type:"voyce_user_quests_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_quests_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_quests_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_ranking_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_ranking_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_ranking_bool_exp:{
		_and:{
			type:"voyce_user_ranking_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_ranking_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_ranking_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		counter:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		day:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		xp_count:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_ranking_constraint: "enum",
	voyce_user_ranking_inc_input:{
		counter:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		xp_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_ranking_insert_input:{
		counter:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		day:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		xp_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_ranking_on_conflict:{
		constraint:{
			type:"voyce_user_ranking_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_ranking_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_ranking_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_ranking_order_by:{
		counter:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		day:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		xp_count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_ranking_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_ranking_select_column: "enum",
	voyce_user_ranking_set_input:{
		counter:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		day:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		xp_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_ranking_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_ranking_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_ranking_stream_cursor_value_input:{
		counter:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		day:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		xp_count:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_ranking_update_column: "enum",
	voyce_user_ranking_updates:{
		_inc:{
			type:"voyce_user_ranking_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_ranking_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_ranking_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_reviews_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_reviews_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_reviews_bool_exp:{
		_and:{
			type:"voyce_user_reviews_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_reviews_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_reviews_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		date_create:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		review:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewer_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_reviews_constraint: "enum",
	voyce_user_reviews_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewer_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_reviews_insert_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		review:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewer_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_reviews_on_conflict:{
		constraint:{
			type:"voyce_user_reviews_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_reviews_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_reviews_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_reviews_order_by:{
		date_create:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		review:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewer_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_reviews_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_reviews_select_column: "enum",
	voyce_user_reviews_set_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		review:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewer_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_reviews_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_reviews_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_reviews_stream_cursor_value_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		publish:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		rating:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		review:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewer_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_reviews_update_column: "enum",
	voyce_user_reviews_updates:{
		_inc:{
			type:"voyce_user_reviews_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_reviews_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_reviews_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_rewards_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_rewards_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_rewards_bool_exp:{
		_and:{
			type:"voyce_user_rewards_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_rewards_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_rewards_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		coin_amount:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_rewards_constraint: "enum",
	voyce_user_rewards_inc_input:{
		coin_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_rewards_insert_input:{
		coin_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_rewards_on_conflict:{
		constraint:{
			type:"voyce_user_rewards_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_rewards_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_rewards_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_rewards_order_by:{
		coin_amount:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_rewards_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_rewards_select_column: "enum",
	voyce_user_rewards_set_input:{
		coin_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_rewards_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_rewards_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_rewards_stream_cursor_value_input:{
		coin_amount:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		coin_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_rewards_update_column: "enum",
	voyce_user_rewards_updates:{
		_inc:{
			type:"voyce_user_rewards_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_rewards_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_rewards_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_roles_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_roles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_roles_bool_exp:{
		_and:{
			type:"voyce_user_roles_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_roles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_roles_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		date_create:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_roles_constraint: "enum",
	voyce_user_roles_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_roles_insert_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_roles_obj_rel_insert_input:{
		data:{
			type:"voyce_user_roles_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_user_roles_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_roles_on_conflict:{
		constraint:{
			type:"voyce_user_roles_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_roles_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_roles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_roles_order_by:{
		date_create:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_roles_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_roles_select_column: "enum",
	voyce_user_roles_set_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_roles_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_roles_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_roles_stream_cursor_value_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_roles_update_column: "enum",
	voyce_user_roles_updates:{
		_inc:{
			type:"voyce_user_roles_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_roles_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_roles_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_series_subscription_count_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_series_subscription_count_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_series_subscription_count_bool_exp:{
		_and:{
			type:"voyce_user_series_subscription_count_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_series_subscription_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_series_subscription_count_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		count:{
			type:"bigint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_series_subscription_count_constraint: "enum",
	voyce_user_series_subscription_count_insert_input:{
		count:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_series_subscription_count_obj_rel_insert_input:{
		data:{
			type:"voyce_user_series_subscription_count_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_user_series_subscription_count_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_series_subscription_count_on_conflict:{
		constraint:{
			type:"voyce_user_series_subscription_count_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_series_subscription_count_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_series_subscription_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_series_subscription_count_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_series_subscription_count_select_column: "enum",
	voyce_user_series_subscription_count_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_series_subscription_count_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_series_subscription_count_stream_cursor_value_input:{
		count:{
			type:"bigint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_series_subscription_count_update_column: "enum",
	voyce_user_settings_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_settings_bool_exp:{
		_and:{
			type:"voyce_user_settings_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_settings_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_settings_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		content_mature:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		content_spoilers:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		page_animation:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_comic:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_manga:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_novel:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		theme:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_settings_constraint: "enum",
	voyce_user_settings_inc_input:{
		content_mature:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		content_spoilers:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		page_animation:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_settings_insert_input:{
		content_mature:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		content_spoilers:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		page_animation:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_comic:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_manga:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_novel:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		theme:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_settings_on_conflict:{
		constraint:{
			type:"voyce_user_settings_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_settings_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_settings_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_settings_order_by:{
		content_mature:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		content_spoilers:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		page_animation:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_comic:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_manga:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_novel:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		theme:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_settings_pk_columns_input:{
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_settings_select_column: "enum",
	voyce_user_settings_set_input:{
		content_mature:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		content_spoilers:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		page_animation:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_comic:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_manga:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_novel:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		theme:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_settings_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_settings_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_settings_stream_cursor_value_input:{
		content_mature:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		content_spoilers:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		page_animation:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_comic:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_manga:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reading_novel:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		theme:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_settings_update_column: "enum",
	voyce_user_settings_updates:{
		_inc:{
			type:"voyce_user_settings_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_settings_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_settings_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_skills_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_skills_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_skills_bool_exp:{
		_and:{
			type:"voyce_user_skills_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_skills_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_skills_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		date_create:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		skill:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_skills_constraint: "enum",
	voyce_user_skills_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_skills_insert_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		skill:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_skills_on_conflict:{
		constraint:{
			type:"voyce_user_skills_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_skills_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_skills_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_skills_order_by:{
		date_create:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		skill:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_skills_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_skills_select_column: "enum",
	voyce_user_skills_set_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		skill:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_skills_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_skills_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_skills_stream_cursor_value_input:{
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		skill:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_skills_update_column: "enum",
	voyce_user_skills_updates:{
		_inc:{
			type:"voyce_user_skills_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_skills_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_skills_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_socials_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_socials_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_socials_bool_exp:{
		_and:{
			type:"voyce_user_socials_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_socials_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_socials_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		access_token:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		expired_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		expires_in:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		provider:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		refresh_token:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		scope:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		token_type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_socials_constraint: "enum",
	voyce_user_socials_inc_input:{
		expires_in:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_socials_insert_input:{
		access_token:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		expired_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		expires_in:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		provider:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refresh_token:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		scope:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_socials_on_conflict:{
		constraint:{
			type:"voyce_user_socials_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_socials_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_socials_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_socials_order_by:{
		access_token:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		expired_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		expires_in:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		provider:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		refresh_token:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		scope:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		token_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_socials_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_socials_select_column: "enum",
	voyce_user_socials_set_input:{
		access_token:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		expired_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		expires_in:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		provider:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refresh_token:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		scope:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_socials_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_socials_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_socials_stream_cursor_value_input:{
		access_token:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		expired_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		expires_in:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		provider:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refresh_token:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		scope:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		token_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_socials_update_column: "enum",
	voyce_user_socials_updates:{
		_inc:{
			type:"voyce_user_socials_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_socials_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_socials_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_soft_deleted_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_soft_deleted_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_soft_deleted_bool_exp:{
		_and:{
			type:"voyce_user_soft_deleted_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_soft_deleted_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_soft_deleted_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		about:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		deleted_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_discord:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_fb:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_instagram:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_tiktok:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_twitter:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_soft_deleted_constraint: "enum",
	voyce_user_soft_deleted_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_soft_deleted_insert_input:{
		about:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		deleted_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_discord:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_fb:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_instagram:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_tiktok:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_twitter:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_soft_deleted_on_conflict:{
		constraint:{
			type:"voyce_user_soft_deleted_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_soft_deleted_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_soft_deleted_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_soft_deleted_order_by:{
		about:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deleted_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_discord:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_fb:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_instagram:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_tiktok:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_twitter:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_soft_deleted_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_soft_deleted_select_column: "enum",
	voyce_user_soft_deleted_set_input:{
		about:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		deleted_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_discord:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_fb:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_instagram:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_tiktok:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_twitter:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_soft_deleted_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_soft_deleted_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_soft_deleted_stream_cursor_value_input:{
		about:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		deleted_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_deleted:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_discord:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_fb:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_instagram:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_tiktok:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_twitter:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_soft_deleted_update_column: "enum",
	voyce_user_soft_deleted_updates:{
		_inc:{
			type:"voyce_user_soft_deleted_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_soft_deleted_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_soft_deleted_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_vtags_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_vtags_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_vtags_bool_exp:{
		_and:{
			type:"voyce_user_vtags_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_vtags_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_vtags_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		vtag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		vtag_v2:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_vtags_constraint: "enum",
	voyce_user_vtags_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_vtags_insert_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		vtag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		vtag_v2:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_vtags_obj_rel_insert_input:{
		data:{
			type:"voyce_user_vtags_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_user_vtags_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_vtags_on_conflict:{
		constraint:{
			type:"voyce_user_vtags_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_vtags_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_vtags_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_vtags_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vtag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vtag_v2:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_vtags_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_vtags_select_column: "enum",
	voyce_user_vtags_set_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		vtag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		vtag_v2:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_vtags_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_vtags_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_vtags_stream_cursor_value_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		vtag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		vtag_v2:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_vtags_update_column: "enum",
	voyce_user_vtags_updates:{
		_inc:{
			type:"voyce_user_vtags_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_vtags_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_vtags_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_wins_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_user_wins_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_user_wins_bool_exp:{
		_and:{
			type:"voyce_user_wins_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_user_wins_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_user_wins_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		claimed:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_wins_constraint: "enum",
	voyce_user_wins_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_wins_insert_input:{
		claimed:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_wins_on_conflict:{
		constraint:{
			type:"voyce_user_wins_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_user_wins_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_user_wins_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_wins_order_by:{
		claimed:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_wins_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_user_wins_select_column: "enum",
	voyce_user_wins_set_input:{
		claimed:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_wins_stream_cursor_input:{
		initial_value:{
			type:"voyce_user_wins_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_wins_stream_cursor_value_input:{
		claimed:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_user_wins_update_column: "enum",
	voyce_user_wins_updates:{
		_inc:{
			type:"voyce_user_wins_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_user_wins_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_user_wins_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_users:{
		author_profiles:{
			distinct_on:{
				type:"voyce_author_profiles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_author_profiles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_author_profiles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		author_profiles_aggregate:{
			distinct_on:{
				type:"voyce_author_profiles_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_author_profiles_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_author_profiles_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_comments:{
			distinct_on:{
				type:"voyce_chapter_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_comments_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_comments_reactions:{
			distinct_on:{
				type:"voyce_chapter_comments_reaction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reaction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reaction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		chapter_comments_reactions_aggregate:{
			distinct_on:{
				type:"voyce_chapter_comments_reaction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_chapter_comments_reaction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_chapter_comments_reaction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		creator_type_preferences:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		fcm_tokens:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		following:{
			distinct_on:{
				type:"voyce_series_subscription_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_subscription_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_subscription_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		following_aggregate:{
			distinct_on:{
				type:"voyce_series_subscription_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_subscription_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_subscription_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		hot_panels:{
			distinct_on:{
				type:"voyce_series_hot_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_hot_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_hot_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		hot_panels_aggregate:{
			distinct_on:{
				type:"voyce_series_hot_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_series_hot_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_series_hot_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		notification_series_settings:{
			distinct_on:{
				type:"voyce_notification_series_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_notification_series_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notification_series_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		notification_series_settings_aggregate:{
			distinct_on:{
				type:"voyce_notification_series_settings_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_notification_series_settings_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notification_series_settings_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		notifications:{
			distinct_on:{
				type:"voyce_notifications_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_notifications_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notifications_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		notifications_aggregate:{
			distinct_on:{
				type:"voyce_notifications_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_notifications_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_notifications_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		panels_comments:{
			distinct_on:{
				type:"voyce_panels_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		panels_comments_aggregate:{
			distinct_on:{
				type:"voyce_panels_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		panels_reactions:{
			distinct_on:{
				type:"voyce_panels_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		panels_reactions_aggregate:{
			distinct_on:{
				type:"voyce_panels_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_panels_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_panels_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		payments_to:{
			distinct_on:{
				type:"voyce_payments_to_creators_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_payments_to_creators_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_payments_to_creators_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		payments_to_aggregate:{
			distinct_on:{
				type:"voyce_payments_to_creators_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_payments_to_creators_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_payments_to_creators_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls:{
			distinct_on:{
				type:"voyce_polls_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_aggregate:{
			distinct_on:{
				type:"voyce_polls_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_comments:{
			distinct_on:{
				type:"voyce_polls_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_comments_aggregate:{
			distinct_on:{
				type:"voyce_polls_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_reactions:{
			distinct_on:{
				type:"voyce_polls_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_reactions_aggregate:{
			distinct_on:{
				type:"voyce_polls_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_votes:{
			distinct_on:{
				type:"voyce_polls_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		polls_votes_aggregate:{
			distinct_on:{
				type:"voyce_polls_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_polls_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_polls_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posts:{
			distinct_on:{
				type:"voyce_posts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posts_aggregate:{
			distinct_on:{
				type:"voyce_posts_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posts_comments:{
			distinct_on:{
				type:"voyce_posts_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posts_comments_aggregate:{
			distinct_on:{
				type:"voyce_posts_comments_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_comments_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_comments_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posts_reactions:{
			distinct_on:{
				type:"voyce_posts_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		posts_reactions_aggregate:{
			distinct_on:{
				type:"voyce_posts_reactions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_posts_reactions_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_posts_reactions_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		sponsorships:{
			distinct_on:{
				type:"voyce_sponsorships_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_sponsorships_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_sponsorships_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		sponsorshipsBySponsoredUserId:{
			distinct_on:{
				type:"voyce_sponsorships_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_sponsorships_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_sponsorships_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		sponsorshipsBySponsoredUserId_aggregate:{
			distinct_on:{
				type:"voyce_sponsorships_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_sponsorships_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_sponsorships_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		sponsorships_aggregate:{
			distinct_on:{
				type:"voyce_sponsorships_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_sponsorships_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_sponsorships_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		usersFollowersByFollowingUid:{
			distinct_on:{
				type:"voyce_users_followers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_followers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		usersFollowersByFollowingUid_aggregate:{
			distinct_on:{
				type:"voyce_users_followers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_followers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		users_followers:{
			distinct_on:{
				type:"voyce_users_followers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_followers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		users_followers_aggregate:{
			distinct_on:{
				type:"voyce_users_followers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_followers_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_followers_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		users_series_junctions:{
			distinct_on:{
				type:"voyce_users_series_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_series_junction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_series_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		users_series_junctions_aggregate:{
			distinct_on:{
				type:"voyce_users_series_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_users_series_junction_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_users_series_junction_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_users_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_users_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_users_append_input:{
		creator_type_preferences:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		fcm_tokens:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_bool_exp:{
		_and:{
			type:"voyce_users_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_users_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		about:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		account_disabled:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ad_share:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		admin_role:{
			type:"voyce_admin_roles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		admin_role_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		author_profile:{
			type:"voyce_author_profiles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		author_profiles:{
			type:"voyce_author_profiles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		author_profiles_aggregate:{
			type:"voyce_author_profiles_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar_bgcolor:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_date:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments:{
			type:"voyce_chapter_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_aggregate:{
			type:"voyce_chapter_comments_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_reactions:{
			type:"voyce_chapter_comments_reaction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_reactions_aggregate:{
			type:"voyce_chapter_comments_reaction_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		cognito_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		cognito_provider:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		cognito_username:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_type_preferences:{
			type:"jsonb_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete_code:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_verified:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_verified_source:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		fcm_token:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		fcm_tokens:{
			type:"jsonb_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		following:{
			type:"voyce_series_subscription_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_aggregate:{
			type:"voyce_series_subscription_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		frame:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		guest_gtag:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		hot_panels:{
			type:"voyce_series_hot_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		hot_panels_aggregate:{
			type:"voyce_series_hot_panels_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_approved:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_onboarded:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_publisher_verified:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_login:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_account_status:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		new_email_code:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		newsletter_subscribe:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_series_settings:{
			type:"voyce_notification_series_settings_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_series_settings_aggregate:{
			type:"voyce_notification_series_settings_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_setting:{
			type:"voyce_notification_settings_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		notifications:{
			type:"voyce_notifications_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		notifications_aggregate:{
			type:"voyce_notifications_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comments:{
			type:"voyce_panels_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comments_aggregate:{
			type:"voyce_panels_comments_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_reactions:{
			type:"voyce_panels_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_reactions_aggregate:{
			type:"voyce_panels_reactions_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_secure:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		payments_to:{
			type:"voyce_payments_to_creators_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		payments_to_aggregate:{
			type:"voyce_payments_to_creators_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls:{
			type:"voyce_polls_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_aggregate:{
			type:"voyce_polls_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comments:{
			type:"voyce_polls_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comments_aggregate:{
			type:"voyce_polls_comments_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_reactions:{
			type:"voyce_polls_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_reactions_aggregate:{
			type:"voyce_polls_reactions_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_votes:{
			type:"voyce_polls_votes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_votes_aggregate:{
			type:"voyce_polls_votes_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts:{
			type:"voyce_posts_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_aggregate:{
			type:"voyce_posts_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comments:{
			type:"voyce_posts_comments_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comments_aggregate:{
			type:"voyce_posts_comments_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_reactions:{
			type:"voyce_posts_reactions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_reactions_aggregate:{
			type:"voyce_posts_reactions_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		private_fields:{
			type:"voyce_users_private_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		publisher_verification_code:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_onboarded:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		remember_code:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reset_code:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reset_code_created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		reset_otp:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		role:{
			type:"voyce_user_roles_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_subscription_count:{
			type:"voyce_user_series_subscription_count_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		signup_source:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_discord:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_fb:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_instagram:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_tiktok:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_twitter:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsorships:{
			type:"voyce_sponsorships_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsorshipsBySponsoredUserId:{
			type:"voyce_sponsorships_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsorshipsBySponsoredUserId_aggregate:{
			type:"voyce_sponsorships_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsorships_aggregate:{
			type:"voyce_sponsorships_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_setup:{
			type:"voyce_stripe_payouts_setup_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		unsubscribe:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_type_preference:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		usersFollowersByFollowingUid:{
			type:"voyce_users_followers_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		usersFollowersByFollowingUid_aggregate:{
			type:"voyce_users_followers_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		users_followers:{
			type:"voyce_users_followers_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		users_followers_aggregate:{
			type:"voyce_users_followers_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		users_series_junctions:{
			type:"voyce_users_series_junction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		users_series_junctions_aggregate:{
			type:"voyce_users_series_junction_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		vmuser:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		vtag:{
			type:"voyce_user_vtags_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_constraint: "enum",
	voyce_users_delete_at_path_input:{
		creator_type_preferences:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		fcm_tokens:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	voyce_users_delete_elem_input:{
		creator_type_preferences:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		fcm_tokens:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_delete_key_input:{
		creator_type_preferences:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fcm_tokens:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_aggregate_bool_exp:{
		count:{
			type:"voyce_users_followers_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_users_followers_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_users_followers_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_users_followers_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_users_followers_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_users_followers_aggregate_order_by:{
		avg:{
			type:"voyce_users_followers_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_users_followers_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_users_followers_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_users_followers_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_users_followers_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_users_followers_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_users_followers_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_users_followers_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_users_followers_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_users_followers_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_arr_rel_insert_input:{
		data:{
			type:"voyce_users_followers_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_users_followers_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_avg_order_by:{
		follower_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_bool_exp:{
		_and:{
			type:"voyce_users_followers_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_users_followers_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_users_followers_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		follower_uid:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_uid:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		userByFollowingUid:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_constraint: "enum",
	voyce_users_followers_inc_input:{
		follower_uid:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_uid:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		follower_uid:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_uid:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		userByFollowingUid:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_max_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		follower_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_min_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		follower_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_on_conflict:{
		constraint:{
			type:"voyce_users_followers_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_users_followers_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_users_followers_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		follower_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		userByFollowingUid:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_users_followers_select_column: "enum",
	voyce_users_followers_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		follower_uid:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_uid:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_stddev_order_by:{
		follower_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_stddev_pop_order_by:{
		follower_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_stddev_samp_order_by:{
		follower_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_stream_cursor_input:{
		initial_value:{
			type:"voyce_users_followers_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		follower_uid:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_uid:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_sum_order_by:{
		follower_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_update_column: "enum",
	voyce_users_followers_updates:{
		_inc:{
			type:"voyce_users_followers_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_users_followers_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_users_followers_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_users_followers_var_pop_order_by:{
		follower_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_var_samp_order_by:{
		follower_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_followers_variance_order_by:{
		follower_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_uid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_inc_input:{
		ad_share:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		admin_role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_onboarded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_insert_input:{
		about:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		account_disabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		ad_share:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		admin_role:{
			type:"voyce_admin_roles_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		admin_role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		author_profile:{
			type:"voyce_author_profiles_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		author_profiles:{
			type:"voyce_author_profiles_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar_bgcolor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments:{
			type:"voyce_chapter_comments_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_reactions:{
			type:"voyce_chapter_comments_reaction_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		cognito_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		cognito_provider:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		cognito_username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_type_preferences:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_verified:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_verified_source:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fcm_token:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fcm_tokens:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		following:{
			type:"voyce_series_subscription_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		frame:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		guest_gtag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		hot_panels:{
			type:"voyce_series_hot_panels_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_approved:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_onboarded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_publisher_verified:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_login:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_account_status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		new_email_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		newsletter_subscribe:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_series_settings:{
			type:"voyce_notification_series_settings_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_setting:{
			type:"voyce_notification_settings_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		notifications:{
			type:"voyce_notifications_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comments:{
			type:"voyce_panels_comments_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_reactions:{
			type:"voyce_panels_reactions_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_secure:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		payments_to:{
			type:"voyce_payments_to_creators_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls:{
			type:"voyce_polls_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comments:{
			type:"voyce_polls_comments_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_reactions:{
			type:"voyce_polls_reactions_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_votes:{
			type:"voyce_polls_votes_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts:{
			type:"voyce_posts_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comments:{
			type:"voyce_posts_comments_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_reactions:{
			type:"voyce_posts_reactions_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		private_fields:{
			type:"voyce_users_private_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		publisher_verification_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_onboarded:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		remember_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reset_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reset_code_created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		reset_otp:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		role:{
			type:"voyce_user_roles_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_subscription_count:{
			type:"voyce_user_series_subscription_count_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		signup_source:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_discord:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_fb:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_instagram:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_tiktok:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_twitter:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsorships:{
			type:"voyce_sponsorships_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsorshipsBySponsoredUserId:{
			type:"voyce_sponsorships_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_setup:{
			type:"voyce_stripe_payouts_setup_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		unsubscribe:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_type_preference:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		usersFollowersByFollowingUid:{
			type:"voyce_users_followers_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		users_followers:{
			type:"voyce_users_followers_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		users_series_junctions:{
			type:"voyce_users_series_junction_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		vmuser:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		vtag:{
			type:"voyce_user_vtags_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_obj_rel_insert_input:{
		data:{
			type:"voyce_users_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_users_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_on_conflict:{
		constraint:{
			type:"voyce_users_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_users_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_order_by:{
		about:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		account_disabled:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ad_share:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		admin_role:{
			type:"voyce_admin_roles_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		admin_role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		author_profile:{
			type:"voyce_author_profiles_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		author_profiles_aggregate:{
			type:"voyce_author_profiles_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar_bgcolor:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_aggregate:{
			type:"voyce_chapter_comments_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		chapter_comments_reactions_aggregate:{
			type:"voyce_chapter_comments_reaction_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cognito_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cognito_provider:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cognito_username:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_type_preferences:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_verified:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_verified_source:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		fcm_token:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		fcm_tokens:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		following_aggregate:{
			type:"voyce_series_subscription_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		frame:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		guest_gtag:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		hot_panels_aggregate:{
			type:"voyce_series_hot_panels_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_approved:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_onboarded:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_publisher_verified:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_login:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_account_status:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		new_email_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		newsletter_subscribe:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_series_settings_aggregate:{
			type:"voyce_notification_series_settings_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_setting:{
			type:"voyce_notification_settings_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		notifications_aggregate:{
			type:"voyce_notifications_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_comments_aggregate:{
			type:"voyce_panels_comments_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panels_reactions_aggregate:{
			type:"voyce_panels_reactions_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_secure:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		payments_to_aggregate:{
			type:"voyce_payments_to_creators_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_aggregate:{
			type:"voyce_polls_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_comments_aggregate:{
			type:"voyce_polls_comments_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_reactions_aggregate:{
			type:"voyce_polls_reactions_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		polls_votes_aggregate:{
			type:"voyce_polls_votes_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_aggregate:{
			type:"voyce_posts_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_comments_aggregate:{
			type:"voyce_posts_comments_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		posts_reactions_aggregate:{
			type:"voyce_posts_reactions_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		private_fields:{
			type:"voyce_users_private_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		publisher_verification_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_onboarded:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		remember_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reset_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reset_code_created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		reset_otp:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role:{
			type:"voyce_user_roles_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_subscription_count:{
			type:"voyce_user_series_subscription_count_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		signup_source:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_discord:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_fb:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_instagram:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_tiktok:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_twitter:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsorshipsBySponsoredUserId_aggregate:{
			type:"voyce_sponsorships_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sponsorships_aggregate:{
			type:"voyce_sponsorships_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stripe_setup:{
			type:"voyce_stripe_payouts_setup_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		unsubscribe:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_type_preference:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		usersFollowersByFollowingUid_aggregate:{
			type:"voyce_users_followers_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		users_followers_aggregate:{
			type:"voyce_users_followers_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		users_series_junctions_aggregate:{
			type:"voyce_users_series_junction_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vmuser:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vtag:{
			type:"voyce_user_vtags_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_users_prepend_input:{
		creator_type_preferences:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		fcm_tokens:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_private_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_users_private_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_users_private_bool_exp:{
		_and:{
			type:"voyce_users_private_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_users_private_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_users_private_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		about:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		ad_share:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_date:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"smallint_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_login:{
			type:"timestamp_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		remember_code:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		vmuser:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_private_inc_input:{
		ad_share:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_private_insert_input:{
		about:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ad_share:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_login:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		remember_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		vmuser:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_private_obj_rel_insert_input:{
		data:{
			type:"voyce_users_private_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_users_private_order_by:{
		about:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		ad_share:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_login:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		remember_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vmuser:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_private_select_column: "enum",
	voyce_users_private_set_input:{
		about:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ad_share:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_login:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		remember_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		vmuser:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_private_stream_cursor_input:{
		initial_value:{
			type:"voyce_users_private_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_private_stream_cursor_value_input:{
		about:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ad_share:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_login:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		remember_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		vmuser:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_private_updates:{
		_inc:{
			type:"voyce_users_private_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_users_private_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_users_private_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_users_select_column: "enum",
	voyce_users_series_junction_aggregate_bool_exp:{
		count:{
			type:"voyce_users_series_junction_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_users_series_junction_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_users_series_junction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_users_series_junction_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_users_series_junction_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_users_series_junction_aggregate_order_by:{
		avg:{
			type:"voyce_users_series_junction_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_users_series_junction_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_users_series_junction_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_users_series_junction_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_users_series_junction_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_users_series_junction_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_users_series_junction_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_users_series_junction_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_users_series_junction_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_users_series_junction_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_arr_rel_insert_input:{
		data:{
			type:"voyce_users_series_junction_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_users_series_junction_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_bool_exp:{
		_and:{
			type:"voyce_users_series_junction_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_users_series_junction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_users_series_junction_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_constraint: "enum",
	voyce_users_series_junction_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_insert_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_max_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_min_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_on_conflict:{
		constraint:{
			type:"voyce_users_series_junction_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_users_series_junction_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_users_series_junction_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_users_series_junction_select_column: "enum",
	voyce_users_series_junction_set_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_stream_cursor_input:{
		initial_value:{
			type:"voyce_users_series_junction_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_stream_cursor_value_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_update_column: "enum",
	voyce_users_series_junction_updates:{
		_inc:{
			type:"voyce_users_series_junction_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_users_series_junction_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_users_series_junction_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_users_series_junction_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_series_junction_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_set_input:{
		about:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		account_disabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		ad_share:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		admin_role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar_bgcolor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		cognito_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		cognito_provider:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		cognito_username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_type_preferences:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_verified:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_verified_source:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fcm_token:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fcm_tokens:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		frame:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		guest_gtag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_approved:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_onboarded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_publisher_verified:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_login:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_account_status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		new_email_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		newsletter_subscribe:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_secure:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		publisher_verification_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_onboarded:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		remember_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reset_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reset_code_created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		reset_otp:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		signup_source:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_discord:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_fb:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_instagram:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_tiktok:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_twitter:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		unsubscribe:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_type_preference:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		vmuser:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_stream_cursor_input:{
		initial_value:{
			type:"voyce_users_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_stream_cursor_value_input:{
		about:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		account_disabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		ad_share:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		admin_role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatar_bgcolor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		banner:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		cognito_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		cognito_provider:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		cognito_username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		creator_type_preferences:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		dark_mode:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_create:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_verified:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		email_verified_source:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fcm_token:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fcm_tokens:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		frame:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		guest_gtag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_account_active:{
			type:"smallint",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_approved:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_onboarded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_publisher_verified:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		klaviyo_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_login:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		linked_account_status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		new_email_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		newsletter_subscribe:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		nickname:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password_secure:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		publisher_verification_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		quest_onboarded:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		remember_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reset_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		reset_code_created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		reset_otp:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		signup_source:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_discord:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_fb:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_instagram:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_tiktok:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		social_twitter:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		unsubscribe:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_type_preference:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		vmuser:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_users_update_column: "enum",
	voyce_users_updates:{
		_append:{
			type:"voyce_users_append_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_delete_at_path:{
			type:"voyce_users_delete_at_path_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_delete_elem:{
			type:"voyce_users_delete_elem_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_delete_key:{
			type:"voyce_users_delete_key_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_inc:{
			type:"voyce_users_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_prepend:{
			type:"voyce_users_prepend_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_users_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_versions_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_versions_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_versions_bool_exp:{
		_and:{
			type:"voyce_versions_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_versions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_versions_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		table:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		version:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_versions_constraint: "enum",
	voyce_versions_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		version:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_versions_insert_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		table:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		version:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_versions_on_conflict:{
		constraint:{
			type:"voyce_versions_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_versions_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_versions_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_versions_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		table:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		version:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_versions_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_versions_select_column: "enum",
	voyce_versions_set_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		table:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		version:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_versions_stream_cursor_input:{
		initial_value:{
			type:"voyce_versions_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_versions_stream_cursor_value_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		table:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		version:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_versions_update_column: "enum",
	voyce_versions_updates:{
		_inc:{
			type:"voyce_versions_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_versions_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_versions_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_voting_analytics_aggregate_bool_exp:{
		count:{
			type:"voyce_voting_analytics_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_voting_analytics_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_voting_analytics_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_voting_analytics_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_voting_analytics_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_voting_analytics_aggregate_order_by:{
		avg:{
			type:"voyce_voting_analytics_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_voting_analytics_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_voting_analytics_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_voting_analytics_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_voting_analytics_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_voting_analytics_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_voting_analytics_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_voting_analytics_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_voting_analytics_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_voting_analytics_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_arr_rel_insert_input:{
		data:{
			type:"voyce_voting_analytics_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_voting_analytics_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_avg_order_by:{
		cumulative_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		daily_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_bool_exp:{
		_and:{
			type:"voyce_voting_analytics_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_voting_analytics_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_voting_analytics_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		cumulative_votes:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		daily_votes:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		meta:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		vote_date:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series:{
			type:"voyce_voting_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_constraint: "enum",
	voyce_voting_analytics_inc_input:{
		cumulative_votes:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		daily_votes:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		cumulative_votes:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		daily_votes:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		meta:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		vote_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series:{
			type:"voyce_voting_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_max_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cumulative_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		daily_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		meta:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vote_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_min_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cumulative_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		daily_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		meta:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vote_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_on_conflict:{
		constraint:{
			type:"voyce_voting_analytics_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_voting_analytics_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_voting_analytics_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cumulative_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		daily_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		meta:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vote_date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series:{
			type:"voyce_voting_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_voting_analytics_select_column: "enum",
	voyce_voting_analytics_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		cumulative_votes:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		daily_votes:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		meta:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		vote_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_stddev_order_by:{
		cumulative_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		daily_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_stddev_pop_order_by:{
		cumulative_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		daily_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_stddev_samp_order_by:{
		cumulative_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		daily_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_stream_cursor_input:{
		initial_value:{
			type:"voyce_voting_analytics_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		cumulative_votes:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		daily_votes:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		meta:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		vote_date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_sum_order_by:{
		cumulative_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		daily_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_update_column: "enum",
	voyce_voting_analytics_updates:{
		_inc:{
			type:"voyce_voting_analytics_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_voting_analytics_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_voting_analytics_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_voting_analytics_var_pop_order_by:{
		cumulative_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		daily_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_var_samp_order_by:{
		cumulative_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		daily_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_analytics_variance_order_by:{
		cumulative_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		daily_votes:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_aggregate_bool_exp:{
		count:{
			type:"voyce_voting_panels_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_voting_panels_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_voting_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_voting_panels_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_voting_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_voting_panels_aggregate_order_by:{
		avg:{
			type:"voyce_voting_panels_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_voting_panels_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_voting_panels_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_voting_panels_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_voting_panels_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_voting_panels_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_voting_panels_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_voting_panels_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_voting_panels_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_voting_panels_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_arr_rel_insert_input:{
		data:{
			type:"voyce_voting_panels_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_voting_panels_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_bool_exp:{
		_and:{
			type:"voyce_voting_panels_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_voting_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_voting_panels_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series:{
			type:"voyce_voting_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_constraint: "enum",
	voyce_voting_panels_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series:{
			type:"voyce_voting_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_max_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_min_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_on_conflict:{
		constraint:{
			type:"voyce_voting_panels_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_voting_panels_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_voting_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series:{
			type:"voyce_voting_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_voting_panels_select_column: "enum",
	voyce_voting_panels_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_stream_cursor_input:{
		initial_value:{
			type:"voyce_voting_panels_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_stream_cursor_value_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		panel_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_update_column: "enum",
	voyce_voting_panels_updates:{
		_inc:{
			type:"voyce_voting_panels_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_voting_panels_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_voting_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_voting_panels_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_panels_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_series:{
		voting_analytics:{
			distinct_on:{
				type:"voyce_voting_analytics_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_analytics_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_analytics_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voting_analytics_aggregate:{
			distinct_on:{
				type:"voyce_voting_analytics_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_analytics_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_analytics_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voting_panels:{
			distinct_on:{
				type:"voyce_voting_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voting_panels_aggregate:{
			distinct_on:{
				type:"voyce_voting_panels_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_panels_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_panels_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voting_user_votes:{
			distinct_on:{
				type:"voyce_voting_user_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_user_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_user_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		voting_user_votes_aggregate:{
			distinct_on:{
				type:"voyce_voting_user_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"voyce_voting_user_votes_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"voyce_voting_user_votes_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_voting_series_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_voting_series_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_voting_series_bool_exp:{
		_and:{
			type:"voyce_voting_series_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_voting_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_voting_series_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		author_image:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		author_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		collaborators:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_caption:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_img1:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_img2:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_img3:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_analytics:{
			type:"voyce_voting_analytics_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_analytics_aggregate:{
			type:"voyce_voting_analytics_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_panels:{
			type:"voyce_voting_panels_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_panels_aggregate:{
			type:"voyce_voting_panels_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_user_votes:{
			type:"voyce_voting_user_votes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_user_votes_aggregate:{
			type:"voyce_voting_user_votes_aggregate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_series_constraint: "enum",
	voyce_voting_series_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_series_insert_input:{
		author_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		author_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		collaborators:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_caption:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_img1:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_img2:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_img3:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_analytics:{
			type:"voyce_voting_analytics_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_panels:{
			type:"voyce_voting_panels_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_user_votes:{
			type:"voyce_voting_user_votes_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_series_obj_rel_insert_input:{
		data:{
			type:"voyce_voting_series_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"voyce_voting_series_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_series_on_conflict:{
		constraint:{
			type:"voyce_voting_series_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_voting_series_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_voting_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_series_order_by:{
		author_image:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		author_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		collaborators:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series:{
			type:"voyce_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_caption:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_img1:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_img2:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_img3:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_analytics_aggregate:{
			type:"voyce_voting_analytics_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_panels_aggregate:{
			type:"voyce_voting_panels_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_user_votes_aggregate:{
			type:"voyce_voting_user_votes_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_series_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_voting_series_select_column: "enum",
	voyce_voting_series_set_input:{
		author_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		author_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		collaborators:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_caption:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_img1:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_img2:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_img3:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_series_stream_cursor_input:{
		initial_value:{
			type:"voyce_voting_series_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_series_stream_cursor_value_input:{
		author_image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		author_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		collaborators:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_caption:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_img1:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_img2:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		spotlight_img3:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_series_update_column: "enum",
	voyce_voting_series_updates:{
		_inc:{
			type:"voyce_voting_series_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_voting_series_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_voting_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_voting_user_votes_aggregate_bool_exp:{
		count:{
			type:"voyce_voting_user_votes_aggregate_bool_exp_count",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_aggregate_bool_exp_count:{
		arguments:{
			type:"voyce_voting_user_votes_select_column",
			array:true,
			arrayRequired:false,
			required:true
		},
		distinct:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"voyce_voting_user_votes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		predicate:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_voting_user_votes_aggregate_fields:{
		count:{
			columns:{
				type:"voyce_voting_user_votes_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	voyce_voting_user_votes_aggregate_order_by:{
		avg:{
			type:"voyce_voting_user_votes_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"voyce_voting_user_votes_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"voyce_voting_user_votes_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"voyce_voting_user_votes_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"voyce_voting_user_votes_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"voyce_voting_user_votes_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"voyce_voting_user_votes_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"voyce_voting_user_votes_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"voyce_voting_user_votes_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"voyce_voting_user_votes_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_arr_rel_insert_input:{
		data:{
			type:"voyce_voting_user_votes_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"voyce_voting_user_votes_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_avg_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_bool_exp:{
		_and:{
			type:"voyce_voting_user_votes_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"voyce_voting_user_votes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"voyce_voting_user_votes_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		date_voted:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		time_voted:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series:{
			type:"voyce_voting_series_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_constraint: "enum",
	voyce_voting_user_votes_inc_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_insert_input:{
		date_voted:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		time_voted:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series:{
			type:"voyce_voting_series_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_max_order_by:{
		date_voted:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		time_voted:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_min_order_by:{
		date_voted:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		time_voted:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_on_conflict:{
		constraint:{
			type:"voyce_voting_user_votes_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"voyce_voting_user_votes_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"voyce_voting_user_votes_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_order_by:{
		date_voted:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		time_voted:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"voyce_users_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series:{
			type:"voyce_voting_series_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_pk_columns_input:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_voting_user_votes_select_column: "enum",
	voyce_voting_user_votes_set_input:{
		date_voted:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		time_voted:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_stddev_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_stddev_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_stddev_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_stream_cursor_input:{
		initial_value:{
			type:"voyce_voting_user_votes_stream_cursor_value_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		ordering:{
			type:"cursor_ordering",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_stream_cursor_value_input:{
		date_voted:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		time_voted:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_sum_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_update_column: "enum",
	voyce_voting_user_votes_updates:{
		_inc:{
			type:"voyce_voting_user_votes_inc_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		_set:{
			type:"voyce_voting_user_votes_set_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		where:{
			type:"voyce_voting_user_votes_bool_exp",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	voyce_voting_user_votes_var_pop_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_var_samp_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	voyce_voting_user_votes_variance_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		voting_series_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	}
}

export const ReturnTypes: Record<string,any> = {
	cached:{
		ttl:"Int",
		refresh:"Boolean"
	},
	AddResult:{
		sum:"Int"
	},
	awsdms_validation_failures_v1:{
		DETAILS:"String",
		FAILURE_TIME:"timestamp",
		FAILURE_TYPE:"String",
		KEY:"String",
		KEY_TYPE:"String",
		TABLE_NAME:"String",
		TABLE_OWNER:"String",
		TASK_NAME:"String"
	},
	awsdms_validation_failures_v1_aggregate:{
		aggregate:"awsdms_validation_failures_v1_aggregate_fields",
		nodes:"awsdms_validation_failures_v1"
	},
	awsdms_validation_failures_v1_aggregate_fields:{
		count:"Int",
		max:"awsdms_validation_failures_v1_max_fields",
		min:"awsdms_validation_failures_v1_min_fields"
	},
	awsdms_validation_failures_v1_max_fields:{
		DETAILS:"String",
		FAILURE_TIME:"timestamp",
		FAILURE_TYPE:"String",
		KEY:"String",
		KEY_TYPE:"String",
		TABLE_NAME:"String",
		TABLE_OWNER:"String",
		TASK_NAME:"String"
	},
	awsdms_validation_failures_v1_min_fields:{
		DETAILS:"String",
		FAILURE_TIME:"timestamp",
		FAILURE_TYPE:"String",
		KEY:"String",
		KEY_TYPE:"String",
		TABLE_NAME:"String",
		TABLE_OWNER:"String",
		TASK_NAME:"String"
	},
	awsdms_validation_failures_v1_mutation_response:{
		affected_rows:"Int",
		returning:"awsdms_validation_failures_v1"
	},
	coinsystem_balances:{
		balance:"numeric",
		coin:"coinsystem_coins",
		coin_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		updated_at:"timestamptz",
		user:"voyce_users",
		user_id:"Int",
		wallet:"coinsystem_wallets",
		wallet_id:"Int"
	},
	coinsystem_balances_aggregate:{
		aggregate:"coinsystem_balances_aggregate_fields",
		nodes:"coinsystem_balances"
	},
	coinsystem_balances_aggregate_fields:{
		avg:"coinsystem_balances_avg_fields",
		count:"Int",
		max:"coinsystem_balances_max_fields",
		min:"coinsystem_balances_min_fields",
		stddev:"coinsystem_balances_stddev_fields",
		stddev_pop:"coinsystem_balances_stddev_pop_fields",
		stddev_samp:"coinsystem_balances_stddev_samp_fields",
		sum:"coinsystem_balances_sum_fields",
		var_pop:"coinsystem_balances_var_pop_fields",
		var_samp:"coinsystem_balances_var_samp_fields",
		variance:"coinsystem_balances_variance_fields"
	},
	coinsystem_balances_avg_fields:{
		balance:"Float",
		coin_id:"Float",
		id:"Float",
		user_id:"Float",
		wallet_id:"Float"
	},
	coinsystem_balances_max_fields:{
		balance:"numeric",
		coin_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		updated_at:"timestamptz",
		user_id:"Int",
		wallet_id:"Int"
	},
	coinsystem_balances_min_fields:{
		balance:"numeric",
		coin_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		updated_at:"timestamptz",
		user_id:"Int",
		wallet_id:"Int"
	},
	coinsystem_balances_mutation_response:{
		affected_rows:"Int",
		returning:"coinsystem_balances"
	},
	coinsystem_balances_stddev_fields:{
		balance:"Float",
		coin_id:"Float",
		id:"Float",
		user_id:"Float",
		wallet_id:"Float"
	},
	coinsystem_balances_stddev_pop_fields:{
		balance:"Float",
		coin_id:"Float",
		id:"Float",
		user_id:"Float",
		wallet_id:"Float"
	},
	coinsystem_balances_stddev_samp_fields:{
		balance:"Float",
		coin_id:"Float",
		id:"Float",
		user_id:"Float",
		wallet_id:"Float"
	},
	coinsystem_balances_sum_fields:{
		balance:"numeric",
		coin_id:"Int",
		id:"Int",
		user_id:"Int",
		wallet_id:"Int"
	},
	coinsystem_balances_var_pop_fields:{
		balance:"Float",
		coin_id:"Float",
		id:"Float",
		user_id:"Float",
		wallet_id:"Float"
	},
	coinsystem_balances_var_samp_fields:{
		balance:"Float",
		coin_id:"Float",
		id:"Float",
		user_id:"Float",
		wallet_id:"Float"
	},
	coinsystem_balances_variance_fields:{
		balance:"Float",
		coin_id:"Float",
		id:"Float",
		user_id:"Float",
		wallet_id:"Float"
	},
	coinsystem_bonus_coins:{
		active:"Boolean",
		bonus_amount:"numeric",
		coin_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		max_amount:"numeric",
		min_amount:"numeric"
	},
	coinsystem_bonus_coins_aggregate:{
		aggregate:"coinsystem_bonus_coins_aggregate_fields",
		nodes:"coinsystem_bonus_coins"
	},
	coinsystem_bonus_coins_aggregate_fields:{
		avg:"coinsystem_bonus_coins_avg_fields",
		count:"Int",
		max:"coinsystem_bonus_coins_max_fields",
		min:"coinsystem_bonus_coins_min_fields",
		stddev:"coinsystem_bonus_coins_stddev_fields",
		stddev_pop:"coinsystem_bonus_coins_stddev_pop_fields",
		stddev_samp:"coinsystem_bonus_coins_stddev_samp_fields",
		sum:"coinsystem_bonus_coins_sum_fields",
		var_pop:"coinsystem_bonus_coins_var_pop_fields",
		var_samp:"coinsystem_bonus_coins_var_samp_fields",
		variance:"coinsystem_bonus_coins_variance_fields"
	},
	coinsystem_bonus_coins_avg_fields:{
		bonus_amount:"Float",
		coin_id:"Float",
		id:"Float",
		max_amount:"Float",
		min_amount:"Float"
	},
	coinsystem_bonus_coins_max_fields:{
		bonus_amount:"numeric",
		coin_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		max_amount:"numeric",
		min_amount:"numeric"
	},
	coinsystem_bonus_coins_min_fields:{
		bonus_amount:"numeric",
		coin_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		max_amount:"numeric",
		min_amount:"numeric"
	},
	coinsystem_bonus_coins_mutation_response:{
		affected_rows:"Int",
		returning:"coinsystem_bonus_coins"
	},
	coinsystem_bonus_coins_stddev_fields:{
		bonus_amount:"Float",
		coin_id:"Float",
		id:"Float",
		max_amount:"Float",
		min_amount:"Float"
	},
	coinsystem_bonus_coins_stddev_pop_fields:{
		bonus_amount:"Float",
		coin_id:"Float",
		id:"Float",
		max_amount:"Float",
		min_amount:"Float"
	},
	coinsystem_bonus_coins_stddev_samp_fields:{
		bonus_amount:"Float",
		coin_id:"Float",
		id:"Float",
		max_amount:"Float",
		min_amount:"Float"
	},
	coinsystem_bonus_coins_sum_fields:{
		bonus_amount:"numeric",
		coin_id:"Int",
		id:"Int",
		max_amount:"numeric",
		min_amount:"numeric"
	},
	coinsystem_bonus_coins_var_pop_fields:{
		bonus_amount:"Float",
		coin_id:"Float",
		id:"Float",
		max_amount:"Float",
		min_amount:"Float"
	},
	coinsystem_bonus_coins_var_samp_fields:{
		bonus_amount:"Float",
		coin_id:"Float",
		id:"Float",
		max_amount:"Float",
		min_amount:"Float"
	},
	coinsystem_bonus_coins_variance_fields:{
		bonus_amount:"Float",
		coin_id:"Float",
		id:"Float",
		max_amount:"Float",
		min_amount:"Float"
	},
	coinsystem_coins:{
		can_mint:"Boolean",
		coin_decimal:"smallint",
		coin_logo:"String",
		coin_name:"String",
		coin_symbol:"String",
		created_at:"timestamptz",
		default:"Boolean",
		id:"Int",
		in_app:"Boolean",
		is_withdrawable:"Boolean",
		updated_at:"timestamptz"
	},
	coinsystem_coins_aggregate:{
		aggregate:"coinsystem_coins_aggregate_fields",
		nodes:"coinsystem_coins"
	},
	coinsystem_coins_aggregate_fields:{
		avg:"coinsystem_coins_avg_fields",
		count:"Int",
		max:"coinsystem_coins_max_fields",
		min:"coinsystem_coins_min_fields",
		stddev:"coinsystem_coins_stddev_fields",
		stddev_pop:"coinsystem_coins_stddev_pop_fields",
		stddev_samp:"coinsystem_coins_stddev_samp_fields",
		sum:"coinsystem_coins_sum_fields",
		var_pop:"coinsystem_coins_var_pop_fields",
		var_samp:"coinsystem_coins_var_samp_fields",
		variance:"coinsystem_coins_variance_fields"
	},
	coinsystem_coins_avg_fields:{
		coin_decimal:"Float",
		id:"Float"
	},
	coinsystem_coins_max_fields:{
		coin_decimal:"smallint",
		coin_logo:"String",
		coin_name:"String",
		coin_symbol:"String",
		created_at:"timestamptz",
		id:"Int",
		updated_at:"timestamptz"
	},
	coinsystem_coins_min_fields:{
		coin_decimal:"smallint",
		coin_logo:"String",
		coin_name:"String",
		coin_symbol:"String",
		created_at:"timestamptz",
		id:"Int",
		updated_at:"timestamptz"
	},
	coinsystem_coins_mutation_response:{
		affected_rows:"Int",
		returning:"coinsystem_coins"
	},
	coinsystem_coins_stddev_fields:{
		coin_decimal:"Float",
		id:"Float"
	},
	coinsystem_coins_stddev_pop_fields:{
		coin_decimal:"Float",
		id:"Float"
	},
	coinsystem_coins_stddev_samp_fields:{
		coin_decimal:"Float",
		id:"Float"
	},
	coinsystem_coins_sum_fields:{
		coin_decimal:"smallint",
		id:"Int"
	},
	coinsystem_coins_var_pop_fields:{
		coin_decimal:"Float",
		id:"Float"
	},
	coinsystem_coins_var_samp_fields:{
		coin_decimal:"Float",
		id:"Float"
	},
	coinsystem_coins_variance_fields:{
		coin_decimal:"Float",
		id:"Float"
	},
	coinsystem_exchange_rates:{
		buy_rate:"numeric",
		created_at:"timestamptz",
		destination_coin_id:"Int",
		id:"Int",
		sell_rate:"numeric",
		source_coin_id:"Int"
	},
	coinsystem_exchange_rates_aggregate:{
		aggregate:"coinsystem_exchange_rates_aggregate_fields",
		nodes:"coinsystem_exchange_rates"
	},
	coinsystem_exchange_rates_aggregate_fields:{
		avg:"coinsystem_exchange_rates_avg_fields",
		count:"Int",
		max:"coinsystem_exchange_rates_max_fields",
		min:"coinsystem_exchange_rates_min_fields",
		stddev:"coinsystem_exchange_rates_stddev_fields",
		stddev_pop:"coinsystem_exchange_rates_stddev_pop_fields",
		stddev_samp:"coinsystem_exchange_rates_stddev_samp_fields",
		sum:"coinsystem_exchange_rates_sum_fields",
		var_pop:"coinsystem_exchange_rates_var_pop_fields",
		var_samp:"coinsystem_exchange_rates_var_samp_fields",
		variance:"coinsystem_exchange_rates_variance_fields"
	},
	coinsystem_exchange_rates_avg_fields:{
		buy_rate:"Float",
		destination_coin_id:"Float",
		id:"Float",
		sell_rate:"Float",
		source_coin_id:"Float"
	},
	coinsystem_exchange_rates_max_fields:{
		buy_rate:"numeric",
		created_at:"timestamptz",
		destination_coin_id:"Int",
		id:"Int",
		sell_rate:"numeric",
		source_coin_id:"Int"
	},
	coinsystem_exchange_rates_min_fields:{
		buy_rate:"numeric",
		created_at:"timestamptz",
		destination_coin_id:"Int",
		id:"Int",
		sell_rate:"numeric",
		source_coin_id:"Int"
	},
	coinsystem_exchange_rates_mutation_response:{
		affected_rows:"Int",
		returning:"coinsystem_exchange_rates"
	},
	coinsystem_exchange_rates_stddev_fields:{
		buy_rate:"Float",
		destination_coin_id:"Float",
		id:"Float",
		sell_rate:"Float",
		source_coin_id:"Float"
	},
	coinsystem_exchange_rates_stddev_pop_fields:{
		buy_rate:"Float",
		destination_coin_id:"Float",
		id:"Float",
		sell_rate:"Float",
		source_coin_id:"Float"
	},
	coinsystem_exchange_rates_stddev_samp_fields:{
		buy_rate:"Float",
		destination_coin_id:"Float",
		id:"Float",
		sell_rate:"Float",
		source_coin_id:"Float"
	},
	coinsystem_exchange_rates_sum_fields:{
		buy_rate:"numeric",
		destination_coin_id:"Int",
		id:"Int",
		sell_rate:"numeric",
		source_coin_id:"Int"
	},
	coinsystem_exchange_rates_var_pop_fields:{
		buy_rate:"Float",
		destination_coin_id:"Float",
		id:"Float",
		sell_rate:"Float",
		source_coin_id:"Float"
	},
	coinsystem_exchange_rates_var_samp_fields:{
		buy_rate:"Float",
		destination_coin_id:"Float",
		id:"Float",
		sell_rate:"Float",
		source_coin_id:"Float"
	},
	coinsystem_exchange_rates_variance_fields:{
		buy_rate:"Float",
		destination_coin_id:"Float",
		id:"Float",
		sell_rate:"Float",
		source_coin_id:"Float"
	},
	coinsystem_logs:{
		created_at:"timestamptz",
		description:"bpchar",
		id:"Int",
		transaction_id:"String"
	},
	coinsystem_logs_aggregate:{
		aggregate:"coinsystem_logs_aggregate_fields",
		nodes:"coinsystem_logs"
	},
	coinsystem_logs_aggregate_fields:{
		avg:"coinsystem_logs_avg_fields",
		count:"Int",
		max:"coinsystem_logs_max_fields",
		min:"coinsystem_logs_min_fields",
		stddev:"coinsystem_logs_stddev_fields",
		stddev_pop:"coinsystem_logs_stddev_pop_fields",
		stddev_samp:"coinsystem_logs_stddev_samp_fields",
		sum:"coinsystem_logs_sum_fields",
		var_pop:"coinsystem_logs_var_pop_fields",
		var_samp:"coinsystem_logs_var_samp_fields",
		variance:"coinsystem_logs_variance_fields"
	},
	coinsystem_logs_avg_fields:{
		id:"Float"
	},
	coinsystem_logs_max_fields:{
		created_at:"timestamptz",
		description:"bpchar",
		id:"Int",
		transaction_id:"String"
	},
	coinsystem_logs_min_fields:{
		created_at:"timestamptz",
		description:"bpchar",
		id:"Int",
		transaction_id:"String"
	},
	coinsystem_logs_mutation_response:{
		affected_rows:"Int",
		returning:"coinsystem_logs"
	},
	coinsystem_logs_stddev_fields:{
		id:"Float"
	},
	coinsystem_logs_stddev_pop_fields:{
		id:"Float"
	},
	coinsystem_logs_stddev_samp_fields:{
		id:"Float"
	},
	coinsystem_logs_sum_fields:{
		id:"Int"
	},
	coinsystem_logs_var_pop_fields:{
		id:"Float"
	},
	coinsystem_logs_var_samp_fields:{
		id:"Float"
	},
	coinsystem_logs_variance_fields:{
		id:"Float"
	},
	coinsystem_transactions:{
		coin_amount:"numeric",
		coin_id:"Int",
		created_at:"timestamptz",
		description:"bpchar",
		id:"Int",
		recipient_id:"Int",
		recipient_wallet_address:"String",
		sender_id:"Int",
		sender_wallet_address:"String",
		transaction_id:"String",
		transaction_type:"String"
	},
	coinsystem_transactions_aggregate:{
		aggregate:"coinsystem_transactions_aggregate_fields",
		nodes:"coinsystem_transactions"
	},
	coinsystem_transactions_aggregate_fields:{
		avg:"coinsystem_transactions_avg_fields",
		count:"Int",
		max:"coinsystem_transactions_max_fields",
		min:"coinsystem_transactions_min_fields",
		stddev:"coinsystem_transactions_stddev_fields",
		stddev_pop:"coinsystem_transactions_stddev_pop_fields",
		stddev_samp:"coinsystem_transactions_stddev_samp_fields",
		sum:"coinsystem_transactions_sum_fields",
		var_pop:"coinsystem_transactions_var_pop_fields",
		var_samp:"coinsystem_transactions_var_samp_fields",
		variance:"coinsystem_transactions_variance_fields"
	},
	coinsystem_transactions_avg_fields:{
		coin_amount:"Float",
		coin_id:"Float",
		id:"Float",
		recipient_id:"Float",
		sender_id:"Float"
	},
	coinsystem_transactions_max_fields:{
		coin_amount:"numeric",
		coin_id:"Int",
		created_at:"timestamptz",
		description:"bpchar",
		id:"Int",
		recipient_id:"Int",
		recipient_wallet_address:"String",
		sender_id:"Int",
		sender_wallet_address:"String",
		transaction_id:"String",
		transaction_type:"String"
	},
	coinsystem_transactions_min_fields:{
		coin_amount:"numeric",
		coin_id:"Int",
		created_at:"timestamptz",
		description:"bpchar",
		id:"Int",
		recipient_id:"Int",
		recipient_wallet_address:"String",
		sender_id:"Int",
		sender_wallet_address:"String",
		transaction_id:"String",
		transaction_type:"String"
	},
	coinsystem_transactions_mutation_response:{
		affected_rows:"Int",
		returning:"coinsystem_transactions"
	},
	coinsystem_transactions_stddev_fields:{
		coin_amount:"Float",
		coin_id:"Float",
		id:"Float",
		recipient_id:"Float",
		sender_id:"Float"
	},
	coinsystem_transactions_stddev_pop_fields:{
		coin_amount:"Float",
		coin_id:"Float",
		id:"Float",
		recipient_id:"Float",
		sender_id:"Float"
	},
	coinsystem_transactions_stddev_samp_fields:{
		coin_amount:"Float",
		coin_id:"Float",
		id:"Float",
		recipient_id:"Float",
		sender_id:"Float"
	},
	coinsystem_transactions_sum_fields:{
		coin_amount:"numeric",
		coin_id:"Int",
		id:"Int",
		recipient_id:"Int",
		sender_id:"Int"
	},
	coinsystem_transactions_var_pop_fields:{
		coin_amount:"Float",
		coin_id:"Float",
		id:"Float",
		recipient_id:"Float",
		sender_id:"Float"
	},
	coinsystem_transactions_var_samp_fields:{
		coin_amount:"Float",
		coin_id:"Float",
		id:"Float",
		recipient_id:"Float",
		sender_id:"Float"
	},
	coinsystem_transactions_variance_fields:{
		coin_amount:"Float",
		coin_id:"Float",
		id:"Float",
		recipient_id:"Float",
		sender_id:"Float"
	},
	coinsystem_wallets:{
		can_mint:"Boolean",
		coin_id:"Int",
		created_at:"timestamptz",
		default:"Boolean",
		id:"Int",
		is_vm:"Boolean",
		tag:"String",
		updated_at:"timestamptz",
		user_id:"Int",
		wallet_address:"String"
	},
	coinsystem_wallets_aggregate:{
		aggregate:"coinsystem_wallets_aggregate_fields",
		nodes:"coinsystem_wallets"
	},
	coinsystem_wallets_aggregate_fields:{
		avg:"coinsystem_wallets_avg_fields",
		count:"Int",
		max:"coinsystem_wallets_max_fields",
		min:"coinsystem_wallets_min_fields",
		stddev:"coinsystem_wallets_stddev_fields",
		stddev_pop:"coinsystem_wallets_stddev_pop_fields",
		stddev_samp:"coinsystem_wallets_stddev_samp_fields",
		sum:"coinsystem_wallets_sum_fields",
		var_pop:"coinsystem_wallets_var_pop_fields",
		var_samp:"coinsystem_wallets_var_samp_fields",
		variance:"coinsystem_wallets_variance_fields"
	},
	coinsystem_wallets_avg_fields:{
		coin_id:"Float",
		id:"Float",
		user_id:"Float"
	},
	coinsystem_wallets_max_fields:{
		coin_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		tag:"String",
		updated_at:"timestamptz",
		user_id:"Int",
		wallet_address:"String"
	},
	coinsystem_wallets_min_fields:{
		coin_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		tag:"String",
		updated_at:"timestamptz",
		user_id:"Int",
		wallet_address:"String"
	},
	coinsystem_wallets_mutation_response:{
		affected_rows:"Int",
		returning:"coinsystem_wallets"
	},
	coinsystem_wallets_stddev_fields:{
		coin_id:"Float",
		id:"Float",
		user_id:"Float"
	},
	coinsystem_wallets_stddev_pop_fields:{
		coin_id:"Float",
		id:"Float",
		user_id:"Float"
	},
	coinsystem_wallets_stddev_samp_fields:{
		coin_id:"Float",
		id:"Float",
		user_id:"Float"
	},
	coinsystem_wallets_sum_fields:{
		coin_id:"Int",
		id:"Int",
		user_id:"Int"
	},
	coinsystem_wallets_var_pop_fields:{
		coin_id:"Float",
		id:"Float",
		user_id:"Float"
	},
	coinsystem_wallets_var_samp_fields:{
		coin_id:"Float",
		id:"Float",
		user_id:"Float"
	},
	coinsystem_wallets_variance_fields:{
		coin_id:"Float",
		id:"Float",
		user_id:"Float"
	},
	mutation_root:{
		delete_awsdms_validation_failures_v1:"awsdms_validation_failures_v1_mutation_response",
		delete_coinsystem_balances:"coinsystem_balances_mutation_response",
		delete_coinsystem_balances_by_pk:"coinsystem_balances",
		delete_coinsystem_bonus_coins:"coinsystem_bonus_coins_mutation_response",
		delete_coinsystem_bonus_coins_by_pk:"coinsystem_bonus_coins",
		delete_coinsystem_coins:"coinsystem_coins_mutation_response",
		delete_coinsystem_coins_by_pk:"coinsystem_coins",
		delete_coinsystem_exchange_rates:"coinsystem_exchange_rates_mutation_response",
		delete_coinsystem_exchange_rates_by_pk:"coinsystem_exchange_rates",
		delete_coinsystem_logs:"coinsystem_logs_mutation_response",
		delete_coinsystem_logs_by_pk:"coinsystem_logs",
		delete_coinsystem_transactions:"coinsystem_transactions_mutation_response",
		delete_coinsystem_transactions_by_pk:"coinsystem_transactions",
		delete_coinsystem_wallets:"coinsystem_wallets_mutation_response",
		delete_coinsystem_wallets_by_pk:"coinsystem_wallets",
		delete_mysql_user:"mysql_user_mutation_response",
		delete_mysql_user_by_pk:"mysql_user",
		delete_store_payments:"store_payments_mutation_response",
		delete_store_payments_by_pk:"store_payments",
		delete_store_products:"store_products_mutation_response",
		delete_store_products_by_pk:"store_products",
		delete_store_purchases:"store_purchases_mutation_response",
		delete_store_purchases_by_pk:"store_purchases",
		delete_store_users:"store_users_mutation_response",
		delete_voyce_admin_roles:"voyce_admin_roles_mutation_response",
		delete_voyce_admin_roles_by_pk:"voyce_admin_roles",
		delete_voyce_app_banners:"voyce_app_banners_mutation_response",
		delete_voyce_app_banners_by_pk:"voyce_app_banners",
		delete_voyce_app_components:"voyce_app_components_mutation_response",
		delete_voyce_app_components_by_pk:"voyce_app_components",
		delete_voyce_audit_trail:"voyce_audit_trail_mutation_response",
		delete_voyce_audit_trail_by_pk:"voyce_audit_trail",
		delete_voyce_author_profiles:"voyce_author_profiles_mutation_response",
		delete_voyce_author_profiles_by_pk:"voyce_author_profiles",
		delete_voyce_balances:"voyce_balances_mutation_response",
		delete_voyce_balances_by_pk:"voyce_balances",
		delete_voyce_banners:"voyce_banners_mutation_response",
		delete_voyce_banners_by_pk:"voyce_banners",
		delete_voyce_chapter_assets:"voyce_chapter_assets_mutation_response",
		delete_voyce_chapter_assets_by_pk:"voyce_chapter_assets",
		delete_voyce_chapter_bookmarks:"voyce_chapter_bookmarks_mutation_response",
		delete_voyce_chapter_bookmarks_by_pk:"voyce_chapter_bookmarks",
		delete_voyce_chapter_comments:"voyce_chapter_comments_mutation_response",
		delete_voyce_chapter_comments_07252024:"voyce_chapter_comments_07252024_mutation_response",
		delete_voyce_chapter_comments_by_pk:"voyce_chapter_comments",
		delete_voyce_chapter_comments_reaction:"voyce_chapter_comments_reaction_mutation_response",
		delete_voyce_chapter_comments_reaction_by_pk:"voyce_chapter_comments_reaction",
		delete_voyce_chapter_comments_reaction_count:"voyce_chapter_comments_reaction_count_mutation_response",
		delete_voyce_chapter_comments_reaction_count_by_pk:"voyce_chapter_comments_reaction_count",
		delete_voyce_chapter_comments_reports:"voyce_chapter_comments_reports_mutation_response",
		delete_voyce_chapter_comments_reports_by_pk:"voyce_chapter_comments_reports",
		delete_voyce_chapter_contents:"voyce_chapter_contents_mutation_response",
		delete_voyce_chapter_contents_by_pk:"voyce_chapter_contents",
		delete_voyce_chapter_contents_old:"voyce_chapter_contents_old_mutation_response",
		delete_voyce_chapter_contents_old_by_pk:"voyce_chapter_contents_old",
		delete_voyce_chapter_effects:"voyce_chapter_effects_mutation_response",
		delete_voyce_chapter_effects_by_pk:"voyce_chapter_effects",
		delete_voyce_chapter_images:"voyce_chapter_images_mutation_response",
		delete_voyce_chapter_images_07252024:"voyce_chapter_images_07252024_mutation_response",
		delete_voyce_chapter_images_by_pk:"voyce_chapter_images",
		delete_voyce_chapter_likes:"voyce_chapter_likes_mutation_response",
		delete_voyce_chapter_likes_by_pk:"voyce_chapter_likes",
		delete_voyce_chapter_locks:"voyce_chapter_locks_mutation_response",
		delete_voyce_chapter_locks_by_pk:"voyce_chapter_locks",
		delete_voyce_chapter_panel_views:"voyce_chapter_panel_views_mutation_response",
		delete_voyce_chapter_panel_views_by_pk:"voyce_chapter_panel_views",
		delete_voyce_chapter_panels:"voyce_chapter_panels_mutation_response",
		delete_voyce_chapter_panels_by_pk:"voyce_chapter_panels",
		delete_voyce_chapter_publish_schedules:"voyce_chapter_publish_schedules_mutation_response",
		delete_voyce_chapter_publish_schedules_by_pk:"voyce_chapter_publish_schedules",
		delete_voyce_chapter_reactions:"voyce_chapter_reactions_mutation_response",
		delete_voyce_chapter_reactions_by_pk:"voyce_chapter_reactions",
		delete_voyce_chapter_reactions_count:"voyce_chapter_reactions_count_mutation_response",
		delete_voyce_chapter_reactions_count_by_pk:"voyce_chapter_reactions_count",
		delete_voyce_chapter_view_logs:"voyce_chapter_view_logs_mutation_response",
		delete_voyce_chapter_view_logs_by_pk:"voyce_chapter_view_logs",
		delete_voyce_chapter_views:"voyce_chapter_views_mutation_response",
		delete_voyce_chapter_views_by_pk:"voyce_chapter_views",
		delete_voyce_chapters:"voyce_chapters_mutation_response",
		delete_voyce_chapters_07252024:"voyce_chapters_07252024_mutation_response",
		delete_voyce_chapters_by_pk:"voyce_chapters",
		delete_voyce_collaborator_roles:"voyce_collaborator_roles_mutation_response",
		delete_voyce_collaborator_roles_by_pk:"voyce_collaborator_roles",
		delete_voyce_comic_types:"voyce_comic_types_mutation_response",
		delete_voyce_comic_types_by_pk:"voyce_comic_types",
		delete_voyce_community_blurbs:"voyce_community_blurbs_mutation_response",
		delete_voyce_community_blurbs_by_pk:"voyce_community_blurbs",
		delete_voyce_contact_us:"voyce_contact_us_mutation_response",
		delete_voyce_contact_us_by_pk:"voyce_contact_us",
		delete_voyce_content_report:"voyce_content_report_mutation_response",
		delete_voyce_content_report_by_pk:"voyce_content_report",
		delete_voyce_contest_data:"voyce_contest_data_mutation_response",
		delete_voyce_contest_data_by_pk:"voyce_contest_data",
		delete_voyce_countries:"voyce_countries_mutation_response",
		delete_voyce_countries_by_pk:"voyce_countries",
		delete_voyce_csat_ratings:"voyce_csat_ratings_mutation_response",
		delete_voyce_csat_ratings_by_pk:"voyce_csat_ratings",
		delete_voyce_csat_ratings_data:"voyce_csat_ratings_data_mutation_response",
		delete_voyce_csat_ratings_data_by_pk:"voyce_csat_ratings_data",
		delete_voyce_education_types:"voyce_education_types_mutation_response",
		delete_voyce_education_types_by_pk:"voyce_education_types",
		delete_voyce_erp_login_roles:"voyce_erp_login_roles_mutation_response",
		delete_voyce_erp_login_roles_by_pk:"voyce_erp_login_roles",
		delete_voyce_etl_series_views_logs:"voyce_etl_series_views_logs_mutation_response",
		delete_voyce_etl_series_views_logs_by_pk:"voyce_etl_series_views_logs",
		delete_voyce_event_schedules:"voyce_event_schedules_mutation_response",
		delete_voyce_event_schedules_by_pk:"voyce_event_schedules",
		delete_voyce_genres:"voyce_genres_mutation_response",
		delete_voyce_genres_by_pk:"voyce_genres",
		delete_voyce_guest_gtags:"voyce_guest_gtags_mutation_response",
		delete_voyce_guest_gtags_by_pk:"voyce_guest_gtags",
		delete_voyce_metabases:"voyce_metabases_mutation_response",
		delete_voyce_metabases_access:"voyce_metabases_access_mutation_response",
		delete_voyce_metabases_access_by_pk:"voyce_metabases_access",
		delete_voyce_metabases_by_pk:"voyce_metabases",
		delete_voyce_newsletter:"voyce_newsletter_mutation_response",
		delete_voyce_newsletter_by_pk:"voyce_newsletter",
		delete_voyce_notification_series_settings:"voyce_notification_series_settings_mutation_response",
		delete_voyce_notification_series_settings_by_pk:"voyce_notification_series_settings",
		delete_voyce_notification_settings:"voyce_notification_settings_mutation_response",
		delete_voyce_notification_settings_by_pk:"voyce_notification_settings",
		delete_voyce_notifications:"voyce_notifications_mutation_response",
		delete_voyce_notifications_by_pk:"voyce_notifications",
		delete_voyce_page_banners:"voyce_page_banners_mutation_response",
		delete_voyce_page_banners_by_pk:"voyce_page_banners",
		delete_voyce_page_components:"voyce_page_components_mutation_response",
		delete_voyce_page_components_by_pk:"voyce_page_components",
		delete_voyce_pages:"voyce_pages_mutation_response",
		delete_voyce_pages_by_pk:"voyce_pages",
		delete_voyce_panels_comments:"voyce_panels_comments_mutation_response",
		delete_voyce_panels_comments_by_pk:"voyce_panels_comments",
		delete_voyce_panels_reactions:"voyce_panels_reactions_mutation_response",
		delete_voyce_panels_reactions_by_pk:"voyce_panels_reactions",
		delete_voyce_panels_reactions_count:"voyce_panels_reactions_count_mutation_response",
		delete_voyce_panels_reactions_count_by_pk:"voyce_panels_reactions_count",
		delete_voyce_payments_to_creators:"voyce_payments_to_creators_mutation_response",
		delete_voyce_payments_to_creators_by_pk:"voyce_payments_to_creators",
		delete_voyce_pg_rs_series_views_count:"voyce_pg_rs_series_views_count_mutation_response",
		delete_voyce_pg_rs_series_views_count_by_pk:"voyce_pg_rs_series_views_count",
		delete_voyce_polls:"voyce_polls_mutation_response",
		delete_voyce_polls_answers:"voyce_polls_answers_mutation_response",
		delete_voyce_polls_answers_by_pk:"voyce_polls_answers",
		delete_voyce_polls_by_pk:"voyce_polls",
		delete_voyce_polls_comments:"voyce_polls_comments_mutation_response",
		delete_voyce_polls_comments_by_pk:"voyce_polls_comments",
		delete_voyce_polls_reactions:"voyce_polls_reactions_mutation_response",
		delete_voyce_polls_reactions_by_pk:"voyce_polls_reactions",
		delete_voyce_polls_votes:"voyce_polls_votes_mutation_response",
		delete_voyce_polls_votes_by_pk:"voyce_polls_votes",
		delete_voyce_popup_components:"voyce_popup_components_mutation_response",
		delete_voyce_popup_components_by_pk:"voyce_popup_components",
		delete_voyce_popup_emails:"voyce_popup_emails_mutation_response",
		delete_voyce_popup_emails_by_pk:"voyce_popup_emails",
		delete_voyce_posts:"voyce_posts_mutation_response",
		delete_voyce_posts_by_pk:"voyce_posts",
		delete_voyce_posts_comments:"voyce_posts_comments_mutation_response",
		delete_voyce_posts_comments_by_pk:"voyce_posts_comments",
		delete_voyce_posts_reactions:"voyce_posts_reactions_mutation_response",
		delete_voyce_posts_reactions_by_pk:"voyce_posts_reactions",
		delete_voyce_quest_difficulties:"voyce_quest_difficulties_mutation_response",
		delete_voyce_quest_difficulties_by_pk:"voyce_quest_difficulties",
		delete_voyce_quests:"voyce_quests_mutation_response",
		delete_voyce_quests_by_pk:"voyce_quests",
		delete_voyce_quests_old:"voyce_quests_old_mutation_response",
		delete_voyce_quests_old_by_pk:"voyce_quests_old",
		delete_voyce_recommended_series:"voyce_recommended_series_mutation_response",
		delete_voyce_recommended_series_by_pk:"voyce_recommended_series",
		delete_voyce_release_schedules:"voyce_release_schedules_mutation_response",
		delete_voyce_release_schedules_by_pk:"voyce_release_schedules",
		delete_voyce_rewards:"voyce_rewards_mutation_response",
		delete_voyce_rewards_by_pk:"voyce_rewards",
		delete_voyce_rewards_leaderboard:"voyce_rewards_leaderboard_mutation_response",
		delete_voyce_rewards_leaderboard_by_pk:"voyce_rewards_leaderboard",
		delete_voyce_rewards_quest:"voyce_rewards_quest_mutation_response",
		delete_voyce_rewards_quest_by_pk:"voyce_rewards_quest",
		delete_voyce_sections:"voyce_sections_mutation_response",
		delete_voyce_sections_by_pk:"voyce_sections",
		delete_voyce_series:"voyce_series_mutation_response",
		delete_voyce_series_by_pk:"voyce_series",
		delete_voyce_series_collaborators:"voyce_series_collaborators_mutation_response",
		delete_voyce_series_collaborators_by_pk:"voyce_series_collaborators",
		delete_voyce_series_donations:"voyce_series_donations_mutation_response",
		delete_voyce_series_donations_by_pk:"voyce_series_donations",
		delete_voyce_series_favorites:"voyce_series_favorites_mutation_response",
		delete_voyce_series_favorites_by_pk:"voyce_series_favorites",
		delete_voyce_series_featured_panels:"voyce_series_featured_panels_mutation_response",
		delete_voyce_series_featured_panels_by_pk:"voyce_series_featured_panels",
		delete_voyce_series_genre:"voyce_series_genre_mutation_response",
		delete_voyce_series_genre_by_pk:"voyce_series_genre",
		delete_voyce_series_giveaway_participants:"voyce_series_giveaway_participants_mutation_response",
		delete_voyce_series_giveaway_participants_by_pk:"voyce_series_giveaway_participants",
		delete_voyce_series_hot_panels:"voyce_series_hot_panels_mutation_response",
		delete_voyce_series_hot_panels_by_pk:"voyce_series_hot_panels",
		delete_voyce_series_layouts:"voyce_series_layouts_mutation_response",
		delete_voyce_series_layouts_by_pk:"voyce_series_layouts",
		delete_voyce_series_leaderboard:"voyce_series_leaderboard_mutation_response",
		delete_voyce_series_leaderboard_by_pk:"voyce_series_leaderboard",
		delete_voyce_series_lock_config:"voyce_series_lock_config_mutation_response",
		delete_voyce_series_lock_config_by_pk:"voyce_series_lock_config",
		delete_voyce_series_sponsorships_configuration:"voyce_series_sponsorships_configuration_mutation_response",
		delete_voyce_series_sponsorships_configuration_by_pk:"voyce_series_sponsorships_configuration",
		delete_voyce_series_subscription:"voyce_series_subscription_mutation_response",
		delete_voyce_series_subscription_by_pk:"voyce_series_subscription",
		delete_voyce_series_tags:"voyce_series_tags_mutation_response",
		delete_voyce_series_tags_by_pk:"voyce_series_tags",
		delete_voyce_series_tags_junction:"voyce_series_tags_junction_mutation_response",
		delete_voyce_series_tags_junction_by_pk:"voyce_series_tags_junction",
		delete_voyce_series_top_comments:"voyce_series_top_comments_mutation_response",
		delete_voyce_series_top_comments_by_pk:"voyce_series_top_comments",
		delete_voyce_series_top_likes:"voyce_series_top_likes_mutation_response",
		delete_voyce_series_top_likes_by_pk:"voyce_series_top_likes",
		delete_voyce_series_top_views:"voyce_series_top_views_mutation_response",
		delete_voyce_series_top_views_by_pk:"voyce_series_top_views",
		delete_voyce_series_views_adjustment:"voyce_series_views_adjustment_mutation_response",
		delete_voyce_series_views_adjustment_by_pk:"voyce_series_views_adjustment",
		delete_voyce_series_votes:"voyce_series_votes_mutation_response",
		delete_voyce_series_votes_by_pk:"voyce_series_votes",
		delete_voyce_series_votes_count:"voyce_series_votes_count_mutation_response",
		delete_voyce_series_votes_count_by_pk:"voyce_series_votes_count",
		delete_voyce_series_voting_schedules:"voyce_series_voting_schedules_mutation_response",
		delete_voyce_series_voting_schedules_by_pk:"voyce_series_voting_schedules",
		delete_voyce_sponsorships:"voyce_sponsorships_mutation_response",
		delete_voyce_sponsorships_by_pk:"voyce_sponsorships",
		delete_voyce_states:"voyce_states_mutation_response",
		delete_voyce_states_by_pk:"voyce_states",
		delete_voyce_stripe_payouts_setup:"voyce_stripe_payouts_setup_mutation_response",
		delete_voyce_stripe_payouts_setup_by_pk:"voyce_stripe_payouts_setup",
		delete_voyce_studio:"voyce_studio_mutation_response",
		delete_voyce_studio_by_pk:"voyce_studio",
		delete_voyce_studio_followers:"voyce_studio_followers_mutation_response",
		delete_voyce_studio_followers_by_pk:"voyce_studio_followers",
		delete_voyce_studio_series:"voyce_studio_series_mutation_response",
		delete_voyce_studio_series_by_pk:"voyce_studio_series",
		delete_voyce_studio_views:"voyce_studio_views_mutation_response",
		delete_voyce_studio_views_by_pk:"voyce_studio_views",
		delete_voyce_system_settings:"voyce_system_settings_mutation_response",
		delete_voyce_system_settings_by_pk:"voyce_system_settings",
		delete_voyce_transaction_logs:"voyce_transaction_logs_mutation_response",
		delete_voyce_transaction_logs_by_pk:"voyce_transaction_logs",
		delete_voyce_url_redirects:"voyce_url_redirects_mutation_response",
		delete_voyce_url_redirects_by_pk:"voyce_url_redirects",
		delete_voyce_user_addresses:"voyce_user_addresses_mutation_response",
		delete_voyce_user_addresses_by_pk:"voyce_user_addresses",
		delete_voyce_user_blocks:"voyce_user_blocks_mutation_response",
		delete_voyce_user_blocks_by_pk:"voyce_user_blocks",
		delete_voyce_user_chapters_unlocked:"voyce_user_chapters_unlocked_mutation_response",
		delete_voyce_user_chapters_unlocked_by_pk:"voyce_user_chapters_unlocked",
		delete_voyce_user_contacts:"voyce_user_contacts_mutation_response",
		delete_voyce_user_contacts_by_pk:"voyce_user_contacts",
		delete_voyce_user_csat_ratings:"voyce_user_csat_ratings_mutation_response",
		delete_voyce_user_csat_ratings_by_pk:"voyce_user_csat_ratings",
		delete_voyce_user_education:"voyce_user_education_mutation_response",
		delete_voyce_user_education_by_pk:"voyce_user_education",
		delete_voyce_user_level:"voyce_user_level_mutation_response",
		delete_voyce_user_level_by_pk:"voyce_user_level",
		delete_voyce_user_linked_accounts:"voyce_user_linked_accounts_mutation_response",
		delete_voyce_user_linked_accounts_by_pk:"voyce_user_linked_accounts",
		delete_voyce_user_login_history:"voyce_user_login_history_mutation_response",
		delete_voyce_user_login_history_by_pk:"voyce_user_login_history",
		delete_voyce_user_quest_ids:"voyce_user_quest_ids_mutation_response",
		delete_voyce_user_quest_ids_by_pk:"voyce_user_quest_ids",
		delete_voyce_user_quest_rewards:"voyce_user_quest_rewards_mutation_response",
		delete_voyce_user_quest_rewards_by_pk:"voyce_user_quest_rewards",
		delete_voyce_user_quests:"voyce_user_quests_mutation_response",
		delete_voyce_user_quests_by_pk:"voyce_user_quests",
		delete_voyce_user_quests_old:"voyce_user_quests_old_mutation_response",
		delete_voyce_user_quests_old_by_pk:"voyce_user_quests_old",
		delete_voyce_user_ranking:"voyce_user_ranking_mutation_response",
		delete_voyce_user_ranking_by_pk:"voyce_user_ranking",
		delete_voyce_user_reviews:"voyce_user_reviews_mutation_response",
		delete_voyce_user_reviews_by_pk:"voyce_user_reviews",
		delete_voyce_user_rewards:"voyce_user_rewards_mutation_response",
		delete_voyce_user_rewards_by_pk:"voyce_user_rewards",
		delete_voyce_user_roles:"voyce_user_roles_mutation_response",
		delete_voyce_user_roles_by_pk:"voyce_user_roles",
		delete_voyce_user_settings:"voyce_user_settings_mutation_response",
		delete_voyce_user_settings_by_pk:"voyce_user_settings",
		delete_voyce_user_skills:"voyce_user_skills_mutation_response",
		delete_voyce_user_skills_by_pk:"voyce_user_skills",
		delete_voyce_user_socials:"voyce_user_socials_mutation_response",
		delete_voyce_user_socials_by_pk:"voyce_user_socials",
		delete_voyce_user_soft_deleted:"voyce_user_soft_deleted_mutation_response",
		delete_voyce_user_soft_deleted_by_pk:"voyce_user_soft_deleted",
		delete_voyce_user_vtags:"voyce_user_vtags_mutation_response",
		delete_voyce_user_vtags_by_pk:"voyce_user_vtags",
		delete_voyce_user_wins:"voyce_user_wins_mutation_response",
		delete_voyce_user_wins_by_pk:"voyce_user_wins",
		delete_voyce_users:"voyce_users_mutation_response",
		delete_voyce_users_by_pk:"voyce_users",
		delete_voyce_users_followers:"voyce_users_followers_mutation_response",
		delete_voyce_users_followers_by_pk:"voyce_users_followers",
		delete_voyce_users_private:"voyce_users_private_mutation_response",
		delete_voyce_users_series_junction:"voyce_users_series_junction_mutation_response",
		delete_voyce_users_series_junction_by_pk:"voyce_users_series_junction",
		delete_voyce_versions:"voyce_versions_mutation_response",
		delete_voyce_versions_by_pk:"voyce_versions",
		delete_voyce_voting_analytics:"voyce_voting_analytics_mutation_response",
		delete_voyce_voting_analytics_by_pk:"voyce_voting_analytics",
		delete_voyce_voting_panels:"voyce_voting_panels_mutation_response",
		delete_voyce_voting_panels_by_pk:"voyce_voting_panels",
		delete_voyce_voting_series:"voyce_voting_series_mutation_response",
		delete_voyce_voting_series_by_pk:"voyce_voting_series",
		delete_voyce_voting_user_votes:"voyce_voting_user_votes_mutation_response",
		delete_voyce_voting_user_votes_by_pk:"voyce_voting_user_votes",
		insert_awsdms_validation_failures_v1:"awsdms_validation_failures_v1_mutation_response",
		insert_awsdms_validation_failures_v1_one:"awsdms_validation_failures_v1",
		insert_coinsystem_balances:"coinsystem_balances_mutation_response",
		insert_coinsystem_balances_one:"coinsystem_balances",
		insert_coinsystem_bonus_coins:"coinsystem_bonus_coins_mutation_response",
		insert_coinsystem_bonus_coins_one:"coinsystem_bonus_coins",
		insert_coinsystem_coins:"coinsystem_coins_mutation_response",
		insert_coinsystem_coins_one:"coinsystem_coins",
		insert_coinsystem_exchange_rates:"coinsystem_exchange_rates_mutation_response",
		insert_coinsystem_exchange_rates_one:"coinsystem_exchange_rates",
		insert_coinsystem_logs:"coinsystem_logs_mutation_response",
		insert_coinsystem_logs_one:"coinsystem_logs",
		insert_coinsystem_transactions:"coinsystem_transactions_mutation_response",
		insert_coinsystem_transactions_one:"coinsystem_transactions",
		insert_coinsystem_wallets:"coinsystem_wallets_mutation_response",
		insert_coinsystem_wallets_one:"coinsystem_wallets",
		insert_mysql_user:"mysql_user_mutation_response",
		insert_mysql_user_one:"mysql_user",
		insert_store_payments:"store_payments_mutation_response",
		insert_store_payments_one:"store_payments",
		insert_store_products:"store_products_mutation_response",
		insert_store_products_one:"store_products",
		insert_store_purchases:"store_purchases_mutation_response",
		insert_store_purchases_one:"store_purchases",
		insert_store_users:"store_users_mutation_response",
		insert_store_users_one:"store_users",
		insert_voyce_admin_roles:"voyce_admin_roles_mutation_response",
		insert_voyce_admin_roles_one:"voyce_admin_roles",
		insert_voyce_app_banners:"voyce_app_banners_mutation_response",
		insert_voyce_app_banners_one:"voyce_app_banners",
		insert_voyce_app_components:"voyce_app_components_mutation_response",
		insert_voyce_app_components_one:"voyce_app_components",
		insert_voyce_audit_trail:"voyce_audit_trail_mutation_response",
		insert_voyce_audit_trail_one:"voyce_audit_trail",
		insert_voyce_author_profiles:"voyce_author_profiles_mutation_response",
		insert_voyce_author_profiles_one:"voyce_author_profiles",
		insert_voyce_balances:"voyce_balances_mutation_response",
		insert_voyce_balances_one:"voyce_balances",
		insert_voyce_banners:"voyce_banners_mutation_response",
		insert_voyce_banners_one:"voyce_banners",
		insert_voyce_chapter_assets:"voyce_chapter_assets_mutation_response",
		insert_voyce_chapter_assets_one:"voyce_chapter_assets",
		insert_voyce_chapter_bookmarks:"voyce_chapter_bookmarks_mutation_response",
		insert_voyce_chapter_bookmarks_one:"voyce_chapter_bookmarks",
		insert_voyce_chapter_comments:"voyce_chapter_comments_mutation_response",
		insert_voyce_chapter_comments_07252024:"voyce_chapter_comments_07252024_mutation_response",
		insert_voyce_chapter_comments_07252024_one:"voyce_chapter_comments_07252024",
		insert_voyce_chapter_comments_one:"voyce_chapter_comments",
		insert_voyce_chapter_comments_reaction:"voyce_chapter_comments_reaction_mutation_response",
		insert_voyce_chapter_comments_reaction_count:"voyce_chapter_comments_reaction_count_mutation_response",
		insert_voyce_chapter_comments_reaction_count_one:"voyce_chapter_comments_reaction_count",
		insert_voyce_chapter_comments_reaction_one:"voyce_chapter_comments_reaction",
		insert_voyce_chapter_comments_reports:"voyce_chapter_comments_reports_mutation_response",
		insert_voyce_chapter_comments_reports_one:"voyce_chapter_comments_reports",
		insert_voyce_chapter_contents:"voyce_chapter_contents_mutation_response",
		insert_voyce_chapter_contents_old:"voyce_chapter_contents_old_mutation_response",
		insert_voyce_chapter_contents_old_one:"voyce_chapter_contents_old",
		insert_voyce_chapter_contents_one:"voyce_chapter_contents",
		insert_voyce_chapter_effects:"voyce_chapter_effects_mutation_response",
		insert_voyce_chapter_effects_one:"voyce_chapter_effects",
		insert_voyce_chapter_images:"voyce_chapter_images_mutation_response",
		insert_voyce_chapter_images_07252024:"voyce_chapter_images_07252024_mutation_response",
		insert_voyce_chapter_images_07252024_one:"voyce_chapter_images_07252024",
		insert_voyce_chapter_images_one:"voyce_chapter_images",
		insert_voyce_chapter_likes:"voyce_chapter_likes_mutation_response",
		insert_voyce_chapter_likes_one:"voyce_chapter_likes",
		insert_voyce_chapter_locks:"voyce_chapter_locks_mutation_response",
		insert_voyce_chapter_locks_one:"voyce_chapter_locks",
		insert_voyce_chapter_panel_views:"voyce_chapter_panel_views_mutation_response",
		insert_voyce_chapter_panel_views_one:"voyce_chapter_panel_views",
		insert_voyce_chapter_panels:"voyce_chapter_panels_mutation_response",
		insert_voyce_chapter_panels_one:"voyce_chapter_panels",
		insert_voyce_chapter_publish_schedules:"voyce_chapter_publish_schedules_mutation_response",
		insert_voyce_chapter_publish_schedules_one:"voyce_chapter_publish_schedules",
		insert_voyce_chapter_reactions:"voyce_chapter_reactions_mutation_response",
		insert_voyce_chapter_reactions_count:"voyce_chapter_reactions_count_mutation_response",
		insert_voyce_chapter_reactions_count_one:"voyce_chapter_reactions_count",
		insert_voyce_chapter_reactions_one:"voyce_chapter_reactions",
		insert_voyce_chapter_view_logs:"voyce_chapter_view_logs_mutation_response",
		insert_voyce_chapter_view_logs_one:"voyce_chapter_view_logs",
		insert_voyce_chapter_views:"voyce_chapter_views_mutation_response",
		insert_voyce_chapter_views_one:"voyce_chapter_views",
		insert_voyce_chapters:"voyce_chapters_mutation_response",
		insert_voyce_chapters_07252024:"voyce_chapters_07252024_mutation_response",
		insert_voyce_chapters_07252024_one:"voyce_chapters_07252024",
		insert_voyce_chapters_one:"voyce_chapters",
		insert_voyce_collaborator_roles:"voyce_collaborator_roles_mutation_response",
		insert_voyce_collaborator_roles_one:"voyce_collaborator_roles",
		insert_voyce_comic_types:"voyce_comic_types_mutation_response",
		insert_voyce_comic_types_one:"voyce_comic_types",
		insert_voyce_community_blurbs:"voyce_community_blurbs_mutation_response",
		insert_voyce_community_blurbs_one:"voyce_community_blurbs",
		insert_voyce_contact_us:"voyce_contact_us_mutation_response",
		insert_voyce_contact_us_one:"voyce_contact_us",
		insert_voyce_content_report:"voyce_content_report_mutation_response",
		insert_voyce_content_report_one:"voyce_content_report",
		insert_voyce_contest_data:"voyce_contest_data_mutation_response",
		insert_voyce_contest_data_one:"voyce_contest_data",
		insert_voyce_countries:"voyce_countries_mutation_response",
		insert_voyce_countries_one:"voyce_countries",
		insert_voyce_csat_ratings:"voyce_csat_ratings_mutation_response",
		insert_voyce_csat_ratings_data:"voyce_csat_ratings_data_mutation_response",
		insert_voyce_csat_ratings_data_one:"voyce_csat_ratings_data",
		insert_voyce_csat_ratings_one:"voyce_csat_ratings",
		insert_voyce_education_types:"voyce_education_types_mutation_response",
		insert_voyce_education_types_one:"voyce_education_types",
		insert_voyce_erp_login_roles:"voyce_erp_login_roles_mutation_response",
		insert_voyce_erp_login_roles_one:"voyce_erp_login_roles",
		insert_voyce_etl_series_views_logs:"voyce_etl_series_views_logs_mutation_response",
		insert_voyce_etl_series_views_logs_one:"voyce_etl_series_views_logs",
		insert_voyce_event_schedules:"voyce_event_schedules_mutation_response",
		insert_voyce_event_schedules_one:"voyce_event_schedules",
		insert_voyce_genres:"voyce_genres_mutation_response",
		insert_voyce_genres_one:"voyce_genres",
		insert_voyce_guest_gtags:"voyce_guest_gtags_mutation_response",
		insert_voyce_guest_gtags_one:"voyce_guest_gtags",
		insert_voyce_metabases:"voyce_metabases_mutation_response",
		insert_voyce_metabases_access:"voyce_metabases_access_mutation_response",
		insert_voyce_metabases_access_one:"voyce_metabases_access",
		insert_voyce_metabases_one:"voyce_metabases",
		insert_voyce_newsletter:"voyce_newsletter_mutation_response",
		insert_voyce_newsletter_one:"voyce_newsletter",
		insert_voyce_notification_series_settings:"voyce_notification_series_settings_mutation_response",
		insert_voyce_notification_series_settings_one:"voyce_notification_series_settings",
		insert_voyce_notification_settings:"voyce_notification_settings_mutation_response",
		insert_voyce_notification_settings_one:"voyce_notification_settings",
		insert_voyce_notifications:"voyce_notifications_mutation_response",
		insert_voyce_notifications_one:"voyce_notifications",
		insert_voyce_page_banners:"voyce_page_banners_mutation_response",
		insert_voyce_page_banners_one:"voyce_page_banners",
		insert_voyce_page_components:"voyce_page_components_mutation_response",
		insert_voyce_page_components_one:"voyce_page_components",
		insert_voyce_pages:"voyce_pages_mutation_response",
		insert_voyce_pages_one:"voyce_pages",
		insert_voyce_panels_comments:"voyce_panels_comments_mutation_response",
		insert_voyce_panels_comments_one:"voyce_panels_comments",
		insert_voyce_panels_reactions:"voyce_panels_reactions_mutation_response",
		insert_voyce_panels_reactions_count:"voyce_panels_reactions_count_mutation_response",
		insert_voyce_panels_reactions_count_one:"voyce_panels_reactions_count",
		insert_voyce_panels_reactions_one:"voyce_panels_reactions",
		insert_voyce_payments_to_creators:"voyce_payments_to_creators_mutation_response",
		insert_voyce_payments_to_creators_one:"voyce_payments_to_creators",
		insert_voyce_pg_rs_series_views_count:"voyce_pg_rs_series_views_count_mutation_response",
		insert_voyce_pg_rs_series_views_count_one:"voyce_pg_rs_series_views_count",
		insert_voyce_polls:"voyce_polls_mutation_response",
		insert_voyce_polls_answers:"voyce_polls_answers_mutation_response",
		insert_voyce_polls_answers_one:"voyce_polls_answers",
		insert_voyce_polls_comments:"voyce_polls_comments_mutation_response",
		insert_voyce_polls_comments_one:"voyce_polls_comments",
		insert_voyce_polls_one:"voyce_polls",
		insert_voyce_polls_reactions:"voyce_polls_reactions_mutation_response",
		insert_voyce_polls_reactions_one:"voyce_polls_reactions",
		insert_voyce_polls_votes:"voyce_polls_votes_mutation_response",
		insert_voyce_polls_votes_one:"voyce_polls_votes",
		insert_voyce_popup_components:"voyce_popup_components_mutation_response",
		insert_voyce_popup_components_one:"voyce_popup_components",
		insert_voyce_popup_emails:"voyce_popup_emails_mutation_response",
		insert_voyce_popup_emails_one:"voyce_popup_emails",
		insert_voyce_posts:"voyce_posts_mutation_response",
		insert_voyce_posts_comments:"voyce_posts_comments_mutation_response",
		insert_voyce_posts_comments_one:"voyce_posts_comments",
		insert_voyce_posts_one:"voyce_posts",
		insert_voyce_posts_reactions:"voyce_posts_reactions_mutation_response",
		insert_voyce_posts_reactions_one:"voyce_posts_reactions",
		insert_voyce_quest_difficulties:"voyce_quest_difficulties_mutation_response",
		insert_voyce_quest_difficulties_one:"voyce_quest_difficulties",
		insert_voyce_quests:"voyce_quests_mutation_response",
		insert_voyce_quests_old:"voyce_quests_old_mutation_response",
		insert_voyce_quests_old_one:"voyce_quests_old",
		insert_voyce_quests_one:"voyce_quests",
		insert_voyce_recommended_series:"voyce_recommended_series_mutation_response",
		insert_voyce_recommended_series_one:"voyce_recommended_series",
		insert_voyce_release_schedules:"voyce_release_schedules_mutation_response",
		insert_voyce_release_schedules_one:"voyce_release_schedules",
		insert_voyce_rewards:"voyce_rewards_mutation_response",
		insert_voyce_rewards_leaderboard:"voyce_rewards_leaderboard_mutation_response",
		insert_voyce_rewards_leaderboard_one:"voyce_rewards_leaderboard",
		insert_voyce_rewards_one:"voyce_rewards",
		insert_voyce_rewards_quest:"voyce_rewards_quest_mutation_response",
		insert_voyce_rewards_quest_one:"voyce_rewards_quest",
		insert_voyce_sections:"voyce_sections_mutation_response",
		insert_voyce_sections_one:"voyce_sections",
		insert_voyce_series:"voyce_series_mutation_response",
		insert_voyce_series_collaborators:"voyce_series_collaborators_mutation_response",
		insert_voyce_series_collaborators_one:"voyce_series_collaborators",
		insert_voyce_series_donations:"voyce_series_donations_mutation_response",
		insert_voyce_series_donations_one:"voyce_series_donations",
		insert_voyce_series_favorites:"voyce_series_favorites_mutation_response",
		insert_voyce_series_favorites_one:"voyce_series_favorites",
		insert_voyce_series_featured_panels:"voyce_series_featured_panels_mutation_response",
		insert_voyce_series_featured_panels_one:"voyce_series_featured_panels",
		insert_voyce_series_genre:"voyce_series_genre_mutation_response",
		insert_voyce_series_genre_one:"voyce_series_genre",
		insert_voyce_series_giveaway_participants:"voyce_series_giveaway_participants_mutation_response",
		insert_voyce_series_giveaway_participants_one:"voyce_series_giveaway_participants",
		insert_voyce_series_hot_panels:"voyce_series_hot_panels_mutation_response",
		insert_voyce_series_hot_panels_one:"voyce_series_hot_panels",
		insert_voyce_series_layouts:"voyce_series_layouts_mutation_response",
		insert_voyce_series_layouts_one:"voyce_series_layouts",
		insert_voyce_series_leaderboard:"voyce_series_leaderboard_mutation_response",
		insert_voyce_series_leaderboard_one:"voyce_series_leaderboard",
		insert_voyce_series_lock_config:"voyce_series_lock_config_mutation_response",
		insert_voyce_series_lock_config_one:"voyce_series_lock_config",
		insert_voyce_series_one:"voyce_series",
		insert_voyce_series_sponsorships_configuration:"voyce_series_sponsorships_configuration_mutation_response",
		insert_voyce_series_sponsorships_configuration_one:"voyce_series_sponsorships_configuration",
		insert_voyce_series_subscription:"voyce_series_subscription_mutation_response",
		insert_voyce_series_subscription_one:"voyce_series_subscription",
		insert_voyce_series_tags:"voyce_series_tags_mutation_response",
		insert_voyce_series_tags_junction:"voyce_series_tags_junction_mutation_response",
		insert_voyce_series_tags_junction_one:"voyce_series_tags_junction",
		insert_voyce_series_tags_one:"voyce_series_tags",
		insert_voyce_series_top_comments:"voyce_series_top_comments_mutation_response",
		insert_voyce_series_top_comments_one:"voyce_series_top_comments",
		insert_voyce_series_top_likes:"voyce_series_top_likes_mutation_response",
		insert_voyce_series_top_likes_one:"voyce_series_top_likes",
		insert_voyce_series_top_views:"voyce_series_top_views_mutation_response",
		insert_voyce_series_top_views_one:"voyce_series_top_views",
		insert_voyce_series_views_adjustment:"voyce_series_views_adjustment_mutation_response",
		insert_voyce_series_views_adjustment_one:"voyce_series_views_adjustment",
		insert_voyce_series_votes:"voyce_series_votes_mutation_response",
		insert_voyce_series_votes_count:"voyce_series_votes_count_mutation_response",
		insert_voyce_series_votes_count_one:"voyce_series_votes_count",
		insert_voyce_series_votes_one:"voyce_series_votes",
		insert_voyce_series_voting_schedules:"voyce_series_voting_schedules_mutation_response",
		insert_voyce_series_voting_schedules_one:"voyce_series_voting_schedules",
		insert_voyce_sponsorships:"voyce_sponsorships_mutation_response",
		insert_voyce_sponsorships_one:"voyce_sponsorships",
		insert_voyce_states:"voyce_states_mutation_response",
		insert_voyce_states_one:"voyce_states",
		insert_voyce_stripe_payouts_setup:"voyce_stripe_payouts_setup_mutation_response",
		insert_voyce_stripe_payouts_setup_one:"voyce_stripe_payouts_setup",
		insert_voyce_studio:"voyce_studio_mutation_response",
		insert_voyce_studio_followers:"voyce_studio_followers_mutation_response",
		insert_voyce_studio_followers_one:"voyce_studio_followers",
		insert_voyce_studio_one:"voyce_studio",
		insert_voyce_studio_series:"voyce_studio_series_mutation_response",
		insert_voyce_studio_series_one:"voyce_studio_series",
		insert_voyce_studio_views:"voyce_studio_views_mutation_response",
		insert_voyce_studio_views_one:"voyce_studio_views",
		insert_voyce_system_settings:"voyce_system_settings_mutation_response",
		insert_voyce_system_settings_one:"voyce_system_settings",
		insert_voyce_transaction_logs:"voyce_transaction_logs_mutation_response",
		insert_voyce_transaction_logs_one:"voyce_transaction_logs",
		insert_voyce_url_redirects:"voyce_url_redirects_mutation_response",
		insert_voyce_url_redirects_one:"voyce_url_redirects",
		insert_voyce_user_addresses:"voyce_user_addresses_mutation_response",
		insert_voyce_user_addresses_one:"voyce_user_addresses",
		insert_voyce_user_blocks:"voyce_user_blocks_mutation_response",
		insert_voyce_user_blocks_one:"voyce_user_blocks",
		insert_voyce_user_chapters_unlocked:"voyce_user_chapters_unlocked_mutation_response",
		insert_voyce_user_chapters_unlocked_one:"voyce_user_chapters_unlocked",
		insert_voyce_user_contacts:"voyce_user_contacts_mutation_response",
		insert_voyce_user_contacts_one:"voyce_user_contacts",
		insert_voyce_user_csat_ratings:"voyce_user_csat_ratings_mutation_response",
		insert_voyce_user_csat_ratings_one:"voyce_user_csat_ratings",
		insert_voyce_user_education:"voyce_user_education_mutation_response",
		insert_voyce_user_education_one:"voyce_user_education",
		insert_voyce_user_level:"voyce_user_level_mutation_response",
		insert_voyce_user_level_one:"voyce_user_level",
		insert_voyce_user_linked_accounts:"voyce_user_linked_accounts_mutation_response",
		insert_voyce_user_linked_accounts_one:"voyce_user_linked_accounts",
		insert_voyce_user_login_history:"voyce_user_login_history_mutation_response",
		insert_voyce_user_login_history_one:"voyce_user_login_history",
		insert_voyce_user_quest_ids:"voyce_user_quest_ids_mutation_response",
		insert_voyce_user_quest_ids_one:"voyce_user_quest_ids",
		insert_voyce_user_quest_rewards:"voyce_user_quest_rewards_mutation_response",
		insert_voyce_user_quest_rewards_one:"voyce_user_quest_rewards",
		insert_voyce_user_quests:"voyce_user_quests_mutation_response",
		insert_voyce_user_quests_old:"voyce_user_quests_old_mutation_response",
		insert_voyce_user_quests_old_one:"voyce_user_quests_old",
		insert_voyce_user_quests_one:"voyce_user_quests",
		insert_voyce_user_ranking:"voyce_user_ranking_mutation_response",
		insert_voyce_user_ranking_one:"voyce_user_ranking",
		insert_voyce_user_reviews:"voyce_user_reviews_mutation_response",
		insert_voyce_user_reviews_one:"voyce_user_reviews",
		insert_voyce_user_rewards:"voyce_user_rewards_mutation_response",
		insert_voyce_user_rewards_one:"voyce_user_rewards",
		insert_voyce_user_roles:"voyce_user_roles_mutation_response",
		insert_voyce_user_roles_one:"voyce_user_roles",
		insert_voyce_user_settings:"voyce_user_settings_mutation_response",
		insert_voyce_user_settings_one:"voyce_user_settings",
		insert_voyce_user_skills:"voyce_user_skills_mutation_response",
		insert_voyce_user_skills_one:"voyce_user_skills",
		insert_voyce_user_socials:"voyce_user_socials_mutation_response",
		insert_voyce_user_socials_one:"voyce_user_socials",
		insert_voyce_user_soft_deleted:"voyce_user_soft_deleted_mutation_response",
		insert_voyce_user_soft_deleted_one:"voyce_user_soft_deleted",
		insert_voyce_user_vtags:"voyce_user_vtags_mutation_response",
		insert_voyce_user_vtags_one:"voyce_user_vtags",
		insert_voyce_user_wins:"voyce_user_wins_mutation_response",
		insert_voyce_user_wins_one:"voyce_user_wins",
		insert_voyce_users:"voyce_users_mutation_response",
		insert_voyce_users_followers:"voyce_users_followers_mutation_response",
		insert_voyce_users_followers_one:"voyce_users_followers",
		insert_voyce_users_one:"voyce_users",
		insert_voyce_users_private:"voyce_users_private_mutation_response",
		insert_voyce_users_private_one:"voyce_users_private",
		insert_voyce_users_series_junction:"voyce_users_series_junction_mutation_response",
		insert_voyce_users_series_junction_one:"voyce_users_series_junction",
		insert_voyce_versions:"voyce_versions_mutation_response",
		insert_voyce_versions_one:"voyce_versions",
		insert_voyce_voting_analytics:"voyce_voting_analytics_mutation_response",
		insert_voyce_voting_analytics_one:"voyce_voting_analytics",
		insert_voyce_voting_panels:"voyce_voting_panels_mutation_response",
		insert_voyce_voting_panels_one:"voyce_voting_panels",
		insert_voyce_voting_series:"voyce_voting_series_mutation_response",
		insert_voyce_voting_series_one:"voyce_voting_series",
		insert_voyce_voting_user_votes:"voyce_voting_user_votes_mutation_response",
		insert_voyce_voting_user_votes_one:"voyce_voting_user_votes",
		update_awsdms_validation_failures_v1:"awsdms_validation_failures_v1_mutation_response",
		update_awsdms_validation_failures_v1_many:"awsdms_validation_failures_v1_mutation_response",
		update_coinsystem_balances:"coinsystem_balances_mutation_response",
		update_coinsystem_balances_by_pk:"coinsystem_balances",
		update_coinsystem_balances_many:"coinsystem_balances_mutation_response",
		update_coinsystem_bonus_coins:"coinsystem_bonus_coins_mutation_response",
		update_coinsystem_bonus_coins_by_pk:"coinsystem_bonus_coins",
		update_coinsystem_bonus_coins_many:"coinsystem_bonus_coins_mutation_response",
		update_coinsystem_coins:"coinsystem_coins_mutation_response",
		update_coinsystem_coins_by_pk:"coinsystem_coins",
		update_coinsystem_coins_many:"coinsystem_coins_mutation_response",
		update_coinsystem_exchange_rates:"coinsystem_exchange_rates_mutation_response",
		update_coinsystem_exchange_rates_by_pk:"coinsystem_exchange_rates",
		update_coinsystem_exchange_rates_many:"coinsystem_exchange_rates_mutation_response",
		update_coinsystem_logs:"coinsystem_logs_mutation_response",
		update_coinsystem_logs_by_pk:"coinsystem_logs",
		update_coinsystem_logs_many:"coinsystem_logs_mutation_response",
		update_coinsystem_transactions:"coinsystem_transactions_mutation_response",
		update_coinsystem_transactions_by_pk:"coinsystem_transactions",
		update_coinsystem_transactions_many:"coinsystem_transactions_mutation_response",
		update_coinsystem_wallets:"coinsystem_wallets_mutation_response",
		update_coinsystem_wallets_by_pk:"coinsystem_wallets",
		update_coinsystem_wallets_many:"coinsystem_wallets_mutation_response",
		update_mysql_user:"mysql_user_mutation_response",
		update_mysql_user_by_pk:"mysql_user",
		update_mysql_user_many:"mysql_user_mutation_response",
		update_store_payments:"store_payments_mutation_response",
		update_store_payments_by_pk:"store_payments",
		update_store_payments_many:"store_payments_mutation_response",
		update_store_products:"store_products_mutation_response",
		update_store_products_by_pk:"store_products",
		update_store_products_many:"store_products_mutation_response",
		update_store_purchases:"store_purchases_mutation_response",
		update_store_purchases_by_pk:"store_purchases",
		update_store_purchases_many:"store_purchases_mutation_response",
		update_store_users:"store_users_mutation_response",
		update_store_users_many:"store_users_mutation_response",
		update_voyce_admin_roles:"voyce_admin_roles_mutation_response",
		update_voyce_admin_roles_by_pk:"voyce_admin_roles",
		update_voyce_admin_roles_many:"voyce_admin_roles_mutation_response",
		update_voyce_app_banners:"voyce_app_banners_mutation_response",
		update_voyce_app_banners_by_pk:"voyce_app_banners",
		update_voyce_app_banners_many:"voyce_app_banners_mutation_response",
		update_voyce_app_components:"voyce_app_components_mutation_response",
		update_voyce_app_components_by_pk:"voyce_app_components",
		update_voyce_app_components_many:"voyce_app_components_mutation_response",
		update_voyce_audit_trail:"voyce_audit_trail_mutation_response",
		update_voyce_audit_trail_by_pk:"voyce_audit_trail",
		update_voyce_audit_trail_many:"voyce_audit_trail_mutation_response",
		update_voyce_author_profiles:"voyce_author_profiles_mutation_response",
		update_voyce_author_profiles_by_pk:"voyce_author_profiles",
		update_voyce_author_profiles_many:"voyce_author_profiles_mutation_response",
		update_voyce_balances:"voyce_balances_mutation_response",
		update_voyce_balances_by_pk:"voyce_balances",
		update_voyce_balances_many:"voyce_balances_mutation_response",
		update_voyce_banners:"voyce_banners_mutation_response",
		update_voyce_banners_by_pk:"voyce_banners",
		update_voyce_banners_many:"voyce_banners_mutation_response",
		update_voyce_chapter_assets:"voyce_chapter_assets_mutation_response",
		update_voyce_chapter_assets_by_pk:"voyce_chapter_assets",
		update_voyce_chapter_assets_many:"voyce_chapter_assets_mutation_response",
		update_voyce_chapter_bookmarks:"voyce_chapter_bookmarks_mutation_response",
		update_voyce_chapter_bookmarks_by_pk:"voyce_chapter_bookmarks",
		update_voyce_chapter_bookmarks_many:"voyce_chapter_bookmarks_mutation_response",
		update_voyce_chapter_comments:"voyce_chapter_comments_mutation_response",
		update_voyce_chapter_comments_07252024:"voyce_chapter_comments_07252024_mutation_response",
		update_voyce_chapter_comments_07252024_many:"voyce_chapter_comments_07252024_mutation_response",
		update_voyce_chapter_comments_by_pk:"voyce_chapter_comments",
		update_voyce_chapter_comments_many:"voyce_chapter_comments_mutation_response",
		update_voyce_chapter_comments_reaction:"voyce_chapter_comments_reaction_mutation_response",
		update_voyce_chapter_comments_reaction_by_pk:"voyce_chapter_comments_reaction",
		update_voyce_chapter_comments_reaction_count:"voyce_chapter_comments_reaction_count_mutation_response",
		update_voyce_chapter_comments_reaction_count_by_pk:"voyce_chapter_comments_reaction_count",
		update_voyce_chapter_comments_reaction_count_many:"voyce_chapter_comments_reaction_count_mutation_response",
		update_voyce_chapter_comments_reaction_many:"voyce_chapter_comments_reaction_mutation_response",
		update_voyce_chapter_comments_reports:"voyce_chapter_comments_reports_mutation_response",
		update_voyce_chapter_comments_reports_by_pk:"voyce_chapter_comments_reports",
		update_voyce_chapter_comments_reports_many:"voyce_chapter_comments_reports_mutation_response",
		update_voyce_chapter_contents:"voyce_chapter_contents_mutation_response",
		update_voyce_chapter_contents_by_pk:"voyce_chapter_contents",
		update_voyce_chapter_contents_many:"voyce_chapter_contents_mutation_response",
		update_voyce_chapter_contents_old:"voyce_chapter_contents_old_mutation_response",
		update_voyce_chapter_contents_old_by_pk:"voyce_chapter_contents_old",
		update_voyce_chapter_contents_old_many:"voyce_chapter_contents_old_mutation_response",
		update_voyce_chapter_effects:"voyce_chapter_effects_mutation_response",
		update_voyce_chapter_effects_by_pk:"voyce_chapter_effects",
		update_voyce_chapter_effects_many:"voyce_chapter_effects_mutation_response",
		update_voyce_chapter_images:"voyce_chapter_images_mutation_response",
		update_voyce_chapter_images_07252024:"voyce_chapter_images_07252024_mutation_response",
		update_voyce_chapter_images_07252024_many:"voyce_chapter_images_07252024_mutation_response",
		update_voyce_chapter_images_by_pk:"voyce_chapter_images",
		update_voyce_chapter_images_many:"voyce_chapter_images_mutation_response",
		update_voyce_chapter_likes:"voyce_chapter_likes_mutation_response",
		update_voyce_chapter_likes_by_pk:"voyce_chapter_likes",
		update_voyce_chapter_likes_many:"voyce_chapter_likes_mutation_response",
		update_voyce_chapter_locks:"voyce_chapter_locks_mutation_response",
		update_voyce_chapter_locks_by_pk:"voyce_chapter_locks",
		update_voyce_chapter_locks_many:"voyce_chapter_locks_mutation_response",
		update_voyce_chapter_panel_views:"voyce_chapter_panel_views_mutation_response",
		update_voyce_chapter_panel_views_by_pk:"voyce_chapter_panel_views",
		update_voyce_chapter_panel_views_many:"voyce_chapter_panel_views_mutation_response",
		update_voyce_chapter_panels:"voyce_chapter_panels_mutation_response",
		update_voyce_chapter_panels_by_pk:"voyce_chapter_panels",
		update_voyce_chapter_panels_many:"voyce_chapter_panels_mutation_response",
		update_voyce_chapter_publish_schedules:"voyce_chapter_publish_schedules_mutation_response",
		update_voyce_chapter_publish_schedules_by_pk:"voyce_chapter_publish_schedules",
		update_voyce_chapter_publish_schedules_many:"voyce_chapter_publish_schedules_mutation_response",
		update_voyce_chapter_reactions:"voyce_chapter_reactions_mutation_response",
		update_voyce_chapter_reactions_by_pk:"voyce_chapter_reactions",
		update_voyce_chapter_reactions_count:"voyce_chapter_reactions_count_mutation_response",
		update_voyce_chapter_reactions_count_by_pk:"voyce_chapter_reactions_count",
		update_voyce_chapter_reactions_count_many:"voyce_chapter_reactions_count_mutation_response",
		update_voyce_chapter_reactions_many:"voyce_chapter_reactions_mutation_response",
		update_voyce_chapter_view_logs:"voyce_chapter_view_logs_mutation_response",
		update_voyce_chapter_view_logs_by_pk:"voyce_chapter_view_logs",
		update_voyce_chapter_view_logs_many:"voyce_chapter_view_logs_mutation_response",
		update_voyce_chapter_views:"voyce_chapter_views_mutation_response",
		update_voyce_chapter_views_by_pk:"voyce_chapter_views",
		update_voyce_chapter_views_many:"voyce_chapter_views_mutation_response",
		update_voyce_chapters:"voyce_chapters_mutation_response",
		update_voyce_chapters_07252024:"voyce_chapters_07252024_mutation_response",
		update_voyce_chapters_07252024_many:"voyce_chapters_07252024_mutation_response",
		update_voyce_chapters_by_pk:"voyce_chapters",
		update_voyce_chapters_many:"voyce_chapters_mutation_response",
		update_voyce_collaborator_roles:"voyce_collaborator_roles_mutation_response",
		update_voyce_collaborator_roles_by_pk:"voyce_collaborator_roles",
		update_voyce_collaborator_roles_many:"voyce_collaborator_roles_mutation_response",
		update_voyce_comic_types:"voyce_comic_types_mutation_response",
		update_voyce_comic_types_by_pk:"voyce_comic_types",
		update_voyce_comic_types_many:"voyce_comic_types_mutation_response",
		update_voyce_community_blurbs:"voyce_community_blurbs_mutation_response",
		update_voyce_community_blurbs_by_pk:"voyce_community_blurbs",
		update_voyce_community_blurbs_many:"voyce_community_blurbs_mutation_response",
		update_voyce_contact_us:"voyce_contact_us_mutation_response",
		update_voyce_contact_us_by_pk:"voyce_contact_us",
		update_voyce_contact_us_many:"voyce_contact_us_mutation_response",
		update_voyce_content_report:"voyce_content_report_mutation_response",
		update_voyce_content_report_by_pk:"voyce_content_report",
		update_voyce_content_report_many:"voyce_content_report_mutation_response",
		update_voyce_contest_data:"voyce_contest_data_mutation_response",
		update_voyce_contest_data_by_pk:"voyce_contest_data",
		update_voyce_contest_data_many:"voyce_contest_data_mutation_response",
		update_voyce_countries:"voyce_countries_mutation_response",
		update_voyce_countries_by_pk:"voyce_countries",
		update_voyce_countries_many:"voyce_countries_mutation_response",
		update_voyce_csat_ratings:"voyce_csat_ratings_mutation_response",
		update_voyce_csat_ratings_by_pk:"voyce_csat_ratings",
		update_voyce_csat_ratings_data:"voyce_csat_ratings_data_mutation_response",
		update_voyce_csat_ratings_data_by_pk:"voyce_csat_ratings_data",
		update_voyce_csat_ratings_data_many:"voyce_csat_ratings_data_mutation_response",
		update_voyce_csat_ratings_many:"voyce_csat_ratings_mutation_response",
		update_voyce_education_types:"voyce_education_types_mutation_response",
		update_voyce_education_types_by_pk:"voyce_education_types",
		update_voyce_education_types_many:"voyce_education_types_mutation_response",
		update_voyce_erp_login_roles:"voyce_erp_login_roles_mutation_response",
		update_voyce_erp_login_roles_by_pk:"voyce_erp_login_roles",
		update_voyce_erp_login_roles_many:"voyce_erp_login_roles_mutation_response",
		update_voyce_etl_series_views_logs:"voyce_etl_series_views_logs_mutation_response",
		update_voyce_etl_series_views_logs_by_pk:"voyce_etl_series_views_logs",
		update_voyce_etl_series_views_logs_many:"voyce_etl_series_views_logs_mutation_response",
		update_voyce_event_schedules:"voyce_event_schedules_mutation_response",
		update_voyce_event_schedules_by_pk:"voyce_event_schedules",
		update_voyce_event_schedules_many:"voyce_event_schedules_mutation_response",
		update_voyce_genres:"voyce_genres_mutation_response",
		update_voyce_genres_by_pk:"voyce_genres",
		update_voyce_genres_many:"voyce_genres_mutation_response",
		update_voyce_guest_gtags:"voyce_guest_gtags_mutation_response",
		update_voyce_guest_gtags_by_pk:"voyce_guest_gtags",
		update_voyce_guest_gtags_many:"voyce_guest_gtags_mutation_response",
		update_voyce_metabases:"voyce_metabases_mutation_response",
		update_voyce_metabases_access:"voyce_metabases_access_mutation_response",
		update_voyce_metabases_access_by_pk:"voyce_metabases_access",
		update_voyce_metabases_access_many:"voyce_metabases_access_mutation_response",
		update_voyce_metabases_by_pk:"voyce_metabases",
		update_voyce_metabases_many:"voyce_metabases_mutation_response",
		update_voyce_newsletter:"voyce_newsletter_mutation_response",
		update_voyce_newsletter_by_pk:"voyce_newsletter",
		update_voyce_newsletter_many:"voyce_newsletter_mutation_response",
		update_voyce_notification_series_settings:"voyce_notification_series_settings_mutation_response",
		update_voyce_notification_series_settings_by_pk:"voyce_notification_series_settings",
		update_voyce_notification_series_settings_many:"voyce_notification_series_settings_mutation_response",
		update_voyce_notification_settings:"voyce_notification_settings_mutation_response",
		update_voyce_notification_settings_by_pk:"voyce_notification_settings",
		update_voyce_notification_settings_many:"voyce_notification_settings_mutation_response",
		update_voyce_notifications:"voyce_notifications_mutation_response",
		update_voyce_notifications_by_pk:"voyce_notifications",
		update_voyce_notifications_many:"voyce_notifications_mutation_response",
		update_voyce_page_banners:"voyce_page_banners_mutation_response",
		update_voyce_page_banners_by_pk:"voyce_page_banners",
		update_voyce_page_banners_many:"voyce_page_banners_mutation_response",
		update_voyce_page_components:"voyce_page_components_mutation_response",
		update_voyce_page_components_by_pk:"voyce_page_components",
		update_voyce_page_components_many:"voyce_page_components_mutation_response",
		update_voyce_pages:"voyce_pages_mutation_response",
		update_voyce_pages_by_pk:"voyce_pages",
		update_voyce_pages_many:"voyce_pages_mutation_response",
		update_voyce_panels_comments:"voyce_panels_comments_mutation_response",
		update_voyce_panels_comments_by_pk:"voyce_panels_comments",
		update_voyce_panels_comments_many:"voyce_panels_comments_mutation_response",
		update_voyce_panels_reactions:"voyce_panels_reactions_mutation_response",
		update_voyce_panels_reactions_by_pk:"voyce_panels_reactions",
		update_voyce_panels_reactions_count:"voyce_panels_reactions_count_mutation_response",
		update_voyce_panels_reactions_count_by_pk:"voyce_panels_reactions_count",
		update_voyce_panels_reactions_count_many:"voyce_panels_reactions_count_mutation_response",
		update_voyce_panels_reactions_many:"voyce_panels_reactions_mutation_response",
		update_voyce_payments_to_creators:"voyce_payments_to_creators_mutation_response",
		update_voyce_payments_to_creators_by_pk:"voyce_payments_to_creators",
		update_voyce_payments_to_creators_many:"voyce_payments_to_creators_mutation_response",
		update_voyce_pg_rs_series_views_count:"voyce_pg_rs_series_views_count_mutation_response",
		update_voyce_pg_rs_series_views_count_by_pk:"voyce_pg_rs_series_views_count",
		update_voyce_pg_rs_series_views_count_many:"voyce_pg_rs_series_views_count_mutation_response",
		update_voyce_polls:"voyce_polls_mutation_response",
		update_voyce_polls_answers:"voyce_polls_answers_mutation_response",
		update_voyce_polls_answers_by_pk:"voyce_polls_answers",
		update_voyce_polls_answers_many:"voyce_polls_answers_mutation_response",
		update_voyce_polls_by_pk:"voyce_polls",
		update_voyce_polls_comments:"voyce_polls_comments_mutation_response",
		update_voyce_polls_comments_by_pk:"voyce_polls_comments",
		update_voyce_polls_comments_many:"voyce_polls_comments_mutation_response",
		update_voyce_polls_many:"voyce_polls_mutation_response",
		update_voyce_polls_reactions:"voyce_polls_reactions_mutation_response",
		update_voyce_polls_reactions_by_pk:"voyce_polls_reactions",
		update_voyce_polls_reactions_many:"voyce_polls_reactions_mutation_response",
		update_voyce_polls_votes:"voyce_polls_votes_mutation_response",
		update_voyce_polls_votes_by_pk:"voyce_polls_votes",
		update_voyce_polls_votes_many:"voyce_polls_votes_mutation_response",
		update_voyce_popup_components:"voyce_popup_components_mutation_response",
		update_voyce_popup_components_by_pk:"voyce_popup_components",
		update_voyce_popup_components_many:"voyce_popup_components_mutation_response",
		update_voyce_popup_emails:"voyce_popup_emails_mutation_response",
		update_voyce_popup_emails_by_pk:"voyce_popup_emails",
		update_voyce_popup_emails_many:"voyce_popup_emails_mutation_response",
		update_voyce_posts:"voyce_posts_mutation_response",
		update_voyce_posts_by_pk:"voyce_posts",
		update_voyce_posts_comments:"voyce_posts_comments_mutation_response",
		update_voyce_posts_comments_by_pk:"voyce_posts_comments",
		update_voyce_posts_comments_many:"voyce_posts_comments_mutation_response",
		update_voyce_posts_many:"voyce_posts_mutation_response",
		update_voyce_posts_reactions:"voyce_posts_reactions_mutation_response",
		update_voyce_posts_reactions_by_pk:"voyce_posts_reactions",
		update_voyce_posts_reactions_many:"voyce_posts_reactions_mutation_response",
		update_voyce_quest_difficulties:"voyce_quest_difficulties_mutation_response",
		update_voyce_quest_difficulties_by_pk:"voyce_quest_difficulties",
		update_voyce_quest_difficulties_many:"voyce_quest_difficulties_mutation_response",
		update_voyce_quests:"voyce_quests_mutation_response",
		update_voyce_quests_by_pk:"voyce_quests",
		update_voyce_quests_many:"voyce_quests_mutation_response",
		update_voyce_quests_old:"voyce_quests_old_mutation_response",
		update_voyce_quests_old_by_pk:"voyce_quests_old",
		update_voyce_quests_old_many:"voyce_quests_old_mutation_response",
		update_voyce_recommended_series:"voyce_recommended_series_mutation_response",
		update_voyce_recommended_series_by_pk:"voyce_recommended_series",
		update_voyce_recommended_series_many:"voyce_recommended_series_mutation_response",
		update_voyce_release_schedules:"voyce_release_schedules_mutation_response",
		update_voyce_release_schedules_by_pk:"voyce_release_schedules",
		update_voyce_release_schedules_many:"voyce_release_schedules_mutation_response",
		update_voyce_rewards:"voyce_rewards_mutation_response",
		update_voyce_rewards_by_pk:"voyce_rewards",
		update_voyce_rewards_leaderboard:"voyce_rewards_leaderboard_mutation_response",
		update_voyce_rewards_leaderboard_by_pk:"voyce_rewards_leaderboard",
		update_voyce_rewards_leaderboard_many:"voyce_rewards_leaderboard_mutation_response",
		update_voyce_rewards_many:"voyce_rewards_mutation_response",
		update_voyce_rewards_quest:"voyce_rewards_quest_mutation_response",
		update_voyce_rewards_quest_by_pk:"voyce_rewards_quest",
		update_voyce_rewards_quest_many:"voyce_rewards_quest_mutation_response",
		update_voyce_sections:"voyce_sections_mutation_response",
		update_voyce_sections_by_pk:"voyce_sections",
		update_voyce_sections_many:"voyce_sections_mutation_response",
		update_voyce_series:"voyce_series_mutation_response",
		update_voyce_series_by_pk:"voyce_series",
		update_voyce_series_collaborators:"voyce_series_collaborators_mutation_response",
		update_voyce_series_collaborators_by_pk:"voyce_series_collaborators",
		update_voyce_series_collaborators_many:"voyce_series_collaborators_mutation_response",
		update_voyce_series_donations:"voyce_series_donations_mutation_response",
		update_voyce_series_donations_by_pk:"voyce_series_donations",
		update_voyce_series_donations_many:"voyce_series_donations_mutation_response",
		update_voyce_series_favorites:"voyce_series_favorites_mutation_response",
		update_voyce_series_favorites_by_pk:"voyce_series_favorites",
		update_voyce_series_favorites_many:"voyce_series_favorites_mutation_response",
		update_voyce_series_featured_panels:"voyce_series_featured_panels_mutation_response",
		update_voyce_series_featured_panels_by_pk:"voyce_series_featured_panels",
		update_voyce_series_featured_panels_many:"voyce_series_featured_panels_mutation_response",
		update_voyce_series_genre:"voyce_series_genre_mutation_response",
		update_voyce_series_genre_by_pk:"voyce_series_genre",
		update_voyce_series_genre_many:"voyce_series_genre_mutation_response",
		update_voyce_series_giveaway_participants:"voyce_series_giveaway_participants_mutation_response",
		update_voyce_series_giveaway_participants_by_pk:"voyce_series_giveaway_participants",
		update_voyce_series_giveaway_participants_many:"voyce_series_giveaway_participants_mutation_response",
		update_voyce_series_hot_panels:"voyce_series_hot_panels_mutation_response",
		update_voyce_series_hot_panels_by_pk:"voyce_series_hot_panels",
		update_voyce_series_hot_panels_many:"voyce_series_hot_panels_mutation_response",
		update_voyce_series_layouts:"voyce_series_layouts_mutation_response",
		update_voyce_series_layouts_by_pk:"voyce_series_layouts",
		update_voyce_series_layouts_many:"voyce_series_layouts_mutation_response",
		update_voyce_series_leaderboard:"voyce_series_leaderboard_mutation_response",
		update_voyce_series_leaderboard_by_pk:"voyce_series_leaderboard",
		update_voyce_series_leaderboard_many:"voyce_series_leaderboard_mutation_response",
		update_voyce_series_lock_config:"voyce_series_lock_config_mutation_response",
		update_voyce_series_lock_config_by_pk:"voyce_series_lock_config",
		update_voyce_series_lock_config_many:"voyce_series_lock_config_mutation_response",
		update_voyce_series_many:"voyce_series_mutation_response",
		update_voyce_series_sponsorships_configuration:"voyce_series_sponsorships_configuration_mutation_response",
		update_voyce_series_sponsorships_configuration_by_pk:"voyce_series_sponsorships_configuration",
		update_voyce_series_sponsorships_configuration_many:"voyce_series_sponsorships_configuration_mutation_response",
		update_voyce_series_subscription:"voyce_series_subscription_mutation_response",
		update_voyce_series_subscription_by_pk:"voyce_series_subscription",
		update_voyce_series_subscription_many:"voyce_series_subscription_mutation_response",
		update_voyce_series_tags:"voyce_series_tags_mutation_response",
		update_voyce_series_tags_by_pk:"voyce_series_tags",
		update_voyce_series_tags_junction:"voyce_series_tags_junction_mutation_response",
		update_voyce_series_tags_junction_by_pk:"voyce_series_tags_junction",
		update_voyce_series_tags_junction_many:"voyce_series_tags_junction_mutation_response",
		update_voyce_series_tags_many:"voyce_series_tags_mutation_response",
		update_voyce_series_top_comments:"voyce_series_top_comments_mutation_response",
		update_voyce_series_top_comments_by_pk:"voyce_series_top_comments",
		update_voyce_series_top_comments_many:"voyce_series_top_comments_mutation_response",
		update_voyce_series_top_likes:"voyce_series_top_likes_mutation_response",
		update_voyce_series_top_likes_by_pk:"voyce_series_top_likes",
		update_voyce_series_top_likes_many:"voyce_series_top_likes_mutation_response",
		update_voyce_series_top_views:"voyce_series_top_views_mutation_response",
		update_voyce_series_top_views_by_pk:"voyce_series_top_views",
		update_voyce_series_top_views_many:"voyce_series_top_views_mutation_response",
		update_voyce_series_views_adjustment:"voyce_series_views_adjustment_mutation_response",
		update_voyce_series_views_adjustment_by_pk:"voyce_series_views_adjustment",
		update_voyce_series_views_adjustment_many:"voyce_series_views_adjustment_mutation_response",
		update_voyce_series_votes:"voyce_series_votes_mutation_response",
		update_voyce_series_votes_by_pk:"voyce_series_votes",
		update_voyce_series_votes_count:"voyce_series_votes_count_mutation_response",
		update_voyce_series_votes_count_by_pk:"voyce_series_votes_count",
		update_voyce_series_votes_count_many:"voyce_series_votes_count_mutation_response",
		update_voyce_series_votes_many:"voyce_series_votes_mutation_response",
		update_voyce_series_voting_schedules:"voyce_series_voting_schedules_mutation_response",
		update_voyce_series_voting_schedules_by_pk:"voyce_series_voting_schedules",
		update_voyce_series_voting_schedules_many:"voyce_series_voting_schedules_mutation_response",
		update_voyce_sponsorships:"voyce_sponsorships_mutation_response",
		update_voyce_sponsorships_by_pk:"voyce_sponsorships",
		update_voyce_sponsorships_many:"voyce_sponsorships_mutation_response",
		update_voyce_states:"voyce_states_mutation_response",
		update_voyce_states_by_pk:"voyce_states",
		update_voyce_states_many:"voyce_states_mutation_response",
		update_voyce_stripe_payouts_setup:"voyce_stripe_payouts_setup_mutation_response",
		update_voyce_stripe_payouts_setup_by_pk:"voyce_stripe_payouts_setup",
		update_voyce_stripe_payouts_setup_many:"voyce_stripe_payouts_setup_mutation_response",
		update_voyce_studio:"voyce_studio_mutation_response",
		update_voyce_studio_by_pk:"voyce_studio",
		update_voyce_studio_followers:"voyce_studio_followers_mutation_response",
		update_voyce_studio_followers_by_pk:"voyce_studio_followers",
		update_voyce_studio_followers_many:"voyce_studio_followers_mutation_response",
		update_voyce_studio_many:"voyce_studio_mutation_response",
		update_voyce_studio_series:"voyce_studio_series_mutation_response",
		update_voyce_studio_series_by_pk:"voyce_studio_series",
		update_voyce_studio_series_many:"voyce_studio_series_mutation_response",
		update_voyce_studio_views:"voyce_studio_views_mutation_response",
		update_voyce_studio_views_by_pk:"voyce_studio_views",
		update_voyce_studio_views_many:"voyce_studio_views_mutation_response",
		update_voyce_system_settings:"voyce_system_settings_mutation_response",
		update_voyce_system_settings_by_pk:"voyce_system_settings",
		update_voyce_system_settings_many:"voyce_system_settings_mutation_response",
		update_voyce_transaction_logs:"voyce_transaction_logs_mutation_response",
		update_voyce_transaction_logs_by_pk:"voyce_transaction_logs",
		update_voyce_transaction_logs_many:"voyce_transaction_logs_mutation_response",
		update_voyce_url_redirects:"voyce_url_redirects_mutation_response",
		update_voyce_url_redirects_by_pk:"voyce_url_redirects",
		update_voyce_url_redirects_many:"voyce_url_redirects_mutation_response",
		update_voyce_user_addresses:"voyce_user_addresses_mutation_response",
		update_voyce_user_addresses_by_pk:"voyce_user_addresses",
		update_voyce_user_addresses_many:"voyce_user_addresses_mutation_response",
		update_voyce_user_blocks:"voyce_user_blocks_mutation_response",
		update_voyce_user_blocks_by_pk:"voyce_user_blocks",
		update_voyce_user_blocks_many:"voyce_user_blocks_mutation_response",
		update_voyce_user_chapters_unlocked:"voyce_user_chapters_unlocked_mutation_response",
		update_voyce_user_chapters_unlocked_by_pk:"voyce_user_chapters_unlocked",
		update_voyce_user_chapters_unlocked_many:"voyce_user_chapters_unlocked_mutation_response",
		update_voyce_user_contacts:"voyce_user_contacts_mutation_response",
		update_voyce_user_contacts_by_pk:"voyce_user_contacts",
		update_voyce_user_contacts_many:"voyce_user_contacts_mutation_response",
		update_voyce_user_csat_ratings:"voyce_user_csat_ratings_mutation_response",
		update_voyce_user_csat_ratings_by_pk:"voyce_user_csat_ratings",
		update_voyce_user_csat_ratings_many:"voyce_user_csat_ratings_mutation_response",
		update_voyce_user_education:"voyce_user_education_mutation_response",
		update_voyce_user_education_by_pk:"voyce_user_education",
		update_voyce_user_education_many:"voyce_user_education_mutation_response",
		update_voyce_user_level:"voyce_user_level_mutation_response",
		update_voyce_user_level_by_pk:"voyce_user_level",
		update_voyce_user_level_many:"voyce_user_level_mutation_response",
		update_voyce_user_linked_accounts:"voyce_user_linked_accounts_mutation_response",
		update_voyce_user_linked_accounts_by_pk:"voyce_user_linked_accounts",
		update_voyce_user_linked_accounts_many:"voyce_user_linked_accounts_mutation_response",
		update_voyce_user_login_history:"voyce_user_login_history_mutation_response",
		update_voyce_user_login_history_by_pk:"voyce_user_login_history",
		update_voyce_user_login_history_many:"voyce_user_login_history_mutation_response",
		update_voyce_user_quest_ids:"voyce_user_quest_ids_mutation_response",
		update_voyce_user_quest_ids_by_pk:"voyce_user_quest_ids",
		update_voyce_user_quest_ids_many:"voyce_user_quest_ids_mutation_response",
		update_voyce_user_quest_rewards:"voyce_user_quest_rewards_mutation_response",
		update_voyce_user_quest_rewards_by_pk:"voyce_user_quest_rewards",
		update_voyce_user_quest_rewards_many:"voyce_user_quest_rewards_mutation_response",
		update_voyce_user_quests:"voyce_user_quests_mutation_response",
		update_voyce_user_quests_by_pk:"voyce_user_quests",
		update_voyce_user_quests_many:"voyce_user_quests_mutation_response",
		update_voyce_user_quests_old:"voyce_user_quests_old_mutation_response",
		update_voyce_user_quests_old_by_pk:"voyce_user_quests_old",
		update_voyce_user_quests_old_many:"voyce_user_quests_old_mutation_response",
		update_voyce_user_ranking:"voyce_user_ranking_mutation_response",
		update_voyce_user_ranking_by_pk:"voyce_user_ranking",
		update_voyce_user_ranking_many:"voyce_user_ranking_mutation_response",
		update_voyce_user_reviews:"voyce_user_reviews_mutation_response",
		update_voyce_user_reviews_by_pk:"voyce_user_reviews",
		update_voyce_user_reviews_many:"voyce_user_reviews_mutation_response",
		update_voyce_user_rewards:"voyce_user_rewards_mutation_response",
		update_voyce_user_rewards_by_pk:"voyce_user_rewards",
		update_voyce_user_rewards_many:"voyce_user_rewards_mutation_response",
		update_voyce_user_roles:"voyce_user_roles_mutation_response",
		update_voyce_user_roles_by_pk:"voyce_user_roles",
		update_voyce_user_roles_many:"voyce_user_roles_mutation_response",
		update_voyce_user_settings:"voyce_user_settings_mutation_response",
		update_voyce_user_settings_by_pk:"voyce_user_settings",
		update_voyce_user_settings_many:"voyce_user_settings_mutation_response",
		update_voyce_user_skills:"voyce_user_skills_mutation_response",
		update_voyce_user_skills_by_pk:"voyce_user_skills",
		update_voyce_user_skills_many:"voyce_user_skills_mutation_response",
		update_voyce_user_socials:"voyce_user_socials_mutation_response",
		update_voyce_user_socials_by_pk:"voyce_user_socials",
		update_voyce_user_socials_many:"voyce_user_socials_mutation_response",
		update_voyce_user_soft_deleted:"voyce_user_soft_deleted_mutation_response",
		update_voyce_user_soft_deleted_by_pk:"voyce_user_soft_deleted",
		update_voyce_user_soft_deleted_many:"voyce_user_soft_deleted_mutation_response",
		update_voyce_user_vtags:"voyce_user_vtags_mutation_response",
		update_voyce_user_vtags_by_pk:"voyce_user_vtags",
		update_voyce_user_vtags_many:"voyce_user_vtags_mutation_response",
		update_voyce_user_wins:"voyce_user_wins_mutation_response",
		update_voyce_user_wins_by_pk:"voyce_user_wins",
		update_voyce_user_wins_many:"voyce_user_wins_mutation_response",
		update_voyce_users:"voyce_users_mutation_response",
		update_voyce_users_by_pk:"voyce_users",
		update_voyce_users_followers:"voyce_users_followers_mutation_response",
		update_voyce_users_followers_by_pk:"voyce_users_followers",
		update_voyce_users_followers_many:"voyce_users_followers_mutation_response",
		update_voyce_users_many:"voyce_users_mutation_response",
		update_voyce_users_private:"voyce_users_private_mutation_response",
		update_voyce_users_private_many:"voyce_users_private_mutation_response",
		update_voyce_users_series_junction:"voyce_users_series_junction_mutation_response",
		update_voyce_users_series_junction_by_pk:"voyce_users_series_junction",
		update_voyce_users_series_junction_many:"voyce_users_series_junction_mutation_response",
		update_voyce_versions:"voyce_versions_mutation_response",
		update_voyce_versions_by_pk:"voyce_versions",
		update_voyce_versions_many:"voyce_versions_mutation_response",
		update_voyce_voting_analytics:"voyce_voting_analytics_mutation_response",
		update_voyce_voting_analytics_by_pk:"voyce_voting_analytics",
		update_voyce_voting_analytics_many:"voyce_voting_analytics_mutation_response",
		update_voyce_voting_panels:"voyce_voting_panels_mutation_response",
		update_voyce_voting_panels_by_pk:"voyce_voting_panels",
		update_voyce_voting_panels_many:"voyce_voting_panels_mutation_response",
		update_voyce_voting_series:"voyce_voting_series_mutation_response",
		update_voyce_voting_series_by_pk:"voyce_voting_series",
		update_voyce_voting_series_many:"voyce_voting_series_mutation_response",
		update_voyce_voting_user_votes:"voyce_voting_user_votes_mutation_response",
		update_voyce_voting_user_votes_by_pk:"voyce_voting_user_votes",
		update_voyce_voting_user_votes_many:"voyce_voting_user_votes_mutation_response"
	},
	mysql_user:{
		account_locked:"String",
		alter_priv:"String",
		alter_routine_priv:"String",
		authentication_string:"String",
		create_priv:"String",
		create_role_priv:"String",
		create_routine_priv:"String",
		create_tablespace_priv:"String",
		create_tmp_table_priv:"String",
		create_user_priv:"String",
		create_view_priv:"String",
		delete_priv:"String",
		drop_priv:"String",
		drop_role_priv:"String",
		event_priv:"String",
		execute_priv:"String",
		file_priv:"String",
		grant_priv:"String",
		host:"String",
		index_priv:"String",
		insert_priv:"String",
		lock_tables_priv:"String",
		max_connections:"bigint",
		max_questions:"bigint",
		max_updates:"bigint",
		max_user_connections:"bigint",
		password_expired:"String",
		password_last_changed:"timestamp",
		password_lifetime:"Int",
		password_require_current:"String",
		password_reuse_history:"Int",
		password_reuse_time:"Int",
		plugin:"String",
		process_priv:"String",
		references_priv:"String",
		reload_priv:"String",
		repl_client_priv:"String",
		repl_slave_priv:"String",
		select_priv:"String",
		show_db_priv:"String",
		show_view_priv:"String",
		shutdown_priv:"String",
		ssl_cipher:"bytea",
		ssl_type:"String",
		super_priv:"String",
		trigger_priv:"String",
		update_priv:"String",
		user:"String",
		user_attributes:"String",
		x509_issuer:"bytea",
		x509_subject:"bytea"
	},
	mysql_user_aggregate:{
		aggregate:"mysql_user_aggregate_fields",
		nodes:"mysql_user"
	},
	mysql_user_aggregate_fields:{
		avg:"mysql_user_avg_fields",
		count:"Int",
		max:"mysql_user_max_fields",
		min:"mysql_user_min_fields",
		stddev:"mysql_user_stddev_fields",
		stddev_pop:"mysql_user_stddev_pop_fields",
		stddev_samp:"mysql_user_stddev_samp_fields",
		sum:"mysql_user_sum_fields",
		var_pop:"mysql_user_var_pop_fields",
		var_samp:"mysql_user_var_samp_fields",
		variance:"mysql_user_variance_fields"
	},
	mysql_user_avg_fields:{
		max_connections:"Float",
		max_questions:"Float",
		max_updates:"Float",
		max_user_connections:"Float",
		password_lifetime:"Float",
		password_reuse_history:"Float",
		password_reuse_time:"Float"
	},
	mysql_user_max_fields:{
		account_locked:"String",
		alter_priv:"String",
		alter_routine_priv:"String",
		authentication_string:"String",
		create_priv:"String",
		create_role_priv:"String",
		create_routine_priv:"String",
		create_tablespace_priv:"String",
		create_tmp_table_priv:"String",
		create_user_priv:"String",
		create_view_priv:"String",
		delete_priv:"String",
		drop_priv:"String",
		drop_role_priv:"String",
		event_priv:"String",
		execute_priv:"String",
		file_priv:"String",
		grant_priv:"String",
		host:"String",
		index_priv:"String",
		insert_priv:"String",
		lock_tables_priv:"String",
		max_connections:"bigint",
		max_questions:"bigint",
		max_updates:"bigint",
		max_user_connections:"bigint",
		password_expired:"String",
		password_last_changed:"timestamp",
		password_lifetime:"Int",
		password_require_current:"String",
		password_reuse_history:"Int",
		password_reuse_time:"Int",
		plugin:"String",
		process_priv:"String",
		references_priv:"String",
		reload_priv:"String",
		repl_client_priv:"String",
		repl_slave_priv:"String",
		select_priv:"String",
		show_db_priv:"String",
		show_view_priv:"String",
		shutdown_priv:"String",
		ssl_type:"String",
		super_priv:"String",
		trigger_priv:"String",
		update_priv:"String",
		user:"String",
		user_attributes:"String"
	},
	mysql_user_min_fields:{
		account_locked:"String",
		alter_priv:"String",
		alter_routine_priv:"String",
		authentication_string:"String",
		create_priv:"String",
		create_role_priv:"String",
		create_routine_priv:"String",
		create_tablespace_priv:"String",
		create_tmp_table_priv:"String",
		create_user_priv:"String",
		create_view_priv:"String",
		delete_priv:"String",
		drop_priv:"String",
		drop_role_priv:"String",
		event_priv:"String",
		execute_priv:"String",
		file_priv:"String",
		grant_priv:"String",
		host:"String",
		index_priv:"String",
		insert_priv:"String",
		lock_tables_priv:"String",
		max_connections:"bigint",
		max_questions:"bigint",
		max_updates:"bigint",
		max_user_connections:"bigint",
		password_expired:"String",
		password_last_changed:"timestamp",
		password_lifetime:"Int",
		password_require_current:"String",
		password_reuse_history:"Int",
		password_reuse_time:"Int",
		plugin:"String",
		process_priv:"String",
		references_priv:"String",
		reload_priv:"String",
		repl_client_priv:"String",
		repl_slave_priv:"String",
		select_priv:"String",
		show_db_priv:"String",
		show_view_priv:"String",
		shutdown_priv:"String",
		ssl_type:"String",
		super_priv:"String",
		trigger_priv:"String",
		update_priv:"String",
		user:"String",
		user_attributes:"String"
	},
	mysql_user_mutation_response:{
		affected_rows:"Int",
		returning:"mysql_user"
	},
	mysql_user_stddev_fields:{
		max_connections:"Float",
		max_questions:"Float",
		max_updates:"Float",
		max_user_connections:"Float",
		password_lifetime:"Float",
		password_reuse_history:"Float",
		password_reuse_time:"Float"
	},
	mysql_user_stddev_pop_fields:{
		max_connections:"Float",
		max_questions:"Float",
		max_updates:"Float",
		max_user_connections:"Float",
		password_lifetime:"Float",
		password_reuse_history:"Float",
		password_reuse_time:"Float"
	},
	mysql_user_stddev_samp_fields:{
		max_connections:"Float",
		max_questions:"Float",
		max_updates:"Float",
		max_user_connections:"Float",
		password_lifetime:"Float",
		password_reuse_history:"Float",
		password_reuse_time:"Float"
	},
	mysql_user_sum_fields:{
		max_connections:"bigint",
		max_questions:"bigint",
		max_updates:"bigint",
		max_user_connections:"bigint",
		password_lifetime:"Int",
		password_reuse_history:"Int",
		password_reuse_time:"Int"
	},
	mysql_user_var_pop_fields:{
		max_connections:"Float",
		max_questions:"Float",
		max_updates:"Float",
		max_user_connections:"Float",
		password_lifetime:"Float",
		password_reuse_history:"Float",
		password_reuse_time:"Float"
	},
	mysql_user_var_samp_fields:{
		max_connections:"Float",
		max_questions:"Float",
		max_updates:"Float",
		max_user_connections:"Float",
		password_lifetime:"Float",
		password_reuse_history:"Float",
		password_reuse_time:"Float"
	},
	mysql_user_variance_fields:{
		max_connections:"Float",
		max_questions:"Float",
		max_updates:"Float",
		max_user_connections:"Float",
		password_lifetime:"Float",
		password_reuse_history:"Float",
		password_reuse_time:"Float"
	},
	query_root:{
		awsdms_validation_failures_v1:"awsdms_validation_failures_v1",
		awsdms_validation_failures_v1_aggregate:"awsdms_validation_failures_v1_aggregate",
		coinsystem_balances:"coinsystem_balances",
		coinsystem_balances_aggregate:"coinsystem_balances_aggregate",
		coinsystem_balances_by_pk:"coinsystem_balances",
		coinsystem_bonus_coins:"coinsystem_bonus_coins",
		coinsystem_bonus_coins_aggregate:"coinsystem_bonus_coins_aggregate",
		coinsystem_bonus_coins_by_pk:"coinsystem_bonus_coins",
		coinsystem_coins:"coinsystem_coins",
		coinsystem_coins_aggregate:"coinsystem_coins_aggregate",
		coinsystem_coins_by_pk:"coinsystem_coins",
		coinsystem_exchange_rates:"coinsystem_exchange_rates",
		coinsystem_exchange_rates_aggregate:"coinsystem_exchange_rates_aggregate",
		coinsystem_exchange_rates_by_pk:"coinsystem_exchange_rates",
		coinsystem_logs:"coinsystem_logs",
		coinsystem_logs_aggregate:"coinsystem_logs_aggregate",
		coinsystem_logs_by_pk:"coinsystem_logs",
		coinsystem_transactions:"coinsystem_transactions",
		coinsystem_transactions_aggregate:"coinsystem_transactions_aggregate",
		coinsystem_transactions_by_pk:"coinsystem_transactions",
		coinsystem_wallets:"coinsystem_wallets",
		coinsystem_wallets_aggregate:"coinsystem_wallets_aggregate",
		coinsystem_wallets_by_pk:"coinsystem_wallets",
		mysql_user:"mysql_user",
		mysql_user_aggregate:"mysql_user_aggregate",
		mysql_user_by_pk:"mysql_user",
		store_payments:"store_payments",
		store_payments_aggregate:"store_payments_aggregate",
		store_payments_by_pk:"store_payments",
		store_products:"store_products",
		store_products_aggregate:"store_products_aggregate",
		store_products_by_pk:"store_products",
		store_purchases:"store_purchases",
		store_purchases_aggregate:"store_purchases_aggregate",
		store_purchases_by_pk:"store_purchases",
		store_users:"store_users",
		store_users_aggregate:"store_users_aggregate",
		test:"AddResult",
		voyce_admin_roles:"voyce_admin_roles",
		voyce_admin_roles_aggregate:"voyce_admin_roles_aggregate",
		voyce_admin_roles_by_pk:"voyce_admin_roles",
		voyce_app_banners:"voyce_app_banners",
		voyce_app_banners_aggregate:"voyce_app_banners_aggregate",
		voyce_app_banners_by_pk:"voyce_app_banners",
		voyce_app_components:"voyce_app_components",
		voyce_app_components_aggregate:"voyce_app_components_aggregate",
		voyce_app_components_by_pk:"voyce_app_components",
		voyce_audit_trail:"voyce_audit_trail",
		voyce_audit_trail_aggregate:"voyce_audit_trail_aggregate",
		voyce_audit_trail_by_pk:"voyce_audit_trail",
		voyce_author_profiles:"voyce_author_profiles",
		voyce_author_profiles_aggregate:"voyce_author_profiles_aggregate",
		voyce_author_profiles_by_pk:"voyce_author_profiles",
		voyce_balances:"voyce_balances",
		voyce_balances_aggregate:"voyce_balances_aggregate",
		voyce_balances_by_pk:"voyce_balances",
		voyce_banners:"voyce_banners",
		voyce_banners_aggregate:"voyce_banners_aggregate",
		voyce_banners_by_pk:"voyce_banners",
		voyce_chapter_assets:"voyce_chapter_assets",
		voyce_chapter_assets_aggregate:"voyce_chapter_assets_aggregate",
		voyce_chapter_assets_by_pk:"voyce_chapter_assets",
		voyce_chapter_bookmarks:"voyce_chapter_bookmarks",
		voyce_chapter_bookmarks_aggregate:"voyce_chapter_bookmarks_aggregate",
		voyce_chapter_bookmarks_by_pk:"voyce_chapter_bookmarks",
		voyce_chapter_comments:"voyce_chapter_comments",
		voyce_chapter_comments_07252024:"voyce_chapter_comments_07252024",
		voyce_chapter_comments_07252024_aggregate:"voyce_chapter_comments_07252024_aggregate",
		voyce_chapter_comments_aggregate:"voyce_chapter_comments_aggregate",
		voyce_chapter_comments_by_pk:"voyce_chapter_comments",
		voyce_chapter_comments_reaction:"voyce_chapter_comments_reaction",
		voyce_chapter_comments_reaction_aggregate:"voyce_chapter_comments_reaction_aggregate",
		voyce_chapter_comments_reaction_by_pk:"voyce_chapter_comments_reaction",
		voyce_chapter_comments_reaction_count:"voyce_chapter_comments_reaction_count",
		voyce_chapter_comments_reaction_count_aggregate:"voyce_chapter_comments_reaction_count_aggregate",
		voyce_chapter_comments_reaction_count_by_pk:"voyce_chapter_comments_reaction_count",
		voyce_chapter_comments_reports:"voyce_chapter_comments_reports",
		voyce_chapter_comments_reports_aggregate:"voyce_chapter_comments_reports_aggregate",
		voyce_chapter_comments_reports_by_pk:"voyce_chapter_comments_reports",
		voyce_chapter_contents:"voyce_chapter_contents",
		voyce_chapter_contents_aggregate:"voyce_chapter_contents_aggregate",
		voyce_chapter_contents_by_pk:"voyce_chapter_contents",
		voyce_chapter_contents_old:"voyce_chapter_contents_old",
		voyce_chapter_contents_old_aggregate:"voyce_chapter_contents_old_aggregate",
		voyce_chapter_contents_old_by_pk:"voyce_chapter_contents_old",
		voyce_chapter_effects:"voyce_chapter_effects",
		voyce_chapter_effects_aggregate:"voyce_chapter_effects_aggregate",
		voyce_chapter_effects_by_pk:"voyce_chapter_effects",
		voyce_chapter_images:"voyce_chapter_images",
		voyce_chapter_images_07252024:"voyce_chapter_images_07252024",
		voyce_chapter_images_07252024_aggregate:"voyce_chapter_images_07252024_aggregate",
		voyce_chapter_images_aggregate:"voyce_chapter_images_aggregate",
		voyce_chapter_images_by_pk:"voyce_chapter_images",
		voyce_chapter_likes:"voyce_chapter_likes",
		voyce_chapter_likes_aggregate:"voyce_chapter_likes_aggregate",
		voyce_chapter_likes_by_pk:"voyce_chapter_likes",
		voyce_chapter_locks:"voyce_chapter_locks",
		voyce_chapter_locks_aggregate:"voyce_chapter_locks_aggregate",
		voyce_chapter_locks_by_pk:"voyce_chapter_locks",
		voyce_chapter_panel_views:"voyce_chapter_panel_views",
		voyce_chapter_panel_views_aggregate:"voyce_chapter_panel_views_aggregate",
		voyce_chapter_panel_views_by_pk:"voyce_chapter_panel_views",
		voyce_chapter_panels:"voyce_chapter_panels",
		voyce_chapter_panels_aggregate:"voyce_chapter_panels_aggregate",
		voyce_chapter_panels_by_pk:"voyce_chapter_panels",
		voyce_chapter_publish_schedules:"voyce_chapter_publish_schedules",
		voyce_chapter_publish_schedules_aggregate:"voyce_chapter_publish_schedules_aggregate",
		voyce_chapter_publish_schedules_by_pk:"voyce_chapter_publish_schedules",
		voyce_chapter_reactions:"voyce_chapter_reactions",
		voyce_chapter_reactions_aggregate:"voyce_chapter_reactions_aggregate",
		voyce_chapter_reactions_by_pk:"voyce_chapter_reactions",
		voyce_chapter_reactions_count:"voyce_chapter_reactions_count",
		voyce_chapter_reactions_count_aggregate:"voyce_chapter_reactions_count_aggregate",
		voyce_chapter_reactions_count_by_pk:"voyce_chapter_reactions_count",
		voyce_chapter_view_logs:"voyce_chapter_view_logs",
		voyce_chapter_view_logs_aggregate:"voyce_chapter_view_logs_aggregate",
		voyce_chapter_view_logs_by_pk:"voyce_chapter_view_logs",
		voyce_chapter_views:"voyce_chapter_views",
		voyce_chapter_views_aggregate:"voyce_chapter_views_aggregate",
		voyce_chapter_views_by_pk:"voyce_chapter_views",
		voyce_chapters:"voyce_chapters",
		voyce_chapters_07252024:"voyce_chapters_07252024",
		voyce_chapters_07252024_aggregate:"voyce_chapters_07252024_aggregate",
		voyce_chapters_aggregate:"voyce_chapters_aggregate",
		voyce_chapters_by_pk:"voyce_chapters",
		voyce_collaborator_roles:"voyce_collaborator_roles",
		voyce_collaborator_roles_aggregate:"voyce_collaborator_roles_aggregate",
		voyce_collaborator_roles_by_pk:"voyce_collaborator_roles",
		voyce_comic_types:"voyce_comic_types",
		voyce_comic_types_aggregate:"voyce_comic_types_aggregate",
		voyce_comic_types_by_pk:"voyce_comic_types",
		voyce_comments_top_mat:"voyce_comments_top_mat",
		voyce_comments_top_mat_aggregate:"voyce_comments_top_mat_aggregate",
		voyce_community_blurbs:"voyce_community_blurbs",
		voyce_community_blurbs_aggregate:"voyce_community_blurbs_aggregate",
		voyce_community_blurbs_by_pk:"voyce_community_blurbs",
		voyce_contact_us:"voyce_contact_us",
		voyce_contact_us_aggregate:"voyce_contact_us_aggregate",
		voyce_contact_us_by_pk:"voyce_contact_us",
		voyce_content_report:"voyce_content_report",
		voyce_content_report_aggregate:"voyce_content_report_aggregate",
		voyce_content_report_by_pk:"voyce_content_report",
		voyce_contest_data:"voyce_contest_data",
		voyce_contest_data_aggregate:"voyce_contest_data_aggregate",
		voyce_contest_data_by_pk:"voyce_contest_data",
		voyce_countries:"voyce_countries",
		voyce_countries_aggregate:"voyce_countries_aggregate",
		voyce_countries_by_pk:"voyce_countries",
		voyce_csat_ratings:"voyce_csat_ratings",
		voyce_csat_ratings_aggregate:"voyce_csat_ratings_aggregate",
		voyce_csat_ratings_by_pk:"voyce_csat_ratings",
		voyce_csat_ratings_data:"voyce_csat_ratings_data",
		voyce_csat_ratings_data_aggregate:"voyce_csat_ratings_data_aggregate",
		voyce_csat_ratings_data_by_pk:"voyce_csat_ratings_data",
		voyce_education_types:"voyce_education_types",
		voyce_education_types_aggregate:"voyce_education_types_aggregate",
		voyce_education_types_by_pk:"voyce_education_types",
		voyce_erp_login_roles:"voyce_erp_login_roles",
		voyce_erp_login_roles_aggregate:"voyce_erp_login_roles_aggregate",
		voyce_erp_login_roles_by_pk:"voyce_erp_login_roles",
		voyce_etl_series_views_logs:"voyce_etl_series_views_logs",
		voyce_etl_series_views_logs_aggregate:"voyce_etl_series_views_logs_aggregate",
		voyce_etl_series_views_logs_by_pk:"voyce_etl_series_views_logs",
		voyce_event_schedules:"voyce_event_schedules",
		voyce_event_schedules_aggregate:"voyce_event_schedules_aggregate",
		voyce_event_schedules_by_pk:"voyce_event_schedules",
		voyce_genres:"voyce_genres",
		voyce_genres_aggregate:"voyce_genres_aggregate",
		voyce_genres_by_pk:"voyce_genres",
		voyce_guest_gtags:"voyce_guest_gtags",
		voyce_guest_gtags_aggregate:"voyce_guest_gtags_aggregate",
		voyce_guest_gtags_by_pk:"voyce_guest_gtags",
		voyce_metabases:"voyce_metabases",
		voyce_metabases_access:"voyce_metabases_access",
		voyce_metabases_access_aggregate:"voyce_metabases_access_aggregate",
		voyce_metabases_access_by_pk:"voyce_metabases_access",
		voyce_metabases_aggregate:"voyce_metabases_aggregate",
		voyce_metabases_by_pk:"voyce_metabases",
		voyce_newsletter:"voyce_newsletter",
		voyce_newsletter_aggregate:"voyce_newsletter_aggregate",
		voyce_newsletter_by_pk:"voyce_newsletter",
		voyce_notification_series_settings:"voyce_notification_series_settings",
		voyce_notification_series_settings_aggregate:"voyce_notification_series_settings_aggregate",
		voyce_notification_series_settings_by_pk:"voyce_notification_series_settings",
		voyce_notification_settings:"voyce_notification_settings",
		voyce_notification_settings_aggregate:"voyce_notification_settings_aggregate",
		voyce_notification_settings_by_pk:"voyce_notification_settings",
		voyce_notifications:"voyce_notifications",
		voyce_notifications_aggregate:"voyce_notifications_aggregate",
		voyce_notifications_by_pk:"voyce_notifications",
		voyce_originals_feed:"voyce_originals_feed",
		voyce_originals_feed_aggregate:"voyce_originals_feed_aggregate",
		voyce_page_banners:"voyce_page_banners",
		voyce_page_banners_aggregate:"voyce_page_banners_aggregate",
		voyce_page_banners_by_pk:"voyce_page_banners",
		voyce_page_components:"voyce_page_components",
		voyce_page_components_aggregate:"voyce_page_components_aggregate",
		voyce_page_components_by_pk:"voyce_page_components",
		voyce_pages:"voyce_pages",
		voyce_pages_aggregate:"voyce_pages_aggregate",
		voyce_pages_by_pk:"voyce_pages",
		voyce_panels_comments:"voyce_panels_comments",
		voyce_panels_comments_aggregate:"voyce_panels_comments_aggregate",
		voyce_panels_comments_by_pk:"voyce_panels_comments",
		voyce_panels_reactions:"voyce_panels_reactions",
		voyce_panels_reactions_aggregate:"voyce_panels_reactions_aggregate",
		voyce_panels_reactions_by_pk:"voyce_panels_reactions",
		voyce_panels_reactions_count:"voyce_panels_reactions_count",
		voyce_panels_reactions_count_aggregate:"voyce_panels_reactions_count_aggregate",
		voyce_panels_reactions_count_by_pk:"voyce_panels_reactions_count",
		voyce_payments_to_creators:"voyce_payments_to_creators",
		voyce_payments_to_creators_aggregate:"voyce_payments_to_creators_aggregate",
		voyce_payments_to_creators_by_pk:"voyce_payments_to_creators",
		voyce_pg_rs_series_views_count:"voyce_pg_rs_series_views_count",
		voyce_pg_rs_series_views_count_aggregate:"voyce_pg_rs_series_views_count_aggregate",
		voyce_pg_rs_series_views_count_by_pk:"voyce_pg_rs_series_views_count",
		voyce_polls:"voyce_polls",
		voyce_polls_aggregate:"voyce_polls_aggregate",
		voyce_polls_answers:"voyce_polls_answers",
		voyce_polls_answers_aggregate:"voyce_polls_answers_aggregate",
		voyce_polls_answers_by_pk:"voyce_polls_answers",
		voyce_polls_by_pk:"voyce_polls",
		voyce_polls_comments:"voyce_polls_comments",
		voyce_polls_comments_aggregate:"voyce_polls_comments_aggregate",
		voyce_polls_comments_by_pk:"voyce_polls_comments",
		voyce_polls_reactions:"voyce_polls_reactions",
		voyce_polls_reactions_aggregate:"voyce_polls_reactions_aggregate",
		voyce_polls_reactions_by_pk:"voyce_polls_reactions",
		voyce_polls_votes:"voyce_polls_votes",
		voyce_polls_votes_aggregate:"voyce_polls_votes_aggregate",
		voyce_polls_votes_by_pk:"voyce_polls_votes",
		voyce_popup_components:"voyce_popup_components",
		voyce_popup_components_aggregate:"voyce_popup_components_aggregate",
		voyce_popup_components_by_pk:"voyce_popup_components",
		voyce_popup_emails:"voyce_popup_emails",
		voyce_popup_emails_aggregate:"voyce_popup_emails_aggregate",
		voyce_popup_emails_by_pk:"voyce_popup_emails",
		voyce_posts:"voyce_posts",
		voyce_posts_aggregate:"voyce_posts_aggregate",
		voyce_posts_by_pk:"voyce_posts",
		voyce_posts_comments:"voyce_posts_comments",
		voyce_posts_comments_aggregate:"voyce_posts_comments_aggregate",
		voyce_posts_comments_by_pk:"voyce_posts_comments",
		voyce_posts_reactions:"voyce_posts_reactions",
		voyce_posts_reactions_aggregate:"voyce_posts_reactions_aggregate",
		voyce_posts_reactions_by_pk:"voyce_posts_reactions",
		voyce_quest_difficulties:"voyce_quest_difficulties",
		voyce_quest_difficulties_aggregate:"voyce_quest_difficulties_aggregate",
		voyce_quest_difficulties_by_pk:"voyce_quest_difficulties",
		voyce_quests:"voyce_quests",
		voyce_quests_aggregate:"voyce_quests_aggregate",
		voyce_quests_by_pk:"voyce_quests",
		voyce_quests_old:"voyce_quests_old",
		voyce_quests_old_aggregate:"voyce_quests_old_aggregate",
		voyce_quests_old_by_pk:"voyce_quests_old",
		voyce_recommended_series:"voyce_recommended_series",
		voyce_recommended_series_aggregate:"voyce_recommended_series_aggregate",
		voyce_recommended_series_by_pk:"voyce_recommended_series",
		voyce_release_schedules:"voyce_release_schedules",
		voyce_release_schedules_aggregate:"voyce_release_schedules_aggregate",
		voyce_release_schedules_by_pk:"voyce_release_schedules",
		voyce_rewards:"voyce_rewards",
		voyce_rewards_aggregate:"voyce_rewards_aggregate",
		voyce_rewards_by_pk:"voyce_rewards",
		voyce_rewards_leaderboard:"voyce_rewards_leaderboard",
		voyce_rewards_leaderboard_aggregate:"voyce_rewards_leaderboard_aggregate",
		voyce_rewards_leaderboard_by_pk:"voyce_rewards_leaderboard",
		voyce_rewards_quest:"voyce_rewards_quest",
		voyce_rewards_quest_aggregate:"voyce_rewards_quest_aggregate",
		voyce_rewards_quest_by_pk:"voyce_rewards_quest",
		voyce_sections:"voyce_sections",
		voyce_sections_aggregate:"voyce_sections_aggregate",
		voyce_sections_by_pk:"voyce_sections",
		voyce_series:"voyce_series",
		voyce_series_aggregate:"voyce_series_aggregate",
		voyce_series_by_pk:"voyce_series",
		voyce_series_collaborators:"voyce_series_collaborators",
		voyce_series_collaborators_aggregate:"voyce_series_collaborators_aggregate",
		voyce_series_collaborators_by_pk:"voyce_series_collaborators",
		voyce_series_donations:"voyce_series_donations",
		voyce_series_donations_aggregate:"voyce_series_donations_aggregate",
		voyce_series_donations_by_pk:"voyce_series_donations",
		voyce_series_favorites:"voyce_series_favorites",
		voyce_series_favorites_aggregate:"voyce_series_favorites_aggregate",
		voyce_series_favorites_by_pk:"voyce_series_favorites",
		voyce_series_featured_panels:"voyce_series_featured_panels",
		voyce_series_featured_panels_aggregate:"voyce_series_featured_panels_aggregate",
		voyce_series_featured_panels_by_pk:"voyce_series_featured_panels",
		voyce_series_genre:"voyce_series_genre",
		voyce_series_genre_aggregate:"voyce_series_genre_aggregate",
		voyce_series_genre_by_pk:"voyce_series_genre",
		voyce_series_giveaway_participants:"voyce_series_giveaway_participants",
		voyce_series_giveaway_participants_aggregate:"voyce_series_giveaway_participants_aggregate",
		voyce_series_giveaway_participants_by_pk:"voyce_series_giveaway_participants",
		voyce_series_hot_panels:"voyce_series_hot_panels",
		voyce_series_hot_panels_aggregate:"voyce_series_hot_panels_aggregate",
		voyce_series_hot_panels_by_pk:"voyce_series_hot_panels",
		voyce_series_layouts:"voyce_series_layouts",
		voyce_series_layouts_aggregate:"voyce_series_layouts_aggregate",
		voyce_series_layouts_by_pk:"voyce_series_layouts",
		voyce_series_leaderboard:"voyce_series_leaderboard",
		voyce_series_leaderboard_aggregate:"voyce_series_leaderboard_aggregate",
		voyce_series_leaderboard_by_pk:"voyce_series_leaderboard",
		voyce_series_likes_count:"voyce_series_likes_count",
		voyce_series_likes_count_aggregate:"voyce_series_likes_count_aggregate",
		voyce_series_likes_count_mat:"voyce_series_likes_count_mat",
		voyce_series_likes_count_mat_aggregate:"voyce_series_likes_count_mat_aggregate",
		voyce_series_lock_config:"voyce_series_lock_config",
		voyce_series_lock_config_aggregate:"voyce_series_lock_config_aggregate",
		voyce_series_lock_config_by_pk:"voyce_series_lock_config",
		voyce_series_sponsorships_configuration:"voyce_series_sponsorships_configuration",
		voyce_series_sponsorships_configuration_aggregate:"voyce_series_sponsorships_configuration_aggregate",
		voyce_series_sponsorships_configuration_by_pk:"voyce_series_sponsorships_configuration",
		voyce_series_subscription:"voyce_series_subscription",
		voyce_series_subscription_aggregate:"voyce_series_subscription_aggregate",
		voyce_series_subscription_by_pk:"voyce_series_subscription",
		voyce_series_tags:"voyce_series_tags",
		voyce_series_tags_aggregate:"voyce_series_tags_aggregate",
		voyce_series_tags_by_pk:"voyce_series_tags",
		voyce_series_tags_junction:"voyce_series_tags_junction",
		voyce_series_tags_junction_aggregate:"voyce_series_tags_junction_aggregate",
		voyce_series_tags_junction_by_pk:"voyce_series_tags_junction",
		voyce_series_top_comments:"voyce_series_top_comments",
		voyce_series_top_comments_aggregate:"voyce_series_top_comments_aggregate",
		voyce_series_top_comments_by_pk:"voyce_series_top_comments",
		voyce_series_top_likes:"voyce_series_top_likes",
		voyce_series_top_likes_aggregate:"voyce_series_top_likes_aggregate",
		voyce_series_top_likes_by_pk:"voyce_series_top_likes",
		voyce_series_top_views:"voyce_series_top_views",
		voyce_series_top_views_aggregate:"voyce_series_top_views_aggregate",
		voyce_series_top_views_by_pk:"voyce_series_top_views",
		voyce_series_views_adjustment:"voyce_series_views_adjustment",
		voyce_series_views_adjustment_aggregate:"voyce_series_views_adjustment_aggregate",
		voyce_series_views_adjustment_by_pk:"voyce_series_views_adjustment",
		voyce_series_views_count:"voyce_series_views_count",
		voyce_series_views_count_aggregate:"voyce_series_views_count_aggregate",
		voyce_series_views_count_mat:"voyce_series_views_count_mat",
		voyce_series_views_count_mat_aggregate:"voyce_series_views_count_mat_aggregate",
		voyce_series_views_count_mat_sep30:"voyce_series_views_count_mat_sep30",
		voyce_series_views_count_mat_sep30_aggregate:"voyce_series_views_count_mat_sep30_aggregate",
		voyce_series_views_count_mat_v2:"voyce_series_views_count_mat_v2",
		voyce_series_views_count_mat_v2_aggregate:"voyce_series_views_count_mat_v2_aggregate",
		voyce_series_votes:"voyce_series_votes",
		voyce_series_votes_aggregate:"voyce_series_votes_aggregate",
		voyce_series_votes_by_pk:"voyce_series_votes",
		voyce_series_votes_count:"voyce_series_votes_count",
		voyce_series_votes_count_aggregate:"voyce_series_votes_count_aggregate",
		voyce_series_votes_count_by_pk:"voyce_series_votes_count",
		voyce_series_voting_schedules:"voyce_series_voting_schedules",
		voyce_series_voting_schedules_aggregate:"voyce_series_voting_schedules_aggregate",
		voyce_series_voting_schedules_by_pk:"voyce_series_voting_schedules",
		voyce_sponsorships:"voyce_sponsorships",
		voyce_sponsorships_aggregate:"voyce_sponsorships_aggregate",
		voyce_sponsorships_by_pk:"voyce_sponsorships",
		voyce_states:"voyce_states",
		voyce_states_aggregate:"voyce_states_aggregate",
		voyce_states_by_pk:"voyce_states",
		voyce_stripe_payouts_setup:"voyce_stripe_payouts_setup",
		voyce_stripe_payouts_setup_aggregate:"voyce_stripe_payouts_setup_aggregate",
		voyce_stripe_payouts_setup_by_pk:"voyce_stripe_payouts_setup",
		voyce_studio:"voyce_studio",
		voyce_studio_aggregate:"voyce_studio_aggregate",
		voyce_studio_by_pk:"voyce_studio",
		voyce_studio_followers:"voyce_studio_followers",
		voyce_studio_followers_aggregate:"voyce_studio_followers_aggregate",
		voyce_studio_followers_by_pk:"voyce_studio_followers",
		voyce_studio_series:"voyce_studio_series",
		voyce_studio_series_aggregate:"voyce_studio_series_aggregate",
		voyce_studio_series_by_pk:"voyce_studio_series",
		voyce_studio_views:"voyce_studio_views",
		voyce_studio_views_aggregate:"voyce_studio_views_aggregate",
		voyce_studio_views_by_pk:"voyce_studio_views",
		voyce_system_settings:"voyce_system_settings",
		voyce_system_settings_aggregate:"voyce_system_settings_aggregate",
		voyce_system_settings_by_pk:"voyce_system_settings",
		voyce_top_genres:"voyce_top_genres",
		voyce_top_genres_aggregate:"voyce_top_genres_aggregate",
		voyce_transaction_logs:"voyce_transaction_logs",
		voyce_transaction_logs_aggregate:"voyce_transaction_logs_aggregate",
		voyce_transaction_logs_by_pk:"voyce_transaction_logs",
		voyce_url_redirects:"voyce_url_redirects",
		voyce_url_redirects_aggregate:"voyce_url_redirects_aggregate",
		voyce_url_redirects_by_pk:"voyce_url_redirects",
		voyce_user_addresses:"voyce_user_addresses",
		voyce_user_addresses_aggregate:"voyce_user_addresses_aggregate",
		voyce_user_addresses_by_pk:"voyce_user_addresses",
		voyce_user_blocks:"voyce_user_blocks",
		voyce_user_blocks_aggregate:"voyce_user_blocks_aggregate",
		voyce_user_blocks_by_pk:"voyce_user_blocks",
		voyce_user_chapters_unlocked:"voyce_user_chapters_unlocked",
		voyce_user_chapters_unlocked_aggregate:"voyce_user_chapters_unlocked_aggregate",
		voyce_user_chapters_unlocked_by_pk:"voyce_user_chapters_unlocked",
		voyce_user_contacts:"voyce_user_contacts",
		voyce_user_contacts_aggregate:"voyce_user_contacts_aggregate",
		voyce_user_contacts_by_pk:"voyce_user_contacts",
		voyce_user_csat_ratings:"voyce_user_csat_ratings",
		voyce_user_csat_ratings_aggregate:"voyce_user_csat_ratings_aggregate",
		voyce_user_csat_ratings_by_pk:"voyce_user_csat_ratings",
		voyce_user_education:"voyce_user_education",
		voyce_user_education_aggregate:"voyce_user_education_aggregate",
		voyce_user_education_by_pk:"voyce_user_education",
		voyce_user_level:"voyce_user_level",
		voyce_user_level_aggregate:"voyce_user_level_aggregate",
		voyce_user_level_by_pk:"voyce_user_level",
		voyce_user_linked_accounts:"voyce_user_linked_accounts",
		voyce_user_linked_accounts_aggregate:"voyce_user_linked_accounts_aggregate",
		voyce_user_linked_accounts_by_pk:"voyce_user_linked_accounts",
		voyce_user_login_history:"voyce_user_login_history",
		voyce_user_login_history_aggregate:"voyce_user_login_history_aggregate",
		voyce_user_login_history_by_pk:"voyce_user_login_history",
		voyce_user_quest_ids:"voyce_user_quest_ids",
		voyce_user_quest_ids_aggregate:"voyce_user_quest_ids_aggregate",
		voyce_user_quest_ids_by_pk:"voyce_user_quest_ids",
		voyce_user_quest_rewards:"voyce_user_quest_rewards",
		voyce_user_quest_rewards_aggregate:"voyce_user_quest_rewards_aggregate",
		voyce_user_quest_rewards_by_pk:"voyce_user_quest_rewards",
		voyce_user_quests:"voyce_user_quests",
		voyce_user_quests_aggregate:"voyce_user_quests_aggregate",
		voyce_user_quests_by_pk:"voyce_user_quests",
		voyce_user_quests_old:"voyce_user_quests_old",
		voyce_user_quests_old_aggregate:"voyce_user_quests_old_aggregate",
		voyce_user_quests_old_by_pk:"voyce_user_quests_old",
		voyce_user_ranking:"voyce_user_ranking",
		voyce_user_ranking_aggregate:"voyce_user_ranking_aggregate",
		voyce_user_ranking_by_pk:"voyce_user_ranking",
		voyce_user_reviews:"voyce_user_reviews",
		voyce_user_reviews_aggregate:"voyce_user_reviews_aggregate",
		voyce_user_reviews_by_pk:"voyce_user_reviews",
		voyce_user_rewards:"voyce_user_rewards",
		voyce_user_rewards_aggregate:"voyce_user_rewards_aggregate",
		voyce_user_rewards_by_pk:"voyce_user_rewards",
		voyce_user_roles:"voyce_user_roles",
		voyce_user_roles_aggregate:"voyce_user_roles_aggregate",
		voyce_user_roles_by_pk:"voyce_user_roles",
		voyce_user_series_subscription_count:"voyce_user_series_subscription_count",
		voyce_user_series_subscription_count_aggregate:"voyce_user_series_subscription_count_aggregate",
		voyce_user_settings:"voyce_user_settings",
		voyce_user_settings_aggregate:"voyce_user_settings_aggregate",
		voyce_user_settings_by_pk:"voyce_user_settings",
		voyce_user_skills:"voyce_user_skills",
		voyce_user_skills_aggregate:"voyce_user_skills_aggregate",
		voyce_user_skills_by_pk:"voyce_user_skills",
		voyce_user_socials:"voyce_user_socials",
		voyce_user_socials_aggregate:"voyce_user_socials_aggregate",
		voyce_user_socials_by_pk:"voyce_user_socials",
		voyce_user_soft_deleted:"voyce_user_soft_deleted",
		voyce_user_soft_deleted_aggregate:"voyce_user_soft_deleted_aggregate",
		voyce_user_soft_deleted_by_pk:"voyce_user_soft_deleted",
		voyce_user_vtags:"voyce_user_vtags",
		voyce_user_vtags_aggregate:"voyce_user_vtags_aggregate",
		voyce_user_vtags_by_pk:"voyce_user_vtags",
		voyce_user_wins:"voyce_user_wins",
		voyce_user_wins_aggregate:"voyce_user_wins_aggregate",
		voyce_user_wins_by_pk:"voyce_user_wins",
		voyce_users:"voyce_users",
		voyce_users_aggregate:"voyce_users_aggregate",
		voyce_users_by_pk:"voyce_users",
		voyce_users_followers:"voyce_users_followers",
		voyce_users_followers_aggregate:"voyce_users_followers_aggregate",
		voyce_users_followers_by_pk:"voyce_users_followers",
		voyce_users_private:"voyce_users_private",
		voyce_users_private_aggregate:"voyce_users_private_aggregate",
		voyce_users_series_junction:"voyce_users_series_junction",
		voyce_users_series_junction_aggregate:"voyce_users_series_junction_aggregate",
		voyce_users_series_junction_by_pk:"voyce_users_series_junction",
		voyce_versions:"voyce_versions",
		voyce_versions_aggregate:"voyce_versions_aggregate",
		voyce_versions_by_pk:"voyce_versions",
		voyce_voting_analytics:"voyce_voting_analytics",
		voyce_voting_analytics_aggregate:"voyce_voting_analytics_aggregate",
		voyce_voting_analytics_by_pk:"voyce_voting_analytics",
		voyce_voting_panels:"voyce_voting_panels",
		voyce_voting_panels_aggregate:"voyce_voting_panels_aggregate",
		voyce_voting_panels_by_pk:"voyce_voting_panels",
		voyce_voting_series:"voyce_voting_series",
		voyce_voting_series_aggregate:"voyce_voting_series_aggregate",
		voyce_voting_series_by_pk:"voyce_voting_series",
		voyce_voting_user_votes:"voyce_voting_user_votes",
		voyce_voting_user_votes_aggregate:"voyce_voting_user_votes_aggregate",
		voyce_voting_user_votes_by_pk:"voyce_voting_user_votes"
	},
	store_payments:{
		amount:"numeric",
		created_at:"timestamptz",
		currency:"String",
		gateway:"String",
		id:"uuid",
		purchase_id:"String",
		remarks:"String",
		status:"String"
	},
	store_payments_aggregate:{
		aggregate:"store_payments_aggregate_fields",
		nodes:"store_payments"
	},
	store_payments_aggregate_fields:{
		avg:"store_payments_avg_fields",
		count:"Int",
		max:"store_payments_max_fields",
		min:"store_payments_min_fields",
		stddev:"store_payments_stddev_fields",
		stddev_pop:"store_payments_stddev_pop_fields",
		stddev_samp:"store_payments_stddev_samp_fields",
		sum:"store_payments_sum_fields",
		var_pop:"store_payments_var_pop_fields",
		var_samp:"store_payments_var_samp_fields",
		variance:"store_payments_variance_fields"
	},
	store_payments_avg_fields:{
		amount:"Float"
	},
	store_payments_max_fields:{
		amount:"numeric",
		created_at:"timestamptz",
		currency:"String",
		gateway:"String",
		id:"uuid",
		purchase_id:"String",
		remarks:"String",
		status:"String"
	},
	store_payments_min_fields:{
		amount:"numeric",
		created_at:"timestamptz",
		currency:"String",
		gateway:"String",
		id:"uuid",
		purchase_id:"String",
		remarks:"String",
		status:"String"
	},
	store_payments_mutation_response:{
		affected_rows:"Int",
		returning:"store_payments"
	},
	store_payments_stddev_fields:{
		amount:"Float"
	},
	store_payments_stddev_pop_fields:{
		amount:"Float"
	},
	store_payments_stddev_samp_fields:{
		amount:"Float"
	},
	store_payments_sum_fields:{
		amount:"numeric"
	},
	store_payments_var_pop_fields:{
		amount:"Float"
	},
	store_payments_var_samp_fields:{
		amount:"Float"
	},
	store_payments_variance_fields:{
		amount:"Float"
	},
	store_products:{
		category:"String",
		chapter_type:"String",
		currency:"String",
		description:"String",
		enabled:"Boolean",
		id:"uuid",
		image:"String",
		is_store_visible:"Boolean",
		label:"String",
		name:"String",
		price:"numeric",
		purchase_limit:"numeric",
		quantity:"Int",
		series:"voyce_series",
		series_id:"numeric",
		sort_num:"numeric",
		tag:"String",
		type:"String"
	},
	store_products_aggregate:{
		aggregate:"store_products_aggregate_fields",
		nodes:"store_products"
	},
	store_products_aggregate_fields:{
		avg:"store_products_avg_fields",
		count:"Int",
		max:"store_products_max_fields",
		min:"store_products_min_fields",
		stddev:"store_products_stddev_fields",
		stddev_pop:"store_products_stddev_pop_fields",
		stddev_samp:"store_products_stddev_samp_fields",
		sum:"store_products_sum_fields",
		var_pop:"store_products_var_pop_fields",
		var_samp:"store_products_var_samp_fields",
		variance:"store_products_variance_fields"
	},
	store_products_avg_fields:{
		price:"Float",
		purchase_limit:"Float",
		quantity:"Float",
		series_id:"Float",
		sort_num:"Float"
	},
	store_products_max_fields:{
		category:"String",
		chapter_type:"String",
		currency:"String",
		description:"String",
		id:"uuid",
		image:"String",
		label:"String",
		name:"String",
		price:"numeric",
		purchase_limit:"numeric",
		quantity:"Int",
		series_id:"numeric",
		sort_num:"numeric",
		tag:"String",
		type:"String"
	},
	store_products_min_fields:{
		category:"String",
		chapter_type:"String",
		currency:"String",
		description:"String",
		id:"uuid",
		image:"String",
		label:"String",
		name:"String",
		price:"numeric",
		purchase_limit:"numeric",
		quantity:"Int",
		series_id:"numeric",
		sort_num:"numeric",
		tag:"String",
		type:"String"
	},
	store_products_mutation_response:{
		affected_rows:"Int",
		returning:"store_products"
	},
	store_products_stddev_fields:{
		price:"Float",
		purchase_limit:"Float",
		quantity:"Float",
		series_id:"Float",
		sort_num:"Float"
	},
	store_products_stddev_pop_fields:{
		price:"Float",
		purchase_limit:"Float",
		quantity:"Float",
		series_id:"Float",
		sort_num:"Float"
	},
	store_products_stddev_samp_fields:{
		price:"Float",
		purchase_limit:"Float",
		quantity:"Float",
		series_id:"Float",
		sort_num:"Float"
	},
	store_products_sum_fields:{
		price:"numeric",
		purchase_limit:"numeric",
		quantity:"Int",
		series_id:"numeric",
		sort_num:"numeric"
	},
	store_products_var_pop_fields:{
		price:"Float",
		purchase_limit:"Float",
		quantity:"Float",
		series_id:"Float",
		sort_num:"Float"
	},
	store_products_var_samp_fields:{
		price:"Float",
		purchase_limit:"Float",
		quantity:"Float",
		series_id:"Float",
		sort_num:"Float"
	},
	store_products_variance_fields:{
		price:"Float",
		purchase_limit:"Float",
		quantity:"Float",
		series_id:"Float",
		sort_num:"Float"
	},
	store_purchases:{
		created_at:"timestamptz",
		id:"uuid",
		product_category:"String",
		product_currency:"String",
		product_description:"String",
		product_image_url:"String",
		product_label:"String",
		product_meta:"json",
		product_name:"String",
		product_price:"numeric",
		product_type:"String",
		quantity:"numeric",
		reference_id:"String",
		remarks:"String",
		series_id:"Int",
		status:"String",
		total_price:"numeric",
		updated_at:"timestamptz",
		user_id:"numeric"
	},
	store_purchases_aggregate:{
		aggregate:"store_purchases_aggregate_fields",
		nodes:"store_purchases"
	},
	store_purchases_aggregate_fields:{
		avg:"store_purchases_avg_fields",
		count:"Int",
		max:"store_purchases_max_fields",
		min:"store_purchases_min_fields",
		stddev:"store_purchases_stddev_fields",
		stddev_pop:"store_purchases_stddev_pop_fields",
		stddev_samp:"store_purchases_stddev_samp_fields",
		sum:"store_purchases_sum_fields",
		var_pop:"store_purchases_var_pop_fields",
		var_samp:"store_purchases_var_samp_fields",
		variance:"store_purchases_variance_fields"
	},
	store_purchases_avg_fields:{
		product_price:"Float",
		quantity:"Float",
		series_id:"Float",
		total_price:"Float",
		user_id:"Float"
	},
	store_purchases_max_fields:{
		created_at:"timestamptz",
		id:"uuid",
		product_category:"String",
		product_currency:"String",
		product_description:"String",
		product_image_url:"String",
		product_label:"String",
		product_name:"String",
		product_price:"numeric",
		product_type:"String",
		quantity:"numeric",
		reference_id:"String",
		remarks:"String",
		series_id:"Int",
		status:"String",
		total_price:"numeric",
		updated_at:"timestamptz",
		user_id:"numeric"
	},
	store_purchases_min_fields:{
		created_at:"timestamptz",
		id:"uuid",
		product_category:"String",
		product_currency:"String",
		product_description:"String",
		product_image_url:"String",
		product_label:"String",
		product_name:"String",
		product_price:"numeric",
		product_type:"String",
		quantity:"numeric",
		reference_id:"String",
		remarks:"String",
		series_id:"Int",
		status:"String",
		total_price:"numeric",
		updated_at:"timestamptz",
		user_id:"numeric"
	},
	store_purchases_mutation_response:{
		affected_rows:"Int",
		returning:"store_purchases"
	},
	store_purchases_stddev_fields:{
		product_price:"Float",
		quantity:"Float",
		series_id:"Float",
		total_price:"Float",
		user_id:"Float"
	},
	store_purchases_stddev_pop_fields:{
		product_price:"Float",
		quantity:"Float",
		series_id:"Float",
		total_price:"Float",
		user_id:"Float"
	},
	store_purchases_stddev_samp_fields:{
		product_price:"Float",
		quantity:"Float",
		series_id:"Float",
		total_price:"Float",
		user_id:"Float"
	},
	store_purchases_sum_fields:{
		product_price:"numeric",
		quantity:"numeric",
		series_id:"Int",
		total_price:"numeric",
		user_id:"numeric"
	},
	store_purchases_var_pop_fields:{
		product_price:"Float",
		quantity:"Float",
		series_id:"Float",
		total_price:"Float",
		user_id:"Float"
	},
	store_purchases_var_samp_fields:{
		product_price:"Float",
		quantity:"Float",
		series_id:"Float",
		total_price:"Float",
		user_id:"Float"
	},
	store_purchases_variance_fields:{
		product_price:"Float",
		quantity:"Float",
		series_id:"Float",
		total_price:"Float",
		user_id:"Float"
	},
	store_users:{
		about:"String",
		ad_share:"Int",
		avatar:"String",
		banner:"String",
		birth_date:"date",
		dark_mode:"smallint",
		date_create:"timestamp",
		email:"String",
		first_name:"String",
		gender:"String",
		id:"Int",
		is_account_active:"smallint",
		last_login:"timestamp",
		last_name:"String",
		nickname:"String",
		password:"String",
		remember_code:"String",
		role_id:"Int",
		username:"String",
		vmuser:"Boolean"
	},
	store_users_aggregate:{
		aggregate:"store_users_aggregate_fields",
		nodes:"store_users"
	},
	store_users_aggregate_fields:{
		avg:"store_users_avg_fields",
		count:"Int",
		max:"store_users_max_fields",
		min:"store_users_min_fields",
		stddev:"store_users_stddev_fields",
		stddev_pop:"store_users_stddev_pop_fields",
		stddev_samp:"store_users_stddev_samp_fields",
		sum:"store_users_sum_fields",
		var_pop:"store_users_var_pop_fields",
		var_samp:"store_users_var_samp_fields",
		variance:"store_users_variance_fields"
	},
	store_users_avg_fields:{
		ad_share:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		role_id:"Float"
	},
	store_users_max_fields:{
		about:"String",
		ad_share:"Int",
		avatar:"String",
		banner:"String",
		birth_date:"date",
		dark_mode:"smallint",
		date_create:"timestamp",
		email:"String",
		first_name:"String",
		gender:"String",
		id:"Int",
		is_account_active:"smallint",
		last_login:"timestamp",
		last_name:"String",
		nickname:"String",
		password:"String",
		remember_code:"String",
		role_id:"Int",
		username:"String"
	},
	store_users_min_fields:{
		about:"String",
		ad_share:"Int",
		avatar:"String",
		banner:"String",
		birth_date:"date",
		dark_mode:"smallint",
		date_create:"timestamp",
		email:"String",
		first_name:"String",
		gender:"String",
		id:"Int",
		is_account_active:"smallint",
		last_login:"timestamp",
		last_name:"String",
		nickname:"String",
		password:"String",
		remember_code:"String",
		role_id:"Int",
		username:"String"
	},
	store_users_mutation_response:{
		affected_rows:"Int",
		returning:"store_users"
	},
	store_users_stddev_fields:{
		ad_share:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		role_id:"Float"
	},
	store_users_stddev_pop_fields:{
		ad_share:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		role_id:"Float"
	},
	store_users_stddev_samp_fields:{
		ad_share:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		role_id:"Float"
	},
	store_users_sum_fields:{
		ad_share:"Int",
		dark_mode:"smallint",
		id:"Int",
		is_account_active:"smallint",
		role_id:"Int"
	},
	store_users_var_pop_fields:{
		ad_share:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		role_id:"Float"
	},
	store_users_var_samp_fields:{
		ad_share:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		role_id:"Float"
	},
	store_users_variance_fields:{
		ad_share:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		role_id:"Float"
	},
	subscription_root:{
		awsdms_validation_failures_v1:"awsdms_validation_failures_v1",
		awsdms_validation_failures_v1_aggregate:"awsdms_validation_failures_v1_aggregate",
		awsdms_validation_failures_v1_stream:"awsdms_validation_failures_v1",
		coinsystem_balances:"coinsystem_balances",
		coinsystem_balances_aggregate:"coinsystem_balances_aggregate",
		coinsystem_balances_by_pk:"coinsystem_balances",
		coinsystem_balances_stream:"coinsystem_balances",
		coinsystem_bonus_coins:"coinsystem_bonus_coins",
		coinsystem_bonus_coins_aggregate:"coinsystem_bonus_coins_aggregate",
		coinsystem_bonus_coins_by_pk:"coinsystem_bonus_coins",
		coinsystem_bonus_coins_stream:"coinsystem_bonus_coins",
		coinsystem_coins:"coinsystem_coins",
		coinsystem_coins_aggregate:"coinsystem_coins_aggregate",
		coinsystem_coins_by_pk:"coinsystem_coins",
		coinsystem_coins_stream:"coinsystem_coins",
		coinsystem_exchange_rates:"coinsystem_exchange_rates",
		coinsystem_exchange_rates_aggregate:"coinsystem_exchange_rates_aggregate",
		coinsystem_exchange_rates_by_pk:"coinsystem_exchange_rates",
		coinsystem_exchange_rates_stream:"coinsystem_exchange_rates",
		coinsystem_logs:"coinsystem_logs",
		coinsystem_logs_aggregate:"coinsystem_logs_aggregate",
		coinsystem_logs_by_pk:"coinsystem_logs",
		coinsystem_logs_stream:"coinsystem_logs",
		coinsystem_transactions:"coinsystem_transactions",
		coinsystem_transactions_aggregate:"coinsystem_transactions_aggregate",
		coinsystem_transactions_by_pk:"coinsystem_transactions",
		coinsystem_transactions_stream:"coinsystem_transactions",
		coinsystem_wallets:"coinsystem_wallets",
		coinsystem_wallets_aggregate:"coinsystem_wallets_aggregate",
		coinsystem_wallets_by_pk:"coinsystem_wallets",
		coinsystem_wallets_stream:"coinsystem_wallets",
		mysql_user:"mysql_user",
		mysql_user_aggregate:"mysql_user_aggregate",
		mysql_user_by_pk:"mysql_user",
		mysql_user_stream:"mysql_user",
		store_payments:"store_payments",
		store_payments_aggregate:"store_payments_aggregate",
		store_payments_by_pk:"store_payments",
		store_payments_stream:"store_payments",
		store_products:"store_products",
		store_products_aggregate:"store_products_aggregate",
		store_products_by_pk:"store_products",
		store_products_stream:"store_products",
		store_purchases:"store_purchases",
		store_purchases_aggregate:"store_purchases_aggregate",
		store_purchases_by_pk:"store_purchases",
		store_purchases_stream:"store_purchases",
		store_users:"store_users",
		store_users_aggregate:"store_users_aggregate",
		store_users_stream:"store_users",
		voyce_admin_roles:"voyce_admin_roles",
		voyce_admin_roles_aggregate:"voyce_admin_roles_aggregate",
		voyce_admin_roles_by_pk:"voyce_admin_roles",
		voyce_admin_roles_stream:"voyce_admin_roles",
		voyce_app_banners:"voyce_app_banners",
		voyce_app_banners_aggregate:"voyce_app_banners_aggregate",
		voyce_app_banners_by_pk:"voyce_app_banners",
		voyce_app_banners_stream:"voyce_app_banners",
		voyce_app_components:"voyce_app_components",
		voyce_app_components_aggregate:"voyce_app_components_aggregate",
		voyce_app_components_by_pk:"voyce_app_components",
		voyce_app_components_stream:"voyce_app_components",
		voyce_audit_trail:"voyce_audit_trail",
		voyce_audit_trail_aggregate:"voyce_audit_trail_aggregate",
		voyce_audit_trail_by_pk:"voyce_audit_trail",
		voyce_audit_trail_stream:"voyce_audit_trail",
		voyce_author_profiles:"voyce_author_profiles",
		voyce_author_profiles_aggregate:"voyce_author_profiles_aggregate",
		voyce_author_profiles_by_pk:"voyce_author_profiles",
		voyce_author_profiles_stream:"voyce_author_profiles",
		voyce_balances:"voyce_balances",
		voyce_balances_aggregate:"voyce_balances_aggregate",
		voyce_balances_by_pk:"voyce_balances",
		voyce_balances_stream:"voyce_balances",
		voyce_banners:"voyce_banners",
		voyce_banners_aggregate:"voyce_banners_aggregate",
		voyce_banners_by_pk:"voyce_banners",
		voyce_banners_stream:"voyce_banners",
		voyce_chapter_assets:"voyce_chapter_assets",
		voyce_chapter_assets_aggregate:"voyce_chapter_assets_aggregate",
		voyce_chapter_assets_by_pk:"voyce_chapter_assets",
		voyce_chapter_assets_stream:"voyce_chapter_assets",
		voyce_chapter_bookmarks:"voyce_chapter_bookmarks",
		voyce_chapter_bookmarks_aggregate:"voyce_chapter_bookmarks_aggregate",
		voyce_chapter_bookmarks_by_pk:"voyce_chapter_bookmarks",
		voyce_chapter_bookmarks_stream:"voyce_chapter_bookmarks",
		voyce_chapter_comments:"voyce_chapter_comments",
		voyce_chapter_comments_07252024:"voyce_chapter_comments_07252024",
		voyce_chapter_comments_07252024_aggregate:"voyce_chapter_comments_07252024_aggregate",
		voyce_chapter_comments_07252024_stream:"voyce_chapter_comments_07252024",
		voyce_chapter_comments_aggregate:"voyce_chapter_comments_aggregate",
		voyce_chapter_comments_by_pk:"voyce_chapter_comments",
		voyce_chapter_comments_reaction:"voyce_chapter_comments_reaction",
		voyce_chapter_comments_reaction_aggregate:"voyce_chapter_comments_reaction_aggregate",
		voyce_chapter_comments_reaction_by_pk:"voyce_chapter_comments_reaction",
		voyce_chapter_comments_reaction_count:"voyce_chapter_comments_reaction_count",
		voyce_chapter_comments_reaction_count_aggregate:"voyce_chapter_comments_reaction_count_aggregate",
		voyce_chapter_comments_reaction_count_by_pk:"voyce_chapter_comments_reaction_count",
		voyce_chapter_comments_reaction_count_stream:"voyce_chapter_comments_reaction_count",
		voyce_chapter_comments_reaction_stream:"voyce_chapter_comments_reaction",
		voyce_chapter_comments_reports:"voyce_chapter_comments_reports",
		voyce_chapter_comments_reports_aggregate:"voyce_chapter_comments_reports_aggregate",
		voyce_chapter_comments_reports_by_pk:"voyce_chapter_comments_reports",
		voyce_chapter_comments_reports_stream:"voyce_chapter_comments_reports",
		voyce_chapter_comments_stream:"voyce_chapter_comments",
		voyce_chapter_contents:"voyce_chapter_contents",
		voyce_chapter_contents_aggregate:"voyce_chapter_contents_aggregate",
		voyce_chapter_contents_by_pk:"voyce_chapter_contents",
		voyce_chapter_contents_old:"voyce_chapter_contents_old",
		voyce_chapter_contents_old_aggregate:"voyce_chapter_contents_old_aggregate",
		voyce_chapter_contents_old_by_pk:"voyce_chapter_contents_old",
		voyce_chapter_contents_old_stream:"voyce_chapter_contents_old",
		voyce_chapter_contents_stream:"voyce_chapter_contents",
		voyce_chapter_effects:"voyce_chapter_effects",
		voyce_chapter_effects_aggregate:"voyce_chapter_effects_aggregate",
		voyce_chapter_effects_by_pk:"voyce_chapter_effects",
		voyce_chapter_effects_stream:"voyce_chapter_effects",
		voyce_chapter_images:"voyce_chapter_images",
		voyce_chapter_images_07252024:"voyce_chapter_images_07252024",
		voyce_chapter_images_07252024_aggregate:"voyce_chapter_images_07252024_aggregate",
		voyce_chapter_images_07252024_stream:"voyce_chapter_images_07252024",
		voyce_chapter_images_aggregate:"voyce_chapter_images_aggregate",
		voyce_chapter_images_by_pk:"voyce_chapter_images",
		voyce_chapter_images_stream:"voyce_chapter_images",
		voyce_chapter_likes:"voyce_chapter_likes",
		voyce_chapter_likes_aggregate:"voyce_chapter_likes_aggregate",
		voyce_chapter_likes_by_pk:"voyce_chapter_likes",
		voyce_chapter_likes_stream:"voyce_chapter_likes",
		voyce_chapter_locks:"voyce_chapter_locks",
		voyce_chapter_locks_aggregate:"voyce_chapter_locks_aggregate",
		voyce_chapter_locks_by_pk:"voyce_chapter_locks",
		voyce_chapter_locks_stream:"voyce_chapter_locks",
		voyce_chapter_panel_views:"voyce_chapter_panel_views",
		voyce_chapter_panel_views_aggregate:"voyce_chapter_panel_views_aggregate",
		voyce_chapter_panel_views_by_pk:"voyce_chapter_panel_views",
		voyce_chapter_panel_views_stream:"voyce_chapter_panel_views",
		voyce_chapter_panels:"voyce_chapter_panels",
		voyce_chapter_panels_aggregate:"voyce_chapter_panels_aggregate",
		voyce_chapter_panels_by_pk:"voyce_chapter_panels",
		voyce_chapter_panels_stream:"voyce_chapter_panels",
		voyce_chapter_publish_schedules:"voyce_chapter_publish_schedules",
		voyce_chapter_publish_schedules_aggregate:"voyce_chapter_publish_schedules_aggregate",
		voyce_chapter_publish_schedules_by_pk:"voyce_chapter_publish_schedules",
		voyce_chapter_publish_schedules_stream:"voyce_chapter_publish_schedules",
		voyce_chapter_reactions:"voyce_chapter_reactions",
		voyce_chapter_reactions_aggregate:"voyce_chapter_reactions_aggregate",
		voyce_chapter_reactions_by_pk:"voyce_chapter_reactions",
		voyce_chapter_reactions_count:"voyce_chapter_reactions_count",
		voyce_chapter_reactions_count_aggregate:"voyce_chapter_reactions_count_aggregate",
		voyce_chapter_reactions_count_by_pk:"voyce_chapter_reactions_count",
		voyce_chapter_reactions_count_stream:"voyce_chapter_reactions_count",
		voyce_chapter_reactions_stream:"voyce_chapter_reactions",
		voyce_chapter_view_logs:"voyce_chapter_view_logs",
		voyce_chapter_view_logs_aggregate:"voyce_chapter_view_logs_aggregate",
		voyce_chapter_view_logs_by_pk:"voyce_chapter_view_logs",
		voyce_chapter_view_logs_stream:"voyce_chapter_view_logs",
		voyce_chapter_views:"voyce_chapter_views",
		voyce_chapter_views_aggregate:"voyce_chapter_views_aggregate",
		voyce_chapter_views_by_pk:"voyce_chapter_views",
		voyce_chapter_views_stream:"voyce_chapter_views",
		voyce_chapters:"voyce_chapters",
		voyce_chapters_07252024:"voyce_chapters_07252024",
		voyce_chapters_07252024_aggregate:"voyce_chapters_07252024_aggregate",
		voyce_chapters_07252024_stream:"voyce_chapters_07252024",
		voyce_chapters_aggregate:"voyce_chapters_aggregate",
		voyce_chapters_by_pk:"voyce_chapters",
		voyce_chapters_stream:"voyce_chapters",
		voyce_collaborator_roles:"voyce_collaborator_roles",
		voyce_collaborator_roles_aggregate:"voyce_collaborator_roles_aggregate",
		voyce_collaborator_roles_by_pk:"voyce_collaborator_roles",
		voyce_collaborator_roles_stream:"voyce_collaborator_roles",
		voyce_comic_types:"voyce_comic_types",
		voyce_comic_types_aggregate:"voyce_comic_types_aggregate",
		voyce_comic_types_by_pk:"voyce_comic_types",
		voyce_comic_types_stream:"voyce_comic_types",
		voyce_comments_top_mat:"voyce_comments_top_mat",
		voyce_comments_top_mat_aggregate:"voyce_comments_top_mat_aggregate",
		voyce_comments_top_mat_stream:"voyce_comments_top_mat",
		voyce_community_blurbs:"voyce_community_blurbs",
		voyce_community_blurbs_aggregate:"voyce_community_blurbs_aggregate",
		voyce_community_blurbs_by_pk:"voyce_community_blurbs",
		voyce_community_blurbs_stream:"voyce_community_blurbs",
		voyce_contact_us:"voyce_contact_us",
		voyce_contact_us_aggregate:"voyce_contact_us_aggregate",
		voyce_contact_us_by_pk:"voyce_contact_us",
		voyce_contact_us_stream:"voyce_contact_us",
		voyce_content_report:"voyce_content_report",
		voyce_content_report_aggregate:"voyce_content_report_aggregate",
		voyce_content_report_by_pk:"voyce_content_report",
		voyce_content_report_stream:"voyce_content_report",
		voyce_contest_data:"voyce_contest_data",
		voyce_contest_data_aggregate:"voyce_contest_data_aggregate",
		voyce_contest_data_by_pk:"voyce_contest_data",
		voyce_contest_data_stream:"voyce_contest_data",
		voyce_countries:"voyce_countries",
		voyce_countries_aggregate:"voyce_countries_aggregate",
		voyce_countries_by_pk:"voyce_countries",
		voyce_countries_stream:"voyce_countries",
		voyce_csat_ratings:"voyce_csat_ratings",
		voyce_csat_ratings_aggregate:"voyce_csat_ratings_aggregate",
		voyce_csat_ratings_by_pk:"voyce_csat_ratings",
		voyce_csat_ratings_data:"voyce_csat_ratings_data",
		voyce_csat_ratings_data_aggregate:"voyce_csat_ratings_data_aggregate",
		voyce_csat_ratings_data_by_pk:"voyce_csat_ratings_data",
		voyce_csat_ratings_data_stream:"voyce_csat_ratings_data",
		voyce_csat_ratings_stream:"voyce_csat_ratings",
		voyce_education_types:"voyce_education_types",
		voyce_education_types_aggregate:"voyce_education_types_aggregate",
		voyce_education_types_by_pk:"voyce_education_types",
		voyce_education_types_stream:"voyce_education_types",
		voyce_erp_login_roles:"voyce_erp_login_roles",
		voyce_erp_login_roles_aggregate:"voyce_erp_login_roles_aggregate",
		voyce_erp_login_roles_by_pk:"voyce_erp_login_roles",
		voyce_erp_login_roles_stream:"voyce_erp_login_roles",
		voyce_etl_series_views_logs:"voyce_etl_series_views_logs",
		voyce_etl_series_views_logs_aggregate:"voyce_etl_series_views_logs_aggregate",
		voyce_etl_series_views_logs_by_pk:"voyce_etl_series_views_logs",
		voyce_etl_series_views_logs_stream:"voyce_etl_series_views_logs",
		voyce_event_schedules:"voyce_event_schedules",
		voyce_event_schedules_aggregate:"voyce_event_schedules_aggregate",
		voyce_event_schedules_by_pk:"voyce_event_schedules",
		voyce_event_schedules_stream:"voyce_event_schedules",
		voyce_genres:"voyce_genres",
		voyce_genres_aggregate:"voyce_genres_aggregate",
		voyce_genres_by_pk:"voyce_genres",
		voyce_genres_stream:"voyce_genres",
		voyce_guest_gtags:"voyce_guest_gtags",
		voyce_guest_gtags_aggregate:"voyce_guest_gtags_aggregate",
		voyce_guest_gtags_by_pk:"voyce_guest_gtags",
		voyce_guest_gtags_stream:"voyce_guest_gtags",
		voyce_metabases:"voyce_metabases",
		voyce_metabases_access:"voyce_metabases_access",
		voyce_metabases_access_aggregate:"voyce_metabases_access_aggregate",
		voyce_metabases_access_by_pk:"voyce_metabases_access",
		voyce_metabases_access_stream:"voyce_metabases_access",
		voyce_metabases_aggregate:"voyce_metabases_aggregate",
		voyce_metabases_by_pk:"voyce_metabases",
		voyce_metabases_stream:"voyce_metabases",
		voyce_newsletter:"voyce_newsletter",
		voyce_newsletter_aggregate:"voyce_newsletter_aggregate",
		voyce_newsletter_by_pk:"voyce_newsletter",
		voyce_newsletter_stream:"voyce_newsletter",
		voyce_notification_series_settings:"voyce_notification_series_settings",
		voyce_notification_series_settings_aggregate:"voyce_notification_series_settings_aggregate",
		voyce_notification_series_settings_by_pk:"voyce_notification_series_settings",
		voyce_notification_series_settings_stream:"voyce_notification_series_settings",
		voyce_notification_settings:"voyce_notification_settings",
		voyce_notification_settings_aggregate:"voyce_notification_settings_aggregate",
		voyce_notification_settings_by_pk:"voyce_notification_settings",
		voyce_notification_settings_stream:"voyce_notification_settings",
		voyce_notifications:"voyce_notifications",
		voyce_notifications_aggregate:"voyce_notifications_aggregate",
		voyce_notifications_by_pk:"voyce_notifications",
		voyce_notifications_stream:"voyce_notifications",
		voyce_originals_feed:"voyce_originals_feed",
		voyce_originals_feed_aggregate:"voyce_originals_feed_aggregate",
		voyce_originals_feed_stream:"voyce_originals_feed",
		voyce_page_banners:"voyce_page_banners",
		voyce_page_banners_aggregate:"voyce_page_banners_aggregate",
		voyce_page_banners_by_pk:"voyce_page_banners",
		voyce_page_banners_stream:"voyce_page_banners",
		voyce_page_components:"voyce_page_components",
		voyce_page_components_aggregate:"voyce_page_components_aggregate",
		voyce_page_components_by_pk:"voyce_page_components",
		voyce_page_components_stream:"voyce_page_components",
		voyce_pages:"voyce_pages",
		voyce_pages_aggregate:"voyce_pages_aggregate",
		voyce_pages_by_pk:"voyce_pages",
		voyce_pages_stream:"voyce_pages",
		voyce_panels_comments:"voyce_panels_comments",
		voyce_panels_comments_aggregate:"voyce_panels_comments_aggregate",
		voyce_panels_comments_by_pk:"voyce_panels_comments",
		voyce_panels_comments_stream:"voyce_panels_comments",
		voyce_panels_reactions:"voyce_panels_reactions",
		voyce_panels_reactions_aggregate:"voyce_panels_reactions_aggregate",
		voyce_panels_reactions_by_pk:"voyce_panels_reactions",
		voyce_panels_reactions_count:"voyce_panels_reactions_count",
		voyce_panels_reactions_count_aggregate:"voyce_panels_reactions_count_aggregate",
		voyce_panels_reactions_count_by_pk:"voyce_panels_reactions_count",
		voyce_panels_reactions_count_stream:"voyce_panels_reactions_count",
		voyce_panels_reactions_stream:"voyce_panels_reactions",
		voyce_payments_to_creators:"voyce_payments_to_creators",
		voyce_payments_to_creators_aggregate:"voyce_payments_to_creators_aggregate",
		voyce_payments_to_creators_by_pk:"voyce_payments_to_creators",
		voyce_payments_to_creators_stream:"voyce_payments_to_creators",
		voyce_pg_rs_series_views_count:"voyce_pg_rs_series_views_count",
		voyce_pg_rs_series_views_count_aggregate:"voyce_pg_rs_series_views_count_aggregate",
		voyce_pg_rs_series_views_count_by_pk:"voyce_pg_rs_series_views_count",
		voyce_pg_rs_series_views_count_stream:"voyce_pg_rs_series_views_count",
		voyce_polls:"voyce_polls",
		voyce_polls_aggregate:"voyce_polls_aggregate",
		voyce_polls_answers:"voyce_polls_answers",
		voyce_polls_answers_aggregate:"voyce_polls_answers_aggregate",
		voyce_polls_answers_by_pk:"voyce_polls_answers",
		voyce_polls_answers_stream:"voyce_polls_answers",
		voyce_polls_by_pk:"voyce_polls",
		voyce_polls_comments:"voyce_polls_comments",
		voyce_polls_comments_aggregate:"voyce_polls_comments_aggregate",
		voyce_polls_comments_by_pk:"voyce_polls_comments",
		voyce_polls_comments_stream:"voyce_polls_comments",
		voyce_polls_reactions:"voyce_polls_reactions",
		voyce_polls_reactions_aggregate:"voyce_polls_reactions_aggregate",
		voyce_polls_reactions_by_pk:"voyce_polls_reactions",
		voyce_polls_reactions_stream:"voyce_polls_reactions",
		voyce_polls_stream:"voyce_polls",
		voyce_polls_votes:"voyce_polls_votes",
		voyce_polls_votes_aggregate:"voyce_polls_votes_aggregate",
		voyce_polls_votes_by_pk:"voyce_polls_votes",
		voyce_polls_votes_stream:"voyce_polls_votes",
		voyce_popup_components:"voyce_popup_components",
		voyce_popup_components_aggregate:"voyce_popup_components_aggregate",
		voyce_popup_components_by_pk:"voyce_popup_components",
		voyce_popup_components_stream:"voyce_popup_components",
		voyce_popup_emails:"voyce_popup_emails",
		voyce_popup_emails_aggregate:"voyce_popup_emails_aggregate",
		voyce_popup_emails_by_pk:"voyce_popup_emails",
		voyce_popup_emails_stream:"voyce_popup_emails",
		voyce_posts:"voyce_posts",
		voyce_posts_aggregate:"voyce_posts_aggregate",
		voyce_posts_by_pk:"voyce_posts",
		voyce_posts_comments:"voyce_posts_comments",
		voyce_posts_comments_aggregate:"voyce_posts_comments_aggregate",
		voyce_posts_comments_by_pk:"voyce_posts_comments",
		voyce_posts_comments_stream:"voyce_posts_comments",
		voyce_posts_reactions:"voyce_posts_reactions",
		voyce_posts_reactions_aggregate:"voyce_posts_reactions_aggregate",
		voyce_posts_reactions_by_pk:"voyce_posts_reactions",
		voyce_posts_reactions_stream:"voyce_posts_reactions",
		voyce_posts_stream:"voyce_posts",
		voyce_quest_difficulties:"voyce_quest_difficulties",
		voyce_quest_difficulties_aggregate:"voyce_quest_difficulties_aggregate",
		voyce_quest_difficulties_by_pk:"voyce_quest_difficulties",
		voyce_quest_difficulties_stream:"voyce_quest_difficulties",
		voyce_quests:"voyce_quests",
		voyce_quests_aggregate:"voyce_quests_aggregate",
		voyce_quests_by_pk:"voyce_quests",
		voyce_quests_old:"voyce_quests_old",
		voyce_quests_old_aggregate:"voyce_quests_old_aggregate",
		voyce_quests_old_by_pk:"voyce_quests_old",
		voyce_quests_old_stream:"voyce_quests_old",
		voyce_quests_stream:"voyce_quests",
		voyce_recommended_series:"voyce_recommended_series",
		voyce_recommended_series_aggregate:"voyce_recommended_series_aggregate",
		voyce_recommended_series_by_pk:"voyce_recommended_series",
		voyce_recommended_series_stream:"voyce_recommended_series",
		voyce_release_schedules:"voyce_release_schedules",
		voyce_release_schedules_aggregate:"voyce_release_schedules_aggregate",
		voyce_release_schedules_by_pk:"voyce_release_schedules",
		voyce_release_schedules_stream:"voyce_release_schedules",
		voyce_rewards:"voyce_rewards",
		voyce_rewards_aggregate:"voyce_rewards_aggregate",
		voyce_rewards_by_pk:"voyce_rewards",
		voyce_rewards_leaderboard:"voyce_rewards_leaderboard",
		voyce_rewards_leaderboard_aggregate:"voyce_rewards_leaderboard_aggregate",
		voyce_rewards_leaderboard_by_pk:"voyce_rewards_leaderboard",
		voyce_rewards_leaderboard_stream:"voyce_rewards_leaderboard",
		voyce_rewards_quest:"voyce_rewards_quest",
		voyce_rewards_quest_aggregate:"voyce_rewards_quest_aggregate",
		voyce_rewards_quest_by_pk:"voyce_rewards_quest",
		voyce_rewards_quest_stream:"voyce_rewards_quest",
		voyce_rewards_stream:"voyce_rewards",
		voyce_sections:"voyce_sections",
		voyce_sections_aggregate:"voyce_sections_aggregate",
		voyce_sections_by_pk:"voyce_sections",
		voyce_sections_stream:"voyce_sections",
		voyce_series:"voyce_series",
		voyce_series_aggregate:"voyce_series_aggregate",
		voyce_series_by_pk:"voyce_series",
		voyce_series_collaborators:"voyce_series_collaborators",
		voyce_series_collaborators_aggregate:"voyce_series_collaborators_aggregate",
		voyce_series_collaborators_by_pk:"voyce_series_collaborators",
		voyce_series_collaborators_stream:"voyce_series_collaborators",
		voyce_series_donations:"voyce_series_donations",
		voyce_series_donations_aggregate:"voyce_series_donations_aggregate",
		voyce_series_donations_by_pk:"voyce_series_donations",
		voyce_series_donations_stream:"voyce_series_donations",
		voyce_series_favorites:"voyce_series_favorites",
		voyce_series_favorites_aggregate:"voyce_series_favorites_aggregate",
		voyce_series_favorites_by_pk:"voyce_series_favorites",
		voyce_series_favorites_stream:"voyce_series_favorites",
		voyce_series_featured_panels:"voyce_series_featured_panels",
		voyce_series_featured_panels_aggregate:"voyce_series_featured_panels_aggregate",
		voyce_series_featured_panels_by_pk:"voyce_series_featured_panels",
		voyce_series_featured_panels_stream:"voyce_series_featured_panels",
		voyce_series_genre:"voyce_series_genre",
		voyce_series_genre_aggregate:"voyce_series_genre_aggregate",
		voyce_series_genre_by_pk:"voyce_series_genre",
		voyce_series_genre_stream:"voyce_series_genre",
		voyce_series_giveaway_participants:"voyce_series_giveaway_participants",
		voyce_series_giveaway_participants_aggregate:"voyce_series_giveaway_participants_aggregate",
		voyce_series_giveaway_participants_by_pk:"voyce_series_giveaway_participants",
		voyce_series_giveaway_participants_stream:"voyce_series_giveaway_participants",
		voyce_series_hot_panels:"voyce_series_hot_panels",
		voyce_series_hot_panels_aggregate:"voyce_series_hot_panels_aggregate",
		voyce_series_hot_panels_by_pk:"voyce_series_hot_panels",
		voyce_series_hot_panels_stream:"voyce_series_hot_panels",
		voyce_series_layouts:"voyce_series_layouts",
		voyce_series_layouts_aggregate:"voyce_series_layouts_aggregate",
		voyce_series_layouts_by_pk:"voyce_series_layouts",
		voyce_series_layouts_stream:"voyce_series_layouts",
		voyce_series_leaderboard:"voyce_series_leaderboard",
		voyce_series_leaderboard_aggregate:"voyce_series_leaderboard_aggregate",
		voyce_series_leaderboard_by_pk:"voyce_series_leaderboard",
		voyce_series_leaderboard_stream:"voyce_series_leaderboard",
		voyce_series_likes_count:"voyce_series_likes_count",
		voyce_series_likes_count_aggregate:"voyce_series_likes_count_aggregate",
		voyce_series_likes_count_mat:"voyce_series_likes_count_mat",
		voyce_series_likes_count_mat_aggregate:"voyce_series_likes_count_mat_aggregate",
		voyce_series_likes_count_mat_stream:"voyce_series_likes_count_mat",
		voyce_series_likes_count_stream:"voyce_series_likes_count",
		voyce_series_lock_config:"voyce_series_lock_config",
		voyce_series_lock_config_aggregate:"voyce_series_lock_config_aggregate",
		voyce_series_lock_config_by_pk:"voyce_series_lock_config",
		voyce_series_lock_config_stream:"voyce_series_lock_config",
		voyce_series_sponsorships_configuration:"voyce_series_sponsorships_configuration",
		voyce_series_sponsorships_configuration_aggregate:"voyce_series_sponsorships_configuration_aggregate",
		voyce_series_sponsorships_configuration_by_pk:"voyce_series_sponsorships_configuration",
		voyce_series_sponsorships_configuration_stream:"voyce_series_sponsorships_configuration",
		voyce_series_stream:"voyce_series",
		voyce_series_subscription:"voyce_series_subscription",
		voyce_series_subscription_aggregate:"voyce_series_subscription_aggregate",
		voyce_series_subscription_by_pk:"voyce_series_subscription",
		voyce_series_subscription_stream:"voyce_series_subscription",
		voyce_series_tags:"voyce_series_tags",
		voyce_series_tags_aggregate:"voyce_series_tags_aggregate",
		voyce_series_tags_by_pk:"voyce_series_tags",
		voyce_series_tags_junction:"voyce_series_tags_junction",
		voyce_series_tags_junction_aggregate:"voyce_series_tags_junction_aggregate",
		voyce_series_tags_junction_by_pk:"voyce_series_tags_junction",
		voyce_series_tags_junction_stream:"voyce_series_tags_junction",
		voyce_series_tags_stream:"voyce_series_tags",
		voyce_series_top_comments:"voyce_series_top_comments",
		voyce_series_top_comments_aggregate:"voyce_series_top_comments_aggregate",
		voyce_series_top_comments_by_pk:"voyce_series_top_comments",
		voyce_series_top_comments_stream:"voyce_series_top_comments",
		voyce_series_top_likes:"voyce_series_top_likes",
		voyce_series_top_likes_aggregate:"voyce_series_top_likes_aggregate",
		voyce_series_top_likes_by_pk:"voyce_series_top_likes",
		voyce_series_top_likes_stream:"voyce_series_top_likes",
		voyce_series_top_views:"voyce_series_top_views",
		voyce_series_top_views_aggregate:"voyce_series_top_views_aggregate",
		voyce_series_top_views_by_pk:"voyce_series_top_views",
		voyce_series_top_views_stream:"voyce_series_top_views",
		voyce_series_views_adjustment:"voyce_series_views_adjustment",
		voyce_series_views_adjustment_aggregate:"voyce_series_views_adjustment_aggregate",
		voyce_series_views_adjustment_by_pk:"voyce_series_views_adjustment",
		voyce_series_views_adjustment_stream:"voyce_series_views_adjustment",
		voyce_series_views_count:"voyce_series_views_count",
		voyce_series_views_count_aggregate:"voyce_series_views_count_aggregate",
		voyce_series_views_count_mat:"voyce_series_views_count_mat",
		voyce_series_views_count_mat_aggregate:"voyce_series_views_count_mat_aggregate",
		voyce_series_views_count_mat_sep30:"voyce_series_views_count_mat_sep30",
		voyce_series_views_count_mat_sep30_aggregate:"voyce_series_views_count_mat_sep30_aggregate",
		voyce_series_views_count_mat_sep30_stream:"voyce_series_views_count_mat_sep30",
		voyce_series_views_count_mat_stream:"voyce_series_views_count_mat",
		voyce_series_views_count_mat_v2:"voyce_series_views_count_mat_v2",
		voyce_series_views_count_mat_v2_aggregate:"voyce_series_views_count_mat_v2_aggregate",
		voyce_series_views_count_mat_v2_stream:"voyce_series_views_count_mat_v2",
		voyce_series_views_count_stream:"voyce_series_views_count",
		voyce_series_votes:"voyce_series_votes",
		voyce_series_votes_aggregate:"voyce_series_votes_aggregate",
		voyce_series_votes_by_pk:"voyce_series_votes",
		voyce_series_votes_count:"voyce_series_votes_count",
		voyce_series_votes_count_aggregate:"voyce_series_votes_count_aggregate",
		voyce_series_votes_count_by_pk:"voyce_series_votes_count",
		voyce_series_votes_count_stream:"voyce_series_votes_count",
		voyce_series_votes_stream:"voyce_series_votes",
		voyce_series_voting_schedules:"voyce_series_voting_schedules",
		voyce_series_voting_schedules_aggregate:"voyce_series_voting_schedules_aggregate",
		voyce_series_voting_schedules_by_pk:"voyce_series_voting_schedules",
		voyce_series_voting_schedules_stream:"voyce_series_voting_schedules",
		voyce_sponsorships:"voyce_sponsorships",
		voyce_sponsorships_aggregate:"voyce_sponsorships_aggregate",
		voyce_sponsorships_by_pk:"voyce_sponsorships",
		voyce_sponsorships_stream:"voyce_sponsorships",
		voyce_states:"voyce_states",
		voyce_states_aggregate:"voyce_states_aggregate",
		voyce_states_by_pk:"voyce_states",
		voyce_states_stream:"voyce_states",
		voyce_stripe_payouts_setup:"voyce_stripe_payouts_setup",
		voyce_stripe_payouts_setup_aggregate:"voyce_stripe_payouts_setup_aggregate",
		voyce_stripe_payouts_setup_by_pk:"voyce_stripe_payouts_setup",
		voyce_stripe_payouts_setup_stream:"voyce_stripe_payouts_setup",
		voyce_studio:"voyce_studio",
		voyce_studio_aggregate:"voyce_studio_aggregate",
		voyce_studio_by_pk:"voyce_studio",
		voyce_studio_followers:"voyce_studio_followers",
		voyce_studio_followers_aggregate:"voyce_studio_followers_aggregate",
		voyce_studio_followers_by_pk:"voyce_studio_followers",
		voyce_studio_followers_stream:"voyce_studio_followers",
		voyce_studio_series:"voyce_studio_series",
		voyce_studio_series_aggregate:"voyce_studio_series_aggregate",
		voyce_studio_series_by_pk:"voyce_studio_series",
		voyce_studio_series_stream:"voyce_studio_series",
		voyce_studio_stream:"voyce_studio",
		voyce_studio_views:"voyce_studio_views",
		voyce_studio_views_aggregate:"voyce_studio_views_aggregate",
		voyce_studio_views_by_pk:"voyce_studio_views",
		voyce_studio_views_stream:"voyce_studio_views",
		voyce_system_settings:"voyce_system_settings",
		voyce_system_settings_aggregate:"voyce_system_settings_aggregate",
		voyce_system_settings_by_pk:"voyce_system_settings",
		voyce_system_settings_stream:"voyce_system_settings",
		voyce_top_genres:"voyce_top_genres",
		voyce_top_genres_aggregate:"voyce_top_genres_aggregate",
		voyce_top_genres_stream:"voyce_top_genres",
		voyce_transaction_logs:"voyce_transaction_logs",
		voyce_transaction_logs_aggregate:"voyce_transaction_logs_aggregate",
		voyce_transaction_logs_by_pk:"voyce_transaction_logs",
		voyce_transaction_logs_stream:"voyce_transaction_logs",
		voyce_url_redirects:"voyce_url_redirects",
		voyce_url_redirects_aggregate:"voyce_url_redirects_aggregate",
		voyce_url_redirects_by_pk:"voyce_url_redirects",
		voyce_url_redirects_stream:"voyce_url_redirects",
		voyce_user_addresses:"voyce_user_addresses",
		voyce_user_addresses_aggregate:"voyce_user_addresses_aggregate",
		voyce_user_addresses_by_pk:"voyce_user_addresses",
		voyce_user_addresses_stream:"voyce_user_addresses",
		voyce_user_blocks:"voyce_user_blocks",
		voyce_user_blocks_aggregate:"voyce_user_blocks_aggregate",
		voyce_user_blocks_by_pk:"voyce_user_blocks",
		voyce_user_blocks_stream:"voyce_user_blocks",
		voyce_user_chapters_unlocked:"voyce_user_chapters_unlocked",
		voyce_user_chapters_unlocked_aggregate:"voyce_user_chapters_unlocked_aggregate",
		voyce_user_chapters_unlocked_by_pk:"voyce_user_chapters_unlocked",
		voyce_user_chapters_unlocked_stream:"voyce_user_chapters_unlocked",
		voyce_user_contacts:"voyce_user_contacts",
		voyce_user_contacts_aggregate:"voyce_user_contacts_aggregate",
		voyce_user_contacts_by_pk:"voyce_user_contacts",
		voyce_user_contacts_stream:"voyce_user_contacts",
		voyce_user_csat_ratings:"voyce_user_csat_ratings",
		voyce_user_csat_ratings_aggregate:"voyce_user_csat_ratings_aggregate",
		voyce_user_csat_ratings_by_pk:"voyce_user_csat_ratings",
		voyce_user_csat_ratings_stream:"voyce_user_csat_ratings",
		voyce_user_education:"voyce_user_education",
		voyce_user_education_aggregate:"voyce_user_education_aggregate",
		voyce_user_education_by_pk:"voyce_user_education",
		voyce_user_education_stream:"voyce_user_education",
		voyce_user_level:"voyce_user_level",
		voyce_user_level_aggregate:"voyce_user_level_aggregate",
		voyce_user_level_by_pk:"voyce_user_level",
		voyce_user_level_stream:"voyce_user_level",
		voyce_user_linked_accounts:"voyce_user_linked_accounts",
		voyce_user_linked_accounts_aggregate:"voyce_user_linked_accounts_aggregate",
		voyce_user_linked_accounts_by_pk:"voyce_user_linked_accounts",
		voyce_user_linked_accounts_stream:"voyce_user_linked_accounts",
		voyce_user_login_history:"voyce_user_login_history",
		voyce_user_login_history_aggregate:"voyce_user_login_history_aggregate",
		voyce_user_login_history_by_pk:"voyce_user_login_history",
		voyce_user_login_history_stream:"voyce_user_login_history",
		voyce_user_quest_ids:"voyce_user_quest_ids",
		voyce_user_quest_ids_aggregate:"voyce_user_quest_ids_aggregate",
		voyce_user_quest_ids_by_pk:"voyce_user_quest_ids",
		voyce_user_quest_ids_stream:"voyce_user_quest_ids",
		voyce_user_quest_rewards:"voyce_user_quest_rewards",
		voyce_user_quest_rewards_aggregate:"voyce_user_quest_rewards_aggregate",
		voyce_user_quest_rewards_by_pk:"voyce_user_quest_rewards",
		voyce_user_quest_rewards_stream:"voyce_user_quest_rewards",
		voyce_user_quests:"voyce_user_quests",
		voyce_user_quests_aggregate:"voyce_user_quests_aggregate",
		voyce_user_quests_by_pk:"voyce_user_quests",
		voyce_user_quests_old:"voyce_user_quests_old",
		voyce_user_quests_old_aggregate:"voyce_user_quests_old_aggregate",
		voyce_user_quests_old_by_pk:"voyce_user_quests_old",
		voyce_user_quests_old_stream:"voyce_user_quests_old",
		voyce_user_quests_stream:"voyce_user_quests",
		voyce_user_ranking:"voyce_user_ranking",
		voyce_user_ranking_aggregate:"voyce_user_ranking_aggregate",
		voyce_user_ranking_by_pk:"voyce_user_ranking",
		voyce_user_ranking_stream:"voyce_user_ranking",
		voyce_user_reviews:"voyce_user_reviews",
		voyce_user_reviews_aggregate:"voyce_user_reviews_aggregate",
		voyce_user_reviews_by_pk:"voyce_user_reviews",
		voyce_user_reviews_stream:"voyce_user_reviews",
		voyce_user_rewards:"voyce_user_rewards",
		voyce_user_rewards_aggregate:"voyce_user_rewards_aggregate",
		voyce_user_rewards_by_pk:"voyce_user_rewards",
		voyce_user_rewards_stream:"voyce_user_rewards",
		voyce_user_roles:"voyce_user_roles",
		voyce_user_roles_aggregate:"voyce_user_roles_aggregate",
		voyce_user_roles_by_pk:"voyce_user_roles",
		voyce_user_roles_stream:"voyce_user_roles",
		voyce_user_series_subscription_count:"voyce_user_series_subscription_count",
		voyce_user_series_subscription_count_aggregate:"voyce_user_series_subscription_count_aggregate",
		voyce_user_series_subscription_count_stream:"voyce_user_series_subscription_count",
		voyce_user_settings:"voyce_user_settings",
		voyce_user_settings_aggregate:"voyce_user_settings_aggregate",
		voyce_user_settings_by_pk:"voyce_user_settings",
		voyce_user_settings_stream:"voyce_user_settings",
		voyce_user_skills:"voyce_user_skills",
		voyce_user_skills_aggregate:"voyce_user_skills_aggregate",
		voyce_user_skills_by_pk:"voyce_user_skills",
		voyce_user_skills_stream:"voyce_user_skills",
		voyce_user_socials:"voyce_user_socials",
		voyce_user_socials_aggregate:"voyce_user_socials_aggregate",
		voyce_user_socials_by_pk:"voyce_user_socials",
		voyce_user_socials_stream:"voyce_user_socials",
		voyce_user_soft_deleted:"voyce_user_soft_deleted",
		voyce_user_soft_deleted_aggregate:"voyce_user_soft_deleted_aggregate",
		voyce_user_soft_deleted_by_pk:"voyce_user_soft_deleted",
		voyce_user_soft_deleted_stream:"voyce_user_soft_deleted",
		voyce_user_vtags:"voyce_user_vtags",
		voyce_user_vtags_aggregate:"voyce_user_vtags_aggregate",
		voyce_user_vtags_by_pk:"voyce_user_vtags",
		voyce_user_vtags_stream:"voyce_user_vtags",
		voyce_user_wins:"voyce_user_wins",
		voyce_user_wins_aggregate:"voyce_user_wins_aggregate",
		voyce_user_wins_by_pk:"voyce_user_wins",
		voyce_user_wins_stream:"voyce_user_wins",
		voyce_users:"voyce_users",
		voyce_users_aggregate:"voyce_users_aggregate",
		voyce_users_by_pk:"voyce_users",
		voyce_users_followers:"voyce_users_followers",
		voyce_users_followers_aggregate:"voyce_users_followers_aggregate",
		voyce_users_followers_by_pk:"voyce_users_followers",
		voyce_users_followers_stream:"voyce_users_followers",
		voyce_users_private:"voyce_users_private",
		voyce_users_private_aggregate:"voyce_users_private_aggregate",
		voyce_users_private_stream:"voyce_users_private",
		voyce_users_series_junction:"voyce_users_series_junction",
		voyce_users_series_junction_aggregate:"voyce_users_series_junction_aggregate",
		voyce_users_series_junction_by_pk:"voyce_users_series_junction",
		voyce_users_series_junction_stream:"voyce_users_series_junction",
		voyce_users_stream:"voyce_users",
		voyce_versions:"voyce_versions",
		voyce_versions_aggregate:"voyce_versions_aggregate",
		voyce_versions_by_pk:"voyce_versions",
		voyce_versions_stream:"voyce_versions",
		voyce_voting_analytics:"voyce_voting_analytics",
		voyce_voting_analytics_aggregate:"voyce_voting_analytics_aggregate",
		voyce_voting_analytics_by_pk:"voyce_voting_analytics",
		voyce_voting_analytics_stream:"voyce_voting_analytics",
		voyce_voting_panels:"voyce_voting_panels",
		voyce_voting_panels_aggregate:"voyce_voting_panels_aggregate",
		voyce_voting_panels_by_pk:"voyce_voting_panels",
		voyce_voting_panels_stream:"voyce_voting_panels",
		voyce_voting_series:"voyce_voting_series",
		voyce_voting_series_aggregate:"voyce_voting_series_aggregate",
		voyce_voting_series_by_pk:"voyce_voting_series",
		voyce_voting_series_stream:"voyce_voting_series",
		voyce_voting_user_votes:"voyce_voting_user_votes",
		voyce_voting_user_votes_aggregate:"voyce_voting_user_votes_aggregate",
		voyce_voting_user_votes_by_pk:"voyce_voting_user_votes",
		voyce_voting_user_votes_stream:"voyce_voting_user_votes"
	},
	voyce_admin_roles:{
		created_at:"timestamptz",
		id:"Int",
		role:"String",
		updated_at:"timestamptz"
	},
	voyce_admin_roles_aggregate:{
		aggregate:"voyce_admin_roles_aggregate_fields",
		nodes:"voyce_admin_roles"
	},
	voyce_admin_roles_aggregate_fields:{
		avg:"voyce_admin_roles_avg_fields",
		count:"Int",
		max:"voyce_admin_roles_max_fields",
		min:"voyce_admin_roles_min_fields",
		stddev:"voyce_admin_roles_stddev_fields",
		stddev_pop:"voyce_admin_roles_stddev_pop_fields",
		stddev_samp:"voyce_admin_roles_stddev_samp_fields",
		sum:"voyce_admin_roles_sum_fields",
		var_pop:"voyce_admin_roles_var_pop_fields",
		var_samp:"voyce_admin_roles_var_samp_fields",
		variance:"voyce_admin_roles_variance_fields"
	},
	voyce_admin_roles_avg_fields:{
		id:"Float"
	},
	voyce_admin_roles_max_fields:{
		created_at:"timestamptz",
		id:"Int",
		role:"String",
		updated_at:"timestamptz"
	},
	voyce_admin_roles_min_fields:{
		created_at:"timestamptz",
		id:"Int",
		role:"String",
		updated_at:"timestamptz"
	},
	voyce_admin_roles_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_admin_roles"
	},
	voyce_admin_roles_stddev_fields:{
		id:"Float"
	},
	voyce_admin_roles_stddev_pop_fields:{
		id:"Float"
	},
	voyce_admin_roles_stddev_samp_fields:{
		id:"Float"
	},
	voyce_admin_roles_sum_fields:{
		id:"Int"
	},
	voyce_admin_roles_var_pop_fields:{
		id:"Float"
	},
	voyce_admin_roles_var_samp_fields:{
		id:"Float"
	},
	voyce_admin_roles_variance_fields:{
		id:"Float"
	},
	voyce_app_banners:{
		active:"Boolean",
		background:"String",
		created_at:"timestamptz",
		id:"Int",
		link:"String",
		mobile:"String",
		overlap:"Boolean",
		screen:"String",
		sort:"smallint",
		tablet:"String",
		updated_at:"timestamptz"
	},
	voyce_app_banners_aggregate:{
		aggregate:"voyce_app_banners_aggregate_fields",
		nodes:"voyce_app_banners"
	},
	voyce_app_banners_aggregate_fields:{
		avg:"voyce_app_banners_avg_fields",
		count:"Int",
		max:"voyce_app_banners_max_fields",
		min:"voyce_app_banners_min_fields",
		stddev:"voyce_app_banners_stddev_fields",
		stddev_pop:"voyce_app_banners_stddev_pop_fields",
		stddev_samp:"voyce_app_banners_stddev_samp_fields",
		sum:"voyce_app_banners_sum_fields",
		var_pop:"voyce_app_banners_var_pop_fields",
		var_samp:"voyce_app_banners_var_samp_fields",
		variance:"voyce_app_banners_variance_fields"
	},
	voyce_app_banners_avg_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_app_banners_max_fields:{
		background:"String",
		created_at:"timestamptz",
		id:"Int",
		link:"String",
		mobile:"String",
		screen:"String",
		sort:"smallint",
		tablet:"String",
		updated_at:"timestamptz"
	},
	voyce_app_banners_min_fields:{
		background:"String",
		created_at:"timestamptz",
		id:"Int",
		link:"String",
		mobile:"String",
		screen:"String",
		sort:"smallint",
		tablet:"String",
		updated_at:"timestamptz"
	},
	voyce_app_banners_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_app_banners"
	},
	voyce_app_banners_stddev_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_app_banners_stddev_pop_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_app_banners_stddev_samp_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_app_banners_sum_fields:{
		id:"Int",
		sort:"smallint"
	},
	voyce_app_banners_var_pop_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_app_banners_var_samp_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_app_banners_variance_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_app_components:{
		active:"Boolean",
		auth:"Boolean",
		component:"String",
		created_at:"timestamptz",
		device:"String",
		id:"Int",
		props:"jsonb",
		screen:"String",
		sort:"smallint",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_app_components_aggregate:{
		aggregate:"voyce_app_components_aggregate_fields",
		nodes:"voyce_app_components"
	},
	voyce_app_components_aggregate_fields:{
		avg:"voyce_app_components_avg_fields",
		count:"Int",
		max:"voyce_app_components_max_fields",
		min:"voyce_app_components_min_fields",
		stddev:"voyce_app_components_stddev_fields",
		stddev_pop:"voyce_app_components_stddev_pop_fields",
		stddev_samp:"voyce_app_components_stddev_samp_fields",
		sum:"voyce_app_components_sum_fields",
		var_pop:"voyce_app_components_var_pop_fields",
		var_samp:"voyce_app_components_var_samp_fields",
		variance:"voyce_app_components_variance_fields"
	},
	voyce_app_components_avg_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_app_components_max_fields:{
		component:"String",
		created_at:"timestamptz",
		device:"String",
		id:"Int",
		screen:"String",
		sort:"smallint",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_app_components_min_fields:{
		component:"String",
		created_at:"timestamptz",
		device:"String",
		id:"Int",
		screen:"String",
		sort:"smallint",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_app_components_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_app_components"
	},
	voyce_app_components_stddev_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_app_components_stddev_pop_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_app_components_stddev_samp_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_app_components_sum_fields:{
		id:"Int",
		sort:"smallint"
	},
	voyce_app_components_var_pop_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_app_components_var_samp_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_app_components_variance_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_audit_trail:{
		action:"String",
		created_at:"timestamptz",
		id:"Int",
		module:"String",
		payload:"json",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_audit_trail_aggregate:{
		aggregate:"voyce_audit_trail_aggregate_fields",
		nodes:"voyce_audit_trail"
	},
	voyce_audit_trail_aggregate_fields:{
		avg:"voyce_audit_trail_avg_fields",
		count:"Int",
		max:"voyce_audit_trail_max_fields",
		min:"voyce_audit_trail_min_fields",
		stddev:"voyce_audit_trail_stddev_fields",
		stddev_pop:"voyce_audit_trail_stddev_pop_fields",
		stddev_samp:"voyce_audit_trail_stddev_samp_fields",
		sum:"voyce_audit_trail_sum_fields",
		var_pop:"voyce_audit_trail_var_pop_fields",
		var_samp:"voyce_audit_trail_var_samp_fields",
		variance:"voyce_audit_trail_variance_fields"
	},
	voyce_audit_trail_avg_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_audit_trail_max_fields:{
		action:"String",
		created_at:"timestamptz",
		id:"Int",
		module:"String",
		user_id:"Int"
	},
	voyce_audit_trail_min_fields:{
		action:"String",
		created_at:"timestamptz",
		id:"Int",
		module:"String",
		user_id:"Int"
	},
	voyce_audit_trail_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_audit_trail"
	},
	voyce_audit_trail_stddev_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_audit_trail_stddev_pop_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_audit_trail_stddev_samp_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_audit_trail_sum_fields:{
		id:"Int",
		user_id:"Int"
	},
	voyce_audit_trail_var_pop_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_audit_trail_var_samp_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_audit_trail_variance_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_author_profiles:{
		created_at:"timestamp",
		followers_count:"Int",
		id:"Int",
		last_activity_date:"timestamptz",
		likes_count:"Int",
		outdated:"Boolean",
		popularity:"Int",
		total_popularity:"Int",
		user:"voyce_users",
		user_id:"Int",
		views_count:"Int"
	},
	voyce_author_profiles_aggregate:{
		aggregate:"voyce_author_profiles_aggregate_fields",
		nodes:"voyce_author_profiles"
	},
	voyce_author_profiles_aggregate_fields:{
		avg:"voyce_author_profiles_avg_fields",
		count:"Int",
		max:"voyce_author_profiles_max_fields",
		min:"voyce_author_profiles_min_fields",
		stddev:"voyce_author_profiles_stddev_fields",
		stddev_pop:"voyce_author_profiles_stddev_pop_fields",
		stddev_samp:"voyce_author_profiles_stddev_samp_fields",
		sum:"voyce_author_profiles_sum_fields",
		var_pop:"voyce_author_profiles_var_pop_fields",
		var_samp:"voyce_author_profiles_var_samp_fields",
		variance:"voyce_author_profiles_variance_fields"
	},
	voyce_author_profiles_avg_fields:{
		followers_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		total_popularity:"Float",
		user_id:"Float",
		views_count:"Float"
	},
	voyce_author_profiles_max_fields:{
		created_at:"timestamp",
		followers_count:"Int",
		id:"Int",
		last_activity_date:"timestamptz",
		likes_count:"Int",
		popularity:"Int",
		total_popularity:"Int",
		user_id:"Int",
		views_count:"Int"
	},
	voyce_author_profiles_min_fields:{
		created_at:"timestamp",
		followers_count:"Int",
		id:"Int",
		last_activity_date:"timestamptz",
		likes_count:"Int",
		popularity:"Int",
		total_popularity:"Int",
		user_id:"Int",
		views_count:"Int"
	},
	voyce_author_profiles_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_author_profiles"
	},
	voyce_author_profiles_stddev_fields:{
		followers_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		total_popularity:"Float",
		user_id:"Float",
		views_count:"Float"
	},
	voyce_author_profiles_stddev_pop_fields:{
		followers_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		total_popularity:"Float",
		user_id:"Float",
		views_count:"Float"
	},
	voyce_author_profiles_stddev_samp_fields:{
		followers_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		total_popularity:"Float",
		user_id:"Float",
		views_count:"Float"
	},
	voyce_author_profiles_sum_fields:{
		followers_count:"Int",
		id:"Int",
		likes_count:"Int",
		popularity:"Int",
		total_popularity:"Int",
		user_id:"Int",
		views_count:"Int"
	},
	voyce_author_profiles_var_pop_fields:{
		followers_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		total_popularity:"Float",
		user_id:"Float",
		views_count:"Float"
	},
	voyce_author_profiles_var_samp_fields:{
		followers_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		total_popularity:"Float",
		user_id:"Float",
		views_count:"Float"
	},
	voyce_author_profiles_variance_fields:{
		followers_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		total_popularity:"Float",
		user_id:"Float",
		views_count:"Float"
	},
	voyce_balances:{
		amount:"Int",
		created_at:"timestamp",
		id:"Int",
		transferable_amount:"Int",
		type:"String",
		updated_at:"timestamp",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_balances_aggregate:{
		aggregate:"voyce_balances_aggregate_fields",
		nodes:"voyce_balances"
	},
	voyce_balances_aggregate_fields:{
		avg:"voyce_balances_avg_fields",
		count:"Int",
		max:"voyce_balances_max_fields",
		min:"voyce_balances_min_fields",
		stddev:"voyce_balances_stddev_fields",
		stddev_pop:"voyce_balances_stddev_pop_fields",
		stddev_samp:"voyce_balances_stddev_samp_fields",
		sum:"voyce_balances_sum_fields",
		var_pop:"voyce_balances_var_pop_fields",
		var_samp:"voyce_balances_var_samp_fields",
		variance:"voyce_balances_variance_fields"
	},
	voyce_balances_avg_fields:{
		amount:"Float",
		id:"Float",
		transferable_amount:"Float",
		user_id:"Float"
	},
	voyce_balances_max_fields:{
		amount:"Int",
		created_at:"timestamp",
		id:"Int",
		transferable_amount:"Int",
		type:"String",
		updated_at:"timestamp",
		user_id:"Int"
	},
	voyce_balances_min_fields:{
		amount:"Int",
		created_at:"timestamp",
		id:"Int",
		transferable_amount:"Int",
		type:"String",
		updated_at:"timestamp",
		user_id:"Int"
	},
	voyce_balances_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_balances"
	},
	voyce_balances_stddev_fields:{
		amount:"Float",
		id:"Float",
		transferable_amount:"Float",
		user_id:"Float"
	},
	voyce_balances_stddev_pop_fields:{
		amount:"Float",
		id:"Float",
		transferable_amount:"Float",
		user_id:"Float"
	},
	voyce_balances_stddev_samp_fields:{
		amount:"Float",
		id:"Float",
		transferable_amount:"Float",
		user_id:"Float"
	},
	voyce_balances_sum_fields:{
		amount:"Int",
		id:"Int",
		transferable_amount:"Int",
		user_id:"Int"
	},
	voyce_balances_var_pop_fields:{
		amount:"Float",
		id:"Float",
		transferable_amount:"Float",
		user_id:"Float"
	},
	voyce_balances_var_samp_fields:{
		amount:"Float",
		id:"Float",
		transferable_amount:"Float",
		user_id:"Float"
	},
	voyce_balances_variance_fields:{
		amount:"Float",
		id:"Float",
		transferable_amount:"Float",
		user_id:"Float"
	},
	voyce_banners:{
		back_image:"String",
		front_image:"String",
		id:"Int",
		order:"Int",
		series:"voyce_series",
		series_id:"Int",
		subtitle:"String",
		tag:"Int"
	},
	voyce_banners_aggregate:{
		aggregate:"voyce_banners_aggregate_fields",
		nodes:"voyce_banners"
	},
	voyce_banners_aggregate_fields:{
		avg:"voyce_banners_avg_fields",
		count:"Int",
		max:"voyce_banners_max_fields",
		min:"voyce_banners_min_fields",
		stddev:"voyce_banners_stddev_fields",
		stddev_pop:"voyce_banners_stddev_pop_fields",
		stddev_samp:"voyce_banners_stddev_samp_fields",
		sum:"voyce_banners_sum_fields",
		var_pop:"voyce_banners_var_pop_fields",
		var_samp:"voyce_banners_var_samp_fields",
		variance:"voyce_banners_variance_fields"
	},
	voyce_banners_avg_fields:{
		id:"Float",
		order:"Float",
		series_id:"Float",
		tag:"Float"
	},
	voyce_banners_max_fields:{
		back_image:"String",
		front_image:"String",
		id:"Int",
		order:"Int",
		series_id:"Int",
		subtitle:"String",
		tag:"Int"
	},
	voyce_banners_min_fields:{
		back_image:"String",
		front_image:"String",
		id:"Int",
		order:"Int",
		series_id:"Int",
		subtitle:"String",
		tag:"Int"
	},
	voyce_banners_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_banners"
	},
	voyce_banners_stddev_fields:{
		id:"Float",
		order:"Float",
		series_id:"Float",
		tag:"Float"
	},
	voyce_banners_stddev_pop_fields:{
		id:"Float",
		order:"Float",
		series_id:"Float",
		tag:"Float"
	},
	voyce_banners_stddev_samp_fields:{
		id:"Float",
		order:"Float",
		series_id:"Float",
		tag:"Float"
	},
	voyce_banners_sum_fields:{
		id:"Int",
		order:"Int",
		series_id:"Int",
		tag:"Int"
	},
	voyce_banners_var_pop_fields:{
		id:"Float",
		order:"Float",
		series_id:"Float",
		tag:"Float"
	},
	voyce_banners_var_samp_fields:{
		id:"Float",
		order:"Float",
		series_id:"Float",
		tag:"Float"
	},
	voyce_banners_variance_fields:{
		id:"Float",
		order:"Float",
		series_id:"Float",
		tag:"Float"
	},
	voyce_chapter_assets:{
		chapter_effect:"voyce_chapter_effects",
		chapter_effect_id:"Int",
		id:"Int",
		order:"Int",
		type:"String",
		url:"String"
	},
	voyce_chapter_assets_aggregate:{
		aggregate:"voyce_chapter_assets_aggregate_fields",
		nodes:"voyce_chapter_assets"
	},
	voyce_chapter_assets_aggregate_fields:{
		avg:"voyce_chapter_assets_avg_fields",
		count:"Int",
		max:"voyce_chapter_assets_max_fields",
		min:"voyce_chapter_assets_min_fields",
		stddev:"voyce_chapter_assets_stddev_fields",
		stddev_pop:"voyce_chapter_assets_stddev_pop_fields",
		stddev_samp:"voyce_chapter_assets_stddev_samp_fields",
		sum:"voyce_chapter_assets_sum_fields",
		var_pop:"voyce_chapter_assets_var_pop_fields",
		var_samp:"voyce_chapter_assets_var_samp_fields",
		variance:"voyce_chapter_assets_variance_fields"
	},
	voyce_chapter_assets_avg_fields:{
		chapter_effect_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_assets_max_fields:{
		chapter_effect_id:"Int",
		id:"Int",
		order:"Int",
		type:"String",
		url:"String"
	},
	voyce_chapter_assets_min_fields:{
		chapter_effect_id:"Int",
		id:"Int",
		order:"Int",
		type:"String",
		url:"String"
	},
	voyce_chapter_assets_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_assets"
	},
	voyce_chapter_assets_stddev_fields:{
		chapter_effect_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_assets_stddev_pop_fields:{
		chapter_effect_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_assets_stddev_samp_fields:{
		chapter_effect_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_assets_sum_fields:{
		chapter_effect_id:"Int",
		id:"Int",
		order:"Int"
	},
	voyce_chapter_assets_var_pop_fields:{
		chapter_effect_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_assets_var_samp_fields:{
		chapter_effect_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_assets_variance_fields:{
		chapter_effect_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_bookmarks:{
		chapter_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		series:"voyce_series",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_bookmarks_aggregate:{
		aggregate:"voyce_chapter_bookmarks_aggregate_fields",
		nodes:"voyce_chapter_bookmarks"
	},
	voyce_chapter_bookmarks_aggregate_fields:{
		avg:"voyce_chapter_bookmarks_avg_fields",
		count:"Int",
		max:"voyce_chapter_bookmarks_max_fields",
		min:"voyce_chapter_bookmarks_min_fields",
		stddev:"voyce_chapter_bookmarks_stddev_fields",
		stddev_pop:"voyce_chapter_bookmarks_stddev_pop_fields",
		stddev_samp:"voyce_chapter_bookmarks_stddev_samp_fields",
		sum:"voyce_chapter_bookmarks_sum_fields",
		var_pop:"voyce_chapter_bookmarks_var_pop_fields",
		var_samp:"voyce_chapter_bookmarks_var_samp_fields",
		variance:"voyce_chapter_bookmarks_variance_fields"
	},
	voyce_chapter_bookmarks_avg_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_bookmarks_max_fields:{
		chapter_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_bookmarks_min_fields:{
		chapter_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_bookmarks_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_bookmarks"
	},
	voyce_chapter_bookmarks_stddev_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_bookmarks_stddev_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_bookmarks_stddev_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_bookmarks_sum_fields:{
		chapter_id:"Int",
		id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_bookmarks_var_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_bookmarks_var_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_bookmarks_variance_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments:{
		animated_image:"String",
		attachment_url:"String",
		chapter:"voyce_chapters",
		chapter_comment:"voyce_chapter_comments",
		chapter_comments:"voyce_chapter_comments",
		chapter_comments_aggregate:"voyce_chapter_comments_aggregate",
		chapter_comments_reactions:"voyce_chapter_comments_reaction",
		chapter_comments_reactions_aggregate:"voyce_chapter_comments_reaction_aggregate",
		chapter_comments_reports:"voyce_chapter_comments_reports",
		chapter_comments_reports_aggregate:"voyce_chapter_comments_reports_aggregate",
		chapter_id:"Int",
		comment:"String",
		created_at:"timestamptz",
		id:"Int",
		last_reacted_at:"timestamptz",
		last_reaction_count:"Int",
		original_comment_id:"Int",
		reaction_count:"Int",
		reactions:"voyce_chapter_comments_reaction_count",
		reactions_aggregate:"voyce_chapter_comments_reaction_count_aggregate",
		replies_count:"Int",
		series:"voyce_series",
		series_id:"Int",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_chapter_comments_07252024:{
		animated_image:"String",
		attachment_url:"String",
		chapter_id:"Int",
		comment:"String",
		created_at:"timestamptz",
		id:"Int",
		last_reacted_at:"timestamptz",
		last_reaction_count:"Int",
		original_comment_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_comments_07252024_aggregate:{
		aggregate:"voyce_chapter_comments_07252024_aggregate_fields",
		nodes:"voyce_chapter_comments_07252024"
	},
	voyce_chapter_comments_07252024_aggregate_fields:{
		avg:"voyce_chapter_comments_07252024_avg_fields",
		count:"Int",
		max:"voyce_chapter_comments_07252024_max_fields",
		min:"voyce_chapter_comments_07252024_min_fields",
		stddev:"voyce_chapter_comments_07252024_stddev_fields",
		stddev_pop:"voyce_chapter_comments_07252024_stddev_pop_fields",
		stddev_samp:"voyce_chapter_comments_07252024_stddev_samp_fields",
		sum:"voyce_chapter_comments_07252024_sum_fields",
		var_pop:"voyce_chapter_comments_07252024_var_pop_fields",
		var_samp:"voyce_chapter_comments_07252024_var_samp_fields",
		variance:"voyce_chapter_comments_07252024_variance_fields"
	},
	voyce_chapter_comments_07252024_avg_fields:{
		chapter_id:"Float",
		id:"Float",
		last_reaction_count:"Float",
		original_comment_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_07252024_max_fields:{
		animated_image:"String",
		attachment_url:"String",
		chapter_id:"Int",
		comment:"String",
		created_at:"timestamptz",
		id:"Int",
		last_reacted_at:"timestamptz",
		last_reaction_count:"Int",
		original_comment_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_comments_07252024_min_fields:{
		animated_image:"String",
		attachment_url:"String",
		chapter_id:"Int",
		comment:"String",
		created_at:"timestamptz",
		id:"Int",
		last_reacted_at:"timestamptz",
		last_reaction_count:"Int",
		original_comment_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_comments_07252024_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_comments_07252024"
	},
	voyce_chapter_comments_07252024_stddev_fields:{
		chapter_id:"Float",
		id:"Float",
		last_reaction_count:"Float",
		original_comment_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_07252024_stddev_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		last_reaction_count:"Float",
		original_comment_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_07252024_stddev_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		last_reaction_count:"Float",
		original_comment_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_07252024_sum_fields:{
		chapter_id:"Int",
		id:"Int",
		last_reaction_count:"Int",
		original_comment_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_comments_07252024_var_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		last_reaction_count:"Float",
		original_comment_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_07252024_var_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		last_reaction_count:"Float",
		original_comment_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_07252024_variance_fields:{
		chapter_id:"Float",
		id:"Float",
		last_reaction_count:"Float",
		original_comment_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_aggregate:{
		aggregate:"voyce_chapter_comments_aggregate_fields",
		nodes:"voyce_chapter_comments"
	},
	voyce_chapter_comments_aggregate_fields:{
		avg:"voyce_chapter_comments_avg_fields",
		count:"Int",
		max:"voyce_chapter_comments_max_fields",
		min:"voyce_chapter_comments_min_fields",
		stddev:"voyce_chapter_comments_stddev_fields",
		stddev_pop:"voyce_chapter_comments_stddev_pop_fields",
		stddev_samp:"voyce_chapter_comments_stddev_samp_fields",
		sum:"voyce_chapter_comments_sum_fields",
		var_pop:"voyce_chapter_comments_var_pop_fields",
		var_samp:"voyce_chapter_comments_var_samp_fields",
		variance:"voyce_chapter_comments_variance_fields"
	},
	voyce_chapter_comments_avg_fields:{
		chapter_id:"Float",
		id:"Float",
		last_reaction_count:"Float",
		original_comment_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_max_fields:{
		animated_image:"String",
		attachment_url:"String",
		chapter_id:"Int",
		comment:"String",
		created_at:"timestamptz",
		id:"Int",
		last_reacted_at:"timestamptz",
		last_reaction_count:"Int",
		original_comment_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_comments_min_fields:{
		animated_image:"String",
		attachment_url:"String",
		chapter_id:"Int",
		comment:"String",
		created_at:"timestamptz",
		id:"Int",
		last_reacted_at:"timestamptz",
		last_reaction_count:"Int",
		original_comment_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_comments_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_comments"
	},
	voyce_chapter_comments_reaction:{
		chapter_comment:"voyce_chapter_comments",
		comment_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		reaction:"Int",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_chapter_comments_reaction_aggregate:{
		aggregate:"voyce_chapter_comments_reaction_aggregate_fields",
		nodes:"voyce_chapter_comments_reaction"
	},
	voyce_chapter_comments_reaction_aggregate_fields:{
		avg:"voyce_chapter_comments_reaction_avg_fields",
		count:"Int",
		max:"voyce_chapter_comments_reaction_max_fields",
		min:"voyce_chapter_comments_reaction_min_fields",
		stddev:"voyce_chapter_comments_reaction_stddev_fields",
		stddev_pop:"voyce_chapter_comments_reaction_stddev_pop_fields",
		stddev_samp:"voyce_chapter_comments_reaction_stddev_samp_fields",
		sum:"voyce_chapter_comments_reaction_sum_fields",
		var_pop:"voyce_chapter_comments_reaction_var_pop_fields",
		var_samp:"voyce_chapter_comments_reaction_var_samp_fields",
		variance:"voyce_chapter_comments_reaction_variance_fields"
	},
	voyce_chapter_comments_reaction_avg_fields:{
		comment_id:"Float",
		id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_reaction_count:{
		comment_id:"Int",
		count:"Int",
		id:"Int",
		reaction:"Int"
	},
	voyce_chapter_comments_reaction_count_aggregate:{
		aggregate:"voyce_chapter_comments_reaction_count_aggregate_fields",
		nodes:"voyce_chapter_comments_reaction_count"
	},
	voyce_chapter_comments_reaction_count_aggregate_fields:{
		avg:"voyce_chapter_comments_reaction_count_avg_fields",
		count:"Int",
		max:"voyce_chapter_comments_reaction_count_max_fields",
		min:"voyce_chapter_comments_reaction_count_min_fields",
		stddev:"voyce_chapter_comments_reaction_count_stddev_fields",
		stddev_pop:"voyce_chapter_comments_reaction_count_stddev_pop_fields",
		stddev_samp:"voyce_chapter_comments_reaction_count_stddev_samp_fields",
		sum:"voyce_chapter_comments_reaction_count_sum_fields",
		var_pop:"voyce_chapter_comments_reaction_count_var_pop_fields",
		var_samp:"voyce_chapter_comments_reaction_count_var_samp_fields",
		variance:"voyce_chapter_comments_reaction_count_variance_fields"
	},
	voyce_chapter_comments_reaction_count_avg_fields:{
		comment_id:"Float",
		count:"Float",
		id:"Float",
		reaction:"Float"
	},
	voyce_chapter_comments_reaction_count_max_fields:{
		comment_id:"Int",
		count:"Int",
		id:"Int",
		reaction:"Int"
	},
	voyce_chapter_comments_reaction_count_min_fields:{
		comment_id:"Int",
		count:"Int",
		id:"Int",
		reaction:"Int"
	},
	voyce_chapter_comments_reaction_count_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_comments_reaction_count"
	},
	voyce_chapter_comments_reaction_count_stddev_fields:{
		comment_id:"Float",
		count:"Float",
		id:"Float",
		reaction:"Float"
	},
	voyce_chapter_comments_reaction_count_stddev_pop_fields:{
		comment_id:"Float",
		count:"Float",
		id:"Float",
		reaction:"Float"
	},
	voyce_chapter_comments_reaction_count_stddev_samp_fields:{
		comment_id:"Float",
		count:"Float",
		id:"Float",
		reaction:"Float"
	},
	voyce_chapter_comments_reaction_count_sum_fields:{
		comment_id:"Int",
		count:"Int",
		id:"Int",
		reaction:"Int"
	},
	voyce_chapter_comments_reaction_count_var_pop_fields:{
		comment_id:"Float",
		count:"Float",
		id:"Float",
		reaction:"Float"
	},
	voyce_chapter_comments_reaction_count_var_samp_fields:{
		comment_id:"Float",
		count:"Float",
		id:"Float",
		reaction:"Float"
	},
	voyce_chapter_comments_reaction_count_variance_fields:{
		comment_id:"Float",
		count:"Float",
		id:"Float",
		reaction:"Float"
	},
	voyce_chapter_comments_reaction_max_fields:{
		comment_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		reaction:"Int",
		user_id:"Int"
	},
	voyce_chapter_comments_reaction_min_fields:{
		comment_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		reaction:"Int",
		user_id:"Int"
	},
	voyce_chapter_comments_reaction_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_comments_reaction"
	},
	voyce_chapter_comments_reaction_stddev_fields:{
		comment_id:"Float",
		id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_reaction_stddev_pop_fields:{
		comment_id:"Float",
		id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_reaction_stddev_samp_fields:{
		comment_id:"Float",
		id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_reaction_sum_fields:{
		comment_id:"Int",
		id:"Int",
		reaction:"Int",
		user_id:"Int"
	},
	voyce_chapter_comments_reaction_var_pop_fields:{
		comment_id:"Float",
		id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_reaction_var_samp_fields:{
		comment_id:"Float",
		id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_reaction_variance_fields:{
		comment_id:"Float",
		id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_reports:{
		chapter_comment:"voyce_chapter_comments",
		comment_id:"Int",
		id:"Int",
		message:"String",
		type_id:"Int",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_chapter_comments_reports_aggregate:{
		aggregate:"voyce_chapter_comments_reports_aggregate_fields",
		nodes:"voyce_chapter_comments_reports"
	},
	voyce_chapter_comments_reports_aggregate_fields:{
		avg:"voyce_chapter_comments_reports_avg_fields",
		count:"Int",
		max:"voyce_chapter_comments_reports_max_fields",
		min:"voyce_chapter_comments_reports_min_fields",
		stddev:"voyce_chapter_comments_reports_stddev_fields",
		stddev_pop:"voyce_chapter_comments_reports_stddev_pop_fields",
		stddev_samp:"voyce_chapter_comments_reports_stddev_samp_fields",
		sum:"voyce_chapter_comments_reports_sum_fields",
		var_pop:"voyce_chapter_comments_reports_var_pop_fields",
		var_samp:"voyce_chapter_comments_reports_var_samp_fields",
		variance:"voyce_chapter_comments_reports_variance_fields"
	},
	voyce_chapter_comments_reports_avg_fields:{
		comment_id:"Float",
		id:"Float",
		type_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_reports_max_fields:{
		comment_id:"Int",
		id:"Int",
		message:"String",
		type_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_comments_reports_min_fields:{
		comment_id:"Int",
		id:"Int",
		message:"String",
		type_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_comments_reports_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_comments_reports"
	},
	voyce_chapter_comments_reports_stddev_fields:{
		comment_id:"Float",
		id:"Float",
		type_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_reports_stddev_pop_fields:{
		comment_id:"Float",
		id:"Float",
		type_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_reports_stddev_samp_fields:{
		comment_id:"Float",
		id:"Float",
		type_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_reports_sum_fields:{
		comment_id:"Int",
		id:"Int",
		type_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_comments_reports_var_pop_fields:{
		comment_id:"Float",
		id:"Float",
		type_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_reports_var_samp_fields:{
		comment_id:"Float",
		id:"Float",
		type_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_reports_variance_fields:{
		comment_id:"Float",
		id:"Float",
		type_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_stddev_fields:{
		chapter_id:"Float",
		id:"Float",
		last_reaction_count:"Float",
		original_comment_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_stddev_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		last_reaction_count:"Float",
		original_comment_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_stddev_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		last_reaction_count:"Float",
		original_comment_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_sum_fields:{
		chapter_id:"Int",
		id:"Int",
		last_reaction_count:"Int",
		original_comment_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_comments_var_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		last_reaction_count:"Float",
		original_comment_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_var_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		last_reaction_count:"Float",
		original_comment_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_comments_variance_fields:{
		chapter_id:"Float",
		id:"Float",
		last_reaction_count:"Float",
		original_comment_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_contents:{
		chapter:"voyce_chapters",
		chapter_id:"Int",
		content:"String",
		content_short:"String",
		created_at:"timestamptz",
		id:"Int",
		mobile_content:"String",
		mobile_content_old:"String",
		order:"Int",
		updated_at:"timestamptz"
	},
	voyce_chapter_contents_aggregate:{
		aggregate:"voyce_chapter_contents_aggregate_fields",
		nodes:"voyce_chapter_contents"
	},
	voyce_chapter_contents_aggregate_fields:{
		avg:"voyce_chapter_contents_avg_fields",
		count:"Int",
		max:"voyce_chapter_contents_max_fields",
		min:"voyce_chapter_contents_min_fields",
		stddev:"voyce_chapter_contents_stddev_fields",
		stddev_pop:"voyce_chapter_contents_stddev_pop_fields",
		stddev_samp:"voyce_chapter_contents_stddev_samp_fields",
		sum:"voyce_chapter_contents_sum_fields",
		var_pop:"voyce_chapter_contents_var_pop_fields",
		var_samp:"voyce_chapter_contents_var_samp_fields",
		variance:"voyce_chapter_contents_variance_fields"
	},
	voyce_chapter_contents_avg_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_contents_max_fields:{
		chapter_id:"Int",
		content:"String",
		content_short:"String",
		created_at:"timestamptz",
		id:"Int",
		mobile_content:"String",
		mobile_content_old:"String",
		order:"Int",
		updated_at:"timestamptz"
	},
	voyce_chapter_contents_min_fields:{
		chapter_id:"Int",
		content:"String",
		content_short:"String",
		created_at:"timestamptz",
		id:"Int",
		mobile_content:"String",
		mobile_content_old:"String",
		order:"Int",
		updated_at:"timestamptz"
	},
	voyce_chapter_contents_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_contents"
	},
	voyce_chapter_contents_old:{
		chapter:"voyce_chapters",
		chapter_id:"Int",
		content:"String",
		created_at:"timestamptz",
		id:"Int",
		mobile_content:"String",
		mobile_content_old:"String",
		order:"Int",
		updated_at:"timestamptz"
	},
	voyce_chapter_contents_old_aggregate:{
		aggregate:"voyce_chapter_contents_old_aggregate_fields",
		nodes:"voyce_chapter_contents_old"
	},
	voyce_chapter_contents_old_aggregate_fields:{
		avg:"voyce_chapter_contents_old_avg_fields",
		count:"Int",
		max:"voyce_chapter_contents_old_max_fields",
		min:"voyce_chapter_contents_old_min_fields",
		stddev:"voyce_chapter_contents_old_stddev_fields",
		stddev_pop:"voyce_chapter_contents_old_stddev_pop_fields",
		stddev_samp:"voyce_chapter_contents_old_stddev_samp_fields",
		sum:"voyce_chapter_contents_old_sum_fields",
		var_pop:"voyce_chapter_contents_old_var_pop_fields",
		var_samp:"voyce_chapter_contents_old_var_samp_fields",
		variance:"voyce_chapter_contents_old_variance_fields"
	},
	voyce_chapter_contents_old_avg_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_contents_old_max_fields:{
		chapter_id:"Int",
		content:"String",
		created_at:"timestamptz",
		id:"Int",
		mobile_content:"String",
		mobile_content_old:"String",
		order:"Int",
		updated_at:"timestamptz"
	},
	voyce_chapter_contents_old_min_fields:{
		chapter_id:"Int",
		content:"String",
		created_at:"timestamptz",
		id:"Int",
		mobile_content:"String",
		mobile_content_old:"String",
		order:"Int",
		updated_at:"timestamptz"
	},
	voyce_chapter_contents_old_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_contents_old"
	},
	voyce_chapter_contents_old_stddev_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_contents_old_stddev_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_contents_old_stddev_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_contents_old_sum_fields:{
		chapter_id:"Int",
		id:"Int",
		order:"Int"
	},
	voyce_chapter_contents_old_var_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_contents_old_var_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_contents_old_variance_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_contents_stddev_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_contents_stddev_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_contents_stddev_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_contents_sum_fields:{
		chapter_id:"Int",
		id:"Int",
		order:"Int"
	},
	voyce_chapter_contents_var_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_contents_var_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_contents_variance_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_chapter_effects:{
		animation_offset:"Int",
		chapter:"voyce_chapters",
		chapter_assets:"voyce_chapter_assets",
		chapter_assets_aggregate:"voyce_chapter_assets_aggregate",
		chapter_id:"Int",
		chapter_image:"voyce_chapter_images",
		chapter_img_id:"Int",
		duration:"Int",
		effect_type:"Int",
		end_x:"Int",
		end_y:"Int",
		id:"Int",
		strength:"Int"
	},
	voyce_chapter_effects_aggregate:{
		aggregate:"voyce_chapter_effects_aggregate_fields",
		nodes:"voyce_chapter_effects"
	},
	voyce_chapter_effects_aggregate_fields:{
		avg:"voyce_chapter_effects_avg_fields",
		count:"Int",
		max:"voyce_chapter_effects_max_fields",
		min:"voyce_chapter_effects_min_fields",
		stddev:"voyce_chapter_effects_stddev_fields",
		stddev_pop:"voyce_chapter_effects_stddev_pop_fields",
		stddev_samp:"voyce_chapter_effects_stddev_samp_fields",
		sum:"voyce_chapter_effects_sum_fields",
		var_pop:"voyce_chapter_effects_var_pop_fields",
		var_samp:"voyce_chapter_effects_var_samp_fields",
		variance:"voyce_chapter_effects_variance_fields"
	},
	voyce_chapter_effects_avg_fields:{
		animation_offset:"Float",
		chapter_id:"Float",
		chapter_img_id:"Float",
		duration:"Float",
		effect_type:"Float",
		end_x:"Float",
		end_y:"Float",
		id:"Float",
		strength:"Float"
	},
	voyce_chapter_effects_max_fields:{
		animation_offset:"Int",
		chapter_id:"Int",
		chapter_img_id:"Int",
		duration:"Int",
		effect_type:"Int",
		end_x:"Int",
		end_y:"Int",
		id:"Int",
		strength:"Int"
	},
	voyce_chapter_effects_min_fields:{
		animation_offset:"Int",
		chapter_id:"Int",
		chapter_img_id:"Int",
		duration:"Int",
		effect_type:"Int",
		end_x:"Int",
		end_y:"Int",
		id:"Int",
		strength:"Int"
	},
	voyce_chapter_effects_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_effects"
	},
	voyce_chapter_effects_stddev_fields:{
		animation_offset:"Float",
		chapter_id:"Float",
		chapter_img_id:"Float",
		duration:"Float",
		effect_type:"Float",
		end_x:"Float",
		end_y:"Float",
		id:"Float",
		strength:"Float"
	},
	voyce_chapter_effects_stddev_pop_fields:{
		animation_offset:"Float",
		chapter_id:"Float",
		chapter_img_id:"Float",
		duration:"Float",
		effect_type:"Float",
		end_x:"Float",
		end_y:"Float",
		id:"Float",
		strength:"Float"
	},
	voyce_chapter_effects_stddev_samp_fields:{
		animation_offset:"Float",
		chapter_id:"Float",
		chapter_img_id:"Float",
		duration:"Float",
		effect_type:"Float",
		end_x:"Float",
		end_y:"Float",
		id:"Float",
		strength:"Float"
	},
	voyce_chapter_effects_sum_fields:{
		animation_offset:"Int",
		chapter_id:"Int",
		chapter_img_id:"Int",
		duration:"Int",
		effect_type:"Int",
		end_x:"Int",
		end_y:"Int",
		id:"Int",
		strength:"Int"
	},
	voyce_chapter_effects_var_pop_fields:{
		animation_offset:"Float",
		chapter_id:"Float",
		chapter_img_id:"Float",
		duration:"Float",
		effect_type:"Float",
		end_x:"Float",
		end_y:"Float",
		id:"Float",
		strength:"Float"
	},
	voyce_chapter_effects_var_samp_fields:{
		animation_offset:"Float",
		chapter_id:"Float",
		chapter_img_id:"Float",
		duration:"Float",
		effect_type:"Float",
		end_x:"Float",
		end_y:"Float",
		id:"Float",
		strength:"Float"
	},
	voyce_chapter_effects_variance_fields:{
		animation_offset:"Float",
		chapter_id:"Float",
		chapter_img_id:"Float",
		duration:"Float",
		effect_type:"Float",
		end_x:"Float",
		end_y:"Float",
		id:"Float",
		strength:"Float"
	},
	voyce_chapter_images:{
		chapter:"voyce_chapters",
		chapter_effects:"voyce_chapter_effects",
		chapter_effects_aggregate:"voyce_chapter_effects_aggregate",
		chapter_id:"Int",
		chapter_panels:"voyce_chapter_panels",
		chapter_panels_aggregate:"voyce_chapter_panels_aggregate",
		created_at:"timestamp",
		group:"smallint",
		id:"Int",
		image:"String",
		sort_order:"Int",
		updated_at:"timestamp"
	},
	voyce_chapter_images_07252024:{
		chapter_id:"Int",
		created_at:"timestamp",
		group:"smallint",
		id:"Int",
		image:"String",
		sort_order:"Int",
		updated_at:"timestamp"
	},
	voyce_chapter_images_07252024_aggregate:{
		aggregate:"voyce_chapter_images_07252024_aggregate_fields",
		nodes:"voyce_chapter_images_07252024"
	},
	voyce_chapter_images_07252024_aggregate_fields:{
		avg:"voyce_chapter_images_07252024_avg_fields",
		count:"Int",
		max:"voyce_chapter_images_07252024_max_fields",
		min:"voyce_chapter_images_07252024_min_fields",
		stddev:"voyce_chapter_images_07252024_stddev_fields",
		stddev_pop:"voyce_chapter_images_07252024_stddev_pop_fields",
		stddev_samp:"voyce_chapter_images_07252024_stddev_samp_fields",
		sum:"voyce_chapter_images_07252024_sum_fields",
		var_pop:"voyce_chapter_images_07252024_var_pop_fields",
		var_samp:"voyce_chapter_images_07252024_var_samp_fields",
		variance:"voyce_chapter_images_07252024_variance_fields"
	},
	voyce_chapter_images_07252024_avg_fields:{
		chapter_id:"Float",
		group:"Float",
		id:"Float",
		sort_order:"Float"
	},
	voyce_chapter_images_07252024_max_fields:{
		chapter_id:"Int",
		created_at:"timestamp",
		group:"smallint",
		id:"Int",
		image:"String",
		sort_order:"Int",
		updated_at:"timestamp"
	},
	voyce_chapter_images_07252024_min_fields:{
		chapter_id:"Int",
		created_at:"timestamp",
		group:"smallint",
		id:"Int",
		image:"String",
		sort_order:"Int",
		updated_at:"timestamp"
	},
	voyce_chapter_images_07252024_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_images_07252024"
	},
	voyce_chapter_images_07252024_stddev_fields:{
		chapter_id:"Float",
		group:"Float",
		id:"Float",
		sort_order:"Float"
	},
	voyce_chapter_images_07252024_stddev_pop_fields:{
		chapter_id:"Float",
		group:"Float",
		id:"Float",
		sort_order:"Float"
	},
	voyce_chapter_images_07252024_stddev_samp_fields:{
		chapter_id:"Float",
		group:"Float",
		id:"Float",
		sort_order:"Float"
	},
	voyce_chapter_images_07252024_sum_fields:{
		chapter_id:"Int",
		group:"smallint",
		id:"Int",
		sort_order:"Int"
	},
	voyce_chapter_images_07252024_var_pop_fields:{
		chapter_id:"Float",
		group:"Float",
		id:"Float",
		sort_order:"Float"
	},
	voyce_chapter_images_07252024_var_samp_fields:{
		chapter_id:"Float",
		group:"Float",
		id:"Float",
		sort_order:"Float"
	},
	voyce_chapter_images_07252024_variance_fields:{
		chapter_id:"Float",
		group:"Float",
		id:"Float",
		sort_order:"Float"
	},
	voyce_chapter_images_aggregate:{
		aggregate:"voyce_chapter_images_aggregate_fields",
		nodes:"voyce_chapter_images"
	},
	voyce_chapter_images_aggregate_fields:{
		avg:"voyce_chapter_images_avg_fields",
		count:"Int",
		max:"voyce_chapter_images_max_fields",
		min:"voyce_chapter_images_min_fields",
		stddev:"voyce_chapter_images_stddev_fields",
		stddev_pop:"voyce_chapter_images_stddev_pop_fields",
		stddev_samp:"voyce_chapter_images_stddev_samp_fields",
		sum:"voyce_chapter_images_sum_fields",
		var_pop:"voyce_chapter_images_var_pop_fields",
		var_samp:"voyce_chapter_images_var_samp_fields",
		variance:"voyce_chapter_images_variance_fields"
	},
	voyce_chapter_images_avg_fields:{
		chapter_id:"Float",
		group:"Float",
		id:"Float",
		sort_order:"Float"
	},
	voyce_chapter_images_max_fields:{
		chapter_id:"Int",
		created_at:"timestamp",
		group:"smallint",
		id:"Int",
		image:"String",
		sort_order:"Int",
		updated_at:"timestamp"
	},
	voyce_chapter_images_min_fields:{
		chapter_id:"Int",
		created_at:"timestamp",
		group:"smallint",
		id:"Int",
		image:"String",
		sort_order:"Int",
		updated_at:"timestamp"
	},
	voyce_chapter_images_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_images"
	},
	voyce_chapter_images_stddev_fields:{
		chapter_id:"Float",
		group:"Float",
		id:"Float",
		sort_order:"Float"
	},
	voyce_chapter_images_stddev_pop_fields:{
		chapter_id:"Float",
		group:"Float",
		id:"Float",
		sort_order:"Float"
	},
	voyce_chapter_images_stddev_samp_fields:{
		chapter_id:"Float",
		group:"Float",
		id:"Float",
		sort_order:"Float"
	},
	voyce_chapter_images_sum_fields:{
		chapter_id:"Int",
		group:"smallint",
		id:"Int",
		sort_order:"Int"
	},
	voyce_chapter_images_var_pop_fields:{
		chapter_id:"Float",
		group:"Float",
		id:"Float",
		sort_order:"Float"
	},
	voyce_chapter_images_var_samp_fields:{
		chapter_id:"Float",
		group:"Float",
		id:"Float",
		sort_order:"Float"
	},
	voyce_chapter_images_variance_fields:{
		chapter_id:"Float",
		group:"Float",
		id:"Float",
		sort_order:"Float"
	},
	voyce_chapter_likes:{
		chapter:"voyce_chapters",
		chapter_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		is_poll_like:"Boolean",
		series_id:"Int",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_chapter_likes_aggregate:{
		aggregate:"voyce_chapter_likes_aggregate_fields",
		nodes:"voyce_chapter_likes"
	},
	voyce_chapter_likes_aggregate_fields:{
		avg:"voyce_chapter_likes_avg_fields",
		count:"Int",
		max:"voyce_chapter_likes_max_fields",
		min:"voyce_chapter_likes_min_fields",
		stddev:"voyce_chapter_likes_stddev_fields",
		stddev_pop:"voyce_chapter_likes_stddev_pop_fields",
		stddev_samp:"voyce_chapter_likes_stddev_samp_fields",
		sum:"voyce_chapter_likes_sum_fields",
		var_pop:"voyce_chapter_likes_var_pop_fields",
		var_samp:"voyce_chapter_likes_var_samp_fields",
		variance:"voyce_chapter_likes_variance_fields"
	},
	voyce_chapter_likes_avg_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_likes_max_fields:{
		chapter_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_likes_min_fields:{
		chapter_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_likes_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_likes"
	},
	voyce_chapter_likes_stddev_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_likes_stddev_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_likes_stddev_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_likes_sum_fields:{
		chapter_id:"Int",
		id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_likes_var_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_likes_var_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_likes_variance_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_locks:{
		buy_currency:"String",
		buy_price:"numeric",
		chapter:"voyce_chapters",
		chapter_id:"Int",
		created_at:"timestamptz",
		creator_id:"Int",
		end_date:"timestamptz",
		id:"Int",
		is_active:"Boolean",
		rent_currency:"String",
		rent_duration:"numeric",
		rent_price:"numeric",
		series:"voyce_series",
		series_id:"Int",
		start_date:"timestamptz",
		updated_at:"timestamptz"
	},
	voyce_chapter_locks_aggregate:{
		aggregate:"voyce_chapter_locks_aggregate_fields",
		nodes:"voyce_chapter_locks"
	},
	voyce_chapter_locks_aggregate_fields:{
		avg:"voyce_chapter_locks_avg_fields",
		count:"Int",
		max:"voyce_chapter_locks_max_fields",
		min:"voyce_chapter_locks_min_fields",
		stddev:"voyce_chapter_locks_stddev_fields",
		stddev_pop:"voyce_chapter_locks_stddev_pop_fields",
		stddev_samp:"voyce_chapter_locks_stddev_samp_fields",
		sum:"voyce_chapter_locks_sum_fields",
		var_pop:"voyce_chapter_locks_var_pop_fields",
		var_samp:"voyce_chapter_locks_var_samp_fields",
		variance:"voyce_chapter_locks_variance_fields"
	},
	voyce_chapter_locks_avg_fields:{
		buy_price:"Float",
		chapter_id:"Float",
		creator_id:"Float",
		id:"Float",
		rent_duration:"Float",
		rent_price:"Float",
		series_id:"Float"
	},
	voyce_chapter_locks_max_fields:{
		buy_currency:"String",
		buy_price:"numeric",
		chapter_id:"Int",
		created_at:"timestamptz",
		creator_id:"Int",
		end_date:"timestamptz",
		id:"Int",
		rent_currency:"String",
		rent_duration:"numeric",
		rent_price:"numeric",
		series_id:"Int",
		start_date:"timestamptz",
		updated_at:"timestamptz"
	},
	voyce_chapter_locks_min_fields:{
		buy_currency:"String",
		buy_price:"numeric",
		chapter_id:"Int",
		created_at:"timestamptz",
		creator_id:"Int",
		end_date:"timestamptz",
		id:"Int",
		rent_currency:"String",
		rent_duration:"numeric",
		rent_price:"numeric",
		series_id:"Int",
		start_date:"timestamptz",
		updated_at:"timestamptz"
	},
	voyce_chapter_locks_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_locks"
	},
	voyce_chapter_locks_stddev_fields:{
		buy_price:"Float",
		chapter_id:"Float",
		creator_id:"Float",
		id:"Float",
		rent_duration:"Float",
		rent_price:"Float",
		series_id:"Float"
	},
	voyce_chapter_locks_stddev_pop_fields:{
		buy_price:"Float",
		chapter_id:"Float",
		creator_id:"Float",
		id:"Float",
		rent_duration:"Float",
		rent_price:"Float",
		series_id:"Float"
	},
	voyce_chapter_locks_stddev_samp_fields:{
		buy_price:"Float",
		chapter_id:"Float",
		creator_id:"Float",
		id:"Float",
		rent_duration:"Float",
		rent_price:"Float",
		series_id:"Float"
	},
	voyce_chapter_locks_sum_fields:{
		buy_price:"numeric",
		chapter_id:"Int",
		creator_id:"Int",
		id:"Int",
		rent_duration:"numeric",
		rent_price:"numeric",
		series_id:"Int"
	},
	voyce_chapter_locks_var_pop_fields:{
		buy_price:"Float",
		chapter_id:"Float",
		creator_id:"Float",
		id:"Float",
		rent_duration:"Float",
		rent_price:"Float",
		series_id:"Float"
	},
	voyce_chapter_locks_var_samp_fields:{
		buy_price:"Float",
		chapter_id:"Float",
		creator_id:"Float",
		id:"Float",
		rent_duration:"Float",
		rent_price:"Float",
		series_id:"Float"
	},
	voyce_chapter_locks_variance_fields:{
		buy_price:"Float",
		chapter_id:"Float",
		creator_id:"Float",
		id:"Float",
		rent_duration:"Float",
		rent_price:"Float",
		series_id:"Float"
	},
	voyce_chapter_panel_views:{
		chapter:"voyce_chapters",
		chapter_id:"Int",
		current_panel:"Int",
		id:"Int",
		panel_id:"Int",
		panels:"bpchar",
		percentage:"Int",
		series:"voyce_series",
		series_id:"Int",
		total_panels:"Int",
		type:"String",
		updated_at:"timestamptz",
		user_id:"Int",
		word_idx:"Int"
	},
	voyce_chapter_panel_views_aggregate:{
		aggregate:"voyce_chapter_panel_views_aggregate_fields",
		nodes:"voyce_chapter_panel_views"
	},
	voyce_chapter_panel_views_aggregate_fields:{
		avg:"voyce_chapter_panel_views_avg_fields",
		count:"Int",
		max:"voyce_chapter_panel_views_max_fields",
		min:"voyce_chapter_panel_views_min_fields",
		stddev:"voyce_chapter_panel_views_stddev_fields",
		stddev_pop:"voyce_chapter_panel_views_stddev_pop_fields",
		stddev_samp:"voyce_chapter_panel_views_stddev_samp_fields",
		sum:"voyce_chapter_panel_views_sum_fields",
		var_pop:"voyce_chapter_panel_views_var_pop_fields",
		var_samp:"voyce_chapter_panel_views_var_samp_fields",
		variance:"voyce_chapter_panel_views_variance_fields"
	},
	voyce_chapter_panel_views_avg_fields:{
		chapter_id:"Float",
		current_panel:"Float",
		id:"Float",
		panel_id:"Float",
		percentage:"Float",
		series_id:"Float",
		total_panels:"Float",
		user_id:"Float",
		word_idx:"Float"
	},
	voyce_chapter_panel_views_max_fields:{
		chapter_id:"Int",
		current_panel:"Int",
		id:"Int",
		panel_id:"Int",
		panels:"bpchar",
		percentage:"Int",
		series_id:"Int",
		total_panels:"Int",
		type:"String",
		updated_at:"timestamptz",
		user_id:"Int",
		word_idx:"Int"
	},
	voyce_chapter_panel_views_min_fields:{
		chapter_id:"Int",
		current_panel:"Int",
		id:"Int",
		panel_id:"Int",
		panels:"bpchar",
		percentage:"Int",
		series_id:"Int",
		total_panels:"Int",
		type:"String",
		updated_at:"timestamptz",
		user_id:"Int",
		word_idx:"Int"
	},
	voyce_chapter_panel_views_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_panel_views"
	},
	voyce_chapter_panel_views_stddev_fields:{
		chapter_id:"Float",
		current_panel:"Float",
		id:"Float",
		panel_id:"Float",
		percentage:"Float",
		series_id:"Float",
		total_panels:"Float",
		user_id:"Float",
		word_idx:"Float"
	},
	voyce_chapter_panel_views_stddev_pop_fields:{
		chapter_id:"Float",
		current_panel:"Float",
		id:"Float",
		panel_id:"Float",
		percentage:"Float",
		series_id:"Float",
		total_panels:"Float",
		user_id:"Float",
		word_idx:"Float"
	},
	voyce_chapter_panel_views_stddev_samp_fields:{
		chapter_id:"Float",
		current_panel:"Float",
		id:"Float",
		panel_id:"Float",
		percentage:"Float",
		series_id:"Float",
		total_panels:"Float",
		user_id:"Float",
		word_idx:"Float"
	},
	voyce_chapter_panel_views_sum_fields:{
		chapter_id:"Int",
		current_panel:"Int",
		id:"Int",
		panel_id:"Int",
		percentage:"Int",
		series_id:"Int",
		total_panels:"Int",
		user_id:"Int",
		word_idx:"Int"
	},
	voyce_chapter_panel_views_var_pop_fields:{
		chapter_id:"Float",
		current_panel:"Float",
		id:"Float",
		panel_id:"Float",
		percentage:"Float",
		series_id:"Float",
		total_panels:"Float",
		user_id:"Float",
		word_idx:"Float"
	},
	voyce_chapter_panel_views_var_samp_fields:{
		chapter_id:"Float",
		current_panel:"Float",
		id:"Float",
		panel_id:"Float",
		percentage:"Float",
		series_id:"Float",
		total_panels:"Float",
		user_id:"Float",
		word_idx:"Float"
	},
	voyce_chapter_panel_views_variance_fields:{
		chapter_id:"Float",
		current_panel:"Float",
		id:"Float",
		panel_id:"Float",
		percentage:"Float",
		series_id:"Float",
		total_panels:"Float",
		user_id:"Float",
		word_idx:"Float"
	},
	voyce_chapter_panels:{
		chapter:"voyce_chapters",
		chapter_id:"Int",
		comments_count:"Int",
		created_at:"timestamptz",
		id:"Int",
		image:"String",
		image_id:"Int",
		last_activity_date:"timestamptz",
		likes_count:"Int",
		outdated:"Boolean",
		panels_comments:"voyce_panels_comments",
		panels_comments_aggregate:"voyce_panels_comments_aggregate",
		panels_reactions:"voyce_panels_reactions",
		panels_reactions_aggregate:"voyce_panels_reactions_aggregate",
		panels_reactions_counts:"voyce_panels_reactions_count",
		panels_reactions_counts_aggregate:"voyce_panels_reactions_count_aggregate",
		popularity:"Int",
		popularity_weekly:"Int",
		reactions_count:"Int",
		series:"voyce_series",
		series_id:"Int",
		spoiler:"Boolean",
		total_popularity:"Int"
	},
	voyce_chapter_panels_aggregate:{
		aggregate:"voyce_chapter_panels_aggregate_fields",
		nodes:"voyce_chapter_panels"
	},
	voyce_chapter_panels_aggregate_fields:{
		avg:"voyce_chapter_panels_avg_fields",
		count:"Int",
		max:"voyce_chapter_panels_max_fields",
		min:"voyce_chapter_panels_min_fields",
		stddev:"voyce_chapter_panels_stddev_fields",
		stddev_pop:"voyce_chapter_panels_stddev_pop_fields",
		stddev_samp:"voyce_chapter_panels_stddev_samp_fields",
		sum:"voyce_chapter_panels_sum_fields",
		var_pop:"voyce_chapter_panels_var_pop_fields",
		var_samp:"voyce_chapter_panels_var_samp_fields",
		variance:"voyce_chapter_panels_variance_fields"
	},
	voyce_chapter_panels_avg_fields:{
		chapter_id:"Float",
		comments_count:"Float",
		id:"Float",
		image_id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		series_id:"Float",
		total_popularity:"Float"
	},
	voyce_chapter_panels_max_fields:{
		chapter_id:"Int",
		comments_count:"Int",
		created_at:"timestamptz",
		id:"Int",
		image:"String",
		image_id:"Int",
		last_activity_date:"timestamptz",
		likes_count:"Int",
		popularity:"Int",
		popularity_weekly:"Int",
		reactions_count:"Int",
		series_id:"Int",
		total_popularity:"Int"
	},
	voyce_chapter_panels_min_fields:{
		chapter_id:"Int",
		comments_count:"Int",
		created_at:"timestamptz",
		id:"Int",
		image:"String",
		image_id:"Int",
		last_activity_date:"timestamptz",
		likes_count:"Int",
		popularity:"Int",
		popularity_weekly:"Int",
		reactions_count:"Int",
		series_id:"Int",
		total_popularity:"Int"
	},
	voyce_chapter_panels_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_panels"
	},
	voyce_chapter_panels_stddev_fields:{
		chapter_id:"Float",
		comments_count:"Float",
		id:"Float",
		image_id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		series_id:"Float",
		total_popularity:"Float"
	},
	voyce_chapter_panels_stddev_pop_fields:{
		chapter_id:"Float",
		comments_count:"Float",
		id:"Float",
		image_id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		series_id:"Float",
		total_popularity:"Float"
	},
	voyce_chapter_panels_stddev_samp_fields:{
		chapter_id:"Float",
		comments_count:"Float",
		id:"Float",
		image_id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		series_id:"Float",
		total_popularity:"Float"
	},
	voyce_chapter_panels_sum_fields:{
		chapter_id:"Int",
		comments_count:"Int",
		id:"Int",
		image_id:"Int",
		likes_count:"Int",
		popularity:"Int",
		popularity_weekly:"Int",
		reactions_count:"Int",
		series_id:"Int",
		total_popularity:"Int"
	},
	voyce_chapter_panels_var_pop_fields:{
		chapter_id:"Float",
		comments_count:"Float",
		id:"Float",
		image_id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		series_id:"Float",
		total_popularity:"Float"
	},
	voyce_chapter_panels_var_samp_fields:{
		chapter_id:"Float",
		comments_count:"Float",
		id:"Float",
		image_id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		series_id:"Float",
		total_popularity:"Float"
	},
	voyce_chapter_panels_variance_fields:{
		chapter_id:"Float",
		comments_count:"Float",
		id:"Float",
		image_id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		series_id:"Float",
		total_popularity:"Float"
	},
	voyce_chapter_publish_schedules:{
		chapter_id:"Int",
		created_at:"timestamptz",
		date:"date",
		epoch:"numeric",
		gmt_offset:"numeric",
		id:"Int",
		is_done:"numeric",
		publish_date:"timestamp",
		series:"voyce_series",
		series_id:"Int",
		time:"time"
	},
	voyce_chapter_publish_schedules_aggregate:{
		aggregate:"voyce_chapter_publish_schedules_aggregate_fields",
		nodes:"voyce_chapter_publish_schedules"
	},
	voyce_chapter_publish_schedules_aggregate_fields:{
		avg:"voyce_chapter_publish_schedules_avg_fields",
		count:"Int",
		max:"voyce_chapter_publish_schedules_max_fields",
		min:"voyce_chapter_publish_schedules_min_fields",
		stddev:"voyce_chapter_publish_schedules_stddev_fields",
		stddev_pop:"voyce_chapter_publish_schedules_stddev_pop_fields",
		stddev_samp:"voyce_chapter_publish_schedules_stddev_samp_fields",
		sum:"voyce_chapter_publish_schedules_sum_fields",
		var_pop:"voyce_chapter_publish_schedules_var_pop_fields",
		var_samp:"voyce_chapter_publish_schedules_var_samp_fields",
		variance:"voyce_chapter_publish_schedules_variance_fields"
	},
	voyce_chapter_publish_schedules_avg_fields:{
		chapter_id:"Float",
		epoch:"Float",
		gmt_offset:"Float",
		id:"Float",
		is_done:"Float",
		series_id:"Float"
	},
	voyce_chapter_publish_schedules_max_fields:{
		chapter_id:"Int",
		created_at:"timestamptz",
		date:"date",
		epoch:"numeric",
		gmt_offset:"numeric",
		id:"Int",
		is_done:"numeric",
		publish_date:"timestamp",
		series_id:"Int"
	},
	voyce_chapter_publish_schedules_min_fields:{
		chapter_id:"Int",
		created_at:"timestamptz",
		date:"date",
		epoch:"numeric",
		gmt_offset:"numeric",
		id:"Int",
		is_done:"numeric",
		publish_date:"timestamp",
		series_id:"Int"
	},
	voyce_chapter_publish_schedules_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_publish_schedules"
	},
	voyce_chapter_publish_schedules_stddev_fields:{
		chapter_id:"Float",
		epoch:"Float",
		gmt_offset:"Float",
		id:"Float",
		is_done:"Float",
		series_id:"Float"
	},
	voyce_chapter_publish_schedules_stddev_pop_fields:{
		chapter_id:"Float",
		epoch:"Float",
		gmt_offset:"Float",
		id:"Float",
		is_done:"Float",
		series_id:"Float"
	},
	voyce_chapter_publish_schedules_stddev_samp_fields:{
		chapter_id:"Float",
		epoch:"Float",
		gmt_offset:"Float",
		id:"Float",
		is_done:"Float",
		series_id:"Float"
	},
	voyce_chapter_publish_schedules_sum_fields:{
		chapter_id:"Int",
		epoch:"numeric",
		gmt_offset:"numeric",
		id:"Int",
		is_done:"numeric",
		series_id:"Int"
	},
	voyce_chapter_publish_schedules_var_pop_fields:{
		chapter_id:"Float",
		epoch:"Float",
		gmt_offset:"Float",
		id:"Float",
		is_done:"Float",
		series_id:"Float"
	},
	voyce_chapter_publish_schedules_var_samp_fields:{
		chapter_id:"Float",
		epoch:"Float",
		gmt_offset:"Float",
		id:"Float",
		is_done:"Float",
		series_id:"Float"
	},
	voyce_chapter_publish_schedules_variance_fields:{
		chapter_id:"Float",
		epoch:"Float",
		gmt_offset:"Float",
		id:"Float",
		is_done:"Float",
		series_id:"Float"
	},
	voyce_chapter_reactions:{
		chapter:"voyce_chapters",
		created_at:"timestamptz",
		id:"Int",
		linked_entity_id:"Int",
		reaction:"Int",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_chapter_reactions_aggregate:{
		aggregate:"voyce_chapter_reactions_aggregate_fields",
		nodes:"voyce_chapter_reactions"
	},
	voyce_chapter_reactions_aggregate_fields:{
		avg:"voyce_chapter_reactions_avg_fields",
		count:"Int",
		max:"voyce_chapter_reactions_max_fields",
		min:"voyce_chapter_reactions_min_fields",
		stddev:"voyce_chapter_reactions_stddev_fields",
		stddev_pop:"voyce_chapter_reactions_stddev_pop_fields",
		stddev_samp:"voyce_chapter_reactions_stddev_samp_fields",
		sum:"voyce_chapter_reactions_sum_fields",
		var_pop:"voyce_chapter_reactions_var_pop_fields",
		var_samp:"voyce_chapter_reactions_var_samp_fields",
		variance:"voyce_chapter_reactions_variance_fields"
	},
	voyce_chapter_reactions_avg_fields:{
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_chapter_reactions_count:{
		chapter:"voyce_chapters",
		chapter_id:"Int",
		count:"Int",
		id:"Int",
		reaction:"Int"
	},
	voyce_chapter_reactions_count_aggregate:{
		aggregate:"voyce_chapter_reactions_count_aggregate_fields",
		nodes:"voyce_chapter_reactions_count"
	},
	voyce_chapter_reactions_count_aggregate_fields:{
		avg:"voyce_chapter_reactions_count_avg_fields",
		count:"Int",
		max:"voyce_chapter_reactions_count_max_fields",
		min:"voyce_chapter_reactions_count_min_fields",
		stddev:"voyce_chapter_reactions_count_stddev_fields",
		stddev_pop:"voyce_chapter_reactions_count_stddev_pop_fields",
		stddev_samp:"voyce_chapter_reactions_count_stddev_samp_fields",
		sum:"voyce_chapter_reactions_count_sum_fields",
		var_pop:"voyce_chapter_reactions_count_var_pop_fields",
		var_samp:"voyce_chapter_reactions_count_var_samp_fields",
		variance:"voyce_chapter_reactions_count_variance_fields"
	},
	voyce_chapter_reactions_count_avg_fields:{
		chapter_id:"Float",
		count:"Float",
		id:"Float",
		reaction:"Float"
	},
	voyce_chapter_reactions_count_max_fields:{
		chapter_id:"Int",
		count:"Int",
		id:"Int",
		reaction:"Int"
	},
	voyce_chapter_reactions_count_min_fields:{
		chapter_id:"Int",
		count:"Int",
		id:"Int",
		reaction:"Int"
	},
	voyce_chapter_reactions_count_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_reactions_count"
	},
	voyce_chapter_reactions_count_stddev_fields:{
		chapter_id:"Float",
		count:"Float",
		id:"Float",
		reaction:"Float"
	},
	voyce_chapter_reactions_count_stddev_pop_fields:{
		chapter_id:"Float",
		count:"Float",
		id:"Float",
		reaction:"Float"
	},
	voyce_chapter_reactions_count_stddev_samp_fields:{
		chapter_id:"Float",
		count:"Float",
		id:"Float",
		reaction:"Float"
	},
	voyce_chapter_reactions_count_sum_fields:{
		chapter_id:"Int",
		count:"Int",
		id:"Int",
		reaction:"Int"
	},
	voyce_chapter_reactions_count_var_pop_fields:{
		chapter_id:"Float",
		count:"Float",
		id:"Float",
		reaction:"Float"
	},
	voyce_chapter_reactions_count_var_samp_fields:{
		chapter_id:"Float",
		count:"Float",
		id:"Float",
		reaction:"Float"
	},
	voyce_chapter_reactions_count_variance_fields:{
		chapter_id:"Float",
		count:"Float",
		id:"Float",
		reaction:"Float"
	},
	voyce_chapter_reactions_max_fields:{
		created_at:"timestamptz",
		id:"Int",
		linked_entity_id:"Int",
		reaction:"Int",
		user_id:"Int"
	},
	voyce_chapter_reactions_min_fields:{
		created_at:"timestamptz",
		id:"Int",
		linked_entity_id:"Int",
		reaction:"Int",
		user_id:"Int"
	},
	voyce_chapter_reactions_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_reactions"
	},
	voyce_chapter_reactions_stddev_fields:{
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_chapter_reactions_stddev_pop_fields:{
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_chapter_reactions_stddev_samp_fields:{
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_chapter_reactions_sum_fields:{
		id:"Int",
		linked_entity_id:"Int",
		reaction:"Int",
		user_id:"Int"
	},
	voyce_chapter_reactions_var_pop_fields:{
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_chapter_reactions_var_samp_fields:{
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_chapter_reactions_variance_fields:{
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_chapter_view_logs:{
		chapter_id:"Int",
		created_at:"timestamptz",
		device_id:"String",
		gtag:"String",
		id:"Int",
		origin:"String",
		page:"String",
		panel_id:"Int",
		series_id:"Int",
		session_api:"String",
		session_id:"String",
		source:"String",
		user_id:"Int",
		user_ip:"String"
	},
	voyce_chapter_view_logs_aggregate:{
		aggregate:"voyce_chapter_view_logs_aggregate_fields",
		nodes:"voyce_chapter_view_logs"
	},
	voyce_chapter_view_logs_aggregate_fields:{
		avg:"voyce_chapter_view_logs_avg_fields",
		count:"Int",
		max:"voyce_chapter_view_logs_max_fields",
		min:"voyce_chapter_view_logs_min_fields",
		stddev:"voyce_chapter_view_logs_stddev_fields",
		stddev_pop:"voyce_chapter_view_logs_stddev_pop_fields",
		stddev_samp:"voyce_chapter_view_logs_stddev_samp_fields",
		sum:"voyce_chapter_view_logs_sum_fields",
		var_pop:"voyce_chapter_view_logs_var_pop_fields",
		var_samp:"voyce_chapter_view_logs_var_samp_fields",
		variance:"voyce_chapter_view_logs_variance_fields"
	},
	voyce_chapter_view_logs_avg_fields:{
		chapter_id:"Float",
		id:"Float",
		panel_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_view_logs_max_fields:{
		chapter_id:"Int",
		created_at:"timestamptz",
		device_id:"String",
		gtag:"String",
		id:"Int",
		origin:"String",
		page:"String",
		panel_id:"Int",
		series_id:"Int",
		session_api:"String",
		session_id:"String",
		source:"String",
		user_id:"Int",
		user_ip:"String"
	},
	voyce_chapter_view_logs_min_fields:{
		chapter_id:"Int",
		created_at:"timestamptz",
		device_id:"String",
		gtag:"String",
		id:"Int",
		origin:"String",
		page:"String",
		panel_id:"Int",
		series_id:"Int",
		session_api:"String",
		session_id:"String",
		source:"String",
		user_id:"Int",
		user_ip:"String"
	},
	voyce_chapter_view_logs_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_view_logs"
	},
	voyce_chapter_view_logs_stddev_fields:{
		chapter_id:"Float",
		id:"Float",
		panel_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_view_logs_stddev_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		panel_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_view_logs_stddev_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		panel_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_view_logs_sum_fields:{
		chapter_id:"Int",
		id:"Int",
		panel_id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_chapter_view_logs_var_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		panel_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_view_logs_var_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		panel_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_view_logs_variance_fields:{
		chapter_id:"Float",
		id:"Float",
		panel_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_chapter_views:{
		chapter_id:"Int",
		id:"Int",
		is_read:"Boolean",
		page:"String",
		series:"voyce_series",
		series_id:"Int",
		updated_at:"timestamp",
		user_id:"Int",
		user_ip:"bigint",
		view_hash:"String"
	},
	voyce_chapter_views_aggregate:{
		aggregate:"voyce_chapter_views_aggregate_fields",
		nodes:"voyce_chapter_views"
	},
	voyce_chapter_views_aggregate_fields:{
		avg:"voyce_chapter_views_avg_fields",
		count:"Int",
		max:"voyce_chapter_views_max_fields",
		min:"voyce_chapter_views_min_fields",
		stddev:"voyce_chapter_views_stddev_fields",
		stddev_pop:"voyce_chapter_views_stddev_pop_fields",
		stddev_samp:"voyce_chapter_views_stddev_samp_fields",
		sum:"voyce_chapter_views_sum_fields",
		var_pop:"voyce_chapter_views_var_pop_fields",
		var_samp:"voyce_chapter_views_var_samp_fields",
		variance:"voyce_chapter_views_variance_fields"
	},
	voyce_chapter_views_avg_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float",
		user_ip:"Float"
	},
	voyce_chapter_views_max_fields:{
		chapter_id:"Int",
		id:"Int",
		page:"String",
		series_id:"Int",
		updated_at:"timestamp",
		user_id:"Int",
		user_ip:"bigint",
		view_hash:"String"
	},
	voyce_chapter_views_min_fields:{
		chapter_id:"Int",
		id:"Int",
		page:"String",
		series_id:"Int",
		updated_at:"timestamp",
		user_id:"Int",
		user_ip:"bigint",
		view_hash:"String"
	},
	voyce_chapter_views_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapter_views"
	},
	voyce_chapter_views_stddev_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float",
		user_ip:"Float"
	},
	voyce_chapter_views_stddev_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float",
		user_ip:"Float"
	},
	voyce_chapter_views_stddev_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float",
		user_ip:"Float"
	},
	voyce_chapter_views_sum_fields:{
		chapter_id:"Int",
		id:"Int",
		series_id:"Int",
		user_id:"Int",
		user_ip:"bigint"
	},
	voyce_chapter_views_var_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float",
		user_ip:"Float"
	},
	voyce_chapter_views_var_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float",
		user_ip:"Float"
	},
	voyce_chapter_views_variance_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float",
		user_ip:"Float"
	},
	voyce_chapters:{
		chapter_comments:"voyce_chapter_comments",
		chapter_comments_aggregate:"voyce_chapter_comments_aggregate",
		chapter_effects:"voyce_chapter_effects",
		chapter_effects_aggregate:"voyce_chapter_effects_aggregate",
		chapter_panels:"voyce_chapter_panels",
		chapter_panels_aggregate:"voyce_chapter_panels_aggregate",
		chapter_reactions:"voyce_chapter_reactions",
		chapter_reactions_aggregate:"voyce_chapter_reactions_aggregate",
		content:"voyce_chapter_contents",
		created_at:"timestamp",
		description:"String",
		description_old:"String",
		displayed_exclusive:"Boolean",
		formatted_content:"String",
		formatted_content_v2:"String",
		formatted_content_v3:"String",
		has_text:"Boolean",
		id:"Int",
		images:"voyce_chapter_images",
		images_aggregate:"voyce_chapter_images_aggregate",
		is_deleted:"Boolean",
		likes:"voyce_chapter_likes",
		likes_aggregate:"voyce_chapter_likes_aggregate",
		locks:"voyce_chapter_locks",
		locks_aggregate:"voyce_chapter_locks_aggregate",
		order:"smallint",
		publish:"smallint",
		publish_date:"timestamptz",
		reactions_count:"Int",
		reading_view:"String",
		schedule:"voyce_chapter_publish_schedules",
		schedule_aggregate:"voyce_chapter_publish_schedules_aggregate",
		series:"voyce_series",
		series_id:"Int",
		status:"String",
		thumbnail:"String",
		title:"String",
		type:"String",
		updated_at:"timestamptz",
		view_count:"Int",
		views:"voyce_chapter_views",
		views_aggregate:"voyce_chapter_views_aggregate"
	},
	voyce_chapters_07252024:{
		created_at:"timestamp",
		description:"String",
		description_old:"String",
		displayed_exclusive:"Boolean",
		formatted_content:"String",
		formatted_content_v2:"String",
		formatted_content_v3:"String",
		has_text:"Boolean",
		id:"Int",
		is_deleted:"Boolean",
		order:"smallint",
		publish:"smallint",
		publish_date:"timestamptz",
		reactions_count:"Int",
		reading_view:"String",
		series_id:"Int",
		status:"String",
		thumbnail:"String",
		title:"String",
		type:"String",
		updated_at:"timestamptz",
		view_count:"Int"
	},
	voyce_chapters_07252024_aggregate:{
		aggregate:"voyce_chapters_07252024_aggregate_fields",
		nodes:"voyce_chapters_07252024"
	},
	voyce_chapters_07252024_aggregate_fields:{
		avg:"voyce_chapters_07252024_avg_fields",
		count:"Int",
		max:"voyce_chapters_07252024_max_fields",
		min:"voyce_chapters_07252024_min_fields",
		stddev:"voyce_chapters_07252024_stddev_fields",
		stddev_pop:"voyce_chapters_07252024_stddev_pop_fields",
		stddev_samp:"voyce_chapters_07252024_stddev_samp_fields",
		sum:"voyce_chapters_07252024_sum_fields",
		var_pop:"voyce_chapters_07252024_var_pop_fields",
		var_samp:"voyce_chapters_07252024_var_samp_fields",
		variance:"voyce_chapters_07252024_variance_fields"
	},
	voyce_chapters_07252024_avg_fields:{
		id:"Float",
		order:"Float",
		publish:"Float",
		reactions_count:"Float",
		series_id:"Float",
		view_count:"Float"
	},
	voyce_chapters_07252024_max_fields:{
		created_at:"timestamp",
		description:"String",
		description_old:"String",
		formatted_content:"String",
		formatted_content_v2:"String",
		formatted_content_v3:"String",
		id:"Int",
		order:"smallint",
		publish:"smallint",
		publish_date:"timestamptz",
		reactions_count:"Int",
		reading_view:"String",
		series_id:"Int",
		status:"String",
		thumbnail:"String",
		title:"String",
		type:"String",
		updated_at:"timestamptz",
		view_count:"Int"
	},
	voyce_chapters_07252024_min_fields:{
		created_at:"timestamp",
		description:"String",
		description_old:"String",
		formatted_content:"String",
		formatted_content_v2:"String",
		formatted_content_v3:"String",
		id:"Int",
		order:"smallint",
		publish:"smallint",
		publish_date:"timestamptz",
		reactions_count:"Int",
		reading_view:"String",
		series_id:"Int",
		status:"String",
		thumbnail:"String",
		title:"String",
		type:"String",
		updated_at:"timestamptz",
		view_count:"Int"
	},
	voyce_chapters_07252024_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapters_07252024"
	},
	voyce_chapters_07252024_stddev_fields:{
		id:"Float",
		order:"Float",
		publish:"Float",
		reactions_count:"Float",
		series_id:"Float",
		view_count:"Float"
	},
	voyce_chapters_07252024_stddev_pop_fields:{
		id:"Float",
		order:"Float",
		publish:"Float",
		reactions_count:"Float",
		series_id:"Float",
		view_count:"Float"
	},
	voyce_chapters_07252024_stddev_samp_fields:{
		id:"Float",
		order:"Float",
		publish:"Float",
		reactions_count:"Float",
		series_id:"Float",
		view_count:"Float"
	},
	voyce_chapters_07252024_sum_fields:{
		id:"Int",
		order:"smallint",
		publish:"smallint",
		reactions_count:"Int",
		series_id:"Int",
		view_count:"Int"
	},
	voyce_chapters_07252024_var_pop_fields:{
		id:"Float",
		order:"Float",
		publish:"Float",
		reactions_count:"Float",
		series_id:"Float",
		view_count:"Float"
	},
	voyce_chapters_07252024_var_samp_fields:{
		id:"Float",
		order:"Float",
		publish:"Float",
		reactions_count:"Float",
		series_id:"Float",
		view_count:"Float"
	},
	voyce_chapters_07252024_variance_fields:{
		id:"Float",
		order:"Float",
		publish:"Float",
		reactions_count:"Float",
		series_id:"Float",
		view_count:"Float"
	},
	voyce_chapters_aggregate:{
		aggregate:"voyce_chapters_aggregate_fields",
		nodes:"voyce_chapters"
	},
	voyce_chapters_aggregate_fields:{
		avg:"voyce_chapters_avg_fields",
		count:"Int",
		max:"voyce_chapters_max_fields",
		min:"voyce_chapters_min_fields",
		stddev:"voyce_chapters_stddev_fields",
		stddev_pop:"voyce_chapters_stddev_pop_fields",
		stddev_samp:"voyce_chapters_stddev_samp_fields",
		sum:"voyce_chapters_sum_fields",
		var_pop:"voyce_chapters_var_pop_fields",
		var_samp:"voyce_chapters_var_samp_fields",
		variance:"voyce_chapters_variance_fields"
	},
	voyce_chapters_avg_fields:{
		id:"Float",
		order:"Float",
		publish:"Float",
		reactions_count:"Float",
		series_id:"Float",
		view_count:"Float"
	},
	voyce_chapters_max_fields:{
		created_at:"timestamp",
		description:"String",
		description_old:"String",
		formatted_content:"String",
		formatted_content_v2:"String",
		formatted_content_v3:"String",
		id:"Int",
		order:"smallint",
		publish:"smallint",
		publish_date:"timestamptz",
		reactions_count:"Int",
		reading_view:"String",
		series_id:"Int",
		status:"String",
		thumbnail:"String",
		title:"String",
		type:"String",
		updated_at:"timestamptz",
		view_count:"Int"
	},
	voyce_chapters_min_fields:{
		created_at:"timestamp",
		description:"String",
		description_old:"String",
		formatted_content:"String",
		formatted_content_v2:"String",
		formatted_content_v3:"String",
		id:"Int",
		order:"smallint",
		publish:"smallint",
		publish_date:"timestamptz",
		reactions_count:"Int",
		reading_view:"String",
		series_id:"Int",
		status:"String",
		thumbnail:"String",
		title:"String",
		type:"String",
		updated_at:"timestamptz",
		view_count:"Int"
	},
	voyce_chapters_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_chapters"
	},
	voyce_chapters_stddev_fields:{
		id:"Float",
		order:"Float",
		publish:"Float",
		reactions_count:"Float",
		series_id:"Float",
		view_count:"Float"
	},
	voyce_chapters_stddev_pop_fields:{
		id:"Float",
		order:"Float",
		publish:"Float",
		reactions_count:"Float",
		series_id:"Float",
		view_count:"Float"
	},
	voyce_chapters_stddev_samp_fields:{
		id:"Float",
		order:"Float",
		publish:"Float",
		reactions_count:"Float",
		series_id:"Float",
		view_count:"Float"
	},
	voyce_chapters_sum_fields:{
		id:"Int",
		order:"smallint",
		publish:"smallint",
		reactions_count:"Int",
		series_id:"Int",
		view_count:"Int"
	},
	voyce_chapters_var_pop_fields:{
		id:"Float",
		order:"Float",
		publish:"Float",
		reactions_count:"Float",
		series_id:"Float",
		view_count:"Float"
	},
	voyce_chapters_var_samp_fields:{
		id:"Float",
		order:"Float",
		publish:"Float",
		reactions_count:"Float",
		series_id:"Float",
		view_count:"Float"
	},
	voyce_chapters_variance_fields:{
		id:"Float",
		order:"Float",
		publish:"Float",
		reactions_count:"Float",
		series_id:"Float",
		view_count:"Float"
	},
	voyce_collaborator_roles:{
		created_at:"timestamptz",
		id:"Int",
		role:"String",
		updated_at:"timestamptz"
	},
	voyce_collaborator_roles_aggregate:{
		aggregate:"voyce_collaborator_roles_aggregate_fields",
		nodes:"voyce_collaborator_roles"
	},
	voyce_collaborator_roles_aggregate_fields:{
		avg:"voyce_collaborator_roles_avg_fields",
		count:"Int",
		max:"voyce_collaborator_roles_max_fields",
		min:"voyce_collaborator_roles_min_fields",
		stddev:"voyce_collaborator_roles_stddev_fields",
		stddev_pop:"voyce_collaborator_roles_stddev_pop_fields",
		stddev_samp:"voyce_collaborator_roles_stddev_samp_fields",
		sum:"voyce_collaborator_roles_sum_fields",
		var_pop:"voyce_collaborator_roles_var_pop_fields",
		var_samp:"voyce_collaborator_roles_var_samp_fields",
		variance:"voyce_collaborator_roles_variance_fields"
	},
	voyce_collaborator_roles_avg_fields:{
		id:"Float"
	},
	voyce_collaborator_roles_max_fields:{
		created_at:"timestamptz",
		id:"Int",
		role:"String",
		updated_at:"timestamptz"
	},
	voyce_collaborator_roles_min_fields:{
		created_at:"timestamptz",
		id:"Int",
		role:"String",
		updated_at:"timestamptz"
	},
	voyce_collaborator_roles_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_collaborator_roles"
	},
	voyce_collaborator_roles_stddev_fields:{
		id:"Float"
	},
	voyce_collaborator_roles_stddev_pop_fields:{
		id:"Float"
	},
	voyce_collaborator_roles_stddev_samp_fields:{
		id:"Float"
	},
	voyce_collaborator_roles_sum_fields:{
		id:"Int"
	},
	voyce_collaborator_roles_var_pop_fields:{
		id:"Float"
	},
	voyce_collaborator_roles_var_samp_fields:{
		id:"Float"
	},
	voyce_collaborator_roles_variance_fields:{
		id:"Float"
	},
	voyce_comic_types:{
		created_at:"timestamp",
		id:"Int",
		klaviyo_id:"String",
		title:"String",
		updated_at:"timestamp"
	},
	voyce_comic_types_aggregate:{
		aggregate:"voyce_comic_types_aggregate_fields",
		nodes:"voyce_comic_types"
	},
	voyce_comic_types_aggregate_fields:{
		avg:"voyce_comic_types_avg_fields",
		count:"Int",
		max:"voyce_comic_types_max_fields",
		min:"voyce_comic_types_min_fields",
		stddev:"voyce_comic_types_stddev_fields",
		stddev_pop:"voyce_comic_types_stddev_pop_fields",
		stddev_samp:"voyce_comic_types_stddev_samp_fields",
		sum:"voyce_comic_types_sum_fields",
		var_pop:"voyce_comic_types_var_pop_fields",
		var_samp:"voyce_comic_types_var_samp_fields",
		variance:"voyce_comic_types_variance_fields"
	},
	voyce_comic_types_avg_fields:{
		id:"Float"
	},
	voyce_comic_types_max_fields:{
		created_at:"timestamp",
		id:"Int",
		klaviyo_id:"String",
		title:"String",
		updated_at:"timestamp"
	},
	voyce_comic_types_min_fields:{
		created_at:"timestamp",
		id:"Int",
		klaviyo_id:"String",
		title:"String",
		updated_at:"timestamp"
	},
	voyce_comic_types_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_comic_types"
	},
	voyce_comic_types_stddev_fields:{
		id:"Float"
	},
	voyce_comic_types_stddev_pop_fields:{
		id:"Float"
	},
	voyce_comic_types_stddev_samp_fields:{
		id:"Float"
	},
	voyce_comic_types_sum_fields:{
		id:"Int"
	},
	voyce_comic_types_var_pop_fields:{
		id:"Float"
	},
	voyce_comic_types_var_samp_fields:{
		id:"Float"
	},
	voyce_comic_types_variance_fields:{
		id:"Float"
	},
	voyce_comments_top_mat:{
		comment_type:"String",
		id:"Int",
		reaction_count:"Int",
		user_id:"Int"
	},
	voyce_comments_top_mat_aggregate:{
		aggregate:"voyce_comments_top_mat_aggregate_fields",
		nodes:"voyce_comments_top_mat"
	},
	voyce_comments_top_mat_aggregate_fields:{
		avg:"voyce_comments_top_mat_avg_fields",
		count:"Int",
		max:"voyce_comments_top_mat_max_fields",
		min:"voyce_comments_top_mat_min_fields",
		stddev:"voyce_comments_top_mat_stddev_fields",
		stddev_pop:"voyce_comments_top_mat_stddev_pop_fields",
		stddev_samp:"voyce_comments_top_mat_stddev_samp_fields",
		sum:"voyce_comments_top_mat_sum_fields",
		var_pop:"voyce_comments_top_mat_var_pop_fields",
		var_samp:"voyce_comments_top_mat_var_samp_fields",
		variance:"voyce_comments_top_mat_variance_fields"
	},
	voyce_comments_top_mat_avg_fields:{
		id:"Float",
		reaction_count:"Float",
		user_id:"Float"
	},
	voyce_comments_top_mat_max_fields:{
		comment_type:"String",
		id:"Int",
		reaction_count:"Int",
		user_id:"Int"
	},
	voyce_comments_top_mat_min_fields:{
		comment_type:"String",
		id:"Int",
		reaction_count:"Int",
		user_id:"Int"
	},
	voyce_comments_top_mat_stddev_fields:{
		id:"Float",
		reaction_count:"Float",
		user_id:"Float"
	},
	voyce_comments_top_mat_stddev_pop_fields:{
		id:"Float",
		reaction_count:"Float",
		user_id:"Float"
	},
	voyce_comments_top_mat_stddev_samp_fields:{
		id:"Float",
		reaction_count:"Float",
		user_id:"Float"
	},
	voyce_comments_top_mat_sum_fields:{
		id:"Int",
		reaction_count:"Int",
		user_id:"Int"
	},
	voyce_comments_top_mat_var_pop_fields:{
		id:"Float",
		reaction_count:"Float",
		user_id:"Float"
	},
	voyce_comments_top_mat_var_samp_fields:{
		id:"Float",
		reaction_count:"Float",
		user_id:"Float"
	},
	voyce_comments_top_mat_variance_fields:{
		id:"Float",
		reaction_count:"Float",
		user_id:"Float"
	},
	voyce_community_blurbs:{
		avatar:"String",
		content:"String",
		id:"Int",
		name:"String",
		order:"Int",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_community_blurbs_aggregate:{
		aggregate:"voyce_community_blurbs_aggregate_fields",
		nodes:"voyce_community_blurbs"
	},
	voyce_community_blurbs_aggregate_fields:{
		avg:"voyce_community_blurbs_avg_fields",
		count:"Int",
		max:"voyce_community_blurbs_max_fields",
		min:"voyce_community_blurbs_min_fields",
		stddev:"voyce_community_blurbs_stddev_fields",
		stddev_pop:"voyce_community_blurbs_stddev_pop_fields",
		stddev_samp:"voyce_community_blurbs_stddev_samp_fields",
		sum:"voyce_community_blurbs_sum_fields",
		var_pop:"voyce_community_blurbs_var_pop_fields",
		var_samp:"voyce_community_blurbs_var_samp_fields",
		variance:"voyce_community_blurbs_variance_fields"
	},
	voyce_community_blurbs_avg_fields:{
		id:"Float",
		order:"Float"
	},
	voyce_community_blurbs_max_fields:{
		avatar:"String",
		content:"String",
		id:"Int",
		name:"String",
		order:"Int",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_community_blurbs_min_fields:{
		avatar:"String",
		content:"String",
		id:"Int",
		name:"String",
		order:"Int",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_community_blurbs_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_community_blurbs"
	},
	voyce_community_blurbs_stddev_fields:{
		id:"Float",
		order:"Float"
	},
	voyce_community_blurbs_stddev_pop_fields:{
		id:"Float",
		order:"Float"
	},
	voyce_community_blurbs_stddev_samp_fields:{
		id:"Float",
		order:"Float"
	},
	voyce_community_blurbs_sum_fields:{
		id:"Int",
		order:"Int"
	},
	voyce_community_blurbs_var_pop_fields:{
		id:"Float",
		order:"Float"
	},
	voyce_community_blurbs_var_samp_fields:{
		id:"Float",
		order:"Float"
	},
	voyce_community_blurbs_variance_fields:{
		id:"Float",
		order:"Float"
	},
	voyce_contact_us:{
		created_at:"timestamptz",
		email:"String",
		first_name:"String",
		id:"Int",
		last_name:"String",
		message:"String",
		number:"String",
		reason:"String"
	},
	voyce_contact_us_aggregate:{
		aggregate:"voyce_contact_us_aggregate_fields",
		nodes:"voyce_contact_us"
	},
	voyce_contact_us_aggregate_fields:{
		avg:"voyce_contact_us_avg_fields",
		count:"Int",
		max:"voyce_contact_us_max_fields",
		min:"voyce_contact_us_min_fields",
		stddev:"voyce_contact_us_stddev_fields",
		stddev_pop:"voyce_contact_us_stddev_pop_fields",
		stddev_samp:"voyce_contact_us_stddev_samp_fields",
		sum:"voyce_contact_us_sum_fields",
		var_pop:"voyce_contact_us_var_pop_fields",
		var_samp:"voyce_contact_us_var_samp_fields",
		variance:"voyce_contact_us_variance_fields"
	},
	voyce_contact_us_avg_fields:{
		id:"Float"
	},
	voyce_contact_us_max_fields:{
		created_at:"timestamptz",
		email:"String",
		first_name:"String",
		id:"Int",
		last_name:"String",
		message:"String",
		number:"String",
		reason:"String"
	},
	voyce_contact_us_min_fields:{
		created_at:"timestamptz",
		email:"String",
		first_name:"String",
		id:"Int",
		last_name:"String",
		message:"String",
		number:"String",
		reason:"String"
	},
	voyce_contact_us_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_contact_us"
	},
	voyce_contact_us_stddev_fields:{
		id:"Float"
	},
	voyce_contact_us_stddev_pop_fields:{
		id:"Float"
	},
	voyce_contact_us_stddev_samp_fields:{
		id:"Float"
	},
	voyce_contact_us_sum_fields:{
		id:"Int"
	},
	voyce_contact_us_var_pop_fields:{
		id:"Float"
	},
	voyce_contact_us_var_samp_fields:{
		id:"Float"
	},
	voyce_contact_us_variance_fields:{
		id:"Float"
	},
	voyce_content_report:{
		created_at:"timestamptz",
		extras:"String",
		id:"Int",
		linked_id:"Int",
		reason:"String",
		type:"String",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_content_report_aggregate:{
		aggregate:"voyce_content_report_aggregate_fields",
		nodes:"voyce_content_report"
	},
	voyce_content_report_aggregate_fields:{
		avg:"voyce_content_report_avg_fields",
		count:"Int",
		max:"voyce_content_report_max_fields",
		min:"voyce_content_report_min_fields",
		stddev:"voyce_content_report_stddev_fields",
		stddev_pop:"voyce_content_report_stddev_pop_fields",
		stddev_samp:"voyce_content_report_stddev_samp_fields",
		sum:"voyce_content_report_sum_fields",
		var_pop:"voyce_content_report_var_pop_fields",
		var_samp:"voyce_content_report_var_samp_fields",
		variance:"voyce_content_report_variance_fields"
	},
	voyce_content_report_avg_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_content_report_max_fields:{
		created_at:"timestamptz",
		extras:"String",
		id:"Int",
		linked_id:"Int",
		reason:"String",
		type:"String",
		user_id:"Int"
	},
	voyce_content_report_min_fields:{
		created_at:"timestamptz",
		extras:"String",
		id:"Int",
		linked_id:"Int",
		reason:"String",
		type:"String",
		user_id:"Int"
	},
	voyce_content_report_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_content_report"
	},
	voyce_content_report_stddev_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_content_report_stddev_pop_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_content_report_stddev_samp_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_content_report_sum_fields:{
		id:"Int",
		linked_id:"Int",
		user_id:"Int"
	},
	voyce_content_report_var_pop_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_content_report_var_samp_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_content_report_variance_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_contest_data:{
		age:"Int",
		created_at:"timestamp",
		discord_username:"String",
		first_arc:"String",
		id:"Int",
		name:"String",
		pdf:"String",
		social_media:"String",
		story_pitch:"String",
		story_summary:"String",
		target_audience:"String",
		user:"voyce_users",
		user_id:"Int",
		where_did_hear:"String"
	},
	voyce_contest_data_aggregate:{
		aggregate:"voyce_contest_data_aggregate_fields",
		nodes:"voyce_contest_data"
	},
	voyce_contest_data_aggregate_fields:{
		avg:"voyce_contest_data_avg_fields",
		count:"Int",
		max:"voyce_contest_data_max_fields",
		min:"voyce_contest_data_min_fields",
		stddev:"voyce_contest_data_stddev_fields",
		stddev_pop:"voyce_contest_data_stddev_pop_fields",
		stddev_samp:"voyce_contest_data_stddev_samp_fields",
		sum:"voyce_contest_data_sum_fields",
		var_pop:"voyce_contest_data_var_pop_fields",
		var_samp:"voyce_contest_data_var_samp_fields",
		variance:"voyce_contest_data_variance_fields"
	},
	voyce_contest_data_avg_fields:{
		age:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_contest_data_max_fields:{
		age:"Int",
		created_at:"timestamp",
		discord_username:"String",
		first_arc:"String",
		id:"Int",
		name:"String",
		pdf:"String",
		social_media:"String",
		story_pitch:"String",
		story_summary:"String",
		target_audience:"String",
		user_id:"Int",
		where_did_hear:"String"
	},
	voyce_contest_data_min_fields:{
		age:"Int",
		created_at:"timestamp",
		discord_username:"String",
		first_arc:"String",
		id:"Int",
		name:"String",
		pdf:"String",
		social_media:"String",
		story_pitch:"String",
		story_summary:"String",
		target_audience:"String",
		user_id:"Int",
		where_did_hear:"String"
	},
	voyce_contest_data_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_contest_data"
	},
	voyce_contest_data_stddev_fields:{
		age:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_contest_data_stddev_pop_fields:{
		age:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_contest_data_stddev_samp_fields:{
		age:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_contest_data_sum_fields:{
		age:"Int",
		id:"Int",
		user_id:"Int"
	},
	voyce_contest_data_var_pop_fields:{
		age:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_contest_data_var_samp_fields:{
		age:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_contest_data_variance_fields:{
		age:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_countries:{
		date_create:"timestamp",
		description:"String",
		feature:"smallint",
		flag:"String",
		id:"Int",
		name:"String",
		phone_code:"Int",
		publish:"smallint",
		sort_name:"String"
	},
	voyce_countries_aggregate:{
		aggregate:"voyce_countries_aggregate_fields",
		nodes:"voyce_countries"
	},
	voyce_countries_aggregate_fields:{
		avg:"voyce_countries_avg_fields",
		count:"Int",
		max:"voyce_countries_max_fields",
		min:"voyce_countries_min_fields",
		stddev:"voyce_countries_stddev_fields",
		stddev_pop:"voyce_countries_stddev_pop_fields",
		stddev_samp:"voyce_countries_stddev_samp_fields",
		sum:"voyce_countries_sum_fields",
		var_pop:"voyce_countries_var_pop_fields",
		var_samp:"voyce_countries_var_samp_fields",
		variance:"voyce_countries_variance_fields"
	},
	voyce_countries_avg_fields:{
		feature:"Float",
		id:"Float",
		phone_code:"Float",
		publish:"Float"
	},
	voyce_countries_max_fields:{
		date_create:"timestamp",
		description:"String",
		feature:"smallint",
		flag:"String",
		id:"Int",
		name:"String",
		phone_code:"Int",
		publish:"smallint",
		sort_name:"String"
	},
	voyce_countries_min_fields:{
		date_create:"timestamp",
		description:"String",
		feature:"smallint",
		flag:"String",
		id:"Int",
		name:"String",
		phone_code:"Int",
		publish:"smallint",
		sort_name:"String"
	},
	voyce_countries_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_countries"
	},
	voyce_countries_stddev_fields:{
		feature:"Float",
		id:"Float",
		phone_code:"Float",
		publish:"Float"
	},
	voyce_countries_stddev_pop_fields:{
		feature:"Float",
		id:"Float",
		phone_code:"Float",
		publish:"Float"
	},
	voyce_countries_stddev_samp_fields:{
		feature:"Float",
		id:"Float",
		phone_code:"Float",
		publish:"Float"
	},
	voyce_countries_sum_fields:{
		feature:"smallint",
		id:"Int",
		phone_code:"Int",
		publish:"smallint"
	},
	voyce_countries_var_pop_fields:{
		feature:"Float",
		id:"Float",
		phone_code:"Float",
		publish:"Float"
	},
	voyce_countries_var_samp_fields:{
		feature:"Float",
		id:"Float",
		phone_code:"Float",
		publish:"Float"
	},
	voyce_countries_variance_fields:{
		feature:"Float",
		id:"Float",
		phone_code:"Float",
		publish:"Float"
	},
	voyce_csat_ratings:{
		cancel_text:"String",
		comment:"Boolean",
		created_at:"timestamptz",
		data:"voyce_csat_ratings_data",
		data_aggregate:"voyce_csat_ratings_data_aggregate",
		desc:"String",
		id:"Int",
		screen:"Int",
		title:"String",
		version:"smallint"
	},
	voyce_csat_ratings_aggregate:{
		aggregate:"voyce_csat_ratings_aggregate_fields",
		nodes:"voyce_csat_ratings"
	},
	voyce_csat_ratings_aggregate_fields:{
		avg:"voyce_csat_ratings_avg_fields",
		count:"Int",
		max:"voyce_csat_ratings_max_fields",
		min:"voyce_csat_ratings_min_fields",
		stddev:"voyce_csat_ratings_stddev_fields",
		stddev_pop:"voyce_csat_ratings_stddev_pop_fields",
		stddev_samp:"voyce_csat_ratings_stddev_samp_fields",
		sum:"voyce_csat_ratings_sum_fields",
		var_pop:"voyce_csat_ratings_var_pop_fields",
		var_samp:"voyce_csat_ratings_var_samp_fields",
		variance:"voyce_csat_ratings_variance_fields"
	},
	voyce_csat_ratings_avg_fields:{
		id:"Float",
		screen:"Float",
		version:"Float"
	},
	voyce_csat_ratings_data:{
		created_at:"timestamptz",
		csat_rating_id:"Int",
		id:"Int",
		image:"String",
		label:"String",
		order:"smallint",
		rating:"String"
	},
	voyce_csat_ratings_data_aggregate:{
		aggregate:"voyce_csat_ratings_data_aggregate_fields",
		nodes:"voyce_csat_ratings_data"
	},
	voyce_csat_ratings_data_aggregate_fields:{
		avg:"voyce_csat_ratings_data_avg_fields",
		count:"Int",
		max:"voyce_csat_ratings_data_max_fields",
		min:"voyce_csat_ratings_data_min_fields",
		stddev:"voyce_csat_ratings_data_stddev_fields",
		stddev_pop:"voyce_csat_ratings_data_stddev_pop_fields",
		stddev_samp:"voyce_csat_ratings_data_stddev_samp_fields",
		sum:"voyce_csat_ratings_data_sum_fields",
		var_pop:"voyce_csat_ratings_data_var_pop_fields",
		var_samp:"voyce_csat_ratings_data_var_samp_fields",
		variance:"voyce_csat_ratings_data_variance_fields"
	},
	voyce_csat_ratings_data_avg_fields:{
		csat_rating_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_csat_ratings_data_max_fields:{
		created_at:"timestamptz",
		csat_rating_id:"Int",
		id:"Int",
		image:"String",
		label:"String",
		order:"smallint",
		rating:"String"
	},
	voyce_csat_ratings_data_min_fields:{
		created_at:"timestamptz",
		csat_rating_id:"Int",
		id:"Int",
		image:"String",
		label:"String",
		order:"smallint",
		rating:"String"
	},
	voyce_csat_ratings_data_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_csat_ratings_data"
	},
	voyce_csat_ratings_data_stddev_fields:{
		csat_rating_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_csat_ratings_data_stddev_pop_fields:{
		csat_rating_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_csat_ratings_data_stddev_samp_fields:{
		csat_rating_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_csat_ratings_data_sum_fields:{
		csat_rating_id:"Int",
		id:"Int",
		order:"smallint"
	},
	voyce_csat_ratings_data_var_pop_fields:{
		csat_rating_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_csat_ratings_data_var_samp_fields:{
		csat_rating_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_csat_ratings_data_variance_fields:{
		csat_rating_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_csat_ratings_max_fields:{
		cancel_text:"String",
		created_at:"timestamptz",
		desc:"String",
		id:"Int",
		screen:"Int",
		title:"String",
		version:"smallint"
	},
	voyce_csat_ratings_min_fields:{
		cancel_text:"String",
		created_at:"timestamptz",
		desc:"String",
		id:"Int",
		screen:"Int",
		title:"String",
		version:"smallint"
	},
	voyce_csat_ratings_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_csat_ratings"
	},
	voyce_csat_ratings_stddev_fields:{
		id:"Float",
		screen:"Float",
		version:"Float"
	},
	voyce_csat_ratings_stddev_pop_fields:{
		id:"Float",
		screen:"Float",
		version:"Float"
	},
	voyce_csat_ratings_stddev_samp_fields:{
		id:"Float",
		screen:"Float",
		version:"Float"
	},
	voyce_csat_ratings_sum_fields:{
		id:"Int",
		screen:"Int",
		version:"smallint"
	},
	voyce_csat_ratings_var_pop_fields:{
		id:"Float",
		screen:"Float",
		version:"Float"
	},
	voyce_csat_ratings_var_samp_fields:{
		id:"Float",
		screen:"Float",
		version:"Float"
	},
	voyce_csat_ratings_variance_fields:{
		id:"Float",
		screen:"Float",
		version:"Float"
	},
	voyce_education_types:{
		date_create:"timestamp",
		id:"Int",
		title:"String"
	},
	voyce_education_types_aggregate:{
		aggregate:"voyce_education_types_aggregate_fields",
		nodes:"voyce_education_types"
	},
	voyce_education_types_aggregate_fields:{
		avg:"voyce_education_types_avg_fields",
		count:"Int",
		max:"voyce_education_types_max_fields",
		min:"voyce_education_types_min_fields",
		stddev:"voyce_education_types_stddev_fields",
		stddev_pop:"voyce_education_types_stddev_pop_fields",
		stddev_samp:"voyce_education_types_stddev_samp_fields",
		sum:"voyce_education_types_sum_fields",
		var_pop:"voyce_education_types_var_pop_fields",
		var_samp:"voyce_education_types_var_samp_fields",
		variance:"voyce_education_types_variance_fields"
	},
	voyce_education_types_avg_fields:{
		id:"Float"
	},
	voyce_education_types_max_fields:{
		date_create:"timestamp",
		id:"Int",
		title:"String"
	},
	voyce_education_types_min_fields:{
		date_create:"timestamp",
		id:"Int",
		title:"String"
	},
	voyce_education_types_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_education_types"
	},
	voyce_education_types_stddev_fields:{
		id:"Float"
	},
	voyce_education_types_stddev_pop_fields:{
		id:"Float"
	},
	voyce_education_types_stddev_samp_fields:{
		id:"Float"
	},
	voyce_education_types_sum_fields:{
		id:"Int"
	},
	voyce_education_types_var_pop_fields:{
		id:"Float"
	},
	voyce_education_types_var_samp_fields:{
		id:"Float"
	},
	voyce_education_types_variance_fields:{
		id:"Float"
	},
	voyce_erp_login_roles:{
		id:"Int",
		role_id:"Int"
	},
	voyce_erp_login_roles_aggregate:{
		aggregate:"voyce_erp_login_roles_aggregate_fields",
		nodes:"voyce_erp_login_roles"
	},
	voyce_erp_login_roles_aggregate_fields:{
		avg:"voyce_erp_login_roles_avg_fields",
		count:"Int",
		max:"voyce_erp_login_roles_max_fields",
		min:"voyce_erp_login_roles_min_fields",
		stddev:"voyce_erp_login_roles_stddev_fields",
		stddev_pop:"voyce_erp_login_roles_stddev_pop_fields",
		stddev_samp:"voyce_erp_login_roles_stddev_samp_fields",
		sum:"voyce_erp_login_roles_sum_fields",
		var_pop:"voyce_erp_login_roles_var_pop_fields",
		var_samp:"voyce_erp_login_roles_var_samp_fields",
		variance:"voyce_erp_login_roles_variance_fields"
	},
	voyce_erp_login_roles_avg_fields:{
		id:"Float",
		role_id:"Float"
	},
	voyce_erp_login_roles_max_fields:{
		id:"Int",
		role_id:"Int"
	},
	voyce_erp_login_roles_min_fields:{
		id:"Int",
		role_id:"Int"
	},
	voyce_erp_login_roles_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_erp_login_roles"
	},
	voyce_erp_login_roles_stddev_fields:{
		id:"Float",
		role_id:"Float"
	},
	voyce_erp_login_roles_stddev_pop_fields:{
		id:"Float",
		role_id:"Float"
	},
	voyce_erp_login_roles_stddev_samp_fields:{
		id:"Float",
		role_id:"Float"
	},
	voyce_erp_login_roles_sum_fields:{
		id:"Int",
		role_id:"Int"
	},
	voyce_erp_login_roles_var_pop_fields:{
		id:"Float",
		role_id:"Float"
	},
	voyce_erp_login_roles_var_samp_fields:{
		id:"Float",
		role_id:"Float"
	},
	voyce_erp_login_roles_variance_fields:{
		id:"Float",
		role_id:"Float"
	},
	voyce_etl_series_views_logs:{
		created_at:"timestamptz",
		glue_epoc:"Int",
		id:"Int"
	},
	voyce_etl_series_views_logs_aggregate:{
		aggregate:"voyce_etl_series_views_logs_aggregate_fields",
		nodes:"voyce_etl_series_views_logs"
	},
	voyce_etl_series_views_logs_aggregate_fields:{
		avg:"voyce_etl_series_views_logs_avg_fields",
		count:"Int",
		max:"voyce_etl_series_views_logs_max_fields",
		min:"voyce_etl_series_views_logs_min_fields",
		stddev:"voyce_etl_series_views_logs_stddev_fields",
		stddev_pop:"voyce_etl_series_views_logs_stddev_pop_fields",
		stddev_samp:"voyce_etl_series_views_logs_stddev_samp_fields",
		sum:"voyce_etl_series_views_logs_sum_fields",
		var_pop:"voyce_etl_series_views_logs_var_pop_fields",
		var_samp:"voyce_etl_series_views_logs_var_samp_fields",
		variance:"voyce_etl_series_views_logs_variance_fields"
	},
	voyce_etl_series_views_logs_avg_fields:{
		glue_epoc:"Float",
		id:"Float"
	},
	voyce_etl_series_views_logs_max_fields:{
		created_at:"timestamptz",
		glue_epoc:"Int",
		id:"Int"
	},
	voyce_etl_series_views_logs_min_fields:{
		created_at:"timestamptz",
		glue_epoc:"Int",
		id:"Int"
	},
	voyce_etl_series_views_logs_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_etl_series_views_logs"
	},
	voyce_etl_series_views_logs_stddev_fields:{
		glue_epoc:"Float",
		id:"Float"
	},
	voyce_etl_series_views_logs_stddev_pop_fields:{
		glue_epoc:"Float",
		id:"Float"
	},
	voyce_etl_series_views_logs_stddev_samp_fields:{
		glue_epoc:"Float",
		id:"Float"
	},
	voyce_etl_series_views_logs_sum_fields:{
		glue_epoc:"Int",
		id:"Int"
	},
	voyce_etl_series_views_logs_var_pop_fields:{
		glue_epoc:"Float",
		id:"Float"
	},
	voyce_etl_series_views_logs_var_samp_fields:{
		glue_epoc:"Float",
		id:"Float"
	},
	voyce_etl_series_views_logs_variance_fields:{
		glue_epoc:"Float",
		id:"Float"
	},
	voyce_event_schedules:{
		active:"Boolean",
		bg_image:"String",
		created_at:"timestamptz",
		date_time:"timestamptz",
		description:"String",
		id:"Int",
		link:"String",
		main_event:"Boolean",
		main_image:"String",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_event_schedules_aggregate:{
		aggregate:"voyce_event_schedules_aggregate_fields",
		nodes:"voyce_event_schedules"
	},
	voyce_event_schedules_aggregate_fields:{
		avg:"voyce_event_schedules_avg_fields",
		count:"Int",
		max:"voyce_event_schedules_max_fields",
		min:"voyce_event_schedules_min_fields",
		stddev:"voyce_event_schedules_stddev_fields",
		stddev_pop:"voyce_event_schedules_stddev_pop_fields",
		stddev_samp:"voyce_event_schedules_stddev_samp_fields",
		sum:"voyce_event_schedules_sum_fields",
		var_pop:"voyce_event_schedules_var_pop_fields",
		var_samp:"voyce_event_schedules_var_samp_fields",
		variance:"voyce_event_schedules_variance_fields"
	},
	voyce_event_schedules_avg_fields:{
		id:"Float"
	},
	voyce_event_schedules_max_fields:{
		bg_image:"String",
		created_at:"timestamptz",
		date_time:"timestamptz",
		description:"String",
		id:"Int",
		link:"String",
		main_image:"String",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_event_schedules_min_fields:{
		bg_image:"String",
		created_at:"timestamptz",
		date_time:"timestamptz",
		description:"String",
		id:"Int",
		link:"String",
		main_image:"String",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_event_schedules_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_event_schedules"
	},
	voyce_event_schedules_stddev_fields:{
		id:"Float"
	},
	voyce_event_schedules_stddev_pop_fields:{
		id:"Float"
	},
	voyce_event_schedules_stddev_samp_fields:{
		id:"Float"
	},
	voyce_event_schedules_sum_fields:{
		id:"Int"
	},
	voyce_event_schedules_var_pop_fields:{
		id:"Float"
	},
	voyce_event_schedules_var_samp_fields:{
		id:"Float"
	},
	voyce_event_schedules_variance_fields:{
		id:"Float"
	},
	voyce_genres:{
		color:"String",
		created_at:"timestamp",
		id:"Int",
		is_trending:"Boolean",
		klaviyo_id:"String",
		thumbnail:"String",
		title:"String",
		updated_at:"timestamp"
	},
	voyce_genres_aggregate:{
		aggregate:"voyce_genres_aggregate_fields",
		nodes:"voyce_genres"
	},
	voyce_genres_aggregate_fields:{
		avg:"voyce_genres_avg_fields",
		count:"Int",
		max:"voyce_genres_max_fields",
		min:"voyce_genres_min_fields",
		stddev:"voyce_genres_stddev_fields",
		stddev_pop:"voyce_genres_stddev_pop_fields",
		stddev_samp:"voyce_genres_stddev_samp_fields",
		sum:"voyce_genres_sum_fields",
		var_pop:"voyce_genres_var_pop_fields",
		var_samp:"voyce_genres_var_samp_fields",
		variance:"voyce_genres_variance_fields"
	},
	voyce_genres_avg_fields:{
		id:"Float"
	},
	voyce_genres_max_fields:{
		color:"String",
		created_at:"timestamp",
		id:"Int",
		klaviyo_id:"String",
		thumbnail:"String",
		title:"String",
		updated_at:"timestamp"
	},
	voyce_genres_min_fields:{
		color:"String",
		created_at:"timestamp",
		id:"Int",
		klaviyo_id:"String",
		thumbnail:"String",
		title:"String",
		updated_at:"timestamp"
	},
	voyce_genres_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_genres"
	},
	voyce_genres_stddev_fields:{
		id:"Float"
	},
	voyce_genres_stddev_pop_fields:{
		id:"Float"
	},
	voyce_genres_stddev_samp_fields:{
		id:"Float"
	},
	voyce_genres_sum_fields:{
		id:"Int"
	},
	voyce_genres_var_pop_fields:{
		id:"Float"
	},
	voyce_genres_var_samp_fields:{
		id:"Float"
	},
	voyce_genres_variance_fields:{
		id:"Float"
	},
	voyce_guest_gtags:{
		created_at:"timestamptz",
		device_id:"String",
		emails:"voyce_popup_emails",
		emails_aggregate:"voyce_popup_emails_aggregate",
		gtag:"String",
		gtag_v2:"String",
		id:"Int",
		klaviyo_id:"String",
		source:"String",
		user_ip:"String",
		username:"String"
	},
	voyce_guest_gtags_aggregate:{
		aggregate:"voyce_guest_gtags_aggregate_fields",
		nodes:"voyce_guest_gtags"
	},
	voyce_guest_gtags_aggregate_fields:{
		avg:"voyce_guest_gtags_avg_fields",
		count:"Int",
		max:"voyce_guest_gtags_max_fields",
		min:"voyce_guest_gtags_min_fields",
		stddev:"voyce_guest_gtags_stddev_fields",
		stddev_pop:"voyce_guest_gtags_stddev_pop_fields",
		stddev_samp:"voyce_guest_gtags_stddev_samp_fields",
		sum:"voyce_guest_gtags_sum_fields",
		var_pop:"voyce_guest_gtags_var_pop_fields",
		var_samp:"voyce_guest_gtags_var_samp_fields",
		variance:"voyce_guest_gtags_variance_fields"
	},
	voyce_guest_gtags_avg_fields:{
		id:"Float"
	},
	voyce_guest_gtags_max_fields:{
		created_at:"timestamptz",
		device_id:"String",
		gtag:"String",
		gtag_v2:"String",
		id:"Int",
		klaviyo_id:"String",
		source:"String",
		user_ip:"String",
		username:"String"
	},
	voyce_guest_gtags_min_fields:{
		created_at:"timestamptz",
		device_id:"String",
		gtag:"String",
		gtag_v2:"String",
		id:"Int",
		klaviyo_id:"String",
		source:"String",
		user_ip:"String",
		username:"String"
	},
	voyce_guest_gtags_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_guest_gtags"
	},
	voyce_guest_gtags_stddev_fields:{
		id:"Float"
	},
	voyce_guest_gtags_stddev_pop_fields:{
		id:"Float"
	},
	voyce_guest_gtags_stddev_samp_fields:{
		id:"Float"
	},
	voyce_guest_gtags_sum_fields:{
		id:"Int"
	},
	voyce_guest_gtags_var_pop_fields:{
		id:"Float"
	},
	voyce_guest_gtags_var_samp_fields:{
		id:"Float"
	},
	voyce_guest_gtags_variance_fields:{
		id:"Float"
	},
	voyce_metabases:{
		access:"voyce_metabases_access",
		created_at:"timestamptz",
		dashboard_id:"Int",
		id:"Int",
		is_active:"Boolean",
		order:"Int",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_metabases_access:{
		created_at:"timestamptz",
		id:"Int",
		metabase_id:"Int",
		role_id:"Int",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_metabases_access_aggregate:{
		aggregate:"voyce_metabases_access_aggregate_fields",
		nodes:"voyce_metabases_access"
	},
	voyce_metabases_access_aggregate_fields:{
		avg:"voyce_metabases_access_avg_fields",
		count:"Int",
		max:"voyce_metabases_access_max_fields",
		min:"voyce_metabases_access_min_fields",
		stddev:"voyce_metabases_access_stddev_fields",
		stddev_pop:"voyce_metabases_access_stddev_pop_fields",
		stddev_samp:"voyce_metabases_access_stddev_samp_fields",
		sum:"voyce_metabases_access_sum_fields",
		var_pop:"voyce_metabases_access_var_pop_fields",
		var_samp:"voyce_metabases_access_var_samp_fields",
		variance:"voyce_metabases_access_variance_fields"
	},
	voyce_metabases_access_avg_fields:{
		id:"Float",
		metabase_id:"Float",
		role_id:"Float",
		user_id:"Float"
	},
	voyce_metabases_access_max_fields:{
		created_at:"timestamptz",
		id:"Int",
		metabase_id:"Int",
		role_id:"Int",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_metabases_access_min_fields:{
		created_at:"timestamptz",
		id:"Int",
		metabase_id:"Int",
		role_id:"Int",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_metabases_access_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_metabases_access"
	},
	voyce_metabases_access_stddev_fields:{
		id:"Float",
		metabase_id:"Float",
		role_id:"Float",
		user_id:"Float"
	},
	voyce_metabases_access_stddev_pop_fields:{
		id:"Float",
		metabase_id:"Float",
		role_id:"Float",
		user_id:"Float"
	},
	voyce_metabases_access_stddev_samp_fields:{
		id:"Float",
		metabase_id:"Float",
		role_id:"Float",
		user_id:"Float"
	},
	voyce_metabases_access_sum_fields:{
		id:"Int",
		metabase_id:"Int",
		role_id:"Int",
		user_id:"Int"
	},
	voyce_metabases_access_var_pop_fields:{
		id:"Float",
		metabase_id:"Float",
		role_id:"Float",
		user_id:"Float"
	},
	voyce_metabases_access_var_samp_fields:{
		id:"Float",
		metabase_id:"Float",
		role_id:"Float",
		user_id:"Float"
	},
	voyce_metabases_access_variance_fields:{
		id:"Float",
		metabase_id:"Float",
		role_id:"Float",
		user_id:"Float"
	},
	voyce_metabases_aggregate:{
		aggregate:"voyce_metabases_aggregate_fields",
		nodes:"voyce_metabases"
	},
	voyce_metabases_aggregate_fields:{
		avg:"voyce_metabases_avg_fields",
		count:"Int",
		max:"voyce_metabases_max_fields",
		min:"voyce_metabases_min_fields",
		stddev:"voyce_metabases_stddev_fields",
		stddev_pop:"voyce_metabases_stddev_pop_fields",
		stddev_samp:"voyce_metabases_stddev_samp_fields",
		sum:"voyce_metabases_sum_fields",
		var_pop:"voyce_metabases_var_pop_fields",
		var_samp:"voyce_metabases_var_samp_fields",
		variance:"voyce_metabases_variance_fields"
	},
	voyce_metabases_avg_fields:{
		dashboard_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_metabases_max_fields:{
		created_at:"timestamptz",
		dashboard_id:"Int",
		id:"Int",
		order:"Int",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_metabases_min_fields:{
		created_at:"timestamptz",
		dashboard_id:"Int",
		id:"Int",
		order:"Int",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_metabases_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_metabases"
	},
	voyce_metabases_stddev_fields:{
		dashboard_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_metabases_stddev_pop_fields:{
		dashboard_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_metabases_stddev_samp_fields:{
		dashboard_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_metabases_sum_fields:{
		dashboard_id:"Int",
		id:"Int",
		order:"Int"
	},
	voyce_metabases_var_pop_fields:{
		dashboard_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_metabases_var_samp_fields:{
		dashboard_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_metabases_variance_fields:{
		dashboard_id:"Float",
		id:"Float",
		order:"Float"
	},
	voyce_newsletter:{
		created_at:"timestamptz",
		email:"String",
		id:"Int",
		user_id:"Int"
	},
	voyce_newsletter_aggregate:{
		aggregate:"voyce_newsletter_aggregate_fields",
		nodes:"voyce_newsletter"
	},
	voyce_newsletter_aggregate_fields:{
		avg:"voyce_newsletter_avg_fields",
		count:"Int",
		max:"voyce_newsletter_max_fields",
		min:"voyce_newsletter_min_fields",
		stddev:"voyce_newsletter_stddev_fields",
		stddev_pop:"voyce_newsletter_stddev_pop_fields",
		stddev_samp:"voyce_newsletter_stddev_samp_fields",
		sum:"voyce_newsletter_sum_fields",
		var_pop:"voyce_newsletter_var_pop_fields",
		var_samp:"voyce_newsletter_var_samp_fields",
		variance:"voyce_newsletter_variance_fields"
	},
	voyce_newsletter_avg_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_newsletter_max_fields:{
		created_at:"timestamptz",
		email:"String",
		id:"Int",
		user_id:"Int"
	},
	voyce_newsletter_min_fields:{
		created_at:"timestamptz",
		email:"String",
		id:"Int",
		user_id:"Int"
	},
	voyce_newsletter_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_newsletter"
	},
	voyce_newsletter_stddev_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_newsletter_stddev_pop_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_newsletter_stddev_samp_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_newsletter_sum_fields:{
		id:"Int",
		user_id:"Int"
	},
	voyce_newsletter_var_pop_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_newsletter_var_samp_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_newsletter_variance_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_notification_series_settings:{
		chapter_comment_email:"smallint",
		chapter_comment_push:"smallint",
		chapter_like_email:"smallint",
		chapter_like_push:"smallint",
		chapter_react_email:"smallint",
		chapter_react_push:"smallint",
		id:"Int",
		series_id:"Int",
		subscription_email:"smallint",
		subscription_push:"smallint",
		user_id:"Int"
	},
	voyce_notification_series_settings_aggregate:{
		aggregate:"voyce_notification_series_settings_aggregate_fields",
		nodes:"voyce_notification_series_settings"
	},
	voyce_notification_series_settings_aggregate_fields:{
		avg:"voyce_notification_series_settings_avg_fields",
		count:"Int",
		max:"voyce_notification_series_settings_max_fields",
		min:"voyce_notification_series_settings_min_fields",
		stddev:"voyce_notification_series_settings_stddev_fields",
		stddev_pop:"voyce_notification_series_settings_stddev_pop_fields",
		stddev_samp:"voyce_notification_series_settings_stddev_samp_fields",
		sum:"voyce_notification_series_settings_sum_fields",
		var_pop:"voyce_notification_series_settings_var_pop_fields",
		var_samp:"voyce_notification_series_settings_var_samp_fields",
		variance:"voyce_notification_series_settings_variance_fields"
	},
	voyce_notification_series_settings_avg_fields:{
		chapter_comment_email:"Float",
		chapter_comment_push:"Float",
		chapter_like_email:"Float",
		chapter_like_push:"Float",
		chapter_react_email:"Float",
		chapter_react_push:"Float",
		id:"Float",
		series_id:"Float",
		subscription_email:"Float",
		subscription_push:"Float",
		user_id:"Float"
	},
	voyce_notification_series_settings_max_fields:{
		chapter_comment_email:"smallint",
		chapter_comment_push:"smallint",
		chapter_like_email:"smallint",
		chapter_like_push:"smallint",
		chapter_react_email:"smallint",
		chapter_react_push:"smallint",
		id:"Int",
		series_id:"Int",
		subscription_email:"smallint",
		subscription_push:"smallint",
		user_id:"Int"
	},
	voyce_notification_series_settings_min_fields:{
		chapter_comment_email:"smallint",
		chapter_comment_push:"smallint",
		chapter_like_email:"smallint",
		chapter_like_push:"smallint",
		chapter_react_email:"smallint",
		chapter_react_push:"smallint",
		id:"Int",
		series_id:"Int",
		subscription_email:"smallint",
		subscription_push:"smallint",
		user_id:"Int"
	},
	voyce_notification_series_settings_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_notification_series_settings"
	},
	voyce_notification_series_settings_stddev_fields:{
		chapter_comment_email:"Float",
		chapter_comment_push:"Float",
		chapter_like_email:"Float",
		chapter_like_push:"Float",
		chapter_react_email:"Float",
		chapter_react_push:"Float",
		id:"Float",
		series_id:"Float",
		subscription_email:"Float",
		subscription_push:"Float",
		user_id:"Float"
	},
	voyce_notification_series_settings_stddev_pop_fields:{
		chapter_comment_email:"Float",
		chapter_comment_push:"Float",
		chapter_like_email:"Float",
		chapter_like_push:"Float",
		chapter_react_email:"Float",
		chapter_react_push:"Float",
		id:"Float",
		series_id:"Float",
		subscription_email:"Float",
		subscription_push:"Float",
		user_id:"Float"
	},
	voyce_notification_series_settings_stddev_samp_fields:{
		chapter_comment_email:"Float",
		chapter_comment_push:"Float",
		chapter_like_email:"Float",
		chapter_like_push:"Float",
		chapter_react_email:"Float",
		chapter_react_push:"Float",
		id:"Float",
		series_id:"Float",
		subscription_email:"Float",
		subscription_push:"Float",
		user_id:"Float"
	},
	voyce_notification_series_settings_sum_fields:{
		chapter_comment_email:"smallint",
		chapter_comment_push:"smallint",
		chapter_like_email:"smallint",
		chapter_like_push:"smallint",
		chapter_react_email:"smallint",
		chapter_react_push:"smallint",
		id:"Int",
		series_id:"Int",
		subscription_email:"smallint",
		subscription_push:"smallint",
		user_id:"Int"
	},
	voyce_notification_series_settings_var_pop_fields:{
		chapter_comment_email:"Float",
		chapter_comment_push:"Float",
		chapter_like_email:"Float",
		chapter_like_push:"Float",
		chapter_react_email:"Float",
		chapter_react_push:"Float",
		id:"Float",
		series_id:"Float",
		subscription_email:"Float",
		subscription_push:"Float",
		user_id:"Float"
	},
	voyce_notification_series_settings_var_samp_fields:{
		chapter_comment_email:"Float",
		chapter_comment_push:"Float",
		chapter_like_email:"Float",
		chapter_like_push:"Float",
		chapter_react_email:"Float",
		chapter_react_push:"Float",
		id:"Float",
		series_id:"Float",
		subscription_email:"Float",
		subscription_push:"Float",
		user_id:"Float"
	},
	voyce_notification_series_settings_variance_fields:{
		chapter_comment_email:"Float",
		chapter_comment_push:"Float",
		chapter_like_email:"Float",
		chapter_like_push:"Float",
		chapter_react_email:"Float",
		chapter_react_push:"Float",
		id:"Float",
		series_id:"Float",
		subscription_email:"Float",
		subscription_push:"Float",
		user_id:"Float"
	},
	voyce_notification_settings:{
		chapter_comment_email:"smallint",
		chapter_comment_push:"smallint",
		chapter_like_email:"smallint",
		chapter_like_push:"smallint",
		chapter_react_email:"smallint",
		chapter_react_push:"smallint",
		chapter_release_email:"smallint",
		chapter_release_push:"smallint",
		comment_react_email:"smallint",
		comment_react_push:"smallint",
		comment_reply_email:"smallint",
		comment_reply_push:"smallint",
		event_email:"smallint",
		event_push:"smallint",
		follow_email:"smallint",
		follow_push:"smallint",
		id:"Int",
		promotion_email:"smallint",
		promotion_push:"smallint",
		recommended_series_email:"smallint",
		recommended_series_push:"smallint",
		reward_email:"smallint",
		reward_push:"smallint",
		subscription_email:"smallint",
		subscription_push:"smallint",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_notification_settings_aggregate:{
		aggregate:"voyce_notification_settings_aggregate_fields",
		nodes:"voyce_notification_settings"
	},
	voyce_notification_settings_aggregate_fields:{
		avg:"voyce_notification_settings_avg_fields",
		count:"Int",
		max:"voyce_notification_settings_max_fields",
		min:"voyce_notification_settings_min_fields",
		stddev:"voyce_notification_settings_stddev_fields",
		stddev_pop:"voyce_notification_settings_stddev_pop_fields",
		stddev_samp:"voyce_notification_settings_stddev_samp_fields",
		sum:"voyce_notification_settings_sum_fields",
		var_pop:"voyce_notification_settings_var_pop_fields",
		var_samp:"voyce_notification_settings_var_samp_fields",
		variance:"voyce_notification_settings_variance_fields"
	},
	voyce_notification_settings_avg_fields:{
		chapter_comment_email:"Float",
		chapter_comment_push:"Float",
		chapter_like_email:"Float",
		chapter_like_push:"Float",
		chapter_react_email:"Float",
		chapter_react_push:"Float",
		chapter_release_email:"Float",
		chapter_release_push:"Float",
		comment_react_email:"Float",
		comment_react_push:"Float",
		comment_reply_email:"Float",
		comment_reply_push:"Float",
		event_email:"Float",
		event_push:"Float",
		follow_email:"Float",
		follow_push:"Float",
		id:"Float",
		promotion_email:"Float",
		promotion_push:"Float",
		recommended_series_email:"Float",
		recommended_series_push:"Float",
		reward_email:"Float",
		reward_push:"Float",
		subscription_email:"Float",
		subscription_push:"Float",
		user_id:"Float"
	},
	voyce_notification_settings_max_fields:{
		chapter_comment_email:"smallint",
		chapter_comment_push:"smallint",
		chapter_like_email:"smallint",
		chapter_like_push:"smallint",
		chapter_react_email:"smallint",
		chapter_react_push:"smallint",
		chapter_release_email:"smallint",
		chapter_release_push:"smallint",
		comment_react_email:"smallint",
		comment_react_push:"smallint",
		comment_reply_email:"smallint",
		comment_reply_push:"smallint",
		event_email:"smallint",
		event_push:"smallint",
		follow_email:"smallint",
		follow_push:"smallint",
		id:"Int",
		promotion_email:"smallint",
		promotion_push:"smallint",
		recommended_series_email:"smallint",
		recommended_series_push:"smallint",
		reward_email:"smallint",
		reward_push:"smallint",
		subscription_email:"smallint",
		subscription_push:"smallint",
		user_id:"Int"
	},
	voyce_notification_settings_min_fields:{
		chapter_comment_email:"smallint",
		chapter_comment_push:"smallint",
		chapter_like_email:"smallint",
		chapter_like_push:"smallint",
		chapter_react_email:"smallint",
		chapter_react_push:"smallint",
		chapter_release_email:"smallint",
		chapter_release_push:"smallint",
		comment_react_email:"smallint",
		comment_react_push:"smallint",
		comment_reply_email:"smallint",
		comment_reply_push:"smallint",
		event_email:"smallint",
		event_push:"smallint",
		follow_email:"smallint",
		follow_push:"smallint",
		id:"Int",
		promotion_email:"smallint",
		promotion_push:"smallint",
		recommended_series_email:"smallint",
		recommended_series_push:"smallint",
		reward_email:"smallint",
		reward_push:"smallint",
		subscription_email:"smallint",
		subscription_push:"smallint",
		user_id:"Int"
	},
	voyce_notification_settings_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_notification_settings"
	},
	voyce_notification_settings_stddev_fields:{
		chapter_comment_email:"Float",
		chapter_comment_push:"Float",
		chapter_like_email:"Float",
		chapter_like_push:"Float",
		chapter_react_email:"Float",
		chapter_react_push:"Float",
		chapter_release_email:"Float",
		chapter_release_push:"Float",
		comment_react_email:"Float",
		comment_react_push:"Float",
		comment_reply_email:"Float",
		comment_reply_push:"Float",
		event_email:"Float",
		event_push:"Float",
		follow_email:"Float",
		follow_push:"Float",
		id:"Float",
		promotion_email:"Float",
		promotion_push:"Float",
		recommended_series_email:"Float",
		recommended_series_push:"Float",
		reward_email:"Float",
		reward_push:"Float",
		subscription_email:"Float",
		subscription_push:"Float",
		user_id:"Float"
	},
	voyce_notification_settings_stddev_pop_fields:{
		chapter_comment_email:"Float",
		chapter_comment_push:"Float",
		chapter_like_email:"Float",
		chapter_like_push:"Float",
		chapter_react_email:"Float",
		chapter_react_push:"Float",
		chapter_release_email:"Float",
		chapter_release_push:"Float",
		comment_react_email:"Float",
		comment_react_push:"Float",
		comment_reply_email:"Float",
		comment_reply_push:"Float",
		event_email:"Float",
		event_push:"Float",
		follow_email:"Float",
		follow_push:"Float",
		id:"Float",
		promotion_email:"Float",
		promotion_push:"Float",
		recommended_series_email:"Float",
		recommended_series_push:"Float",
		reward_email:"Float",
		reward_push:"Float",
		subscription_email:"Float",
		subscription_push:"Float",
		user_id:"Float"
	},
	voyce_notification_settings_stddev_samp_fields:{
		chapter_comment_email:"Float",
		chapter_comment_push:"Float",
		chapter_like_email:"Float",
		chapter_like_push:"Float",
		chapter_react_email:"Float",
		chapter_react_push:"Float",
		chapter_release_email:"Float",
		chapter_release_push:"Float",
		comment_react_email:"Float",
		comment_react_push:"Float",
		comment_reply_email:"Float",
		comment_reply_push:"Float",
		event_email:"Float",
		event_push:"Float",
		follow_email:"Float",
		follow_push:"Float",
		id:"Float",
		promotion_email:"Float",
		promotion_push:"Float",
		recommended_series_email:"Float",
		recommended_series_push:"Float",
		reward_email:"Float",
		reward_push:"Float",
		subscription_email:"Float",
		subscription_push:"Float",
		user_id:"Float"
	},
	voyce_notification_settings_sum_fields:{
		chapter_comment_email:"smallint",
		chapter_comment_push:"smallint",
		chapter_like_email:"smallint",
		chapter_like_push:"smallint",
		chapter_react_email:"smallint",
		chapter_react_push:"smallint",
		chapter_release_email:"smallint",
		chapter_release_push:"smallint",
		comment_react_email:"smallint",
		comment_react_push:"smallint",
		comment_reply_email:"smallint",
		comment_reply_push:"smallint",
		event_email:"smallint",
		event_push:"smallint",
		follow_email:"smallint",
		follow_push:"smallint",
		id:"Int",
		promotion_email:"smallint",
		promotion_push:"smallint",
		recommended_series_email:"smallint",
		recommended_series_push:"smallint",
		reward_email:"smallint",
		reward_push:"smallint",
		subscription_email:"smallint",
		subscription_push:"smallint",
		user_id:"Int"
	},
	voyce_notification_settings_var_pop_fields:{
		chapter_comment_email:"Float",
		chapter_comment_push:"Float",
		chapter_like_email:"Float",
		chapter_like_push:"Float",
		chapter_react_email:"Float",
		chapter_react_push:"Float",
		chapter_release_email:"Float",
		chapter_release_push:"Float",
		comment_react_email:"Float",
		comment_react_push:"Float",
		comment_reply_email:"Float",
		comment_reply_push:"Float",
		event_email:"Float",
		event_push:"Float",
		follow_email:"Float",
		follow_push:"Float",
		id:"Float",
		promotion_email:"Float",
		promotion_push:"Float",
		recommended_series_email:"Float",
		recommended_series_push:"Float",
		reward_email:"Float",
		reward_push:"Float",
		subscription_email:"Float",
		subscription_push:"Float",
		user_id:"Float"
	},
	voyce_notification_settings_var_samp_fields:{
		chapter_comment_email:"Float",
		chapter_comment_push:"Float",
		chapter_like_email:"Float",
		chapter_like_push:"Float",
		chapter_react_email:"Float",
		chapter_react_push:"Float",
		chapter_release_email:"Float",
		chapter_release_push:"Float",
		comment_react_email:"Float",
		comment_react_push:"Float",
		comment_reply_email:"Float",
		comment_reply_push:"Float",
		event_email:"Float",
		event_push:"Float",
		follow_email:"Float",
		follow_push:"Float",
		id:"Float",
		promotion_email:"Float",
		promotion_push:"Float",
		recommended_series_email:"Float",
		recommended_series_push:"Float",
		reward_email:"Float",
		reward_push:"Float",
		subscription_email:"Float",
		subscription_push:"Float",
		user_id:"Float"
	},
	voyce_notification_settings_variance_fields:{
		chapter_comment_email:"Float",
		chapter_comment_push:"Float",
		chapter_like_email:"Float",
		chapter_like_push:"Float",
		chapter_react_email:"Float",
		chapter_react_push:"Float",
		chapter_release_email:"Float",
		chapter_release_push:"Float",
		comment_react_email:"Float",
		comment_react_push:"Float",
		comment_reply_email:"Float",
		comment_reply_push:"Float",
		event_email:"Float",
		event_push:"Float",
		follow_email:"Float",
		follow_push:"Float",
		id:"Float",
		promotion_email:"Float",
		promotion_push:"Float",
		recommended_series_email:"Float",
		recommended_series_push:"Float",
		reward_email:"Float",
		reward_push:"Float",
		subscription_email:"Float",
		subscription_push:"Float",
		user_id:"Float"
	},
	voyce_notifications:{
		child_count:"Int",
		created_at:"timestamptz",
		data:"jsonb",
		email_status:"String",
		emailed_at:"timestamptz",
		id:"Int",
		linked_id:"Int",
		parent_id:"Int",
		pushed_at:"timestamptz",
		read_at:"timestamptz",
		type:"String",
		unsubscribe_id:"uuid",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_notifications_aggregate:{
		aggregate:"voyce_notifications_aggregate_fields",
		nodes:"voyce_notifications"
	},
	voyce_notifications_aggregate_fields:{
		avg:"voyce_notifications_avg_fields",
		count:"Int",
		max:"voyce_notifications_max_fields",
		min:"voyce_notifications_min_fields",
		stddev:"voyce_notifications_stddev_fields",
		stddev_pop:"voyce_notifications_stddev_pop_fields",
		stddev_samp:"voyce_notifications_stddev_samp_fields",
		sum:"voyce_notifications_sum_fields",
		var_pop:"voyce_notifications_var_pop_fields",
		var_samp:"voyce_notifications_var_samp_fields",
		variance:"voyce_notifications_variance_fields"
	},
	voyce_notifications_avg_fields:{
		child_count:"Float",
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		user_id:"Float"
	},
	voyce_notifications_max_fields:{
		child_count:"Int",
		created_at:"timestamptz",
		email_status:"String",
		emailed_at:"timestamptz",
		id:"Int",
		linked_id:"Int",
		parent_id:"Int",
		pushed_at:"timestamptz",
		read_at:"timestamptz",
		type:"String",
		unsubscribe_id:"uuid",
		user_id:"Int"
	},
	voyce_notifications_min_fields:{
		child_count:"Int",
		created_at:"timestamptz",
		email_status:"String",
		emailed_at:"timestamptz",
		id:"Int",
		linked_id:"Int",
		parent_id:"Int",
		pushed_at:"timestamptz",
		read_at:"timestamptz",
		type:"String",
		unsubscribe_id:"uuid",
		user_id:"Int"
	},
	voyce_notifications_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_notifications"
	},
	voyce_notifications_stddev_fields:{
		child_count:"Float",
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		user_id:"Float"
	},
	voyce_notifications_stddev_pop_fields:{
		child_count:"Float",
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		user_id:"Float"
	},
	voyce_notifications_stddev_samp_fields:{
		child_count:"Float",
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		user_id:"Float"
	},
	voyce_notifications_sum_fields:{
		child_count:"Int",
		id:"Int",
		linked_id:"Int",
		parent_id:"Int",
		user_id:"Int"
	},
	voyce_notifications_var_pop_fields:{
		child_count:"Float",
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		user_id:"Float"
	},
	voyce_notifications_var_samp_fields:{
		child_count:"Float",
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		user_id:"Float"
	},
	voyce_notifications_variance_fields:{
		child_count:"Float",
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		user_id:"Float"
	},
	voyce_originals_feed:{
		category:"String",
		created_at:"timestamptz",
		desc:"String",
		id:"String",
		image:"String",
		title:"String",
		url:"String"
	},
	voyce_originals_feed_aggregate:{
		aggregate:"voyce_originals_feed_aggregate_fields",
		nodes:"voyce_originals_feed"
	},
	voyce_originals_feed_aggregate_fields:{
		count:"Int",
		max:"voyce_originals_feed_max_fields",
		min:"voyce_originals_feed_min_fields"
	},
	voyce_originals_feed_max_fields:{
		category:"String",
		created_at:"timestamptz",
		desc:"String",
		id:"String",
		image:"String",
		title:"String",
		url:"String"
	},
	voyce_originals_feed_min_fields:{
		category:"String",
		created_at:"timestamptz",
		desc:"String",
		id:"String",
		image:"String",
		title:"String",
		url:"String"
	},
	voyce_page_banners:{
		active:"Boolean",
		background:"String",
		created_at:"timestamptz",
		desktop:"String",
		id:"Int",
		link:"String",
		mobile:"String",
		overlap:"Boolean",
		page:"String",
		sort:"smallint",
		tablet:"String",
		updated_at:"timestamptz"
	},
	voyce_page_banners_aggregate:{
		aggregate:"voyce_page_banners_aggregate_fields",
		nodes:"voyce_page_banners"
	},
	voyce_page_banners_aggregate_fields:{
		avg:"voyce_page_banners_avg_fields",
		count:"Int",
		max:"voyce_page_banners_max_fields",
		min:"voyce_page_banners_min_fields",
		stddev:"voyce_page_banners_stddev_fields",
		stddev_pop:"voyce_page_banners_stddev_pop_fields",
		stddev_samp:"voyce_page_banners_stddev_samp_fields",
		sum:"voyce_page_banners_sum_fields",
		var_pop:"voyce_page_banners_var_pop_fields",
		var_samp:"voyce_page_banners_var_samp_fields",
		variance:"voyce_page_banners_variance_fields"
	},
	voyce_page_banners_avg_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_page_banners_max_fields:{
		background:"String",
		created_at:"timestamptz",
		desktop:"String",
		id:"Int",
		link:"String",
		mobile:"String",
		page:"String",
		sort:"smallint",
		tablet:"String",
		updated_at:"timestamptz"
	},
	voyce_page_banners_min_fields:{
		background:"String",
		created_at:"timestamptz",
		desktop:"String",
		id:"Int",
		link:"String",
		mobile:"String",
		page:"String",
		sort:"smallint",
		tablet:"String",
		updated_at:"timestamptz"
	},
	voyce_page_banners_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_page_banners"
	},
	voyce_page_banners_stddev_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_page_banners_stddev_pop_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_page_banners_stddev_samp_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_page_banners_sum_fields:{
		id:"Int",
		sort:"smallint"
	},
	voyce_page_banners_var_pop_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_page_banners_var_samp_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_page_banners_variance_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_page_components:{
		active:"Boolean",
		auth:"Boolean",
		component:"String",
		created_at:"timestamptz",
		device:"String",
		id:"Int",
		page:"String",
		props:"json",
		sort:"smallint",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_page_components_aggregate:{
		aggregate:"voyce_page_components_aggregate_fields",
		nodes:"voyce_page_components"
	},
	voyce_page_components_aggregate_fields:{
		avg:"voyce_page_components_avg_fields",
		count:"Int",
		max:"voyce_page_components_max_fields",
		min:"voyce_page_components_min_fields",
		stddev:"voyce_page_components_stddev_fields",
		stddev_pop:"voyce_page_components_stddev_pop_fields",
		stddev_samp:"voyce_page_components_stddev_samp_fields",
		sum:"voyce_page_components_sum_fields",
		var_pop:"voyce_page_components_var_pop_fields",
		var_samp:"voyce_page_components_var_samp_fields",
		variance:"voyce_page_components_variance_fields"
	},
	voyce_page_components_avg_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_page_components_max_fields:{
		component:"String",
		created_at:"timestamptz",
		device:"String",
		id:"Int",
		page:"String",
		sort:"smallint",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_page_components_min_fields:{
		component:"String",
		created_at:"timestamptz",
		device:"String",
		id:"Int",
		page:"String",
		sort:"smallint",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_page_components_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_page_components"
	},
	voyce_page_components_stddev_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_page_components_stddev_pop_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_page_components_stddev_samp_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_page_components_sum_fields:{
		id:"Int",
		sort:"smallint"
	},
	voyce_page_components_var_pop_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_page_components_var_samp_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_page_components_variance_fields:{
		id:"Float",
		sort:"Float"
	},
	voyce_pages:{
		content:"String",
		created_at:"timestamp",
		id:"Int",
		slug:"String",
		title:"String",
		updated_at:"timestamp"
	},
	voyce_pages_aggregate:{
		aggregate:"voyce_pages_aggregate_fields",
		nodes:"voyce_pages"
	},
	voyce_pages_aggregate_fields:{
		avg:"voyce_pages_avg_fields",
		count:"Int",
		max:"voyce_pages_max_fields",
		min:"voyce_pages_min_fields",
		stddev:"voyce_pages_stddev_fields",
		stddev_pop:"voyce_pages_stddev_pop_fields",
		stddev_samp:"voyce_pages_stddev_samp_fields",
		sum:"voyce_pages_sum_fields",
		var_pop:"voyce_pages_var_pop_fields",
		var_samp:"voyce_pages_var_samp_fields",
		variance:"voyce_pages_variance_fields"
	},
	voyce_pages_avg_fields:{
		id:"Float"
	},
	voyce_pages_max_fields:{
		content:"String",
		created_at:"timestamp",
		id:"Int",
		slug:"String",
		title:"String",
		updated_at:"timestamp"
	},
	voyce_pages_min_fields:{
		content:"String",
		created_at:"timestamp",
		id:"Int",
		slug:"String",
		title:"String",
		updated_at:"timestamp"
	},
	voyce_pages_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_pages"
	},
	voyce_pages_stddev_fields:{
		id:"Float"
	},
	voyce_pages_stddev_pop_fields:{
		id:"Float"
	},
	voyce_pages_stddev_samp_fields:{
		id:"Float"
	},
	voyce_pages_sum_fields:{
		id:"Int"
	},
	voyce_pages_var_pop_fields:{
		id:"Float"
	},
	voyce_pages_var_samp_fields:{
		id:"Float"
	},
	voyce_pages_variance_fields:{
		id:"Float"
	},
	voyce_panels_comments:{
		animated_image:"String",
		chapter_panel:"voyce_chapter_panels",
		created_at:"timestamptz",
		id:"Int",
		image:"String",
		linked_id:"Int",
		panels_comment:"voyce_panels_comments",
		panels_comments:"voyce_panels_comments",
		panels_comments_aggregate:"voyce_panels_comments_aggregate",
		panels_reactions:"voyce_panels_reactions",
		panels_reactions_aggregate:"voyce_panels_reactions_aggregate",
		parent_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		text:"String",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_panels_comments_aggregate:{
		aggregate:"voyce_panels_comments_aggregate_fields",
		nodes:"voyce_panels_comments"
	},
	voyce_panels_comments_aggregate_fields:{
		avg:"voyce_panels_comments_avg_fields",
		count:"Int",
		max:"voyce_panels_comments_max_fields",
		min:"voyce_panels_comments_min_fields",
		stddev:"voyce_panels_comments_stddev_fields",
		stddev_pop:"voyce_panels_comments_stddev_pop_fields",
		stddev_samp:"voyce_panels_comments_stddev_samp_fields",
		sum:"voyce_panels_comments_sum_fields",
		var_pop:"voyce_panels_comments_var_pop_fields",
		var_samp:"voyce_panels_comments_var_samp_fields",
		variance:"voyce_panels_comments_variance_fields"
	},
	voyce_panels_comments_avg_fields:{
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_panels_comments_max_fields:{
		animated_image:"String",
		created_at:"timestamptz",
		id:"Int",
		image:"String",
		linked_id:"Int",
		parent_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		text:"String",
		user_id:"Int"
	},
	voyce_panels_comments_min_fields:{
		animated_image:"String",
		created_at:"timestamptz",
		id:"Int",
		image:"String",
		linked_id:"Int",
		parent_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		text:"String",
		user_id:"Int"
	},
	voyce_panels_comments_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_panels_comments"
	},
	voyce_panels_comments_stddev_fields:{
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_panels_comments_stddev_pop_fields:{
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_panels_comments_stddev_samp_fields:{
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_panels_comments_sum_fields:{
		id:"Int",
		linked_id:"Int",
		parent_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		user_id:"Int"
	},
	voyce_panels_comments_var_pop_fields:{
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_panels_comments_var_samp_fields:{
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_panels_comments_variance_fields:{
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_panels_reactions:{
		chapter_panel:"voyce_chapter_panels",
		comment_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		linked_entity_id:"Int",
		panels_comment:"voyce_panels_comments",
		reaction:"Int",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_panels_reactions_aggregate:{
		aggregate:"voyce_panels_reactions_aggregate_fields",
		nodes:"voyce_panels_reactions"
	},
	voyce_panels_reactions_aggregate_fields:{
		avg:"voyce_panels_reactions_avg_fields",
		count:"Int",
		max:"voyce_panels_reactions_max_fields",
		min:"voyce_panels_reactions_min_fields",
		stddev:"voyce_panels_reactions_stddev_fields",
		stddev_pop:"voyce_panels_reactions_stddev_pop_fields",
		stddev_samp:"voyce_panels_reactions_stddev_samp_fields",
		sum:"voyce_panels_reactions_sum_fields",
		var_pop:"voyce_panels_reactions_var_pop_fields",
		var_samp:"voyce_panels_reactions_var_samp_fields",
		variance:"voyce_panels_reactions_variance_fields"
	},
	voyce_panels_reactions_avg_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_panels_reactions_count:{
		chapter_panel:"voyce_chapter_panels",
		count:"Int",
		id:"Int",
		panel_id:"Int",
		reaction:"Int"
	},
	voyce_panels_reactions_count_aggregate:{
		aggregate:"voyce_panels_reactions_count_aggregate_fields",
		nodes:"voyce_panels_reactions_count"
	},
	voyce_panels_reactions_count_aggregate_fields:{
		avg:"voyce_panels_reactions_count_avg_fields",
		count:"Int",
		max:"voyce_panels_reactions_count_max_fields",
		min:"voyce_panels_reactions_count_min_fields",
		stddev:"voyce_panels_reactions_count_stddev_fields",
		stddev_pop:"voyce_panels_reactions_count_stddev_pop_fields",
		stddev_samp:"voyce_panels_reactions_count_stddev_samp_fields",
		sum:"voyce_panels_reactions_count_sum_fields",
		var_pop:"voyce_panels_reactions_count_var_pop_fields",
		var_samp:"voyce_panels_reactions_count_var_samp_fields",
		variance:"voyce_panels_reactions_count_variance_fields"
	},
	voyce_panels_reactions_count_avg_fields:{
		count:"Float",
		id:"Float",
		panel_id:"Float",
		reaction:"Float"
	},
	voyce_panels_reactions_count_max_fields:{
		count:"Int",
		id:"Int",
		panel_id:"Int",
		reaction:"Int"
	},
	voyce_panels_reactions_count_min_fields:{
		count:"Int",
		id:"Int",
		panel_id:"Int",
		reaction:"Int"
	},
	voyce_panels_reactions_count_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_panels_reactions_count"
	},
	voyce_panels_reactions_count_stddev_fields:{
		count:"Float",
		id:"Float",
		panel_id:"Float",
		reaction:"Float"
	},
	voyce_panels_reactions_count_stddev_pop_fields:{
		count:"Float",
		id:"Float",
		panel_id:"Float",
		reaction:"Float"
	},
	voyce_panels_reactions_count_stddev_samp_fields:{
		count:"Float",
		id:"Float",
		panel_id:"Float",
		reaction:"Float"
	},
	voyce_panels_reactions_count_sum_fields:{
		count:"Int",
		id:"Int",
		panel_id:"Int",
		reaction:"Int"
	},
	voyce_panels_reactions_count_var_pop_fields:{
		count:"Float",
		id:"Float",
		panel_id:"Float",
		reaction:"Float"
	},
	voyce_panels_reactions_count_var_samp_fields:{
		count:"Float",
		id:"Float",
		panel_id:"Float",
		reaction:"Float"
	},
	voyce_panels_reactions_count_variance_fields:{
		count:"Float",
		id:"Float",
		panel_id:"Float",
		reaction:"Float"
	},
	voyce_panels_reactions_max_fields:{
		comment_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		linked_entity_id:"Int",
		reaction:"Int",
		user_id:"Int"
	},
	voyce_panels_reactions_min_fields:{
		comment_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		linked_entity_id:"Int",
		reaction:"Int",
		user_id:"Int"
	},
	voyce_panels_reactions_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_panels_reactions"
	},
	voyce_panels_reactions_stddev_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_panels_reactions_stddev_pop_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_panels_reactions_stddev_samp_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_panels_reactions_sum_fields:{
		comment_id:"Int",
		id:"Int",
		linked_entity_id:"Int",
		reaction:"Int",
		user_id:"Int"
	},
	voyce_panels_reactions_var_pop_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_panels_reactions_var_samp_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_panels_reactions_variance_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_payments_to_creators:{
		amount:"Int",
		created_at:"timestamptz",
		id:"Int",
		stripe_id:"String",
		user_from:"voyce_users",
		user_from_id:"Int",
		user_to:"voyce_users",
		user_to_id:"Int"
	},
	voyce_payments_to_creators_aggregate:{
		aggregate:"voyce_payments_to_creators_aggregate_fields",
		nodes:"voyce_payments_to_creators"
	},
	voyce_payments_to_creators_aggregate_fields:{
		avg:"voyce_payments_to_creators_avg_fields",
		count:"Int",
		max:"voyce_payments_to_creators_max_fields",
		min:"voyce_payments_to_creators_min_fields",
		stddev:"voyce_payments_to_creators_stddev_fields",
		stddev_pop:"voyce_payments_to_creators_stddev_pop_fields",
		stddev_samp:"voyce_payments_to_creators_stddev_samp_fields",
		sum:"voyce_payments_to_creators_sum_fields",
		var_pop:"voyce_payments_to_creators_var_pop_fields",
		var_samp:"voyce_payments_to_creators_var_samp_fields",
		variance:"voyce_payments_to_creators_variance_fields"
	},
	voyce_payments_to_creators_avg_fields:{
		amount:"Float",
		id:"Float",
		user_from_id:"Float",
		user_to_id:"Float"
	},
	voyce_payments_to_creators_max_fields:{
		amount:"Int",
		created_at:"timestamptz",
		id:"Int",
		stripe_id:"String",
		user_from_id:"Int",
		user_to_id:"Int"
	},
	voyce_payments_to_creators_min_fields:{
		amount:"Int",
		created_at:"timestamptz",
		id:"Int",
		stripe_id:"String",
		user_from_id:"Int",
		user_to_id:"Int"
	},
	voyce_payments_to_creators_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_payments_to_creators"
	},
	voyce_payments_to_creators_stddev_fields:{
		amount:"Float",
		id:"Float",
		user_from_id:"Float",
		user_to_id:"Float"
	},
	voyce_payments_to_creators_stddev_pop_fields:{
		amount:"Float",
		id:"Float",
		user_from_id:"Float",
		user_to_id:"Float"
	},
	voyce_payments_to_creators_stddev_samp_fields:{
		amount:"Float",
		id:"Float",
		user_from_id:"Float",
		user_to_id:"Float"
	},
	voyce_payments_to_creators_sum_fields:{
		amount:"Int",
		id:"Int",
		user_from_id:"Int",
		user_to_id:"Int"
	},
	voyce_payments_to_creators_var_pop_fields:{
		amount:"Float",
		id:"Float",
		user_from_id:"Float",
		user_to_id:"Float"
	},
	voyce_payments_to_creators_var_samp_fields:{
		amount:"Float",
		id:"Float",
		user_from_id:"Float",
		user_to_id:"Float"
	},
	voyce_payments_to_creators_variance_fields:{
		amount:"Float",
		id:"Float",
		user_from_id:"Float",
		user_to_id:"Float"
	},
	voyce_pg_rs_series_views_count:{
		count:"bigint",
		series_id:"Int"
	},
	voyce_pg_rs_series_views_count_aggregate:{
		aggregate:"voyce_pg_rs_series_views_count_aggregate_fields",
		nodes:"voyce_pg_rs_series_views_count"
	},
	voyce_pg_rs_series_views_count_aggregate_fields:{
		avg:"voyce_pg_rs_series_views_count_avg_fields",
		count:"Int",
		max:"voyce_pg_rs_series_views_count_max_fields",
		min:"voyce_pg_rs_series_views_count_min_fields",
		stddev:"voyce_pg_rs_series_views_count_stddev_fields",
		stddev_pop:"voyce_pg_rs_series_views_count_stddev_pop_fields",
		stddev_samp:"voyce_pg_rs_series_views_count_stddev_samp_fields",
		sum:"voyce_pg_rs_series_views_count_sum_fields",
		var_pop:"voyce_pg_rs_series_views_count_var_pop_fields",
		var_samp:"voyce_pg_rs_series_views_count_var_samp_fields",
		variance:"voyce_pg_rs_series_views_count_variance_fields"
	},
	voyce_pg_rs_series_views_count_avg_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_pg_rs_series_views_count_max_fields:{
		count:"bigint",
		series_id:"Int"
	},
	voyce_pg_rs_series_views_count_min_fields:{
		count:"bigint",
		series_id:"Int"
	},
	voyce_pg_rs_series_views_count_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_pg_rs_series_views_count"
	},
	voyce_pg_rs_series_views_count_stddev_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_pg_rs_series_views_count_stddev_pop_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_pg_rs_series_views_count_stddev_samp_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_pg_rs_series_views_count_sum_fields:{
		count:"bigint",
		series_id:"Int"
	},
	voyce_pg_rs_series_views_count_var_pop_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_pg_rs_series_views_count_var_samp_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_pg_rs_series_views_count_variance_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_polls:{
		comments_count:"Int",
		created_at:"timestamp",
		end_date:"timestamp",
		id:"Int",
		last_activity_date:"timestamp",
		likes_count:"Int",
		outdated:"Boolean",
		polls_answers:"voyce_polls_answers",
		polls_answers_aggregate:"voyce_polls_answers_aggregate",
		polls_comments:"voyce_polls_comments",
		polls_comments_aggregate:"voyce_polls_comments_aggregate",
		polls_reactions:"voyce_polls_reactions",
		polls_reactions_aggregate:"voyce_polls_reactions_aggregate",
		polls_votes:"voyce_polls_votes",
		polls_votes_aggregate:"voyce_polls_votes_aggregate",
		popularity:"Int",
		popularity_weekly:"Int",
		reactions_count:"Int",
		spoiler:"Boolean",
		title:"String",
		total_popularity:"Int",
		user:"voyce_users",
		user_id:"Int",
		votes_count:"Int"
	},
	voyce_polls_aggregate:{
		aggregate:"voyce_polls_aggregate_fields",
		nodes:"voyce_polls"
	},
	voyce_polls_aggregate_fields:{
		avg:"voyce_polls_avg_fields",
		count:"Int",
		max:"voyce_polls_max_fields",
		min:"voyce_polls_min_fields",
		stddev:"voyce_polls_stddev_fields",
		stddev_pop:"voyce_polls_stddev_pop_fields",
		stddev_samp:"voyce_polls_stddev_samp_fields",
		sum:"voyce_polls_sum_fields",
		var_pop:"voyce_polls_var_pop_fields",
		var_samp:"voyce_polls_var_samp_fields",
		variance:"voyce_polls_variance_fields"
	},
	voyce_polls_answers:{
		id:"Int",
		image:"String",
		order:"Int",
		poll:"voyce_polls",
		poll_id:"Int",
		polls_votes:"voyce_polls_votes",
		polls_votes_aggregate:"voyce_polls_votes_aggregate",
		text:"String",
		votes_count:"Int"
	},
	voyce_polls_answers_aggregate:{
		aggregate:"voyce_polls_answers_aggregate_fields",
		nodes:"voyce_polls_answers"
	},
	voyce_polls_answers_aggregate_fields:{
		avg:"voyce_polls_answers_avg_fields",
		count:"Int",
		max:"voyce_polls_answers_max_fields",
		min:"voyce_polls_answers_min_fields",
		stddev:"voyce_polls_answers_stddev_fields",
		stddev_pop:"voyce_polls_answers_stddev_pop_fields",
		stddev_samp:"voyce_polls_answers_stddev_samp_fields",
		sum:"voyce_polls_answers_sum_fields",
		var_pop:"voyce_polls_answers_var_pop_fields",
		var_samp:"voyce_polls_answers_var_samp_fields",
		variance:"voyce_polls_answers_variance_fields"
	},
	voyce_polls_answers_avg_fields:{
		id:"Float",
		order:"Float",
		poll_id:"Float",
		votes_count:"Float"
	},
	voyce_polls_answers_max_fields:{
		id:"Int",
		image:"String",
		order:"Int",
		poll_id:"Int",
		text:"String",
		votes_count:"Int"
	},
	voyce_polls_answers_min_fields:{
		id:"Int",
		image:"String",
		order:"Int",
		poll_id:"Int",
		text:"String",
		votes_count:"Int"
	},
	voyce_polls_answers_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_polls_answers"
	},
	voyce_polls_answers_stddev_fields:{
		id:"Float",
		order:"Float",
		poll_id:"Float",
		votes_count:"Float"
	},
	voyce_polls_answers_stddev_pop_fields:{
		id:"Float",
		order:"Float",
		poll_id:"Float",
		votes_count:"Float"
	},
	voyce_polls_answers_stddev_samp_fields:{
		id:"Float",
		order:"Float",
		poll_id:"Float",
		votes_count:"Float"
	},
	voyce_polls_answers_sum_fields:{
		id:"Int",
		order:"Int",
		poll_id:"Int",
		votes_count:"Int"
	},
	voyce_polls_answers_var_pop_fields:{
		id:"Float",
		order:"Float",
		poll_id:"Float",
		votes_count:"Float"
	},
	voyce_polls_answers_var_samp_fields:{
		id:"Float",
		order:"Float",
		poll_id:"Float",
		votes_count:"Float"
	},
	voyce_polls_answers_variance_fields:{
		id:"Float",
		order:"Float",
		poll_id:"Float",
		votes_count:"Float"
	},
	voyce_polls_avg_fields:{
		comments_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		total_popularity:"Float",
		user_id:"Float",
		votes_count:"Float"
	},
	voyce_polls_comments:{
		animated_image:"String",
		created_at:"timestamptz",
		id:"Int",
		image:"String",
		linked_id:"Int",
		parent_id:"Int",
		poll:"voyce_polls",
		polls_comment:"voyce_polls_comments",
		polls_comments:"voyce_polls_comments",
		polls_comments_aggregate:"voyce_polls_comments_aggregate",
		polls_reactions:"voyce_polls_reactions",
		polls_reactions_aggregate:"voyce_polls_reactions_aggregate",
		reaction_count:"Int",
		replies_count:"Int",
		text:"String",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_polls_comments_aggregate:{
		aggregate:"voyce_polls_comments_aggregate_fields",
		nodes:"voyce_polls_comments"
	},
	voyce_polls_comments_aggregate_fields:{
		avg:"voyce_polls_comments_avg_fields",
		count:"Int",
		max:"voyce_polls_comments_max_fields",
		min:"voyce_polls_comments_min_fields",
		stddev:"voyce_polls_comments_stddev_fields",
		stddev_pop:"voyce_polls_comments_stddev_pop_fields",
		stddev_samp:"voyce_polls_comments_stddev_samp_fields",
		sum:"voyce_polls_comments_sum_fields",
		var_pop:"voyce_polls_comments_var_pop_fields",
		var_samp:"voyce_polls_comments_var_samp_fields",
		variance:"voyce_polls_comments_variance_fields"
	},
	voyce_polls_comments_avg_fields:{
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_polls_comments_max_fields:{
		animated_image:"String",
		created_at:"timestamptz",
		id:"Int",
		image:"String",
		linked_id:"Int",
		parent_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		text:"String",
		user_id:"Int"
	},
	voyce_polls_comments_min_fields:{
		animated_image:"String",
		created_at:"timestamptz",
		id:"Int",
		image:"String",
		linked_id:"Int",
		parent_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		text:"String",
		user_id:"Int"
	},
	voyce_polls_comments_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_polls_comments"
	},
	voyce_polls_comments_stddev_fields:{
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_polls_comments_stddev_pop_fields:{
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_polls_comments_stddev_samp_fields:{
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_polls_comments_sum_fields:{
		id:"Int",
		linked_id:"Int",
		parent_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		user_id:"Int"
	},
	voyce_polls_comments_var_pop_fields:{
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_polls_comments_var_samp_fields:{
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_polls_comments_variance_fields:{
		id:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_polls_max_fields:{
		comments_count:"Int",
		created_at:"timestamp",
		end_date:"timestamp",
		id:"Int",
		last_activity_date:"timestamp",
		likes_count:"Int",
		popularity:"Int",
		popularity_weekly:"Int",
		reactions_count:"Int",
		title:"String",
		total_popularity:"Int",
		user_id:"Int",
		votes_count:"Int"
	},
	voyce_polls_min_fields:{
		comments_count:"Int",
		created_at:"timestamp",
		end_date:"timestamp",
		id:"Int",
		last_activity_date:"timestamp",
		likes_count:"Int",
		popularity:"Int",
		popularity_weekly:"Int",
		reactions_count:"Int",
		title:"String",
		total_popularity:"Int",
		user_id:"Int",
		votes_count:"Int"
	},
	voyce_polls_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_polls"
	},
	voyce_polls_reactions:{
		comment_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		linked_entity_id:"Int",
		poll:"voyce_polls",
		polls_comment:"voyce_polls_comments",
		reaction:"Int",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_polls_reactions_aggregate:{
		aggregate:"voyce_polls_reactions_aggregate_fields",
		nodes:"voyce_polls_reactions"
	},
	voyce_polls_reactions_aggregate_fields:{
		avg:"voyce_polls_reactions_avg_fields",
		count:"Int",
		max:"voyce_polls_reactions_max_fields",
		min:"voyce_polls_reactions_min_fields",
		stddev:"voyce_polls_reactions_stddev_fields",
		stddev_pop:"voyce_polls_reactions_stddev_pop_fields",
		stddev_samp:"voyce_polls_reactions_stddev_samp_fields",
		sum:"voyce_polls_reactions_sum_fields",
		var_pop:"voyce_polls_reactions_var_pop_fields",
		var_samp:"voyce_polls_reactions_var_samp_fields",
		variance:"voyce_polls_reactions_variance_fields"
	},
	voyce_polls_reactions_avg_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_polls_reactions_max_fields:{
		comment_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		linked_entity_id:"Int",
		reaction:"Int",
		user_id:"Int"
	},
	voyce_polls_reactions_min_fields:{
		comment_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		linked_entity_id:"Int",
		reaction:"Int",
		user_id:"Int"
	},
	voyce_polls_reactions_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_polls_reactions"
	},
	voyce_polls_reactions_stddev_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_polls_reactions_stddev_pop_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_polls_reactions_stddev_samp_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_polls_reactions_sum_fields:{
		comment_id:"Int",
		id:"Int",
		linked_entity_id:"Int",
		reaction:"Int",
		user_id:"Int"
	},
	voyce_polls_reactions_var_pop_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_polls_reactions_var_samp_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_polls_reactions_variance_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_polls_stddev_fields:{
		comments_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		total_popularity:"Float",
		user_id:"Float",
		votes_count:"Float"
	},
	voyce_polls_stddev_pop_fields:{
		comments_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		total_popularity:"Float",
		user_id:"Float",
		votes_count:"Float"
	},
	voyce_polls_stddev_samp_fields:{
		comments_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		total_popularity:"Float",
		user_id:"Float",
		votes_count:"Float"
	},
	voyce_polls_sum_fields:{
		comments_count:"Int",
		id:"Int",
		likes_count:"Int",
		popularity:"Int",
		popularity_weekly:"Int",
		reactions_count:"Int",
		total_popularity:"Int",
		user_id:"Int",
		votes_count:"Int"
	},
	voyce_polls_var_pop_fields:{
		comments_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		total_popularity:"Float",
		user_id:"Float",
		votes_count:"Float"
	},
	voyce_polls_var_samp_fields:{
		comments_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		total_popularity:"Float",
		user_id:"Float",
		votes_count:"Float"
	},
	voyce_polls_variance_fields:{
		comments_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		total_popularity:"Float",
		user_id:"Float",
		votes_count:"Float"
	},
	voyce_polls_votes:{
		answer_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		poll:"voyce_polls",
		poll_id:"Int",
		polls_answer:"voyce_polls_answers",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_polls_votes_aggregate:{
		aggregate:"voyce_polls_votes_aggregate_fields",
		nodes:"voyce_polls_votes"
	},
	voyce_polls_votes_aggregate_fields:{
		avg:"voyce_polls_votes_avg_fields",
		count:"Int",
		max:"voyce_polls_votes_max_fields",
		min:"voyce_polls_votes_min_fields",
		stddev:"voyce_polls_votes_stddev_fields",
		stddev_pop:"voyce_polls_votes_stddev_pop_fields",
		stddev_samp:"voyce_polls_votes_stddev_samp_fields",
		sum:"voyce_polls_votes_sum_fields",
		var_pop:"voyce_polls_votes_var_pop_fields",
		var_samp:"voyce_polls_votes_var_samp_fields",
		variance:"voyce_polls_votes_variance_fields"
	},
	voyce_polls_votes_avg_fields:{
		answer_id:"Float",
		id:"Float",
		poll_id:"Float",
		user_id:"Float"
	},
	voyce_polls_votes_max_fields:{
		answer_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		poll_id:"Int",
		user_id:"Int"
	},
	voyce_polls_votes_min_fields:{
		answer_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		poll_id:"Int",
		user_id:"Int"
	},
	voyce_polls_votes_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_polls_votes"
	},
	voyce_polls_votes_stddev_fields:{
		answer_id:"Float",
		id:"Float",
		poll_id:"Float",
		user_id:"Float"
	},
	voyce_polls_votes_stddev_pop_fields:{
		answer_id:"Float",
		id:"Float",
		poll_id:"Float",
		user_id:"Float"
	},
	voyce_polls_votes_stddev_samp_fields:{
		answer_id:"Float",
		id:"Float",
		poll_id:"Float",
		user_id:"Float"
	},
	voyce_polls_votes_sum_fields:{
		answer_id:"Int",
		id:"Int",
		poll_id:"Int",
		user_id:"Int"
	},
	voyce_polls_votes_var_pop_fields:{
		answer_id:"Float",
		id:"Float",
		poll_id:"Float",
		user_id:"Float"
	},
	voyce_polls_votes_var_samp_fields:{
		answer_id:"Float",
		id:"Float",
		poll_id:"Float",
		user_id:"Float"
	},
	voyce_polls_votes_variance_fields:{
		answer_id:"Float",
		id:"Float",
		poll_id:"Float",
		user_id:"Float"
	},
	voyce_popup_components:{
		auth:"Boolean",
		component:"String",
		created_at:"timestamptz",
		email_form:"Boolean",
		exclusive_series:"Boolean",
		followers:"Boolean",
		id:"Int",
		is_active:"Boolean",
		klaviyo_list_id:"String",
		layout:"Int",
		page:"String",
		props:"json",
		series_visibility:"Boolean",
		sort:"smallint",
		updated_at:"timestamptz"
	},
	voyce_popup_components_aggregate:{
		aggregate:"voyce_popup_components_aggregate_fields",
		nodes:"voyce_popup_components"
	},
	voyce_popup_components_aggregate_fields:{
		avg:"voyce_popup_components_avg_fields",
		count:"Int",
		max:"voyce_popup_components_max_fields",
		min:"voyce_popup_components_min_fields",
		stddev:"voyce_popup_components_stddev_fields",
		stddev_pop:"voyce_popup_components_stddev_pop_fields",
		stddev_samp:"voyce_popup_components_stddev_samp_fields",
		sum:"voyce_popup_components_sum_fields",
		var_pop:"voyce_popup_components_var_pop_fields",
		var_samp:"voyce_popup_components_var_samp_fields",
		variance:"voyce_popup_components_variance_fields"
	},
	voyce_popup_components_avg_fields:{
		id:"Float",
		layout:"Float",
		sort:"Float"
	},
	voyce_popup_components_max_fields:{
		component:"String",
		created_at:"timestamptz",
		id:"Int",
		klaviyo_list_id:"String",
		layout:"Int",
		page:"String",
		sort:"smallint",
		updated_at:"timestamptz"
	},
	voyce_popup_components_min_fields:{
		component:"String",
		created_at:"timestamptz",
		id:"Int",
		klaviyo_list_id:"String",
		layout:"Int",
		page:"String",
		sort:"smallint",
		updated_at:"timestamptz"
	},
	voyce_popup_components_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_popup_components"
	},
	voyce_popup_components_stddev_fields:{
		id:"Float",
		layout:"Float",
		sort:"Float"
	},
	voyce_popup_components_stddev_pop_fields:{
		id:"Float",
		layout:"Float",
		sort:"Float"
	},
	voyce_popup_components_stddev_samp_fields:{
		id:"Float",
		layout:"Float",
		sort:"Float"
	},
	voyce_popup_components_sum_fields:{
		id:"Int",
		layout:"Int",
		sort:"smallint"
	},
	voyce_popup_components_var_pop_fields:{
		id:"Float",
		layout:"Float",
		sort:"Float"
	},
	voyce_popup_components_var_samp_fields:{
		id:"Float",
		layout:"Float",
		sort:"Float"
	},
	voyce_popup_components_variance_fields:{
		id:"Float",
		layout:"Float",
		sort:"Float"
	},
	voyce_popup_emails:{
		component:"voyce_popup_components",
		created_at:"timestamptz",
		email:"String",
		gtag:"String",
		guest_gtag:"voyce_guest_gtags",
		id:"Int",
		klaviyo_id:"String",
		page:"String",
		popup_id:"Int",
		series:"voyce_series",
		series_id:"Int",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_popup_emails_aggregate:{
		aggregate:"voyce_popup_emails_aggregate_fields",
		nodes:"voyce_popup_emails"
	},
	voyce_popup_emails_aggregate_fields:{
		avg:"voyce_popup_emails_avg_fields",
		count:"Int",
		max:"voyce_popup_emails_max_fields",
		min:"voyce_popup_emails_min_fields",
		stddev:"voyce_popup_emails_stddev_fields",
		stddev_pop:"voyce_popup_emails_stddev_pop_fields",
		stddev_samp:"voyce_popup_emails_stddev_samp_fields",
		sum:"voyce_popup_emails_sum_fields",
		var_pop:"voyce_popup_emails_var_pop_fields",
		var_samp:"voyce_popup_emails_var_samp_fields",
		variance:"voyce_popup_emails_variance_fields"
	},
	voyce_popup_emails_avg_fields:{
		id:"Float",
		popup_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_popup_emails_max_fields:{
		created_at:"timestamptz",
		email:"String",
		gtag:"String",
		id:"Int",
		klaviyo_id:"String",
		page:"String",
		popup_id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_popup_emails_min_fields:{
		created_at:"timestamptz",
		email:"String",
		gtag:"String",
		id:"Int",
		klaviyo_id:"String",
		page:"String",
		popup_id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_popup_emails_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_popup_emails"
	},
	voyce_popup_emails_stddev_fields:{
		id:"Float",
		popup_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_popup_emails_stddev_pop_fields:{
		id:"Float",
		popup_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_popup_emails_stddev_samp_fields:{
		id:"Float",
		popup_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_popup_emails_sum_fields:{
		id:"Int",
		popup_id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_popup_emails_var_pop_fields:{
		id:"Float",
		popup_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_popup_emails_var_samp_fields:{
		id:"Float",
		popup_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_popup_emails_variance_fields:{
		id:"Float",
		popup_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_posts:{
		comments_count:"Int",
		created_at:"timestamp",
		giphy_url:"String",
		id:"Int",
		image_url:"String",
		last_activity_date:"timestamp",
		likes_count:"Int",
		outdated:"Boolean",
		popularity:"Int",
		popularity_weekly:"Int",
		posts_comments:"voyce_posts_comments",
		posts_comments_aggregate:"voyce_posts_comments_aggregate",
		posts_reactions:"voyce_posts_reactions",
		posts_reactions_aggregate:"voyce_posts_reactions_aggregate",
		reactions_count:"Int",
		spoiler:"Boolean",
		text:"String",
		total_popularity:"Int",
		user:"voyce_users",
		user_id:"Int",
		video_url:"String"
	},
	voyce_posts_aggregate:{
		aggregate:"voyce_posts_aggregate_fields",
		nodes:"voyce_posts"
	},
	voyce_posts_aggregate_fields:{
		avg:"voyce_posts_avg_fields",
		count:"Int",
		max:"voyce_posts_max_fields",
		min:"voyce_posts_min_fields",
		stddev:"voyce_posts_stddev_fields",
		stddev_pop:"voyce_posts_stddev_pop_fields",
		stddev_samp:"voyce_posts_stddev_samp_fields",
		sum:"voyce_posts_sum_fields",
		var_pop:"voyce_posts_var_pop_fields",
		var_samp:"voyce_posts_var_samp_fields",
		variance:"voyce_posts_variance_fields"
	},
	voyce_posts_avg_fields:{
		comments_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		total_popularity:"Float",
		user_id:"Float"
	},
	voyce_posts_comments:{
		animated_image:"String",
		created_at:"timestamptz",
		id:"Int",
		image:"String",
		last_reacted_at:"timestamptz",
		last_reaction_count:"Int",
		linked_id:"Int",
		parent_id:"Int",
		post:"voyce_posts",
		posts_comment:"voyce_posts_comments",
		posts_comments:"voyce_posts_comments",
		posts_comments_aggregate:"voyce_posts_comments_aggregate",
		posts_reactions:"voyce_posts_reactions",
		posts_reactions_aggregate:"voyce_posts_reactions_aggregate",
		reaction_count:"Int",
		replies_count:"Int",
		text:"String",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_posts_comments_aggregate:{
		aggregate:"voyce_posts_comments_aggregate_fields",
		nodes:"voyce_posts_comments"
	},
	voyce_posts_comments_aggregate_fields:{
		avg:"voyce_posts_comments_avg_fields",
		count:"Int",
		max:"voyce_posts_comments_max_fields",
		min:"voyce_posts_comments_min_fields",
		stddev:"voyce_posts_comments_stddev_fields",
		stddev_pop:"voyce_posts_comments_stddev_pop_fields",
		stddev_samp:"voyce_posts_comments_stddev_samp_fields",
		sum:"voyce_posts_comments_sum_fields",
		var_pop:"voyce_posts_comments_var_pop_fields",
		var_samp:"voyce_posts_comments_var_samp_fields",
		variance:"voyce_posts_comments_variance_fields"
	},
	voyce_posts_comments_avg_fields:{
		id:"Float",
		last_reaction_count:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_posts_comments_max_fields:{
		animated_image:"String",
		created_at:"timestamptz",
		id:"Int",
		image:"String",
		last_reacted_at:"timestamptz",
		last_reaction_count:"Int",
		linked_id:"Int",
		parent_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		text:"String",
		user_id:"Int"
	},
	voyce_posts_comments_min_fields:{
		animated_image:"String",
		created_at:"timestamptz",
		id:"Int",
		image:"String",
		last_reacted_at:"timestamptz",
		last_reaction_count:"Int",
		linked_id:"Int",
		parent_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		text:"String",
		user_id:"Int"
	},
	voyce_posts_comments_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_posts_comments"
	},
	voyce_posts_comments_stddev_fields:{
		id:"Float",
		last_reaction_count:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_posts_comments_stddev_pop_fields:{
		id:"Float",
		last_reaction_count:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_posts_comments_stddev_samp_fields:{
		id:"Float",
		last_reaction_count:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_posts_comments_sum_fields:{
		id:"Int",
		last_reaction_count:"Int",
		linked_id:"Int",
		parent_id:"Int",
		reaction_count:"Int",
		replies_count:"Int",
		user_id:"Int"
	},
	voyce_posts_comments_var_pop_fields:{
		id:"Float",
		last_reaction_count:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_posts_comments_var_samp_fields:{
		id:"Float",
		last_reaction_count:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_posts_comments_variance_fields:{
		id:"Float",
		last_reaction_count:"Float",
		linked_id:"Float",
		parent_id:"Float",
		reaction_count:"Float",
		replies_count:"Float",
		user_id:"Float"
	},
	voyce_posts_max_fields:{
		comments_count:"Int",
		created_at:"timestamp",
		giphy_url:"String",
		id:"Int",
		image_url:"String",
		last_activity_date:"timestamp",
		likes_count:"Int",
		popularity:"Int",
		popularity_weekly:"Int",
		reactions_count:"Int",
		text:"String",
		total_popularity:"Int",
		user_id:"Int",
		video_url:"String"
	},
	voyce_posts_min_fields:{
		comments_count:"Int",
		created_at:"timestamp",
		giphy_url:"String",
		id:"Int",
		image_url:"String",
		last_activity_date:"timestamp",
		likes_count:"Int",
		popularity:"Int",
		popularity_weekly:"Int",
		reactions_count:"Int",
		text:"String",
		total_popularity:"Int",
		user_id:"Int",
		video_url:"String"
	},
	voyce_posts_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_posts"
	},
	voyce_posts_reactions:{
		comment_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		linked_entity_id:"Int",
		post:"voyce_posts",
		posts_comment:"voyce_posts_comments",
		reaction:"Int",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_posts_reactions_aggregate:{
		aggregate:"voyce_posts_reactions_aggregate_fields",
		nodes:"voyce_posts_reactions"
	},
	voyce_posts_reactions_aggregate_fields:{
		avg:"voyce_posts_reactions_avg_fields",
		count:"Int",
		max:"voyce_posts_reactions_max_fields",
		min:"voyce_posts_reactions_min_fields",
		stddev:"voyce_posts_reactions_stddev_fields",
		stddev_pop:"voyce_posts_reactions_stddev_pop_fields",
		stddev_samp:"voyce_posts_reactions_stddev_samp_fields",
		sum:"voyce_posts_reactions_sum_fields",
		var_pop:"voyce_posts_reactions_var_pop_fields",
		var_samp:"voyce_posts_reactions_var_samp_fields",
		variance:"voyce_posts_reactions_variance_fields"
	},
	voyce_posts_reactions_avg_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_posts_reactions_max_fields:{
		comment_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		linked_entity_id:"Int",
		reaction:"Int",
		user_id:"Int"
	},
	voyce_posts_reactions_min_fields:{
		comment_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		linked_entity_id:"Int",
		reaction:"Int",
		user_id:"Int"
	},
	voyce_posts_reactions_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_posts_reactions"
	},
	voyce_posts_reactions_stddev_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_posts_reactions_stddev_pop_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_posts_reactions_stddev_samp_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_posts_reactions_sum_fields:{
		comment_id:"Int",
		id:"Int",
		linked_entity_id:"Int",
		reaction:"Int",
		user_id:"Int"
	},
	voyce_posts_reactions_var_pop_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_posts_reactions_var_samp_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_posts_reactions_variance_fields:{
		comment_id:"Float",
		id:"Float",
		linked_entity_id:"Float",
		reaction:"Float",
		user_id:"Float"
	},
	voyce_posts_stddev_fields:{
		comments_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		total_popularity:"Float",
		user_id:"Float"
	},
	voyce_posts_stddev_pop_fields:{
		comments_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		total_popularity:"Float",
		user_id:"Float"
	},
	voyce_posts_stddev_samp_fields:{
		comments_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		total_popularity:"Float",
		user_id:"Float"
	},
	voyce_posts_sum_fields:{
		comments_count:"Int",
		id:"Int",
		likes_count:"Int",
		popularity:"Int",
		popularity_weekly:"Int",
		reactions_count:"Int",
		total_popularity:"Int",
		user_id:"Int"
	},
	voyce_posts_var_pop_fields:{
		comments_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		total_popularity:"Float",
		user_id:"Float"
	},
	voyce_posts_var_samp_fields:{
		comments_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		total_popularity:"Float",
		user_id:"Float"
	},
	voyce_posts_variance_fields:{
		comments_count:"Float",
		id:"Float",
		likes_count:"Float",
		popularity:"Float",
		popularity_weekly:"Float",
		reactions_count:"Float",
		total_popularity:"Float",
		user_id:"Float"
	},
	voyce_quest_difficulties:{
		coins:"Int",
		created_at:"timestamptz",
		experience:"Int",
		id:"Int",
		title:"String",
		updated_at:"timestamptz",
		vox:"Int"
	},
	voyce_quest_difficulties_aggregate:{
		aggregate:"voyce_quest_difficulties_aggregate_fields",
		nodes:"voyce_quest_difficulties"
	},
	voyce_quest_difficulties_aggregate_fields:{
		avg:"voyce_quest_difficulties_avg_fields",
		count:"Int",
		max:"voyce_quest_difficulties_max_fields",
		min:"voyce_quest_difficulties_min_fields",
		stddev:"voyce_quest_difficulties_stddev_fields",
		stddev_pop:"voyce_quest_difficulties_stddev_pop_fields",
		stddev_samp:"voyce_quest_difficulties_stddev_samp_fields",
		sum:"voyce_quest_difficulties_sum_fields",
		var_pop:"voyce_quest_difficulties_var_pop_fields",
		var_samp:"voyce_quest_difficulties_var_samp_fields",
		variance:"voyce_quest_difficulties_variance_fields"
	},
	voyce_quest_difficulties_avg_fields:{
		coins:"Float",
		experience:"Float",
		id:"Float",
		vox:"Float"
	},
	voyce_quest_difficulties_max_fields:{
		coins:"Int",
		created_at:"timestamptz",
		experience:"Int",
		id:"Int",
		title:"String",
		updated_at:"timestamptz",
		vox:"Int"
	},
	voyce_quest_difficulties_min_fields:{
		coins:"Int",
		created_at:"timestamptz",
		experience:"Int",
		id:"Int",
		title:"String",
		updated_at:"timestamptz",
		vox:"Int"
	},
	voyce_quest_difficulties_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_quest_difficulties"
	},
	voyce_quest_difficulties_stddev_fields:{
		coins:"Float",
		experience:"Float",
		id:"Float",
		vox:"Float"
	},
	voyce_quest_difficulties_stddev_pop_fields:{
		coins:"Float",
		experience:"Float",
		id:"Float",
		vox:"Float"
	},
	voyce_quest_difficulties_stddev_samp_fields:{
		coins:"Float",
		experience:"Float",
		id:"Float",
		vox:"Float"
	},
	voyce_quest_difficulties_sum_fields:{
		coins:"Int",
		experience:"Int",
		id:"Int",
		vox:"Int"
	},
	voyce_quest_difficulties_var_pop_fields:{
		coins:"Float",
		experience:"Float",
		id:"Float",
		vox:"Float"
	},
	voyce_quest_difficulties_var_samp_fields:{
		coins:"Float",
		experience:"Float",
		id:"Float",
		vox:"Float"
	},
	voyce_quest_difficulties_variance_fields:{
		coins:"Float",
		experience:"Float",
		id:"Float",
		vox:"Float"
	},
	voyce_quests:{
		created_at:"timestamptz",
		description:"String",
		difficulty:"voyce_quest_difficulties",
		difficulty_id:"Int",
		event_data:"json",
		event_trigger:"String",
		experience_points:"Int",
		frequency:"String",
		id:"Int",
		initial_status:"String",
		notif_message:"String",
		reward:"json",
		start_data:"json",
		start_type:"String",
		tag:"String",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_quests_aggregate:{
		aggregate:"voyce_quests_aggregate_fields",
		nodes:"voyce_quests"
	},
	voyce_quests_aggregate_fields:{
		avg:"voyce_quests_avg_fields",
		count:"Int",
		max:"voyce_quests_max_fields",
		min:"voyce_quests_min_fields",
		stddev:"voyce_quests_stddev_fields",
		stddev_pop:"voyce_quests_stddev_pop_fields",
		stddev_samp:"voyce_quests_stddev_samp_fields",
		sum:"voyce_quests_sum_fields",
		var_pop:"voyce_quests_var_pop_fields",
		var_samp:"voyce_quests_var_samp_fields",
		variance:"voyce_quests_variance_fields"
	},
	voyce_quests_avg_fields:{
		difficulty_id:"Float",
		experience_points:"Float",
		id:"Float"
	},
	voyce_quests_max_fields:{
		created_at:"timestamptz",
		description:"String",
		difficulty_id:"Int",
		event_trigger:"String",
		experience_points:"Int",
		frequency:"String",
		id:"Int",
		initial_status:"String",
		notif_message:"String",
		start_type:"String",
		tag:"String",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_quests_min_fields:{
		created_at:"timestamptz",
		description:"String",
		difficulty_id:"Int",
		event_trigger:"String",
		experience_points:"Int",
		frequency:"String",
		id:"Int",
		initial_status:"String",
		notif_message:"String",
		start_type:"String",
		tag:"String",
		title:"String",
		updated_at:"timestamptz"
	},
	voyce_quests_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_quests"
	},
	voyce_quests_old:{
		end_date:"timestamp",
		id:"Int",
		image:"String",
		recurrency:"Int",
		recurrent:"Boolean",
		rewards_quests:"voyce_rewards_quest",
		rewards_quests_aggregate:"voyce_rewards_quest_aggregate",
		start_date:"timestamp",
		steps:"Int",
		title:"String",
		user_quests:"voyce_user_quests_old",
		user_quests_aggregate:"voyce_user_quests_old_aggregate"
	},
	voyce_quests_old_aggregate:{
		aggregate:"voyce_quests_old_aggregate_fields",
		nodes:"voyce_quests_old"
	},
	voyce_quests_old_aggregate_fields:{
		avg:"voyce_quests_old_avg_fields",
		count:"Int",
		max:"voyce_quests_old_max_fields",
		min:"voyce_quests_old_min_fields",
		stddev:"voyce_quests_old_stddev_fields",
		stddev_pop:"voyce_quests_old_stddev_pop_fields",
		stddev_samp:"voyce_quests_old_stddev_samp_fields",
		sum:"voyce_quests_old_sum_fields",
		var_pop:"voyce_quests_old_var_pop_fields",
		var_samp:"voyce_quests_old_var_samp_fields",
		variance:"voyce_quests_old_variance_fields"
	},
	voyce_quests_old_avg_fields:{
		id:"Float",
		recurrency:"Float",
		steps:"Float"
	},
	voyce_quests_old_max_fields:{
		end_date:"timestamp",
		id:"Int",
		image:"String",
		recurrency:"Int",
		start_date:"timestamp",
		steps:"Int",
		title:"String"
	},
	voyce_quests_old_min_fields:{
		end_date:"timestamp",
		id:"Int",
		image:"String",
		recurrency:"Int",
		start_date:"timestamp",
		steps:"Int",
		title:"String"
	},
	voyce_quests_old_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_quests_old"
	},
	voyce_quests_old_stddev_fields:{
		id:"Float",
		recurrency:"Float",
		steps:"Float"
	},
	voyce_quests_old_stddev_pop_fields:{
		id:"Float",
		recurrency:"Float",
		steps:"Float"
	},
	voyce_quests_old_stddev_samp_fields:{
		id:"Float",
		recurrency:"Float",
		steps:"Float"
	},
	voyce_quests_old_sum_fields:{
		id:"Int",
		recurrency:"Int",
		steps:"Int"
	},
	voyce_quests_old_var_pop_fields:{
		id:"Float",
		recurrency:"Float",
		steps:"Float"
	},
	voyce_quests_old_var_samp_fields:{
		id:"Float",
		recurrency:"Float",
		steps:"Float"
	},
	voyce_quests_old_variance_fields:{
		id:"Float",
		recurrency:"Float",
		steps:"Float"
	},
	voyce_quests_stddev_fields:{
		difficulty_id:"Float",
		experience_points:"Float",
		id:"Float"
	},
	voyce_quests_stddev_pop_fields:{
		difficulty_id:"Float",
		experience_points:"Float",
		id:"Float"
	},
	voyce_quests_stddev_samp_fields:{
		difficulty_id:"Float",
		experience_points:"Float",
		id:"Float"
	},
	voyce_quests_sum_fields:{
		difficulty_id:"Int",
		experience_points:"Int",
		id:"Int"
	},
	voyce_quests_var_pop_fields:{
		difficulty_id:"Float",
		experience_points:"Float",
		id:"Float"
	},
	voyce_quests_var_samp_fields:{
		difficulty_id:"Float",
		experience_points:"Float",
		id:"Float"
	},
	voyce_quests_variance_fields:{
		difficulty_id:"Float",
		experience_points:"Float",
		id:"Float"
	},
	voyce_recommended_series:{
		created_at:"timestamptz",
		id:"Int",
		notification_image:"String",
		notification_status:"String",
		notification_text:"String",
		notification_title:"String",
		series:"voyce_series",
		series_id:"Int"
	},
	voyce_recommended_series_aggregate:{
		aggregate:"voyce_recommended_series_aggregate_fields",
		nodes:"voyce_recommended_series"
	},
	voyce_recommended_series_aggregate_fields:{
		avg:"voyce_recommended_series_avg_fields",
		count:"Int",
		max:"voyce_recommended_series_max_fields",
		min:"voyce_recommended_series_min_fields",
		stddev:"voyce_recommended_series_stddev_fields",
		stddev_pop:"voyce_recommended_series_stddev_pop_fields",
		stddev_samp:"voyce_recommended_series_stddev_samp_fields",
		sum:"voyce_recommended_series_sum_fields",
		var_pop:"voyce_recommended_series_var_pop_fields",
		var_samp:"voyce_recommended_series_var_samp_fields",
		variance:"voyce_recommended_series_variance_fields"
	},
	voyce_recommended_series_avg_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_recommended_series_max_fields:{
		created_at:"timestamptz",
		id:"Int",
		notification_image:"String",
		notification_status:"String",
		notification_text:"String",
		notification_title:"String",
		series_id:"Int"
	},
	voyce_recommended_series_min_fields:{
		created_at:"timestamptz",
		id:"Int",
		notification_image:"String",
		notification_status:"String",
		notification_text:"String",
		notification_title:"String",
		series_id:"Int"
	},
	voyce_recommended_series_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_recommended_series"
	},
	voyce_recommended_series_stddev_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_recommended_series_stddev_pop_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_recommended_series_stddev_samp_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_recommended_series_sum_fields:{
		id:"Int",
		series_id:"Int"
	},
	voyce_recommended_series_var_pop_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_recommended_series_var_samp_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_recommended_series_variance_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_release_schedules:{
		chapter:"voyce_chapters",
		chapter_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		schedule:"timestamptz",
		series:"voyce_series",
		series_id:"Int",
		updated_at:"timestamptz"
	},
	voyce_release_schedules_aggregate:{
		aggregate:"voyce_release_schedules_aggregate_fields",
		nodes:"voyce_release_schedules"
	},
	voyce_release_schedules_aggregate_fields:{
		avg:"voyce_release_schedules_avg_fields",
		count:"Int",
		max:"voyce_release_schedules_max_fields",
		min:"voyce_release_schedules_min_fields",
		stddev:"voyce_release_schedules_stddev_fields",
		stddev_pop:"voyce_release_schedules_stddev_pop_fields",
		stddev_samp:"voyce_release_schedules_stddev_samp_fields",
		sum:"voyce_release_schedules_sum_fields",
		var_pop:"voyce_release_schedules_var_pop_fields",
		var_samp:"voyce_release_schedules_var_samp_fields",
		variance:"voyce_release_schedules_variance_fields"
	},
	voyce_release_schedules_avg_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_release_schedules_max_fields:{
		chapter_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		schedule:"timestamptz",
		series_id:"Int",
		updated_at:"timestamptz"
	},
	voyce_release_schedules_min_fields:{
		chapter_id:"Int",
		created_at:"timestamptz",
		id:"Int",
		schedule:"timestamptz",
		series_id:"Int",
		updated_at:"timestamptz"
	},
	voyce_release_schedules_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_release_schedules"
	},
	voyce_release_schedules_stddev_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_release_schedules_stddev_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_release_schedules_stddev_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_release_schedules_sum_fields:{
		chapter_id:"Int",
		id:"Int",
		series_id:"Int"
	},
	voyce_release_schedules_var_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_release_schedules_var_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_release_schedules_variance_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_rewards:{
		end_date:"timestamp",
		expiry_date:"timestamp",
		free:"Boolean",
		id:"Int",
		image:"String",
		purchaseId:"String",
		start_date:"timestamp",
		title:"String",
		transferable:"Boolean",
		type:"String",
		value:"Int"
	},
	voyce_rewards_aggregate:{
		aggregate:"voyce_rewards_aggregate_fields",
		nodes:"voyce_rewards"
	},
	voyce_rewards_aggregate_fields:{
		avg:"voyce_rewards_avg_fields",
		count:"Int",
		max:"voyce_rewards_max_fields",
		min:"voyce_rewards_min_fields",
		stddev:"voyce_rewards_stddev_fields",
		stddev_pop:"voyce_rewards_stddev_pop_fields",
		stddev_samp:"voyce_rewards_stddev_samp_fields",
		sum:"voyce_rewards_sum_fields",
		var_pop:"voyce_rewards_var_pop_fields",
		var_samp:"voyce_rewards_var_samp_fields",
		variance:"voyce_rewards_variance_fields"
	},
	voyce_rewards_avg_fields:{
		id:"Float",
		value:"Float"
	},
	voyce_rewards_leaderboard:{
		id:"Int",
		order:"Int",
		reward:"voyce_rewards",
		reward_id:"Int",
		tag:"String",
		title:"String"
	},
	voyce_rewards_leaderboard_aggregate:{
		aggregate:"voyce_rewards_leaderboard_aggregate_fields",
		nodes:"voyce_rewards_leaderboard"
	},
	voyce_rewards_leaderboard_aggregate_fields:{
		avg:"voyce_rewards_leaderboard_avg_fields",
		count:"Int",
		max:"voyce_rewards_leaderboard_max_fields",
		min:"voyce_rewards_leaderboard_min_fields",
		stddev:"voyce_rewards_leaderboard_stddev_fields",
		stddev_pop:"voyce_rewards_leaderboard_stddev_pop_fields",
		stddev_samp:"voyce_rewards_leaderboard_stddev_samp_fields",
		sum:"voyce_rewards_leaderboard_sum_fields",
		var_pop:"voyce_rewards_leaderboard_var_pop_fields",
		var_samp:"voyce_rewards_leaderboard_var_samp_fields",
		variance:"voyce_rewards_leaderboard_variance_fields"
	},
	voyce_rewards_leaderboard_avg_fields:{
		id:"Float",
		order:"Float",
		reward_id:"Float"
	},
	voyce_rewards_leaderboard_max_fields:{
		id:"Int",
		order:"Int",
		reward_id:"Int",
		tag:"String",
		title:"String"
	},
	voyce_rewards_leaderboard_min_fields:{
		id:"Int",
		order:"Int",
		reward_id:"Int",
		tag:"String",
		title:"String"
	},
	voyce_rewards_leaderboard_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_rewards_leaderboard"
	},
	voyce_rewards_leaderboard_stddev_fields:{
		id:"Float",
		order:"Float",
		reward_id:"Float"
	},
	voyce_rewards_leaderboard_stddev_pop_fields:{
		id:"Float",
		order:"Float",
		reward_id:"Float"
	},
	voyce_rewards_leaderboard_stddev_samp_fields:{
		id:"Float",
		order:"Float",
		reward_id:"Float"
	},
	voyce_rewards_leaderboard_sum_fields:{
		id:"Int",
		order:"Int",
		reward_id:"Int"
	},
	voyce_rewards_leaderboard_var_pop_fields:{
		id:"Float",
		order:"Float",
		reward_id:"Float"
	},
	voyce_rewards_leaderboard_var_samp_fields:{
		id:"Float",
		order:"Float",
		reward_id:"Float"
	},
	voyce_rewards_leaderboard_variance_fields:{
		id:"Float",
		order:"Float",
		reward_id:"Float"
	},
	voyce_rewards_max_fields:{
		end_date:"timestamp",
		expiry_date:"timestamp",
		id:"Int",
		image:"String",
		purchaseId:"String",
		start_date:"timestamp",
		title:"String",
		type:"String",
		value:"Int"
	},
	voyce_rewards_min_fields:{
		end_date:"timestamp",
		expiry_date:"timestamp",
		id:"Int",
		image:"String",
		purchaseId:"String",
		start_date:"timestamp",
		title:"String",
		type:"String",
		value:"Int"
	},
	voyce_rewards_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_rewards"
	},
	voyce_rewards_quest:{
		id:"Int",
		quest:"voyce_quests_old",
		quest_id:"Int",
		reward:"voyce_rewards",
		rewards_id:"Int"
	},
	voyce_rewards_quest_aggregate:{
		aggregate:"voyce_rewards_quest_aggregate_fields",
		nodes:"voyce_rewards_quest"
	},
	voyce_rewards_quest_aggregate_fields:{
		avg:"voyce_rewards_quest_avg_fields",
		count:"Int",
		max:"voyce_rewards_quest_max_fields",
		min:"voyce_rewards_quest_min_fields",
		stddev:"voyce_rewards_quest_stddev_fields",
		stddev_pop:"voyce_rewards_quest_stddev_pop_fields",
		stddev_samp:"voyce_rewards_quest_stddev_samp_fields",
		sum:"voyce_rewards_quest_sum_fields",
		var_pop:"voyce_rewards_quest_var_pop_fields",
		var_samp:"voyce_rewards_quest_var_samp_fields",
		variance:"voyce_rewards_quest_variance_fields"
	},
	voyce_rewards_quest_avg_fields:{
		id:"Float",
		quest_id:"Float",
		rewards_id:"Float"
	},
	voyce_rewards_quest_max_fields:{
		id:"Int",
		quest_id:"Int",
		rewards_id:"Int"
	},
	voyce_rewards_quest_min_fields:{
		id:"Int",
		quest_id:"Int",
		rewards_id:"Int"
	},
	voyce_rewards_quest_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_rewards_quest"
	},
	voyce_rewards_quest_stddev_fields:{
		id:"Float",
		quest_id:"Float",
		rewards_id:"Float"
	},
	voyce_rewards_quest_stddev_pop_fields:{
		id:"Float",
		quest_id:"Float",
		rewards_id:"Float"
	},
	voyce_rewards_quest_stddev_samp_fields:{
		id:"Float",
		quest_id:"Float",
		rewards_id:"Float"
	},
	voyce_rewards_quest_sum_fields:{
		id:"Int",
		quest_id:"Int",
		rewards_id:"Int"
	},
	voyce_rewards_quest_var_pop_fields:{
		id:"Float",
		quest_id:"Float",
		rewards_id:"Float"
	},
	voyce_rewards_quest_var_samp_fields:{
		id:"Float",
		quest_id:"Float",
		rewards_id:"Float"
	},
	voyce_rewards_quest_variance_fields:{
		id:"Float",
		quest_id:"Float",
		rewards_id:"Float"
	},
	voyce_rewards_stddev_fields:{
		id:"Float",
		value:"Float"
	},
	voyce_rewards_stddev_pop_fields:{
		id:"Float",
		value:"Float"
	},
	voyce_rewards_stddev_samp_fields:{
		id:"Float",
		value:"Float"
	},
	voyce_rewards_sum_fields:{
		id:"Int",
		value:"Int"
	},
	voyce_rewards_var_pop_fields:{
		id:"Float",
		value:"Float"
	},
	voyce_rewards_var_samp_fields:{
		id:"Float",
		value:"Float"
	},
	voyce_rewards_variance_fields:{
		id:"Float",
		value:"Float"
	},
	voyce_sections:{
		content:"String",
		created_at:"timestamp",
		id:"Int",
		title:"String",
		updated_at:"timestamp"
	},
	voyce_sections_aggregate:{
		aggregate:"voyce_sections_aggregate_fields",
		nodes:"voyce_sections"
	},
	voyce_sections_aggregate_fields:{
		avg:"voyce_sections_avg_fields",
		count:"Int",
		max:"voyce_sections_max_fields",
		min:"voyce_sections_min_fields",
		stddev:"voyce_sections_stddev_fields",
		stddev_pop:"voyce_sections_stddev_pop_fields",
		stddev_samp:"voyce_sections_stddev_samp_fields",
		sum:"voyce_sections_sum_fields",
		var_pop:"voyce_sections_var_pop_fields",
		var_samp:"voyce_sections_var_samp_fields",
		variance:"voyce_sections_variance_fields"
	},
	voyce_sections_avg_fields:{
		id:"Float"
	},
	voyce_sections_max_fields:{
		content:"String",
		created_at:"timestamp",
		id:"Int",
		title:"String",
		updated_at:"timestamp"
	},
	voyce_sections_min_fields:{
		content:"String",
		created_at:"timestamp",
		id:"Int",
		title:"String",
		updated_at:"timestamp"
	},
	voyce_sections_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_sections"
	},
	voyce_sections_stddev_fields:{
		id:"Float"
	},
	voyce_sections_stddev_pop_fields:{
		id:"Float"
	},
	voyce_sections_stddev_samp_fields:{
		id:"Float"
	},
	voyce_sections_sum_fields:{
		id:"Int"
	},
	voyce_sections_var_pop_fields:{
		id:"Float"
	},
	voyce_sections_var_samp_fields:{
		id:"Float"
	},
	voyce_sections_variance_fields:{
		id:"Float"
	},
	voyce_series:{
		animated_cover_image:"String",
		audio_allowed:"Boolean",
		author:"voyce_users",
		average_rating:"float8",
		back_image:"String",
		banner_bg:"String",
		book_reverse_view:"Boolean",
		book_view:"Boolean",
		chapter_comments:"voyce_chapter_comments",
		chapter_comments_aggregate:"voyce_chapter_comments_aggregate",
		chapter_count:"Int",
		chapter_panels:"voyce_chapter_panels",
		chapter_panels_aggregate:"voyce_chapter_panels_aggregate",
		chapters:"voyce_chapters",
		chapters_aggregate:"voyce_chapters_aggregate",
		chapters_hide:"String",
		character_cover:"String",
		coin_price:"smallint",
		collaborators:"voyce_series_collaborators",
		collaborators_aggregate:"voyce_series_collaborators_aggregate",
		comic_type_id:"Int",
		cover_image:"String",
		cover_image_mobile:"String",
		created_at:"timestamp",
		csat:"Boolean",
		default_view:"String",
		description:"String",
		feature:"bit",
		featured_panels:"voyce_series_featured_panels",
		featured_panels_aggregate:"voyce_series_featured_panels_aggregate",
		followers:"voyce_series_subscription",
		followers_aggregate:"voyce_series_subscription_aggregate",
		frequency:"String",
		frequency_mask:"Int",
		front_image:"String",
		genres:"voyce_series_genre",
		genres_aggregate:"voyce_series_genre_aggregate",
		hot_panels:"voyce_series_hot_panels",
		hot_panels_aggregate:"voyce_series_hot_panels_aggregate",
		id:"Int",
		is_animated_cover_allowed:"Boolean",
		is_approved:"smallint",
		is_deleted:"Boolean",
		is_intro:"smallint",
		is_locked:"Boolean",
		is_original:"smallint",
		is_pilots:"smallint",
		is_recommended:"smallint",
		klaviyo_id:"String",
		klaviyo_list_id:"String",
		latest_chapter_update:"timestamp",
		likes:"voyce_chapter_likes",
		likes_aggregate:"voyce_chapter_likes_aggregate",
		likes_count:"voyce_series_likes_count_mat",
		lock_config:"voyce_series_lock_config",
		locked:"Boolean",
		locked_chapters_amount:"Int",
		notification_settings:"voyce_notification_series_settings",
		paginated_view:"Boolean",
		pilots_votes_count:"Int",
		portfolio_desc:"String",
		publish:"smallint",
		rating_count:"Int",
		reading_view:"String",
		scroll_view:"Boolean",
		series_tags_junctions:"voyce_series_tags_junction",
		series_tags_junctions_aggregate:"voyce_series_tags_junction_aggregate",
		short_desc:"String",
		slug:"String",
		sponsorship_config:"voyce_series_sponsorships_configuration",
		sponsorships:"voyce_sponsorships",
		sponsorships_aggregate:"voyce_sponsorships_aggregate",
		status:"String",
		subscriber_count:"Int",
		support_msg:"String",
		team_spotlight:"Boolean",
		thumbnail:"String",
		thumbnail_box:"String",
		title:"String",
		trending:"bit",
		trending_position:"Int",
		type:"voyce_comic_types",
		updated_at:"timestamp",
		user_display_field:"String",
		user_id:"Int",
		users_series_junctions:"voyce_users_series_junction",
		users_series_junctions_aggregate:"voyce_users_series_junction_aggregate",
		view_count:"Int",
		views:"voyce_chapter_views",
		views_aggregate:"voyce_chapter_views_aggregate",
		views_counts:"voyce_pg_rs_series_views_count",
		welcome_msg:"String"
	},
	voyce_series_aggregate:{
		aggregate:"voyce_series_aggregate_fields",
		nodes:"voyce_series"
	},
	voyce_series_aggregate_fields:{
		avg:"voyce_series_avg_fields",
		count:"Int",
		max:"voyce_series_max_fields",
		min:"voyce_series_min_fields",
		stddev:"voyce_series_stddev_fields",
		stddev_pop:"voyce_series_stddev_pop_fields",
		stddev_samp:"voyce_series_stddev_samp_fields",
		sum:"voyce_series_sum_fields",
		var_pop:"voyce_series_var_pop_fields",
		var_samp:"voyce_series_var_samp_fields",
		variance:"voyce_series_variance_fields"
	},
	voyce_series_avg_fields:{
		average_rating:"Float",
		chapter_count:"Float",
		coin_price:"Float",
		comic_type_id:"Float",
		frequency_mask:"Float",
		id:"Float",
		is_approved:"Float",
		is_intro:"Float",
		is_original:"Float",
		is_pilots:"Float",
		is_recommended:"Float",
		locked_chapters_amount:"Float",
		pilots_votes_count:"Float",
		publish:"Float",
		rating_count:"Float",
		subscriber_count:"Float",
		trending_position:"Float",
		user_id:"Float",
		view_count:"Float"
	},
	voyce_series_collaborators:{
		collaborator_role:"voyce_collaborator_roles",
		created_at:"timestamptz",
		id:"Int",
		role_id:"Int",
		series:"voyce_series",
		series_id:"Int",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_series_collaborators_aggregate:{
		aggregate:"voyce_series_collaborators_aggregate_fields",
		nodes:"voyce_series_collaborators"
	},
	voyce_series_collaborators_aggregate_fields:{
		avg:"voyce_series_collaborators_avg_fields",
		count:"Int",
		max:"voyce_series_collaborators_max_fields",
		min:"voyce_series_collaborators_min_fields",
		stddev:"voyce_series_collaborators_stddev_fields",
		stddev_pop:"voyce_series_collaborators_stddev_pop_fields",
		stddev_samp:"voyce_series_collaborators_stddev_samp_fields",
		sum:"voyce_series_collaborators_sum_fields",
		var_pop:"voyce_series_collaborators_var_pop_fields",
		var_samp:"voyce_series_collaborators_var_samp_fields",
		variance:"voyce_series_collaborators_variance_fields"
	},
	voyce_series_collaborators_avg_fields:{
		id:"Float",
		role_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_collaborators_max_fields:{
		created_at:"timestamptz",
		id:"Int",
		role_id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_collaborators_min_fields:{
		created_at:"timestamptz",
		id:"Int",
		role_id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_collaborators_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_collaborators"
	},
	voyce_series_collaborators_stddev_fields:{
		id:"Float",
		role_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_collaborators_stddev_pop_fields:{
		id:"Float",
		role_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_collaborators_stddev_samp_fields:{
		id:"Float",
		role_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_collaborators_sum_fields:{
		id:"Int",
		role_id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_collaborators_var_pop_fields:{
		id:"Float",
		role_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_collaborators_var_samp_fields:{
		id:"Float",
		role_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_collaborators_variance_fields:{
		id:"Float",
		role_id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_donations:{
		amount:"numeric",
		created_at:"timestamptz",
		currency:"String",
		id:"Int",
		message:"String",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_donations_aggregate:{
		aggregate:"voyce_series_donations_aggregate_fields",
		nodes:"voyce_series_donations"
	},
	voyce_series_donations_aggregate_fields:{
		avg:"voyce_series_donations_avg_fields",
		count:"Int",
		max:"voyce_series_donations_max_fields",
		min:"voyce_series_donations_min_fields",
		stddev:"voyce_series_donations_stddev_fields",
		stddev_pop:"voyce_series_donations_stddev_pop_fields",
		stddev_samp:"voyce_series_donations_stddev_samp_fields",
		sum:"voyce_series_donations_sum_fields",
		var_pop:"voyce_series_donations_var_pop_fields",
		var_samp:"voyce_series_donations_var_samp_fields",
		variance:"voyce_series_donations_variance_fields"
	},
	voyce_series_donations_avg_fields:{
		amount:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_donations_max_fields:{
		amount:"numeric",
		created_at:"timestamptz",
		currency:"String",
		id:"Int",
		message:"String",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_donations_min_fields:{
		amount:"numeric",
		created_at:"timestamptz",
		currency:"String",
		id:"Int",
		message:"String",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_donations_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_donations"
	},
	voyce_series_donations_stddev_fields:{
		amount:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_donations_stddev_pop_fields:{
		amount:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_donations_stddev_samp_fields:{
		amount:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_donations_sum_fields:{
		amount:"numeric",
		id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_donations_var_pop_fields:{
		amount:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_donations_var_samp_fields:{
		amount:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_donations_variance_fields:{
		amount:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_favorites:{
		created_at:"timestamptz",
		id:"Int",
		series:"voyce_series",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_favorites_aggregate:{
		aggregate:"voyce_series_favorites_aggregate_fields",
		nodes:"voyce_series_favorites"
	},
	voyce_series_favorites_aggregate_fields:{
		avg:"voyce_series_favorites_avg_fields",
		count:"Int",
		max:"voyce_series_favorites_max_fields",
		min:"voyce_series_favorites_min_fields",
		stddev:"voyce_series_favorites_stddev_fields",
		stddev_pop:"voyce_series_favorites_stddev_pop_fields",
		stddev_samp:"voyce_series_favorites_stddev_samp_fields",
		sum:"voyce_series_favorites_sum_fields",
		var_pop:"voyce_series_favorites_var_pop_fields",
		var_samp:"voyce_series_favorites_var_samp_fields",
		variance:"voyce_series_favorites_variance_fields"
	},
	voyce_series_favorites_avg_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_favorites_max_fields:{
		created_at:"timestamptz",
		id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_favorites_min_fields:{
		created_at:"timestamptz",
		id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_favorites_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_favorites"
	},
	voyce_series_favorites_stddev_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_favorites_stddev_pop_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_favorites_stddev_samp_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_favorites_sum_fields:{
		id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_favorites_var_pop_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_favorites_var_samp_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_favorites_variance_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_featured_panels:{
		id:"Int",
		image:"String",
		series_id:"Int"
	},
	voyce_series_featured_panels_aggregate:{
		aggregate:"voyce_series_featured_panels_aggregate_fields",
		nodes:"voyce_series_featured_panels"
	},
	voyce_series_featured_panels_aggregate_fields:{
		avg:"voyce_series_featured_panels_avg_fields",
		count:"Int",
		max:"voyce_series_featured_panels_max_fields",
		min:"voyce_series_featured_panels_min_fields",
		stddev:"voyce_series_featured_panels_stddev_fields",
		stddev_pop:"voyce_series_featured_panels_stddev_pop_fields",
		stddev_samp:"voyce_series_featured_panels_stddev_samp_fields",
		sum:"voyce_series_featured_panels_sum_fields",
		var_pop:"voyce_series_featured_panels_var_pop_fields",
		var_samp:"voyce_series_featured_panels_var_samp_fields",
		variance:"voyce_series_featured_panels_variance_fields"
	},
	voyce_series_featured_panels_avg_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_featured_panels_max_fields:{
		id:"Int",
		image:"String",
		series_id:"Int"
	},
	voyce_series_featured_panels_min_fields:{
		id:"Int",
		image:"String",
		series_id:"Int"
	},
	voyce_series_featured_panels_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_featured_panels"
	},
	voyce_series_featured_panels_stddev_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_featured_panels_stddev_pop_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_featured_panels_stddev_samp_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_featured_panels_sum_fields:{
		id:"Int",
		series_id:"Int"
	},
	voyce_series_featured_panels_var_pop_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_featured_panels_var_samp_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_featured_panels_variance_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_genre:{
		created_at:"timestamp",
		genre:"voyce_genres",
		genre_id:"Int",
		id:"Int",
		series:"voyce_series",
		series_id:"Int",
		sub_genre:"Boolean"
	},
	voyce_series_genre_aggregate:{
		aggregate:"voyce_series_genre_aggregate_fields",
		nodes:"voyce_series_genre"
	},
	voyce_series_genre_aggregate_fields:{
		avg:"voyce_series_genre_avg_fields",
		count:"Int",
		max:"voyce_series_genre_max_fields",
		min:"voyce_series_genre_min_fields",
		stddev:"voyce_series_genre_stddev_fields",
		stddev_pop:"voyce_series_genre_stddev_pop_fields",
		stddev_samp:"voyce_series_genre_stddev_samp_fields",
		sum:"voyce_series_genre_sum_fields",
		var_pop:"voyce_series_genre_var_pop_fields",
		var_samp:"voyce_series_genre_var_samp_fields",
		variance:"voyce_series_genre_variance_fields"
	},
	voyce_series_genre_avg_fields:{
		genre_id:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_genre_max_fields:{
		created_at:"timestamp",
		genre_id:"Int",
		id:"Int",
		series_id:"Int"
	},
	voyce_series_genre_min_fields:{
		created_at:"timestamp",
		genre_id:"Int",
		id:"Int",
		series_id:"Int"
	},
	voyce_series_genre_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_genre"
	},
	voyce_series_genre_stddev_fields:{
		genre_id:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_genre_stddev_pop_fields:{
		genre_id:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_genre_stddev_samp_fields:{
		genre_id:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_genre_sum_fields:{
		genre_id:"Int",
		id:"Int",
		series_id:"Int"
	},
	voyce_series_genre_var_pop_fields:{
		genre_id:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_genre_var_samp_fields:{
		genre_id:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_genre_variance_fields:{
		genre_id:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_giveaway_participants:{
		created_at:"timestamptz",
		email:"String",
		first_name:"String",
		from_series:"Boolean",
		id:"Int",
		last_name:"String",
		series_id:"Int"
	},
	voyce_series_giveaway_participants_aggregate:{
		aggregate:"voyce_series_giveaway_participants_aggregate_fields",
		nodes:"voyce_series_giveaway_participants"
	},
	voyce_series_giveaway_participants_aggregate_fields:{
		avg:"voyce_series_giveaway_participants_avg_fields",
		count:"Int",
		max:"voyce_series_giveaway_participants_max_fields",
		min:"voyce_series_giveaway_participants_min_fields",
		stddev:"voyce_series_giveaway_participants_stddev_fields",
		stddev_pop:"voyce_series_giveaway_participants_stddev_pop_fields",
		stddev_samp:"voyce_series_giveaway_participants_stddev_samp_fields",
		sum:"voyce_series_giveaway_participants_sum_fields",
		var_pop:"voyce_series_giveaway_participants_var_pop_fields",
		var_samp:"voyce_series_giveaway_participants_var_samp_fields",
		variance:"voyce_series_giveaway_participants_variance_fields"
	},
	voyce_series_giveaway_participants_avg_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_giveaway_participants_max_fields:{
		created_at:"timestamptz",
		email:"String",
		first_name:"String",
		id:"Int",
		last_name:"String",
		series_id:"Int"
	},
	voyce_series_giveaway_participants_min_fields:{
		created_at:"timestamptz",
		email:"String",
		first_name:"String",
		id:"Int",
		last_name:"String",
		series_id:"Int"
	},
	voyce_series_giveaway_participants_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_giveaway_participants"
	},
	voyce_series_giveaway_participants_stddev_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_giveaway_participants_stddev_pop_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_giveaway_participants_stddev_samp_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_giveaway_participants_sum_fields:{
		id:"Int",
		series_id:"Int"
	},
	voyce_series_giveaway_participants_var_pop_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_giveaway_participants_var_samp_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_giveaway_participants_variance_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_hot_panels:{
		active:"Boolean",
		created_at:"timestamptz",
		id:"Int",
		image:"String",
		order:"smallint",
		series_id:"Int",
		spoiler:"Boolean",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_series_hot_panels_aggregate:{
		aggregate:"voyce_series_hot_panels_aggregate_fields",
		nodes:"voyce_series_hot_panels"
	},
	voyce_series_hot_panels_aggregate_fields:{
		avg:"voyce_series_hot_panels_avg_fields",
		count:"Int",
		max:"voyce_series_hot_panels_max_fields",
		min:"voyce_series_hot_panels_min_fields",
		stddev:"voyce_series_hot_panels_stddev_fields",
		stddev_pop:"voyce_series_hot_panels_stddev_pop_fields",
		stddev_samp:"voyce_series_hot_panels_stddev_samp_fields",
		sum:"voyce_series_hot_panels_sum_fields",
		var_pop:"voyce_series_hot_panels_var_pop_fields",
		var_samp:"voyce_series_hot_panels_var_samp_fields",
		variance:"voyce_series_hot_panels_variance_fields"
	},
	voyce_series_hot_panels_avg_fields:{
		id:"Float",
		order:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_hot_panels_max_fields:{
		created_at:"timestamptz",
		id:"Int",
		image:"String",
		order:"smallint",
		series_id:"Int",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_series_hot_panels_min_fields:{
		created_at:"timestamptz",
		id:"Int",
		image:"String",
		order:"smallint",
		series_id:"Int",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_series_hot_panels_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_hot_panels"
	},
	voyce_series_hot_panels_stddev_fields:{
		id:"Float",
		order:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_hot_panels_stddev_pop_fields:{
		id:"Float",
		order:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_hot_panels_stddev_samp_fields:{
		id:"Float",
		order:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_hot_panels_sum_fields:{
		id:"Int",
		order:"smallint",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_hot_panels_var_pop_fields:{
		id:"Float",
		order:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_hot_panels_var_samp_fields:{
		id:"Float",
		order:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_hot_panels_variance_fields:{
		id:"Float",
		order:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_layouts:{
		active:"Boolean",
		bg_image:"String",
		characters:"json",
		comments:"json",
		dl_banner:"json",
		id:"Int",
		layout:"smallint",
		logo:"String",
		menu:"json",
		series_id:"Int",
		socials:"json",
		story:"json"
	},
	voyce_series_layouts_aggregate:{
		aggregate:"voyce_series_layouts_aggregate_fields",
		nodes:"voyce_series_layouts"
	},
	voyce_series_layouts_aggregate_fields:{
		avg:"voyce_series_layouts_avg_fields",
		count:"Int",
		max:"voyce_series_layouts_max_fields",
		min:"voyce_series_layouts_min_fields",
		stddev:"voyce_series_layouts_stddev_fields",
		stddev_pop:"voyce_series_layouts_stddev_pop_fields",
		stddev_samp:"voyce_series_layouts_stddev_samp_fields",
		sum:"voyce_series_layouts_sum_fields",
		var_pop:"voyce_series_layouts_var_pop_fields",
		var_samp:"voyce_series_layouts_var_samp_fields",
		variance:"voyce_series_layouts_variance_fields"
	},
	voyce_series_layouts_avg_fields:{
		id:"Float",
		layout:"Float",
		series_id:"Float"
	},
	voyce_series_layouts_max_fields:{
		bg_image:"String",
		id:"Int",
		layout:"smallint",
		logo:"String",
		series_id:"Int"
	},
	voyce_series_layouts_min_fields:{
		bg_image:"String",
		id:"Int",
		layout:"smallint",
		logo:"String",
		series_id:"Int"
	},
	voyce_series_layouts_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_layouts"
	},
	voyce_series_layouts_stddev_fields:{
		id:"Float",
		layout:"Float",
		series_id:"Float"
	},
	voyce_series_layouts_stddev_pop_fields:{
		id:"Float",
		layout:"Float",
		series_id:"Float"
	},
	voyce_series_layouts_stddev_samp_fields:{
		id:"Float",
		layout:"Float",
		series_id:"Float"
	},
	voyce_series_layouts_sum_fields:{
		id:"Int",
		layout:"smallint",
		series_id:"Int"
	},
	voyce_series_layouts_var_pop_fields:{
		id:"Float",
		layout:"Float",
		series_id:"Float"
	},
	voyce_series_layouts_var_samp_fields:{
		id:"Float",
		layout:"Float",
		series_id:"Float"
	},
	voyce_series_layouts_variance_fields:{
		id:"Float",
		layout:"Float",
		series_id:"Float"
	},
	voyce_series_leaderboard:{
		amount:"numeric",
		arrow:"String",
		created_at:"timestamptz",
		currency:"String",
		date:"date",
		id:"Int",
		rank:"Int",
		series_id:"Int",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_series_leaderboard_aggregate:{
		aggregate:"voyce_series_leaderboard_aggregate_fields",
		nodes:"voyce_series_leaderboard"
	},
	voyce_series_leaderboard_aggregate_fields:{
		avg:"voyce_series_leaderboard_avg_fields",
		count:"Int",
		max:"voyce_series_leaderboard_max_fields",
		min:"voyce_series_leaderboard_min_fields",
		stddev:"voyce_series_leaderboard_stddev_fields",
		stddev_pop:"voyce_series_leaderboard_stddev_pop_fields",
		stddev_samp:"voyce_series_leaderboard_stddev_samp_fields",
		sum:"voyce_series_leaderboard_sum_fields",
		var_pop:"voyce_series_leaderboard_var_pop_fields",
		var_samp:"voyce_series_leaderboard_var_samp_fields",
		variance:"voyce_series_leaderboard_variance_fields"
	},
	voyce_series_leaderboard_avg_fields:{
		amount:"Float",
		id:"Float",
		rank:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_leaderboard_max_fields:{
		amount:"numeric",
		arrow:"String",
		created_at:"timestamptz",
		currency:"String",
		date:"date",
		id:"Int",
		rank:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_leaderboard_min_fields:{
		amount:"numeric",
		arrow:"String",
		created_at:"timestamptz",
		currency:"String",
		date:"date",
		id:"Int",
		rank:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_leaderboard_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_leaderboard"
	},
	voyce_series_leaderboard_stddev_fields:{
		amount:"Float",
		id:"Float",
		rank:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_leaderboard_stddev_pop_fields:{
		amount:"Float",
		id:"Float",
		rank:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_leaderboard_stddev_samp_fields:{
		amount:"Float",
		id:"Float",
		rank:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_leaderboard_sum_fields:{
		amount:"numeric",
		id:"Int",
		rank:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_leaderboard_var_pop_fields:{
		amount:"Float",
		id:"Float",
		rank:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_leaderboard_var_samp_fields:{
		amount:"Float",
		id:"Float",
		rank:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_leaderboard_variance_fields:{
		amount:"Float",
		id:"Float",
		rank:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_likes_count:{
		count:"bigint",
		series_id:"Int"
	},
	voyce_series_likes_count_aggregate:{
		aggregate:"voyce_series_likes_count_aggregate_fields",
		nodes:"voyce_series_likes_count"
	},
	voyce_series_likes_count_aggregate_fields:{
		avg:"voyce_series_likes_count_avg_fields",
		count:"Int",
		max:"voyce_series_likes_count_max_fields",
		min:"voyce_series_likes_count_min_fields",
		stddev:"voyce_series_likes_count_stddev_fields",
		stddev_pop:"voyce_series_likes_count_stddev_pop_fields",
		stddev_samp:"voyce_series_likes_count_stddev_samp_fields",
		sum:"voyce_series_likes_count_sum_fields",
		var_pop:"voyce_series_likes_count_var_pop_fields",
		var_samp:"voyce_series_likes_count_var_samp_fields",
		variance:"voyce_series_likes_count_variance_fields"
	},
	voyce_series_likes_count_avg_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_series_likes_count_mat:{
		actual_count:"bigint",
		count:"float8",
		series_id:"Int"
	},
	voyce_series_likes_count_mat_aggregate:{
		aggregate:"voyce_series_likes_count_mat_aggregate_fields",
		nodes:"voyce_series_likes_count_mat"
	},
	voyce_series_likes_count_mat_aggregate_fields:{
		avg:"voyce_series_likes_count_mat_avg_fields",
		count:"Int",
		max:"voyce_series_likes_count_mat_max_fields",
		min:"voyce_series_likes_count_mat_min_fields",
		stddev:"voyce_series_likes_count_mat_stddev_fields",
		stddev_pop:"voyce_series_likes_count_mat_stddev_pop_fields",
		stddev_samp:"voyce_series_likes_count_mat_stddev_samp_fields",
		sum:"voyce_series_likes_count_mat_sum_fields",
		var_pop:"voyce_series_likes_count_mat_var_pop_fields",
		var_samp:"voyce_series_likes_count_mat_var_samp_fields",
		variance:"voyce_series_likes_count_mat_variance_fields"
	},
	voyce_series_likes_count_mat_avg_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_likes_count_mat_max_fields:{
		actual_count:"bigint",
		count:"float8",
		series_id:"Int"
	},
	voyce_series_likes_count_mat_min_fields:{
		actual_count:"bigint",
		count:"float8",
		series_id:"Int"
	},
	voyce_series_likes_count_mat_stddev_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_likes_count_mat_stddev_pop_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_likes_count_mat_stddev_samp_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_likes_count_mat_sum_fields:{
		actual_count:"bigint",
		count:"float8",
		series_id:"Int"
	},
	voyce_series_likes_count_mat_var_pop_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_likes_count_mat_var_samp_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_likes_count_mat_variance_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_likes_count_max_fields:{
		count:"bigint",
		series_id:"Int"
	},
	voyce_series_likes_count_min_fields:{
		count:"bigint",
		series_id:"Int"
	},
	voyce_series_likes_count_stddev_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_series_likes_count_stddev_pop_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_series_likes_count_stddev_samp_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_series_likes_count_sum_fields:{
		count:"bigint",
		series_id:"Int"
	},
	voyce_series_likes_count_var_pop_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_series_likes_count_var_samp_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_series_likes_count_variance_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_series_lock_config:{
		buy_currency:"String",
		buy_price:"numeric",
		created_at:"timestamptz",
		id:"Int",
		lock_duration:"numeric",
		lock_latest_chapters:"Int",
		rent_currency:"String",
		rent_duration:"numeric",
		rent_price:"numeric",
		series_id:"Int",
		updated_at:"timestamptz"
	},
	voyce_series_lock_config_aggregate:{
		aggregate:"voyce_series_lock_config_aggregate_fields",
		nodes:"voyce_series_lock_config"
	},
	voyce_series_lock_config_aggregate_fields:{
		avg:"voyce_series_lock_config_avg_fields",
		count:"Int",
		max:"voyce_series_lock_config_max_fields",
		min:"voyce_series_lock_config_min_fields",
		stddev:"voyce_series_lock_config_stddev_fields",
		stddev_pop:"voyce_series_lock_config_stddev_pop_fields",
		stddev_samp:"voyce_series_lock_config_stddev_samp_fields",
		sum:"voyce_series_lock_config_sum_fields",
		var_pop:"voyce_series_lock_config_var_pop_fields",
		var_samp:"voyce_series_lock_config_var_samp_fields",
		variance:"voyce_series_lock_config_variance_fields"
	},
	voyce_series_lock_config_avg_fields:{
		buy_price:"Float",
		id:"Float",
		lock_duration:"Float",
		lock_latest_chapters:"Float",
		rent_duration:"Float",
		rent_price:"Float",
		series_id:"Float"
	},
	voyce_series_lock_config_max_fields:{
		buy_currency:"String",
		buy_price:"numeric",
		created_at:"timestamptz",
		id:"Int",
		lock_duration:"numeric",
		lock_latest_chapters:"Int",
		rent_currency:"String",
		rent_duration:"numeric",
		rent_price:"numeric",
		series_id:"Int",
		updated_at:"timestamptz"
	},
	voyce_series_lock_config_min_fields:{
		buy_currency:"String",
		buy_price:"numeric",
		created_at:"timestamptz",
		id:"Int",
		lock_duration:"numeric",
		lock_latest_chapters:"Int",
		rent_currency:"String",
		rent_duration:"numeric",
		rent_price:"numeric",
		series_id:"Int",
		updated_at:"timestamptz"
	},
	voyce_series_lock_config_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_lock_config"
	},
	voyce_series_lock_config_stddev_fields:{
		buy_price:"Float",
		id:"Float",
		lock_duration:"Float",
		lock_latest_chapters:"Float",
		rent_duration:"Float",
		rent_price:"Float",
		series_id:"Float"
	},
	voyce_series_lock_config_stddev_pop_fields:{
		buy_price:"Float",
		id:"Float",
		lock_duration:"Float",
		lock_latest_chapters:"Float",
		rent_duration:"Float",
		rent_price:"Float",
		series_id:"Float"
	},
	voyce_series_lock_config_stddev_samp_fields:{
		buy_price:"Float",
		id:"Float",
		lock_duration:"Float",
		lock_latest_chapters:"Float",
		rent_duration:"Float",
		rent_price:"Float",
		series_id:"Float"
	},
	voyce_series_lock_config_sum_fields:{
		buy_price:"numeric",
		id:"Int",
		lock_duration:"numeric",
		lock_latest_chapters:"Int",
		rent_duration:"numeric",
		rent_price:"numeric",
		series_id:"Int"
	},
	voyce_series_lock_config_var_pop_fields:{
		buy_price:"Float",
		id:"Float",
		lock_duration:"Float",
		lock_latest_chapters:"Float",
		rent_duration:"Float",
		rent_price:"Float",
		series_id:"Float"
	},
	voyce_series_lock_config_var_samp_fields:{
		buy_price:"Float",
		id:"Float",
		lock_duration:"Float",
		lock_latest_chapters:"Float",
		rent_duration:"Float",
		rent_price:"Float",
		series_id:"Float"
	},
	voyce_series_lock_config_variance_fields:{
		buy_price:"Float",
		id:"Float",
		lock_duration:"Float",
		lock_latest_chapters:"Float",
		rent_duration:"Float",
		rent_price:"Float",
		series_id:"Float"
	},
	voyce_series_max_fields:{
		animated_cover_image:"String",
		average_rating:"float8",
		back_image:"String",
		banner_bg:"String",
		chapter_count:"Int",
		chapters_hide:"String",
		character_cover:"String",
		coin_price:"smallint",
		comic_type_id:"Int",
		cover_image:"String",
		cover_image_mobile:"String",
		created_at:"timestamp",
		default_view:"String",
		description:"String",
		frequency:"String",
		frequency_mask:"Int",
		front_image:"String",
		id:"Int",
		is_approved:"smallint",
		is_intro:"smallint",
		is_original:"smallint",
		is_pilots:"smallint",
		is_recommended:"smallint",
		klaviyo_id:"String",
		klaviyo_list_id:"String",
		latest_chapter_update:"timestamp",
		locked_chapters_amount:"Int",
		pilots_votes_count:"Int",
		portfolio_desc:"String",
		publish:"smallint",
		rating_count:"Int",
		reading_view:"String",
		short_desc:"String",
		slug:"String",
		status:"String",
		subscriber_count:"Int",
		support_msg:"String",
		thumbnail:"String",
		thumbnail_box:"String",
		title:"String",
		trending_position:"Int",
		updated_at:"timestamp",
		user_display_field:"String",
		user_id:"Int",
		view_count:"Int",
		welcome_msg:"String"
	},
	voyce_series_min_fields:{
		animated_cover_image:"String",
		average_rating:"float8",
		back_image:"String",
		banner_bg:"String",
		chapter_count:"Int",
		chapters_hide:"String",
		character_cover:"String",
		coin_price:"smallint",
		comic_type_id:"Int",
		cover_image:"String",
		cover_image_mobile:"String",
		created_at:"timestamp",
		default_view:"String",
		description:"String",
		frequency:"String",
		frequency_mask:"Int",
		front_image:"String",
		id:"Int",
		is_approved:"smallint",
		is_intro:"smallint",
		is_original:"smallint",
		is_pilots:"smallint",
		is_recommended:"smallint",
		klaviyo_id:"String",
		klaviyo_list_id:"String",
		latest_chapter_update:"timestamp",
		locked_chapters_amount:"Int",
		pilots_votes_count:"Int",
		portfolio_desc:"String",
		publish:"smallint",
		rating_count:"Int",
		reading_view:"String",
		short_desc:"String",
		slug:"String",
		status:"String",
		subscriber_count:"Int",
		support_msg:"String",
		thumbnail:"String",
		thumbnail_box:"String",
		title:"String",
		trending_position:"Int",
		updated_at:"timestamp",
		user_display_field:"String",
		user_id:"Int",
		view_count:"Int",
		welcome_msg:"String"
	},
	voyce_series_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series"
	},
	voyce_series_sponsorships_configuration:{
		enabled:"Boolean",
		id:"Int",
		message:"String",
		series:"voyce_series",
		series_id:"Int",
		voyceme_payee:"Boolean"
	},
	voyce_series_sponsorships_configuration_aggregate:{
		aggregate:"voyce_series_sponsorships_configuration_aggregate_fields",
		nodes:"voyce_series_sponsorships_configuration"
	},
	voyce_series_sponsorships_configuration_aggregate_fields:{
		avg:"voyce_series_sponsorships_configuration_avg_fields",
		count:"Int",
		max:"voyce_series_sponsorships_configuration_max_fields",
		min:"voyce_series_sponsorships_configuration_min_fields",
		stddev:"voyce_series_sponsorships_configuration_stddev_fields",
		stddev_pop:"voyce_series_sponsorships_configuration_stddev_pop_fields",
		stddev_samp:"voyce_series_sponsorships_configuration_stddev_samp_fields",
		sum:"voyce_series_sponsorships_configuration_sum_fields",
		var_pop:"voyce_series_sponsorships_configuration_var_pop_fields",
		var_samp:"voyce_series_sponsorships_configuration_var_samp_fields",
		variance:"voyce_series_sponsorships_configuration_variance_fields"
	},
	voyce_series_sponsorships_configuration_avg_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_sponsorships_configuration_max_fields:{
		id:"Int",
		message:"String",
		series_id:"Int"
	},
	voyce_series_sponsorships_configuration_min_fields:{
		id:"Int",
		message:"String",
		series_id:"Int"
	},
	voyce_series_sponsorships_configuration_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_sponsorships_configuration"
	},
	voyce_series_sponsorships_configuration_stddev_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_sponsorships_configuration_stddev_pop_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_sponsorships_configuration_stddev_samp_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_sponsorships_configuration_sum_fields:{
		id:"Int",
		series_id:"Int"
	},
	voyce_series_sponsorships_configuration_var_pop_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_sponsorships_configuration_var_samp_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_sponsorships_configuration_variance_fields:{
		id:"Float",
		series_id:"Float"
	},
	voyce_series_stddev_fields:{
		average_rating:"Float",
		chapter_count:"Float",
		coin_price:"Float",
		comic_type_id:"Float",
		frequency_mask:"Float",
		id:"Float",
		is_approved:"Float",
		is_intro:"Float",
		is_original:"Float",
		is_pilots:"Float",
		is_recommended:"Float",
		locked_chapters_amount:"Float",
		pilots_votes_count:"Float",
		publish:"Float",
		rating_count:"Float",
		subscriber_count:"Float",
		trending_position:"Float",
		user_id:"Float",
		view_count:"Float"
	},
	voyce_series_stddev_pop_fields:{
		average_rating:"Float",
		chapter_count:"Float",
		coin_price:"Float",
		comic_type_id:"Float",
		frequency_mask:"Float",
		id:"Float",
		is_approved:"Float",
		is_intro:"Float",
		is_original:"Float",
		is_pilots:"Float",
		is_recommended:"Float",
		locked_chapters_amount:"Float",
		pilots_votes_count:"Float",
		publish:"Float",
		rating_count:"Float",
		subscriber_count:"Float",
		trending_position:"Float",
		user_id:"Float",
		view_count:"Float"
	},
	voyce_series_stddev_samp_fields:{
		average_rating:"Float",
		chapter_count:"Float",
		coin_price:"Float",
		comic_type_id:"Float",
		frequency_mask:"Float",
		id:"Float",
		is_approved:"Float",
		is_intro:"Float",
		is_original:"Float",
		is_pilots:"Float",
		is_recommended:"Float",
		locked_chapters_amount:"Float",
		pilots_votes_count:"Float",
		publish:"Float",
		rating_count:"Float",
		subscriber_count:"Float",
		trending_position:"Float",
		user_id:"Float",
		view_count:"Float"
	},
	voyce_series_subscription:{
		created_at:"timestamp",
		id:"Int",
		klaviyo_id:"String",
		series:"voyce_series",
		series_id:"Int",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_series_subscription_aggregate:{
		aggregate:"voyce_series_subscription_aggregate_fields",
		nodes:"voyce_series_subscription"
	},
	voyce_series_subscription_aggregate_fields:{
		avg:"voyce_series_subscription_avg_fields",
		count:"Int",
		max:"voyce_series_subscription_max_fields",
		min:"voyce_series_subscription_min_fields",
		stddev:"voyce_series_subscription_stddev_fields",
		stddev_pop:"voyce_series_subscription_stddev_pop_fields",
		stddev_samp:"voyce_series_subscription_stddev_samp_fields",
		sum:"voyce_series_subscription_sum_fields",
		var_pop:"voyce_series_subscription_var_pop_fields",
		var_samp:"voyce_series_subscription_var_samp_fields",
		variance:"voyce_series_subscription_variance_fields"
	},
	voyce_series_subscription_avg_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_subscription_max_fields:{
		created_at:"timestamp",
		id:"Int",
		klaviyo_id:"String",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_subscription_min_fields:{
		created_at:"timestamp",
		id:"Int",
		klaviyo_id:"String",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_subscription_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_subscription"
	},
	voyce_series_subscription_stddev_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_subscription_stddev_pop_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_subscription_stddev_samp_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_subscription_sum_fields:{
		id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_subscription_var_pop_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_subscription_var_samp_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_subscription_variance_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_sum_fields:{
		average_rating:"float8",
		chapter_count:"Int",
		coin_price:"smallint",
		comic_type_id:"Int",
		frequency_mask:"Int",
		id:"Int",
		is_approved:"smallint",
		is_intro:"smallint",
		is_original:"smallint",
		is_pilots:"smallint",
		is_recommended:"smallint",
		locked_chapters_amount:"Int",
		pilots_votes_count:"Int",
		publish:"smallint",
		rating_count:"Int",
		subscriber_count:"Int",
		trending_position:"Int",
		user_id:"Int",
		view_count:"Int"
	},
	voyce_series_tags:{
		id:"Int",
		name:"String",
		series_tags_junctions:"voyce_series_tags_junction",
		series_tags_junctions_aggregate:"voyce_series_tags_junction_aggregate"
	},
	voyce_series_tags_aggregate:{
		aggregate:"voyce_series_tags_aggregate_fields",
		nodes:"voyce_series_tags"
	},
	voyce_series_tags_aggregate_fields:{
		avg:"voyce_series_tags_avg_fields",
		count:"Int",
		max:"voyce_series_tags_max_fields",
		min:"voyce_series_tags_min_fields",
		stddev:"voyce_series_tags_stddev_fields",
		stddev_pop:"voyce_series_tags_stddev_pop_fields",
		stddev_samp:"voyce_series_tags_stddev_samp_fields",
		sum:"voyce_series_tags_sum_fields",
		var_pop:"voyce_series_tags_var_pop_fields",
		var_samp:"voyce_series_tags_var_samp_fields",
		variance:"voyce_series_tags_variance_fields"
	},
	voyce_series_tags_avg_fields:{
		id:"Float"
	},
	voyce_series_tags_junction:{
		admin_created:"Boolean",
		chapter:"voyce_chapters",
		chapter_id:"Int",
		id:"Int",
		images:"jsonb",
		order:"Int",
		series:"voyce_series",
		series_id:"Int",
		series_tag:"voyce_series_tags",
		tag_id:"Int"
	},
	voyce_series_tags_junction_aggregate:{
		aggregate:"voyce_series_tags_junction_aggregate_fields",
		nodes:"voyce_series_tags_junction"
	},
	voyce_series_tags_junction_aggregate_fields:{
		avg:"voyce_series_tags_junction_avg_fields",
		count:"Int",
		max:"voyce_series_tags_junction_max_fields",
		min:"voyce_series_tags_junction_min_fields",
		stddev:"voyce_series_tags_junction_stddev_fields",
		stddev_pop:"voyce_series_tags_junction_stddev_pop_fields",
		stddev_samp:"voyce_series_tags_junction_stddev_samp_fields",
		sum:"voyce_series_tags_junction_sum_fields",
		var_pop:"voyce_series_tags_junction_var_pop_fields",
		var_samp:"voyce_series_tags_junction_var_samp_fields",
		variance:"voyce_series_tags_junction_variance_fields"
	},
	voyce_series_tags_junction_avg_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float",
		series_id:"Float",
		tag_id:"Float"
	},
	voyce_series_tags_junction_max_fields:{
		chapter_id:"Int",
		id:"Int",
		order:"Int",
		series_id:"Int",
		tag_id:"Int"
	},
	voyce_series_tags_junction_min_fields:{
		chapter_id:"Int",
		id:"Int",
		order:"Int",
		series_id:"Int",
		tag_id:"Int"
	},
	voyce_series_tags_junction_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_tags_junction"
	},
	voyce_series_tags_junction_stddev_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float",
		series_id:"Float",
		tag_id:"Float"
	},
	voyce_series_tags_junction_stddev_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float",
		series_id:"Float",
		tag_id:"Float"
	},
	voyce_series_tags_junction_stddev_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float",
		series_id:"Float",
		tag_id:"Float"
	},
	voyce_series_tags_junction_sum_fields:{
		chapter_id:"Int",
		id:"Int",
		order:"Int",
		series_id:"Int",
		tag_id:"Int"
	},
	voyce_series_tags_junction_var_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float",
		series_id:"Float",
		tag_id:"Float"
	},
	voyce_series_tags_junction_var_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float",
		series_id:"Float",
		tag_id:"Float"
	},
	voyce_series_tags_junction_variance_fields:{
		chapter_id:"Float",
		id:"Float",
		order:"Float",
		series_id:"Float",
		tag_id:"Float"
	},
	voyce_series_tags_max_fields:{
		id:"Int",
		name:"String"
	},
	voyce_series_tags_min_fields:{
		id:"Int",
		name:"String"
	},
	voyce_series_tags_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_tags"
	},
	voyce_series_tags_stddev_fields:{
		id:"Float"
	},
	voyce_series_tags_stddev_pop_fields:{
		id:"Float"
	},
	voyce_series_tags_stddev_samp_fields:{
		id:"Float"
	},
	voyce_series_tags_sum_fields:{
		id:"Int"
	},
	voyce_series_tags_var_pop_fields:{
		id:"Float"
	},
	voyce_series_tags_var_samp_fields:{
		id:"Float"
	},
	voyce_series_tags_variance_fields:{
		id:"Float"
	},
	voyce_series_top_comments:{
		comment:"voyce_chapter_comments",
		comment_id:"Int",
		count:"Int",
		created_at:"timestamptz",
		id:"Int",
		series_id:"Int",
		tag:"String",
		type:"String"
	},
	voyce_series_top_comments_aggregate:{
		aggregate:"voyce_series_top_comments_aggregate_fields",
		nodes:"voyce_series_top_comments"
	},
	voyce_series_top_comments_aggregate_fields:{
		avg:"voyce_series_top_comments_avg_fields",
		count:"Int",
		max:"voyce_series_top_comments_max_fields",
		min:"voyce_series_top_comments_min_fields",
		stddev:"voyce_series_top_comments_stddev_fields",
		stddev_pop:"voyce_series_top_comments_stddev_pop_fields",
		stddev_samp:"voyce_series_top_comments_stddev_samp_fields",
		sum:"voyce_series_top_comments_sum_fields",
		var_pop:"voyce_series_top_comments_var_pop_fields",
		var_samp:"voyce_series_top_comments_var_samp_fields",
		variance:"voyce_series_top_comments_variance_fields"
	},
	voyce_series_top_comments_avg_fields:{
		comment_id:"Float",
		count:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_top_comments_max_fields:{
		comment_id:"Int",
		count:"Int",
		created_at:"timestamptz",
		id:"Int",
		series_id:"Int",
		tag:"String",
		type:"String"
	},
	voyce_series_top_comments_min_fields:{
		comment_id:"Int",
		count:"Int",
		created_at:"timestamptz",
		id:"Int",
		series_id:"Int",
		tag:"String",
		type:"String"
	},
	voyce_series_top_comments_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_top_comments"
	},
	voyce_series_top_comments_stddev_fields:{
		comment_id:"Float",
		count:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_top_comments_stddev_pop_fields:{
		comment_id:"Float",
		count:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_top_comments_stddev_samp_fields:{
		comment_id:"Float",
		count:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_top_comments_sum_fields:{
		comment_id:"Int",
		count:"Int",
		id:"Int",
		series_id:"Int"
	},
	voyce_series_top_comments_var_pop_fields:{
		comment_id:"Float",
		count:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_top_comments_var_samp_fields:{
		comment_id:"Float",
		count:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_top_comments_variance_fields:{
		comment_id:"Float",
		count:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_top_likes:{
		count:"Int",
		created_at:"timestamptz",
		id:"Int",
		is_original:"smallint",
		series:"voyce_series",
		series_id:"Int",
		type:"String"
	},
	voyce_series_top_likes_aggregate:{
		aggregate:"voyce_series_top_likes_aggregate_fields",
		nodes:"voyce_series_top_likes"
	},
	voyce_series_top_likes_aggregate_fields:{
		avg:"voyce_series_top_likes_avg_fields",
		count:"Int",
		max:"voyce_series_top_likes_max_fields",
		min:"voyce_series_top_likes_min_fields",
		stddev:"voyce_series_top_likes_stddev_fields",
		stddev_pop:"voyce_series_top_likes_stddev_pop_fields",
		stddev_samp:"voyce_series_top_likes_stddev_samp_fields",
		sum:"voyce_series_top_likes_sum_fields",
		var_pop:"voyce_series_top_likes_var_pop_fields",
		var_samp:"voyce_series_top_likes_var_samp_fields",
		variance:"voyce_series_top_likes_variance_fields"
	},
	voyce_series_top_likes_avg_fields:{
		count:"Float",
		id:"Float",
		is_original:"Float",
		series_id:"Float"
	},
	voyce_series_top_likes_max_fields:{
		count:"Int",
		created_at:"timestamptz",
		id:"Int",
		is_original:"smallint",
		series_id:"Int",
		type:"String"
	},
	voyce_series_top_likes_min_fields:{
		count:"Int",
		created_at:"timestamptz",
		id:"Int",
		is_original:"smallint",
		series_id:"Int",
		type:"String"
	},
	voyce_series_top_likes_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_top_likes"
	},
	voyce_series_top_likes_stddev_fields:{
		count:"Float",
		id:"Float",
		is_original:"Float",
		series_id:"Float"
	},
	voyce_series_top_likes_stddev_pop_fields:{
		count:"Float",
		id:"Float",
		is_original:"Float",
		series_id:"Float"
	},
	voyce_series_top_likes_stddev_samp_fields:{
		count:"Float",
		id:"Float",
		is_original:"Float",
		series_id:"Float"
	},
	voyce_series_top_likes_sum_fields:{
		count:"Int",
		id:"Int",
		is_original:"smallint",
		series_id:"Int"
	},
	voyce_series_top_likes_var_pop_fields:{
		count:"Float",
		id:"Float",
		is_original:"Float",
		series_id:"Float"
	},
	voyce_series_top_likes_var_samp_fields:{
		count:"Float",
		id:"Float",
		is_original:"Float",
		series_id:"Float"
	},
	voyce_series_top_likes_variance_fields:{
		count:"Float",
		id:"Float",
		is_original:"Float",
		series_id:"Float"
	},
	voyce_series_top_views:{
		comic_type:"String",
		count:"Int",
		created_at:"timestamptz",
		id:"Int",
		prev_rank:"Int",
		rank:"Int",
		series:"voyce_series",
		series_id:"Int",
		tag:"String",
		type:"String"
	},
	voyce_series_top_views_aggregate:{
		aggregate:"voyce_series_top_views_aggregate_fields",
		nodes:"voyce_series_top_views"
	},
	voyce_series_top_views_aggregate_fields:{
		avg:"voyce_series_top_views_avg_fields",
		count:"Int",
		max:"voyce_series_top_views_max_fields",
		min:"voyce_series_top_views_min_fields",
		stddev:"voyce_series_top_views_stddev_fields",
		stddev_pop:"voyce_series_top_views_stddev_pop_fields",
		stddev_samp:"voyce_series_top_views_stddev_samp_fields",
		sum:"voyce_series_top_views_sum_fields",
		var_pop:"voyce_series_top_views_var_pop_fields",
		var_samp:"voyce_series_top_views_var_samp_fields",
		variance:"voyce_series_top_views_variance_fields"
	},
	voyce_series_top_views_avg_fields:{
		count:"Float",
		id:"Float",
		prev_rank:"Float",
		rank:"Float",
		series_id:"Float"
	},
	voyce_series_top_views_max_fields:{
		comic_type:"String",
		count:"Int",
		created_at:"timestamptz",
		id:"Int",
		prev_rank:"Int",
		rank:"Int",
		series_id:"Int",
		tag:"String",
		type:"String"
	},
	voyce_series_top_views_min_fields:{
		comic_type:"String",
		count:"Int",
		created_at:"timestamptz",
		id:"Int",
		prev_rank:"Int",
		rank:"Int",
		series_id:"Int",
		tag:"String",
		type:"String"
	},
	voyce_series_top_views_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_top_views"
	},
	voyce_series_top_views_stddev_fields:{
		count:"Float",
		id:"Float",
		prev_rank:"Float",
		rank:"Float",
		series_id:"Float"
	},
	voyce_series_top_views_stddev_pop_fields:{
		count:"Float",
		id:"Float",
		prev_rank:"Float",
		rank:"Float",
		series_id:"Float"
	},
	voyce_series_top_views_stddev_samp_fields:{
		count:"Float",
		id:"Float",
		prev_rank:"Float",
		rank:"Float",
		series_id:"Float"
	},
	voyce_series_top_views_sum_fields:{
		count:"Int",
		id:"Int",
		prev_rank:"Int",
		rank:"Int",
		series_id:"Int"
	},
	voyce_series_top_views_var_pop_fields:{
		count:"Float",
		id:"Float",
		prev_rank:"Float",
		rank:"Float",
		series_id:"Float"
	},
	voyce_series_top_views_var_samp_fields:{
		count:"Float",
		id:"Float",
		prev_rank:"Float",
		rank:"Float",
		series_id:"Float"
	},
	voyce_series_top_views_variance_fields:{
		count:"Float",
		id:"Float",
		prev_rank:"Float",
		rank:"Float",
		series_id:"Float"
	},
	voyce_series_var_pop_fields:{
		average_rating:"Float",
		chapter_count:"Float",
		coin_price:"Float",
		comic_type_id:"Float",
		frequency_mask:"Float",
		id:"Float",
		is_approved:"Float",
		is_intro:"Float",
		is_original:"Float",
		is_pilots:"Float",
		is_recommended:"Float",
		locked_chapters_amount:"Float",
		pilots_votes_count:"Float",
		publish:"Float",
		rating_count:"Float",
		subscriber_count:"Float",
		trending_position:"Float",
		user_id:"Float",
		view_count:"Float"
	},
	voyce_series_var_samp_fields:{
		average_rating:"Float",
		chapter_count:"Float",
		coin_price:"Float",
		comic_type_id:"Float",
		frequency_mask:"Float",
		id:"Float",
		is_approved:"Float",
		is_intro:"Float",
		is_original:"Float",
		is_pilots:"Float",
		is_recommended:"Float",
		locked_chapters_amount:"Float",
		pilots_votes_count:"Float",
		publish:"Float",
		rating_count:"Float",
		subscriber_count:"Float",
		trending_position:"Float",
		user_id:"Float",
		view_count:"Float"
	},
	voyce_series_variance_fields:{
		average_rating:"Float",
		chapter_count:"Float",
		coin_price:"Float",
		comic_type_id:"Float",
		frequency_mask:"Float",
		id:"Float",
		is_approved:"Float",
		is_intro:"Float",
		is_original:"Float",
		is_pilots:"Float",
		is_recommended:"Float",
		locked_chapters_amount:"Float",
		pilots_votes_count:"Float",
		publish:"Float",
		rating_count:"Float",
		subscriber_count:"Float",
		trending_position:"Float",
		user_id:"Float",
		view_count:"Float"
	},
	voyce_series_views_adjustment:{
		count:"Int",
		created_at:"timestamptz",
		id:"Int",
		series_id:"Int",
		updated_at:"timestamptz"
	},
	voyce_series_views_adjustment_aggregate:{
		aggregate:"voyce_series_views_adjustment_aggregate_fields",
		nodes:"voyce_series_views_adjustment"
	},
	voyce_series_views_adjustment_aggregate_fields:{
		avg:"voyce_series_views_adjustment_avg_fields",
		count:"Int",
		max:"voyce_series_views_adjustment_max_fields",
		min:"voyce_series_views_adjustment_min_fields",
		stddev:"voyce_series_views_adjustment_stddev_fields",
		stddev_pop:"voyce_series_views_adjustment_stddev_pop_fields",
		stddev_samp:"voyce_series_views_adjustment_stddev_samp_fields",
		sum:"voyce_series_views_adjustment_sum_fields",
		var_pop:"voyce_series_views_adjustment_var_pop_fields",
		var_samp:"voyce_series_views_adjustment_var_samp_fields",
		variance:"voyce_series_views_adjustment_variance_fields"
	},
	voyce_series_views_adjustment_avg_fields:{
		count:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_views_adjustment_max_fields:{
		count:"Int",
		created_at:"timestamptz",
		id:"Int",
		series_id:"Int",
		updated_at:"timestamptz"
	},
	voyce_series_views_adjustment_min_fields:{
		count:"Int",
		created_at:"timestamptz",
		id:"Int",
		series_id:"Int",
		updated_at:"timestamptz"
	},
	voyce_series_views_adjustment_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_views_adjustment"
	},
	voyce_series_views_adjustment_stddev_fields:{
		count:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_views_adjustment_stddev_pop_fields:{
		count:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_views_adjustment_stddev_samp_fields:{
		count:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_views_adjustment_sum_fields:{
		count:"Int",
		id:"Int",
		series_id:"Int"
	},
	voyce_series_views_adjustment_var_pop_fields:{
		count:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_views_adjustment_var_samp_fields:{
		count:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_views_adjustment_variance_fields:{
		count:"Float",
		id:"Float",
		series_id:"Float"
	},
	voyce_series_views_count:{
		count:"bigint",
		series_id:"Int"
	},
	voyce_series_views_count_aggregate:{
		aggregate:"voyce_series_views_count_aggregate_fields",
		nodes:"voyce_series_views_count"
	},
	voyce_series_views_count_aggregate_fields:{
		avg:"voyce_series_views_count_avg_fields",
		count:"Int",
		max:"voyce_series_views_count_max_fields",
		min:"voyce_series_views_count_min_fields",
		stddev:"voyce_series_views_count_stddev_fields",
		stddev_pop:"voyce_series_views_count_stddev_pop_fields",
		stddev_samp:"voyce_series_views_count_stddev_samp_fields",
		sum:"voyce_series_views_count_sum_fields",
		var_pop:"voyce_series_views_count_var_pop_fields",
		var_samp:"voyce_series_views_count_var_samp_fields",
		variance:"voyce_series_views_count_variance_fields"
	},
	voyce_series_views_count_avg_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat:{
		actual_count:"bigint",
		count:"float8",
		series_id:"Int"
	},
	voyce_series_views_count_mat_aggregate:{
		aggregate:"voyce_series_views_count_mat_aggregate_fields",
		nodes:"voyce_series_views_count_mat"
	},
	voyce_series_views_count_mat_aggregate_fields:{
		avg:"voyce_series_views_count_mat_avg_fields",
		count:"Int",
		max:"voyce_series_views_count_mat_max_fields",
		min:"voyce_series_views_count_mat_min_fields",
		stddev:"voyce_series_views_count_mat_stddev_fields",
		stddev_pop:"voyce_series_views_count_mat_stddev_pop_fields",
		stddev_samp:"voyce_series_views_count_mat_stddev_samp_fields",
		sum:"voyce_series_views_count_mat_sum_fields",
		var_pop:"voyce_series_views_count_mat_var_pop_fields",
		var_samp:"voyce_series_views_count_mat_var_samp_fields",
		variance:"voyce_series_views_count_mat_variance_fields"
	},
	voyce_series_views_count_mat_avg_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_max_fields:{
		actual_count:"bigint",
		count:"float8",
		series_id:"Int"
	},
	voyce_series_views_count_mat_min_fields:{
		actual_count:"bigint",
		count:"float8",
		series_id:"Int"
	},
	voyce_series_views_count_mat_sep30:{
		actual_count:"bigint",
		count:"float8",
		series_id:"Int"
	},
	voyce_series_views_count_mat_sep30_aggregate:{
		aggregate:"voyce_series_views_count_mat_sep30_aggregate_fields",
		nodes:"voyce_series_views_count_mat_sep30"
	},
	voyce_series_views_count_mat_sep30_aggregate_fields:{
		avg:"voyce_series_views_count_mat_sep30_avg_fields",
		count:"Int",
		max:"voyce_series_views_count_mat_sep30_max_fields",
		min:"voyce_series_views_count_mat_sep30_min_fields",
		stddev:"voyce_series_views_count_mat_sep30_stddev_fields",
		stddev_pop:"voyce_series_views_count_mat_sep30_stddev_pop_fields",
		stddev_samp:"voyce_series_views_count_mat_sep30_stddev_samp_fields",
		sum:"voyce_series_views_count_mat_sep30_sum_fields",
		var_pop:"voyce_series_views_count_mat_sep30_var_pop_fields",
		var_samp:"voyce_series_views_count_mat_sep30_var_samp_fields",
		variance:"voyce_series_views_count_mat_sep30_variance_fields"
	},
	voyce_series_views_count_mat_sep30_avg_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_sep30_max_fields:{
		actual_count:"bigint",
		count:"float8",
		series_id:"Int"
	},
	voyce_series_views_count_mat_sep30_min_fields:{
		actual_count:"bigint",
		count:"float8",
		series_id:"Int"
	},
	voyce_series_views_count_mat_sep30_stddev_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_sep30_stddev_pop_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_sep30_stddev_samp_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_sep30_sum_fields:{
		actual_count:"bigint",
		count:"float8",
		series_id:"Int"
	},
	voyce_series_views_count_mat_sep30_var_pop_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_sep30_var_samp_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_sep30_variance_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_stddev_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_stddev_pop_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_stddev_samp_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_sum_fields:{
		actual_count:"bigint",
		count:"float8",
		series_id:"Int"
	},
	voyce_series_views_count_mat_v2:{
		actual_count:"bigint",
		count:"float8",
		series_id:"Int"
	},
	voyce_series_views_count_mat_v2_aggregate:{
		aggregate:"voyce_series_views_count_mat_v2_aggregate_fields",
		nodes:"voyce_series_views_count_mat_v2"
	},
	voyce_series_views_count_mat_v2_aggregate_fields:{
		avg:"voyce_series_views_count_mat_v2_avg_fields",
		count:"Int",
		max:"voyce_series_views_count_mat_v2_max_fields",
		min:"voyce_series_views_count_mat_v2_min_fields",
		stddev:"voyce_series_views_count_mat_v2_stddev_fields",
		stddev_pop:"voyce_series_views_count_mat_v2_stddev_pop_fields",
		stddev_samp:"voyce_series_views_count_mat_v2_stddev_samp_fields",
		sum:"voyce_series_views_count_mat_v2_sum_fields",
		var_pop:"voyce_series_views_count_mat_v2_var_pop_fields",
		var_samp:"voyce_series_views_count_mat_v2_var_samp_fields",
		variance:"voyce_series_views_count_mat_v2_variance_fields"
	},
	voyce_series_views_count_mat_v2_avg_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_v2_max_fields:{
		actual_count:"bigint",
		count:"float8",
		series_id:"Int"
	},
	voyce_series_views_count_mat_v2_min_fields:{
		actual_count:"bigint",
		count:"float8",
		series_id:"Int"
	},
	voyce_series_views_count_mat_v2_stddev_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_v2_stddev_pop_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_v2_stddev_samp_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_v2_sum_fields:{
		actual_count:"bigint",
		count:"float8",
		series_id:"Int"
	},
	voyce_series_views_count_mat_v2_var_pop_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_v2_var_samp_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_v2_variance_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_var_pop_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_var_samp_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_mat_variance_fields:{
		actual_count:"Float",
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_max_fields:{
		count:"bigint",
		series_id:"Int"
	},
	voyce_series_views_count_min_fields:{
		count:"bigint",
		series_id:"Int"
	},
	voyce_series_views_count_stddev_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_stddev_pop_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_stddev_samp_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_sum_fields:{
		count:"bigint",
		series_id:"Int"
	},
	voyce_series_views_count_var_pop_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_var_samp_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_series_views_count_variance_fields:{
		count:"Float",
		series_id:"Float"
	},
	voyce_series_votes:{
		created_at:"timestamp",
		date:"date",
		gtag_id:"String",
		id:"Int",
		series_id:"Int",
		tag:"String",
		user_id:"Int"
	},
	voyce_series_votes_aggregate:{
		aggregate:"voyce_series_votes_aggregate_fields",
		nodes:"voyce_series_votes"
	},
	voyce_series_votes_aggregate_fields:{
		avg:"voyce_series_votes_avg_fields",
		count:"Int",
		max:"voyce_series_votes_max_fields",
		min:"voyce_series_votes_min_fields",
		stddev:"voyce_series_votes_stddev_fields",
		stddev_pop:"voyce_series_votes_stddev_pop_fields",
		stddev_samp:"voyce_series_votes_stddev_samp_fields",
		sum:"voyce_series_votes_sum_fields",
		var_pop:"voyce_series_votes_var_pop_fields",
		var_samp:"voyce_series_votes_var_samp_fields",
		variance:"voyce_series_votes_variance_fields"
	},
	voyce_series_votes_avg_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_votes_count:{
		count:"Int",
		created_at:"timestamptz",
		date:"date",
		id:"Int",
		series_id:"Int",
		tag:"String",
		total:"Int",
		updated_at:"timestamptz"
	},
	voyce_series_votes_count_aggregate:{
		aggregate:"voyce_series_votes_count_aggregate_fields",
		nodes:"voyce_series_votes_count"
	},
	voyce_series_votes_count_aggregate_fields:{
		avg:"voyce_series_votes_count_avg_fields",
		count:"Int",
		max:"voyce_series_votes_count_max_fields",
		min:"voyce_series_votes_count_min_fields",
		stddev:"voyce_series_votes_count_stddev_fields",
		stddev_pop:"voyce_series_votes_count_stddev_pop_fields",
		stddev_samp:"voyce_series_votes_count_stddev_samp_fields",
		sum:"voyce_series_votes_count_sum_fields",
		var_pop:"voyce_series_votes_count_var_pop_fields",
		var_samp:"voyce_series_votes_count_var_samp_fields",
		variance:"voyce_series_votes_count_variance_fields"
	},
	voyce_series_votes_count_avg_fields:{
		count:"Float",
		id:"Float",
		series_id:"Float",
		total:"Float"
	},
	voyce_series_votes_count_max_fields:{
		count:"Int",
		created_at:"timestamptz",
		date:"date",
		id:"Int",
		series_id:"Int",
		tag:"String",
		total:"Int",
		updated_at:"timestamptz"
	},
	voyce_series_votes_count_min_fields:{
		count:"Int",
		created_at:"timestamptz",
		date:"date",
		id:"Int",
		series_id:"Int",
		tag:"String",
		total:"Int",
		updated_at:"timestamptz"
	},
	voyce_series_votes_count_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_votes_count"
	},
	voyce_series_votes_count_stddev_fields:{
		count:"Float",
		id:"Float",
		series_id:"Float",
		total:"Float"
	},
	voyce_series_votes_count_stddev_pop_fields:{
		count:"Float",
		id:"Float",
		series_id:"Float",
		total:"Float"
	},
	voyce_series_votes_count_stddev_samp_fields:{
		count:"Float",
		id:"Float",
		series_id:"Float",
		total:"Float"
	},
	voyce_series_votes_count_sum_fields:{
		count:"Int",
		id:"Int",
		series_id:"Int",
		total:"Int"
	},
	voyce_series_votes_count_var_pop_fields:{
		count:"Float",
		id:"Float",
		series_id:"Float",
		total:"Float"
	},
	voyce_series_votes_count_var_samp_fields:{
		count:"Float",
		id:"Float",
		series_id:"Float",
		total:"Float"
	},
	voyce_series_votes_count_variance_fields:{
		count:"Float",
		id:"Float",
		series_id:"Float",
		total:"Float"
	},
	voyce_series_votes_max_fields:{
		created_at:"timestamp",
		date:"date",
		gtag_id:"String",
		id:"Int",
		series_id:"Int",
		tag:"String",
		user_id:"Int"
	},
	voyce_series_votes_min_fields:{
		created_at:"timestamp",
		date:"date",
		gtag_id:"String",
		id:"Int",
		series_id:"Int",
		tag:"String",
		user_id:"Int"
	},
	voyce_series_votes_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_votes"
	},
	voyce_series_votes_stddev_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_votes_stddev_pop_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_votes_stddev_samp_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_votes_sum_fields:{
		id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_series_votes_var_pop_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_votes_var_samp_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_votes_variance_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_series_voting_schedules:{
		created_at:"timestamptz",
		desc:"String",
		end_date:"timestamptz",
		id:"Int",
		start_date:"timestamptz",
		tag:"String",
		updated_at:"timestamptz"
	},
	voyce_series_voting_schedules_aggregate:{
		aggregate:"voyce_series_voting_schedules_aggregate_fields",
		nodes:"voyce_series_voting_schedules"
	},
	voyce_series_voting_schedules_aggregate_fields:{
		avg:"voyce_series_voting_schedules_avg_fields",
		count:"Int",
		max:"voyce_series_voting_schedules_max_fields",
		min:"voyce_series_voting_schedules_min_fields",
		stddev:"voyce_series_voting_schedules_stddev_fields",
		stddev_pop:"voyce_series_voting_schedules_stddev_pop_fields",
		stddev_samp:"voyce_series_voting_schedules_stddev_samp_fields",
		sum:"voyce_series_voting_schedules_sum_fields",
		var_pop:"voyce_series_voting_schedules_var_pop_fields",
		var_samp:"voyce_series_voting_schedules_var_samp_fields",
		variance:"voyce_series_voting_schedules_variance_fields"
	},
	voyce_series_voting_schedules_avg_fields:{
		id:"Float"
	},
	voyce_series_voting_schedules_max_fields:{
		created_at:"timestamptz",
		desc:"String",
		end_date:"timestamptz",
		id:"Int",
		start_date:"timestamptz",
		tag:"String",
		updated_at:"timestamptz"
	},
	voyce_series_voting_schedules_min_fields:{
		created_at:"timestamptz",
		desc:"String",
		end_date:"timestamptz",
		id:"Int",
		start_date:"timestamptz",
		tag:"String",
		updated_at:"timestamptz"
	},
	voyce_series_voting_schedules_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_series_voting_schedules"
	},
	voyce_series_voting_schedules_stddev_fields:{
		id:"Float"
	},
	voyce_series_voting_schedules_stddev_pop_fields:{
		id:"Float"
	},
	voyce_series_voting_schedules_stddev_samp_fields:{
		id:"Float"
	},
	voyce_series_voting_schedules_sum_fields:{
		id:"Int"
	},
	voyce_series_voting_schedules_var_pop_fields:{
		id:"Float"
	},
	voyce_series_voting_schedules_var_samp_fields:{
		id:"Float"
	},
	voyce_series_voting_schedules_variance_fields:{
		id:"Float"
	},
	voyce_sponsorships:{
		amount:"Int",
		created_at:"timestamptz",
		id:"Int",
		message:"String",
		series:"voyce_series",
		series_id:"Int",
		sponsored_user:"voyce_users",
		sponsored_user_id:"Int",
		sponsoring_user:"voyce_users",
		sponsoring_user_id:"Int",
		stripe_subscription:"String",
		updated_at:"timestamptz"
	},
	voyce_sponsorships_aggregate:{
		aggregate:"voyce_sponsorships_aggregate_fields",
		nodes:"voyce_sponsorships"
	},
	voyce_sponsorships_aggregate_fields:{
		avg:"voyce_sponsorships_avg_fields",
		count:"Int",
		max:"voyce_sponsorships_max_fields",
		min:"voyce_sponsorships_min_fields",
		stddev:"voyce_sponsorships_stddev_fields",
		stddev_pop:"voyce_sponsorships_stddev_pop_fields",
		stddev_samp:"voyce_sponsorships_stddev_samp_fields",
		sum:"voyce_sponsorships_sum_fields",
		var_pop:"voyce_sponsorships_var_pop_fields",
		var_samp:"voyce_sponsorships_var_samp_fields",
		variance:"voyce_sponsorships_variance_fields"
	},
	voyce_sponsorships_avg_fields:{
		amount:"Float",
		id:"Float",
		series_id:"Float",
		sponsored_user_id:"Float",
		sponsoring_user_id:"Float"
	},
	voyce_sponsorships_max_fields:{
		amount:"Int",
		created_at:"timestamptz",
		id:"Int",
		message:"String",
		series_id:"Int",
		sponsored_user_id:"Int",
		sponsoring_user_id:"Int",
		stripe_subscription:"String",
		updated_at:"timestamptz"
	},
	voyce_sponsorships_min_fields:{
		amount:"Int",
		created_at:"timestamptz",
		id:"Int",
		message:"String",
		series_id:"Int",
		sponsored_user_id:"Int",
		sponsoring_user_id:"Int",
		stripe_subscription:"String",
		updated_at:"timestamptz"
	},
	voyce_sponsorships_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_sponsorships"
	},
	voyce_sponsorships_stddev_fields:{
		amount:"Float",
		id:"Float",
		series_id:"Float",
		sponsored_user_id:"Float",
		sponsoring_user_id:"Float"
	},
	voyce_sponsorships_stddev_pop_fields:{
		amount:"Float",
		id:"Float",
		series_id:"Float",
		sponsored_user_id:"Float",
		sponsoring_user_id:"Float"
	},
	voyce_sponsorships_stddev_samp_fields:{
		amount:"Float",
		id:"Float",
		series_id:"Float",
		sponsored_user_id:"Float",
		sponsoring_user_id:"Float"
	},
	voyce_sponsorships_sum_fields:{
		amount:"Int",
		id:"Int",
		series_id:"Int",
		sponsored_user_id:"Int",
		sponsoring_user_id:"Int"
	},
	voyce_sponsorships_var_pop_fields:{
		amount:"Float",
		id:"Float",
		series_id:"Float",
		sponsored_user_id:"Float",
		sponsoring_user_id:"Float"
	},
	voyce_sponsorships_var_samp_fields:{
		amount:"Float",
		id:"Float",
		series_id:"Float",
		sponsored_user_id:"Float",
		sponsoring_user_id:"Float"
	},
	voyce_sponsorships_variance_fields:{
		amount:"Float",
		id:"Float",
		series_id:"Float",
		sponsored_user_id:"Float",
		sponsoring_user_id:"Float"
	},
	voyce_states:{
		country_id:"Int",
		date_create:"timestamp",
		description:"String",
		feature:"smallint",
		id:"Int",
		name:"String",
		publish:"smallint"
	},
	voyce_states_aggregate:{
		aggregate:"voyce_states_aggregate_fields",
		nodes:"voyce_states"
	},
	voyce_states_aggregate_fields:{
		avg:"voyce_states_avg_fields",
		count:"Int",
		max:"voyce_states_max_fields",
		min:"voyce_states_min_fields",
		stddev:"voyce_states_stddev_fields",
		stddev_pop:"voyce_states_stddev_pop_fields",
		stddev_samp:"voyce_states_stddev_samp_fields",
		sum:"voyce_states_sum_fields",
		var_pop:"voyce_states_var_pop_fields",
		var_samp:"voyce_states_var_samp_fields",
		variance:"voyce_states_variance_fields"
	},
	voyce_states_avg_fields:{
		country_id:"Float",
		feature:"Float",
		id:"Float",
		publish:"Float"
	},
	voyce_states_max_fields:{
		country_id:"Int",
		date_create:"timestamp",
		description:"String",
		feature:"smallint",
		id:"Int",
		name:"String",
		publish:"smallint"
	},
	voyce_states_min_fields:{
		country_id:"Int",
		date_create:"timestamp",
		description:"String",
		feature:"smallint",
		id:"Int",
		name:"String",
		publish:"smallint"
	},
	voyce_states_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_states"
	},
	voyce_states_stddev_fields:{
		country_id:"Float",
		feature:"Float",
		id:"Float",
		publish:"Float"
	},
	voyce_states_stddev_pop_fields:{
		country_id:"Float",
		feature:"Float",
		id:"Float",
		publish:"Float"
	},
	voyce_states_stddev_samp_fields:{
		country_id:"Float",
		feature:"Float",
		id:"Float",
		publish:"Float"
	},
	voyce_states_sum_fields:{
		country_id:"Int",
		feature:"smallint",
		id:"Int",
		publish:"smallint"
	},
	voyce_states_var_pop_fields:{
		country_id:"Float",
		feature:"Float",
		id:"Float",
		publish:"Float"
	},
	voyce_states_var_samp_fields:{
		country_id:"Float",
		feature:"Float",
		id:"Float",
		publish:"Float"
	},
	voyce_states_variance_fields:{
		country_id:"Float",
		feature:"Float",
		id:"Float",
		publish:"Float"
	},
	voyce_stripe_payouts_setup:{
		completed_stripe_onboarding:"Boolean",
		cut:"Int",
		id:"Int",
		stripe_account_id:"String",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_stripe_payouts_setup_aggregate:{
		aggregate:"voyce_stripe_payouts_setup_aggregate_fields",
		nodes:"voyce_stripe_payouts_setup"
	},
	voyce_stripe_payouts_setup_aggregate_fields:{
		avg:"voyce_stripe_payouts_setup_avg_fields",
		count:"Int",
		max:"voyce_stripe_payouts_setup_max_fields",
		min:"voyce_stripe_payouts_setup_min_fields",
		stddev:"voyce_stripe_payouts_setup_stddev_fields",
		stddev_pop:"voyce_stripe_payouts_setup_stddev_pop_fields",
		stddev_samp:"voyce_stripe_payouts_setup_stddev_samp_fields",
		sum:"voyce_stripe_payouts_setup_sum_fields",
		var_pop:"voyce_stripe_payouts_setup_var_pop_fields",
		var_samp:"voyce_stripe_payouts_setup_var_samp_fields",
		variance:"voyce_stripe_payouts_setup_variance_fields"
	},
	voyce_stripe_payouts_setup_avg_fields:{
		cut:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_stripe_payouts_setup_max_fields:{
		cut:"Int",
		id:"Int",
		stripe_account_id:"String",
		user_id:"Int"
	},
	voyce_stripe_payouts_setup_min_fields:{
		cut:"Int",
		id:"Int",
		stripe_account_id:"String",
		user_id:"Int"
	},
	voyce_stripe_payouts_setup_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_stripe_payouts_setup"
	},
	voyce_stripe_payouts_setup_stddev_fields:{
		cut:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_stripe_payouts_setup_stddev_pop_fields:{
		cut:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_stripe_payouts_setup_stddev_samp_fields:{
		cut:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_stripe_payouts_setup_sum_fields:{
		cut:"Int",
		id:"Int",
		user_id:"Int"
	},
	voyce_stripe_payouts_setup_var_pop_fields:{
		cut:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_stripe_payouts_setup_var_samp_fields:{
		cut:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_stripe_payouts_setup_variance_fields:{
		cut:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_studio:{
		active:"Boolean",
		avatar:"String",
		created_at:"timestamptz",
		description:"String",
		discord:"String",
		facebook:"String",
		followers:"voyce_studio_followers",
		followers_aggregate:"voyce_studio_followers_aggregate",
		id:"Int",
		instagram:"String",
		layout:"smallint",
		logo:"String",
		order:"Int",
		props:"json",
		series:"voyce_studio_series",
		series_aggregate:"voyce_studio_series_aggregate",
		slug:"String",
		tiktok:"String",
		title:"String",
		twitter:"String",
		updated_at:"timestamptz",
		views:"voyce_studio_views",
		website:"String"
	},
	voyce_studio_aggregate:{
		aggregate:"voyce_studio_aggregate_fields",
		nodes:"voyce_studio"
	},
	voyce_studio_aggregate_fields:{
		avg:"voyce_studio_avg_fields",
		count:"Int",
		max:"voyce_studio_max_fields",
		min:"voyce_studio_min_fields",
		stddev:"voyce_studio_stddev_fields",
		stddev_pop:"voyce_studio_stddev_pop_fields",
		stddev_samp:"voyce_studio_stddev_samp_fields",
		sum:"voyce_studio_sum_fields",
		var_pop:"voyce_studio_var_pop_fields",
		var_samp:"voyce_studio_var_samp_fields",
		variance:"voyce_studio_variance_fields"
	},
	voyce_studio_avg_fields:{
		id:"Float",
		layout:"Float",
		order:"Float"
	},
	voyce_studio_followers:{
		created_at:"timestamptz",
		id:"Int",
		studio_id:"Int",
		user_id:"Int"
	},
	voyce_studio_followers_aggregate:{
		aggregate:"voyce_studio_followers_aggregate_fields",
		nodes:"voyce_studio_followers"
	},
	voyce_studio_followers_aggregate_fields:{
		avg:"voyce_studio_followers_avg_fields",
		count:"Int",
		max:"voyce_studio_followers_max_fields",
		min:"voyce_studio_followers_min_fields",
		stddev:"voyce_studio_followers_stddev_fields",
		stddev_pop:"voyce_studio_followers_stddev_pop_fields",
		stddev_samp:"voyce_studio_followers_stddev_samp_fields",
		sum:"voyce_studio_followers_sum_fields",
		var_pop:"voyce_studio_followers_var_pop_fields",
		var_samp:"voyce_studio_followers_var_samp_fields",
		variance:"voyce_studio_followers_variance_fields"
	},
	voyce_studio_followers_avg_fields:{
		id:"Float",
		studio_id:"Float",
		user_id:"Float"
	},
	voyce_studio_followers_max_fields:{
		created_at:"timestamptz",
		id:"Int",
		studio_id:"Int",
		user_id:"Int"
	},
	voyce_studio_followers_min_fields:{
		created_at:"timestamptz",
		id:"Int",
		studio_id:"Int",
		user_id:"Int"
	},
	voyce_studio_followers_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_studio_followers"
	},
	voyce_studio_followers_stddev_fields:{
		id:"Float",
		studio_id:"Float",
		user_id:"Float"
	},
	voyce_studio_followers_stddev_pop_fields:{
		id:"Float",
		studio_id:"Float",
		user_id:"Float"
	},
	voyce_studio_followers_stddev_samp_fields:{
		id:"Float",
		studio_id:"Float",
		user_id:"Float"
	},
	voyce_studio_followers_sum_fields:{
		id:"Int",
		studio_id:"Int",
		user_id:"Int"
	},
	voyce_studio_followers_var_pop_fields:{
		id:"Float",
		studio_id:"Float",
		user_id:"Float"
	},
	voyce_studio_followers_var_samp_fields:{
		id:"Float",
		studio_id:"Float",
		user_id:"Float"
	},
	voyce_studio_followers_variance_fields:{
		id:"Float",
		studio_id:"Float",
		user_id:"Float"
	},
	voyce_studio_max_fields:{
		avatar:"String",
		created_at:"timestamptz",
		description:"String",
		discord:"String",
		facebook:"String",
		id:"Int",
		instagram:"String",
		layout:"smallint",
		logo:"String",
		order:"Int",
		slug:"String",
		tiktok:"String",
		title:"String",
		twitter:"String",
		updated_at:"timestamptz",
		website:"String"
	},
	voyce_studio_min_fields:{
		avatar:"String",
		created_at:"timestamptz",
		description:"String",
		discord:"String",
		facebook:"String",
		id:"Int",
		instagram:"String",
		layout:"smallint",
		logo:"String",
		order:"Int",
		slug:"String",
		tiktok:"String",
		title:"String",
		twitter:"String",
		updated_at:"timestamptz",
		website:"String"
	},
	voyce_studio_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_studio"
	},
	voyce_studio_series:{
		id:"Int",
		series:"voyce_series",
		series_id:"Int",
		series_views:"voyce_pg_rs_series_views_count",
		studio_id:"Int"
	},
	voyce_studio_series_aggregate:{
		aggregate:"voyce_studio_series_aggregate_fields",
		nodes:"voyce_studio_series"
	},
	voyce_studio_series_aggregate_fields:{
		avg:"voyce_studio_series_avg_fields",
		count:"Int",
		max:"voyce_studio_series_max_fields",
		min:"voyce_studio_series_min_fields",
		stddev:"voyce_studio_series_stddev_fields",
		stddev_pop:"voyce_studio_series_stddev_pop_fields",
		stddev_samp:"voyce_studio_series_stddev_samp_fields",
		sum:"voyce_studio_series_sum_fields",
		var_pop:"voyce_studio_series_var_pop_fields",
		var_samp:"voyce_studio_series_var_samp_fields",
		variance:"voyce_studio_series_variance_fields"
	},
	voyce_studio_series_avg_fields:{
		id:"Float",
		series_id:"Float",
		studio_id:"Float"
	},
	voyce_studio_series_max_fields:{
		id:"Int",
		series_id:"Int",
		studio_id:"Int"
	},
	voyce_studio_series_min_fields:{
		id:"Int",
		series_id:"Int",
		studio_id:"Int"
	},
	voyce_studio_series_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_studio_series"
	},
	voyce_studio_series_stddev_fields:{
		id:"Float",
		series_id:"Float",
		studio_id:"Float"
	},
	voyce_studio_series_stddev_pop_fields:{
		id:"Float",
		series_id:"Float",
		studio_id:"Float"
	},
	voyce_studio_series_stddev_samp_fields:{
		id:"Float",
		series_id:"Float",
		studio_id:"Float"
	},
	voyce_studio_series_sum_fields:{
		id:"Int",
		series_id:"Int",
		studio_id:"Int"
	},
	voyce_studio_series_var_pop_fields:{
		id:"Float",
		series_id:"Float",
		studio_id:"Float"
	},
	voyce_studio_series_var_samp_fields:{
		id:"Float",
		series_id:"Float",
		studio_id:"Float"
	},
	voyce_studio_series_variance_fields:{
		id:"Float",
		series_id:"Float",
		studio_id:"Float"
	},
	voyce_studio_stddev_fields:{
		id:"Float",
		layout:"Float",
		order:"Float"
	},
	voyce_studio_stddev_pop_fields:{
		id:"Float",
		layout:"Float",
		order:"Float"
	},
	voyce_studio_stddev_samp_fields:{
		id:"Float",
		layout:"Float",
		order:"Float"
	},
	voyce_studio_sum_fields:{
		id:"Int",
		layout:"smallint",
		order:"Int"
	},
	voyce_studio_var_pop_fields:{
		id:"Float",
		layout:"Float",
		order:"Float"
	},
	voyce_studio_var_samp_fields:{
		id:"Float",
		layout:"Float",
		order:"Float"
	},
	voyce_studio_variance_fields:{
		id:"Float",
		layout:"Float",
		order:"Float"
	},
	voyce_studio_views:{
		count:"Int",
		id:"Int",
		studio_id:"Int"
	},
	voyce_studio_views_aggregate:{
		aggregate:"voyce_studio_views_aggregate_fields",
		nodes:"voyce_studio_views"
	},
	voyce_studio_views_aggregate_fields:{
		avg:"voyce_studio_views_avg_fields",
		count:"Int",
		max:"voyce_studio_views_max_fields",
		min:"voyce_studio_views_min_fields",
		stddev:"voyce_studio_views_stddev_fields",
		stddev_pop:"voyce_studio_views_stddev_pop_fields",
		stddev_samp:"voyce_studio_views_stddev_samp_fields",
		sum:"voyce_studio_views_sum_fields",
		var_pop:"voyce_studio_views_var_pop_fields",
		var_samp:"voyce_studio_views_var_samp_fields",
		variance:"voyce_studio_views_variance_fields"
	},
	voyce_studio_views_avg_fields:{
		count:"Float",
		id:"Float",
		studio_id:"Float"
	},
	voyce_studio_views_max_fields:{
		count:"Int",
		id:"Int",
		studio_id:"Int"
	},
	voyce_studio_views_min_fields:{
		count:"Int",
		id:"Int",
		studio_id:"Int"
	},
	voyce_studio_views_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_studio_views"
	},
	voyce_studio_views_stddev_fields:{
		count:"Float",
		id:"Float",
		studio_id:"Float"
	},
	voyce_studio_views_stddev_pop_fields:{
		count:"Float",
		id:"Float",
		studio_id:"Float"
	},
	voyce_studio_views_stddev_samp_fields:{
		count:"Float",
		id:"Float",
		studio_id:"Float"
	},
	voyce_studio_views_sum_fields:{
		count:"Int",
		id:"Int",
		studio_id:"Int"
	},
	voyce_studio_views_var_pop_fields:{
		count:"Float",
		id:"Float",
		studio_id:"Float"
	},
	voyce_studio_views_var_samp_fields:{
		count:"Float",
		id:"Float",
		studio_id:"Float"
	},
	voyce_studio_views_variance_fields:{
		count:"Float",
		id:"Float",
		studio_id:"Float"
	},
	voyce_system_settings:{
		created_at:"timestamp",
		id:"Int",
		key:"String",
		updated_at:"timestamp",
		value:"String"
	},
	voyce_system_settings_aggregate:{
		aggregate:"voyce_system_settings_aggregate_fields",
		nodes:"voyce_system_settings"
	},
	voyce_system_settings_aggregate_fields:{
		avg:"voyce_system_settings_avg_fields",
		count:"Int",
		max:"voyce_system_settings_max_fields",
		min:"voyce_system_settings_min_fields",
		stddev:"voyce_system_settings_stddev_fields",
		stddev_pop:"voyce_system_settings_stddev_pop_fields",
		stddev_samp:"voyce_system_settings_stddev_samp_fields",
		sum:"voyce_system_settings_sum_fields",
		var_pop:"voyce_system_settings_var_pop_fields",
		var_samp:"voyce_system_settings_var_samp_fields",
		variance:"voyce_system_settings_variance_fields"
	},
	voyce_system_settings_avg_fields:{
		id:"Float"
	},
	voyce_system_settings_max_fields:{
		created_at:"timestamp",
		id:"Int",
		key:"String",
		updated_at:"timestamp",
		value:"String"
	},
	voyce_system_settings_min_fields:{
		created_at:"timestamp",
		id:"Int",
		key:"String",
		updated_at:"timestamp",
		value:"String"
	},
	voyce_system_settings_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_system_settings"
	},
	voyce_system_settings_stddev_fields:{
		id:"Float"
	},
	voyce_system_settings_stddev_pop_fields:{
		id:"Float"
	},
	voyce_system_settings_stddev_samp_fields:{
		id:"Float"
	},
	voyce_system_settings_sum_fields:{
		id:"Int"
	},
	voyce_system_settings_var_pop_fields:{
		id:"Float"
	},
	voyce_system_settings_var_samp_fields:{
		id:"Float"
	},
	voyce_system_settings_variance_fields:{
		id:"Float"
	},
	voyce_top_genres:{
		count:"bigint",
		genre:"voyce_genres",
		genre_id:"Int",
		series_genres:"voyce_series_genre",
		series_genres_aggregate:"voyce_series_genre_aggregate"
	},
	voyce_top_genres_aggregate:{
		aggregate:"voyce_top_genres_aggregate_fields",
		nodes:"voyce_top_genres"
	},
	voyce_top_genres_aggregate_fields:{
		avg:"voyce_top_genres_avg_fields",
		count:"Int",
		max:"voyce_top_genres_max_fields",
		min:"voyce_top_genres_min_fields",
		stddev:"voyce_top_genres_stddev_fields",
		stddev_pop:"voyce_top_genres_stddev_pop_fields",
		stddev_samp:"voyce_top_genres_stddev_samp_fields",
		sum:"voyce_top_genres_sum_fields",
		var_pop:"voyce_top_genres_var_pop_fields",
		var_samp:"voyce_top_genres_var_samp_fields",
		variance:"voyce_top_genres_variance_fields"
	},
	voyce_top_genres_avg_fields:{
		count:"Float",
		genre_id:"Float"
	},
	voyce_top_genres_max_fields:{
		count:"bigint",
		genre_id:"Int"
	},
	voyce_top_genres_min_fields:{
		count:"bigint",
		genre_id:"Int"
	},
	voyce_top_genres_stddev_fields:{
		count:"Float",
		genre_id:"Float"
	},
	voyce_top_genres_stddev_pop_fields:{
		count:"Float",
		genre_id:"Float"
	},
	voyce_top_genres_stddev_samp_fields:{
		count:"Float",
		genre_id:"Float"
	},
	voyce_top_genres_sum_fields:{
		count:"bigint",
		genre_id:"Int"
	},
	voyce_top_genres_var_pop_fields:{
		count:"Float",
		genre_id:"Float"
	},
	voyce_top_genres_var_samp_fields:{
		count:"Float",
		genre_id:"Float"
	},
	voyce_top_genres_variance_fields:{
		count:"Float",
		genre_id:"Float"
	},
	voyce_transaction_logs:{
		amount:"Int",
		coins_type:"String",
		created_at:"timestamp",
		id:"Int",
		item_id:"Int",
		product_id:"Int",
		receipt_id:"Int",
		receiver_id:"Int",
		sender_id:"Int",
		title:"String",
		type:"String",
		userByReceiverId:"voyce_users",
		userBySenderId:"voyce_users"
	},
	voyce_transaction_logs_aggregate:{
		aggregate:"voyce_transaction_logs_aggregate_fields",
		nodes:"voyce_transaction_logs"
	},
	voyce_transaction_logs_aggregate_fields:{
		avg:"voyce_transaction_logs_avg_fields",
		count:"Int",
		max:"voyce_transaction_logs_max_fields",
		min:"voyce_transaction_logs_min_fields",
		stddev:"voyce_transaction_logs_stddev_fields",
		stddev_pop:"voyce_transaction_logs_stddev_pop_fields",
		stddev_samp:"voyce_transaction_logs_stddev_samp_fields",
		sum:"voyce_transaction_logs_sum_fields",
		var_pop:"voyce_transaction_logs_var_pop_fields",
		var_samp:"voyce_transaction_logs_var_samp_fields",
		variance:"voyce_transaction_logs_variance_fields"
	},
	voyce_transaction_logs_avg_fields:{
		amount:"Float",
		id:"Float",
		item_id:"Float",
		product_id:"Float",
		receipt_id:"Float",
		receiver_id:"Float",
		sender_id:"Float"
	},
	voyce_transaction_logs_max_fields:{
		amount:"Int",
		coins_type:"String",
		created_at:"timestamp",
		id:"Int",
		item_id:"Int",
		product_id:"Int",
		receipt_id:"Int",
		receiver_id:"Int",
		sender_id:"Int",
		title:"String",
		type:"String"
	},
	voyce_transaction_logs_min_fields:{
		amount:"Int",
		coins_type:"String",
		created_at:"timestamp",
		id:"Int",
		item_id:"Int",
		product_id:"Int",
		receipt_id:"Int",
		receiver_id:"Int",
		sender_id:"Int",
		title:"String",
		type:"String"
	},
	voyce_transaction_logs_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_transaction_logs"
	},
	voyce_transaction_logs_stddev_fields:{
		amount:"Float",
		id:"Float",
		item_id:"Float",
		product_id:"Float",
		receipt_id:"Float",
		receiver_id:"Float",
		sender_id:"Float"
	},
	voyce_transaction_logs_stddev_pop_fields:{
		amount:"Float",
		id:"Float",
		item_id:"Float",
		product_id:"Float",
		receipt_id:"Float",
		receiver_id:"Float",
		sender_id:"Float"
	},
	voyce_transaction_logs_stddev_samp_fields:{
		amount:"Float",
		id:"Float",
		item_id:"Float",
		product_id:"Float",
		receipt_id:"Float",
		receiver_id:"Float",
		sender_id:"Float"
	},
	voyce_transaction_logs_sum_fields:{
		amount:"Int",
		id:"Int",
		item_id:"Int",
		product_id:"Int",
		receipt_id:"Int",
		receiver_id:"Int",
		sender_id:"Int"
	},
	voyce_transaction_logs_var_pop_fields:{
		amount:"Float",
		id:"Float",
		item_id:"Float",
		product_id:"Float",
		receipt_id:"Float",
		receiver_id:"Float",
		sender_id:"Float"
	},
	voyce_transaction_logs_var_samp_fields:{
		amount:"Float",
		id:"Float",
		item_id:"Float",
		product_id:"Float",
		receipt_id:"Float",
		receiver_id:"Float",
		sender_id:"Float"
	},
	voyce_transaction_logs_variance_fields:{
		amount:"Float",
		id:"Float",
		item_id:"Float",
		product_id:"Float",
		receipt_id:"Float",
		receiver_id:"Float",
		sender_id:"Float"
	},
	voyce_url_redirects:{
		id:"Int",
		path:"String",
		redirect_url:"String",
		views:"Int"
	},
	voyce_url_redirects_aggregate:{
		aggregate:"voyce_url_redirects_aggregate_fields",
		nodes:"voyce_url_redirects"
	},
	voyce_url_redirects_aggregate_fields:{
		avg:"voyce_url_redirects_avg_fields",
		count:"Int",
		max:"voyce_url_redirects_max_fields",
		min:"voyce_url_redirects_min_fields",
		stddev:"voyce_url_redirects_stddev_fields",
		stddev_pop:"voyce_url_redirects_stddev_pop_fields",
		stddev_samp:"voyce_url_redirects_stddev_samp_fields",
		sum:"voyce_url_redirects_sum_fields",
		var_pop:"voyce_url_redirects_var_pop_fields",
		var_samp:"voyce_url_redirects_var_samp_fields",
		variance:"voyce_url_redirects_variance_fields"
	},
	voyce_url_redirects_avg_fields:{
		id:"Float",
		views:"Float"
	},
	voyce_url_redirects_max_fields:{
		id:"Int",
		path:"String",
		redirect_url:"String",
		views:"Int"
	},
	voyce_url_redirects_min_fields:{
		id:"Int",
		path:"String",
		redirect_url:"String",
		views:"Int"
	},
	voyce_url_redirects_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_url_redirects"
	},
	voyce_url_redirects_stddev_fields:{
		id:"Float",
		views:"Float"
	},
	voyce_url_redirects_stddev_pop_fields:{
		id:"Float",
		views:"Float"
	},
	voyce_url_redirects_stddev_samp_fields:{
		id:"Float",
		views:"Float"
	},
	voyce_url_redirects_sum_fields:{
		id:"Int",
		views:"Int"
	},
	voyce_url_redirects_var_pop_fields:{
		id:"Float",
		views:"Float"
	},
	voyce_url_redirects_var_samp_fields:{
		id:"Float",
		views:"Float"
	},
	voyce_url_redirects_variance_fields:{
		id:"Float",
		views:"Float"
	},
	voyce_user_addresses:{
		address:"String",
		city_id:"Int",
		country_id:"Int",
		date_create:"timestamp",
		id:"Int",
		is_billing_address:"smallint",
		is_shipping_address:"smallint",
		state_id:"Int",
		user_id:"Int",
		zip_code:"String"
	},
	voyce_user_addresses_aggregate:{
		aggregate:"voyce_user_addresses_aggregate_fields",
		nodes:"voyce_user_addresses"
	},
	voyce_user_addresses_aggregate_fields:{
		avg:"voyce_user_addresses_avg_fields",
		count:"Int",
		max:"voyce_user_addresses_max_fields",
		min:"voyce_user_addresses_min_fields",
		stddev:"voyce_user_addresses_stddev_fields",
		stddev_pop:"voyce_user_addresses_stddev_pop_fields",
		stddev_samp:"voyce_user_addresses_stddev_samp_fields",
		sum:"voyce_user_addresses_sum_fields",
		var_pop:"voyce_user_addresses_var_pop_fields",
		var_samp:"voyce_user_addresses_var_samp_fields",
		variance:"voyce_user_addresses_variance_fields"
	},
	voyce_user_addresses_avg_fields:{
		city_id:"Float",
		country_id:"Float",
		id:"Float",
		is_billing_address:"Float",
		is_shipping_address:"Float",
		state_id:"Float",
		user_id:"Float"
	},
	voyce_user_addresses_max_fields:{
		address:"String",
		city_id:"Int",
		country_id:"Int",
		date_create:"timestamp",
		id:"Int",
		is_billing_address:"smallint",
		is_shipping_address:"smallint",
		state_id:"Int",
		user_id:"Int",
		zip_code:"String"
	},
	voyce_user_addresses_min_fields:{
		address:"String",
		city_id:"Int",
		country_id:"Int",
		date_create:"timestamp",
		id:"Int",
		is_billing_address:"smallint",
		is_shipping_address:"smallint",
		state_id:"Int",
		user_id:"Int",
		zip_code:"String"
	},
	voyce_user_addresses_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_addresses"
	},
	voyce_user_addresses_stddev_fields:{
		city_id:"Float",
		country_id:"Float",
		id:"Float",
		is_billing_address:"Float",
		is_shipping_address:"Float",
		state_id:"Float",
		user_id:"Float"
	},
	voyce_user_addresses_stddev_pop_fields:{
		city_id:"Float",
		country_id:"Float",
		id:"Float",
		is_billing_address:"Float",
		is_shipping_address:"Float",
		state_id:"Float",
		user_id:"Float"
	},
	voyce_user_addresses_stddev_samp_fields:{
		city_id:"Float",
		country_id:"Float",
		id:"Float",
		is_billing_address:"Float",
		is_shipping_address:"Float",
		state_id:"Float",
		user_id:"Float"
	},
	voyce_user_addresses_sum_fields:{
		city_id:"Int",
		country_id:"Int",
		id:"Int",
		is_billing_address:"smallint",
		is_shipping_address:"smallint",
		state_id:"Int",
		user_id:"Int"
	},
	voyce_user_addresses_var_pop_fields:{
		city_id:"Float",
		country_id:"Float",
		id:"Float",
		is_billing_address:"Float",
		is_shipping_address:"Float",
		state_id:"Float",
		user_id:"Float"
	},
	voyce_user_addresses_var_samp_fields:{
		city_id:"Float",
		country_id:"Float",
		id:"Float",
		is_billing_address:"Float",
		is_shipping_address:"Float",
		state_id:"Float",
		user_id:"Float"
	},
	voyce_user_addresses_variance_fields:{
		city_id:"Float",
		country_id:"Float",
		id:"Float",
		is_billing_address:"Float",
		is_shipping_address:"Float",
		state_id:"Float",
		user_id:"Float"
	},
	voyce_user_blocks:{
		created_at:"timestamptz",
		extras:"String",
		id:"Int",
		linked_id:"Int",
		reason:"String",
		type:"String",
		user_id:"Int"
	},
	voyce_user_blocks_aggregate:{
		aggregate:"voyce_user_blocks_aggregate_fields",
		nodes:"voyce_user_blocks"
	},
	voyce_user_blocks_aggregate_fields:{
		avg:"voyce_user_blocks_avg_fields",
		count:"Int",
		max:"voyce_user_blocks_max_fields",
		min:"voyce_user_blocks_min_fields",
		stddev:"voyce_user_blocks_stddev_fields",
		stddev_pop:"voyce_user_blocks_stddev_pop_fields",
		stddev_samp:"voyce_user_blocks_stddev_samp_fields",
		sum:"voyce_user_blocks_sum_fields",
		var_pop:"voyce_user_blocks_var_pop_fields",
		var_samp:"voyce_user_blocks_var_samp_fields",
		variance:"voyce_user_blocks_variance_fields"
	},
	voyce_user_blocks_avg_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_user_blocks_max_fields:{
		created_at:"timestamptz",
		extras:"String",
		id:"Int",
		linked_id:"Int",
		reason:"String",
		type:"String",
		user_id:"Int"
	},
	voyce_user_blocks_min_fields:{
		created_at:"timestamptz",
		extras:"String",
		id:"Int",
		linked_id:"Int",
		reason:"String",
		type:"String",
		user_id:"Int"
	},
	voyce_user_blocks_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_blocks"
	},
	voyce_user_blocks_stddev_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_user_blocks_stddev_pop_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_user_blocks_stddev_samp_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_user_blocks_sum_fields:{
		id:"Int",
		linked_id:"Int",
		user_id:"Int"
	},
	voyce_user_blocks_var_pop_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_user_blocks_var_samp_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_user_blocks_variance_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_user_chapters_unlocked:{
		chapter_id:"Int",
		created_at:"timestamptz",
		end_date:"date",
		id:"Int",
		is_expired:"Boolean",
		series:"voyce_series",
		series_id:"Int",
		start_date:"date",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_user_chapters_unlocked_aggregate:{
		aggregate:"voyce_user_chapters_unlocked_aggregate_fields",
		nodes:"voyce_user_chapters_unlocked"
	},
	voyce_user_chapters_unlocked_aggregate_fields:{
		avg:"voyce_user_chapters_unlocked_avg_fields",
		count:"Int",
		max:"voyce_user_chapters_unlocked_max_fields",
		min:"voyce_user_chapters_unlocked_min_fields",
		stddev:"voyce_user_chapters_unlocked_stddev_fields",
		stddev_pop:"voyce_user_chapters_unlocked_stddev_pop_fields",
		stddev_samp:"voyce_user_chapters_unlocked_stddev_samp_fields",
		sum:"voyce_user_chapters_unlocked_sum_fields",
		var_pop:"voyce_user_chapters_unlocked_var_pop_fields",
		var_samp:"voyce_user_chapters_unlocked_var_samp_fields",
		variance:"voyce_user_chapters_unlocked_variance_fields"
	},
	voyce_user_chapters_unlocked_avg_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_user_chapters_unlocked_max_fields:{
		chapter_id:"Int",
		created_at:"timestamptz",
		end_date:"date",
		id:"Int",
		series_id:"Int",
		start_date:"date",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_user_chapters_unlocked_min_fields:{
		chapter_id:"Int",
		created_at:"timestamptz",
		end_date:"date",
		id:"Int",
		series_id:"Int",
		start_date:"date",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_user_chapters_unlocked_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_chapters_unlocked"
	},
	voyce_user_chapters_unlocked_stddev_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_user_chapters_unlocked_stddev_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_user_chapters_unlocked_stddev_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_user_chapters_unlocked_sum_fields:{
		chapter_id:"Int",
		id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_user_chapters_unlocked_var_pop_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_user_chapters_unlocked_var_samp_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_user_chapters_unlocked_variance_fields:{
		chapter_id:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_user_contacts:{
		contact:"String",
		country_code:"String",
		date_create:"timestamp",
		id:"Int",
		is_primary:"smallint",
		user_id:"Int"
	},
	voyce_user_contacts_aggregate:{
		aggregate:"voyce_user_contacts_aggregate_fields",
		nodes:"voyce_user_contacts"
	},
	voyce_user_contacts_aggregate_fields:{
		avg:"voyce_user_contacts_avg_fields",
		count:"Int",
		max:"voyce_user_contacts_max_fields",
		min:"voyce_user_contacts_min_fields",
		stddev:"voyce_user_contacts_stddev_fields",
		stddev_pop:"voyce_user_contacts_stddev_pop_fields",
		stddev_samp:"voyce_user_contacts_stddev_samp_fields",
		sum:"voyce_user_contacts_sum_fields",
		var_pop:"voyce_user_contacts_var_pop_fields",
		var_samp:"voyce_user_contacts_var_samp_fields",
		variance:"voyce_user_contacts_variance_fields"
	},
	voyce_user_contacts_avg_fields:{
		id:"Float",
		is_primary:"Float",
		user_id:"Float"
	},
	voyce_user_contacts_max_fields:{
		contact:"String",
		country_code:"String",
		date_create:"timestamp",
		id:"Int",
		is_primary:"smallint",
		user_id:"Int"
	},
	voyce_user_contacts_min_fields:{
		contact:"String",
		country_code:"String",
		date_create:"timestamp",
		id:"Int",
		is_primary:"smallint",
		user_id:"Int"
	},
	voyce_user_contacts_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_contacts"
	},
	voyce_user_contacts_stddev_fields:{
		id:"Float",
		is_primary:"Float",
		user_id:"Float"
	},
	voyce_user_contacts_stddev_pop_fields:{
		id:"Float",
		is_primary:"Float",
		user_id:"Float"
	},
	voyce_user_contacts_stddev_samp_fields:{
		id:"Float",
		is_primary:"Float",
		user_id:"Float"
	},
	voyce_user_contacts_sum_fields:{
		id:"Int",
		is_primary:"smallint",
		user_id:"Int"
	},
	voyce_user_contacts_var_pop_fields:{
		id:"Float",
		is_primary:"Float",
		user_id:"Float"
	},
	voyce_user_contacts_var_samp_fields:{
		id:"Float",
		is_primary:"Float",
		user_id:"Float"
	},
	voyce_user_contacts_variance_fields:{
		id:"Float",
		is_primary:"Float",
		user_id:"Float"
	},
	voyce_user_csat_ratings:{
		comment:"String",
		created_at:"timestamptz",
		gtag_id:"String",
		id:"Int",
		is_answered:"Boolean",
		more_info:"String",
		page_uri:"String",
		rating:"String",
		tag:"String",
		user_id:"Int"
	},
	voyce_user_csat_ratings_aggregate:{
		aggregate:"voyce_user_csat_ratings_aggregate_fields",
		nodes:"voyce_user_csat_ratings"
	},
	voyce_user_csat_ratings_aggregate_fields:{
		avg:"voyce_user_csat_ratings_avg_fields",
		count:"Int",
		max:"voyce_user_csat_ratings_max_fields",
		min:"voyce_user_csat_ratings_min_fields",
		stddev:"voyce_user_csat_ratings_stddev_fields",
		stddev_pop:"voyce_user_csat_ratings_stddev_pop_fields",
		stddev_samp:"voyce_user_csat_ratings_stddev_samp_fields",
		sum:"voyce_user_csat_ratings_sum_fields",
		var_pop:"voyce_user_csat_ratings_var_pop_fields",
		var_samp:"voyce_user_csat_ratings_var_samp_fields",
		variance:"voyce_user_csat_ratings_variance_fields"
	},
	voyce_user_csat_ratings_avg_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_csat_ratings_max_fields:{
		comment:"String",
		created_at:"timestamptz",
		gtag_id:"String",
		id:"Int",
		more_info:"String",
		page_uri:"String",
		rating:"String",
		tag:"String",
		user_id:"Int"
	},
	voyce_user_csat_ratings_min_fields:{
		comment:"String",
		created_at:"timestamptz",
		gtag_id:"String",
		id:"Int",
		more_info:"String",
		page_uri:"String",
		rating:"String",
		tag:"String",
		user_id:"Int"
	},
	voyce_user_csat_ratings_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_csat_ratings"
	},
	voyce_user_csat_ratings_stddev_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_csat_ratings_stddev_pop_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_csat_ratings_stddev_samp_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_csat_ratings_sum_fields:{
		id:"Int",
		user_id:"Int"
	},
	voyce_user_csat_ratings_var_pop_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_csat_ratings_var_samp_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_csat_ratings_variance_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_education:{
		date_create:"timestamp",
		education_type_id:"Int",
		id:"Int",
		in_progress:"smallint",
		institute:"String",
		passing_date:"date",
		title:"String",
		user_id:"Int"
	},
	voyce_user_education_aggregate:{
		aggregate:"voyce_user_education_aggregate_fields",
		nodes:"voyce_user_education"
	},
	voyce_user_education_aggregate_fields:{
		avg:"voyce_user_education_avg_fields",
		count:"Int",
		max:"voyce_user_education_max_fields",
		min:"voyce_user_education_min_fields",
		stddev:"voyce_user_education_stddev_fields",
		stddev_pop:"voyce_user_education_stddev_pop_fields",
		stddev_samp:"voyce_user_education_stddev_samp_fields",
		sum:"voyce_user_education_sum_fields",
		var_pop:"voyce_user_education_var_pop_fields",
		var_samp:"voyce_user_education_var_samp_fields",
		variance:"voyce_user_education_variance_fields"
	},
	voyce_user_education_avg_fields:{
		education_type_id:"Float",
		id:"Float",
		in_progress:"Float",
		user_id:"Float"
	},
	voyce_user_education_max_fields:{
		date_create:"timestamp",
		education_type_id:"Int",
		id:"Int",
		in_progress:"smallint",
		institute:"String",
		passing_date:"date",
		title:"String",
		user_id:"Int"
	},
	voyce_user_education_min_fields:{
		date_create:"timestamp",
		education_type_id:"Int",
		id:"Int",
		in_progress:"smallint",
		institute:"String",
		passing_date:"date",
		title:"String",
		user_id:"Int"
	},
	voyce_user_education_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_education"
	},
	voyce_user_education_stddev_fields:{
		education_type_id:"Float",
		id:"Float",
		in_progress:"Float",
		user_id:"Float"
	},
	voyce_user_education_stddev_pop_fields:{
		education_type_id:"Float",
		id:"Float",
		in_progress:"Float",
		user_id:"Float"
	},
	voyce_user_education_stddev_samp_fields:{
		education_type_id:"Float",
		id:"Float",
		in_progress:"Float",
		user_id:"Float"
	},
	voyce_user_education_sum_fields:{
		education_type_id:"Int",
		id:"Int",
		in_progress:"smallint",
		user_id:"Int"
	},
	voyce_user_education_var_pop_fields:{
		education_type_id:"Float",
		id:"Float",
		in_progress:"Float",
		user_id:"Float"
	},
	voyce_user_education_var_samp_fields:{
		education_type_id:"Float",
		id:"Float",
		in_progress:"Float",
		user_id:"Float"
	},
	voyce_user_education_variance_fields:{
		education_type_id:"Float",
		id:"Float",
		in_progress:"Float",
		user_id:"Float"
	},
	voyce_user_level:{
		created_at:"timestamptz",
		experience:"Int",
		id:"Int",
		level:"Int",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_user_level_aggregate:{
		aggregate:"voyce_user_level_aggregate_fields",
		nodes:"voyce_user_level"
	},
	voyce_user_level_aggregate_fields:{
		avg:"voyce_user_level_avg_fields",
		count:"Int",
		max:"voyce_user_level_max_fields",
		min:"voyce_user_level_min_fields",
		stddev:"voyce_user_level_stddev_fields",
		stddev_pop:"voyce_user_level_stddev_pop_fields",
		stddev_samp:"voyce_user_level_stddev_samp_fields",
		sum:"voyce_user_level_sum_fields",
		var_pop:"voyce_user_level_var_pop_fields",
		var_samp:"voyce_user_level_var_samp_fields",
		variance:"voyce_user_level_variance_fields"
	},
	voyce_user_level_avg_fields:{
		experience:"Float",
		id:"Float",
		level:"Float",
		user_id:"Float"
	},
	voyce_user_level_max_fields:{
		created_at:"timestamptz",
		experience:"Int",
		id:"Int",
		level:"Int",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_user_level_min_fields:{
		created_at:"timestamptz",
		experience:"Int",
		id:"Int",
		level:"Int",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_user_level_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_level"
	},
	voyce_user_level_stddev_fields:{
		experience:"Float",
		id:"Float",
		level:"Float",
		user_id:"Float"
	},
	voyce_user_level_stddev_pop_fields:{
		experience:"Float",
		id:"Float",
		level:"Float",
		user_id:"Float"
	},
	voyce_user_level_stddev_samp_fields:{
		experience:"Float",
		id:"Float",
		level:"Float",
		user_id:"Float"
	},
	voyce_user_level_sum_fields:{
		experience:"Int",
		id:"Int",
		level:"Int",
		user_id:"Int"
	},
	voyce_user_level_var_pop_fields:{
		experience:"Float",
		id:"Float",
		level:"Float",
		user_id:"Float"
	},
	voyce_user_level_var_samp_fields:{
		experience:"Float",
		id:"Float",
		level:"Float",
		user_id:"Float"
	},
	voyce_user_level_variance_fields:{
		experience:"Float",
		id:"Float",
		level:"Float",
		user_id:"Float"
	},
	voyce_user_linked_accounts:{
		created_at:"timestamptz",
		hash:"String",
		id:"Int",
		identity:"String",
		main_account:"Boolean",
		provider:"String",
		sub:"String",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_user_linked_accounts_aggregate:{
		aggregate:"voyce_user_linked_accounts_aggregate_fields",
		nodes:"voyce_user_linked_accounts"
	},
	voyce_user_linked_accounts_aggregate_fields:{
		avg:"voyce_user_linked_accounts_avg_fields",
		count:"Int",
		max:"voyce_user_linked_accounts_max_fields",
		min:"voyce_user_linked_accounts_min_fields",
		stddev:"voyce_user_linked_accounts_stddev_fields",
		stddev_pop:"voyce_user_linked_accounts_stddev_pop_fields",
		stddev_samp:"voyce_user_linked_accounts_stddev_samp_fields",
		sum:"voyce_user_linked_accounts_sum_fields",
		var_pop:"voyce_user_linked_accounts_var_pop_fields",
		var_samp:"voyce_user_linked_accounts_var_samp_fields",
		variance:"voyce_user_linked_accounts_variance_fields"
	},
	voyce_user_linked_accounts_avg_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_linked_accounts_max_fields:{
		created_at:"timestamptz",
		hash:"String",
		id:"Int",
		identity:"String",
		provider:"String",
		sub:"String",
		user_id:"Int"
	},
	voyce_user_linked_accounts_min_fields:{
		created_at:"timestamptz",
		hash:"String",
		id:"Int",
		identity:"String",
		provider:"String",
		sub:"String",
		user_id:"Int"
	},
	voyce_user_linked_accounts_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_linked_accounts"
	},
	voyce_user_linked_accounts_stddev_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_linked_accounts_stddev_pop_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_linked_accounts_stddev_samp_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_linked_accounts_sum_fields:{
		id:"Int",
		user_id:"Int"
	},
	voyce_user_linked_accounts_var_pop_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_linked_accounts_var_samp_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_linked_accounts_variance_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_login_history:{
		browser:"String",
		city:"String",
		country:"String",
		id:"Int",
		ip:"String",
		is_login:"smallint",
		latitude:"String",
		login_time:"timestamp",
		logout_time:"timestamp",
		longitude:"String",
		os:"String",
		region:"String",
		token:"String",
		user_agent:"String",
		user_id:"Int"
	},
	voyce_user_login_history_aggregate:{
		aggregate:"voyce_user_login_history_aggregate_fields",
		nodes:"voyce_user_login_history"
	},
	voyce_user_login_history_aggregate_fields:{
		avg:"voyce_user_login_history_avg_fields",
		count:"Int",
		max:"voyce_user_login_history_max_fields",
		min:"voyce_user_login_history_min_fields",
		stddev:"voyce_user_login_history_stddev_fields",
		stddev_pop:"voyce_user_login_history_stddev_pop_fields",
		stddev_samp:"voyce_user_login_history_stddev_samp_fields",
		sum:"voyce_user_login_history_sum_fields",
		var_pop:"voyce_user_login_history_var_pop_fields",
		var_samp:"voyce_user_login_history_var_samp_fields",
		variance:"voyce_user_login_history_variance_fields"
	},
	voyce_user_login_history_avg_fields:{
		id:"Float",
		is_login:"Float",
		user_id:"Float"
	},
	voyce_user_login_history_max_fields:{
		browser:"String",
		city:"String",
		country:"String",
		id:"Int",
		ip:"String",
		is_login:"smallint",
		latitude:"String",
		login_time:"timestamp",
		logout_time:"timestamp",
		longitude:"String",
		os:"String",
		region:"String",
		token:"String",
		user_agent:"String",
		user_id:"Int"
	},
	voyce_user_login_history_min_fields:{
		browser:"String",
		city:"String",
		country:"String",
		id:"Int",
		ip:"String",
		is_login:"smallint",
		latitude:"String",
		login_time:"timestamp",
		logout_time:"timestamp",
		longitude:"String",
		os:"String",
		region:"String",
		token:"String",
		user_agent:"String",
		user_id:"Int"
	},
	voyce_user_login_history_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_login_history"
	},
	voyce_user_login_history_stddev_fields:{
		id:"Float",
		is_login:"Float",
		user_id:"Float"
	},
	voyce_user_login_history_stddev_pop_fields:{
		id:"Float",
		is_login:"Float",
		user_id:"Float"
	},
	voyce_user_login_history_stddev_samp_fields:{
		id:"Float",
		is_login:"Float",
		user_id:"Float"
	},
	voyce_user_login_history_sum_fields:{
		id:"Int",
		is_login:"smallint",
		user_id:"Int"
	},
	voyce_user_login_history_var_pop_fields:{
		id:"Float",
		is_login:"Float",
		user_id:"Float"
	},
	voyce_user_login_history_var_samp_fields:{
		id:"Float",
		is_login:"Float",
		user_id:"Float"
	},
	voyce_user_login_history_variance_fields:{
		id:"Float",
		is_login:"Float",
		user_id:"Float"
	},
	voyce_user_quest_ids:{
		created_at:"timestamptz",
		id:"Int",
		quest:"voyce_user_quests",
		tag:"String",
		type:"String",
		type_id:"Int",
		user_id:"Int",
		user_quest_id:"Int"
	},
	voyce_user_quest_ids_aggregate:{
		aggregate:"voyce_user_quest_ids_aggregate_fields",
		nodes:"voyce_user_quest_ids"
	},
	voyce_user_quest_ids_aggregate_fields:{
		avg:"voyce_user_quest_ids_avg_fields",
		count:"Int",
		max:"voyce_user_quest_ids_max_fields",
		min:"voyce_user_quest_ids_min_fields",
		stddev:"voyce_user_quest_ids_stddev_fields",
		stddev_pop:"voyce_user_quest_ids_stddev_pop_fields",
		stddev_samp:"voyce_user_quest_ids_stddev_samp_fields",
		sum:"voyce_user_quest_ids_sum_fields",
		var_pop:"voyce_user_quest_ids_var_pop_fields",
		var_samp:"voyce_user_quest_ids_var_samp_fields",
		variance:"voyce_user_quest_ids_variance_fields"
	},
	voyce_user_quest_ids_avg_fields:{
		id:"Float",
		type_id:"Float",
		user_id:"Float",
		user_quest_id:"Float"
	},
	voyce_user_quest_ids_max_fields:{
		created_at:"timestamptz",
		id:"Int",
		tag:"String",
		type:"String",
		type_id:"Int",
		user_id:"Int",
		user_quest_id:"Int"
	},
	voyce_user_quest_ids_min_fields:{
		created_at:"timestamptz",
		id:"Int",
		tag:"String",
		type:"String",
		type_id:"Int",
		user_id:"Int",
		user_quest_id:"Int"
	},
	voyce_user_quest_ids_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_quest_ids"
	},
	voyce_user_quest_ids_stddev_fields:{
		id:"Float",
		type_id:"Float",
		user_id:"Float",
		user_quest_id:"Float"
	},
	voyce_user_quest_ids_stddev_pop_fields:{
		id:"Float",
		type_id:"Float",
		user_id:"Float",
		user_quest_id:"Float"
	},
	voyce_user_quest_ids_stddev_samp_fields:{
		id:"Float",
		type_id:"Float",
		user_id:"Float",
		user_quest_id:"Float"
	},
	voyce_user_quest_ids_sum_fields:{
		id:"Int",
		type_id:"Int",
		user_id:"Int",
		user_quest_id:"Int"
	},
	voyce_user_quest_ids_var_pop_fields:{
		id:"Float",
		type_id:"Float",
		user_id:"Float",
		user_quest_id:"Float"
	},
	voyce_user_quest_ids_var_samp_fields:{
		id:"Float",
		type_id:"Float",
		user_id:"Float",
		user_quest_id:"Float"
	},
	voyce_user_quest_ids_variance_fields:{
		id:"Float",
		type_id:"Float",
		user_id:"Float",
		user_quest_id:"Float"
	},
	voyce_user_quest_rewards:{
		created_at:"timestamptz",
		description:"String",
		id:"Int",
		metadata:"String",
		type:"String",
		user_id:"Int",
		user_quest_id:"Int",
		value:"Int"
	},
	voyce_user_quest_rewards_aggregate:{
		aggregate:"voyce_user_quest_rewards_aggregate_fields",
		nodes:"voyce_user_quest_rewards"
	},
	voyce_user_quest_rewards_aggregate_fields:{
		avg:"voyce_user_quest_rewards_avg_fields",
		count:"Int",
		max:"voyce_user_quest_rewards_max_fields",
		min:"voyce_user_quest_rewards_min_fields",
		stddev:"voyce_user_quest_rewards_stddev_fields",
		stddev_pop:"voyce_user_quest_rewards_stddev_pop_fields",
		stddev_samp:"voyce_user_quest_rewards_stddev_samp_fields",
		sum:"voyce_user_quest_rewards_sum_fields",
		var_pop:"voyce_user_quest_rewards_var_pop_fields",
		var_samp:"voyce_user_quest_rewards_var_samp_fields",
		variance:"voyce_user_quest_rewards_variance_fields"
	},
	voyce_user_quest_rewards_avg_fields:{
		id:"Float",
		user_id:"Float",
		user_quest_id:"Float",
		value:"Float"
	},
	voyce_user_quest_rewards_max_fields:{
		created_at:"timestamptz",
		description:"String",
		id:"Int",
		metadata:"String",
		type:"String",
		user_id:"Int",
		user_quest_id:"Int",
		value:"Int"
	},
	voyce_user_quest_rewards_min_fields:{
		created_at:"timestamptz",
		description:"String",
		id:"Int",
		metadata:"String",
		type:"String",
		user_id:"Int",
		user_quest_id:"Int",
		value:"Int"
	},
	voyce_user_quest_rewards_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_quest_rewards"
	},
	voyce_user_quest_rewards_stddev_fields:{
		id:"Float",
		user_id:"Float",
		user_quest_id:"Float",
		value:"Float"
	},
	voyce_user_quest_rewards_stddev_pop_fields:{
		id:"Float",
		user_id:"Float",
		user_quest_id:"Float",
		value:"Float"
	},
	voyce_user_quest_rewards_stddev_samp_fields:{
		id:"Float",
		user_id:"Float",
		user_quest_id:"Float",
		value:"Float"
	},
	voyce_user_quest_rewards_sum_fields:{
		id:"Int",
		user_id:"Int",
		user_quest_id:"Int",
		value:"Int"
	},
	voyce_user_quest_rewards_var_pop_fields:{
		id:"Float",
		user_id:"Float",
		user_quest_id:"Float",
		value:"Float"
	},
	voyce_user_quest_rewards_var_samp_fields:{
		id:"Float",
		user_id:"Float",
		user_quest_id:"Float",
		value:"Float"
	},
	voyce_user_quest_rewards_variance_fields:{
		id:"Float",
		user_id:"Float",
		user_quest_id:"Float",
		value:"Float"
	},
	voyce_user_quests:{
		completed_at:"timestamptz",
		created_at:"timestamptz",
		data:"json",
		frequency_tag:"String",
		id:"Int",
		notified:"Boolean",
		quest:"voyce_quests",
		quest_id:"Int",
		rewarded_at:"timestamptz",
		rewards_data:"json",
		status:"String",
		updated_at:"timestamptz",
		user_id:"Int",
		vtag:"voyce_user_vtags"
	},
	voyce_user_quests_aggregate:{
		aggregate:"voyce_user_quests_aggregate_fields",
		nodes:"voyce_user_quests"
	},
	voyce_user_quests_aggregate_fields:{
		avg:"voyce_user_quests_avg_fields",
		count:"Int",
		max:"voyce_user_quests_max_fields",
		min:"voyce_user_quests_min_fields",
		stddev:"voyce_user_quests_stddev_fields",
		stddev_pop:"voyce_user_quests_stddev_pop_fields",
		stddev_samp:"voyce_user_quests_stddev_samp_fields",
		sum:"voyce_user_quests_sum_fields",
		var_pop:"voyce_user_quests_var_pop_fields",
		var_samp:"voyce_user_quests_var_samp_fields",
		variance:"voyce_user_quests_variance_fields"
	},
	voyce_user_quests_avg_fields:{
		id:"Float",
		quest_id:"Float",
		user_id:"Float"
	},
	voyce_user_quests_max_fields:{
		completed_at:"timestamptz",
		created_at:"timestamptz",
		frequency_tag:"String",
		id:"Int",
		quest_id:"Int",
		rewarded_at:"timestamptz",
		status:"String",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_user_quests_min_fields:{
		completed_at:"timestamptz",
		created_at:"timestamptz",
		frequency_tag:"String",
		id:"Int",
		quest_id:"Int",
		rewarded_at:"timestamptz",
		status:"String",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_user_quests_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_quests"
	},
	voyce_user_quests_old:{
		created_at:"timestamp",
		id:"Int",
		progress:"numeric",
		quest:"voyce_quests_old",
		quest_id:"Int",
		updated_at:"timestamp",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_user_quests_old_aggregate:{
		aggregate:"voyce_user_quests_old_aggregate_fields",
		nodes:"voyce_user_quests_old"
	},
	voyce_user_quests_old_aggregate_fields:{
		avg:"voyce_user_quests_old_avg_fields",
		count:"Int",
		max:"voyce_user_quests_old_max_fields",
		min:"voyce_user_quests_old_min_fields",
		stddev:"voyce_user_quests_old_stddev_fields",
		stddev_pop:"voyce_user_quests_old_stddev_pop_fields",
		stddev_samp:"voyce_user_quests_old_stddev_samp_fields",
		sum:"voyce_user_quests_old_sum_fields",
		var_pop:"voyce_user_quests_old_var_pop_fields",
		var_samp:"voyce_user_quests_old_var_samp_fields",
		variance:"voyce_user_quests_old_variance_fields"
	},
	voyce_user_quests_old_avg_fields:{
		id:"Float",
		progress:"Float",
		quest_id:"Float",
		user_id:"Float"
	},
	voyce_user_quests_old_max_fields:{
		created_at:"timestamp",
		id:"Int",
		progress:"numeric",
		quest_id:"Int",
		updated_at:"timestamp",
		user_id:"Int"
	},
	voyce_user_quests_old_min_fields:{
		created_at:"timestamp",
		id:"Int",
		progress:"numeric",
		quest_id:"Int",
		updated_at:"timestamp",
		user_id:"Int"
	},
	voyce_user_quests_old_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_quests_old"
	},
	voyce_user_quests_old_stddev_fields:{
		id:"Float",
		progress:"Float",
		quest_id:"Float",
		user_id:"Float"
	},
	voyce_user_quests_old_stddev_pop_fields:{
		id:"Float",
		progress:"Float",
		quest_id:"Float",
		user_id:"Float"
	},
	voyce_user_quests_old_stddev_samp_fields:{
		id:"Float",
		progress:"Float",
		quest_id:"Float",
		user_id:"Float"
	},
	voyce_user_quests_old_sum_fields:{
		id:"Int",
		progress:"numeric",
		quest_id:"Int",
		user_id:"Int"
	},
	voyce_user_quests_old_var_pop_fields:{
		id:"Float",
		progress:"Float",
		quest_id:"Float",
		user_id:"Float"
	},
	voyce_user_quests_old_var_samp_fields:{
		id:"Float",
		progress:"Float",
		quest_id:"Float",
		user_id:"Float"
	},
	voyce_user_quests_old_variance_fields:{
		id:"Float",
		progress:"Float",
		quest_id:"Float",
		user_id:"Float"
	},
	voyce_user_quests_stddev_fields:{
		id:"Float",
		quest_id:"Float",
		user_id:"Float"
	},
	voyce_user_quests_stddev_pop_fields:{
		id:"Float",
		quest_id:"Float",
		user_id:"Float"
	},
	voyce_user_quests_stddev_samp_fields:{
		id:"Float",
		quest_id:"Float",
		user_id:"Float"
	},
	voyce_user_quests_sum_fields:{
		id:"Int",
		quest_id:"Int",
		user_id:"Int"
	},
	voyce_user_quests_var_pop_fields:{
		id:"Float",
		quest_id:"Float",
		user_id:"Float"
	},
	voyce_user_quests_var_samp_fields:{
		id:"Float",
		quest_id:"Float",
		user_id:"Float"
	},
	voyce_user_quests_variance_fields:{
		id:"Float",
		quest_id:"Float",
		user_id:"Float"
	},
	voyce_user_ranking:{
		counter:"Int",
		day:"String",
		id:"Int",
		series:"voyce_series",
		series_id:"Int",
		user:"voyce_users",
		user_id:"Int",
		xp_count:"Int"
	},
	voyce_user_ranking_aggregate:{
		aggregate:"voyce_user_ranking_aggregate_fields",
		nodes:"voyce_user_ranking"
	},
	voyce_user_ranking_aggregate_fields:{
		avg:"voyce_user_ranking_avg_fields",
		count:"Int",
		max:"voyce_user_ranking_max_fields",
		min:"voyce_user_ranking_min_fields",
		stddev:"voyce_user_ranking_stddev_fields",
		stddev_pop:"voyce_user_ranking_stddev_pop_fields",
		stddev_samp:"voyce_user_ranking_stddev_samp_fields",
		sum:"voyce_user_ranking_sum_fields",
		var_pop:"voyce_user_ranking_var_pop_fields",
		var_samp:"voyce_user_ranking_var_samp_fields",
		variance:"voyce_user_ranking_variance_fields"
	},
	voyce_user_ranking_avg_fields:{
		counter:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float",
		xp_count:"Float"
	},
	voyce_user_ranking_max_fields:{
		counter:"Int",
		day:"String",
		id:"Int",
		series_id:"Int",
		user_id:"Int",
		xp_count:"Int"
	},
	voyce_user_ranking_min_fields:{
		counter:"Int",
		day:"String",
		id:"Int",
		series_id:"Int",
		user_id:"Int",
		xp_count:"Int"
	},
	voyce_user_ranking_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_ranking"
	},
	voyce_user_ranking_stddev_fields:{
		counter:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float",
		xp_count:"Float"
	},
	voyce_user_ranking_stddev_pop_fields:{
		counter:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float",
		xp_count:"Float"
	},
	voyce_user_ranking_stddev_samp_fields:{
		counter:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float",
		xp_count:"Float"
	},
	voyce_user_ranking_sum_fields:{
		counter:"Int",
		id:"Int",
		series_id:"Int",
		user_id:"Int",
		xp_count:"Int"
	},
	voyce_user_ranking_var_pop_fields:{
		counter:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float",
		xp_count:"Float"
	},
	voyce_user_ranking_var_samp_fields:{
		counter:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float",
		xp_count:"Float"
	},
	voyce_user_ranking_variance_fields:{
		counter:"Float",
		id:"Float",
		series_id:"Float",
		user_id:"Float",
		xp_count:"Float"
	},
	voyce_user_reviews:{
		date_create:"timestamp",
		id:"Int",
		publish:"smallint",
		rating:"Int",
		review:"String",
		reviewer_id:"Int",
		user_id:"Int"
	},
	voyce_user_reviews_aggregate:{
		aggregate:"voyce_user_reviews_aggregate_fields",
		nodes:"voyce_user_reviews"
	},
	voyce_user_reviews_aggregate_fields:{
		avg:"voyce_user_reviews_avg_fields",
		count:"Int",
		max:"voyce_user_reviews_max_fields",
		min:"voyce_user_reviews_min_fields",
		stddev:"voyce_user_reviews_stddev_fields",
		stddev_pop:"voyce_user_reviews_stddev_pop_fields",
		stddev_samp:"voyce_user_reviews_stddev_samp_fields",
		sum:"voyce_user_reviews_sum_fields",
		var_pop:"voyce_user_reviews_var_pop_fields",
		var_samp:"voyce_user_reviews_var_samp_fields",
		variance:"voyce_user_reviews_variance_fields"
	},
	voyce_user_reviews_avg_fields:{
		id:"Float",
		publish:"Float",
		rating:"Float",
		reviewer_id:"Float",
		user_id:"Float"
	},
	voyce_user_reviews_max_fields:{
		date_create:"timestamp",
		id:"Int",
		publish:"smallint",
		rating:"Int",
		review:"String",
		reviewer_id:"Int",
		user_id:"Int"
	},
	voyce_user_reviews_min_fields:{
		date_create:"timestamp",
		id:"Int",
		publish:"smallint",
		rating:"Int",
		review:"String",
		reviewer_id:"Int",
		user_id:"Int"
	},
	voyce_user_reviews_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_reviews"
	},
	voyce_user_reviews_stddev_fields:{
		id:"Float",
		publish:"Float",
		rating:"Float",
		reviewer_id:"Float",
		user_id:"Float"
	},
	voyce_user_reviews_stddev_pop_fields:{
		id:"Float",
		publish:"Float",
		rating:"Float",
		reviewer_id:"Float",
		user_id:"Float"
	},
	voyce_user_reviews_stddev_samp_fields:{
		id:"Float",
		publish:"Float",
		rating:"Float",
		reviewer_id:"Float",
		user_id:"Float"
	},
	voyce_user_reviews_sum_fields:{
		id:"Int",
		publish:"smallint",
		rating:"Int",
		reviewer_id:"Int",
		user_id:"Int"
	},
	voyce_user_reviews_var_pop_fields:{
		id:"Float",
		publish:"Float",
		rating:"Float",
		reviewer_id:"Float",
		user_id:"Float"
	},
	voyce_user_reviews_var_samp_fields:{
		id:"Float",
		publish:"Float",
		rating:"Float",
		reviewer_id:"Float",
		user_id:"Float"
	},
	voyce_user_reviews_variance_fields:{
		id:"Float",
		publish:"Float",
		rating:"Float",
		reviewer_id:"Float",
		user_id:"Float"
	},
	voyce_user_rewards:{
		coin_amount:"numeric",
		coin_type:"String",
		created_at:"timestamptz",
		id:"Int",
		type:"String",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_user_rewards_aggregate:{
		aggregate:"voyce_user_rewards_aggregate_fields",
		nodes:"voyce_user_rewards"
	},
	voyce_user_rewards_aggregate_fields:{
		avg:"voyce_user_rewards_avg_fields",
		count:"Int",
		max:"voyce_user_rewards_max_fields",
		min:"voyce_user_rewards_min_fields",
		stddev:"voyce_user_rewards_stddev_fields",
		stddev_pop:"voyce_user_rewards_stddev_pop_fields",
		stddev_samp:"voyce_user_rewards_stddev_samp_fields",
		sum:"voyce_user_rewards_sum_fields",
		var_pop:"voyce_user_rewards_var_pop_fields",
		var_samp:"voyce_user_rewards_var_samp_fields",
		variance:"voyce_user_rewards_variance_fields"
	},
	voyce_user_rewards_avg_fields:{
		coin_amount:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_user_rewards_max_fields:{
		coin_amount:"numeric",
		coin_type:"String",
		created_at:"timestamptz",
		id:"Int",
		type:"String",
		user_id:"Int"
	},
	voyce_user_rewards_min_fields:{
		coin_amount:"numeric",
		coin_type:"String",
		created_at:"timestamptz",
		id:"Int",
		type:"String",
		user_id:"Int"
	},
	voyce_user_rewards_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_rewards"
	},
	voyce_user_rewards_stddev_fields:{
		coin_amount:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_user_rewards_stddev_pop_fields:{
		coin_amount:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_user_rewards_stddev_samp_fields:{
		coin_amount:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_user_rewards_sum_fields:{
		coin_amount:"numeric",
		id:"Int",
		user_id:"Int"
	},
	voyce_user_rewards_var_pop_fields:{
		coin_amount:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_user_rewards_var_samp_fields:{
		coin_amount:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_user_rewards_variance_fields:{
		coin_amount:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_user_roles:{
		date_create:"timestamp",
		id:"Int",
		title:"String"
	},
	voyce_user_roles_aggregate:{
		aggregate:"voyce_user_roles_aggregate_fields",
		nodes:"voyce_user_roles"
	},
	voyce_user_roles_aggregate_fields:{
		avg:"voyce_user_roles_avg_fields",
		count:"Int",
		max:"voyce_user_roles_max_fields",
		min:"voyce_user_roles_min_fields",
		stddev:"voyce_user_roles_stddev_fields",
		stddev_pop:"voyce_user_roles_stddev_pop_fields",
		stddev_samp:"voyce_user_roles_stddev_samp_fields",
		sum:"voyce_user_roles_sum_fields",
		var_pop:"voyce_user_roles_var_pop_fields",
		var_samp:"voyce_user_roles_var_samp_fields",
		variance:"voyce_user_roles_variance_fields"
	},
	voyce_user_roles_avg_fields:{
		id:"Float"
	},
	voyce_user_roles_max_fields:{
		date_create:"timestamp",
		id:"Int",
		title:"String"
	},
	voyce_user_roles_min_fields:{
		date_create:"timestamp",
		id:"Int",
		title:"String"
	},
	voyce_user_roles_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_roles"
	},
	voyce_user_roles_stddev_fields:{
		id:"Float"
	},
	voyce_user_roles_stddev_pop_fields:{
		id:"Float"
	},
	voyce_user_roles_stddev_samp_fields:{
		id:"Float"
	},
	voyce_user_roles_sum_fields:{
		id:"Int"
	},
	voyce_user_roles_var_pop_fields:{
		id:"Float"
	},
	voyce_user_roles_var_samp_fields:{
		id:"Float"
	},
	voyce_user_roles_variance_fields:{
		id:"Float"
	},
	voyce_user_series_subscription_count:{
		count:"bigint",
		user_id:"Int"
	},
	voyce_user_series_subscription_count_aggregate:{
		aggregate:"voyce_user_series_subscription_count_aggregate_fields",
		nodes:"voyce_user_series_subscription_count"
	},
	voyce_user_series_subscription_count_aggregate_fields:{
		avg:"voyce_user_series_subscription_count_avg_fields",
		count:"Int",
		max:"voyce_user_series_subscription_count_max_fields",
		min:"voyce_user_series_subscription_count_min_fields",
		stddev:"voyce_user_series_subscription_count_stddev_fields",
		stddev_pop:"voyce_user_series_subscription_count_stddev_pop_fields",
		stddev_samp:"voyce_user_series_subscription_count_stddev_samp_fields",
		sum:"voyce_user_series_subscription_count_sum_fields",
		var_pop:"voyce_user_series_subscription_count_var_pop_fields",
		var_samp:"voyce_user_series_subscription_count_var_samp_fields",
		variance:"voyce_user_series_subscription_count_variance_fields"
	},
	voyce_user_series_subscription_count_avg_fields:{
		count:"Float",
		user_id:"Float"
	},
	voyce_user_series_subscription_count_max_fields:{
		count:"bigint",
		user_id:"Int"
	},
	voyce_user_series_subscription_count_min_fields:{
		count:"bigint",
		user_id:"Int"
	},
	voyce_user_series_subscription_count_stddev_fields:{
		count:"Float",
		user_id:"Float"
	},
	voyce_user_series_subscription_count_stddev_pop_fields:{
		count:"Float",
		user_id:"Float"
	},
	voyce_user_series_subscription_count_stddev_samp_fields:{
		count:"Float",
		user_id:"Float"
	},
	voyce_user_series_subscription_count_sum_fields:{
		count:"bigint",
		user_id:"Int"
	},
	voyce_user_series_subscription_count_var_pop_fields:{
		count:"Float",
		user_id:"Float"
	},
	voyce_user_series_subscription_count_var_samp_fields:{
		count:"Float",
		user_id:"Float"
	},
	voyce_user_series_subscription_count_variance_fields:{
		count:"Float",
		user_id:"Float"
	},
	voyce_user_settings:{
		content_mature:"smallint",
		content_spoilers:"smallint",
		id:"Int",
		page_animation:"smallint",
		reading_comic:"String",
		reading_manga:"String",
		reading_novel:"String",
		theme:"String",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_user_settings_aggregate:{
		aggregate:"voyce_user_settings_aggregate_fields",
		nodes:"voyce_user_settings"
	},
	voyce_user_settings_aggregate_fields:{
		avg:"voyce_user_settings_avg_fields",
		count:"Int",
		max:"voyce_user_settings_max_fields",
		min:"voyce_user_settings_min_fields",
		stddev:"voyce_user_settings_stddev_fields",
		stddev_pop:"voyce_user_settings_stddev_pop_fields",
		stddev_samp:"voyce_user_settings_stddev_samp_fields",
		sum:"voyce_user_settings_sum_fields",
		var_pop:"voyce_user_settings_var_pop_fields",
		var_samp:"voyce_user_settings_var_samp_fields",
		variance:"voyce_user_settings_variance_fields"
	},
	voyce_user_settings_avg_fields:{
		content_mature:"Float",
		content_spoilers:"Float",
		id:"Float",
		page_animation:"Float",
		user_id:"Float"
	},
	voyce_user_settings_max_fields:{
		content_mature:"smallint",
		content_spoilers:"smallint",
		id:"Int",
		page_animation:"smallint",
		reading_comic:"String",
		reading_manga:"String",
		reading_novel:"String",
		theme:"String",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_user_settings_min_fields:{
		content_mature:"smallint",
		content_spoilers:"smallint",
		id:"Int",
		page_animation:"smallint",
		reading_comic:"String",
		reading_manga:"String",
		reading_novel:"String",
		theme:"String",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_user_settings_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_settings"
	},
	voyce_user_settings_stddev_fields:{
		content_mature:"Float",
		content_spoilers:"Float",
		id:"Float",
		page_animation:"Float",
		user_id:"Float"
	},
	voyce_user_settings_stddev_pop_fields:{
		content_mature:"Float",
		content_spoilers:"Float",
		id:"Float",
		page_animation:"Float",
		user_id:"Float"
	},
	voyce_user_settings_stddev_samp_fields:{
		content_mature:"Float",
		content_spoilers:"Float",
		id:"Float",
		page_animation:"Float",
		user_id:"Float"
	},
	voyce_user_settings_sum_fields:{
		content_mature:"smallint",
		content_spoilers:"smallint",
		id:"Int",
		page_animation:"smallint",
		user_id:"Int"
	},
	voyce_user_settings_var_pop_fields:{
		content_mature:"Float",
		content_spoilers:"Float",
		id:"Float",
		page_animation:"Float",
		user_id:"Float"
	},
	voyce_user_settings_var_samp_fields:{
		content_mature:"Float",
		content_spoilers:"Float",
		id:"Float",
		page_animation:"Float",
		user_id:"Float"
	},
	voyce_user_settings_variance_fields:{
		content_mature:"Float",
		content_spoilers:"Float",
		id:"Float",
		page_animation:"Float",
		user_id:"Float"
	},
	voyce_user_skills:{
		date_create:"timestamp",
		id:"Int",
		skill:"String",
		user_id:"Int"
	},
	voyce_user_skills_aggregate:{
		aggregate:"voyce_user_skills_aggregate_fields",
		nodes:"voyce_user_skills"
	},
	voyce_user_skills_aggregate_fields:{
		avg:"voyce_user_skills_avg_fields",
		count:"Int",
		max:"voyce_user_skills_max_fields",
		min:"voyce_user_skills_min_fields",
		stddev:"voyce_user_skills_stddev_fields",
		stddev_pop:"voyce_user_skills_stddev_pop_fields",
		stddev_samp:"voyce_user_skills_stddev_samp_fields",
		sum:"voyce_user_skills_sum_fields",
		var_pop:"voyce_user_skills_var_pop_fields",
		var_samp:"voyce_user_skills_var_samp_fields",
		variance:"voyce_user_skills_variance_fields"
	},
	voyce_user_skills_avg_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_skills_max_fields:{
		date_create:"timestamp",
		id:"Int",
		skill:"String",
		user_id:"Int"
	},
	voyce_user_skills_min_fields:{
		date_create:"timestamp",
		id:"Int",
		skill:"String",
		user_id:"Int"
	},
	voyce_user_skills_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_skills"
	},
	voyce_user_skills_stddev_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_skills_stddev_pop_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_skills_stddev_samp_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_skills_sum_fields:{
		id:"Int",
		user_id:"Int"
	},
	voyce_user_skills_var_pop_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_skills_var_samp_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_skills_variance_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_socials:{
		access_token:"String",
		created_at:"timestamptz",
		expired_at:"timestamptz",
		expires_in:"numeric",
		id:"Int",
		provider:"String",
		refresh_token:"String",
		scope:"String",
		token_type:"String",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_user_socials_aggregate:{
		aggregate:"voyce_user_socials_aggregate_fields",
		nodes:"voyce_user_socials"
	},
	voyce_user_socials_aggregate_fields:{
		avg:"voyce_user_socials_avg_fields",
		count:"Int",
		max:"voyce_user_socials_max_fields",
		min:"voyce_user_socials_min_fields",
		stddev:"voyce_user_socials_stddev_fields",
		stddev_pop:"voyce_user_socials_stddev_pop_fields",
		stddev_samp:"voyce_user_socials_stddev_samp_fields",
		sum:"voyce_user_socials_sum_fields",
		var_pop:"voyce_user_socials_var_pop_fields",
		var_samp:"voyce_user_socials_var_samp_fields",
		variance:"voyce_user_socials_variance_fields"
	},
	voyce_user_socials_avg_fields:{
		expires_in:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_user_socials_max_fields:{
		access_token:"String",
		created_at:"timestamptz",
		expired_at:"timestamptz",
		expires_in:"numeric",
		id:"Int",
		provider:"String",
		refresh_token:"String",
		scope:"String",
		token_type:"String",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_user_socials_min_fields:{
		access_token:"String",
		created_at:"timestamptz",
		expired_at:"timestamptz",
		expires_in:"numeric",
		id:"Int",
		provider:"String",
		refresh_token:"String",
		scope:"String",
		token_type:"String",
		updated_at:"timestamptz",
		user_id:"Int"
	},
	voyce_user_socials_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_socials"
	},
	voyce_user_socials_stddev_fields:{
		expires_in:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_user_socials_stddev_pop_fields:{
		expires_in:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_user_socials_stddev_samp_fields:{
		expires_in:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_user_socials_sum_fields:{
		expires_in:"numeric",
		id:"Int",
		user_id:"Int"
	},
	voyce_user_socials_var_pop_fields:{
		expires_in:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_user_socials_var_samp_fields:{
		expires_in:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_user_socials_variance_fields:{
		expires_in:"Float",
		id:"Float",
		user_id:"Float"
	},
	voyce_user_soft_deleted:{
		about:"String",
		avatar:"String",
		banner:"String",
		created_at:"timestamptz",
		deleted_at:"timestamptz",
		email:"String",
		first_name:"String",
		id:"Int",
		is_deleted:"Boolean",
		last_name:"String",
		nickname:"String",
		social_discord:"String",
		social_fb:"String",
		social_instagram:"String",
		social_tiktok:"String",
		social_twitter:"String",
		user:"voyce_users",
		user_id:"Int",
		username:"String"
	},
	voyce_user_soft_deleted_aggregate:{
		aggregate:"voyce_user_soft_deleted_aggregate_fields",
		nodes:"voyce_user_soft_deleted"
	},
	voyce_user_soft_deleted_aggregate_fields:{
		avg:"voyce_user_soft_deleted_avg_fields",
		count:"Int",
		max:"voyce_user_soft_deleted_max_fields",
		min:"voyce_user_soft_deleted_min_fields",
		stddev:"voyce_user_soft_deleted_stddev_fields",
		stddev_pop:"voyce_user_soft_deleted_stddev_pop_fields",
		stddev_samp:"voyce_user_soft_deleted_stddev_samp_fields",
		sum:"voyce_user_soft_deleted_sum_fields",
		var_pop:"voyce_user_soft_deleted_var_pop_fields",
		var_samp:"voyce_user_soft_deleted_var_samp_fields",
		variance:"voyce_user_soft_deleted_variance_fields"
	},
	voyce_user_soft_deleted_avg_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_soft_deleted_max_fields:{
		about:"String",
		avatar:"String",
		banner:"String",
		created_at:"timestamptz",
		deleted_at:"timestamptz",
		email:"String",
		first_name:"String",
		id:"Int",
		last_name:"String",
		nickname:"String",
		social_discord:"String",
		social_fb:"String",
		social_instagram:"String",
		social_tiktok:"String",
		social_twitter:"String",
		user_id:"Int",
		username:"String"
	},
	voyce_user_soft_deleted_min_fields:{
		about:"String",
		avatar:"String",
		banner:"String",
		created_at:"timestamptz",
		deleted_at:"timestamptz",
		email:"String",
		first_name:"String",
		id:"Int",
		last_name:"String",
		nickname:"String",
		social_discord:"String",
		social_fb:"String",
		social_instagram:"String",
		social_tiktok:"String",
		social_twitter:"String",
		user_id:"Int",
		username:"String"
	},
	voyce_user_soft_deleted_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_soft_deleted"
	},
	voyce_user_soft_deleted_stddev_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_soft_deleted_stddev_pop_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_soft_deleted_stddev_samp_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_soft_deleted_sum_fields:{
		id:"Int",
		user_id:"Int"
	},
	voyce_user_soft_deleted_var_pop_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_soft_deleted_var_samp_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_soft_deleted_variance_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_vtags:{
		id:"Int",
		user_id:"Int",
		vtag:"String",
		vtag_v2:"String"
	},
	voyce_user_vtags_aggregate:{
		aggregate:"voyce_user_vtags_aggregate_fields",
		nodes:"voyce_user_vtags"
	},
	voyce_user_vtags_aggregate_fields:{
		avg:"voyce_user_vtags_avg_fields",
		count:"Int",
		max:"voyce_user_vtags_max_fields",
		min:"voyce_user_vtags_min_fields",
		stddev:"voyce_user_vtags_stddev_fields",
		stddev_pop:"voyce_user_vtags_stddev_pop_fields",
		stddev_samp:"voyce_user_vtags_stddev_samp_fields",
		sum:"voyce_user_vtags_sum_fields",
		var_pop:"voyce_user_vtags_var_pop_fields",
		var_samp:"voyce_user_vtags_var_samp_fields",
		variance:"voyce_user_vtags_variance_fields"
	},
	voyce_user_vtags_avg_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_vtags_max_fields:{
		id:"Int",
		user_id:"Int",
		vtag:"String",
		vtag_v2:"String"
	},
	voyce_user_vtags_min_fields:{
		id:"Int",
		user_id:"Int",
		vtag:"String",
		vtag_v2:"String"
	},
	voyce_user_vtags_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_vtags"
	},
	voyce_user_vtags_stddev_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_vtags_stddev_pop_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_vtags_stddev_samp_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_vtags_sum_fields:{
		id:"Int",
		user_id:"Int"
	},
	voyce_user_vtags_var_pop_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_vtags_var_samp_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_vtags_variance_fields:{
		id:"Float",
		user_id:"Float"
	},
	voyce_user_wins:{
		claimed:"Boolean",
		created_at:"timestamp",
		id:"Int",
		linked_id:"Int",
		source:"String",
		title:"String",
		updated_at:"timestamp",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_user_wins_aggregate:{
		aggregate:"voyce_user_wins_aggregate_fields",
		nodes:"voyce_user_wins"
	},
	voyce_user_wins_aggregate_fields:{
		avg:"voyce_user_wins_avg_fields",
		count:"Int",
		max:"voyce_user_wins_max_fields",
		min:"voyce_user_wins_min_fields",
		stddev:"voyce_user_wins_stddev_fields",
		stddev_pop:"voyce_user_wins_stddev_pop_fields",
		stddev_samp:"voyce_user_wins_stddev_samp_fields",
		sum:"voyce_user_wins_sum_fields",
		var_pop:"voyce_user_wins_var_pop_fields",
		var_samp:"voyce_user_wins_var_samp_fields",
		variance:"voyce_user_wins_variance_fields"
	},
	voyce_user_wins_avg_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_user_wins_max_fields:{
		created_at:"timestamp",
		id:"Int",
		linked_id:"Int",
		source:"String",
		title:"String",
		updated_at:"timestamp",
		user_id:"Int"
	},
	voyce_user_wins_min_fields:{
		created_at:"timestamp",
		id:"Int",
		linked_id:"Int",
		source:"String",
		title:"String",
		updated_at:"timestamp",
		user_id:"Int"
	},
	voyce_user_wins_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_user_wins"
	},
	voyce_user_wins_stddev_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_user_wins_stddev_pop_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_user_wins_stddev_samp_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_user_wins_sum_fields:{
		id:"Int",
		linked_id:"Int",
		user_id:"Int"
	},
	voyce_user_wins_var_pop_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_user_wins_var_samp_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_user_wins_variance_fields:{
		id:"Float",
		linked_id:"Float",
		user_id:"Float"
	},
	voyce_users:{
		about:"String",
		account_disabled:"Boolean",
		ad_share:"Int",
		admin_role:"voyce_admin_roles",
		admin_role_id:"Int",
		author_profile:"voyce_author_profiles",
		author_profiles:"voyce_author_profiles",
		author_profiles_aggregate:"voyce_author_profiles_aggregate",
		avatar:"String",
		avatar_bgcolor:"String",
		banner:"String",
		birth_date:"date",
		chapter_comments:"voyce_chapter_comments",
		chapter_comments_aggregate:"voyce_chapter_comments_aggregate",
		chapter_comments_reactions:"voyce_chapter_comments_reaction",
		chapter_comments_reactions_aggregate:"voyce_chapter_comments_reaction_aggregate",
		cognito_id:"String",
		cognito_provider:"String",
		cognito_username:"String",
		country_id:"Int",
		creator_type_preferences:"jsonb",
		dark_mode:"smallint",
		date_create:"timestamp",
		delete_code:"String",
		email:"String",
		email_verified:"Boolean",
		email_verified_source:"String",
		fcm_token:"String",
		fcm_tokens:"jsonb",
		first_name:"String",
		following:"voyce_series_subscription",
		following_aggregate:"voyce_series_subscription_aggregate",
		frame:"String",
		gender:"String",
		guest_gtag:"String",
		hot_panels:"voyce_series_hot_panels",
		hot_panels_aggregate:"voyce_series_hot_panels_aggregate",
		id:"Int",
		is_account_active:"smallint",
		is_approved:"Boolean",
		is_onboarded:"Int",
		is_publisher_verified:"Boolean",
		klaviyo_id:"String",
		last_login:"timestamp",
		last_name:"String",
		linked_account_status:"String",
		new_email_code:"String",
		newsletter_subscribe:"Boolean",
		nickname:"String",
		notification_series_settings:"voyce_notification_series_settings",
		notification_series_settings_aggregate:"voyce_notification_series_settings_aggregate",
		notification_setting:"voyce_notification_settings",
		notifications:"voyce_notifications",
		notifications_aggregate:"voyce_notifications_aggregate",
		panels_comments:"voyce_panels_comments",
		panels_comments_aggregate:"voyce_panels_comments_aggregate",
		panels_reactions:"voyce_panels_reactions",
		panels_reactions_aggregate:"voyce_panels_reactions_aggregate",
		password:"String",
		password_secure:"String",
		payments_to:"voyce_payments_to_creators",
		payments_to_aggregate:"voyce_payments_to_creators_aggregate",
		polls:"voyce_polls",
		polls_aggregate:"voyce_polls_aggregate",
		polls_comments:"voyce_polls_comments",
		polls_comments_aggregate:"voyce_polls_comments_aggregate",
		polls_reactions:"voyce_polls_reactions",
		polls_reactions_aggregate:"voyce_polls_reactions_aggregate",
		polls_votes:"voyce_polls_votes",
		polls_votes_aggregate:"voyce_polls_votes_aggregate",
		posts:"voyce_posts",
		posts_aggregate:"voyce_posts_aggregate",
		posts_comments:"voyce_posts_comments",
		posts_comments_aggregate:"voyce_posts_comments_aggregate",
		posts_reactions:"voyce_posts_reactions",
		posts_reactions_aggregate:"voyce_posts_reactions_aggregate",
		private_fields:"voyce_users_private",
		publisher_verification_code:"String",
		quest_onboarded:"Boolean",
		remember_code:"String",
		reset_code:"String",
		reset_code_created_at:"timestamptz",
		reset_otp:"String",
		role:"voyce_user_roles",
		role_id:"Int",
		series_subscription_count:"voyce_user_series_subscription_count",
		signup_source:"String",
		social_discord:"String",
		social_fb:"String",
		social_instagram:"String",
		social_tiktok:"String",
		social_twitter:"String",
		sponsorships:"voyce_sponsorships",
		sponsorshipsBySponsoredUserId:"voyce_sponsorships",
		sponsorshipsBySponsoredUserId_aggregate:"voyce_sponsorships_aggregate",
		sponsorships_aggregate:"voyce_sponsorships_aggregate",
		stripe_setup:"voyce_stripe_payouts_setup",
		unsubscribe:"Boolean",
		updated_at:"timestamptz",
		user_type_preference:"String",
		username:"String",
		usersFollowersByFollowingUid:"voyce_users_followers",
		usersFollowersByFollowingUid_aggregate:"voyce_users_followers_aggregate",
		users_followers:"voyce_users_followers",
		users_followers_aggregate:"voyce_users_followers_aggregate",
		users_series_junctions:"voyce_users_series_junction",
		users_series_junctions_aggregate:"voyce_users_series_junction_aggregate",
		vmuser:"Boolean",
		vtag:"voyce_user_vtags"
	},
	voyce_users_aggregate:{
		aggregate:"voyce_users_aggregate_fields",
		nodes:"voyce_users"
	},
	voyce_users_aggregate_fields:{
		avg:"voyce_users_avg_fields",
		count:"Int",
		max:"voyce_users_max_fields",
		min:"voyce_users_min_fields",
		stddev:"voyce_users_stddev_fields",
		stddev_pop:"voyce_users_stddev_pop_fields",
		stddev_samp:"voyce_users_stddev_samp_fields",
		sum:"voyce_users_sum_fields",
		var_pop:"voyce_users_var_pop_fields",
		var_samp:"voyce_users_var_samp_fields",
		variance:"voyce_users_variance_fields"
	},
	voyce_users_avg_fields:{
		ad_share:"Float",
		admin_role_id:"Float",
		country_id:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		is_onboarded:"Float",
		role_id:"Float"
	},
	voyce_users_followers:{
		created_at:"timestamptz",
		follower_uid:"Int",
		following_uid:"Int",
		id:"Int",
		user:"voyce_users",
		userByFollowingUid:"voyce_users"
	},
	voyce_users_followers_aggregate:{
		aggregate:"voyce_users_followers_aggregate_fields",
		nodes:"voyce_users_followers"
	},
	voyce_users_followers_aggregate_fields:{
		avg:"voyce_users_followers_avg_fields",
		count:"Int",
		max:"voyce_users_followers_max_fields",
		min:"voyce_users_followers_min_fields",
		stddev:"voyce_users_followers_stddev_fields",
		stddev_pop:"voyce_users_followers_stddev_pop_fields",
		stddev_samp:"voyce_users_followers_stddev_samp_fields",
		sum:"voyce_users_followers_sum_fields",
		var_pop:"voyce_users_followers_var_pop_fields",
		var_samp:"voyce_users_followers_var_samp_fields",
		variance:"voyce_users_followers_variance_fields"
	},
	voyce_users_followers_avg_fields:{
		follower_uid:"Float",
		following_uid:"Float",
		id:"Float"
	},
	voyce_users_followers_max_fields:{
		created_at:"timestamptz",
		follower_uid:"Int",
		following_uid:"Int",
		id:"Int"
	},
	voyce_users_followers_min_fields:{
		created_at:"timestamptz",
		follower_uid:"Int",
		following_uid:"Int",
		id:"Int"
	},
	voyce_users_followers_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_users_followers"
	},
	voyce_users_followers_stddev_fields:{
		follower_uid:"Float",
		following_uid:"Float",
		id:"Float"
	},
	voyce_users_followers_stddev_pop_fields:{
		follower_uid:"Float",
		following_uid:"Float",
		id:"Float"
	},
	voyce_users_followers_stddev_samp_fields:{
		follower_uid:"Float",
		following_uid:"Float",
		id:"Float"
	},
	voyce_users_followers_sum_fields:{
		follower_uid:"Int",
		following_uid:"Int",
		id:"Int"
	},
	voyce_users_followers_var_pop_fields:{
		follower_uid:"Float",
		following_uid:"Float",
		id:"Float"
	},
	voyce_users_followers_var_samp_fields:{
		follower_uid:"Float",
		following_uid:"Float",
		id:"Float"
	},
	voyce_users_followers_variance_fields:{
		follower_uid:"Float",
		following_uid:"Float",
		id:"Float"
	},
	voyce_users_max_fields:{
		about:"String",
		ad_share:"Int",
		admin_role_id:"Int",
		avatar:"String",
		avatar_bgcolor:"String",
		banner:"String",
		birth_date:"date",
		cognito_id:"String",
		cognito_provider:"String",
		cognito_username:"String",
		country_id:"Int",
		dark_mode:"smallint",
		date_create:"timestamp",
		delete_code:"String",
		email:"String",
		email_verified_source:"String",
		fcm_token:"String",
		first_name:"String",
		frame:"String",
		gender:"String",
		guest_gtag:"String",
		id:"Int",
		is_account_active:"smallint",
		is_onboarded:"Int",
		klaviyo_id:"String",
		last_login:"timestamp",
		last_name:"String",
		linked_account_status:"String",
		new_email_code:"String",
		nickname:"String",
		password:"String",
		password_secure:"String",
		publisher_verification_code:"String",
		remember_code:"String",
		reset_code:"String",
		reset_code_created_at:"timestamptz",
		reset_otp:"String",
		role_id:"Int",
		signup_source:"String",
		social_discord:"String",
		social_fb:"String",
		social_instagram:"String",
		social_tiktok:"String",
		social_twitter:"String",
		updated_at:"timestamptz",
		user_type_preference:"String",
		username:"String"
	},
	voyce_users_min_fields:{
		about:"String",
		ad_share:"Int",
		admin_role_id:"Int",
		avatar:"String",
		avatar_bgcolor:"String",
		banner:"String",
		birth_date:"date",
		cognito_id:"String",
		cognito_provider:"String",
		cognito_username:"String",
		country_id:"Int",
		dark_mode:"smallint",
		date_create:"timestamp",
		delete_code:"String",
		email:"String",
		email_verified_source:"String",
		fcm_token:"String",
		first_name:"String",
		frame:"String",
		gender:"String",
		guest_gtag:"String",
		id:"Int",
		is_account_active:"smallint",
		is_onboarded:"Int",
		klaviyo_id:"String",
		last_login:"timestamp",
		last_name:"String",
		linked_account_status:"String",
		new_email_code:"String",
		nickname:"String",
		password:"String",
		password_secure:"String",
		publisher_verification_code:"String",
		remember_code:"String",
		reset_code:"String",
		reset_code_created_at:"timestamptz",
		reset_otp:"String",
		role_id:"Int",
		signup_source:"String",
		social_discord:"String",
		social_fb:"String",
		social_instagram:"String",
		social_tiktok:"String",
		social_twitter:"String",
		updated_at:"timestamptz",
		user_type_preference:"String",
		username:"String"
	},
	voyce_users_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_users"
	},
	voyce_users_private:{
		about:"String",
		ad_share:"Int",
		avatar:"String",
		banner:"String",
		birth_date:"date",
		dark_mode:"smallint",
		date_create:"timestamp",
		email:"String",
		first_name:"String",
		gender:"String",
		id:"Int",
		is_account_active:"smallint",
		last_login:"timestamp",
		last_name:"String",
		nickname:"String",
		password:"String",
		remember_code:"String",
		role_id:"Int",
		username:"String",
		vmuser:"Boolean"
	},
	voyce_users_private_aggregate:{
		aggregate:"voyce_users_private_aggregate_fields",
		nodes:"voyce_users_private"
	},
	voyce_users_private_aggregate_fields:{
		avg:"voyce_users_private_avg_fields",
		count:"Int",
		max:"voyce_users_private_max_fields",
		min:"voyce_users_private_min_fields",
		stddev:"voyce_users_private_stddev_fields",
		stddev_pop:"voyce_users_private_stddev_pop_fields",
		stddev_samp:"voyce_users_private_stddev_samp_fields",
		sum:"voyce_users_private_sum_fields",
		var_pop:"voyce_users_private_var_pop_fields",
		var_samp:"voyce_users_private_var_samp_fields",
		variance:"voyce_users_private_variance_fields"
	},
	voyce_users_private_avg_fields:{
		ad_share:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		role_id:"Float"
	},
	voyce_users_private_max_fields:{
		about:"String",
		ad_share:"Int",
		avatar:"String",
		banner:"String",
		birth_date:"date",
		dark_mode:"smallint",
		date_create:"timestamp",
		email:"String",
		first_name:"String",
		gender:"String",
		id:"Int",
		is_account_active:"smallint",
		last_login:"timestamp",
		last_name:"String",
		nickname:"String",
		password:"String",
		remember_code:"String",
		role_id:"Int",
		username:"String"
	},
	voyce_users_private_min_fields:{
		about:"String",
		ad_share:"Int",
		avatar:"String",
		banner:"String",
		birth_date:"date",
		dark_mode:"smallint",
		date_create:"timestamp",
		email:"String",
		first_name:"String",
		gender:"String",
		id:"Int",
		is_account_active:"smallint",
		last_login:"timestamp",
		last_name:"String",
		nickname:"String",
		password:"String",
		remember_code:"String",
		role_id:"Int",
		username:"String"
	},
	voyce_users_private_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_users_private"
	},
	voyce_users_private_stddev_fields:{
		ad_share:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		role_id:"Float"
	},
	voyce_users_private_stddev_pop_fields:{
		ad_share:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		role_id:"Float"
	},
	voyce_users_private_stddev_samp_fields:{
		ad_share:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		role_id:"Float"
	},
	voyce_users_private_sum_fields:{
		ad_share:"Int",
		dark_mode:"smallint",
		id:"Int",
		is_account_active:"smallint",
		role_id:"Int"
	},
	voyce_users_private_var_pop_fields:{
		ad_share:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		role_id:"Float"
	},
	voyce_users_private_var_samp_fields:{
		ad_share:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		role_id:"Float"
	},
	voyce_users_private_variance_fields:{
		ad_share:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		role_id:"Float"
	},
	voyce_users_series_junction:{
		id:"Int",
		series:"voyce_series",
		series_id:"Int",
		user:"voyce_users",
		user_id:"Int"
	},
	voyce_users_series_junction_aggregate:{
		aggregate:"voyce_users_series_junction_aggregate_fields",
		nodes:"voyce_users_series_junction"
	},
	voyce_users_series_junction_aggregate_fields:{
		avg:"voyce_users_series_junction_avg_fields",
		count:"Int",
		max:"voyce_users_series_junction_max_fields",
		min:"voyce_users_series_junction_min_fields",
		stddev:"voyce_users_series_junction_stddev_fields",
		stddev_pop:"voyce_users_series_junction_stddev_pop_fields",
		stddev_samp:"voyce_users_series_junction_stddev_samp_fields",
		sum:"voyce_users_series_junction_sum_fields",
		var_pop:"voyce_users_series_junction_var_pop_fields",
		var_samp:"voyce_users_series_junction_var_samp_fields",
		variance:"voyce_users_series_junction_variance_fields"
	},
	voyce_users_series_junction_avg_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_users_series_junction_max_fields:{
		id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_users_series_junction_min_fields:{
		id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_users_series_junction_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_users_series_junction"
	},
	voyce_users_series_junction_stddev_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_users_series_junction_stddev_pop_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_users_series_junction_stddev_samp_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_users_series_junction_sum_fields:{
		id:"Int",
		series_id:"Int",
		user_id:"Int"
	},
	voyce_users_series_junction_var_pop_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_users_series_junction_var_samp_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_users_series_junction_variance_fields:{
		id:"Float",
		series_id:"Float",
		user_id:"Float"
	},
	voyce_users_stddev_fields:{
		ad_share:"Float",
		admin_role_id:"Float",
		country_id:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		is_onboarded:"Float",
		role_id:"Float"
	},
	voyce_users_stddev_pop_fields:{
		ad_share:"Float",
		admin_role_id:"Float",
		country_id:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		is_onboarded:"Float",
		role_id:"Float"
	},
	voyce_users_stddev_samp_fields:{
		ad_share:"Float",
		admin_role_id:"Float",
		country_id:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		is_onboarded:"Float",
		role_id:"Float"
	},
	voyce_users_sum_fields:{
		ad_share:"Int",
		admin_role_id:"Int",
		country_id:"Int",
		dark_mode:"smallint",
		id:"Int",
		is_account_active:"smallint",
		is_onboarded:"Int",
		role_id:"Int"
	},
	voyce_users_var_pop_fields:{
		ad_share:"Float",
		admin_role_id:"Float",
		country_id:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		is_onboarded:"Float",
		role_id:"Float"
	},
	voyce_users_var_samp_fields:{
		ad_share:"Float",
		admin_role_id:"Float",
		country_id:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		is_onboarded:"Float",
		role_id:"Float"
	},
	voyce_users_variance_fields:{
		ad_share:"Float",
		admin_role_id:"Float",
		country_id:"Float",
		dark_mode:"Float",
		id:"Float",
		is_account_active:"Float",
		is_onboarded:"Float",
		role_id:"Float"
	},
	voyce_versions:{
		id:"Int",
		table:"String",
		updated_at:"timestamptz",
		version:"Int"
	},
	voyce_versions_aggregate:{
		aggregate:"voyce_versions_aggregate_fields",
		nodes:"voyce_versions"
	},
	voyce_versions_aggregate_fields:{
		avg:"voyce_versions_avg_fields",
		count:"Int",
		max:"voyce_versions_max_fields",
		min:"voyce_versions_min_fields",
		stddev:"voyce_versions_stddev_fields",
		stddev_pop:"voyce_versions_stddev_pop_fields",
		stddev_samp:"voyce_versions_stddev_samp_fields",
		sum:"voyce_versions_sum_fields",
		var_pop:"voyce_versions_var_pop_fields",
		var_samp:"voyce_versions_var_samp_fields",
		variance:"voyce_versions_variance_fields"
	},
	voyce_versions_avg_fields:{
		id:"Float",
		version:"Float"
	},
	voyce_versions_max_fields:{
		id:"Int",
		table:"String",
		updated_at:"timestamptz",
		version:"Int"
	},
	voyce_versions_min_fields:{
		id:"Int",
		table:"String",
		updated_at:"timestamptz",
		version:"Int"
	},
	voyce_versions_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_versions"
	},
	voyce_versions_stddev_fields:{
		id:"Float",
		version:"Float"
	},
	voyce_versions_stddev_pop_fields:{
		id:"Float",
		version:"Float"
	},
	voyce_versions_stddev_samp_fields:{
		id:"Float",
		version:"Float"
	},
	voyce_versions_sum_fields:{
		id:"Int",
		version:"Int"
	},
	voyce_versions_var_pop_fields:{
		id:"Float",
		version:"Float"
	},
	voyce_versions_var_samp_fields:{
		id:"Float",
		version:"Float"
	},
	voyce_versions_variance_fields:{
		id:"Float",
		version:"Float"
	},
	voyce_voting_analytics:{
		created_at:"timestamptz",
		cumulative_votes:"Int",
		daily_votes:"Int",
		id:"Int",
		meta:"String",
		updated_at:"timestamptz",
		vote_date:"date",
		voting_series:"voyce_voting_series",
		voting_series_id:"Int"
	},
	voyce_voting_analytics_aggregate:{
		aggregate:"voyce_voting_analytics_aggregate_fields",
		nodes:"voyce_voting_analytics"
	},
	voyce_voting_analytics_aggregate_fields:{
		avg:"voyce_voting_analytics_avg_fields",
		count:"Int",
		max:"voyce_voting_analytics_max_fields",
		min:"voyce_voting_analytics_min_fields",
		stddev:"voyce_voting_analytics_stddev_fields",
		stddev_pop:"voyce_voting_analytics_stddev_pop_fields",
		stddev_samp:"voyce_voting_analytics_stddev_samp_fields",
		sum:"voyce_voting_analytics_sum_fields",
		var_pop:"voyce_voting_analytics_var_pop_fields",
		var_samp:"voyce_voting_analytics_var_samp_fields",
		variance:"voyce_voting_analytics_variance_fields"
	},
	voyce_voting_analytics_avg_fields:{
		cumulative_votes:"Float",
		daily_votes:"Float",
		id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_analytics_max_fields:{
		created_at:"timestamptz",
		cumulative_votes:"Int",
		daily_votes:"Int",
		id:"Int",
		meta:"String",
		updated_at:"timestamptz",
		vote_date:"date",
		voting_series_id:"Int"
	},
	voyce_voting_analytics_min_fields:{
		created_at:"timestamptz",
		cumulative_votes:"Int",
		daily_votes:"Int",
		id:"Int",
		meta:"String",
		updated_at:"timestamptz",
		vote_date:"date",
		voting_series_id:"Int"
	},
	voyce_voting_analytics_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_voting_analytics"
	},
	voyce_voting_analytics_stddev_fields:{
		cumulative_votes:"Float",
		daily_votes:"Float",
		id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_analytics_stddev_pop_fields:{
		cumulative_votes:"Float",
		daily_votes:"Float",
		id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_analytics_stddev_samp_fields:{
		cumulative_votes:"Float",
		daily_votes:"Float",
		id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_analytics_sum_fields:{
		cumulative_votes:"Int",
		daily_votes:"Int",
		id:"Int",
		voting_series_id:"Int"
	},
	voyce_voting_analytics_var_pop_fields:{
		cumulative_votes:"Float",
		daily_votes:"Float",
		id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_analytics_var_samp_fields:{
		cumulative_votes:"Float",
		daily_votes:"Float",
		id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_analytics_variance_fields:{
		cumulative_votes:"Float",
		daily_votes:"Float",
		id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_panels:{
		created_at:"timestamptz",
		id:"Int",
		panel_image:"String",
		updated_at:"timestamptz",
		voting_series:"voyce_voting_series",
		voting_series_id:"Int"
	},
	voyce_voting_panels_aggregate:{
		aggregate:"voyce_voting_panels_aggregate_fields",
		nodes:"voyce_voting_panels"
	},
	voyce_voting_panels_aggregate_fields:{
		avg:"voyce_voting_panels_avg_fields",
		count:"Int",
		max:"voyce_voting_panels_max_fields",
		min:"voyce_voting_panels_min_fields",
		stddev:"voyce_voting_panels_stddev_fields",
		stddev_pop:"voyce_voting_panels_stddev_pop_fields",
		stddev_samp:"voyce_voting_panels_stddev_samp_fields",
		sum:"voyce_voting_panels_sum_fields",
		var_pop:"voyce_voting_panels_var_pop_fields",
		var_samp:"voyce_voting_panels_var_samp_fields",
		variance:"voyce_voting_panels_variance_fields"
	},
	voyce_voting_panels_avg_fields:{
		id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_panels_max_fields:{
		created_at:"timestamptz",
		id:"Int",
		panel_image:"String",
		updated_at:"timestamptz",
		voting_series_id:"Int"
	},
	voyce_voting_panels_min_fields:{
		created_at:"timestamptz",
		id:"Int",
		panel_image:"String",
		updated_at:"timestamptz",
		voting_series_id:"Int"
	},
	voyce_voting_panels_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_voting_panels"
	},
	voyce_voting_panels_stddev_fields:{
		id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_panels_stddev_pop_fields:{
		id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_panels_stddev_samp_fields:{
		id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_panels_sum_fields:{
		id:"Int",
		voting_series_id:"Int"
	},
	voyce_voting_panels_var_pop_fields:{
		id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_panels_var_samp_fields:{
		id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_panels_variance_fields:{
		id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_series:{
		author_image:"String",
		author_name:"String",
		collaborators:"String",
		created_at:"timestamptz",
		id:"Int",
		series:"voyce_series",
		series_id:"Int",
		sort:"Int",
		spotlight_caption:"String",
		spotlight_img1:"String",
		spotlight_img2:"String",
		spotlight_img3:"String",
		updated_at:"timestamptz",
		voting_analytics:"voyce_voting_analytics",
		voting_analytics_aggregate:"voyce_voting_analytics_aggregate",
		voting_panels:"voyce_voting_panels",
		voting_panels_aggregate:"voyce_voting_panels_aggregate",
		voting_user_votes:"voyce_voting_user_votes",
		voting_user_votes_aggregate:"voyce_voting_user_votes_aggregate"
	},
	voyce_voting_series_aggregate:{
		aggregate:"voyce_voting_series_aggregate_fields",
		nodes:"voyce_voting_series"
	},
	voyce_voting_series_aggregate_fields:{
		avg:"voyce_voting_series_avg_fields",
		count:"Int",
		max:"voyce_voting_series_max_fields",
		min:"voyce_voting_series_min_fields",
		stddev:"voyce_voting_series_stddev_fields",
		stddev_pop:"voyce_voting_series_stddev_pop_fields",
		stddev_samp:"voyce_voting_series_stddev_samp_fields",
		sum:"voyce_voting_series_sum_fields",
		var_pop:"voyce_voting_series_var_pop_fields",
		var_samp:"voyce_voting_series_var_samp_fields",
		variance:"voyce_voting_series_variance_fields"
	},
	voyce_voting_series_avg_fields:{
		id:"Float",
		series_id:"Float",
		sort:"Float"
	},
	voyce_voting_series_max_fields:{
		author_image:"String",
		author_name:"String",
		collaborators:"String",
		created_at:"timestamptz",
		id:"Int",
		series_id:"Int",
		sort:"Int",
		spotlight_caption:"String",
		spotlight_img1:"String",
		spotlight_img2:"String",
		spotlight_img3:"String",
		updated_at:"timestamptz"
	},
	voyce_voting_series_min_fields:{
		author_image:"String",
		author_name:"String",
		collaborators:"String",
		created_at:"timestamptz",
		id:"Int",
		series_id:"Int",
		sort:"Int",
		spotlight_caption:"String",
		spotlight_img1:"String",
		spotlight_img2:"String",
		spotlight_img3:"String",
		updated_at:"timestamptz"
	},
	voyce_voting_series_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_voting_series"
	},
	voyce_voting_series_stddev_fields:{
		id:"Float",
		series_id:"Float",
		sort:"Float"
	},
	voyce_voting_series_stddev_pop_fields:{
		id:"Float",
		series_id:"Float",
		sort:"Float"
	},
	voyce_voting_series_stddev_samp_fields:{
		id:"Float",
		series_id:"Float",
		sort:"Float"
	},
	voyce_voting_series_sum_fields:{
		id:"Int",
		series_id:"Int",
		sort:"Int"
	},
	voyce_voting_series_var_pop_fields:{
		id:"Float",
		series_id:"Float",
		sort:"Float"
	},
	voyce_voting_series_var_samp_fields:{
		id:"Float",
		series_id:"Float",
		sort:"Float"
	},
	voyce_voting_series_variance_fields:{
		id:"Float",
		series_id:"Float",
		sort:"Float"
	},
	voyce_voting_user_votes:{
		date_voted:"date",
		id:"Int",
		time_voted:"timestamptz",
		user:"voyce_users",
		user_id:"Int",
		voting_series:"voyce_voting_series",
		voting_series_id:"Int"
	},
	voyce_voting_user_votes_aggregate:{
		aggregate:"voyce_voting_user_votes_aggregate_fields",
		nodes:"voyce_voting_user_votes"
	},
	voyce_voting_user_votes_aggregate_fields:{
		avg:"voyce_voting_user_votes_avg_fields",
		count:"Int",
		max:"voyce_voting_user_votes_max_fields",
		min:"voyce_voting_user_votes_min_fields",
		stddev:"voyce_voting_user_votes_stddev_fields",
		stddev_pop:"voyce_voting_user_votes_stddev_pop_fields",
		stddev_samp:"voyce_voting_user_votes_stddev_samp_fields",
		sum:"voyce_voting_user_votes_sum_fields",
		var_pop:"voyce_voting_user_votes_var_pop_fields",
		var_samp:"voyce_voting_user_votes_var_samp_fields",
		variance:"voyce_voting_user_votes_variance_fields"
	},
	voyce_voting_user_votes_avg_fields:{
		id:"Float",
		user_id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_user_votes_max_fields:{
		date_voted:"date",
		id:"Int",
		time_voted:"timestamptz",
		user_id:"Int",
		voting_series_id:"Int"
	},
	voyce_voting_user_votes_min_fields:{
		date_voted:"date",
		id:"Int",
		time_voted:"timestamptz",
		user_id:"Int",
		voting_series_id:"Int"
	},
	voyce_voting_user_votes_mutation_response:{
		affected_rows:"Int",
		returning:"voyce_voting_user_votes"
	},
	voyce_voting_user_votes_stddev_fields:{
		id:"Float",
		user_id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_user_votes_stddev_pop_fields:{
		id:"Float",
		user_id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_user_votes_stddev_samp_fields:{
		id:"Float",
		user_id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_user_votes_sum_fields:{
		id:"Int",
		user_id:"Int",
		voting_series_id:"Int"
	},
	voyce_voting_user_votes_var_pop_fields:{
		id:"Float",
		user_id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_user_votes_var_samp_fields:{
		id:"Float",
		user_id:"Float",
		voting_series_id:"Float"
	},
	voyce_voting_user_votes_variance_fields:{
		id:"Float",
		user_id:"Float",
		voting_series_id:"Float"
	}
}